import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IBloqueio from "../interfaces/IBloqueio";
import { AdicionaBloqueioRequest } from "../interfaces/Requests/Bloqueio/AdicionaBloqueioRequest";
import { EditarBloqueioRequest } from "../interfaces/Requests/Bloqueio/EditarBloqueioRequest";
import FiltroPesquisaBloqueioRequest from "../interfaces/Requests/Bloqueio/FiltroPesquisaBloqueioRequest";
import { EModulo, ETipoCampoEnum } from "../enum";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class BloqueioService {

    public async obterBloqueios(filtros: FiltroPesquisaBloqueioRequest): Promise<IRecuperaLista<IBloqueio>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.statusBloqueioId.length > 0) {
            filtros.statusBloqueioId.forEach((id) => (query += `&statusBloqueioId=${id}`));
        }

        if (filtros.bloqueioId) {
            query += `&bloqueioId=${filtros.bloqueioId}`;
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.dataBloqueioMaiorIgual) {
            query += `&dataBloqueioMaiorIgual=${filtros.dataBloqueioMaiorIgual}`;
        }

        if (filtros.dataBloqueioMenorIgual) {
            query += `&dataBloqueioMenorIgual=${filtros.dataBloqueioMenorIgual}`;
        }

        if (filtros.dataDesbloqueioMaiorIgual) {
            query += `&dataDesbloqueioMaiorIgual=${filtros.dataDesbloqueioMaiorIgual}`;
        }

        if (filtros.dataDesbloqueioMenorIgual) {
            query += `&dataDesbloqueioMenorIgual=${filtros.dataDesbloqueioMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        if (filtros.valorMaiorIgual) {
            query += `&valorMaiorIgual=${filtros.valorMaiorIgual}`;
        }

        if (filtros.valorMenorIgual) {
            query += `&valorMenorIgual=${filtros.valorMenorIgual}`;
        }

        if (filtros.descricao) {
            query += `&descricao=${filtros.descricao}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IBloqueio>>(`Bloqueio/RecuperaBloqueio?${query}`);

        return result;
    }


    
    public async obterBloqueioPorId(bloqueioId: number): Promise<IRecuperaLista<IBloqueio>> {

        let { data: result } = await api.get<IRecuperaLista<IBloqueio>>(`Bloqueio/RecuperaBloqueio?bloqueioId=${bloqueioId}`);

        return result;
    }

    public async obterCapaBloqueio(bloqueioId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
            `/Bloqueio/RecuperaCapaBloqueio?bloqueioId=${bloqueioId}`
        );

        return result;
    }

    public async adicionaBloqueio(adicionaBloqueio: AdicionaBloqueioRequest): Promise<IBloqueio> {
        let { data: result } = await api.post<IBloqueio>(`Bloqueio/AdicionaBloqueio`, this.montarObjetoRequest(adicionaBloqueio));

        return result;
    }

    public async editarBloqueio(editarBloqueio: AdicionaBloqueioRequest) {
        let request = this.montarObjetoArray(editarBloqueio);

        if (request.length > 0) {
            let result = await api.patch(`Bloqueio/AtualizaBloqueioPorId?id=${editarBloqueio.bloqueioId}`, request);
            return result;
        }
    }

    public async alterarStatus(bloqueioId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IBloqueio>(`Bloqueio/AtualizaBloqueioPorId?id=${bloqueioId}`, data);
    }

    public async atualizarBloqueio(editarBloqueio: EditarBloqueioRequest): Promise<void> {

        let dataBloqueio = [
            { "op": "replace", "path": "/codigo", "value": editarBloqueio.codigo },
            { "op": "replace", "path": "/descricaoBloqueio", "value": editarBloqueio.descricao }
        ]


        await Promise.all([
            await api.patch<void>(`Bloqueio/AtualizaBloqueioPorId?id=${editarBloqueio.bloqueioId}`, dataBloqueio)
        ]);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Bloqueio/AdicionaXCampoValor`, request);

        return result;
    }

    public async adicionaFavorido(bloqueioId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Bloqueio, bloqueioId: bloqueioId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async alterarBloqueioPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Bloqueio/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    private montarObjetoRequest = (bloqueio: AdicionaBloqueioRequest) => {
        let request: any = {};

        if (bloqueio.xCampoValorList.length > 0) request.xCampoValorList = bloqueio.xCampoValorList;

        if (bloqueio.tipoBloqueioId) request.tipoBloqueioId = bloqueio.tipoBloqueioId;

        // Campos texto
        if (bloqueio.codigo) request.codigo = bloqueio.codigo;
        if (bloqueio.pastaId) request.pastaId = bloqueio.pastaId;
        if (bloqueio.descricao) request.descricao = bloqueio.descricao;
        if (bloqueio.agencia) request.agencia = bloqueio.agencia;
        if (bloqueio.conta) request.conta = bloqueio.conta;
        if (bloqueio.bancoId) request.bancoId = bloqueio.bancoId;
        if (bloqueio.moedaId) request.moedaId = bloqueio.moedaId;
        if (bloqueio.valor) request.valor = bloqueio.valor;
        if (bloqueio.databloqueio) request.dataBloqueio = bloqueio.databloqueio;
        if (bloqueio.datadesbloqueio) request.dataDesbloqueio = bloqueio.datadesbloqueio;

        if (bloqueio.processoId) {
            request.processoId = bloqueio.processoId;
            request.modulo = EModulo.AreaDireito;
        }

        if (bloqueio.imovelId) {
            request.imovelId = bloqueio.imovelId;
            request.modulo = EModulo.Imovel;
        }

        return request;
    };

    public concluirCompromisso = async (bloqueio: AdicionaBloqueioRequest): Promise<any> => {

        let result: any[] = [];

        // Salvar campos normais
        result.push(await this.editarBloqueio(bloqueio));

        // Salvar xcampos
        if (bloqueio.xCampoValorList?.length > 0) {
            bloqueio.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, bloqueioId: bloqueio.bloqueioId, texto: xCampo.texto }));
                        else result.push(await this.alterarBloqueioPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, bloqueioId: bloqueio.bloqueioId, data: xCampo.data }));
                        else result.push(await this.alterarBloqueioPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, bloqueioId: bloqueio.bloqueioId, valor: xCampo.valor }));
                        else result.push(await this.alterarBloqueioPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, bloqueioId: bloqueio.bloqueioId, inteiro: xCampo.inteiro }));
                        else result.push(await this.alterarBloqueioPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, bloqueioId: bloqueio.bloqueioId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarBloqueioPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        return result;
    };

    private montarObjetoArray = (bloqueio: AdicionaBloqueioRequest) => {
        let request: any = [];

        // Campos texto
        if (bloqueio.codigo) request.push({ op: "replace", path: "/codigo", value: bloqueio.codigo });
        if (bloqueio.descricao) request.push({ op: "replace", path: "/descricao", value: bloqueio.descricao });
        if (bloqueio.agencia) request.push({ op: "replace", path: "/agencia", value: bloqueio.agencia });
        if (bloqueio.conta) request.push({ op: "replace", path: "/conta", value: bloqueio.conta });

        // // Campos data
        if (bloqueio.databloqueio) request.push({ op: "replace", path: "/dataBloqueio", value: bloqueio.databloqueio });
        if (bloqueio.datadesbloqueio) request.push({ op: "replace", path: "/dataDesbloqueio", value: bloqueio.datadesbloqueio });

        // // Campos Valor
        if (bloqueio.valor) request.push({ op: "replace", path: "/valor", value: bloqueio.valor });
        if (bloqueio.bancoId) request.push({ op: "replace", path: "/bancoId", value: bloqueio.bancoId });
        if (bloqueio.moedaId) request.push({ op: "replace", path: "/moedaId", value: bloqueio.moedaId });
        if (bloqueio.valor) request.push({ op: "replace", path: "/valor", value: bloqueio.valor });

        return request;
    };
}


export default new BloqueioService();