import { Modal, ModalProps } from "react-bootstrap";
import { SaveButton, SaveButtonContainer, SaveButtonLayout } from "./styles";
import { MdFileCopy, MdPictureAsPdf } from "react-icons/md";
import { useSlateStatic } from "slate-react";
import { useReactToPrint } from "react-to-print";
import { getStyles } from "../../../../helpers/getStyles";
import { blockHelper } from "../../../../helpers/blockHelper";
import { useProvider } from "../../../../hooks/useProvider";
import { toast } from "react-toastify";
import { docxHelper } from "../../../../helpers/docxHelper";

interface SaveFileModalProps extends ModalProps {
  onHide: () => void;
  editorRef: React.RefObject<HTMLDivElement>;
}

export const SaveFileModal = (props: SaveFileModalProps): JSX.Element => {
  const editor = useSlateStatic();
  const { version } = useProvider();
  const handlePrint = useReactToPrint({
    content: () => props.editorRef.current,
  });

  const saveAsDocx = async () => {
    if (!version) {
      toast.error("Nenhuma versão disponível.", { position: "bottom-right" });
      return;
    }

    const footers = blockHelper.footers(editor);
    const headers = blockHelper.headers(editor);

    await docxHelper.saveAsFile({
      editor,
      documentStyle: getStyles(version),
      title: version.title,
      firstPageHeaderFooterDiff: version.firstPageHeaderAndFooterDifferent,
      footers,
      headers,
    });
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <strong>Salvar arquivo</strong>
      </Modal.Header>
      <Modal.Body>
        <SaveButtonContainer>
          <SaveButtonLayout>
            <span>
              Salvar como <b>PDF</b>:
            </span>
            <SaveButton onClick={handlePrint} variant="pdf">
              <MdPictureAsPdf />
            </SaveButton>
          </SaveButtonLayout>
          <SaveButtonLayout>
            <span>
              Salvar como <b>DOCX</b>:
            </span>
            <SaveButton onClick={saveAsDocx} variant="docx">
              <MdFileCopy />
            </SaveButton>
          </SaveButtonLayout>
        </SaveButtonContainer>
      </Modal.Body>
    </Modal>
  );
};
