export default function gerarCor(index: number, limit:number, opacidade: any = 1): string {

    const opacidadeFinal = opacidade > 1 ? 1 : opacidade < 0 ? 0 : opacidade;

    const corFinal =  [236,148,83];
    const corInicial = [110,61,144];

    const cor = corInicial.map((c, i) => {
        return c + (corFinal[i] - c) * index / limit;
    });

    return `rgba(${cor[0]}, ${cor[1]}, ${cor[2]}, ${opacidadeFinal})`;
}