import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { EModulo } from "../../../../../../enum";
import { useCustomFlags } from "../../../../hooks/useCustomFlags";
import { useTenant } from "../../../../hooks/useTenant";
import { AIDiagnosticModal } from "../../../AIDiagnosticModal";
import EnviarMinutaModal from "../../../EnviarMinutaModal/EnviarMinutaModal";
import FluxoModal from "../../../../../Fluxo/Modals/FluxoModal";
import { useMenu } from "./hooks/useMenu";
import { MenuType } from "./interfaces/menuItem";
import { Menu } from "./components";
import { EditorVersionData } from "../../../../../../interfaces/IEditorVersionResponse";
import { formatDate, handleApiError } from "../../../../../../utils/Utils";
import { TemplateListModal } from "../TemplateList";
import { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";
import { usePublishDocument } from "./hooks/usePublishDocument";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useDrawer } from "../../../Drawer/useDrawer";
import { useTitle } from "../../hooks/useTitle";
import { MdClose, MdCompareArrows } from "react-icons/md";
import { ChangesComparatorModal } from "../ChangesComparator";
import { useMode } from "../../hooks/useMode";
import FileMinutaField from "../../../../../FileUpload/IFileMinutaField";

interface WordProcessorMenuProps {
  container: DocumentEditorContainerComponent;
  version?: EditorVersionData;
  onChangeToHistoryMode: () => void;
}

export function WordProcessorMenu({
  version,
  container,
  onChangeToHistoryMode,
}: WordProcessorMenuProps) {

  const [openTemplateListModal, setOpenTemplateListModal] = useState<MenuType | null>(null);
  const [openImportadorModal, setOpenImportadorModal] = useState<MenuType | null>(null);

  const { mode, toggleComparatorMode } = useMode();
  const navigate = useNavigate();
  const { tenant, module, caller } = useTenant();
  const { toggle } = useDrawer();
  const { data: customFlags } = useCustomFlags({ tenant, modulo: module });
  const { getItems: menuItems } = useMenu(customFlags);
  const { publish, isLoading: publishLoading } = usePublishDocument();
  const { setDocTitle, docTitle } = useTitle();

  function clearModal() {
    setOpenTemplateListModal(null);
  }

  function importadorModal() {
    if (openImportadorModal == MenuType.importador) {
      setOpenImportadorModal(null);
    } else {
      setOpenImportadorModal(MenuType.importador);
    }
  }

  async function onPublish() {
    try {
      const contentBlob = await container.documentEditor.saveAsBlob("Sfdt");
      const content = await contentBlob.text();
      await publish({
        tenant,
        modulo: module,
        content,
        title: docTitle,
      });
    } catch (error) {
      const message = handleApiError(error);
      toast.error(message, { position: "bottom-right" });
    }
  }

  async function menuAction(type: MenuType) {
    switch (type) {
      case MenuType.cover:
        navigate(`/${caller}/Capa`);
        return;
      case MenuType.send:
        setOpenTemplateListModal(MenuType.send);
        return;
      case MenuType.ia:
        setOpenTemplateListModal(MenuType.ia);
        return;
      case MenuType.publish:
        await onPublish();
        return;
      case MenuType.templateList:
        setOpenTemplateListModal(MenuType.templateList);
        return;
      case MenuType.historyVersion:
        onChangeToHistoryMode();
        return;
      case MenuType.commitment:
        setOpenTemplateListModal(MenuType.commitment);
        return;
      case MenuType.importador:
        setOpenImportadorModal(MenuType.importador);
        return;
      case MenuType.comparator:
        if (isComparatorMode) {
          const currentVersionContent = version?.jsonContent.sfdt;
          if (!currentVersionContent) {
            return;
          }
          toggleComparatorMode({
            editor: container.documentEditor,
            data: JSON.stringify({ sfdt: currentVersionContent }),
          });
          return;
        }
        setOpenTemplateListModal(MenuType.comparator);
        return;
    }
  }

  const isHistoryMode = mode === "history";
  const isComparatorMode = mode === "comparator";

  const menuItemsParsed = isComparatorMode
    ? []
    : menuItems(isHistoryMode).filter((item) => {
      if (isHistoryMode) {
        return item.type === MenuType.historyVersion;
      }
      return item;
    });

  return (
    <Fragment>
      {openTemplateListModal === MenuType.templateList && (
        <TemplateListModal
          show={openTemplateListModal === MenuType.templateList}
          onHide={clearModal}
          editor={container.documentEditor}
        />
      )}

      <AIDiagnosticModal show={openTemplateListModal === MenuType.ia} onHide={clearModal} />

      {openTemplateListModal === MenuType.comparator && (
        <ChangesComparatorModal
          containerComponent={container}
          show={openTemplateListModal === MenuType.comparator}
          onHide={clearModal}
        />
      )}


      {openImportadorModal === MenuType.importador && (
        <FileMinutaField
          exibirModal={openImportadorModal == MenuType.importador}
          toggleModal={importadorModal}
        />
      )}


      <EnviarMinutaModal
        toggleModal={clearModal}
        exibirModal={openTemplateListModal === MenuType.send}
        modulo={EModulo.Contrato}
        objetoId={tenant.provider.value}
      />

      <FluxoModal
        exibirModal={openTemplateListModal === MenuType.commitment}
        setExibirModal={() => setOpenTemplateListModal(MenuType.commitment)}
        compromisso={{
          modulo: customFlags?.compromissoReadDto?.[0]?.modulo || 0,
          fluxoFaseId: customFlags?.compromissoReadDto?.[0]?.fluxoFaseId,
          contratoId: customFlags?.compromissoReadDto?.[0]?.contratoId,
          procuracaoId: customFlags?.compromissoReadDto?.[0]?.procuracaoId,
          compromissoId:
            customFlags?.compromissoReadDto?.[0]?.compromissoId || 0,
          tipoCompromissoId:
            customFlags?.compromissoReadDto?.[0]?.tipoCompromissoId || 0,
          observacaoCompromisso:
            customFlags?.compromissoReadDto?.[0]?.observacaoConclusao || "",
          codigo: "",
          tipoCompromissoNome: "",
          observacaoConclusao: "",
          dataConclusao: new Date(),
          dataCompromisso: new Date(),
          usuariosAprovadoresList: [],
          horario: "",
          grupoId: [],
        }}
        setRefresh={clearModal}
      />

      <Menu.Container>
        <Menu.DocumentInfo>
          <Menu.Title>
            <Menu.TitleInput
              readOnly={isHistoryMode || isComparatorMode}
              value={docTitle}
              onChange={(e) => setDocTitle(e.target.value)}
            />
          </Menu.Title>
          {version && (
            <Menu.Description>
              Por {version.nomeUsuario} em{" "}
              {formatDate(version.dataCadastro, "dd/MM/yyyy 'às' HH:mm")}
            </Menu.Description>
          )}
        </Menu.DocumentInfo>

        <Menu.Actions>
          {menuItemsParsed.map(({ icon: Icon, ...item }) => (
            <Menu.Item
              disabled={item.type === MenuType.publish && publishLoading}
              onClick={() => menuAction(item.type)}
              key={item.title}
              title={item.title}
              className={item.className}
            >
              {item.type === MenuType.publish && publishLoading ? (
                <Spinner size="sm" />
              ) : (
                <Icon size={20} />
              )}
            </Menu.Item>
          ))}

          <Menu.Separator />

          {isHistoryMode ? (
            <button
              onClick={() => toggle()}
              className="btn btn-outline-primary btn-sm"
            >
              Versões
            </button>
          ) : (
            <Menu.Item
              onClick={() => menuAction(MenuType.comparator)}
              title={
                isComparatorMode
                  ? "Sair do comparador"
                  : "Comparador de versões"
              }
              className="btn btn-warning btn-sm"
            >
              {isComparatorMode ? (
                <MdClose size={20} />
              ) : (
                <MdCompareArrows size={20} />
              )}
            </Menu.Item>
          )}
        </Menu.Actions>
      </Menu.Container>
    </Fragment>
  );
}
