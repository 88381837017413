import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IClasseMarca from "../interfaces/IClasseMarca";
import { AdicionaClasseMarcaRequest } from "../interfaces/Requests/ClasseMarca/AdicionaClasseMarcaRequest";
import FiltroPesquisaClasseMarcaRequest from "../interfaces/Requests/ClasseMarca/FiltroPesquisaClasseMarcaRequest";
import { EditarClasseMarcaRequest } from "../interfaces/Requests/ClasseMarca/EditarClasseMarcaRequest";

class ClasseMarcaService {

    async obterClasseMarcas(filtros: FiltroPesquisaClasseMarcaRequest): Promise<IRecuperaLista<IClasseMarca>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IClasseMarca>>(`ClasseMarca/RecuperaClasseMarca?${query}`);

        return result;
    }

    async adicionaClasseMarca(adicionaClasseMarca: AdicionaClasseMarcaRequest): Promise<IClasseMarca> {
        let { data: result } = await api.post<IClasseMarca>(`ClasseMarca/AdicionaClasseMarca`, adicionaClasseMarca);

        return result;
    }


    async alterarStatus(classeMarcaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IClasseMarca>(`ClasseMarca/AtualizaClasseMarcaPorId?id=${classeMarcaId}`, data);
    }

    async atualizarClasseMarca(editarClasseMarca: EditarClasseMarcaRequest): Promise<void> {

        let dataClasseMarca = [
            { "op": "replace", "path": "/nome", "value": editarClasseMarca.nome },
            { "op": "replace", "path": "/codigo", "value": editarClasseMarca.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`ClasseMarca/AtualizaClasseMarcaPorId?id=${editarClasseMarca.classeMarcaId}`, dataClasseMarca)
        ]);
    }
}


export default new ClasseMarcaService();