import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateCNPJ, validateCPF } from "validations-br";
import { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as Yup from 'yup';
import clsx from "clsx";

import { MASCARA_CNPJ, MASCARA_CPF } from "../../../utils/Constants";

import PessoaService from "../../../services/PessoaService";

import { IPessoa } from "../../../interfaces/IPessoa";
import FiltroPesquisaPessoa from "../../../interfaces/Requests/Pessoa/FiltroPesquisaPessoaRequest";

import GridPadrao from "../GridPadrao";
import CNPJAService from "../../../services/CNPJAService";
import { notifySuccess } from "../Toast/ToastFormik";
import { isValidCNPJ, removeSpecialCharacters } from "../../../utils/Utils";

interface RecuperaPessoaProps {
    handlerPessoa: (pessoa: IPessoa) => void,
    handlerTipo: (tipo: string) => void,
    handlerNumeroDocumento: (numeroDocumento: string) => void,
    documentoPadrao?: string,
    tiposDocumento?: string[],
    titulo?: string,
    submitForm?: (formik: any) => void,
}

function RecuperaPessoa({ handlerPessoa, handlerTipo, handlerNumeroDocumento, documentoPadrao, tiposDocumento, titulo, submitForm }: RecuperaPessoaProps) {

    const [tipoDocumento, setTipoDocumento] = useState<string>(documentoPadrao || 'CPF');
    const [tiposDocumentos] = useState<string[]>(tiposDocumento || ['CPF', 'CNPJ', 'NOME', 'OUTROS']);

    const [erroDocumento, setErroDocumento] = useState<string>('');
    const [pessoas, setPessoas] = useState<IPessoa[]>([]);

    const [pesquisar, setPesquisar] = useState<boolean>(false);
    const [carregando, setCarregando] = useState<boolean>(false);

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaPessoa>({
        nome: '',
        documento: '',
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'id',
        status: 1,
        filtro: '',
        tipoPesquisa: 0,
    });

    const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });

    const handlePageChange = (page: number) => setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });

    const handleSort = async (column: TableColumn<IPessoa>, sortDirection: string) => setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });

    useEffect(() => { formik.validateForm(); }, [])

    useEffect(() => { if (pesquisar) carregaPessoa(); }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort, pesquisar]);

    const carregaPessoa = async () => {

        try {

            setCarregando(true);

            const result = await PessoaService.buscarPessoa({
                ...filtrosPesquisa,
                documento: formik.values.numeroDocumento,
                nome: formik.values.nome,
            });

            if (isValidCNPJ(formik.values.numeroDocumento)) {
                let validarCNPJ = await CNPJAService.pesquisarEmpresa(removeSpecialCharacters(formik.values.numeroDocumento));
                if (validarCNPJ?.statusEmpresa != 1) {
                    notifySuccess('Empresa não ativa na receira federal.');
                }
            }

            if (result?.totalRegistros > 0) {
                setPessoas(result.data);

                setFiltrosPesquisa((oldState: any) => { return { ...oldState, totalItems: result?.totalRegistros } });
            }
            else {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-orange me-5',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                });

                let text;

                if (tipoDocumento == "NOME") {
                    text = `Pessoa não foi encontrada, para cadastrar você deve informar o CPF ou CNPJ`;
                } else {
                    text = `Pessoa não foi encontrada, para cadastrar você deve informar o ${tipoDocumento}`;
                }

                let resultButton = await swalWithBootstrapButtons.fire({
                    heightAuto: false,
                    icon: 'warning',
                    text,
                    showConfirmButton: !(tipoDocumento == "NOME"),
                    showCancelButton: true,
                    cancelButtonText: 'Pesquisar outra pessoa',
                    confirmButtonText: `Continuar`,
                });

                if (resultButton.isConfirmed) {
                    handlerTipo(tipoDocumento);
                    handlerNumeroDocumento(formik.values.numeroDocumento);
                    handlerPessoa({ pessoaId: 0 } as IPessoa);
                    setPessoas([]);
                }
            }
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Ocorreu um erro ao tentar buscar Pessoa`,
                text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : '', //error && error.response && error.response.data && typeof error.response.data === 'string' ? error.response.data : error.message,
                showConfirmButton: true
            });
        } finally {
            setPesquisar(false);
            setCarregando(false)
        }
    }

    const colunas: TableColumn<IPessoa>[] = [
        {
            name: 'Id',
            sortField: 'pessoaId',
            selector: (row: IPessoa) => row.pessoaId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Documento',
            sortField: 'numeroDocumento',
            selector: (row: IPessoa) => row.numeroDocumento,
            sortable: true,
            wrap: true
        },
        {
            name: 'Nome',
            sortField: 'nome',
            selector: (row: IPessoa) => row.nome,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (pessoa: IPessoa) => {
                return (
                    <div>
                        <FontAwesomeIcon title="Editar Banco" onClick={async () => {
                            const swalWithBootstrapButtons = Swal.mixin({
                                heightAuto: false,
                                customClass: {
                                    confirmButton: 'btn btn-orange',
                                    cancelButton: 'btn btn-danger ms-5'
                                },
                                buttonsStyling: false
                            });

                            let result = await swalWithBootstrapButtons.fire({
                                title: 'Pessoa',
                                text: `Você realmente selecionar essa pessoa?`,
                                showCancelButton: true,
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: `Confirmar`
                            });

                            if (result.isConfirmed) {
                                handlerTipo(tipoDocumento);
                                handlerNumeroDocumento(formik.values.numeroDocumento);
                                handlerPessoa(pessoa);
                            }
                        }}
                            style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPlus} />
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ]

    const schema = Yup.object().shape({
        numeroDocumento: Yup.string().test('documento-obrigatorio', 'Validação de documento', value => {
            if (tipoDocumento === 'CNPJ') {
                if (!value) {
                    setErroDocumento('Pesquisa deve ser preenchida')
                    return false;
                }
                let cnpjValidar = value.replace(/\./g, '').replace(/-/g, '').replace('/', '').replace(/_/g, '');

                if (cnpjValidar.length !== 14) {
                    setErroDocumento('Preencha todo CNPJ');
                    return false;
                }

                if (!validateCNPJ(cnpjValidar)) {
                    setErroDocumento('CNPJ Inválido');
                    return false;
                }

                setErroDocumento('');
                return true;

            } else if (tipoDocumento === 'CPF') {
                if (!value) {
                    setErroDocumento('Pesquisa deve ser preenchida')
                    return false;
                }
                let cpfValidar = value.replace(/\./g, '').replace(/-/g, '').replace(/_/g, '');

                if (cpfValidar.length !== 11) {
                    setErroDocumento('Preencha todo CPF');
                    return false;
                }

                if (!validateCPF(cpfValidar)) {
                    setErroDocumento('CPF Inválido');
                    return false;
                }

                setErroDocumento('');
                return true;
            } else {
                return true;
            }
        })
    })

    const formik = useFormik({
        initialValues: {
            numeroDocumento: '',
            nome: ''
        },
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            setPesquisar(true);
            setSubmitting(false);
        }
    });

    return (
        <>
            <h3 className="text-orange mb-3">{titulo || "Pesquisar Pessoa:"}</h3>
            <form onSubmit={(e) => {
                e.preventDefault();
                submitForm ? submitForm(formik) : formik.submitForm();
            }} className="row g-3 mb-3">
                {tiposDocumentos.length > 1 &&
                    <div className="col-md-3 ">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Tipo de Pesquisa:</label>
                        <select value={tipoDocumento} onChange={(e) => {

                            formik.resetForm();
                            setTipoDocumento(e.target.value);
                        }} placeholder="Tipo Documento" className={'form-select'} id="form-select" >
                            {tiposDocumentos.map(tipo => {
                                return <option key={tipo} value={tipo}> {tipo} </option>
                            })}
                        </select>
                    </div>
                }

                {
                    tipoDocumento === 'CPF' && (
                        <div className="col-md-3 ">
                            <label htmlFor="form-documento" className="form-label fw-bolder text-orange">CPF:</label>
                            <MaskedInput mask={MASCARA_CPF} {...formik.getFieldProps('numeroDocumento')} placeholder="000.000.000-00" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.numeroDocumento && formik.errors.numeroDocumento
                                },
                                {
                                    'is-valid': formik.touched.numeroDocumento && !formik.errors.numeroDocumento
                                }
                            )} id="form-documento" />
                            {formik.touched.numeroDocumento && formik.errors.numeroDocumento && (
                                <div className='fv-plugins-message-container mt-1'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{erroDocumento}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }

                {
                    tipoDocumento === 'CNPJ' && (
                        <div className="col-md-3 ">
                            <label htmlFor="form-documento" className="form-label fw-bolder text-orange">CNPJ:</label>
                            <MaskedInput mask={MASCARA_CNPJ} {...formik.getFieldProps('numeroDocumento')} placeholder="00.000.000/0000-00" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.numeroDocumento && formik.errors.numeroDocumento
                                },
                                {
                                    'is-valid': formik.touched.numeroDocumento && !formik.errors.numeroDocumento
                                }
                            )} id="form-documento" />
                            {formik.touched.numeroDocumento && formik.errors.numeroDocumento && (
                                <div className='fv-plugins-message-container mt-1'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{erroDocumento}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }

                {
                    tipoDocumento === 'OUTROS' && (
                        <div className="col-md-3 ">
                            <label htmlFor="form-documento" className="form-label fw-bolder text-orange">Documento:</label>
                            <input {...formik.getFieldProps('numeroDocumento')} placeholder="Documento" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.numeroDocumento && formik.errors.numeroDocumento
                                },
                                {
                                    'is-valid': formik.touched.numeroDocumento && !formik.errors.numeroDocumento
                                }
                            )} id="form-documento" />
                            {formik.touched.numeroDocumento && formik.errors.numeroDocumento && (
                                <div className='fv-plugins-message-container mt-1'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{erroDocumento}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )

                }

                {
                    tipoDocumento === 'NOME' && (
                        <div className="col-md-3 ">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                            <input {...formik.getFieldProps('nome')} placeholder="Informe um nome" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.nome && formik.errors.nome
                                },
                                {
                                    'is-valid': formik.touched.nome && !formik.errors.nome
                                }
                            )} id="form-nome" />
                            {formik.touched.nome && formik.errors.nome && (
                                <div className='fv-plugins-message-container mt-1'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{erroDocumento}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }

                <div className="col-md-3">
                    <button disabled={formik.isSubmitting || !formik.isValid} type="submit" className="btn btn-orange search-buttom-margin">
                        {!carregando && <> <FontAwesomeIcon color={'white'} className='mx-2' icon={faSearch} /> </>}
                        {carregando && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                <span className='spinner-border spinner-border-sm align-middle'></span>
                            </span>
                        )}
                    </button>
                </div>

                <div className="col-md-12 mb-3">
                    <GridPadrao onSort={handleSort} progressPending={carregando} limit={filtrosPesquisa.limit} onChangePage={handlePageChange} onChangeRowsPerPage={handlePerRowsChange} paginationServer={true} paginationTotalRows={filtrosPesquisa.totalItems} colunas={colunas} tipo='Esfera' itens={pessoas} />
                </div>

            </form>


        </>
    )
}

export default RecuperaPessoa;