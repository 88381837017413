import { Transforms } from "slate"
import { ReactEditor } from "slate-react"
import { CustomEditor, ImageElement } from "../../../../../utils/types/slate"
import { isValidUrl } from "../../../util"
import imageExtensions from 'image-extensions'

const insertImage = (editor: CustomEditor, url: string) => {
  const text = { text: '' }
  const image: ImageElement = {
    type: 'image',
    url,
    children: [text]
  }
  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] })
  ReactEditor.focus(editor)
}

const isImageUrl = (url: string) => {
  if (!url) return false
  if (!isValidUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop() ?? ""
  return imageExtensions.includes(ext)
}

export const withImages = (editor: CustomEditor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => element.type === 'image' ? true : isVoid(element)

  editor.insertData = data => {
    const text = data.getData('text/plain')
    
    if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}