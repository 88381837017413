import { Button, Form, Modal, ModalProps, OverlayTrigger, Popover } from "react-bootstrap";
import { recorrenciaOptions, useRecorrencia } from "../RecorrenciaContext";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  OcurrencyInfo,
  EndDate,
} from "./styles";
import { MdClose, MdInfo, MdRepeat } from "react-icons/md";
import ReactSelect from "react-select";
import { useMemo } from "react";
import { format, addMonths, addWeeks, addYears, addDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import IRecorrencia from "../../../../../interfaces/IRecorrencia";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type RecorrenciaModalProps = {
  startDate: Date,
  recorreciaRequest: IRecorrencia,
  setRecorrenciaRequest(recorreciaRequest: IRecorrencia): void
} & ModalProps;

const options = Array.from(new Array(99).keys());

export const RecorrenciaModal = ({ startDate, recorreciaRequest, setRecorrenciaRequest, ...props }: RecorrenciaModalProps) => {
  const { showRecorrenciaModal, setShowRecorrenciaModal, recorrencia, setDatesToAdd, setRecorrencia } = useRecorrencia();

  const formatDefault = (date: Date) => format(date, "yyyy-MM-dd", { locale: ptBR });

  const label = useMemo(() => {

    const options = {
      none: "",
      daily: "dia(s)",
      weekly: "semana(s)",
      monthly: "mês(es)",
      yearly: "ano(s)",
    };

    return options[recorrencia.value];

  }, [recorrencia.value]);

  const onHide = () => {

    setRecorrencia(recorrenciaOptions[0]);

    setShowRecorrenciaModal((prev) => !prev);

  }

  const message = useMemo(() => {
    let base = "Esse compromisso irá ocorrer";

    const { value } = recorrencia;

    if (value === "daily") {
      if (recorreciaRequest.repetirCada === 1) {
        base += " todos os dias até:";
      } else {
        base += ` a cada ${recorreciaRequest.repetirCada} ${label} até:`;
      }
    }

    if (value === "weekly") {
      const dayWeek = format(startDate, "EEEE'(s)'", { locale: ptBR });

      if (recorreciaRequest.termino === 1) {
        base += ` toda ${dayWeek} até:`;
      } else {
        base += ` a cada ${recorreciaRequest.repetirCada} ${dayWeek} até:`;
      }
    }

    const dayMonth = format(startDate, "dd", { locale: ptBR });

    if (value === "monthly") {
      if (recorreciaRequest.repetirCada === 1) {
        base += ` todo dia ${dayMonth} até:`;
      } else {
        base += ` todo dia ${dayMonth} a cada ${recorreciaRequest.repetirCada} meses até:`;
      }
    }

    if (value === "yearly") {
      const month = format(startDate, "LLLL", { locale: ptBR });
      base += ` todo dia ${dayMonth} de ${month}.`;
    }

    return base;
  }, [recorreciaRequest.repetirCada, recorrencia]);

  const adicionarData = () => {
    const { value } = recorrencia;

    const start = startDate;
    const end = new Date(recorreciaRequest.termino);

    const datesToAdd: string[] = [];
    let currentDate = start;

    while (currentDate <= end) {
      datesToAdd.push(formatDefault(currentDate));
      if (value === 'daily') {
        currentDate = addDays(currentDate, 1);
      }
      if (value === 'weekly') {
        currentDate = addWeeks(currentDate, 1);
      }
      if (value === 'monthly') {
        currentDate = addMonths(currentDate, 1);
      }
      if (value === 'yearly') {
        currentDate = addYears(currentDate, 1);
      }
    }

    return datesToAdd
  }

  const adicionar = () => {

    const datesToAdd = adicionarData();

    setDatesToAdd(datesToAdd);

    setShowRecorrenciaModal(false);

  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>Ao marcar a opção "Somente dias Úteis?", excluem-se sábados, domingos e feriados do cálculo de datas. Com recorrência semanal, mensal ou anual, o sistema antecipa eventos para o próximo dia útil quando a data gerada cair em um sábado, domingo ou feriado. Por exemplo, se um evento está agendado mensalmente para o dia 30 e este cair em um domingo, o sistema ajustará automaticamente a data para o dia útil anterior..</Popover.Body>
    </Popover>
  );


  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );


  return (
    <Modal
      {...props}
      size="sm"
      show={showRecorrenciaModal}
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <ModalHeader>
          <h5 className="text-orange">Repetir</h5>
          <button onClick={onHide} type="button">
            <MdClose />
          </button>
        </ModalHeader>

        <ModalContent>
          <MdRepeat size={20} />

          <div className="select">
            <span className="text-orange">Repetir a cada</span>
            {recorrencia.value !== "yearly" && (
              <ReactSelect
                isSearchable={false}
                value={{ label: `${recorreciaRequest.repetirCada}`, value: recorreciaRequest.repetirCada }}
                onChange={(e) => setRecorrenciaRequest({ ...recorreciaRequest, repetirCada: e?.value || 1 })}
                components={{
                  IndicatorSeparator: () => null,
                  Placeholder: () => null,
                }}
                options={options.map((option) => ({
                  value: option + 1,
                  label: `${option + 1}`,
                }))}
              />
            )}

            <span>
              <b className="text-orange">{label}</b>
            </span>
          </div>
        </ModalContent>

        <OcurrencyInfo>
          <MdInfo size={18} />
          <span className="text-orange">{message}</span>
        </OcurrencyInfo>

        {recorrencia.value !== "yearly" && (
          <EndDate>
            <small className="text-orange">Término:</small>
            <input
              onChange={(e) => setRecorrenciaRequest({ ...recorreciaRequest, termino: e.target.value })}
              min={formatDefault(startDate)}
              value={recorreciaRequest.termino}
              className="form-control"
              type="date"
            />
          </EndDate>
        )}


        <EndDate>
          <small className="text-orange" >Somente dias Úteis? <PopoverHelper /></small>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={recorreciaRequest.somenteDiasUteis}
            onChange={() => {
              if (recorreciaRequest.somenteDiasUteis) setRecorrenciaRequest({ ...recorreciaRequest, somenteDiasUteis: false });
              if (!recorreciaRequest.somenteDiasUteis) setRecorrenciaRequest({ ...recorreciaRequest, somenteDiasUteis: true });
            }}
          />
        </EndDate>

        <ModalFooter>
          <Button type="button" variant="text" onClick={onHide}>
            Cancelar
          </Button>
          <Button onClick={adicionar} type="button" variant="var(--primary-base2)">
            Salvar
          </Button>
        </ModalFooter>
      </Modal.Body>
    </Modal>
  );
};
