import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { ICampo } from "../../../../interfaces/ICampo";

import FormECampo from "../Form/FormECampo";
import FormXCampoObrigacao from "../../XCampos/Solucoes/FormXCampoObrigacao";

interface ColumnProps {
  alterarItem(item: ICampo): void;
  col: {
    id: string;
    list: ICampo[];
  };
}

const Column: React.FC<ColumnProps> = ({ col: { id, list }, alterarItem }) => {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <>
          <div
            key={id}
            style={{ border: "1px solid #dee2e6", paddingTop: "40px", paddingBottom: "600px" }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map((item, index) => {
              return (
                <>
                  {item && item.isXCampo && (
                    <FormXCampoObrigacao item={item} key={item?.campo} index={index} alterarItem={alterarItem} col={id} />
                  )}

                  {item && !item.isXCampo && (
                    <FormECampo item={item} key={item?.campo} index={index} alterarItem={alterarItem} col={id} />
                  )}
                </>
              );
            })}
          </div>
        </>
      )}
    </Droppable>
  );
};

export default Column;
