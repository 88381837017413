import {
  Content,
  ContainerInterligacao,
  ElementoContainer,
  NomeGrupo,
  NomeGrupoContainer,
} from "./styles";
import { Empresa } from "../Empresa";
import { useRef } from "react";
import { IOrganogramaEmpresa } from "../../../../../interfaces/IOrganogramaEmpresa";

type GroupProps = {
  nome: string;
  empresas: IOrganogramaEmpresa[];
};

export default function Grupo({ nome, empresas }: GroupProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div style={{ display: "flex" }} ref={containerRef}>
      <Content>
        <NomeGrupoContainer>
          <NomeGrupo>{nome}</NomeGrupo>
        </NomeGrupoContainer>
        <ContainerInterligacao>
          <div />
        </ContainerInterligacao>
        <div style={{ overflow: "hidden" }}>
          <ElementoContainer>
            {empresas.map((empresa) => (
              <Empresa
                key={empresa.nome}
                socios={empresa.socios}
                filiais={empresa.filiais}
                nome={empresa.nome}
              />
            ))}
          </ElementoContainer>
        </div>
      </Content>
    </div>
  );
}
