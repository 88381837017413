import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoProcuracao from "../interfaces/ITipoProcuracao";
import { AdicionaTipoProcuracaoRequest } from "../interfaces/Requests/TipoProcuracao/AdicionaTipoProcuracaoRequest";
import FiltroPesquisaTipoProcuracaoRequest from "../interfaces/Requests/TipoProcuracao/FiltroPesquisaTipoProcuracaoRequest";
import { EditarTipoProcuracaoRequest } from "../interfaces/Requests/TipoProcuracao/EditarTipoProcuracaoRequest";

class TipoProcuracaoService {
  async obterTipoProcuracoes(
    filtros: FiltroPesquisaTipoProcuracaoRequest
  ): Promise<IRecuperaLista<ITipoProcuracao>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.categoria && filtros.categoria > 0) {
      query += `&categoria=${filtros.categoria}`;
    }

    if (filtros.categoriaNot && filtros.categoriaNot > 0) {
      query += `&categoriaNot=${filtros.categoriaNot}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ITipoProcuracao>>(
      `TipoProcuracao/RecuperaTipoProcuracao?${query}${limit}`
    );

    return result;
  }

  async adicionaTipoProcuracao(
    adicionaTipoProcuracao: AdicionaTipoProcuracaoRequest
  ): Promise<ITipoProcuracao> {
    let { data: result } = await api.post<ITipoProcuracao>(
      `TipoProcuracao/AdicionaTipoProcuracao`,
      adicionaTipoProcuracao
    );

    return result;
  }

  async alterarStatus(tipoProcuracaoId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ITipoProcuracao>(
      `TipoProcuracao/AtualizaTipoProcuracaoPorId?id=${tipoProcuracaoId}`,
      data
    );
  }

  async atualizarTipoProcuracao(
    editarTipoProcuracao: EditarTipoProcuracaoRequest
  ): Promise<void> {
    let dataTipoProcuracao = [
      { op: "replace", path: "/nome", value: editarTipoProcuracao.nome },
      { op: "replace", path: "/codigo", value: editarTipoProcuracao.codigo },
    ];

    await Promise.all([
      await api.patch<void>(
        `TipoProcuracao/AtualizaTipoProcuracaoPorId?id=${editarTipoProcuracao.tipoProcuracaoId}`,
        dataTipoProcuracao
      ),
    ]);
  }
}

export default new TipoProcuracaoService();
