import { FaDollarSign } from "react-icons/fa";
import { GrBarChart } from "react-icons/gr";
import { DadoNumerico, ModeloWidget } from "../../../components/dashboard";

export const WIDGETS: ModeloWidget[] = [
{
    tipo: "card",
    props: {
        titulo: 'Indicadores Gerais',
        tipo: 'compacto', // compacto || vertical
        dados: {
            etiquetas: {
                metodo: 'dadosIndicadoresGerais',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },
            dados: {
                metodo: 'dadosIndicadoresGerais',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        estilos: [
            {
               background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: GrBarChart,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
                background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',	
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
               background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
                background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',	
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
               background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
                background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',	
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
               background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
                background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',	
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
               background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            },
            {
                background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',	
                borderColor: 'transparent',
                minWidth: '20%',
                color: '#fff',
                icon: FaDollarSign,
                iconBackgroundColor: 'transparent',
                iconColor: '#fff',
                iconGrow: true,
                size: 2
            }
        ]
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Qtd. Ativo x Passivo',
        dados: {
            etiquetas: ['Passivo', 'Ativo'],
            dados:
            {
                metodo: 'dadosPassivoAtivo',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'doughnut',
        configuracaoGrafico: {
            direcaoDaBarra: "vertical",
            posicaoLegenda: 'right',
            mostrarLegenda: true,
            mostrarMaisNoZoom: true
        }
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Qtd. Judicial x Administrativo',      
        dados: {
            etiquetas: ['Judicial', 'Administrativo'],  
            dados:                              
            {
                metodo: 'dadosJudicialAdministrativo',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'pie',
        configuracaoGrafico: {
            direcaoDaBarra: "vertical",
            posicaoLegenda: 'right',         
            mostrarLegenda: true,
            mostrarMaisNoZoom: true        
        }

    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Valor Total x Divisão',      
        dados: {
            etiquetas: {
                metodo: 'dadosValorTotalPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },  
            dados:                              
            {
                metodo: 'dadosValorTotalPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: "horizontal",
            tipoDeDado: 'moeda',
            mostrarLegenda: false,
            mostraTotalNoLabel: false,
            mostrarMaisNoZoom: true
        }

    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Valor Provisionado x Divisão',
        dados: {
            etiquetas:
            {
                metodo: 'dadosProvisaoPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },
            dados:
            {
                metodo: 'dadosProvisaoPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: "horizontal",
            posicaoLegenda: 'bottom',
            tipoDeDado: 'moeda',
            mostrarLegenda: false,
            mostrarMaisNoZoom: true
        }
    }
},
{
    tipo: "chart",
    props: { 
        titulo: 'Provisão Por Área',      
        dados: {
            etiquetas: {
                metodo: 'provisaoPorArea',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },  
            dados:                              
            {
                metodo: 'provisaoPorArea',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'doughnut',
        configuracaoGrafico: {            
            direcaoDaBarra: "vertical",          
            tipoDeDado: 'moeda',  
            posicaoLegenda: 'right',
            mostrarMaisNoZoom: true        
        }

    }
},
{
    tipo: "chart",
    props: { 
        titulo: 'Variação Provisão Por Áreas',      
        dados: {
            etiquetas: {
                metodo: 'labelsVariacaoProvisaoPorArea',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },  
            dados:                              
            {
                metodo: 'dadosVariacaoProvisaoPorArea',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'line',
        configuracaoGrafico: {           
            escalaIndividual: false,
            posicaoLegenda: 'bottom',
            habilitarZoom: true,
            tipoDeDado: 'moeda',
            mostrarLegenda: true,
            mostrarMaisNoZoom: true,
            tamanho: 50       
        }

    }
},
{
    tipo: "chart",
    props: { 
        titulo: 'Provisão Por Divisão',      
        dados: {
            etiquetas: {
                metodo: 'provisaoPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },  
            dados:                              
            {
                metodo: 'provisaoPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'doughnut',
        configuracaoGrafico: {            
            direcaoDaBarra: "vertical",          
            tipoDeDado: 'moeda',  
            posicaoLegenda: 'bottom',
            mostrarMaisNoZoom: true,             
        }

    }
},
{
    tipo: "chart",
    props: { 
        titulo: 'Variação Provisão Por Divisão',      
        dados: {
            etiquetas: {
                metodo: 'labelsVariacaoProvisaoPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },  
            dados:                              
            {
                metodo: 'dadosVariacaoProvisaoPorDivisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'line',
        configuracaoGrafico: {
            escalaIndividual: false,
            habilitarZoom: true,         
            posicaoLegenda: 'bottom',
            tipoDeDado: 'moeda',           
            mostrarLegenda: true,
            tamanho: 50,
            mostrarMaisNoZoom: true        
        }

    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Valor Total Por Area',      
        dados: {
            etiquetas: {
                metodo: 'valorTotalPorArea',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },  
            dados:                              
            {
                metodo: 'valorTotalPorArea',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: "vertical",           
            tipoDeDado: 'moeda',  
            mostrarMaisNoZoom: true      
        }

    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Variação Provisão',      
        dados: {
            etiquetas: {
                metodo: 'variacaoProvisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
            },  
            dados:                              
            {
                metodo: 'variacaoProvisao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: "vertical",
            mostrarMaisNoZoom: false,  
            tamanho: 300,       
            tipoDeDado: 'moeda',              
        }

    }
}
]