import { useContext, useEffect, useRef, useState } from "react";
import { faHistory, faPrint, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsDiagram3 } from "react-icons/bs";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import * as Formik from "formik";
import Swal from "sweetalert2";
import moment from "moment";

import { EModulo, EStatusEmpresa, ETipoCampoEnum } from "../../../enum";

import EmpresaService from "../../../services/EmpresaService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import { ReturnFormField } from "../interfaces";
import { ICampo } from "../../../interfaces/ICampo";
import { IConfiguracaoTela } from "../../../interfaces/IConfiguracaoTela";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import { PopoverSubMenuEmpresa } from "../../../components/Comum/Popover/PopoverSubMenuEmpresa";
import { CamposCapaEmpresaDinamicos } from "../../../components/Empresa";

import Andamento from "../componentes/Andamento/Andamento";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import Documentos from "../componentes/Documentos/Documentos";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import Compromisso from "../componentes/Compromisso/Compromisso";
import { LayoutPageContents } from "../../../layout/LayoutPageContents";

import {
  fieldNeedHaveId,
  generateSchemaFromModelo,
  mergeValuesInModelo,
  showErrorMessage,
} from "../utils";

import { camelizar } from "../../ConfiguracaoTelaPage/Empresa/utils";
import { Modelo } from "../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";
import OrganogramaModal from "../../ConfiguracaoTelaPage/Empresa/Organograma/Modal";
import CompartilharModal from "../../../components/Comum/Share/CompartilharModal";

const EmpresaCapaPage = () => {

  const { setEmpresa, empresa } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState(false);
  const [exibirOrganograma, setExibirOrganograma] = useState(false)
  const [configuracoesTela, setConfiguracoesTela] = useState<Modelo[]>([]);
  const [tela, setTela] = useState<IConfiguracaoTela>();
  const [esquemaValidacao, setEsquemaValidacao] = useState();
  const componentRef = useRef<any>();

  const [exibirModalShare, setExibirModalShare] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleShare = () => setExibirModalShare(!exibirModalShare);

  const empresaTelaCapa: IConfiguracaoTela = {
    modulo: EModulo.Societario,
    tela: 4,
    camposList: [],
  };

  const initialValues = {
    grupoEconomicoId: "",
    tipoEstabelecimento: "",
    tipoSociedadeId: empresa.tipoSociedadeId,
    pessoaId: "",
    tipoEmpresaId: "",
    objetoSocial: "",
    inscricaoEstadual: "",
    inscricaoMunicipal: "",
    orgaoRegistroId: "",
    descricaoOrgao: "",
    nire: "",
    dataConstituicao: "",
    dataPrimeiroRegistro: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
    cidadeId: "",
    porteEmpresa: "",
    valorCapitalSocial: "",
    valorCapitalIntegralizado: "",
    valorCapitalAIntegralizar: "",
    moedaId: "",
    observacoesEmpresa: "",
    telefone: "",
    email: "",
    status: empresa.statusEmpresa,
    dataSituacao: "",
    motivoSituacaoId: "",
    atividadeEmpresaPrincipalId: "",
    atividadesEmpresa: empresa.empresasAtividadesEmpresa,
  };

  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: esquemaValidacao,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const setarValor = (field: ReturnFormField) => formik.setFieldValue(field.name, field.value);

  const carregaConfiguracoesTela = async () => {
    try {

      if (!empresa.empresaId) return;

      setCarregandoConfiguracoesTela(true);

      const { data: empresaAtualizada } = await EmpresaService.obterDadosEmpresa(
        empresa.empresaId
      );

      setEmpresa(empresaAtualizada[0]);

      const atividadesEmpresa = await EmpresaService.obterAtividadesEmpresa(
        empresa.empresaId
      );

      const modeloFields =
        await ConfiguracaoTelaService.obterConfiguracaoTela2({
          Modulo: empresaTelaCapa.modulo,
          Tela: empresaTelaCapa.tela,
          SubModulo: empresaTelaCapa?.subModulo,
          TipoSociedadeId: empresa.tipoSociedadeId,
        });

      const fieldsCapa = (
        await EmpresaService.obterCapaEmpresa(empresa.empresaId)
      ).data as unknown as ICampo[];

      const fieldsWithValues = mergeValuesInModelo(modeloFields, fieldsCapa);

      fieldsWithValues.map((field) => {
        let campoNome = camelizar(field.campoNome);

        if (field.tipo === ETipoCampoEnum.Data) {
          field.valor = moment(field.valor).format("YYYY-MM-DD");
        }

        if (field.campoNome === "TipoEstabelecimento") {
          field.tipo = ETipoCampoEnum.EnumLista;
        }

        if ([ETipoCampoEnum.EnumLista].includes(field.tipo)) {
          field.controller = field.campoNome;
        }

        if (fieldNeedHaveId(field)) {
          field.campoNome = campoNome + "Id";
          campoNome = campoNome + "Id";
        }

        if (field.campoNome === "OrgaoRegistroId") {
          field.valorId = empresa.orgaoRegistroId;
        }

        if (field.campoNome === "StatusEmpresa") {
          field.valorId = EStatusEmpresa[field.valor] as unknown as number;
        }

        if (formik) {
          if (campoNome === "atividadesEmpresa") {
            formik.setFieldValue(campoNome, atividadesEmpresa || []);
          } else {
            formik.setFieldValue(campoNome, field.valorId || field.valor);
          }
        }
      });

      setEsquemaValidacao(() => generateSchemaFromModelo(fieldsWithValues));
      setConfiguracoesTela(fieldsWithValues);
      setTela({
        ...empresaTelaCapa,
        camposList: fieldsWithValues,
      });
      setCarregandoConfiguracoesTela(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter a empresa selecionado`,
        text:
          error?.response?.data?.Message &&
            typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    if (!empresa.empresaId) return navigate("/Societario/Empresa");

    carregaConfiguracoesTela();

  }, []);

  const compartilhar = () => `Empresa_${empresa.empresaId}_${empresa.tipoSociedadeId > 0 ? "Nome_" + empresa.nomeFantasia : ""}_Status_${empresa.statusEmpresa}`;

  const adicionarFavorito = async (empresaId: number) => {
    try {
      let resultado = await EmpresaService.adicionarFavorito(empresaId);
      setEmpresa({ ...empresa, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar empresa como favorito`,
        text: showErrorMessage(error.message),
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await EmpresaService.removerFavorito(favoritoId);
      setEmpresa({ ...empresa, favoritoId: 0 });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: showErrorMessage(error.message),
        showConfirmButton: true,
      });
    }
  };

  const editar = async (campo: any) => {
    const valor = formik.values[camelizar(campo.campoNome)];
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Editar dados da Capa",
        text: `Você realmente deseja editar este campo?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          icon: "info",
          title: "Realizando edição...",
          showConfirmButton: false,
        });
        Swal.showLoading();

        if (formik.errors[camelizar(campo.campoNome)]) {
          throw new Error(
            formik.errors[camelizar(campo.campoNome)]?.toString()
          );
        }

        await EmpresaService.atualizaEmpresaPorCampo(
          camelizar(campo.campoNome),
          valor,
          empresa.empresaId
        );

        carregaConfiguracoesTela();

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Alteração realizada com sucesso!`,
          showConfirmButton: true,
          timer: 4000,
        });
        Swal.close();
      }
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar a edição!`,
        text: showErrorMessage(error.message),
        showConfirmButton: true,
      });
    }
  };

  return (
    <span ref={componentRef}>
      {exibirOrganograma &&
        <OrganogramaModal
          type="empresa"
          show={exibirOrganograma}
          onHide={() => setExibirOrganograma(prev => !prev)}
          empresaId={empresa.empresaId}
          nomeEmpresa={empresa.nomeFantasia}
        />}

      <LayoutPageTitle
        title={`Capa #${empresa.empresaId} - Empresa: ${empresa.nomeFantasia}`}
      >
        <>
          <ToastContainer />

          <CompartilharModal
            texto={compartilhar()}
            exibirModal={exibirModalShare}
            toggleModal={toggleShare}
          />

          {!carregandoConfiguracoesTela && (
            <a
              style={{ cursor: "pointer", fontSize: "30px" }}
              onClick={() => carregaConfiguracoesTela()}
            >
              {
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faHistory}
                />
              }
            </a>
          )}

          {carregandoConfiguracoesTela && (
            <span
              className="indicator-progress"
              style={{
                display: "block",
                fontSize: "30px",
                paddingLeft: "11px",
                paddingRight: "11px",
              }}
            >
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </>
        <ReactToPrint
          trigger={() => (
            <a style={{ cursor: "pointer", fontSize: "30px" }}>
              <FontAwesomeIcon
                color={"#DC853D"}
                className="mx-2"
                icon={faPrint}
                title="Imprimir Empresa"
              />
            </a>
          )}
          content={() => componentRef.current}
        />
        <a
          style={{ cursor: "pointer", fontSize: "30px" }}
          className="linkVisitado"
          onClick={() => toggleShare()}
          title="Compartilhar"
        >
          {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShareAlt} />}
        </a>

        <BsDiagram3
          title="Organograma"
          onClick={() => setExibirOrganograma(true)}
          style={{ fontWeight: "normal", cursor: "pointer" }}
          size="35px"
          className="mx-2 text-orange"
        />

        {empresa.favoritoId ? (
          <a
            style={{ marginLeft: "5px", cursor: "pointer" }}
            onClick={() => removerFavorito(empresa.favoritoId)}
            className="navigation-service-icon navigation-service-icon-startShow"
            title="Desfavoritar o empresa"
          ></a>
        ) : (
          <a
            style={{ marginLeft: "5px", cursor: "pointer" }}
            onClick={() => adicionarFavorito(empresa.empresaId)}
            className="navigation-service-icon navigation-service-icon-start"
            title="Favoritar a empresa"
          ></a>
        )}

        <OverlayTrigger
          trigger="click"
          rootClose={true}
          placement="bottom"
          overlay={PopoverSubMenuEmpresa()}
        >
          <span
            hidden={carregandoConfiguracoesTela}
            style={{ cursor: "pointer", marginRight: "10px" }}
            className="navigation-services-list-link"
          >
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </span>
        </OverlayTrigger>
      </LayoutPageTitle>

      <LayoutPageContents>
        <Row className="w-100">
          {tela &&
            configuracoesTela.map((campo: Modelo, index: number) => {
              const campoNomeCamelCase = camelizar(campo.campoNome);

              const campopsFullWidth = ["AtividadesEmpresa"];

              return (
                <div
                  key={index}
                  className={`col-12 col-md-6 ${campopsFullWidth.includes(campo.campoNome)
                    ? "col-lg-12"
                    : "col-lg-3"
                    }  mt-3`}
                >
                  <CamposCapaEmpresaDinamicos
                    modelo={campo}
                    handleSetValue={setarValor}
                    handleUpdateValue={editar}
                    campo={{
                      onChange: (e: any) => {
                        formik.setFieldValue(
                          campoNomeCamelCase,
                          e.target.value
                        );
                      },
                      name: campoNomeCamelCase,
                      onBlur: () => {
                        return;
                      },
                      value: formik.values[campoNomeCamelCase],
                    }}
                    touched={
                      formik.touched[
                      campoNomeCamelCase
                      ] as Formik.FormikTouched<any>
                    }
                    error={formik.errors[campoNomeCamelCase]}
                    tela={tela}
                  />
                </div>
              );
            })}

          <Andamento empresa={empresa} />

          <hr />

          <Compromisso empresa={empresa} />

          <hr />

          <Documentos empresa={empresa} />
        </Row>
      </LayoutPageContents>
    </span>
  );
};

export default EmpresaCapaPage;
