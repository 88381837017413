import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ICNJOrgao from "../interfaces/ICNJOrgao";
import ICNJTribunal from "../interfaces/ICNJTribunal";
import ICNJUnidadeOrigem from "../interfaces/ICNJUnidadeOrigem";
import FiltroPesquisaCNJOrgaoRequest from "../interfaces/Requests/CNJ/FiltroPesquisaCNJOrgaoRequest";
import FiltroPesquisaCNJTribunalRequest from "../interfaces/Requests/CNJ/FiltroPesquisaCNJTribunalRequest";
import FiltroPesquisaCNJUnidadeOrigemRequest from "../interfaces/Requests/CNJ/FiltroPesquisaCNJUnidadeOrigemRequest";

class CNJService {

    async obterCNJOrgao(filtros: FiltroPesquisaCNJOrgaoRequest): Promise<IRecuperaLista<ICNJOrgao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICNJOrgao>>(`CNJ/RecuperaCNJOrgao?${query}`);

        return result;
    }

    async obterRecuperaCNJTribunal(filtros: FiltroPesquisaCNJTribunalRequest): Promise<IRecuperaLista<ICNJTribunal>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICNJTribunal>>(`CNJ/RecuperaCNJTribunal?${query}`);

        return result;
    }

    async obterRecuperaCNJUnidadeOrigem(filtros: FiltroPesquisaCNJUnidadeOrigemRequest): Promise<IRecuperaLista<ICNJUnidadeOrigem>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICNJUnidadeOrigem>>(`CNJ/RecuperaCNJUnidadeOrigem?${query}`);

        return result;
    }

}
export default new CNJService();