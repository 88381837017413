import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";


import IAreaDireito from "../../interfaces/IAreaDireito";
import IRecuperaLista from "../../interfaces/IRecuperaLista";

import GridPadrao from "./GridPadrao";
import XCampoService from "../../services/XCampoService";
import { Form } from "react-bootstrap";

interface IListaSelectedProps {
  xCampoId: number;
  setFieldValue: any;
  ListaSelected: any[];
}

const ListaXSelected = ({ xCampoId, setFieldValue, ListaSelected }: IListaSelectedProps) => {
  const [Lista, setLista] = useState<any[]>([]);
  const [carregandoListaCamposs, setCarregandoListaCampos] = useState(false);
  const [marcarTodos, setMarcarTodos] = useState<boolean>(false);


  const [filtrosPesquisa, setFiltrosPesquisa] = useState<any>({
    campoId: xCampoId,
    status: 1,
    offset: 0,
    limit: 5,
    sort: "CampoId",
    totalPaginas: 0,
  });

  useEffect(() => {
    if (Lista.length > 0) {
      ListaSelected.forEach((item) => {
        const ListaCamposChecked = Lista.filter((e: any) => e.xCampoOpcaoId === item.xCampoOpcaoId)[0];
        if (ListaCamposChecked) ListaCamposChecked.checked = true;
      });
    }
    setLista(Lista);
  }, [Lista, ListaSelected]);

  const handleChange = (id: number) => {
    Lista.forEach((item) => {
      if (item.xCampoOpcaoId === id) {
        if (!item.checked) {
          item.checked = true;
          ListaSelected.push(item);
          setLista(Lista);
          return;
        }
        if (item.checked) {
          item.checked = false;
          ListaSelected = ListaSelected.filter((e) => e.xCampoOpcaoId !== item.xCampoOpcaoId);
          setLista(Lista);
          return;
        }
      }
    });
    setFieldValue("Lista", ListaSelected, true);
  };

  const handleChangeAll = (marcar: boolean) => {
    setMarcarTodos(marcar);

    Lista.forEach((item) => {
      if (!marcarTodos) {
        item.checked = true;
        ListaSelected.push(item);
        setLista(Lista);
        return;
      } else {
        item.checked = false;
        ListaSelected = ListaSelected.filter((e) => e.xCampoOpcaoId !== item.xCampoOpcaoId);
        setLista(Lista);
        return;
      }
    });
    setFieldValue("Lista", ListaSelected, true);
  };

  async function carregarListaXCampos(filtro: any) {
    try {
      setCarregandoListaCampos(true);
      setMarcarTodos(false);
      let resultado: IRecuperaLista<any>;

      resultado = await XCampoService.obterCampo(filtro);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setLista(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setLista([]);
    } finally {
      setCarregandoListaCampos(false);
    }
  }

  const colunas: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: `xCampoOpcaoId`,
      selector: (row: any) => row.xCampoOpcaoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: any) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: any) => {
        return (
          <div>
            <label>
              <Form.Check
                type="switch"
                id="custom-switch"
                style={{ cursor: 'pointer' }}
                checked={row.checked}
                onClick={() => handleChange(row.xCampoOpcaoId)}
              />
            </label>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAreaDireito>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    carregarListaXCampos(filtrosPesquisa);
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarListaXCampos(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Nome
              </label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>

            <div className="col-md-2">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Código
              </label>
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={"form-control"}
                id="form-codigo"
              />
            </div>

            <div className="col-md-2">
              <button
                type="button"
                className="btn btn-sm btn-orange search-buttom-margin "
                onClick={(e) => {
                  carregarListaXCampos(filtrosPesquisa);
                }}
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
              </button>
            </div>

            <div className="row mt-3">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Selecionar pagina atual: {"  "}
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  style={{ cursor: 'pointer' }}
                  checked={marcarTodos}
                  onChange={(e) => {
                    handleChangeAll(e.target.checked)
                  }}
                />
              </label>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoListaCamposs}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="selecoes"
            itens={Lista}
          />
        </div>
      </div>
    </>
  );
};
export default ListaXSelected;
