import styled, { css } from "styled-components";

export const Container = styled.div<{ historyMode: boolean }>`
  height: 100%;
  width: 100%;

  > div.editor-wrapper {
    height: 100%;
    width: 100%;
  }

  ${props => props.historyMode && css`
    display: flex;
    gap: 12px;
  `}
`;