import {
  CustomToolbarItemModel,
  DocumentEditor,
  ToolbarItem,
} from "@syncfusion/ej2-react-documenteditor";
import { EditorVersionAdditionalResponse } from "../../../../../interfaces/IEditorVersionResponse";
import { ToolbarClickArgs } from "../interfaces/toolbar";
import { useState } from "react";

export function useToolbar() {
  const [isExporting, setIsExporting] = useState(false);

  function onToolbarClick(args: ToolbarClickArgs, editor?: DocumentEditor) {
    if (!editor) {
      return
    }

    switch (args.item.id) {
      case 'save': 
        setIsExporting(true)
        break
      default:
        break
    }
  }

  function getItems(flags: EditorVersionAdditionalResponse | null) {
    const saveItem: CustomToolbarItemModel = {
      prefixIcon: "e-save icon",
      tooltipText: "Exportar documento",
      text: "Exportar",
      id: "save",
    };

    let items: (CustomToolbarItemModel | ToolbarItem)[] = [
      "New",
      "Open",
      saveItem,
      "Separator",
      "Undo",
      "Redo",
      "Separator",
      "Image",
      "Table",
      "Hyperlink",
      "Bookmark",
      "TableOfContents",
      "Separator",
      "Header",
      "Footer",
      "TrackChanges",
      "PageSetup",
      "PageNumber",
      "Break",
      "InsertFootnote",
      "InsertEndnote",
      "Separator",
      "Find",
      "Separator",
      "Comments",
      "Separator",
      "LocalClipboard",
      "RestrictEditing",
      "Separator",
      "FormFields",
      "UpdateFields",
      "ContentControl", 
    ];

    if (flags?.ocultarMenuArquivoAbrir) {
      items = items.filter((item) => item !== "Open");
    }

    if (flags?.ocultarMenuInserir) {
      items = items.filter((item) => item !== "New");
    }

    if (flags?.ocultarMenuArquivoSalvar) {
      items = items.filter(
        (item) => (item as CustomToolbarItemModel).id !== "save"
      );
    }

    if (flags?.habilitarTrackChanges) {
      items = items.filter(item => item !== 'TrackChanges')
    }

    return items;
  }

  return {
    toolbarItems: getItems,
    onToolbarClick,
    isExporting,
    setIsExporting
  }
}
