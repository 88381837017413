import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ConfiguracaoModeloRelatorioService from "../../../services/ConfiguracaoModeloRelatorioService";
import Swal from "sweetalert2";
import IModeloRelatorio from "../../../interfaces/IModeloRelatorio";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import { ICampo } from "../../../interfaces/ICampo";
import DragDropRelatorio from "./components/DragDropRelatorio";
import ICampoTipos from "../../../interfaces/ICampoTipos";
import RelatorioLabelModal from "./RelatorioParametrizacaoLabelModal";

const RelatorioParametrizacaoConfig = () => {
  const [loading, setLoading] = useState(false);
  const [validacaoFases, setValidacaoFases] = useState(true);
  const [modal, setModal] = useState(false);
  const [campoModal, setCampoModal] = useState<{
    id: string;
    label?: string;
  }>({ id: "", label: "" });
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [campos, setCampos] = useState<ICampo[]>([]);
  const [tipoCampos, setTipoCampos] = useState<ICampoTipos[]>([]);
  const [camposRelatorio, setCamposRelatorio] = useState<
    { id: string; label?: string; fase?: number }[]
  >([]);
  const [relatorio, setRelatorio] = useState<IModeloRelatorio>({
    modeloRelatorioCampos: [],
    modeloRelatorioId: 0,
    modulo: 0,
    nome: "",
    status: 0,
    tipo: 0,
    extensao: "",
    tipoRelatorioId: 0,
    idUnico: "",
  });

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const carregarRelatorio = async () => {
    try {
      setLoading(true);

      if (params.id) {
        const data =
          await ConfiguracaoModeloRelatorioService.obterModeloRelatorio({
            modeloRelatorioId: params.id,
          });

        const mappedData = data.data[0].modeloRelatorioCampos.map((campo) => ({
          ...campo,
          idUnico: `${campo.camposId}${campo.tabelaOrigem}`,
        }));

        const mappedRelatorioCampos = mappedData.map((campo) => ({
          id: campo.idUnico,
          label: campo.label,
          fase: campo.fase,
        }));

        setRelatorio({ ...data.data[0], modeloRelatorioCampos: mappedData });
        setCamposRelatorio(mappedRelatorioCampos);

        await carregarCampos(
          data.data[0].modulo,
          data.data[0].tipoRelatorioId ?? 0
        );
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros do Modelo de Relatório",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const carregarCampos = async (moduloId: number, tipoId: number) => {
    try {
      setLoading(true);

      const data = await ConfiguracaoTelaService.obterCamposRelatorio(
        moduloId,
        tipoId
      );

      const mappedData = data.data.map((campoTipo) => ({
        tipo: campoTipo.tipo,
        tabelaOrigem: campoTipo.tabelaOrigem,
        campos: campoTipo.campos.map((campo) => ({
          ...campo,
          idUnico: `${campo.campo}${campo.tabelaOrigem}`,
        })),
      }));

      const camposGeral: ICampo[] = [];

      mappedData.map((campoTipo) =>
        campoTipo.campos.map((campo) => camposGeral.push(campo))
      );

      setTipoCampos(mappedData);
      setCampos(camposGeral);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos Campos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmar = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    try {
      setLoadingEnvio(true);

      const relatorioFilter = campos
        .filter((campo) =>
          camposRelatorio.find((campoRelatorio) => {
            return campoRelatorio.id === campo.idUnico;
          })
        )
        .map((relatorioMap, index) => {
          const campoRelatorio = camposRelatorio.find(
            (campoRelatorio) => campoRelatorio.id === relatorioMap.idUnico
          );

          return {
            camposId: relatorioMap.campo,
            ordenacao: index,
            label: campoRelatorio?.label ?? relatorioMap.label,
            tabelaOrigem: relatorioMap.tabelaOrigem,
            extensao: relatorio.extensao,
            fase: campoRelatorio?.fase ?? undefined,
          };
        });

      const putObject = {
        modeloRelatorioId: relatorio.modeloRelatorioId,
        nome: relatorio.nome,
        modulo: relatorio.modulo,
        tipo: relatorio.tipoRelatorioId ?? 0,
        modeloRelatorioCampos: relatorioFilter,
      };

      await ConfiguracaoModeloRelatorioService.adicionarCampos(putObject);

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Modelo de Relatório atualizado com sucesso.`,
        showConfirmButton: false,
        timer: 3000,
      });

      navigate("/Relatorio/ConfiguracaoRelatorio");
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível atualizar o Modelo de Relatório",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoadingEnvio(false);
    }
  };

  const toggleModal = (campo: { id: string; label?: string }) => {
    setModal(!modal);
    setCampoModal(campo);
  };

  const validarFases = () => {
    const relatorioFilter = campos
      .filter((campo) =>
        camposRelatorio.find((campoRelatorio) => {
          return campoRelatorio.id === campo.idUnico;
        })
      )
      .map((relatorioMap) => {
        const campoRelatorio = camposRelatorio.find(
          (campoRelatorio) => campoRelatorio.id === relatorioMap.idUnico
        );

        return {
          camposId: relatorioMap.campo,
          label: campoRelatorio?.label ?? relatorioMap.label,
          tabelaOrigem: relatorioMap.tabelaOrigem,
          fase: campoRelatorio?.fase ?? undefined,
        };
      });

    const validacao = relatorioFilter.filter(
      (campo) =>
        campo.tabelaOrigem === 7 &&
        (campo.fase === -1 || typeof campo.fase === "undefined")
    );

    setValidacaoFases(validacao.length > 0);
  };

  useEffect(() => {
    if (params.id && parseInt(params.id) > 0) {
      carregarRelatorio();
    }
  }, [params.id]);

  useEffect(() => {
    validarFases();
  }, [camposRelatorio]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <LayoutPageTitle title={"Configuração do Relatório: " + relatorio.nome} />

      <RelatorioLabelModal
        exibirModal={modal}
        handleClose={toggleModal}
        campo={campoModal}
        camposRelatorio={camposRelatorio}
        setCamposRelatorio={setCamposRelatorio}
        campos={campos}
      />

      <div className="d-flex justify-content-between mb-3">
        <div>
          <button
            type="button"
            className="btn btn-orange d-flex align-items-center"
            onClick={handleConfirmar}
            disabled={loadingEnvio || validacaoFases}
          >
            Confirmar?
            {loadingEnvio ? (
              <span
                className="indicator-progress text-white"
                style={{ display: "block", marginLeft: "5px" }}
              >
                <span className="spinner-border spinner-border-sm align-middle" />
              </span>
            ) : (
              <FontAwesomeIcon
                color={"white"}
                className="mx-2"
                icon={faPaperPlane}
              />
            )}
          </button>
          {validacaoFases && (
            <div className="fv-plugins-message-container mt-1">
              <div className="fv-help-block">
                <span role="alert">
                  Selecione a fase de todos os Campos do tipo Valores
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        {loading && (
          <DragDropRelatorio
            loading={true}
            campos={campos}
            camposRelatorio={relatorio.modeloRelatorioCampos}
            camposRelatorioCustom={camposRelatorio}
            setCamposRelatorio={setCamposRelatorio}
            tipoCampos={tipoCampos}
            toggleModal={toggleModal}
          />
        )}

        {!loading && (
          <DragDropRelatorio
            loading={false}
            campos={campos}
            camposRelatorio={relatorio.modeloRelatorioCampos}
            camposRelatorioCustom={camposRelatorio}
            setCamposRelatorio={setCamposRelatorio}
            tipoCampos={tipoCampos}
            toggleModal={toggleModal}
          />
        )}
      </div>
    </div>
  );
};

export default RelatorioParametrizacaoConfig;
