import api from "./Api";
import IEmpresa from "../interfaces/IEmpresa";
import IAdvogado from "../interfaces/IAdvogado";
import ICentroCusto from "../interfaces/ICentroCusto";

import { EModulo, ETipoCampoEnum } from "../enum";
import { routePropriedade } from "../utils/Utils";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IParteContraria from "../interfaces/IParteContraria";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import { IUpdatePartesList } from "../interfaces/Requests/Processo/IUpdatePartesList";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class ParteContrariaService {

    public async obterParteContraria(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoParteContraria/RecuperaTipoParteContraria?${query}`);

        return result;
    }

    public async obterParteContrariaLimitada(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? 'status=-1&status=1' : 'status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoParteContraria/RecuperaListaLimitada?${query}`);

        return result;
    }


    async obterListaLimitadaCadastroCampo(
        controller?: string,
        categoriaId?: number,
        formaParticipacaoId?: number,
        campo?: number,
    ): Promise<IRecuperaLista<any>> {
        let query = "";

        if (campo) query += `&campo=${campo}`;

        query += `&tipoParteContrariaId=${categoriaId}`;

        query += `&papelSignatarioId=${formaParticipacaoId}`;

        let { data: result } = await api.get<IRecuperaLista<any>>(`${controller}/RecuperaListaLimitada?modulo=${EModulo.ParteContraria}${query}`);

        return result;
    }

    async adicionaParteContrariaList(partesContrariasList: IParteContraria[], objetoId: number, entidade: string) {
        const partesContrariasRequestList: any[] = [];
        partesContrariasList.forEach((parteContraria) => {
            let requestParteContraria: any = {};
            requestParteContraria[`${entidade}Id`] = objetoId;
            if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
            if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
            if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
            if (parteContraria.cargoId) requestParteContraria.cargoId = parteContraria.cargoId;
            if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
            if (parteContraria.tipoCentroCustoId) requestParteContraria.tipoCentroCustoId = parteContraria.tipoCentroCustoId;
            if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
            if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
            if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
            if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
            if (parteContraria.ctps) requestParteContraria.ctps = parteContraria.ctps;
            if (parteContraria.dataAdmissao) requestParteContraria.dataAdmissao = parteContraria.dataAdmissao;
            if (parteContraria.dataDemissao) requestParteContraria.dataDemissao = parteContraria.dataDemissao;
            if (parteContraria.dataNascimento) requestParteContraria.dataNascimento = parteContraria.dataNascimento;
            if (parteContraria.departamentoId) requestParteContraria.departamentoId = parteContraria.departamentoId;
            if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
            if (parteContraria.email) requestParteContraria.email = parteContraria.email;
            if (parteContraria.empresaContratante) requestParteContraria.empresaContratante = parteContraria.empresaContratante;
            if (parteContraria.empresaTerceira) requestParteContraria.empresaTerceira = parteContraria.empresaTerceira;
            if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
            if (parteContraria.estadoCivilId) requestParteContraria.estadoCivilId = parteContraria.estadoCivilId;
            if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
            if (parteContraria.papelSignatarioId) requestParteContraria.papelSignatarioId = parteContraria.papelSignatarioId;
            if (parteContraria.formaParticipacaoId) requestParteContraria.formaParticipacaoId = parteContraria.formaParticipacaoId;
            if (parteContraria.formaParticipacaoNome) requestParteContraria.formaParticipacaoNome = parteContraria.formaParticipacaoNome;
            if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
            if (parteContraria.matricula) requestParteContraria.matricula = parteContraria.matricula;
            if (parteContraria.moedaId) requestParteContraria.moedaId = parteContraria.moedaId;
            if (parteContraria.motivoDesligamentoId) requestParteContraria.motivoDesligamentoId = parteContraria.motivoDesligamentoId;
            if (parteContraria.nacionalidade) requestParteContraria.nacionalidade = parteContraria.nacionalidade;
            if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
            if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
            if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
            if (parteContraria.numeroContrato) requestParteContraria.numeroContrato = parteContraria.numeroContrato;
            if (parteContraria.observacoesContratacao) requestParteContraria.observacoesContratacao = parteContraria.observacoesContratacao;
            if (parteContraria.observacoesDesligamento) requestParteContraria.observacoesDesligamento = parteContraria.observacoesDesligamento;
            if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
            if (parteContraria.orgaoRegistroId) requestParteContraria.orgaoRegistroId = parteContraria.orgaoRegistroId;
            if (parteContraria.pisPasep) requestParteContraria.pisPasep = parteContraria.pisPasep;
            if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
            if (parteContraria.regimeContratacao) requestParteContraria.regimeContratacao = parteContraria.regimeContratacao;
            if (parteContraria.rg) requestParteContraria.rg = parteContraria.rg;
            if (parteContraria.rgOrgao) requestParteContraria.rgOrgao = parteContraria.rgOrgao;
            if (parteContraria.statusFolhaId) requestParteContraria.statusFolhaId = parteContraria.statusFolhaId;
            if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
            if (parteContraria.tipoContratacao) requestParteContraria.tipoContratacao = parteContraria.tipoContratacao;
            if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
            if (parteContraria.ultimoSalario) requestParteContraria.ultimoSalario = parteContraria.ultimoSalario;
            partesContrariasRequestList.push(requestParteContraria);
        });

        let { data: result } = await api.post<any>(`${entidade}/AdicionaParteContraria`, partesContrariasRequestList);

        return result;

    }

    public async obterCapaParteContraria(parteContrariaId: number, controller: string): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {

        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/${controller}/RecuperaCapa${controller}ParteContraria?${controller}parteContrariaId=${parteContrariaId}`);

        return result;
    }

    async obterXOptions(filtros: any): Promise<IRecuperaLista<any>> {
        let query = "";

        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';

        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';

        query += filtros.tipoContratoId ? `tipoImovelId=${filtros.tipoContratoId}&` : '';

        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoParteContrariaId=${filtros.tipoParteContrariaId}`;

        let { data: result } = await api.get<IRecuperaLista<any>>(`XCampos/RecuperaListaLimitada?${query}`);

        return result;
    }

    async obterOptions(filtros: any): Promise<IRecuperaLista<any>> {
        let query = "";


        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';

        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';

        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';

        query += filtros.tipoContratoId ? `&tipoContratoId=${filtros.tipoContratoId}` : '';

        query += `&tipoParteContrariaId=${filtros.categoriaId}`

        let { data: result } = await api.get<IRecuperaLista<any>>(`${filtros.controller}/RecuperaListaLimitada?modulo=${filtros.modulo}${query}`);

        return result;
    }

    async obterAdvogadosInterno(objetoId: number, entidade: string): Promise<IRecuperaLista<IPartesProcesso>> {
        let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/${entidade}/RecuperaAdvogadoInternoPorId?${entidade}Id=${objetoId}`);

        return result;
    }

    async obterCentroCusto(objetoId: number, entidade: string): Promise<IRecuperaLista<ICentroCusto>> {
        let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`/${entidade}/RecuperaCentroCustoPorId?${entidade}Id=${objetoId}`);
        return result;
    }

    async alterarPorCampo(campo: string, valor: any, parteContrariaId: number, entidade: string) {
        let request: any = [];

        if (valor) {

            request.push({ op: "replace", path: "/" + campo, value: valor });

            let { data: result } = await api.patch<any>(`${entidade}/AtualizaParteContrariaPorId?id=${parteContrariaId}`, request);

            return result;
        }
    }

    async alterarCampoPrincipal(campo: string, valor: any, parteContrariaId: number, entidade: string) {

        let request: any = [];

        request.push({ op: "replace", path: "/" + campo, value: valor });

        let { data: result } = await api.patch<any>(`${entidade}/AtualizaParteContrariaPorId?id=${parteContrariaId}`, request);

        return result;
    }

    async alterarPorXCampo(campo: string, valor: any, xcampoid: number, entidade: string) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`${entidade}/AtualizaXCampoValor?id=${xcampoid}`, request);
            return result;
        }
    }

    async alterarPorXCampoMulti(XOpcoes: any, xcampoid: number, entidade: string, objetoId: number,) {
        let request: any = {};

        request[`${entidade}Id`] = objetoId;

        request.campoId = xcampoid;

        request.xCampoOpcaoIdList = [];

        XOpcoes.forEach((opcao: any) => { request.xCampoOpcaoIdList.push(opcao.value); });

        let result = await api.post<any>(`${entidade}/AtualizaXCampoValorListaMulti?id=${xcampoid}`, request);

        return result;
    }

    async adicionarPorXCampoValor(campo: number, valor: any, xCampoOpcaoId: number, objetoId: number, entidade: string, tipo: ETipoCampoEnum) {
        let request: any;

        let objeto: any = {};

        objeto[`${entidade}Id`] = objetoId;

        switch (tipo) {

            case ETipoCampoEnum.Data:
                objeto.data = valor;
                break;

            case ETipoCampoEnum.Texto:
            case ETipoCampoEnum.AreaTexto:
                objeto.texto = valor;
                break;

            case ETipoCampoEnum.Lista:
            case ETipoCampoEnum.EnumLista:
                objeto.lista = valor;
                break;

            case ETipoCampoEnum.Inteiro:
                objeto.inteiro = valor;
                break;

            case ETipoCampoEnum.Numerico:
                objeto.numerico = valor;
                break;

            default:
                objeto.valor = valor;
                break;
        }

        if (xCampoOpcaoId > 0)
            request = {
                campoId: campo,
                xCampoOpcaoId: xCampoOpcaoId,
                ...objeto,
            };
        else
            request = {
                campoId: campo,
                ...objeto,
            }

        let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);

        return result;
    }

    async adicionarXCampoValor(request: any) {
        let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);

        return result;
    }

    async alterarPorXCampoBolleano(campo: string, valor: any, xcampoid: number, entidade: string) {
        let request: any = [];

        request.push({ op: "replace", path: "/" + campo, value: valor });
        await api.patch<any>(`${entidade}/AtualizaXCampoValor?id=${xcampoid}`, request);
    }

    async alterarPorCampoBolleano(campo: string, valor: any, objetoId: number, entidade: string) {
        let request: any = [];

        request.push({ op: "replace", path: "/" + campo, value: valor });
        await api.patch<any>(`${entidade}/AtualizaParteContrariaPorId?id=${objetoId}`, request);
    }

    async alterarPorCentroCustoList(centrosCusto: ICentroCusto[], objetoId: number, entidade: string) {
        if (centrosCusto.length > 0) {
            let objeto: any = {};
            objeto[`${entidade}Id`] = objetoId;
            await api.put<any>(`${entidade}/AtualizaCentroCusto`, {
                ...objeto,
                centrosCusto: centrosCusto,
            });
        }
    }

    async alterarPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], objetoId: number, entidade: string) {

        if (advogadoInternoList.filter((e) => e.checked).length > 0) {
            const advogadoInternoListRequest: any[] = [];
            advogadoInternoList
                .filter((e) => e.checked)
                .forEach((item) => {
                    let advogado: any = {
                        advogadoInternoId: item.advogadoInternoId,
                        principal: item.principal,
                        codigo: item.codigo,
                    };
                    advogadoInternoListRequest.push(advogado);
                });

            if (advogadoInternoListRequest.length > 0) {
                let objeto: any = {};
                objeto[`${entidade}Id`] = objetoId;
                const request: IUpdatePartesList = {
                    ...objeto,
                    advogadoInternoList: advogadoInternoListRequest,
                };
                await api.put<any>(`${entidade}/AtualizaParteContrariaPorId`, request);
            }
        }
    }

    async alterarPorAdvogadoList(advogadoList: IAdvogado[], objetoId: number, entidade: string) {
        if (advogadoList.filter((e) => e.checked).length > 0) {
            const advogadoListRequest: any[] = [];
            advogadoList
                .filter((e) => e.checked)
                .forEach((item) => {
                    let advogado: any = {
                        advogadoId: item.advogadoId,
                        codigo: item.codigo,
                    };
                    advogadoListRequest.push(advogado);
                });

            if (advogadoListRequest.length > 0) {
                let objeto: any = {};
                objeto[`${entidade}Id`] = objetoId;
                const request: any = {
                    ...objeto,
                    advogadoList: advogadoListRequest,
                };
                await api.put<any>(`${entidade}/AtualizaParteContrariaPorId`, request);
            }
        }
    }

    async alterarPorEmpresaList(empresasList: IEmpresa[], objetoId: number, entidade: string, rateio?: boolean) {
        if (empresasList.filter((e) => e.checked).length > 0) {
            const empresasRequestList: any[] = [];
            let objeto: any = {};
            empresasList
                .filter((e) => e.checked)
                .forEach((item) => {
                    if (rateio) {
                        if (item.gestaoProcessoId) objeto.gestaoProcessoId = item.gestaoProcessoId;
                        if (item.percentual) objeto.percentual = item.percentual;
                    }
                    let empresa: any = {
                        empresaId: item.empresaId,
                        codigo: item.codigo,
                        principal: item.principal,
                        formaParticipacaoId: item.formaParticipacaoId,
                        ...objeto
                    };
                    empresasRequestList.push(empresa);
                });

            if (empresasRequestList.length > 0) {
                let objeto: any = {};
                objeto[`${entidade}Id`] = objetoId;
                const request: any = {
                    ...objeto,
                    empresasList: empresasRequestList,
                };
                await api.put<any>(`${entidade}/AtualizaParteContrariaPorId`, request);
            }
        }
    }

    async adicionaXCampoValorTabela(linhas: any, entidade: string, objetoId: number): Promise<void> {
        let objeto: any = {};
        objeto[`${entidade}Id`] = objetoId;
        await api.post<any>(`${entidade}/AdicionaXCampoValor`, {
            ...objeto, xCampoValorTabelaCreateDto: linhas, campoId: linhas?.find((e: any) => e)?.campoId
        });
    }

    async removerXCampoValorTabelaPorLinha(xCampoValorTabelaId: number, modulo: EModulo): Promise<void> {
        if (xCampoValorTabelaId) {
            await api.post<void>(`${routePropriedade(modulo)}/RemoveXCampoValorTabela`, [xCampoValorTabelaId]);
        }
    }

    async removerXCampoValorTabelaPorLinhas(linhas: any, entidade: string): Promise<void> {
        linhas.forEach(async (linha: any) => {
            if (linha.xCampoValorTabelaId) {
                await api.post<void>(`${entidade}/RemoveXCampoValorTabela`, [linha.xCampoValorTabelaId]);
            }
        });
    }

    async removerXCampoValorTabelaPorColuna(colunas: any, entidade: string): Promise<void> {
        colunas.forEach(async (linhas: any) => {
            linhas.forEach(async (linha: any) => {
                if (linha.xCampoValorTabelaId)
                    await api.post<void>(`${entidade}/RemoveXCampoValorTabela`, [linha.xCampoValorTabelaId]);
            });
        });
    }

    async alterarXCampoValorTabelaPorLinhas(linhas: any, entidade: string): Promise<void> {
        if (linhas?.length > 0) await api.post<void>(`${entidade}/AdicionaLinhaXCampoValorTabela`, linhas);
    }

    async alterarXCampoValorTabelaPorColuna(linhas: any, entidade: string, objetoId: number): Promise<void> {

        let addCampo: any[] = [];
        let addLinha: any[] = [];

        linhas.forEach((item: any) => {
            if (item.colunas) {
                item.colunas.forEach((coluna: any) => {
                    if (!coluna.xCampoValorTabelaId) {
                        if (!coluna.xCampoValorId) {
                            addCampo.push(coluna);
                        } else {
                            addLinha.push(coluna);
                        }

                    }
                });
            }
        });

        if (addLinha.length > 0) {
            await api.post<void>(`${entidade}/AdicionaLinhaXCampoValorTabela`, addLinha);
        }

        if (addCampo.length > 0) {
            await this.adicionaXCampoValorTabela(addCampo, entidade, objetoId);
        }
    }

}


export default new ParteContrariaService();