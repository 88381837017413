import { faCheckCircle, faEraser, faPen, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "../../components/Comum/GridPadrao";
import IPais from "../../interfaces/IPais";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaPaisRequest from "../../interfaces/Requests/Pais/FiltroPesquisaPaisRequest";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import PaisService from "../../services/PaisService";
import PaisModal from "./PaisModal";

const PaisPage = () => {
    const [pais, setPais] = useState<IPais>();
    const [paiss, setPaiss] = useState<IPais[]>([]);
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoPais, setCarregandoPais] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaPaisRequest>({
        nome: '',
        codigo: '',
        ddi: '',
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'id'
    });

    function toggleModal(carregarDados?: boolean): void {
        if (carregarDados) {
            carregarPais(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);

        setTimeout(() => {
            setPais(undefined)
        }, 300)
    }

    async function carregarPais(filtro: FiltroPesquisaPaisRequest) {
        try {
            setCarregandoPais(true);
            let resultado: IRecuperaLista<IPais>;

            resultado = await PaisService.obterPais(filtro);

            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
            setPaiss(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setPaiss([]);
        }
        finally {
            setCarregandoPais(false);
            setLoadingInicial(false);
        }
    }

    async function alterarStatus(paisId: number, status: number) {
        try {
            const swalWithBootstrapButtons = Swal.mixin({
                heightAuto: false,
                customClass: {
                    confirmButton: 'btn btn-orange',
                    cancelButton: 'btn btn-danger ms-5'
                },
                buttonsStyling: false
            });

            let result = await swalWithBootstrapButtons.fire({
                title: 'Alterar Status ',
                text: `Você realmente deseja alterar o status?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Confirmar`
            });

            if (result.isConfirmed) {
                await PaisService.alterarStatus(paisId, status);

                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Status do País alterado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                })

                await limparFiltros();

                setFiltrosPesquisa(oldState => {
                    carregarPais(oldState);
                    return oldState;
                })
            }

        } catch (error: any) {
            let mensagemErro = 'Ocorreu um erro inesperado';

            if (error?.response?.data?.Message) {
                mensagemErro = error.response.data.Message
            }

            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel alterar o Status do País`,
                text: mensagemErro,
                showConfirmButton: true
            });
        }
    }

    const colunas: TableColumn<IPais>[] = [
        {
            name: 'Id',
            sortField: 'paisId',
            selector: (row: IPais) => row.paisId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Nome',
            sortField: 'paisNome',
            selector: (row: IPais) => row.nome,
            sortable: true,
            wrap: true
        },
        {
            name: 'Código',
            sortField: 'codigo',
            selector: (row: IPais) => row.codigo,
            sortable: true,
            wrap: true
        },
        {
            name: 'DDI',
            sortField: 'ddi',
            selector: (row: IPais) => row.ddi,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (pais: IPais) => {
                return (
                    <div>
                        <FontAwesomeIcon title="Editar País" onClick={() => {
                            setPais(pais);
                            setExibirModal(true);
                        }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />

                        {pais.status === 'Ativo' ?
                            <FontAwesomeIcon title="Desativar País" onClick={() => {
                                alterarStatus(pais.paisId, -1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faTrashAlt} />
                            :
                            <FontAwesomeIcon title="Ativar País" onClick={() => {
                                alterarStatus(pais.paisId, 1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faCheckCircle} />
                        }
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ]

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<IPais>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };

    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                nome: '',
                codigo: '',
                ddi: '',
                status: 1
            }
        });
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarPais(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

    return (<>
        <LayoutPageTitle title="País">
            <button onClick={() => {
                setPais(undefined);
                setExibirModal(true);
            }} className="btn btn-md btn-orange">
                Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        <PaisModal exibirModal={exibirModal} titulo={pais ? 'Editar Registro' : 'Criar Registro'} toggleModal={toggleModal} pais={pais} />

        <div className="row mt-2">
            <div className="col-md-12">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    carregarPais(filtrosPesquisa);
                }} className="row g-3 mb-3">
                    <div className="col-md-2 ">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                        <input value={filtrosPesquisa.nome} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, nome: e.target.value } });
                        }} placeholder="Nome" type="text" className={'form-control'} id="form-nome" />
                    </div>
                    <div className="col-md-2 ">
                        <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código:</label>
                        <input value={filtrosPesquisa.codigo} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, codigo: e.target.value } });
                        }} placeholder="Código" type="text" className={'form-control'} id="form-codigo" />
                    </div>
                    <div className="col-md-2 ">
                        <label htmlFor="form-ddi" className="form-label fw-bolder text-orange">DDI:</label>
                        <input value={filtrosPesquisa.ddi} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, ddi: e.target.value } });
                        }} placeholder="DDI" type="text" className={'form-control'} id="form-ddi" />
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Status:</label>
                        <select value={filtrosPesquisa.status} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                        }} placeholder="Nome" className={'form-select'} id="form-select" >
                            <option value="0" label="Todos"> Todos </option>
                            <option value="1" label="Ativo"> Ativo </option>
                            <option value="-1" label="Inativo"> Inativo </option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <div>
                            <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                                <FontAwesomeIcon color='white' className='mx-2' icon={faSearch} />
                            </button>
                            <button onClick={(e) => {
                                e.preventDefault();
                                limparFiltros();
                            }} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                                <FontAwesomeIcon color='white' className='mx-2' icon={faEraser} />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-12 mb-10">
                <GridPadrao onSort={handleSort} progressPending={carregandoPais} limit={filtrosPesquisa.limit} onChangePage={handlePageChange} onChangeRowsPerPage={handlePerRowsChange} paginationServer={true} paginationTotalRows={filtrosPesquisa.totalItems} colunas={colunas} tipo='País' itens={paiss} />
            </div>
        </div>
    </>)
}

export default PaisPage;