import { useContext, useEffect, useRef, useState } from "react";
import { faPrint, faShare, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";

import { defaultSchema } from "../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaDesdobramentoRequest } from "../../interfaces/Requests/Desdobramento/AdicionaDesdobramentoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../enum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import DesdobramentoService from "../../services/DesdobramentoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCapaSolucoes from "../../components/Comum/Solucao/CamposCapaSolucoes";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";

const DesdobramentoCapaPage = () => {
  const { desdobramento, tipoDesdobramento, setDesdobramento } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);

  const componentRef = useRef<any>();

  const compartilharWhatSapp = () => {
    return `Número do Desdobramento: *#${desdobramento.desdobramentoId}* / ${tipoDesdobramento.tipoDesdobramentoId > 0
      ? "Tipo de Desdobramento: *" + tipoDesdobramento.nome + "*"
      : ""
      }  / Status: *${desdobramento.statusDesdobramentoNome == ""
        ? "Ativo*"
        : nomearStatusDesdobramento(desdobramento.statusDesdobramentoNome)
      }*`;
  };

  const nomearStatusDesdobramento = (categoria: string) => {
    switch (categoria) {
      case "EmElaboracao":
        return "Em Elaboração";
      case "EmAprovacao":
        return "Em Aprovação";
      case "EmAssinatura":
        return "Em Assinatura";
      case "Assinado":
        return "Assinado";
      case "Cancelado":
        return "Cancelado";
      default:
        return "Não definido";
    }
  };

  const initialValues: AdicionaDesdobramentoRequest = {
    desdobramentoId: desdobramento.desdobramentoId,
    principalId: desdobramento.desdobramentoId,
    tipoDesdobramentoId: desdobramento.tipoDesdobramentoId,

    modulo: EModulo.Desdobramento,
    tela: ETelaEnum.TelaCapaDesdobramento,
    categoriaId: desdobramento.categoriaId || 0,

    processoId: desdobramento.processoId || 0,
    areaDireitoId: desdobramento.areaDireitoId || 0,

    imovelId: desdobramento.imovelId || 0,
    tipoImovelId: desdobramento.tipoImovelId || 0,

    codigo: "",
    cidadeId: 0,
    datadistribuicao: null,
    estadoId: 0,
    instanciaId: 0,
    numero: "",
    responsaveldesdobramentoId: 0,
    resumo: "",
    dataprotocolo: null,

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!desdobramento.desdobramentoId) return;

    carregaCapa();

    formik.setValues(initialValues);

  }, [desdobramento.desdobramentoId, refreshCapa]);

  useEffect(() => {
    if (!desdobramento.desdobramentoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [desdobramento]);


  const inserirSessionStorage = () => sessionStorageService.inserir(desdobramento, "desdobramento_capa");


  const carregarSessionStorage = async () => setDesdobramento(JSON.parse((await sessionStorageService.obter("desdobramento_capa")) || null));


  const carregaCapa = async () => {
    try {
      if (!desdobramento.desdobramentoId) return;

      setCarregandoConfiguracoesTela(true);
      let resultado = await DesdobramentoService.obterCapaDesdobramento(desdobramento.desdobramentoId);
      setConfiguracoesTela(resultado.data);
      setCarregandoConfiguracoesTela(false);
    } catch (error: any) {
      console.log(error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (desdobramentoId: number) => {
    try {
      let resultado = await DesdobramentoService.adicionaFavorido(desdobramentoId);
      setDesdobramento({ ...desdobramento, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await DesdobramentoService.removerFavorido(favoritoId);
      setDesdobramento({ ...desdobramento, favoritoId: 0 });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaSolucoes
          controller={"desdobramento"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${desdobramento.desdobramentoId}  ${tipoDesdobramento.tipoDesdobramentoId > 0 ? " - " + tipoDesdobramento.nome : ""
            } => ${desdobramento.statusDesdobramentoNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a
                style={{ cursor: "pointer", fontSize: "30px" }}
                onClick={() => setRefreshCapa(!refreshCapa)}
              >
                {
                  <FontAwesomeIcon
                    color={"#DC853D"}
                    className="mx-2"
                    icon={faHistory}
                  />
                }
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span
                className="indicator-progress"
                style={{
                  display: "block",
                  fontSize: "30px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faPrint}
                  title="Imprimir o desdobramento"
                />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {
              <FontAwesomeIcon
                color={"#DC853D"}
                className="mx-2"
                icon={faShare}
              />
            }
          </a>

          {desdobramento.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(desdobramento.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o desdobramento"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(desdobramento.desdobramentoId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o desdobramento"
            ></a>
          )}

          <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="bottom"
            overlay={desdobramento.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}
          >
            <a
              style={{ cursor: "pointer", marginRight: "10px" }}
              className="navigation-services-list-link"
            >
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderCampos()}
            <CompromissoListField modulo={EModulo.Desdobramento} setRefreshCapa={setRefreshCapa}
            />
            <FileListCapaField modulo={EModulo.Desdobramento} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
          </>
        )}

      </div>
    </>
  );
};
export default DesdobramentoCapaPage;
