import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      background-color: transparent;
      border: none;
      font-size: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .insert-html {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;

    > textarea {
      width: 100%;
      max-height: 500px;
      min-height: 200px;
      padding: 10px;
    }

    > button {
      align-self: end;
    }
  }
`;

export const Card = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: transparent;
  width: 50%;
  padding: 20px 0;
  border: none;

  border-radius: 4px;
  border-left: 4px solid var(--bs-orange);

  transition: box-shadow 0.4s ease;

  > svg {
    color: var(--bs-orange);
  }

  &:hover {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
  }
`;