import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IFechamentoConfiguracao from "../interfaces/IFechamentoConfiguracao";
import FiltroPesquisaFechamentoConfiguracaoRequest from "../interfaces/Requests/Fechamento/FiltroPesquisaFechamentoConfiguracaoRequest";
import { AdicionaFechamentoConfiguracaoRequest } from "../interfaces/Requests/FechamentoConfiguracao/AdicionaFechamentoConfiguracaoRequest";
import { EditarFechamentoConfiguracaoRequest } from "../interfaces/Requests/FechamentoConfiguracao/EditarFechamentoConfiguracaoRequest";
import { AdicionaFechamentoConfiguracaoPropriedadeRequest } from "../interfaces/Requests/FechamentoConfiguracaoPropriedade/AdicionaFechamentoConfiguracaoPropriedadeRequest";

class FechamentoConfiguracaoService {

    async obterFechamentoConfiguracaos(filtros: FiltroPesquisaFechamentoConfiguracaoRequest): Promise<IRecuperaLista<IFechamentoConfiguracao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFechamentoConfiguracao>>(`Fechamento/RecuperaFechamentoConfiguracao?${query}`);

        return result;
    }

    async adicionaFechamentoConfiguracao(adicionaFechamentoConfiguracao: AdicionaFechamentoConfiguracaoRequest): Promise<IFechamentoConfiguracao> {
        let { data: result } = await api.post<IFechamentoConfiguracao>(`Fechamento/AdicionaFechamentoConfiguracao`, adicionaFechamentoConfiguracao);

        return result;
    }

    async atualizarFechamentoConfiguracao(editarFechamentoConfiguracao: EditarFechamentoConfiguracaoRequest): Promise<void> {

        let dataFechamentoConfiguracao = [
            { "op": "replace", "path": "/nome", "value": editarFechamentoConfiguracao.nome },
            { "op": "replace", "path": "/codigo", "value": editarFechamentoConfiguracao.codigo }
        ]

        await Promise.all([
            await api.patch<void>(`Fechamento/AtualizaFechamentoConfiguracaoPorId?id=${editarFechamentoConfiguracao.fechamentoConfiguracaoId}`, dataFechamentoConfiguracao)
        ]);
    }

    async adicionaFechamentoConfiguracaoPropriedade(adicionaFechamentoConfiguracaoPropriedade: AdicionaFechamentoConfiguracaoPropriedadeRequest): Promise<void> {

        await Promise.all([
            await api.post<IFechamentoConfiguracao>(`Fechamento/AtualizaPropriedadesFechamentoConfiguracao`, adicionaFechamentoConfiguracaoPropriedade)
        ]);
    }
    
    

    async alterarStatus(fechamentoConfiguracaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IFechamentoConfiguracao>(`Fechamento/AtualizaFechamentoConfiguracaoPorId?id=${fechamentoConfiguracaoId}`, data);
    }
    

}
export default new FechamentoConfiguracaoService();