import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoHonorario from "../interfaces/ITipoHonorario";
import { AdicionaTipoHonorarioRequest } from "../interfaces/Requests/TipoHonorario/AdicionaTipoHonorarioRequest";
import FiltroPesquisaTipoHonorarioRequest from "../interfaces/Requests/TipoHonorario/FiltroPesquisaTipoHonorarioRequest";
import { EditarTipoHonorarioRequest } from "../interfaces/Requests/TipoHonorario/EditarTipoHonorarioRequest";

class TipoHonorarioService {

    async obterTipoHonorarios(filtros: FiltroPesquisaTipoHonorarioRequest): Promise<IRecuperaLista<ITipoHonorario>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoHonorario>>(`TipoHonorario/RecuperaTipoHonorario?${query}`);

        return result;
    }

    async adicionaTipoHonorario(adicionaTipoHonorario: AdicionaTipoHonorarioRequest): Promise<ITipoHonorario> {
        let { data: result } = await api.post<ITipoHonorario>(`TipoHonorario/AdicionaTipoHonorario`, adicionaTipoHonorario);

        return result;
    }

    async obterTipoHonorarioLimitada(filtros: FiltroPesquisaTipoHonorarioRequest): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if(filtros.queryCustom){
            query += filtros.queryCustom;
        }
        
        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        } 

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoHonorario/RecuperaListaLimitada?${query}`);

        return result;
    }

    async alterarStatus(tipoHonorarioId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoHonorario>(`TipoHonorario/AtualizaTipoHonorarioPorId?id=${tipoHonorarioId}`, data);
    }

    async atualizarTipoHonorario(editarTipoHonorario: EditarTipoHonorarioRequest): Promise<void> {

        let dataTipoHonorario = [
            { "op": "replace", "path": "/nome", "value": editarTipoHonorario.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoHonorario.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoHonorario/AtualizaTipoHonorarioPorId?id=${editarTipoHonorario.tipoHonorarioId}`, dataTipoHonorario)
        ]);
    }
}


export default new TipoHonorarioService();