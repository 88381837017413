
import { PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    PointElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend,
    ArcElement,
  } from 'chart.js'
  
import {ChartOptions, ChartData} from 'chart.js';

import IWidgetDataSet, { IWidgetDado } from "../interfaces/IWidgetDataSet";
import { DadoGenerico, TimeDelta } from '../../types';
import gerarCor from '../../utils/Colors';


ChartJS.register(
    CategoryScale,
    PointElement,
    ArcElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend
)

export default function PolarAreaChart(props: { dados: IWidgetDataSet }) {

    function corFundo(index: number, limit:number): string {
        return gerarCor(index, limit, 0.95)
    }
    function corBorda(index: number, limit:number): string {
        return gerarCor(index, limit, 1)
    }
    function getDados(): ChartData<"polarArea", number[], string>{
        let dataSet = props.dados;
        let dados = dataSet.dados as IWidgetDado[];
        return  {
            labels: dataSet.etiquetas as string[],
            datasets: [{
                data: dados.map((dado :IWidgetDado)=>{
                    if((dado.valor as unknown as DadoGenerico)?.tipo === 'timeDelta'){
                        return (dado.valor as unknown as TimeDelta).valueOf();
                    }
                    return dado.valor as number
                }),
                backgroundColor: dados.map((dado :IWidgetDado, index: number)=>{
                    return dado?.backgroundColor || corFundo(index, dados.length)
                }),
                borderColor: dados.map((dado :IWidgetDado, index: number)=>{
                    return dado?.borderColor || corBorda(index, dados.length)
                }),
                borderWidth: 0.5
            }],
        };
    }
     
    const chartOptions: ChartOptions<'polarArea'> = {

        responsive: false,
        maintainAspectRatio: false,
        scales:{
            yAxis:{
                ticks: {
                    callback: function(label, index, labels) {
                        let dados = props.dados.dados as IWidgetDado[];
                        if((dados[index]?.valor as unknown as DadoGenerico)?.tipo === 'timeDelta'){
                            return new TimeDelta(typeof label === 'string'?parseInt(label):label).toString("%D%h:%m:%s");
                        }
                        return label
                    }
                },
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function(this, i): string| string[] {
                        let dados = props.dados.dados as IWidgetDado[];
                        if((dados[0]?.valor as unknown as DadoGenerico)?.tipo === 'timeDelta'){
                            const data:number = Math.floor(this.dataPoints[0].raw as number);
                            const td = new TimeDelta(data);
                            return td.toString('%Y%X%D%h:%m:%s');
                        }
                        return i.formattedValue;
                    }
                }
            }
        }
    }

    return <PolarArea options={chartOptions} data={getDados()}/>;
}
