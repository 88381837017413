/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { MultiSelect } from "react-multi-select-component";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";

import ICidade from "../../interfaces/ICidade";
import IIndice from "../../interfaces/IIndice";
import IEmpresa from "../../interfaces/IEmpresa";
import IDepartamento from "../../interfaces/IDepartamento";
import ITipoAquisicao from "../../interfaces/ITipoAquisicao";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import TipoAquisicaoService from "../../services/TipoAquisicaoService";

import CidadeService from "../../services/CidadeService";
import IndiceService from "../../services/IndiceService";
import EmpresaService from "../../services/EmpresaService";
import DepartamentoService from "../../services/DepartamentoService";
import TipoRelacionamentoService from "../../services/TipoRelacionamentoService";



interface IMarcaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtro: any;
  setFiltro: (filtro: any) => void;
}

const MarcaFiltroModal = ({ toggleModal, exibirModal, filtro, setFiltro, setPesquisar }: IMarcaModalProps) => {
  const [carregamentoDepartamento, setCarregamentoDepartamento] = useState<boolean>(false);
  const [departamento, setDepartamento] = useState<any[]>([]);
  const [departamentoSelected, setDepartamentoSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);

  const [carregamentoCidades, setCarregamentoCidades] = useState<boolean>(false);
  const [cidades, setCidades] = useState<any>([]);
  const [cidadesSelected, setCidadesSelected] = useState([]);

  const [carregamentoIndices, setCarregamentoIndices] = useState<boolean>(false);
  const [indices, setIndices] = useState<any>([]);
  const [indicesSelected, setIndicesSelected] = useState([]);

  const [carregamentoTipoAquisicao, setCarregamentoTipoAquisicao] = useState<boolean>(false);
  const [tiposAcquisicao, setTipoAquisicao] = useState<any>([]);
  const [tiposAcquisicaosSelected, setTipoAquisicaoSelected] = useState([]);

  const [carregamentoTipoRelacionamentos, setCarregamentoTipoRelacionamentos] = useState<boolean>(false);
  const [tipoRelacionamento, setTipoRelacionamento] = useState<any>([]);
  const [tipoRelacionamentosSelected, settipoRelacionamentosSelected] = useState([]);

  const [exibirFiltrosData, setExibirFiltrosData] = useState<boolean>(false);
  const [exibirFiltrosLista, setExibirFiltrosLista] = useState<boolean>(false);

  const [selectedImovelPrincipal, setSelectedImovelPrincipal] = useState<any>([]);

  useEffect(() => {
    if (exibirFiltrosLista) {

      carregarDepartamento();

      carregarCidade();

      carregarIndice();

      carregarTipoAquisicao();

      carregarEmpresa();

      carregarTipoRelacionamento();
    }
  }, [exibirFiltrosLista]);

  const carregarDepartamento = async () => {
    try {
      if (departamento.length > 0) return;
      setCarregamentoDepartamento(true);
      let resultado: IRecuperaLista<IDepartamento>;
      resultado = await DepartamentoService.obterDepartamentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "departamentoId",
      });
      setDepartamento(returnOptions(resultado.data, "departamentoId", "departamento"));
      setCarregamentoDepartamento(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoDepartamento(false);
      setDepartamento([]);
    }
  }

  const optionsImovelPrincipal = [
    { label: "Sim", value: "1" },
    { label: "Não", value: "0" },
  ];

  const carregarEmpresa = async () => {
    try {
      if (empresa.length > 0) return;
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "empresaId",
      });
      setEmpresa(returnOptionsEmpresa(resultado.data));
      setCarregamentoEmpresa(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
  }

  const carregarTipoRelacionamento = async () => {
    try {
      if (tipoRelacionamento.length > 0) return;
      setCarregamentoTipoRelacionamentos(true);
      let resultado: IRecuperaLista<any>;
      resultado = await TipoRelacionamentoService.obter({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoRelacionamentoId",
      });
      setTipoRelacionamento(returnOptions(resultado.data, "tipoRelacionamentoId", "tipoRelacionamento"));
      setCarregamentoTipoRelacionamentos(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoRelacionamentos(false);
      setTipoRelacionamento([]);
    }
  }

  const carregarCidade = async () => {
    try {
      if (cidades.length > 0) return;
      setCarregamentoCidades(true);
      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidade({
        limit: 10000,
        codigo: "",
        offset: 0,
        sort: "cidadeId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setCidades(returnOptions(resultado.data, "cidadeId", "cidades"));
      setCarregamentoCidades(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoCidades(false);
      setCidades([]);
    }
  }

  const carregarIndice = async () => {
    try {
      if (indices.length > 0) return;
      setCarregamentoIndices(true);
      let resultado: IRecuperaLista<IIndice>;
      resultado = await IndiceService.obterIndices({
        limit: 1000,
        codigo: "",
        offset: 0,
        sort: "indiceId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setIndices(returnOptions(resultado.data, "indiceId", "indices"));
      setCarregamentoIndices(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoIndices(false);
      setIndices([]);
    }
  }

  const carregarTipoAquisicao = async () => {
    try {
      if (tiposAcquisicao.length > 0) return;
      setCarregamentoTipoAquisicao(true);
      let resultado: IRecuperaLista<ITipoAquisicao>;
      resultado = await TipoAquisicaoService.obterTipoAquisicoes({
        limit: 100,
        codigo: "",
        offset: 0,
        sort: "tipoAquisicaoIdId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setTipoAquisicao(returnOptions(resultado.data, "tipoAquisicaoIdId", "tiposAcquisicao"));
      setCarregamentoTipoAquisicao(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoAquisicao(false);
      setTipoAquisicao([]);
    }
  }

  const returnOptionsEmpresa = (empresas: any) => {
    let empresasParse: any = [];
    empresas.forEach((item: any) => {
      empresasParse.push({
        value: item.empresaId,
        label: item.nomeFantasia,
      });
    });
    return empresasParse;
  };

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });

    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {

      case "tipoRelacionamentoId":
        setFiltro({ ...filtro, tipoRelacionamentoId: IdsSelected });
        break;

      case "classificacaoIPCId":
        setFiltro({ ...filtro, classificacaoIPCId: IdsSelected });
        break;

      case "cidadeId":
        setFiltro({ ...filtro, cidadeId: IdsSelected });
        break;

      case "paisId":
        setFiltro({ ...filtro, paisId: IdsSelected });
        break;

      case "naturezaMarcaId":
        setFiltro({ ...filtro, naturezaMarcaId: IdsSelected });
        break;

      case "classeMarcaId":
        setFiltro({ ...filtro, classeMarcaId: IdsSelected });
        break;

      case "departamentoId":
        setFiltro({ ...filtro, departamentoId: IdsSelected });
        break;

      case "empresa":
        setFiltro({ ...filtro, empresa: IdsSelected });
        break;

      case "apresentacaoMarca":
        setFiltro({ ...filtro, apresentacaoMarca: IdsSelected });
        break;

      case "usuarioIdCadastro":
        setFiltro({ ...filtro, usuarioIdCadastro: IdsSelected });
        break;

      case "imovelPrincipal":
        setFiltro({ ...filtro, imovelPrincipal: IdsSelected });
        break;
    }
  };

  return (
    <>
      <>
        <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
          <div className="modal-content">
            <div className="modal-body">

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosData(!exibirFiltrosData)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosData ? "-" : "+"} Filtros por datas</h5>
                </div>
              </div>
              {exibirFiltrosData && (
                <>
                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Data de Cadastro:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataCadastroMaiorIgual && filtro.dataCadastroMenorIgual
                            ? [filtro.dataCadastroMaiorIgual, filtro.dataCadastroMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataCadastroMaiorIgual: "",
                                dataCadastroMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataInicioVigencia" className="form-label fw-bolder text-orange">
                        Data de Início da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataInicioVigenciaMaiorIgual && filtro.dataInicioVigenciaMenorIgual
                            ? [filtro.dataInicioVigenciaMaiorIgual, filtro.dataInicioVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataInicioVigenciaMaiorIgual: "",
                                dataInicioVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataInicioVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataInicioVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataFimVigencia" className="form-label fw-bolder text-orange">
                        Data Fim da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataFimVigenciaMaiorIgual && filtro.dataFimVigenciaMenorIgual
                            ? [filtro.dataFimVigenciaMaiorIgual, filtro.dataFimVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataFimVigenciaMaiorIgual: "",
                                dataFimVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataFimVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataFimVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Data de Aquisição:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataAquisicaoMaiorIgual && filtro.dataAquisicaoMenorIgual
                            ? [filtro.dataAquisicaoMaiorIgual, filtro.dataAquisicaoMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataAquisicaoMaiorIgual: "",
                                dataAquisicaoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataAquisicaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataAquisicaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataAssinatura" className="form-label fw-bolder text-orange">
                        Data do Reajuste:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataReajusteMaiorIgual && filtro.dataReajusteMenorIgual
                            ? [filtro.dataReajusteMaiorIgual, filtro.dataReajusteMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataReajusteMaiorIgual: "",
                                dataReajusteMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataReajusteMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataReajusteMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataAssinatura" className="form-label fw-bolder text-orange">
                        Data da Consolidação:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataConsolidicacaoMaiorIgual && filtro.dataConsolidicacaoMenorIgual
                            ? [filtro.dataConsolidicacaoMaiorIgual, filtro.dataConsolidicacaoMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataConsolidicacaoMaiorIgual: "",
                                dataConsolidicacaoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataConsolidicacaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataConsolidicacaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Data de Avaliação:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataAvaliacaoMaiorIgual && filtro.dataAvaliacaoMenorIgual
                            ? [filtro.dataAvaliacaoMaiorIgual, filtro.dataAvaliacaoMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataAvaliacaoMaiorIgual: "",
                                dataAvaliacaoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataAvaliacaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataAvaliacaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataAssinatura" className="form-label fw-bolder text-orange">
                        Data do Registro da Matrícula:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataRegistroMatriculaMaiorIgual && filtro.dataRegistroMatriculaMenorIgual
                            ? [filtro.dataRegistroMatriculaMaiorIgual, filtro.dataRegistroMatriculaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataRegistroMatriculaMaiorIgual: "",
                                dataRegistroMatriculaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataRegistroMatriculaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataRegistroMatriculaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosLista(!exibirFiltrosLista)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosLista ? "-" : "+"} Filtros por listas</h5>
                </div>
              </div>
              {exibirFiltrosLista && (
                <>
                  <div className="row mt-6">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Departamento:
                      </label>
                      <MultiSelect
                        disabled={carregamentoDepartamento}
                        isLoading={carregamentoDepartamento}
                        options={departamento}
                        value={departamentoSelected}
                        onChange={(event: any) => {
                          setDepartamentoSelected(event);
                          setIdsSelect("departamentoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Empresa:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoEmpresa}
                        disabled={carregamentoEmpresa}
                        options={empresa}
                        value={empresaSelected}
                        onChange={(event: any) => {
                          setEmpresaSelected(event);
                          setIdsSelect("empresaId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-avaliador" className="form-label fw-bolder text-orange">
                        Avaliador:
                      </label>
                      <input
                        value={filtro.avaliador}
                        onChange={(e) => {
                          setFiltro((oldState: any) => {
                            return { ...oldState, avaliador: e.target.value };
                          });
                        }}
                        placeholder="Avaliador"
                        type="text"
                        className={"form-control"}
                        id="form-avaliador"
                      />
                    </div>
                  </div>

                  <div className="row mt-6">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Indice:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoIndices}
                        disabled={carregamentoIndices}
                        options={indices}
                        value={indicesSelected}
                        onChange={(event: any) => {
                          setIndicesSelected(event);
                          setIdsSelect("indiceId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Cidade:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoCidades}
                        disabled={carregamentoCidades}
                        options={cidades}
                        value={cidadesSelected}
                        onChange={(event: any) => {
                          setCidadesSelected(event);
                          setIdsSelect("cidadeId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Tipos de Aquisição:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoTipoAquisicao}
                        disabled={carregamentoTipoAquisicao}
                        options={tiposAcquisicao}
                        value={tiposAcquisicaosSelected}
                        onChange={(event: any) => {
                          setTipoAquisicaoSelected(event);
                          setIdsSelect("tipoAquisicaoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-6">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Tipo de Relacionamento:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoTipoRelacionamentos}
                        disabled={carregamentoTipoRelacionamentos}

                        options={tipoRelacionamento}
                        value={tipoRelacionamentosSelected}
                        onChange={(event: any) => {
                          settipoRelacionamentosSelected(event);
                          setIdsSelect("tipoRelacionamentoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer" style={{ margin: "0 auto" }}>
              <button
                type="button"
                onClick={() => {
                  setPesquisar(true);
                  toggleModal();
                }}
                className="btn btn-orange me-5"
              >
                Pesquisar
              </button>
              <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                CANCELAR
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default MarcaFiltroModal;
