import { useEffect, useState } from "react";
import clsx from "clsx";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";
import { useFormik } from "formik";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";
import { Button, Modal } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faHistory, faTimes } from "@fortawesome/free-solid-svg-icons";

import AndamentoService from "../../../services/AndamentoService";
import AndamentoTipoService from "../../../services/AndamentoTipoService";

import IOption from "../../../interfaces/IOption";
import IAndamentos from "../../../interfaces/IAndamentos";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IAndamentoTipo from "../../../interfaces/IAndamentoTipo";
import FiltroPesquisaAndamentoTipoRequest from "../../../interfaces/Requests/AndamentoTipo/FiltroPesquisaAndamentoTipoRequest";

import { ECamposIDEnum, EModulo } from "../../../enum";

import FileUploadAndamentoField from "../../FileUpload/Andamento/FileUploadAndamentoField";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import { tipoPorModulo } from "../../../utils/Utils";


interface IAndamentoFieldProps {

  modulo: number;

  processoId?: number;
  areaDireitoId?: number;

  contratoId?: number;
  tipoContratoId?: number;

  imovelId?: number;
  tipoImovelId?: number;

  marcaId?: number;
  tipoMarcaId?: number;

  licencaId?: number;
  tipoLicencaId?: number;

  empresaId?: number;
  tipoSociedadeId?: number;

  consultaId?: number;
  tipoConsultaId?: number;

  procuracaoId?: number;
  tipoProcuracaoId?: number;

  editarAndamento: IAndamentos;
  toggleModal: () => void;
  exibirModal: boolean;
  setAtualizarTabela(atualizaAgora: boolean): void
}

const AndamentoField = ({

  modulo,

  processoId,
  areaDireitoId,

  contratoId,
  tipoContratoId,

  marcaId,
  tipoMarcaId,

  imovelId,
  tipoImovelId,

  consultaId,
  tipoConsultaId,

  procuracaoId,
  tipoProcuracaoId,

  licencaId,
  tipoLicencaId,

  empresaId,
  tipoSociedadeId,

  toggleModal,
  exibirModal,
  editarAndamento,
  setAtualizarTabela }: IAndamentoFieldProps) => {

  const [options, setOptions] = useState<IOption[]>([]);
  const [carregandoAndamentoTipo, setCarregandoAndamentoTipo] = useState(false);
  const [filtrosPesquisa] = useState<FiltroPesquisaAndamentoTipoRequest>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 1000,
    totalItems: 0,
    offset: 0,
    sort: "nome",
  });

  const initialValues: IAndamentos = {
    andamentoId: 0,
    andamentoTipoId: 0,
    modulo: modulo,
    codigo: "",
    dataAndamento: new Date(),
    descricao: "",
    restrito: false,
    andamentoTipoNome: "",
    dataCadastro: "",
    numeroProcesso: "",
    xFinderAndamentoTipoExterno: "",
    processoParteContraria: []
  };

  const [importarArquivos, setImportarArquivos] = useState<boolean>(false);
  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const schema = () => {
    let schema: any = {};
    schema.dataAndamento = Yup.date()
      .transform((value) => {
        return value ? moment(value).toDate() : value;
      })
      .required("Campo data do andamento é obrigatório");
    schema.descricao = Yup.string().min(3, "Campo descrição está inválido").required("Campo descrição é obrigatório");
    schema.andamentoTipoId = Yup.number().min(1).required("Campo tipo de andamento é obrigatório");

    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema(),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let confirm = await swalWithBootstrapButtons.fire({
          title: "Registrar Andamento ",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (confirm.isConfirmed) {
          if (values.andamentoId) {
            await AndamentoService.atualizarAndamento(values);
          } else {
            await AndamentoService.adicionaAndamento(values);
          }
          toggleModal();
          setSubmitting(false);
          setAtualizarTabela(true);
          setImportarArquivos(true);
          await Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: `Andamento adicionado com sucesso`,
            showConfirmButton: false,
            timer: 3000
          });
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  const cancelar = () => {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  const carregarAndamentoTipo = async (filtro: FiltroPesquisaAndamentoTipoRequest) => {

    let tipos: any[] = [{ label: 'Selecione', value: 0 }];

    let resultado: IRecuperaLista<IAndamentoTipo>;

    try {
      setCarregandoAndamentoTipo(true);

      resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.TipoAndamento,
        formik.values.modulo,
        eval(`${tipoPorModulo(formik.values?.modulo)}Id`),
        0);

      resultado.data.map((item) => tipos.push({ label: item.nome, value: item.andamentoTipoId }));
      setOptions(tipos);

    } catch (error: any) {
      resultado = await AndamentoTipoService.obterAndamentoTipos(filtro);
      resultado.data.map((item) => tipos.push({ label: item.nome, value: item.andamentoTipoId }));
      setOptions(tipos);
    } finally {
      setCarregandoAndamentoTipo(false);
    }
  }

  useEffect(() => {

    if (exibirModal) carregarAndamentoTipo(filtrosPesquisa);

  }, [exibirModal]);

  useEffect(() => {

    let objeto: any = {};

    if (processoId && EModulo.AreaDireito == modulo) {
      objeto.processoId = processoId;
    }

    if (contratoId && EModulo.Contrato == modulo) {
      objeto.contratoId = contratoId;
    }

    if (imovelId && EModulo.Imovel == modulo) {
      objeto.imovelId = imovelId;
    }

    if (licencaId && EModulo.Regulatorio == modulo) {
      objeto.licencaId = licencaId;
    }

    if (procuracaoId && EModulo.Procuracao == modulo) {
      objeto.procuracaoId = procuracaoId;
    }

    if (empresaId && EModulo.Societario == modulo) {
      objeto.empresaId = empresaId;
    }

    if (marcaId && EModulo.MarcaEPertences == modulo) {
      objeto.marcaId = marcaId;
    }

    if (consultaId && EModulo.Consultivo == modulo) {
      objeto.consultaId = consultaId;
    }

    formik.setValues({
      ...initialValues,
      ...objeto,
    });

    if (editarAndamento.andamentoId > 0) formik.setValues({ ...editarAndamento, dataAndamento: new Date(editarAndamento.dataAndamento) });

  }, [exibirModal, editarAndamento]);

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Registrar Andamento {formik.values.andamentoId ? `- #${formik.values.andamentoId}` : ""}
            <FontAwesomeIcon className="mx-2 text-orange" icon={faHistory} />
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">
              {carregandoAndamentoTipo ? (
                <>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                      Tipo de Andamento:
                    </label>
                    <span className="indicator-progress" style={{ display: "block" }}>
                      <span className="spinner-border text-orange spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </div>
                </>
              ) : (
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    Tipo de Andamento:
                  </label>
                  <Select
                    value={
                      {
                        label: options.find((e) => e.value === formik.values.andamentoTipoId)?.label,
                        value: formik.values.andamentoTipoId,
                      }
                    }

                    options={options}
                    onChange={(option: any) => {
                      formik.setFieldTouched("andamentoTipoId", true);
                      formik.setFieldValue("andamentoTipoId", parseInt(option.value));
                    }}
                  />

                </div>
              )}

              <div className="col-md-4 mt-3">
                <label htmlFor="form-dataNascimento" className="form-label required fw-bolder text-orange">
                  Data do Andamento:
                </label>
                <DatePicker
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  className={"form-control"}
                  selected={formik.values.dataAndamento}
                  onChange={(date: Date) => formik.setFieldValue("dataAndamento", date)}
                />

                {formik.touched.dataAndamento && formik.errors.dataAndamento && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span style={{ fontSize: "11px" }} role="alert">
                        {formik.errors.dataAndamento}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Descrição
                </label>
                <textarea
                  {...formik.getFieldProps("descricao")}
                  maxLength={5000}
                  rows={5}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.descricao && formik.errors.descricao,
                    },
                    {
                      "is-valid": formik.touched.descricao && !formik.errors.descricao,
                    }
                  )}
                  id="form-nomeUsuario"
                />
                {formik.touched.descricao && formik.errors.descricao && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.descricao}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mt-6 mt-3">
                <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                  Ser Restrito?:{" "}
                  <FormCheckInput
                    type="checkbox"
                    style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                    checked={formik.values.restrito}
                    onChange={() => {
                      if (formik.values.restrito) formik.setValues({ ...formik.values, restrito: false });
                      if (!formik.values.restrito) formik.setValues({ ...formik.values, restrito: true });
                    }}
                  />
                </label>
              </div>

              <div className="row mt-12 mt-3">
                <div className="col-md-12 mt-3">
                  <Button
                    onClick={() => toggleImportacaoArquivos()}
                    style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                    variant="secondary"
                    size="sm"
                  >
                    Clique aqui para importar <FontAwesomeIcon style={{ cursor: "pointer" }} color={"white"} className="mx-2" icon={faFile} />
                    {quantidadeArquivosSelecionados} importados
                  </Button>

                </div>
              </div>

              <div className="row mt-12 mt-3">
                <div className="col-md-12 mt-3">
                  <FileUploadAndamentoField

                    modulo={modulo}
                    importarArquivos={importarArquivos}
                    campo={58}

                    setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                    exibirModal={exibirModalImportacaoArquivos}
                    toggleModal={toggleImportacaoArquivos}

                    processoId={processoId}
                    areaDireitoId={areaDireitoId}

                    procuracaoId={procuracaoId}
                    tipoProcuracaoId={tipoProcuracaoId}

                    contratoId={contratoId}
                    tipoContratoId={tipoContratoId}

                    consultaId={consultaId}
                    tipoConsultaId={tipoConsultaId}

                    licencaId={licencaId}
                    tipoLicencaId={tipoLicencaId}

                    imovelId={imovelId}
                    tipoImovelId={tipoImovelId}

                    marcaId={marcaId}
                    tipoMarcaId={tipoMarcaId}

                    empresaId={empresaId}
                    tipoSociedadeId={tipoSociedadeId}

                  />
                </div>

              </div>

            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={() => formik.submitForm()} disabled={!formik.isValid} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AndamentoField;
