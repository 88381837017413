import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IAndamentoTipo from "../interfaces/IAndamentoTipo";
import { AdicionaAndamentoTipoRequest } from "../interfaces/Requests/AndamentoTipo/AdicionaAndamentoTipoRequest";
import FiltroPesquisaAndamentoTipoRequest from "../interfaces/Requests/AndamentoTipo/FiltroPesquisaAndamentoTipoRequest";
import { EditarAndamentoTipoRequest } from "../interfaces/Requests/AndamentoTipo/EditarAndamentoTipoRequest";

class AndamentoTipoService {

    async obterAndamentoTipos(filtros: FiltroPesquisaAndamentoTipoRequest): Promise<IRecuperaLista<IAndamentoTipo>> {
        let query = `${filtros.status === 0 ? 'status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IAndamentoTipo>>(`AndamentoTipo/RecuperaAndamentoTipo?${query}`);

        return result;
    }

    async adicionaAndamentoTipo(adicionaAndamentoTipo: AdicionaAndamentoTipoRequest): Promise<IAndamentoTipo> {
        let { data: result } = await api.post<IAndamentoTipo>(`AndamentoTipo/AdicionaAndamentoTipo`, adicionaAndamentoTipo);

        return result;
    }


    async alterarStatus(andamentoTipoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IAndamentoTipo>(`AndamentoTipo/AtualizaAndamentoTipoPorId?id=${andamentoTipoId}`, data);
    }

    async atualizarAndamentoTipo(editarAndamentoTipo: EditarAndamentoTipoRequest): Promise<void> {

        let dataAndamentoTipo = [
            { "op": "replace", "path": "/nome", "value": editarAndamentoTipo.nome },
            { "op": "replace", "path": "/codigo", "value": editarAndamentoTipo.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`AndamentoTipo/AtualizaAndamentoTipoPorId?id=${editarAndamentoTipo.andamentoTipoId}`, dataAndamentoTipo)
        ]);
    }
}


export default new AndamentoTipoService();