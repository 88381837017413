import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusDesdobramento from "../interfaces/IStatusDesdobramento";
import { AdicionaStatusDesdobramentoRequest } from "../interfaces/Requests/StatusDesdobramento/AdicionaStatusDesdobramentoRequest";
import FiltroPesquisaStatusDesdobramentoRequest from "../interfaces/Requests/StatusDesdobramento/FiltroPesquisaStatusDesdobramentoRequest";
import { EditarStatusDesdobramentoRequest } from "../interfaces/Requests/StatusDesdobramento/EditarStatusDesdobramentoRequest";

class StatusDesdobramentoService {

    async obterStatusDesdobramentos(filtros: FiltroPesquisaStatusDesdobramentoRequest): Promise<IRecuperaLista<IStatusDesdobramento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusDesdobramento>>(`StatusDesdobramento/RecuperaStatusDesdobramento?${query}`);

        return result;
    }

    async adicionaStatusDesdobramento(adicionaStatusDesdobramento: AdicionaStatusDesdobramentoRequest): Promise<IStatusDesdobramento> {
        let { data: result } = await api.post<IStatusDesdobramento>(`StatusDesdobramento/AdicionaStatusDesdobramento`, adicionaStatusDesdobramento);

        return result;
    }


    async alterarStatus(statusDesdobramentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusDesdobramento>(`StatusDesdobramento/AtualizaStatusDesdobramentoPorId?id=${statusDesdobramentoId}`, data);
    }

    async atualizarStatusDesdobramento(editarStatusDesdobramento: EditarStatusDesdobramentoRequest): Promise<void> {

        let dataStatusDesdobramento = [
            { "op": "replace", "path": "/nome", "value": editarStatusDesdobramento.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusDesdobramento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusDesdobramento/AtualizaStatusDesdobramentoPorId?id=${editarStatusDesdobramento.statusDesdobramentoId}`, dataStatusDesdobramento)
        ]);
    }
}


export default new StatusDesdobramentoService();