/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Card, Form, Popover } from "react-bootstrap";
import { useFormik } from "formik";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";
import { TableColumn } from "react-data-table-component";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import Swal from "sweetalert2";
import moment from "moment";
import {
  faTimes,
  faEraser,
  faPlus,
  faSearch,
  faFileExcel,
  faTrash,
  faCloudDownloadAlt,
  faEye,
  faEdit,
  faPaperPlane,
  faTags,
} from "@fortawesome/free-solid-svg-icons";

import FiltroPesquisaDocumentoRequest from "../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import ITipoDocumento from "../../interfaces/ITipoDocumento";
import { IEtiqueta } from "../../interfaces/IEtiquetas";
import IDocumento from "../../interfaces/IDocumento";
import IPastaGed from "../../interfaces/IPastaGed";
import IUsuario from "../../interfaces/IUsuario";

import DocumentoService from "../../services/DocumentoService";
import PastaGedService from "../../services/PastaGedService";
import UsuarioService from "../../services/UsuarioService";
import TipoDocumentoService from "../../services/TipoDocumentoService";
import EtiquetaService from "../../services/EtiquetaService";

import GridPadrao from "../../components/Comum/GridPadrao";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import NovoGedModal from "./NovoGedModal";
import { useAuth } from "../../contexts/AuthProvider";
import PreviewPDFField from "../../components/Comum/PreviewPDF/PreviewPDF";
import { ETIQUETAS_STATICA } from "./EtiquetasStatic";

const GedPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [relatorioId, setRelatorioId] = useState<number | null>(null);

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    processoId: 0,
    modulo: [6],
    nome: "",
    status: 1,
    origem: 12,
    limit: 10,
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    codigo: "",
    descricao: "",
    empresaId: 0,
    tipoDocumentoId: [],
    usuarioIdCadastro: [],
    totalItems: 0,
    offset: 0,
    sort: "-dataCadastro",
  });

  const [pastaGed, setPastaGed] = useState<any>([]);
  const [pastaGedSelecionados, setPastaGedSelecionados] = useState([]);
  const [carregandoPastaGed, setCarregandoPastaGed] = useState<boolean>(false);

  const [documentos] = useState<any>([]);
  const [tiposDocumentos, setTiposDocumentos] = useState<any>([]);
  const [tipoDocumentoSelecionados, setTipoDocumentoSelecionados] = useState([]);
  const [carregandoTipoDocumento, setCarregandoTipoDocumento] = useState<boolean>(false);

  const [usuarios, setUsuarios] = useState<any>([]);
  const [usuarioSelecionados, setUsuarioSelecionados] = useState([]);
  const [carregandoUsuario, setCarregandoUsuario] = useState<boolean>(false);

  const { user } = useAuth();

  useEffect(() => {
    if (!loadingInicial) {
      carregarDocumentos(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    carregarPastaGed();
    carregarTipoDocumento();
    carregarUsuario();
  }, []);

  const documentosFormik = useFormik({
    initialValues: documentos,
    onSubmit: async ({ }) => { },
  });

  const etiquetasFormik = useFormik({
    initialValues: ETIQUETAS_STATICA,
    onSubmit: async ({ }) => { },
  });

  const toggleModal = (carregarDados?: boolean) => {
    if (carregarDados) {
      carregarDocumentos(filtrosPesquisa);
    }

    setExibirModal(!exibirModal);
  };

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {
      setCarregandoDocumentos(true);
      let resultado: IRecuperaLista<IDocumento>;

      resultado = await DocumentoService.obterArquivos(filtro);

      if (resultado.data === null) {
        documentosFormik.setValues([]);
        setRelatorioId(null);
      } else {
        setFiltrosPesquisa((oldState) => {
          return { ...oldState, totalItems: resultado.totalRegistros };
        });
        setRelatorioId(resultado.relatorioId || null);
        documentosFormik.setValues(resultado.data);
      }
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros.",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      documentosFormik.setValues([]);
      setRelatorioId(null);
    } finally {
      setCarregandoDocumentos(false);
      setLoadingInicial(false);
    }
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        codigo: "",
        documentoId: [],
        processoId: 0,
        pastaGed: [],
        nome: "",
        status: 1,
        origem: 9,
        limit: 10,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        descricao: "",
        empresaId: 0,
        tipoDocumentoId: [],
      };
    });
    setPastaGedSelecionados([]);
    setTipoDocumentoSelecionados([]);
    setUsuarioSelecionados([]);
  };

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const verificaIsPreview = (extensao: string) => {
    if (extensao.endsWith(".pdf") || extensao.endsWith(".mp4")) return true;
    else return false;
  };

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download do conteúdo...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Conteúdo baixado com sucesso`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel baixar arquivos`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const baixarRelatorio = async () => {
    if (!documentosFormik.values) return;

    if (relatorioId === 0 || relatorioId === null) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `A pesquisa não retornou nenhum resultado.`,
        showConfirmButton: true,
        timer: 4000,
      });
      return;
    }

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Baixando arquivos...",
      showConfirmButton: false,
    });
    Swal.showLoading();
    try {
      const arquivo = await DocumentoService.gerarExcel(relatorioId || 0);
      if (arquivo) {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${new Date().toLocaleString("BR")} - Documentos.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      Swal.hideLoading();
      Swal.update({
        title: "Baixado com sucesso",
        text: "",
        icon: "success",
        showConfirmButton: true,
      });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível baixar arquivos",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const carregarPastaGed = async () => {
    try {
      if (pastaGed.length > 0) return;
      setCarregandoPastaGed(true);
      let resultado: IRecuperaLista<IPastaGed>;
      resultado = await PastaGedService.obterPastaGeds({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setPastaGed(returnOptions(resultado.data, "pastaGedId", "pastaGed"));
      setCarregandoPastaGed(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregandoPastaGed(false);
      setPastaGed([]);
    }
  };

  const carregarTipoDocumento = async () => {
    try {
      if (pastaGed.length > 0) return;
      setCarregandoTipoDocumento(true);
      let resultado: IRecuperaLista<ITipoDocumento>;
      resultado = await TipoDocumentoService.obterTipoDocumentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTiposDocumentos(returnOptions(resultado.data, "tipoDocumentoId", "tiposDocumentos"));
      setCarregandoTipoDocumento(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregandoTipoDocumento(false);
      setTiposDocumentos([]);
    }
  };

  const carregarUsuario = async () => {
    try {
      if (usuarios.length > 0) return;
      setCarregandoUsuario(true);
      let resultado: IRecuperaLista<IUsuario>;
      resultado = await UsuarioService.obterUsuarios({
        nomeUsuario: "",
        clientes: [user?.clienteId || 0],
        email: "",
        numeroDocumento: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nomeUsuario",
      });
      setUsuarios(returnOptions(resultado.data, "usuarioId", "usuarios"));
      setCarregandoUsuario(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregandoUsuario(false);
      setUsuarios([]);
    }
  };

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });
    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {
      case "pastaGedId":
        setFiltrosPesquisa({ ...filtrosPesquisa, pastaGedId: IdsSelected });
        break;

      case "tipoDocumentoId":
        setFiltrosPesquisa({ ...filtrosPesquisa, tipoDocumentoId: IdsSelected });
        break;

      case "usuarioId":
        setFiltrosPesquisa({ ...filtrosPesquisa, usuarioIdCadastro: IdsSelected });
        break;
    }
  };

  const alterarEtiqueta = (index: number, row: IDocumento) => {
    documentosFormik.values.forEach((documento: any) => {
      if (documento.documentoId == row.documentoId) {
        documento.etiquetas[index].alterar = true;
        documentosFormik.setValues(documentosFormik.values);
        return;
      }
    });
  };

  const salvarEtiqueta = (index: number, nome: string, row: IDocumento) => {
    documentosFormik.values.forEach(async (documento: any) => {
      if (documento.documentoId == row.documentoId) {
        documento.etiquetas[index].alterar = false;
        documentosFormik.setValues(documentosFormik.values);
        if (nome) {
          documento.etiquetas[index].nome = nome;
          await EtiquetaService.alterarEtiqueta(documento.etiquetas[index]);
          documentosFormik.setValues(documentosFormik.values);
        }
        return;
      }
    });
  };

  const removerEtiqueta = async (index: number, row: IDocumento) => {
    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-orange",
        cancelButton: "btn btn-danger ms-5",
      },
      buttonsStyling: false,
    });

    let result = await swalWithBootstrapButtons.fire({
      title: "Excluir Etiqueta",
      text: `Você realmente deseja excluir?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: `Confirmar`,
    });

    if (result.isConfirmed) {
      EtiquetaService.removerEtiqueta(row.etiquetas[index].etiquetaId);
      setTimeout(() => carregarDocumentos(filtrosPesquisa), 2000);
    }
  };

  const selecionarEtiquetas = (index: number, checked: boolean, row: IDocumento) => {
    if (checked && row.etiquetas.length >= 5) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `Você atingiu o limite máximo de etiquetas`,
        showConfirmButton: true,
      });
      etiquetasFormik.values[index].checked = false;
      etiquetasFormik.setValues(etiquetasFormik.values);

      return;
    }

    if (checked && !etiquetasFormik.values[index].nome) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `É necessário que você informe uma descrição para etiqueta`,
        showConfirmButton: true,
      });
      etiquetasFormik.values[index].checked = false;
      etiquetasFormik.setValues(etiquetasFormik.values);
      return;
    }

    etiquetasFormik.values[index].checked = checked;
    etiquetasFormik.setValues(etiquetasFormik.values);
    etiquetasFormik.values
      .filter((e) => e.checked)
      .forEach((etiqueta) => {
        if (row.etiquetas.filter((e) => e.nome == etiqueta.nome).length == 0) {
          row.etiquetas.push(etiqueta);
          EtiquetaService.adicionaEtiqueta({ ...etiqueta, documentoId: row.documentoId });
        }
      });
  };

  const abrirEtiquetasModal = (row: IDocumento) => {
    fecharTodosModalPorDocumento(row);
    limparCheckedTodasEtiquetas();
    documentosFormik.values.forEach(async (documento: any) => {
      if (documento.documentoId == row.documentoId) {
        documento.etiquetasModal = !documento.etiquetasModal;
        documentosFormik.setValues(documentosFormik.values);
        return;
      }
    });
  };

  const fecharTodosModalPorDocumento = (row: IDocumento) => {
    documentosFormik.values.forEach(async (documento: IDocumento) => {
      if (documento.documentoId != row.documentoId) {
        documento.etiquetasModal = false;
        documentosFormik.setValues(documentosFormik.values);
        return;
      }
    });
  };

  const fecharTodosModal = () => {
    documentosFormik.values.forEach(async (documento: IDocumento) => {
      documento.etiquetasModal = false;
      documentosFormik.setValues(documentosFormik.values);
      return;
    });
  };

  const limparCheckedTodasEtiquetas = () => {
    etiquetasFormik.values.forEach(async (etiqueta: IEtiqueta) => (etiqueta.checked = false));
    etiquetasFormik.setValues(etiquetasFormik.values);
  };

  const alterarEtiquetaStatica = (index: number, checked: boolean) => {
    etiquetasFormik.values[index].alterar = checked;
    etiquetasFormik.setValues(etiquetasFormik.values);
  };

  const popoverEtiquetas = (row: IDocumento) => {
    return (
      <Popover style={{ top: "165px", left: "207px", maxWidth: "320px" }}>
        <Popover.Body>
          <div style={{ width: "25rem", cursor: "pointer" }}>
            {etiquetasFormik.values.map((item, index) => (
              <>
                <div className="col-md-6">
                  {!item.alterar ? (
                    <>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>
                          <FormCheckInput
                            name="terms"
                            checked={item.checked}
                            onClick={() => selecionarEtiquetas(index, !item.checked, row)}
                            className="text-orange"
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              cursor: "pointer",
                              fontSize: "24px",
                              marginTop: "-2px",
                            }}
                          />
                        </td>

                        <td>
                          <Card
                            onClick={() => alterarEtiquetaStatica(index, true)}
                            className={"fade alert alert-" + item.cor + " show"}
                            style={{ width: "215px", height: "28px" }}
                          >
                            <Card.Body
                              onDoubleClick={() => alterarEtiquetaStatica(index, true)}
                              style={{ width: "300px", height: "28px", marginTop: "-25px", marginLeft: "-20px" }}
                            >
                              {" "}
                              <label style={{ fontSize: "14px" }} className="text-orange">
                                {item.nome} {"  "}
                              </label>
                            </Card.Body>
                          </Card>
                        </td>
                        <td style={{ paddingLeft: "10px" }}>
                          <FontAwesomeIcon style={{ cursor: "pointer", fontSize: "15px" }} className="text-orange" icon={faEdit} />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>
                          <FontAwesomeIcon
                            style={{ cursor: "pointer", fontSize: "24px" }}
                            onClick={() => alterarEtiquetaStatica(index, false)}
                            className="text-orange"
                            icon={faPaperPlane}
                          />
                        </td>
                        <td>
                          <Card className={"fade alert alert-" + item.cor + " show"} style={{ width: "215px", height: "28px" }}>
                            <Card.Body style={{ width: "300px", height: "28px", marginTop: "-25px", marginLeft: "-20px" }}>
                              <Form.Control
                                style={{ height: "21px", width: "188px" }}
                                type="text"
                                onChange={(e) => (item.nome = e.target.value)}
                                maxLength={17}
                              />
                            </Card.Body>
                          </Card>
                        </td>
                      </tr>
                    </>
                  )}
                </div>
              </>
            ))}
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const renderTags = (row: IDocumento) => {
    return (
      <div className="row mt-2">
        <div style={{ cursor: "pointer" }} className="btn-group" role="group">
          {row.etiquetas.map((item: IEtiqueta, index: number) =>
            !item.alterar ? (
              <>
                <tr>
                  <td style={{ paddingRight: "10px" }}>
                    <Card className={"fade alert alert-" + item.cor + " show"} style={{ width: "215px", height: "38px" }}>
                      <Card.Body
                        onDoubleClick={() => alterarEtiqueta(index, row)}
                        style={{ width: "300px", height: "28px", marginTop: "-25px", marginLeft: "-20px" }}
                      >
                        {" "}
                        <label style={{ fontSize: "14px" }} className="text-orange">
                          {item.nome} {"  "}
                          <FontAwesomeIcon
                            onClick={() => alterarEtiqueta(index, row)}
                            style={{ cursor: "pointer", fontSize: "15px" }}
                            className="text-orange"
                            icon={faEdit}
                          />
                          {"  "}
                          <FontAwesomeIcon
                            onClick={() => removerEtiqueta(index, row)}
                            style={{ cursor: "pointer", fontSize: "15px" }}
                            className="text-orange"
                            icon={faTimes}
                          />
                        </label>
                      </Card.Body>
                    </Card>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td style={{ paddingRight: "5px" }}>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer", fontSize: "24px" }}
                      onClick={() => salvarEtiqueta(index, item.nome, row)}
                      className="text-orange"
                      icon={faPaperPlane}
                    />
                  </td>
                  <td>
                    <Card className={"fade alert alert-" + item.cor + " show"} style={{ width: "215px", height: "38px" }}>
                      <Card.Body style={{ width: "300px", height: "28px", marginTop: "-25px", marginLeft: "-20px" }}>
                        <Form.Control
                          style={{ height: "21px", width: "188px" }}
                          type="text"
                          onChange={(e) => (item.nome = e.target.value)}
                          maxLength={17}
                        />
                      </Card.Body>
                    </Card>
                  </td>
                </tr>
              </>
            )
          )}
        </div>
      </div>
    );
  };

  const colunas: TableColumn<IDocumento>[] = [
    {
      cell: (row: IDocumento) => {
        return (
          <>
            <a onDoubleClick={() => fecharTodosModal()} style={{ cursor: "pointer" }}>
              <p
                className="form-label fw-bolder text-orange"
                style={{
                  paddingTop: "30px",
                }}
              >
                #{row.documentoId} - {row.nome} - {moment(row.dataCadastro).format("DD/MM/yyyy")}
              </p>
              <p>{row.descricao}</p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Restrito: </b> {row.restrito ? "Sim" : "Não"}
                <br />
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Pasta: </b> {row.pastaGedNome}
                <br />
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo: </b> {row.tipoDocumentoNome}
                <br />
              </p>
              <p>
                <>
                  <FontAwesomeIcon
                    title="Realizar download"
                    onClick={async () => {
                      try {
                        baixarArquivo(row.documentoId, row.nome);
                      } catch (error: any) {
                        return Swal.fire({
                          heightAuto: false,
                          icon: "error",
                          title: `Não foi possível realizar o download.`,
                          text:
                            error?.response?.data?.Message && typeof error.response.data.Message === "string"
                              ? error.response.data.Message
                              : error.message,
                          showConfirmButton: true,
                        });
                      }
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faCloudDownloadAlt}
                  />

                  <>
                    {verificaIsPreview(row.extensao) && (
                      <FontAwesomeIcon
                        title="Preview"
                        onClick={() => previewArquivo(row.documentoId)}
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2 text-orange"
                        icon={faEye}
                      />
                    )}
                  </>

                  <FontAwesomeIcon
                    title="Remover Documento"
                    onClick={async () => {
                      const swalWithBootstrapButtons = Swal.mixin({
                        heightAuto: false,
                        customClass: {
                          confirmButton: "btn btn-orange",
                          cancelButton: "btn btn-danger ms-5",
                        },
                        buttonsStyling: false,
                      });

                      let result = await swalWithBootstrapButtons.fire({
                        title: "Remover",
                        text: `Você realmente deseja remover?`,
                        showCancelButton: true,
                        cancelButtonText: "Cancelar",
                        confirmButtonText: `Confirmar`,
                      });

                      if (result.isConfirmed) {
                        try {
                          DocumentoService.inativaDocumento(row.documentoId);
                          await Swal.fire({
                            heightAuto: false,
                            icon: "success",
                            text: `Documento excluído com sucesso`,
                            showConfirmButton: false,
                            timer: 3000,
                          });
                          carregarDocumentos(filtrosPesquisa);
                        } catch (error: any) {
                          return Swal.fire({
                            heightAuto: false,
                            icon: "error",
                            title: `Não foi possível excluir o documento.`,
                            text:
                              error?.response?.data?.Message && typeof error.response.data.Message === "string"
                                ? error.response.data.Message
                                : error.message,
                            showConfirmButton: true,
                          });
                        }
                      }
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faTrash}
                  />

                  <FontAwesomeIcon
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    className="mx-2 text-orange"
                    size="2x"
                    onClick={() => abrirEtiquetasModal(row)}
                    icon={faTags}
                  />
                  {row.etiquetasModal && popoverEtiquetas(row)}
                </>
              </p>
              <p>{renderTags(row)}</p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IDocumento>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  return (
    <>
      <LayoutPageTitle title="Gestão de Documentos">
        <button
          onClick={() => {
            setExibirModal(true);
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      {exibirModal && <NovoGedModal exibirModal={exibirModal} titulo={`Novo`} toggleModal={toggleModal} />}

      <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

      <div onDoubleClick={() => fecharTodosModal()} className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarDocumentos(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-documento"
          >
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data de Cadastro:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                  }
                }} />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-descricao" className="form-label fw-bolder text-orange">
                Descrição:
              </label>
              <input
                value={filtrosPesquisa.descricao}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, descricao: e.target.value };
                  });
                }}
                placeholder="Descrição"
                className={"form-control"}
                id="form-descricao"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Nome:
              </label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                className={"form-control"}
                id="form-nome"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-usuarioId" className="form-label fw-bolder text-orange">
                Responsável pelo Cadastro:
              </label>
              <MultiSelect
                isLoading={carregandoUsuario}
                disabled={carregandoUsuario}
                options={usuarios}
                value={usuarioSelecionados}
                onChange={(event: any) => {
                  setUsuarioSelecionados(event);
                  setIdsSelect("usuarioId", event);
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)",
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-tipoDocumentoId" className="form-label fw-bolder text-orange">
                Tipo de Documento:
              </label>
              <MultiSelect
                isLoading={carregandoTipoDocumento}
                disabled={carregandoTipoDocumento}
                options={tiposDocumentos}
                value={tipoDocumentoSelecionados}
                onChange={(event: any) => {
                  setTipoDocumentoSelecionados(event);
                  setIdsSelect("tipoDocumentoId", event);
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)",
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-modeulo" className="form-label fw-bolder text-orange">
                Pasta:
              </label>
              <MultiSelect
                isLoading={carregandoPastaGed}
                disabled={carregandoPastaGed}
                options={pastaGed}
                value={pastaGedSelecionados}
                onChange={(event: any) => {
                  setPastaGedSelecionados(event);
                  setIdsSelect("pastaGedId", event);
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)",
                }}
              />
            </div>

            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  baixarRelatorio();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Baixar Relatório"
                disabled={relatorioId === null || carregandoDocumentos}
              >
                <FontAwesomeIcon color="white" className="" icon={faFileExcel} />
              </button>
            </div>
          </form>
        </div>

        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoDocumentos}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Documentos"
            itens={documentosFormik.values}
          />
        </div>
      </div>
    </>
  );
};

export default GedPage;
