import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoDocumento from "../interfaces/ITipoDocumento";
import { AdicionaTipoDocumentoRequest } from "../interfaces/Requests/TipoDocumento/AdicionaTipoDocumentoRequest";
import FiltroPesquisaTipoDocumentoRequest from "../interfaces/Requests/TipoDocumento/FiltroPesquisaTipoDocumentoRequest";
import { EditarTipoDocumentoRequest } from "../interfaces/Requests/TipoDocumento/EditarTipoDocumentoRequest";
import { EModulo } from "../enum";
import { tipoPorModulo } from "../utils/Utils";

class TipoDocumentoService {

    async obterTipoDocumentos(filtros: FiltroPesquisaTipoDocumentoRequest): Promise<IRecuperaLista<ITipoDocumento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.tipoDocumentoId && filtros.tipoDocumentoId.length > 0) {
            query += filtros.tipoDocumentoId.map(id => `&tipoDocumentoId=${id}`).join('')
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoDocumento>>(`TipoDocumento/RecuperaTipoDocumento?${query}`);

        return result;
    }

    async adicionaTipoDocumento(adicionaTipoDocumento: AdicionaTipoDocumentoRequest): Promise<ITipoDocumento> {
        let { data: result } = await api.post<ITipoDocumento>(`TipoDocumento/AdicionaTipoDocumento`, adicionaTipoDocumento);

        return result;
    }


    async alterarStatus(tipoDocumentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoDocumento>(`TipoDocumento/AtualizaTipoDocumentoPorId?id=${tipoDocumentoId}`, data);
    }

    async atualizarTipoDocumento(editarTipoDocumento: EditarTipoDocumentoRequest): Promise<void> {

        let dataTipoDocumento = [
            { "op": "replace", "path": "/nome", "value": editarTipoDocumento.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoDocumento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoDocumento/AtualizaTipoDocumentoPorId?id=${editarTipoDocumento.tipoDocumentoId}`, dataTipoDocumento)
        ]);
    }
    
  async obterListaLimitadaCadastro(filtros: any, modulo: EModulo): Promise<IRecuperaLista<any>> {
    let query = "";
    switch (modulo) {

      //Modulos
      case EModulo.AreaDireito:
        query += `${"modulo=1&tela=" + filtros.tela + "&campo=58&areaDireitoId=" + filtros.areaDireitoId}`;
        break;

      case EModulo.Contrato:
        query += `${"modulo=7&tela=" + filtros.tela + "&campo=58&tipoContratoId=" + filtros.tipoContratoId}`;
        break;

      case EModulo.Imovel:
        query += `${"modulo=10&tela=" + filtros.tela + "&campo=58&tipoImovelId=" + filtros.tipoImovelId}`;
        break;

      case EModulo.MarcaEPertences:
        query += `${"modulo=11&tela=" + filtros.tela + "&campo=58&tipomarcaid=" + filtros.tipoMarcaId}`;
        break;

      case EModulo.Regulatorio:
        query += `${"modulo=30&tela=" + filtros.tela + "&campo=58&tipoLicencaId=" + filtros.tipoLicencaId}`;
        break;

      case EModulo.Consultivo:
        query += `${"modulo=9&tela=" + filtros.tela + "&campo=58&tipoConsultaId=" + filtros.tipoConsultaId}`;
        break;

      case EModulo.Procuracao:
        query += `${"modulo=21&tela=" + filtros.tela + "&campo=58&tipoProcuracaoId=" + filtros.tipoProcuracaoId}`;
        break;

      case EModulo.Societario:
        query += `${"modulo=5&tela=" + filtros.tela + "&campo=58&tipoSociedadeId=" + filtros.tipoSociedadeId}`;
        break;

      case EModulo.AtoSocietario:
        query += `${"modulo=13&tela=" + filtros.tela + "&campo=58&tipoAtoId=" + filtros.tipoAtoId}`;
        break;

      case EModulo.Compromisso:
        query += `${"modulo=16&tela=" + filtros.tela + "&campo=58&fluxoFaseId=" + filtros.fluxoFaseId}`;
        break;


      //Soluções
      case EModulo.Despesa:
        query += `${"modulo=12&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoDespesaId=" + filtros.tipoDespesaId}`;
        break;

      case EModulo.Garantia:
        query += `${"modulo=22&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoGarantiaId=" + filtros.tipoGarantiaId}`;
        break;

      case EModulo.PropostaAcordo:
        query += `${"modulo=25&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoPropostaAcordoId=" + filtros.tipoPropostaAcordoId}`;
        break;

      case EModulo.Bloqueio:
        query += `${"modulo=15&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoBloqueioId=" + filtros.tipoBloqueioId}`;
        break;

      case EModulo.Decisao:
        query += `${"modulo=17&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoDecisaoId=" + filtros.tipoDecisaoId}`;
        break;

      case EModulo.Obrigacao:
        query += `${"modulo=23&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoObrigacaoId=" + filtros.tipoObrigacaoId}`;
        break;

      case EModulo.ParteContraria:
        query += `${"modulo=40&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoParteContrariaId=" + filtros.tipoParteContrariaId}`;
        break;

      case EModulo.Desdobramento:
        query += `${"modulo=18&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoDesdobramentoId=" + filtros.tipoDesdobramentoId}`;
        break;

      case EModulo.XpayHonorario:
        query += `${"modulo=34&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoHonorarioId=" + filtros.tipoHonorarioId}`;
        break;

      case EModulo.XpayReembolso:
        query += `${"modulo=35&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&campo=58&tipoReembolsoId=" + filtros.tipoReembolsoId}`;
        break;

      default:
        "";
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(`TipoDocumento/RecuperaListaLimitada?${query}`);

    return result;
  }
}


export default new TipoDocumentoService();