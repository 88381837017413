import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IFileUploadField from "./FileUploadField";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { OrangeContext } from "../../contexts/OrangeProvider";
import { EModulo } from "../../enum";

interface IFileListFieldProps {
  modulo: EModulo,
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
}

const IFileListField = ({
  modulo,
  configuracaoTela,
  formik
}: IFileListFieldProps) => {

  const {
    processo,
    areaDireito,

    procuracao,
    tipoProcuracao,

    licenca,
    tipoLicenca,

    contrato,
    tipoContrato,

    consulta,
    tipoConsulta,

    imovel,
    tipoImovel,


    decisao,
    tipoDecisao,

    obrigacao,
    tipoObrigacao,

    desdobramento,
    tipoDesdobramento,

    despesa,
    tipoDespesa,

    garantia,
    tipoGarantia,

    reembolso,
    tipoReembolso,

    bloqueio,
    tipoBloqueio,

    propostaAcordo,
    tipoPropostaAcordo,

    comunicacao,
    tipoComunicacao,

    tipoParteContraria,


  } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  useEffect(() => { formik.setFieldValue("quantidadeArquivosSelecionados", quantidadeArquivosSelecionados) }, [quantidadeArquivosSelecionados]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12 mt-3">
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}{configuracaoTela.obrigatorio ? "*" : ""}:
          </label>
          <br></br>
          <Button
            onClick={() => setExibirModalImportacaoArquivos(true)}
            style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
            variant="secondary"
            size="sm"
          >
            <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
            Selecione os arquivos
          </Button>
        </div>
        <IFileUploadField
          modulo={modulo}
          campo={configuracaoTela.campo}
          setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
          exibirModal={exibirModalImportacaoArquivos}
          toggleModal={toggleImportacaoArquivos}

          // modulos
          processoId={processo.processoId}
          areaDireitoId={areaDireito.areaDireitoId}

          procuracaoId={procuracao.procuracaoId}
          tipoProcuracaoId={tipoProcuracao.tipoProcuracaoId}

          contratoId={contrato.contratoId}
          tipoContratoId={tipoContrato.tipoContratoId}

          consultaId={consulta.consultaId}
          tipoConsultaId={tipoConsulta.tipoConsultaId}

          licencaId={licenca.licencaId}
          tipoLicencaId={tipoLicenca.tipoLicencaId}

          imovelId={imovel.imovelId}
          tipoImovelId={tipoImovel.tipoImovelId}

          //soluções
          decisaoId={decisao.decisaoId}
          tipoDecisaoId={tipoDecisao.tipoDecisaoId}

          obrigacaoId={obrigacao.obrigacaoId}
          tipoObrigacaoId={tipoObrigacao.tipoObrigacaoId}

          despesaId={despesa.despesaId}
          tipoDespesaId={tipoDespesa.tipoDespesaId}

          desdobramentoId={desdobramento.desdobramentoId}
          tipoDesdobramentoId={tipoDesdobramento.tipoDesdobramentoId}

          garantiaId={garantia.garantiaId}
          tipoGarantiaId={tipoGarantia.tipoGarantiaId}

          reembolsoId={reembolso.reembolsoId}
          tipoReembolsoId={tipoReembolso.tipoReembolsoId}

          bloqueioId={bloqueio.bloqueioId}
          tipoBloqueioId={tipoBloqueio.tipoBloqueioId}

          propostaAcordoId={propostaAcordo.propostaAcordoId}
          tipoPropostaAcordoId={tipoPropostaAcordo.tipoPropostaAcordoId}

          comunicacaoId={comunicacao?.comunicacaoId}
          tipoComunicacaoId={tipoComunicacao?.tipoComunicacaoId}
        />
      </div>
      <div className="row mt-2">
        <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
          {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
          {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
          {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
        </a>
      </div>
    </>
  );
};

export default IFileListField;
