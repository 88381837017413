import { Editable } from "slate-react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
`;

export const EditorArea = styled(Editable)`
  margin-bottom: 20px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  > div {
    background: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    padding: 10px;
    border-radius: 4px;

    > span {
      letter-spacing: -0.5px;
      font-weight: 500;
    }
  }
`;

export const Content = styled.div<{ evenly?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  > div#editor-container {
    display: flex;
    flex-direction: column;
  }

  ${(props) =>
    props.evenly &&
    css`
      padding: 0 40px;
    `}
`;

export const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;

  > strong {
    font-size: 22px;
    color: var(--bs-gray-dark);
  }

  > button {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    background: none;
  }
`;

export const CommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div#threads {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;
