import { Modal } from "react-bootstrap";
import { ModuloType } from "./PerfilPermissoesPageEdit";
import "./style/modalStyles.css";
import { useEffect, useState } from "react";
import PerfilService from "../../services/PerfilService";
import Swal from "sweetalert2";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

interface IPermissaoEditModalProps {
  perfilId: string;
  exibirModal: boolean;
  handleClose: () => void;
  modulo: ModuloType;
}

const PermissoesEditModal = ({
  perfilId,
  exibirModal,
  handleClose,
  modulo,
}: IPermissaoEditModalProps) => {
  const permissoesDefault = [
    "Leitura",
    "Escrita",
    "Atualizacao",
    "Exclusao",
    "VisualizarMenu",
    "GerarRelatorio",
    "ExibirDashboard",
  ];
  const [permissoesModulo, setPermissoesModulo] = useState(
    new Array(7).fill(0)
  );

  const handleSelectAll = () => {
    if (permissoesModulo.includes(0)) {
      const filledPermissoes = permissoesModulo.map((el, index) => {
        return index + 1;
      });

      setPermissoesModulo(filledPermissoes);
    } else {
      const filledPermissoes = permissoesModulo.map(() => {
        return 0;
      });

      setPermissoesModulo(filledPermissoes);
    }
  };

  useEffect(() => {
    if (modulo.permissoes.length > 0) {
      const newPermissoes = permissoesModulo.map((el, outIndex) => {
        const [fill] = modulo.permissoes.filter((el) => {
          if (el.role === outIndex + 1) {
            return el.role;
          }
        });
        if (fill === undefined) {
          return 0;
        } else {
          return fill.role;
        }
      });

      setPermissoesModulo(newPermissoes);
    }
  }, [modulo]);

  const submitForm = async () => {
    try {
      const roles = permissoesModulo
        .map((permissao, index) => {
          if (permissao) {
            return index + 1;
          }
        })
        .filter((role) => role !== undefined);

      const req = {
        Permissoes: [
          {
            Modulo: modulo.modulo,
            Roles: roles,
          },
        ],
      };

      await PerfilService.editaPerfil(parseInt(perfilId), req);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: "Perfil alterado com sucesso",
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível alterar o perfil",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      handleClose();
    }
  };

  const handleCheck = (index: number) => {
    const updatedCheck = permissoesModulo.map((permissao, mapIndex) => {
      if (index === mapIndex) {
        if (permissao === 0) {
          return index + 1;
        } else {
          return 0;
        }
      } else {
        return permissao;
      }
    });

    setPermissoesModulo(updatedCheck);
  };

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Permissões: {modulo.moduloNome}
          </h5>
        </div>
        <div className="modal-body">
          <div className="d-flex" style={{ gap: "10px" }}>
            <FormCheckInput
              type="checkbox"
              checked={!permissoesModulo.includes(0)}
              onChange={() => {
                handleSelectAll();
              }}
            />
            <span className="text-orange fw-bold mb-3">
              Selecionar todas as opções
            </span>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th className="text-orange">Acessos</th>
                <th className="text-orange align-items-center">
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "center", gap: "10px" }}
                  >
                    Opção
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {permissoesDefault.map((permissaoDefault, index) => {
                return (
                  <tr key={permissaoDefault}>
                    <td>{permissaoDefault}</td>
                    <td>
                      <FormCheckInput
                        type="checkbox"
                        checked={permissoesModulo[index] != 0}
                        onChange={() => {
                          handleCheck(index);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button
            onClick={submitForm}
            type="button"
            className="btn btn-orange me-5"
          >
            ALTERAR
          </button>
          <button
            onClick={() => {
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PermissoesEditModal;
