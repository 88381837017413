import { useEffect, useState } from "react";
import DashboardService from "../../../services/DashboardService";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import Swal from "sweetalert2";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import Dashboard, { TimeDelta } from "../../../components/dashboard";
import { EstatisticasContextoProvider } from "../../../components/dashboard/statistics";
import * as constants from './ContratoDashboardConstantes';
import { IWidgetsTamanhos } from "../../../components/dashboard/widgets/interfaces/IWidget";
import IDashboardWidget from "../../../interfaces/IDashboardWidget";
import IDashboard from "../../../interfaces/IDashboard";
import { AxiosError } from "axios";
import FiltroPesquisaContratoRequest from "../../../interfaces/Requests/Contrato/FiltroPesquisaContratoRequest";
import ContratoService from "../../../services/ContratoService";
import IContrato from "../../../interfaces/IContrato";
import ContratoEtatisticas from "../../../components/dashboard/statistics/ContratoEtatisticas";
import RelatorioPadraoModal from "../Modal/RelatorioPadraoModal";

const DASHBOARDID = 1;


const ContratoDashboardPage = () => {
    const [carregandoEnvelopes, setCarregandoEnvelopes] = useState(true);

    const [estatisticas] = useState(new ContratoEtatisticas([]));
    const [dashboardId, setDashboardId] = useState(0);
    const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaContratoRequest>({
        contratoId: 0,
        pastaId: 0,

        numeroContrato: "",
        objetoContrato: "",
        observacoesContrato: "",
        codigo: "",
        parteContraria: "",

        urgente: false,
        isNovaPasta: false,

        statusContrato: [],
        statusContratoId: 0,
        statusPasta: [],
        tipoContratoId: [],
        periodicidadeId: [],
        departamentoId: [],
        gestorId: [],
        moedaId: [],
        indiceId: [],
        usuarioIdCadastro: [],
        urgenteFilter: [],

        dataInicioVigenciaMaiorIgual: "",
        dataInicioVigenciaMenorIgual: "",

        dataFimVigenciaMaiorIgual: "",
        dataFimVigenciaMenorIgual: "",

        dataCadastroMaiorIgual: dataInicialPadrao(),
        dataCadastroMenorIgual: new Date().toISOString(),

        dataAprovacaoMaiorIgual: "",
        dataAprovacaoMenorIgual: "",

        dataAssinaturaMaiorIgual: "",
        dataAssinaturaMenorIgual: "",

        dataCanceladoMaiorIgual: "",
        dataCanceladoMenorIgual: "",

        valorTotalMaiorIgual: 0,
        valorTotalMenorIgual: 0,

        offset: 0,
        limit: 1000000,
        sort: "dataCadastro",
        totalItems: 0,

        modeloRelatorioId: 0
    });

    const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

    const [widgetOrdem, setWidgetOrdem] = useState<string[]>([
        'Envelopes - Dia',
        'Quantidade Por Status - Período',
        'Envelopes - Mês',
        'Envelopes Assinados - Dia',
        'Envelopes Assinados - Mês',
        'Tempo Médio de Espera  - Dia',
        'Tempo Médio de Espera - Mês'
    ]);
    
    const [widgetTamanhos, setWidgetTamanho] = useState<IWidgetsTamanhos[]>([
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '4'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '4'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '4'
        },
    ]);

    async function salvar(widgets: IDashboardWidget[]) {
        Swal.fire({
            title: 'Salvando...',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        });
        Swal.showLoading();
        try {
            let resultado: IDashboard;
            if (dashboardId === 0) {
                resultado = await DashboardService.adicionarDashboard({
                    dashboard: DASHBOARDID,
                    widgets: widgets
                } as IDashboard);
            } else {
                resultado = await DashboardService.salvarDashboard({
                    widgetPreferenciasId: dashboardId,
                    dashboard: DASHBOARDID,
                    widgets: widgets
                });
            }
            Swal.fire({
                title: 'Salvo!',
                text: 'Os widgets foram salvos com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });

            setDashboardId(resultado.widgetPreferenciasId as number);
            setWidgetOrdem(resultado.widgets.map(w => w.nome));
            setWidgetTamanho(resultado.widgets.map(w => w as IWidgetsTamanhos));
        } catch (err) {
            const error = err as AxiosError
            Swal.fire({
                title: 'Erro!',
                text: (error && error.response && error.response.data && typeof error.response.data === 'string' ? error.response.data : error.response?.data.message) || 'Ocorreu um erro ao salvar os widgets!',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
                allowEnterKey: true,
                allowEscapeKey: true,
                allowOutsideClick: true
            });
            throw (error);
        }
    }

    async function getWidgets(): Promise<void> {
        try {
            const dashboardConfig = await DashboardService.recuperarDashboard(DASHBOARDID);

            setWidgetOrdem(dashboardConfig.widgets.map(widget => widget.nome));
            setDashboardId(dashboardConfig.widgetPreferenciasId || 0);
            setWidgetTamanho(dashboardConfig.widgets.map(widget => {
                return {
                    xl: widget.xl,
                    lg: widget.lg,
                    md: widget.md,
                    sm: widget.sm,
                    xs: widget.xs
                }
            }));

        } catch (e) {
            console.log(e)
        }
    }

    function dataInicialPadrao(): string {
        const hoje = new Date();
        const dt = new TimeDelta({ months: -hoje.getMonth(), days: 1 - hoje.getDate() });
        return dt.addToDate(hoje).toISOString();
    }

    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                codigo: '',
                contratoId: 0,
                nomeDocumento: '',
                dataAssinaturaMaiorIgual: '',
                dataAssinaturaMenorIgual: '',
                dataCanceladoMaiorIgual: '',
                dataCanceladoMenorIgual: '',
                dataCadastroMaiorIgual: '',
                dataCadastroMenorIgual: '',
                status: 0,
            }
        });
    }

    async function carregarEnvelopes(filtro: FiltroPesquisaContratoRequest): Promise<void> {
        await estatisticas.atualiza(async () => {
            try {
                setCarregandoEnvelopes(true);
                let resultado: IRecuperaLista<IContrato>;

                resultado = await ContratoService.obterContratos(filtro);

                setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
                setCarregandoEnvelopes(false);
                return resultado.data;
            } catch (error) {
                return [];
            }
            finally {
                setCarregandoEnvelopes(false);
            }
        });
    }

    useEffect(() => {
        getWidgets();
        carregarEnvelopes(filtrosPesquisa);
    }, []);

    return <>
        <LayoutPageTitle title="Contratos - Dashboard" />

        <RelatorioPadraoModal
            exibirModal={exibirConfirmarModeloModal}
            toggleModal={toggleConfimarModeloModal}
            filtro={filtrosPesquisa}
            setFiltro={setFiltrosPesquisa}
        />

        <div className="row mt-2">
            <div className="col-md-12">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    carregarEnvelopes(filtrosPesquisa);
                }} className="row g-3 mb-3 form-buscar-envelope">
                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro</label>
                        <DateRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            maxDate={new Date()}
                            value={
                                (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                                    [
                                        filtrosPesquisa.dataCadastroMaiorIgual,
                                        filtrosPesquisa.dataCadastroMenorIgual,
                                    ] : ''
                            }
                            onChange={(datas: Date[]) => {
                                if (!datas) {
                                    setFiltrosPesquisa(oldValue => {
                                        return {
                                            ...oldValue,
                                            dataCadastroMaiorIgual: '',
                                            dataCadastroMenorIgual: '',
                                        }
                                    });
                                    return
                                }

                                let data_inicial = datas[0];
                                let data_final = datas[1];
                                if (data_final) {
                                    data_final.setHours(0, 0, 0, 0);
                                    data_final = new Date(data_final.getTime() - 1)
                                }
                                setFiltrosPesquisa(oldValue => {

                                    return {
                                        ...oldValue,
                                        dataCadastroMaiorIgual: (data_inicial) ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                        dataCadastroMenorIgual: (data_final) ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">Data da Assinatura</label>
                        <DateRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            maxDate={new Date()}
                            value={
                                (filtrosPesquisa.dataAssinaturaMaiorIgual && filtrosPesquisa.dataAssinaturaMenorIgual) ?
                                    [
                                        filtrosPesquisa.dataAssinaturaMaiorIgual,
                                        filtrosPesquisa.dataAssinaturaMenorIgual,
                                    ] : ''
                            }
                            onChange={(datas: Date[]) => {
                                if (!datas) {
                                    setFiltrosPesquisa(oldValue => {
                                        return {
                                            ...oldValue,
                                            dataAssinaturaMaiorIgual: '',
                                            dataAssinaturaMenorIgual: '',
                                        }
                                    });
                                    return
                                }
                                let data_inicial = datas[0];
                                let data_final = datas[1];
                                if (data_final) {
                                    data_final.setHours(0, 0, 0, 0);
                                    data_final = new Date(data_final.getTime() - 1)
                                }

                                setFiltrosPesquisa(oldValue => {

                                    return {
                                        ...oldValue,
                                        dataAssinaturaMaiorIgual: (data_inicial) ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                        dataAssinaturaMenorIgual: (data_final) ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                    }
                                })

                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">Data do Cancelamento</label>
                        <DateRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            maxDate={new Date()}
                            value={
                                (filtrosPesquisa.dataCanceladoMaiorIgual && filtrosPesquisa.dataCanceladoMenorIgual) ?
                                    [
                                        filtrosPesquisa.dataCanceladoMaiorIgual,
                                        filtrosPesquisa.dataCanceladoMenorIgual,
                                    ] : ''
                            }
                            onChange={(datas: Date[]) => {
                                if (!datas) {
                                    setFiltrosPesquisa(oldValue => {
                                        return {
                                            ...oldValue,
                                            dataCanceladoMaiorIgual: '',
                                            dataCanceladoMenorIgual: '',
                                        }
                                    });
                                    return
                                }


                                let data_inicial = datas[0];
                                let data_final = datas[1];
                                if (data_final) {
                                    data_final.setHours(0, 0, 0, 0);
                                    data_final = new Date(data_final.getTime() - 1)
                                }

                                setFiltrosPesquisa(oldValue => {
                                    return {
                                        ...oldValue,
                                        dataCanceladoMaiorIgual: (data_inicial) ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                        dataCanceladoMenorIgual: (data_final) ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                    }
                                })

                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código</label>
                        <input value={filtrosPesquisa.codigo} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, codigo: e.target.value } });
                        }} placeholder="Código" className={'form-control'} id="form-codigo" />
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-status" className="form-label fw-bolder text-orange">Status</label>
                        <select value={filtrosPesquisa.statusContratoId} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                        }} placeholder="Status" className={'form-select'} id="form-status" >
                            <option value={0}>Todos</option>
                            <option value={1}>Em Elaboração</option>
                            <option value={3}>Em Assinatura</option>
                            <option value={4}>Assinado</option>
                            <option value={5}>Cancelado</option>
                        </select>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-nome-documento" className="form-label fw-bolder text-orange">Número do Contrato</label>
                        <input value={filtrosPesquisa.numeroContrato} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, nomeDocumento: e.target.value } });
                        }} placeholder="Número do Contrato" className={'form-control'} id="form-nome-documento" />
                    </div>
                    <div className="col-12 "
                        style={{ textAlign: 'right' }}>
                        <button
                            type="submit"
                            className="btn btn-orange search-buttom-margin "
                            title="Buscar"
                        >
                            <FontAwesomeIcon color='white' className='' icon={faSearch} />
                        </button>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                limparFiltros();
                            }}
                            className="btn btn-orange search-buttom-margin ms-2"
                            title="Limpar Filtro"
                        >
                            <FontAwesomeIcon color='white' className='' icon={faEraser} />
                        </button>
                        <button
                            onClick={() => toggleConfimarModeloModal()}
                            className="btn btn-sm btn-orange search-buttom-margin ms-2"
                            disabled={carregandoEnvelopes}>
                            <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                        </button>
                    </div>
                </form>
            </div>
            <div className="col-md-12 mb-10">
                {estatisticas &&
                    <EstatisticasContextoProvider
                        estatisticas={estatisticas}
                    >
                        <Dashboard
                            widgets={constants.WIDGETS}
                            widgetOrdens={widgetOrdem}
                            widgetTamanhos={widgetTamanhos}
                            carregando={carregandoEnvelopes}
                            dashboadID={DASHBOARDID}
                            onDashboardChanged={getWidgets}
                            salvar={salvar}
                        />
                    </EstatisticasContextoProvider>
                }
            </div>
        </div>
    </>;
}

export default ContratoDashboardPage;