import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IMotivoDesligamento from "../interfaces/IMotivoDesligamento";
import { AdicionaMotivoDesligamentoRequest } from "../interfaces/Requests/MotivoDesligamento/AdicionaMotivoDesligamentoRequest";
import FiltroPesquisaMotivoDesligamentoRequest from "../interfaces/Requests/MotivoDesligamento/FiltroPesquisaMotivoDesligamentoRequest";
import { EditarMotivoDesligamentoRequest } from "../interfaces/Requests/MotivoDesligamento/EditarMotivoDesligamentoRequest";

class MotivoDesligamentoService {

    async obterMotivoDesligamentos(filtros: FiltroPesquisaMotivoDesligamentoRequest): Promise<IRecuperaLista<IMotivoDesligamento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IMotivoDesligamento>>(`MotivoDesligamento/RecuperaMotivoDesligamento?${query}`);

        return result;
    }

    async adicionaMotivoDesligamento(adicionaMotivoDesligamento: AdicionaMotivoDesligamentoRequest): Promise<IMotivoDesligamento> {
        let { data: result } = await api.post<IMotivoDesligamento>(`MotivoDesligamento/AdicionaMotivoDesligamento`, adicionaMotivoDesligamento);

        return result;
    }


    async alterarStatus(motivoDesligamentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IMotivoDesligamento>(`MotivoDesligamento/AtualizaMotivoDesligamentoPorId?id=${motivoDesligamentoId}`, data);
    }

    async atualizarMotivoDesligamento(editarMotivoDesligamento: EditarMotivoDesligamentoRequest): Promise<void> {

        let dataMotivoDesligamento = [
            { "op": "replace", "path": "/nome", "value": editarMotivoDesligamento.nome },
            { "op": "replace", "path": "/codigo", "value": editarMotivoDesligamento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`MotivoDesligamento/AtualizaMotivoDesligamentoPorId?id=${editarMotivoDesligamento.motivoDesligamentoId}`, dataMotivoDesligamento)
        ]);
    }
}


export default new MotivoDesligamentoService();