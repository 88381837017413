import { faEdit, faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import AndamentoField from "../../../../components/Comum/Andamento/AndamentoField";
import GridPadrao from "../../../../components/Comum/GridPadrao";
import IAndamentos from "../../../../interfaces/IAndamentos";
import IEmpresa from "../../../../interfaces/IEmpresa";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import FiltroPesquisaAndamentoRequest from "../../../../interfaces/Requests/Andamento/FiltroPesquisaAndamentoRequest";
import AndamentoService from "../../../../services/AndamentoService";
import useIsMounted from "../../hooks/useIsMounted";
import { EModulo } from "../../../../enum";
interface IAndamentoProps {
  empresa?: IEmpresa
}

const Andamento = ({ empresa }: IAndamentoProps) => {

  const [exibirListaAndamento, setExibirListaAndamento] = useState<boolean>(false);
  const [exibirModalAndamento, setExibirModalAndamento] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const [atualizaTabelaAndamento, setAtualizaTabelaAndamento] = useState<boolean>(false);
  const [andamentos, setAndamentos] = useState<IAndamentos[]>([]);
  const [carregandoAndamentos, setCarregandoAndamentos] = useState(false);

  const toggleAndamento = () => setExibirModalAndamento(!exibirModalAndamento);

  const [editarAndamento, setEditarAndamento] = useState<IAndamentos>({
    processoParteContraria: [],
    contratoId: 0,
    andamentoId: 0,
    andamentoTipoId: 0,
    modulo: EModulo.Societario,
    codigo: "",
    dataAndamento: new Date(),
    andamentoTipoNome: "",
    descricao: "",
    restrito: false,
    dataCadastro: "",
    xFinderAndamentoTipoExterno: "",
    numeroContrato: "",
  });

  const [filtrosAndamento, setFiltrosAndamento] = useState<FiltroPesquisaAndamentoRequest>({
    areaDireitoId: [],
    empresaId: 0,
    tipoPesquisa: 1,
    filtro: "",
    limit: 10,
    ultimosQuantidade: null,
    totalItems: 0,
    offset: 0,
    sort: "-DataAndamento",
    status: [1],
    modulo: 5,
    descricao: "",
    andamentoTipoId: [],
    dataAndamentoMaiorIgual: "",
    dataAndamentoMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
  });

  const colunasAndamentos: TableColumn<IAndamentos>[] = [
    {
      cell: (row: IAndamentos) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                className="form-label fw-bolder text-orange"
                style={{
                  paddingTop: "30px",
                }}
              >
                #{row.andamentoId} - {row.andamentoTipoNome} - {moment(row.dataCadastro).format("DD/MM/yyyy")}
              </p>
              <p>{row.descricao}</p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Data do andamento: </b> {moment(row.dataAndamento).format("DD/MM/yyyy")}
                <br />
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Restrito: </b> {row.restrito ? "Sim" : "Não"}
                <br />
              </p>
              <p>
                <>
                  <FontAwesomeIcon
                    title="Remover Andamento"
                    onClick={async () => {
                      const swalWithBootstrapButtons = Swal.mixin({
                        heightAuto: false,
                        customClass: {
                          confirmButton: "btn btn-orange",
                          cancelButton: "btn btn-danger ms-5",
                        },
                        buttonsStyling: false,
                      });

                      let result = await swalWithBootstrapButtons.fire({
                        title: "Remover",
                        text: `Você realmente deseja remover?`,
                        showCancelButton: true,
                        cancelButtonText: "Cancelar",
                        confirmButtonText: `Confirmar`,
                      });

                      if (result.isConfirmed) {
                        AndamentoService.inativarAndamento(row.andamentoId);
                        carregarAndamentos(filtrosAndamento);
                      }
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faTrash}
                  />
                  <FontAwesomeIcon
                    title="Editar Andamento"
                    onClick={async () => {
                      setEditarAndamento({
                        modulo: EModulo.AreaDireito,
                        andamentoId: row.andamentoId,
                        codigo: row.codigo,
                        descricao: row.descricao,
                        andamentoTipoId: row.andamentoTipoId,
                        dataAndamento: row.dataAndamento,
                        contratoId: row.contratoId,
                        restrito: row.restrito,
                        andamentoTipoNome: "",
                        dataCadastro: "",
                        xFinderAndamentoTipoExterno: "",
                        numeroContrato: "",
                        processoParteContraria: row.processoParteContraria,
                      });
                      setExibirModalAndamento(true);
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faEdit}
                  />
                </>
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];


  const carregarAndamentos = async (filtro: FiltroPesquisaAndamentoRequest) => {
    try {

      if (!empresa?.empresaId) return;
      setCarregandoAndamentos(true);

      let resultado: IRecuperaLista<IAndamentos>;
      resultado = await AndamentoService.obterAndamentos({ ...filtro, empresaId: empresa?.empresaId });

      if (resultado.data != null) setAndamentos(resultado.data);
      else setAndamentos([]);

      setFiltrosAndamento((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

    } catch (error: any) {
      setAndamentos([]);
    } finally {
      setAtualizaTabelaAndamento(false);
      setCarregandoAndamentos(false);
    }
  };

  const handlePerRowsChangeAndamento = async (currentRowsPerPage: number) => {
    setFiltrosAndamento((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChangeAndamento = (page: number) => {
    setFiltrosAndamento((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosAndamento.limit };
    });
  };

  const handleSortAndamento = async (column: TableColumn<IAndamentos>, sortDirection: string) => {
    setFiltrosAndamento((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  useEffect(() => {
    setFiltrosAndamento((oldState) => {
      return { ...oldState, ultimosQuantidade: 0 };
    });
    carregarAndamentos(filtrosAndamento);
  }, [filtrosAndamento.offset, filtrosAndamento.limit, filtrosAndamento.sort]);

  useEffect(() => {
    if (atualizaTabelaAndamento) {
      carregarAndamentos(filtrosAndamento);
    }
    setAtualizaTabelaAndamento(false);
  }, [atualizaTabelaAndamento]);

  useEffect(() => {
    if (isMounted()) {
      carregarAndamentos(filtrosAndamento);
    }
  }, [])

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12 mt-2">
          <label
            style={{ cursor: "pointer" }}
            onClick={() => setExibirListaAndamento(!exibirListaAndamento)}
            htmlFor="form-cliente"
            className="form-label fw-bolder text-orange"
          >
            Últimos Andamentos {exibirListaAndamento ? "-" : "+"}
          </label>
        </div>
        {exibirListaAndamento && (
          <>
            <div className="col-md-12">
              <button
                onClick={() => {
                  setEditarAndamento({ ...editarAndamento, andamentoId: 0 });
                  setExibirModalAndamento(!exibirModalAndamento);
                }}
                className="btn btn-orange"
              >
                {exibirModalAndamento && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                {!exibirModalAndamento && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
              </button>
            </div>
            <div className="col-md-auto mt-3">
              <input
                value={filtrosAndamento.filtro}
                onChange={(e) => {
                  setFiltrosAndamento((oldState) => {
                    return { ...oldState, filtro: e.target.value };
                  });
                }}
                placeholder="Andamentos"
                type="text"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto mt-3">
              <input
                value={filtrosAndamento.ultimosQuantidade || ""}
                type="number"
                maxLength={3}
                onChange={(e) => {
                  setFiltrosAndamento((oldState) => {
                    return { ...oldState, ultimosQuantidade: parseInt(e.target.value, 10) };
                  });
                }}
                placeholder="+ Quantidade"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto mt-2">
              <button onClick={() => carregarAndamentos(filtrosAndamento)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>
            <div className="col-md-auto mt-2">{<> Buscar por: </>}</div>
            <div className="col-md-auto mt-2">
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosAndamento({ ...filtrosAndamento, tipoPesquisa: 1 })}
                  label="Nome"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosAndamento({ ...filtrosAndamento, tipoPesquisa: 2 })}
                  label="Código"
                  name="group1"
                  type="radio"
                />
              </div>
            </div>
            <div className="col-md-12 mb-5">
              <GridPadrao
                onSort={handleSortAndamento}
                progressPending={carregandoAndamentos}
                limit={filtrosAndamento.limit}
                onChangePage={handlePageChangeAndamento}
                onChangeRowsPerPage={handlePerRowsChangeAndamento}
                paginationServer={true}
                paginationTotalRows={filtrosAndamento.totalItems}
                colunas={colunasAndamentos}
                tipo="Andamentos"
                itens={andamentos}
              />
            </div>
          </>
        )}
      </div>
      <AndamentoField
        editarAndamento={editarAndamento}
        exibirModal={exibirModalAndamento}
        toggleModal={toggleAndamento}
        modulo={EModulo.Societario}
        empresaId={empresa?.empresaId}
        tipoSociedadeId={empresa?.tipoSociedadeId}
        setAtualizarTabela={setAtualizaTabelaAndamento}
      />


    </>
  )
}

export default memo(Andamento);