import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPraca from "../interfaces/IPraca";
import { AdicionaPracaRequest } from "../interfaces/Requests/Praca/AdicionaPracaRequest";
import FiltroPesquisaPracaRequest from "../interfaces/Requests/Praca/FiltroPesquisaPracaRequest";
import { EditarPracaRequest } from "../interfaces/Requests/Praca/EditarPracaRequest";

class PracaService {

    async obterPracas(filtros: FiltroPesquisaPracaRequest): Promise<IRecuperaLista<IPraca>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IPraca>>(`Praca/RecuperaPraca?${query}`);

        return result;
    }

    async adicionaPraca(adicionaPraca: AdicionaPracaRequest): Promise<IPraca> {
        let { data: result } = await api.post<IPraca>(`Praca/AdicionaPraca`, adicionaPraca);

        return result;
    }


    async alterarStatus(pracaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPraca>(`Praca/AtualizaPracaPorId?id=${pracaId}`, data);
    }

    async atualizarPraca(editarPraca: EditarPracaRequest): Promise<void> {

        let dataPraca = [
            { "op": "replace", "path": "/nome", "value": editarPraca.nome },
            { "op": "replace", "path": "/codigo", "value": editarPraca.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Praca/AtualizaPracaPorId?id=${editarPraca.pracaId}`, dataPraca)
        ]);
    }
}


export default new PracaService();