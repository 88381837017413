import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoServico from "../interfaces/ITipoServico";
import { AdicionaTipoServicoRequest } from "../interfaces/Requests/TipoServico/AdicionaTipoServicoRequest";
import FiltroPesquisaTipoServicoRequest from "../interfaces/Requests/TipoServico/FiltroPesquisaTipoServicoRequest";
import { EditarTipoServicoRequest } from "../interfaces/Requests/TipoServico/EditarTipoServicoRequest";

class TipoServicoService {

    async obterTipoServicos(filtros: FiltroPesquisaTipoServicoRequest): Promise<IRecuperaLista<ITipoServico>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoServico>>(`TipoServico/RecuperaTipoServico?${query}`);

        return result;
    }

    async adicionaTipoServico(adicionaTipoServico: AdicionaTipoServicoRequest): Promise<ITipoServico> {
        let { data: result } = await api.post<ITipoServico>(`TipoServico/AdicionaTipoServico`, adicionaTipoServico);

        return result;
    }


    async alterarStatus(tipoServicoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoServico>(`TipoServico/AtualizaTipoServicoPorId?id=${tipoServicoId}`, data);
    }

    async atualizarTipoServico(editarTipoServico: EditarTipoServicoRequest): Promise<void> {

        let dataTipoServico = [
            { "op": "replace", "path": "/nome", "value": editarTipoServico.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoServico.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoServico/AtualizaTipoServicoPorId?id=${editarTipoServico.tipoServicoId}`, dataTipoServico)
        ]);
    }
}


export default new TipoServicoService();