import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import { EModulo, EPropriedadesIDEnum, ETelaEnum } from "../../enum";
import { EPeriodoEnum } from "../../enum/EPeriodoEnum";
import LayoutPageForButton from "../../layout/LayoutPageButton";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaContratoRequest } from "../../interfaces/Requests/Contrato/IAdicionaContratoRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaPropriedadesTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ContratoService from "../../services/ContratoService";
import TipoContratoService from "../../services/TipoContratoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";
import TipoContratoGrupoService from "../../services/TipoContratoGrupoService";

import { OrangeContext } from "../../contexts/OrangeProvider";
import { setValuesXCadastroCampos, defaultSchema, setValuesCampo } from "../../utils/SchemasUtil";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";
import { contratoInitialValues, tipoContratoInicialValues } from "../../contexts/InitialValuesContext";
import { notifyDanger, notifyFormikDanger, notifyWarning } from "../../components/Comum/Toast/ToastFormik";

const ContratoCadastroPage = () => {

  const navigate = useNavigate();
  const { contrato, setContrato, setTipoContrato, tipoContrato } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoTipos, setCarregandoTipos] = useState<boolean>(false);
  const [carregandoGrupos, setCarregandoGrupos] = useState<boolean>(false);
  const [carregandoPropriedades, setCarregandoPropriedades] = useState<boolean>(false);

  const [existeGrupo, setExisteGrupo] = useState<boolean>(true);
  const [defineIsNovaPasta, setDefineIsNovaPasta] = useState<boolean>(false);

  const [options, setOptions] = useState<any[]>([]);
  const [optionGrupos, setOptionGrupos] = useState<any[]>([]);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [propriedadesTela, setPropriedades] = useState<IRecuperaPropriedadesTelaResponse[]>([]);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);

  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const initialValues: IAdicionaContratoRequest = {
    contratoId: 0,
    tipoContratoId: 0,
    tipoContratoGrupoId: 0,
    isnovapasta: defineIsNovaPasta,
    modulo: EModulo.Default,
    tela: ETelaEnum.Default,
    prazovigencia: EPeriodoEnum.DIA,
    categoriaId: 0,
    nomepasta: "",
    numerocontrato: "",
    pastaid: 0,
    objetocontrato: "",
    datainiciovigencia: null,
    periodicidadeId: 0,
    cidadeId: 0,
    estadoId: 0,
    datafimvigencia: null,
    observacoescontrato: "",
    departamentoId: 0,
    gestorId: 0,
    urgente: false,
    restrito: false,
    bancoId: 0,
    agencia: "",
    conta: "",
    moedaId: 0,
    indiceId: 0,
    valortotal: 0,
    dataaprovacao: null,
    dataassinatura: null,
    codigo: "",
    advogadoInternoList: [],
    empresasList: [],
    centrosCusto: [],
    partesContrariasList: [],
    xCampoValorList: [],
    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
    quantidadeperiodicidade: 0,
    tipoVigenciaId: 0
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {


        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Contrato",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          setAdicionarLoading(true);
          let resultado = await ContratoService.adicionaContrato2({
            ...values,
            tipoContratoId: tipoContrato.tipoContratoId,
            isnovapasta: !defineIsNovaPasta,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          }, values.centrosCusto);

          setContrato(resultado);

          setTipoContrato(tipoContrato);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Contrato cadastrado com sucesso`,
            timer: 4000,
          });
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
      finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setContrato(contratoInitialValues);

    setTipoContrato(tipoContratoInicialValues);

    setConfiguracoesTela([]);

    carregarPropriedadesTela();

  }, []);

  useEffect(() => {
    if (tipoContrato.tipoContratoId > 0) {
      carregarConfiguracaoTela();
    } else {

      setConfiguracoesTela([]);

      setTipoContrato(tipoContratoInicialValues);

      formik.setValues(initialValues);
    }
  }, [tipoContrato.tipoContratoId]);

  useEffect(() => {

    if (formik.values.tipoContratoGrupoId > 0) {

      carregarTipoContrato();

      setContrato(contratoInitialValues);

      setConfiguracoesTela([]);

    } else {

      setContrato(contratoInitialValues);

      setTipoContrato(tipoContratoInicialValues);

      setConfiguracoesTela([]);

    }
  }, [formik.values.tipoContratoGrupoId]);

  useEffect(() => {
    let existeGrupo = propriedadesTela?.find(e => e.nome == EPropriedadesIDEnum.CopiarContratoPrincipal);
    if (formik.values.contratoId > 0 && existeGrupo) {
      iniciarCapa(formik.values.contratoId);
    }

  }, [formik.values.contratoId]);

  useEffect(() => {
    let existeGrupo = propriedadesTela?.find(e => e.nome == EPropriedadesIDEnum.TipoContratoGrupo);

    if (existeGrupo) { ///validar se existe tipo de grupo

      formik.setFieldValue('tipoContratoGrupoId', 0);

      carregarTipoContratoGrupo();

      setExisteGrupo(true);

    } else {

      formik.setFieldValue('tipoContratoGrupoId', 0);

      carregarTipoContrato();

      setExisteGrupo(false);

    }

  }, [defineIsNovaPasta, propriedadesTela?.length]);

  const carregarTipoContrato = async () => {
    try {
      let options: any[] = [{ label: 'Selecione', value: 0 }];
      setCarregandoTipos(true);
      if (defineIsNovaPasta) {
        let resultado = await TipoContratoService.obterTipoContratos({
          nome: "",
          tipoContratoGrupoId: formik.values.tipoContratoGrupoId,
          codigo: "",
          status: 1,
          categoriaNot: 1,
          limit: 100,
          totalItems: 0,
          offset: 0,
          sort: "nome",
        });
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoContratoId }))
        setDefineIsNovaPasta(true);
        setOptions(options);
      } else {
        let resultado = await TipoContratoService.obterTipoContratos({
          nome: "",
          tipoContratoGrupoId: formik.values.tipoContratoGrupoId,
          codigo: "",
          status: 1,
          categoria: 1,
          limit: 100,
          totalItems: 0,
          offset: 0,
          sort: "nome",
        });
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoContratoId }))
        setOptions(options);
        setDefineIsNovaPasta(false);
      }
    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoTipos(false);
      setConfiguracoesTela([]);
    }
  };

  const carregarTipoContratoGrupo = async () => {
    try {
      let options: any[] = [{ label: 'Selecione', value: 0 }];
      setCarregandoGrupos(true);
      let resultado = await TipoContratoGrupoService.obterTipoContratoGrupo({
        nome: "",
        codigo: "",
        status: 1,
        categoriaNot: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoContratoGrupoId }));
      setOptionGrupos(options);
    } catch (error: any) {
      setOptionGrupos([]);
    } finally {
      setCarregandoGrupos(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        TipoContratoId: tipoContrato.tipoContratoId,
        Modulo: EModulo.Contrato,
        Tela: ETelaEnum.TelaCadastroContrato,
      });
      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
      notifyWarning("Nenhum campo configurado");
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const iniciarCapa = async (contratoId: number) => {
    try {
      setCarregandoConfiguracoesTela(true);

      const capa = await ContratoService.obterCapaContratoPorId(contratoId);

      setValuesCampo(capa.data, formik);

    } catch (error: any) {
      notifyDanger('Erro ao carregar capa do contrato:' + error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const carregarPropriedadesTela = async () => {
    try {
      setCarregandoPropriedades(true);
      let resultado = await ConfiguracaoTelaService.obterPropriedadesTela(EModulo.Contrato);
      setPropriedades(resultado.data);
    } catch (error) {
      setPropriedades([]);
    } finally {
      setCarregandoPropriedades(false);
    }
  };

  const onChangeTipoContrato = (value: number, texto: string) => {
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoContratoId", value);
    formik.setFieldValue("categoriaId", value);
    formik.setFieldValue("modulo", EModulo.Contrato);
    formik.setFieldValue("tela", ETelaEnum.TelaCadastroContrato);

    setTipoContrato({
      tipoContratoId: value,
      nome: texto,
      checked: false,
      codigo: "",
      status: "",
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  const renderTipoInstrumento = () => {
    return (existeGrupo && <>
      <div className="row mt-2">
        {carregandoGrupos ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando grupos...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Contratação:
            </label>
            <Select
              value={{
                label: optionGrupos.find((e) => e.value === formik.values.tipoContratoGrupoId)?.label,
                value: formik.values.tipoContratoGrupoId,
              }}
              onChange={(option: any) => formik.setFieldValue('tipoContratoGrupoId', option.value)}
              options={optionGrupos} />
          </div>
        )}
      </div>
    </>
    );
  }




  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Novo Contrato ${tipoContrato.tipoContratoId > 0 ? " - " + tipoContrato.nome : ""}`}>

        {contrato.contratoId > 0 ? (
          <>
            <button onClick={() => navigate("/Contrato/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{contrato.contratoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (

          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }
              }
              disabled={adicionarLoading}
              style={{ display: !formik.values.tipoContratoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>)}

      </LayoutPageForButton>


      <div className="row mt-2">
        <div className="col-md-3 mt-3">
          <div className="form-label fw-bolder text-orange">
            {<> Deseja vincular esta solicitação à uma pasta de contrato já existente? </>}
            <></>
            <div key={`inline-radio`} className="fw-bolder text-orange">
              <Form.Check inline checked={defineIsNovaPasta} className="form-radio" onClick={() => setDefineIsNovaPasta(true)} label="Sim" name="group1" type="radio" />
              <Form.Check inline checked={!defineIsNovaPasta} className="form-radio" onClick={() => setDefineIsNovaPasta(false)} label="Não" name="group1" type="radio" />
            </div>
          </div>
        </div>
      </div>

      {renderTipoInstrumento()}

      <div className="row mt-2">

        {carregandoTipos || carregandoPropriedades || carregandoGrupos ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de contrato...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Contrato:
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoContratoId)?.label,
                value: formik.values.tipoContratoId,
              }}
              onChange={(option: any) => onChangeTipoContrato(option.value, option.label)}
              options={options} />
          </div>
        )}

      </div>

      <hr></hr>

      {formik.values.tipoContratoId > 0 && (
        <>
          {renderCampos()}
        </>
      )}

    </>
  );
}

export default ContratoCadastroPage;

