import { useContext, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Carregando from "../../components/Carregando";
import { useAuth } from "../../contexts/AuthProvider";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { contratoInitialValues } from "../../contexts/InitialValuesContext";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

const LinkEditorPage = () => {
  const navigate = useNavigate();
  const { email, hash, id } = useParams();
  const { authenticateExternal, isAuthorized } = useAuth();
  const { setContrato } = useContext<OrangeInterfaceContext>(OrangeContext);

  const external = useCallback(async (email: string, hash: string) => {
    try {
      initialRemoveClassCardLogin();
      await authenticateExternal(email, hash);
      navigate('/Contrato/Editor');
    } catch (error) {
      window.location.href = '/';
    }
  }, [authenticateExternal, navigate]);

  const initialRemoveClassCardLogin = useCallback(() => {
    const element = document.getElementById("card-login");
    if (element) {
      element.classList.remove("card-login");
    }
  }, []);

  useEffect(() => {
    if (email && hash && !isAuthorized) {
      external(email, hash);
    } else {
      navigate('/Contrato/Editor');
    }
  }, [email, hash, isAuthorized, navigate, external]);

  useEffect(() => {
    if (id) {
      setContrato({ ...contratoInitialValues, contratoId: parseInt(id) });
      sessionStorageService.inserir({ ...contratoInitialValues, contratoId: parseInt(id) }, "contrato_capa");
    }
  }, [id, setContrato]);

  return <Carregando />;
};

export default LinkEditorPage;
