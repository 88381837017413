import { OverlayTrigger, OverlayTriggerProps, Tooltip as BsTooltip } from "react-bootstrap"

type TooltipProps = {
  content: string;
} & Omit<OverlayTriggerProps, 'overlay'>

const tooltip = (content: string, place?: any) => (
  <BsTooltip placement={place} className="in" id="tooltip-bottom">
    <span>{content}</span>
  </BsTooltip>
);

export const Tooltip = (props: TooltipProps) => {
  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={tooltip(props.content, props.placement)}
    >
      {props.children}
    </OverlayTrigger>
  )
}