import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IFormaParticipacao from "../interfaces/IFormaParticipacao";
import { AdicionaFormaParticipacaoRequest } from "../interfaces/Requests/FormaParticipacao/AdicionaFormaParticipacaoRequest";
import { EditarFormaParticipacaoRequest } from "../interfaces/Requests/FormaParticipacao/EditarFormaParticipacaoRequest";

class FormaParticipacaoService {

    async obterFormaParticipacaos(filtros: any): Promise<IRecuperaLista<IFormaParticipacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFormaParticipacao>>(`FormaParticipacao/RecuperaFormaParticipacao?${query}`);

        return result;
    }

    async adicionaFormaParticipacao(adicionaFormaParticipacao: AdicionaFormaParticipacaoRequest): Promise<IFormaParticipacao> {
        let { data: result } = await api.post<IFormaParticipacao>(`FormaParticipacao/AdicionaFormaParticipacao`, adicionaFormaParticipacao);

        return result;
    }


    async alterarStatus(formaParticipacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IFormaParticipacao>(`FormaParticipacao/AtualizaFormaParticipacaoPorId?id=${formaParticipacaoId}`, data);
    }

    async atualizarFormaParticipacao(editarFormaParticipacao: EditarFormaParticipacaoRequest): Promise<void> {

        let dataFormaParticipacao = [
            { "op": "replace", "path": "/nome", "value": editarFormaParticipacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarFormaParticipacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`FormaParticipacao/AtualizaFormaParticipacaoPorId?id=${editarFormaParticipacao.formaParticipacaoId}`, dataFormaParticipacao)
        ]);
    }
}


export default new FormaParticipacaoService();