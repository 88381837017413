import { useContext, useEffect, useRef, useState } from "react";
import { faPrint, faShare, faHistory, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { defaultSchema } from "../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaPropriedadesTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../enum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import DocumentoService from "../../services/DocumentoService";
import BloqueioService from "../../services/BloqueioService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCapaSolucoes from "../../components/Comum/Solucao/CamposCapaSolucoes";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import { EPropriedadesTela } from "../../enum/EPropriedadesTela";
import { AdicionaBloqueioRequest } from "../../interfaces/Requests/Bloqueio/AdicionaBloqueioRequest";

const BloqueioCapaPage = () => {
  const { bloqueio, tipoBloqueio, setBloqueio } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);
  const [propriedadesTela, setPropriedadesTela] = useState<IRecuperaPropriedadesTelaResponse[]>([]);

  const componentRef = useRef<any>();

  const compartilharWhatSapp = () => {
    return `Número do Bloqueio: *#${bloqueio.bloqueioId}* / ${tipoBloqueio.tipoBloqueioId > 0
      ? "Tipo de Bloqueio: *" + tipoBloqueio.nome + "*"
      : ""
      }  / Status: *${bloqueio.statusBloqueioNome == ""
        ? "Ativo*"
        : nomearStatusBloqueio(bloqueio.statusBloqueioNome)
      }*`;
  };

  const nomearStatusBloqueio = (categoria: string) => {
    switch (categoria) {
      case "EmElaboracao":
        return "Em Elaboração";
      case "EmAprovacao":
        return "Em Aprovação";
      case "EmAssinatura":
        return "Em Assinatura";
      case "Assinado":
        return "Assinado";
      case "Cancelado":
        return "Cancelado";
      default:
        return "Não definido";
    }
  };

  const initialValues: AdicionaBloqueioRequest = {
    bloqueioId: bloqueio.bloqueioId,
    principalId: bloqueio.bloqueioId,
    categoriaId: bloqueio.categoriaId || 0,
    modulo: EModulo.Bloqueio,

    tela: ETelaEnum.TelaCapaBloqueio,

    processoId: bloqueio.processoId || 0,
    areaDireitoId: bloqueio.areaDireitoId || 0,

    imovelId: bloqueio.imovelId || 0,
    tipoImovelId: bloqueio.tipoImovelId || 0,

    tipoBloqueioId: bloqueio.tipoBloqueioId,

    atoSocietarioId: 0,

    descricao: "",
    databloqueio: new Date(),
    datadesbloqueio: null,
    bancoId: null,
    agencia: "",
    conta: "",
    moedaId: null,
    valor: 0,
    codigo: "",

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!bloqueio.bloqueioId) return;

    carregaCapa();

    formik.setValues(initialValues);

  }, [bloqueio.bloqueioId, refreshCapa]);

  useEffect(() => {
    if (!bloqueio.bloqueioId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [bloqueio]);


  const inserirSessionStorage = () => sessionStorageService.inserir(bloqueio, "bloqueio_capa");


  const carregarSessionStorage = async () => setBloqueio(JSON.parse((await sessionStorageService.obter("bloqueio_capa")) || null));


  const carregaCapa = async () => {
    try {
      
      setCarregandoConfiguracoesTela(true);

      let resultado = await BloqueioService.obterCapaBloqueio(bloqueio.bloqueioId);
      setConfiguracoesTela(resultado.data);
      setPropriedadesTela(resultado.dataPropriedades);
      setCarregandoConfiguracoesTela(false);

    } catch (error: any) {
      console.log(error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (bloqueioId: number) => {
    try {
      let resultado = await BloqueioService.adicionaFavorido(bloqueioId);
      setBloqueio({ ...bloqueio, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await BloqueioService.removerFavorido(favoritoId);
      setBloqueio({ ...bloqueio, favoritoId: 0 });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const baixarFormulario = async () => {

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });

    Swal.showLoading();

    try {
      await DocumentoService.downloadRelatorioCustomizado({ bloqueioId: bloqueio.bloqueioId, chaveRelatorioCustomizado: EPropriedadesTela.BancoMasterFormularioContasPagar })
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", 'Formulario - Fornecedores Master.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaSolucoes
          controller={"bloqueio"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${bloqueio.bloqueioId}  ${tipoBloqueio.tipoBloqueioId > 0 ? " - " + tipoBloqueio.nome : ""
            } => ${bloqueio.statusBloqueioNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a
                style={{ cursor: "pointer", fontSize: "30px" }}
                onClick={() => setRefreshCapa(!refreshCapa)}
              >
                {
                  <FontAwesomeIcon
                    color={"#DC853D"}
                    className="mx-2"
                    icon={faHistory}
                  />
                }
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span
                className="indicator-progress"
                style={{
                  display: "block",
                  fontSize: "30px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faPrint}
                  title="Imprimir o bloqueio"
                />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {
              <FontAwesomeIcon
                color={"#DC853D"}
                className="mx-2"
                icon={faShare}
              />
            }
          </a>
          {propriedadesTela.find(e => e.codigo == EPropriedadesTela.BancoMasterFormularioContasPagar) &&
            <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => baixarFormulario()}>
              {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faMoneyBill} title="Formulário" />}
            </a>
          }
          {bloqueio.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(bloqueio.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o bloqueio"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(bloqueio.bloqueioId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o bloqueio"
            ></a>
          )}


          <>
            <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={
              bloqueio.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}>

              <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
                <span className="navigation-service-icon navigation-service-icon-grid"></span>
              </a>
            </OverlayTrigger>
          </>

        </LayoutPageButton>

        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderCampos()}
            <CompromissoListField modulo={EModulo.Bloqueio} setRefreshCapa={setRefreshCapa}
            />
            <FileListCapaField modulo={EModulo.Bloqueio} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela}
            />
          </>
        )}

      </div>
    </>
  );
};
export default BloqueioCapaPage;
