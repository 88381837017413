import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IGestaoProcesso from "../interfaces/IGestaoProcesso";
import { AdicionaGestaoProcessoRequest } from "../interfaces/Requests/GestaoProcesso/AdicionaGestaoProcessoRequest";
import FiltroPesquisaGestaoProcessoRequest from "../interfaces/Requests/GestaoProcesso/FiltroPesquisaGestaoProcessoRequest";
import { EditarGestaoProcessoRequest } from "../interfaces/Requests/GestaoProcesso/EditarGestaoProcessoRequest";

class GestaoProcessoService {

    async obterGestaoProcessos(filtros: FiltroPesquisaGestaoProcessoRequest): Promise<IRecuperaLista<IGestaoProcesso>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IGestaoProcesso>>(`GestaoProcesso/RecuperaGestaoProcesso?${query}`);

        return result;
    }

    async adicionaGestaoProcesso(adicionaGestaoProcesso: AdicionaGestaoProcessoRequest): Promise<IGestaoProcesso> {
        let { data: result } = await api.post<IGestaoProcesso>(`GestaoProcesso/AdicionaGestaoProcesso`, adicionaGestaoProcesso);

        return result;
    }


    async alterarStatus(gestaoProcessoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IGestaoProcesso>(`GestaoProcesso/AtualizaGestaoProcessoPorId?id=${gestaoProcessoId}`, data);
    }

    async atualizarGestaoProcesso(editarGestaoProcesso: EditarGestaoProcessoRequest): Promise<void> {

        let dataGestaoProcesso = [
            { "op": "replace", "path": "/nome", "value": editarGestaoProcesso.nome },
            { "op": "replace", "path": "/codigo", "value": editarGestaoProcesso.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`GestaoProcesso/AtualizaGestaoProcessoPorId?id=${editarGestaoProcesso.gestaoProcessoId}`, dataGestaoProcesso)
        ]);
    }
}


export default new GestaoProcessoService();