import { useEffect, useState } from "react";
import Select from "react-select";

import IEstado from "../../../interfaces/IEstado";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import EstadoService from "../../../services/EstadoService";

import { styleSelect } from "../../../utils/Utils";
import IOption from "../../../interfaces/IOption";



interface IFieldProps {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
  capa?: boolean;
}

const EstadoField = ({ formik, configuracaoTela, capa }: IFieldProps) => {
  const [carregandoEstados, setCarregandoEstados] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => { carregaEstado(); }, []);

  const carregaEstado = async () => {
    try {

      setCarregandoEstados(true);

      let resultado: IRecuperaLista<IEstado>;

      let estados: any[] = [{ label: 'Selecione', value: 0 }];

      resultado = await EstadoService.obterEstados({
        nome: "",
        codigo: "",
        status: 0,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setCarregandoEstados(false);

      resultado.data.map((item) => estados.push({ label: item.nome, value: item.estadoId }));

      setOptions(estados);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoEstados(false);
    }
  };

  return (
    carregandoEstados ? (
      <span className="indicator-progress" style={{ display: "block" }}>
        <span className="spinner-border text-orange spinner-border-sm align-middle ms-2"></span>
      </span>
    ) : (
      <>
        {!capa &&
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
            <a style={{ fontSize: "12px" }}>
              {configuracaoTela.obrigatorio ? "*:" : ":"}
            </a>
          </label>}
        <Select
          value={
            {
              label: options.find((e) => e.value === formik.values.estadoId)?.label,
              value: formik.values.estadoId,
            }
          }
          styles={styleSelect(formik, configuracaoTela)}
          options={options}
          onChange={(option: any) => {
            configuracaoTela.valor = option.label;
            configuracaoTela.valorId = option.value;
            formik.setFieldTouched("estadoId", true);
            formik.setFieldValue("estadoId", parseInt(option.value));
          }}
        />

        {formik.touched.estadoId && formik.errors.estadoId && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.estadoId}</span>
            </div>
          </div>)}
      </>
    )
  );
};

export default EstadoField;
