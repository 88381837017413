import { Draggable } from "react-beautiful-dnd";
import IUsuariosGrupoUsuarios from "../../../../../interfaces/IUsuariosGrupoUsuarios";

interface IUsuarioProps {
  usuario: IUsuariosGrupoUsuarios;
  index: number;
}

const Usuario = ({ usuario, index }: IUsuarioProps) => {
  return (
    <Draggable draggableId={usuario.usuarioId.toString()} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div
            style={{
              border: "1px solid var(--primary-base2)",
              backgroundColor: "var(--primary-base2)",
              borderRadius: 4,
              padding: 8,
              marginBottom: 10,
              color: "white",
              fontWeight: "bold",
            }}
          >
            {usuario.nomeUsuario}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Usuario;
