import { useEffect, useState } from "react";
import { faBackward, faBuilding, faEdit, faImage, faLink, faQuestionCircle, faTable, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { MultiSelect } from "react-multi-select-component";
import { TableColumn } from "react-data-table-component";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import Swal from "sweetalert2";
import clsx from "clsx";

import { ETipoCampoEnum } from "../../../enum/ETipoCampoEnum";
import { MASCARA_NUMERO_PROCESSO } from "../../../utils/Constants";

import CidadeService from "../../../services/CidadeService";
import { CorreiosAPI } from "../../../services/CorreiosApi";
import CamposService from "../../../services/ParteContrariaService";
import ProcessoService from "../../../services/ProcessoService";
import ProtocoloService from "../../../services/ProtocoloService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import IEmpresa from "../../../interfaces/IEmpresa";
import IProcesso from "../../../interfaces/IProcesso";
import ICentroCusto from "../../../interfaces/ICentroCusto";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";


import {
  currencyFormatter,
  currencyFormatterPerc,
  currencyLabelFormatter,
  styleSelect,
  tipoPorModulo,
  validarCep
} from "../../../utils/Utils";

import { ECamposIDEnum, returnarEnumDescricaoID } from "../../../enum/ECamposIDEnum";

import EmpresaField from "../Empresa/EmpresaField";
import CentroCustoField from "../CentroCusto/CentroCustoField";

import GridPadrao from "../GridPadrao";
import ImagemField from "../imagem/ImagemField";
import EstadoField from "../EstadoCidade/EstadoField";
import CidadeField from "../EstadoCidade/CidadeField";
import ProtocoloField from "../Protocolar/ProtocoloField";
import IFileListField from "../../FileUpload/ParteContraria/FileListFieldParteContraria";
import FormTabelaField from "../TabelaField/Modulo/FormTabelaField";
import { notifySuccess, notifyWarning } from "../Toast/ToastFormik";
import FormListTabelaField from "../TabelaField/Modulo/FormListTabelaField";
import IFileCheckListField from "../../FileUpload/ParteContraria/IFileCheckListField";
import ParteContrariaService from "../../../services/ParteContrariaService";
import { EModulo } from "../../../enum";

interface ICamposDinamicosPropos {
  formik: any;
  modulo: EModulo,
  controller: string;
  setRefreshPrincipal(atualiza: boolean): void;
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  configuracaoTelaLista: IRecuperaConfiguracaoTelaResponse[];
  setConfiguracaoTela(item: IRecuperaConfiguracaoTelaResponse[]): void;
}

const CamposCapaDinamicos = ({
  formik,
  modulo,
  controller,
  configuracaoTela,
  setConfiguracaoTela,
  setRefreshPrincipal,
  configuracaoTelaLista,
}: ICamposDinamicosPropos) => {

  const [genericId, setGenericId] = useState<number>(0);
  const [options, setOptions] = useState<any[]>([]);

  const [genericIdMulti, setGenericIdMulti] = useState<any>([]);
  const [genericIdMultiSelected, setGenericIdMultiSelected] = useState<any>([]);

  const [tipoProtocoloTribunal, setTipoProtocoloTribunal] = useState<string>('');

  const [refreshCapa, setRefreshCapa] = useState<boolean>(false);

  const [exibirTabela, setExibirTabela] = useState<boolean>(false);
  const [exibirModalImagem, setExibirModalImagem] = useState<boolean>(false);
  const [exibirModalEmpresas, setExibirModalEmpresas] = useState<boolean>(false);
  const [exibirModalCentroCusto, setExibirModalCentroCusto] = useState<boolean>(false);
  const [exibirModalProtocoloDocumento, setExibirModalProtocoloDocumento] = useState<boolean>(false);

  const toggleTabelaModal = () => setExibirTabela(!exibirTabela);

  const toggleImagemModal = () => setExibirModalImagem(!exibirModalImagem);

  const toggleEmpresaModal = () => setExibirModalEmpresas(!exibirModalEmpresas);

  const toggleCentroCustoModal = () => setExibirModalCentroCusto(!exibirModalCentroCusto);

  const toggleProtocoloDocumentoModal = () => setExibirModalProtocoloDocumento(!exibirModalProtocoloDocumento);


  const optionMulti = async () => {
    try {

      // monstar refrection
      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values?.modulo)}Id`] = formik.values?.categoriaId;

      let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
        ...objeto,
        tela: formik.values?.tela,
        campo: configuracaoTela.campo,
        modulo: formik.values.modulo
      });

      //carregar a lista de opções
      campos.data.forEach((item: any) => {
        genericIdMulti.push({ value: item.xCampoOpcaoId, label: item.nome });
      });

      //carregar a lista de opções selecionadas
      configuracaoTela.xCampoValorIdList && configuracaoTela.xCampoValorIdList.forEach((opcao: any) => {
        genericIdMultiSelected.push({ value: opcao, label: campos.data?.find(e => e.xCampoOpcaoId == opcao)?.nome });
      });

    } catch (error: any) {
      setGenericIdMulti([]);
      setGenericIdMultiSelected([])
    }
  };

  const option = async () => {
    try {
      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values?.modulo)}Id`] = formik.values?.categoriaId;
      let options: any[] = [{ label: 'Selecione', value: 0 }];
      if (configuracaoTela.isXCampo) {
        let resultado = await CamposService.obterXOptions({
          ...objeto,
          tela: formik.values?.tela,
          campo: configuracaoTela.campo,
          modulo: formik.values.modulo,
          areaDireitoId: formik.values.areaDireitoId,
          tipoImovelId: formik.values.tipoImovelId,
        });

        resultado.data.map((item) => options.push({ label: item.nome, value: item.xCampoOpcaoId }));

      } else {
        let resultado = await CamposService.obterOptions({
          controller: configuracaoTela?.campoNome,
          modulo: formik.values.modulo,
          categoriaId: objeto[`${tipoPorModulo(formik.values?.modulo)}Id`],
          areaDireitoId: formik.values.areaDireitoId,
          tipoImovelId: formik.values.tipoImovelId,
          principalId: formik.values.principalId
        });

        resultado.data.map((item) => options.push({ label: item.nome, value: item[returnarEnumDescricaoID(configuracaoTela?.campoNome)] }));
      }
      setOptions(options);
    } catch (error: any) {
      setOptions([]);
    }
  };

  const optionsTipoProtocoloTribunal = async () => {
    try {

      let options: any[] = [{ label: 'Selecione', value: 0 }];
      let resultado = await ProtocoloService.obterTipoProtocoloTribunal(
        {
          processoId: formik.values.processoId,
          tipoProtocoloId: formik.values.tipoProtocoloId,
          codigo: '',
          descricao: '',
          processoEmpresaId: 0,
          statusProtocoloId: 0,
          tipoProtocoloTribunal: '',
          clienteId: 0,
          status: [],
          limit: 100,
          totalItems: 0,
          offset: 0,
          sort: "-documentoId",
        }
      );
      resultado.data.map((item: string, index: number) => options.push({ label: item, value: index }));
      setOptions(options)
    } catch (error: any) {
      setOptions([]);
    }
  };

  const carregarCentroCusto = async () => {
    try {

      let resultado: IRecuperaLista<ICentroCusto>;
      resultado = await CamposService.obterCentroCusto(formik.values.principalId, controller);
      formik.setFieldValue("centrosCusto", resultado, true);

    } catch (error: any) {
      formik.setFieldValue("centrosCusto", [], true);
    }
  };

  const carregarListaLimitadaFormaParticipacao = async () => {
    try {

      if (modulo == EModulo.AreaDireito) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.FormaParticipacao, EModulo.AreaDireito, formik.values.areaDireitoId, 0);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.formaParticipacaoId }));
      }

      if (modulo == EModulo.Consultivo) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.FormaParticipacao, EModulo.Consultivo, formik.values.tipoConsultaId, 0);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.formaParticipacaoId }));
      }

    } catch (error: any) {
      setOptions([]);
    }
  }

  const carregarListaLimitadaPapelSignatario = async () => {
    try {

      if (modulo == EModulo.Contrato) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.PapelSignatario, EModulo.Contrato, formik.values.tipoContratoId, 0);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.papelSignatarioId }));
      }

    } catch (error: any) {
      setOptions([]);
    }
  }

  const validarNumeroProcessos = async () => {
    try {

      let resultado: IRecuperaLista<IProcesso>;

      resultado = await ProcessoService.validarExisteProcesso(formik.values.numeroprocesso);

      if (resultado.totalRegistros > 0) {
        notifyWarning("Já existe número de processo cadastrado.");
      }
    } catch (error: any) {
      formik.setFieldValue('numeroprocesso', '');
    }

  }

  useEffect(() => {

    if (configuracaoTela.campoNome == ECamposIDEnum.Estado || configuracaoTela.campoNome == ECamposIDEnum.Cidade) {
      return;
    }

    if (configuracaoTela.campoNome == ECamposIDEnum.CentroCusto || configuracaoTela.campoNome == ECamposIDEnum.CentroCustoProvisao) {
      carregarCentroCusto();
      return;
    }

    if (configuracaoTela.campoNome == ECamposIDEnum.FormaParticipacao) {
      carregarListaLimitadaFormaParticipacao();
      return;
    }

    if (configuracaoTela.campoNome == ECamposIDEnum.PapelSignatario) {
      carregarListaLimitadaPapelSignatario();
      return;
    }

    if (configuracaoTela.campoTipo == ETipoCampoEnum.Lista || configuracaoTela.campoTipo == ETipoCampoEnum.ListaComLink) {
      option();
      return;
    }

    if (configuracaoTela.campoTipo == ETipoCampoEnum.ListaMulti) {
      optionMulti();
      return;
    }

    if (configuracaoTela.campoNome == ECamposIDEnum.TipoProtocoloTribunal) {
      optionsTipoProtocoloTribunal();
      return;
    }

  }, []);

  useEffect(() => {

    if (refreshCapa) {

      if (formik.values.campoNome == ECamposIDEnum.CentroCusto) carregarCentroCusto();

    }

  }, [refreshCapa]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const adicionarEndereco = (cep: string) => {
    try {
      if (validarCep(cep)) {
        CorreiosAPI(cep)
          .then((correios) => correios.json())
          .then((correio) => {

            configuracaoTelaLista.forEach(async campo => {

              if (campo.campoNome == ECamposIDEnum.Endereco || campo.campoNome == ECamposIDEnum.Logradouro) {
                campo.valor = correio.logradouro;
                await ParteContrariaService.alterarPorCampo(ECamposIDEnum.CEP, cep, formik.values.principalId, controller);
                await ParteContrariaService.alterarPorCampo(ECamposIDEnum.Logradouro, cep, formik.values.principalId, controller);
                formik.setFieldValue("endereco", correio.logradouro);
                formik.setFieldValue("logradouto", correio.logradouto);
              }

              if (campo.campoNome == ECamposIDEnum.Cidade) {
                let resultado = await CidadeService.obterCidadePorIBGE(correio.ibge);
                campo.valor = resultado.data[0]?.cidadeId;
                await ParteContrariaService.alterarPorCampo("cidadeId", campo.valor, formik.values.principalId, controller);
                formik.setFieldValue("cidadeId", resultado.data[0]?.cidadeId);
              }

              if (campo.campoNome == ECamposIDEnum.Bairro) {
                campo.valor = correio.bairro;
                await ParteContrariaService.alterarPorCampo(ECamposIDEnum.Bairro, correio.bairro, formik.values.principalId, controller);
                formik.setFieldValue("bairro", correio.bairro);
              }
            });
            setConfiguracaoTela(configuracaoTelaLista);
          });
      }
    } catch (error) {
      console.log(error)
    }
  }

  const adicionarSelect = async (valorNovoTexto: string, valorNovoId: number, campoNome: string, salvarNaBase?: boolean) => {

    formik.setFieldValue(returnarEnumDescricaoID(campoNome), valorNovoId, true);

    setGenericId(valorNovoId);

    configuracaoTela.valor = valorNovoTexto;

    configuracaoTela.valorId = valorNovoId;

    if (configuracaoTela.alterarCampo && salvarNaBase && valorNovoId > 0) {

      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          if (configuracaoTela.isXCampo) {
            if (configuracaoTela.xCampoValorId > 0) {
              await CamposService.alterarPorXCampo(ETipoCampoEnum.xCampoOpcao, valorNovoId, configuracaoTela.xCampoValorId, controller); // OK
            } else {
              await CamposService.adicionarPorXCampoValor(configuracaoTela.campo, valorNovoId, valorNovoId, formik.values.principalId, `${controller}ParteContraria`, ETipoCampoEnum.Valor); // OK
            }
          } else {
            await CamposService.alterarPorCampo(`${returnarEnumDescricaoID(campoNome)}`, valorNovoId, formik.values.principalId, controller); // OK
          }

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();

          setRefreshCapa(true);

          setRefreshPrincipal(true);

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarMultiSelect = async (event: any, campoNome: string, salvarNaBase?: boolean) => {

    formik.setFieldValue(`${campoNome.toLowerCase()}Id`, event);

    setGenericIdMultiSelected(event);

    configuracaoTela.valor = '';

    genericIdMultiSelected.forEach((opcao: any) => { configuracaoTela.valor += `${opcao.label}, ` });

    if (configuracaoTela.alterarCampo && salvarNaBase) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await CamposService.alterarPorXCampoMulti(event, configuracaoTela.campo, controller, formik.values.principalId); // OK

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarTexto = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          if (configuracaoTela.isXCampo) {
            if (configuracaoTela.xCampoValorId > 0) {
              await CamposService.alterarPorXCampo(ETipoCampoEnum.Texto, valorNovo, configuracaoTela.xCampoValorId, controller); //OK
            }
            else {
              await CamposService.adicionarPorXCampoValor(configuracaoTela.campo, valorNovo, 0, formik.values.principalId, controller, ETipoCampoEnum.Texto); // OK
            }
          } else {
            await CamposService.alterarPorCampo(nome, valorNovo, formik.values.principalId, controller); // OK
          }

          // caso for cep... buscar nos correios :)
          if (configuracaoTela.campoNome == ECamposIDEnum.CEP) adicionarEndereco(valorNovo);

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarInteiro = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          if (configuracaoTela.isXCampo) {
            if (configuracaoTela.xCampoValorId > 0) {
              await CamposService.alterarPorXCampo(ETipoCampoEnum.Inteiro, valorNovo, configuracaoTela.xCampoValorId, controller); //OK
            }
            else {
              await CamposService.adicionarPorXCampoValor(configuracaoTela.campo, valorNovo, 0, formik.values.principalId, controller, ETipoCampoEnum.Inteiro); // OK
            }
          } else {
            await CamposService.alterarPorCampo(nome, valorNovo, formik.values.principalId, controller); //OK
          }

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarValor = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          if (configuracaoTela.isXCampo) {
            if (configuracaoTela.xCampoValorId > 0) {
              await CamposService.alterarPorXCampo(ETipoCampoEnum.Valor, valorNovo, configuracaoTela.xCampoValorId, controller); //OK
            }
            else {
              await CamposService.adicionarPorXCampoValor(configuracaoTela.campo, valorNovo, 0, formik.values.principalId, controller, ETipoCampoEnum.Valor); // OK
            }
          } else {
            await CamposService.alterarPorCampo(nome, valorNovo, formik.values.principalId, controller); // OK
          }

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarAreaTexto = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          if (configuracaoTela.isXCampo) {
            if (configuracaoTela.xCampoValorId > 0) {
              await CamposService.alterarPorXCampo(ETipoCampoEnum.Texto, valorNovo, configuracaoTela.xCampoValorId, controller); //OK
            }
            else {
              await CamposService.adicionarPorXCampoValor(configuracaoTela.campo, valorNovo, 0, formik.values.principalId, controller, ETipoCampoEnum.Texto); // OK
            }
          } else {
            await CamposService.alterarPorCampo(nome, valorNovo, formik.values.principalId, controller); // OK
          }

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarBollean = async (nome: string, valor: any) => {
    if (configuracaoTela.alterarCampo && typeof valor == "boolean") {
      formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, valor);
      configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valor });
      setConfiguracaoTela(configuracaoTelaLista);

      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          if (configuracaoTela.isXCampo) {
            await CamposService.alterarPorXCampoBolleano(ETipoCampoEnum.Boolean, valor, configuracaoTela.xCampoValorId, controller); // OK
          } else {
            await CamposService.alterarPorCampoBolleano(nome, valor, formik.values.principalId, controller); // OK
          }

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarData = async (nome: string, dataNova: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = dataNova;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: dataNova });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          if (configuracaoTela.isXCampo) {
            if (configuracaoTela.xCampoValorId > 0) {
              await CamposService.alterarPorXCampo(ETipoCampoEnum.Data, dataNova, configuracaoTela.xCampoValorId, controller);
            }
            else {
              await CamposService.adicionarPorXCampoValor(configuracaoTela.campo, dataNova, 0, formik.values.principalId, controller, ETipoCampoEnum.Data); // OK
            }
          } else {
            await CamposService.alterarPorCampo(nome, dataNova, formik.values.principalId, controller);
          }

          notifySuccess(`Alteração realizada com sucesso!`);

          Swal.close();

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarCentroCusto = async () => {
    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await CamposService.alterarPorCentroCustoList(formik.values.centrosCusto, formik.values.principalId, controller); // OK

          notifySuccess(`Centro de Custo salvo com sucesso!`);

          Swal.close();

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      } finally {
        setRefreshCapa(true);
      }
    }
  };

  const adicionarEmpresa = async () => {
    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar empresas",
          text: `Você realmente deseja editar as empresas?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await CamposService.alterarPorEmpresaList(formik.values.empresasList, formik.values.principalId, controller); // OK

          notifySuccess(`Empresas salvas com sucesso!`);

          Swal.close();
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar as empresas`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      } finally {
        setRefreshCapa(true);
      }
    }
  };

  const adicionarTabela = async (linhas: any) => {

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar Tabela",
          text: `Você realmente deseja editar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await CamposService.alterarXCampoValorTabelaPorColuna(linhas, controller, formik.values.principalId); //OK

          notifySuccess(`Linhas salvas com sucesso!`);

          Swal.close();
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar as partes`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const alterarCampo = () => {

    switch (configuracaoTela.campoTipo) {
      case ETipoCampoEnum.Lista:
        if (configuracaoTela?.campoNome == ECamposIDEnum.Estado) break;
        if (configuracaoTela?.campoNome == ECamposIDEnum.CentroCusto) adicionarCentroCusto();
        adicionarSelect(configuracaoTela.valor || "", configuracaoTela.valorId || 0, configuracaoTela?.campoNome, true);
        break;

      case ETipoCampoEnum.ListaMulti:
        adicionarMultiSelect(genericIdMultiSelected, `${configuracaoTela?.campoNome.toLowerCase()}`, true);
        break;

      case ETipoCampoEnum.Texto:
        adicionarTexto(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.AreaTexto:
        adicionarAreaTexto(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Valor:
      case ETipoCampoEnum.Percentual:
        adicionarValor(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Inteiro:
        adicionarInteiro(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Data:
        adicionarData(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Boolean:
        adicionarBollean(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Pessoa:
        if (configuracaoTela?.campoNome == ECamposIDEnum.Empresa) adicionarEmpresa();
        break;

      case ETipoCampoEnum.Empresa:
        if (configuracaoTela?.campoNome == ECamposIDEnum.Empresa) adicionarEmpresa();
        break;

      case ETipoCampoEnum.Tabela:
        adicionarTabela(eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.ListaComLink:
        adicionarSelect(configuracaoTela.valor || "", configuracaoTela.valorId || 0, configuracaoTela?.campoNome, true);
        break;

    }

    configuracaoTelaLista = configuracaoTelaLista.filter((e) => e.campo !== configuracaoTela.campo);
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 0, { ...configuracaoTela, alterarCampo: !configuracaoTela.alterarCampo });
    setConfiguracaoTela(configuracaoTelaLista);
  };

  const colunasCentroCusto: TableColumn<ICentroCusto>[] = [
    {
      name: "Id",
      sortField: "tipoCentroCustoId",
      selector: (row: ICentroCusto) => row.tipoCentroCustoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: ICentroCusto) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Percentual",
      sortField: "percentual",
      selector: (row: ICentroCusto) => (row.percentual ? row.percentual + "%" : "0%"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasPartesContrarias: TableColumn<any>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacao",
      selector: (row: any) => row.formaParticipacao || row.papelSignatario,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.tipo === "advogadoParteContraria" ? "N/A" : row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      sortField: "numeroDocumento",
      selector: (row: any) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo de Documento",
      sortField: "tipoDocumento",
      selector: (row: any) => row.tipoDocumento,
      sortable: true,
      wrap: true,
    },
  ];

  const colunasEmpresa: TableColumn<IEmpresa>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacao",
      selector: (row: any) => row.formaParticipacao || row.papelSignatario,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      sortField: "numeroDocumento",
      selector: (row: any) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo do Documento",
      sortField: "tipoDocumento",
      selector: (row: any) => row.tipoDocumento,
      sortable: true,
      wrap: true,
    },
  ];

  const renderClassName = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Tabela ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa ||
      configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  const renderModalEmpresa = () => {
    if (configuracaoTela?.campoNome === ECamposIDEnum.Empresa) {
      return (
        <>
          <EmpresaField
            exibirModal={exibirModalEmpresas}
            toggleModal={toggleEmpresaModal}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalCentroCusto = () => {
    if (configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
      return (
        <>
          <CentroCustoField
            centroCustoSelected={formik.values.centrosCusto || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalCentroCusto}
            toggleModal={toggleCentroCustoModal}
            areaDireitoId={formik.values.areaDireitoId}
            modulo={formik.values.modulo}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalProtocoloDocumentos = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.ProtocoloDocumentos) {
      return (
        <>
          <ProtocoloField
            documentosSelected={formik.values.documentos}
            exibirModal={exibirModalProtocoloDocumento}
            setFieldValue={formik.setFieldValue}
            toggleModal={toggleProtocoloDocumentoModal}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalTabela = () => {
    if (configuracaoTela?.campoTipo == ETipoCampoEnum.Tabela) {
      return (
        <>
          <FormTabelaField
            configuracaoTela={configuracaoTela}
            toggleModal={toggleTabelaModal}
            exibirModal={exibirTabela}
            formik={formik}
            capa={true}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalImagem = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Imagem) {
      return (
        <>
          <ImagemField
            exibirModal={exibirModalImagem}
            toggleModal={toggleImagemModal}
            url={configuracaoTela.valor}
          />
        </>
      );
    }
    return <></>;
  };

  const renderInputs = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
        if (configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalCentroCusto(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                Selecione os centro de custo
              </Button>
              <hr />
            </>
          );
        } else {
          component = (
            <>
              <Select
                value={
                  {
                    label: options.find((e) => e.value === genericId)?.label,
                    value: genericId,
                  }
                }
                styles={styleSelect(formik, configuracaoTela)}
                onChange={(option: any) => adicionarSelect(option.label, option.value, configuracaoTela?.campoNome)}
                options={options}
              />

              {eval(`formik.touched.${returnarEnumDescricaoID(configuracaoTela?.campoNome)}`) &&
                eval(`formik.errors.${returnarEnumDescricaoID(configuracaoTela?.campoNome)}`) && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{eval(`formik.errors.${returnarEnumDescricaoID(configuracaoTela?.campoNome)}`)}</span>
                    </div>
                  </div>
                )}
            </>
          );
        }
        break;

      case ETipoCampoEnum.ListaComLink:
        component = (
          <>
            <select
              value={genericId}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[`${returnarEnumDescricaoID(configuracaoTela?.campoNome)}`] &&
                  formik.errors[`${returnarEnumDescricaoID(configuracaoTela?.campoNome)}`], // Adicione a classe "campo-invalido" quando for inválido
              })}
              onChange={(option: any) => adicionarSelect(option.target[option.target.selectedIndex].text, option.target.value, configuracaoTela?.campoNome || "")}
            >
              {options?.map((item: any) => {
                return (<option key={item.value} value={item.value}>{item.label}  </option>);
              })}

            </select>

            {formik.touched[returnarEnumDescricaoID(configuracaoTela?.campoNome)] && formik.errors[returnarEnumDescricaoID(configuracaoTela?.campoNome)] && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors[returnarEnumDescricaoID(configuracaoTela?.campoNome)]}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.ListaMulti:
        component = (
          <>
            <MultiSelect
              isLoading={false}
              disabled={false}
              options={genericIdMulti}
              value={genericIdMultiSelected}
              onChange={(event: any) => adicionarMultiSelect(event, configuracaoTela?.campoNome || "")}
              labelledBy={"Selecione..."}
              overrideStrings={{
                selectSomeItems: "Selecione...",
                allItemsAreSelected: "Todos selecionados",
                selectAll: "Selecione todos",
                search: "Pesquise",
                selectAllFiltered: "Selecione todos (filtrados)"
              }}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`)}</span>
              </div>
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Valor:
        component = (
          <>
            <NumberFormat
              format={(value) => currencyFormatter(value, configuracaoTelaLista?.find(e => e.campoNome == ECamposIDEnum.Moeda)?.valor)}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              thousandSeparator={true}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;


      case ETipoCampoEnum.Percentual:
        component = (
          <>
            <NumberFormat
              format={currencyFormatterPerc}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              thousandSeparator={true}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="date"
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{"A data infomanda está inválida"}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              type="checkbox"
              checked={configuracaoTela.valor}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              onClick={(e: any) => (configuracaoTela.valor = e.target.checked)}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Texto:
        if (configuracaoTela?.campoNome === ECamposIDEnum.TipoProtocoloTribunal) {
          component = (
            <>
              <Select
                value={
                  {
                    label: options.find((e) => e.label === tipoProtocoloTribunal)?.label,
                    value: tipoProtocoloTribunal,
                  }
                }
                onChange={(option: any) => {
                  formik.setFieldTouched(`${configuracaoTela.campoNome.toLowerCase()}`, true);
                  formik.setFieldValue(`${configuracaoTela.campoNome.toLowerCase()}`, option.label);
                  setTipoProtocoloTribunal(option.label);
                }}
                options={options}
                styles={styleSelect(formik, configuracaoTela)}
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          );
        }
        else if (configuracaoTela?.campoNome === ECamposIDEnum.NumeroProcesso && formik.values?.esferaId == 1) {
          component = (
            <>
              <MaskedInput
                mask={MASCARA_NUMERO_PROCESSO}
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder="NNNNNNN-DD.AAAA.J.TR.OOOO"
                type="text"
                onBlur={() => validarNumeroProcessos()}
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                    formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                })}
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          )
          break;
        }
        else if (configuracaoTela?.campoNome === ECamposIDEnum.NumeroProcesso) {
          component = (
            <>
              <input
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                onBlur={() => validarNumeroProcessos()}
                className={
                  clsx("form-control", {
                    "form-control-error": configuracaoTela?.obrigatorio &&
                      formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                      formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                  })
                }
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          )
          break;
        }
        else {
          component = (
            <>
              <input
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                className={
                  clsx("form-control", {
                    "form-control-error": configuracaoTela?.obrigatorio &&
                      formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                      formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                  })
                }
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          )
        }
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <textarea
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              maxLength={5000}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
            />

            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Inteiro:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="number"
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Empresa:
        component = (
          <>
            <Button
              onClick={() => setExibirModalEmpresas(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faBuilding} />
              Selecione as empresas envolvidas
            </Button>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {"  "}
                {formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length === 0 && "Nenhum empresa selecionada"}
                {formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length === 1 &&
                  `${formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length} empresa selecionada`}
                {formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length > 1 &&
                  `${formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length} empresas selecionadas`}
              </a>
            </div>
            <hr />
          </>
        );
        break;

      case ETipoCampoEnum.Tabela:
        component = (
          <>
            <Button
              onClick={() => setExibirTabela(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faTable} />
              {configuracaoTela.label}
              {configuracaoTela.obrigatorio && "*"}
              {configuracaoTela.help && <PopoverHelper />}
            </Button>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length === 0 && "Nenhum campos selecionado"}
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length === 1 &&
                  `${formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length} campos selecionados`}
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length > 1 &&
                  `${formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length} campos selecionados`}
              </a>
            </div>

            <hr />
          </>
        );
        break;

      case ETipoCampoEnum.Imagem:
        component = (
          <>
            <Button
              onClick={() => setExibirModalImagem(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              Clique aqui para visualizar <FontAwesomeIcon style={{ cursor: "pointer" }} color={"white"} className="mx-2" icon={faImage} />
            </Button>
          </>
        )
        break;

    }
    return component;
  };

  const renderLabel = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
      case ETipoCampoEnum.ListaMulti:
        if (configuracaoTela?.campoNome == ECamposIDEnum.CentroCusto) {
          component = (
            <>
              <div className="row mt-2">
                <div className="col-md-12">
                  <GridPadrao
                    paginationServer={true}
                    colunas={colunasCentroCusto}
                    tipo="TipoCentroCusto"
                    itens={formik.values.centrosCusto}
                  />
                </div>
                <hr />
              </div>
            </>
          );
        } else if (configuracaoTela?.campoNome == ECamposIDEnum.FormaPagamento) {
          component = <b>{formik.values.formaParticipacaoNome}</b>;
        } else if (configuracaoTela?.campoNome == ECamposIDEnum.PapelSignatario) {
          component = <b>{formik.values.papelSignatarioNome}</b>;
        } else {
          component = <b>{configuracaoTela.valor}</b>;
        }

        break;

      case ETipoCampoEnum.Percentual:
        if (configuracaoTela.valor) {
          const formattedValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
            .formatToParts(configuracaoTela.valor)
            .filter((part) => part.type !== 'currency')
            .map((part) => part.value)
            .join('');

          component = <b>{formattedValue}%</b>;
        } else {
          component = <b>0,00%</b>;
        }
        break;


      case ETipoCampoEnum.Valor:
        if (configuracaoTela.valor) component = <b>{currencyLabelFormatter(configuracaoTela.valor, configuracaoTelaLista?.find(e => e.campoNome == ECamposIDEnum.Moeda)?.valor)}</b>;
        break;

      case ETipoCampoEnum.Data:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Texto:
        if (configuracaoTela?.campoNome === ECamposIDEnum.TipoProtocoloTribunal) {
          component = <b>{configuracaoTela.valor}</b>;
        } else {
          component = <b>{configuracaoTela.valor}</b>;
        }
        break;

      case ETipoCampoEnum.AreaTexto:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Boolean:
        component = <b>{configuracaoTela.valor ? "Sim" : "Não"}</b>;
        break;

      case ETipoCampoEnum.Inteiro:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Empresa:
        component = (
          <>
            <div className="row mt-2">
              <div className="col-md-12">
                <GridPadrao
                  paginationServer={true}
                  colunas={colunasEmpresa}
                  tipo="Empresa"
                  itens={formik?.values?.partesProcesso?.filter((e: any) => e.tipo == "empresa")}
                />
              </div>
              <hr />
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Tabela:
        component = (<><FormListTabelaField configuracaoTela={configuracaoTela} formik={formik} capa={true} /></>)
        break;

      case ETipoCampoEnum.ListaComLink:
        component = (
          <>
            <Button
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
              href={configuracaoTela.codigo}
              target="_blank"
            >
              {configuracaoTela.valor}<FontAwesomeIcon style={{ cursor: "pointer" }} color={"white"} className="mx-2" icon={faLink} />
            </Button>
          </>
        );
        break;

      case ETipoCampoEnum.Imagem:
        component = (
          <>
            <Button
              onClick={() => setExibirModalImagem(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              Clique aqui para visualizar <FontAwesomeIcon style={{ cursor: "pointer" }} color={"white"} className="mx-2" icon={faImage} />
            </Button>
          </>
        )
        break;
    }

    return component;
  };

  const definirInput = () => {
    let component: any[] = [];


    switch (configuracaoTela.campoNome) {

      case ECamposIDEnum.Estado:
        component.push(
          <div className={renderClassName()}>
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              {configuracaoTela.label}
              <a style={{ fontSize: "12px" }}>
                {configuracaoTela.obrigatorio ? "*:" : ":"}

                {configuracaoTela.help && <PopoverHelper></PopoverHelper>}

                {configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && (
                  <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faBackward} />
                )}

                {!configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && (
                  <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />
                )}
              </a>
            </label>
            {configuracaoTela.alterarCampo && <EstadoField configuracaoTela={configuracaoTela} formik={formik} capa={true} />}
            {!configuracaoTela.alterarCampo && (
              <b>
                <br />
                {configuracaoTela.valor}
              </b>
            )}
          </div>);
        break;

      case ECamposIDEnum.Cidade:
        component.push(
          <div className={renderClassName()}>
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              {configuracaoTela.label}
              <a style={{ fontSize: "12px" }}>
                {configuracaoTela.obrigatorio ? "*:" : ":"}

                {configuracaoTela.help && <PopoverHelper></PopoverHelper>}

                {configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && (
                  <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faBackward} />
                )}

                {!configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && (
                  <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />
                )}
              </a>
            </label>
            {configuracaoTela.alterarCampo && <CidadeField configuracaoTela={configuracaoTela} formik={formik} capa={true} />}
            {!configuracaoTela.alterarCampo && (
              <b>
                <br />
                {configuracaoTela.valor}
              </b>
            )}
          </div>);

        break;

      case ECamposIDEnum.TipoDocumento:
        component.push(<IFileListField configuracaoTela={configuracaoTela} formik={formik} />);
        break;

      case ECamposIDEnum.TipoDocumentoCheckList:
        component.push(<IFileCheckListField configuracaoTela={configuracaoTela} formik={formik} />);
        break;

      default:
        component.push(
          <>
            {renderModalTabela()}
            {renderModalImagem()}
            {renderModalEmpresa()}
            {renderModalCentroCusto()}
            {renderModalProtocoloDocumentos()}

            <div className={renderClassName()}>
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                {configuracaoTela.label}
                <a style={{ fontSize: "12px" }}>
                  {configuracaoTela.obrigatorio ? "*:" : ":"}

                  {configuracaoTela.help && <PopoverHelper></PopoverHelper>}

                  {configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && (
                    <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faBackward} />
                  )}

                  {!configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && (
                    <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />
                  )}
                </a>
              </label>
              <div className="row">
                {configuracaoTela.alterarCampo && <div className="col-md-12">{renderInputs()}</div>}
                {!configuracaoTela.alterarCampo && <div className="col-md-12">{renderLabel()}</div>}
              </div>
            </div>
          </>);
    }

    return <>{component}</>
  }

  return (definirInput());
};

export default CamposCapaDinamicos;