/* eslint-disable @typescript-eslint/no-unused-vars */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";

import { ICampo } from "../../../../../interfaces/ICampo";
import { OrangeInterfaceContext } from "../../../../../interfaces/Contexts/OrangeInterfaceContext";

import { OrangeContext } from "../../../../../contexts/OrangeProvider";

interface ItemProps {
  alterarItem(item: ICampo): void;
  item: ICampo;
  index: number;
  col: string;
}

const buscarEstilo = (isDragging: boolean, draggableStyle: any) => ({
  padding: 10,
  margin: `0 50px 15px 40px`,
  background: isDragging ? "var(--primary-base)" : "white",
  color: isDragging ? "white" : "black",
  border: `1px solid`,
  fontSize: `12px`,
  borderRadius: `5px`,
  cursor: "pointer",
  ...draggableStyle,
});

const FormLabel: React.FC<ItemProps> = ({ item, index, alterarItem, col }) => {
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoListaLimitadaCadastro,] = useState<boolean>(false);
  const [adicionarListaLImitadaCadastro,] = useState<boolean>(false);

  const { campos } = useContext<OrangeInterfaceContext>(OrangeContext);
  const valoresIniciais = {
    campo: 0,
    campoNome: item?.campoNome,
    ordenacao: item?.ordenacao,
    tipo: item?.tipo,
    entidade: "",
    label: "",
    nome: "",
    help: "",
    obrigatorio: false,
    somenteLeitura: false,
    Lista: [],
    isXCampo: false,
  } as ICampo;


  const formik = useFormik({
    initialValues: valoresIniciais,
    onSubmit: async (values, { setSubmitting }) => {
      alterarItem(values);

      campos.splice(values.ordenacao, 0, { ...values });

      formik.setValues(valoresIniciais);

      setSubmitting(false);
    },
  });

  const preencherCampos = () => {
    formik.setValues(item);
    setExibirModal(!exibirModal);
  };

  return (
    <>
      <Draggable key={item?.campo} draggableId={item?.campo?.toString()} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && <>{item?.label}</>}
                {carregandoListaLimitadaCadastro && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                onClick={() => preencherCampos()}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && !adicionarListaLImitadaCadastro && (
                  <>
                    {item?.label} {item?.obrigatorio && "*"}
                  </>
                )}
                {(carregandoListaLimitadaCadastro || adicionarListaLImitadaCadastro) && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          )
        }
      </Draggable>

     <Modal size="lg" centered={true} show={exibirModal && !carregandoListaLimitadaCadastro} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Alterar Campo: {formik.values.campoNome} - Label: {formik.values.label}</h5>
            <div
              onClick={() => {
                formik.setValues(valoresIniciais);
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form>
              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Nome
                  </label>
                  <input placeholder="Nome" {...formik.getFieldProps("label")} type="text" className={"form-control"}></input>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              onClick={() => {
                alterarItem(formik.values);
                setExibirModal(!exibirModal);
              }}
              type="button"
              className="btn btn-orange me-5"
            >
              ALTERAR
            </button>
            <button
              onClick={() => {
                setExibirModal(!exibirModal);
                formik.setValues(valoresIniciais);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormLabel;
