import { useContext, useEffect, useRef, useState } from "react";
import { faPrint, faShare, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";

import { defaultSchema } from "../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../enum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import AudienciaService from "../../services/AudienciaService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCapaSolucoes from "../../components/Comum/Solucao/CamposCapaSolucoes";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";

const AudienciaCapaPage = () => {
  const { audiencia, tipoAudiencia, setAudiencia } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);

  const componentRef = useRef<any>();

  const compartilharWhatSapp = () => {
    return `Número do Audiencia: *#${audiencia.audienciaId}* / ${tipoAudiencia.tipoAudienciaId > 0
      ? "Tipo de Decisão: *" + tipoAudiencia.nome + "*"
      : ""
      }  / Status: *${audiencia.status == ""
        ? "Ativo*"
        : nomearStatusAudiencia(audiencia.status)
      }*`;
  };

  const nomearStatusAudiencia = (categoria: string) => {
    switch (categoria) {
      case "EmElaboracao":
        return "Em Elaboração";
      case "EmAprovacao":
        return "Em Aprovação";
      case "EmAssinatura":
        return "Em Assinatura";
      case "Assinado":
        return "Assinado";
      case "Cancelado":
        return "Cancelado";
      case "Ativo":
        return "Ativo";
      default:
        return "Não definido";
    }
  };

  const initialValues: any = {
    audienciaId: audiencia.audienciaId,
    principalId: audiencia.audienciaId,
    tipoAudienciaId: audiencia.tipoAudienciaId,

    modulo: EModulo.Audiencia,
    tela: ETelaEnum.TelaCapaAudiencia,
    categoriaId: audiencia.categoriaId,

    processoId: audiencia.processoId,
    areaDireitoId: audiencia.areaDireitoId,

    codigo: "",

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!audiencia.audienciaId) return;

    carregaCapa();

    formik.setValues(initialValues);

  }, [audiencia.audienciaId, refreshCapa]);

  useEffect(() => {
    if (!audiencia.audienciaId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [audiencia]);


  const inserirSessionStorage = () => sessionStorageService.inserir(audiencia, "audiencia_capa");


  const carregarSessionStorage = async () => setAudiencia(JSON.parse((await sessionStorageService.obter("audiencia_capa")) || null));


  const carregaCapa = async () => {
    try {
      if (!audiencia.audienciaId) return;
      setCarregandoConfiguracoesTela(true);
      let resultado = await AudienciaService.obterCapaAudiencia(audiencia.audienciaId);
      setConfiguracoesTela(resultado.data);
      setCarregandoConfiguracoesTela(false);
    } catch (error: any) {
      console.log(error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (audienciaId: number) => {
    try {
      let resultado = await AudienciaService.adicionaFavorido(audienciaId);
      setAudiencia({ ...audiencia, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await AudienciaService.removerFavorido(favoritoId);
      setAudiencia({ ...audiencia, favoritoId: 0 });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaSolucoes
          controller={"audiencia"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${audiencia.audienciaId}  ${tipoAudiencia.tipoAudienciaId > 0 ? " - " + tipoAudiencia.nome : ""
            } => ${audiencia.status}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a
                style={{ cursor: "pointer", fontSize: "30px" }}
                onClick={() => setRefreshCapa(!refreshCapa)}
              >
                {
                  <FontAwesomeIcon
                    color={"#DC853D"}
                    className="mx-2"
                    icon={faHistory}
                  />
                }
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span
                className="indicator-progress"
                style={{
                  display: "block",
                  fontSize: "30px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faPrint}
                  title="Imprimir o audiencia"
                />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {
              <FontAwesomeIcon
                color={"#DC853D"}
                className="mx-2"
                icon={faShare}
              />
            }
          </a>

          {audiencia.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(audiencia.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o audiencia"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(audiencia.audienciaId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o audiencia"
            ></a>
          )}

          <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="bottom"
            overlay={audiencia.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}
          >
            <a
              style={{ cursor: "pointer", marginRight: "10px" }}
              className="navigation-services-list-link"
            >
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderCampos()}
            <hr />
            <CompromissoListField modulo={EModulo.Audiencia} setRefreshCapa={setRefreshCapa}
            />
            <hr />
            <FileListCapaField modulo={EModulo.Audiencia} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela}
            />
          </>
        )}

      </div>
    </>
  );
};
export default AudienciaCapaPage;
