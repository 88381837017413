import { EModulo, ETipoCampoEnum } from "../enum";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import ICentroCusto from "../interfaces/ICentroCusto";
import IEmpresa from "../interfaces/IEmpresa";
import IEmpresaLicenca from "../interfaces/IEmpresaContrato";
import IParteContraria from "../interfaces/IParteContraria";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import FiltroPesquisaLicencaRequest from "../interfaces/Requests/Licenca/FiltroPesquisaLicencaRequest";
import { IAdicionaLicencaRequest } from "../interfaces/Requests/Licenca/IAdicionaLicencaRequest";
import api from "./Api";

class LicencaService {

  public async adicionaLicenca(adicionaLicenca: IAdicionaLicencaRequest): Promise<ILicenca> {
    let { data: result } = await api.post<ILicenca>(`Licenca/AdicionaLicenca`, this.montarObjetoAdicionarLicenca(adicionaLicenca));
    return result;
  }

  public async editarLicenca(editarLicenca: IAdicionaLicencaRequest) {
    let request = this.montarObjetoArrayLicenca(editarLicenca);
    if (request.length > 0) {
      let result = await api.patch(`Licenca/AtualizaLicencaPorId?id=${editarLicenca.licencaId}`, request);
      return result;
    }
  }

  public async alterarProcessoPorCentroCustoList(centrosCusto: ICentroCusto[], licencaId: number) {
    if (centrosCusto.length > 0) {
      await api.put<IAdicionaLicencaRequest>(`Licenca/AtualizaCentroCusto`, {
        licencaId: licencaId,
        centrosCusto: centrosCusto,
      });
    }
  }

  public async obterLicencas(filtros: FiltroPesquisaLicencaRequest): Promise<IRecuperaLista<ILicenca>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.numeroLicenca) {
      query += `&numeroLicenca=${filtros.numeroLicenca}`;
    }

    if (filtros.canal) {
      query += `&canal=${filtros.canal}`;
    }

    if (filtros.fistel) {
      query += `&fistel=${filtros.fistel}`;
    }

    if (filtros.licencaId) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.nomeSocio) {
      query += `&nomeSocio=${filtros.nomeSocio}`;
    }

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<ILicenca>>(`Licenca/RecuperaListagemLicenca?${query}`);

    return result;
  }

  public async obterLimiteOutourga(filtros: FiltroPesquisaLicencaRequest): Promise<IRecuperaLista<ILimiteOutorga>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.numeroLicenca) {
      query += `&numeroLicenca=${filtros.numeroLicenca}`;
    }

    if (filtros.canal) {
      query += `&canal=${filtros.canal}`;
    }

    if (filtros.fistel) {
      query += `&fistel=${filtros.fistel}`;
    }

    if (filtros.licencaId) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.nomeSocio) {
      query += `&nomeSocio=${filtros.nomeSocio}`;
    }

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<ILimiteOutorga>>(`Licenca/RecuperaListagemLimiteOutorgas?${query}`);

    return result;
  }

  async gerarExcelLimiteOutourga(relatorioId: number): Promise<Blob> {
    const { data: result } = await api.get(`Licenca/GerarRelatorioLimitesOutorgas?relatorioId=${relatorioId}`, { responseType: 'blob' });
    return result;
  }

  async obterCentroCusto(licencaId: number): Promise<IRecuperaLista<ICentroCusto>> {
    let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`/Licenca/RecuperaCentroCustoPorId?licencaId=${licencaId}`);
    return result;
  }

  public async alterarLicencaPorCampo(campo: string, valor: any, licencaId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaLicencaRequest>(`Licenca/AtualizaLicencaPorId?id=${licencaId}`, request);
    }
  }

  public async alterarLicencaPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaLicencaRequest>(`Licenca/AtualizaXCampoValor?id=${xcampoId}`, request);
    }
  }

  public async alterarLicencaPorCampoBolleano(campo: string, valor: any, licencaId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaLicencaRequest>(`Licenca/AtualizaLicencaPorId?id=${licencaId}`, request);
  }

  public async alterarLicencaPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaLicencaRequest>(`Licenca/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`/Favorito/RecuperaFavorito`);

    return result;
  }

  public async alterarLicencaPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], licencaId: number) {

    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        const request = {
          licencaId: licencaId,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<IAdicionaLicencaRequest>("Licenca/AtualizaPartesLicencaPorId", request);
      }
    }
  }

  public async alterarLicencaPorEmpresaList(empresasList: IEmpresa[], licencaId: number) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        const request = {
          licencaId: licencaId,
          empresasList: empresasRequestList,
        };
        await api.put<IAdicionaLicencaRequest>("Licenca/AtualizaPartesLicencaPorId", request);
      }
    }
  }

  public async alterarLicencaPorParteLicencaList(partesLicencasList: IParteContraria[], licencaId: number) {
    const partesLicencasRequestList: any[] = [];
    partesLicencasList.forEach((parteLicenca) => {
      let requestParteLicenca: any = {};
      if (parteLicenca.bairro) requestParteLicenca.bairro = parteLicenca.bairro;
      if (parteLicenca.celular) requestParteLicenca.celular = parteLicenca.celular;
      if (parteLicenca.cep) requestParteLicenca.cep = parteLicenca.cep;
      if (parteLicenca.cidadeId) requestParteLicenca.cidadeId = parteLicenca.cidadeId;
      if (parteLicenca.codigo) requestParteLicenca.codigo = parteLicenca.codigo;
      if (parteLicenca.complemento) requestParteLicenca.complemento = parteLicenca.complemento;
      if (parteLicenca.documento) requestParteLicenca.documento = parteLicenca.documento;
      if (parteLicenca.email) requestParteLicenca.email = parteLicenca.email;
      if (parteLicenca.endereco) requestParteLicenca.endereco = parteLicenca.endereco;
      if (parteLicenca.estadoId) requestParteLicenca.estadoId = parteLicenca.estadoId;
      if (parteLicenca.papelSignatarioId) requestParteLicenca.papelSignatarioId = parteLicenca.papelSignatarioId;
      if (parteLicenca.papelSignatarioNome) requestParteLicenca.papelSignatarioNome = parteLicenca.papelSignatarioNome;
      if (parteLicenca.logradouro) requestParteLicenca.logradouro = parteLicenca.logradouro;
      if (parteLicenca.nome) requestParteLicenca.nome = parteLicenca.nome;
      if (parteLicenca.nomeSocial) requestParteLicenca.nomeSocial = parteLicenca.nomeSocial;
      if (parteLicenca.numero) requestParteLicenca.numero = parteLicenca.numero;
      if (parteLicenca.observacoesGerais) requestParteLicenca.observacoesGerais = parteLicenca.observacoesGerais;
      if (parteLicenca.pessoaId) requestParteLicenca.pessoaId = parteLicenca.pessoaId;
      if (parteLicenca.principal) requestParteLicenca.principal = parteLicenca.principal;
      if (parteLicenca.telefone) requestParteLicenca.telefone = parteLicenca.telefone;
      if (parteLicenca.tipoDocumento) requestParteLicenca.tipoDocumento = parteLicenca.tipoDocumento;
      partesLicencasRequestList.push(requestParteLicenca);
    });

    const request = {
      licencaId: licencaId,
      partesLicencasList: partesLicencasRequestList,
    }
    await api.put<IAdicionaLicencaRequest>("Licenca/AtualizaPartesLicencaPorId", request);

  }

  public async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  public async adicionaFavorido(licencaId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.Regulatorio,
      licencaId: licencaId,
    });
    return result;
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  public async obterAdvogadosInterno(licencaId: number): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/Licenca/RecuperaAdvogadoInternoPorId?licencaId=${licencaId}`);

    return result;
  }

  public async obterLicencaPorId(licencaId: number): Promise<IRecuperaLista<ILicenca>> {
    let { data: result } = await api.get<IRecuperaLista<ILicenca>>(`/Licenca/RecuperaListagemLicenca?&licencaId=${licencaId}`);
    return result;
  }

  public async obterCapaLicenca(licencaId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Licenca/RecuperaCapaLicenca?licencaId=${licencaId}`
    );

    return result;
  }

  public async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);

    return result;
  }


  public concluirCompromisso = async (licenca: IAdicionaLicencaRequest): Promise<any> => {

    let result: any[] = [];

    // Salvar campos normais
    await this.editarLicenca(licenca);

    // Salvar campos Pessoas
    if (licenca.advogadoInternoList?.length > 0) result.push(await this.alterarLicencaPorAdvogadoInternoList(licenca.advogadoInternoList, licenca.licencaId));

    // Salvar xcampos
    if (licenca.xCampoValorList?.length > 0) {
      licenca.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, licencaId: licenca.licencaId, texto: xCampo.texto }));
            else result.push(await this.alterarLicencaPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, licencaId: licenca.licencaId, data: xCampo.data }));
            else result.push(await this.alterarLicencaPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, licencaId: licenca.licencaId, valor: xCampo.valor }));
            else result.push(await this.alterarLicencaPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, licencaId: licenca.licencaId, inteiro: xCampo.inteiro }));
            else result.push(await this.alterarLicencaPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) await result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, licencaId: licenca.licencaId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
            else await result.push(await this.alterarLicencaPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
            break;

          default:
            break;
        }
      });
    }

    return result;
  };
  

  private montarObjetoArrayLicenca = (licenca: IAdicionaLicencaRequest) => {
    let request: any = [];

    // Campos Texto
    if (licenca.numerolicenca) request.push({ op: "replace", path: "/numerolicenca", value: licenca.numerolicenca });
    if (licenca.canal) request.push({ op: "replace", path: "/canal", value: licenca.canal });
    if (licenca.fistel) request.push({ op: "replace", path: "/fistel", value: licenca.fistel });
    if (licenca.observacoeslicenca) request.push({ op: "replace", path: "/observacoeslicenca", value: licenca.observacoeslicenca });
    if (licenca.numerolicencadiacritics) request.push({ op: "replace", path: "/numerolicencadiacritics", value: licenca.numerolicencadiacritics });


    // Campos Boleano
    if (licenca.orgaoResponsavelId) request.push({ op: "replace", path: "/orgaoresponsavelId", value: licenca.orgaoResponsavelId });
    if (licenca.cidadeId) request.push({ op: "replace", path: "/cidadeId", value: licenca.cidadeId });
    if (licenca.tipoServicoId) request.push({ op: "replace", path: "/tiposervicoId", value: licenca.tipoServicoId });
    if (licenca.restrito) request.push({ op: "replace", path: "/restrito", value: licenca.restrito });

    // Campos Data
    if (licenca.dataemissao) request.push({ op: "replace", path: "/dataemissao", value: licenca.dataemissao });
    if (licenca.dataConcessao) request.push({ op: "replace", path: "/dataConcessao", value: licenca.dataConcessao });
    if (licenca.datavencimento) request.push({ op: "replace", path: "/datavencimento", value: licenca.datavencimento });

    return request;
  };

  private montarObjetoAdicionarLicenca = (licenca: IAdicionaLicencaRequest) => {
    let request: any = {};

    // XCampos
    if (licenca.xCampoValorList.length > 0) request.xCampoValorList = licenca.xCampoValorList;

    // Campos texto
    if (licenca.numerolicenca) request.numeroLicenca = licenca.numerolicenca;
    if (licenca.observacoeslicenca) request.observacoesLicenca = licenca.observacoeslicenca;
    if (licenca.numerolicencadiacritics) request.numeroLicencaDiacritics = licenca.numerolicencadiacritics;
    if (licenca.fistel) request.fistel = licenca.fistel;
    if (licenca.canal) request.canal = licenca.canal;

    // Campos bool
    request.restrito = licenca.restrito ? true : false;

    // Campos data
    if (licenca.dataemissao) request.dataemissao = licenca.dataemissao;
    if (licenca.datavencimento) request.dataVencimento = licenca.datavencimento;
    if (licenca.dataConcessao) request.dataConcessao = licenca.dataConcessao;

    // Campos lista
    if (licenca.cidadeId) request.cidadeId = licenca.cidadeId;
    if (licenca.tipoLicencaId) request.tipoLicencaId = licenca.tipoLicencaId;
    if (licenca.orgaoResponsavelId) request.orgaoResponsavelId = licenca.orgaoResponsavelId;
    if (licenca.tipoServicoId) request.tipoServicoId = licenca.tipoServicoId;

    // Campos Pessoa e Empresa
    if (licenca.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      licenca.advogadoInternoList
        .filter((e: IAdvogadoInterno) => e.checked)
        .forEach((item: IAdvogadoInterno) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (licenca.empresasList.filter((e: IEmpresaLicenca) => e.checked).length > 0) {
      request.empresasList = [];
      licenca.empresasList
        .filter((e: IEmpresaLicenca) => e.checked)
        .forEach((item: IEmpresaLicenca) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          request.empresasList.push(empresa);
        });
    }

    return request;
  };

  private montarListas = (filtros: any, query: string) => {

    if (filtros.empresaId.length > 0) filtros.empresaId.forEach((id: any) => (query += `&empresa=${id}`));
    if (filtros.cidadeId.length > 0) filtros.cidadeId.forEach((id: any) => (query += `&cidadeId=${id}`));
    if (filtros.estadoId.length > 0) filtros.estadoId.forEach((id: any) => (query += `&estadoId=${id}`));
    if (filtros.statusLicencaId.length > 0) filtros.statusLicencaId.forEach((id: any) => (query += `&statusLicencaId=${id}`));
    if (filtros.tipoLicencaId.length > 0) filtros.tipoLicencaId.forEach((id: any) => (query += `&TipoLicencaId=${id}`));
    if (filtros.orgaoResponsavelId.length > 0) filtros.orgaoResponsavelId.forEach((id: any) => (query += `&OrgaoResponsavelId=${id}`));
    if (filtros.tipoServicoId.length > 0) filtros.tipoServicoId.forEach((id: any) => (query += `&TipoServicoId=${id}`));

    return query;
  };

  private montarDatas = (filtros: any, query: string) => {
    if (filtros.dataEmissaoMaiorIgual && filtros.dataEmissaoMenorIgual) {
      query += `&dataEmissaoMaiorIgual=${filtros.dataEmissaoMaiorIgual}&dataEmissaoMenorIgual=${filtros.dataEmissaoMenorIgual}`;
    }
    if (filtros.dataVencimentoMaiorIgual && filtros.dataVencimentoMenorIgual) {
      query += `&dataVencimentoMaiorIgual=${filtros.dataVencimentoMaiorIgual}&dataVencimentoMenorIgual=${filtros.dataVencimentoMenorIgual}`;
    }
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    return query;
  };



}

export default new LicencaService();
