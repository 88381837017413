import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { Modal, ModalProps, Spinner } from "react-bootstrap";
import { Formats } from "./styles";
import { IoMdPaper } from "react-icons/io";
import {
  MdClose,
  MdOutlineFileCopy,
  MdSwapHoriz,
  MdTextFormat,
} from "react-icons/md";
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  PdfSection,
  SizeF,
} from "@syncfusion/ej2-pdf-export";
import { useTenant } from "../../../../hooks/useTenant";
import { useFileUpload } from "../../hooks/useFileUpload";
import { useState } from "react";
import { useTitle } from "../../hooks/useTitle";

type ExportModalProps = ModalProps & {
  editor?: DocumentEditor;
};

type Format = "Docx" | "Dotx" | "Html" | "Txt" | "Pdf";

export function ExportModal({ editor, ...props }: ExportModalProps) {
  const { caller } = useTenant();
  const { docTitle } = useTitle()
  const { isLoading, upload } = useFileUpload();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  function getPDF(): Promise<PdfDocument> {
    return new Promise((resolve, reject) => {
      if (!editor) {
        reject(new Error("Editor is not defined"));
        return;
      }

      let pdfdocument: PdfDocument = new PdfDocument();
      let count: number = editor.pageCount;
      editor.documentEditorSettings.printDevicePixelRatio = 2;
      let loadedPage = 0;

      for (let i = 1; i <= count; i++) {
        setTimeout(() => {
          let image = editor.exportAsImage(i, "Jpeg");
          image.onload = function () {
            let imageHeight = parseInt(
              image.style.height.toString().replace("px", "")
            );
            let imageWidth = parseInt(
              image.style.width.toString().replace("px", "")
            );

            let section: PdfSection = pdfdocument.sections.add() as PdfSection;
            let settings: PdfPageSettings = new PdfPageSettings(0);
            if (imageWidth > imageHeight) {
              settings.orientation = PdfPageOrientation.Landscape;
            }
            settings.size = new SizeF(imageWidth, imageHeight);
            (section as PdfSection).setPageSettings(settings);

            let page = section.pages.add();
            let graphics = page.graphics;
            let imageStr = image.src.replace("data:image/jpeg;base64,", "");
            let pdfImage = new PdfBitmap(imageStr);
            graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);

            loadedPage++;
            if (loadedPage === count) {
              resolve(pdfdocument);
            }
          };

          image.onerror = function (error) {
            reject(error);
          };
        }, 500);
      }
    });
  }

  function getFilename(extension?: string) {
    let documentName = docTitle;
    if (extension) {
      documentName += `.${extension}`;
    }
    return documentName;
  }

  async function attachFile() {
    setIsExporting(true);
    const document = await getPDF();
    const { blobData } = await document.save();
    const filename = getFilename("pdf");
    const file = new File([blobData], filename);
    setIsExporting(false);

    await upload(file);
    props.onHide?.();
  }

  async function save(format: Format) {
    if (format === "Pdf") {
      setPdfLoading(true);
      const document = await getPDF();
      const filename = getFilename("pdf");
      document.save(filename);
      setPdfLoading(false);
    } else {
      const filename = getFilename(format.toLowerCase());
      editor?.save(filename, format);
    }

    props.onHide?.();
  }

  const items = [
    {
      id: 'docx',
      label: "Documento do Word (docx)",
      icon: IoMdPaper,
      action: () => save("Docx"),
    },
    {
      id: 'pdf',
      label: "Arquivo PDF (pdf)",
      icon: MdOutlineFileCopy,
      action: () => save("Pdf"),
    },
    {
      id: 'dotx',
      label: "Modelo do Word (dotx)",
      icon: MdSwapHoriz,
      action: () => save("Dotx"),
    },
    {
      id: 'txt',
      label: "Texto Simples (txt)",
      icon: MdTextFormat,
      action: () => save("Txt"),
    },
  ];

  const btnLoading = isLoading || isExporting;

  return (
    <Modal size="sm" centered {...props}>
      <Formats>
        <div className="header">
          <strong>Exportar como:</strong>
          <button type="button" onClick={props.onHide}>
            <MdClose />
          </button>
        </div>

        {items.map(({ icon: Icon, ...item }) => (
          <button
            className={pdfLoading && item.id === 'pdf' ? 'd-flex items-center justify-content-center' : undefined}
            disabled={pdfLoading}
            onClick={item.action}
            key={item.id}
            type="button"
          >
            {pdfLoading && item.id === 'pdf' ? (
              <Spinner size="sm" />
            ) : (
              <>
                <Icon />
                {item.label}
              </>
            )}
          </button>
        ))}

        <div className="d-flex flex-column gap-2 p-3 border-top">
          <strong>Anexar documento no {caller}</strong>
          <button
            disabled={btnLoading}
            onClick={attachFile}
            className="btn btn-orange btn-sm"
          >
            {btnLoading ? <Spinner size="sm" /> : "Anexar"}
          </button>
        </div>
      </Formats>
    </Modal>
  );
}
