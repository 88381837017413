import styled, { css } from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 20px;

  display: flex;
  justify-content: center;

  > button {
    border: none;
    color: #312e38;
    background: transparent;
    font-weight: bold;
    height: 40px;
    border-radius: 4px;
    transition: 0.3s ease;

    &:not(:disabled) {
      &:hover {
        background: #f2f2fa;
      }
    }

    &:disabled {
      opacity: 0.4;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    color: #DB4437;
  }
`

export const Input = styled.input<{ error: boolean }>`
  border: none;
  outline: none;
  border: ${(props) => (props.error ? "2px solid #DB4437" : "1px solid #ddd")};
  padding: 0 10px;
  height: 40px;
  width: 200px;
  border-radius: 4px;

  &:focus {
    ${(props) =>
      !props.error &&
      css`
        border: 2px solid #4285f4;
      `}
  }
`;
