import IAreaDireito from "../interfaces/IAreaDireito";
import ICliente from "../interfaces/ICliente";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { AdicionarClienteRequest } from "../interfaces/Requests/Cliente/AdicionaClienteRequest";
import IConfiguracaoesSeguranca from "../interfaces/Requests/Cliente/IConfiguracaoesSeguranca";
import { EditarClienteRequest } from "../interfaces/Requests/Cliente/EditarClienteRequest";
import FiltroPesquisaClienteRequest from "../interfaces/Requests/Cliente/FiltroPesquisaClienteRequest";
import api from "./Api";

class ClienteService {
  async obterClientes(filtros: FiltroPesquisaClienteRequest): Promise<IRecuperaLista<ICliente>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort
      }`;

    if (filtros.clienteId > 0) {
      query += `&clienteId=${filtros.clienteId}`;
    }

    if (filtros.pessoaId > 0) {
      query += `&pessoaId=${filtros.pessoaId}`;
    }

    if (filtros.clienteNome.length > 0) {
      query += `&nome=${filtros.clienteNome}`;
    }

    if (filtros.clienteDocumento.length > 0) {
      query += `&numeroDocumento=${filtros.clienteDocumento}`;
    }

    if (filtros.codigo.length > 0) {
      query += `&codigo=${filtros.codigo}`;
    }
    if (filtros.clienteTipo.length > 0) {
      query += `&clienteTipo=${filtros.clienteTipo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ICliente>>(`Cliente/RecuperaCliente?${query}`);

    return result;
  }

  async obterPorId(clienteId: number): Promise<IRecuperaLista<ICliente>> {
    let query = `${"&status=-1&status=1&offset=0&limit=10&sort=clienteId"}`
    if (clienteId > 0) {
      query += `&clienteId=${clienteId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ICliente>>(`Cliente/RecuperaCliente?${query}`);

    return result;
  }

  async adicionaCliente(adicionaCliente: AdicionarClienteRequest): Promise<ICliente> {

    let dataAdicionaCliente = {
      pessoaId: adicionaCliente.pessoaId,
      codigo: adicionaCliente.codigo,
      urlLogo: adicionaCliente.urlLogo,
      escritorios: adicionaCliente.escritorios,
      areasDireito: this.getIdsAreaDireito(adicionaCliente.areasDireito),
    };

    let { data: result } = await api.post<ICliente>(`Cliente/AdicionaCliente`, dataAdicionaCliente);

    return result;
  }

  async atualizarCliente(editarCliente: EditarClienteRequest): Promise<void> {
    let dataCliente = [
      { op: "replace", path: "/codigo", value: editarCliente.codigo },
      { op: "replace", path: "/urlLogo", value: editarCliente.urlLogo },
    ];

    let dataEscritorioAreaDireitos = {
      clienteId: editarCliente.clienteId,
      atualizaEscritorio: true,
      atualizaAreas: true,
      escritorios: editarCliente.escritorios,
      areasDireito: this.getIdsAreaDireito(editarCliente.areasDireito),
    };


    let dataPessoa = [
      { "op": "replace", "path": "/nome", "value": editarCliente.clienteNome },
      { "op": "replace", "path": "/nomeSocial", "value": editarCliente.clienteNomeSocial }
    ]


    await api.put<void>(`Cliente/AtualizaDadosAdicionais`, dataEscritorioAreaDireitos);
    await api.patch<ICliente>(`Cliente/AtualizaClientePorId?id=${editarCliente.clienteId}`, dataCliente);
    await api.patch<void>(`Pessoa/AtualizaPessoaPorId?id=${editarCliente.pessoaId}`, dataPessoa);

  }

  async salvarConfiguracapSeguranca(atualizarConfigSeguranca: IConfiguracaoesSeguranca): Promise<ICliente> {

    let { data: result } = await api.post<ICliente>(`Cliente/AtualizaConfiguracoesSeguranca`, atualizarConfigSeguranca);

    return result;
  }

  async obterConfiguracapSeguranca(clienteId: number): Promise<IConfiguracaoesSeguranca> {

    let { data: result } = await api.get<IConfiguracaoesSeguranca>(`Cliente/RecuperaConfiguracoesSeguranca?ClienteId=${clienteId}`);

    return result;
  }

  private getIdsAreaDireito = (areasDireito: IAreaDireito[]) => {
    let ids: any = [];
    areasDireito.forEach((area) => ids.push(area.areaDireitoId));
    return ids;
  };

  async alterarStatus(clienteId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ICliente>(`Cliente/AtualizaClientePorId?id=${clienteId}`, data);
  }

  async recuperaClientePorEscritorio(): Promise<IRecuperaLista<ICliente>> {

    let { data: result } = await api.get<IRecuperaLista<ICliente>>(`Cliente/RecuperaClientePorEscritorio`);

    return result;
  }
}

export default new ClienteService();
