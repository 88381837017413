import { faCommentDollar, faEdit, faExchangeAlt, faHandHolding, faMoneyBill, faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { Popover, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsCash, BsCashStack } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import { OrangeContext } from "../../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import { EModulo } from "../../../enum";

import {
  despesaInicialValues,
  obrigacaoInicialValues,
  propostaAcordoInicialValues,
  protocoloInitialValues,
  bloqueioInicialValues,
  decisaoInicialValues,
  desdobramentoInicialValues,
  garantiaInicialValues,
  reembolsoInicialValues,
  audienciaInicialValues,
  honorarioInicialValues,
} from "../../../contexts/InitialValuesContext";

export const PopoverSubMenuProcesso = () => {

  const {
    processo,

    setDespesa,
    setDecisao,
    setGarantia,
    setBloqueio,
    setProtocolo,
    setObrigacao,
    setDesdobramento,
    setPropostaAcordo,
    setReembolso,
    setHonorario,
    setAudiencia


  } = useContext<OrangeInterfaceContext>(OrangeContext);

  const navigate = useNavigate();

  const tooltip = (title: string) => <Tooltip id={`button-tooltip-${title}`}> {title} </Tooltip>

  return (
    <Popover id="popover-basic">

      <Popover.Body style={{ cursor: "pointer" }}>
        <p>
          <OverlayTrigger placement="bottom" overlay={tooltip("Voltar para Capa do Processo")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Processo/Capa")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Despesa</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                onClick={() => {
                  setDespesa({
                    ...despesaInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Despesa")

                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-despesa"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Obrigações</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {

                  setObrigacao({
                    ...obrigacaoInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });

                  navigate("/Obrigacao")
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-obrigacao"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Garantia</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setGarantia({
                    ...garantiaInicialValues,
                    imovelId: 0,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Garantia")
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-garantia"></span>
              </Button>
            )}
          </OverlayTrigger>
        </p>

        <p>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Proposta</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setPropostaAcordo({
                    ...propostaAcordoInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    numeroProcesso: processo.numeroProcesso,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/PropostaAcordo");
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-proposta"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Bloqueio</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setBloqueio({
                    ...bloqueioInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Bloqueio")
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-bloqueio"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Valores</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Valores")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-valores"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Desdobramento</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setDesdobramento({
                    ...desdobramentoInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Desdobramento")
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-dobrar"></span>
              </Button>
            )}
          </OverlayTrigger>
        </p>

        <p>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Honorário</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none"
                }}
                onClick={() => {
                  setHonorario({
                    ...honorarioInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Honorario");
                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faCommentDollar}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Reembolso</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                onClick={() => {
                  setReembolso({
                    ...reembolsoInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Reembolso")
                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faMoneyBillWave}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Logs</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Processo/Logs")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faExchangeAlt}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Decisão</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setDecisao({
                    ...decisaoInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Decisao")
                }} className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-decisao"></span>
              </Button>
            )}
          </OverlayTrigger>
        </p>

        <p>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Editor</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Processo/NovoEditor")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faEdit}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={tooltip("Protocolos")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setProtocolo({
                    ...protocoloInitialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    numeroProcesso: processo.numeroProcesso,
                    categoriaId: processo.areaDireitoId,
                  });
                  navigate("/Protocolo");
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-protocolo"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={tooltip("Audiência")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {

                  setAudiencia({
                    ...audienciaInicialValues,
                    modulo: EModulo.AreaDireito,
                    processoId: processo.processoId,
                    areaDireitoId: processo.areaDireitoId,
                    categoriaId: processo.areaDireitoId,
                  });

                  navigate("/Audiencia");

                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-audiencia"></span>
              </Button>
            )}
          </OverlayTrigger>

        </p>
      </Popover.Body>
    </Popover>
  );
};
