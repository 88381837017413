import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IFechamento from "../interfaces/IFechamento";
import { AdicionaFechamentoRequest } from "../interfaces/Requests/Fechamento/AdicionaFechamentoRequest";
import FiltroPesquisaFechamentoRequest from "../interfaces/Requests/Fechamento/FiltroPesquisaFechamentoRequest";
import FiltroRelatorioFechamentoRequest from "../interfaces/Requests/Fechamento/FiltroRelatorioFechamentoRequest";

class FechamentoService {

    async obterFechamentos(filtros: FiltroPesquisaFechamentoRequest): Promise<IRecuperaLista<IFechamento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=0&status=1&status=2&status=3' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.fechamentosConfiguracao) {
            if (filtros.fechamentosConfiguracao.length > 0) {
                for (let index = 0; index < filtros.fechamentosConfiguracao.length; index++) {
                    const fechamentoConfiguracaoId = filtros.fechamentosConfiguracao[index];

                    query += `&fechamentoConfiguracaoId=${fechamentoConfiguracaoId}`;
                }
            }
        }

        if (filtros.fechamentoId && filtros.fechamentoId !== 0) {
            query += `&fechamentoId=${filtros.fechamentoId}`;
        }

        if (filtros.dataCorteMaiorIgual && filtros.dataCorteMaiorIgual && filtros.dataCorteMaiorIgual.length) {
            query += `&dataCorteMaiorIgual=${filtros.dataCorteMaiorIgual}`;
        }

        if (filtros.dataCorteMenorIgual && filtros.dataCorteMenorIgual && filtros.dataCorteMenorIgual.length) {
            query += `&dataCorteMenorIgual=${filtros.dataCorteMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMaiorIgual && filtros.dataCadastroMaiorIgual.length) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual && filtros.dataCadastroMenorIgual && filtros.dataCadastroMenorIgual.length) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFechamento>>(`Fechamento/RecuperaFechamento?${query}`);

        return result;
    }

    async adicionaFechamento(adicionaFechamento: AdicionaFechamentoRequest): Promise<IFechamento> {
        let { data: result } = await api.post<IFechamento>(`Fechamento/AdicionaFechamento`, adicionaFechamento);

        return result;
    }

    async gerarExcelFechamento(relatorioFechamento: FiltroRelatorioFechamentoRequest): Promise<Blob> {
        let query = `fechamentoAtualId=${relatorioFechamento.fechamentoAtualId}`;

        if (relatorioFechamento.fechamentoAnteriorId && relatorioFechamento.fechamentoAnteriorId !== 0) {
            query += `&fechamentoAnteriorId=${relatorioFechamento.fechamentoAnteriorId}`;
        }

        const { data: result } = await api.get(`Fechamento/GerarRelatorioFechamento?${query}`, { responseType: "blob" });
        
        return result;
    }

    async recuperaDetalhesFechamento(fechamentoId: number): Promise<IFechamento> {
        let query = `fechamentoId=${fechamentoId}`;
        const { data: result } = await api.get(`Fechamento/RecuperaDetalhesFechamento?${query}`);
        return result;
    }

    async obterDadosFechamentoDashboard(): Promise<any> {
        const { data: result } = await api.get(`/Fechamento/RecuperaFechamentoDashboard`);
        return result;
    }
}


export default new FechamentoService();