import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import {
  faPrint,
  faHistory,
  faShareAlt,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";

import { defaultSchema } from "../../utils/SchemasUtil";

import IConsulta from "../../interfaces/IConsulta";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaConsultaRequest } from "../../interfaces/Requests/Consulta/IAdicionaConsultaRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaSeparadorTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ESeparadorIDEnum, ETelaEnum } from "../../enum";

import ConsultaService from "../../services/ConsultaService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import { OrangeContext } from "../../contexts/OrangeProvider";

import Carregando from "../../components/Carregando";
import LayoutPageButton from "../../layout/LayoutPageButton";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompartilharModal from "../../components/Comum/Share/CompartilharModal";
import AndamentoListField from "../../components/Comum/Andamento/AndamentoListField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";
import CollapseCapaDinanimos from "../../components/Comum/Modulo/CollapseCapaDinanimos";
import ComunicacaoListField from "../../components/Comum/Comunicacao/ComunicacaoListField";
import EncerramentoModal from "../../components/Comum/EncerramentoConsulta/EncerramentoModal";

const ConsultaCapaPage = () => {

  const componentRef = useRef<any>();

  const { consulta, tipoConsulta, setConsulta } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [refreshCapa, setRefreshCapa] = useState(true);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [exibirModalShare, setExibirModalShare] = useState<boolean>(false);
  const [exibirModalEncerramento, setExibirModalEncerramento] = useState<boolean>(false);

  const toggleShare = () => setExibirModalShare(!exibirModalShare);
  const toggleEncerramento = () => setExibirModalEncerramento(!exibirModalEncerramento);

  const compartilhar = () => `Número_${consulta.consultaId}_${tipoConsulta.tipoConsultaId > 0 ? "_Tipo_de_Consulta_" + tipoConsulta.nome : ""}_Status_${consulta.statusConsultaNome}`;

  const initialValues: IAdicionaConsultaRequest = {
    principalId: consulta.consultaId,
    consultaId: consulta.consultaId,

    tipoConsultaId: consulta.tipoConsultaId,
    categoriaId: consulta.tipoConsultaId,

    modulo: EModulo.Consultivo,
    tela: ETelaEnum.TelaCapaConsultivo,

    cidadeId: 0,
    departamentoId: 0,
    escritorioId: 0,
    tipodocumentoId: 0,

    restrito: false,

    codigo: "",
    observacoesconsulta: "",
    nomesolicitante: "",
    emailsolicitante: "",
    parecerjuridico: "",
    tempogasto: "",
    descricaoconsulta: "",

    datafinalizacao: null,
    prazofatal: null,

    empresasList: [],
    partesContrariasList: [],
    advogadoInternoList: [],
    centrosCusto: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (consulta.consultaId) {

      carregarConsulta();

      formik.setValues(initialValues);

    }

  }, [consulta.consultaId]);

  useEffect(() => {
    if (refreshCapa) {
      carregarSeparadoresTela();
    }
  }, [refreshCapa]);

  useEffect(() => {
    if (!consulta.consultaId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [consulta]);

  const inserirSessionStorage = () => sessionStorageService.inserir(consulta, "consulta_capa");

  const carregarSessionStorage = async () => setConsulta(JSON.parse((await sessionStorageService.obter("consulta_capa")) || null));

  const carregarConsulta = async () => {
    try {

      let resultado: IRecuperaLista<IConsulta>;

      resultado = await ConsultaService.obterConsultaPorId(consulta.consultaId);

      setConsulta(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setRefreshCapa(true);
    }
  }

  const carregarSeparadoresTela = async () => {

    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;
      resultado = await ConfiguracaoSeparadorService.obterCapa({
        modulo: EModulo.Consultivo,
        tipoConsultaId: formik.values.tipoConsultaId,
      });

      setSeparadoresTela(resultado.data);

    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (consultaId: number) => {
    try {
      let resultado = await ConsultaService.adicionaFavorido(consultaId);
      setConsulta({ ...consulta, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar consulta como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ConsultaService.removerFavorido(favoritoId);
      setConsulta({ ...consulta, favoritoId: 0 });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };


  const renderSeparadores = () => {

    const components = separadoresTela.map((separadorTela, index) => {
      switch (separadorTela.codigo) {

        case ESeparadorIDEnum.ExibirAndamento:
          return <AndamentoListField key={index.toString()} modulo={EModulo.Consultivo} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirCompromisso:
          return <CompromissoListField key={index.toString()} modulo={EModulo.Consultivo} setRefreshCapa={setRefreshCapa} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirComunicacao:
          return <ComunicacaoListField key={index.toString()} modulo={EModulo.Consultivo} configuracaoTela={separadorTela} />;

        default:
          return (
            <CollapseCapaDinanimos
              key={index.toString()}
              formik={formik}
              controller={"consulta"}
              setRefreshCapa={setRefreshCapa}
              separadorTela={separadorTela}
              setConfiguracoesTela={setConfiguracoesTela}
              configuracoesTela={configuracoesTela}
              separadorTelaLista={separadoresTela}
              setSeparadorTelaLista={setSeparadoresTela}
            />
          );
      }
    });

    return components;
  };

  return (
    <>
      <ToastContainer />

      <CompartilharModal
        texto={compartilhar()}
        exibirModal={exibirModalShare}
        toggleModal={toggleShare}
      />

      <EncerramentoModal
        exibirModal={exibirModalEncerramento}
        toggleModal={toggleEncerramento}
        setRefreshCapa={setRefreshCapa}
      />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${consulta.consultaId}  ${tipoConsulta.tipoConsultaId > 0 ? " - " + tipoConsulta.nome : ""} => ${consulta.statusConsultaNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => setRefreshCapa(!refreshCapa)}>
                {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faHistory} />}
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span className="indicator-progress" style={{ display: "block", fontSize: "30px", paddingLeft: "11px", paddingRight: "11px" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          {consulta.statusConsultaId != 12 && (
            <a
              style={{ marginLeft: "-0px", cursor: "pointer", fontSize: "30px" }}
              onClick={() => {
                setExibirModalEncerramento(!exibirModalEncerramento);
              }}
            >
              {exibirModalEncerramento && <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPowerOff} title="Encerrar o consulta" />}
              {!exibirModalEncerramento && <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPowerOff} title="Encerrar o consulta" />}
            </a>
          )}
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPrint} title="Imprimir o consulta" />
              </a>
            )}
            content={() => componentRef.current}
          />

          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            onClick={() => toggleShare()}
            title="Compartilhar"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShareAlt} />}
          </a>

          {consulta.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(consulta.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o consulta"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(consulta.consultaId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o consulta"
            ></a>
          )}

        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderSeparadores()}
            <FileListCapaField modulo={EModulo.Consultivo} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
          </>
        )}
      </div>
    </>
  );
}

export default ConsultaCapaPage;
