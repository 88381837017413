import IAlterarSenhaRequest from "../interfaces/IAlterarSenhaRequest";
import IAutenticacao from "../interfaces/IAutenticao";
import api from "./Api";

class AuthService {
    async signIn(email: string, codigo: string, ip: string): Promise<IAutenticacao> {
        let { data: result } = await api.post<IAutenticacao>('Autenticacao/TwoFactor', { email, codigo, ip });

        return result;
    }

    async trocarCliente(clienteId: number): Promise<IAutenticacao> {
        let { data: result } = await api.post<IAutenticacao>('Usuario/TrocarCliente', { clienteId });
        
        return result;
    }

    async authenticate(email: string, senha: string): Promise<IAutenticacao> {
        let { data: result } = await api.post<IAutenticacao>('Autenticacao/Authenticate', { email, senha });

        return result;
    }

    async authenticateExternal(email: string, hash: string, ): Promise<IAutenticacao> {
        let { data: result } = await api.post<IAutenticacao>('autenticacao/autenticarUsuarioExterno', { email, hash });

        return result;
    }

    async resetPassword(email: string): Promise<void> {
        await api.put<IAutenticacao>('Usuario/ResetarSenha', { email });
    }

    async resetPasswordConfirmCode(email: string, codigoResetSenha: string): Promise<void> {
        await api.put<IAutenticacao>('Usuario/ConfirmaResetSenha', { email, codigoResetSenha });
    }

    async changePassword(alterarSenhaRequest: IAlterarSenhaRequest): Promise<void> {
        await api.put<void>('Usuario/AlterarSenha', alterarSenhaRequest);
    }
}


export default new AuthService();