import { IPessoa } from "../interfaces/IPessoa";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import AdicionaPessoaRequest from "../interfaces/Requests/AdicionaPessoaRequest";
import FiltroPesquisaPessoa from "../interfaces/Requests/Pessoa/FiltroPesquisaPessoaRequest";
import api from "./Api";

class PessoaService {
    async buscarPessoa(filtros: FiltroPesquisaPessoa): Promise<IRecuperaLista<IPessoa>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.documento) query += `&numeroDocumento=${filtros.documento}`;

        if (filtros.nome) query += `&nome=${filtros.nome}`;

        let { data: result } = await api.get<IRecuperaLista<IPessoa>>(`Pessoa/RecuperaPessoa?${query}`);

        return result;
    }

    async buscarPessoaPorDocumento(numeroDocumento: string): Promise<IRecuperaLista<IPessoa>> {
        let query = ``;

        query += `&numeroDocumento=${numeroDocumento}`;

        let { data: result } = await api.get<IRecuperaLista<IPessoa>>(`Pessoa/RecuperaPessoa?${query}`);

        return result;
    }

    async buscarPessoaPorNome(nome: string): Promise<IRecuperaLista<IPessoa>> {
        let query = ``;

        query += `&nome=${nome}`;

        let { data: result } = await api.get<IRecuperaLista<IPessoa>>(`Pessoa/RecuperaPessoa?${query}`);

        return result;
    }

    async adicionaPessoa(adicionaPessoa: AdicionaPessoaRequest): Promise<IPessoa> {
        let { data: result } = await api.post<IPessoa>(`Pessoa/AdicionaPessoa`, adicionaPessoa);
        return result;
    }
}


export default new PessoaService();