import { Button } from "react-bootstrap";
import { Container, Avatar } from "./styles";
import { MdDelete, MdEdit, MdReply } from "react-icons/md";
import { Comment, DeleteComment, EditComment } from "../../../types";
import { useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

type CommentCardProps = {
  isAwnser?: boolean;
  data: Comment;
  reply?: (comment: string) => Promise<void>;
  edit: (data: EditComment) => Promise<void>;
  deleteComment: (data: DeleteComment) => Promise<void>;
};

export const CommentCard = ({
  isAwnser,
  data,
  reply,
  edit,
  deleteComment,
}: CommentCardProps) => {
  const [isActive, setIsActive] = useState(false);
  const [comment, setComment] = useState("");
  const [isEdition, setIsEdition] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const avatarLabel = useMemo(() => {
    const [name, surname] = data.autorNome.split(" ");
    const [n, s] = [name?.charAt(0) ?? "", surname?.charAt(0) ?? ""];
    return `${n}${s}`;
  }, [data.autorNome]);

  const selectComment = () => {
    if (isAwnser) {
      return;
    }
    setComment("");
    setIsActive(true);
    focus();
  };

  const handleReply = async (event: React.FormEvent) => { 
    event.preventDefault()

    if (!comment) {
      return;
    }

    if (isEdition) {
      await edit({
        comment, 
        id: data.editorDocumentoComentariosId, 
        isAwnser,
        threadId: isAwnser ? data.comentarioPaiId : data.editorDocumentoComentariosId
      });
      closeForm();
      return;
    }

    if (reply) {
      await reply(comment);
      closeForm();
    }
  };

  const handleDelete = async () => {
    await deleteComment({
      id: data.editorDocumentoComentariosId, 
      isAwnser,
      threadId: isAwnser ? data.comentarioPaiId : data.editorDocumentoComentariosId
    });
    closeForm();
  };

  const openEdition = () => {
    focus();

    const comment = data.comentario;
    setComment(comment);
    setIsEdition(true);
  };

  const showForm = useMemo(() => {
    if (isEdition) {
      return true;
    }
    return isActive && !isAwnser;
  }, [isEdition, isActive, isAwnser]);

  const closeForm = () => {
    setIsActive(false);
    setIsEdition(false);
  };

  const focus = () => {
    inputRef.current?.focus();
  };

  const formattedCreationDate = useMemo(() => {
    return format(new Date(data.dataCadastro), "HH:mm cccc", { locale: ptBR });
  }, [data.dataCadastro]);

  return (
    <Container isActive={isActive} isAwnser={isAwnser || false}>
      <div className="actions">
        {!isAwnser && (
          <button onClick={selectComment} className="reply" type="button">
            <MdReply />
          </button>
        )}

        <button onClick={openEdition} className="edit" type="button">
          <MdEdit />
        </button>

        <button onClick={handleDelete} className="delete" type="button">
          <MdDelete />
        </button>
      </div>

      <div onClick={closeForm} className="wrapper">
        <div className="header">
          {data.fotoLink ? (
            <img src={data.fotoLink} alt={data.autorNome} />
          ) : (
            <Avatar>
              <strong>{avatarLabel}</strong>
            </Avatar>
          )}
          <div className="info">
            <strong>{data.autorNome}</strong>
            <small>{formattedCreationDate}</small>
          </div>
        </div>
        <div className="content">
          <span>{data.comentario}</span>
        </div>
      </div>

      {showForm && (
        <form onSubmit={handleReply} className="footer">
          <input
            ref={inputRef}
            className="form-control"
            value={comment}
            type="text"
            placeholder="Escreva uma resposta..."
            onChange={(event) => setComment(event.target.value)}
          />
          <div className="actions">
            <Button onClick={closeForm} variant="text" type="button">
              Cancelar
            </Button>
            <Button variant="var(--primary-base2)" type="submit">
              Responder
            </Button>
          </div>
        </form>
      )}
    </Container>
  );
};
