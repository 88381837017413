import { useContext, useEffect, useRef, useState } from "react";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";

import { MultiSelect } from "react-multi-select-component";
import IDecisao from "../../interfaces/IDecisao";
import IProcesso from "../../interfaces/IProcesso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaDecisaoRequest from "../../interfaces/Requests/Decisao/FiltroPesquisaDecisaoRequest";

import DecisaoService from "../../services/DecisaoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const DecisaoPage = () => {

  const [StatusOptions, setStatusOptions] = useState<any>([{ label: "Encerrada", value: 1 }, { label: "Ativa", value: 2 }]);
  const [StatusSelected, setStatusSelected] = useState([]);
  const [carregandoDecisoes, setCarregandoDecisoes] = useState(false);
  const [decisoes, setDecisoes] = useState<IDecisao[]>([]);

  const { processo, setProcesso, setAreaDireito, decisao, setDecisao } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaDecisaoRequest>({
    modulo: 0,
    processoId: processo.processoId,
    teorDecisao: "",
    dataDecisaoMaiorIgual: "",
    dataDecisaoMenorIgual: "",
    prazoFatalMaiorIgual: "",
    prazoFatalMenorIgual: "",
    dataInicioVigenciaMaiorIgual: "",
    dataInicioVigenciaMenorIgual: "",
    dataVencimentoMaiorIgual: "",
    dataVencimentoMenorIgual: "",
    possuiRecurso: false,
    detalhesRecurso: "",
    possuiDecisao: false,
    detalhesDecisao: "",
    codigo: "",
    decisaoId: 0,
    clienteId: 0,
    usuarioIdCadastro: 0,
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    status: [],
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "decisaoId",
  });

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, status: IdsSelected };
    });
  }, [StatusSelected]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);

  useEffect(() => {
    if (atualizarTabela) {
      setTimeout(() => {
        carregarDecisoes(filtrosPesquisa);
      }, 2000);
    }
  }, [atualizarTabela]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_decisao");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_decisao");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_decisao")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaDecisaoRequest = JSON.parse((await sessionStorageService.obter("filtro_decisao")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, processoId: filtro.processoId });
  };

  async function carregarDecisoes(filtro: FiltroPesquisaDecisaoRequest): Promise<void> {
    try {
      setCarregandoDecisoes(true);
      let resultado: IRecuperaLista<IDecisao>;

      resultado = await DecisaoService.obterDecisoes(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setDecisoes(resultado.data);
      inserirSessionStorage();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setDecisoes([]);
    } finally {
      setCarregandoDecisoes(false);
      setAtualizaTabela(false);
    }
  }

  const colunas: TableColumn<IDecisao>[] = [
    {
      name: "Id",
      sortField: "DecisaoId",
      selector: (row: IDecisao) => row.decisaoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IDecisao) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Prazo",
      sortField: "prazoFatal",
      selector: (row: IDecisao) => {
        if (row.prazoFatal === null) return "-";
        const data = row.prazoFatal.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Data da Decisão",
      sortField: "dataDecisao",
      selector: (row: IDecisao) => {
        if (row.dataDecisao === null) return "-";
        const data = row.dataDecisao.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Teor",
      sortField: "teorDecisao",
      selector: (row: IDecisao) => row.teorDecisao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Possui Recurso",
      sortField: "possuiRecurso",
      selector: (row: IDecisao) => (row.possuiRecurso ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Possui Obrigação",
      sortField: "possuiObrigacao",
      selector: (row: IDecisao) => (row.possuiObrigacao ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IDecisao) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Editar"
              onClick={() => abrirCapa(row)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IDecisao>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        teorDecisao: "",
        dataDecisaoMaiorIgual: "",
        dataDecisaoMenorIgual: "",
        prazoFatalMaiorIgual: "",
        prazoFatalMenorIgual: "",
        dataInicioVigenciaMaiorIgual: "",
        dataInicioVigenciaMenorIgual: "",
        possuiRecurso: false,
        detalhesRecurso: "",
        possuiDecisao: false,
        detalhesDecisao: "",
        codigo: "",
        decisaoId: 0,
        clienteId: 0,
        usuarioIdCadastro: 0,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        status: [],
      };
    });
  }

  const abrirCapa = (row: IDecisao) => {
    setDecisao({
      ...row,
      decisaoId: row.decisaoId,
      tipoDecisaoId: row.tipoDecisaoId,
      categoriaId: row.tipoDecisaoId,

      modulo: decisao.modulo,

      processoId: decisao?.processoId,
      areaDireitoId: decisao.areaDireitoId,

      tipoImovelId: decisao.tipoImovelId,
      imovelId: decisao?.imovelId,
      status: row.status
    });
    navigate("/Decisao/Capa");
  };

  return (
    <>
      <LayoutPageForButton title="Decisão">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setDecisao({ ...decisao, decisaoId: 0 });
            navigate("/Decisao/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <div className="col-md-12">

          <CollapseFiltro titulo="Filtros" content={
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarDecisoes(filtrosPesquisa);
              }}
              className="row g-3 mb-3 form-buscar-decisao"
            >
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                  Data do Cadastro:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={
                    filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                      ? [new Date(filtrosPesquisa.dataCadastroMaiorIgual), new Date(filtrosPesquisa.dataCadastroMenorIgual)]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas || datas.length === 0) {
                      setFiltrosPesquisa((oldValue) => ({
                        ...oldValue,
                        dataCadastroMaiorIgual: "",
                        dataCadastroMenorIgual: "",
                      }));
                      return;
                    }

                    const [data_inicial, data_final] = datas;

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    }));
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-dataDecisao" className="form-label fw-bolder text-orange">
                  Data da Decisão:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={
                    filtrosPesquisa.dataDecisaoMaiorIgual && filtrosPesquisa.dataDecisaoMenorIgual
                      ? [new Date(filtrosPesquisa.dataDecisaoMaiorIgual), new Date(filtrosPesquisa.dataDecisaoMenorIgual)]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas || datas.length === 0) {
                      setFiltrosPesquisa((oldValue) => ({
                        ...oldValue,
                        dataDecisaoMaiorIgual: "",
                        dataDecisaoMenorIgual: "",
                      }));
                      return;
                    }

                    const [data_inicial, data_final] = datas;

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataDecisaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataDecisaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    }));
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-dataInicioVigencia" className="form-label fw-bolder text-orange">
                  Data de Início da Vigência:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={
                    filtrosPesquisa.dataInicioVigenciaMaiorIgual && filtrosPesquisa.dataInicioVigenciaMenorIgual
                      ? [new Date(filtrosPesquisa.dataInicioVigenciaMaiorIgual), new Date(filtrosPesquisa.dataInicioVigenciaMenorIgual)]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas || datas.length === 0) {
                      setFiltrosPesquisa((oldValue) => ({
                        ...oldValue,
                        dataInicioVigenciaMaiorIgual: "",
                        dataInicioVigenciaMenorIgual: "",
                      }));
                      return;
                    }

                    const [data_inicial, data_final] = datas;

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataInicioVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataInicioVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    }));
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-dataVencimento" className="form-label fw-bolder text-orange">
                  Data de Vencimento:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={
                    filtrosPesquisa.dataVencimentoMaiorIgual && filtrosPesquisa.dataVencimentoMenorIgual
                      ? [new Date(filtrosPesquisa.dataVencimentoMaiorIgual), new Date(filtrosPesquisa.dataVencimentoMenorIgual)]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas || datas.length === 0) {
                      setFiltrosPesquisa((oldValue) => ({
                        ...oldValue,
                        dataVencimentoMaiorIgual: "",
                        dataVencimentoMenorIgual: "",
                      }));
                      return;
                    }

                    const [data_inicial, data_final] = datas;

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataVencimentoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataVencimentoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    }));
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-dataFatal" className="form-label fw-bolder text-orange">
                  Prazo Fatal:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={
                    filtrosPesquisa.prazoFatalMaiorIgual && filtrosPesquisa.prazoFatalMenorIgual
                      ? [new Date(filtrosPesquisa.prazoFatalMaiorIgual), new Date(filtrosPesquisa.prazoFatalMenorIgual)]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas || datas.length === 0) {
                      setFiltrosPesquisa((oldValue) => ({
                        ...oldValue,
                        prazoFatalMaiorIgual: "",
                        prazoFatalMenorIgual: "",
                      }));
                      return;
                    }

                    const [data_inicial, data_final] = datas;

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      prazoFatalMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      prazoFatalMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    }));
                  }}
                />
              </div>


              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={false}
                  disabled={false}
                  options={StatusOptions}
                  value={StatusSelected}
                  onChange={(event: any) => setStatusSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
              </div>
              <div className="col-12 " style={{ textAlign: "right" }}>
                <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                  <FontAwesomeIcon color="white" className="" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-orange search-buttom-margin ms-2"
                  title="Limpar Filtro"
                >
                  <FontAwesomeIcon color="white" className="" icon={faEraser} />
                </button>
              </div>
            </form>} />
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoDecisoes}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Decisoes"
            itens={decisoes}
          />
        </div>
      </div>
    </>
  );
};

export default DecisaoPage;
