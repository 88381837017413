import { useEffect, useRef, useState } from 'react';
import Dashboard from '../../../components/dashboard';
import { EstatisticasContextoProvider } from '../../../components/dashboard/statistics/EstatisticasContexto';
import LayoutPageTitle from '../../../layout/LayoutPageTitle';
import * as constants from './FechamentoDashboardConstants';
import FechamentoDashboardEstatisticas from '../../../components/dashboard/statistics/FechamentoDashboardEstatisticas';
import { IWidgetsTamanhos } from '../../../components/dashboard/widgets/interfaces/IWidget';
import FechamentoService from '../../../services/FechamentoService';

const FechamentoDashboardPage = () => {
  const mountedRef = useRef(true);
  const [carregandoDados, setCarregandoDados] = useState(true);
  const [estatisticas] = useState(new FechamentoDashboardEstatisticas());

  const [widgetOrdem] = useState<string[]>([
    'Indicadores Gerais',
    'Qtd. Ativo x Passivo',
    'Qtd. Judicial x Administrativo',
    'Variação Provisão',
    'Provisão Por Área',
    'Variação Provisão Por Áreas',
    'Provisão Por Divisão',
    'Variação Provisão Por Divisão',
    'Valor Total Por Área',
    'Valor Total x Divisão',  
  ]);
  
  const [widgetTamanhos] = useState<IWidgetsTamanhos[]>([
    {
      xs: '12',
      sm: '12',
      md: '12',
      lg: '12',
      xl: '12'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    }, 
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    }, 
    {
      xs: '12',
      sm: '12',
      md: '12',
      lg: '12',
      xl: '12'
    }, 
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    }, 
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
    {
      xs: '12',
      sm: '12',
      md: '6',
      lg: '6',
      xl: '6'
    },
  ])

  const carregarDados = async (): Promise<void> => {
    await estatisticas.atualiza(async () => {
        try {
                setCarregandoDados(true);
                
                const resultado = await FechamentoService.obterDadosFechamentoDashboard();
                // const resultado = { ...dadosMock}
                
                let resultadosFiltrados = resultado.data;            

                return [resultadosFiltrados]
               
        } catch (error) {
            console.log(error)
            setCarregandoDados(false);
            return [];
        }
        finally {
          setCarregandoDados(false);
            
        } 
    });
  }

  useEffect(() => {
    mountedRef.current ? carregarDados() : ''
       return void (mountedRef.current = false);
   }, [])  


    return (<>
        <LayoutPageTitle title="Fechamento Dashboard" />
        <div className="row mt-2">
            <div className="col-md-12">
            <div className="col-md-12 mb-10">
                    {
                    estatisticas ? 
                        <EstatisticasContextoProvider
                            estatisticas={estatisticas}
                        >
                            <Dashboard
                                    estatico={true}
                                    widgets={constants.WIDGETS}
                                    widgetOrdens={widgetOrdem}
                                    widgetTamanhos={widgetTamanhos}
                                    carregando={carregandoDados}
                                    dashboadID={1} 
                                    onDashboardChanged={function () {
                                        throw new Error('Function not implemented.');
                                    } } 
                                    salvar={function () {
                                        throw new Error('Function not implemented.');
                                    } }                                
                            >
                            </Dashboard>
                            
                        </EstatisticasContextoProvider>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    </>)
}

export default FechamentoDashboardPage;
