import { useEffect, useState } from "react";
import Select from "react-select";

import ICidade from "../../../interfaces/ICidade";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";

import CidadeService from "../../../services/CidadeService";
import { styleSelect } from "../../../utils/Utils";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

interface IFieldProps {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
  capa?: boolean
}

const CidadeFied = ({ formik, configuracaoTela, capa }: IFieldProps) => {
  const [carregandoCidades, setCarregandoCidades] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (formik.values.estadoId > 0) {
      carregaCidade();
    }
  }, [formik.values.estadoId]);

  const carregaCidade = async () => {
    try {

      setOptions([]);

      setCarregandoCidades(true);

      let resultado: IRecuperaLista<ICidade>;

      let cidades: any[] = [{ label: 'Selecione', value: 0 }];

      resultado = await CidadeService.obterCidadePorFiltros({
        estadoId: formik.values.estadoId,
        IBGE: "",
        nome: "",
        status: 1,
        limit: 900,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      resultado.data.map((item) => cidades.push({ label: item.nome, value: item.cidadeId }));

      setOptions(cidades);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoCidades(false);
    }
  };

  return (
    carregandoCidades ? (
      <span className="indicator-progress" style={{ display: "block" }}>
        <span className="spinner-border text-orange spinner-border-sm align-middle ms-2"></span>
      </span>
    ) : (
      <>
        {!capa &&
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
            <a style={{ fontSize: "12px" }}>
              {configuracaoTela.obrigatorio ? "*:" : ":"}
            </a>
          </label>}

        <Select
          value={
            {
              label: options.find((e) => e.value === formik.values.cidadeId)?.label,
              value: formik.values.cidadeId,
            }
          }
          styles={styleSelect(formik, configuracaoTela)}
          options={options}
          onChange={(option: any) => {
            configuracaoTela.valor = option.label;
            configuracaoTela.valorId = option.value;
            formik.setFieldTouched("cidadeId", true);
            formik.setFieldValue("cidadeId", parseInt(option.value));
          }}
        />
        {formik.touched.cidadeId && formik.errors.cidadeId && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.cidadeId}</span>
            </div>
          </div>)}
      </>
    )
  );
};

export default CidadeFied;
