import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import LayoutPageForButton from "../../layout/LayoutPageButton";
import { tipoPorModulo } from "../../utils/Utils";
import { EModulo, ETelaEnum } from "../../enum";

import ITipoReembolso from "../../interfaces/ITipoReembolso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaTipoReembolsoRequest from "../../interfaces/Requests/TipoReembolso/FiltroPesquisaTipoReembolsoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ReembolsoService from "../../services/ReembolsoService";
import TipoReembolsoService from "../../services/TipoReembolsoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { OrangeContext } from "../../contexts/OrangeProvider";
import CamposCadastroDinamicos from "../../components/Comum/Solucao/CamposCadastroSolucoes";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import { defaultSchema, setValuesXCamposSolucoes } from "../../utils/SchemasUtil";
import { tipoReembolsoInicialValues } from "../../contexts/InitialValuesContext";
import IOption from "../../interfaces/IOption";


const ReembolsoCadastroPage = () => {

  const navigate = useNavigate();

  const { reembolso, setReembolso, tipoReembolso, setTipoReembolso, processo } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [adicionarReembolsoLoading, setAdicionarReembolsoLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [carregandoTipoReembolso, setCarregandoTipoReembolso] = useState(false);
  const [filtrosPesquisa] = useState<FiltroPesquisaTipoReembolsoRequest>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: "tipoReembolsoId",
  });


  console.log(reembolso)

  const initialValues = {
    tipoReembolsoId: 0,
    principalId: 0,

    modulo: EModulo.XpayReembolso,
    categoriaId: reembolso.categoriaId,

    tela: ETelaEnum.TelaCadastroReembolso,

    processoId: processo.processoId,
    areaDireitoId: processo.areaDireitoId,
    escritorioId: processo.escritorioId,

    contratoId: reembolso.contratoId,
    tipoContratoId: reembolso.tipoContratoId,

    imovelId: reembolso.imovelId,
    tipoImovelId: reembolso.tipoImovelId,

    consultaId: reembolso.consultaId,
    tipoConsultaId: reembolso.tipoConsultaId

  };


  useEffect(() => {

    setTipoReembolso(tipoReembolsoInicialValues);

    carregarTipoReembolso(filtrosPesquisa);

    setConfiguracoesTela([]);

    formik.setValues(initialValues);

  }, []);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Reembolso",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          Swal.showLoading();
          setAdicionarReembolsoLoading(true);
          let resultado = await ReembolsoService.adicionaReembolso({
            ...values,
            xCampoValorList: setValuesXCamposSolucoes(configuracoesTela, formik)
          });

          Swal.hideLoading();

          setReembolso(resultado);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarReembolsoLoading(false);
      } catch (error: any) {

        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel cadastrar o processo.`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });

      } finally {
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarReembolsoLoading(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.tipoReembolsoId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      formik.setValues(initialValues);
    }
  }, [formik.values.tipoReembolsoId]);


  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(reembolso.modulo)}Id=${reembolso.categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        Modulo: reembolso.modulo,
        Tela: ETelaEnum.TelaCadastroReembolso,
        TipoReembolsoId: tipoReembolso.tipoReembolsoId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const carregarTipoReembolso = async (filtro: FiltroPesquisaTipoReembolsoRequest) => {
    try {

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      setCarregandoTipoReembolso(true);

      if (reembolso.modulo === EModulo.AreaDireito)
        filtro.queryCustom = `&modulo=1&areaDireitoId=${reembolso.categoriaId}`;

      if (reembolso.modulo === EModulo.Contrato)
        filtro.queryCustom = `&modulo=7&contratoId=${reembolso.categoriaId}`;

      if (reembolso.modulo === EModulo.Consultivo)
        filtro.queryCustom = `&modulo=9&consultaId=${reembolso.categoriaId}`;

      let resultado: IRecuperaLista<ITipoReembolso>;

      resultado = await TipoReembolsoService.obterTipoReembolsoLimitada(filtro);

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId || 0 }));

      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoTipoReembolso(false);
    }
  };

  const onChangeTipoReembolso = (value: any, texto: string) => {

    setConfiguracoesTela([]);

    formik.setFieldValue("tipoReembolsoId", parseInt(value));

    formik.setFieldValue("categoriaId", value);

    setTipoReembolso({
      tipoReembolsoId: parseInt(value),
      nome: texto,
      checked: false,
      codigo: "",
      status: "",
      categoriaId: 0,
      categoriaNome: "",
      modulo: 0,
      moduloNome: "",
      opcaoNome: "",
      opcaoId: 0
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos key={configuracaoTela.campo} configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Cadastro de Reembolso`}>
        {reembolso.reembolsoId > 0 ? (
          <>
            <button onClick={() => navigate("/XpayReembolso/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{reembolso.reembolsoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !tipoReembolso.tipoReembolsoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarReembolsoLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarReembolsoLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarReembolsoLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>


      <div className="row mt-2">
        {carregandoTipoReembolso ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipos de reembolsos...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Reembolso:
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoReembolsoId)?.label,
                value: formik.values.tipoReembolsoId,
              }}
              onChange={(option: any) => onChangeTipoReembolso(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>
      <hr></hr>
      <>
        {renderCampos()}
      </>
    </>
  );
};

export default ReembolsoCadastroPage;
