import { useContext, useEffect, useRef, useState } from "react";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { MultiSelect } from "react-multi-select-component";
import IReembolso from "../../interfaces/IReembolso";
import IProcesso from "../../interfaces/IProcesso"
import ITipoReembolso from "../../interfaces/ITipoReembolso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaReembolsoRequest from "../../interfaces/Requests/Reembolso/FiltroPesquisaReembolsoRequest";

import { EModulo } from "../../enum";

import ReembolsoService from "../../services/ReembolsoService";
import TipoReembolsoService from "../../services/TipoReembolsoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageTitle from "../../layout/LayoutPageTitle";

import { reembolsoInicialValues } from "../../contexts/InitialValuesContext";
import { OverlayTrigger } from "react-bootstrap";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const ReembolsoPage = () => {
  const [StatusOptions, setStatusOptions] = useState<any>
    ([{ label: "Pendente Aprovação", value: 1 }, { label: "Aguardando Fechamento", value: 2 }, { label: "Cancelado", value: 3 }, { label: "Em Aprovação da Fatura", value: 4 }, { label: "Pago", value: 5 }]);
  const [StatusSelected, setStatusSelected] = useState([]);
  const [carregandoReembolsos, setCarregandoReembolsos] = useState(false);
  const [reembolsos, setReembolsos] = useState<IReembolso[]>([]);
  const { processo, imovel, contrato, setProcesso, reembolso, setReembolso, setAreaDireito } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);
  const [tipoReembolsos, setTipoReembolsos] = useState<ITipoReembolso[]>([]);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaReembolsoRequest>({
    reembolsoId: 0,
    tipoReembolsoId: 0,
    tipoReembolsoNome: "",
    processoId: processo.processoId,
    descricao: "",
    CompetenciaMaiorIgual: "",
    CompetenciaMenorIgual: "",
    ValorTotalMaiorIgual: 0,
    ValorTotalMenorIgual: 0,
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    statusId: [],
    statusNome: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "reembolsoId",
  });

  const navigate = useNavigate();
  const componentRef = useRef<any>();

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusId: IdsSelected };
    });
  }, [StatusSelected]);

  useEffect(() => {
    carregarReembolsos(filtrosPesquisa);
    carregarTipoReembolso();
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    if (atualizarTabela) setTimeout(() => carregarReembolsos(filtrosPesquisa), 1000);
  }, [atualizarTabela]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_reembolso");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_reembolso");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_reembolso")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaReembolsoRequest = JSON.parse((await sessionStorageService.obter("filtro_reembolso")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, processoId: filtro.processoId });
  };

  const carregarReembolsos = async (filtro: FiltroPesquisaReembolsoRequest): Promise<void> => {
    try {
      setCarregandoReembolsos(true);
      let resultado: any;
      resultado = await ReembolsoService.obterReembolsos(filtro);
      setReembolsos(resultado.data);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      inserirSessionStorage();
      setAtualizaTabela(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setReembolsos([]);
    } finally {
      setCarregandoReembolsos(false);
    }
  }

  const carregarTipoReembolso = async () => {
    try {

      let resultado: IRecuperaLista<ITipoReembolso>;

      resultado = await TipoReembolsoService.obterTipoReembolsos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoReembolsoId",
      });

      setFiltrosPesquisa({ ...filtrosPesquisa, totalItems: resultado.totalRegistros });

      setTipoReembolsos(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoReembolsos([]);
    }
  }

  const alterarEdicao = (row: IReembolso) => {
    setReembolso({
      modulo: reembolso.modulo,
      reembolsoId: row.reembolsoId,
      tipoReembolsoId: row.tipoReembolsoId,
      categoriaId: row.tipoReembolsoId,

      processoId: processo.processoId,
      areaDireitoId: processo.areaDireitoId,
      categoriaNome: processo.areaDireitoNome,

      contratoId: contrato.contratoId,
      tipoContratoId: contrato.tipoContratoId,

      imovelId: imovel.imovelId,
      tipoImovelId: imovel.tipoImovelId,


      escritorioId: processo.escritorioId || 0,

      descricao: row.descricao,
      competencia: new Date(row.competencia?.toString()),
      moedaId: row.moedaId,
      valorTotal: row.valorTotal * 100,
      codigo: row.codigo,
      tipoReembolsoNome: "",
      clienteId: row.clienteId,
      clienteNome: "",
      dataCadastro: row.dataCadastro,
      moedaNome: "",
      objetoTipo: "",
      statusId: row.statusId,
      statusNome: "",
      xCampoValorList: []
    });

    navigate("/XpayReembolso/Capa");
  };

  const colunas: TableColumn<IReembolso>[] = [
    {
      name: "Id",
      sortField: "ReembolsoId",
      selector: (row: IReembolso) => row.reembolsoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: IReembolso) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Competência",
      sortField: "competencia",
      selector: (row: IReembolso) => {
        if (row.competencia === null) return "-";
        const data = row.competencia.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IReembolso) => row.statusNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Tipo de Reembolso",
      sortField: "tipoReembolsoNome",
      selector: (row: IReembolso) => row.tipoReembolsoNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Valor",
      sortField: "valor",
      selector: (row: IReembolso) => {
        if (row.valorTotal === null) return "R$ 0,00";

        const valor = row.valorTotal || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },

    {
      name: "",
      cell: (reembolso: IReembolso) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Detalhes da Reembolso"
              onClick={() => alterarEdicao(reembolso)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IReembolso>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        reembolsoId: 0,
        tipoReembolsoId: 0,
        tipoReembolsoNome: "",
        descricao: "",
        CompetenciaMaiorIgual: "",
        CompetenciaMenorIgual: "",
        ValorTotalMaiorIgual: 0,
        ValorTotalMenorIgual: 0,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        statusId: [],
        statusNome: "",
      };
    });
  };

  return (
    <>
      <LayoutPageForButton title="Reembolso">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>
      
      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setReembolso({
              ...reembolsoInicialValues,
              processoId: processo.processoId,
              categoriaId: processo.areaDireitoId,
              modulo: EModulo.AreaDireito
            });
            navigate("/XpayReembolso/Novo");
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <div className="col-md-12">

          <CollapseFiltro titulo="Filtros" content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarReembolsos(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-reembolso"
          >
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data de Cadastro:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: "",
                        dataCadastroMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-competencia" className="form-label fw-bolder text-orange">
                Competência:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.CompetenciaMaiorIgual && filtrosPesquisa.CompetenciaMenorIgual
                    ? [filtrosPesquisa.CompetenciaMaiorIgual, filtrosPesquisa.CompetenciaMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        CompetenciaMaiorIgual: "",
                        CompetenciaMenorIgual: "",
                      };
                    });
                    return;
                  }
                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }

                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      CompetenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      CompetenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                  isLoading={false}
                  disabled={false}
                  options={StatusOptions}
                  value={StatusSelected}
                  onChange={(event: any) => setStatusSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-tipo-reembolso" className="form-label fw-bolder text-orange">
                Tipo de Reembolso:
              </label>
              <select
                value={filtrosPesquisa.tipoReembolsoId}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, tipoReembolsoId: parseInt(e.target.value) };
                  });
                }}
                placeholder="Tipo de Reembolso"
                className={"form-select"}
                id="form-tipo-reembolso"
              >
                <option value="0"> Todos </option>
                {tipoReembolsos.map((map) => {
                  return (
                    <option
                      key={map.tipoReembolsoId}
                      value={map.tipoReembolsoId}
                    >
                      {" "}
                      {map.nome}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
            </div>
          </form>}/>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoReembolsos}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Reembolsos Processo"
            itens={reembolsos}
          />
        </div>
      </div>
    </>
  );
};

export default ReembolsoPage;
