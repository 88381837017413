import { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";
import { Modal, ModalProps, Spinner } from "react-bootstrap";
import { formatDate } from "../../../../../../utils/Utils";
import { MdCheckBox, MdOutlineSquare } from "react-icons/md";
import { useState } from "react";
import { useChangesComparator } from "./useChangesComparator";
import { ICompareChangesResponse } from "../../../../../../interfaces/IEditorVersionResponse";
import { useMode } from "../../hooks/useMode";
import { IComparedVersion } from "../../interfaces/versionType";
import { useVersionList } from "../../hooks/useVersionList";
import { EditorPagination } from "../Pagination";

interface ChangesComparatorModalProps extends ModalProps {
  containerComponent: DocumentEditorContainerComponent;
}

export function ChangesComparatorModal({
  containerComponent,
  ...props
}: ChangesComparatorModalProps) {
  const { toggleComparatorMode } = useMode();
  const { compareChanges, isLoading } = useChangesComparator({
    onSuccessCompare,
  });
  const [selectedVersions, setSelectedVersions] = useState<IComparedVersion[]>(
    []
  );

  const [paginationParams, setPaginationParams] = useState({
    limit: 10,
    offset: 0,
  });

  const { versions, isLoading: versionsLoading } =
    useVersionList(paginationParams);

  const isLastVersion = (versionNumber: number) => {
    return versionNumber === versions?.totalRegistros
  };

  function toggleSelectVersion(documentId: number) {
    const selectedVersion = selectedVersions.find(
      (version) => version.versionId === documentId
    );

    if (selectedVersion) {
      setSelectedVersions((state) => {
        let updatedState = state.filter(
          (version) => version.versionId !== documentId
        );
        if (updatedState.length) {
          updatedState = updatedState.map((state) => ({
            ...state,
            versionType: "original",
          }));
        }
        return updatedState;
      });
      return;
    }

    if (selectedVersions.length < 2) {
      const newVersion: IComparedVersion = {
        versionId: documentId,
        versionType: selectedVersions.length >= 1 ? "revisioned" : "original",
      };
      setSelectedVersions((state) => [...state, newVersion]);
    }
  }

  function onSuccessCompare(data: ICompareChangesResponse) {
    toggleComparatorMode({
      data: JSON.stringify(data),
      editor: containerComponent.documentEditor,
    });

    props.onHide?.();
  }

  async function compare() {
    await compareChanges(selectedVersions);
  }

  const isVersionSelected = (versionId: number) => {
    return selectedVersions.find((version) => version.versionId === versionId);
  };

  const versionBadge = (versionId: number) => {
    const findVersion = selectedVersions.find(
      (version) => version.versionId === versionId
    );
    if (findVersion) {
      return {
        className:
          findVersion.versionType === "original"
            ? "badge badge-warning"
            : "badge badge-primary",
        content:
          findVersion.versionType === "original" ? "Original" : "Revisada",
      };
    }
    return null;
  };

  const currentPage =
    paginationParams.offset === 0
      ? 1
      : paginationParams.offset / paginationParams.limit;

  return (
    <Modal {...props} centered>
      <div className="d-flex items-center justify-content-between p-4 pb-0">
        <div>
          <h6 className="m-0">Comparar versões</h6>
          <p className="text-muted">Escolha as versões que deseja comparar.</p>
        </div>

        <button onClick={props.onHide} className="btn btn-close btn-sm" />
      </div>

      <Modal.Body className="px-4">
        <div style={{ maxHeight: "450px", overflowY: "auto", padding: 10 }}>
          <div className="d-flex flex-column gap-2">
            {versionsLoading && (
              <div className="d-flex items-center justify-content-center mt-2">
                <Spinner size="sm" className="text-orange" />
              </div>
            )}

            {!versions?.data.length && !versionsLoading && (
              <div className="d-flex items-center justify-content-center mt-2">
                <span>Nenhum resultado encontrado</span>
              </div>
            )}

            {versions?.data &&
              versions.data.map((version) => {
                const badge = versionBadge(version.editorDocumentoId);

                return (
                  <div
                    key={version.editorDocumentoId}
                    className="d-flex items-center justify-content-between border-gray border-bottom pb-2"
                  >
                    <div className="d-flex flex-column">
                      <h6 className="text-gray font-weight-medium m-0">
                        {version.titulo ?? "Sem título"}
                        {"\n"}
                        {isLastVersion(version.versao) && (
                          <span className="badge badge-success">Principal</span>
                        )}
                        {"\n"}
                        {badge && (
                          <span className={badge.className}>
                            {badge.content}
                          </span>
                        )}
                      </h6>

                      <small className="text-muted">
                        Por {version.nomeUsuario} em{" "}
                        {formatDate(
                          version.dataCadastro,
                          "dd/MM/yyyy 'às' HH:mm"
                        )}
                      </small>
                    </div>

                    <div>
                      <button
                        onClick={() =>
                          toggleSelectVersion(version.editorDocumentoId)
                        }
                        className="btn btn-sm"
                      >
                        {isVersionSelected(version.editorDocumentoId) ? (
                          <MdCheckBox size={24} color="var(--bs-success)" />
                        ) : (
                          <MdOutlineSquare size={24} color="var(--text-gray)" />
                        )}
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {!versionsLoading && (
          <div className="m-2 mt-4">
            <EditorPagination
              currentPage={currentPage}
              onPageChange={(page) =>
                setPaginationParams((state) => ({
                  ...state,
                  offset: page * paginationParams.limit,
                }))
              }
              totalPages={versions?.totalPaginas ?? 0}
            />
          </div>
        )}

        <div className="d-flex items-center gap-2 justify-content-end mt-4">
          <button onClick={props.onHide} className="btn btn-light btn-sm">
            Cancelar
          </button>
          <button
            onClick={compare}
            disabled={selectedVersions.length !== 2 || isLoading}
            className="btn btn-primary btn-sm"
          >
            {isLoading ? <Spinner size="sm" /> : "Comparar"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
