import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import {
  faCheckCircle,
  faEraser,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import IFornecedores from "../../interfaces/IFornecedores";
import { useCallback, useEffect, useState } from "react";
import FornecedoresService from "../../services/FornecedoresService";
import Swal from "sweetalert2";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import FiltroPesquisaFornecedorRequest from "../../interfaces/Requests/Fornecedores/FiltroPesquisaFornecedores";
import GridUsuarios from "../Admininstracao/Seguranca/GrupoUsuarios/components/GridUsuarios";
import FornecedorEditModal from "./modals/FornecedorEditModal";

export default function FornecedoresPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [fornecedores, setFornecedores] = useState<IFornecedores[]>([]);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [fornecedor, setFornecedor] = useState<IFornecedores>({
    nome: "",
    status: "",
    codigo: "",
    fornecedorId: 0,
    numeroDocumento: "",
    clienteId: 0,
    pessoaId: 0,
    codigoIntegracao: "",
  });
  const [filtros, setFiltros] = useState<FiltroPesquisaFornecedorRequest>({
    nome: "",
    status: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "fornecedorId",
    codigo: "",
  });

  const navigate = useNavigate();

  function renderCell(fornecedor: IFornecedores) {
    if (fornecedor.status !== "Ativo") {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Fornecedor"
              onClick={() => {
                setFornecedor(fornecedor);
                setModalEdit(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Ativar Fornecedor"
              onClick={() => {
                handleStatusChange(fornecedor.fornecedorId.toString(), "1");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faCheckCircle}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Fornecedor"
              onClick={() => {
                setFornecedor(fornecedor);
                setModalEdit(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Desativar Fornecedor"
              onClick={() => {
                handleStatusChange(fornecedor.fornecedorId.toString(), "-1");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faTrashAlt}
            />
          </div>
        </>
      );
    }
  }

  const colunas: TableColumn<IFornecedores>[] = [
    {
      name: "Id",
      sortField: "fornecedorId",
      selector: (row: IFornecedores) => row.fornecedorId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IFornecedores) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IFornecedores) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const getFornecedores = useCallback(
    async (filtro: FiltroPesquisaFornecedorRequest) => {
      try {
        const data = await FornecedoresService.obterFornecedores(filtro);

        setFornecedores(data.data);
        setFiltros((oldState) => {
          return { ...oldState, totalItems: data.totalRegistros };
        });
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          title: "Não foi possível obter registros dos fornecedores",
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          timer: 4000,
          icon: "error",
          showConfirmButton: false,
        });

        setFornecedores([]);
      }
    },
    []
  );

  const handleStatusChange = async (id: string, status: string) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await FornecedoresService.atualizarFornecedor(id, { status });

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status do fornecedor alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await getFornecedores(filtros);
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível editar o Fornecedor`,
        text: "Ocorreu um erro inesperado.",
        showConfirmButton: true,
      });
    }
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });

    await getFornecedores({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((oldState) => {
      return { ...oldState, offset: (page - 1) * filtros.limit };
    });

    await getFornecedores({
      ...filtros,
      offset: (page - 1) * filtros.limit,
    });
  };

  const handleSort = async (
    column: TableColumn<IFornecedores>,
    sortDirection: string
  ) => {
    setFiltros((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await getFornecedores({
      ...filtros,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    });
  };

  useEffect(() => {
    getFornecedores(filtros);
  }, [filtros.status]);

  return (
    <>
      <LayoutPageTitle title="Fornecedores">
        <button
          onClick={() => {
            navigate("/Fornecedores/Adicionar");
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      {modalEdit && (
        <FornecedorEditModal
          exibirModal={modalEdit}
          handleClose={async () => {
            setModalEdit(false);
            await getFornecedores(filtros);
          }}
          fornecedor={fornecedor}
        />
      )}

      <div className="col-md-12">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setReset(!reset);
            await getFornecedores(filtros);
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Nome:
            </label>
            <input
              value={filtros.nome}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return {
                    ...oldState,
                    nome: e.target.value,
                  };
                });
              }}
              placeholder="Nome"
              type="text"
              className="form-control"
              id="form-nome"
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Status:
            </label>

            <select
              onChange={async (e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return { ...oldState, status: parseInt(e.target.value) };
                });
                setReset(!reset);

                await getFornecedores({
                  ...filtros,
                  status: parseInt(e.target.value),
                });
              }}
              value={filtros.status}
              placeholder="Status"
              className="form-control"
              id="form-select"
            >
              <option value="0" label="Todos">
                Todos
              </option>

              <option value="1" label="Ativo">
                Ativo
              </option>

              <option value="-1" label="Inativo">
                Inativo
              </option>
            </select>
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Código:
            </label>
            <input
              value={filtros.codigo}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return {
                    ...oldState,
                    codigo: e.target.value,
                  };
                });
              }}
              placeholder="Código"
              type="text"
              className="form-control"
              id="form-codigo"
            />
          </div>

          <div className="col-md-2">
            <div>
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  setReset(!reset);

                  setFiltros({
                    nome: "",
                    status: 0,
                    limit: 10,
                    totalItems: 0,
                    offset: 0,
                    sort: "fornecedorId",
                    codigo: "",
                  });

                  await getFornecedores({
                    nome: "",
                    status: 0,
                    limit: 10,
                    totalItems: 0,
                    offset: 0,
                    sort: "fornecedorId",
                    codigo: "",
                  });
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridUsuarios
            onSort={handleSort}
            progressPending={loading}
            limit={filtros.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={filtros.totalItems}
            paginationServer={true}
            colunas={colunas}
            tipo="Fornecedores"
            itens={fornecedores}
            pagination
            reset={reset}
          />
        </div>
      </div>
    </>
  );
}
