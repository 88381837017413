import IPastaGed from "../interfaces/IPastaGed";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { AdicionaPastaGedRequest } from "../interfaces/Requests/PastaGed/AdicionaPastaGedRequest";
import { EditarPastaGedRequest } from "../interfaces/Requests/PastaGed/EditarPastaGedRequest";
import FiltroPesquisaPastaGedRequest from "../interfaces/Requests/PastaGed/FiltroPesquisaPastaGedRequest";
import api from "./Api";

class PastaGedService {

    async obterPastaGeds(filtros: FiltroPesquisaPastaGedRequest): Promise<IRecuperaLista<IPastaGed>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IPastaGed>>(`PastaGed/RecuperaPastaGed?${query}`);

        return result;
    }
 

    async adicionaPastaGed(adicionaPastaGed: AdicionaPastaGedRequest): Promise<IPastaGed> {
        let { data: result } = await api.post<IPastaGed>(`PastaGed/AdicionaPastaGed`, adicionaPastaGed);

        return result;
    }


    async alterarStatus(pastaGedId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPastaGed>(`PastaGed/AtualizaPastaGedPorId?id=${pastaGedId}`, data);
    }

    async atualizarPastaGed(editarPastaGed: EditarPastaGedRequest): Promise<void> {

        let dataPastaGed = [
            { "op": "replace", "path": "/nome", "value": editarPastaGed.nome },
            { "op": "replace", "path": "/codigo", "value": editarPastaGed.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`PastaGed/AtualizaPastaGedPorId?id=${editarPastaGed.pastaGedId}`, dataPastaGed)
        ]);
    }
}


export default new PastaGedService();