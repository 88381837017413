import { EModulo, returnarEnumDescricaoID } from "../enum";
import api from "./Api";

import { ICampo } from "../interfaces/ICampo";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IConfiguracaoTela } from "../interfaces/IConfiguracaoTela";

import FiltroPesquisaLimitacaoTabelaResques from "../interfaces/Requests/LimitacoesTabelas/FiltroPesquisaLimitacaoTabelaResques";
import { tipoPorModulo } from "../utils/Utils";

class LimitacaoTabelaService {

  public async adicionaOpcoes(item: any): Promise<boolean> {
    let objeto: any = {};
    objeto[`${tipoPorModulo(item.modulo)}Id`] = eval(`item.${tipoPorModulo(item.modulo)}Id`);
    
    let request = {
      modulo: item.modulo,
      campo: item.campo,
      opcaoId: this.getOpcoesId(item.Lista, item.campoNome),
      ...objeto
    };

    await api.post<boolean>(`LimitacaoTabela/AdicionaLimitacaoTabela`, request);

    return true;
  }

  public async adicionaXOpcoes(item: any): Promise<boolean> {
    let objeto: any = {};
    objeto[`${tipoPorModulo(item.modulo)}Id`] = eval(`item.${tipoPorModulo(item.modulo)}Id`);
    let request = {
      modulo: item.modulo,
      campo: item.campo,
      opcaoId: this.getOpcoesXId(item.Lista),
      ...objeto
    };
    await api.post<boolean>(`LimitacaoTabela/AdicionaLimitacaoTabela`, request);
    return true;
  }

  public async adicionaListaLimitadaSolucoes(item: any): Promise<boolean> {

    await api.post<boolean>(`LimitacaoTabela/AdicionaLimitacaoTabela`, item);

    return true;
  }

  public async adicionaListaLimitadaFluxo(item: any, fluxoFaseId: number): Promise<boolean> {
    let request = {
      modulo: EModulo.Compromisso,
      campo: item.campo,
      opcaoId: this.getOpcoesId(item.Lista, item.entidade),
      fluxoFaseId: fluxoFaseId,
    };
    await api.post<boolean>(`LimitacaoTabela/AdicionaLimitacaoTabela`, request);
    return true;
  }

  public async adicionaListaLimitadaSocietario(item: any, tipoSociedadeId: number): Promise<boolean> {
    let request = {
      modulo: EModulo.Societario,
      campo: item.campo,
      opcaoId: this.getOpcoesId(item.Lista, item.entidade),
      tipoSociedadeId: tipoSociedadeId,
    };
    await api.post<boolean>(`LimitacaoTabela/AdicionaLimitacaoTabela`, request);
    return true;
  }

  public async adicionaListaLimitadaSocietarioXCampo(item: ICampo, tipoSociedadeId: number): Promise<boolean> {
    let request = {
      modulo: EModulo.Societario,
      campo: item.campo,
      opcaoId: this.getOpcoesXId(item.Lista),
      tipoSociedadeId: tipoSociedadeId,
    };
    await api.post<boolean>(`LimitacaoTabela/AdicionaLimitacaoTabela`, request);
    return true;
  }


  public async adicionaListaLimitada(item: ICampo, tela: IConfiguracaoTela): Promise<boolean> {
    let request = {
      modulo: tela.modulo,
      subModulo: tela.subModulo,
      areaDireitoId: tela.areaDireitoId,
      tipoDespesaId: tela.tipoDespesaId,
      tipoContratoId: tela.tipoContratoId,
      tipoAtoId: tela.tipoAtoId,
      campo: item.campo,
      opcaoId: this.getOpcoesId(item.Lista, item.entidade),
    };

    await api.post<boolean>(`LimitacaoTabela/AdicionaLimitacaoTabela`, request);
    return true;
  }

  public async obterListaLimitadaEmpresa(tela: IConfiguracaoTela, entidade: string, isConfiguracao = false): Promise<IRecuperaLista<any>> {
    let query = {
      modulo: tela.modulo,
      subModulo: tela.subModulo,
      areaDireitoId: tela.areaDireitoId,
      tipoDespesaId: tela.tipoDespesaId,
      tipoAtoId: tela.tipoAtoId,
      IsConfiguracaoTelaCadastro: isConfiguracao,
    };

    let { data: result } = await api.get<IRecuperaLista<any>>(`${entidade}/RecuperaListaLimitada`, { params: query });

    return result;
  }

  public async obterListaCompleta(entidade: string): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`${entidade}/Recupera${entidade}`);
    return result;
  }

  async obterListaLimitadaTelaPesquisa(filtros: FiltroPesquisaLimitacaoTabelaResques): Promise<IRecuperaLista<any>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

    if (filtros.modulo && filtros.modulo > 0) {
      query += `&modulo=${filtros.modulo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(`${filtros.controller}/RecuperaListaLimitada?${query}`);

    return result;
  }

  private getOpcoesId = (Lista: any, entidade: string): number[] => {
    const OpcoesId: any[] = [];
    if (Lista && Lista.length > 0) {
      Lista.forEach((item: any) => {
        if (item[`${returnarEnumDescricaoID(entidade)}`]) {
          OpcoesId.push(item[`${returnarEnumDescricaoID(entidade)}`]);
        }
      });
    }
    return OpcoesId;
  };

  private getOpcoesXId = (Lista: any): number[] => {
    const OpcoesId: any[] = [];
    if (Lista && Lista.length > 0) {
      Lista.forEach((item: any) => {
        if (item.xCampoOpcaoId) {
          OpcoesId.push(item.xCampoOpcaoId);
        }
      });
    }
    return OpcoesId;
  };


}

export default new LimitacaoTabelaService();
