import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoImovel from "../interfaces/ITipoImovel";
import { AdicionaTipoImovelRequest } from "../interfaces/Requests/TipoImovel/AdicionaTipoImovelRequest";
import FiltroPesquisaTipoImovelRequest from "../interfaces/Requests/TipoImovel/FiltroPesquisaTipoImovelRequest";
import { EditarTipoImovelRequest } from "../interfaces/Requests/TipoImovel/EditarTipoImovelRequest";

class TipoImovelService {
  async obterTipoImoveis(
    filtros: FiltroPesquisaTipoImovelRequest
  ): Promise<IRecuperaLista<ITipoImovel>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ITipoImovel>>(
      `TipoImovel/RecuperaTipoImovel?${query}${limit}`
    );

    return result;
  }

  async adicionaTipoImovel(
    adicionaTipoImovel: AdicionaTipoImovelRequest
  ): Promise<ITipoImovel> {
    let { data: result } = await api.post<ITipoImovel>(
      `TipoImovel/AdicionaTipoImovel`,
      adicionaTipoImovel
    );

    return result;
  }

  async alterarStatus(tipoImovelId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ITipoImovel>(
      `TipoImovel/AtualizaTipoImovelPorId?id=${tipoImovelId}`,
      data
    );
  }

  async atualizarTipoImovel(
    editarTipoImovel: EditarTipoImovelRequest
  ): Promise<void> {
    let dataTipoImovel = [
      { op: "replace", path: "/nome", value: editarTipoImovel.nome },
      { op: "replace", path: "/codigo", value: editarTipoImovel.codigo },
    ];

    await Promise.all([
      await api.patch<void>(
        `TipoImovel/AtualizaTipoImovelPorId?id=${editarTipoImovel.tipoImovelId}`,
        dataTipoImovel
      ),
    ]);
  }
}

export default new TipoImovelService();
