import clsx from "clsx";
import { Form, Modal } from "react-bootstrap";
import GridPadrao from "../Comum/GridPadrao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import FiltroPesquisaAdvogadoRequest from "../../interfaces/Requests/FiltroPesquisaAdvogadoRequest";
import AdvogadoInternoService from "../../services/AdvogadoInternosService";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import Swal from "sweetalert2";
import IAdvogadoInterno from "../../interfaces/IAdvogadoInterno";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

interface IAdvogadoInternoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  advogadoInternoSelected: IAdvogadoInterno[];
  setFieldValue: any;
}

const AdvogadoInternoField = ({ toggleModal, exibirModal, advogadoInternoSelected, setFieldValue }: IAdvogadoInternoFieldProps) => {
  const [advogados, setAdvogados] = useState<IAdvogadoInterno[]>([]);
  const [carregandoAdvogados, setCarregandoAdvogados] = useState(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaAdvogadoRequest>({
    filtro: "",
    tipoPesquisa: 1,
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "nome",
  });

  useEffect(() => {
    if (advogados.length > 0) {
      advogados.forEach((advogado) => {
        const advogadoChecked = advogadoInternoSelected.filter((e: IAdvogadoInterno) => e.advogadoInternoId === advogado.advogadoInternoId)[0];
        if (advogadoChecked && advogadoChecked.checked) advogado.checked = true;
        else advogado.checked = false;
        if (advogadoChecked && advogadoChecked.principal) advogado.principal = true;
        else advogado.principal = false;
      });
    }

    setAdvogados(advogados);
  }, [advogados, advogadoInternoSelected]);

  useEffect(() => { if(exibirModal) carregarAdvogadosInternos(filtrosPesquisa); }, [exibirModal]);

  useEffect(() => {
    if (!carregandoAdvogados && exibirModal) {
      carregarAdvogadosInternos(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  const marcarPrincipal = (advogadoId: number) => {
    setFieldValue("advogadoInternoList", [], true);
    advogados.forEach((advogado) => {
      if (advogado.advogadoInternoId == advogadoId) {
        if (!advogado.principal) {
          advogado.principal = true;
          advogado.checked = true;
          setAdvogados(advogados);
          setFieldValue("advogadoInternoList", advogados, true);
          return;
        }
      } else {
        advogado.principal = false;
        setAdvogados(advogados);
        setFieldValue("advogadoInternoList", advogados, true);
        return;
      }
    });
  };

  const adicionar = (advogadoId: number) => {
    setFieldValue("advogadoInternoList", [], true);
    advogados.forEach((advogado) => {
      if (advogado.advogadoInternoId == advogadoId) {
        if (!advogado.checked) {
          advogado.checked = true;
          setAdvogados(advogados);
          setFieldValue("advogadoInternoList", advogados, true);
          return;
        }
        if (advogado.checked) {
          advogado.checked = false;
          advogado.principal = false;
          setAdvogados(advogados);
          setFieldValue("advogadoInternoList", advogados, true);
          return;
        }
      }
    });
  };

  const carregarAdvogadosInternos = async (filtro: FiltroPesquisaAdvogadoRequest) => {
    try {
      setCarregandoAdvogados(true);

      let resultado: IRecuperaLista<IAdvogadoInterno>;
      resultado = await AdvogadoInternoService.obterAdvogadosPorFiltro(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setAdvogados(resultado.data.sort((a, b) => a.nome.localeCompare(b.nome)));
      setCarregandoAdvogados(false);
    } catch (error: any) {
      setAdvogados([]);
      setCarregandoAdvogados(false);
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const colunas: TableColumn<IAdvogadoInterno>[] = [
    {
      name: "Id",
      sortField: "advogadoInternoId",
      selector: (row: IAdvogadoInterno) => row.advogadoInternoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome Advogado",
      sortField: "nome",
      selector: (row: IAdvogadoInterno) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IAdvogadoInterno) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      cell: (row: IAdvogadoInterno) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.principal}
              onClick={() => marcarPrincipal(row.advogadoInternoId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
    {
      name: "Adicionar?",
      cell: (row: IAdvogadoInterno) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => adicionar(row.advogadoInternoId)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAdvogadoInterno>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Advogados Internos</h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-auto">
              <input
                value={filtrosPesquisa.filtro}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, filtro: e.target.value };
                  });
                }}
                placeholder="Advogado(s) interno(s)"
                type="text"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto">
              <button
                onClick={() => {
                  carregarAdvogadosInternos(filtrosPesquisa);
                }}
                className="btn btn-orange"
              >
                {<> Pesquisar </>}
              </button>
            </div>
            <div className="col-md-auto">{<> Buscar por: </>}</div>
            <div className="col-md-auto">
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 1 })}
                  label="Nome"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 2 })}
                  label="Documento"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 3 })}
                  label="Código"
                  name="group1"
                  type="radio"
                />
              </div>
            </div>
          </div>
          <div className="col-md-auto mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoAdvogados}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Advogados"
              itens={advogados}
            />
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={advogadoInternoSelected.filter((e) => e.principal).length == 0}
            onClick={() => toggleModal()}
            type="button"
            className="btn btn-orange ms-5"
          >
            Adicionar
          </button>

          <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdvogadoInternoField;
