import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faUsers } from "@fortawesome/free-solid-svg-icons";
import { TableColumn } from "react-data-table-component";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import NumberFormat from "react-number-format";
import clsx from "clsx";

import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";
import { currencyFormatter } from "../../utils/Utils";
import { ECamposIDEnum, returnarEnumDescricaoID } from "../../enum/ECamposIDEnum";

import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";
import { EModulo, ETelaEnum } from "../../enum";
import AtividadeEmpresaService from "../../services/AtividadeEmpresaService";
import SocioAdministradorField from "../Comum/SociosAdministradoresField";
import ISocioAdministrador from "../../interfaces/ISocioAdministrador";
import GridPadrao from "../GridPadrao";

interface ICamposDinamicosPropos {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
}

const CamposCadastroDinamicos = ({ configuracaoTela, formik }: ICamposDinamicosPropos) => {
  const [campos, setCampos] = useState<any[]>([]);
  const [genericId, setGenericId] = useState<number>(0);
  const [exibirModalSocioAdministradores, setExibirModalSocioAdministradores] = useState<boolean>(false);

  const toggleAdvogadosInternosModal = (): void => setExibirModalSocioAdministradores(!exibirModalSocioAdministradores)

  useEffect(() => {
    if (configuracaoTela.campoTipo == ETipoCampoEnum.Lista || configuracaoTela.campoTipo == ETipoCampoEnum.EnumLista) {
      carregaLista();
    }
  }, [formik.values.tipoSociedadeId]);

  const colunasSociosAdministradores: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "socioAdministradorId",
      selector: (row: any) => row.socioAdministradorId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
  ];

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const carregaLista = async () => {
    try {

      if (configuracaoTela.campoNome == ECamposIDEnum.StatusEmpresa) {
        setCampos([{ nome: "Ativo", statusEmpresa: 1 }, { nome: "Inativo", statusEmpresa: 2 }]);
        return;
      }

      if (configuracaoTela.campoNome == ECamposIDEnum.AtividadeEmpresa || configuracaoTela.campoNome == ECamposIDEnum.AtividadeEmpresaPrincipal) {
        let campos = await AtividadeEmpresaService.obterAtividadeEmpresas({
          offset: 0,
          limit: 10000,
          sort: "atividadeEmpresaId",
          status: 0,
          nome: "",
          codigo: "",
          totalItems: 0
        });
        setCampos(campos.data);
        return;
      }

      if (configuracaoTela.isXCampo) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
          tipoSociedadeId: formik.values.tipoSociedadeId,
          tela: ETelaEnum.TelaCadastroEmpresa,
          campo: configuracaoTela.campo,
          modulo: EModulo.Societario
        });
        setCampos(campos.data);
        return;
      }

      if (!configuracaoTela.isXCampo) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(configuracaoTela?.campoNome, EModulo.Societario, formik.values.tipoSociedadeId, 0);
        setCampos(campos.data);
        return;
      }

    } catch (error: any) {
      setCampos([]);
    }
  };

  const onChangeSelect = (value: string, nome: string) => {
    formik.setFieldTouched(`${nome.toLowerCase()}Id`, true);
    formik.setFieldValue(`${nome.toLowerCase()}Id`, parseInt(value));
    setGenericId(parseInt(value));
  };

  const renderInput = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
      case ETipoCampoEnum.EnumLista:
        component = (
          <>
            <select
              value={genericId}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              onChange={(e) => onChangeSelect(e.target.value, configuracaoTela?.campoNome || "")}
              placeholder={configuracaoTela?.campoNome}
              id={`form-${configuracaoTela?.campoNome}`}
            >
              <option value="0"> Selecione </option>
              {configuracaoTela.isXCampo ?
                campos?.map((item: any) => {
                  return (
                    <option key={item[`xCampoOpcaoId`]} value={item[`xCampoOpcaoId`]}>
                      {item.nome}
                    </option>
                  );
                }) :
                campos?.map((item: any) => {
                  return (
                    <option key={item[`${configuracaoTela?.campoNome.toLowerCase()}Id`]} value={item[returnarEnumDescricaoID(configuracaoTela?.campoNome)]}>
                      {item.nome}
                    </option>
                  );
                })}
            </select>
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}Id`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Valor:
        component = (
          <>
            <NumberFormat
              format={currencyFormatter}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              thousandSeparator={true}
              prefix={"R$"}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Inteiro:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="number"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="checkbox"
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="date"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <textarea
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              maxLength={500}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,

              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Texto:
        component = (
          <>{
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              maxLength={500}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          }
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;


      case ETipoCampoEnum.Pessoa:
        if (configuracaoTela?.campoNome == ECamposIDEnum.SociosAdministradores) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalSocioAdministradores(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione os socios(a)s
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values.sociosAdministradores.filter((e: ISocioAdministrador) => e.checked).length === 0 && "Nenhum socios selecionado"}
                  {formik.values.sociosAdministradores.filter((e: ISocioAdministrador) => e.checked).length === 1 &&
                    `${formik.values.sociosAdministradores.filter((e: ISocioAdministrador) => e.checked).length} socios selecionado`}
                  {formik.values.sociosAdministradores.filter((e: ISocioAdministrador) => e.checked).length > 1 &&
                    `${formik.values.sociosAdministradores.filter((e: ISocioAdministrador) => e.checked).length} socios selecionados`}
                </a>
              </div>
              <GridPadrao
                paginationServer={true}
                colunas={colunasSociosAdministradores}
                tipo="Socios Administradores"
                itens={formik.values.sociosAdministradores.filter((e: ISocioAdministrador) => e.checked)}
              />
              <hr />
            </>
          );
        }
        break;
    }

    return component;
  };

  const renderClassName = () => {

    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa ||
      configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  const renderModalSocioAdministrador = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.SociosAdministradores) {
      return (
        <>
          <SocioAdministradorField
            socioSelected={formik.values.socioAdminitradores || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalSocioAdministradores}
            toggleModal={toggleAdvogadosInternosModal}
            empresaId={formik.values.empresaId}
          />
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      {renderModalSocioAdministrador()}

      <div className={renderClassName()}>
        <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
          {configuracaoTela.label}
          <a style={{ fontSize: "12px" }}>
            {configuracaoTela.obrigatorio ? "*:" : ":"}
            {configuracaoTela.help && <PopoverHelper></PopoverHelper>}
          </a>
        </label>
        <div className="row">
          <div className="col-md-12">{renderInput()}</div>
        </div>
      </div>
    </>
  );
};

export default CamposCadastroDinamicos;
