import { useCallback, useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Grupo from "../Grupo";
import "./styles.scss";

import { MdFileDownload } from "react-icons/md";
import jsPDF from "jspdf";

import OrganogramaService from "../../../../../services/OrganogramaService";

import { IOrganogramaGrupo } from "../../../../../interfaces/IOrganogramaGrupo";
import { OrganogramaType } from "../../../../../interfaces/Types/OrganogramaType";
import { showErrorMessage } from "../../../../SocietarioPage/utils";

type OrganogramaModalProps = {
  type: OrganogramaType;
  show: boolean;
  empresaId: number;
  nomeEmpresa: string;
  onHide: () => void;
};

export default function OrganogramaModal({
  type,
  show,
  empresaId,
  nomeEmpresa,
  onHide,
}: OrganogramaModalProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const [grupos, setGrupos] = useState<IOrganogramaGrupo[]>([]);
  const [alignCenter, setAlignCenter] = useState(false);

  const obterOrganograma = useCallback(async () => {
    try {
      const organogramaService = new OrganogramaService();
      const { data } = await organogramaService.exibirOrganograma(
        empresaId,
        type
      );
      setGrupos(data.grupos);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel obter o organograma!`,
        text: showErrorMessage(error.message),
        showConfirmButton: true,
      });
    }
  }, []);

  const salvarOrganograma = useCallback(async () => {
    const container = containerRef.current;

    if (!container) return;

    const pdf = new jsPDF();

    const listWidth = container.scrollWidth;
    const listHeight = container.scrollHeight;
    const orientation = listWidth > listHeight ? "landscape" : "portrait";

    pdf.addPage(orientation, "p");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const scaleX = pdfWidth / listWidth;
    const scaleY = pdfHeight / listHeight;

    const scale = listWidth > 10000 ? Math.min(scaleX, scaleY) * 2 : Math.min(scaleX, scaleY)

    pdf.html(container, {
      x: 0,
      y: 50,
      callback(doc) {
        const filename = `${nomeEmpresa}_organograma.pdf`;
        doc.save(filename);
      },
      html2canvas: {
        scale,
        useCORS: true,
      },
    });
  }, []);

  useEffect(() => {
    obterOrganograma();
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const hasScroll =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setAlignCenter(!hasScroll);
    }

    return () => {
      setAlignCenter(true);
    };
  }, []);

  return (
    <Modal
      scrollable
      dialogClassName="modal-size"
      contentClassName="modal-size-height"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <div className="modal-custom-header">
          <Modal.Title>{nomeEmpresa}</Modal.Title>

          <button
            onClick={salvarOrganograma}
            type="button"
            className="btn btn-orange"
          >
            Baixar
            <MdFileDownload size={20} />
          </button>
        </div>
      </Modal.Header>

      <Modal.Body>
        <ScrollContainer>
          <div
            ref={containerRef}
            style={{
              display: "flex",
            }}
          >
            {grupos.map((data) => (
              <Grupo
                key={data.nome}
                nome={data.nome}
                empresas={data.empresas}
              />
            ))}
          </div>
        </ScrollContainer>
      </Modal.Body>
    </Modal>
  );
}
