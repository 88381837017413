/* eslint-disable no-case-declarations */
import { camelizar } from "../pages/ConfiguracaoTelaPage/Empresa/utils";

export enum EPropriedadesIDEnum {
  GestorEmpresa = "ContenciosoGestorEmpresa",
  TipoContratoGrupo = "TipoContratoGrupo",
  HabilitarGrupoEconomicoProcesso = "HabilitarGrupoEconomicoProcesso",
  CopiarContratoPrincipal = "CopiarContratoPrincipal",
  HabilitarTipoProvisao = "HabilitarTipoProvisao",
}

export enum ESeparadorIDEnum {
  // Mostra o andamento do processo
  ExibirAndamento = "ExibirAndamento",

  // Mostra os compromissos relacionados ao processo
  ExibirCompromisso = "ExibirCompromisso",

  // Mostra os arquivos relacionados ao processo
  ExibirArquivo = "ExibirArquivo",

  // Mostra as publicações relacionadas ao processo
  ExibirPublicacao = "ExibirPublicacao",

  // Mostra as comunicações relacionadas ao processo
  ExibirComunicacao = "ExibirComunicacao",

  // Mostra as comunicações relacionadas ao processo
  ExibirAudiencia = "ExibirAudiencia",

  // Mostra os documentos relacionados ao processo
  ExibirDocumento = "ExibirDocumento",
}

export enum ECamposIDEnum {
  NumeroProcesso = "NumeroProcesso",
  Numero = "Numero",
  Moeda = "Moeda",
  Fornecedor = "Fornecedor",
  Principal = "Principal",
  Representantes = "Representantes",
  SociosAdministradores = "SociosAdministradores",
  AtividadeEmpresaPrincipal = "AtividadeEmpresaPrincipal",
  AtividadeEmpresa = "AtividadeEmpresa",
  AreaDireito = "AreaDireito",
  FaseProcessual = "FaseProcessual",
  SubAreaDireito = "SubAreaDireito",
  TipoContingencia = "TipoContingencia",
  StatusProcesso = "StatusProcesso",
  Classificacao = "Classificacao",
  Ambito = "Ambito",
  Esfera = "Esfera",
  MotivoEncerramento = "MotivoEncerramento",
  Instancia = "Instancia",
  TipoAcao = "TipoAcao",
  Risco = "Risco",
  Cidade = "Cidade",
  DataDistribuicao = "DataDistribuicao",
  DataRecebimento = "DataRecebimento",
  CentroCusto = "TipoCentroCusto",
  CentroCustoProvisao = "CentroCustoProvisao",
  Escritorio = "Escritorio",
  Estado = "Estado",
  FormaParticipacao = "FormaParticipacao",
  Pais = "Pais",
  TipoDocumento = "TipoDocumento",
  TipoDocumentoCheckList = "TipoDocumentoCheckList",
  ResumoProcesso = "ResumoProcesso",
  ObservacoesProcesso = "ObservacoesProcesso",
  Observacao = "Observacao",
  DescricaoObjetoAcao = "DescricaoObjetoAcao",
  AdvogadoInterno = "AdvogadoInterno",
  ProtocoloDocumentos = "ProtocoloDocumentos",
  Advogado = "Advogado",
  ParteContraria = "ParteContraria",
  ParteContrariaSemDocumento = "ParteContrariaSemDocumento",
  Empresa = "Empresa",
  EmpresaSemDocumento = "EmpresaSemDocumento",
  Tabela = "Tabela",
  EmpresaRegulatorio = "EmpresaRegulatorio",
  Juiz = "Juiz",
  Departamento = "Departamento",
  ClasseMarca = "ClasseMarca",
  ClassificacaoIPC = "ClassificacaoIPC",
  NaturezaMarca = "NaturezaMarca",
  TipoMarca = "TipoMarca",
  ApresentacaoMarca = "ApresentacaoMarca",
  CEP = "CEP",
  Endereco = "Endereco",
  Bairro = "Bairro",
  Logradouro = "Logradouro",
  TipoAquisicao = "TipoAquisicao",
  TipoLocalidade = "TipoLocalidade",
  NumeroEndereco = "NumeroEndereco",
  TipoServico = "TipoServico",
  OrgaoResponsavel = "OrgaoResponsavel",
  TipoInstrumento = "TipoInstrumento",
  TipoDespesa = "TipoDespesa",
  PublicacaoAto = "PublicacaoAto",
  AtoRegistradoJunta = "AtoRegistradoJunta",
  Banco = "Banco",
  FormaPagamento = "FormaPagamento",
  Gestor = "Gestor",
  GestaoProcesso = "GestaoProcesso",
  StatusAto = "StatusAto",
  OrgaoRegistro = "OrgaoRegistro",
  StatusEmpresa = "StatusEmpresa",
  SubGrupo = "SubGrupo",
  TipoEmpresa = "TipoEmpresa",
  ValorCausa = "ValorCausa",
  AprovarDespesa = "AprovarDespesa",
  Competencia = "Competencia",
  StatusImovel = "StatusImovel",
  TipoVigencia = "TipoVigencia",
  TipoDocumentoMinuta = "TipoDocumentoMinuta",
  TipoProposta = "TipoProposta",
  StatusPropostaAcordo = "StatusPropostaAcordo",
  StatusDespesa = "StatusDespesa",
  PapelSignatario = "PapelSignatario",
  TipoProtocoloTribunal = "TipoProtocoloTribunal",
  TipoParteContraria = "TipoParteContraria",
  TipoCompromisso = "TipoCompromisso",
  GrupoUsuario = "GrupoUsuario",
  GrupoEconomico = "GrupoEconomico",
  TipoAndamento = "AndamentoTipo",
  TipoMinuta = "TipoMinuta",
  TipoRelacionamento = "TipoRelacionamento",
  Pasta = "PastaId",
  ProcessoPrincipalId = "ProcessoPrincipalId",
  ResponsavelDesdobramento = 'ResponsavelDesdobramento',
  TipoContratacao = 'TipoContratacao',
  RegimeContratacao = 'RegimeContratacao',
  MotivoDesligamento = 'MotivoDesligamento',
  EstadoCivil = 'EstadoCivil',
  TipoContratoGrupo = "TipoContratoGrupo",
  ProcessoEmpresa = "ProcessoEmpresa",
  GestorAreaSolicitanteApData = "GestorAreaSolicitanteApData",
  FluxoFase = "FluxoFase",
  AndamentoTipo = "AndamentoTipo",
  TipoContrato = "TipoContrato",
  ResultadoAudiencia = "ResultadoAudiencia",
  ResultadoSentenca = "ResultadoSentenca",

}

export const returnarEnumDescricaoID = (campoNome: string, isXCampo?: boolean) => {
  switch (campoNome) {

    case ECamposIDEnum.FormaParticipacao:
      return "formaParticipacaoId";

    case ECamposIDEnum.Advogado:
      return "advogadoId";

    case ECamposIDEnum.AdvogadoInterno:
      return "advogadoInternoId";

    case ECamposIDEnum.PapelSignatario:
      return "papelSignatarioId";

    case ECamposIDEnum.FaseProcessual:
      return "faseProcessualId";

    case ECamposIDEnum.AreaDireito:
      return "areaDireitoId";

    case ECamposIDEnum.ResultadoAudiencia:
      return "resultadoAudienciaId";

    case ECamposIDEnum.ResultadoSentenca:
      return "resultadoSentencaId";

    case ECamposIDEnum.SubAreaDireito:
      return "subAreaDireitoId";

    case ECamposIDEnum.TipoContingencia:
      return "tipoContingenciaId";

    case ECamposIDEnum.StatusProcesso:
      return "statusProcessoId";

    case ECamposIDEnum.Classificacao:
      return "classificacaoId";

    case ECamposIDEnum.Ambito:
      return "ambitoId";

    case ECamposIDEnum.TipoContrato:
      return "tipoContratoId";

    case ECamposIDEnum.Esfera:
      return "esferaId";

    case ECamposIDEnum.MotivoEncerramento:
      return "motivoEncerramentoId";

    case ECamposIDEnum.Instancia:
      return "instanciaId";

    case ECamposIDEnum.TipoAcao:
      return "tipoAcaoId";

    case ECamposIDEnum.Risco:
      return "riscoId";

    case ECamposIDEnum.Cidade:
      return "cidadeId";

    case ECamposIDEnum.CentroCusto:
      return "tipoCentroCustoId";

    case ECamposIDEnum.Escritorio:
      return "escritorioId";

    case ECamposIDEnum.Estado:
      return "estadoId";

    case ECamposIDEnum.Pais:
      return "paisId";

    case ECamposIDEnum.TipoDocumento:
    case ECamposIDEnum.TipoDocumentoMinuta:
      return "tipoDocumentoId";

    case ECamposIDEnum.Juiz:
      return "juizId";

    case ECamposIDEnum.Departamento:
      return "departamentoId";

    case ECamposIDEnum.ClasseMarca:
      return "classeMarcaId";

    case ECamposIDEnum.ClassificacaoIPC:
      return "classificacaoIPCId";

    case ECamposIDEnum.TipoMarca:
      return "tipoMarcaId";

    case ECamposIDEnum.NaturezaMarca:
      return "naturezaMarcaId";

    case ECamposIDEnum.ApresentacaoMarca:
      return "apresentacaoMarcaId";

    case ECamposIDEnum.TipoLocalidade:
      return "tipoLocalidadeId";

    case ECamposIDEnum.TipoAquisicao:
      return "tipoAquisicaoId";

    case ECamposIDEnum.TipoServico:
      return "tipoServicoId";

    case ECamposIDEnum.OrgaoResponsavel:
      return "orgaoResponsavelId";

    case ECamposIDEnum.TipoInstrumento:
      return "tipoInstrumentoId";

    case ECamposIDEnum.Gestor:
    case ECamposIDEnum.GestorAreaSolicitanteApData:
      return "gestorId";

    case ECamposIDEnum.TipoDespesa:
      return "tipoDespesaId";

    case ECamposIDEnum.PublicacaoAto:
      return "publicacaoAtoId";

    case ECamposIDEnum.AtoRegistradoJunta:
      return "atoRegistradoJuntaId";

    case ECamposIDEnum.Banco:
      return "bancoId";

    case ECamposIDEnum.FormaPagamento:
      return "formaPagamentoId";

    case ECamposIDEnum.StatusAto:
      return "statusAtoId";

    case ECamposIDEnum.AtividadeEmpresaPrincipal:
      return "atividadeEmpresaId";

    case ECamposIDEnum.OrgaoRegistro:
      return "orgaoRegistroId";

    case ECamposIDEnum.StatusEmpresa:
      return "statusEmpresa";

    case ECamposIDEnum.SubGrupo:
      return "subGrupoId";

    case ECamposIDEnum.TipoEmpresa:
      return "tipoEmpresaId";

    case ECamposIDEnum.AprovarDespesa:
      return "aprovarDespesaId";

    case ECamposIDEnum.StatusImovel:
      return "statusImovelId";

    case ECamposIDEnum.TipoProposta:
      return "tipoPropostaId";

    case ECamposIDEnum.StatusPropostaAcordo:
      return "statusPropostaAcordoId";

    case ECamposIDEnum.StatusDespesa:
      return "statusDespesaId";

    case ECamposIDEnum.TipoMinuta:
      return "tipoMinutaId";

    case ECamposIDEnum.TipoRelacionamento:
      return "tipoRelacionamentoId";

    case ECamposIDEnum.ResponsavelDesdobramento:
      return "responsavelDesdobramentoId";

    case ECamposIDEnum.TipoContratacao:
      return "tipoContratacaoId";

    case ECamposIDEnum.RegimeContratacao:
      return "regimeContratacaoId";

    case ECamposIDEnum.MotivoDesligamento:
      return "motivoDesligamentoId";

    case ECamposIDEnum.EstadoCivil:
      return "estadoCivilId";

    case ECamposIDEnum.ProcessoEmpresa:
      return "processoEmpresaId";

    case ECamposIDEnum.TipoCompromisso:
      return "tipoCompromissoId";

    case ECamposIDEnum.FluxoFase:
      return "fluxosFasesId";

    case ECamposIDEnum.AndamentoTipo:
      return "andamentoTipoId";

    default:
      return (isXCampo ? `${campoNome?.toLowerCase()}Id` : `${camelizar(`${campoNome}`)}Id`);
  }
};