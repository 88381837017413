import React,{ createContext } from "react";
import { DashboardContexto } from "../DashboardContext";
import { IWidget } from "../widgets";
import { DropavelProps } from "./DropavelProps";

const DragAndDropContexto = createContext<{instance: Dropavel, state: DropavelState}|undefined>(undefined);


interface ArrastavelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    index: number;
}

const Arrastavel = (props :ArrastavelProps) => {

    const context = React.useContext(DragAndDropContexto);
    const dashboadContext = React.useContext(DashboardContexto);
    const [arrastando, setArrastando] = React.useState<boolean>(false);

    return (
        <>

            <div
                {...props}
                draggable={dashboadContext?.editando}
                className={`${props.className}${arrastando ? " arrastando" : ""}`}
                onDragStart={(e) => {
                    if(!dashboadContext?.editando) return;
                    setArrastando(true);
                    context?.instance.startDragging(e.currentTarget, props.index);
                    
                    e.dataTransfer.setDragImage(document.createElement('div'), e.currentTarget.offsetWidth/2, e.currentTarget.offsetHeight/2);
                    e.dataTransfer.dropEffect = "move";
                }}

                onDragOver={(e) => {
                    if(!dashboadContext?.editando) return;
                    e.preventDefault();
                    let index = props.index;

                    if(context?.state.dragging === e.currentTarget){
                        return;
                    }

                    if(e.nativeEvent.offsetX > e.currentTarget.offsetWidth / 2){
                        index++;
                    }

                    context?.instance.draggingOver(e.currentTarget, index)
                }}

                style={context?.instance.itemEstilo(props.index)}

                onDrag={(e) => {
                    e.preventDefault();
                }}

                onDragEnd={(e) => {
                    setArrastando(false);
                    if(!context) return
                    const posicao = context.state.draggingOverIndex;
                    if(posicao >= 0){                        
                        dashboadContext?.instancia.moverWidget((props.children as IWidget).props.titulo, posicao)
                    }
                    context?.instance.drop();
                }}
            >
                {props.children}
            </div>
        </>
    )

}


interface DropavelState{
    dragging: HTMLElement|null;
    draggingIndex: number;
    draggingOver: HTMLElement|null;
    draggingOverIndex: number;
}

class Dropavel extends React.Component<DropavelProps, DropavelState> {

    constructor(props: DropavelProps) {
        super(props);
        this.state = {
            dragging: null,
            draggingIndex: -1,
            draggingOver: null,
            draggingOverIndex: -1
        }
        this.itemEstilo = this.itemEstilo.bind(this);
        this.startDragging = this.startDragging.bind(this);
        this.draggingOver = this.draggingOver.bind(this);
    }

    itemEstilo(index: number) {
        if(this.state.draggingIndex >= 0 && this.state.draggingIndex === index) {
            return {
                order: this.state.draggingOverIndex >= 0 ? this.state.draggingOverIndex : index,
                zIndex: 10000
            }
        }
        return {
            order: this.state.draggingOverIndex > index? index : index+1
        }
    }

    startDragging(element: HTMLElement, index: number) {
        this.setState({
            dragging: element,
            draggingIndex: index
        })
    }

    draggingOver(element: HTMLElement, index: number): HTMLElement|undefined {      

        this.setState({
            draggingOver: element,
            draggingOverIndex: index
        })

        return 
    }

    drop() {
        this.setState({
            dragging: null,
            draggingIndex: -1,
            draggingOver: null,
            draggingOverIndex: -1
        })
    }

    render(){
        return (
            <DragAndDropContexto.Provider value={{instance: this, state: this.state}}>
                {this.props.children}
            </DragAndDropContexto.Provider>
        )
    }
}


const DragAndDropContextoProvider = ( props: {children: IWidget[]}) => {
    return (
            <Dropavel>
                {props.children.map((widget:IWidget, index:number) => {
                    return <Arrastavel
                        key={index}
                        index={index}
                        className={
                            'widget'+
                            ` col-xs-${widget.props.xs}` +
                            ` col-sm-${widget.props.sm}` +
                            ` col-md-${widget.props.md}` +
                            ` col-lg-${widget.props.lg}` +
                            ` col-xl-${widget.props.xl}`  
                        }
                    >
                        {widget}
                    </Arrastavel>
                })}
            </Dropavel>
    );
};

export { DragAndDropContexto, DragAndDropContextoProvider };
