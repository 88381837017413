import { useEffect, useState } from "react";
import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import { Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { faMinus, faPlus, faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import Swal from "sweetalert2";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaAdvogadoRequest from "../../interfaces/Requests/FiltroPesquisaAdvogadoRequest";
import { AdicionaAdvogadoRequest } from "../../interfaces/Requests/Advogado/AdicionaAdvogadoRequest";
import { IPessoa } from "../../interfaces/IPessoa";
import IEstado from "../../interfaces/IEstado";
import IAdvogado from "../../interfaces/IAdvogado";

import AdvogadoService from "../../services/AdvogadoService";
import RecuperaPessoa from "../Comum/Pessoa/RecuperaPessoa";
import AdicionaPessoa from "../Comum/Pessoa/AdicionaPessoa";
import EstadoService from "../../services/EstadoService";
import { Nullable } from "../types/Nullable";
import GridPadrao from "../Comum/GridPadrao";
import FiltroPesquisaEstadoRequest from "../../interfaces/Requests/Estado/FiltroPesquisaEstadoRequest";
import Spinner from "../Spinner";

interface IAdvogadoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  advogadoSelected: IAdvogado[];
  setFieldValue: any;
}

const AdvogadoField = ({ toggleModal, exibirModal, advogadoSelected, setFieldValue }: IAdvogadoFieldProps) => {
  const [pessoa, setPessoa] = useState<Nullable<IPessoa>>(null);
  const [tipoDocumento, setTipoDocumento] = useState<string>("");
  const [numeroDocumento, setNumeroDocumento] = useState<string>("");
  const [estados, setEstado] = useState<IEstado[]>([]);
  const [exibirCadastro, setExibirCadastro] = useState(false);

  const [advogados, setAdvogados] = useState<IAdvogado[]>([]);
  const [carregandoAdvogados, setCarregandoAdvogados] = useState(false);
  const [filtrosAdvogado, setFiltrosAdvogado] = useState<FiltroPesquisaAdvogadoRequest>({
    filtro: "",
    tipoPesquisa: 1,
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "advogadoId",
  });

  const [filtrosPesquisa] = useState<FiltroPesquisaEstadoRequest>({
    nome: '',
    codigo: '',
    status: 1,
    limit: 50,
    totalItems: 0,
    offset: 0,
    sort: 'estadoId'
  });

  const handlerPessoa = (newPessoa: IPessoa) => setPessoa(newPessoa);

  const handlerTipo = (newTipo: string) => setTipoDocumento(newTipo);

  const handlerNumeroDocumento = (newNumeroDocumento: string) => setNumeroDocumento(newNumeroDocumento);


  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>Use o campo de busca para verificar se o advogado desejado já existe em nossa base, caso ele não seja encontrado, basta clicar no botão de + para efetuar o novo cadastro.</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  useEffect(() => {
    if (advogados.length > 0) {
      advogados.forEach((advogado) => {
        const advogadoChecked = advogadoSelected.filter((e: IAdvogado) => e.advogadoId === advogado.advogadoId)[0];
        if (advogadoChecked && advogadoChecked.checked) advogado.checked = true;
        else advogado.checked = false;
      });
    }

    setAdvogados(advogados);
  }, [advogados, advogadoSelected]);

  const adicionar = (advogadoId: number) => {
    setFieldValue("advogadoList", [], true);
    advogados.forEach((advogado) => {
      if (advogado.advogadoId == advogadoId) {
        if (!advogado.checked) {
          advogado.checked = true;
          setAdvogados(advogados);
          setFieldValue("advogadoList", advogados, true);
          return;
        }
        if (advogado.checked) {
          advogado.checked = false;
          setAdvogados(advogados);
          setFieldValue("advogadoList", advogados, true);
          return;
        }
      }
    });
  };

  const carregarAdvogadoss = async (filtro: FiltroPesquisaAdvogadoRequest) => {
    try {
      setCarregandoAdvogados(true);

      let resultado: IRecuperaLista<IAdvogado>;
      resultado = await AdvogadoService.obterAdvogadosPorFiltro(filtro);

      setFiltrosAdvogado((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setAdvogados(resultado.data);
      setCarregandoAdvogados(false);
    } catch (error: any) {
      setAdvogados([]);
      setCarregandoAdvogados(false);
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };


  const carregarEstados = async () => {
    try {
      let resultado: IRecuperaLista<IEstado>;
      resultado = await EstadoService.obterEstados(filtrosPesquisa);

      setFiltrosAdvogado((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setEstado(resultado.data);
    } catch (error: any) {
      setEstado([]);
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const colunas: TableColumn<IAdvogado>[] = [
    {
      name: "Id",
      sortField: "advogadoId",
      selector: (row: IAdvogado) => row.advogadoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IAdvogado) => row.pessoaNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "OAB",
      sortField: "oab",
      selector: (row: IAdvogado) => row.oab,
      sortable: true,
      wrap: true,
    },
    {
      name: "Estado",
      sortField: "estadoNome",
      selector: (row: IAdvogado) => row.estadoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Adicionar?",
      cell: (row: IAdvogado) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => adicionar(row.advogadoId)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosAdvogado((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosAdvogado((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosAdvogado.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAdvogado>, sortDirection: string) => {
    setFiltrosAdvogado((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const adicionaAdvogadoRequest = Yup.object().shape({
    estadoId: Yup.number().min(1).required("Cliente é obrigatório"),
    oab: Yup.string().min(4, "Minimo 4 caracateres").max(50, "Máximo 50 caracateres").required("O Código da OAB é obrigátório"),
    codigo: Yup.string().max(50, "Maximo 50 caracteres"),
  });

  const initialValues: AdicionaAdvogadoRequest = {
    pessoaId: 0,
    oab: "",
    estadoId: "",
    codigo: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaAdvogadoRequest,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (pessoa && pessoa.pessoaId > 0) {
          await AdvogadoService.adicionaAdvogado({ ...values, pessoaId: pessoa.pessoaId });
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Advogado cadastrado com sucesso`,
            timer: 4000,
          });
        }

        setExibirCadastro(false);
        carregarAdvogadoss(filtrosAdvogado);
        setPessoa(null);
        setExibirCadastro(false);
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar advogado`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setExibirCadastro(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!carregandoAdvogados && exibirModal) { carregarAdvogadoss(filtrosAdvogado); }
  }, [filtrosAdvogado.offset, filtrosAdvogado.limit, filtrosAdvogado.sort]);

  useEffect(() => { if (exibirModal) carregarAdvogadoss(filtrosAdvogado); }, [exibirModal]);

  useEffect(() => { if (exibirCadastro && estados.length == 0) carregarEstados(); }, [exibirCadastro]);

  const cancelar = () => {
    toggleModal();
    setPessoa(null);
    setExibirCadastro(false);
  };

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Advogados das demais partes
            <a style={{ fontSize: "12px" }}>
              {<PopoverHelper></PopoverHelper>}
            </a>
          </h5>
          <div onClick={() => cancelar()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          {exibirCadastro && (
            <>
              {!pessoa && <RecuperaPessoa handlerTipo={handlerTipo} handlerNumeroDocumento={handlerNumeroDocumento} handlerPessoa={handlerPessoa} />}

              {pessoa && pessoa.pessoaId === 0 && (
                <>
                  <AdicionaPessoa tipoDocumento={tipoDocumento} numeroDocumento={numeroDocumento} hrefCancelar="555" handlerPessoa={handlerPessoa} />
                </>
              )}

              {pessoa && pessoa.pessoaId > 0 && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                >
                  <div className="row mt-3">
                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Nome:
                      </label>
                      <input disabled={true} type="text" value={pessoa.nome} className={"form-control"} />
                    </div>

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Nome Social:
                      </label>
                      <input disabled={true} type="text" value={pessoa.nomeSocial} className={"form-control"} />
                    </div>

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Documento:
                      </label>
                      <input disabled={true} type="text" value={pessoa.numeroDocumento} className={"form-control"} />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Código:
                      </label>
                      <input
                        {...formik.getFieldProps("codigo")}
                        placeholder="Código"
                        type="text"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.codigo && formik.errors.codigo,
                          },
                          {
                            "is-valid": formik.touched.codigo && !formik.errors.codigo,
                          }
                        )}
                        id="form-codigo"
                      />
                      {formik.touched.codigo && formik.errors.codigo && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.codigo}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        OAB:
                      </label>
                      <input
                        {...formik.getFieldProps("oab")}
                        placeholder="OAB"
                        type="text"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.oab && formik.errors.oab,
                          },
                          {
                            "is-valid": formik.touched.oab && !formik.errors.oab,
                          }
                        )}
                        id="form-oab"
                      />
                      {formik.touched.oab && formik.errors.oab && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.oab}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Estado:
                      </label>
                      <select
                        name="estadoId"
                        value={formik.values.estadoId}
                        onChange={(e) => {
                          formik.setFieldTouched("estadoId", true);
                          formik.setFieldValue("estadoId", parseInt(e.target.value));
                        }}
                        placeholder="Nome"
                        className={clsx(
                          "form-select",
                          {
                            "is-invalid": formik.touched.estadoId && formik.errors.estadoId,
                          },
                          {
                            "is-valid": formik.touched.estadoId && !formik.errors.estadoId,
                          }
                        )}
                        id="form-client"
                      >
                        <option value="0"> Selecione o Estado </option>
                        {estados.map((estadosMap) => {
                          return (
                            <option key={estadosMap.estadoId} value={estadosMap.estadoId}>
                              {" "}
                              {estadosMap.nome}{" "}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3 mt-5">
                    <button disabled={formik.isSubmitting || !formik.isValid} type="submit" className="btn btn-orange">
                      {!formik.isSubmitting && <> Salvar </>}
                      {formik.isSubmitting && (
                       <Spinner/>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setPessoa(null);
                        setExibirCadastro(false);
                      }}
                      disabled={formik.isSubmitting}
                      type="button"
                      className="btn btn-danger float-end"
                    >
                      Cancelar
                    </button>
                  </div>
                </form>
              )}
            </>
          )}

          <div className="row mt-5">
            <div className="col-md-auto">
              <button
                title="Clique aqui para cadastrar um novo advogado"
                onClick={() => {
                  setExibirCadastro(false);
                  setExibirCadastro(!exibirCadastro);
                }}
                className="btn btn-orange"
              >

                {exibirCadastro && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                {!exibirCadastro && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
              </button>
            </div>

            <div className="col-md-auto">
              <input
                value={filtrosAdvogado.filtro}
                onChange={(e) => {
                  setFiltrosAdvogado((oldState) => {
                    return { ...oldState, filtro: e.target.value };
                  });
                }}
                placeholder="Advogado(s)"
                type="text"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto">
              <button
                onClick={() => {
                  carregarAdvogadoss(filtrosAdvogado);
                }}
                className="btn btn-orange"
              >
                {<> Pesquisar </>}
              </button>
            </div>
            <div className="col-md-auto">{<> Buscar por: </>}</div>
            <div className="col-md-auto">
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosAdvogado({ ...filtrosAdvogado, tipoPesquisa: 1 })}
                  label="Nome"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosAdvogado({ ...filtrosAdvogado, tipoPesquisa: 3 })}
                  label="OAB"
                  name="group1"
                  type="radio"
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoAdvogados}
                limit={filtrosAdvogado.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosAdvogado.totalItems}
                colunas={colunas}
                tipo="Advogados"
                itens={advogados}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={() => toggleModal()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button onClick={() => cancelar()} type="button" className="btn btn-danger ms-5">
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdvogadoField;
