import {
  AlignmentType,
  Document,
  ISectionPropertiesOptions,
  ImageRun,
  Packer,
  Paragraph,
  TableRow,
  TextRun,
  UnderlineType,
  Table,
  TableCell,
  ISectionOptions,
  Header,
  Footer,
} from "docx";
import { saveAs } from "file-saver";
import { Element, Text } from "slate";
import {
  CustomElement,
  CustomEditor,
  FooterElement,
  HeaderElement,
  CustomText,
  ImageElement,
  TableElement,
} from "../../../utils/types/slate";
import { removeUnit } from "../components/Toolbar/controls/common";
import { ContentService, HeaderService, FooterService } from "../lib";
import { ImageService } from "../lib/slate/services/image";
import { Block, DocumentStyle } from "../types";

type GenerateHeaderAndFooterParams = {
  content: CustomElement[] | null;
  type: "header" | "footer";
};

type SaveFileParams = {
  editor: CustomEditor;
  documentStyle: DocumentStyle;
  title: string;
  footers: Block<FooterElement>;
  headers: Block<HeaderElement>;
  firstPageHeaderFooterDiff: boolean;
};

function getElementTextRun(text: CustomText) {
  const textRun = new TextRun({
    text: text.text,
    bold: text.marks?.bold,
    italics: text.marks?.italic,
    strike: text.marks?.strikethrough,
    size: `${removeUnit(text.marks?.fontSize)}pt`,
    font: text.marks?.fontFamily?.replace("Regular", ""),
    underline: {
      color: "#000000",
      type: text.marks?.underline ? UnderlineType.DASH : UnderlineType.NONE,
    },
  });
  return textRun;
}

async function getImageRun(imageElement: ImageElement) {
  const imgBuffer = await ImageService.getImageBlobAsync(imageElement.url);

  const imageRun = new ImageRun({
    data: imgBuffer ?? "",
    transformation: {
      height: removeUnit(imageElement.style?.height),
      width: removeUnit(imageElement.style?.width),
    },
  });
  return imageRun;
}

async function getTableData(element: TableElement) {
  const cells: TableCell[] = [];

  for await (const tableRow of element.children) {
    if (!Element.isElement(tableRow)) {
      return;
    }

    for await (const tableCell of tableRow.children) {
      if (!Element.isElement(tableCell)) {
        cells.push(new TableCell({ children: [] }));
        return;
      }

      const children = await getParagraphChildren(tableCell);
      const paragraph = new Paragraph({ children });
      cells.push(new TableCell({ children: [paragraph] }));
    }
  }

  const rows = new TableRow({ children: cells });
  const table = new Table({ rows: [rows] });
  return table;
}

async function getElementContent(element: CustomElement) {
  if (element.type === "table") {
    const tableData = await getTableData(element);
    return tableData ?? new TextRun({});
  }

  return new TextRun({});
}

async function getParagraphChildren(element: CustomElement) {
  const paragraphChildrenRun = [];

  if (element.type === "image") {
    const imageRun = await getImageRun(element);
    paragraphChildrenRun.push(imageRun);
  }

  for await (const children of element.children) {
    if (Element.isElement(children)) {
      const docxElement = await getElementContent(children);
      paragraphChildrenRun.push(docxElement);
    }

    if (Text.isText(children)) {
      paragraphChildrenRun.push(getElementTextRun(children));
    }
  }

  return paragraphChildrenRun;
}

async function slateChildrensToDocxChildrens(content: CustomElement[]) {
  const children: Paragraph[] = [];

  for await (const element of content) {
    const alignment: { [key: string]: any } = {
      center: AlignmentType.CENTER,
      left: AlignmentType.LEFT,
      right: AlignmentType.RIGHT,
      start: AlignmentType.START,
      justify: AlignmentType.JUSTIFIED,
    };

    const paragraph = new Paragraph({
      alignment: alignment[element.style?.textAlign ?? "start"],
      indent: {
        start: `${removeUnit(element.style?.marginLeft)}pt`,
      },
      children: await getParagraphChildren(element),
    });

    children.push(paragraph);
  }

  return children;
}

function getProperties(
  documentStyle: DocumentStyle
): ISectionPropertiesOptions {
  return {
    titlePage: true,
    page: {
      size: {
        height: `${removeUnit(documentStyle.page.height)}pt`,
        width: `${removeUnit(documentStyle.page.width)}pt`,
      },
      margin: {
        bottom: `${removeUnit(documentStyle.content.marginBottom)}pt`,
        top: `${removeUnit(documentStyle.content.marginTop)}pt`,
        left: `${removeUnit(documentStyle.content.marginLeft)}pt`,
        right: `${removeUnit(documentStyle.content.marginRight)}pt`,
        footer: `${removeUnit(documentStyle.footer.padding)}pt`,
        header: `${removeUnit(documentStyle.header.padding)}pt`,
      },
    },
  };
}

async function slateHeadersFooterToDocxHeaders(
  params: GenerateHeaderAndFooterParams
) {
  const { content, type } = params;

  const defaultResponse =
    type === "header"
      ? ({} as ISectionOptions["headers"])
      : ({} as ISectionOptions["footers"]);

  if (!content) {
    return defaultResponse;
  }

  const children = await slateChildrensToDocxChildrens(content);

  if (type === "header") {
    return {
      default: new Header({ children }),
      first: new Header({ children }),
      even: new Header({ children }),
    };
  }

  return {
    default: new Footer({ children }),
    first: new Footer({ children }),
    even: new Footer({ children }),
  };
}

async function saveAsFile(params: SaveFileParams) {
  const { documentStyle, editor, footers, headers, title } = params;

  const properties = getProperties(documentStyle);
  const content = ContentService.getAllPageContent(editor);
  const children = await slateChildrensToDocxChildrens(content);

  let headerContent = headers.default;
  let footerContent = footers.default;

  if (params.firstPageHeaderFooterDiff) {
    headerContent = headers.firstPage;
    footerContent = footers.firstPage;
  }

  const headerChildrenContent = HeaderService.getHeaderChildrens(headerContent);
  const docxHeaders = await slateHeadersFooterToDocxHeaders({
    content: headerChildrenContent,
    type: "header",
  });

  const footerChildrenContent = FooterService.getFooterChildrens(footerContent);
  const docxFooters = await slateHeadersFooterToDocxHeaders({
    content: footerChildrenContent,
    type: "footer",
  });

  const document = new Document({
    sections: [
      {
        children,
        properties,
        headers: docxHeaders,
        footers: docxFooters,
      },
    ],
    title,
  });

  const documentBlob = await Packer.toBlob(document);
  saveAs(documentBlob, `${title}.docx`);
}

export const docxHelper = {
  saveAsFile,
};
