import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ILog  from "../interfaces/ILog";


import FiltroPesquisaLogRequest from "../interfaces/Requests/Log/FiltroPesquisaLogRequest";


class LogService {

    async gerarExcel(relatorioId: number): Promise<Blob> {
        const { data: result } = await api.get(`Log/GerarRelatorio?relatorioId=${relatorioId}`, { responseType: 'blob' });
        return result;
    }  

    async obterLogs(filtros: FiltroPesquisaLogRequest): Promise<IRecuperaLista<ILog>> {
        let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

        if (filtros.logId && filtros.logId.length> 0) {
            query += filtros.logId.map(id => `&logId=${id}`).join('')
        }

        if (filtros.usuarioNomeCadastro && filtros.usuarioNomeCadastro && filtros.usuarioNomeCadastro.length){
            query += `&usuarioNomeCadastro=${filtros.usuarioNomeCadastro}`;
        }

        if (filtros.ip && filtros.ip && filtros.ip.length){
            query += `&ip=${filtros.ip}`;
        }

        if (filtros.funcao && filtros.funcao !== 0 ) {
            query += `&funcao=${filtros.funcao}`;
        }

        if (filtros.dataMaiorIgual && filtros.dataMaiorIgual && filtros.dataMaiorIgual.length) {
            query += `&dataMaiorIgual=${filtros.dataMaiorIgual}`;
        }

        if (filtros.dataMenorIgual && filtros.dataMenorIgual && filtros.dataMenorIgual.length) {
            query += `&dataMenorIgual=${filtros.dataMenorIgual}`;
        }

        if (filtros.campo && filtros.campo && filtros.campo.length){
            query += `&campo=${filtros.campo}`;
        }

        if (filtros.valorAntigo && filtros.valorAntigo && filtros.valorAntigo.length){
            query += `&valorAntigo=${filtros.valorAntigo}`;
        }

        if (filtros.valorNovo && filtros.valorNovo && filtros.valorNovo.length){
            query += `&valorNovo=${filtros.valorNovo}`;
        }

        if (filtros.processoId && filtros.processoId !== 0 ) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.contratoId && filtros.contratoId !== 0 ) {
            query += `&contratoId=${filtros.contratoId}`;
        }

        if (filtros.marcaId && filtros.marcaId !== 0 ) {
            query += `&marcaId=${filtros.marcaId}`;
        }

        if (filtros.imovelId && filtros.imovelId !== 0 ) {
            query += `&imovelId=${filtros.imovelId}`;
        }

        if (filtros.empresaId && filtros.empresaId !== 0 ) {
            query += `&empresaId=${filtros.empresaId}`;
        }   

        if (filtros.licencaId && filtros.licencaId !== 0 ) {
            query += `&licencaId=${filtros.licencaId}`;
        }  

        if (filtros.consultaId && filtros.consultaId !== 0 ) {
            query += `&consultaId=${filtros.consultaId}`;
        }  

        if (filtros.procuracaoId && filtros.procuracaoId !== 0 ) {
            query += `&procuracaoId=${filtros.procuracaoId}`;
        }  

        let { data: result } = await api.get<IRecuperaLista<ILog>>(`Log/RecuperaLog?${query}`);

        return result;
    }

}


export default new LogService();