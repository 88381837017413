import IContrato from "../../../interfaces/IContrato";
import IProcesso from "../../../interfaces/IProcesso";
import sessionStorageService from "../../../services/sessionStorage/sessionStorageService";

export interface GetCallerDataParams {
  urlParams: any;
  procuracaoId?: number;
  contratoId?: number;
  processoId?: number;
}

export enum Caller {
  PROCURACAO = "Procuracao",
  CONTRATO = "Contrato",
  PROCESSO = "Processo",
  EMPTY = ""
}

export const getParamsFromCaller = (caller: string, callerId: number) => {
  const keys: {[key: string]: string} = {
    [Caller.CONTRATO]: 'contratoId',
    [Caller.PROCURACAO]: 'procuracaoId',
    [Caller.PROCESSO]: 'processoId'
  }
  return {
    [keys[caller]]: callerId
  }
}

export const getCallerData = async ({
  urlParams,
  procuracaoId,
  contratoId,
  processoId
}: GetCallerDataParams) => {
  const caller = urlParams.caller
  
  const keys: { [key: string]: string } = {
    [Caller.CONTRATO]: "contrato_capa",
    [Caller.PROCURACAO]: "procuracao_capa",
    [Caller.PROCESSO]: "processo_capa"
  };
  
  const data = await sessionStorageService.obter(keys[caller]);
  
  if (caller === Caller.CONTRATO) {
    const contractData = JSON.parse(data) as IContrato;
    return { caller: Caller.CONTRATO, callerId: contratoId || contractData.contratoId };
  }
  
  if (caller === Caller.PROCURACAO) {
    const procuration = JSON.parse(data) as IProcuracao;
    return { caller: Caller.PROCURACAO, callerId: procuracaoId || procuration.procuracaoId };
  }

  if (caller === Caller.PROCESSO) {
    const process = JSON.parse(data) as IProcesso;
    return { caller: Caller.PROCESSO, callerId: processoId || process.processoId };
  }
  
  return { caller: Caller.EMPTY, callerId: 0 };
};
