import "./fonts.css";
import { FontContextProvider } from "./context";
import { ToastContainer } from "react-toastify";
import { ThreadProvider } from "./hooks/useThread";
import { EditorProvider } from "./hooks/useProvider";
import React from "react";
import { DrawerProvider } from "./components/Drawer/context";

type WrapperProps = {
  children: React.ReactNode;
};

const Wrapper = ({ children }: WrapperProps) => {
  return (
    <FontContextProvider>
      <EditorProvider>
        <DrawerProvider>
          <ThreadProvider>{children}</ThreadProvider>
        </DrawerProvider>
      </EditorProvider>
    </FontContextProvider>
  );
};

export const Editor = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Wrapper>
      <ToastContainer />
      {children}
    </Wrapper>
  );
};
