
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import clsx from "clsx";
import Swal from "sweetalert2";
import { useFormik } from 'formik';
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from '../../enum';


import IXCampo from '../../interfaces/IXCampo';
import IXOpcao from "../../interfaces/IXOpcao";
import IRecuperaLista from "../../interfaces/IRecuperaLista";

import XCampoService from '../../services/XCampoService';
import { getOptionTipoCampoText } from "../../utils/Utils";
import { notifySuccess } from "../../components/Comum/Toast/ToastFormik";
import TabelaXCamposField from "../../components/Comum/TabelaField/TabelaXCamposField";

interface IXCampoModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    xCampo?: IXCampo,
    titulo: string
}

const XCampoModal = ({ toggleModal, exibirModal, xCampo, titulo }: IXCampoModalProps) => {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [opcoes, setOpcoes] = useState<IXOpcao[]>([]);

    const valoresIniciais: IXCampo = {
        label: "",
        codigo: "",
        modulo: EModulo.Default,
        isXCampo: true,
        tipo: "",
        tipoDescricao: '',
        exibirTelaPesquisa: false,
        salvarXCampoFechamento: false,
        xCampoTabelaPropriedades: []
    };

    useEffect(() => {
        if (exibirModal) {
            if (xCampo?.campo && xCampo?.campo > 0) {
                formik.setValues({ ...xCampo, tipo: xCampo.tipoId || 0 });
                carregarXCamposOpcoes(xCampo.campo);
            } else {
                formik.setValues(valoresIniciais);
                setOpcoes([]);
            }
        }
    }, [exibirModal]);


    const schema = Yup.object().shape({
        label: Yup.string().min(3, "Mínimo é obrigatório").max(100, 'Maximo 100 caracteres').required('Label é obrigatório'),
        modulo: Yup.number().min(1, "Selecione um módulo").required("Módulo é obrigatório"),
        tipo: Yup.number().min(1, "Selecione um tipo").required("Tipo é obrigatório"),
    });


    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {

            try {
                values.tipoDescricao = getOptionTipoCampoText(values.tipo);

                if (values.camposId) {
                    await XCampoService.atualizaXCampo(values);

                    opcoes.forEach(async opcao => await editarOpcao(opcao));

                } else {
                    let resultado = await XCampoService.adicionaXCampo(values);

                    if (values.tipo == 1 || values.tipo == 10) {
                        await XCampoService.adicionaOpcoesXCampoPorLista(opcoes, resultado.camposId || 0);
                    }
                }

                await Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `XCampo cadastrado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                });

            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel editar o XCampo`,
                    text: error?.response?.data?.Message,
                    showConfirmButton: true
                });
            } finally {
                setSubmitting(false);
                toggleModal(true);
                setOpcoes([]);
            }
        }
    });

    async function carregarXCamposOpcoes(campoId: number) {
        try {
            let resultado: IRecuperaLista<IXOpcao>;
            resultado = await XCampoService.obterCampo({
                campoId: campoId,
                status: 1,
                offset: 0,
                limit: 500,
                sort: "CampoId",
                totalPaginas: 0,
            });

            setOpcoes(resultado.data);
        } catch (error: any) {
            setOpcoes([]);

        }

    }

    const adicionarOpcao = () => setOpcoes([...opcoes, { campoId: 0, nome: '', codigo: '' }]);


    const editarOpcao = async (opcao: IXOpcao) => {
        try {
            setCarregando(true);

            if (opcao.campoId) await XCampoService.atualizaXOpcaoCampo(opcao);
            else await XCampoService.adicionaOpcoesXCampo({ ...opcao, campoId: formik.values.campo || 0 });

            notifySuccess('Opção salva com sucesso')

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel editar o XCampo Opção`,
                text: error?.response?.data?.Message,
                showConfirmButton: true
            });
        } finally {
            setCarregando(false)
        }
    }

    return <>
        <ToastContainer />
        <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo} #{formik.values.campo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="col-md-4 mb-2">
                            <label htmlFor="form-label" className="form-label required fw-bolder text-orange">Label:</label>
                            <input {...formik.getFieldProps('label')} placeholder="Label" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.label && formik.errors.label,
                                },
                                {
                                    'is-valid': formik.touched.label && !formik.errors.label,
                                }
                            )} id="form-codigo" />
                            {formik.touched.label && formik.errors.label && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.label}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-md-4 mb-2">
                            <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                                Módulo:
                            </label>
                            <select
                                value={formik.values.modulo.toString()}
                                onChange={(e) => {
                                    formik.setFieldTouched("modulo", true);
                                    formik.setFieldValue("modulo", e.target.value.toString(), true);
                                    formik.validateField("modulo");
                                }}
                                className={"form-select"}
                                id="form-select"
                            >
                                <option value="0" label="Selecione o Módulo">Selecione o Módulo</option>
                                <option value="1" label="Contencioso">Contencioso</option>
                                <option value="7" label="Contrato">Contrato</option>
                                <option value="1" label="Ato Societário">Ato Societário</option>
                                <option value="15" label="Bloqueio">Bloqueio</option>
                                <option value="9" label="Consultivo">Consultivo</option>
                                <option value="17" label="Decisão">Decisão</option>
                                <option value="12" label="Despesa">Despesa</option>
                                <option value="22" label="Garantia">Garantia</option>
                                <option value="10" label="Imobiliario">Imobiliario</option>
                                <option value="11" label="MarcasEPatentes">MarcasEPatentes</option>
                                <option value="23" label="Obrigação">Obrigação</option>
                                <option value="40" label="Parte Contraria">Parte Contraria</option>
                                <option value="25" label="Proposta Acordo">Proposta Acordo</option>
                                <option value="21" label="Procuração">Procuração</option>
                                <option value="46" label="Protocolo">Protocolo</option>
                                <option value="30" label="Regulatorio">Regulatorio</option>
                                <option value="5" label="Societário">Societário</option>
                                <option value="34" label="XPay - Honorário">XPay - Honorário</option>
                                <option value="35" label="XPay - Reembolso">XPay - Reembolso</option>
                                <option value="2" label="XSigner">XSigner</option>

                            </select>
                        </div>
                        <div className="row mt-1">
                            <div className="col-md-4 mb-1">
                                <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                                    Tipo:
                                </label>
                                <select
                                    disabled={!!formik?.values?.camposId}
                                    value={formik.values.tipo?.toString()}
                                    onChange={(event: any) => {
                                        const selectedValue = parseInt(event.target.value);
                                        formik.setFieldValue("tipo", selectedValue, true);
                                    }}
                                    className={"form-select"}
                                    id="form-select"
                                >
                                    <option value="0" label="Selecione o Tipo" />
                                    <option value="1" label="Lista" />
                                    <option value="16" label="ListaLink" />
                                    <option value="10" label="ListaMulti" />
                                    <option value="2" label="Data" />
                                    <option value="3" label="Texto" />
                                    <option value="4" label="Valor" />
                                    <option value="12" label="Percentual" />
                                    <option value="5" label="AreaTexto" />
                                    <option value="6" label="Boolean" />
                                    <option value="9" label="Inteiro" />
                                    <option value="15" label="Tabela" />
                                </select>
                            </div>
                            <div className="col-md-4 mb-1">
                                <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                                    Deseja exibir na tela de pesquisa?
                                    <FormCheckInput
                                        type="checkbox"
                                        style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                                        checked={formik.values.exibirTelaPesquisa}
                                        onChange={() => {
                                            if (formik.values.exibirTelaPesquisa) formik.setValues({ ...formik.values, exibirTelaPesquisa: false });
                                            if (!formik.values.exibirTelaPesquisa) formik.setValues({ ...formik.values, exibirTelaPesquisa: true });
                                        }}
                                    />
                                </label>
                            </div>
                            {((formik.values.modulo.toString() === "1") &&
                                <>
                                    <div className="col-md-4 mb-1">
                                        <label htmlFor="form-salvarXCampoFechamento" className="form-label fw-bolder text-orange">
                                            Salvar este campo no fechamento?
                                            <FormCheckInput
                                                type="checkbox"
                                                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                                                checked={formik.values.salvarXCampoFechamento}
                                                onChange={() => {
                                                    if (formik.values.salvarXCampoFechamento) formik.setValues({ ...formik.values, salvarXCampoFechamento: false });
                                                    if (!formik.values.salvarXCampoFechamento) formik.setValues({ ...formik.values, salvarXCampoFechamento: true });
                                                }}
                                            />
                                        </label>
                                    </div>
                                </>
                            )}
                        </div>

                        {formik.values.tipo === 15 &&
                            <div className="col-md-12 mb-1">
                                <TabelaXCamposField setFieldValue={formik.setFieldValue} />
                            </div>}

                        {(formik.values.tipo === 1 || formik.values.tipo === 10) &&
                            <>
                                <div className="col-md-9 mb-5">
                                    <div className="input-group">
                                        <button onClick={adicionarOpcao} type="button" className="btn btn-outline-secondary btn-orange"> Adicionar </button>
                                    </div>
                                    <table className="table text-orange m-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Nome</th>
                                                {formik.values.campo && <th scope="col">#Salvar</th>}
                                                {!formik.values.campo && <th scope="col">#Remover</th>}
                                                <th scope="col"></th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {opcoes.map((opcao: IXOpcao, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{opcao.xCampoOpcaoId}</td>
                                                        <td>
                                                            <input
                                                                value={opcao.nome}
                                                                onChange={(e) => {
                                                                    const novasOpcoes = [...opcoes]; // Crie uma cópia das opções
                                                                    novasOpcoes[index].nome = e.target.value; // Atualize a cópia
                                                                    setOpcoes(novasOpcoes); // Defina o estado com a cópia atualizada
                                                                }}
                                                                type="text"
                                                                className={'form-control'}
                                                                id="form-nome"
                                                            />
                                                        </td>
                                                        {formik.values.campo && (
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    title="Editar"
                                                                    onClick={() => editarOpcao(opcao)}
                                                                    style={{ fontWeight: 'normal', cursor: 'pointer' }}
                                                                    size='2x'
                                                                    className='mx-2 text-orange'
                                                                    icon={faSave}
                                                                />
                                                            </td>
                                                        )}
                                                        {!formik.values.campo && (
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    title="Excluir"
                                                                    onClick={() => {
                                                                        const novasOpcoes = [...opcoes];
                                                                        novasOpcoes.splice(index, 1);
                                                                        setOpcoes(novasOpcoes);
                                                                    }}
                                                                    style={{ fontWeight: 'normal', cursor: 'pointer' }}
                                                                    size='2x'
                                                                    className='mx-2 text-orange'
                                                                    icon={faTrash}
                                                                />
                                                            </td>
                                                        )}
                                                        <td>
                                                            {carregando ?
                                                                <span className="indicator-progress" style={{ display: "block", fontSize: '20px' }}>
                                                                    <span style={{ display: "block", fontSize: '20px' }} className="spinner-border text-orange spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                                : '#'}
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </>}

                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formik.isSubmitting && <> SALVAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default XCampoModal;