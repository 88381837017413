/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";


import MarcaService from "../../services/MarcaService";
import TipoMarcaService from "../../services/TipoMarcaService";
import StatusMarcaService from "../../services/StatusMarcaService";

import ITipoMarca from "../../interfaces/ITipoMarca";
import IStatusMarca from "../../interfaces/IStatusMarca";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaMarcaRequest from "../../interfaces/Requests/TipoMarca/FiltroPesquisaMarcaRequest";


import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";
import MarcaFiltroModal from "./MarcaFiltroModal";
import GridPadrao from "../../components/Comum/GridPadrao";
import "./style/icons.css";
import { Collapse } from "react-bootstrap";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const filtroInicial: FiltroPesquisaMarcaRequest = {
  marcaId: 0,
  numeroPedido: "",
  totalItems: 0,
  tipoMarcaId: 0,
  offset: 0,
  limit: 10,
  sort: "marcaId",

  tituloRegistro: "",
  parteContraria: "",
  nomeMarca: '',
  numeroRegistro: '',

  empresaId: [],
  departamentoId: [],
  apresentacaoMarcas: [],
  escritorioId: [],
  naturezaMarcaId: [],
  classeMarcaId: [],
  classificacaoIPCId: [],
  paisId: [],
  cidadeId: [],
  statusMarcaId: [],


  dataCadastroMaiorIgual: "",
  dataCadastroMenorIgual: "",

  dataInicioVigenciaMaiorIgual: "",
  dataInicioVigenciaMenorIgual: "",

  dataFimVigenciaMaiorIgual: "",
  dataFimVigenciaMenorIgual: "",

  dataDepositoMaiorIgual: "",
  dataDepositoMenorIgual: "",

  dataConcessaoMaiorIgual: "",
  dataConcessaoMenorIgual: "",

  dataPublicacaoMaiorIgual: "",
  dataPublicacaoMenorIgual: "",

};


const MarcaPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaMarcaRequest>(filtroInicial);

  const [carregamentoTipoMarca, setCarregamentoTipoMarca] = useState<boolean>(false);
  const [tiposMarcaOptions, setTiposMarcaOptions] = useState<any>([]);
  const [tipoMarcaSelected, setTipoMarcaSelected] = useState([]);

  const [carregamentoStatusMarca, setCarregamentoStatusMarca] = useState<boolean>(false);
  const [StatusMarcaOptions, setStatusMarcaOptions] = useState<any>([]);
  const [StatusMarcaSelected, setStatusMarcaSelected] = useState([]);

  const { setMarca, setTipoMarca } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [marcas, setMarcas] = useState<IMarca[]>([]);
  const [carregandoMarcas, setCarregandoMarcas] = useState(false);

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useLayoutEffect(() => {
    carregarTipoMarca();
    carregarStatusMarca();
  }, []);

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  useEffect(() => {
    if (pesquisar) carregarMarcas(filtrosPesquisa);
  }, [pesquisar]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoMarcaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoMarcaId: IdsSelected };
    });
  }, [tipoMarcaSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusMarcaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusMarcaId: IdsSelected };
    });
  }, [StatusMarcaSelected]);

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  const returnOptionsStatusMarca = (array: any) => {
    array.forEach((item: any) => {
      StatusMarcaOptions.push({
        value: item.statusMarcaId,
        label: item.nome,
      });
    });
    return setStatusMarcaOptions;
  };

  const returnOptionsTiposMarca = (array: any) => {
    array.forEach((item: any) => {
      tiposMarcaOptions.push({
        value: item.tipoMarcaId,
        label: item.nome,
      });
    });
    return setTiposMarcaOptions;
  };

  const carregarTipoMarca = async (): Promise<void> => {
    try {
      setCarregamentoTipoMarca(true);
      let resultadoTipoMarca: IRecuperaLista<ITipoMarca>;
      resultadoTipoMarca = await TipoMarcaService.obterTipoMarcas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoMarcaId",
      });
      setTiposMarcaOptions(returnOptionsTiposMarca(resultadoTipoMarca.data));
      setCarregamentoTipoMarca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoMarca(false);
      setTiposMarcaOptions([]);
    }
  }

  const carregarStatusMarca = async (): Promise<void> => {
    try {
      setCarregamentoStatusMarca(true);
      let resultado: IRecuperaLista<IStatusMarca>;
      resultado = await StatusMarcaService.obterStatusMarcas({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusMarcaId",
      });
      setStatusMarcaOptions(returnOptionsStatusMarca(resultado.data));
      setCarregamentoStatusMarca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoStatusMarca(false);
      setStatusMarcaOptions([]);
    }
  }

  const colunas: TableColumn<IMarca>[] = [
    {
      cell: (marca: IMarca) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setMarca(marca);
                  setTipoMarca({
                    tipoMarcaId: marca.tipoMarcaId,
                    nome: marca.tipoMarcaNome,
                    codigo: "",
                    status: marca.statusMarcaNome
                  });
                  navigate("/Marca/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Marca: #{marca.marcaId.toString().padStart(5, "0")}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{marca.statusMarcaNome}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {marca.marcaId}
                {marca.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(marca.favoritoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(marca.marcaId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Contrato: </b> {marca.tipoMarcaNome}{" "}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Nome da Marca: </b> {marca.nomeMarca}{" "}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Nº do Registro: </b> {marca.numeroRegistro}{" "}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Empresa: </b> {marca.marcaEmpresas.map(empresa => `${empresa.nome} / `)}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Partes: </b> {marca.marcaParteContraria.map(parte => `${parte.nome} / `)}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const adicionarFavorito = async (marcaId: number): Promise<void> => {
    try {
      await MarcaService.adicionaFavorido(marcaId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Contrato adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarMarcas(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar contrato como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const removerFavorido = async (favoritoId: number): Promise<void> => {
    try {
      await MarcaService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Contrato removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarMarcas(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IMarca>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const carregarMarcas = async (filtro: FiltroPesquisaMarcaRequest) => {
    try {
      setCarregandoMarcas(true);

      let resultado: IRecuperaLista<IMarca>;
      resultado = await MarcaService.obterMarcas(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setMarcas(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoMarcas(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  return (
    <>
      <LayoutPageTitle title="Marcas e Patentes">
        <button onClick={() => navigate("/Marca/Novo")} className="btn btn-md btn-orange">
          Nova <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <MarcaFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <div className="row">
        <div className="col-md-12" >

          <CollapseFiltro titulo="Filtros" content={
            <form onSubmit={(e) => e.preventDefault()}
              className="row g-3 mb-3 form">
              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-tipoMarcaId" className="form-label fw-bolder text-orange">
                  Identificador:
                </label>
                <input
                  value={filtrosPesquisa.marcaId}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, marcaId: e.target.value };
                    });
                  }}
                  placeholder="ID"
                  type="number"
                  className={"form-control"}
                  id="form-tipoMarcaId"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-numero" className="form-label fw-bolder text-orange">
                  Título do Registro:
                </label>
                <input
                  value={filtrosPesquisa.tituloRegistro}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, tituloRegistro: e.target.value };
                    });
                  }}
                  placeholder="Número"
                  type="text"
                  className={"form-control"}
                  id="form-numero"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-numero" className="form-label fw-bolder text-orange">
                  Número do Pedido:
                </label>
                <input
                  value={filtrosPesquisa.numeroPedido}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, numeroPedido: e.target.value };
                    });
                  }}
                  placeholder="Número"
                  type="text"
                  className={"form-control"}
                  id="form-numero"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Tipo de Marca:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoMarca}
                  disabled={carregamentoTipoMarca}
                  options={tiposMarcaOptions}
                  value={tipoMarcaSelected}
                  onChange={(event: any) => setTipoMarcaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                  Parte:
                </label>
                <input
                  value={filtrosPesquisa.parteContraria}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, parteContraria: e.target.value };
                    });
                  }}
                  placeholder="Parte"
                  type="text"
                  className={"form-control"}
                  id="form-parteContraria"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={carregamentoStatusMarca}
                  disabled={carregamentoStatusMarca}
                  options={StatusMarcaOptions}
                  value={StatusMarcaSelected}
                  onChange={(event: any) => setStatusMarcaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>


              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                  Nome da Marca:
                </label>
                <input
                  value={filtrosPesquisa.nomeMarca}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nomeMarca: e.target.value };
                    });
                  }}
                  placeholder="Nome da Marca"
                  type="text"
                  className={"form-control"}
                  id="form-nomeMarca"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                  N° do Registro:
                </label>
                <input
                  value={filtrosPesquisa.numeroRegistro}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, numeroRegistro: e.target.value };
                    });
                  }}
                  placeholder="Número da Marca"
                  type="text"
                  className={"form-control"}
                  id="form-nomeMarca"
                />
              </div>

              <div className="col-12"
                style={{ textAlign: 'right' }}>
                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    setFiltrosPesquisa(filtroInicial);
                    setPesquisar(false);
                    setTipoMarcaSelected([]);
                    setStatusMarcaSelected([]);
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

              </div>

            </form>} />

          <div className="col-md-12 mb-10" style={{ overflow: !carregandoMarcas ? "auto" : "scroll", height: "600px" }}>

            <div ref={componentRef}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoMarcas}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Marca"
                itens={marcas}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarcaPage;

