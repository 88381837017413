import { Descendant } from "slate";

function childrenReplacer<Element>(
  node: Descendant,
  page: number,
) {
  const str = JSON.stringify(node);
  const pageNumber = (page + 1).toString();

  const data = str.replace("@PAGE_NUMBER", pageNumber)
  return JSON.parse(data) as Element;
}

export const replacer = {
  childrenReplacer,
};
