import moment from "moment";
import { useEffect, useState } from "react";
import { BsCloudArrowDown, BsCloudArrowUp, BsEye, BsTrash } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import IFileUploadFieldEdit from "../../../../components/FileUpload/FileUploadFieldEdit";
import IDocumento from "../../../../interfaces/IDocumento";
import IEmpresa from "../../../../interfaces/IEmpresa";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";

import { faBackward, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import GridPadrao from "../../../../components/Comum/GridPadrao";
import PreviewPDFField from "../../../../components/Comum/PreviewPDF/PreviewPDF";
import IFileUploadField from "../../../../components/FileUpload/FileUploadField";
import FiltroPesquisaDocumentoRequest from "../../../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import DocumentoService from "../../../../services/DocumentoService";
import { EModulo } from "../../../../enum";

interface IDocumentosProps {
  empresa: IEmpresa
}

const Documentos = ({ empresa }: IDocumentosProps) => {
  const [documentos, setDocumentos] = useState<any>([]);
  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);

  const [documento, setDocumento] = useState<IDocumento>({
    index: 0,
    documentoId: 0,
    clienteId: 0,
    nome: "",
    descricao: "",
    tamanho: 0,
    tipoDocumentoId: 0,
    tipoDocumentoNome: "",
    restrito: false,
    status: "",
    codigo: "",
    dataCadastro: new Date(),
    usuarioIdCadastro: 0,
    usuarioNomeCadastro: "",
    modulo: "",
    extensao: "",
    etiquetas: [],
    pastaGedNome: ''
  });

  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    empresaId: empresa.empresaId,
    modulo: [],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [exibirListaArquivosImportados, setExibirListaArquivosImportados] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);

  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [exibirComponenteParaImportarArquivo, setExibirComponenteParaImportarArquivo] = useState<boolean>(false);

  const [exibirModalDocumentoEdit, setExibirModalDocumentoEdit] = useState<boolean>(false);
  const [atualizaTabelaDocumento, setAtualizaTabelaDocumento] = useState<boolean>(false);


  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  const toggleDocumentoEdit = () => setExibirModalDocumentoEdit(!exibirModalDocumentoEdit);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const verificaIsPdf = (extensao: string) => {
    if (extensao.endsWith(".pdf")) return true;
    else return false;
  };

  const onChangeTipoDocumento = () => {
    // if (configuracoesTela.filter((e: { campo: number }) => e.campo == 58).length > 0) {
    //   const tipoDocumento = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo == 58)[0];

    //   const configuracoesTelaFilter = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo !== 58);

    //   configuracoesTelaFilter.push({ ...tipoDocumento, alterarCampo: true });

    //   setConfiguracoesTela(configuracoesTelaFilter);
    // }
    if (exibirComponenteParaImportarArquivo) carregarDocumentos(filtrosPesquisaArquivos);
  };


  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {
      if (!empresa.empresaId) return;
      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos({ ...filtro, empresaId: empresa.empresaId, status: 1, modulo: [5] });

      if (resultado.data != null) setDocumentos(resultado.data);
      else setDocumentos([]);

      setFiltrosPesquisaArquivos((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoDocumentos(false);
    } catch (error: any) {
      setDocumentos([]);
      setCarregandoDocumentos(false);
    }
  };


  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const deletaDocumento = async (documentoId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Deletar documento",
        text: `Você realmente deseja deletar este documento?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await DocumentoService.inativaDocumento(documentoId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Documento deletado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        carregarDocumentos(filtrosPesquisaArquivos);
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel deletar o documento`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };

  const handlePerRowsChangeArquivo = async (currentRowsPerPage: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChangeArquivo = (page: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisaArquivos.limit };
    });
  };

  const handleSortArquivo = async (column: TableColumn<any>, sortDirection: string) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const colunasArquivos: TableColumn<any>[] = [
    {
      name: "Documento ID",
      sortField: "documentoId",
      selector: (row: any) => row.documentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumentoNome",
      selector: (row: any) => row.tipoDocumentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Pasta",
      sortField: "pastaGedNome",
      selector: (row: any) => row.pastaGedNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: any) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Restrito?",
      sortField: "restrito",
      selector: (row: any) => (row.restrito ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Usuário",
      sortField: "usuarioNomeCadastro",
      selector: (row: any) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "  ",
      selector: (row: any) => moment(row.dataCadastro).format("DD/MM/yyyy"),
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: any) => {
        return (
          <div>
            <FaEdit
              title="Editar Arquivo"
              onClick={() => {
                setDocumento(row);
                onChangeTipoDocumento();
                setExibirModalDocumentoEdit(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />

            <BsTrash
              title="Deletar Arquivo"
              onClick={() => deletaDocumento(row.documentoId)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />

            <BsCloudArrowDown
              title="Baixar Arquivo"
              onClick={() => baixarArquivo(row.documentoId, row.nome)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />
            <>
              {verificaIsPdf(row.extensao) && (
                <BsEye
                  title="Preview"
                  onClick={() => previewArquivo(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
              )}
            </>

          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    carregarDocumentos(filtrosPesquisaArquivos);
  }, [filtrosPesquisaArquivos.offset, filtrosPesquisaArquivos.limit, filtrosPesquisaArquivos.sort]);

  useEffect(() => {
    if (atualizaTabelaDocumento) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
    setAtualizaTabelaDocumento(false);
  }, [atualizaTabelaDocumento]);

  useEffect(() => {
    carregarDocumentos(filtrosPesquisaArquivos);
  }, []);

  return exibirComponenteParaImportarArquivo ? (
    <>
      <div className="row mt-3">
        <div className="col-md-4 mt-3">
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            Importação de arquivos:{" "}
            <FontAwesomeIcon
              onClick={() => {
                onChangeTipoDocumento();
                setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
              }}
              color={"var(--primary-base2)"}
              className="mx-3"
              style={{ cursor: "pointer" }}
              icon={faBackward}
            />
          </label>
          <br />
          <>
            <Button
              onClick={() => setExibirModalImportacaoArquivos(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
              Selecione os arquivos da empresa
            </Button>
            <IFileUploadField
              modulo={EModulo.Societario}
              setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
              exibirModal={exibirModalImportacaoArquivos}
              toggleModal={toggleImportacaoArquivos}
              empresaId={empresa.empresaId}
              capa={true}
            />
          </>
        </div>
      </div>
      <div className="row mt-2">
        <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
          {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
          {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
          {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
        </a>
      </div>
    </>
  ) : (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <label
            onClick={() => setExibirListaArquivosImportados(!exibirListaArquivosImportados)}
            htmlFor="form-Documentos"
            style={{ cursor: "pointer" }}
            className="form-label fw-bolder text-orange"
          >
            Documentos {exibirListaArquivosImportados ? "-" : "+"}
            <BsCloudArrowUp
              onClick={() => {
                onChangeTipoDocumento();
                setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
              }}
              color={"var(--primary-base2)"}
              className="mx-3"
              style={{ cursor: "pointer" }}
              size={"30px"}
              title={"Realizar upload de documento"}
            />
          </label>
          {exibirListaArquivosImportados && (
            <GridPadrao
              progressPending={carregandoDocumentos}
              onSort={handleSortArquivo}
              limit={filtrosPesquisaArquivos.limit}
              onChangePage={handlePageChangeArquivo}
              onChangeRowsPerPage={handlePerRowsChangeArquivo}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisaArquivos.totalItems}
              colunas={colunasArquivos}
              tipo="Documentos"
              itens={documentos}
            />
          )}
        </div>
        <hr />
      </div>

      <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

      <IFileUploadFieldEdit
        campo={58}
        exibirModalDocumentoEdit={exibirModalDocumentoEdit}
        toggleModal={toggleDocumentoEdit}
        editarDocumento={documento}
        modulo={parseInt(documento.modulo)}
        setAtualizarTabela={setAtualizaTabelaDocumento}
        empresaId={empresa.empresaId}


      />
    </>
  )

}

export default Documentos;