/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import IPropriedade from "../../../../interfaces/IPropriedade";
import DocumentoService from "../../../../services/DocumentoService";
import Swal from "sweetalert2";
import { BsEye } from "react-icons/bs";
import PreviewPDFField from "../../../../components/Comum/PreviewPDF/PreviewPDF";

interface ItemProps {
  item: IPropriedade;
  index: number;
  col: string;
}

const buscarEstilo = (isDragging: boolean, draggableStyle: any) => ({
  padding: 10,
  margin: `0 50px 15px 40px`,
  background: isDragging ? "var(--primary-base)" : "white",
  color: isDragging ? "white" : "black",
  border: `1px solid`,
  fontSize: `12px`,
  borderRadius: `5px`,
  cursor: "pointer",
  ...draggableStyle,
});


const FormPropriedadesEstaticos: React.FC<ItemProps> = ({ item, index, col }) => {
  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);

      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  return (
    <>
      <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

      <Draggable key={item.propriedadeId} draggableId={item.propriedadeId.toString()} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <OverlayTrigger placement="top" overlay={<Tooltip>{item.descricao}</Tooltip>}>
                <div
                  key={index.toString()}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={buscarEstilo(true, provided.draggableProps.style)}
                >
                  {item.nome} 
                  {item.documentoId &&
                    <BsEye
                      title="Preview"
                      onClick={() => previewArquivo(item.documentoId)}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="20px"
                      className="mx-2"
                    />}
                </div>
              </OverlayTrigger>
            </>
          ) : (
            <OverlayTrigger placement="top" overlay={<Tooltip>{item.descricao}</Tooltip>}>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {item.nome}
                {item.documentoId &&
                  <BsEye
                    title="Preview"
                    onClick={() => previewArquivo(item.documentoId)}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="20px"
                    className="mx-2"
                  />}
              </div>
            </OverlayTrigger>
          )
        }
      </Draggable>

    </>
  );
};

export default FormPropriedadesEstaticos;
