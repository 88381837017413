import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IDespesa from "../../interfaces/IDespesa";
import moment from "moment";

interface IDespesaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  despesa?: IDespesa;
  titulo: string;
}

const DespesaDetalheModal = ({ toggleModal, exibirModal, despesa, titulo }: IDespesaModalProps) => {

  function formatarMoeda(valor: number): string {
    valor = valor / 100;
    const retorno = valor || 0;

    return retorno.toLocaleString("pt-BR",
      { style: "currency", currency: "BRL" })
  }

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="form-bancoNome" className="form-label fw-bolder text-orange">
                  Banco
                </label>
                <br />
                {despesa?.bancoNome}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-codigoBarras" className="form-label fw-bolder text-orange">
                  Código de Barras
                </label>
                <br />
                {despesa?.codigoBarras}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="form-conta" className="form-label fw-bolder text-orange">
                  Conta
                </label>
                <br />
                {despesa?.conta}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-descricao" className="form-label fw-bolder text-orange">
                  Descrição
                </label>
                <br />
                {despesa?.descricao}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="form-formaPagamentoNome" className="form-label fw-bolder text-orange">
                  Froma de Pagamento
                </label>
                <br />
                {despesa?.formaPagamentoNome}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-tipoDespesaNome" className="form-label fw-bolder text-orange">
                  Tipo de Despesa
                </label>
                <br />
                {despesa?.tipoDespesaNome}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="form-dataCadastro" className="form-label fw-bolder text-orange">
                  Data de Cadastro
                </label>
                <br />
                {moment(despesa?.dataCadastro).format('DD/MM/YYYY')}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-dataVencimento" className="form-label fw-bolder text-orange">
                  Vencimento
                </label>
                <br />
                {moment(despesa?.dataVencimento).format('DD/MM/YYYY')}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="form-dataFatal" className="form-label fw-bolder text-orange">
                  Data Fatal
                </label>
                <br />
                {moment(despesa?.dataFatal).format('DD/MM/YYYY')}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-dataEfetiva" className="form-label fw-bolder text-orange">
                  Data Efetiva
                </label>
                <br />
                {moment(despesa?.dataEfetiva).format('DD/MM/YYYY')}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="form-valorPrincipal" className="form-label fw-bolder text-orange">
                  Valor Principal
                </label>
                <br />
                {formatarMoeda(despesa?.valorPrincipal || 0)}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-valorCorrecao" className="form-label fw-bolder text-orange">
                  Correção
                </label>
                <br />
                {formatarMoeda(despesa?.valorCorrecao|| 0)}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="form-valorJuros" className="form-label fw-bolder text-orange">
                  Juros
                </label>
                <br />
                {formatarMoeda(despesa?.valorJuros|| 0)}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-valorMulta" className="form-label fw-bolder text-orange">
                  Multa
                </label>
                <br />
                {formatarMoeda(despesa?.valorMulta|| 0)}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="form-valorHonorarios" className="form-label fw-bolder text-orange">
                  Honorários
                </label>
                <br />
                {formatarMoeda(despesa?.valorHonorarios|| 0)}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-valorTotal" className="form-label fw-bolder text-orange">
                  Valor Total
                </label>
                <br />
                {formatarMoeda(despesa?.valorTotal|| 0)}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DespesaDetalheModal;
