import { createEditor } from "slate";
import { withReact } from "slate-react";
import { withHistory } from "slate-history";
import { withImages } from "./withImages";
import { withLink } from "./withLinks";
import { withTextIndent } from "./withTextIndent";
import { withTables } from "./withTables";

const plugins = [
  withImages,
  withLink,
  withTextIndent,
  withTables,
  withReact,
  withHistory,
];

export const customEditor = () => {
  let editor = createEditor();
  plugins.forEach((plugin) => (editor = plugin(editor)));
  return editor
};
