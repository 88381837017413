import clsx from "clsx";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { useContext, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EModulo } from "../../../enum";

import EncerramentoService from "../../../services/EncerramentoService";
import MotivoEncerramentoService from "../../../services/MotivoEncerramentoConsultaService";

import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IMotivoEncerramento from "../../../interfaces/IMotivoEncerramento";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaEncerramentoRequest } from "../../../interfaces/Requests/Encerramento/AdicionaEncerramentoRequest";
import FiltroPesquisaMotivoEncerramentoRequest from "../../../interfaces/Requests/MotivoEncerramento/FiltroPesquisaMotivoEncerramentoRequest";

import { OrangeContext } from "../../../contexts/OrangeProvider";
import ConsultaService from "../../../services/ConsultaService";
import IConsulta from "../../../interfaces/IConsulta";

interface IEncerramentoProps {
    toggleModal: () => void;
    exibirModal: boolean,
    setRefreshCapa(atualizaAgora: boolean): void;
}

const EncerramentoModal = ({ toggleModal, exibirModal, setRefreshCapa }: IEncerramentoProps) => {

    const { consulta, setConsulta } = useContext<OrangeInterfaceContext>(OrangeContext);

    const [motivoEncerramento, setMotivoEncerramento] = useState<IMotivoEncerramento[]>([]);
    const [carregandoMotivoEncerramento, setCarregandoMotivoEncerramento] = useState(false);
    const [filtrosPesquisa] = useState<FiltroPesquisaMotivoEncerramentoRequest>({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        tipoConsultaId: consulta.tipoConsultaId,
        areaDireitoId: 0,
        modulo: EModulo.Consultivo,
        sort: "motivoEncerramentoId",
    });

    const initialValues: AdicionaEncerramentoRequest = {
        consultaId: consulta.consultaId,
        processoId: 0,
        motivoEncerramentoId: 0,
        dataEncerramento: null,
        observacoesEncerramento: ""
    };

    const schema = () => {
        let schema: any = {};
        schema.dataEncerramento = Yup.date()
            .transform((value) => {
                return value ? moment(value).toDate() : value;
            })
            .required("Campo data de Encerramento é obrigatório");

        schema.motivoEncerramentoId = Yup.number().min(1).required("Campo Motivo Encerramento é obrigatório");

        return Yup.object().shape(schema);
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                toggleModal();

                if (consulta.consultaId) {

                    const swalWithBootstrapButtons = Swal.mixin({
                        heightAuto: false,
                        customClass: {
                            confirmButton: "btn btn-orange",
                            cancelButton: "btn btn-danger ms-5",
                        },
                        buttonsStyling: false,
                    });

                    let result = await swalWithBootstrapButtons.fire({
                        title: "Encerramento do Processo",
                        text: `Você realmente deseja encerrar?`,
                        showCancelButton: true,
                        cancelButtonText: "Fechar",
                        confirmButtonText: `Encerrar`,
                    });

                    if (result.isConfirmed) {

                        await EncerramentoService.EncerraConsulta({ ...values, motivoEncerramentoConsultaId: values.motivoEncerramentoId });

                        let resultado: IRecuperaLista<IConsulta>;

                        resultado = await ConsultaService.obterConsultaPorId(consulta.consultaId);

                        setConsulta(resultado.data[0]);

                        formik.resetForm();
                        setSubmitting(false);
                        setRefreshCapa(true);

                        await Swal.fire({
                            heightAuto: false,
                            icon: 'success',
                            text: `Consulta encerrada com sucesso`,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                }

            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possível encerrar o Consulta`,
                    text: error?.response?.data?.Message,
                    showConfirmButton: true
                });
            }
        }
    });


    useEffect(() => {

        formik.setValues(initialValues);

    }, [consulta.consultaId]);

    const cancelar = async () => {
        formik.resetForm();
        formik.setValues(initialValues);
    }

    const carregarMotivoEncerramento = async (filtro: FiltroPesquisaMotivoEncerramentoRequest) => {

        let resultado: IRecuperaLista<IMotivoEncerramento>;

        try {
            setCarregandoMotivoEncerramento(true);

            resultado = await MotivoEncerramentoService.obterMotivoEncerramentos(filtro);
            setMotivoEncerramento(resultado.data);
        } catch (error: any) {
            resultado = await MotivoEncerramentoService.obterMotivoEncerramentos(filtro);
            setMotivoEncerramento(resultado.data);
            setMotivoEncerramento([]);
        } finally {
            setCarregandoMotivoEncerramento(false);
        }
    }

    useEffect(() => {
        if (exibirModal)
            carregarMotivoEncerramento(filtrosPesquisa);
    }, [exibirModal]);

    return (
        <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">
                        Encerrar Consulta
                    </h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.submitForm();
                        }}
                    >
                        <div className="row mt-1">
                            {carregandoMotivoEncerramento ? (
                                <>Carregando Motivos de Encerramento...</>
                            ) : (
                                <div className="col-md-6 mt-3">
                                    <label htmlFor="form-cliente" className="form-label required fw-bolder text-orange">
                                        Motivo de Encerramento:
                                    </label>
                                    <select
                                        {...formik.getFieldProps("motivoEncerramentoId")}
                                        value={formik.values.motivoEncerramentoId}
                                        className={clsx(
                                            "form-select",
                                            {
                                                "is-invalid": formik.touched.motivoEncerramentoId && formik.errors.motivoEncerramentoId,
                                            },
                                            {
                                                "is-valid": formik.touched.motivoEncerramentoId && !formik.errors.motivoEncerramentoId,
                                            }
                                        )}
                                        id="form-client"
                                    >
                                        <option value="0"> Selecione uma opção</option>
                                        {motivoEncerramento.map((map) => {
                                            return (
                                                <option key={map.motivoEncerramentoConsultaId} value={map.motivoEncerramentoConsultaId}>
                                                    {" "}
                                                    {map.nome}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            )}

                            <div className="col-md-4 mt-3">

                                <label htmlFor="form-areas" className="form-label fw-bolder required text-orange">
                                    Data de Encerramento:
                                </label>

                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={clsx(
                                        "form-control",
                                        {
                                            "is-invalid": formik.touched.dataEncerramento && formik.errors.dataEncerramento,
                                        },
                                        {
                                            "is-valid": formik.touched.dataEncerramento && !formik.errors.dataEncerramento,
                                        }
                                    )}
                                    id="form-dataEncerramento" selected={formik.values.dataEncerramento}
                                    onChange={(date: Date) => formik.setFieldValue("dataEncerramento", date)}
                                />

                                {formik.touched.dataEncerramento && formik.errors.dataEncerramento && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span style={{ fontSize: "11px" }} role="alert">
                                                {formik.errors.dataEncerramento}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-6 mt-3">

                                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                    Descrição
                                </label>
                                <textarea
                                    {...formik.getFieldProps("observacoesEncerramento")}
                                    maxLength={500}
                                    className={clsx(
                                        "form-control",
                                        {
                                            "is-invalid": formik.touched.observacoesEncerramento && formik.errors.observacoesEncerramento,
                                        },
                                        {
                                            "is-valid": formik.touched.observacoesEncerramento && !formik.errors.observacoesEncerramento,
                                        }
                                    )}
                                    id="form-observacoesEncerramento"
                                />
                                {formik.touched.observacoesEncerramento && formik.errors.observacoesEncerramento && (
                                    <div className="fv-plugins-message-container mt-1">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.observacoesEncerramento}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: "0 auto" }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => formik.submitForm()} type="button" className="btn btn-orange ms-5">
                        Adicionar
                    </button>

                    <button
                        onClick={() => {
                            cancelar();
                            toggleModal();
                        }}
                        type="button"
                        className="btn btn-danger ms-5"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EncerramentoModal;