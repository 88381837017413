import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import {
  faArrowLeft,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useFormik } from "formik";
import Swal from "sweetalert2";
import Select from "react-select";
import React, { useCallback, useEffect, useState } from "react";
import MigracoesService from "../../../../services/MigracoesService";
import Spinner from "../../../../components/Spinner";
import IMigracoesUpload from "../../../../interfaces/IMigracoesFileUpload";
import DragDropTableMigration from "./components/DragDropTableMigration";
import { optionsType } from "../../../../components/Comum/Fornecedor/FornecedorField";
import DynamicMigration from "../../../../interfaces/Requests/Migracoes/MigracoesCreateRequest";

const MigracoesAddPage = () => {
  const [tipo, setTipo] = useState({ label: "", value: "" });
  const [tipos, setTipos] = useState<{ label: string; value: string }[]>([]);
  const [sheets, setSheets] = useState<{ label: string; value: string }[]>([]);
  const [sheet, setSheet] = useState({ label: "", value: "" });
  const [file, setFile] = useState<FileList | null>(null);
  const [fileProps, setFileProps] = useState<IMigracoesUpload>({
    data: {
      fileUrl: "",
      migrationFileId: 0,
    },
  });
  const [colunas, setColunas] = useState({
    objectName: "",
    properties: [
      {
        propertyName: "Selecione o tipo e a sheet!",
        requestName: "Selecione o tipo e a sheet!",
        type: "Selecione o tipo e a sheet!",
        required: false,
        hasProperties: false,
      },
    ],
  });
  const [headers, setHeaders] = useState<optionsType[]>([
    { label: "Selecione uma sheet!", value: 0 },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [requiredFields, setRequiredFields] = useState<string[]>([""]);
  const [createRequest, setCreateRequest] = useState<DynamicMigration>({
    migrationFileId: 0,
    migrationType: "",
    SheetName: "",
  });

  const navigate = useNavigate();

  const validarForm = Yup.object().shape({
    tipoMigracao: Yup.string().required("O Tipo é obrigatório."),
    sheet: Yup.string().required("A Sheet é obrigatória."),
  });

  const carregarTipos = async () => {
    try {
      setLoading(true);

      const data = await MigracoesService.obterTipoMigracoes();

      const mappedData = data.data.map((tipo) => ({
        label: tipo,
        value: tipo,
      }));

      setTipos(mappedData);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível obter os Tipos de Migração`,
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarColunasTipo = async (tipo: string) => {
    try {
      setLoading(true);

      const { data } = await MigracoesService.obterColunasTipo(tipo);

      data.properties.sort((a, b) => {
        if (a.required && !b.required) return -1;
        if (!a.required && b.required) return 1;
        return 0;
      });

      setColunas(data);
      updateCreateRequest(data.objectName, {});
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível obter as colunas`,
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarSheets = async (migrationFileId: number) => {
    try {
      setLoading(true);

      const response = await MigracoesService.obterSheets(migrationFileId);

      const mappedReponse = response.data.sheets.map((sheet) => ({
        label: sheet.sheetName,
        value: sheet.sheetName,
      }));

      setSheets(mappedReponse);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível obter as Sheets`,
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarHeaders = async (
    sheetName: string,
    migrationFileId: number
  ) => {
    try {
      setLoading(true);

      const response = await MigracoesService.obterHeaders(
        sheetName,
        migrationFileId
      );

      const mappedResponse = response.data.sheets[0].data.map((sheet) => {
        return {
          label: Object.keys(sheet)[0],
          value: sheet[Object.keys(sheet)[0]],
        };
      });

      setHeaders(mappedResponse);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível obter os cabeçalhos do arquivo`,
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    setFile(e.target.files);
  };

  const handleFileUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setLoading(true);

      e.preventDefault();

      if (file !== null && file.length > 0) {
        const response = await MigracoesService.carregarArquivo(file);

        setFileProps(response);

        updateCreateRequest("migrationFileId", response.data.migrationFileId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Arquivo enviado com sucesso.`,
          showConfirmButton: true,
        });

        await carregarSheets(response.data.migrationFileId);
      } else {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Insira um arquivo`,
          text: "Insira um arquivo .xls ou .xlsx para enviar",
          showConfirmButton: true,
        });
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível fazer o upload do arquivo`,
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateCreateRequest = (
    key: string,
    value: number | string | object,
    removeNestedObject?: boolean,
    objectNameProp?: boolean
  ) => {
    if (removeNestedObject) {
      const newCreateRequest = createRequest;
      type ObjectName = keyof typeof newCreateRequest; // Tipos das chaves de newCreateRequest

      const objectName = colunas.objectName as ObjectName; // Garantir que objectName é uma chave válida

      if (objectName in newCreateRequest) {
        const targetObject = newCreateRequest[objectName];

        if (typeof targetObject === "object" && targetObject !== null) {
          const keyName = key as keyof typeof targetObject; // Convertendo keyName de forma segura

          if (keyName in targetObject) {
            delete targetObject[keyName];
          }
        }
      }

      console.log(newCreateRequest);

      setCreateRequest(newCreateRequest);
    } else if (objectNameProp) {
      setCreateRequest((prev) => {
        const currentObject =
          prev[colunas.objectName as keyof typeof prev] || {};

        return {
          ...prev,
          [colunas.objectName]: {
            ...currentObject,
            [key]: value,
          },
        };
      });
    } else {
      Object.keys(createRequest).map((key) => {
        if (
          typeof createRequest[key as keyof typeof createRequest] === "object"
        ) {
          delete createRequest[key as keyof typeof createRequest];
        }
      });
      setCreateRequest((prev) => ({ ...prev, [key]: value }));
    }
  };

  const checkRequiredFields = useCallback(() => {
    if (createRequest[colunas.objectName as keyof typeof createRequest]) {
      const keysInRequest = Object.keys(
        createRequest[colunas.objectName as keyof typeof createRequest]
      );

      const filteredFields = colunas.properties
        .filter((campo) => {
          return (
            !keysInRequest.find((key) => key === campo.requestName) &&
            campo.required
          );
        })
        .map((field) => field.requestName);

      console.log(filteredFields);

      setRequiredFields(filteredFields);
    }
  }, [createRequest]);

  const formik = useFormik({
    initialValues: {
      tipoMigracao: "",
      sheet: "",
    },
    enableReinitialize: true,
    validationSchema: validarForm,
    onSubmit: async () => {
      try {
        const values = Object.values(
          createRequest[colunas.objectName as keyof typeof createRequest]
        );

        const keys = Object.keys(
          createRequest[colunas.objectName as keyof typeof createRequest]
        );

        const texto = keys.map(
          (key, index) =>
            `Campo: <b>${
              colunas.properties.find((coluna) => coluna.requestName === key)
                ?.propertyName
            }</b><br /> Coluna Associada: <b>${
              headers.find((header) => header.value === values[index])?.label
            }</b>`
        );

        const htmlString = `
        <div>
          ${texto.map((t) => `<span>${t}<br /><br /></span>`).join("")}
        </div>
      `;

        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        const result = await swalWithBootstrapButtons.fire({
          title: "Confirme os campos selecionados:",
          html: htmlString,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Migração cadastrada com sucesso`,
            showConfirmButton: false,
            timer: 3000,
          });

          await MigracoesService.criarMigracao(createRequest);

          navigate("/Administracao/Seguranca/Migracoes");
        } else {
          return;
        }
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível cadastrar a Migração`,
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          showConfirmButton: true,
        });
        // } finally {
        //   resetForm();
      }
    },
  });

  useEffect(() => {
    carregarTipos();
  }, []);

  useEffect(() => {
    checkRequiredFields();
  }, [createRequest]);

  return (
    <div>
      <LayoutPageTitle title="Adicionar Migração">
        <button
          onClick={() => {
            navigate("/Administracao/Seguranca/Migracoes");
          }}
          className="btn btn-md btn-orange"
        >
          Voltar
          <FontAwesomeIcon
            color={"white"}
            className="mx-2"
            icon={faArrowLeft}
          />
        </button>
      </LayoutPageTitle>

      <div className="d-md-flex justify-content-space-between">
        <div style={{ width: "220%" }}>
          <form
            className="mb-3 d-xl-flex align-items-end gap-3"
            onSubmit={handleFileUpload}
            style={{ width: "220%" }}
          >
            <div className="col-md-3 mt-3">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Arquivo de Migração:
              </label>

              <input
                type="file"
                className="form-control"
                onChange={handleFileChange}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />

              {formik.touched.tipoMigracao && formik.errors.tipoMigracao && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.tipoMigracao}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-3">
              <button type="submit" className="btn btn-orange mt-5">
                <FontAwesomeIcon
                  title="Carregar Arquivo"
                  icon={faCloudUploadAlt}
                />
              </button>
            </div>
          </form>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            className="mb-3"
            style={{ width: "220%" }}
          >
            <div className="col-md-3 mt-3">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Tipo de Migração:
              </label>
              <Select
                placeholder="Selecione um tipo"
                id="form-select"
                options={tipos}
                isSearchable
                isLoading={loading}
                value={tipo}
                isDisabled={fileProps.data.migrationFileId === 0}
                onChange={(select) => {
                  if (select) {
                    setTipo(select);
                    formik.setFieldValue("tipoMigracao", select.value);

                    updateCreateRequest("migrationType", select.value);

                    carregarColunasTipo(select.value);
                  }
                }}
              />

              {formik.touched.tipoMigracao && formik.errors.tipoMigracao && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.tipoMigracao}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-3 mt-3">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Sheet Escolhida:
              </label>
              <Select
                placeholder="Selecione uma Sheet"
                id="form-select"
                options={sheets}
                isSearchable
                isLoading={loading}
                value={sheet}
                isDisabled={fileProps.data.migrationFileId === 0}
                onChange={async (select) => {
                  if (select) {
                    setSheet(select);
                    formik.setFieldValue("sheet", select.value);

                    updateCreateRequest("SheetName", select.value);

                    await carregarHeaders(
                      select.value,
                      fileProps.data.migrationFileId
                    );
                  }
                }}
              />

              {formik.touched.tipoMigracao && formik.errors.tipoMigracao && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.tipoMigracao}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-3 d-flex justify-content-between">
              <button
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  requiredFields.length > 0
                }
                type="submit"
                className="btn btn-orange mt-5"
              >
                {!formik.isSubmitting && <> Salvar </>}
                {formik.isSubmitting && <Spinner />}
              </button>
              <button
                onClick={() => {
                  navigate("/Administracao/Seguranca/Migracoes");
                }}
                disabled={formik.isSubmitting}
                type="button"
                className="btn btn-danger  mt-5 float-end"
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>

        <div>
          {loading && (
            <DragDropTableMigration
              colunas={[
                {
                  propertyName: "Carregando...",
                  requestName: "Carregando...",
                  type: "Carregando...",
                  required: false,
                  hasProperties: false,
                },
              ]}
              headers={headers}
              updateCreateRequest={updateCreateRequest}
            />
          )}

          {!loading && (
            <DragDropTableMigration
              colunas={colunas.properties}
              headers={headers}
              updateCreateRequest={updateCreateRequest}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MigracoesAddPage;
