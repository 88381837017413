import Estatisticas from "./Estatisticas";
import { DadoNumerico } from "../types"
import { IProcessoEstatisticas } from "../../../interfaces/IProcesso";
import { MultiDados } from "../types/TiposDados";

export default class DashboardStatisticas extends Estatisticas<IProcessoEstatisticas> {

  public meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

  public processosPorUF(): DadoNumerico[] {
    const resultado: DadoNumerico[] = []
    const ativos = this.dados.filter(processo => processo.statusProcessoId === 1 && processo.valorProvisaoAtualizado > 0);

    const agrupadoPorUf = groupBy(ativos, e => e.estadoUF)

    Array.from(agrupadoPorUf).map(e => {
      resultado.push(new DadoNumerico(e[0] === undefined ? 'Outras' : e[0], [], e[1].map(a => 1)))
    })

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) > Number(b.total())) ? 1 : -1 })

    return resultadoSort;
  }

  public processosAnoDistribuidoPorMes(): DadoNumerico[] {
    const dados = this.dados.filter((dado) => dado.dataDistribuicao)
    dados.map((dado: any) => {
      dado.ano = new Date(dado.dataDistribuicao).getFullYear().toString();
      dado.mes = this.meses[new Date(dado.dataDistribuicao).getMonth()];
    });

    const resultado: DadoNumerico[] = []

    const agrupadoPorAno = groupBy(dados, e => new Date(e.dataDistribuicao).getFullYear().valueOf());

    agrupadoPorAno.forEach((val, key) => {
      const mdata: number[] = [];

      this.meses.forEach((n, index) => {
        const temValorNoMes = val.filter((t: any) => t.mes === n)
        if (temValorNoMes) {
          mdata.push(temValorNoMes.length);
        } else {
          //Completa o array com 0
          mdata.push(0);
        }
      })

      resultado.push(new DadoNumerico(!key.toString() ? 'Outros' : key.toString(), [], mdata))
    })
    const resultadoSort = resultado.sort((a, b) => { return (Number(a.etiqueta) > Number(b.etiqueta)) ? 1 : -1 })


    return resultadoSort;
  }

  public processosAnoEncerradosPorMes(): DadoNumerico[] {
    const dados = this.dados.filter((dado) => dado.dataEncerramento)
    dados.map((dado: any) => {
      dado.ano = new Date(dado.dataDistribuicao).getFullYear().toString();
      dado.mes = this.meses[new Date(dado.dataDistribuicao).getMonth()];
    });

    const resultado: DadoNumerico[] = []
    const agrupadoPorAno = groupBy(dados, e => new Date(e.dataEncerramento).getFullYear().valueOf());

    agrupadoPorAno.forEach((val, key) => {
      const mdata: number[] = [];

      this.meses.forEach((n, index) => {
        const temValorNoMes = val.filter((t: any) => t.mes === n)

        if (temValorNoMes) {
          mdata.push(temValorNoMes.length);
        } else {
          //Completa o array com 0
          mdata.push(0);
        }
      })

      resultado.push(new DadoNumerico(!key.toString() ? 'Outros' : key.toString(), [], mdata))
    })
    return resultado
  }

  public processosEstatusPeriodo(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];

    const statusProcessosGroup = groupBy(this.dados, e => e.statusProcessoNome);

    Array.from(statusProcessosGroup).map(processo => {
      resultado.push(new DadoNumerico(!processo[0] ? 'Outros' : processo[0], [], [processo[1].length]))
    })

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) < Number(b.total())) ? 1 : -1 })
    return resultadoSort
  }

  public processosEstatusTipo(): MultiDados {
    const resultado: DadoNumerico[] = [];
    let values: number[] = [];
    const labelsTipoProcesso = groupBy(this.dados, e => e.areaDireitoNome);
    const labelsStatusProcessos = groupBy(this.dados, e => e.statusProcessoNome);
    const labels = Array.from(labelsStatusProcessos.keys());

    Array.from(labelsTipoProcesso).map(processo => {
      const quantidadeProcessosDoTipoAtualPorStatus = Array.from(groupBy(processo[1], t => t.statusProcessoNome))
      Array.from(labelsStatusProcessos).map((processoStatus) => {        
        const achou = quantidadeProcessosDoTipoAtualPorStatus.find((p) => p[0] === processoStatus[0])
        if (achou) {
          values.push(achou[1].length)
        } else {
          values.push(0)
        }
      })
      resultado.push(new DadoNumerico(!processo[0] ? 'Outros' : processo[0], [], values))
      values = []
    })
    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) > Number(b.total())) ? -1 : 1 })
    // return resultadoSort
    return new MultiDados(labels, [], resultadoSort);
  }

  public processoPorEmpresa(): DadoNumerico[] {
    const ativos = this.dados.filter(processo => processo.statusProcessoId === 1);
    const empresas = groupBy(ativos, e => e.processoEmpresas.find(e => e.principal === "True")?.nome)
    let resultado: DadoNumerico[] = []
    Array.from(empresas).map(e => {
      resultado.push(new DadoNumerico(e[0] === undefined ? 'Outras' : e[0], [], e[1].map(a => 1)))
    })

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) > Number(b.total())) ? -1 : 1 })

    return resultadoSort
  }

  public empresaPorValor(): DadoNumerico[] {
    const ativos = this.dados.filter(processo => processo.statusProcessoId === 1 && processo.valorProvisaoAtualizado > 0);
    const empresas = groupBy(ativos, e => e.processoEmpresas.find(e => e.principal === "True")?.nome)
    let resultado: DadoNumerico[] = [];

    Array.from(empresas).map(e => {
      resultado.push(new DadoNumerico(e[0] === undefined ? 'Outras' : e[0], [], e[1].map(a => a.valorProvisaoAtualizado) ))
      console.log(e[0] +' - '+ e[1].map(a => a.valorProvisaoAtualizado).reduce((partialSum, a) => partialSum + a, 0 ))
    })


    // const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) > Number(b.total())) ? -1 : 1 })


    return resultado;
  }

    public empresasPorValorTipoProcesso(): MultiDados {
      const resultado: DadoNumerico[] = [];      
      let values: any[] = [];
      
      const ativos = this.dados.filter(processo => processo.statusProcessoId === 1 && processo.valorProvisaoAtualizado > 0);
      const labelsTipoProcesso = groupBy(ativos, e => e.areaDireitoNome);
      const empresas = groupBy(ativos, e => e.processoEmpresas.find(e => e.principal === "True")?.nome);
      const labelsArea = Array.from(labelsTipoProcesso.keys());

      Array.from(labelsTipoProcesso).map(tipoProcesso => {

        const quantidadeProcessosDoTipoAtualPorEmpresa = Array.from(groupBy(tipoProcesso[1], t => t.processoEmpresas.find(e => e.principal === "True")?.nome));

        Array.from(empresas).map((empresa)  => {          
          const achou = quantidadeProcessosDoTipoAtualPorEmpresa.find((p) => p[0] == empresa[0]);
          if (achou) {
            values.push(achou[1].map(e => e.valorProvisaoAtualizado).reduce((partialSum, a) => partialSum + a, 0) );
          } else {
            values.push(0);
          }
        });

        resultado.push(new DadoNumerico(!tipoProcesso[0] ? 'Outros' : tipoProcesso[0], [], values))
        values = []
      })

      const toSort:any[] = [];

      Array.from(empresas).map((empresa, index) => {
        toSort.push({
          empresa: empresa[0] || 'Outras',
          dados: resultado.map(area => area.dados[index]),
          total: resultado.map((area) => area.dados[index]).reduce((partialSum, a) => partialSum + a, 0)        
        })
      })

      toSort.sort((a, b) => { return (Number(a.total) > Number(b.total)) ? -1 : 1 })
      const resultadoFinal:any[] = [];

      labelsArea.map((tipoProcesso, index) => {

        const dadosArea = toSort.map((dado) => {
          return dado.dados[index]
        })
        resultadoFinal.push(new DadoNumerico(!tipoProcesso ? 'Outros' : tipoProcesso, [], dadosArea))
      })
      
      return new MultiDados(toSort.map(e => e.empresa), [], resultadoFinal);
      // const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) > Number(b.total())) ? -1 : 1 })
   
    }

  public UfPorValor(): DadoNumerico[] {
    const ativos = this.dados.filter(processo => processo.statusProcessoId === 1 && processo.valorProvisaoAtualizado > 0);

    const ufPorValor = ativos.filter(processo => processo.valorProvisaoAtualizado > 0);
    let resultado: DadoNumerico[] = []
    //Implementar somente os processos ativos

    const processosPorUf = groupBy(ufPorValor, e => e.estadoUF)

    Array.from(processosPorUf).map(e => {
      resultado.push(new DadoNumerico(e[0] === undefined ? 'Outras' : e[0], [], e[1].map(a => a.valorProvisaoAtualizado)))
    })

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) > Number(b.total())) ? -1 : 1 })

    return resultadoSort;

  }

  public processosPorDataDistribuicao(): DadoNumerico[] {
    const dados = this.dados.filter((dado) => dado.dataDistribuicao)
    const resultado: DadoNumerico[] = [];
    const statusProcessosGroup = groupBy(dados, e => new Date(e.dataDistribuicao).getFullYear().valueOf());
    Array.from(statusProcessosGroup).map(processo => {
      resultado.push(new DadoNumerico(!processo[0].toString() ? 'Outros' : processo[0].toString(), [], [processo[1].length]))
    })

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.etiqueta) > Number(b.etiqueta)) ? 1 : -1 })

    return resultadoSort;
  }

  public processosPorDataEncerramento(): DadoNumerico[] {
    const dados = this.dados.filter((dado) => dado.dataEncerramento)
    const resultado: DadoNumerico[] = [];
    const statusProcessosGroup = groupBy(dados, e => new Date(e.dataEncerramento).getFullYear().valueOf());
    Array.from(statusProcessosGroup).map(processo => {
      resultado.push(new DadoNumerico(!processo[0].toString() ? 'Outros' : processo[0].toString(), [], [processo[1].length]))
    })

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.etiqueta) > Number(b.etiqueta)) ? 1 : -1 })

    return resultadoSort;
  }
}

// Função que agrupa por um determinado campo do array.
export function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
  return array.reduce((store, item) => {
    let key = grouper(item)
    if (!store.has(key)) {
      store.set(key, [item])
    } else {
      store.get(key)?.push(item)
    }
    return store
  }, new Map<K, V[]>());
}
