import { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import NumberFormat from "react-number-format";
import { notifyWarning } from "../Toast/ToastFormik";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { faBuilding, faEdit, faQuestionCircle, faSearch, faTable, faTrashAlt, faUsers } from "@fortawesome/free-solid-svg-icons";

import CidadeService from "../../../services/CidadeService";
import { CorreiosAPI } from "../../../services/CorreiosApi";
import ProcessoService from "../../../services/ProcessoService";
import ProtocoloService from "../../../services/ProtocoloService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import IEmpresa from "../../../interfaces/IEmpresa";
import IProcesso from "../../../interfaces/IProcesso";
import ICentroCusto from "../../../interfaces/ICentroCusto";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IAdvogadoInterno from "../../../interfaces/IAdvogadoInterno";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo } from "../../../enum";
import { EPeriodoEnum } from "../../../enum/EPeriodoEnum";
import { ETipoCampoEnum } from "../../../enum/ETipoCampoEnum";

import {
  currencyFormatterPerc,
  currencyLabelFormatterNotRS,
  styleSelect,
  tipoPorModulo,
  validarCep
} from "../../../utils/Utils";

import { MASCARA_NUMERO_PROCESSO } from "../../../utils/Constants";
import { setValueCalcularVigencia } from "../../../utils/SchemasUtil";
import { ECamposIDEnum, returnarEnumDescricaoID } from "../../../enum/ECamposIDEnum";

import GridPadrao from "../GridPadrao";
import EmpresaField from "../Empresa/EmpresaField";
import CidadeField from "../EstadoCidade/CidadeField";
import EstadoField from "../EstadoCidade/EstadoField";
import CurrencyField from "../CurrencyBRL/CurrencyField";
import AdvogadoField from "../../Advogados/AdvogadoField";
import IFileListField from "../../FileUpload/FileListField";
import FormTabelaField from "../TabelaField/Modulo/FormTabelaField";
import CentroCustoField from "../CentroCusto/CentroCustoField";
import FormListTabelaField from "../TabelaField/Modulo/FormListTabelaField";
import IFileCheckListField from "../../FileUpload/FileCheckListField";
import ParteContrariaField from "../ParteContraria/ParteContrariaField";
import AdvogadoInternoField from "../../Advogados/AdvogadoInternoField";

import AreaTextoField from "../AreaTexto/AreaTextoField";
import IDModalPage from "../../../pages/ProcessoPage/Modal/IDModalPage";
import AudienciaCadastroModal from "../Audiencia/AudienciaCadastroModal";
import ContratoPastaModalPage from "../../../pages/Contrato/Modal/PastaModalPage";
import ProcuracaoPastaModalPage from "../../../pages/ProcuracaoPage/ProcuracaoPastaModalPage";

interface ICamposDinamicosPropos {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  configuracaoTelaLista: IRecuperaConfiguracaoTelaResponse[];
  formik: any;
}

const CamposCadastroDinamicos = ({ configuracaoTela, configuracaoTelaLista, formik }: ICamposDinamicosPropos) => {

  const [options, setOptions] = useState<any[]>([]);

  const [genericId, setGenericId] = useState<number>(0);
  const [genericIdMulti, setGenericIdMulti] = useState<any>([]);
  const [genericIdMultiSelected, setGenericIdMultiSelected] = useState([]);

  const [tipoProtocoloTribunal, setTipoProtocoloTribunal] = useState<string>('');

  const [exibirModalEmpresas, setExibirModalEmpresas] = useState<boolean>(false);
  const [exibirModalAudiencia, setExibirModalAudiencia] = useState<boolean>(false);
  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);
  const [exibirModalCentroCusto, setExibirModalCentroCusto] = useState<boolean>(false);
  const [exibirModalPartesContrarias, setExibirModalPartesContrarias] = useState<boolean>(false);
  const [exibirModalAdvogadosInternos, setExibirModalAdvogadosInternos] = useState<boolean>(false);
  const [exibirModalAdvogadosPartesContrarias, setExibirModalAdvogadosPartesContrarias] = useState<boolean>(false);

  const [exibirCampo, setExibirCampo] = useState<boolean>(true);
  const [exibirPasta, setExibirPasta] = useState<boolean>(false);
  const [exibirTabela, setExibirTabela] = useState<boolean>(false);
  const [exibirProcesso, setExibirProcesso] = useState<boolean>(false);

  const togglePastaModal = () => setExibirPasta(!exibirPasta);
  const toggleTabelaModal = () => setExibirTabela(!exibirTabela);
  const toggleProcessoModal = () => setExibirProcesso(!exibirProcesso);
  const toggleEmpresaModal = () => setExibirModalEmpresas(!exibirModalEmpresas);
  const toggleAudienciaModal = () => setExibirModalAudiencia(!exibirModalAudiencia);
  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);
  const toggleCentroCustoModal = () => setExibirModalCentroCusto(!exibirModalCentroCusto);
  const toggleParteContrariaModal = () => setExibirModalPartesContrarias(!exibirModalPartesContrarias);
  const toggleAdvogadosInternosModal = () => setExibirModalAdvogadosInternos(!exibirModalAdvogadosInternos);
  const toggleAdvogadosParteContrariaModal = () => setExibirModalAdvogadosPartesContrarias(!exibirModalAdvogadosPartesContrarias);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelperEndereco = () => (
    <a onClick={() => adicionarEndereco(formik.values.cep)} style={{ cursor: "pointer" }}>
      <FontAwesomeIcon className="mx-1 text-orange" icon={faSearch} />
    </a>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const validarNumeroProcessos = async () => {
    try {

      let resultado: IRecuperaLista<IProcesso>;

      resultado = await ProcessoService.validarExisteProcesso(formik.values.numeroprocesso);

      if (resultado.totalRegistros > 0) {
        notifyWarning("Já existe número de processo cadastrado.");
      }
    } catch (error: any) {
      console.log(error)
    }

  }

  const optionsMulti = async () => {
    try {
      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values?.modulo)}Id`] = formik.values?.categoriaId;
      let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
        ...objeto,
        tela: formik.values?.tela,
        campo: configuracaoTela.campo,
        modulo: formik.values?.modulo
      });
      campos.data.forEach((item: any) => { genericIdMulti.push({ value: item.xCampoOpcaoId, label: item.nome }); });
    } catch (error: any) {
      setGenericIdMulti([]);
    }
  };

  const option = async () => {
    try {
      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values?.modulo)}Id`] = formik.values?.categoriaId;

      let options: any[] = [{ label: 'Selecione', value: 0 }];

      if (configuracaoTela.isXCampo) {

        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({ ...objeto, tela: formik.values.tela, campo: configuracaoTela.campo, modulo: formik.values.modulo });

        resultado.data.map((item) => options.push({ label: item.nome, value: item.xCampoOpcaoId }));

        formik.setFieldValue(`${configuracaoTela.campoNome.toLowerCase()}Id`, 0); // resolver bug da validação

      } else {

        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(configuracaoTela?.campoNome, formik.values.modulo, objeto[`${tipoPorModulo(formik.values?.modulo)}Id`], 0);

        resultado.data.map((item) => options.push({ label: item.nome, value: item[returnarEnumDescricaoID(configuracaoTela?.campoNome)] }));
      }

      setOptions(options)
    } catch (error: any) {
      setOptions([]);
    }
  };

  const optionsGestorAreaSolicitanteApData = async () => {
    try {

      let objeto: any = {};

      objeto[`${tipoPorModulo(formik.values?.modulo)}Id`] = formik.values?.categoriaId;

      let options: any[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await ConfiguracaoTelaService.obterListaGestorSolicitanteApData(formik.values.modulo, objeto[`${tipoPorModulo(formik.values?.modulo)}Id`], 0, formik.values.departamentoId);

      resultado.data.map((item) => options.push({ label: item.nome, value: item[returnarEnumDescricaoID(ECamposIDEnum.Gestor)] }));

      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    }
  };

  const optionsTipoProtocoloTribunal = async () => {
    try {

      let options: any[] = [{ label: 'Selecione', value: 0 }];
      let resultado = await ProtocoloService.obterTipoProtocoloTribunal(
        {
          processoId: formik.values.processoId,
          tipoProtocoloId: formik.values.tipoProtocoloId,
          codigo: '',
          descricao: '',
          processoEmpresaId: 0,
          statusProtocoloId: 0,
          tipoProtocoloTribunal: '',
          clienteId: 0,
          status: [],
          limit: 100,
          totalItems: 0,
          offset: 0,
          sort: "-documentoId",
        }
      );

      resultado.data.map((item: string, index: number) => options.push({ label: item, value: index }));
      setOptions(options)
    } catch (error: any) {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (!configuracaoTela.campoCondicionadoEnable) return;

    const campoCondicionado = configuracaoTelaLista?.find(e => e.campo === configuracaoTela.camposCondicionadoId);

    if (campoCondicionado?.campoNome) {
      const campoCondicionadoId = formik.values[`${returnarEnumDescricaoID(campoCondicionado.campoNome, campoCondicionado.isXCampo)}`] || campoCondicionado.valorId;
      const opcaoEncontrada = configuracaoTela.campoCondicionadoOpcoes?.some(campoOpcao => campoOpcao.opcaoId === campoCondicionadoId);

      configuracaoTela.obrigatorio = !!opcaoEncontrada;
      setExibirCampo(!!opcaoEncontrada);
    }
  }, [formik.values, configuracaoTela, configuracaoTelaLista]);


  useEffect(() => { if (configuracaoTela.campoTipo == ETipoCampoEnum.ListaComLink) option(); }, [formik.values?.categoriaId]);

  useEffect(() => { if (configuracaoTela.campoTipo == ETipoCampoEnum.ListaMulti) optionsMulti(); }, [formik.values?.categoriaId]);

  useEffect(() => { if (configuracaoTela.campoNome == ECamposIDEnum.TipoProtocoloTribunal) optionsTipoProtocoloTribunal(); }, [formik.values?.categoriaId]);

  useEffect(() => { if (formik.values.modulo == EModulo.Contrato) setValueCalcularVigencia(EPeriodoEnum.DEFAULT, formik) }, [formik.values?.tipoVigenciaId]);

  useEffect(() => { if (configuracaoTela.campoNome == ECamposIDEnum.GestorAreaSolicitanteApData) optionsGestorAreaSolicitanteApData(); }, [formik.values?.departamentoId]);

  useEffect(() => { if (configuracaoTela.campoTipo == ETipoCampoEnum.ListaMulti) formik.setFieldValue(returnarEnumDescricaoID(configuracaoTela.campoNome, configuracaoTela.isXCampo), true) }, [formik.values?.categoriaId]);

  useEffect(() => { if (formik.values.modulo == EModulo.Contrato) setValueCalcularVigencia(parseInt(formik.values?.periodicidadeId), formik) }, [formik.values?.periodicidadeId, formik.values.datainiciovigencia, formik.values.quantidadeperiodicidade]);

  useEffect(() => { if (configuracaoTela.campoTipo == ETipoCampoEnum.Lista && configuracaoTela.campoNome != ECamposIDEnum.TipoDocumento && configuracaoTela.campoNome != ECamposIDEnum.GestorAreaSolicitanteApData) option(); }, [formik.values?.categoriaId]);

  useEffect(() => {
    if (configuracaoTela && formik.values[returnarEnumDescricaoID(configuracaoTela.campoNome, configuracaoTela.isXCampo)]) {
      setGenericId(formik.values[returnarEnumDescricaoID(configuracaoTela.campoNome, configuracaoTela.isXCampo)]);
    }
  }, [formik.values, configuracaoTela]);

  const onChangeSelect = (value: string, campoNome: string, isXCampo = false) => {

    formik.setFieldTouched(returnarEnumDescricaoID(campoNome, isXCampo), true);

    formik.setFieldValue(returnarEnumDescricaoID(campoNome, isXCampo), parseInt(value));

    setGenericId(parseInt(value));
  };

  const onChangeMultiSelect = (event: any, campoNome: string, isXCampo = false) => {

    formik.setFieldTouched(returnarEnumDescricaoID(campoNome, isXCampo), true);

    formik.setFieldValue(returnarEnumDescricaoID(campoNome, isXCampo), event);

    setGenericIdMultiSelected(event);
  };

  const adicionarEndereco = (cep: string) => {
    try {
      if (validarCep(cep)) {
        CorreiosAPI(cep)
          .then((correios) => correios.json())
          .then(async (correio) => {
            formik.setFieldValue("endereco", correio.logradouro);
            formik.setFieldValue("bairro", correio.bairro);
            let resultado = await CidadeService.obterCidadePorIBGE(correio.ibge);
            formik.setFieldValue("cidadeId", resultado.data[0]?.cidadeId);
            formik.setFieldValue("estadoId", resultado.data[0]?.estadoId);
            formik.setFieldValue("paisId", 1);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const colunasCentroCusto: TableColumn<ICentroCusto>[] = [
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: ICentroCusto) => row.codigo,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: ICentroCusto) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Percentual",
      sortField: "percentual",
      selector: (row: ICentroCusto) => (row.percentual ? row.percentual + "%" : "0%"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAdvogadoInterno: TableColumn<IAdvogadoInterno>[] = [
    {
      name: "Id",
      sortField: "advogadoInternoId",
      selector: (row: any) => row.advogadoInternoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal == true ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAdvogado: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "advogadoId",
      selector: (row: any) => row.advogadoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "pessoaNome",
      selector: (row: any) => row.pessoaNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "OAB",
      sortField: "oab",
      selector: (row: any) => row.oab,
      sortable: true,
      wrap: true,
    },
  ];

  const colunasParteContraria: TableColumn<any>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Documento",
      sortField: "documento",
      selector: (row: any) => row.documento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumento",
      selector: (row: any) => row.tipoDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacaoNome",
      selector: (row: any) => row.formaParticipacaoNome || row.papelSignatarioNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasEmpresa: TableColumn<IEmpresa>[] = [
    {
      name: "Id",
      sortField: "empresaId",
      selector: (row: IEmpresa) => row.empresaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nomeFantasia",
      selector: (row: IEmpresa) => row.nomeFantasia,
      sortable: true,
      wrap: true,
    },
    {
      name: "CNPJ",
      sortField: "numeroDocumento",
      selector: (row: any) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacao",
      selector: (row: IEmpresa) => row.formaParticipacao || row.papelSignatario,
      sortable: true,
      wrap: true,
    },
    ...(formik?.values?.rateio
      ? [
        {
          name: "Percentual",
          sortField: "percentual",
          selector: (row: IEmpresa) => currencyLabelFormatterNotRS(row.percentual),
          sortable: true,
          wrap: true,
        },
      ]
      : []),
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: IEmpresa) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAudiencia: TableColumn<any>[] = [
    {
      name: "Tipo",
      sortField: "tipoAudienciaNome",
      selector: (row: any) => row.tipoAudienciaNome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Data da Audiência",
      sortField: "dataaudiencia",
      selector: (row: any) => moment(row.dataaudiencia).format('DD/MM/YYYY'),
      sortable: true,
      wrap: true,
    },
    {
      name: "Observação",
      sortField: "observacao",
      selector: (row: any) => row.observacao,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Opções',
      cell: (index: number) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Remover"
              onClick={() => formik.setFieldValue('audienciaList', formik.values.audienciaList.filter((e: any, i: number) => i !== index))}
              style={{ fontWeight: 'normal', cursor: 'pointer' }}
              className="mx-1 text-orange"
              icon={faTrashAlt}
            />
          </div>
        )
      },
      ignoreRowClick: true,
    }
  ];

  const renderModalParteContraria = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.ParteContraria) {
      return (
        <>
          <ParteContrariaField
            modulo={formik.values.modulo}
            titulo={configuracaoTela?.label}
            setFieldValue={formik.setFieldValue}
            toggleModal={toggleParteContrariaModal}
            principalId={formik.values.principalId}
            categoriaId={formik.values.categoriaId}
            exibirModal={exibirModalPartesContrarias}
            partesContrariasList={formik.values.partesContrariasList || []}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalEmpresa = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.Empresa) {
      return (
        <>
          <EmpresaField
            exibirModal={exibirModalEmpresas}
            toggleModal={toggleEmpresaModal}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalCentroCusto = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.CentroCusto) {
      return (
        <>
          <CentroCustoField
            centroCustoSelected={formik.values?.centrosCusto || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalCentroCusto}
            toggleModal={toggleCentroCustoModal}
            areaDireitoId={formik.values?.areaDireitoId}
            tipoDespesaId={formik.values?.tipoDespesaId}
            modulo={formik.values?.modulo}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalTabela = () => {
    if (configuracaoTela?.campoTipo == ETipoCampoEnum.Tabela) {
      return (
        <>
          <FormTabelaField
            configuracaoTela={configuracaoTela}
            toggleModal={toggleTabelaModal}
            exibirModal={exibirTabela}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderAreaTexto = () => {
    if (configuracaoTela?.campoTipo == ETipoCampoEnum.AreaTexto) {
      return (
        <>
          <AreaTextoField
            configuracaoTela={configuracaoTela}
            toggleModal={toggleAreaTextoModal}
            exibirModal={exibirModalAreaTexto}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalAdvogadoInterno = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.AdvogadoInterno) {
      return (
        <>
          <AdvogadoInternoField
            advogadoInternoSelected={formik.values?.advogadoInternoList || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalAdvogadosInternos}
            toggleModal={toggleAdvogadosInternosModal}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalAdvogado = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.Advogado) {
      return (
        <>
          <AdvogadoField
            advogadoSelected={formik.values?.advogadoList || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalAdvogadosPartesContrarias}
            toggleModal={toggleAdvogadosParteContrariaModal}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalPasta = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.Pasta && formik.values.modulo == EModulo.Contrato) {
      return (
        <>
          <ContratoPastaModalPage
            togglePastaModal={togglePastaModal}
            exibirPastaModal={exibirPasta}
            formik={formik}
          />
        </>
      );
    }
    if (configuracaoTela?.campoNome == ECamposIDEnum.Pasta && formik.values.modulo == EModulo.Procuracao) {
      return (
        <>
          <ProcuracaoPastaModalPage
            togglePastaModal={togglePastaModal}
            exibirPastaModal={exibirPasta}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalPrincipalProcessoId = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.ProcessoPrincipalId && formik.values.modulo == EModulo.AreaDireito) {
      return (
        <>
          <IDModalPage
            toggleProcessoModal={toggleProcessoModal}
            exibirProcessoModal={exibirProcesso}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderClassName = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Tabela ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Audiencia ||
      configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto ||
      configuracaoTela?.campoNome === ECamposIDEnum.TipoDocumento ||
      configuracaoTela?.campoNome === ECamposIDEnum.TipoDocumentoMinuta ||
      configuracaoTela?.campoNome === ECamposIDEnum.TipoDocumentoCheckList) {
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  const renderInput = () => {
    let component = <></>;

    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
        if (configuracaoTela?.campoNome === ECamposIDEnum.GestorAreaSolicitanteApData) {
          component = (
            <>
              <Select
                value={
                  {
                    label: options.find((e) => e.value === genericId)?.label,
                    value: genericId,
                  }
                }
                onChange={(option: any) => onChangeSelect(option.value, ECamposIDEnum.Gestor)}
                options={options}
                styles={styleSelect(formik, configuracaoTela)}
              />
              {eval(`formik.touched.${returnarEnumDescricaoID(ECamposIDEnum.Gestor)}`) && eval(`formik.errors.${returnarEnumDescricaoID(ECamposIDEnum.Gestor)}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${returnarEnumDescricaoID(ECamposIDEnum.Gestor)}`)}</span>
                  </div>
                </div>

              )}
            </>
          );
        } else if (configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalCentroCusto(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione os centro de custo
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked)?.length === 0 && "Nenhum centro de custo selecionado"}
                  {formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked)?.length === 1 &&
                    `${formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked)?.length} centro de custo selecionado`}
                  {formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked)?.length > 1 &&
                    `${formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked)?.length} centro de custos selecionados`}
                </a>
              </div>
              <GridPadrao
                paginationServer={true}
                colunas={colunasCentroCusto}
                tipo="TipoCentroCusto"
                itens={formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked)}
              />
              <hr />
            </>
          );
        } else {
          component = (
            <>
              <Select
                value={
                  {
                    label: options.find((e) => e.value === genericId)?.label,
                    value: genericId,
                  }
                }
                onChange={(option: any) => onChangeSelect(option.value, configuracaoTela?.campoNome, configuracaoTela.isXCampo)}
                options={options}
                styles={styleSelect(formik, configuracaoTela)}
              />
              {eval(`formik.touched.${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`) && eval(`formik.errors.${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`)}</span>
                  </div>
                </div>
              )}
            </>
          );
        }
        break;

      case ETipoCampoEnum.ListaComLink:
        component = (
          <>
            <select
              value={genericId}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[`${returnarEnumDescricaoID(configuracaoTela?.campoNome)}`] &&
                  formik.errors[`${returnarEnumDescricaoID(configuracaoTela?.campoNome)}`], // Adicione a classe "campo-invalido" quando for inválido
              })}
              onChange={(event: any) => onChangeSelect(event.target.value, configuracaoTela?.campoNome)}
            >

              {options?.map((item: any) => {
                return (<option key={item.value} value={item.value}>{item.label}  </option>);
              })}

            </select>
            {formik.touched[returnarEnumDescricaoID(configuracaoTela?.campoNome)] && formik.errors[returnarEnumDescricaoID(configuracaoTela?.campoNome)] && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors[returnarEnumDescricaoID(configuracaoTela?.campoNome)]}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.ListaMulti:
        component = (
          <>
            <MultiSelect
              isLoading={false}
              disabled={false}
              options={genericIdMulti}
              value={genericIdMultiSelected}
              onChange={(event: any) => onChangeMultiSelect(event, configuracaoTela?.campoNome, configuracaoTela.isXCampo)}
              labelledBy={"Selecione..."}
              overrideStrings={{
                selectSomeItems: "Selecione...",
                allItemsAreSelected: "Todos selecionados",
                selectAll: "Selecione todos",
                search: "Pesquise",
                selectAllFiltered: "Selecione todos (filtrados)"
              }}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`)}</span>
              </div>
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Valor:
        component = (
          <>
            <CurrencyField
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue);
              }}
              floatValue={eval(`formik.values?.${configuracaoTela?.campoNome.toLowerCase()}`)} />

            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Percentual:
        component = (
          <>
            <NumberFormat
              format={currencyFormatterPerc}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              thousandSeparator={true}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Inteiro:

        if (configuracaoTela?.campoNome === ECamposIDEnum.Pasta) {
          component = (
            <>
              <div className="input-group">
                <input
                  {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                  placeholder={configuracaoTela.label}
                  type="number"
                  className={clsx("form-control", {
                    "form-control-error": configuracaoTela?.obrigatorio &&
                      formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                      formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                  })}
                  id={`form-${configuracaoTela?.campoNome}`}
                />
                <Button
                  onClick={() => togglePastaModal()}
                  style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                  variant="secondary"
                  size="sm"
                >
                  Pesquisar<FontAwesomeIcon color={"white"} className="mx-1" icon={faSearch} />
                </Button>
              </div>
            </>
          );
          break;
        } else if (configuracaoTela?.campoNome === ECamposIDEnum.ProcessoPrincipalId) {
          component = (
            <>
              <div className="input-group">
                <input
                  {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                  placeholder={configuracaoTela.label}
                  type="number"
                  className={clsx("form-control", {
                    "form-control-error": configuracaoTela?.obrigatorio &&
                      formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                      formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                  })}
                  id={`form-${configuracaoTela?.campoNome}`}
                />
                <Button
                  onClick={() => toggleProcessoModal()}
                  style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                  variant="secondary"
                  size="sm"
                >
                  Pesquisar<FontAwesomeIcon color={"white"} className="mx-1" icon={faSearch} />
                </Button>
              </div>
            </>
          );
          break;
        } else {
          component = (
            <>
              <input
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="number"
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                    formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                })}
                id={`form-${configuracaoTela?.campoNome}`}
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          );
        }
        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="checkbox"
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="date"
              value={moment(eval(`formik.values?.${configuracaoTela?.campoNome.toLowerCase()}`)).format("YYYY-MM-DD")}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <div className="input-group">
              <textarea
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                    formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                })}
                id={`form-${configuracaoTela?.campoNome}`} />

              <Button
                onClick={() => toggleAreaTextoModal()}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
              </Button>
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Texto:
        if (configuracaoTela?.campoNome === ECamposIDEnum.TipoProtocoloTribunal) {
          component = (
            <>
              <Select
                value={
                  {
                    label: options.find((e) => e.label === tipoProtocoloTribunal)?.label,
                    value: tipoProtocoloTribunal,
                  }
                }
                onChange={(option: any) => {
                  formik.setFieldTouched(`${configuracaoTela.campoNome.toLowerCase()}`, true);
                  formik.setFieldValue(`${configuracaoTela.campoNome.toLowerCase()}`, option.label);
                  setTipoProtocoloTribunal(option.label);
                }}
                options={options}
                styles={styleSelect(formik, configuracaoTela)}
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          );
        }
        else if (configuracaoTela?.campoNome === ECamposIDEnum.NumeroProcesso && formik.values?.esferaId == 1) {
          component = (
            <>
              <MaskedInput
                mask={MASCARA_NUMERO_PROCESSO}
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder="NNNNNNN-DD.AAAA.J.TR.OOOO"
                type="text"
                onBlur={() => validarNumeroProcessos()}
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                    formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                })}
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          )
          break;
        }
        else if (configuracaoTela?.campoNome === ECamposIDEnum.NumeroProcesso) {
          component = (
            <>
              <input
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                onBlur={() => validarNumeroProcessos()}
                className={
                  clsx("form-control", {
                    "form-control-error": configuracaoTela?.obrigatorio &&
                      formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                      formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                  })
                }
                id={`form-${configuracaoTela?.campoNome}`}
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          )
          break;
        }
        else {
          component = (
            <>
              <input
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                className={
                  clsx("form-control", {
                    "form-control-error": configuracaoTela?.obrigatorio &&
                      formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                      formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                  })
                }
                id={`form-${configuracaoTela?.campoNome}`}
              />
              {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                  </div>
                </div>
              )}
            </>
          )
        }
        break;

      case ETipoCampoEnum.Tabela:
        component = (
          <>
            <Button
              onClick={() => setExibirTabela(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faTable} />
              {configuracaoTela.label}
              {configuracaoTela.obrigatorio && "*"}
              {configuracaoTela.help && <PopoverHelper />}
            </Button>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length === 0 && "Nenhum campos selecionado"}
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length === 1 &&
                  `${formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length} campos selecionados`}
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length > 1 &&
                  `${formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length} campos selecionados`}
              </a>
            </div>
            {formik?.values[`${configuracaoTela.campoNome?.toLowerCase()}`]?.length > 0 &&
              <>
                <FormListTabelaField configuracaoTela={configuracaoTela} formik={formik} />
              </>
            }
            <hr />
          </>
        );
        break;

      case ETipoCampoEnum.Empresa:
        component = (
          <>
            <Button
              onClick={() => setExibirModalEmpresas(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faBuilding} />
              Selecione empresas
            </Button>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length === 0 && "Nenhum empresa selecionada"}
                {formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length === 1 &&
                  `${formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length} empresa selecionada`}
                {formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length > 1 &&
                  `${formik.values?.empresasList?.filter((e: IEmpresa) => e.checked).length} empresas selecionadas`}
              </a>
            </div>
            <GridPadrao
              paginationServer={true}
              colunas={colunasEmpresa}
              tipo="Empresa"
              itens={formik.values?.empresasList?.filter((e: IEmpresa) => e.checked)}
            />
            <hr />
          </>
        );
        break;

      case ETipoCampoEnum.Pessoa:
        if (configuracaoTela?.campoNome === ECamposIDEnum.AdvogadoInterno) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalAdvogadosInternos(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione os advogados(a)s Internos{"  "}
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.advogadoInternoList?.filter((e: IAdvogadoInterno) => e.checked).length === 0 && "Nenhum advogado selecionado"}
                  {formik.values?.advogadoInternoList?.filter((e: IAdvogadoInterno) => e.checked).length === 1 &&
                    `${formik.values?.advogadoInternoList?.filter((e: IAdvogadoInterno) => e.checked).length} advogado selecionado`}
                  {formik.values?.advogadoInternoList?.filter((e: IAdvogadoInterno) => e.checked).length > 1 &&
                    `${formik.values?.advogadoInternoList?.filter((e: IAdvogadoInterno) => e.checked).length} advogados selecionados`}
                </a>
              </div>
              <GridPadrao
                paginationServer={true}
                colunas={colunasAdvogadoInterno}
                tipo="AdvogadoInterno"
                itens={formik.values?.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked)}
              />
              <hr />
            </>
          );
        }
        if (configuracaoTela?.campoNome === ECamposIDEnum.Advogado) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalAdvogadosPartesContrarias(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione os advogados(a)s das partes contrárias
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.advogadoList?.filter((e: any) => e.checked).length === 0 && "Nenhum advogado selecionado"}
                  {formik.values?.advogadoList?.filter((e: any) => e.checked).length === 1 &&
                    `${formik.values?.advogadoList?.filter((e: any) => e.checked).length} advogado selecionado`}
                  {formik.values?.advogadoList?.filter((e: any) => e.checked).length > 1 &&
                    `${formik.values?.advogadoList?.filter((e: any) => e.checked).length} advogados selecionados`}
                </a>
              </div>
              <GridPadrao
                paginationServer={true}
                colunas={colunasAdvogado}
                tipo="Advogado"
                itens={formik.values?.advogadoList?.filter((e: any) => e.checked)}
              />
              <hr />
            </>
          );
        }
        if (configuracaoTela?.campoNome === ECamposIDEnum.ParteContraria) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalPartesContrarias(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione as partes contrárias
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.partesContrariasList?.length === 0 && "Nenhum parte contrária selecionada"}
                  {formik.values?.partesContrariasList?.length === 1 && `${formik.values?.partesContrariasList?.length} parte contrária selecionada`}
                  {formik.values?.partesContrariasList?.length > 1 && `${formik.values?.partesContrariasList?.length} partes contrárias selecionadas`}
                </a>
              </div>
              <GridPadrao paginationServer={true} colunas={colunasParteContraria} tipo="ParteContraria" itens={formik.values?.partesContrariasList} />
              <hr />
            </>
          );
        }
        break;

      case ETipoCampoEnum.Audiencia:
        component = (
          <>
            <Button
              onClick={() => setExibirModalAudiencia(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
              Informar dados da audiência
            </Button>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {"  "}
                {formik.values?.audienciaList?.length === 0 && "Nenhum audiência selecionada"}
                {formik.values?.audienciaList?.length === 1 && `${formik.values?.audienciaList?.length} audiência selecionada`}
                {formik.values?.audienciaList?.length > 1 && `${formik.values?.audienciaList?.length} audiências selecionadas`}
              </a>
            </div>
            <AudienciaCadastroModal
              exibirModal={exibirModalAudiencia}
              toggleModal={toggleAudienciaModal}
              formik={formik}
            />
            <GridPadrao paginationServer={true} colunas={colunasAudiencia} tipo="Audiência" itens={formik.values?.audienciaList} />
          </>
        )
        break;

    }

    return component;
  };

  const definirInput = () => {
    let component: any[] = [];

    switch (configuracaoTela.campoNome) {

      case ECamposIDEnum.Estado:
        component.push(<><div className="col-md-3 mt-3"><EstadoField configuracaoTela={configuracaoTela} formik={formik} ></EstadoField> </div ></>);
        break;

      case ECamposIDEnum.Cidade:
        component.push(<><div className="col-md-3 mt-3"><CidadeField configuracaoTela={configuracaoTela} formik={formik} ></CidadeField> </div ></>);
        break;

      case ECamposIDEnum.TipoDocumento:
        component.push(<IFileListField configuracaoTela={configuracaoTela} formik={formik} modulo={formik.values?.modulo} />);
        break;

      case ECamposIDEnum.TipoDocumentoCheckList:
        component.push(<IFileCheckListField configuracaoTela={configuracaoTela} formik={formik} />);
        break;

      case ECamposIDEnum.TipoDocumentoMinuta:
        component.push(<IFileListField configuracaoTela={configuracaoTela} formik={formik} modulo={formik.values?.modulo} />);
        break;

      default:


        component.push(
          <>
            {renderAreaTexto()}
            {renderModalPasta()}
            {renderModalTabela()}
            {renderModalEmpresa()}
            {renderModalAdvogado()}
            {renderModalCentroCusto()}
            {renderModalParteContraria()}
            {renderModalAdvogadoInterno()}
            {renderModalPrincipalProcessoId()}

            <div className={renderClassName()}>
              <label className="form-label fw-bolder text-orange">
                {configuracaoTela.label}{configuracaoTela.campoNome == ECamposIDEnum.CEP && <PopoverHelperEndereco />}
                {configuracaoTela.obrigatorio ? "*:" : ":"}
                {configuracaoTela.help && <PopoverHelper />}
              </label>
              <div className="row">
                <div className="col-md-12">{renderInput()}
                </div>
              </div>
            </div>
          </>);
    }

    return <>{component}</>
  }

  return (exibirCampo ? <>{definirInput()} </> : <></>);
};

export default CamposCadastroDinamicos;