/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Draggable } from "react-beautiful-dnd";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { Modal } from "react-bootstrap";

import { EModulo } from "../../../../enum";
import { ETelaEnum } from "../../../../enum/ETelaEnum";
import { tipoPorModulo } from "../../../../utils/Utils";
import { ETipoCampoEnum } from "../../../../enum/ETipoCampoEnum";

import { ICampo } from "../../../../interfaces/ICampo";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";

import ConfiguracaoTelaService from "../../../../services/ConfiguracaoTelaService";
import LimitacaoTabelaService from "../../../../services/LimitacaoTabelaService";

import ListaSelected from "../../../../components/Comum/ListaCamposSolucoesField";
import { OrangeContext } from "../../../../contexts/OrangeProvider";
import { camelizar } from "../../Empresa/utils";

interface ItemProps {
  alterarItem(item: ICampo): void;
  item: ICampo;
  index: number;
  col: string;
}

const buscarEstilo = (isDragging: boolean, draggableStyle: any) => ({
  padding: 10,
  margin: `0 50px 15px 40px`,
  background: isDragging ? "var(--primary-base)" : "white",
  color: isDragging ? "white" : "black",
  border: `1px solid`,
  fontSize: `12px`,
  borderRadius: `5px`,
  cursor: "pointer",
  ...draggableStyle,
});

const FormECampo: React.FC<ItemProps> = ({ item, index, alterarItem, col }) => {
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoListaLimitadaCadastro, setCarregandoListaLImitadaCadastro] = useState<boolean>(false);
  const [adicionarListaLImitadaCadastro, setAdicionarListaLImitadaCadastro] = useState<boolean>(false);

  const { tela, tipoComunicacao, campos, setCampos, eCampos } = useContext<OrangeInterfaceContext>(OrangeContext);
  const valoresIniciais = {
    campo: 0,
    entidade: item.entidade,
    campoNome: item.campoNome,
    label: "",
    help: "",
    obrigatorio: false,
    somenteLeitura: false,
    ordenacao: item.ordenacao,
    tipo: item.tipo,
    nome: "",
    Lista: [],
    isXCampo: false,
  } as ICampo;

  useEffect(() => {
    if (!carregandoListaLimitadaCadastro && exibirModal && formik.values.tipo === ETipoCampoEnum.Lista)
      carregarListaLimitadaCadastroPorCampo(item.entidade);
  }, [exibirModal]);


  const getOpcoesId = (Lista: any, entidade: string): number[] => {
    const OpcoesId: any[] = [];
    if (Lista && Lista.length > 0) {
      Lista.forEach((item: any) => {
        if (item) OpcoesId.push(eval(`item.${camelizar(entidade)}Id`));
      });
    }
    return OpcoesId;
  };

  const formik = useFormik({
    initialValues: valoresIniciais,
    onSubmit: async (values, { setSubmitting }) => {
      alterarItem(values);

      campos.splice(values.ordenacao, 0, { ...values });

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(tipoComunicacao.modulo)}Id=${tipoComunicacao.categoriaId}`);

      LimitacaoTabelaService.adicionaListaLimitadaSolucoes({
        ...objeto,
        modulo: EModulo.Comunicacao,
        campo: item.campo,
        opcaoId: getOpcoesId(formik.values.Lista, item.entidade),
        tipoComunicacaoId: tipoComunicacao.opcaoId
      });

      formik.setValues(valoresIniciais);

      setSubmitting(false);
    },
  });

  const verificarExisteListaSelecionada = (entidade: string) => {
    if (
      campos &&
      campos.filter((e) => e.entidade === entidade) &&
      campos.filter((e) => e.entidade === entidade)[0] &&
      (campos.filter((e) => e.entidade === entidade)[0].Lista || []).length > 0
    ) {
      formik.values.Lista = campos.filter((e) => e.entidade === entidade)[0].Lista;
      return true;
    }
    return false;
  };

  async function carregarListaLimitadaCadastroPorCampo(entidade: string) {
    try {

      if (verificarExisteListaSelecionada(entidade)) return;

      let resultado: IRecuperaLista<any>;
      let objeto: any = {};
      eval(`objeto.${tipoPorModulo(tipoComunicacao.modulo)}Id=${tipoComunicacao.categoriaId}`);

      setCarregandoListaLImitadaCadastro(true);

      resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastro({
        ...objeto,
        tela: tela,
        campo: item.campo,
        modulo: tipoComunicacao.modulo,
        tipoComunicacaoId: tipoComunicacao.opcaoId
      },
        entidade,
        EModulo.Comunicacao);
      formik.values.Lista = resultado.data;
      setCarregandoListaLImitadaCadastro(false);

    } catch (error: any) {
      setCarregandoListaLImitadaCadastro(false);
    }
  }

  const preencherCampos = () => {
    if (tela === ETelaEnum.TelaCadastroComunicacao) {
      item.entidade = eCampos.filter((e: any) => e.campo === item.campo)[0]?.entidade;
      item.tipo = eCampos.filter((e: any) => e.campo === item.campo)[0]?.tipo;
    }
    formik.setValues(item);
    setExibirModal(!exibirModal);
  };

  return (
    <>
      <Draggable key={item?.campo} draggableId={item?.campo?.toString()} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && <>{item?.label}</>}
                {carregandoListaLimitadaCadastro && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                onClick={() => preencherCampos()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && !adicionarListaLImitadaCadastro && (
                  <>
                    {item?.label} {item?.obrigatorio && "*"}
                  </>
                )}
                {(carregandoListaLimitadaCadastro || adicionarListaLImitadaCadastro) && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          )
        }
      </Draggable>

      <Modal size="lg" centered={true} show={exibirModal && !carregandoListaLimitadaCadastro} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Alterar Campo: {formik.values.campoNome} - Label: {formik.values.label}</h5>
            <div
              onClick={() => {
                formik.setValues(valoresIniciais);
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form>
              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Nome
                  </label>
                  <input placeholder="Nome" {...formik.getFieldProps("label")} type="text" className={"form-control"}></input>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Texto de Ajuda
                  </label>
                  <input placeholder={formik.values.help} {...formik.getFieldProps("help")} type="text" className={"form-control"}></input>
                </div>
              </div>

              {tela === ETelaEnum.TelaCadastroComunicacao && (
                <div className="row mt-4">
                  <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                    Obrigatório:{" "}
                    <FormCheckInput
                      type="checkbox"
                      style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                      checked={formik.values.obrigatorio}
                      onChange={(e: any) => {
                        if (formik.values.obrigatorio) formik.setValues({ ...formik.values, obrigatorio: false });
                        if (!formik.values.obrigatorio) formik.setValues({ ...formik.values, obrigatorio: true });
                      }}
                    />
                  </label>
                </div>
              )}
              {tela === ETelaEnum.TelaCapaComunicacao && (
                <div className="row mt-4">
                  <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                    Somente Leitura:{" "}
                    <FormCheckInput
                      type="checkbox"
                      style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                      checked={formik.values.somenteLeitura}
                      onChange={(e) => {
                        if (formik.values.somenteLeitura) formik.setValues({ ...formik.values, somenteLeitura: false });
                        if (!formik.values.somenteLeitura) formik.setValues({ ...formik.values, somenteLeitura: true });
                      }}
                    />
                  </label>
                </div>
              )}

              <div className="row mt-4">
                {tela === ETelaEnum.TelaCadastroComunicacao && formik.values.tipo === ETipoCampoEnum.Lista && (
                  <>
                    <ListaSelected
                      ListaSelected={formik.values.Lista || []}
                      tipoId={tipoComunicacao.tipoComunicacaoId || 0}
                      entidade={formik.values.entidade}
                      setFieldValue={formik.setFieldValue}
                    ></ListaSelected>
                  </>
                )}
              </div>
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            {formik.values.tipo === ETipoCampoEnum.Lista ? (
              <button
                disabled={formik.values.Lista?.length === 0}
                onClick={() => {
                  setAdicionarListaLImitadaCadastro(true);
                  setCampos(campos.filter((e) => e.campo !== formik.values.campo));
                  formik.submitForm();
                  setExibirModal(!exibirModal);
                  setAdicionarListaLImitadaCadastro(false);
                }}
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            ) : (
              <button
                onClick={() => {
                  alterarItem(formik.values);
                  setExibirModal(!exibirModal);
                }}
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            )}

            <button
              onClick={() => {
                setExibirModal(!exibirModal);
                formik.setValues(valoresIniciais);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormECampo;
