import { faBalanceScale, faExchangeAlt, faFolderOpen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Popover, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { EModulo } from "../../../enum";
import { despesaInicialValues, propostaAcordoInicialValues } from "../../../contexts/InitialValuesContext";

export const PopoverSubMenuImovel = (isProcesso?: boolean) => {
  const navigate = useNavigate();

  const {
    setPropostaAcordo,
    setDespesa,

    garantia,
    setGarantia,

    imovel } = useContext<OrangeInterfaceContext>(OrangeContext);
  const tooltip = (title: string) => <Tooltip id={`button-tooltip-${title}`}> {title} </Tooltip>

  return (
    <Popover id="popover-basic">
      <Popover.Body style={{ cursor: "pointer" }}>
        <p>

          <OverlayTrigger placement="bottom" overlay={tooltip("Capa do Imóvel")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Imovel/Capa")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
              </Button>
            )}
          </OverlayTrigger>

          {isProcesso &&
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Novo Processo</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  {...triggerHandler}
                  onClick={() => navigate("/Imovel/Processo/Novo")}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref}>
                    <FontAwesomeIcon color={"var(--primary-base2)"} className="mx5" icon={faPlus} />
                  </span>
                </Button>
              )}
            </OverlayTrigger>
          }



          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Processos</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Imovel/Processo")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faBalanceScale}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>


          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Contrato</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Contrato/ContratoPastaImovelPage")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faFolderOpen}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>


          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Despesa</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                onClick={() => {
                  setDespesa({
                    ...despesaInicialValues,
                    modulo: EModulo.Imovel,
                    imovelId: imovel.imovelId,
                    tipoImovelId: imovel.tipoImovelId,
                    categoriaId: imovel.tipoImovelId,
                  });
                  navigate("/Despesa");
                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-despesa"></span>
              </Button>
            )}
          </OverlayTrigger>


        </p>
        <p>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Logs</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Imovel/Logs")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faExchangeAlt}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Proposta</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setPropostaAcordo({
                    ...propostaAcordoInicialValues,
                    modulo: EModulo.Imovel,
                    imovelId: imovel.imovelId,
                    tipoImovelId: imovel.tipoImovelId,
                    categoriaId: imovel.tipoImovelId,
                  });

                  navigate("/PropostaAcordo");
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-proposta"></span>
              </Button>
            )}
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Garantias</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setGarantia({
                    ...garantia,
                    processoId: 0,
                    modulo: EModulo.Imovel,
                    imovelId: imovel.imovelId,
                    tipoImovelId: imovel.tipoImovelId,
                    categoriaId: imovel.tipoImovelId,
                  });
                  navigate("/Garantia")
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-garantia"></span>
              </Button>
            )}
          </OverlayTrigger>


        </p>
      </Popover.Body>
    </Popover>
  );
};
