import { useEffect, useState } from "react";
import { faBuilding, faEdit, faQuestionCircle, faTable, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { MultiSelect } from "react-multi-select-component";
import { TableColumn } from "react-data-table-component";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import moment from "moment";
import clsx from "clsx";

import { currencyFormatterPerc } from "../../utils/Utils";
import { MASCARA_NUMERO_PROCESSO } from "../../utils/Constants";
import { EModulo, ETelaEnum, ETipoCampoEnum, ECamposIDEnum, returnarEnumDescricaoID } from "../../enum";

import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import IEmpresa from "../../interfaces/IEmpresa";
import ICentroCusto from "../../interfaces/ICentroCusto";
import IAdvogadoInterno from "../../interfaces/IAdvogadoInterno";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import GridPadrao from "../Comum/GridPadrao";
import AdvogadoField from "../Advogados/AdvogadoField";
import EmpresaField from "../Comum/Empresa/EmpresaField";
import CurrencyField from "../Comum/CurrencyBRL/CurrencyField";
import AdvogadoInternoField from "../Advogados/AdvogadoInternoField";
import CentroCustoField from "../Comum/CentroCusto/CentroCustoField";
import ParteContrariaField from "../Comum/ParteContraria/ParteContrariaField";
import FormListTabelaField from "../Comum/TabelaField/Modulo/FormListTabelaField";
import FormFluxoTabelaField from "../Comum/TabelaField/Fluxo/FormFluxoTabelaField";
import FormListFluxoTabelaField from "../Comum/TabelaField/Fluxo/FormFluxoListTabelaField";
import AreaTextoField from "../Comum/AreaTexto/AreaTextoField";

interface ICamposCadastroDinamicoProps {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  configuracaoTelaLista: IRecuperaConfiguracaoTelaResponse[];
  formik: any;
}

const CamposCadastroDinamico = ({ configuracaoTela, formik, configuracaoTelaLista }: ICamposCadastroDinamicoProps) => {
  const [campos, setCampos] = useState<any[]>([]);

  const [genericId, setGenericId] = useState<number>(0);
  const [genericIdMulti, setGenericIdMulti] = useState<any>([]);
  const [genericIdMultiSelected, setGenericIdMultiSelected] = useState([]);

  const [exibirCampo, setExibirCampo] = useState<boolean>(true);
  const [exibirTabela, setExibirTabela] = useState<boolean>(false);
  const [exibirModalEmpresas, setExibirModalEmpresas] = useState<boolean>(false);
  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);
  const [exibirModalCentroCusto, setExibirModalCentroCusto] = useState<boolean>(false);
  const [exibirModalPartesContrarias, setExibirModalPartesContrarias] = useState<boolean>(false);
  const [exibirModalAdvogadosInternos, setExibirModalAdvogadosInternos] = useState<boolean>(false);
  const [exibirModalAdvogadosPartesContrarias, setExibirModalAdvogadosPartesContrarias] = useState<boolean>(false);

  const toggleTabelaModal = () => setExibirTabela(!exibirTabela);
  const toggleEmpresaModal = () => setExibirModalEmpresas(!exibirModalEmpresas);
  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);
  const toggleCentroCustoModal = () => setExibirModalCentroCusto(!exibirModalCentroCusto);
  const toggleParteContrariaModal = () => setExibirModalPartesContrarias(!exibirModalPartesContrarias);
  const toggleAdvogadosInternosModal = () => setExibirModalAdvogadosInternos(!exibirModalAdvogadosInternos);
  const toggleAdvogadosParteContrariaModal = () => setExibirModalAdvogadosPartesContrarias(!exibirModalAdvogadosPartesContrarias);

  const options = async () => {
    try {
      if (configuracaoTela.isXCampo) {

        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
          fluxoFaseId: formik.values?.fluxoFaseId,
          tela: ETelaEnum.TelaConcluirFase,
          campo: configuracaoTela.campo,
          modulo: EModulo.Compromisso
        });

        setCampos(campos.data);

      } else {

        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
          configuracaoTela?.campoNome,
          EModulo.Compromisso,
          formik.values?.fluxoFaseId);

        setCampos(campos.data);
      }
    } catch (error: any) {
      setCampos([]);
    }
  };

  const optionsMulti = async () => {
    try {

      let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        configuracaoTela?.campoNome,
        EModulo.Compromisso,
        formik.values?.fluxoFaseId);

      campos.data.forEach((item: any) => {
        genericIdMulti.push({ value: item[`${returnarEnumDescricaoID(configuracaoTela.campoNome, configuracaoTela.isXCampo)}`], label: item.nome });
      });


    } catch (error: any) {
      setGenericIdMulti([]);
    }
  };


  const carregarPartes = async () => {
    try {

      let resultado = await ConfiguracaoTelaService.obterPartes(formik.values.modulo, formik.values.principalId);

      formik.setFieldValue(`partesProcesso`, resultado.data);

      formik.setFieldValue(`empresasList`, resultado.data?.filter((e: any) => e.tipo == 'empresa'));

      formik.setFieldValue(`partesContrariasList`, resultado.data?.filter((e: any) => e.tipo == 'parteContraria'));


    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter campos lista`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    if (configuracaoTela.campoTipo == ETipoCampoEnum.Lista) options();

    if (configuracaoTela.campoTipo == ETipoCampoEnum.ListaMulti) optionsMulti();

    if (
      configuracaoTela.campoNome == ECamposIDEnum.Empresa ||
      configuracaoTela.campoNome == ECamposIDEnum.ParteContraria ||
      configuracaoTela.campoNome == ECamposIDEnum.EmpresaRegulatorio ||
      configuracaoTela.campoNome == ECamposIDEnum.ParteContraria ||
      configuracaoTela.campoNome == ECamposIDEnum.ParteContrariaSemDocumento
    ) {
      carregarPartes();
      return;
    }

  }, [formik.values?.fluxoFaseId]);


  useEffect(() => {
    if (!configuracaoTela.campoCondicionadoEnable) return;

    const campoCondicionado = configuracaoTelaLista?.find(e => e.campo === configuracaoTela.camposCondicionadoId);

    if (campoCondicionado?.campoNome) {
      const campoCondicionadoId = formik.values[`${returnarEnumDescricaoID(campoCondicionado.campoNome, campoCondicionado.isXCampo)}`] || campoCondicionado.valorId;
      const opcaoEncontrada = configuracaoTela.campoCondicionadoOpcoes?.some(campoOpcao => campoOpcao.opcaoId === campoCondicionadoId);

      configuracaoTela.obrigatorio = !!opcaoEncontrada;
      setExibirCampo(!!opcaoEncontrada);
    }
  }, [formik.values, configuracaoTela, configuracaoTelaLista]);


  const onChangeSelect = (value: string, label: string, campoNome: string, isXCampo: boolean) => {

    formik.setFieldTouched(returnarEnumDescricaoID(campoNome, isXCampo));

    formik.setFieldValue(returnarEnumDescricaoID(campoNome, isXCampo), parseInt(value));

    formik.setFieldValue(`${returnarEnumDescricaoID(campoNome, isXCampo)}Nome`, label);

    setGenericId(parseInt(value));
  };
  
  const onChangeMultiSelect = (event: any, campoNome: string, isXCampo: boolean) => {
    // Captura os textos (labels) das opções selecionadas
    const selectedLabels = Array.from(event).map((option: any) => option.label);
  
    // Marca o campo como tocado no Formik
    formik.setFieldTouched(returnarEnumDescricaoID(campoNome, isXCampo), true);
  
    // Define os valores selecionados no campo correspondente
    formik.setFieldValue(returnarEnumDescricaoID(campoNome, isXCampo), event);
  
    // Define os nomes dos itens selecionados concatenados em um campo "Nome"
    formik.setFieldValue(
      `${returnarEnumDescricaoID(campoNome, isXCampo)}Nome`, 
      selectedLabels.join(', ') // Concatena os textos para exibir corretamente como uma string separada por vírgulas
    );
  
    // Atualiza o estado com os IDs das opções selecionadas
    setGenericIdMultiSelected(event);
  };
  
  const colunasCentroCusto: TableColumn<ICentroCusto>[] = [
    {
      name: "Id",
      sortField: "tipoCentroCustoId",
      selector: (row: ICentroCusto) => row.tipoCentroCustoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: ICentroCusto) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Percentual",
      sortField: "percentual",
      selector: (row: ICentroCusto) => (row.percentual ? row.percentual + "%" : "0%"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAdvogadoInterno: TableColumn<IAdvogadoInterno>[] = [
    {
      name: "Id",
      sortField: "advogadoInternoId",
      selector: (row: any) => row.advogadoInternoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal == true ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAdvogado: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "advogadoId",
      selector: (row: any) => row.advogadoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "pessoaNome",
      selector: (row: any) => row.pessoaNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "OAB",
      sortField: "oab",
      selector: (row: any) => row.oab,
      sortable: true,
      wrap: true,
    },
  ];

  const colunasParteContraria: TableColumn<any>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Documento",
      sortField: "documento",
      selector: (row: any) => row.documento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumento",
      selector: (row: any) => row.tipoDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacaoNome",
      selector: (row: any) => formik.values.modulo == EModulo.Contrato ? row.papelSignatarioNome || row.papelSignatario : (row.formaParticipacaoNome || row.formaParticipacao),
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasEmpresa: TableColumn<IEmpresa>[] = [
    {
      name: "Id",
      sortField: "empresaId",
      selector: (row: IEmpresa) => row.empresaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nomeFantasia",
      selector: (row: IEmpresa) => row.nomeFantasia || row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "CNPJ",
      sortField: "numeroDocumento",
      selector: (row: IEmpresa) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: IEmpresa) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const renderInput = () => {
    let component = <></>;

    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
        if (configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalCentroCusto(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione os centro de custo
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked).length === 0 && "Nenhum centro de custo selecionado"}
                  {formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked).length === 1 &&
                    `${formik.values?.centrosCusto.filter((e: ICentroCusto) => e.checked).length} centro de custo selecionado`}
                  {formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked).length > 1 &&
                    `${formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked).length} centro de custos selecionados`}
                </a>
              </div>
              <GridPadrao
                paginationServer={true}
                colunas={colunasCentroCusto}
                tipo="TipoCentroCusto"
                itens={formik.values?.centrosCusto?.filter((e: ICentroCusto) => e.checked)}
              />
              <hr />
            </>
          );
        } else {
          component = (
            <>
              <select
                value={genericId}
                className={clsx("form-control", {
                  "border border-danger": configuracaoTela.obrigatorio,
                })}
                onChange={(e) => onChangeSelect(e.target.value, e.target.selectedOptions[0].text, configuracaoTela?.campoNome, configuracaoTela.isXCampo)}
                >
                <option value="0"> Selecione </option>
                {configuracaoTela.isXCampo ?
                  campos?.map((item: any) => {
                    return (
                      <option key={item[`xCampoOpcaoId`]} value={item[`xCampoOpcaoId`]}>
                        {item.nome}
                      </option>
                    );
                  }) :
                  campos?.map((item: any) => {
                    return (
                      <option key={item[`${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`]} value={item[returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)]}>
                        {item.nome}
                      </option>
                    );
                  })}
              </select>
              {formik.touched[`${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`] || formik.errors[`${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`] && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors[`${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`]}</span>
                  </div>
                </div>
              )}
            </>
          );
        }
        break;

      case ETipoCampoEnum.ListaMulti:
        component = (
          <>
            <MultiSelect
              isLoading={false}
              disabled={false}
              options={genericIdMulti}
              value={genericIdMultiSelected}
              onChange={(event: any) => onChangeMultiSelect(event, configuracaoTela?.campoNome, configuracaoTela.isXCampo)}
              labelledBy={"Selecione..."}
              overrideStrings={{
                selectSomeItems: "Selecione...",
                allItemsAreSelected: "Todos selecionados",
                selectAll: "Selecione todos",
                search: "Pesquise",
                selectAllFiltered: "Selecione todos (filtrados)"
              }}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`)}</span>
              </div>
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Valor:
        component = (
          <>
            <CurrencyField
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue);
              }}
              floatValue={eval(`formik.values?.${configuracaoTela?.campoNome.toLowerCase()}`)} />

            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Percentual:
        component = (
          <>
            <NumberFormat
              format={currencyFormatterPerc}
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              thousandSeparator={true}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Inteiro:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="number"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="checkbox"
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="date"
              value={moment(eval(`formik.values?.${configuracaoTela?.campoNome.toLowerCase()}`)).format("YYYY-MM-DD")}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) || eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <div className="input-group">
              <textarea
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                    formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                })}
                id={`form-${configuracaoTela?.campoNome}`} />

              <Button
                onClick={() => toggleAreaTextoModal()}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
              </Button>
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Texto:
        component = (
          <>
            {configuracaoTela?.campoNome === ECamposIDEnum.NumeroProcesso && formik.values?.esferaId == 1 ? (
              <MaskedInput
                mask={MASCARA_NUMERO_PROCESSO}
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder="NNNNNNN-DD.AAAA.J.TR.OOOO"
                type="text"
                className={clsx("form-control", {
                  "border border-danger 2px solid": configuracaoTela.obrigatorio,
                })}
              />
            ) : (

              <input
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                className={clsx("form-control", {
                  "border border-danger": configuracaoTela.obrigatorio,
                })}
                id={`form-${configuracaoTela?.campoNome}`}
              />
            )}
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors?.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Empresa:
        component = (
          <>
            <Button
              onClick={() => setExibirModalEmpresas(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faBuilding} />
              Selecione empresas
            </Button>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {formik.values?.empresasList.filter((e: IEmpresa) => e.checked).length === 0 && "Nenhum empresa selecionada"}
                {formik.values?.empresasList.filter((e: IEmpresa) => e.checked).length === 1 &&
                  `${formik.values?.empresasList.filter((e: IEmpresa) => e.checked).length} empresa selecionada`}
                {formik.values?.empresasList.filter((e: IEmpresa) => e.checked).length > 1 &&
                  `${formik.values?.empresasList.filter((e: IEmpresa) => e.checked).length} empresas selecionadas`}
              </a>
            </div>
            <GridPadrao
              paginationServer={true}
              colunas={colunasEmpresa}
              tipo="Empresa"
              itens={formik.values?.empresasList}
            />
            <hr />
          </>
        );
        break;

      case ETipoCampoEnum.Pessoa:
        if (configuracaoTela?.campoNome === ECamposIDEnum.AdvogadoInterno) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalAdvogadosInternos(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione os advogados(a)s Internos{"  "}
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked).length === 0 && "Nenhum advogado selecionado"}
                  {formik.values?.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked).length === 1 &&
                    `${formik.values?.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked).length} advogado selecionado`}
                  {formik.values?.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked).length > 1 &&
                    `${formik.values?.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked).length} advogados selecionados`}
                </a>
              </div>
              <GridPadrao
                paginationServer={true}
                colunas={colunasAdvogadoInterno}
                tipo="AdvogadoInterno"
                itens={formik.values?.advogadoInternoList.filter((e: IAdvogadoInterno) => e.checked)}
              />
              <hr />
            </>
          );
        }
        if (configuracaoTela?.campoNome === ECamposIDEnum.Advogado) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalAdvogadosPartesContrarias(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione os advogados(a)s das partes contrárias
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.advogadoList.filter((e: any) => e.checked).length === 0 && "Nenhum advogado selecionado"}
                  {formik.values?.advogadoList.filter((e: any) => e.checked).length === 1 &&
                    `${formik.values?.advogadoList.filter((e: any) => e.checked).length} advogado selecionado`}
                  {formik.values?.advogadoList.filter((e: any) => e.checked).length > 1 &&
                    `${formik.values?.advogadoList.filter((e: any) => e.checked).length} advogados selecionados`}
                </a>
              </div>
              <GridPadrao
                paginationServer={true}
                colunas={colunasAdvogado}
                tipo="Advogado"
                itens={formik.values?.advogadoList.filter((e: any) => e.checked)}
              />
              <hr />
            </>
          );
        }
        if (configuracaoTela?.campoNome === ECamposIDEnum.ParteContraria) {
          component = (
            <>
              <Button
                onClick={() => setExibirModalPartesContrarias(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Selecione as partes contrárias
              </Button>
              <div className="row mt-2">
                <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                  {"  "}
                  {formik.values?.partesContrariasList.length === 0 && "Nenhum parte contrária selecionada"}
                  {formik.values?.partesContrariasList.length === 1 && `${formik.values?.partesContrariasList.length} parte contrária selecionada`}
                  {formik.values?.partesContrariasList.length > 1 && `${formik.values?.partesContrariasList.length} partes contrárias selecionadas`}
                </a>
              </div>
              <GridPadrao paginationServer={true} colunas={colunasParteContraria} tipo="ParteContraria" itens={formik.values?.partesContrariasList} />
              <hr />
            </>
          );
        }
        break;

      case ETipoCampoEnum.Tabela:
        component = (
          <>
            <Button
              onClick={() => setExibirTabela(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faTable} />
              {configuracaoTela.label}
              {configuracaoTela.obrigatorio && "*"}
              {configuracaoTela.help && <PopoverHelper />}
            </Button>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length === 0 && "Nenhum campos selecionado"}
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length === 1 &&
                  `${formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length} campos selecionados`}
                {formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length > 1 &&
                  `${formik.values[`${configuracaoTela.campoNome.toLowerCase()}`]?.length} campos selecionados`}
              </a>
            </div>
            {formik?.values[`${configuracaoTela.campoNome?.toLowerCase()}`]?.length > 0 &&
              <>
                <FormListFluxoTabelaField configuracaoTela={configuracaoTela} formik={formik} />
              </>
            }
            <hr />
          </>
        );
    }

    return component;
  };

  const renderLabel = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
        if (configuracaoTela?.campoNome == ECamposIDEnum.CentroCusto) {
          component = (
            <>
              <div className="row mt-2">
                <div className="col-md-12">
                  <GridPadrao paginationServer={true} colunas={colunasCentroCusto} tipo="TipoCentroCusto" itens={formik.values.centrosCusto} />{" "}
                </div>
              </div>
            </>
          );
        } else {
          component = <b>{configuracaoTela.valor}</b>;
        }
        break;

      case ETipoCampoEnum.Percentual:
        if (configuracaoTela.valor) {
          const formattedValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
            .formatToParts(configuracaoTela.valor)
            .filter((part) => part.type !== 'currency')
            .map((part) => part.value)
            .join('');

          component = <b>{formattedValue}%</b>;
        } else {
          component = <b>0,00%</b>;
        }
        break;

      case ETipoCampoEnum.Valor:
        if (configuracaoTela.valor) {
          component = <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configuracaoTela.valor)}</b>;
        } else {
          component = <b>0,00</b>
        }
        break;

      case ETipoCampoEnum.Data:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Texto:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.AreaTexto:
        // eslint-disable-next-line no-case-declarations
        let value = configuracaoTela?.valor || '';
        component = <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
          {value.length > 200 ? (<>
            {value.substring(0, 200)}...<span onClick={() => {
              formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
              toggleAreaTextoModal();
            }}
              style={{ color: 'orange', cursor: 'pointer' }}>
              {!exibirModalAreaTexto && 'ler mais '}
            </span>
          </>) : <p>{value}</p>}
        </p>;
        break;

      case ETipoCampoEnum.Boolean:
        component = <b>{configuracaoTela.valor ? "Sim" : "Não"}</b>;
        break;

      case ETipoCampoEnum.Inteiro:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Tabela:
        component = (<><FormListTabelaField configuracaoTela={configuracaoTela} formik={formik} capa={true} /></>);
        break;

      case ETipoCampoEnum.Empresa:
        component = (<><GridPadrao
          paginationServer={true}
          colunas={colunasEmpresa}
          tipo="Empresa"
          itens={formik.values?.empresasList}
        /></>);
        break;

      case ETipoCampoEnum.Pessoa:
        component = (<><GridPadrao
          paginationServer={true}
          colunas={colunasParteContraria}
          tipo="ParteContraria"
          itens={formik.values?.partesContrariasList.filter((e: any) => e.tipo == "parteContraria")}
        /></>);
        break;
    }

    return component;
  };

  const renderModalParteContraria = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.ParteContraria) {
      return (
        <>
          <ParteContrariaField
            modulo={formik.values.modulo}
            titulo={configuracaoTela?.label}
            setFieldValue={formik.setFieldValue}
            toggleModal={toggleParteContrariaModal}
            principalId={formik.values.principalId}
            categoriaId={formik.values.areaDireitoId}
            exibirModal={exibirModalPartesContrarias}
            partesContrariasList={formik.values.partesContrariasList || []}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalEmpresa = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.Empresa) {
      return (
        <>
          <EmpresaField
            exibirModal={exibirModalEmpresas}
            toggleModal={toggleEmpresaModal}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalCentroCusto = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.CentroCusto) {
      return (
        <>
          <CentroCustoField
            centroCustoSelected={formik.values?.centrosCusto || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalCentroCusto}
            toggleModal={toggleCentroCustoModal}
            areaDireitoId={formik.values?.areaDireitoId}
            modulo={formik.values?.modulo}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalAdvogadoInterno = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.AdvogadoInterno) {
      return (
        <>
          <AdvogadoInternoField
            advogadoInternoSelected={formik.values?.advogadoInternoList || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalAdvogadosInternos}
            toggleModal={toggleAdvogadosInternosModal}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalAdvogado = () => {
    if (configuracaoTela?.campoNome == ECamposIDEnum.Advogado) {
      return (
        <>
          <AdvogadoField
            advogadoSelected={formik.values?.advogadoList || []}
            setFieldValue={formik.setFieldValue}
            exibirModal={exibirModalAdvogadosPartesContrarias}
            toggleModal={toggleAdvogadosParteContrariaModal}
          />
        </>
      );
    }
    return <></>;
  };

  const renderModalTabela = () => {
    if (configuracaoTela?.campoTipo == ETipoCampoEnum.Tabela) {
      return (
        <>
          <FormFluxoTabelaField
            configuracaoTela={configuracaoTela}
            toggleModal={toggleTabelaModal}
            exibirModal={exibirTabela}
            formik={formik}
            capa={true}
          />
        </>
      );
    }
    return <></>;
  };

  const renderAreaTexto = () => {
    if (configuracaoTela?.campoTipo == ETipoCampoEnum.AreaTexto) {
      return (
        <>
          <AreaTextoField
            configuracaoTela={configuracaoTela}
            toggleModal={toggleAreaTextoModal}
            exibirModal={exibirModalAreaTexto}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderClassName = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Tabela ||
      configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const definirInput = () => {
    let component: any[] = [];
    component.push(
      <>
        {renderAreaTexto()}
        {renderModalTabela()}
        {renderModalEmpresa()}
        {renderModalAdvogado()}
        {renderModalCentroCusto()}
        {renderModalParteContraria()}
        {renderModalAdvogadoInterno()}

        <div className={renderClassName()}>
          <label className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
            {configuracaoTela.obrigatorio ? "*:" : ":"}
            {configuracaoTela.help && <PopoverHelper />}
          </label>
          <div className="row">
            {configuracaoTela.somenteLeitura && <div className="col-md-12">{renderLabel()}</div>}
            {!configuracaoTela.somenteLeitura && <div className="col-md-12">{renderInput()}</div>}
          </div>
        </div>
      </>);

    return <>{component}</>
  }

  return (exibirCampo ? <>{definirInput()} </> : <></>);
};

export default CamposCadastroDinamico;