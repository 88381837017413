import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { RiFileSearchLine } from "react-icons/ri";
import IEmpresa from '../../../interfaces/IEmpresa';
import { IPessoa } from "../../../interfaces/IPessoa";
import { IModalActions, useDisclosure } from "../hooks/useDisclosure";
import { useEffect, useState } from 'react';
import RecuperaPessoaEmpresaCNPJA from "./RecuperaEmpresaCNPJA";
import ModalListagemEmpresa from "./ModalListagemEmpresa";
interface IModalRecuperaPessoa {
  title: string
  modalActions: IModalActions
  handlePessoa: (pessoa: IPessoa, dadosEmpresa?: IEmpresa) => void
  setTipoDocumento: (tipoDocumento: string) => void
  setNumeroDocumento: (numeroDocumento: string) => void
  setNome: (nome: string) => void
  setMatriz?: (matriz: IEmpresa | undefined) => void
  documentoPadrao?: string
  tiposDocumento?: string[]
  className?: string
}

const ModalRecuperaPessoa = (props: IModalRecuperaPessoa) => {
  const { modalActions, handlePessoa } = props;
  const [isMatriz, setIsMatriz] = useState(true);
  const [matriz, setMatriz] = useState<IEmpresa>();

  const modalMatrizActions = useDisclosure();

  useEffect(() => {
    if (!isMatriz) {
      modalMatrizActions.handleOpen()
      modalActions.handleClose()
    }
    if (isMatriz) {
      props.setMatriz?.(undefined)
      setMatriz(() => undefined)
    }

  }, [isMatriz]);

  useEffect(() => {
    if (modalMatrizActions.isOpen === false) {
      if (isMatriz) {
        setMatriz(undefined)
      }
    }

  }, [modalMatrizActions.isOpen]);

  return (
    <>
      <Modal size="lg" show={modalActions.isOpen} className={props.className} centered={true} onHide={
        modalActions.handleClose
      }>
        <Modal.Header>
          <RiFileSearchLine style={{ width: 28, height: 28 }} />
          <Modal.Title>{props.title}</Modal.Title>
          <div onClick={() => modalActions.handleClose()} className="btn btn-icon close btn-sm ms-2">
            <FontAwesomeIcon className="mx-2" icon={faTimes} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <RecuperaPessoaEmpresaCNPJA
            titulo={' '}
            documentoPadrao={props?.documentoPadrao}
            handlePessoa={handlePessoa}
            tiposDocumento={props.tiposDocumento}
            handleTipo={props.setTipoDocumento}
            handleNumeroDocumento={props.setNumeroDocumento}
            handleNome={props.setNome}
          />

        </Modal.Body>
        <Modal.Footer>
          <div className="text-start w-100">
            <label htmlFor="form-label" className="form-label fw-bolder text-orange p-2">
              Matriz:{" "}
              <FormCheckInput
                type="checkbox"
                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                checked={isMatriz}
                onChange={(e) => {
                  setIsMatriz(() => true)
                }}
              />
            </label>

            <label htmlFor="form-label" className="form-label fw-bolder text-orange p-2">
              Filial:{" "}
              <FormCheckInput
                type="checkbox"
                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                checked={!isMatriz}
                onChange={(e) => {
                  setIsMatriz(() => false)
                }}
              />
            </label>
            {matriz && (
              <p>MAtriz: ID:{matriz?.empresaId} - Nome: {matriz?.nomeFantasia}</p>
            )}
          </div>
          <Button className={'btn btn-orange'} form={'form-recupera-pessoa-modal'} type={'submit'}>Pesquisar</Button>
          <Button className={'btn btn-danger'} onClick={() => modalActions.handleClose()}>Cancelar</Button>
        </Modal.Footer>
      </Modal>

      <ModalListagemEmpresa
        toggleModal={() => {
          modalMatrizActions.handleToggle()
          modalActions.handleToggle()
        }}
        modulo={0}
        exibirModal={modalMatrizActions.isOpen}
        empresaSelected={[]}
        setFieldValue={(value, empresaSelecionada) => {
          if (empresaSelecionada) {
            props.setMatriz?.(empresaSelecionada)
            setMatriz(empresaSelecionada);
            setIsMatriz(false)
          } else {
            props.setMatriz?.(undefined)
            setMatriz(undefined);
            setIsMatriz(true)

          }
        }}
      />

    </>
  )


}

export default ModalRecuperaPessoa