import { Lane } from "../Lane";
import { Container, EmptyContainer, Skeleton } from "./styles";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useKanban } from "../../hooks/useKanban";
import { IConsultaKanbanCard } from "../../../../interfaces/IConsultaKanban";
import { Fragment, useState } from "react";
import { ConsultModal } from "../ConsultModal";

export function Board() {
  const [selectedCard, setSelectedCard] = useState<IConsultaKanbanCard | null>(
    null
  );
  const { items, isLoading, isStatusChanging, change, move, changeStatus } =
    useKanban();

  if (isLoading) {
    return (
      <Container>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Container>
    );
  }

  if (!items || !items.cards) {
    return (
      <EmptyContainer>
        <span>Nenhum status encontrado.</span>
      </EmptyContainer>
    );
  }

  async function onDragEnd(result: DropResult) {
    if (!result.destination || !items) {
      return;
    }

    const [from, fromIndex] = [result.source.droppableId, result.source.index];
    const [to, toIndex] = [
      result.destination.droppableId,
      result.destination.index,
    ];

    if (from === to) {
      change({
        position: { from: fromIndex, to: toIndex },
        status: to,
      });
      return;
    }

    const targetStatus = items.cards.find(
      (item) => item.agrupadorOpcaoNome === to
    );

    if (targetStatus) {
      move({
        position: { from: fromIndex, to: toIndex },
        status: { from, to },
      });

      await changeStatus({
        newStatus: targetStatus.agrupadorOpcaoId,
        consultaId: Number(result.draggableId),
      });
    }
  }

  function selectCard(card: IConsultaKanbanCard) {
    setSelectedCard(card);
  }

  return (
    <Fragment>
      <ConsultModal selectedCard={selectedCard} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Container>
          {items?.cards.map((lane) => (
            <Lane
              key={lane.agrupadorOpcaoNome}
              lane={lane}
              showLoader={isStatusChanging}
              selectCard={selectCard}
            />
          ))}
        </Container>
      </DragDropContext>
    </Fragment>
  );
}
