import { Element } from "slate";
import {
  ContentElement,
  CustomEditor,
  CustomElement,
  PageElement,
} from "../../../../../utils/types/slate";
import { ReactEditor } from "slate-react";

export const ContentService = {
  getContent(editor: CustomEditor) {
    const firstElement = editor.children[0];

    if (Element.isElement(firstElement) && firstElement.type === "page") {
      const content = firstElement.children.find(
        (child) => Element.isElement(child) && child.type === "content"
      );
      return content ? (content as ContentElement) : ({} as ContentElement);
    }

    return {} as ContentElement;
  },
  getAllPageContent(editor: CustomEditor) {
    const content = editor.children.map(
      (page) => Element.isElement(page) && page.children[1]
    );
    const childs: CustomElement[] = [];
    if (Element.isElementList(content)) {
      for (const element of content) {
        if (Element.isElementList(element.children)) {
          childs.push(...element.children);
        }
      }
    }

    return childs
  },
  getHtmlContentByPage(editor: CustomEditor, pageNumber: number) {
    const page = editor.children[pageNumber] as PageElement
    const content = page.children[1] as ContentElement
    return ReactEditor.toDOMNode(editor, content)
  },
  getContentByPage(editor: CustomEditor, pageNumber: number) {
    const page = editor.children[pageNumber] as PageElement
    return page.children[1] as ContentElement
  },
};
