import { HistoryEditor } from "slate-history";
import { TaskOption } from "./types";
import { CustomEditor } from "../../../../../../utils/types/slate";
import { ReactEditor } from "slate-react";

type TaskHandlerParams = {
  editor: CustomEditor
  option: TaskOption
  action?: () => void
  print?: () => void
}

export const taskHandler = ({
  editor,
  option,
  action,
  print
}: TaskHandlerParams) => {
  const options: { [key: string]: () => void } = {
    redo: () => HistoryEditor.redo(editor),
    undo: () => HistoryEditor.undo(editor),
    print: () => print?.()
  };

  options[option]();
  action?.();
  ReactEditor.focus(editor);
};
