import { Fragment, useContext } from "react";
import { MdEdit, MdRemoveCircleOutline } from "react-icons/md";
import { ImageButtonAction, VerticalDivider } from "../styles";
import { controls, formattedControlName } from "../../../../Toolbar/controls";
import { alignmentHandler } from "../../../../Toolbar/controls/alignment/handler";
// import { useEditor } from "../../../../../context/hooks";
import { FontContext } from "../../../../../context";
import { AlignmentOption } from "../../../../Toolbar/controls/alignment";
import { useSlateStatic } from "slate-react";
import { Tooltip } from "../../../../Tooltip";

type ImageActionsProps = {
  remove(): void;
  edit(): void;
};

export const ImageActions = ({ remove, edit }: ImageActionsProps) => {
  const editor = useSlateStatic();
  // const { disableBlockControl, enableBlockControl } = useEditor();
  const { currentStyles, setCurrentStyles } = useContext(FontContext);

  const setAlignment = (option: AlignmentOption) => {
    const isActive = currentStyles.textAlign === option;

    if (isActive) { return }

    setCurrentStyles((prev) => ({
      ...prev,
      textAlign: option,
    }));

    const alignmentOptions: AlignmentOption[] = [
      "left",
      "center",
      "right",
      "start",
      "justify",
    ];

    const alignmentOptionsWithoutSelected = alignmentOptions.filter(
      (alignmentOption) => alignmentOption !== option
    );

    // alignmentOptionsWithoutSelected.forEach((alignmentOption) =>
    //   // disableBlockControl(alignmentOption)
    // );
    
    // enableBlockControl(option);
  };

  const renderAlignmentComponents = () => {
    return controls.alignment.map(({ component, type: option }) => {
      const action = () => alignmentHandler(editor, option, () => setAlignment(option));
      return (
        <Tooltip key={option} placement="bottom" content={formattedControlName(option)}>
          <ImageButtonAction onClick={action}>{component}</ImageButtonAction>
        </Tooltip>
      );
    });
  };

  return (
    <Fragment>
      {renderAlignmentComponents()}

      <VerticalDivider />

      <Tooltip placement="bottom" content="Remover">
        <ImageButtonAction onClick={remove}>
          <MdRemoveCircleOutline color="var(--bs-orange)" />
        </ImageButtonAction>
      </Tooltip>

      <Tooltip placement="bottom" content="Editar">
        <ImageButtonAction onClick={edit} className="edit-action">
          <MdEdit color="var(--bs-primary)" />
        </ImageButtonAction>
      </Tooltip>
    </Fragment>
  );
};
