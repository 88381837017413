/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// React and related hooks
import { useEffect, useState, useRef } from "react";

// External libraries and components
import { faEraser, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { TableColumn } from "react-data-table-component";

// Internal components and services
import GridPadrao from "../../../components/Comum/GridPadrao";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import ContratoFiltroModal from "./FiltroModal";
import ContratoService from "../../../services/ContratoService";
import TipoContratoService from "../../../services/TipoContratoService";
import LimitacaoTabelaService from "../../../services/LimitacaoTabelaService";

// Interfaces and types
import FiltroPesquisaContratoRequest from "../../../interfaces/Requests/Contrato/FiltroPesquisaContratoRequest";
import IContrato, { IContratoPartesContraria } from "../../../interfaces/IContrato";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import ITipoContrato from "../../../interfaces/ITipoContrato";

// Styles
import "../style/icons.css";


const filtroInicial: FiltroPesquisaContratoRequest = {
  contratoId: 0,
  pastaId: 0,

  numeroContrato: "",
  objetoContrato: "",
  observacoesContrato: "",
  codigo: "",
  parteContraria: "",

  urgente: false,
  isNovaPasta: false,

  statusContrato: [],
  statusPasta: [],
  tipoContratoId: [],
  periodicidadeId: [],
  departamentoId: [],
  gestorId: [],
  moedaId: [],
  indiceId: [],
  usuarioIdCadastro: [],
  urgenteFilter: [],

  dataInicioVigenciaMaiorIgual: "",
  dataInicioVigenciaMenorIgual: "",

  dataFimVigenciaMaiorIgual: "",
  dataFimVigenciaMenorIgual: "",

  dataCadastroMaiorIgual: "",
  dataCadastroMenorIgual: "",

  dataAprovacaoMaiorIgual: "",
  dataAprovacaoMenorIgual: "",

  dataAssinaturaMaiorIgual: "",
  dataAssinaturaMenorIgual: "",

  dataCanceladoMaiorIgual: "",
  dataCanceladoMenorIgual: "",

  valorTotalMaiorIgual: 0,
  valorTotalMenorIgual: 0,

  offset: 0,
  limit: 10,
  sort: "contratoId",
  totalItems: 0,
  modeloRelatorioId: 0
};

interface IContratoModalProps {
  togglePastaModal: (exibirPastaModal?: boolean) => void;
  exibirPastaModal: boolean;
  formik: any;
}

const PastaModalPage = ({ togglePastaModal, exibirPastaModal, formik }: IContratoModalProps) => {

  const componentRef = useRef<any>();

  const [contratos, setContratos] = useState<IContrato[]>([]);
  const [carregandoContratos, setCarregandoContratos] = useState(false);

  const [pesquisar, setPesquisar] = useState(false);
  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);

  const [carregamentoTipoContrato, setCarregamentoTipoContrato] = useState<boolean>(false);
  const [tiposContratosOptions, setTiposContratosOptions] = useState<any>([]);
  const [tipoContratoSelected, setTipoContratoSelected] = useState([]);

  const [carregamentoDepartamento, setCarregamentoDepartamento] = useState<boolean>(false);
  const [departamentosOptions, setDepartamentosOptions] = useState<any>([]);
  const [departamentoSelected, setDepartamentoSelected] = useState([]);

  const [selectedStatusContrato, setSelectedStatusContrato] = useState<any>([]);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaContratoRequest>(filtroInicial);

  const optionsStatusContrato = [
    { label: "Em Elaboração", value: "1" },
    { label: "Em Aprovação", value: "2" },
    { label: "Em Assinatura", value: "3" },
    { label: "Assinado", value: "4" },
    { label: "Cancelado", value: "5" },
  ];

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    setFiltrosPesquisa({ ...filtrosPesquisa, statusContrato: IdsSelected });
  };

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  useEffect(() => {

    carregarTipoContrato();

    carregarDepartamento();

  }, [exibirPastaModal]);

  useEffect(() => { if (!carregandoContratos) setPesquisar(true); }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => { if (pesquisar) carregarContratos(filtrosPesquisa); }, [pesquisar]);

  const returnOptionsTiposContratos = (array: any) => {
    array.forEach((item: any) => {
      tiposContratosOptions.push({
        value: item.tipoContratoId,
        label: item.nome,
      });
    });
    return setTiposContratosOptions;
  };

  const returnOptionsDepartamentos = (array: any) => {
    array.forEach((item: any) => {
      departamentosOptions.push({
        value: item.departamentoId,
        label: item.nome,
      });
    });
    return setDepartamentosOptions;
  };

  const formatarData = (data: string): string => {
    const dataRet = data || ''
    if (dataRet.includes('T')) {
      const [ano, mes, dia] = dataRet.split('T')[0].split('-')
      return `${dia}/${mes}/${ano}`
    }
    return '-'
  }

  const nomearStatusContrato = (status: string): string => {
    switch (status) {
      case "EmElaboracao":
        return 'Em Elaboração';
      case "EmAprovacao":
        return 'Em Aprovação';
      case "EmAssinatura":
        return 'Em Assinatura';
      case "Assinado":
        return 'Assinado';
      case "Cancelado":
        return 'Cancelado';
      default:
        return 'Não definido';
    }
  }

  const nomearStatusPasta = (status: string): string => {
    switch (status) {
      case "EmElaboracao":
        return 'Em Elaboração';
      default:
        return status;
    }
  }

  const carregarTipoContrato = async () => {
    try {
      setCarregamentoTipoContrato(true);
      let resultadoTipoContrato: IRecuperaLista<ITipoContrato>;
      resultadoTipoContrato = await TipoContratoService.obterTipoContratos({
        nome: "",
        codigo: "",
        status: 1,
        categoria: 0,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoContratoId",
      });
      setTiposContratosOptions(returnOptionsTiposContratos(resultadoTipoContrato.data));
      setCarregamentoTipoContrato(false);
    } catch (error: any) {
      setTiposContratosOptions([]);
    }
  }

  const carregarDepartamento = async () => {
    try {
      setCarregamentoDepartamento(true);
      let resultadoDepartamento: IRecuperaLista<any>;
      resultadoDepartamento = await LimitacaoTabelaService.obterListaLimitadaTelaPesquisa({
        controller: "Departamento",
        modulo: 7,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setDepartamentosOptions(returnOptionsDepartamentos(resultadoDepartamento.data));
      setCarregamentoDepartamento(false);
    } catch (error: any) {
      setCarregamentoDepartamento(false);
      setDepartamentosOptions([]);
    }
  }

  const carregarContratos = async (filtro: FiltroPesquisaContratoRequest) => {
    try {
      setCarregandoContratos(true);

      let resultado: IRecuperaLista<IContrato>;
      resultado = await ContratoService.obterContratos(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setContratos(resultado.data);
      setCarregandoContratos(false);
      setPesquisar(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setContratos([]);
    } finally {
      setCarregandoContratos(false);
      setPesquisar(false);
    }
  }

  const montarParteContraria = (partes: IContratoPartesContraria[]) => {
    let partesString = "";

    partes.forEach((parte) => {
      if (parte.principal) {
        partesString += parte.nome + " - " + parte.papelSignatarioNome;
      }
    });
    return partesString;
  };

  const colunas: TableColumn<IContrato>[] = [
    {
      cell: (contrato: IContrato) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  formik.setFieldValue('pastaid', contrato.pastaId);
                  formik.setFieldValue('contratoId', contrato.contratoId);
                  togglePastaModal();
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Contrato: {contrato.numeroContrato} - {montarParteContraria(contrato.contratoParteContraria)}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{nomearStatusContrato(contrato.statusContrato)}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID Pasta:</b> {contrato.pastaId}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Código: </b> {contrato.codigo}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Nome: </b> {contrato.pastaNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Grupo Econômico: </b> {contrato.observacoesContrato}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>UF: </b> {contrato.uf}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Contrato: </b> {contrato.tipoContratoNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Vigência: </b>
                {formatarData(contrato.dataInicioVigencia === null ? "" : contrato.dataInicioVigencia.toString().toString())}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}> até </b>
                {formatarData(contrato.dataFimVigencia === null ? "" : contrato.dataFimVigencia.toString())}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status da Pasta: </b> {nomearStatusPasta(contrato.statusPasta)}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Área Solicitante: </b> {contrato.departamentoNome}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IContrato>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };



  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoContratoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, tipoContratoId: IdsSelected };
    });
  }, [tipoContratoSelected]);

  return (
    <>
      <Modal size="lg" centered={true} show={exibirPastaModal} onHide={togglePastaModal}>

        <div className="modal-content">
          <div className="modal-body">
            <LayoutPageTitle title="Pesquisar pasta" />

            <ContratoFiltroModal
              setPesquisar={setPesquisar}
              exibirModal={exibirFormModal}
              filtro={filtrosPesquisa}
              setFiltro={setFiltrosPesquisa}
              toggleModal={toggleModal}
            />

            <div className="row mt-2">
              <div className="col-md-12" >
                <form onSubmit={(e) => e.preventDefault()}
                  className="row g-3 mb-3 form-buscar-contrato">

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-contratoId" className="form-label fw-bolder text-orange">
                      ID do Instrumento:
                    </label>
                    <input
                      value={filtrosPesquisa.contratoId}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState: any) => {
                          return { ...oldState, contratoId: e.target.value };
                        });
                      }}
                      placeholder="ID"
                      type="number"
                      className={"form-control"}
                      id="form-contratoId"
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-numeroContrato" className="form-label fw-bolder text-orange">
                      Número do Contrato:
                    </label>
                    <input
                      value={filtrosPesquisa.numeroContrato}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState) => {
                          return { ...oldState, numeroContrato: e.target.value };
                        });
                      }}
                      placeholder="Número do Contrato"
                      type="text"
                      className={"form-control"}
                      id="form-numeroContrato"
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Tipo de Contrato:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoTipoContrato}
                      disabled={carregamentoTipoContrato}
                      options={tiposContratosOptions}
                      value={tipoContratoSelected}
                      onChange={(event: any) => setTipoContratoSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                      Parte Contrária:
                    </label>
                    <input
                      value={filtrosPesquisa.parteContraria}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState) => {
                          return { ...oldState, parteContraria: e.target.value };
                        });
                      }}
                      placeholder="Nome ou CPF/CNPJ"
                      type="text"
                      className={"form-control"}
                      id="form-parteContraria"
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                      Status do Instrumento:
                    </label>
                    <MultiSelect
                      options={optionsStatusContrato}
                      value={selectedStatusContrato}
                      //onChange={setSelectedStatusContrato}
                      onChange={(event: any) => {
                        setSelectedStatusContrato(event);
                        setIdsSelect("statusContrato", event);
                      }}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-cluster" className="form-label fw-bolder text-orange">
                      Área Solicitante:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoDepartamento}
                      disabled={carregamentoDepartamento}
                      options={departamentosOptions}
                      value={departamentoSelected}
                      onChange={(event: any) => setDepartamentoSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                      Código:
                    </label>
                    <input
                      value={filtrosPesquisa.codigo}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState) => {
                          return { ...oldState, codigo: e.target.value };
                        });
                      }}
                      placeholder="Código"
                      type="text"
                      className={"form-control"}
                      id="form-codigo"
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-grupoEconomico" className="form-label fw-bolder text-orange">
                      Grupo Econômico:
                    </label>
                    <input
                      value={filtrosPesquisa.observacoesContrato}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState) => {
                          return { ...oldState, observacoesContrato: e.target.value };
                        });
                      }}
                      placeholder="Grupo Econômico"
                      type="text"
                      className={"form-control"}
                      id="form-grupoEconomico"
                    />
                  </div>

                  <div className="col-12" style={{ textAlign: 'right' }}>
                    <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                    </button>
                    <button
                      onClick={() => {
                        setFiltrosPesquisa(filtroInicial);
                        setPesquisar(false);
                        setTipoContratoSelected([]);
                        setSelectedStatusContrato([]);
                      }}
                      className="btn btn-sm btn-orange search-buttom-margin ms-2"
                    >
                      <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                    </button>


                    <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                    </button>

                  </div>
                </form>

                <div className="col-md-12 mb-10" style={{ overflow: !carregandoContratos ? "auto" : "scroll", height: "600px" }}>
                  <div ref={componentRef}>
                    <GridPadrao
                      onSort={handleSort}
                      progressPending={carregandoContratos}
                      limit={filtrosPesquisa.limit}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      paginationServer={true}
                      paginationTotalRows={filtrosPesquisa.totalItems}
                      colunas={colunas}
                      tipo="Contratos"
                      itens={contratos}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button onClick={() => togglePastaModal()} type="button" className="btn btn-danger ms-5">
              Fechar
            </button>
          </div>
        </div>

      </Modal>
    </>
  );
};
export default PastaModalPage;
