import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IIndice from "../interfaces/IIndice";
import { AdicionaIndiceRequest } from "../interfaces/Requests/Indice/AdicionaIndiceRequest";
import FiltroPesquisaIndiceRequest from "../interfaces/Requests/Indice/FiltroPesquisaIndiceRequest";
import { EditarIndiceRequest } from "../interfaces/Requests/Indice/EditarIndiceRequest";

class IndiceService {

    async obterIndices(filtros: FiltroPesquisaIndiceRequest): Promise<IRecuperaLista<IIndice>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IIndice>>(`Indice/RecuperaIndice?${query}`);

        return result;
    }

    async adicionaIndice(adicionaIndice: AdicionaIndiceRequest): Promise<IIndice> {
        let { data: result } = await api.post<IIndice>(`Indice/AdicionaIndice`, adicionaIndice);

        return result;
    }


    async alterarStatus(indiceId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IIndice>(`Indice/AtualizaIndicePorId?id=${indiceId}`, data);
    }

    async atualizarIndice(editarIndice: EditarIndiceRequest): Promise<void> {

        let dataIndice = [
            { "op": "replace", "path": "/nome", "value": editarIndice.nome },
            { "op": "replace", "path": "/codigo", "value": editarIndice.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Indice/AtualizaIndicePorId?id=${editarIndice.indiceId}`, dataIndice)
        ]);
    }
}


export default new IndiceService();