import { EModulo, ETipoCampoEnum } from "../enum";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import ICentroCusto from "../interfaces/ICentroCusto";
import IConsulta from "../interfaces/IConsulta";
import { IConsultaKanban, IConsultantKanbanFilter } from "../interfaces/IConsultaKanban";
import IEmpresaContrato from "../interfaces/IEmpresaContrato";
import IParteContraria from "../interfaces/IParteContraria";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import FiltroPesquisaConsultaRequest from "../interfaces/Requests/Consulta/FiltroPesquisaConsultaRequest";
import { IAdicionaConsultaRequest } from "../interfaces/Requests/Consulta/IAdicionaConsultaRequest";
import api from "./Api";

class ConsultaService {
  async adicionaConsulta(
    adicionaConsulta: IAdicionaConsultaRequest
  ): Promise<IConsulta> {
    let { data: result } = await api.post<IConsulta>(
      `Consulta/AdicionaConsulta`,
      this.montarObjetoAdicionarConsulta(adicionaConsulta)
    );
    return result;
  }

  public async editarConsulta(editarConsulta: IAdicionaConsultaRequest) {
    let request = this.montarObjetoArrayContrato(editarConsulta);
    if (request.length > 0) {
      let result = await api.patch(
        `Consulta/AtualizaConsultaPorId?id=${editarConsulta.consultaId}`,
        request
      );
      return result;
    }
  }

  public async updateConsultaStatus({
    consultaId,
    newStatus,
  }: {
    consultaId: number;
    newStatus: number;
  }) {
    const { status } = await api.patch(
      `Consulta/AtualizaConsultaPorId?id=${consultaId}`,
      [
        {
          op: "replace",
          path: "/statusConsultaId",
          value: newStatus,
        },
      ]
    );

    return status
  }

  public async alterarConsultaPorCentroCustoList(
    centrosCusto: ICentroCusto[],
    consultaId: number
  ) {
    if (centrosCusto.length > 0) {
      await api.put<IAdicionaConsultaRequest>(`Consulta/AtualizaCentroCusto`, {
        consultaId: consultaId,
        centrosCusto: centrosCusto,
      });
    }
  }

  public async obterConsultas(
    filtros: FiltroPesquisaConsultaRequest
  ): Promise<IRecuperaLista<IConsulta>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.consultaId) {
      query += `&consultaId=${filtros.consultaId}`;
    }

    if (filtros.nomeSolicitante) {
      query += `&nomeSolicitante=${filtros.nomeSolicitante}`;
    }

    if (filtros.descricaoConsulta) {
      query += `&descricaoConsulta=${filtros.descricaoConsulta}`;
    }

    if (filtros.emailSolicitante) {
      query += `&emailSolicitante=${filtros.emailSolicitante}`;
    }

    if (filtros.parteContraria && filtros.parteContraria.length > 0) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<IConsulta>>(
      `Consulta/RecuperaListagemConsulta?${query}`
    );

    return result;
  }

  async obterConsultasKanban(
    params: IConsultantKanbanFilter
  ): Promise<IConsultaKanban> {
    const { data } = await api.get<IConsultaKanban>(
      "Consulta/RecuperaListagemKanbanConsulta",
      {
        params,
      }
    );
    return data;
  }

  async obterCentroCusto(
    consultaId: number
  ): Promise<IRecuperaLista<ICentroCusto>> {
    let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(
      `/Consulta/RecuperaCentroCustoPorId?consultaId=${consultaId}`
    );
    return result;
  }

  public async alterarConsultaPorCampo(
    campo: string,
    valor: any,
    consultaId: number
  ) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaConsultaRequest>(
        `Consulta/AtualizaConsultaPorId?id=${consultaId}`,
        request
      );
    }
  }

  public async alterarConsultaPorXCampo(
    campo: string,
    valor: any,
    xcampoId: number
  ) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaConsultaRequest>(
        `Consulta/AtualizaXCampoValor?id=${xcampoId}`,
        request
      );
    }
  }

  public async alterarConsultaPorCampoBolleano(
    campo: string,
    valor: any,
    consultaId: number
  ) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaConsultaRequest>(
      `Consulta/AtualizaConsultaPorId?id=${consultaId}`,
      request
    );
  }

  public async alterarConsultaPorXCampoBolleano(
    campo: string,
    valor: any,
    xcampoid: number
  ) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaConsultaRequest>(
      `Consulta/AtualizaXCampoValor?id=${xcampoid}`,
      request
    );
  }

  async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(
      `/Favorito/RecuperaFavorito`
    );

    return result;
  }

  public async alterarConsultaPorAdvogadoInternoList(
    advogadoInternoList: IAdvogadoInterno[],
    consultaId: number
  ) {
    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        const request = {
          consultaId: consultaId,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<IAdicionaConsultaRequest>(
          "Consulta/AtualizaPartesConsultaPorId",
          request
        );
      }
    }
  }

  public async alterarConsultaPorEmpresaList(
    empresasList: IEmpresaContrato[],
    consultaId: number
  ) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        const request = {
          consultaId: consultaId,
          empresasList: empresasRequestList,
        };
        await api.put<IAdicionaConsultaRequest>(
          "Consulta/AtualizaPartesConsultaPorId",
          request
        );
      }
    }
  }

  public async alterarConsultaPorParteConsultaList(
    partesConsultasList: IParteContraria[],
    consultaId: number
  ) {
    const partesConsultasRequestList: any[] = [];
    partesConsultasList.forEach((parteConsulta) => {
      let requestParteConsulta: any = {};
      if (parteConsulta.bairro)
        requestParteConsulta.bairro = parteConsulta.bairro;
      if (parteConsulta.celular)
        requestParteConsulta.celular = parteConsulta.celular;
      if (parteConsulta.cep) requestParteConsulta.cep = parteConsulta.cep;
      if (parteConsulta.cidadeId)
        requestParteConsulta.cidadeId = parteConsulta.cidadeId;
      if (parteConsulta.codigo)
        requestParteConsulta.codigo = parteConsulta.codigo;
      if (parteConsulta.complemento)
        requestParteConsulta.complemento = parteConsulta.complemento;
      if (parteConsulta.documento)
        requestParteConsulta.documento = parteConsulta.documento;
      if (parteConsulta.email) requestParteConsulta.email = parteConsulta.email;
      if (parteConsulta.endereco)
        requestParteConsulta.endereco = parteConsulta.endereco;
      if (parteConsulta.estadoId)
        requestParteConsulta.estadoId = parteConsulta.estadoId;
      if (parteConsulta.papelSignatarioId)
        requestParteConsulta.papelSignatarioId =
          parteConsulta.papelSignatarioId;
      if (parteConsulta.papelSignatarioNome)
        requestParteConsulta.papelSignatarioNome =
          parteConsulta.papelSignatarioNome;
      if (parteConsulta.logradouro)
        requestParteConsulta.logradouro = parteConsulta.logradouro;
      if (parteConsulta.nome) requestParteConsulta.nome = parteConsulta.nome;
      if (parteConsulta.nomeSocial)
        requestParteConsulta.nomeSocial = parteConsulta.nomeSocial;
      if (parteConsulta.numero)
        requestParteConsulta.numero = parteConsulta.numero;
      if (parteConsulta.observacoesGerais)
        requestParteConsulta.observacoesGerais =
          parteConsulta.observacoesGerais;
      if (parteConsulta.pessoaId)
        requestParteConsulta.pessoaId = parteConsulta.pessoaId;
      if (parteConsulta.principal)
        requestParteConsulta.principal = parteConsulta.principal;
      if (parteConsulta.telefone)
        requestParteConsulta.telefone = parteConsulta.telefone;
      if (parteConsulta.tipoDocumento)
        requestParteConsulta.tipoDocumento = parteConsulta.tipoDocumento;
      partesConsultasRequestList.push(requestParteConsulta);
    });

    const request = {
      consultaId: consultaId,
      partesConsultasList: partesConsultasRequestList,
    };
    await api.put<IAdicionaConsultaRequest>(
      "Consulta/AtualizaPartesConsultaPorId",
      request
    );
  }

  public async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  public async adicionaFavorido(consultaId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.Consultivo,
      consultaId: consultaId,
    });
    return result;
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(
      `Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`
    );
  }

  public async obterAdvogadosInterno(
    consultaId: number
  ): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(
      `/Consulta/RecuperaAdvogadoInternoPorId?consultaId=${consultaId}`
    );

    return result;
  }

  public async obterConsultaPorId(
    consultaId: number
  ): Promise<IRecuperaLista<IConsulta>> {
    let { data: result } = await api.get<IRecuperaLista<IConsulta>>(
      `/Consulta/RecuperaListagemConsulta?&consultaId=${consultaId}`
    );
    return result;
  }

  public async obterCapaConsulta(
    consultaId: number
  ): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<
      IRecuperaLista<IRecuperaConfiguracaoTelaResponse>
    >(`/Consulta/RecuperaCapaConsulta?consultaId=${consultaId}`);

    return result;
  }

  private montarObjetoAdicionarConsulta = (
    consulta: IAdicionaConsultaRequest
  ) => {
    let request: any = {};

    // XCampos
    if (consulta.xCampoValorList.length > 0)
      request.xCampoValorList = consulta.xCampoValorList;

    // Campos texto
    if (consulta.codigo) request.codigo = consulta.codigo;
    if (consulta.observacoesconsulta)
      request.observacoesConsulta = consulta.observacoesconsulta;
    if (consulta.nomesolicitante)
      request.nomeSolicitante = consulta.nomesolicitante;
    if (consulta.emailsolicitante)
      request.emailSolicitante = consulta.emailsolicitante;
    if (consulta.parecerjuridico)
      request.parecerJuridico = consulta.parecerjuridico;
    if (consulta.tempogasto) request.tempoGasto = consulta.tempogasto;
    if (consulta.descricaoconsulta)
      request.descricaoConsulta = consulta.descricaoconsulta;

    // Campos bool
    request.restrito = consulta.restrito ? true : false;

    // Campos data
    if (consulta.datafinalizacao)
      request.dataFinalizacao = consulta.datafinalizacao;
    if (consulta.prazofatal) request.prazoFatal = consulta.prazofatal;

    // Campos lista
    if (consulta.departamentoId)
      request.departamentoId = consulta.departamentoId;
    if (consulta.tipoConsultaId)
      request.tipoConsultaId = consulta.tipoConsultaId;
    if (consulta.escritorioId) request.escritorioId = consulta.escritorioId;
    if (consulta.cidadeId) request.cidadeId = consulta.cidadeId;
    if (consulta.tipodocumentoId)
      request.tipoDocumentoId = consulta.tipodocumentoId;

    // Campos Pessoa e Empresa
    if (consulta.advogadoInternoList.filter((e) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      consulta.advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (consulta.empresasList.filter((e) => e.checked).length > 0) {
      request.empresasList = [];
      consulta.empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          request.empresasList.push(empresa);
        });
    }

    if (consulta.centrosCusto.filter((e) => e.checked).length > 0) {
      request.centrosCusto = [];
      consulta.centrosCusto
        .filter((e) => e.checked)
        .forEach((item) => {
          let centroCusto: any = {
            tipoCentroCustoId: item.tipoCentroCustoId,
            percentual: item.percentual,
          };
          request.centrosCusto.push(centroCusto);
        });
    }

    if (consulta.partesContrariasList.length > 0) {
      request.partesContrariasList = [];
      consulta.partesContrariasList.forEach((parteContraria) => {
        let requestParteContraria: any = {};
        if (parteContraria.xCampoValorList?.length > 0)
          requestParteContraria.xCampoValorList =
            parteContraria.xCampoValorList;
        if (parteContraria.tipoParteContrariaId)
          requestParteContraria.tipoParteContrariaId =
            parteContraria.tipoParteContrariaId;
        if (parteContraria.bairro)
          requestParteContraria.bairro = parteContraria.bairro;
        if (parteContraria.cargoId)
          requestParteContraria.cargoId = parteContraria.cargoId;
        if (parteContraria.celular)
          requestParteContraria.celular = parteContraria.celular;
        if (parteContraria.tipoCentroCustoId)
          requestParteContraria.tipoCentroCustoId = parteContraria.tipoCentroCustoId;
        if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
        if (parteContraria.cidadeId)
          requestParteContraria.cidadeId = parteContraria.cidadeId;
        if (parteContraria.codigo)
          requestParteContraria.codigo = parteContraria.codigo;
        if (parteContraria.complemento)
          requestParteContraria.complemento = parteContraria.complemento;
        if (parteContraria.ctps)
          requestParteContraria.ctps = parteContraria.ctps;
        if (parteContraria.dataAdmissao)
          requestParteContraria.dataAdmissao = parteContraria.dataAdmissao;
        if (parteContraria.dataDemissao)
          requestParteContraria.dataDemissao = parteContraria.dataDemissao;
        if (parteContraria.dataNascimento)
          requestParteContraria.dataNascimento = parteContraria.dataNascimento;
        if (parteContraria.departamentoId)
          requestParteContraria.departamentoId = parteContraria.departamentoId;
        if (parteContraria.documento)
          requestParteContraria.documento = parteContraria.documento;
        if (parteContraria.email)
          requestParteContraria.email = parteContraria.email;
        if (parteContraria.empresaContratante)
          requestParteContraria.empresaContratante =
            parteContraria.empresaContratante;
        if (parteContraria.empresaTerceira)
          requestParteContraria.empresaTerceira =
            parteContraria.empresaTerceira;
        if (parteContraria.endereco)
          requestParteContraria.endereco = parteContraria.endereco;
        if (parteContraria.estadoCivilId)
          requestParteContraria.estadoCivilId = parteContraria.estadoCivilId;
        if (parteContraria.formaParticipacaoId)
          requestParteContraria.formaParticipacaoId =
            parteContraria.formaParticipacaoId;
        if (parteContraria.formaParticipacaoNome)
          requestParteContraria.formaParticipacaoNome =
            parteContraria.formaParticipacaoNome;
        if (parteContraria.logradouro)
          requestParteContraria.logradouro = parteContraria.logradouro;
        if (parteContraria.matricula)
          requestParteContraria.matricula = parteContraria.matricula;
        if (parteContraria.moedaId)
          requestParteContraria.moedaId = parteContraria.moedaId;
        if (parteContraria.motivoDesligamentoId)
          requestParteContraria.motivoDesligamentoId =
            parteContraria.motivoDesligamentoId;
        if (parteContraria.nacionalidade)
          requestParteContraria.nacionalidade = parteContraria.nacionalidade;
        if (parteContraria.nome)
          requestParteContraria.nome = parteContraria.nome;
        if (parteContraria.nomeSocial)
          requestParteContraria.nomeSocial = parteContraria.nomeSocial;
        if (parteContraria.numero)
          requestParteContraria.numero = parteContraria.numero;
        if (parteContraria.numeroContrato)
          requestParteContraria.numeroContrato = parteContraria.numeroContrato;
        if (parteContraria.observacoesContratacao)
          requestParteContraria.observacoesContratacao =
            parteContraria.observacoesContratacao;
        if (parteContraria.observacoesDesligamento)
          requestParteContraria.observacoesDesligamento =
            parteContraria.observacoesDesligamento;
        if (parteContraria.observacoesGerais)
          requestParteContraria.observacoesGerais =
            parteContraria.observacoesGerais;
        if (parteContraria.orgaoRegistroId)
          requestParteContraria.orgaoRegistroId =
            parteContraria.orgaoRegistroId;
        if (parteContraria.pessoaId)
          requestParteContraria.pessoaId = parteContraria.pessoaId;
        if (parteContraria.pisPasep)
          requestParteContraria.pisPasep = parteContraria.pisPasep;
        if (parteContraria.principal)
          requestParteContraria.principal = parteContraria.principal;
        if (parteContraria.regimeContratacao)
          requestParteContraria.regimeContratacao =
            parteContraria.regimeContratacao;
        if (parteContraria.rg) requestParteContraria.rg = parteContraria.rg;
        if (parteContraria.rgOrgao)
          requestParteContraria.rgOrgao = parteContraria.rgOrgao;
        if (parteContraria.statusFolhaId)
          requestParteContraria.statusFolhaId = parteContraria.statusFolhaId;
        if (parteContraria.telefone)
          requestParteContraria.telefone = parteContraria.telefone;
        if (parteContraria.tipoContratacao)
          requestParteContraria.tipoContratacao =
            parteContraria.tipoContratacao;
        if (parteContraria.tipoDocumento)
          requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
        if (parteContraria.ultimoSalario)
          requestParteContraria.ultimoSalario = parteContraria.ultimoSalario;
        request.partesContrariasList.push(requestParteContraria);
      });
    }

    return request;
  };

  private montarListas = (
    filtros: FiltroPesquisaConsultaRequest,
    query: string
  ) => {
    if (filtros.empresa.length > 0)
      filtros.empresa.forEach((id: any) => (query += `&empresa=${id}`));
    if (filtros.departamentoId.length > 0)
      filtros.departamentoId.forEach(
        (id: any) => (query += `&DepartamentoId=${id}`)
      );
    if (filtros.escritorioId.length > 0)
      filtros.escritorioId.forEach(
        (id: any) => (query += `&escritorioId=${id}`)
      );
    if (filtros.cidadeId.length > 0)
      filtros.cidadeId.forEach((id: any) => (query += `&cidadeId=${id}`));
    if (filtros.statusConsultaId.length > 0)
      filtros.statusConsultaId.forEach(
        (id: any) => (query += `&statusConsultaId=${id}`)
      );
    if (filtros.tipoConsultaId.length > 0)
      filtros.tipoConsultaId.forEach(
        (id: any) => (query += `&TipoConsultaId=${id}`)
      );
    return query;
  };

  private montarDatas = (
    filtros: FiltroPesquisaConsultaRequest,
    query: string
  ) => {
    if (
      filtros.dataFinalizacaoMaiorIgual &&
      filtros.dataFinalizacaoMenorIgual
    ) {
      query += `&dataFinalizacaoMaiorIgual=${filtros.dataFinalizacaoMaiorIgual}&dataFinalizacaoMenorIgual=${filtros.dataFinalizacaoMenorIgual}`;
    }
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.prazoFatalMaiorIgual && filtros.prazoFatalMenorIgual) {
      query += `&prazoFatalMaiorIgual=${filtros.prazoFatalMaiorIgual}&prazoFatalMenorIgual=${filtros.prazoFatalMenorIgual}`;
    }

    return query;
  };

  public async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(
      `XCampos/AdicionaXCampoValor`,
      request
    );

    return result;
  }

  public concluirCompromisso = async (
    consulta: IAdicionaConsultaRequest
  ): Promise<any> => {
    let result: any[] = [];

    // Salvar campos normais
    await this.editarConsulta(consulta);

    // Salvar campos Pessoas
    // Salvar campos Pessoas
    if (consulta.partesContrariasList?.length > 0)
      result.push(
        await this.alterarConsultaPorParteConsultaList(
          consulta.partesContrariasList,
          consulta.consultaId
        )
      );
    if (consulta.advogadoInternoList?.length > 0)
      result.push(
        await this.alterarConsultaPorAdvogadoInternoList(
          consulta.advogadoInternoList,
          consulta.consultaId
        )
      );
    if (consulta.empresasList?.length > 0)
      result.push(
        await this.alterarConsultaPorEmpresaList(
          consulta.empresasList,
          consulta.consultaId
        )
      );

    // Salvar xcampos
    if (consulta.xCampoValorList?.length > 0) {
      consulta.xCampoValorList.forEach(async (xCampo) => {
        switch (xCampo.campoTipo) {
          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId)
              result.push(
                await this.adicionarXCampoValor({
                  campoId: xCampo.campoId,
                  consultaId: consulta.consultaId,
                  texto: xCampo.texto,
                })
              );
            else
              result.push(
                await this.alterarConsultaPorXCampo(
                  ETipoCampoEnum.Texto,
                  xCampo.texto,
                  xCampo.xCampoValorId
                )
              );
            break;

          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId)
              result.push(
                await this.adicionarXCampoValor({
                  campoId: xCampo.campoId,
                  consultaId: consulta.consultaId,
                  data: xCampo.data,
                })
              );
            else
              result.push(
                await this.alterarConsultaPorXCampo(
                  ETipoCampoEnum.Data,
                  xCampo.data,
                  xCampo.xCampoValorId
                )
              );
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId)
              result.push(
                await this.adicionarXCampoValor({
                  campoId: xCampo.campoId,
                  consultaId: consulta.consultaId,
                  valor: xCampo.valor,
                })
              );
            else
              result.push(
                await this.alterarConsultaPorXCampo(
                  ETipoCampoEnum.Valor,
                  xCampo.valor,
                  xCampo.xCampoValorId
                )
              );
            break;

          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId)
              result.push(
                await this.adicionarXCampoValor({
                  campoId: xCampo.campoId,
                  consultaId: consulta.consultaId,
                  valor: xCampo.inteiro,
                })
              );
            else
              result.push(
                await this.alterarConsultaPorXCampo(
                  ETipoCampoEnum.Inteiro,
                  xCampo.inteiro,
                  xCampo.xCampoValorId
                )
              );
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId)
              await result.push(
                await this.adicionarXCampoValor({
                  campoId: xCampo.campoId,
                  consultaId: consulta.consultaId,
                  xCampoOpcaoId: xCampo.xCampoOpcaoId,
                })
              );
            else
              await result.push(
                await this.alterarConsultaPorXCampo(
                  ETipoCampoEnum.xCampoOpcao,
                  xCampo.xCampoOpcaoId,
                  xCampo.xCampoValorId
                )
              );
            break;

          default:
            break;
        }
      });
    }

    return result;
  };

  private montarObjetoArrayContrato = (consulta: IAdicionaConsultaRequest) => {
    let request: any = [];

    // Campos Texto
    if (consulta.codigo)
      request.push({ op: "replace", path: "/codigo", value: consulta.codigo });
    if (consulta.observacoesconsulta)
      request.push({
        op: "replace",
        path: "/observacoesconsulta",
        value: consulta.observacoesconsulta,
      });
    if (consulta.nomesolicitante)
      request.push({
        op: "replace",
        path: "/nomesolicitante",
        value: consulta.nomesolicitante,
      });
    if (consulta.emailsolicitante)
      request.push({
        op: "replace",
        path: "/emailsolicitante",
        value: consulta.emailsolicitante,
      });
    if (consulta.parecerjuridico)
      request.push({
        op: "replace",
        path: "/parecerjuridico",
        value: consulta.parecerjuridico,
      });
    if (consulta.tempogasto)
      request.push({
        op: "replace",
        path: "/tempogasto",
        value: consulta.tempogasto,
      });
    if (consulta.descricaoconsulta)
      request.push({
        op: "replace",
        path: "/descricaoconsulta",
        value: consulta.descricaoconsulta,
      });

    // Campos Boleano
    if (consulta.restrito)
      request.push({
        op: "replace",
        path: "/restrito",
        value: consulta.restrito,
      });

    // Campos Data
    if (consulta.datafinalizacao)
      request.push({
        op: "replace",
        path: "/datafinalizacao",
        value: consulta.datafinalizacao,
      });
    if (consulta.prazofatal)
      request.push({
        op: "replace",
        path: "/prazofatal",
        value: consulta.prazofatal,
      });

    // Campos Lista
    if (consulta.cidadeId)
      request.push({
        op: "replace",
        path: "/cidadeId",
        value: consulta.cidadeId,
      });
    if (consulta.departamentoId)
      request.push({
        op: "replace",
        path: "/departamentoId",
        value: consulta.departamentoId,
      });
    if (consulta.escritorioId)
      request.push({
        op: "replace",
        path: "/escritorioId",
        value: consulta.escritorioId,
      });
    if (consulta.tipodocumentoId)
      request.push({
        op: "replace",
        path: "/tipodocumentoId",
        value: consulta.tipodocumentoId,
      });

    return request;
  };

  async gerarExcel(relatorioId: number) {
    // TODO
    const { data: result } = await api.get(
      `Consulta/GerarRelatorio?relatorioId=${relatorioId}`, 
    );

    return result;
  }
}

export default new ConsultaService();
