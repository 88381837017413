export enum ERecorrenciaEnum {
  Diariamente = "daily",
  Semanalmente = "weekly",
  Mensalmente = "monthly",
  Anualmente = "yearly",
}
export const returnarRecorrenciaEnumID = (recorrencia: string) => {
  switch (recorrencia) {
    case ERecorrenciaEnum.Diariamente:
      return 1;

    case ERecorrenciaEnum.Semanalmente:
      return 2;

    case ERecorrenciaEnum.Mensalmente:
      return 3;

    case ERecorrenciaEnum.Anualmente:
      return 4;

    default:
      0;
  }
};