import { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontContext } from "../../../context/FontContext";
import { Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { getNodeBySelection } from "../../Toolbar/controls/common";

const fontNames = [
  "Amatic",
  "Arial",
  "Calibri",
  "Caveat",
  "Comfortaa",
  "Comic Sans Ms",
  "Courier New",
  "EB Garamond",
  "Georgia",
  "Lexend",
  "Lobster",
  "Lora",
  "Merriweather",
  "Montserrat",
  "Nunito",
  "Oswald",
  "Pacifico",
  "Playfair Display",
  "Roboto",
  "Roboto Mono",
  "Roboto Serif",
  "Spectral",
  "Times",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];

export const FontFamily = () => {
  const { fontFamily } = useContext(FontContext);
  const editor = useSlateStatic();
  const [selectedFontFamily, setSelectedFontFamily] = useState(fontFamily);
  const [show, setShow] = useState(false);

  const getFontFamily = (font: string) => font.replace(/\s/g, "") + "Regular";

  const onChangeFont = (font: string) => {
    setSelectedFontFamily(font);

    const fontFamily = getFontFamily(font);
    const selectedNode = getNodeBySelection(editor)

    Transforms.setNodes(editor, {
      style: {
        ...selectedNode?.style,
        fontFamily,
      },
    });
  };

  return (
    <Dropdown onToggle={() => setShow((prev) => !prev)} show={show}>
      <Dropdown.Toggle
        style={{ display: "flex", alignItems: "center" }}
        size="sm"
        variant="text"
      >
        {selectedFontFamily}
        {show ? <MdArrowDropUp size={20} /> : <MdArrowDropDown size={20} />}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ overflow: "scroll", maxHeight: 600 }}>
        {fontNames.map((font) => (
          <Dropdown.Item
            onClick={() => onChangeFont(font)}
            key={font}
            active={font === selectedFontFamily}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <small style={{ fontFamily: getFontFamily(font) }}>{font}</small>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
