/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import TipoLicencaService from "../../services/TipoLicencaService";
import StatusLicencaService from "../../services/StatusLicencaService";
import IStatusLicenca from "../../interfaces/IStatusLicenca";
import ITipoLicenca from "../../interfaces/ITipoLicenca";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaLicencaRequest from "../../interfaces/Requests/Licenca/FiltroPesquisaLicencaRequest";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LicencaFiltroModal from "./LicencaFiltroModal";
import GridPadrao from "../../components/Comum/GridPadrao";
import "./style/icons.css";
import LicencaService from "../../services/LicencaService";
import moment from "moment";
import EstadoService from "../../services/EstadoService";
import IEstado from "../../interfaces/IEstado";
import EmpresaService from "../../services/EmpresaService";
import IEmpresa from "../../interfaces/IEmpresa";
import ICidade from "../../interfaces/ICidade";
import CidadeService from "../../services/CidadeService";
import ITipoServico from "../../interfaces/ITipoServico";
import TipoServicoService from "../../services/TipoServicoService";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";


const filtroInicial: FiltroPesquisaLicencaRequest = {
  licencaId: 0,
  tipoLicencaId: 0,

  numeroLicenca: "",
  nomeSocio: "",
  canal: "",
  fistel: "",
  observacoesLicenca: "",

  restrito: false,

  empresaId: [],
  orgaoResponsavelId: [],
  cidadeId: [],
  estadoId: [],
  cidadeNome: "",
  tipoServicoId: [],
  statusLicencaId: [],

  dataCadastroMaiorIgual: "",
  dataCadastroMenorIgual: "",

  dataEmissaoMaiorIgual: "",
  dataEmissaoMenorIgual: "",

  dataVencimentoMaiorIgual: "",
  dataVencimentoMenorIgual: "",

  limit: 10,
  offset: 0,
  sort: "licencaId",
  totalItems: 0,
};


const LicencaPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaLicencaRequest>(filtroInicial);

  const [carregamentoTipoLicenca, setCarregamentoTipoLicenca] = useState<boolean>(false);
  const [tiposLicencaOptions, setTiposLicencaOptions] = useState<any>([]);
  const [tipoLicencaSelected, setTipoLicencaSelected] = useState([]);

  const [carregamentoTipoServico, setCarregamentoTipoServico] = useState<boolean>(false);
  const [tiposServicoOptions, setTiposServicoOptions] = useState<any>([]);
  const [tipoServicoSelected, setTipoServicoSelected] = useState([]);

  const [carregamentoEstado, setCarregamentoEstado] = useState<boolean>(false);
  const [estadoOptions, setEstadoOptions] = useState<any>([]);
  const [estadoSelected, setEstadoSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresaOptions, setEmpresaOptions] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);

  const [carregamentoStatusLicenca, setCarregamentoStatusLicenca] = useState<boolean>(false);
  const [StatusLicencaOptions, setStatusLicencaOptions] = useState<any>([]);
  const [StatusLicencaSelected, setStatusLicencaSelected] = useState([]);

  const [carregamentoCidades, setCarregamentoCidades] = useState<boolean>(false);
  const [cidadesOption, setCidadesOption] = useState<any>([]);
  const [cidadesSelected, setCidadesSelected] = useState([]);

  const { setLicenca, setTipoLicenca } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [licencas, setLicencas] = useState<ILicenca[]>([]);
  const [carregandoLicencas, setCarregandoLicencas] = useState(false);

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {

    carregarTipoLicenca();

    carregarStatusLicenca();

    carregarEstado();

    carregarCidade();

    carregarEmpresa();

    carregarTipoServico();


  }, []);

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  useEffect(() => {
    if (filtrosPesquisa?.estadoId?.length > 0) {
      carregarCidade();
    }
  }, [filtrosPesquisa.estadoId]);

  useEffect(() => {
    if (pesquisar) carregarLicencas(filtrosPesquisa);
  }, [pesquisar]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoLicencaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoLicencaId: IdsSelected };
    });
  }, [tipoLicencaSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    estadoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, estadoId: IdsSelected };
    });
  }, [estadoSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    empresaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, empresaId: IdsSelected };
    });
  }, [empresaSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusLicencaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusLicencaId: IdsSelected };
    });
  }, [StatusLicencaSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    cidadesSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, cidadeId: IdsSelected };
    });
  }, [cidadesSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoServicoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoServicoId: IdsSelected };
    });
  }, [tipoServicoSelected]);

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  const returnOptionsStatusLicenca = (status: any) => {
    let array: any[] = [];
    status.forEach((item: any) => {
      array.push({
        value: item.statusLicencaId,
        label: item.nome,
      });
    });
    return array;
  };

  const returnOptionsTiposLicenca = (tipos: any) => {
    let array: any[] = [];
    tipos.forEach((item: any) => {
      array.push({
        value: item.tipoLicencaId,
        label: item.nome,
      });
    });
    return array;
  };

  const returnOptionsEstado = (estados: any) => {
    let array: any[] = [];
    estados.forEach((item: any) => {
      array.push({
        value: item.estadoId,
        label: item.nome,
      });
    });
    return array;
  };

  const returnOptionsCidade = (cidades: any) => {
    let array: any[] = [];
    cidades.forEach((item: any) => {
      array.push({
        value: item.cidadeId,
        label: item.nome,
      });
    });
    return array;
  };

  const returnOptionsEmpresa = (empresas: any) => {
    let array: any[] = [];
    empresas.forEach((item: any) => {
      array.push({
        value: item.empresaId,
        label: item.nomeFantasia + " - " + item.numeroDocumento,
      });
    });
    return array;
  };

  const returnOptionsTiposServico = (array: any) => {
    array.forEach((item: any) => {
      tiposServicoOptions.push({
        value: item.tipoServicoId,
        label: item.nome,
      });
    });
    return setTiposServicoOptions;
  };

  const carregarTipoServico = async (): Promise<void> => {
    try {
      setCarregamentoTipoServico(true);
      let resultadoTipoServico: IRecuperaLista<ITipoServico>;
      resultadoTipoServico = await TipoServicoService.obterTipoServicos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoServicoId",
      });
      setTiposServicoOptions(returnOptionsTiposServico(resultadoTipoServico.data));
      setCarregamentoTipoServico(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoServico(false);
      setTiposServicoOptions([]);
    }
  }

  const carregarTipoLicenca = async (): Promise<void> => {
    try {
      setCarregamentoTipoLicenca(true);
      let resultadoTipoLicenca: IRecuperaLista<ITipoLicenca>;
      resultadoTipoLicenca = await TipoLicencaService.obterTipoLicencas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoLicencaId",
      });
      setTiposLicencaOptions(returnOptionsTiposLicenca(resultadoTipoLicenca.data));
      setCarregamentoTipoLicenca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoLicenca(false);
      setTiposLicencaOptions([]);
    }
  }

  const carregarEstado = async (): Promise<void> => {
    try {
      setCarregamentoEstado(true);
      let resultadoEstado: IRecuperaLista<IEstado>;
      resultadoEstado = await EstadoService.obterEstados({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setEstadoOptions(returnOptionsEstado(resultadoEstado.data));
      setCarregamentoEstado(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEstado(false);
      setEstadoOptions([]);
    }
  }

  const carregarEmpresa = async (): Promise<void> => {
    try {
      setCarregamentoEmpresa(true);
      let resultadoEmpresa: IRecuperaLista<IEmpresa>;
      resultadoEmpresa = await EmpresaService.obterEmpresa({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nomeFantasia",
      });
      setEmpresaOptions(returnOptionsEmpresa(resultadoEmpresa.data));
      setCarregamentoEmpresa(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEmpresa(false);
      setEmpresaOptions([]);
    }
  }

  const carregarCidade = async () => {
    try {

      setCidadesOption([]);

      setCidadesSelected([]);

      setCarregamentoCidades(true);

      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidadePorEstados({
        limit: 1000,
        codigo: "",
        offset: 0,
        sort: "cidadeId",
        nome: "",
        totalItems: 0,
        status: 0,
        estadoId: filtrosPesquisa.estadoId
      });
      setCidadesOption(returnOptionsCidade(resultado.data));
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregamentoCidades(false);
    }
  }

  const carregarStatusLicenca = async (): Promise<void> => {
    try {
      setCarregamentoStatusLicenca(true);
      let resultado: IRecuperaLista<IStatusLicenca>;
      resultado = await StatusLicencaService.obterStatusLicencas({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusLicencaId",
      });
      setStatusLicencaOptions(returnOptionsStatusLicenca(resultado.data));
      setCarregamentoStatusLicenca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoStatusLicenca(false);
      setStatusLicencaOptions([]);
    }
  }

  const colunas: TableColumn<ILicenca>[] = [
    {
      cell: (licenca: ILicenca) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setLicenca(licenca);
                  setTipoLicenca({
                    tipoLicencaId: licenca.tipoLicencaId,
                    nome: licenca.tipoLicencaNome,
                    codigo: "",
                    status: licenca.statusLicenca
                  });
                  navigate("/Licenca/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Licenca: #{licenca.licencaId.toString().padStart(5, "0")}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{licenca.statusLicenca}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {licenca.licencaId}
                {licenca.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(licenca.favoritoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(licenca.licencaId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo: </b> {licenca.tipoLicencaNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Cidade: </b> {licenca.cidadeNome}{" "}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Empresa: </b> {licenca.licencaEmpresas.map(empresa => `${empresa.nome}`)}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Serviço: </b> {licenca.tipoServicoNome} <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}> / </b>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Canal: </b> {licenca.canal}<b style={{ fontSize: "14px", color: "var(--primary-base2)" }}></b>
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Emissão: </b> {moment(licenca.dataEmissao).format("DD/MM/yyyy")}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}> - Vencimento: </b> {moment(licenca.dataVencimento).format("DD/MM/yyyy")}{" "}

              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const adicionarFavorito = async (licencaId: number): Promise<void> => {
    try {
      await LicencaService.adicionaFavorido(licencaId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Contrato adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarLicencas(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar contrato como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const removerFavorido = async (favoritoId: number): Promise<void> => {
    try {
      await LicencaService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Contrato removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarLicencas(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<ILicenca>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const carregarLicencas = async (filtro: FiltroPesquisaLicencaRequest) => {
    try {
      setCarregandoLicencas(true);

      let resultado: IRecuperaLista<ILicenca>;
      resultado = await LicencaService.obterLicencas(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setLicencas(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoLicencas(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  return (
    <>
      <LayoutPageTitle title="Regulatório">
        <button onClick={() => navigate("/Licenca/Novo")} className="btn btn-md btn-orange">
          Nova <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <LicencaFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <div className="row">
        <div className="col-md-12" >

          <CollapseFiltro titulo="Filtros" content={
            <form onSubmit={(e) => e.preventDefault()}
              className="row g-4 mb-3 form">

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-tipoLicencaId" className="form-label fw-bolder text-orange">
                  Identificador:
                </label>
                <input
                  value={filtrosPesquisa.licencaId}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, licencaId: e.target.value };
                    });
                  }}
                  placeholder="ID"
                  type="number"
                  className={"form-control"}
                  id="form-tipoLicencaId"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-canal" className="form-label fw-bolder text-orange">
                  Canal:
                </label>
                <input
                  value={filtrosPesquisa.canal}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, canal: e.target.value };
                    });
                  }}
                  placeholder="Canal"
                  type="text"
                  className={"form-control"}
                  id="form-numero"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-numeroLicenca" className="form-label fw-bolder text-orange">
                  Número:
                </label>
                <input
                  value={filtrosPesquisa.numeroLicenca}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, numeroLicenca: e.target.value };
                    });
                  }}
                  placeholder="Número"
                  type="text"
                  className={"form-control"}
                  id="form-numero"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Tipo:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoLicenca}
                  disabled={carregamentoTipoLicenca}
                  options={tiposLicencaOptions}
                  value={tipoLicencaSelected}
                  onChange={(event: any) => setTipoLicencaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-empresa" className="form-label fw-bolder text-orange">
                  Empresa:
                </label>
                <MultiSelect
                  isLoading={carregamentoEmpresa}
                  disabled={carregamentoEmpresa}
                  options={empresaOptions}
                  value={empresaSelected}
                  onChange={(event: any) => setEmpresaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={carregamentoStatusLicenca}
                  disabled={carregamentoStatusLicenca}
                  options={StatusLicencaOptions}
                  value={StatusLicencaSelected}
                  onChange={(event: any) => setStatusLicencaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-estado" className="form-label fw-bolder text-orange">
                  Estado:
                </label>
                <MultiSelect
                  isLoading={carregamentoEstado}
                  disabled={carregamentoEstado}
                  options={estadoOptions}
                  value={estadoSelected}
                  onChange={(event: any) => setEstadoSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Cidade:
                </label>
                <MultiSelect
                  isLoading={carregamentoCidades}
                  disabled={carregamentoCidades}
                  options={cidadesOption}
                  value={cidadesSelected}
                  onChange={(event: any) => setCidadesSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-tipoSerico" className="form-label fw-bolder text-orange">
                  Tipo de Serviço:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoServico}
                  disabled={carregamentoTipoServico}
                  options={tiposServicoOptions}
                  value={tipoServicoSelected}
                  onChange={(event: any) => setTipoServicoSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">
                <label htmlFor="form-nomeSocio" className="form-label fw-bolder text-orange">
                  Sócio:
                </label>
                <input
                  value={filtrosPesquisa.nomeSocio}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nomeSocio: e.target.value };
                    });
                  }}
                  placeholder="Nome ou Documento"
                  type="text"
                  className={"form-control"}
                  id="form-nomeSocio"
                />
              </div>


              <div className="col-12"
                style={{ textAlign: 'right' }}>
                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    setFiltrosPesquisa(filtroInicial);
                    setPesquisar(false);
                    setTipoLicencaSelected([]);
                    setStatusLicencaSelected([]);
                    setCidadesSelected([]);
                    setEstadoSelected([]);
                    setEmpresaSelected([]);
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

              </div>


            </form>} />

          <div className="col-md-12 mb-10" style={{ overflow: !carregandoLicencas ? "auto" : "scroll", height: "600px" }}>

            <div ref={componentRef}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoLicencas}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Licenca"
                itens={licencas}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LicencaPage;

