// globalStyles.ts
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary-base: ${({ theme }) => theme.primaryBase};
    --primary-base2: ${({ theme }) => theme.primaryBase2};
    --primary-base-light1: ${({ theme }) => theme.primaryBaseLight1};
    --primary-base-light2: ${({ theme }) => theme.primaryBaseLight2};
    --primary-base-light3: ${({ theme }) => theme.primaryBaseLight3};
    --primary-base-dark1: ${({ theme }) => theme.primaryBaseDark1};
    --primary-base-dark2: ${({ theme }) => theme.primaryBaseDark2};
    --primary-base-dark3: ${({ theme }) => theme.primaryBaseDark3};
    --secondary-base: ${({ theme }) => theme.secondaryBase};
  }

`;
