import styled, { css } from 'styled-components'

export const HeaderComponent = styled.div<{ selected: boolean }>`
  border: none !important;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;

  > div.container {
    padding: 0;

    ${props => props.selected && css`
      border-style: solid;
      border-color: #ddd;
      border-top-width: 0;
      border-bottom-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      background: #fff;
    `}
  }

  > div.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-top-width: 0;
    background: #f2f2fa;
    padding: 0 10px;

    > div {
      display: flex;
      align-items: center;
      gap: 2px;

      > button {
        background: none;
        border: none;
        border-radius: 4px;
        height: 40px;
        width: 40px;
        transition: opacity 0.4s;

        > svg {
          font-size: 20px;
        }

        &:hover {
          opacity: 0.4;
        }
      }
    }
  }
`;