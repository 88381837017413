/* eslint-disable @typescript-eslint/no-unused-vars */
import { EModulo } from "../enum";
import IDocumento from "../interfaces/IDocumento";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaDocumentoRequest from "../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import api from "./Api";
import apiKurier from "./ApiKurierGetInicial";

class DocumentoService {

  public async downloadRelatorioCustomizado(filtros: any) {

    let query = '';

    if (filtros.despesaId) {
      query += `&despesaId=${filtros.despesaId}`;
    }

    if (filtros.chaveRelatorioCustomizado) {
      query += `&chaveRelatorioCustomizado=${filtros.chaveRelatorioCustomizado}`;
    }

    return await api.get(`/RelatoriosCustomizados/GerarRelatorioByModelo?${query}`, { responseType: "blob" });
  }

  public async downloadRelatorioFeed(filtros: any): Promise<Blob> {

    let query = '';

    query += `&modeloRelatorioId=${filtros.modeloRelatorioId}`;

    if (filtros.usuarioId?.length > 0) filtros.usuarioId.forEach((item: any) => (query += `&usuarioId=${item.value}`));

    if (filtros.grupoUsuarioId?.length > 0) filtros.grupoUsuarioId.forEach((item: any) => (query += `&grupoUsuarioId=${item.value}`));

    if (filtros.escritorioId?.length > 0) filtros.escritorioId.forEach((item: any) => (query += `&escritorioId=${item.value}`));

    const { data: result } = await api.get(`/Compromisso/GerarRelatorioByModeloFeed?${query}`, { responseType: "blob" });

    return result;
  }

  public async download(documentoId: number): Promise<Blob> {
    return await api.get(`/ged/awss3/download/${documentoId}`, { responseType: "blob" });
  }

  public async downloadKurier(url: string) {
    return await apiKurier.get(url, { responseType: "blob" });
  }

  public async previewDocument(documentoId: number): Promise<any> {
    return await api.get<any>(`/ged/awss3/generatePreSignedURL/${documentoId}`);
  }

  public async obterArquivos(filtros: FiltroPesquisaDocumentoRequest): Promise<IRecuperaLista<IDocumento>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.dashboardId) {
      query += `&dashboardId=${filtros.dashboardId}`;
    }

   

    if (filtros.fechamentoAnteriorId) {
      query += `&fechamentoAnteriorId=${filtros.fechamentoAnteriorId}`;
    }

    if (filtros.fechamentoAtualId) {
      query += `&fechamentoAtualId=${filtros.fechamentoAtualId}`;
    }

    if (filtros.documentoId && filtros.documentoId.length > 0) {
      query += filtros.documentoId.map(id => `&documentoId=${id}`).join('')
    }

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    //Modulos
    if (filtros.nome && filtros.nome && filtros.nome.length) query += `&nome=${filtros.nome}`;

    if (filtros.processoId && filtros.modulo.find(e => e) == EModulo.AreaDireito) query += `&processoId=${filtros.processoId}`;

    if (filtros.contratoId && filtros.modulo.find(e => e) == EModulo.Contrato) query += `&contratoId=${filtros.contratoId}`;

    if (filtros.imovelId && filtros.modulo.find(e => e) == EModulo.Imovel) query += `&imovelId=${filtros.imovelId}`;

    if (filtros.empresaId && filtros.empresaId !== 0 && filtros.modulo.find(e => e) == EModulo.Societario) query += `&empresaId=${filtros.empresaId}`;

    if (filtros.marcaId && filtros.marcaId !== 0 && filtros.modulo.find(e => e) == EModulo.MarcaEPertences) query += `&marcaId=${filtros.marcaId}`;

    if (filtros.licencaId && filtros.licencaId !== 0 && filtros.modulo.find(e => e) == EModulo.Regulatorio) query += `&licencaId=${filtros.licencaId}`;

    if (filtros.consultaId && filtros.consultaId !== 0 && filtros.modulo.find(e => e) == EModulo.Consultivo) query += `&consultaId=${filtros.consultaId}`;

    if (filtros.atoSocietarioId && filtros.atoSocietarioId !== 0 && filtros.modulo.find(e => e) == EModulo.AtoSocietario) query += `&atoSocietarioId=${filtros.atoSocietarioId}`;

    if (filtros.procuracaoId && filtros.modulo.find(e => e) == EModulo.Procuracao) query += `&procuracaoId=${filtros.procuracaoId}`;


    // Soluções
    if (filtros.despesaId && filtros.modulo.find(e => e) == EModulo.Despesa) query += `&despesaId=${filtros.despesaId}`;

    if (filtros.decisaoId && filtros.modulo.find(e => e) == EModulo.Decisao) query += `&decisaoId=${filtros.decisaoId}`;

    if (filtros.garantiaId && filtros.modulo.find(e => e) == EModulo.Garantia) query += `&garantiaId=${filtros.garantiaId}`;

    if (filtros.propostaAcordoId && filtros.modulo.find(e => e) == EModulo.PropostaAcordo) query += `&propostaAcordoId=${filtros.propostaAcordoId}`;

    if (filtros.obrigacaoId && filtros.modulo.find(e => e) == EModulo.Obrigacao) query += `&obrigacaoId=${filtros.obrigacaoId}`;

    if (filtros.xfinderProtocoloId && filtros.modulo.find(e => e) == EModulo.Protocolo) query += `&xfinderProtocoloId=${filtros.xfinderProtocoloId}`;

    if (filtros.desdobramentoId && filtros.modulo.find(e => e) == EModulo.Desdobramento) query += `&desdobramentoId=${filtros.desdobramentoId}`;

    if (filtros?.comunicacaoId && filtros.modulo.find(e => e) == EModulo.Comunicacao) query += `&comunicacaoId=${filtros.comunicacaoId}`;

    if (filtros?.parteContrariaId && filtros.modulo.find(e => e) == EModulo.ParteContraria) query += `&parteContrariaId=${filtros.parteContrariaId}`;

    if (filtros?.reembolsoId && filtros.modulo.find(e => e) == EModulo.XpayReembolso) query += `&reembolsoId=${filtros.reembolsoId}`;

    if (filtros?.honorarioId && filtros.modulo.find(e => e) == EModulo.XpayHonorario) query += `&honorarioId=${filtros.honorarioId}`;

    if (filtros.modulo.length > 0) {
      for (let index = 0; index < filtros.modulo.length; index++) {
        const modulo = filtros.modulo[index];

        query += `&modulo=${modulo == EModulo.Protocolo ? EModulo.AreaDireito : modulo}`;
      }
    }

    if (filtros.status && filtros.status !== 0) {
      query += `&status=${filtros.status}`;
    }

    if (filtros.origem && filtros.origem !== 0) {
      query += `&origem=${filtros.origem}`;
    }

    if (filtros.descricao && filtros.descricao.length > 0) {
      query += `&descricao=${filtros.descricao}`;
    }

    if (filtros.codigo && filtros.codigo.length > 0) {
      query += `&codigo=${filtros.codigo}`;
    }

    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }


    if (filtros.tipoDocumentoId && filtros.tipoDocumentoId.length > 0) {
      query += filtros.tipoDocumentoId.map(id => `&tipoDocumentoId=${id}`).join('')
    }

    if (filtros.usuarioIdCadastro && filtros.usuarioIdCadastro.length > 0) {
      query += filtros.usuarioIdCadastro.map(id => `&usuarioIdCadastro=${id}`).join('')
    }

    let { data: result } = await api.get<IRecuperaLista<IDocumento>>(`/Documento/RecuperaDocumento?${query}`);

    return result;
  }

  public async obterArquivo(documentoId: number): Promise<IRecuperaLista<IDocumento>> {

    let { data: result } = await api.get<IRecuperaLista<IDocumento>>(`/Documento/RecuperaDocumento?documentoId=${documentoId}`);

    return result;
  }

  public async upload(dados: { Modulo: number; Origem: string; File: File; TipoDocumentoId: number; Descricao: string; Restrito: string; Codigo: string; TipoContratoId?: number; PastaGedId?: number, TipoProcuracaoId?: number, ModeloDocumentoId?: number }): Promise<any> {
    const dadosFormulario = new FormData()

    dadosFormulario.append('Modulo', dados.Modulo.toString());
    dadosFormulario.append('Origem', dados.Origem);
    dadosFormulario.append('File', dados.File);
    dadosFormulario.append('TipoDocumentoId', dados.TipoDocumentoId.toString());
    dadosFormulario.append('Descricao', dados.Descricao);
    dadosFormulario.append('Restrito', dados.Restrito.toString());
    dadosFormulario.append('Codigo', dados.Codigo);

    if (dados.ModeloDocumentoId) {
      dadosFormulario.append('ModeloDocumentoId', dados.ModeloDocumentoId.toString());
    }

    if (dados.TipoContratoId && dados.TipoContratoId > 0) {
      dadosFormulario.append('TipoContratoId', dados.TipoContratoId.toString());
    }

    if (dados.TipoProcuracaoId && dados.TipoProcuracaoId > 0) {
      dadosFormulario.append('TipoProcuracaoId', dados.TipoProcuracaoId.toString());
    }

    if (dados.PastaGedId && dados.PastaGedId > 0) {
      dadosFormulario.append('PastaGedId', dados.PastaGedId.toString());
    }

    const arquivo = new FileReader();

    const controle = {
      carregando: true,
      arquivoB64: ""
    }

    arquivo.onload = async () => {
      controle.carregando = false;
      controle.arquivoB64 = arquivo.result as string;
    }
    arquivo.onerror = () => {
      controle.carregando = false;
    }
    arquivo.onabort = () => {
      controle.carregando = false;
    }

    arquivo.readAsDataURL(dados.File);

    while (controle.carregando) {
      await new Promise(resolve => setTimeout(resolve, 10));
    }

    const result = await api.post<number>('/ged/awss3/upload', dadosFormulario, { headers: { 'Content-Type': 'multipart/form-data' } });

    return { id: result.data, arquivoB64: controle.arquivoB64 };
  }

  public async inativaDocumento(documentoId: number) {
    let { data: result } = await api.put(`Documento/InativaDocumentoPorId?id=${documentoId}`);

    return result;
  }

  public async atualizaDocumento(editarDocumento: IDocumento): Promise<void> {
    let dataDocumento = [
      { op: "replace", path: "/descricao", value: editarDocumento.descricao },
      { op: "replace", path: "/restrito", value: editarDocumento.restrito },
      { op: "replace", path: "/tipoDocumentoId", value: editarDocumento.tipoDocumentoId },
    ];

    if (editarDocumento.pastaGedId && editarDocumento.pastaGedId > 0) {
      dataDocumento.push({ op: "replace", path: "/pastaGedId", value: editarDocumento.pastaGedId })
    }

    await Promise.all([await api.patch<void>(`Documento/AtualizaDocumento?id=${editarDocumento.documentoId}`, dataDocumento)]);
  }


  public async atualizaAnexoMinuta(documentoId: number, anexoMinuta: boolean): Promise<void> {

    let dataDocumento = [
      { op: "replace", path: "/anexoMinuta", value: anexoMinuta },
    ];

    await Promise.all([await api.patch<void>(`Documento/AtualizaDocumento?id=${documentoId}`, dataDocumento)]);
  }

  public async atualizaTipoProtocoloTribunal(documento: IDocumento): Promise<void> {

    let dataDocumento = [
      { op: "replace", path: "/descricao", value: documento.descricao },
      { op: "replace", path: "/tipoDocumentoId", value: documento.tipoDocumentoId },
      { op: "replace", path: "/tipoXFinderProtocolo", value: documento.tipoProtocoloTribunal },
    ];

    await Promise.all([await api.patch<void>(`Documento/AtualizaDocumento?id=${documento.documentoId}`, dataDocumento)]);
  }

  public async gerarExcel(relatorioId: number): Promise<Blob> {
    const { data: result } = await api.get(`Documento/GerarRelatorio?relatorioId=${relatorioId}`, { responseType: 'blob' });
    return result;
  }

}

export default new DocumentoService();
