import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { faEraser, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as Yup from "yup";

import { ETelaEnum } from "../../../enum/ETelaEnum";
import { tipoPorModulo } from "../../../utils/Utils";
import { EModulo, ETipoCampoEnum } from "../../../enum";
import "../Styles/icons.css";

import LimitacaoTabelaService from "../../../services/LimitacaoTabelaService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import TipoDesdobramentoService from "../../../services/TipoDesdobramentoService";

import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { ICampoDinamico } from "../../../interfaces/ICampoDinamico";
import ITipoDesdobramento from "../../../interfaces/ITipoDesdobramento";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import GridPadrao from "../../../components/Comum/GridPadrao";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { OrangeContext } from "../../../contexts/OrangeProvider";

const TipoDesdobramentoPesquisaPage = () => {
  const { setTipoDesdobramento, setCampos, setTela } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [tipoDesdobramentosLimitada, setTipoDesdobramentosLimitada] = useState<any[]>([]);
  const [tipoDesdobramentos, setTipoDesdobramentos] = useState<ITipoDesdobramento[]>([]);

  const [tipos, setTipo] = useState<any[]>([]);

  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoTipoDesdobramento, setCarregandoTipoDesdobramento] = useState(false);
  const navigate = useNavigate();

  const [filtrosPesquisaLimitada, setFiltrosPesquisaLimitada] = useState<any>({
    nome: "",
    codigo: "",
    categoria: "",
    modulo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "tipoDesdobramentoId",
  });


  const [filtrosPesquisa, setFiltrosPesquisa] = useState<any>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: "tipoDesdobramentoId",
  });

  const campoInicial: ICampoDinamico = {
    campo: 0,
    controller: "",
    isXCampo: false,
    nome: "",
    tipo: ETipoCampoEnum.Lista
  };

  const [campoSistema, setCampoSistema] = useState<ICampoDinamico>(campoInicial);

  const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisaLimitada({ ...filtrosPesquisaLimitada, limit: currentRowsPerPage });

  const handlePageChange = (page: number) => setFiltrosPesquisaLimitada({ ...filtrosPesquisaLimitada, offset: (page - 1) * filtrosPesquisaLimitada.limit });

  const handleSort = async (column: TableColumn<ITipoDesdobramento>, sortDirection: string) => setFiltrosPesquisaLimitada({ ...filtrosPesquisaLimitada, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` });

  const toggleModal = () => setExibirModal(!exibirModal);

  const initialValues = { tipoDesdobramentoId: 0, categoria: 0, modulo: 0 }

  useLayoutEffect(() => {
    setCampos([]);
    carregarCampo("TipoDesdobramento");

    setTela(ETelaEnum.TelaCadastroDesdobramento);

    if (!carregandoTipoDesdobramento) {
      carregarTipoDesdobramento(filtrosPesquisa)
      carregarTipoDesdobramentoLimitada(filtrosPesquisaLimitada);
    }
  }, []);

  const carregarCampo = async (nome: string) => {
    try {

      let resultado: IRecuperaLista<ICampoDinamico>;

      resultado = await ConfiguracaoTelaService.recuperarCampos("label=" + nome);

      setCampoSistema(resultado.data[0]);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCampoSistema(campoInicial);
    }
  }

  const carregarTipoDesdobramento = async (filtro: any) => {
    try {
      setCarregandoTipoDesdobramento(true);

      let resultado: IRecuperaLista<ITipoDesdobramento>;

      resultado = await TipoDesdobramentoService.obterTipoDesdobramentos(filtro);

      setFiltrosPesquisa({ ...filtrosPesquisa, totalItems: resultado.totalRegistros });

      setTipoDesdobramentos(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoDesdobramentos([]);
    } finally {
      setCarregandoTipoDesdobramento(false);
      setLoadingInicial(false);
    }
  }

  const carregarTipoDesdobramentoLimitada = async (filtro: any) => {
    try {

      setCarregandoTipoDesdobramento(true);

      let resultado: IRecuperaLista<any>;
      resultado = await TipoDesdobramentoService.obterListaLimitadaTipoDesdobramentos(filtro);

      setFiltrosPesquisaLimitada({ ...filtrosPesquisaLimitada, totalItems: resultado.totalRegistros });

      setTipoDesdobramentosLimitada(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoDesdobramentosLimitada([]);
    } finally {
      setCarregandoTipoDesdobramento(false);
      setLoadingInicial(false);
    }
  }

  const carregarTipo = async (modulo: EModulo) => {
    try {

      if (!modulo) return setTipo([]);

      let resultado: IRecuperaLista<any>;

      resultado = await ConfiguracaoTelaService.obteTipos(modulo);

      let options: any[] = [];
      resultado.data.forEach(tipo => {
        options.push({
          Id: eval(`tipo.${tipoPorModulo(modulo)}Id`),
          nome: tipo.nome
        })
      });

      setTipo(options);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipo([]);
    }
  }

  const colunas: TableColumn<ITipoDesdobramento>[] = [
    {
      name: "Categoria",
      sortField: "categoriaNome",
      selector: (row: ITipoDesdobramento) => row.categoriaNome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Opção",
      sortField: "opcaoNome",
      selector: (row: ITipoDesdobramento) => row.opcaoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Modulo",
      sortField: "moduloNome",
      selector: (row: ITipoDesdobramento) => row.moduloNome,
      sortable: true,
      wrap: true,
    },
  ];

  const onRowClicked = (row: ITipoDesdobramento) => {
    setTipoDesdobramento({
      ...row,
      categoriaId: row.categoriaId || 0,
      categoriaNome: row.opcaoNome
    });
    navigate("/Desdobramento/ConfiguracaoTela/Capa");
  };

  const limparFiltros = () =>
    setFiltrosPesquisaLimitada({
      ...filtrosPesquisaLimitada,
      nome: "",
      modulo: "",
      status: 1,
    });

  const validacao = () => {
    let schema: any = {};
    schema.tipoDesdobramentoId = Yup.number().min(1, "Selecione o campo").required("Campo tipo é obrigatório");
    schema.categoria = Yup.number().min(1, "Selecione o campo").required("Campo tipo de categoria é obrigatório");
    schema.modulo = Yup.number().min(1, "Selecione o campo").required("Campo tipo de categoria é obrigatório");
    return Yup.object().shape(schema);
  };

  useEffect(() => {
    if (!loadingInicial) {
      carregarTipoDesdobramentoLimitada(filtrosPesquisaLimitada);
    }
  }, [filtrosPesquisaLimitada.offset, filtrosPesquisaLimitada.limit, filtrosPesquisaLimitada.sort]);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validacao,
    onSubmit: async (values, { setSubmitting }) => {
      try {

        let objeto: any = {};

        eval(`objeto.${tipoPorModulo(values.modulo)}Id=${values.categoria}`);

        await LimitacaoTabelaService.adicionaListaLimitadaSolucoes({
          ...objeto,
          campo: campoSistema.campo,
          modulo: values.modulo,
          opcaoId: [values.tipoDesdobramentoId]
        });

        toggleModal();

        await Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Limitação cadastrada com sucesso`,
          showConfirmButton: false,
          timer: 2000
        });

      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          title: `Não foi possivel criar`,
          text: error?.response?.data?.Message,
          showConfirmButton: true
        });
      } finally {
        setSubmitting(false);
        formik.setValues(initialValues);
      }
    }
  });

  return (
    <>
      <LayoutPageTitle title="Selecione o tipo de desdobramento que você deseja configurar">
        <button onClick={() => {
          setExibirModal(true);
        }} className="btn btn-md btn-orange">
          Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarTipoDesdobramentoLimitada(filtrosPesquisaLimitada);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2 ">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Nome
              </label>
              <input
                value={filtrosPesquisaLimitada.nome}
                onChange={(e) => {
                  setFiltrosPesquisaLimitada((oldState: any) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                Módulo:
              </label>
              <select
                className={"form-select"}
                value={filtrosPesquisaLimitada.modulo}
                onChange={(e) => {
                  setFiltrosPesquisaLimitada((oldState: any) => {
                    return { ...oldState, modulo: e.target.value };
                  });
                }}
              >
                <option value="" label="Selecione um modulo" />

                <option value="1" label="Contencioso">
                  Contencioso
                </option>
                <option value="7" label="Contrato">
                  Contrato
                </option>
                <option value="11" label="Marcas & Patentes">
                  Marcas & Patentes
                </option>
                <option value="9" label="Consultivo">
                  Consultivo
                </option>
                <option value="10" label="Imobiliario">
                  Imobiliario
                </option>
                <option value="5" label="Societário">
                  Societário
                </option>
                <option value="30" label="Regulatorio">
                  Regulatorio
                </option>
                <option value="21" label="Procuração">
                  Procuração
                </option>
              </select>
            </div>

            <div className="col-md-2">
              <div>
                <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            onRowClicked={onRowClicked}
            progressPending={carregandoTipoDesdobramento}
            limit={filtrosPesquisaLimitada.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisaLimitada.totalItems}
            colunas={colunas}
            tipo="Tipo de Desdobramento"
            itens={tipoDesdobramentosLimitada}
          />
        </div>
      </div>


      <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Configuração de Desdobramento</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4 mb-2">
                  <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                    Tipo de Desdobramento:
                  </label>
                  <select
                    {...formik.getFieldProps("tipoDesdobramentoId")}
                    value={formik.values.tipoDesdobramentoId}
                    className={'form-select'}
                    onChange={(e) => formik.setFieldValue("tipoDesdobramentoId", parseInt(e.target.value), true)}
                  >
                    <option value="0" label="Selecione um tipo de proposta acordo" />
                    {tipoDesdobramentos.map((tipoDesdobramento) => {
                      return (
                        <option key={tipoDesdobramento.tipoDesdobramentoId} value={tipoDesdobramento.tipoDesdobramentoId}>
                          {" "}
                          {tipoDesdobramento.nome}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 mb-2">
                  <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                    Módulo:
                  </label>
                  <select
                    {...formik.getFieldProps("modulo")}
                    className={"form-select"}
                    value={formik.values.modulo}
                    onChange={(e: any) => {
                      formik.setFieldValue("modulo", parseInt(e.target.value), true);
                      carregarTipo(parseInt(e.target.value));
                    }}
                  >
                    <option value="" label="Selecione um modulo" />

                    <option value="1" label="Contencioso">
                      Contencioso
                    </option>
                    <option value="7" label="Contrato">
                      Contrato
                    </option>
                    <option value="11" label="Marcas & Patentes">
                      Marcas & Patentes
                    </option>
                    <option value="9" label="Consultivo">
                      Consultivo
                    </option>
                    <option value="10" label="Imobiliario">
                      Imobiliario
                    </option>
                    <option value="5" label="Societário">
                      Societário
                    </option>
                    <option value="30" label="Regulatorio">
                      Regulatorio
                    </option>
                    <option value="21" label="Procuração">
                      Procuração
                    </option>
                  </select>
                </div>

                {formik.values.modulo > 0 &&
                  <div className="col-md-4 mb-2">
                    <label htmlFor="form" className="form-label fw-bolder text-orange">
                      Categoria:
                    </label>
                    <select
                      className={'form-select'}
                      {...formik.getFieldProps("categoria")}
                      value={formik.values.categoria}
                      onChange={(e) => formik.setFieldValue("categoria", parseInt(e.target.value), true)
                      }
                    >
                      <option value="0" label="Selecione" />
                      {tipos.map((tipo: any) => {
                        return (
                          <option key={tipo.Id} value={tipo.Id}>
                            {tipo.nome}
                          </option>
                        );
                      })}
                    </select>
                  </div>}
              </div>

            </form>
          </div>
          <div className="modal-footer" style={{ margin: '0 auto' }}>
            <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
              {!formik.isSubmitting && <> SALVAR </>}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

          </div>
        </div>
      </Modal>

    </>
  );
};

export default TipoDesdobramentoPesquisaPage;
