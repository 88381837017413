import styled from "styled-components";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h5 {
    color: var(--bs-orange);
  }

  > button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.4s;
    border-radius: 4px;
    padding: 10px;

    &:hover {
      background: #eee;
    }
  }
`

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  > svg {
    color: var(--bs-orange);
  }

  > div.select {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .select-size {
    height: 40px;
    width: 80px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-end;
`

export const OcurrencyInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 5px;

  > svg {
    color: var(--bs-primary);
  }

  > span {
    font-size: 12px;
  }
`

export const EndDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`