import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faFileArchive, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import clsx from "clsx";

import { ETipoCampoEnum } from "../../../../../enum/ETipoCampoEnum";
import { ECamposIDEnum } from "../../../../../enum/ECamposIDEnum";
import { EModulo, ETelaEnum } from "../../../../../enum";

import ICidade from "../../../../../interfaces/ICidade";
import IEstado from "../../../../../interfaces/IEstado";
import ITipoAto from "../../../../../interfaces/ITipoAto";
import IRecuperaLista from "../../../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaAtoSocietarioRequest } from "../../../../../interfaces/Requests/AtoSocietario/AdicionaAtoSocietarioRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import CidadeService from "../../../../../services/CidadeService";
import EstadoService from "../../../../../services/EstadoService";
import TipoAtoService from "../../../../../services/TipoAtoService";
import AtoSocietarioService from "../../../../../services/AtoSocietarioService";
import ConfiguracaoTelaService from "../../../../../services/ConfiguracaoTelaService";

import { OrangeContext } from "../../../../../contexts/OrangeProvider";
import LayoutPageForButton from "../../../../../layout/LayoutPageButton";
import IFileUploadField from "../../../../../components/FileUpload/FileUploadField";
import CamposCadastroDinamicos from "../../../../../components/AtoSocietario/CamposCadastroDinamicos";
import { atoSocietarioInitialValues, tipoAtoInicialValues } from "../../../../../contexts/InitialValuesContext";
import { ToastContainer } from "react-toastify";
import { notifyFormikDanger } from "../../../../../components/Comum/Toast/ToastFormik";
import Carregando from "../../../../../components/Carregando";

const AtoSocietarioCadastroPage = () => {
  const [carregandoTiposAtoSocietario, setCarregandoTiposAtoSocietario] = useState<boolean>(false);
  const [tiposAtoSocietario, setTiposAtoSocietario] = useState<ITipoAto[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { atoSocietario, setAtoSocietario, setTipoAto, tipoAto, empresa } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [exibirModalImportacaoArquivosCheckList, setExibirModalImportacaoArquivosCheckList] = useState<boolean>(false);

  const [carregandoCidades, setCarregandoCidades] = useState<boolean>(false);
  const [cidades, setCidades] = useState<ICidade[]>([]);
  const [estados, setEstado] = useState<IEstado[]>([]);

  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);
  const [quantidadeArquivosCheckListSelecionados, setQuantidadeArquivosCheckListSelecionados] = useState<number>(0);

  const [adicionarAtoSocietarioLoading, setAdicionarAtoSocietarioLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const toggleImportacaoArquivosCheckList = () => setExibirModalImportacaoArquivosCheckList(!exibirModalImportacaoArquivosCheckList);

  const initialValues: AdicionaAtoSocietarioRequest =
  {
    empresaId: empresa.empresaId,
    atoSocietarioId: 0,
    tipoAtoId: 0,
    tipoDespesaId: 0,
    dataAto: new Date(),
    dataArquivamento: null,
    numeroArquivamento: "",
    textoMateriasDeliberadas: "",
    composicaoMesa: "",
    atoRegistradoJuntaId: 0,
    numeroRegistro: "",
    dataRegistro: null,
    publicacaoAtoId: 0,
    publicacao: "",
    dataPublicacao: null,
    observacoesAto: "",
    codigo: "",
    xCampoValorList: [],
    atoSocietarioMateriaDeliberada: [],
    statusAtoId: 1
  };

  const adicionarShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`${configuracao.campoNome} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Lista:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}Id`] = Yup.number().min(1, `${configuracao.campoNome} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.date().required(`${configuracao.campoNome} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Empresa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Empresa) {
            schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Pessoa:

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno) {
            schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.ParteContraria) {
            schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionarShema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          setAdicionarAtoSocietarioLoading(true);
          let resultado = await AtoSocietarioService.adicionaAtoSocietario({
            ...values,
            tipoAtoId: parseInt(tipoAto.tipoAtoId.toString()),
            xCampoValorList: carregarObjetoXCampos()
          });
          setAtoSocietario(resultado);
          setTipoAto(tipoAto);
          formik.resetForm();
          setQuantidadeArquivosSelecionados(0);
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `AtoSocietario cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarAtoSocietarioLoading(false);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarAtoSocietarioLoading(false);
      }
    },
  });

  useEffect(() => {

    setAtoSocietario(atoSocietarioInitialValues);

    setTipoAto(tipoAtoInicialValues);

    carregarTipoAtoSocietario();

    setConfiguracoesTela([]);

  }, []);

  useEffect(() => {
    if (tipoAto.tipoAtoId > 0) {
      carregarConfiguracaoTela();
      carregaEstado();
    } else {
      setConfiguracoesTela([]);
      setTipoAto(tipoAtoInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoAto.tipoAtoId]);

  useEffect(() => {
    if (formik.values.estadoId > 0) carregaCidade();
  }, [formik.values.estadoId]);

  const carregarObjetoXCampos = () => {
    let xCampoValorList = new Array<any>;
    configuracoesTela.forEach(configuracao => {

      if (configuracao?.isXCampo) {
        switch (configuracao.campoTipo) {
          case ETipoCampoEnum.AreaTexto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Texto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Data:
            xCampoValorList.push({ campoId: configuracao.campo, data: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Valor:
          case ETipoCampoEnum.Percentual:
            xCampoValorList.push({ campoId: configuracao.campo, valor: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Inteiro:
            xCampoValorList.push({ campoId: configuracao.campo, inteiro: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Lista:
            xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Boolean:
            xCampoValorList.push({ campoId: configuracao.campo, boolean: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;
        }
      }
    });
    return xCampoValorList;
  }

  const carregarTipoAtoSocietario = async () => {
    try {
      setCarregandoTiposAtoSocietario(true);
      let resultado = await TipoAtoService.obterTipoAtos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTiposAtoSocietario(resultado.data);
      setCarregandoTiposAtoSocietario(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
      setTiposAtoSocietario([]);
    } finally {
      setCarregandoTiposAtoSocietario(false);
    }
  };


  const carregaCidade = async () => {
    try {
      setCidades([]);
      setCarregandoCidades(true);

      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidadePorFiltros({
        estadoId: formik.values.estadoId,
        IBGE: "",
        nome: "",
        status: 1,
        limit: 900,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setCarregandoCidades(false);
      setCidades(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCidades([]);
    }
  };

  const carregaEstado = async () => {
    try {
      if (estados.length > 0) return;
      let resultado: IRecuperaLista<IEstado>;
      resultado = await EstadoService.obterEstados({
        nome: "",
        codigo: "",
        status: 0,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "estadoId",
      });
      setEstado(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setEstado([]);
    }
  };


  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);
      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        TipoAtoId: tipoAto.tipoAtoId,
        Modulo: EModulo.AtoSocietario,
        Tela: ETelaEnum.TelaCadastroAtoSocietario,
      });
      setConfiguracoesTela(resultado.data);
    } catch (error: any) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const onChangeTipoAtoSocietario = (value: number, texto: string) => {
    setConfiguracoesTela([]);
    formik.resetForm();
    formik.setFieldValue("tipoAtoId", value);
    setTipoAto({
      tipoAtoId: value,
      nome: texto,
      codigo: "",
      status: "",
    });
  };

  const renderDefault = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <CamposCadastroDinamicos
          configuracaoTela={configuracaoTela}
          formik={formik}
        />
      </>
    );
  };

  const renderCidade = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <div className="col-md-3 mt-3">
          {carregandoCidades ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              Carregando cidades...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <>
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                {configuracaoTela.label}
                <a style={{ fontSize: "12px" }}>
                  {configuracaoTela.obrigatorio ? "*:" : ":"}
                </a>
              </label>
              <select
                name="cidadeId"
                value={formik.values.cidadeId}
                onChange={(e) => {
                  formik.setFieldTouched("cidadeId", true);
                  formik.setFieldValue("cidadeId", parseInt(e.target.value));
                }}
                className={clsx(
                  "form-select",
                  {
                    "is-invalid": formik.touched.cidadeId && formik.errors.cidadeId,
                  },
                  {
                    "is-valid": formik.touched.cidadeId && !formik.errors.cidadeId,
                  }
                )}
                id="form-cidadeId"
              >
                <option value="0">Selecione</option>
                {cidades.map((map: any) => {
                  return (
                    <option key={map.nome} value={map.cidadeId}>
                      {map.nome}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </>
    );
  };

  const renderEstado = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <div className="col-md-3 mt-3">
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
            <a style={{ fontSize: "12px" }}>
              {configuracaoTela.obrigatorio ? "*:" : ":"}
            </a>
          </label>
          <select
            name="estadoId"
            value={formik.values.estadoId}
            onChange={(e) => {
              formik.setFieldTouched("estadoId", true);
              formik.setFieldValue("estadoId", parseInt(e.target.value));
            }}
            className={clsx(
              "form-select",
              {
                "is-invalid": formik.touched.estadoId && formik.errors.estadoId,
              },
              {
                "is-valid": formik.touched.estadoId && !formik.errors.estadoId,
              }
            )}
            id="form-estadoId"
          >
            <option value="0">Selecione</option>
            {estados.map((map: any) => {
              return (
                <option key={map.nome} value={map.estadoId}>
                  {map.nome}
                </option>
              );
            })}
          </select>
        </div>
      </>
    );
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      if (configuracaoTela.campoNome == ECamposIDEnum.Estado) {
        component.push(renderEstado(configuracaoTela));
        return;
      }

      if (configuracaoTela.campoNome == ECamposIDEnum.Cidade) {
        component.push(renderCidade(configuracaoTela));
        return;
      }

      if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        component.push(renderImportadorArquivos());
        return;
      }

      if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumentoCheckList) {
        component.push(renderImportadorArquivosCheckList());
        return;
      } else {
        component.push(renderDefault(configuracaoTela));
      }
    });

    return <div className="row mt-12">{component}</div>;
  };

  const renderImportadorArquivos = () => {
    return (
      <>
        {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumento).length > 0 && (
          <>
            <div className="row mt-3">
              <div className="col-md-4 mt-3">
                <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                  {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumento)[0]?.label}:
                </label>
                <br></br>
                <Button
                  onClick={() => setExibirModalImportacaoArquivos(true)}
                  style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                  variant="secondary"
                  size="sm"
                >
                  <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                  Selecione os arquivos do consulta
                </Button>
              </div>
              <IFileUploadField
                modulo={EModulo.AtoSocietario}
                setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                exibirModal={exibirModalImportacaoArquivos}
                toggleModal={toggleImportacaoArquivos}
                consultaId={atoSocietario.atoSocietarioId}
                tipoConsultaId={tipoAto.tipoAtoId}
              />
            </div>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
                {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
                {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
              </a>
            </div>
          </>
        )}
      </>
    );
  };

  const renderImportadorArquivosCheckList = () => {
    return (
      <>
        {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumentoCheckList).length > 0 && (
          <>
            <div className="row mt-3">
              <div className="col-md-4 mt-3">
                <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                  {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumentoCheckList)[0]?.label}:
                </label>
                <br></br>
                <Button
                  onClick={() => setExibirModalImportacaoArquivosCheckList(true)}
                  style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                  variant="secondary"
                  size="sm"
                >
                  <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                  Selecione os arquivos do consulta
                </Button>
              </div>
              <IFileUploadField
                modulo={EModulo.AtoSocietario}
                setQuantidadeArquivosSelecionados={setQuantidadeArquivosCheckListSelecionados}
                exibirModal={exibirModalImportacaoArquivosCheckList}
                toggleModal={toggleImportacaoArquivosCheckList}
                consultaId={atoSocietario.atoSocietarioId}
                tipoConsultaId={tipoAto.tipoAtoId}
              />
            </div>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {quantidadeArquivosCheckListSelecionados === 0 && "Nenhum arquivos selecionado"}
                {quantidadeArquivosCheckListSelecionados === 1 && `${quantidadeArquivosCheckListSelecionados} arquivos selecionado`}
                {quantidadeArquivosCheckListSelecionados > 1 && `${quantidadeArquivosCheckListSelecionados} arquivos selecionados`}
              </a>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <LayoutPageForButton title={`Novo Ato Societário ${tipoAto.tipoAtoId > 0 ? " - " + tipoAto.nome : ""}`}>
        {atoSocietario.atoSocietarioId > 0 ? (
          <>
            <button onClick={() => navigate("/Ato/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{atoSocietario.atoSocietarioId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoAtoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarAtoSocietarioLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarAtoSocietarioLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarAtoSocietarioLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposAtoSocietario ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de atoSocietario...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Ato Societário
            </label>
            <select
              name="tipoAtoId"
              value={tipoAto.tipoAtoId}
              className={"form-select"}
              onChange={(event: any) => onChangeTipoAtoSocietario(event.target.value, event.target[event.target.selectedIndex].text)}
              placeholder="Nome"
              id="form-area"
            >
              <option value="0"> Selecione um tipo de ato societário</option>
              {tiposAtoSocietario.map((tipoAto) => {
                return (
                  <option key={tipoAto.tipoAtoId} value={tipoAto.tipoAtoId}>
                    {tipoAto.nome}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default AtoSocietarioCadastroPage;
