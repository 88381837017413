import React, { createContext, useCallback, useState } from "react";

export const TitleContext = createContext(
  {} as {
    docTitle: string;
    setDocTitle: (title?: string | null) => void;
  }
);

export function TitleProvider({ children }: { children: React.ReactNode }) {
  const [docTitle, setDocTitle] = useState("Sem título");

  const setTitle = useCallback((title?: string | null) => {
    setDocTitle(title ?? "Sem título");
  }, []);

  return (
    <TitleContext.Provider value={{ docTitle, setDocTitle: setTitle }}>
      {children}
    </TitleContext.Provider>
  );
}
