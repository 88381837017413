import api from "./Api";

import { EModulo, ETipoCampoEnum } from "../enum";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import IComunicacao from "../interfaces/IComunicacao";
import { AdicionaComunicacaoRequest } from "../interfaces/Requests/Comunicacao/AdicionaComunicacaoRequest";
import FiltroPesquisaComunicacaoRequest from "../interfaces/Requests/Comunicacao/FiltroPesquisaComunicacaoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class ComunicacaoService {

    public async obterComunicacoes(filtros: FiltroPesquisaComunicacaoRequest): Promise<IRecuperaLista<IComunicacao>> {
        let query = "";

        if (filtros?.ultimosQuantidade) {
            query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.ultimosQuantidade
                }&sort=${filtros.sort}`;
        } else {
            query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort
            }`;
        }

        if (filtros.tipoPesquisa == 1) {
            query += `&descricao=${filtros.filtro}`;
        }

        if (filtros.comunicacaoId) {
            query += `&comunicacaoId=${filtros.comunicacaoId}`;
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }
        if (filtros.contratoId) {
            query += `&contratoId=${filtros.contratoId}`;
        }

        if (filtros.tipoPesquisa == 2) {
            query += `&codigo=${filtros.filtro}`;
        }

        if (filtros?.tipoComunicacaoId?.length > 0) {
            for (let index = 0; index < filtros.tipoComunicacaoId.length; index++) {
                const tipoComunicacaoId = filtros.tipoComunicacaoId[index];
                query += `&tipoComunicacaoId=${tipoComunicacaoId}`;
            }
        }

        let { data: result } = await api.get<IRecuperaLista<IComunicacao>>(`Comunicacao/RecuperaComunicacao?${query}`);

        return result;

    }

    public async obterComunicacaoPorId(comunicacaoId: number): Promise<IRecuperaLista<IComunicacao>> {
        let query = `&offset=0&limit=100&sort=comunicacaoId`

        query += `&comunicacaoId=${comunicacaoId}`;

        let { data: result } = await api.get<IRecuperaLista<IComunicacao>>(`Comunicacao/RecuperaComunicacao?${query}`);

        return result;
    }

    public async adicionaComunicacao(adicionaComunicacao: any): Promise<IComunicacao> {
        let { data: result } = await api.post<IComunicacao>(`Comunicacao/AdicionaComunicacao`, this.montarObjetoRequest(adicionaComunicacao));

        return result;
    }

    public async editarComunicacao(editarComunicacao: AdicionaComunicacaoRequest) {
        let request = this.montarObjetoArray(editarComunicacao);

        if (request.length > 0) {
            let result = await api.patch(`Comunicacao/AtualizaComunicacaoPorId?id=${editarComunicacao.comunicacaoId}`, request);
            return result;
        }
    }

    public async adicionaFavorido(comunicacaoId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Comunicacao, comunicacaoId: comunicacaoId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async obterCapaComunicacao(comunicacaoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Comunicacao/RecuperaCapaComunicacao?comunicacaoId=${comunicacaoId}`);
        return result;
    }

    async alterarStatus(comunicacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IComunicacao>(`Comunicacao/AtualizaComunicacaoPorId?id=${comunicacaoId}`, data);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Comunicacao/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarComunicacaoPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Comunicacao/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    private montarObjetoArray = (comunicacao: AdicionaComunicacaoRequest) => {
        let request: any = [];

        if (comunicacao.codigo) request.push({ op: "replace", path: "/codigo", value: comunicacao.codigo });
        if (comunicacao.descricao) request.push({ op: "replace", path: "/descricao", value: comunicacao.descricao });
        if (comunicacao.datacomunicacao) request.push({ op: "replace", path: "/dataComunicacao", value: comunicacao.datacomunicacao });

        return request;
    };

    private montarObjetoRequest = (comunicacao: AdicionaComunicacaoRequest) => {
        let request: any = {};

        if (comunicacao.xCampoValorList.length > 0) request.xCampoValorList = comunicacao.xCampoValorList;

        if (comunicacao.tipoComunicacaoId) request.tipoComunicacaoId = comunicacao.tipoComunicacaoId;

        if (comunicacao.codigo) request.codigo = comunicacao.codigo;
        if (comunicacao.descricao) request.descricao = comunicacao.descricao;
        if (comunicacao.datacomunicacao) request.dataComunicacao = comunicacao.datacomunicacao;

        if (comunicacao.processoId) {
            request.modulo = EModulo.AreaDireito;
            request.processoId = comunicacao.processoId;
            request.areaDireitoId = comunicacao.areaDireitoId;
        }

        if (comunicacao.contratoId) {
            request.modulo = EModulo.Contrato;
            request.contratoId = comunicacao.contratoId;
            request.tipoContratoId = comunicacao.tipoContratoId;
        }

        return request;
    };

    public concluirCompromisso = async (comunicacao: AdicionaComunicacaoRequest): Promise<any> => {

        let result: any[] = [];

        // Salvar campos normais
        result.push(await this.editarComunicacao(comunicacao));

        // Salvar xcampos
        if (comunicacao.xCampoValorList?.length > 0) {
            comunicacao.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, comunicacaoId: comunicacao.comunicacaoId, texto: xCampo.texto }));
                        else result.push(await this.alterarComunicacaoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, comunicacaoId: comunicacao.comunicacaoId, data: xCampo.data }));
                        else result.push(await this.alterarComunicacaoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, comunicacaoId: comunicacao.comunicacaoId, valor: xCampo.valor }));
                        else result.push(await this.alterarComunicacaoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, comunicacaoId: comunicacao.comunicacaoId, inteiro: xCampo.inteiro }));
                        else result.push(await this.alterarComunicacaoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, comunicacaoId: comunicacao.comunicacaoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarComunicacaoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        // results
        return result;
    };
}


export default new ComunicacaoService();