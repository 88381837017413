import { useSlateStatic } from "slate-react";
import { FormContainer, Input, InputContainer } from "./styles";
import React, { useMemo, useState } from "react";
import { Editor } from "slate";
import { isValidUrl } from "../../../util";
import { insertLink } from "../../../lib/slate/plugins/withLinks";

type LinkPopoverProps = {
  onHide: () => void
}

export const LinkPopover = ({ onHide }: LinkPopoverProps) => {
  const editor = useSlateStatic();

  const [errorState, setErrorState] = useState({
    message: {},
    inputList: [""],
  });

  const [form, setForm] = useState({
    text: "",
    url: "",
  });

  const selectedText = useMemo(() => {
    const { selection } = editor
    return selection ? Editor.string(editor, selection) : ""
  }, [editor])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorState((prev) => ({
      message: {
        ...prev.message,
        [e.target.name]: "",
      },
      inputList: prev.inputList.filter((list) => list !== e.target.name),
    }));

    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
 
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidUrl(form.url)) {
      setErrorState((prev) => ({
        error: true,
        message: {
          ...prev.message,
          url: "Link inválido.",
        },
        inputList: [...prev.inputList, "url"],
      }));
      return;
    }

    insertLink(editor, form.url, form.text)
    onHide()
  };

  const disabled = useMemo(() => {
    if (selectedText) {
      return !form.url
    }
    return !form.text || !form.url
  }, [selectedText, form])

  return (
    <FormContainer onSubmit={handleSubmit}>
      {!selectedText && (
        <InputContainer>
          <small>Texto</small>
          <Input
            error={errorState.inputList.includes("text")}
            onChange={onChange}
            name="text"
            type="text"
            placeholder="Meu link..."
          />
          <p>{(errorState.message as any)?.text}</p>
        </InputContainer>
      )}
      <InputContainer>
        <small>Link</small>
        <Input
          error={errorState.inputList.includes("url")}
          onChange={onChange}
          name="url"
          type="text"
          placeholder="https://exemplo.com"
        />
        <p>{(errorState.message as any)?.url}</p>
      </InputContainer>
      <button disabled={disabled} type="submit">
        Aplicar
      </button>
    </FormContainer>
  );
};
