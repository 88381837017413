"use client";

import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { useNodesState, Node, Background, MarkerType, useEdgesState, addEdge, Edge, ReactFlowProvider, MiniMap } from 'reactflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from "react-number-format";
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import clsx from 'clsx';

import ENodeEnum from '../../../../../enum/ENodeEnum';
import { currencyFormatter } from '../../../../../utils/Utils';
import { EModulo, ETipoCampoEnum, returnarEnumDescricaoID } from '../../../../../enum';

import NodeFim from './NodeFinal';
import NodeFase from './NodeFase';
import NodeRaia from './NodeRaia';
import NodeInicio from './NodeIniciar';
import NodeCondicao from './NodeCondicao';

import RegraOperacaoService from '../../../../../services/RegraOperacaoService';
import sessionStorageService from '../../../../../services/sessionStorage/sessionStorageService';

import FloatingEdge from '../util/FloatingEdge';
import CustomEdgeLabel from '../util/CustomEdgeLabel';
import SideBarSelection from '../util/SidebarSelection';
import CustomConnectionLine from '../util/CustomConnectionLine';

import 'reactflow/dist/style.css';

import ListaXSelected from '../../../../../components/Comum/ListaXCamposSelected';
import ListaSelected from '../../../../../components/Comum/ListaCamposSelectedField';
import { notifyDanger, notifySuccess } from '../../../../../components/Comum/Toast/ToastFormik';

export const connectionLineStyle = { strokeWidth: 1, stroke: 'orange' };

export const initialSelectedEdge: Edge = {
  type: "",
  source: "",
  target: "",
  id: '',
  data: {
    label: '',
    codigo: '',
    operacao: ''
  }
}

export const nodeTypes = {
  NodeFim: NodeFim,
  NodeRaia: NodeRaia,
  NodeFase: NodeFase,
  NodeInicio: NodeInicio,
  NodeCondicao: NodeCondicao,
};

export const edgeTypes: any = {
  edgeLabel: CustomEdgeLabel,
  floating: FloatingEdge,
};

const Fluxo = ({ initialNodes, initialEdges, onNodesEdgesChange }: { initialNodes: Node[], initialEdges: Edge[], onNodesEdgesChange: any }) => {

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [operations, setOperation] = useState([]);
  const [exibirModal, setExibirModal] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState<Edge>(initialSelectedEdge);

  const flowRef: any = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

  useEffect(() => { onNodesEdgesChange({ nodes, edges }) }, [nodes, edges]);

  useEffect(() => {
    const handleLocalStorageChange = (event: any) => {
      const { chave, valor } = event.detail;
      const objValor = JSON.parse(valor);
      if (chave === "apagaNode") {
        const nodesFiltrados = nodes.filter((node) => node.id !== objValor.id);
        setNodes(nodesFiltrados);
      }

      if (chave === "atualizaNode") {
        const nodesFiltrados = nodes.map((node) =>
          node.id === objValor.id ? { ...node, data: objValor.data } : node
        );
        setNodes(nodesFiltrados);
      }
    };

    window.addEventListener('localStorageAtualizado', handleLocalStorageChange);

    return () => {
      window.removeEventListener('localStorageAtualizado', handleLocalStorageChange);
    };
  }, [nodes]);

  useEffect(() => {
    const handleLocalStorageChange = (event: any) => {
      const { chave, valor } = event.detail;
      const objValor = JSON.parse(valor);
      if (chave === "apagaEdge") {
        const edgesFiltrados = edges.filter((edge) => edge.id !== objValor.id);
        setEdges(edgesFiltrados);
      }
    };

    window.addEventListener('localStorageAtualizado', handleLocalStorageChange);

    return () => {
      window.removeEventListener('localStorageAtualizado', handleLocalStorageChange);
    };
  }, [edges]);

  const schema = Yup.object({
    Lista: Yup.array()
      .when('campoTipo', {
        is: 'Lista',
        then: Yup.array().min(1, 'Campo Opções é obrigatório'),
        otherwise: Yup.array().notRequired(),
      }),
    operacao: Yup.string().required('Operação é obrigatória'),
    valor: Yup.number()
      .when('campoTipo', {
        is: 'Valor',
        then: Yup.number()
          .required('Valor é obrigatório')
          .moreThan(0, 'Valor deve ser maior que zero'),
        otherwise: Yup.number().notRequired(),
      }),
  });

  const atualizar = (chave: any, valor: any) => {

    sessionStorageService.inserir(chave, valor);

    const evento = new CustomEvent('localStorageAtualizado', { detail: { chave, valor } });

    window.dispatchEvent(evento);
  };

  const initialValues = {
    nome: '',
    label: '',
    codigo: "",
    campoId: 0,
    operacao: '',
    campoNome: '',
    isXCampo: false,
    tabelaOrigemId: 0,
    valor: 0,

    Lista: [],

    faseInicialId: 0,
    modulo: EModulo.Compromisso,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {

        let node: any = nodes.find((e: any) => e.id == values.id);
        if (node?.data?.condicoes) {
          let updateCondicao = node.data.condicoes.filter((e: any) => e.codigo != values.codigo) || [];
          updateCondicao.push({ ...values, regras: parseRegras() });
          node.data.condicoes = updateCondicao;
        } else {
          node.data.condicoes = [];
          node.data.condicoes.push({ ...values, regras: parseRegras() });
        }

        atualizar("atualizaNode", JSON.stringify({ data: node?.data, id: node.id }));

        notifySuccess('Opções da regra adicionadas');

      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        toggleModal();
      }
    },
  });


  const parseRegras = () => {
    if (formik.values.campoTipo == ETipoCampoEnum.Lista)
      return parseRegrasLista();
    else
      return parseRegrasValor();

  }

  const parseRegrasLista = () => {
    let options: any[] = [];
    formik.values.Lista.forEach((opcao: any) => {
      options.push({
        nome: formik.values.nome,
        valor: formik.values.isXCampo ? opcao.xCampoOpcaoId : opcao[`${returnarEnumDescricaoID(formik.values.campoNome)}`],
        codigo: formik.values.codigo,
        campoId: formik.values.campoId,
        operacao: formik.values.operacao,
        tabelaOrigemId: formik.values.tabelaOrigemId,
      })
    });
    return options;
  }

  const parseRegrasValor = () => {
    let options: any[] = [];
    options.push({
      nome: formik.values.nome,
      valor: formik.values.valor,
      codigo: formik.values.codigo,
      campoId: formik.values.campoId,
      operacao: formik.values.operacao,
      tabelaOrigemId: formik.values.tabelaOrigemId,
    });
    return options;
  }

  const faseComFase = (sourceNode: Node, params: any) => {
    if (sourceNode?.data?.condicoes) {
      let updateCondicao = sourceNode.data.condicoes.filter((e: any) => e.codigo !== `${params.target}${params.source}`) || [];
      updateCondicao.push({ codigo: `${params.target}${params.source}`, codigoProximaFaseId: params.target });
      sourceNode.data.condicoes = updateCondicao;
    } else {
      sourceNode.data.condicoes = [];
      sourceNode.data.condicoes.push({ codigo: `${params.target}${params.source}`, codigoProximaFaseId: params.target });
    }
    atualizar("atualizaNode", JSON.stringify({ data: sourceNode.data, id: sourceNode.id }));
  };

  const criarEdge = (params: any, formikValues: any) => {
    return {
      ...params,
      style: { strokeWidth: 1, stroke: 'black' },
      type: 'floating',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: 'black',
      },
      data: {
        label: formikValues.label,
        operacao: formikValues.operacao
      }
    };
  };

  const onConnect = useCallback((params: any) => {
    const sourceNode = nodes.find(node => node.id === params.source);
    const targetNode = nodes.find(node => node.id === params.target);

    if (sourceNode?.type === ENodeEnum.NodeCondicao && targetNode?.type === ENodeEnum.NodeCondicao) {
      notifyDanger('Cenário invalido é necessário uma fase.');
      return;
    }

    if (sourceNode?.type === ENodeEnum.NodeFase && targetNode?.type === ENodeEnum.NodeFase) {
      faseComFase(sourceNode, params);
    }

    const newEdge = criarEdge(params, formik.values);

    setEdges((eds) => addEdge(newEdge, eds));

  }, [nodes, edges, setEdges, formik.values.label, formik.values.operacao]);


  const onDragOver = useCallback((event: any) => {

    event.preventDefault();

    event.dataTransfer.dropEffect = 'move';

  }, []);

  const onDrop = useCallback((event: any) => {

    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow');

    if (typeof type === 'undefined' || !type) {
      return;
    }
    const flowRect = flowRef.current.getBoundingClientRect();
    const position = reactFlowInstance.project({
      x: event.clientX - flowRect.left,
      y: event.clientY - flowRect.top
    })

    let idNode = "";
    let dataNode = {};
    let styleNode = {};

    if (type == ENodeEnum.NodeFase) {
      idNode = uuidv4()
      dataNode = {
        nome: '',
        tipoCompromisso: '',
        faseInicial: false,
        observacao: '',
        responsavel: null,
        tipoSla: '',
        tabelaOrigem: '',
        campoSla: '',
        sla: null,
      }
    }

    if (type == ENodeEnum.NodeCondicao) {
      idNode = uuidv4()
      dataNode = {
        condicao: ''
      }
    }

    if (type == ENodeEnum.NodeRaia) {
      idNode = uuidv4()
      dataNode = {
        nome: '',
      }
      styleNode = {
        zIndex: '-2'
      }
    }

    if (type == ENodeEnum.NodeInicio) {
      idNode = ENodeEnum.NodeInicio
    }

    if (type == ENodeEnum.NodeFim) {
      idNode = ENodeEnum.NodeFim
    }

    const newNode = {
      id: idNode,
      type,
      position,
      data: dataNode,
      style: styleNode
    }

    setNodes((nds) => nds.concat(newNode));
  }, [reactFlowInstance]);

  const carregarOperacoes = useCallback(async () => {
    try {
      const resultados: any = await RegraOperacaoService.obter({
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setOperation(resultados);
    } catch (error) {
      console.error("Error in tiposCompromisso:", error);
    }
  }, []);

  useEffect(() => {
    if (exibirModal) {
      carregarOperacoes();

    }
  }, [formik.values.campoId, exibirModal]);


  const onEdgeClick = useCallback((e: any, edge: any) => {

    e.stopPropagation();

    let source: any = nodes.find(node => node.id === edge.source) || {};
    let target: any = nodes.find(node => node.id === edge.target) || {};

    if (!source.data.campoId) {
      notifyDanger('Informar o campo para seleção da regra na condição.');
      return;
    }

    formik.setFieldValue('id', source.id);
    formik.setFieldValue('nome', source.data.nome);
    formik.setFieldValue('campoId', source.data.campoId);
    formik.setFieldValue('isXCampo', source.data.isXCampo);
    formik.setFieldValue('campoNome', source.data.campoNome);
    formik.setFieldValue('campoTipo', source.data.campoTipo);

    if (source.type == ENodeEnum.NodeCondicao) {
      formik.setFieldValue('codigo', `${edge.target}${edge.source}`);
      formik.setFieldValue('tabelaOrigemId', source.data.tabelaOrigemId);
      formik.setFieldValue('codigoProximaFaseId', target.data.faseInicial ? null : target.id);
      formik.setFieldValue('codigoAnteriorFaseId', target.data.faseInicial ? target.id : source.data.codigoAnteriorFaseId);

      formik.setFieldValue('label', source?.data?.condicoes?.find((e: any) => e.codigo == `${edge.target}${edge.source}`)?.label);
      formik.setFieldValue('valor', source?.data?.condicoes?.find((e: any) => e.codigo == `${edge.target}${edge.source}`)?.valor);
      formik.setFieldValue('operacao', source?.data?.condicoes?.find((e: any) => e.codigo == `${edge.target}${edge.source}`)?.operacao);
      formik.setFieldValue('Lista', source?.data?.condicoes?.find((e: any) => e.codigo == `${edge.target}${edge.source}`)?.Lista || []);

    }

    setExibirModal(true);

  }, [nodes, edges]);


  const confirmar = useCallback((edge: any) => {

    const updatedEdges = edges.map((e) => e.id === edge.id ? edge : e);

    setEdges(updatedEdges);

    formik.handleSubmit();

  }, [edges, formik]);

  const excluir = useCallback(() => {

    let newEdges = edges.filter(edge => edge.id !== selectedEdge.id);

    setEdges(newEdges);

    toggleModal();

  }, [edges, selectedEdge]);

  const toggleModal = useCallback(() => {

    setExibirModal(!exibirModal);

    setSelectedEdge(initialSelectedEdge);

  }, [exibirModal]);

  return (
    <ReactFlow
      nodes={nodes}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}

      edges={edges}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      onEdgeClick={onEdgeClick}
      onEdgesChange={onEdgesChange}

      ref={flowRef}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onInit={setReactFlowInstance}
      connectionLineStyle={connectionLineStyle}
      connectionLineComponent={CustomConnectionLine}
      fitView
    >
      <MiniMap />
      <Background gap={10} size={1} color="#1e1313" />

      <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Configuração de Condição - {formik.values.campoNome}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <div className="row">

              <div className="col-12">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange"> {formik.values.nome}?</label>
              </div>
              <div className="col-6 mt-3">

                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Label:</label>

                <input
                  type="text"
                  {...formik.getFieldProps('label')}
                  className={"form-control"}
                />

                {formik.touched.label && formik.errors.label && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.label}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Operação:</label>
                <select
                  {...formik.getFieldProps('operacao')}
                  className={"form-select"}
                >
                  <option value="" label="Selecione um tipo de compromisso">
                    Selecione um tipo de operação
                  </option>
                  {Array.isArray(operations)
                    ? operations.map((resultado: any) => (
                      <option key={resultado} value={resultado}>
                        {resultado}
                      </option>
                    ))
                    : null}
                </select>
                {formik.touched.operacao && formik.errors.operacao && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.operacao}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {formik.values.campoTipo == ETipoCampoEnum.Lista &&
              <div className="row">
                <div className="col-12 mt-3">
                  {formik.values.isXCampo ?
                    <ListaXSelected
                      xCampoId={formik.values.campoId}
                      setFieldValue={formik.setFieldValue}
                      ListaSelected={formik.values.Lista || []}
                    /> :
                    <ListaSelected
                      entidade={formik.values.campoNome}
                      setFieldValue={formik.setFieldValue}
                      ListaSelected={formik.values.Lista || []}
                    />}
                  {formik.touched.Lista && formik.errors.Lista && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Lista}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>}

            {formik.values.campoTipo == ETipoCampoEnum.Valor &&
              <div className="row">
                <div className="col-6 mt-3">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Informar o valor da condição:</label>
                  <NumberFormat
                    format={currencyFormatter}
                    placeholder={"0,00"}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          !formik.values.valor || formik.values.valor == 0
                      },
                      {
                        "is-valid":
                          formik.touched.valor &&
                          !formik.errors.valor,
                      }
                    )}
                    value={formik.values.valor}
                    onValueChange={(values) =>
                      formik.setFieldValue(
                        "valor",
                        (parseFloat(values.value) / 100).toFixed(2),
                        true
                      )
                    }
                  />
                  {formik.touched.valor && formik.errors.valor && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Lista}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>}

            {formik.values.campoTipo == ETipoCampoEnum.Texto &&
              <div className="row">
                <div className="col-6 mt-3">
                  <label htmlFor="form-valor" className="form-label fw-bolder">Informar o texto da condição:</label>
                  <input {...formik.getFieldProps('valor')} placeholder="Informar o texto da condição" type="text" className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.valor && formik.errors.valor,
                    },
                    {
                      'is-valid': formik.touched.valor && !formik.errors.valor,
                    }
                  )} id="form-valor" />
                  {formik.touched.valor && formik.errors.valor && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.valor}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>}

          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>

            <button
              type="button"
              disabled={!formik.isValid}
              className="btn btn-orange ms-5"
              onClick={() => confirmar(selectedEdge)}
            >
              Adicionar
            </button>

            <button
              onClick={() => {
                excluir();
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </ReactFlow>
  );
};

const NodeProvedor = ({ initialNodes, initialEdges, onNodesEdgesChange }: { initialNodes: Node[], initialEdges: Edge[], onNodesEdgesChange: any }) => {
  return (
    <ReactFlowProvider>
      <Fluxo initialEdges={initialEdges} initialNodes={initialNodes} onNodesEdgesChange={onNodesEdgesChange}></Fluxo>
      <SideBarSelection />
    </ReactFlowProvider>
  )
}

export default NodeProvedor;
