import { useEffect, useState } from "react";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";

import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import DuplicarParametrizacaoRequest from "../../interfaces/Requests/DuplicarParametrizacao/DuplicarParametrizacaoRequest";
import Spinner from "../Spinner";
import IOption from "../../interfaces/IOption";

interface IDuplicarParametrizacaoModal<T extends Record<string, any>> {
  exibirModal: boolean;
  handleClose: () => void;
  modalTitle: string;
  itens: T[];
  idKey: keyof T;
  moduloId: number;
}

const DuplicarParametrizacaoModal = <T extends Record<string, any>>({
  exibirModal,
  handleClose,
  modalTitle,
  itens,
  idKey,
  moduloId,
}: IDuplicarParametrizacaoModal<T>) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [areas, setAreas] = useState<IOption[]>([]);
  const [limitacoes, setLimitacoes] = useState<boolean>(false);

  const [origem, setOrigem] = useState<IOption>({
    label: "",
    value: 0,
  });
  const [destino, setDestino] = useState<IOption>({
    label: "",
    value: 0,
  });


  useEffect(() => {

    if (exibirModal) {

      carregarAreas(itens);

      validar();
    }

  }, [exibirModal, origem, destino]);

  const carregarAreas = (itens: T[]) => {
    const mappedAreas = itens
      .map((item) => {
        console.log(item[idKey])
        return {
          label: item.nome,
          value: item[idKey],
        };
      })
      .filter((item) => {
        return item.value !== origem.value && item.value !== destino.value;
      });

    setAreas(mappedAreas);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Copiar Configurações",
        text: `Você deseja copiar as configurações de ${origem.label} para ${destino.label}?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {

        const values: DuplicarParametrizacaoRequest = {
          idOrigem: origem.value,
          idDestino: destino.value,
          modulo: moduloId,
          copiarListasLimitadas: limitacoes,
        };

        await ConfiguracaoTelaService.duplicar(values);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Cópia feita com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        setOrigem({
          label: "",
          value: 0,
        });

        setDestino({
          label: "",
          value: 0,
        });

        setLimitacoes(false);
        handleClose();
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível realizar a cópia",
        text:
          e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const validar = () => {
    if (origem.value !== 0 && destino.value !== 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };


  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">{modalTitle}</h5>
        </div>
        <div className="modal-body">
          <div
            className="row mt-1 mb-3"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="col-md-5">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder text-orange"
              >
                Origem das Configurações:
              </label>

              <Select
                isLoading={false}
                placeholder="Selecione a origem das configurações"
                id="form-select"
                options={areas}
                isSearchable
                value={origem}
                onChange={(select) => {
                  if (select) {
                    setOrigem(select);
                  }
                }}
              />
            </div>

            <div className="col-md-5">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder text-orange"
              >
                Destino das Configurações:
              </label>

              <Select
                isLoading={false}
                placeholder="Selecione o destino das configurações"
                id="form-select"
                options={areas}
                isSearchable
                value={destino}
                onChange={(select) => {
                  if (select) {
                    setDestino(select);
                  }
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 3,
              justifyContent: "center",
            }}
          >
            <FormCheckInput
              type="checkbox"
              style={{
                backgroundColor: "var(--primary-base2)",
                borderColor: "var(--primary-base2)",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              checked={limitacoes}
              onChange={() => setLimitacoes(!limitacoes)}
              placeholder="Deseja copiar as limitações de tabelas?"
            />

            <label
              htmlFor="form-label"
              className="form-label fw-bolder text-orange"
              style={{ marginTop: "2px" }}
            >
              Deseja copiar as limitações de tabelas?
            </label>
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          {loading ? (
            <Spinner />
          ) : (
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-orange me-5"
              disabled={disabled}
            >
              SALVAR
            </button>
          )}
          <button
            onClick={() => {

              setOrigem({
                label: "",
                value: 0,
              });

              setDestino({
                label: "",
                value: 0,
              });

              setLimitacoes(false);

              handleClose();

            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicarParametrizacaoModal;
