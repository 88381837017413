import { Button, Modal, ModalProps } from "react-bootstrap";
import { Container, Card } from "./styles";
import {
  MdArrowBack,
  MdClose,
  MdOutlineCreate,
  MdOutlineHtml,
} from "react-icons/md";
import { TableService } from "../../lib/slate/services/table";
import { CommonService } from "../../lib/slate/services/commons";
import { useSlateStatic } from "slate-react";
import { useState } from "react";
import { HtmlService } from "../../lib";
import Swal from "sweetalert2";

export const ChooseTableOptionModal = (props: ModalProps): JSX.Element => {
  const editor = useSlateStatic();

  const [isHtmlTable, setIsHtmlTable] = useState(false);
  const [htmlValue, setHtmlValue] = useState("");

  const createManualTable = () => {
    const table = TableService.buildTable(2, 3);
    CommonService.addTable(editor, table);
    props.onHide?.();
  };

  const createHtmlTable = () => {
    if (!htmlValue) { return }

    if (!HtmlService.isValidHTML(htmlValue)) {
      Swal.fire('', 'Informe um html válido', 'error')
      return
    }

    HtmlService.loadHtmlContent(editor, htmlValue)
    setHtmlValue('')
    setIsHtmlTable(false)
    props.onHide?.()
  }

  return (
    <Modal centered {...props}>
      <Container>
        <div className="container-header">
          <h6>Criar tabela</h6>
          <button type="button">
            {isHtmlTable ? (
              <MdArrowBack onClick={() => setIsHtmlTable(false)} />
            ) : (
              <MdClose onClick={props.onHide} />
            )}
          </button>
        </div>

        {isHtmlTable ? (
          <div className="insert-html">
            <textarea
              onChange={(e) => setHtmlValue(e.target.value)}
              value={htmlValue}
              placeholder="Cole seu código HTML aqui..."
            />

            <Button disabled={!htmlValue} type="button" onClick={createHtmlTable} variant="var(--primary-base2)">Criar</Button>
          </div>
        ) : (
          <div className="card-container">
            <Card onClick={() => setIsHtmlTable(true)}>
              <MdOutlineHtml size={40} />
              <strong>Código HTML</strong>
            </Card>

            <Card onClick={createManualTable}>
              <MdOutlineCreate size={40} />
              <strong>Criar manualmente</strong>
            </Card>
          </div>
        )}
      </Container>
    </Modal>
  );
};
