import { MdLink, MdOutlineFileUpload } from "react-icons/md";
import { Container } from "./styles";
import React, { useState } from "react";
import { Input, InputContainer } from "../Link/styles";
import { isValidUrl } from "../../../util";
import { CustomEditor, ImageElement } from "../../../../../utils/types/slate";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import imageExtensions from 'image-extensions'

const uploadOptions = [
  {
    type: "user_storage",
    icon: <MdOutlineFileUpload />,
    text: "Fazer upload do computador",
  },
  { type: "by_url", icon: <MdLink />, text: "Por url" },
];

const insertImage = (editor: CustomEditor, url: string) => {
  const text = { text: '' }
  const image: ImageElement = {
    type: 'image',
    url,
    children: [text]
  }
  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] })
  ReactEditor.focus(editor)
}

const isImageUrl = (url: string) => {
  if (!url) return false
  if (!isValidUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop() ?? ""
  return imageExtensions.includes(ext)
}

export const withImages = (editor: CustomEditor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => element.type === 'image' ? true : isVoid(element)

  editor.insertData = data => {
    const text = data.getData('text/plain')
    
    if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const ImagePopover = (): JSX.Element => {
  const editor = useSlateStatic()
  const [selectedOption, setSelectedOption] = useState("");
  const [url, setUrl] = useState("");
  const [errorState, setErrorState] = useState({
    message: '',
    error: false
  });

  const handleSearchByUrl = (event: React.FormEvent) => {
    event.preventDefault();

    if (!isValidUrl(url)) {
      setErrorState({ error: true, message: 'Url inválida' })
      return
    }

    insertImage(editor, url)
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value)
    setErrorState({ error: false, message: '' })
  }
  
  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0]

    if (uploadedFile) {
      const url = URL.createObjectURL(uploadedFile)
      insertImage(editor, url)
    }
  }

  return (
    <Container>
      {selectedOption === "by_url" && (
        <form onSubmit={handleSearchByUrl}>
          <InputContainer>
            <small>Inserir url</small>
            <Input
              onChange={onChange}
              error={false}
              placeholder="https://exemplo.com"
            />
            {errorState.message && <p>{errorState.message}</p>}
          </InputContainer>
          <button disabled={!url} type="submit">Aplicar</button>
        </form>
      )}

      {!selectedOption &&
        uploadOptions.map((uploadOption) => {
          if (uploadOption.type === 'user_storage') {
            return (
              <label htmlFor="inputImg">
                <input 
                  id="inputImg"
                  accept="image/*" 
                  type="file" 
                  name="inputImg"
                  onChange={onUpload} 
                />
                {uploadOption.icon}
                {uploadOption.text}
              </label>
            )
          }

          return (
            <button
              onClick={() => setSelectedOption(uploadOption.type)}
              key={uploadOption.type}
            >
              {uploadOption.icon}
              {uploadOption.text}
            </button>
          )
        })}
    </Container>
  );
};
