import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "../../components/Comum/GridPadrao";
import ICNJOrgao from "../../interfaces/ICNJOrgao";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaCNJOrgaoRequest from "../../interfaces/Requests/CNJ/FiltroPesquisaCNJOrgaoRequest";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import CNJOrgaoService from "../../services/CNJService";


const CNJOrgaoPage = () => {
    const [cnjOrgaos, setCNJOrgaos] = useState<ICNJOrgao[]>([]);
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoCNJOrgao, setCarregandoCNJOrgao] = useState(false);
    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaCNJOrgaoRequest>({
        nome: '',
        codigo: '',
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'id'
    });

    async function carregarCNJOrgao(filtro: FiltroPesquisaCNJOrgaoRequest) {
        try {
            setCarregandoCNJOrgao(true);
            let resultado: IRecuperaLista<ICNJOrgao>;

            resultado = await CNJOrgaoService.obterCNJOrgao(filtro);

            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
            setCNJOrgaos(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setCNJOrgaos([]);
        }
        finally {
            setCarregandoCNJOrgao(false);
            setLoadingInicial(false);
        }
    }

    const colunas: TableColumn<ICNJOrgao>[] = [
        {
            name: 'Id',
            sortField: 'cnjOrgaoId',
            selector: (row: ICNJOrgao) => row.cnjOrgaoId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Nome',
            sortField: 'cnjOrgaoNome',
            selector: (row: ICNJOrgao) => row.nome,
            sortable: true,
            wrap: true
        }
    ]

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<ICNJOrgao>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };

    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                nome: '',
                codigo: '',
                status: 1
            }
        });
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarCNJOrgao(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

    return (<>
        <LayoutPageTitle title="Órgão">
        </LayoutPageTitle>


        <div className="row mt-2">
            <div className="col-md-12">
                <div className="col-md-12">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        carregarCNJOrgao(filtrosPesquisa);
                    }} className="row g-3 mb-3">
                        <div className="col-md-2 ">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                            <input value={filtrosPesquisa.nome} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, nome: e.target.value } });
                            }} placeholder="Nome" type="text" className={'form-control'} id="form-nome" />
                        </div>
                        <div className="col-md-2 ">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código:</label>
                            <input value={filtrosPesquisa.codigo} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, codigo: e.target.value } });
                            }} placeholder="Código" type="text" className={'form-control'} id="form-codigo" />
                        </div>        
                        <div className="col-md-2">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Status:</label>
                            <select value={filtrosPesquisa.status} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                            }} placeholder="Nome" className={'form-select'} id="form-select" >
                                <option value="0" label="Todos"> Todos </option>
                                <option value="1" label="Ativo"> Ativo </option>
                                <option value="-1" label="Inativo"> Inativo </option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                                    <FontAwesomeIcon color='white' className='mx-2' icon={faSearch} />
                                </button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    limparFiltros();
                                }} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                                    <FontAwesomeIcon color='white' className='mx-2' icon={faEraser} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 mb-10">
                    <GridPadrao 
                    onSort={handleSort} 
                    progressPending={carregandoCNJOrgao} 
                    limit={filtrosPesquisa.limit} 
                    onChangePage={handlePageChange} 
                    onChangeRowsPerPage={handlePerRowsChange} 
                    paginationServer={true} 
                    paginationTotalRows={filtrosPesquisa.totalItems} 
                    colunas={colunas} 
                    tipo='Órgão' 
                    itens={cnjOrgaos} />
                </div>
            </div>
        </div >
    </>)
}

export default CNJOrgaoPage;