import { ETipoCampoEnum } from "../../../../../enum";
import { Modelo } from "../../../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";

export const modelo: Modelo  = {
  nome: "SocioAdministrador",
  label: "Socio Administrador",
  controller: "NULL",
  entidade: '',
  tipo: ETipoCampoEnum.Pessoa,    
  isXCampo: false,
  campo: 91,
  campoNome: "socioAdministrador",
  ordenacao: 1,
  obrigatorio: false,
  help: "",
  somenteLeitura: false,
  multiplo: false,
  camposRetornar: [
    "pessoaId",
    "quantidade",
    "dataEntrada",
    "quotasAcoes",
    "status",
  ],
  camposExtras: [
    {
      nome: "dataEntrada",
      label: "Data Entrada",
      obrigatorio: true,
      help: '',
      tipo: ETipoCampoEnum.Data,
      campoNome: 'dataEntrada',
      campo: 999,
      controller: '',
      entidade: '',
      isXCampo: false,
      ordenacao: 0,
      somenteLeitura: false
    },
    {
      nome: "tipoQuotaAcoesId",
      label: "Tipo de Ações/Quotas",
      obrigatorio: true,
      help: '',
      tipo: ETipoCampoEnum.Lista,
      campoNome: 'tipoQuotasAcoesId',
      campo: 0,
      controller: 'TipoQuotasAcoes',
      entidade: '',
      isXCampo: false,
      ordenacao: 0,
      somenteLeitura: false
    },
    {
      nome: "quotasAcoes",
      label: "Quantidade",
      obrigatorio: true,
      help: '',
      tipo: ETipoCampoEnum.Inteiro,
      campoNome: 'quotasAcoes',
      campo: 999,
      controller: '',
      entidade: '',
      isXCampo: false,
      ordenacao: 0,
      somenteLeitura: false
    },
    {
      campoNome: "Status",
      nome: "Status",
      controller: "StatusRepresentante",
      label: "Status",
      entidade: "",
      tipo: ETipoCampoEnum.EnumLista,
      ordenacao: 0,
      obrigatorio: true,
      somenteLeitura: false,
      campo: 0,
      help: "",
      isXCampo: false
  },
  ]
}