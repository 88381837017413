import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { Accordion, Button, Card, Col, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestion, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const options = [
  {
    label: 'Pode-se desligar um Funcionário que está em período gestacional?',
    population: 4780127,
    capital: 'Montgomery',
    region: 'South',
  },
  { label: 'Pode-se desligar um Funcionário que faz parte da CIPA?', population: 710249, capital: 'Juneau', region: 'West' },
  { label: 'O que caracteriza uma justa causa?', population: 6392307, capital: 'Phoenix', region: 'West' },
  {
    label: 'O que caracteriza um Dano Moral?',
    population: 2915958,
    capital: 'Little Rock',
    region: 'South',
  },
  {
    label: 'O que caracteriza um Dano Material?',
    population: 37254503,
    capital: 'Sacramento',
    region: 'West',
  },
  { label: 'O que deve constar em uma cláusula contratual de LGPD (Lei Geral de Proteção de Dados)?', population: 5029324, capital: 'Denver', region: 'West' },
  {
    label: 'Como deve ser feita a provisão de um Processo?',
    population: 3574118,
    capital: 'Hartford',
    region: 'Northeast',
  },
  { label: 'Como eu realizo o calculo da SELIC?', population: 897936, capital: 'Dover', region: 'South' },
];


const ConsultaFAQPage = () => {

  const [selected, setSelected] = useState<any>([]);

  const navigate = useNavigate();

  return (
    <>
      <LayoutPageTitle title="FAQ - Dúvidas">
        <button onClick={() => navigate("/Consulta/Novo")} className="btn btn-md btn-orange">
          Novo Artigo/Consulta <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <div className="col-md-4" >
          <Accordion>
            <Accordion.Item eventKey="0" style={{ border: 'none' }}>
              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  Pode-se desligar um Funcionário que está em período gestacional?
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Não é legalmente permitido demitir uma mulher grávida sem justa causa durante a gestação. Isso é protegido por lei em muitos países, incluindo o Brasil. Essa proteção também se estende ao período de licença-maternidade e por um período após o término da licença.
Além disso, no Brasil, a Lei nº 9.029/1995 proíbe a discriminação contra mulheres em razão do sexo ou estado gravídico. Além disso, a Constituição Federal prevê em seu artigo 7º, inciso XVIII, a proteção do mercado de trabalho da mulher, mediante incentivos específicos.
Caso uma empresa demita uma mulher grávida sem justa causa, ela pode recorrer à Justiça do Trabalho e solicitar indenização por danos morais e materiais. É importante que a gestante conheça seus direitos trabalhistas e os faça valer caso sejam violados.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" style={{ border: 'none' }}>
              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  Pode-se desligar um Funcionário que faz parte da CIPA?
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Não é permitido demitir um empregado da CIPA (Comissão Interna de Prevenção de Acidentes) durante o seu mandato de estabilidade, que dura desde a data da eleição até um ano após o final do mandato. Isso é estabelecido pela Norma Regulamentadora 5 (NR-5), do Ministério do Trabalho e Emprego.
No entanto, se houver justa causa para a demissão do empregado, essa decisão pode ser tomada. É importante destacar que a justa causa não pode estar relacionada à sua atuação na CIPA e deve ser comprovada por meio de um processo administrativo que siga as normas trabalhistas e assegure o direito de defesa do empregado.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" style={{ border: 'none' }}>

                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #2
                      </label>
                    </Accordion.Header>

                    <Accordion.Body>
                    Os membros da CIPA (Comissão Interna de Prevenção de Acidentes) são eleitos pelos trabalhadores de uma empresa para representá-los nas questões relacionadas à segurança e saúde do trabalho. Eles têm como objetivo identificar os riscos ambientais no ambiente de trabalho e sugerir medidas para prevenir acidentes e doenças ocupacionais.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion>
            <Accordion.Item eventKey="0" style={{ border: 'none' }}>
              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  O que caracteriza uma justa causa?
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    A demissão por justa causa é uma forma de rescisão do contrato de trabalho motivada por uma falta grave cometida pelo funcionário, que torna insustentável a continuidade da relação empregatícia. Os critérios para a demissão por justa causa são determinados pela legislação trabalhista brasileira (CLT) e incluem:
Desídia no desempenho das funções: falta de zelo, descuido ou negligência no trabalho;
Ato de improbidade: cometimento de fraude, roubo, desvio ou apropriação indevida de bens da empresa;
Incontinência de conduta ou mau procedimento: comportamento inadequado, desrespeitoso ou ofensivo com colegas, superiores ou clientes;
Violação de segredo da empresa: divulgação de informações confidenciais ou sigilosas;
Condenação criminal do empregado: sentença condenatória transitada em julgado, que impossibilite a continuidade da relação empregatícia;
Embriaguez habitual ou em serviço: consumo de álcool ou drogas que prejudique o desempenho das atividades;
Indisciplina ou insubordinação: desobediência a ordens ou regras, desrespeito a superiores hierárquicos;
Abandono de emprego: ausência injustificada do trabalho por mais de 30 dias consecutivos.
É importante ressaltar que a demissão por justa causa deve ser aplicada com cautela e observância aos princípios do contraditório e da ampla defesa, garantindo ao funcionário o direito de se manifestar e apresentar sua defesa. Caso seja comprovada a falta grave, a demissão por justa causa pode ser aplicada, resultando na perda de diversos direitos trabalhistas, como o recebimento do aviso prévio, do 13º salário e do saque do FGTS.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" style={{ border: 'none' }}>
              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  O que caracteriza um Dano Moral?
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Existem diversas causas que podem levar a um dano material em um processo, dentre as quais podemos destacar:
Negligência: a falta de cuidado ou atenção adequados pode levar a danos materiais, como a quebra de um equipamento ou a perda de um documento importante.
Falha técnica: problemas com máquinas ou equipamentos podem causar danos materiais em um processo, como a queima de um motor ou a falha de um sistema eletrônico.
Acidentes: eventos imprevisíveis, como colisões de veículos ou incêndios, podem causar danos materiais em um processo.
Falha humana: erros cometidos por pessoas envolvidas no processo, como funcionários ou prestadores de serviço, podem levar a danos materiais, como a perda de um componente ou a quebra de um objeto.
Força maior: eventos externos, como terremotos, tempestades ou inundações, podem causar danos materiais em um processo.
É importante identificar a causa do dano material em um processo para que medidas possam ser tomadas para prevenir a ocorrência de futuros danos. Além disso, em muitos casos, é possível buscar a reparação dos danos por meio de ações judiciais ou de negociações com as partes envolvidas.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion>
            <Accordion.Item eventKey="0" style={{ border: 'none' }}>
              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  O que deve constar em uma cláusula contratual de LGPD (Lei Geral de Proteção de Dados)?
                </label>
              </Accordion.Header>
              <Accordion.Body>

                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Uma cláusula de LGPD (Lei Geral de Proteção de Dados) em um contrato é uma disposição que estabelece as obrigações das partes contratantes em relação ao tratamento de dados pessoais no âmbito do contrato.
Essa cláusula deve ser elaborada de forma clara e objetiva, de modo a garantir a compreensão das obrigações das partes. Alguns dos elementos que podem estar presentes em uma cláusula de LGPD em um contrato são:
Definição dos termos utilizados na cláusula, como "dado pessoal", "tratamento de dados", "titular dos dados", entre outros.
Descrição das atividades de tratamento de dados que serão realizadas pelas partes contratantes, como coleta, armazenamento, processamento, compartilhamento, etc.
Finalidades para as quais os dados pessoais serão tratados, ou seja, os motivos pelos quais as partes precisam desses dados.
Base legal para o tratamento de dados, ou seja, o fundamento jurídico que permite que as partes tratem os dados pessoais. Pode ser o consentimento do titular dos dados, o cumprimento de obrigação legal, a execução de contrato, entre outros.
Obrigações daspartes contratantes em relação à segurança dos dados pessoais, como medidas técnicas e organizacionais para garantir a proteção dos dados.
Direitos dos titulares dos dados, como o direito de acesso, retificação, exclusão, portabilidade, etc.
Prazo de retenção dos dados pessoais, ou seja, por quanto tempo as partes irão armazenar os dados pessoais.
Condições para o compartilhamento de dados pessoais com terceiros, como consentimento do titular dos dados ou exigência legal.
Consequências do descumprimento das obrigações previstas na cláusula, como multas e indenizações.
É importante ressaltar que a inclusão de uma cláusula de LGPD em um contrato de prestação de serviços não exime as partes contratantes do cumprimento da legislação de proteção de dados. A cláusula serve como um instrumento para garantir a conformidade do contrato com a LGPD, mas as partes devem adotar medidas adicionais para assegurar a proteção dos dados pessoais.                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" style={{ border: 'none' }}>

                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #2
                      </label>
                    </Accordion.Header>

                    <Accordion.Body>
                    A LGPD é a sigla para Lei Geral de Proteção de Dados, uma legislação brasileira que entrou em vigor em setembro de 2020 e tem como objetivo regulamentar o tratamento de dados pessoais por empresas e órgãos públicos no país.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" style={{ border: 'none' }}>

              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  Como deve ser feita a provisão de um Processo?
                </label>
              </Accordion.Header>

              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Provisão é um termo contábil que se refere a uma quantia estimada que uma empresa reserva em seus livros para cobrir um passivo ou uma despesa futura que ainda não foi paga ou registrada. É uma estimativa baseada em informações e análises de risco, esta pode precisar ser ajustada à medida que mais informações se tornem disponíveis ou decisões judiciais.
Todos os valores prováveis devem ser devidamente divulgadosao mercaso e os valores possíveis através de notas explicativas que, por sua vez, é um documento que acompanha as demonstrações financeiras de uma empresa e fornece informações adicionais e esclarecimentos sobre suas atividades e desempenho financeiro. Ela pode incluir informações sobre as políticas contábeis da empresa, eventos significativos que possam afetar suas finanças, detalhes sobre transações importantes e outras informações que possam ajudar os usuários das demonstrações financeiras a entender melhor a posição financeira e o desempenho da empresa. A nota explicativa é uma parte importante das demonstrações financeiras e é exigida pelas normas contábeis e regulatórias em muitos países.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" style={{ border: 'none' }}>

                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #2
                      </label>
                    </Accordion.Header>

                    <Accordion.Body>
                    Em processos judiciais trabalhistas, a empresa pode realizar uma provisão processual para cobrir possíveis valores que venham a ser determinados em eventual condenação. Essa provisão consiste em reservar um valor no balanço contábil da empresa para eventual pagamento de verbas trabalhistas e indenizações que possam ser determinadas pelo juiz em uma sentença judicial desfavorável.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion>
            <Accordion.Item eventKey="0" style={{ border: 'none' }}>
              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  O que caracteriza um Dano Material?
                </label>
              </Accordion.Header>
              <Accordion.Body>

                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    Existem diversas causas que podem levar a um dano material em um processo, dentre as quais podemos destacar:
Negligência: a falta de cuidado ou atenção adequados pode levar a danos materiais, como a quebra de um equipamento ou a perda de um documento importante.
Falha técnica: problemas com máquinas ou equipamentos podem causar danos materiais em um processo, como a queima de um motor ou a falha de um sistema eletrônico.
Acidentes: eventos imprevisíveis, como colisões de veículos ou incêndios, podem causar danos materiais em um processo.
Falha humana: erros cometidos por pessoas envolvidas no processo, como funcionários ou prestadores de serviço, podem levar a danos materiais, como a perda de um componente ou a quebra de um objeto.
Força maior: eventos externos, como terremotos, tempestades ou inundações, podem causar danos materiais em um processo.
É importante identificar a causa do dano material em um processo para que medidas possam ser tomadas para prevenir a ocorrência de futuros danos. Além disso, em muitos casos, é possível buscar a reparação dos danos por meio de ações judiciais ou de negociações com as partes envolvidas.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" style={{ border: 'none' }}>

              <Accordion.Header>
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  <FontAwesomeIcon style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faQuestion} />
                  Como eu realizo o calculo da SELIC?
                </label>
              </Accordion.Header>

              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #1
                      </label>
                    </Accordion.Header>
                    <Accordion.Body>
                    A taxa SELIC é definida pelo Comitê de Política Monetária (COPOM) do Banco Central do Brasil e é divulgada periodicamente. Ela representa a taxa básica de juros da economia brasileira e é utilizada como referência para o cálculo de juros de diversos tipos de investimentos e empréstimos.

Para calcular a taxa de juros de um investimento ou empréstimo com base na SELIC, é necessário aplicar o fator de cálculo da SELIC, que é dado pela fórmula:

Fator = [(1 + (SELIC / 100)) ^ (dias corridos / 252)]
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" style={{ border: 'none' }}>

                    <Accordion.Header>
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Resposta #2
                      </label>
                    </Accordion.Header>

                    <Accordion.Body>
                    SELIC: é a taxa SELIC divulgada pelo Banco Central do Brasil, em percentual;
Dias corridos: é o período em dias que se deseja calcular os juros, contados a partir da data de início do investimento ou empréstimo;
252: é o número de dias úteis em um ano.
Por exemplo, suponha que a taxa SELIC esteja em 6,50% ao ano e que se deseja calcular os juros de um investimento de 30 dias. O fator de cálculo da SELIC seria:

Fator = [(1 + (6,50 / 100)) ^ (30 / 252)] = 1,005076

Assim, se o investimento tivesse um valor inicial de R$ 10.000,00, o valor final com juros seria:

Valor final = R$ 10.000,00 x 1,005076 = R$ 10.050,76

É importante lembrar que o cálculo da taxa de juros de um investimento ou empréstimo com base na SELIC pode ser influenciado por outros fatores, como impostos, tarifas bancárias, entre outros.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </div>
        <div className="col-md-6" >
          <label htmlFor="form-nomeSolicitante" className="form-label fw-bolder text-orange">
            Como podemos ajudar?
          </label>
          <InputGroup>
            <InputGroup.Text><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faSearch} /> </InputGroup.Text>
            <Typeahead
              onChange={(e) => setSelected(e)}
              options={options}
              placeholder="Pesquisar artigos de ajuda..."
              selected={selected}
              style={{ width: '450px' }}
            />
          </InputGroup>


          <div className="row mt-4">
            <div className="col-md-6">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                Topicos mais lidos
              </label>
            </div>
            <Row xs={1} md={2} className="g-4">
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-orange">LGPD</Card.Title>
                    <Card.Text className="text-orange">
                    A LGPD é a sigla para Lei Geral de Proteção de Dados, uma legislação brasileira que entrou em vigor em setembro de 2020 e tem como objetivo regulamentar o tratamento de dados pessoais por empresas e órgãos públicos no país.
                    </Card.Text>
                    <Button className="btn btn-md btn-orange">
                      Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faSearch} />
                    </Button>
                  </Card.Body>
                </Card>
              </Col>


              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-orange">CIPA</Card.Title>
                    <Card.Text className="text-orange">
                    CIPA é a sigla para Comissão Interna de Prevenção de Acidentes. Trata-se de uma comissão formada por representantes dos trabalhadores e do empregador que tem como objetivo prevenir acidentes e doenças ocupacionais dentro de uma empresa.
                    </Card.Text>
                    <Button className="btn btn-md btn-orange">
                      Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faSearch} />
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-orange">eSocial</Card.Title>
                    <Card.Text className="text-orange">
                    eSocial é um sistema de escrituração digital que tem como objetivo unificar o envio de informações trabalhistas, previdenciárias e fiscais por parte das empresas e empregadores domésticos. O sistema foi instituído pelo Governo Federal brasileiro em 2014, por meio do Decreto nº 8.373, e sua implantação foi realizada de forma gradual, iniciando-se em 2018 para as grandes empresas.
                    </Card.Text>
                    <Button className="btn btn-md btn-orange">
                      Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faSearch} />
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-orange">Compliance</Card.Title>
                    <Card.Text className="text-orange">
                    Compliance é um conjunto de práticas e procedimentos adotados por uma organização para garantir que suas atividades estejam em conformidade com as leis, regulamentações e normas internas e externas. O termo "compliance" vem do inglês e significa "conformidade".
                    </Card.Text>
                    <Button className="btn btn-md btn-orange">
                      Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faSearch} />
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    </>
  );
}

export default ConsultaFAQPage;

