import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";
import { tipoPorModulo } from "../../utils/Utils";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";

import IOption from "../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaPropostaAcordoRequest } from "../../interfaces/Requests/PropostaAcordo/AdicionaPropostaAcordoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import PropostaAcordoService from "../../services/PropostaAcordoService";
import TipoPropostaAcordoService from "../../services/TipoPropostaAcordoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Solucao/CamposCadastroSolucoes";
import { tipoPropostaAcordoInicialValues } from "../../contexts/InitialValuesContext";

const PropostaAcordoCadastroPage = () => {
  const [carregandoTiposPropostaAcordo, setCarregandoTiposPropostaAcordo] = useState<boolean>(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { propostaAcordo, setPropostaAcordo, setTipoPropostaAcordo, tipoPropostaAcordo } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: AdicionaPropostaAcordoRequest = {
    propostaAcordoId: 0,
    principalId: 0,
    tipoPropostaAcordoId: 0,
    modulo: EModulo.PropostaAcordo,
    tela: ETelaEnum.TelaCadastroPropostaAcordo,
    categoriaId: propostaAcordo.categoriaId || 0,

    processoId: propostaAcordo.processoId || 0,
    areaDireitoId: propostaAcordo.areaDireitoId || 0,

    imovelId: propostaAcordo.imovelId || 0,
    tipoImovelId: propostaAcordo.tipoImovelId || 0,

    validadeproposta: null,
    valoracordo: 0,
    descricao: "",
    datapagamento: null,
    multaatraso: false,
    valormultaatraso: 0,
    periodicidadeId: 0,
    codigo: "",
    realizadopor: "",
    dataCadastro: null,
    statusPropostaAcordoNome: "",
    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {

        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Proposta de Acordo",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        
        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro da proposta..",
            showConfirmButton: false,
          });

          Swal.showLoading();

          setAdicionarLoading(true);

          let resultado = await PropostaAcordoService.adicionaPropostaAcordo({
            ...values,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });


          Swal.hideLoading();

          setPropostaAcordo({
            ...resultado,
            modulo: propostaAcordo.modulo,
            imovelId: propostaAcordo?.imovelId,
            processoId: propostaAcordo?.processoId,
            tipoImovelId: propostaAcordo.tipoImovelId,
            categoriaId: resultado.tipoPropostaAcordoId,
            areaDireitoId: propostaAcordo.areaDireitoId,
            propostaAcordoId: resultado.propostaAcordoId,
            tipoPropostaAcordoId: resultado.tipoPropostaAcordoId,
          });

          setTipoPropostaAcordo(tipoPropostaAcordo);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Proposta de Acordo cadastrada com sucesso`,
            timer: 4000,
          });

        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setTipoPropostaAcordo(tipoPropostaAcordoInicialValues);

    carregarTipoPropostaAcordo();

    setConfiguracoesTela([]);

    formik.setValues(initialValues);

  }, []);

  useEffect(() => {
    if (tipoPropostaAcordo.tipoPropostaAcordoId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoPropostaAcordo(tipoPropostaAcordoInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoPropostaAcordo.tipoPropostaAcordoId]);

  const carregarTipoPropostaAcordo = async () => {
    try {

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(propostaAcordo.modulo)}Id=${propostaAcordo.categoriaId}`);

      setCarregandoTiposPropostaAcordo(true);

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await TipoPropostaAcordoService.obterListaLimitadaTipoPropostaAcordos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        modulo: propostaAcordo.modulo,  
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...objeto
      });

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));
      setOptions(options);

    } catch (error: any) {
      setOptions([]);
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setCarregandoTiposPropostaAcordo(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(propostaAcordo.modulo)}Id=${propostaAcordo.categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        modulo: propostaAcordo.modulo,
        tela: ETelaEnum.TelaCadastroPropostaAcordo,
        tipoPropostaAcordoId: tipoPropostaAcordo.tipoPropostaAcordoId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const onChangeTipoPropostaAcordo = (value: number, texto: string) => {
    
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoPropostaAcordoId", value);

    formik.setFieldValue("categoriaId", value);

    setTipoPropostaAcordo({
      tipoPropostaAcordoId: value,
      categoriaId: value,
      nome: texto,
      categoriaNome: texto,
      opcaoNome: "",
      moduloNome: "",
      modulo: EModulo.PropostaAcordo,
      opcaoId: 0,
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />
      <LayoutPageForButton title={`Nova Proposta ${tipoPropostaAcordo.tipoPropostaAcordoId > 0 ? " - " + tipoPropostaAcordo.nome : ""}`}>
        {propostaAcordo.propostaAcordoId > 0 ? (
          <>
            <button onClick={() => navigate("/PropostaAcordo/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{propostaAcordo.propostaAcordoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoPropostaAcordoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposPropostaAcordo ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de propostas...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Proposta
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoPropostaAcordoId)?.label,
                value: formik.values.tipoPropostaAcordoId,
              }}
              onChange={(option: any) => onChangeTipoPropostaAcordo(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default PropostaAcordoCadastroPage;
