import { EModulo } from "../enum";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoDespesa from "../interfaces/ITipoDespesa";
import { AdicionaTipoDespesaRequest } from "../interfaces/Requests/TipoDespesa/AdicionaTipoDespesaRequest";
import { EditarTipoDespesaRequest } from "../interfaces/Requests/TipoDespesa/EditarTipoDespesaRequest";
import FiltroPesquisaTipoDespesaRequest from "../interfaces/Requests/TipoDespesa/FiltroPesquisaTipoDespesaRequest";
import api from "./Api";

class TipoDespesaService {

    async obterTipoDespesas(filtros: FiltroPesquisaTipoDespesaRequest): Promise<IRecuperaLista<ITipoDespesa>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoDespesa>>(`TipoDespesa/RecuperaTipoDespesa?${query}`);

        return result;
    }

    async obterTipoDespesasLimitada(filtros: FiltroPesquisaTipoDespesaRequest): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        } 

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoDespesa/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoDespesa(adicionaTipoDespesa: AdicionaTipoDespesaRequest): Promise<ITipoDespesa> {
        let { data: result } = await api.post<ITipoDespesa>(`TipoDespesa/AdicionaTipoDespesa`, adicionaTipoDespesa);

        return result;
    }

    async alterarStatus(tipoDespesaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoDespesa>(`TipoDespesa/AtualizaTipoDespesaPorId?id=${tipoDespesaId}`, data);
    }

    async atualizarTipoDespesa(editarTipoDespesa: EditarTipoDespesaRequest): Promise<void> {

        let dataTipoDespesa = [
            { "op": "replace", "path": "/nome", "value": editarTipoDespesa.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoDespesa.codigo }
        ]

        await Promise.all([
            await api.patch<void>(`TipoDespesa/AtualizaTipoDespesaPorId?id=${editarTipoDespesa.tipoDespesaId}`, dataTipoDespesa)
        ]);
    }

    async RecuperaListaLimitada(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<ITipoDespesa>> {
        let { data: result } = await api.get<IRecuperaLista<ITipoDespesa>>(
            `/TipoDespesa/RecuperaListaLimitada`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Societario,
                },
            }
        );

        return result;
    }

    async recuperaListaLimitadaPesquisa(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<ITipoDespesa>> {
        let { data: result } = await api.get<IRecuperaLista<ITipoDespesa>>(
            `/TipoDespesa/RecuperaListaLimitadaPesquisa`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Societario,
                },
            }
        );

        return result;
    }
}


export default new TipoDespesaService();