import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { TrackChangesArgs } from "../interfaces/trackChanges";
import { EditorVersionAdditionalResponse, EditorVersionData } from "../../../../../interfaces/IEditorVersionResponse";
import { useTitle } from "./useTitle";
import { Caller } from "../../../util";
import { useTenant } from "../../../hooks/useTenant";

export function useSyncFusionEditor() {
  const { caller } = useTenant()
  const { setDocTitle } = useTitle()

  function customSettings(editor: DocumentEditor, options: EditorVersionAdditionalResponse) {
    editor.enableTrackChanges = options.habilitarTrackChanges ?? false
    editor.showRevisions = !(options.ocultarPainelDeAprovacao ?? false)
    editor.isReadOnly = options.bloquearEdicaoMinuta
  }

  function controlTrackChanges(args: TrackChangesArgs, options?: EditorVersionAdditionalResponse | null) {
    if (options?.ocultarPainelDeAprovacao && caller !== Caller.PROCESSO) {
      args.cancel = true;
    }
  }

  function openDocument(editor: DocumentEditor, version?: EditorVersionData) {
    const content = version?.jsonContent.sfdt;
    if (content) {
      const data = { sfdt: content };
      editor?.open(JSON.stringify(data));
      setDocTitle(version.titulo)
    }
  }

  return {
    controlTrackChanges,
    openDocument,
    customSettings
  };
}
