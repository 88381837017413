import React, { useRef, useState } from "react";
import styled from "styled-components";
import { MdEdit, MdPhoto } from "react-icons/md";
import UsuarioService from "../../../services/UsuarioService";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/AuthProvider";

export const UploadChancelaButton = styled.button`
  margin: 20px 0;
  width: 100%;
  align-self: center;
  border: none;
  padding: 5px 0;
  border-radius: 4px;
  background-color: transparent;
  color: var(--bs-orange);
  transition: color 0.5s ease;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    opacity: 0.8;
  }
`;

const ChancelaPreview = styled.div`
  display: flex;
  flex-direction: column;

  > input {
    display: none;
  }
`;

const ChancelaImageContainer = styled.button`
  border: none;
  background: none;
  padding: 0;
  height: 60px;
  width: 60px;

  > div.source-image {
    position: relative;

    > img,
    div {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      border: 2px solid var(--bs-orange);
    }

    > div {
      background: var(--bs-orange);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div.edit-tab {
      background: var(--bs-orange);
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      height: 30px;
      width: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #fff;
    }
  }

  transition: opacity 0.4s ease;

  &:hover {
    opacity: 0.6;
  }
`;

export const ProfileChancelaUpload = (): JSX.Element => {
  const [hovered, setHovered] = useState(false);
  const { user, updateUserPhoto } = useAuth()
  const [chancelaUrl, setChancelaUrl] = useState(user?.urlChancela);

  const inputRef = useRef<HTMLInputElement>(null);

  const triggerUpload = () => {
    inputRef.current?.click();
  };

  const handleUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      Swal.showLoading()

      const photo = event.target.files?.[0];

      if (photo && user) {
        const response = await UsuarioService.upload("seal", photo, user.id);
        if (response) {
          setChancelaUrl(response.urlChancela)
          updateUserPhoto(response)
        }
      }

      await Swal.fire({
        title: 'Chancela alterada com sucesso!',
        icon: 'success',
      })
    } catch (error) {
      console.log(error);
    } finally {
      Swal.hideLoading()
    }
  };

  return (
    <div className="fv-row mb-3">
      <label className="form-label fs-6 fw-bolder text-orange">Chancela</label>
      <ChancelaPreview>
        <input
          ref={inputRef}
          type="file"
          multiple={false}
          accept="image/*"
          onChange={handleUpload}
        />
        <ChancelaImageContainer onClick={triggerUpload} type="button">
          <div
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="source-image"
          >
            {chancelaUrl ? (
              <img src={chancelaUrl} alt="Chancela" />
            ) : (
              <div>
                <MdPhoto size={20} color="#fff" />
              </div>
            )}

            {hovered && (
              <div className="edit-tab">
                <MdEdit color="#fff" size={20} />
              </div>
            )}
          </div>
        </ChancelaImageContainer>
      </ChancelaPreview>
    </div>
  );
};
