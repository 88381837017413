import IFieldMigracoes from "../interfaces/IFieldsMigracoes";
import IHeadersMigracao from "../interfaces/IHeadersMigracao";
import IMigracoes from "../interfaces/IMigracoes";
import IMigracoesUpload from "../interfaces/IMigracoesFileUpload";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaMigracoesRequest from "../interfaces/Requests/Migracoes/FiltroPesquisaMigracoes";
import DynamicMigration from "../interfaces/Requests/Migracoes/MigracoesCreateRequest";
import api from "./Api";

class MigracoesService {
  async obterMigracoes(filtros: FiltroPesquisaMigracoesRequest) {
    const queryStatus = filtros.status ?? "";

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";
    const queryPaginacao = `&offset=${filtros.offset}${limit}&sort=${filtros.sort}`;

    const { data: result } = await api.get<IRecuperaLista<IMigracoes>>(
      `/Migrador/?${queryStatus}${queryPaginacao}`,
      { baseURL: "https://xjurmigratorqa.azurewebsites.net" }
    );

    return result;
  }

  async obterTipoMigracoes() {
    const { data: result } = await api.get<IRecuperaLista<string>>(
      "/fields/migrationtypes",
      { baseURL: "https://xjurmigratorqa.azurewebsites.net" }
    );

    return result;
  }

  async obterColunasTipo(tipo: string) {
    const { data: result } = await api.get<IFieldMigracoes>(
      `/fields?type=${tipo}`,
      { baseURL: "https://xjurmigratorqa.azurewebsites.net" }
    );

    return result;
  }

  async obterSheets(migrationFileId: number) {
    const { data: result } = await api.post<{
      data: {
        sheets: { data: any[]; sheetName: string }[];
      };
    }>(
      "/migrador/sheets",
      { migrationFileId },
      {
        baseURL: "https://xjurmigratorqa.azurewebsites.net",
      }
    );

    return result;
  }

  async obterHeaders(sheetName: string, migrationFileId: number) {
    const { data: result } = await api.post<IHeadersMigracao>(
      "/migrador/headers",
      {
        sheetName,
        migrationFileId,
      },
      {
        baseURL: "https://xjurmigratorqa.azurewebsites.net",
      }
    );

    return result;
  }

  async carregarArquivo(file: FileList) {
    const formData = new FormData();

    formData.append("file", file[0]);

    const { data: result } = await api.post<IMigracoesUpload>(
      "/file/upload",
      formData,
      {
        baseURL: "https://xjurmigratorqa.azurewebsites.net",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return result;
  }

  async baixarArquivo(migrationId: number) {
    const { data: result } = await api.get<Blob>(
      `/File/Download/${migrationId}`,
      {
        baseURL: "https://xjurmigratorqa.azurewebsites.net",
        responseType: "blob",
      }
    );

    return result;
  }

  async criarMigracao(req: DynamicMigration) {
    const { data: result } = await api.post<IRecuperaLista<string>>(
      "/migrador/Migrate",
      req,
      {
        baseURL: "https://xjurmigratorqa.azurewebsites.net",
      }
    );

    return result;
  }
}

export default new MigracoesService();
