import IContrato from "../../../interfaces/IContrato";
import IEnvelope from "../../../interfaces/IEnvelope";
import { DadoTemporal, DadoNumerico, TimeDelta} from '../types';
import Estatisticas from "./Estatisticas";


export default class ContratoEtatisticas extends Estatisticas<IContrato>{

    public cadastradoPorDia():DadoNumerico[] {
        const cadastradoPorDia: DadoNumerico[] = [];

        this.dados.forEach(envelope => {            
            const dataCadastro = new Date(envelope.dataCadastro);
            const dataCadastroFormatada = dataCadastro.getDate().toString().padStart(2,'0') + '/' + (dataCadastro.getMonth() + 1).toString().padStart(2,'0') + '/' + dataCadastro.getFullYear();

            let dado = cadastradoPorDia.find((dado: DadoNumerico)=>dado.etiqueta === dataCadastroFormatada);

            if(!dado){
                dado = new DadoNumerico(dataCadastroFormatada, [], []);
                cadastradoPorDia.push(dado);
            }
            
            if(dado.ids.find((id: number)=> id === envelope.contratoId)) return;
            dado.ids.push(envelope.contratoId);
            dado.dados.push(1);
        });
        
        return cadastradoPorDia;
    }


    public cadastradoPorMes():DadoNumerico[] {
        const cadastradoPorMes: DadoNumerico[] = [];

        this.dados.forEach(envelope => {            
            const dataCadastro = new Date(envelope.dataCadastro);
            const dataCadastroFormatada = (dataCadastro.getMonth() + 1).toString().padStart(2,'0') + '/' + dataCadastro.getFullYear();

            let dado = cadastradoPorMes.find((dado: DadoNumerico)=>dado.etiqueta === dataCadastroFormatada);

            if(!dado){
                dado = new DadoNumerico(dataCadastroFormatada, [], []);
                cadastradoPorMes.push(dado);
            }
            
            if(dado.ids.find((id: number)=> id === envelope.contratoId)) return;
            dado.dados.push(1);
        });

        return cadastradoPorMes;
    }


    public assinadosPorDia():DadoNumerico[] {
        const assinadosPorDia: DadoNumerico[] = [];

        this.dados.sort((a,b)=>new Date(a.dataAssinatura).valueOf() - new Date(b.dataAssinatura).valueOf()).forEach(envelope => {
            if(envelope.statusContrato !== "Assinado") return;
            const dataAssinatura = new Date(envelope.dataAssinatura);
            const dataAssinaturaFormatada = dataAssinatura.getDate().toString().padStart(2,'0') + '/' + (dataAssinatura.getMonth() + 1).toString().padStart(2,'0') + '/' + dataAssinatura.getFullYear();

            let dado = assinadosPorDia.find((dado: DadoNumerico)=>dado.etiqueta === dataAssinaturaFormatada);

            if(!dado){
                dado = new DadoNumerico(dataAssinaturaFormatada, [], []);
                assinadosPorDia.push(dado);
            }
            
            if(dado.ids.find((id: number)=> id === envelope.contratoId)) return;
            
            dado.ids.push(envelope.contratoId);
            dado.dados.push(1);
        });

        return assinadosPorDia;
    }


    public assinadosPorMes():DadoNumerico[] {
        const assinadosPorMes: DadoNumerico[] = [];

        this.dados.sort((a,b)=>new Date(a.dataAssinatura).valueOf() - new Date(b.dataAssinatura).valueOf()).forEach(envelope => {
            if(envelope.statusContrato !== "Assinado") return;
            const dataAssinatura = new Date(envelope.dataAssinatura);
            const dataAssinaturaFormatada =  (dataAssinatura.getMonth() + 1).toString().padStart(2,'0') + '/' + dataAssinatura.getFullYear();

            let dado = assinadosPorMes.find((dado: DadoNumerico)=>dado.etiqueta === dataAssinaturaFormatada);

            if(!dado){
                dado = new DadoNumerico(dataAssinaturaFormatada, [], []);
                assinadosPorMes.push(dado);
            }
            
            if(dado.ids.find((id: number)=> id === envelope.contratoId)) return;
            dado.ids.push(envelope.contratoId);
            dado.dados.push(1);
        });

        return assinadosPorMes;
    }


    public tempoEsperaPorDia(): DadoTemporal[] {
        const tempoEsperaTotalPorDia: DadoTemporal[] = [];

        this.dados.forEach(envelope => {
            const dataAssinatura = envelope.dataInicioVigencia? new Date(envelope.dataInicioVigencia ): new Date();
            const dataCadastro = new Date(envelope.dataCadastro);
            const dataCadastroFormatada = (dataCadastro.getDate()).toString().padStart(2,'0') + '/' + (dataCadastro.getMonth() + 1).toString().padStart(2,'0') + '/' + dataCadastro.getFullYear();

            let dado = tempoEsperaTotalPorDia.find((dado: DadoTemporal)=>dado.etiqueta === dataCadastroFormatada);

            if(!dado){
                dado = new DadoTemporal(dataCadastroFormatada, [], []);
                tempoEsperaTotalPorDia.push(dado);
            }
            
            if(dado.ids.find((id: number)=> id === envelope.contratoId)) return;
            dado.ids.push(envelope.contratoId);
            dado.dados.push(new TimeDelta(dataAssinatura.valueOf() - dataCadastro.valueOf()));
        });

        return tempoEsperaTotalPorDia;
    }


    public tempoEsperaPorMes(): DadoTemporal[] {
        const tempoEsperaTotalPorMes: DadoTemporal[] = [];

        this.dados.forEach(envelope => {
            const dataAssinatura = envelope.dataInicioVigencia? new Date(envelope.dataInicioVigencia ): new Date();
            const dataCadastro = new Date(envelope.dataCadastro);
            const dataCadastroFormatada = (dataCadastro.getMonth() + 1).toString().padStart(2,'0') + '/' + dataCadastro.getFullYear();

            let dado = tempoEsperaTotalPorMes.find((dado: DadoTemporal)=>dado.etiqueta === dataCadastroFormatada);

            if(!dado){
                dado = new DadoTemporal(dataCadastroFormatada, [], []);
                tempoEsperaTotalPorMes.push(dado);
            }
            
            if(dado.ids.find((id: number)=> id === envelope.contratoId)) return;
            dado.ids.push(envelope.contratoId);
            dado.dados.push(new TimeDelta(dataAssinatura.valueOf() - dataCadastro.valueOf()));
        });

        return tempoEsperaTotalPorMes;
    }



    public envelopesConfeccaoPeriodo(): DadoNumerico {
        return new DadoNumerico('Em Elaboração', this.dados.filter(envelope =>envelope.statusContrato === "EmElaboracao").map(e=>e.contratoId),  this.dados.filter(envelope =>envelope.statusContrato === "EmElaboracao").map(e=>1));
    }

    public envelopesPendentesPeriodo(): DadoNumerico {
        return new DadoNumerico('Em Assinatura', this.dados.filter(envelope =>envelope.statusContrato === "EmAssinatura").map(e=>e.contratoId),  this.dados.filter(envelope =>envelope.statusContrato === "EmAssinatura").map(e=>1));
    }

    public envelopesAssinadosPeriodo(): DadoNumerico {
        return new DadoNumerico('Assinado', this.dados.filter(envelope =>envelope.statusContrato === "Assinado").map(e=>e.contratoId),  this.dados.filter(envelope =>envelope.statusContrato === "Assinado").map(e=>1));
    }

    public envelopesCanceladosPeriodo(): DadoNumerico {
        return new DadoNumerico('Cancelado',  this.dados.filter(envelope =>envelope.statusContrato === "Cancelado").map(e=>e.contratoId), this.dados.filter(envelope =>envelope.statusContrato === "Cancelado").map(e=>1));
    }

    public envelopesEstatusPeriodo(): DadoNumerico[] {
        const result =  [
            this.envelopesConfeccaoPeriodo(),
            this.envelopesPendentesPeriodo(),
            this.envelopesAssinadosPeriodo(),
            this.envelopesCanceladosPeriodo()
        ];
        return result;
    }

}