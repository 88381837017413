function removeTrackChangesButtons() {
  setTimeout(() => {
    const elements = ["e-de-track-reject-button", "e-de-track-accept-button"];

    for (const element of elements) {
      const buttons = Array.from(document.querySelectorAll(`.${element}`));
      buttons.forEach((button) => {
        const buttonHtmlElement = button as HTMLButtonElement;
        buttonHtmlElement.style.display = "none";
      });
    }
  }, 1000);
}

export const elementHelper = {
  removeTrackChangesButtons,
};
