import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import INaturezaMarca from "../interfaces/INaturezaMarca";
import { AdicionaNaturezaMarcaRequest } from "../interfaces/Requests/NaturezaMarca/AdicionaNaturezaMarcaRequest";
import FiltroPesquisaNaturezaMarcaRequest from "../interfaces/Requests/NaturezaMarca/FiltroPesquisaNaturezaMarcaRequest";
import { EditarNaturezaMarcaRequest } from "../interfaces/Requests/NaturezaMarca/EditarNaturezaMarcaRequest";

class NaturezaMarcaService {

    async obterNaturezaMarcas(filtros: FiltroPesquisaNaturezaMarcaRequest): Promise<IRecuperaLista<INaturezaMarca>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<INaturezaMarca>>(`NaturezaMarca/RecuperaNaturezaMarca?${query}`);

        return result;
    }

    async adicionaNaturezaMarca(adicionaNaturezaMarca: AdicionaNaturezaMarcaRequest): Promise<INaturezaMarca> {
        let { data: result } = await api.post<INaturezaMarca>(`NaturezaMarca/AdicionaNaturezaMarca`, adicionaNaturezaMarca);

        return result;
    }


    async alterarStatus(naturezaMarcaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<INaturezaMarca>(`NaturezaMarca/AtualizaNaturezaMarcaPorId?id=${naturezaMarcaId}`, data);
    }

    async atualizarNaturezaMarca(editarNaturezaMarca: EditarNaturezaMarcaRequest): Promise<void> {

        let dataNaturezaMarca = [
            { "op": "replace", "path": "/nome", "value": editarNaturezaMarca.nome },
            { "op": "replace", "path": "/codigo", "value": editarNaturezaMarca.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`NaturezaMarca/AtualizaNaturezaMarcaPorId?id=${editarNaturezaMarca.naturezaMarcaId}`, dataNaturezaMarca)
        ]);
    }
}


export default new NaturezaMarcaService();