import { useEffect, useState } from "react";
import {
  faAddressCard,
  faCalendar,
  faCheckCircle,
  faEraser,
  faPen,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import UsuarioModal from "./UsuarioModal";
import Swal from "sweetalert2";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import GridPadrao from "../../../../components/Comum/GridPadrao";
import IUsuario from "../../../../interfaces/IUsuario";
import UsuarioService from "../../../../services/UsuarioService";
import { useNavigate } from "react-router-dom";
import FiltroPesquisaUsuarioRequest from "../../../../interfaces/Requests/FiltroPesquisaUsuarioRequest";
import ClienteService from "../../../../services/ClienteService";
import ReactSelect, { MultiValue } from "react-select";
import UsuarioDetalheModal from "./UsuarioDetalhes";
import GrupoUsuarioModal from "./GrupoUsuarioModal";
import IOption from "../../../../interfaces/IOption";

const usuarioInicial: IUsuario = {
  nome: "",
  clienteId: 0,
  pessoaId: 0,
  codigo: "",
  clienteNome: "",
  usuarioTipo: 0,
  status: "",
  email: "",
  nomePessoa: "",
  nomeSocial: "",
  nomeUsuario: "",
  numeroDocumento: "",
  usuarioId: 0,
  escritorioId: 0,
  exibirProcessoRestrito: false,
  exibirContratoRestrito: false,
  exibirDocumentoRestrito: false,
  exibirLicencaRestrito: false,
  exibirConsultaRestrito: false,
  exibirImovelRestrito: false,
  exibirProcuracaoRestrito: false,
  exibirMarcaRestrito: false,
  alterarCompromissosDeTerceiros: false,
  perfisUsuario: [],
};

const UsuarioPage = () => {
  const [carregandoUsuarios, setCarregandoUsuarios] = useState(false);
  const [usuario, setUsuario] = useState<IUsuario>(usuarioInicial);
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);

  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [exibirDetalheModal, setExibirDetalheModal] = useState<boolean>(false);
  const [exibirVisualizadorAgendaModal, setExibirVisualizadorAgendaModal] =
    useState<boolean>(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [clientesSelecionados, setClientesSelecionados] = useState<
    MultiValue<{ value: string; label: string }>
  >([]);

  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaUsuarioRequest>({
      clientes: [],
      numeroDocumento: "",
      email: "",
      nomeUsuario: "",
      status: 1,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "clienteId",
    });

  const navigate = useNavigate();

  function toggleFormModal(carregarDados?: boolean): void {
    if (carregarDados) {
      carregarUsuarios(filtrosPesquisa);
    }

    setExibirModal(!exibirModal);

    setTimeout(() => {
      setUsuario(usuarioInicial);
    }, 300);
  }

  function toggleDetalheModal(): void {
    setExibirDetalheModal(!exibirDetalheModal);

    setTimeout(() => {
      setUsuario(usuarioInicial);
    }, 300);
  }

  const toggleVisualizadorAgendaModal = () =>
    setExibirVisualizadorAgendaModal(!exibirVisualizadorAgendaModal);

  async function carregarUsuarios(filtro: FiltroPesquisaUsuarioRequest) {
    try {
      setCarregandoUsuarios(true);
      let resultado: IRecuperaLista<IUsuario>;

      resultado = await UsuarioService.obterUsuarios(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setUsuarios(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setUsuarios([]);
    } finally {
      setCarregandoUsuarios(false);
    }
  }

  async function alterarStatus(usuarioId: number, status: number) {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await UsuarioService.alterarStatus(usuarioId, status);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status do Usuário alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();

        setFiltrosPesquisa((oldState) => {
          carregarUsuarios(oldState);
          return oldState;
        });
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o Status do Usuário`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  }

  const colunas: TableColumn<IUsuario>[] = [
    {
      name: "Id",
      sortField: "usuarioId",
      selector: (row: IUsuario) => row.usuarioId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
      width:'100px'
    },
    {
      name: "Nome Cliente",
      sortField: "clienteNome",
      selector: (row: IUsuario) => row.clienteNome,
      sortable: true,
      wrap: true,
      width:'400px'
    },
    {
      name: "Documento",
      sortField: "numeroDocumento",
      selector: (row: IUsuario) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome Social",
      sortField: "nomeSocial",
      selector: (row: IUsuario) => row.nomeSocial,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      sortField: "email",
      selector: (row: IUsuario) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome do Usuário",
      sortField: "nomeUsuario",
      selector: (row: IUsuario) => row.nomeUsuario,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (usuario: IUsuario) => {
        return (
          <>
            <div style={{ paddingRight: "10px" }}>
              <FontAwesomeIcon
                title="Editar Usuário"
                onClick={() => {
                  setUsuario(usuario);
                  setExibirModal(true);
                }}
                style={{ fontWeight: "normal", cursor: "pointer" }}
                size="1x"
                className="mx-2 text-orange"
                icon={faPen}
              />
            </div>
            <div style={{ paddingRight: "10px" }}>
              {usuario.status === "Ativo" ? (
                <FontAwesomeIcon
                  title="Desativar Usuário"
                  onClick={() => {
                    alterarStatus(usuario.usuarioId, -1);
                  }}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="1x"
                  className="mx-2 text-orange"
                  icon={faTrashAlt}
                />
              ) : (
                <FontAwesomeIcon
                  title="Ativar Usuário"
                  onClick={() => {
                    alterarStatus(usuario.usuarioId, 1);
                  }}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="1x"
                  className="mx-2 text-orange"
                  icon={faCheckCircle}
                />
              )}
            </div>
            <div style={{ paddingRight: "10px" }}>
              <FontAwesomeIcon
                title="Detalhes Usuário"
                onClick={() => {
                  setUsuario(usuario);
                  setExibirDetalheModal(true);
                }}
                style={{ fontWeight: "normal", cursor: "pointer" }}
                size="1x"
                className="mx-1 text-orange"
                icon={faAddressCard}
              />
            </div>
            <div style={{ paddingRight: "10px" }}>
              <FontAwesomeIcon
                title="Agenda"
                onClick={() => {
                  setUsuario(usuario);
                  setExibirVisualizadorAgendaModal(true);
                }}
                style={{ fontWeight: "normal", cursor: "pointer" }}
                size="1x"
                className="mx-1 text-orange"
                icon={faCalendar}
              />
            </div>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<IUsuario>,
    sortDirection: string
  ) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  async function pesquisarClientes() {
    try {
      const result = await ClienteService.obterClientes({
        clienteId: 0,
        pessoaId: 0,
        clienteNome: "",
        clienteDocumento: "",
        clienteTipo: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "clienteNome",
      });

      result.data.map((item) =>
        options.push({ label: item.clienteNome, value: item.clienteId })
      );

      setOptions(options);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar os Clientes`,
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        showConfirmButton: true,
      });
    }
  }

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        clientes: [],
        numeroDocumento: "",
        email: "",
        nomeUsuario: "",
        status: 1,
      };
    });
    setClientesSelecionados([]);
  }

  useEffect(() => {
    if (!carregandoUsuarios) {
      carregarUsuarios(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    pesquisarClientes();
  }, []);

  return (
    <>
      <ToastContainer />
      <LayoutPageTitle title="Usuários">
        <button
          onClick={() => {
            navigate("/Administracao/Seguranca/Usuarios/Cadastrar");
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      {usuario && (
        <UsuarioModal
          exibirModal={exibirModal}
          titulo="Editar Usuário"
          toggleModal={toggleFormModal}
          usuario={usuario}
          clientes={options}
        />
      )}

      <UsuarioDetalheModal
        exibirModal={exibirDetalheModal}
        titulo={"Detalhes do Usuário"}
        toggleModal={toggleDetalheModal}
        usuario={usuario}
      />

      <GrupoUsuarioModal
        exibirModal={exibirVisualizadorAgendaModal}
        toggleModal={toggleVisualizadorAgendaModal}
        usuario={usuario}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarUsuarios(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2 ">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Nome do Usuário:
              </label>
              <input
                value={filtrosPesquisa.nomeUsuario}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nomeUsuario: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>

            <div className="col-md-2 ">
              <label
                htmlFor="form-email"
                className="form-label fw-bolder text-orange"
              >
                E-mail:
              </label>
              <input
                value={filtrosPesquisa.email}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, email: e.target.value };
                  });
                }}
                placeholder="Email"
                type="text"
                className={"form-control"}
                id="form-email"
              />
            </div>

            <div className="col-md-2 ">
              <label
                htmlFor="form-cliente"
                className="form-label fw-bolder text-orange"
              >
                Cliente:
              </label>
              <ReactSelect
                onChange={(selectedOptions) => {
                  let idsCliente = selectedOptions.map((c) =>
                    parseInt(c.value)
                  );

                  setFiltrosPesquisa((oldState) => ({
                    ...oldState,
                    clientes: idsCliente,
                  }));
                  setClientesSelecionados(selectedOptions);
                }}
                name="clienteId"
                placeholder="Clientes"
                isMulti={true}
                value={clientesSelecionados}
                defaultValue={clientesSelecionados}
                options={options.map((c) => ({
                  value: c.value.toString(),
                  label: c.label,
                }))}
              />
            </div>
            <div className="col-md-2 ">
              <label
                htmlFor="form-numeroDocumento"
                className="form-label fw-bolder text-orange"
              >
                Documento:
              </label>
              <input
                value={filtrosPesquisa.numeroDocumento}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, numeroDocumento: e.target.value };
                  });
                }}
                placeholder="Numero Documento"
                type="text"
                className={"form-control"}
                id="form-numeroDocumento"
              />
            </div>
            <div className="col-md-2">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Status:
              </label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, status: parseInt(e.target.value) };
                  });
                }}
                placeholder="Nome"
                className={"form-select"}
                id="form-select"
              >
                <option value="0" label="Todos">
                  {" "}
                  Todos{" "}
                </option>
                <option value="1" label="Ativo">
                  {" "}
                  Ativo{" "}
                </option>
                <option value="-1" label="Inativo">
                  {" "}
                  Inativo{" "}
                </option>
              </select>
            </div>
            <div className="col-md-2">
              <div>
                <button
                  type="submit"
                  className="btn btn-sm btn-orange search-buttom-margin "
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faSearch}
                  />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faEraser}
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoUsuarios}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Usuários"
            itens={usuarios}
          />
        </div>
      </div>
    </>
  );
};

export default UsuarioPage;
