// React and related hooks
import { useEffect, useState } from "react";
import { useFormik } from 'formik';

// Validation libraries
import * as Yup from 'yup';

// UI and styling libraries
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Services and Interfaces
import AutomacaoService from '../../../services/AutomacaoService';
import { IAutomacao } from '../../../interfaces/Requests/Automacao/IAutomacao';
import IRecuperaLista from '../../../interfaces/IRecuperaLista';

interface IAutomacaoModalProps {
    toggleModal: (carregarDados?: boolean) => void;
    exibirModal: boolean;
    automacao: IAutomacao;
    titulo: string;
}

const AutomacaoModal = ({ toggleModal, exibirModal, automacao, titulo }: IAutomacaoModalProps) => {

    const [tipos, setTipos] = useState<any[]>([]);

    useEffect(() => {
        carregarTipo();

        async function montarForm() {
            await formik.resetForm();

            if (automacao.automacaoId) {
                await formik.setValues({ ...automacao });
            }

            await formik.validateForm();
        }

        montarForm();
    }, [exibirModal, automacao]);

    const automacaoValidacoes = Yup.object().shape({
        nome: Yup.string().max(50, 'Máximo 50 caracteres').required('Nome é obrigatório'),
        modulo: Yup.number().required('Módulo é obrigatório').min(1, 'Selecione um módulo válido'),
        tipo: Yup.number().required('Tipo é obrigatório').min(1, 'Selecione um tipo válido'),
    });

    const carregarTipo = async () => {
        try {
            const resultado: IRecuperaLista<any> = await AutomacaoService.obterTipos({
                nome: "",
                codigo: "",
                status: 1,
                limit: 1000,
                totalItems: 0,
                offset: 0,
                sort: "tipoId",
            });
            setTipos(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message || error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
        }
    };

    const formik = useFormik({
        initialValues: {
            nome: '',
            codigo: '',
            automacaoId: 0,
            modulo: 0,
            tipo: 0,
        } as IAutomacao,
        validationSchema: automacaoValidacoes,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!values.automacaoId) {
                    await AutomacaoService.adicionaAutomacao(values);
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Automação cadastrada com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                } else {
                    await AutomacaoService.atualizarAutomacao(values);
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Automação editada com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }

                setSubmitting(false);
                toggleModal(true);
            } catch (error: any) {
                const mensagemErro = error?.response?.data?.Message || 'Ocorreu um erro inesperado';

                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possível ${values.automacaoId ? 'editar' : 'cadastrar'} a automação`,
                    text: mensagemErro,
                    showConfirmButton: true
                });

                setSubmitting(false);
            }
        }
    });

    return (
        <Modal size='lg' centered show={exibirModal} onHide={() => toggleModal()}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <button onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </button>
                </div>
                <div className="modal-body">
                    <form className="row g-3" onSubmit={formik.handleSubmit}>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-modulo" className="form-label required fw-bolder text-orange">Módulo:</label>
                            <select
                                id="form-modulo"
                                className={clsx('form-select', { 'is-invalid': formik.touched.modulo && formik.errors.modulo })}
                                {...formik.getFieldProps('modulo')}
                            >
                                <option value="">Selecione um módulo</option>
                                <option value="1">Contencioso</option>
                                <option value="2">XSigner</option>
                                <option value="3">XFinder</option>
                                <option value="4">Administração</option>
                                <option value="5">Societário</option>
                                <option value="6">Ged</option>
                                <option value="7">Contrato</option>
                                <option value="8">Dashboard</option>
                                <option value="9">Consultivo</option>
                                <option value="10">Imobiliário</option>
                                <option value="11">Marcas & Patentes</option>
                                <option value="12">Despesa</option>
                                <option value="13">Ato Societário</option>
                                <option value="14">Andamento</option>
                                <option value="15">Bloqueio</option>
                                <option value="16">Compromisso</option>
                                <option value="17">Decisão</option>
                                <option value="18">Desdobramento</option>
                                <option value="19">Favorito</option>
                                <option value="20">Fechamento</option>
                                <option value="21">Procuração</option>
                                <option value="22">Garantia</option>
                                <option value="23">Obrigação</option>
                                <option value="24">Pessoas</option>
                                <option value="25">Proposta de Acordo</option>
                                <option value="26">Publicação</option>
                                <option value="27">Valores</option>
                                <option value="28">Log</option>
                                <option value="29">Pesquisa Global</option>
                                <option value="30">Regulatório</option>
                                <option value="31">Relatório</option>
                                <option value="32">Email</option>
                                <option value="33">Editor de Documento</option>
                                <option value="34">Xpay Honorário</option>
                                <option value="35">Xpay Reembolso</option>
                                <option value="36">Xpay Fechamento</option>
                                <option value="37">Xpay Contrato</option>
                                <option value="38">Audiência</option>
                                <option value="39">XLearning</option>
                                <option value="40">Parte Contrária</option>
                                <option value="41">Encerramento de Processo</option>
                                <option value="42">XFinder Distribuição</option>
                                <option value="43">Representante Societário</option>
                                <option value="44">Sócio Administrador</option>
                                <option value="45">Filial</option>
                                <option value="46">XFinder Protocolo</option>
                                <option value="47">Comunicação</option>
                                <option value="48">Cliente</option>
                                <option value="49">Usuário</option>
                                <option value="50">Levantamento de Garantia</option>
                            </select>
                            {formik.touched.modulo && formik.errors.modulo && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.modulo}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-tipo" className="form-label required fw-bolder text-orange">Tipo:</label>
                            <select
                                id="form-tipo"
                                className={clsx('form-select', { 'is-invalid': formik.touched.tipo && formik.errors.tipo })}
                                {...formik.getFieldProps('tipo')}
                            >
                                <option value={0}>Selecione um tipo</option>
                                {tipos.map((tipo) => (
                                    <option key={tipo.tipoId} value={tipo.tipoId}>
                                        {tipo.nome}
                                    </option>
                                ))}
                            </select>
                            {formik.touched.tipo && formik.errors.tipo && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.tipo}</span>
                                    </div>
                                </div>
                            )}
                        </div>


                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-nome" className="form-label required fw-bolder">Nome</label>
                            <input
                                id="form-nome"
                                {...formik.getFieldProps('nome')}
                                placeholder="Nome"
                                type="text"
                                className={clsx(
                                    'form-control',
                                    { 'is-invalid': formik.touched.nome && formik.errors.nome },
                                    { 'is-valid': formik.touched.nome && !formik.errors.nome }
                                )}
                            />
                            {formik.touched.nome && formik.errors.nome && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.nome}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        type="submit"
                        onClick={formik.submitForm}
                        className="btn btn-orange me-5"
                    >
                        {!formik.isSubmitting ? 'SALVAR' : (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button
                        disabled={formik.isSubmitting}
                        onClick={() => toggleModal()}
                        type="button"
                        className="btn btn-danger ms-5"
                    >
                        CANCELAR
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AutomacaoModal;
