import { createContext, ReactChild, useState } from "react";
import { ETelaEnum } from "../enum/ETelaEnum";
import ITipoAto from "../interfaces/ITipoAto";
import { ICampo } from "../interfaces/ICampo";
import IContrato from "../interfaces/IContrato";
import IEmpresa from "../interfaces/IEmpresa";
import IProcesso from "../interfaces/IProcesso";
import ITipoMarca from "../interfaces/ITipoMarca";
import ITipoImovel from "../interfaces/ITipoImovel";
import ITipoDespesa from "../interfaces/ITipoDespesa";
import IAreaDireito from "../interfaces/IAreaDireito";
import ITipoLicenca from "../interfaces/ITipoLicenca";
import ITipoContrato from "../interfaces/ITipoContrato";
import ITipoConsulta from "../interfaces/ITipoConsulta";
import ITipoSociedade from "../interfaces/ITipoSociedade";
import ITipoProcuracao from "../interfaces/ITipoProcuracao";
import { IAtoSocietario } from "../interfaces/AtoSocietario";
import IModeloRelatorio from "../interfaces/IModeloRelatorio";
import { OrangeInterfaceContext } from "../interfaces/Contexts/OrangeInterfaceContext";
import IAdicionaProcessoRequest from "../interfaces/Requests/Processo/IAdicionaProcessoRequest";
import {
  modeloRelatorioInitialValues,
  areaDireitoInicialValues,
  tipoSociedadeInicialValues,
  tipoMarcaInicialValues,
  tipoImovelInicialValues,
  tipoContratoInicialValues,
  tipoLicencaInicialValues,
  tipoConsultaInicialValues,
  tipoProcuracaoInicialValues,
  processoInitialValues,
  contratoInitialValues,
  marcaInitialValues,
  procuracaoInitialValues,
  consultaInitialValues,
  imovelInitialValues,
  licencaInitialValues,
  processoCadastroInicialValues,
  empresaInitialValues,
  atoSocietarioInitialValues,
  tipoAtoInicialValues,
  tipoDespesaInicialValues,
  despesaInicialValues,
  tipoPropriedadeInicialValues,
  fluxoFaseInicialValues,
  obrigacaoInicialValues,
  bloqueioInicialValues,
  decisaoInicialValues,
  garantiaInicialValues,
  desdobramentoInicialValues,
  propostaAcordoInicialValues,
  tipoHonorarioInicialValues,
  honorarioInicialValues,
  tipoReembolsoInicialValues,
  reembolsoInicialValues,
  tipoPropostaAcordoInicialValues,
  tipoGarantiaInicialValues,
  tipoParteContrariaInitialValues,
  tipoBloqueioInicialValues,
  tipoDecisaoInicialValues,
  tipoDesdobramentoInicialValues,
  tipoObrigacaoInicialValues,
  tipoProtocoloInicialValues,
  protocoloInitialValues,
  tipoSeparadorInicialValues,
  comunicacaoInitialValues,
  tipoComunicacaoInitialValues,
  parteContrariaInitial,
  tipoAudienciaInicialValues,
  audienciaInicialValues,
  selectedParametrizacoesInitialValues,
  selectedChangeLogInitialValues,
  selectedThemeInitialValues
} from "./InitialValuesContext";
import IDespesa from "../interfaces/IDespesa";
import IPropriedade from "../interfaces/IPropriedade";
import ITipoPropriedades from "../interfaces/ITipoPropriedades";
import IFluxo from "../interfaces/IFluxo";
import IObrigacao from "../interfaces/IObrigacao";
import IBloqueio from "../interfaces/IBloqueio";
import IDecisao from "../interfaces/IDecisao";
import IGarantia from "../interfaces/IGarantia";
import IDesdobramento from "../interfaces/IDesdobramento";
import IPropostaAcordo from "../interfaces/IPropostaAcordo";
import ITipoHonorario from "../interfaces/ITipoHonorario";
import IHonorario from "../interfaces/IHonorario";
import ITipoReembolso from "../interfaces/ITipoReembolso";
import IReembolso from "../interfaces/IReembolso";
import IConsulta from "../interfaces/IConsulta";
import ITipoPropostaAcordo from "../interfaces/ITipoPropostaAcordo";
import ITipoGarantia from "../interfaces/ITipoGarantia";
import ITipoBloqueio from "../interfaces/ITipoBloqueio";
import ITipoDecisao from "../interfaces/ITipoDecisao";
import ITipoDesdobramento from "../interfaces/ITipoDesdobramento";
import ITipoObrigacao from "../interfaces/ITipoObrigacao";
import ITipoProtocolo from "../interfaces/ITipoProtocolo";
import IProtocolo from "../interfaces/IProtocolo";
import ISeparador from "../interfaces/ISeparador";
import ITipoSeparadores from "../interfaces/ITipoSeparadores";
import ITipoComunicacao from "../interfaces/ITipoComunicacao";
import IComunicacao from "../interfaces/IComunicacao";
import IParteContraria from "../interfaces/IParteContraria";
import ITipoAudiencia from "../interfaces/ITipoAudiencia";
import IAudiencia from "../interfaces/IAudiencia";
import IFechamentoConfiguracaoParametrizacao from "../interfaces/IFechamentoConfiguracaoParametrizacao";
import IChangeLog from "../interfaces/IChangeLog";
import { DefaultTheme } from "styled-components";
import { EModulo } from "../enum";

export const OrangeContext = createContext<OrangeInterfaceContext>({
  setModeloRelatorio: () => ({}),
  modeloRelatorio: modeloRelatorioInitialValues,

  setAreaDireito: () => ({}),
  areaDireito: areaDireitoInicialValues,

  setFluxoFase: () => ({}),
  fluxoFase: fluxoFaseInicialValues,

  setTipoPropriedade: () => ({}),
  tipoPropriedade: tipoPropriedadeInicialValues,

  setPropriedades: () => ({}),
  propriedades: [],

  setEPropriedades: () => ({}),
  EPropriedades: [],

  setTipoSeparador: () => ({}),
  tipoSeparador: tipoSeparadorInicialValues,

  setSeparadores: () => ({}),
  separadores: [],

  setESeparadores: () => ({}),
  ESeparadores: [],

  setTipoSociedade: () => ({}),
  tipoSociedade: tipoSociedadeInicialValues,

  setTipoDespesa: () => ({}),
  tipoDespesa: tipoDespesaInicialValues,

  setDespesa: () => ({}),
  despesa: despesaInicialValues,

  setTipoMarca: () => ({}),
  tipoMarca: tipoMarcaInicialValues,

  setAtoSocietario: () => ({}),
  atoSocietario: atoSocietarioInitialValues,

  setTipoAto: () => ({}),
  tipoAto: tipoAtoInicialValues,

  setTipoImovel: () => ({}),
  tipoImovel: tipoImovelInicialValues,

  setTipoContrato: () => ({}),
  tipoContrato: tipoContratoInicialValues,

  setTipoLicenca: () => ({}),
  tipoLicenca: tipoLicencaInicialValues,

  setTipoConsulta: () => ({}),
  tipoConsulta: tipoConsultaInicialValues,

  setTipoProcuracao: () => ({}),
  tipoProcuracao: tipoProcuracaoInicialValues,

  setTela: () => ({}),
  tela: ETelaEnum.Default,

  setCampos: () => ({}),
  campos: [],

  setECampos: () => ({}),
  eCampos: [],

  setProcesso: () => ({}),
  processo: processoInitialValues,

  setContrato: () => ({}),
  contrato: contratoInitialValues,

  setMarca: () => ({}),
  marca: marcaInitialValues,

  setProcuracao: () => ({}),
  procuracao: procuracaoInitialValues,

  setConsulta: () => ({}),
  consulta: consultaInitialValues,

  setImovel: () => ({}),
  imovel: imovelInitialValues,

  setLicenca: () => ({}),
  licenca: licencaInitialValues,

  setProcessoCadastro: () => ({}),
  processoCadastro: processoCadastroInicialValues,

  setEmpresa: () => ({}),
  empresa: empresaInitialValues as IEmpresa,

  setObrigacao: () => ({}),
  obrigacao: obrigacaoInicialValues as IObrigacao,

  setBloqueio: () => ({}),
  bloqueio: bloqueioInicialValues as IBloqueio,

  setDecisao: () => ({}),
  decisao: decisaoInicialValues as IDecisao,

  setGarantia: () => ({}),
  garantia: garantiaInicialValues as IGarantia,

  setDesdobramento: () => ({}),
  desdobramento: desdobramentoInicialValues as IDesdobramento,

  setPropostaAcordo: () => ({}),
  propostaAcordo: propostaAcordoInicialValues as IPropostaAcordo,

  setTipoHonorario: () => ({}),
  tipoHonorario: tipoHonorarioInicialValues,

  setHonorario: () => ({}),
  honorario: honorarioInicialValues,

  setTipoReembolso: () => ({}),
  tipoReembolso: tipoReembolsoInicialValues,

  setReembolso: () => ({}),
  reembolso: reembolsoInicialValues,

  setTipoPropostaAcordo: () => ({}),
  tipoPropostaAcordo: tipoPropostaAcordoInicialValues,

  setTipoGarantia: () => ({}),
  tipoGarantia: tipoGarantiaInicialValues as ITipoGarantia,

  setTipoParteContraria: () => ({}),
  tipoParteContraria: tipoParteContrariaInitialValues,

  setTipoBloqueio: () => ({}),
  tipoBloqueio: tipoBloqueioInicialValues as ITipoBloqueio,

  setTipoDecisao: () => ({}),
  tipoDecisao: tipoDecisaoInicialValues as ITipoDecisao,

  setTipoDesdobramento: () => ({}),
  tipoDesdobramento: tipoDesdobramentoInicialValues as ITipoDesdobramento,
  
  setTipoObrigacao: () => ({}),
  tipoObrigacao: tipoObrigacaoInicialValues as ITipoObrigacao,

  setTipoProtocolo: () => ({}),
  tipoProtocolo: tipoProtocoloInicialValues as ITipoProtocolo,

  setTipoAudiencia: () => ({}),
  tipoAudiencia: tipoAudienciaInicialValues as ITipoAudiencia,

  setAudiencia: () => ({}),
  audiencia: audienciaInicialValues as IAudiencia, 

  setProtocolo: () => ({}),
  protocolo: protocoloInitialValues,

  setComunicacao: () => ({}),
  comunicacao: comunicacaoInitialValues,
  
  setTipoComunicacao: () => ({}),
  tipoComunicacao: tipoComunicacaoInitialValues as ITipoComunicacao,

  setParteContraria: () => ({}),
  parteContraria: parteContrariaInitial,

  setUrlLogo: () => ({}),
  urlLogo: '',

  setSelectedParametrizacoes: ()=>({}),
  selectedParametrizacoes: selectedParametrizacoesInitialValues as IFechamentoConfiguracaoParametrizacao,

  setSelectedChangeLog: ()=>({}),
  selectedChangeLog: selectedChangeLogInitialValues as IChangeLog,

  setSelectedTheme: () => ({}),
  selectedTheme: selectedThemeInitialValues as DefaultTheme,

  setThemeMode: () => ({}),
  themeMode: "light" as string,
});

const OrangeProvider = (propsProvider: { children: ReactChild }) => {

  const [tela, setTela] = useState<ETelaEnum>(ETelaEnum.Default);

  const [fluxoFase, setFluxoFase] = useState<IFluxo>(fluxoFaseInicialValues);

  const [propriedades, setPropriedades] = useState<IPropriedade[]>([]);
  const [EPropriedades, setEPropriedades] = useState<IPropriedade[]>([]);

  const [tipoPropriedade, setTipoPropriedade] = useState<ITipoPropriedades>(tipoPropriedadeInicialValues);

  const [separadores, setSeparadores] = useState<ISeparador[]>([]);
  const [ESeparadores, setESeparadores] = useState<ISeparador[]>([]);

  const [tipoSeparador, setTipoSeparador] = useState<ITipoSeparadores>(tipoSeparadorInicialValues);

  const [processoCadastro, setProcessoCadastro] = useState<IAdicionaProcessoRequest>(processoCadastroInicialValues);
  const [areaDireito, setAreaDireito] = useState<IAreaDireito>(areaDireitoInicialValues);
  const [processo, setProcesso] = useState<IProcesso>(processoInitialValues);

  const [tipoMarca, setTipoMarca] = useState<ITipoMarca>(tipoMarcaInicialValues);
  const [marca, setMarca] = useState<IMarca>(marcaInitialValues);

  const [tipoContrato, setTipoContrato] = useState<ITipoContrato>(tipoContratoInicialValues);
  const [contrato, setContrato] = useState<IContrato>(contratoInitialValues);

  const [tipoImovel, setTipoImovel] = useState<ITipoImovel>(tipoImovelInicialValues);
  const [imovel, setImovel] = useState<IImovel>(imovelInitialValues);

  const [tipoLicenca, setTipoLicenca] = useState<ITipoLicenca>(tipoLicencaInicialValues);
  const [licenca, setLicenca] = useState<ILicenca>(licencaInitialValues);

  const [tipoAto, setTipoAto] = useState<ITipoAto>(tipoAtoInicialValues);
  const [atoSocietario, setAtoSocietario] = useState<IAtoSocietario>(atoSocietarioInitialValues);

  const [tipoConsulta, setTipoConsulta] = useState<ITipoConsulta>(tipoConsultaInicialValues);
  const [consulta, setConsulta] = useState<IConsulta>(consultaInitialValues);

  const [tipoProcuracao, setTipoProcuracao] = useState<ITipoProcuracao>(tipoProcuracaoInicialValues);
  const [procuracao, setProcuracao] = useState<IProcuracao>(procuracaoInitialValues);

  const [tipoSociedade, setTipoSociedade] = useState<ITipoSociedade>(tipoSociedadeInicialValues);
  const [empresa, setEmpresa] = useState<IEmpresa>(empresaInitialValues as IEmpresa);

  const [tipoDespesa, setTipoDespesa] = useState<ITipoDespesa>(tipoDespesaInicialValues);
  const [despesa, setDespesa] = useState<IDespesa>(despesaInicialValues);

  const [tipoHonorario, setTipoHonorario] = useState<ITipoHonorario>(tipoHonorarioInicialValues);
  const [honorario, setHonorario] = useState<IHonorario>(honorarioInicialValues);

  const [tipoReembolso, setTipoReembolso] = useState<ITipoReembolso>(tipoReembolsoInicialValues);
  const [reembolso, setReembolso] = useState<IReembolso>(reembolsoInicialValues);

  const [campos, setCampos] = useState<ICampo[]>([]);
  const [eCampos, setECampos] = useState<ICampo[]>([]);

  const [obrigacao, setObrigacao] = useState<IObrigacao>(obrigacaoInicialValues);
  const [tipoObrigacao, setTipoObrigacao] = useState<ITipoObrigacao>(tipoObrigacaoInicialValues);

  const [comunicacao, setComunicacao] = useState<IComunicacao>(comunicacaoInitialValues);
  const [tipoComunicacao, setTipoComunicacao] = useState<ITipoComunicacao>(tipoComunicacaoInitialValues);

  const [bloqueio, setBloqueio] = useState<IBloqueio>(bloqueioInicialValues);
  const [tipoBloqueio, setTipoBloqueio] = useState<ITipoBloqueio>(tipoBloqueioInicialValues);

  const [decisao, setDecisao] = useState<IDecisao>(decisaoInicialValues);
  const [tipoDecisao, setTipoDecisao] = useState<ITipoDecisao>(tipoDecisaoInicialValues);

  const [tipoGarantia, setTipoGarantia] = useState<ITipoGarantia>(tipoGarantiaInicialValues);
  const [garantia, setGarantia] = useState<IGarantia>(garantiaInicialValues);

  const [tipoDesdobramento, setTipoDesdobramento] = useState<ITipoDesdobramento>(tipoDesdobramentoInicialValues);
  const [desdobramento, setDesdobramento] = useState<IDesdobramento>(desdobramentoInicialValues);

  const [tipoPropostaAcordo, setTipoPropostaAcordo] = useState<ITipoPropostaAcordo>(tipoPropostaAcordoInicialValues);
  const [propostaAcordo, setPropostaAcordo] = useState<IPropostaAcordo>(propostaAcordoInicialValues);

  const [tipoProtocolo, setTipoProtocolo] = useState<ITipoProtocolo>(tipoProtocoloInicialValues);
  const [protocolo, setProtocolo] = useState<IProtocolo>(protocoloInitialValues);

  const [tipoAudiencia, setTipoAudiencia] = useState<ITipoAudiencia>(tipoAudienciaInicialValues);
  const [audiencia, setAudiencia] = useState<IAudiencia>(audienciaInicialValues);

  const [modeloRelatorio, setModeloRelatorio] = useState<IModeloRelatorio>(modeloRelatorioInitialValues);

  const [parteContraria, setParteContraria] = useState<IParteContraria>(parteContrariaInitial);
  const [tipoParteContraria, setTipoParteContraria] = useState<any>(tipoParteContrariaInitialValues);

  const [urlLogo, setUrlLogo] = useState<string>('');
  
  const [selectedParametrizacoes, setSelectedParametrizacoes] = useState<IFechamentoConfiguracaoParametrizacao>(selectedParametrizacoesInitialValues);
  
  const [selectedChangeLog, setSelectedChangeLog] = useState<IChangeLog>(selectedChangeLogInitialValues);
  
  const [selectedTheme, setSelectedTheme] = useState<DefaultTheme>(selectedThemeInitialValues);
  
  const [themeMode, setThemeMode] = useState<string>("light");


  return (
    <OrangeContext.Provider
      value={{

        fluxoFase,
        setFluxoFase,

        setModeloRelatorio,
        modeloRelatorio,

        setAreaDireito,
        areaDireito,

        propriedades,
        setPropriedades,

        EPropriedades,
        setEPropriedades,

        setTipoPropriedade,
        tipoPropriedade,

        separadores,
        setSeparadores,

        ESeparadores,
        setESeparadores,

        setTipoSeparador,
        tipoSeparador,

        setDespesa,
        despesa,

        setTipoMarca,
        tipoMarca,

        setTipoLicenca,
        tipoLicenca,

        setTipoConsulta,
        tipoConsulta,

        setTipoDespesa,
        tipoDespesa,

        setTipoProcuracao,
        tipoProcuracao,

        setProcuracao,
        procuracao,

        setMarca,
        marca,

        setAtoSocietario,
        atoSocietario,

        setTipoAto,
        tipoAto,

        setConsulta,
        consulta,

        setImovel,
        imovel,

        setLicenca,
        licenca,

        setTipoContrato,
        tipoContrato,

        setTipoImovel,
        tipoImovel,

        setTela,
        tela,

        campos,
        setCampos,

        eCampos,
        setECampos,

        processo,
        setProcesso,

        contrato,
        setContrato,

        setProcessoCadastro,
        processoCadastro,

        empresa,
        setEmpresa,

        tipoSociedade,
        setTipoSociedade,

        obrigacao,
        setObrigacao,

        bloqueio,
        setBloqueio,

        decisao,
        setDecisao,

        garantia,
        setGarantia,

        desdobramento,
        setDesdobramento,

        propostaAcordo,
        setPropostaAcordo,

        setTipoHonorario,
        tipoHonorario,

        setHonorario,
        honorario,

        setTipoReembolso,
        tipoReembolso,

        setReembolso,
        reembolso,

        tipoPropostaAcordo,
        setTipoPropostaAcordo,

        tipoGarantia,
        setTipoGarantia,

        tipoParteContraria,
        setTipoParteContraria,
        
        tipoBloqueio,
        setTipoBloqueio,

        tipoDecisao,
        setTipoDecisao,

        tipoDesdobramento,
        setTipoDesdobramento,

        tipoObrigacao,
        setTipoObrigacao,

        tipoProtocolo,
        setTipoProtocolo,

        setProtocolo,
        protocolo,

        setAudiencia,
        audiencia,

        tipoAudiencia,
        setTipoAudiencia,

        urlLogo,
        setUrlLogo,

        comunicacao,
        setComunicacao,

        tipoComunicacao,
        setTipoComunicacao,
        
        parteContraria,
        setParteContraria,

        selectedParametrizacoes,
        setSelectedParametrizacoes,
        
        selectedChangeLog,
        setSelectedChangeLog,

        selectedTheme,
        setSelectedTheme,

        themeMode,
        setThemeMode
      }}
    >
      {propsProvider.children}
    </OrangeContext.Provider>
  );
};

export default OrangeProvider;