import React from "react"
import { CustomEditor } from "../../../../../utils/types/slate"

export enum KeyboardKeys {
  STRIKETHROUGH = "s",
  BOLD = "b",
  ITALIC = "i",
  UNDERLINE = "u",
  ENTER = "Enter",
  UNDO = "z",
  REDO = "y"
}

export interface KeydownParams {
  event: React.KeyboardEvent
  editor: CustomEditor
}

export type ActionKey = {
  [K in KeyboardKeys]: () => void
}
