import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import IEnvelopeSignatario from "../../../interfaces/IEnvelopeSignatario";
import ISignatarioPapel from '../../../interfaces/ISignatarioPapel';
import EnvelopeService from "../../../services/EnvelopeService";
import Swal from "sweetalert2";
import clsx from "clsx";

interface IEnvelopeSignatarioModalProps {
    toggleModal: (signatario?: IEnvelopeSignatario) => void,
    exibirModal: boolean,
    signatario: IEnvelopeSignatario,
    papeisSignatario: ISignatarioPapel[],
    titulo: string,
}

const EnvelopeSignatarioModal = ({ toggleModal, exibirModal, signatario, titulo, papeisSignatario }: IEnvelopeSignatarioModalProps) => {

    const signatarioValidacoes = Yup.object().shape({
        nome: Yup.string().max(50, 'Maximo 50 caracteres'),
        email: Yup.string().max(50, 'Maximo 50 caracteres').required('Email é obrigatório').email('Email inválido'),
        papelSignatarioId: Yup.string().required('Papel é obrigatório').matches(/^[0-9]+$/, 'Papel inválido'),
        
    })

    const formularioSignatario = useFormik({
        initialValues: {nome: signatario.nome, email: signatario.email, papelSignatarioId: signatario.papelSignatarioId, } as IEnvelopeSignatario,
        validationSchema: signatarioValidacoes,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                signatario.nome = values.nome;
                signatario.email = values.email;
                signatario.papelSignatarioId = values.papelSignatarioId;
                
                if((signatario?.signatarioId || 0) > 0){
                    await EnvelopeService.atualizarSignatario(signatario);

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Signatário ${signatario.signatarioId && signatario.signatarioId > 0 ? 'editado' : 'criado'} com sucesso`,
                        showConfirmButton: false,
                        timer: 3000,
                        didClose: () => {
                            toggleModal(signatario);
                        }
                    }); 
                } else {
                    toggleModal(signatario);
                }

            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possível ${values.signatarioId && values.signatarioId > 0 ? 'editar' : 'criar'} o envelope`,
                    text: error && (error.response && error.response.data)? typeof error.response.data === 'string' ? error.response.data: error.response.data.Message: error.message,
                    showConfirmButton: true
                });
            }finally{
                setSubmitting(false);
            }
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <label htmlFor="form-nome" className="form-label required fw-bolder">Nome</label>
                                <input {...formularioSignatario.getFieldProps('nome')} placeholder="Nome Usuario" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formularioSignatario.touched.nome && formularioSignatario.errors.nome,
                                    },
                                    {
                                        'is-valid': formularioSignatario.touched.nome && !formularioSignatario.errors.nome,
                                    }
                                )} id="form-nome" />
                                {formularioSignatario.touched.nome && formularioSignatario.errors.nome && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioSignatario.errors.nome}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="form-email" className="form-label required fw-bolder">Email</label>
                                <input {...formularioSignatario.getFieldProps('email')} placeholder="Email" type="email" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formularioSignatario.touched.email && formularioSignatario.errors.email,
                                    },
                                    {
                                        'is-valid': formularioSignatario.touched.email && !formularioSignatario.errors.email,
                                    }
                                )} id="form-email" />
                                {formularioSignatario.touched.email && formularioSignatario.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioSignatario.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <label htmlFor="form-papelSignatarioId" className="form-label required fw-bolder">Papel</label>
                                <select {...formularioSignatario.getFieldProps('papelSignatarioId')}  placeholder="Papel" className={'form-select'} id="form-select" >{
                                            papeisSignatario && papeisSignatario.map((item, index) => {
                                                return <option key={index} value={item.papelSignatarioId}>{item.nome}</option>
                                            })
                                        }
                                        </select>
                                {formularioSignatario.touched.papelSignatarioId && formularioSignatario.errors.papelSignatarioId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioSignatario.errors.papelSignatarioId}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formularioSignatario.isSubmitting || !formularioSignatario.isValid} onClick={() => { formularioSignatario.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formularioSignatario.isSubmitting && <> SALVAR </>}
                        {formularioSignatario.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formularioSignatario.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default EnvelopeSignatarioModal;