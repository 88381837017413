import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IContrapartida from "../interfaces/IContrapartida";
import { AdicionaContrapartidaRequest } from "../interfaces/Requests/Contrapartida/AdicionaContrapartidaRequest";
import FiltroPesquisaContrapartidaRequest from "../interfaces/Requests/Contrapartida/FiltroPesquisaContrapartidaRequest";
import { EditarContrapartidaRequest } from "../interfaces/Requests/Contrapartida/EditarContrapartidaRequest";

class ContrapartidaService {

    async obterContrapartidas(filtros: FiltroPesquisaContrapartidaRequest): Promise<IRecuperaLista<IContrapartida>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IContrapartida>>(`Contrapartida/RecuperaContrapartida?${query}`);

        return result;
    }

    async adicionaContrapartida(adicionaContrapartida: AdicionaContrapartidaRequest): Promise<IContrapartida> {
        let { data: result } = await api.post<IContrapartida>(`Contrapartida/AdicionaContrapartida`, adicionaContrapartida);

        return result;
    }


    async alterarStatus(contrapartidaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IContrapartida>(`Contrapartida/AtualizaContrapartidaPorId?id=${contrapartidaId}`, data);
    }

    async atualizarContrapartida(editarContrapartida: EditarContrapartidaRequest): Promise<void> {

        let dataContrapartida = [
            { "op": "replace", "path": "/nome", "value": editarContrapartida.nome },
            { "op": "replace", "path": "/codigo", "value": editarContrapartida.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Contrapartida/AtualizaContrapartidaPorId?id=${editarContrapartida.contrapartidaId}`, dataContrapartida)
        ]);
    }
}


export default new ContrapartidaService();