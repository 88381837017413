import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoBloqueio from "../interfaces/ITipoBloqueio";

class TipoBloqueioService {

    async obterTipoBloqueios(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoBloqueio/RecuperaTipoBloqueio?${query}`);

        return result;
    }

    
    async obterListaLimitadaTipoBloqueios(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoBloqueio/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoBloqueio(adicionaTipoBloqueio: any): Promise<ITipoBloqueio> {
        let { data: result } = await api.post<ITipoBloqueio>(`TipoBloqueio/AdicionaTipoBloqueio`, adicionaTipoBloqueio);

        return result;
    }


    async alterarStatus(tipoBloqueioId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoBloqueio>(`TipoBloqueio/AtualizaTipoBloqueioPorId?id=${tipoBloqueioId}`, data);
    }

    async atualizarTipoBloqueio(editarTipoBloqueio: any): Promise<void> {

        let dataTipoBloqueio = [
            { "op": "replace", "path": "/nome", "value": editarTipoBloqueio.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoBloqueio.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoBloqueio/AtualizaTipoBloqueioPorId?id=${editarTipoBloqueio.tipoBloqueioId}`, dataTipoBloqueio)
        ]);
    }
}


export default new TipoBloqueioService();