import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../../enum";
import { defaultSchema, setValuesXCadastroCampos } from "../../../utils/SchemasUtil";

import IOption from "../../../interfaces/IOption";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import Carregando from "../../Carregando";
import { notifyFormikDanger } from "../Toast/ToastFormik";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import TipoAudienciaService from "../../../services/TipoAudienciaService";
import CamposCadastroDinamicos from "../ParteContraria/CamposCadastroParteContraria";
import LayoutPageForButton from "../../../layout/LayoutPageButton";
import AudienciaService from "../../../services/AudienciaService";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

interface IFieldEditProps {
  toggleModal: () => void;
  exibirModal: boolean;
  formik: any;
}

const AudienciaCadastroModal = ({
  toggleModal,
  exibirModal,
  formik: formikProcesso,
}: IFieldEditProps) => {
  const { processo } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoTiposAudiencia, setCarregandoTiposAudiencia] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const initialValues: any = {
    audienciaId: 0,
    principalId: 0,
    tipoAudienciaId: 0,

    modulo: EModulo.Audiencia,
    tela: ETelaEnum.TelaCadastroAudiencia,
    categoriaId: 0,

    processoId: 0,
    areaDireitoId: formikProcesso?.values?.areaDireitoId,

    codigo: "",
    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {

        formikProcesso.values.audienciaList.push(values);

        setConfiguracoesTela([]);

      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        toggleModal();
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    carregarTipoAudiencia();

    setConfiguracoesTela([]);

    formik.setValues(initialValues);

  }, []);


  useEffect(() => {
    const adicionarAudiencia = async () => {
      if (processo?.processoId) {
        try {
          for (const values of formikProcesso.values.audienciaList) {
            await AudienciaService.adicionaAudiencia({
              ...values,
              xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
            });
          }
        } catch (error) {
          console.error("Erro ao adicionar audiência:", error);
        }
      }
    };
  
    adicionarAudiencia();
  }, [processo?.processoId]);
  
  

  useEffect(() => {
    if (formik.values.tipoAudienciaId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      formik.setValues(initialValues);
    }
  }, [formik.values.tipoAudienciaId]);


  const carregarTipoAudiencia = async () => {
    try {

      setCarregandoTiposAudiencia(true);

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await TipoAudienciaService.obterListaLimitadaTipoAudiencia({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...maisFiltros()
      });

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));

      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoTiposAudiencia(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...maisFiltros(),
        tela: ETelaEnum.TelaCadastroAudiencia,
        tipoAudienciaId: formik.values?.tipoAudienciaId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      console.log(error);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const maisFiltros = (): any => {
    let objeto: any = {};

    if (formikProcesso.values?.areaDireitoId) {
      objeto.modulo = EModulo.AreaDireito;
      objeto.areaDireitoId = formikProcesso.values.areaDireitoId;
    }


    return objeto;
  }

  const onChangeTipoAudiencia = (value: number, texto: string) => {

    setConfiguracoesTela([]);

    formik.setFieldValue("categoriaId", value);
    formik.setFieldValue("tipoAudienciaId", value);
    formik.setFieldValue("tipoAudienciaNome", texto);

  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <ToastContainer />
          <div className="modal-header">
            <LayoutPageForButton title={`Nova Audiência ${formik.values.tipoAudienciaId > 0 ? " - " + formik.values.tipoAudienciaNome : ""}`} />
          </div>
          <div className="modal-body">
            <div className="row mt-2">
              {carregandoTiposAudiencia ? (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando tipo de audiencias...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <div className="col-md-3 mt-3">
                  <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                    Tipo de Audiência:
                  </label>
                  <Select
                    value={{
                      label: options.find((e) => e.value === formik.values.tipoAudienciaId)?.label,
                      value: formik.values.tipoAudienciaId,
                    }}
                    onChange={(option: any) => onChangeTipoAudiencia(option.value, option.label)}
                    options={options}
                  />
                </div>
              )}
            </div>
            <hr></hr>
            {carregandoConfiguracoesTela ? <Carregando /> : <>{renderCampos()}</>}
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoAudienciaId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}
            >
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  Adicionar
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AudienciaCadastroModal;
