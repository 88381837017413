import { ETipoCampoEnum } from "../../../../../enum";
import { Modelo } from "../../../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";

export type ModeloFixo = Modelo & { disabled?: boolean, camposDependentes?: string[] };

export const modelo: ModeloFixo[] = [
  {
    nome: 'tipoAto',
    label: 'Tipo de Ato',
    obrigatorio: true,
    help: '',
    tipo: ETipoCampoEnum.Lista,
    multiplo: false,
    campoNome: 'tipoAto',
    campo: 999,
    controller: 'TipoAto',
    entidade: 'TipoAto',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'dataAto',
    label: 'Data do Ato',
    obrigatorio: true,
    help: '',
    tipo: ETipoCampoEnum.Data,
    campoNome: 'dataAto',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'dataArquivamento',
    label: 'Data do Arquivamento',
    obrigatorio: true,
    help: '',
    tipo: ETipoCampoEnum.Data,
    campoNome: 'dataArquivamento',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'numeroArquivamento',
    label: 'Numero Arquivamento',
    obrigatorio: true,
    help: '',
    tipo: ETipoCampoEnum.Texto,
    campoNome: 'numeroArquivamento',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'materiasDeliberadas',
    label: 'Matérias Deliberadss',
    obrigatorio: false,
    help: '',
    tipo: ETipoCampoEnum.Texto,
    campoNome: 'materiasDeliberadas',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'composicaoMesa',
    label: 'Composição Mesa',
    obrigatorio: false,
    help: '',
    tipo: ETipoCampoEnum.AreaTexto,
    campoNome: 'composicaoMesa',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'atoRegistradoJunta',
    label: 'Ato Registrado Junta',
    obrigatorio: true,
    help: '',
    tipo: ETipoCampoEnum.EnumLista,
    camposDependentes: ['numeroRegistro','dataRegistro'],
    campoNome: 'atoRegistradoJunta',
    campo: 999,
    controller: 'AtoRegistradoJunta',
    entidade: 'AtoRegistradoJunta',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'numeroRegistro',
    label: 'Numero Registro',
    obrigatorio: false,
    help: '',
    tipo: ETipoCampoEnum.Texto,
    campoNome: 'numeroRegistro',
    campo: 999,
    controller: '',
    entidade: '',
    disabled: true,
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'dataRegistro',
    label: 'Data Registro',
    obrigatorio: false,
    help: '',
    tipo: ETipoCampoEnum.Data,
    campoNome: 'dataRegistro',
    campo: 999,
    controller: '',
    entidade: '',
    disabled: true,
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'atoPublicado',
    label: 'Ato Publicado',
    obrigatorio: true,
    help: '',
    tipo: ETipoCampoEnum.EnumLista,
    camposDependentes: ['publicacao','observacoesAto'],
    campoNome: 'atoPublicado',
    campo: 999,
    controller: 'AtoPublicado',
    entidade: 'AtoPublicado',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'publicacao',
    label: 'Publicação',
    obrigatorio: false,
    help: '',
    tipo: ETipoCampoEnum.Texto,
    disabled: true,
    campoNome: 'publicacao',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'observacoesAto',
    label: 'Observações Ato',
    obrigatorio: false,
    help: '',
    tipo: ETipoCampoEnum.Texto,
    disabled: true,
    campoNome: 'observacoesAto',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'status',
    label: 'Status',
    obrigatorio: true,
    help: '',
    tipo: ETipoCampoEnum.EnumLista,
    campoNome: 'Status',
    campo: 999,
    controller: 'StatusAtoSocietario',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  },
  {
    nome: 'codigo',
    label: 'Código',
    obrigatorio: false,
    help: '',
    tipo: ETipoCampoEnum.Texto,
    campoNome: 'codigo',
    campo: 999,
    controller: '',
    entidade: '',
    isXCampo: false,
    ordenacao: 0,
    somenteLeitura: false
  }

]
