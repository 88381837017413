import IEscritorio from "../interfaces/IEscritorio";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaEscritorioRequest from "../interfaces/Requests/Escritorio/FiltroPesquisaEscritorioRequest";
import api from "./Api";

class EscritorioService {

    async obterEscritorios(filtros: FiltroPesquisaEscritorioRequest): Promise<IRecuperaLista<IEscritorio>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.clienteId > 0) {
            query += `&clienteId=${filtros.clienteId}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.isTelaPesquisaProcesso) {
            query += `&isTelaPesquisaProcesso=${filtros.isTelaPesquisaProcesso}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IEscritorio>>(`Escritorio/RecuperaEscritorio?${query}`);

        return result;
    }

}
export default new EscritorioService();