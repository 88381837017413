import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusImovel from "../interfaces/IStatusImovel";
import { AdicionaStatusImovelRequest } from "../interfaces/Requests/StatusImovel/AdicionaStatusImovelRequest";
import FiltroPesquisaStatusImovelRequest from "../interfaces/Requests/StatusImovel/FiltroPesquisaStatusImovelRequest";
import { EditarStatusImovelRequest } from "../interfaces/Requests/StatusImovel/EditarStatusImovelRequest";

class StatusImovelService {

    async obterStatusImoveis(filtros: FiltroPesquisaStatusImovelRequest): Promise<IRecuperaLista<IStatusImovel>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusImovel>>(`StatusImovel/RecuperaStatusImovel?${query}`);

        return result;
    }

    async adicionaStatusImovel(adicionaStatusImovel: AdicionaStatusImovelRequest): Promise<IStatusImovel> {
        let { data: result } = await api.post<IStatusImovel>(`StatusImovel/AdicionaStatusImovel`, adicionaStatusImovel);

        return result;
    }


    async alterarStatus(statusImovelId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusImovel>(`StatusImovel/AtualizaStatusImovelPorId?id=${statusImovelId}`, data);
    }

    async atualizarStatusImovel(editarStatusImovel: EditarStatusImovelRequest): Promise<void> {

        let dataStatusImovel = [
            { "op": "replace", "path": "/nome", "value": editarStatusImovel.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusImovel.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusImovel/AtualizaStatusImovelPorId?id=${editarStatusImovel.statusImovelId}`, dataStatusImovel)
        ]);
    }
}


export default new StatusImovelService();