import { Modal, Button } from "react-bootstrap";

interface IPublicacaoRelatorioModal {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
}

const PublicacaoIAModal = ({ toggleModal, exibirModal }: IPublicacaoRelatorioModal) => {
  return (
    <Modal size="xl" centered show={exibirModal} onHide={() => toggleModal(false)} >
      <Modal.Header closeButton>
        <Modal.Title className="text-orange">Detalhes do Processo:</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "800px", overflowY: "auto" }}>
        <div className="mb-4">
          <h2 className="text-orange">Número do Processo</h2>
          <div className="highlight">ATOrd-0000068-24.2022.5.05.0192</div>
        </div>
        <div className="mb-4">
          <h2 className="text-orange">Reclamantes</h2>
          <div className="mb-3">
            <div><strong className="text-orange">Nome:</strong> Igor Lima de Souza</div>
            <div><strong className="text-orange">Advogados:</strong></div>
            <div className="pl-3">
              <div><strong className="text-orange">Nome:</strong> Diogo Fernandes de Oliveira</div>
              <div><strong className="text-orange">OAB Número:</strong> 24733</div>
              <div><strong className="text-orange">OAB UF:</strong> BA</div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-orange">Reclamadas</h2>
          <div className="mb-3">
            <div><strong className="text-orange">Nome:</strong> Panasonic do Brasil Limitada</div>
            <div><strong className="text-orange">Advogados:</strong></div>
            <div className="pl-3">
              <div><strong className="text-orange">Nome:</strong> Ismenia Evelise Oliveira de Castro</div>
              <div><strong className="text-orange">OAB Número:</strong> 223753</div>
              <div><strong className="text-orange">OAB UF:</strong> SP</div>
            </div>
          </div>
          <div className="mb-3">
            <div><strong className="text-orange">Nome:</strong> Seven Trade Marketing e Consultoria Ltda</div>
            <div><strong className="text-orange">Advogados:</strong></div>
            <div className="pl-3">
              <div><strong className="text-orange">Nome:</strong> Carlos Andre Nogueira de Carvalho</div>
              <div><strong className="text-orange">OAB Número:</strong> 319556</div>
              <div><strong className="text-orange">OAB UF:</strong> SP</div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-orange">Detalhes do Tribunal</h2>
          <div><strong className="text-orange">Foro:</strong> Tribunal Regional do Trabalho da 5ª Região</div>
          <div><strong className="text-orange">Vara:</strong> Vara do Trabalho de Salvador</div>
          <div><strong className="text-orange">Estado:</strong> Bahia</div>
          <div><strong className="text-orange">Cidade:</strong> Salvador</div>
        </div>
        <div className="mb-4">
          <h2 className="text-orange">Resumo</h2>
          <div className="highlight">
            O reclamante, Igor Lima de Souza, apresentou um atestado médico de uma clínica odontológica para justificar sua ausência em audiência. O despacho proferido considerou o atestado insuficiente para justificar a ausência, pois não comprova a impossibilidade de locomoção. Foi aplicada a pena de confissão ao reclamante, e o processo foi excluído de pauta. As partes devem se manifestar sobre a possibilidade de conciliação no prazo de cinco dias, sob pena de remessa do feito ao 1º Núcleo de Justiça 4.0 para prolatação de sentença.
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-orange">Riscos Envolvidos</h2>
          <div className="highlight">
            Os reclamados têm um risco reduzido neste despacho específico, pois o atestado do reclamante foi considerado inválido e a pena de confissão foi aplicada ao reclamante. No entanto, há o risco de prolongamento do litígio se o reclamante contestar a decisão.
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-orange">Providências</h2>
          <div className="highlight">
            <ol>
              <li>Tomar ciência do despacho e dos novos prazos estabelecidos.</li>
              <li>Avaliar a possibilidade de conciliação e manifestar-se dentro do prazo de cinco dias.</li>
              <li>Preparar argumentos e documentação necessária para a próxima fase do processo, conforme a decisão do despacho.</li>
            </ol>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-orange">Peças</h2>
          <div className="pl-3">
            <div className="highlight mb-4">
              <h3 className="text-orange">Modelo: Manifestação sobre possibilidade de conciliação</h3>
              <p>Excelentíssimo Senhor Juiz do Trabalho,</p>
              <p>A parte reclamada, Panasonic do Brasil Limitada e Seven Trade Marketing e Consultoria Ltda, vem respeitosamente informar que não vislumbra, no momento, a possibilidade de conciliação.</p>
              <p>Termos em que,</p>
              <p>Pede deferimento.</p>
            </div>
            <div className="highlight">
              <h3 className="text-orange">Modelo: Resposta ao despacho contestando a validade do atestado</h3>
              <p>Excelentíssimo Senhor Juiz do Trabalho,</p>
              <p>A parte reclamada, Panasonic do Brasil Limitada e Seven Trade Marketing e Consultoria Ltda, vem respeitosamente, em atenção ao despacho ID fc4d34d, manifestar-se sobre o atestado médico apresentado pelo reclamante. Conforme destacado no despacho, o atestado não comprova a impossibilidade de locomoção do reclamante na data da audiência, sendo insuficiente para justificar sua ausência.</p>
              <p>Assim, requer a manutenção da pena de confissão aplicada ao reclamante e o prosseguimento do feito para julgamento de mérito.</p>
              <p>Termos em que,</p>
              <p>Pede deferimento.</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => toggleModal(false)}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublicacaoIAModal;
