import { Control } from "../styles";
import { OverlayTrigger } from "react-bootstrap";
import { Block } from "./types";
import { formattedControlName } from ".";
import { Tooltip } from "../../Tooltip";

export const getPopoverComponent = (
  block: Block,
  action: () => void,
  isActive = false
): JSX.Element => {
  if (block.popover) {
    return (
      <OverlayTrigger
        key={block.type}
        placement="bottom"
        trigger="click"
        rootClose
        overlay={block.popover()}
      >
        <Control onClick={action} isActive={isActive}>
          {block.component}
        </Control>
      </OverlayTrigger>
    );
  }

  return <div />;
};

export const getConventionalComponent = (
  block: Block,
  action: () => void,
  isActive = false
): JSX.Element => (
  <Tooltip key={block.type} placement="bottom" content={formattedControlName(block.type)}>
    <Control onClick={action} isActive={isActive}>
      {block.component}
    </Control>
  </Tooltip>
);
