import Swal from "sweetalert2";

export function useAlert() {
  async function confirm(title: string, description: string) {
    return Swal.fire({
      title,
      text: description,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "var(--bs-orange)",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "var(--bs-gray)",
      focusConfirm: false,
      focusCancel: false,
    })
  }

  return {
    confirm
  }
}