/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { MultiSelect } from "react-multi-select-component";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";

import IEmpresa from "../../interfaces/IEmpresa";
import IRecuperaLista from "../../interfaces/IRecuperaLista";

import EmpresaService from "../../services/EmpresaService";

import { useAuth } from "../../contexts/AuthProvider";
import TipoServicoService from "../../services/TipoServicoService";
import ITipoServico from "../../interfaces/ITipoServico";
import OrgaoResponsavelService from "../../services/OrgaoResponsavelService";
import IOrgaoResponsavel from "../../interfaces/IOrgaoResponsavel";


interface ILicencaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtro: any;
  setFiltro: (filtro: any) => void;
}

const LicencaFiltroModal = ({ toggleModal, exibirModal, filtro, setFiltro, setPesquisar }: ILicencaModalProps) => {

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);


  const [carregamentoOrgaoResposaveis, setCarregamentoOrgaoResposaveis] = useState<boolean>(false);
  const [orgaoResposaveis, setOrgaoResposaveis] = useState<any>([]);
  const [OrgaoResposaveisSelected, setOrgaoResposaveisSelected] = useState([]);

  const [exibirFiltrosData, setExibirFiltrosData] = useState<boolean>(false);
  const [exibirFiltrosLista, setExibirFiltrosLista] = useState<boolean>(false);

  const { user } = useAuth();

  useEffect(() => {
    if (exibirFiltrosLista) {

      carregarEmpresa();
      
      carregarOrgaoResposaveis();
    }
  }, [exibirFiltrosLista]);

  const carregarEmpresa = async () => {
    try {
      if (empresa.length > 0) return;
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "empresaId",
      });
      setEmpresa(returnOptionsEmpresa(resultado.data));
      setCarregamentoEmpresa(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
  }

  const carregarOrgaoResposaveis = async () => {
    try {
      if (orgaoResposaveis.length > 0) return;
      setCarregamentoOrgaoResposaveis(true);
      let resultado: IRecuperaLista<IOrgaoResponsavel>;
      resultado = await OrgaoResponsavelService.obterOrgaoResponsavels({
        limit: 100,
        codigo: "",
        offset: 0,
        sort: "orgaoResponsavelId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setOrgaoResposaveis(returnOptions(resultado.data, "orgaoResponsavelId", "orgaoResposaveis"));
      setCarregamentoOrgaoResposaveis(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoOrgaoResposaveis(false);
      setOrgaoResposaveis([]);
    }
  }

  const returnOptionsEmpresa = (empresas: any) => {
    let empresasParse: any = [];
    empresas.forEach((item: any) => {
      empresasParse.push({
        value: item.empresaId,
        label: item.nomeFantasia,
      });
    });
    return empresasParse;
  };

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });

    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {

      case "empresa":
        setFiltro({ ...filtro, empresaId: IdsSelected });
        break;

      case "tipoServicoId":
        setFiltro({ ...filtro, tipoServicoId: IdsSelected });
        break;

      case "orgaoResponsavelId":
        setFiltro({ ...filtro, orgaoResponsavelId: IdsSelected });
        break;

    }
  };

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-body">

            <div className="row mt-3">
              <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosData(!exibirFiltrosData)} className="modal-header">
                <h5 className="modal-title text-orange"> {exibirFiltrosData ? "-" : "+"} Filtros por datas</h5>
              </div>
            </div>
            {exibirFiltrosData && (
              <>
                <div className="row mt-3">
                  <div className="col-4">
                    <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                      Data de Cadastro:
                    </label>
                    <DateTimeRangePicker
                      className="form-control p-0"
                      calendarIcon={null}
                      showLeadingZeros={true}
                      value={
                        filtro.dataCadastroMaiorIgual && filtro.dataCadastroMenorIgual
                          ? [filtro.dataCadastroMaiorIgual, filtro.dataCadastroMenorIgual]
                          : ""
                      }
                      onChange={(datas: Date[]) => {
                        if (!datas) {
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataCadastroMaiorIgual: "",
                              dataCadastroMenorIgual: "",
                            };
                          });
                          return;
                        }

                        let data_inicial = datas[0];
                        let data_final = datas[1];
                        if (data_final) {
                          data_final.setHours(0, 0, 0, 0);
                          data_final = new Date(data_final.getTime() - 1);
                        }
                        setFiltro((oldValue: any) => {
                          return {
                            ...oldValue,
                            dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="form-dataInicioVigencia" className="form-label fw-bolder text-orange">
                      Data de Emissão:
                    </label>
                    <DateTimeRangePicker
                      className="form-control p-0"
                      calendarIcon={null}
                      showLeadingZeros={true}
                      value={
                        filtro.dataEmissaoMaiorIgual && filtro.dataEmissaoMenorIgual
                          ? [filtro.dataEmissaoMaiorIgual, filtro.dataEmissaoMenorIgual]
                          : ""
                      }
                      onChange={(datas: Date[]) => {
                        if (!datas) {
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataEmissaoMaiorIgual: "",
                              dataEmissaoMenorIgual: "",
                            };
                          });
                          return;
                        }

                        let data_inicial = datas[0];
                        let data_final = datas[1];
                        if (data_final) {
                          data_final.setHours(0, 0, 0, 0);
                          data_final = new Date(data_final.getTime() - 1);
                        }
                        setFiltro((oldValue: any) => {
                          return {
                            ...oldValue,
                            dataEmissaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataEmissaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="form-Vencimento" className="form-label fw-bolder text-orange">
                      Data do Vencimento:
                    </label>
                    <DateTimeRangePicker
                      className="form-control p-0"
                      calendarIcon={null}
                      showLeadingZeros={true}
                      value={
                        filtro.dataVencimentoMaiorIgual && filtro.dataVencimentoMenorIgual
                          ? [filtro.dataVencimentoMaiorIgual, filtro.dataVencimentoMenorIgual]
                          : ""
                      }
                      onChange={(datas: Date[]) => {
                        if (!datas) {
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataVencimentoMaiorIgual: "",
                              dataVencimentoMenorIgual: "",
                            };
                          });
                          return;
                        }

                        let data_inicial = datas[0];
                        let data_final = datas[1];
                        if (data_final) {
                          data_final.setHours(0, 0, 0, 0);
                          data_final = new Date(data_final.getTime() - 1);
                        }
                        setFiltro((oldValue: any) => {
                          return {
                            ...oldValue,
                            dataVencimentoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataVencimentoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row mt-3">
              <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosLista(!exibirFiltrosLista)} className="modal-header">
                <h5 className="modal-title text-orange"> {exibirFiltrosLista ? "-" : "+"} Filtros por listas</h5>
              </div>
            </div>
            {exibirFiltrosLista && (
              <>
                <div className="row mt-3">
                  <>{(user?.clienteTipo === 'Empresa') &&
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Empresa:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoEmpresa}
                        disabled={carregamentoEmpresa}
                        options={empresa}
                        value={empresaSelected}
                        onChange={(event: any) => {
                          setEmpresaSelected(event);
                          setIdsSelect("empresa", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  }</>
                </div>

                <div className="row mt-3">
                  <div className="col-md-4 mt-3">
                    <label htmlFor="form" className="form-label fw-bolder text-orange">
                      Orgãos Responsáveis:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoOrgaoResposaveis}
                      disabled={carregamentoOrgaoResposaveis}
                      options={OrgaoResposaveisSelected}
                      value={orgaoResposaveis}
                      onChange={(event: any) => {
                        setOrgaoResposaveisSelected(event);
                        setIdsSelect("orgaoResponsavelId", event);
                      }}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>
                </div>

              </>
            )}
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              type="button"
              onClick={() => {
                setPesquisar(true);
                toggleModal();
              }}
              className="btn btn-orange me-5"
            >
              Pesquisar
            </button>
            <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LicencaFiltroModal;
