import DataTable, {
  ConditionalStyles,
  PaginationOptions,
  SortOrder,
  TableColumn,
} from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import Carregando from "../../../../../components/Carregando";
import { notifyWarning } from "../../../../../components/Comum/Toast/ToastFormik";

interface GridPadraoType {
  colunas: any;
  itens: any[];
  tipo: string;
  conditionalRowStyles?: ConditionalStyles<any>[];
  selectableRows?: boolean;
  paginationServer?: boolean;
  paginationTotalRows?: number;
  onChangeRowsPerPage?: (
    currentRowsPerPage: number,
    currentPage: number
  ) => void;
  onChangePage?: (page: number, totalRows: number) => void;
  onRowClicked?: (row: any, event: any) => void;
  take?: number;
  limit?: number /*Incluímos esta variável para alterar os serviços de busca*/;
  progressPending?: boolean;
  reset?: boolean;
  pagination: boolean;
  defaultPage?: number;
  onSort?: (selectedColumn: TableColumn<any>, sortDirection: SortOrder) => void;
}

export default function GridUsuarios({
  progressPending,
  take = 10,
  colunas,
  itens,
  tipo,
  conditionalRowStyles,
  selectableRows,
  paginationServer,
  paginationTotalRows,
  onChangePage,
  onChangeRowsPerPage,
  onRowClicked,
  onSort,
  reset,
  pagination,
  defaultPage,
}: Readonly<GridPadraoType>) {
  const paginationComponentOptions: PaginationOptions = {
    rowsPerPageText: `Registros por página`,
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const [pesquisou, setPesquisou] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      if (itens?.length == 0 && progressPending && pesquisou) {
        notifyWarning(`Nenhum registro encontrado de ${tipo.toLowerCase()}.`);
      }
    }, 1000);
  }, [progressPending]);

  useEffect(() => {
    if (itens?.length == 0 && progressPending) setPesquisou(true);
  }, [progressPending]);

  return (
    <>
      {itens?.length > 0 ? (
        <DataTable
          onSort={onSort}
          progressComponent={<Carregando />}
          progressPending={progressPending}
          paginationPerPage={take}
          onRowClicked={onRowClicked}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          pagination={pagination}
          paginationServer={paginationServer}
          paginationTotalRows={paginationTotalRows}
          selectableRows={selectableRows}
          conditionalRowStyles={conditionalRowStyles}
          highlightOnHover={true}
          columns={colunas}
          data={itens}
          paginationComponentOptions={paginationComponentOptions}
          paginationDefaultPage={defaultPage ?? 1}
          paginationResetDefaultPage={reset}
        />
      ) : (
        <ToastContainer />
      )}
    </>
  );
}
