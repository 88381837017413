import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IQualificacao from "../interfaces/IQualificacao";
import { AdicionaQualificacaoRequest } from "../interfaces/Requests/Qualificacao/AdicionaQualificacaoRequest";
import FiltroPesquisaQualificacaoRequest from "../interfaces/Requests/Qualificacao/FiltroPesquisaQualificacaoRequest";
import { EditarQualificacaoRequest } from "../interfaces/Requests/Qualificacao/EditarQualificacaoRequest";

class QualificacaoService {

    async obterQualificacaos(filtros: FiltroPesquisaQualificacaoRequest): Promise<IRecuperaLista<IQualificacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IQualificacao>>(`Qualificacao/RecuperaQualificacao?${query}`);

        return result;
    }

    async adicionaQualificacao(adicionaQualificacao: AdicionaQualificacaoRequest): Promise<IQualificacao> {
        let { data: result } = await api.post<IQualificacao>(`Qualificacao/AdicionaQualificacao`, adicionaQualificacao);

        return result;
    }


    async alterarStatus(qualificacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IQualificacao>(`Qualificacao/AtualizaQualificacaoPorId?id=${qualificacaoId}`, data);
    }

    async atualizarQualificacao(editarQualificacao: EditarQualificacaoRequest): Promise<void> {

        let dataQualificacao = [
            { "op": "replace", "path": "/nome", "value": editarQualificacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarQualificacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Qualificacao/AtualizaQualificacaoPorId?id=${editarQualificacao.qualificacaoId}`, dataQualificacao)
        ]);
    }
}


export default new QualificacaoService();