import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusMarca from "../interfaces/IStatusMarca";
import { AdicionaStatusMarcaRequest } from "../interfaces/Requests/StatusMarca/AdicionaStatusMarcaRequest";
import FiltroPesquisaStatusMarcaRequest from "../interfaces/Requests/StatusMarca/FiltroPesquisaStatusMarcaRequest";
import { EditarStatusMarcaRequest } from "../interfaces/Requests/StatusMarca/EditarStatusMarcaRequest";

class StatusMarcaService {

    async obterStatusMarcas(filtros: FiltroPesquisaStatusMarcaRequest): Promise<IRecuperaLista<IStatusMarca>> {
        let query = `${'status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusMarca>>(`StatusMarca/RecuperaStatusMarca?${query}`);

        return result;
    }

    async adicionaStatusMarca(adicionaStatusMarca: AdicionaStatusMarcaRequest): Promise<IStatusMarca> {
        let { data: result } = await api.post<IStatusMarca>(`StatusMarca/AdicionaStatusMarca`, adicionaStatusMarca);

        return result;
    }


    async alterarStatus(statusMarcaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusMarca>(`StatusMarca/AtualizaStatusMarcaPorId?id=${statusMarcaId}`, data);
    }

    async atualizarStatusMarca(editarStatusMarca: EditarStatusMarcaRequest): Promise<void> {

        let dataStatusMarca = [
            { "op": "replace", "path": "/nome", "value": editarStatusMarca.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusMarca.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusMarca/AtualizaStatusMarcaPorId?id=${editarStatusMarca.statusMarcaId}`, dataStatusMarca)
        ]);
    }
}


export default new StatusMarcaService();