import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IXPayFechamento from "../interfaces/IXPayFechamento";
import { AdicionaXPayFechamentoRequest } from "../interfaces/Requests/XPayFechamento/AdicionaXPayFechamentoRequest";
import FiltroPesquisaXPayFechamentoRequest from "../interfaces/Requests/XPayFechamento/FiltroPesquisaXPayFechamentoRequest";

class XPayFechamentoService {

    async obterXPayXPayFechamentos(filtros: FiltroPesquisaXPayFechamentoRequest): Promise<IRecuperaLista<IXPayFechamento>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if(filtros.status && filtros.status !==0){
            query += `&status=${filtros.status}`;
        }

        if (filtros.xPayfechamentoId && filtros.xPayfechamentoId !== 0 ) {
            query += `&xPayfechamentoId=${filtros.xPayfechamentoId}`;
        }

        if(filtros.tipoFechamento && filtros.tipoFechamento !==0){
            query += `&tipoFechamento=${filtros.tipoFechamento}`;
        }

        if (filtros.modulo.length > 0) {
            for (let index = 0; index < filtros.modulo.length; index++) {
                const modulo = filtros.modulo[index];

                query += `&modulo=${modulo}`;
            }
        }

        if (filtros.escritorioId.length > 0) {
            for (let index = 0; index < filtros.escritorioId.length; index++) {
                const escritorioId = filtros.escritorioId[index];

                query += `&escritorioId=${escritorioId}`;
            }
        }

        if (filtros.dataCorteMaiorIgual && filtros.dataCorteMaiorIgual && filtros.dataCorteMaiorIgual.length) {
            query += `&dataCorteMaiorIgual=${filtros.dataCorteMaiorIgual}`;
        }

        if (filtros.dataCorteMenorIgual && filtros.dataCorteMenorIgual && filtros.dataCorteMenorIgual.length) {
            query += `&dataCorteMenorIgual=${filtros.dataCorteMenorIgual}`;
        }

        if (filtros.dataVencimentoMaiorIgual && filtros.dataVencimentoMaiorIgual && filtros.dataVencimentoMaiorIgual.length) {
            query += `&dataVencimentoMaiorIgual=${filtros.dataVencimentoMaiorIgual}`;
        }

        if (filtros.dataVencimentoMenorIgual && filtros.dataVencimentoMenorIgual && filtros.dataVencimentoMenorIgual.length) {
            query += `&dataVencimentoMenorIgual=${filtros.dataVencimentoMenorIgual}`;
        }

        if (filtros.dataPagamentoMaiorIgual && filtros.dataPagamentoMaiorIgual && filtros.dataPagamentoMaiorIgual.length) {
            query += `&dataPagamentoMaiorIgual=${filtros.dataPagamentoMaiorIgual}`;
        }

        if (filtros.dataPagamentoMenorIgual && filtros.dataPagamentoMenorIgual && filtros.dataPagamentoMenorIgual.length) {
            query += `&dataPagamentoMenorIgual=${filtros.dataPagamentoMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMaiorIgual && filtros.dataCadastroMaiorIgual.length) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual && filtros.dataCadastroMenorIgual && filtros.dataCadastroMenorIgual.length) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IXPayFechamento>>(`XPayFechamento/RecuperaXpayFechamento?${query}`);

        return result;
    }

    async adicionaXPayFechamento(adicionaXPayFechamento: AdicionaXPayFechamentoRequest): Promise<IXPayFechamento> {
        let { data: result } = await api.post<IXPayFechamento>(`XPayFechamento/AdicionaXpayFechamento`, adicionaXPayFechamento);

        return result;
    }
}


export default new XPayFechamentoService();