import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import {
  faCheckCircle,
  faEraser,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import FiltroPesquisaAdvogadoInternoReq from "../../../../interfaces/Requests/AdvogadoInterno/FiltroPesquisaAdvogadoInternoReq";
import GridUsuarios from "../GrupoUsuarios/components/GridUsuarios";
import { TableColumn } from "react-data-table-component";
import IAdvogadoInternoAcesso from "../../../../interfaces/IAdvogadoInternoAcesso";
import Swal from "sweetalert2";
import AdvogadoInternoAcessosService from "../../../../services/AdvogadoInternoAcessosService";
import Carregando from "../../../../components/Carregando";
import AdvogadoInternoAddModal from "./modals/AdvogadoInternoAddModal";
import AdvogadoInternoEditModal from "./modals/AdvogadoInternoEditModal";

export default function AdvogadoInternoPage() {
  const [reset, setReset] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [exibirAddModal, setExibirAddModal] = useState<boolean>(false);
  const [exibirEditModal, setExibirEditModal] = useState<boolean>(false);
  const [advogados, setAdvogados] = useState<IAdvogadoInternoAcesso[]>([]);
  const [advogado, setAdvogado] = useState<IAdvogadoInternoAcesso>({
    advogadoInternoId: 0,
    clienteId: 0,
    usuarioId: 0,
    clienteNome: "",
    codigo: "",
    nome: "",
    email: "",
    status: "",
  });
  const [filtros, setFiltros] = useState<FiltroPesquisaAdvogadoInternoReq>({
    email: "",
    limit: 10,
    nome: "",
    offset: 0,
    sort: "advogadoInternoId",
    status: "0",
    totalItems: 0,
  });

  const renderCell = (advogado: IAdvogadoInternoAcesso) => {
    if (advogado.status === "Inativo") {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Advogado"
              onClick={() => {
                setAdvogado(advogado);
                setExibirEditModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Ativar Advogado"
              onClick={() => {
                handleStatusChange(advogado.advogadoInternoId.toString(), "1");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faCheckCircle}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Advogado"
              onClick={() => {
                setAdvogado(advogado);
                setExibirEditModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Desativar Advogado"
              onClick={() => {
                handleStatusChange(advogado.advogadoInternoId.toString(), "-1");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faTrashAlt}
            />
          </div>
        </>
      );
    }
  };

  const colunas: TableColumn<IAdvogadoInternoAcesso>[] = [
    {
      name: "Id",
      sortField: "advogadoInternoId",
      selector: (row: IAdvogadoInternoAcesso) => row.advogadoInternoId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IAdvogadoInternoAcesso) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      sortField: "email",
      selector: (row: IAdvogadoInternoAcesso) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IAdvogadoInternoAcesso) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const carregarAdvogados = async (
    filtro: FiltroPesquisaAdvogadoInternoReq
  ) => {
    try {
      const response =
        await AdvogadoInternoAcessosService.obterAdvogadoInterno(filtro);

      setAdvogados(response.data);
      setFiltros((prev) => ({ ...prev, totalItems: response.totalRegistros }));
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos advogados",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((prev) => {
      return { ...prev, limit: currentRowsPerPage };
    });

    await carregarAdvogados({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((prev) => {
      return { ...prev, offset: (page - 1) * filtros.limit };
    });

    await carregarAdvogados({
      ...filtros,
      offset: (page - 1) * filtros.limit,
    });
  };

  const handleSort = async (
    column: TableColumn<IAdvogadoInternoAcesso>,
    sortDirection: string
  ) => {
    setFiltros((prev) => {
      return {
        ...prev,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await carregarAdvogados({
      ...filtros,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    });
  };

  const handleStatusChange = async (id: string, status: string) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status do advogado alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await AdvogadoInternoAcessosService.atualizarAdvogadoInterno(id, {
          status: parseInt(status),
        });

        await carregarAdvogados(filtros);
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível editar o Fornecedor`,
        text: "Ocorreu um erro inesperado.",
        showConfirmButton: true,
      });
    }
  };

  const limparFiltros = async () => {
    setFiltros({
      email: "",
      limit: 10,
      nome: "",
      offset: 0,
      sort: "advogadoInternoId",
      status: "0",
      totalItems: 0,
    });

    await carregarAdvogados({
      email: "",
      limit: 10,
      nome: "",
      offset: 0,
      sort: "advogadoInternoId",
      status: "0",
      totalItems: 0,
    });
  };

  useEffect(() => {
    carregarAdvogados(filtros);
  }, [filtros.status]);

  if (loading) {
    return <Carregando />;
  }

  return (
    <>
      <LayoutPageTitle title="Advogados Internos">
        <button
          onClick={() => {
            setExibirAddModal(true);
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <AdvogadoInternoAddModal
        exibirModal={exibirAddModal}
        handleClose={async () => {
          setExibirAddModal(false);
          await carregarAdvogados(filtros);
        }}
      />

      <AdvogadoInternoEditModal
        exibirModal={exibirEditModal}
        handleClose={async () => {
          setExibirEditModal(false);
          await carregarAdvogados(filtros);
        }}
        advogado={advogado}
      />

      <div className="col-md-12">
        <form
          onSubmit={async (e) => {
            setReset(!reset);
            e.preventDefault();

            await carregarAdvogados(filtros);
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Nome:
            </label>
            <input
              placeholder="Nome"
              type="text"
              className="form-control"
              id="form-nome"
              value={filtros.nome}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((prev) => {
                  return {
                    ...prev,
                    nome: e.target.value,
                  };
                });
              }}
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-email"
              className="form-label fw-bolder text-orange"
            >
              Email:
            </label>
            <input
              placeholder="Email"
              type="text"
              className="form-control"
              id="form-email"
              value={filtros.email}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((prev) => {
                  return {
                    ...prev,
                    email: e.target.value,
                  };
                });
              }}
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Status:
            </label>

            <select
              placeholder="Status"
              className="form-control"
              id="form-select"
              value={filtros.status}
              onChange={async (e) => {
                e.preventDefault();
                setFiltros((prev) => {
                  return { ...prev, status: e.target.value };
                });
                setReset(!reset);

                await carregarAdvogados({
                  ...filtros,
                  status: e.target.value,
                });
              }}
            >
              <option value="0" label="Todos">
                Todos
              </option>

              <option value="1" label="Ativo">
                Ativo
              </option>

              <option value="-1" label="Inativo">
                Inativo
              </option>
            </select>
          </div>

          <div className="col-md-2">
            <div>
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>
              <button
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridUsuarios
            onSort={handleSort}
            progressPending={loading}
            limit={filtros.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtros.totalItems}
            colunas={colunas}
            tipo="Advogados"
            itens={advogados}
            reset={reset}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
}
