import { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useAuth } from "../../../contexts/AuthProvider";
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import IUsuarioAD from "../../../interfaces/IUsuarioAD";
import Storage from "../../../utils/StorageUtils";
import Cookie from "../../../utils/CookieUtils";
import { Nullable } from "../../../components/types/Nullable";
import Carregando from "../../../components/Carregando";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { lightTheme } from "../../../../theme";

export default function LoginForm() {
    const { authenticate, signIn, signInSemToken } = useAuth();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const mountedRef = useRef(true);
    const [userCookie, setUserCookie] = useState<Nullable<IUsuarioAD>>();
    const [mostrarForm, setMostrarForm] = useState(false);


    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email incorreto')
            .min(3, 'Minimo 3 caracteres')
            .max(50, 'Máximo 50 caracteres')
            .required('Email é obrigatório'),
        password: Yup.string()
            .min(3, 'Minimo 3 caracteres')
            .max(50, 'Máximo 50 caracteres')
            .required('Senha é obrigatório'),
    })

    const initialValues = {
        email: '',
        password: ''
    }

    const { setSelectedTheme, selectedTheme, setThemeMode, themeMode } =
    useContext<OrangeInterfaceContext>(OrangeContext);

    useEffect(() => {
        setSelectedTheme(lightTheme)
    }, [])
    

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                setMostrarForm(false);
                let result = await authenticate(values.email, values.password);
                if (result.token !== "null" && result.email === values.email && result.clienteId !== 0)
                    signInSemToken(result);

                else
                    setStep(2);

            } catch (error) {
                setStatus("Usuário ou Senha inválidos")
            } finally {
                setLoading(false);
                setSubmitting(false);
                setMostrarForm(true);

            }
        },
    })

    const codigoSchema = Yup.object().shape({
        codigo: Yup.string()
            .min(3, 'Minimo 3 caracteres')
            .max(100, 'Máximo 100 caracteres')
            .required('Código é obrigatório')
    })

    const codigoInitialValues = {
        codigo: ''
    };

    const codigoFormik = useFormik({
        initialValues: codigoInitialValues,
        validationSchema: codigoSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let ip = '';

            try {
                setMostrarForm(false);
                setTimeout(() => setMostrarForm(false), 1000);
                const resultado = await (await fetch('https://api.ipify.org?format=json')).json();
                ip = await resultado.ip;
            } catch (error) {
                const resultado = await (await fetch('https://v4.ident.me')).text();
                ip = await resultado;
            }

            setLoading(true)
            try {
                await signIn(formik.values.email, values.codigo, ip);
            } catch (error) {
                setStatus("Não foi possivel realizar login");
            } finally {
                setLoading(false)
                setSubmitting(false);
                setTimeout(() => setMostrarForm(true), 1000);
            }
        },
    });

    const setUserCookieSession = async () => {
        if (await Cookie.getCookie("user")) {
            setUserCookie(JSON.parse(decodeURIComponent(await Cookie.getCookie("user"))));
        }
    }

    const addClassCardLogin = () => {
        let element: any = document.getElementById("card-login");
        element.classList.add("card-login");
    }

    const removeClassCardLogin = () => {
        let element: any = document.getElementById("card-login");
        element.classList.remove("card-login");
    }

    useEffect(() => {
        if (!mostrarForm) {
            removeClassCardLogin()
        } else {
            addClassCardLogin()
        }
    }, [mostrarForm]);


    const initCookieUser = async () => {
        if (!userCookie?.Id) {
            setMostrarForm(true);
        } else {
            let user = {
                urlLogo: userCookie.UrlLogo,
                corTemplate: userCookie.CorTemplate,
                modoExibicao: userCookie.ModoExibicao,
                email: userCookie.Email,
                id: userCookie.Id,
                nomeUsuario: userCookie.NomeUsuario,
                clienteTipo: userCookie.ClienteTipo,
                role: userCookie.Role,
                token: userCookie.Token,
                primeiroAcesso: userCookie.PrimeiroAcesso,
                clienteId: userCookie.ClienteId,
                visualizarMenus: userCookie.VisualizarMenus,
                activeDitectory: true,
                alterarCompromissosDeTerceiros: userCookie.AlterarCompromissosDeTerceiros ? true : false,
                urlChancela: userCookie.UrlChancela,
                urlFoto: userCookie.UrlFoto,
            };

            await Storage.setUser(user);
            await Storage.setToken(user.token);
            Storage.updateAccessExpireIn();

            Cookie.clearCookie();

            window.location.href = '/home';
        }
    }


    useEffect(() => {
        codigoFormik.validateForm();
        return () => { mountedRef.current = false }
    }, []);


    useEffect(() => {
        setUserCookieSession();
        setTimeout(() => initCookieUser(), 2000);
    }, [userCookie?.Id]);

    return (
        !mostrarForm ? <Carregando /> :
            <>
                {
                    step === 1 && (
                        <form
                            className='form w-100'
                            onSubmit={formik.handleSubmit}
                            noValidate
                            id='kt_login_signin_form'
                        >
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}

                            {/* begin::Form group */}
                            <div className='fv-row mb-3'>
                                <label className='form-label fs-6 fw-bolder text-orange'>Email</label>
                                <input
                                    placeholder='Email'
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        { 'is-invalid': formik.touched.email && formik.errors.email },
                                        {
                                            'is-valid': formik.touched.email && !formik.errors.email,
                                        }
                                    )}
                                    type='email'
                                    name='email'
                                    autoComplete='off'
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert'>{formik.errors.email}</span>
                                    </div>
                                )}
                            </div>
                            {/* end::Form group */}

                            {/* begin::Form group */}
                            <div className='fv-row mb-10'>
                                <div className='d-flex justify-content-between mt-n5'>
                                    <div className='d-flex flex-stack mb-2'>
                                        <label className='form-label fw-bolder text-orange fs-6 mb-0'>Senha</label>
                                    </div>
                                </div>
                                <input
                                    placeholder='Senha'
                                    type='password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        {
                                            'is-invalid': formik.touched.password && formik.errors.password,
                                        },
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        }
                                    )}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Link to='/esqueci-minha-senha' className='link-esqueci-minha-senha fs-7 fw-bolder mt-1'>
                                Esqueci minha senha
                            </Link>
                            {/* end::Form group */}

                            {/* begin::Action */}
                            <div className='text-center'>
                                <button
                                    type='submit'
                                    className='btn btn-lg btn-orange w-100 mt-5'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && <span className='indicator-label'>CONTINUAR</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            Aguarde...
                                        </span>
                                    )}
                                </button>
                            </div>
                            {/* end::Action */}
                        </form>
                    )

                }
                {
                    step === 2 && (
                        <form
                            className='form w-100'
                            onSubmit={codigoFormik.handleSubmit}
                            noValidate
                            id='kt_login_signin_form'
                        >

                            {codigoFormik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{codigoFormik.status}</div>
                                </div>
                            )}

                            <div className='fv-row mb-10'>
                                <div className='d-flex justify-content-between mt-n5'>
                                    <div className='d-flex flex-stack mb-2'>
                                        <label className='form-label fw-bolder text-orange fs-6 mb-0'>Código de Autorização</label>
                                    </div>
                                </div>
                                <input
                                    placeholder='Código de Autorização'
                                    type='text'
                                    autoComplete='off'
                                    {...codigoFormik.getFieldProps('codigo')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        {
                                            'is-invalid': codigoFormik.touched.codigo && codigoFormik.errors.codigo,
                                        },
                                        {
                                            'is-valid': codigoFormik.touched.codigo && !codigoFormik.errors.codigo,
                                        }
                                    )}
                                />
                                {codigoFormik.touched.codigo && codigoFormik.errors.codigo && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{codigoFormik.errors.codigo}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='text-center'>
                                <button
                                    type='submit'
                                    className='btn btn-lg btn-orange w-100 mt-5'
                                    disabled={codigoFormik.isSubmitting || !codigoFormik.isValid}
                                >
                                    {!loading && <span className='indicator-label'>ENTRAR</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            Aguarde...
                                        </span>
                                    )}
                                </button>

                            </div>
                        </form>
                    )
                }

            </>
    )
}