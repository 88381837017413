import { useContext, useEffect, useState } from "react";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";

import { defaultSchema } from "../../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaComunicacaoRequest } from "../../../interfaces/Requests/Comunicacao/AdicionaComunicacaoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../../enum";
import LayoutPageButton from "../../../layout/LayoutPageButton";

import ComunicacaoService from "../../../services/ComunicacaoService";
import sessionStorageService from "../../../services/sessionStorage/sessionStorageService";

import Carregando from "../../Carregando";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import CamposCapaSolucoes from "../Solucao/CamposCapaSolucoes";
import FileListCapaField from "../../FileUpload/FileListCapaField";

interface IComunicacaoFieldEditProps {
  toggleModal: () => void;
  exibirModal: boolean;
}

const ComunicacaoFieldEdit = ({
  toggleModal,
  exibirModal,
}: IComunicacaoFieldEditProps) => {
  const {  comunicacao, tipoComunicacao, setComunicacao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);


  const initialValues: AdicionaComunicacaoRequest = {
    comunicacaoId: comunicacao?.comunicacaoId,
    principalId: comunicacao?.comunicacaoId,
    tipoComunicacaoId: comunicacao?.tipoComunicacaoId,
    categoriaId: comunicacao?.tipoComunicacaoId,

    modulo: EModulo.Comunicacao,
    tela: ETelaEnum.TelaCapaComunicacao,

    processoId: comunicacao?.processoId,
    areaDireitoId: comunicacao?.areaDireitoId,

    contratoId: comunicacao?.contratoId,
    tipoContratoId: comunicacao?.tipoContratoId,

    datacomunicacao: null,
    descricao: "",
    codigo: "",
    status: "",

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    
    if (exibirModal) {
      if (!comunicacao?.comunicacaoId) return;

      carregaCapa();

      formik.setValues(initialValues);
    }

  }, [comunicacao?.comunicacaoId, refreshCapa, exibirModal]);

  useEffect(() => {
    if (!comunicacao?.comunicacaoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [comunicacao]);


  const inserirSessionStorage = () => sessionStorageService.inserir(comunicacao, "comunicacao_capa");

  const carregarSessionStorage = async () => setComunicacao(JSON.parse((await sessionStorageService.obter("comunicacao_capa")) || null));

  const carregaCapa = async () => {
    try {

      setCarregandoConfiguracoesTela(true);
      let resultado = await ComunicacaoService.obterCapaComunicacao(comunicacao?.comunicacaoId);
      setConfiguracoesTela(resultado.data);

      setCarregandoConfiguracoesTela(false);

    } catch (error: any) {
      setCarregandoConfiguracoesTela(false);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const renderCampos = () => {
    let component: any[] = [];
    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaSolucoes
          controller={"comunicacao"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });

    return <div key={comunicacao?.comunicacaoId} className="row mt-12">{component}</div>;
  };

  return (
    <>
      <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <ToastContainer />
        <div className="modal-content">

          <div className="modal-header">
            <div style={{ marginLeft: "15px" }}>
              <LayoutPageButton
                title={`Editar #${comunicacao?.comunicacaoId}  ${tipoComunicacao?.tipoComunicacaoId > 0 ? " - " + tipoComunicacao?.nome : ""
                  } => ${comunicacao?.statusComunicacaoNome}`}
              >
                <>
                  {!carregandoConfiguracoesTela && (
                    <a
                      style={{ cursor: "pointer", fontSize: "30px" }}
                      onClick={() => setRefreshCapa(!refreshCapa)}
                    >
                      {
                        <FontAwesomeIcon
                          color={"#DC853D"}
                          className="mx-2"
                          icon={faHistory}
                        />
                      }
                    </a>
                  )}

                  {carregandoConfiguracoesTela && (
                    <span
                      className="indicator-progress"
                      style={{
                        display: "block",
                        fontSize: "30px",
                        paddingLeft: "11px",
                        paddingRight: "11px",
                      }}
                    >
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </>
              </LayoutPageButton>
              <br />
            </div>
          </div>

          <div className="modal-body">
            {carregandoConfiguracoesTela ? (
              <Carregando />
            ) : (
              <>
                {renderCampos()}
                {/* <CompromissoListField modulo={EModulo.Comunicacao} setRefreshCapa={setRefreshCapa} /> */}
                <FileListCapaField modulo={EModulo.Comunicacao} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
              </>
            )}
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }} />

        </div>
      </Modal>
    </>
  );
};
export default ComunicacaoFieldEdit;
