
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,    
  } from 'chart.js'
  
import {ChartOptions, ChartData} from 'chart.js';

import IWidgetDataSet, { IWidgetDado } from "../interfaces/IWidgetDataSet";
import { DadoGenerico, TimeDelta } from '../../types';
import gerarCor from '../../utils/Colors';
import { IGraficoOptions } from './GraficoWidget';
import { useState } from 'react';
import { useEffect } from 'react';


ChartJS.register(
    CategoryScale,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
)

export default function DoughnutChart(props: { dados: IWidgetDataSet, options?:IGraficoOptions}) {
    const [ posicaoLegenda, setPosicaoLegenda ] = useState<any>('top');

    let dataSet = props.dados;
    let dados = dataSet.dados as IWidgetDado[];        
    let total = 0;

    dados.map((a) => total += Number(a.valor));
    const emPercentual = dados.map(v => Math.max(Number(v.valor) / total * 100, 4));

    useEffect(() => {
        if(props.options?.posicaoLegenda) {
            setPosicaoLegenda(props.options?.posicaoLegenda)
        }

    } , [props.options?.posicaoLegenda])

    function corFundo(index: number, limit:number): string {
        return gerarCor(index, limit, 0.95)
    }
    function corBorda(index: number, limit:number): string {
        return gerarCor(index, limit, 1)
    }

    function getDados(): ChartData<"doughnut", number[], string>{
       
        return  {
            labels: dataSet.etiquetas as string[],
            datasets: [{
                data: emPercentual,
                // data: dados.map((dado :IWidgetDado)=>{
                //     if((dado.valor as unknown as DadoGenerico)?.tipo === 'timeDelta'){
                //         return (dado.valor as unknown as TimeDelta).valueOf();
                //     }
                //     return dado.valor as number
                // }),
                backgroundColor: dados.map((dado :IWidgetDado, index: number)=>{
                    return dado?.backgroundColor || corFundo(index, dados.length)
                }),
                borderColor: dados.map((dado :IWidgetDado, index: number)=>{
                    return dado?.borderColor || corBorda(index, dados.length)
                }),
                borderWidth: 1.5
            }],
        };
    }
     
    const chartOptions: ChartOptions<"doughnut"> = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2/1,
        rotation:90,
        layout: {
            padding: 22           
        },
        plugins: {            
            legend: {
                display: true,
                position: posicaoLegenda || 'top',               
                align: 'end',                       
                labels: {
                        filter(item:any, data:any) {
                            if(item.text.length > 20) {
                                item.text = item.text.substring(0,20) + '...';
                            }                           
                            return true;
                        },
                        boxWidth: 25,  
                        padding: 20,                                             
                    },
            },
            datalabels: {   
                display: true,                          
                align:'end',
                anchor: 'end',                        
                color: 'black',                  
                formatter: function(value,context) { 
                    let montante = 0;
                    if(context.dataset.data) {
                        context.dataset.data.map((a) => {
                            montante += Number(a?.toString())
                        })
                    } 

                    if(props.options?.tipoDeDado === 'moeda') {
                        return dados[context.dataIndex].valor.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
                    }

                    return dados[context.dataIndex].valor + ` (${Math.round(value)}%) `

                },                         
                font: {
                    size: 12,                    
                    weight: 'normal',
                }              
                  
            },
            tooltip: {
                callbacks: {                               
                    label: function(this, i): string| string[] {
                        let dados = props.dados.dados as IWidgetDado[];
                        if((dados[0]?.valor as unknown as DadoGenerico)?.tipo === 'timeDelta') {
                            const data:number = Math.floor(this.dataPoints[0].raw as number);
                            const td = new TimeDelta(data);
                            return td.toString('%Y%X%D%h:%m:%s');
                        }

                        return i.label +': ' +dados[i.dataIndex].valor.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
                    }
                }
            }
        }
    }

    //? #TODO Melhorar essa função.
    function calculaPorcentagem(valor: number, montante:number) {
        const resultado = valor * 100 / montante;
        return Math.floor(resultado)
        
    }

    return <Doughnut options={chartOptions} data={getDados()}/>;
}
