import { ReactNode } from "react";

interface ContentsProps {
  children?: ReactNode | ReactNode[];
}

export const LayoutPageContents = ({children}: ContentsProps) => {
  return (
    <div>
      { children }
    </div>
  )
}