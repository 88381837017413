import { useEffect, useState } from "react";
import { faBackward, faEdit, faImage, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import moment from "moment";
import clsx from "clsx";

import AtoSocietarioService from "../../services/AtoSocietarioService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { currencyFormatter } from "../../utils/Utils";
import { ECamposIDEnum, returnarEnumDescricaoID, ETipoCampoEnum, EModulo, ETelaEnum } from "../../enum";

import ImagemField from "../Comum/imagem/ImagemField";

interface ICamposDinamicosPropos {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  setConfiguracaoTela(item: IRecuperaConfiguracaoTelaResponse[]): void;
  configuracaoTelaLista: IRecuperaConfiguracaoTelaResponse[];
  formik: any;
}

const CamposCapaDinamicos = ({
  configuracaoTela,
  setConfiguracaoTela,
  configuracaoTelaLista,
  formik,
}: ICamposDinamicosPropos) => {
  const [campos, setCampos] = useState<any[]>([]);
  const [genericId, setGenericId] = useState<number>(0);
  const [exibirModalImagem, setExibirModalImagem] = useState<boolean>(false);

  const toggleImagemModal = () => setExibirModalImagem(!exibirModalImagem);

  const carregaLista = async () => {
    try {
      if (configuracaoTela.isXCampo) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
          tela: ETelaEnum.TelaCapaAtoSocietario,
          campo: configuracaoTela.campo,
          tipoAtoId: formik.values.tipoAtoId,
          modulo: EModulo.AtoSocietario
        });
        setCampos(campos.data);
      } else {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
          configuracaoTela?.campoNome,
          EModulo.AtoSocietario,
          formik.values.tipoAtoId,
          formik.values.atoSocietarioId);
        setCampos(campos.data);
      }
    } catch (error: any) {
      setCampos([]);
    }
  };

  useEffect(() => {
    if (configuracaoTela.campoTipo == ETipoCampoEnum.Lista) {
      carregaLista();
    }
  }, [formik.values.tipoAtoId]);

  const adicionarSelect = async (valorNovoTexto: string, valorNovoId: number, nome: string, salvarNaBase?: boolean) => {
    formik.setFieldValue(`${nome.toLowerCase()}Id`, valorNovoId, true);
    setGenericId(valorNovoId);
    configuracaoTela.valor = valorNovoTexto;
    configuracaoTela.valorId = valorNovoId;

    if (configuracaoTela.alterarCampo && salvarNaBase && valorNovoId > 0) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando edição...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          if (configuracaoTela.isXCampo) {
            await AtoSocietarioService.alterarAtoSocietarioPorXCampo(ETipoCampoEnum.xCampoOpcao, valorNovoId, configuracaoTela.xCampoValorId);
          } else {
            await AtoSocietarioService.alterarAtoSocietarioPorCampo(`${configuracaoTela?.campoNome.toLowerCase()}Id`, valorNovoId, formik.values.atoSocietarioId);
          }

          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Alteração realizada com sucesso!`,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarTexto = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando edição...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          if (configuracaoTela.isXCampo) {
            await AtoSocietarioService.alterarAtoSocietarioPorXCampo(ETipoCampoEnum.Texto, valorNovo, configuracaoTela.xCampoValorId);
          } else {
            await AtoSocietarioService.alterarAtoSocietarioPorCampo(nome, valorNovo, formik.values.atoSocietarioId);
          }
          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Alteração realizada com sucesso!`,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarValor = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando edição...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          if (configuracaoTela.isXCampo) {
            await AtoSocietarioService.alterarAtoSocietarioPorXCampo(ETipoCampoEnum.Valor, valorNovo, configuracaoTela.xCampoValorId);
          } else {
            await AtoSocietarioService.alterarAtoSocietarioPorCampo(nome, valorNovo, formik.values.atoSocietarioId);
          }
          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Alteração realizada com sucesso!`,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarInteiro = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando edição...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          if (configuracaoTela.isXCampo) {
            await AtoSocietarioService.alterarAtoSocietarioPorXCampo(ETipoCampoEnum.Inteiro, valorNovo, configuracaoTela.xCampoValorId);
          } else {
            await AtoSocietarioService.alterarAtoSocietarioPorCampo(nome, valorNovo, formik.values.atoSocietarioId);
          }

          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Alteração realizada com sucesso!`,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarAreaTexto = async (nome: string, valorNovo: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = valorNovo;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valorNovo });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando edição...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          if (configuracaoTela.isXCampo) {
            await AtoSocietarioService.alterarAtoSocietarioPorXCampo(ETipoCampoEnum.Texto, valorNovo, configuracaoTela.xCampoValorId);
          } else {
            await AtoSocietarioService.alterarAtoSocietarioPorCampo(nome, valorNovo, formik.values.atoSocietarioId);
          }

          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Alteração realizada com sucesso!`,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
        }
      } catch (error: any) {

        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarBoleano = async (nome: string, valor: any) => {
    if (configuracaoTela.alterarCampo && typeof valor == "boolean") {
      formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, valor);
      configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: valor });
      setConfiguracaoTela(configuracaoTelaLista);

      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando edição...",
            showConfirmButton: false,
          });
          Swal.showLoading();
          if (configuracaoTela.isXCampo) {
            await AtoSocietarioService.alterarAtoSocietarioPorXCampoBolleano(ETipoCampoEnum.Boolean, valor, configuracaoTela.xCampoValorId);
          } else {
            await AtoSocietarioService.alterarAtoSocietarioPorCampoBolleano(nome, valor, formik.values.atoSocietarioId);
          }
          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Alteração realizada com sucesso!`,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const adicionarData = async (nome: string, dataNova: string) => {
    formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
    configuracaoTela.valor = dataNova;
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 1, { ...configuracaoTela, valor: dataNova });
    setConfiguracaoTela(configuracaoTelaLista);

    if (configuracaoTela.alterarCampo) {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar dados da Capa",
          text: `Você realmente deseja editar este campo?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando edição...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          if (configuracaoTela.isXCampo) {
            await AtoSocietarioService.alterarAtoSocietarioPorXCampo(ETipoCampoEnum.Data, dataNova, configuracaoTela.xCampoValorId);
          } else {
            await AtoSocietarioService.alterarAtoSocietarioPorCampo(nome, dataNova, formik.values.atoSocietarioId);
          }
          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Alteração realizada com sucesso!`,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel realizar a edição!`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }
  };

  const alterarCampo = () => {

    switch (configuracaoTela.campoTipo) {
      case ETipoCampoEnum.Lista:
        adicionarSelect(configuracaoTela.valor || "", configuracaoTela.valorId || 0, configuracaoTela?.campoNome, true);
        break;

      case ETipoCampoEnum.Texto:
        adicionarTexto(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Boolean:
        adicionarBoleano(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.AreaTexto:
        adicionarAreaTexto(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Valor:
        adicionarValor(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Inteiro:
        adicionarInteiro(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;

      case ETipoCampoEnum.Data:
        adicionarData(`${configuracaoTela?.campoNome.toLowerCase()}`, eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`));
        break;



    }

    configuracaoTelaLista = configuracaoTelaLista.filter((e) => e.campo !== configuracaoTela.campo);
    configuracaoTelaLista.splice(configuracaoTela.ordenacao, 0, { ...configuracaoTela, alterarCampo: !configuracaoTela.alterarCampo });
    setConfiguracaoTela(configuracaoTelaLista);
  };

  const renderModalImagem = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Imagem) {
      return (
        <>
          <ImagemField
            exibirModal={exibirModalImagem}
            toggleModal={toggleImagemModal}
            url={configuracaoTela.valor}
          />
        </>
      );
    }
    return <></>;
  };

  const renderInputs = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
        component = (
          <>
            <select
              value={genericId}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              onChange={(event: any) => adicionarSelect(event.target[event.target.selectedIndex].text, event.target.value, configuracaoTela?.campoNome || "")}
              placeholder={configuracaoTela?.campoNome}
              id={`form-${configuracaoTela?.campoNome}`}
            >
              <option value="0"> Selecione </option>
              {configuracaoTela.isXCampo ?
                campos?.map((item: any) => {
                  return (
                    <option key={item[`xCampoOpcaoId`]} value={item[`xCampoOpcaoId`]}>
                      {item.nome}
                    </option>
                  );
                }) :
                campos?.map((item: any) => {
                  return (
                    <option key={item[`${configuracaoTela?.campoNome.toLowerCase()}Id`]} value={item[returnarEnumDescricaoID(configuracaoTela?.campoNome || "")]}>
                      {item.nome}
                    </option>
                  );
                })}

            </select>
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}Id`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Valor:
        component = (
          <>
            <NumberFormat
              format={currencyFormatter}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              thousandSeparator={true}
              prefix={"R$"}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="date"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              type="checkbox"
              checked={configuracaoTela.valor}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              onClick={(e: any) => (configuracaoTela.valor = e.target.checked)}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Texto:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              maxLength={500}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />

            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <textarea
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />

            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Inteiro:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="number"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          </>
        );
        break;
    }

    return component;
  };

  const renderLabel = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Valor:
        if (configuracaoTela.valor) {
          component = <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configuracaoTela.valor)}</b>;
        } else {
          component = <b>0,00</b>
        }
        break;

      case ETipoCampoEnum.Data:
        component = <b> {configuracaoTela.valor && moment(configuracaoTela.valor).format("DD/MM/YYYY")}</b>;
        break;

      case ETipoCampoEnum.Texto:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.AreaTexto:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Boolean:
        component = <b>{configuracaoTela.valor ? "Sim" : "Não"}</b>;
        break;

      case ETipoCampoEnum.Inteiro:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Imagem:
        component = (
          <>
            <Button
              onClick={() => setExibirModalImagem(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              Clique aqui para visualizar <FontAwesomeIcon style={{ cursor: "pointer" }} color={"white"} className="mx-2" icon={faImage} />
            </Button>
          </>
        )
    }

    return component;
  };

  const renderClassName = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa ||
      configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  return (
    <>
      {renderModalImagem()}

      <div className={renderClassName()}>
        <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
          {configuracaoTela.label}
          <a style={{ fontSize: "12px" }}>
            {configuracaoTela.obrigatorio ? "*:" : ":"}

            {configuracaoTela.help && <PopoverHelper></PopoverHelper>}

            {configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && (
              <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faBackward} />
            )}

            {!configuracaoTela.alterarCampo && !configuracaoTela.somenteLeitura && configuracaoTela.campoTipo != ETipoCampoEnum.Imagem && (
              <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => alterarCampo()} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />
            )}
          </a>
        </label>
        <div className="row">
          {configuracaoTela.alterarCampo && <div className="col-md-12">{renderInputs()}</div>}
          {!configuracaoTela.alterarCampo && <div className="col-md-12">{renderLabel()}</div>}
        </div>
      </div>
    </>
  );
};

export default CamposCapaDinamicos;
