import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IInstancia from "../interfaces/IInstancia";
import { AdicionaInstanciaRequest } from "../interfaces/Requests/Instancia/AdicionaInstanciaRequest";
import FiltroPesquisaInstanciaRequest from "../interfaces/Requests/Instancia/FiltroPesquisaInstanciaRequest";
import { EditarInstanciaRequest } from "../interfaces/Requests/Instancia/EditarInstanciaRequest";

class InstanciaService {

    async obterInstancias(filtros: FiltroPesquisaInstanciaRequest): Promise<IRecuperaLista<IInstancia>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IInstancia>>(`Instancia/RecuperaInstancia?${query}`);

        return result;
    }

    async adicionaInstancia(adicionaInstancia: AdicionaInstanciaRequest): Promise<IInstancia> {
        let { data: result } = await api.post<IInstancia>(`Instancia/AdicionaInstancia`, adicionaInstancia);

        return result;
    }


    async alterarStatus(instanciaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IInstancia>(`Instancia/AtualizaInstanciaPorId?id=${instanciaId}`, data);
    }

    async atualizarInstancia(editarInstancia: EditarInstanciaRequest): Promise<void> {

        let dataInstancia = [
            { "op": "replace", "path": "/nome", "value": editarInstancia.nome },
            { "op": "replace", "path": "/codigo", "value": editarInstancia.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Instancia/AtualizaInstanciaPorId?id=${editarInstancia.instanciaId}`, dataInstancia)
        ]);
    }
}


export default new InstanciaService();