/* eslint-disable no-mixed-spaces-and-tabs */
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from "../../../../../enum";
import { showErrorMessage } from "../../../utils";

import { ISocioAdministrador } from "../Interfaces";
import { ICampo } from "../../../../../interfaces/ICampo";
import { IPessoa } from '../../../../../interfaces/IPessoa';
import IRecuperaLista from "../../../../../interfaces/IRecuperaLista";
import { IConfiguracaoTela } from "../../../../../interfaces/IConfiguracaoTela";
import { OrangeInterfaceContext } from "../../../../../interfaces/Contexts/OrangeInterfaceContext";

import EmpresaService from "../../../../../services/EmpresaService";
import LimitacaoTabelaService from "../../../../../services/LimitacaoTabelaService";

import { modelo } from "./SocioAdministradorCampos";
import TelaCadastroSolucao from "../../../componentes/TelaCadastroSolucao";
import { EmpresaSolucaoLayout } from "../../../componentes/EmpresaSolucaoLayout";
import { notifyDanger } from "../../../../../components/Comum/Toast/ToastFormik";
import TipoQuotaAcoesService from "../../../../../services/TipoQuotaAcoesService";

import { OrangeContext } from "../../../../../contexts/OrangeProvider";
import "../../Print.scss"

interface FiltroPesquisaEmpresaSocioAdministrador {
	nome: string
}

type SocioAdministradorSolucao = ISocioAdministrador & { qualificacaoNome: string, tipoQuotasAcoesNome: string };

export const DistribuicaoCapitalPage = () => {

	const { empresa, setEmpresa } = useContext<OrangeInterfaceContext>(OrangeContext);
	const [exibeModalPessoa, setExibirModalPessoa] = useState<boolean>(false);
	const [carregando, setCarregando] = useState<boolean>(false);
	const [pesquisar, setPesquisar] = useState(false);
	const [dadosFiltrados, setDadosFiltrados] = useState<SocioAdministradorSolucao[]>([])
	const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEmpresaSocioAdministrador>({
		nome: '',
	});

	const [listaTipoQuotaAcoes, setListaTipoQuotaAcoes] = useState<any[]>([]);
	const [listaQualificacoes, setListaQualificacoes] = useState<any[]>([]);

	const calculaQuotasDisponivel = (): number => {
		const quotasTotalInicial = 100
		const restanteDeQuotas = empresa.sociosAdministradores?.reduce((acc, socio) => acc - (socio.quotasAcoes || 0), quotasTotalInicial)
		return restanteDeQuotas !== undefined ? restanteDeQuotas : quotasTotalInicial;
	}

	const [quotasDisponivel, setQuotasDisponivel] = useState(calculaQuotasDisponivel);

	useEffect(() => {
		setTimeout(async () => {
			if (quotasDisponivel > 0) {
				notifyDanger('Percetual de quotas está 100% disponível');
				await Swal.fire({
					heightAuto: false,
					icon: "info",
					title: `Atenção`,
					text: `Percetual de quotas está 100% disponível`
				});
			}
		}, 3000);
	}, [quotasDisponivel]);

	const empresaTelaCadastro: IConfiguracaoTela = {
		modulo: EModulo.Societario,
		tela: 3,
		camposList: [],
	}

	const tela =
	{
		...empresaTelaCadastro,
		camposList: [modelo] as ICampo[],
	}

	const colunas: TableColumn<SocioAdministradorSolucao>[] = [
		{
			name: "Número Documento",
			sortField: "numeroDocumento",
			selector: (row: SocioAdministradorSolucao) => row.numeroDocumento,
			sortable: true,
			wrap: true,
			ignoreRowClick: true,
		},
		{
			name: "Nome Sócio",
			sortField: "nome",
			selector: (row: SocioAdministradorSolucao) => row.nome,
			sortable: true,
			wrap: true,
			ignoreRowClick: true,
		},
		{
			name: "Qualificação",
			sortField: "qualificacaoId",
			selector: (row: SocioAdministradorSolucao) => row.qualificacaoNome,
			sortable: true,
			wrap: true,
			ignoreRowClick: true,
		},
		{
			name: "Tipo de Ações/Quotas",
			sortField: "tipoQuotasAcoesNome",
			selector: (row: SocioAdministradorSolucao) => row.tipoQuotasAcoesNome,
			sortable: true,
			wrap: true,
			ignoreRowClick: true,
		},
		{
			name: "Data entrada",
			sortField: "dataEntradaSociedade",
			selector: (row: SocioAdministradorSolucao) => row.dataEntrada ? new Date(row.dataEntrada).toLocaleDateString() : '',
			sortable: true,
			wrap: true,
			ignoreRowClick: true,
		},
		{
			name: "Quantidade de Ações/Quotas",
			sortField: "quotasAcoes",
			selector: (row: SocioAdministradorSolucao) => row.quotasAcoes ? row.quotasAcoes.toString() : '',
			sortable: true,
			wrap: true,
			ignoreRowClick: true,
		}
	]

	const toggleModal = (value?: boolean) => {
		if (value !== undefined) {
			setExibirModalPessoa(value);
		} else {
			setExibirModalPessoa(!exibeModalPessoa);
		}
	}

	const adicionar = async (socioAdiministrador: ISocioAdministrador, pessoaAtual?: IPessoa) => {
		try {
			const swalConfirm = Swal.mixin({
				heightAuto: false,
				customClass: {
					confirmButton: "btn btn-orange",
					cancelButton: "btn btn-danger ms-5",
				},
				buttonsStyling: false,
			});

			let result = await swalConfirm.fire({
				title: "Salvar Sócio Administrador",
				text: `Você realmente deseja salvar o Sócio Administrador?`,
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: `Confirmar`,
			});

			if (result.isConfirmed) {
				Swal.fire({
					heightAuto: false,
					icon: "info",
					title: "Realizando operação. Aguarde...",
					showConfirmButton: false,
				});
				Swal.showLoading()

				//? #TODO: Verificar esses campos com o Allan.
				socioAdiministrador.idade = '12'
				socioAdiministrador.pais = 'Brasil'

				if (pessoaAtual) {
					socioAdiministrador.nome = pessoaAtual.nome
					socioAdiministrador.numeroDocumento = pessoaAtual.numeroDocumento
				}

				const resultado = await EmpresaService.adicionaSocioAdministrador(empresa.empresaId, socioAdiministrador);

				if (resultado) {
					await carregarEmpresa();

					Swal.fire({
						title: 'Sucesso',
						text: 'Sócio Administrador cadastrado com sucesso.',
						icon: 'success',
						showConfirmButton: true,
						timer: 3000,
					})
					return true
				}
			}
		} catch (error: any) {
			Swal.fire({
				title: 'Operação não realizada!',
				icon: 'error',
				text: showErrorMessage(error),
				showConfirmButton: true,
				timer: 3000,
			})
		} finally {
			Swal.hideLoading()
		}
		return false
	}

	const editar = async (socioAdiministrador: ISocioAdministrador) => {

		try {
			const swalConfirm = Swal.mixin({
				heightAuto: false,
				customClass: {
					confirmButton: 'btn btn-orange',
					cancelButton: 'btn btn-danger ms-5',
				},
				buttonsStyling: false,
			})

			const result = await swalConfirm.fire({
				title: 'Atualizar Sócio Administrador',
				text: 'Você deseja atualizar o sócio administrador?',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Confirmar',
			});

			if (result.isConfirmed) {
				Swal.fire({
					heightAuto: false,
					icon: "info",
					title: 'Realizando Operação, Aguarde...',
					showConfirmButton: false,

				})
				Swal.showLoading();
				await EmpresaService.alteraSocioAdministrador(socioAdiministrador);
				await carregarEmpresa();

				Swal.fire({
					heightAuto: false,
					title: 'Sucesso',
					text: 'Sócio Administrador atualizado com sucesso.',
					icon: 'success',
					showConfirmButton: true,
					timer: 3000,
				})
			}

		} catch (error: any) {
			Swal.fire({
				title: 'Operação não realizada!',
				icon: 'error',
				text: showErrorMessage(error),
				showConfirmButton: true,
				timer: 3000,
			})
		} finally {
			Swal.hideLoading()
		}

	}

	const alterarStatus = async (socioAdiministrador: ISocioAdministrador) => {
		try {
			if (!socioAdiministrador.socioAdministradorId) { return }
			const swalConfirm = Swal.mixin({
				heightAuto: false,
				customClass: {
					confirmButton: "btn btn-orange",
					cancelButton: "btn btn-danger ms-5",
				},
				buttonsStyling: false,
			});

			let result = await swalConfirm.fire({
				title: "Deletar Socio Administrador ",
				text: `Você realmente deseja deletar?`,
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: `Confirmar`,
			});

			if (result.isConfirmed) {
				Swal.fire({
					heightAuto: false,
					icon: "info",
					title: "Realizando operação. Aguarde...",
					showConfirmButton: false,
				});

				Swal.showLoading();
				await EmpresaService.removeSocioAdministrador(socioAdiministrador.socioAdministradorId);
				await carregarEmpresa();

				Swal.fire({
					heightAuto: false,
					icon: "success",
					text: `Socio Administrador deletado com sucesso`,
					showConfirmButton: false,
					timer: 3000,
				});
			}

		} catch (error: any) {
			Swal.fire({
				heightAuto: false,
				icon: "error",
				title: `Ops..`,
				text: showErrorMessage(error),
				showConfirmButton: true,
				timer: 3000,
			});
		} finally {
			Swal.hideLoading();
		}
	}

	const carregarQualificacoes = async () => {
		try {

			const data = await LimitacaoTabelaService.obterListaCompleta('Qualificacao');

			setListaQualificacoes(() => data.data);

		} catch (error: any) {
			Swal.fire({
				heightAuto: false,
				icon: "error",
				title: `Ops..`,
				text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
				showConfirmButton: true,
				timer: 3000,
			});
		}
	}

	const carregarTipoQuotaAcaoes = async () => {
		try {

			let resultado: IRecuperaLista<any>;

			resultado = await TipoQuotaAcoesService.obter({
				nome: '',
				codigo: '',
				status: 1,
				limit: 10,
				totalItems: 0,
				offset: 0,
				sort: 'nome'
			});

			setListaTipoQuotaAcoes(() => resultado.data);

		} catch (error: any) {
			Swal.fire({
				heightAuto: false,
				icon: "error",
				title: `Ops..`,
				text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
				showConfirmButton: true,
				timer: 3000,
			});
		}
	}

	const carregarSocios = (filtroPesquisa: FiltroPesquisaEmpresaSocioAdministrador) => {

		setCarregando(true);

		if (!empresa.sociosAdministradores || listaQualificacoes.length === 0 || listaTipoQuotaAcoes.length === 0) { return; }

		let filtro: SocioAdministradorSolucao[] = [];

		const listaSocios: SocioAdministradorSolucao[] = empresa.sociosAdministradores.map((socio) => {
			const qualificacao = listaQualificacoes.find((el) => el.qualificacaoId === socio.qualificacaoId);
			const tipoQuotaAcoes = listaTipoQuotaAcoes.find((el) => el.tipoQuotasAcoesId === socio.tipoQuotasAcoesId);
			return { ...socio, qualificacaoNome: qualificacao?.nome || '', tipoQuotasAcoesNome: tipoQuotaAcoes?.nome || '' }
		})

		if (filtroPesquisa.nome) {
			filtro = listaSocios?.filter(socio => socio.nome?.includes(filtroPesquisa?.nome));
		} else {
			filtro = listaSocios
		}


		if (filtro.length === 0) {
			Swal.fire({
				heightAuto: false,
				icon: "info",
				title: "Nenhum registro encontrado.",
				showConfirmButton: true,
			});
			setDadosFiltrados(() => listaSocios);
			setPesquisar(false);
		} else {
			setDadosFiltrados(() => filtro);
			setPesquisar(false);

		}
		setCarregando(false);
	}

	const carregarEmpresa = async () => {
		try {

			const resultado = await EmpresaService.obterDadosEmpresa(empresa.empresaId);

			setEmpresa(resultado.data[0]);

			limpaFiltros();

		} catch (error: any) {
			Swal.fire({
				heightAuto: false,
				icon: "error",
				title: `Ops..`,
				text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
				showConfirmButton: true,
				timer: 3000,
			});
		}
	}

	useEffect(() => {

		carregarQualificacoes();

		carregarTipoQuotaAcaoes();

		setQuotasDisponivel(calculaQuotasDisponivel())

		modelo.camposExtras?.map((campo: any) => {
			if (campo.campoNome === 'quotasAcoes') {
				campo.maxValue = calculaQuotasDisponivel()
			}
		})

	}, [empresa.sociosAdministradores]);

	useEffect(() => {
		if (pesquisar === true) {
			carregarSocios(filtrosPesquisa);
		}
	}, [pesquisar]);

	const limpaFiltros = () => {
		setFiltrosPesquisa((oldState) => {
			return { ...oldState, nome: '' };
		});
		setPesquisar(true);
	}

	const botao = (
		<button
			onClick={() => {
				setExibirModalPessoa(true);
			}}
			className="btn btn-md btn-orange"
		>
			Novo
			<FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
		</button>
	)

	return (
		<EmpresaSolucaoLayout title="Distribuição do Capital" botaoAdicionar={botao} loading={carregando}>
			<>
				<ToastContainer />

				<div className="col-md-12 mt-3">
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row mt-3">

							<div className="col-12 col-md-4 col-xl-4">
								<label htmlFor="form-razaoSocial" className="form-label fw-bolder text-orange">
									Nome:
								</label>
								<input
									value={filtrosPesquisa.nome}
									onChange={(e) => {
										setFiltrosPesquisa((oldState) => {
											return { ...oldState, nome: e.target.value };
										});
									}}
									placeholder="Nome"
									type="text"
									className={"form-control"}
									id="form-razaoSocial"
								/>
							</div>

							<div className="col-12 col-md-12 col-xl-12 text-end">

								<button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
									<FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
								</button>
								<button
									onClick={() => {
										limpaFiltros()
									}}
									className="btn btn-sm btn-orange search-buttom-margin ms-2"
								>
									<FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
								</button>

							</div>

						</div>
					</form>

				</div>

				<div>
					<h6 className="text-orange">Percentual disponível</h6>
					<div className="progress">
						<div className="progress-bar-orange text-white" role="progressbar" aria-label="Quotas disponíveis" style={{ width: `${quotasDisponivel}%` }} aria-valuenow={quotasDisponivel} aria-valuemin={0} aria-valuemax={100}>{quotasDisponivel + '%'}</div>
					</div>
				</div>

				{listaQualificacoes && listaTipoQuotaAcoes && (
					<TelaCadastroSolucao
						modelo={modelo}
						colunasDoGrid={colunas}
						loading={carregando}
						dados={dadosFiltrados}
						exibirModal={exibeModalPessoa}
						toggleModal={toggleModal}
						adicionar={adicionar}
						alterarStatus={alterarStatus}
						editar={editar}
						tela={tela}
					/>
				)}

			</>
		</EmpresaSolucaoLayout>
	)
}