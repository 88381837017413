import { useState } from "react";
import { ICampo } from "../../../../interfaces/ICampo";
import ColumnRelatorio from "./Column";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import ICampoTipos from "../../../../interfaces/ICampoTipos";

interface DragDropProps {
  campos: ICampo[];
  camposRelatorio: ICampo[];
  loading: boolean;
  tipoCampos: ICampoTipos[];
  camposRelatorioCustom: { id: string; label?: string; fase?: number }[];
  setCamposRelatorio: (props: { id: string; label?: string }[]) => void;
  toggleModal: (campo: { id: string; label?: string }) => void;
}

const DragDropRelatorio = ({
  campos,
  camposRelatorio,
  loading,
  setCamposRelatorio,
  tipoCampos,
  camposRelatorioCustom,
  toggleModal,
}: DragDropProps) => {
  const [columns, setColumns] = useState({
    "column-1": {
      id: "column-1",
      title: "Campos do Sistema",
      campoIds: campos
        .map((campo) => campo.idUnico)
        .filter((idUnico) => {
          const found = camposRelatorio.find(
            (campoRelatorio) => campoRelatorio.idUnico === idUnico
          );

          return !found;
        }),
    },
    "column-2": {
      id: "column-2",
      title: "Campos do Relatório",
      campoIds: camposRelatorio.map((campo) => campo.idUnico ?? ""),
    },
    "column-3": {
      id: "column-3",
      title: "Campos Descartados",
      campoIds: [""],
    },
  });

  const [columnsOrder] = useState(["column-1", "column-2", "column-3"]);

  const handleDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = columns[source.droppableId as keyof typeof columns];
    const finish = columns[destination.droppableId as keyof typeof columns];

    if (start === finish) {
      const newFieldIds = Array.from(start.campoIds);
      newFieldIds.splice(source.index, 1);
      newFieldIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        campoIds: newFieldIds,
      };

      setColumns((prev) => ({ ...prev, [newColumn.id]: newColumn }));

      return;
    }

    const startFieldIds = Array.from(start.campoIds);
    startFieldIds.splice(source.index, 1);

    const newStart = {
      ...start,
      campoIds: startFieldIds,
    };

    const finishFieldIds = Array.from(finish.campoIds);
    finishFieldIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      campoIds: finishFieldIds,
    };

    setColumns((prev) => ({
      ...prev,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    }));

    if (newStart.id === "column-1" || newStart.id === "column-3") {
      const mappedFields = finishFieldIds.map((field) => {
        const [campoSelecionado] = campos.filter(
          (campo) => campo.idUnico === field
        );

        if (field === draggableId) {
          return {
            id: field ?? "",
            label: campoSelecionado.label,
            fase: campoSelecionado.fase,
          };
        } else {
          const findField = camposRelatorioCustom.find(
            (campo) => campo.id === field
          );

          return {
            id: field ?? "",
            label: findField?.label ?? campoSelecionado.label,
            fase: findField?.fase,
          };
        }
      });

      setCamposRelatorio(mappedFields);
    } else {
      const mappedFields = startFieldIds.map((field) => {
        if (draggableId === field) {
          const fieldSelecionado = campos.find(
            (campo) => campo.idUnico === field
          );

          return {
            id: field ?? "",
            label: fieldSelecionado?.label,
            fase: fieldSelecionado?.fase,
          };
        }

        const fieldSelecionado = camposRelatorioCustom.find(
          (campo) => campo.id === field
        );

        return {
          id: field ?? "",
          label: fieldSelecionado?.label,
          fase: fieldSelecionado?.fase,
        };
      });
      setCamposRelatorio(mappedFields);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="d-flex justify-content-between">
        {columnsOrder.map((columnId) => {
          const column = columns[columnId as keyof typeof columns];

          const mappedCampos = column.campoIds.map((campoId) => {
            const found = campos.filter((campo) => campo.idUnico === campoId);

            return found[0];
          });

          const mappedTipoCampos =
            columnId === "column-1"
              ? tipoCampos.map((tipoCampo) => {
                  const campos = tipoCampo.campos.filter((campo) => {
                    return column.campoIds.includes(campo.idUnico ?? "");
                  });

                  return {
                    tipo: tipoCampo.tipo,
                    tabelaOrigem: tipoCampo.tabelaOrigem,
                    campos: campos,
                  };
                })
              : [];

          return (
            <ColumnRelatorio
              key={column.id}
              column={
                column as {
                  id: string;
                  title: string;
                  campoIds: string[];
                }
              }
              campos={mappedCampos}
              loading={loading}
              isDivided={columnId === "column-1"}
              tipoCampos={mappedTipoCampos}
              camposRelatorio={camposRelatorioCustom}
              toggleModal={toggleModal}
            />
          );
        })}
      </div>
    </DragDropContext>
  );
};

export default DragDropRelatorio;
