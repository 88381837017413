import { Validate } from "validate-cnj";
import { EModulo, ETelaEnum, returnarEnumDescricaoID } from "../enum";
import { ETipoTelaEnum } from "../enum/ETipoTelaEnum";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import axios from "axios";

const DEFAULT_ERROR_MESSAGE = "Ocorreu um erro inesperado, se o problema persistir contate nosso suporte."

export const removerPropriedadesVazia = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => removerPropriedadesVazia(item));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (value !== null && value !== '' && value !== undefined && value !== 0 && value !== false && !(Array.isArray(value) && value.length === 0) && !(typeof value === 'object' && Object.keys(value).length === 0)) {
        acc[key] = removerPropriedadesVazia(value);
      }
      return acc;
    }, {} as any);
  } else {
    return obj;
  }
}

export const validarResults = (resultados: any[]): boolean => {
  let OK = false;
  resultados?.forEach(item => {
    if (item?.status === 200 || item?.status == 201) {
      OK = true;
    } else {
      return false;
    }
  });
  return OK;
}
export const isValidCNPJ = (cnpj: any) => {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]+/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs inválidos conhecidos
  if (/^(\d)\1+$/.test(cnpj)) return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export const getOptionTipoCampoText = (value: any) => {
  const options: { [key: number]: string } = {
    0: "Selecione o Tipo",
    1: "Lista",
    16: "ListLink",
    10: "ListaMulti",
    2: "Data",
    3: "Texto",
    4: "Valor",
    12: "Percentual",
    5: "AreaTexto",
    6: "Boolean",
    9: "Inteiro",
    15: "Tabela",
  };

  return options[value] || "Unknown";
};


export const orderByNome = (a: any, b: any) => {
  const nomeA = a.nome.toUpperCase();
  const nomeB = b.nome.toUpperCase();

  if (nomeA < nomeB) {
    return -1;
  }
  if (nomeA > nomeB) {
    return 1;
  }

  return 0; // Nomes são iguais
}

export const tratarValorZerado = (value: any) => { if (!value) return 0; else return value; }


export const styleSelect = (formik: any, configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
  return {
    control: (provided: any) => ({
      ...provided,
      borderColor: configuracaoTela.obrigatorio &&
        formik.touched[`${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`] &&
        formik.errors[`${returnarEnumDescricaoID(configuracaoTela?.campoNome, configuracaoTela.isXCampo)}`] ? '#ff0000' : provided.borderColor,
    }),
  };
};

export const validarCep = (cep: string) => {
  if (cep) {
    cep = cep.replace(/[^0-9]/gi, "");
    if (cep.length == 8) {
      return cep;
    }
  }
  return null;
};

export const formartDateDDMMYYYY = (date: Date): string => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formartDDMMYYYToDateTime = (data: any): any => {
  if (data) return new Date(data);
  return null;
};

export const formatDate = (dateString: string, pattern = "dd/MM/yyyy") => {
  return format(new Date(dateString), pattern, { locale: ptBR });
};

export const handleApiError = (error: unknown, defaultMessage = DEFAULT_ERROR_MESSAGE): string => {
  return axios.isAxiosError(error) ? error.response?.data?.Message : defaultMessage
}

export const tipoPorContexto = (modulo: EModulo): string => {

  switch (modulo) {

    case EModulo.AreaDireito:
      return `processo.areaDireitoId`;

    case EModulo.Contrato:
      return `contrato.tipoContratoId`;

    case EModulo.Societario:
      return `empresa.tipoSociedadeId`;

    case EModulo.Audiencia:
      return `audiencia.tipoAudienciaId`;

    case EModulo.MarcaEPertences:
      return `marca.tipoMarcaId`;

    case EModulo.Imovel:
      return `imovel.tipoImovelId`;

    case EModulo.Regulatorio:
      return `licenca.tipoLicencaId`;

    case EModulo.Consultivo:
      return `consulta.tipoConsultaId`;

    case EModulo.Procuracao:
      return `procuracao.tipoProcuracaoId`;

    case EModulo.AtoSocietario:
      return `ato.tipoAtoId`;

    case EModulo.Despesa:
      return `despesa.tipoDespesaId`;

    case EModulo.Obrigacao:
      return `obrigado.tipoObrigacaoId`;

    case EModulo.Decisao:
      return `decisao.tipoDecisaoId`;

    case EModulo.Bloqueio:
      return `bloqueio.tipoBloqueioId`;

    case EModulo.PropostaAcordo:
      return `propostaAcordo.tipoPropostaAcordoId`;

    case EModulo.Desdobramento:
      return `desdobramento.tipoDesdobramentoId`;

    case EModulo.Garantia:
      return `garantia.tipoGarantiaId`;

    case EModulo.Protocolo:
      return `propotocolo.tipoProtocoloId`;

    case EModulo.Comunicacao:
      return `comunicacao.tipoComunicacaoId`;

    case EModulo.ParteContraria:
      return `parteContraria.tipoParteContrariaId`;

    case EModulo.XpayReembolso:
      return `reembolso.tipoReembolsoId`;

    default:
      return "NaoEncontrado";
  }
}

export const tipoPorModulo = (modulo: EModulo): string => {

  switch (modulo) {

    case EModulo.AreaDireito:
      return `areaDireito`;

    case EModulo.Contrato:
      return `tipoContrato`;

    case EModulo.Societario:
      return `tipoSociedade`;

    case EModulo.Audiencia:
      return `tipoAudiencia`;

    case EModulo.MarcaEPertences:
      return `tipoMarca`;

    case EModulo.Imovel:
      return `tipoImovel`;

    case EModulo.Regulatorio:
      return `tipoLicenca`;

    case EModulo.Consultivo:
      return `tipoConsulta`;

    case EModulo.Procuracao:
      return `tipoProcuracao`;

    case EModulo.AtoSocietario:
      return `tipoAto`;

    case EModulo.Despesa:
      return `tipoDespesa`;

    case EModulo.Obrigacao:
      return `tipoObrigacao`;

    case EModulo.Decisao:
      return `tipoDecisao`;

    case EModulo.Bloqueio:
      return `tipoBloqueio`;

    case EModulo.PropostaAcordo:
      return `tipoPropostaAcordo`;

    case EModulo.Desdobramento:
      return `tipoDesdobramento`;

    case EModulo.Garantia:
      return `tipoGarantia`;

    case EModulo.Protocolo:
      return `tipoProtocolo`;

    case EModulo.Comunicacao:
      return `tipoComunicacao`;

    case EModulo.ParteContraria:
      return `tipoParteContraria`;

    case EModulo.XpayReembolso:
      return `tipoReembolso`;

    case EModulo.Compromisso:
      return `fluxoFase`;

    default:
      return "NaoEncontrado";
  }
}

export const routePropriedade = (modulo: EModulo): string => {

  switch (modulo) {

    case EModulo.AreaDireito:
      return `Processo`;

    case EModulo.Contrato:
      return `Contrato`;

    case EModulo.Societario:
      return `Empresa`;

    case EModulo.Bloqueio:
      return `Bloqueio`;

    case EModulo.Obrigacao:
      return `Obrigacao`;

    case EModulo.Decisao:
      return `Decisao`;

    case EModulo.Garantia:
      return `Garantia`;

    case EModulo.AtoSocietario:
      return `Ato`;

    case EModulo.Desdobramento:
      return `Desdobramento`;

    case EModulo.MarcaEPertences:
      return `Marca`;

    case EModulo.Imovel:
      return `Imovel`;

    case EModulo.PropostaAcordo:
      return `PropostaAcordo`;

    case EModulo.Regulatorio:
      return `Licenca`;

    case EModulo.Consultivo:
      return `Consulta`;

    case EModulo.Procuracao:
      return `Procuracao`;

    case EModulo.Despesa:
      return `Despesa`;

    case EModulo.XpayHonorario:
      return `XpayHonorario`;

    case EModulo.XpayReembolso:
      return `XpayReembolso`;

    case EModulo.Comunicacao:
      return `Processo`;

    case EModulo.Audiencia:
      return `Audiencia`;

    default:
      return "NaoEncontrado";
  }
}

export const routeCapaCadastroPesquisa = (tela: ETelaEnum, modulo: EModulo, naoAdicionarModuloURL?: boolean): string => {
  
  if (tela == ETelaEnum.TelaPesquisaPrincipal) {
    return naoAdicionarModuloURL ? `Pesquisa/${modulo}` : ETipoTelaEnum.Principal;
  }
  if (tela == ETelaEnum.TelaPesquisaMaisFiltros) {
    return naoAdicionarModuloURL ? `Pesquisa/${modulo}` : ETipoTelaEnum.Filtros;
  }
  if (DefinirTipoTela(tela) == ETipoTelaEnum.Cadastro) {
    return ETipoTelaEnum.Cadastro;
  }
  else {
    return ETipoTelaEnum.Capa;
  }
}

export const telaCadastro = (modulo: EModulo): string => {

  switch (modulo) {

    case EModulo.AreaDireito:
      return 'processoId';

    case EModulo.Contrato:
      return `contratoId`;

    case EModulo.Societario:
      return `empresaId`;

    case EModulo.AtoSocietario:
      return `atoId`;

    case EModulo.MarcaEPertences:
      return `marcaId`;

    case EModulo.Imovel:
      return `imovelId`;

    case EModulo.Regulatorio:
      return `licencaId`;

    case EModulo.Consultivo:
      return `consultaId`;

    case EModulo.Procuracao:
      return `procuracaoId`;

    case EModulo.Despesa:
      return `despesaId`;

    case EModulo.XpayHonorario:
      return `honorarioId`;

    case EModulo.XpayReembolso:
      return `reembolsoId`;

    case EModulo.Comunicacao:
      return `comunicacaoId`;

    default:
      return "NaoEncontrado";
  }
}


export const objetoPropriedade = (modulo: EModulo): string => {

  switch (modulo) {

    case EModulo.AreaDireito:
      return `processo`;

    case EModulo.Contrato:
      return `contrato`;

    case EModulo.Societario:
      return `empresa`;

    case EModulo.AtoSocietario:
      return `ato`;

    case EModulo.MarcaEPertences:
      return `marca`;

    case EModulo.Imovel:
      return `imovel`;

    case EModulo.Regulatorio:
      return `licenca`;

    case EModulo.Consultivo:
      return `consulta`;

    case EModulo.Procuracao:
      return `procuracao`;

    case EModulo.Despesa:
      return `despesa`;

    case EModulo.XpayHonorario:
      return `honorario`;

    case EModulo.XpayReembolso:
      return `reembolso`;

    case EModulo.Comunicacao:
      return `comunicacao`;

    default:
      return "NaoEncontrado";
  }
}


export const objetoIdPropriedade = (modulo: EModulo): string => {

  switch (modulo) {

    case EModulo.AreaDireito:
      return `processoId`;

    case EModulo.Contrato:
      return `contratoId`;

    case EModulo.Societario:
      return `empresaId`;

    case EModulo.AtoSocietario:
      return `atoId`;

    case EModulo.MarcaEPertences:
      return `marcaId`;

    case EModulo.Imovel:
      return `imovelId`;

    case EModulo.Regulatorio:
      return `licencaId`;

    case EModulo.Consultivo:
      return `consultaId`;

    case EModulo.Procuracao:
      return `procuracaoId`;

    case EModulo.Despesa:
      return `despesaId`;

    case EModulo.XpayHonorario:
      return `honorarioId`;

    case EModulo.XpayReembolso:
      return `reembolsoId`;

    case EModulo.Comunicacao:
      return `comunicacaoId`;

    default:
      return "NaoEncontrado";
  }
}

export const formatDateYYYYDDMM = (data: string) => {
  if (data) {
    let mes = data.split("/")[0];
    let dia = data.split("/")[1];
    let ano = data.split("/")[2].substring(0, 4);
    return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
  }
  return null;
};

export const currencyFormatterPerc = (value: any): string => {
  if (!value) return "0,00";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).formatToParts(value / 100);

  const numericValue = amount
    .filter((part) => part.type !== "currency")
    .map((part) => part.value)
    .join("");

  return numericValue;
};


export const currencyFormatter = (value: any, moeda?: string): string => {
  if (!value) return "0,00";

  let amount: any;
  let currencyCode = "BRL";
  let locale = "pt-BR";

  switch (moeda) {

    case "Dólar":
      currencyCode = "USD";
      break;

    case "Peso Agentino":
      currencyCode = "ARS";
      break;

    case "Peso Chileno":
      // eslint-disable-next-line no-case-declarations
      amount = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).formatToParts(value / 100);
      // eslint-disable-next-line no-case-declarations
      const numericValue = amount
        .filter((part: any) => part.type !== "currency")
        .map((part: any) => part.value)
        .join("");

      return `CLP ${numericValue}`;


    case "Euro":
      currencyCode = "EUR";
      break;

    case "Libra":
      currencyCode = "GBP";
      break;

    default:
      currencyCode = "BRL";
  }

  amount = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  }).format(value / 100);

  return `${amount}`;
};

export const currencyLabelFormatter = (value: any, moeda?: string): string => {
  if (!value) return "0,00";

  let amount: any;
  let currencyCode = "BRL";
  let locale = "pt-BR";

  switch (moeda) {

    case "Dólar":
      currencyCode = "USD";
      break;

    case "Peso Argentino":
      currencyCode = "ARS";
      break;

    case "Peso Chileno":
      // eslint-disable-next-line no-case-declarations
      amount = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).formatToParts(value);
      // eslint-disable-next-line no-case-declarations
      const numericValue = amount
        .filter((part: any) => part.type !== "currency")
        .map((part: any) => part.value)
        .join("");

      return `CLP ${numericValue}`;

    case "Euro":
      currencyCode = "EUR";
      break;

    case "Libra":
      currencyCode = "GBP";
      break;

    default:
      currencyCode = "BRL";
  }

  amount = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  }).format(value);

  return `${amount}`;
};


export const currencyFormatterNotRS = (value: any): string => {
  if (!value) return "0,00";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return `${amount}`.replaceAll("R$", "");
};

export const currencyLabelFormatterNotRS = (value: any): string => {
  if (!value) return "0,00";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return `${amount}`.replaceAll("R$", "");
};

export const currencyLabelTotalFormatterNotRS = (value: any): string => {
  if (!Number(value)) return "0,00";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return `${amount}`.replaceAll("R$", "");
};

export const currencyNumber = (value: any): string => {
  if (!Number(value)) return "";
  return value.replace(/([^\d])+/gim, '');
};

export const countNumber = (value: string): number => {
  return (value.match(/[1-9]/g) || []).length;
};

export const countUpperCase = (value: string): number => {
  return (value.match(/[A-Z]/g) || []).length;
};

export const countLowerCase = (value: string): number => {
  return (value.match(/[a-z]/g) || []).length;
};

export const countCharacterSpecial = (value: string): number => {
  const noSpecialChars = value.replace(/[^a-zA-Z0-9 ]/g, '#');
  return (noSpecialChars.match(new RegExp("#", "g")) || []).length;
};

export const removeFormatMoney = (value: string): number => {
  if (value) {
    let formatValue = value.replace("R$ ", "").replace(/\D/g, "");
    const slice = formatValue.length - 2;
    formatValue = [formatValue.slice(0, slice), ".", formatValue.slice(slice, formatValue.length)].join("");

    return Number(formatValue);
  }
  return 0;
};

export const validarNumeroUnicoProcesso = (numero: string): boolean => {
  Validate.load(numero);

  return true;
};

export const valorAbreviado = (num: number, digits: number): any => {
  let units = ['mil', 'Mi', 'Bi'],
    decimal;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }

  return num;
}

export const removeSpecialCharacters = (str: string): string => {
  const regex = /[^a-zA-Z0-9 ]/g;
  return str.replace(regex, '');
}


export const DefinirTipoTela = (tela: ETelaEnum) => {
  switch (tela) {
    case ETelaEnum.TelaCadastroProcesso:
    case ETelaEnum.TelaCadastroEmpresa:
    case ETelaEnum.TelaCadastroAtoSocietario:
    case ETelaEnum.TelaCadastroDespesa:
    case ETelaEnum.TelaCadastroContrato:
    case ETelaEnum.TelaCadastroRegulatorio:
    case ETelaEnum.TelaCadastroConsultivo:
    case ETelaEnum.TelaCadastroImovel:
    case ETelaEnum.TelaCadastroMarcasEPertences:
    case ETelaEnum.TelaCadastroProcuracao:
    case ETelaEnum.TelaConcluirFase:
    case ETelaEnum.TelaCadastroHonorario:
    case ETelaEnum.TelaCadastroReembolso:
    case ETelaEnum.TelaCadastroXpayFechamento:
      return ETipoTelaEnum.Cadastro;

    case ETelaEnum.TelaCapaProcesso:
    case ETelaEnum.TelaCapaEmpresa:
    case ETelaEnum.TelaCapaAtoSocietario:
    case ETelaEnum.TelaCapaDespesa:
    case ETelaEnum.TelaCapaContrato:
    case ETelaEnum.TelaCapaRegulatorio:
    case ETelaEnum.TelaCapaConsultivo:
    case ETelaEnum.TelaCapaImovel:
    case ETelaEnum.TelaCapaMarcasEPertences:
    case ETelaEnum.TelaCapaProcuracao:
    case ETelaEnum.TelaCapaHonorario:
    case ETelaEnum.TelaCapaReembolso:
    case ETelaEnum.TelaCapaXpayFechamento:
    case ETelaEnum.TelaPesquisaPrincipal:
    case ETelaEnum.TelaPesquisaMaisFiltros:
      return ETipoTelaEnum.Capa;

  }
}
