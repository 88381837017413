import { useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileImport, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import IPublicacao from "../../interfaces/IPublicacao";
import PublicacaoService from "../../services/PublicacaoService";
import IDModalPage from "../ProcessoPage/Modal/IDModalPage";


interface IPublicacaoModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  publicacao: IPublicacao;
  titulo: string;
}

const PublicacaoVincularModal = ({ toggleModal, exibirModal, publicacao, titulo }: IPublicacaoModalProps) => {

  const [exibirProcesso, setExibirProcesso] = useState<boolean>(false);

  const toggleProcessoModal = () => setExibirProcesso(!exibirProcesso);

  const initialValues = {
    processoprincipalid: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      vincularProcesso(publicacao.publicacaoId, values.processoprincipalid);
    },
  });


  async function vincularProcesso(Id: number, processoId: number) {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-orange',
          cancelButton: 'btn btn-danger ms-5'
        },
        buttonsStyling: false
      });

      let result = await swalWithBootstrapButtons.fire({
        title: 'Processo',
        text: `Você realmente deseja vincular?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: `Confirmar`
      });

      if (result.isConfirmed) {

        await PublicacaoService.vincularProcesso(Id, processoId);

        toggleModal();

        Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Processo Vinculado com sucesso`,
          showConfirmButton: false,
          timer: 3000
        });

      }

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        title: `Não foi possivel alterar`,
        text: error?.response?.data?.Message,
        showConfirmButton: true
      });
    }
  }

  return (
    <>

      <IDModalPage
        toggleProcessoModal={toggleProcessoModal}
        exibirProcessoModal={exibirProcesso}
        formik={formik}
      />

      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}  <FontAwesomeIcon className="mx-2 text-orange" icon={faFileAlt} /></h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="input-group">
                  <input
                    {...formik.getFieldProps('processoprincipalid')}
                    placeholder={'Informar o Processo'}
                    type="number"
                    className={'form-control'}
                  />
                  <Button
                    onClick={() => toggleProcessoModal()}
                    style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                    variant="secondary"
                    size="sm"
                  >
                    Pesquisar: <FontAwesomeIcon color={"white"} className="mx-1" icon={faSearch} />
                  </Button>
                </div>
              </div>
            </div>

            <br></br>

            <div className="modal-footer" style={{ margin: "0 auto" }}>
              <button onClick={() => formik.handleSubmit()} type="button" className="btn btn-orange me-5">
                Vincular <FontAwesomeIcon className="mx-2" icon={faFileImport} />
              </button>
              <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                Fechar
              </button>
            </div>
          </div>
        </div>


      </Modal>
    </>
  );
};

export default PublicacaoVincularModal;
