import { faClone, faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import GridPadrao from "../../../components/Comum/GridPadrao";
import ITipoContrato from "../../../interfaces/ITipoContrato";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import FiltroPesquisaTipoContratoRequest from "../../../interfaces/Requests/TipoContrato/FiltroPesquisaTipoContratoRequest";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import TipoContratoService from "../../../services/TipoContratoService";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { EModulo, ETelaEnum } from "../../../enum";
import "../Styles/icons.css";
import DuplicarParametrizacaoModal from "../../../components/DuplicarParametrizacao/DuplicarParametrizacaoModal";

const TipoContratoPesquisaPage = () => {
  const [tipoContratos, setTipoContratos] = useState<ITipoContrato[]>([]);
  const [tipoContratosClone, setTipoContratosClone] = useState<ITipoContrato[]>([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoTipoContrato, setCarregandoTipoContrato] = useState(false);
  const { setTipoContrato, setCampos, setTela } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaTipoContratoRequest>({
    nome: "",
    codigo: "",
    categoria: 0,
    status: 1,
    limit: 1000,
    totalItems: 0,
    offset: 0,
    sort: "id",
  });


  const nomearCategoria = (categoria: string): string => {
    switch (categoria) {
      case "Contrato": return "Contrato";
      case "AditivoPrazo": return "Aditivo de Prazo";
      case "AditivoValor": return "Aditivo de Valor";
      case "AditivoOutros": return "Outros Aditivos";
      case "Distrato": return "Distrato";
      case "Notificacoes": return "Notificações";
      default: return "Não definido";
    }
  };

  const carregarTipoContrato = async (filtro: FiltroPesquisaTipoContratoRequest) => {
    try {
      setCarregandoTipoContrato(true);
      const resultado: IRecuperaLista<ITipoContrato> = await TipoContratoService.obterTipoContratos(filtro);
      setFiltrosPesquisa(oldState => ({ ...oldState, totalItems: resultado.totalRegistros }));
      setTipoContratos(resultado.data);
    } catch (error: any) {
      setTipoContratos([]);
    } finally {
      setCarregandoTipoContrato(false);
      setLoadingInicial(false);
    }
  };

  const carregarTipoContratoClone = async () => {
    try {
      const resultado: IRecuperaLista<ITipoContrato> = await TipoContratoService.obterTipoContratos({
        nome: "",
        codigo: "",
        categoria: 0,
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "id",
      });
      setTipoContratosClone(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message ?? error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  useLayoutEffect(() => {
    setCampos([]);
    setTela(ETelaEnum.Default);
    if (!carregandoTipoContrato) carregarTipoContrato(filtrosPesquisa);
  }, []);

  const colunas: TableColumn<ITipoContrato>[] = [
    { name: "Id", sortField: "tipoContratoId", selector: row => row.tipoContratoId, sortable: true, wrap: true, ignoreRowClick: true },
    { name: "Nome", sortField: "tipoContratoNome", selector: row => row.nome, sortable: true, wrap: true },
    { name: "Categoria", sortField: "categoria", selector: row => nomearCategoria(row.categoria?.toString() || ""), sortable: true, wrap: true },
  ];

  const onRowClicked = (row: ITipoContrato) => {
    setTipoContrato(row);
    navigate("/Contrato/ConfiguracaoTela/Solucoes");
  };

  const handlePerRowsChange = (currentRowsPerPage: number) => {
    setFiltrosPesquisa(oldState => ({ ...oldState, limit: currentRowsPerPage }));
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa(oldState => ({ ...oldState, offset: (page - 1) * filtrosPesquisa.limit }));
  };

  const handleSort = (column: TableColumn<ITipoContrato>, sortDirection: string) => {
    setFiltrosPesquisa(oldState => ({
      ...oldState,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    }));
  };

  const limparFiltros = () => {
    setFiltrosPesquisa(oldValue => ({ ...oldValue, nome: "", codigo: "", status: 1, categoria: 0 }));
  };

  useEffect(() => {
    if (!loadingInicial) {
      carregarTipoContrato(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageTitle title="Selecione o Tipo de Contrato que você deseja configurar" />

      <DuplicarParametrizacaoModal
        exibirModal={exibirModal}
        handleClose={async () => {
          setExibirModal(false);
          await carregarTipoContrato(filtrosPesquisa);
        }}
        itens={tipoContratosClone}
        modalTitle="Copiar configurações de um Contrato para o outro"
        idKey="tipoContratoId"
        moduloId={EModulo.Contrato}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarTipoContrato(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome</label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => setFiltrosPesquisa(oldState => ({ ...oldState, nome: e.target.value }))}
                placeholder="Nome"
                type="text"
                className="form-control"
                id="form-nome"
              />
            </div>

            <div className="col-md-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Status</label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => setFiltrosPesquisa(oldState => ({ ...oldState, status: parseInt(e.target.value) }))}
                placeholder="Nome"
                className="form-select"
                id="form-select"
              >
                <option value="0" label="Todos">Todos</option>
                <option value="1" label="Ativo">Ativo</option>
                <option value="-1" label="Inativo">Inativo</option>
              </select>
            </div>

            <div className="col-md-2">
              <label htmlFor="form-categoria" className="form-label fw-bolder text-orange">Categoria</label>
              <select
                value={filtrosPesquisa.categoria}
                onChange={(e) => setFiltrosPesquisa(oldState => ({ ...oldState, categoria: parseInt(e.target.value) }))}
                placeholder="Nome"
                className="form-select"
                id="form-select"
              >
                <option value="0" label="Selecione">Selecione</option>
                <option value="1" label="Contrato">Contrato</option>
                <option value="2" label="Aditivo Prazo">Aditivo Prazo</option>
                <option value="3" label="Aditivo Valor">Aditivo Valor</option>
                <option value="4" label="Aditivo Outros">Aditivo Outros</option>
                <option value="5" label="Distrato">Distrato</option>
                <option value="6" label="Notificações">Notificações</option>
              </select>
            </div>

            <div className="col-md-2">
              <div>
                <button type="submit" className="btn btn-sm btn-orange search-buttom-margin">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                </button>
                <button
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  onClick={async (e) => {
                    e.preventDefault();
                    await carregarTipoContratoClone();
                    setExibirModal(true);
                  }}
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faClone} />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            onRowClicked={onRowClicked}
            progressPending={carregandoTipoContrato}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Tipo de Contrato"
            itens={tipoContratos}
          />
        </div>
      </div>
    </>
  );
};

export default TipoContratoPesquisaPage;
