import { useContext } from "react";
import { MdAdd, MdRemove } from "react-icons/md";
import { Editor, Text, Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import styled from "styled-components";
import { FontContext } from "../../../context/FontContext";
import { CustomText } from "../../../../../utils/types/slate";

const Container = styled.div`
  display: flex;
  gap: 4px;

  > input,
  button {
    border: none;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    text-align: center;
  }

  > input {
    border: 1px solid #ddd;
  }

  > button {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    &:hover {
      background: #d3e3fd;
    }
  }
`;

export const FontSize = () => {
  const editor = useSlateStatic();
  const { fontSize, setFontSize } = useContext(FontContext);

  const setNodeSize = (size: number) => {
    const { marks } = Editor.marks(editor) as CustomText;

    Transforms.setNodes(editor, {
      marks: {
        ...marks,
        fontSize: `${size}pt`,
      },
    }, { match: node => Text.isText(node), split: true });

    setFontSize(size);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNodeSize(Number(event.target.value))
  };

  const toggle = (action: "remove" | "add") => {
    if (action === "remove") {
      setNodeSize(fontSize - 1)
    }

    if (action === "add") {
      setNodeSize(fontSize + 1)
    }
  };

  return (
    <Container>
      <button onClick={() => toggle("remove")} type="button">
        <MdRemove />
      </button>
      <input min={1} type="text" value={fontSize} onChange={onChange} />
      <button type="button">
        <MdAdd onClick={() => toggle("add")} />
      </button>
    </Container>
  );
};
