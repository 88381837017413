import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IBandeira from "../interfaces/IBandeira";
import { AdicionaBandeiraRequest } from "../interfaces/Requests/Bandeira/AdicionaBandeiraRequest";
import FiltroPesquisaBandeiraRequest from "../interfaces/Requests/Bandeira/FiltroPesquisaBandeiraRequest";
import { EditarBandeiraRequest } from "../interfaces/Requests/Bandeira/EditarBandeiraRequest";

class BandeiraService {

    async obterBandeiras(filtros: FiltroPesquisaBandeiraRequest): Promise<IRecuperaLista<IBandeira>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IBandeira>>(`Bandeira/RecuperaBandeira?${query}`);

        return result;
    }

    async adicionaBandeira(adicionaBandeira: AdicionaBandeiraRequest): Promise<IBandeira> {
        let { data: result } = await api.post<IBandeira>(`Bandeira/AdicionaBandeira`, adicionaBandeira);

        return result;
    }


    async alterarStatus(bandeiraId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IBandeira>(`Bandeira/AtualizaBandeiraPorId?id=${bandeiraId}`, data);
    }

    async atualizarBandeira(editarBandeira: EditarBandeiraRequest): Promise<void> {

        let dataBandeira = [
            { "op": "replace", "path": "/nome", "value": editarBandeira.nome },
            { "op": "replace", "path": "/codigo", "value": editarBandeira.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Bandeira/AtualizaBandeiraPorId?id=${editarBandeira.bandeiraId}`, dataBandeira)
        ]);
    }
}


export default new BandeiraService();