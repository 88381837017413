/* eslint @typescript-eslint/no-var-requires: "off" */
import { validateCNPJ, validateCPF } from "validations-br";
import * as formik from 'formik';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import * as Yup from "yup";
import { Modelo } from './CadastroCampo';
import RecuperaPessoa from "../../../../components/Comum/Pessoa/RecuperaPessoa";
import { ETipoCampoEnum } from "../../../../enum";
import { ICampo } from "../../../../interfaces/ICampo";
import { ICampoDinamico } from "../../../../interfaces/ICampoDinamico";
import { IPessoa } from "../../../../interfaces/IPessoa";
import ConfiguracaoTelaService from "../../../../services/ConfiguracaoTelaService";
import { MASCARA_CPF, MASCARA_CNPJ } from "../../../../utils/Constants";
import * as enums from '../../../../enum';


export function camelizar(texto: string): string {
    if (texto && texto.toUpperCase() === texto) {
        return texto.toLowerCase();
    }
    let textoFormatado = "";
    const regex = /(?:[^a-zA-Z]+)(ID|id)(?:[^a-zA-Z]+|$)|(?:[\s-]+)[a-z]{0,3}(?:\s+|$|-|([A-Z]+))|-|((?!^)[A-Z])/g;
    const grupos = ' $1$2$3';

    texto.replaceAll(regex, grupos).trim().split(/\s+/).forEach(parte => {
        if (parte.trim().length > 0) {
            textoFormatado += parte.charAt(0).toUpperCase() + parte.slice(1).toLowerCase();
        }
    });
    textoFormatado = textoFormatado.charAt(0).toLowerCase() + textoFormatado.slice(1);
    return textoFormatado
}



/*
* Tenta converter a string do formato camelCase para
* o um texto com espaços entre as palavras

* @param texto
* @returns {string}
*
* @example
* camelizar("cidade") // "Cidade"
* camelizar("nomeCidade") // "Nome Cidade"
* camelizar("cidadeIBGE") // "Cidade IBGE"
* camelizar("cidadeId") // "Cidade ID"
*/
export function desCamelizar(texto: string): string {
    let textoFormatado: string[] = [];
    const regex = /(?:\s*|^|([a-z]))(ID|id)(?:[\s-]+|$)|([A-Z]+)|(\s+)/g;
    const grupos = '$1 $2$3';

    texto.replaceAll(regex, grupos).trim().split(/\s+/).forEach(parte => {
        if (parte.trim().length > 0) {
            if (parte.toLowerCase() === "id") {
                textoFormatado.push("ID");
            } else {
                textoFormatado.push(parte.charAt(0).toUpperCase() + parte.slice(1))
            }
        }
    });
    return textoFormatado.join(" ");
}

export async function preencherListaCampo(campo: ICampo): Promise<ICampo> {
    let campoFormatado: ICampo = { ...campo };
    if (campo.tipo === "Lista" || campo.tipo === "ListaMulti") {
        const valores = await ConfiguracaoTelaService.obterValoresCampo(campo)
        campoFormatado.Lista = valores;
    }
    return campoFormatado
}


export function getEnumByName(enumName: string): any[] {
    let tipo: any;


    Object.keys(enums).forEach(key => {
        if (
            key === enumName ||
            key === `E${enumName}` ||
            key === `E${enumName}Enum`
        ) {
            tipo = (enums as any)[key];
        }
    })
    if (!tipo) {
        return [];
    }

    return Object.keys(tipo).filter((item) => {
        return isNaN(Number(item));
    }).map((item) => {
        return {
            label: item,
            value: tipo[item]
        }
    });
}

export function objectShape(shape: Modelo[]): any {

    return Object.keys(shape).map((key) => {
        return recuperarTipoCampo(shape[key as any]);
    });
}

export function recuperarTipoCampo(field: Modelo) {
    switch (field.campoNome.toLowerCase()) {
        case "quotasacoes":
            return Yup.number().lessThan((field as any).maxValue || 100, "O percentual da quota não pode exceder o limite disponível.");
        case "email":
            return Yup.string().email("Email inválido");
        case "cpf":
            return Yup.string().matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido").test(
                "CPF",
                "CPF inválido",
                (value) => {
                    if (!value) {
                        return !field.obrigatorio;
                    }
                    return validateCPF(value as string);
                }
            );
        case "cnpj":
            return Yup.string().matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, "CNPJ inválido").test(
                "CNPJ",
                "CNPJ inválido",
                (value) => {
                    if (!value) {
                        return !field.obrigatorio;
                    }
                    return validateCNPJ(value as string);
                }
            );
        case "cep":
            return Yup.string().matches(/^\d{5}-?\d{3}$/, "CEP inválido");
        case "telefone":
            return Yup.string().matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, "Telefone inválido");
        case "celular":
            return Yup.string().matches(/^\(?\d{2}\)? ?\d{5}-?\d{4}$/, "Celular inválido");
        default:
            break
    }

    if (field.camposExtras && field.camposExtras.length > 0) {
        let schema = Yup.object().shape({
            [camelizar(field.campoNome) + 'Id']: Yup.number().positive(),
            ...objectShape(field.camposExtras)
        })
        if (field.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || field.multiplo) {
            return Yup.array().of(
                schema
            )
        }
        return schema
    }

    if (field.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || field.multiplo) {
        return Yup.array().of(
            Yup.object().shape({
                [camelizar(field.campoNome) + 'Id']: Yup.number().positive(),
                ordem: Yup.number()
            })
        );
    }

    switch (field.tipo) {
        case ETipoCampoEnum.Lista as ETipoCampoEnum:
        case ETipoCampoEnum.Empresa as ETipoCampoEnum:
        case ETipoCampoEnum.Pessoa as ETipoCampoEnum:
            if (field.camposExtras && field.camposExtras.length > 0) {
                let schema = Yup.object().shape({
                    [camelizar(field.campoNome) + 'Id']: Yup.number().positive(),
                    ...objectShape(field.camposExtras)
                })
                return schema
            }
            else
                return Yup.number().positive();
        case ETipoCampoEnum.Data as ETipoCampoEnum:
            return Yup.date().nullable();
        case ETipoCampoEnum.Inteiro as ETipoCampoEnum:
        case ETipoCampoEnum.Documento as ETipoCampoEnum:
            return Yup.number().integer();
        case ETipoCampoEnum.Valor as ETipoCampoEnum:
            return Yup.number();
        case ETipoCampoEnum.Percentual as ETipoCampoEnum:
            return Yup.number();
        case ETipoCampoEnum.Texto as ETipoCampoEnum:
        case ETipoCampoEnum.EnumLista as ETipoCampoEnum:
            return Yup.string().nullable();
    }

    return Yup.string().nullable();
}

export function gerarEschemaValidacao(fields: Modelo[]): any {
    let schema: any = {};

    fields.forEach(field => {
        let fieldName = camelizar(field.campoNome);
        let campo = recuperarTipoCampo(field);
        if (campo._type === "string" && field.controller && field.controller.length > 0 && !fieldName.endsWith('Id')) {
            fieldName += 'Id';
        }
        if (field.obrigatorio) {
            campo = campo.required(`${field.label}: O campo ${field.label} é obrigatório.`);
        }
        schema[fieldName] = campo;
    });
    return Yup.object().shape(schema)
}

export function getCampoTipo(
    modelo: Modelo,
    campo: formik.FieldInputProps<any>,
    getClassName: (base?: string) => string,
    opcoes?: any[],
    handleSelectChange?: (value: any) => void,
) {
    switch (modelo.tipo) {
        case ETipoCampoEnum.Lista as ETipoCampoEnum:
        case ETipoCampoEnum.ListaMulti as ETipoCampoEnum:
        case ETipoCampoEnum.EnumLista as ETipoCampoEnum:
            return <Select
                defaultInputValue={campo.value}
                name={campo.name}
                placeholder={modelo.label}
                isClearable={true}
                backspaceRemovesValue={true}
                onBlur={campo.onBlur}
                onChange={handleSelectChange}
                loadingMessage={() => 'Carregando...'}
                isMulti={modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum}
                className={getClassName()}
                options={opcoes}
            />;
        case ETipoCampoEnum.AreaTexto as ETipoCampoEnum:
            return <textarea
                {...campo}
                onBlur={campo.onBlur}
                className={getClassName('form-control')}
            ></textarea>
        case ETipoCampoEnum.Valor as ETipoCampoEnum:
            return <MaskedInput
                className={getClassName('form-control')}
                name={campo.name}
                onBlur={campo.onBlur}
                value={campo.value || ''}
                onChange={campo.onChange}
                mask={createNumberMask({
                    prefix: 'R$ ',
                    suffix: '',
                    includeThousandsSeparator: false,
                    allowDecimal: true,
                    decimalSymbol: ',',
                    decimalLimit: 2,
                    integerLimit: 20,
                    allowLeadingZeroes: true
                }
                )}
            />;
        case ETipoCampoEnum.Percentual as ETipoCampoEnum:
            return <MaskedInput
                className={getClassName('form-control')}
                name={campo.name}
                onBlur={campo.onBlur}
                value={campo.value || ''}
                onChange={campo.onChange}
                mask={createNumberMask({
                    prefix: '% ',
                    suffix: '',
                    includeThousandsSeparator: false,
                    allowDecimal: true,
                    decimalSymbol: ',',
                    decimalLimit: 2,
                    integerLimit: 20,
                    allowLeadingZeroes: true
                }
                )}
            />;
        case ETipoCampoEnum.Documento as ETipoCampoEnum:
            return <MaskedInput
                className={getClassName('form-control')}
                name={campo.name}
                onBlur={campo.onBlur}
                value={campo.value || ''}
                onChange={campo.onChange}
                mask={modelo.campoNome === 'cpf' ? MASCARA_CPF : MASCARA_CNPJ}
            />;
        case ETipoCampoEnum.Data as ETipoCampoEnum:
            return <input
                {...campo}
                onBlur={campo.onBlur}
                className={getClassName('form-control')}
                type="date"
            />;
        case ETipoCampoEnum.Inteiro as ETipoCampoEnum:
            return <input
                {...campo}
                onBlur={campo.onBlur}
                className={getClassName('form-control')}
                type="number"
            />;
        case ETipoCampoEnum.Pessoa as ETipoCampoEnum:
            return <RecuperaPessoa
                tiposDocumento={['CPF']}
                titulo={' '}
                submitForm={function (values) {
                    alert(values)
                }}
                handlerPessoa={function (pessoa: IPessoa): void {
                    console.log(pessoa)
                }}
                handlerTipo={function (tipo: string): void {
                    alert(tipo)
                }}
                handlerNumeroDocumento={function (numeroDocumento: string): void {
                    alert(numeroDocumento)
                }}
            />
        default:
            return <input type="abc" {...campo} className={getClassName('form-control')} />
    }
}

export function gerarCampo(campo: ICampoDinamico): ICampo {
    let entidade = campo.controller || '';
    if (campo.tipo === ETipoCampoEnum.Pessoa) {
        entidade = 'Pessoa';
    }
    if (campo.tipo === ETipoCampoEnum.Empresa) {
        entidade = 'Empresa';
    }

    const campoGerado: ICampo = {
        campo: campo.campo,
        tipo: campo.tipo,
        nome: "",
        campoNome: campo.nome,
        entidade: entidade,
        label: desCamelizar(campo.nome),
        ordenacao: 0,
        obrigatorio: false,
        somenteLeitura: false,
        help: "",
        isXCampo: false
    }

    return campoGerado;
}