import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoLicenca from "../interfaces/ITipoLicenca";
import { AdicionaTipoLicencaRequest } from "../interfaces/Requests/TipoLicenca/AdicionaTipoLicencaRequest";
import FiltroPesquisaTipoLicencaRequest from "../interfaces/Requests/TipoLicenca/FiltroPesquisaTipoLicencaRequest";
import { EditarTipoLicencaRequest } from "../interfaces/Requests/TipoLicenca/EditarTipoLicencaRequest";

class TipoLicencaService {
  async obterTipoLicencas(
    filtros: FiltroPesquisaTipoLicencaRequest
  ): Promise<IRecuperaLista<ITipoLicenca>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ITipoLicenca>>(
      `TipoLicenca/RecuperaTipoLicenca?${query}${limit}`
    );

    return result;
  }

  async adicionaTipoLicenca(
    adicionaTipoLicenca: AdicionaTipoLicencaRequest
  ): Promise<ITipoLicenca> {
    let { data: result } = await api.post<ITipoLicenca>(
      `TipoLicenca/AdicionaTipoLicenca`,
      adicionaTipoLicenca
    );

    return result;
  }

  async alterarStatus(tipoLicencaId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ITipoLicenca>(
      `TipoLicenca/AtualizaTipoLicencaPorId?id=${tipoLicencaId}`,
      data
    );
  }

  async atualizarTipoLicenca(
    editarTipoLicenca: EditarTipoLicencaRequest
  ): Promise<void> {
    let dataTipoLicenca = [
      { op: "replace", path: "/nome", value: editarTipoLicenca.nome },
      { op: "replace", path: "/codigo", value: editarTipoLicenca.codigo },
    ];

    await Promise.all([
      await api.patch<void>(
        `TipoLicenca/AtualizaTipoLicencaPorId?id=${editarTipoLicenca.tipoLicencaId}`,
        dataTipoLicenca
      ),
    ]);
  }
}

export default new TipoLicencaService();
