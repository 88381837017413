import { useEffect, useState } from "react";
import EnvelopeService from "../../../services/EnvelopeService";
import DashboardService from "../../../services/DashboardService";
import FiltroPesquisaEnvelopeRequest from "../../../interfaces/Requests/FiltroPesquisaEnvelopeRequest";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IEnvelope from "../../../interfaces/IEnvelope";
import Swal from "sweetalert2";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import Dashboard, { TimeDelta} from "../../../components/dashboard";
import { EstatisticasContextoProvider } from "../../../components/dashboard/statistics";
import EnvelopeEtatisticas from "../../../components/dashboard/statistics/EnvelopeEtatisticas";
import * as constants from './EnvelopeDashboardConstantes';
import { IWidgetsTamanhos } from "../../../components/dashboard/widgets/interfaces/IWidget";
import IDashboardWidget from "../../../interfaces/IDashboardWidget";
import IDashboard from "../../../interfaces/IDashboard";
import { AxiosError } from "axios";


const DASHBOARDID = 1;


const EnvelopeDashboardPage = () => {
    const [carregandoEnvelopes, setCarregandoEnvelopes] = useState(true);

    const [estatisticas] = useState(new EnvelopeEtatisticas([]));
    const [relatorioId, setRelatorioId] = useState<number | null>(null);
    const [dashboardId, setDashboardId] = useState(0);


    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEnvelopeRequest>({
        codigo: '',
        envelopeId: [],
        clienteId: 0,
        nomeDocumento: '',
        usuarioIdCadastro: 0,
        dataAssinaturaMaiorIgual: '',
        dataAssinaturaMenorIgual: '',
        dataCanceladoMaiorIgual: '',
        dataCanceladoMenorIgual: '',
        dataCadastroMaiorIgual: dataInicialPadrao(),
        dataCadastroMenorIgual: new Date().toISOString(),
        status: 0,
        limit: 100000,
        totalItems: 0,
        offset: 0,
        sort: 'dataCadastro'
    });

    const [widgetOrdem, setWidgetOrdem] = useState<string[]>([
        'Envelopes - Dia',
        'Quantidade Por Status - Período',
        'Envelopes - Mês',
        'Envelopes Assinados - Dia',
        'Envelopes Assinados - Mês',
        'Tempo Médio de Espera  - Dia',
        'Tempo Médio de Espera - Mês'
    ]);
    const [widgetTamanhos, setWidgetTamanho] = useState<IWidgetsTamanhos[]>([
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '3'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '4'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '4'
        },
        {
            xs: '12',
            sm: '12',
            md: '6',
            lg: '6',
            xl: '4'
        },
    ]);

    async function salvar(widgets: IDashboardWidget[]) {
        Swal.fire({
            title: 'Salvando...',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false        
        });
        Swal.showLoading();
        try{
            let resultado: IDashboard;
            if(dashboardId === 0){
                resultado = await DashboardService.adicionarDashboard({
                    dashboard: DASHBOARDID,
                    widgets: widgets
                } as IDashboard);
            }else{
                resultado = await DashboardService.salvarDashboard({
                    widgetPreferenciasId: dashboardId,
                    dashboard: DASHBOARDID,
                    widgets: widgets
                });
            }
            Swal.fire({
                title: 'Salvo!',
                text: 'Os widgets foram salvos com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
            
            setDashboardId(resultado.widgetPreferenciasId as number);
            setWidgetOrdem(resultado.widgets.map(w=>w.nome));
            setWidgetTamanho(resultado.widgets.map(w=>w as IWidgetsTamanhos));
        }catch(err){
            const error = err as AxiosError
            Swal.fire({
                title: 'Erro!',
                text: (error && error.response && error.response.data && typeof error.response.data === 'string' ? error.response.data : error.response?.data.message) || 'Ocorreu um erro ao salvar os widgets!',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
                allowEnterKey: true,
                allowEscapeKey: true,
                allowOutsideClick: true   
            });
            throw(error);
        }
    }

    
    async function getWidgets(): Promise<void> {
        try{
            const dashboardConfig = await DashboardService.recuperarDashboard(DASHBOARDID);

            setWidgetOrdem(dashboardConfig.widgets.map(widget => widget.nome));
            setDashboardId(dashboardConfig.widgetPreferenciasId || 0);
            setWidgetTamanho(dashboardConfig.widgets.map(widget => {
                return {
                    xl: widget.xl,
                    lg: widget.lg,
                    md: widget.md,
                    sm: widget.sm,
                    xs: widget.xs
                }
            }));
            
        } catch(e){
            console.log(e)
        }
    }

    function dataInicialPadrao(): string {
        const hoje = new Date();
        const dt = new TimeDelta({months: -hoje.getMonth(), days: 1 - hoje.getDate()});
        return dt.addToDate(hoje).toISOString();
    }

    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                codigo: '',
                envelopeId: [],        
                nomeDocumento: '',
                usuarioIdCadastro: 0,
                dataAssinaturaMaiorIgual: '',
                dataAssinaturaMenorIgual: '',
                dataCanceladoMaiorIgual: '',
                dataCanceladoMenorIgual: '',
                dataCadastroMaiorIgual: '',
                dataCadastroMenorIgual: '',
                status: 0,
            }
        });
    }

    async function baixarRelatorio(): Promise<void>{
        if(!relatorioId) return;

        if(relatorioId === 0 || relatorioId === null){
            await Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `A pesquisa não retornou nenhum resultado`,
                showConfirmButton: true,
                timer: 4000
            });
            return;
        }

        Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Baixando arquivos...',
            showConfirmButton: false,
        });

        Swal.showLoading();

        try{
            const arquivo = await EnvelopeService.gerarExcel(relatorioId);
            if (arquivo) {
                const url = window.URL.createObjectURL(arquivo);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${new Date().toLocaleString('BR')} - Envelopes.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            Swal.hideLoading();
            Swal.update({
                title: 'Baixado com sucesso',
                text: '',
                icon: 'success',
                showConfirmButton: true
            });

        }catch(err){
            const error = err as AxiosError
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível baixar arquivos',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false
            });
        }
    }

    async function carregarEnvelopes(filtro: FiltroPesquisaEnvelopeRequest): Promise<void> {
        await estatisticas.atualiza(async ()=>{
            try {
                    setCarregandoEnvelopes(true);
                    let resultado: IRecuperaLista<IEnvelope>;

                    resultado = await EnvelopeService.obterEnvelopes(filtro);
                    setRelatorioId(resultado.relatorioId || null);

                    setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
                    setCarregandoEnvelopes(false);
                    return resultado.data;
            } catch (error) {
                setRelatorioId(null);
                return [];
            }
            finally {
                setCarregandoEnvelopes(false);
            }
        });
    }

    useEffect(() => {
        getWidgets();
        carregarEnvelopes(filtrosPesquisa);
    }, []);

    return <>
        <LayoutPageTitle title="Envelopes - Dashboard"/>

        <div className="row mt-2">
            <div className="col-md-12">
                <div className="col-md-12" >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        carregarEnvelopes(filtrosPesquisa);
                    }} className="row g-3 mb-3 form-buscar-envelope">
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro</label>
                            <DateRangePicker
                                className="form-control p-0"
                                calendarIcon={null}
                                showLeadingZeros={true}
                                maxDate={new Date()}
                                value={
                                    (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual)?
                                        [
                                            filtrosPesquisa.dataCadastroMaiorIgual,
                                            filtrosPesquisa.dataCadastroMenorIgual,
                                        ]:''
                                }
                                onChange={(datas:Date[])=>{
                                    if(!datas) {
                                        setFiltrosPesquisa(oldValue => {
                                            return {
                                                ...oldValue,
                                                dataCadastroMaiorIgual:  '',
                                                dataCadastroMenorIgual:  '',
                                            }
                                        });
                                        return 
                                    }

                                    let data_inicial = datas[0];
                                    let data_final = datas[1];
                                    if(data_final){
                                        data_final.setHours(0,0,0,0);
                                        data_final = new Date(data_final.getTime() - 1)
                                    }
                                    setFiltrosPesquisa(oldValue => {
                                        
                                        return {
                                            ...oldValue,
                                            dataCadastroMaiorIgual:  (data_inicial)?`${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`:'',
                                            dataCadastroMenorIgual:  (data_final)?`${data_final.toISOString().split('T')[0]}T23:59:59.9999`:'',
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">Data da Assinatura</label>
                            <DateRangePicker
                                className="form-control p-0"
                                calendarIcon={null}
                                showLeadingZeros={true}
                                maxDate={new Date()}
                                value={
                                    (filtrosPesquisa.dataAssinaturaMaiorIgual && filtrosPesquisa.dataAssinaturaMenorIgual)?
                                        [
                                            filtrosPesquisa.dataAssinaturaMaiorIgual,
                                            filtrosPesquisa.dataAssinaturaMenorIgual,
                                        ]:''
                                }
                                onChange={(datas: Date[])=>{
                                    if(!datas) {
                                        setFiltrosPesquisa(oldValue => {
                                            return {
                                                ...oldValue,
                                                dataAssinaturaMaiorIgual:  '',
                                                dataAssinaturaMenorIgual:  '',
                                            }
                                        });
                                        return 
                                    }
                                    let data_inicial = datas[0];
                                    let data_final = datas[1];
                                    if(data_final){
                                        data_final.setHours(0,0,0,0);
                                        data_final = new Date(data_final.getTime() - 1)
                                    }

                                    setFiltrosPesquisa(oldValue => {
                                        
                                        return {
                                            ...oldValue,
                                            dataAssinaturaMaiorIgual: (data_inicial)?`${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`:'',
                                            dataAssinaturaMenorIgual: (data_final)?`${data_final.toISOString().split('T')[0]}T23:59:59.9999`:'',
                                        }
                                    })
                                    
                                }}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">Data do Cancelamento</label>
                            <DateRangePicker
                                className="form-control p-0"
                                calendarIcon={null}
                                showLeadingZeros={true}
                                maxDate={new Date()}
                                value={
                                    (filtrosPesquisa.dataCanceladoMaiorIgual && filtrosPesquisa.dataCanceladoMenorIgual)?
                                        [
                                            filtrosPesquisa.dataCanceladoMaiorIgual,
                                            filtrosPesquisa.dataCanceladoMenorIgual,
                                        ]:''
                                }
                                onChange={(datas: Date[])=>{
                                    if(!datas) {
                                        setFiltrosPesquisa(oldValue => {
                                            return {
                                                ...oldValue,
                                                dataCanceladoMaiorIgual:  '',
                                                dataCanceladoMenorIgual:  '',
                                            }
                                        });
                                        return 
                                    }


                                    let data_inicial = datas[0];
                                    let data_final = datas[1];
                                    if(data_final){
                                        data_final.setHours(0,0,0,0);
                                        data_final = new Date(data_final.getTime() - 1)
                                    }
                                    
                                    setFiltrosPesquisa(oldValue => {
                                        return {
                                            ...oldValue,
                                            dataCanceladoMaiorIgual: (data_inicial)?`${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`:'',
                                            dataCanceladoMenorIgual: (data_final)?`${data_final.toISOString().split('T')[0]}T23:59:59.9999`:'',
                                        }
                                    })
                                    
                                }}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código</label>
                            <input value={filtrosPesquisa.codigo} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, codigo: e.target.value } });
                            }} placeholder="Código" className={'form-control'} id="form-codigo" />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-status" className="form-label fw-bolder text-orange">Status</label>
                            <select value={filtrosPesquisa.status} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                            }} placeholder="Status" className={'form-select'} id="form-status" >
                                <option value={0}>Todos</option>
                                <option value={1}>Em Confecção</option>
                                <option value={2}>Aguardando Signatários</option>
                                <option value={3}>Assinado</option>
                                <option value={-1}>Cancelado</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-nome-documento" className="form-label fw-bolder text-orange">Nome do Documento</label>
                            <input value={filtrosPesquisa.nomeDocumento} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, nomeDocumento: e.target.value } });
                            }} placeholder="Nome do Documento" className={'form-control'} id="form-nome-documento" />
                        </div>
                        <div className="col-12 "
                        style={{textAlign: 'right'}}>
                            <button
                                type="submit"
                                className="btn btn-orange search-buttom-margin "
                                title="Buscar"
                            >
                                <FontAwesomeIcon color='white' className='' icon={faSearch} />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    limparFiltros();
                                }}
                                className="btn btn-orange search-buttom-margin ms-2"
                                title="Limpar Filtro"
                            >
                                <FontAwesomeIcon color='white' className='' icon={faEraser} />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    baixarRelatorio();
                                }}
                                className="btn btn-orange search-buttom-margin ms-2"
                                title="Baixar Relatório"
                                disabled={relatorioId === null || carregandoEnvelopes}
                            >
                                <FontAwesomeIcon color='white' className='' icon={faFileExcel} />
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 mb-10">
                    {estatisticas &&
                        <EstatisticasContextoProvider
                            estatisticas={estatisticas}
                        >
                            <Dashboard
                                widgets={constants.WIDGETS}
                                widgetOrdens={widgetOrdem}
                                widgetTamanhos={widgetTamanhos}
                                carregando={carregandoEnvelopes}
                                dashboadID={DASHBOARDID}
                                onDashboardChanged={getWidgets}
                                salvar={salvar}
                            />
                        </EstatisticasContextoProvider>
                    }
                </div>
            </div>
        </div >
    </>;
}

export default EnvelopeDashboardPage;