import { Draggable } from "react-beautiful-dnd";
import { ModuloType } from "../PerfilPermissoesPageEdit";

interface IModuloProps {
  modulo: ModuloType;
  index: number;
  onModuloClick: (modulo: ModuloType) => void;
}

const Modulo = ({ modulo, index, onModuloClick }: IModuloProps) => {
  return (
    <Draggable draggableId={modulo.modulo.toString()} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={() => onModuloClick(modulo)}
        >
          <div
            style={{
              border: "1px solid var(--primary-base2)",
              backgroundColor: "var(--primary-base2)",
              borderRadius: 4,
              padding: 8,
              marginBottom: 10,
              color: "white",
              fontWeight: "bold",
            }}
          >
            {modulo.moduloNome}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Modulo;
