import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusLicenca from "../interfaces/IStatusLicenca";
import { AdicionaStatusLicencaRequest } from "../interfaces/Requests/StatusLicenca/AdicionaStatusLicencaRequest";
import { EditarStatusLicencaRequest } from "../interfaces/Requests/StatusLicenca/EditarStatusLicencaRequest";
import FiltroPesquisaStatusLicencaRequest from "../interfaces/Requests/StatusLicenca/FiltroPesquisaStatusLicencaRequest";

class StatusLicencaService {

    async obterStatusLicencas(filtros: FiltroPesquisaStatusLicencaRequest): Promise<IRecuperaLista<IStatusLicenca>> {
        let query = `${'status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusLicenca>>(`StatusLicenca/RecuperaStatusLicenca?${query}`);

        return result;
    }

    async adicionaStatusLicenca(adicionaStatusLicenca: AdicionaStatusLicencaRequest): Promise<IStatusLicenca> {
        let { data: result } = await api.post<IStatusLicenca>(`StatusLicenca/AdicionaStatusLicenca`, adicionaStatusLicenca);

        return result;
    }


    async alterarStatus(statusLicencaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusLicenca>(`StatusLicenca/AtualizaStatusLicencaPorId?id=${statusLicencaId}`, data);
    }

    async atualizarStatusLicenca(editarStatusLicenca: EditarStatusLicencaRequest): Promise<void> {

        let dataStatusLicenca = [
            { "op": "replace", "path": "/nome", "value": editarStatusLicenca.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusLicenca.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusLicenca/AtualizaStatusLicencaPorId?id=${editarStatusLicenca.statusLicencaId}`, dataStatusLicenca)
        ]);
    }
}


export default new StatusLicencaService();