import { useMutation, useQueryClient } from "react-query";
import EditorService, {
  EditorCommentSuccessResponseStatus,
} from "../../../services/EditorService";
import {
  AddComment,
  CommentThread,
  DeleteComment,
  EditComment,
  ReplyComment,
} from "../types";
import { queries } from "../queries";
import { toast } from "react-toastify";
import React, { createContext, useContext, useState } from "react";

interface ThreadQuery {
  thread: CommentThread[] | undefined;
}

export function useThread() {
  const context = useContext(ThreadContext);
  if (!context) {
    throw new Error("useThread must be wrapped with ThreadProvider");
  }
  return context;
}

const ThreadContext = createContext(
  {} as {
    thread: {
      close: (threadId: number) => Promise<void>;
      remove: (threadId: number) => Promise<void>;
      selectedThread: CommentThread | null;
      setSelectedThread: React.Dispatch<
        React.SetStateAction<CommentThread | null>
      >;
    };
    comment: {
      add: (params: AddComment) => Promise<void>;
      delete: (params: DeleteComment) => Promise<void>;
      edit: (params: EditComment) => Promise<void>;
      reply: (params: ReplyComment) => Promise<void>;
    };
  }
);

export function ThreadProvider({ children }: { children: React.ReactNode }) {
  const { threads: threadsQuery } = queries;
  const queryClient = useQueryClient();
  const [selectedThread, setSelectedThread] = useState<CommentThread | null>(
    null
  );

  function showSuccess(message: string) {
    toast.success(message, { position: 'bottom-right' });
  }

  function handleError(error: Error) {
    const message = error?.message ?? "Erro inesperado, contate o suporte!";
    toast.error(message);
  }

  const updateThreadsOnDelete = (
    threads: ThreadQuery,
    { threadId, isAwnser, id }: DeleteComment
  ) => {
    const selectedThread = threads?.thread?.find(
      (thread) => thread.comentarioPai.editorDocumentoComentariosId === threadId
    );

    if (!selectedThread) {
      return threads.thread;
    }

    if (isAwnser) {
      const updatedThread = {
        ...selectedThread,
        respostas: selectedThread.respostas.filter(
          (answer) => answer.editorDocumentoComentariosId !== id
        ),
      };

      return threads?.thread?.map((thread) =>
        thread.comentarioPai.editorDocumentoComentariosId === threadId
          ? updatedThread
          : thread
      );
    } else {
      return threads?.thread?.filter(
        (thread) => thread.comentarioPai.editorDocumentoComentariosId !== id
      );
    }
  };

  const updateThreadsOnEdit = (
    threads: ThreadQuery,
    { threadId, comment, id, isAwnser }: EditComment
  ) => {
    const selectedThread = threads?.thread?.find(
      (thread) => thread.comentarioPai.editorDocumentoComentariosId === threadId
    );

    if (!selectedThread) {
      return threads.thread;
    }

    let updatedThread = selectedThread;

    if (isAwnser) {
      updatedThread = {
        ...updatedThread,
        respostas: updatedThread.respostas.map((answer) => {
          if (answer.editorDocumentoComentariosId === id) {
            return {
              ...answer,
              comentario: comment,
            };
          }
          return answer;
        }),
      };
    } else {
      updatedThread = {
        ...updatedThread,
        comentarioPai: {
          ...updatedThread.comentarioPai,
          comentario: comment,
        },
      };
    }

    return threads?.thread?.map((thread) =>
      thread.comentarioPai.editorDocumentoComentariosId ===
      updatedThread.comentarioPai.editorDocumentoComentariosId
        ? updatedThread
        : thread
    );
  };

  const { mutateAsync: closeThreadFn } = useMutation({
    mutationFn: EditorService.closeThread,
    onError: handleError,
    onSuccess: (_, { id }) => {
      queryClient.setQueriesData<ThreadQuery>(threadsQuery, (threads) => ({
        thread: threads?.thread?.filter(
          (thread) => thread.comentarioPai.editorDocumentoComentariosId !== id
        ),
      }));

      showSuccess("Discussão finalizada com sucesso!");
    },
  });

  const { mutateAsync: addCommentFn } = useMutation({
    mutationFn: EditorService.addComment,
    onError: handleError,
    onSuccess: (data, { path }) => {
      const thread: CommentThread = {
        comentarioPai: data,
        path,
        respostas: [],
      };

      queryClient.setQueriesData<ThreadQuery>(threadsQuery, (threads) => ({
        thread: [...(threads?.thread ?? []), thread],
      }));

      // setSelectedThread(thread);
      showSuccess("Comentário adicionado com sucesso!");
    },
  });

  const { mutateAsync: deleteThreadFn } = useMutation({
    mutationFn: EditorService.deleteThread,
    onError: handleError,
    onSuccess: (_, { id }) => {
      queryClient.setQueriesData<ThreadQuery>(threadsQuery, (threads) => ({
        thread: threads?.thread?.filter(
          (thread) => thread.comentarioPai.editorDocumentoComentariosId !== id
        ),
      }));
      showSuccess("Discussão excluída com sucesso!");
    },
  });

  const { mutateAsync: deleteCommentFn } = useMutation({
    mutationFn: EditorService.deleteComment,
    onError: handleError,
    onSuccess: (_, params) => {
      queryClient.setQueriesData<ThreadQuery>(threadsQuery, (threads) => {
        if (!threads) {
          return { thread: undefined };
        }
        return { thread: updateThreadsOnDelete(threads, params) };
      });
      showSuccess("Comentário excluído com sucesso!");
    },
  });

  const { mutateAsync: editCommentFn } = useMutation({
    mutationFn: EditorService.editComment,
    onError: handleError,
    onSuccess: (status, params) => {
      queryClient.setQueriesData<ThreadQuery>(threadsQuery, (threads) => {
        if (status !== EditorCommentSuccessResponseStatus.FINISHED) {
          return { thread: threads?.thread };
        }

        if (!threads) {
          return { thread: undefined };
        }

        return { thread: updateThreadsOnEdit(threads, params) };
      });

      showSuccess("Comentário editado com sucesso!");
    },
  });

  const { mutateAsync: replyFn } = useMutation({
    mutationFn: EditorService.addReply,
    onError: handleError,
    onSuccess: (newComment, { parentId }) => {
      queryClient.setQueriesData<ThreadQuery>(threadsQuery, (threads) => {
        const currentThread = threads?.thread?.find(
          (p) => p.comentarioPai.editorDocumentoComentariosId === parentId
        );

        if (!currentThread) {
          return {
            thread: threads?.thread,
          };
        }

        return {
          thread: threads?.thread?.map((thread) => {
            if (
              thread.comentarioPai.editorDocumentoComentariosId ===
              currentThread.comentarioPai.editorDocumentoComentariosId
            ) {
              const updatedThread = {
                ...currentThread,
                respostas: [...currentThread.respostas, newComment],
              };

              return updatedThread;
            }

            return thread;
          }),
        };
      });

      showSuccess("Resposta enviada com sucesso!");
    },
  });

  async function close(threadId: number) {
    await closeThreadFn({ id: threadId });
  }

  async function remove(threadId: number) {
    await deleteThreadFn({ id: threadId });
  }

  async function comment(params: AddComment) {
    await addCommentFn(params);
  }

  async function deleteComment(params: DeleteComment) {
    await deleteCommentFn(params);
  }

  async function editComment(params: EditComment) {
    await editCommentFn(params);
  }

  async function reply(params: ReplyComment) {
    await replyFn(params);
  }

  return (
    <ThreadContext.Provider
      value={{
        thread: {
          close,
          remove,
          selectedThread,
          setSelectedThread,
        },
        comment: {
          add: comment,
          delete: deleteComment,
          edit: editComment,
          reply,
        },
      }}
    >
      {children}
    </ThreadContext.Provider>
  );
}
