enum ENodeEnum {

    NodeFim = "NodeFim",
    NodeFase = "NodeFase",
    NodeRaia = "NodeRaia",
    NodeInicio = "NodeInicio",
    NodeCondicao = "NodeCondicao",

  }
  
  export default ENodeEnum;
  