import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment"

import { EModulo } from "../../../../../enum"
import { showErrorMessage } from '../../../utils';

import { IRepresentanteEmpresa } from '../Interfaces';
import { ICampo } from "../../../../../interfaces/ICampo";
import { IConfiguracaoTela } from "../../../../../interfaces/IConfiguracaoTela";
import { OrangeInterfaceContext } from "../../../../../interfaces/Contexts/OrangeInterfaceContext";

import EmpresaService from "../../../../../services/EmpresaService"

import TelaCadastroSolucao from "../../../componentes/TelaCadastroSolucao";
import { OrangeContext } from "../../../../../contexts/OrangeProvider";
import { EmpresaSolucaoLayout } from "../../../componentes/EmpresaSolucaoLayout";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { modelo } from "./RepresentantesCampos";

interface FiltroPesquisaEmpresaRepresentante {
  nome: string
}

export const RepresentantesPage = () => {
  const { setEmpresa, empresa } = useContext<OrangeInterfaceContext>(OrangeContext)
  const [pesquisar, setPesquisar] = useState(false);
  const [dados, setDados] = useState<any[]>([]);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEmpresaRepresentante>({
    nome: '',
  });

  const modalPessoa = useDisclosure(false);

  const empresaTelaCadastro: IConfiguracaoTela = {
    modulo: EModulo.Societario,
    tela: 3,
    camposList: [],
  }

  const tela = {
    ...empresaTelaCadastro,
    camposList: [modelo] as ICampo[],
  }

  const carregarEmpresa = async () => {
    try {

      const resultado = await EmpresaService.obterDadosEmpresa(empresa.empresaId);
      
      setEmpresa(resultado.data[0]);

      limpaFiltros();

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ops..`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        showConfirmButton: true,
        timer: 3000,
      });
    }
  }

  const toggleModal = (value?: boolean) => {
    if (value !== undefined) {
      if (value === true) {
        modalPessoa.handleOpen()
      }
      if (value === false) {
        modalPessoa.handleClose()
      }
    } else {
      modalPessoa.handleToggle()
    }
  }

  const adicionar = async (values: any) => {

    try {
      const swalConfirm = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalConfirm.fire({
        title: "Salvar Representante",
        text: `Você realmente deseja salvar o Representante?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          icon: "info",
          title: "Realizando operação. Aguarde...",
          showConfirmButton: false,
        });
        Swal.showLoading()
        values.empresaId = empresa.empresaId
        const resultado = await EmpresaService.adicionaRepresentante(empresa.empresaId, values);
        // const resultado = await EmpresaService.adicionarAtosSocietarios(values)
        if (resultado) {
          await carregarEmpresa();

          Swal.fire({
            title: 'Sucesso',
            text: 'Representante cadastrado com sucesso.',
            icon: 'success',
            showConfirmButton: true,
            timer: 3000,
          })
          return true
        }
      }
    } catch (error: any) {
      Swal.fire({
        title: 'Operação não realizada!',
        icon: 'error',
        text: showErrorMessage(error),
        showConfirmButton: true,
        timer: 3000,
      })
    } finally {
      Swal.hideLoading()
    }
    return false
  }

  const alterarStatus = async (values: any) => {
    try {
      const swalConfirm = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalConfirm.fire({
        title: "Deletar Representante ",
        text: `Você realmente deseja deletar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        // setCarregando(true);
        Swal.fire({
          heightAuto: false,
          icon: "info",
          title: "Realizando operação. Aguarde...",
          showConfirmButton: false,
        });
        Swal.showLoading();
        await EmpresaService.removeRepresentante(values.representanteId);
        await carregarEmpresa();
        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Representante deletado com sucesso`,
          showConfirmButton: true,
          timer: 3000,
        });
      }
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        title: 'Operação não realizada!',
        text: showErrorMessage(error),
        showConfirmButton: true,
        timer: 3000,
      });
    } finally {
      Swal.hideLoading();
    }
  }

  const editar = async (values: any) => {
    try {
      const swalConfirm = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-orange',
          cancelButton: 'btn btn-danger ms-5',
        },
        buttonsStyling: false,
      })

      const result = await swalConfirm.fire({
        title: 'Atualizar Representante',
        text: 'Você deseja atualizar o representante?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      });

      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          icon: "info",
          title: 'Realizando Operação, Aguarde...',
          showConfirmButton: false,

        })
        Swal.showLoading();
        await EmpresaService.alterarRepresentante(values);
        await carregarEmpresa()

        Swal.fire({
          heightAuto: false,
          title: 'Sucesso',
          text: 'Representante atualizado com sucesso.',
          icon: 'success',
          showConfirmButton: true,
          timer: 3000,
        })
      }

    } catch (error: any) {
      Swal.fire({
        title: 'Operação não realizada!',
        icon: 'error',
        text: showErrorMessage(error),
        showConfirmButton: true,
        timer: 3000,
      })
    } finally {
      Swal.hideLoading()
    }
  }

  const colunas: TableColumn<IRepresentanteEmpresa>[] = [
    {
      name: "Id",
      sortField: "pessoaId",
      selector: (row) => row.pessoaId || '',
      sortable: true,
      wrap: false,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row) => row.nome || '',
      sortable: true,
      wrap: true,
    },
    {
      name: "E-mail",
      sortField: "email",
      selector: (row) => row.email || '',
      sortable: true,
      wrap: false,
    },
    {
      name: "Entrada",
      sortField: "dataEntrada",
      selector: (row) => row.dataEntrada ? moment(row.dataEntrada).format('DD/MM/YYYY') : '',
      sortable: true,
      wrap: true,
    },
    {
      name: "Vencimento Mandato",
      sortField: "dataVencimentoMandato",
      selector: (row) => row.dataVencimentoMandato ? moment(row.dataVencimentoMandato).format('DD/MM/YYYY') : '',
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Saída",
      sortField: "dataSaida",
      selector: (row) => row.dataSaida ? moment(row.dataSaida).format('DD/MM/YYYY') : '',
      sortable: true,
      wrap: true,
    },

    {
      name: "Pode Assinar?",
      sortField: "podeAssinar",
      selector: (row) => row?.podeAssinar ? "Sim" : 'Não',
      sortable: true,
      wrap: true,
    },
  ];

  const botao = (
    <button onClick={() => { modalPessoa.handleOpen(); }} className="btn btn-md btn-orange" >
      Novo
      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
    </button>
  )

  const carregarRepresentantes = (filtroPesquisa: FiltroPesquisaEmpresaRepresentante) => {
    let filtro: Partial<IRepresentanteEmpresa[]> = [];
    if (filtroPesquisa.nome) {
      filtro = empresa.representantes.filter(representante => representante.nome.includes(filtroPesquisa.nome));
    } else {
      filtro = empresa.representantes
    }

    if (filtro.length === 0) {
      setDados(() => empresa.representantes);
      setPesquisar(false);
    } else {
      setDados(() => filtro);
      setPesquisar(false);

    }
  }

  useEffect(() => {
    if (pesquisar === true) {
      carregarRepresentantes(filtrosPesquisa);
    }
  }, [pesquisar])

  const limpaFiltros = () => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, nome: '' };
    });
    setPesquisar(true);
  }

  return (
    <EmpresaSolucaoLayout title="Representantes" botaoAdicionar={botao} loading={false}>
      <>
        <div style={{ height: '89%' }} className="d-flex justify-content-between flex-wrap align-items-start">

          <div className="col-md-12 mt-3">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row mt-3">

                <div className="col-12 col-md-4 col-xl-4">
                  <label htmlFor="form-razaoSocial" className="form-label fw-bolder text-orange">
                    Nome:
                  </label>
                  <input
                    value={filtrosPesquisa.nome}
                    onChange={(e) => {
                      setFiltrosPesquisa((oldState) => {
                        return { ...oldState, nome: e.target.value };
                      });
                    }}
                    placeholder="Nome"
                    type="text"
                    className={"form-control"}
                    id="form-razaoSocial"
                  />
                </div>

                <div className="col-12 col-md-12 col-xl-12 text-end">

                  <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                    <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                  </button>
                  <button
                    onClick={() => {
                      limpaFiltros()
                    }}
                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                  </button>

                </div>

              </div>
            </form>

          </div>

          <TelaCadastroSolucao
            modelo={modelo}
            colunasDoGrid={colunas}
            dados={dados}
            exibirModal={modalPessoa.isOpen}
            toggleModal={toggleModal}
            adicionar={adicionar}
            alterarStatus={alterarStatus}
            editar={editar}
            tela={tela}
          />

        </div>
      </>
    </EmpresaSolucaoLayout>

  )
}