import { EModulo, ETipoCampoEnum } from "../enum";
import IReembolso from "../interfaces/IReembolso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { AdicionaReembolsoRequest } from "../interfaces/Requests/Reembolso/AdicionaReembolsoRequest";
import FiltroPesquisaReembolsoRequest from "../interfaces/Requests/Reembolso/FiltroPesquisaReembolsoRequest";
import api from "./Api";

class ReembolsoService {

  public async obterReembolsos(filtros: FiltroPesquisaReembolsoRequest): Promise<IRecuperaLista<any>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

    if (filtros.statusId && filtros.statusId.length > 0) {
      query += `&statusId=${filtros.statusId}`;
    }

    if (filtros.processoId) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.reembolsoId) {
      query += `&reembolsoId=${filtros.reembolsoId}`;
    }

    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }

    if (filtros.CompetenciaMaiorIgual) {
      query += `&CompetenciaMaiorIgual=${filtros.CompetenciaMaiorIgual}`;
    }

    if (filtros.CompetenciaMenorIgual) {
      query += `&CompetenciaMenorIgual=${filtros.CompetenciaMenorIgual}`;
    }

    if (filtros.tipoReembolsoId) {
      query += `&tipoReembolsoId=${filtros.tipoReembolsoId}`;
    }

    if (filtros.contratoId) {
      query += `&contratoId=${filtros.contratoId}`;
    }

    if (filtros.consultaId) {
      query += `&consultaId=${filtros.consultaId}`;
    }

    if (filtros.escritorioId) {
      query += `&escritorioId=${filtros.escritorioId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(`Reembolso/RecuperaReembolso?${query}`);

    return result;
  }

  public async obterReembolsoPorId(reembolsoId: number): Promise<IRecuperaLista<IReembolso>> {

    let { data: result } = await api.get<IRecuperaLista<IReembolso>>(`Reembolso/RecuperaReembolso?reembolsoId=${reembolsoId}`);

    return result;
  }

  public async adicionaReembolso(adicionaReembolso: AdicionaReembolsoRequest): Promise<IReembolso> {
    let { data: result } = await api.post<IReembolso>(`Reembolso/AdicionaReembolso`, this.montarObjetoRequest(adicionaReembolso));

    return result;
  }

  public async editarReembolso(editarReembolso: AdicionaReembolsoRequest) {
    let request = this.montarObjetoArray(editarReembolso);
    if (request.length > 0) await api.patch(`Reembolso/AtualizaReembolsoPorId?id=${editarReembolso.reembolsoId}`, request);
  }

  public async alterarStatus(reembolsoId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<IReembolso>(`Reembolso/AtualizaReembolsoPorId?id=${reembolsoId}`, data);
  }

  public async obterCapaReembolso(reembolsoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Reembolso/RecuperaCapaReembolso?reembolsoId=${reembolsoId}`
    );

    return result;
  }

  public async alterarReembolsoPorCampo(campo: string, valor: any, reembolsoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<any>(`Reembolso/AtualizaReembolsoPorId?id=${reembolsoId}`, request);
    }
  }

  public async alterarReembolsoPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<any>(`Reembolso/AtualizaXCampoValor?id=${xcampoId}`, request);
    }
  }

  public async alterarReembolsoPorCampoBolleano(campo: string, valor: any, reembolsoId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`Reembolso/AtualizaReembolsoPorId?id=${reembolsoId}`, request);
  }

  public async alterarReembolsoPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`Reembolso/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  public async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`Reembolso/AdicionaXCampoValor`, request);

    return result;
  }

  public async atualizarReembolso(editarReembolso: AdicionaReembolsoRequest): Promise<void> {
    let dataReembolso = [
      { op: "replace", path: "/tipoReembolsoId", value: editarReembolso.tipoReembolsoId },
      { op: "replace", path: "/descricao", value: editarReembolso.descricao },
      { op: "replace", path: "/competencia", value: editarReembolso.competencia },
      { op: "replace", path: "/escritorioId", value: editarReembolso.escritorioId },
      { op: "replace", path: "/moedaId", value: editarReembolso.moedaId },
      { op: "replace", path: "/status", value: editarReembolso.statusId },
      { op: "replace", path: "/valorTotal", value: editarReembolso.valortotal },
      { op: "replace", path: "/codigo", value: editarReembolso.codigo },
      { op: "replace", path: "/tipoCentroCustoId", value: editarReembolso.tipoCentroCustoId }
    ];

    await Promise.all([await api.patch<void>(`Reembolso/AtualizaReembolsoPorId?id=${editarReembolso.reembolsoId}`, dataReembolso)]);
  }


  private montarObjetoRequest = (reembolso: AdicionaReembolsoRequest) => {
    let request: any = {};

    if (reembolso.xCampoValorList && reembolso.xCampoValorList.length > 0) request.xCampoValorList = reembolso.xCampoValorList;

    if (reembolso.tipoReembolsoId) request.tipoReembolsoId = reembolso.tipoReembolsoId;
    if (reembolso.objetoTipo) request.objetoTipo = reembolso.objetoTipo;
    if (reembolso.atoSocietarioId) request.atoSocietarioId = reembolso.atoSocietarioId;
    if (reembolso.descricao) request.descricao = reembolso.descricao;
    if (reembolso.competencia) request.competencia = reembolso.competencia;
    if (reembolso.moedaId) request.moedaId = reembolso.moedaId;
    if (reembolso.statusId) request.statusId = reembolso.statusId;
    if (reembolso.valortotal) request.valorTotal = reembolso.valortotal;
    if (reembolso.escritorioId) request.escritorioId = reembolso.escritorioId;
    if (reembolso.tipoCentroCustoId) request.tipoCentroCustoId = reembolso.tipoCentroCustoId;
    if (reembolso.payFechamentoId) request.payFechamentoId = reembolso.payFechamentoId;
    if (reembolso.dataCadastro) request.dataCadastro = reembolso.dataCadastro;
    if (reembolso.codigo) request.codigo = reembolso.codigo;


    if (reembolso.processoId) {
      request.modulo = EModulo.AreaDireito;
      request.processoId = reembolso.processoId;
      request.areaDireitoId = reembolso.areaDireitoId;
    }

    if (reembolso.imovelId) {
      request.modulo = EModulo.Imovel;
      request.imovelId = reembolso.imovelId;
      request.tipoImovelId = reembolso.tipoImovelId;
    }

    if (reembolso.contratoId) {
      request.modulo = EModulo.Contrato;
      request.contratoId = reembolso.contratoId;
      request.tipoContratoId = reembolso.tipoContratoId;
    }

    if (reembolso.consultaId) {
      request.modulo = EModulo.Consultivo;
      request.consultaId = reembolso.consultaId;
      request.tipoConsultaId = reembolso.tipoConsultaId;
    }

    return request;
  };


  public concluirCompromisso = async (reembolso: AdicionaReembolsoRequest) => {

    // Salvar campos normais
    await this.editarReembolso(reembolso);

    // Salvar xcampos
    if (reembolso.xCampoValorList?.length > 0) {
      reembolso.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, reembolsoId: reembolso.reembolsoId, texto: xCampo.texto });
            else await this.alterarReembolsoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId);
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, reembolsoId: reembolso.reembolsoId, data: xCampo.data });
            else await this.alterarReembolsoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId);
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, reembolsoId: reembolso.reembolsoId, valor: xCampo.valor });
            else await this.alterarReembolsoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId);
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, reembolsoId: reembolso.reembolsoId, inteiro: xCampo.inteiro });
            else await this.alterarReembolsoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId);
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, reembolsoId: reembolso.reembolsoId, xCampoOpcaoId: xCampo.xCampoOpcaoId });
            else await this.alterarReembolsoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId);
            break;
        }
      });
    }
  };

  private montarObjetoArray = (reembolso: AdicionaReembolsoRequest) => {
    let request: any = [];

    // Campos texto
    if (reembolso.codigo) request.push({ op: "replace", path: "/codigo", value: reembolso.codigo });
    if (reembolso.descricao) request.push({ op: "replace", path: "/descricao", value: reembolso.descricao });

    // Campos data
    if (reembolso.competencia) request.push({ op: "replace", path: "/competencia", value: reembolso.competencia });

    //Valor
    if (reembolso.valortotal) request.push({ op: "replace", path: "/valorTotal", value: reembolso.valortotal });

    // Campos lista
    if (reembolso.tipoCentroCustoId) request.push({ op: "replace", path: "/tipoCentroCustoId", value: reembolso.tipoCentroCustoId });
    if (reembolso.escritorioId) request.push({ op: "replace", path: "/escritorioId", value: reembolso.escritorioId });
    if (reembolso.moedaId) request.push({ op: "replace", path: "/moedaId", value: reembolso.moedaId });

    return request;
  };
}

export default new ReembolsoService();
