import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaGlobalRequest from "../interfaces/Requests/PesquisaGlobal/FiltroPesquisaGlobalRequest";
import IPesquisaGlobal from "../interfaces/IPesquisaGlobal";

class PesquisaGlobalService {

    async obterPesquisaGlobal(filtros: FiltroPesquisaGlobalRequest): Promise<IRecuperaLista<IPesquisaGlobal>> {
        let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.termoPesquisado) {
            query += `&termoPesquisado=${filtros.termoPesquisado}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IPesquisaGlobal>>(`PesquisaGlobal/RecuperaRegistros?${query}`);

        return result;
    }
}
export default new PesquisaGlobalService();