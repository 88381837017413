/* eslint-disable @typescript-eslint/no-empty-function */
import { FormikProps } from "formik";

import { Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

interface AreaTextoDefaultProps {
  toggleModal: () => void;
  exibirModal: boolean;
  formik: FormikProps<any>;
  field: string
}

const AreaTextoDefault = ({
  toggleModal,
  exibirModal,
  formik,
  field,
}: AreaTextoDefaultProps) => {

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Observação:
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">

          <div className="row">
            <textarea
              {...formik.getFieldProps(field)}
              maxLength={10000}
              rows={10}
              className={'form-control'}
            />
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button onClick={() => toggleModal()} type="button" className="btn btn-orange ms-5">
              Adicionar
            </button>
            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>

          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AreaTextoDefault;