import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import {
  faPrint,
  faShare,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";


import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaLicencaRequest } from "../../interfaces/Requests/Licenca/IAdicionaLicencaRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaSeparadorTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ESeparadorIDEnum, ETelaEnum } from "../../enum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import LicencaService from "../../services/LicencaService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import { OrangeContext } from "../../contexts/OrangeProvider";
import { PopoverSubMenuLicenca } from "../../components/Comum/Popover/PopoverSubMenuLicenca";
import Carregando from "../../components/Carregando";
import AndamentoListField from "../../components/Comum/Andamento/AndamentoListField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import { defaultSchema } from "../../utils/SchemasUtil";
import { ToastContainer } from "react-toastify";
import CollapseCapaDinanimos from "../../components/Comum/Modulo/CollapseCapaDinanimos";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";
import ComunicacaoListField from "../../components/Comum/Comunicacao/ComunicacaoListField";

const LicencaCapaPage = () => {

  const { licenca, tipoLicenca, setLicenca } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [refreshCapa, setRefreshCapa] = useState(true);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const componentRef = useRef<any>();

  const compartilharWhatSapp = () => {
    return `Número do Licenca: *#${licenca.licencaId}* / ${tipoLicenca.tipoLicencaId > 0 ? "Tipo de Licenca: *" + tipoLicenca.nome + "*" : ""
      }  / Status: *${licenca.statusLicenca}*`;
  };


  const initialValues: IAdicionaLicencaRequest = {

    licencaId: licenca.licencaId,
    principalId: licenca.licencaId,
    tipoLicencaId: licenca.tipoLicencaId,
    categoriaId: licenca.tipoLicencaId,
    modulo: EModulo.Regulatorio,
    tela: ETelaEnum.TelaCapaRegulatorio,

    numerolicenca: "",
    canal: "",
    fistel: "",
    observacoeslicenca: "",
    numerolicencadiacritics: "",

    orgaoResponsavelId: 0,
    cidadeId: 0,
    tipoServicoId: 0,
    restrito: false,

    dataemissao: null,
    datavencimento: null,
    dataConcessao: null,

    empresasList: [],
    advogadoInternoList: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (licenca.licencaId) {

      carregarLicenca();

      formik.setValues(initialValues);

    }

  }, [licenca.licencaId]);


  useEffect(() => {
    if (refreshCapa) {

      carregarSeparadoresTela();

      setRefreshCapa(false);

    }
  }, [refreshCapa]);

  useEffect(() => {
    if (!licenca.licencaId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [licenca]);

  const inserirSessionStorage = () => sessionStorageService.inserir(licenca, "licenca_capa");


  const carregarSessionStorage = async () => setLicenca(JSON.parse((await sessionStorageService.obter("licenca_capa")) || null));


  const carregarLicenca = async () => {
    try {

      let resultado: IRecuperaLista<ILicenca>;
      resultado = await LicencaService.obterLicencaPorId(licenca.licencaId);
      setLicenca(resultado.data[0]);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setRefreshCapa(true);
    }
  }


  const carregarSeparadoresTela = async () => {

    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;
      resultado = await ConfiguracaoSeparadorService.obterCapa({
        modulo: EModulo.Regulatorio,
        tipoLicencaId: formik.values.tipoLicencaId,
        
      });

      setSeparadoresTela(resultado.data);

    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (licencaId: number) => {
    try {
      let resultado = await LicencaService.adicionaFavorido(licencaId);
      setLicenca({ ...licenca, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar licenca como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await LicencaService.removerFavorido(favoritoId);
      setLicenca({ ...licenca, favoritoId: 0 });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };


  const renderSeparadores = () => {

    const components = separadoresTela.map((separadorTela, index) => {
      switch (separadorTela.codigo) {

        case ESeparadorIDEnum.ExibirAndamento:
          return <AndamentoListField key={index.toString()} modulo={EModulo.Regulatorio} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirCompromisso:
          return <CompromissoListField key={index.toString()} modulo={EModulo.Regulatorio} setRefreshCapa={setRefreshCapa} configuracaoTela={separadorTela}/>;

        case ESeparadorIDEnum.ExibirComunicacao:
          return <ComunicacaoListField key={index.toString()} modulo={EModulo.Regulatorio} configuracaoTela={separadorTela} />;

        default:
          return (
            <CollapseCapaDinanimos
              key={index.toString()}
              formik={formik}
              controller={"licenca"}
              setRefreshCapa={setRefreshCapa}
              separadorTela={separadorTela}
              setConfiguracoesTela={setConfiguracoesTela}
              configuracoesTela={configuracoesTela}
              separadorTelaLista={separadoresTela}
              setSeparadorTelaLista={setSeparadoresTela}
            />
          );
      }
    });

    return components;
  };


  return (
    <>
      <ToastContainer />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${licenca.licencaId}  ${tipoLicenca.tipoLicencaId > 0 ? " - " + tipoLicenca.nome : ""} => ${licenca.statusLicenca}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => setRefreshCapa(!refreshCapa)}>
                {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faHistory} />}
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span className="indicator-progress" style={{ display: "block", fontSize: "30px", paddingLeft: "11px", paddingRight: "11px" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPrint} title="Imprimir o licenca" />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShare} />}
          </a>

          {licenca.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(licenca.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o licenca"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(licenca.licencaId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o licenca"
            ></a>
          )}

          <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuLicenca()}>
            <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>

        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderSeparadores()}
            <FileListCapaField modulo={EModulo.Regulatorio} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
          </>
        )}
      </div>
    </>
  );
}
export default LicencaCapaPage;
