import { RenderElementProps } from "slate-react";
import { FragmentElement as FragmentEl } from "../../../../../../utils/types/slate";
import styled from 'styled-components'

const Wrapper = styled.div<{cssContent: string}>`${props => props.cssContent}`;

export const FragmentElement = (props: RenderElementProps) => {
  const element = props.element as FragmentEl

  return (
    <Wrapper cssContent={element.inlineCssStyle ?? ""} {...props.attributes}>
      {props.children}
    </Wrapper>
  );
}