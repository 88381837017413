import { FC, useEffect } from "react";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";
import AssinarEnvelope from "../pages/XSigner/Envelopes/AssinarEnvelopePage";
import LayoutWrapper from "../layout/LayoutWrapper";
import AuthWrapper from "../pages/AuthWrapper/AuthWrapper";
import StorageUtils from "../utils/StorageUtils";
import OrangeProvider from "../contexts/OrangeProvider";
import { RecorrenciaProvider } from "../components/Comum/Compromisso/Recorrencia/RecorrenciaContext";
import ThemeController from "../../ThemeController";

const Routes: FC = () => {
  const { isAuthorized, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized === false) {
      return;
    }

    if (new Date().getTime() > StorageUtils.getAccessExpireIn()) {
      window.location.reload();
      return;
    }

    StorageUtils.updateAccessExpireIn();
  }, [navigate]);

  return (
    <>
      <OrangeProvider>
        <RecorrenciaProvider>
          <>
          <ThemeController>
            {/* <Route path='/error' component={ErrorsPage} /> */}
            {!loading &&
              (!isAuthorized ? (
                <Switch>
                  <Route path="/xsigner">
                    <Route index element={<AssinarEnvelope />} />
                  </Route>
                  <Route path="*" element={<AuthWrapper />} />
                </Switch>
              ) : (
                <Switch>
                  <Route path="/xsigner">
                    <Route index element={<AssinarEnvelope />} />
                  </Route>
                  <Route path="*" element={<LayoutWrapper />} />
                </Switch>
              ))}
              </ThemeController>
          </>
        </RecorrenciaProvider>
      </OrangeProvider>
    </>
  );
};

export { Routes };
