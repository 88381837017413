import React from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IDropdownProps {
  content: any;
  totalItems: number;
  height?: number;
}

const DropDown: React.FC<IDropdownProps> = ({ totalItems, content, height = 150 }) => {

  const dropdownHeight = totalItems <= 4 ? `${height.toString()}px` : '50px';
  const toggleMargin = totalItems <= 4 ? '45px 0 0 0' : '0';

  return (
    <div style={{ height: dropdownHeight }}>
      <Dropdown as={ButtonGroup} key={totalItems} className="ms-2 mt-2 mb-2">
        <Dropdown.Toggle style={{ margin: toggleMargin }} split variant="default" key={totalItems}>
          <FontAwesomeIcon title="Opções" icon={faEllipsisH} size="1x" className="text-orange" />
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ margin: "-90px 0 0 0" }} className="tx-13">
          {content}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropDown;
