import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ClienteService from "../../services/ClienteService";
import ICliente from "../../interfaces/ICliente";
import EscritorioSelected from "../../components/Comum/EscritorioSelected";
import AreaDireitoSelected from "../../components/Comum/AreaDireitoSelected";
import { EditarClienteRequest } from "../../interfaces/Requests/Cliente/EditarClienteRequest";

interface IClienteModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  Cliente: ICliente;
  titulo: string;
}

enum stepEnum {
  visible = "visible",
  hidden = "hidden",
  collapse = "collapse",
}

const ClienteModal = ({ toggleModal, exibirModal, Cliente, titulo }: IClienteModalProps) => {
  const [stepFields, setStepFields] = useState<stepEnum>(stepEnum.visible);
  const [stepEscritorio, setStepEscritorio] = useState<stepEnum>(stepEnum.hidden);
  const [stepAreaDireito, setStepAreaDireito] = useState<stepEnum>(stepEnum.hidden);

  const ConfigureStepBack = () => {
    // Caso o cliente seja do tipo escritorio ou empresa,
    // exibir a modal com a lista de escritorios para seleção
    if (formik.values.usuarioTipo === "1" || formik.values.usuarioTipo === "2") {
      setStepFields(stepEnum.hidden);
      setStepEscritorio(stepEnum.visible);
      setStepAreaDireito(stepEnum.hidden);
    } else {
      setStepFields(stepEnum.visible);
      setStepEscritorio(stepEnum.hidden);
      setStepAreaDireito(stepEnum.hidden);
    }
  };

  const ConfigureStepFiels = () => {
    setStepFields(stepEnum.hidden);
    // Caso o cliente seja do tipo escritorio ou empresa,
    // exibir a modal com a lista de escritorios para seleção
    if (formik.values.usuarioTipo === "1") {
      setStepEscritorio(stepEnum.visible);
      setStepAreaDireito(stepEnum.hidden);
    } else {
      setStepEscritorio(stepEnum.hidden);
      setStepAreaDireito(stepEnum.visible);
    }
  };
  const ConfigureStepEscritorio = () => {
    setStepFields(stepEnum.hidden);
    setStepEscritorio(stepEnum.hidden);
    setStepAreaDireito(stepEnum.visible);
  };
  const ConfigureStepInitial = () => {
    setStepFields(stepEnum.visible);
    setStepEscritorio(stepEnum.hidden);
    setStepAreaDireito(stepEnum.hidden);
  };
  useEffect(() => {
    async function montarForm() {
      await formik.resetForm();

      if (Cliente) {
        await formik.setTouched({
          clienteId: true,
          pessoaId: true,
          codigo: true,
          urlLogo: true,
          clienteNome: true,
          clienteNomeSocial: true,
          usuarioTipo: true,
        });
        await formik.setValues({
          ...Cliente,
          clienteNome: Cliente.clienteNome || "",
          clienteNomeSocial: Cliente.clienteNomeSocial || "",
          codigo: Cliente.codigo || "",
          urlLogo: Cliente.urlLogo || "",          
          escritorios: Cliente.escritorios,
          areasDireito: Cliente.areasDireito,
          atualizaEscritorio: true,
          status: Cliente.status === "Ativo" ? 1 : -1,
        });
      }

      await formik.validateForm();

      ConfigureStepInitial();
    }

    montarForm();

    // eslint-disable-next-line
  }, [exibirModal, Cliente]);

  const ClienteValidacoes = Yup.object().shape({
    clienteId: Yup.number().min(1, "Selecione um Cliente").required("Selecione um Cliente"),
    pessoaId: Yup.number().min(1, "Selecione uma Pessoa").required(),
    clienteNome: Yup.string().min(4, "Mínimo 4 caracteres").required("Nome é obrigatório"),
    clienteNomeSocial: Yup.string().min(4, "Mínimo 4 caracteres").required("Nome Social é obrigatório"),
    codigo: Yup.string().max(50, "Maximo 50 caracteres"),
  });

  const valoresIniciais: EditarClienteRequest = {
    clienteId: 0,
    pessoaId: 0,
    codigo: "",
    urlLogo: "",
    clienteNome: "",
    clienteNomeSocial: "",
    atualizaEscritorio: true,
    escritorios: [],
    atualizaAreas: true,
    areasDireito: [],
    usuarioTipo: "",
    status: 1,
  };

  const formik = useFormik({
    initialValues: valoresIniciais,
    validationSchema: ClienteValidacoes,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await ClienteService.atualizarCliente(values);

        setSubmitting(false);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Cliente editado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        toggleModal(true);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar o Cliente`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    },
  });

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              {stepFields === stepEnum.visible && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                        Código
                      </label>
                      <input
                        {...formik.getFieldProps("codigo")}
                        placeholder="Código"
                        type="codigo"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.codigo && formik.errors.codigo,
                          },
                          {
                            "is-valid": formik.touched.codigo && !formik.errors.codigo,
                          }
                        )}
                        id="form-codigo"
                      />
                      {formik.touched.codigo && formik.errors.codigo && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.codigo}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label htmlFor="form-clienteNome" className="form-label fw-bolder text-orange">
                        Nome Pessoa
                      </label>
                      <input
                        {...formik.getFieldProps("clienteNome")}
                        placeholder="Nome Cliente"
                        type="text"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.clienteNome && formik.errors.clienteNome,
                          },
                          {
                            "is-valid": formik.touched.clienteNome && !formik.errors.clienteNome,
                          }
                        )}
                        id="form-nomePessoa"
                      />
                      {formik.touched.clienteNome && formik.errors.clienteNome && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.clienteNome}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="form-nomeSocial" className="form-label fw-bolder text-orange">
                        Nome Social
                      </label>
                      <input
                        {...formik.getFieldProps("clienteNomeSocial")}
                        placeholder="Nome Social"
                        type="nomeSocial"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.clienteNomeSocial && formik.errors.clienteNomeSocial,
                          },
                          {
                            "is-valid": formik.touched.clienteNomeSocial && !formik.errors.clienteNomeSocial,
                          }
                        )}
                        id="form-nomeSocial"
                      />
                      {formik.touched.clienteNomeSocial && formik.errors.clienteNomeSocial && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.clienteNomeSocial}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label htmlFor="form-urlLogo" className="form-label fw-bolder text-orange">
                        URL
                      </label>
                      <input
                        {...formik.getFieldProps("urlLogo")}
                        placeholder="URL"
                        type="urlLogo"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.urlLogo && formik.errors.urlLogo,
                          },
                          {
                            "is-valid": formik.touched.urlLogo && !formik.errors.urlLogo,
                          }
                        )}
                        id="form-urlLogo"
                      />
                      {formik.touched.urlLogo && formik.errors.urlLogo && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.urlLogo}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {stepEscritorio === stepEnum.visible && (
                <>
                  <EscritorioSelected clienteId={0} escritoriosSelected={formik.values.escritorios} setFieldValue={formik.setFieldValue} />
                </>
              )}

              {stepAreaDireito === stepEnum.visible && (
                <>
                  <AreaDireitoSelected areasDireitoSelected={formik.values.areasDireito || []} setFieldValue={formik.setFieldValue} />
                </>
              )}
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            {stepFields === stepEnum.visible && (
              <button
                disabled={formik.isSubmitting || !formik.isValid}
                onClick={() => ConfigureStepFiels()}
                type="button"
                className="btn btn-orange me-5"
              >
                AVANÇAR
              </button>
            )}

            {stepEscritorio === stepEnum.visible && (
              <button
                disabled={formik.values.escritorios.length === 0}
                onClick={() => ConfigureStepEscritorio()}
                type="button"
                className="btn btn-orange me-5"
              >
                AVANÇAR
              </button>
            )}

            {stepAreaDireito === stepEnum.visible && (
              <button
                disabled={(formik.values.areasDireito || []).length === 0}
                onClick={() => {
                  formik.submitForm();
                }}
                type="button"
                className="btn btn-orange me-5"
              >
                {!formik.isSubmitting && <> SALVAR </>}
                {formik.isSubmitting && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Salvando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            )}

            {stepFields === stepEnum.visible && (
              <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                CANCELAR
              </button>
            )}

            {stepEscritorio === stepEnum.visible && (
              <button onClick={() => ConfigureStepInitial()} type="button" className="btn btn-danger me-5">
                VOLTAR
              </button>
            )}

            {stepAreaDireito === stepEnum.visible && (
              <button onClick={() => ConfigureStepBack()} type="button" className="btn btn-danger ms-5">
                {" "}
                VOLTAR{" "}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClienteModal;