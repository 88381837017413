import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import {
  faPrint,
  faHistory,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";

import IEnvelope from "../../interfaces/IEnvelope";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IContrato, { IContratoPartesContraria } from "../../interfaces/IContrato";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaContratoRequest } from "../../interfaces/Requests/Contrato/IAdicionaContratoRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaSeparadorTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ECamposIDEnum, EModulo, ESeparadorIDEnum, ETelaEnum } from "../../enum";
import { defaultSchema } from "../../utils/SchemasUtil";

import EnvelopeService from "../../services/EnvelopeService";
import ContratoService from "../../services/ContratoService";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import { OrangeContext } from "../../contexts/OrangeProvider";

import Carregando from "../../components/Carregando";
import LayoutPageButton from "../../layout/LayoutPageButton";
import { GoogleMap } from "../../components/Comum/Map/GoogleMap";
import CollapseDefault from "../../components/Collapse/CollapseDefault";
import { googleMapsApi } from "../../components/Comum/Map/services/api";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompartilharModal from "../../components/Comum/Share/CompartilharModal";
import AndamentoListField from "../../components/Comum/Andamento/AndamentoListField";
import CollapseCapaDinanimos from "../../components/Comum/Modulo/CollapseCapaDinanimos";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuContrato } from "../../components/Comum/Popover/PopoverSubMenuContrato";
import { GetCoordinatesResponse, CoordinatesResponseStatusEnum, LatLng } from "../../components/Comum/Map/contract/MapContract";
import ComunicacaoListField from "../../components/Comum/Comunicacao/ComunicacaoListField";

const ContratoCapaPage = () => {
  const componentRef = useRef<any>();
  const { contrato, setContrato } = useContext<OrangeInterfaceContext>(OrangeContext);


  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [refreshCapa, setRefreshCapa] = useState(false);
  const [envelope, setEnvelope] = useState<IEnvelope>();
  const [exibirModalShare, setExibirModalShare] = useState<boolean>(false);

  const [showMap, setShowMap] = useState(false);
  const [mapError, setMapError] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [mapCenter, setMapCenter] = useState<LatLng>({
    lat: 0,
    lng: 0,
  });

  const inserirSessionStorage = () => sessionStorageService.inserir(contrato, "contrato_capa");
  const carregarSessionStorage = async () => setContrato(JSON.parse((await sessionStorageService.obter("contrato_capa")) || null));
  const toggleShare = () => setExibirModalShare(!exibirModalShare);
  const compartilhar = () => `Número_${contrato.numeroContrato}_${contrato.tipoContratoId > 0 ? "_Tipo_de_Contrato_" + contrato.tipoContratoNome : ""}_Status_${contrato.statusContrato == "" ? "_Ativo_" : nomearStatusContrato(contrato.statusContrato)}`;
  const getConfiguracaoTelaFieldValue = (field: string) => { return configuracoesTela.filter((config) => config.campoNome === field)[0]?.valor; };

  const initialValues: IAdicionaContratoRequest = {

    contratoId: contrato.contratoId,
    principalId: contrato.contratoId,
    tipoContratoId: contrato.tipoContratoId,
    categoriaId: contrato.tipoContratoId,

    modulo: EModulo.Contrato,
    tela: ETelaEnum.TelaCapaContrato,

    isnovapasta: null,
    numerocontrato: "",
    nomepasta: "",
    pastaid: 0,
    objetocontrato: "",
    datainiciovigencia: null,
    periodicidadeId: 0,
    datafimvigencia: null,
    observacoescontrato: "",
    departamentoId: 0,
    gestorId: 0,
    urgente: null,
    restrito: null,
    bancoId: 0,
    agencia: "",
    conta: "",
    moedaId: 0,
    indiceId: 0,
    estadoId: 0,
    cidadeId: 0,
    valortotal: 0,
    dataaprovacao: null,
    dataassinatura: null,
    codigo: "",

    centrosCusto: [],
    advogadoInternoList: [],
    empresasList: [],
    partesContrariasList: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
    quantidadeperiodicidade: 0,
    tipoVigenciaId: 0
  };

  useEffect(() => {
    async function loadMapCenter(): Promise<void> {
      try {
        const addressFields = [
          getConfiguracaoTelaFieldValue(ECamposIDEnum.NumeroEndereco),
          getConfiguracaoTelaFieldValue(ECamposIDEnum.Bairro),
          getConfiguracaoTelaFieldValue(ECamposIDEnum.CEP),
        ];

        let address = getConfiguracaoTelaFieldValue(ECamposIDEnum.Endereco);

        addressFields.forEach((field) => {
          if (field) {
            address += ` ${field}`;
          }
        });

        const api = await googleMapsApi();
        const response = await api.get<GetCoordinatesResponse>("geocode/json", {
          params: { address },
        });

        const { status } = response.data;

        if (status === CoordinatesResponseStatusEnum.ZERO_RESULTS) {
          setMapError(true);
          return;
        }

        setMapCenter(response.data.results[0].geometry.location);
        setFormattedAddress(response.data.results[0].formatted_address);
      } catch (error) {
        setMapError(true);
      }
    }

    if (configuracoesTela.length) {
      loadMapCenter();
    }
  }, [configuracoesTela, showMap]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (contrato.contratoId) {
      carregarContrato();
      carregarEnvelope();
      formik.setValues(initialValues);
    }
  }, [contrato.contratoId]);

  useEffect(() => {
    if (refreshCapa) {
      carregarSeparadoresTela();
      setRefreshCapa(false);
    }
  }, [refreshCapa]);

  useEffect(() => {
    if (!contrato.contratoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [contrato]);

  const nomearStatusContrato = (categoria: string) => {
    switch (categoria) {
      case "EmElaboracao":
        return "Em Elaboração";
      case "EmAprovacao":
        return "Em Aprovação";
      case "EmAssinatura":
        return "Em Assinatura";
      case "Assinado":
        return "Assinado";
      case "Cancelado":
        return "Cancelado";
      default:
        return "Não definido";
    }
  };

  const montarParteContraria = (partes: IContratoPartesContraria[]) => {
    let partesString = "";

    partes.forEach((parte) => {
      if (parte.principal) {
        partesString += parte.nome;
      }
    });
    return partesString;
  };

  const carregarContrato = async () => {
    try {
      let resultado: IRecuperaLista<IContrato>;
      resultado = await ContratoService.obterContratoPorId(contrato.contratoId);
      setContrato(resultado.data[0]);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setRefreshCapa(true);
    }
  };

  const carregarSeparadoresTela = async () => {

    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;
      resultado = await ConfiguracaoSeparadorService.obterCapa({
        modulo: EModulo.Contrato,
        tipoContratoId: formik.values.tipoContratoId,
      });

      setSeparadoresTela(resultado.data);

    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const carregarEnvelope = async () => {
    try {
      let resultado: IRecuperaLista<IEnvelope>;
      resultado = await EnvelopeService.obterEnvelopes({
        codigo: '',
        envelopeId: [],
        clienteId: 0,
        nomeDocumento: '',
        contratoId: contrato.contratoId,
        usuarioIdCadastro: 0,
        dataAssinaturaMaiorIgual: '',
        dataAssinaturaMenorIgual: '',
        dataCanceladoMaiorIgual: '',
        dataCanceladoMenorIgual: '',
        dataCadastroMaiorIgual: '',
        dataCadastroMenorIgual: '',
        limit: 1,
        totalItems: 0,
        offset: 0,
        sort: 'envelopeId'
      });
      if (resultado.data != null) setEnvelope(resultado.data[0]);
    } catch (error: any) {
      console.log(error);
    }
  };

  const adicionarFavorito = async (contratoId: number) => {
    try {
      let resultado = await ContratoService.adicionaFavorido(contratoId);
      setContrato({ ...contrato, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar contrato como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ContratoService.removerFavorido(favoritoId);
      setContrato({ ...contrato, favoritoId: 0 });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };



  const renderSeparadores = () => {

    const components = separadoresTela.map((separadorTela, index) => {
      switch (separadorTela.codigo) {

        case ESeparadorIDEnum.ExibirAndamento:
          return <AndamentoListField key={index.toString()} modulo={EModulo.Contrato} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirCompromisso:
          return <CompromissoListField key={index.toString()} modulo={EModulo.Contrato} setRefreshCapa={setRefreshCapa} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirComunicacao:
          return <ComunicacaoListField key={index.toString()} modulo={EModulo.Contrato} configuracaoTela={separadorTela} />;

        default:
          return (
            <CollapseCapaDinanimos
              key={index.toString()}
              formik={formik}
              controller={"contrato"}
              setRefreshCapa={setRefreshCapa}
              separadorTela={separadorTela}
              setConfiguracoesTela={setConfiguracoesTela}
              configuracoesTela={configuracoesTela}
              separadorTelaLista={separadoresTela}
              setSeparadorTelaLista={setSeparadoresTela}
            />
          );
      }
    });

    return components;
  };

  return (
    <>
      <ToastContainer />

      <CompartilharModal
        texto={compartilhar()}
        exibirModal={exibirModalShare}
        toggleModal={toggleShare}
      />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${contrato.numeroContrato}  
                        ${contrato.tipoContratoId > 0 ? " - " + contrato.tipoContratoNome : ""} 
                        => ${nomearStatusContrato(contrato.statusContrato)}
                        ${" - " + montarParteContraria(contrato.contratoParteContraria)} 
                `}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => setRefreshCapa(!refreshCapa)}>
                {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faHistory} />}
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span className="indicator-progress" style={{ display: "block", fontSize: "30px", paddingLeft: "11px", paddingRight: "11px" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPrint} title="Imprimir o contrato" />
              </a>
            )}
            content={() => componentRef.current}
          />

          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            onClick={() => toggleShare()}
            title="Compartilhar"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShareAlt} />}
          </a>

          {contrato.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(contrato.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o contrato"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(contrato.contratoId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o contrato"
            ></a>
          )}

          <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuContrato(envelope?.envelopeId)}>
            <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageButton>
        <hr />
        <br />
        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderSeparadores()}
            <FileListCapaField modulo={EModulo.Contrato} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />

            {configuracoesTela.length > 0 && (
              <>
                <CollapseDefault
                  carregando={false}
                  exibir={showMap}
                  modulo={EModulo.Default}
                  setExibir={setShowMap}
                  titulo="Localização"
                  content={
                    <Fragment>
                      <GoogleMap center={mapCenter} formattedAddress={formattedAddress} />
                    </Fragment>} />
                <hr />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default ContratoCapaPage;  