import styled from "styled-components";

export const Container = styled.button`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  background: var(--bs-white);
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  border: 1px solid #ddd;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div.thread-actions {
    display: flex;
    align-items: center;
    gap: 8px;

    > button {
    border: none;
    border-radius: 8px;
    color: var(--bs-gray);
    height: 30px;
    width: 30px;
    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: background 0.4s ease;

    > svg {
      font-size: 18px;
    }

    &:hover {
      &:nth-child(1) {
        color: var(--bs-green);
      }
      &:nth-child(2) {
        color: var(--bs-danger);
      }
    }
  }
  }
`;

export const Content = styled.div`
  width: 100%;

  div.replies {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
    width: 100%;

    > div.replies-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding: 10px;
    }
  }
`;
