import { RenderElementProps } from "slate-react";
import { TableHeadElement as TableHeadEl } from "../../../../../../utils/types/slate";

export const TableHeadElement = (props: RenderElementProps) => {
  const element = props.element as TableHeadEl;

  return (
    <th
      {...props.attributes}
      style={props.element.style}
      rowSpan={element.cellAttributes?.rowSpan}
      colSpan={element.cellAttributes?.colSpan}
    >
      {props.children}
    </th>
  );
};
