import { Draggable } from "react-beautiful-dnd";
import { MigrationFieldsType } from "./DragDropTableMigration";

type FieldProps = {
  field: MigrationFieldsType;
  index: number;
};

const FieldMigration = ({ field, index }: FieldProps) => {
  return (
    <Draggable draggableId={`${field.requestName}`} index={index}>
      {(provided) => {
        const className = field.required
          ? "border border-danger rounded mt-2 p-2 required fw-bold text-white bg-orange"
          : "border rounded mt-2 p-2 fw-bold text-white bg-orange";

        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={className}
          >
            {field.propertyName}
          </div>
        );
      }}
    </Draggable>
  );
};

export default FieldMigration;
