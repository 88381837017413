import { EModulo, ETipoCampoEnum } from "../enum";
import IDespesa from "../interfaces/IDespesa";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { AdicionaDespesaRequest } from "../interfaces/Requests/Despesa/AdicionaDespesaRequest";
import FiltroPesquisaDespesaRequest from "../interfaces/Requests/Despesa/FiltroPesquisaDespesaRequest";
import api from "./Api";

class DespesaService {

  public async obterDespesas(filtros: FiltroPesquisaDespesaRequest): Promise<IRecuperaLista<any>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query = this.montarQuery(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<any>>(`Despesa/RecuperaDespesa?${query}`);

    return result;
  }

  public async obterDespesaPorId(despesaId: number): Promise<IRecuperaLista<IDespesa>> {
    let query = `&offset=0&limit=100&sort=despesaId`;

    query += `&despesaId=${despesaId}`;

    let { data: result } = await api.get<IRecuperaLista<IDespesa>>(`Despesa/RecuperaDespesa?${query}`);

    return result;
  }

  async obterListaLimitadaTipoDespesa(filtros: any): Promise<IRecuperaLista<any>> {
    let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    if (filtros.areaDireitoId) {
      query += `&areaDireitoId=${filtros.areaDireitoId}`;
    }

    if (filtros.tipoMarcaId) {
      query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
    }

    if (filtros.tipoImovelId) {
      query += `&tipoImovelId=${filtros.tipoImovelId}`;
    }

    if (filtros.tipoContratoId) {
      query += `&tipoContratoId=${filtros.tipoContratoId}`;
    }

    if (filtros.tipoLicencaId) {
      query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
    }

    if (filtros.tipoConsultaId) {
      query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
    }

    if (filtros.tipoProcuracaoId) {
      query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(`TipoDespesa/RecuperaListaLimitada?${query}`);

    return result;
  }

  public async adicionaDespesa(adicionaDespesa: AdicionaDespesaRequest): Promise<IDespesa> {
    let { data: result } = await api.post<IDespesa>(`Despesa/AdicionaDespesa`, this.montarObjetoRequest(adicionaDespesa));

    return result;
  }

  public async editarDespesa(editarDespesa: AdicionaDespesaRequest) {
    let request = this.montarObjetoArray(editarDespesa);
    if (request.length > 0) {
      let result = await api.patch(`Despesa/AtualizaDespesaPorId?id=${editarDespesa.despesaId}`, request);
      return result;
    }
  }

  public async alterarStatus(despesaId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/statusDespesaId", value: status }];

    await api.patch<IDespesa>(`Despesa/AtualizaDespesaPorId?id=${despesaId}`, data);
  }

  public async obterCapaDespesa(despesaId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Despesa/RecuperaCapaDespesa?despesaId=${despesaId}`
    );

    return result;
  }

  public async alterarDespesaPorCampo(campo: string, valor: any, despesaId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let result = await api.patch<any>(`Despesa/AtualizaDespesaPorId?id=${despesaId}`, request);
      return result;
    }
  }

  public async alterarDespesaPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let result = await api.patch<any>(`Despesa/AtualizaXCampoValor?id=${xcampoId}`, request);
      return result;
    }
  }

  public async alterarDespesaPorCampoBolleano(campo: string, valor: any, despesaId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`Despesa/AtualizaDespesaPorId?id=${despesaId}`, request);
  }

  public async alterarDespesaPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`Despesa/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  public async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`Despesa/AdicionaXCampoValor`, request);

    return result;
  }

  public async adicionaFavorido(despesaId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Despesa, despesaId: despesaId });
    return result;
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  public async atualizarDespesa(editarDespesa: AdicionaDespesaRequest): Promise<void> {
    let dataDespesa = [
      { op: "replace", path: "/tipoDespesaId", value: editarDespesa.tipoDespesaId },
      { op: "replace", path: "/descricao", value: editarDespesa.descricao },
      { op: "replace", path: "/dataEfetiva", value: editarDespesa.dataefetiva },
      { op: "replace", path: "/dataVencimento", value: editarDespesa.datavencimento },
      { op: "replace", path: "/dataFatal", value: editarDespesa.datafatal },
      { op: "replace", path: "/formaPagamentoId", value: editarDespesa.formapagamentoId },
      { op: "replace", path: "/codigoBarras", value: editarDespesa.codigobarras },
      { op: "replace", path: "/bancoId", value: editarDespesa.bancoId },
      { op: "replace", path: "/agencia", value: editarDespesa.agencia },
      { op: "replace", path: "/conta", value: editarDespesa.conta },
      { op: "replace", path: "/moedaId", value: editarDespesa.moedaId },
      { op: "replace", path: "/statusDespesaId", value: editarDespesa.statusdespesaId },
      { op: "replace", path: "/valorPrincipal", value: editarDespesa.valorprincipal },
      { op: "replace", path: "/valorCorrecao", value: editarDespesa.valorcorrecao },
      { op: "replace", path: "/valorJuros", value: editarDespesa.valorjuros },
      { op: "replace", path: "/valorMulta", value: editarDespesa.valormulta },
      { op: "replace", path: "/valorHonorarios", value: editarDespesa.valorhonorarios },
      { op: "replace", path: "/codigo", value: editarDespesa.codigo },
    ];

    await Promise.all([await api.patch<void>(`Despesa/AtualizaDespesaPorId?id=${editarDespesa.despesaId}`, dataDespesa)]);
  }

  private montarObjetoRequest = (despesa: AdicionaDespesaRequest) => {
    let request: any = {};

    if (despesa.xCampoValorList.length > 0) request.xCampoValorList = despesa.xCampoValorList;

    // Campos texto
    if (despesa.codigo) request.codigo = despesa.codigo;
    if (despesa.descricao) request.descricao = despesa.descricao;
    if (despesa.codigobarras) request.codigoBarras = despesa.codigobarras;
    if (despesa.agencia) request.agencia = despesa.agencia;
    if (despesa.conta) request.conta = despesa.conta;

    //Data
    if (despesa.dataefetiva) request.dataEfetiva = despesa.dataefetiva;
    if (despesa.datavencimento) request.dataVencimento = despesa.datavencimento;
    if (despesa.datafatal) request.dataFatal = despesa.datafatal;

    //Valor
    if (despesa.valorprincipal) request.valorPrincipal = despesa.valorprincipal;
    if (despesa.valorjuros) request.valorJuros = despesa.valorjuros;
    if (despesa.valorcorrecao) request.valorCorrecao = despesa.valorcorrecao;
    if (despesa.valormulta) request.valorMulta = despesa.valormulta;
    if (despesa.valorhonorarios) request.valorHonorarios = despesa.valorhonorarios;

    if (despesa.processoId) {
      request.processoId = despesa.processoId;
      request.modulo = EModulo.AreaDireito;
    }

    if (despesa.imovelId) {
      request.imovelId = despesa.imovelId;
      request.modulo = EModulo.Imovel;
    }

    if (despesa.tipoDespesaId) request.tipoDespesaId = despesa.tipoDespesaId;
    if (despesa.formapagamentoId) request.formaPagamentoId = despesa.formapagamentoId;
    if (despesa.bancoId) request.bancoId = despesa.bancoId;
    if (despesa.moedaId) request.moedaId = despesa.moedaId;
    if (despesa.statusdespesaId) request.statusdespesaId = despesa.statusdespesaId;
    if (despesa.fornecedorId) request.fornecedorId = despesa.fornecedorId;
    if (despesa.processoId) request.processoId = despesa.processoId;
    if (despesa.imovelId) request.imovelId = despesa.imovelId;
    if (despesa.empresaId) request.empresaId = despesa.empresaId;
    if (despesa.atoSocietarioId) request.atoSocietarioId = despesa.atoSocietarioId;

    if (despesa.centrosCusto.filter((e) => e.checked).length > 0) {
      request.centrosCusto = [];
      despesa.centrosCusto
        .filter((e) => e.checked)
        .forEach((item) => {
          let centroCusto: any = {
            tipoCentroCustoId: item.tipoCentroCustoId,
            percentual: item.percentual,
          };
          request.centrosCusto.push(centroCusto);
        });
    }

    return request;
  };

  public concluirCompromisso = async (despesa: AdicionaDespesaRequest): Promise<any> => {

    let result: any[] = [];

    // Salvar campos normais
    result.push(await this.editarDespesa(despesa));

    // Salvar xcampos
    if (despesa.xCampoValorList?.length > 0) {
      despesa.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, despesaId: despesa.despesaId, texto: xCampo.texto }));
            else result.push(await this.alterarDespesaPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, despesaId: despesa.despesaId, data: xCampo.data }));
            else result.push(await this.alterarDespesaPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, despesaId: despesa.despesaId, valor: xCampo.valor }));
            else result.push(await this.alterarDespesaPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, despesaId: despesa.despesaId, inteiro: xCampo.inteiro }));
            else result.push(await this.alterarDespesaPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, despesaId: despesa.despesaId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
            else result.push(await this.alterarDespesaPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
            break;
        }
      });
    }

    // results
    return result;
  };

  private montarObjetoArray = (despesa: AdicionaDespesaRequest) => {
    let request: any = [];

    // Campos texto
    if (despesa.codigo) request.push({ op: "replace", path: "/codigo", value: despesa.codigo });
    if (despesa.descricao) request.push({ op: "replace", path: "/descricao", value: despesa.descricao });
    if (despesa.codigobarras) request.push({ op: "replace", path: "/codigoBarras", value: despesa.codigobarras });
    if (despesa.agencia) request.push({ op: "replace", path: "/agencia", value: despesa.agencia });
    if (despesa.conta) request.push({ op: "replace", path: "/conta", value: despesa.conta });

    // Campos data
    if (despesa.dataefetiva) request.push({ op: "replace", path: "/dataEfetiva", value: despesa.dataefetiva });
    if (despesa.datavencimento) request.push({ op: "replace", path: "/dataVencimento", value: despesa.datavencimento });
    if (despesa.datafatal) request.push({ op: "replace", path: "/dataFatal", value: despesa.datafatal });

    //Valor
    if (despesa.valorjuros) request.push({ op: "replace", path: "/valorJuros", value: despesa.valorjuros });
    if (despesa.valorcorrecao) request.push({ op: "replace", path: "/valorCorrecao", value: despesa.valorcorrecao });
    if (despesa.valormulta) request.push({ op: "replace", path: "/valorMulta", value: despesa.valormulta });
    if (despesa.valorhonorarios) request.push({ op: "replace", path: "/valorHonorarios", value: despesa.valorhonorarios });

    // Campos lista
    if (despesa.formapagamentoId) request.push({ op: "replace", path: "/formaPagamentoId", value: despesa.formapagamentoId });
    if (despesa.bancoId) request.push({ op: "replace", path: "/bancoId", value: despesa.bancoId });
    if (despesa.moedaId) request.push({ op: "replace", path: "/moedaId", value: despesa.moedaId });
    if (despesa.valorprincipal) request.push({ op: "replace", path: "/valorPrincipal", value: despesa.valorprincipal });
    if (despesa.statusdespesaId) request.push({ op: "replace", path: "/statusDespesaId", value: despesa.statusdespesaId });

    return request;
  };

  async gerarExcel(filtros: any): Promise<Blob> {

    let query = `sort=${filtros.sort}&modeloRelatorioId=${filtros.modeloRelatorioId}`;

    query = this.montarQuery(filtros, query);

    const { data: result } = await api.get(`Despesa/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

    return result;
  }

  private montarQuery = (filtros: FiltroPesquisaDespesaRequest, query: string) => {
    if (filtros?.tipoDespesaSelectedId) filtros.tipoDespesaSelectedId.forEach((id) => (query += `&tipoDespesaId=${id}`));

    if (filtros?.statusDespesaSelectedId) filtros.statusDespesaSelectedId.forEach((id) => (query += `&statusDespesaId=${id}`));

    if (filtros.despesaId && filtros.modulo == EModulo.Despesa) {
      query += `&despesaId=${filtros.despesaId}`;
    }

    if (filtros.processoId && filtros.modulo == EModulo.AreaDireito) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.imovelId && filtros.modulo == EModulo.Imovel) {
      query += `&imovelId=${filtros.imovelId}`;
    }

    if (filtros.empresaId && filtros.modulo == EModulo.Societario) {
      query += `&empresaId=${filtros.empresaId}`;
    }

    if (filtros.atoSocietarioId && filtros.modulo == EModulo.AtoSocietario) {
      query += `&atoSocietarioId=${filtros.atoSocietarioId}`;
    }

    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }

    if (filtros.DataEfetivaMaiorIgual) {
      query += `&DataEfetivaMaiorIgual=${filtros.DataEfetivaMaiorIgual}`;
    }

    if (filtros.DataEfetivaMenorIgual) {
      query += `&DataEfetivaMenorIgual=${filtros.DataEfetivaMenorIgual}`;
    }

    if (filtros.DataVencimentoMaiorIgual) {
      query += `&DataVencimentoMaiorIgual=${filtros.DataVencimentoMaiorIgual}`;
    }

    if (filtros.DataVencimentoMenorIgual) {
      query += `&DataVencimentoMenorIgual=${filtros.DataVencimentoMenorIgual}`;
    }

    if (filtros.tipoDespesaId) {
      query += `&tipoDespesaId=${filtros.tipoDespesaId}`;
    }

    if (filtros.modulo) {
      query += `&modulo=${filtros.modulo}`;
    }

    return query;
  };
}

export default new DespesaService();
