import { useContext, useEffect, useRef, useState } from "react";
import { faBackward, faFileArchive, faHistory, faPrint, faShare } from "@fortawesome/free-solid-svg-icons";
import { BsCloudArrowDown, BsCloudArrowUp, BsEye, BsTrash } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import ReactToPrint from "react-to-print";
import { FaEdit } from "react-icons/fa";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";
import * as Yup from "yup";

import { ETipoCampoEnum } from "../../../../../enum/ETipoCampoEnum";
import { ECamposIDEnum } from "../../../../../enum/ECamposIDEnum";
import { EModulo } from "../../../../../enum";

import DocumentoService from "../../../../../services/DocumentoService";
import AtoSocietarioService from "../../../../../services/AtoSocietarioService";
import sessionStorageService from "../../../../../services/sessionStorage/sessionStorageService";

import IDocumento from "../../../../../interfaces/IDocumento";
import IRecuperaLista from "../../../../../interfaces/IRecuperaLista";
import { IAtoSocietario } from "../../../../../interfaces/AtoSocietario";
import { OrangeInterfaceContext } from "../../../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaAndamentoRequest from "../../../../../interfaces/Requests/Andamento/FiltroPesquisaAndamentoRequest";
import FiltroPesquisaDocumentoRequest from "../../../../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import { AdicionaAtoSocietarioRequest } from "../../../../../interfaces/Requests/AtoSocietario/AdicionaAtoSocietarioRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";


import GridPadrao from "../../../../../components/Comum/GridPadrao";
import LayoutPageButton from "../../../../../layout/LayoutPageButton";
import { OrangeContext } from "../../../../../contexts/OrangeProvider";
import PreviewPDFField from "../../../../../components/Comum/PreviewPDF/PreviewPDF";
import IFileUploadField from "../../../../../components/FileUpload/FileUploadField";
import IFileUploadFieldEdit from "../../../../../components/FileUpload/FileUploadFieldEdit";
import CamposCapaDinamicos from "../../../../../components/AtoSocietario/CamposCapaDinamicos";


const AtoSocietarioCapaPage = () => {
  const { tipoAto, atoSocietario, setAtoSocietario, setTipoAto, empresa } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [documentos, setDocumentos] = useState<any>([]);
  const [documento, setDocumento] = useState<IDocumento>({
    index: 0,
    documentoId: 0,
    clienteId: 0,
    nome: "",
    descricao: "",
    tamanho: 0,
    tipoDocumentoId: 0,
    tipoDocumentoNome: "",
    restrito: false,
    status: "",
    codigo: "",
    dataCadastro: new Date(),
    usuarioIdCadastro: 0,
    usuarioNomeCadastro: "",
    modulo: "",
    extensao: "",
    pastaGedNome: "",
    etiquetas: []
  });
  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);

  const [filtrosAndamento, setFiltrosAndamento] = useState<FiltroPesquisaAndamentoRequest>({
    areaDireitoId:[],
    atoSocietarioId: atoSocietario.atoSocietarioId,
    tipoPesquisa: 1,
    filtro: "",
    limit: 10,
    ultimosQuantidade: null,
    totalItems: 0,
    offset: 0,
    sort: "-andamentoId",
    status: [1],
    modulo: EModulo.AtoSocietario,
    descricao: "",
    andamentoTipoId: [],
    dataAndamentoMaiorIgual: "",
    dataAndamentoMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
  });

  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    atoSocietarioId: atoSocietario.atoSocietarioId,
    modulo: [],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [exibirListaArquivosImportados, setExibirListaArquivosImportados] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);

  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [exibirComponenteParaImportarArquivo, setExibirComponenteParaImportarArquivo] = useState<boolean>(false);

  const [exibirModalDocumentoEdit, setExibirModalDocumentoEdit] = useState<boolean>(false);
  const [atualizaTabelaDocumento, setAtualizaTabelaDocumento] = useState<boolean>(false);

  const [refreshCapa, setRefreshCapa] = useState(false);

  const componentRef = useRef<any>();

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  const toggleDocumentoEdit = () => setExibirModalDocumentoEdit(!exibirModalDocumentoEdit);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const compartilharWhatSapp = () => {
    return `Número do AtoSocietario: *#${atoSocietario.atoSocietarioId}* / ${tipoAto.tipoAtoId > 0 ? "Tipo de AtoSocietario: *" + tipoAto.nome + "*" : ""
      }  / Status: *${atoSocietario.statusAtoNome == "" ? "Ativo*" : atoSocietario.statusAtoNome}*`;
  };

  const verificaIsPdf = (extensao: string) => {
    if (extensao.endsWith(".pdf")) return true;
    else return false;
  };

  useEffect(() => {
    carregarDocumentos(filtrosPesquisaArquivos);
  }, [filtrosPesquisaArquivos.offset, filtrosPesquisaArquivos.limit, filtrosPesquisaArquivos.sort]);

  useEffect(() => {
    if (atualizaTabelaDocumento) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
    setAtualizaTabelaDocumento(false);
  }, [atualizaTabelaDocumento]);

  const initialValues: AdicionaAtoSocietarioRequest =
  {
    empresaId: empresa.empresaId,
    atoSocietarioId: atoSocietario.atoSocietarioId,
    tipoAtoId: atoSocietario.tipoAtoId,
    tipoDespesaId: 0,
    dataAto: new Date(),
    dataArquivamento: null,
    numeroArquivamento: "",
    atoRegistradoJuntaId: 0,
    publicacaoAtoId: 0,
    statusAtoId: 0,
    textoMateriasDeliberadas: "",
    composicaoMesa: "",
    numeroRegistro: "",
    dataRegistro: null,
    publicacao: "",
    dataPublicacao: null,
    observacoesAto: "",
    codigo: "",
    xCampoValorList: [],
    atoSocietarioMateriaDeliberada: [],
  };

  const adicionaShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required();
          break;

        case ETipoCampoEnum.Lista:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}Id`] = Yup.number().min(1);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          schema[configuracao.campoNome.toLowerCase()] = Yup.date()
            .transform((value) => {
              return value ? moment(value).toDate() : value;
            })
            .required()
            .max(new Date());
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaShema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!atoSocietario.atoSocietarioId) return;
    carregarAtoSocietario();
    carregaCapa();
    carregarDocumentos(filtrosPesquisaArquivos);
    formik.setValues({
      ...formik.values,
      atoSocietarioId: atoSocietario.atoSocietarioId,
      tipoAtoId: atoSocietario.tipoAtoId
    })
  }, [atoSocietario.atoSocietarioId, refreshCapa]);

  useEffect(() => {
    if (!atoSocietario.atoSocietarioId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [atoSocietario]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(atoSocietario, "atoSocietario_capa");
    sessionStorageService.inserir(tipoAto, "tipoAto_capa");
    sessionStorageService.inserir(filtrosAndamento, "andamento_capa");
    sessionStorageService.inserir(filtrosPesquisaArquivos, "pesquisaArquivos_capa");
  };

  const carregarSessionStorage = async () => {
    setAtoSocietario(JSON.parse((await sessionStorageService.obter("atoSocietario_capa")) || null));
    setTipoAto(JSON.parse((await sessionStorageService.obter("tipoAto_capa")) || null));
    setFiltrosAndamento(JSON.parse((await sessionStorageService.obter("andamento_capa")) || null));
    setFiltrosPesquisaArquivos(JSON.parse((await sessionStorageService.obter("pesquisaArquivos_capa")) || null));
  };

  const carregarAtoSocietario = async () => {
    try {
      if (!atoSocietario.atoSocietarioId) return;
      let resultado: IRecuperaLista<IAtoSocietario>;
      resultado = await AtoSocietarioService.obterAtoSocietarioPorId(atoSocietario.atoSocietarioId);
      setAtoSocietario(resultado.data[0]);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  }

  const carregaCapa = async () => {
    try {
      if (!atoSocietario.atoSocietarioId) return;
      setCarregandoConfiguracoesTela(true);
      let resultado = await AtoSocietarioService.obterCapaAtoSocietario(atoSocietario.atoSocietarioId);
      setConfiguracoesTela(resultado.data);
      setCarregandoConfiguracoesTela(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o atoSocietario selecionado`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };


  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {
      if (!atoSocietario.atoSocietarioId) return;
      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos({ ...filtro, atoSocietarioId: atoSocietario.atoSocietarioId, status: 1, modulo: [EModulo.AtoSocietario] });

      if (resultado.data != null) setDocumentos(resultado.data);
      else setDocumentos([]);

      setFiltrosPesquisaArquivos((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoDocumentos(false);
    } catch (error: any) {
      setDocumentos([]);
      setCarregandoDocumentos(false);
    }
  };

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const deletaDocumento = async (documentoId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Deletar documento",
        text: `Você realmente deseja deletar este documento?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await DocumentoService.inativaDocumento(documentoId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Documento deletado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        carregarDocumentos(filtrosPesquisaArquivos);
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel deletar o documento`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };





  const handlePerRowsChangeArquivo = async (currentRowsPerPage: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChangeArquivo = (page: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisaArquivos.limit };
    });
  };

  const handleSortArquivo = async (column: TableColumn<any>, sortDirection: string) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const colunasArquivos: TableColumn<any>[] = [
    {
      name: "Documento ID",
      sortField: "documentoId",
      selector: (row: any) => row.documentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumentoNome",
      selector: (row: any) => row.tipoDocumentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: any) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Restrito?",
      sortField: "restrito",
      selector: (row: any) => (row.restrito ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Usuário",
      sortField: "usuarioNomeCadastro",
      selector: (row: any) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "  ",
      selector: (row: any) => moment(row.dataCadastro).format("DD/MM/yyyy"),
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: any) => {
        return (
          <div>
            <FaEdit
              title="Editar Arquivo"
              onClick={() => {
                setDocumento(row);
                onChangeTipoDocumento();
                setExibirModalDocumentoEdit(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />

            <IFileUploadFieldEdit
              campo={58}
              exibirModalDocumentoEdit={exibirModalDocumentoEdit}
              toggleModal={toggleDocumentoEdit}
              editarDocumento={documento}
              modulo={parseInt(documento.modulo)}
              setAtualizarTabela={setAtualizaTabelaDocumento}
              tipoAtoId={tipoAto.tipoAtoId}
            />

            <BsTrash
              title="Deletar Arquivo"
              onClick={() => deletaDocumento(row.documentoId)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />

            <BsCloudArrowDown
              title="Baixar Arquivo"
              onClick={() => baixarArquivo(row.documentoId, row.nome)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />
            <>
              {verificaIsPdf(row.extensao) && (
                <BsEye
                  title="Preview"
                  onClick={() => previewArquivo(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
              )}
            </>

          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];



  const onChangeTipoDocumento = () => {
    if (configuracoesTela.filter((e: { campo: number }) => e.campo == 58).length > 0) {
      const tipoDocumento = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo == 58)[0];

      const configuracoesTelaFilter = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo !== 58);

      configuracoesTelaFilter.push({ ...tipoDocumento, alterarCampo: true });

      setConfiguracoesTela(configuracoesTelaFilter);
    }
    if (exibirComponenteParaImportarArquivo) carregarDocumentos(filtrosPesquisaArquivos);
  };

  const renderImportadorArquivos = () => {
    return exibirComponenteParaImportarArquivo ? (
      <>
        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Importação de arquivos:{" "}
              <FontAwesomeIcon
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                icon={faBackward}
              />
            </label>
            <br />
            <>
              <Button
                onClick={() => setExibirModalImportacaoArquivos(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                Selecione os arquivos do atoSocietario
              </Button>
              <IFileUploadField
                modulo={EModulo.AtoSocietario}
                setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                exibirModal={exibirModalImportacaoArquivos}
                toggleModal={toggleImportacaoArquivos}
                atoSocietarioId={atoSocietario.atoSocietarioId}
                tipoAtoId={tipoAto.tipoAtoId}
                capa={true}
              />
            </>
          </div>
        </div>
        <div className="row mt-2">
          <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
            {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
            {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
            {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
          </a>
        </div>
      </>
    ) : (
      <>
        <div className="row mt-2">
          <div className="col-md-12">
            <label
              onClick={() => setExibirListaArquivosImportados(!exibirListaArquivosImportados)}
              htmlFor="form-Documentos"
              style={{ cursor: "pointer" }}
              className="form-label fw-bolder text-orange"
            >
              Documentos {exibirListaArquivosImportados ? "-" : "+"}
              <BsCloudArrowUp
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                size={"30px"}
                title={"Realizar upload de documento"}
              />
            </label>
            {exibirListaArquivosImportados && (
              <GridPadrao
                progressPending={carregandoDocumentos}
                onSort={handleSortArquivo}
                limit={filtrosPesquisaArquivos.limit}
                onChangePage={handlePageChangeArquivo}
                onChangeRowsPerPage={handlePerRowsChangeArquivo}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisaArquivos.totalItems}
                colunas={colunasArquivos}
                tipo="Documentos"
                itens={documentos}
              />
            )}
          </div>
          <hr />
        </div>
      </>
    );
  };

  const renderDefault = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <CamposCapaDinamicos
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      </>
    );
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        return;
      }

      component.push(renderDefault(configuracaoTela));
    });

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${atoSocietario.atoSocietarioId}  ${tipoAto.tipoAtoId > 0 ? " - " + tipoAto.nome : ""} => ${atoSocietario.statusAtoNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => setRefreshCapa(!refreshCapa)}>
                {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faHistory} />}
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span className="indicator-progress" style={{ display: "block", fontSize: "30px", paddingLeft: "11px", paddingRight: "11px" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPrint} title="Imprimir o atoSocietario" />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShare} />}
          </a>

        </LayoutPageButton>
        <hr />
        <br />

        {renderCampos()}
        <hr />
        {renderImportadorArquivos()}
      </div>
    </>
  );
}
export default AtoSocietarioCapaPage;
