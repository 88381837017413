import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { Form } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from "../../../enum";

import AudienciaService from "../../../services/AudienciaService";

import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaAudienciaRequest from "../../../interfaces/Requests/Audiencia/IFiltroPesquisaAudienciaRequest";
import { IRecuperaSeparadorTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import GridPadrao from "../GridPadrao";
import IAudiencia from "../../../interfaces/IAudiencia";
import CollapseDefault from "../../Collapse/CollapseDefault";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { audienciaInicialValues } from "../../../contexts/InitialValuesContext";

import AudienciaField from "./AudienciaCapaModal";
import AudienciaEditModal from "./AudienciaEditModal";

interface IAudienciaListFieldPropos {
  modulo: EModulo;
  configuracaoTela: IRecuperaSeparadorTelaResponse;
}

const AudienciaListField = ({ modulo, configuracaoTela }: IAudienciaListFieldPropos) => {
  const { processo, contrato, setAudiencia } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirListaAudiencia, setExibirListaAudiencia] = useState<boolean>(false);
  const [exibirModalCapaAudiencia, setExibirModalCapaAudiencia] = useState<boolean>(false);
  const [exibirModalCadastroAudiencia, setExibirModalCadastroAudiencia] = useState<boolean>(false);

  const [atualizaTabelaAudiencia, setAtualizaTabelaAudiencia] = useState<boolean>(false);
  const [audiencias, setAudiencias] = useState<IAudiencia[]>([]);
  const [carregandoAudiencia, setCarregandoAudiencia] = useState(false);

  const [filtrosAudiencia, setFiltrosAudiencia] = useState<FiltroPesquisaAudienciaRequest>({
    modulo: modulo,
    codigo: '',

    dataAudienciaMaiorIgual: '',
    dataAudienciaMenorIgual: '',

    dataCadastroMaiorIgual: '',
    dataCadastroMenorIgual: '',

    nome: '',

    processoId: processo.processoId,
    contratoId: contrato.contratoId,

    status: [],

    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "audienciaId",
  });

  const toggleCapaAudiencia = () => setExibirModalCapaAudiencia(!exibirModalCapaAudiencia);

  const toggleCadastroAudiencia = () => setExibirModalCadastroAudiencia(!exibirModalCadastroAudiencia);

  useEffect(() => {

    if (atualizaTabelaAudiencia) {
      setTimeout(() => carregarAudiencia(filtrosAudiencia), 3000);
    }

    setAtualizaTabelaAudiencia(false);

  }, [atualizaTabelaAudiencia]);

  useEffect(() => {

    setFiltrosAudiencia((oldState) => {
      return { ...oldState, ultimosQuantidade: 0 };
    });

    if (exibirListaAudiencia) carregarAudiencia(filtrosAudiencia);

  }, [filtrosAudiencia.offset, filtrosAudiencia.limit, filtrosAudiencia.sort]);

  useEffect(() => {
    if (exibirListaAudiencia) {
      carregarAudiencia(filtrosAudiencia);
    }
  }, [exibirListaAudiencia]);

  const handlePerRowsChangeAudiencia = async (currentRowsPerPage: number) => setFiltrosAudiencia((oldState) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChangeAudiencia = (page: number) => setFiltrosAudiencia((oldState) => { return { ...oldState, offset: (page - 1) * filtrosAudiencia.limit }; });

  const handleSortAudiencia = async (column: TableColumn<IAudiencia>, sortDirection: string) => setFiltrosAudiencia((oldState) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  const colunas: TableColumn<IAudiencia>[] = [
    {
      cell: (row: IAudiencia) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                className="form-label fw-bolder text-orange"
                style={{
                  paddingTop: "30px",
                }}
              >
                #{row.audienciaId} - {row.tipoAudienciaNome} - {moment(row.dataCadastro).format("DD/MM/yyyy")}
              </p>
              <p><b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Descrição: </b>{row.categoriaNome}</p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Data da Audiência: </b> {moment(row.dataaudiencia).format("DD/MM/yyyy")}
                <br />
              </p>
              <p><b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Observação: </b>{row.observacao}</p>
              <p>
                <>
                  <FontAwesomeIcon
                    title="Editar"
                    onClick={() => abrirCapa(row)}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faEdit}
                  />
                </>
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const abrirCapa = (row: IAudiencia) => {

    setAudiencia({
      ...row,
      audienciaId: row.audienciaId,
      tipoAudienciaId: row.tipoAudienciaId,

      modulo: modulo,
      processoId: processo.processoId,
      areaDireitoId: processo.areaDireitoId,

      contratoId: contrato.contratoId,
      tipoContratoId: contrato.tipoContratoId,
    });

    setExibirModalCapaAudiencia(true);
  };

  const carregarAudiencia = async (filtro: FiltroPesquisaAudienciaRequest) => {
    try {

      setCarregandoAudiencia(true);

      let resultado: IRecuperaLista<IAudiencia>;

      resultado = await AudienciaService.obterAudiencias(filtro);

      setFiltrosAudiencia((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setAudiencias(resultado.data);

      setCarregandoAudiencia(false);

    } catch (error: any) {
      setAudiencias([]);
      setCarregandoAudiencia(false);
    }
  };

  return (
    <>
      <AudienciaField
        exibirModal={exibirModalCadastroAudiencia}
        toggleModal={toggleCadastroAudiencia}
        setAtualizarTabela={setAtualizaTabelaAudiencia}
      />

      <AudienciaEditModal
        exibirModal={exibirModalCapaAudiencia}
        toggleModal={toggleCapaAudiencia}
      />

      <div className="row mt-3">
        <CollapseDefault
          modulo={EModulo.Audiencia}
          carregando={carregandoAudiencia}
          exibir={exibirListaAudiencia}
          setExibir={setExibirListaAudiencia}
          help={configuracaoTela.help}
          titulo={configuracaoTela.label}
          content={<>
            {exibirListaAudiencia && (
              <>
                <div className="col-md-12">
                  <button
                    onClick={() => {

                      setAudiencia({
                        ...audienciaInicialValues,
                        modulo: modulo,

                        processoId: processo.processoId,
                        areaDireitoId: processo.areaDireitoId,

                        contratoId: contrato.contratoId,
                        tipoAudienciaId: contrato.tipoContratoId,
                      });

                      setExibirModalCadastroAudiencia(true);

                    }}
                    className="btn btn-orange"
                  >
                    {exibirModalCadastroAudiencia && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                    {!exibirModalCadastroAudiencia && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
                  </button>
                </div>
                <div className="col-md-auto mt-3">
                  <input
                    value={filtrosAudiencia.filtro}
                    onChange={(e) => {
                      setFiltrosAudiencia((oldState) => {
                        return { ...oldState, filtro: e.target.value };
                      });
                    }}
                    placeholder="Audiência"
                    type="text"
                    className={clsx("form-control")}
                  />
                </div>
                <div className="col-md-auto mt-3">
                  <input
                    value={filtrosAudiencia.ultimosQuantidade || ""}
                    type="number"
                    maxLength={3}
                    onChange={(e) => {
                      setFiltrosAudiencia((oldState) => {
                        return { ...oldState, ultimosQuantidade: parseInt(e.target.value, 10) };
                      });
                    }}
                    placeholder="+ Quantidade"
                    className={clsx("form-control")}
                  />
                </div>
                <div className="col-md-auto mt-2">
                  <button onClick={() => carregarAudiencia(filtrosAudiencia)} className="btn btn-orange">
                    {<> Pesquisar </>}
                  </button>
                </div>
                <div className="col-md-auto mt-2">{<> Buscar por: </>}</div>
                <div className="col-md-auto mt-2">
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltrosAudiencia({ ...filtrosAudiencia, tipoPesquisa: 1 })}
                      label="Nome"
                      name="group1"
                      type="radio"
                    />
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltrosAudiencia({ ...filtrosAudiencia, tipoPesquisa: 2 })}
                      label="Código"
                      name="group1"
                      type="radio"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-5">
                  <GridPadrao
                    onSort={handleSortAudiencia}
                    progressPending={carregandoAudiencia}
                    limit={filtrosAudiencia.limit}
                    onChangePage={handlePageChangeAudiencia}
                    onChangeRowsPerPage={handlePerRowsChangeAudiencia}
                    paginationServer={true}
                    paginationTotalRows={filtrosAudiencia.totalItems}
                    colunas={colunas}
                    tipo="Audiencia"
                    itens={audiencias}
                  />
                </div>
              </>
            )}
          </>}
        />
      </div>
      <hr />
    </>
  );
};

export default AudienciaListField;
