import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { ETelaEnum } from "../../../enum/ETelaEnum";

import TipoSociedadeService from "../../../services/TipoSociedadeService";

import ITipoSociedade from "../../../interfaces/ITipoSociedade";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaTipoSociedadeRequest from "../../../interfaces/Requests/TipoSociedade/FiltroPesquisaTipoSociedadeRequest";

import GridPadrao from "../../../components/Comum/GridPadrao";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import "../Styles/icons.css";

const TipoSociedadePage = () => {
  const [tipoSociedades, setTipoSociedades] = useState<ITipoSociedade[]>([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoTipoSociedade, setCarregandoTipoSociedade] = useState(false);
  const { setTipoSociedade, setCampos, setTela } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaTipoSociedadeRequest>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "areaDireitoId",
  });

  useLayoutEffect(() => {
    setCampos([]);

    setTela(ETelaEnum.Default);

    if (!carregandoTipoSociedade) carregarTipoSociedade(filtrosPesquisa);
  }, []);

 const carregarTipoSociedade = async (filtro: FiltroPesquisaTipoSociedadeRequest) => {
    try {
      setCarregandoTipoSociedade(true);
      let resultado: IRecuperaLista<ITipoSociedade>;
      resultado = await TipoSociedadeService.obterTipoSociedades(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setTipoSociedades(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoSociedades([]);
    } finally {
      setCarregandoTipoSociedade(false);
      setLoadingInicial(false);
    }
  }

  const colunas: TableColumn<ITipoSociedade>[] = [
    {
      name: "Id",
      sortField: "areaDireitoId",
      selector: (row: ITipoSociedade) => row.tipoSociedadeId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "areaDireitoNome",
      selector: (row: ITipoSociedade) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: ITipoSociedade) => row.codigo,
      sortable: true,
      wrap: true,
    },
  ];

  const onRowClicked = (row: ITipoSociedade) => {
    setTipoSociedade(row);
    navigate("/Empresa/ConfiguracaoTela/Solucoes");
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<ITipoSociedade>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
        status: 1,
      };
    });
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarTipoSociedade(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageTitle title="Selecione o tipo de sociedade que você deseja configurar"> </LayoutPageTitle>
      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarTipoSociedade(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2 ">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Nome:
              </label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>
            <div className="col-md-2 ">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Código:
              </label>
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={"form-control"}
                id="form-codigo"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, status: parseInt(e.target.value) };
                  });
                }}
                placeholder="Nome"
                className={"form-select"}
                id="form-select"
              >
                <option value="0" label="Todos">
                  {" "}
                  Todos{" "}
                </option>
                <option value="1" label="Ativo">
                  {" "}
                  Ativo{" "}
                </option>
                <option value="-1" label="Inativo">
                  {" "}
                  Inativo{" "}
                </option>
              </select>
            </div>
            <div className="col-md-2">
              <div>
                <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            onRowClicked={onRowClicked}
            progressPending={carregandoTipoSociedade}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Área do Direito"
            itens={tipoSociedades}
          />
        </div>
      </div>
    </>
  );
};

export default TipoSociedadePage;