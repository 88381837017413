import { RenderElementProps } from "slate-react";
import { ContentElement as ContentEl } from "../../../../../../utils/types/slate";

export const ContentElement = (props: RenderElementProps) => {
  const contentStyle = {
    ...props.element.style,
    zIndex: 1
  };

  const contentElement = props.element as ContentEl;

  return (
    <div {...props.attributes} id={contentElement.id} style={contentStyle}>
      {props.children}
    </div>
  );
};
