import { MdOutlineDescription } from 'react-icons/md'
import { TextContentProps } from './props'
import { Container, Label, TextArea } from './styles'

export function TextContent({ label, ...props }: TextContentProps) {
  return (
    <Container>
      <Label>
        <MdOutlineDescription size={18} />
        <label>{label}</label>
      </Label>
      <TextArea {...props} />
    </Container>
  )
}