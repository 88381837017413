import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ISocioAdministrador from "../interfaces/ISocioAdministrador";

class SocioAdministradorService {

    async obterSocioAdministradores(filtros: any): Promise<IRecuperaLista<ISocioAdministrador>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.empresaId) {
            query += `&empresaId=${filtros.empresaId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ISocioAdministrador>>(`Empresa/RecuperaSocioAdministrador?${query}`);

        return result;
    }

    async obterListaLimitadaSocioAdministradors(filtros: any): Promise<IRecuperaLista<ISocioAdministrador>> {
        let query = `modulo=${filtros.modulo}`;

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ISocioAdministrador>>(`SocioAdministrador/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaSocioAdministrador(adicionaSocioAdministrador: any, empresaId: number): Promise<ISocioAdministrador> {
        let { data: result } = await api.post<ISocioAdministrador>(`Empresa/AdicionaSocioAdministrador?empresaId=${empresaId}`, adicionaSocioAdministrador);

        return result;
    }


    async alterarStatus(tipoCentroCustoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ISocioAdministrador>(`Empresa/AtualizaSocioAdministradorPorId?id=${tipoCentroCustoId}`, data);
    }

    async atualizarSocioAdministrador(editarSocioAdministrador: any): Promise<void> {

        let dataSocioAdministrador = [
            { "op": "replace", "path": "/nome", "value": editarSocioAdministrador.nome },
            { "op": "replace", "path": "/codigo", "value": editarSocioAdministrador.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Empresa/AtualizaSocioAdministradorPorId?id=${editarSocioAdministrador.tipoCentroCustoId}`, dataSocioAdministrador)
        ]);
    }
}


export default new SocioAdministradorService();