import { useEffect, useState } from "react";
import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { faMinus, faPlus, faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { IPessoa } from "../../interfaces/IPessoa";
import ISocioAdministrador from "../../interfaces/ISocioAdministrador";

import SocioAdministradorService from "../../services/SocioAdministradorService";
import RecuperaPessoa from "../Comum/Pessoa/RecuperaPessoa";
import AdicionaPessoa from "../Comum/Pessoa/AdicionaPessoa";
import { Nullable } from "../types/Nullable";
import GridPadrao from "../Comum/GridPadrao";
import IQualificacao from "../../interfaces/IQualificacao";
import QualificacaoService from "../../services/QualificacaoService";
import TipoQuotaAcoesService from "../../services/TipoQuotaAcoesService";
import Spinner from "../Spinner";

interface ISocioFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  socioSelected: ISocioAdministrador[];
  setFieldValue: any;
  empresaId: number;
}

const SocioAdministradorField = ({ toggleModal, exibirModal, socioSelected, setFieldValue, empresaId }: ISocioFieldProps) => {
  const [pessoa, setPessoa] = useState<Nullable<IPessoa>>(null);
  const [tipoDocumento, setTipoDocumento] = useState<string>("");
  const [numeroDocumento, setNumeroDocumento] = useState<string>("");
  const [exibirCadastro, setExibirCadastro] = useState(false);

  const [socios, setSocios] = useState<ISocioAdministrador[]>([]);
  const [carregandoSocios, setCarregandoSocios] = useState(false);

  const [qualificacaos, setQualificacaos] = useState<IQualificacao[]>([]);
  const [tipoQuotasAcoes, setTipoQuotasAcoesId] = useState<any[]>([]);

  const [carregandoQualificacao, setCarregandoQualificacao] = useState(false);

  const [filtrosSocio, setFiltrosSocio] = useState<any>({
    empresaId: empresaId,
    filtro: "",
    tipoPesquisa: 1,
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "socioId",
  });

  const handlerPessoa = (newPessoa: IPessoa) => setPessoa(newPessoa);

  const handlerTipo = (newTipo: string) => setTipoDocumento(newTipo);

  const handlerNumeroDocumento = (newNumeroDocumento: string) => setNumeroDocumento(newNumeroDocumento);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>Use o campo de busca para verificar se o socio desejado já existe em nossa base, caso ele não seja encontrado, basta clicar no botão de + para efetuar o novo cadastro.</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  useEffect(() => {
    carregarQualificacao();
    carregarTipoQuotaAcaoes();
  }, []);

  useEffect(() => {

    if (socios.length > 0) {
      socios.forEach((socio) => {
        const socioChecked = socioSelected.filter((e: ISocioAdministrador) => e.pessoaId === socio.pessoaId)[0];
        if (socioChecked && socioChecked.checked) socio.checked = true;
        else socio.checked = false;
      });
    }

    setSocios(socios);
  }, [socios, socioSelected]);

  const adicionar = (pessoaId: number) => {
    setFieldValue("socioList", [], true);
    socios.forEach((socio) => {
      if (socio.pessoaId == pessoaId) {
        if (!socio.checked) {
          socio.checked = true;
          setSocios(socios);
          setFieldValue("sociosAdministradores", socios, true);
          return;
        }
        if (socio.checked) {
          socio.checked = false;
          setSocios(socios);
          setFieldValue("sociosAdministradores", socios, true);
          return;
        }
      }
    });
  };

  const carregarSocios = async (filtro: any) => {
    try {
      setCarregandoSocios(true);

      let resultado: IRecuperaLista<ISocioAdministrador>;
      resultado = await SocioAdministradorService.obterSocioAdministradores(filtro);

      setFiltrosSocio((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setSocios(resultado.data);
      setCarregandoSocios(false);

    } catch (error: any) {
      setSocios([]);
      setCarregandoSocios(false);
    }
  };

  const carregarQualificacao = async () => {

    try {
      setCarregandoQualificacao(true);
      let resultado: IRecuperaLista<IQualificacao>;

      resultado = await QualificacaoService.obterQualificacaos({
        nome: '',
        codigo: '',
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: 'id'
      });

      setQualificacaos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: 'Não foi possível obter registros',
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        timer: 4000,
        icon: 'error',
        showConfirmButton: false,
      });
      setQualificacaos([]);
    }
    finally {
      setCarregandoQualificacao(false);
    }
  }

  const carregarTipoQuotaAcaoes = async () => {
    try {

      let resultado: IRecuperaLista<any>;

      resultado = await TipoQuotaAcoesService.obter({
        nome: '',
        codigo: '',
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: 'nome'
      });

      setTipoQuotasAcoesId(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ops..`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        showConfirmButton: true,
        timer: 3000,
      });
    }
  }

  const colunas: TableColumn<ISocioAdministrador>[] = [
    {
      name: "Id",
      sortField: "socioId",
      selector: (row: ISocioAdministrador) => row.pessoaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: ISocioAdministrador) => row.codigo,
      sortable: true,
      wrap: true,
    },

    {
      name: "Adicionar?",
      cell: (row: ISocioAdministrador) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => adicionar(row.pessoaId)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosSocio((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handleSort = async (column: TableColumn<ISocioAdministrador>, sortDirection: string) => {
    setFiltrosSocio((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const adicionaSocioRequest = Yup.object().shape({
    pessoaId: Yup.number().min(1).required("Campo é obrigatório"),
    codigo: Yup.string().max(50, "Maximo 50 caracteres"),
    idade: Yup.string().max(3, "Maximo 3 caracteres"),
    pais: Yup.string().max(20, "Maximo 20 caracteres"),
    qualificacaoId: Yup.number().min(1).required("Campo é obrigatório"),
    tipoQuotasAcoesId: Yup.number().min(1).required("Campo é obrigatório"),
  });

  const initialValues: any = {
    codigo: "",
    pessoaId: 0,
    dataEntrada: null,
    idade: "",
    tipoQuotasAcoesId: 0,
    quotasAcoes: 0,
    pais: "",
    qualificacaoId: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaSocioRequest,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (pessoa && pessoa.pessoaId > 0) {
          await SocioAdministradorService.adicionaSocioAdministrador({ ...values, pessoaId: pessoa.pessoaId }, empresaId);
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Socio cadastrado com sucesso`,
            timer: 4000,
          });
        }

        setExibirCadastro(false);
        carregarSocios(filtrosSocio);
        setPessoa(null);
        setExibirCadastro(false);
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar socio`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setExibirCadastro(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!carregandoSocios) {
      carregarSocios(filtrosSocio);
    }
  }, [filtrosSocio.offset, filtrosSocio.limit, filtrosSocio.sort]);


  const cancelar = () => {
    toggleModal();
    setPessoa(null);
    setExibirCadastro(false);
  };

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Sócios Administradores
            <a style={{ fontSize: "12px" }}>
              {<PopoverHelper></PopoverHelper>}
            </a>
          </h5>
          <div onClick={() => cancelar()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          {exibirCadastro && (
            <>
              {!pessoa && <RecuperaPessoa handlerTipo={handlerTipo} handlerNumeroDocumento={handlerNumeroDocumento} handlerPessoa={handlerPessoa} />}

              {pessoa && pessoa.pessoaId === 0 && (
                <>
                  <AdicionaPessoa tipoDocumento={tipoDocumento} numeroDocumento={numeroDocumento} hrefCancelar="555" handlerPessoa={handlerPessoa} />
                </>
              )}

              {pessoa && pessoa.pessoaId > 0 && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                >
                  <div className="row mt-3">
                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Nome:
                      </label>
                      <input disabled={true} type="text" value={pessoa.nome} className={"form-control"} />
                    </div>

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Nome Social:
                      </label>
                      <input disabled={true} type="text" value={pessoa.nomeSocial} className={"form-control"} />
                    </div>

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Documento:
                      </label>
                      <input disabled={true} type="text" value={pessoa.numeroDocumento} className={"form-control"} />
                    </div>
                  </div>


                  <div className="row mt-2">

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Código:
                      </label>
                      <input
                        {...formik.getFieldProps("codigo")}
                        placeholder="Código"
                        type="text"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.codigo && formik.errors.codigo,
                          },
                          {
                            "is-valid": formik.touched.codigo && !formik.errors.codigo,
                          }
                        )}
                        id="form-codigo"
                      />
                      {formik.touched.codigo && formik.errors.codigo && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.codigo}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4 mt-0">
                      <label htmlFor="form" className="form-label required fw-bolder text-orange">
                        Data da Entrada:
                      </label>
                      <DatePicker
                        locale={ptBR}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/MM/yyyy"
                        className={"form-control"}
                        selected={formik?.values?.dataEntrada || new Date()}
                        onChange={(date: Date) => formik.setFieldValue("dataEntrada", date)}
                      />

                      {formik.touched.dataEntrada && formik.errors.dataEntrada && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span style={{ fontSize: "11px" }} role="alert">
                              {formik.errors.dataEntrada}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="row mt-2">

                    <div className="col-md-6 mt-0">
                      {carregandoQualificacao ? (
                        <>Carregando...</>
                      ) : (
                        <div className="col-md-6 mt-3">
                          <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                            Tipo de Ações/Quotas:
                          </label>
                          <select
                            {...formik.getFieldProps("tipoQuotasAcoesId")}
                            value={formik.values.tipoQuotasAcoesId}
                            onChange={(e) => {
                              formik.setFieldTouched("tipoQuotasAcoesId", true);
                              formik.setFieldValue("tipoQuotasAcoesId", parseInt(e.target.value));
                            }}
                            className={clsx(
                              "form-select",
                              {
                                "is-invalid": formik.touched.tipoQuotasAcoesId && formik.errors.tipoQuotasAcoesId,
                              },
                              {
                                "is-valid": formik.touched.tipoQuotasAcoesId && !formik.errors.tipoQuotasAcoesId,
                              }
                            )}
                            id="form-client"
                          >
                            <option value="0"> Selecione </option>
                            {tipoQuotasAcoes.map((map) => {
                              return (
                                <option key={map.tipoQuotasAcoesId} value={map.tipoQuotasAcoesId}>
                                  {map.nome}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 mt-0">
                      {carregandoQualificacao ? (
                        <>Carregando...</>
                      ) : (
                        <div className="col-md-6 mt-3">
                          <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                            Qualificações:
                          </label>
                          <select
                            {...formik.getFieldProps("qualificacaoId")}
                            value={formik.values.qualificacaoId}
                            onChange={(e) => {
                              formik.setFieldTouched("qualificacaoId", true);
                              formik.setFieldValue("qualificacaoId", parseInt(e.target.value));
                            }}
                            className={clsx(
                              "form-select",
                              {
                                "is-invalid": formik.touched.qualificacaoId && formik.errors.qualificacaoId,
                              },
                              {
                                "is-valid": formik.touched.qualificacaoId && !formik.errors.qualificacaoId,
                              }
                            )}
                            id="form-client"
                          >
                            <option value="0"> Selecione </option>
                            {qualificacaos.map((map) => {
                              return (
                                <option key={map.qualificacaoId} value={map.qualificacaoId}>
                                  {map.nome}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>

                  </div>


                  <div className="row mt-2">

                    <div className="col-md-6 mt-0">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Pais:
                      </label>
                      <input
                        {...formik.getFieldProps("pais")}
                        placeholder="pais"
                        type="text"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.pais && formik.errors.pais,
                          },
                          {
                            "is-valid": formik.touched.pais && !formik.errors.pais,
                          }
                        )}
                        id="form-pais"
                      />
                      {formik.touched.pais && formik.errors.pais && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.pais}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 mt-0">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Quantidade:
                      </label>
                      <input
                        {...formik.getFieldProps("quotasAcoes")}
                        placeholder="quotasAcoes"
                        type="number"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched.quotasAcoes && formik.errors.quotasAcoes,
                          },
                          {
                            "is-valid": formik.touched.quotasAcoes && !formik.errors.quotasAcoes,
                          }
                        )}
                        id="form-pais"
                      />
                      {formik.touched.quotasAcoes && formik.errors.quotasAcoes && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.quotasAcoes}</span>
                          </div>
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="col-md-3 mt-5">
                    <button disabled={formik.isSubmitting || !formik.isValid} type="submit" className="btn btn-orange">
                      {!formik.isSubmitting && <> Salvar </>}
                      {formik.isSubmitting && (
                        <Spinner />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setPessoa(null);
                        setExibirCadastro(false);
                      }}
                      disabled={formik.isSubmitting}
                      type="button"
                      className="btn btn-danger float-end"
                    >
                      Cancelar
                    </button>
                  </div>
                </form>
              )}
            </>
          )}

          <div className="row mt-2">
            <div className="col-md-auto">
              <button
                title="Clique aqui para cadastrar um novo socio"
                onClick={() => {
                  setExibirCadastro(false);
                  setExibirCadastro(!exibirCadastro);
                }}
                className="btn btn-orange"
              >

                {exibirCadastro && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                {!exibirCadastro && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
              </button>
            </div>


          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoSocios}
                limit={filtrosSocio.limit}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosSocio.totalItems}
                colunas={colunas}
                tipo="Socios"
                itens={socios}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={() => toggleModal()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button onClick={() => cancelar()} type="button" className="btn btn-danger ms-5">
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SocioAdministradorField;
