import { RenderElementProps } from "slate-react"

export const TableElement = (props: RenderElementProps) => {
  return (
    <table {...props.attributes} style={props.element.style} >
      <tbody>
        {props.children}
      </tbody>
    </table>
  )
}