import styled, { css } from "styled-components";

export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  z-index: 999999;
  display: none;
  transition: display 0.3s ease;

  ${props => props.open && css`
    display: block;
  `}
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: #fff;
  z-index: 1;
  width: 400px;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.3);
`;