import { useContext, useEffect, useState } from "react";
import { faInfo, faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext } from "react-beautiful-dnd";
import { Modal } from "react-bootstrap";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as Yup from 'yup';

import { ICampo } from "../../../interfaces/ICampo";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import { ETelaEnum } from "../../../enum/ETelaEnum";
import {ETipoCampoEnum } from "../../../enum";

import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageForButton from "../../../layout/LayoutPageButton";
import Column from "./Column/Column";
import "./dragdrop.css";
import FechamentoConfiguracaoService from "../../../services/FechamentoConfiguracaoService";
import { useNavigate } from "react-router-dom";
import { notifyWarning } from "../../../components/Comum/Toast/ToastFormik";
import { ToastContainer } from "react-toastify";


const FechamentoParametrizacao = () => {
  const navigate = useNavigate();
  const [exibirConfirmarModal, setExibirConfirmarModal] = useState<boolean>(false);
  const [carregandoConfiguracaoTela, setCarregandoConfiguracaoTela] = useState<boolean>(false);
  const [processamentoAoSalvar, setProcessamentoAoSalvar] = useState<boolean>(false);
  const { setSelectedParametrizacoes, selectedParametrizacoes,setCampos, campos, eCampos, setECampos, modeloRelatorio } = useContext<OrangeInterfaceContext>(OrangeContext);
  
  useEffect(() => {
    if(selectedParametrizacoes.fechamentoConfiguracaoId == 0) {
      navigate("/Home")
    }
  }, [selectedParametrizacoes.fechamentoConfiguracaoId]);


  function criarCampo(campoId: any, nomeEntidade: any) {
    return {
      Lista: [],
      campo: campoId,
      campoNome: nomeEntidade,
      campoTipo: ETipoCampoEnum.Texto,
      camposId: 28,
      entidade: nomeEntidade,
      help: "",
      isXCampo: false,
      label: nomeEntidade,
      nome: "",
      obrigatorio: false,
      ordenacao: 0,
      somenteLeitura: false,
      tabelaOrigem: 1,
      tipo: ETipoCampoEnum.Texto,
      valor: 0,
      valorId: 0
    };
  }
  
  const [columns, setColumns] = useState<any>(() => {
      const hasEmpresa = selectedParametrizacoes.empresas.length !== 0;
      const hasAreaDireito = selectedParametrizacoes.areasDireito.length !== 0;
  
      const empresaItem = criarCampo(28, "Empresa");
      const areaDireitoItem = criarCampo(990, "Area de direito");
  
      let todoItems = [];
      let doingItems = [];
  
      if (hasEmpresa && !hasAreaDireito) {
        todoItems.push(areaDireitoItem);
        doingItems.push(empresaItem);
      } else if (!hasEmpresa && hasAreaDireito) {
        todoItems.push(empresaItem);
        doingItems.push(areaDireitoItem);
      } else if (!hasEmpresa && !hasAreaDireito) {
        todoItems.push(areaDireitoItem, empresaItem);
      } else if (hasEmpresa && hasAreaDireito) {
        doingItems.push(areaDireitoItem, empresaItem);
      }
  
      return {
        todo: { id: "todo", list: todoItems },
        doing: { id: "doing", list: doingItems }
      };
  });

  const setarOrdenacao = () => columns.doing.list.forEach((campo: ICampo, index: number) => (campo.ordenacao = index));

  const toggleConfimarModal = (): void => setExibirConfirmarModal(!exibirConfirmarModal);
  
  useEffect(() => {
    
    if(columns.todo.list.length > 0){
      if(columns.todo.list.length > 1){
        setSelectedParametrizacoes({areasDireito: [], empresas: [], fechamentoConfiguracaoId: selectedParametrizacoes.fechamentoConfiguracaoId})
      }else if(columns.todo.list[0].campoNome == "Area de direito"){      
      setSelectedParametrizacoes({areasDireito: [], empresas: selectedParametrizacoes.empresas, fechamentoConfiguracaoId: selectedParametrizacoes.fechamentoConfiguracaoId})
      }else{
      setSelectedParametrizacoes({areasDireito: selectedParametrizacoes.areasDireito, empresas: [], fechamentoConfiguracaoId: selectedParametrizacoes.fechamentoConfiguracaoId})
}
  }

  }, [columns.todo,columns.doing]);

  useEffect(() => {
    if (!carregandoConfiguracaoTela) {

      carregarConfiguracaoTodo();
     
    }
  }, []);

  useEffect(() => {
    setarOrdenacao();
    if (columns.doing.list.length > 0) {
      setCampos(columns.doing.list);
    }
  }, [columns.doing]);

  const onDragEnd = ({ source, destination }: any) => {
    try {
      if (destination === undefined || destination === null) return null;

      if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

      const start = columns[source.droppableId];
      const end = columns[destination.droppableId];

      if (start === end) {
        const newList = start.list.filter((_: any, idx: number) => idx !== source.index);

        newList.splice(destination.index, 0, start.list[source.index]);
        const newCol = {
          id: start.id,
          list: newList,
        };

        setColumns((state: any) => ({ ...state, [newCol.id]: newCol }));
      } else {
        const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);

        const newStartCol = {
          id: start.id,
          list: newStartList,
        };
        const newEndList = end.list;

        newEndList.splice(destination.index, 0, eCampos.filter((e) => e.campo === start.list[source.index].campo)[0]);

        const newEndCol = {
          id: end.id,
          list: newEndList,
        };

        setColumns((state: any) => ({
          ...state,
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol,
        }));
        setarOrdenacao();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const alterarItem = (item: ICampo) => {
    columns.doing.list = columns.doing.list.filter((e: ICampo) => e.campo !== item.campo);
    columns.doing.list.splice(item.ordenacao, 0, item);
    setColumns({
      todo: {
        id: "todo",
        list: columns.todo.list,
      },
      doing: {
        id: "doing",
        list: columns.doing.list,
      },
    });
  };
  
  const carregarConfiguracaoTodo = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let todo: IRecuperaLista<any>;

      todo = await ConfiguracaoTelaService.obterTodo(ETelaEnum.TelaCapaEmpresa);

      let resultado: ICampo[] = [{
        Lista: [],
        campo: 990,
        campoNome: "Area de direito",
        campoTipo: ETipoCampoEnum.Texto,
        camposId: 28,
        entidade: "Area de direito",
        help: "",
        isXCampo: false,
        label: "Area de direito",
        nome: "",
        obrigatorio: false,
        ordenacao: 0,
        somenteLeitura: false,
        tabelaOrigem: 1,
        tipo: ETipoCampoEnum.Texto,
        valor: 0,
        valorId: 0
      },
      {
        Lista: [],
        campo: 28,
        campoNome: "Empresa",
        campoTipo: ETipoCampoEnum.Texto,
        camposId: 28,
        entidade: "Empresa",
        help: "",
        isXCampo: false,
        label: "Empresa",
        nome: "",
        obrigatorio: false,
        ordenacao: 0,
        somenteLeitura: false,
        tabelaOrigem: 1,
        tipo: ETipoCampoEnum.Texto,
        valor: 0,
        valorId: 0
      }
    ]
      

      setECampos(resultado);      


      
      
    } catch (error: any) {
      setECampos([]);
      setCarregandoConfiguracaoTela(false);
    }finally{
      setCarregandoConfiguracaoTela(false);

    }
  };
  
  const adicionarConfiguracao = async () => {
    try {

      setProcessamentoAoSalvar(true);
    
      FechamentoConfiguracaoService.adicionaFechamentoConfiguracaoPropriedade(selectedParametrizacoes)
      await Swal.fire({
        heightAuto: false,
        icon: 'success',
        text: `Configuração do fechamento cadastrada com sucesso!`,
        showConfirmButton: false,
        timer: 3000
    });
      setProcessamentoAoSalvar(false);
      toggleConfimarModal();
    } catch (error: any) {
      setProcessamentoAoSalvar(false);
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel cadastrar os campos.`,
        text: error.response.data.Message,
        showConfirmButton: true,
      });
    }
  };
  
  const schema = Yup.object().shape({
    nome: Yup.string().max(50, 'Maximo 50 caracteres').required('Nome é obrigatório'),
  })
  
  const formik = useFormik({
    initialValues: { nome: modeloRelatorio.nome, tipo: 13 },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false)
    }
  });

  return (
    <>
      <LayoutPageTitle title={("Configuração de Fechamento: ") + modeloRelatorio.nome} />
      <LayoutPageForButton title={""}>
        <div className="col-md-11">
        </div>
        <div className="col-md-12">
          <button disabled={processamentoAoSalvar} onClick={() => {
          toggleConfimarModal()}} type="button" className="btn btn-orange me-5">
            {!processamentoAoSalvar && (
              <>
                Confirmar?
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
              </>
            )}
            {processamentoAoSalvar && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Salvando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </LayoutPageForButton>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row mt-2">
          {Object.values(columns).map((col: any) => (
            <div className="col-md-6">
              {col.id === "doing" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      Clique para configurar os campos selecionados.
                    </h6>
                  </div>
                </p>
              )}
              {col.id === "todo" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      Clique segure e arraste para selecionar um campo.
                    </h6>
                  </div>
                </p>
              )}
              <div style={{ overflow: "auto", height: "650px" }}>
                <Column col={col} key={col.id} alterarItem={alterarItem} />
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>

      <Modal size="lg" centered={false} show={exibirConfirmarModal} onHide={toggleConfimarModal}>
        <ToastContainer/>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              Confirmar parametrização:
            </h5>
            <div onClick={() => toggleConfimarModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <ul>
            {columns.doing.list[0] ? <li>{columns.doing.list[0].campoNome}</li>: "Voce deve selecionar ao menos uma parametrização!"}
            {columns.doing.list[1] ? <li>{columns.doing.list[1].campoNome}</li>: ""}

            </ul>
          
          
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button disabled={!formik.isValid} onClick={() => {

                if(selectedParametrizacoes.fechamentoConfiguracaoId != 0){
                  if(columns.doing.list[0]) {
                    if(columns.doing.list[0].campoNome == "Empresa" && !(columns.doing.list[1]) ){
                      if(selectedParametrizacoes.empresas.length > 0){
                        adicionarConfiguracao();
                      }else{
                        notifyWarning("Você deve preencher as configurações!");

                      }
                    }
                  else if(columns.doing.list[0].campoNome == "Area de direito" && !(columns.doing.list[1]) ){
                    if(selectedParametrizacoes.areasDireito.length > 0){
                      adicionarConfiguracao();
                    }else{
                      notifyWarning("Você deve preencher as configurações!");

                    }
                  }else if(selectedParametrizacoes.areasDireito.length > 0 && selectedParametrizacoes.empresas.length > 0){
                      adicionarConfiguracao();
                  }else{
                    notifyWarning("Você deve preencher as configurações!");
                  }
                }}else{
                  navigate("/Home")
                }
              }} type="button" className="btn btn-orange ms-5">
              Salvar
            </button>

            <button
              onClick={() => toggleConfimarModal()}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FechamentoParametrizacao;
