import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ICompromisso from "../interfaces/ICompromisso";

class TipoCompromissoService {

    async obter(filtros: any): Promise<IRecuperaLista<ICompromisso>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICompromisso>>(`TipoCompromisso/RecuperaTipoCompromisso?${query}`);

        return result;
    }

    
    async alterar(nome: any, categoria:any): Promise<IRecuperaLista<ICompromisso>> {
        let { data: result } = await api.post<any>(`TipoCompromisso/AdicionaTipoCompromisso`, {nome: nome, Categoria: categoria});

        return result;
    }
}


export default new TipoCompromissoService();