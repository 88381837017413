import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IFaseProcessual from "../interfaces/IFaseProcessual";
import { AdicionaFaseProcessualRequest } from "../interfaces/Requests/FaseProcessual/AdicionaFaseProcessualRequest";
import FiltroPesquisaFaseProcessualRequest from "../interfaces/Requests/FaseProcessual/FiltroPesquisaFaseProcessualRequest";
import { EditarFaseProcessualRequest } from "../interfaces/Requests/FaseProcessual/EditarFaseProcessualRequest";

class FaseProcessualService {

    async obterFaseProcessuals(filtros: FiltroPesquisaFaseProcessualRequest): Promise<IRecuperaLista<IFaseProcessual>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFaseProcessual>>(`FaseProcessual/RecuperaFaseProcessual?${query}`);

        return result;
    }

    async adicionaFaseProcessual(adicionaFaseProcessual: AdicionaFaseProcessualRequest): Promise<IFaseProcessual> {
        let { data: result } = await api.post<IFaseProcessual>(`FaseProcessual/AdicionaFaseProcessual`, adicionaFaseProcessual);

        return result;
    }


    async alterarStatus(faseProcessualId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IFaseProcessual>(`FaseProcessual/AtualizaFaseProcessualPorId?id=${faseProcessualId}`, data);
    }

    async atualizarFaseProcessual(editarFaseProcessual: EditarFaseProcessualRequest): Promise<void> {

        let dataFaseProcessual = [
            { "op": "replace", "path": "/nome", "value": editarFaseProcessual.nome },
            { "op": "replace", "path": "/codigo", "value": editarFaseProcessual.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`FaseProcessual/AtualizaFaseProcessualPorId?id=${editarFaseProcessual.faseProcessualId}`, dataFaseProcessual)
        ]);
    }
}


export default new FaseProcessualService();