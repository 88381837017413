/* eslint-disable @typescript-eslint/no-empty-function */

import { FormikProps } from "formik";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { EModulo, returnarEnumDescricaoID } from "../../../enum";
import { useEffect, useState } from "react";

import CamposService from "../../../services/CamposService";

import { tipoPorModulo } from "../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Select from "react-select";
import FornecedoresModal from "./FornecedorModal";

interface IFornecedorFieldProps {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  modulo: EModulo;
  formik: FormikProps<any>;
}

export type optionsType = {
  label: string;
  value: number;
};

const FornecedorField = ({
  configuracaoTela,
  modulo,
  formik,
}: IFornecedorFieldProps) => {
  const [options, setOptions] = useState<optionsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fornecedor, setFornecedor] = useState<optionsType>({
    label: "",
    value: 0,
  });

  const [exibirModal, setExibirModal] = useState(false);

  useEffect(() => {
    option();
  }, []);

  const option = async (fornecedor?: optionsType) => {
    try {
      let objeto: any = {};
      objeto[`${tipoPorModulo(modulo)}Id`] = formik.values?.categoriaId;
      let options: optionsType[] = [];

      let resultado = await CamposService.obterOptions({
        controller: configuracaoTela?.campoNome,
        modulo: formik.values.modulo,
        categoriaId: objeto[`${tipoPorModulo(modulo)}Id`],
        processoId: formik.values.processoId,
        areaDireitoId: formik.values.areaDireitoId,
        tipoImovelId: formik.values.tipoImovelId,
        principalId: formik.values.principalId,
      });

      resultado.data.map((item) =>
        options.push({
          label: item.nome,
          value: item[returnarEnumDescricaoID(configuracaoTela?.campoNome)],
        })
      );

      if (fornecedor) {
        const [fornecedorOption] = options.filter(
          (option) => option.value === fornecedor.value
        );
        formik.setFieldValue("fornecedorId", fornecedorOption.value);
        setFornecedor(fornecedorOption);
      }

      setOptions(options);
    } catch (error: any) {
      setOptions([]);
    }
  };

  const handleClose = async (fornecedor?: optionsType) => {
    setLoading(true);
    setExibirModal(false);

    if (fornecedor) {
      await option(fornecedor);
    }

    setLoading(false);
  };

  return (
    <>
      {exibirModal && (
        <FornecedoresModal
          exibirModal={exibirModal}
          handleClose={(fornecedor) => handleClose(fornecedor)}
        />
      )}

      <div className="col-md-3">
        <label
          htmlFor="form-nome"
          className="form-label fw-bolder text-orange mt-3"
        >
          Fornecedor
        </label>
        <div className="row align-items-center">
          <div className="col-md-10 pe-0">
            <Select
              isLoading={loading}
              placeholder="Fornecedor"
              id="form-select"
              options={options}
              isSearchable
              value={fornecedor}
              onChange={(select) => {
                if (select) {
                  setFornecedor(select);
                  formik.setFieldValue("fornecedorId", select.value);
                }
              }}
            />
          </div>
          <div className="col-md-2 ps-2">
            <Button
              onClick={() => setExibirModal(true)}
              style={{
                color: "white",
                backgroundColor: "var(--primary-base)",
                borderColor: "var(--primary-base)",
                height: "calc(1.5em + 0.75rem + 2px)",
              }}
              className="btn btn-orange"
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-1" icon={faPlus} />
            </Button>
          </div>
        </div>
        {formik.touched.fornecedorId && formik.errors.fornecedorId && (
          <div className="fv-plugins-message-container mt-1">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.fornecedorId}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FornecedorField;
