/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, ToastContainer } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { Validate } from "validate-cnj";
import * as Yup from "yup";
import moment from "moment";
import ReactToPrint from "react-to-print";
import {
  faPowerOff,
  faPrint,
  faShare,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import IAdicionaProcessoRequest from "../../interfaces/Requests/Processo/IAdicionaProcessoRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaSeparadorTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ECamposIDEnum, ESeparadorIDEnum } from "../../enum/ECamposIDEnum";
import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import IProcesso from "../../interfaces/IProcesso";
import IEncerramento from "../../interfaces/IEncerramento";
import ProcessoService from "../../services/ProcessoService";

import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import { OrangeContext } from "../../contexts/OrangeProvider";

import EncerramentoModal from "../../components/Comum/Encerramento/EncerramentoModal";
import { EModulo, ETelaEnum } from "../../enum";
import Carregando from "../../components/Carregando";
import AndamentoListField from "../../components/Comum/Andamento/AndamentoListField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import ComunicacaoListField from "../../components/Comum/Comunicacao/ComunicacaoListField";
import CollapseCapaDinanimos from "../../components/Comum/Modulo/CollapseCapaDinanimos";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";
import PublicacaoListField from "../../components/Comum/Publicacao/PublicacaoListField";

function ProcessoImovelCapaPage() {


  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { processo, areaDireito, setProcesso, setAreaDireito, imovel } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [exibirModalEncerramento, setExibirModalEncerramento] = useState<boolean>(false);
  const [encerramento] = useState<IEncerramento>();
  const [refreshCapa, setRefreshCapa] = useState(false);
  const componentRef = useRef<any>();
  const [sumirPropriedades, setSumirPropriedades] = useState(false);

  const toggleEncerramento = () => setExibirModalEncerramento(!exibirModalEncerramento);

  const initialValues: IAdicionaProcessoRequest = {
    processoId: processo.processoId,
    areaDireitoId: processo.areaDireitoId,
    imovelId: imovel.imovelId,
    principalId: processo.processoId,

    numeroprocesso: "",
    pasta: "",
    faseProcessualId: 0,
    subareadireitoId: 0,
    tipoContingenciaId: 0,
    statusprocessoId: 0,
    classificacaoId: 0,
    ambitoId: 0,
    esferaId: 0,
    motivoencerramentoId: 0,
    instanciaId: 0,
    tipoAcaoId: 0,
    riscoId: 0,
    cidadeId: 0,
    escritorioId: 0,
    estadoId: 0,
    formaparticipacaoId: 0,
    paisId: 0,
    tipodocumentoId: 0,
    codigo: "",
    clienteId: 0,
    juizId: 0,

    datadistribuicao: null,
    datarecebimento: null,
    prazofatal: null,
    dataencerramento: null,
    dataencerramentocadastro: null,
    databaixaprovisoria: null,
    databaixaprovisoriacadastro: null,
    descricaoobjetoacao: "",
    observacoesprocesso: "",
    resumoprocesso: "",
    valorcausa: 0,

    partesContrariasList: [],
    empresasList: [],
    advogadoInternoList: [],
    advogadoList: [],
    centrosCusto: [],
    xCampoValorList: [],
    audienciaList: [],
    quantidadeArquivosSelecionados: 0,
    quantidadeDocumentosCheckList: 0

  };

  const adicionaShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required();
          if (configuracao.campoNome.toLowerCase() == ECamposIDEnum.NumeroProcesso.toLowerCase() && formik.values.esferaId == 1) {
            schema[configuracao.campoNome.toLowerCase()] = Yup.string()
              .required("Campo obrigatório")
              .test("numeroProcesso", "", function (value: any) {
                if (Validate.load(value)) {
                  return true;
                }
                return this.createError({
                  message: "Número do processo está inválido",
                });
              });
          }
          break;

        case ETipoCampoEnum.Lista:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}Id`] = Yup.number().min(1);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          schema[configuracao.campoNome.toLowerCase()] = Yup.date()
            .transform((value) => {
              return value ? moment(value).toDate() : value;
            })
            .required()
            .max(new Date());
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaShema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (processo.processoId) {

      carregarProcesso();

      carregarSeparadoresTela();

      formik.setValues({
        ...formik.values,
        processoId: processo.processoId,
        principalId: processo.processoId,
        areaDireitoId: processo.areaDireitoId,
        categoriaId: processo.areaDireitoId,
        tela: ETelaEnum.TelaCapaProcesso,
        modulo: EModulo.AreaDireito,
        imovelId: imovel.imovelId,
      })
    }

  }, [processo.processoId, refreshCapa]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo.processoId]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_capa");
    sessionStorageService.inserir(areaDireito, "areaDireito_capa");
  };

  const compartilharWhatSapp = () => {
    return `ID do Processo: *#${processo.processoId}* / ${areaDireito.areaDireitoId > 0 ? "Área de Direito: *" + areaDireito.nome + "*" : ""
      }  / Status: *${processo.statusProcessoNome == "" ? "Ativo*" : processo.statusProcessoNome}*`;
  };

  const carregarSessionStorage = async () => {
    setProcesso(JSON.parse((await sessionStorageService.obter("processo_capa")) || null));
    setAreaDireito(JSON.parse((await sessionStorageService.obter("areaDireito_capa")) || null));
  };

  const carregarProcesso = async () => {
    try {

      let resultado: IRecuperaLista<IProcesso>;
      resultado = await ProcessoService.obterProcessoPorId(processo.processoId);
      setProcesso(resultado.data[0]);
      setRefreshCapa(false);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const carregarSeparadoresTela = async () => {

    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;
      resultado = await ConfiguracaoSeparadorService.obterCapa({
        modulo: EModulo.AreaDireito,
        areaDireitoId: formik.values.areaDireitoId,

      });

      setSeparadoresTela(resultado.data);

    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (processoId: number) => {
    try {
      let resultado = await ProcessoService.adicionaFavorido(processoId);
      setProcesso({ ...processo, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar processo como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ProcessoService.removerFavorido(favoritoId);
      setProcesso({ ...processo, favoritoId: 0 });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };


  const renderSeparadores = () => {

    const components = separadoresTela.map((separadorTela, index) => {
      switch (separadorTela.codigo) {

        case ESeparadorIDEnum.ExibirPublicacao:
          return <PublicacaoListField configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirAndamento:
          return <AndamentoListField key={index.toString()} modulo={EModulo.AreaDireito} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirCompromisso:
          return <CompromissoListField key={index.toString()} modulo={EModulo.AreaDireito} setRefreshCapa={setRefreshCapa} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirComunicacao:
          return <ComunicacaoListField key={index.toString()} modulo={EModulo.AreaDireito} configuracaoTela={separadorTela} />;

        default:
          return (
            <CollapseCapaDinanimos
              key={index.toString()}
              formik={formik}
              controller={"processo"}
              setRefreshCapa={setRefreshCapa}
              separadorTela={separadorTela}
              setConfiguracoesTela={setConfiguracoesTela}
              configuracoesTela={configuracoesTela}
              separadorTelaLista={separadoresTela}
              setSeparadorTelaLista={setSeparadoresTela}
            />
          );
      }
    });

    return components;
  };

  return (
    <>
      <ToastContainer />

      <EncerramentoModal
        exibirModal={exibirModalEncerramento}
        toggleModal={toggleEncerramento}
        setRefreshCapa={setRefreshCapa}
      />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>


        <LayoutPageButton
          title={`Capa #${processo.processoId}  ${areaDireito.areaDireitoId > 0 ? " - " + areaDireito.nome : ""} - Status: ${processo.statusProcessoId > 0 ? processo.statusProcessoNome : ""
            }`}
        >
          <>
            {!sumirPropriedades &&
              !carregandoConfiguracoesTela && (
                <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => setRefreshCapa(!refreshCapa)}>
                  {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faHistory} />}
                </a>
              )}

            {carregandoConfiguracoesTela && (
              <span className="indicator-progress" style={{ display: "block", fontSize: "30px", paddingLeft: "11px", paddingRight: "11px" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}

            {processo.statusProcessoId != 2 && (
              <a
                style={{ marginLeft: "-0px", cursor: "pointer", fontSize: "30px" }}
                onClick={() => {
                  setExibirModalEncerramento(!exibirModalEncerramento);
                }}
              >
                {exibirModalEncerramento && <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPowerOff} title="Encerrar o processo" />}
                {!exibirModalEncerramento && <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPowerOff} title="Encerrar o processo" />}
              </a>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon onClick={() => {
                  setSumirPropriedades(true);
                  setTimeout(() => {
                    setSumirPropriedades(false);
                  }, 3000);
                }} color={"#DC853D"} className="mx-2" icon={faPrint} title="Imprimir o processo" />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShare} />}
          </a>

          {processo.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(processo.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o processo"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(processo.processoId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o processo"
            ></a>
          )}

          <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
            <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>

        </LayoutPageButton>
        <hr />

        {carregandoConfiguracoesTela ? <Carregando /> :
          <>
            {renderSeparadores()}
            <FileListCapaField modulo={EModulo.AreaDireito} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
          </>}
      </div>
    </>
  );
}
export default ProcessoImovelCapaPage;
