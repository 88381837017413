import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaCamposXRequest from "../interfaces/Requests/CamposX/FiltroPesquisaCamposXRequest";
import { IXCampoField } from "../interfaces/IXCampoField";

class CamposXService {

    async obterCamposX(filtros: FiltroPesquisaCamposXRequest): Promise<IRecuperaLista<IXCampoField>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }
      
        let { data: result } = await api.get<IRecuperaLista<IXCampoField>>(`XCampos/RecuperaXCampo?${query}`);

        return result;
    }
}


export default new CamposXService();