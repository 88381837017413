import { Editor, Element, Transforms } from "slate";
import { CustomEditor } from "../../../../../../utils/types/slate";
import { ListOption } from "./types";
import { ReactEditor } from "slate-react";

const isListActive = (editor: CustomEditor, option: ListOption) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node: any) =>
        !Editor.isEditor(node) &&
        Element.isElement(node) &&
        node.type === option,
    })
  );

  return !!match;
};

export const listHandler = (editor: CustomEditor, option: ListOption, action?: () => void) => {
  const isActive = isListActive(editor, option);

  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) &&
      Element.isElement(node) &&
      ["numbered-list", "bulleted-list"].includes(node.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : "list-item",
    children: [{ text: "" }],
  });

  if (!isActive) {
    const block = { type: option, children: [] };
    Transforms.wrapNodes(editor, block);
  }

  action?.()
  ReactEditor.focus(editor)
};
