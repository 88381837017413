import { v4 } from "uuid";

export const defaultColors = [
  { id: v4(), colorName: "Branco", hex: "#ffffff" },
  { id: v4(), colorName: "Preto", hex: "#000000" },
  { id: v4(), colorName: "Cinza Claro", hex: "#eeeeee" },
  { id: v4(), colorName: "Cinza Médio Claro", hex: "#dddddd" },
  { id: v4(), colorName: "Cinza Médio", hex: "#cccccc" },
  { id: v4(), colorName: "Cinza Médio Escuro", hex: "#bbbbbb" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#aaaaaa" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#999999" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#888888" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#777777" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#666666" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#555555" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#444444" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#333333" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#222222" },
  { id: v4(), colorName: "Cinza Escuro", hex: "#111111" },
  { id: v4(), colorName: "Vermelho", hex: "#ff0000" },
  { id: v4(), colorName: "Vermelho Claro", hex: "#ff3333" },
  { id: v4(), colorName: "Vermelho Claro", hex: "#ff6666" },
  { id: v4(), colorName: "Vermelho Claro", hex: "#ff9999" },
  { id: v4(), colorName: "Rosa Claro", hex: "#ffcccc" },
  { id: v4(), colorName: "Laranja", hex: "#ff9900" },
  { id: v4(), colorName: "Laranja Claro", hex: "#ffad33" },
  { id: v4(), colorName: "Laranja Claro", hex: "#ffc266" },
  { id: v4(), colorName: "Laranja Claro", hex: "#ffcc99" },
  { id: v4(), colorName: "Amarelo", hex: "#ffff00" },
  { id: v4(), colorName: "Amarelo Claro", hex: "#ffff33" },
  { id: v4(), colorName: "Amarelo Claro", hex: "#ffff66" },
  { id: v4(), colorName: "Amarelo Claro", hex: "#ffff99" },
  { id: v4(), colorName: "Verde Limão", hex: "#ccff00" },
  { id: v4(), colorName: "Verde Limão Claro", hex: "#ccff33" },
  { id: v4(), colorName: "Verde Limão Claro", hex: "#ccff66" },
  { id: v4(), colorName: "Verde Limão Claro", hex: "#ccff99" },
  { id: v4(), colorName: "Verde", hex: "#00ff00" },
  { id: v4(), colorName: "Verde Claro", hex: "#33ff33" },
  { id: v4(), colorName: "Verde Claro", hex: "#66ff66" },
  { id: v4(), colorName: "Verde Claro", hex: "#99ff99" },
  { id: v4(), colorName: "Ciano", hex: "#00ffff" },
  { id: v4(), colorName: "Ciano Claro", hex: "#33ffff" },
  { id: v4(), colorName: "Ciano Claro", hex: "#66ffff" },
  { id: v4(), colorName: "Ciano Claro", hex: "#99ffff" },
  { id: v4(), colorName: "Azul Claro", hex: "#0099ff" },
  { id: v4(), colorName: "Azul Claro", hex: "#00ccff" },
  { id: v4(), colorName: "Azul Claro", hex: "#00ffff" },
  { id: v4(), colorName: "Azul Claro", hex: "#99ccff" },
  { id: v4(), colorName: "Azul", hex: "#0000ff" },
  { id: v4(), colorName: "Azul Claro", hex: "#3333ff" },
  { id: v4(), colorName: "Azul Claro", hex: "#6666ff" },
  { id: v4(), colorName: "Azul Claro", hex: "#9999ff" },
  { id: v4(), colorName: "Violeta Claro", hex: "#cc99ff" },
  { id: v4(), colorName: "Violeta", hex: "#9900ff" },
  { id: v4(), colorName: "Violeta Claro", hex: "#cc33ff" },
  { id: v4(), colorName: "Rosa Claro", hex: "#ff99ff" },
  { id: v4(), colorName: "Rosa Claro", hex: "#ff66cc" },
  { id: v4(), colorName: "Rosa Claro", hex: "#ff66ff" },
  { id: v4(), colorName: "Rosa Claro", hex: "#ff99cc" },
  { id: v4(), colorName: "Rosa Claro", hex: "#ffccff" },
  { id: v4(), colorName: "Rosa Claro", hex: "#ffcccc" },
  { id: v4(), colorName: "Laranja Claro", hex: "#ff9966" },
  { id: v4(), colorName: "Laranja Claro", hex: "#ff9933" },
  { id: v4(), colorName: "Laranja Escuro", hex: "#cc6600" },
  { id: v4(), colorName: "Laranja Escuro", hex: "#cc3300" },
  { id: v4(), colorName: "Laranja Escuro", hex: "#996600" },
  { id: v4(), colorName: "Marrom Escuro", hex: "#663300" },
  { id: v4(), colorName: "Dourado", hex: "#ffcc00" },
  { id: v4(), colorName: "Amarelo Claro", hex: "#ffffcc" },
  { id: v4(), colorName: "Bege Claro", hex: "#cccc99" },
  { id: v4(), colorName: "Marrom Escuro", hex: "#666633" },
  { id: v4(), colorName: "Marrom Escuro", hex: "#333300" },
  { id: v4(), colorName: "Marrom Escuro", hex: "#996633" }
];
