import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const PopoverSubMenuMarca = () => {
  const navigate = useNavigate();
  const tooltip = (title: string) => <Tooltip id={`button-tooltip-${title}`}> {title} </Tooltip>

  return (
    <Popover id="popover-basic">
      <Popover.Body style={{ cursor: "pointer" }}>
        <p>
          <OverlayTrigger placement="bottom" overlay={tooltip("Voltar para Capa da Marca")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Marca/Capa")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Logs</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Marca/Logs")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faExchangeAlt}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>
        </p>
      </Popover.Body>
    </Popover>
  );
};
