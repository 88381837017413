import React, { useContext, useEffect, useState } from "react";
import { faInfo, faProjectDiagram, faRobot } from "@fortawesome/free-solid-svg-icons";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsEye } from "react-icons/bs";

import { TelaFluxo } from "../../../enum";
import { ETipoTelaEnum } from "../../../enum/ETipoTelaEnum";

import ConfiguracaoFluxoFaseService from "../../../services/ConfiguracaoFluxoFaseService";

import { IAutomacao } from "../../../interfaces/IAutomacao";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { OrangeContext } from "../../../contexts/OrangeProvider";

import "./../Styles/dragdrop.css";
import Column from "./Column/Column";
import Spinner from "../../../components/Spinner";
import { notifySuccess } from "../../../components/Comum/Toast/ToastFormik";

const ConfiguracaoAutomacaoPage = () => {
  const [carregandoConfiguracaoTela, setCarregandoConfiguracaoTela] = useState<boolean>(false);

  const { fluxoFase, setTela } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [campos, setCampos] = useState<IAutomacao[]>([]);
  const [eCampos, setECampos] = useState<IAutomacao[]>([]);

  const navigate = useNavigate();

  const [columns, setColumns] = useState<any>({
    todo: {
      id: "todo",
      list: [] as IAutomacao[],
    },
    doing: {
      id: "doing",
      list: [] as IAutomacao[],
    },
  });

  const setarOrdenacao = () => columns.doing.list.forEach((automacao: IAutomacao, index: number) => (automacao.ordenacao = index));

  useEffect(() => {
    carregarConfiguracaoDoing();
    carregarConfiguracaoTodo();
  }, []);

  useEffect(() => {
    setarOrdenacao();
    if (columns.doing.list.length > 0) {
      setCampos(columns.doing.list);
    }
  }, [columns.doing]);

  useEffect(() => {
    setColumns({
      todo: {
        id: "todo",
        list: tratarECampos(eCampos, campos),
      },
      doing: {
        id: "doing",
        list: tratarCampos(campos),
      },
    });
  }, [eCampos?.length, campos?.length]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    if (start === end) {
      const newList = Array.from(start.list);
      const [movedItem] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, movedItem);

      setColumns((state: any) => ({
        ...state,
        [start.id]: { ...start, list: newList },
      }));
      
    } else {

      const newStartList = Array.from(start.list);
      const [movedItem] = newStartList.splice(source.index, 1) as [IAutomacao];

      const newEndList = Array.from(end.list);
      newEndList.splice(destination.index, 0, movedItem);

      setColumns((state: any) => ({
        ...state,
        [start.id]: { ...start, list: newStartList },
        [end.id]: { ...end, list: newEndList },
      }));

      setarOrdenacao();

      if (source.droppableId === "doing" && destination.droppableId === "todo") {
        if (movedItem.fluxosFasesAutomacaoId) {
          try {
            await ConfiguracaoFluxoFaseService.removerAutomacao(movedItem.fluxosFasesAutomacaoId);
            notifySuccess('Automação removida com sucesso.');
          } catch (error) {
            console.error(`Erro ao remover automação ID ${movedItem.fluxosFasesAutomacaoId}:`, error);
          }
        }
      }   
    }
  };

  const tratarECampos = (todo: IAutomacao[], doing: IAutomacao[]) => {
    return todo?.filter(campo => !doing.some(e => e.automacaoId === campo.automacaoId))
      .map((campo, index) => ({
        ...campo,
        ordenacao: index + 1,
      }));
  };

  const tratarCampos = (doing: IAutomacao[]) => {
    return doing.map((campo) => ({
      ...campo,
      nome: campo.nome,
      automacaoId: campo.automacaoId,
    }));
  };

  const carregarConfiguracaoTodo = async () => {
    try {
      setCarregandoConfiguracaoTela(true);

      const todo: IRecuperaLista<IAutomacao> = await ConfiguracaoFluxoFaseService.obterTodo();

      setECampos(todo.data);
    } catch (error) {
      console.error(error);
      setECampos([]);
    } finally {
      setCarregandoConfiguracaoTela(false);
    }
  };

  const carregarConfiguracaoDoing = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      const doing: IRecuperaLista<IAutomacao> = await ConfiguracaoFluxoFaseService.obterDoing(fluxoFase.fluxoFaseId || 0);

      setCampos(doing.data);
      setarOrdenacao();

    } catch (error) {
      console.error(error);
      setCampos([]);
    } finally {
      setCarregandoConfiguracaoTela(false);
    }
  };


  const popover = () => (
    <Popover id="popover-basic" style={{ marginLeft: "-20px" }}>
      <Popover.Body style={{ cursor: "pointer" }}>
        {!carregandoConfiguracaoTela ? (
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Cadastro</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(TelaFluxo(fluxoFase.modulo, ETipoTelaEnum.Cadastro));
                    navigate("/Fluxo/ConfiguracaoTela/Cadastro");
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-input"></span>
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Capa</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(TelaFluxo(fluxoFase.modulo, ETipoTelaEnum.Capa));
                    navigate("/Fluxo/ConfiguracaoTela/Capa");
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Fluxo</Tooltip>}>
              {({ ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => navigate("/Fluxo/FluxoFase")}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <FontAwesomeIcon className="mx-2 text-orange" icon={faProjectDiagram} />
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Automação</Tooltip>}>
              {({ ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => navigate("/Fluxo/ConfiguracaoTela/Automacao")}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <FontAwesomeIcon className="mx-2 text-orange" icon={faRobot} />
                </Button>
              )}
            </OverlayTrigger>
          </div>
        ) : (
          <Spinner />
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <LayoutPageTitle title={`Fluxo - ${fluxoFase.nome}`}>
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover()}>
          <a style={{ cursor: "pointer" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageTitle>

      <ToastContainer />

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row mt-2">
          {Object.values(columns).map((col: any) => (
            <div className="col-md-6" key={col.id}>
              {col.id === "doing" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique no
                      <BsEye
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="20px"
                        className="mx-2"
                      />

                      para visualizar o tutorial.
                    </h6>
                  </div>
                </p>
              )}
              {col.id === "todo" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique, segure e arraste para selecionar uma automação.
                    </h6>
                  </div>
                </p>
              )}
              <div style={{ overflow: "auto", height: "650px" }}>
                <Column col={col} key={col.id} />
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

export default ConfiguracaoAutomacaoPage;
