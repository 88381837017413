import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import GridPadrao from "../../../components/Comum/GridPadrao";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import "../Styles/icons.css";
import FiltroPesquisaAutomacaoRequest from "../../../interfaces/Requests/Automacao/FiltroPesquisaAutomacaoRequest";
import AutomacaoService from "../../../services/AutomacaoService";
import AutomacaoModal from "./AutomacaoModal";
import { EModulo } from "../../../enum";
import { IAutomacao } from "../../../interfaces/Requests/Automacao/IAutomacao";

const AutomacaoPage = () => {
  const [automacoes, setAutomacoes] = useState<IAutomacao[]>([]);

  const [automacao, setAutomacao] = useState<IAutomacao>({
    nome: '',
    codigo: '',
    automacaoId: 0,
    modulo: EModulo.Default,
    tipo: 0,
  });

  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoAutomacao, setCarregandoAutomacao] = useState(false);

  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaAutomacaoRequest>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "tipoMarcaId",
  });

  useEffect(() => {
    if (!carregandoAutomacao) carregarAutomacao(filtrosPesquisa);
  }, []);

  const carregarAutomacao = async (filtro: FiltroPesquisaAutomacaoRequest) => {
    try {
      setCarregandoAutomacao(true);
      const resultado: IRecuperaLista<IAutomacao> = await AutomacaoService.obterAutomacaos(filtro);
      setFiltrosPesquisa((oldState) => ({
        ...oldState,
        totalItems: resultado.totalRegistros,
      }));
      setAutomacoes(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message || error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoAutomacao(false);
      setLoadingInicial(false);
    }
  };

  const colunas: TableColumn<IAutomacao>[] = [
    {
      name: "Id",
      sortField: "automacaoId",
      selector: (row: IAutomacao) => row.automacaoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "AutomacaoNome",
      selector: (row: IAutomacao) => row.nome,
      sortable: true,
      wrap: true,
    },
  ];

  const onRowClicked = (row: IAutomacao) => {
    setAutomacao(row);
    navigate("/Marca/ConfiguracaoTela/Solucoes");
  };

  const handlePerRowsChange = (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => ({
      ...oldState,
      limit: currentRowsPerPage,
    }));
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => ({
      ...oldState,
      offset: (page - 1) * filtrosPesquisa.limit,
    }));
  };

  const handleSort = (column: TableColumn<IAutomacao>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => ({
      ...oldState,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    }));
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => ({
      ...oldValue,
      nome: "",
      codigo: "",
      status: 1,
    }));
  };

  const toggleModal = (carregarDados?: boolean): void => {
    if (carregarDados) {
      carregarAutomacao(filtrosPesquisa);
    }
    setExibirModal(!exibirModal);
  };

  useEffect(() => {
    if (!loadingInicial) {
      carregarAutomacao(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageTitle title="Automações">
        <button onClick={() => {
          setExibirModal(true);
        }} className="btn btn-md btn-orange">
          Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <AutomacaoModal
        exibirModal={exibirModal}
        titulo={automacao ? "Editar Registro" : "Criar Registro"}
        toggleModal={toggleModal}
        automacao={automacao}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarAutomacao(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Nome
              </label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => ({
                    ...oldState,
                    nome: e.target.value,
                  }));
                }}
                placeholder="Nome"
                type="text"
                className="form-control"
                id="form-nome"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Código
              </label>
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => ({
                    ...oldState,
                    codigo: e.target.value,
                  }));
                }}
                placeholder="Código"
                type="text"
                className="form-control"
                id="form-codigo"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status
              </label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => ({
                    ...oldState,
                    status: parseInt(e.target.value),
                  }));
                }}
                className="form-select"
                id="form-status"
              >
                <option value="0">Todos</option>
                <option value="1">Ativo</option>
                <option value="-1">Inativo</option>
              </select>
            </div>
            <div className="col-md-2 d-flex align-items-end">
              <button type="submit" className="btn btn-sm btn-orange me-2">
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-sm btn-orange"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            onRowClicked={onRowClicked}
            progressPending={carregandoAutomacao}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Automações"
            itens={automacoes}
          />
        </div>
      </div>
    </>
  );
};

export default AutomacaoPage;
