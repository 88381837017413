import { GOOGLE_MAPS_API_KEY } from '../util'

export const googleMapsApi = async () => {
  const axios = (await import('axios')).default
  const baseURL = "https://maps.googleapis.com/maps/api/"

  return axios.create({
    baseURL,
    params: {
      key: GOOGLE_MAPS_API_KEY
    }
  })
}