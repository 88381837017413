import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IJuiz from "../interfaces/IJuiz";
import { AdicionaJuizRequest } from "../interfaces/Requests/Juiz/AdicionaJuizRequest";
import FiltroPesquisaJuizRequest from "../interfaces/Requests/Juiz/FiltroPesquisaJuizRequest";
import { EditarJuizRequest } from "../interfaces/Requests/Juiz/EditarJuizRequest";

class JuizService {

    async obterJuizs(filtros: FiltroPesquisaJuizRequest): Promise<IRecuperaLista<IJuiz>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IJuiz>>(`Juiz/RecuperaJuiz?${query}`);

        return result;
    }

    async adicionaJuiz(adicionaJuiz: AdicionaJuizRequest): Promise<IJuiz> {
        let { data: result } = await api.post<IJuiz>(`Juiz/AdicionaJuiz`, adicionaJuiz);

        return result;
    }


    async alterarStatus(juizId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IJuiz>(`Juiz/AtualizaJuizPorId?id=${juizId}`, data);
    }

    async atualizarJuiz(editarJuiz: EditarJuizRequest): Promise<void> {

        let dataJuiz = [
            { "op": "replace", "path": "/nome", "value": editarJuiz.nome },
            { "op": "replace", "path": "/codigo", "value": editarJuiz.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Juiz/AtualizaJuizPorId?id=${editarJuiz.juizId}`, dataJuiz)
        ]);
    }
}


export default new JuizService();