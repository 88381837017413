import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import clsx from "clsx";

import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";
import { currencyFormatter, tipoPorModulo } from "../../utils/Utils";
import { ECamposIDEnum, returnarEnumDescricaoID } from "../../enum/ECamposIDEnum";

import { MASCARA_NUMERO_PROCESSO } from "../../utils/Constants";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";
import { ETelaEnum } from "../../enum";
import moment from "moment";

interface ICamposDinamicosPropos {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
}

const CamposCadastroDinamicos = ({ configuracaoTela, formik }: ICamposDinamicosPropos) => {
  const [campos, setCampos] = useState<any[]>([]);
  const [genericId, setGenericId] = useState<number>(0);

  const carregaLista = async () => {
    try {

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(formik.values.modulo)}Id=${formik.values.categoriaId}`);

      if (configuracaoTela.isXCampo) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaXHonorario({
          ...objeto,
          tela: ETelaEnum.TelaCadastroHonorario,
          campo: configuracaoTela.campo,
          modulo: formik.values.modulo,
          tipoHonorarioId: formik.values.tipoHonorarioId
        });
        setCampos(campos.data);
      } else {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaHonorario(
          formik.values.modulo,
          configuracaoTela?.campoNome,
          formik.values.tipoHonorarioId,
          0,
        );
        setCampos(campos.data);
      }
    } catch (error: any) {
      setCampos([]);
    }
  };

  useEffect(() => {
    if (configuracaoTela.campoTipo == ETipoCampoEnum.Lista) {
      carregaLista();
    }
  }, [formik.values.tipoHonorarioId]);

  const onChangeSelect = (value: string, nome: string) => {
    formik.setFieldTouched(`${nome.toLowerCase()}Id`, true);
    formik.setFieldValue(`${nome.toLowerCase()}Id`, parseInt(value));
    setGenericId(parseInt(value));
  };

  const renderInput = () => {
    let component = <></>;

    switch (configuracaoTela?.campoTipo) {

      case ETipoCampoEnum.Lista:
        component = (
          <>
            <select
              value={genericId}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              onChange={(e) => onChangeSelect(e.target.value, configuracaoTela?.campoNome || "")}
              placeholder={configuracaoTela?.campoNome}
              id={`form-${configuracaoTela?.campoNome}`}
            >
              <option value="0"> Selecione </option>
              {configuracaoTela.isXCampo ?
                campos?.map((item: any) => {
                  return (
                    <option key={item[`xCampoOpcaoId`]} value={item[`xCampoOpcaoId`]}>
                      {item.nome}
                    </option>
                  );
                }) :
                campos?.map((item: any) => {
                  return (
                    <option key={item[`${configuracaoTela?.campoNome.toLowerCase()}Id`]} value={item[returnarEnumDescricaoID(configuracaoTela?.campoNome || "")]}>
                      {item.nome}
                    </option>
                  );
                })}
            </select>
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}Id`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Valor:
        component = (
          <>
            <NumberFormat
              format={currencyFormatter}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              thousandSeparator={true}
              prefix={"R$"}
              defaultValue={eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`)}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Inteiro:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="number"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="checkbox"
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="date"
              value={moment(eval(`formik.values.${configuracaoTela?.campoNome.toLowerCase()}`)).format("YYYY-MM-DD")}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <textarea
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              maxLength={500}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Texto:
        component = (
          <>
            {configuracaoTela?.campoNome === ECamposIDEnum.NumeroProcesso && formik.values.esferaId == 1 ? (
              <MaskedInput
                mask={MASCARA_NUMERO_PROCESSO}
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder="NNNNNNN-DD.AAAA.J.TR.OOOO"
                type="text"
                className={clsx("form-control", {
                  "border border-danger 2px solid": configuracaoTela.obrigatorio,
                })}
              />
            ) : (

              <input
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                className={clsx("form-control", {
                  "border border-danger": configuracaoTela.obrigatorio,
                })}
                id={`form-${configuracaoTela?.campoNome}`}
              />
            )}
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;
    }
    return component;
  };

  const renderClassName = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa){
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  const popoverObrigatorio = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelperObrigatorio = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popoverObrigatorio}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  return (
    <>
      <div className={renderClassName()}>
        <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
          {configuracaoTela.label}
          <a style={{ fontSize: "12px" }}>
            {configuracaoTela.obrigatorio ? "*:" : ":"}
            {configuracaoTela.help && <PopoverHelperObrigatorio></PopoverHelperObrigatorio>}
          </a>
        </label>
        <div className="row">
          <div className="col-md-12">{renderInput()}</div>
        </div>
      </div >
    </>
  );
};

export default CamposCadastroDinamicos;
