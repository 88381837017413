import { useState, useEffect } from 'react';

import Swal from 'sweetalert2';
import { models } from 'powerbi-client';
import { ToastContainer } from 'react-toastify';
import { PowerBIEmbed } from 'powerbi-client-react';
import { faDownload, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EModulo } from '../../../enum';

import PowerBIService from '../../../services/PowerBIService';
import DashboardService from '../../../services/DashboardService';
import DocumentoService from '../../../services/DocumentoService';

import IDash from '../../../interfaces/IDash';
import IDocumento from '../../../interfaces/IDocumento';
import FiltroPesquisaDocumentoRequest from '../../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest';

import { notifySuccess } from '../Toast/ToastFormik';
import moment from 'moment';

interface IConfiguracaoPowerBI {
  modulo: EModulo,
}

export const initializeDocumento: IDocumento = {
  index: 0,
  documentoId: 0,
  clienteId: 0,
  tipoProtocoloTribunalIndex: 0,
  tipoProtocoloTribunal: "",
  nome: "",
  descricao: "",
  tamanho: 0,
  tipoDocumentoId: 0,
  tipoDocumentoNome: "",
  restrito: false,
  status: "",
  codigo: "",
  dataCadastro: new Date(),
  usuarioIdCadastro: 0,
  usuarioNomeCadastro: "",
  modulo: "",
  extensao: "",
  etiquetas: [],
  etiquetasModal: false,
};


export const initializeDash: IDash = {
  dashboardId: 0,
  nome: '',
  extensao: '',
};

const PowerBI = ({ modulo }: IConfiguracaoPowerBI) => {

  const [carregandoDashboard, setCarregandoDashboard] = useState<boolean>(false);
  const [dashboards, setDashboards] = useState<IDash[]>([]);
  const [dashboard, setDashboard] = useState<IDash>(initializeDash);

  const [documento, setDocumento] = useState<IDocumento>();
  const [filtrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    modulo: [],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [powerBI, setPowerBI] = useState<any>();


  const embedConfiguration: models.IEmbedConfiguration = {
    type: 'report',
    embedUrl: powerBI?.embedReport[0]?.embedUrl,
    accessToken: powerBI?.embedToken?.token,
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: true,
      navContentPaneEnabled: true,
    },
  };

  useEffect(() => {

    carregarDashboard();

  }, []);

  useEffect(() => {

    if (dashboard.dashboardId > 0) {

      carregarPowerBI();

      carregarDocumento(dashboard.dashboardId);

    } else {

      setDocumento(initializeDocumento);

      setDashboard(initializeDash);

    }
  }, [dashboard]);


  useEffect(() => {

    if (documento?.documentoId)
      notifySuccess(`Documento ${dashboard.nomeSemExtencao} disponível para download.`);

  }, [documento]);

  const confirmarSolicitacao = async (): Promise<void> => {
    Swal.fire({
      title: "Seleciona a extenção do arquivo",
      icon: 'info',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: ".pdf",
      denyButtonText: `.pptx`,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        solicitar('pdf');
      } else if (result.isDenied) {
        solicitar('pptx');
      }
    });
  }


  const confirmarBaixar = async (documentoId: number): Promise<void> => {
    Swal.fire({
      title: "Deseja fazer uma nova solicitação?",
      icon: 'info',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Baixar arquivo atual?",
      denyButtonText: `Nova solicitação?`,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        baixar(documentoId);
      } else if (result.isDenied) {
        confirmarSolicitacao();
      }
    });
  }

  const solicitar = async (extensao: string): Promise<void> => {

    Swal.fire({
      heightAuto: false,
      icon: 'info',
      title: 'Solicitando arquivo de dashboard.',
      showConfirmButton: false,
      timer: 3000,
    });

    Swal.showLoading();

    try {

      await PowerBIService.solicitar({
        dashboardId: dashboard.dashboardId,
        nome: dashboard.nome,
        extensao
      });

      Swal.hideLoading();

      notifySuccess(`Arquivo de dashboard solicitado.`);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: 'Não foi possível baixar arquivos',
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        timer: 4000,
        icon: 'error',
        showConfirmButton: false
      });
    }
  }

  const carregarDashboard = async () => {
    try {

      setCarregandoDashboard(true);
      let resultado = await DashboardService.obterDashboard(modulo);

      setDashboards(resultado.data);
      setCarregandoDashboard(false);

    } catch (error: any) {
      setCarregandoDashboard(false);
    }
  };

  const carregarDocumento = async (dashboardId: number) => {
    try {

      setCarregandoDashboard(true);

      let resultado = await PowerBIService.obterPowerBiExport({ ...filtrosPesquisaArquivos, dashboardId: dashboardId });

      setDocumento(resultado.data);

      setCarregandoDashboard(false);

    } catch (error: any) {
      setCarregandoDashboard(false);
    }
  };

  const carregarPowerBI = async () => {
    try {

      setCarregandoDashboard(true);
      let resultado = await DashboardService.obterPowerBI(dashboard.dashboardId, modulo);

      setPowerBI(resultado);
      setCarregandoDashboard(false);

    } catch (error: any) {
      setPowerBI(null);
      setCarregandoDashboard(false);
    }
  };


  const baixar = async (documentoId: number) => {

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });

    Swal.showLoading();

    try {

      let resultado = await DocumentoService.obterArquivo(documentoId);

      await DocumentoService.download(documentoId)

        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";

          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }

          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", `${resultado?.data?.find(e => e)?.nome}`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })

        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      Swal.close();
    }
  };

  const onChange = (value: any, texto: string) => {
    setDashboard({
      dashboardId: parseInt(value),
      nome: `${texto}.pdf`,
      nomeSemExtencao: texto,
      extensao: '',
    });
  };

  return (
    <>
      <ToastContainer />

      <div className="row mt-3">
        <div className="input-group">
          <div className="col-md-4 mb-10" style={{ cursor: "pointer" }}>
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Informar Dashboard:
            </label>
            <div className="input-group mb-3">
              <select
                name="dashboardId"
                value={dashboard.dashboardId}
                className={"form-control"}
                onChange={(event: any) => onChange(event.target.value, event.target[event.target.selectedIndex].text)}
              >
                <option value="0"> Selecione...</option>
                {dashboards && dashboards.map((dash) => {
                  return (
                    <option key={dash.dashboardId} value={dash.dashboardId}>
                      {dash.nome}
                    </option>
                  );
                })}
              </select>

              {carregandoDashboard ? (
                <span className="indicator-progress" style={{ display: "block" }}>
                  <span className="spinner-border spinner-border-sm align-middle ms-2 text-orange"></span>
                </span>
              ) : (

                <>
                  {documento?.documentoId ?
                    <>
                      <div className="input-group-append">
                        <button
                          onClick={() => confirmarBaixar(documento.documentoId)}
                          disabled={carregandoDashboard || !documento.documentoId}
                          className={"btn btn-orange"}>
                          {!carregandoDashboard && (
                            <>
                              {moment(documento.dataCadastro).format('DD/MM/YYYY')} <FontAwesomeIcon color={"white"} className="mx-2" icon={faDownload} />
                            </>
                          )}
                        </button>
                      </div>
                    </> :
                    <>
                      <div className="input-group-append">
                        <button
                          onClick={() => confirmarSolicitacao()}
                          disabled={carregandoDashboard || !dashboard.dashboardId}
                          className={"btn btn-orange"}>
                          {!carregandoDashboard && (
                            <>
                              Solicitar <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
                            </>
                          )}
                        </button>
                      </div>
                    </>
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {dashboard.dashboardId > 0 &&
        <div className="row mt-3">
          <div className="col-md-12 mt-3">
            <div className={`report-container-fullscreen`}>
              <PowerBIEmbed embedConfig={embedConfiguration} cssClassName={`report-container-fullscreen`} />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PowerBI;
