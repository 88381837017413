
import { Line} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from "chartjs-plugin-zoom";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    TimeScale,
    Title,   
    Tooltip,
    Legend,
    TooltipModel,
    TooltipItem,    
    
  } from 'chart.js'
  
import {ChartOptions, ChartData } from 'chart.js';
import IWidgetDataSet, { IWidgetDado } from "../interfaces/IWidgetDataSet";
import { DadoGenerico, TimeDelta } from '../../types';
import React, { useState } from 'react';
import gerarCor from '../../utils/Colors';
import { IGraficoOptions } from './GraficoWidget';
import autocolors from 'chartjs-plugin-autocolors';
import { valorAbreviado } from '../../../../utils/Utils';



ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,    
    Legend,
    ChartDataLabels,
    autocolors,
    zoomPlugin    
)

export default function LineChart(props: { dados: IWidgetDataSet, options: IGraficoOptions }) {    
    const [ posicaoLegenda, setPosicaoLegenda ] = useState(props.options?.posicaoLegenda || 'top');
    const [ tamanho, setTamanho ] = useState(props.options?.tamanho || 187);
    const [ habilitarZoom, setHabilitarZoom ] = useState(props.options?.habilitarZoom || false);
    const [ escalaIndividual, setEscalaIndividual ] = useState(props.options?.escalaIndividual || false);
    const [ data, setData ] = useState<any>();

    let dataSet = props.dados;        
    let dados = dataSet.dados as IWidgetDado[];

    const isValorTimeDelta = ((dados[0]?.valor)  as unknown as DadoGenerico)?.tipo === 'timeDelta'
    const isMultiDataset = dados[0]?.etiqueta ? true : false

    const ref = React.useRef();

    function refresh() {
        const chart = ref.current as any         
        chart.data.datasets.map( (dataset: any) => {
            dataset.data.map((v: number, index: number) => {
                dataset.data[index] = Math.random() * 100000000 
            })
        })
        chart.update();
    }

    function corFundo(index: number, limit:number): string {
        return gerarCor(index, limit, 1)
    }
    function corBorda(index: number, limit:number): string {
        return gerarCor(index, limit, 1)
    }

    function getOptions() {       
        const chartOptions: ChartOptions<'line'> = {
            responsive: true,          
            maintainAspectRatio: props.options?.mostrarMaisNoZoom ? true : false,            
            scales:{
                yAxis:{
                    ticks: {
                        callback: function(label, index, labels) {                            
                            if(isValorTimeDelta) {
                                const td = new TimeDelta(typeof label === 'string'?parseInt(label):label)
                                return td.toString("%X%D").length > 0? td.toString("%X%D"): td.toString("%h:%m:%s");
                            }
                            return label
                        }
                    },
                    type: 'linear',
                    
                }
            },
            plugins: {
                zoom: {
                    zoom: {
                      wheel: {
                        enabled: habilitarZoom
                      },
                      mode: "y",
                    },
                    pan: {
                      enabled: habilitarZoom,
                      mode: "y",                      
                    }
                },
                datalabels: {
                    display: false
                },
                legend: {
                    display: false,                                      
                },
                tooltip: {
                    callbacks: {                        
                        label: function(this: TooltipModel<'line'>, i: TooltipItem<'line'>): string| string[] {
                            if(isValorTimeDelta){
                                const data:number = Math.floor(this.dataPoints[0].raw as number);
                                const td = new TimeDelta(data);
                                return td.toString('%Y%X%D%h:%m:%s');
                            }
                            return i.formattedValue;
                        }
                    }
                }
            },
        }

        if(isMultiDataset) {
            const yaxis = {}   

            if(escalaIndividual) {
                dados.map((line, index) => {
                    const name = 'y' + (index + 1)
                    Object.assign(yaxis, {
                        [`${name}`]:{
                            display: false,
                            type: 'linear',
                            position: 'left',
                            stack: 'demo',
                            stackWeight: 2,                        
                          },
                    });               
                })
            }         

             chartOptions.scales = {
                y: {
                display: escalaIndividual ? false : true,               
                },
                ...yaxis
            }
            chartOptions.plugins = {
                autocolors: {
                    enabled: true,
                },
                legend:{                    
                    display: true,                    
                    labels: {
                        filter(item:any, data:any) {
                            if(item.text.length > 20) {
                                item.text = item.text.substring(0,20) + '...';
                            }                           
                            return true;
                        },
                        boxWidth: 15,                                               
                    },
                    maxWidth: 20,
                    position: posicaoLegenda || 'top',
                },               
                datalabels: {
                    display: false,
                    align:'end',
                    anchor: 'end',
                    backgroundColor: 'var(--secondary-base)', 
                    textAlign: 'center',
                    borderWidth: 5,                    
                    borderRadius: 5,                    
                    color: 'white',
                    formatter: function(value,context) {                        
                    if(props.options?.tipoDeDado === 'moeda') {
                        return 'R$ '+ valorAbreviado(value,1);
                        // return '\n '+ value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
                    }
                    return value

                    },                         
                    font: {
                    weight: 'normal',
                    },
                    offset: 0,
                    padding: 0
                },
                zoom: {
                    zoom: {
                      wheel: {
                        enabled: habilitarZoom
                      },
                      mode: "y",
                    },
                    pan: {
                      enabled: habilitarZoom,
                      mode: "y",                      
                    }
                }                           
            };
        }

        return chartOptions

    }

    function getDados(): ChartData<"line", (number | TimeDelta)[], string> {        
        let _datasets:any[] = [];

        if(isMultiDataset) {
            const sortedArrayOfObj = dados.sort((a, b) => {
                const um = Number(a.etiqueta)
                const dois = Number(b.etiqueta)
            return um > dois ? 1 : -1;
            });
            sortedArrayOfObj.map((dado :IWidgetDado, index) => {
                const yIds = 'y'+ (index + 1)
                _datasets.push(
                    {
                        label: dado.etiqueta,
                        data: dado.valor, 
                        yAxisID: escalaIndividual ? yIds : undefined,
                        borderWidth: 2,                       
                        tension: 0,
                        // backgroundColor: corFundo(index, dados.length),                       
                        // borderColor: corBorda(index, dados.length)
                        
                    }
                )

                

            })
            
        } else {
            _datasets.push({
                data: dados.map((dado :IWidgetDado) => {
                    if(isValorTimeDelta) {
                        return (dado.valor as unknown as TimeDelta).valueOf();
                    }
                        return dado.valor as number
                    }),                                      
                    backgroundColor: corFundo(0, 1),
                    borderWidth: 1,
                    borderColor: corBorda(0, 1)                
            })
        }
        return  {
            labels: dataSet.etiquetas as string[],
            datasets: [..._datasets]           
        };
     
    }  

    return (
        <>
            {/* <button onClick={refresh}> Click </button> */}
           
            <Line width={'100%'} ref={ref} height={ tamanho } data={getDados()} options={getOptions()}>
               
            </Line>
        </>
       
    )
}
