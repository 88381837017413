import { useSlateStatic } from "slate-react";
import React, { Fragment, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { MdCalendarMonth, MdComment, MdGroup, MdPublish, MdRefresh, MdSend } from "react-icons/md";
import {
  ActionButton,
  Actions,
  Container,
  Content,
  FormEdit,
  Triggers,
} from "./styles";

import { useNavigate, useParams } from "react-router-dom";
import { EModulo } from "../../../../enum";

import { FooterService, HeaderService } from "../../lib/slate";
import { SaveFileModal } from "../Element/components/SaveFileModal";
import { ChooseTableOptionModal } from "../ChooseTableOptionModal";
import EnviarMinutaModal from "../EnviarMinutaModal/EnviarMinutaModal";
import { Tooltip } from "../Tooltip";
import { MenuProps } from "./props";

import { useAlert } from "../../hooks/useAlert";
import { useUpload } from "../../hooks/useUpload";
import { useTenant } from "../../hooks/useTenant";
import { usePublish } from "../../hooks/usePublish";
import { useProvider } from "../../hooks/useProvider";
import { useMenuOptions } from "../../hooks/useMenuOptions";

import FluxoModal from "../../../Fluxo/Modals/FluxoModal";
import { DynamicProviderUrl } from "../../util/getDynamicProvider";

type ActionButton = {
  showRefreshButtonText: boolean;
  showPublishButtonText: boolean;
};

export function Menu(props: MenuProps): JSX.Element {
  const fileRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const editor = useSlateStatic();

  const { version, setTitle, customFlags } = useProvider();
  const { uploadDocument } = useUpload();
  const { publish, refetch } = usePublish();
  const { tenant } = useTenant();
  const { confirm } = useAlert();

  const { options } = useMenuOptions();
  const navigate = useNavigate();
  const params = useParams<DynamicProviderUrl>();

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [showModalEnviar, setShowModalEnviar] = useState<boolean>(false);
  const [showChooseTableOption, setShowChooseTableOption] = useState(false);

  const [showModalCompromisso, setShowModalCompromisso] = useState<boolean>(false);

  const openSendMinuta = (): void => setShowModalEnviar(!showModalEnviar);
  const openModalCompromisso = (): void => setShowModalCompromisso(!showModalCompromisso);
  const openHistoryVersion = () => { if (params.caller) { navigate(`/${params.caller}/Editor/Historico`); } }
  const openRefreshMinutaConfirmation = async () => {
    const { isConfirmed } = await confirm(
      "Reprocessar minuta",
      "Tem certeza que deseja reprocessar?"
    );

    if (isConfirmed) {
      await refetch.action(tenant);
    }
  }

  const onClick = (option: string) => {
    const actions: { [key: string]: () => void } = {
      header: () => HeaderService.addHeader(editor),
      footer: () => FooterService.addFooter(editor),
      table: () => editor.selection && setShowChooseTableOption(true),
      openFile: () => fileRef.current?.click(),
      save: () => setOpenSaveModal(true),
      historyVersion: openHistoryVersion,
    };

    actions[option]();
  }

  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) {
      return;
    }
    const file = files[0];
    await uploadDocument(file);
  }

  return (
    <Fragment>
      <SaveFileModal
        editorRef={props.editorRef}
        show={openSaveModal}
        onHide={() => setOpenSaveModal(false)}
      />

      <ChooseTableOptionModal
        show={showChooseTableOption}
        onHide={() => setShowChooseTableOption(false)}
      />

      <EnviarMinutaModal
        toggleModal={openSendMinuta}
        exibirModal={showModalEnviar}
        modulo={EModulo.Contrato}
        objetoId={tenant.provider.value}
      />

      <FluxoModal
        exibirModal={showModalCompromisso}
        setExibirModal={openModalCompromisso}
        compromisso={{
          modulo: customFlags?.compromissoReadDto?.[0]?.modulo || 0,
          fluxoFaseId: customFlags?.compromissoReadDto?.[0]?.fluxoFaseId,
          contratoId: customFlags?.compromissoReadDto?.[0]?.contratoId,
          procuracaoId: customFlags?.compromissoReadDto?.[0]?.procuracaoId,
          compromissoId:
            customFlags?.compromissoReadDto?.[0]?.compromissoId || 0,
          tipoCompromissoId:
            customFlags?.compromissoReadDto?.[0]?.tipoCompromissoId || 0,
          observacaoCompromisso:
            customFlags?.compromissoReadDto?.[0]?.observacaoConclusao || "",

          codigo: "",
          tipoCompromissoNome: "",
          observacaoConclusao: "",
          dataConclusao: new Date(),
          dataCompromisso: new Date(),
          usuariosAprovadoresList: [],
          horario: "",
          grupoId: [],
        }}
        setRefresh={() => setShowModalCompromisso(false)}
      />

      <input
        onChange={onUpload}
        accept=".docx, .doc"
        type="file"
        name="insertFile"
        hidden
        ref={fileRef}
      />

      <Container>
        <div className="flex-row">
          <Content>
            <Actions>
              <FormEdit className="edit">
                <input
                  ref={inputRef}
                  style={{ width: 350 }}
                  type="text"
                  defaultValue="Sem título"
                  value={version?.title}
                  onChange={({ target }) => setTitle(target.value)}
                />
              </FormEdit>

              <Triggers>
                <Tooltip placement="bottom" content="Comentários">
                  <ActionButton
                    backgroundColor="var(--bs-info)"
                    type="button"
                    onClick={props.toggleComments}
                  >
                    <MdComment size={20} />
                  </ActionButton>
                </Tooltip>

                {!customFlags?.ocultarBotaoPublicar && (
                  <Tooltip placement="bottom" content="Publicar versão">
                    <ActionButton
                      backgroundColor="var(--bs-success)"
                      type="button"
                      onClick={() => publish.action(editor, version)}
                    >
                      {publish.isLoading ? "..." : <MdPublish size={20} />}
                    </ActionButton>
                  </Tooltip>
                )}

                {!customFlags?.ocultarBotaoReprocessarMinuta && (
                  <Tooltip placement="bottom" content="Reprocessar minuta">
                    <ActionButton
                      onClick={openRefreshMinutaConfirmation}
                      type="button"
                      className="refresh"
                      backgroundColor="var(--bs-orange)"
                    >
                      <MdRefresh size={20} />
                    </ActionButton>
                  </Tooltip>
                )}

                {!customFlags?.ocultarBotaoEnviarMinuta && (
                  <Tooltip placement="bottom" content="Enviar Minuta">
                    <ActionButton
                      onClick={openSendMinuta}
                      type="button"
                      className="refresh"
                      backgroundColor="var(--bs-orange)"
                    >
                      <MdSend size={20} />
                    </ActionButton>
                  </Tooltip>
                )}

                {customFlags?.compromissoReadDto &&
                  customFlags?.compromissoReadDto?.length > 0 && (
                    <Tooltip placement="bottom" content="Compromisso">
                      <ActionButton
                        onClick={openModalCompromisso}
                        type="button"
                        className="refresh"
                        backgroundColor="var(--bs-orange)"
                      >
                        <MdCalendarMonth size={20} />
                      </ActionButton>
                    </Tooltip>
                  )}
              </Triggers>
            </Actions>

            <nav>
              {options.map((dropdown) => (
                <Dropdown key={dropdown.name}>
                  <Dropdown.Toggle
                    style={{ cursor: "pointer" }}
                    as="div"
                    size="sm"
                    variant="text"
                  >
                    <span>{dropdown.name}</span>
                    <Dropdown.Menu>
                      {dropdown.options.map((option) => (
                        <Dropdown.Item
                          onClick={() => onClick(option.id)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 6,
                          }}
                          key={option.id}
                        >
                          {option.icon}
                          <span>{option.name}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown.Toggle>
                </Dropdown>
              ))}
            </nav>
          </Content>
        </div>
      </Container>
    </Fragment>
  );
}
