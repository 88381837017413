/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faEye, faFileExcel, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";


import TipoLicencaService from "../../services/TipoLicencaService";
import StatusLicencaService from "../../services/StatusLicencaService";

import IStatusLicenca from "../../interfaces/IStatusLicenca";

import ITipoLicenca from "../../interfaces/ITipoLicenca";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaLicencaRequest from "../../interfaces/Requests/Licenca/FiltroPesquisaLicencaRequest";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import LicencaFiltroModal from "./LicencaFiltroModal";
import GridPadrao from "../../components/Comum/GridPadrao";
import "./style/icons.css";
import LicencaService from "../../services/LicencaService";
import ITipoServico from "../../interfaces/ITipoServico";
import TipoServicoService from "../../services/TipoServicoService";
import DocumentoService from "../../services/DocumentoService";
import PreviewPDFField from "../../components/Comum/PreviewPDF/PreviewPDF";


const filtroInicial: FiltroPesquisaLicencaRequest = {
  licencaId: 0,
  tipoLicencaId: 0,

  numeroLicenca: "",
  nomeSocio: "",
  canal: "",
  fistel: "",
  observacoesLicenca: "",

  restrito: false,

  empresaId: [],
  orgaoResponsavelId: [],
  cidadeId: [],
  estadoId: [],
  cidadeNome: "",
  tipoServicoId: [],
  statusLicencaId: [],

  dataCadastroMaiorIgual: "",
  dataCadastroMenorIgual: "",

  dataEmissaoMaiorIgual: "",
  dataEmissaoMenorIgual: "",

  dataVencimentoMaiorIgual: "",
  dataVencimentoMenorIgual: "",

  limit: 10,
  offset: 0,
  sort: "licencaId",
  totalItems: 0,
};


const LicencaLimitesPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaLicencaRequest>(filtroInicial);

  const [carregamentoTipoLicenca, setCarregamentoTipoLicenca] = useState<boolean>(false);
  const [tiposLicencaOptions, setTiposLicencaOptions] = useState<any>([]);
  const [tipoLicencaSelected, setTipoLicencaSelected] = useState([]);

  const [carregamentoTipoServico, setCarregamentoTipoServico] = useState<boolean>(false);
  const [tiposServicoOptions, setTiposServicoOptions] = useState<any>([]);
  const [tipoServicoSelected, setTipoServicoSelected] = useState([]);

  const [carregamentoStatusLicenca, setCarregamentoStatusLicenca] = useState<boolean>(false);
  const [StatusLicencaOptions, setStatusLicencaOptions] = useState<any>([]);
  const [StatusLicencaSelected, setStatusLicencaSelected] = useState([]);

  const [licencas, setLicencas] = useState<ILimiteOutorga[]>([]);
  const [carregandoLicencas, setCarregandoLicencas] = useState(false);

  const [relatorioId, setRelatorioId] = useState<number | null>(null);

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);

  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>('');

  const componentRef = useRef<any>();

  useLayoutEffect(() => {
    carregarTipoLicenca();
    carregarTipoServico();
    carregarStatusLicenca();
  }, []);

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  useEffect(() => {
    if (pesquisar) carregarLicencas(filtrosPesquisa);
  }, [pesquisar]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoLicencaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoLicencaId: IdsSelected };
    });
  }, [tipoLicencaSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoServicoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoServicoId: IdsSelected };
    });
  }, [tipoServicoSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusLicencaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusLicencaId: IdsSelected };
    });
  }, [StatusLicencaSelected]);

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  const returnOptionsStatusLicenca = (array: any) => {
    array.forEach((item: any) => {
      StatusLicencaOptions.push({
        value: item.statusLicencaId,
        label: item.nome,
      });
    });
    return setStatusLicencaOptions;
  };

  const returnOptionsTiposLicenca = (array: any) => {
    array.forEach((item: any) => {
      tiposLicencaOptions.push({
        value: item.tipoLicencaId,
        label: item.nome,
      });
    });
    return setTiposLicencaOptions;
  };

  const returnOptionsTiposServico = (array: any) => {
    array.forEach((item: any) => {
      tiposServicoOptions.push({
        value: item.tipoServicoId,
        label: item.nome,
      });
    });
    return setTiposServicoOptions;
  };

  const carregarTipoLicenca = async (): Promise<void> => {
    try {
      setCarregamentoTipoLicenca(true);
      let resultadoTipoLicenca: IRecuperaLista<ITipoLicenca>;
      resultadoTipoLicenca = await TipoLicencaService.obterTipoLicencas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoLicencaId",
      });
      setTiposLicencaOptions(returnOptionsTiposLicenca(resultadoTipoLicenca.data));
      setCarregamentoTipoLicenca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoLicenca(false);
      setTiposLicencaOptions([]);
    }
  }

  const carregarTipoServico = async (): Promise<void> => {
    try {
      setCarregamentoTipoServico(true);
      let resultadoTipoServico: IRecuperaLista<ITipoServico>;
      resultadoTipoServico = await TipoServicoService.obterTipoServicos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoServicoId",
      });
      setTiposServicoOptions(returnOptionsTiposServico(resultadoTipoServico.data));
      setCarregamentoTipoServico(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoServico(false);
      setTiposServicoOptions([]);
    }
  }

  const carregarStatusLicenca = async (): Promise<void> => {
    try {
      setCarregamentoStatusLicenca(true);
      let resultado: IRecuperaLista<IStatusLicenca>;
      resultado = await StatusLicencaService.obterStatusLicencas({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusLicencaId",
      });
      setStatusLicencaOptions(returnOptionsStatusLicenca(resultado.data));
      setCarregamentoStatusLicenca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoStatusLicenca(false);
      setStatusLicencaOptions([]);
    }
  }

  const previewArquivo = async (documentoId: number, nomeArquivo: string) => {

    try {
        const resultado = await DocumentoService.previewDocument(documentoId);
        setUrlPDFDocumento(resultado.data);
        setModalExibirPDFDocumento(true);

    } catch (error: any) {
        await Swal.fire({
            heightAuto: false,
            icon: "error",
            title: `Ocorreu um erro ao obter o arquivo`,
            text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
            showConfirmButton: true,
        });
    }
};

function togglePDFDocumento(): void {
    setModalExibirPDFDocumento(!exibirModalPDFDocumento);
}

  const colunas: TableColumn<ILimiteOutorga>[] = [
    {
      name: 'Sócio',
      sortField: 'socioNome',
      selector: (row: ILimiteOutorga) => row.nomeSocio,
      sortable: false,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: 'Documento',
      sortField: 'numeroDocumento',
      selector: (row: ILimiteOutorga) => row.numeroDocumento,
      sortable: false,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: 'Veículo',
      sortField: 'tipo',
      selector: (row: ILimiteOutorga) => row.tipo,
      sortable: false,
      wrap: true
    },
    {
      name: 'UF',
      sortField: 'uf',
      selector: (row: ILimiteOutorga) => row.uf,
      sortable: false,
      wrap: true
    },
    {
      name: 'Quantidade',
      sortField: 'quantidade',
      selector: (row: ILimiteOutorga) => row.quantidade,
      sortable: false,
      wrap: true
    }
  ]

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<ILicenca>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const carregarLicencas = async (filtro: FiltroPesquisaLicencaRequest) => {
    try {
      setCarregandoLicencas(true);

      let resultado: IRecuperaLista<ILimiteOutorga>;
      resultado = await LicencaService.obterLimiteOutourga(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setLicencas(resultado.data);

      setRelatorioId(resultado.relatorioId || null);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });

      setLicencas([]);
      setRelatorioId(null);

    } finally {
      setCarregandoLicencas(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  async function baixarRelatorio(): Promise<void> {
    if (!licencas) return;

    if (relatorioId === 0 || relatorioId === null) {
      await Swal.fire({
        heightAuto: false,
        icon: 'error',
        title: `A pesquisa não retornou nenhum resultado.`,
        showConfirmButton: true,
        timer: 4000
      });
      return;
    }


    Swal.fire({
      heightAuto: false,
      icon: 'info',
      title: 'Baixando arquivos...',
      showConfirmButton: false,
    });
    Swal.showLoading();
    try {
      const arquivo = await LicencaService.gerarExcelLimiteOutourga(relatorioId || 0);
      if (arquivo) {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${new Date().toLocaleString('BR')} - Limites Outorgas.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      Swal.hideLoading();
      Swal.update({
        title: 'Baixado com sucesso',
        text: '',
        icon: 'success',
        showConfirmButton: true
      });

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: 'Não foi possível baixar arquivos',
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        timer: 4000,
        icon: 'error',
        showConfirmButton: false
      });
    }
  }

  return (
    <>
      <LayoutPageTitle title="Limites">
      </LayoutPageTitle>

      <LicencaFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <PreviewPDFField
        exibirModal={exibirModalPDFDocumento}
        toggleModal={togglePDFDocumento}
        url={urlPDFDocumento}
      />

      <div className="row mt-2">
        <div className="col-md-12" >
          <form onSubmit={(e) => e.preventDefault()}
            className="row g-3 mb-3 form">
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-tipoLicencaId" className="form-label fw-bolder text-orange">
                Identificador:
              </label>
              <input
                value={filtrosPesquisa.licencaId}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, licencaId: e.target.value };
                  });
                }}
                placeholder="ID"
                type="number"
                className={"form-control"}
                id="form-tipoLicencaId"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-canal" className="form-label fw-bolder text-orange">
                Canal:
              </label>
              <input
                value={filtrosPesquisa.canal}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, canal: e.target.value };
                  });
                }}
                placeholder="Canal"
                type="text"
                className={"form-control"}
                id="form-numero"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nomeSocio" className="form-label fw-bolder text-orange">
                Sócio:
              </label>
              <input
                value={filtrosPesquisa.nomeSocio}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nomeSocio: e.target.value };
                  });
                }}
                placeholder="Nome ou Documento"
                type="text"
                className={"form-control"}
                id="form-nomeSocio"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Tipo:
              </label>
              <MultiSelect
                isLoading={carregamentoTipoLicenca}
                disabled={carregamentoTipoLicenca}
                options={tiposLicencaOptions}
                value={tipoLicencaSelected}
                onChange={(event: any) => setTipoLicencaSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}

              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-tipoSerico" className="form-label fw-bolder text-orange">
                Veículo:
              </label>
              <MultiSelect
                isLoading={carregamentoTipoServico}
                disabled={carregamentoTipoServico}
                options={tiposServicoOptions}
                value={tipoServicoSelected}
                onChange={(event: any) => setTipoServicoSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}

              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                isLoading={carregamentoStatusLicenca}
                disabled={carregamentoStatusLicenca}
                options={StatusLicencaOptions}
                value={StatusLicencaSelected}
                onChange={(event: any) => setStatusLicencaSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}
              />
            </div>
            <div className="col-12"
              style={{ textAlign: 'right' }}>
              <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
              </button>
              <button
                onClick={() => {
                  setFiltrosPesquisa(filtroInicial);
                  setPesquisar(false);
                  setTipoLicencaSelected([]);
                  setTipoServicoSelected([]);
                  setStatusLicencaSelected([]);
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  baixarRelatorio();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
                title="Baixar Relatório"
                disabled={relatorioId === null || carregandoLicencas}
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Limpar Filtros" />
              </button>

              <button
                onClick={() => previewArquivo(2050, "row.nome")}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
                title="Verificar Regras"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEye} title="Verificar Regras" />
              </button>

              <ReactToPrint
                trigger={() => (
                  <button
                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                    disabled={relatorioId === null || carregandoLicencas}>
                    <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                  </button>
                )}
                content={() => componentRef.current}
              />

              <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
              </button>

            </div>
          </form>

          <div className="col-md-12 mb-10" style={{ overflow: !carregandoLicencas ? "auto" : "scroll", height: "600px" }}>

            <div ref={componentRef}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoLicencas}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Licenca"
                itens={licencas}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LicencaLimitesPage;

