import { EModulo } from "../enum";
import IEmpresa from "../interfaces/IEmpresa";
import IRecuperaLista from "../interfaces/IRecuperaLista";

import api from "./Api";

import FiltroPesquisaEmpresaRequest from "../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest";
import { IAdicionaSocietarioRequest } from "../interfaces/Requests/Societario/IAdicionaSocietarioRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class SocietarioService {

  public async adicionaSocietario(adicionaSocietario: IAdicionaSocietarioRequest): Promise<IEmpresa> {
    let { data: result } = await api.post<IEmpresa>(`Empresa/AdicionaEmpresa`, this.montarObjetoAdicionarSocietario(adicionaSocietario));
    return result;
  }

  public async obterSocietarios(filtros: any): Promise<IRecuperaLista<IEmpresa>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.numeroPedido) {
      query += `&numeroPedido=${filtros.numeroPedido}`;
    }

    if (filtros.tituloRegistro) {
      query += `&tituloRegistro=${filtros.tituloRegistro}`;
    }

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<IEmpresa>>(`Empresa/RecuperaListagemEmpresa?${query}`);

    return result;
  }

  public async alterarSocietarioPorCampo(campo: string, valor: any, empresaId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaSocietarioRequest>(`Empresa/AtualizaEmpresaPorId?id=${empresaId}`, request);
    }
  }

  public async alterarSocietarioPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaSocietarioRequest>(`Empresa/AtualizaXCampoValor?id=${xcampoId}`, request);
    }
  }

  public async alterarSocietarioPorCampoBolleano(campo: string, valor: any, empresaId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaSocietarioRequest>(`Empresa/AtualizaEmpresaPorId?id=${empresaId}`, request);
  }

  public async alterarSocietarioPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaSocietarioRequest>(`Empresa/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  public async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`/Favorito/RecuperaFavorito`);

    return result;
  }

  public async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  public async adicionaFavorido(empresaId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.Societario,
      empresaId: empresaId,
    });
    return result;
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  public async obterSocietarioPorId(empresaId: number): Promise<IRecuperaLista<IEmpresa>> {
    let { data: result } = await api.get<IRecuperaLista<IEmpresa>>(`/Empresa/RecuperaListagemEmpresa?&empresaId=${empresaId}`);
    return result;
  }

  public async obterCapaSocietario(empresaId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Empresa/RecuperaCapaEmpresa?empresaId=${empresaId}`
    );

    return result;
  }

  private montarObjetoAdicionarSocietario = (empresa: IAdicionaSocietarioRequest) => {
    let request: any = {};

    // XCampos
    if (empresa.xCampoValorList.length > 0) request.xCampoValorList = empresa.xCampoValorList;

    // Campos texto
    if (empresa.codigo) request.codigo = empresa.codigo;
    if (empresa.objetosocial) request.objetoSocial = empresa.objetosocial;
    if (empresa.inscricaomunicipal) request.inscricaoMunicipal = empresa.inscricaomunicipal;
    if (empresa.descricaoorgao) request.descricaoOrgao = empresa.descricaoorgao;
    if (empresa.nire) request.nire = empresa.nire;
    if (empresa.endereco) request.endereco = empresa.endereco;
    if (empresa.numero) request.numero = empresa.numero;
    if (empresa.complemento) request.complemento = empresa.complemento;
    if (empresa.bairro) request.bairro = empresa.bairro;
    if (empresa.cep) request.bairro = empresa.cep;
    if (empresa.observacoesempresa) request.observacoesempresa = empresa.observacoesempresa;
    if (empresa.telefone) request.telefone = empresa.telefone;
    if (empresa.email) request.email = empresa.email;

    // Campos Inteiro
    if (empresa.grupoeconomicoId) request.grupoEconomicoId = empresa.grupoeconomicoId;
    if (empresa.tipoestabelecimento) request.tipoEstabelecimento = empresa.tipoestabelecimento;
    if (empresa.tipoSociedadeId) request.tipoSociedadeId = empresa.tipoSociedadeId;
    if (empresa.pessoaId) request.pessoaId = empresa.pessoaId;
    if (empresa.tipoempresaId) request.tipoEmpresaId = empresa.tipoempresaId;
    if (empresa.orgaoregistroId) request.orgaoRegistroId = empresa.orgaoregistroId;
    if (empresa.cidadeId) request.cidadeId = empresa.cidadeId;
    if (empresa.porteEmpresa) request.porteEmpresa = empresa.porteEmpresa;
    if (empresa.valorcapitalsocial) request.valorCapitalSocial = empresa.valorcapitalsocial;
    if (empresa.valorcapitalintegralizado) request.valorcapitalintegralizado = empresa.valorcapitalintegralizado;
    if (empresa.valorcapitalAintegralizar) request.valorCapitalAIntegralizar = empresa.valorcapitalAintegralizar;
    if (empresa.moedaId) request.moedaId = empresa.moedaId;

    if (empresa.statusempresa) request.statusEmpresa = empresa.statusempresa;
    if (empresa.motivosituacaoId) request.motivoSituacaoId = empresa.motivosituacaoId;
    if (empresa.atividadeempresaprincipalId) request.atividadeEmpresaPrincipalId = empresa.atividadeempresaprincipalId;
    if (empresa.empresamatrizId) request.empresamatrizId = empresa.empresamatrizId;
    if (empresa.pracaId) request.pracaId = empresa.pracaId;
    if (empresa.bandeiraId) request.bandeiraId = empresa.bandeiraId;


    if (empresa.dataconstituicao) request.dataConstituicao = empresa.dataconstituicao;
    if (empresa.dataprimeiroregistro) request.dataPrimeiroRegistro = empresa.dataprimeiroregistro;
    if (empresa.datasituacao) request.dataSituacao = empresa.datasituacao;

    return request;
  };

  private montarListas = (filtros: any, query: string) => {

    if (filtros.empresaId.length > 0) filtros.empresaId.forEach((id: any) => (query += `&empresa=${id}`));
    if (filtros.departamentoId.length > 0) filtros.departamentoId.forEach((id: any) => (query += `&DepartamentoId=${id}`));
    if (filtros.apresentacaoSocietarios.length > 0) filtros.apresentacaoSocietarios.forEach((id: any) => (query += `&ApresentacaoSocietarioId=${id}`));

    if (filtros.escritorioId.length > 0) filtros.escritorioId.forEach((id: any) => (query += `&escritorioId=${id}`));
    if (filtros.naturezaSocietarioId.length > 0) filtros.naturezaSocietarioId.forEach((id: any) => (query += `&naturezaSocietarioId=${id}`));
    if (filtros.classeSocietarioId.length > 0) filtros.classeSocietarioId.forEach((id: any) => (query += `&classeSocietarioId=${id}`));

    if (filtros.classificacaoIPCId.length > 0) filtros.classificacaoIPCId.forEach((id: any) => (query += `&classificacaoIPCId=${id}`));
    if (filtros.paisId.length > 0) filtros.paisId.forEach((id: any) => (query += `&paisId=${id}`));
    if (filtros.cidadeId.length > 0) filtros.cidadeId.forEach((id: any) => (query += `&cidadeId=${id}`));

    if (filtros.statusSocietarioId.length > 0) filtros.statusSocietarioId.forEach((id: any) => (query += `&statusSocietarioId=${id}`));
    if (filtros.tipoSocietarioId.length > 0) filtros.tipoSocietarioId.forEach((id: any) => (query += `&TipoSocietarioId=${id}`));


    return query;
  };

  private montarDatas = (filtros: any, query: string) => {
    if (filtros.dataInicioVigenciaMaiorIgual && filtros.dataInicioVigenciaMenorIgual) {
      query += `&dataInicioVigenciaMaiorIgual=${filtros.dataInicioVigenciaMaiorIgual}&dataInicioVigenciaMenorIgual=${filtros.dataInicioVigenciaMenorIgual}`;
    }
    if (filtros.dataFimVigenciaMaiorIgual && filtros.dataFimVigenciaMenorIgual) {
      query += `&dataFimVigenciaMaiorIgual=${filtros.dataFimVigenciaMaiorIgual}&dataFimVigenciaMenorIgual=${filtros.dataFimVigenciaMenorIgual}`;
    }
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.dataDepositoMaiorIgual && filtros.dataDepositoMenorIgual) {
      query += `&dataDepositoMaiorIgual=${filtros.dataDepositoMaiorIgual}&dataDepositoMenorIgual=${filtros.dataDepositoMenorIgual}`;
    }
    if (filtros.dataConcessaoMaiorIgual && filtros.dataConcessaoMenorIgual) {
      query += `&dataConcessaoMaiorIgual=${filtros.dataConcessaoMaiorIgual}&dataConcessaoMenorIgual=${filtros.dataConcessaoMenorIgual}`;
    }
    if (filtros.dataPublicacaoMaiorIgual && filtros.dataPublicacaoMenorIgual) {
      query += `&dataPublicacaoMaiorIgual=${filtros.dataPublicacaoMaiorIgual}&dataPublicacaoMenorIgual=${filtros.dataPublicacaoMenorIgual}`;
    }

    return query;
  };

  public async gerarExcel(filtros: FiltroPesquisaEmpresaRequest): Promise<Blob> {
    let query = `sort=${filtros.sort}&modeloRelatorioId=${filtros.modeloRelatorioId}`;

    if (filtros.razaoSocial) {
      query += `&razaoSocial=${filtros.razaoSocial}`;
    }

    if (filtros.nomeSocio) {
      query += `&nomeSocio=${filtros.nomeSocio}`;
    }

    if (filtros.nomeRepresentante) {
      query += `&nomeRepresentante=${filtros.nomeRepresentante}`;
    }

    if (filtros.inscricaoEstadual) {
      query += `&inscricaoEstadual=${filtros.inscricaoEstadual}`;
    }

    if (filtros.cnpj) {
      query += `&cnpj=${filtros.cnpj}`;
    }

    if (filtros.nomeFantasia) {
      query += `&nomeFantasia=${filtros.nomeFantasia}`;
    }

    if (filtros.inscricaoMunicipal) {
      query += `&inscricaoMunicipal=${filtros.inscricaoMunicipal}`;
    }

    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }

    if (filtros.dataConstituicaoMaiorIgual && filtros.dataConstituicaoMenorIgual) {
      query += `&dataConstituicaoMaiorIgual=${filtros.dataConstituicaoMaiorIgual}&dataConstituicaoMenorIgual=${filtros.dataConstituicaoMenorIgual}`;
    }

    if (filtros.dataPrimeiroRegistroMaiorIgual && filtros.dataPrimeiroRegistroMenorIgual) {
      query += `&dataPrimeiroRegistroMaiorIgual=${filtros.dataPrimeiroRegistroMenorIgual}&dataPrimeiroRegistroMenorIgual=${filtros.dataPrimeiroRegistroMenorIgual}`;
    }

    if (filtros.dataSituacaoMaiorIgual && filtros.dataSituacaoMenorIgual) {
      query += `&dataSituacaoMaiorIgual=${filtros.dataSituacaoMaiorIgual}&dataSituacaoMenorIgual=${filtros.dataSituacaoMenorIgual}`;
    }

    if (filtros.grupoEconomicoId) filtros.grupoEconomicoId.forEach((id: number) => (query += `&grupoEconomicoId=${id}`));
    if (filtros.cidadeId) filtros.cidadeId.forEach((id: number) => (query += `&cidadeId=${id}`));
    if (filtros.atividadeEmpresaPrincipalId) filtros.atividadeEmpresaPrincipalId.forEach((id: number) => (query += `&atividadeEmpresaPrincipalId=${id}`));
    if (filtros.clienteId) filtros.clienteId.forEach((id: number) => (query += `&clienteId=${id}`));
    if (filtros.moedaId) filtros.moedaId.forEach((id: number) => (query += `&moedaId=${id}`));
    if (filtros.motivoSituacaoId) filtros.motivoSituacaoId.forEach((id: number) => (query += `&motivoSituacaoId=${id}`));
    if (filtros.orgaoRegistroId) filtros.orgaoRegistroId.forEach((id: number) => (query += `&orgaoRegistroId=${id}`));
    if (filtros.tipoEmpresaId) filtros.tipoEmpresaId.forEach((id: number) => (query += `&tipoEmpresaId=${id}`));

    const { data: result } = await api.get(`Empresa/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

    return result;
  }

}

export default new SocietarioService();
