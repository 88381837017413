import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IXFinderGuia  from "../interfaces/IXFinderGuia";


import FiltroPesquisaXFinderGuiaRequest from "../interfaces/Requests/XFinder/FiltroPesquisaXFinderGuiaRequest";


class XFinderGuiaService {

    async gerarExcel(relatorioId: number): Promise<Blob> {
        const { data: result } = await api.get(`XFinderGuias/GerarRelatorio?relatorioId=${relatorioId}`, { responseType: 'blob' });
        return result;
    }  


    async obterXFinderGuias(filtros: FiltroPesquisaXFinderGuiaRequest): Promise<IRecuperaLista<IXFinderGuia>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}&advogadoAutor=fechamento`

        if (filtros.xFinderGuiasId && filtros.xFinderGuiasId.length> 0) {
            query += filtros.xFinderGuiasId.map(id => `&xFinderGuiasId=${id}`).join('')
        }

        if(filtros.status != 0){
            query += `&status=${filtros.status}`;
        }

        if (filtros.nomePesquisa ){
            query += `&nomePesquisa=${filtros.nomePesquisa}`;
        }

        if (filtros.numeroProcesso){
            query += `&numeroProcesso=${filtros.numeroProcesso}`;
        }

        if (filtros.dataAudienciaMaiorIgual) {
            query += `&dataAudienciaMaiorIgual=${filtros.dataAudienciaMaiorIgual}`;
        }

        if (filtros.dataAudienciaMenorIgual) {
            query += `&dataAudienciaMenorIgual=${filtros.dataAudienciaMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }
        if (filtros.dataGuiaMaiorIgual) {
            query += `&dataGuiaMaiorIgual=${filtros.dataGuiaMaiorIgual}`;
        }

        if (filtros.dataGuiaMenorIgual) {
            query += `&dataGuiaMenorIgual=${filtros.dataGuiaMenorIgual}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IXFinderGuia>>(`XFinderGuias/RecuperaXFinderGuias?${query}`);

        return result;
    }

    async alterarStatus(xFinderGuiasId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IXFinderGuia>(`XFinderGuias/AtualizaXFinderGuiasPorId?id=${xFinderGuiasId}`, data);
    }

}


export default new XFinderGuiaService();