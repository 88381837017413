/* eslint-disable @typescript-eslint/no-explicit-any */

// React imports
import { useEffect, useState } from "react";

// Component imports
import { Modal } from "react-bootstrap";

// Interface imports
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { IRecuperaSeparadorTelaResponse, IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

// Service imports
import ConfiguracaoSeparadorService from "../../../services/ConfiguracaoSeparadorService";

// Enum imports
import CollapsePesquisaDinanimos from "./CollapsePesquisaDinanimos";
import Carregando from "../../Carregando";
import { ETelaEnum } from "../../../enum";


interface IModalProps {
  configuracoesTela: IRecuperaConfiguracaoTelaResponse[];
  setConfiguracaoesTela(configuracoesTela: IRecuperaConfiguracaoTelaResponse[]): void;

  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  formik: any;
}

const FiltroModal = ({ toggleModal, exibirModal, formik, setPesquisar, configuracoesTela, setConfiguracaoesTela }: IModalProps) => {

  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  useEffect(() => {

    if (exibirModal) {
      carregarSeparadoresTela();
    }
  }, [exibirModal]);

  const carregarSeparadoresTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);
      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;

      resultado = await ConfiguracaoSeparadorService.obterFiltro(formik.values.modulo, ETelaEnum.TelaSeparadorPesquisa);

      setSeparadoresTela(resultado.data);
    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const renderSeparadores = () => {
    return separadoresTela.map((separadorTela, index) => (
      <CollapsePesquisaDinanimos

        key={index.toString()}
        formik={formik}
        separadorTela={separadorTela}

        configuracoesTela={configuracoesTela}
        setConfiguracoesTela={setConfiguracaoesTela}

        separadorTelaLista={separadoresTela}
        setSeparadorTelaLista={setSeparadoresTela}
      />
    ));
  };

  return (
    <>
      <>
        <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
          <div className="modal-content">
            <div className="modal-body">
              {carregandoConfiguracoesTela ? <Carregando /> : renderSeparadores()}
            </div>
            <div className="modal-footer" style={{ margin: "0 auto" }}>
              <button
                type="button"
                onClick={() => {
                  setPesquisar(true);
                  toggleModal();
                }}
                className="btn btn-orange me-5"
              >
                Pesquisar
              </button>
              <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                CANCELAR
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default FiltroModal;
