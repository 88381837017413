import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";
import { useFormik } from "formik";
import { Button } from "react-bootstrap";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import AndamentoService from "../../../services/AndamentoService";

import IAndamentos from "../../../interfaces/IAndamentos";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IAndamentoTipo from "../../../interfaces/IAndamentoTipo";

import { ECamposIDEnum, EModulo } from "../../../enum";

import { OrangeContext } from "../../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FileUploadAndamentoField from "../../FileUpload/Andamento/FileUploadAndamentoField";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import { tipoPorContexto } from "../../../utils/Utils";

interface IAndamentoFieldProps {
  modulo: number;
  submit: boolean;
  setSubmit(submit: boolean): void;
}

const AndamentoFluxoField = ({
  modulo,
  submit,
  setSubmit
}: IAndamentoFieldProps) => {

  const { processo, contrato, imovel, licenca, procuracao, marca, consulta, empresa } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [importarArquivos, setImportarArquivos] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);

  const [options, setOptions] = useState<any[]>([]);

  const [exibirModalAndamento, setExibirModalAndamento] = useState<boolean>(false);
  const [carregandoAndamentoTipo, setCarregandoAndamentoTipo] = useState(false);

  const initialValues: IAndamentos = {
    andamentoId: 0,
    andamentoTipoId: 0,
    modulo: modulo,
    codigo: "",
    dataAndamento: new Date(),
    descricao: "",
    restrito: false,
    andamentoTipoNome: "",
    dataCadastro: "",
    numeroProcesso: "",
    xFinderAndamentoTipoExterno: "",
    processoParteContraria: []
  };

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const schema = () => {
    let schema: any = {};
    schema.dataAndamento = Yup.date()
      .transform((value) => {
        return value ? moment(value).toDate() : value;
      })
      .required("Campo data do andamento é obrigatório");
    schema.descricao = Yup.string().min(3, "Campo descrição está inválido").required("Campo descrição é obrigatório");
    schema.andamentoTipoId = Yup.number().min(1).required("Campo tipo de andamento é obrigatório");

    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema(),
    onSubmit: async (values, { setSubmitting }) => {
      try {

        await AndamentoService.adicionaAndamento(values);

        setSubmitting(false);

        limparTudo();

        await Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Andamento adicionado com sucesso`,
          showConfirmButton: false,
          timer: 3000
        });

      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
      }
    },
  });


  useEffect(() => {

    if (submit) {
      formik.submitForm();
      setImportarArquivos(true);
    }

  }, [submit]);

  useEffect(() => {

    if (exibirModalAndamento) carregarAndamentoTipo();

    let objeto: any = {};
    if (processo.processoId && EModulo.AreaDireito == modulo) objeto.processoId = processo.processoId;
    if (contrato.contratoId && EModulo.Contrato == modulo) objeto.contratoId = contrato.contratoId;
    if (imovel.imovelId && EModulo.Imovel == modulo) objeto.imovelId = imovel.imovelId;
    if (licenca.licencaId && EModulo.Regulatorio == modulo) objeto.licencaId = licenca.licencaId;
    if (procuracao.procuracaoId && EModulo.Procuracao == modulo) objeto.procuracaoId = procuracao.procuracaoId;
    if (empresa.empresaId && EModulo.Societario == modulo) objeto.empresaId = empresa.empresaId;
    if (marca.marcaId && EModulo.MarcaEPertences == modulo) objeto.marcaId = marca.marcaId;
    if (consulta.consultaId && EModulo.Consultivo == modulo) objeto.consultaId = consulta.consultaId;

    formik.setValues({
      ...initialValues,
      ...objeto,
    });


  }, [exibirModalAndamento]);


  


  const carregarAndamentoTipo = async () => {
    try {
      setCarregandoAndamentoTipo(true);
      let resultado: IRecuperaLista<IAndamentoTipo>;

      let tipos: any[] = [{ label: 'Selecione', value: 0 }];

      resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.TipoAndamento,
        formik.values.modulo,
        eval(`${tipoPorContexto(formik.values?.modulo)}`),
        0);

      resultado.data.map((item) => tipos.push({ label: item.nome, value: item.andamentoTipoId }));

      setOptions(tipos);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoAndamentoTipo(false);
    }
  }

  const limparTudo = () => {

    setSubmit(false);

    setImportarArquivos(false);

    formik.resetForm();
  }

  return (
    <>
      <div className="col-md-12 mt-3">
        <button
          onClick={() => setExibirModalAndamento(!exibirModalAndamento)}
          className="btn btn-orange"
        >
          {exibirModalAndamento && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
          {!exibirModalAndamento && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
        </button>  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
          Novo Andamento:
        </label>
      </div>

      {exibirModalAndamento &&
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.submitForm();
          }}
        >

          {carregandoAndamentoTipo ? (
            <>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                  Tipo de Andamento:
                </label>
                <span className="indicator-progress" style={{ display: "block" }}>
                  <span className="spinner-border text-orange spinner-border-sm align-middle ms-2"></span>
                </span>
              </div>
            </>
          ) : (
            <div className="col-md-6 mt-3">
              <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                Tipo de Andamento:
              </label>
              <Select
                value={
                  {
                    label: options.find((e) => e.value === formik.values.andamentoTipoId)?.label,
                    value: formik.values.andamentoTipoId,
                  }
                }

                options={options}
                onChange={(option: any) => {
                  formik.setFieldTouched("andamentoTipoId", true);
                  formik.setFieldValue("andamentoTipoId", parseInt(option.value));
                }}
              />

            </div>
          )}

          <div className="col-md-6 mt-3">
            <label htmlFor="form-dataNascimento" className="form-label required fw-bolder text-orange">
              Data do Andamento:
            </label>
            <DatePicker
              locale={ptBR}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/MM/yyyy"
              className={"form-control"}
              selected={formik.values.dataAndamento}
              onChange={(date: Date) => formik.setFieldValue("dataAndamento", date)}
            />

            {formik.touched.dataAndamento && formik.errors.dataAndamento && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span style={{ fontSize: "11px" }} role="alert">
                    {formik.errors.dataAndamento}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="col-md-12 mt-3">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Descrição
            </label>
            <textarea
              {...formik.getFieldProps("descricao")}
              maxLength={5000}
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.descricao && formik.errors.descricao,
                },
                {
                  "is-valid": formik.touched.descricao && !formik.errors.descricao,
                }
              )}
              id="form-nomeUsuario"
            />
            {formik.touched.descricao && formik.errors.descricao && (
              <div className="fv-plugins-message-container mt-1">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.descricao}</span>
                </div>
              </div>
            )}
          </div>

          <div className="row mt-6 mt-3">
            <label htmlFor="form-label" className="form-label fw-bolder text-orange">
              Ser Restrito?:{" "}
              <FormCheckInput
                type="checkbox"
                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                checked={formik.values.restrito}
                onChange={() => {
                  if (formik.values.restrito) formik.setValues({ ...formik.values, restrito: false });
                  if (!formik.values.restrito) formik.setValues({ ...formik.values, restrito: true });
                }}
              />
            </label>
          </div>


          <div className="row mt-12 mt-3">
            <div className="col-md-12 mt-3">
              <Button
                onClick={() => toggleImportacaoArquivos()}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                Clique aqui para importar <FontAwesomeIcon style={{ cursor: "pointer" }} color={"white"} className="mx-2" icon={faFile} />
                {quantidadeArquivosSelecionados} importados
              </Button>

            </div>
          </div>

          <div className="row mt-12 mt-3">
            <div className="col-md-12 mt-3">

              <FileUploadAndamentoField
                modulo={modulo}
                campo={58}

                importarArquivos={importarArquivos}

                setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                exibirModal={exibirModalImportacaoArquivos}
                toggleModal={toggleImportacaoArquivos}

                processoId={processo.processoId}
                areaDireitoId={processo.areaDireitoId}

                procuracaoId={procuracao.procuracaoId}
                tipoProcuracaoId={procuracao.tipoProcuracaoId}

                contratoId={contrato.contratoId}
                tipoContratoId={contrato.tipoContratoId}

                consultaId={consulta.consultaId}
                tipoConsultaId={consulta.tipoConsultaId}

                licencaId={licenca.licencaId}
                tipoLicencaId={licenca.tipoLicencaId}

                imovelId={imovel.imovelId}
                tipoImovelId={imovel.tipoImovelId}
              />
            </div>

          </div>

        </form>
      }
    </>
  );
};

export default AndamentoFluxoField;
