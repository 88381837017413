import clsx from "clsx";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import IFornecedores from "../../../interfaces/IFornecedores";
import FornecedoresService from "../../../services/FornecedoresService";

interface IFornecedorEditModal {
  exibirModal: boolean;
  handleClose: () => void;
  fornecedor: IFornecedores;
}

const FornecedorEditModal = ({
  exibirModal,
  handleClose,
  fornecedor,
}: IFornecedorEditModal) => {
  const validarFormPermissoes = Yup.object().shape({
    codigo: Yup.string()
      .max(50, "Máximo 50 caracteres")
      .required("O Código é obrigatório."),
    codigoIntegracao: Yup.string()
      .max(50, "Máximo 50 caracteres")
      .notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      codigo: fornecedor.codigo,
      codigoIntegracao: fornecedor.codigoIntegracao ?? "",
    },
    enableReinitialize: true,
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        await FornecedoresService.atualizarFornecedor(
          fornecedor.fornecedorId.toString(),
          values
        );

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Fornecedor editado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível editar o Fornecedor`,
          text: "Ocorreu um erro inesperado.",
          showConfirmButton: true,
        });
      } finally {
        resetForm();
        handleClose();
      }
    },
  });

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Editar Fornecedor</h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Código
              </label>
              <input
                {...formik.getFieldProps("codigo")}
                placeholder="Código"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.codigo && formik.errors.codigo,
                  },
                  {
                    "is-valid": formik.touched.codigo && !formik.errors.codigo,
                  }
                )}
                id="form-codigo"
              />
              {formik.touched.codigo && formik.errors.codigo && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.codigo}</span>
                  </div>
                </div>
              )}

              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Código de Integração
              </label>
              <input
                {...formik.getFieldProps("codigoIntegracao")}
                placeholder="Código de Integração"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.codigoIntegracao &&
                      formik.errors.codigoIntegracao,
                  },
                  {
                    "is-valid":
                      formik.touched.codigoIntegracao &&
                      !formik.errors.codigoIntegracao,
                  }
                )}
                id="form-codigoIntegracao"
              />
              {formik.touched.codigoIntegracao &&
                formik.errors.codigoIntegracao && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.codigoIntegracao}</span>
                    </div>
                  </div>
                )}
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FornecedorEditModal;
