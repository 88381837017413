import { useCallback, useEffect } from "react";

interface IOutsideClickProps {
  source: HTMLElement | null;
  onClickOutside: () => void;
}

export function useOutsideClick({
  source,
  onClickOutside,
}: IOutsideClickProps) {
  const handleClick = useCallback((event: MouseEvent) => {
    const target = event.target as Node;
    if (source && !source.contains(target)) {
      onClickOutside();
    }
  }, [onClickOutside]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClick);
    return () => window.removeEventListener("mousedown", handleClick);
  }, [handleClick]);
}
