import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import {
  faCheckCircle,
  faEraser,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import FiltroPesquisaGruposRequest from "../../../../interfaces/Requests/GrupoUsuarios/FiltroPesquisaGruposRequest";
import IGrupoUsuarios from "../../../../interfaces/IGrupoUsuarios";
import GrupoUsuariosService from "../../../../services/GrupoUsuariosService";
import Swal from "sweetalert2";
import { TableColumn } from "react-data-table-component";
import GridUsuarios from "./components/GridUsuarios";
import ModuloService from "../../../../services/ModuloService";
import GrupoUsuariosAddModal from "./modals/GrupoUsuariosAddModal";
import GrupoUsuariosEditModal from "./modals/GrupoUsuariosEditModal";
import { useNavigate } from "react-router-dom";

type filtrosType = {
  nome: string;
  status: number;
  limit: number;
  totalItems: number;
  offset: number;
  sort: string;
  moduloId: string;
};

type filtrosModulosType = {
  nome: string;
  status: number;
  codigo: string;
  limit: number;
  totalItems: number;
  offset: number;
  sort: string;
};

export type modulosType = {
  id: number;
  nome: string;
  status: string;
};

export default function GrupoUsuariosPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalAddOpen, setModalAddOpen] = useState<boolean>(false);
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const [grupoUsuariosEdit, setGrupoUsuariosEdit] = useState<IGrupoUsuarios>({
    grupoId: 0,
    nome: "",
    status: 0,
    dataCadastro: new Date(),
    moduloId: 0,
    moduloNome: "",
    usuarios: [],
  });
  const [reset, setReset] = useState<boolean>(false);
  const [grupoUsuarios, setGrupoUsuarios] = useState<IGrupoUsuarios[]>([]);
  const [pesquisaFlag, setPesquisaFlag] = useState(false);
  const [modulos, setModulos] = useState<modulosType[]>([]);
  const [filtros, setFiltros] = useState<FiltroPesquisaGruposRequest>({
    nome: "",
    status: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "id",
    moduloId: "0",
  });

  const navigate = useNavigate();

  useEffect(() => {
    carregarGrupos({
      nome: "",
      status: 0,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "id",
      moduloId: "0",
    });

    carregarModulos({
      nome: "",
      codigo: "",
      status: 1,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "id",
    });
  }, []);

  const renderCell = (grupo: IGrupoUsuarios) => {
    if (grupo.status === -1) {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Grupo"
              onClick={() => {
                setGrupoUsuariosEdit(grupo);
                setModalEditOpen(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Ativar Grupo"
              onClick={() => {
                handleStatusChange(grupo.grupoId.toString(), 1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faCheckCircle}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Grupo"
              onClick={() => {
                setGrupoUsuariosEdit(grupo);
                setModalEditOpen(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Desativar Grupo"
              onClick={() => {
                handleStatusChange(grupo.grupoId.toString(), -1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faTrashAlt}
            />
          </div>
        </>
      );
    }
  };

  const colunas: TableColumn<IGrupoUsuarios>[] = [
    {
      name: "Id",
      sortField: "grupoId",
      selector: (row: IGrupoUsuarios) => row.grupoId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IGrupoUsuarios) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Módulo",
      sortField: "modulo",
      selector: (row: IGrupoUsuarios) => row.moduloNome,
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const limparFiltros = async () => {
    setFiltros({
      nome: "",
      status: 0,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "id",
      moduloId: "0",
    });

    setReset(!reset);

    await carregarGrupos({
      nome: "",
      status: 0,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "id",
      moduloId: "0",
    });
  };

  const carregarGrupos = useCallback(
    async (filtros: filtrosType) => {
      try {
        setLoading(true);

        const data = await GrupoUsuariosService.obterGruposUsuarios(filtros);

        setGrupoUsuarios(data.data);

        setFiltros((oldState) => {
          return { ...oldState, totalItems: data.totalRegistros };
        });
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          title: "Não foi possível obter registros dos grupos",
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          timer: 4000,
          icon: "error",
          showConfirmButton: false,
        });
      } finally {
        setLoading(false);
        setPesquisaFlag(false);
      }
    },
    [filtros]
  );

  const carregarModulos = useCallback(async (filtro: filtrosModulosType) => {
    try {
      const modulos = await ModuloService.obterModulos(filtro);

      const mappedModulos = modulos.data.map((modulo) => {
        return {
          id: modulo.moduloId,
          nome: modulo.nome,
          status: modulo.status,
        };
      });

      setModulos(mappedModulos);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos módulos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }, []);

  const handleStatusChange = async (id: string, status: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await GrupoUsuariosService.alterarStatusGruposUsuarios(id, status);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: "Status do Grupo alterado com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });
      }

      carregarGrupos(filtros);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o status do Grupo`,
        text:
          e?.response?.data?.message ??
          "Não foi possível alterar o status do Grupo.",
        showConfirmButton: true,
      });
    }
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });

    await carregarGrupos({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((oldState) => {
      return { ...oldState, offset: (page - 1) * filtros.limit };
    });

    if (!pesquisaFlag) {
      await carregarGrupos({ ...filtros, offset: (page - 1) * filtros.limit });
    }
  };

  const handleSort = async (
    column: TableColumn<IGrupoUsuarios>,
    sortDirection: string
  ) => {
    setFiltros((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await carregarGrupos(filtros);
  };

  return (
    <>
      <LayoutPageTitle title="Grupos de Usuários">
        <button
          onClick={() => {
            setModalAddOpen(true);
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <GrupoUsuariosAddModal
        exibirModal={modalAddOpen}
        modulos={modulos.filter((modulo) => modulo.status == "1")}
        handleClose={async () => {
          setModalAddOpen(false);
          await carregarGrupos(filtros);
        }}
      />

      <GrupoUsuariosEditModal
        exibirModal={modalEditOpen}
        modulos={modulos.filter((modulo) => modulo.status == "1")}
        grupo={grupoUsuariosEdit}
        handleClose={async () => {
          setModalEditOpen(false);
          await carregarGrupos(filtros);
        }}
      />

      <div className="col-md-12">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setReset(!reset);
            await carregarGrupos(filtros);
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Nome do Grupo de Usuários:
            </label>
            <input
              value={filtros.nome}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return {
                    ...oldState,
                    nome: e.target.value,
                  };
                });
              }}
              placeholder="Nome"
              type="text"
              className="form-control"
              id="form-nome"
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Status do Grupo:
            </label>

            <select
              onChange={async (e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return { ...oldState, status: parseInt(e.target.value) };
                });
                setReset(!reset);

                await carregarGrupos({
                  ...filtros,
                  status: parseInt(e.target.value),
                });
              }}
              value={filtros.status}
              placeholder="Status"
              className="form-control"
              id="form-select"
            >
              <option value="0" label="Todos">
                Todos
              </option>

              <option value="1" label="Ativo">
                Ativo
              </option>

              <option value="-1" label="Inativo">
                Inativo
              </option>
            </select>
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Módulo do Grupo:
            </label>
            <select
              onChange={async (e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return { ...oldState, moduloId: e.target.value };
                });
                setReset(!reset);

                await carregarGrupos({ ...filtros, moduloId: e.target.value });
              }}
              value={filtros.moduloId}
              placeholder="Módulos"
              className="form-control"
              id="form-select"
            >
              <option value="0">Selecione:</option>
              {modulos.map((modulo) => {
                return (
                  <option key={modulo.id} value={modulo.id} label={modulo.nome}>
                    {modulo.nome}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-md-2">
            <div>
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridUsuarios
            onSort={handleSort}
            progressPending={loading}
            limit={filtros.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtros.totalItems}
            onRowClicked={(row) =>
              navigate(`/Administracao/Seguranca/GrupoUsuarios/${row.grupoId}`)
            }
            colunas={colunas}
            tipo="Grupos"
            itens={grupoUsuarios}
            reset={reset}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
}
