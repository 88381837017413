import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import RecuperaPessoa from "../../../../components/Comum/Pessoa/RecuperaPessoa";
import { IPessoa } from "../../../../interfaces/IPessoa";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import { AdicionaUsuarioRequest } from "../../../../interfaces/Requests/AdicionaUsuarioRequest";
import AdicionaPessoa from "../../../../components/Comum/Pessoa/AdicionaPessoa";
import UsuarioService from "../../../../services/UsuarioService";
import ClienteService from "../../../../services/ClienteService";
import ICliente from "../../../../interfaces/ICliente";
import Storage from "../../../../utils/StorageUtils";
import EscritorioSelected from "../../../../components/Comum/EscritorioSelected";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../../components/Spinner";
import IPerfilLista from "../../../../interfaces/IPerfilLista";
import PerfilService from "../../../../services/PerfilService";
import Carregando from "../../../../components/Carregando";
import Select, { ActionMeta, components, MultiValue } from "react-select";

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <FormCheckInput
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: "10px" }}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

type SelectPerfilType = {
  label: string;
  value: number;
};

function UsuarioCadastroPage() {
  const [pessoa, setPessoa] = useState<IPessoa>();
  const [tipoDocumento, setTipoDocumento] = useState<string>("");
  const [numeroDocumento, setNumeroDocumento] = useState<string>("");
  const [clientes, setClientes] = useState<ICliente[]>([]);
  const [clienteId, setClienteId] = useState(0);
  const [exibeRestricoes, setExibeRestricoes] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [perfilSelecionadoId, setPerfilSelecionadoId] = useState<
    MultiValue<SelectPerfilType>
  >([]);
  const [perfisTotal, setPerfisTotal] = useState<SelectPerfilType[]>([]);

  const navigate = useNavigate();

  const handlerPessoa = (newPessoa: IPessoa) => setPessoa(newPessoa);
  const handlerTipo = (newTipo: string) => setTipoDocumento(newTipo);
  const handlerNumeroDocumento = (newNumeroDocumento: string) =>
    setNumeroDocumento(newNumeroDocumento);

  const adicionaUsuarioRequest = Yup.object().shape({
    clienteId: Yup.number().min(1).required("Cliente é obrigatório"),
    usuarioTipo: Yup.number()
      .min(1, "Informe o tipo de usuário")
      .required("Informe o tipo de usuário"),
    nomeUsuario: Yup.string()
      .min(4, "Minimo 4 caracateres")
      .max(50, "Máximo 50 caracateres")
      .required("Nome do Usuário é obrigatório"),
    email: Yup.string()
      .email("Digite um email valido")
      .required("Email é obrigatório"),
  });

  const initialValues: AdicionaUsuarioRequest = {
    clienteId: 0,
    pessoaId: 0,
    email: "",
    nomeUsuario: "",
    escritorios: [],
    usuarioTipo: 0,
    exibirProcessoRestrito: false,
    exibirContratoRestrito: false,
    exibirDocumentoRestrito: false,
    exibirLicencaRestrito: false,
    exibirConsultaRestrito: false,
    exibirImovelRestrito: false,
    exibirProcuracaoRestrito: false,
    exibirMarcaRestrito: false,
    alterarCompromissosDeTerceiros: false,
    PerfisUsuarioId: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaUsuarioRequest,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const perfisFilter = perfilSelecionadoId
          .filter((perfil) => perfil.value !== 0)
          .map((perfis) => perfis.value);

        await UsuarioService.adicionaUsuario({
          ...values,
          PerfisUsuarioId: perfisFilter,
        });

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Usuário cadastrado com sucesso`,
          timer: 4000,
        });

        navigate("/Administracao/Seguranca/Usuarios/");
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar usuário`,
          text:
            error?.response?.data?.Message && typeof error.response.data.Message
              ? error.response.data.Message
              : "",
          showConfirmButton: true,
        });

        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (pessoa) {
      pesquisarClientes();
      formik.setFieldValue("pessoaId", pessoa.pessoaId);
    }
  }, [pessoa]);

  useEffect(() => {
    carregarPerfis();
  }, []);

  const pesquisarClientes = async () => {
    try {
      setLoading(true);

      const result = await ClienteService.obterClientes({
        clienteId: 0,
        pessoaId: 0,
        clienteNome: "",
        clienteDocumento: "",
        clienteTipo: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "clienteNome",
      });

      const user = await Storage.getUser();

      if (user) {
        const userData = await UsuarioService.obterUnicoUsuario(user?.id);

        const clientes = result.data.filter(
          (cliente) => cliente.clienteId === userData.data[0].clienteId
        );

        setClientes(clientes);
      } else {
        throw new Error("Usuário não definido.");
      }
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar os Clientes`,
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarPerfis = async () => {
    try {
      setLoading(true);

      const response: IPerfilLista = await PerfilService.obterPerfis({
        nome: "",
        status: "0",
        sort: "nome",
        offset: 0,
        limit: 0,
        totalItems: 0,
      });

      const mappedResponse = response.data.map((perfil) => {
        return {
          value: perfil.perfilUsuarioId,
          label: perfil.nome,
        };
      });

      setPerfisTotal(mappedResponse);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelection = (
    select: MultiValue<SelectPerfilType>,
    action: ActionMeta<SelectPerfilType>
  ) => {
    console.log(action);
    if (action.option?.value === 0 && action.action === "select-option") {
      setPerfilSelecionadoId([
        { label: "Selecionar todos", value: 0 },
        ...perfisTotal,
      ]);
    } else if (
      (action.removedValue?.value === 0 && action.action === "remove-value") ||
      (action.action === "deselect-option" && action.option?.value === 0)
    ) {
      setPerfilSelecionadoId([]);
    } else if (select.some((option) => option.value === 0)) {
      const selectFiltered = select.filter((option) => option.value !== 0);
      setPerfilSelecionadoId(selectFiltered);
    } else {
      setPerfilSelecionadoId(select);
    }
  };

  if (loading) {
    return <Carregando />;
  }

  return (
    <>
      <LayoutPageTitle title="Cadastrar Usuário" />

      <div className="row mt-2">
        <div className="col-md-12">
          {!pessoa && (
            <RecuperaPessoa
              handlerTipo={handlerTipo}
              handlerNumeroDocumento={handlerNumeroDocumento}
              handlerPessoa={handlerPessoa}
            />
          )}
          {pessoa && pessoa.pessoaId === 0 && (
            <AdicionaPessoa
              tipoDocumento={tipoDocumento}
              numeroDocumento={numeroDocumento}
              hrefCancelar="/Administracao/Seguranca/Usuarios"
              handlerPessoa={handlerPessoa}
            />
          )}
          {pessoa && pessoa.pessoaId > 0 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
              className="mb-3"
            >
              <div className="col-md-3 ">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder text-orange"
                >
                  Nome do Usuário
                </label>
                <input
                  {...formik.getFieldProps("nomeUsuario")}
                  placeholder="Nome do Usuário"
                  type="text"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.nomeUsuario && formik.errors.nomeUsuario,
                    },
                    {
                      "is-valid":
                        formik.touched.nomeUsuario &&
                        !formik.errors.nomeUsuario,
                    }
                  )}
                  id="form-nomeUsuario"
                />
                {formik.touched.nomeUsuario && formik.errors.nomeUsuario && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.nomeUsuario}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="form-cliente"
                  className="form-label fw-bolder text-orange"
                >
                  Cliente
                </label>
                <select
                  name="clienteId"
                  value={clienteId}
                  onChange={(e) => {
                    formik.setFieldTouched("clienteId", true);

                    if (e.target.value === "0") {
                      formik.setFieldValue("clienteId", 0);
                      setClienteId(0);
                      return;
                    }

                    formik.setFieldValue("clienteId", parseInt(e.target.value));
                    setClienteId(parseInt(e.target.value));
                  }}
                  placeholder="Nome"
                  className={clsx(
                    "form-select",
                    {
                      "is-invalid":
                        formik.touched.clienteId && formik.errors.clienteId,
                    },
                    {
                      "is-valid":
                        formik.touched.clienteId && !formik.errors.clienteId,
                    }
                  )}
                  id="form-client"
                >
                  <option value="0"> Selecione um Cliente </option>
                  {clientes.map((clienteMap) => {
                    return (
                      <option
                        key={clienteMap.clienteId}
                        value={clienteMap.clienteId}
                      >
                        {clienteMap.clienteNome}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="form-clienteTipo"
                  className="form-label required text-orange fw-bolder"
                >
                  Tipo de Usuário
                </label>
                <select
                  {...formik.getFieldProps("usuarioTipo")}
                  value={formik.values.usuarioTipo}
                  onChange={(e) => {
                    formik.setFieldTouched("usuarioTipo", true);
                    formik.setFieldValue("usuarioTipo", e.target.value, true);
                    formik.validateField("usuarioTipo");
                  }}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.usuarioTipo && formik.errors.usuarioTipo,
                    },
                    {
                      "is-valid":
                        formik.touched.usuarioTipo &&
                        !formik.errors.usuarioTipo,
                    }
                  )}
                  id="form-select"
                >
                  <option value="" label="Selecione">
                    {" "}
                    Selecione{" "}
                  </option>
                  <option value="1" label="Empresa">
                    {" "}
                    Empresa{" "}
                  </option>
                  <option value="2" label="Escritorio">
                    {" "}
                    Escritorio{" "}
                  </option>
                  <option value="3" label="XJur">
                    {" "}
                    XJur{" "}
                  </option>
                </select>
                {formik.touched.usuarioTipo && formik.errors.usuarioTipo && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.usuarioTipo}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder text-orange"
                >
                  Email
                </label>
                <input
                  {...formik.getFieldProps("email")}
                  placeholder="Email"
                  type="email"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.email && formik.errors.email,
                    },
                    {
                      "is-valid": formik.touched.email && !formik.errors.email,
                    }
                  )}
                  id="form-email"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="row mt-3">
                  <div className="col-md-6 mt-3">
                    <label
                      className="form-label fw-bolder text-orange"
                      onClick={() => setExibeRestricoes(!exibeRestricoes)}
                      style={{ cursor: "pointer" }}
                    >
                      Restrições de Acesso:
                      <FontAwesomeIcon
                        color={"var(--primary-base2)"}
                        className="mx-3"
                        icon={exibeRestricoes ? faMinus : faPlus}
                      />
                    </label>
                    <div
                      className="row"
                      style={{ display: exibeRestricoes ? "block" : "none" }}
                    >
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirProcessoRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Processos Restritos:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirProcessoRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirProcessoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirProcessoRestrito: false,
                                });
                              if (!formik.values.exibirProcessoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirProcessoRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirContratoRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Contratos Restritos:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirContratoRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirContratoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirContratoRestrito: false,
                                });
                              if (!formik.values.exibirContratoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirContratoRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirDocumentoRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Documentos Restritos:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirDocumentoRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirDocumentoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirDocumentoRestrito: false,
                                });
                              if (!formik.values.exibirDocumentoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirDocumentoRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirLicencaRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Licenças Restritas:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirLicencaRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirLicencaRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirLicencaRestrito: false,
                                });
                              if (!formik.values.exibirLicencaRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirLicencaRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirConsultaRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Consultas Restritas:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirConsultaRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirConsultaRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirConsultaRestrito: false,
                                });
                              if (!formik.values.exibirConsultaRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirConsultaRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirImovelRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Imoveis Restritos:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirImovelRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirImovelRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirImovelRestrito: false,
                                });
                              if (!formik.values.exibirImovelRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirImovelRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirProcuracaoRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Procurações Restritas:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirProcuracaoRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirProcuracaoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirProcuracaoRestrito: false,
                                });
                              if (!formik.values.exibirProcuracaoRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirProcuracaoRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-exibirMarcaRestrito"
                          className="form-label fw-bolder text-orange"
                        >
                          Exibir Marcas Restritas:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={formik.values.exibirMarcaRestrito}
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.exibirMarcaRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirMarcaRestrito: false,
                                });
                              if (!formik.values.exibirMarcaRestrito)
                                formik.setValues({
                                  ...formik.values,
                                  exibirMarcaRestrito: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                      <div className="mt-6 mt-3">
                        <label
                          htmlFor="form-alterarCompromissosDeTerceiros"
                          className="form-label fw-bolder text-orange"
                        >
                          Alterar Compromissos de Terceiros:{" "}
                          <FormCheckInput
                            type="checkbox"
                            checked={
                              formik.values.alterarCompromissosDeTerceiros
                            }
                            style={{
                              backgroundColor: "var(--primary-base2)",
                              borderColor: "var(--primary-base2)",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onChange={() => {
                              if (formik.values.alterarCompromissosDeTerceiros)
                                formik.setValues({
                                  ...formik.values,
                                  alterarCompromissosDeTerceiros: false,
                                });
                              if (!formik.values.alterarCompromissosDeTerceiros)
                                formik.setValues({
                                  ...formik.values,
                                  alterarCompromissosDeTerceiros: true,
                                });
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="form-clienteTipo"
                  className="form-label required text-orange fw-bolder"
                >
                  Perfis
                </label>

                <Select
                  options={[
                    { label: "Selecionar todos", value: 0 },
                    ...perfisTotal,
                  ]}
                  isMulti
                  isSearchable
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={(selected, action) => {
                    handleSelection(selected, action);
                  }}
                  placeholder="Selecione os perfis para o usuário"
                  isLoading={loading}
                  value={perfilSelecionadoId}
                />
              </div>

              {clientes.filter(
                (e) =>
                  e.clienteId === formik.values.clienteId &&
                  e.usuarioTipo === "Escritorio"
              ).length > 0 && (
                <EscritorioSelected
                  clienteId={formik.values.clienteId}
                  setFieldValue={formik.setFieldValue}
                  escritoriosSelected={formik.values.escritorios}
                />
              )}

              <div className="col-md-3 mt-5">
                <button
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                  className="btn btn-orange"
                >
                  {!formik.isSubmitting && <> Salvar </>}
                  {formik.isSubmitting && <Spinner />}
                </button>

                <button
                  onClick={() => {
                    navigate("/Administracao/Seguranca/Usuarios");
                  }}
                  disabled={formik.isSubmitting}
                  type="button"
                  className="btn btn-danger float-end"
                >
                  Cancelar
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default UsuarioCadastroPage;
