import {
  MdOutlineFormatAlignLeft,
  MdOutlineFormatAlignCenter,
  MdOutlineFormatAlignRight,
  MdOutlineFormatAlignJustify,
} from "react-icons/md";
import { AlignmentControl } from "./types";

export const alignment: AlignmentControl[] = [
  {
    type: "left",
    component: <MdOutlineFormatAlignLeft />,
  },
  {
    type: "center",
    component: <MdOutlineFormatAlignCenter />,
  },
  {
    type: "right",
    component: <MdOutlineFormatAlignRight />,
  },
  {
    type: "justify",
    component: <MdOutlineFormatAlignJustify />,
  },
];
