/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { MultiSelect } from "react-multi-select-component";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";

import IPais from "../../interfaces/IPais";
import ICidade from "../../interfaces/ICidade";
import IEmpresa from "../../interfaces/IEmpresa";
import IEscritorio from "../../interfaces/IEscritorio";
import IClasseMarca from "../../interfaces/IClasseMarca";
import IDepartamento from "../../interfaces/IDepartamento";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import INaturezaMarca from "../../interfaces/INaturezaMarca";
import IClassificacaoIPC from "../../interfaces/IClassificacaoIPC";
import IApresentacaoMarca from "../../interfaces/IApresentacaoMarca";

import PaisService from "../../services/PaisService";
import CidadeService from "../../services/CidadeService";
import EmpresaService from "../../services/EmpresaService";
import EscritorioService from "../../services/EscritorioService";
import ClasseMarcaService from "../../services/ClasseMarcaService";
import DepartamentoService from "../../services/DepartamentoService";
import NaturezaMarcaService from "../../services/NaturezaMarcaService";
import ClassificacaoIPCService from "../../services/ClassificacaoIPCService";
import ApresentacaoMarcaService from "../../services/ApresentacaoMarcaService";

import { useAuth } from "../../contexts/AuthProvider";


interface IMarcaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtro: any;
  setFiltro: (filtro: any) => void;
}

const MarcaFiltroModal = ({ toggleModal, exibirModal, filtro, setFiltro, setPesquisar }: IMarcaModalProps) => {
  const [carregamentoDepartamento, setCarregamentoDepartamento] = useState<boolean>(false);
  const [departamento, setDepartamento] = useState<any[]>([]);
  const [departamentoSelected, setDepartamentoSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);

  const [carregamentoApresentacaoMarca, setCarregamentoApresentacaoMarca] = useState<boolean>(false);
  const [apresentacoesMarca, setApresentacoesMarca] = useState<any[]>([]);
  const [apresentacaoMarcaSelected, setApresentacaoMarcaSelected] = useState([]);

  const [carregamentoClasseMarca, setCarregamentoClasseMarca] = useState<boolean>(false);
  const [classeMarca, setClasseMarca] = useState<any>([]);
  const [classeMarcaSelected, setClasseMarcaSelected] = useState([]);

  const [carregamentoEscritorios, setCarregamentoEscritorios] = useState<boolean>(false);
  const [escritorios, setEscritorios] = useState<any>([]);
  const [escritoriosSelected, setEscritoriosSelected] = useState([]);

  const [carregamentoNaturezasMarca, setCarregamentoNaturezasMarca] = useState<boolean>(false);
  const [naturezasMarca, setNaturezasMarca] = useState<any>([]);
  const [naturezasMarcaSelected, setNaturezasMarcaSelected] = useState([]);

  const [carregamentoPaises, setCarregamentoPaises] = useState<boolean>(false);
  const [paises, setPaises] = useState<any>([]);
  const [PaisesSelected, setPaisesSelected] = useState([]);

  const [carregamentoClassificacoes, setCarregamentoClassificacoes] = useState<boolean>(false);
  const [classificacoes, setClassificacoes] = useState<any>([]);
  const [classificacoesSelected, setClassificacoesSelected] = useState([]);

  const [carregamentoCidades, setCarregamentoCidades] = useState<boolean>(false);
  const [cidades, setCidades] = useState<any>([]);
  const [cidadesSelected, setCidadesSelected] = useState([]);

  const [exibirFiltrosData, setExibirFiltrosData] = useState<boolean>(false);
  const [exibirFiltrosLista, setExibirFiltrosLista] = useState<boolean>(false);

  const { user } = useAuth();

  useEffect(() => {
    if (exibirFiltrosLista) {
      carregarDepartamento();
      carregarApresentacaoMarca();
      carregarClasseMarca();
      carregarEscritorio();
      carregarNaturezaMarca();
      carregarCidade();
      carregarPais();
      carregarClassificacao();
      if (user?.clienteTipo === 'Empresa') {
        carregarEmpresa();
      }
    }
  }, [exibirFiltrosLista]);

  const carregarDepartamento = async () => {
    try {
      if (departamento.length > 0) return;
      setCarregamentoDepartamento(true);
      let resultado: IRecuperaLista<IDepartamento>;
      resultado = await DepartamentoService.obterDepartamentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "departamentoId",
      });
      setDepartamento(returnOptions(resultado.data, "departamentoId", "departamento"));
      setCarregamentoDepartamento(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoDepartamento(false);
      setDepartamento([]);
    }
  }

  const carregarEmpresa = async () => {
    try {
      if (empresa.length > 0) return;
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "empresaId",
      });
      setEmpresa(returnOptionsEmpresa(resultado.data));
      setCarregamentoEmpresa(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
  }

  const carregarApresentacaoMarca = async () => {
    try {
      if (apresentacoesMarca.length > 0) return;
      setCarregamentoApresentacaoMarca(true);
      let resultado: IRecuperaLista<IApresentacaoMarca>;
      resultado = await ApresentacaoMarcaService.obterApresentacaoMarcas({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "apresentacaoMarcaId",
        nome: "",
        totalItems: 0,
      });
      setApresentacoesMarca(returnOptions(resultado.data, "apresentacaoMarcaId", "apresentacoesMarca"));
      setCarregamentoApresentacaoMarca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoApresentacaoMarca(false);
      setApresentacoesMarca([]);
    }
  }

  const carregarClasseMarca = async () => {
    try {
      if (apresentacoesMarca.length > 0) return;
      setCarregamentoClasseMarca(true);
      let resultado: IRecuperaLista<IClasseMarca>;
      resultado = await ClasseMarcaService.obterClasseMarcas({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "classeMarcaId",
        nome: "",
        totalItems: 0,
      });
      setClasseMarca(returnOptions(resultado.data, "classeMarcaId", "classeMarca"));
      setCarregamentoClasseMarca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoClasseMarca(false);
      setApresentacoesMarca([]);
    }
  }

  const carregarEscritorio = async () => {
    try {
      if (escritorios.length > 0) return;
      setCarregamentoEscritorios(true);
      let resultado: IRecuperaLista<IEscritorio>;
      resultado = await EscritorioService.obterEscritorios({
        limit: 100,
        codigo: "",
        offset: 0,
        sort: "escritorioId",
        nome: "",
        totalItems: 0,
        clienteId: 0,
        isTelaPesquisaProcesso: true,
      });
      setEscritorios(returnOptions(resultado.data, "escritorioId", "escritorios"));
      setCarregamentoEscritorios(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEscritorios(false);
      setEscritorios([]);
    }
  }

  const carregarNaturezaMarca = async () => {
    try {
      if (naturezasMarca.length > 0) return;
      setCarregamentoNaturezasMarca(true);
      let resultado: IRecuperaLista<INaturezaMarca>;
      resultado = await NaturezaMarcaService.obterNaturezaMarcas({
        limit: 100,
        codigo: "",
        offset: 0,
        sort: "naturezaMarcaId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setNaturezasMarca(returnOptions(resultado.data, "naturezaMarcaId", "naturezasMarca"));
      setCarregamentoNaturezasMarca(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoNaturezasMarca(false);
      setNaturezasMarca([]);
    }
  }

  const carregarCidade = async () => {
    try {
      if (cidades.length > 0) return;
      setCarregamentoCidades(true);
      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidade({
        limit: 10000,
        codigo: "",
        offset: 0,
        sort: "cidadeId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setCidades(returnOptions(resultado.data, "cidadeId", "cidades"));
      setCarregamentoCidades(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoCidades(false);
      setCidades([]);
    }
  }

  const carregarPais = async () => {
    try {
      if (paises.length > 0) return;
      setCarregamentoPaises(true);
      let resultado: IRecuperaLista<IPais>;
      resultado = await PaisService.obterPais({
        limit: 100,
        codigo: "",
        offset: 0,
        sort: "paisId",
        totalItems: 0,
        status: 0,
        ddi:"",
        nome: "",
      });
      setPaises(returnOptions(resultado.data, "paisId", "paises"));
      setCarregamentoPaises(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoPaises(false);
      setPaises([]);
    }
  }
  
  const carregarClassificacao = async () => {
    try {
      if (classificacoes.length > 0) return;
      setCarregamentoClassificacoes(true);
      let resultado: IRecuperaLista<IClassificacaoIPC>;
      resultado = await ClassificacaoIPCService.obterClassificacoesIPC({
        limit: 100,
        codigo: "",
        offset: 0,
        sort: "classificacaoIPCId",
        totalItems: 0,
        status: 0,
        nome: "",
      });
      setClassificacoes(returnOptions(resultado.data, "classificacaoIPCId", "classificacoes"));
      setCarregamentoClassificacoes(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoClassificacoes(false);
      setClassificacoes([]);
    }
  }

  const returnOptionsEmpresa = (empresas: any) => {
    let empresasParse: any = [];
    empresas.forEach((item: any) => {
      empresasParse.push({
        value: item.empresaId,
        label: item.nomeFantasia,
      });
    });
    return empresasParse;
  };

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });

    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {

      case "classificacaoIPCId":
        setFiltro({ ...filtro, classificacaoIPCId: IdsSelected });
        break;

      case "cidadeId":
        setFiltro({ ...filtro, cidadeId: IdsSelected });
        break;

      case "paisId":
        setFiltro({ ...filtro, paisId: IdsSelected });
        break;

      case "naturezaMarcaId":
        setFiltro({ ...filtro, naturezaMarcaId: IdsSelected });
        break;

      case "classeMarcaId":
        setFiltro({ ...filtro, classeMarcaId: IdsSelected });
        break;

      case "departamentoId":
        setFiltro({ ...filtro, departamentoId: IdsSelected });
        break;

      case "empresa":
        setFiltro({ ...filtro, empresa: IdsSelected });
        break;

      case "apresentacaoMarca":
        setFiltro({ ...filtro, apresentacaoMarca: IdsSelected });
        break;

      case "usuarioIdCadastro":
        setFiltro({ ...filtro, usuarioIdCadastro: IdsSelected });
        break;
    }
  };

  return (
    <>
      <>
        <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
          <div className="modal-content">
            <div className="modal-body">

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosData(!exibirFiltrosData)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosData ? "-" : "+"} Filtros por datas</h5>
                </div>
              </div>
              {exibirFiltrosData && (
                <>
                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Data de Cadastro:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataCadastroMaiorIgual && filtro.dataCadastroMenorIgual
                            ? [filtro.dataCadastroMaiorIgual, filtro.dataCadastroMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataCadastroMaiorIgual: "",
                                dataCadastroMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataInicioVigencia" className="form-label fw-bolder text-orange">
                        Data de Início da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataInicioVigenciaMaiorIgual && filtro.dataInicioVigenciaMenorIgual
                            ? [filtro.dataInicioVigenciaMaiorIgual, filtro.dataInicioVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataInicioVigenciaMaiorIgual: "",
                                dataInicioVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataInicioVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataInicioVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataFimVigencia" className="form-label fw-bolder text-orange">
                        Data Fim da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataFimVigenciaMaiorIgual && filtro.dataFimVigenciaMenorIgual
                            ? [filtro.dataFimVigenciaMaiorIgual, filtro.dataFimVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataFimVigenciaMaiorIgual: "",
                                dataFimVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataFimVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataFimVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-dataAprovacao" className="form-label fw-bolder text-orange">
                        Data do Deposito:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataDepositoMaiorIgual && filtro.dataDepositoMenorIgual
                            ? [filtro.dataDepositoMaiorIgual, filtro.dataDepositoMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataDepositoMaiorIgual: "",
                                dataDepositoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataDepositoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataDepositoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataAssinatura" className="form-label fw-bolder text-orange">
                        Data da Concessão:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataConcessaoMaiorIgual && filtro.dataConcessaoMenorIgual
                            ? [filtro.dataConcessaoMaiorIgual, filtro.dataConcessaoMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataConcessaoMaiorIgual: "",
                                dataConcessaoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataConcessaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataConcessaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Data da Publicação:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataPublicacaoMaiorIgual && filtro.dataPublicacaoMenorIgual ?
                            [filtro.dataPublicacaoMaiorIgual, filtro.dataPublicacaoMenorIgual] : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataPublicacaoMaiorIgual: "",
                                dataPublicacaoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataPublicacaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataPublicacaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosLista(!exibirFiltrosLista)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosLista ? "-" : "+"} Filtros por listas</h5>
                </div>
              </div>
              {exibirFiltrosLista && (
                <>
                  <div className="row mt-6">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Departamento:
                      </label>
                      <MultiSelect
                        disabled={carregamentoDepartamento}
                        isLoading={carregamentoDepartamento}
                        options={departamento}
                        value={departamentoSelected}
                        onChange={(event: any) => {
                          setDepartamentoSelected(event);
                          setIdsSelect("departamentoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <>{(user?.clienteTipo === 'Empresa') &&
                      <div className="col-md-4 mt-3">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Empresa:
                        </label>
                        <MultiSelect
                          isLoading={carregamentoEmpresa}
                          disabled={carregamentoEmpresa}
                          options={empresa}
                          value={empresaSelected}
                          onChange={(event: any) => {
                            setEmpresaSelected(event);
                            setIdsSelect("empresa", event);
                          }}
                          labelledBy={"Selecione..."}
                          overrideStrings={{
                            selectSomeItems: "Selecione...",
                            allItemsAreSelected: "Todos selecionados",
                            selectAll: "Selecione todos",
                            search: "Pesquise",
                            selectAllFiltered: "Selecione todos (filtrados)"
                          }}
                        />
                      </div>
                    }</>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Apresentação da Marca:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoApresentacaoMarca}
                        disabled={carregamentoApresentacaoMarca}
                        options={apresentacoesMarca}
                        value={apresentacaoMarcaSelected}
                        onChange={(event: any) => {
                          setApresentacaoMarcaSelected(event);
                          setIdsSelect("apresentacaoMarca", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>


                  <div className="row mt-6">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Classes da Marca:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoClasseMarca}
                        disabled={carregamentoClasseMarca}
                        options={classeMarca}
                        value={classeMarcaSelected}
                        onChange={(event: any) => {
                          setClasseMarcaSelected(event);
                          setIdsSelect("classeMarcaId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Escritório:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoEscritorios}
                        disabled={carregamentoEscritorios}
                        options={escritorios}
                        value={escritoriosSelected}
                        onChange={(event: any) => {
                          setEscritoriosSelected(event);
                          setIdsSelect("escritorioId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Natureza da Marca:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoNaturezasMarca}
                        disabled={carregamentoNaturezasMarca}
                        options={naturezasMarca}
                        value={naturezasMarcaSelected}
                        onChange={(event: any) => {
                          setNaturezasMarcaSelected(event);
                          setIdsSelect("naturezaMarcaId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>



                  <div className="row mt-6">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Pais:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoPaises}
                        disabled={carregamentoPaises}
                        options={paises}
                        value={PaisesSelected}
                        onChange={(event: any) => {
                          setPaisesSelected(event);
                          setIdsSelect("paisId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Cidade:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoCidades}
                        disabled={carregamentoCidades}
                        options={cidades}
                        value={cidadesSelected}
                        onChange={(event: any) => {
                          setCidadesSelected(event);
                          setIdsSelect("cidadeId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Classificação IPC:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoClassificacoes}
                        disabled={carregamentoClassificacoes}
                        options={classificacoes}
                        value={classificacoesSelected}
                        onChange={(event: any) => {
                          setClassificacoesSelected(event);
                          setIdsSelect("classificacaoIPCId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>

                </>
              )}
            </div>

            <div className="modal-footer" style={{ margin: "0 auto" }}>
              <button
                type="button"
                onClick={() => {
                  setPesquisar(true);
                  toggleModal();
                }}
                className="btn btn-orange me-5"
              >
                Pesquisar
              </button>
              <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                CANCELAR
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default MarcaFiltroModal;
