import React, { useState } from "react";

export type FontContextContract = {
  fontSize: number;
  setFontSize: React.Dispatch<React.SetStateAction<number>>;

  currentStyles: React.CSSProperties;
  setCurrentStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>;

  fontFamily: string
};

export const FontContext = React.createContext({} as FontContextContract);

type FontContextProps = {
  children: React.ReactNode;
};

export const FontContextProvider = ({ children }: FontContextProps) => {
  const [fontSize, setFontSize] = useState(11);
  const [fontFamily] = useState('Calibri')
  const [currentStyles, setCurrentStyles] = useState<React.CSSProperties>({} as React.CSSProperties)

  return (
    <FontContext.Provider
      value={{ fontSize, fontFamily, setFontSize, currentStyles, setCurrentStyles }}
    >
      {children}
    </FontContext.Provider>
  );
};
