import {
  MdFolderOpen,
  MdHistory,
  MdOutlineCalendarViewDay,
  MdOutlineFileCopy,
  MdOutlineGridView,
  MdSave,
  MdTitle,
} from "react-icons/md";
import { useProvider } from "./useProvider";
import { useMemo } from "react";

type DropdownOption = {
  name: string;
  id: string;
  hide: boolean;
  options: {
    id: string;
    name: string;
    icon: JSX.Element;
    hide: boolean;
  }[];
};

export function useMenuOptions() {
  const { customFlags } = useProvider();

  const options = useMemo(() => {
    const dropdownOptions: DropdownOption[] = [
      {
        name: "Arquivo",
        hide: false,
        id: "file",
        options: [
          {
            id: "openFile",
            name: "Abrir",
            icon: <MdFolderOpen />,
            hide: customFlags?.ocultarMenuArquivoAbrir ?? false,
          },
          {
            id: "historyVersion",
            name: "Histórico de versões",
            icon: <MdHistory />,
            hide: customFlags?.ocultarMenuArquivoHistoricoVersao ?? false,
          },
          {
            id: "save",
            name: "Salvar",
            icon: <MdSave />,
            hide: customFlags?.ocultarMenuArquivoSalvar ?? false,
          },
        ],
      },
      {
        name: "Inserir",
        id: "insert",
        hide: customFlags?.ocultarMenuInserir ?? false,
        options: [
          {
            id: "header",
            name: "Cabeçalho",
            icon: <MdTitle />,
            hide: false,
          },
          {
            id: "footer",
            name: "Rodapé",
            icon: <MdOutlineCalendarViewDay />,
            hide: false,
          },
          {
            id: "table",
            name: "Tabela",
            icon: <MdOutlineGridView />,
            hide: false,
          },
          {
            id: "page",
            name: "Página",
            icon: <MdOutlineFileCopy />,
            hide: false,
          },
        ],
      },
    ];
    return dropdownOptions;
  }, [customFlags]);

  function filterHideOptions(options: DropdownOption[]): DropdownOption[] {
    return options.map((option) => {
      if (option.hide) {
        return null;
      }
      return {
        ...option,
        options: option.options.filter((opt) => !opt.hide),
      };
    }).filter(Boolean) as DropdownOption[]
  }

  return { options: filterHideOptions(options) };
}
