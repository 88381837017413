import { RenderElementProps } from "slate-react";
import { unitHelper } from "../../../../helpers/unit";

export const ParagraphElement = (props: RenderElementProps) => {
  const getFontSize = (fSize: string | number | undefined) => {
    const withoutUnit = unitHelper.removeUnit(fSize)
    if (withoutUnit === 0) { return "11pt" }
    return fSize
  }

  return (
    <p
      {...props.attributes}
      style={{
        ...props.element.style,
        paddingTop: props.element.style?.paddingTop ?? 0,
        paddingBottom: props.element.style?.paddingBottom ?? 0,
        marginTop: props.element.style?.marginTop ?? 0,
        marginBottom: props.element.style?.marginBottom ?? 0,
        fontSize: getFontSize(props.element.style?.fontSize),
        textIndent: unitHelper.removeUnit(props.element.style?.textIndent) < 0 ? 0 : props.element.style?.textIndent
      }}
    >
      {props.children}
    </p>
  );
};
