import { useContext, useEffect, useState } from "react";
import { faEraser, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IDespesa from "../../interfaces/IDespesa";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaDespesaRequest from "../../interfaces/Requests/Despesa/FiltroPesquisaDespesaRequest";

import DespesaService from "../../services/DespesaService";

import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { EModulo } from "../../enum";
import { MultiSelect } from "react-multi-select-component";
import StatusDespesaService from "../../services/StatusDespesaService";
import IStatusDespesa from "../../interfaces/IStatusDespesa";
import ITipoDespesa from "../../interfaces/ITipoDespesa";
import TipoDespesaService from "../../services/TipoDespesaService";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const DespesaPage = () => {
  const { setDespesa, despesa } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [carregandoDespesas, setCarregandoDespesas] = useState(false);
  const [despesas, setDespesas] = useState<IDespesa[]>([]);
  const [atualizaTabela, setAtualizaTabela] = useState<boolean>(false);

  const [carregamentoTipoDespesa, setCarregamentoTipoDespesa] = useState<boolean>(false);
  const [tipoDespesaOptions, setTipoDespesaOptions] = useState<any>([]);
  const [tipoDespesaSelected, setTipoDespesaSelected] = useState([]);

  const [carregamentoStatus, setCarregamentoStatus] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<any>([]);
  const [statusSelected, setStatusSelected] = useState([]);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaDespesaRequest>({
    despesaId: 0,
    tipoDespesaId: 0,
    tipoDespesaNome: "",
    modulo: despesa.modulo,
    processoId: despesa?.processoId,
    imovelId: despesa?.imovelId,
    descricao: "",
    DataEfetivaMaiorIgual: "",
    DataEfetivaMenorIgual: "",
    DataVencimentoMaiorIgual: "",
    DataVencimentoMenorIgual: "",
    DataFatalMaiorIgual: "",
    DataFatalMenorIgual: "",
    ValorPrincipalMaiorIgual: 0,
    ValorPrincipalMenorIgual: 0,
    ValorTotalMaiorIgual: 0,
    ValorTotalMenorIgual: 0,
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    statusDespesaId: 0,
    statusDespesaNome: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "despesaId",
  });

  const navigate = useNavigate();

  useEffect(() => {

    carregarDespesas(filtrosPesquisa);

    carregarStatus();

    carregarTipoDespesa();

  }, []);

  useEffect(() => {
    carregarDespesas(filtrosPesquisa);

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

  useEffect(() => {
    if (atualizaTabela) {
      setTimeout(() => {
        carregarDespesas(filtrosPesquisa);
      }, 2000);
    }
  }, [atualizaTabela]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    statusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusDespesaSelectedId: IdsSelected };
    });
  }, [statusSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoDespesaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoDespesaSelectedId: IdsSelected };
    });
  }, [tipoDespesaSelected]);

  const carregarDespesas = async (filtro: FiltroPesquisaDespesaRequest): Promise<void> => {
    try {
      setCarregandoDespesas(true);
      let resultado: IRecuperaLista<IDespesa>;

      resultado = await DespesaService.obterDespesas(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setDespesas(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setDespesas([]);
    } finally {
      setCarregandoDespesas(false);
      setAtualizaTabela(false);
    }
  }

  const carregarTipoDespesa = async () => {
    try {
      setCarregamentoTipoDespesa(true);
      let resultadoTipoDespesa: IRecuperaLista<ITipoDespesa>;
      resultadoTipoDespesa = await TipoDespesaService.obterTipoDespesas(
        {
          codigo: "",
          nome: "",
          status: 1,
          limit: 100,
          totalItems: 0,
          offset: 0,
          sort: "statusId",
        }
      );
      setTipoDespesaOptions(returnOptionsTipoDespesa(resultadoTipoDespesa.data));
      setCarregamentoTipoDespesa(false);
    } catch (error: any) {
      setCarregamentoTipoDespesa(false);
      setTipoDespesaOptions([]);
    }
  }

  const carregarStatus = async () => {
    try {
      setCarregamentoStatus(true);
      let resultado: IRecuperaLista<IStatusDespesa>;
      resultado = await StatusDespesaService.obterStatusDespesas({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusId",
      });
      setStatusOptions(returnOptionsStatus(resultado.data));
      setCarregamentoStatus(false);
    } catch (error: any) {
      setCarregamentoStatus(false);
      setStatusOptions([]);
    }
  }

  const returnOptionsStatus = (array: any) => {
    array.forEach((item: any) => {
      statusOptions.push({
        value: item.statusDespesaId,
        label: item.nome,
      });
    });
    return statusOptions;
  };

  const returnOptionsTipoDespesa = (array: any) => {
    array.forEach((item: any) => {
      tipoDespesaOptions.push({
        value: item.tipoDespesaId,
        label: item.nome,
      });
    });
    return tipoDespesaOptions;
  };

  const colunas: TableColumn<IDespesa>[] = [
    {
      name: "Id",
      sortField: "DespesaId",
      selector: (row: IDespesa) => row.despesaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: IDespesa) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Vencimento",
      sortField: "dataFatal",
      selector: (row: IDespesa) => {
        if (row.dataVencimento === null) return "-";
        const data = row.dataVencimento.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IDespesa) => row.statusDespesaNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Tipo de Despesa",
      sortField: "tipoDespesaNome",
      selector: (row: IDespesa) => row.tipoDespesaNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Valor",
      sortField: "valor",
      selector: (row: IDespesa) => {
        if (row.valorPrincipal === null) return "R$ 0,00";

        const valor = row.valorPrincipal || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },

    {
      name: "",
      cell: (despesa: IDespesa) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Detalhes da Despesa"
              onClick={() => alterarEdicao(despesa)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
    {
      name: "",
      cell: (despesa: IDespesa) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Excluir"
              onClick={() => excluir(despesa)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faTrash}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const excluir = async (row: IDespesa) => {

    try {

      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Excluir Despesa",
        text: `Você realmente deseja salvar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });
      if (result.isConfirmed) {

        Swal.showLoading();

        await DespesaService.alterarStatus(row.despesaId, 3);

        carregarDespesas(filtrosPesquisa);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Excluido com sucesso`,
          timer: 4000,
        });
      }

    } catch (error) {
      console.log(error)
    }
  };


  const alterarEdicao = (row: IDespesa) => {
    setDespesa({
      ...row,
      despesaId: row.despesaId,
      tipoDespesaId: row.tipoDespesaId,
      categoriaId: row.tipoDespesaId,

      modulo: despesa.modulo,

      processoId: despesa?.processoId,
      areaDireitoId: despesa.areaDireitoId,

      tipoImovelId: despesa.tipoImovelId,
      imovelId: despesa?.imovelId,

      empresaId: despesa.empresaId,
      atoSocietarioId: despesa.atoSocietarioId,

    });
    navigate("/Despesa/Capa");
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IDespesa>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        despesaId: 0,
        tipoDespesaId: 0,
        tipoDespesaNome: "",
        processoId: despesa?.processoId,
        imovelId: despesa?.imovelId,
        descricao: "",
        DataEfetivaMaiorIgual: "",
        DataEfetivaMenorIgual: "",
        DataVencimentoMaiorIgual: "",
        DataVencimentoMenorIgual: "",
        DataFatalMaiorIgual: "",
        DataFatalMenorIgual: "",
        ValorPrincipalMaiorIgual: 0,
        ValorPrincipalMenorIgual: 0,
        ValorTotalMaiorIgual: 0,
        ValorTotalMenorIgual: 0,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        statusDespesaId: 0,
        statusDespesaNome: "",
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: "despesaId",
      };
    });
  }

  return (
    <>
      <LayoutPageForButton title="Despesa">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={
          despesa.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}>

          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>

        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setDespesa({ ...despesa, despesaId: 0 });
            navigate("/Despesa/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>


      <div className="row mt-2">
        <div className="col-md-12">
          <CollapseFiltro titulo="Filtros" content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarDespesas(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-despesa"
          >
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data de Cadastro:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: "",
                      dataCadastroMenorIgual: "",
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  if (data_inicial instanceof Date && data_final instanceof Date) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);

                    const isoDataInicial = data_inicial.toISOString().split("T")[0] + "T00:00:00.0000";
                    const isoDataFinal = data_final.toISOString().split("T")[0] + "T23:59:59.9999";

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: isoDataInicial,
                      dataCadastroMenorIgual: isoDataFinal,
                    }));
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">
                Data Efetiva:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={
                  filtrosPesquisa.DataEfetivaMaiorIgual && filtrosPesquisa.DataEfetivaMenorIgual
                    ? [filtrosPesquisa.DataEfetivaMaiorIgual, filtrosPesquisa.DataEfetivaMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      DataEfetivaMaiorIgual: "",
                      DataEfetivaMenorIgual: "",
                    }));
                    return;
                  }
                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_inicial instanceof Date && data_final instanceof Date) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                    const isoDataInicial = data_inicial.toISOString().split("T")[0] + "T00:00:00.0000";
                    const isoDataFinal = data_final.toISOString().split("T")[0] + "T23:59:59.9999";

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      DataEfetivaMaiorIgual: isoDataInicial,
                      DataEfetivaMenorIgual: isoDataFinal,
                    }));
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">
                Data de Vencimento:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={
                  filtrosPesquisa.DataVencimentoMaiorIgual && filtrosPesquisa.DataVencimentoMenorIgual
                    ? [filtrosPesquisa.DataVencimentoMaiorIgual, filtrosPesquisa.DataVencimentoMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      DataVencimentoMaiorIgual: "",
                      DataVencimentoMenorIgual: "",
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_inicial instanceof Date && data_final instanceof Date) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);

                    const isoDataInicial = data_inicial.toISOString().split("T")[0] + "T00:00:00.0000";
                    const isoDataFinal = data_final.toISOString().split("T")[0] + "T23:59:59.9999";

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      DataVencimentoMaiorIgual: isoDataInicial,
                      DataVencimentoMenorIgual: isoDataFinal,
                    }));
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                isLoading={carregamentoStatus}
                disabled={carregamentoStatus}
                options={statusOptions}
                value={statusSelected}
                onChange={(event: any) => setStatusSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}

              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Tipo de Despesa:
              </label>
              <MultiSelect
                isLoading={carregamentoTipoDespesa}
                disabled={carregamentoTipoDespesa}
                options={tipoDespesaOptions}
                value={tipoDespesaSelected}
                onChange={(event: any) => setTipoDespesaSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}

              />
            </div>
            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
            </div>
          </form>}/>
        </div>

        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoDespesas}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Despesas"
            itens={despesas}
          />
        </div>
      </div>
    </>
  );
};

export default DespesaPage;
