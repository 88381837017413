import clsx from "clsx";
import moment from "moment";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEdit, faEye, faMinus, faPlus, faSignature, faStopCircle } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from "../../../enum";

import DocumentoService from "../../../services/DocumentoService";
import CompromissoService from "../../../services/CompromissoService";

import IUsuario from "../../../interfaces/IUsuario";
import IDocumento from "../../../interfaces/IDocumento";
import ICompromisso from "../../../interfaces/ICompromisso";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaDocumentoRequest from "../../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import { IRecuperaSeparadorTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";


import GridPadrao from "../GridPadrao";
import CompromissoField from "./CompromissoField";
import FluxoModal from "../../Fluxo/Modals/FluxoModal";
import { useAuth } from "../../../contexts/AuthProvider";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import CompromissoCancelarField from "./CompromissoCancelarField";
import CollapseDefault from "../../Collapse/CollapseDefault";

interface ICompromissoListFieldProps {
  modulo: EModulo;
  setRefreshCapa(atualiza: boolean): void;
  configuracaoTela?: IRecuperaSeparadorTelaResponse;
}

const CompromissoListField = ({ modulo, configuracaoTela }: ICompromissoListFieldProps) => {

  const { user } = useAuth();

  const navigate = useNavigate();

  const [documentos, setDocumentos] = useState<IDocumento[]>([]);
  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    modulo: [],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const {

    //modulos
    processo,
    contrato,
    imovel,
    licenca,
    procuracao,
    marca,
    consulta,
    empresa,

    //solucoes
    despesa,
    propostaAcordo,
    desdobramento,
    garantia,
    honorario,
    reembolso,
    bloqueio,
    obrigacao,
    decisao,
    protocolo,
    comunicacao,
    audiencia,


  } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [atualizaTabelaCompromisso, setAtualizarTabelaCompromisso] = useState<boolean>(false);
  const [compromissos, setCompromissos] = useState<ICompromisso[]>([]);
  const [carregandoCompromissos, setCarregandoCompromissos] = useState(false);
  const [exibirListaCompromisso, setExibirListaCompromisso] = useState<boolean>(false);
  const [exibirModalCompromisso, setExibirModalCompromisso] = useState<boolean>(false);
  const [exibirModalCompromissoCancelar, setExibirModalCompromissoCancelar] = useState<boolean>(false);
  const [exibirModalCompromissoConcluir, setExibirModalCompromissoConcluir] = useState<boolean>(false);

  const [editarCompromisso, setEditarCompromisso] = useState<ICompromisso>({
    modulo: modulo,
    tipoCompromissoNome: "",
    compromissoId: 0,
    codigo: "",
    dataConclusao: new Date(),
    dataCompromisso: new Date(),
    observacaoCompromisso: "",
    observacaoConclusao: "",
    tipoCompromissoId: 0,
    usuariosAprovadoresList: [],
    horario: "",
    grupoId: []
  });

  const [filtros, setFiltroCompromisso] = useState<any>({
    tipoPesquisa: 1,
    filtroPesquisa: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "compromissoId",
    status: 1,
  });

  const toggleExibirCompromisso = () => setExibirModalCompromisso(!exibirModalCompromisso);

  const toggleExibirCompromissoCancelar = () => setExibirModalCompromissoCancelar(!exibirModalCompromissoCancelar);

  const toggleExibirCompromissoConcluir = () => setExibirModalCompromissoConcluir(!exibirModalCompromissoConcluir);

  const handlePerRowsChangeCompromisso = async (currentRowsPerPage: number) => setFiltroCompromisso((oldState: any) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChangeCompromisso = (page: number) => setFiltroCompromisso((oldState: any) => { return { ...oldState, offset: (page - 1) * filtros.limit }; });

  const handleSortCompromisso = async (column: TableColumn<ICompromisso>, sortDirection: string) => setFiltroCompromisso((oldState: any) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  const validarAprovadoresCompromisso = (usuarios: IUsuario[]) => {

    if (user?.alterarCompromissosDeTerceiros){
      return true;
    }

    let existe = usuarios?.find(e => e.usuarioId == user?.id);

    if (existe)
      return true;
    else
      return false;
  }

  const abrirXFinder = async (compromissoId: number) => {
    try {
      let documento = documentos.find((e: any) => e.minutaVersaoFinal);
      if (documento) {
        let resultado = await DocumentoService.previewDocument(documento.documentoId);
        navigate(`/XSigner/Envelopes/Novo`, {
          state: {
            envelope: {
              envelopeId: -2,
              clienteId: -2,
              status: -2,
              usuarioIdCadastro: -2,
              documentoAssinadoId: -2,
              codigo: documento.documentoId,
              usuarioIdCancelado: -2,
              signatarios: [],
              procuracaoId: procuracao.procuracaoId,
              contratoId: contrato.contratoId,
              compromissoId: compromissoId,
              modulo: modulo,
              documentos: [{
                envelopeDocumentosId: -2,
                documentoId: documento.documentoId,
                documentoUrl: resultado.data,
              }]
            }
          }
        });
      } else {
        await Swal.fire({
          heightAuto: false,
          icon: "info",
          title: `Documento`,
          text: "Não existe nenhum documento em PDF disponível para assinatura",
          showConfirmButton: true,
          timer: 2000
        });
      }
    } catch (error) {
      console.log(console.error());
    }
  }

  const maisFiltros = (): any => {
    let objeto: any = {};

    //modulos
    if (processo.processoId && EModulo.AreaDireito == modulo) objeto.processoId = processo.processoId;
    if (contrato.contratoId && EModulo.Contrato == modulo) objeto.contratoId = contrato.contratoId;
    if (imovel.imovelId && EModulo.Imovel == modulo) objeto.imovelId = imovel.imovelId;
    if (licenca.licencaId && EModulo.Regulatorio == modulo) objeto.licencaId = licenca.licencaId;
    if (procuracao.procuracaoId && EModulo.Procuracao == modulo) objeto.procuracaoId = procuracao.procuracaoId;
    if (empresa.empresaId && EModulo.Societario == modulo) objeto.empresaId = empresa.empresaId;
    if (marca.marcaId && EModulo.MarcaEPertences == modulo) objeto.marcaId = marca.marcaId;
    if (consulta.consultaId && EModulo.Consultivo == modulo) objeto.consultaId = consulta.consultaId;

    //solucoes
    if (despesa.despesaId && EModulo.Despesa == modulo) objeto.despesaId = despesa.despesaId;
    if (decisao.decisaoId && EModulo.Decisao == modulo) objeto.decisaoId = decisao.decisaoId;
    if (bloqueio.bloqueioId && EModulo.Bloqueio == modulo) objeto.bloqueioId = bloqueio.bloqueioId;
    if (garantia.garantiaId && EModulo.Garantia == modulo) objeto.garantiaId = garantia.garantiaId;
    if (obrigacao.obrigacaoId && EModulo.Obrigacao == modulo) objeto.obrigacaoId = obrigacao.obrigacaoId;
    if (honorario.honorarioId && EModulo.XpayHonorario == modulo) objeto.honorarioId = honorario.honorarioId;
    if (propostaAcordo.propostaAcordoId && EModulo.PropostaAcordo == modulo) objeto.propostaAcordoId = propostaAcordo.propostaAcordoId;
    if (desdobramento.desdobramentoId && EModulo.Desdobramento == modulo) objeto.desdobramentoId = desdobramento.desdobramentoId;
    if (protocolo.xfinderProtocoloId && EModulo.Protocolo == modulo) objeto.xfinderProtocoloId = protocolo.xfinderProtocoloId;
    if (reembolso.reembolsoId && EModulo.XpayReembolso == modulo) objeto.reembolsoId = reembolso.reembolsoId;
    if (comunicacao?.comunicacaoId && EModulo.Comunicacao == modulo) objeto.comunicacaoId = comunicacao?.comunicacaoId;
    if (audiencia?.audienciaId && EModulo.Audiencia == modulo) objeto.audienciaId = audiencia?.audienciaId;

    return objeto;
  }

  const colunas: TableColumn<ICompromisso>[] = [
    {
      cell: (row: ICompromisso) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                className="form-label fw-bolder text-orange"
                style={{
                  paddingTop: "10px",
                }}
              >
                #{row.compromissoId} - {row.tipoCompromissoNome} - {row.status} - {moment(row.dataCompromisso).format("DD/MM/yyyy HH:mm:ss")}
              </p>
              <p>
                <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                  Solicitação:
                </label> {row.observacaoCompromisso}
              </p>

              {row.status != "Pendente" && (
                <p><label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                  Retorno:
                </label> {row.observacaoConclusao}
                  <br></br>
                  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    Confirmado por:
                  </label> {row.usuarioNomeConclusao} <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    em: </label> {moment(row.dataConclusao).format("DD/MM/yyyy HH:mm:ss")}</p>)}

              {row.status == "Pendente" && validarAprovadoresCompromisso(row.usuariosAprovadoresList) && (
                <>
                  <p>
                    {row.indicarSignatario ?
                      <FontAwesomeIcon
                        title="Assinatura"
                        onClick={async () => {
                          {
                            const swalWithBootstrapButtons = Swal.mixin({
                              heightAuto: false,
                              customClass: {
                                confirmButton: "btn btn-orange",
                                cancelButton: "btn btn-danger ms-5",
                              },
                              buttonsStyling: false,
                            });

                            let result = await swalWithBootstrapButtons.fire({
                              title: "Assinaturas",
                              text: `Deseja indicar os signatários?`,
                              showCancelButton: true,
                              cancelButtonText: "Cancelar",
                              confirmButtonText: `Confirmar`,
                            });

                            if (result.isConfirmed) {
                              abrirXFinder(row.compromissoId);
                            }
                          }
                        }}
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2 text-orange"
                        icon={faSignature} />
                      :
                      <FontAwesomeIcon
                        title="Concluir"
                        onClick={async () => {
                          toggleExibirCompromissoConcluir();
                          setEditarCompromisso(row);
                        }}
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2 text-orange"
                        icon={faCheckCircle}
                      />
                    }

                    <FontAwesomeIcon
                      title="Cancelar"
                      onClick={async () => {
                        toggleExibirCompromissoCancelar();
                        setEditarCompromisso(row);
                      }}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="2x"
                      className="mx-2 text-orange"
                      icon={faStopCircle}
                    />

                    <FontAwesomeIcon
                      title="Editar Compromisso"
                      onClick={async () => {
                        toggleExibirCompromisso();
                        setEditarCompromisso({
                          modulo: row.modulo,
                          contratoId: row.contratoId,
                          compromissoId: row.compromissoId,
                          codigo: row.codigo,
                          observacaoCompromisso: row.observacaoCompromisso,
                          tipoCompromissoId: row.tipoCompromissoId,
                          observacaoConclusao: row.observacaoConclusao,
                          tipoCompromissoNome: "",
                          usuariosAprovadoresList: row.usuariosAprovadoresList,
                          dataCompromisso: row.dataCompromisso,
                          dataConclusao: row.dataConclusao,
                          horario: moment(row.dataCompromisso).format("HH:mm"),
                          grupoId: []
                        });
                      }}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="2x"
                      className="mx-2 text-orange"
                      icon={faEdit}
                    />
                  </p>
                </>
              )}

              {row.status == "Concluido" && (
                <>
                  <p>
                    <FontAwesomeIcon
                      title="Visualizar"
                      onClick={async () => {
                        toggleExibirCompromissoConcluir();
                        setEditarCompromisso(row);
                      }}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="2x"
                      className="mx-2 text-orange"
                      icon={faEye}
                    />
                  </p>

                </>
              )}
              {row?.usuariosAprovadoresList?.length > 0 &&
                <p>
                  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    Aprovadores:
                  </label>
                  {row.usuariosAprovadoresList.map((map) => ` ${map?.nome}; `)}
                </p>
              }
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const carregarCompromissos = async (filtro: any) => {
    try {
      setCarregandoCompromissos(true);

      let resultado: IRecuperaLista<ICompromisso>;
      resultado = await CompromissoService.obter(filtro);

      setCompromissos(resultado.data);

      setFiltroCompromisso((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoCompromissos(false);
      setAtualizarTabelaCompromisso(false);
    } catch (error: any) {
      setCarregandoCompromissos(false);
      setAtualizarTabelaCompromisso(false);
    }
  };

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {
      if (!contrato.contratoId) return;

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos({ ...filtro, contratoId: contrato.contratoId, status: 1, modulo: [7] });

      if (resultado.data != null) setDocumentos(resultado.data);
      else setDocumentos([]);

      setFiltrosPesquisaArquivos((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

    } catch (error: any) {
      setDocumentos([]);
    }
  };

  useEffect(() => {

    if (exibirListaCompromisso) {

      setFiltroCompromisso((oldState: any) => { return { ...oldState, ultimosQuantidade: 0 }; });

      carregarCompromissos({ ...filtros, ...maisFiltros() });

      carregarDocumentos(filtrosPesquisaArquivos);
    }

  }, [filtros.offset, filtros.limit, filtros.sort, exibirListaCompromisso]);

  useEffect(() => {
    if (atualizaTabelaCompromisso) {
      setTimeout(() => {
        carregarCompromissos({ ...filtros, ...maisFiltros() });
      }, 2000);
    }
  }, [atualizaTabelaCompromisso]);


  return (
    <>

      <CompromissoField
        setAtualizarTabelaCompromisso={setAtualizarTabelaCompromisso}
        editarCompromisso={editarCompromisso}
        toggleModal={toggleExibirCompromisso}
        exibirModal={exibirModalCompromisso}

        modulo={modulo}

        //modulos
        processoId={processo.processoId}
        areaDireitoId={processo.areaDireitoId}

        contratoId={contrato.contratoId}
        tipoContratoId={contrato.tipoContratoId}

        imovelId={imovel.imovelId}
        tipoImovelId={imovel.tipoImovelId}

        procuracaoId={procuracao.procuracaoId}
        tipoProcuracaoId={procuracao.tipoProcuracaoId}

        consultaId={consulta.consultaId}
        tipoConsultaId={consulta.tipoConsultaId}

        marcaId={marca.marcaId}
        tipoMarcaId={marca.tipoMarcaId}

        licencaId={licenca.licencaId}
        tipoLicencaId={licenca.tipoLicencaId}

        //solucoes
        despesaId={despesa.despesaId}
        tipoDespesaId={despesa.tipoDespesaId}

        bloqueioId={bloqueio.bloqueioId}
        tipoBloqueioId={bloqueio.tipoBloqueioId}

        decisaoId={decisao.decisaoId}
        tipoDecisaoId={decisao.tipoDecisaoId}

        obrigacaoId={obrigacao.obrigacaoId}
        tipoObrigacaoId={obrigacao.tipoObrigacaoId}

        garantiaId={garantia.garantiaId}
        tipoGarantiaId={garantia.tipoGarantiaId}

        comunicacaoId={comunicacao?.comunicacaoId}
        tipoComunicacaoId={comunicacao?.tipoComunicacaoId}

        audienciaId={audiencia?.audienciaId}
        tipoAudienciaId={audiencia?.tipoAudienciaId}

        propostaAcordoId={propostaAcordo.propostaAcordoId}
        tipoPropostaAcordoId={propostaAcordo.tipoPropostaAcordoId}

        desdobramentoId={desdobramento.desdobramentoId}
        tipoDesdobramentoId={desdobramento.tipoDesdobramentoId}

        reembolsoId={reembolso.reembolsoId}
        tipoReembolsoId={reembolso.tipoReembolsoId}

        honorarioId={honorario.honorarioId}
        tipoHonorarioId={honorario.tipoHonorarioId}

        xfinderProtocoloId={protocolo.xfinderProtocoloId}
        tipoProtocoloId={protocolo.tipoProtocoloId}

      />

      <CompromissoCancelarField
        setAtualizarTabelaCompromisso={setAtualizarTabelaCompromisso}
        exibirModal={exibirModalCompromissoCancelar}
        toggleModal={toggleExibirCompromissoCancelar}
        compromissoId={editarCompromisso.compromissoId}
      />

      <FluxoModal
        exibirModal={exibirModalCompromissoConcluir}
        setExibirModal={toggleExibirCompromissoConcluir}
        compromisso={editarCompromisso}
        setRefresh={setAtualizarTabelaCompromisso}
      />

      <div className="row mt-3">
        <CollapseDefault
          modulo={EModulo.Compromisso}
          carregando={carregandoCompromissos}
          exibir={exibirListaCompromisso}
          setExibir={setExibirListaCompromisso}
          help={configuracaoTela?.help ? configuracaoTela.help : ''}
          titulo={configuracaoTela?.label ? configuracaoTela.label : 'Últimos Compromissos'}
          content={<>
            {exibirListaCompromisso && (
              <>
                <div className="col-md-12">
                  <button
                    onClick={() => {
                      setEditarCompromisso({ ...editarCompromisso, compromissoId: 0 });
                      setExibirModalCompromisso(!exibirModalCompromisso);
                    }}
                    className="btn btn-orange"
                  >
                    {exibirModalCompromisso && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                    {!exibirModalCompromisso && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
                  </button>
                </div>

                {modulo == EModulo.AreaDireito &&
                  <div className="col-md-auto mt-3">
                    <select
                      className={"form-select"}
                      value={filtros.modulo}
                      onChange={(e) => setFiltroCompromisso({ ...filtros, modulo: e.target.value })}
                    >
                      <option value="" label="Selecione" />

                      <option value="1" label="Processo">
                        Processo
                      </option>

                      <option value="15" label="Bloqueio">
                        Bloqueio
                      </option>

                      <option value="47" label="Comunicação">
                        Comunicação
                      </option>

                      <option value="12" label="Despesa">
                        Despesa
                      </option>

                      <option value="17" label="Decisao">
                        Decisao
                      </option>

                      <option value="18" label="Desdobramento">
                        Desdobramento
                      </option>

                      <option value="22" label="Garantia">
                        Garantia
                      </option>

                      <option value="23" label="Obrigação">
                        Obrigação
                      </option>

                      <option value="25" label="Proposta Acordo">
                        Proposta Acordo
                      </option>

                      <option value="35" label="Proposta Acordo">
                        XPay Reembolso
                      </option>

                    </select>
                  </div>
                }
                <div className="col-md-auto mt-3">
                  <select
                    className={"form-select"}
                    value={filtros.status}
                    onChange={(e) => setFiltroCompromisso({ ...filtros, status: e.target.value })}
                  >
                    <option value="0" label="Status" />

                    <option value="1" label="Pendente">
                      Pendente
                    </option>

                    <option value="2" label="Concluído">
                      Concluido
                    </option>

                    <option value="3" label="Cancelado">
                      Cancelado
                    </option>

                  </select>
                </div>

                <div className="col-md-auto mt-3">
                  <input
                    value={filtros.filtroPesquisa}
                    onChange={(e) => {
                      setFiltroCompromisso((oldState: any) => {
                        return { ...oldState, filtroPesquisa: e.target.value };
                      });
                    }}
                    placeholder="Compromissos"
                    type="text"
                    className={clsx("form-control")}
                  />
                </div>

                <div className="col-md-auto mt-2">
                  <button onClick={() => carregarCompromissos({ ...filtros, ...maisFiltros() })} className="btn btn-orange">
                    {<> Pesquisar </>}
                  </button>
                </div>



                <div className="col-md-auto mt-2">{<> Buscar por: </>}</div>

                <div className="col-md-auto mt-2">
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltroCompromisso({ ...filtros, tipoPesquisa: 1 })}
                      label="ID"
                      name="group1"
                      type="radio"
                    />
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltroCompromisso({ ...filtros, tipoPesquisa: 2 })}
                      label="Observação"
                      name="group1"
                      type="radio"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-5">
                  <GridPadrao
                    onSort={handleSortCompromisso}
                    progressPending={carregandoCompromissos}
                    limit={filtros.limit}
                    onChangePage={handlePageChangeCompromisso}
                    onChangeRowsPerPage={handlePerRowsChangeCompromisso}
                    paginationServer={true}
                    paginationTotalRows={filtros.totalItems}
                    colunas={colunas}
                    tipo="Compromisso"
                    itens={compromissos}
                  />
                </div>
              </>
            )}
          </>}
        />
      </div>
      <hr />
    </>
  );
};

export default CompromissoListField;
