import { useMutation } from "react-query";
import { uploadDocumentService } from "../services/uploadDocumentService";
import { toast } from "react-toastify";
import { useProvider } from "./useProvider";
import { useValue } from "./useValue";
import { useSlateStatic } from "slate-react";
import { blockHelper } from "../helpers/blockHelper";
import { handleApiError } from "../../../utils/Utils";

export function useUpload() {
  const editor = useSlateStatic();
  const { setVersion, setEditorLoading } = useProvider();
  const { populate, clear } = useValue(editor);

  const { mutateAsync: uploadDocumentFn, isLoading } = useMutation({
    mutationFn: uploadDocumentService,
    onError: (error: Error) => {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage, { position: "bottom-right" });
    },
    onSuccess: async (data) => {
      await clear();
      setVersion(data);

      populate({
        ...data,
        editor,
        pageNumber: 0,
        onFinish: () => {
          blockHelper.replacePageCount(editor);
          setEditorLoading(false);
        },
      });
    },
  });

  async function uploadDocument(file: File) {
    setEditorLoading(true);
    await uploadDocumentFn(file);
  }

  return {
    uploadDocument,
    isLoading,
  };
}
