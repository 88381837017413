import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoAudiencia from "../interfaces/ITipoAudiencia";
import { AdicionaTipoAudienciaRequest } from "../interfaces/Requests/TipoAudiencia/AdicionaTipoAudienciaRequest";
import FiltroPesquisaTipoAudienciaRequest from "../interfaces/Requests/TipoAudiencia/FiltroPesquisaTipoAudienciaRequest";
import { EditarTipoAudienciaRequest } from "../interfaces/Requests/TipoAudiencia/EditarTipoAudienciaRequest";
import { EModulo } from "../enum/EModulos";

class TipoAudienciaService {

    async obterTipoAudiencias(filtros: FiltroPesquisaTipoAudienciaRequest): Promise<IRecuperaLista<ITipoAudiencia>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoAudiencia>>(`TipoAudiencia/RecuperaTipoAudiencia?${query}`);

        return result;
    }
    
    async obterListaLimitadaTipoAudiencia(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoAudiencia/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoAudiencia(adicionaTipoAudiencia: AdicionaTipoAudienciaRequest): Promise<ITipoAudiencia> {
        let { data: result } = await api.post<ITipoAudiencia>(`TipoAudiencia/AdicionaTipoAudiencia`, adicionaTipoAudiencia);

        return result;
    }


    async alterarStatus(tipoaudienciaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoAudiencia>(`TipoAudiencia/AtualizaTipoAudienciaPorId?id=${tipoaudienciaId}`, data);
    }

    async atualizarTipoAudiencia(editarTipoAudiencia: EditarTipoAudienciaRequest): Promise<void> {

        let dataTipoAudiencia = [
            { "op": "replace", "path": "/nome", "value": editarTipoAudiencia.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoAudiencia.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoAudiencia/AtualizaTipoAudienciaPorId?id=${editarTipoAudiencia.tipoAudienciaId}`, dataTipoAudiencia)
        ]);
    }

    async obterTipoAudienciaLimitada(filtros: FiltroPesquisaTipoAudienciaRequest): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        } 

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoAudiencia/RecuperaListaLimitada?${query}`);

        return result;
    }

    async RecuperaListaLimitada(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<ITipoAudiencia>> {
        let { data: result } = await api.get<IRecuperaLista<ITipoAudiencia>>(
            `/TipoAudiencia/RecuperaListaLimitada`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Audiencia,
                },
            }
        );

        return result;
    }

    async recuperaListaLimitadaPesquisa(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<ITipoAudiencia>> {
        let { data: result } = await api.get<IRecuperaLista<ITipoAudiencia>>(
            `/TipoAudiencia/RecuperaListaLimitadaPesquisa`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Audiencia,
                },
            }
        );

        return result;
    }
}


export default new TipoAudienciaService();