const removeUnit = (value: string | number | undefined): number => {
  if (typeof value === 'string') {
    return Number(value.replace(/[^\d.-]/g, ''))
  }

  if (typeof value === 'number') {
    return value
  }

  return 0
}

const pxToPt = (pxValue: number) => {
  return pxValue / 1.333333
}

export const unitHelper = {
  removeUnit,
  pxToPt
}

