import { Modal, ModalProps, Spinner } from "react-bootstrap";
import { Container, Header, Content } from "./styles";
import { MdCheckCircle, MdClose } from "react-icons/md";
import { useTemplate } from "./useTemplate";
import { useTenant } from "../../../../hooks/useTenant";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { useState } from "react";
import { EModulo } from "../../../../../../enum";

export interface TemplateListProps extends ModalProps {
  editor: DocumentEditor;
}

export function TemplateListModal(props: TemplateListProps) {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null
  );

  const { templates, selectTemplate, isLoading, selectLoading } = useTemplate({
    editor: props.editor,
    close: () => props.onHide?.(),
  });

  const { tenant } = useTenant();

  async function select(templateId: number) {
    setSelectedTemplateId(templateId);
    await selectTemplate({
      templateId,
      tenant,
      modulo: EModulo.AreaDireito,
    });
  }

  return (
    <Modal size="sm" centered {...props}>
      <Container>
        <Header>
          <div />
          <strong>Selecione um modelo</strong>
          <button onClick={props.onHide} type="button">
            <MdClose size={16} />
          </button>
        </Header>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center text-orange mt-2">
            <Spinner size="sm" />
          </div>
        ) : (
          <Content>
            {templates.map((template) => (
              <div key={template.modeloDocumentoId} className="card-template">
                <div className="card-info">
                  <p>{template.nome}</p>
                  <span>{template.moduloNome}</span>
                </div>

                <div className="button-container">
                  <button
                    onClick={() => select(template.modeloDocumentoId)}
                    className="btn btn-outline-primary btn-sm"
                    type="button"
                  >
                    {selectLoading &&
                    template.modeloDocumentoId === selectedTemplateId ? (
                      <Spinner size="sm" />
                    ) : (
                      <div className="d-flex justify-content-center align-items-center gap-1 font-size-4">
                        <MdCheckCircle size={16} />
                        Selecionar
                      </div>
                    )}
                  </button>
                </div>
              </div>
            ))}
          </Content>
        )}
      </Container>
    </Modal>
  );
}
