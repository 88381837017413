import React from "react";
import { Droppable } from "react-beautiful-dnd";

import { IAutomacao } from "../../../../interfaces/IAutomacao";

import FormACampo from "../Form/FormACampo";

interface ColumnProps {
  col: {
    id: string;
    list: IAutomacao[];
  };
}

const Column: React.FC<ColumnProps> = ({ col: { id, list } }) => {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <>
          <div
            key={id}
            style={{ border: "1px solid #dee2e6", paddingTop: "40px", paddingBottom: "600px" }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list?.map((item, index) => {
              return (
                <>
                  <FormACampo item={item} key={item?.automacaoId} index={index} col={id} />
                </>
              );
            })}
          </div>
        </>
      )}
    </Droppable>
  );
};

export default Column;
