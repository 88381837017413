import api from "./Api";
import { EModulo, ETipoCampoEnum } from "../enum";

import IEmpresa from "../interfaces/IEmpresa";
import ICentroCusto from "../interfaces/ICentroCusto";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IParteContraria from "../interfaces/IParteContraria";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import IAdicionaProcuracaoRequest from "../interfaces/Requests/Procuracao/IAdicionaProcuracaoRequest";
import FiltroPesquisaProcuracaoRequest from "../interfaces/Requests/Procuracao/FiltroPesquisaProcuracaoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class ProcuracaoService {

  public async adicionaProcuracao(adicionaProcuracao: IAdicionaProcuracaoRequest): Promise<IProcuracao> {
    let { data: result } = await api.post<IProcuracao>(`Procuracao/AdicionaProcuracao`, this.montarObjetoAdicionarProcuracao(adicionaProcuracao));
    return result;
  }

  public async editarProcuracao(editarProcuracao: IAdicionaProcuracaoRequest) {
    let request = this.montarObjetoArrayProcesso(editarProcuracao);

    if (request.length > 0) {
      let result = await api.patch(`Procuracao/AtualizaProcuracaoPorId?id=${editarProcuracao.procuracaoId}`, request);
      return result;
    }
  }

  public async alterarProcessoPorCentroCustoList(centrosCusto: ICentroCusto[], procuracaoId: number) {
    if (centrosCusto.length > 0) {
      await api.put<IAdicionaProcuracaoRequest>(`Procuracao/AtualizaCentroCusto`, {
        procuracaoId: procuracaoId,
        centrosCusto: centrosCusto,
      });
    }
  }

  public async obterProcuracaos(filtros: FiltroPesquisaProcuracaoRequest): Promise<IRecuperaLista<IProcuracao>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.procuracaoId > 0) query += `&procuracaoId=${filtros.procuracaoId}`;

    query = this.montarTextos(filtros, query);

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<IProcuracao>>(`Procuracao/RecuperaListagemProcuracao?${query}`);

    return result;
  }

  async obterCentroCusto(procuracaoId: number): Promise<IRecuperaLista<ICentroCusto>> {
    let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`/Procuracao/RecuperaCentroCustoPorId?procuracaoId=${procuracaoId}`);
    return result;
  }

  public async alterarProcuracaoPorCampo(campo: string, valor: any, procuracaoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaProcuracaoRequest>(`Procuracao/AtualizaProcuracaoPorId?id=${procuracaoId}`, request);
    }
  }

  async adicionarXCampoValor(request: any) {
    let result = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);
    return result;
  }

  public async alterarProcuracaoPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let result = await api.patch<IAdicionaProcuracaoRequest>(`Procuracao/AtualizaXCampoValor?id=${xcampoId}`, request);
      return result;
    }
  }

  public async alterarProcuracaoPorCampoBolleano(campo: string, valor: any, procuracaoId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaProcuracaoRequest>(`Procuracao/AtualizaProcuracaoPorId?id=${procuracaoId}`, request);
  }

  public async alterarProcuracaoPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaProcuracaoRequest>(`Procuracao/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`/Favorito/RecuperaFavorito`);

    return result;
  }

  public async alterarProcuracaoPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], procuracaoId: number) {

    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        const request = {
          procuracaoId: procuracaoId,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<IAdicionaProcuracaoRequest>("Procuracao/AtualizaPartesProcuracaoPorId", request);
      }
    }
  }

  public async alterarProcuracaoPorEmpresaList(empresasList: IEmpresa[], procuracaoId: number) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        const request = {
          procuracaoId: procuracaoId,
          empresasList: empresasRequestList,
        };
        await api.put<IAdicionaProcuracaoRequest>("Procuracao/AtualizaPartesProcuracaoPorId", request);
      }
    }
  }

  public async alterarProcuracaoPorParteProcuracaoList(partesProcuracaosList: IParteContraria[], procuracaoId: number) {
    const partesProcuracaosRequestList: any[] = [];
    partesProcuracaosList.forEach((parteProcuracao) => {
      let requestParteProcuracao: any = {};
      if (parteProcuracao.bairro) requestParteProcuracao.bairro = parteProcuracao.bairro;
      if (parteProcuracao.celular) requestParteProcuracao.celular = parteProcuracao.celular;
      if (parteProcuracao.cep) requestParteProcuracao.cep = parteProcuracao.cep;
      if (parteProcuracao.cidadeId) requestParteProcuracao.cidadeId = parteProcuracao.cidadeId;
      if (parteProcuracao.codigo) requestParteProcuracao.codigo = parteProcuracao.codigo;
      if (parteProcuracao.complemento) requestParteProcuracao.complemento = parteProcuracao.complemento;
      if (parteProcuracao.documento) requestParteProcuracao.documento = parteProcuracao.documento;
      if (parteProcuracao.email) requestParteProcuracao.email = parteProcuracao.email;
      if (parteProcuracao.endereco) requestParteProcuracao.endereco = parteProcuracao.endereco;
      if (parteProcuracao.estadoId) requestParteProcuracao.estadoId = parteProcuracao.estadoId;
      if (parteProcuracao.papelSignatarioId) requestParteProcuracao.papelSignatarioId = parteProcuracao.papelSignatarioId;
      if (parteProcuracao.papelSignatarioNome) requestParteProcuracao.papelSignatarioNome = parteProcuracao.papelSignatarioNome;
      if (parteProcuracao.logradouro) requestParteProcuracao.logradouro = parteProcuracao.logradouro;
      if (parteProcuracao.nome) requestParteProcuracao.nome = parteProcuracao.nome;
      if (parteProcuracao.nomeSocial) requestParteProcuracao.nomeSocial = parteProcuracao.nomeSocial;
      if (parteProcuracao.numero) requestParteProcuracao.numero = parteProcuracao.numero;
      if (parteProcuracao.observacoesGerais) requestParteProcuracao.observacoesGerais = parteProcuracao.observacoesGerais;
      if (parteProcuracao.pessoaId) requestParteProcuracao.pessoaId = parteProcuracao.pessoaId;
      if (parteProcuracao.principal) requestParteProcuracao.principal = parteProcuracao.principal;
      if (parteProcuracao.telefone) requestParteProcuracao.telefone = parteProcuracao.telefone;
      if (parteProcuracao.tipoDocumento) requestParteProcuracao.tipoDocumento = parteProcuracao.tipoDocumento;
      partesProcuracaosRequestList.push(requestParteProcuracao);
    });

    const request = {
      procuracaoId: procuracaoId,
      partesProcuracaosList: partesProcuracaosRequestList,
    }
    await api.put<IAdicionaProcuracaoRequest>("Procuracao/AtualizaPartesProcuracaoPorId", request);

  }

  public async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  public async adicionaFavorido(procuracaoId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.Procuracao,
      procuracaoId: procuracaoId,
    });
    return result;
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  public async obterAdvogadosInterno(procuracaoId: number): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/Procuracao/RecuperaAdvogadoInternoPorId?procuracaoId=${procuracaoId}`);

    return result;
  }

  public async obterProcuracaoPorId(procuracaoId: number): Promise<IRecuperaLista<IProcuracao>> {
    let { data: result } = await api.get<IRecuperaLista<IProcuracao>>(`/Procuracao/RecuperaListagemProcuracao?&procuracaoId=${procuracaoId}`);
    return result;
  }

  public async obterCapaProcuracao(procuracaoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Procuracao/RecuperaCapaProcuracao?procuracaoId=${procuracaoId}`
    );

    return result;
  }

  public concluirCompromisso = async (procuracao: IAdicionaProcuracaoRequest): Promise<any> => {

    let result: any[] = [];

    // Salvar campos normais
    result.push(await this.editarProcuracao(procuracao));

    // Salvar campos Pessoas
    if (procuracao.partesContrariasList?.length > 0) result.push(await this.alterarProcuracaoPorParteProcuracaoList(procuracao.partesContrariasList, procuracao.procuracaoId));
    if (procuracao.advogadoInternoList?.length > 0) result.push(await this.alterarProcuracaoPorAdvogadoInternoList(procuracao.advogadoInternoList, procuracao.procuracaoId));
    // if (procuracao.empresasList?.length > 0) result.push(await this.alterarProcuracaoPorEmpresaList(procuracao.empresasList, procuracao.procuracaoId);

    // Salvar xcampos
    if (procuracao.xCampoValorList?.length > 0) {
      procuracao.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, procuracaoId: procuracao.procuracaoId, texto: xCampo.texto }));
            else result.push(await this.alterarProcuracaoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, procuracaoId: procuracao.procuracaoId, data: xCampo.data }));
            else result.push(await this.alterarProcuracaoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, procuracaoId: procuracao.procuracaoId, valor: xCampo.valor }));
            else result.push(await this.alterarProcuracaoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, procuracaoId: procuracao.procuracaoId, inteiro: xCampo.inteiro }));
            else result.push(await this.alterarProcuracaoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, procuracaoId: procuracao.procuracaoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
            else result.push(await this.alterarProcuracaoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
            break;
        }
      });
    }
    return result;
  };

  public async gerarExcel(filtros: FiltroPesquisaProcuracaoRequest): Promise<Blob> {

    let query = `sort=${filtros.sort}&modeloRelatorioId=${filtros.modeloRelatorioId}`;

    query = this.montarTextos(filtros, query);

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    const { data: result } = await api.get(`Procuracao/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

    return result;
  }

  private montarTextos = (filtros: FiltroPesquisaProcuracaoRequest, query: string) => {

    if (filtros.parteContraria.length > 0) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    if (filtros.pastaId > 0) {
      query += `&pastaId=${filtros.pastaId}`;
    }

    return query;
  };

  // Métodos privados
  private montarListas = (filtros: FiltroPesquisaProcuracaoRequest, query: string) => {
    if (filtros.tipoProcuracaoId.length > 0) filtros.tipoProcuracaoId.forEach((id) => (query += `&tipoProcuracaoId=${id}`));
    if (filtros.departamentoId.length > 0) filtros.departamentoId.forEach((id) => (query += `&departamentoId=${id}`));
    if (filtros.gestorId.length > 0) filtros.gestorId.forEach((id) => (query += `&gestorId=${id}`));
    if (filtros.empresa.length > 0) filtros.empresa.forEach((id) => (query += `&empresa=${id}`));

    return query;
  };

  private montarDatas = (filtros: FiltroPesquisaProcuracaoRequest, query: string) => {
    if (filtros.dataInicioVigenciaMaiorIgual && filtros.dataInicioVigenciaMenorIgual) {
      query += `&dataInicioVigenciaMaiorIgual=${filtros.dataInicioVigenciaMaiorIgual}&dataInicioVigenciaMenorIgual=${filtros.dataInicioVigenciaMenorIgual}`;
    }
    if (filtros.dataFimVigenciaMaiorIgual && filtros.dataFimVigenciaMenorIgual) {
      query += `&dataFimVigenciaMaiorIgual=${filtros.dataFimVigenciaMaiorIgual}&dataFimVigenciaMenorIgual=${filtros.dataFimVigenciaMenorIgual}`;
    }
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.dataAprovacaoMaiorIgual && filtros.dataAprovacaoMenorIgual) {
      query += `&dataAprovacaoMaiorIgual=${filtros.dataAprovacaoMaiorIgual}&dataAprovacaoMenorIgual=${filtros.dataAprovacaoMenorIgual}`;
    }
    if (filtros.dataAssinaturaMaiorIgual && filtros.dataAssinaturaMenorIgual) {
      query += `&dataAssinaturaMaiorIgual=${filtros.dataAssinaturaMaiorIgual}&dataAssinaturaMenorIgual=${filtros.dataAssinaturaMenorIgual}`;
    }

    return query;
  };

  private montarObjetoAdicionarProcuracao = (procuracao: IAdicionaProcuracaoRequest) => {
    let request: any = {};

    // XCampos
    if (procuracao.xCampoValorList.length > 0) request.xCampoValorList = procuracao.xCampoValorList;

    // Campos texto

    if (procuracao.codigo) request.codigo = procuracao.codigo;
    if (procuracao.nomepasta) request.nomePasta = procuracao.nomepasta;
    if (procuracao.observacoesprocuracao) request.observacoesProcuracao = procuracao.observacoesprocuracao;
    if (procuracao.poderesprocuracao) request.poderesProcuracao = procuracao.poderesprocuracao;
    if (procuracao.nomesolicitante) request.nomeSolicitante = procuracao.nomesolicitante;
    if (procuracao.emailsolicitante) request.emailSolicitante = procuracao.emailsolicitante;

    // Campos bool
    if (procuracao.isnovapasta) request.isNovaPasta = procuracao.isnovapasta;
    if (procuracao.restrito) request.restrito = procuracao.restrito;

    // Campos inteiro

    // Campos data
    if (procuracao.dataaprovacao) request.dataAprovacao = procuracao.dataaprovacao;
    if (procuracao.dataassinatura) request.dataAssinatura = procuracao.dataassinatura;
    if (procuracao.datafimvigencia) request.dataFimVigencia = procuracao.datafimvigencia;
    if (procuracao.datainiciovigencia) request.dataInicioVigencia = procuracao.datainiciovigencia;

    // Campos lista
    if (procuracao.pastaid) request.pastaId = procuracao.pastaid;
    if (procuracao.tipoProcuracaoId) request.tipoProcuracaoId = procuracao.tipoProcuracaoId;
    if (procuracao.cidadeId) request.cidadeId = procuracao.cidadeId;
    if (procuracao.departamentoId) request.departamentoId = procuracao.departamentoId;
    if (procuracao.tipoinstrumentoId) request.tipoInstrumentoId = procuracao.tipoinstrumentoId;
    if (procuracao.gestorId) request.gestorId = procuracao.gestorId;

    // Campos Pessoa
    if (procuracao.advogadoInternoList.filter((e) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      procuracao.advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (procuracao.empresasList.filter((e) => e.checked).length > 0) {
      request.empresasList = [];
      procuracao.empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          request.empresasList.push(empresa);
        });
    }

    if (procuracao.partesContrariasList.length > 0) {
      request.partesContrariasList = [];
      procuracao.partesContrariasList.forEach((parteContraria) => {
        let requestParteContraria: any = {};
        if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
        if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
        if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
        if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
        if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
        if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
        if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
        if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
        if (parteContraria.email) requestParteContraria.email = parteContraria.email;
        if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
        if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
        if (parteContraria.formaParticipacaoId) requestParteContraria.formaParticipacaoId = parteContraria.formaParticipacaoId;
        if (parteContraria.formaParticipacaoNome) requestParteContraria.formaParticipacaoNome = parteContraria.formaParticipacaoNome;
        if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
        if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
        if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
        if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
        if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
        if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
        if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
        if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
        if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
        request.partesContrariasList.push(requestParteContraria);
      });
    }
    return request;
  };

  private montarObjetoArrayProcesso = (procuracao: IAdicionaProcuracaoRequest) => {
    let request: any = [];

    // Campos texto
    if (procuracao.codigo) request.push({ op: "replace", path: "/codigo", value: procuracao.codigo });
    if (procuracao.nomepasta) request.push({ op: "replace", path: "/nomepasta", value: procuracao.nomepasta });
    if (procuracao.observacoesprocuracao) request.push({ op: "replace", path: "/observacoesprocuracao", value: procuracao.observacoesprocuracao });
    if (procuracao.poderesprocuracao) request.push({ op: "replace", path: "/poderesprocuracao", value: procuracao.poderesprocuracao });
    if (procuracao.nomesolicitante) request.push({ op: "replace", path: "/nomesolicitante", value: procuracao.nomesolicitante });
    if (procuracao.emailsolicitante) request.push({ op: "replace", path: "/emailsolicitante", value: procuracao.emailsolicitante });

    // Campos data
    if (procuracao.datainiciovigencia) request.push({ op: "replace", path: "/datainiciovigencia", value: procuracao.datainiciovigencia });
    if (procuracao.datafimvigencia) request.push({ op: "replace", path: "/datafimvigencia", value: procuracao.datafimvigencia });
    if (procuracao.dataaprovacao) request.push({ op: "replace", path: "/dataaprovacao", value: procuracao.dataaprovacao });
    if (procuracao.dataassinatura) request.push({ op: "replace", path: "/dataassinatura", value: procuracao.dataassinatura });

    // Campos lista
    if (procuracao.cidadeId) request.push({ op: "replace", path: "/cidadeId", value: procuracao.cidadeId });
    if (procuracao.departamentoId) request.push({ op: "replace", path: "/departamentoId", value: procuracao.departamentoId });
    if (procuracao.tipoinstrumentoId) request.push({ op: "replace", path: "/tipoinstrumentoId", value: procuracao.tipoinstrumentoId });
    if (procuracao.gestorId) request.push({ op: "replace", path: "/gestorId", value: procuracao.gestorId });
    return request;
  };

}

export default new ProcuracaoService();
