import { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { IPessoa } from "../../../interfaces/IPessoa";
import FornecedoresService from "../../../services/FornecedoresService";
import Carregando from "../../Carregando";
import RecuperaPessoa from "../Pessoa/RecuperaPessoa";
import AdicionaPessoa from "../Pessoa/AdicionaPessoa";
import Spinner from "../../Spinner";
import { Modal } from "react-bootstrap";
import { optionsType } from "./FornecedorField";

interface IFornecedorModal {
  exibirModal: boolean;
  handleClose: (fornecedor?: optionsType) => void;
}

const FornecedoresModal = ({ exibirModal, handleClose }: IFornecedorModal) => {
  const [pessoa, setPessoa] = useState<IPessoa>();
  const [tipoDocumento, setTipoDocumento] = useState<string>("");
  const [numeroDocumento, setNumeroDocumento] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handlerPessoa = async (newPessoa: IPessoa) => {
    const result = await FornecedoresService.obterFornecedorViaPessoa(
      newPessoa.pessoaId
    );

    if (result.data.length === 0) {
      setPessoa(newPessoa);
    } else {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível selecionar essa Pessoa",
        text: "Essa pessoa já está cadastrada como fornecedor",
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  const handlerTipo = (newTipo: string) => setTipoDocumento(newTipo);
  const handlerNumeroDocumento = (newNumeroDocumento: string) =>
    setNumeroDocumento(newNumeroDocumento);

  const formik = useFormik({
    initialValues: {
      pessoaId: 0,
      codigo: "",
    },
    validationSchema: Yup.object().shape({
      codigo: Yup.string().min(1, "Código necessário."),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const result = await FornecedoresService.adicionarFornecedor({
          codigo: values.codigo,
          pessoaId: pessoa?.pessoaId ?? 0,
        });

        Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Usuário cadastrado com sucesso`,
          timer: 4000,
        });

        formik.resetForm();
        handleClose({ label: result.nome, value: result.fornecedorId });
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar usuário`,
          text:
            e?.response?.data?.Message && typeof e.response.data.Message
              ? e.response.data.Message
              : "",
          showConfirmButton: true,
        });

        setSubmitting(false);
      }
    },
  });

  if (loading) {
    return <Carregando />;
  }

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Cadastrar um novo fornecedor
          </h5>
        </div>

        <div className="modal-body">
          <div className="row mt-2">
            <div className="col-md-12">
              {!pessoa && (
                <RecuperaPessoa
                  handlerTipo={handlerTipo}
                  handlerNumeroDocumento={handlerNumeroDocumento}
                  handlerPessoa={handlerPessoa}
                />
              )}

              {pessoa && pessoa.pessoaId === 0 && (
                <AdicionaPessoa
                  tipoDocumento={tipoDocumento}
                  numeroDocumento={numeroDocumento}
                  modalCancelar={handleClose}
                  handlerPessoa={handlerPessoa}
                />
              )}

              {pessoa && pessoa.pessoaId > 0 && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                  className="mb-3"
                >
                  <div className="col-md-3 ">
                    <label
                      htmlFor="form-nome"
                      className="form-label fw-bolder text-orange"
                    >
                      Pessoa:
                    </label>
                    <input
                      placeholder="Pessoa"
                      type="text"
                      id="form-codigo"
                      value={pessoa.nome}
                      disabled
                      className="form-control"
                    />

                    <label
                      htmlFor="form-nome"
                      className="form-label fw-bolder text-orange mt-3"
                    >
                      Código
                    </label>
                    <input
                      {...formik.getFieldProps("codigo")}
                      placeholder="Código"
                      type="text"
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.codigo && formik.errors.codigo,
                        },
                        {
                          "is-valid":
                            formik.touched.codigo && !formik.errors.codigo,
                        }
                      )}
                      id="form-codigo"
                    />
                    {formik.touched.codigo && formik.errors.codigo && (
                      <div className="fv-plugins-message-container mt-1">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.codigo}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mt-5">
                    {/* <div className="col-md-3 mt-5"> */}
                    <button
                      disabled={formik.isSubmitting || !formik.isValid}
                      type="submit"
                      className="btn btn-orange"
                    >
                      {!formik.isSubmitting && <> Salvar </>}
                      {formik.isSubmitting && <Spinner />}
                    </button>

                    <button
                      onClick={() => {
                        handleClose();
                      }}
                      disabled={formik.isSubmitting}
                      type="button"
                      className="btn btn-danger float-end"
                    >
                      Cancelar
                    </button>
                    {/* </div> */}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FornecedoresModal;
