import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faHistory, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";

import IEnvelope from "../../interfaces/IEnvelope";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import IAdicionaProcuracaoRequest from "../../interfaces/Requests/Procuracao/IAdicionaProcuracaoRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaSeparadorTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ESeparadorIDEnum, ETelaEnum } from "../../enum";

import LayoutPageButton from "../../layout/LayoutPageButton";

import EnvelopeService from "../../services/EnvelopeService";
import ProcuracaoService from "../../services/ProcuracaoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";

import { OrangeContext } from "../../contexts/OrangeProvider";

import AndamentoListField from "../../components/Comum/Andamento/AndamentoListField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompartilharModal from "../../components/Comum/Share/CompartilharModal";
import Carregando from "../../components/Carregando";
import { PopoverSubMenuProcuracao } from "../../components/Comum/Popover/PopoverSubMenuProcuracao";
import { ToastContainer } from "react-toastify";
import ComunicacaoListField from "../../components/Comum/Comunicacao/ComunicacaoListField";
import CollapseCapaDinanimos from "../../components/Comum/Modulo/CollapseCapaDinanimos";

import { defaultSchema } from "../../utils/SchemasUtil";


const ProcuracaoCapaPage = () => {

  const componentRef = useRef<any>();

  const { procuracao, tipoProcuracao, setProcuracao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [refreshCapa, setRefreshCapa] = useState(true);
  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [envelope, setEnvelope] = useState<IEnvelope>();

  const [exibirModalShare, setExibirModalShare] = useState<boolean>(false);

  const toggleShare = () => setExibirModalShare(!exibirModalShare);

  const compartilhar = () => `Número_${procuracao.numeroProcuracao}_${tipoProcuracao.tipoProcuracaoId > 0 ? "_Tipo_de_Procuracao_" + tipoProcuracao.nome : ""}_Status_${procuracao.statusProcuracaoNome}`;

  const initialValues: IAdicionaProcuracaoRequest = {
    principalId: procuracao.procuracaoId,
    procuracaoId: procuracao.procuracaoId,

    tipoProcuracaoId: procuracao.tipoProcuracaoId,
    categoriaId: procuracao.tipoProcuracaoId,

    modulo: EModulo.Procuracao,
    tela: ETelaEnum.TelaCapaProcuracao,

    pastaid: 0,
    cidadeId: 0,
    departamentoId: 0,
    tipoinstrumentoId: 0,
    gestorId: 0,

    restrito: false,
    isnovapasta: false,

    codigo: "",
    nomepasta: "",
    observacoesprocuracao: "",
    poderesprocuracao: "",
    nomesolicitante: "",
    emailsolicitante: "",

    datainiciovigencia: null,
    datafimvigencia: null,
    dataaprovacao: null,
    dataassinatura: null,

    advogadoInternoList: [],
    empresasList: [],
    centrosCusto: [],
    partesContrariasList: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (procuracao.procuracaoId) {

      carregarProcuracao();

      carregarEnvelope();

      formik.setValues(initialValues);

    }

  }, [procuracao.procuracaoId]);

  useEffect(() => {
    if (refreshCapa) {
      carregarSeparadoresTela();
    }
  }, [refreshCapa]);

  useEffect(() => {
    if (!procuracao.procuracaoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [procuracao]);

  const inserirSessionStorage = () => sessionStorageService.inserir(procuracao, "procuracao_capa");

  const carregarSessionStorage = async () => setProcuracao(JSON.parse((await sessionStorageService.obter("procuracao_capa")) || null));

  const carregarProcuracao = async () => {
    try {

      let resultado: IRecuperaLista<IProcuracao>;

      resultado = await ProcuracaoService.obterProcuracaoPorId(procuracao.procuracaoId);

      setProcuracao(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setRefreshCapa(true);
    }
  }

  const carregarEnvelope = async () => {
    try {
      if (!procuracao.procuracaoId) return;

      let resultado: IRecuperaLista<IEnvelope>;

      resultado = await EnvelopeService.obterEnvelopes({
        procuracaoId: procuracao.procuracaoId,
        limit: 1,
        totalItems: 0,
        offset: 0,
        sort: 'envelopeId'
      });

      if (resultado.data != null) {
        setEnvelope(resultado.data[0]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };


  const carregarSeparadoresTela = async () => {

    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;
      resultado = await ConfiguracaoSeparadorService.obterCapa({
        modulo: EModulo.Procuracao,
        tipoProcuracaoId: formik.values.tipoProcuracaoId,
      });

      setSeparadoresTela(resultado.data);

    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (procuracaoId: number) => {
    try {
      let resultado = await ProcuracaoService.adicionaFavorido(procuracaoId);
      setProcuracao({ ...procuracao, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar procuracao como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ProcuracaoService.removerFavorido(favoritoId);
      setProcuracao({ ...procuracao, favoritoId: 0 });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };

  const renderSeparadores = () => {

    const components = separadoresTela.map((separadorTela, index) => {
      switch (separadorTela.codigo) {

        case ESeparadorIDEnum.ExibirAndamento:
          return <AndamentoListField key={index.toString()} modulo={EModulo.Procuracao} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirCompromisso:
          return <CompromissoListField key={index.toString()} modulo={EModulo.Procuracao} setRefreshCapa={setRefreshCapa} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirComunicacao:
          return <ComunicacaoListField key={index.toString()} modulo={EModulo.Procuracao} configuracaoTela={separadorTela} />;

        default:
          return (
            <CollapseCapaDinanimos
              key={index.toString()}
              formik={formik}
              controller={"procuracao"}
              setRefreshCapa={setRefreshCapa}
              separadorTela={separadorTela}
              setConfiguracoesTela={setConfiguracoesTela}
              configuracoesTela={configuracoesTela}
              separadorTelaLista={separadoresTela}
              setSeparadorTelaLista={setSeparadoresTela}
            />
          );
      }
    });

    return components;
  };

  return (
    <>
      <ToastContainer />

      <CompartilharModal
        texto={compartilhar()}
        exibirModal={exibirModalShare}
        toggleModal={toggleShare}
      />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${procuracao.procuracaoId}  ${tipoProcuracao.tipoProcuracaoId > 0 ? " - " + tipoProcuracao.nome : ""} => ${procuracao.statusProcuracaoNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => setRefreshCapa(!refreshCapa)}>
                {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faHistory} />}
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span className="indicator-progress" style={{ display: "block", fontSize: "30px", paddingLeft: "11px", paddingRight: "11px" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faPrint} title="Imprimir o procuracao" />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            onClick={() => toggleShare()}
            title="Compartilhar"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShareAlt} />}
          </a>

          {procuracao.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(procuracao.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o procuracao"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(procuracao.procuracaoId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o procuracao"
            ></a>
          )}

          <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcuracao(envelope?.envelopeId)}>
            <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>

        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderSeparadores()}
            <FileListCapaField modulo={EModulo.Procuracao} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela}  />
          </>
        )}
      </div>
    </>
  );
}

export default ProcuracaoCapaPage;
