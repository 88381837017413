import { faExchangeAlt, faEdit, faFolderOpen, faEnvelope, faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const PopoverSubMenuContrato = (envelopeId?: number) => {
  const navigate = useNavigate();

  const tooltip = (title: string) => <Tooltip id={`button-tooltip-${title}`}> {title} </Tooltip>

  return (
    <Popover id="popover-basic">
      <Popover.Body style={{ cursor: "pointer" }}>

        <p>
          <OverlayTrigger placement="bottom" overlay={tooltip("Voltar para Capa do Contrato")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Contrato/Capa")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Pasta</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Contrato/ContratoPastaPage")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faFolderOpen}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Minuta</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate('/Contrato/Editor')}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faEdit}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>
        </p>

        <p>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Logs</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Contrato/Logs")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faExchangeAlt}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Envelope</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  if (envelopeId) {
                    navigate(`/XSigner/Envelopes/Editar/${envelopeId}`);
                  } else {
                    Swal.fire({
                      heightAuto: false,
                      icon: "error",
                      title: `Envelope`,
                      text: "Nenhum envelope encontrado",
                      showConfirmButton: true,
                    });
                  }
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faSignature}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>
        </p>
      </Popover.Body>
    </Popover>
  );
};
