import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { faArrowDown, faArrowUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import GridPadrao from "../GridPadrao";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";

import { ECamposIDEnum, EModulo } from "../../../enum";

import DocumentoService from "../../../services/DocumentoService";
import ProtocoloService from "../../../services/ProtocoloService";
import GedUploadService from "../../../services/GedUploadService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import IFile from "../../../interfaces/IFile";
import IOption from "../../../interfaces/IOption";
import IDocumento from "../../../interfaces/IDocumento";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaDocumentoRequest from "../../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import FiltroPesquisaProtocoloRequest from "../../../interfaces/Requests/Protocolo/FiltroPesquisaProtocoloRequest";

import { notifySuccess } from "../Toast/ToastFormik";
import CollapseFiltro from "../../Collapse/CollapseFiltro";
import { FileDropzone } from "../../FileUpload/DragDropZone";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import ITipoDocumento from "../../../interfaces/ITipoDocumento";
import Spinner from "../../Spinner";

interface IProtocoloFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  documentosSelected: IDocumento[];
  setFieldValue: (field: string, value: any, ok: boolean) => any;
}

const ProtocoloField = ({ toggleModal, exibirModal, documentosSelected, setFieldValue }: IProtocoloFieldProps) => {

  const { processo, tipoProtocolo } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoTiposTribunais, setCarregandoTipoTribunais] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);

  const [documentos, setDocumentos] = useState<IDocumento[]>([]);
  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);

  const [descricao, setDescricao] = useState<string>("");
  const [tipoDocumentos, setTipoDocumento] = useState<ITipoDocumento[]>([]);
  const [tipoDocumentoId, setTipoDocumentoId] = useState<number>(0);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    modulo: [EModulo.AreaDireito],
    processoId: processo.processoId,
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [filtrosPesquisaTipos] = useState<FiltroPesquisaProtocoloRequest>({
    processoId: processo.processoId,
    tipoProtocoloId: tipoProtocolo.tipoProtocoloId,
    codigo: '',
    descricao: '',
    processoEmpresaId: 0,
    statusProtocoloId: 0,
    tipoProtocoloTribunal: '',
    clienteId: 0,
    status: [],
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const initialValues: IFile[] = [];

  const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa((oldState) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChange = (page: number) => setFiltrosPesquisa((oldState) => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit }; });

  const handleSort = async (column: TableColumn<IDocumento>, sortDirection: string) => setFiltrosPesquisa((oldState) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  useEffect(() => {
    if (exibirModal) {

      setFieldValue("documentos", documentos.filter(e => e.checked), true);

      carregarDocumentos(filtrosPesquisa);

    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    if (exibirModal) {

      carregarTiposTribunais();

      carregaTipoDocumento();

      carregarDocumentos(filtrosPesquisa);

      limparCampos();

    }
  }, [exibirModal]);


  const adicionar = (documentoId: number) => {
    // Encontrar o documento correspondente pelo documentoId
    const documento = documentos.find(doc => doc.documentoId === documentoId);

    // Verificar se o documento foi encontrado
    if (!documento) {
      console.error('Documento não encontrado');
      return;
    }

    // Criar um novo array de documentos selecionados
    const novosDocumentosSelecionados = [...documentosSelected];

    // Verificar se o documento já está selecionado
    const documentoSelecionadoIndex = novosDocumentosSelecionados.findIndex(doc => doc.documentoId === documentoId);

    if (documentoSelecionadoIndex === -1) {
      // Se o documento não estiver selecionado, adicionar ao array de documentos selecionados
      documento.checked = true;
      novosDocumentosSelecionados.push(documento);
    } else {
      // Se o documento estiver selecionado, remover do array de documentos selecionados
      documento.checked = false;
      documento.tipoProtocoloTribunalIndex = 0;
      novosDocumentosSelecionados.splice(documentoSelecionadoIndex, 1);
    }

    // Atualizar o estado apenas uma vez com os novos documentos selecionados
    setDocumentos([...documentos]);

    setFieldValue("documentos", documentos.filter(e => e.checked), true);

  };

  const preencherMarcacoes = (documentos: IDocumento[]) => {

    documentos.forEach((documento, index) => {

      documento.index = index;
      documento.tipoProtocoloTribunalIndex = 0;

      const documentoChecked = documentosSelected.find(e => e.documentoId == documento.documentoId);

      if (documentoChecked) {
        documento.index = documentoChecked.index;
        documento.checked = true;
        documento.descricao = documentoChecked.descricao;
        documento.tipoDocumentoId = documentoChecked.tipoDocumentoId;
        documento.tipoDocumentoNome = documentoChecked.tipoDocumentoNome;
        documento.tipoProtocoloTribunal = documentoChecked.tipoProtocoloTribunal;
        documento.tipoProtocoloTribunalIndex = documentoChecked.tipoProtocoloTribunalIndex;
      }
    });

    // Ordenar os documentos pelo campo tipoProtocoloTribunalIndex
    documentos.sort((a, b) => a.index - b.index);

    return documentos;
  }

  const atribuirTipoTribunalId = (row: IDocumento, event: any) => {

    const novosDocumentos = documentos.map(documento => {
      if (documento.documentoId === row.documentoId) {
        return {
          ...documento,
          tipoProtocoloTribunal: options.find(e => e.value == event.target.value)?.label,
          tipoProtocoloTribunalIndex: event.target.value
        };
      }
      return documento;
    });

    setDocumentos(novosDocumentos);

    // Notify success with documentoId and label
    notifySuccess(`Documento ID: ${row.documentoId} - ${options.find(e => e.value == event.target.value)?.label || 'No label found'}`);

    setFieldValue("documentos", novosDocumentos.filter(e => e.checked), true);

  };

  const atribuirTipo = (documentoId: number, tipoDocumentoId: number) => {

    const novosDocumentos = documentos.map(file => {
      if (file.documentoId === documentoId) {
        return {
          ...file,
          tipoDocumentoId,
          tipoDocumentoNome: tipoDocumentos?.find(e => e.tipoDocumentoId === tipoDocumentoId)?.nome || ''
        };
      }
      return file;
    });

    setDocumentos(novosDocumentos);

  };

    const atribuirDescricao = (documentoId: number, descricao: string) => {
      const novosDocumentos = documentos.map(file => {
        if (file.documentoId === documentoId) {
          return { ...file, descricao: descricao };
        }
        return file;
      });

      setDocumentos(novosDocumentos);

      setFieldValue("documentos", novosDocumentos.filter(e => e.checked), true);

    };

  const trocarPosicao = (indiceAtual: number, novoIndice: number) => {
    // Verificar se os índices estão dentro dos limites
    if (novoIndice < 0 || novoIndice >= documentos.length || indiceAtual === novoIndice) return;

    // Clone do array de documentos
    const novosDocumentos = [...documentos];

    // Remover o documento atual do array e armazená-lo
    const documentoMovido = novosDocumentos.splice(indiceAtual, 1)[0];

    // Inserir o documento no novo índice
    novosDocumentos.splice(novoIndice, 0, documentoMovido);

    // Atualizar os índices de todos os documentos
    novosDocumentos.forEach((documento, index) => documento.index = index);

    // Atualizar o estado com a nova ordem de documentos
    setDocumentos(novosDocumentos);

    setFieldValue("documentos", novosDocumentos.filter(e => e.checked), true);
  };

  const carregarTiposTribunais = async () => {
    try {

      let options: any[] = [{ label: 'Selecione', value: 0 }];

      setCarregandoTipoTribunais(true);

      let resultado = await ProtocoloService.obterTipoArquivo(filtrosPesquisaTipos);

      resultado.data.map((item, index: number) => options.push({ label: item, value: index + 1 }));

      setOptions(options.sort((a, b) => a.label - b.label));

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoTipoTribunais(false);
    }
  }

  const carregaTipoDocumento = async () => {
    try {

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.AreaDireito, processo.areaDireitoId, processo.processoId, 0, 58);

      setTipoDocumento(resultado.data);

    } catch (error: any) {
      setTipoDocumento([]);
    }
  };

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {

      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setDocumentos(preencherMarcacoes(resultado.data));

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoDocumentos(false);
    }
  };

  const colunas: TableColumn<IDocumento>[] = [
    {
      name: 'Trocar Posição',
      ignoreRowClick: true,
      width:'110px',
      cell: (row: IDocumento, rowIndex: number) => {
        return (
          <div style={{ visibility: row.checked ? 'initial' : 'hidden' }}>
            {rowIndex != 0 && <FontAwesomeIcon style={{ cursor: 'pointer' }} className="mx-1 text-orange" icon={faArrowUp} onClick={() => trocarPosicao(rowIndex, rowIndex - 1)} />}
            <span className="mx-1"></span>
            {rowIndex != documentos.length - 1 && <FontAwesomeIcon style={{ cursor: 'pointer' }} className="mx-1 text-orange" icon={faArrowDown} onClick={() => trocarPosicao(rowIndex, rowIndex + 1)} />}
          </div>
        );
      },
    },
    {
      name: "ID",
      sortField: "documentoId",
      selector: (row: IDocumento) => row.documentoId,
      sortable: true,
      wrap: true,
      width:'70px',
    },
    {
      name: "Protocolar?",
      ignoreRowClick: true,
      width:'100px',
      cell: (row: IDocumento) => {
        return (
          <div>
            <Form.Check
              type="switch"
              checked={row.checked}
              id="custom-switch"
              onChange={() => adicionar(row.documentoId)}
            />
          </div>
        );
      },
    },
    {
      name: 'Tipo de Tribunal',
      ignoreRowClick: true,
      width:'280px',
      cell: (row: IDocumento) => {
        return (
          carregandoTiposTribunais ? (
            <Spinner />
          ) : (
            <select
              name="tipoProtocoloTribunal"
              value={row.tipoProtocoloTribunalIndex}
              disabled={!row.checked}
              onChange={(event) => atribuirTipoTribunalId(row, event)}
              className={"form-control"}
            >
              {options.map((map: any) => {
                return (
                  <option key={map.value} value={map.value}>
                    {map.label}
                  </option>
                );
              })}
            </select>

          )
        );
      },
    },
    {
      name: 'Tipo do arquivo:',
      ignoreRowClick: true,
      width:'280px',
      cell: (row: IDocumento) => {
        return (
          carregandoTiposTribunais ? (
            <Spinner />
          ) : (
            <select
              name="tipoDocumentoId"
              disabled={!row.checked}
              value={row.tipoDocumentoId}
              onChange={(event) => atribuirTipo(row.documentoId, parseInt(event.target.value))}
              className={"form-control"}
            >
              <option value="0">Selecione</option>
              {tipoDocumentos.map((map: any) => {
                return (
                  <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                    {map.nome}
                  </option>
                );
              })}
            </select>)
        );
      },
    },
    {
      name: 'Descrição:',
      ignoreRowClick: true,
      cell: (row: IDocumento) => {
        return (
          <input
            value={row.descricao}
            placeholder="Opcional"
            disabled={!row.checked}
            type="text"
            className={"form-control"}
            onChange={(e: any) => atribuirDescricao(row.documentoId, e.target.value)}
          />
        );
      },
    },


    {
      name: "Data de Cadastro",
      sortField: "dataCadastro",
      selector: (row: IDocumento) => moment(row.dataCadastro).format('DD/MM/YYYY'),
      sortable: true,
      wrap: true,
      width:'130px',
    },
  ];

  const isValidProtocolos = () => {
    let isValid = true;

    documentos.forEach(documento => {
      if (documento.checked && !documento.tipoProtocoloTribunalIndex) {
        isValid = false;
      }
    });

    return isValid;
  }

  const confirmar = async () => {

    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-orange",
        cancelButton: "btn btn-danger ms-5",
      },
      buttonsStyling: false,
    });

    let confirm = await swalWithBootstrapButtons.fire({
      title: "Protocolar ",
      text: `Você realmente deseja salvar?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Confirmar`,
    });

    if (confirm.isConfirmed) {

      // 1. Caso a lista estiver vazia
      if (documentosSelected?.length == 0) {
        toggleModal();
      }

      // 2. Gravar Tipos
      documentos.filter(e => e.checked).forEach(async (documento) => {
        if (documento?.tipoProtocoloTribunal) {
          await DocumentoService.atualizaTipoProtocoloTribunal(documento);
          notifySuccess(`Documento ID: ${documento.documentoId} vinculado`)
        }
      });

      toggleModal();
    }
  }

  const cancelar = () => {

    formik.setValues([]);

    toggleModal();
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      formik.setValues(values);
    },
  });

  const adicionaArquivo = (event: any) => {
    for (let index = 0; index < event.length; index++) {
      const currentFile = event[index];
      formik.values.push({
        index: formik.values.length,
        tipoDocumentoId: 0,
        documentoId: 0,
        serRestriro: false,
        file: currentFile,
        nome: !descricao ? currentFile.name : descricao,
        extensao: currentFile.name.substring(currentFile.name.lastIndexOf(".") + 1),
      });
    }

    formik.values.forEach((file: IFile, index: number) => (file.index = index));

  };

  const iniciarUploadArquivos = async () => {

    formik.values.forEach(async (item: IFile, index: number) => {

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Realizando upload...`,
        allowOutsideClick: false,
        showConfirmButton: false
      });

      Swal.showLoading()

      try {

        const formData = new FormData();
        formData.append("origem", "1");
        formData.append("file", item.file);
        formData.append("tipoDocumentoId", tipoDocumentoId.toString());
        formData.append("descricao", !descricao ? item.nome : `${descricao}`);
        formData.append("restrito", item.serRestriro.toString());
        formData.append("modulo", EModulo.AreaDireito.toString());
        formData.append("processoId", processo.processoId.toString());
        await GedUploadService.adicionaArquivo(formData);

        Swal.hideLoading();

        Swal.close();

      } catch (error: any) {
        console.log(error)
      } finally {

        carregarDocumentos(filtrosPesquisa);

        carregarTiposTribunais();
      }
    });

    limparCampos();

  };

  const limparCampos = () => {

    formik.setValues(initialValues);

    setDescricao('');

    setTipoDocumentoId(0);
  }

  return (
    <Modal size="xl"  centered={false} show={exibirModal} onHide={toggleModal}>
      <ToastContainer />
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Documentos
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">

          <div className="row mb-2">

            <div className="col-md-auto mb-10">
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Pesquise"
                type="text"
                className={clsx("form-control")}
              />
            </div>

            <div className="col-md-auto mb-3">
              <button onClick={() => carregarDocumentos(filtrosPesquisa)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>

          </div>

          <div className="col-md-12 mb-3">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoDocumentos}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Documentos"
              itens={documentos}
            />
          </div>

          <CollapseFiltro
            titulo="Deseja importar novos arquivos?"
            open={false}
            content={
              <>
                <div className="row mb-2">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                      Tipo de Documento:
                    </label>
                    <select
                      name="tipoDocumentoId"
                      value={tipoDocumentoId}
                      onChange={(event) => setTipoDocumentoId(parseInt(event.target.value))}
                      className={"form-control"}
                    >
                      <option value="0">Selecione</option>
                      {tipoDocumentos.map((map: any) => {
                        return (
                          <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                            {map.nome}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Descrição:
                    </label>
                    <input
                      value={descricao}
                      placeholder="Descrição do arquivo..."
                      type="text"
                      className={"form-control"}
                      onChange={(e: any) => setDescricao(e.target.value)}
                    />
                  </div>

                </div>

                <div className="col-md-12 mb-3">
                  <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                    Arquivo:
                  </label>
                  <FileDropzone
                    onDrop={(acceptedFiles) => {
                      adicionaArquivo(acceptedFiles);
                    }}
                  />
                </div>

                <div className="modal-footer" style={{ margin: "0 auto" }}>

                  <button
                    onClick={() => iniciarUploadArquivos()}
                    disabled={!tipoDocumentoId}
                    type="button"
                    className="btn btn-orange ms-5"
                  >
                    Iniciar Importação
                  </button>

                </div>
              </>}
          />

        </div>

        <div className="modal-footer" style={{ margin: "0 auto" }}>

          <button
            onClick={() => confirmar()}
            disabled={!isValidProtocolos()}
            type="button"
            className="btn btn-orange ms-5"
          >
            Adicionar
          </button>

          <button
            onClick={() => cancelar()}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal >
  );
};

export default ProtocoloField;
