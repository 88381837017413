import { MdArrowBack, MdTag } from "react-icons/md";
import { EditorVersionData } from "../../../../../../interfaces/IEditorVersionResponse";
import { Drawer } from "../../../Drawer";
import { Container, VersionCard } from "./styles";
import { formatDate } from "../../../../../../utils/Utils";
import { useState } from "react";
import { useDrawer } from "../../../Drawer/useDrawer";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { useTitle } from "../../hooks/useTitle";
import { usePublishDocument } from "../Menu/hooks/usePublishDocument";
import { toast } from "react-toastify";
import { useTenant } from "../../../../hooks/useTenant";
import { Spinner } from "react-bootstrap";
import { EditorPagination } from "../Pagination";
import { useVersionList } from "../../hooks/useVersionList";

interface VersionsProps {
  editor: DocumentEditor;
  toggleViewMode: () => void;
}

export function Versions({ editor, toggleViewMode }: VersionsProps) {
  const [paginationParams, setPaginationParams] = useState({
    limit: 10,
    offset: 0,
  });
  const { versions, isLoading: versionListLoading } =
    useVersionList(paginationParams);

  const [selectedVersion, setSelectedVersion] = useState<
    EditorVersionData | undefined
  >();
  const [restoringVersion, setRestoringVersion] = useState<null | number>(null);

  const { open, toggle } = useDrawer();
  const { setDocTitle, docTitle } = useTitle();
  const { module, tenant } = useTenant();
  const { publish, isLoading } = usePublishDocument();

  function selectVersion(version: EditorVersionData) {
    setSelectedVersion(version);

    const content = version.jsonContent.sfdt;

    if (content) {
      const data = { sfdt: content };
      editor.open(JSON.stringify(data));
      setDocTitle(version.titulo);
      toggle();
    }
  }

  const isMain = (versionNumber: number) => {
    return versionNumber === versions?.totalRegistros
  };

  async function publishVersion(version: EditorVersionData) {
    setRestoringVersion(version.versao);
    const jsonContent = version.jsonContent.sfdt;
    if (!jsonContent) {
      toast.error("Erro ao publicar versão, conteúdo não encontrado", {
        position: "bottom-right",
      });
      return;
    }

    const content = JSON.stringify(version.jsonContent);

    await publish({
      content,
      modulo: module,
      tenant,
      title: docTitle,
      restoring: true,
    });

    editor.open(content);
    toggleViewMode();
  }

  const currentPage =
    paginationParams.offset === 0
      ? 1
      : paginationParams.offset / paginationParams.limit;

  return (
    <Drawer open={open}>
      <Container>
        <div className="header">
          <button onClick={() => toggle()} className="btn btn-sm">
            <MdArrowBack size={16} />
          </button>
          <h6 className="m-0 p-0">Histórico de versões</h6>
          <div />
        </div>

        <div className="content">
          {versionListLoading && (
            <div className="mt-2 d-flex items-center justify-content-center">
              <Spinner size="sm" className="text-orange" />
            </div>
          )}

          {!versions?.data.length && !versionListLoading && (
            <div className="my-2 d-flex items-center justify-content-center">
              <span>Nenhum resultado encontrado</span>
            </div>
          )}

          {versions?.data &&
            versions.data.map((version) => (
              <VersionCard
                selected={selectedVersion?.versao === version.versao}
                key={version.versao}
              >
                <div className="icon">
                  <MdTag size={20} />
                </div>

                <div className="title">
                  <div>
                    {isMain(version.versao) && (
                      <div className="badge badge-success">Principal</div>
                    )}
                    <h6 className="mt-2 mb-0 p-0 text-md font-weight-bold">
                      {version.titulo || "Sem título"}
                    </h6>
                  </div>
                  <span className="text-muted">
                    Publicada por {version.nomeUsuario} em{" "}
                    {formatDate(version.dataCadastro)}
                  </span>
                  {version.versao !== selectedVersion?.versao && (
                    <button
                      onClick={() => selectVersion(version)}
                      className="btn btn-link btn-sm d-flex justify-content-start align-items-center outline-none"
                    >
                      Visualizar
                    </button>
                  )}

                  {!isMain(version.versao) && (
                    <div className="d-flex justify-content-end align-items-center mt-1">
                      <button
                        disabled={isLoading}
                        onClick={() => publishVersion(version)}
                        className="btn btn-light btn-sm d-flex gap-2 align-items-center outline-none"
                      >
                        {restoringVersion === version.versao && isLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          "Restaurar"
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </VersionCard>
            ))}

          {versions?.data && !versionListLoading && (
            <EditorPagination
              currentPage={currentPage}
              onPageChange={(page) =>
                setPaginationParams((state) => ({
                  ...state,
                  offset: page * paginationParams.limit,
                }))
              }
              totalPages={versions?.totalPaginas ?? 0}
            />
          )}
        </div>
      </Container>
    </Drawer>
  );
}
