import { Element } from "slate";
import { CustomEditor, CustomElement, FooterElement } from "../../../../../utils/types/slate";
import { CommonService } from "./commons";
import { pxToPt } from "../../../components/Toolbar/controls/common";

export const FooterService = {
  defaultStyle: {
    padding: "35.4pt"
  },
  makeFooter(): FooterElement {
    return {
      id: 'footer_component_0',
      type: "footer",
      children: [CommonService.makeParagraph()],
    };
  },
  getFooterChildrens(footer: FooterElement): CustomElement[] | null {
    const childs: CustomElement[] = [];
    
    if (Element.isElement(footer)) {
      footer.children.forEach(child => Element.isElement(child) && childs.push(child))
    }

    return childs
  },
  getExistentFooter(editor: CustomEditor): FooterElement | null {
    const firstElement = editor.children[0];

    if (Element.isElement(firstElement) && firstElement.type === "page") {
      const footer = firstElement.children.find(
        (child) => Element.isElement(child) && child.type === "footer"
      );
      return footer ? (footer as FooterElement) : null;
    }

    return null;
  },
  addFooter(editor: CustomEditor) {
    const existenteFooter = this.getExistentFooter(editor);

    if (existenteFooter) {
      const { findElementPath, focus } = CommonService;
      const path = findElementPath(editor, existenteFooter);
      focus(editor, path);
      return;
    }

    editor.children.forEach((child) => {
      if (Element.isElement(child) && child.type === "page") {
        const path = CommonService.findElementPath(editor, child)?.concat(0);
        const footer = this.makeFooter();
        CommonService.addElement(editor, footer, path);
      }
    });

    const selectionPath = editor.selection?.anchor.path[0] ?? 0;
    CommonService.focus(editor, [selectionPath]);
  },
  removeFooter(editor: CustomEditor, element: FooterElement) {
    CommonService.removeElement(editor, element);
  },
  addSealInFooter(editor: CustomEditor, sealUrl: string) {
    const footers: FooterElement[] = []

    for (const page of editor.children) {
      if (Element.isElement(page) && page.type === 'page') {
        for (const pageChildren of page.children) {
          if (Element.isElement(pageChildren) && pageChildren.type === 'footer') {
            footers.push(pageChildren)
          }
        }
      }
    }

    footers.forEach((footer) => {
      if (Element.isElement(footer)) {
        const path = CommonService.findElementPath(editor, footer);
        const img = CommonService.makeImage(sealUrl, { height: '50pt', textAlign: 'right' })
        CommonService.addElement(editor, img, path.concat(0))
      }
    });
  },
  getHeight() {
    const element = document.getElementById('footer-component')
    if (!element) { return 0 }
    const { height } = element.getBoundingClientRect()
    return pxToPt(height)  
  },
  formatFooterChildren(footer: FooterElement): FooterElement {
    const paragraph = CommonService.makeParagraph()

    return {
      ...footer,
      id: `footer_component`,
      children: footer.children.filter((child) => Element.isElement(child)).concat(paragraph),
    };
  },
};
