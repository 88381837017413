import React, { CSSProperties, ReactChild, ReactNode } from "react"
import { DashboardContexto } from "../DashboardContext";
import { IWidgetsTamanhos } from "./interfaces/IWidget";


interface  WidgetState {
    telaCheia: boolean;
    tamanhos: IWidgetsTamanhos;
    redimessionando: boolean;
    
}

export interface WidgetWrapperProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    titulo:string;
    editando?: boolean | undefined;
    tamanhos: IWidgetsTamanhos;
}


export default class WidgetWrapper extends React.Component<WidgetWrapperProps, WidgetState>{

    static contextType = DashboardContexto;
    widgetRef = React.createRef<HTMLDivElement>();
    puxadorRef = React.createRef<HTMLDivElement>();
    dashboard: React.ContextType<typeof DashboardContexto>;

    constructor(props: WidgetWrapperProps, context: React.ContextType<typeof DashboardContexto>) {
        super(props);
        this.state = {
            telaCheia: false,
            tamanhos: props.tamanhos,
            redimessionando: false,
        }
        this.dashboard = context;

        this.toggleTelaCheia.bind(this);
    }


    componentDidUpdate(prevProps: WidgetWrapperProps, prevState: WidgetState) {
        if (prevProps.tamanhos !== this.props.tamanhos) {
            this.setState({
                tamanhos: this.props.tamanhos
            });
        }
    }


    style(): CSSProperties{
        if(!this.state.telaCheia || !this.widgetRef.current){
            return {};
        }

        const rect = this.widgetRef.current.getBoundingClientRect();
        const root = document.querySelector('.main-content-orange') as HTMLDivElement;
        const rootRect = root.getBoundingClientRect();
        const scale = Math.max(1, Math.min(rootRect.width / rect.width * 0.9, rootRect.height / rect.height * 0.9));
        const posX = (rect.left - rootRect.left);
        const posY = (rect.top - rootRect.top);
        const rootCenterX = rootRect.width / 2;
        const rootCenterY = rootRect.height / 2;
        const translateX = rootCenterX / scale - posX / (scale) - (rect.width / 2);
        const translateY = rootCenterY / scale - posY / (scale) - (rect.height / 2);

        return {
            transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
            zIndex: "100000",
            height: '100%',
            maxHeight: 'none'
            
        };
    }

    toggleTelaCheia(){
        const graficoProps = this.props?.children as React.ReactElement
        
        this.setState({
            telaCheia: (!this.context.editando) && !this.state.telaCheia
        });
       
        if(graficoProps.props.options.mostrarMaisNoZoom) {
            if(this.widgetRef.current?.parentElement?.parentElement?.style){
                this.widgetRef.current.parentElement.parentElement.style.width = !this.state.telaCheia ? '80%' : '';
                this.widgetRef.current.parentElement.parentElement.style.maxHeight = !this.state.telaCheia ? 'none' : '';
                const conteudo = this.widgetRef.current.lastChild as HTMLDivElement
                conteudo.style.maxHeight = !this.state.telaCheia ? 'none' : '';
          
            }
       }


        

       
    }

    private tamanhoAtual(){
        let xl = window.matchMedia('(min-width: 1200px)')
        let lg = window.matchMedia('(min-width: 992px)')
        let md = window.matchMedia('(min-width: 768px)')
        let sm = window.matchMedia('(min-width: 576px)')


        if(xl.matches){
            return 'xl';
        }

        if(lg.matches){
            return 'lg';
        }

        if(md.matches){
            return 'md';
        }

        if(sm.matches){
            return 'sm';
        }

        return 'xs';
    }

    private onMouseMove = (e: MouseEvent) => {                    
        if(!this.dashboard || !this.widgetRef.current || !this.puxadorRef.current) return;
        e.preventDefault();

        const widget = this.widgetRef.current.parentElement as HTMLDivElement;
        const puxador = this.puxadorRef.current;
        const redimensionar = puxador.parentElement as HTMLDivElement;

        const widgetRect = widget.getBoundingClientRect();
        const tamanho = this.tamanhoAtual();
        const tamanhos = {
            ...this.state.tamanhos
        };

        tamanhos[tamanho] = Math.max(
            2, Math.abs(
                Math.min(
                    Math.round(
                        (e.clientX - widgetRect.left) / this.dashboard.instancia.width * 12
                    ), 12
                )
            )
        ).toString();
        
        const x = e.clientX - widgetRect.left;
        redimensionar.style.width = x  + 'px';
        puxador.style.display = 'block';
        document.body.style.userSelect = 'none';
        if(tamanhos[tamanho] !== this.state.tamanhos[tamanho]){
            this.dashboard.instancia.onWidgetChanged(this.props.titulo, tamanhos)
            this.setState({
                tamanhos: tamanhos
            })
        }
    }

    private onMouseUp = (e: MouseEvent) => {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        if(!this.widgetRef.current || !this.puxadorRef.current) return;
        const puxador = this.puxadorRef.current;
        const redimensionar = puxador.parentElement as HTMLDivElement;
        redimensionar.style.width = '100%';
        puxador.style.removeProperty('display');
        document.body.style.removeProperty('userSelect');
    }

    redimensionar(){
        if(this.state.telaCheia) return <></>;
        return <>
            <div className="widget-redimensionar">
                <div
                    className="puxador"
                    ref={this.puxadorRef}
                    onMouseDown={(e) => {
                        document.addEventListener('mousemove', this.onMouseMove);
                        document.addEventListener('mouseup', this.onMouseUp);
                    }}
                />
            </div>
        </>;
    }

    
    render(){
        return(
            <div
                style={this.state.telaCheia ? {zIndex: 10000} : {}}
            >
                <div className={`widget-backdrop${this.state.telaCheia?' visivel':''}`} onClick={()=>this.toggleTelaCheia()}/>
                <div
                    className="widget-conteiner"
                    ref={this.widgetRef}
                    onDoubleClick={()=>this.toggleTelaCheia()}
                    style={this.style()}
                >
                    <DashboardContexto.Consumer>
                        {(value) =>{
                            this.context = value;
                            return value?.editando && this.redimensionar()
                        }}
                    </DashboardContexto.Consumer>
                    <div className="widget-titulo mb-2">
                        {this.props.titulo}
                    </div>
                    <div className="widget-conteudo">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

}