import { faEraser, faPen, faPlus, faSearch, faFileExcel, } from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableColumn } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import EnvelopeDocumentoModal from './EnvelopeDocumentoModal';
import EnvelopeService from '../../../services/EnvelopeService';
import FiltroPesquisaEnvelopeRequest from '../../../interfaces/Requests/FiltroPesquisaEnvelopeRequest';
import GridPadrao from '../../../components/Comum/GridPadrao';
import IEnvelope from '../../../interfaces/IEnvelope';
import IRecuperaLista from '../../../interfaces/IRecuperaLista';
import LayoutPageTitle from '../../../layout/LayoutPageTitle';
import Swal from "sweetalert2";
import NovoEnvelopeModal from './NovoEnvelopeModal';


const EnvelopesPage = () => {
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoEnvelopes, setCarregandoEnvelopes] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);
    const [envelopes, setEnvelopes] = useState<IEnvelope[]>([]);
    const [relatorioId, setRelatorioId] = useState<number | null>(null);

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEnvelopeRequest>({
        codigo: '',
        envelopeId: [],
        clienteId: 0,
        nomeDocumento: '',
        usuarioIdCadastro: 0,
        dataAssinaturaMaiorIgual: '',
        dataAssinaturaMenorIgual: '',
        dataCanceladoMaiorIgual: '',
        dataCanceladoMenorIgual: '',
        dataCadastroMaiorIgual: '',
        dataCadastroMenorIgual: '',
        status: 0,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'envelopeId'
    });

    const navigate = useNavigate();

    function toggleModal(carregarDados?: boolean): void {
        if (carregarDados) {
            carregarEnvelopes(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);
    }

    async function carregarEnvelopes(filtro: FiltroPesquisaEnvelopeRequest): Promise<void> {
        try {
            setCarregandoEnvelopes(true);
            let resultado: IRecuperaLista<IEnvelope>;

            resultado = await EnvelopeService.obterEnvelopes(filtro);

            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
            setRelatorioId(resultado.relatorioId || null);
            setEnvelopes(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros.',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setEnvelopes([]);
            setRelatorioId(null);
        }
        finally {
            setCarregandoEnvelopes(false);
            setLoadingInicial(false);
        }
    }


    function nomearStatusEnvelope(status: number): string {
        switch (status) {
            case -1:
                return 'Cancelado';
            case 1:
                return 'Em Confecção';
            case 2:
                return 'Aguardando Signatários';
            case 3:
                return 'Assinado';
            default:
                return 'Não definido';
        }
    }

    const colunas: TableColumn<IEnvelope>[] = [
        {
            name: 'Id',
            sortField: 'envelopeId',
            selector: (row: IEnvelope) => row.envelopeId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Nome Documento',
            sortField: 'nomeDocumento',
            selector: (row: IEnvelope) => row.nomeDocumento,
            sortable: true,
            wrap: true
        },
        {
            name: 'Data Cadastro',
            sortField: 'dataCadastro',
            selector: (row: IEnvelope) => {
                const data = row.dataCadastro || ''
                if (data.includes('T')) {
                    const [ano, mes, dia] = data.split('T')[0].split('-')
                    return `${dia}/${mes}/${ano}`
                }
                return '-'
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Data Assinatura',
            sortField: 'dataAssinatura',
            selector: (row: IEnvelope) => {
                const data = row.dataAssinatura || ''
                if (data.includes('T')) {
                    const [ano, mes, dia] = data.split('T')[0].split('-')
                    return `${dia}/${mes}/${ano}`
                }
                return '-'
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Data Cancelamento',
            sortField: 'dataCancelado',
            selector: (row: IEnvelope) => {
                const data = row.dataCancelado || ''
                if (data.includes('T')) {
                    const [ano, mes, dia] = data.split('T')[0].split('-')
                    return `${dia}/${mes}/${ano}`
                }
                return '-'
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            sortField: 'status',
            selector: (row: IEnvelope) => nomearStatusEnvelope(row.status),
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (envelope: IEnvelope) => {
                return (
                    <div>
                        {envelope.status === 1 ? <>
                            <FontAwesomeIcon title="Editar Envelope" onClick={() => {
                                navigate(`/XSigner/Envelopes/Editar/${envelope.envelopeId}`);
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />
                        </>
                            :
                            <FontAwesomeIcon title="Visualizar Envelope" onClick={() => {
                                navigate(`/XSigner/Envelopes/Editar/${envelope.envelopeId}`);
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faSearch} />
                        }
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ]

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<IEnvelope>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };


    async function baixarRelatorio(): Promise<void> {
        if (!envelopes) return;

        if (relatorioId === 0 || relatorioId === null) {
            await Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `A pesquisa não retornou nenhum resultado.`,
                showConfirmButton: true,
                timer: 4000
            });
            return;
        }


        Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Baixando arquivos...',
            showConfirmButton: false,
        });
        Swal.showLoading();
        try {
            const arquivo = await EnvelopeService.gerarExcel(relatorioId || 0);
            if (arquivo) {
                const url = window.URL.createObjectURL(arquivo);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${new Date().toLocaleString('BR')} - Envelopes.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            Swal.hideLoading();
            Swal.update({
                title: 'Baixado com sucesso',
                text: '',
                icon: 'success',
                showConfirmButton: true
            });

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível baixar arquivos',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false
            });
        }
    }


    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                codigo: '',
                envelopeId: [],
                nomeDocumento: '',
                usuarioIdCadastro: 0,
                dataAssinaturaMaiorIgual: '',
                dataAssinaturaMenorIgual: '',
                dataCanceladoMaiorIgual: '',
                dataCanceladoMenorIgual: '',
                dataCadastroMaiorIgual: '',
                dataCadastroMenorIgual: '',
                status: 0,
            }
        });
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarEnvelopes(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])


    return (<>
        <LayoutPageTitle title="Envelopes">
            <button onClick={() => {
                setExibirModal(true)
            }} className="btn btn-md btn-orange">
                Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        {
            exibirModal &&
            <NovoEnvelopeModal
                exibirModal={exibirModal}
                titulo={`Novo Envelope`}
                onCreate={(envelope) => {
                    navigate(`/XSigner/Envelopes/Novo`, { state: { envelope: envelope } });
                }}
                toggleModal={toggleModal} />
        }


        <div className="row mt-2">
            <div className="col-md-12">
                <div className="col-md-12" >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        carregarEnvelopes(filtrosPesquisa);
                    }} className="row g-3 mb-3 form-buscar-envelope">
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
                            <DateRangePicker
                                className="form-control p-0"
                                calendarIcon={null}
                                showLeadingZeros={true}
                                maxDate={new Date()}
                                value={
                                    (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                                        [
                                            filtrosPesquisa.dataCadastroMaiorIgual,
                                            filtrosPesquisa.dataCadastroMenorIgual,
                                        ] : ''
                                }
                                onChange={(datas: Date[]) => {
                                    if (!datas || datas.length === 0) {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCadastroMaiorIgual: '',
                                            dataCadastroMenorIgual: '',
                                        }));
                                        return;
                                    }

                                    let data_inicial = datas[0];
                                    let data_final = datas[1];

                                    const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                                    if (isValidDate(data_inicial)) {
                                        if (isValidDate(data_final)) {
                                            data_final.setHours(0, 0, 0, 0);
                                            data_final = new Date(data_final.getTime() - 1);
                                        }

                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                            dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                        }));
                                    } else {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCadastroMaiorIgual: '',
                                            dataCadastroMenorIgual: '',
                                        }));
                                    }
                                }}

                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">Data da Assinatura:</label>
                            <DateRangePicker
                                className="form-control p-0"
                                calendarIcon={null}
                                showLeadingZeros={true}
                                maxDate={new Date()}
                                value={
                                    (filtrosPesquisa.dataAssinaturaMaiorIgual && filtrosPesquisa.dataAssinaturaMenorIgual) ?
                                        [
                                            filtrosPesquisa.dataAssinaturaMaiorIgual,
                                            filtrosPesquisa.dataAssinaturaMenorIgual,
                                        ] : ''
                                }
                                onChange={(datas: Date[]) => {
                                    if (!datas || datas.length === 0) {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataAssinaturaMaiorIgual: '',
                                            dataAssinaturaMenorIgual: '',
                                        }));
                                        return;
                                    }

                                    let data_inicial = datas[0];
                                    let data_final = datas[1];

                                    const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                                    if (isValidDate(data_inicial)) {
                                        if (isValidDate(data_final)) {
                                            data_final.setHours(0, 0, 0, 0);
                                            data_final = new Date(data_final.getTime() - 1);
                                        }

                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataAssinaturaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                            dataAssinaturaMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                        }));
                                    } else {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataAssinaturaMaiorIgual: '',
                                            dataAssinaturaMenorIgual: '',
                                        }));
                                    }
                                }}

                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">Data do Cancelamento:</label>
                            <DateRangePicker
                                className="form-control p-0"
                                calendarIcon={null}
                                showLeadingZeros={true}
                                maxDate={new Date()}
                                value={
                                    (filtrosPesquisa.dataCanceladoMaiorIgual && filtrosPesquisa.dataCanceladoMenorIgual) ?
                                        [
                                            filtrosPesquisa.dataCanceladoMaiorIgual,
                                            filtrosPesquisa.dataCanceladoMenorIgual,
                                        ] : ''
                                }
                                onChange={(datas: Date[]) => {
                                    if (!datas || datas.length === 0) {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCanceladoMaiorIgual: '',
                                            dataCanceladoMenorIgual: '',
                                        }));
                                        return;
                                    }

                                    let data_inicial = datas[0];
                                    let data_final = datas[1];

                                    const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                                    if (isValidDate(data_inicial)) {
                                        if (isValidDate(data_final)) {
                                            data_final.setHours(0, 0, 0, 0);
                                            data_final = new Date(data_final.getTime() - 1);
                                        }

                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCanceladoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                            dataCanceladoMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                        }));
                                    } else {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCanceladoMaiorIgual: '',
                                            dataCanceladoMenorIgual: '',
                                        }));
                                    }
                                }}

                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código:</label>
                            <input value={filtrosPesquisa.codigo} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, codigo: e.target.value } });
                            }} placeholder="Código" className={'form-control'} id="form-codigo" />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-status" className="form-label fw-bolder text-orange">Status:</label>
                            <select value={filtrosPesquisa.status} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                            }} placeholder="Status" className={'form-select'} id="form-status" >
                                <option value={0}>Todos</option>
                                <option value={1}>Em Confecção</option>
                                <option value={2}>Aguardando Signatários</option>
                                <option value={3}>Assinado</option>
                                <option value={-1}>Cancelado</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-nome-documento" className="form-label fw-bolder text-orange">Nome do Documento:</label>
                            <input value={filtrosPesquisa.nomeDocumento} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, nomeDocumento: e.target.value } });
                            }} placeholder="Nome do Documento" className={'form-control'} id="form-nome-documento" />
                        </div>
                        <div className="col-12 "
                            style={{ textAlign: 'right' }}>
                            <button
                                type="submit"
                                className="btn btn-orange search-buttom-margin "
                                title="Buscar"
                            >
                                <FontAwesomeIcon color='white' className='' icon={faSearch} />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    limparFiltros();
                                }}
                                className="btn btn-orange search-buttom-margin ms-2"
                                title="Limpar Filtro"
                            >
                                <FontAwesomeIcon color='white' className='' icon={faEraser} />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    baixarRelatorio();
                                }}
                                className="btn btn-orange search-buttom-margin ms-2"
                                title="Baixar Relatório"
                                disabled={relatorioId === null || carregandoEnvelopes}
                            >
                                <FontAwesomeIcon color='white' className='' icon={faFileExcel} />
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 mb-10">
                    <GridPadrao
                        onSort={handleSort}
                        progressPending={carregandoEnvelopes}
                        limit={filtrosPesquisa.limit}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationServer={true}
                        paginationTotalRows={filtrosPesquisa.totalItems}
                        colunas={colunas} tipo='Envelopes' itens={envelopes} />
                </div>
            </div>
        </div >
    </>)
}

export default EnvelopesPage;