import { useQuery } from "react-query";
import ConsultaService from "../../../services/ConsultaService";
import { KanbanQuery } from "../queries";

export function useInternalLawyer(consultId?: number) {
  const { data } = useQuery({
    queryKey: [KanbanQuery.internalLawyer, consultId],
    queryFn: () => ConsultaService.obterAdvogadosInterno(consultId || 0),
    enabled: consultId !== undefined
  })

  return {
    lawyers: data?.data ?? []
  }
}