import clsx from "clsx";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { AdicionaXPayFechamentoRequest } from "../../interfaces/Requests/XPayFechamento/AdicionaXPayFechamentoRequest";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import IEscritorio from "../../interfaces/IEscritorio";
import FiltroPesquisaEscritorioRequest from "../../interfaces/Requests/Escritorio/FiltroPesquisaEscritorioRequest";
import XPayFechamentoService from "../../services/XPayFechamentoService";
import EscritorioService from "../../services/EscritorioService";

interface IXPayFechamentoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  setAtualizarTabela(atualizaAgora: boolean): void;

}

const XPayFechamentoField = ({ toggleModal, exibirModal, setAtualizarTabela }: IXPayFechamentoFieldProps) => {

  const [escritorio, setEscritorio] = useState<IEscritorio[]>([]);
  const [carregandoEscritorio, setCarregandoEscritorio] = useState(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEscritorioRequest>({
    nome: "",
    codigo: "",
    clienteId: 0,
    isTelaPesquisaProcesso: true,
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: "escritorioId",
  });

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>A data de corte é utilizada como filtro para determinar os novos processos e os encerrados. A data de corte é comparada com a data de encerramentos para os casos já encerrados ou com a data de cadastro para os demais casos.</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const initialValues: AdicionaXPayFechamentoRequest = {
    escritorioId: 0,
    modulo: 0,
    tipoFechamento: 0,
    dataCorte: new Date()
  };

  const schema = () => {
    let schema: any = {};
    schema.dataCorte = Yup.date()
      .transform((value) => {
        return value ? moment(value).toDate() : value;
      })
      .required("Campo data de corte é obrigatório");
    schema.escritorioId = Yup.number().min(1, "Selecione um escritório").required("Escritório é obrigatório");
    schema.modulo = Yup.number().min(1, "Selecione um módulo").required("Módulo é obrigatório");
    schema.tipoFechamento = Yup.number().min(1, "Selecione um tipo de fechamento").required("Tipo de Fechamento é obrigatório");
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Registrar XPayFechamento",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: "XPayFechamento criado com sucesso, aguarde alguns minutos até que ele esteja concluído!",
            showConfirmButton: true,
            timer: 4000
          });

          Swal.showLoading();

          XPayFechamentoService.adicionaXPayFechamento(values);
          toggleModal();

          setSubmitting(false);

          Swal.hideLoading();

          setAtualizarTabela(true);

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  async function cancelar() {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  async function carregarEscritorio(filtro: FiltroPesquisaEscritorioRequest) {
    try {
      setCarregandoEscritorio(true);
      let resultado: IRecuperaLista<IEscritorio>;

      resultado = await EscritorioService.obterEscritorios(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setEscritorio(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setEscritorio([]);
    } finally {
      setCarregandoEscritorio(false);
    }
  }

  useLayoutEffect(() => {
    carregarEscritorio(filtrosPesquisa);
  }, []);

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Registrar XPayFechamento
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">

              <div className="col-md-6 mb-3">
                <label htmlFor="form-tipoFechamento" className="form-label fw-bolder text-orange">
                  Tipo:
                </label>
                <select
                  value={formik.values.tipoFechamento.toString()}
                  onChange={(e) => {
                    formik.setFieldTouched("tipoFechamento", true);
                    formik.setFieldValue("tipoFechamento", e.target.value.toString(), true);
                    formik.validateField("tipoFechamento");
                  }}
                  className={"form-select"}
                  id="form-select"
                >
                  <option value="0" label="Selecione o Tipo">
                    {" "}
                    Selecione o Tipo{" "}
                  </option>
                  <option value="1" label="Honorário">
                    {" "}
                    Honorario{" "}
                  </option>
                  <option value="2" label="Reembolso">
                    {" "}
                    Reembolso{" "}
                  </option>
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                  Módulo:
                </label>
                <select
                  value={formik.values.modulo.toString()}
                  onChange={(e) => {
                    formik.setFieldTouched("modulo", true);
                    formik.setFieldValue("modulo", e.target.value.toString(), true);
                    formik.validateField("modulo");
                  }}
                  className={"form-select"}
                  id="form-select"
                >
                  <option value="0" label="Selecione o Modulo">
                    {" "}
                    Selecione o Módulo{" "}
                  </option>
                  <option value="1" label="Contencioso">
                    {" "}
                    Contencioso{" "}
                  </option>
                  <option value="7" label="Contrato">
                    {" "}
                    Contrato{" "}
                  </option>
                  <option value="9" label="Consultivo">
                    {" "}
                    Consultivo{" "}
                  </option>
                </select>
              </div>

              {carregandoEscritorio ? (
                <>Carregando escritórios...</>
              ) : (
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-escritorio" className="form-label required fw-bolder text-orange">
                    Escritório:
                  </label>
                  <select
                    {...formik.getFieldProps("escritorioId")}
                    value={formik.values.escritorioId}
                    onChange={(e) => {
                      formik.setFieldTouched("escritorioId", true);
                      formik.setFieldValue("escritorioId", parseInt(e.target.value));
                    }}
                    className={clsx(
                      "form-select",
                      {
                        "is-invalid": formik.touched.escritorioId && formik.errors.escritorioId,
                      },
                      {
                        "is-valid": formik.touched.escritorioId && !formik.errors.escritorioId,
                      }
                    )}
                    id="form-client"
                  >
                    <option value="0"> Selecione uma opção</option>
                    {escritorio.map((map) => {
                      return (
                        <option key={map.escritorioId} value={map.escritorioId}>
                          {" "}
                          {map.nome}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="col-md-6 mt-3">
                <label htmlFor="form-areas" className="form-label fw-bolder required text-orange">
                  Data de Corte:
                  <a style={{ fontSize: "12px" }}>
                    {<PopoverHelper></PopoverHelper>}
                  </a>
                </label>
                <DatePicker
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.dataCorte && formik.errors.dataCorte,
                    },
                    {
                      "is-valid": formik.touched.dataCorte && !formik.errors.dataCorte,
                    }
                  )}
                  id="form-dataCorte" selected={formik.values.dataCorte}
                  onChange={(date: Date) => formik.setFieldValue("dataCorte", date)}
                />

                {formik.touched.dataCorte && formik.errors.dataCorte && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span style={{ fontSize: "11px" }} role="alert">
                        {formik.errors.dataCorte}
                      </span>
                    </div>
                  </div>
                )}
              </div>



            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => formik.submitForm()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default XPayFechamentoField;