import { faEraser, faPlus, faSearch, faFileExcel, faTrash, faCloudDownloadAlt, faEye, } from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableColumn } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import GridPadrao from '../../components/Comum/GridPadrao';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import LayoutPageTitle from '../../layout/LayoutPageTitle';
import Swal from "sweetalert2";
import FiltroPesquisaDocumentoRequest from '../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest';
import IDocumento from '../../interfaces/IDocumento';
import XLearningService from '../../services/XLearningService';
import NovoXlearningModal from './NovoXlearningModal';
import { useAuth } from '../../contexts/AuthProvider';
import moment from 'moment';
import PreviewPDFField from '../../components/Comum/PreviewPDF/PreviewPDF';
import IModulo from '../../interfaces/IModulo';
import ModuloService from '../../services/ModuloService';
import { MultiSelect } from 'react-multi-select-component';
import ITipoDocumento from '../../interfaces/ITipoDocumento';
import TipoDocumentoService from '../../services/TipoDocumentoService';

const XlearningPage = () => {
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);
    const [documentos, setDocumentos] = useState<IDocumento[]>([]);
    const [relatorioId, setRelatorioId] = useState<number | null>(null);

    const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
    const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>('');

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaDocumentoRequest>({
        documentoId: [],
        processoId: 0,
        modulo: [],
        nome: "",
        status: 1,
        origem: 9,
        limit: 10,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        codigo: "",
        descricao: "",
        empresaId: 0,
        tipoDocumentoId: [],
        totalItems: 0,
        offset: 0,
        sort: "-dataCadastro",
    });

    const [modulo, setModulo] = useState<any>([]);
    const [moduloSelecionados, setModuloSelecionados] = useState([]);
    const [carregandoModulo, setCarregandoModulo] = useState<boolean>(false);

    const [tiposDocumentos, setTiposDocumentos] = useState<any>([]);
    const [tipoDocumentoSelecionados, setTipoDocumentoSelecionados] = useState([]);
    const [carregandoTipoDocumento, setCarregandoTipoDocumento] = useState<boolean>(false);

    const { user } = useAuth();

    function toggleModal(carregarDados?: boolean): void {
        if (carregarDados) {
            carregarDocumentos(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);
    }

    async function carregarDocumentos(filtro: FiltroPesquisaDocumentoRequest): Promise<void> {
        try {
            setCarregandoDocumentos(true);
            let resultado: IRecuperaLista<IDocumento>;

            resultado = await XLearningService.obterArquivos(filtro);

            if (resultado.data === null) {
                setDocumentos([]);
                setRelatorioId(null);
            }
            else {
                setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
                setRelatorioId(resultado.relatorioId || null);
                setDocumentos(resultado.data);
            }
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros.',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setDocumentos([]);
            setRelatorioId(null);
        }
        finally {
            setCarregandoDocumentos(false);
            setLoadingInicial(false);
        }
    }

    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                codigo: '',
                documentoId: [],
                processoId: 0,
                modulo: [],
                nome: "",
                status: 1,
                origem: 9,
                limit: 10,
                dataCadastroMaiorIgual: "",
                dataCadastroMenorIgual: "",
                descricao: "",
                empresaId: 0,
                tipoDocumentoId: [],
            }
        });
        setModuloSelecionados([]);
    }

    const previewArquivo = async (documentoId: number, nomeArquivo: string) => {

        try {
            const resultado = await XLearningService.previewDocument(documentoId);
            setUrlPDFDocumento(resultado.data);
            setModalExibirPDFDocumento(true);

        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Ocorreu um erro ao obter o arquivo`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        }
    };

    function verificaIsPreview(extensao: string) {
        if (extensao.endsWith('.pdf') || extensao.endsWith('.mp4'))
            return true;
        else
            return false;
    }

    const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
        Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Realizando download do conteúdo...',
            showConfirmButton: false,
        });
        Swal.showLoading();

        try {
            await XLearningService.download(documentoId)
                .then((response: any) => {
                    const disposition = response.headers["content-disposition"];
                    let fileName = "";
                    if (disposition && disposition.indexOf("attachment") !== -1) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].replace(/['"]/g, "");
                        }
                    }
                    const fileLink = document.createElement("a");
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    fileLink.setAttribute("download", nomeArquivo);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error: any) => {
                    throw new Error(error);
                });

            Swal.hideLoading();

            await Swal.fire({
                heightAuto: false,
                icon: 'success',
                text: `Conteúdo baixado com sucesso`,
                showConfirmButton: true,
                timer: 4000
            });

            Swal.close();

        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Não foi possivel baixar arquivos`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        }
    };

    async function baixarRelatorio(): Promise<void> {
        if (!documentos) return;

        if (relatorioId === 0 || relatorioId === null) {
            await Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `A pesquisa não retornou nenhum resultado.`,
                showConfirmButton: true,
                timer: 4000
            });
            return;
        }


        Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Baixando arquivos...',
            showConfirmButton: false,
        });
        Swal.showLoading();
        try {
            const arquivo = await XLearningService.gerarExcel(relatorioId || 0);
            if (arquivo) {
                const url = window.URL.createObjectURL(arquivo);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${new Date().toLocaleString('BR')} - Documentos.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            Swal.hideLoading();
            Swal.update({
                title: 'Baixado com sucesso',
                text: '',
                icon: 'success',
                showConfirmButton: true
            });

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível baixar arquivos',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false
            });
        }
    }

    async function carregarModulo() {
        try {
            if (modulo.length > 0) return;
            setCarregandoModulo(true);
            let resultado: IRecuperaLista<IModulo>;
            resultado = await ModuloService.obterModulos({
                nome: "",
                codigo: "",
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "nome",
            });
            setModulo(returnOptions(resultado.data, "moduloId", "modulo"));
            setCarregandoModulo(false);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setCarregandoModulo(false);
            setModulo([]);
        }
    }

    async function carregarTipoDocumento() {
        try {
            if (modulo.length > 0) return;
            setCarregandoTipoDocumento(true);
            let resultado: IRecuperaLista<ITipoDocumento>;
            resultado = await TipoDocumentoService.obterTipoDocumentos({
                nome: "",
                codigo: "",
                tipoDocumentoId: [53, 54],
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "nome",
            });
            setTiposDocumentos(returnOptions(resultado.data, "tipoDocumentoId", "tiposDocumentos"));
            setCarregandoTipoDocumento(false);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setCarregandoTipoDocumento(false);
            setTiposDocumentos([]);
        }
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarDocumentos(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

    useEffect(() => {
        carregarModulo();
        carregarTipoDocumento();
    }, []);

    const returnOptions = (array: any, propId: string, arrayName: string) => {
        array.forEach((item: any) => {
            eval(`${arrayName}`).push({
                value: eval(`item.${propId}`),
                label: item.nome,
            });
        });
        return eval(`${arrayName}`);
    };

    const setIdsSelect = (entidade: string, optionsSelected: any) => {
        const IdsSelected: number[] = [];

        optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

        switch (entidade) {
            case "moduloId":
                setFiltrosPesquisa({ ...filtrosPesquisa, modulo: IdsSelected });
                break;

            case "tipoDocumentoId":
                setFiltrosPesquisa({ ...filtrosPesquisa, tipoDocumentoId: IdsSelected });
                break;

        }
    };

    const colunas: TableColumn<IDocumento>[] = [
        {
            cell: (row: IDocumento) => {
                return (
                    <>
                        <a style={{ cursor: "pointer" }}>
                            <p
                                className="form-label fw-bolder text-orange"
                                style={{
                                    paddingTop: "30px",
                                }}
                            >
                                #{row.documentoId} - {row.nome} - {moment(row.dataCadastro).format("DD/MM/yyyy")}
                            </p>
                            <p>{row.descricao}</p>
                            <p>
                                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Módulo: </b> {row.modulo}
                                <br />
                                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo: </b> {row.tipoDocumentoNome}
                                <br />
                            </p>
                            <p>
                                <>
                                    <FontAwesomeIcon
                                        title="Realizar download"
                                        onClick={async () => {
                                            const swalWithBootstrapButtons = Swal.mixin({
                                                heightAuto: false,
                                                customClass: {
                                                    confirmButton: "btn btn-orange",
                                                    cancelButton: "btn btn-danger ms-5",
                                                },
                                                buttonsStyling: false,
                                            });
                                            try {

                                                baixarArquivo(row.documentoId, row.nome);

                                            } catch (error: any) {
                                                return Swal.fire({
                                                    heightAuto: false,
                                                    icon: 'error',
                                                    title: `Não foi possível realizar o download.`,
                                                    text: (error.response) ? (typeof error.response?.data === 'string' ? error.response.data : error.response.data.message) : error.message,
                                                    showConfirmButton: true
                                                });
                                            }

                                        }}
                                        style={{ fontWeight: "normal", cursor: "pointer" }}
                                        size="2x"
                                        className="mx-2 text-orange"
                                        icon={faCloudDownloadAlt}
                                    />

                                    <>{(verificaIsPreview(row.extensao)) &&
                                        <FontAwesomeIcon
                                            title="Preview"
                                            onClick={() => previewArquivo(row.documentoId, row.nome)}
                                            style={{ fontWeight: "normal", cursor: "pointer" }}
                                            size="2x"
                                            className="mx-2 text-orange"
                                            icon={faEye}
                                        />

                                    }</>

                                    <>{(user?.clienteId === 1) &&
                                        <FontAwesomeIcon
                                            title="Remover Conteúdo"
                                            onClick={async () => {
                                                const swalWithBootstrapButtons = Swal.mixin({
                                                    heightAuto: false,
                                                    customClass: {
                                                        confirmButton: "btn btn-orange",
                                                        cancelButton: "btn btn-danger ms-5",
                                                    },
                                                    buttonsStyling: false,
                                                });

                                                let result = await swalWithBootstrapButtons.fire({
                                                    title: "Remover",
                                                    text: `Você realmente deseja remover?`,
                                                    showCancelButton: true,
                                                    cancelButtonText: "Cancelar",
                                                    confirmButtonText: `Confirmar`,
                                                });

                                                if (result.isConfirmed) {

                                                    try {
                                                        XLearningService.inativaDocumento(row.documentoId);
                                                        await Swal.fire({
                                                            heightAuto: false,
                                                            icon: 'success',
                                                            text: `Conteúdo excluído com sucesso`,
                                                            showConfirmButton: false,
                                                            timer: 3000
                                                        });
                                                        carregarDocumentos(filtrosPesquisa);

                                                    } catch (error: any) {
                                                        return Swal.fire({
                                                            heightAuto: false,
                                                            icon: 'error',
                                                            title: `Não foi possível excluir o conteúdo.`,
                                                            text: (error.response) ? (typeof error.response?.data === 'string' ? error.response.data : error.response.data.message) : error.message,
                                                            showConfirmButton: true
                                                        });
                                                    }
                                                }
                                            }}
                                            style={{ fontWeight: "normal", cursor: "pointer" }}
                                            size="2x"
                                            className="mx-2 text-orange"
                                            icon={faTrash}
                                        />
                                    }</>

                                </>
                            </p>
                        </a>
                    </>
                );
            },
            ignoreRowClick: true,
        },
    ];

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<IDocumento>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };

    function togglePDFDocumento(): void {
        setModalExibirPDFDocumento(!exibirModalPDFDocumento);
    }


    return (<>
        <LayoutPageTitle title="Manuais e Vídeos">
            <button onClick={() => {
                setExibirModal(true)
            }} className="btn btn-md btn-orange">
                Novo Conteúdo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        {
            exibirModal &&
            <NovoXlearningModal
                exibirModal={exibirModal}
                titulo={`Novo Documento`}
                toggleModal={toggleModal} />
        }

        <PreviewPDFField
            exibirModal={exibirModalPDFDocumento}
            toggleModal={togglePDFDocumento}
            url={urlPDFDocumento}
        />


        <div className="row mt-2">
            <div className="col-md-12">
                <div className="col-md-12" >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        carregarDocumentos(filtrosPesquisa);
                    }} className="row g-3 mb-3 form-buscar-documento">
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
                            <DateRangePicker
                                className="form-control p-0"
                                calendarIcon={null}
                                showLeadingZeros={true}
                                maxDate={new Date()}
                                value={
                                    (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                                        [
                                            filtrosPesquisa.dataCadastroMaiorIgual,
                                            filtrosPesquisa.dataCadastroMenorIgual,
                                        ] : ''
                                }
                                onChange={(datas: Date[]) => {
                                    if (!datas || datas.length === 0) {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCadastroMaiorIgual: '',
                                            dataCadastroMenorIgual: '',
                                        }));
                                        return;
                                    }

                                    let data_inicial = datas[0];
                                    let data_final = datas[1];

                                    const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                                    if (isValidDate(data_inicial)) {
                                        if (isValidDate(data_final)) {
                                            data_final.setHours(0, 0, 0, 0);
                                            data_final = new Date(data_final.getTime() - 1);
                                        }

                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                            dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                        }));
                                    } else {
                                        setFiltrosPesquisa(oldValue => ({
                                            ...oldValue,
                                            dataCadastroMaiorIgual: '',
                                            dataCadastroMenorIgual: '',
                                        }));
                                    }
                                }}

                            />
                        </div>

                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-descricao" className="form-label fw-bolder text-orange">Descrição:</label>
                            <input value={filtrosPesquisa.descricao} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, descricao: e.target.value } });
                            }} placeholder="Descrição" className={'form-control'} id="form-descricao" />
                        </div>

                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código:</label>
                            <input value={filtrosPesquisa.codigo} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, codigo: e.target.value } });
                            }} placeholder="Código" className={'form-control'} id="form-codigo" />
                        </div>

                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                            <input value={filtrosPesquisa.nome} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, nome: e.target.value } });
                            }} placeholder="Nome" className={'form-control'} id="form-nome" />
                        </div>

                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-tipoDocumentoId" className="form-label fw-bolder text-orange">
                                Tipo de Documento:
                            </label>
                            <MultiSelect
                                isLoading={carregandoTipoDocumento}
                                disabled={carregandoTipoDocumento}
                                options={tiposDocumentos}
                                value={tipoDocumentoSelecionados}
                                onChange={(event: any) => {
                                    setTipoDocumentoSelecionados(event);
                                    setIdsSelect("tipoDocumentoId", event);
                                }}
                                labelledBy={"Selecione..."}
                                overrideStrings={{
                                    selectSomeItems: "Selecione...",
                                    allItemsAreSelected: "Todos selecionados",
                                    selectAll: "Selecione todos",
                                    search: "Pesquise",
                                    selectAllFiltered: "Selecione todos (filtrados)",
                                }}
                            />
                        </div>

                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-modeulo" className="form-label fw-bolder text-orange">
                                Módulo:
                            </label>
                            <MultiSelect
                                isLoading={carregandoModulo}
                                disabled={carregandoModulo}
                                options={modulo}
                                value={moduloSelecionados}
                                onChange={(event: any) => {
                                    setModuloSelecionados(event);
                                    setIdsSelect("moduloId", event);
                                }}
                                labelledBy={"Selecione..."}
                                overrideStrings={{
                                    selectSomeItems: "Selecione...",
                                    allItemsAreSelected: "Todos selecionados",
                                    selectAll: "Selecione todos",
                                    search: "Pesquise",
                                    selectAllFiltered: "Selecione todos (filtrados)",
                                }}
                            />
                        </div>

                        <div className="col-12 "
                            style={{ textAlign: 'right' }}>
                            <button
                                type="submit"
                                className="btn btn-orange search-buttom-margin "
                                title="Buscar"
                            >
                                <FontAwesomeIcon color='white' className='' icon={faSearch} />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    limparFiltros();
                                }}
                                className="btn btn-orange search-buttom-margin ms-2"
                                title="Limpar Filtro"
                            >
                                <FontAwesomeIcon color='white' className='' icon={faEraser} />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    baixarRelatorio();
                                }}
                                className="btn btn-orange search-buttom-margin ms-2"
                                title="Baixar Relatório"
                                disabled={relatorioId === null || carregandoDocumentos}
                            >
                                <FontAwesomeIcon color='white' className='' icon={faFileExcel} />
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 mb-10">
                    <GridPadrao
                        onSort={handleSort}
                        progressPending={carregandoDocumentos}
                        limit={filtrosPesquisa.limit}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationServer={true}
                        paginationTotalRows={filtrosPesquisa.totalItems}
                        colunas={colunas} tipo='Documentos' itens={documentos} />
                </div>
            </div>
        </div >
    </>)
}

export default XlearningPage;