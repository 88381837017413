import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoDesdobramento from "../interfaces/ITipoDesdobramento";
import { AdicionaTipoDesdobramentoRequest } from "../interfaces/Requests/TipoDesdobramento/AdicionaTipoDesdobramentoRequest";
import FiltroPesquisaTipoDesdobramentoRequest from "../interfaces/Requests/TipoDesdobramento/FiltroPesquisaTipoDesdobramentoRequest";
import { EditarTipoDesdobramentoRequest } from "../interfaces/Requests/TipoDesdobramento/EditarTipoDesdobramentoRequest";

class TipoDesdobramentoService {

    async obterTipoDesdobramentos(filtros: FiltroPesquisaTipoDesdobramentoRequest): Promise<IRecuperaLista<ITipoDesdobramento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoDesdobramento>>(`TipoDesdobramento/RecuperaTipoDesdobramento?${query}`);

        return result;
    }

    async obterListaLimitadaTipoDesdobramentos(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoDesdobramento/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoDesdobramento(adicionaTipoDesdobramento: AdicionaTipoDesdobramentoRequest): Promise<ITipoDesdobramento> {
        let { data: result } = await api.post<ITipoDesdobramento>(`TipoDesdobramento/AdicionaTipoDesdobramento`, adicionaTipoDesdobramento);

        return result;
    }


    async alterarStatus(tipoDesdobramentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoDesdobramento>(`TipoDesdobramento/AtualizaTipoDesdobramentoPorId?id=${tipoDesdobramentoId}`, data);
    }

    async atualizarTipoDesdobramento(editarTipoDesdobramento: EditarTipoDesdobramentoRequest): Promise<void> {

        let dataTipoDesdobramento = [
            { "op": "replace", "path": "/nome", "value": editarTipoDesdobramento.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoDesdobramento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoDesdobramento/AtualizaTipoDesdobramentoPorId?id=${editarTipoDesdobramento.tipoDesdobramentoId}`, dataTipoDesdobramento)
        ]);
    }
}


export default new TipoDesdobramentoService();