import { useFormik } from "formik";
import { Nullable } from "../../types/Nullable";
import { notifyDanger } from "../Toast/ToastFormik";
import { ToastContainer } from "react-toastify";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { ICampoTabela } from "../../../interfaces/ICampoTabela";

interface ITabelaXCampos {
  setFieldValue: any;
}



const TabelaModalXCampos = ({ setFieldValue }: ITabelaXCampos) => {

  interface ITabela {
    quantidadeColunas: Nullable<number>;
    xCampoTabelaPropriedades: ICampoTabela[];
  }

  const initialValues = {
    quantidadeColunas: null,
    xCampoTabelaPropriedades: []
  } as ITabela;


  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const setColunas = () => {
    formik.values.xCampoTabelaPropriedades = [];

    if (formik?.values?.quantidadeColunas && formik?.values?.quantidadeColunas > 5) {
      notifyDanger('Quantidada maxima é de 5 colunas');
      return;
    }

    if (formik?.values?.quantidadeColunas) {
      for (let index = 0; index < formik?.values?.quantidadeColunas; index++) {
        formik.values.xCampoTabelaPropriedades.push({
          colunaIndex: index,
          label: "",
          tipo: 0,
          obrigatorio: false,
          linhaIndex: 0,
          help: "",
        })
      }
    }
    formik.setValues(formik?.values);
  }

  const renderCampos = () => {

    let component: any[] = [];
    formik?.values?.xCampoTabelaPropriedades.forEach((coluna, index) =>
      component.push(<>
        <div className="row">

          <div className="col-md-3 mt-3">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Coluna - {index + 1} - Label
            </label>
            <input
              value={formik.values.xCampoTabelaPropriedades[index].label}
              placeholder="Label"
              maxLength={20}
              type="text"
              className={"form-select"}
              onChange={(e) => {
                formik.values.xCampoTabelaPropriedades[index].label = e.target.value.replace(/[0-9]/g, '');
                formik.setValues(formik.values);
                setFieldValue('xCampoTabelaPropriedades', formik.values.xCampoTabelaPropriedades);
              }}
            />
          </div>

          <div className="col-md-3 mt-3">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Coluna - {index + 1} - Tipo
            </label>
            <select
              value={formik.values.xCampoTabelaPropriedades[index].tipo}
              className={"form-select"}
              id="form-select"
              onChange={(e) => {
                formik.values.xCampoTabelaPropriedades[index].tipo = parseInt(e.target.value);
                formik.setValues(formik.values);
                setFieldValue('xCampoTabelaPropriedades', formik.values.xCampoTabelaPropriedades);
              }}
            >
              <option value="0" label="Selecione o Tipo" />
              <option value="2" label="Data" />
              <option value="3" label="Texto" />
              <option value="4" label="Valor" />
              <option value="9" label="Inteiro" />
            </select>
          </div>

          <div className="col-md-3 mt-3">
            <label htmlFor="form-label" className="form-label fw-bolder text-orange">
              Obrigatório?
            </label>
            <br></br>
            <FormCheckInput
              type="checkbox"
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              checked={formik?.values?.xCampoTabelaPropriedades[index]?.obrigatorio}
              onChange={(e) => {
                if (formik?.values?.xCampoTabelaPropriedades[index]?.obrigatorio) {
                  formik.values.xCampoTabelaPropriedades[index].obrigatorio = false;
                  setFieldValue('xCampoTabelaPropriedades', formik.values.xCampoTabelaPropriedades);
                }
                else {
                  formik.values.xCampoTabelaPropriedades[index].obrigatorio = true;
                  setFieldValue('xCampoTabelaPropriedades', formik.values.xCampoTabelaPropriedades);
                }
              }}
            />
          </div>

          <div className="col-md-3 mt-3">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Texto de Ajuda
            </label>
            <input
              value={formik.values.xCampoTabelaPropriedades[index]?.help}
              placeholder="Label"
              maxLength={20}
              type="text"
              className={"form-select"}
              onChange={(e) => {
                formik.values.xCampoTabelaPropriedades[index].help = e.target.value.replace(/[0-9]/g, '');
                formik.setValues(formik.values);
                setFieldValue('xCampoTabelaPropriedades', formik.values.xCampoTabelaPropriedades);
              }}
            />
          </div>

        </div>

      </>));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />
      <div className="col-md-3 mt-3">
        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
          Quantidade de Colunas*:
        </label>
        <input
          {...formik.getFieldProps("quantidadeColunas")}
          placeholder="Informar Quantidade"
          maxLength={3}
          type="number"
          className={"form-control"}
          onKeyUp={() => setColunas()}
        />
      </div>
      {renderCampos()}
    </>
  );
};
export default TabelaModalXCampos;
