import { Editor, Text, Transforms } from "slate";
import { CustomEditor, CustomText, Mark } from "../../../../../../utils/types/slate";
import { MarkOption } from "./types";
import { ReactEditor } from "slate-react";

export const isMarkActive = (editor: CustomEditor, mark: MarkOption) => {
  const customEditorMarks = Editor.marks(editor) as CustomText;
  const marks = customEditorMarks?.marks
  return marks?.[mark] ?? false
};

export const markHandler = (editor: CustomEditor, option: MarkOption) => {
  const isActive = isMarkActive(editor, option);
  const { marks } = Editor.marks(editor) as CustomText;

  const selectedMark: Mark = {
    ...marks,
    [option]: !isActive,
  };

  Transforms.setNodes(
    editor,
    {
      marks: selectedMark,
    },
    {
      match: (node) => Text.isText(node),
      split: true,
    }
  );

  ReactEditor.focus(editor)
};
