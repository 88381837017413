import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ICargo from "../interfaces/ICargo";
import { AdicionaCargoRequest } from "../interfaces/Requests/Cargo/AdicionaCargoRequest";
import FiltroPesquisaCargoRequest from "../interfaces/Requests/Cargo/FiltroPesquisaCargoRequest";
import { EditarCargoRequest } from "../interfaces/Requests/Cargo/EditarCargoRequest";

class CargoService {

    async obterCargos(filtros: FiltroPesquisaCargoRequest): Promise<IRecuperaLista<ICargo>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICargo>>(`Cargo/RecuperaCargo?${query}`);

        return result;
    }

    async adicionaCargo(adicionaCargo: AdicionaCargoRequest): Promise<ICargo> {
        let { data: result } = await api.post<ICargo>(`Cargo/AdicionaCargo`, adicionaCargo);

        return result;
    }


    async alterarStatus(cargoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ICargo>(`Cargo/AtualizaCargoPorId?id=${cargoId}`, data);
    }

    async atualizarCargo(editarCargo: EditarCargoRequest): Promise<void> {

        let dataCargo = [
            { "op": "replace", "path": "/nome", "value": editarCargo.nome },
            { "op": "replace", "path": "/codigo", "value": editarCargo.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Cargo/AtualizaCargoPorId?id=${editarCargo.cargoId}`, dataCargo)
        ]);
    }
}


export default new CargoService();