import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ICentroCusto from "../interfaces/ICentroCusto";
import { AdicionaCentroCustoRequest } from "../interfaces/Requests/CentroCusto/AdicionaCentroCustoRequest";
import FiltroPesquisaCentroCustoRequest from "../interfaces/Requests/CentroCusto/FiltroPesquisaCentroCustoRequest";
import { EditarCentroCustoRequest } from "../interfaces/Requests/CentroCusto/EditarCentroCustoRequest";

class CentroCustoService {

    async obterCentroCustos(filtros: FiltroPesquisaCentroCustoRequest): Promise<IRecuperaLista<ICentroCusto>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }
        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`TipoCentroCusto/RecuperaTipoCentroCusto?${query}`);

        return result;
    }

    async obterListaLimitadaCentroCustos(filtros: FiltroPesquisaCentroCustoRequest): Promise<IRecuperaLista<ICentroCusto>> {

        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`


        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }
        
        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`TipoCentroCusto/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaCentroCusto(adicionaCentroCusto: AdicionaCentroCustoRequest): Promise<ICentroCusto> {
        let { data: result } = await api.post<ICentroCusto>(`TipoCentroCusto/AdicionaTipoCentroCusto`, adicionaCentroCusto);

        return result;
    }


    async alterarStatus(tipoCentroCustoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ICentroCusto>(`TipoCentroCusto/AtualizaTipoCentroCustoPorId?id=${tipoCentroCustoId}`, data);
    }

    async atualizarCentroCusto(editarCentroCusto: EditarCentroCustoRequest): Promise<void> {

        let dataCentroCusto = [
            { "op": "replace", "path": "/nome", "value": editarCentroCusto.nome },
            { "op": "replace", "path": "/codigo", "value": editarCentroCusto.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoCentroCusto/AtualizaTipoCentroCustoPorId?id=${editarCentroCusto.tipoCentroCustoId}`, dataCentroCusto)
        ]);
    }
}


export default new CentroCustoService();