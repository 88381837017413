import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { faCheckCircle, faFileArchive, faTimes } from "@fortawesome/free-solid-svg-icons";
import CompromissoService from "../../../services/CompromissoService";
import IFileUploadField from "../../FileUpload/FileUploadField";
import { useState } from "react";
import { EModulo } from "../../../enum";

interface ICompromissoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  compromissoId: number;
  tipoCompromissoId?: number;
  objetoId?: number
  setAtualizarTabelaCompromisso(atualiza: boolean): void;
}

const CompromissoConcluirField = ({
  toggleModal,
  exibirModal,
  compromissoId,
  setAtualizarTabelaCompromisso,
  tipoCompromissoId,
  objetoId: processoId }: ICompromissoFieldProps) => {
  const initialValues: any = {
    observacao: "",
  };

  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [importarArquivos, setImportarArquivos] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);
  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);


  const schema = () => {
    let schema: any = {};
    schema.observacao = Yup.string().min(3, "Campo observação está inválido").required("Campo observação é obrigatório");
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema(),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Registrar Compromisso ",
          text: `Você realmente deseja concluir?`,
          showCancelButton: true,
          cancelButtonText: "Concluir",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          CompromissoService.concluirCompromisso(values, compromissoId, processoId || 0);
          setAtualizarTabelaCompromisso(true);
          setImportarArquivos(true);
          setSubmitting(false);
          formik.resetForm();
          toggleModal();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              #{compromissoId}  Concluir <FontAwesomeIcon className="mx-2 text-orange" icon={faCheckCircle} />
            </h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
        </div>

        <div className="modal-body">
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            >
              {
                tipoCompromissoId === 9 &&
                <div className="row mt-3">
                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-status" className="form-label required fw-bolder text-orange">Há necessidade de solicitação de Subsídios?</label>
                    <select
                      placeholder="Há necessidade de solicitação de Subsídios?" className={'form-select'} id="form-status" >
                      <option value={1}>Sim</option>
                      <option value={2}>Não</option>
                    </select>
                  </div>
                </div>
              }
              {
                tipoCompromissoId === 15 &&
                <div className="row mt-3">
                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-status" className="form-label required fw-bolder text-orange">De acordo com a peça elaborada?</label>
                    <select
                      placeholder="De acordo com a peça elaborada?" className={'form-select'} id="form-status" >
                      <option value={1}>Sim</option>
                      <option value={2}>Não</option>
                    </select>
                  </div>
                </div>
              }

              {tipoCompromissoId === (12) &&
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                    Anexar Subsídios:{" "}
                  </label>
                  <br />
                  <>
                    <Button
                      onClick={() => {
                        setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);
                        setImportarArquivos(false);
                      }}
                      style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                      variant="secondary"
                      size="sm"
                    >
                      <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                      Selecione os Subsídios
                    </Button>
                    <IFileUploadField
                      modulo={EModulo.AreaDireito}
                      importarArquivos={importarArquivos}
                      exibirAdicionar={false}
                      setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                      exibirModal={exibirModalImportacaoArquivos}
                      toggleModal={toggleImportacaoArquivos}
                      processoId={processoId}
                      areaDireitoId={1}
                      tipoContratoId={0}
                      tipoImovelId={0}
                      tipoLicencaId={0}
                      tipoConsultaId={0}
                      tipoProcuracaoId={0}
                      tipoAudienciaId={0}
                      capa={true}
                    />
                  </>
                </div>
              }

              {tipoCompromissoId === (11) &&
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                    Anexar Peça:{" "}
                  </label>
                  <br />
                  <>
                    <Button
                      onClick={() => {
                        setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);
                        setImportarArquivos(false);
                      }}
                      style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                      variant="secondary"
                      size="sm"
                    >
                      <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                      Selecione os Documentos
                    </Button>
                    <IFileUploadField
                      modulo={EModulo.AreaDireito}
                      importarArquivos={importarArquivos}
                      exibirAdicionar={false}
                      setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                      exibirModal={exibirModalImportacaoArquivos}
                      toggleModal={toggleImportacaoArquivos}
                      processoId={processoId}
                      areaDireitoId={1}
                      tipoContratoId={0}
                      tipoImovelId={0}
                      tipoLicencaId={0}
                      tipoConsultaId={0}
                      tipoProcuracaoId={0}
                      tipoAudienciaId={0}
                      capa={true}
                    />
                  </>
                </div>
              }
              {tipoCompromissoId === (14) &&
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                    Anexar Arquivo:{" "}
                  </label>
                  <br />
                  <>
                    <Button
                      onClick={() => {
                        setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);
                        setImportarArquivos(false);
                      }}
                      style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                      variant="secondary"
                      size="sm"
                    >
                      <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                      Selecione os Documentos
                    </Button>
                    <IFileUploadField
                      modulo={EModulo.AreaDireito}
                      importarArquivos={importarArquivos}
                      exibirAdicionar={false}
                      setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                      exibirModal={exibirModalImportacaoArquivos}
                      toggleModal={toggleImportacaoArquivos}
                      processoId={processoId}
                      areaDireitoId={1}
                      tipoContratoId={0}
                      tipoImovelId={0}
                      tipoLicencaId={0}
                      tipoConsultaId={0}
                      tipoProcuracaoId={0}
                      tipoAudienciaId={0}
                      capa={true}
                    />
                  </>
                </div>
              }


              <div className="row mt-3">
                <div className="col-md-6">
                  <label htmlFor="form-observacao" className="form-label required fw-bolder text-orange">
                    Observação:
                  </label>
                  <textarea {...formik.getFieldProps("observacao")} placeholder={""} className={"form-control"} />
                  {formik.touched.observacao && formik.errors.observacao && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.observacao}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-3">

                <div className="modal-footer" style={{ margin: "0 auto" }}>
                  <div className="row mt-1" style={{ margin: "0 auto" }}>
                    <div className="col-md-6">
                      <button disabled={!formik.isValid} type="submit" className="btn btn-orange">
                        Concluir
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button onClick={() => toggleModal()} type="button" className="btn btn-danger float-end">
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default CompromissoConcluirField;
