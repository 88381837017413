import { useContext, useEffect, useState } from "react";
import { faArrowRight, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Validate } from "validate-cnj";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";
import * as Yup from "yup";

import LayoutPageForButton from "../../layout/LayoutPageButton";
import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";
import { ECamposIDEnum } from "../../enum/ECamposIDEnum";

import IAreaDireito from "../../interfaces/IAreaDireito";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import IAdicionaProcessoRequest from "../../interfaces/Requests/Processo/IAdicionaProcessoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ProcessoService from "../../services/ProcessoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";
import AreaDireitoService from "../../services/AreaDireitoService";

import { EModulo, ETelaEnum } from "../../enum";

import { setValuesXCadastroCampos } from "../../utils/SchemasUtil";
import { OrangeContext } from "../../contexts/OrangeProvider";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";
import { areaDireitoInicialValues, processoInitialValues } from "../../contexts/InitialValuesContext";

const ProcessoImovelCadastroPage = () => {
  const navigate = useNavigate();

  const { processo, setProcesso, setAreaDireito, areaDireito, processoCadastro, imovel } = useContext<OrangeInterfaceContext>(OrangeContext);
  
  const [carregandoAreasDireito, setCarregandoAreasDireito] = useState<boolean>(false);
  const [areasDireito, setAreasDireito] = useState<IAreaDireito[]>([]);
  
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [adicionarProcessoLoading, setAdicionarProcessoLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const initialValues: IAdicionaProcessoRequest = {
    processoId: 0,
    areaDireitoId: areaDireito.areaDireitoId,
    imovelId: imovel.imovelId,
    modulo: EModulo.Default,
    tela: ETelaEnum.Default,
    categoriaId: 0,

    numeroprocesso: "",
    pasta: "",

    faseProcessualId: 0,
    subareadireitoId: 0,
    tipoContingenciaId: 0,
    statusprocessoId: 0,
    classificacaoId: 0,
    ambitoId: 0,
    esferaId: 0,
    motivoencerramentoId: 0,
    instanciaId: 0,
    tipoAcaoId: 0,
    riscoId: 0,
    cidadeId: 0,
    escritorioId: 0,
    estadoId: 0,
    formaparticipacaoId: 0,
    paisId: 0,
    tipodocumentoId: 0,
    codigo: "",
    clienteId: 0,
    juizId: 0,
    xFinderDistribuicaoId: null,

    datadistribuicao: null,
    datarecebimento: null,
    prazofatal: null,
    dataencerramento: null,
    dataencerramentocadastro: null,
    databaixaprovisoria: null,
    databaixaprovisoriacadastro: null,
    descricaoobjetoacao: "",
    observacoesprocesso: "",
    resumoprocesso: "",
    valorcausa: 0,

    partesContrariasList: [],
    empresasList: [],
    advogadoInternoList: [],
    advogadoList: [],
    centrosCusto: [],
    xCampoValorList: [],
    audienciaList: [],
    quantidadeArquivosSelecionados: 0,
    quantidadeDocumentosCheckList: 0
  };

  useEffect(() => {
    
    carregarAreaDireito();
    
    setProcesso(processoInitialValues);
    
    setAreaDireito(areaDireitoInicialValues);
    
    setConfiguracoesTela([]);

  }, []);

  useEffect(() => {
    if (areaDireito.areaDireitoId > 0) carregarConfiguracaoTela();
  }, [areaDireito.areaDireitoId]);

  const processoSchema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`Campo ${configuracao.label} é obrigatório`);
          if (configuracao.campoNome.toLowerCase() == ECamposIDEnum.NumeroProcesso.toLowerCase() && formik.values.esferaId == 1) {
            schema[configuracao.campoNome.toLowerCase()] = Yup.string()
              .required("Número do processo está inválido")
              .test("numeroProcesso", "", function (value: any) {
                try {
                  Validate.load(value);
                } catch (error) {
                  return this.createError({
                    message: "Número do processo está inválido",
                  });
                }
                return true;
              });
          }

          break;

        case ETipoCampoEnum.Lista:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}Id`] = Yup.number().min(1, `${configuracao.label} é um campo obrigatório`);

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.TipoDocumentoCheckList) {
            schema.quantidadeDocumentosCheckList = Yup.number().min(1, "Documentos do checklist são obrigatórios");
          }
  
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.TipoDocumento) {
            schema.quantidadeArquivosSelecionados = Yup.number().min(1, "Documentos são obrigatórios");
          }
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          schema[configuracao.campoNome.toLowerCase()] = Yup.date()
            .transform((value) => {
              return value ? moment(value).toDate() : value;
            })
            .required(`Campo ${configuracao.label} é obrigatório`)
            .max(new Date(), "Por favor informe uma data válida")
            .typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Empresa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Empresa) {
            schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Pessoa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Advogado) {
            schema.advogadoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno) {
            schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.ParteContraria) {
            schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: processoCadastro.numeroprocesso ? processoCadastro : initialValues,
    validationSchema: processoSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Processo",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          let msgCarregando = "Realizando o cadastro do processo...";
          if (processoCadastro.xFinderDistribuicaoId && processoCadastro.xFinderDistribuicaoId > 0) {
            msgCarregando = "Realizando o cadastro do processo, esta ação pode demorar um pouco, pois estamos baixando os documentos do tribunal...";
          }
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: msgCarregando,
            showConfirmButton: false,
          });
          Swal.showLoading();
          setAdicionarProcessoLoading(true);
          let resultado = await ProcessoService.adicionaProcesso({
            ...values,
            areaDireitoId: areaDireito.areaDireitoId,
            xFinderDistribuicaoId: processoCadastro.xFinderDistribuicaoId,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });
          Swal.hideLoading();
          setProcesso(resultado);
          setAreaDireito(areaDireito);
          formik.resetForm();
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Processo cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarProcessoLoading(false);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel cadastrar o processo.`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarProcessoLoading(false);
      }
    },
  });

  const carregarAreaDireito = async (cliente: number | null | undefined = undefined) => {
    try {
      setCarregandoAreasDireito(true);
      let resultado = await AreaDireitoService.obterListaLimitada(cliente);
      setAreasDireito(resultado.data);
      setCarregandoAreasDireito(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
      setCarregandoAreasDireito(false);
      setAreasDireito([]);
    }
  };

  const carregarConfiguracaoTela = async () => {
    setCarregandoConfiguracoesTela(true);
    let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
      AreaDireitoId: areaDireito.areaDireitoId,
      Modulo: EModulo.AreaDireito,
      Tela: ETelaEnum.TelaCadastroProcesso,
    });
    setConfiguracoesTela(resultado.data);
    setCarregandoConfiguracoesTela(false);
  };

  const onChangeAreaDireito = (value: number, texto: string) => {
    setConfiguracoesTela([]);
    formik.resetForm();
    formik.setFieldValue("areaDireitoId", value);
    formik.setFieldValue("categoriaId", value);
    formik.setFieldValue("modulo", EModulo.AreaDireito);
    formik.setFieldValue("tela", ETelaEnum.TelaCadastroProcesso);
    setAreaDireito({
      areaDireitoId: value,
      nome: texto,
      checked: false,
      codigo: "",
      status: "",
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <LayoutPageForButton title={`Cadastro de Processo ${areaDireito.areaDireitoId > 0 ? " - " + areaDireito.nome : ""} para Imóvel: #${imovel.imovelId}`}>
        {processo.processoId > 0 ? (
          <>
            <button onClick={() => navigate("/Regulatorio/Processo/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{processo.processoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button onClick={() => formik.submitForm()} disabled={!formik.isValid} type="submit" className="btn btn-orange me-5">
              {!adicionarProcessoLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
                </>
              )}
              {adicionarProcessoLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarProcessoLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">
        {carregandoAreasDireito ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando área de direito...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Áreas
            </label>
            <select
              name="areaDireitoId"
              value={areaDireito.areaDireitoId}
              className={"form-select"}
              onChange={(event: any) => onChangeAreaDireito(event.target.value, event.target[event.target.selectedIndex].text)}
              placeholder="Nome"
              id="form-area"
            >
              <option value="0"> Selecione uma área</option>
              {areasDireito.map((area) => {
                return (
                  <option key={area.areaDireitoId} value={area.areaDireitoId}>
                    {area.nome}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>

      <hr></hr>

      {formik.values.areaDireitoId > 0 && (
        <>
          {renderCampos()}
        </>
      )}
    </>
  );
};

export default ProcessoImovelCadastroPage;
