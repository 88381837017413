import {
  faCheckCircle,
  faEraser,
  faPen,
  faPlus,
  faSearch,
  faTrashAlt,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IGrupoEconomico from "../../interfaces/IGrupoEconomico";
import FiltroPesquisaGrupoEconomicoRequest from "../../interfaces/Requests/GrupoEconomico/FiltroPesquisaGrupoEconomicoRequest";

import GrupoEconomicoModal from "./GrupoEconomicoModal";
import GridPadrao from "../../components/Comum/GridPadrao";
import GrupoEconomicoService from "../../services/GrupoEconomicoService";
import OrganogramaModal from "../ConfiguracaoTelaPage/Empresa/Organograma/Modal";

const GrupoEconomicoPage = () => {
  const [grupoEconomico, setGrupoEconomico] = useState<IGrupoEconomico>();
  const [grupoEconomicos, setGrupoEconomicos] = useState<IGrupoEconomico[]>([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoGrupoEconomico, setCarregandoGrupoEconomico] =
    useState(false);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [exibirOrganograma, setExibirOrganograma] = useState(false);

  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaGrupoEconomicoRequest>({
      nome: "",
      codigo: "",
      status: 1,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "id",
    });

  function toggleModal(carregarDados?: boolean): void {
    if (carregarDados) {
      carregarGrupoEconomico(filtrosPesquisa);
    }

    setExibirModal(!exibirModal);

    setTimeout(() => {
      setGrupoEconomico(undefined);
    }, 300);
  }

  async function carregarGrupoEconomico(
    filtro: FiltroPesquisaGrupoEconomicoRequest
  ) {
    try {
      setCarregandoGrupoEconomico(true);
      let resultado: IRecuperaLista<IGrupoEconomico>;

      resultado = await GrupoEconomicoService.obterGrupoEconomicos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setGrupoEconomicos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setGrupoEconomicos([]);
    } finally {
      setCarregandoGrupoEconomico(false);
      setLoadingInicial(false);
    }
  }

  async function alterarStatus(grupoEconomicoId: number, status: number) {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await GrupoEconomicoService.alterarStatus(grupoEconomicoId, status);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status do Grupo Econômico alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();

        setFiltrosPesquisa((oldState) => {
          carregarGrupoEconomico(oldState);
          return oldState;
        });
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o Status do Grupo Econômico`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  }

  function mostrarOrganograma(grupoEconomico: IGrupoEconomico) {
    setGrupoEconomico(grupoEconomico)
    setExibirOrganograma((prev) => !prev)
  }

  const colunas: TableColumn<IGrupoEconomico>[] = [
    {
      name: "Id",
      sortField: "grupoEconomicoId",
      selector: (row: IGrupoEconomico) => row.grupoEconomicoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "grupoEconomicoNome",
      selector: (row: IGrupoEconomico) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IGrupoEconomico) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (grupoEconomico: IGrupoEconomico) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Editar Grupo Econômico"
              onClick={() => {
                setGrupoEconomico(grupoEconomico);
                setExibirModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faPen}
            />

            <FontAwesomeIcon
              onClick={() => mostrarOrganograma(grupoEconomico)}
              title="Organograma"
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faProjectDiagram}
            />

            {grupoEconomico.status === "Ativo" ? (
              <FontAwesomeIcon
                title="Desativar Grupo Econômico"
                onClick={() => {
                  alterarStatus(grupoEconomico.grupoEconomicoId, -1);
                }}
                style={{ fontWeight: "normal", cursor: "pointer" }}
                size="2x"
                className="mx-2 text-orange"
                icon={faTrashAlt}
              />
            ) : (
              <FontAwesomeIcon
                title="Ativar Grupo Econômico"
                onClick={() => {
                  alterarStatus(grupoEconomico.grupoEconomicoId, 1);
                }}
                style={{ fontWeight: "normal", cursor: "pointer" }}
                size="2x"
                className="mx-2 text-orange"
                icon={faCheckCircle}
              />
            )}
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<IGrupoEconomico>,
    sortDirection: string
  ) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
        status: 1,
      };
    });
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarGrupoEconomico(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageTitle title="Grupo Econômico">
        <button
          onClick={() => {
            setGrupoEconomico(undefined);
            setExibirModal(true);
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      {grupoEconomico && (
        <OrganogramaModal
          type="grupo"
          show={exibirOrganograma}
          empresaId={grupoEconomico.grupoEconomicoId}
          nomeEmpresa={grupoEconomico.nome}
          onHide={() => setExibirOrganograma((prev) => !prev)}
        />
      )}

      <GrupoEconomicoModal
        exibirModal={exibirModal}
        titulo={grupoEconomico ? "Editar Registro" : "Criar Registro"}
        toggleModal={toggleModal}
        grupoEconomico={grupoEconomico}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarGrupoEconomico(filtrosPesquisa);
              }}
              className="row g-3 mb-3"
            >
              <div className="col-md-2 ">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder text-orange"
                >
                  Nome:
                </label>
                <input
                  value={filtrosPesquisa.nome}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nome: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-nome"
                />
              </div>
              <div className="col-md-2 ">
                <label
                  htmlFor="form-codigo"
                  className="form-label fw-bolder text-orange"
                >
                  Código:
                </label>
                <input
                  value={filtrosPesquisa.codigo}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, codigo: e.target.value };
                    });
                  }}
                  placeholder="Código"
                  type="text"
                  className={"form-control"}
                  id="form-codigo"
                />
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder text-orange"
                >
                  Status:
                </label>
                <select
                  value={filtrosPesquisa.status}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, status: parseInt(e.target.value) };
                    });
                  }}
                  placeholder="Nome"
                  className={"form-select"}
                  id="form-select"
                >
                  <option value="0" label="Todos">
                    {" "}
                    Todos{" "}
                  </option>
                  <option value="1" label="Ativo">
                    {" "}
                    Ativo{" "}
                  </option>
                  <option value="-1" label="Inativo">
                    {" "}
                    Inativo{" "}
                  </option>
                </select>
              </div>
              <div className="col-md-2">
                <div>
                  <button
                    type="submit"
                    className="btn btn-sm btn-orange search-buttom-margin "
                  >
                    <FontAwesomeIcon
                      color="white"
                      className="mx-2"
                      icon={faSearch}
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      limparFiltros();
                    }}
                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  >
                    <FontAwesomeIcon
                      color="white"
                      className="mx-2"
                      icon={faEraser}
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-12 mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoGrupoEconomico}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Grupo Econômico"
              itens={grupoEconomicos}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GrupoEconomicoPage;
