const image = "/images/logo-xjur.png";

export default function Carregando() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="text-center">
        <img src={image} alt="Logo Xjur" style={{ width: "150px", maxWidth: "100%" }} className="img-fluid heartBeat" />
      </div>
    </div>
  );
}