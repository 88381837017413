import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoContrato from "../interfaces/ITipoContrato";
import { AdicionaTipoContratoRequest } from "../interfaces/Requests/TipoContrato/AdicionaTipoContratoRequest";
import FiltroPesquisaTipoContratoRequest from "../interfaces/Requests/TipoContrato/FiltroPesquisaTipoContratoRequest";
import { EditarTipoContratoRequest } from "../interfaces/Requests/TipoContrato/EditarTipoContratoRequest";

class TipoContratoService {
  async obterTipoContratos(filtros: FiltroPesquisaTipoContratoRequest): Promise<IRecuperaLista<ITipoContrato>> {

    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}&limit=${filtros.limit}`;

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.categoria && filtros.categoria > 0) {
      query += `&categoria=${filtros.categoria}`;
    }

    if (filtros.categoriaNot && filtros.categoriaNot > 0) {
      query += `&categoriaNot=${filtros.categoriaNot}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    if (filtros.tipoContratoGrupoId && filtros.tipoContratoGrupoId > 0) {
      query += `&tipoContratoGrupoId=${filtros.tipoContratoGrupoId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ITipoContrato>>(`TipoContrato/RecuperaTipoContrato?${query}`
    );

    return result;
  }

  async adicionaTipoContrato(
    adicionaTipoContrato: AdicionaTipoContratoRequest
  ): Promise<ITipoContrato> {
    let { data: result } = await api.post<ITipoContrato>(
      `TipoContrato/AdicionaTipoContrato`,
      adicionaTipoContrato
    );

    return result;
  }

  async alterarStatus(tipoContratoId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ITipoContrato>(
      `TipoContrato/AtualizaTipoContratoPorId?id=${tipoContratoId}`,
      data
    );
  }

  async atualizarTipoContrato(
    editarTipoContrato: EditarTipoContratoRequest
  ): Promise<void> {
    let dataTipoContrato = [
      { op: "replace", path: "/nome", value: editarTipoContrato.nome },
      { op: "replace", path: "/codigo", value: editarTipoContrato.codigo },
      {
        op: "replace",
        path: "/categoria",
        value: editarTipoContrato.categoria,
      },
    ];
    await Promise.all([
      await api.patch<void>(
        `TipoContrato/AtualizaTipoContratoPorId?id=${editarTipoContrato.tipoContratoId}`,
        dataTipoContrato
      ),
    ]);
  }
}

export default new TipoContratoService();
