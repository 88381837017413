import React, {useEffect} from 'react';
import * as formik from 'formik';
import clsx from 'clsx';
import Select from 'react-select';
import { camelizar, getEnumByName } from './index';
import Swal from 'sweetalert2';
import MaskedInput from 'react-text-mask';
import CurrencyInput from 'react-currency-masked-input';
import CadastroCampoPessoa from './CadastroCampoPessoa';
import {  faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Overlay } from 'react-bootstrap';
import { ICampoDinamico } from '../../../../interfaces/ICampoDinamico';
import { ETipoCampoEnum } from '../../../../enum';
import { ICampo } from '../../../../interfaces/ICampo';
import { IConfiguracaoTela } from '../../../../interfaces/IConfiguracaoTela';
import LimitacaoTabelaService from '../../../../services/LimitacaoTabelaService';
import { MASCARA_CNPJ, MASCARA_CPF } from '../../../../utils/Constants';


export interface Modelo extends ICampo, ICampoDinamico { 
    camposExtras?: Modelo[];
    camposRetornar?: string[];
    multiplo?: boolean;
}

export interface CadastroCampoProps extends React.PropsWithRef<any> {
    modelo: Modelo;
    campo: formik.FieldInputProps<any>;
    touched: boolean | formik.FormikTouched<any> | formik.FormikTouched<any>[] | undefined;
    error: string | formik.FormikErrors<any> | string[] | formik.FormikErrors<any>[] | undefined;
    disabled?: boolean;
    tela: IConfiguracaoTela;
    setValue: (value: any) => void;
}


const CadastroCampo: React.FC<CadastroCampoProps> = (props)=>{
    const {modelo, campo, touched, error, tela, setValue } = props;

    const [loading, setLoading] = React.useState(true);
    const [opcoes, setOpcoes] = React.useState<any[]>([]);
    const [ajuda, setAjuda] = React.useState<boolean>(false);
    const ajudaRef = React.useRef<any>(null);
    const ref = React.useRef<any>();
    
    const getClassName = (extra = ""): string => {
        return clsx(
            `${props.className || ''} ${extra}`,
            {
                'is-invalid': touched && error,
            },
            {
            }
        )
    }

    async function getOpcoes(){
        let options: any[] = [];
        if(modelo.tipo !== ETipoCampoEnum.ListaMulti as ETipoCampoEnum &&
           modelo.tipo !== ETipoCampoEnum.Lista as ETipoCampoEnum &&
           modelo.tipo !== ETipoCampoEnum.EnumLista as ETipoCampoEnum
        ){
            setLoading(false);
            setOpcoes(options);
        } else if (modelo.tipo === ETipoCampoEnum.EnumLista as ETipoCampoEnum) {
            if(modelo.controller && modelo.controller.length > 0 && modelo.controller !== 'NULL'){
                options = getEnumByName(modelo.controller);
            } else {
                options = getEnumByName(modelo.campoNome);
            }
        }  else {
            let resultado:any[] = [];
            let chave = ''
            
            if(modelo.controller && modelo.controller.length){
                try{
                    chave = `${camelizar(modelo.controller as string)}Id`
                    try{
                        resultado = (await LimitacaoTabelaService.obterListaLimitadaEmpresa(tela, modelo.controller, true)).data;
                    } catch(e){
                        resultado = (await LimitacaoTabelaService.obterListaCompleta(modelo.controller)).data;
                    }
                } catch (error: any) {
                    Swal.fire({
                        heightAuto: false,
                        title: "Não foi possível obter registros",
                        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                        timer: 4000,
                        icon: "error",
                        showConfirmButton: false,
                    });
                }
            }
            
            options = resultado.map((item: any)=>{
                return {
                    label: item.nome,
                    value: item[chave]
                }
            });
        }

        setLoading(false);
        setOpcoes(options);
    }

    useEffect(()=>{
        getOpcoes();
    }, []);

    const handleSelectChange = function handleSelectChange(valor:any, meta:any){
        let selecionado = {};
        if(valor === undefined || valor === null){
            selecionado = "";
        } else if(modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || modelo.multiplo){
            selecionado = valor.map((item:any, index:number)=>{
                return {
                    [camelizar(modelo.campoNome)+'Id']: item.value,
                    ordem: index
                }
            });
        } else {
            selecionado = valor.value || {};
        }
        setValue(selecionado);
    }

    const getCampoTipo = () => {
        switch (modelo.tipo) {
            case ETipoCampoEnum.Lista as ETipoCampoEnum:
            case ETipoCampoEnum.ListaMulti as ETipoCampoEnum:
            case ETipoCampoEnum.EnumLista as ETipoCampoEnum:
                return <Select                    
                    ref={ref}
                    value={opcoes.find(x=>x.value == campo.value)}
                    name={campo.name}
                    placeholder={modelo.label}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    onBlur={campo.onBlur}
                    id={props.id}
                    onChange={handleSelectChange}
                    isOptionSelected={(option:any, value:any)=>{
                            if(!campo.value) return false;
                        if(modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || modelo.multiplo){
                            return campo.value.includes(option.value)
                        }
                        return option.value === campo.value;
                    }}
                    isLoading={loading}
                    loadingMessage={()=>'Carregando...'}
                    isMulti={modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || modelo.multiplo}
                    className={getClassName()}
                    options={opcoes}
                    />;
           
            case ETipoCampoEnum.Pessoa as ETipoCampoEnum:
                return <>
                    <CadastroCampoPessoa
                        modelo={modelo}
                        campo={campo}
                        touched={touched}
                        onBlur={campo.onBlur}
                        error={error}
                        pessoasInicial={campo.value}
                        setValue={(valor)=>{
                            if(modelo.camposRetornar && modelo.camposRetornar.length > 0){
                                if(modelo.multiplo){
                                    setValue(
                                        valor.map((v: any, i:number)=>{
                                            let r:any = {};
                                            modelo.camposRetornar?.forEach((c:string)=>{
                                                r[c]= v[c];
                                            })
                                            return r
                                        })
                                    )
                                } else {
                                    let r:any = {};
                                    modelo.camposRetornar?.forEach((c:string)=>{
                                        r[c]= valor[c];
                                    })
                                    setValue(r);
                                }
                            }
                            else if(modelo.camposExtras && modelo.camposExtras.length > 0){
                                setValue(valor)
                            } else {
                                if(modelo.multiplo){
                                    setValue(
                                        valor.map((v: any, i:number)=>{
                                            return {
                                                [camelizar(modelo.campoNome)+'Id']: v.pessoaId,
                                                ordem: i
                                            }
                                        })
                                    )
                                } else {
                                    setValue(valor.pessoaId);
                                }
                            }
                        }}
                        innerRef={ref}
                        tela={tela}
                    />
                </>;
            case ETipoCampoEnum.Documento as ETipoCampoEnum:
                return <MaskedInput
                    className={getClassName('form-control')}
                    name={campo.name}
                    onBlur={campo.onBlur}
                    value={campo.value || ''}
                    onChange={campo.onChange}
                    placeholderChar={' '}
                    mask={(value)=>(value.replace(/[^0-9]/g, '').length > 11)? MASCARA_CNPJ : [...MASCARA_CPF, /\d/]}
                />;
            case ETipoCampoEnum.AreaTexto as ETipoCampoEnum:
                return <textarea
                    ref={ref}
                    {...campo}
                    onBlur={campo.onBlur}
                    className={getClassName('form-control')}
                ></textarea>
            case ETipoCampoEnum.Percentual as ETipoCampoEnum:
                return <CurrencyInput
                    className={getClassName('form-control')}
                    name={campo.name}
                    onBlur={campo.onBlur}
                    value={(campo.value || 0.0)?.toFixed('2')}
                    onChange={
                        (event:Event, value:string)=>{
                            setValue(parseFloat(value.replace(',', '.')))
                        }
                    }
                    separator=','
                />;
            case ETipoCampoEnum.Valor as ETipoCampoEnum:
                return <CurrencyInput
                    className={getClassName('form-control')}
                    name={campo.name}
                    onBlur={campo.onBlur}
                    value={(campo.value || 0.0)?.toFixed('2')}
                    onChange={
                        (event:Event, value:string)=>{
                            setValue(parseFloat(value.replace(',', '.')))
                        }
                    }
                    separator=','
                />;
            case ETipoCampoEnum.Data as ETipoCampoEnum:
                return <input
                    ref={ref}
                    {...campo}
                    value={campo.value || new Date()}
                    onBlur={campo.onBlur}
                    className={getClassName('form-control')}
                    type="date"
                />;
            case ETipoCampoEnum.Inteiro as ETipoCampoEnum:
                return <input
                    ref={ref}
                    {...campo}
                    onBlur={campo.onBlur}
                    className={getClassName('form-control')}
                    type="number"
                />;
            case ETipoCampoEnum.Texto as ETipoCampoEnum:
                //return <Field type="text" {...campo} className={getClassName('form-control')} />
                 return <input type="text" {...campo} className={getClassName('form-control')} />
            default:
                return <input type="abc" {...campo} className={getClassName('form-control')} />
        }
    }

    return <>       
            <label
                htmlFor="form-nome"
                className={`form-label ${modelo.obrigatorio?'required ':''}fw-bolder text-orange`}
            >{modelo.label}
            
            
            {modelo.help && (
                <div ref={ajudaRef} style={{position: "relative", display: "inline-block", cursor: 'help'}}>
                    <FontAwesomeIcon  className='mx-2' style={{color: "var(--secondary-base)"}} icon={faInfoCircle} onClick={()=>{setAjuda(!ajuda)}} onMouseEnter={()=>setAjuda(true)} onMouseLeave={()=>setAjuda(false)}/>
                    <Overlay target={ajudaRef} show={ajuda} placement="right">
                        {({ placement, arrowProps, show: _show, popper, ...props }) => (
                        <div
                            {...props}
                            style={{
                            position: 'absolute',
                            background: 'var(--secondary-base)',
                            padding: '2px 10px',
                            color: 'white',
                            borderRadius: 3,
                            zIndex: 1,
                            ...props.style,
                            }}
                        >
                            {modelo.help}
                        </div>
                        )}
                    </Overlay>
                </div>
            )}
            </label>

            {getCampoTipo()}
            {touched && error && 
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert" className="text-danger">{error}</span>
                    </div>
                </div>
            }
        
    </>

}

export default CadastroCampo;