import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoInstrumento from "../interfaces/ITipoInstrumento";
import { AdicionaTipoInstrumentoRequest } from "../interfaces/Requests/TipoInstrumento/AdicionaTipoInstrumentoRequest";
import FiltroPesquisaTipoInstrumentoRequest from "../interfaces/Requests/TipoInstrumento/FiltroPesquisaTipoInstrumentoRequest";
import { EditarTipoInstrumentoRequest } from "../interfaces/Requests/TipoInstrumento/EditarTipoInstrumentoRequest";

class TipoInstrumentoService {

    async obterTipoInstrumentos(filtros: FiltroPesquisaTipoInstrumentoRequest): Promise<IRecuperaLista<ITipoInstrumento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoInstrumento>>(`TipoInstrumento/RecuperaTipoInstrumento?${query}`);

        return result;
    }

    async adicionaTipoInstrumento(adicionaTipoInstrumento: AdicionaTipoInstrumentoRequest): Promise<ITipoInstrumento> {
        let { data: result } = await api.post<ITipoInstrumento>(`TipoInstrumento/AdicionaTipoInstrumento`, adicionaTipoInstrumento);

        return result;
    }


    async alterarStatus(tipoInstrumentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoInstrumento>(`TipoInstrumento/AtualizaTipoInstrumentoPorId?id=${tipoInstrumentoId}`, data);
    }

    async atualizarTipoInstrumento(editarTipoInstrumento: EditarTipoInstrumentoRequest): Promise<void> {

        let dataTipoInstrumento = [
            { "op": "replace", "path": "/nome", "value": editarTipoInstrumento.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoInstrumento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoInstrumento/AtualizaTipoInstrumentoPorId?id=${editarTipoInstrumento.tipoInstrumentoId}`, dataTipoInstrumento)
        ]);
    }
}


export default new TipoInstrumentoService();