import { useContext, useEffect, useState } from "react";
import { faEraser, faFolderPlus, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Collapse, OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";

import IGarantia from "../../interfaces/IGarantia";
import ITipoGarantia from "../../interfaces/ITipoGarantia";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IStatusGarantia from "../../interfaces/IStatusGarantia";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaGarantiaRequest from "../../interfaces/Requests/Garantia/FiltroPesquisaGarantiaRequest";

import GarantiaService from "../../services/GarantiaService";
import TipoGarantiaService from "../../services/TipoGarantiaService";
import StatusGarantiaService from "../../services/StatusGarantiaService";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import LevantamentoModal from "./GarantiaLevantamentoModal";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { EModulo } from "../../enum";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const GarantiaPage = () => {
  const [carregandoGarantias, setCarregandoGarantias] = useState(false);
  const [exibirLevantamentoModal, setExibirrLevantamentoModal] = useState<boolean>(false);

  const [garantias, setGarantias] = useState<IGarantia[]>([]);

  const { setGarantia, garantia } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [atualizarTabela, setAtualizaTabelaAndamento] = useState<boolean>(false);

  const [status, setStatus] = useState<any>([]);
  const [statusSelecionados, setStatusSelecionados] = useState([]);
  const [carregandoStatus, setCarregandoStatus] = useState<boolean>(false);

  const [tipoGarantia, setTipoGarantia] = useState<any>([]);
  const [tipoGarantiaSelecionados, setTipoGarantiaSelecionados] = useState([]);
  const [carregandoTipoGarantia, setCarregandoTipoGarantia] = useState<boolean>(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaGarantiaRequest>({
    garantiaId: 0,
    modulo: garantia.modulo,
    processoId: garantia.processoId,
    imovelId: garantia.imovelId,
    tipoGarantiaId: [],
    descricao: "",
    dataInicioVigenciaMaiorIgual: "",
    dataInicioVigenciaMenorIgual: "",
    dataVencimentoMaiorIgual: "",
    dataVencimentoMenorIgual: "",
    dataFatalMaiorIgual: "",
    dataFatalMenorIgual: "",
    dataGarantiaMaiorIgual: "",
    dataGarantiaMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    formaPagamentoId: 0,
    codigoBarras: "",
    bancoId: 0,
    agencia: "",
    conta: "",
    moedaId: 0,
    valorPrincipalAtualMaiorIgual: "",
    valorPrincipalAtualMenorIgual: "",
    indiceId: 0,
    juros: 0,
    numeroApolice: "",
    matriculaImovel: "",
    cartorio: "",
    corretora: "",
    codigo: "",
    statusGarantiaId: [],
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "garantiaId",
  });

  const navigate = useNavigate();

  const toggleLevantamentoModal = () => setExibirrLevantamentoModal(!exibirLevantamentoModal);

  useEffect(() => {

    carregarGarantias(filtrosPesquisa);

    carregarStatus();

    carregarTipoGarantia();

  }, []);

  useEffect(() => {
    if (atualizarTabela) setTimeout(() => carregarGarantias(filtrosPesquisa), 1000);
  }, [atualizarTabela]);


  const carregarStatus = async () => {
    try {
      if (status.length > 0) return;
      setCarregandoStatus(true);
      let resultado: IRecuperaLista<IStatusGarantia>;
      resultado = await StatusGarantiaService.obterStatusGarantias({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setStatus(returnOptions(resultado.data, "statusGarantiaId", "status"));
      setCarregandoStatus(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregandoStatus(false);
      setStatus([]);
    }
  }

  const carregarTipoGarantia = async () => {
    try {
      if (tipoGarantia.length > 0) return;
      setCarregandoTipoGarantia(true);
      let resultado: IRecuperaLista<ITipoGarantia>;
      resultado = await TipoGarantiaService.obterTipoGarantias({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTipoGarantia(returnOptions(resultado.data, "tipoGarantiaId", "tipoGarantia"));
      setCarregandoTipoGarantia(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregandoTipoGarantia(false);
      setTipoGarantia([]);
    }
  }

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });
    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {
      case "statusGarantiaId":
        setFiltrosPesquisa({ ...filtrosPesquisa, statusGarantiaId: IdsSelected });
        break;

      case "tipoGarantiaId":
        setFiltrosPesquisa({ ...filtrosPesquisa, tipoGarantiaId: IdsSelected });
        break;
    }
  };

  const carregarGarantias = async (filtro: FiltroPesquisaGarantiaRequest): Promise<void> => {
    try {
      setCarregandoGarantias(true);
      let resultado: IRecuperaLista<IGarantia>;

      resultado = await GarantiaService.obterGarantias(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setGarantias(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setGarantias([]);
    } finally {
      setCarregandoGarantias(false);
    }
  }

  const colunas: TableColumn<IGarantia>[] = [
    {
      name: "Id",
      sortField: "GarantiaId",
      selector: (row: IGarantia) => row.garantiaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IGarantia) => row.statusGarantiaNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data da Garantia",
      sortField: "dataGarantia",
      selector: (row: IGarantia) => {
        if (row.dataGarantia === null) return "-";
        const data = row.dataGarantia.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo de Garantia",
      sortField: "tipoGarantiaNome",
      selector: (row: IGarantia) => row.tipoGarantiaNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Num. Apólice",
      sortField: "numeroApolice",
      selector: (row: IGarantia) => row.numeroApolice,
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Principal",
      sortField: "valorPrincipal",
      selector: (row: IGarantia) => {
        if (row.valorPrincipal === null) return "R$ 0,00";

        const valor = row.valorPrincipal || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Atualizado",
      sortField: "valorAtualizado",
      selector: (row: IGarantia) => {
        if (row.valorAtualizado === null) return "R$ 0,00";

        const valor = row.valorAtualizado || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Recuperado (Empresa)",
      sortField: "valorLevantadoEmpresaAtualizado",
      selector: (row: IGarantia) => {
        if (row.valorLevantadoEmpresaAtualizado === null) return "R$ 0,00";

        const valor = row.valorLevantadoEmpresaAtualizado || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Pago",
      sortField: "valorLevantadoParteContrariaAtualizado",
      selector: (row: IGarantia) => {
        if (row.valorLevantadoParteContrariaAtualizado === null) return "R$ 0,00";
        const valor = row.valorLevantadoParteContrariaAtualizado || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Saldo",
      sortField: "saldo",
      selector: (row: IGarantia) => {
        if (row.saldoAtualizado === null) return "R$ 0,00";
        const valor = row.saldoAtualizado || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: '',
      cell: (row: IGarantia) => {
        return (
          <div>
            <i className="bi bi-file-diff"></i>
            <FontAwesomeIcon title="Levantamento" onClick={() => {
               setGarantia({
                ...row,
                garantiaId: row.garantiaId,
                tipoGarantiaId: row.tipoGarantiaId,
                categoriaId: row.tipoGarantiaId,
          
                modulo: garantia.modulo,
          
                processoId: garantia?.processoId,
                areaDireitoId: garantia.areaDireitoId,
          
                tipoImovelId: garantia.tipoImovelId,
                imovelId: garantia?.imovelId
              });
              toggleLevantamentoModal();
            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faFolderPlus} />


          </div>
        )
      },
      ignoreRowClick: true,
    },
    {
      name: "",
      cell: (proposta: IGarantia) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Editar Garantia"
              onClick={() => abrirCapa(proposta)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },

  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IGarantia>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const abrirCapa = (row: IGarantia) => {
    setGarantia({
      ...row,
      garantiaId: row.garantiaId,
      tipoGarantiaId: row.tipoGarantiaId,
      categoriaId: row.tipoGarantiaId,

      modulo: garantia.modulo,

      processoId: garantia?.processoId,
      areaDireitoId: garantia.areaDireitoId,

      tipoImovelId: garantia.tipoImovelId,
      imovelId: garantia?.imovelId
    });
    navigate("/Garantia/Capa");
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        garantiaId: 0,
        modulo: garantia.modulo,
        processoId: garantia.processoId,
        imovelId: garantia.imovelId,
        tipoGarantiaId: [],
        descricao: "",
        dataInicioVigenciaMaiorIgual: "",
        dataInicioVigenciaMenorIgual: "",
        dataVencimentoMaiorIgual: "",
        dataVencimentoMenorIgual: "",
        dataFatalMaiorIgual: "",
        dataFatalMenorIgual: "",
        dataGarantiaMaiorIgual: "",
        dataGarantiaMenorIgual: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        formaPagamentoId: 0,
        codigoBarras: "",
        bancoId: 0,
        agencia: "",
        conta: "",
        moedaId: 0,
        valorPrincipalAtualMaiorIgual: "",
        valorPrincipalAtualMenorIgual: "",
        indiceId: 0,
        juros: 0,
        numeroApolice: "",
        matriculaImovel: "",
        cartorio: "",
        corretora: "",
        codigo: "",
        statusGarantiaId: [],
      };
    });
    setStatusSelecionados([]);
    setTipoGarantiaSelecionados([]);
  };

  return (
    <>
      <LayoutPageForButton title="Garantia">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={
          garantia.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setGarantia({ ...garantia, garantiaId: 0 });
            navigate("/Garantia/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>


      <LevantamentoModal
        exibirModal={exibirLevantamentoModal}
        toggleModal={toggleLevantamentoModal}
        setAtualizarTabela={setAtualizaTabelaAndamento}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          
          <CollapseFiltro titulo="Filtros" content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarGarantias(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-garantia"
          >
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data de Cadastro:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: "",
                        dataCadastroMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-dataGarantia" className="form-label fw-bolder text-orange">
                Data da Garantia:{" "}
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataGarantiaMaiorIgual && filtrosPesquisa.dataGarantiaMenorIgual
                    ? [filtrosPesquisa.dataGarantiaMaiorIgual, filtrosPesquisa.dataGarantiaMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataGarantiaMaiorIgual: "",
                        dataGarantiaMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataGarantiaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataGarantiaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-dataInicioVigencia" className="form-label fw-bolder text-orange">
                Data de Início da Vigência:{" "}
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataInicioVigenciaMaiorIgual && filtrosPesquisa.dataInicioVigenciaMenorIgual
                    ? [filtrosPesquisa.dataInicioVigenciaMaiorIgual, filtrosPesquisa.dataInicioVigenciaMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataInicioVigenciaMaiorIgual: "",
                        dataInicioVigenciaMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataInicioVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataInicioVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-dataVencimento" className="form-label fw-bolder text-orange">
                Data de Vencimento:{" "}
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataVencimentoMaiorIgual && filtrosPesquisa.dataVencimentoMenorIgual
                    ? [filtrosPesquisa.dataVencimentoMaiorIgual, filtrosPesquisa.dataVencimentoMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataVencimentoMaiorIgual: "",
                        dataVencimentoMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataVencimentoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataVencimentoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                isLoading={carregandoStatus}
                disabled={carregandoStatus}
                options={status}
                value={statusSelecionados}
                onChange={(event: any) => {
                  setStatusSelecionados(event);
                  setIdsSelect("statusGarantiaId", event);
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)",
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-tipoGarantia" className="form-label fw-bolder text-orange">
                Tipo de Garantia:
              </label>
              <MultiSelect
                isLoading={carregandoTipoGarantia}
                disabled={carregandoTipoGarantia}
                options={tipoGarantia}
                value={tipoGarantiaSelecionados}
                onChange={(event: any) => {
                  setTipoGarantiaSelecionados(event);
                  setIdsSelect("tipoGarantiaId", event);
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)",
                }}
              />
            </div>

            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
            </div>
          </form>}/>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoGarantias}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Garantias"
            itens={garantias}
          />
        </div>
      </div>
    </>
  );
};

export default GarantiaPage;
