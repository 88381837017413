import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faFileExcel, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";

import ImovelService from "../../services/ImovelService";
import TipoImovelService from "../../services/TipoImovelService";
import StatusImovelService from "../../services/StatusImovelService";

import ITipoImovel from "../../interfaces/ITipoImovel";
import IStatusImovel from "../../interfaces/IStatusImovel";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";
import ImovelFiltroModal from "./ImovelFiltroModal";
import GridPadrao from "../../components/Comum/GridPadrao";
import "./style/icons.css";
import ImovelRelatorioModal from "./ImovelRelatorioModal";
import IModeloRelatorio from "../../interfaces/IModeloRelatorio";
import ConfiguracaoModeloRelatorioService from "../../services/ConfiguracaoModeloRelatorioService";
import { EModulo } from "../../enum";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const filtroInicial: any = {
  imovelId: 0,
  clienteId: 0,

  totalItems: 0,
  offset: 0,
  limit: 10,
  sort: "imovelId",

  tipoImovelid: [],
  statusImovelId: [],
  cidadeId: [],
  departamentoId: [],
  indiceId: [],
  tipoAquisicaoId: [],
  tipoLocalidadeId: [],
  empresaId: [],
  imovelPrincipal: [],
  tipoRelacionamentoId: [],

  restrito: false,

  observacoesImovel: "",
  nomeImovel: "",
  logradouro: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  cep: "",
  dadosMatricula: "",
  areaConstruida: "",
  metragemTerreno: "",
  parteContraria: "",

  dataCadastroMaiorIgual: "",
  dataCadastroMenorIgual: "",

  dataInicioVigenciaMaiorIgual: "",
  dataInicioVigenciaMenorIgual: "",

  dataFimVigenciaMaiorIgual: "",
  dataFimVigenciaMenorIgual: "",

  dataAquisicaoMaiorIgual: "",
  dataAquisicaoMenorIgual: "",

  dataReajusteMaiorIgual: "",
  dataReajusteMenorIgual: "",

  valorAluguelMaiorIgual: 0,
  valorAluguelMenorIgual: 0,

  valorCondominioMaiorIgual: 0,
  valorCondominioMenorIgual: 0,

  valorIPTUMaiorIgual: 0,
  valorIPTUMenorIgual: 0,

};


const ImovelPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<any>(filtroInicial);

  const [carregamentoTipoImovel, setCarregamentoTipoImovel] = useState<boolean>(false);
  const [tiposImovelOptions, setTiposImovelOptions] = useState<any>([]);
  const [tipoImovelSelected, setTipoImovelSelected] = useState([]);

  const [carregamentoStatusImovel, setCarregamentoStatusImovel] = useState<boolean>(false);
  const [StatusImovelOptions, setStatusImovelOptions] = useState<any>([]);
  const [StatusImovelSelected, setStatusImovelSelected] = useState([]);

  const [, setModelos] = useState<IModeloRelatorio[]>([]);
  const [, setCarregandoModelo] = useState(false);
  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);

  const { setImovel, setTipoImovel } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [imoveis, setImovels] = useState<IImovel[]>([]);
  const [carregandoImoveis, setCarregandoImovels] = useState(false);

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useLayoutEffect(() => {
    carregarTipoImovel();
    carregarStatusImovel();
    carregarModelos();
  }, []);

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  useEffect(() => {
    if (pesquisar) carregarImoveis(filtrosPesquisa);
  }, [pesquisar]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoImovelSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoImovelId: IdsSelected };
    });
  }, [tipoImovelSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusImovelSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusImovelId: IdsSelected };
    });
  }, [StatusImovelSelected]);

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

  const returnOptionsStatusImovel = (array: any) => {
    array.forEach((item: any) => {
      StatusImovelOptions.push({
        value: item.statusImovelId,
        label: item.nome,
      });
    });
    return setStatusImovelOptions;
  };

  const returnOptionsTiposImovel = (array: any) => {
    array.forEach((item: any) => {
      tiposImovelOptions.push({
        value: item.tipoImovelId,
        label: item.nome,
      });
    });
    return setTiposImovelOptions;
  };

  const carregarTipoImovel = async (): Promise<void> => {
    try {
      setCarregamentoTipoImovel(true);
      let resultadoTipoImovel: IRecuperaLista<ITipoImovel>;
      resultadoTipoImovel = await TipoImovelService.obterTipoImoveis({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoImovelId",
      });
      setTiposImovelOptions(returnOptionsTiposImovel(resultadoTipoImovel.data));
      setCarregamentoTipoImovel(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoImovel(false);
      setTiposImovelOptions([]);
    }
  }

  const carregarStatusImovel = async (): Promise<void> => {
    try {
      setCarregamentoStatusImovel(true);
      let resultado: IRecuperaLista<IStatusImovel>;
      resultado = await StatusImovelService.obterStatusImoveis({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusImovelId",
      });
      setStatusImovelOptions(returnOptionsStatusImovel(resultado.data));
      setCarregamentoStatusImovel(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoStatusImovel(false);
      setStatusImovelOptions([]);
    }
  }

  const carregarModelos = async () => {
    try {
      setCarregandoModelo(true);
      let resultado: IRecuperaLista<any>;
      resultado = await ConfiguracaoModeloRelatorioService.obterModeloRelatorio({
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        modulo: EModulo.Imovel,
        sort: "modeloRelatorioIdId",
        modeloRelatorioId: "",
      });

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setModelos(resultado.data);
    } catch (error: any) {
      setModelos([]);
    } finally {
      setCarregandoModelo(false);
      setLoadingInicial(false);
    }
  }

  const colunas: TableColumn<IImovel>[] = [
    {
      cell: (imovel: IImovel) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setImovel(imovel);
                  setTipoImovel({
                    tipoImovelId: imovel.tipoImovelId,
                    nome: imovel.tipoImovelNome,
                    codigo: "",
                    status: imovel.statusImovelNome
                  });
                  navigate("/Imovel/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Imóvel: #{imovel.imovelId.toString().padStart(5, "0")} - {imovel.nomeImovel}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{imovel.statusImovelNome}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {imovel.imovelId}
                {imovel.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(imovel.favoritoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(imovel.imovelId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Imóvel: </b> {imovel.tipoImovelNome}{" "}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Empresa: </b> {imovel.imovelEmpresas.map(empresa => `${empresa.nome} / `)}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Partes: </b> {imovel.imovelParteContraria.map(parte => `${parte.nome} / `)}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const adicionarFavorito = async (imovelId: number): Promise<void> => {
    try {
      await ImovelService.adicionaFavorido(imovelId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Contrato adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarImoveis(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar imóvel como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const removerFavorido = async (favoritoId: number): Promise<void> => {
    try {
      await ImovelService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Contrato removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarImoveis(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IImovel>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const carregarImoveis = async (filtro: any) => {
    try {
      setCarregandoImovels(true);

      let resultado: IRecuperaLista<IImovel>;
      resultado = await ImovelService.obterImovels(filtro);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setImovels(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoImovels(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  return (
    <>
      <LayoutPageTitle title="Imobiliário">
        <button onClick={() => navigate("/Imovel/Novo")} className="btn btn-md btn-orange">
          Novo <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <ImovelFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <ImovelRelatorioModal
        exibirModal={exibirConfirmarModeloModal}
        toggleModal={toggleConfimarModeloModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
      />

      <div className="row">
        <div className="col-md-12" >

          < CollapseFiltro titulo="Filtros" content={
            <form onSubmit={(e) => e.preventDefault()}
              className="row g-3 mb-3 form">
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-tipoImovelId" className="form-label fw-bolder text-orange">
                  Identificador:
                </label>
                <input
                  value={filtrosPesquisa.imovelId}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, imovelId: e.target.value };
                    });
                  }}
                  placeholder="ID"
                  type="number"
                  className={"form-control"}
                  id="form-tipoImovelId"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-numero" className="form-label fw-bolder text-orange">
                  Nome do Imóvel:
                </label>
                <input
                  value={filtrosPesquisa.nomeImovel}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, nomeImovel: e.target.value };
                    });
                  }}
                  placeholder="Nome do Imóvel"
                  type="text"
                  className={"form-control"}
                  id="form-numero"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Tipo de Imovel:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoImovel}
                  disabled={carregamentoTipoImovel}
                  options={tiposImovelOptions}
                  value={tipoImovelSelected}
                  onChange={(event: any) => setTipoImovelSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">

                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={carregamentoStatusImovel}
                  disabled={carregamentoStatusImovel}
                  options={StatusImovelOptions}
                  value={StatusImovelSelected}
                  onChange={(event: any) => setStatusImovelSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-matricula" className="form-label fw-bolder text-orange">
                  Matrícula:
                </label>
                <input
                  value={filtrosPesquisa.dadosMatricula}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, dadosMatricula: e.target.value };
                    });
                  }}
                  placeholder="Matrícula"
                  type="text"
                  className={"form-control"}
                  id="form-dadosMatricula"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                  Partes:
                </label>
                <input
                  value={filtrosPesquisa.parteContraria}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, parteContraria: e.target.value };
                    });
                  }}
                  placeholder="Parte"
                  type="text"
                  className={"form-control"}
                  id="form-parteContraria"
                />
              </div>

              <div className="col-12"
                style={{ textAlign: 'right' }}>
                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    setFiltrosPesquisa(filtroInicial);
                    setPesquisar(false);
                    setTipoImovelSelected([]);
                    setStatusImovelSelected([]);
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <button
                  onClick={() => toggleConfimarModeloModal()}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  disabled={carregandoImoveis}>
                  <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                </button>

                <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

              </div>
            </form>} />

          <div className="col-md-12 mb-10" style={{ overflow: !carregandoImoveis ? "auto" : "scroll", height: "600px" }}>

            <div ref={componentRef}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoImoveis}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Imóveis"
                itens={imoveis}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImovelPage;

