import React from "react";
import { CustomEditor } from "../../../utils/types/slate";
import { EditorEvent } from "../components/DocumentEditor/events";

export function useEvent(editor: CustomEditor) {
  function keydown(event: React.KeyboardEvent) {
    EditorEvent.keydown({ event, editor })
  }

  return { keydown }
}