import { IWidgetDado } from "../widgets";


export interface IEstatisticaDado{
    metodo: string;
    args?: any[];
    callback?: (resultado: any) => any[];
}


export default class Estatisticas<T>{
    
    protected dados : T[];
    onAtualizando?: () => void;
    onAtualizado?: () => void;
    onFalhaAoAtualizar?: (e: any) => void;


    constructor(dados: T[] = []){
        this.dados = dados;
        this.atualiza = this.atualiza.bind(this);
        this.recuperarDado = this.recuperarDado.bind(this);
    }

    async atualiza(callback: ()=>Promise<T[]>): Promise<void> {
        try{
            this.onAtualizando && this.onAtualizando();
            const dados = await callback();
            this.dados = dados;
            this.onAtualizado && this.onAtualizado();
        }catch(e: any){
            console.log(e.message)
            this.onFalhaAoAtualizar && this.onFalhaAoAtualizar(e);
        }
    }

    recuperarDado<D>(id: string, args: any[] = []): D{
        const methodo = Object.getPrototypeOf(this)[id]
        if(!methodo) throw new Error(`Método ${id} não encontrado na classe ${this.constructor.name}`);
        const response =  methodo.bind(this).apply(this, ...args) as D;
        return response;
    }
}