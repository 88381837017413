import clsx from "clsx";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { useContext, useEffect, useState } from "react";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EModulo } from "../../../enum";

import ProcessoService from "../../../services/ProcessoService";
import EncerramentoService from "../../../services/EncerramentoService";
import MotivoEncerramentoService from "../../../services/MotivoEncerramentoService";

import IProcesso from "../../../interfaces/IProcesso";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IMotivoEncerramento from "../../../interfaces/IMotivoEncerramento";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaEncerramentoRequest } from "../../../interfaces/Requests/Encerramento/AdicionaEncerramentoRequest";
import FiltroPesquisaMotivoEncerramentoRequest from "../../../interfaces/Requests/MotivoEncerramento/FiltroPesquisaMotivoEncerramentoRequest";

import { OrangeContext } from "../../../contexts/OrangeProvider";
import AreaTextoField from "../AreaTexto/AreaTextoField";
import AreaTextoEdit from "../AreaTexto/AreaTextoEdit";
import AreaTextoDefault from "../AreaTexto/AreaTextoDefault";

interface IEncerramentoProps {
    toggleModal: () => void;
    exibirModal: boolean,
    setRefreshCapa(atualizaAgora: boolean): void;
}

const EncerramentoModal = ({ toggleModal, exibirModal, setRefreshCapa }: IEncerramentoProps) => {

    const { processo, setProcesso } = useContext<OrangeInterfaceContext>(OrangeContext);
    const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

    const [motivoEncerramento, setMotivoEncerramento] = useState<IMotivoEncerramento[]>([]);
    const [carregandoMotivoEncerramento, setCarregandoMotivoEncerramento] = useState(false);
    const [filtrosPesquisa] = useState<FiltroPesquisaMotivoEncerramentoRequest>({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        areaDireitoId: processo.areaDireitoId,
        modulo: EModulo.AreaDireito,
        sort: "motivoEncerramentoId",
    });

    const initialValues: AdicionaEncerramentoRequest = {
        processoId: processo.processoId,
        motivoEncerramentoId: 0,
        dataEncerramento: null,
        observacoesEncerramento: ""
    };


    const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);


    const schema = () => {
        let schema: any = {};
        schema.dataEncerramento = Yup.date()
            .transform((value) => {
                return value ? moment(value).toDate() : value;
            })
            .required("Campo data de Encerramento é obrigatório");

        schema.motivoEncerramentoId = Yup.number().min(1).required("Campo Motivo Encerramento é obrigatório");

        return Yup.object().shape(schema);
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                toggleModal();

                if (processo.processoId) {

                    const swalWithBootstrapButtons = Swal.mixin({
                        heightAuto: false,
                        customClass: {
                            confirmButton: "btn btn-orange",
                            cancelButton: "btn btn-danger ms-5",
                        },
                        buttonsStyling: false,
                    });

                    let result = await swalWithBootstrapButtons.fire({
                        title: "Encerramento do Processo",
                        text: `Você realmente deseja encerrar?`,
                        showCancelButton: true,
                        cancelButtonText: "Fechar",
                        confirmButtonText: `Encerrar`,
                    });

                    if (result.isConfirmed) {

                        await EncerramentoService.EncerraProcesso(values);

                        let resultado: IRecuperaLista<IProcesso>;

                        resultado = await ProcessoService.obterProcessoPorId(processo.processoId);

                        setProcesso(resultado.data[0]);

                        formik.resetForm();

                        setSubmitting(false);

                        setRefreshCapa(true);

                        await Swal.fire({
                            heightAuto: false,
                            icon: 'success',
                            text: `Processo encerrado com sucesso`,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                }

            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possível encerrar o processo`,
                    text: error?.response?.data?.Message,
                    showConfirmButton: true
                });
            }
        }
    });


    useEffect(() => {

        formik.setValues(initialValues);

    }, [processo.processoId]);

    const cancelar = async () => {
        formik.resetForm();
        formik.setValues(initialValues);
    }

    const carregarMotivoEncerramento = async (filtro: FiltroPesquisaMotivoEncerramentoRequest) => {

        let resultado: IRecuperaLista<IMotivoEncerramento>;

        try {
            setCarregandoMotivoEncerramento(true);

            resultado = await MotivoEncerramentoService.obterMotivoEncerramentosLimitado(filtro);
            setMotivoEncerramento(resultado.data);
        } catch (error: any) {
            resultado = await MotivoEncerramentoService.obterMotivoEncerramentos(filtro);
            setMotivoEncerramento(resultado.data);
            setMotivoEncerramento([]);
        } finally {
            setCarregandoMotivoEncerramento(false);
        }
    }

    useEffect(() => {
        if (exibirModal)
            carregarMotivoEncerramento(filtrosPesquisa);
    }, [exibirModal]);

    return (


        <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>

            <AreaTextoDefault
                toggleModal={toggleAreaTextoModal}
                exibirModal={exibirModalAreaTexto}
                field="observacoesEncerramento"
                formik={formik}
            />

            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">
                        Encerrar Processo
                    </h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.submitForm();
                        }}
                    >
                        <div className="row mt-1">
                            <div className="col-md-6 mt-3">

                                {carregandoMotivoEncerramento ? (
                                    <>Carregando Motivos de Encerramento...</>
                                ) : (
                                    <><label htmlFor="form-cliente" className="form-label required fw-bolder text-orange">
                                        Motivo de Encerramento:
                                    </label><select
                                        {...formik.getFieldProps("motivoEncerramentoId")}
                                        value={formik.values.motivoEncerramentoId}
                                        onChange={(e) => {
                                            formik.setFieldTouched("motivoEncerramentoId", true);
                                            formik.setFieldValue("motivoEncerramentoId", parseInt(e.target.value));
                                        }}
                                        className={clsx(
                                            "form-select",
                                            {
                                                "is-invalid": formik.touched.motivoEncerramentoId && formik.errors.motivoEncerramentoId,
                                            },
                                            {
                                                "is-valid": formik.touched.motivoEncerramentoId && !formik.errors.motivoEncerramentoId,
                                            }
                                        )}
                                        id="form-client"
                                    >
                                            <option value="0"> Selecione uma opção</option>
                                            {motivoEncerramento.map((map) => {
                                                return (
                                                    <option key={map.motivoEncerramentoId} value={map.motivoEncerramentoId}>
                                                        {" "}
                                                        {map.nome}{" "}
                                                    </option>
                                                );
                                            })}
                                        </select></>
                                )}
                            </div>

                            <div className="col-md-6 mt-3">
                                <label htmlFor="form-areas" className="form-label fw-bolder required text-orange">
                                    Data de Encerramento:
                                </label>

                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={clsx(
                                        "form-select",
                                        {
                                            "is-invalid": formik.touched.dataEncerramento && formik.errors.dataEncerramento,
                                        },
                                        {
                                            "is-valid": formik.touched.dataEncerramento && !formik.errors.dataEncerramento,
                                        }
                                    )}
                                    id="form-dataEncerramento"
                                    selected={formik.values.dataEncerramento}
                                    onChange={(date: Date) => formik.setFieldValue("dataEncerramento", date)}
                                />

                                {formik.touched.dataEncerramento && formik.errors.dataEncerramento && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span style={{ fontSize: "11px" }} role="alert">
                                                {formik.errors.dataEncerramento}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-12 mt-3">
                                <label htmlFor="form-observacoesEncerramento" className="form-label fw-bolder text-orange">
                                    Descrição:
                                </label>
                                <div className="input-group">
                                    <textarea
                                        {...formik.getFieldProps("observacoesEncerramento")}
                                        maxLength={500}
                                        placeholder="Descrição do encerramento"
                                        className={clsx(
                                            "form-control",
                                            {
                                                "is-invalid": formik.touched.observacoesEncerramento && formik.errors.observacoesEncerramento,
                                            },
                                            {
                                                "is-valid": formik.touched.observacoesEncerramento && !formik.errors.observacoesEncerramento,
                                            }
                                        )}
                                        id="form-observacoesEncerramento"
                                    />
                                    <Button
                                        onClick={() => toggleAreaTextoModal()} // Função para abrir modal de edição de texto
                                        style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
                                    </Button>
                                </div>

                                {formik.touched.observacoesEncerramento && formik.errors.observacoesEncerramento && (
                                    <div className="fv-plugins-message-container mt-1">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.observacoesEncerramento}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: "0 auto" }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => formik.submitForm()} type="button" className="btn btn-orange ms-5">
                        Adicionar
                    </button>

                    <button
                        onClick={() => {
                            cancelar();
                            toggleModal();
                        }}
                        type="button"
                        className="btn btn-danger ms-5"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EncerramentoModal;