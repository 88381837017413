import { faEraser, faFileExcel, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import "./style/icons.css";
import { EModulo, ETelaEnum } from "../../enum";
import { setValuesXPesquisaCampos } from "../../utils/SchemasUtil";

import GridPadrao from "../../components/Comum/GridPadrao";

import ContratoService from "../../services/ContratoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IContrato, { IContratoPartesContraria } from "../../interfaces/IContrato";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";

import Carregando from "../../components/Carregando";
import RelatorioModal from "./Modal/RelatorioModal";
import FiltroModal from "../../components/Comum/Pesquisa/FiltroModal";
import { contratoInitialValues } from "../../contexts/InitialValuesContext";
import CamposPesquisaDinamicos from "../../components/Comum/Pesquisa/CamposPesquisaDinamicos";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const ContratoPage = () => {
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [configuracoesModalTela, setConfiguracoesModalTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [limpar, setLimpar] = useState(true);
  const [pesquisar, setPesquisar] = useState(false);
  const [contratos, setContratos] = useState<IContrato[]>([]);
  const [carregandoContratos, setCarregandoContratos] = useState(false);
  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);
  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [paginacao, setPaginacao] = useState<any>({
    offset: 0,
    limit: 10,
    sort: "contratoId",
    totalItems: 0,
  });

  const { setContrato, setTipoContrato } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();
  const componentRef = useRef<any>();

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);
  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

  useEffect(() => {
    if (limpar) {
      formik.resetForm();
      carregarConfiguracaoTela();
    }
  }, [limpar]);

  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);
      const resultado: IRecuperaLista<IRecuperaConfiguracaoTelaResponse> = await ConfiguracaoTelaService.obterPesquisa(EModulo.Contrato, ETelaEnum.TelaPesquisaPrincipal);
      setConfiguracoesTela(resultado.data);
    } catch (error: any) {
      navigate("/Contrato/Padrao");
    } finally {
      setCarregandoConfiguracoesTela(false);
      setLimpar(false);
    }
  };

  useEffect(() => {
    if (pesquisar) {
      formik.handleSubmit();
    }
  }, [pesquisar]);

  const montarParteContraria = (partes: IContratoPartesContraria[]) => partes
    .filter(parte => parte.principal)
    .map(parte => `${parte.nome} - ${parte.papelSignatarioNome}`)
    .join(", ");

  const formatarData = (data: string): string => data.includes('T') ? data.split('T')[0].split('-').reverse().join('/') : '-';

  const nomearStatusContrato = (status: string): string => {
    const statusMap: { [key: string]: string } = {
      "EmElaboracao": "Em Elaboração",
      "EmAprovacao": "Em Aprovação",
      "EmAssinatura": "Em Assinatura",
      "Assinado": "Assinado",
      "Cancelado": "Cancelado",
    };
    return statusMap[status] || 'Não definido';
  };

  const colunas: TableColumn<IContrato>[] = [
    {
      cell: (contrato: IContrato) => (
        <a style={{ cursor: "pointer" }}>
          <p
            onClick={() => {
              setContrato(contrato);
              setTipoContrato({
                tipoContratoId: contrato.tipoContratoId,
                nome: contrato.tipoContratoNome,
                categoria: 0,
                checked: false,
                codigo: "",
                status: "",
              });
              navigate("/Contrato/Capa");
            }}
            style={{
              overflow: "hidden",
              textDecoration: "underline",
              fontFamily: "arial,sans-serif",
              fontSize: "18px",
              color: "#0000FF",
              paddingTop: "30px",
            }}
          >
            Contrato: {contrato.numeroContrato} - {montarParteContraria(contrato.contratoParteContraria)}
          </p>
          <p>
            {" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
            <a style={{ fontSize: "13px", textDecoration: "underline" }}>{nomearStatusContrato(contrato.statusContrato)}</a>
            {"  "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {contrato.contratoId}
            {contrato.favoritoId ? (
              <a
                onClick={() => removerFavorito(contrato.favoritoId)}
                style={{ margin: "-28px -11px 4px 200px" }}
                className="navigation-service-icon navigation-service-icon-startShow"
              ></a>
            ) : (
              <a
                onClick={() => adicionarFavorito(contrato.contratoId)}
                style={{ margin: "-28px -11px 4px 200px" }}
                className="navigation-service-icon navigation-service-icon-start"
              ></a>
            )}
          </p>
          <p>
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Código: </b> {contrato.codigo}{" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Nome: </b> {contrato.pastaNome}{" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Grupo Econômico: </b> {contrato.observacoesContrato}{" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>UF: </b> {contrato.uf}
          </p>
          <p>
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Contrato: </b> {contrato.tipoContratoNome}{" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Vigência: </b>
            {formatarData(contrato?.dataInicioVigencia ?? "")} até {formatarData(contrato?.dataFimVigencia ?? "")}
          </p>
          <p>
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status da Pasta: </b> {nomearStatusContrato(contrato.statusPasta ?? "")}{" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Área Solicitante: </b> {contrato.departamentoNome}
          </p>
        </a>
      ),
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = (currentRowsPerPage: number) => {
    setPaginacao((oldState: any) => ({ ...oldState, limit: currentRowsPerPage }));
  };

  const handlePageChange = (page: number) => {
    setPaginacao((oldState: any) => ({ ...oldState, offset: (page - 1) * paginacao.limit }));
  };

  const handleSort = (column: TableColumn<IContrato>, sortDirection: string) => {
    setPaginacao((oldState: any) => ({
      ...oldState,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    }));
  };

  const adicionarFavorito = async (contratoId: number) => {
    try {
      await ContratoService.adicionaFavorido(contratoId);
      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Contrato adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      formik.handleSubmit();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar contrato como favorito`,
        text: error?.response?.data?.Message || error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ContratoService.removerFavorido(favoritoId);
      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Contrato removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      formik.handleSubmit();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorito`,
        text: error?.response?.data?.Message || error.message,
        showConfirmButton: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: { modulo: EModulo.Contrato },
    onSubmit: async (values, { setSubmitting }) => {
      setCarregandoContratos(true);
      try {
        const resultado: IRecuperaLista<IContrato> = await ContratoService.obterContratosv2({
          ...values,
          ...paginacao,
          xCampoValores: setValuesXPesquisaCampos([...configuracoesTela, ...configuracoesModalTela], formik),
        });
        setPaginacao((oldState: any) => ({
          ...oldState,
          totalItems: resultado.totalRegistros,
        }));
        setContratos(resultado.data);
      } catch (error: any) {
        setContratos([]);
      } finally {
        setCarregandoContratos(false);
        setPesquisar(false);
        setSubmitting(false);
      }
    },
  });

  const renderCampos = () => configuracoesTela.map((configuracaoTela) => (
    <CamposPesquisaDinamicos
      key={configuracaoTela.campoNome}
      configuracaoTela={configuracaoTela}
      formik={formik}
    />
  ));

  return (
    <>
      <LayoutPageTitle title="Contrato">
        <button onClick={() => {
          setContrato(contratoInitialValues);
          navigate("/Contrato/Novo");
        }}
          className="btn btn-md btn-orange">
          Novo <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <FiltroModal
        formik={formik}
        toggleModal={toggleModal}
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        configuracoesTela={configuracoesModalTela}
        setConfiguracaoesTela={setConfiguracoesModalTela}
      />

      <RelatorioModal
        formik={formik}
        toggleModal={toggleConfimarModeloModal}
        exibirModal={exibirConfirmarModeloModal}
        configuracoesTela={configuracoesTela}
        configuracoesModalTela={configuracoesModalTela}
      />

      <CollapseFiltro titulo="Filtros" content={<> {carregandoConfiguracoesTela ? <Carregando /> : <div className="row mt-12">{renderCampos()}</div>} </>} />

      <div className="row">
        <div className="col-12"
          style={{ textAlign: 'right' }}>
          <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
            <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
          </button>
          <button
            onClick={() => setLimpar(true)}
            className="btn btn-sm btn-orange search-buttom-margin ms-2"
          >
            <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
          </button>
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
              </button>
            )}
            content={() => componentRef.current} />
          <button
            onClick={() => toggleConfimarModeloModal()}
            className="btn btn-sm btn-orange search-buttom-margin ms-2"
            disabled={carregandoContratos}>
            <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
          </button>

          <button onClick={toggleModal} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
            <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
          </button>

        </div>
      </div>

      <div className="row">
        <div className="col-md-12" style={{ overflow: !carregandoContratos ? "auto" : "scroll", height: "600px" }}>
          <div ref={componentRef}>
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoContratos}
              limit={paginacao.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={paginacao.totalItems}
              colunas={colunas}
              tipo="Contratos"
              itens={contratos}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContratoPage;
