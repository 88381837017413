import DataTable, { ConditionalStyles, PaginationOptions } from 'react-data-table-component';
import Carregando from './Carregando';
import { notifyWarning } from './Comum/Toast/ToastFormik';
import { ToastContainer } from 'react-toastify';
import { useContext, useEffect, useState } from 'react';
import { OrangeInterfaceContext } from '../interfaces/Contexts/OrangeInterfaceContext';
import { OrangeContext } from '../contexts/OrangeProvider';

interface GridPadraoType {
  colunas: any,
  itens: any[],
  tipo: string,
  conditionalRowStyles?: ConditionalStyles<any>[],
  selectableRows?: boolean,
  paginationServer?: boolean,
  paginationTotalRows?: number,
  onChangeRowsPerPage?: (currentRowsPerPage: number, currentPage: number) => void,
  onChangePage?: (page: number, totalRows: number) => void
  onRowClicked?: (row: any, event: any) => void,
  take?: number,
  progressPending?: boolean,
  disableSelectAllRows?: boolean, // Default value is false
}

export default function GridPadrao({ progressPending, take = 10, colunas, itens, tipo, conditionalRowStyles, selectableRows, paginationServer, paginationTotalRows, onChangePage, onChangeRowsPerPage, onRowClicked, disableSelectAllRows = false }: GridPadraoType) {
  const paginationComponentOptions: PaginationOptions = {
    rowsPerPageText: `${tipo} por página`,
    rangeSeparatorText: 'de',
    selectAllRowsItem: !disableSelectAllRows,
    selectAllRowsItemText: 'Todos'
  };
  const [pesquisou, setPesquisou] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      if (itens?.length == 0 && progressPending && pesquisou) {
        notifyWarning(`Nenhum registro encontrado de ${tipo.toLowerCase()}.`);
      }
    }, 1000);
  }, [progressPending]);

  useEffect(() => { if (itens?.length == 0 && progressPending) setPesquisou(true); }, [progressPending]);

  const { themeMode, setThemeMode } =
  useContext<OrangeInterfaceContext>(OrangeContext);


  return <>
    {itens?.length > 0 ?
      <DataTable
        
        progressComponent={<Carregando />}
        progressPending={progressPending}
        paginationPerPage={take}
        onRowClicked={onRowClicked}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        pagination={true}
        paginationServer={paginationServer}
        paginationTotalRows={paginationTotalRows}
        selectableRows={selectableRows}
        conditionalRowStyles={conditionalRowStyles}
        highlightOnHover={true}
        columns={colunas}
        data={itens}
        paginationComponentOptions={paginationComponentOptions} /> : <ToastContainer />
    }
  </>
}