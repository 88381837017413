
// External libraries
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import clsx from "clsx";
import { ToastContainer } from 'react-toastify';

// Internal modules
import EditorService from '../../../../services/EditorService';
import { EModulo } from '../../../../enum';
import { objetoIdPropriedade } from '../../../../utils/Utils';
import { notifySuccess } from '../../../Comum/Toast/ToastFormik';


interface IEnviarModalProps {
    objetoId: number;
    modulo: EModulo;
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
}

const EnviarMinutaModal = ({ toggleModal, exibirModal, objetoId, modulo }: IEnviarModalProps) => {

    const schema = Yup.object().shape({
        nome: Yup.string()
            .max(50, 'Máximo 50 caracteres')
            .required('Nome é obrigatório'),
        email: Yup.string()
            .email('E-mail inválido')
            .max(50, 'Máximo 50 caracteres')
            .required('E-mail é obrigatório')
    });


    const valoresIniciais = {
        modulo: modulo,
        nome: '',
        email: '',
    };

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                let objeto: any = {};

                objeto[`${objetoIdPropriedade(modulo)}`] = objetoId;

                await EditorService.enviarDocumento({ ...values, ...objeto });

                setSubmitting(false);

                toggleModal(true);

                notifySuccess('Documento enviado com sucesso');

            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel enviar o documento`,
                    text: error.response.data.Message,
                    showConfirmButton: true
                });
            }
        }
    });


    useEffect(() => { formik.setValues(valoresIniciais); }, []);

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <ToastContainer />
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">Enviar Minuta</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">Nome:</label>
                            <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.nome && formik.errors.nome,
                                },
                                {
                                    'is-valid': formik.touched.nome && !formik.errors.nome,
                                }
                            )} id="form-nome" />
                            {formik.touched.nome && formik.errors.nome && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.nome}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-email" className="form-label fw-bolder text-orange">E-mail</label>
                            <input {...formik.getFieldProps('email')} placeholder="Código" type="email" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.email && formik.errors.email,
                                },
                                {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                }
                            )} id="form-email" />
                            {formik.touched.email && formik.errors.email && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.email}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formik.isSubmitting && <> SALVAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default EnviarMinutaModal;