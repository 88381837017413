import DataTable, {
  ConditionalStyles,
  PaginationOptions,
} from "react-data-table-component";

import Carregando from "../../../components/Carregando";

interface GridPadraoType {
  colunas: any;
  itens: any[];
  tipo: string;
  conditionalRowStyles?: ConditionalStyles<any>[];
  selectableRows?: boolean;
  paginationServer?: boolean;
  paginationTotalRows?: number;
  onChangeRowsPerPage?: (
    currentRowsPerPage: number,
    currentPage: number
  ) => void;
  onChangePage?: (page: number, totalRows: number) => void;
  onRowClicked?: (row: any, event: any) => void;
  take?: number;
  progressPending?: boolean;
}

const NoData = () => (
  <p className="form-label fw-bold" style={{ margin: "10px" }}>
    Sem Usuários
  </p>
);

export default function GridUser({
  progressPending,
  take = 5,
  colunas,
  itens,
  conditionalRowStyles,
  selectableRows,
  paginationServer,
  paginationTotalRows,
  onChangePage,
  onRowClicked,
}: Readonly<GridPadraoType>) {
  const paginationComponentOptions: PaginationOptions = {
    rowsPerPageText: "Perfis",
    rangeSeparatorText: "de",
    noRowsPerPage: true,
  };

  return (
    <DataTable
      progressComponent={<Carregando />}
      progressPending={progressPending}
      paginationPerPage={take}
      onRowClicked={onRowClicked}
      onChangePage={onChangePage}
      pagination={true}
      paginationServer={paginationServer}
      paginationTotalRows={paginationTotalRows}
      selectableRows={selectableRows}
      conditionalRowStyles={conditionalRowStyles}
      highlightOnHover={true}
      columns={colunas}
      data={itens}
      paginationComponentOptions={paginationComponentOptions}
      persistTableHead
      noDataComponent={<NoData />}
    />
  );
}
