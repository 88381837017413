import { CustomEditor } from "../../../../../utils/types/slate"
import { getNodeBySelection, removeUnit } from "../../../components/Toolbar/controls/common"
import { indentHandler } from "../../../components/Toolbar/controls/indentation"

export const withTextIndent = (editor: CustomEditor) => {
  const { deleteBackward } = editor

  editor.deleteBackward = (option) => {
    const node = getNodeBySelection(editor)
    const textIndent = removeUnit(node?.style?.textIndent)

    if (node?.style && textIndent > 0) {
      indentHandler(editor, 'decreaseIndent')
    } else {
      deleteBackward(option)
    }
  }

  return editor
}