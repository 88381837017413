import IAdvogadoInternoAcesso from "../interfaces/IAdvogadoInternoAcesso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroAtualizarAdvogadoInterno from "../interfaces/Requests/AdvogadoInterno/FiltroAtualizarAdvogadoInterno";
import FiltroPesquisaAdvogadoInternoReq from "../interfaces/Requests/AdvogadoInterno/FiltroPesquisaAdvogadoInternoReq";
import api from "./Api";

class AdvogadoInternoAcessoService {
  async obterAdvogadoInterno(filtros: FiltroPesquisaAdvogadoInternoReq) {
    let query = `${filtros.status === "0" ? "&status=-1&status=1" : "&status=" + filtros.status}`;

    query += `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query += filtros.email !== "" ? `&email=${filtros.email}` : "";
    query += filtros.nome !== "" ? `&nome=${filtros.nome}` : "";

    let { data: result } = await api.get<
      IRecuperaLista<IAdvogadoInternoAcesso>
    >(`AdvogadoInterno/RecuperaAdvogadoInterno?${query}`);

    return result;
  }

  async adicionarAdvogadoInterno(usuarioId: string) {
    const { data: result } = await api.post<
      IRecuperaLista<IAdvogadoInternoAcesso>
    >(`AdvogadoInterno/AdicionaAdvogadoInterno`, { usuarioId });

    return result;
  }

  async atualizarAdvogadoInterno(
    id: string,
    filtros: FiltroAtualizarAdvogadoInterno
  ) {
    const body = [];

    for (const elem of Object.keys(filtros)) {
      body.push({
        op: "replace",
        path: `/${elem}`,
        value: filtros[elem as keyof typeof filtros],
      });
    }

    const { data: result } = await api.patch<
      IRecuperaLista<IAdvogadoInternoAcesso>
    >(`AdvogadoInterno/AtualizaAdvogadoInternoPorId?id=${id}`, body);

    return result;
  }
}

export default new AdvogadoInternoAcessoService();
