import clsx from "clsx";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import GrupoUsuariosService from "../../../../../services/GrupoUsuariosService";
import { modulosType } from "../GrupoUsuariosPage";
import IGrupoUsuarios from "../../../../../interfaces/IGrupoUsuarios";

interface IGrupoUsuariosModal {
  exibirModal: boolean;
  handleClose: () => void;
  modulos: modulosType[];
  grupo: IGrupoUsuarios;
}

const GrupoUsuariosEditModal = ({
  exibirModal,
  handleClose,
  modulos,
  grupo,
}: IGrupoUsuariosModal) => {
  const validarFormPermissoes = Yup.object().shape({
    nome: Yup.string()
      .max(50, "Máximo 50 caracteres")
      .required("O Nome é obrigatório."),
    moduloId: Yup.string()
      .max(5, "Máximo 50 caracteres")
      .required("O módulo é requerido"),
  });

  const formik = useFormik({
    initialValues: {
      nome: grupo.nome,
      moduloId: grupo.moduloId.toString(),
    },
    enableReinitialize: true,
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        await GrupoUsuariosService.editarGrupoUsuarios(
          grupo.grupoId.toString(),
          values
        );

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Grupo cadastrado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível cadastrar o Grupo`,
          text: "Ocorreu um erro inesperado.",
          showConfirmButton: true,
        });
      } finally {
        resetForm();
        handleClose();
      }
    },
  });

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Editar Grupo</h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Nome
              </label>
              <input
                {...formik.getFieldProps("nome")}
                placeholder="Nome"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.nome && formik.errors.nome,
                  },
                  {
                    "is-valid": formik.touched.nome && !formik.errors.nome,
                  }
                )}
                id="form-nome"
              />
              {formik.touched.nome && formik.errors.nome && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.nome}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 mb-4">
              <label htmlFor="form-moduloId" className="form-label fw-bolder">
                Módulo
              </label>

              <select
                {...formik.getFieldProps("moduloId")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.moduloId && formik.errors.moduloId,
                  },
                  {
                    "is-valid":
                      formik.touched.moduloId && !formik.errors.moduloId,
                  }
                )}
                id="form-moduloId"
              >
                <option value="">Selecione um:</option>
                {modulos.map((modulo) => {
                  return (
                    <option key={modulo.id} value={modulo.id}>
                      {modulo.nome}
                    </option>
                  );
                })}
              </select>

              {formik.touched.moduloId && formik.errors.moduloId && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.moduloId}</span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default GrupoUsuariosEditModal;
