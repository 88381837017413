import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import GridPadrao from "../../components/Comum/GridPadrao";
import { formartDDMMYYYToDateTime } from "../../utils/Utils";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { faEraser, faSearch, faFileExcel, faTrashAlt, faCheckCircle, faPlusCircle, faCloudDownloadAlt, faFilter, faBuilding, faTimes, faFile, faChartBar } from "@fortawesome/free-solid-svg-icons";


import IEstado from "../../interfaces/IEstado";
import ICidade from "../../interfaces/ICidade";
import IEscritorio from "../../interfaces/IEscritorio";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IXFinderDistribuicao from "../../interfaces/IXFinderDistribuicao";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaXFinderDistribuicaoRequest from "../../interfaces/Requests/XFinder/FiltroPesquisaXFinderDistribuicaoRequest";

import CidadeService from "../../services/CidadeService";
import EstadoService from "../../services/EstadoService";
import DocumentoService from "../../services/DocumentoService";
import EscritorioService from "../../services/EscritorioService";
import XFinderDistribuicaoService from "../../services/XFinderDistribuicaoService";

import { OrangeContext } from "../../contexts/OrangeProvider";
import { areaDireitoInicialValues, desdobramentoInicialValues, tipoDesdobramentoInicialValues, xFinderDistribuicaoInitialValues } from "../../contexts/InitialValuesContext";
import IAreaDireito from "../../interfaces/IAreaDireito";
import AreaDireitoService from "../../services/AreaDireitoService";
import { MultiSelect } from "react-multi-select-component";
import XFinderRelatorioModal from "./XFinderRelatorioModal";
import PreviewPDFField from "../../components/Comum/PreviewPDF/PreviewPDF";
import DropDown from "../../components/Comum/DropDown/DropDown";
import { BsSearch } from "react-icons/bs";
import XFinderDistribuicaoModal from "./XFinderDistribuicaoModal";
import IDocumento from "../../interfaces/IDocumento";
import XFinderDesdobramentoModal from "./XFinderDesdobramentoModal";
import IProcesso from "../../interfaces/IProcesso";
import ProcessoService from "../../services/ProcessoService";
import { EModulo } from "../../enum";
import { useAuth } from "../../contexts/AuthProvider";

const XFinderDistribuicaoPage = () => {

  const { user } = useAuth();

  const { processoCadastro, setProcessoCadastro, setAreaDireito, setProcesso, setDesdobramento, setTipoDesdobramento } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoXFinderDistribuicoes, setCarregandoXFinderDistribuicoes] = useState(false);
  const [xFinderDistribuicoes, setXFinderDistribuicoes] = useState<IXFinderDistribuicao[]>([]);
  const [xFinderDistribuicao, setXFinderDistribuicao] = useState<IXFinderDistribuicao>(xFinderDistribuicaoInitialValues);

  const [options, setOptions] = useState<any[]>([]);
  const [escritorioSelectId, setEscritorioSelectId] = useState<number>(0);
  const [xFinderDistribuicaoId, setXFinderDistribuicaoId] = useState<number>(0);
  const [exibirEscritorioModal, setEscritorioExibirModal] = useState<boolean>(false);

  const [carregamentoAreaDireito, setCarregamentoAreaDireito] = useState<boolean>(false);
  const [areasDireitoOptions, setAreasDireitoOptions] = useState<any>([]);
  const [areaDireitoSelected, setAreaDireitoSelected] = useState([]);

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");
  const [exibirArquivosModal, setArquivosExibirModal] = useState<boolean>(false);
  const [arquivos, setArquivos] = useState<IDocumento[]>([]);

  const [exibirModalXFinder, setExibirModalXFinder] = useState<boolean>(false);
  const [exibirModalDesdobramento, setExibirModalDesdobramento] = useState<boolean>(false);
  const [exibirAnaliseDistribuicao, setExibiAnaliseDistribuicao] = useState<boolean>(false);
  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);


  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaXFinderDistribuicaoRequest>({
    xFinderDistribuicaoId: [],
    areaDireitoId: [],
    dataDistribuicaoMaiorIgual: "",
    dataDistribuicaoMenorIgual: "",
    nomePesquisa: "",
    numeroProcesso: "",
    reu: "",
    autor: "",
    advogadoAutor: "",
    dataAudienciaMaiorIgual: "",
    dataAudienciaMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    status: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "xFinderDistribuicaoId",
  });

  const navigate = useNavigate();

  const toggleEscritorioModal = () => {

    setEscritorioSelectId(0);

    setEscritorioExibirModal(!exibirEscritorioModal);
  }

  const toggleXFinderModal = () => setExibirModalXFinder(!exibirModalXFinder);
  const toggleArquivosModal = () => setArquivosExibirModal(!exibirArquivosModal);
  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);
  const toggleAnaliseDistribuicao = () => setExibiAnaliseDistribuicao(!exibirAnaliseDistribuicao);
  const toggleModelDesdobramento = (): void => setExibirModalDesdobramento(!exibirModalDesdobramento);
  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

  useEffect(() => { carregarEscritorios(); carregarAreaDireito(); }, []);

  useEffect(() => {
    if (!loadingInicial) {
      carregarXFinderDistribuicoes(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    areaDireitoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, areaDireitoId: IdsSelected };
    });
  }, [areaDireitoSelected]);

  const returnOptions = (array: any, propId: string) => {
    let options: any[] = [];

    array.forEach((item: any) => {
      options.push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });
    });

    return options;
  };

  const carregarAreaDireito = async () => {
    try {
      setCarregamentoAreaDireito(true);
      let resultado: IRecuperaLista<IAreaDireito>;
      resultado = await AreaDireitoService.obterListaLimitada();
      setAreasDireitoOptions(returnOptions(resultado.data, "areaDireitoId"));
      setCarregamentoAreaDireito(false);
    } catch (error: any) {
      setCarregamentoAreaDireito(false);
      setAreasDireitoOptions([]);
    }
  }

  const alterarStatus = async (xFinderDistribuicaoId: number, status: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        if (status == 5) {
          await XFinderDistribuicaoService.triagem(xFinderDistribuicaoId);
        } else {
          await XFinderDistribuicaoService.alterarStatus(xFinderDistribuicaoId, status);
        }
        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();

        setFiltrosPesquisa((oldState) => {
          carregarXFinderDistribuicoes(oldState);
          return oldState;
        });
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o Status do Processo`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  }

  const salvarEscritorio = async () => {
    try {
      await toggleEscritorioModal();

      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: options.find(e => e.value == escritorioSelectId)?.label,
        text: `Você realmente deseja salvar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {

        await XFinderDistribuicaoService.salvarEscritorio(xFinderDistribuicaoId, escritorioSelectId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();
        setFiltrosPesquisa((oldState) => {
          carregarXFinderDistribuicoes(oldState);
          return oldState;
        });
      } else {
        setEscritorioExibirModal(true);
      }


    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o Status do Processo`,
        text: error?.response?.data?.Message,
        showConfirmButton: true,
      });
    }
  }

  const carregarXFinderDistribuicoes = async (filtro: FiltroPesquisaXFinderDistribuicaoRequest): Promise<void> => {
    try {
      setCarregandoXFinderDistribuicoes(true);
      let resultado: IRecuperaLista<IXFinderDistribuicao>;

      resultado = await XFinderDistribuicaoService.obterXFinderDistribuicoes(filtro);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setXFinderDistribuicoes(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros.",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setXFinderDistribuicoes([]);
    } finally {
      setCarregandoXFinderDistribuicoes(false);
      setLoadingInicial(false);
    }
  }

  const abrirNovoProcesso = async (xFinderDistribuicao: IXFinderDistribuicao) => {
    try {

      let estadoResponse = await carregarEstado(xFinderDistribuicao.uf);

      let cidadeResponse = await carregarCidade(estadoResponse, xFinderDistribuicao.cidade);

      setAreaDireito(areaDireitoInicialValues);

      setProcessoCadastro({
        ...processoCadastro,
        estadoId: estadoResponse,
        cidadeId: cidadeResponse,
        datadistribuicao: formartDDMMYYYToDateTime(xFinderDistribuicao.dataDistribuicao),
        numeroprocesso: xFinderDistribuicao.numeroProcesso,
        valorcausa: xFinderDistribuicao.valor,
        escritorioId: xFinderDistribuicao.escritorioId,
        cnjUnidadeOrigemId: xFinderDistribuicao.cnjUnidadeOrigemId,
        xFinderDistribuicaoId: xFinderDistribuicao.xFinderDistribuicaoId,
        esferaId: 1,
        instanciaId: 2,
        resumoprocesso: xFinderDistribuicao.tipoOcorrencia
      });

      navigate("/Processo/Novo");

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  const carregarEstado = async (nome: string) => {
    let resultado: IRecuperaLista<IEstado>;
    resultado = await EstadoService.obterEstadoPorSigla(nome);

    return resultado.data?.length > 0 ? resultado?.data[0]?.estadoId : 0;
  }

  const carregarCidade = async (estadoId: number, nome: string) => {
    let resultadoCidade: IRecuperaLista<ICidade>;
    resultadoCidade = await CidadeService.obterCidadePorFiltros({
      estadoId: estadoId,
      IBGE: "",
      nome: nome,
      status: 1,
      limit: 900,
      totalItems: 0,
      offset: 0,
      sort: "nome",
    });
    return resultadoCidade.data?.length > 0 ? resultadoCidade?.data[0]?.cidadeId : 0;
  }

  const carregarEscritorios = async () => {
    try {

      let resultado: IRecuperaLista<IEscritorio>;

      resultado = await EscritorioService.obterEscritorios({
        clienteId: 0,
        nome: "",
        codigo: "",
        limit: 1000,
        isTelaPesquisaProcesso: false,
        totalItems: 0,
        offset: 0,
        sort: "escritorioId",
      });

      resultado.data.map((item) => options.push({ label: item.nome, value: item.escritorioId }));

    } catch (error: any) {
      setOptions([]);
    }
  }

  const iniciarProcesso = async (processoId: number, xFinderDistribuicao: IXFinderDistribuicao) => {
    try {

      let resultado: IRecuperaLista<IProcesso>;

      resultado = await ProcessoService.obterProcessoPorId(processoId);

      let processo = resultado.data[0];

      setProcesso(processo);

      setDesdobramento({
        ...desdobramentoInicialValues,
        tipoDesdobramentoId: 0,
        modulo: EModulo.AreaDireito,
        processoId: processo.processoId,
        areaDireitoId: processo.areaDireitoId,
        categoriaId: processo.areaDireitoId,
        dataDistribuicao: formartDDMMYYYToDateTime(xFinderDistribuicao.dataDistribuicao),
        numero: xFinderDistribuicao.numeroProcesso,
      });

      setTipoDesdobramento(tipoDesdobramentoInicialValues);

      navigate("/Desdobramento/Novo");

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message,
        showConfirmButton: true,
      });
    }
  };

  const baixarArquivo = async (url: string, nomeArquivo: string) => {

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });

    Swal.showLoading();

    try {
      url = url.replace("http:", "https:");
      await DocumentoService.downloadKurier(url)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const colunasXFinder: TableColumn<IXFinderDistribuicao>[] = [
    {
      name: "Id",
      sortField: "xFinderDistribuicaoId",
      selector: (row: IXFinderDistribuicao) => row.xFinderDistribuicaoId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IXFinderDistribuicao) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Parte",
      sortField: "autor",
      selector: (row: IXFinderDistribuicao) => row.autor,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome Pesquisa",
      sortField: "nomePesquisa",
      selector: (row: IXFinderDistribuicao) => row.nomePesquisa,
      sortable: true,
      wrap: true,
    },
    {
      name: "Número do Processo no XFinder",
      sortField: "numeroProcesso",
      selector: (row: IXFinderDistribuicao) => row.numeroProcesso,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Cadastro",
      sortField: "dataCadastro",
      selector: (row: IXFinderDistribuicao) => {
        if (row.dataCadastro === null) return "-";
        const data = row.dataCadastro || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Distribuição",
      sortField: "dataDistribuicao",
      selector: (row: IXFinderDistribuicao) => {
        if (row.dataDistribuicao === null) return "-";
        const data = row.dataDistribuicao || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Audiência",
      sortField: "dataAudiencia",
      selector: (row: IXFinderDistribuicao) => {
        if (row.dataAudiencia === null) return "-";
        const data = row.dataAudiencia || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Opções',
      cell: (xFinderDistribuicao: IXFinderDistribuicao) => {
        return (
          <>
            <DropDown
              height={220}
              totalItems={xFinderDistribuicoes?.length}
              content={
                <>
                  {xFinderDistribuicao.linkAcessoInicial?.length > 0 && xFinderDistribuicao.xFinderOrigem?.length > 0 && (
                    <Dropdown.Item onClick={() => {
                      setArquivosExibirModal(true);
                      setArquivos(xFinderDistribuicao.xFinderDistribuicaoDocumentos);
                    }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Baixar Arquivo"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faCloudDownloadAlt}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Baixar Arquivo
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  {xFinderDistribuicao.status === "Pendente" && (
                    <Dropdown.Item onClick={() => abrirNovoProcesso(xFinderDistribuicao)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Cadastrar Processo"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faPlusCircle}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Cadastrar Processo
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  {xFinderDistribuicao.status === "Pendente" && (
                    <Dropdown.Item onClick={() => alterarStatus(xFinderDistribuicao.xFinderDistribuicaoId, -1)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Cancelar Processo"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faTrashAlt}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Cancelar Processo
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  {xFinderDistribuicao.status === "Triagem" && (
                    <Dropdown.Item onClick={() => abrirNovoProcesso(xFinderDistribuicao)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Cadastrar Processo"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faPlusCircle}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Cadastrar Processo
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  {xFinderDistribuicao.status === "Triagem" && (
                    <Dropdown.Item onClick={() => alterarStatus(xFinderDistribuicao.xFinderDistribuicaoId, -1)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Cancelar Processo"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faTrashAlt}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Cancelar Processo
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  {xFinderDistribuicao.status === "Pendente" && (
                    <Dropdown.Item onClick={() => alterarStatus(xFinderDistribuicao.xFinderDistribuicaoId, 5)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Triagem"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faFilter}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Triagem
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  {xFinderDistribuicao.status === "Cancelado" && (
                    <Dropdown.Item onClick={() => alterarStatus(xFinderDistribuicao.xFinderDistribuicaoId, 1)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Alterar Processo Para Pendente"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faCheckCircle}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Alterar Processo Para Pendente
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  {xFinderDistribuicao.status === "Triagem" && (
                    <Dropdown.Item onClick={() => {
                      toggleEscritorioModal();
                      setXFinderDistribuicaoId(xFinderDistribuicao.xFinderDistribuicaoId);
                    }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          title="Selecionar o escritório"
                          style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                          icon={faBuilding}
                          size="1x"
                          className="text-orange"
                        />
                        <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Selecionar escritório
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item onClick={() => {
                    if (xFinderDistribuicao.xProcessoId) {
                      iniciarProcesso(parseInt(xFinderDistribuicao.xProcessoId), xFinderDistribuicao);
                    } else {
                      toggleModelDesdobramento();
                      setXFinderDistribuicao(xFinderDistribuicao);
                    }
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-10px' }}>

                      <div style={{ width: '35px', textAlign: 'center' }}>
                        <span style={{ fontSize: '13px' }} className="navigation-service-icon navigation-service-icon-dobrar"></span>
                      </div>

                      <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Cadastrar Desdobramento
                      </label>

                    </div>
                  </Dropdown.Item>


                  <Dropdown.Item onClick={() => {
                    setXFinderDistribuicao(xFinderDistribuicao);
                    setExibirModalXFinder(true);
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BsSearch
                        title="Detalhes"
                        style={{ fontWeight: "normal", cursor: "pointer", marginRight: '4px' }}
                        size="25px"
                        className="text-orange"
                      />
                      <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Detalhes
                      </label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setExibiAnaliseDistribuicao(true)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FontAwesomeIcon
                        title="Detalhes"
                        style={{ fontWeight: "normal", cursor: "pointer", visibility: user?.id == 13 ? 'initial' : 'hidden' }} // mostrar apenas para usuario 13
                        size="1x"
                        icon={faChartBar}
                        className="text-orange"
                      />
                      <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Análise de Distribuição
                      </label>
                    </div>
                  </Dropdown.Item>

                </>}>

            </DropDown>
          </>
        )
      },
    },
  ];

  const colunasArquivos: TableColumn<IDocumento>[] = [
    {
      name: "Arquivo",
      sortField: "Nome",
      cell: (row: IDocumento, index: number) => `Arquivo ${index + 1}`,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "Nome",
      cell: (row: IDocumento) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Arquivos',
      cell: (row: IDocumento, index: number) => {
        return (
          <FontAwesomeIcon
            title="Baixar Arquivo"
            style={{ fontWeight: "normal", cursor: "pointer" }}
            icon={faCloudDownloadAlt}
            size="2x"
            onClick={() => {
              if (row.linkDocumento) {
                if (row.linkDocumento.match(/pdf.*/)) {
                  setUrlPDFDocumento(row.linkDocumento);
                  setModalExibirPDFDocumento(true);
                } else {
                  baixarArquivo(row.linkDocumento, `arquivo_${index}`);
                }
              }
            }}
            className="mx-2 text-orange"
          />
        )
      },
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IXFinderDistribuicao>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue: any) => {
      return {
        ...oldValue,
        xFinderDistribuicaoId: 0,
        dataDistribuicaoMaiorIgual: "",
        dataDistribuicaoMenorIgual: "",
        nomePesquisa: "",
        numeroProcesso: "",
        reu: "",
        autor: "",
        advogadoAutor: "",
        dataAudienciaMaiorIgual: "",
        dataAudienciaMenorIgual: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        status: 0,
      };
    });
  }

  return (
    <>

      <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

      <PreviewPDFField exibirModal={exibirAnaliseDistribuicao} toggleModal={toggleAnaliseDistribuicao} url={'https://xjurbancoimagens.s3.sa-east-1.amazonaws.com/IA-AnaliseDistribuicao.pdf'} />

      <XFinderDistribuicaoModal
        exibirModal={exibirModalXFinder}
        finderDistribuicao={xFinderDistribuicao}
        titulo="Detalhes"
        toggleModal={toggleXFinderModal}
      />

      <XFinderDesdobramentoModal
        exibirModal={exibirModalDesdobramento}
        toggleModal={toggleModelDesdobramento}
        xFinderDistribuicao={xFinderDistribuicao}
      />

      <Modal size='lg' centered={true} show={exibirEscritorioModal} onHide={toggleEscritorioModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Selecionar o escritório  <FontAwesomeIcon
              title="Selecionar o escritório"
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faBuilding}
            /></h5>
            <div onClick={() => toggleEscritorioModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              <div className="col-md-6 mb-3">
                <label htmlFor="form-clienteTipo" className="form-label text-orange fw-bolder">
                  Escritório:
                </label>
                <Select
                  value={{
                    label: options.find((e) => e.value === escritorioSelectId)?.label,
                    value: escritorioSelectId,
                  }}
                  onChange={(option: any) => setEscritorioSelectId(option.value)}
                  options={options}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer" style={{ margin: '0 auto' }}>
            <button disabled={!escritorioSelectId} type="button" onClick={() => salvarEscritorio()} className="btn btn-orange me-5">
              Salvar
            </button>
            <button onClick={() => toggleEscritorioModal()} type="button" className="btn btn-danger ms-5"> Cancelar </button>

          </div>
        </div>
      </Modal>


      <Modal size='lg' centered={true} show={exibirArquivosModal} onHide={toggleArquivosModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Selecionar o arquivo  <FontAwesomeIcon
              title="Selecionar"
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faFile}
            /></h5>
            <div onClick={() => toggleArquivosModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              <div className="col-md-12 mb-3">
                <GridPadrao
                  colunas={colunasArquivos}
                  tipo="Arquivos"
                  itens={arquivos}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <XFinderRelatorioModal
        exibirModal={exibirConfirmarModeloModal}
        toggleModal={toggleConfimarModeloModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
      />


      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarXFinderDistribuicoes(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-xfinder-distribuicao"
          >

            <div className="row">
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Área Direito:
                </label>
                <MultiSelect
                  isLoading={carregamentoAreaDireito}
                  disabled={carregamentoAreaDireito}
                  options={areasDireitoOptions}
                  value={areaDireitoSelected}
                  onChange={(event: any) => setAreaDireitoSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-numero-processo" className="form-label fw-bolder text-orange">
                  Assunto:
                </label>
                <input
                  value={filtrosPesquisa.assuntos}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, assuntos: e.target.value };
                    });
                  }}
                  placeholder="Assunto"
                  className={"form-control"}
                  id="form-numero-processo"
                />
              </div>
            </div>


            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data de Cadastro:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas?.length === 0) {
                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`,
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                  }
                }}

              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-distribuicao" className="form-label fw-bolder text-orange">
                Data da Distribuição:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataDistribuicaoMaiorIgual && filtrosPesquisa.dataDistribuicaoMenorIgual
                    ? [filtrosPesquisa.dataDistribuicaoMaiorIgual, filtrosPesquisa.dataDistribuicaoMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas?.length === 0) {
                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataDistribuicaoMaiorIgual: '',
                      dataDistribuicaoMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataDistribuicaoMaiorIgual: `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`,
                      dataDistribuicaoMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataDistribuicaoMaiorIgual: '',
                      dataDistribuicaoMenorIgual: '',
                    }));
                  }
                }}

              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form" className="form-label fw-bolder text-orange">Data da Audência:</label>
              <DateTimeRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={filtrosPesquisa.dataAudienciaMaiorIgual && filtrosPesquisa.dataAudienciaMenorIgual
                  ? [filtrosPesquisa.dataAudienciaMaiorIgual, filtrosPesquisa.dataAudienciaMenorIgual]
                  : ""}
                onChange={(datas: Date[]) => {
                  if (!datas || datas?.length === 0) {
                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataAudienciaMaiorIgual: '',
                      dataAudienciaMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataAudienciaMaiorIgual: `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`,
                      dataAudienciaMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataAudienciaMaiorIgual: '',
                      dataAudienciaMenorIgual: '',
                    }));
                  }
                }} />

            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nomePesquisa" className="form-label fw-bolder text-orange">
                Nome Pesquisa:
              </label>
              <input
                value={filtrosPesquisa.nomePesquisa}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, nomePesquisa: e.target.value };
                  });
                }}
                placeholder="Nome Pesquisa"
                className={"form-control"}
                id="form-nomePesquisa"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, status: parseInt(e.target.value) };
                  });
                }}
                placeholder="Status"
                className={"form-select"}
                id="form-status"
              >
                <option value={0}>Todos</option>
                <option value={1}>Pendente</option>
                <option value={2}>Cadastrado</option>
                <option value={-1}>Cancelado</option>
                <option value={5}>Triagem</option>

              </select>
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-numero-processo" className="form-label fw-bolder text-orange">
                Número do Processo no XFinder:
              </label>
              <input
                value={filtrosPesquisa.numeroProcesso}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, numeroProcesso: e.target.value };
                  });
                }}
                placeholder="Número do Processo no XFinder"
                className={"form-control"}
                id="form-numero-processo"
              />
            </div>
            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
              <button
                onClick={() => toggleConfimarModeloModal()}
                className="btn btn-orange search-buttom-margin ms-2"
                disabled={carregandoXFinderDistribuicoes}>
                <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
              </button>
            </div>
          </form>
        </div>

        <div className="col-md-12">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoXFinderDistribuicoes}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunasXFinder}
            tipo="XFinderDistribuicoes"
            itens={xFinderDistribuicoes}
          />
        </div>
      </div>
    </>
  );
};

export default XFinderDistribuicaoPage;
