import GridPadrao from '../Comum/GridPadrao';
import { TableColumn } from 'react-data-table-component';

interface ILabelConcluidoDinamicoPropos {
  historico: any;
}

const LabelConcluidoDinamicoPropos = ({ historico }: ILabelConcluidoDinamicoPropos) => {

  const colunasParteContraria: TableColumn<any>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Documento",
      sortField: "documento",
      selector: (row: any) => row.documento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumento",
      selector: (row: any) => row.tipoDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacaoNome",
      selector: (row: any) => row.formaParticipacao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasEmpresa: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "empresaId",
      selector: (row: any) => row.empresaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nomeFantasia",
      selector: (row: any) => row.nomeFantasia || row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "CNPJ",
      sortField: "numeroDocumento",
      selector: (row: any) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAdvogado: TableColumn<any>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: any) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const renderLabel = (item: any) => {
    let component = <></>;

    if (Array.isArray(item.value)) {
      if (item.label === "Parte Contraria") {
        component = (
          <GridPadrao paginationServer={true} colunas={colunasParteContraria} tipo="ParteContraria" itens={item.value} />
        );
      } else if (item.label === "Empresa") {
        component = (
          <GridPadrao paginationServer={true} colunas={colunasEmpresa} tipo="Empresa" itens={item.value} />
        );
      } else if (item.label === "Advogado") {
        component = (
          <GridPadrao paginationServer={true} colunas={colunasAdvogado} tipo="Advogado" itens={item.value} />
        );
      } else {
        component = (
          <div style={{ marginLeft: "20px" }}>
            {item.value.map((subItem: any, index: number) => (
              <div key={index}>
                <b>{subItem.label}:</b> {subItem.value}
              </div>
            ))}
          </div>
        );
      }
    } else if (item.value) {
      component = <b>{item.value}</b>;
    } else {
      component = <b>N/A</b>;
    }

    return component;
  };

  const getClassName = (item: any) => {
    // Apenas "Parte Contraria", "Advogado" ou "Empresa" devem ser 'col-md-12'
    return item.label === "Parte Contraria" || item.label === "Advogado" || item.label === "Empresa"
      ? "col-md-12 mt-3"
      : "col-md-4 mt-3";
  };

  return (
    <>
      <div className="row">
        {historico.map((item: any, index: number) => (
          <div key={index} className={getClassName(item)}>
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              {item.label}
            </label>
            <div className={getClassName(item)}>{renderLabel(item)}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LabelConcluidoDinamicoPropos;
