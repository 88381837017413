import IDashboardTabela from "../interfaces/IDashboardTabela";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import AdicionarDashboardReq from "../interfaces/Requests/Dashboard/AdicionarDashboardReq";
import AtualizaDashboardReq from "../interfaces/Requests/Dashboard/AtualizaDashboardReq";
import FiltroPesquisaDashboardReq from "../interfaces/Requests/Dashboard/FiltroPesquisaDashboardReq";
import api from "./Api";

class DashboardTabelaService {
  async obterDashboards(filtros: FiltroPesquisaDashboardReq) {
    let query = `${filtros.status === "0" ? "&status=-1&status=1" : "&status=" + filtros.status}`;

    query += `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query += filtros.modulo !== 0 ? `&modulo=${filtros.modulo}` : "";
    query += filtros.nome !== "" ? `&nome=${filtros.nome}` : "";

    const { data: result } = await api.get<IRecuperaLista<IDashboardTabela>>(
      `Dashboard/RecuperaDashboard?${query}`
    );

    return result;
  }

  async adicionarDashboards(req: AdicionarDashboardReq) {
    const { data: result } = await api.post<IDashboardTabela>(
      "Dashboard/AdicionaDashboard",
      req
    );

    return result;
  }

  async atualizarDashboard(id: string, req: AtualizaDashboardReq) {
    const body = [];

    for (const elem of Object.keys(req)) {
      body.push({
        op: "replace",
        path: `/${elem}`,
        value: req[elem as keyof typeof req],
      });
    }

    const { data: result } = await api.patch<IDashboardTabela>(
      `Dashboard/AtualizaDashboardPorId?id=${id}`,
      body
    );

    return result;
  }
}

export default new DashboardTabelaService();
