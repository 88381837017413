import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap"
import { IoBusinessOutline, IoCashOutline, IoCreateOutline, IoPeopleOutline } from "react-icons/io5"

import { useNavigate } from "react-router-dom"
import { despesaInicialValues } from "../../../contexts/InitialValuesContext"
import { EModulo } from "../../../enum"
import { useContext } from "react"
import { OrangeContext } from "../../../contexts/OrangeProvider"
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext"

export const PopoverSubMenuEmpresa = () => {
  const navigate = useNavigate();

  const {
    setDespesa,
    empresa,

  } = useContext<OrangeInterfaceContext>(OrangeContext);
  const tooltip = (title: string) => <Tooltip id={`button-tooltip-${title}`}> {title} </Tooltip>

  return (
    <Popover id="popover-basic">
      <Popover.Body style={{ cursor: "pointer" }}>

        <p>
          <OverlayTrigger placement="bottom" overlay={tooltip("Voltar para Capa da Empresa")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Societario/Empresa/Capa")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={tooltip("Despesas")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => {
                  setDespesa({
                    ...despesaInicialValues,
                    modulo: EModulo.AreaDireito,
                    empresaId: empresa.empresaId,
                    tipoSociedadeId: empresa.tipoSociedadeId,
                    categoriaId: empresa.tipoSociedadeId,
                  });
                  navigate("/Societario/Empresa/Solucoes/Despesa");
                }}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-despesa" style={{ marginTop: 10 }}></span>

              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={tooltip("Filiais")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Societario/Empresa/Solucoes/Filiais")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="icon-solucao">
                  <IoBusinessOutline color="#fd7e14" />
                </span>
              </Button>
            )}
          </OverlayTrigger>
        </p>

        <p>

          <OverlayTrigger placement="bottom" overlay={tooltip("Atos Societários")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Societario/Empresa/Solucoes/AtosSocietario")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="icon-solucao">
                  <IoCreateOutline color="#fd7e14" />
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={tooltip("Representantes")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Societario/Empresa/Solucoes/Representante")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="icon-solucao">
                  <IoPeopleOutline color="#fd7e14" />
                </span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={tooltip("Distriduição do Capital")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Societario/Empresa/Solucoes/DistribuicaoCapitalPage")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="icon-solucao">
                  <IoCashOutline color="#D2691E" />
                </span>
              </Button>
            )}
          </OverlayTrigger>
        </p>

        <p>

          <OverlayTrigger placement="bottom" overlay={tooltip("Logs")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Empresa/Logs")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="icon-solucao">
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faExchangeAlt}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>
        </p>
      </Popover.Body>
    </Popover>
  )

}
