
import { useEffect, useState } from "react";

import { TableColumn } from "react-data-table-component";

import { ECamposIDEnum, returnarEnumDescricaoID } from "../../enum/ECamposIDEnum";
import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";


import IEmpresa from "../../interfaces/IEmpresa";
import ICentroCusto from "../../interfaces/ICentroCusto";
import IPartesProcesso from "../../interfaces/IPartesProcesso";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import GridPadrao from "../Comum/GridPadrao";
import AreaTextoField from "../Comum/AreaTexto/AreaTextoField";
import FormListTabelaField from "../Comum/TabelaField/Modulo/FormListTabelaField";

interface ICamposCapaDinamicosPropos {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  configuracaoTelaLista: IRecuperaConfiguracaoTelaResponse[];
  partesProcesso: IPartesProcesso[];
  advogadosInterno: IPartesProcesso[];
  formik: any;
}

const CamposCapaDinamicos = ({
  configuracaoTela,
  configuracaoTelaLista,
  partesProcesso,
  advogadosInterno,
  formik,
}: ICamposCapaDinamicosPropos) => {

  const [exibirCampo, setExibirCampo] = useState<boolean>(true);
  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);

  useEffect(() => {
    if (!configuracaoTela.campoCondicionadoEnable) return;

    const campoCondicionado = configuracaoTelaLista?.find(e => e.campo === configuracaoTela.camposCondicionadoId);

    if (campoCondicionado?.campoNome) {
      const campoCondicionadoId = formik.values[`${returnarEnumDescricaoID(campoCondicionado.campoNome, campoCondicionado.isXCampo)}`] || campoCondicionado.valorId;
      const opcaoEncontrada = configuracaoTela.campoCondicionadoOpcoes?.some(campoOpcao => campoOpcao.opcaoId === campoCondicionadoId);

      configuracaoTela.obrigatorio = !!opcaoEncontrada;
      setExibirCampo(!!opcaoEncontrada);
    }
  }, [formik.values, configuracaoTela, configuracaoTelaLista]);

  const renderClassName = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Tabela ||
      configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  const colunasCentroCusto: TableColumn<ICentroCusto>[] = [
    {
      name: "Id",
      sortField: "tipoCentroCustoId",
      selector: (row: ICentroCusto) => row.tipoCentroCustoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: ICentroCusto) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Percentual",
      sortField: "percentual",
      selector: (row: ICentroCusto) => (row.percentual ? row.percentual + "%" : "0%"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAdvogadoInterno: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "processoAdvogadoInternoId",
      selector: (row: any) => row.processoAdvogadoInternoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
  ];

  const colunasPartesContrarias: TableColumn<IPartesProcesso>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacao",
      selector: (row: any) => row.formaParticipacao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.tipo === "advogadoParteContraria" ? "N/A" : row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      sortField: "numeroDocumento",
      selector: (row: any) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo de Documento",
      sortField: "tipoDocumento",
      selector: (row: any) => row.tipoDocumento,
      sortable: true,
      wrap: true,
    },
  ];

  const colunasEmpresa: TableColumn<IEmpresa>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacao",
      selector: (row: any) => row.formaParticipacao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      sortField: "principal",
      selector: (row: any) => (row.principal ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      sortField: "numeroDocumento",
      selector: (row: any) => row.numeroDocumento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo do Documento",
      sortField: "tipoDocumento",
      selector: (row: any) => row.tipoDocumento,
      sortable: true,
      wrap: true,
    },
  ];

  const renderLabel = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:
        if (configuracaoTela?.campoNome == ECamposIDEnum.CentroCusto) {
          component = (
            <>
              <div className="row mt-2">
                <div className="col-md-12">
                  <GridPadrao paginationServer={true} colunas={colunasCentroCusto} tipo="TipoCentroCusto" itens={formik.values.centrosCusto} />{" "}
                </div>
              </div>
            </>
          );
        } else {
          component = <b>{configuracaoTela.valor}</b>;
        }
        break;

      case ETipoCampoEnum.Percentual:
        if (configuracaoTela.valor) {
          const formattedValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
            .formatToParts(configuracaoTela.valor)
            .filter((part) => part.type !== 'currency')
            .map((part) => part.value)
            .join('');

          component = <b>{formattedValue}%</b>;
        } else {
          component = <b>0,00%</b>;
        }
        break;

      case ETipoCampoEnum.Valor:
        if (configuracaoTela.valor) {
          component = <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configuracaoTela.valor)}</b>;
        } else {
          component = <b>0,00</b>
        }
        break;

      case ETipoCampoEnum.Data:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Texto:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.AreaTexto:
        // eslint-disable-next-line no-case-declarations
        let value = configuracaoTela?.valor || '';
        component = <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
          {value.length > 200 ? (<>
            {value.substring(0, 200)}...<span onClick={() => {
              formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, configuracaoTela.valor);
              toggleAreaTextoModal();
            }}
              style={{ color: 'orange', cursor: 'pointer' }}>
              {!exibirModalAreaTexto && 'ler mais '}
            </span>
          </>) : <p>{value}</p>}
        </p>;
        break;

      case ETipoCampoEnum.Boolean:
        component = <b>{configuracaoTela.valor ? "Sim" : "Não"}</b>;
        break;

      case ETipoCampoEnum.Inteiro:
        component = <b>{configuracaoTela.valor}</b>;
        break;

      case ETipoCampoEnum.Empresa:
        component = (
          <>
            <div className="row mt-2">
              <div className="col-md-12">
                <GridPadrao
                  paginationServer={true}
                  colunas={colunasEmpresa}
                  tipo="Empresa"
                  itens={partesProcesso.filter((e) => e.tipo == "empresa")}
                />
              </div>
              <hr />
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Pessoa:
        if (configuracaoTela?.campoNome === ECamposIDEnum.AdvogadoInterno) {
          component = (
            <>
              <div className="row mt-2">
                <div className="col-md-12">
                  <GridPadrao paginationServer={true} colunas={colunasAdvogadoInterno} tipo="AdvogadoInterno" itens={advogadosInterno} />
                </div>
                <hr />
              </div>
            </>
          );
        }

        if (configuracaoTela?.campoNome === ECamposIDEnum.Advogado) {
          component = (
            <>
              <div className="row mt-2">
                <div className="col-md-12">
                  <GridPadrao
                    paginationServer={true}
                    colunas={colunasPartesContrarias}
                    tipo="Advogado"
                    itens={partesProcesso.filter((e) => e.tipo == "advogadoParteContraria")}
                  />
                </div>
                <hr />
              </div>
            </>
          );
        }

        if (configuracaoTela?.campoNome === "ParteContraria") {
          component = (
            <>
              <div className="row mt-2">
                <div className="col-md-12">
                  <GridPadrao
                    paginationServer={true}
                    colunas={colunasPartesContrarias}
                    tipo="PartesContraria"
                    itens={partesProcesso.filter((e) => e.tipo == "parteContraria")}
                  />
                </div>
                <hr />
              </div>
            </>
          );
        }

        break;

      case ETipoCampoEnum.Tabela:
        component = (<><FormListTabelaField configuracaoTela={configuracaoTela} formik={formik} capa={true} /></>)
        break;
    }

    return component;
  };

  const renderAreaTexto = () => {
    if (configuracaoTela?.campoTipo == ETipoCampoEnum.AreaTexto) {
      return (
        <>
          <AreaTextoField
            configuracaoTela={configuracaoTela}
            toggleModal={toggleAreaTextoModal}
            exibirModal={exibirModalAreaTexto}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  return (
    exibirCampo ? <>{
      <>
        {renderAreaTexto()}
        <div className={renderClassName()}>
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
          </label>
          <div className="row">
            <div className="col-md-12">{renderLabel()}  </div>
          </div>
        </div>
      </>
    } </> : <></>);
};

export default CamposCapaDinamicos;
