import { useContext, useEffect, useState } from "react";
import { faFileArchive, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import clsx from "clsx";

import HonorarioService from "../../../services/HonorarioService";
import CompromissoService from "../../../services/CompromissoService";

import { ECamposIDEnum, ETipoCampoEnum, EModulo, ETelaEnum, returnarEnumDescricaoID } from "../../../enum";

import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import Carregando from "../../../components/Carregando";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import IFileUploadField from "../../../components/FileUpload/FileUploadField";
import CamposCapaDinamicos from "../../../components/Fluxo/CamposCapaDinamicos";
import CamposCadastroDinamico from "../../../components/Fluxo/CamposCadastroDinamicos";
import ICompromisso from "../../../interfaces/ICompromisso";
import moment from "moment";

interface IWorkflowModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  compromisso: ICompromisso;
  setRefresh(salvando: boolean): void;
  capa: boolean;
}

const HonorarioFluxoModal = ({ toggleModal, exibirModal, compromisso, setRefresh, capa }: IWorkflowModalProps) => {
  const { honorario } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const initialValues = {
    modulo: EModulo.XpayHonorario,
    fluxoFaseId: compromisso.fluxoFaseId || 0,
    categoriaId: honorario.categoriaId,
    processoId: honorario.processoId || null,
    contratoId: honorario.contratoId || null,
    consultaId: honorario.consultaId || null,
    tipoHonorarioId: honorario.tipoHonorarioId,
  };
  const adicionarShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Lista:
           if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[`${returnarEnumDescricaoID(configuracao.campoNome, configuracao.isXCampo)}`] = Yup.number().min(1, `${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
            schema[configuracao.campoNome.toLowerCase()] = Yup.date().required(`Campo ${configuracao.label} é obrigatório`).typeError(`Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Empresa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Empresa) {
            schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Pessoa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Advogado && !configuracao.somenteLeitura) {
            schema.advogadoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno && !configuracao.somenteLeitura) {
            schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.ParteContraria && !configuracao.somenteLeitura) {
            schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionarShema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Honorário",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Concluindo o compromisso...",
            showConfirmButton: false,
          });
          Swal.showLoading();

          // Salvar Honorario
          await HonorarioService.concluirCompromisso({
            ...values,
            xCampoValorList: carregarObjetoXCampos()
          });
        }

        setTimeout(async () => {

          // Salvar Compromisso
          await CompromissoService.concluirCompromisso({ observacao: values.observacao || 'Concluído!' }, compromisso.compromissoId, honorario.honorarioId);

          toggleModal();

          await Swal.fire({ icon: "success", title: `Compromisso concluído com sucesso`, timer: 3000 });

          Swal.hideLoading();

          formik.resetForm();

          setQuantidadeArquivosSelecionados(0);

          setRefresh(true);

        }, 2000);

      } catch (error: any) {
        Swal.hideLoading();
        setSubmitting(false);
        setConfiguracoesTela([]);
      }
    },
  });

  useEffect(() => {
    if (compromisso.compromissoId) carregarConfiguracaoTela();

    formik.setValues({
      ...formik.values,
      honorarioId: honorario.honorarioId,
      categoriaId: honorario.categoriaId,
      tipoHonorarioId: honorario.tipoHonorarioId,
      fluxoFaseId: compromisso.fluxoFaseId,
      modulo: EModulo.XpayHonorario
    });

  }, [honorario]);


  useEffect(() => {
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      if (configuracao.campoTipo == ETipoCampoEnum.Lista
        && configuracao.campoNome != ECamposIDEnum.TipoDocumento
        && configuracao.campoNome != ECamposIDEnum.TipoDocumentoCheckList
        && configuracao.campoNome != ECamposIDEnum.TipoDocumentoMinuta) {
        if (configuracao.obrigatorio) formik.setFieldValue(`${configuracao.campoNome.toLowerCase()}Id`, configuracao.obrigatorio ? 0 : eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`));
      } else {
        if (configuracao.obrigatorio) formik.setFieldValue(`${configuracao.campoNome.toLowerCase()}`, configuracao.obrigatorio ? "" : eval(`formik.values.${configuracao.campoNome.toLowerCase()}`));
      }
    });
  }, [configuracoesTela?.length]);



  const carregarObjetoXCampos = () => {
    let xCampoValorList = new Array<any>;
    configuracoesTela.forEach(configuracao => {

      if (configuracao?.isXCampo) {
        switch (configuracao.campoTipo) {
          case ETipoCampoEnum.AreaTexto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Texto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Data:
            xCampoValorList.push({ campoId: configuracao.campo, data: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Valor:
          case ETipoCampoEnum.Percentual:
            xCampoValorList.push({ campoId: configuracao.campo, valor: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Inteiro:
            xCampoValorList.push({ campoId: configuracao.campo, inteiro: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Lista:
                        xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: eval(`formik.values.${returnarEnumDescricaoID(configuracao.campoNome, true)}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Boolean:
            xCampoValorList.push({ campoId: configuracao.campo, boolean: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          default:
            break;
        }
      }
    });
    return xCampoValorList;
  }

  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);

      let resultado = await CompromissoService.obterTelaCompromisso(compromisso.compromissoId, ETelaEnum.TelaConcluirFase);

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setCarregandoConfiguracoesTela(false);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const renderImportadorArquivos = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumento)[0]?.label}:
            </label>
            <br></br>
            <Button
              onClick={() => setExibirModalImportacaoArquivos(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
              Selecione os arquivos da honorario
            </Button>
          </div>
          <IFileUploadField
            campo={58}
            modulo={EModulo.XpayHonorario}
            setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
            exibirModal={exibirModalImportacaoArquivos}
            toggleModal={toggleImportacaoArquivos}
            honorarioId={honorario.honorarioId}
            tipoHonorarioId={formik.values.tipoHonorarioId}
          />
        </div>
        <div className="row mt-2">
          <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
            {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
            {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
            {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
          </a>
        </div>
      </>
    );
  };

  const renderDefault = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        {capa ?
          <CamposCapaDinamicos
            configuracaoTela={configuracaoTela}
            configuracaoTelaLista={configuracoesTela}
            advogadosInterno={[]}
            partesProcesso={[]}
            formik={formik} />
          :
          <CamposCadastroDinamico
            configuracaoTelaLista={configuracoesTela}
            configuracaoTela={configuracaoTela}
            formik={formik}
          />
        }
      </>
    );
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {

      if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        component.push(renderImportadorArquivos());
        return;
      }

      component.push(renderDefault(configuracaoTela));
    });

    return <div className="row mt-12">{component}</div>;
  };

  const renderObservacao = () => {
    return (
      <div className="row mt-3">
        <div className="col-md-6">
          <label htmlFor="form-observacao" className="form-label fw-bolder text-orange">
            Observação:
          </label>
          <textarea {...formik.getFieldProps("observacao")} placeholder={"Informa uma observação"}
            className={clsx(
              "form-select",
              {
                "is-invalid": formik.touched.observacao && formik.errors.observacao,
              },
              {
                "is-valid": formik.touched.observacao && !formik.errors.observacao,
              }
            )}
          />
          {formik.touched.observacao && formik.errors.observacao && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span style={{ fontSize: "11px" }} role="alert">
                  {formik.errors.observacao}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Concluir: #{compromisso.compromissoId}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            {carregandoConfiguracoesTela ? <Carregando /> :
              <>
                {compromisso.fluxoFaseId ? renderCampos() : null}
                {renderObservacao()}
              </>}
          </div>


          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <div className="row mt-1" style={{ margin: "0 auto" }}>
              <div className="col-md-6">
                <button onClick={() => formik.submitForm()} disabled={!formik.isValid} type="submit" className="btn btn-orange">
                  Concluir
                </button>
              </div>
              <div className="col-md-6">
                <button onClick={() => toggleModal()} type="button" className="btn btn-danger float-end">
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default HonorarioFluxoModal;
