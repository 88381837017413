export enum ETipoCampoEnum {
  Default = "",
  Lista = "Lista",
  Audiencia = "Audiencia",
  Tabela = "Tabela",
  Data = "Data",
  Texto = "Texto",
  Valor = "Valor",
  AreaTexto = "AreaTexto",
  Boolean = "Boolean",
  Empresa = "Empresa",
  CentroCusto = "TipoCentroCusto",
  EmpresaRegulatorio = "EmpresaRegulatorio",
  EnumLista = "EnumLista",
  Inteiro = "Inteiro",
  ListaMulti = "ListaMulti",  
  Pessoa = "Pessoa",
  Percentual = "Percentual",
  Documento = "Documento",
  Numerico = "Numerico",
  xCampoOpcao = "xCampoOpcaoId",
  Imagem = "Imagem",
  ListaComLink = "ListaComLink",
}
