import IContrato from "../../../interfaces/IContrato";
import IProcesso from "../../../interfaces/IProcesso";
import { DynamicProvider } from "../../../services/EditorService";

export const enum EditorProvider {
  Contrato = "Contrato",
  Processo = "Processo",
  Procuracao = "Procuracao",
}

export type DynamicProviderUrl = Partial<{
  caller: EditorProvider
}>

export function getDynamicProvider(params: DynamicProviderUrl): DynamicProvider {
  const provider = params.caller ?? EditorProvider.Contrato;

  const storage = {
    [EditorProvider.Contrato]: "contrato_capa",
    [EditorProvider.Processo]: "processo_capa",
    [EditorProvider.Procuracao]: "procuracao_capa",
  };

  const response: DynamicProvider = {
    provider: { key: "", value: 0 },
  };

  const key = storage[provider];

  const data = sessionStorage.getItem(key)

  if (!data) {
    return response
  }

  if (provider === EditorProvider.Contrato) {
    const contractData = JSON.parse(data) as IContrato | null;
    response.provider = {
      key: "contratoId",
      value: contractData?.contratoId ?? 0,
    };
  }

  if (provider === EditorProvider.Processo) {
    const processData = JSON.parse(data) as IProcesso | null;
    response.provider = {
      key: "processoId",
      value: processData?.processoId ?? 0,
    };
  }

  if (provider === EditorProvider.Procuracao) {
    const procurationData = JSON.parse(data) as IProcuracao | null;
    response.provider = {
      key: "procuracaoId",
      value: procurationData?.procuracaoId ?? 0,
    };
  }

  return response;
}
