import { GrDocumentConfig, GrDocumentTime, GrDocumentVerified, GrDocumentMissing } from "react-icons/gr";
import { DadoNumerico, DadoTemporal, ModeloWidget } from "../../../components/dashboard";

export const WIDGETS: ModeloWidget[] = [
    {
        tipo: "card",
        props: {
            titulo: 'Quantidade Por Status - Período',
            tipo: 'compacto', // compacto || vertical
            dados: {
                etiquetas: {
                    metodo: 'envelopesEstatusPeriodo',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
                },
                dados: {
                    metodo: 'envelopesEstatusPeriodo',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
                },
            },
            sm: '12',
            md: '6',
            lg: '3',
            estilos: [
                {
                    background: 'linear-gradient(90deg, var(--primary-base) 0%, #a86574 100%)',	
                    borderColor: 'transparent',
                    color: '#fff',
                    icon: GrDocumentConfig,
                    iconBackgroundColor: 'transparent',
                    iconColor: '#fff',
                    iconGrow: true,
                    size: 6
                },
                {
                    background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',	
                    borderColor: 'transparent',
                    color: '#fff',
                    icon: GrDocumentTime,
                    iconBackgroundColor: 'transparent',
                    iconColor: '#fff',
                    iconGrow: true,
                    size: 6
                },
                {
                    background: 'linear-gradient(90deg, var(--primary-base) 0%, #a86574 100%)',	
                    borderColor: 'transparent',
                    color: '#fff',
                    icon: GrDocumentVerified,
                    iconBackgroundColor: 'transparent',
                    iconColor: '#fff',
                    iconGrow: true,
                    size: 6
                },
                {
                    background: 'linear-gradient(90deg, #a86574 0%, var(--secondary-base) 100%)',	
                    borderColor: 'transparent',
                    color: '#fff',
                    icon: GrDocumentMissing,
                    iconBackgroundColor: 'transparent',
                    iconColor: '#fff',
                    iconGrow: true,
                    size: 6
                }
            ]
        }
    },
    {
        tipo: "chart",
        props: {
            titulo: 'Envelopes - Dia',
            sm: '12',
            md:'6',
            lg:'3',
            dados: {
                etiquetas: {
                    metodo: 'cadastradoPorDia',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
                },
                dados: {
                    metodo: 'cadastradoPorDia',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
                },
            },
            tipoGrafico: 'line'
        }
    },
    {
        tipo: "chart",
        props: {
            titulo: 'Envelopes - Mês',
            sm: '12',
            md:'6',
            lg:'3',
            dados: {
                etiquetas: {
                    metodo: 'cadastradoPorMes',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
                },
                dados: {
                    metodo: 'cadastradoPorMes',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
                },
            },
            tipoGrafico: 'bar',
        }
    },
    {
        tipo: "chart",
        props: {
            titulo: 'Envelopes Assinados - Dia',
            sm: '12',
            md:'6',
            lg:'3',
            dados: {
                etiquetas: {
                    metodo: 'assinadosPorDia',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
                },
                dados: {
                    metodo: 'assinadosPorDia',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
                },
            },
            tipoGrafico: 'line'
        }
    },
    {
        tipo: "chart",
        props: {
            titulo: 'Envelopes Assinados - Mês',
            sm: '12',
            md:'6',
            lg:'4',
            dados: {
                etiquetas: {
                    metodo: 'assinadosPorMes',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.etiqueta)
                },
                dados: {
                    metodo: 'assinadosPorMes',
                    callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
                },
            },
            tipoGrafico: 'bar'
        }
    },
    {
        tipo: "chart",
        props: {
            titulo: 'Tempo Médio de Espera  - Dia',
            sm: '12',
            md:'6',
            lg:'4',
            dados: {
                etiquetas: {
                    metodo: 'tempoEsperaPorDia',
                    callback: (dados: DadoTemporal[]) => dados.map(dado => dado.etiqueta)
                },
                dados: {
                    metodo: 'tempoEsperaPorDia',
                    callback: (dados: DadoTemporal[]) => dados.map(dado => dado.media())
                },
            },
            tipoGrafico: 'line'
        }
    },
    {
        tipo: "chart",
        props: {
            titulo: 'Tempo Médio de Espera - Mês',
            sm: '12',
            md:'6',
            lg:'4',
            dados: {
                etiquetas: {
                    metodo: 'tempoEsperaPorMes',
                    callback: (dados: DadoTemporal[]) => dados.map(dado => dado.etiqueta)
                },
                dados: {
                    metodo: 'tempoEsperaPorMes',
                    callback: (dados: DadoTemporal[]) => dados.map(dado => dado.media())
                },
            },
            tipoGrafico: 'bar'
        }
    }
];