import { Fragment, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp, MdSync } from "react-icons/md";
import {
  RecorrenciaSelectButton,
  RecorrenciaOptions,
  RecorrenciaOption,
} from "./styles";
import { Recorrencia, recorrenciaOptions, useRecorrencia } from "../RecorrenciaContext";

export const RecorrenciaSelect = () => {
  const { recorrencia, setRecorrencia, setShowRecorrenciaModal } = useRecorrencia()
  const [showOptions, setShowOptions] = useState(false);
  
  const onClick = (option: Recorrencia) => {
    setRecorrencia(option);
    toggleShowOptions();

    if (option.value !== "none") {
      setShowRecorrenciaModal(true)
    }
  };

  const toggleShowOptions = () => setShowOptions((prev) => !prev);

  return (
    <Fragment>
      <RecorrenciaSelectButton
        type="button"
        onClick={toggleShowOptions}
        className="form-control"
      >
        <div className="recorrencia-icon">
          <MdSync />
        </div>

        <div className="recorrencia-value">
          <span>{recorrencia.title}</span>
        </div>

        <div className="arrow-icon">
          {showOptions ? <MdArrowDropUp /> : <MdArrowDropDown />}
        </div>
      </RecorrenciaSelectButton>

      {showOptions && (
        <RecorrenciaOptions>
          {recorrenciaOptions.map((option) => (
            <RecorrenciaOption
              type="button"
              onClick={() => onClick(option)}
              selected={recorrencia.title === option.title}
              key={option.title}
            >
              <span>{option.title}</span>
            </RecorrenciaOption>
          ))}
        </RecorrenciaOptions>
      )}
    </Fragment>
  );
};
