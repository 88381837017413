import IEmpresa from "../interfaces/IEmpresa";
import ITipoAto from "../interfaces/ITipoAto";
import IDespesa from "../interfaces/IDespesa";
import IContrato from "../interfaces/IContrato";
import IProcesso from "../interfaces/IProcesso";
import ITipoMarca from "../interfaces/ITipoMarca";
import IPropriedade from "../interfaces/IPropriedade";
import ITipoLicenca from "../interfaces/ITipoLicenca";
import ITipoDespesa from "../interfaces/ITipoDespesa";
import IAreaDireito from "../interfaces/IAreaDireito";
import ITipoConsulta from "../interfaces/ITipoConsulta";
import ITipoSociedade from "../interfaces/ITipoSociedade";
import ITipoProcuracao from "../interfaces/ITipoProcuracao";
import { IAtoSocietario } from "../interfaces/AtoSocietario";
import IModeloRelatorio from "../interfaces/IModeloRelatorio";
import ITipoPropriedades from "../interfaces/ITipoPropriedades";
import { EModulo } from "../enum/EModulos";
import { ETelaEnum } from "../enum/ETelaEnum";
import { EPorteEmpresa } from "../enum/EPorteEmpresa";
import { EStatusEmpresa } from "../enum/EStatusEmpresa";
import IFluxoFase from "../interfaces/IFluxo";
import IAudiencia from "../interfaces/IAudiencia";
import IObrigacao from "../interfaces/IObrigacao";
import IBloqueio from "../interfaces/IBloqueio";
import IDecisao from "../interfaces/IDecisao";
import IGarantia from "../interfaces/IGarantia";
import IDesdobramento from "../interfaces/IDesdobramento";
import IHonorario from "../interfaces/IHonorario";
import ITipoHonorario from "../interfaces/ITipoHonorario";
import IReembolso from "../interfaces/IReembolso";
import ITipoReembolso from "../interfaces/ITipoReembolso";
import IConsulta from "../interfaces/IConsulta";
import ITipoGarantia from "../interfaces/ITipoGarantia";
import ITipoBloqueio from "../interfaces/ITipoBloqueio";
import ITipoDecisao from "../interfaces/ITipoDecisao";
import ITipoObrigacao from "../interfaces/ITipoObrigacao";
import ITipoProtocolo from "../interfaces/ITipoProtocolo";
import IProtocolo from "../interfaces/IProtocolo";
import ITipoSeparadores from "../interfaces/ITipoSeparadores";
import ITipoComunicacao from "../interfaces/ITipoComunicacao";
import IComunicacao from "../interfaces/IComunicacao";
import IParteContraria from "../interfaces/IParteContraria";
import IPropostaAcordo from "../interfaces/IPropostaAcordo";
import ITipoAudiencia from "../interfaces/ITipoAudiencia";
import ITipoDesdobramento from "../interfaces/ITipoDesdobramento";
import ITipoPropostaAcordo from "../interfaces/ITipoPropostaAcordo";
import IXFinderDistribuicao from "../interfaces/IXFinderDistribuicao";
import IAdicionaProcessoRequest from "../interfaces/Requests/Processo/IAdicionaProcessoRequest";
import IFechamentoConfiguracaoParametrizacao from "../interfaces/IFechamentoConfiguracaoParametrizacao";
import IChangeLog from "../interfaces/IChangeLog";
import { lightTheme } from "../../theme";
import { DefaultTheme } from "styled-components";

export const areaDireitoInicialValues: IAreaDireito = {
  areaDireitoId: 0,
  checked: false,
  codigo: "",
  nome: "",
  status: "",
};

export const propriedadeInicialValues: IPropriedade = {
  codigo: "",
  descricao: "",
  documentoId: 0,
  modulo: EModulo.Default,
  nome: "",
  propriedadeId: 0,
  status: "",
  ordenacao: 0
};

export const tipoPropriedadeInicialValues: ITipoPropriedades = {
  modulo: EModulo.Default,
  telas: { cadastro: ETelaEnum.Default, capa: ETelaEnum.Default },
  nome: "",
  areaDireitoId: 0,
  tipoDespesaId: 0,
  tipoAtoId: 0,
  tipoContratoId: 0,
  tipoLicencaId: 0,
  tipoConsultaId: 0,
  tipoImovelId: 0,
  tipoProcuracaoId: 0,
  tipoMarcaId: 0,
  tipoSociedadeId: 0,
  fluxosFasesId: 0,
  propriedadeId: []
};


export const tipoSeparadorInicialValues: ITipoSeparadores = {
  modulo: EModulo.Default,
  telas: { cadastro: ETelaEnum.Default, capa: ETelaEnum.Default },
  nome: "",
  areaDireitoId: 0,
  tipoDespesaId: 0,
  tipoAtoId: 0,
  tipoContratoId: 0,
  tipoLicencaId: 0,
  tipoConsultaId: 0,
  tipoImovelId: 0,
  tipoProcuracaoId: 0,
  tipoMarcaId: 0,
  tipoSociedadeId: 0,
  fluxosFasesId: 0,
  separadorId: []
};

export const fluxoFaseInicialValues: IFluxoFase = {
  fluxoId: 0,
  fluxoFaseId: 0,
  faseInicial: false,
  modulo: 0,
  nome: "",
  descricao: "",
  codigo: "",
  jsonContent: "",
  fases: []
};

export const tipoSociedadeInicialValues: ITipoSociedade = {
  tipoSociedadeId: 0,
  codigo: "",
  nome: "",
  status: "",
};

export const tipoMarcaInicialValues: ITipoMarca = {
  tipoMarcaId: 0,
  codigo: "",
  nome: "",
  status: "",
};

export const tipoPropostaAcordoInicialValues: ITipoPropostaAcordo = {
  tipoPropostaAcordoId: 0,
  nome: "",
  categoriaNome: "",
  opcaoNome: "",
  categoriaId: 0,
  moduloNome: "",
  modulo: EModulo.Default,
  opcaoId: 0
};

export const despesaInicialValues: IDespesa = {
  despesaId: 0,
  categoriaId: 0,
  categoriaNome: "",
  modulo: 0,
  tipoDespesaId: 0,
  tipoDespesaNome: "",
  objetoTipo: "",
  processoId: 0,
  empresaId: 0,
  atoSocietarioId: 0,
  clienteId: 0,
  clienteNome: "",
  descricao: "",
  dataEfetiva: new Date(),
  dataVencimento: new Date(),
  dataFatal: new Date(),
  formaPagamentoId: 0,
  formaPagamentoNome: "",
  codigoBarras: "",
  bancoId: 0,
  bancoNome: "",
  agencia: "",
  conta: "",
  moedaId: 0,
  moedaNome: "",
  statusDespesaId: 0,
  statusDespesaNome: "",
  valorPrincipal: 0,
  valorCorrecao: 0,
  valorJuros: 0,
  valorMulta: 0,
  valorHonorarios: 0,
  valorTotal: 0,
  dataCadastro: new Date(),
  codigo: "",
  xCampoValorList: [],
  favoritoId: 0,
};

export const tipoDespesaInicialValues: ITipoDespesa = {
  tipoDespesaId: 0,
  categoriaId: 0,
  codigo: "",
  nome: "",
  opcaoNome: "",
  moduloNome: "",
  modulo: 0,
  categoriaNome: "",
  status: "",
  checked: false,
};

export const tipoAtoInicialValues: ITipoAto = {
  tipoAtoId: 0,
  codigo: "",
  nome: "",
  status: "",
};

export const tipoProcuracaoInicialValues: ITipoProcuracao = {
  tipoProcuracaoId: 0,
  codigo: "",
  nome: "",
  status: "",
};

export const tipoConsultaInicialValues: ITipoConsulta = {
  tipoConsultaId: 0,
  codigo: "",
  nome: "",
  status: "",
};

export const tipoImovelInicialValues = {
  tipoImovelId: 0,
  checked: false,
  codigo: "",
  nome: "",
  status: "",
};

export const tipoContratoInicialValues = {
  tipoContratoId: 0,
  categoria: 0,
  checked: false,
  codigo: "",
  nome: "",
  status: "",
};

export const tipoLicencaInicialValues: ITipoLicenca = {
  tipoLicencaId: 0,
  nome: "",
  codigo: "",
  status: "",
  checked: false,
};

export const parteContrariaInitial: IParteContraria = {
  parteContrariaId: 0,
  tipoParteContrariaId: 0,
  controller: 'processo',
  modulo: EModulo.Default,
  nome: "",
  nomeSocial: "",
  documento: "",
  tipoDocumento: "",
  principal: false,
  pessoaId: 0,
  formaParticipacaoId: 0,
  papelSignatarioId: 0,
  dataAdmissao: null,
  dataDemissao: null,
  dataNascimento: null,
  matricula: "",
  email: "",
  estadoCivilId: 0,
  nacionalidade: "",
  telefone: "",
  celular: "",
  rg: "",
  rgOrgao: "",
  orgaoRegistroId: 0,
  pisPasep: "",
  cargoId: 0,
  motivoDesligamentoId: 0,
  statusFolhaId: 0,
  tipoCentroCustoId: 0,
  ultimoSalario: 0,
  moedaId: 0,
  departamentoId: 0,
  codigo: "",
  ctps: "",
  observacoesDesligamento: "",
  observacoesGerais: "",
  tipoContratacao: 0,
  empresaTerceira: "",
  numeroContrato: "",
  regimeContratacao: 0,
  empresaContratante: "",
  observacoesContratacao: "",
  logradouro: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  cep: "",
  estadoId: 0,
  cidadeId: 0,
  arquivosParaImportar: [],
  xCampoValorList: []
};

export const processoInitialValues: IProcesso = {
  processoId: 0,
  areaDireitoId: 0,
  areaDireitoNome: "",
  cidadeNome: "",
  clienteId: 0,
  escritorioNome: "",
  estadoNome: "",
  estadoUF: "",
  favoritoId: 0,
  numeroProcesso: "",
  processoParteContraria: [],
  statusProcessoNome: "",
  statusProcessoId: 0,
  tipoAcaoNome: "",
  licencaId: 0,
  imovelId: 0,
  datadistribuicao: ''
};

export const contratoInitialValues: IContrato = {
  contratoId: 0,
  clienteId: 0,
  pastaId: 0,
  tipoContratoId: 0,
  tipoContratoNome: "",
  statusContrato: "",
  statusPasta: "",
  numeroContrato: "",
  nomePasta: "",
  objetoContrato: "",
  dataInicioVigencia: "",
  periodicidadeId: 0,
  periodicidadeNome: "",
  dataFimVigencia: "",
  observacoesContrato: "",
  departamentoId: 0,
  departamentoNome: "",
  gestorId: 0,
  gestorNome: "",
  urgente: false,
  isNovaPasta: false,
  restrito: false,
  moedaId: 0,
  moedaNome: "",
  indiceId: 0,
  indiceNome: "",
  valorTotal: 0,
  dataCadastro: new Date(),
  dataAprovacao: new Date(),
  dataAssinatura: new Date(),
  codigo: "",
  favoritoId: 0,
  contratoParteContraria: [],
  contratoEmpresas: [],
};

export const marcaInitialValues: IMarca = {
  clienteId: 0,
  favoritoId: 0,
  marcaEmpresas: [],
  marcaId: 0,
  marcaParteContraria: [],
  statusMarcaId: 0,
  statusMarcaNome: "",
  tipoMarcaId: 0,
  tipoMarcaNome: ""
};

export const atoSocietarioInitialValues: IAtoSocietario = {
  atoRegistradoJuntaId: 0,
  publicacaoAtoId: 0,
  atoSocietarioId: 0,
  clienteId: 0,
  codigo: "",
  composicaoMesa: "",
  dataArquivamento: null,
  dataAto: null,
  dataCadastro: null,
  dataPublicacao: null,
  dataRegistro: null,
  empresaId: 0,
  textoMateriasDeliberadas: "",
  numeroArquivamento: "",
  numeroRegistro: "",
  observacoesAto: "",
  publicacao: "",
  tipoAtoId: 0,
  usuarioIdCadastro: 0,
  statusAtoSocietarioId: 0,
  statusAtoId: 0,
  statusAtoNome: "",
  atoRegistradoJuntaNome: "",
  publicacaoAtoNome: ""
};

export const procuracaoInitialValues: IProcuracao = {
  clienteId: 0,
  favoritoId: 0,
  pastaId: 0,
  numeroProcuracao: "",
  procuracaoEmpresas: [],
  procuracaoId: 0,
  procuracaoParteContraria: [],
  statusProcuracaoId: 0,
  statusProcuracaoNome: "",
  tipoProcuracaoId: 0,
  tipoProcuracaoNome: ""
};

export const consultaInitialValues: IConsulta = {
  clienteId: 0,
  favoritoId: 0,
  consultaEmpresas: [],
  consultaId: 0,
  consultaParteContraria: [],
  statusConsultaId: 0,
  statusConsultaNome: "",
  tipoConsultaId: 0,
  tipoConsultaNome: ""
};

export const imovelInitialValues: IImovel = {
  clienteId: 0,
  favoritoId: 0,
  imovelEmpresas: [],
  imovelId: 0,
  imovelParteContraria: [],
  statusImovelId: 0,
  statusImovelNome: "",
  tipoImovelId: 0,
  tipoImovelNome: "",
  nomeImovel: ""
};

export const licencaInitialValues: ILicenca = {
  licencaId: 0,
  tipoLicencaId: 0,
  tipoServicoId: 0,

  tipoLicencaNome: "",
  tipoServicoNome: "",
  numeroLicenca: "",
  canal: "",
  cidadeNome: "",
  dataCadastro: "",
  dataEmissao: "",
  dataVencimento: "",
  fistel: "",
  numeroLicencaDiacritics: "",
  observacoesLicenca: "",
  orgaoResponsavelNome: "",
  restrito: false,
  statusLicenca: "",

  favoritoId: 0,
  clienteId: 0,
  orgaoResponsavelId: 0,
  statusLicencaId: 0,

  licencaEmpresas: [],
  checked: false,
};

export const processoCadastroInicialValues: IAdicionaProcessoRequest = {
  processoId: 0,
  numeroprocesso: "",
  pasta: "",
  descricaoobjetoacao: "",
  observacoesprocesso: "",
  resumoprocesso: "",
  valorcausa: 0,
  codigo: "",
  clienteId: 0,

  areaDireitoId: 0,
  subareadireitoId: 0,
  classificacaoId: 0,
  ambitoId: 0,
  tipoContingenciaId: 0,
  tipodocumentoId: 0,
  esferaId: 0,
  statusprocessoId: 0,
  motivoencerramentoId: 0,
  instanciaId: 0,
  faseProcessualId: 0,
  tipoAcaoId: 0,
  riscoId: 0,
  estadoId: 0,
  paisId: 0,
  formaparticipacaoId: 0,
  juizId: 0,

  cidadeId: 0,
  escritorioId: 0,

  datadistribuicao: null,
  datarecebimento: null,
  prazofatal: null,
  dataencerramento: null,
  dataencerramentocadastro: null,
  databaixaprovisoria: null,
  databaixaprovisoriacadastro: null,

  advogadoInternoList: [],
  advogadoList: [],
  empresasList: [],
  partesContrariasList: [],
  centrosCusto: [],
  xCampoValorList: [],

  audienciaList: [],
  quantidadeArquivosSelecionados: 0,
  quantidadeDocumentosCheckList: 0
};

export const empresaInitialValues: Partial<IEmpresa> = {
  codigo: '',
  empresaId: 0,
  nome: '',
  nomeFantasia: '',
  status: '',
  checked: false,
  formaParticipacaoId: 0,
  papelSignatarioId: 0,
  representantes: [],
  principal: undefined
}


export const empresaFilialInitialValues: IEmpresa = {
  empresaId: 0,
  tipoSociedadeId: 0,
  nome: "",
  nomeFantasia: "",
  gestaoProcessoId: 0,
  gestaoProcessoNome: "",
  percentual: 0,
  percentualMask: "",
  provisao: 0,
  provisaoAtualizada: 0,
  codigo: "",
  status: "",
  clienteId: 0,
  clienteNome: "",
  grupoEconomicoId: 0,
  grupoEconomicoNome: "",
  tipoEstabelecimento: 0,
  pessoaId: 0,
  numeroDocumento: "",
  razaoSocial: "",
  tipoEmpresaId: 0,
  objetoSocial: "",
  inscricaoEstadual: "",
  inscricaoMunicipal: "",
  orgaoRegistroId: 0,
  descricaoOrgao: "",
  nire: "",
  dataConstituicao: "",
  dataPrimeiroRegistro: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  cep: "",
  cidadeId: 0,
  porteEmpresa: EPorteEmpresa.DEMAIS,
  valorCapitalSocial: 0,
  valorCapitalIntegralizado: 0,
  valorCapitalAIntegralizar: 0,
  moedaId: 0,
  observacoesEmpresa: "",
  statusEmpresa: EStatusEmpresa.Ativa,
  telefone: "",
  email: "",
  dataSituacao: "",
  motivoSituacaoId: 0,
  atividadeEmpresaPrincipalId: 0,
  empresasAtividadesEmpresa: [],
  sociosAdministradores: [],
  representantes: [],
  dataCadastro: "",
  usuarioIdCadastro: 0,
  papelSignatarioId: 0,
  papelSignatario: "",
  formaParticipacao: "",
  formaParticipacaoId: 0,
  checked: false,
  principal: false,
  favoritoId: 0,
  pracaoNome: "",
  bandeiraNome: "",
  atividadeEmpresaPrincipal: [],
};


export const modeloRelatorioInitialValues: IModeloRelatorio = {
  modeloRelatorioCampos: [],
  modeloRelatorioId: 0,
  modulo: 0,
  nome: "",
  status: "",
  tipo: 0
}

export const obrigacaoInicialValues: IObrigacao = {
  obrigacaoId: 0,
  tipoObrigacaoId: 0,
  modulo: EModulo.Default,
  processoId: 0,
  origemObrigacaoId: 0,
  descricaoObrigacao: "",
  prazo: new Date(),
  possuiMulta: false,
  periodicidadeId: 0,
  valorMultaBase: 0,
  descricaoMulta: "",
  dataBaseMulta: new Date(),
  dataIntimacao: new Date(),
  dataConclusao: new Date(),
  concluida: false,
  codigo: "",
  clienteId: 0,
  dataCadastro: new Date(),
  statusObrigacaoNome: '',
  tipoObrigacaoNome: '',
  usuarioIdCadastro: 0,
  favoritoId: 0,
}

export const bloqueioInicialValues: IBloqueio = {
  bloqueioId: 0,
  modulo: 0,
  processoId: 0,
  descricao: "",
  dataBloqueio: new Date(),
  dataDesbloqueio: new Date(),
  banco_id: 0,
  agencia: "",
  conta: "",
  moedaId: "",
  valor: 0,
  usuarioIdCadastro: 0,
  codigo: "",
  dataCadastro: new Date(),
  statusBloqueioNome: "",
  clienteId: 0,
  favoritoId: 0

}

export const decisaoInicialValues: IDecisao = {
  modulo: 0,
  tipoDecisaoId: 0,
  processoId: 0,
  teorDecisao: "",
  dataDecisao: new Date(),
  prazoFatal: new Date(),
  possuiRecurso: false,
  detalhesRecurso: "",
  possuiObrigacao: false,
  detalhesObrigacao: "",
  codigo: "",
  decisaoId: 0,
  clienteId: 0,
  usuarioIdCadastro: 0,
  dataCadastro: new Date(),
  status: 0,
  statusDecisaoNome: "",
  favoritoId: 0,
}

export const garantiaInicialValues: IGarantia = {
  tipoGarantiaId: 0,

  garantiaId: 0,
  modulo: 0,
  categoriaId: 0,

  processoId: 0,
  areaDireitoId: 0,
  numeroProcesso: "",

  imovelId: 0,
  tipoImovelId: 0,

  tela: 0,

  descricao: "",
  favoritoId: 0,
  dataInicioVigencia: null,
  dataVencimento: null,
  dataFatal: null,
  dataGarantia: null,
  formaPagamentoId: 0,
  codigoBarras: "",
  bancoId: 0,
  agencia: "",
  conta: "",
  moedaId: 0,
  valorPrincipal: 0,
  indiceId: 0,
  juros: 0,
  numeroApolice: "",
  matriculaImovel: "",
  cartorio: "",
  corretora: "",
  codigo: "",
  clienteId: 0,
  usuarioIdCadastro: 0,
  dataCadastro: null,
  statusGarantiaNome: "",
  tipoGarantiaNome: "",
  valorAtualizado: 0,
  valorLevantadoEmpresaAtualizado: 0,
  valorLevantadoParteContrariaAtualizado: 0,
  saldoAtualizado: 0,
  levantamentos: []
}

export const tipoGarantiaInicialValues: ITipoGarantia = {
  tipoGarantiaId: 0,
  nome: "",
  opcaoNome: "",
  codigo: "",
  status: "",
  opcaoId: 0,
  categoriaNome: "",
  moduloNome: "",
  categoriaId: 0,
  modulo: EModulo.Default
}

export const tipoBloqueioInicialValues: ITipoBloqueio = {
  tipoBloqueioId: 0,
  nome: "",
  opcaoNome: "",
  codigo: "",
  status: "",
  opcaoId: 0,
  categoriaNome: "",
  moduloNome: "",
  categoriaId: 0,
  modulo: EModulo.Default
}

export const tipoDesdobramentoInicialValues: ITipoDesdobramento = {
  tipoDesdobramentoId: 0,
  nome: "",
  opcaoNome: "",
  codigo: "",
  status: "",
  opcaoId: 0,
  categoriaNome: "",
  moduloNome: "",
  categoriaId: 0,
  modulo: EModulo.Default
}

export const tipoObrigacaoInicialValues: ITipoObrigacao = {
  tipoObrigacaoId: 0,
  nome: "",
  opcaoNome: "",
  codigo: "",
  status: "",
  opcaoId: 0,
  categoriaNome: "",
  moduloNome: "",
  categoriaId: 0,
  modulo: EModulo.Default
}


export const tipoComunicacaoInitialValues: ITipoComunicacao = {
  tipoComunicacaoId: 0,
  nome: "",
  opcaoNome: "",
  codigo: "",
  status: "",
  opcaoId: 0,
  categoriaNome: "",
  moduloNome: "",
  categoriaId: 0,
  modulo: EModulo.Default
}

export const comunicacaoInitialValues: IComunicacao = {
  comunicacaoId: 0,
  dataCadastro: null,
  statusComunicacaoNome: "",

  tipoComunicacaoId: 0,
  tipoComunicacaoNome: "",

  nome: '',
  tela: ETelaEnum.Default, // Replace VALUE with the appropriate value from ETelaEnum

  processoId: 0,
  areaDireitoId: 0,
  categoriaId: 0,

  modulo: EModulo.Default,
  dataComunicacao: null, // Or null if preferred
  descricao: "",
  codigo: "",
  status: "",
  favoritoId: 0,
}

export const tipoDecisaoInicialValues: ITipoDecisao = {
  tipoDecisaoId: 0,
  nome: "",
  opcaoNome: "",
  codigo: "",
  status: "",
  opcaoId: 0,
  categoriaNome: "",
  moduloNome: "",
  categoriaId: 0,
  modulo: EModulo.Default,
}

export const tipoProtocoloInicialValues: ITipoProtocolo = {
  tipoProtocoloId: 0,
  nome: "",
  opcaoNome: "",
  codigo: "",
  status: "",
  opcaoId: 0,
  categoriaNome: "",
  moduloNome: "",
  categoriaId: 0,
  modulo: EModulo.Default,
}

export const desdobramentoInicialValues: IDesdobramento = {
  desdobramentoId: 0,
  tipoDesdobramentoId: 0,
  modulo: EModulo.Default,
  clienteId: 0,
  processoId: 0,
  resumo: "",
  dataDistribuicao: null,
  dataProtocolo: null,
  instanciaId: 0,
  cidadeId: 0,
  numero: "",
  dataEncerramento: null,
  motivoEncerramentoId: 0,
  responsavelDesdobramento: 0,
  statusDesdobramentoNome: "",
  codigo: "",
  tipoDesdobramentoNome: "",
  instanciaNome: "",
  favoritoId: 0,
}

export const propostaAcordoInicialValues: IPropostaAcordo = {
  modulo: 0,
  statusPropostaAcordoId: 0,
  tipoPropostaAcordoId: 0,
  validadeProposta: new Date(),
  valorAcordo: 0,
  descricao: "",

  processoId: 0,
  areaDireitoId: 0,

  imovelId: 0,
  tipoImovelId: 0,

  favoritoId: 0,

  dataPagamento: new Date(),
  multaAtraso: false,
  valorMultaAtraso: 0,
  periodicidadeId: 0,
  codigo: "",
  propostaAcordoId: 0,
  clienteId: 0,
  usuarioIdCadastro: 0,
  dataCadastro: new Date(),
  statusPropostaAcordoNome: ""
}

export const honorarioInicialValues: IHonorario = {
  honorarioId: 0,
  modulo: 0,
  categoriaId: 0,
  categoriaNome: "",
  tipoHonorarioId: 0,
  tipoHonorarioNome: "",
  objetoTipo: "",
  processoId: 0,
  empresaId: 0,
  atoSocietarioId: 0,
  clienteId: 0,
  clienteNome: "",
  descricao: "",
  competencia: new Date(),
  moedaId: 0,
  moedaNome: "",
  statusId: 0,
  statusNome: "",
  valorTotal: 0,
  numeroParcela: 0,
  escritorioId: 0,
  tipoCentroCustoId: 0,
  xpayFechamentoId: 0,
  dataCadastro: new Date(),
  codigo: "",
  xCampoValorList: [],
  tela: ETelaEnum.Default,
};

export const tipoHonorarioInicialValues: ITipoHonorario = {
  tipoHonorarioId: 0,
  categoriaId: 0,
  codigo: "",
  nome: "",
  opcaoNome: "",
  moduloNome: "",
  modulo: 0,
  categoriaNome: "",
  status: "",
  checked: false,
};

export const reembolsoInicialValues: IReembolso = {
  reembolsoId: 0,
  modulo: 0,
  categoriaId: 0,
  categoriaNome: "",
  tipoReembolsoId: 0,
  tipoReembolsoNome: "",
  objetoTipo: "",

  processoId: 0,
  areaDireitoId: 0,

  contratoId: 0,
  tipoContratoId: 0,

  imovelId: 0,
  tipoImovelId: 0,

  empresaId: 0,
  atoSocietarioId: 0,
  clienteId: 0,
  clienteNome: "",
  descricao: "",
  competencia: new Date(),
  moedaId: 0,
  moedaNome: "",
  statusId: 0,
  statusNome: "",
  valorTotal: 0,
  escritorioId: 0,
  tipoCentroCustoId: 0,
  xpayFechamentoId: 0,
  dataCadastro: new Date(),
  codigo: "",
  xCampoValorList: [],
};



export const protocoloInitialValues: IProtocolo = {
  xfinderProtocoloId: 0,
  tipoProtocoloId: 0,
  categoriaId: 0,
  favoritoId: 0,
  tela: ETelaEnum.Default,
  modulo: EModulo.Default,

  processoId: 0,
  areaDireitoId: 0,

  descricao: "",
  codigo: "",
  numeroProcesso: "",
  status: 0,

  tipoProtocoloTribunal: "",
  statusProtocoloId: 0,
  statusProtocoloNome: '',
  processoEmpresaId: 0,
  documentosId: [],
  xCampoValorList: [],
};

export const tipoParteContrariaInitialValues: any = {
  tipoParteContrariaId: 0,
  categoriaId: 0,
  codigo: "",
  nome: "",
  opcaoNome: "",
  moduloNome: "",
  modulo: 0,
  categoriaNome: "",
  status: "",
  checked: false,
};

export const tipoReembolsoInicialValues: ITipoReembolso = {
  tipoReembolsoId: 0,
  categoriaId: 0,
  codigo: "",
  nome: "",
  opcaoNome: "",
  moduloNome: "",
  modulo: 0,
  categoriaNome: "",
  status: "",
  checked: false,
};


export const audienciaInicialValues: IAudiencia = {
  audienciaId: 0, // Default value, should be replaced with actual ID
  modulo: {} as EModulo, // Assuming EModulo is an object with its own structure
  categoriaId: 0, // Default value, should be replaced with actual category ID
  categoriaNome: '', // Default value, should be replaced with actual category name
  processoId: undefined, // Optional, can be undefined or assigned a default value
  tipoAudienciaId: 0, // Default value, should be replaced with actual tipo audiencia ID
  tipoAudienciaNome: '', // Default value, should be replaced with actual tipo audiencia name
  resultadosudienciaId: 0, // Default value, should be replaced with actual resultado audiencia ID
  resultadoAudienciaNome: '', // Default value, should be replaced with actual resultado audiencia name
  resultadosentencaId: 0, // Default value, should be replaced with actual resultado sentenca ID
  resultadoSentencaNome: '', // Default value, should be replaced with actual resultado sentenca name
  clienteId: 0, // Default value, should be replaced with actual client ID
  clienteNome: '', // Default value, should be replaced with actual client name
  observacao: '', // Default value, can be an empty string
  dataleitura: new Date(), // Default to the current date and time
  datasentenca: new Date(), // Default to the current date and time
  dataaudiencia: new Date(), // Default to the current date and time
  status: '', // Default value, should be replaced with actual status
  dataCadastro: new Date(), // Default to the current date and time
  codigo: '', // Default value, should be replaced with actual code,
  favoritoId: 0,
  xCampoValorList: [] // Default to an empty array
};

export const tipoAudienciaInicialValues: ITipoAudiencia = {
  tipoAudienciaId: 0,
  categoriaId: 0,
  codigo: "",
  nome: "",
  opcaoNome: "",
  moduloNome: "",
  modulo: 0,
  categoriaNome: "",
  status: "",
  checked: false,
  opcaoId: 0,
};



export const xFinderDistribuicaoInitialValues: IXFinderDistribuicao = {
  xFinderDistribuicaoId: 0,
  escritorioId: 0,
  cnjUnidadeOrigemId: 0,
  status: "",
  dataDistribuicao: "",
  nomePesquisa: "",
  tribunal: "",
  numeroProcesso: "",
  tipoOcorrencia: "",
  reu: "",
  autor: "",
  forum: "",
  assuntos: "",
  vara: "",
  cidade: "",
  uf: "",
  valor: 0,
  advogadoAutor: "",
  dataAudiencia: "",
  processoEletronico: "",
  linkAcessoInicial: "",
  codCliente: "",
  classificacaoAudiencia: "",
  dataCadastro: "",
  xFinderOrigem: "",
  xProcessoId: "",
  xFinderDistribuicaoDocumentos: []
};

export const urlLogoInitialValues = '';

export const selectedParametrizacoesInitialValues: IFechamentoConfiguracaoParametrizacao = {
  fechamentoConfiguracaoId: 0,
  areasDireito: [{}],
  empresas: [{}]
};

export const selectedChangeLogInitialValues: IChangeLog = {
  changeLogId: 0,
  status: 0,
  versao: "",
  descricao: ""
};

export const selectedThemeInitialValues: DefaultTheme = lightTheme;