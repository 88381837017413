import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { ETelaEnum } from "../../../enum/ETelaEnum";
import { EModulo } from "../../../enum";
import { faEllipsisH, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../Styles/icons.css";

const SolucoesPage = () => {
  const { setTela, areaDireito, setTipoPropriedade , setTipoSeparador} = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (areaDireito.areaDireitoId === 0) navigate("/ConfiguracaoTela/AreaDireito");
  }, [areaDireito, navigate]);

  const popover = () => (
    <Popover id="popover-basic" style={{ marginLeft: "-20px" }}>
      <Popover.Body style={{ cursor: "pointer" }}>
        <p>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Cadastro</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                onClick={() => {
                  setTela(ETelaEnum.TelaCadastroProcesso);
                  navigate("/Processo/ConfiguracaoTela/Cadastro");
                }}
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-input"></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Capa</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                onClick={() => {
                  setTela(ETelaEnum.TelaCapaProcesso);
                  navigate("/Processo/ConfiguracaoTela/Capa");
                }}
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
              </Button>
            )}
          </OverlayTrigger>
        </p>

        <p>
          <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Propriedades</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                onClick={() => {
                  setTipoPropriedade(
                    {
                      telas: { cadastro: ETelaEnum.TelaCapaProcesso, capa: ETelaEnum.TelaCapaProcesso },
                      modulo: EModulo.AreaDireito,
                      areaDireitoId: areaDireito.areaDireitoId,
                      nome: areaDireito.nome,
                      propriedadeId: []
                    }
                  );
                  navigate("/Configuracao/Propriedade");
                }}
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}><FontAwesomeIcon className="mx-2 text-orange" icon={faEllipsisH} /></span>
              </Button>
            )}
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Separadores</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                onClick={() => {
                  setTipoSeparador(
                    {
                      telas: { cadastro: ETelaEnum.TelaCapaProcesso, capa: ETelaEnum.TelaCapaProcesso },
                      modulo: EModulo.AreaDireito,
                      areaDireitoId: areaDireito.areaDireitoId,
                      nome: areaDireito.nome,
                      separadorId: []
                    }
                  );
                  navigate("/Configuracao/Separador");
                }}
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}><FontAwesomeIcon className="mx-2 text-orange" icon={faLayerGroup} /></span>
              </Button>
            )}
          </OverlayTrigger>
        </p>
      </Popover.Body>
    </Popover >
  );
  return (
    <>
      <LayoutPageTitle title={"Clique no ícone e selecione a tela que você deseja configurar."}>
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover()}>
          <a style={{ cursor: "pointer" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageTitle>
    </>
  );
};

export default SolucoesPage;
