import { useContext, useEffect, useRef, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler, faUser } from '@fortawesome/free-solid-svg-icons';

import { formartDateDDMMYYYY } from '../../../../../utils/Utils';
import { EStatusRepresentante, ETipoCampoEnum } from '../../../../../enum';

import GridPadrao from '../../../../../components/GridPadrao';
import ModalSolucao from '../../../componentes/ModalSolucao';

import { IConselho, IGrupoConselho } from '../Interfaces';
import { IPessoa } from '../../../../../interfaces/IPessoa';
import { OrangeInterfaceContext } from '../../../../../interfaces/Contexts/OrangeInterfaceContext';

import { useDisclosure } from '../../../hooks/useDisclosure';
import { OrangeContext } from '../../../../../contexts/OrangeProvider';
import { ModalCustomBody } from '../../../componentes/ModalCustomBody';
import { EmpresaSolucaoLayout } from '../../../componentes/EmpresaSolucaoLayout';
import { Modelo } from '../../../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo';


export const ConselhoPage = () => {
	const mountedRef = useRef(true);

	const {empresa, } = useContext<OrangeInterfaceContext>(OrangeContext);

	const [exibirModalConselheiros, setExibirModalConselheiros] = useState(false);
	
	const modalActions = useDisclosure()
	const modalGrupoActions = useDisclosure()

	const [mockConselheiros, setMockConselheiros] = useState<IConselho[]>([
		{
			cpf: '228.164.958-67',
			dataPosse: new Date(),
			dataVencimentoMandato: new Date(),
			nome: 'Ataide Bastos',
			grupo: {id: 1, nome: 'Grupo 1', status: EStatusRepresentante.Ativo },
			grupoId: 1,
			tipoCargo: { cargoId: 1, codigo: '1', nome: 'ADS', status: 'Ativo' }
		}
	]);

	const [mockGrupoConselho, setMockGrupoConselho ] = useState<IGrupoConselho[]>([{
		id: 1,
		nome: 'Grupo 1',
		status: EStatusRepresentante.Ativo
	}]);
	
	const [ grupoSelecionado, setGrupoSelecionado] = useState<IGrupoConselho>();

	const [ conselhoDoGrupoSelecionado, setConselhoDoGrupoSelecionado ] = useState<IConselho[]>([]);

	const botaoAdicionarDaTela = (
		<div>
			<button
				className='btn btn-md btn-orange m-1'
				onClick={() => {
					modalGrupoActions.handleOpen()
				}}
			> Adicionar Grupo </button>
		</div>
	)
	
	const botoesAcoesGrid = ( grupoClicado: IGrupoConselho) => {
		return (
			<div>
					<FontAwesomeIcon title="Adicionar Conselho" onClick={ () => {
							modalActions.handleOpen();
							setGrupoSelecionado(grupoClicado);
							
					}} style={{ fontWeight: 'normal', cursor: 'pointer'}} size='2x' className='mx-2 text-orange' icon={faUser} />
					<FontAwesomeIcon title="Ver Conselho" onClick={() => {
							setGrupoSelecionado(grupoClicado);				
							setExibirModalConselheiros(true);
					}} style={{ fontWeight: 'normal', cursor: 'pointer'}} size='2x' className='mx-2 text-orange' icon={faPencilRuler} />
			
			</div>
		)
	}

	const listaDeCampos: Modelo[] = [
		{
			nome: 'cpf',
			label: 'CPF',
			obrigatorio: true,
			help: 'CPF',
			tipo: ETipoCampoEnum.Documento,
			valor: '',
			campoNome: 'cpf',
			campo: 999,
			controller: '',
			entidade: '',
			isXCampo: false,
			ordenacao: 0,
			somenteLeitura: false
		},
		{
			nome: 'nome',
			label: 'Nome',
			obrigatorio: true,
			help: '',
			tipo: ETipoCampoEnum.Texto,
			valor: '',
			campoNome: 'nome',
			campo: 999,
			controller: '',
			entidade: '',
			isXCampo: false,
			ordenacao: 0,
			somenteLeitura: false
		},
		{
			nome: 'cargo',
			label: 'Cargo',
			obrigatorio: true,
			help: '',
			tipo: ETipoCampoEnum.Lista,
			valor: 0,
			campoNome: 'cargo',
			campo: 999,
			controller: '',
			entidade: 'cargo',
			isXCampo: false,
			ordenacao: 0,
			somenteLeitura: false

		}
	]

	const listaDeCamposGrupo: Modelo[] = [
		{
			nome: 'nome',
			label: 'Nome',
			obrigatorio: true,
			help: 'nome',
			tipo: ETipoCampoEnum.Texto,
			campoNome: 'nome',
			campo: 999,
			controller: '',
			entidade: '',
			isXCampo: false,
			ordenacao: 0,
			somenteLeitura: false
		},
		{
			nome: "status",
			label: "Status",
			obrigatorio: false,
			help: '',
			tipo: ETipoCampoEnum.EnumLista,
			campoNome: 'status',
			camposRetornar: ['Ativo', 'Inativo'],
			campo: 999,
			controller: '',
			entidade: '',
			isXCampo: false,
			ordenacao: 0,
			somenteLeitura: false
		}
	]

	const colunas: TableColumn<IConselho>[] = [
		{
			name: 'CPF',
      sortField: 'cpf',
      selector: (row: IConselho) => row.cpf,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
		},
		{
			name: 'Nome',
			sortField: 'nome',
			selector: (row: IConselho) => row.nome,
			sortable: true,
			ignoreRowClick: true,
		},
		{
			name: 'Data da Posse',
			sortField: 'dataPosse',
			selector: (row: IConselho) => row.dataPosse ? formartDateDDMMYYYY(row.dataPosse) : '',
			sortable: true,
			ignoreRowClick: true,
		},
		{
			name: 'Vencimento do Mandato',
			sortField: 'dataVencimentoMandato',
			selector: (row: IConselho) => row.dataVencimentoMandato ? formartDateDDMMYYYY(row.dataVencimentoMandato) : '',
			sortable: true,
			ignoreRowClick: true,
		},
		{
			name: 'Data Reeleição',
			sortField: 'dataReeleicao',
			selector: (row: IConselho) => row.dataReeleicao ? formartDateDDMMYYYY(row.dataReeleicao) : '',
			sortable: true,
			ignoreRowClick: true,
		},
		{
			name: 'Tipo Cargo',
			sortField: 'tipoCargo',
			// selector: (row: IConselho) => row.tipoCargo.nome,
			selector: (row: any) => row.cargo,
			sortable: true,
			ignoreRowClick: true,
		},
		{
			name: 'Descriçãp ccargo',
			sortField: 'descricaoCargo',
			selector: (row: IConselho) => row.descricaoCargo || '',
			sortable: true,
			ignoreRowClick: true,
		},
		{
			name: 'Observação',
			sortField: 'observacao',
			selector: (row: IConselho) => row.observacao || '',
			sortable: true,
			ignoreRowClick: true,
		},
		// {
		// 	name: 'Observação',
		// 	sortField: 'observacao',
		// 	selector: (row: IConselho) => row.observacao || '',
		// 	sortable: true,
		// 	ignoreRowClick: true,
		// }

	]

	const colunasGrupo:TableColumn<IGrupoConselho>[] = [
		{
			name: 'Id',
			selector: (row: IGrupoConselho) => row.id || ''
		},
		{
			name: 'Nome do Grupo',
			selector: (row: IGrupoConselho) => row.nome
		},
		{
			name: 'Status',
			selector: (row: IGrupoConselho) => row.status
		},
		{
			name: '',
			cell:	botoesAcoesGrid,
			ignoreRowClick: true,
		}
	]

	const handleActions = (item: IConselho, actions: any ) => {
		if(grupoSelecionado) {
			item.grupoId = grupoSelecionado.id
		}

		setMockConselheiros( (old) => {
			return [
				...old,
				item
				]
			}
		);
	}

	const handlePessoa = (pessoa: IPessoa) => {
		alert(pessoa.nome);
	}

	const handleActionsGrupo = (item: IGrupoConselho, actions: any) => {
		alert(actions)

		setMockGrupoConselho( (old) => {
			item.id = old[old.length - 1].id! + 1;
			return [
				...old,
				item
				]
			}
		);
	}

	const conteudoModalListaConselheiros = (
		<>
			<GridPadrao
				colunas={colunas}
				tipo={"Conselheitos"}
				itens={conselhoDoGrupoSelecionado}
			/>
		</>
	)

	const findConselheitosByGrupo = (grupoId: number) => {
		const conselheitos = mockConselheiros.filter((item) => item.grupoId === grupoId )
		return conselheitos
	}

	useEffect(() => {
		if(grupoSelecionado) {
			setConselhoDoGrupoSelecionado(findConselheitosByGrupo(grupoSelecionado.id!))	
		}
			return () => { mountedRef.current = false }
	},[grupoSelecionado])

	useEffect(() => {
		if(exibirModalConselheiros == true) {
			if(grupoSelecionado) {
				setConselhoDoGrupoSelecionado(findConselheitosByGrupo(grupoSelecionado.id!))
			}
		}
		
	},[exibirModalConselheiros]);

	return (
		<EmpresaSolucaoLayout title='Conselho' botaoAdicionar={botaoAdicionarDaTela}>
			<div className="col-md-12">

				<GridPadrao
					colunas={colunasGrupo}
					tipo="Grupo"
					itens={mockGrupoConselho}
				/>

				{/* <ModalRecuperaPessoa className='societario'
					title={'Adicionar Conselho ao Grupo'} 
					documentoPadrao={'CPF'}
					modalActions={modalActions}
					handlePessoa={handlePessoa} 
					setTipoDocumento={setTipoDocumento} 
					setNumeroDocumento={setNumeroDocumento}				
				/> */}
	
				<ModalSolucao
					titulo={'Registrar Conselho no Grupo: ' + grupoSelecionado?.nome}
					campos={listaDeCampos}
					handleActions={handleActions}
					empresaId={empresa.empresaId}
					modalActions={modalActions}
				/>

				<ModalCustomBody
					toggleModal={() => { setExibirModalConselheiros(!exibirModalConselheiros)} }
					titulo={'Conselho do Grupo ' + grupoSelecionado?.nome}
					exibirModal={exibirModalConselheiros}
					conteudo={conteudoModalListaConselheiros}
				/>

				<ModalSolucao 
					titulo='Novo Grupo'
					campos={listaDeCamposGrupo}
					handleActions={handleActionsGrupo}
					empresaId={empresa.empresaId} 
					modalActions={modalGrupoActions}				
				/>
			</div>
		</EmpresaSolucaoLayout>
	)

}

