import { faEraser, faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";

import XFinderIntimacaoService from "../../../../services/XFinderIntimacaoService";

import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import IXFinderIntimacao, { IXFinderIntimacaoLogin } from "../../../../interfaces/IXFinderIntimacao";
import IAdicionarXFinderIntimacaoLogin from "../../../../interfaces/Requests/XFinder/IAdicionarXFinderIntimacao";
import FiltroPesquisaXFinderIntimacaoLoginRequest from "../../../../interfaces/Requests/XFinder/FiltroPesquisaXFinderIntimacaoLoginRequest";

import GridPadrao from "../../../../components/Comum/GridPadrao";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import XFinderIntimacaoLoginModal from "./XFinderIntimacaoLoginModal";
import { MultiSelect } from "react-multi-select-component";
import ICNJTribunal from "../../../../interfaces/ICNJTribunal";


const XFinderIntimacaoLoginPage = () => {
    const [carregamentoCNJTribunalId, setCarregamentoCNJTribunalId] = useState<boolean>(false);
    const [cnjTribunalIds, setCNJTribunalIds] = useState<any>([]);
    const [CNJTribunalIdSelected, setCNJTribunalIdSelected] = useState([]);

    const [xFinderIntimacao, setXFinderIntimacao] = useState<IAdicionarXFinderIntimacaoLogin>();
    const [xFinderIntimacoes, setXFinderIntimacoes] = useState<IXFinderIntimacaoLogin[]>([]);
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoIntimacao, setCarregandoIntimacao] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaXFinderIntimacaoLoginRequest>({
        cNJTribunalId: [],
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'id',
        status: 0,
        userName: ""
    });

    const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } })

    const handlePageChange = (page: number) => setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } })

    const handleSort = async (column: TableColumn<IXFinderIntimacao>, sortDirection: string) => setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });

    const toggleModal = (carregarDados?: boolean): void => {
        if (carregarDados) {
            carregarIntimacao(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);

        setTimeout(() => {
            setXFinderIntimacao(undefined)
        }, 300)
    }

    const returnOptions = (array: ICNJTribunal[]) => {
        array.forEach((item: ICNJTribunal) => {
            cnjTribunalIds.push({
                value: item.cnjTribunalId,
                label: item.nome,
            });
        });
        return cnjTribunalIds;
    };

    const carregarCNJs = async () => {
        try {
            const resultado = await XFinderIntimacaoService.obterXFinderCNJs({
                codigo: "",
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "id",
            });
            setCNJTribunalIds(returnOptions(resultado.data));
        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Ocorreu um erro ao tentar pesquisar`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        }
    }

    const carregarIntimacao = async (filtro: FiltroPesquisaXFinderIntimacaoLoginRequest) => {
        try {

            setCarregandoIntimacao(true);

            let resultado: IRecuperaLista<IXFinderIntimacaoLogin>;

            resultado = await XFinderIntimacaoService.obterXFinderIntimacaoLogin(filtro);

            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });

            setXFinderIntimacoes(resultado.data);

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setXFinderIntimacoes([]);
        }
        finally {
            setCarregandoIntimacao(false);
            setLoadingInicial(false);
        }
    }

    const colunas: TableColumn<IXFinderIntimacaoLogin>[] = [
        {
            name: 'CNPJ do Tribunal - ID',
            sortField: 'cnjTribunalId',
            selector: (row: IXFinderIntimacaoLogin) => row.cnjTribunalId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'CNPJ do Tribunal - Nome',
            sortField: 'cnjTribunalNome',
            selector: (row: IXFinderIntimacaoLogin) => row.cnjTribunalNome,
            sortable: true,
            wrap: true
        },
        {
            name: 'Nome do Usuário ',
            sortField: 'username',
            selector: (row: IXFinderIntimacaoLogin) => row.username,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (xFinderIntimacao: IXFinderIntimacaoLogin) => {
                return (
                    <div>
                        <FontAwesomeIcon title="Editar" onClick={() => {
                            setXFinderIntimacao({
                                xFinderIntimacaoLoginId: xFinderIntimacao.xFinderIntimacaoLoginId,
                                username: xFinderIntimacao.username,
                                cnjTribunalId: xFinderIntimacao.cnjTribunalId,
                                confirmarPassword: "",
                                password: "",
                                uriLogin: xFinderIntimacao.uriLogin,
                                uriRecuperaConteudoDocumento: xFinderIntimacao.uriRecuperaConteudoDocumento,
                                uriRecuperaDocumento: xFinderIntimacao.uriRecuperaDocumento,
                                uriRecuperaIntimacao: xFinderIntimacao.uriRecuperaIntimacao,
                            });
                            setExibirModal(true);
                        }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ]


    const limparFiltros = () => {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                limit: 10,
                totalItems: 0,
                offset: 0,
                sort: 'id',
                cNJTribunalId: [],
                status: 0
            }
        });
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarIntimacao(filtrosPesquisa);
        }
    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

    useEffect(() => {
        carregarCNJs();
    }, [])


    const setIdsSelect = (optionsSelected: any) => {
        const IdsSelected: number[] = [];

        optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

        setFiltrosPesquisa({ ...filtrosPesquisa, cNJTribunalId: IdsSelected });
    };

    return (<>
        <LayoutPageTitle title="XFinder de Intimação - Login">
            <button onClick={() => {
                setXFinderIntimacao(undefined);
                setExibirModal(true);
            }} className="btn btn-md btn-orange">
                Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        <XFinderIntimacaoLoginModal
            exibirModal={exibirModal}
            titulo={xFinderIntimacao ? 'Editar Registro' : 'Criar Registro'}
            toggleModal={toggleModal}
            xFinderIntimacaoLogin={xFinderIntimacao} />

        <div className="row mt-2">
            <div className="col-md-12">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    carregarIntimacao(filtrosPesquisa);
                }} className="row g-3 mb-3">
                    <div className="col-md-4 mt-3">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                            CNJ Tribunal ID:
                        </label>
                        <MultiSelect
                            isLoading={carregamentoCNJTribunalId}
                            disabled={carregamentoCNJTribunalId}
                            options={cnjTribunalIds}
                            value={CNJTribunalIdSelected}
                            onChange={(event: any) => {
                                setCNJTribunalIdSelected(event);
                                setIdsSelect(event);
                            }}
                            labelledBy={"Selecione..."}
                            overrideStrings={{
                                selectSomeItems: "Selecione...",
                                allItemsAreSelected: "Todos selecionados",
                                selectAll: "Selecione todos",
                                search: "Pesquise",
                                selectAllFiltered: "Selecione todos (filtrados)"
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Usuário</label>
                        <input value={filtrosPesquisa.userName} onChange={(e) => {
                            setFiltrosPesquisa(oldState => { return { ...oldState, userName: e.target.value } });
                        }} placeholder="Nome" type="text" className={'form-control'} id="form-nome" />
                    </div>
                    <div className="col-md-2">
                        <div>
                            <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                                <FontAwesomeIcon color='white' className='mx-2' icon={faSearch} />
                            </button>
                            <button onClick={(e) => {
                                e.preventDefault();
                                limparFiltros();
                            }} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                                <FontAwesomeIcon color='white' className='mx-2' icon={faEraser} />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-12 mb-10">
                <GridPadrao
                    onSort={handleSort}
                    progressPending={carregandoIntimacao}
                    limit={filtrosPesquisa.limit}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer={true}
                    paginationTotalRows={filtrosPesquisa.totalItems}
                    colunas={colunas}
                    tipo='XFinderIntimacao'
                    itens={xFinderIntimacoes} />
            </div>
        </div>
    </>)
}

export default XFinderIntimacaoLoginPage;