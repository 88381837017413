import { useNavigate, useParams } from "react-router-dom";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import IPerfil from "../../interfaces/IPerfil";
import { useCallback, useEffect, useRef, useState } from "react";
import PerfilService from "../../services/PerfilService";
import IPerfilPermissoes from "../../interfaces/IPerfilPermissoes";
import Column from "./components/Column";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import PermissoesEditModal from "./PerfilPermissoesPageEditModal";
import IModulo from "../../interfaces/IModulo";
import ModuloService from "../../services/ModuloService";
import Swal from "sweetalert2";
import Carregando from "../../components/Carregando";

export type ModuloType = {
  modulo: number;
  moduloNome: string;
  permissoes: {
    permissaoId: number;
    permissaoNome: string;
    role: number;
  }[];
};

interface Columns {
  [key: string]: {
    id: string;
    title: string;
    modulosIds: number[];
  };
}

const PerfilPermissoesPageEdit = () => {
  const [perfil, setPerfil] = useState<IPerfil>();
  const [loading, setLoading] = useState(true);
  const [modulosPerfil, setModulosPerfil] = useState<ModuloType[]>([]);
  const [modulosTotal, setModulosTotal] = useState<IModulo[]>([]);
  const [modulosTotalRestantes, setModulosTotalRestantes] = useState<IModulo[]>(
    []
  );
  const [columns, setColumns] = useState<Columns>({
    "column-1": {
      id: "column-1",
      title: "Clique para configurar os campos selecionados.",
      modulosIds: [],
    },
  });
  const [columnOrder] = useState(["column-1", "column-2"]);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [moduloModal, setModuloModal] = useState<ModuloType | null>(null);
  const modulosColumnRef = useRef<ModuloType[]>([]);
  const refactoredModulosRestantesRef = useRef<ModuloType[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getModulos();
    getPerfil();
  }, [id]);

  useEffect(() => {
    setLoading(true);

    if (perfil && modulosTotal.length > 0) {
      const modulos = getModulosPerfil(
        perfil.permissoesReadDto as IPerfilPermissoes[]
      );

      setModulosPerfil(modulos);

      const modulosRestantes = modulosTotal.filter(
        (moduloExistente) =>
          !modulos.some((modulo) => modulo.modulo === moduloExistente.moduloId)
      );

      setModulosTotalRestantes(modulosRestantes);

      setColumns({
        "column-1": {
          id: "column-1",
          title: "Clique para configurar os campos selecionados.",
          modulosIds: modulos.map((modulo) => modulo.modulo),
        },

        "column-2": {
          id: "column-2",
          title: "Clique, segure e arraste para selecionar um módulo.",
          modulosIds: modulosRestantes.map((modulo) => modulo.moduloId),
        },
      });

      setLoading(false);
    }
  }, [perfil, modulosTotal]);

  const getPerfil = useCallback(async () => {
    try {
      const { data } = await PerfilService.obterPerfil(id ?? "");
      setPerfil(data);
    } catch (error) {
      console.error("Erro ao buscar o perfil:", error);
    }
  }, []);

  const getModulos = useCallback(async () => {
    try {
      const { data } = await ModuloService.obterModulos({
        nome: "",
        codigo: "",
        status: 1,
        offset: 0,
        limit: 0,
        sort: "",
        totalItems: 0,
      });

      setModulosTotal(data);
    } catch (error) {
      console.error("Erro ao buscar os módulos:", error);
    }
  }, []);

  const getModulosPerfil = (permissoesReadDto: IPerfilPermissoes[]) => {
    return permissoesReadDto.reduce<ModuloType[]>((acc, curr) => {
      const moduloExistente = acc.find(
        (modulo) => modulo.modulo === curr.modulo
      );
      if (!moduloExistente) {
        acc.push({
          modulo: curr.modulo,
          moduloNome: curr.moduloNome,
          permissoes: [
            {
              permissaoId: curr.permissaoId,
              permissaoNome: curr.permissaoNome,
              role: curr.role,
            },
          ],
        });
      } else {
        moduloExistente.permissoes.push({
          permissaoId: curr.permissaoId,
          permissaoNome: curr.permissaoNome,
          role: curr.role,
        });
      }
      return acc;
    }, []);
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    if (start === finish) {
      const newModulosIds = Array.from(start.modulosIds);
      newModulosIds.splice(source.index, 1);
      newModulosIds.splice(destination.index, 0, parseInt(draggableId));

      const newColumn = {
        ...start,
        modulosIds: newModulosIds,
      };

      const newState = {
        ...columns,
        [newColumn.id]: newColumn,
      };

      setColumns(newState);

      return;
    }

    const startModulosIds = Array.from(start.modulosIds);
    startModulosIds.splice(source.index, 1);

    const newStart = {
      ...start,
      modulosIds: startModulosIds,
    };

    const finishModulosIds = Array.from(finish.modulosIds);
    finishModulosIds.splice(destination.index, 0, parseInt(draggableId));

    const newFinish = {
      ...finish,
      modulosIds: finishModulosIds,
    };

    const newState = {
      ...columns,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    };

    if (newFinish.id === "column-1") {
      const moduloRef = refactoredModulosRestantesRef.current.find(
        (modulo) => modulo.modulo === start.modulosIds[source.index]
      );

      if (moduloRef) {
        handleModuloClick(moduloRef);
      }
    } else if (newFinish.id === "column-2") {
      const moduloRef = modulosColumnRef.current.find(
        (modulo) => modulo.modulo === start.modulosIds[source.index]
      );

      if (moduloRef) {
        handleModuleDelete(moduloRef);
      }
    }

    setColumns(newState);
  };

  const handleModalClose = async () => {
    await getPerfil();
    await getModulos();

    setToggleModal(false);
    setModuloModal(null);
  };

  const handleModuleDelete = async (modulo: ModuloType) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja remover este módulo?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        const req = {
          Permissoes: [
            {
              Modulo: modulo.modulo,
              Roles: [],
            },
          ],
        };

        await PerfilService.editaPerfil(parseInt(id ?? "0"), req);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: "Status do perfil alterado com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });

        await getPerfil();
        await getModulos();
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o status do perfil`,
        text:
          e?.response?.data?.message ??
          "Não foi possível alterar o status do perfil.",
        showConfirmButton: true,
      });
    }
  };

  const handleModuloClick = async (modulo: ModuloType) => {
    await getPerfil();
    await getModulos();

    setModuloModal(modulo);
    setToggleModal(true);
  };

  const handleDeleteAllModules = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Remover os módulos",
        text: `Você realmente deseja remover todos os módulos?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        const modulosId = modulosPerfil.map((modulo) => modulo.modulo);

        const Permissoes = modulosId.map((modulo) => {
          return {
            Modulo: modulo,
            Roles: [],
          };
        });

        const req = {
          Permissoes,
        };

        await PerfilService.editaPerfil(parseInt(id ?? "0"), req);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: "Módulos removidos com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });

        await getPerfil();
        await getModulos();
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível remover os módulos`,
        text:
          e?.response?.data?.message ??
          "Não foi possível remover os módulos.",
        showConfirmButton: true,
      });
    }
  };

  const handleAddAllModules = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Adicionar todos os módulos",
        text: `Você realmente deseja adicionar todos os módulos (e suas respectivas permissões)?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        const modulosId = modulosTotal.map((modulo) => modulo.moduloId);

        const Permissoes = modulosId.map((modulo) => {
          return {
            Modulo: modulo,
            Roles: [1, 2, 3, 4, 5, 6, 7],
          };
        });

        const req = {
          Permissoes,
        };

        await PerfilService.editaPerfil(parseInt(id ?? "0"), req);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: "Módulos adicionados com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });

        await getPerfil();
        await getModulos();
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível editar os módulos`,
        text:
          e?.response?.data?.message ??
          "Não foi possível editar os módulos.",
        showConfirmButton: true,
      });
    }
  };

  const handleGoBack = () => {
    navigate("/Administracao/Seguranca/PerfilPermissoes");
  };

  if (loading) {
    return <Carregando />;
  }

  return (
    <>
      <LayoutPageTitle title={`Perfil: ${perfil?.nome}`}></LayoutPageTitle>

      <button
        className="btn btn-md btn-orange"
        onClick={handleGoBack}
        style={{ marginBottom: "20px" }}
      >
        Voltar
        <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowLeft} />
      </button>

      <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <button className="btn btn-md btn-orange " onClick={handleAddAllModules}>
          Adicionar todos os módulos
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>

        <button
          className="btn btn-md btn-orange "
          onClick={handleDeleteAllModules}
        >
          Remover todos os módulos
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faTrash} />
        </button>
      </div>

      {moduloModal && (
        <PermissoesEditModal
          perfilId={id ?? ""}
          exibirModal={toggleModal}
          handleClose={handleModalClose}
          modulo={moduloModal}
        />
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: 20,
            justifyContent: "center",
          }}
        >
          {columnOrder.map((columnId) => {
            if (columnId === "column-1") {
              const column = columns["column-1"];
              const modulosColumn: ModuloType[] = column.modulosIds
                .map((moduloId) => {
                  const moduloEncontrado = modulosPerfil.find(
                    (modulo) => modulo.modulo === moduloId
                  );

                  if (!moduloEncontrado) {
                    const moduloAdd = modulosTotalRestantes.find(
                      (modulo) => moduloId === modulo.moduloId
                    );

                    return {
                      modulo: moduloAdd?.moduloId,
                      moduloNome: moduloAdd?.nome,
                      permissoes: {},
                    };
                  }

                  return moduloEncontrado;
                })
                .filter((modulo): modulo is ModuloType => modulo !== undefined);

              modulosColumnRef.current = modulosColumn;

              return (
                <Column
                  key={column.id}
                  column={column}
                  modulos={modulosColumn}
                  onModuloClick={handleModuloClick}
                />
              );
            } else if (columnId === "column-2") {
              const column = columns["column-2"];
              const modulosColumn = column.modulosIds
                .map((moduloId) =>
                  modulosTotalRestantes.find(
                    (modulo) => modulo.moduloId === moduloId
                  )
                )
                .filter((modulo): modulo is IModulo => modulo !== undefined);

              const refactoredModulosRestantes: ModuloType[] = modulosColumn
                .map((modulo) => {
                  if (modulo) {
                    return {
                      modulo: modulo.moduloId,
                      moduloNome: modulo.nome,
                      permissoes: {},
                    };
                  }
                })
                .filter((modulo): modulo is ModuloType => modulo !== undefined);

              refactoredModulosRestantesRef.current =
                refactoredModulosRestantes;

              return (
                <Column
                  key={column.id}
                  column={column}
                  modulos={refactoredModulosRestantes}
                  onModuloClick={() => null}
                />
              );
            }
          })}
        </div>
      </DragDropContext>
    </>
  );
};

export default PerfilPermissoesPageEdit;
