import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoAcao from "../interfaces/ITipoAcao";
import { AdicionaTipoAcaoRequest } from "../interfaces/Requests/TipoAcao/AdicionaTipoAcaoRequest";
import FiltroPesquisaTipoAcaoRequest from "../interfaces/Requests/TipoAcao/FiltroPesquisaTipoAcaoRequest";
import { EditarTipoAcaoRequest } from "../interfaces/Requests/TipoAcao/EditarTipoAcaoRequest";

class TipoAcaoService {

    async obterTipoAcaos(filtros: FiltroPesquisaTipoAcaoRequest): Promise<IRecuperaLista<ITipoAcao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoAcao>>(`TipoAcao/RecuperaTipoAcao?${query}`);

        return result;
    }

    async adicionaTipoAcao(adicionaTipoAcao: AdicionaTipoAcaoRequest): Promise<ITipoAcao> {
        let { data: result } = await api.post<ITipoAcao>(`TipoAcao/AdicionaTipoAcao`, adicionaTipoAcao);

        return result;
    }


    async alterarStatus(tipoAcaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoAcao>(`TipoAcao/AtualizaTipoAcaoPorId?id=${tipoAcaoId}`, data);
    }

    async atualizarTipoAcao(editarTipoAcao: EditarTipoAcaoRequest): Promise<void> {

        let dataTipoAcao = [
            { "op": "replace", "path": "/nome", "value": editarTipoAcao.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoAcao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoAcao/AtualizaTipoAcaoPorId?id=${editarTipoAcao.tipoAcaoId}`, dataTipoAcao)
        ]);
    }
}


export default new TipoAcaoService();