import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IXFinderIntimacao, { IXFinderIntimacaoLogin }  from "../interfaces/IXFinderIntimacao";
import FiltroPesquisaXFinderIntimacaoRequest from "../interfaces/Requests/XFinder/FiltroPesquisaXFinderIntimacaoRequest";
import FiltroPesquisaXFinderIntimacaoLoginRequest from "../interfaces/Requests/XFinder/FiltroPesquisaXFinderIntimacaoLoginRequest";
import IAdicionarXFinderIntimacaoLogin from "../interfaces/Requests/XFinder/IAdicionarXFinderIntimacao";
import ICNJTribunal from "../interfaces/ICNJTribunal";
import IConcluiXFinderIntimacao from "../interfaces/Requests/XFinder/IConcluiXFinderIntimacao";

class XFinderIntimacaoService {

    async gerarExcel(relatorioId: number): Promise<Blob> {
        const { data: result } = await api.get(`XFinderIntimacao/GerarRelatorio?relatorioId=${relatorioId}`, { responseType: 'blob' });
        return result;
    }  

    async obterXFinderIntimacoes(filtros: FiltroPesquisaXFinderIntimacaoRequest): Promise<IRecuperaLista<IXFinderIntimacao>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        if (filtros.juntadoEmMaiorIgual) {
            query += `&juntadoEmMaiorIgual=${filtros.juntadoEmMaiorIgual}`;
        }

        if (filtros.juntadoEmMenorIgual) {
            query += `&juntadoEmMenorIgual=${filtros.juntadoEmMenorIgual}`;
        }

        if (filtros.dataCienciaParteMaiorIgual) {
            query += `&dataCienciaParteMaiorIgual=${filtros.dataCienciaParteMaiorIgual}`;
        }

        if (filtros.dataCienciaParteMenorIgual) {
            query += `&dataCienciaParteMenorIgual=${filtros.dataCienciaParteMenorIgual}`;
        }

        if (filtros.nomeParteAutora ){
            query += `&nomeParteAutora=${filtros.nomeParteAutora}`;
        }

        if (filtros.numeroProcesso){
            query += `&numeroProcesso=${filtros.numeroProcesso}`;
        }

        if (filtros.cNJTribunalId.length > 0) {
            for (let index = 0; index < filtros.cNJTribunalId.length; index++) {
                const cNJTribunalId = filtros.cNJTribunalId[index];

                query += `&cNJTribunalId=${cNJTribunalId}`;
            }
        }

        if (filtros.numeroProcesso){
            query += `&numeroProcesso=${filtros.numeroProcesso}`;
        }

        if (filtros.status != 0){
            query += `&status=${filtros.status}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IXFinderIntimacao>>(`XFinderIntimacao/RecuperaXFinderIntimacao?${query}`);

        return result;
    }

    async concluiXFinderIntimacao(adicionar: IConcluiXFinderIntimacao, intimacaoId: number): Promise<IRecuperaLista<IConcluiXFinderIntimacao>> {
        adicionar.id = intimacaoId;
        
        let { data: result } = await api.post<IRecuperaLista<IConcluiXFinderIntimacao>>(`XFinderIntimacao/ConcluiXFinderIntimacao`, adicionar);

        return result;
    }

    async obterXFinderIntimacaoLogin(filtros: FiltroPesquisaXFinderIntimacaoLoginRequest): Promise<IRecuperaLista<IXFinderIntimacaoLogin>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        
        if (filtros.cNJTribunalId.length > 0) filtros.cNJTribunalId.forEach((id) => (query += `&cNJTribunalId=${id}`));


        if (filtros.userName){
            query += `&userName=${filtros.userName}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IXFinderIntimacaoLogin>>(`XFinderIntimacao/RecuperaXFinderIntimacaoLogin?${query}`);

        return result;
    }

    async obterXFinderCNJs(filtros: any): Promise<IRecuperaLista<ICNJTribunal>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        let { data: result } = await api.get<IRecuperaLista<ICNJTribunal>>(`CNJ/RecuperaCNJTribunal?${query}`);

        return result;
    }

    async adicionarXFinderIntimacaoLogin(adicionar: IAdicionarXFinderIntimacaoLogin): Promise<IRecuperaLista<IAdicionarXFinderIntimacaoLogin>> {

        let { data: result } = await api.post<IRecuperaLista<IAdicionarXFinderIntimacaoLogin>>(`XFinderIntimacao/AdicionaXFinderIntimacaoLogin`, adicionar);

        return result;
    }

    async editarXFinderIntimacaoLogin(editarBanco: IAdicionarXFinderIntimacaoLogin): Promise<void> {
        let data = [
            { "op": "replace", "path": "/cnjTribunalId", "value": editarBanco.cnjTribunalId },
            { "op": "replace", "path": "/username", "value": editarBanco.username },
            { "op": "replace", "path": "/password", "value": editarBanco.password },
            { "op": "replace", "path": "/uriLogin", "value": editarBanco.uriLogin },
            { "op": "replace", "path": "/uriRecuperaConteudoDocumento", "value": editarBanco.uriRecuperaConteudoDocumento },
            { "op": "replace", "path": "/uriRecuperaDocumento", "value": editarBanco.uriRecuperaDocumento },
            { "op": "replace", "path": "/uriRecuperaIntimacao", "value": editarBanco.uriRecuperaIntimacao },
        ]
        await Promise.all([
            await api.patch<void>(`XFinderIntimacao/AtualizaXFinderIntimacaoLoginPorId?id=${editarBanco.xFinderIntimacaoLoginId}`, data)
        ]);
    }
}


export default new XFinderIntimacaoService();