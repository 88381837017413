import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusFolha from "../interfaces/IStatusFolha";
import { AdicionaStatusFolhaRequest } from "../interfaces/Requests/StatusFolha/AdicionaStatusFolhaRequest";
import FiltroPesquisaStatusFolhaRequest from "../interfaces/Requests/StatusFolha/FiltroPesquisaStatusFolhaRequest";
import { EditarStatusFolhaRequest } from "../interfaces/Requests/StatusFolha/EditarStatusFolhaRequest";

class StatusFolhaService {

    async obterStatusFolhas(filtros: FiltroPesquisaStatusFolhaRequest): Promise<IRecuperaLista<IStatusFolha>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusFolha>>(`StatusFolha/RecuperaStatusFolha?${query}`);

        return result;
    }

    async adicionaStatusFolha(adicionaStatusFolha: AdicionaStatusFolhaRequest): Promise<IStatusFolha> {
        let { data: result } = await api.post<IStatusFolha>(`StatusFolha/AdicionaStatusFolha`, adicionaStatusFolha);

        return result;
    }


    async alterarStatus(statusFolhaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusFolha>(`StatusFolha/AtualizaStatusFolhaPorId?id=${statusFolhaId}`, data);
    }

    async atualizarStatusFolha(editarStatusFolha: EditarStatusFolhaRequest): Promise<void> {

        let dataStatusFolha = [
            { "op": "replace", "path": "/nome", "value": editarStatusFolha.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusFolha.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusFolha/AtualizaStatusFolhaPorId?id=${editarStatusFolha.statusFolhaId}`, dataStatusFolha)
        ]);
    }
}


export default new StatusFolhaService();