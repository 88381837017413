import React from 'react';

interface LayoutPageTitleProps {
    title: string;
    dash: any;
}

const LayoutDashboard = ({dash, title }: LayoutPageTitleProps) => {
    return (<>
        <div className="d-flex text-orange justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="auto">{title}</h1>
        </div>
        <div>
            {dash}
        </div>
    </>)
}

export default LayoutDashboard;