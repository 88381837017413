import { ControlObject } from "./types";
import { mark } from "./mark";
import { task } from "./task";
import { font } from "./font";
import { fontColor } from "./fontColor";
import { alignment } from "./alignment";
import { embeed } from "./embeed";
import { list } from "./list";
import { comment } from "./comment";
import { indentation } from './indentation'

export const formattedControlName = (control: string) => {
  const formmated: { [key: string]: string } = {
    bold: "Negrito",
    italic: "Itálico",
    underline: "Sublinhado",
    strikethrough: "Texto tachado",
    undo: 'Desfazer',
    redo: 'Refazer',
    print: 'Imprimir',
    'copy-formating': 'Pintar formatação',
    'add-comment': 'Comentário',
    'bulleted-list': 'Lista com marcadores',
    'numbered-list': 'Lista numerada',
    decreaseIndent: 'Diminuir recuo',
    increaseIndent: 'Aumentar recuo',
    center: 'Alinhar ao centro',
    left: 'Alinhar a esquerda',
    right: 'Alinhar a direita',
    justify: 'Justificar',
  };

  return formmated[control]
};

export const controls: ControlObject = {
  task,
  font,
  mark,
  fontColor,
  alignment,
  indentation,
  embeed,
  list,
  comment,
};
