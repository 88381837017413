import { RenderElementProps, useSelected } from "slate-react";
import { LinkElement as LinkEl } from "../../../../../../utils/types/slate";
import { LinkComponent, LinkTrigger } from "./styles";

export const LinkElement = (props: RenderElementProps) => {
  const element = props.element as LinkEl;
  const selected = useSelected();

  const openUrl = () => {
    const linkElement = element as LinkEl;
    window.open(linkElement.url);
  };

  return (
    <div style={{ position: "relative" }}>
      <LinkComponent
        selected={selected}
        style={{
          ...element.style,
          color: element.style?.color ?? "blue",
        }}
        href={element.url}
        {...props.attributes}
      >
        {props.children}
      </LinkComponent>

      {selected && (
        <LinkTrigger>
          <button type="button" onClick={() => openUrl()}>
            Visitar
          </button>
        </LinkTrigger>
      )}
    </div>
  );
};
