import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ICampo } from "../../../interfaces/ICampo";
import Select from "react-select";

export interface IRelatorioLabelModal {
  exibirModal: boolean;
  handleClose: (campo: { id: string; label?: string }) => void;
  camposRelatorio?: { id: string; label?: string; fase?: number }[];
  setCamposRelatorio?: (
    props: { id: string; label?: string; fase?: number }[]
  ) => void;
  campo: { id: string; label?: string };
  campos: ICampo[];
}

const RelatorioLabelModal = ({
  exibirModal,
  handleClose,
  camposRelatorio,
  setCamposRelatorio,
  campo,
  campos,
}: IRelatorioLabelModal) => {
  const [nome, setNome] = useState("");
  const [fase, setFase] = useState({ label: "", value: -1 });
  const [faseToggle, setFaseToggle] = useState(false);

  const fases = [
    { label: "Pedido", value: 0 },
    { label: "Instância 1", value: 1 },
    { label: "Instância 2", value: 2 },
    { label: "Instância 3", value: 3 },
    { label: "Instância 4", value: 4 },
    { label: "Execução", value: 5 },
    { label: "Estimado", value: 6 },
    { label: "Atual", value: 7 },
  ];

  const carregarCampo = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const camposArray = Array.from(camposRelatorio ?? []);

    const mappedCampos = camposArray.map((campoarray) => {
      if (campoarray.id === campo.id) {
        return {
          id: campoarray.id,
          label: nome === "" ? campoarray.label : nome,
          fase: faseToggle ? fase.value : campoarray.fase,
        };
      } else {
        return {
          id: campoarray.id,
          label: campoarray.label,
          fase: campoarray.fase,
        };
      }
    });

    if (setCamposRelatorio) {
      setCamposRelatorio(mappedCampos);
    }

    handleClose({ id: "", label: "" });
    setNome("");
    setFase({ label: "", value: -1 });
    setFaseToggle(false);
  };

  const validarTabelaOrigem = () => {
    const campoSelecionado = campos.find(
      (campoarray) => campoarray.idUnico === campo.id
    );

    if (campoSelecionado?.tabelaOrigem === 7) {
      setFaseToggle(true);

      const fase = camposRelatorio?.find(
        (campoRelatorio) => campoRelatorio.id === campoSelecionado.idUnico
      )?.fase;

      if (typeof fase !== "undefined" && fase >= 0) {
        const faseSelecionada = fases.find(
          (faseElement) => faseElement.value === fase
        );

        setFase(faseSelecionada ?? { label: "", value: -1 });
      }
    }
  };

  useEffect(() => {
    if (exibirModal) {
      validarTabelaOrigem();
    }
  }, [campo]);

  return (
    <Modal
      centered={true}
      show={exibirModal}
      onHide={() => {
        setNome("");
        setFase({ label: "", value: -1 });
        setFaseToggle(false);
        handleClose({ id: "", label: "" });
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Deseja mudar o nome do Campo?
          </h5>
        </div>
        <div className="modal-body">
          <form
            className="d-flex justify-content-center row"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="col-md-11 mb-2">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder required text-orange"
              >
                Nome
              </label>
              <input
                placeholder={campo.label}
                type="text"
                className="form-control"
                id="form-nome"
                value={nome}
                onChange={(e) => {
                  e.preventDefault();
                  setNome(e.target.value);
                }}
              />
            </div>

            {faseToggle && (
              <div className="col-md-11 mb-2">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder required text-orange"
                >
                  Fase
                </label>
                <Select
                  placeholder="Selecione uma fase"
                  id="form-select"
                  options={fases}
                  isSearchable
                  value={fase}
                  onChange={async (select) => {
                    if (select) {
                      setFase(select);
                    }
                  }}
                />
              </div>
            )}
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            type="button"
            className="btn btn-orange me-5"
            onClick={carregarCampo}
          >
            ALTERAR
          </button>
          <button
            onClick={() => {
              setNome("");
              setFase({ label: "", value: -1 });
              setFaseToggle(false);
              handleClose({ id: "", label: "" });
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RelatorioLabelModal;
