import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoProvisao from "../interfaces/ITipoProvisao";
import FiltroPesquisaTipoPrevisaoRequest from "../interfaces/Requests/TipoPrevisao/FiltroPesquisaTipoPrevisaoRequest";

class TipoProvisaoService {

    async obterTipoProvisaos(filtros: FiltroPesquisaTipoPrevisaoRequest): Promise<IRecuperaLista<ITipoProvisao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoProvisao>>(`TipoProvisao/RecuperaTipoProvisao?${query}`);

        return result;
    }
    async obterListaLimitada(filtros: FiltroPesquisaTipoPrevisaoRequest): Promise<IRecuperaLista<ITipoProvisao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoProvisao>>(`TipoProvisao/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoProvisao(adicionaTipoProvisao: any): Promise<ITipoProvisao> {
        let { data: result } = await api.post<ITipoProvisao>(`TipoProvisao/AdicionaTipoProvisao`, adicionaTipoProvisao);

        return result;
    }


    async alterarStatus(tipoProvisaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoProvisao>(`TipoProvisao/AtualizaTipoProvisaoPorId?id=${tipoProvisaoId}`, data);
    }

    async atualizarTipoProvisao(editarTipoProvisao: any): Promise<void> {

        let dataTipoProvisao = [
            { "op": "replace", "path": "/nome", "value": editarTipoProvisao.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoProvisao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoProvisao/AtualizaTipoProvisaoPorId?id=${editarTipoProvisao.tipoProvisaoId}`, dataTipoProvisao)
        ]);
    }
}


export default new TipoProvisaoService();