import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { faDownload, faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from "../../enum";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IModeloRelatorio from "../../interfaces/IModeloRelatorio";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";

import ImovelService from "../../services/ImovelService";
import ConfiguracaoModeloRelatorio from "../../services/ConfiguracaoModeloRelatorioService";

import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import "./style/icons.css";

interface IImovelRelatorioModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  filtro: any;
  setFiltro: (filtro: any) => void;
}

const ImovelRelatorioModal = ({ toggleModal, exibirModal, filtro, setFiltro }: IImovelRelatorioModalProps) => {
  const [modelos, setModelos] = useState<IModeloRelatorio[]>([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoModelo, setCarregandoModelo] = useState(false);
  const { setCampos, imovel, setModeloRelatorio } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<any>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    modulo: EModulo.Imovel,
    tipoImovelId: imovel.tipoImovelId,
    sort: "modeloRelatorioIdId",
    modeloRelatorioId: 0,
  });

  useLayoutEffect(() => {
    setCampos([]); if (!carregandoModelo) carregarModelos(filtrosPesquisa);
  }, []);


  useEffect(() => {
    if (exibirModal && filtro.modeloRelatorioId) baixarRelatorio(filtro);
    else
      setFiltro({ ...filtro, modeloRelatorioId: 0 });
  }, [filtro.modeloRelatorioId, exibirModal]);

  const carregarModelos = async (filtrosPesquisa: any) => {
    try {
      setCarregandoModelo(true);
      let resultado: IRecuperaLista<any>;
      resultado = await ConfiguracaoModeloRelatorio.obterModeloRelatorio(filtrosPesquisa);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setModelos(resultado.data);
    } catch (error: any) {
      setModelos([]);
    } finally {
      setCarregandoModelo(false);
      setLoadingInicial(false);
    }
  }

  const colunas: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "modeloRelatorioIdId",
      selector: (row: IModeloRelatorio) => row.modeloRelatorioId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IModeloRelatorio) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo",
      sortField: "tipo",
      selector: (row: IModeloRelatorio) => row.tipo,
      sortable: true,
      wrap: true,
    },
    {
      name: '',
      cell: (modelo: IModeloRelatorio) => {
        return (
          <div>

            <FontAwesomeIcon title="Baixar" onClick={() => {
              setFiltro({ ...filtro, modeloRelatorioId: modelo.modeloRelatorioId });
            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faDownload} />
          </div>
        )
      },
      ignoreRowClick: true,
    }
  ];

  const baixarRelatorio = async (filtro: any): Promise<void> => {
    Swal.fire({
      heightAuto: false,
      icon: 'info',
      title: 'Baixando arquivos...',
      showConfirmButton: false,
    });
    Swal.showLoading();
    try {
      const arquivo = await ImovelService.gerarExcel(filtro);
      if (arquivo) {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${new Date().toLocaleString('BR')} - Imovels.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      Swal.hideLoading();
      Swal.update({
        title: 'Baixado com sucesso',
        text: '',
        icon: 'success',
        showConfirmButton: true
      });

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: 'Não foi possível baixar arquivos',
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        timer: 4000,
        icon: 'error',
        showConfirmButton: false
      });
    }
  }

  const onRowClicked = (row: IModeloRelatorio) => {
    setModeloRelatorio(row);
    navigate("/Imovel/ConfiguracaoRelatorio/Modelo");
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: IModeloRelatorio) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: IModeloRelatorio) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<any>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: IModeloRelatorio) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = async () => {
    setFiltrosPesquisa((oldValue: IModeloRelatorio) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
        status: 1,
        modeloRelatorioId: ""
      };
    });
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarModelos(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="row mt-12 mt-6">
              <div style={{ cursor: 'pointer' }} onClick={() => toggleModal()} className="modal-header">
                <h5 className="modal-title text-orange"> Selecione o modelo do relatório: </h5>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    carregarModelos(filtrosPesquisa);
                  }}
                  className="row g-3 mb-3"
                >

                  <div className="col-md-2 ">
                    <label htmlFor="form" className="form-label fw-bolder text-orange">
                      ID
                    </label>
                    <input
                      value={filtrosPesquisa.modeloRelatorioId}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState: any) => {
                          return { ...oldState, modeloRelatorioId: e.target.value };
                        });
                      }}
                      placeholder="ID"
                      type="text"
                      className={"form-control"}
                      id="form"
                    />
                  </div>
                  <div className="col-md-2 ">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Nome
                    </label>
                    <input
                      value={filtrosPesquisa.nome}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState: any) => {
                          return { ...oldState, nome: e.target.value };
                        });
                      }}
                      placeholder="Nome"
                      type="text"
                      className={"form-control"}
                      id="form-nome"
                    />
                  </div>
                  <div className="col-md-2">
                    <div>
                      <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                        <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          limparFiltros();
                        }}
                        className="btn btn-sm btn-orange search-buttom-margin ms-2"
                      >
                        <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
                <GridPadrao
                  onSort={handleSort}
                  onRowClicked={onRowClicked}
                  progressPending={carregandoModelo}
                  limit={filtrosPesquisa.limit}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationServer={true}
                  paginationTotalRows={filtrosPesquisa.totalItems}
                  colunas={colunas}
                  tipo="Modelos"
                  itens={modelos}
                />
              </div>
              <div className="modal-footer" style={{ margin: "0 auto" }}>
              </div>
            </div>
          </div>
        </div>

      </Modal>
    </>
  );
};

export default ImovelRelatorioModal;
