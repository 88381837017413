import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import Dropdown from "react-bootstrap/esm/Dropdown";
import Swal from "sweetalert2";

import { ETipoUsuarioEnum } from "../enum/ETipoUsuarioEnum";

import ProcessoService from "../services/ProcessoService";
import FeedCountService from "../services/FeedCountService";
import PesquisaGlobalService from "../services/PesquisaGlobalService";

import IProcesso from "../interfaces/IProcesso";
import IFeedCount from "../interfaces/IFeedCount";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPesquisaGlobal from "../interfaces/IPesquisaGlobal";
import { OrangeInterfaceContext } from "../interfaces/Contexts/OrangeInterfaceContext";

import useDebounce from "../components/Comum/Debounce";
import { OrangeContext } from "../contexts/OrangeProvider";
import TrocaUsuarioModal from "./TrocaUsuario/TrocaUsuarioModal";
import { useAuth } from "../contexts/AuthProvider";
import LayoutSideBar from "./LayoutSideBar";
import "./Layout.scss";

type LayoutProps = {
  children: React.ReactNode
  isEditorPage?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, isEditorPage }: LayoutProps) => {
  let navigate = useNavigate();
  const { signOut, user } = useAuth();
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [nomeExibicao, setNomeExibicao] = useState("SN");
  const { pathname } = useLocation();
  const [feedCount, setFeedCount] = useState(0);

  const [pesquisaGlobal, setPesquisaGlobal] = useState<string>("");
  const [pesquisaGlobalList, setPesquisaGlobalList] = useState<IPesquisaGlobal[]>([]);
  const [carregandoPesquisaGlobal, setCarregandoPesquisaGlobal] = useState(false);
  const [optionsPesquisa, setOptionsPesquisa] = useState<string[]>([]);

  const [exibirTrocaClienteModal, setExibirTrocaClienteModal] = useState<boolean>(false);

  const handleToggleSidebar = () => setToggled(!toggled);
  const handleCollapsedChange = () => setCollapsed(!collapsed);
  const {
    setProcesso,
    processo,

    setContrato,
    contrato,

    marca,
    setMarca,

    setImovel,
    imovel,

    setLicenca,
    licenca,

    setProcuracao,
    procuracao,

    setConsulta,
    consulta,

    empresa,
    setEmpresa,

    desdobramento,
    setDesdobramento,

    themeMode,
    setThemeMode

  } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [favoritos, setFavoritos] = useState<any[]>([]);

  const typeahead: any = useRef(null);

  const addFilter = (value: string) => setPesquisaGlobal(value);

  const debouncedChange = useDebounce(addFilter, 1000);

  const handleChange = (value: string) => debouncedChange(value);

  const toggleTrocaUsuarioModal = (): void => setExibirTrocaClienteModal(!exibirTrocaClienteModal);

  useEffect(() => {
    limparTypeahead();
    if (pesquisaGlobal.length > 1) {
      setTimeout(() => {
        if (!carregandoPesquisaGlobal) {
          carregarPesquisaGlobal();
        }
      }, 1000);
    }
  }, [pesquisaGlobal]);


  useEffect(() => {
    pesquisaGlobalList.forEach((pesquisa) => {
      let numeroDesdobramento = "";
      if (pesquisa?.numerosDesdobramentos) pesquisa.numerosDesdobramentos.forEach((numero) => (numeroDesdobramento += numero + " - "));

      let partes = "";
      if (pesquisa?.partes) pesquisa.partes.forEach((parte) => (partes += parte.nome + " - " + parte.formaParticipacaoNome + " "));

      let numeroReplace = "";
      if (pesquisa?.numero) numeroReplace = pesquisa?.numero?.replaceAll(".", "")?.replaceAll("-", "");

      let option = `${pesquisa.id}|${numeroReplace}|${pesquisa.pasta}|${pesquisa.modulo}|${partes}|${pesquisa.status}|${pesquisa.numero}|${pesquisa.areaDireitoId}|${numeroDesdobramento}`;
      if (optionsPesquisa.filter((e) => e === option).length === 0) optionsPesquisa.push(option);
    });
  }, [pesquisaGlobalList]);

  useEffect(() => {
    if (!user) return;
    let nome = "";
    const nomeSplit = user.nomeUsuario.split(" ");
    if (nomeSplit.length < 2) {
      nome = user.nomeUsuario.slice(0, 2);
    } else {
      for (let index = 0; index < 2; index++) {
        const n = nomeSplit[index];
        nome += n[0];
      }
    }
    setNomeExibicao(nome.toUpperCase());
  }, [user]);

  useEffect(() => {
    carregarFeedCount();
    carregarFavoritos();
    setToggled(false);
  }, [pathname]);

  const contentClassName = useMemo(() => {
    const defaultClass = themeMode == "light" ? "main-content-orange" : "main-content-orange-dark";
    if (isEditorPage) {
      return `${defaultClass} remove-padding`;
    }
    return defaultClass;
  }, [isEditorPage, themeMode]);

  const markText = (item: string) => {
    if (item && item.length > 0 && item.toLowerCase().includes(pesquisaGlobal.toLowerCase())) return <mark>{item}</mark>;
    else return item;
  };

  const limparTypeahead = () => {
    if (pesquisaGlobal.length === 0 || pesquisaGlobal == " " || pesquisaGlobal == "  ") {
      setOptionsPesquisa([]);
      setPesquisaGlobalList([]);
      typeahead.current.clear();
    }
  };

  setInterval(() => {
    if (typeahead.current)
      if (typeahead.current.state.text)
        if (typeahead.current.state.text.includes("|")) {
          limparTypeahead();
        }
  }, 5000);

  const renderMenuItemChildren = (option: any) => {
    return !carregandoPesquisaGlobal ? (
      <>
        {option.split("|")[3] === "Contencioso" && (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              setProcesso({
                ...processo,
                processoId: parseInt(option.split("|")[0], 10),
                areaDireitoId: parseInt(option.split("|")[7], 10)
              });
              setPesquisaGlobal("");
              setOptionsPesquisa([]);
              navigate("/Processo/Capa");
            }}
          >
            <div
              style={{
                textDecoration: "underline",
                fontFamily: "arial,sans-serif",
                fontSize: "16px",
                color: "#0000FF",
              }}
            >
              Processo: {markText(option.split("|")[6])}

            </div>

            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Partes:</b> <a style={{ fontSize: "13px" }}>{markText(option.split("|")[4])}</a>
            </div>

            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Desdobramento:</b> <a style={{ fontSize: "13px" }}>{markText(option.split("|")[8])}</a>
            </div>

            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Status:</b>{" "}
              <a style={{ fontSize: "13px", textDecoration: "underline" }}>{markText(option.split("|")[5])}</a>{" "}
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>ID: </b>
              <a style={{ fontSize: "13px" }}>{markText(option.split("|")[0])}</a>
            </div>

            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Pasta:</b> <a style={{ fontSize: "13px" }}>{markText(option.split("|")[2])}</a>
            </div>

            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Módulo:</b> <a style={{ fontSize: "13px" }}>{markText(option.split("|")[3])}</a>
            </div>
          </a>
        )}
        {option.split("|")[3] === "Contrato" && (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              setContrato({ ...contrato, contratoId: parseInt(option.split("|")[0], 10) });
              setPesquisaGlobal("");

              setOptionsPesquisa([]);
              navigate("/Contrato/Capa");
            }}
          >
            <div
              style={{
                textDecoration: "underline",
                fontFamily: "arial,sans-serif",
                fontSize: "16px",
                color: "#0000FF",
              }}
            >
              Contrato: {markText(option.split("|")[6])}
            </div>
            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Partes:</b> <a style={{ fontSize: "13px" }}>{markText(option.split("|")[4])}</a>
            </div>
            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Status:</b>{" "}
              <a style={{ fontSize: "13px", textDecoration: "underline" }}>{markText(option.split("|")[5])}</a>{" "}
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>ID: </b>
              <a style={{ fontSize: "13px" }}>{markText(option.split("|")[0])}</a>
            </div>
            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Pasta:</b> <a style={{ fontSize: "13px" }}>{markText(option.split("|")[2])}</a>
            </div>
            <div>
              <b style={{ fontSize: "13px", color: "var(--primary-base2)" }}>Módulo:</b> <a style={{ fontSize: "13px" }}>{markText(option.split("|")[3])}</a>
            </div>
          </a>
        )}

      </>
    ) : (
      <></>
    );
  };

  async function carregarPesquisaGlobal() {
    try {

      setCarregandoPesquisaGlobal(true);

      let resultado: IRecuperaLista<IPesquisaGlobal>;
      resultado = await PesquisaGlobalService.obterPesquisaGlobal({
        termoPesquisado: pesquisaGlobal,
        limit: 100,
        offset: 0,
        sort: "pasta",
        totalItems: 0,
      });

      setPesquisaGlobalList(resultado.data);
      setCarregandoPesquisaGlobal(false);
    } catch (error: any) {
      setCarregandoPesquisaGlobal(false);
    }
  }

  async function carregarFeedCount() {
    try {
      let resultado: IFeedCount;

      resultado = await FeedCountService.obterFeedCount();
      setFeedCount(resultado.totalRegistros);
    } catch (error: any) {
      setFeedCount(0);
    }
  }

  async function carregarFavoritos() {
    try {
      let resultado: IRecuperaLista<IProcesso>;
      resultado = await ProcessoService.obterFavoritos();
      setFavoritos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      <TrocaUsuarioModal
        exibirModal={exibirTrocaClienteModal}
        toggleModal={toggleTrocaUsuarioModal}
      />
      <LayoutSideBar
        handleCollapsedChange={handleCollapsedChange}
        collapsed={user?.primeiroAcesso || collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
      <main className="main-orange">
        <div className="pe-3">
          <div
            className="btn btn-md bg-white btn-toggle float-start ms-3 text-orange"
            onClick={() => handleToggleSidebar()}
          >
            <FaBars className="text-orange" />
          </div>
          <div className="row g-3">
            <div style={{ paddingLeft: "25px" }} className={themeMode == "light" ? "col-md-6 mb-1" : "col-md-6 mb-1 search-dark"}> 
              <Typeahead
                id= "pesquisaGlobal"
                labelKey="data"
                ref={typeahead}
                emptyLabel={
                  carregandoPesquisaGlobal
                    ? "Carregando..."
                    : "Nenhum registro encontrado"
                }
                defaultInputValue={pesquisaGlobal}
                onInputChange={(pesquisa: any) => handleChange(pesquisa)}
                renderMenuItemChildren={renderMenuItemChildren}
                placeholder={"Pesquisar..."}
                options={optionsPesquisa}
                paginate={true}
              />
            </div>

            <div className="col-md-6 mb-1">
              <div
                style={{ cursor: "pointer" }}
                className="menu-usuario-orange float-end"
              >
                {user?.usuarioTipo == ETipoUsuarioEnum.Escritorio && (
                  <>
                    <span
                      onClick={() =>
                        setExibirTrocaClienteModal(!exibirTrocaClienteModal)
                      }
                      className="icone-notificacao-orange"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.25em"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z" />
                      </svg>{" "}
                    </span>
                  </>
                )}
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    <a className="navigation-service-icon navigation-service-icon-startFavorite"></a>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {favoritos.map((favorito, index) => {
                      return (
                        <div key={index}>
                          {favorito.modulo === "Contencioso" && (
                            <Dropdown.Item
                              onClick={() => {
                                setProcesso({
                                  ...processo,
                                  processoId: favorito.processoId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Processo/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}

                          {favorito.modulo === "Contrato" && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setContrato({
                                  ...contrato,
                                  contratoId: favorito.contratoId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Contrato/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}

                          {favorito.modulo === "MarcasEPatentes" && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setMarca({
                                  ...marca,
                                  marcaId: favorito.marcaId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Contrato/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}

                          {favorito.modulo === "Imobiliario" && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setImovel({
                                  ...imovel,
                                  imovelId: favorito.imovelId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Imovel/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}

                          {favorito.modulo === "Regulatorio" && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setLicenca({
                                  ...licenca,
                                  licencaId: favorito.licencaId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Licenca/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}

                          {favorito.modulo === "Consultivo" && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setConsulta({
                                  ...consulta,
                                  consultaId: favorito.consultaId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Consulta/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}

                          {favorito.modulo === "Procuracao" && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setProcuracao({
                                  ...procuracao,
                                  procuracaoId: favorito.procuracaoId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Procuracao/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}
                          {favorito.modulo === "Societario" && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setEmpresa({
                                  ...empresa,
                                  empresaId: favorito.empresaId,
                                  favoritoId: favorito.favoritoId,
                                });
                                navigate("/Societario/Empresa/Capa");
                              }}
                            >
                              {favorito.favoritoDetalhes}
                            </Dropdown.Item>
                          )}
                        </div>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                <span
                  className="icone-notificacao-orange"
                  onClick={() => navigate("/Feed")}
                >
                  {/* Alteração para mudança de ìcone de Notificação */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-chat-left-text"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                  </svg>
                  <span>{feedCount}</span>
                </span>

                <div className="dropdown">
                  {user?.urlFoto ? (
                    <img
                      id="dropdown-user"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="ms-4 avatar-orange"
                      src={user.urlFoto}
                      alt={user.nomeUsuario}
                    />
                  ) : (
                    <div
                      id="dropdown-user"
                      className="ms-4 avatar-orange"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {nomeExibicao}
                    </div>
                  )}
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdown-profile"
                  >
                    {!user?.primeiroAcesso && (
                      <>
                        <li>
                          <div>
                            <div
                              onClick={() => navigate("/Perfil")}
                              className="dropdown-profile"
                            >
                              Meu Perfil
                            </div>
                          </div>
                        </li>
                        {!user?.activeDitectory && (
                          <li>
                            <div>
                              <div
                                onClick={() => navigate("/Alterar-Senha")}
                                className="dropdown-profile"
                              >
                                Alterar Senha
                              </div>
                            </div>
                          </li>
                        )}
                      </>
                    )}

                    <li>
                      <div>
                        <div
                          onClick={() => navigate("/Updates")}
                          className="dropdown-profile"
                        >
                          Sobre
                        </div>
                      </div>
                    </li>

                    <li>
                      <div>
                        <div
                          onClick={() => signOut()}
                          className="dropdown-profile"
                        >
                          Sair
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={contentClassName}>{children}</div>
      </main>
    </div>
  );
};

export default Layout;
