import clsx from "clsx";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import Swal from "sweetalert2";
import GridPadrao from "../../../components/Comum/GridPadrao";
import IEmpresa from "../../../interfaces/IEmpresa";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import EmpresaService from "../../../services/EmpresaService";
import FiltroPesquisaEmpresaRequest from '../../../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest';

interface IEmpresaFieldProps {
  toggleModal: () => void;
  modulo: number,
  exibirModal: boolean;
  empresaSelected: IEmpresa[];
  setFieldValue: (field: string, value: IEmpresa | undefined, ok: boolean) => any;
}

const ModalListagemEmpresa = ({ toggleModal, modulo, exibirModal, empresaSelected, setFieldValue }: IEmpresaFieldProps) => {
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [carregandoEmpresas, setCarregandoEmpresas] = useState(false);
  const [pesquisar, setPesquisar] = useState(false);
  const [empresaSelecionada, setEmpresaSelecionada] = useState<IEmpresa>();

  const filtroInicial: FiltroPesquisaEmpresaRequest & { filtro?: string, tipoPesquisa?: number } = {
    filtro: "",
    statusEmpresa: 0,
    tipoPesquisa: 1,
    limit: 10,
    offset: 0,
    sort: "empresaId",
    totalItems: 0,
    modeloRelatorioId: 0
  };

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEmpresaRequest & { filtro?: string, tipoPesquisa?: number }>(filtroInicial);

  const adicionar = (empresaId: number) => {
    empresas.forEach((empresa) => {
      empresa.checked = false;
      return;
    })

    empresas.forEach((empresa) => {
      if (empresa.empresaId == empresaId) {
        if (!empresa.checked) {
          empresa.checked = true;
          setEmpresas(empresas);

          if (empresaSelecionada) {
            setFieldValue("empresasSelecionada", empresaSelecionada, true);
          }
          return;
        }
      }
    });
  };

  const carregarEmpresas = async (filtro: FiltroPesquisaEmpresaRequest) => {
    try {
      setCarregandoEmpresas(true);

      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setEmpresas(resultado.data);
      setCarregandoEmpresas(false);

    } catch (error: any) {

      setEmpresas([]);
      setCarregandoEmpresas(false);

      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const colunas: TableColumn<IEmpresa>[] = [
    {
      name: "Id",
      sortField: "empresaId",
      selector: (row: IEmpresa) => row.empresaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IEmpresa) => row.nomeFantasia,
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      sortField: "numeroDocumento",
      selector: (row: IEmpresa) => row.numeroDocumento,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Selecionar como matriz?",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => setEmpresaSelecionada(row)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
    setPesquisar(true);
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
    setPesquisar(true);
  };

  const handleSort = async (column: TableColumn<IEmpresa>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
    setPesquisar(true);
  };

  useEffect(() => {
    if (pesquisar) {
      carregarEmpresas(filtrosPesquisa);
      setPesquisar(false);
    }
  }, [pesquisar]);

  useEffect(() => {
    carregarEmpresas(filtrosPesquisa);
  }, []);

  useEffect(() => {
    if (empresaSelecionada) {
      adicionar(empresaSelecionada.empresaId);
    }
  }, [empresaSelecionada]);

  const handleCancel = () => {
    setEmpresaSelecionada(() => undefined);
    setFieldValue("empresasSelecionada", undefined, true);
    empresas.forEach((empresa) => {
      empresa.checked = false
    })

    toggleModal();
  }

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Selecione uma Matriz.</h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-auto">
              <input
                value={filtrosPesquisa.filtro}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, filtro: e.target.value };
                  });
                }}
                placeholder="Pesquise"
                type="text"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto">
              <button onClick={() => carregarEmpresas(filtrosPesquisa)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>
            <div className="col-md-auto">{<> Buscar por: </>}</div>
            <div className="col-md-auto">
              <div key={`inline-radio`} className="mb-3">
                {/* <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 1 })}
                  label="Nome"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 2 })}
                  label="Documento"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 3 })}
                  label="Código"
                  name="group1"
                  type="radio"
                /> */}
              </div>
            </div>
          </div>
          <div className="col-md-auto mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoEmpresas}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Selecione a empresa matriz."
              itens={empresas}
            />
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            onClick={() => {
              toggleModal();
              setFiltrosPesquisa(filtroInicial)
            }}
            type="button"
            className="btn btn-orange ms-5"
          >
            {" "}
            Adicionar{" "}
          </button>

          <button
            onClick={() => {
              setFiltrosPesquisa(filtroInicial)
              handleCancel();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            {" "}
            Cancelar{" "}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalListagemEmpresa;
