import api from "./Api";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import IGarantia from "../interfaces/IGarantia";

import { EModulo, ETipoCampoEnum } from "../enum";

import { ILevantamento } from "../interfaces/ILevantamento";
import { EditarGarantiaRequest } from "../interfaces/Requests/Garantia/EditarGarantiaRequest";
import { AdicionaGarantiaRequest } from "../interfaces/Requests/Garantia/AdicionaGarantiaRequest";
import FiltroPesquisaGarantiaRequest from "../interfaces/Requests/Garantia/FiltroPesquisaGarantiaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class GarantiaService {

    public async obterGarantias(filtros: FiltroPesquisaGarantiaRequest): Promise<IRecuperaLista<IGarantia>> {
        let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.statusGarantiaId?.length > 0) {
            for (let index = 0; index < filtros.statusGarantiaId.length; index++) {
                const statusGarantiaId = filtros.statusGarantiaId[index];

                query += `&statusGarantiaId=${statusGarantiaId}`;
            }
        }

        if (filtros.garantiaId && filtros.garantiaId > 0) {
            query += `&garantiaId=${filtros.garantiaId}`;
        }

        if (filtros.processoId && filtros.modulo == EModulo.AreaDireito) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.imovelId && filtros.modulo == EModulo.Imovel) {
            query += `&imovelId=${filtros.imovelId}`;
        }


        if (filtros.dataInicioVigenciaMaiorIgual) {
            query += `&dataInicioVigenciaMaiorIgual=${filtros.dataInicioVigenciaMaiorIgual}`;
        }

        if (filtros.dataInicioVigenciaMenorIgual) {
            query += `&dataInicioVigenciaMenorIgual=${filtros.dataInicioVigenciaMenorIgual}`;
        }

        if (filtros.dataVencimentoMaiorIgual) {
            query += `&dataVencimentoMaiorIgual=${filtros.dataVencimentoMaiorIgual}`;
        }

        if (filtros.dataVencimentoMenorIgual) {
            query += `&dataVencimentoMenorIgual=${filtros.dataVencimentoMenorIgual}`;
        }

        if (filtros.dataFatalMaiorIgual) {
            query += `&dataFatalMaiorIgual=${filtros.dataFatalMaiorIgual}`;
        }

        if (filtros.dataFatalMenorIgual) {
            query += `&dataFatalMenorIgual=${filtros.dataFatalMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        if (filtros.dataGarantiaMaiorIgual) {
            query += `&dataGarantiaMaiorIgual=${filtros.dataGarantiaMaiorIgual}`;
        }

        if (filtros.dataGarantiaMenorIgual) {
            query += `&dataGarantiaMenorIgual=${filtros.dataGarantiaMenorIgual}`;
        }

        if (filtros.valorPrincipalAtualMaiorIgual) {
            query += `&valorPrincipalAtualMaiorIgual=${filtros.valorPrincipalAtualMaiorIgual}`;
        }

        if (filtros.valorPrincipalAtualMenorIgual) {
            query += `&valorPrincipalAtualMenorIgual=${filtros.valorPrincipalAtualMenorIgual}`;
        }

        if (filtros.tipoGarantiaId?.length > 0) {
            for (let index = 0; index < filtros.tipoGarantiaId.length; index++) {
                const tipoGarantiaId = filtros.tipoGarantiaId[index];

                query += `&tipoGarantiaId=${tipoGarantiaId}`;
            }
        }

        if (filtros.garantiaId) {
            query += `&garantiaId=${filtros.garantiaId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IGarantia>>(`Garantia/RecuperaGarantia?${query}`);

        return result;
    }

    public async obterGarantiaPorId(garantiaId: number): Promise<IRecuperaLista<IGarantia>> {
        let { data: result } = await api.get<IRecuperaLista<IGarantia>>(`/Garantia/RecuperaGarantia?&garantiaId=${garantiaId}`);
        return result;
    }

    public async adicionaFavorido(garantiaId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Garantia, garantiaId: garantiaId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async obterCapaGarantia(garantiaId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Garantia/RecuperaCapaGarantia?garantiaId=${garantiaId}`);
        return result;
    }

    public async adicionaGarantia(adicionaGarantia: AdicionaGarantiaRequest): Promise<IGarantia> {
        let { data: result } = await api.post<IGarantia>(`Garantia/AdicionaGarantia`, this.montarObjetoRequest(adicionaGarantia));

        return result;
    }

    public async adicionaLevantamento(adicionaLevantamento: ILevantamento): Promise<IGarantia> {
        let { data: result } = await api.post<IGarantia>(`Garantia/AdicionaLevantamentoGarantia`, adicionaLevantamento);

        return result;
    }

    public async editarGarantia(editarGarantia: AdicionaGarantiaRequest) {
        let request = this.montarObjetoArray(editarGarantia);

        if (request.length > 0) {
            let result = await api.patch(`Garantia/AtualizaGarantiaPorId?id=${editarGarantia.garantiaId}`, request);
            return result;
        }
    }

    public async alterarStatus(garantiaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IGarantia>(`Garantia/AtualizaGarantiaPorId?id=${garantiaId}`, data);
    }

    public async atualizarGarantia(editarGarantia: EditarGarantiaRequest): Promise<void> {

        let dataGarantia = [
            { "op": "replace", "path": "/codigo", "value": editarGarantia.codigo },
            { "op": "replace", "path": "/descricaoGarantia", "value": editarGarantia.descricaoGarantia }
        ]


        await Promise.all([
            await api.patch<void>(`Garantia/AtualizaGarantiaPorId?id=${editarGarantia.garantiaId}`, dataGarantia)
        ]);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Garantia/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarGarantiaPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Garantia/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    public concluirCompromisso = async (garantia: AdicionaGarantiaRequest): Promise<any> => {

        let result: any[] = [];

        // Salvar campos normais
        result.push(await this.editarGarantia(garantia));

        // Salvar xcampos
        if (garantia.xCampoValorList?.length > 0) {
            garantia.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, garantiaId: garantia.garantiaId, texto: xCampo.texto }));
                        else result.push(await this.alterarGarantiaPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, garantiaId: garantia.garantiaId, data: xCampo.data }));
                        else result.push(await this.alterarGarantiaPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, garantiaId: garantia.garantiaId, valor: xCampo.valor }));
                        else result.push(await this.alterarGarantiaPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, garantiaId: garantia.garantiaId, inteiro: xCampo.inteiro }));
                        else result.push(await this.alterarGarantiaPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, garantiaId: garantia.garantiaId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarGarantiaPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        // results
        return result;
    };

    private montarObjetoRequest = (garantia: AdicionaGarantiaRequest) => {
        let request: any = {};


        if (garantia.xCampoValorList.length > 0) request.xCampoValorList = garantia.xCampoValorList;

        // Campos texto
        if (garantia.codigo) request.codigo = garantia.codigo;
        if (garantia.agencia) request.agencia = garantia.agencia;
        if (garantia.descricao) request.descricao = garantia.descricao;
        if (garantia.codigobarras) request.codigobarras = garantia.codigobarras;
        if (garantia.conta) request.conta = garantia.conta;
        if (garantia.numeroapolice) request.numeroapolice = garantia.numeroapolice;
        if (garantia.matriculaimovel) request.matriculaimovel = garantia.matriculaimovel;
        if (garantia.cartorio) request.cartorio = garantia.cartorio;
        if (garantia.corretora) request.corretora = garantia.corretora;
        if (garantia.matriculagarantia) request.matriculagarantia = garantia.matriculagarantia;

        if (garantia.datainiciovigencia) request.datainiciovigencia = garantia.datainiciovigencia;
        if (garantia.datavencimento) request.datavencimento = garantia.datavencimento;
        if (garantia.datafatal) request.datafatal = garantia.datafatal;
        if (garantia.datagarantia) request.datagarantia = garantia.datagarantia;

        if (garantia.valorprincipal) request.valorprincipal = garantia.valorprincipal;

        if (garantia.processoId) {
            request.modulo = EModulo.AreaDireito;
            request.processoId = garantia.processoId;
            request.areaDireitoId = garantia.areaDireitoId;
        }

        if (garantia.imovelId) {
            request.modulo = EModulo.Imovel;
            request.imovelId = garantia.imovelId;
            request.tipoImovelId = garantia.tipoImovelId;
        }

        if (garantia.tipoGarantiaId) request.tipoGarantiaId = garantia.tipoGarantiaId;
        if (garantia.formapagamentoId) request.formapagamentoId = garantia.formapagamentoId;
        if (garantia.indiceId) request.indiceId = garantia.indiceId;
        if (garantia.juros) request.juros = garantia.juros;
        if (garantia.moedaId) request.moedaId = garantia.moedaId;
        if (garantia.bancoId) request.bancoId = garantia.bancoId;

        return request;
    };

    private montarObjetoArray = (garantia: AdicionaGarantiaRequest) => {
        let request: any = [];

        // Campos texto
        if (garantia.codigo) request.push({ op: "replace", path: "/codigo", value: garantia.codigo });
        if (garantia.codigobarras) request.push({ op: "replace", path: "/codigoBarras", value: garantia.codigobarras });
        if (garantia.agencia) request.push({ op: "replace", path: "/agencia", value: garantia.agencia });
        if (garantia.conta) request.push({ op: "replace", path: "/conta", value: garantia.conta });
        if (garantia.descricao) request.push({ op: "replace", path: "/descricao", value: garantia.descricao });
        if (garantia.numeroapolice) request.push({ op: "replace", path: "/numeroApolice", value: garantia.numeroapolice });
        if (garantia.matriculagarantia) request.push({ op: "replace", path: "/matriculaGarantia", value: garantia.matriculagarantia });
        if (garantia.cartorio) request.push({ op: "replace", path: "/cartorio", value: garantia.cartorio });
        if (garantia.corretora) request.push({ op: "replace", path: "/corretora", value: garantia.corretora });

        // Campos data
        if (garantia.datagarantia) request.push({ op: "replace", path: "/dataGarantia", value: garantia.datagarantia });
        if (garantia.datainiciovigencia) request.push({ op: "replace", path: "/dataInicioVigencia", value: garantia.datainiciovigencia });
        if (garantia.datavencimento) request.push({ op: "replace", path: "/dataVencimento", value: garantia.datavencimento });
        if (garantia.datafatal) request.push({ op: "replace", path: "/dataFatal", value: garantia.datafatal });



        if (garantia.bancoId) request.push({ op: "replace", path: "/bancoId", value: garantia.bancoId });
        if (garantia.moedaId) request.push({ op: "replace", path: "/moedaId", value: garantia.moedaId });
        if (garantia.juros) request.push({ op: "replace", path: "/juros", value: garantia.juros });
        if (garantia.valorprincipal) request.push({ op: "replace", path: "/valorPrincipal", value: garantia.valorprincipal });
        if (garantia.formapagamentoId) request.push({ op: "replace", path: "/formaPagamentoId", value: garantia.formapagamentoId });

        return request;
    };
}


export default new GarantiaService();
