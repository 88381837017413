import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICampo } from "../../../../interfaces/ICampo";
import { faAngleDown, faInfo } from "@fortawesome/free-solid-svg-icons";
import CampoRelatorio from "./Campo";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import Spinner from "../../../../components/Spinner";
import ICampoTipos from "../../../../interfaces/ICampoTipos";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { ReactNode } from "react";

interface ColumnRelatorioProps {
  column: {
    id: string;
    title: string;
    campoIds: string[];
  };
  campos: ICampo[];
  loading: boolean;
  isDivided: boolean;
  tipoCampos: ICampoTipos[];
  camposRelatorio?: { id: string; label?: string; fase?: number }[];
  toggleModal: (campo: { id: string; label?: string }) => void;
}

const ColumnRelatorio = ({
  column,
  campos,
  loading,
  isDivided,
  tipoCampos,
  camposRelatorio,
  toggleModal,
}: ColumnRelatorioProps) => {
  const renderCamposCategoria = () => {
    return (
      <div
        className="border p-4 align-items-center"
        style={{ overflowY: "scroll", height: "60vh" }}
      >
        {tipoCampos.map((tipoCampo, indexTipo) => {
          const eventKey = indexTipo.toString();
          return (
            <Accordion key={eventKey}>
              <CustomToggle eventKey={eventKey}>{tipoCampo.tipo}</CustomToggle>
              <Accordion.Collapse eventKey={eventKey}>
                <Droppable droppableId={column.id}>
                  {(provided) =>
                    renderTipoCampos(provided, tipoCampo, indexTipo)
                  }
                </Droppable>
              </Accordion.Collapse>
            </Accordion>
          );
        })}
      </div>
    );
  };

  const renderTipoCampos = (
    provided: DroppableProvided,
    tipoCampo: ICampoTipos,
    indexTipo: number
  ) => (
    <div {...provided.droppableProps} ref={provided.innerRef}>
      {tipoCampo.campos.map((campo, index) => {
        let indexCorrigido = 0;

        if (indexTipo > 0) {
          for (let index = 0; index < indexTipo; index++) {
            indexCorrigido += tipoCampos[index].campos.length;
          }
        }

        return (
          <div style={{ marginLeft: "15px" }} key={campo.idUnico}>
            <CampoRelatorio
              key={campo.idUnico}
              campo={{ idUnico: campo.idUnico ?? "", label: campo.label }}
              index={index + indexCorrigido}
            />
          </div>
        );
      })}
      {provided.placeholder}
    </div>
  );

  return (
    <div style={{ width: "32%" }}>
      <div className="mt-4 mb-3">
        <h6 className="modal-title text-orange d-flex align-items-center gap-1">
          {loading ? (
            <Spinner />
          ) : (
            <FontAwesomeIcon color={"orange"} className="mx-1" icon={faInfo} />
          )}

          {column.title}
        </h6>
      </div>
      {isDivided && renderCamposCategoria()}

      {!isDivided && (
        <Droppable droppableId={column.id}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="border p-4 align-items-center"
              style={{ overflowY: "scroll", height: "60vh" }}
            >
              {campos.map((campo, index) => {
                if (campo) {
                  const campoRelatorio = camposRelatorio?.find(
                    (campoR) => campoR.id === campo.idUnico
                  );

                  return (
                    <CampoRelatorio
                      key={campo.idUnico}
                      campo={{
                        idUnico: campo.idUnico ?? "",
                        label: campoRelatorio?.label ?? campo.label,
                        tabelaOrigem: campo.tabelaOrigem,
                        fase: campoRelatorio?.fase,
                      }}
                      index={index}
                      descartado={column.id === "column-3"}
                      isRelatorio={column.id === "column-2"}
                      toggleModal={toggleModal}
                    />
                  );
                }
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
};

const CustomToggle = ({
  children,
  eventKey,
}: Readonly<{
  children: ReactNode;
  eventKey: string;
}>) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <div
      style={{
        border: "1px solid orange",
        backgroundColor: "#f29f65",
        borderRadius: 4,
        padding: 8,
        marginBottom: 10,
        color: "white",
        fontWeight: "bold",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onClick={decoratedOnClick}
    >
      {children}
      <FontAwesomeIcon icon={faAngleDown} />
    </div>
  );
};

export default ColumnRelatorio;
