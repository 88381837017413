import React from "react";
import { MenuChildProps } from "../props";
import { MenuTitleEdit } from "../styles";

type TitleEditProps = MenuChildProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

export function TitleEdit(props: TitleEditProps) {
  return <MenuTitleEdit {...props}>{props.children}</MenuTitleEdit>;
}
