import {
  MdCalendarMonth,
  MdOutlineArrowBack,
  MdOutlineBook,
  MdOutlineBookmark,
  MdOutlineHistory,
  MdOutlinePublish,
  MdOutlineSend,
  MdUpload,
} from "react-icons/md";
import { EditorVersionAdditionalResponse } from "../../../../../../../interfaces/IEditorVersionResponse";
import { DocumentMenuItem, MenuType } from "../interfaces/menuItem";
import { IoSparklesOutline } from "react-icons/io5";
import { Caller } from "../../../../../util";
import { useTenant } from "../../../../../hooks/useTenant";
import { useAuth } from "../../../../../../../contexts/AuthProvider";

export function useMenu(options: EditorVersionAdditionalResponse | null) {
  const { caller } = useTenant();
  const { user } = useAuth();

  function getItems(historyMode: boolean): DocumentMenuItem[] {
    return [
      {
        type: MenuType.importador,
        title: "Importador",
        enabled: true,
        icon: MdUpload,
        className: "btn btn-orange btn-sm",
      },
      {
        type: MenuType.historyVersion,
        title: historyMode ? "Editar documento" : "Histórico de versões",
        enabled: caller !== Caller.PROCESSO && !options?.ocultarMenuArquivoHistoricoVersao,
        icon: historyMode ? MdOutlineArrowBack : MdOutlineHistory,
        className: "btn btn-warning btn-sm",
      },
      {
        type: MenuType.publish,
        title: "Publicar versão",
        enabled: !options?.ocultarBotaoPublicar && caller !== Caller.PROCESSO,
        icon: MdOutlinePublish,
        className: "btn btn-success btn-sm",
      },
      {
        type: MenuType.cover,
        title: "Acessar capa",
        enabled: true,
        icon: MdOutlineBook,
        className: "btn btn-primary btn-sm",
      },
      {
        type: MenuType.ia,
        title: "Analise de IA",
        enabled: user?.id === 13,
        icon: IoSparklesOutline,
        className: "btn btn-purple btn-sm",
      },
      {
        type: MenuType.templateList,
        title: "Modelo de documento",
        enabled: caller === Caller.PROCESSO,
        icon: MdOutlineBookmark,
        className: "btn btn-info btn-sm",
      },
      {
        type: MenuType.commitment,
        title: "Compromisso",
        enabled: !!(
          options?.compromissoReadDto && options.compromissoReadDto.length > 0
        ),
        icon: MdCalendarMonth,
        className: "btn btn-orange btn-sm",
      },
      {
        type: MenuType.send,
        title: "Enviar minuta",
        enabled: !options?.ocultarBotaoEnviarMinuta,
        icon: MdOutlineSend,
        className: "btn btn-danger btn-sm",
      },
    ].filter((item) => item.enabled);
  }

  return {
    getItems,
  };
}
