import { ETipoCampoEnum } from "../../../../../enum";
import { Modelo } from "../../../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";

  export const modelo: Modelo = {
    nome: "Representantes",
    label: "Representantes",
    controller: "NULL",
    entidade: '',
    tipo: ETipoCampoEnum.Pessoa,    
    isXCampo: false,
    campo: 91,
    campoNome: "representante",
    ordenacao: 1,
    obrigatorio: false,
    help: "",
    somenteLeitura: false,
    multiplo: false,    
    camposExtras: [
        {
            campoNome: "DataEntrada",
            nome: "DataEntrada",
            controller: null,
            label: "Data da Entrada",
            entidade: "",
            obrigatorio: true,
            tipo: ETipoCampoEnum.Data,
            ordenacao: 0,
            somenteLeitura: false,
            campo: 0,
            help: "",
            isXCampo: false
        },
        {
            campoNome: "DataVencimentoMandato",
            nome: "DataVencimentoMandato",
            controller: null,
            label: "Data de Vencimento do Mandato",
            entidade: "",
            tipo: ETipoCampoEnum.Data,
            ordenacao: 0,
            obrigatorio: true,
            somenteLeitura: false,
            campo: 0,
            help: "",
            isXCampo: false
        },
       
        {
            campoNome: "DataSaida",
            nome: "DataSaida",
            controller: null,
            label: "Data da Saída",
            entidade: "",
            tipo: ETipoCampoEnum.Data,
            ordenacao: 0,
            obrigatorio: false,
            somenteLeitura: false,
            campo: 0,
            help: "",
            isXCampo: false
        },
        {
            campoNome: "Qualificacao",
            nome: "Qualificacao",
            controller: "Qualificacao",
            label: "Qualificação",
            entidade: "",
            tipo: ETipoCampoEnum.Lista,
            ordenacao: 0,
            obrigatorio: true,
            somenteLeitura: false,
            campo: 0,
            help: "",
            isXCampo: false
        },
        {
            campoNome: "ObservacaoRepresentante",
            nome: "ObservacaoRepresentante",
            controller: null,
            label: "Observação do Representante",
            entidade: "",
            tipo: ETipoCampoEnum.Texto,
            ordenacao: 0,
            obrigatorio: false,
            somenteLeitura: false,
            campo: 0,
            help: "",
            isXCampo: false
        },
        {
            campoNome: "Status",
            nome: "Status",
            controller: "StatusRepresentante",
            label: "Status",
            entidade: "",
            tipo: ETipoCampoEnum.EnumLista,
            ordenacao: 0,
            obrigatorio: true,
            somenteLeitura: false,
            campo: 0,
            help: "",
            isXCampo: false
        },
      
    ]
}

export const camposPesquisa = []