import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps, FileRejection, Accept } from 'react-dropzone';

interface FileDropzoneProps {
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({ onDrop }) => {
    const [progress, setProgress] = useState(0);
    const [count, setCount] = useState(0);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const onDropCallback = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            setProgress(0); // Reset progress when new files are dropped
            setSelectedFile(acceptedFiles[0] || null);

            // Simulate a delay for the progress bar (you can replace this with actual file upload logic)
            const simulateUpload = () => {
                const totalSteps = 100;
                let currentStep = 0;
                setCount(acceptedFiles.length);

                const interval = setInterval(() => {
                    currentStep++;
                    setProgress((currentStep / totalSteps) * 100);

                    if (currentStep === totalSteps) {
                        clearInterval(interval);
                        onDrop(acceptedFiles, fileRejections);

                    }
                }, 50);
            };

            simulateUpload();
        },
        [onDrop]
    );

    const accepts: Accept = { formatos: ['image/*', '.doc', '.docx', '.xlsx', '.xls', '.ppt', '.pptx', '.pdf', '.mp3', '.zip', '.wav', '.tiff', '.tif', '.rtf', '.rar', '.mp4', '.eml', '.opus', '.mkv', '.htm', '.oft', '.msg', '.mht', '.pjc'] };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
    }: {
        getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
        getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
        isDragActive: boolean;
    } = useDropzone({
        onDrop: onDropCallback,
        accept: accepts,
    });

    const dropzoneStyle: React.CSSProperties = {
        border: '2px dashed var(--primary-base2)',
        borderRadius: '4px',
        padding: '10px',
        textAlign: 'center',
        cursor: 'pointer',
    };

    return (
        <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p className='text-orange'>Solte os arquivos aqui...</p>
            ) : (<>

                {progress > 0 && progress < 100 ?
                    <>
                        <p><FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faFile} /></p>
                        <span className="indicator-progress text-orange" style={{ display: "block", paddingBottom: '16px' }}>
                            <span className="spinner-border spinner-border-sm align-middle text-orange"></span>
                        </span>
                    </> :
                    <>
                        <p><FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faFile} /></p>
                        <p className='text-orange'>Arraste e solte alguns arquivos aqui, ou clique para selecionar</p>
                    </>
                }
            </>
            )}

            {selectedFile && (
                <div style={{ marginTop: '20px' }}>
                    {progress > 0 && progress < 100 && <><h6 className="text-orange"> {count > 1 ? `${count} arquivos selecionados` : `Arquivo selecionado: ${selectedFile.name}`}</h6></>}
                    <div className="progress">
                        <div className="progress-bar-orange text-white" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}>{progress > 100 ? 0 : progress + '%'}</div>
                    </div>
                </div>
            )}
        </div>
    );
};



