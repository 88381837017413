import { useContext, useEffect, useState } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import {
    FaArrowCircleLeft,
    FaArrowCircleRight,
    FaBalanceScale,
    FaSearch,
    FaRegFileArchive,
    FaCog,
    FaQuestionCircle,
    FaSignature,
    FaUserFriends,
    FaFileContract,
    FaRegNewspaper,
    FaWarehouse,
    FaRegistered,
    FaBookOpen,
    FaClipboardCheck,
    FaFile
} from 'react-icons/fa';

import { EModulo } from '../enum';

import 'react-pro-sidebar/dist/css/styles.css';
import './LayoutSideBar.css';
import Storage from "../../../src/app/utils/StorageUtils";
import { checkIsActive } from '../utils/RouteUtils';
import { useAuth } from '../contexts/AuthProvider';
import { OrangeContext } from '../contexts/OrangeProvider';
import { OrangeInterfaceContext } from '../interfaces/Contexts/OrangeInterfaceContext';
const image = "/images/logo-xjur.png";


export default function LayoutSideBar({ handleCollapsedChange, collapsed, toggled, handleToggleSidebar }: any) {
    const { pathname } = useLocation();
    const [carregandoLogo, setCarregandoLogo] = useState<boolean>(false);
    const { urlLogo, setUrlLogo } = useContext<OrangeInterfaceContext>(OrangeContext);

    const [submenuSelecionado, setSubemnuSelecionado] = useState({
        adm: false,
        societario: false,
        contencioso: false,
        xfinder: false,
        xsigner: false,
        contrato: false,
        procuracao: false,
        consultivo: false,
        imobiliario: false,
        marcasEPatentes: false,
        ged: false,
        regulatorio: false,
        xlearning: false,
        xPayFechamento: false,
        relatorio: false
    });
    const { user } = useAuth();

    useEffect(() => {
        if (user) {

            setUrlLogo(user.urlLogo);

            setCarregandoLogo(true);

            setTimeout(() => {
                setCarregandoLogo(false)
            }, 3000);
        }
    }, [user]);


    useEffect(() => {
        if (urlLogo && user) {

            Storage.setUser({ ...user, urlLogo });

            setCarregandoLogo(true);

            setTimeout(() => {
                setCarregandoLogo(false)
            }, 3000);
        }
    }, [urlLogo]);

    const showContencioso = user?.visualizarMenus.some(element => {
        if (element === 1)
            return true;
        return false;
    });

    const showXSigner = user?.visualizarMenus.some(element => {
        if (element === 2)
            return true;
        return false;
    });

    const showXFinder = user?.visualizarMenus.some(element => {
        if (element === 3)
            return true;
        return false;
    });

    const showAdministracao = user?.visualizarMenus.some(element => {
        if (element === 4)
            return true;
        return false;
    });

    const showSocietario = user?.visualizarMenus.some(element => {
        if (element === 5)
            return true;
        return false;
    });

    const showGed = user?.visualizarMenus.some(element => {
        if (element === 6)
            return true;
        return false;
    });

    const showContrato = user?.visualizarMenus.some(element => {
        if (element === 7)
            return true;
        return false;
    });

    const showProcuracao = user?.visualizarMenus.some(element => {
        if (element === 21)
            return true;
        return false;
    });

    const showConsultivo = user?.visualizarMenus.some(element => {
        if (element === 9)
            return true;
        return false;
    });

    const showDashboard = user?.visualizarMenus.some(element => {
        if (element === 8)
            return true;
        return false;
    });

    const showDespesa = user?.visualizarMenus.some(element => {
        if (element === 12)
            return true;
        return false;
    });

    const showImobiliario = user?.visualizarMenus.some(element => {
        if (element === 10)
            return true;
        return false;
    });

    const showMarcasEPatentes = user?.visualizarMenus.some(element => {
        if (element === 11)
            return true;
        return false;
    });

    const showRegulatorio = user?.visualizarMenus.some(element => {
        if (element === 30)
            return true;
        return false;
    });

    const showSubMenusAdm = user?.visualizarMenus.some(element => {
        if (element === -1)
            return true;
        return false;
    });

    const showXPayFechamento = user?.visualizarMenus.some(element => {
        if (element === 36)
            return true;
        return false;
    });

    return (
        <ProSidebar breakPoint="md" onToggle={handleToggleSidebar} collapsed={collapsed} toggled={toggled}>
            <SidebarHeader className="navbar bg-orange">
                {
                    !(user?.primeiroAcesso) && <div className="closemenu" onClick={handleCollapsedChange}>
                        {collapsed ? (<FaArrowCircleRight />) : (<FaArrowCircleLeft />)}
                    </div>
                }

                <Link className="navbar-brand-orange" to="/home">
                    {/* <img className="ms-6" style={{ maxHeight: '50px' }} src={process.env.PUBLIC_URL + '/images/XJUR - LOGO_ROXO.png'} alt="Orange" /> */}
                    {carregandoLogo ?
                        <img className="heartBeat ms-6" src={image} style={{ maxHeight: '50px' }}></img>
                        :
                        <img className="ms-6" style={{ maxHeight: '50px' }} src={urlLogo} alt="cliente" />
                    }
                </Link>
            </SidebarHeader>
            <SidebarContent>
                {
                    !(user?.primeiroAcesso) && <Menu iconShape="square">

                        {
                            (showAdministracao) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.adm) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: true, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }

                            }} open={submenuSelecionado.adm} title="Administração" icon={<FaCog size={25} />}>
                                {(showSubMenusAdm) &&
                                    <SubMenu title="Tabelas" icon=''>
                                        <SubMenu title="Andamentos" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/andamentoTipo') ? 'active' : ''}>
                                                Tipo de Andamento
                                                <Link to="/AndamentoTipo" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Audiencias" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/tipoAudiencia') ? 'active' : ''}>
                                                Tipo de Audiência
                                                <Link to="/TipoAudiencia" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/resultadoAudiencia') ? 'active' : ''}>
                                                Resultado da Audiência
                                                <Link to="/ResultadoAudiencia" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/resultadoSentenca') ? 'active' : ''}>
                                                Resultado da Sentença
                                                <Link to="/ResultadoSentenca" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Bloqueios" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/statusBloqueio') ? 'active' : ''}>
                                                Status do Bloqueio
                                                <Link to="/StatusBloqueio" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Consultivo" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/statusConsulta') ? 'active' : ''}>
                                                Status da Consulta
                                                <Link to="/StatusConsulta" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoConsulta') ? 'active' : ''}>
                                                Tipo de Consulta
                                                <Link to="/TipoConsulta" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/MotivoEncerramentoConsulta') ? 'active' : ''}>
                                                Motivo de Encerramento
                                                <Link to="/MotivoEncerramentoConsulta" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Contencioso" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/ambito') ? 'active' : ''}>
                                                Âmbito
                                                <Link to="/Ambito" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/areaDireito') ? 'active' : ''}>
                                                Área do Direito
                                                <Link to="/AreaDireito" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/classificacao') ? 'active' : ''}>
                                                Classificação
                                                <Link to="/Classificacao" />
                                            </MenuItem>
                                            <SubMenu title="CNJ" icon=''>
                                                <MenuItem className={checkIsActive(pathname, '/cnjOrgao') ? 'active' : ''}>
                                                    Órgão
                                                    <Link to="/CNJOrgao" />
                                                </MenuItem>
                                                <MenuItem className={checkIsActive(pathname, '/cnjTribunal') ? 'active' : ''}>
                                                    Tribunal
                                                    <Link to="/CNJTribunal" />
                                                </MenuItem>
                                                <MenuItem className={checkIsActive(pathname, '/cnjUnidadeOrigem') ? 'active' : ''}>
                                                    Unidade de Origem
                                                    <Link to="/CNJUnidadeOrigem" />
                                                </MenuItem>
                                            </SubMenu>
                                            <MenuItem className={checkIsActive(pathname, '/contrapartida') ? 'active' : ''}>
                                                Contrapartida
                                                <Link to="/contrapartida" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/esfera') ? 'active' : ''}>
                                                Esfera
                                                <Link to="/Esfera" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/faseProcessual') ? 'active' : ''}>
                                                Fase Processual
                                                <Link to="/FaseProcessual" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/formaParticipacao') ? 'active' : ''}>
                                                Forma da Participação
                                                <Link to="/FormaParticipacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/gestaoProcessual') ? 'active' : ''}>
                                                Gestão Processual
                                                <Link to="/gestaoProcessual" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/instancia') ? 'active' : ''}>
                                                Instância
                                                <Link to="/Instancia" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/juiz') ? 'active' : ''}>
                                                Juiz
                                                <Link to="/Juiz" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/motivoEncerramento') ? 'active' : ''}>
                                                Motivo de Encerramento
                                                <Link to="/MotivoEncerramento" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoPedido') ? 'active' : ''}>
                                                Pedido
                                                <Link to="/TipoPedido" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/risco') ? 'active' : ''}>
                                                Risco
                                                <Link to="/Risco" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/statusCitacao') ? 'active' : ''}>
                                                Status da Citação
                                                <Link to="/StatusCitacao" />
                                            </MenuItem>
                                            <MenuItem title='Status da Proposta de Acordo' className={checkIsActive(pathname, '/statusPropostaAcordo') ? 'active' : ''}>
                                                Status da Proposta de Acordo
                                                <Link to="/StatusPropostaAcordo" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/statusProcesso') ? 'active' : ''}>
                                                Status do Processo
                                                <Link to="/StatusProcesso" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/subAreaDireito') ? 'active' : ''}>
                                                Sub-área do Direito
                                                <Link to="/SubAreaDireito" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoAcao') ? 'active' : ''}>
                                                Tipo de Ação
                                                <Link to="/TipoAcao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoContingencia') ? 'active' : ''}>
                                                Tipo de Contigência
                                                <Link to="/TipoContingencia" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/TipoProvisao') ? 'active' : ''}>
                                                Tipo de Provisão
                                                <Link to="/TipoProvisao" />
                                            </MenuItem>
                                        </SubMenu>

                                        <SubMenu title="Contrato" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/papelSignatario') ? 'active' : ''}>
                                                Papel do Signatário
                                                <Link to="/PapelSignatario" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoContrato') ? 'active' : ''}>
                                                Tipo de Contrato
                                                <Link to="/TipoContrato" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Desdobramento" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/statusDesdobramento') ? 'active' : ''}>
                                                Status do Desdobramento
                                                <Link to="/statusDesdobramento" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoDesdobramento') ? 'active' : ''}>
                                                Tipo de Desdobramento
                                                <Link to="/tipoDesdobramento" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Despesas" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/statusDespesa') ? 'active' : ''}>
                                                Status da Despesa
                                                <Link to="/statusDespesa" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoDespesa') ? 'active' : ''}>
                                                Tipo de Despesa
                                                <Link to="/tipoDespesa" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Editor" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/ModeloDocumento') ? 'active' : ''}>
                                                Modelo de Documento
                                                <Link to="/ModeloDocumento" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Fornecedores" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/Fornecedores') ? 'active' : ''}>
                                                Lista de Fornecedores
                                                <Link to="/Fornecedores" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Garantias" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/statusGarantia') ? 'active' : ''}>
                                                Status da Garantia
                                                <Link to="/statusGarantia" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoGarantia') ? 'active' : ''}>
                                                Tipo de Garantia
                                                <Link to="/tipoGarantia" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="GED" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/pastaGed') ? 'active' : ''}>
                                                Pasta
                                                <Link to="/pastaGed" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoDocumento') ? 'active' : ''}>
                                                Tipo de Documento
                                                <Link to="/tipoDocumento" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Geral" icon=''>

                                            <MenuItem className={checkIsActive(pathname, '/banco') ? 'active' : ''}>
                                                Banco
                                                <Link to="/banco" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/cargo') ? 'active' : ''}>
                                                Cargo
                                                <Link to="/cargo" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/centroCusto') ? 'active' : ''}>
                                                Centro de Custo
                                                <Link to="/centroCusto" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipocomunicacao') ? 'active' : ''}>
                                                Comunicação
                                                <Link to="/tipocomunicacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/dashboard') ? 'active' : ''}>
                                                Dashboards
                                                <Link to="/dashboard" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/departamento') ? 'active' : ''}>
                                                Departamento
                                                <Link to="/departamento" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/estadoCivil') ? 'active' : ''}>
                                                Estado Civil
                                                <Link to="/estadoCivil" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/formaPagamento') ? 'active' : ''}>
                                                Forma de Pagamento
                                                <Link to="/formaPagamento" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/indice') ? 'active' : ''}>
                                                Índice
                                                <Link to="/indice" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/modulo') ? 'active' : ''}>
                                                Módulo
                                                <Link to="/modulo" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/moeda') ? 'active' : ''}>
                                                Moeda
                                                <Link to="/moeda" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/motivoDesligamento') ? 'active' : ''}>
                                                Motivo de Desligamento
                                                <Link to="/motivoDesligamento" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/periodicidade') ? 'active' : ''}>
                                                Periodicidade
                                                <Link to="/periodicidade" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/seguradora') ? 'active' : ''}>
                                                Seguradora
                                                <Link to="/seguradora" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/separador') ? 'active' : ''}>
                                                Separador
                                                <Link to="/separador" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/statusFolha') ? 'active' : ''}>
                                                Status em Folha
                                                <Link to="/statusFolha" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Imobiliário" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/statusImovel') ? 'active' : ''}>
                                                Status do Imóvel
                                                <Link to="/StatusImovel" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoAquisicao') ? 'active' : ''}>
                                                Tipo de Aquisição
                                                <Link to="/TipoAquisicao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoImovel') ? 'active' : ''}>
                                                Tipo de Imóvel
                                                <Link to="/TipoImovel" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoImovel') ? 'active' : ''}>
                                                Tipo de Localidade
                                                <Link to="/TipoLocalidade" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Localização" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/cidade') ? 'active' : ''}>
                                                Cidade
                                                <Link to="/cidade" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/estado') ? 'active' : ''}>
                                                Estado
                                                <Link to="/estado" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/pais') ? 'active' : ''}>
                                                País
                                                <Link to="/pais" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Marcas" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/classificacaoIPC') ? 'active' : ''}>
                                                Classificação do IPC
                                                <Link to="/ClassificacaoIPC" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/naturezaMarca') ? 'active' : ''}>
                                                Natureza da Marca
                                                <Link to="/NaturezaMarca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/statusMarca') ? 'active' : ''}>
                                                Status da Marca
                                                <Link to="/StatusMarca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/apresentacaoMarca') ? 'active' : ''}>
                                                Tipo de Apresentação da Marca
                                                <Link to="/ApresentacaoMarca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/classeMarca') ? 'active' : ''}>
                                                Tipo de Classe
                                                <Link to="/ClasseMarca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoMarca') ? 'active' : ''}>
                                                Tipo de Marca
                                                <Link to="/TipoMarca" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Obrigações" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/origemObrigacao') ? 'active' : ''}>
                                                Origem da Obrigação
                                                <Link to="/origemObrigacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/statusObrigacao') ? 'active' : ''}>
                                                Status da Obrigação
                                                <Link to="/statusObrigacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoObrigacao') ? 'active' : ''}>
                                                Tipo de Obrigação
                                                <Link to="/tipoObrigacao" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Procurações" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/statusProcuracao') ? 'active' : ''}>
                                                Status da Procuração
                                                <Link to="/StatusProcuracao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoInstrumento') ? 'active' : ''}>
                                                Tipo de Instrumento
                                                <Link to="/TipoInstrumento" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoLocalidade') ? 'active' : ''}>
                                                Tipo de Localidade
                                                <Link to="/TipoLocalidade" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoProcuracao') ? 'active' : ''}>
                                                Tipo de Procuração
                                                <Link to="/TipoProcuracao" />
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu title="Regulatório" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/orgaoResponsavel') ? 'active' : ''}>
                                                Órgão Responsável
                                                <Link to="/orgaoResponsavel" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoLicenca') ? 'active' : ''}>
                                                Tipo de Licença
                                                <Link to="/tipoLicenca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoServico') ? 'active' : ''}>
                                                Tipo de Serviço
                                                <Link to="/tipoServico" />
                                            </MenuItem>
                                        </SubMenu>

                                        <SubMenu title="Societário" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/atividadeEmpresa') ? 'active' : ''}>
                                                Atividade Empresa
                                                <Link to="/AtividadeEmpresa" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/atoRegistradoJunta') ? 'active' : ''}>
                                                Ato Registrado na Junta
                                                <Link to="/AtoRegistradoJunta" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/bandeira') ? 'active' : ''}>
                                                Bandeira
                                                <Link to="/Bandeira" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/qualificacao') ? 'active' : ''}>
                                                Qualificação
                                                <Link to="/Qualificacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/materiaDeliberada') ? 'active' : ''}>
                                                Matéria Deliberada
                                                <Link to="/MateriaDeliberada" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/motivoSituacao') ? 'active' : ''}>
                                                Motivo Situação
                                                <Link to="/MotivoSituacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/praca') ? 'active' : ''}>
                                                Praça
                                                <Link to="/Praca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/orgaoRegistro') ? 'active' : ''}>
                                                Órgão de Registro
                                                <Link to="/OrgaoRegistro" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/publicacaoAto') ? 'active' : ''}>
                                                Ato Publicado
                                                <Link to="/PublicacaoAto" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/statusAto') ? 'active' : ''}>
                                                Status do Ato
                                                <Link to="/StatusAto" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/subGrupo') ? 'active' : ''}>
                                                Sub Grupo
                                                <Link to="/SubGrupo" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoAto') ? 'active' : ''}>
                                                Tipo de Ato
                                                <Link to="/TipoAto" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoEmpresa') ? 'active' : ''}>
                                                Tipo de Empresa
                                                <Link to="/TipoEmpresa" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoSociedade') ? 'active' : ''}>
                                                Tipo de Sociedade
                                                <Link to="/TipoSociedade" />
                                            </MenuItem>
                                        </SubMenu>

                                        <SubMenu title="XPay" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/tipoHonorario') ? 'active' : ''}>
                                                Tipo de Honorário
                                                <Link to="/TipoHonorario" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/tipoReembolso') ? 'active' : ''}>
                                                Tipo de Reembolso
                                                <Link to="/TipoReembolso" />
                                            </MenuItem>
                                        </SubMenu>

                                    </SubMenu>}


                                {(showSubMenusAdm) &&
                                    <SubMenu title="Gestão de Acessos" icon=''>
                                        <MenuItem className={checkIsActive(pathname, '/administracao/seguranca/AdvogadoInterno') ? 'active' : ''}>
                                            Advogado Interno
                                            <Link to="/administracao/seguranca/AdvogadoInterno" />
                                        </MenuItem>
                                        <MenuItem className={checkIsActive(pathname, '/administracao/seguranca/grupousuarios') ? 'active' : ''}>
                                            Grupo de Usuários
                                            <Link to="/administracao/seguranca/grupousuarios" />
                                        </MenuItem>
                                        <MenuItem className={checkIsActive(pathname, '/administracao/seguranca/perfilpermissoes') ? 'active' : ''}>
                                            Perfil e Permissões
                                            <Link to="/administracao/seguranca/perfilpermissoes" />
                                        </MenuItem>
                                        <MenuItem className={checkIsActive(pathname, '/administracao/seguranca/usuarios') ? 'active' : ''}>
                                            Usuários
                                            <Link to="/administracao/seguranca/usuarios" />
                                        </MenuItem>
                                        <MenuItem className={checkIsActive(pathname, '/administracao/seguranca/xfinder') ? 'active' : ''}>
                                            XFinder
                                            <Link to="/administracao/seguranca/xfinder" />
                                        </MenuItem>
                                    </SubMenu>}

                                {(showSubMenusAdm) &&
                                    <SubMenu title="Clientes" icon=''>
                                        <MenuItem className={checkIsActive(pathname, '/Clientes') ? 'active' : ''}>
                                            Clientes
                                            <Link to="/Cliente" />
                                        </MenuItem>
                                    </SubMenu>}


                                <SubMenu title="Parametrizações" icon=''>

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Audiência" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Audiencia/ConfiguracaoTela/TipoAudiencia') ? 'active' : ''}>
                                                Tipo de Audiência
                                                <Link to="Audiencia/ConfiguracaoTela/TipoAudiencia" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Audiencia/ConfiguracaoTela/Pesquisa/${EModulo.Audiencia}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Audiencia/ConfiguracaoTela/Pesquisa/${EModulo.Audiencia}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Automação" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Automacao/') ? 'active' : ''}>
                                                Automação
                                                <Link to="Automacao/" />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    <SubMenu title="Contencioso" icon=''>
                                        <MenuItem className={checkIsActive(pathname, '/Processo/ConfiguracaoTela/AreaDireito') ? 'active' : ''}>
                                            Área do Direito
                                            <Link to="Processo/ConfiguracaoTela/AreaDireito" />
                                        </MenuItem>
                                        <MenuItem className={checkIsActive(pathname, `/Processo/ConfiguracaoTela/Pesquisa/${EModulo.AreaDireito}`) ? 'active' : ''}>
                                            Pesquisa
                                            <Link to={`Processo/ConfiguracaoTela/Pesquisa/${EModulo.AreaDireito}`} />
                                        </MenuItem>
                                        <MenuItem className={checkIsActive(pathname, '/Processo/ConfiguracaoRelatorio') ? 'active' : ''}>
                                            Relatório
                                            <Link to="Processo/ConfiguracaoRelatorio" />
                                        </MenuItem>
                                    </SubMenu>


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Bloqueio" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Bloqueio/ConfiguracaoTela/TipoBloqueio') ? 'active' : ''}>
                                                Tipo de Bloqueio
                                                <Link to="Bloqueio/ConfiguracaoTela/TipoBloqueio" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Bloqueio/ConfiguracaoTela/Pesquisa/${EModulo.Bloqueio}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Bloqueio/ConfiguracaoTela/Pesquisa/${EModulo.Bloqueio}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Comunicação" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Comunicacao/ConfiguracaoTela/TipoComunicacao') ? 'active' : ''}>
                                                Tipo de Comunicação
                                                <Link to="Comunicacao/ConfiguracaoTela/TipoComunicacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Comunicacao/ConfiguracaoTela/Pesquisa/${EModulo.Comunicacao}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Comunicacao/ConfiguracaoTela/Pesquisa/${EModulo.Comunicacao}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Contrato" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/TipoContrato') ? 'active' : ''}>
                                                Tipo de Contrato
                                                <Link to="Contrato/ConfiguracaoTela/TipoContrato" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Contrato/ConfiguracaoTela/Pesquisa/${EModulo.Contrato}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Contrato/ConfiguracaoTela/Pesquisa/${EModulo.Contrato}`} />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/Relatorio') ? 'active' : ''}>
                                                Relatório
                                                <Link to="Contrato/ConfiguracaoRelatorio" />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Consultivo" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/Consulta') ? 'active' : ''}>
                                                Tipo de Consulta
                                                <Link to="Consulta/ConfiguracaoTela/TipoConsulta" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Consulta/ConfiguracaoTela/Pesquisa/${EModulo.Consultivo}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Consulta/ConfiguracaoTela/Pesquisa/${EModulo.Consultivo}`} />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/Consulta/ConfiguracaoRelatorio') ? 'active' : ''}>
                                                Relatório
                                                <Link to="Consulta/ConfiguracaoRelatorio" />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Decisão" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Decisao/ConfiguracaoTela/TipoDecisao') ? 'active' : ''}>
                                                Tipo de Decisão
                                                <Link to="Decisao/ConfiguracaoTela/TipoDecisao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Decisao/ConfiguracaoTela/Pesquisa/${EModulo.Decisao}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Decisao/ConfiguracaoTela/Pesquisa/${EModulo.Decisao}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Despesa" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Despesa/ConfiguracaoTela/TipoDespesa') ? 'active' : ''}>
                                                Tipo de Despesa
                                                <Link to="Despesa/ConfiguracaoTela/TipoDespesa" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Despesa/ConfiguracaoTela/Pesquisa/${EModulo.Despesa}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Despesa/ConfiguracaoTela/Pesquisa/${EModulo.Despesa}`} />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/Relatorio') ? 'active' : ''}>
                                                Relatório
                                                <Link to="Despesa/ConfiguracaoRelatorio" />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Desdobramento" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Desdobramento/ConfiguracaoTela/TipoDesdobramento') ? 'active' : ''}>
                                                Tipo de Desdobramento
                                                <Link to="Desdobramento/ConfiguracaoTela/TipoDesdobramento" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Desdobramento/ConfiguracaoTela/Pesquisa/${EModulo.Desdobramento}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Desdobramento/ConfiguracaoTela/Pesquisa/${EModulo.Desdobramento}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    <SubMenu title="Fechamento" icon=''>
                                        <MenuItem className={checkIsActive(pathname, '/FechamentoGlossario') ? 'active' : ''}>
                                            Configuração
                                            <Link to="Fechamento/ConfiguracaoTela/FechamentoConfiguracao" />
                                        </MenuItem>

                                        <MenuItem className={checkIsActive(pathname, '/FechamentoGlossario') ? 'active' : ''}>
                                            Glossário do Fechamento
                                            <Link to="Fechamento/ConfiguracaoTela/FechamentoGlossario" />
                                        </MenuItem>
                                    </SubMenu>



                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Garantia" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Garantia/ConfiguracaoTela/TipoGarantia') ? 'active' : ''}>
                                                Tipo de Garantia
                                                <Link to="Garantia/ConfiguracaoTela/TipoGarantia" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Garantia/ConfiguracaoTela/Pesquisa/${EModulo.Garantia}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Garantia/ConfiguracaoTela/Pesquisa/${EModulo.Imovel}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }



                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Imobiliário" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/Imovel') ? 'active' : ''}>
                                                Tipo de Imóvel
                                                <Link to="Imovel/ConfiguracaoTela/TipoImovel" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Imovel/ConfiguracaoTela/Pesquisa/${EModulo.Imovel}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Imovel/ConfiguracaoTela/Pesquisa/${EModulo.Imovel}`} />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/Relatorio') ? 'active' : ''}>
                                                Relatório
                                                <Link to="Imovel/ConfiguracaoRelatorio" />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Marca e Patentes" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/TipoMarca') ? 'active' : ''}>
                                                Tipo de Marca
                                                <Link to="/Marca/ConfiguracaoTela/TipoMarca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Marca/ConfiguracaoTela/Pesquisa/${EModulo.MarcaEPertences}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Marca/ConfiguracaoTela/Pesquisa/${EModulo.MarcaEPertences}`} />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Marca/ConfiguracaoRelatorio`) ? 'active' : ''}>
                                                Relatório
                                                <Link to={`Marca/ConfiguracaoRelatorio`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Obrigação" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Obrigacao/ConfiguracaoTela/TipoObrigacao') ? 'active' : ''}>
                                                Tipo de Obrigação
                                                <Link to="Obrigacao/ConfiguracaoTela/TipoObrigacao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Obrigacao/ConfiguracaoTela/Pesquisa/${EModulo.Obrigacao}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Obrigacao/ConfiguracaoTela/Pesquisa/${EModulo.Obrigacao}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Parte Contrária" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'ParteContraria/ConfiguracaoTela/TipoParteContraria') ? 'active' : ''}>
                                                Parte Contraria
                                                <Link to="ParteContraria/ConfiguracaoTela/TipoParteContraria" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/ParteContraria/ConfiguracaoTela/Pesquisa/${EModulo.ParteContraria}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`ParteContraria/ConfiguracaoTela/Pesquisa/${EModulo.ParteContraria}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Procuracao" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/Procuracao') ? 'active' : ''}>
                                                Tipo de Procuracão
                                                <Link to="Procuracao/ConfiguracaoTela/TipoProcuracao" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Procuracao/ConfiguracaoTela/Pesquisa/${EModulo.Procuracao}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Procuracao/ConfiguracaoTela/Pesquisa/${EModulo.Procuracao}`} />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `Procuracao/ConfiguracaoRelatorio`) ? 'active' : ''}>
                                                Relatório
                                                <Link to={`Procuracao/ConfiguracaoRelatorio`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Proposta de Acordo" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'PropostaAcordo/ConfiguracaoTela/TipoPropostaAcordo') ? 'active' : ''}>
                                                Tipo de Proposta Acordo
                                                <Link to="PropostaAcordo/ConfiguracaoTela/TipoPropostaAcordo" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/PropostaAcordo/ConfiguracaoTela/Pesquisa/${EModulo.PropostaAcordo}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`PropostaAcordo/ConfiguracaoTela/Pesquisa/${EModulo.PropostaAcordo}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Protocolo" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Protocolo/ConfiguracaoTela/TipoProtocolo') ? 'active' : ''}>
                                                Tipo de Protocolo
                                                <Link to="Protocolo/ConfiguracaoTela/TipoProtocolo" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Protocolo/ConfiguracaoTela/Pesquisa/${EModulo.Protocolo}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Protocolo/ConfiguracaoTela/Pesquisa/${EModulo.Protocolo}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Regulatório" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/TipoLicenca') ? 'active' : ''}>
                                                Tipo de Licença
                                                <Link to="/Licenca/ConfiguracaoTela/TipoLicenca" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Licenca/ConfiguracaoTela/Pesquisa/${EModulo.Regulatorio}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Licenca/ConfiguracaoTela/Pesquisa/${EModulo.Regulatorio}`} />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Regulatorio/ConfiguracaoRelatorio`) ? 'active' : ''}>
                                                Relatório
                                                <Link to={`Regulatorio/ConfiguracaoRelatorio`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) && 
                                    <SubMenu title="Relatório" icon=''>
                                        <MenuItem className={checkIsActive(pathname, '/Relatorio/ConfiguracaoRelatorio') ? 'active' : ''}>
                                            Relatórios
                                            <Link to="Relatorio/ConfiguracaoRelatorio" />
                                        </MenuItem>
                                    </SubMenu>   
                                    
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Societário" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Empresa/ConfiguracaoTela/TipoSociedade') ? 'active' : ''}>
                                                Empresa
                                                <Link to="Empresa/ConfiguracaoTela/TipoSociedade" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, 'Ato/ConfiguracaoTela/TipoAto') ? 'active' : ''}>
                                                Tipo de Ato
                                                <Link to="Ato/ConfiguracaoTela/TipoAto" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, '/Relatorio') ? 'active' : ''}>
                                                Relatório
                                                <Link to="Societario/ConfiguracaoRelatorio" />
                                            </MenuItem>
                                        </SubMenu>
                                    }


                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Workflow" icon=''>
                                            <MenuItem className={checkIsActive(pathname, '/Fluxo') ? 'active' : ''}>
                                                Workflow
                                                <Link to="/Fluxo" />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                    {(showSubMenusAdm) &&
                                        <MenuItem className={checkIsActive(pathname, '/XCampo') ? 'active' : ''}>
                                            XCampo
                                            <Link to="/XCampo" />
                                        </MenuItem>}

                                    {(showSubMenusAdm) &&
                                        <SubMenu title="Xpay" icon=''>
                                            <MenuItem className={checkIsActive(pathname, 'Xpay/Configuracao/Contrato') ? 'active' : ''}>
                                                Contrato
                                                <Link to="Xpay/Configuracao/Contrato" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, 'Xpay/ConfiguracaoTela/TipoHonorario') ? 'active' : ''}>
                                                Tipo de Honorário
                                                <Link to="Xpay/ConfiguracaoTela/TipoHonorario" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, 'Xpay/ConfiguracaoTela/TipoReembolso') ? 'active' : ''}>
                                                Tipo de Reembolso
                                                <Link to="Xpay/ConfiguracaoTela/TipoReembolso" />
                                            </MenuItem>
                                            <MenuItem className={checkIsActive(pathname, `/Xpay/ConfiguracaoTela/Pesquisa/${EModulo.XpayHonorario}`) ? 'active' : ''}>
                                                Pesquisa
                                                <Link to={`Xpay/ConfiguracaoTela/Pesquisa/${EModulo.XpayHonorario}`} />
                                            </MenuItem>
                                        </SubMenu>
                                    }

                                </SubMenu>

                                <SubMenu title="Change Log" icon=''>
                                    <MenuItem className={checkIsActive(pathname, '/ChangeLogs') ? 'active' : ''}>
                                        Configuração
                                        <Link to="/ChangeLogs" />
                                    </MenuItem>
                                    <MenuItem className={checkIsActive(pathname, '/ChangeLogEmail') ? 'active' : ''}>
                                        Notificação E-mail
                                        <Link to="/ChangeLogEmail" />
                                    </MenuItem>
                                </SubMenu>

                                {(showSubMenusAdm) &&
                                    <SubMenu title="Migrações" icon=''>
                                        <MenuItem className={checkIsActive(pathname, '/administracao/seguranca/migracoes') ? 'active' : ''}>
                                            Migrações
                                            <Link to="/administracao/seguranca/migracoes" />
                                        </MenuItem>
                                    </SubMenu>
                                }
                            </SubMenu>
                        }

                        {

                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.relatorio) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: true, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.relatorio} title="Relatório" icon={<FaFile size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Relatorio') ? 'active' : ''}>
                                    Relatório
                                    <Link to="/Relatorio" />
                                </MenuItem>
                            </SubMenu>
                        }


                        {(showSocietario) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.societario) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: true, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.societario} title="Societário" icon={<FaUserFriends size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Societario/Empresa') ? 'active' : ''}>
                                    Empresas
                                    <Link to="/Societario/Empresa" />
                                </MenuItem>
                                <MenuItem className={checkIsActive(pathname, '/grupoEconomico') ? 'active' : ''}>
                                    Grupo Econômico
                                    <Link to="/grupoEconomico" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showContencioso || showDashboard) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.contencioso) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: true, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.contencioso} title="Contencioso" icon={<FaBalanceScale size={25} />}>
                                <MenuItem className={checkIsActive(pathname, "/Processo") ? "active" : ""}>
                                    Processo
                                    <Link to="/Processo" />
                                </MenuItem>

                                <MenuItem className={checkIsActive(pathname, '/Fechamento') ? 'active' : ''}>
                                    Fechamento
                                    <Link to="/Fechamento" />
                                </MenuItem>

                                <SubMenu title="Dashboards" icon=''>
                                    {showDashboard &&
                                        <MenuItem className={checkIsActive(pathname, '/Processo/Dashboard') ? 'active' : ''}>
                                            Dashboard
                                            <Link to="/Processo/Dashboard" />
                                        </MenuItem>}
                                    <MenuItem className={checkIsActive(pathname, '/Contencioso/Dashboards/DashboardFechamento') ? 'active' : ''}>
                                        Fechamento
                                        <Link to="/Contencioso/Dashboards/DashboardFechamento" />
                                    </MenuItem>

                                </SubMenu>
                                {
                                    (showXPayFechamento) &&
                                    <SubMenu title="XPay" icon=''>
                                        <MenuItem className={checkIsActive(pathname, '/XPay') ? 'active' : ''}>
                                            Fechamento
                                            <Link to="/XPay/Fechamento" />
                                        </MenuItem>
                                    </SubMenu>
                                }
                            </SubMenu>
                        }

                        {
                            (showXFinder) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.xfinder) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: true, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.xfinder} title="XFinder" icon={<FaSearch size={25} />}>

                                <MenuItem className={checkIsActive(pathname, '/Andamento') ? 'active' : ''}>
                                    Andamentos
                                    <Link to="Andamento" />
                                </MenuItem>

                                <MenuItem className={checkIsActive(pathname, '/XFinderDistribuicao') ? 'active' : ''}>
                                    Distribuições
                                    <Link to="XFinder/XFinderDistribuicao" />
                                </MenuItem>
                                <MenuItem className={checkIsActive(pathname, '/XFinderIntimacao') ? 'active' : ''}>
                                    Intimações / DJE
                                    <Link to="XFinder/XFinderIntimacao" />
                                </MenuItem>
                                <MenuItem className={checkIsActive(pathname, '/Publicacao') ? 'active' : ''}>
                                    Publicações
                                    <Link to="Publicacao" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showXSigner) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.xsigner) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: true, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.xsigner} title="XSigner" icon={<FaSignature size={25} />}>
                                <MenuItem className={checkIsActive(pathname, 'XSigner/Envelopes') ? 'active' : ''}>
                                    Envelopes
                                    <Link to="XSigner/Envelopes" />
                                </MenuItem>
                                <MenuItem className={checkIsActive(pathname, 'XSigner/Dashboard') ? 'active' : ''}>
                                    Dashboard
                                    <Link to="XSigner/Dashboard" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showContrato) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.contrato) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: true, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.contrato} title="Contratos" icon={<FaFileContract size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Contrato') ? 'active' : ''}>
                                    Contrato
                                    <Link to="/Contrato" />
                                </MenuItem>
                                <MenuItem className={checkIsActive(pathname, '/Contrato/Dashboard') ? 'active' : ''}>
                                    Dashboard
                                    <Link to="/Contrato/Dashboard" />
                                </MenuItem>
                                <MenuItem className={checkIsActive(pathname, '/Contrato/PowerBI') ? 'active' : ''}>
                                    Power BI
                                    <Link to="/Contrato/PowerBI" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showProcuracao) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.procuracao) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: true, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.procuracao} title="Procurações" icon={<FaRegNewspaper size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Procuracao') ? 'active' : ''}>
                                    Procuração
                                    <Link to="/Procuracao" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showConsultivo) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.consultivo) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: true, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.consultivo} title="Consultivo" icon={<FaQuestionCircle size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Consulta') ? 'active' : ''}>
                                    Consulta
                                    <Link to="/Consulta" />
                                </MenuItem>
                                <MenuItem className={checkIsActive(pathname, '/Consulta/FAQ') ? 'active' : ''}>
                                    FAQ
                                    <Link to="/Consulta/FAQ" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showImobiliario) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.imobiliario) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: true, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.imobiliario} title="Imobiliário" icon={<FaWarehouse size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Imovel') ? 'active' : ''}>
                                    Imóvel
                                    <Link to="/Imovel" />
                                </MenuItem>
                                {showDashboard &&
                                    <MenuItem className={checkIsActive(pathname, '/Imovel/Dashboard') ? 'active' : ''}>
                                        Dashboard
                                        <Link to="/Imovel/Dashboard" />
                                    </MenuItem>}
                                {showDespesa &&
                                    <MenuItem className={checkIsActive(pathname, '/Imovel/DespesaFiltro') ? 'active' : ''}>
                                        Despesa
                                        <Link to="/Imovel/DespesaFiltro" />
                                    </MenuItem>}
                            </SubMenu>
                        }

                        {
                            (showMarcasEPatentes) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.marcasEPatentes) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: true, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }

                            }} open={submenuSelecionado.marcasEPatentes} title="Marcas e Patentes" icon={<FaRegistered size={25} />}>

                                <MenuItem className={checkIsActive(pathname, '/Marca') ? 'active' : ''}>
                                    Marcas e Patentes
                                    <Link to="/Marca" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showGed) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.ged) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: true, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.ged} title="GED" icon={<FaRegFileArchive size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Ged') ? 'active' : ''}>
                                    Documento
                                    <Link to="/Ged" />
                                </MenuItem>
                            </SubMenu>
                        }

                        {
                            (showRegulatorio) &&
                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.regulatorio) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: true });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.regulatorio} title="Regulatório" icon={<FaClipboardCheck size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Licenca') ? 'active' : ''}>
                                    Registros
                                    <Link to="/Licenca" />
                                </MenuItem>

                                <MenuItem className={checkIsActive(pathname, '/Licenca/Dashboard') ? 'active' : ''}>
                                    Dashboard
                                    <Link to="/Licenca/Dashboard" />
                                </MenuItem>

                                <MenuItem className={checkIsActive(pathname, '/LicencaLimites') ? 'active' : ''}>
                                    Limites de Outorgas
                                    <Link to="/LicencaLimites" />
                                </MenuItem>

                            </SubMenu>
                        }

                        {

                            <SubMenu onOpenChange={(open) => {
                                if (open || !submenuSelecionado.xlearning) {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: true, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                } else {
                                    setSubemnuSelecionado({ ...submenuSelecionado, adm: false, xlearning: false, relatorio: false, societario: false, contencioso: false, xfinder: false, xsigner: false, contrato: false, procuracao: false, consultivo: false, imobiliario: false, marcasEPatentes: false, ged: false, regulatorio: false });
                                }
                            }} open={submenuSelecionado.xlearning} title="XLearning" icon={<FaBookOpen size={25} />}>
                                <MenuItem className={checkIsActive(pathname, '/Xlearning') ? 'active' : ''}>
                                    Conteúdo
                                    <Link to="/Xlearning" />
                                </MenuItem>
                            </SubMenu>
                        }


                    </Menu>
                }

            </SidebarContent>
            <SidebarFooter className="bg-orange text-center text-white pt-3">
                <p>
                    <a target='blank' href="https://www.xjur.com.br">
                        <img width="35" height="35" className="ms-6" style={{ maxHeight: '50px' }} src={process.env.PUBLIC_URL + '/images/XJUR - LOGO_BRANCO.png'} alt="Orange" />
                    </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* Ajuste de link do Suporte WhatsApp */}
                    <a className="linkVisitado text-white" href="https://web.whatsapp.com/send?phone=+5511974779371&text=Ol%C3%A1%2C+XJUR+preciso+de+um+auxilio+com+o+sistema,+pode+me+ajudar?" target="_blank">
                        {/* <img src="http://www.w3.org/2000/svg" width="16" height="16"></img> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                        </svg>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* Ajuste de Link para youtube */}
                    <a className="linkVisitado text-white" href="https://www.youtube.com/channel/UCPs24f0WdWsbXMBbdVm48TQ" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                        </svg>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* Ajuste de Link para Instagram */}
                    <a className="linkVisitado text-white" href="https://www.instagram.com/xjursystem" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                        </svg>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* Ajuste de Link para Linkedin */}
                    <a className="linkVisitado text-white" href="https://www.linkedin.com/in/xjur-system-56061b23a/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                        </svg>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
            </SidebarFooter>
        </ProSidebar>
    );
}
