import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import {
  faAddressCard,
  faCheckCircle,
  faEraser,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import GridPadrao from "../../components/Comum/GridPadrao";
import { useCallback, useEffect, useState } from "react";
import IPerfil from "../../interfaces/IPerfil";
import { TableColumn } from "react-data-table-component";
import PermissoesModal from "./PerfilPermissoesModal";
import PerfilService from "../../services/PerfilService";
import Swal from "sweetalert2";
import IPerfilLista from "../../interfaces/IPerfilLista";
import FiltroPesquisaPerfisRequest from "../../interfaces/Requests/Perfil/FiltroPesquisaPerfisRequest";
import { useNavigate } from "react-router-dom";
import PerfilEditModal from "./PerfilEditModal";
import PerfilDetalhesModal from "./PerfilDetalhesModal";

export default function PerfilPermissoesPage() {
  const [perfis, setPerfis] = useState<IPerfil[]>([]);
  const [perfilEdit, setPerfilEdit] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);
  const [perfilDetails, setPerfilDetails] = useState<IPerfil>({
    perfilUsuarioId: 0,
    nome: "",
    clienteId: 0,
    perfilInicial: false,
    perfilAdHash: null,
    permissoesReadDto: [],
    status: 1,
    usuarios: [],
  });
  const [filtros, setFiltros] = useState<FiltroPesquisaPerfisRequest>({
    nome: "",
    status: "0",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "id",
  });
  const [pesquisaFlag, setPesquisaFlag] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    carregarPerfis(filtros);
  }, [filtros.status]);

  const carregarPerfis = useCallback(
    async (filtro: FiltroPesquisaPerfisRequest) => {
      try {
        setLoading(true);

        const response: IPerfilLista = await PerfilService.obterPerfis(filtro);

        setFiltros((oldState) => {
          return { ...oldState, totalItems: response.totalRegistros };
        });

        setPerfis(response.data);
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          title: "Não foi possível obter registros",
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          timer: 4000,
          icon: "error",
          showConfirmButton: false,
        });

        setPerfis([]);
      } finally {
        setLoading(false);
        setPesquisaFlag(false);
      }
    },
    []
  );

  const pesquisarPerfis = async (nome: string) => {
    try {
      setPesquisaFlag(true);
      const response: IPerfilLista = await PerfilService.pesquisaPerfis(nome);

      setFiltros((oldState) => {
        return { ...oldState, totalItems: response.totalRegistros };
      });

      setPerfis(response.data);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });

      setPerfis([]);
    } finally {
      setLoading(false);
      setPesquisaFlag(false);
    }
  };

  const renderCell = (perfil: IPerfil) => {
    if (perfil.status === -1) {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Perfil"
              onClick={() => {
                editPerfilModal(perfil.perfilUsuarioId);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Ativar Perfil"
              onClick={() => {
                handleActive(perfil.perfilUsuarioId);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faCheckCircle}
            />
          </div>

          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Detalhes Perfil"
              onClick={() => {
                detailsPerfilModal(perfil);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              color="grey"
              icon={faAddressCard}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Perfil"
              onClick={() => {
                editPerfilModal(perfil.perfilUsuarioId);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Desativar Perfil"
              onClick={() => {
                handleDelete(perfil.perfilUsuarioId);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faTrashAlt}
            />
          </div>
          <div style={{ paddingRight: "10px" }}>
            <FontAwesomeIcon
              title="Detalhes Perfil"
              onClick={() => {
                detailsPerfilModal(perfil);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faAddressCard}
            />
          </div>
        </>
      );
    }
  };

  const handleRowClicked = (row: IPerfil): void => {
    navigate(
      `/Administracao/Seguranca/PerfilPermissoes/${row.perfilUsuarioId}`
    );
  };

  const editPerfilModal = (perfil: number) => {
    if (perfil) {
      setPerfilEdit(perfil);
      setOpenEditModal(true);
    } else {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel carregar o perfil`,
        text: "Não foi possível alterar o status do perfil.",
        showConfirmButton: true,
      });
    }
  };

  const detailsPerfilModal = (perfil: IPerfil) => {
    setPerfilDetails(perfil);
    setOpenDetailsModal(true);
  };

  const colunas: TableColumn<IPerfil>[] = [
    {
      name: "Id",
      sortField: "perfilUsuarioId",
      selector: (row: IPerfil) => row.perfilUsuarioId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IPerfil) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Hash AD",
      sortField: "hashAd",
      selector: (row: IPerfil) => {
        if (row.perfilAdHash === "" || !row.perfilAdHash) {
          return "";
        } else {
          return row.perfilAdHash;
        }
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Perfil Inicial?",
      sortField: "perfilInicial",
      selector: (row: IPerfil) => (row.perfilInicial ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });

    await carregarPerfis({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((oldState) => {
      return { ...oldState, offset: (page - 1) * filtros.limit };
    });

    if (!pesquisaFlag) {
      await carregarPerfis({ ...filtros, offset: (page - 1) * filtros.limit });
    }
  };

  const handleSort = async (
    column: TableColumn<IPerfil>,
    sortDirection: string
  ) => {
    setFiltros((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await carregarPerfis({
      ...filtros,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    });
  };

  const limparFiltros = async () => {
    setFiltros((oldState) => {
      return {
        ...oldState,
        nome: "",
        status: "0",
      };
    });

    await carregarPerfis({ ...filtros, nome: "", status: "0" });
  };

  const handleActive = async (perfilUsuarioId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await PerfilService.ativaPerfil(perfilUsuarioId.toString(), {
          Status: true,
        });

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: "Status do perfil alterado com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();

        setFiltros((oldState) => {
          carregarPerfis(oldState);
          return oldState;
        });
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o status do perfil`,
        text:
          e?.response?.data?.message ??
          "Não foi possível alterar o status do perfil.",
        showConfirmButton: true,
      });
    }
  };

  const handleDelete = async (perfilUsuarioId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await PerfilService.removePerfil(perfilUsuarioId.toString());

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: "Status do perfil alterado com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();

        setFiltros((oldState) => {
          carregarPerfis(oldState);
          return oldState;
        });
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o status do perfil`,
        text:
          e?.response?.data?.message ??
          "Não foi possível alterar o status do perfil.",
        showConfirmButton: true,
      });
    }
  };

  return (
    <>
      <LayoutPageTitle title="Perfil e Permissões">
        <button
          onClick={() => {
            setOpenModal(true);
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <PermissoesModal
        exibirModal={openModal}
        handleClose={() => {
          setOpenModal(false);
          carregarPerfis(filtros);
        }}
      />

      <PerfilEditModal
        exibirModal={openEditModal}
        perfil={perfilEdit}
        handleClose={() => {
          setOpenEditModal(false);
          carregarPerfis(filtros);
        }}
      />

      <PerfilDetalhesModal
        titulo="Detalhes do Perfil"
        toggleModal={() => {
          setOpenDetailsModal(false);
        }}
        exibirModal={openDetailsModal}
        perfil={perfilDetails}
      />

      <div className="col-md-12">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            pesquisarPerfis(filtros.nome);
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Nome do Perfil:
            </label>
            <input
              value={filtros.nome}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return { ...oldState, nome: e.target.value };
                });
              }}
              placeholder="Nome"
              type="text"
              className="form-control"
              id="form-nome"
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Status do perfil:
            </label>

            <select
              onChange={(e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return { ...oldState, status: e.target.value };
                });
              }}
              placeholder="Status"
              className="form-control"
              id="form-select"
            >
              <option value="0" label="Todos">
                Todos
              </option>

              <option value="1" label="Ativo">
                Ativo
              </option>

              <option value="-1" label="Inativo">
                Inativo
              </option>
            </select>
          </div>

          <div className="col-md-2">
            <div>
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            progressPending={loading}
            limit={filtros.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtros.totalItems}
            onRowClicked={handleRowClicked}
            colunas={colunas}
            tipo="Perfis"
            itens={perfis}
          />
        </div>
      </div>
    </>
  );
}
