import React, { FC } from 'react';
import { FiSettings } from 'react-icons/fi';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge, getSmoothStepPath } from 'reactflow';

const CustomEdgeLabel: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{stroke: 'black', strokeWidth: '3',}}  markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
      <div className="nodeFaseIcon" >
            <h1>CONF</h1>
              <FiSettings />
          </div>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#f1f1f1',
            border: '1px solid grey',
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 700,
            strokeWidth: 3,
            stroke: "black"
          }}
          className="nodrag nopan"
        >
        
          
          {data.label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdgeLabel;
