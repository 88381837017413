import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusGarantia from "../interfaces/IStatusGarantia";
import { AdicionaStatusGarantiaRequest } from "../interfaces/Requests/StatusGarantia/AdicionaStatusGarantiaRequest";
import FiltroPesquisaStatusGarantiaRequest from "../interfaces/Requests/StatusGarantia/FiltroPesquisaStatusGarantiaRequest";
import { EditarStatusGarantiaRequest } from "../interfaces/Requests/StatusGarantia/EditarStatusGarantiaRequest";

class StatusGarantiaService {

    async obterStatusGarantias(filtros: FiltroPesquisaStatusGarantiaRequest): Promise<IRecuperaLista<IStatusGarantia>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusGarantia>>(`StatusGarantia/RecuperaStatusGarantia?${query}`);

        return result;
    }

    async adicionaStatusGarantia(adicionaStatusGarantia: AdicionaStatusGarantiaRequest): Promise<IStatusGarantia> {
        let { data: result } = await api.post<IStatusGarantia>(`StatusGarantia/AdicionaStatusGarantia`, adicionaStatusGarantia);

        return result;
    }


    async alterarStatus(statusGarantiaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusGarantia>(`StatusGarantia/AtualizaStatusGarantiaPorId?id=${statusGarantiaId}`, data);
    }

    async atualizarStatusGarantia(editarStatusGarantia: EditarStatusGarantiaRequest): Promise<void> {

        let dataStatusGarantia = [
            { "op": "replace", "path": "/nome", "value": editarStatusGarantia.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusGarantia.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusGarantia/AtualizaStatusGarantiaPorId?id=${editarStatusGarantia.statusGarantiaId}`, dataStatusGarantia)
        ]);
    }
}


export default new StatusGarantiaService();