import { useState, useEffect } from 'react';
import { Handle, Position, useReactFlow, useStore } from 'reactflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FiSettings, FiTrash } from 'react-icons/fi';
import { IoMdMove } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import Select from "react-select";
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import ENodeEnum from '../../../../../enum/ENodeEnum';

import { ICampo } from '../../../../../interfaces/ICampo';
import IOption from '../../../../../interfaces/IOption';

import TabelaOrigemService from '../../../../../services/TabelaOrigem';
import CampoFluxoRegraSerivce from '../../../../../services/CampoFluxoRegraService';
import sessionStorageService from '../../../../../services/sessionStorage/sessionStorageService';

import './styles/NodeCondicao.css';
import { notifySuccess } from '../../../../../components/Comum/Toast/ToastFormik';
import { ICondicao } from '../../../../../interfaces/IFase';

const connectionNodeIdSelector = (state: any) => state.connectionNodeId;

export default function NodeCondicao({ id, data }: { id: any, data: any }) {

    const { getNodes, getEdges } = useReactFlow();

    const [exibirModal, setExibirModal] = useState(false);

    const [campos, setCampos] = useState<ICampo[]>([]);
    const [camposOption, setCamposOptions] = useState<IOption[]>([]);

    const [tabelaOrigem, setTabelaOrigem] = useState<IOption[]>([]);

    const connectionNodeId = useStore<any>(connectionNodeIdSelector);
    const isConnecting = !!connectionNodeId;
    const isTarget = connectionNodeId && connectionNodeId !== id;

    const toggleModal = () => {
        const connectedEdge = getEdges().find((edge: any) => {
            const sourceNode = getNodes().find((node: any) => node.id === edge.source);
            const targetNode = getNodes().find((node: any) => node.id === edge.target);
            return (
                (edge.source === id && sourceNode?.type === ENodeEnum.NodeFase) ||
                (edge.target === id && targetNode?.type === ENodeEnum.NodeCondicao)
            );
        });

        const connectedNode = getNodes().find((node: any) => node.id === connectedEdge?.source || node.id === connectedEdge?.target);
        data.codigoAnteriorFaseId = connectedNode?.id || null;
        setExibirModal(!exibirModal);
    };

    const initialValues = {
        id: data.id,
        codigo: data.id,
        nome: data.nome,
        campoId: data.campoId,
        isXCampo: data.isXCampo,
        campoNome: data.campoNome,
        campoTipo: data.campoTipo,
        tabelaOrigemId: data.tabelaOrigemId,
        tabelaOrigemNome: data.tabelaOrigemNome,
        codigoProximaFaseId: data.codigoProximaFaseId,
        codigoAnteriorFaseId: data.codigoAnteriorFaseId,
        regras: []
    };

    useEffect(() => {
        if (exibirModal) {
            carregarTabelaOrigem();
            formik.setValues(initialValues);
        }
    }, [exibirModal]);

    const schema = Yup.object({
        nome: Yup.string().required('Campo é obrigatório'),
        campoId: Yup.number().min(1, 'Campo é obrigatório').required('Campo é obrigatório'),
        tabelaOrigemId: Yup.number().min(1, 'Campo é obrigatório').required('Campo é obrigatório'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const swalWithBootstrapButtons = Swal.mixin({
                    heightAuto: false,
                    customClass: {
                        confirmButton: "btn btn-orange",
                        cancelButton: "btn btn-danger ms-5",
                    },
                    buttonsStyling: false,
                });

                const result = await swalWithBootstrapButtons.fire({
                    title: "Condição",
                    text: `Você realmente deseja adicionar?`,
                    showCancelButton: true,
                    cancelButtonText: "Cancelar",
                    confirmButtonText: `Confirmar`,
                });

                if (result.isConfirmed) {
                    atualizar("atualizaNode", JSON.stringify({ data: values, id: id }));
                    notifySuccess('Condição da regra adicionada.');
                }
            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: "error",
                    title: `Não foi possível salvar esta solicitação`,
                    text: error.response.data.Message,
                    showConfirmButton: true,
                });
            } finally {
                setSubmitting(false);
                toggleModal();
            }
        },
    });

    useEffect(() => {
        if (formik.values?.tabelaOrigemNome) {
            carregarCampos(formik.values.tabelaOrigemNome);
        }
    }, [formik.values.tabelaOrigemNome]);

    const carregarCampos = async (tabelaOrigemNome: string) => {
        try {

            let options: IOption[] = [];

            const resultados: any = await CampoFluxoRegraSerivce.obter({
                codigo: "",
                status: 1,
                limit: 1000,
                totalItems: 0,
                offset: 0,
                sort: "nome",
                tabelaOrigem: tabelaOrigemNome
            });

            resultados.data.map((item: any) => options.push({ label: item.nome, value: item.campo }));

            setCamposOptions(options);

            setCampos(resultados.data);

        } catch (error) {
            console.error("Error in tiposCompromisso:", error);
        }
    };

    const carregarTabelaOrigem = async () => {
        try {

            let options: IOption[] = [];

            const resultados: any = await TabelaOrigemService.obter({
                status: 1,
                limit: 100,
                offset: 0,
                sort: "nome",
            });

            resultados.data.map((item: any) => options.push({ label: item.nome, value: item.tabelaOrigemId }));

            setTabelaOrigem(options);
        } catch (error) {
            setTabelaOrigem([]);
        }
    };

    const atualizar = (chave: any, valor: any) => {

        sessionStorageService.inserir(chave, valor);

        const evento = new CustomEvent('localStorageAtualizado', { detail: { chave, valor } });

        window.dispatchEvent(evento);
    };

    const excluir = async () => {

        const swalWithBootstrapButtons = Swal.mixin({
            heightAuto: false,
            customClass: {
                confirmButton: "btn btn-orange",
                cancelButton: "btn btn-danger ms-5",
            },
            buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
            title: "Excluir",
            html: "Você realmente deseja excluir?<br>Qualquer ligação com esta fase ou condição também será removida.",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
        });

        if (result.isConfirmed) {

            getNodes().forEach((node) => {

                if (node.data?.condicoes) {

                    const updatedCondicoes = node.data.condicoes.filter((condicao: ICondicao) => condicao.codigoProximaFaseId != id) || [];

                    atualizar("atualizaNode", JSON.stringify({ data: { ...node.data, condicoes: updatedCondicoes }, id: node.id }));
                }
            });

            setTimeout(() => { atualizar("apagaNode", JSON.stringify({ data: data, id: id })); }, 3000);
        }

    };


    const renderCampos = () => (
        <>
            <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-orange">Configuração da Condição</h5>
                        <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className="col-6 mt-3">
                                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                                <input
                                    type="text"
                                    id="form-nome"
                                    {...formik.getFieldProps('nome')}
                                    className={"form-control"} />
                                {formik.touched.nome && formik.errors.nome && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.nome}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-6 mt-3">
                                <label htmlFor="form-tabelaOrigem" className="form-label fw-bolder text-orange">Tabela de Origem:</label>
                                <Select
                                    value={{
                                        label: tabelaOrigem.find((e) => e.value === formik.values.tabelaOrigemId)?.label,
                                        value: formik.values.tabelaOrigemId,
                                    }}
                                    options={tabelaOrigem}
                                    onChange={(option: any) => {
                                        formik.setFieldValue("tabelaOrigemId", option.value);
                                        formik.setFieldValue("tabelaOrigemNome", option.label);
                                    }}
                                />
                                {formik.touched.tabelaOrigemId && formik.errors.tabelaOrigemId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.tabelaOrigemId}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {formik.values.tabelaOrigemId > 0 && (
                                <div className="col-6 mt-3">
                                    <label htmlFor="form-campo" className="form-label fw-bolder text-orange">Campo:</label>
                                    <Select
                                        value={{
                                            label: camposOption.find((e) => e.value === formik.values.campoId)?.label,
                                            value: formik.values.campoId,
                                        }}
                                        options={camposOption}
                                        onChange={(option: any) => {
                                            formik.setFieldValue("campoId", option.value);
                                            formik.setFieldValue("campoNome", option.label);
                                            formik.setFieldValue("campoTipo", campos.find(e => e.camposId == parseInt(option.value))?.tipo);
                                            formik.setFieldValue("isXCampo", campos.find(e => e.camposId == parseInt(option.value))?.isXCampo);
                                        }} />
                                    {formik.touched.campoId && formik.errors.campoId && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.campoId}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="modal-footer mt-3" style={{ margin: "0 auto" }}>

                        <button
                            onClick={() => formik.handleSubmit()}
                            type="button"
                            className="btn btn-orange ms-5"
                        >
                            Adicionar
                        </button>
                        <button
                            onClick={() => toggleModal()}
                            type="button"
                            className="btn btn-danger ms-5"
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );

    return (
        <>

            {renderCampos()}


            <div className="nodeCondicao">
                <div style={{ display: 'flex', flexDirection: 'row', rotate: '-45deg', position: 'absolute', top: '-15px' }}>
                    <div className="nodeCondicaoIcon" style={{ cursor: 'move' }}>
                        <IoMdMove />
                    </div>
                    <div className="nodeCondicaoIcon" style={{ cursor: 'pointer' }} onClick={toggleModal}>
                        <FiSettings />
                    </div>
                    <div className="nodeCondicaoIcon" style={{ cursor: 'pointer' }} onClick={excluir}>
                        <FiTrash />
                    </div>
                </div>

                <div
                    className="nodeCondicaoBody"
                    style={{
                        borderStyle: isTarget ? 'dashed' : 'solid',
                        backgroundColor: isTarget ? '#fff5e1' : '#fff5e1',
                    }}
                >
                    {!isConnecting && (
                        <Handle className="customHandle" position={Position.Right} type="source" />
                    )}
                    <Handle
                        className="customHandle"
                        position={Position.Left}
                        type="target"
                        isConnectableStart={false}
                    />
                    <div className="nodeCondicaoContet">
                        {!isTarget && (
                            <div>
                                {formik.values.nome ? (
                                    <>
                                        <a>{formik.values.nome}</a>
                                    </>

                                ) : (
                                    <a>Clique para editar</a>
                                )}
                            </div>
                        )}
                        {isTarget && (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{
                                        width: "20px",
                                        height: "20px",
                                    }} /><p>...</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
