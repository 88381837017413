import { Editable } from "slate-react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const EditorArea = styled(Editable)`
  margin-bottom: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #eee;

  > button {
    border: none;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: var(--bs-blue);
    color: #fff;
    border-radius: 4px;

    > span {
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 14px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    > strong {
      font-size: 22px;
    }

    > span {
      font-size: 14px;
      color: var(--bs-gray);
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 40px;

  > div#editor-container {
    display: flex;
    flex-direction: column;
  }
`;

export const Versions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding: 20px;
`;

export const Version = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  gap: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.08);

  ${(props) =>
    props.selected &&
    css`
      border-left: 4px solid var(--bs-success);
    `}

  > div.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;

    > div.content-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    > div.content-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > strong {
        font-size: 18px;
      }

      > small {
        color: var(--bs-gray);
      }
    }
  }
`;

export const VersionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;

  > strong {
    font-size: 22px;
    color: var(--bs-gray-dark);
  }

  > button {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    background: none;
  }
`;

export const BagdgeVersion = styled.div<{ currentVersion: boolean }>`
  padding: 0 6px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  background: var(--bs-primary);

  ${(props) =>
    props.currentVersion &&
    css`
      background: var(--bs-success);
    `}
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center ;
  justify-content: center;
  margin-top: 10px;

  > div {
    background: var(--bs-orange);
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
  }
`;