import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";

class RelatorioService {


  async obterRelatorio(filtros: any): Promise<IRecuperaLista<any>> {

    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

    if (filtros?.statusRelatorioProcessamento.length > 0) filtros.statusRelatorioProcessamento.forEach((id: any) => (query += `&statusRelatorioProcessamento=${id}`));

    if (filtros?.modulo.length > 0) filtros.modulo.forEach((id: any) => (query += `&modulo=${id}`));

    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(`/Relatorio/RecuperaRelatorios?${query}`);

    return result;
  }

  async solicitarRelatorio(propostaAcordoId: number, chaveRelatorioCustomizado: number): Promise<Blob> {

    const { data: result } = await api.post(`RelatoriosCustomizados/SolicitarRelatorio`, { propostaAcordoId, chaveRelatorioCustomizado });

    return result;
  }

}

export default new RelatorioService();
