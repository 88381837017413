import styled from 'styled-components';

export const Formats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  div.header {
    padding: 20px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > button {
      border: none;
      background: transparent;

      > svg {
        font-size: 20px;
      }
    }
  }

  > button {
    border-radius: 4px;
    border: none;
    background: transparent;
    box-shadow: 0 5px 20px rgba(0,0,0,0.08);
    text-align: left;
    color: var(--bs-muted);
    border-bottom: 1px solid #ddd;
    margin: 0 20px;

    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;

    padding: 10px 20px;

    > svg {
      font-size: 20px;
    }
  }
`;