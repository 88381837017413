import { useMutation, useQueryClient } from "react-query";
import EditorService from "../../../../../../../services/EditorService";
import {
  EditorVersionData,
  IPublishVersionRequest,
} from "../../../../../../../interfaces/IEditorVersionResponse";
import { handleApiError } from "../../../../../../../utils/Utils";
import { toast } from "react-toastify";
import { EditorQuery } from "../../../queries";
import IRecuperaLista from "../../../../../../../interfaces/IRecuperaLista";
import { updateVersionCache } from "../../../helpers/cache/versionListCache";

export function usePublishDocument() {
  const queryClient = useQueryClient();

  const { mutateAsync: publishVersionFn, isLoading } = useMutation({
    mutationFn: EditorService.publishNewVersion,
    onSuccess: (version, { restoring }) => {
      queryClient.setQueriesData(
        [EditorQuery.get_versions],
        (current: IRecuperaLista<EditorVersionData> | undefined) =>
          updateVersionCache(version, current)
      );

      toast.success(`Versão ${restoring ? 'restaurada' : 'publicada'} com sucesso!`, {
        position: "bottom-right",
      });
    },
    onError: (error) => {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage, { position: "bottom-right" });
    },
  });

  async function publish(params: IPublishVersionRequest) {
    await publishVersionFn(params);
  }

  return {
    publish,
    isLoading,
  };
}
