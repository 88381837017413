
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import IAdicionarXFinderIntimacaoLogin from '../../../../interfaces/Requests/XFinder/IAdicionarXFinderIntimacao';
import XFinderIntimacaoService from '../../../../services/XFinderIntimacaoService';
import PasswordStrengthBar from 'react-password-strength-bar';
import { countCharacterSpecial, countNumber } from '../../../../utils/Utils';

interface IAdicionarXFinderIntimacaoLoginProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    xFinderIntimacaoLogin?: IAdicionarXFinderIntimacaoLogin,
    titulo: string
}

const XFinderIntimacaoLoginModal = ({ toggleModal, exibirModal, xFinderIntimacaoLogin, titulo }: IAdicionarXFinderIntimacaoLoginProps) => {
    const [password, setPassword] = useState('');
    const [cnjTribunalIds, setCnjTribunalIds] = useState<any[]>([]);
    const [strengthPassword, setStrengthPassword] = useState(0);
    const [validacaoSeguranca, setValidacaoSeguranca] = useState('');
    const [tipoSenha, setTipoSenha] = useState({
        password: 'password',
        confirmarPassword: 'password',
    });

    useEffect(() => {
        async function montarForm() {
            await formik.resetForm();

            if (xFinderIntimacaoLogin) {
                await formik.setValues({ ...xFinderIntimacaoLogin });
            }

            await formik.validateForm();
        }

        montarForm();
        carregarCNJs();

    }, [exibirModal, xFinderIntimacaoLogin]);


    useEffect(() => {
        if (formik.values && formik.values.password) formik.validateField('password');
    }, [strengthPassword]);
    const schema = Yup.object().shape({
        cnjTribunalId: Yup.number().min(1, "Selecione um CNJ Tribunal").required("Selecione um CNJ"),
        username: Yup.string().max(50, 'Maximo 50 caracteres').required('Campo é obrigatório'),
        password: Yup.string()
            .min(3, `Informe no minimo 3 caracteres.`)
            .max(50, 'Máximo 50 caracteres')
            .required('Nova Senha é obrigatório').test('validacao-forca', validacaoSeguranca, async (value) => {
                if (strengthPassword <= 1) {
                    return false;
                }

                if (value && countNumber(value) < 1) {
                    setValidacaoSeguranca(`Informe no minimo 1 número.`);
                    return false;
                }

                if (value && countCharacterSpecial(value) < 1) {
                    setValidacaoSeguranca(`Informe no minimo 1 caracteres especiais. Ex: &, @, *, #`);
                    return false;
                }

                return true;
            }),
        confirmarPassword: Yup.string()
            .min(3, `Minimo 3 caracteres`)
            .max(50, 'Máximo 50 caracteres')
            .oneOf([Yup.ref('password'), null], 'Você digitou senhas diferentes.'),
        uriLogin: Yup.string().max(200, 'Maximo 200 caracteres').required('Campo é obrigatório'),
        uriRecuperaIntimacao: Yup.string().max(200, 'Maximo 200 caracteres').required('Campo é obrigatório'),
        uriRecuperaDocumento: Yup.string().max(200, 'Maximo 200 caracteres').required('Campo é obrigatório'),
        // uriRecuperaConteudoDocumento: Yup.string().max(50, 'Maximo 50 caracteres').required('Campo é obrigatório'),
    })



    const valoresIniciais: IAdicionarXFinderIntimacaoLogin = {
        cnjTribunalId: 0,
        password: "",
        uriLogin: "",
        uriRecuperaConteudoDocumento: "",
        uriRecuperaDocumento: "",
        uriRecuperaIntimacao: "",
        username: ""
    } as IAdicionarXFinderIntimacaoLogin;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                if (!values.xFinderIntimacaoLoginId) {
                    await XFinderIntimacaoService.adicionarXFinderIntimacaoLogin(values);

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Cadastrado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                } else {
                    await XFinderIntimacaoService.editarXFinderIntimacaoLogin(values);

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Alterado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }

                setSubmitting(false);

                toggleModal(true);
            } catch (error: any) {
                let mensagemErro = 'Ocorreu um erro inesperado';

                if (error?.response?.data?.Message) {
                    mensagemErro = error.response.data.Message
                }

                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel salvar`,
                    text: mensagemErro,
                    showConfirmButton: true
                });
            }
        }
    });

    useEffect(() => {
        setPassword(formik.values.password);
    }, [formik.values.password]);

    const handlerTypePassword = (name: 'password' | 'confirmarPassword') => {
        let novoValor = tipoSenha[name];

        if (novoValor === 'password') {
            novoValor = 'text'
        } else {
            novoValor = 'password'
        }

        setTipoSenha(oldState => ({ ...oldState, [name]: novoValor }))
    }

    const carregarCNJs = async () => {
        try {
            const result = await XFinderIntimacaoService.obterXFinderCNJs({
                codigo: "",
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "id",
            });
            setCnjTribunalIds(result.data);
        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Ocorreu um erro ao tentar pesquisar`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        }
    }
    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">

                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="form-clienteId" className="form-label fw-bolder text-orange">
                                CNJ Tribunal ID:
                            </label>
                            <select
                                name="cnjTribunalId"
                                value={formik.values.cnjTribunalId}
                                onChange={(e) => {
                                    formik.setFieldTouched("cnjTribunalId", true);
                                    formik.setFieldValue("cnjTribunalId", parseInt(e.target.value), true);
                                    formik.validateField("cnjTribunalId");
                                }}
                                className={clsx(
                                    "form-select",
                                    {
                                        "is-invalid": formik.touched.cnjTribunalId && formik.errors.cnjTribunalId,
                                    },
                                    {
                                        "is-valid": formik.touched.cnjTribunalId && !formik.errors.cnjTribunalId,
                                    }
                                )}
                                id="form-client"
                            >
                                <option value="0"> Selecione um Cliente</option>
                                {cnjTribunalIds.map((cnjs) => {
                                    return (
                                        <option key={cnjs.cnjTribunalId} value={cnjs.cnjTribunalId}>
                                            {" "}
                                            {cnjs.nome}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                            {formik.touched.cnjTribunalId && formik.errors.cnjTribunalId && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">{formik.errors.cnjTribunalId}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="form" className="form-label required fw-bolder text-orange">Usuário:</label>
                            <input {...formik.getFieldProps('username')} placeholder="Nome" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.username && formik.errors.username,
                                },
                                {
                                    'is-valid': formik.touched.username && !formik.errors.username,
                                }
                            )} id="form-nome" />
                            {formik.touched.username && formik.errors.username && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.username}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {!formik.values.xFinderIntimacaoLoginId &&
                        <>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className='form-label fs-6 fw-bolder text-orange'>Nova Senha:</label>
                                    {
                                        formik.values.password.length > 4 && <PasswordStrengthBar className="mb-2" onChangeScore={setStrengthPassword} scoreWords={['Ruim', 'Fraca', 'Boa', 'Forte', 'Muito Forte']} password={password} />
                                    }
                                    <div className="input-group">
                                        <input
                                            placeholder='Nova Senha'
                                            {...formik.getFieldProps('password')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                { 'is-invalid': formik.touched.password && formik.errors.password },
                                                {
                                                    'is-valid': formik.touched.password && !formik.errors.password,
                                                }
                                            )}
                                            type={tipoSenha.password}
                                            autoComplete='off'
                                        />
                                        <span onClick={() => handlerTypePassword('password')} className="input-group-text background-orange" style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon size="lg" color='white' className='mx-2' icon={tipoSenha.password === 'password' ? faEye : faEyeSlash} />
                                        </span>
                                    </div>
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className='form-label fs-6 fw-bolder text-orange'>Confirmar Nova Senha:</label>
                                    <div className="input-group">
                                        <input
                                            placeholder='Confirmar Nova Senha'
                                            {...formik.getFieldProps('confirmarPassword')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                { 'is-invalid': formik.touched.confirmarPassword && formik.errors.confirmarPassword },
                                                {
                                                    'is-valid': formik.touched.confirmarPassword && !formik.errors.confirmarPassword,
                                                }
                                            )}
                                            type={tipoSenha.confirmarPassword}
                                            autoComplete='off'
                                        />
                                        <span onClick={() => handlerTypePassword('confirmarPassword')} className="input-group-text background-orange" style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon size="lg" color='white' className='mx-2' icon={tipoSenha.confirmarPassword === 'password' ? faEye : faEyeSlash} />
                                        </span>
                                    </div>
                                    {formik.touched.confirmarPassword && formik.errors.confirmarPassword && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.confirmarPassword}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                    {formik.values.xFinderIntimacaoLoginId > 0 &&
                        <>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className='form-label fs-6 fw-bolder text-orange'>Senha:</label>
                                    {
                                        formik.values.password.length > 4 && <PasswordStrengthBar className="mb-2" onChangeScore={setStrengthPassword} scoreWords={['Ruim', 'Fraca', 'Boa', 'Forte', 'Muito Forte']} password={password} />
                                    }
                                    <div className="input-group">
                                        <input
                                            placeholder='Senha'
                                            {...formik.getFieldProps('password')}
                                            onChange={(e) => {
                                                formik.setFieldValue('confirmarPassword', e.target.value);
                                                formik.setFieldValue('password', e.target.value);
                                            }}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                { 'is-invalid': formik.touched.password && formik.errors.password },
                                                {
                                                    'is-valid': formik.touched.password && !formik.errors.password,
                                                }
                                            )}
                                            type={tipoSenha.password}
                                            autoComplete='off'
                                        />
                                        <span onClick={() => handlerTypePassword('password')} className="input-group-text background-orange" style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon size="lg" color='white' className='mx-2' icon={tipoSenha.password === 'password' ? faEye : faEyeSlash} />
                                        </span>
                                    </div>
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </>
                    }
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label htmlFor="form" className="form-label fw-bolder text-orange">Uri de Login:</label>
                            <input {...formik.getFieldProps('uriLogin')} placeholder="Uri de Recuperar Conteudo do Documento" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.uriLogin && formik.errors.uriLogin,
                                },
                                {
                                    'is-valid': formik.touched.uriLogin && !formik.errors.uriLogin,
                                }
                            )} id="form-codigo" />
                            {formik.touched.uriLogin && formik.errors.uriLogin && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.uriLogin}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label htmlFor="form" className="form-label fw-bolder text-orange">Uri de Recuperar Conteudo do Documento:</label>
                            <input {...formik.getFieldProps('uriRecuperaConteudoDocumento')} placeholder="Uri de Recuperar Conteudo do Documento" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.uriRecuperaConteudoDocumento && formik.errors.uriRecuperaConteudoDocumento,
                                },
                                {
                                    'is-valid': formik.touched.uriRecuperaConteudoDocumento && !formik.errors.uriRecuperaConteudoDocumento,
                                }
                            )} id="form-codigo" />
                            {formik.touched.uriRecuperaConteudoDocumento && formik.errors.uriRecuperaConteudoDocumento && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.uriRecuperaConteudoDocumento}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-12 mb-3">
                            <label htmlFor="form" className="form-label fw-bolder text-orange">Uri de Recuperar Intimação:</label>
                            <input {...formik.getFieldProps('uriRecuperaIntimacao')} placeholder="Uri de Recuperar Intimação" type="uriRecuperaIntimacao" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.uriRecuperaIntimacao && formik.errors.uriRecuperaIntimacao,
                                },
                                {
                                    'is-valid': formik.touched.uriRecuperaIntimacao && !formik.errors.uriRecuperaIntimacao,
                                }
                            )} id="form-codigo" />
                            {formik.touched.uriRecuperaIntimacao && formik.errors.uriRecuperaIntimacao && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.uriRecuperaIntimacao}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-12 mb-3">
                            <label htmlFor="form" className="form-label fw-bolder text-orange">Uri de Recuperar Documentação:</label>
                            <input {...formik.getFieldProps('uriRecuperaDocumento')} placeholder="Uri de Recuperar Documentação" type="uriRecuperaDocumento" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.uriRecuperaDocumento && formik.errors.uriRecuperaDocumento,
                                },
                                {
                                    'is-valid': formik.touched.uriRecuperaDocumento && !formik.errors.uriRecuperaDocumento,
                                }
                            )} id="form-codigo" />
                            {formik.touched.uriRecuperaDocumento && formik.errors.uriRecuperaDocumento && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.uriRecuperaDocumento}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formik.isSubmitting && <> SALVAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default XFinderIntimacaoLoginModal;