import { ApiContentResponsePayload, Version } from "../types";
import { editorApi } from "./api";

export async function uploadDocumentService(document: File): Promise<Version> {
  const formData = new FormData();
  formData.append("document", document);

  const { data } = await editorApi.post<ApiContentResponsePayload>("uploadDocument", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data.payload;
}
