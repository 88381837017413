import { Transforms } from "slate";
import { CustomEditor } from "../../../../../../utils/types/slate";
import { AlignmentOption } from "./types";
import { ReactEditor } from "slate-react";
import { getNodeBySelection } from "../common";

export const alignmentHandler = (
  editor: CustomEditor,
  value: AlignmentOption,
  action?: () => void
) => {
  const selectedNode = getNodeBySelection(editor)

  if (!selectedNode) return

  const isActive = selectedNode.style?.textAlign === value

  Transforms.setNodes(editor, {
    style: {
      ...selectedNode.style,
      textAlign: isActive ? "start" : value,
    },
  });

  action?.()
  ReactEditor.focus(editor)
}