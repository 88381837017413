import { faEraser, faPen, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import Row from "react-bootstrap/esm/Row";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "../../../../../components/Comum/GridPadrao";
import { EModulo } from "../../../../../enum";
import IDespesa from "../../../../../interfaces/IDespesa";
import FiltroPesquisaDespesaRequest from "../../../../../interfaces/Requests/Despesa/FiltroPesquisaDespesaRequest";
import DespesaService from "../../../../../services/DespesaService";
import DespesaDetalheModal from "../../../../DespesaPage/DespesaDetalhes";
import { IModalActions } from "../../../hooks/useDisclosure";
import DespesaEmpresaModal from "../Despesas/DespesaEmpresaModal";

interface IModalDespesaAtoProps {
  modalActions: IModalActions;
  tituloModal: string;
  atoSocietarioId?: number;
}

const DespesasAtoSocietario = ({
  modalActions,
  tituloModal,
  atoSocietarioId
}: IModalDespesaAtoProps) => {
  const [carregandoDespesas, setCarregandoDespesas] = useState(false);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [despesa, setDespesa] = useState<IDespesa>();
  const [despesas, setDespesas] = useState<IDespesa[]>([]);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);
  const [exibirDetalheModal, setExibirDetalheModal] = useState<boolean>(false);
  const [titulo, setTitulo] = useState<string>("");
  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaDespesaRequest>({
      despesaId: 0,
      tipoDespesaId: 0,
      tipoDespesaNome: "",
      atoSocietarioId: atoSocietarioId,
      descricao: "",
      DataEfetivaMaiorIgual: "",
      DataEfetivaMenorIgual: "",
      DataVencimentoMaiorIgual: "",
      DataVencimentoMenorIgual: "",
      DataFatalMaiorIgual: "",
      DataFatalMenorIgual: "",
      ValorPrincipalMaiorIgual: 0,
      ValorPrincipalMenorIgual: 0,
      ValorTotalMaiorIgual: 0,
      ValorTotalMenorIgual: 0,
      dataCadastroMaiorIgual: "",
      dataCadastroMenorIgual: "",
      statusDespesaId: 0,
      statusDespesaNome: "",
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "despesaId",
    });

  const colunas: TableColumn<IDespesa>[] = [
    {
      name: "Id",
      sortField: "DespesaId",
      selector: (row: IDespesa) => row.despesaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: IDespesa) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Fatal",
      sortField: "dataFatal",
      selector: (row: IDespesa) => {
        if (row.dataFatal === null) return "-";
        const data = row.dataFatal.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IDespesa) => row.statusDespesaNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Tipo de Despesa",
      sortField: "tipoDespesaNome",
      selector: (row: IDespesa) => row.tipoDespesaNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Valor",
      sortField: "valor",
      selector: (row: IDespesa) => {
        if (row.valorPrincipal === null) return "R$ 0,00";

        const valor = row.valorPrincipal || 0;
        return valor.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
      sortable: true,
      wrap: true,
    },

    {
      name: "",
      cell: (despesa: IDespesa) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Detalhes da Despesa"
              onClick={() => {
                alterarEdicao(despesa);
                setExibirDetalheModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faSearch}
            />
            <FontAwesomeIcon
              title="Editar Despesa"
              onClick={() => {
                alterarEdicao(despesa);
                setTitulo("Editar Despesa");
                setExibirModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faPen}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const onCLickCancel = () => {
    // formik.resetForm();
    modalActions.handleClose();
  };

  const onClickAdd = () => {
    setDespesa(undefined);
    setExibirModal(true);
    setTitulo("Regitrar Despesa");
  };


  const toggleModal = (carregarDados?: boolean) => {
    if (carregarDados) carregarDespesas(filtrosPesquisa);

    setExibirModal(!exibirModal);
  };

  const toggleDetalheModal = () => setExibirDetalheModal(!exibirDetalheModal);

  async function carregarDespesas(
    filtro: FiltroPesquisaDespesaRequest
  ): Promise<void> {
    try {
      if (!atoSocietarioId) return;
      setCarregandoDespesas(true);
      const resultado = await DespesaService.obterDespesas({
        ...filtro,
        atoSocietarioId: atoSocietarioId
      });
      setDespesas(resultado.data);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      // inserirSessionStorage();
      setAtualizaTabela(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
            typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setDespesas([]);
    } finally {
      setCarregandoDespesas(false);
    }
  }

  const alterarEdicao = (despesa: IDespesa) => {
    setDespesa({
      despesaId: despesa.despesaId,
      categoriaId: atoSocietarioId || 0,
      atoSocietarioId: atoSocietarioId,
      modulo: EModulo.Societario,
      categoriaNome: "",
      tipoDespesaId: despesa.tipoDespesaId,
      descricao: despesa.descricao,
      dataEfetiva: new Date(despesa.dataEfetiva?.toString()),
      dataVencimento: new Date(despesa.dataVencimento?.toString()),
      dataFatal: new Date(despesa.dataFatal?.toString()),
      formaPagamentoId: despesa.formaPagamentoId,
      codigoBarras: despesa.codigoBarras,
      bancoId: despesa.bancoId,
      agencia: despesa.agencia,
      conta: despesa.conta,
      moedaId: despesa.moedaId,
      valorPrincipal: despesa.valorPrincipal * 100,
      valorCorrecao: despesa.valorCorrecao * 100,
      valorJuros: despesa.valorJuros * 100,
      valorMulta: despesa.valorMulta * 100,
      valorHonorarios: despesa.valorHonorarios * 100,
      valorTotal: despesa.valorTotal * 100,
      codigo: despesa.codigo,
 
      tipoDespesaNome: "",
      bancoNome: "",
      clienteId: despesa.clienteId,
      clienteNome: "",
      dataCadastro: despesa.dataCadastro,
      formaPagamentoNome: "",
      moedaNome: "",
      objetoTipo: "",
      statusDespesaId: despesa.statusDespesaId,
      statusDespesaNome: "",
      xCampoValorList: [],
      favoritoId:0,
    });
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<IDespesa>,
    sortDirection: string
  ) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        despesaId: 0,
        tipoDespesaId: 0,
        tipoDespesaNome: "",
        descricao: "",
        DataEfetivaMaiorIgual: "",
        DataEfetivaMenorIgual: "",
        DataVencimentoMaiorIgual: "",
        DataVencimentoMenorIgual: "",
        DataFatalMaiorIgual: "",
        DataFatalMenorIgual: "",
        ValorPrincipalMaiorIgual: 0,
        ValorPrincipalMenorIgual: 0,
        ValorTotalMaiorIgual: 0,
        ValorTotalMenorIgual: 0,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        statusDespesaId: 0,
        statusDespesaNome: "",
      };
    });
  };

  useEffect(() => {
    carregarDespesas(filtrosPesquisa);
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort, filtrosPesquisa.atoSocietarioId]);

  useEffect(() => {
    if (atualizarTabela)
      setTimeout(() => carregarDespesas(filtrosPesquisa), 500);
  }, [atualizarTabela]);

  useEffect(() => {
    setFiltrosPesquisa({ ...filtrosPesquisa, atoSocietarioId: atoSocietarioId });
  }, [atoSocietarioId]);

  return (
    <>
      <Modal
        size={exibirModal || exibirDetalheModal ? 'sm' : 'xl'}
        className={"societario"}
        centered={true}
        show={modalActions.isOpen}
        onHide={onCLickCancel}
      >
        <Modal.Header>
          <Modal.Title>{tituloModal}</Modal.Title>
          <button onClick={onCLickCancel} className={"btn btn-icon close"}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal.Header>

        <Modal.Body style={{ display: exibirModal || exibirDetalheModal ? "none" : "block" }}>
          <Row>
            <div className="row mt-2">
              <div className="col-md-12">
                <div className="col-md-12">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      carregarDespesas(filtrosPesquisa);
                    }}
                    className="row g-3 mb-3 form-buscar-despesa"
                  >
                    <div className="col-12 col-lg-4 col-xl-4">
                      <label
                        htmlFor="form-data-cadastro"
                        className="form-label fw-bolder text-orange"
                      >
                        Data de Cadastro
                      </label>
                      <DateRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        maxDate={new Date()}
                        value={
                          filtrosPesquisa.dataCadastroMaiorIgual &&
                            filtrosPesquisa.dataCadastroMenorIgual
                            ? [
                              filtrosPesquisa.dataCadastroMaiorIgual,
                              filtrosPesquisa.dataCadastroMenorIgual,
                            ]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltrosPesquisa((oldValue) => {
                              return {
                                ...oldValue,
                                dataCadastroMaiorIgual: "",
                                dataCadastroMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltrosPesquisa((oldValue) => {
                            return {
                              ...oldValue,
                              dataCadastroMaiorIgual: data_inicial
                                ? `${data_inicial.toISOString().split("T")[0]
                                }T00:00:00.0000`
                                : "",
                              dataCadastroMenorIgual: data_final
                                ? `${data_final.toISOString().split("T")[0]
                                }T23:59:59.9999`
                                : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <label
                        htmlFor="form-data-assinatura"
                        className="form-label fw-bolder text-orange"
                      >
                        Data Efetiva
                      </label>
                      <DateRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        maxDate={new Date()}
                        value={
                          filtrosPesquisa.DataEfetivaMaiorIgual &&
                            filtrosPesquisa.DataEfetivaMenorIgual
                            ? [
                              filtrosPesquisa.DataEfetivaMaiorIgual,
                              filtrosPesquisa.DataEfetivaMenorIgual,
                            ]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltrosPesquisa((oldValue) => {
                              return {
                                ...oldValue,
                                DataEfetivaMaiorIgual: "",
                                DataEfetivaMenorIgual: "",
                              };
                            });
                            return;
                          }
                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }

                          setFiltrosPesquisa((oldValue) => {
                            return {
                              ...oldValue,
                              DataEfetivaMaiorIgual: data_inicial
                                ? `${data_inicial.toISOString().split("T")[0]
                                }T00:00:00.0000`
                                : "",
                              DataEfetivaMenorIgual: data_final
                                ? `${data_final.toISOString().split("T")[0]
                                }T23:59:59.9999`
                                : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <label
                        htmlFor="form-data-assinatura"
                        className="form-label fw-bolder text-orange"
                      >
                        Data de Vencimento
                      </label>
                      <DateRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        maxDate={new Date()}
                        value={
                          filtrosPesquisa.DataVencimentoMaiorIgual &&
                            filtrosPesquisa.DataVencimentoMenorIgual
                            ? [
                              filtrosPesquisa.DataVencimentoMaiorIgual,
                              filtrosPesquisa.DataVencimentoMenorIgual,
                            ]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltrosPesquisa((oldValue) => {
                              return {
                                ...oldValue,
                                DataVencimentoMaiorIgual: "",
                                DataVencimentoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }

                          setFiltrosPesquisa((oldValue) => {
                            return {
                              ...oldValue,
                              DataVencimentoMaiorIgual: data_inicial
                                ? `${data_inicial.toISOString().split("T")[0]
                                }T00:00:00.0000`
                                : "",
                              DataVencimentoMenorIgual: data_final
                                ? `${data_final.toISOString().split("T")[0]
                                }T23:59:59.9999`
                                : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <label
                        htmlFor="form-status"
                        className="form-label fw-bolder text-orange"
                      >
                        Status
                      </label>
                      <select
                        value={filtrosPesquisa.statusDespesaId}
                        onChange={(e) => {
                          setFiltrosPesquisa((oldState) => {
                            return {
                              ...oldState,
                              statusDespesaId: parseInt(e.target.value),
                            };
                          });
                        }}
                        placeholder="Status"
                        className={"form-select"}
                        id="form-status"
                      >
                        <option value={0}>Todos</option>
                        <option value={3}>Cancelado</option>
                        <option value={2}>Pago</option>
                        <option value={1}>Pendente</option>
                      </select>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <label
                        htmlFor="form-tipo-despesa"
                        className="form-label fw-bolder text-orange"
                      >
                        Tipo de Despesa
                      </label>
                      <select
                        value={filtrosPesquisa.tipoDespesaId}
                        onChange={(e) => {
                          setFiltrosPesquisa((oldState) => {
                            return {
                              ...oldState,
                              tipoDespesaId: parseInt(e.target.value),
                            };
                          });
                        }}
                        placeholder="Tipo de Despesa"
                        className={"form-select"}
                        id="form-tipo-despesa"
                      >
                        <option value={0}>Todos</option>
                        <option value={1}>Acordo</option>
                        <option value={2}>Levantamento de Garantia</option>
                      </select>
                    </div>
                    <div className="col-12 " style={{ textAlign: "right" }}>
                      <button
                        type="submit"
                        className="btn btn-orange search-buttom-margin "
                        title="Buscar"
                      >
                        <FontAwesomeIcon
                          color="white"
                          className=""
                          icon={faSearch}
                        />
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          limparFiltros();
                        }}
                        className="btn btn-orange search-buttom-margin ms-2"
                        title="Limpar Filtro"
                      >
                        <FontAwesomeIcon
                          color="white"
                          className=""
                          icon={faEraser}
                        />
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-12 mb-10">
                  <GridPadrao
                    onSort={handleSort}
                    progressPending={carregandoDespesas}
                    limit={filtrosPesquisa.limit}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer={true}
                    paginationTotalRows={filtrosPesquisa.totalItems}
                    colunas={colunas}
                    tipo="Despesas Empresa"
                    itens={despesas}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={onClickAdd}
            type={"button"}
            className={"btn btn-orange ms-5"}
          >
            {" "}
            Adicionar{" "}
          </Button>
          <Button onClick={onCLickCancel} className={"btn btn-danger ms-5"}>
            {" "}
            Cancelar{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      {atoSocietarioId && (
        <>
          <DespesaEmpresaModal
            editarDespesa={despesa}
            exibirModal={exibirModal}
            toggleModal={toggleModal}
            atoSocietarioId={atoSocietarioId}
            titulo={titulo}
            setAtualizarTabela={setAtualizaTabela}
          />
          <DespesaDetalheModal
            toggleModal={toggleDetalheModal}
            exibirModal={exibirDetalheModal}
            despesa={despesa}
            titulo={"Detalhes da Despesa"}
          />
        </>

      )}


    </>
  );
};

export default DespesasAtoSocietario;
