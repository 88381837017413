import { AiOutlineFileSync } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import EnvelopeService from '../../../services/EnvelopeService';
import IEnvelope from '../../../interfaces/IEnvelope';
import IRecuperaLista from '../../../interfaces/IRecuperaLista';
import LayoutPageTitle from '../../../layout/LayoutPageTitle';
import { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import VisualizarDocumentos from '../../../components/VisualizarDocumentos';
import { Location } from 'history';
import CardEnvelopeDados from './CardEnvelopeDados';
import { EPlataformaEnum } from '../../../enum/EPlataformaEnum';

interface IEstado {
    envelope: IEnvelope
}

const EditarEnvelopePage = () => {


    const location: Location = useLocation();

    let { envelopeId } = useParams();
    const navigate = useNavigate();

    const [envelope, setEnvelope] = useState<IEnvelope>();
    const [carregandoEnvelope, setCarregandoEnvelope] = useState(true);
    const state: IEstado | null = location.state as IEstado;

    async function carregarEnvelope(): Promise<void> {
        let resultado: IRecuperaLista<IEnvelope>;
        try {
            setCarregandoEnvelope(true);

            resultado = await EnvelopeService.obterEnvelopes({
                codigo: '',
                envelopeId: [parseInt(envelopeId || '0')],
                clienteId: 0,
                nomeDocumento: '',
                usuarioIdCadastro: 0,
                dataAssinaturaMaiorIgual: '',
                dataAssinaturaMenorIgual: '',
                dataCanceladoMaiorIgual: '',
                dataCanceladoMenorIgual: '',
                dataCadastroMaiorIgual: '',
                dataCadastroMenorIgual: '',
                limit: 1,
                totalItems: 0,
                offset: 0,
                sort: 'envelopeId'
            });

            if (!resultado || !resultado.data || !resultado.data.length) {
                navigate('/XSigner/Envelopes')
            } else {
                setEnvelope(resultado.data[0]);
                setCarregandoEnvelope(false);
            }

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
                didClose: () => {
                    navigate('/XSigner/Envelopes')
                }
            });
        }
    }

    useEffect(() => {

        if (state) {
            setEnvelope(state.envelope as IEnvelope);
            setCarregandoEnvelope(false);
        }

    }, [location.state]);

    useEffect(() => {
        if (envelopeId !== undefined) {
            carregarEnvelope()
        }

    }, [envelopeId]);



    if (carregandoEnvelope) {
        return <>
            <div
                style={{
                    margin: 'auto auto',
                    position: 'relative',
                    width: 'fit-content',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    textAlign: 'center',
                }}
            >
                <img
                    alt="Carregando"
                    style={{
                        width: '200px',
                        margin: "0 auto",
                        animation: 'pulsing 2s ease-in-out infinite',
                    }}
                    src={process.env.PUBLIC_URL + '/images/logo-xjur.png'}
                />
            </div>
        </>
    }

    return (
        <>
            <LayoutPageTitle title="Editar Envelope" />
            <div className="col-md-12">
                <div className="row">
                    {envelope &&
                        <CardEnvelopeDados
                            envelope={envelope}
                            carregarEnvelope={carregarEnvelope}
                            setEnvelope={value => setEnvelope(value)}
                            publico={false}
                        />
                    }

                    <div className="envelope-documentos">

                        {envelope && envelope.plataforma != EPlataformaEnum.D4SIGN && envelope.documentos.map((documento, index) => (
                            <VisualizarDocumentos
                                key={index}
                                style={{
                                    width: '100%'
                                }}
                                url={documento.documentoUrl}
                            >
                                <div className="pdf-pagina-conteiner carregando">
                                    <div className="capa">
                                        <i>
                                            <AiOutlineFileSync />
                                        </i>
                                    </div>
                                </div>
                            </VisualizarDocumentos>
                        ))}
                        

                        {envelope && envelope.plataforma == EPlataformaEnum.D4SIGN && envelope.status !== 3 && envelope.documentos.map((documento, index) => (
                            <VisualizarDocumentos
                                key={index}
                                style={{
                                    width: '100%'
                                }}
                                url={documento.documentoUrl}
                            >
                                <div className="pdf-pagina-conteiner carregando">
                                    <div className="capa">
                                        <i>
                                            <AiOutlineFileSync />
                                        </i>
                                    </div>
                                </div>
                            </VisualizarDocumentos>
                        ))}


                        {envelope && envelope?.documentoAssinadoIdUrl?.length &&
                            <VisualizarDocumentos
                                style={{
                                    width: '100%'
                                }}
                                url={envelope.documentoAssinadoIdUrl}
                            >
                                <div className="pdf-pagina-conteiner carregando">
                                    <div className="capa">
                                        <i>
                                            <AiOutlineFileSync />
                                        </i>
                                    </div>
                                </div>
                            </VisualizarDocumentos>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}



export default EditarEnvelopePage;

