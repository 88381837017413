import React from "react";
import IWidget, { IWidgetsTamanhos } from "../interfaces/IWidget";
import { IWidgetDado } from "../interfaces/IWidgetDataSet";
import WidgetWrapper from "../WidgetWrapper";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnutChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import PolarAreaChart from "./PolarAreaChart";
import RadarChart from "./RadarChart";

export interface IGraficoOptions { 
    direcaoDaBarra?: "horizontal" | "vertical";
    escalaIndividual?: boolean;
    barraInvertida?: boolean;
    agruparDataSets?: boolean;
    mostrarLegenda?: boolean;
    mostraDataLabels?: boolean;
    mostraTotalNoLabel?: boolean;
    habilitarZoom?: boolean;
    tipoDeDado?: 'moeda' | 'data' | 'string' | 'number';
    posicaoLegenda?: 'left' | 'top' | 'right' | 'bottom' | 'center' ;
    mostrarMaisNoZoom?: boolean;
    tamanho?: number
 }


export interface LineChartProps extends IWidget{
    tipoGrafico: "line";
    
}
export interface BarChartProps extends IWidget{
    tipoGrafico: "bar";
    empilhado?:boolean;
    configuracaoGrafico: IGraficoOptions;
    
}
export interface PieChartProps extends IWidget{
    tipoGrafico: "pie";
    
}
export interface DoughnutChartProps extends IWidget{
    tipoGrafico: "doughnut";
    
}
export interface PolarAreaChartProps extends IWidget{
    tipoGrafico: "polarArea";
    
}
export interface RadarChartProps extends IWidget{
    tipoGrafico: "radar";
    
}


export declare type GraficoProps = LineChartProps | BarChartProps | PieChartProps | DoughnutChartProps | PolarAreaChartProps | RadarChartProps;


const GraficoErro = (props: React.PropsWithChildren<any>) => {
    return (
        <div className="alert alert-danger" role="alert">
            <strong>Falha ao Carregar Dashboard!</strong>
            {props.children}
        </div>
    );
}


export default class GraficoWidget extends React.Component<GraficoProps>{
    tipoGrafico = 'line';

    constructor(props:GraficoProps) {
        super(props);
        if(props.ref){
            props.ref(this);
        }
    }


    private getClassName(){
        let className = this.props.ClassName ||'';
        className += 'widget'

        if(this.props.desativado){
            className += ' widget-desativado'
        }

        return className;
    }

    
    private tamanhos(){
        return {
            xs: this.props.xs || '',
            sm: this.props.sm || '',
            md: this.props.md || '',
            lg: this.props.lg || '',
            xl: this.props.xl || '',
        }  as IWidgetsTamanhos
    }

    
    render() {
        return <WidgetWrapper
            titulo={this.props.titulo}
            editando={false}
            tamanhos={this.tamanhos()}
            className={this.getClassName()}
        >
            {this.getGrafico()}
        </WidgetWrapper>;
    }

    dataSetDados(): IWidgetDado[] {
        return this.props.dados.dados as IWidgetDado[];
    }
    
    getGrafico(): JSX.Element {
        switch(this.props.tipoGrafico){
            case "line":
                return <LineChart dados={this.props.dados} options={this.props.configuracaoGrafico}/>
            case "pie":
                return <PieChart dados={this.props.dados} options={this.props.configuracaoGrafico}/>;
            case "doughnut":
                return <DoughnutChart dados={this.props.dados} options={this.props.configuracaoGrafico}/>;
            case "polarArea":
                return <PolarAreaChart dados={this.props.dados}/>;
            case "radar":
                return <RadarChart dados={this.props.dados}/>;
            case "bar":
                return <BarChart options={this.props.configuracaoGrafico} empilhado={this.props.empilhado} direcao="x" dados={this.props.dados}/>;
            default:
                return <GraficoErro/>;
        }
    }
    
}
