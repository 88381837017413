import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css"; // Add toastify CSS
import { ToastContainer } from "react-toastify";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faTimes, faPlus, faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

import { ETipoCampoEnum } from "../../../../enum";

import { IRecuperaConfiguracaoTelaResponse } from "../../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import CurrencyField from "../../CurrencyBRL/CurrencyField";
import { notifyDanger } from "../../Toast/ToastFormik";
import { ICampoTabela } from "../../../../interfaces/ICampoTabela";

interface ITabelaFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
  capa?: boolean;
}

const FormTabelaField = ({
  toggleModal,
  exibirModal,
  configuracaoTela,
  formik,
  capa,
}: ITabelaFieldProps) => {
  const [colunas, setColunas] = useState<JSX.Element[]>([]);
  const [linhas, setLinhas] = useState<JSX.Element[]>([]);
  const [values, setValues] = useState<any[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [remover, setRemover] = useState<boolean>(false);
  const [removerIndex, setRemoverIndex] = useState<number>(0);

  const quantidade = capa ? configuracaoTela?.capaCampoTabelaReadDto?.xCampoTabelaPropriedades.length || 2 : configuracaoTela?.xCampoTabelaPropriedades?.length || 2;

  const popover = (help: string) => (
    <Popover id="popover-basic">
      <Popover.Body>{help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = (help: string) => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover(help)}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  useEffect(() => {
    if (remover) {
      setLinhas(linhas.filter((_, i) => i !== removerIndex));
      setValues(values.filter((e) => e.linhaIndex !== removerIndex));
      setIndex(linhas.length > 0 ? linhas.length - 1 : 0);
      setRemover(false);
    }
  }, [remover]);

  useEffect(() => {

    if (!formik.values[`${configuracaoTela.campoNome?.toLowerCase()}`]) {
      formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), []);
    }
    if (exibirModal) {

      const linhas = configuracaoTela?.capaCampoTabelaReadDto?.linhas || [];

      const propriedadesTabela = capa
        ? configuracaoTela?.capaCampoTabelaReadDto?.xCampoTabelaPropriedades
        : configuracaoTela?.xCampoTabelaPropriedades;

      if (propriedadesTabela) {
        const novasColunas = propriedadesTabela.map((coluna: any) => (
          <div className={definirCol()} key={coluna.id}>
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              {`${coluna.label[0]?.toUpperCase()}${coluna.label?.substring(1)}:`} <>{coluna.help && PopoverHelper(coluna.help)}</>
            </label>
          </div>
        ));

        setColunas(novasColunas);

        setIndex(linhas?.length);

      }
    }
  }, [exibirModal, capa, configuracaoTela]);


  useEffect(() => {
    if (!formik.values[`${configuracaoTela.campoNome?.toLowerCase()}`]) {
      formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), []);
    }
  }, []);


  const definirCol = (opcoes?: boolean) => {
    switch (quantidade) {
      case 1: return 'col-6 m-1';
      case 2: return 'col-2 m-1';
      case 3: return 'col-2 m-1';
      case 4: return 'col-2 m-1';
      case 5: return opcoes ? 'col-1 m-1' : 'col-2 m-1';
    }
  }

  const renderInput = (campo: ICampoTabela, index: number) => {

    let component: JSX.Element = <></>;

    const value: ICampoTabela = {
      tipo: campo.tipo,
      linhaIndex: index,
      colunaIndex: campo.colunaIndex,
      obrigatorio: campo.obrigatorio,
      campoId: configuracaoTela?.campo,
      xCampoValorId: configuracaoTela?.xCampoValorId,
      campoTabelaPropriedadesId: campo.campoTabelaPropriedadesId
    };

    values.push(value);

    const handleValueChange = (e: any, campoName: string) => {
      const updatedValues = [...values];
      const campoIndex = updatedValues.findIndex((e) => e.colunaIndex === campo.colunaIndex && e.linhaIndex === index);
      updatedValues[campoIndex][campoName] = e;
      setValues(updatedValues);
    };

    switch (campo.tipo) {

      case ETipoCampoEnum.Valor:
        component = (
          <div className={definirCol()} key={campo.id}>
            <CurrencyField
              className={`form-control ${value.obrigatorio && 'form-control-error'}`}
              onValueChange={(e) => handleValueChange(e.floatValue, 'valor')}
              floatValue={0}
            />
          </div>
        );
        break;

      case ETipoCampoEnum.Inteiro:
        component = (
          <div className={definirCol()} key={campo.id}>
            <input
              placeholder={`Informar ${campo.label}`}
              type="number"
              maxLength={10}
              className={`form-control ${value.obrigatorio && 'form-control-error'}`}
              onChange={(e) => handleValueChange(e.target.value, 'inteiro')}
            />
          </div>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <div className={definirCol()} key={campo.id}>
            <input
              type="date"
              className={`form-control ${value.obrigatorio && 'form-control-error'}`}
              onChange={(e) => handleValueChange(e.target.value, 'data')}
            />
          </div>
        );
        break;

      case ETipoCampoEnum.Texto:
        component = (
          <div className={definirCol()} key={campo.id}>
            <input
              placeholder={`Informar ${campo.label}`}
              type="text"
              maxLength={50}
              className={`form-control ${value.obrigatorio ? 'form-control-error' : ''}`}
              onChange={(e) => handleValueChange(e.target.value, 'texto')}
            />
          </div>
        );
        break;

      default:
        component = <></>;
    }

    return component;
  };

  const adicionar = () => {
    setIndex(index);

    const components = capa
      ? configuracaoTela?.capaCampoTabelaReadDto?.xCampoTabelaPropriedades.map((campo: ICampoTabela) => renderInput(campo, index))
      : configuracaoTela?.xCampoTabelaPropriedades && configuracaoTela?.xCampoTabelaPropriedades.map((campo: any) => renderInput(campo, index));

    const newRow = (
      <div className="row" key={index}>
        {components}
        <div className={definirCol(true)}>
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            className="mx-4 text-orange"
            icon={faTrash}
            onClick={() => {
              setRemoverIndex(index);
              setRemover(true);
            }}
          />
        </div>
      </div>
    );

    setLinhas((prevLinhas) => [
      ...prevLinhas,
      React.cloneElement(newRow, { key: index, linhaIndex: index }),
    ]);

    setIndex(index + 1); // Increment linhaIndex by 1
  };


  const validar = () => {
    let validos = true; // Variável de controle

    const propriedadesTabela = capa
      ? configuracaoTela?.capaCampoTabelaReadDto?.xCampoTabelaPropriedades
      : configuracaoTela?.xCampoTabelaPropriedades;

    values.forEach((value: ICampoTabela) => {
      const validar = propriedadesTabela.find((e: any) => e.campoTabelaPropriedadesId == value.campoTabelaPropriedadesId);
      if (!value.texto && validar.obrigatorio && validar.tipo == ETipoCampoEnum.Texto) {
        notifyDanger(`${validar.label} é obrigatório na linha ${value.linhaIndex + 1}`);
        validos = false;
      }
      if (!value.inteiro && validar.obrigatorio && validar.tipo == ETipoCampoEnum.Inteiro) {
        notifyDanger(`${validar.label} é obrigatório na linha ${value.linhaIndex + 1}`);
        validos = false;
      }
      if (!value.data && validar.obrigatorio && validar.tipo == ETipoCampoEnum.Data) {
        notifyDanger(`${validar.label} é obrigatório na linha ${value.linhaIndex + 1}`);
        validos = false;
      }
      if (!value.valor && validar.obrigatorio && validar.tipo == ETipoCampoEnum.Valor) {
        notifyDanger(`${validar.label} é obrigatório na linha ${value.linhaIndex + 1}`);
        validos = false;
      }
    });

    return validos;
  }

  const confirmar = () => {

    if (validar()) {
      if (capa) {

        if (configuracaoTela?.capaCampoTabelaReadDto?.linhas?.length > 0) {
          configuracaoTela.capaCampoTabelaReadDto.linhas.push({ colunas: values });
        } else {
          configuracaoTela.capaCampoTabelaReadDto.linhas = [];
          configuracaoTela.capaCampoTabelaReadDto.linhas.push({ colunas: values });
        }

        formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), configuracaoTela.capaCampoTabelaReadDto.linhas);

      } else {
        formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), values);
      }

      setLinhas([]);
      setColunas([]);
      setValues([]);

      toggleModal();
    }
  };

  const cancelar = () => {
    setColunas([]);
    setValues([]);
    setLinhas([]);

    toggleModal();
  };

  return (
    <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
      <ToastContainer />
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Adicionar <FontAwesomeIcon className="mx-2 text-orange" icon={faTable} />
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="col-md-auto">
            <button onClick={adicionar} type="button" className="btn btn-orange ms-5">
              <FontAwesomeIcon className="mx-2" icon={faPlus} />
            </button>
          </div>

          <div className="row mt-2">{colunas}</div>

          <div id='linhas'>{linhas}</div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>

          <button disabled={!values?.length} onClick={confirmar} type="button" className="btn btn-orange ms-5">
            {capa ? 'Salvar' : 'Adicionar'}
          </button>

          <button
            onClick={cancelar}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FormTabelaField;
