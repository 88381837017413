import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IChangeLog from "../interfaces/IChangeLog";
import FiltroPesquisaChangeLogRequest from "../interfaces/Requests/ChangeLog/FiltroPesquisaChangeLogRequest";
import { AdicionaChangeLogRequest } from "../interfaces/Requests/ChangeLog/AdicionaChangeLogRequest";
import { AtualizarChangeLogPorIdRequest } from "../interfaces/Requests/ChangeLog/AtualizarChangeLogPorIdRequest";

class ChangeLogService {

    async obterChangeLogs(filtros: FiltroPesquisaChangeLogRequest): Promise<IRecuperaLista<IChangeLog>> {
        let query = `${filtros.status === -3 ? '&status=0&status=1&status=-1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.versao) {
            query += `&versao=${filtros.versao}`;
        }

        if (filtros.status) {
            query += `&codigo=${filtros.status}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IChangeLog>>(`ChangeLog/RecuperaChangeLog?${query}`);

        return result;
    }

    async adicionaChangeLog(adicionaChangeLog: AdicionaChangeLogRequest): Promise<IChangeLog> {
        let { data: result } = await api.post<IChangeLog>(`ChangeLog/AdicionaChangeLog`, adicionaChangeLog);

        return result;
    }

    async atualizarVersao(editarChangeLog: AtualizarChangeLogPorIdRequest): Promise<void> {

        let dataChangeLog = [
            { "op": "replace", "path": "/versao", "value": editarChangeLog.versao },
        ]

        await Promise.all([
            await api.patch<void>(`ChangeLog/AtualizaChangeLogPorId?id=${editarChangeLog.ChangeLogId}`, dataChangeLog)
        ]);
    }

    async atualizarDescricao(editarChangeLog: AtualizarChangeLogPorIdRequest): Promise<void> {

        let dataChangeLog = [
            { "op": "replace", "path": "/descricao", "value": editarChangeLog.descricao},
        ]

        await Promise.all([
            await api.patch<void>(`ChangeLog/AtualizaChangeLogPorId?id=${editarChangeLog.ChangeLogId}`, dataChangeLog)
        ]);
    }

    async alterarStatus(ChangeLogId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IChangeLog>(`ChangeLog/AtualizaChangeLogPorId?id=${ChangeLogId}`, data);
    }
    

}
export default new ChangeLogService();