import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { faCheckCircle, faEraser, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { EModulo } from "../../../../enum";


import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import IModeloRelatorio from "../../../../interfaces/IModeloRelatorio";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";

import ConfiguracaoModeloRelatorio from "../../../../services/ConfiguracaoModeloRelatorioService";

import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import GridPadrao from "../../../../components/Comum/GridPadrao";
import { OrangeContext } from "../../../../contexts/OrangeProvider";
import { modeloRelatorioInitialValues } from "../../../../contexts/InitialValuesContext";
import "../../Styles/icons.css";

const RelatorioModeloPage = () => {
    const [modelos, setModelos] = useState<IModeloRelatorio[]>([]);
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoModelo, setCarregandoModelo] = useState(false);
    const { setCampos, tipoSociedade, setModeloRelatorio } = useContext<OrangeInterfaceContext>(OrangeContext);
    const navigate = useNavigate();

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<any>({
        nome: "",
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        modulo: EModulo.Societario,
        tipoSociedadeId: tipoSociedade.tipoSociedadeId,
        sort: "modeloRelatorioIdId",
        modeloRelatorioId: "",
    });

    useLayoutEffect(() => {
        setCampos([]);

        if (!carregandoModelo) carregarModelos(filtrosPesquisa);
    }, []);

    useEffect(() => {
        if (!loadingInicial) {
            carregarModelos(filtrosPesquisa);
        }
    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

    const carregarModelos = async (filtro: any) => {
        try {
            setCarregandoModelo(true);
            let resultado: IRecuperaLista<IModeloRelatorio>;
            resultado = await ConfiguracaoModeloRelatorio.obterModeloRelatorio(filtro);
            setFiltrosPesquisa({ ...filtro, totalItems: resultado.totalRegistros });

            setModelos(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setModelos([]);
        } finally {
            setCarregandoModelo(false);
            setLoadingInicial(false);
        }
    }

    const alterarStatus = async (modeloRelatorioId: number, status: number) => {
        try {
            const swalWithBootstrapButtons = Swal.mixin({
                heightAuto: false,
                customClass: {
                    confirmButton: 'btn btn-orange',
                    cancelButton: 'btn btn-danger ms-5'
                },
                buttonsStyling: false
            });

            let result = await swalWithBootstrapButtons.fire({
                title: 'Alterar Status ',
                text: `Você realmente deseja alterar o status?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Confirmar`
            });

            if (result.isConfirmed) {
                await ConfiguracaoModeloRelatorio.alterarStatus(modeloRelatorioId, status);
                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Status alterado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                })
                await limparFiltros();
                setFiltrosPesquisa((filtro: any) => {
                    carregarModelos(filtro);
                    return filtro;
                })
            }

        } catch (error: any) {
            let mensagemErro = 'Ocorreu um erro inesperado';

            if (error?.response?.data?.Message) {
                mensagemErro = error.response.data.Message
            }

            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel alterar o Status`,
                text: mensagemErro,
                showConfirmButton: true
            });
        }
    }

    const colunas: TableColumn<any>[] = [
        {
            name: "Id",
            sortField: "modeloRelatorioIdId",
            selector: (row: IModeloRelatorio) => row.modeloRelatorioId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true,
        },
        {
            name: "Nome",
            sortField: "nome",
            selector: (row: IModeloRelatorio) => row.nome,
            sortable: true,
            wrap: true,
        },
        {
            name: "Tipo",
            sortField: "tipo",
            selector: (row: IModeloRelatorio) => row.tipo,
            sortable: true,
            wrap: true,
        },
        {
            name: '',
            cell: (modelo: IModeloRelatorio) => {
                return (
                    <div>

                        {modelo.status === 'Ativo' ?
                            <FontAwesomeIcon title="Desativar" onClick={() => {
                                alterarStatus(modelo.modeloRelatorioId, -1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faTrashAlt} />
                            :
                            <FontAwesomeIcon title="Ativar" onClick={() => {
                                alterarStatus(modelo.modeloRelatorioId, 1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faCheckCircle} />
                        }
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ];

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa((filtro: any) => {
            return { ...filtro, limit: currentRowsPerPage };
        });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa((filtro: any) => {
            return { ...filtro, offset: (page - 1) * filtrosPesquisa.limit };
        });
    };

    const handleSort = async (column: TableColumn<IModeloRelatorio>, sortDirection: string) => {
        setFiltrosPesquisa((filtro: any) => {
            return {
                ...filtro,
                sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
            };
        });
    };

    const limparFiltros = async () => {
        setFiltrosPesquisa((oldValue: IModeloRelatorio) => {
            return {
                ...oldValue,
                nome: "",
                codigo: "",
                status: 1,
            };
        });
    }

    const onRowClicked = (row: IModeloRelatorio) => {
        setModeloRelatorio(row);
        navigate("/Societario/ConfiguracaoRelatorio/Modelo");
    };

    return (
        <>
            <LayoutPageTitle title="Selecione o modelo que você deseja configurar">
                <button onClick={() => {
                    setModeloRelatorio({
                        ...modeloRelatorioInitialValues,
                        modulo: EModulo.Societario,
                        modeloRelatorioCampos: [],
                        modeloRelatorioId: 0,
                        nome: "",
                        status: 0
                    });
                    navigate("/Societario/ConfiguracaoRelatorio/Modelo");
                }} className="btn btn-md btn-orange">
                    Novo Modelo <FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
                </button>
            </LayoutPageTitle>
            <div className="row mt-2">
                <div className="col-md-12">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            carregarModelos(filtrosPesquisa);
                        }}
                        className="row g-3 mb-3"
                    >

                        <div className="col-md-2 ">
                            <label htmlFor="form" className="form-label fw-bolder text-orange">
                                ID:
                            </label>
                            <input
                                value={filtrosPesquisa.modeloRelatorioId}
                                onChange={(e) => {
                                    setFiltrosPesquisa((filtro: any) => {
                                        return { ...filtro, modeloRelatorioId: e.target.value };
                                    });
                                }}
                                placeholder="ID"
                                type="text"
                                className={"form-control"}
                                id="form"
                            />
                        </div>
                        <div className="col-md-2 ">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                Nome:
                            </label>
                            <input
                                value={filtrosPesquisa.nome}
                                onChange={(e) => {
                                    setFiltrosPesquisa((filtro: any) => {
                                        return { ...filtro, nome: e.target.value };
                                    });
                                }}
                                placeholder="Nome"
                                type="text"
                                className={"form-control"}
                                id="form-nome"
                            />
                        </div>

                        <div className="col-md-2">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                Status:
                            </label>
                            <select
                                value={filtrosPesquisa.status}
                                onChange={(e) => {
                                    setFiltrosPesquisa((filtro: any) => {
                                        return { ...filtro, status: parseInt(e.target.value) };
                                    });
                                }}
                                placeholder="Nome"
                                className={"form-select"}
                                id="form-select"
                            >
                                <option value="0" label="Todos">
                                    Todos
                                </option>
                                <option value="1" label="Ativo">
                                    Ativo
                                </option>
                                <option value="-1" label="Inativo">
                                    Inativo
                                </option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                                    <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        limparFiltros();
                                    }}
                                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                                >
                                    <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
                    <GridPadrao
                        onSort={handleSort}
                        onRowClicked={onRowClicked}
                        progressPending={carregandoModelo}
                        limit={filtrosPesquisa.limit}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationServer={true}
                        paginationTotalRows={filtrosPesquisa.totalItems}
                        colunas={colunas}
                        tipo="Modelos"
                        itens={modelos}
                    />
                </div>
            </div>
        </>
    );
};

export default RelatorioModeloPage;
