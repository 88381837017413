
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import PowerBI from "../../components/Comum/PowerBI/PowerBI";
import { EModulo } from "../../enum";
import "./style/icons.css";

const RegulatorioDashboardPage = () => {

  return (
    <>
      <LayoutPageTitle title="Dashboard Regulatório" />
      <PowerBI modulo={EModulo.Regulatorio} />
    </>
  );
}

export default RegulatorioDashboardPage;
