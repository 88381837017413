import styled from "styled-components";

type ButtonProps = {
  variant: 'docx' | 'pdf' 
}

export const SaveButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const SaveButtonLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const SaveButton = styled.button<ButtonProps>`
  border: none;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  padding: 5px;
  color: #fff;
  transition: background-color 0.8ms ease;
  background: ${props => props.variant === 'pdf' ? 'var(--bs-danger)' : 'var(--bs-primary)'};
  opacity: 0.6;

  > svg {
    font-size: 18px;
  }

  &:hover {
    opacity: 1;
  }
`