import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IFechamento from "../../interfaces/IFechamento";

interface IFechamentoModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  fechamento: IFechamento;
  titulo: string;
}

const FechamentoDetalheModal = ({ toggleModal, exibirModal, fechamento, titulo }: IFechamentoModalProps) => {

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="form-quantidades" className="form-label fw-bolder text-orange">
                  Total de Processos Ativos
                </label>
                <br />
                {fechamento?.totalProcessosAtivos}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-email" className="form-label fw-bolder text-orange">
                  Total de Processos Encerrados
                </label>
                <br />
                {fechamento?.totalProcessosEncerrados}
              </div>
            </div>

            <hr />

            <div className="row mt-2">
              <label htmlFor="form-valorProvisaoAtual" className="form-label fw-bolder text-orange">
                Contingência Passiva
              </label>
              <div className="col-md-6">
                <label htmlFor="form-valorProvisaoAtual" className="form-label fw-bolder text-orange">
                  Valores Atuais
                </label>
                <br />
                <b>Principal:</b> {fechamento?.passivaValorTotalPrincipal?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />
                <b>Multa:</b> {fechamento?.passivaValorTotalMulta?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />
                <b>Honorários:</b> {fechamento?.passivaValorTotalHonorarios?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />?
                <b>Juros e Correção:</b> {fechamento?.passivaValorTotalJurosECorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.passivaValorTotalAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />
                <b>Provisão Atualizada:</b> {fechamento?.passivaValorProvisaoAtualAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-valorProvavel" className="form-label fw-bolder text-orange">
                  Valor Provável
                </label>
                <br />
                <b>Principal:</b> {fechamento?.passivaValorProvavelPrincipal?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />
                <b>Correção:</b> {fechamento?.passivaValorProvavelCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />
                <b>Juros:</b> {fechamento?.passivaValorProvavelJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.passivaValorProvavelAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
              </div>
            </div>

            <div className="row mt-2">

              <div className="col-md-6">
                <label htmlFor="form-valorPossivel" className="form-label fw-bolder text-orange">
                  Valor Possível
                </label>
                <br />
                <b>Principal:</b> {fechamento?.passivaValorPossivelPrincipal?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Correção:</b> {fechamento?.passivaValorPossivelCorrecao?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Juros: </b> {fechamento?.passivaValorPossivelJuros?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.passivaValorPossivelAtualizado?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-valorRemoto" className="form-label fw-bolder text-orange">
                  Valor Remoto
                </label>
                <br />
                <b>Principal:</b> {fechamento?.passivaValorRemotoPrincipal?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Correção:</b> {fechamento?.passivaValorRemotoCorrecao?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Juros:</b> {fechamento?.passivaValorRemotoJuros?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.passivaValorRemotoAtualizado?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
              </div>
            </div>

            <hr />

            <div className="row mt-2">
              <label htmlFor="form-valorProvisaoAtual" className="form-label fw-bolder text-orange">
                Contingência Ativa
              </label>
              <div className="col-md-6">
                <label htmlFor="form-valorProvisaoAtual" className="form-label fw-bolder text-orange">
                  Provisão Atual
                </label>
                <br />
                <b>Principal:</b> {fechamento?.ativaValorTotalPrincipal
                  ?.toLocaleString("pt-BR",
                    { style: "currency", currency: "BRL" })}
                <br />
                <b>Multa:</b> {fechamento?.ativaValorTotalMulta
                  ?.toLocaleString("pt-BR",
                    { style: "currency", currency: "BRL" })}
                <br />
                <b>Honorários:</b> {fechamento?.ativaValorTotalHonorarios
                  ?.toLocaleString("pt-BR",
                    { style: "currency", currency: "BRL" })}
                <br />
                <b>Juros e Correção:</b> {fechamento?.ativaValorTotalJurosECorrecao?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.ativaValorTotalAtualizado?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Provisão Atualizada:</b> {fechamento?.ativaValorProvisaoAtualAtualizado?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-valorProvavel" className="form-label fw-bolder text-orange">
                  Valor Provável
                </label>
                <br />
                <b>Principal:</b> {fechamento?.ativaValorProvavelPrincipal
                  ?.toLocaleString("pt-BR",
                    { style: "currency", currency: "BRL" })}
                <br />
                <b>Correção:</b> {fechamento?.ativaValorProvavelCorrecao?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Juros:</b> {fechamento?.ativaValorProvavelJuros?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.ativaValorProvavelAtualizado?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
              </div>
            </div>

            <div className="row mt-2">

              <div className="col-md-6">
                <label htmlFor="form-valorPossivel" className="form-label fw-bolder text-orange">
                  Valor Possível
                </label>
                <br />
                <b>Principal:</b> {fechamento?.ativaValorPossivelPrincipal?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Correção:</b> {fechamento?.ativaValorPossivelCorrecao?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Juros: </b> {fechamento?.ativaValorPossivelJuros?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.ativaValorPossivelAtualizado?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-valorRemoto" className="form-label fw-bolder text-orange">
                  Valor Remoto
                </label>
                <br />
                <b>Principal:</b> {fechamento?.ativaValorRemotoPrincipal?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Correção:</b> {fechamento?.ativaValorRemotoCorrecao?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Juros:</b> {fechamento?.ativaValorRemotoJuros?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
                <br />
                <b>Total Atualizado:</b> {fechamento?.ativaValorRemotoAtualizado?.toLocaleString("pt-BR",
                  { style: "currency", currency: "BRL" })}
              </div>
            </div>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default FechamentoDetalheModal;
