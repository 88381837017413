
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import ITipoConsulta from "../../interfaces/ITipoConsulta";
import { Modal } from "react-bootstrap";
import TipoConsultaService from "../../services/TipoConsultaService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { EditarTipoConsultaRequest } from "../../interfaces/Requests/TipoConsulta/EditarTipoConsultaRequest";

interface ITipoConsultaModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    tipoConsulta?: ITipoConsulta,
    titulo: string
}

const TipoConsultaModal = ({ toggleModal, exibirModal, tipoConsulta, titulo }: ITipoConsultaModalProps) => {

    useEffect(() => {
        async function montarForm() {
            await formik.resetForm();

            if (tipoConsulta) {
                await formik.setValues({ ...tipoConsulta });
            }

            await formik.validateForm();
        }

        montarForm();

        // eslint-disable-next-line
    }, [exibirModal, tipoConsulta]);

    const tipoConsultaValidacoes = Yup.object().shape({
        nome: Yup.string().max(50, 'Maximo 50 caracteres').required('Nome é obrigatório'),
        codigo: Yup.string().max(50, 'Maximo 50 caracteres')
    })

    const valoresIniciais: EditarTipoConsultaRequest = {
        nome: '',
        codigo: '',
    } as ITipoConsulta;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: tipoConsultaValidacoes,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                if (!values.tipoConsultaId) {
                    await TipoConsultaService.adicionaTipoConsulta(values);

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Tipo de Consulta cadastrado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                } else {
                    await TipoConsultaService.atualizarTipoConsulta(values);

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Tipo de Consulta editado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }

                setSubmitting(false);

                toggleModal(true);
            } catch (error: any) {
                let mensagemErro = 'Ocorreu um erro inesperado';

                if (error?.response?.data?.Message) {
                    mensagemErro = error.response.data.Message
                }

                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel editar o Tipo de Consulta`,
                    text: mensagemErro,
                    showConfirmButton: true
                });
            }
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">Nome</label>
                            <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.nome && formik.errors.nome,
                                },
                                {
                                    'is-valid': formik.touched.nome && !formik.errors.nome,
                                }
                            )} id="form-nome" />
                            {formik.touched.nome && formik.errors.nome && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.nome}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código</label>
                            <input {...formik.getFieldProps('codigo')} placeholder="Código" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.codigo && formik.errors.codigo,
                                },
                                {
                                    'is-valid': formik.touched.codigo && !formik.errors.codigo,
                                }
                            )} id="form-codigo" />
                            {formik.touched.codigo && formik.errors.codigo && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.codigo}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formik.isSubmitting && <> SALVAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default TipoConsultaModal;