import {
  MdOutlineFormatBold,
  MdOutlineFormatItalic,
  MdOutlineFormatUnderlined,
  MdOutlineStrikethroughS,
} from "react-icons/md";
import { MarkControl } from "./types";

export const mark: MarkControl[] = [
  {
    type: "bold",
    component: <MdOutlineFormatBold />,
  },
  {
    type: "italic",
    component: <MdOutlineFormatItalic />,
  },
  {
    type: "underline",
    component: <MdOutlineFormatUnderlined />,
  },
  {
    type: "strikethrough",
    component: <MdOutlineStrikethroughS />,
  },
];
