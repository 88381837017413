import { IComparedVersion } from "../components/Editor/components/WordProcessor/interfaces/versionType";
import {
  AddComment,
  CloseThread,
  DeleteComment,
  EditComment,
  GetThread,
  ReplyComment,
  Comment,
} from "../components/Editor/types";
import { getParamsFromCaller } from "../components/Editor/util";
import {
  EditorVersionAdditionalResponse,
  EditorVersionData,
  IAttachEditorFile,
  ICompareChangesResponse,
  IEditorCommentThreadResponse,
  IEditorPaginationRequestParams,
  IEditorVersionAdditionalInfoRequest,
  IEditorVersionAdditionalInfoResponse,
  IEditorVersionRequest,
  IEditorVersionResponse,
  IModeloDocumento,
  IPublishVersionRequest,
  ISelecionaModeloRequest,
  ISelecionaModeloResponse,
  IUploadImageResponse,
} from "../interfaces/IEditorVersionResponse";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import api from "./Api";
import sessionStorageService from "./sessionStorage/sessionStorageService";

export enum EditorCommentSuccessResponseStatus {
  CREATED = 201,
  FINISHED = 200,
}

export interface DynamicProvider<T = number> {
  provider: {
    key: string;
    value: T;
  };
}

class EditorService {
  private uploadImageBasePath = "UploadImagens";

  private editorStorageKey = "contrato_editor";

  public async isCached(id: number) {
    return this.getCacheEditor(id) ?? false;
  }

  public async removeEditorCache() {
    await this.updateStorage(this.editorStorageKey);
  }

  public async getCacheEditor(id: number): Promise<EditorVersionData | null> {
    const cacheKey = `${this.editorStorageKey}_${id}`;
    const cachedEditor = await sessionStorageService.obter(cacheKey);

    if (cachedEditor) {
      return JSON.parse(cachedEditor) as EditorVersionData;
    }

    return null;
  }

  private async updateStorage(cacheKey: string) {
    const keys = Object.keys(sessionStorage).filter(
      (key) => key !== cacheKey && key.startsWith(this.editorStorageKey)
    );

    for await (const key of keys) {
      await sessionStorageService.remove(key);
    }
  }

  public async setCacheEditor(
    data: EditorVersionData,
    id: number
  ): Promise<void> {
    const cacheKey = `${this.editorStorageKey}_${id}`;
    await sessionStorageService.inserir(data, cacheKey);
    await this.updateStorage(cacheKey);
  }

  public async getVersionsV2(
    dynamicProvider: DynamicProvider<number>,
    paginationParams?: IEditorPaginationRequestParams
  ) {
    const endpoint = `/EditorDocumento/RecuperaVersao`;
    const { key, value } = dynamicProvider.provider;
    const { data } = await api.get<IEditorVersionResponse>(endpoint, {
      params: {
        [key]: value,
        ...paginationParams,
      },
    });
    return data;
  }

  public async getVersions(
    callerId: number,
    caller: string
  ): Promise<IEditorVersionResponse> {
    const params = getParamsFromCaller(caller, callerId);
    const path = `/EditorDocumento/RecuperaVersao`;
    const response = await api.get<IEditorVersionResponse>(path, { params });
    return response.data;
  }

  public async setNewVersion(data: IEditorVersionRequest) {
    const path = `/EditorDocumento/AdicionaVersao`;
    const response = await api.post(path, data);
    return response.data;
  }

  public async templateList(modulo: number) {
    const path = "/ModeloDocumento/RecuperaModeloDocumento";
    const response = await api.get<IRecuperaLista<IModeloDocumento>>(path, {
      params: { modulo },
    });
    return response.data;
  }

  public async selectTemplate(request: ISelecionaModeloRequest) {
    const path = "/EditorDocumento/ProcessarDocumentoPorModelo";
    const response = await api.post<ISelecionaModeloResponse>(path, {
      modeloDocumentoId: request.templateId,
      modulo: request.modulo,
      [request.tenant.provider.key]: request.tenant.provider.value,
    });
    return response.data;
  }

  public async getLastVersion(
    callerId: number,
    caller: string
  ): Promise<EditorVersionData> {
    try {
      const cachedData = await this.getCacheEditor(callerId);

      if (cachedData) {
        return cachedData;
      }

      const versions = await this.getVersions(callerId, caller);
      const lastVersion = versions.data[0];
      await this.setCacheEditor(lastVersion, callerId);
      return lastVersion;
    } catch (error) {
      const cacheKey = `${this.editorStorageKey}_${callerId}`;
      await this.updateStorage(cacheKey);
      throw error;
    }
  }

  public async getAdditionalInformation(
    data: IEditorVersionAdditionalInfoRequest
  ): Promise<EditorVersionAdditionalResponse> {
    const path = `/EditorDocumento/ConsultaDadosAdicionais`;
    const params = {
      [data.tenant.provider.key]: data.tenant.provider.value,
      modulo: data.modulo,
    };
    const response = await api.get<IEditorVersionAdditionalInfoResponse>(path, {
      params,
    });

    return response.data.data;
  }

  public async getCommentThreads(data: GetThread) {
    const path = `EditorDocumentoComentarios/RecuperaThread`;

    const params = {
      modulo: data.modulo,
      status: data.status,
      [data.tenant.provider.key]: data.tenant.provider.value,
    };

    const response = await api.get<IEditorCommentThreadResponse>(path, {
      params,
    });
    return response.data.data;
  }

  public async addComment(data: AddComment): Promise<Comment> {
    const path = `EditorDocumentoComentarios/AdicionaComentario`;
    const requestBody = {
      modulo: data.modulo,
      comentario: data.comment,
      path: data.path,
      [data.tenant.provider.key]: data.tenant.provider.value,
    };
    const response = await api.post<Comment>(path, requestBody);
    return response.data;
  }

  public async addReply(data: ReplyComment): Promise<Comment> {
    const path = `EditorDocumentoComentarios/AdicionaResposta`;
    const requestBody = {
      comentarioPaiId: data.parentId,
      modulo: data.modulo,
      comentario: data.comment,
      [data.tenant.provider.key]: data.tenant.provider.value,
    };
    const response = await api.post<Comment>(path, requestBody);
    return response.data;
  }

  public async editComment(data: EditComment): Promise<number> {
    const path = `EditorDocumentoComentarios/EditarComentario`;
    const requestBody = {
      comentarioEditato: data.comment,
    };
    const { status } = await api.put(path, requestBody, {
      params: { id: data.id },
    });
    return status;
  }

  public async deleteComment(data: DeleteComment): Promise<number> {
    const path = `EditorDocumentoComentarios/ExcluirComentario`;
    const { status } = await api.delete(path, { params: { id: data.id } });
    return status;
  }

  public async deleteThread(data: CloseThread): Promise<number> {
    const path = `EditorDocumentoComentarios/ExcluirThread?id=${data.id}`;
    const { status } = await api.delete(path, { params: { id: data.id } });
    return status;
  }

  public async closeThread(data: CloseThread): Promise<number> {
    const path = `EditorDocumentoComentarios/FecharThread?id=${data.id}`;
    const { status } = await api.put(path);
    return status;
  }

  public async uploadEditorImage(
    imageFile: File
  ): Promise<IUploadImageResponse> {
    const formData = new FormData();
    formData.append("file", imageFile);

    const path = `/EditorDocumento/${this.uploadImageBasePath}`;
    const { data } = await api.post<IUploadImageResponse>(path, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  }

  public async refetchMinuta(tenant: DynamicProvider): Promise<void> {
    await api.post("/Contrato/ReprecessarMinuta", {
      [tenant.provider.key]: tenant.provider.value,
    });
  }

  public async publishNewVersion(params: IPublishVersionRequest) {
    const path = "/EditorDocumento/AdicionaVersao";
    const response = await api.post<EditorVersionData>(path, {
      jsonContent: params.content,
      modulo: params.modulo,
      titulo: params.title,
      [params.tenant.provider.key]: params.tenant.provider.value,
    });
    return response.data;
  }

  public async attachEditorFile(params: IAttachEditorFile) {
    const path = "ged/awss3/upload";
    const data = new FormData();

    data.append("origem", params.origem.toString());
    data.append("file", params.file);
    data.append("tipoDocumentoId", params.tipoDocumentoId.toString());
    data.append("descricao", params.descricao);
    data.append("restrito", String(params.restrito));
    data.append("modulo", params.modulo.toString());
    data.append(
      params.tenant.provider.key,
      params.tenant.provider.value.toString()
    );

    await api.post(path, data);
  }

  public async compareChanges(versions: IComparedVersion[]) {
    const originalVersion = versions.find(
      (version) => version.versionType === "original"
    );
    const revisionedVersion = versions.find(
      (version) => version.versionType === "revisioned"
    );
    const path = "EditorDocumento/ComparaVersao";
    const response = await api.post<ICompareChangesResponse>(path, {
      editorDocumentoIdOriginal: originalVersion?.versionId,
      editorDocumentoIdRevisada: revisionedVersion?.versionId,
    });
    return response.data;
  }

  async enviarDocumento(enviar: any): Promise<any> {
    let { data: result } = await api.post(
      `EditorDocumento/EnviarEmailUsuarioExterno`,
      enviar
    );
    return result;
  }
}

export default new EditorService();
