import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusAto from "../interfaces/IStatusAto";
import { AdicionaStatusAtoRequest } from "../interfaces/Requests/StatusAto/AdicionaStatusAtoRequest";
import FiltroPesquisaStatusAtoRequest from "../interfaces/Requests/StatusAto/FiltroPesquisaStatusAtoRequest";
import { EditarStatusAtoRequest } from "../interfaces/Requests/StatusAto/EditarStatusAtoRequest";

class StatusAtoService {

    async obterStatusAtos(filtros: FiltroPesquisaStatusAtoRequest): Promise<IRecuperaLista<IStatusAto>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusAto>>(`StatusAto/RecuperaStatusAto?${query}`);

        return result;
    }

    async adicionaStatusAto(adicionaStatusAto: AdicionaStatusAtoRequest): Promise<IStatusAto> {
        let { data: result } = await api.post<IStatusAto>(`StatusAto/AdicionaStatusAto`, adicionaStatusAto);

        return result;
    }


    async alterarStatus(statusAtoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusAto>(`StatusAto/AtualizaStatusAtoPorId?id=${statusAtoId}`, data);
    }

    async atualizarStatusAto(editarStatusAto: EditarStatusAtoRequest): Promise<void> {

        let dataStatusAto = [
            { "op": "replace", "path": "/nome", "value": editarStatusAto.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusAto.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusAto/AtualizaStatusAtoPorId?id=${editarStatusAto.statusAtoId}`, dataStatusAto)
        ]);
    }
}


export default new StatusAtoService();