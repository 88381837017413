import { ToastContainer } from "react-toastify";

import { IPessoa } from "../../../interfaces/IPessoa";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";


import Carregando from "../../../components/Carregando";
import CamposCadastroDinamicos from "./CamposCadastroParteContraria";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ECamposIDEnum } from "../../../enum";

interface IParteContrariaFieldProps {
  formik: any;
  pessoa: IPessoa;
  categoriaId: number;
  carregandoConfiguracoesTela: boolean;
  configuracoesTela: IRecuperaConfiguracaoTelaResponse[];
}

const ParteContrariaCadastroPage = ({ configuracoesTela, carregandoConfiguracoesTela, formik, pessoa }: IParteContrariaFieldProps) => {

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {

      switch (configuracaoTela.campoNome) {
        case ECamposIDEnum.FormaParticipacao: // ignore
        case ECamposIDEnum.PapelSignatario: // ignore
          break;

        default:
          component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />)
          break;
      }
    });

    return <div className="row mt-3">{component}</div>;
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>É o nome pelo qual as pessoas preferem ser identificadas no meio social e que reflete a sua identidade de gênero.</Popover.Body>
    </Popover>
  );


  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  return (
    <>
      <ToastContainer />

      <div className="row mt-3">
        <div className="col-md-4 mt-0">
          <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
            Nome:
          </label>
          <input disabled={true} type="text" value={pessoa.nome} className={"form-control"} />
        </div>

        <div className="col-md-4 mt-0">
          <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
            Nome Social:<PopoverHelper />
          </label>
          <input disabled={true} type="text" value={pessoa.nomeSocial} className={"form-control"} />
        </div>

        <div className="col-md-4 mt-0">
          <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
            Documento:
          </label>
          <input disabled={true} type="text" value={pessoa.numeroDocumento} className={"form-control"} />
        </div>
      </div>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
          <br></br>
          <br></br>
          <br></br>
        </>}
    </>
  );
}

export default ParteContrariaCadastroPage;
