import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import { TableColumn } from "react-data-table-component";
import { Form, Modal } from 'react-bootstrap';
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { ECamposIDEnum, EModulo } from "../../enum";

import IFile from "../../interfaces/IFile";
import IPastaGed from "../../interfaces/IPastaGed";

import PastaGedService from "../../services/PastaGedService";
import GedUploadService from "../../services/GedUploadService";
import TipoDocumentoService from "../../services/TipoDocumentoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import GridPadrao from "../Comum/GridPadrao";
import { FileDropzone } from "./DragDropZone";

interface IFileUploadFieldProps {

  modulo: EModulo;
  campo?: number;

  exibirAdicionar?: boolean;
  importarArquivos?: boolean;

  //modulos
  processoId?: number;
  areaDireitoId?: number;

  tipoDesdobramentoId?: number,
  desdobramentoId?: number,

  tipoObrigacaoId?: number,
  obrigacaoId?: number,

  marcaId?: number;
  tipoMarcaId?: number;

  empresaId?: number;
  atoSocietarioId?: number;
  tipoAtoId?: number;

  tipoContratoId?: number;
  contratoId?: number;

  imovelId?: number;
  tipoImovelId?: number;

  consultaId?: number,
  tipoConsultaId?: number,

  procuracaoId?: number,
  tipoProcuracaoId?: number,

  licencaId?: number;
  tipoLicencaId?: number;


  // solucoes
  despesaId?: number;
  tipoDespesaId?: number,

  garantiaId?: number;
  tipoGarantiaId?: number,

  propostaAcordoId?: number;
  tipoPropostaAcordoId?: number;

  honorarioId?: number;
  tipoHonorarioId?: number;

  reembolsoId?: number;
  tipoReembolsoId?: number;

  audienciaId?: number;
  tipoAudienciaId?: number;

  decisaoId?: number,
  tipoDecisaoId?: number,

  tipoBloqueioId?: number,
  bloqueioId?: number,

  tipoComunicacaoId?: number,
  comunicacaoId?: number,

  parteContrariaId?: number,
  tipoParteContrariaId?: number,

  setQuantidadeArquivosSelecionados(quantidade: number): void;
  toggleModal: () => void;
  exibirModal: boolean;
  capa?: boolean;
}

const IFileUploadField = ({

  modulo,
  campo,

  exibirAdicionar = true,
  importarArquivos = false,

  despesaId,
  tipoDespesaId,

  garantiaId,
  tipoGarantiaId,

  propostaAcordoId,
  tipoPropostaAcordoId,

  decisaoId,
  tipoDecisaoId,

  tipoBloqueioId,
  bloqueioId,

  tipoDesdobramentoId,
  desdobramentoId,

  tipoObrigacaoId,
  obrigacaoId,

  areaDireitoId,
  processoId,

  empresaId,
  atoSocietarioId,
  tipoAtoId,

  contratoId,
  tipoContratoId,

  marcaId,
  tipoMarcaId,

  consultaId,
  tipoConsultaId,

  procuracaoId,
  tipoProcuracaoId,

  imovelId,
  tipoImovelId,

  licencaId,
  tipoLicencaId,

  honorarioId,
  tipoHonorarioId,

  reembolsoId,
  tipoReembolsoId,

  audienciaId,
  tipoAudienciaId,

  comunicacaoId,
  tipoComunicacaoId,

  parteContrariaId,
  tipoParteContrariaId,

  setQuantidadeArquivosSelecionados,
  toggleModal,
  exibirModal,

  capa,
}: IFileUploadFieldProps) => {
  const [tipoDocumentos, setTipoDocumento] = useState<any[]>([]);
  const [listaPasta, setListaPasta] = useState<IPastaGed[]>([]);
  const [pastaGedId, setPastaGedId] = useState<number>(0);

  const initialValues: IFile[] = [];

  useEffect(() => { if (exibirModal) carregaTipoDocumento(); }, [exibirModal]);

  const adicionaArquivo = (event: any) => {
    for (let index = 0; index < event.length; index++) {
      const currentFile = event[index];
      formik.values.push({
        index: formik.values.length,
        documentoId: 0,
        serRestriro: false,
        tipoDocumentoId: 0,
        file: currentFile,
        nome: currentFile.name,
        extensao: currentFile.name.substring(currentFile.name.lastIndexOf(".") + 1),
      });
    }

    formik.values.forEach((file: IFile, index: number) => (file.index = index));

    formik.submitForm();
  };

  const iniciarUploadArquivos = async () => {

    formik.values.forEach(async (item: IFile) => {

      const enviados = [];

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Realizando upload...`,
        allowOutsideClick: false,
        showConfirmButton: false
      });

      Swal.showLoading()

      try {

        const formData = new FormData();
        formData.append("origem", "1");
        formData.append("file", item.file);
        formData.append("tipoDocumentoId", item.tipoDocumentoId?.toString() || '');
        formData.append("descricao", item.nome);
        formData.append("restrito", item.serRestriro.toString());

        if (processoId && modulo == EModulo.AreaDireito) {
          formData.append("modulo", modulo.toString());
          formData.append("processoId", processoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (contratoId && modulo == EModulo.Contrato) {
          formData.append("modulo", modulo.toString());
          formData.append("contratoId", contratoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (marcaId && modulo == EModulo.MarcaEPertences) {
          formData.append("modulo", modulo.toString());
          formData.append("marcaId", marcaId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (imovelId && modulo == EModulo.Imovel) {
          formData.append("modulo", modulo.toString());
          formData.append("imovelId", imovelId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (consultaId && modulo == EModulo.Consultivo) {
          formData.append("modulo", modulo.toString());
          formData.append("consultaId", consultaId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (procuracaoId && modulo == EModulo.Procuracao) {
          formData.append("modulo", modulo.toString());
          formData.append("procuracaoId", procuracaoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (licencaId && modulo == EModulo.Regulatorio) {
          formData.append("modulo", modulo.toString());
          formData.append("licencaId", licencaId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (empresaId && modulo == EModulo.Societario) {
          formData.append("modulo", modulo.toString());
          formData.append("empresaId", empresaId.toString());
          if (pastaGedId > 0) {
            formData.append("pastaGedId", pastaGedId.toString());
          }
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (atoSocietarioId && modulo == EModulo.AtoSocietario) {
          formData.append("modulo", modulo.toString());
          formData.append("atoSocietarioId", atoSocietarioId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }


        if (despesaId && modulo == EModulo.Despesa) {
          formData.append("modulo", modulo.toString());
          formData.append("despesaId", despesaId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (propostaAcordoId && modulo == EModulo.PropostaAcordo) {
          formData.append("modulo", modulo.toString());
          formData.append("propostaAcordoId", propostaAcordoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }


        if (garantiaId && modulo == EModulo.Garantia) {
          formData.append("modulo", modulo.toString());
          formData.append("garantiaId", garantiaId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (decisaoId && modulo == EModulo.Decisao) {
          formData.append("modulo", modulo.toString());
          formData.append("decisaoId", decisaoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (bloqueioId && modulo == EModulo.Bloqueio) {
          formData.append("modulo", modulo.toString());
          formData.append("bloqueioId", bloqueioId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (obrigacaoId && modulo == EModulo.Obrigacao) {
          formData.append("modulo", modulo.toString());
          formData.append("obrigacaoId", obrigacaoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (comunicacaoId && modulo == EModulo.Comunicacao) {
          formData.append("modulo", modulo.toString());
          formData.append("comunicacaoId", comunicacaoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (desdobramentoId && modulo == EModulo.Desdobramento) {
          formData.append("modulo", modulo.toString());
          formData.append("desdobramentoId", desdobramentoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (honorarioId && modulo == EModulo.XpayHonorario) {
          formData.append("modulo", modulo.toString());
          formData.append("honorarioId", honorarioId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (reembolsoId && modulo == EModulo.XpayReembolso) {
          formData.append("modulo", modulo.toString());
          formData.append("reembolsoId", reembolsoId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (audienciaId && modulo == EModulo.Audiencia) {
          formData.append("modulo", modulo.toString());
          formData.append("audienciaId", audienciaId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        if (parteContrariaId && modulo == EModulo.ParteContraria) {
          formData.append("modulo", modulo.toString());
          formData.append("parteContrariaId", parteContrariaId.toString());
          enviados.push(await GedUploadService.adicionaArquivo(formData));
        }

        setQuantidadeArquivosSelecionados(0);

        Swal.hideLoading();

        await Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Upload realizado com sucesso!`,
          showConfirmButton: true,
          timer: 4000
        });

        Swal.close();

      } catch (error: any) {
        console.log(error)
      }
    });

    limparCampos();

  };


  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      formik.setValues(values);
      setQuantidadeArquivosSelecionados(values.length);
      setSubmitting(false);
    },
  });

  //modulos
  useEffect(() => { if (processoId) iniciarUploadArquivos(); }, [processoId]);
  useEffect(() => { if (contratoId) iniciarUploadArquivos(); }, [contratoId]);
  useEffect(() => { if (marcaId) iniciarUploadArquivos(); }, [marcaId]);
  useEffect(() => { if (licencaId) iniciarUploadArquivos(); }, [licencaId]);
  useEffect(() => { if (imovelId) iniciarUploadArquivos(); }, [imovelId]);
  useEffect(() => { if (empresaId) iniciarUploadArquivos(); }, [empresaId]);
  useEffect(() => { if (consultaId) iniciarUploadArquivos(); }, [consultaId]);
  useEffect(() => { if (procuracaoId) iniciarUploadArquivos(); }, [procuracaoId]);
  useEffect(() => { if (atoSocietarioId) iniciarUploadArquivos(); }, [atoSocietarioId]);

  // solucoes
  useEffect(() => { if (decisaoId) iniciarUploadArquivos(); }, [decisaoId]);
  useEffect(() => { if (despesaId) iniciarUploadArquivos(); }, [despesaId]);
  useEffect(() => { if (garantiaId) iniciarUploadArquivos(); }, [garantiaId]);
  useEffect(() => { if (honorarioId) iniciarUploadArquivos(); }, [honorarioId]);
  useEffect(() => { if (reembolsoId) iniciarUploadArquivos(); }, [reembolsoId]);
  useEffect(() => { if (obrigacaoId) iniciarUploadArquivos(); }, [obrigacaoId]);
  useEffect(() => { if (bloqueioId) iniciarUploadArquivos(); }, [bloqueioId]);
  useEffect(() => { if (audienciaId) iniciarUploadArquivos(); }, [audienciaId]);
  useEffect(() => { if (comunicacaoId) iniciarUploadArquivos(); }, [comunicacaoId]);
  useEffect(() => { if (desdobramentoId) iniciarUploadArquivos(); }, [desdobramentoId]);
  useEffect(() => { if (propostaAcordoId) iniciarUploadArquivos(); }, [propostaAcordoId]);
  useEffect(() => { if (parteContrariaId) iniciarUploadArquivos(); }, [parteContrariaId]);



  useEffect(() => { if (importarArquivos) adicionar(); }, [importarArquivos]);

  const adicionaSerRestrito = (index: number) => {
    formik.values.forEach((file) => {
      if (file.index == index) {
        if (!file.serRestriro) {
          file.serRestriro = true;
          formik.setValues(formik.values);
          return;
        }
        if (file.serRestriro) {
          file.serRestriro = false;
          formik.setValues(formik.values);
          return;
        }
      }
    });
  };

  const atribuirTipo = (index: number, tipoDocumentoId: number) => {
    if (index == 0) {
      formik.values.forEach((file) => {
        file.tipoDocumentoId = tipoDocumentoId;
        formik.setValues(formik.values);
      });
    } else {
      formik.values.forEach((file) => {
        if (file.index == index) {
          file.tipoDocumentoId = tipoDocumentoId;
          formik.setValues(formik.values);
        }
      });
    }
  };

  const atribuirDescricao = (index: number, descricao: string) => {
    formik.values.forEach((file) => {
      if (file.index == index) {
        file.nome = descricao;
        formik.setValues(formik.values);
      }
    });
  };


  const colunas: TableColumn<IFile>[] = [
    {
      name: 'Selecione o tipo do arquivo:',
      ignoreRowClick: true,
      cell: (row: IFile, index: number) => {
        return (
          <>
            <select
              name="tipoDocumentoId"
              value={row.tipoDocumentoId}
              onChange={(event) => atribuirTipo(index, parseInt(event.target.value))}
              className={"form-control"}
            >
              <option value="0">Selecione</option>
              {tipoDocumentos.map((map: any) => {
                return (
                  <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                    {map.nome}
                  </option>
                );
              })}
            </select>
          </>
        );
      },
    },
    {
      name: 'Descrição:',
      ignoreRowClick: true,
      cell: (row: IFile, index: number) => {
        return (
          <input
            value={row.nome}
            placeholder="Opcional"
            type="text"
            className={"form-control"}
            onChange={(e: any) => atribuirDescricao(index, e.target.value)}
          />
        );
      },
    },
    {
      name: "Restrito?",
      cell: (row: IFile) => {
        return (
          <div>
            <Form.Check
              type="switch"
              checked={row.serRestriro}
              id="custom-switch"
              onChange={() => adicionaSerRestrito(row.index)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
    {
      name: "Remover?",
      cell: (row: IFile) => {
        return (
          <div style={{ paddingRight: "10px" }}>
            <FontAwesomeIcon
              title="Remover?"
              onClick={async () => {
                const swalWithBootstrapButtons = Swal.mixin({
                  heightAuto: false,
                  customClass: {
                    confirmButton: "btn btn-danger ms-5",
                    cancelButton: "btn btn-orange ms-5",
                  },
                  buttonsStyling: false,
                });

                let result = await swalWithBootstrapButtons.fire({
                  title: "Cancelar",
                  text: `Você realmente deseja remover este arquivo?`,
                  showCancelButton: true,
                  cancelButtonText: "Não",
                  confirmButtonText: `Sim, desejo remover!`,
                });

                if (result.isConfirmed) {
                  let restantes = formik.values.filter((e) => e.index != row.index);
                  formik.setValues(restantes);
                }
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faTrash}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const carregaTipoDocumento = async () => {
    try {

      // modulos
      if (areaDireitoId && areaDireitoId > 0 && modulo == EModulo.AreaDireito) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.AreaDireito, areaDireitoId, processoId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoContratoId && tipoContratoId > 0 && modulo == EModulo.Contrato) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Contrato, tipoContratoId, contratoId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoMarcaId && tipoMarcaId > 0 && modulo == EModulo.MarcaEPertences) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.MarcaEPertences, tipoMarcaId, marcaId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoImovelId && tipoImovelId > 0 && modulo == EModulo.Imovel) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Imovel, tipoImovelId, imovelId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoLicencaId && tipoLicencaId > 0 && modulo == EModulo.Regulatorio) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Regulatorio, tipoLicencaId, licencaId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoProcuracaoId && tipoProcuracaoId > 0 && modulo == EModulo.Procuracao) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Procuracao, tipoProcuracaoId, procuracaoId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoConsultaId && tipoConsultaId > 0 && modulo == EModulo.Consultivo) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Consultivo, tipoConsultaId, consultaId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoAtoId && tipoAtoId > 0 && modulo == EModulo.AtoSocietario) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.AtoSocietario, tipoAtoId, atoSocietarioId, 0, campo);
        setTipoDocumento(campos.data);
      }

      //solucoes
      if (tipoDespesaId && tipoDespesaId > 0 && modulo == EModulo.Despesa) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Despesa, tipoDespesaId, despesaId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoGarantiaId && tipoGarantiaId > 0 && modulo == EModulo.Garantia) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Garantia, tipoGarantiaId, garantiaId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoBloqueioId && tipoBloqueioId > 0 && modulo == EModulo.Bloqueio) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Bloqueio, tipoBloqueioId, bloqueioId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoDecisaoId && tipoDecisaoId > 0 && modulo == EModulo.Decisao) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Decisao, tipoDecisaoId, decisaoId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoObrigacaoId && tipoObrigacaoId > 0 && modulo == EModulo.Obrigacao) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Obrigacao, tipoObrigacaoId, obrigacaoId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoComunicacaoId && tipoComunicacaoId > 0 && modulo == EModulo.Comunicacao) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Comunicacao, tipoComunicacaoId, comunicacaoId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoDesdobramentoId && tipoDesdobramentoId > 0 && modulo == EModulo.Desdobramento) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Desdobramento, tipoDesdobramentoId, desdobramentoId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoPropostaAcordoId && tipoPropostaAcordoId > 0 && modulo == EModulo.PropostaAcordo) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.PropostaAcordo, tipoPropostaAcordoId, propostaAcordoId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoParteContrariaId && tipoParteContrariaId > 0 && modulo == EModulo.ParteContraria) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.PropostaAcordo, tipoParteContrariaId, parteContrariaId, 0, campo);
        setTipoDocumento(campos.data);
      }


      if (tipoHonorarioId && tipoHonorarioId > 0 && modulo == EModulo.XpayHonorario) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.XpayHonorario, tipoHonorarioId, honorarioId, processoId, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoReembolsoId && tipoReembolsoId > 0 && modulo == EModulo.XpayReembolso) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.XpayReembolso, tipoReembolsoId, reembolsoId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoAudienciaId && tipoAudienciaId > 0 && modulo == EModulo.Audiencia) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Audiencia, tipoAudienciaId, audienciaId, empresaId, campo);
        setTipoDocumento(campos.data);
      }

      if (empresaId && empresaId > 0 && modulo == EModulo.Societario) {
        let campos = await TipoDocumentoService.obterTipoDocumentos({
          nome: '',
          codigo: '',
          status: 0,
          offset: 0,
          limit: 1000,
          sort: 'tipoDocumentoId',
          totalItems: 0
        });
        setTipoDocumento(campos.data);

        const pastas = await PastaGedService.obterPastaGeds({
          nome: '',
          codigo: '',
          status: 1,
          limit: 10,
          totalItems: 0,
          offset: 0,
          sort: 'id'
        })
        setListaPasta(pastas.data)

      }

      if (atoSocietarioId && atoSocietarioId > 0 && modulo == EModulo.AtoSocietario) {
        let campos = await TipoDocumentoService.obterTipoDocumentos({
          nome: '',
          codigo: '',
          status: 0,
          offset: 0,
          limit: 1000,
          sort: 'tipoDocumentoId',
          totalItems: 0
        });
        setTipoDocumento(campos.data);
      }

    } catch (error: any) {
      setTipoDocumento([]);
    }
  };

  const adicionar = () => {
    if (capa) {
      iniciarUploadArquivos();
    }
    toggleModal();
  };

  const limparCampos = () => formik.setValues(initialValues);

  const isValid = () => {
    let retorno = false;
    formik.values.forEach(file => {
      if (file.tipoDocumentoId == 0) retorno = true;
    });
    return retorno;
  }

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Importação de arquivos <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faFile} /> </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row g-3">

            {empresaId && (
              <div className="col-md-6 mb-3">
                <label htmlFor="form-pasta" className="form-label fw-bolder text-orange">Pasta</label>
                <select
                  placeholder="Pasta"
                  className={'form-select'}
                  onChange={(event) => setPastaGedId(parseInt(event.target.value))}
                >
                  <option value="0">Selecione uma pasta</option>
                  {listaPasta.map((pasta, index) => {
                    return (
                      <option key={index} value={pasta.pastaGedId} label={pasta.nome}> {pasta.nome} </option>
                    )
                  })}
                </select>

              </div>
            )}

            <div className="col-md-12 mb-3">
              <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                Arquivo:
              </label>
              <FileDropzone
                onDrop={(acceptedFiles) => {
                  adicionaArquivo(acceptedFiles);
                }}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <GridPadrao paginationServer={true} colunas={colunas} tipo="Arquivos" itens={formik.values} />
            </div>
          </div>

        </div>

        <div className="modal-footer" style={{ margin: "0 auto" }}>

          {exibirAdicionar ?
            <button disabled={isValid()} onClick={() => adicionar()} type="button" className=" btn btn-orange ms-5">
              Adicionar
            </button>
            :
            <button disabled={isValid()} onClick={() => toggleModal()} type="button" className=" btn btn-orange ms-5">
              Adicionar
            </button>}
          <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IFileUploadField;
