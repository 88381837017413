import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import AdvogadoInternoAcessosService from "../../../../../services/AdvogadoInternoAcessosService";
import IAdvogadoInternoAcesso from "../../../../../interfaces/IAdvogadoInternoAcesso";
import clsx from "clsx";

interface IAdvogadoInternoEditModal {
  exibirModal: boolean;
  handleClose: () => void;
  advogado: IAdvogadoInternoAcesso;
}

const AdvogadoInternoEditModal = ({
  exibirModal,
  handleClose,
  advogado,
}: IAdvogadoInternoEditModal) => {
  const validarFormPermissoes = Yup.object().shape({
    codigo: Yup.string()
      .max(50, "O máximo é de 50 caracteres.")
      .min(1, "Mínimo de 1 caractere."),
  });

  const formik = useFormik({
    initialValues: {
      codigo: advogado.codigo ?? null,
    },
    enableReinitialize: true,
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        await AdvogadoInternoAcessosService.atualizarAdvogadoInterno(
          advogado.advogadoInternoId.toString(),
          {
            codigo: values.codigo,
          }
        );

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Advogado atualizado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível atualizar o advogado`,
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          showConfirmButton: true,
        });
      } finally {
        resetForm();
        handleClose();
      }
    },
  });

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Cadastrar Advogado</h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Código
              </label>
              <input
                {...formik.getFieldProps("codigo")}
                placeholder="Código"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.codigo && formik.errors.codigo,
                  },
                  {
                    "is-valid": formik.touched.codigo && !formik.errors.codigo,
                  }
                )}
                id="form-codigo"
              />
              {formik.touched.codigo && formik.errors.codigo && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.codigo}</span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdvogadoInternoEditModal;
