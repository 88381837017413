import { Descendant, Element, Transforms } from "slate";
import { DocumentStyle, EditorHandler, StaticHandler } from "../types";
import { unitHelper } from "./unit";
import {
  ContentElement,
  CustomEditor,
  PageElement,
} from "../../../utils/types/slate";
import { is } from "./is";
import { nodeHelper } from "./nodeHelper";
import { PageService } from "../lib";
import { documentStyles } from "../lib/slate/services/document/styles";
import { childHelper } from "./handleChild";
import { getStyles } from "./getStyles";
import { promise } from "./promise";
import { CommonService } from "../lib/slate/services/commons";

function emptyPage(
  styles: DocumentStyle = documentStyles.default,
  pageNumber = 0
) {
  const page = PageService.makePage(pageNumber, styles);
  return page;
}

function maxPageHeight(documentStyles: DocumentStyle, pageNumber: number) {
  const { page, content } = documentStyles;
  const pageHeight = unitHelper.removeUnit(page.height);
  const [topSpacing, bottomSpacing] = [
    unitHelper.removeUnit(content.marginBottom),
    unitHelper.removeUnit(content.marginTop),
  ];
  const spacing = topSpacing + bottomSpacing;
  const header = document.getElementById(`header_component_${pageNumber}`);
  const footer = document.getElementById(`footer_component_${pageNumber}`);

  console.log()

  let blockSpacing = 0

  if (header) {
    blockSpacing += header.offsetHeight;
  }

  if (footer) {
    blockSpacing += footer.offsetHeight;
  }

  return pageHeight - spacing - blockSpacing;
}

function getHtmlPageContent(editor: CustomEditor, pageNumber: number) {
  const page = editor.children[pageNumber];

  if (!is<PageElement>(page, "page")) {
    return null;
  }

  const pageContent = page.children.find((child) => {
    return is<ContentElement>(child, "content");
  });

  if (!pageContent) {
    return null;
  }

  return nodeHelper.toHTML(editor, pageContent);
}

function createPage(data: EditorHandler) {
  const { editor, pageNumber } = data;

  return promise.create(() => {
    const existentPage = editor.children?.[pageNumber];

    if (existentPage) {
      Transforms.removeNodes(editor, { at: [pageNumber] })
    }

    const pageElement: PageElement = emptyPage(getStyles(data), data.pageNumber);
    const newPage = childHelper.getChilds(pageElement, data);
    Transforms.insertNodes(editor, newPage, { at: [pageNumber] });
    data.nodes.forEach((node, index) => {
      Transforms.insertNodes(editor, node, { at: [pageNumber, 1, index] });
    });
  });
}

function removeElements(editor: CustomEditor, nodes: Descendant[], removeOnlyHtml = false) {
  return promise.create(() => {
    for (const node of nodes) {
      if (removeOnlyHtml) {
        const htmlElement = nodeHelper.toHTML(editor, node);
        htmlElement?.parentNode?.removeChild(htmlElement);
      } else {
        if (Element.isElement(node)) {
          CommonService.removeElement(editor, node);
        } 
      }
    }
  });
}

function breakPage(data: EditorHandler) {
  const { editor, nodes, pageNumber } = data;
  removeElements(editor, nodes, data.removeOnlyHtml).then(() => {
    populate({ ...data, pageNumber: pageNumber + 1 });
  });
}

function splitContent(
  childs: HTMLElement[],
  heightLimit: number
) {
  childs.forEach((child) => {
    const childHeight = unitHelper.pxToPt(child.offsetHeight);
    if (childHeight > heightLimit) {
      child.parentNode?.removeChild(child);
    }
  });
  return childs;
}

function organize(version: EditorHandler) {
  const { editor, pageNumber } = version;
  const styles = getStyles(version);
  const heightLimit = maxPageHeight(styles, pageNumber);
  const content = getHtmlPageContent(editor, pageNumber);

  if (content) {
    let currentPageHeight = 0;
    const editorElements: Descendant[] = [];
    const childs = splitContent(
      Array.from(content.children) as HTMLElement[],
      heightLimit
    );

    childs.forEach((child) => {
      const childHeight = child.offsetHeight;
      currentPageHeight += childHeight;
      if (currentPageHeight > heightLimit) {
        const editorElement = nodeHelper.toNode(editor, child);
        if (editorElement && Element.isElement(editorElement)) {
          editorElements.push(editorElement);
        }
      }
    });

    if (editorElements.length > 0) {
      breakPage({
        ...version,
        pageNumber,
        nodes: editorElements,
      });
    } else {
      version.onFinish();
    }
  }
}

function staticInsert(data: StaticHandler) {
  Transforms.removeNodes(data.editor)

  data.nodes.forEach((node, index) => {
    Transforms.insertNodes(data.editor, node, { at: [index] })
  })
}

function clear(editor: CustomEditor) {
  return promise.create(() => {
    editor.children.forEach(
      (page) =>
        Element.isElement(page) && CommonService.removeElement(editor, page)
    );
  });
}

function populate(data: EditorHandler) {
  createPage(data).then(() => organize(data))
}

export const pageHelper = {
  organize,
  createPage,
  emptyPage,
  populate,
  staticInsert,
  clear,
  getHtmlPageContent,
  maxPageHeight,
};
