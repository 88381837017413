import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import clsx from "clsx";
import DocumentoService from "../../../services/DocumentoService";
import Select from "react-select";
import { optionsType } from "../../../components/Comum/Fornecedor/FornecedorField";
import { useEffect, useState } from "react";

interface IModeloDocumentoUploadMinutaModal {
  toggleModalUploadMinuta: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  titulo: string;
  ModeloDocumentoId: number;
  modulos: optionsType[];
}

const ModeloDocumentoUploadMinutaModal = ({
  toggleModalUploadMinuta,
  exibirModal,
  titulo,
  ModeloDocumentoId,
  modulos,
}: IModeloDocumentoUploadMinutaModal) => {
  const [modulo, setModulo] = useState<optionsType>({ label: "", value: 0 });

  const novoXlearningValidacao = Yup.object().shape({
    arquivo: Yup.mixed()
      .required("Arquivo obrigatório.")
      .test("type", "O arquivo deve ser do tipos docx.", (arquivo) => {
        const caminho = arquivo as string;
        return caminho !== undefined && caminho.endsWith(".docx");
      }),

    modulo: Yup.number()
      .required()
      .min(1, "O módulo é obrigatório"),
  });

  const formularioUploadMinuta = useFormik({
    initialValues: {
      arquivo: "",
      codigo: "",
      descricao: "",
      modulo: 0,
    },
    validationSchema: novoXlearningValidacao,
    onSubmit: async (values, { setSubmitting }) => {
      const campo = document.querySelector("#form-arquivo");

      if (!values.arquivo || !campo) return;

      const arquivos: FileList | null = (campo as HTMLInputElement).files;

      if (!arquivos || arquivos.length === 0) return;

      for (let i = 0; i < arquivos.length; i++) {
        if (!arquivos[i].name?.endsWith(".docx")) {
          return await Swal.fire({
            title: "Arquivo inválido.",
            text: "O arquivo deve ser do tipo docx.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Enviando arquivos...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      Swal.showLoading();

      for (let i = 0; i < arquivos.length; i++) {
        const dados = {
          Modulo: values.modulo,
          Origem: "20",
          File: arquivos[i],
          TipoDocumentoId: 317,
          Descricao: "Modelo de Documento",
          Restrito: "false",
          Codigo: "",
          ModeloDocumentoId: ModeloDocumentoId,
        };

        try {
          const swalWithBootstrapButtons = Swal.mixin({
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-orange",
              cancelButton: "btn btn-danger ms-5",
            },
            buttonsStyling: false,
          });

          let result = await swalWithBootstrapButtons.fire({
            title: "Atualizar Minuta",
            text: `Você realmente deseja atualizar este Modelo de Documento?`,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: `Confirmar`,
          });

          if (result.isConfirmed) {
            await DocumentoService.upload(dados);

            await Swal.fire({
              heightAuto: false,
              icon: "success",
              text: `Minuta atualizada com sucesso`,
              showConfirmButton: false,
              timer: 3000,
            });

            toggleModalUploadMinuta();
          }
        } catch (error: any) {
          setSubmitting(false);
          let mensagemErro = "Ocorreu um erro inesperado";

          if (error?.response?.data?.Message) {
            mensagemErro = error.response.data.Message;
          }
          return Swal.fire({
            heightAuto: false,
            icon: "error",
            title: `Não foi possível atualizar o Modelo de Documento`,
            text: mensagemErro,
            showConfirmButton: true,
          });
        } finally {
          formularioUploadMinuta.setFieldValue("arquivo", undefined);
          setSubmitting(false);
          setModulo({ label: "", value: 0 });
          Swal.close();
        }
      }
    },
  });

  useEffect(() => {
    console.log(formularioUploadMinuta.values);
  }, [ModeloDocumentoId]);

  return (
    <Modal
      size="lg"
      centered={true}
      show={exibirModal}
      backdrop="static"
      onEscapeKeyDown={(e) => {
        e.preventDefault();
        return false;
      }}
      onHide={() => {
        formularioUploadMinuta.resetForm();
        formularioUploadMinuta.setFieldValue("arquivo", undefined);
        setModulo({ label: "", value: 0 });
        toggleModalUploadMinuta();
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">{titulo}</h5>
          <div
            onClick={() => toggleModalUploadMinuta()}
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          >
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form className="row g-3">
            <div className="row mt-2">
              <div className="col-12 col-lg-6">
                <label
                  htmlFor="form-arquivo"
                  className="form-label required fw-bolder text-orange"
                >
                  Minuta
                </label>
                <input
                  {...formularioUploadMinuta.getFieldProps("arquivo")}
                  placeholder="Selecione o Modelo de Documento"
                  type="file"
                  multiple={false}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formularioUploadMinuta.touched.arquivo &&
                        formularioUploadMinuta.errors.arquivo,
                    },
                    {
                      "is-valid":
                        formularioUploadMinuta.touched.arquivo &&
                        !formularioUploadMinuta.errors.arquivo,
                    }
                  )}
                  id="form-arquivo"
                />
                {formularioUploadMinuta.touched.arquivo &&
                  formularioUploadMinuta.errors.arquivo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formularioUploadMinuta.errors.arquivo}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="col-md-6 mb-3">
                <label
                  htmlFor="form-nome"
                  className="form-label required fw-bolder"
                >
                  Módulo:
                </label>

                <Select
                  placeholder="Selecione um módulo"
                  id="form-select"
                  options={modulos}
                  isSearchable
                  value={modulo}
                  onChange={(select) => {
                    if (select) {
                      setModulo(select);
                      formularioUploadMinuta.setFieldValue(
                        "modulo",
                        select.value
                      );
                    }
                  }}
                />

                {formularioUploadMinuta.touched.modulo &&
                  formularioUploadMinuta.errors.modulo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formularioUploadMinuta.errors.modulo}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={
              formularioUploadMinuta.isSubmitting ||
              !formularioUploadMinuta.isValid
            }
            onClick={() => {
              formularioUploadMinuta.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            {!formularioUploadMinuta.isSubmitting && <> SALVAR </>}
            {formularioUploadMinuta.isSubmitting && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Salvando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <button
            disabled={formularioUploadMinuta.isSubmitting}
            onClick={() => toggleModalUploadMinuta()}
            type="button"
            className="btn btn-danger ms-5"
          >
            {" "}
            CANCELAR{" "}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModeloDocumentoUploadMinutaModal;
