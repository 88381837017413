import DataTable, {
  ConditionalStyles,
  PaginationOptions,
} from "react-data-table-component";

import { ToastContainer } from "react-toastify";
import Carregando from "../../../../../components/Carregando";

interface GridPadraoType {
  colunas: any;
  itens: any[];
  tipo: string;
  conditionalRowStyles?: ConditionalStyles<any>[];
  selectableRows?: boolean;
  paginationServer?: boolean;
  paginationTotalRows?: number;
  onChangeRowsPerPage?: (
    currentRowsPerPage: number,
    currentPage: number
  ) => void;
  onChangePage?: (page: number, totalRows: number) => void;
  onRowClicked?: (row: any, event: any) => void;
  take?: number;
  progressPending?: boolean;
}

export default function GridPerfil({
  progressPending,
  take = 5,
  colunas,
  itens,
  conditionalRowStyles,
  selectableRows,
  paginationServer,
  paginationTotalRows,
  onChangePage,
  onRowClicked,
}: Readonly<GridPadraoType>) {
  const paginationComponentOptions: PaginationOptions = {
    rowsPerPageText: "Perfis",
    rangeSeparatorText: "de",
    noRowsPerPage: true,
  };

  return (
    <>
      {itens?.length > 0 ? (
        <DataTable
          progressComponent={<Carregando />}
          progressPending={progressPending}
          paginationPerPage={take}
          onRowClicked={onRowClicked}
          onChangePage={onChangePage}
          pagination={true}
          paginationServer={paginationServer}
          paginationTotalRows={paginationTotalRows}
          selectableRows={selectableRows}
          conditionalRowStyles={conditionalRowStyles}
          highlightOnHover={true}
          columns={colunas}
          data={itens}
          paginationComponentOptions={paginationComponentOptions}
        />
      ) : (
        <ToastContainer />
      )}
    </>
  );
}
