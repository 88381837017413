import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TableColumn } from "react-data-table-component";
import GridUser from "./components/GridUser";
import IPerfil from "../../interfaces/IPerfil";
import IUsuariosPerfil from "../../interfaces/IUsuariosPerfil";

interface IPerifDetalheProps {
  toggleModal: () => void;
  exibirModal: boolean;
  perfil: IPerfil;
  titulo: string;
}

const PerfilDetalhesModal = ({
  toggleModal,
  exibirModal,
  perfil,
  titulo,
}: IPerifDetalheProps) => {
  const [exibePerfis, setExibePerfis] = useState<boolean>(false);
  const [usuarios, setUsuarios] = useState<IUsuariosPerfil[]>([]);

  const colunas: TableColumn<IUsuariosPerfil>[] = [
    {
      name: "ID",
      sortField: "ID",
      selector: (row: IUsuariosPerfil) => row.usuarioId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IUsuariosPerfil) => row.nomeUsuario,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      sortField: "email",
      selector: (row: IUsuariosPerfil) => row.email,
      sortable: true,
      wrap: true,
    },
  ];

  const handleHide = () => {
    setExibePerfis(false);
    toggleModal();
  };

  useEffect(() => {
    const mappedPerfil = perfil.usuarios.map((usuario) => usuario);

    setUsuarios(mappedPerfil);
  }, [perfil]);

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleHide}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">{titulo}</h5>
          <div
            onClick={handleHide}
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          >
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row mt-2">
            <div className="col-md-6">
              <label
                htmlFor="form-nomeUsuario"
                className="form-label fw-bolder text-orange"
              >
                Nome do Perfil
              </label>
              <br />
              {perfil?.nome}
            </div>

            <div className="col-md-6">
              <label
                htmlFor="form-clienteNome"
                className="form-label fw-bolder text-orange"
              >
                Hash AD
              </label>
              <br />
              {perfil?.perfilAdHash}
            </div>

            <div className="row mt-2 mb-3">
              <div className="col-md-6">
                <label
                  htmlFor="form-codigo"
                  className="form-label fw-bolder text-orange"
                >
                  Perfil Inicial?
                </label>
                <br />
                {perfil?.perfilInicial ? "Sim" : "Não"}
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-12">
                <label
                  className="form-label fw-bolder text-orange"
                  onClick={() => setExibePerfis(!exibePerfis)}
                  style={{ cursor: "pointer" }}
                >
                  Usuários atribuídos ao perfil:
                  <FontAwesomeIcon
                    color={"var(--primary-base2)"}
                    className="mx-3"
                    icon={exibePerfis ? faMinus : faPlus}
                  />
                </label>
              </div>
            </div>

            {exibePerfis && (
              <GridUser
                colunas={colunas}
                itens={usuarios}
                tipo="Perfis"
                paginationServer={false}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PerfilDetalhesModal;
