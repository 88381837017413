import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import ReactSelect, { MultiValue } from "react-select";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";

import { MultiSelect } from "react-multi-select-component";
import IProcesso from "../../interfaces/IProcesso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IDesdobramento from "../../interfaces/IDesdobramento";
import ITipoDesdobramento from "../../interfaces/ITipoDesdobramento";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaDesdobramentoRequest from "../../interfaces/Requests/Desdobramento/FiltroPesquisaDesdobramentoRequest";
import FiltroPesquisaTipoDesdobramentoRequest from "../../interfaces/Requests/TipoDesdobramento/FiltroPesquisaTipoDesdobramentoRequest";

import DesdobramentoService from "../../services/DesdobramentoService";
import TipoDesdobramentoService from "../../services/TipoDesdobramentoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const DesdobramentoPage = () => {
  const [StatusOptions, setStatusOptions] = useState<any>([{ label: "Ativo", value: 1 }, { label: "Encerrado", value: -1 }]);
  const [StatusSelected, setStatusSelected] = useState([]);

  const [carregandoDesdobramentos, setCarregandoDesdobramentos] = useState(false);

  const [tipoDesdobramentos, setTipoDesdobramentos] = useState<ITipoDesdobramento[]>([]);
  const [desdobramentos, setDesdobramentos] = useState<IDesdobramento[]>([]);

  const { processo, setProcesso, setAreaDireito, desdobramento, setDesdobramento } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [filtrosPesquisaTipoDesdobramento, setFiltrosPesquisaTipoDesdobramento] = useState<FiltroPesquisaTipoDesdobramentoRequest>({
    tipoDesdobramento: [],
    nome: "",
    codigo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "tipoDesdobramentoId",
  });
  const [tipoDesdobramentosSelecionados, setTipoDesdobramentosSelecionados] = useState<MultiValue<{ value: string; label: string }>>([]);


  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaDesdobramentoRequest>({
    desdobramentoId: 0,
    tipoDesdobramentoId: [],
    modulo: 0,
    clienteId: 0,
    processoId: processo.processoId,
    resumo: "",
    dataDistribuicaoMaiorIgual: "",
    dataDistribuicaoMenorIgual: "",
    dataProtocoloMaiorIgual: "",
    dataProtocoloMenorIgual: "",
    instanciaId: 0,
    cidadeId: 0,
    numero: "",
    dataEncerramentoMaiorIgual: "",
    dataEncerramentoMenorIgual: "",
    motivoEncerramentoId: 0,
    responsavelDesdobramento: 0,
    usuarioIdCadastro: 0,
    codigo: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    statusdesdobramentoNome: "",
    tipodesdobramentoNome: "",
    statusDesdobramentoId: [],
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "desdobramentoId",
  });

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusDesdobramentoId: IdsSelected };
    });
  }, [StatusSelected]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_desdobramento");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_desdobramento");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_desdobramento")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaDesdobramentoRequest = JSON.parse((await sessionStorageService.obter("filtro_desdobramento")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, processoId: filtro.processoId });
  };

  async function carregarTipoDesdobramento(filtro: FiltroPesquisaTipoDesdobramentoRequest) {
    try {
      let resultado: IRecuperaLista<ITipoDesdobramento>;

      resultado = await TipoDesdobramentoService.obterTipoDesdobramentos(filtro);

      setFiltrosPesquisaTipoDesdobramento((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setTipoDesdobramentos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoDesdobramentos([]);
    }
  }

  useLayoutEffect(() => {
    carregarTipoDesdobramento(filtrosPesquisaTipoDesdobramento);
  }, []);

  async function carregarDesdobramentos(filtro: FiltroPesquisaDesdobramentoRequest): Promise<void> {
    try {
      setCarregandoDesdobramentos(true);
      let resultado: IRecuperaLista<IDesdobramento>;

      resultado = await DesdobramentoService.obterDesdobramentos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setDesdobramentos(resultado.data);
      inserirSessionStorage();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setDesdobramentos([]);
    } finally {
      setCarregandoDesdobramentos(false);
    }
  }

  const colunas: TableColumn<IDesdobramento>[] = [
    {
      name: "Id",
      sortField: "DesdobramentoId",
      selector: (row: IDesdobramento) => row.desdobramentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Número",
      sortField: "numero",
      selector: (row: IDesdobramento) => row.numero,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo de Desdobramento",
      sortField: "tipoDesdobramentoId,",
      selector: (row: IDesdobramento) => row.tipoDesdobramentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Distribuição",
      sortField: "dataDistribuicao",
      selector: (row: IDesdobramento) => {
        if (row.dataDistribuicao === null) return "-";
        const data = row.dataDistribuicao.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Instância",
      sortField: "instanciaId,",
      selector: (row: IDesdobramento) => row.instanciaNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Status",
      sortField: "status",
      selector: (row: IDesdobramento) => row.statusDesdobramentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Responsável",
      sortField: "responsavelDesdobramento",
      selector: (row: IDesdobramento) => nomearResponsavel(row?.responsavelDesdobramento?.toString()),
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (proposta: IDesdobramento) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Editar"
              onClick={() => abrirCapa(proposta)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IDesdobramento>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const nomearResponsavel = (nome: string): string => {
    switch (nome) {
      case "Empresa":
        return "Empresa";
      case "ParteContraria":
        return "Parte Contrária";
      default:
        return "Não definido";
    }
  }

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        desdobramentoId: 0,
        tipoDesdobramentoId: [],
        modulo: 0,
        clienteId: 0,
        processoId: 0,
        resumo: "",
        dataDistribuicaoMaiorIgual: "",
        dataDistribuicaoMenorIgual: "",
        dataProtocoloMaiorIgual: "",
        dataProtocoloMenorIgual: "",
        instanciaId: 0,
        cidadeId: 0,
        numero: "",
        dataEncerramentoMaiorIgual: "",
        dataEncerramentoMenorIgual: "",
        motivoEncerramentoId: 0,
        responsavelDesdobramento: 0,
        usuarioIdCadastro: 0,
        codigo: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        statusDesdobramentoId: [],
      };
    });
    setTipoDesdobramentosSelecionados([]);
  }

  const abrirCapa = (row: IDesdobramento) => {
    setDesdobramento({
      ...row,
      desdobramentoId: row.desdobramentoId,
      tipoDesdobramentoId: row.tipoDesdobramentoId,
      categoriaId: row.tipoDesdobramentoId,

      modulo: desdobramento.modulo,

      processoId: desdobramento?.processoId,
      areaDireitoId: desdobramento.areaDireitoId,

      tipoImovelId: desdobramento.tipoImovelId,
      imovelId: desdobramento?.imovelId
    });
    navigate("/Desdobramento/Capa");
  };

  return (
    <>
      <LayoutPageForButton title="Desdobramento">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setDesdobramento({ ...desdobramento, tipoDesdobramentoId: 0 });
            navigate("/Desdobramento/Novo");
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>


      <div className="row mt-2">
        <div className="col-md-12">

          <CollapseFiltro titulo="Filtros" content={
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarDesdobramentos(filtrosPesquisa);
              }}
              className="row g-3 mb-3 form-buscar-desdobramento"
            >
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                  Data do Cadastro:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                      ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue) => {
                        return {
                          ...oldValue,
                          dataCadastroMaiorIgual: "",
                          dataCadastroMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-dataDistribuicao" className="form-label fw-bolder text-orange">
                  Data de Distriuição:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataDistribuicaoMaiorIgual && filtrosPesquisa.dataDistribuicaoMenorIgual
                      ? [filtrosPesquisa.dataDistribuicaoMaiorIgual, filtrosPesquisa.dataDistribuicaoMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue) => {
                        return {
                          ...oldValue,
                          dataDistribuicaoMaiorIgual: "",
                          dataDistribuicaoMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataDistribuicaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataDistribuicaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-dataProtocolo" className="form-label fw-bolder text-orange">
                  Data do Protocolo:
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataProtocoloMaiorIgual && filtrosPesquisa.dataProtocoloMenorIgual
                      ? [filtrosPesquisa.dataProtocoloMaiorIgual, filtrosPesquisa.dataProtocoloMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue) => {
                        return {
                          ...oldValue,
                          dataProtocoloMaiorIgual: "",
                          dataProtocoloMenorIgual: "",
                        };
                      });
                      return;
                    }
                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataProtocoloMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataProtocoloMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={false}
                  disabled={false}
                  options={StatusOptions}
                  value={StatusSelected}
                  onChange={(event: any) => setStatusSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-tipoDesdobramentoId" className="form-label fw-bolder text-orange">
                  Tipo de Desdobramento:
                </label>
                <ReactSelect
                  onChange={(selectedOptions) => {
                    let tipoDesdobramentoId = selectedOptions.map((c) => parseInt(c.value));

                    setFiltrosPesquisa((oldState) => ({ ...oldState, tipoDesdobramentoId: tipoDesdobramentoId }));
                    setTipoDesdobramentosSelecionados(selectedOptions);
                  }}
                  name="tipoDesdobramentoId"
                  placeholder="Tipo de Desdobramento"
                  isMulti={true}
                  value={tipoDesdobramentosSelecionados}
                  defaultValue={tipoDesdobramentosSelecionados}
                  options={tipoDesdobramentos.map((c) => ({ value: c.tipoDesdobramentoId.toString(), label: c.nome }))}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-numero" className="form-label fw-bolder text-orange">
                  Número:
                </label>
                <input
                  value={filtrosPesquisa.numero}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, numero: e.target.value };
                    });
                  }}
                  placeholder="Número"
                  className={"form-control"}
                  id="form-numero"
                />
              </div>

              <div className="col-12 " style={{ textAlign: "right" }}>
                <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                  <FontAwesomeIcon color="white" className="" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-orange search-buttom-margin ms-2"
                  title="Limpar Filtro"
                >
                  <FontAwesomeIcon color="white" className="" icon={faEraser} />
                </button>
              </div>
            </form>} />
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoDesdobramentos}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Desdobramentos"
            itens={desdobramentos}
          />
        </div>
      </div>
    </>
  );
};

export default DesdobramentoPage;
