import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
export const NomeGrupoContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const NomeGrupo = styled.div`
  padding: 12px 40px;
  border-radius: 8px;
  background: #EBF4F9;
  border: 3px solid #3B86C2;
`
export const ContainerInterligacao = styled.div`
  display: flex;
  justify-content: center;

  > div {
    border-left: 3px solid #3B86C2;
    height: 20px;
  }
`
export const ElementoContainer = styled.div`
  display: flex;
  padding: 0 20px;
`
