import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAlt, faSearch, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import PublicacaoService from "../../../services/PublicacaoService";

import IPublicacao from "../../../interfaces/IPublicacao";

import GridPadrao from "../../GridPadrao";
import IDModalPage from "../../../pages/ProcessoPage/Modal/IDModalPage";

interface IPublicacaoModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  publicacao: IPublicacao;
  titulo: string;
}

const PublicacaoDetalheModal = ({ toggleModal, exibirModal, publicacao, titulo }: IPublicacaoModalProps) => {

  const [exibirProcesso, setExibirProcesso] = useState<boolean>(false);
  const toggleProcessoModal = () => setExibirProcesso(!exibirProcesso);

  const initialValues = {
    processoprincipalid: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      vincularProcesso(publicacao.publicacaoId, values.processoprincipalid);
    },
  });

  function formatarData(data: string): string {
    const dataRet = data || ''
    if (dataRet.includes('T')) {
      const [ano, mes, dia] = dataRet.split('T')[0].split('-')
      return `${dia}/${mes}/${ano}`
    }
    return '-'
  }

  async function alterarStatus(Id: number, status: number) {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-orange',
          cancelButton: 'btn btn-danger ms-5'
        },
        buttonsStyling: false
      });

      let result = await swalWithBootstrapButtons.fire({
        title: 'Alterar Status ',
        text: `Você realmente deseja ${status == 2 ? 'marcar como lido' : ''}${status == 3 ? 'descartar' : ''}?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: `Confirmar`
      });

      if (result.isConfirmed) {

        await PublicacaoService.alterarStatus(Id, status);

        Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Status alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000
        })
      }

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        title: `Não foi possivel alterar o Status do Banco`,
        text: error?.response?.data?.Message,
        showConfirmButton: true
      });
    } finally {
      toggleModal();
    }
  }

  useEffect(() => {

    if (formik.values.processoprincipalid > 0) {
      formik.handleSubmit();
    }

  }, [formik.values.processoprincipalid]);

  async function vincularProcesso(Id: number, processoId: number) {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-orange',
          cancelButton: 'btn btn-danger ms-5'
        },
        buttonsStyling: false
      });

      let result = await swalWithBootstrapButtons.fire({
        title: 'Processo',
        text: `Você realmente deseja vincular?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: `Confirmar`
      });

      if (result.isConfirmed) {

        await PublicacaoService.vincularProcesso(Id, processoId);

        toggleModal();

        Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Processo Vinculado com sucesso`,
          showConfirmButton: false,
          timer: 3000
        });

      }

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        title: `Não foi possivel alterar`,
        text: error?.response?.data?.Message,
        showConfirmButton: true
      });
    }
  }

  const colunasPartesContrarias: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "processoParteContrariaId",
      selector: (row: any) => row.processoParteContrariaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacaoNome",
      selector: (row: any) => row.formaParticipacaoNome,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <>

      <IDModalPage
        toggleProcessoModal={toggleProcessoModal}
        exibirProcessoModal={exibirProcesso}
        formik={formik}
      />

      <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}  <FontAwesomeIcon className="mx-2 text-orange" icon={faFileAlt} /></h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>

          <div className="modal-body" style={{ maxHeight: "400px", overflowY: "auto" }}>

            <div className="row mt-3">

              <div className="col-md-3">
                <label htmlFor="form-publicacao" className="form-label fw-bolder text-orange">
                  Número do Processo:
                </label>
                <br />
                {publicacao.numeroProcessoCapturado}
              </div>

              <div className="col-md-3">
                <label htmlFor="form-dataPublicacao" className="form-label fw-bolder text-orange">
                  Data de Publicação:
                </label>
                <br />
                {formatarData(publicacao.dataPublicacao.toString() || "")}
              </div>

              <div className="col-md-3">
                <label htmlFor="form-dataDivulgacao" className="form-label fw-bolder text-orange">
                  Data de Divulgação:
                </label>
                <br />
                {formatarData(publicacao.dataDivulgacao.toString() || "")}
              </div>

              <div className="col-md-3">
                <label htmlFor="form-dataCadastro" className="form-label fw-bolder text-orange">
                  Data de Cadastro no Sistema
                </label>
                <br />
                {formatarData(publicacao.dataCadastro.toString() || "")}
              </div>

            </div>


            <div className="row mt-3">

              <div className="col-md-3">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status
                </label>
                <br />
                {publicacao.status}
              </div>

              <div className="col-md-3">
                <label htmlFor="form-estado" className="form-label fw-bolder text-orange">
                  Estado:
                </label>
                <br />
                {publicacao.estadoNome}
              </div>
            </div>


            <div className="row mt-3">
              <div className="col-md-12">
                <label htmlFor="form-publicacao" className="form-label fw-bolder text-orange">
                  Partes:
                </label>
                <br />
                <GridPadrao
                  paginationServer={true}
                  colunas={colunasPartesContrarias}
                  tipo="Partes"
                  itens={publicacao.processoParteContraria}
                />
              </div>
            </div>


            <div className="row mt-3">
              {!publicacao.processoId &&
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      {...formik.getFieldProps('processoprincipalid')}
                      placeholder={'Informar o Processo'}
                      type="number"
                      className={'form-control'}
                    />
                    <Button
                      onClick={() => toggleProcessoModal()}
                      style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                      variant="secondary"
                      size="sm"
                    >
                      Pesquisar: <FontAwesomeIcon color={"white"} className="mx-1" icon={faSearch} />
                    </Button>
                  </div>
                </div>
              }
            </div>


            <div className="row mt-3">
              <div className="col-md-12">
                <label htmlFor="form-teorPublicacao" className="form-label fw-bolder text-orange">
                  Teor da Publicação:
                </label>
                <br />
                {publicacao?.teorPublicacao?.split(/\.(?=\s\d)/).map((sentence, index) => (
                  <p key={index}>{sentence?.trim()}.</p>
                ))}
              </div>
            </div>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>

            <button onClick={() => alterarStatus(publicacao.publicacaoId, 2)} type="button" className="btn btn-success me-5">
              <FontAwesomeIcon className="mx-2" icon={faCheck} />
            </button>

            <button onClick={() => alterarStatus(publicacao.publicacaoId, 3)} type="button" className="btn btn-danger ms-5">
              <FontAwesomeIcon className="mx-2" icon={faTrash} />
            </button>
          </div>
        </div>

      </Modal>
    </>
  );
};

export default PublicacaoDetalheModal;
