"use client";

import { memo, useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FiSettings } from 'react-icons/fi';
import { NodeResizeControl } from 'reactflow';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

import sessionStorageService from '../../../../../services/sessionStorage/sessionStorageService';

import { notifySuccess } from '../../../../../components/Comum/Toast/ToastFormik';
import './styles/NodeRaia.css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const controlStyle = {
  background: 'transparent',
  border: 'none',
};

const NodeRaia = ({ data: initialData, id }: any) => {

  const [dimensions, setDimensions] = useState({ width: 100, height: 200 });
  const latestDimensions = useRef(dimensions);

  const [data, setData] = useState(initialData);
  const [exibirModal, setExibirModal] = useState(false);
  const [inputValues, setInputValues] = useState(initialData);

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {

        atualizarLocalStorage('atualizaNode', JSON.stringify({ data: values, id }));

        notifySuccess('Informações atualizadas com sucesso');

      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          title: `Não foi possível salvar esta solicitação`,
          text: error.message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        toggleModal();
      }
    },
  });

  useEffect(() => {
    if (exibirModal) {
      setInputValues(data);
    }
  }, [exibirModal, data]);

  const handleResize = (event: any, direction: any) => {
    requestAnimationFrame(() => {
      latestDimensions.current = {
        width: direction.width,
        height: direction.height,
      };
      setDimensions(latestDimensions.current);
    });
  };

  const atualizarLocalStorage = (chave: any, valor: any) => {

    sessionStorageService.inserir(chave, valor);

    const evento = new CustomEvent('localStorageAtualizado', {
      detail: { chave, valor },
    });

    window.dispatchEvent(evento);
  };

  const open = () => setExibirModal(true);

  const toggleModal = () => {

    setExibirModal(!exibirModal);

    if (!exibirModal) setInputValues(data);

  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => formik.setFieldValue(key, e.target.value);

  const renderCampos = () => {
    return Object.keys(inputValues).map((key: string) => (
      <div key={key}>
        <label htmlFor={`form-${key}`} className="form-label fw-bolder text-orange">{key}</label>
        <input
          type="text"
          value={formik.values[key]}
          onChange={(e) => onChange(e, key)}
          className={"form-control"}
        />
      </div>
    ));
  };


  const cancelar = () => {

    setData({ ...data, nome: 'apagar' });

    atualizarLocalStorage('apagaNode', JSON.stringify({ data, id }));

    toggleModal();
  };

  return (
    <>
      <div className="nodeFaseIcon" style={{ zIndex: '-3' }} onClick={open}>
        <FiSettings />
      </div>

      <div style={{ position: 'relative', border: '1px solid black', backgroundColor: 'rgb(0, 0, 0, 0.1)', zIndex: '-10', width: dimensions.width }}>
        <NodeResizeControl style={controlStyle} minWidth={100} minHeight={200} onResize={handleResize}>
          <ResizeIcon />
        </NodeResizeControl>

        <div style={{ backgroundColor: 'white', width: 'auto', textAlign: 'center', fontSize: '18px', padding: '10px', height: dimensions.height, writingMode: 'vertical-rl', borderRight: '1px solid black' }}>
          {data.nome ? (
            <p>{data.nome}</p>
          ) : (
            <p>clique e edite</p>
          )}
        </div>
      </div>

      <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">

          <div className="modal-header">
            <h5 className="modal-title text-orange">Configuração da Raia</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>

          <div className="modal-body">
            <div className="col-md-auto mb-10">
              {renderCampos()}
            </div>
          </div>

          <div className="modal-footer" style={{ margin: '0 auto' }}>
            <button
              onClick={cancelar}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>
            <button
              onClick={() => formik.handleSubmit()}
              type="button"
              className="btn btn-orange ms-5"
            >

              Adicionar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

function ResizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#ff0071"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ position: 'absolute', right: 5, bottom: 5 }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="16 20 20 20 20 16" />
      <line x1="14" y1="14" x2="20" y2="20" />
      <polyline points="8 4 4 4 4 8" />
      <line x1="4" y1="4" x2="10" y2="10" />
    </svg>
  );
}

export default memo(NodeRaia);
