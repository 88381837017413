import { CustomEditor, ImageElement } from "../../../../../utils/types/slate";
import { editorApi } from "../../../services/api";
import { Node, Path } from 'slate'
import { CommonService } from "./commons";
import { Element } from "slate";

type Response = {
  payload: Buffer;
};

type ReplaceImageParams = {
  editor: CustomEditor
  imagePath: Path
  newImageUrl: string
}

export const ImageService = {
  async getImageBlobAsync(url: string) {
    try {
      const imageBuffer = await editorApi.post<Response>("/get-image-blob", {
        imageUrl: url,
      });
      return imageBuffer.data.payload;
    } catch (error) {
      return null;
    }
  },

  async replaceImage(params: ReplaceImageParams) {
    const { editor, imagePath, newImageUrl } = params

    const element = CommonService.elementByPath(editor, imagePath)

    if (element && Element.isElement(element) && element.type === "image") {
      const replacedElement: ImageElement = {
        ...element,
        url: newImageUrl
      }

      CommonService.removeElementByPath(editor, imagePath)
      CommonService.addElement(editor, replacedElement, imagePath)
    }
  },

  hasErrorInImageUrl(url: string): boolean {
    const urlRegex = new RegExp(/^https:\/\/editor-documento\.s3\.sa-east-1\.amazonaws\.com\//)

    if (url.match(urlRegex)) {
      return false
    }

    return true
  },

  verifyIfHasImageErrors(editor: CustomEditor): boolean {
    const nodes = Array.from(Node.descendants(editor))

    let hasError = false

    for (const node of nodes) {
      const [element] = node

      if (Element.isElement(element) && element.type === "image") {
        hasError = this.hasErrorInImageUrl(element.url)
      }
    }

    return hasError 
  }
};
