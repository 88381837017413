/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Draggable } from "react-beautiful-dnd";
import { MultiSelect } from "react-multi-select-component";
import { faLayerGroup, faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ECamposIDEnum, EModulo, ETelaEnum, returnarEnumDescricaoID } from "../../enum";
import { ETipoTelaEnum } from "../../enum/ETipoTelaEnum";
import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";

import { ICampo } from "../../interfaces/ICampo";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";

import XCampoService from "../../services/XCampoService";
import TipoDocumentoService from "../../services/TipoDocumentoService";
import LimitacaoTabelaService from "../../services/LimitacaoTabelaService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { DefinirTipoTela } from "../../utils/Utils";
import ListaSelected from "./ListaCamposSelectedField";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { notifySuccess } from "./Toast/ToastFormik";
import { camelizar } from "../../pages/ConfiguracaoTelaPage/Empresa/utils";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";

interface ItemProps {
  alterarItem(item: ICampo): void;
  item: ICampo;
  index: number;
  col: string;
}



const FormECampos: React.FC<ItemProps> = ({ item, index, alterarItem, col }) => {

  const { tela, campos, setCampos, eCampos } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoListaLimitadaCadastro, setCarregandoListaLimitadaCadastro] = useState<boolean>(false);
  const [adicionarListaLimitadaCadastro, setAdicionarListaLImitadaCadastro] = useState<boolean>(false);

  const [opcoes, setOpcoes] = useState<any[]>([]);
  const [opcoesSelected, setOpcoesSelected] = useState<any[]>([]);
  const [separadores, setSeparadores] = useState<ICampo[]>([]);

  const initialValues = {
    campo: item.campo,
    camposId: item.camposId,

    nome: "",
    tipo: item.tipo || item.campoTipo,
    modulo: item.modulo,
    entidade: item.campoNome?.toLowerCase(),
    campoNome: item.campoNome,
    ordenacao: item.ordenacao,

    camposCondicionadoId: item.camposCondicionadoId,
    campoCondicionadoEnable: item.campoCondicionadoEnable,
    campoCondicionadoOpcoes: item.campoCondicionadoOpcoes,


    fluxoFaseId: item.fluxoFaseId,
    tipoMarcaId: item.tipoMarcaId,
    separadorId: item.separadorId,
    tipoImovelId: item.tipoImovelId,
    areaDireitoId: item.areaDireitoId,
    tipoLicencaId: item.tipoLicencaId,
    tipoContratoId: item.tipoContratoId,
    tipoConsultaId: item.tipoConsultaId,
    tipoSociedadeId: item.tipoSociedadeId,
    tipoProcuracaoId: item.tipoProcuracaoId,

    entidadeCondicionada: "",
    label: item.label,
    help: item.help,
    obrigatorio: item.obrigatorio,
    somenteLeitura: item.somenteLeitura,
    Lista: [],
    isXCampo: false,
  } as ICampo;

  const todo = (draggableStyle: any) => ({
    padding: 10,
    margin: `0 50px 15px 40px`,
    background: "var(--primary-base)",
    color: "white",
    border: `1px solid`,
    fontSize: `12px`,
    borderRadius: `5px`,
    cursor: "pointer",
    ...draggableStyle,
  });

  const doing = (isDragging: boolean, draggableStyle: any) => ({
    padding: 10,
    margin: `0 50px 15px 40px`,
    background: isDragging ? "var(--primary-base)" : "white",
    color: "white",
    border: `1px solid`,
    fontSize: `12px`,
    borderRadius: `5px`,
    cursor: "pointer",
    ...draggableStyle,
  });



  const PopoverSeparador = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={
      <Popover id="popover-basic">
        <Popover.Body>Opção de organização que permite reunir campos relacionados ou similares em uma única seção visual. Essa funcionalidade é valiosa em interfaces de usuário para melhorar a usabilidade e a experiência do usuário, especialmente em telas com muitos campos ou informações.</Popover.Body>
      </Popover>
    }>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );


  const PopoverCondicionado = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={
      <Popover id="popover-basic">
        <Popover.Body>Um campo condicionado é um que só é exibido com base no valor de outro campo do tipo Lista.</Popover.Body>
      </Popover>
    }>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      alterarItem(values);

      campos.splice(values.ordenacao, 0, { ...values });

      LimitacaoTabelaService.adicionaOpcoes(values);

      formik.setValues(initialValues);

      setSubmitting(false);

      notifySuccess('Limitação de opções cadastrada com sucesso');
    },
  });

  useEffect(() => {

    if (exibirModal) {
      if (DefinirTipoTela(tela) === ETipoTelaEnum.Cadastro && formik.values.tipo === ETipoCampoEnum.Lista) {
        carregarListaLimitadaCadastroPorCampo(item.entidade);
      }

      if (formik.values.camposCondicionadoId) {
        formik.setFieldValue("camposCondicionadoId", formik.values.camposCondicionadoId, true);
        formik.setFieldValue("entidadeCondicionada", campos.find(e => e.campo == formik.values.camposCondicionadoId)?.campoNome);
      }

      carregarConfiguracaoTelaSeparadores();

      formik.setValues(initialValues);

    }

  }, [exibirModal]);

  useEffect(() => {
    if (campos?.length > 0) {
      let campo = campos?.find(e => e.campo == formik.values.camposCondicionadoId);

      formik.setFieldValue("entidadeCondicionada", campo?.campoNome?.toLocaleLowerCase());
    }
  }, [campos?.length]);

  useEffect(() => {

    setOpcoes([]);

    setOpcoesSelected([]);

    carregarListaLimitadaPorCondicionadas();

  }, [formik.values.entidadeCondicionada]);

  const verificarExisteListaSelecionada = (entidade: string) => {
    if (
      campos &&
      campos.filter((e) => e.entidade === entidade) &&
      campos.filter((e) => e.entidade === entidade)[0] &&
      (campos.filter((e) => e.entidade === entidade)[0].Lista || []).length > 0
    ) {
      formik.values.Lista = campos.filter((e) => e.entidade === entidade)[0].Lista;
      return true;
    }
    return false;
  };


  const carregarListaLimitadaCadastroPorCampo = async (entidade: string) => {
    try {
      if (verificarExisteListaSelecionada(entidade)) return;


      setCarregandoListaLimitadaCadastro(true);

      let resultado: IRecuperaLista<any>;

      if (entidade == 'tipoDocumentoMinuta') entidade = camelizar(ECamposIDEnum.TipoDocumento);

      if (entidade == camelizar(ECamposIDEnum.TipoDocumento)) {
        resultado = await TipoDocumentoService.obterListaLimitadaCadastro(
          {
            tipoMarcaId: formik.values.tipoMarcaId,
            fluxoFaseId: formik.values.fluxoFaseId,
            tipoImovelId: formik.values.tipoImovelId,
            tipoLicencaId: formik.values.tipoLicencaId,
            areaDireitoId: formik.values.areaDireitoId,
            tipoConsultaId: formik.values.tipoConsultaId,
            tipoContratoId: formik.values.tipoContratoId,
            tipoSociedadeId: formik.values.tipoSociedadeId,
            tipoProcuracaoId: formik.values.tipoProcuracaoId,
            tela: tela
          },
          (formik.values.modulo || EModulo.Default)
        );
      }
      else {
        resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastro(
          {
            tipoMarcaId: formik.values.tipoMarcaId,
            fluxoFaseId: formik.values.fluxoFaseId,
            tipoImovelId: formik.values.tipoImovelId,
            tipoLicencaId: formik.values.tipoLicencaId,
            areaDireitoId: formik.values.areaDireitoId,
            tipoConsultaId: formik.values.tipoConsultaId,
            tipoContratoId: formik.values.tipoContratoId,
            tipoSociedadeId: formik.values.tipoSociedadeId,
            tipoProcuracaoId: formik.values.tipoProcuracaoId,
            campo: formik.values.campo,
            tela: tela
          },
          entidade,
          (formik.values.modulo || EModulo.Default)
        );
      }

      formik.setFieldValue("Lista", resultado.data, true);
    }
    catch (error) {
      setCarregandoListaLimitadaCadastro(false);
    }
    finally {
      setCarregandoListaLimitadaCadastro(false);
    }
  }

  const carregarListaLimitadaPorCondicionadas = async () => {
    try {
      let resultado: IRecuperaLista<any>;

      if (formik.values.entidadeCondicionada && formik.values?.entidadeCondicionada?.length > 0) {

        let campo = campos?.find(e => e.campo == formik.values.camposCondicionadoId);

        if (campo?.isXCampo) {
          resultado = await XCampoService.obterCampo({
            campoId: formik.values.camposCondicionadoId,
            status: 1,
            offset: 0,
            limit: 100,
            sort: "CampoId",
            totalPaginas: 0,
          });
        } else {
          resultado = await ConfiguracaoTelaService.obterCampo({
            status: 1,
            limit: 500,
            totalItems: 0,
            offset: 0,
            sort: `nome`
          }, formik.values.entidadeCondicionada);
        }
        setOpcoes(returnOptionsAll(resultado.data, campo?.campoNome, campo?.isXCampo));
        setOpcoesSelected(returnOptionsSelected(resultado.data, campo?.campoNome, campo?.isXCampo));
      }

    } catch (error: any) {
      setOpcoes([]);
      setOpcoesSelected([]);
    }
  }

  const carregarConfiguracaoTelaSeparadores = async () => {

    try {
      let resultado: IRecuperaLista<any>;

      resultado = await ConfiguracaoSeparadorService.obterDoing({
        modulo: formik.values.modulo || EModulo.Default,
        areaDireitoId: formik.values.areaDireitoId,
        tipoContratoId: formik.values.tipoContratoId,
        tipoConsultaId: formik.values.tipoConsultaId,
        tipoImovelId: formik.values.tipoImovelId,
        tipoLicencaId: formik.values.tipoLicencaId,
        tipoMarcaId: formik.values.tipoMarcaId,
        tipoProcuracaoId: formik.values.tipoProcuracaoId,
        tipoSociedadeId: formik.values.tipoSociedadeId,
        fluxosFasesId: formik.values.fluxoFaseId,
        nome: "",
        telas: {
          capa: tela,
          cadastro: tela
        },
      }, ETelaEnum.TelaSeparadorCapa );

      setSeparadores(resultado.data);

    } catch (error: any) {
      setSeparadores([]);
    }
  }

  const returnOptionsSelected = (array: any, campoNome?: string, xCampo?: boolean) => {
    let lista: any[] = [];
    array.forEach((item: any) => {
      let existe = formik.values?.campoCondicionadoOpcoes?.find(e => e.opcaoId == item[returnarEnumDescricaoID(campoNome || "")]);
      if (existe) {
        lista.push({
          value: xCampo ? item.xCampoOpcaoId : existe.opcaoId,
          label: item.nome,
        });
      }
    });
    return lista;
  };

  const returnOptionsAll = (array: any, campoNome?: string, xCampo?: boolean) => {
    let lista: any[] = [];
    array.forEach((item: any) => {
      lista.push({
        value: xCampo ? item.xCampoOpcaoId : item[returnarEnumDescricaoID(campoNome || "")],
        label: item.nome,
      });
    });
    return lista;
  };

  const setListaCondicionada = (opcoes: any) => {
    let lista: any[] = [];
    opcoes.forEach((opcao: any) => {
      if (formik.values.campoCondicionadoOpcoes) {
        lista.push({ opcaoId: opcao.value });
      }
    });
    formik.setFieldValue("campoCondicionadoOpcoes", lista);
  }

  const preencherCampos = () => {
    if (DefinirTipoTela(tela) === ETipoTelaEnum.Cadastro) {
      item.entidade = eCampos.filter((e: any) => e.campo === item.campo)[0]?.entidade;
      item.tipo = eCampos.filter((e: any) => e.campo === item.campo)[0]?.tipo;
    }
    formik.setValues(item);
    setExibirModal(!exibirModal);
  };

  return (
    <>
      <Draggable key={item?.campo} draggableId={item?.campo?.toString()} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                onClick={() => preencherCampos()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={todo(provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && <>{item?.label}</>}
                {carregandoListaLimitadaCadastro && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                onClick={() => preencherCampos()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={doing(true, provided.draggableProps.style)}
                title={!item.separadorId && DefinirTipoTela(tela) == ETipoTelaEnum.Capa && 'Definir Separador'}
              >

                {!carregandoListaLimitadaCadastro && !adicionarListaLimitadaCadastro && (
                  <>
                    {formik.values?.label}{formik.values?.obrigatorio && <span style={{ color: 'red' }}>{" "}*</span>}{!formik.values.separadorId && DefinirTipoTela(tela) == ETipoTelaEnum.Capa && <FontAwesomeIcon color={"white"} className="mx-2" icon={faLayerGroup} />}
                  </>
                )}

                {(carregandoListaLimitadaCadastro || adicionarListaLimitadaCadastro) && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          )
        }
      </Draggable>

      <Modal size="lg" centered={true} show={exibirModal && !carregandoListaLimitadaCadastro} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title text-orange">Alterar Campo: {formik.values.campoNome} / Label: {formik.values.label}</h6>
            <div
              onClick={() => {
                formik.setValues(initialValues);
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Nome:
                  </label>
                  <input placeholder="Nome" {...formik.getFieldProps("label")} type="text" className={"form-control"}></input>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Texto de Ajuda:
                  </label>
                  <input placeholder={formik.values.help} {...formik.getFieldProps("help")} type="text" className={"form-control"}></input>
                </div>
              </div>



              {DefinirTipoTela(tela) === ETipoTelaEnum.Capa &&
                <>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                        Definir Separador: <PopoverSeparador />
                      </label>
                      <select
                        className={'form-select'}
                        {...formik.getFieldProps("separadorId")}
                        value={formik.values.separadorId || 0}
                        onChange={(event: any) => {
                          formik.setFieldValue("separadorId", parseInt(event.target.value), true);
                        }}
                      >
                        <option key={0} value={0}> Selecione</option>
                        {separadores.map((campo: ICampo) => {
                          return (
                            <option key={campo.separadorId} value={campo.separadorId || 0}>
                              {campo.label}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.separadorId && formik.errors.separadorId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.separadorId}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>}

              <div className="row mt-4">
                {DefinirTipoTela(tela) === ETipoTelaEnum.Cadastro && (
                  <div className="col-md-4">
                    <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                      É um campo obrigatório?{" "}
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        style={{ cursor: 'pointer' }}
                        checked={formik.values.obrigatorio}
                        onClick={() => {
                          if (formik.values.obrigatorio) formik.setValues({ ...formik.values, obrigatorio: false });
                          if (!formik.values.obrigatorio) formik.setValues({ ...formik.values, obrigatorio: true });
                        }}
                      />
                    </label>
                  </div>
                )}

                <div className="col-md-4">
                  <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                    É um campo somente leitura?{" "}
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={formik.values.somenteLeitura}
                      onChange={() => {
                        if (formik.values.somenteLeitura) formik.setValues({ ...formik.values, somenteLeitura: false });
                        if (!formik.values.somenteLeitura) formik.setValues({ ...formik.values, somenteLeitura: true });
                      }}
                    />
                  </label>
                </div>

                <div className="col-md-4">
                  <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                    É um campo condicionado?{" "} <PopoverCondicionado />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={formik.values.campoCondicionadoEnable}
                      onChange={() => {
                        if (formik.values.campoCondicionadoEnable) {
                          formik.setFieldValue("campoCondicionadoEnable", false);
                          formik.setFieldValue("camposCondicionadoId", 0);
                          formik.setFieldValue("campoCondicionadoOpcoes", []);
                        }
                        if (!formik.values.campoCondicionadoEnable) formik.setFieldValue("campoCondicionadoEnable", true);
                      }}
                    />
                  </label>
                </div>
              </div>

              {formik.values.campoCondicionadoEnable &&
                <>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                        Campo:
                      </label>
                      <select
                        className={'form-select'}
                        {...formik.getFieldProps("camposCondicionadoId")}
                        value={formik.values.camposCondicionadoId}
                        onChange={(event: any) => {
                          formik.setFieldValue("camposCondicionadoId", parseInt(event.target.value), true);

                          if (parseInt(event.target.value)) {
                            formik.setFieldValue("entidadeCondicionada", event.target[event.target.selectedIndex]?.text || null);
                          }

                        }}
                      >
                        <option key={0} value={0}> Selecione</option>
                        {campos.filter(e => (e.campoTipo == ETipoCampoEnum.Lista || e.tipo == ETipoCampoEnum.Lista) && e.campo != formik.values.campo).map((campo: ICampo) => {
                          return (
                            <option key={campo.campo} value={campo.campo}>
                              {campo.campoNome}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Opções:
                      </label>
                      <MultiSelect
                        isLoading={false}
                        disabled={false}
                        options={opcoes}
                        value={opcoesSelected}
                        onChange={(event: any) => {
                          setOpcoesSelected(event);
                          setListaCondicionada(event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>
                </>
              }

              <div className="row mt-4">
                {DefinirTipoTela(tela) === ETipoTelaEnum.Cadastro && formik.values.tipo === ETipoCampoEnum.Lista && (
                  <>
                    <ListaSelected
                      ListaSelected={formik.values.Lista || []}
                      entidade={formik.values.campoNome}
                      setFieldValue={formik.setFieldValue}
                    ></ListaSelected>
                  </>
                )}
              </div>
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            {formik.values.tipo === ETipoCampoEnum.Lista && DefinirTipoTela(tela) == ETipoTelaEnum.Cadastro ? (
              <button
                disabled={formik.values.Lista?.length === 0}
                onClick={() => {
                  setAdicionarListaLImitadaCadastro(true);
                  setCampos(campos.filter((e) => e.campo !== formik.values.campo));
                  formik.submitForm();
                  setExibirModal(!exibirModal);
                  setAdicionarListaLImitadaCadastro(false);
                }}
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            ) : (
              <button
                onClick={() => {
                  alterarItem(formik.values);
                  setExibirModal(!exibirModal);
                }}
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            )}

            <button
              onClick={() => {
                setExibirModal(!exibirModal);
                formik.setValues(initialValues);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormECampos;
