import { Descendant } from "slate";
import { jsx } from "slate-hyperscript";
import { CustomElement, CustomEditor } from "../../../../../utils/types/slate";
import { CommonService } from "./commons";
import { v4 } from "uuid";

type HtmlTag = {
  [key: string]: (style?: React.CSSProperties, customAttr?: any) => any;
};

export const HtmlService = {
  htmlTags: {
    table: (style: React.CSSProperties) => ({ type: "table", style }),
    tr: (style: React.CSSProperties) => ({ type: "table-row", style }),
    td: (style: React.CSSProperties, cellAttributes: any) => ({ type: "table-cell", style, cellAttributes }),
    th: (style: React.CSSProperties, cellAttributes: any) => ({ type: "table-head", style, cellAttributes }),
    h2: (style: React.CSSProperties) => ({ type: "heading-two", style }),
    body: (style: React.CSSProperties, inlineCssStyle: any) => ({ id: v4(), type: "fragment", style, inlineCssStyle }),
  } as HtmlTag,

  deserialize(
    element: HTMLElement,
    head: HTMLHeadElement
  ): CustomElement | string | Descendant[] | null {
    const styleElement = head.querySelector("style")?.textContent;
    const elementName = element.nodeName.toLowerCase();

    if (element.nodeType === 3) {
      return [{ text: element.textContent?.trim() ?? "" }]
    }

    const children = Array.from(element.childNodes)
      .map((el) => this.deserialize(el as HTMLElement, head))
      .flat()
      .filter((c: any) => c?.text !== "");

    if (!children.length) {
      return [{ text: "" }];
    }

    if (this.htmlTags[elementName]) {
      const colSpan = element.getAttribute('colSpan')
      const rowSpan = element.getAttribute('rowSpan')
      
      const cellAttributes = {
        colSpan: colSpan ?? 1,
        rowSpan: rowSpan ?? 1,
      }

      const style: React.CSSProperties = {
        width: element.style?.width
      }

      const customAttrOptions: {[key: string]: any} = {
        body: styleElement,
        td: cellAttributes,
        th: cellAttributes
      }

      const customElement = this.htmlTags[elementName](style, customAttrOptions[elementName]);
      return jsx("element", customElement, children);
    }

    return children as Descendant[];
  },

  loadHtmlContent(editor: CustomEditor, htmlData: string) {
    const { selection } = editor

    if (!selection) { return }

    const parsedString = new DOMParser().parseFromString(htmlData, "text/html");
    const element = parsedString.body;
    const head = parsedString.head;
    const content = this.deserialize(element, head) as Descendant[];
    const path = selection.anchor.path
    CommonService.addElement(editor, content, path);

    const newPath = [...path]
    newPath[newPath.length - 1] += 1
    CommonService.addElement(editor, CommonService.makeParagraph(), newPath)
  },

  isValidHTML(htmlString: string): boolean {
    const regex = /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i
    return regex.test(htmlString)
  }
};
