/* eslint-disable @typescript-eslint/no-empty-function */
import { FormikProps } from "formik";

import { Modal } from "react-bootstrap";

import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

interface AreaTextoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  formik: FormikProps<any>;
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
}

const AreaTextoEdit = ({
  toggleModal,
  exibirModal,
  formik,
  configuracaoTela,
}: AreaTextoFieldProps) => {


  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            {configuracaoTela.label} <FontAwesomeIcon className="mx-2 text-orange" icon={!configuracaoTela.alterarCampo ? faSearch : faEdit} />
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">

          {!configuracaoTela.alterarCampo ?
            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{formik.values[`${configuracaoTela?.campoNome.toLowerCase()}`]}</p>
            :
            <div className="row">
              <textarea
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                maxLength={10000}
                disabled={!configuracaoTela.alterarCampo}
                rows={10}
                className={clsx("form-control", {
                  "border border-danger": configuracaoTela.obrigatorio,

                })}
                id={`form-${configuracaoTela?.campoNome}`}
              />
            </div>}

        </div>

        {configuracaoTela.alterarCampo &&
          <div className="modal-footer" style={{ margin: "0 auto" }}>

            <button onClick={() => toggleModal()} type="button" className="btn btn-orange ms-5">
              Adicionar
            </button>

            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>

          </div>}
      </div>
    </Modal>
  );
};

export default AreaTextoEdit;