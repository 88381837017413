import api from "./Api";
import IFluxoFase from "../interfaces/IFluxo";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaFluxoFaseRequest from "../interfaces/Requests/FluxoFase/FiltroPesquisaFluxoFaseRequest";
import { IAutomacao } from "../interfaces/IAutomacao";

class ConfiguracaoFluxoFaseService {

    async obterDoing(fluxosFasesId: number): Promise<IRecuperaLista<IAutomacao>> {

        let { data: result } = await api.get<IRecuperaLista<IAutomacao>>(`Fluxo/RecuperaFluxoFaseAutomacao?fluxosFasesId=${fluxosFasesId}`);

        return result;
    }

    async obterTodo(): Promise<IRecuperaLista<IAutomacao>> {

        let { data: result } = await api.get<IRecuperaLista<IAutomacao>>(`Automacao/RecuperaAutomacao`);

        return result;
    }

    async adicionaAutomacao(automacao: IAutomacao) {

        let { data: result } = await api.post(`Fluxo/AdicionaAutomacao`, automacao);

        return result;
    }

    async removerAutomacao(fluxosFasesAutomacaoId: number) {

        let { data: result } = await api.delete(`Fluxo/RemoveFluxoFaseAutomacao/${fluxosFasesAutomacaoId}`);

        return result;
    }

    async obterFluxoFase(filtros: FiltroPesquisaFluxoFaseRequest): Promise<IRecuperaLista<IFluxoFase>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFluxoFase>>(`FluxoFase/RecuperaFases?${query}`);

        return result;
    }

    async obterFluxoFasePorSigla(uf: string): Promise<IRecuperaLista<IFluxoFase>> {

        let { data: result } = await api.get<IRecuperaLista<IFluxoFase>>(`FluxoFase/RecuperaFluxoFase?status=-1&status=1&offset=0&limit=100&UF=${uf}`);

        return result;
    }

    async adicionaFluxoFase(adicionaFluxoFase: IFluxoFase): Promise<IFluxoFase> {
        let { data: result } = await api.post<IFluxoFase>(`FluxoFase/AdicionaFluxoFase`, adicionaFluxoFase);

        return result;
    }

    async alterarStatus(estadoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IFluxoFase>(`FluxoFase/AtualizaFluxoFasePorId?id=${estadoId}`, data);
    }

    async atualizarFluxoFase(editarFluxoFase: IFluxoFase): Promise<void> {

        let dataFluxoFase = [
            { "op": "replace", "path": "/nome", "value": editarFluxoFase.nome },
        ]


        await Promise.all([
            await api.patch<void>(`FluxoFase/AtualizaFluxoFasePorId?id=${editarFluxoFase.fluxoId}`, dataFluxoFase)
        ]);
    }
}


export default new ConfiguracaoFluxoFaseService();