import styled, { css } from "styled-components";

export const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0,0,0, 0.09);
  cursor: pointer !important;

  ${props => props.isDragging && css`
    border: 2px dashed #E5E5E6;
    opacity: 0.9;
  `};

  &:hover {
    opacity: 0.8;
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #2E3334;
  width: 100%;
  word-break: break-word; 
  overflow-wrap: break-word;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 14px;
    font-weight: 200;
    color: #2E3334;
  }
`;