import { useContext, useEffect, useRef, useState } from "react";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IPropostaAcordo from "../../interfaces/IPropostaAcordo";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaPropostaAcordoRequest from "../../interfaces/Requests/PropostaAcordo/FiltroPesquisaPropostaAcordoRequest";

import PropostaAcordoService from "../../services/PropostaAcordoService";

import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { EModulo } from "../../enum";
import { MultiSelect } from "react-multi-select-component";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const PropostaAcordoPage = () => {
  const { setPropostaAcordo, propostaAcordo } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [carregandoPropostasAcordo, setCarregandoPropostasAcordo] = useState(false);
  const [propostasAcordo, setPropostasAcordo] = useState<IPropostaAcordo[]>([]);
  const [atualizaTabela, setAtualizaTabela] = useState<boolean>(false);

  const [carregamentoStatus, setCarregamentoStatus] = useState<boolean>(false);
  const [StatusOptions, setStatusOptions] = useState<any>([]);
  const [StatusSelected, setStatusSelected] = useState([]);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaPropostaAcordoRequest>({
    propostaAcordoId: 0,
    modulo: propostaAcordo.modulo,
    processoId: propostaAcordo?.processoId,
    imovelId: propostaAcordo?.imovelId,
    statusPropostaAcordoId: [],
    validadePropostaMaiorIgual: "",
    validadePropostaMenorIgual: "",
    valorAcordoMaiorIgual: "",
    valorAcordoMenorIgual: "",
    descricao: "",
    dataPagamentoMaiorIgual: "",
    dataPagamentoMenorIgual: "",
    multaAtraso: true,
    valorMultaAtrasoMaiorIgual: "",
    valorMultaAtrasoMenorIgual: "",
    periodicidadeId: 0,
    codigo: "",
    clienteId: 0,
    usuarioIdCadastro: 0,
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    statusPropostaAcordoNome: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "propostaAcordoId",
  });

  const navigate = useNavigate();

  useEffect(() => {

    carregarStatus();

  }, []);

  useEffect(() => {
    if (atualizaTabela) {
      setTimeout(() => {
        carregarPropostasAcordo(filtrosPesquisa);
      }, 2000);
    }
  }, [atualizaTabela]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusPropostaAcordoId: IdsSelected };
    });
  }, [StatusSelected]);

  const carregarPropostasAcordo = async (filtro: FiltroPesquisaPropostaAcordoRequest): Promise<void> => {
    try {
      setCarregandoPropostasAcordo(true);
      let resultado: IRecuperaLista<IPropostaAcordo>;

      resultado = await PropostaAcordoService.obterPropostasAcordo(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setPropostasAcordo(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setPropostasAcordo([]);
    } finally {
      setCarregandoPropostasAcordo(false);
      setAtualizaTabela(false);
    }
  }

  const returnOptionsStatusImovel = (array: any) => {
    array.forEach((item: any) => {
      StatusOptions.push({
        value: item.statusPropostaAcordoId,
        label: item.nome,
      });
    });
    return setStatusOptions;
  };

  const carregarStatus = async (): Promise<void> => {
    try {
      setCarregamentoStatus(true);

      let resultado: IRecuperaLista<any>;
      resultado = await PropostaAcordoService.obterPropostasAcordoStatus({
        codigo: "",
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusPropostaAcordoId",
      });

      setStatusOptions(returnOptionsStatusImovel(resultado.data));
      setCarregamentoStatus(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregamentoStatus(false);
    }
  }

  const colunas: TableColumn<IPropostaAcordo>[] = [
    {
      name: "Id",
      sortField: "PropostaAcordoId",
      selector: (row: IPropostaAcordo) => row.propostaAcordoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IPropostaAcordo) => row.statusPropostaAcordoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Validade",
      sortField: "validadeProposta",
      selector: (row: IPropostaAcordo) => {
        if (row.validadeProposta === null) return "-";
        const data = row.validadeProposta.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Acordo",
      sortField: "valorAcordo",
      selector: (row: IPropostaAcordo) => {
        if (row.valorAcordo === null) return "R$ 0,00";

        const valor = row.valorAcordo || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Multa por Atraso",
      sortField: "multaAtraso",
      selector: (row: IPropostaAcordo) => (row.multaAtraso ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Multa",
      sortField: "valorMultaAtraso",
      selector: (row: IPropostaAcordo) => {
        if (row.valorMultaAtraso === null) return "R$ 0,00";

        const valor = row.valorMultaAtraso || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (proposta: IPropostaAcordo) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Editar Despesa"
              onClick={() => alterarEdicao(proposta)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const alterarEdicao = (row: IPropostaAcordo) => {
    setPropostaAcordo({
      ...row,
      propostaAcordoId: row.propostaAcordoId,
      tipoPropostaAcordoId: row.tipoPropostaAcordoId,
      categoriaId: row.tipoPropostaAcordoId,

      modulo: propostaAcordo.modulo,

      processoId: propostaAcordo?.processoId,
      areaDireitoId: propostaAcordo.areaDireitoId,

      tipoImovelId: propostaAcordo.tipoImovelId,
      imovelId: propostaAcordo?.imovelId
    });
    navigate("/PropostaAcordo/Capa");
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IPropostaAcordo>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        propostaAcordoId: 0,
        statusPropostaAcordoId: [],
        validadePropostaMaiorIgual: "",
        validadePropostaMenorIgual: "",
        valorAcordoMaiorIgual: "",
        valorAcordoMenorIgual: "",
        descricao: "",
        dataPagamentoMaiorIgual: "",
        dataPagamentoMenorIgual: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        multaAtraso: true,
        valorMultaAtrasoMaiorIgual: "",
        valorMultaAtrasoMenorIgual: "",
        periodicidadeId: 0,
        codigo: "",
        clienteId: 0,
        usuarioIdCadastro: 0,
        status: 0,
      };
    });
  }

  return (
    <>
      <LayoutPageForButton title="Proposta">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={
          propostaAcordo.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}>

          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>

        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setPropostaAcordo({ ...propostaAcordo, propostaAcordoId: 0 });
            navigate("/PropostaAcordo/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>


      <div className="row mt-2">
        <div className="col-md-12">

        <CollapseFiltro titulo="Filtros" content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarPropostasAcordo(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-propostaAcordo"
          >
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data do Cadastro:{" "}
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: "",
                        dataCadastroMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-validadeProposta" className="form-label fw-bolder text-orange">
                Validade da Proposta:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.validadePropostaMaiorIgual && filtrosPesquisa.validadePropostaMenorIgual
                    ? [filtrosPesquisa.validadePropostaMaiorIgual, filtrosPesquisa.validadePropostaMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        validadePropostaMaiorIgual: "",
                        validadePropostaMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      validadePropostaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      validadePropostaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-dataPagamento" className="form-label fw-bolder text-orange">
                Data do Pagamento:{" "}
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataPagamentoMaiorIgual && filtrosPesquisa.dataPagamentoMenorIgual
                    ? [filtrosPesquisa.dataPagamentoMaiorIgual, filtrosPesquisa.dataPagamentoMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataPagamentoMaiorIgual: "",
                        dataPagamentoMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataPagamentoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataPagamentoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                isLoading={carregamentoStatus}
                disabled={carregamentoStatus}
                options={StatusOptions}
                value={StatusSelected}
                onChange={(event: any) => setStatusSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}
              />
            </div>
            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
            </div>
          </form>}/>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoPropostasAcordo}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="PropostasAcordo"
            itens={propostasAcordo}
          />
        </div>
      </div>
    </>
  );
};

export default PropostaAcordoPage;
