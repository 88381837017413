import { useContext, useEffect, useState } from "react";
import { faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";

import AudienciaService from "../../../services/AudienciaService";
import { EModulo, ETelaEnum } from "../../../enum";
import { tipoPorModulo } from "../../../utils/Utils";
import { defaultSchema, setValuesXCadastroCampos } from "../../../utils/SchemasUtil";

import IOption from "../../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import Carregando from "../../Carregando";
import { notifyFormikDanger } from "../Toast/ToastFormik";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import Swal from "sweetalert2";
import { tipoAudienciaInicialValues } from "../../../contexts/InitialValuesContext";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import TipoAudienciaService from "../../../services/TipoAudienciaService";
import CamposCadastroDinamicos from "../ParteContraria/CamposCadastroParteContraria";
import LayoutPageForButton from "../../../layout/LayoutPageButton";

interface IFieldEditProps {
  toggleModal: () => void;
  exibirModal: boolean;
  setAtualizarTabela(atualizaTabela: boolean): void;
}

const AudienciadField = ({
  toggleModal,
  exibirModal,
  setAtualizarTabela
}: IFieldEditProps) => {
  const [carregandoTiposAudiencia, setCarregandoTiposAudiencia] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { audiencia, setAudiencia, setTipoAudiencia, tipoAudiencia, processo, contrato } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const initialValues: any = {
    audienciaId: 0,
    principalId: 0,
    tipoAudienciaId: 0,

    modulo: EModulo.Audiencia,
    tela: ETelaEnum.TelaCadastroAudiencia,
    categoriaId: audiencia?.categoriaId,

    processoId: processo?.processoId,
    areaDireitoId: processo?.areaDireitoId,

    contratoId: contrato?.contratoId,
    tipoContratoId: contrato?.tipoContratoId,

    codigo: "",
    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Audiência",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro da audiência..",
            showConfirmButton: false,
          });
          Swal.showLoading();
          setAdicionarLoading(true);
          let resultado = await AudienciaService.adicionaAudiencia({
            ...values,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });
          Swal.hideLoading();
          setAudiencia({
            ...resultado,

            audienciaId: resultado.audienciaId,
            categoriaId: resultado.tipoAudienciaId,
            tipoAudienciaId: resultado.tipoAudienciaId,

            modulo: audiencia?.modulo,
            processoId: audiencia?.processoId,
            areaDireitoId: audiencia?.areaDireitoId,

            contratoId: audiencia?.contratoId,
            tipoContratoId: audiencia?.tipoContratoId,

          });

          setTipoAudiencia(tipoAudiencia);
          setConfiguracoesTela([]);
          setAtualizarTabela(true);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Audiência cadastrada com sucesso`,
            timer: 4000,
          });
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setTipoAudiencia(tipoAudienciaInicialValues);

    carregarTipoAudiencia();

    setConfiguracoesTela([]);

    formik.setValues(initialValues);
  }, []);

  useEffect(() => {
    if (tipoAudiencia.tipoAudienciaId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoAudiencia(tipoAudienciaInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoAudiencia.tipoAudienciaId]);



  const carregarTipoAudiencia = async () => {
    try {

      setCarregandoTiposAudiencia(true);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(audiencia?.modulo)}Id=${audiencia?.categoriaId}`);

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await TipoAudienciaService.obterListaLimitadaTipoAudiencia({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...maisFiltros()
      });

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));
      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoTiposAudiencia(false);
    }
  };


  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...maisFiltros(),
        tela: ETelaEnum.TelaCadastroAudiencia,
        tipoAudienciaId: tipoAudiencia.tipoAudienciaId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      console.log(error);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const maisFiltros = (): any => {
    let objeto: any = {};

    if (processo.processoId) {
      objeto.modulo = EModulo.AreaDireito;
      objeto.processoId = processo.processoId;
      objeto.areaDireitoId = processo.areaDireitoId;
    }

    if (contrato.contratoId) {
      objeto.modulo = EModulo.Contrato;
      objeto.contratoId = contrato.contratoId;
      objeto.tipoContratoId = contrato.tipoContratoId;
    }

    return objeto;
  }


  const onChangeTipoAudiencia = (value: number, texto: string) => {

    setConfiguracoesTela([]);

    formik.setFieldValue("tipoAudienciaId", value);
    formik.setFieldValue("categoriaId", value);

    setTipoAudiencia({
      tipoAudienciaId: value,
      nome: texto,
      opcaoNome: "",
      codigo: "",
      status: "",
      opcaoId: 0,
      categoriaNome: "",
      moduloNome: "",
      categoriaId: 0,
      checked: false,
      modulo: EModulo.Default
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <ToastContainer />
          <div className="modal-header">
            <LayoutPageForButton title={`Nova Audiência ${tipoAudiencia.tipoAudienciaId > 0 ? " - " + tipoAudiencia.nome : ""}`} />
          </div>
          <div className="modal-body">
            <div className="row mt-2">
              {carregandoTiposAudiencia ? (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando tipo de audiencias...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <div className="col-md-3 mt-3">
                  <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                    Tipo de Audiência:
                  </label>
                  <Select
                    value={{
                      label: options.find((e) => e.value === formik.values.tipoAudienciaId)?.label,
                      value: formik.values.tipoAudienciaId,
                    }}
                    onChange={(option: any) => onChangeTipoAudiencia(option.value, option.label)}
                    options={options}
                  />
                </div>
              )}
            </div>
            <hr></hr>
            {carregandoConfiguracoesTela ? <Carregando /> : <>{renderCampos()}</>}
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoAudienciaId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}
            >
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AudienciadField;
