import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { BsTrash, BsCloudArrowDown, BsEye, BsCloudArrowUp } from "react-icons/bs";

import DocumentoService from "../../../services/DocumentoService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import { ECamposIDEnum, EModulo } from "../../../enum";

import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { OrangeContext } from "../../../contexts/OrangeProvider";

import IOption from "../../../interfaces/IOption";
import IDocumento from "../../../interfaces/IDocumento";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import FiltroPesquisaDocumentoRequest from "../../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";

import GridPadrao from "../../Comum/GridPadrao";
import IFileUploadField from "./FileUploadParteContraria";
import PreviewPDFField from "../../Comum/PreviewPDF/PreviewPDF";

interface IFileCheckListCapaFielProps {
  formik: any;
  somenteLeitura?: boolean;
  configuracoesTela: IRecuperaConfiguracaoTelaResponse[];
  setConfiguracoesTela(configuracoesTela: IRecuperaConfiguracaoTelaResponse[]): void;
}

const FileListCapaParteContrariaField = ({ configuracoesTela, setConfiguracoesTela, somenteLeitura, formik }: IFileCheckListCapaFielProps) => {

  const { parteContraria } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [documentos, setDocumentos] = useState<any>([]);

  const [carregamentoOptions, setCarregamentoOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [optionsSelected, setOptionsSelected] = useState([]);

  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);

  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    modulo: [EModulo.ParteContraria],
    documentoId: [],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [exibirListaArquivosImportados, setExibirListaArquivosImportados] = useState<boolean>(false);
  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [exibirComponenteParaImportarArquivo, setExibirComponenteParaImportarArquivo] = useState<boolean>(false);
  const [atualizaTabelaDocumento, setAtualizaTabelaDocumento] = useState<boolean>(false);

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const togglerComponenteParaImportarArquivo = () => setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);


  useEffect(() => {
    const IdsSelected: number[] = [];
    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisaArquivos((oldState) => {
      return { ...oldState, tipoDocumentoId: IdsSelected };
    });
  }, [optionsSelected]);


  useEffect(() => {
    if (exibirListaArquivosImportados) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
  }, [filtrosPesquisaArquivos.offset, filtrosPesquisaArquivos.limit, filtrosPesquisaArquivos.sort]);

  useEffect(() => {
    if (atualizaTabelaDocumento || exibirListaArquivosImportados) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
  }, [atualizaTabelaDocumento, exibirListaArquivosImportados]);


  useEffect(() => { if (exibirListaArquivosImportados) carregaTipoDocumento(); }, []);

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {
      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos({
        ...filtro,
        modulo: [EModulo.ParteContraria],
        parteContrariaId: parteContraria.parteContrariaId,
        status: 1
      });

      setDocumentos(resultado.data);

      setFiltrosPesquisaArquivos((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoDocumentos(false);
    } catch (error: any) {
      setDocumentos([]);
      setCarregandoDocumentos(false);
    } finally {
      setAtualizaTabelaDocumento(false);
    }
  };

  const verificaIsPdf = (extensao: string) => {
    if (extensao.endsWith(".pdf")) return true;
    else return false;
  };

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const previewArquivo = async (documentoId: number) => {
    try {

      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      togglePDFDocumento();

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const excluirDocumento = async (documentoId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Deletar documento",
        text: `Você realmente deseja deletar este documento?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await DocumentoService.inativaDocumento(documentoId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Documento deletado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        carregarDocumentos(filtrosPesquisaArquivos);
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel deletar o documento`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };

  const colunas: TableColumn<any>[] = [
    {
      name: "ID",
      sortField: "documentoId",
      selector: (row: any) => row.documentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo de Documento",
      sortField: "tipoDocumentoNome",
      selector: (row: any) => row.tipoDocumentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: any) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Restrito?",
      sortField: "restrito",
      selector: (row: any) => (row.restrito ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Usuário",
      sortField: "usuarioNomeCadastro",
      selector: (row: any) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "  ",
      selector: (row: any) => moment(row.dataCadastro).format("DD/MM/yyyy"),
      sortable: true,
      wrap: true,
    },
    ...(!somenteLeitura
      ? [
        {
          name: "",
          cell: (row: IDocumento) => {
            return (
              <div>

                <BsTrash
                  title="Deletar Arquivo"
                  onClick={() => excluirDocumento(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="20px"
                  className="mx-1 text-orange"
                />

                <BsCloudArrowDown
                  title="Baixar Arquivo"
                  onClick={() => baixarArquivo(row.documentoId, row.nome)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="20px"
                  className="mx-1 text-orange"
                />
                <>
                  {verificaIsPdf(row.extensao) && (
                    <BsEye
                      title="Preview"
                      onClick={() => previewArquivo(row.documentoId)}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="20px"
                      className="mx-1 text-orange"
                    />
                  )}
                </>
              </div>
            );
          },
        }
      ]
      : []),
  ];

  const handlePerRowsChangeArquivo = async (currentRowsPerPage: number) => setFiltrosPesquisaArquivos((oldState: any) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChangeArquivo = (page: number) => setFiltrosPesquisaArquivos((oldState: any) => { return { ...oldState, offset: (page - 1) * filtrosPesquisaArquivos.limit }; });

  const handleSortArquivo = async (column: TableColumn<any>, sortDirection: string) => setFiltrosPesquisaArquivos((oldState: any) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  const onChangeTipoDocumento = () => {
    if (configuracoesTela.filter((e: { campo: number }) => e.campo == 58).length > 0) {
      const tipoDocumento = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo == 58)[0];

      const configuracoesTelaFilter = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo !== 58);

      configuracoesTelaFilter.push({ ...tipoDocumento, alterarCampo: true });

      setConfiguracoesTela(configuracoesTelaFilter);
    }
    if (exibirComponenteParaImportarArquivo) carregarDocumentos(filtrosPesquisaArquivos);
  };

  const carregaTipoDocumento = async () => {
    try {

      setCarregamentoOptions(true);

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.ParteContraria, parteContraria.tipoParteContrariaId, parteContraria.parteContrariaId, 0, 58);

      resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));

      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregamentoOptions(false);
    }
  };


  return (
    exibirComponenteParaImportarArquivo ? (
      <>
        <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />
        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Clique aqui
              <FontAwesomeIcon
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                icon={faBackward}
              /> para salvar
            </label>
            <br />
            <>
              <Button
                onClick={() => setExibirModalImportacaoArquivos(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                Selecione os arquivos
              </Button>
              <IFileUploadField
                exibirModal={exibirModalImportacaoArquivos}
                toggleModal={toggleImportacaoArquivos}
                principal={formik}
                campo={58}
              />
            </>
          </div>
        </div>

        <div className="row mt-2">
          <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
            {formik.values.quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
            {formik.values.quantidadeArquivosSelecionados === 1 && `${formik.values.quantidadeArquivosSelecionados} arquivos selecionado`}
            {formik.values.quantidadeArquivosSelecionados > 1 && `${formik.values.quantidadeArquivosSelecionados} arquivos selecionados`}
          </a>
        </div>

      </>
    ) : (
      <>
        <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

        <div className="row mt-2">
          <div className="col-md-12">
            <label
              onClick={() => setExibirListaArquivosImportados(!exibirListaArquivosImportados)}
              htmlFor="form-Documentos"
              style={{ cursor: "pointer" }}
              className="form-label fw-bolder text-orange"
            >
              Documentos {exibirListaArquivosImportados ? "-" : "+"}

              {!somenteLeitura &&
                <BsCloudArrowUp
                  onClick={() => {
                    onChangeTipoDocumento();
                    togglerComponenteParaImportarArquivo();
                  }}
                  color={"var(--primary-base2)"}
                  className="mx-3"
                  style={{ cursor: "pointer" }}
                  size={"30px"}
                  title={"Realizar upload de documento"}
                />
              }

            </label>

            {exibirListaArquivosImportados && (
              <>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <MultiSelect
                      isLoading={carregamentoOptions}
                      disabled={carregamentoOptions}
                      options={options}
                      value={optionsSelected}
                      onChange={(event: any) => setOptionsSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione o tipo...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <input
                      value={filtrosPesquisaArquivos.nome}
                      onChange={(e) => {
                        setFiltrosPesquisaArquivos((oldState: any) => {
                          return { ...oldState, nome: e.target.value };
                        });
                      }}
                      placeholder="Descrição"
                      type="text"
                      className={clsx("form-control")} />
                  </div>

                  <div className="col-4">
                    <button onClick={() => carregarDocumentos({ ...filtrosPesquisaArquivos })} className="btn btn-orange">
                      {<> Pesquisar </>}
                    </button>
                  </div>
                </div>

                <GridPadrao
                  progressPending={carregandoDocumentos}
                  onSort={handleSortArquivo}
                  limit={filtrosPesquisaArquivos.limit}
                  onChangePage={handlePageChangeArquivo}
                  onChangeRowsPerPage={handlePerRowsChangeArquivo}
                  paginationServer={true}
                  paginationTotalRows={filtrosPesquisaArquivos.totalItems}
                  colunas={colunas}
                  tipo="Documentos"
                  itens={documentos} /></>
            )}
          </div>
        </div>
        <hr />
      </>)
  );
};

export default FileListCapaParteContrariaField;
