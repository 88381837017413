import { NodeEntry, BaseRange, Editor, Text, Range } from "slate";
import { CommentThread } from "../types";
import { CustomEditor } from "../../../utils/types/slate";

export function useDecorate() {
  function comment(
    editor: CustomEditor,
    entrypoint: NodeEntry,
    thread: CommentThread | null
  ): BaseRange[] {
    const [node, path] = entrypoint;

    if (Text.isText(node) && thread?.path) {
      const intersection = Range.intersection(
        JSON.parse(thread.path),
        Editor.range(editor, path)
      );

      if (intersection == null) {
        return [];
      }

      const range = {
        marks: { bold: true, highlight: "var(--primary-base)" },
        ...intersection,
      };

      return [range];
    }

    return [];
  }

  return { comment }
}
