import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

interface ICollapseFiltroProps {
  titulo: string;
  content: any;
  open?: boolean
}

const CollapseFiltro = ({ content, titulo, open = true }: ICollapseFiltroProps) => {

  const [show, setShow] = useState<boolean>(open);

  return (
    <>
      <div
        onClick={() => setShow(!show)}
        className="col-md-12 mt-2">
        <label
          data-bs-toggle="collapse" data-bs-target="#collapse"
          style={{ cursor: "pointer" }}
          htmlFor="form"
          className="form-label fw-bolder text-orange"
          onClick={() => setShow(!show)}
        >
          <h6>{titulo} <a onClick={() => setShow(!show)} data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false">
            <FontAwesomeIcon color={"var(--primary-base2)"} onClick={() => setShow(!show)} className="mx-2" icon={show ? faPlus : faMinus} title="+ Filtros" />
          </a></h6>
        </label>
      </div>

      <div style={{ paddingBottom: '80px' }} className={`collapse row ${open && 'show'}`} id="collapse">
        {content}
      </div>
    </>
  );
};

export default CollapseFiltro;
