import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import AdvogadoInternoAcessosService from "../../../../../services/AdvogadoInternoAcessosService";
import Select from "react-select";
import { useEffect, useState } from "react";
import { optionsType } from "../../../../../components/Comum/Fornecedor/FornecedorField";
import UsuarioService from "../../../../../services/UsuarioService";

interface IAdvogadoInternoAddModal {
  exibirModal: boolean;
  handleClose: () => void;
}

const AdvogadoInternoAddModal = ({
  exibirModal,
  handleClose,
}: IAdvogadoInternoAddModal) => {
  const [usuario, setUsuario] = useState<optionsType>({
    label: "",
    value: 0,
  });
  const [usuarios, setUsuarios] = useState<optionsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const validarFormPermissoes = Yup.object().shape({
    usuarioId: Yup.string().required("O usuário é obrigatório."),
    codigo: Yup.string().max(50, "O máximo é de 50 caracteres."),
  });

  const formik = useFormik({
    initialValues: {
      usuarioId: "",
    },
    enableReinitialize: true,
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        await AdvogadoInternoAcessosService.adicionarAdvogadoInterno(
          values.usuarioId
        );

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Advogado cadastrado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível cadastrar o advogado`,
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          showConfirmButton: true,
        });
      } finally {
        resetForm();
        setUsuario({
          label: "",
          value: 0,
        });
        handleClose();
      }
    },
  });

  const carregarUsuarios = async () => {
    try {
      setLoading(true);
      const response = await UsuarioService.obterUsuarios({
        clientes: [],
        numeroDocumento: "",
        email: "",
        nomeUsuario: "",
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: "clienteId",
      });

      const mappedUsuarios = response.data.map((usuario) => {
        return {
          label: usuario.nome,
          value: usuario.usuarioId,
        };
      });

      setUsuarios(mappedUsuarios);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos usuários",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    carregarUsuarios();
  }, []);

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Cadastrar Advogado</h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Usuário:
              </label>
              <Select
                isLoading={loading}
                placeholder="Selecione um usuário"
                id="form-select"
                options={usuarios}
                isSearchable
                value={usuario}
                onChange={(select) => {
                  if (select) {
                    setUsuario(select);
                    formik.setFieldValue("usuarioId", select.value);
                  }
                }}
              />
              {formik.touched.usuarioId && formik.errors.usuarioId && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.usuarioId}</span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdvogadoInternoAddModal;
