import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  primaryBase: "#ec9453",
  primaryBase2: "orange",
  primaryBaseLight1: "#f7ae7a",
  primaryBaseLight2: "rgb(236, 148, 83)",
  primaryBaseLight3: "#be713a",
  primaryBaseDark1: "#a35e2e",
  primaryBaseDark2: "#d86f3e",
  primaryBaseDark3: "#d7874c",
  secondaryBase: "#6e3d90",
};

export const darkTheme: DefaultTheme = {
  primaryBase: "#4b4b4b", 
  primaryBase2: "#3d3d3d", 
  primaryBaseLight1: "#5f5f5f",
  primaryBaseLight2: "#6f6f6f",
  primaryBaseLight3: "#7f7f7f", 
  primaryBaseDark1: "#2f2f2f", 
  primaryBaseDark2: "#1f1f1f", 
  primaryBaseDark3: "#101010", 
  secondaryBase: "#2e2e2e", 
};
