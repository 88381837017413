import {
  faCloudUploadAlt,
  faEraser,
  faFileExcel,
  faPlus,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import GridPadrao from "../../../../../components/Comum/GridPadrao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { TableColumn } from "react-data-table-component";
import { useContext, useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";


import { showErrorMessage } from "../../../utils";
import {
  EModulo,
} from "../../../../../enum";

import ITipoAto from "../../../../../interfaces/ITipoAto";

import EmpresaService from "../../../../../services/EmpresaService";
import TipoAtoService from "../../../../../services/TipoAtoService";
import AtoSocietarioService from "../../../../../services/AtoSocietarioService";

import IRecuperaLista from "../../../../../interfaces/IRecuperaLista";
import { IAtoSocietario } from "../../../../../interfaces/AtoSocietario";
import { OrangeInterfaceContext } from "../../../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaEmpresaAto from "../../../../../interfaces/Requests/AtoSocietario/FiltroPesquisaEmpresaAto";
import FiltroPesquisaTipoAtoRequest from '../../../../../interfaces/Requests/TipoAto/FiltroPesquisaTipoAtoRequest';

import { modelo } from "./AtoSocietariosCampos";
import DespesasAtoSocietario from "./DespesasAtoSocietario";
import { DocumentosAtos } from "./DocumentosAtosSocietario";
import ModalSolucao from "../../../componentes/ModalSolucao";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { OrangeContext } from "../../../../../contexts/OrangeProvider";
import FileUploadAtoField from "../../../componentes/FileUploadAtoField";
import { EmpresaSolucaoLayout } from "../../../componentes/EmpresaSolucaoLayout";

export const AtosSocietariosPage = () => {
  const navigate = useNavigate();

  const { empresa, setAtoSocietario } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [tiposAto, setTiposAto] = useState<ITipoAto[]>([]);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [pesquisar, setPesquisar] = useState(false);
  const [atosSocietarios, setAtosSocietarios] = useState<IAtoSocietario[]>([]);
  const [atoSelected, setAtoSelected] = useState<IAtoSocietario>();

  const [exibirModalUploadDocumento, setExibirModalUploadDocumento] = useState<boolean>(false);

  const modalActions = useDisclosure(false);
  const modalDespesasActions = useDisclosure();
  const modalEditFilesActions = useDisclosure();

  const filtroTipoAto: FiltroPesquisaTipoAtoRequest = {
    limit: 100,
    nome: "",
    codigo: "",
    status: 0,
    offset: 0,
    sort: "",
    totalItems: 0,
  };

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEmpresaAto>({
    empresaId: empresa.empresaId,
    atoSocietarioId: 0,
    tipoAtoId: 0,
    status: 0,
    atoRegistrado: 0,
    dataAtoMaiorIgual: "",
    dataAtoMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    dataRegistroMaiorIgual: "",
    dataRegistroMenorIgual: "",

    offset: 0,
    limit: 10,
    sort: "TipoAtoId",
    totalItems: 0,
  });

  const tela = {
    modulo: EModulo.Societario,
    tela: 3,
    camposList: modelo,
  };


  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAtoSocietario>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  useEffect(() => { carregarAtosSocietarios(filtrosPesquisa) }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => { if (pesquisar) carregarAtosSocietarios(filtrosPesquisa) }, [pesquisar]);

  const carregarAtosSocietarios = async (filtro: any) => {
    try {
      let resultado: IRecuperaLista<IAtoSocietario>;

      resultado = await AtoSocietarioService.obterAtosSocietarios(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setAtosSocietarios(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  const carregarTiposAtos = async () => {
    try {
      setCarregando(true);
      const tipoAtosResult = await TipoAtoService.obterTipoAtos(filtroTipoAto);
      if (tipoAtosResult) {
        setTiposAto(() => tipoAtosResult.data);
      }
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: showErrorMessage(error),
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregando(false);
    }
  };

  const adicionar = async (values: IAtoSocietario) => {
    try {
      const swalConfirm = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalConfirm.fire({
        title: "Salvar Ato Societário",
        text: `Você realmente deseja salvar o Ato?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          icon: "info",
          title: "Realizando operação. Aguarde...",
          showConfirmButton: false,
        });
        Swal.showLoading();
        values.empresaId = empresa.empresaId;
        const resultado = await EmpresaService.adicionarAtosSocietarios(values);
        if (resultado) {
          await carregarAtosSocietarios(filtrosPesquisa);
          modalActions.handleClose();
          Swal.fire({
            title: "Sucesso",
            text: "Ato cadastrado com sucesso.",
            icon: "success",
            showConfirmButton: true,
            timer: 3000,
          });
          return true;
        }
      }
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Operação não realizada!",
        icon: "error",
        text: showErrorMessage(error),
        showConfirmButton: true,
        timer: 3000,
      });
    } finally {
      Swal.hideLoading();
    }
    return false;
  };

  const editar = async (values: IAtoSocietario) => {
    try {
      const swalConfirm = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });
      const result = await swalConfirm.fire({
        title: "Atualizar Ato Societário",
        text: `Você realmente deseja atualizar o Ato?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          icon: "info",
          title: "Realizando operação. Aguarde...",
          showConfirmButton: false,
        });
        Swal.showLoading();
        await EmpresaService.alteraAtosSocietarios(values.empresaId, values);
        await carregarAtosSocietarios(filtrosPesquisa);
        Swal.hideLoading();
        Swal.fire({
          heightAuto: false,
          title: "Sucesso",
          text: "Ato atualizado com sucesso.",
          icon: "success",
          showConfirmButton: true,
          timer: 3000,
        });
        modalActions.handleClose();
      }
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "OPS!",
        icon: "error",
        text: showErrorMessage(error),
        showConfirmButton: true,
        timer: 3000,
      });
    } finally {
      Swal.hideLoading();
    }
  };

  const alterarStatus = async () => {
    try {
      const swalConfirm = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });
      let result = await swalConfirm.fire({
        title: "Deletar Ato Societário",
        text: `Você realmente deseja deletar o Ato?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          icon: "info",
          title: "Realizando operação. Aguarde...",
          showConfirmButton: false,
        });
        Swal.showLoading();
        await carregarAtosSocietarios(filtrosPesquisa);
        throw new Error(
          "Não existe nenhuma recurso de delete, Status não presente no objeto ATOSOCIETARIO"
        );
      }
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "OPS!",
        icon: "error",
        text: showErrorMessage(error),
        showConfirmButton: true,
        timer: 3000,
      });
    } finally {
      Swal.hideLoading();
    }
  };

  const toggleImportacaoArquivos = () => setExibirModalUploadDocumento(!exibirModalUploadDocumento);

  useEffect(() => {
    carregarTiposAtos();
  }, []);

  const colunas: TableColumn<IAtoSocietario>[] = [
    {
      name: "#",
      sortField: "atoSocietarioId",
      selector: (row) => row.atoSocietarioId,
      sortable: true,
      ignoreRowClick: true,
      maxWidth: "75px",
      minWidth: "70px",
    },
    {
      name: "Tipo de Ato",
      sortField: "atoSocietarioId",
      selector: (row) =>
        tiposAto.find((ta) => ta.tipoAtoId === row.tipoAtoId)?.nome || "",
      sortable: true,
      ignoreRowClick: true,
    },
    {
      name: "Data do Ato",
      sortField: "dataAto",
      selector: (row) => moment(row.dataAto).format("DD/MM/YYYY"),
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Publicado?",
      sortField: "publicacaoAtoId",
      selector: (row) => row.publicacaoAtoNome,
      sortable: true,
      ignoreRowClick: true,
    },
    {
      name: "Registrado na Junta",
      sortField: "publicacaoAtoId",
      selector: (row) => row.atoRegistradoJuntaNome,
      sortable: true,
      ignoreRowClick: true,
    },

    {
      name: "Opções",
      cell: (row) => {
        return (
          <div>

            {/* <FontAwesomeIcon
              title={"Exluir"}
              onClick={() => {
                handleActions(row, "delete");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faTrash}
            /> */}
            <FontAwesomeIcon
              title="Anexar Documentos"
              onClick={() => {
                setAtoSelected(() => row);
                setExibirModalUploadDocumento(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faCloudUploadAlt}
            />
            <FontAwesomeIcon
              title="Detalhes"
              onClick={() => {
                setAtoSocietario(row);
                navigate("/Ato/Capa");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />
            {/*<FontAwesomeIcon
              title="Adicionar Despesas"
              onClick={() => {
                setAtoSelected(() => row);
                modalDespesasActions.handleOpen();
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faCalculator}
            />*/}
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handleActions = (
    values: IAtoSocietario,
    actions: "create" | "update" | "delete"
  ) => {
    if (actions === "create") {
      adicionar(values);
    } else if (actions === "update") {
      editar(values);
    } else if (actions === "delete") {
      alterarStatus();
    }
  };

  const limpaFiltros = () => setFiltrosPesquisa({
    empresaId: empresa.empresaId,
    atoSocietarioId: 0,
    tipoAtoId: 0,
    status: 0,
    atoRegistrado: 0,
    dataAtoMaiorIgual: "",
    dataAtoMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    dataRegistroMaiorIgual: "",
    dataRegistroMenorIgual: "",

    offset: 0,
    limit: 10,
    sort: "TipoAtoId",
    totalItems: 0,
  });


  return (
    <>
      <EmpresaSolucaoLayout
        title="Atos Societários"
        botaoAdicionar={<Button
          onClick={() => navigate("/Ato/Novo")}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </Button>}
        loading={carregando}
      >
        <>
          <div className="row mt-2">
            <div className="col-md-12">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row mt-3">
                  <div className="col-12 col-lg-4 col-xl-4">
                    <label
                      htmlFor="form-data-cadastro"
                      className="form-label fw-bolder text-orange"
                    >
                      Data de Cadastro
                    </label>

                    <DateRangePicker
                      className="form-control p-0"
                      calendarIcon={null}
                      showLeadingZeros={true}
                      maxDate={new Date()}
                      value={
                        filtrosPesquisa.dataCadastroMaiorIgual &&
                          filtrosPesquisa.dataCadastroMenorIgual
                          ? [
                            filtrosPesquisa.dataCadastroMaiorIgual,
                            filtrosPesquisa.dataCadastroMenorIgual,
                          ]
                          : ""
                      }
                      onChange={(datas: Date[]) => {
                        if (!datas) {
                          setFiltrosPesquisa((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataCadastroMaiorIgual: "",
                              dataCadastroMenorIgual: "",
                            };
                          });
                          return;
                        }

                        let data_inicial = datas[0];
                        let data_final = datas[1];
                        if (data_final) {
                          data_final.setHours(0, 0, 0, 0);
                          data_final = new Date(data_final.getTime() - 1);
                        }
                        setFiltrosPesquisa((oldValue: any) => {
                          return {
                            ...oldValue,
                            dataCadastroMaiorIgual: data_inicial
                              ? `${data_inicial.toISOString().split("T")[0]
                              }T00:00:00.0000`
                              : "",
                            dataCadastroMenorIgual: data_final
                              ? `${data_final.toISOString().split("T")[0]
                              }T23:59:59.9999`
                              : "",
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <label
                      htmlFor="form-data-distribuicao"
                      className="form-label fw-bolder text-orange"
                    >
                      Data do Ato
                    </label>
                    <DateRangePicker
                      className="form-control p-0"
                      calendarIcon={null}
                      showLeadingZeros={true}
                      maxDate={new Date()}
                      value={
                        filtrosPesquisa.dataAtoMaiorIgual &&
                          filtrosPesquisa.dataAtoMenorIgual
                          ? [
                            filtrosPesquisa.dataAtoMaiorIgual,
                            filtrosPesquisa.dataAtoMenorIgual,
                          ]
                          : ""
                      }
                      onChange={(datas: Date[]) => {
                        if (!datas) {
                          setFiltrosPesquisa((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataAtoMaiorIgual: "",
                              dataAtoMenorIgual: "",
                            };
                          });
                          return;
                        }
                        let data_inicial = datas[0];
                        let data_final = datas[1];
                        if (data_final) {
                          data_final.setHours(0, 0, 0, 0);
                          data_final = new Date(data_final.getTime() - 1);
                        }

                        setFiltrosPesquisa((oldValue: any) => {
                          return {
                            ...oldValue,
                            dataAtoMaiorIgual: data_inicial
                              ? `${data_inicial.toISOString().split("T")[0]
                              }T00:00:00.0000`
                              : "",
                            dataAtoMenorIgual: data_final
                              ? `${data_final.toISOString().split("T")[0]
                              }T23:59:59.9999`
                              : "",
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <label
                      htmlFor="form-data-audiencia"
                      className="form-label fw-bolder text-orange"
                    >
                      Data de Registro do Ato
                    </label>
                    <DateRangePicker
                      className="form-control p-0"
                      calendarIcon={null}
                      showLeadingZeros={true}
                      maxDate={new Date()}
                      value={
                        filtrosPesquisa.dataRegistroMaiorIgual &&
                          filtrosPesquisa.dataRegistroMenorIgual
                          ? [
                            filtrosPesquisa.dataRegistroMaiorIgual,
                            filtrosPesquisa.dataRegistroMenorIgual,
                          ]
                          : ""
                      }
                      onChange={(datas: Date[]) => {
                        if (!datas) {
                          setFiltrosPesquisa((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataRegistroMaiorIgual: "",
                              dataRegistroMenorIgual: "",
                            };
                          });
                          return;
                        }

                        let data_inicial = datas[0];
                        let data_final = datas[1];
                        if (data_final) {
                          data_final.setHours(0, 0, 0, 0);
                          data_final = new Date(data_final.getTime() - 1);
                        }

                        setFiltrosPesquisa((oldValue: any) => {
                          return {
                            ...oldValue,
                            dataRegistroMaiorIgual: data_inicial
                              ? `${data_inicial.toISOString().split("T")[0]
                              }T00:00:00.0000`
                              : "",
                            dataRegistroMenorIgual: data_final
                              ? `${data_final.toISOString().split("T")[0]
                              }T23:59:59.9999`
                              : "",
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-lg-4 col-xl-4 mt-2">
                    <label
                      htmlFor="form-nome"
                      className="form-label fw-bolder text-orange"
                    >
                      Tipo de Ato
                    </label>
                    <select
                      value={filtrosPesquisa.tipoAtoId}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState) => {
                          return {
                            ...oldState,
                            tipoAtoId: parseInt(e.target.value),
                          };
                        });
                      }}
                      placeholder="Nome"
                      className={"form-select"}
                      id="form-select"
                    >
                      <option value="0" label="Todos">
                        {" "}
                        Todos{" "}
                      </option>
                      {tiposAto.map((tipoAto, index) => {
                        return (
                          <option
                            key={index}
                            value={tipoAto.tipoAtoId}
                            label={tipoAto.nome}
                          >
                            {tipoAto.nome}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-lg-4 col-xl-4 mt-2">
                    <label
                      htmlFor="form-status"
                      className="form-label fw-bolder text-orange"
                    >
                      Status
                    </label>
                    <select
                      value={filtrosPesquisa.status}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState: any) => {
                          return {
                            ...oldState,
                            status: parseInt(e.target.value),
                          };
                        });
                      }}
                      placeholder="Status"
                      className={"form-select"}
                      id="form-status"
                    >
                      <option value={0}>Todos</option>
                      <option value={1}>Ativo</option>
                      <option value={2}>Descobrir</option>
                      <option value={-1}>Cancelado</option>
                    </select>
                  </div>
                  <div className="col-12 col-lg-4 col-xl-4 mt-2">
                    <label
                      htmlFor="form-status"
                      className="form-label fw-bolder text-orange"
                    >
                      Ato Registrado?
                    </label>
                    <select
                      value={filtrosPesquisa.atoRegistrado}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState: any) => {
                          return {
                            ...oldState,
                            atoRegistrado: parseInt(e.target.value),
                          };
                        });
                      }}
                      placeholder="Status"
                      className={"form-select"}
                      id="form-status"
                    >
                      <option value={0}>Todos</option>
                      <option value={1}>Sim</option>
                      <option value={2}>Não</option>
                    </select>
                  </div>

                  <div className="col-12 col-md-12 col-xl-12 text-end">
                    <button
                      onClick={() => setPesquisar(true)}
                      className="btn btn-sm btn-orange search-buttom-margin ms-2"
                    >
                      <FontAwesomeIcon
                        color="white"
                        className="mx-2"
                        icon={faSearch}
                        title="Pesquisar"
                      />
                    </button>

                    <button
                      onClick={() => {
                        limpaFiltros();
                      }}
                      className="btn btn-sm btn-orange search-buttom-margin ms-2"
                    >
                      <FontAwesomeIcon
                        color="white"
                        className="mx-2"
                        icon={faEraser}
                        title="Limpar Filtros"
                      />
                    </button>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        // baixarRelatorio();
                      }}
                      className="btn btn-sm btn-orange search-buttom-margin ms-2"
                      title="Baixar Relatório"
                    // disabled={relatorioId === null || carregandoLogs}
                    >
                      <FontAwesomeIcon
                        color="white"
                        className="mx-2"
                        icon={faFileExcel}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <GridPadrao
            onSort={handleSort}
            progressPending={false}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Itens"
            itens={atosSocietarios}
          />

          {(atoSelected || modalActions.isOpen) && (
            <>
              <ModalSolucao
                titulo="Ato Societário"
                campos={modelo}
                itemSelecionado={atoSelected}
                handleActions={handleActions}
                modalActions={modalActions}
                empresaId={empresa.empresaId}
                tela={tela}
              />

              <FileUploadAtoField
                setQuantidadeArquivosSelecionados={() => {
                  return;
                }}
                exibirModal={exibirModalUploadDocumento}
                toggleModal={toggleImportacaoArquivos}
                atoSocietarioId={atoSelected?.atoSocietarioId}
                capa={true}
              />
              {modalDespesasActions.isOpen && (
                <DespesasAtoSocietario
                  modalActions={modalDespesasActions}
                  tituloModal="Despesas Ato Societário"
                  atoSocietarioId={atoSelected?.atoSocietarioId}
                />
              )}

              <Modal
                size="xl"
                centered={true}
                show={modalEditFilesActions.isOpen}
                onHide={modalEditFilesActions.handleClose}
              >
                <Modal.Header>
                  <Modal.Title className="text-orange">
                    {" "}
                    #{atoSelected?.atoSocietarioId} Arquivos do Ato -{" "}
                    {
                      tiposAto.find(
                        (ta) => ta.tipoAtoId === atoSelected?.tipoAtoId
                      )?.nome
                    }
                  </Modal.Title>
                  <button
                    onClick={modalEditFilesActions.handleClose}
                    className={"btn btn-icon close"}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </Modal.Header>

                <Modal.Body>
                  <Row>
                    {atoSelected && (
                      <DocumentosAtos atoSocietario={atoSelected} />
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={modalEditFilesActions.handleClose}
                    type={"button"}
                    className={"btn btn-orange ms-5"}
                  >
                    {" "}
                    Adicionar{" "}
                  </Button>
                  <Button
                    onClick={modalEditFilesActions.handleClose}
                    className={"btn btn-danger ms-5"}
                  >
                    {" "}
                    Cancelar{" "}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </>
      </EmpresaSolucaoLayout>
    </>
  );
};
