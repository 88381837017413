import { useMutation } from "react-query";
import EditorService from "../../../../../services/EditorService";
import { handleApiError } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import { useTenant } from "../../../hooks/useTenant";

export function useFileUpload() {
  const { module, tenant } = useTenant()

  const { mutateAsync: uploadFileFn, isLoading } = useMutation({
    mutationFn: EditorService.attachEditorFile,
    onSuccess: () => {
      toast.success('Documento anexado com sucesso!', { position: 'bottom-right' });
    },
    onError: (error) => {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage, { position: "bottom-right" });
    },
  });

  async function upload(file: File) {
    await uploadFileFn({
      descricao: file.name,
      file,
      modulo: module,
      origem: 1,
      restrito: false,
      tenant,
      tipoDocumentoId: 77 // Temporary
    })
  }

  return {
    upload,
    isLoading
  }
}
