import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IBanco from "../interfaces/IBanco";
import { AdicionaBancoRequest } from "../interfaces/Requests/Banco/AdicionaBancoRequest";
import FiltroPesquisaBancoRequest from "../interfaces/Requests/Banco/FiltroPesquisaBancoRequest";
import { EditarBancoRequest } from "../interfaces/Requests/Banco/EditarBancoRequest";

class BancoService {

    async obterBancos(filtros: FiltroPesquisaBancoRequest): Promise<IRecuperaLista<IBanco>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IBanco>>(`Banco/RecuperaBanco?${query}`);

        return result;
    }

    async adicionaBanco(adicionaBanco: AdicionaBancoRequest): Promise<IBanco> {
        let { data: result } = await api.post<IBanco>(`Banco/AdicionaBanco`, adicionaBanco);

        return result;
    }


    async alterarStatus(bancoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IBanco>(`Banco/AtualizaBancoPorId?id=${bancoId}`, data);
    }

    async atualizarBanco(editarBanco: EditarBancoRequest): Promise<void> {

        let dataBanco = [
            { "op": "replace", "path": "/nome", "value": editarBanco.nome },
            { "op": "replace", "path": "/codigo", "value": editarBanco.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Banco/AtualizaBancoPorId?id=${editarBanco.bancoId}`, dataBanco)
        ]);
    }
}


export default new BancoService();