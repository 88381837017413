import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoProtocolo from "../interfaces/ITipoProtocolo";
import { AdicionaTipoProtocoloRequest } from "../interfaces/Requests/TipoProtocolo/AdicionaTipoProtocoloRequest";
import { EditarTipoProtocoloRequest } from "../interfaces/Requests/TipoProtocolo/EditarTipoProtocoloRequest";
import FiltroPesquisaTipoProtocoloRequest from "../interfaces/Requests/TipoProtocolo/FiltroPesquisaTipoProtocoloRequest";


class TipoProtocoloService {

    async obterTipoProtocolos(filtros: FiltroPesquisaTipoProtocoloRequest): Promise<IRecuperaLista<ITipoProtocolo>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoProtocolo>>(`TipoProtocolo/RecuperaTipoProtocolo?${query}`);

        return result;
    }


    async obterListaLimitadaTipoProtocolos(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoProtocolo/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoProtocolo(adicionaTipoProtocolo: AdicionaTipoProtocoloRequest): Promise<ITipoProtocolo> {
        let { data: result } = await api.post<ITipoProtocolo>(`TipoProtocolo/AdicionaTipoProtocolo`, adicionaTipoProtocolo);

        return result;
    }


    async alterarStatus(tipoProtocoloId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoProtocolo>(`TipoProtocolo/AtualizaTipoProtocoloPorId?id=${tipoProtocoloId}`, data);
    }

    async atualizarTipoProtocolo(editarTipoProtocolo: EditarTipoProtocoloRequest): Promise<void> {

        let dataTipoProtocolo = [
            { "op": "replace", "path": "/nome", "value": editarTipoProtocolo.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoProtocolo.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoProtocolo/AtualizaTipoProtocoloPorId?id=${editarTipoProtocolo.tipoProtocoloId}`, dataTipoProtocolo)
        ]);
    }
}


export default new TipoProtocoloService();