import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import Swal from "sweetalert2";
import { Button, Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faCheckCircle, faDownload, faFileArchive, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { BsTrash, BsCloudArrowDown, BsEye, BsCloudArrowUp } from "react-icons/bs";

import PastaGedService from "../../services/PastaGedService";
import DocumentoService from "../../services/DocumentoService";
import TipoDocumentoService from "../../services/TipoDocumentoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { ECamposIDEnum, EModulo } from "../../enum";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { OrangeContext } from "../../contexts/OrangeProvider";

import IOption from "../../interfaces/IOption";
import IPastaGed from "../../interfaces/IPastaGed";
import IDocumento from "../../interfaces/IDocumento";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaDocumentoRequest from "../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";

import GridPadrao from "../Comum/GridPadrao";
import IFileUploadField from "./FileUploadField";
import IFileUploadFieldEdit from "./FileUploadFieldEdit";
import PreviewPDFField from "../Comum/PreviewPDF/PreviewPDF";

interface IFileCheckListCapaFielProps {
  modulo: EModulo;
  somenteLeitura?: boolean;
  configuracoesTela: IRecuperaConfiguracaoTelaResponse[];
  setConfiguracoesTela(configuracoesTela: IRecuperaConfiguracaoTelaResponse[]): void;
}

const FileListCapaField = ({ modulo, configuracoesTela, setConfiguracoesTela, somenteLeitura }: IFileCheckListCapaFielProps) => {

  const {

    processo,
    contrato,
    imovel,
    licenca,
    procuracao,
    marca,
    consulta,
    empresa,

    despesa,
    propostaAcordo,
    garantia,
    decisao,
    obrigacao,
    bloqueio,
    protocolo,
    desdobramento,
    comunicacao,
    atoSocietario,
    honorario,
    reembolso,
    audiencia


  } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [documentos, setDocumentos] = useState<IDocumento[]>([]);
  const [documento, setDocumento] = useState<IDocumento>({
    index: 0,
    documentoId: 0,
    clienteId: 0,
    nome: "",
    descricao: "",
    tamanho: 0,
    tipoDocumentoId: 0,
    tipoDocumentoNome: "",
    restrito: false,
    status: "",
    codigo: "",
    dataCadastro: new Date(),
    usuarioIdCadastro: 0,
    usuarioNomeCadastro: "",
    modulo: "",
    extensao: "",
    pastaGedNome: "",
    etiquetas: [],
  });

  const [listaPasta, setListaPasta] = useState<IPastaGed[]>([]);

  const [carregamentoOptions, setCarregamentoOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [optionsSelected, setOptionsSelected] = useState([]);

  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);

  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    modulo: [modulo],
    documentoId: [],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [exibirListaArquivosImportados, setExibirListaArquivosImportados] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);
  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [exibirComponenteParaImportarArquivo, setExibirComponenteParaImportarArquivo] = useState<boolean>(false);
  const [exibirModalDocumentoEdit, setExibirModalDocumentoEdit] = useState<boolean>(false);
  const [atualizaTabelaDocumento, setAtualizaTabelaDocumento] = useState<boolean>(false);
  const [marcarTodos, setMarcarTodos] = useState<boolean>(false);

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  const toggleDocumentoEdit = () => setExibirModalDocumentoEdit(!exibirModalDocumentoEdit);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const togglerComponenteParaImportarArquivo = () => setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);


  useEffect(() => { carregaTipoDocumento(); }, []);


  useEffect(() => {
    const IdsSelected: number[] = [];
    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisaArquivos((oldState) => {
      return { ...oldState, tipoDocumentoId: IdsSelected };
    });
  }, [optionsSelected]);


  useEffect(() => {

    carregarDocumentos(filtrosPesquisaArquivos);

  }, [filtrosPesquisaArquivos.offset, filtrosPesquisaArquivos.limit, filtrosPesquisaArquivos.sort]);

  useEffect(() => {
    if (atualizaTabelaDocumento || exibirListaArquivosImportados) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
  }, [atualizaTabelaDocumento, exibirListaArquivosImportados]);

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {
      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos({
        ...filtro,
        modulo: [modulo],

        //modulos
        processoId: processo.processoId,
        contratoId: contrato.contratoId,
        consultaId: consulta.consultaId,
        imovelId: imovel.imovelId,
        marcaId: marca.marcaId,
        licencaId: licenca.licencaId,
        procuracaoId: procuracao.procuracaoId,
        empresaId: empresa.empresaId,

        //solucoes
        despesaId: despesa.despesaId,
        propostaAcordoId: propostaAcordo.propostaAcordoId,
        garantiaId: garantia.garantiaId,
        obrigacaoId: obrigacao.obrigacaoId,
        bloqueioId: bloqueio.bloqueioId,
        decisaoId: decisao.decisaoId,
        xfinderProtocoloId: protocolo.xfinderProtocoloId,
        desdobramentoId: desdobramento.desdobramentoId,
        comunicacaoId: comunicacao?.comunicacaoId,
        reembolsoId: reembolso.reembolsoId,
        honorarioId: honorario.honorarioId
        , status: 1
      });

      setDocumentos(resultado.data);

      setFiltrosPesquisaArquivos((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoDocumentos(false);
    } catch (error: any) {
      setDocumentos([]);
      setCarregandoDocumentos(false);
    } finally {
      setAtualizaTabelaDocumento(false);
    }
  };

  const verificaIsPdf = (extensao: string) => {
    if (extensao.endsWith(".pdf")) return true;
    else return false;
  };

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      togglePDFDocumento();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };


  const excluirMarcados = async () => {

    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-orange",
        cancelButton: "btn btn-danger ms-5",
      },
      buttonsStyling: false,
    });

    let result = await swalWithBootstrapButtons.fire({
      title: "Deletar documentos",
      text: `Você realmente deseja deletar?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Confirmar`,
    });

    if (result.isConfirmed) {

      let selecionados = documentos?.filter((e: IDocumento) => e.checked) || [];

      selecionados.forEach(async documento => await DocumentoService.inativaDocumento(documento.documentoId));

      setTimeout(() => carregarDocumentos(filtrosPesquisaArquivos), 3000);

    }
  };


  const excluirDocumento = async (documentoId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Deletar documentos",
        text: `Você realmente deseja deletar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {

        await DocumentoService.inativaDocumento(documentoId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Documento deletado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        carregarDocumentos(filtrosPesquisaArquivos);
      }
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel deletar o documento`,
        text: error?.response?.data?.Message,
        showConfirmButton: true,
      });
    }
  };

  const adicionar = (documentoId: number) => {

    const updated = documentos.map((documento: IDocumento) => {
      if (documento.documentoId === documentoId) {
        return { ...documento, checked: !documento.checked };
      }
      return documento;
    });

    setDocumentos(updated);
  };

  const colunas: TableColumn<IDocumento>[] = [
    {
      name: "Selecionar",
      ignoreRowClick: true,
      cell: (row: any) => {
        return (
          <div>
            <Form.Check
              type="switch"
              id="custom-switch"
              style={{ cursor: 'pointer' }}
              checked={row?.checked}
              onClick={() => adicionar(row.documentoId)}
            />
          </div>
        );
      },
    },
    {
      name: "ID",
      sortField: "documentoId",
      selector: (row: IDocumento) => row.documentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo de Documento",
      sortField: "tipoDocumentoNome",
      selector: (row: IDocumento) => row.tipoDocumentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: IDocumento) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Restrito?",
      sortField: "restrito",
      selector: (row: IDocumento) => (row.restrito ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Usuário",
      sortField: "usuarioNomeCadastro",
      selector: (row: IDocumento) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "  ",
      selector: (row: IDocumento) => moment(row.dataCadastro).format("DD/MM/yyyy"),
      sortable: true,
      wrap: true,
    },
    ...(!somenteLeitura
      ? [
        {
          name: "",
          cell: (row: IDocumento) => {
            return (
              <div>
                <FaEdit
                  title="Editar Arquivo"
                  onClick={() => {
                    setDocumento(row);
                    onChangeTipoDocumento();
                    setExibirModalDocumentoEdit(true);
                  }}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="20px"
                  className="mx-2 text-orange"
                />

                <IFileUploadFieldEdit
                  campo={58}
                  exibirModalDocumentoEdit={exibirModalDocumentoEdit}
                  toggleModal={toggleDocumentoEdit}
                  editarDocumento={documento}
                  modulo={parseInt(documento.modulo)}
                  setAtualizarTabela={setAtualizaTabelaDocumento}

                  processoId={processo.processoId}
                  areaDireitoId={processo.areaDireitoId}

                  contratoId={contrato.contratoId}
                  tipoContratoId={contrato.tipoContratoId}

                  imovelId={imovel.imovelId}
                  tipoImovelId={imovel.tipoImovelId}

                  procuracaoId={procuracao.procuracaoId}
                  tipoProcuracaoId={procuracao.tipoProcuracaoId}

                  consultaId={consulta.consultaId}
                  tipoConsultaId={consulta.tipoConsultaId}

                  marcaId={marca.marcaId}
                  tipoMarcaId={marca.tipoMarcaId}

                  licencaId={licenca.licencaId}
                  tipoLicencaId={licenca.tipoLicencaId}

                  empresaId={empresa.empresaId}

                  desdobramentoId={desdobramento.desdobramentoId}
                  tipoDesdobramentoId={desdobramento.tipoDesdobramentoId}

                  audienciaId={audiencia.audienciaId}
                  tipoAudienciaId={audiencia.tipoAudienciaId}

                  reembolsoId={reembolso.reembolsoId}
                  tipoReembolsoId={reembolso.tipoReembolsoId}

                  honorarioId={honorario.honorarioId}
                  tipoHonorarioId={honorario.tipoHonorarioId}

                />

                <BsTrash
                  title="Deletar Arquivo"
                  onClick={() => excluirDocumento(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="20px"
                  className="mx-1 text-orange"
                />

                <BsCloudArrowDown
                  title="Baixar Arquivo"
                  onClick={() => baixarArquivo(row.documentoId, row.nome)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="20px"
                  className="mx-1 text-orange"
                />
                <>
                  {verificaIsPdf(row.extensao) && (
                    <BsEye
                      title="Preview"
                      onClick={() => previewArquivo(row.documentoId)}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="20px"
                      className="mx-1 text-orange"
                    />
                  )}
                </>
              </div>
            );
          },
        }
      ]
      : []),
  ];

  const handlePerRowsChangeArquivo = async (currentRowsPerPage: number) => setFiltrosPesquisaArquivos((oldState: any) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChangeArquivo = (page: number) => setFiltrosPesquisaArquivos((oldState: any) => { return { ...oldState, offset: (page - 1) * filtrosPesquisaArquivos.limit }; });

  const handleSortArquivo = async (column: TableColumn<any>, sortDirection: string) => setFiltrosPesquisaArquivos((oldState: any) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  const onChangeTipoDocumento = () => {
    if (configuracoesTela.filter((e: { campo: number }) => e.campo == 58).length > 0) {
      const tipoDocumento = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo == 58)[0];

      const configuracoesTelaFilter = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo !== 58);

      configuracoesTelaFilter.push({ ...tipoDocumento, alterarCampo: true });

      setConfiguracoesTela(configuracoesTelaFilter);
    }
    if (exibirComponenteParaImportarArquivo) carregarDocumentos(filtrosPesquisaArquivos);
  };

  const carregaTipoDocumento = async () => {
    try {

      setCarregamentoOptions(true);

      // modulos
      if (processo.areaDireitoId && processo.areaDireitoId > 0 && modulo == EModulo.AreaDireito) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.AreaDireito, processo.areaDireitoId, processo.processoId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (contrato.tipoContratoId && contrato.tipoContratoId > 0 && modulo == EModulo.Contrato) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Contrato, contrato.tipoContratoId, contrato.contratoId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (marca.tipoMarcaId && marca.tipoMarcaId > 0 && modulo == EModulo.MarcaEPertences) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.MarcaEPertences, marca.tipoMarcaId, marca.marcaId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (imovel.tipoImovelId && imovel.tipoImovelId > 0 && modulo == EModulo.Imovel) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Imovel, imovel.tipoImovelId, imovel.imovelId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (licenca.tipoLicencaId && licenca.tipoLicencaId > 0 && modulo == EModulo.Regulatorio) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Regulatorio, licenca.tipoLicencaId, licenca.licencaId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (procuracao.tipoProcuracaoId && procuracao.tipoProcuracaoId > 0 && modulo == EModulo.Procuracao) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Procuracao, procuracao.tipoProcuracaoId, procuracao.procuracaoId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (consulta.tipoConsultaId && consulta.tipoConsultaId > 0 && modulo == EModulo.Consultivo) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Consultivo, consulta.tipoConsultaId, consulta.consultaId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (atoSocietario.tipoAtoId && atoSocietario.tipoAtoId > 0 && modulo == EModulo.AtoSocietario) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.AtoSocietario, atoSocietario.tipoAtoId, atoSocietario.atoSocietarioId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      //solucoes
      if (despesa.tipoDespesaId && despesa.tipoDespesaId > 0 && modulo == EModulo.Despesa) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Despesa, despesa.tipoDespesaId, despesa.despesaId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (garantia.tipoGarantiaId && garantia.tipoGarantiaId > 0 && modulo == EModulo.Garantia) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Garantia, garantia.tipoGarantiaId, garantia.garantiaId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (bloqueio.tipoBloqueioId && bloqueio.tipoBloqueioId > 0 && modulo == EModulo.Bloqueio) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Bloqueio, bloqueio.tipoBloqueioId, bloqueio.bloqueioId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (decisao.tipoDecisaoId && decisao.tipoDecisaoId > 0 && modulo == EModulo.Decisao) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Decisao, decisao.tipoDecisaoId, decisao.decisaoId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (obrigacao.tipoObrigacaoId && obrigacao.tipoObrigacaoId > 0 && modulo == EModulo.Obrigacao) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Obrigacao, obrigacao.tipoObrigacaoId, obrigacao.obrigacaoId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (comunicacao.tipoComunicacaoId && comunicacao.tipoComunicacaoId > 0 && modulo == EModulo.Comunicacao) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Comunicacao, comunicacao.tipoComunicacaoId, comunicacao.comunicacaoId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (desdobramento.tipoDesdobramentoId && desdobramento.tipoDesdobramentoId > 0 && modulo == EModulo.Desdobramento) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Desdobramento, desdobramento.tipoDesdobramentoId, desdobramento.desdobramentoId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (propostaAcordo.tipoPropostaAcordoId && propostaAcordo.tipoPropostaAcordoId > 0 && modulo == EModulo.PropostaAcordo) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.PropostaAcordo, propostaAcordo.tipoPropostaAcordoId, propostaAcordo.propostaAcordoId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (honorario.tipoHonorarioId && honorario.tipoHonorarioId > 0 && modulo == EModulo.XpayHonorario) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.XpayHonorario, honorario.tipoHonorarioId, honorario.honorarioId, processo.processoId, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (reembolso.tipoReembolsoId && reembolso.tipoReembolsoId > 0 && modulo == EModulo.XpayReembolso) {
        let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.XpayReembolso, reembolso.tipoReembolsoId, reembolso.reembolsoId, 0, 58);
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      if (empresa.empresaId && empresa.empresaId > 0 && modulo == EModulo.Societario) {
        let resultado = await TipoDocumentoService.obterTipoDocumentos({
          nome: '',
          codigo: '',
          status: 0,
          offset: 0,
          limit: 1000,
          sort: 'tipoDocumentoId',
          totalItems: 0
        });

        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));

        const pastas = await PastaGedService.obterPastaGeds({
          nome: '',
          codigo: '',
          status: 1,
          limit: 10,
          totalItems: 0,
          offset: 0,
          sort: 'id'
        })
        setListaPasta(pastas.data)
      }

      if (atoSocietario.atoSocietarioId && atoSocietario.atoSocietarioId > 0 && modulo == EModulo.AtoSocietario) {
        let resultado = await TipoDocumentoService.obterTipoDocumentos({
          nome: '',
          codigo: '',
          status: 0,
          offset: 0,
          limit: 1000,
          sort: 'tipoDocumentoId',
          totalItems: 0
        });
        resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoDocumentoId }));
      }

      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregamentoOptions(false);
    }
  };

  const documentosMarcados = () => {
    let selecionados = documentos?.filter((e: IDocumento) => e.checked) || [];
    return selecionados.map(documento => documento.documentoId);
  };

  const baixarMarcados = () => {
    let selecionados = documentos?.filter((e: IDocumento) => e.checked) || [];

    selecionados.forEach(async documento => {
      await baixarArquivo(documento.documentoId, documento.nome);
    });

  };


  const adicionarTodos = () => {

    const updated = documentos.map((documento: IDocumento) => {
      if (documento.checked) {
        setMarcarTodos(false);
        return { ...documento, checked: false };
      } else {
        setMarcarTodos(true);
        return { ...documento, checked: true };
      }
    });

    setDocumentos(updated);
  }

  return (
    exibirComponenteParaImportarArquivo ? (
      <>
        <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />
        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Clique aqui
              <FontAwesomeIcon
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                icon={faBackward}
              /> para salvar
            </label>
            <br />
            <>
              <Button
                onClick={() => setExibirModalImportacaoArquivos(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                Selecione os arquivos
              </Button>
              <IFileUploadField
                setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                exibirModal={exibirModalImportacaoArquivos}
                toggleModal={toggleImportacaoArquivos}
                campo={58}

                modulo={modulo}
                //modulos
                processoId={processo.processoId}
                areaDireitoId={processo.areaDireitoId}

                contratoId={contrato.contratoId}
                tipoContratoId={contrato.tipoContratoId}

                imovelId={imovel.imovelId}
                tipoImovelId={imovel.tipoImovelId}

                procuracaoId={procuracao.procuracaoId}
                tipoProcuracaoId={procuracao.tipoProcuracaoId}

                consultaId={consulta.consultaId}
                tipoConsultaId={consulta.tipoConsultaId}

                marcaId={marca.marcaId}
                tipoMarcaId={marca.tipoMarcaId}

                licencaId={licenca.licencaId}
                tipoLicencaId={licenca.tipoLicencaId}

                //solucoes
                propostaAcordoId={propostaAcordo.propostaAcordoId}
                tipoPropostaAcordoId={propostaAcordo.tipoPropostaAcordoId}

                despesaId={despesa.despesaId}
                tipoDespesaId={despesa.tipoDespesaId}

                garantiaId={garantia.garantiaId}
                tipoGarantiaId={garantia.tipoGarantiaId}

                decisaoId={decisao.decisaoId}
                tipoDecisaoId={decisao.tipoDecisaoId}

                obrigacaoId={obrigacao.obrigacaoId}
                tipoObrigacaoId={obrigacao.tipoObrigacaoId}

                bloqueioId={bloqueio.bloqueioId}
                tipoBloqueioId={bloqueio.tipoBloqueioId}

                desdobramentoId={desdobramento.desdobramentoId}
                tipoDesdobramentoId={desdobramento.tipoDesdobramentoId}

                comunicacaoId={comunicacao?.comunicacaoId}
                tipoComunicacaoId={comunicacao?.tipoComunicacaoId}

                audienciaId={audiencia?.audienciaId}
                tipoAudienciaId={audiencia?.tipoAudienciaId}

                reembolsoId={reembolso.reembolsoId}
                tipoReembolsoId={reembolso.tipoReembolsoId}

                honorarioId={honorario.honorarioId}
                tipoHonorarioId={honorario.tipoHonorarioId}

                capa={true}
              />
            </>
          </div>
        </div>

        <div className="row mt-2">
          <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
            {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
            {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
            {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
          </a>
        </div>

      </>
    ) : (
      <>
        <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

        <div className="row mt-2">
          <div className="col-md-12">
            <label
              onClick={() => setExibirListaArquivosImportados(!exibirListaArquivosImportados)}
              htmlFor="form-Documentos"
              style={{ cursor: "pointer" }}
              className="form-label fw-bolder text-orange"
            >
              Documentos {exibirListaArquivosImportados ? "-" : "+"}

              {!somenteLeitura &&
                <BsCloudArrowUp
                  onClick={() => {
                    onChangeTipoDocumento();
                    togglerComponenteParaImportarArquivo();
                  }}
                  color={"var(--primary-base2)"}
                  className="mx-3"
                  style={{ cursor: "pointer" }}
                  size={"30px"}
                  title={"Realizar upload de documento"}
                />
              }

            </label>

            {exibirListaArquivosImportados && (
              <>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <MultiSelect
                      isLoading={carregamentoOptions}
                      disabled={carregamentoOptions}
                      options={options}
                      value={optionsSelected}
                      onChange={(event: any) => setOptionsSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione o tipo...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>

                  <div className="col-3">
                    <input
                      value={filtrosPesquisaArquivos.nome}
                      onChange={(e) => {
                        setFiltrosPesquisaArquivos((oldState: any) => {
                          return { ...oldState, nome: e.target.value };
                        });
                      }}
                      placeholder="Descrição"
                      type="text"
                      className={clsx("form-control")} />
                  </div>

                  <div className="col-6">

                    <button type='button' onClick={() => carregarDocumentos({ ...filtrosPesquisaArquivos })} className="btn btn-orange me-2">
                      <FontAwesomeIcon color='white' icon={faSearch} />
                    </button>

                    {documentosMarcados()?.length > 0 && (
                      <>
                        <button type='button' onClick={baixarMarcados} className="btn btn-orange me-2" title="Download">
                          <FontAwesomeIcon color='white' icon={faDownload} />
                        </button>

                        <button type='button' onClick={() => excluirMarcados()} className="btn btn-orange me-2" title="Excluir">
                          <FontAwesomeIcon color='white' icon={faTrash} />
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6 d-flex align-items-center">
                    <label
                      style={{ cursor: "pointer" }}
                      className="form-label fw-bolder text-orange me-2 mb-0"
                    >
                      Todos:
                    </label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      style={{ cursor: 'pointer' }}
                      checked={marcarTodos}
                      onClick={() => adicionarTodos()} />
                  </div>
                </div>



                <GridPadrao
                  progressPending={carregandoDocumentos}
                  onSort={handleSortArquivo}
                  limit={filtrosPesquisaArquivos.limit}
                  onChangePage={handlePageChangeArquivo}
                  onChangeRowsPerPage={handlePerRowsChangeArquivo}
                  paginationServer={true}
                  paginationTotalRows={filtrosPesquisaArquivos.totalItems}
                  colunas={colunas}
                  tipo="Documentos"
                  itens={documentos} /></>
            )}
          </div>
        </div>
        <hr />
      </>)
  );
};

export default FileListCapaField;
