import { MdOutlineFormatIndentDecrease, MdOutlineFormatIndentIncrease } from "react-icons/md";
import { TextIndentationControl } from "./types";

export const indentation: TextIndentationControl[] = [
  {
    type: 'decreaseIndent',
    component: <MdOutlineFormatIndentDecrease />,
  },
  {
    type: 'increaseIndent',
    component: <MdOutlineFormatIndentIncrease />,
  }
]