import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";

import ITipoMarca from "../../interfaces/ITipoMarca";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaMarcaRequest } from "../../interfaces/Requests/Marca/IAdicionaMarcaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import MarcaService from "../../services/MarcaService";
import TipoMarcaService from "../../services/TipoMarcaService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";
import { marcaInitialValues, tipoMarcaInicialValues } from "../../contexts/InitialValuesContext";

const MarcaCadastroPage = () => {
  const [carregandoTiposMarca, setCarregandoTiposMarca] = useState<boolean>(false);
  const [tiposMarca, setTiposMarca] = useState<ITipoMarca[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { marca, setMarca, setTipoMarca, tipoMarca } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarMarcaLoading, setAdicionarMarcaLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: IAdicionaMarcaRequest = {
    marcaId: 0,
    tipoMarcaId: 0,
    restrito: false,

    modulo: EModulo.MarcaEPertences,
    tela: ETelaEnum.TelaCadastroMarcasEPertences,
    categoriaId: 0,

    datainiciovigencia: null,
    datafimvigencia: null,
    datadeposito: null,
    dataconcessao: null,
    datapublicacao: null,

    codigo: "",
    nomemarca: "",
    numeropedido: "",
    observacoesmarca: "",
    especificacao: "",
    numeroregistro: "",
    tituloregistro: "",

    tipoDocumentoCheckList: 0,
    apresentacaomarcaId: 0,
    classemarcaId: 0,
    naturezamarcaId: 0,
    classificacaoipcId: 0,
    escritorioId: 0,
    departamentoId: 0,
    paisId: 0,
    estadoId: 0,
    cidadeId: 0,
    tipodocumentoId: 0,

    empresasList: [],
    partesContrariasList: [],
    advogadoInternoList: [],
    centrosCusto: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Marca",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          setAdicionarMarcaLoading(true);
          let resultado = await MarcaService.adicionaMarca({
            ...values,
            tipoMarcaId: tipoMarca.tipoMarcaId,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });
          setMarca(resultado);
          setTipoMarca(tipoMarca);
          formik.resetForm();
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Marca cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarMarcaLoading(false);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarMarcaLoading(false);
      }
    },
  });

  useEffect(() => {

    setMarca(marcaInitialValues);

    setTipoMarca(tipoMarcaInicialValues);

    carregarTipoMarca();

    setConfiguracoesTela([]);

  }, []);

  useEffect(() => {
    if (tipoMarca.tipoMarcaId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoMarca(tipoMarcaInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoMarca.tipoMarcaId]);


  const carregarTipoMarca = async () => {
    try {
      setCarregandoTiposMarca(true);
      let resultado = await TipoMarcaService.obterTipoMarcas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTiposMarca(resultado.data);
      setCarregandoTiposMarca(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
      setCarregandoTiposMarca(false);
      setTiposMarca([]);
    }
  };

  const carregarConfiguracaoTela = async () => {
    setCarregandoConfiguracoesTela(true);
    let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
      TipoMarcaId: tipoMarca.tipoMarcaId,
      Modulo: EModulo.MarcaEPertences,
      Tela: ETelaEnum.TelaCadastroMarcasEPertences,
    });
    setConfiguracoesTela(resultado.data);
    setCarregandoConfiguracoesTela(false);
  };

  const onChangeTipoMarca = (value: number, texto: string) => {

    setConfiguracoesTela([]);
    
    formik.setFieldValue("tipoMarcaId", value);

    formik.setFieldValue("categoriaId", value);
    
    formik.setFieldValue("modulo", EModulo.MarcaEPertences);
    
    formik.setFieldValue("tela", ETelaEnum.TelaCadastroMarcasEPertences);

    setTipoMarca({
      tipoMarcaId: value,
      nome: texto,
      codigo: "",
      status: "",
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />
      <LayoutPageForButton title={`Novo Marca ${tipoMarca.tipoMarcaId > 0 ? " - " + tipoMarca.nome : ""}`}>
        {marca.marcaId > 0 ? (
          <>
            <button onClick={() => navigate("/Marca/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{marca.marcaId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoMarcaId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarMarcaLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarMarcaLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarMarcaLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposMarca ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de marca...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Marca
            </label>
            <select
              name="tipoMarcaId"
              value={tipoMarca.tipoMarcaId}
              className={"form-select"}
              onChange={(event: any) => onChangeTipoMarca(event.target.value, event.target[event.target.selectedIndex].text)}
              placeholder="Nome"
              id="form-area"
            >
              <option value="0"> Selecione um tipo de marca</option>
              {tiposMarca.map((tipoMarca) => {
                return (
                  <option key={tipoMarca.tipoMarcaId} value={tipoMarca.tipoMarcaId}>
                    {tipoMarca.nome}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default MarcaCadastroPage;
