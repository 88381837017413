import { Context, createContext,  ReactNode } from "react";
import { boolean } from "yup";
import Dashboard from "./Dashboard";


export interface DashboardContextoProps {
  instancia: Dashboard<any>;
  editando: boolean;
}

const DashboardContexto = createContext<DashboardContextoProps | null>(null);

const DashboardContextoProvider = ( props: {children: ReactNode[]|ReactNode, props:DashboardContextoProps}) => {
  return (
    <DashboardContexto.Provider value={props.props}>
        {props.children}
    </DashboardContexto.Provider>
  );
};

export { DashboardContexto, DashboardContextoProvider };
