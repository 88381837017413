import IXPayContrato from "../interfaces/IXPayContrato";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import api from "./Api";
import FiltroPesquisaXPayContratoRequest from "../interfaces/Requests/XPayContrato/FiltroPesquisaXCampoRequest";
import AdicionaXPayContratoRequest from "../interfaces/Requests/XPayContrato/AdicionaXPayContratoRequest";
import IXpayContratoAgrupadores from "../interfaces/Requests/XPayContrato/IXpayContratoAgrupadores";


class XPayContratoService {

  async obterXPayContrato(filtros: FiltroPesquisaXPayContratoRequest): Promise<IRecuperaLista<IXPayContrato>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}&isXPayContrato=true`;

    if (filtros.nome && filtros.nome && filtros.nome.length) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.modulo.length > 0) {
      for (let index = 0; index < filtros.modulo.length; index++) {
        const modulo = filtros.modulo[index];

        query += `&modulo=${modulo}`;
      }
    }

    if (filtros.escritorioId.length > 0) {
      for (let index = 0; index < filtros.escritorioId.length; index++) {
        const escritorioId = filtros.escritorioId[index];

        query += `&escritorioId=${escritorioId}`;
      }
    }

    if (filtros.status && filtros.status !== 0) {
      query += `&status=${filtros.status}`;
    }


    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }


    let { data: result } = await api.get<IRecuperaLista<IXPayContrato>>(`/XpayContrato/RecuperaXpayContrato?${query}`);

    return result;
  }

  async adicionaXPayContrato(adicionaXPayContrato: AdicionaXPayContratoRequest): Promise<IXPayContrato> {
    let { data: result } = await api.post<IXPayContrato>(`XpayContrato/AdicionaXpayContrato`, adicionaXPayContrato);

    return result;
  }

  async inativaXPayContrato(editarXPayContrato: IXPayContrato): Promise<void> {
    let dataXPayContrato = [
      { op: "replace", path: "/status", value: "-1" },
    ];

    await Promise.all([await api.patch<void>(`XpayContrato/AtualizaXpayContratoPorId?id=${editarXPayContrato.xpayContratoId}`, dataXPayContrato)]);
  }

  async ativaXPayContrato(editarXPayContrato: IXPayContrato): Promise<void> {
    let dataXPayContrato = [
      { op: "replace", path: "/status", value: "1" },
    ];

    await Promise.all([await api.patch<void>(`XpayContrato/AtualizaXpayContratoPorId?id=${editarXPayContrato.xpayContratoId}`, dataXPayContrato)]);
  }

  async atualizaXPayContrato(editarXPayContrato: IXPayContrato): Promise<void> {
    let dataXPayContrato = [
      { op: "replace", path: "/nome", value: editarXPayContrato.nome },
      { op: "replace", path: "/codigo", value: editarXPayContrato.codigo },
      { op: "replace", path: "/modulo", value: editarXPayContrato.modulo },
      { op: "replace", path: "/escritorioId", value: editarXPayContrato.escritorioId },
    ];

    await Promise.all([await api.patch<void>(`XpayContrato/AtualizaXpayContratoPorId?id=${editarXPayContrato.xpayContratoId}`, dataXPayContrato)]);
  }

  async atualizaAgrupadores(atualizaAgrupadores: IXpayContratoAgrupadores): Promise<IXPayContrato> {

    let { data: result } = await api.post<IXPayContrato>(`XpayContrato/AtualizaAgrupadores`, atualizaAgrupadores);

    return result;
  }

}

export default new XPayContratoService();
