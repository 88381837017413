import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const notifyFormikDanger = async (errors: any) => {
  const messages = Object.values(errors);
  messages && messages.length > 0 && await messages.map((message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      delay: 2000,
      progressClassName: "progress-validation",
      icon: <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faExclamation} />
    });
  })
};

export const notifyDanger = async (message: any) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    delay: 2000,
    progressClassName: "progress-validation",
    icon: <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faExclamation} />
  });
};

export const notifyWarning = async (message: any) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
    delay: 2000,
    progressClassName: "progress-validation",
    icon: <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faExclamation} />
  });
};

export const notifySuccess = async (message: any) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    delay: 1000,
    progressClassName: "progress-validation",
    icon: <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faExclamation} />
  });
};