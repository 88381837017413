import api from "./Api";
import AtualizarUsuarioRequest from "../interfaces/Requests/AtualizarUsuarioRequest";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IUsuario from "../interfaces/IUsuario";
import { AdicionaUsuarioRequest } from "../interfaces/Requests/AdicionaUsuarioRequest";
import FiltroPesquisaUsuarioRequest from "../interfaces/Requests/FiltroPesquisaUsuarioRequest";
import { EditarUsuarioRequest } from "../interfaces/Requests/EditarUsuarioRequest";
import { IUploadResponse } from "../interfaces/IUploadResponse";
import { EModulo } from "../enum";

class UsuarioService {
  async obterUsuarios(
    filtros: FiltroPesquisaUsuarioRequest
  ): Promise<IRecuperaLista<IUsuario>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.nomeUsuario) {
      query += `&nomeUsuario=${filtros.nomeUsuario}`;
    }

    if (filtros.clientes.length > 0) {
      for (let index = 0; index < filtros.clientes.length; index++) {
        const clienteId = filtros.clientes[index];

        query += `&clienteId=${clienteId}`;
      }
    }

    if (filtros.email) {
      query += `&email=${filtros.email}`;
    }

    if (filtros.numeroDocumento) {
      query += `&numeroDocumento=${filtros.numeroDocumento}`;
    }

    let { data: result } = await api.get<IRecuperaLista<IUsuario>>(
      `Usuario/RecuperaUsuario?${query}`
    );

    return result;
  }
  async obterListaLimitadaGrupoUsuario(
    modulo?: EModulo,
    categoriaId?: number,
    tipoCompromisoId?: number
  ): Promise<IRecuperaLista<any>> {
    let query = "";

    switch (modulo) {
      case EModulo.AreaDireito:
        query += `&areaDireitoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Contrato:
        query += `&tipoContratoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.MarcaEPertences:
        query += `&tipoMarcaId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Imovel:
        query += `&tipoImovelId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Regulatorio:
        query += `&tipoLicencaId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Consultivo:
        query += `&tipoConsultaId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Procuracao:
        query += `&tipoProcuracaoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Societario:
        query += `&tipoSociedadeId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.AtoSocietario:
        query += `&tipoAtoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Compromisso:
        query += `&fluxoFaseId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Despesa:
        query += `&tipoDespesaId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Garantia:
        query += `&tipoGarantiaId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Protocolo:
        query += `&tipoProtocoloId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Bloqueio:
        query += `&tipoBloqueioId=${categoriaId}`;
        break;

      case EModulo.Decisao:
        query += `&tipoDecisaoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Desdobramento:
        query += `&tipoDesdobramentoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.PropostaAcordo:
        query += `&tipoPropostaAcordoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Obrigacao:
        query += `&tipoObrigacaoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Comunicacao:
        query += `&tipoComunicacaoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.XpayHonorario:
        query += `&tipoHonorarioId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.XpayReembolso:
        query += `&tipoReembolsoId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      case EModulo.Audiencia:
        query += `&tipoAudienciaId=${categoriaId}&tipoCompromisoId=${tipoCompromisoId}`;
        break;

      default:
        "";
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `GrupoUsuario/RecuperaListaLimitada?modulo=${EModulo.Compromisso}${query}`
    );

    return result;
  }

  async obterUsuariosCompartilhados(
    filtros: FiltroPesquisaUsuarioRequest
  ): Promise<IRecuperaLista<IUsuario>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query += `&usuarioId=${filtros.usuarioId}`;

    let { data: result } = await api.get<IRecuperaLista<IUsuario>>(
      `CompromissoUsuarioCompartilhado/RecuperaUsuarioCompartilhado?${query}`
    );

    return result;
  }

  async obterUsuariosFeeds(
    filtros: FiltroPesquisaUsuarioRequest
  ): Promise<IRecuperaLista<IUsuario>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    let { data: result } = await api.get<IRecuperaLista<IUsuario>>(
      `CompromissoUsuarioCompartilhado/RecuperaUsuariosCompartilhados?${query}`
    );

    return result;
  }

  async adicionaUsuarioCompartilhado(
    usuarioSelected: any,
    usuarioId: number
  ): Promise<IUsuario> {
    let request: any = {};

    request.usuarioCompartilhadoId = [];

    request.usuarioId = usuarioId;

    usuarioSelected.forEach((usuario: any) => {
      request.usuarioCompartilhadoId.push(usuario.value);
    });

    let { data: result } = await api.post<IUsuario>(
      `CompromissoUsuarioCompartilhado/AdicionaUsuarioCompartilhado`,
      request
    );

    return result;
  }

  async obterGrupoUsuarios(
    filtros: FiltroPesquisaUsuarioRequest
  ): Promise<IRecuperaLista<IUsuario>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    let { data: result } = await api.get<IRecuperaLista<IUsuario>>(
      `compromissoUsuarioCompartilhado/RecuperaGrupoUsuariosCompartilhados?${query}`
    );

    return result;
  }

  async obterClientesPorEscritorio(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<IUsuario>>(
      `Usuario/RecuperaClientesByEscritorio`
    );

    return result;
  }

  async adicionaUsuario(
    adicionaUsuario: AdicionaUsuarioRequest
  ): Promise<IUsuario> {
    let { data: result } = await api.post<IUsuario>(
      `Usuario/AdicionaUsuario`,
      adicionaUsuario
    );

    return result;
  }

  async atualizarPerfil(usuario: AtualizarUsuarioRequest): Promise<void> {
    let data = [
      { op: "replace", path: "/nomeUsuario", value: usuario.nomeUsuario },
      { op: "replace", path: "/corTemplate", value: usuario.corTemplate },
      { op: "replace", path: "/modoExibicao", value: usuario.modoExibicao.toString() },
    ];

    await api.patch<void>(`Usuario/AtualizaPreferenciasUsuario`, data);
  }

  async alterarStatus(usuarioId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<IUsuario>(`Usuario/AtualizaUsuario?id=${usuarioId}`, data);
  }

  async upload(
    parameter: "photo" | "seal",
    file: File,
    userId: number
  ): Promise<IUploadResponse> {
    const paths = {
      photo: "UploadFoto",
      seal: "UploadChancela",
    };
    const path = paths[parameter];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("usuarioIdCadastro", String(userId));

    const response = await api.post<IUploadResponse>(
      `Usuario/${path}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return response.data;
  }

  async atualizarUsuario(editarUsuario: EditarUsuarioRequest): Promise<void> {
    let dataUsuario: any[] = [];

    dataUsuario.push({
      op: "replace",
      path: "/clienteId",
      value: editarUsuario.clienteId,
    });
    dataUsuario.push({
      op: "replace",
      path: "/email",
      value: editarUsuario.email,
    });
    dataUsuario.push({
      op: "replace",
      path: "/nomeUsuario",
      value: editarUsuario.nomeUsuario,
    });
    dataUsuario.push({
      op: "replace",
      path: "/codigo",
      value: editarUsuario.codigo,
    });
    dataUsuario.push({
      op: "replace",
      path: "/usuarioTipo",
      value: editarUsuario.usuarioTipo,
    });
    if (editarUsuario?.escritorioId > 0)
      dataUsuario.push({
        op: "replace",
        path: "/escritorioId",
        value: editarUsuario.escritorioId,
      });
    dataUsuario.push({
      op: "replace",
      path: "/exibirProcessoRestrito",
      value: editarUsuario.exibirProcessoRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/exibirContratoRestrito",
      value: editarUsuario.exibirContratoRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/exibirDocumentoRestrito",
      value: editarUsuario.exibirDocumentoRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/exibirLicencaRestrito",
      value: editarUsuario.exibirLicencaRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/exibirConsultaRestrito",
      value: editarUsuario.exibirConsultaRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/exibirImovelRestrito",
      value: editarUsuario.exibirImovelRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/exibirProcuracaoRestrito",
      value: editarUsuario.exibirProcuracaoRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/exibirMarcaRestrito",
      value: editarUsuario.exibirMarcaRestrito,
    });
    dataUsuario.push({
      op: "replace",
      path: "/alterarCompromissosDeTerceiros",
      value: editarUsuario.alterarCompromissosDeTerceiros,
    });
    dataUsuario.push({
      op: "replace",
      path: "/PerfilUsuarioIds",
      value: editarUsuario.alterarPerfis,
    });

    let dataPessoa = [
      { op: "replace", path: "/nome", value: editarUsuario.nomePessoa },
      { op: "replace", path: "/nomeSocial", value: editarUsuario.nomeSocial },
    ];

    await Promise.all([
      await api.patch<void>(
        `Usuario/AtualizaUsuario?id=${editarUsuario.usuarioId}`,
        dataUsuario
      ),
      await api.patch<void>(
        `Pessoa/AtualizaPessoaPorId?id=${editarUsuario.pessoaId}`,
        dataPessoa
      ),
    ]);
  }

  async obterUnicoUsuario(usuarioId: number) {
    let { data: result } = await api.get<IRecuperaLista<IUsuario>>(
      `Usuario/RecuperaUsuario?usuarioId=${usuarioId}`
    );

    return result;
  }
}

export default new UsuarioService();
