import { useSlateStatic } from "slate-react";
import { Menu } from "../Menu";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Container, Content } from "./styles";
import { controls } from "./controls";
import { ActiveControl, Block, ControlKey } from "./controls/types";
import {
  getConventionalComponent,
  getPopoverComponent,
} from "./controls/components";
import { commentHandler } from "./controls/comment/handler";
import { markHandler } from "./controls/mark/handler";
import { MarkOption } from "./controls/mark";
import { alignmentHandler } from "./controls/alignment/handler";
import { AlignmentOption } from "./controls/alignment";
import { FontContext } from "../../context";
import { listHandler } from "./controls/list/handler";
import { ListOption } from "./controls/list";
import { taskHandler } from "./controls/task/handler";
import { TaskOption } from "./controls/task";
import { Editor } from "slate";
import { CustomText, Mark } from "../../../../utils/types/slate";
import { useReactToPrint } from "react-to-print";
import { TextIndentationOption, indentHandler } from "./controls/indentation";
import { AddCommentModal } from "../Comments/Modal";

type ToolbarProps = {
  editorRef: any;
  toggleComments: () => void;
};

export const Toolbar = (props: ToolbarProps) => {
  const editor = useSlateStatic();
  const { currentStyles, setCurrentStyles } = useContext(FontContext);
  const [activeControls, setActiveControls] = useState<ActiveControl[]>([]);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => props.editorRef.current,
  });

  const alignmentCallback = (block: Block) => {
    const isActive = currentStyles.textAlign === block.type;

    setCurrentStyles((prev) => ({
      ...prev,
      textAlign: isActive ? "start" : (block.type as AlignmentOption),
    }));

    const alignmentOptions: AlignmentOption[] = [
      "left",
      "center",
      "right",
      "start",
      "justify",
    ];

    const alignmentOptionsWithoutSelected = alignmentOptions.filter(
      (option) => option !== block.type
    );

    alignmentOptionsWithoutSelected.forEach(selectControl);
    disableControl(block.type);
  };

  const selectControl = (control: ActiveControl) => {
    setActiveControls((prev) => prev.filter((current) => current !== control));
  };

  const disableControl = (control: ActiveControl) => {
    setActiveControls((prevState) => {
      if (prevState.includes(control)) {
        return prevState.filter((state) => state !== control);
      }
      return [...prevState, control];
    });
  };

  const listCallback = (block: Block) => {
    const listOptions: ListOption[] = ["bulleted-list", "numbered-list"];
    const listOptionsWithoutSelected = listOptions.filter(
      (option) => option !== block.type
    );

    listOptionsWithoutSelected.forEach(selectControl);
    disableControl(block.type);
  };

  const renderBlock = useCallback(
    (control: ControlKey, block: Block) => {
      const action: { [key: string]: () => void } = {
        task: () =>
          taskHandler({
            editor,
            option: block.type as TaskOption,
            print: handlePrint,
          }),
        mark: () => markHandler(editor, block.type as MarkOption),
        alignment: () =>
          alignmentHandler(editor, block.type as AlignmentOption, () =>
            alignmentCallback(block)
          ),
        indentation: () =>
          indentHandler(editor, block.type as TextIndentationOption),
        list: () =>
          listHandler(editor, block.type as ListOption, () =>
            listCallback(block)
          ),
        comment: () =>
          commentHandler(editor, () => {
            setShowCommentModal(true);
          }),
      };

      const isActive = activeControls.includes(block.type);
      const componentBlocks = ["font-size", "font-heading", "font-family"];
      const isComponent = componentBlocks.includes(block.type);

      if (isComponent) {
        return block.component;
      } else if (block.popover) {
        return getPopoverComponent(block, action[control], isActive);
      } else {
        return getConventionalComponent(block, action[control], isActive);
      }
    },
    [editor, activeControls, currentStyles]
  );

  const renderControls = () => {
    const availableControls = Object.keys(controls) as ControlKey[];
    const jsxControls: JSX.Element[] = [];

    availableControls.forEach((control) => {
      const blocks = controls[control];
      blocks.forEach((block) => jsxControls.push(renderBlock(control, block)));
    });

    return jsxControls;
  };

  const handleSelectionChange = useCallback(() => {
    const { selection } = editor;

    if (!selection) {
      setActiveControls([]);
      return;
    }

    const [node] = Editor.node(editor, selection.anchor);
    const element = node as CustomText;

    const marks: Mark = {
      bold: element.marks?.bold ?? false,
      italic: element.marks?.italic ?? false,
      underline: element.marks?.underline ?? false,
      strikethrough: element.marks?.strikethrough ?? false,
      highlight: element.marks?.highlight ?? "",
    };

    Object.keys(marks).forEach((key) => {
      const typedKey = key as keyof Mark;
      const value = marks[typedKey];
      if (value) {
        setActiveControls((prev) => [...prev, typedKey as ActiveControl]);
      } else {
        setActiveControls((prev) =>
          prev.filter((state) => state !== (typedKey as ActiveControl))
        );
      }
    });
  }, [editor]);

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [handleSelectionChange]);

  return (
    <Fragment>
      <AddCommentModal
        show={showCommentModal}
        onHide={() => setShowCommentModal((prev) => !prev)}
      />

      <Container>
        <Menu toggleComments={props.toggleComments} editorRef={props.editorRef} />
        <Content>{renderControls()}</Content>
      </Container>
    </Fragment>
  );
};
