import { Popover } from "react-bootstrap";
import { MdOutlineFormatColorText, MdOutlineColorize } from "react-icons/md";
import { FontColor } from "../../../Popover/FontColor";
import { FontColorControl } from "./types";

export const fontColor: FontColorControl[] = [
  {
    type: "text-color",
    component: <MdOutlineFormatColorText />,
    popover: () => {
      return (
        <Popover id="popover-positioned-bottom" title="Selecionar cor">
          <FontColor caller="text-color" />
        </Popover>
      );
    },
  },
  {
    type: "background-color",
    component: <MdOutlineColorize />,
    popover: () => {
      return (
        <Popover id="popover-positioned-bottom" title="Selecionar cor">
          <FontColor caller="background-color" />
        </Popover>
      );
    },
  },
];