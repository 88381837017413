
import { useLayoutEffect, useState } from "react";
import * as Yup from 'yup';
import clsx from "clsx";
import Swal from "sweetalert2";
import { useFormik } from 'formik';
import { Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EModulo } from '../../enum';

import XPayContratoService from '../../services/XPayContratoService';
import IXPayContrato from '../../interfaces/IXPayContrato';
import AdicionaXPayContratoRequest from "../../interfaces/Requests/XPayContrato/AdicionaXPayContratoRequest";
import IEscritorio from "../../interfaces/IEscritorio";
import FiltroPesquisaEscritorioRequest from "../../interfaces/Requests/Escritorio/FiltroPesquisaEscritorioRequest";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import EscritorioService from "../../services/EscritorioService";

interface IXPayContratoModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    XPayContrato?: IXPayContrato,
    titulo: string
}

const XPayContratoModal = ({ toggleModal, exibirModal, titulo }: IXPayContratoModalProps) => {

    const [escritorio, setEscritorio] = useState<IEscritorio[]>([]);
    const [, setCarregandoEscritorio] = useState(false);
    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEscritorioRequest>({
      nome: "",
      codigo: "",
      clienteId: 0,
      isTelaPesquisaProcesso: true,
      limit: 100,
      totalItems: 0,
      offset: 0,
      sort: "escritorioId",
    });
    
    /* useEffect(() => {
         const montarForm = async () => {
             await formik.resetForm();
 
             if (XPayContrato) {
                 await formik.setValues(XPayContrato);
             }
 
             await formik.validateForm();
         }
 
         montarForm();
 
     }, [exibirModal, XPayContrato]);*/

    const schema = Yup.object().shape({
        nome: Yup.string().min(3, "Mínimo é obrigatório").max(100, 'Maximo 100 caracteres').required('Nome é obrigatório'),
        modulo: Yup.number().min(1, "Selecione um módulo").required("Módulo é obrigatório"),
        escritorioId: Yup.number().min(1, "Selecione um escritório").required("Escritório é obrigatório"),
    });


    const valoresIniciais: AdicionaXPayContratoRequest = {
        codigo: "",
        nome: "",
        modulo: EModulo.Default,
        escritorioId: 0
    };

    async function carregarEscritorio(filtro: FiltroPesquisaEscritorioRequest) {
        try {
          setCarregandoEscritorio(true);
          let resultado: IRecuperaLista<IEscritorio>;
    
          resultado = await EscritorioService.obterEscritorios(filtro);
    
          setFiltrosPesquisa((oldState) => {
            return { ...oldState, totalItems: resultado.totalRegistros };
          });
          setEscritorio(resultado.data);
        } catch (error: any) {
          Swal.fire({
            heightAuto: false,
            title: "Não foi possível obter registros",
            text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
            timer: 4000,
            icon: "error",
            showConfirmButton: false,
          });
          setEscritorio([]);
        } finally {
          setCarregandoEscritorio(false);
        }
      }

      useLayoutEffect(() => {
        carregarEscritorio(filtrosPesquisa);
      }, []);

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await XPayContratoService.adicionaXPayContrato(values);
                await Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `XPayContrato cadastrado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                });

                setSubmitting(false);

                toggleModal(true);
            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel editar o XPayContrato`,
                    text: error?.response?.data?.Message,
                    showConfirmButton: true
                });
            }
        }
    });


    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo} "-" {formik.values.modulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-2">

                        <div className="col-md-4 mb-2">
                            <label htmlFor="form-label" className="form-label required fw-bolder text-orange">Nome:</label>
                            <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.nome && formik.errors.nome,
                                },
                                {
                                    'is-valid': formik.touched.nome && !formik.errors.nome,
                                }
                            )} id="form-nome" />
                            {formik.touched.nome && formik.errors.nome && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.nome}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-md-4 mt-2">
                            <label htmlFor="form-escritorioId" className="form-label required fw-bolder text-orange">
                                Escritório:
                            </label>
                            <select
                                {...formik.getFieldProps("escritorioId")}
                                value={formik.values.escritorioId}
                                onChange={(e) => {
                                    formik.setFieldTouched("escritorioId", true);
                                    formik.setFieldValue("escritorioId", parseInt(e.target.value));
                                }}
                                className={clsx(
                                    "form-select",
                                    {
                                        "is-invalid": formik.touched.escritorioId && formik.errors.escritorioId,
                                    },
                                    {
                                        "is-valid": formik.touched.escritorioId && !formik.errors.escritorioId,
                                    }
                                )}
                                id="form-escritorioId"
                            >
                                <option value="0"> Selecione uma opção</option>
                                {escritorio.map((map) => {
                                    return (
                                        <option key={map.escritorioId} value={map.escritorioId}>
                                            {" "}
                                            {map.nome}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div className="col-md-4 mb-2">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código:</label>
                            <input {...formik.getFieldProps('codigo')} placeholder="Código" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.codigo && formik.errors.codigo,
                                },
                                {
                                    'is-valid': formik.touched.codigo && !formik.errors.codigo,
                                }
                            )} id="form-codigo" />
                            {formik.touched.codigo && formik.errors.codigo && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.codigo}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-md-4 mb-2">
                            <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                                Módulo:
                            </label>
                            <select
                                value={formik.values.modulo.toString()}
                                onChange={(e) => {
                                    formik.setFieldTouched("modulo", true);
                                    formik.setFieldValue("modulo", e.target.value.toString(), true);
                                    formik.validateField("modulo");
                                }}
                                className={"form-select"}
                                id="form-select"
                            >
                                <option value="0" label="Selecione o Modulo">
                                    {" "}
                                    Selecione o Módulo{" "}
                                </option>
                                <option value="1" label="Contencioso">
                                    {" "}
                                    Contencioso{" "}
                                </option>
                                <option value="7" label="Contrato">
                                    {" "}
                                    Contrato{" "}
                                </option>
                                <option value="9" label="Consultivo">
                                    {" "}
                                    Consultivo{" "}
                                </option>
                            </select>
                        </div>

                        <div className="row mt-1">

                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formik.isSubmitting && <> SALVAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default XPayContratoModal;