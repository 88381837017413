import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import Select, { components, MultiValue } from "react-select";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import Storage from "../../../../utils/StorageUtils";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

import IUsuario from "../../../../interfaces/IUsuario";
import UsuarioService from "../../../../services/UsuarioService";
import ClienteService from "../../../../services/ClienteService";
import EscritorioService from "../../../../services/EscritorioService";

import IOption from "../../../../interfaces/IOption";
import IEscritorio from "../../../../interfaces/IEscritorio";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import { EditarUsuarioRequest } from "../../../../interfaces/Requests/EditarUsuarioRequest";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaEscritorioRequest from "../../../../interfaces/Requests/Escritorio/FiltroPesquisaEscritorioRequest";

import { OrangeContext } from "../../../../contexts/OrangeProvider";
import { notifySuccess } from "../../../../components/Comum/Toast/ToastFormik";
import IPerfilLista from "../../../../interfaces/IPerfilLista";
import PerfilService from "../../../../services/PerfilService";

interface IUsuarioModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  usuario: IUsuario;
  titulo: string;
  clientes: IOption[];
}

type SelectPerfilType = {
  label: string;
  value: number;
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <FormCheckInput
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: "10px" }}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const UsuarioModal = ({
  toggleModal,
  exibirModal,
  usuario,
  titulo,
  clientes,
}: IUsuarioModalProps) => {
  const [exibeRestricoes, setExibeRestricoes] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUrlLogo } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [escritorios, setEscritorios] = useState<IEscritorio[]>([]);
  const [perfisTotal, setPerfisTotal] = useState<SelectPerfilType[]>([]);
  const [perfisUsuario, setPerfisUsuario] = useState<
    MultiValue<SelectPerfilType>
  >([]);
  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaEscritorioRequest>({
      clienteId: 0,
      nome: "",
      codigo: "",
      limit: 1000,
      isTelaPesquisaProcesso: false,
      totalItems: 0,
      offset: 0,
      sort: "escritorioId",
    });

  useEffect(() => {
    if (usuario && exibirModal) {
      formik.setValues({
        ...usuario,
        nomePessoa: usuario.nomePessoa || "",
        codigo: usuario.codigo || "",
        atualizaEscritorio: true,
        usuarioTipo:
          usuario.usuarioTipo === "Empresa"
            ? 1
            : usuario.usuarioTipo === "Escritorio"
              ? 2
              : 3,
        escritorioId: usuario.escritorioId,
        Tipo: "",
        alterarPerfis: [],
      });
      carregarEscritorios(filtrosPesquisa);
    }
    // eslint-disable-next-line
  }, [exibirModal, usuario.pessoaId]);

  useEffect(() => {
    carregarEscritorios(filtrosPesquisa);
    carregarPerfis();
  }, [exibirModal, usuario]);

  useEffect(() => {
    formik.setFieldTouched("alterarPerfis", false);
  }, [exibirModal]);

  const usuarioValidacoes = Yup.object().shape({
    usuarioId: Yup.number().min(1, "Selecione um Usuario").required(),
    clienteId: Yup.number()
      .min(1, "Selecione um Cliente")
      .required("Selecione um Cliente"),
    pessoaId: Yup.number().min(1, "Selecione uma Pessoa").required(),
    nomePessoa: Yup.string()
      .min(4, "Mínimo 4 caracteres")
      .required("Nome é obrigatório"),
    nomeSocial: Yup.string()
      .min(4, "Mínimo 4 caracteres")
      .required("Nome Social é obrigatório"),
    nomeUsuario: Yup.string()
      .min(4, "Mínimo 4 caracteres")
      .required("Nome do Usuário é obrigatório"),
    email: Yup.string()
      .email("Digite um email válido")
      .max(200, "Maximo 200 caracteres")
      .required("Email é obrigatório"),
    codigo: Yup.string().max(50, "Maximo 50 caracteres"),
    usuarioTipo: Yup.string().required("Nome é obrigatório"),
    alterarPerfis: Yup.array().min(1, "Pelo menos um perfil é necessário"),
  });

  const valoresIniciais: EditarUsuarioRequest = {
    usuarioId: usuario.usuarioId,
    clienteId: usuario.clienteId,
    usuarioTipo:
      usuario.usuarioTipo === "Xjur"
        ? 3
        : usuario.usuarioTipo === "Escritorio"
          ? 2
          : 1,
    escritorioId: usuario.escritorioId,
    pessoaId: usuario.pessoaId,
    codigo: usuario.codigo,
    email: usuario.email,
    nomePessoa: usuario.nomePessoa,
    nomeSocial: usuario.nomeSocial,
    nomeUsuario: usuario.nomeUsuario,
    atualizaEscritorio: true,
    Tipo: "",
    exibirProcessoRestrito: false,
    exibirContratoRestrito: false,
    exibirDocumentoRestrito: false,
    exibirLicencaRestrito: false,
    exibirConsultaRestrito: false,
    exibirImovelRestrito: false,
    exibirProcuracaoRestrito: false,
    exibirMarcaRestrito: false,
    alterarCompromissosDeTerceiros: false,
    alterarPerfis: usuario.perfisUsuario.map(
      (perfil) => perfil.perfilUsuarioId
    ),
  };

  const formik = useFormik({
    initialValues: valoresIniciais,
    validationSchema: usuarioValidacoes,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let user = await Storage.getUser();

        await UsuarioService.atualizarUsuario({
          ...values,
          alterarPerfis: perfisUsuario.map((perfil) => perfil.value),
        });

        if (values.usuarioId == user?.id) {
          let urlLogo = (
            await ClienteService.obterPorId(values.clienteId)
          ).data.find((e) => e)?.urlLogo;

          if (user && urlLogo) {
            setUrlLogo(urlLogo);
          }
        }

        setSubmitting(false);

        notifySuccess("Usuário editado com sucesso");

        toggleModal(true);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar o Usuário`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    },
  });

  const carregarEscritorios = async (
    filtro: FiltroPesquisaEscritorioRequest
  ) => {
    try {
      let resultado: IRecuperaLista<IEscritorio>;
      resultado = await EscritorioService.obterEscritorios(filtro);
      setEscritorios(resultado.data);

      setFiltrosPesquisa({
        ...filtrosPesquisa,
        totalItems: resultado.totalRegistros,
      });
    } catch (error: any) {
      setEscritorios([]);
    }
  };

  const carregarPerfis = useCallback(
    async (clienteId?: number) => {
      try {
        setLoading(true);

        const response: IPerfilLista = await PerfilService.obterPerfis({
          nome: "",
          status: "0",
          sort: "nome",
          offset: 0,
          limit: 0,
          totalItems: 0,
          clienteId,
        });

        const mappedTotalPerfis = response.data.map((perfil) => {
          return {
            value: perfil.perfilUsuarioId,
            label: perfil.nome,
          };
        });

        const mappedUsuarioPerfis = usuario.perfisUsuario.map((perfil) => {
          return {
            value: perfil.perfilUsuarioId,
            label: perfil.nome,
          };
        });

        setPerfisUsuario(mappedUsuarioPerfis);
        setPerfisTotal(mappedTotalPerfis);
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          title: "Não foi possível obter registros",
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          timer: 4000,
          icon: "error",
          showConfirmButton: false,
        });
      } finally {
        setLoading(false);
      }
    },
    [exibirModal]
  );

  return (
    <>
      <ToastContainer />
      <Modal
        size="lg"
        centered={true}
        show={exibirModal}
        onHide={() => toggleModal(true)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}</h5>
            <div
              onClick={() => toggleModal()}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              <div className="row mt-2">
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="form-clienteTipo"
                    className="form-label required text-orange fw-bolder"
                  >
                    Tipo de Usuário:
                  </label>
                  <select
                    {...formik.getFieldProps("usuarioTipo")}
                    value={formik.values.usuarioTipo}
                    onChange={(e) => {
                      formik.setFieldTouched("usuarioTipo", true);
                      formik.setFieldValue(
                        "usuarioTipo",
                        parseInt(e.target.value),
                        true
                      );
                      formik.validateField("usuarioTipo");
                    }}
                    className={"form-select"}
                  >
                    <option value="" label="Selecione">
                      {" "}
                      Selecione{" "}
                    </option>
                    <option value="1" label="Empresa">
                      {" "}
                      Empresa{" "}
                    </option>
                    <option value="2" label="Escritorio">
                      {" "}
                      Escritorio{" "}
                    </option>
                    <option value="3" label="XJur">
                      {" "}
                      XJur{" "}
                    </option>
                  </select>
                </div>

                {formik.values.usuarioTipo == "2" && (
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="form-clienteTipo"
                      className="form-label required text-orange fw-bolder"
                    >
                      Escritório:
                    </label>
                    <select
                      {...formik.getFieldProps("escritorioId")}
                      value={formik.values.escritorioId}
                      name="escritorioId"
                      onChange={(e) => {
                        formik.setFieldTouched("escritorioId", true);
                        formik.setFieldValue(
                          "escritorioId",
                          e.target.value.toString()
                        );
                      }}
                      className={"form-control"}
                    >
                      {escritorios.map((map) => {
                        return (
                          <option
                            key={map.escritorioId}
                            value={map.escritorioId}
                          >
                            {" "}
                            {map.nome}{" "}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>
              <hr />
              <div className="row mt-2">
                <div className="col-md-6">
                  <label
                    htmlFor="form-nomeUsuario"
                    className="form-label required fw-bolder text-orange"
                  >
                    Nome do Usuário:
                  </label>
                  <input
                    {...formik.getFieldProps("nomeUsuario")}
                    placeholder="Nome Usuario"
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.nomeUsuario &&
                          formik.errors.nomeUsuario,
                      },
                      {
                        "is-valid":
                          formik.touched.nomeUsuario &&
                          !formik.errors.nomeUsuario,
                      }
                    )}
                    id="form-nomeUsuario"
                  />
                  {formik.touched.nomeUsuario && formik.errors.nomeUsuario && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.nomeUsuario}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="form-email"
                    className="form-label required fw-bolder text-orange"
                  >
                    E-mail:
                  </label>
                  <input
                    {...formik.getFieldProps("email")}
                    placeholder="Email"
                    type="email"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.email && formik.errors.email,
                      },
                      {
                        "is-valid":
                          formik.touched.email && !formik.errors.email,
                      }
                    )}
                    id="form-email"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <label
                    htmlFor="form-cliente"
                    className="form-label fw-bolder text-orange"
                  >
                    Cliente:
                  </label>
                  <Select
                    value={{
                      label: clientes.find(
                        (e: IOption) => e.value === formik.values.clienteId
                      )?.label,
                      value: formik.values.clienteId,
                    }}
                    options={clientes}
                    onChange={async (option: any) => {
                      formik.setFieldTouched("clienteId", true);
                      formik.setFieldValue("clienteId", parseInt(option.value));

                      usuario.perfisUsuario.length = 0;
                      formik.setFieldTouched("alterarPerfis", true);
                      formik.setFieldValue("alterarPerfis", []);

                      carregarPerfis(option.value);
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="form-codigo"
                    className="form-label fw-bolder text-orange"
                  >
                    Código:
                  </label>
                  <input
                    {...formik.getFieldProps("codigo")}
                    placeholder="Código"
                    type="codigo"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.codigo && formik.errors.codigo,
                      },
                      {
                        "is-valid":
                          formik.touched.codigo && !formik.errors.codigo,
                      }
                    )}
                    id="form-codigo"
                  />
                  {formik.touched.codigo && formik.errors.codigo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.codigo}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <label
                    htmlFor="form-nomePessoa"
                    className="form-label required fw-bolder text-orange"
                  >
                    Nome Pessoa:
                  </label>
                  <input
                    {...formik.getFieldProps("nomePessoa")}
                    placeholder="Nome Usuario"
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.nomePessoa && formik.errors.nomePessoa,
                      },
                      {
                        "is-valid":
                          formik.touched.nomePessoa &&
                          !formik.errors.nomePessoa,
                      }
                    )}
                    id="form-nomePessoa"
                  />
                  {formik.touched.nomePessoa && formik.errors.nomePessoa && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.nomePessoa}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="form-nomeSocial"
                    className="form-label required fw-bolder text-orange"
                  >
                    Nome Social
                  </label>
                  <input
                    {...formik.getFieldProps("nomeSocial")}
                    placeholder="nomeSocial"
                    type="nomeSocial"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.nomeSocial && formik.errors.nomeSocial,
                      },
                      {
                        "is-valid":
                          formik.touched.nomeSocial &&
                          !formik.errors.nomeSocial,
                      }
                    )}
                    id="form-nomeSocial"
                  />
                  {formik.touched.nomeSocial && formik.errors.nomeSocial && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.nomeSocial}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <label
                    htmlFor="form-perfis"
                    className="form-label fw-bolder text-orange"
                  >
                    Perfis:
                  </label>
                  <Select
                    options={perfisTotal}
                    isMulti
                    isSearchable
                    components={{
                      Option,
                    }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    placeholder="Selecione os perfis para o usuário"
                    isLoading={loading}
                    onChange={(select) => {
                      formik.setFieldTouched("alterarPerfis", true);
                      formik.setFieldValue(
                        "alterarPerfis",
                        select.map((perfil) => {
                          return perfil.value;
                        })
                      );
                      setPerfisUsuario(select);
                    }}
                    value={perfisUsuario}
                  />

                  {formik.touched.alterarPerfis &&
                    formik.errors.alterarPerfis && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.alterarPerfis}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div>
                <hr />
                <div className="row mt-3">
                  <div className="col-md-6 mt-3">
                    <label
                      className="form-label fw-bolder text-orange"
                      onClick={() => setExibeRestricoes(!exibeRestricoes)}
                      style={{ cursor: "pointer" }}
                    >
                      Restrições de Acesso:
                      <FontAwesomeIcon
                        color={"var(--primary-base2)"}
                        className="mx-3"
                        icon={exibeRestricoes ? faMinus : faPlus}
                      />
                    </label>
                    <div
                      className="row"
                      style={{ display: exibeRestricoes ? "block" : "none" }}
                    >
                      <div className="col-md-12 mt-3">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="text-orange">Acessos</th>
                              <th className="text-orange">Opção</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-orange">
                                Exibir Processos Restritos:{" "}
                              </td>
                              <td>
                                <FormCheckInput
                                  type="checkbox"
                                  checked={formik.values.exibirProcessoRestrito}
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirProcessoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirProcessoRestrito: false,
                                      });
                                    if (!formik.values.exibirProcessoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirProcessoRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                Exibir Contratos Restritos:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={formik.values.exibirContratoRestrito}
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirContratoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirContratoRestrito: false,
                                      });
                                    if (!formik.values.exibirContratoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirContratoRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                Exibir Documentos Restritos:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={
                                    formik.values.exibirDocumentoRestrito
                                  }
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirDocumentoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirDocumentoRestrito: false,
                                      });
                                    if (!formik.values.exibirDocumentoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirDocumentoRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                Exibir Licenças Restritas:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={formik.values.exibirLicencaRestrito}
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirLicencaRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirLicencaRestrito: false,
                                      });
                                    if (!formik.values.exibirLicencaRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirLicencaRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                Exibir Consultas Restritas:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={formik.values.exibirConsultaRestrito}
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirConsultaRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirConsultaRestrito: false,
                                      });
                                    if (!formik.values.exibirConsultaRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirConsultaRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                Exibir Imoveis Restritos:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={formik.values.exibirImovelRestrito}
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirImovelRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirImovelRestrito: false,
                                      });
                                    if (!formik.values.exibirImovelRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirImovelRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                {" "}
                                Exibir Procurações Restritas:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={
                                    formik.values.exibirProcuracaoRestrito
                                  }
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirProcuracaoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirProcuracaoRestrito: false,
                                      });
                                    if (!formik.values.exibirProcuracaoRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirProcuracaoRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                {" "}
                                Exibir Marcas Restritas:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={formik.values.exibirMarcaRestrito}
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (formik.values.exibirMarcaRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirMarcaRestrito: false,
                                      });
                                    if (!formik.values.exibirMarcaRestrito)
                                      formik.setValues({
                                        ...formik.values,
                                        exibirMarcaRestrito: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row" className="text-orange">
                                {" "}
                                Alterar Compromissos de Terceiros:{" "}
                              </td>
                              <td>
                                {" "}
                                <FormCheckInput
                                  type="checkbox"
                                  checked={
                                    formik.values.alterarCompromissosDeTerceiros
                                  }
                                  style={{
                                    backgroundColor: "var(--primary-base2)",
                                    borderColor: "var(--primary-base2)",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    if (
                                      formik.values
                                        .alterarCompromissosDeTerceiros
                                    )
                                      formik.setValues({
                                        ...formik.values,
                                        alterarCompromissosDeTerceiros: false,
                                      });
                                    if (
                                      !formik.values
                                        .alterarCompromissosDeTerceiros
                                    )
                                      formik.setValues({
                                        ...formik.values,
                                        alterarCompromissosDeTerceiros: true,
                                      });
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            {clientes &&
              clientes.length > 0 &&
              clientes.filter(
                (e) => e.value === formik.values.clienteId
              )[0] && (
                <button
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => {
                    formik.submitForm();
                  }}
                  type="button"
                  className="btn btn-orange me-5"
                >
                  {!formik.isSubmitting && <> SALVAR </>}
                  {formik.isSubmitting && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Salvando...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              )}

            <button
              disabled={formik.isSubmitting}
              onClick={() => toggleModal(true)}
              type="button"
              className="btn btn-danger ms-5"
            >
              {" "}
              CANCELAR{" "}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UsuarioModal;
