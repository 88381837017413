import React, { useContext, createContext, useMemo, useState } from "react";

export type RecorrenciaOption =
  | "none"
  | "daily"
  | "weekly"
  | "monthly"
  | "yearly";

export interface Recorrencia {
  value: RecorrenciaOption;
  title: string;
}

interface RecorrenciaContextContract {
  recorrencia: Recorrencia;
  setRecorrencia: React.Dispatch<React.SetStateAction<Recorrencia>>;

  showRecorrenciaModal: boolean;
  setShowRecorrenciaModal: React.Dispatch<React.SetStateAction<boolean>>;

  datesToAdd: string[];
  setDatesToAdd: React.Dispatch<React.SetStateAction<string[]>>;
}

const RecorrenciaContext = createContext({} as RecorrenciaContextContract);

interface RecorrenciaProviderProps {
  children: React.ReactNode;
}

export const recorrenciaOptions: Recorrencia[] = [
  { value: "none", title: "Não repetir" },
  { value: "daily", title: "Diariamente" },
  { value: "weekly", title: "Semanalmente" },
  { value: "monthly", title: "Mensalmente" },
  { value: "yearly", title: "Anual" },
];

export const RecorrenciaProvider = ({ children }: RecorrenciaProviderProps) => {
  const [recorrencia, setRecorrencia] = useState<Recorrencia>(
    recorrenciaOptions[0]
  );

  const [showRecorrenciaModal, setShowRecorrenciaModal] = useState(false);
  const [datesToAdd, setDatesToAdd] = useState<string[]>([]);

  const value = useMemo(
    () => ({
      recorrencia,
      setRecorrencia,
      showRecorrenciaModal,
      setShowRecorrenciaModal,
      datesToAdd,
      setDatesToAdd,
    }),
    [
      recorrencia,
      setRecorrencia,
      showRecorrenciaModal,
      setShowRecorrenciaModal,
      datesToAdd,
      setDatesToAdd,
    ]
  );

  return (
    <RecorrenciaContext.Provider value={value}>
      {children}
    </RecorrenciaContext.Provider>
  );
};

export const useRecorrencia = () => {
  const context = useContext(RecorrenciaContext);
  return context;
};
