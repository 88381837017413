import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";

import ITipoImovel from "../../interfaces/ITipoImovel";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaImovelRequest } from "../../interfaces/Requests/Imovel/IAdicionaImovelRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ImovelService from "../../services/ImovelService";
import TipoImovelService from "../../services/TipoImovelService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";
import { imovelInitialValues, tipoImovelInicialValues } from "../../contexts/InitialValuesContext";


const ImovelCadastroPage = () => {
  const [carregandoTiposImovel, setCarregandoTiposImovel] = useState<boolean>(false);
  const [tiposImoveis, setTiposImoveis] = useState<ITipoImovel[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { imovel, setImovel, setTipoImovel, tipoImovel } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [adicionarImovelLoading, setAdicionarImovelLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  useLayoutEffect(() => {

    carregarTipoImovel();

    setImovel(imovelInitialValues);

    setTipoImovel(tipoImovelInicialValues);

    setConfiguracoesTela([]);

  }, []);

  useEffect(() => {
    if (tipoImovel.tipoImovelId > 0) carregarConfiguracaoTela();
  }, [tipoImovel.tipoImovelId]);

  const initialValues: IAdicionaImovelRequest = {
    imovelId: 0,
    tipoImovelId: 0,
    modulo: EModulo.Imovel,
    tela: ETelaEnum.Default,
    categoriaId: 0,
    cidadeId: 0,
    departamentoId: 0,
    indiceId: 0,
    tipoaquisicaoId: 0,
    tipolocalidadeId: 0,
    tiporelacionamentoId: 0,
    imovelprincipalId: 0,

    restrito: false,

    codigo: "",
    observacoesimovel: "",
    nomeimovel: "",
    logradouro: "",
    endereco: "",
    numeroendereco: "",
    complemento: "",
    bairro: "",
    cep: "",
    dadosmatricula: "",
    areaconstruida: "",
    metragemterreno: "",
    avaliador: "",

    datainiciovigencia: null,
    datafimvigencia: null,
    dataaquisicao: null,
    datareajuste: null,
    dataavaliacao: null,
    dataconsolidicacao: null,
    dataregistromatricula: null,

    valoraluguel: 0,
    valorcondominio: 0,
    valoriptu: 0,
    valoravaliacao: 0,
    valorcontabil: 0,
    valorvendaforcada: 0,

    empresasList: [],
    partesContrariasList: [],
    advogadoInternoList: [],
    centrosCusto: [],
    xCampoValorList: [],

  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Imóvel",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {

          setAdicionarImovelLoading(true);

          let resultado = await ImovelService.adicionaImovel({
            ...values,
            tipoImovelId: tipoImovel.tipoImovelId,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });

          setImovel(resultado);
          setTipoImovel(tipoImovel);
          formik.resetForm();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Imovel cadastrado com sucesso`,
            timer: 4000,
          });
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        })
      }
      finally {
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarImovelLoading(false);
      }
    },
  });


  const carregarTipoImovel = async () => {
    try {
      setCarregandoTiposImovel(true);
      let resultado = await TipoImovelService.obterTipoImoveis({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTiposImoveis(resultado.data);
      setCarregandoTiposImovel(false);
    } catch (error: any) {
      setCarregandoTiposImovel(false);
      setTiposImoveis([]);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);
      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        TipoImovelId: tipoImovel.tipoImovelId,
        Modulo: EModulo.Imovel,
        Tela: ETelaEnum.TelaCadastroImovel,
      });
      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const onChangeTipoImovel = (value: any, texto: string) => {
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoImovelId", parseInt(value));

    formik.setFieldValue("categoriaId", parseInt(value));

    setTipoImovel({
      tipoImovelId: parseInt(value),
      nome: texto,
      codigo: "",
      status: "",
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Novo Imóvel ${tipoImovel.tipoImovelId > 0 ? " - " + tipoImovel.nome : ""}`}>
        {imovel.imovelId > 0 ? (
          <>
            <button onClick={() => navigate("/Imovel/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{imovel.imovelId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoImovelId || !configuracoesTela?.length ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarImovelLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarImovelLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarImovelLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposImovel ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de imóvel...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Imóvel
            </label>
            <select
              name="tipoImovelId"
              value={tipoImovel.tipoImovelId}
              className={"form-select"}
              onChange={(event: any) => onChangeTipoImovel(event.target.value, event.target[event.target.selectedIndex].text)}
              placeholder="Nome"
              id="form-area"
            >
              <option value="0"> Selecione um tipo de imóvel</option>
              {tiposImoveis.map((tipoImovel) => {
                return (
                  <option key={tipoImovel.tipoImovelId} value={tipoImovel.tipoImovelId}>
                    {tipoImovel.nome}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>

      <hr></hr>

      {
        formik.values.tipoImovelId > 0 && (
          <>
            {renderCampos()}
          </>
        )
      }
    </>
  );
}

export default ImovelCadastroPage;
