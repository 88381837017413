import React, { useMemo, useRef } from "react";
import {
  ReactEditor,
  RenderElementProps,
  useSelected,
  useSlateStatic,
} from "slate-react";
import {
  ActionsContainer,
  ImageComponent,
  ImageContainer,
  ImagePosition,
} from "./styles";
import { ImageElement as ImageEl } from "../../../../../../utils/types/slate";
import { ImageService } from "../../../../lib/slate/services/image";
import EditorService from "../../../../../../services/EditorService";
import Swal from "sweetalert2";
import { ImageActions } from "./components/Actions";
import { CommonService } from "../../../../lib/slate/services/commons";
import { imageConstants } from "./constants/image";

export const ImageElement = (props: RenderElementProps) => {
  const {
    defaultImageUrl,
    editSuccessDescription,
    editSuccessTitle,
    errorMessage,
  } = imageConstants;

  const editor = useSlateStatic();
  const selected = useSelected();
  const imgRef = useRef<HTMLImageElement>(null);
  const imgChooseInputRef = useRef<HTMLInputElement>(null);
  const element = props.element as ImageEl;
  const path = ReactEditor.findPath(editor, element);

  const handleImageChooseOpen = () => {
    imgChooseInputRef.current?.click();
  };

  const removeImage = () => {
    CommonService.removeElementByPath(editor, path);
  };

  const editImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];

    if (!uploadedFile) {
      return;
    }

    try {
      Swal.showLoading();

      const image = await EditorService.uploadEditorImage(uploadedFile);
      await ImageService.replaceImage({
        editor,
        imagePath: path,
        newImageUrl: image.data.documentoUrl,
      });

      await Swal.fire(editSuccessTitle, editSuccessDescription, "success");
    } catch (error) {
      await Swal.fire({
        title: errorMessage,
        icon: "error",
      });
    } finally {
      Swal.hideLoading();
    }
  };

  const alignment = useMemo(() => {
    if (!element.style?.textAlign) { return "start" }

    const alignment = element.style.textAlign

    const options: {[key: string]: string} = {
      left: 'start',
      justify: 'start',
      right: 'end',
      center: 'center',
    }

    return options[alignment]
  }, [element.style?.textAlign])

  return (
    <div {...props.attributes}>
      <input
        type="file"
        ref={imgChooseInputRef}
        multiple
        accept="image/*"
        hidden
        onChange={editImage}
      />

      {props.children}

      <div style={{ position: "relative" }}>
        <ImageContainer
          alignment={alignment}
          contentEditable={false}
        >
          <ImagePosition contentEditable={false} position={element.position}>
            <ImageComponent
              draggable={false}
              ref={imgRef}
              selected={selected}
              style={{
                width: props.element.style?.width,
                height: props.element.style?.height,
              }}
              src={element.url}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultImageUrl;
              }}
              alt={element.type}
            />
          </ImagePosition>
        </ImageContainer>

        {selected && (
          <ActionsContainer alignment={alignment} contentEditable={false}>
            <div>
              <ImageActions edit={handleImageChooseOpen} remove={removeImage} />
            </div>
          </ActionsContainer>
        )}
      </div>
    </div>
  );
};
