import { EModulo } from "../enum";
import IDashboard from "../interfaces/IDashboard";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { routePropriedade } from "../utils/Utils";
import api from "./Api";

class DashboardService {

    async obterPowerBI(dashboardId: number, modulo: EModulo): Promise<any> {

        const { data: result } = await api.get<any>(`/${routePropriedade(modulo)}/RecuperaDashboardPorId?dashboardId=${dashboardId}`);
    
        return result;
    }

    async recuperarDashboard(dashboard: string | number): Promise<IDashboard> {
        const { data: resposta } = await api.get<IRecuperaLista<IDashboard>>(`/WidgetPreferencias/RecuperaWidgetPreferencias?dashboard=${dashboard}&sort=-widgetPreferenciasId`);
        if (resposta.totalRegistros === 0) throw ("Não foi possível recuperar o dashboard");
        return resposta.data[0];
    }

    async obterDashboard(modulo: EModulo): Promise<any> {

        const { data: result } = await api.get<any>(`/Dashboard/RecuperaDashboard?modulo=${modulo}`);

        return result;
    }

    async adicionarDashboard(dashboard: IDashboard): Promise<IDashboard> {
        const { data: resposta } = await api.post(`/WidgetPreferencias/AdicionaWidgetPreferencias`, dashboard);
        return resposta;
    }

    async salvarDashboard(dashboard: IDashboard): Promise<IDashboard> {
        return this.adicionarDashboard(dashboard)
    }
}


export default new DashboardService();