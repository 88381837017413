import React, { createContext, useContext, useState } from "react";
import { Version } from "../types";
import { EditorVersionAdditionalResponse, EditorVersionData } from "../../../interfaces/IEditorVersionResponse";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

const EditorContext = createContext(
  {} as {
    versionData: EditorVersionData | null;
    setVersionData: SetState<EditorVersionData | null>;
    version: Version | null;
    setVersion: SetState<Version | null>;
    setTitle: (title: string) => void;
    editorLoading: boolean;
    setEditorLoading: SetState<boolean>;
    setCustomFlags: SetState<EditorVersionAdditionalResponse | null>;
    customFlags: EditorVersionAdditionalResponse | null;
  }
);

export function EditorProvider({ children }: { children: React.ReactNode }) {
  const [version, setVersion] = useState<Version | null>(null);
  const [versionData, setVersionData] = useState<EditorVersionData | null>(null);
  const [customFlags, setCustomFlags] =
    useState<EditorVersionAdditionalResponse | null>(null);
  const [editorLoading, setEditorLoading] = useState(true);

  function setTitle(title: string) {
    setVersion((prev) => {
      if (!prev) {
        return null;
      }
      return { ...prev, title };
    });
  }

  return (
    <EditorContext.Provider
      value={{
        version,
        setVersion,
        setTitle,
        editorLoading,
        setEditorLoading,
        customFlags,
        setCustomFlags,
        versionData,
        setVersionData
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useProvider() {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error("useProvider must be used with EditorProvider");
  }
  return context;
}
