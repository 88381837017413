/* eslint-disable @typescript-eslint/no-empty-function */
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { useState } from "react";

interface AreaTextoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  titulo: string;
  field: string;
  setFiltrosPesquisa: any;
}

const AreaTextoFiltroField = ({
  setFiltrosPesquisa,
  toggleModal,
  exibirModal,
  titulo,
  field,
}: AreaTextoFieldProps) => {
  const [text, setText] = useState("");

  const confirmar = () => {
    const filtro = { [field]: text };

    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, ...filtro };
    });

    toggleModal();
  };

  const cancelar = () => {
    toggleModal();
  };

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={cancelar}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            {titulo} <FontAwesomeIcon className="mx-2 text-orange" icon={faEdit} />
          </h5>
          <div onClick={cancelar} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <textarea
              placeholder="..."
              maxLength={5000}
              rows={10}
              className={clsx("form-control")}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={confirmar} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>
          <button onClick={cancelar} type="button" className="btn btn-danger ms-5">
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AreaTextoFiltroField;
