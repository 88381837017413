
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import IStatusDespesa from "../../interfaces/IStatusDespesa";
import { Modal } from "react-bootstrap";
import StatusDespesaService from "../../services/StatusDespesaService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { EditarStatusDespesaRequest } from "../../interfaces/Requests/StatusDespesa/EditarStatusDespesaRequest";

interface IStatusDespesaModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    statusDespesa?: IStatusDespesa,
    titulo: string
}

const StatusDespesaModal = ({ toggleModal, exibirModal, statusDespesa, titulo }: IStatusDespesaModalProps) => {

    useEffect(() => {
        async function montarForm() {
            await formik.resetForm();

            if (statusDespesa) {
                await formik.setValues({ ...statusDespesa });
            }

            await formik.validateForm();
        }

        montarForm();

        // eslint-disable-next-line
    }, [exibirModal, statusDespesa]);

    const statusDespesaValidacoes = Yup.object().shape({
        nome: Yup.string().max(50, 'Maximo 50 caracteres').required('Nome é obrigatório'),
        codigo: Yup.string().max(50, 'Maximo 50 caracteres'),
        cor: Yup.string().max(50, 'Maximo 50 caracteres').required('Cor é obrigatória')
    })

    const valoresIniciais: EditarStatusDespesaRequest = {
        nome: '',
        codigo: '',
        cor: '',
    } as IStatusDespesa;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: statusDespesaValidacoes,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                if (!values.statusDespesaId) {
                    await StatusDespesaService.adicionaStatusDespesa(values);

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Status da Despesa cadastrado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                } else {
                    await StatusDespesaService.atualizarStatusDespesa(values);

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Status da Despesa editado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }

                setSubmitting(false);

                toggleModal(true);
            } catch (error: any) {
                let mensagemErro = 'Ocorreu um erro inesperado, favor acionar nosso Suporte.';

                if (error?.response?.data?.Message) {
                    mensagemErro = error.response.data.Message
                }

                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel realizar a operação desejada.`,
                    text: mensagemErro,
                    showConfirmButton: true
                });
            }
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">Nome</label>
                            <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.nome && formik.errors.nome,
                                },
                                {
                                    'is-valid': formik.touched.nome && !formik.errors.nome,
                                }
                            )} id="form-nome" />
                            {formik.touched.nome && formik.errors.nome && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.nome}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código</label>
                            <input {...formik.getFieldProps('codigo')} placeholder="Código" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.codigo && formik.errors.codigo,
                                },
                                {
                                    'is-valid': formik.touched.codigo && !formik.errors.codigo,
                                }
                            )} id="form-codigo" />
                            {formik.touched.codigo && formik.errors.codigo && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.codigo}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-cor" className="form-label required fw-bolder text-orange">Cor</label>
                            <input {...formik.getFieldProps('cor')} placeholder="Cor" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.cor && formik.errors.cor,
                                },
                                {
                                    'is-valid': formik.touched.cor && !formik.errors.cor,
                                }
                            )} id="form-cor" />
                            {formik.touched.cor && formik.errors.cor && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.cor}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formik.isSubmitting && <> SALVAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default StatusDespesaModal;