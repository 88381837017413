import { useCallback, useState } from 'react';

export interface IModalActions {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
}

export const useDisclosure = (initialState = false): IModalActions=> {
  const [isOpen, setIsOpen] = useState(initialState);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleToggle = useCallback(() => setIsOpen((state) => !state), []);

  return {
    isOpen,
    handleOpen,
    handleClose,
    handleToggle,
  };
};