import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IObrigacao from "../interfaces/IObrigacao";
import { AdicionaObrigacaoRequest } from "../interfaces/Requests/Obrigacao/AdicionaObrigacaoRequest";
import { EditarObrigacaoRequest } from "../interfaces/Requests/Obrigacao/EditarObrigacaoRequest";
import FiltroPesquisaObrigacaoRequest from "../interfaces/Requests/Obrigacao/FiltroPesquisaObrigacaoRequest";
import { EModulo, ETipoCampoEnum } from "../enum";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class ObrigacaoService {

    public async obterObrigacoes(filtros: FiltroPesquisaObrigacaoRequest): Promise<IRecuperaLista<IObrigacao>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.statusObrigacaoId.length > 0) {
            filtros.statusObrigacaoId.forEach((id) => (query += `&statusObrigacaoId=${id}`));
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.obrigacaoId) {
            query += `&obrigacaoId=${filtros.obrigacaoId}`;
        }

        if (filtros.dataBaseMultaMaiorIgual) {
            query += `&dataBaseMultaMaiorIgual=${filtros.dataBaseMultaMaiorIgual}`;
        }

        if (filtros.dataBaseMultaMenorIgual) {
            query += `&dataBaseMultaMenorIgual=${filtros.dataBaseMultaMenorIgual}`;
        }

        if (filtros.dataIntimacaoMaiorIgual) {
            query += `&dataIntimacaoMaiorIgual=${filtros.dataIntimacaoMaiorIgual}`;
        }

        if (filtros.dataIntimacaoMenorIgual) {
            query += `&dataIntimacaoMenorIgual=${filtros.dataIntimacaoMenorIgual}`;
        }

        if (filtros.dataConclusaoMaiorIgual) {
            query += `&dataConclusaoMaiorIgual=${filtros.dataConclusaoMaiorIgual}`;
        }

        if (filtros.dataConclusaoMenorIgual) {
            query += `&dataConclusaoMenorIgual=${filtros.dataConclusaoMenorIgual}`;
        }


        if (filtros.tipoObrigacaoId) {
            query += `&tipoObrigacaoId=${filtros.tipoObrigacaoId}`;
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IObrigacao>>(`Obrigacao/RecuperaObrigacao?${query}`);

        return result;
    }

    public async obterObrigacaoPorId(obrigacaoId: number): Promise<IRecuperaLista<IObrigacao>> {

        let { data: result } = await api.get<IRecuperaLista<IObrigacao>>(`Obrigacao/RecuperaObrigacao?obrigacaoId=${obrigacaoId}`);

        return result;
    }

    public async adicionaObrigacao(adicionaObrigacao: AdicionaObrigacaoRequest): Promise<IObrigacao> {
        let { data: result } = await api.post<IObrigacao>(`Obrigacao/AdicionaObrigacao`, this.montarObjetoRequest(adicionaObrigacao));

        return result;
    }

    public async editarObrigacao(editarObrigacao: AdicionaObrigacaoRequest) {
        let request = this.montarObjetoArray(editarObrigacao);

        if (request.length > 0) {
            let result = await api.patch(`Obrigacao/AtualizaObrigacaoPorId?id=${editarObrigacao.obrigacaoId}`, request);
            return result;
        }
    }

    public async adicionaFavorido(obrigacaoId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Obrigacao, obrigacaoId: obrigacaoId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async obterCapaObrigacao(obrigacaoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Obrigacao/RecuperaCapaObrigacao?obrigacaoId=${obrigacaoId}`);
        return result;
    }

    async alterarStatus(obrigacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IObrigacao>(`Obrigacao/AtualizaObrigacaoPorId?id=${obrigacaoId}`, data);
    }

    async atualizarObrigacao(editarObrigacao: EditarObrigacaoRequest): Promise<void> {

        let dataObrigacao = [
            { "op": "replace", "path": "/codigo", "value": editarObrigacao.codigo },
            { "op": "replace", "path": "/descricaoObrigacao", "value": editarObrigacao.descricaoObrigacao }
        ]


        await Promise.all([
            await api.patch<void>(`Obrigacao/AtualizaObrigacaoPorId?id=${editarObrigacao.obrigacaoId}`, dataObrigacao)
        ]);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Obrigacao/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarObrigacaoPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Obrigacao/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    private montarObjetoArray = (despesa: AdicionaObrigacaoRequest) => {
        let request: any = [];

        // Campos texto
        if (despesa.codigo) request.push({ op: "replace", path: "/codigo", value: despesa.codigo });
        if (despesa.descricaomulta) request.push({ op: "replace", path: "/descricaoMulta", value: despesa.descricaomulta });
        if (despesa.descricaoobrigacao) request.push({ op: "replace", path: "/descricaoObrigacao", value: despesa.descricaoobrigacao });

        // Campos data
        if (despesa.databasemulta) request.push({ op: "replace", path: "/dataBaseMulta", value: despesa.databasemulta });
        if (despesa.dataintimacao) request.push({ op: "replace", path: "/dataIntimacao", value: despesa.dataintimacao });
        if (despesa.dataconclusao) request.push({ op: "replace", path: "/dataConclusao", value: despesa.dataconclusao });
        if (despesa.prazo) request.push({ op: "replace", path: "/prazo", value: despesa.prazo });


        return request;
    };

    private montarObjetoRequest = (obrigacao: AdicionaObrigacaoRequest) => {
        let request: any = {};

        if (obrigacao.xCampoValorList.length > 0) request.xCampoValorList = obrigacao.xCampoValorList;

        if (obrigacao.tipoObrigacaoId) request.tipoObrigacaoId = obrigacao.tipoObrigacaoId;

        if (obrigacao.codigo) request.codigo = obrigacao.codigo;
        if (obrigacao.descricaoobrigacao) request.descricaoObrigacao = obrigacao.descricaoobrigacao;
        if (obrigacao.origemobrigacaoId) request.origemobrigacaoId = obrigacao.origemobrigacaoId;
        if (obrigacao.descricaomulta) request.descricaomulta = obrigacao.descricaomulta;
        if (obrigacao.periodicidadeId) request.periodicidadeId = obrigacao.periodicidadeId;
        if (obrigacao.valormultabase) request.valormultabase = obrigacao.valormultabase;
        if (obrigacao.databasemulta) request.databasemulta = obrigacao.databasemulta;
        if (obrigacao.dataintimacao) request.dataintimacao = obrigacao.dataintimacao;
        if (obrigacao.dataconclusao) request.dataconclusao = obrigacao.dataconclusao;
        if (obrigacao.prazo) request.prazo = obrigacao.prazo;

        request.concluida = obrigacao.concluida;
        request.possuiMulta = obrigacao.possuimulta;

        if (obrigacao.processoId) {
            request.modulo = EModulo.AreaDireito;
            request.processoId = obrigacao.processoId;
            request.areaDireitoId = obrigacao.areaDireitoId;
        }

        if (obrigacao.imovelId) {
            request.modulo = EModulo.Imovel;
            request.imovelId = obrigacao.imovelId;
            request.tipoImovelId = obrigacao.tipoImovelId;
        }

        return request;
    };

    public concluirCompromisso = async (obrigacao: AdicionaObrigacaoRequest): Promise<any> => {

        let result: any[] = [];

        // Salvar campos normais
        result.push(await this.editarObrigacao(obrigacao));

        // Salvar xcampos
        if (obrigacao.xCampoValorList?.length > 0) {
            obrigacao.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, obrigacaoId: obrigacao.obrigacaoId, texto: xCampo.texto }));
                        else result.push(await this.alterarObrigacaoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, obrigacaoId: obrigacao.obrigacaoId, data: xCampo.data }));
                        else result.push(await this.alterarObrigacaoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, obrigacaoId: obrigacao.obrigacaoId, valor: xCampo.valor }));
                        else result.push(await this.alterarObrigacaoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, obrigacaoId: obrigacao.obrigacaoId, inteiro: xCampo.inteiro }));
                        else result.push(await this.alterarObrigacaoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, obrigacaoId: obrigacao.obrigacaoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarObrigacaoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        // results
        return result;
    };
}


export default new ObrigacaoService();