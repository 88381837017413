import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ISeparador from "../interfaces/ISeparador";
import { AdicionaSeparadorRequest } from "../interfaces/Requests/Separador/AdicionaSeparadorRequest";
import FiltroPesquisaSeparadorRequest from "../interfaces/Requests/Separador/FiltroPesquisaSeparadorRequest";
import { EditarSeparadorRequest } from "../interfaces/Requests/Separador/EditarSeparadorRequest";

class SeparadorService {

    async obterSeparadors(filtros: FiltroPesquisaSeparadorRequest): Promise<IRecuperaLista<ISeparador>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ISeparador>>(`Separador/RecuperaSeparador?${query}`);

        return result;
    }

    async adicionaSeparador(adicionaSeparador: AdicionaSeparadorRequest): Promise<ISeparador> {
        let { data: result } = await api.post<ISeparador>(`Separador/AdicionaSeparador`, adicionaSeparador);

        return result;
    }


    async alterarStatus(cargoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ISeparador>(`Separador/AtualizaSeparadorPorId?id=${cargoId}`, data);
    }

    async atualizarSeparador(editarSeparador: EditarSeparadorRequest): Promise<void> {

        let dataSeparador = [
            { "op": "replace", "path": "/nome", "value": editarSeparador.nome },
            { "op": "replace", "path": "/codigo", "value": editarSeparador.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Separador/AtualizaSeparadorPorId?id=${editarSeparador.separadorId}`, dataSeparador)
        ]);
    }
}


export default new SeparadorService();