import { useEffect, useState } from "react";
import clsx from "clsx";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { useAuth } from "../../contexts/AuthProvider";

import IAlterarSenhaRequest from "../../interfaces/IAlterarSenhaRequest";
import IConfiguracaoesSeguranca from "../../interfaces/Requests/Cliente/IConfiguracaoesSeguranca";
import ClienteService from "../../services/ClienteService";
import { countCharacterSpecial, countLowerCase, countNumber, countUpperCase } from "../../utils/Utils";
import StorageUtils from "../../utils/StorageUtils";


export default function AlterarSenhaPage() {
    const [loading, setLoading] = useState(false);
    const [validacaoSeguranca, setValidacaoSeguranca] = useState('');
    const [configSeguranca, setConfigSeguranca] = useState<IConfiguracaoesSeguranca>({
        clienteId: 0,
        configuracoesSegurancaId: 0,
        quantidadeMinimaCaracteres: 8,
        quantidadeMinimaLetraMaiuscula: 1,
        quantidadeMinimaLetraMinuscula: 1,
        quantidadeMinimaNumero: 1,
        quantidadeMinimaCaracteresEspeciais: 1,
        quantidadeMinutosExigirNovoToken: null,
        desabilitarExigirToken: false
    });
    const [password, setPassword] = useState('');
    const [strengthPassword, setStrengthPassword] = useState(0);
    const [tipoSenha, setTipoSenha] = useState({
        senha: 'password',
        novaSenha: 'password',
        confirmarNovaSenha: 'password',
    });
    const { user, changePassword } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (user?.primeiroAcesso) {
            Swal.fire({
                heightAuto: false,
                icon: 'warning',
                title: ``,
                text: 'Você deve alterar sua senha antes de continuar usando o sistema.',
                showConfirmButton: true
            });
        }


    }, [])

    const resetPasswordSchema = Yup.object().shape({
        senhaAtual: Yup.string()
            .required('Senha Atual é obrigatório'),
        novaSenha: Yup.string()
            .min(configSeguranca.quantidadeMinimaCaracteres, `Informe no minimo ${configSeguranca.quantidadeMinimaCaracteres} caracteres.`)
            .max(50, 'Máximo 50 caracteres')
            .required('Nova Senha é obrigatório').test('validacao-forca', validacaoSeguranca, async (value) => {
                if (strengthPassword <= 1) {
                    return false;
                }

                if (value && countNumber(value) < configSeguranca.quantidadeMinimaNumero) {
                    setValidacaoSeguranca(`Informe no minimo ${configSeguranca.quantidadeMinimaNumero} números.`);
                    return false;
                }

                if (value && countUpperCase(value) < configSeguranca.quantidadeMinimaLetraMaiuscula) {
                    setValidacaoSeguranca(`Informe no minimo ${configSeguranca.quantidadeMinimaLetraMaiuscula} letras maiúscula.`);
                    return false;
                }

                if (value && countLowerCase(value) < configSeguranca.quantidadeMinimaLetraMinuscula) {
                    setValidacaoSeguranca(`Informe no minimo ${configSeguranca.quantidadeMinimaLetraMinuscula} letras minúsculas.`);
                    return false;
                }

                if (value && countCharacterSpecial(value) < configSeguranca.quantidadeMinimaCaracteresEspeciais) {
                    setValidacaoSeguranca(`Informe no minimo ${configSeguranca.quantidadeMinimaCaracteresEspeciais} caracteres especiais. Ex: &, @, *, #`);
                    return false;
                }

                return true;
            }),
        confirmarNovaSenha: Yup.string()
            .min(configSeguranca.quantidadeMinimaCaracteres, `Minimo ${configSeguranca.quantidadeMinimaCaracteres} caracteres`)
            .max(50, 'Máximo 50 caracteres')
            .oneOf([Yup.ref('novaSenha'), null], 'Você digitou senhas diferentes.')
            .required('Confirmação de Senha é obrigatório')
    })

    const initialValues = {
        senhaAtual: '',
        novaSenha: '',
        confirmarNovaSenha: '',
    }

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                let changePasswordRequest = { usuarioId: user?.id, email: user?.email, antigaSenha: values.senhaAtual, novaSenha: values.novaSenha } as IAlterarSenhaRequest;
                await changePassword(changePasswordRequest);
                formik.resetForm();
                await Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    title: `Senha Alterada com sucesso`,
                    showConfirmButton: true
                });
                navigate('/');
            } catch (error: any) {
                let mensagem = 'Não foi possivel resetar a senha';

                if (error?.response?.data?.Message) {
                    mensagem = error.response.data.Message;
                }

                setStatus(mensagem);
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        setPassword(formik.values.novaSenha);
    }, [formik.values.novaSenha])

    useEffect(() => {
        formik.validateField('novaSenha');


    }, [strengthPassword])

    const handlerTypePassword = (name: 'senha' | 'novaSenha' | 'confirmarNovaSenha') => {
        let novoValor = tipoSenha[name];

        if (novoValor === 'password') {
            novoValor = 'text'
        } else {
            novoValor = 'password'
        }

        setTipoSenha(oldState => ({ ...oldState, [name]: novoValor }))
    }

    useEffect(() => {
        carregarConfigSeguranca();
    }, [])

    const carregarConfigSeguranca = async () => {
        try {

            const storageUser = await StorageUtils.getUser();

            let resultado: any;

            resultado = await ClienteService.obterConfiguracapSeguranca(storageUser?.clienteId || 0);

            if (resultado.data.length > 0) setConfigSeguranca(resultado.data[0]); else setConfigSeguranca(configSeguranca);

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
        }
    }

    return (
        <>
            <LayoutPageTitle title="Alterar Senha" />

            <div className="row mt-2">
                <div className="col-md-4">
                    <form className='form' onSubmit={formik.handleSubmit} noValidate autoComplete="no" >
                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}

                        <div className='fv-row mb-3'>
                            <label className='form-label fs-6 fw-bolder text-orange'>Senha Atual</label>
                            <div className="input-group">
                                <input
                                    placeholder='Senha Atual'
                                    {...formik.getFieldProps('senhaAtual')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        { 'is-invalid': formik.touched.senhaAtual && formik.errors.senhaAtual },
                                        {
                                            'is-valid': formik.touched.senhaAtual && !formik.errors.senhaAtual,
                                        }
                                    )}
                                    type={tipoSenha.senha}
                                    autoComplete='no'
                                />
                                <span onClick={() => handlerTypePassword('senha')} className="input-group-text background-orange" style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon size="lg" color='white' className='mx-2' icon={tipoSenha.senha === 'password' ? faEye : faEyeSlash} />
                                </span>
                            </div>
                            {formik.touched.senhaAtual && formik.errors.senhaAtual && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.senhaAtual}</span>
                                </div>
                            )}
                        </div>

                        <div className='fv-row mb-3'>
                            <label className='form-label fs-6 fw-bolder text-orange'>Nova Senha</label>
                            {
                                formik.values.novaSenha.length > 4 && <PasswordStrengthBar className="mb-2" onChangeScore={setStrengthPassword} scoreWords={['Ruim', 'Fraca', 'Boa', 'Forte', 'Muito Forte']} password={password} />
                            }
                            <div className="input-group">
                                <input
                                    placeholder='Nova Senha'
                                    {...formik.getFieldProps('novaSenha')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        { 'is-invalid': formik.touched.novaSenha && formik.errors.novaSenha },
                                        {
                                            'is-valid': formik.touched.novaSenha && !formik.errors.novaSenha,
                                        }
                                    )}
                                    type={tipoSenha.novaSenha}
                                    autoComplete='off'
                                />
                                <span onClick={() => handlerTypePassword('novaSenha')} className="input-group-text background-orange" style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon size="lg" color='white' className='mx-2' icon={tipoSenha.novaSenha === 'password' ? faEye : faEyeSlash} />
                                </span>
                            </div>
                            {formik.touched.novaSenha && formik.errors.novaSenha && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.novaSenha}</span>
                                </div>
                            )}
                        </div>

                        <div className='fv-row mb-3'>
                            <label className='form-label fs-6 fw-bolder text-orange'>Confirmar Nova Senha</label>
                            <div className="input-group">
                                <input
                                    placeholder='Confirmar Nova Senha'
                                    {...formik.getFieldProps('confirmarNovaSenha')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        { 'is-invalid': formik.touched.confirmarNovaSenha && formik.errors.confirmarNovaSenha },
                                        {
                                            'is-valid': formik.touched.confirmarNovaSenha && !formik.errors.confirmarNovaSenha,
                                        }
                                    )}
                                    type={tipoSenha.confirmarNovaSenha}
                                    autoComplete='off'
                                />
                                <span onClick={() => handlerTypePassword('confirmarNovaSenha')} className="input-group-text background-orange" style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon size="lg" color='white' className='mx-2' icon={tipoSenha.confirmarNovaSenha === 'password' ? faEye : faEyeSlash} />
                                </span>
                            </div>
                            {formik.touched.confirmarNovaSenha && formik.errors.confirmarNovaSenha && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{formik.errors.confirmarNovaSenha}</span>
                                </div>
                            )}
                        </div>

                        <div className='text-center'>
                            <button
                                type='submit'
                                className='btn btn-lg btn-orange w-100 mt-2'
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className='indicator-label'>Salvar</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        Aguarde...
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}