import { useContext, useEffect, useRef, useState } from "react";
import { faPrint, faHistory, faMoneyBill, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { objetoPropriedade, routePropriedade, tipoPorModulo } from "../../../utils/Utils";
import { defaultSchema } from "../../../utils/SchemasUtil";
import { EPropriedadesTela } from "../../../enum/EPropriedadesTela";

import IParteContraria from "../../../interfaces/IParteContraria";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaPropriedadesTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ECamposIDEnum, EModulo, ETelaEnum } from "../../../enum";
import LayoutPageButton from "../../../layout/LayoutPageButton";

import DocumentoService from "../../../services/DocumentoService";
import ParteContrariaService from "../../../services/ParteContrariaService";

import { notifySuccess } from "../Toast/ToastFormik";
import Carregando from "../../../components/Carregando";
import CamposCapaParteContraria from "./CamposCapaParteContraria";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import FileListCapa from "../../FileUpload/ParteContraria/FileListCapaParteContrariaField";

interface IParteContrariaFieldProps {
  modulo: EModulo;
  categoriaId?: number;
  exibirModal: boolean;
  toggleModal: () => void;
  parteContraria: IParteContraria;
  parteContrariaList: IParteContraria[];
  setRefreshPrincipal: (atualiza: boolean) => void;
}

const ParteContrariaCapaPage = ({ toggleModal, exibirModal, parteContraria, parteContrariaList, categoriaId, modulo, setRefreshPrincipal }: IParteContrariaFieldProps) => {

  const { setParteContraria, contrato, processo, consulta, imovel, licenca, empresa } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [refreshCapa, setRefreshCapa] = useState(true);

  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [propriedadesTela, setPropriedadesTela] = useState<IRecuperaPropriedadesTelaResponse[]>([]);

  const componentRef = useRef<any>();

  const initialValues = {
    parteContrariaId: parteContraria?.parteContrariaId,
    principalId: parteContraria?.parteContrariaId,
    controller: parteContraria?.controller,

    principal: parteContraria?.principal,

    formaParticipacaoId: 0,
    formaParticipacaoNome: parteContraria?.formaParticipacaoNome,

    PapelSignatarioNome: parteContraria?.papelSignatarioNome,
    papelSignatarioId: 0,

    modulo: EModulo.ParteContraria,
    tela: ETelaEnum.TelaCapaParteContraria,

    tipoParteContrariaId: parteContraria?.tipoParteContrariaId,
    categoriaId: parteContraria?.categoriaId,

    imovelId: imovel?.imovelId,
    tipoImovelId: imovel.tipoImovelId,

    empresaId: empresa?.empresaId,
    tipoEmpresaId: empresa?.tipoEmpresaId,

    licencaId: licenca?.licencaId,
    tipoLicencaId: licenca?.tipoLicencaId,

    processoId: processo?.processoId,
    areaDireitoId: processo?.areaDireitoId,

    consultaId: consulta?.consultaId,
    tipoConsultaId: consulta?.tipoConsultaId,

    contratoId: contrato?.contratoId,
    tipoContratoId: contrato?.tipoContratoId,

    atoSocietarioId: parteContraria?.atoSocietarioId,

    arquivosParaImportar: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!parteContraria?.parteContrariaId) return;

    if (exibirModal) {

      carregaCapa();

      carregaParteContrariaTipo();

      setParteContraria(parteContraria);

      formik.setValues(initialValues);
    }

  }, [parteContraria?.parteContrariaId, refreshCapa, exibirModal]);


  const carregaCapa = async () => {
    try {

      setCarregandoConfiguracoesTela(true);
      let resultado = await ParteContrariaService.obterCapaParteContraria(parteContraria?.parteContrariaId, parteContraria?.controller);

      setConfiguracoesTela(resultado.data);
      setPropriedadesTela(resultado.dataPropriedades);
      setCarregandoConfiguracoesTela(false);

    } catch (error: any) {
      console.log(error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const baixar = async () => {

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });

    Swal.showLoading();

    try {
      await DocumentoService.downloadRelatorioCustomizado({ parteContrariaId: parteContraria?.parteContrariaId, chaveRelatorioCustomizado: EPropriedadesTela.BancoMasterFormularioContasPagar })
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", 'Formulario - Fornecedores Master.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const marcarPrincipal = async () => {

    parteContrariaList.forEach(async (parte: any) => await ParteContrariaService.alterarCampoPrincipal(ECamposIDEnum.Principal, false, parte[`${objetoPropriedade(modulo)}ParteContrariaId`], routePropriedade(modulo)));

    await ParteContrariaService.alterarPorCampo(ECamposIDEnum.Principal, true, parteContraria.parteContrariaId, routePropriedade(modulo))

    notifySuccess(`${parteContraria?.nome} marcado como principal.`);

    formik.setFieldValue('principal', true);

    setRefreshPrincipal(true);

  }

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {

      switch (configuracaoTela.campoNome) {
        case ECamposIDEnum.Principal:
          component.push(
            <>
              <div className="row mt-3">
                <div className="col-12">
                  <label htmlFor="form" className="form-label fw-bolder text-orange">Marcar como principal?</label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    disabled={formik.values?.principal}
                    checked={formik.values?.principal}
                    onChange={(e) => marcarPrincipal()} // Define o valor de 'principal' com base no estado do switch
                  />
                </div>
              </div>
            </>)
          break;

        default:
          component.push(
            <CamposCapaParteContraria
            formik={formik}
              modulo={modulo}
              configuracaoTela={configuracaoTela}
              controller={routePropriedade(modulo)}
              configuracaoTelaLista={configuracoesTela}
              setConfiguracaoTela={setConfiguracoesTela}
              setRefreshPrincipal={setRefreshPrincipal}
            />
          );
          break;
      }
    });

    return <div className="row mt-3">{component}</div>;
  };

  const carregaParteContrariaTipo = async () => {
    try {

      let objeto: any = {};

      objeto[`${tipoPorModulo(modulo)}Id`] = categoriaId;

      let resultado = await ParteContrariaService.obterParteContraria({
        nome: parteContraria.tipoDocumento == "NOME" ? "CPF" : parteContraria.tipoDocumento,
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "tipoParteContrariaId",
      });

      formik.setFieldValue('tipoParteContrariaId', resultado.data.find((e: any) => e).tipoParteContrariaId);

      formik.setFieldValue('categoriaId', resultado.data.find((e: any) => e).tipoParteContrariaId);


    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        timer: 4000,
        icon: "info",
        showConfirmButton: false,
      });
    }
  }

  return (
    <>
      <ToastContainer />

      <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <LayoutPageButton title={`Capa: #${formik.values.principalId} Parte Contrária`}
            >
              <>
                {!carregandoConfiguracoesTela && (
                  <a
                    style={{ cursor: "pointer", fontSize: "30px" }}
                    onClick={() => setRefreshCapa(!refreshCapa)}
                  >
                    {
                      <FontAwesomeIcon
                        color={"#DC853D"}
                        className="mx-2"
                        icon={faHistory}
                      />
                    }
                  </a>
                )}

                {carregandoConfiguracoesTela && (
                  <span
                    className="indicator-progress"
                    style={{
                      display: "block",
                      fontSize: "30px",
                      paddingLeft: "11px",
                      paddingRight: "11px",
                    }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </>
              <ReactToPrint
                trigger={() => (
                  <a style={{ cursor: "pointer", fontSize: "30px" }}>
                    <FontAwesomeIcon
                      color={"#DC853D"}
                      className="mx-2"
                      icon={faPrint}
                      title="Imprimir o parteContraria"
                    />
                  </a>
                )}
                content={() => componentRef.current}
              />

              {propriedadesTela.find(e => e.codigo == EPropriedadesTela.BancoMasterFormularioContasPagar) &&
                <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => baixar()}>
                  {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faMoneyBill} title="Formulário" />}
                </a>
              }


            </LayoutPageButton>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
        </div>

        <div className="modal-body">

          <div style={{ marginLeft: "15px" }} ref={componentRef}>
            <br />
            {carregandoConfiguracoesTela ? (
              <Carregando />
            ) : (
              <>
                {renderCampos()}
                <FileListCapa formik={formik} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
              </>
            )}
          </div>
        </div>

        <div className="modal-footer" style={{ margin: "0 auto" }}>

        </div>
      </Modal >
    </>
  );
};
export default ParteContrariaCapaPage;
