import { useEffect, useState, useContext } from 'react';
import { faEraser, faSearch, faFileExcel, faCalendar, faCheck, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { BsFillArrowUpRightCircleFill, BsSearch } from 'react-icons/bs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { MultiSelect } from 'react-multi-select-component';
import { TableColumn } from 'react-data-table-component';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal from "sweetalert2";

import { EModulo } from '../../enum';

import AndamentoService from '../../services/AndamentoService';
import AreaDireitoService from '../../services/AreaDireitoService';
import AndamentoTipoService from '../../services/AndamentoTipoService';

import IAndamento from '../../interfaces/IAndamentos';
import ICompromisso from '../../interfaces/ICompromisso';
import IAreaDireito from '../../interfaces/IAreaDireito';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import IAndamentoTipo from '../../interfaces/IAndamentoTipo';
import { OrangeInterfaceContext } from '../../interfaces/Contexts/OrangeInterfaceContext';
import FiltroPesquisaAndamentoRequest from '../../interfaces/Requests/Andamento/FiltroPesquisaAndamentoRequest';

import AndamentoDetalhes from './AndamentoDetalhes';
import GridPadrao from '../../components/Comum/GridPadrao';
import { OrangeContext } from '../../contexts/OrangeProvider';
import DropDown from '../../components/Comum/DropDown/DropDown';
import AndamentoRelatorioModal from './AndamentoRelatorioModal';
import CompromissoField from '../../components/Comum/Compromisso/CompromissoField';

const AndamentoPage = () => {
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoAndamentos, setCarregandoAndamentos] = useState(false);
    const [exibirModalAndamento, setExibirModalAndamento] = useState<boolean>(false);

    const [statusOptions] = useState<any>([{ label: 'Pendente', value: 1 }, { label: 'Lido', value: 2 }, { label: 'Descartado', value: 3 },]);
    const [statusSelected, setStatusSelected] = useState([]);

    const [carregamentoAreaDireito, setCarregamentoAreaDireito] = useState<boolean>(false);
    const [areasDireitoOptions, setAreasDireitoOptions] = useState<any>([]);
    const [areaDireitoSelected, setAreaDireitoSelected] = useState([]);

    const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);

    const [andamentos, setAndamentos] = useState<IAndamento[]>([]);
    const [andamento, setAndamento] = useState<IAndamento>({
        processoParteContraria: [],
        processoId: 0,
        andamentoId: 0,
        andamentoTipoId: 0,
        modulo: EModulo.AreaDireito,
        codigo: "",
        dataAndamento: new Date(),
        andamentoTipoNome: "",
        descricao: "",
        restrito: false,
        dataCadastro: "",
        xFinderAndamentoTipoExterno: "",
        numeroProcesso: ""
    });
    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaAndamentoRequest>({
        processoId: 0,
        areaDireitoId: [],
        tipoPesquisa: 1,
        filtro: "",
        limit: 10,
        ultimosQuantidade: null,
        totalItems: 0,
        offset: 0,
        sort: "-DataAndamento",
        status: [1, 2, 3],
        modulo: EModulo.AreaDireito,
        descricao: "",
        andamentoTipoId: [],
        dataAndamentoMaiorIgual: '',
        dataAndamentoMenorIgual: '',
        dataCadastroMaiorIgual: '',
        dataCadastroMenorIgual: ''
    });

    const { setProcesso, processo } = useContext<OrangeInterfaceContext>(OrangeContext);

    const [tipoAndamento, setTipoAndamento] = useState<any>([]);
    const [tipoAndamentoSelecionados, setTipoAndamentoSelecionados] = useState([]);
    const [carregandoTipoAndamento, setCarregandoTipoAndamento] = useState<boolean>(false);

    const [, setAtualizarTabelaCompromisso] = useState<boolean>(false);
    const [exibirCompromisso, setExibirCompromisso] = useState<boolean>(false);
    const [editarCompromisso] = useState<ICompromisso>({
        modulo: EModulo.AreaDireito,
        tipoCompromissoNome: "",
        compromissoId: 0,
        codigo: "",
        dataConclusao: new Date(),
        dataCompromisso: new Date(),
        observacaoCompromisso: "",
        observacaoConclusao: "",
        tipoCompromissoId: 0,
        usuariosAprovadoresList: [],
        horario: "",
        grupoId: []
    });

    const toggleCompromissoModal = () => setExibirCompromisso(!exibirCompromisso);

    const toggleAndamento = (): void => setExibirModalAndamento(!exibirModalAndamento);

    const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

    const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa((oldState: any) => { return { ...oldState, limit: currentRowsPerPage } });

    const handlePageChange = (page: number) => setFiltrosPesquisa((oldState: any) => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });

    const handleSort = async (column: TableColumn<IAndamento>, sortDirection: string) => setFiltrosPesquisa((oldState: any) => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });

    const returnOptions = (array: any, propId: string) => {
        let options: any[] = [];

        array.forEach((item: any) => {
            options.push({
                value: eval(`item.${propId}`),
                label: item.nome,
            });
        });

        return options;
    };

    const setIdsSelect = (entidade: string, optionsSelected: any) => {
        const IdsSelected: number[] = [];

        optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

        setFiltrosPesquisa({ ...filtrosPesquisa, andamentoTipoId: IdsSelected });
    };


    useEffect(() => {
        const IdsSelected: number[] = [];
        statusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
        setFiltrosPesquisa((oldState: any) => {
            return { ...oldState, status: IdsSelected };
        });
    }, [statusSelected]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!loadingInicial) {
            carregarAndamentos(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

    useEffect(() => {
        const IdsSelected: number[] = [];
        areaDireitoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
        setFiltrosPesquisa((oldState) => {
            return { ...oldState, areaDireitoId: IdsSelected };
        });
    }, [areaDireitoSelected]);

    useEffect(() => {

        carregarTipoAndamento();

        carregarAreaDireito();

    }, []);

    const carregarAndamentos = async (filtro: FiltroPesquisaAndamentoRequest): Promise<void> => {
        try {
            setCarregandoAndamentos(true);

            let resultado: IRecuperaLista<IAndamento>;

            resultado = await AndamentoService.obterAndamentos(filtro);

            setAndamentos(resultado.data);

            setFiltrosPesquisa((oldState: any) => { return { ...oldState, totalItems: resultado.totalRegistros } });

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros.',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
        }
        finally {
            setCarregandoAndamentos(false);
            setLoadingInicial(false);
        }
    }

    const carregarTipoAndamento = async () => {
        try {
            if (tipoAndamento.length > 0) return;
            setCarregandoTipoAndamento(true);
            let resultado: IRecuperaLista<IAndamentoTipo>;
            resultado = await AndamentoTipoService.obterAndamentoTipos({
                nome: "",
                codigo: "",
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "nome",
            });
            setTipoAndamento(returnOptions(resultado.data, "andamentoTipoId"));
            setCarregandoTipoAndamento(false);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setCarregandoTipoAndamento(false);
            setTipoAndamento([]);
        }
    }

    const carregarAreaDireito = async () => {
        try {
            setCarregamentoAreaDireito(true);
            let resultado: IRecuperaLista<IAreaDireito>;
            resultado = await AreaDireitoService.obterListaLimitada();
            setAreasDireitoOptions(returnOptions(resultado.data, "areaDireitoId"));
            setCarregamentoAreaDireito(false);
        } catch (error: any) {
            setCarregamentoAreaDireito(false);
            setAreasDireitoOptions([]);
        }
    }

    const limparFiltros = () => {
        setFiltrosPesquisa((oldValue: any) => {
            return {
                ...oldValue,
                processoId: 0,
                tipoPesquisa: 1,
                filtro: "",
                limit: 10,
                ultimosQuantidade: null,
                totalItems: 0,
                offset: 0,
                status: 1,
                modulo: EModulo.AreaDireito,
                descricao: "",
                andamentoTipoId: [],
                dataAndamentoMaiorIgual: '',
                dataAndamentoMenorIgual: '',
                dataCadastroMaiorIgual: '',
                dataCadastroMenorIgual: ''
            }
        });
        setTipoAndamentoSelecionados([]);
    }

    const alterarStatus = async (Id: number, status: number) => {
        try {
            const swalWithBootstrapButtons = Swal.mixin({
                heightAuto: false,
                customClass: {
                    confirmButton: 'btn btn-orange',
                    cancelButton: 'btn btn-danger ms-5'
                },
                buttonsStyling: false
            });

            let result = await swalWithBootstrapButtons.fire({
                title: 'Alterar Status ',
                text: `Você realmente deseja ${status == 2 ? 'marcar como lido' : ''}${status == 3 ? 'descartar' : ''}?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Confirmar`
            });

            if (result.isConfirmed) {

                await AndamentoService.alterarStatus(Id, status);

                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Status alterado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                })
            }

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel alterar o Status do Banco`,
                text: error?.response?.data?.Message,
                showConfirmButton: true
            });
        }
    }

    const colunas: TableColumn<IAndamento>[] = [
        {
            name: 'Id',
            sortField: 'andamentoId',
            selector: (row: IAndamento) => row.andamentoId,
            sortable: true,
            wrap: true
        },
        {
            name: "Status",
            ignoreRowClick: true,
            cell: (row: IAndamento) => {
                return (
                    <div>
                        {row?.status == "Lido" && <div placeholder='Lido' className="lidoStatus"></div>}
                        {row?.status == "Pendente" && <div placeholder='Pendente' className="pendenteStatus"></div>}
                        {row?.status == "Descartado" && <div placeholder='Descartado' className="excluidaStatus"></div>}
                    </div>
                );
            },
        },
        {
            name: 'ID Processo',
            sortField: 'processoId',
            selector: (row: IAndamento) => row.processoId || 0,
            sortable: true,
            wrap: true
        },

        {
            name: 'Número Processo',
            sortField: 'numeroProcessoCapturado',
            selector: (row: IAndamento) => row.numeroProcesso || " - ",
            sortable: true,
            wrap: true
        },
        {
            name: 'Parte Contrária',
            sortField: 'processoParteContraria',
            cell: (row: IAndamento) => row.processoParteContraria.map((map) => ` ${map?.nome}; `),
            sortable: true,
            wrap: true
        },
        {
            name: 'Tipo',
            sortField: 'andamentoTipoNome',
            selector: (row: IAndamento) => row.andamentoTipoNome,
            sortable: true,
            wrap: true
        },
        {
            name: 'Tipo - XFinder',
            sortField: 'xFinderAndamentoTipoExterno',
            selector: (row: IAndamento) => row.xFinderAndamentoTipoExterno,
            sortable: true,
            wrap: true
        },
        {
            name: 'Data Andamento',
            sortField: 'dataAndamento',
            selector: (row: IAndamento) => {
                const data = row.dataAndamento.toString() || ''
                if (data.includes('T')) {
                    const [ano, mes, dia] = data.split('T')[0].split('-')
                    const [horario] = data.split('T')[1].split('.')
                    return `${dia}/${mes}/${ano} ${horario}`
                }
                return '-'
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Data de Cadastro',
            sortField: 'dataCadastro',
            selector: (row: IAndamento) => {
                const data = row.dataCadastro.toString() || ''
                if (data.includes('T')) {
                    const [ano, mes, dia] = data.split('T')[0].split('-')
                    const [horario] = data.split('T')[1].split('.')
                    return `${dia}/${mes}/${ano} ${horario}`
                }
                return '-'
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Opções',
            cell: (row: IAndamento) => {
                return (
                    <>
                        <DropDown
                            height={200}
                            totalItems={andamentos?.length}
                            content={
                                <>
                                    <Dropdown.Item onClick={async () => {
                                        setAndamento(row);
                                        setExibirModalAndamento(true);
                                    }}>
                                        <BsSearch
                                            title="Detalhes"
                                            onClick={async () => {
                                                setAndamento(row);
                                                setExibirModalAndamento(true);
                                            }}
                                            style={{ fontWeight: "normal", cursor: "pointer" }}
                                            size="25px"
                                            className="mx-2 text-orange"
                                        /><label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                            Detalhes
                                        </label>
                                    </Dropdown.Item>


                                    <Dropdown.Item onClick={async () => {
                                        setAndamento(row);
                                        setProcesso({ ...processo, processoId: row.processoId || 0 });
                                        navigate("/Processo/Capa");
                                    }}>
                                        <BsFillArrowUpRightCircleFill
                                            title="Acessar o Processo"
                                            onClick={async () => {
                                                setAndamento(row);
                                                setProcesso({ ...processo, processoId: row.processoId || 0 });
                                                navigate("/Processo/Capa");
                                            }}
                                            style={{ fontWeight: "normal", cursor: "pointer" }}
                                            size="25px"
                                            className="mx-2 text-orange"
                                        /><label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                            Acessar Processo
                                        </label>
                                    </Dropdown.Item>

                                    {row.status == "Pendente" && (
                                        <Dropdown.Item onClick={() => alterarStatus(row.andamentoId, 2)}>
                                            <FontAwesomeIcon
                                                title="Lido"
                                                onClick={() => alterarStatus(row.andamentoId, 2)}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faCheck}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Marcar como lido
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    {row.status == "Pendente" && (
                                        <Dropdown.Item onClick={() => alterarStatus(row.andamentoId, 3)}>
                                            <FontAwesomeIcon
                                                title="Descartar"
                                                onClick={() => alterarStatus(row.andamentoId, 3)}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faTrash}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Descartar
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    <Dropdown.Item onClick={() => {
                                        setAndamento(row);
                                        toggleCompromissoModal()
                                    }}>
                                        <FontAwesomeIcon
                                            title="Criar um compromisso"
                                            onClick={() => {
                                                setAndamento(row);
                                                toggleCompromissoModal()
                                            }}
                                            style={{ fontWeight: "normal", cursor: "pointer" }}
                                            icon={faCalendar}
                                            size="1x"
                                            className="mx-2 text-orange"
                                        /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                            Criar um compromisso
                                        </label>
                                    </Dropdown.Item>
                                </>}
                        >
                        </DropDown >
                    </>
                )
            },
        },
    ]

    return (<>

        <AndamentoDetalhes
            exibirModal={exibirModalAndamento}
            toggleModal={toggleAndamento}
            andamento={andamento}
            titulo="Detalhes do Andamento"
        />

        <AndamentoRelatorioModal
            exibirModal={exibirConfirmarModeloModal}
            toggleModal={toggleConfimarModeloModal}
            filtro={filtrosPesquisa}
            setFiltro={setFiltrosPesquisa}
        />

        {andamento.processoId ?
            <CompromissoField
                toggleModal={toggleCompromissoModal}
                processoId={andamento.processoId}
                modulo={EModulo.AreaDireito}
                editarCompromisso={editarCompromisso}
                exibirModal={exibirCompromisso}
                setAtualizarTabelaCompromisso={setAtualizarTabelaCompromisso}
            />
            : <></>
        }

        <ToastContainer />

        <div className="row mt-2">

            <div className="col-md-12" >

                <form onSubmit={(e) => {
                    e.preventDefault();
                    carregarAndamentos(filtrosPesquisa);
                }} className="row g-3 mb-3 form-buscar-andamento">

                    <div className="row">
                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                Área Direito:
                            </label>
                            <MultiSelect
                                isLoading={carregamentoAreaDireito}
                                disabled={carregamentoAreaDireito}
                                options={areasDireitoOptions}
                                value={areaDireitoSelected}
                                onChange={(event: any) => setAreaDireitoSelected(event)}
                                labelledBy={"Selecione..."}
                                overrideStrings={{
                                    selectSomeItems: "Selecione...",
                                    allItemsAreSelected: "Todos selecionados",
                                    selectAll: "Selecione todos",
                                    search: "Pesquise",
                                    selectAllFiltered: "Selecione todos (filtrados)"
                                }}

                            />
                        </div>

                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-numero-processo" className="form-label fw-bolder text-orange">
                                Número do Processo:
                            </label>
                            <input
                                value={filtrosPesquisa.numeroProcesso}
                                onChange={(e) => {
                                    setFiltrosPesquisa((oldState: any) => {
                                        return { ...oldState, numeroProcesso: e.target.value };
                                    });
                                }}
                                placeholder="Número do Processo"
                                className={"form-control"}
                                id="form-numero-processo"
                            />
                        </div>

                        <div className="col-12 col-lg-4 col-xl-4">
                            <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                                Parte Contrária:
                            </label>
                            <input
                                value={filtrosPesquisa.parteContraria}
                                onChange={(e) => {
                                    setFiltrosPesquisa((oldState) => {
                                        return { ...oldState, parteContraria: e.target.value };
                                    });
                                }}
                                placeholder="Nome ou CPF/CNPJ"
                                type="text"
                                className={"form-control"}
                                id="form-parteContraria"
                            />
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-dataAndamento" className="form-label fw-bolder text-orange">Data do Andamento:</label>
                        <DateRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            maxDate={new Date()}
                            value={
                                (filtrosPesquisa.dataAndamentoMaiorIgual && filtrosPesquisa.dataAndamentoMenorIgual) ?
                                    [
                                        filtrosPesquisa.dataAndamentoMaiorIgual,
                                        filtrosPesquisa.dataAndamentoMenorIgual,
                                    ] : ''
                            }
                            onChange={(datas: Date[]) => {
                                if (!datas || datas.length === 0) {
                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataAndamentoMaiorIgual: '',
                                        dataAndamentoMenorIgual: '',
                                    }));
                                    return;
                                }

                                let data_inicial = datas[0];
                                let data_final = datas[1];

                                const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                                if (isValidDate(data_inicial)) {
                                    if (isValidDate(data_final)) {
                                        data_final.setHours(0, 0, 0, 0);
                                        data_final = new Date(data_final.getTime() - 1);
                                    }

                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataAndamentoMaiorIgual: `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`,
                                        dataAndamentoMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                    }));
                                } else {
                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataAndamentoMaiorIgual: '',
                                        dataAndamentoMenorIgual: '',
                                    }));
                                }
                            }}
                        />

                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-dataCadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
                        <DateRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            maxDate={new Date()}
                            value={
                                (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                                    [
                                        filtrosPesquisa.dataCadastroMaiorIgual,
                                        filtrosPesquisa.dataCadastroMenorIgual,
                                    ] : ''
                            }
                            onChange={(datas: Date[]) => {
                                if (!datas || datas.length === 0) {
                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataCadastroMaiorIgual: '',
                                        dataCadastroMenorIgual: '',
                                    }));
                                    return;
                                }

                                let data_inicial = datas[0];
                                let data_final = datas[1];

                                const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                                if (isValidDate(data_inicial)) {
                                    if (isValidDate(data_final)) {
                                        data_final.setHours(0, 0, 0, 0);
                                        data_final = new Date(data_final.getTime() - 1);
                                    }

                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataCadastroMaiorIgual: `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`,
                                        dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                    }));
                                } else {
                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataCadastroMaiorIgual: '',
                                        dataCadastroMenorIgual: '',
                                    }));
                                }
                            }}

                        />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-tipoAndamento" className="form-label fw-bolder text-orange">
                            Tipo de Andamento:
                        </label>
                        <MultiSelect
                            isLoading={carregandoTipoAndamento}
                            disabled={carregandoTipoAndamento}
                            options={tipoAndamento}
                            value={tipoAndamentoSelecionados}
                            onChange={(event: any) => {
                                setTipoAndamentoSelecionados(event);
                                setIdsSelect("tipoAndamentoId", event);
                            }}
                            labelledBy={"Selecione..."}
                            overrideStrings={{
                                selectSomeItems: "Selecione...",
                                allItemsAreSelected: "Todos selecionados",
                                selectAll: "Selecione todos",
                                search: "Pesquise",
                                selectAllFiltered: "Selecione todos (filtrados)"
                            }}
                        />
                    </div>


                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                            Status:
                        </label>
                        <MultiSelect
                            isLoading={false}
                            disabled={false}
                            options={statusOptions}
                            value={statusSelected}
                            onChange={(event: any) => setStatusSelected(event)}
                            labelledBy={"Selecione..."}
                            overrideStrings={{
                                selectSomeItems: "Selecione...",
                                allItemsAreSelected: "Todos selecionados",
                                selectAll: "Selecione todos",
                                search: "Pesquise",
                                selectAllFiltered: "Selecione todos (filtrados)"
                            }}

                        />
                    </div>

                    <div className="col-12"
                        style={{ textAlign: 'right' }}>
                        <button
                            type="submit"
                            className="btn btn-orange search-buttom-margin ms-2"
                            title="Buscar"
                        >
                            <FontAwesomeIcon color='white' className='' icon={faSearch} />
                        </button>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                limparFiltros();
                            }}
                            className="btn btn-orange search-buttom-margin ms-2"
                            title="Limpar Filtro"
                        >
                            <FontAwesomeIcon color='white' className='' icon={faEraser} />
                        </button>
                        <button
                            onClick={() => toggleConfimarModeloModal()}
                            className="btn btn-orange search-buttom-margin ms-2"
                            disabled={carregandoAndamentos}>
                            <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                        </button>
                    </div>
                </form>
            </div>

            <div className="col-md-12 mb-10">
                <GridPadrao
                    onSort={handleSort}
                    progressPending={carregandoAndamentos}
                    limit={filtrosPesquisa.limit}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer={true}
                    paginationTotalRows={filtrosPesquisa.totalItems}
                    colunas={colunas} tipo='Andamentos' itens={andamentos} />
            </div>

        </div>
    </>)
}

export default AndamentoPage;
