import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoConsulta from "../interfaces/ITipoConsulta";
import { AdicionaTipoConsultaRequest } from "../interfaces/Requests/TipoConsulta/AdicionaTipoConsultaRequest";
import FiltroPesquisaTipoConsultaRequest from "../interfaces/Requests/TipoConsulta/FiltroPesquisaTipoConsultaRequest";
import { EditarTipoConsultaRequest } from "../interfaces/Requests/TipoConsulta/EditarTipoConsultaRequest";

class TipoConsultaService {
  async obterTipoConsultas(
    filtros: FiltroPesquisaTipoConsultaRequest
  ): Promise<IRecuperaLista<ITipoConsulta>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ITipoConsulta>>(
      `TipoConsulta/RecuperaTipoConsulta?${query}${limit}`
    );

    return result;
  }

  async adicionaTipoConsulta(
    adicionaTipoConsulta: AdicionaTipoConsultaRequest
  ): Promise<ITipoConsulta> {
    let { data: result } = await api.post<ITipoConsulta>(
      `TipoConsulta/AdicionaTipoConsulta`,
      adicionaTipoConsulta
    );

    return result;
  }

  async alterarStatus(tipoConsultaId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ITipoConsulta>(
      `TipoConsulta/AtualizaTipoConsultaPorId?id=${tipoConsultaId}`,
      data
    );
  }

  async atualizarTipoConsulta(
    editarTipoConsulta: EditarTipoConsultaRequest
  ): Promise<void> {
    let dataTipoConsulta = [
      { op: "replace", path: "/nome", value: editarTipoConsulta.nome },
      { op: "replace", path: "/codigo", value: editarTipoConsulta.codigo },
    ];

    await Promise.all([
      await api.patch<void>(
        `TipoConsulta/AtualizaTipoConsultaPorId?id=${editarTipoConsulta.tipoConsultaId}`,
        dataTipoConsulta
      ),
    ]);
  }
}

export default new TipoConsultaService();
