import React, { CSSProperties } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconType } from "react-icons";
import { valorAbreviado } from "../../../../utils/Utils";
import IWidget, { IWidgetsTamanhos } from "../interfaces/IWidget";
import { IWidgetDado } from "../interfaces/IWidgetDataSet";
import WidgetWrapper from "../WidgetWrapper";



export interface CardEstilos extends CSSProperties{
        icon?: IconType;
        iconColor?: string;
        iconBackgroundColor?: string;
        iconGrow?: boolean;
        iconSize: string;
        size?: number;
}
export interface CardProps extends IWidget{
    estilos?: CardEstilos[];
    tipo?: "vertical" | "compacto";
    
    
}

export default class CardWidget extends React.Component<CardProps>{
    tipo = 'vertical';

    constructor(props:CardProps) {
        super(props);
        this.tipo = props.tipo || 'vertical';
        if(props.ref){
            props.ref(this);
        }
    }


    private tamanhos(){
        return {
            xs: this.props.xs || '',
            sm: this.props.sm || '',
            md: this.props.md || '',
            lg: this.props.lg || '',
            xl: this.props.xl || '',
        }  as IWidgetsTamanhos
    }

    private getClassName(){
        let className = this.props.ClassName ||'';

        if(this.getDados().length === 1){
            className += ' unico';
        }

        if(this.props.desativado){
            className += ' widget-desativado'
        } else{
            className += 'widget'
        }

        return className;
    }

    getDados(){
        return (this.props.dados.dados as IWidgetDado[]).map((dado, index)=>{
            return {
                etiqueta: (this.props.dados.etiquetas as any[])[index],
                valor: dado.valor
            } as IWidgetDado;
        });
    }

    tamanhoAjustado(estilo: CardEstilos|undefined): string{
        if(estilo?.size){
            return '-'+Math.max(1, Math.min(12, estilo.size));
        }
        return '';
    }

    icone(estilo: CardEstilos|undefined): JSX.Element{
        if(estilo?.icon){
            const style: CSSProperties = {}
            if(estilo?.iconColor){
                style['color'] = estilo.iconColor;
            }
            if(estilo?.iconBackgroundColor){
                style['backgroundColor'] = estilo.iconBackgroundColor;
            }
            if(estilo?.iconSize){
                style['fontSize'] = estilo.iconSize;
            }
            const className = `icone${estilo?.iconGrow === false ? ' estatico' : ''}`;
            return <div className={className} style={style}><estilo.icon/></div>;
        }
        return <></>
    }

    estilo(estilo: CardEstilos|undefined): CSSProperties{
        return estilo as CSSProperties;

    }

    renderValorCompletoFormatado = (valor: any, index: number) => (
        <Tooltip id="button-tooltip">
         { index === 0 ? valor : valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </Tooltip>
      );
    
    
    render(){
        return  <WidgetWrapper
            titulo={this.props.titulo}
            className={this.getClassName()}
            editando={this.props.editando}
            tamanhos={this.tamanhos()}
        >
            <div className="widget-card-conteiner">
                {this.getDados().map((dado, index) => {
                    return <div 
                        key={index}                          
                        className={`widget-card ${this.tipo.replace('vertical', '')} col${this.tamanhoAjustado(this.props.estilos?.[index])}`}
                    >
                        <div
                            className="card"
                            style={this.estilo(this.props.estilos?.[index])}
                        >
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.renderValorCompletoFormatado(dado.valor as string, index)}
                            >                          
                            <div className="card-body">
                                {this.icone(this.props.estilos?.[index])}
                                {dado.etiqueta && 
                                    <div className="etiqueta">{dado.etiqueta}</div>
                                }
                                <div className="valor">{valorAbreviado(Number(dado.valor),1)}</div>
                            </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                })} 
                </div>
        </WidgetWrapper>
    }

    dataSetDados(): IWidgetDado[] {
        return this.props.dados.dados as IWidgetDado[];
    }
    
}
