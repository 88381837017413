import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { DragDropContext } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import {  faInfo, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { ICampo } from "../../../interfaces/ICampo";
import { ETelaEnum } from "../../../enum/ETelaEnum";

import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageForButton from "../../../layout/LayoutPageButton";
import CamposXField from "../XCampos/CamposXField";
import Column from "./Column/Column";
import "./../Styles/dragdrop.css";
import { tipoPorModulo } from "../../../utils/Utils";
import { EModulo } from "../../../enum/EModulos";
import { notifySuccess } from "../../../components/Comum/Toast/ToastFormik";

const ConfiguracaoTelaPage = () => {
  const [carregandoConfiguracaoTela, setCarregandoConfiguracaoTela] = useState<boolean>(false);
  const [processamentoAoSalvar, setProcessamentoAoSalvar] = useState<boolean>(false);
  const { tipoReembolso, tela, setTela, setCampos, campos, eCampos, setECampos } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();
  const [columns, setColumns] = useState<any>({
    todo: {
      id: "todo",
      list: [],
    },
    doing: {
      id: "doing",
      list: [],
    },
  });
  const [exibirModalXCampos, setExibirModalXCampos] = useState<boolean>(false);

  const setarOrdenacao = () => columns.doing.list.forEach((campo: ICampo, index: number) => (campo.ordenacao = index)); // preencher o campo ordenação de acordo com a index

  const toggleXCampos = (): void => setExibirModalXCampos(!exibirModalXCampos);

  useEffect(() => {
    if (!carregandoConfiguracaoTela) {
      carregarConfiguracaoDoing();
      carregarConfiguracaoTodo();
    }
  }, [tela]);

  useEffect(() => {
    if (tipoReembolso.categoriaId === 0) navigate("Reembolso/ConfiguracaoTela/TipoReembolso");
  }, [tipoReembolso]);

  useEffect(() => {
    setarOrdenacao();
    if (columns.doing.list.length > 0) {
      setCampos(columns.doing.list);
    }
  }, [columns.doing]);


  useEffect(() => {
    if (tela === ETelaEnum.TelaCadastroReembolso)
      setColumns({
        todo: {
          id: "todo",
          list: tratarECampos(eCampos, campos),
        },
        doing: {
          id: "doing",
          list: campos,
        },
      });

    if (tela === ETelaEnum.TelaCapaReembolso)
      setColumns({
        todo: {
          id: "todo",
          list: tratarECampos(eCampos, campos),
        },
        doing: {
          id: "doing",
          list: campos,
        },
      });
  }, [campos.length, eCampos.length]);

  const onDragEnd = ({ source, destination }: any) => {
    try {
      if (destination === undefined || destination === null) return null;

      if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

      const start = columns[source.droppableId];
      const end = columns[destination.droppableId];

      if (start === end) {
        const newList = start.list.filter((_: any, idx: number) => idx !== source.index);

        newList.splice(destination.index, 0, start.list[source.index]);
        const newCol = {
          id: start.id,
          list: newList,
        };

        setColumns((state: any) => ({ ...state, [newCol.id]: newCol }));
      } else {
        const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);

        const newStartCol = {
          id: start.id,
          list: newStartList,
        };
        const newEndList = end.list;

        newEndList.splice(destination.index, 0, eCampos.filter((e) => e.campo === start.list[source.index].campo)[0]);

        const newEndCol = {
          id: end.id,
          list: newEndList,
        };

        setColumns((state: any) => ({
          ...state,
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol,
        }));
        setarOrdenacao();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const alterarItem = (item: ICampo) => {
    columns.doing.list = columns.doing.list.filter((e: ICampo) => e.campo !== item.campo);
    columns.doing.list.splice(item.ordenacao, 0, item);
    setColumns({
      todo: {
        id: "todo",
        list: columns.todo.list,
      },
      doing: {
        id: "doing",
        list: columns.doing.list,
      },
    });
  };

  const tratarECampos = (todo: any, doing: any) => {
    let lista: ICampo[] = [];
    todo.forEach((campo: any, index: number) => {
      if (doing.filter((e: any) => e.campo === campo.campo).length == 0) {
        lista.push({ ...campo, ordenacao: (index + 1) });
      }
    });

    return lista;
  }

  const carregarConfiguracaoTodo = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let todo: IRecuperaLista<any>;
      todo = await ConfiguracaoTelaService.obterTodo(tela);
      setECampos(todo.data);

      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setECampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const carregarConfiguracaoDoing = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(tipoReembolso.modulo)}Id=${tipoReembolso.categoriaId}`);

      let doing: IRecuperaLista<any>;
      doing = await ConfiguracaoTelaService.obterDoing({
        ...objeto,
        tela: tela,
        modulo: tipoReembolso.modulo,
        tipoReembolsoId: tipoReembolso.opcaoId
      });
      setCampos(doing.data);

      setarOrdenacao();
      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setCampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const adicionarConfiguracao = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Salvar Configuração de Tela ",
        text: `Você realmente deseja salvar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        setProcessamentoAoSalvar(true);

        let objeto: any = {};

        eval(`objeto.${tipoPorModulo(tipoReembolso.modulo)}Id=${tipoReembolso.categoriaId}`);

        await ConfiguracaoTelaService.adicionaConfiguracaoTela({
          ...objeto,
          tela: tela,
          modulo: tipoReembolso.modulo,
          camposList: columns.doing.list,
          tipoReembolsoId: tipoReembolso.opcaoId
        });

       notifySuccess('Campos cadastrados com sucesso');
       
      }
      setProcessamentoAoSalvar(false);
    } catch (error: any) {
      setProcessamentoAoSalvar(false);
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel cadastrar os campos.`,
        text: error?.response?.data?.Message,
        showConfirmButton: true,
      });
    }
  };

  const popover = () => (
    <Popover id="popover-basic">
      <Popover.Body style={{ cursor: "pointer" }}>
        {!carregandoConfiguracaoTela ? (
          <>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Cadastro</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(ETelaEnum.TelaCadastroReembolso);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-input"></span>
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Capa</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(ETelaEnum.TelaCapaReembolso);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
                </Button>
              )}
            </OverlayTrigger>
          </>
        ) : (
          <span className="indicator-progress" style={{ display: "block" }}>
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <CamposXField setCamposList={setCampos} camposList={campos} exibirModal={exibirModalXCampos} toggleModal={toggleXCampos} modulo={EModulo.XpayReembolso} />
      <ToastContainer />
      <LayoutPageTitle title={(tela === ETelaEnum.TelaCadastroReembolso ? "Cadastro" : "Capa") + " - " + tipoReembolso.categoriaNome}>
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover()}>
          <a style={{ cursor: "pointer" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageTitle>

      <LayoutPageForButton title={""}>
        <div className="col-md-11">
          <button disabled={processamentoAoSalvar} onClick={() => setExibirModalXCampos(true)} type="button" className="btn btn-orange me-5">
            X CAMPOS <FontAwesomeIcon color={"white"} className="mx-2" icon={faInfo} />
          </button>
        </div>
        <div className="col-md-12">
          <button disabled={processamentoAoSalvar} onClick={() => adicionarConfiguracao()} type="button" className="btn btn-orange me-5">
            {!processamentoAoSalvar && (
              <>
                SALVAR
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
              </>
            )}
            {processamentoAoSalvar && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Salvando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </LayoutPageForButton>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row mt-2">
          {Object.values(columns).map((col: any) => (
            <div className="col-md-6">
              {col.id === "doing" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique para configurar os campos selecionados.
                    </h6>
                  </div>
                </p>
              )}
              {col.id === "todo" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique segure e arraste para selecionar um campo.
                    </h6>
                  </div>
                </p>
              )}
              <div style={{ overflow: "auto", height: "650px" }}>
                <Column col={col} key={col.id} alterarItem={alterarItem} />
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

export default ConfiguracaoTelaPage;
