import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IModulo from "../interfaces/IModulo";
import { AdicionaModuloRequest } from "../interfaces/Requests/Modulo/AdicionaModuloRequest";
import FiltroPesquisaModuloRequest from "../interfaces/Requests/Modulo/FiltroPesquisaModuloRequest";
import { EditarModuloRequest } from "../interfaces/Requests/Modulo/EditarModuloRequest";

class ModuloService {

    async obterModulos(filtros: FiltroPesquisaModuloRequest): Promise<IRecuperaLista<IModulo>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}`

        query += filtros.limit !== 0 ? `&limit=${filtros.limit}` : '';

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IModulo>>(`Modulo/RecuperaModulo?${query}`);

        return result;
    }

    async adicionaModulo(adicionaModulo: AdicionaModuloRequest): Promise<IModulo> {
        let { data: result } = await api.post<IModulo>(`Modulo/AdicionaModulo`, adicionaModulo);

        return result;
    }


    async alterarStatus(moduloId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IModulo>(`Modulo/AtualizaModuloPorId?id=${moduloId}`, data);
    }

    async atualizarModulo(editarModulo: EditarModuloRequest): Promise<void> {

        let dataModulo = [
            { "op": "replace", "path": "/nome", "value": editarModulo.nome },
            { "op": "replace", "path": "/codigo", "value": editarModulo.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Modulo/AtualizaModuloPorId?id=${editarModulo.moduloId}`, dataModulo)
        ]);
    }
}


export default new ModuloService();