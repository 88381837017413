import { ToastContainer } from "react-toastify";
import { Board } from "./components/Board";
import { Filters } from "./components/Filters";
import { Wrapper } from "./styles";

export function Kanban() {
  return (
    <Wrapper>
      <ToastContainer />
      <Filters />
      <Board />
    </Wrapper>
  )
}