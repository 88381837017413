import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IApresentacaoMarca from "../interfaces/IApresentacaoMarca";
import { AdicionaApresentacaoMarcaRequest } from "../interfaces/Requests/ApresentacaoMarca/AdicionaApresentacaoMarcaRequest";
import FiltroPesquisaApresentacaoMarcaRequest from "../interfaces/Requests/ApresentacaoMarca/FiltroPesquisaApresentacaoMarcaRequest";
import { EditarApresentacaoMarcaRequest } from "../interfaces/Requests/ApresentacaoMarca/EditarApresentacaoMarcaRequest";

class ApresentacaoMarcaService {

    async obterApresentacaoMarcas(filtros: FiltroPesquisaApresentacaoMarcaRequest): Promise<IRecuperaLista<IApresentacaoMarca>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IApresentacaoMarca>>(`ApresentacaoMarca/RecuperaApresentacaoMarca?${query}`);

        return result;
    }

    async adicionaApresentacaoMarca(adicionaApresentacaoMarca: AdicionaApresentacaoMarcaRequest): Promise<IApresentacaoMarca> {
        let { data: result } = await api.post<IApresentacaoMarca>(`ApresentacaoMarca/AdicionaApresentacaoMarca`, adicionaApresentacaoMarca);

        return result;
    }


    async alterarStatus(apresentacaoMarcaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IApresentacaoMarca>(`ApresentacaoMarca/AtualizaApresentacaoMarcaPorId?id=${apresentacaoMarcaId}`, data);
    }

    async atualizarApresentacaoMarca(editarApresentacaoMarca: EditarApresentacaoMarcaRequest): Promise<void> {

        let dataApresentacaoMarca = [
            { "op": "replace", "path": "/nome", "value": editarApresentacaoMarca.nome },
            { "op": "replace", "path": "/codigo", "value": editarApresentacaoMarca.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`ApresentacaoMarca/AtualizaApresentacaoMarcaPorId?id=${editarApresentacaoMarca.apresentacaoMarcaId}`, dataApresentacaoMarca)
        ]);
    }
}


export default new ApresentacaoMarcaService();