import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 10px;
  z-index: 999;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  div.flex-row {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 6px;
  }

  div#document-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: var(--bs-orange);

    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      font-size: 20px;
      color: #fff;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;

  > nav {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Triggers = styled.div`
  display: flex;
  gap: 5px;

  > button.comment_btn {
    border: none;
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 10px;
    gap: 6px;
    background-color: var(--bs-blue);
    color: #fff;
    border-radius: 4px;

    > span {
      font-weight: 500;
      letter-spacing: -1px;
      font-size: 14px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const FormEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > input {
    border: none;
    border-radius: 4px;
    outline: 0;
    height: 30px;
    font-size: 20px;
    font-weight: 500;
    max-width: 500px;
    text-overflow: ellipsis;

    &:focus {
      border: 1px solid #ddd;
    }
  }
`;

export const ActionButton = styled.button<{ backgroundColor?: string }>`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${props => props.backgroundColor || 'var(--bs-primary)'};
  color: #fff;
  transition: opacity 0.3s ease-out;
  font-size: 20px;

  &:hover {
    opacity: 0.7;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;

  > span {
    font-size: 12px;
    font-weight: 500;
  }
`;
