import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import clsx from "clsx";
import XLearningService from "../../services/XLearningService";
import { useEffect, useState } from "react";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IModulo from "../../interfaces/IModulo";
import ModuloService from "../../services/ModuloService";

interface ICriarXlearningModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    titulo: string,
}

const CriarEnvelopeModal = ({ toggleModal, exibirModal, titulo }: ICriarXlearningModalProps) => {

    const novoXlearningValidacao = Yup.object().shape({
        arquivo: Yup.mixed().required("Arquivo obritagório.")
            .test(
                'type', 'O arquivo deve ser do tipos pdf ou mp4.', (arquivo, contexto) => {
                    console.log(arquivo)
                    const caminho = arquivo as string;
                    return (
                        caminho !== undefined &&
                        (
                            caminho.endsWith('.pdf') ||
                            caminho.endsWith('.mp4')
                        )
                    );
                }
            ),
        codigo: Yup.string().required("Código obritagório.").min(5, "Código deve ter no mínimo 5 caracteres."),

        descricao: Yup.string().required("Descrição obritagória.").min(5, "Descrição deve ter no mínimo 5 caracteres."),

        //modulo: Yup.string().required("Módulo obritagório."),

        tipoDocumentoId: Yup.string().required("Tipo de Documento obritagório.").min(1, "Campo tipo de documento é obrigatório"),

        modulo: Yup.number().required("Campo módulo é obrigatório").min(1, "Campo módulo é obrigatório")
    })

    const [carregandoModulo, setCarregandoModulo] = useState(false);
    const [modulos, setModulos] = useState<IModulo[]>([]);

    async function carregarModulo() {
        try {
            setCarregandoModulo(true);
            let resultado: IRecuperaLista<IModulo>;

            resultado = await ModuloService.obterModulos({
                nome: "",
                codigo: "",
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "nome"
            });
            setModulos(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setModulos([]);
        } finally {
            setCarregandoModulo(false);
        }
    }

    useEffect(() => {
        carregarModulo();
    }, []);

    const formularioNovoXlearning = useFormik({
        initialValues: { arquivo: "", codigo: "", descricao: "", modulo: 0, tipoDocumentoId: 0 },
        validationSchema: novoXlearningValidacao,
        onSubmit: async (values, { setSubmitting }) => {
            const campo = document.querySelector('#form-arquivo')

            if (!values.arquivo || !campo) return;

            const arquivos: FileList | null = (campo as HTMLInputElement).files;

            if (!arquivos || arquivos.length === 0) return;

            for (let i = 0; i < arquivos.length; i++) {
                if (!['application/pdf'].includes(arquivos[i].type) && !arquivos[i].name?.endsWith('.mp4')) {
                    return await Swal.fire({
                        title: 'Arquivo inválido.',
                        text: 'O arquivo deve ser do tipo pdf ou mp4.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }

            const enviados = [];

            Swal.fire({
                heightAuto: false,
                icon: "info",
                text: `Enviando arquivos...`,
                allowOutsideClick: false,
                showConfirmButton: false
            });

            Swal.showLoading()

            for (let i = 0; i < arquivos.length; i++) {
                const dados = {
                    "Modulo": values.modulo,
                    "Origem": "9",
                    "File": arquivos[i],
                    "TipoDocumentoId": values.tipoDocumentoId,
                    "Descricao": values.descricao,
                    "Restrito": "false",
                    "Codigo": values.codigo
                }

                try {
                    enviados.push(await XLearningService.upload(dados));

                    await Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Conteúdo cadastrado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000
                    });

                    toggleModal();

                } catch (error: any) {
                    setSubmitting(false);
                    return Swal.fire({
                        heightAuto: false,
                        icon: 'error',
                        title: `Não foi possível Enviar o arquivo.`,
                        text: (error.response) ? (typeof error.response?.data === 'string' ? error.response.data : error.response.data.message) : error.message,
                        showConfirmButton: true
                    });
                }
            }

            setSubmitting(false);

            Swal.close();
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModal} backdrop='static' onEscapeKeyDown={(e) => { e.preventDefault(); return false }} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">

                        <div className="row mt-2">

                            <div className="col-md-6 mt-3">
                                <label htmlFor="form-tipoDocumentoId" className="form-label required fw-bolder text-orange">
                                    Tipo de Documento:
                                </label>
                                <select
                                    {...formularioNovoXlearning.getFieldProps("tipoDocumentoId")}
                                    value={formularioNovoXlearning.values.tipoDocumentoId}
                                    onChange={(e) => {
                                        formularioNovoXlearning.setFieldTouched("tipoDocumentoId", true);
                                        formularioNovoXlearning.setFieldValue("tipoDocumentoId", e.target.value);
                                    }}
                                    className={clsx(
                                        "form-select",
                                        {
                                            "is-invalid": formularioNovoXlearning.touched.tipoDocumentoId && formularioNovoXlearning.errors.tipoDocumentoId,
                                        },
                                        {
                                            "is-valid": formularioNovoXlearning.touched.tipoDocumentoId && !formularioNovoXlearning.errors.tipoDocumentoId,
                                        }
                                    )}
                                    id="form-tipoDocumentoId"
                                >
                                    <option value="" label="Selecione">Selecione</option>
                                    <option value="53" label="Manual">Manual</option>
                                    <option value="54" label="Vídeo">Video</option>
                                </select>

                                {formularioNovoXlearning.touched.tipoDocumentoId && formularioNovoXlearning.errors.tipoDocumentoId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioNovoXlearning.errors.tipoDocumentoId}</span>
                                        </div>
                                    </div>
                                )}
                            </div>


                            {carregandoModulo ? (
                                <>Carregando módulos...</>
                            ) : (
                                <div className="col-md-6 mt-3">
                                    <label htmlFor="form-modulo" className="form-label required fw-bolder text-orange">
                                        Módulo:
                                    </label>
                                    <select
                                        {...formularioNovoXlearning.getFieldProps("modulo")}
                                        value={formularioNovoXlearning.values.modulo}
                                        onChange={(e) => {
                                            formularioNovoXlearning.setFieldTouched("modulo", true);
                                            formularioNovoXlearning.setFieldValue("modulo", parseInt(e.target.value));
                                        }}
                                        className={clsx(
                                            "form-select",
                                            {
                                                "is-invalid": formularioNovoXlearning.touched.modulo && formularioNovoXlearning.errors.modulo,
                                            },
                                            {
                                                "is-valid": formularioNovoXlearning.touched.modulo && !formularioNovoXlearning.errors.modulo,
                                            }
                                        )}
                                        id="form-modulo"
                                    >
                                        <option value="0"> Selecione um módulo </option>
                                        {modulos.map((map) => {
                                            return (
                                                <option key={map.moduloId} value={map.moduloId}>
                                                    {" "}
                                                    {map.nome}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {formularioNovoXlearning.touched.modulo && formularioNovoXlearning.errors.modulo && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioNovoXlearning.errors.modulo}</span>
                                        </div>
                                    </div>
                                )}
                                </div>
                            )}
                        </div>

                        <div className="row mt-2">

                            <div className="col-12 col-lg-6">
                                <label htmlFor="form-codigo" className="form-label required fw-bolder text-orange">Código</label>
                                <input {...formularioNovoXlearning.getFieldProps('codigo')} placeholder="Insira o Código" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formularioNovoXlearning.touched.codigo && formularioNovoXlearning.errors.codigo,
                                    },
                                    {
                                        'is-valid': formularioNovoXlearning.touched.codigo && !formularioNovoXlearning.errors.codigo,
                                    }
                                )} id="form-codigo" />
                                {formularioNovoXlearning.touched.codigo && formularioNovoXlearning.errors.codigo && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioNovoXlearning.errors.codigo}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-12 col-lg-6">
                                <label htmlFor="form-arquivo" className="form-label required fw-bolder text-orange">Arquivos</label>
                                <input {...formularioNovoXlearning.getFieldProps('arquivo')} placeholder="Selecione os arquivos" type="file" multiple={true} className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formularioNovoXlearning.touched.arquivo && formularioNovoXlearning.errors.arquivo,
                                    },
                                    {
                                        'is-valid': formularioNovoXlearning.touched.arquivo && !formularioNovoXlearning.errors.arquivo,
                                    }
                                )} id="form-arquivo" />
                                {formularioNovoXlearning.touched.arquivo && formularioNovoXlearning.errors.arquivo && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioNovoXlearning.errors.arquivo}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-12 col-lg-15">
                                <label htmlFor="form-descricao" className="form-label required fw-bolder text-orange">Descrição</label>
                                <textarea {...formularioNovoXlearning.getFieldProps('descricao')}
                                    placeholder="Insira a descrição"
                                    maxLength={4000}
                                    className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formularioNovoXlearning.touched.descricao && formularioNovoXlearning.errors.descricao,
                                        },
                                        {
                                            'is-valid': formularioNovoXlearning.touched.descricao && !formularioNovoXlearning.errors.descricao,
                                        }
                                    )} id="form-descricao" />
                                {formularioNovoXlearning.touched.descricao && formularioNovoXlearning.errors.descricao && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioNovoXlearning.errors.descricao}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formularioNovoXlearning.isSubmitting || !formularioNovoXlearning.isValid} onClick={() => { formularioNovoXlearning.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formularioNovoXlearning.isSubmitting && <> SALVAR </>}
                        {formularioNovoXlearning.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formularioNovoXlearning.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default CriarEnvelopeModal;