import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { faClone, faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { ETelaEnum } from "../../../enum/ETelaEnum";

import TipoAtoService from "../../../services/TipoAtoService";

import ITipoAto from "../../../interfaces/ITipoAto";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaTipoAtoRequest from "../../../interfaces/Requests/TipoAto/FiltroPesquisaTipoAtoRequest";

import GridPadrao from "../../../components/Comum/GridPadrao";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import "../Styles/icons.css";
import DuplicarParametrizacaoModal from "../../../components/DuplicarParametrizacao/DuplicarParametrizacaoModal";

const TipoAtoPesquisaPage = () => {
  const [tipoAtos, setTipoAtos] = useState<ITipoAto[]>([]);
  const [tipoAtosCloneModal, setTipoAtosCloneModal] = useState<ITipoAto[]>([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoTipoAto, setCarregandoTipoAto] = useState(false);
  const { setTipoAto, setCampos, setTela } =
    useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaTipoAtoRequest>({
      nome: "",
      codigo: "",
      status: 1,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "tipoAtoId",
    });

  useLayoutEffect(() => {
    setCampos([]);

    setTela(ETelaEnum.Default);

    if (!carregandoTipoAto) carregarTipoAto(filtrosPesquisa);
  }, []);

  async function carregarTipoAto(filtro: FiltroPesquisaTipoAtoRequest) {
    try {
      setCarregandoTipoAto(true);
      let resultado: IRecuperaLista<ITipoAto>;
      resultado = await TipoAtoService.obterTipoAtos(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setTipoAtos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoAtos([]);
    } finally {
      setCarregandoTipoAto(false);
      setLoadingInicial(false);
    }
  }

  async function carregarTipoAtoCloneModal() {
    try {
      let resultado: IRecuperaLista<ITipoAto>;
      resultado = await TipoAtoService.obterTipoAtos({
        codigo: "",
        limit: 1000,
        nome: "",
        offset: 0,
        sort: "nome",
        status: 1,
        totalItems: 0,
      });

      setTipoAtosCloneModal(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  const colunas: TableColumn<ITipoAto>[] = [
    {
      name: "Id",
      sortField: "TipoAtoId",
      selector: (row: ITipoAto) => row.tipoAtoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "TipoAtoNome",
      selector: (row: ITipoAto) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: ITipoAto) => row.codigo,
      sortable: true,
      wrap: true,
    },
  ];

  const onRowClicked = (row: ITipoAto) => {
    setTipoAto(row);
    navigate("/Ato/ConfiguracaoTela/Solucoes");
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<ITipoAto>,
    sortDirection: string
  ) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
        status: 1,
      };
    });
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarTipoAto(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageTitle title="Selecione o tipo de ato que você deseja configurar">
        {" "}
      </LayoutPageTitle>

      <DuplicarParametrizacaoModal
        exibirModal={exibirModal}
        handleClose={async () => {
          setExibirModal(false);

          await carregarTipoAto(filtrosPesquisa);
        }}
        itens={tipoAtosCloneModal}
        idKey="tipoAtoId"
        modalTitle="Copiar configurações de um Tipo de Ato para o outro"
        moduloId={5}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarTipoAto(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2 ">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Nome
              </label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>
            <div className="col-md-2 ">
              <label
                htmlFor="form-codigo"
                className="form-label fw-bolder text-orange"
              >
                Código
              </label>
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={"form-control"}
                id="form-codigo"
              />
            </div>
            <div className="col-md-2">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Status
              </label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, status: parseInt(e.target.value) };
                  });
                }}
                placeholder="Nome"
                className={"form-select"}
                id="form-select"
              >
                <option value="0" label="Todos">
                  {" "}
                  Todos{" "}
                </option>
                <option value="1" label="Ativo">
                  {" "}
                  Ativo{" "}
                </option>
                <option value="-1" label="Inativo">
                  {" "}
                  Inativo{" "}
                </option>
              </select>
            </div>
            <div className="col-md-2">
              <div>
                <button
                  type="submit"
                  className="btn btn-sm btn-orange search-buttom-margin "
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faSearch}
                  />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faEraser}
                  />
                </button>
                <button
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  onClick={async (e) => {
                    e.preventDefault();
                    await carregarTipoAtoCloneModal();
                    setExibirModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faClone}
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            onRowClicked={onRowClicked}
            progressPending={carregandoTipoAto}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Tipo Ato"
            itens={tipoAtos}
          />
        </div>
      </div>
    </>
  );
};

export default TipoAtoPesquisaPage;
