
interface layout {
    title: string
    children?: any
}

const LayoutPageForButton = ({ children, title }: layout) => {
    return (<>
        <div className="d-flex text-orange justify-content-between flex-wrap flex-md-nowrap align-items-center pt-1 pb-1 mb-0">
            <h4>{title}</h4>
            {children}
        </div>
    </>)
}
export default LayoutPageForButton;