import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaAdvogadoRequest from "../interfaces/Requests/FiltroPesquisaAdvogadoRequest";
import api from "./Api";

class AdvogadoInternoService {
    async obterAdvogadosPorFiltro(filtros: FiltroPesquisaAdvogadoRequest): Promise<IRecuperaLista<IAdvogadoInterno>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`
        
        if (filtros.tipoPesquisa === 1 && filtros.filtro.length > 0) {
            query += `&nome=${filtros.filtro}`;
        }

        if (filtros.tipoPesquisa === 2 && filtros.filtro.length > 0) {
            query += `&documento=${filtros.filtro}`;
        }

        if (filtros.tipoPesquisa === 3 && filtros.filtro.length > 0) {
            query += `&codigo=${filtros.filtro}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IAdvogadoInterno>>(`AdvogadoInterno/RecuperaAdvogadoInterno?${query}`);

        return result;
    }
}

export default new AdvogadoInternoService();