import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IAtoRegistradoJunta from "../interfaces/IAtoRegistradoJunta";
import { AdicionaAtoRegistradoJuntaRequest } from "../interfaces/Requests/AtoRegistradoJunta/AdicionaAtoRegistradoJuntaRequest";
import FiltroPesquisaAtoRegistradoJuntaRequest from "../interfaces/Requests/AtoRegistradoJunta/FiltroPesquisaAtoRegistradoJuntaRequest";
import { EditarAtoRegistradoJuntaRequest } from "../interfaces/Requests/AtoRegistradoJunta/EditarAtoRegistradoJuntaRequest";

class AtoRegistradoJuntaService {

    async obterAtoRegistradoJuntas(filtros: FiltroPesquisaAtoRegistradoJuntaRequest): Promise<IRecuperaLista<IAtoRegistradoJunta>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IAtoRegistradoJunta>>(`AtoRegistradoJunta/RecuperaAtoRegistradoJunta?${query}`);

        return result;
    }

    async adicionaAtoRegistradoJunta(adicionaAtoRegistradoJunta: AdicionaAtoRegistradoJuntaRequest): Promise<IAtoRegistradoJunta> {
        let { data: result } = await api.post<IAtoRegistradoJunta>(`AtoRegistradoJunta/AdicionaAtoRegistradoJunta`, adicionaAtoRegistradoJunta);

        return result;
    }


    async alterarStatus(atoRegistradoJuntaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IAtoRegistradoJunta>(`AtoRegistradoJunta/AtualizaAtoRegistradoJuntaPorId?id=${atoRegistradoJuntaId}`, data);
    }

    async atualizarAtoRegistradoJunta(editarAtoRegistradoJunta: EditarAtoRegistradoJuntaRequest): Promise<void> {

        let dataAtoRegistradoJunta = [
            { "op": "replace", "path": "/nome", "value": editarAtoRegistradoJunta.nome },
            { "op": "replace", "path": "/codigo", "value": editarAtoRegistradoJunta.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`AtoRegistradoJunta/AtualizaAtoRegistradoJuntaPorId?id=${editarAtoRegistradoJunta.atoRegistradoJuntaId}`, dataAtoRegistradoJunta)
        ]);
    }
}


export default new AtoRegistradoJuntaService();