import { faCheckCircle, faStopCircle, faEdit, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import CompromissoCancelarField from "../../../../components/Comum/Compromisso/CompromissoCancelarField";
import CompromissoConcluirField from "../../../../components/Comum/Compromisso/CompromissoConcluirField";
import CompromissoField from "../../../../components/Comum/Compromisso/CompromissoField";
import GridPadrao from "../../../../components/Comum/GridPadrao";
import ICompromisso from "../../../../interfaces/ICompromisso";
import IEmpresa from "../../../../interfaces/IEmpresa";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import IUsuario from "../../../../interfaces/IUsuario";
import CompromissoService from "../../../../services/CompromissoService";
import { EModulo } from "../../../../enum";

const Compromisso = (props: { empresa: IEmpresa }) => {
  const { empresa } = props;

  const [compromissoId, setCompromissoId] = useState<number>(0);
  const [atualizaTabelaCompromisso, setAualizarTabelaCompromisso] = useState<boolean>(false);
  const [compromissos, setCompromissos] = useState<ICompromisso[]>([]);
  const [carregandoCompromissos, setCarregandoCompromissos] = useState(false);

  const [exibirListaCompromisso, setExibirListaCompromisso] = useState<boolean>(false);
  const [exibirModalCompromisso, setExibirModalCompromisso] = useState<boolean>(false);
  const [exibirModalCompromissoCancelar, setExibirModalCompromissoCancelar] = useState<boolean>(false);
  const [exibirModalCompromissoConcluir, setExibirModalCompromissoConcluir] = useState<boolean>(false);

  const [editarCompromisso, setEditarCompromisso] = useState<ICompromisso>({
    modulo: 5,
    tipoCompromissoNome: "",
    compromissoId: 0,
    empresaId: 0,
    codigo: "",
    dataConclusao: new Date(),
    dataCompromisso: new Date(),
    observacaoCompromisso: "",
    observacaoConclusao: "",
    tipoCompromissoId: 0,
    usuariosAprovadoresList: [],
    horario: "",
    grupoId:[]
  });

  const [filtrosCompromissos, setFiltroCompromisso] = useState<any>({
    empresaId: empresa.empresaId,
    tipoPesquisa: 1,
    filtroPesquisa: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "compromissoId",
    status: 1,
  });

  const toggleExibirCompromisso = () => setExibirModalCompromisso(!exibirModalCompromisso);
 
  const toggleExibirCompromissoCancelar = () => setExibirModalCompromissoCancelar(!exibirModalCompromissoCancelar);

  const toggleExibirCompromissoConcluir = () => setExibirModalCompromissoConcluir(!exibirModalCompromissoConcluir);

  const carregarCompromissos = async (filtro: any) => {
    try {
      if (!empresa.empresaId) return;
      setCarregandoCompromissos(true);

      let resultado: IRecuperaLista<ICompromisso>;
      resultado = await CompromissoService.obter({ ...filtro, empresaId: empresa.empresaId });

      if (resultado.data != null) setCompromissos(resultado.data);
      else setCompromissos([]);

      setFiltroCompromisso((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoCompromissos(false);
    } catch (error: any) {
      setCompromissos([]);
      setCarregandoCompromissos(false);
    }
  };

  const handlePerRowsChangeCompromisso = async (currentRowsPerPage: number) => {
    setFiltroCompromisso((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChangeCompromisso = (page: number) => {
    setFiltroCompromisso((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosCompromissos.limit };
    });
  };

  const handleSortCompromisso = async (column: TableColumn<ICompromisso>, sortDirection: string) => {
    setFiltroCompromisso((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const colunasCompromissos: TableColumn<ICompromisso>[] = [
    {
      cell: (row: ICompromisso) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                className="form-label fw-bolder text-orange"
                style={{
                  paddingTop: "30px",
                }}
              >
                #{row.compromissoId} - {row.tipoCompromissoNome} - {row.status} - {moment(row.dataCompromisso).format("DD/MM/yyyy HH:mm:ss")}
              </p>
              <p>{row.observacaoCompromisso}</p>
              <p>{row.observacaoConclusao}</p>
              {row.status == "Pendente" && (
                <>
                  <p>
                    <>
                      <FontAwesomeIcon
                        title="Concluir"
                        onClick={async () => {
                          setExibirModalCompromissoConcluir(!exibirModalCompromissoConcluir);
                          setCompromissoId(row.compromissoId);
                        }}
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2 text-orange"
                        icon={faCheckCircle}
                      />

                      <FontAwesomeIcon
                        title="Cancelar"
                        onClick={async () => {
                          setExibirModalCompromissoCancelar(!exibirModalCompromissoCancelar);
                          setCompromissoId(row.compromissoId);
                        }}
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2 text-orange"
                        icon={faStopCircle}
                      />

                      <FontAwesomeIcon
                        title="Editar Compromisso"
                        onClick={async () => {
                          setEditarCompromisso({
                            modulo: 5,
                            empresaId: row.empresaId,
                            compromissoId: row.compromissoId,
                            codigo: row.codigo,
                            observacaoCompromisso: row.observacaoCompromisso,
                            tipoCompromissoId: row.tipoCompromissoId,
                            observacaoConclusao: row.observacaoConclusao,
                            tipoCompromissoNome: "",
                            usuariosAprovadoresList: row.usuariosAprovadoresList,
                            dataCompromisso: row.dataCompromisso,
                            dataConclusao: row.dataConclusao,
                            horario: moment(row.dataCompromisso).format("HH:mm"),
                            grupoId:[]
                          });
                          setExibirModalCompromisso(true);
                        }}
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2 text-orange"
                        icon={faEdit}
                      />
                    </>
                  </p>
                  <p>
                    <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                      Aprovadores:
                    </label>
                    {row.usuariosAprovadoresList.map((map: IUsuario) => {
                      return " " + map.nome + " - " + (map.principal ? "Principal" : "") + "; ";
                    })}
                  </p>
                </>
              )}
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];


  

  useEffect(() => {
    setFiltroCompromisso((oldState: any) => {
      return { ...oldState, ultimosQuantidade: 0 };
    });
    carregarCompromissos(filtrosCompromissos);
  }, [filtrosCompromissos.offset, filtrosCompromissos.limit, filtrosCompromissos.sort]);

  useEffect(() => {
    if (atualizaTabelaCompromisso) {
      setTimeout(() => {
        carregarCompromissos(filtrosCompromissos);
      }, 2000);
    }
    setAualizarTabelaCompromisso(false);
  }, [atualizaTabelaCompromisso]);
 
  useEffect(() => {
    carregarCompromissos(filtrosCompromissos);
  },[]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12 mt-2">
          <label
            style={{ cursor: "pointer" }}
            onClick={() => setExibirListaCompromisso(!exibirListaCompromisso)}
            htmlFor="form-cliente"
            className="form-label fw-bolder text-orange"
          >
            Últimos Compromissos {exibirListaCompromisso ? "-" : "+"}
          </label>
        </div>
        {exibirListaCompromisso && (
          <>
            <div className="col-md-12">
              <button
                onClick={() => {
                  setEditarCompromisso({ ...editarCompromisso, compromissoId: 0 });
                  setExibirModalCompromisso(!exibirModalCompromisso);
                }}
                className="btn btn-orange"
              >
                {exibirModalCompromisso && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                {!exibirModalCompromisso && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
              </button>
            </div>

            <div className="col-md-auto mt-3">
              <input
                value={filtrosCompromissos.filtroPesquisa}
                onChange={(e) => {
                  setFiltroCompromisso((oldState: any) => {
                    return { ...oldState, filtroPesquisa: e.target.value };
                  });
                }}
                placeholder="Compromissos"
                type="text"
                className={clsx("form-control")}
              />
            </div>

            <div className="col-md-auto mt-2">
              <button onClick={() => carregarCompromissos(filtrosCompromissos)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>

            <div className="col-md-auto mt-2">{<> Buscar por: </>}</div>

            <div className="col-md-auto mt-2">
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltroCompromisso({ ...filtrosCompromissos, tipoPesquisa: 1 })}
                  label="ID"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltroCompromisso({ ...filtrosCompromissos, tipoPesquisa: 2 })}
                  label="Observação"
                  name="group1"
                  type="radio"
                />
              </div>
            </div>

            <div className="col-md-12 mb-5">
              <GridPadrao
                onSort={handleSortCompromisso}
                progressPending={carregandoCompromissos}
                limit={filtrosCompromissos.limit}
                onChangePage={handlePageChangeCompromisso}
                onChangeRowsPerPage={handlePerRowsChangeCompromisso}
                paginationServer={true}
                paginationTotalRows={filtrosCompromissos.totalItems}
                colunas={colunasCompromissos}
                tipo="Compromisso"
                itens={compromissos}
              />
            </div>
          </>
        )}
      </div>

      <CompromissoField
        editarCompromisso={editarCompromisso}
        exibirModal={exibirModalCompromisso}
        toggleModal={toggleExibirCompromisso}
        empresaId={empresa.empresaId}
        modulo={EModulo.Societario}
        setAtualizarTabelaCompromisso={setAualizarTabelaCompromisso}
      />

      <CompromissoCancelarField
        setAtualizarTabelaCompromisso={setAualizarTabelaCompromisso}
        exibirModal={exibirModalCompromissoCancelar}
        toggleModal={toggleExibirCompromissoCancelar}
        compromissoId={compromissoId} 
      />

      <CompromissoConcluirField
        setAtualizarTabelaCompromisso={setAualizarTabelaCompromisso}
        exibirModal={exibirModalCompromissoConcluir}
        toggleModal={toggleExibirCompromissoConcluir}
        compromissoId={compromissoId}
        objetoId={empresa.empresaId}
      />
    </>
  )

}
export default Compromisso;