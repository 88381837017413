import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Validate } from "validate-cnj";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";
import * as Yup from "yup";

import LayoutPageForButton from "../../layout/LayoutPageButton";

import { EModulo, ETelaEnum } from "../../enum";
import { setValuesXCadastroCampos } from "../../utils/SchemasUtil";
import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";
import { ECamposIDEnum, EPropriedadesIDEnum, returnarEnumDescricaoID } from "../../enum/ECamposIDEnum";

import IOption from "../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import IAdicionaProcessoRequest from "../../interfaces/Requests/Processo/IAdicionaProcessoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ProcessoService from "../../services/ProcessoService";
import PublicacaoService from "../../services/PublicacaoService";
import AreaDireitoService from "../../services/AreaDireitoService";
import GrupoEconomicoService from "../../services/GrupoEconomicoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";
import { areaDireitoInicialValues, processoInitialValues } from "../../contexts/InitialValuesContext";

const ProcessoCadastroPage = () => {

  const navigate = useNavigate();

  const { processo, setProcesso, setAreaDireito, processoCadastro, areaDireito } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [options, setOptions] = useState<IOption[]>([]);
  const [carregandoAreasDireito, setCarregandoAreasDireito] = useState<boolean>(false);

  const [existeGrupo, setExisteGrupo] = useState<boolean>(true);
  const [optionGrupos, setOptionGrupos] = useState<IOption[]>([]);
  const [carregandoGrupos, setCarregandoGrupos] = useState<boolean>(false);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [carregandoPropriedades, setCarregandoPropriedades] = useState<boolean>(false);

  const initialValues: IAdicionaProcessoRequest = {
    processoId: 0,
    areaDireitoId: areaDireito.areaDireitoId,
    grupoEconomicoId: 0,
    modulo: EModulo.AreaDireito,
    tela: ETelaEnum.TelaCadastroProcesso,

    publicacaoId: processo.publicacaoId,

    categoriaId: 0,
    numeroprocesso: "",
    pasta: "",

    faseProcessualId: 0,
    subareadireitoId: 0,
    tipoContingenciaId: 0,
    statusprocessoId: 0,
    classificacaoId: 0,
    ambitoId: 0,
    esferaId: 0,
    motivoencerramentoId: 0,
    instanciaId: 0,
    tipoAcaoId: 0,
    riscoId: 0,
    cidadeId: 0,
    escritorioId: 0,
    estadoId: 0,
    formaparticipacaoId: 0,
    paisId: 0,
    tipodocumentoId: 0,
    codigo: "",
    clienteId: 0,
    juizId: 0,
    xFinderDistribuicaoId: null,
    dadosmatricula: "",

    datadistribuicao: null,
    datarecebimento: null,
    prazofatal: null,
    dataencerramento: null,
    dataencerramentocadastro: null,
    databaixaprovisoria: null,
    databaixaprovisoriacadastro: null,
    descricaoobjetoacao: "",
    observacoesprocesso: "",
    resumoprocesso: "",
    valorcausa: null,

    partesContrariasList: [],
    empresasList: [],
    advogadoInternoList: [],
    advogadoList: [],
    centrosCusto: [],
    xCampoValorList: [],
    audienciaList: [],

    quantidadeArquivosSelecionados: 0,
    quantidadeDocumentosCheckList: 0
  };

  const shema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`Campo ${configuracao.label} é obrigatório`);
          if (configuracao.campoNome.toLowerCase() == ECamposIDEnum.NumeroProcesso.toLowerCase() && formik.values.esferaId == 1) {
            schema[configuracao.campoNome.toLowerCase()] = Yup.string()
              .required("Número do processo está inválido")
              .test("numeroProcesso", "", function (value: any) {
                try {
                  Validate.load(value);
                } catch (error) {
                  return this.createError({
                    message: "Número do processo está inválido",
                  });
                }
                return true;
              });
          }

          break;

        case ETipoCampoEnum.Lista:

          if (configuracao.campoNome === ECamposIDEnum.CentroCusto) break;

          if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
            schema[`${returnarEnumDescricaoID(configuracao.campoNome, configuracao.isXCampo)}`] = Yup.number().min(1, `Campo ${configuracao.label} é obrigatório`).notOneOf([0], `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.TipoDocumentoCheckList) {
            schema.quantidadeDocumentosCheckList = Yup.number().min(1, "Documentos do checklist são obrigatórios");
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.TipoDocumento) {
            schema.quantidadeArquivosSelecionados = Yup.number().min(1, "Documentos são obrigatórios");
          }
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.date().required(`Campo ${configuracao.label} é obrigatório`).typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Empresa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Empresa) {
            schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Pessoa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Advogado) {
            schema.advogadoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno) {
            schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.ParteContraria) {
            schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };


  const setarValoresXFinderDistribuicao = () => {

    if (processoCadastro?.valorcausa && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.ValorCausa)) {
      formik.setFieldValue("valorcausa", processoCadastro?.valorcausa);
    }

    if (processoCadastro?.estadoId && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.Estado)) {
      formik.setFieldValue("estadoId", processoCadastro?.estadoId);
    }

    if (processoCadastro?.cidadeId && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.Cidade)) {
      formik.setFieldValue("cidadeId", processoCadastro?.cidadeId);
    }

    if (processoCadastro?.datadistribuicao && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.DataDistribuicao)) {
      formik.setFieldValue("datadistribuicao", moment(processoCadastro?.datadistribuicao).format('YYYY-MM-DD'));
    }

    if (processoCadastro?.numeroprocesso && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.NumeroProcesso)) {
      formik.setFieldValue("numeroprocesso", processoCadastro?.numeroprocesso);
    }

    if (processoCadastro?.datarecebimento && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.DataRecebimento)) {
      formik.setFieldValue("datarecebimento", moment(processoCadastro?.datarecebimento).format('YYYY-MM-DD'));
    }

    if (processoCadastro?.esferaId && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.Esfera)) {
      formik.setFieldValue("esferaId", processoCadastro?.esferaId);
    }

    if (processoCadastro?.instanciaId && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.Instancia)) {
      formik.setFieldValue("instanciaId", processoCadastro?.instanciaId);
    }

    if (processoCadastro?.resumoprocesso && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.ResumoProcesso)) {
      formik.setFieldValue("resumoprocesso", processoCadastro?.resumoprocesso);
    }

    if (processoCadastro?.escritorioId && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.Escritorio)) {
      formik.setFieldValue("escritorioId", processoCadastro?.escritorioId);
    }

    if (processoCadastro?.xFinderDistribuicaoId) {
      formik.setFieldValue("xFinderDistribuicaoId", processoCadastro?.xFinderDistribuicaoId);
    }

    if (processoCadastro?.cnjUnidadeOrigemId) {
      formik.setFieldValue("cnjUnidadeOrigemId", processoCadastro?.cnjUnidadeOrigemId);
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: shema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Processo",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          let msgCarregando = "Realizando o cadastro do processo...";
          if (values.xFinderDistribuicaoId && values.xFinderDistribuicaoId > 0) {
            msgCarregando = "Realizando o cadastro do processo, esta ação pode demorar um pouco, pois estamos baixando os documentos do tribunal...";
          }

          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: msgCarregando,
            showConfirmButton: false,
          });

          Swal.showLoading();

          setAdicionarLoading(true);

          let resultado = await ProcessoService.adicionaProcesso({
            ...values,
            areaDireitoId: areaDireito.areaDireitoId,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });

          if (values.publicacaoId) // Verificar se processo deve criado a partir de uma publicação
            await PublicacaoService.vincularProcesso(values.publicacaoId, resultado.processoId);

          Swal.hideLoading();

          setProcesso(resultado);

          setAreaDireito(areaDireito);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Processo cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarLoading(false);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel cadastrar o processo.`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  console.log(formik.errors)

  useEffect(() => {

    carregarPropriedadesTela();

    carregarAreaDireito();

    setProcesso(processoInitialValues);

    setAreaDireito(areaDireitoInicialValues);

    setConfiguracoesTela([]);

  }, []);

  useEffect(() => {
    if (areaDireito.areaDireitoId > 0) {
      carregarConfiguracaoTela();
      setarValoresXFinderDistribuicao();
    } else {
      setConfiguracoesTela([]);
      setAreaDireito(areaDireitoInicialValues);
      formik.setValues({ ...initialValues, grupoEconomicoId: formik.values.grupoEconomicoId });
    }
  }, [areaDireito.areaDireitoId]);

  useEffect(() => { if (existeGrupo) carregarGrupoEconomico(); }, [existeGrupo]);

  const carregarAreaDireito = async (cliente: number | null | undefined = undefined) => {
    try {

      let options: any[] = [{ label: 'Selecione', value: 0 }];

      setCarregandoAreasDireito(true);
      let resultado = await AreaDireitoService.obterListaLimitada(cliente);
      resultado.data.map((item) => options.push({ label: item.nome, value: item.areaDireitoId }))
      setOptions(options);

    } catch (error: any) {
      setCarregandoAreasDireito(false);
    } finally {
      setCarregandoAreasDireito(false);
    }
  };

  const carregarGrupoEconomico = async () => {
    try {

      let options: any[] = [{ label: 'Selecione', value: 0 }];

      setCarregandoGrupos(true);
      let resultado = await GrupoEconomicoService.obterGrupoEconomicos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      resultado.data.map((item) => options.push({ label: item.nome, value: item.grupoEconomicoId }));
      setOptionGrupos(options);
    } catch (error: any) {
      setOptionGrupos([]);
    } finally {
      setCarregandoGrupos(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        AreaDireitoId: areaDireito.areaDireitoId,
        Modulo: EModulo.AreaDireito,
        Tela: ETelaEnum.TelaCadastroProcesso,
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setCarregandoConfiguracoesTela(false);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const carregarPropriedadesTela = async () => {
    try {
      setCarregandoPropriedades(true);
      let resultado = await ConfiguracaoTelaService.obterPropriedadesTela(EModulo.AreaDireito);
      setExisteGrupo(resultado.data?.find(e => e.codigo == EPropriedadesIDEnum.HabilitarGrupoEconomicoProcesso));
    } catch (error) {
      setExisteGrupo(false);
    } finally {
      setCarregandoPropriedades(false);
    }
  };

  const onChangeAreaDireito = (value: number, texto: string) => {
    setConfiguracoesTela([]);
    formik.resetForm();

    formik.setFieldValue("areaDireitoId", value);
    formik.setFieldValue("categoriaId", value);
    formik.setFieldValue("modulo", EModulo.AreaDireito);
    formik.setFieldValue("tela", ETelaEnum.TelaCadastroProcesso);

    setAreaDireito({
      areaDireitoId: value,
      nome: texto,
      checked: false,
      codigo: "",
      status: "",
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />
      <LayoutPageForButton title={`Cadastro de Processo ${areaDireito.areaDireitoId > 0 ? " - " + areaDireito.nome : ""}`}>
        {processo.processoId > 0 ? (
          <>
            <button onClick={() => navigate("/Processo/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{processo.processoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              disabled={adicionarLoading}
              style={{ display: !formik.values.areaDireitoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">
        {carregandoAreasDireito ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando área de direito...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Áreas
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.areaDireitoId)?.label,
                value: formik.values.areaDireitoId,
              }}
              onChange={(option: any) => onChangeAreaDireito(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>

      <hr></hr>
      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {existeGrupo && <>
            <div className="row mt-2">
              {carregandoGrupos && carregandoPropriedades ? (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando grupos...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <div className="col-md-3">
                  <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                    Grupo Economico:
                  </label>
                  <Select
                    value={{
                      label: optionGrupos.find((e) => e.value === formik.values.grupoEconomicoId)?.label,
                      value: formik.values.grupoEconomicoId,
                    }}
                    onChange={(option: any) => formik.setFieldValue('grupoEconomicoId', option.value)}
                    options={optionGrupos} />
                </div>
              )}
            </div>
          </>}
          {renderCampos()}
        </>}
    </>
  );
};

export default ProcessoCadastroPage;
