import { useEffect, useState, useContext, useRef } from "react";
import { faEraser, faSearch, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";

import ILog from "../../interfaces/ILog";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaLogRequest from "../../interfaces/Requests/Log/FiltroPesquisaLogRequest";

import sessionStorageService from "../../services/sessionStorage/sessionStorageService";
import LogService from "../../services/LogService";

import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import { PopoverSubMenuProcuracao } from "../../components/Comum/Popover/PopoverSubMenuProcuracao";

const LogProcuracaoPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoLogs, setCarregandoLogs] = useState(false);
  const [logs, setLogs] = useState<ILog[]>([]);
  const [relatorioId, setRelatorioId] = useState<number | null>(null);
  const { procuracao, setProcuracao, setTipoProcuracao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaLogRequest>({
    logId: [],
    ip: "",
    usuarioNomeCadastro: "",
    dataMaiorIgual: "",
    dataMenorIgual: "",
    funcao: 0,
    tabela: "",
    campo: "",
    valorAntigo: "",
    valorNovo: "",
    procuracaoId: procuracao.procuracaoId,
    empresaId: 0, //TO DO: Implementar ID da empresa quando tiver o societário.
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-logId",
  });

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {
    if (!procuracao.procuracaoId) {
      carregarSessionStorage();
    }

    else {
      inserirSessionStorage();
    }
  }, [procuracao]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(procuracao, "procuracao_logs");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_logs");
  };

  const carregarSessionStorage = async () => {

    let procuracao: IProcuracao = JSON.parse((await sessionStorageService.obter("procuracao_logs")) || null);
    setProcuracao(procuracao);

    let filtro: FiltroPesquisaLogRequest = JSON.parse((await sessionStorageService.obter("filtro_logs")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, procuracaoId: filtro.procuracaoId });

  };


  async function carregarLogs(filtro: FiltroPesquisaLogRequest): Promise<void> {
    try {
      setCarregandoLogs(true);
      let resultado: IRecuperaLista<ILog>;

      resultado = await LogService.obterLogs(filtro);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setRelatorioId(resultado.relatorioId || null);
      setLogs(resultado.data);
      inserirSessionStorage();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros.",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setLogs([]);
      setRelatorioId(null);
    } finally {
      setCarregandoLogs(false);
      setLoadingInicial(false);
    }
  }

  function nomearFuncao(status: number): string {
    switch (status) {
      case 1:
        return "Criação";
      case 2:
        return "Leitura";
      case 3:
        return "Atualização";
      case 4:
        return "Exclusão";
      case 5:
        return "Relatório";
      default:
        return "Não definido";
    }
  }

  const colunas: TableColumn<ILog>[] = [
    {
      name: "Id",
      sortField: "logId",
      selector: (row: ILog) => row.logId,
      sortable: true,
      wrap: true,
    },
    {
      name: "IP",
      sortField: "ip",
      selector: (row: ILog) => row.ip,
      sortable: true,
      wrap: true,
    },
    {
      name: "Usuário",
      sortField: "usuarioNomeCadastro",
      selector: (row: ILog) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data",
      sortField: "data",
      selector: (row: ILog) => {
        const data = row.data || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          const [horario] = data.split("T")[1].split(".");
          return `${dia}/${mes}/${ano} ${horario}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Função",
      sortField: "funcao",
      selector: (row: ILog) => nomearFuncao(row.funcao),
      sortable: true,
      wrap: true,
    },
    {
      name: "Tabela",
      sortField: "tabela",
      selector: (row: ILog) => row.tabela,
      sortable: true,
      wrap: true,
    },
    {
      name: "Campo",
      sortField: "campo",
      selector: (row: ILog) => row.campo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Antigo",
      sortField: "valorAntigo",
      selector: (row: ILog) => row.valorAntigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Novo",
      sortField: "valorNovo",
      selector: (row: ILog) => row.valorNovo,
      sortable: true,
      wrap: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<ILog>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  async function baixarRelatorio(): Promise<void> {
    if (!logs) return;

    if (relatorioId === 0 || relatorioId === null) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `A pesquisa não retornou nenhum resultado.`,
        showConfirmButton: true,
        timer: 4000,
      });
      return;
    }

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Baixando arquivos...",
      showConfirmButton: false,
    });
    Swal.showLoading();
    try {
      const arquivo = await LogService.gerarExcel(relatorioId || 0);
      if (arquivo) {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${new Date().toLocaleString("BR")} - Logs.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      Swal.hideLoading();
      Swal.update({
        title: "Baixado com sucesso",
        text: "",
        icon: "success",
        showConfirmButton: true,
      });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível baixar arquivos",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  function limparFiltros() {
    setFiltrosPesquisa((oldValue: any) => {
      return {
        ...oldValue,
        logId: 0,
        ip: "",
        usuarioNomeCadastro: "",
        dataMaiorIgual: "",
        dataMenorIgual: "",
        funcao: 0,
        tabela: "",
        campo: "",
        valorAntigo: "",
        valorNovo: "",
        procuracaoId: 0,
      };
    });
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarLogs(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    //pesquisarLogs();
    //setLoadingInicial(true);
  }, []);

  return (
    <>
      <div title="Acessar a Procuração" style={{ marginLeft: "0px" }} ref={componentRef}>

        <LayoutPageForButton title="Logs">
          <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcuracao()}>
            <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageForButton>
        <hr />
        {procuracao.procuracaoId ? (
          <a style={{ cursor: "pointer" }}>
            <p
              onClick={() => {
                setProcuracao(procuracao);
                setTipoProcuracao({
                  tipoProcuracaoId: procuracao.tipoProcuracaoId,
                  nome: procuracao.tipoProcuracaoNome,
                  codigo: "",
                  status: "",
                });
                navigate("/Processo/Capa");
              }}
              style={{
                overflow: "hidden",
                textDecoration: "underline",
                fontFamily: "arial,sans-serif",
                fontSize: "18px",
                color: "var(--primary-base2)",
                paddingTop: "30px",
              }}
            >
              <b>Procuracao: #{procuracao.procuracaoId.toString().padStart(5, "0")}</b>
            </p>
          </a>
        ) : (
          //TODO: IMPLEMENTAR QUANDO TIVER OS DEMAIS MÓDULOS
          <a
          //style={{ marginLeft: "10px", cursor: "pointer" }}
          //onClick={() => adicionarFavorito(procuracao.procuracaoId)}
          //className="navigation-service-icon navigation-service-icon-start"
          ></a>
        )}
      </div>

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarLogs(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-log"
          >
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data" className="form-label fw-bolder text-orange">
                Data
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataMaiorIgual && filtrosPesquisa.dataMenorIgual
                    ? [filtrosPesquisa.dataMaiorIgual, filtrosPesquisa.dataMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataMaiorIgual: "",
                        dataMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue: any) => {
                    return {
                      ...oldValue,
                      dataMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-usuarioNomeCadastro" className="form-label fw-bolder text-orange">
                Nome do Usuário
              </label>
              <input
                value={filtrosPesquisa.usuarioNomeCadastro}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, usuarioNomeCadastro: e.target.value };
                  });
                }}
                placeholder="Nome do Usuário"
                className={"form-control"}
                id="form-usuarioNomeCadastro"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-funcao" className="form-label fw-bolder text-orange">
                Função
              </label>
              <select
                value={filtrosPesquisa.funcao}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, funcao: parseInt(e.target.value) };
                  });
                }}
                placeholder="Função"
                className={"form-select"}
                id="form-funcao"
              >
                <option value={0}>Todos</option>
                <option value={1}>Criação</option>
                <option value={2}>Leitura</option>
                <option value={3}>Atualização</option>
                <option value={4}>Exclusão</option>
                <option value={5}>Relatório</option>
              </select>
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-campo" className="form-label fw-bolder text-orange">
                Campo
              </label>
              <input
                value={filtrosPesquisa.campo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, campo: e.target.value };
                  });
                }}
                placeholder="Campo"
                className={"form-control"}
                id="form-campo"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-valorAntigo" className="form-label fw-bolder text-orange">
                Valor Antigo
              </label>
              <input
                value={filtrosPesquisa.valorAntigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, valorAntigo: e.target.value };
                  });
                }}
                placeholder="Valor Antigo"
                className={"form-control"}
                id="form-valorAntigo"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-valorNovo" className="form-label fw-bolder text-orange">
                Valor Novo
              </label>
              <input
                value={filtrosPesquisa.valorNovo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, valorNovo: e.target.value };
                  });
                }}
                placeholder="Valor Novo"
                className={"form-control"}
                id="form-valorNovo"
              />
            </div>

            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  baixarRelatorio();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Baixar Relatório"
                disabled={relatorioId === null || carregandoLogs}
              >
                <FontAwesomeIcon color="white" className="" icon={faFileExcel} />
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoLogs}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Logs"
            itens={logs}
          />
        </div>
      </div>
    </>
  );
};

export default LogProcuracaoPage;
