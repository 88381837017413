export interface LatLng {
  lat: number;
  lng: number;
}

export interface CoordinatesResult {
  formatted_address: string;
  geometry: {
    location: LatLng;
  };
}

export enum CoordinatesResponseStatusEnum {
  OK = "OK",
  ZERO_RESULTS = "ZERO_RESULTS"
}

export interface GetCoordinatesResponse {
  results: CoordinatesResult[]
  status: CoordinatesResponseStatusEnum
}
