import {BsCheckCircle, BsExclamationCircle} from 'react-icons/bs';
import IEnvelopeSignatario from '../../../interfaces/IEnvelopeSignatario';
import React, { CSSProperties } from 'react';
import {DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps} from 'react-beautiful-dnd';
import {BiLoaderAlt} from 'react-icons/bi';


export function SignatariosListItem(props: {
         signatario: IEnvelopeSignatario;
         carregando: boolean;
         publico?: boolean;
         innerRef?: React.LegacyRef<HTMLDivElement>;
         style?: CSSProperties;
         draggableProps?: DraggableProvidedDraggableProps;
         dragHandleProps?: DraggableProvidedDragHandleProps | undefined;
         children?: React.ReactNode;
}) {

    return <>
        <div
            ref={props.innerRef}
            {...props.draggableProps}
            {...props.dragHandleProps}
            style={props.style}
        >
            <div className="row">
                <div style={{
                    width: 'fit-content',
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '2rem',
                    padding: '0.1em',
                    color: '#00c853'
                }}>
                {props.publico && props.signatario.status === 1  &&
                    <BsExclamationCircle style={{color: '#0004'}}/>
                }
                {props.signatario.status === 2 &&
                        <BsCheckCircle />
                }
                </div>
                <div className="col">
                    <div className="col-md-12">
                        <p className="form-label text-soft fw-bolder mb-0">{props.signatario.nome}
                        </p>
                    </div>
                    <div className="col-md-12">
                        <p className="form-label text-soft mb-0">{props.signatario.email}
                            {props.carregando &&
                                <span className='float-right text-orange' style={{ marginTop: "-.19em", fontSize: '1.2em' }}>
                                    <BiLoaderAlt className='spin' />
                                </span>
                            }
                        </p>
                        <span className='clear-fix'></span>
                    </div>
                    <div className="col-md-12">
                        <span className="badge badge-secondary background-orange me-2">{props.signatario.papelSignatarioNome}</span>
                        {props.signatario.dataAssinatura && <span className="badge badge-success">Assinado em: {new Date(props.signatario.dataAssinatura).toLocaleString('BR')}</span>}
                    </div>
                </div>
                {props.children &&
                    <div style={{
                        width: 'fit-content',
                        flexDirection: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '0.1em calc(var(--bs-gutter-x) * .5) 0 0',
                    }}>
                        {props.children}
                    </div>
                }
            </div>
        </div>
    </>;
}


