import TimeDelta from "./TimeDelta";


export class DadoGenerico {
    etiqueta: string;
    ids: number[];
    dados: (number|TimeDelta|string)[];
    protected _tipo = 'generico';

    constructor(etiqueta: string, ids: number[], dados: (number|TimeDelta|string)[]){
        this.etiqueta = etiqueta;
        this.ids = ids;
        this.dados = dados;
    }

    public get tipo (){
        return this._tipo;
    }
}

export class MultiDados {
    etiquetas: string[];
    ids: number[];
    dados: DadoNumerico[];
    protected _tipo = 'multidados';

    constructor(etiquetas: string[], ids: number[], dados: DadoNumerico[]){
        this.etiquetas = etiquetas;
        this.ids = ids;
        this.dados = dados;
    }

    public get tipo (){
        return this._tipo;
    }
}


export class DadoNumerico extends DadoGenerico {
    dados: (number)[];
    protected _tipo = 'number';

    constructor(etiqueta: string, ids: number[], dados: number[]) {
        super(etiqueta, ids, dados);
        this.dados = dados;
    }


    media(): number {
        if (!this.dados || !this.dados.length) {
            return 0;
        }

        return this.dados.reduce((acc, curr) => acc + curr) / this.dados.length;
    }

    total(): number {
        if (!this.dados || !this.dados.length) {
            return 0;
        }
        
        return this.dados.reduce((acc, curr) => acc + curr);
    }

}


export class DadoTemporal extends DadoGenerico {
    dados: (TimeDelta)[];
    protected _tipo = 'timedelta';

    constructor(etiqueta: string, ids: number[], dados: TimeDelta[]) {
        super(etiqueta, ids, dados);
        this.dados = dados;
    }

    media(): TimeDelta {
        if (!this.dados || !this.dados.length) {
            return new TimeDelta(0);
        }

        return new TimeDelta(this.dados.reduce<number>((acc, curr) => acc + curr.valueOf(), 0) / this.dados.length);
    }

    total(): TimeDelta {
        if (!this.dados || !this.dados.length) {
            return new TimeDelta(0);
        }
        
        return new TimeDelta(this.dados.reduce<number>((acc, curr) => acc + curr.valueOf(), 0));
    }
}


const TiposDados = {
    DadoTemporal,
    DadoNumerico,
    DadoGenerico
};

export default TiposDados;