import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { TextIndentationOption } from "./types";
import { getNodeBySelection, removeUnit } from "../common";
import { CustomEditor } from "../../../../../../utils/types/slate";

export const indentHandler = (
  editor: CustomEditor,
  actionType: TextIndentationOption,
) => {
  const selectedNode = getNodeBySelection(editor)

  if (!selectedNode) return

  let indentAsNumber = removeUnit(selectedNode.style?.textIndent)
  const INDENT_CONSTANT = 36

  if (actionType === 'increaseIndent') {
    indentAsNumber += INDENT_CONSTANT
  }

  if (actionType === 'decreaseIndent') {
    if (indentAsNumber <= 0) {
      ReactEditor.focus(editor)
      return
    }

    indentAsNumber -= INDENT_CONSTANT
  }

  Transforms.setNodes(editor, {
    style: {
      ...selectedNode.style,
      textIndent: indentAsNumber + 'pt'
    }
  })

  ReactEditor.focus(editor)
}