import React from 'react';

import "../nodes/styles/SidebarSelection.css"

export default function SideBarSelection()  {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside className="divSidebar">
      <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'NodeFase')} draggable>
        <div className='nodeFaseExample'>
          <p>Fase</p>
        </div>
      </div>
      <div className="dndnode" onDragStart={(event) => onDragStart(event, 'NodeCondicao')} draggable>
        <div className='nodeCondicaoExample'>
          <p>Regra</p>
        </div>
      </div>
      {/* <div className="dndnode" onDragStart={(event) => onDragStart(event, 'NodeRaia')} draggable>
        <div className='nodeRaiaExample'>
          <div className='nodeRaiaSubExample'>
            <p>Raia</p>
          </div>
        </div>
      </div> */}

    </aside>
  );
}