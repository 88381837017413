/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsCloudArrowDown, BsCloudArrowUp, BsEye, BsTrash } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import {
  faBackward,
  faEdit,
  faFileArchive,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import ICidade from "../../interfaces/ICidade";
import IEstado from "../../interfaces/IEstado";
import IDocumento from "../../interfaces/IDocumento";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaContratoRequest } from "../../interfaces/Requests/Contrato/IAdicionaContratoRequest";
import FiltroPesquisaDocumentoRequest from "../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";


import { ECamposIDEnum, EModulo, ETelaEnum, returnarEnumDescricaoID } from "../../enum";
import GridPadrao from "../../components/Comum/GridPadrao";
import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";

import CidadeService from "../../services/CidadeService";
import EstadoService from "../../services/EstadoService";
import ContratoService from "../../services/ContratoService";
import DocumentoService from "../../services/DocumentoService";
import CompromissoService from "../../services/CompromissoService";


import Carregando from "../../components/Carregando";
import LayoutPageButton from "../../layout/LayoutPageButton";
import { OrangeContext } from "../../contexts/OrangeProvider";
import IFileUploadField from "../../components/FileUpload/FileUploadField";
import PreviewPDFField from "../../components/Comum/PreviewPDF/PreviewPDF";
import CamposCapaDinamicos from "../../components/Fluxo/CamposCapaDinamicos";
import IFileUploadFieldEdit from "../../components/FileUpload/FileUploadFieldEdit";
import CamposCadastroDinamico from "../../components/Fluxo/CamposCadastroDinamicos";
import ICompromisso from "../../interfaces/ICompromisso";
import FileUploadCheckListField from "../../components/FileUpload/IFileUploadCheckField";
import AndamentoFluxoField from "../../components/Comum/Andamento/AndamentoFluxoField";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import AreaTextoDefault from "../../components/Comum/AreaTexto/AreaTextoDefault";
import LabelConcluidoDinamico from "../../components/Fluxo/LabelConcluidoDinamico";


interface IContratoFluxoModal {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  compromisso: ICompromisso;
  setRefresh(salvando: boolean): void;
  capa: boolean;
}

const ContratoFluxoModal = ({ toggleModal, exibirModal, compromisso, setRefresh, capa }: IContratoFluxoModal) => {

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { contrato } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoCidades, setCarregandoCidades] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [historico, setHistorico] = useState<any[]>([]);
  const [cidades, setCidades] = useState<ICidade[]>([]);
  const [estados, setEstado] = useState<IEstado[]>([]);

  const [documentos, setDocumentos] = useState<any>([]);
  const [documento, setDocumento] = useState<IDocumento>({
    index: 0,
    documentoId: 0,
    clienteId: 0,
    nome: "",
    descricao: "",
    tamanho: 0,
    tipoDocumentoId: 0,
    tipoDocumentoNome: "",
    restrito: false,
    status: "",
    codigo: "",
    dataCadastro: new Date(),
    usuarioIdCadastro: 0,
    usuarioNomeCadastro: "",
    modulo: "",
    extensao: "",
    pastaGedNome: "",
    etiquetas: [],
  });
  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);
  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    contratoId: contrato.contratoId,
    modulo: [EModulo.Contrato],
    nome: "",
    status: 1,
    origem: 0,
    limit: 5,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [submitAndamento, setSubmitAndamento] = useState<boolean>(false);

  const [exibirListaArquivosImportados, setExibirListaArquivosImportados] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);
  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [quantidadeArquivosCheckListSelecionados, setQuantidadeArquivosCheckListSelecionados] = useState<number>(0);
  const [exibirModalImportacaoArquivosCheckList, setExibirModalImportacaoArquivosCheckList] = useState<boolean>(false);
  const [exibirComponenteParaImportarArquivo, setExibirComponenteParaImportarArquivo] = useState<boolean>(false);
  const [exibirModalDocumentoEdit, setExibirModalDocumentoEdit] = useState<boolean>(false);
  const [atualizaTabelaDocumento, setAtualizaTabelaDocumento] = useState<boolean>(false);
  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const componentRef = useRef<any>();

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  const toggleDocumentoEdit = () => setExibirModalDocumentoEdit(!exibirModalDocumentoEdit);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const toggleImportacaoArquivosCheckList = () => setExibirModalImportacaoArquivosCheckList(!exibirModalImportacaoArquivosCheckList);

  const initialValues: IAdicionaContratoRequest = {

    contratoId: contrato.contratoId,
    principalId: contrato.contratoId,

    tipoContratoId: contrato.tipoContratoId,
    categoriaId: contrato.tipoContratoId,
    modulo: EModulo.Contrato,

    fluxoFaseId: compromisso.fluxoFaseId,

    cidadeId: 0,
    observacao: "",
    isnovapasta: null,
    nomepasta: "",
    numerocontrato: "",
    pastaid: 0,
    estadoId: 0,
    objetocontrato: "",
    datainiciovigencia: null,
    periodicidadeId: 0,
    datafimvigencia: null,
    observacoescontrato: "",
    departamentoId: 0,
    gestorId: 0,
    urgente: false,
    restrito: false,
    bancoId: 0,
    agencia: "",
    conta: "",
    moedaId: 0,
    indiceId: 0,
    valortotal: 0,
    dataaprovacao: null,
    dataassinatura: null,
    codigo: "",
    advogadoInternoList: [],
    empresasList: [],
    partesContrariasList: [],
    xCampoValorList: [],
    centrosCusto: [],
    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
    quantidadeperiodicidade: 0,
    tipoVigenciaId: 0

  };
  const adicionarShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Lista:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[`${returnarEnumDescricaoID(configuracao.campoNome, configuracao.isXCampo)}`] = Yup.number().min(1, `${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
            schema[configuracao.campoNome.toLowerCase()] = Yup.date().required(`Campo ${configuracao.label} é obrigatório`).typeError(`Campo ${configuracao.label} é obrigatório`);
          }
          break;


        case ETipoCampoEnum.Empresa:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.Empresa) {
            schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Pessoa:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.Advogado) {
            schema.advogadoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno) {
            schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.ParteContraria) {
            schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const carregarObjetoXCampos = () => {
    let xCampoValorList = new Array<any>;
    configuracoesTela.forEach(configuracao => {

      if (configuracao?.isXCampo) {
        switch (configuracao.campoTipo) {
          case ETipoCampoEnum.AreaTexto:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Texto:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Data:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, data: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Valor:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, valor: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Percentual:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, percentual: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Inteiro:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, inteiro: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Lista:
            xCampoValorList.push({ xCampoOpcaoNome: eval(`formik.values.${returnarEnumDescricaoID(configuracao.campoNome, true)}Nome`), label: configuracao.label, campoId: configuracao.campo, xCampoOpcaoId: eval(`formik.values.${returnarEnumDescricaoID(configuracao.campoNome, true)}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Boolean:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, boolean: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Tabela:
            xCampoValorList.push({ label: configuracao.label, campoId: configuracao.campo, linhas: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          default:
            break;
        }
      }
    });
    return xCampoValorList;
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionarShema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Compromisso",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Concluindo o compromisso...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          // Salvar Contrato
          await ContratoService.concluirCompromisso({
            ...values,
            contratoId: contrato.contratoId,
            xCampoValorList: carregarObjetoXCampos()
          });


          setTimeout(async () => {

            await CompromissoService.concluirCompromisso(values, compromisso.compromissoId, contrato.contratoId);

            await ContratoService.adicionarHistorico({ ...values, xCampoValorList: carregarObjetoXCampos() }, compromisso.compromissoId);

            toggleModal();
            formik.resetForm();
            setConfiguracoesTela([]);

            setRefresh(true);
            Swal.hideLoading();
            setSubmitAndamento(true);
            setQuantidadeArquivosSelecionados(0);

            await Swal.fire({ icon: "success", title: `Compromisso concluído com sucesso`, timer: 3000, });

          }, 2000);
        }
      } catch (error: any) {
        Swal.hideLoading();
        setSubmitting(false);
        setConfiguracoesTela([]);
      }
    },
  });

  useEffect(() => {
    if (exibirListaArquivosImportados) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
  }, [filtrosPesquisaArquivos.offset, filtrosPesquisaArquivos.limit, filtrosPesquisaArquivos.sort, exibirListaArquivosImportados]);

  useEffect(() => {
    if (atualizaTabelaDocumento) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
    setAtualizaTabelaDocumento(false);
  }, [atualizaTabelaDocumento]);

  useEffect(() => {
    if (exibirModal) {
      if (!contrato.contratoId) return;

      carregarEstado();

      formik.setValues({
        ...formik.values,
        principalId: contrato.contratoId,
        contratoId: contrato.contratoId,
        tipoContratoId: contrato.tipoContratoId,
        categoriaId: contrato.tipoContratoId,
        fluxoFaseId: compromisso.fluxoFaseId,
        modulo: EModulo.Contrato,
        observacao: compromisso.observacaoConclusao
      });
    }
  }, [compromisso.fluxoFaseId, exibirModal]);

  useEffect(() => {
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      if (configuracao.campoTipo == ETipoCampoEnum.Lista
        && configuracao.campoNome != ECamposIDEnum.TipoDocumento
        && configuracao.campoNome != ECamposIDEnum.TipoDocumentoCheckList
        && configuracao.campoNome != ECamposIDEnum.TipoDocumentoMinuta) {
        if (configuracao.obrigatorio) formik.setFieldValue(`${configuracao.campoNome.toLowerCase()}Id`, configuracao.obrigatorio ? 0 : eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`));
      } else {
        if (configuracao.obrigatorio) formik.setFieldValue(`${configuracao.campoNome.toLowerCase()}`, configuracao.obrigatorio ? "" : eval(`formik.values.${configuracao.campoNome.toLowerCase()}`));
      }
    });
  }, [configuracoesTela?.length]);

  useEffect(() => {
    if (formik.values.estadoId > 0) carregarCidade();
  }, [formik.values.estadoId]);

  useEffect(() => {
    if (exibirModal) {

      carregarConfiguracaoTela();

      if (compromisso?.status?.toLowerCase() == "concluido") {
        carregarConfiguracaoHistorico();
      }

    }
  }, [compromisso.compromissoId, exibirModal]);

  const verificaIsPdf = (extensao: string) => {
    if (extensao.endsWith(".pdf")) return true;
    else return false;
  };

  const carregarConfiguracaoTela = async () => {
    try {


      setCarregandoConfiguracoesTela(true);

      let resultado = await CompromissoService.obterTelaCompromisso(compromisso.compromissoId, ETelaEnum.TelaConcluirFase);

      setConfiguracoesTela(resultado.data);

    } catch (error) {
      setCarregandoConfiguracoesTela(false);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const carregarConfiguracaoHistorico = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<any>;
      resultado = await CompromissoService.obterHistorico({
        compromissoId: compromisso.compromissoId,
        IBGE: "",
        nome: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setHistorico(JSON.parse(resultado.data[resultado.data.length - 1]?.historicoValor));

      setCarregandoConfiguracoesTela(false);

    } catch (error: any) {
      setHistorico([]);
      setCarregandoConfiguracoesTela(false);
    }
  };

  console.log(compromisso);

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {
      if (!contrato.contratoId) return;
      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;

      resultado = await DocumentoService.obterArquivos({ ...filtro, contratoId: contrato.contratoId });

      setDocumentos(resultado.data);

      setFiltrosPesquisaArquivos((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoDocumentos(false);
    } catch (error: any) {
      setDocumentos([]);
      setCarregandoDocumentos(false);
    }
  };

  const carregarEstado = async () => {
    try {
      if (estados.length > 0) return;
      let resultado: IRecuperaLista<IEstado>;
      resultado = await EstadoService.obterEstados({
        nome: "",
        codigo: "",
        status: 0,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "estadoId",
      });
      setEstado(resultado.data);
    } catch (error: any) {
      setEstado([]);
    }
  };

  const carregarCidade = async () => {
    try {
      setCidades([]);
      setCarregandoCidades(true);

      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidadePorFiltros({
        estadoId: formik.values.estadoId,
        IBGE: "",
        nome: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setCarregandoCidades(false);
      setCidades(resultado.data);
    } catch (error: any) {
      setCidades([]);
      setCarregandoCidades(false);
    }
  };

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const excluirDocumento = async (documentoId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Deletar documento",
        text: `Você realmente deseja deletar este documento?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await DocumentoService.inativaDocumento(documentoId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Documento deletado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        carregarDocumentos(filtrosPesquisaArquivos);
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel deletar o documento`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };

  const handlePerRowsChangeArquivo = async (currentRowsPerPage: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChangeArquivo = (page: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisaArquivos.limit };
    });
  };

  const handleSortArquivo = async (column: TableColumn<any>, sortDirection: string) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const colunasArquivos: TableColumn<any>[] = [
    {
      name: "Documento ID",
      sortField: "documentoId",
      selector: (row: any) => row.documentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumentoNome",
      selector: (row: any) => row.tipoDocumentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: any) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Restrito?",
      sortField: "restrito",
      selector: (row: any) => (row.restrito ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Usuário",
      sortField: "usuarioNomeCadastro",
      selector: (row: any) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "  ",
      selector: (row: any) => moment(row.dataCadastro).format("DD/MM/yyyy"),
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IDocumento) => {
        return (
          <div>
            {!capa &&
              <>
                <FaEdit
                  title="Editar Arquivo"
                  onClick={() => {
                    setDocumento(row);
                    onChangeTipoDocumento();
                    setExibirModalDocumentoEdit(true);
                  }}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />

                <IFileUploadFieldEdit
                  campo={58}
                  exibirModalDocumentoEdit={exibirModalDocumentoEdit}
                  toggleModal={toggleDocumentoEdit}
                  editarDocumento={documento}
                  modulo={parseInt(documento.modulo)}
                  setAtualizarTabela={setAtualizaTabelaDocumento}
                  tipoContratoId={contrato.tipoContratoId}
                />

                <BsTrash
                  title="Deletar Arquivo"
                  onClick={() => excluirDocumento(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
              </>
            }

            <BsCloudArrowDown
              title="Baixar Arquivo"
              onClick={() => baixarArquivo(row.documentoId, row.nome)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />
            <>
              {verificaIsPdf(row.extensao) && (
                <BsEye
                  title="Preview"
                  onClick={() => previewArquivo(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
              )}
            </>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const onChangeTipoDocumento = () => {
    if (configuracoesTela.filter((e: { campo: number }) => e.campo == 58).length > 0) {
      const tipoDocumento = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo == 58)[0];

      const configuracoesTelaFilter = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo !== 58);

      configuracoesTelaFilter.push({ ...tipoDocumento, alterarCampo: true });

      setConfiguracoesTela(configuracoesTelaFilter);
    }
    if (exibirComponenteParaImportarArquivo) carregarDocumentos(filtrosPesquisaArquivos);
  };

  const renderImportadorArquivos = () => {
    return exibirComponenteParaImportarArquivo ? (
      <>
        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumento)[0]?.label}:
              <FontAwesomeIcon
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                icon={faBackward}
              />
            </label>
            <br />
            <>
              <Button
                onClick={() => setExibirModalImportacaoArquivos(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                Selecione os arquivos do contrato
              </Button>
              <IFileUploadField
                campo={58}
                modulo={EModulo.Contrato}
                setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                exibirModal={exibirModalImportacaoArquivos}
                toggleModal={toggleImportacaoArquivos}
                contratoId={contrato.contratoId}
                tipoContratoId={contrato.tipoContratoId}
                capa={true}
              />
            </>
          </div>
        </div>
        <div className="row mt-2">
          <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
            {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
            {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
            {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
          </a>
        </div>
      </>
    ) : (
      <>
        <div className="row mt-2">
          <div className="col-md-12">
            <label
              onClick={() => setExibirListaArquivosImportados(!exibirListaArquivosImportados)}
              htmlFor="form-Documentos"
              style={{ cursor: "pointer" }}
              className="form-label fw-bolder text-orange"
            >
              Documentos {exibirListaArquivosImportados ? "-" : "+"}
              <BsCloudArrowUp
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                size={"30px"}
                title={"Realizar upload de documento"}
              />
            </label>
            {exibirListaArquivosImportados && (
              <GridPadrao
                progressPending={carregandoDocumentos}
                onSort={handleSortArquivo}
                limit={filtrosPesquisaArquivos.limit}
                onChangePage={handlePageChangeArquivo}
                onChangeRowsPerPage={handlePerRowsChangeArquivo}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisaArquivos.totalItems}
                colunas={colunasArquivos}
                tipo="Documentos"
                itens={documentos}
              />
            )}
          </div>
          <hr />
        </div>
      </>
    );
  };

  const renderImportadorArquivosCheckList = () => {
    return (
      <>
        {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumentoCheckList).length > 0 && (
          <>
            <div className="row mt-3">
              <div className="col-md-4 mt-3">
                <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                  {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumentoCheckList)[0]?.label}:
                </label>
                <br></br>
                <Button
                  onClick={() => setExibirModalImportacaoArquivosCheckList(true)}
                  style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                  variant="secondary"
                  size="sm"
                >
                  <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                  Selecione os arquivos do contrato
                </Button>
              </div>
              <FileUploadCheckListField
                setQuantidadeArquivosSelecionados={setQuantidadeArquivosCheckListSelecionados}
                exibirModal={exibirModalImportacaoArquivosCheckList}
                toggleModal={toggleImportacaoArquivosCheckList}
                tipoContratoId={contrato.tipoContratoId}
                contratoId={contrato.contratoId}
                modulo={EModulo.Contrato}
                campo={58}
              />
            </div>
            <div className="row mt-2">
              <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
                {quantidadeArquivosCheckListSelecionados === 0 && "Nenhum arquivos selecionado"}
                {quantidadeArquivosCheckListSelecionados === 1 && `${quantidadeArquivosCheckListSelecionados} arquivos selecionado`}
                {quantidadeArquivosCheckListSelecionados > 1 && `${quantidadeArquivosCheckListSelecionados} arquivos selecionados`}
              </a>
            </div>
          </>
        )}
      </>
    );
  };

  const renderCidade = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <div className="col-md-3 mt-3">
          {carregandoCidades ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              Carregando cidades...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <>
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                {configuracaoTela.label}
                <a style={{ fontSize: "12px" }}>
                  {configuracaoTela.obrigatorio ? "*:" : ":"}
                  {/* {configuracaoTela.help && <PopoverHelper></PopoverHelper>} */}
                </a>
              </label>
              <select
                name="cidadeId"
                value={formik.values.cidadeId}
                onChange={(e) => {
                  formik.setFieldTouched("cidadeId", true);
                  formik.setFieldValue("cidadeId", parseInt(e.target.value));
                }}
                className={clsx(
                  "form-select",
                  {
                    "is-invalid": formik.touched.cidadeId && formik.errors.cidadeId,
                  },
                  {
                    "is-valid": formik.touched.cidadeId && !formik.errors.cidadeId,
                  }
                )}
                id="form-cidadeId"
              >
                <option value="0">Selecione</option>
                {cidades.map((map: any) => {
                  return (
                    <option key={map.nome} value={map.cidadeId}>
                      {map.nome}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </>
    );
  };

  const renderEstado = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <div className="col-md-3 mt-3">
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
            <a style={{ fontSize: "12px" }}>
              {configuracaoTela.obrigatorio ? "*:" : ":"}
              {/* {configuracaoTela.help && <PopoverHelper></PopoverHelper>} */}
            </a>
          </label>
          <select
            name="estadoId"
            value={formik.values.estadoId}
            onChange={(e) => {
              formik.setFieldTouched("estadoId", true);
              formik.setFieldValue("estadoId", parseInt(e.target.value));
            }}
            className={clsx(
              "form-select",
              {
                "is-invalid": formik.touched.estadoId && formik.errors.estadoId,
              },
              {
                "is-valid": formik.touched.estadoId && !formik.errors.estadoId,
              }
            )}
            id="form-estadoId"
          >
            <option value="0">Selecione</option>
            {estados.map((map: any) => {
              return (
                <option key={map.nome} value={map.estadoId}>
                  {map.nome}
                </option>
              );
            })}
          </select>
        </div>
      </>
    );
  };

  const renderHistorico = () => {
    return (
      <>
        {historico?.length > 0 ? <LabelConcluidoDinamico historico={historico} /> : renderCapa()}
      </>
    );
  };

  const renderCapa = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      if (configuracaoTela.campoNome == ECamposIDEnum.Estado) {
        component.push(renderEstado(configuracaoTela));
        return;
      }

      else if (configuracaoTela.campoNome == ECamposIDEnum.Cidade) {
        component.push(renderCidade(configuracaoTela));
        return;
      }

      else if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        component.push(renderImportadorArquivos());
        return;
      }

      else if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumentoCheckList) {
        component.push(renderImportadorArquivosCheckList());
        return;
      }

      else {
        component.push(<CamposCapaDinamicos
          configuracaoTela={configuracaoTela}
          configuracaoTelaLista={configuracoesTela}
          advogadosInterno={[]}
          partesProcesso={[]}
          formik={formik} />);
        return;
      }
    });

    return <div className="row mt-12">{component}</div>;
  };

  const renderCadastro = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      if (configuracaoTela.campoNome == ECamposIDEnum.Estado) {
        component.push(renderEstado(configuracaoTela));
        return;
      }

      else if (configuracaoTela.campoNome == ECamposIDEnum.Cidade) {
        component.push(renderCidade(configuracaoTela));
        return;
      }

      else if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        component.push(renderImportadorArquivos());
        return;
      }

      else if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumentoCheckList) {
        component.push(renderImportadorArquivosCheckList());
        return;
      }

      else {
        component.push(<CamposCadastroDinamico
          configuracaoTelaLista={configuracoesTela}
          configuracaoTela={configuracaoTela}
          formik={formik}
        />);
        return;
      }
    });

    return <div className="row mt-12">{component}</div>;
  };

  const renderObservacao = () => {
    return (
      <div className="row mt-3">
        <div className="col-md-6">
          <label htmlFor="form-observacao" className="form-label fw-bolder text-orange">
            Observação:
          </label>
          {capa ? <p> {compromisso.observacaoCompromisso} </p> :
            <div className="input-group">
              <textarea
                {...formik.getFieldProps("observacao")}
                placeholder="Observação"
                className={"form-control"}
                id="form-observacaoCompromisso"
                rows={5}
              />

              <Button
                onClick={() => toggleAreaTextoModal()}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
              </Button>
            </div>}
        </div>
      </div>
    )
  }

  return (
    <>
      <ToastContainer />

      <AreaTextoDefault
        toggleModal={toggleAreaTextoModal}
        exibirModal={exibirModalAreaTexto}
        field="observacao"
        formik={formik}
      />
      <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Concluir: #{compromisso.compromissoId} - Contrato - {contrato.tipoContratoNome}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
            </div>
          </div>
          <div className="modal-body" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />
            <div style={{ marginLeft: "15px" }} ref={componentRef}>

              {/* Exibir "Carregando" enquanto os dados de configuração da tela são carregados */}
              {carregandoConfiguracoesTela ? (
                <Carregando />
              ) : (
                <>
                  {/* Exibir formulário de conclusão SE o status NÃO for "concluído" */}
                  {compromisso?.status?.toLowerCase() !== "concluido" && (
                    <>
                      {compromisso.fluxoFaseId ? renderCadastro() : null}
                      {renderObservacao()}
                      <AndamentoFluxoField modulo={EModulo.Contrato} submit={submitAndamento} setSubmit={setSubmitAndamento} />
                    </>
                  )}

                  {/* Exibir histórico SE o status for "concluído" */}
                  {compromisso?.status?.toLowerCase() === "concluido" && (
                    <>
                      {renderHistorico()}
                    </>
                  )}
                </>
              )}

            </div>
          </div>


          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <div className="row mt-1" style={{ margin: "0 auto" }}>
              <div className="col-md-6">
                <button
                  onClick={() => {
                    notifyFormikDanger(formik.errors);
                    formik.submitForm();
                  }}
                  disabled={capa || carregandoConfiguracoesTela} className="btn btn-orange">
                  Concluir
                </button>
              </div>
              <div className="col-md-6">
                <button onClick={() => toggleModal()} type="button" className="btn btn-danger float-end">
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ContratoFluxoModal;
