import { Droppable } from "react-beautiful-dnd";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Usuario from "./Usuario";
import IUsuariosGrupoUsuarios from "../../../../../interfaces/IUsuariosGrupoUsuarios";

interface IColumnProps {
  column: {
    id: string;
    title: string;
    usuarioIds: number[] | undefined;
  };
  customHolder: string;
  usuarios: IUsuariosGrupoUsuarios[];
}

const Column = ({ column, usuarios, customHolder }: IColumnProps) => {
  return (
    <div>
      <div>
        <div className="modal-header">
          <h6 className="modal-title text-orange">
            <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />
            {column.title}
          </h6>
        </div>
      </div>
      <div
        style={{
          marginTop: "1rem",
          borderRadius: 2,
          padding: "1.5rem",
          display: "flex",
          flexDirection: "column",
          height: "60vh",
          width: "30vw",
          maxWidth: "700px",
        }}
        className="border sm:flex-row"
      >
        <Droppable droppableId={column.id}>
          {(provided) => (
            <div
              style={{ padding: 8, overflowY: "scroll", height: "60vh" }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {usuarios?.map((usuario, index) => {
                return (
                  <Usuario
                    key={usuario.usuarioId}
                    usuario={usuario}
                    index={index}
                  />
                );
              })}

              {usuarios.length === 0 && (
                <p
                  style={{
                    color: "lightgray",
                    fontWeight: "bolder",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  {customHolder}
                </p>
              )}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default Column;
