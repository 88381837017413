import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoPedido from "../interfaces/ITipoPedido";
import { AdicionaTipoPedidoRequest } from "../interfaces/Requests/TipoPedido/AdicionaTipoPedidoRequest";
import FiltroPesquisaTipoPedidoRequest from "../interfaces/Requests/TipoPedido/FiltroPesquisaTipoPedidoRequest";
import { EditarTipoPedidoRequest } from "../interfaces/Requests/TipoPedido/EditarTipoPedidoRequest";

class TipoPedidoService {

    async obterTipoPedidos(filtros: FiltroPesquisaTipoPedidoRequest): Promise<IRecuperaLista<ITipoPedido>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoPedido>>(`TipoPedido/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoPedido(adicionaTipoPedido: AdicionaTipoPedidoRequest): Promise<ITipoPedido> {
        let { data: result } = await api.post<ITipoPedido>(`TipoPedido/AdicionaTipoPedido`, adicionaTipoPedido);

        return result;
    }


    async alterarStatus(tipoPedidoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoPedido>(`TipoPedido/AtualizaTipoPedidoPorId?id=${tipoPedidoId}`, data);
    }

    async atualizarTipoPedido(editarTipoPedido: EditarTipoPedidoRequest): Promise<void> {

        let dataTipoPedido = [
            { "op": "replace", "path": "/nome", "value": editarTipoPedido.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoPedido.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoPedido/AtualizaTipoPedidoPorId?id=${editarTipoPedido.tipoPedidoId}`, dataTipoPedido)
        ]);
    }
}


export default new TipoPedidoService();