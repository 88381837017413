import { useContext, useEffect, useRef, useState } from "react";
import { faDownload, faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { MultiSelect } from "react-multi-select-component";
import { EModulo } from "../../enum";

import IProtocolo from "../../interfaces/IProtocolo";
import IProcesso from "../../interfaces/IProcesso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaProtocoloRequest from "../../interfaces/Requests/Protocolo/FiltroPesquisaProtocoloRequest";

import ProtocoloService from "../../services/ProtocoloService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import moment from "moment";
import DocumentoService from "../../services/DocumentoService";

const ProtocoloPage = () => {

  const [StatusOptions, setStatusOptions] = useState<any>([{ label: "Encerrada", value: 1 }, { label: "Ativa", value: 2 }]);
  const [StatusSelected, setStatusSelected] = useState([]);

  const [carregandoDecisoes, setCarregandoDecisoes] = useState(false);
  const [protocolos, setProtocolos] = useState<IProtocolo[]>([]);

  const { processo, setProcesso, setAreaDireito, protocolo, setProtocolo } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaProtocoloRequest>({
    modulo: EModulo.Protocolo,
    processoId: processo.processoId,

    tipoProtocoloId: 0,
    processoEmpresaId: 0,
    statusProtocoloId: 0,

    codigo: "",
    descricao: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    tipoProtocoloTribunal: "",

    status: [],
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "xfinderProtocoloId",
  });

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, status: IdsSelected };
    });
  }, [StatusSelected]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);

  useEffect(() => {
    if (atualizarTabela) {
      setTimeout(() => {
        carregarProtocolos(filtrosPesquisa);
      }, 2000);
    }
  }, [atualizarTabela]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_protocolo");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_protocolo");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_protocolo")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaProtocoloRequest = JSON.parse((await sessionStorageService.obter("filtro_protocolo")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, processoId: filtro.processoId });
  };


  const baixarRelatorio = async (documentoId: number, nomeArquivo: string): Promise<any> => {

    Swal.fire({
      heightAuto: false,
      icon: 'info',
      title: 'Baixando arquivos...',
      showConfirmButton: false,
    });

    Swal.showLoading();

    try {

      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      Swal.update({
        title: 'Baixado com sucesso',
        text: '',
        icon: 'success',
        showConfirmButton: true
      });

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: 'Não foi possível baixar arquivos',
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: 'error',
        showConfirmButton: false
      });
    } 
  }

  async function carregarProtocolos(filtro: FiltroPesquisaProtocoloRequest): Promise<void> {
    try {
      setCarregandoDecisoes(true);
      let resultado: IRecuperaLista<IProtocolo>;

      resultado = await ProtocoloService.obterProtocolo(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setProtocolos(resultado.data);
      inserirSessionStorage();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setProtocolos([]);
    } finally {
      setCarregandoDecisoes(false);
      setAtualizaTabela(false);
    }
  }

  const colunas: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "ProtocoloId",
      selector: (row: any) => row.xfinderProtocoloId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: any) => row.descricao,
      sortable: true,
      wrap: true,
    },

    {
      name: "Tipo de Protocolo Tribunal",
      sortField: "tipoProtocoloTribunal",
      selector: (row: any) => row.tipoProtocoloTribunal,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "dataCadastro",
      cell: (row: any) => moment(row.dataCadastro).format('DD/MM/YYYY'),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      sortField: "statusProtocoloNome",
      selector: (row: any) => row.statusProtocoloNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: any) => {
        return (
          <div>

            <FontAwesomeIcon
              title="Editar"
              onClick={() => abrirCapa(row)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />

            {row.documentoId &&
              <FontAwesomeIcon
                title="Baixar"
                onClick={() => baixarRelatorio(row?.documentoId, row?.nomeArquivo)}
                style={{ fontWeight: "normal", cursor: "pointer" }}
                size="2x"
                className="mx-2 text-orange"
                icon={faDownload}
              />}

          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IProtocolo>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        tipoProtocoloId: 0,
        codigo: "",
        descricao: "",
        processoEmpresaId: 0,
        statusProtocoloId: 0,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        tipoProtocoloTribunal: "",
        xfinderProtocoloId: 0,
        clienteId: 0,
        usuarioIdCadastro: 0,
        status: [],
      };
    });
  }

  const abrirCapa = (row: IProtocolo) => {
    setProtocolo({
      ...row,
      xfinderProtocoloId: row.xfinderProtocoloId,
      tipoProtocoloId: row.tipoProtocoloId,
      categoriaId: row.tipoProtocoloId,

      modulo: protocolo.modulo,

      processoId: protocolo?.processoId,
      areaDireitoId: protocolo.areaDireitoId,

      status: row.status
    });
    navigate("/Protocolo/Capa");
  };

  return (
    <>
      <LayoutPageForButton title="Protocolos">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setProtocolo({ ...protocolo, xfinderProtocoloId: 0 });
            navigate("/Protocolo/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarProtocolos(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-protocolo"
          >

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                Identificador:
              </label>
              <input
                value={filtrosPesquisa.xfinderProtocoloId}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, xfinderProtocoloId: e.target.value };
                  });
                }}
                placeholder="ID"
                type="number"
                className={"form-control"}
                id="form"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                Tipo de Protocolo Tribunal:
              </label>
              <input
                value={filtrosPesquisa.tipoProtocoloTribunal}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, tipoProtocoloTribunal: e.target.value };
                  });
                }}
                placeholder="Informar o nome do tipo de tribunal"
                type="number"
                className={"form-control"}
                id="form"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-descricao" className="form-label fw-bolder text-orange">
                Descrição:
              </label>
              <input
                value={filtrosPesquisa.descricao}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, descricao: e.target.value };
                  });
                }}
                placeholder="Informar uma descrição"
                type="text"
                className={"form-control"}
                id="form-parteContraria"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data do Cadastro:
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: "",
                        dataCadastroMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue) => {
                    return {
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                  isLoading={false}
                  disabled={false}
                  options={StatusOptions}
                  value={StatusSelected}
                  onChange={(event: any) => setStatusSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
            </div>

            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
            </div>

          </form>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoDecisoes}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Protocolos"
            itens={protocolos}
          />
        </div>
      </div>
    </>
  );
};

export default ProtocoloPage;
