import ICidade from "../interfaces/ICidade";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { AdicionaCidadeRequest } from "../interfaces/Requests/Cidade/AdicionaCidadeRequest";
import { EditarCidadeRequest } from "../interfaces/Requests/Cidade/EditarCidadeRequest";
import FiltroPesquisaCidadeRequest from "../interfaces/Requests/Cidade/FiltroPesquisaCidadeRequest";
import api from "./Api";

class CidadeService {
  async obterCidade(filtros: any): Promise<IRecuperaLista<ICidade>> {
    let query = `${filtros.status === 0 ? "status=-1&status=1" : "status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${
      filtros.sort
    }`;

    let { data: result } = await api.get<IRecuperaLista<ICidade>>(`Cidade/RecuperaCidade?${query}`);

    return result;
  }

  async obterCidadePorEstados(filtros: any): Promise<IRecuperaLista<ICidade>> {
    let query = `${filtros.status === 0 ? "status=-1&status=1" : "status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${
      filtros.sort
    }`;

    if (filtros.estadoId.length > 0) filtros.estadoId.forEach((id: any) => (query += `&estadoId=${id}`));

    let { data: result } = await api.get<IRecuperaLista<ICidade>>(`Cidade/RecuperaCidade?${query}`);

    return result;
  }

  async adicionaCidade(adicionaCidade: AdicionaCidadeRequest): Promise<ICidade> {
    let { data: result } = await api.post<ICidade>(`Cidade/AdicionaCidade`, adicionaCidade);

    return result;
  }

  async obterCidadePorFiltros(filtros: FiltroPesquisaCidadeRequest): Promise<IRecuperaLista<ICidade>> {
    let query = `${filtros.status === 0 ? "status=-1&status=1" : "status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${
      filtros.sort
    }`;

    if (filtros.estadoId) {
      query += `&estadoId=${filtros.estadoId}`;
    } 
    
    if (filtros.nome) {
      query = "status=1&limit=100";
      query += `&estadoId=${filtros.estadoId}`;
      query += `&nome=${filtros.nome}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ICidade>>(`Cidade/RecuperaCidade?${query}`);

    return result;
  }

  async obterCidadePorIBGE(ibge: string): Promise<IRecuperaLista<ICidade>> {
    let { data: result } = await api.get<IRecuperaLista<ICidade>>(`Cidade/RecuperaCidade?status=1&limit=100&IBGE=${ibge}`);

    return result;
  }

  async alterarStatus(cidadeId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ICidade>(`Cidade/AtualizaCidadePorId?id=${cidadeId}`, data);
  }

  async atualizarCidade(editarCidade: EditarCidadeRequest): Promise<void> {
    let dataCidade = [
      { op: "replace", path: "/nome", value: editarCidade.nome },
      { op: "replace", path: "/codigo", value: editarCidade.codigo },
    ];

    await Promise.all([await api.patch<void>(`Cidade/AtualizaCidadePorId?id=${editarCidade.cidadeId}`, dataCidade)]);
  }
}

export default new CidadeService();
