import { useRef } from "react";
import { Slate } from "slate-react";
import {
  CommentContainer,
  CommentHeader,
  Container,
  Content,
  EditorArea,
  LoaderContainer,
} from "./styles";
import { renderElement, renderLeaf } from "../Element";
import { Toolbar } from "../Toolbar";
import { useEvent } from "../../hooks/useEvent";
import { useTenant } from "../../hooks/useTenant";
import { useCustomFlags } from "../../hooks/useCustomFlags";
import { customEditor } from "../../lib";
import { useValue } from "../../hooks/useValue";
import { useEditorVersion } from "../../hooks/useVersion";
import { Spinner } from "react-bootstrap";
import { useProvider } from "../../hooks/useProvider";
import { useGetThread } from "../../hooks/useGetThread";
import { CommentThread } from "../Comments";
import { useDecorate } from "../../hooks/useDecorate";
import { useThread } from "../../hooks/useThread";
import { Drawer } from "../Drawer";
import { MdClose } from "react-icons/md";
import { useDrawer } from "../Drawer/useDrawer";

const editor = customEditor();

export function DocumentEditor() {
  const editorRef = useRef<HTMLDivElement>(null);
  const { initialValue } = useValue(editor);
  const { keydown } = useEvent(editor);
  const { tenant, module } = useTenant();
  const { editorLoading } = useProvider();
  const { data } = useCustomFlags({ modulo: module, tenant });
  const decorate = useDecorate();
  const { threads } = useGetThread({
    modulo: module,
    status: 1,
    tenant,
  });
  const { thread } = useThread();
  const { open, toggle: toggleDrawer } = useDrawer()

  useEditorVersion({ 
    editor, 
    provider: tenant,
    paginationParams: { limit: 1, offset: 0 }
  });

  return (
    <Slate value={initialValue()} editor={editor}>
      <Drawer open={open}>
        <CommentContainer>
          <CommentHeader>
            <strong>Comentários <small>({threads.length})</small></strong>
            <button onClick={() => toggleDrawer()} type="button"> 
              <MdClose size={20} />
            </button>
          </CommentHeader>
          <div id="threads">
            {threads.map((thread) => (
              <CommentThread
                modulo={module}
                thread={thread}
                key={thread.comentarioPai.comentarioPaiId}
                toggleDrawer={toggleDrawer}
              />
            ))}
          </div>
        </CommentContainer>
      </Drawer>
      <Container>
        {!editorLoading && <Toolbar toggleComments={toggleDrawer} editorRef={editorRef} />}

        {editorLoading && (
          <LoaderContainer>
            <div>
              <span>Carregando minuta</span>
              <Spinner size="sm" />
            </div>
          </LoaderContainer>
        )}

        <Content evenly={threads.length > 0}>
          <div ref={editorRef} id="editor-container">
            <EditorArea
              readOnly={data?.bloquearEdicaoMinuta ?? false}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onKeyDown={keydown}
              decorate={(entry) =>
                decorate.comment(editor, entry, thread.selectedThread)
              }
            />
          </div>
        </Content>
      </Container>
    </Slate>
  );
}
