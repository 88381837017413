import clsx from "clsx";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTimes } from "@fortawesome/free-solid-svg-icons";
import XFinderIntimacaoService from "../../services/XFinderIntimacaoService";
import IConcluiXFinderIntimacao from "../../interfaces/Requests/XFinder/IConcluiXFinderIntimacao";

interface IXFinderIntimacaoFieldProps {
  toggleModal: () => void;
  intimacaoId: number;
  exibirModal: boolean;
  setAtualizarTabela(atualizaAgora: boolean): void;

}

const XFinderIntimacaoField = ({ toggleModal, exibirModal, setAtualizarTabela, intimacaoId}: IXFinderIntimacaoFieldProps) => {  
  const initialValues: IConcluiXFinderIntimacao = {
    id: intimacaoId,
    motivoConclusao: ""
  };

  const schema = () => {
    let schema: any = {};
    schema.motivoConclusao = Yup.string().min(5).required("Campo motivo é obrigatório");

    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Concluir Intimação",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.showLoading();
          await XFinderIntimacaoService.concluiXFinderIntimacao(values, intimacaoId);
          toggleModal();
          setSubmitting(false);
          formik.resetForm();
          Swal.hideLoading();

          Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: "Intimação concluída com sucesso.",
            showConfirmButton: true,
            timer: 4000
          });

          setAtualizarTabela(true);

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar concluir a intimação`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  async function cancelar() {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Concluir Intimação
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">
            <div className="col-md-12 mt-3">
                <label htmlFor="form-motivoConclusao" className="form-label required fw-bolder text-orange">
                  Motivo da Conclusão
                </label>
                <textarea
                  {...formik.getFieldProps("motivoConclusao")}
                  maxLength={4000}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.motivoConclusao && formik.errors.motivoConclusao,
                    },
                    {
                      "is-valid": formik.touched.motivoConclusao && !formik.errors.motivoConclusao,
                    }
                  )}
                  id="form-motivoConclusao"
                />
                {formik.touched.motivoConclusao && formik.errors.motivoConclusao && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.motivoConclusao}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
        <button disabled={formik.isSubmitting || !formik.isValid}  onClick={() => formik.submitForm()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default XFinderIntimacaoField;