import { useContext, useEffect, useState } from 'react';
import { faEraser, faSearch, faFastForward, faEdit, faPaperPlane, faTrash, faPlus, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { InputGroup, OverlayTrigger } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from 'moment';

import { ETipoRisco } from '../../enum/ETipoRisco';
import { EModulo, EPropriedadesIDEnum } from '../../enum';
import { currencyLabelFormatterNotRS, currencyFormatterNotRS } from '../../utils/Utils';


import IRisco from "../../interfaces/IRisco";
import IIndice from "../../interfaces/IIndice";
import IValores from '../../interfaces/IValores';
import ITipoProvisao from '../../interfaces/ITipoProvisao';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import { OrangeInterfaceContext } from '../../interfaces/Contexts/OrangeInterfaceContext';
import FiltroPesquisaValoresRequest from '../../interfaces/Requests/Valores/FiltroPesquisaValoresRequest';

import RiscoService from '../../services/RiscoService';
import IndiceService from '../../services/IndiceService';
import ValoresService from '../../services/ValoresService';
import TipoProvisaoService from '../../services/TipoProvisaoService';
import ConfiguracaoTelaService from '../../services/ConfiguracaoTelaService';

import ValoresModal from './ValoresModal';
import GridPadrao from '../../components/Comum/GridPadrao';
import LayoutPageTitle from '../../layout/LayoutPageTitle';
import { OrangeContext } from '../../contexts/OrangeProvider';
import AlterarFaseValoresModal from './AlterarFaseValoresModal';
import LayoutPageForButton from '../../layout/LayoutPageButton';
import { notifySuccess } from '../../components/Comum/Toast/ToastFormik';
import { PopoverSubMenuProcesso } from '../../components/Comum/Popover/PopoverSubMenuProcesso';
import CurrencyField, { CurrencyFormatterLabel } from '../../components/Comum/CurrencyBRL/CurrencyField';


const GerenciarValoresPage = () => {
    const { processo } = useContext<OrangeInterfaceContext>(OrangeContext);

    const [indices, setIndices] = useState<IIndice[]>([]);
    const [riscos, setRiscos] = useState<IRisco[]>([]);
    const [tipoProvisao, setTipoProvisao] = useState<ITipoProvisao[]>([]);

    const [valores, setValores] = useState<IValores>();
    const [valoresList, setValoresList] = useState<IValores[]>([]);

    const [generic, setGeneric] = useState<any>();

    const [exibirModal, setExibirModal] = useState<boolean>(false);
    const [exibirModalFase, setExibirModalFase] = useState<boolean>(false);
    const [exibirTipoProvisao, setExibirTipoProvisao] = useState<boolean>(true);

    const [atualizarTabela, setAtualizarTabela] = useState<boolean>(false);

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaValoresRequest>({
        ValoresProcessoPedidoId: 0,
        ValoresProcessoId: 0,
        ProcessoId: processo.processoId,
        TipoPedidoId: 0,
        ValoresFaseId: 0,
        Fase: 0,
        codigo: "",
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'ValoresProcessoPedidoId'
    });

    const navigate = useNavigate();

    const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });

    const handlePageChange = (page: number) => setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });

    const handleSort = async (column: TableColumn<IValores>, sortDirection: string) => setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });

    const formik = useFormik({
        initialValues: valoresList,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(false);
        },
    });

    useEffect(() => {

        carregarValores(filtrosPesquisa);

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


    useEffect(() => {
        if (atualizarTabela) {
            carregarValores(filtrosPesquisa);
        }
    }, [atualizarTabela]);

    useEffect(() => {

        carregarIndice();

        carregarRisco();

        carregarPropriedade();

        carregarTipoProvisao();

    }, []);

    useEffect(() => {
        if (!processo.processoId) {
            navigate("/Processo/Capa");
        }
    }, [processo]);

    useEffect(() => { formik.setValues(valoresList); }, [valoresList.length]);

    const limparFiltros = () => {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                despesaId: 0,
                tipoValoresId: 0,
                tipoValoresNome: "",
                processoId: 0,
                descricao: "",
                DataEfetivaMaiorIgual: "",
                DataEfetivaMenorIgual: "",
                DataVencimentoMaiorIgual: "",
                DataVencimentoMenorIgual: "",
                DataFatalMaiorIgual: "",
                DataFatalMenorIgual: "",
                ValorPrincipalMaiorIgual: 0,
                ValorPrincipalMenorIgual: 0,
                ValorTotalMaiorIgual: 0,
                ValorTotalMenorIgual: 0,
                dataCadastroMaiorIgual: "",
                dataCadastroMenorIgual: "",
                statusValoresId: 0,
                statusValoresNome: "",
            }
        });
    }

    const toggleModal = (carregarDados?: boolean) => {
        if (carregarDados) {
            carregarValores(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);
    }

    const toggleModalFase = (carregarDados?: boolean) => {
        if (carregarDados) {
            carregarValores(filtrosPesquisa);
        }
        setExibirModalFase(!exibirModalFase);
    }

    const validarSomatoriaPrincipal = (index: number): boolean => {
        if (formik.values[index]?.valoresFaseReadDto?.tipoRisco == ETipoRisco.Percentual) {

            formik.values[index].valoresFaseReadDto.valor = formik.values[index].valoresFaseReadDto.valor ? formik.values[index].valoresFaseReadDto.valor : 0;
            formik.values[index].valoresFaseReadDto.provavelValor = formik.values[index]?.valoresFaseReadDto?.provavelValor ? formik.values[index]?.valoresFaseReadDto?.provavelValor : 0;
            formik.values[index].valoresFaseReadDto.possivelValor = formik.values[index]?.valoresFaseReadDto?.possivelValor ? formik.values[index]?.valoresFaseReadDto?.possivelValor : 0;
            formik.values[index].valoresFaseReadDto.remotoValor = formik.values[index]?.valoresFaseReadDto?.remotoValor ? formik.values[index]?.valoresFaseReadDto?.remotoValor : 0;

            if (!formik.values[index].valoresFaseReadDto.valor) {
                Swal.fire({ title: "Ops!", text: "Informe o valor total do percentual", timer: 4000, icon: "info" });
                return false;
            }

            let somatoriaValores = (parseFloat(formik.values[index]?.valoresFaseReadDto?.provavelValor.toString())) +
                (parseFloat(formik.values[index]?.valoresFaseReadDto?.possivelValor.toString())) +
                (parseFloat(formik.values[index]?.valoresFaseReadDto?.remotoValor.toString())) / 100;

            if (somatoriaValores > 100) {
                Swal.fire({ title: "Ops!", text: "Somatória dos percentuais não está 100%", timer: 4000, icon: "info" });
                formik.values[index].valoresFaseReadDto.valor = 0;
                formik.values[index].valoresFaseReadDto.provavelValor = 0;
                formik.values[index].valoresFaseReadDto.possivelValor = 0;
                formik.values[index].valoresFaseReadDto.remotoValor = 0;
                return false;
            }
        }
        return true;
    }

    const validarProbabilidadeMulta = (index: number) => {

        if (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRiscoReadDto.riscoId == 3) {
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaProvavelValor = 0;
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaPossivelValor = 0;
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaRemotoValor = 100;
        }

        if (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRiscoReadDto.riscoId == 2) {
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaProvavelValor = 0;
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaPossivelValor = 100;
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaRemotoValor = 0;
        }
        if (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRiscoReadDto.riscoId == 1) {
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaProvavelValor = 100;
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaPossivelValor = 0;
            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaRemotoValor = 0;
        }
    }

    const validarProbabilidadePrincipal = (index: number) => {

        if (formik.values[index]?.valoresFaseReadDto?.riscoId == 3) {
            formik.values[index].valoresFaseReadDto.provavelValor = 0;
            formik.values[index].valoresFaseReadDto.possivelValor = 0;
            formik.values[index].valoresFaseReadDto.remotoValor = 100;
        } else if (formik.values[index]?.valoresFaseReadDto?.riscoId == 2) {
            formik.values[index].valoresFaseReadDto.provavelValor = 0;
            formik.values[index].valoresFaseReadDto.possivelValor = 100;
            formik.values[index].valoresFaseReadDto.remotoValor = 0;
        } else
            if (formik.values[index]?.valoresFaseReadDto?.riscoId == 1) {
                formik.values[index].valoresFaseReadDto.provavelValor = 100;
                formik.values[index].valoresFaseReadDto.possivelValor = 0;
                formik.values[index].valoresFaseReadDto.remotoValor = 0;
            } else {
                formik.values[index].valoresFaseReadDto.provavelValor = 0;
                formik.values[index].valoresFaseReadDto.possivelValor = 0;
                formik.values[index].valoresFaseReadDto.remotoValor = 100;
            }
    }

    const resetEdit = (valoresList: IValores[]) => {
        valoresList.forEach((item: IValores) => {
            setGeneric("0");

            // Principal
            item.valoresFaseReadDto.tipoRiscoExibir = false;
            item.valoresFaseReadDto.tipoProvisaoIdExibir = false;
            item.valoresFaseReadDto.tipoContingenciaReadDto = item.valoresFaseReadDto.tipoContingenciaReadDto ? item.valoresFaseReadDto.tipoContingenciaReadDto : { tipoContingenciaIdExibir: false, tipoContingenciaId: 0, nome: 'N/A' };
            item.valoresFaseReadDto.provavelValor = item.valoresFaseReadDto.provavelValor ? item.valoresFaseReadDto.provavelValor : 0;
            item.valoresFaseReadDto.possivelValor = item.valoresFaseReadDto.possivelValor ? item.valoresFaseReadDto.possivelValor : 0;
            item.valoresFaseReadDto.remotoValor = item.valoresFaseReadDto.remotoValor ? item.valoresFaseReadDto.remotoValor : 0;
            item.valoresFaseReadDto.valor = item.valoresFaseReadDto.valor ? item.valoresFaseReadDto.valor : 0;
            item.valoresFaseReadDto.juros = item.valoresFaseReadDto.juros ? item.valoresFaseReadDto.juros : 0;
            item.valoresFaseReadDto.indiceIdExibir = false;
            item.valoresFaseReadDto.riscoIdExibir = false;
            item.valoresFaseReadDto.valorExibir = false;
            item.valoresFaseReadDto.jurosDataBaseBaseExibir = false;
            item.valoresFaseReadDto.correcaoDataBaseExibir = false;
            item.valoresFaseReadDto.valorExibir = false;
            item.valoresFaseReadDto.possivelValorExibir = false;
            item.valoresFaseReadDto.remotoValorExibir = false;
            item.valoresFaseReadDto.provavelValorExibir = false;
            item.valoresFaseReadDto.jurosValorExibir = false;


            // Honorarios
            item.valoresFaseReadDto.encargosHonorariosReadDto.provavelValor = item.valoresFaseReadDto?.encargosHonorariosReadDto?.provavelValor ? item?.valoresFaseReadDto?.encargosHonorariosReadDto?.provavelValor : 0;
            item.valoresFaseReadDto.encargosHonorariosReadDto.possivelValor = item.valoresFaseReadDto?.encargosHonorariosReadDto?.possivelValor ? item?.valoresFaseReadDto?.encargosHonorariosReadDto?.possivelValor : 0;
            item.valoresFaseReadDto.encargosHonorariosReadDto.remotoValor = item.valoresFaseReadDto?.encargosHonorariosReadDto?.remotoValor ? item?.valoresFaseReadDto?.encargosHonorariosReadDto?.remotoValor : 0;
            item.valoresFaseReadDto.encargosHonorariosReadDto.valor = item.valoresFaseReadDto?.encargosHonorariosReadDto?.valor ? item?.valoresFaseReadDto?.encargosHonorariosReadDto?.valor : 0;
            item.valoresFaseReadDto.encargosHonorariosReadDto.valorExibir = false;
            item.valoresFaseReadDto.encargosHonorariosReadDto.possivelValorExibir = false;
            item.valoresFaseReadDto.encargosHonorariosReadDto.remotoValorExibir = false;
            item.valoresFaseReadDto.encargosHonorariosReadDto.provavelValorExibir = false;

            // Multas
            item.valoresFaseReadDto.valoresMultaReadDto.multaProvavelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaPossivelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaRemotoValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaJuros = item.valoresFaseReadDto?.valoresMultaReadDto?.multaJuros ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaJuros : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIndiceReadDto.indiceIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaRiscoReadDto.riscoIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaJurosDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaCorrecaoDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaPossivelValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaRemotoValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaProvavelValorExibir = false;

            // Oficio
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioProvavelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioProvavelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioProvavelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioPossivelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioPossivelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioPossivelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioRemotoValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRemotoValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRemotoValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioJuros = item.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioJuros ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioJuros : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioIndiceIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioRiscoIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioCorrecaoDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioPossivelValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaOficioRemotoValorExibir = false;

            // Mora
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraProvavelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraProvavelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraProvavelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraPossivelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraPossivelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraPossivelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraRemotoValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRemotoValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRemotoValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraJuros = item.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraJuros ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraJuros : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraIndiceIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraRiscoIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraCorrecaoDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraPossivelValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraRemotoValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaMoraProvavelValorExibir = false;


            // Isolada
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvavelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaProvavelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaProvavelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaPossivelValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaPossivelValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaPossivelValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRemotoValor = item.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRemotoValor ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRemotoValor : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJuros = item.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaJuros ? item?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaJuros : 0;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaIndiceIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRiscoIdExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaCorrecaoDataBaseExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaPossivelValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRemotoValorExibir = false;
            item.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvavelValorExibir = false;

        });
        return valoresList;
    }

    const alterarStatus = async (Id: number, status: number) => {
        try {
            const swalWithBootstrapButtons = Swal.mixin({
                heightAuto: false,
                customClass: {
                    confirmButton: 'btn btn-orange',
                    cancelButton: 'btn btn-danger ms-5'
                },
                buttonsStyling: false
            });

            let result = await swalWithBootstrapButtons.fire({
                title: 'Alterar Status ',
                text: `Você realmente deseja alterar o status?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Confirmar`
            });

            if (result.isConfirmed) {
                await ValoresService.alterarStatus(Id, status);

                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Status do alterado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                })

                await limparFiltros();

                setFiltrosPesquisa(oldState => {
                    carregarValores(oldState);
                    return oldState;
                })
            }

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel alterar o Status do Tipo de Imovel`,
                text: error?.response?.data?.Message,
                showConfirmButton: true
            });
        }
    }

    const carregarValores = async (filtro: FiltroPesquisaValoresRequest) => {
        try {
            let resultado: IRecuperaLista<IValores>;

            resultado = await ValoresService.obterValores(filtro);

            setValoresList(resetEdit(resultado.data));

            formik.setValues(resetEdit(resultado.data));

            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });

        } catch (error: any) {
            setValoresList([]);
        } finally {
            setAtualizarTabela(false);
        }
    }

    const carregarRisco = async () => {
        try {

            let resultado: IRecuperaLista<IRisco>;

            resultado = await RiscoService.obterListaLimitada({ offset: 0, limit: 100, codigo: '', status: 1, totalItems: 100, nome: '', sort: 'riscoId' });

            setRiscos(resultado.data);

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setRiscos([]);
        }
    }

    const carregarIndice = async () => {
        try {

            let resultado: IRecuperaLista<IIndice>;

            resultado = await IndiceService.obterIndices({ offset: 0, limit: 1000, codigo: '', status: 1, totalItems: 100, nome: '', sort: 'indiceId' });

            setIndices(resultado.data);

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setIndices([]);
        }
    }

    const carregarPropriedade = async () => {
        try {

            let resultado = await ConfiguracaoTelaService.obterPropriedadesTela(EModulo.Valores);

            setExibirTipoProvisao(resultado.data?.find(e => e.codigo == EPropriedadesIDEnum.HabilitarTipoProvisao));

        } catch (error: any) {
            setExibirTipoProvisao(false);
        }
    }


    const carregarTipoProvisao = async () => {
        try {

            let resultado: IRecuperaLista<ITipoProvisao>;

            resultado = await TipoProvisaoService.obterListaLimitada({ offset: 0, limit: 1000, codigo: '', status: 1, totalItems: 100, nome: '', sort: 'indiceId' });

            setTipoProvisao(resultado.data);

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setTipoProvisao([]);
        }
    }

    const colunas: TableColumn<IValores>[] = [
        {
            cell: (valor: IValores, index: number) => {
                return (
                    <>
                        <a style={{ paddingTop: '50px', cursor: "pointer" }}>

                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.submitForm();
                                }}
                            >
                                {renderTitulo(index)}
                                {renderPrincipal(index)}
                                {renderHonorarios(index)}

                                {processo.areaDireitoId == 3 ?
                                    <>
                                        {renderMultaIsolada(index)}
                                        {renderMultaOficio(index)}
                                        {renderMultaMora(index)}
                                        {renderTributarioTotal(index)}
                                    </> :
                                    <>
                                        {renderMulta(index)}
                                        {renderTotal(index)}
                                    </>
                                }


                            </form>
                        </a>
                    </>
                );
            },
            ignoreRowClick: true,
        },
    ];

    const renderProbalidadeMultaIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    try {
                        validarProbabilidadeMulta(index);
                        eval(`formik.values[${index}].${field}Exibir=false`);
                        formik.setValues(formik.values);
                        await ValoresService.atualizarProbabilidadeValoresMulta(formik.values[index]);
                        notifySuccess('Alteração realizada com sucesso');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        carregarValores(filtrosPesquisa);
                    }
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    formik.setValues(resetEdit(formik.values));
                    eval(`formik.values[${index}].${field}Exibir=true`);
                    setGeneric(eval(`formik.values[${index}].${field}`));
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderProbalidadePrincipalIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    try {
                        validarProbabilidadePrincipal(index);
                        eval(`formik.values[${index}].${field}Exibir=false`);
                        formik.setValues(formik.values);
                        await ValoresService.atualizarProbabilidadeValoresPrincipal(formik.values[index]);
                        notifySuccess('Alteração realizada com sucesso');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        carregarValores(filtrosPesquisa);
                    }
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    formik.setValues(resetEdit(formik.values));
                    eval(`formik.values[${index}].${field}Exibir=true`);
                    setGeneric(eval(`formik.values[${index}].${field}`));
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderPrincipalIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    try {
                        validarSomatoriaPrincipal(index);
                        eval(`formik.values[${index}].${field}Exibir=false`);
                        formik.setValues(formik.values);
                        await ValoresService.atualizarValoresPrincipal(formik.values[index]);
                        notifySuccess('Alteração realizada com sucesso');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        carregarValores(filtrosPesquisa);
                    }
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    formik.setValues(resetEdit(formik.values));
                    eval(`formik.values[${index}].${field}Exibir=true`);
                    setGeneric(eval(`formik.values[${index}].${field}`));
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderHonorariosIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=false`);
                    formik.setValues(formik.values);
                    await ValoresService.atualizarValoresHonorarios(formik.values[index]);
                    carregarValores(filtrosPesquisa);
                    notifySuccess('Alteração realizada com sucesso');
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=true`)
                    formik.setValues(formik.values);
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderMultaIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=false`);
                    formik.setValues(formik.values);
                    await ValoresService.atualizarValoresMulta(formik.values[index]);
                    carregarValores(filtrosPesquisa);
                    notifySuccess('Alteração realizada com sucesso');
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=true`)
                    formik.setValues(formik.values);
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderMultaIsoladaIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=false`);
                    formik.setValues(formik.values);
                    await ValoresService.atualizarValoresMultaIsolada(formik.values[index]);
                    carregarValores(filtrosPesquisa);
                    notifySuccess('Alteração realizada com sucesso');
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=true`)
                    formik.setValues(formik.values);
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderMultaMoraIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=false`);
                    formik.setValues(formik.values);
                    await ValoresService.atualizarValoresMultaMora(formik.values[index]);
                    carregarValores(filtrosPesquisa);
                    notifySuccess('Alteração realizada com sucesso');
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=true`)
                    formik.setValues(formik.values);
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderMultaOficioIconEdit = (index: number, field: string) => {

        if (eval(`formik.values[${index}]?.${field}Exibir==true`)) {
            return (<InputGroup.Text
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=false`);
                    formik.setValues(formik.values);
                    await ValoresService.atualizarValoresMultaOficio(formik.values[index]);
                    carregarValores(filtrosPesquisa);
                    notifySuccess('Alteração realizada com sucesso');
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    eval(`formik.values[${index}].${field}Exibir=true`)
                    formik.setValues(formik.values);
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const renderTrocarTipoRisco = () => {

        if (formik.values[0]?.valoresFaseReadDto?.tipoRiscoExibir) {
            return (<InputGroup.Text
                onClick={async () => {
                    formik.values[0].valoresFaseReadDto.tipoRiscoExibir = false;
                    formik.setValues(formik.values);
                    await ValoresService.trocarTipoRiscoId(processo.processoId, formik.values[0].valoresFaseReadDto.tipoRisco);
                    carregarValores(filtrosPesquisa);
                }}
            ><FontAwesomeIcon style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faPaperPlane} /> </InputGroup.Text>);
        }
        else {
            return (<FontAwesomeIcon
                onClick={async () => {
                    formik.values[0].valoresFaseReadDto.tipoRiscoExibir = true;
                    formik.setValues(formik.values);
                }} style={{ cursor: "pointer" }} color={"var(--primary-base2)"} className="mx-2" icon={faEdit} />);
        }
    }

    const onValueChange = (v: any, index: number, field: string) => eval(`formik.values[${index}].${field}=${v.floatValue}`);


    const renderTitulo = (index: number) => {
        return (
            <>
                <div style={{ width: '2600px' }} className="row overflow-auto">

                    <div style={{ width: '210px' }}>
                        <label className="form-label fw-bolder text-orange">
                            <h6>Objeto: {formik.values[index]?.tipoPedidoNome}</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            Total:
                        </label>

                    </div>
                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-provavel" className="form-label fw-bolder text-orange">
                            Provável:
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-provavel" className="form-label fw-bolder text-orange">
                            Possível:
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-remoto" className="form-label fw-bolder text-orange">
                            Remoto:
                        </label>

                    </div>

                    {processo.tipoContingenciaId == 3 &&
                        <div style={{ width: '200px' }}>
                            <label htmlFor="form-indice" className="form-label required fw-bolder text-orange">
                                Tipo da Contingência:
                            </label>
                        </div>
                    }

                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-indice" className="form-label required fw-bolder text-orange">
                            Índice:
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-dataBaseCorrecao" className="form-label fw-bolder text-orange">
                            DB de Correção:
                        </label>

                    </div>
                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-juros" className="form-label fw-bolder text-orange">
                            Juros %:
                        </label>

                    </div>
                    <div style={{ width: '200px' }}>
                        <label htmlFor="form-dataBaseJuros" className="form-label fw-bolder text-orange">
                            DB. de Juros:
                        </label>

                    </div>
                    {formik.values[index]?.valoresFaseReadDto?.tipoRisco == 2 &&
                        <div style={{ width: '200px' }}>
                            <label htmlFor="form-risco" className="form-label required fw-bolder text-orange">
                                Probabilidade de Perda:
                            </label>
                        </div>}

                    <div style={{ width: '200px', visibility: exibirTipoProvisao ? 'initial' : 'hidden' }}>
                        <label htmlFor="form-provisao" className="form-label required fw-bolder text-orange">
                            Tipo de Provisão:
                        </label>
                    </div>

                    <div style={{ width: '200px' }}>
                        <label className="form-label fw-bolder text-orange">
                            <FontAwesomeIcon onClick={(() => alterarStatus(formik.values[index].valoresProcessoPedidoId, -1))} title="Excluir" style={{ fontWeight: 'normal', cursor: 'pointer' }} size='1x' className='mx-2 text-orange' icon={faTrash} />
                        </label>
                    </div>
                </div>
            </>
        )


    }

    const renderPrincipal = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Principal</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valorExibir ?
                                <InputGroup>
                                    {renderProbalidadePrincipalIconEdit(index, "valoresFaseReadDto.valor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valor} />
                                </InputGroup> :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valor)}
                                    {renderProbalidadePrincipalIconEdit(index, "valoresFaseReadDto.valor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.provavelValorExibir ?
                                <InputGroup>
                                    {renderPrincipalIconEdit(index, "valoresFaseReadDto.provavelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.provavelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.provavelValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.provavelValor)}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.possivelValorExibir ?
                                <InputGroup>
                                    {renderPrincipalIconEdit(index, "valoresFaseReadDto.possivelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.possivelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.possivelValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.possivelValor)}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.remotoValorExibir ?
                                <InputGroup>
                                    {renderPrincipalIconEdit(index, "valoresFaseReadDto.remotoValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.remotoValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.remotoValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.remotoValor)}
                                </label>}
                        </p>
                    </div>
                    {processo.tipoContingenciaId == 3 &&
                        <div style={{ width: '200px' }}>
                            {formik.values[index]?.valoresFaseReadDto?.tipoContingenciaReadDto?.tipoContingenciaIdExibir ?
                                <InputGroup>
                                    {renderPrincipalIconEdit(index, "valoresFaseReadDto.tipoContingenciaReadDto.tipoContingenciaId")}
                                    <select
                                        value={generic}
                                        onChange={(e) => {
                                            setGeneric(e.target.value);
                                            formik.values[index].valoresFaseReadDto.tipoContingenciaReadDto.tipoContingenciaId = parseInt(e.target.value);
                                        }}
                                        className={"form-control"}
                                        id="form-risco"
                                    >
                                        <option value="0"> Selecione</option>
                                        <option value="1"> Passivo</option>
                                        <option value="2"> Ativo </option>

                                    </select>
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {formik.values[index].valoresFaseReadDto?.tipoContingenciaReadDto?.nome}
                                    {renderPrincipalIconEdit(index, "valoresFaseReadDto.tipoContingenciaReadDto.tipoContingenciaId")}
                                </label>}
                        </div>}

                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.indiceIdExibir ?
                            <InputGroup>
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.indiceId")}
                                <select
                                    value={generic}
                                    onChange={(e) => {
                                        setGeneric(e.target.value);
                                        formik.values[index].valoresFaseReadDto.indiceId = parseInt(e.target.value);
                                    }}
                                    className={"form-control"}
                                    id="form-indice"
                                >
                                    <option value="0"> Selecione um Índice </option>
                                    {indices.map((map) => {
                                        return (
                                            <option key={map.indiceId} value={map.indiceId}>
                                                {" "}
                                                {map.nome}{" "}
                                            </option>
                                        );
                                    })}
                                </select>
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {indices.length > 0 && (indices.filter(e => e.indiceId == formik.values[index]?.valoresFaseReadDto?.indiceId)[0]?.nome || "Não Informado")}
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.indiceId")}
                            </label>}
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.correcaoDataBaseExibir ?
                            <InputGroup>
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.correcaoDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(generic)}
                                    onChange={(date: Date) => {
                                        formik.values[index].valoresFaseReadDto.correcaoDataBase = date;
                                        setGeneric(date);
                                    }} />
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto?.correcaoDataBase).format("DD/MM/YYYY")}
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.correcaoDataBase")}
                            </label>
                        }
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.jurosExibir ?
                                <InputGroup>
                                    {renderPrincipalIconEdit(index, "valoresFaseReadDto.juros")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.juros")} floatValue={formik.values[index]?.valoresFaseReadDto?.juros} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.juros)}
                                    {renderPrincipalIconEdit(index, "valoresFaseReadDto.juros")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.jurosDataBaseBaseExibir ?
                            <InputGroup>
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.jurosDataBaseBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto.jurosDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.jurosDataBase = date} />
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto?.jurosDataBase).format("DD/MM/YYYY")}
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.jurosDataBaseBase")}
                            </label>
                        }
                    </div>
                    {formik.values[index]?.valoresFaseReadDto?.tipoRisco == 2 &&
                        <div style={{ width: '200px' }}>
                            {formik.values[index]?.valoresFaseReadDto?.riscoIdExibir ?
                                <InputGroup>
                                    {renderProbalidadePrincipalIconEdit(index, "valoresFaseReadDto.riscoId")}
                                    <select
                                        value={generic}
                                        onChange={(e) => {
                                            setGeneric(e.target.value);
                                            formik.values[index].valoresFaseReadDto.riscoId = parseInt(e.target.value);
                                        }}
                                        className={"form-control"}
                                        id="form-risco"
                                    >
                                        <option value="0"> Selecione um Risco </option>
                                        {riscos.map((map) => {
                                            return (
                                                <option key={map.riscoId} value={map.riscoId}>
                                                    {" "}
                                                    {map.nome}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {riscos.length > 0 && (riscos.filter(e => e.riscoId == formik.values[index]?.valoresFaseReadDto?.riscoId)[0]?.nome || "Não Informado")}
                                    {renderProbalidadePrincipalIconEdit(index, "valoresFaseReadDto.riscoId")}
                                </label>}
                        </div>}

                    <div style={{ width: '200px', visibility: exibirTipoProvisao ? 'initial' : 'hidden'  }}>
                        {formik.values[index]?.valoresFaseReadDto?.tipoProvisaoIdExibir ?
                            <InputGroup>
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.tipoProvisaoId")}
                                <select
                                    value={generic}
                                    onChange={(e) => {
                                        setGeneric(e.target.value);
                                        formik.values[index].valoresFaseReadDto.tipoProvisaoId = parseInt(e.target.value);
                                    }}
                                    className={"form-control"}
                                    id="form-risco"
                                >
                                    <option value="0"> Selecione um Tipo de Provisão </option>
                                    {tipoProvisao.map((map) => {
                                        return (
                                            <option key={map.tipoProvisaoId} value={map.tipoProvisaoId}>
                                                {map.nome}
                                            </option>
                                        );
                                    })}
                                </select>
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {tipoProvisao.length > 0 && (tipoProvisao.filter(e => e.tipoProvisaoId == formik.values[index]?.valoresFaseReadDto?.tipoProvisaoId)[0]?.nome || "Não Informado")}
                                {renderPrincipalIconEdit(index, "valoresFaseReadDto.tipoProvisaoId")}
                            </label>}
                    </div>
                </div>
            </>
        )


    }

    const renderHonorarios = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Honorários</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.valorExibir ?
                                <InputGroup>
                                    {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.valor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.encargosHonorariosReadDto.valor")} floatValue={formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.valor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.valor)}
                                    {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.valor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.provavelValorExibir ?
                                <InputGroup>
                                    {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.provavelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.encargosHonorariosReadDto.provavelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.provavelValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.provavelValor)}
                                    {/* {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.provavelValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.possivelValorExibir ?
                                <InputGroup>
                                    {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.possivelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.encargosHonorariosReadDto.possivelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.possivelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.possivelValor)}
                                    {/* {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.possivelValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.remotoValorExibir ?
                                <InputGroup>
                                    {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.remotoValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.encargosHonorariosReadDto.remotoValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.remotoValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto?.remotoValor)}
                                    {/* {renderHonorariosIconEdit(index, "valoresFaseReadDto.encargosHonorariosReadDto.remotoValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }} />
                </div>
            </>
        )


    }

    const renderMulta = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Multa</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaValorExibir ?
                                <InputGroup>
                                    {renderProbalidadeMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaValor)}
                                    {renderProbalidadeMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValorExibir ?
                                <InputGroup>
                                    {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaProvavelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaProvavelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaProvavelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValor)}
                                    {/* {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaProvavelValor")} */}
                                </label>}
                        </p>

                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValorExibir ?
                                <InputGroup>
                                    {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaPossivelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaPossivelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaPossivelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValor)}
                                    {/* {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaPossivelValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValorExibir ?
                                <InputGroup>
                                    {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaRemotoValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaRemotoValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaRemotoValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValor)}
                                    {/* {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaRemotoValor")} */}
                                </label>}
                        </p>
                    </div>

                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIndiceReadDto?.indiceIdExibir ?
                            <InputGroup>
                                {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIndiceReadDto.indiceId")}
                                <select
                                    value={generic}
                                    onChange={(e) => {
                                        setGeneric(e.target.value);
                                        formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaIndiceReadDto.indiceId = parseInt(e.target.value);
                                    }}
                                    className={"form-control"}
                                    id="form-indice"
                                >
                                    <option value="0"> Selecione um Índice </option>
                                    {indices.map((map) => {
                                        return (
                                            <option key={map.indiceId} value={map.indiceId}>
                                                {" "}
                                                {map.nome}{" "}
                                            </option>
                                        );
                                    })}
                                </select>
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {indices.length > 0 && (indices.filter(e => e.indiceId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIndiceReadDto?.indiceId)[0]?.nome || "Não Informado")}
                                {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIndiceReadDto.indiceId")}
                            </label>}
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaJurosDataBaseExibir ?
                            <InputGroup>
                                {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaJurosDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaJurosDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaJurosDataBase = date} />
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaJurosDataBase).format("DD/MM/YYYY")}
                                {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaJurosDataBase")}
                            </label>
                        }
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaJurosValorExibir ?
                                <InputGroup>
                                    {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaJurosValor")}
                                    <CurrencyField
                                        onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaJurosValor")}
                                        floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaJurosValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyFormatterNotRS(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaJurosValor)}
                                    {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaJurosValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaCorrecaoDataBaseExibir ?
                            <InputGroup>
                                {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaCorrecaoDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaCorrecaoDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaCorrecaoDataBase = date} />
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaCorrecaoDataBase).format("DD/MM/YYYY")}
                                {renderMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaCorrecaoDataBase")}
                            </label>
                        }
                    </div>
                    {formik.values[index]?.valoresFaseReadDto?.tipoRisco == 2 &&
                        <div style={{ width: '200px' }}>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRiscoReadDto?.riscoIdExibir ?
                                <InputGroup>
                                    {renderProbalidadeMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaRiscoReadDto.riscoId")}
                                    <select
                                        value={generic}
                                        onChange={(e) => {
                                            setGeneric(e.target.value);
                                            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaRiscoReadDto.riscoId = parseInt(e.target.value);
                                        }}
                                        className={"form-control"}
                                        id="form-risco"
                                    >
                                        <option value="0"> Selecione um Risco </option>
                                        {riscos.map((map) => {
                                            return (
                                                <option key={map.riscoId} value={map.riscoId}>
                                                    {" "}
                                                    {map.nome}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {riscos.length > 0 && (riscos.filter(e => e.riscoId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRiscoReadDto?.riscoId)[0]?.nome || "Não Informado")}
                                    {renderProbalidadeMultaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaRiscoReadDto.riscoId")}
                                </label>}
                        </div>}
                </div>
            </>
        )
    }

    const renderTotal = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Totais</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.valor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valor))}
                            </label>
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.provavelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.provavelValor))}
                            </label>
                        </p>

                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.possivelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.possivelValor))}
                            </label>
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.remotoValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.remotoValor))}
                            </label>
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const renderMultaIsolada = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Isolada</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaValorExibir ?
                                <InputGroup>
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaIsoladaValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaValor)}
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaProvavelValorExibir ?
                                <InputGroup>
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvavelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvavelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaIsoladaProvavelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaProvavelValor)}
                                    {/* {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvavelValor")} */}
                                </label>}
                        </p>

                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaPossivelValorExibir ?
                                <InputGroup>
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaPossivelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaPossivelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaIsoladaPossivelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaPossivelValor)}
                                    {/* {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaPossivelValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRemotoValorExibir ?
                                <InputGroup>
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRemotoValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRemotoValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaIsoladaRemotoValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRemotoValor)}
                                    {/* {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRemotoValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }} />
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaIndiceIdExibir ?
                            <InputGroup>
                                {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaIndiceId")}
                                <select
                                    value={generic}
                                    onChange={(e) => {
                                        setGeneric(e.target.value);
                                        formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaIsoladaIndiceId = parseInt(e.target.value);
                                    }}
                                    className={"form-control"}
                                    id="form-indice"
                                >
                                    <option value="0"> Selecione um Índice </option>
                                    {indices.map((map) => {
                                        return (
                                            <option key={map.indiceId} value={map.indiceId}>
                                                {" "}
                                                {map.nome}{" "}
                                            </option>
                                        );
                                    })}
                                </select>
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {indices.length > 0 && (indices.filter(e => e.indiceId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaIndiceId)[0]?.nome || "Não Informado")}
                                {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaIndiceId")}
                            </label>}
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaJurosDataBaseExibir ?
                            <InputGroup>
                                {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaIsoladaJurosDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosDataBase = date} />
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaIsoladaJurosDataBase).format("DD/MM/YYYY")}
                                {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosDataBase")}
                            </label>
                        }
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaJurosValorExibir ?
                                <InputGroup>
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosValor")}
                                    <CurrencyField
                                        onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosValor")}
                                        floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaIsoladaJurosValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyFormatterNotRS(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaIsoladaJurosValor)}
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaCorrecaoDataBaseExibir ?
                            <InputGroup>
                                {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaCorrecaoDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaCorrecaoDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaIsoladaCorrecaoDataBase = date} />
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaIsoladaCorrecaoDataBase).format("DD/MM/YYYY")}
                                {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaCorrecaoDataBase")}
                            </label>
                        }
                    </div>
                    {formik.values[index]?.valoresFaseReadDto?.tipoRisco == 2 &&
                        <div style={{ width: '200px' }}>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRiscoIdExibir ?
                                <InputGroup>
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRiscoId")}
                                    <select
                                        value={generic}
                                        onChange={(e) => {
                                            setGeneric(e.target.value);
                                            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRiscoId = parseInt(e.target.value);
                                        }}
                                        className={"form-control"}
                                        id="form-risco"
                                    >
                                        <option value="0"> Selecione um Risco </option>
                                        {riscos.map((map) => {
                                            return (
                                                <option key={map.riscoId} value={map.riscoId}>
                                                    {" "}
                                                    {map.nome}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {riscos.length > 0 && (riscos.filter(e => e.riscoId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRiscoId)[0]?.nome || "Não Informado")}
                                    {renderMultaIsoladaIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRiscoId")}
                                </label>}
                        </div>}
                </div>
            </>
        )
    }

    const renderMultaOficio = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Oficio</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioValorExibir ?
                                <InputGroup>
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaOficioValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioValor)}
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioProvavelValorExibir ?
                                <InputGroup>
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioProvavelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioProvavelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaOficioProvavelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioProvavelValor)}
                                    {/* {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioProvavelValor")} */}
                                </label>}
                        </p>

                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioPossivelValorExibir ?
                                <InputGroup>
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioPossivelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioPossivelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaOficioPossivelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioPossivelValor)}
                                    {/* {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioPossivelValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRemotoValorExibir ?
                                <InputGroup>
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioRemotoValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioRemotoValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaOficioRemotoValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRemotoValor)}
                                    {/* {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioRemotoValor")} */}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }} />
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioIndiceIdExibir ?
                            <InputGroup>
                                {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioIndiceId")}
                                <select
                                    value={generic}
                                    onChange={(e) => {
                                        setGeneric(e.target.value);
                                        formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaOficioIndiceId = parseInt(e.target.value);
                                    }}
                                    className={"form-control"}
                                    id="form-indice"
                                >
                                    <option value="0"> Selecione um Índice </option>
                                    {indices.map((map) => {
                                        return (
                                            <option key={map.indiceId} value={map.indiceId}>
                                                {" "}
                                                {map.nome}{" "}
                                            </option>
                                        );
                                    })}
                                </select>
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {indices.length > 0 && (indices.filter(e => e.indiceId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioIndiceId)[0]?.nome || "Não Informado")}
                                {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioIndiceId")}
                            </label>}
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioJurosDataBaseExibir ?
                            <InputGroup>
                                {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaOficioJurosDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosDataBase = date} />
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaOficioJurosDataBase).format("DD/MM/YYYY")}
                                {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosDataBase")}
                            </label>
                        }
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioJurosValorExibir ?
                                <InputGroup>
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosValor")}
                                    <CurrencyField
                                        onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosValor")}
                                        floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaOficioJurosValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyFormatterNotRS(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaOficioJurosValor)}
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioCorrecaoDataBaseExibir ?
                            <InputGroup>
                                {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioCorrecaoDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioCorrecaoDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaOficioCorrecaoDataBase = date} />
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaOficioCorrecaoDataBase).format("DD/MM/YYYY")}
                                {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioCorrecaoDataBase")}
                            </label>
                        }
                    </div>
                    {formik.values[index]?.valoresFaseReadDto?.tipoRisco == 2 &&
                        <div style={{ width: '200px' }}>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRiscoIdExibir ?
                                <InputGroup>
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioRiscoId")}
                                    <select
                                        value={generic}
                                        onChange={(e) => {
                                            setGeneric(e.target.value);
                                            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaOficioRiscoId = parseInt(e.target.value);
                                        }}
                                        className={"form-control"}
                                        id="form-risco"
                                    >
                                        <option value="0"> Selecione um Risco </option>
                                        {riscos.map((map) => {
                                            return (
                                                <option key={map.riscoId} value={map.riscoId}>
                                                    {" "}
                                                    {map.nome}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {riscos.length > 0 && (riscos.filter(e => e.riscoId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRiscoId)[0]?.nome || "Não Informado")}
                                    {renderMultaOficioIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaOficioRiscoId")}
                                </label>}
                        </div>}
                </div>
            </>
        )
    }

    const renderMultaMora = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Mora</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraValorExibir ?
                                <InputGroup>
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaMoraValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraValor)}
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraProvavelValorExibir ?
                                <InputGroup>
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraProvavelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraProvavelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaMoraProvavelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraProvavelValor)}
                                </label>}
                        </p>

                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraPossivelValorExibir ?
                                <InputGroup>
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraPossivelValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraPossivelValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaMoraPossivelValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraPossivelValor)}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRemotoValorExibir ?
                                <InputGroup>
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraRemotoValor")}
                                    <CurrencyField onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraRemotoValor")} floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaMoraRemotoValor} />
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyLabelFormatterNotRS(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRemotoValor)}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }} />
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraIndiceIdExibir ?
                            <InputGroup>
                                {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraIndiceId")}
                                <select
                                    value={generic}
                                    onChange={(e) => {
                                        setGeneric(e.target.value);
                                        formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaMoraIndiceId = parseInt(e.target.value);
                                    }}
                                    className={"form-control"}
                                    id="form-indice"
                                >
                                    <option value="0"> Selecione um Índice </option>
                                    {indices.map((map) => {
                                        return (
                                            <option key={map.indiceId} value={map.indiceId}>
                                                {" "}
                                                {map.nome}{" "}
                                            </option>
                                        );
                                    })}
                                </select>
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {indices.length > 0 && (indices.filter(e => e.indiceId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraIndiceId)[0]?.nome || "Não Informado")}
                                {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraIndiceId")}
                            </label>}
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraJurosDataBaseExibir ?
                            <InputGroup>
                                {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaMoraJurosDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosDataBase = date} />
                            </InputGroup>

                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaMoraJurosDataBase).format("DD/MM/YYYY")}
                                {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosDataBase")}
                            </label>
                        }
                    </div>
                    <div style={{ width: '200px' }}>
                        <p>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraJurosValorExibir ?
                                <InputGroup>
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosValor")}
                                    <CurrencyField
                                        onValueChange={(v) => onValueChange(v, index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosValor")}
                                        floatValue={formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto.multaMoraJurosValor} />
                                </InputGroup>

                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {currencyFormatterNotRS(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaMoraJurosValor)}
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosValor")}
                                </label>}
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>
                        {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraCorrecaoDataBaseExibir ?
                            <InputGroup>
                                {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraCorrecaoDataBase")}
                                <DatePicker
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/MM/yyyy"
                                    className={"form-control"}
                                    selected={new Date(formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraCorrecaoDataBase)}
                                    onChange={(date: Date) => formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaMoraCorrecaoDataBase = date} />
                            </InputGroup>
                            :
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {moment(formik.values[index]?.valoresFaseReadDto.valoresMultaReadDto?.multaMoraCorrecaoDataBase).format("DD/MM/YYYY")}
                                {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraCorrecaoDataBase")}
                            </label>
                        }
                    </div>
                    {formik.values[index]?.valoresFaseReadDto?.tipoRisco == 2 &&
                        <div style={{ width: '200px' }}>
                            {formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRiscoIdExibir ?
                                <InputGroup>
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraRiscoId")}
                                    <select
                                        value={generic}
                                        onChange={(e) => {
                                            setGeneric(e.target.value);
                                            formik.values[index].valoresFaseReadDto.valoresMultaReadDto.multaMoraRiscoId = parseInt(e.target.value);
                                        }}
                                        className={"form-control"}
                                        id="form-risco"
                                    >
                                        <option value="0"> Selecione um Risco </option>
                                        {riscos.map((map) => {
                                            return (
                                                <option key={map.riscoId} value={map.riscoId}>
                                                    {" "}
                                                    {map.nome}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {riscos.length > 0 && (riscos.filter(e => e.riscoId == formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRiscoId)[0]?.nome || "Não Informado")}
                                    {renderMultaMoraIconEdit(index, "valoresFaseReadDto.valoresMultaReadDto.multaMoraRiscoId")}
                                </label>}
                        </div>}
                </div>
            </>
        )
    }

    const renderTributarioTotal = (index: number) => {
        return (
            <>
                <div style={{ width: '2300px' }} className="row overflow-auto">
                    <div style={{ width: '215px' }}>
                        <label htmlFor="form-principal" className="form-label fw-bolder text-orange">
                            <h6>Totais</h6>
                        </label>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.valor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valor))}
                            </label>
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaProvavelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraProvavelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioProvavelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.provavelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.provavelValor))}
                            </label>
                        </p>

                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaPossivelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraPossivelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioPossivelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.possivelValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.possivelValor))}
                            </label>
                        </p>
                    </div>
                    <div style={{ width: '200px' }}>

                        <p>
                            <label style={{ width: "200px" }} className="fw-bolder">
                                {CurrencyFormatterLabel(
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRemotoValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRemotoValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRemotoValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.encargosHonorariosReadDto.remotoValor) +
                                    (formik.values[index]?.valoresFaseReadDto?.remotoValor))}
                            </label>
                        </p>
                    </div>
                </div><hr />
            </>
        )
    }

    return (<>
        <LayoutPageForButton title="Gerenciamento de Valores">
            <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>

                <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
                    <span className="navigation-service-icon navigation-service-icon-grid"></span>
                </a>

            </OverlayTrigger>
        </LayoutPageForButton>

        <LayoutPageTitle title="">
            <button
                onClick={() => {
                    setValores(undefined);
                    setExibirModal(true);
                }}
                className="btn btn-md btn-orange"
            >
                Novo
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
            </button>
        </LayoutPageTitle>

        <ValoresModal
            editarValores={valores}
            exibirModal={exibirModal}
            toggleModal={toggleModal}
            processoId={processo.processoId}
            titulo={"Valores"}
            areaDireitoId={processo.areaDireitoId}
            fase={filtrosPesquisa.Fase.toString()}
            setAtualizarTabela={setAtualizarTabela}
        />

        <AlterarFaseValoresModal
            exibirModalFase={exibirModalFase}
            toggleModalFase={toggleModalFase}
            processoId={processo.processoId}
            faseOrigem={filtrosPesquisa.Fase}
        />

        <div className="row mt-2">
            <div className="col-md-12" >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    carregarValores(filtrosPesquisa);

                }} className="row g-3 mb-3 form-buscar-valores">
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Selecione a Instância:</label>
                            <select value={filtrosPesquisa.Fase} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, Fase: parseInt(e.target.value) } });
                            }} placeholder="ValoresFaseId" className={'form-select'} id="form-select" >
                                <option value="-1" label="Selecione">Selecione</option>
                                <option value="0" label="Pedido">Pedido</option>
                                <option value="1" label="1º Instância">Instancia1</option>
                                <option value="2" label="2º Instância">Instancia2</option>
                                <option value="3" label="3º Instância">Instancia3</option>
                                <option value="5" label="Execução">Execucao</option>
                                <option value="6" label="Estimado">Estimado</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12"
                        style={{ textAlign: 'right' }}>
                        <button
                            onClick={() => carregarValores(filtrosPesquisa)}
                            className="btn btn-orange search-buttom-margin "
                            title="Buscar"
                        >
                            <FontAwesomeIcon color='white' className='' icon={faSearch} />
                        </button>

                        <button
                            onClick={() =>setExibirModalFase(true)}
                            className="btn btn-orange search-buttom-margin ms-2"
                            title="Cadastrar Próxima Fase"
                        >
                            <FontAwesomeIcon color='white' className='' icon={faFastForward} />
                        </button>

                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                limparFiltros();
                            }}
                            className="btn btn-orange search-buttom-margin ms-2"
                            title="Limpar Filtro"
                        >
                            <FontAwesomeIcon color='white' className='' icon={faEraser} />
                        </button>
                    </div>
                </form>
            </div >
            <div className="col-md-12 mb-10">
                {/* {formik.values[0]?.valoresFaseReadDto &&
                    <div className="row">
                        <div className="col-md-5">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Deseja trocar o tipo de risco?</label><br />
                            {formik.values[0]?.valoresFaseReadDto?.tipoRiscoExibir ?
                                <InputGroup>
                                    {renderTrocarTipoRisco()}
                                    <select value={formik.values[0]?.valoresFaseReadDto?.tipoRisco} onChange={(e) => {
                                        formik.values[0].valoresFaseReadDto.tipoRisco = parseInt(e.target.value);
                                        formik.setValues(formik.values);
                                    }
                                    } className={'form-select'} id="form-select" >
                                        <option value="-1" label="Selecione">Selecione</option>
                                        <option value="0" label="Percentual">Percentual</option>
                                        <option value="1" label="Detalhando">Detalhando</option>
                                        <option value="2" label="Unico">Unico</option>
                                    </select>
                                </InputGroup>
                                :
                                <label style={{ width: "200px" }} className="fw-bolder">
                                    {formik.values[0]?.valoresFaseReadDto?.tipoRisco == 0 && 'Percentual'}
                                    {formik.values[0]?.valoresFaseReadDto?.tipoRisco == 1 && 'Detalhando'}
                                    {formik.values[0]?.valoresFaseReadDto?.tipoRisco == 2 && 'Unico'}
                                    {renderTrocarTipoRisco()}
                                </label>}
                        </div>
                    </div>} */}

                <GridPadrao
                    onSort={handleSort}
                    progressPending={false}
                    limit={filtrosPesquisa.limit}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer={true}
                    paginationTotalRows={filtrosPesquisa.totalItems}
                    colunas={colunas}
                    tipo='Valores'
                    itens={formik.values} />
            </div>
        </div>
    </>)
}

export default GerenciarValoresPage;