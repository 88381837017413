import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";

import { MultiSelect } from "react-multi-select-component";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IBloqueio from "../../interfaces/IBloqueio";
import IProcesso from "../../interfaces/IProcesso";

import BloqueioService from "../../services/BloqueioService";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";
import FiltroPesquisaBloqueioRequest from "../../interfaces/Requests/Bloqueio/FiltroPesquisaBloqueioRequest";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import { EModulo } from "../../enum";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const BloqueioPage = () => {
  const [carregandoBloqueios, setCarregandoBloqueios] = useState(false);

  const [bloqueios, setBloqueios] = useState<IBloqueio[]>([]);

  const { processo, setProcesso, setAreaDireito, setBloqueio, bloqueio } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);

  const [StatusOptions, setStatusOptions] = useState<any>([{ label: "Bloqueado", value: 1 }, { label: "Desbloqueado", value: 2 }]);
  const [StatusSelected, setStatusSelected] = useState([]);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaBloqueioRequest>({
    bloqueioId: 0,
    clienteId: 0,
    modulo: EModulo.AreaDireito,
    processoId: processo.processoId,
    descricao: "",
    dataBloqueioMaiorIgual: "",
    dataBloqueioMenorIgual: "",
    dataDesbloqueioMaiorIgual: "",
    dataDesbloqueioMenorIgual: "",
    banco_id: 0,
    agencia: "",
    conta: "",
    moedaId: "",
    valorMaiorIgual: "",
    valorMenorIgual: "",
    garantiaId: 0,
    usuarioIdCadastro: 0,
    codigo: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    statusBloqueioNome: "",
    statusBloqueioId: [],
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "bloqueioId",
  });

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, statusBloqueioId: IdsSelected };
    });
  }, [StatusSelected]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);


  useEffect(() => {
    if (atualizarTabela) {
      setTimeout(() => {
        carregarBloqueios(filtrosPesquisa);
      }, 2000);
    }
  }, [atualizarTabela]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_bloqueio");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_bloqueio");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_bloqueio")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaBloqueioRequest = JSON.parse((await sessionStorageService.obter("filtro_bloqueio")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, processoId: filtro.processoId });
  };

  const carregarBloqueios = async (filtro: FiltroPesquisaBloqueioRequest): Promise<void> => {
    try {
      setCarregandoBloqueios(true);
      let resultado: IRecuperaLista<IBloqueio>;

      resultado = await BloqueioService.obterBloqueios(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setBloqueios(resultado.data);
      inserirSessionStorage();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setBloqueios([]);
    } finally {
      setCarregandoBloqueios(false);
      setAtualizaTabela(false);
    }
  }

  const colunas: TableColumn<IBloqueio>[] = [
    {
      name: "Id",
      sortField: "BloqueioId",
      selector: (row: IBloqueio) => row.bloqueioId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IBloqueio) => row.statusBloqueioNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data do Bloqueio",
      sortField: "dataBloqueio",
      selector: (row: IBloqueio) => {
        if (row.dataBloqueio === null) return "-";
        const data = row.dataBloqueio.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Data do Desbloqueio",
      sortField: "dataDesbloqueio",
      selector: (row: IBloqueio) => {
        if (row.dataDesbloqueio === null) return "-";
        const data = row.dataDesbloqueio.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor",
      sortField: "valor",
      selector: (row: IBloqueio) => {
        if (row.valor === null) return "R$ 0,00";

        const valor = row.valor || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IBloqueio) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Detalhes da Bloqueio"
              onClick={() => alterarEdicao(row)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];


  const alterarEdicao = (row: IBloqueio) => {
    setBloqueio({
      ...row,
      bloqueioId: row.bloqueioId,
      tipoBloqueioId: row.tipoBloqueioId,
      categoriaId: row.tipoBloqueioId,
      modulo: bloqueio.modulo,
      processoId: bloqueio.processoId,
      areaDireitoId: bloqueio.areaDireitoId,
    });
    navigate("/Bloqueio/Capa");
  };


  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IBloqueio>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        bloqueioId: 0,
        modulo: EModulo.AreaDireito,
        processoId: processo.processoId,
        descricao: "",
        dataBloqueioMaiorIgual: "",
        dataBloqueioMenorIgual: "",
        dataDesbloqueioMaiorIgual: "",
        dataDesbloqueioMenorIgual: "",
        banco_id: 0,
        agencia: "",
        conta: "",
        moedaId: "",
        valorMaiorIgual: "",
        valorMenorIgual: "",
        garantiaId: 0,
        usuarioIdCadastro: 0,
        codigo: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        statusBloqueioNome: "",
        statusBloqueioId: [],
      };
    });
  }

  return (
    <>
      <LayoutPageForButton title="Bloqueio">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setBloqueio({ ...bloqueio, bloqueioId: 0 });
            navigate("/Bloqueio/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <div className="col-md-12">

          <CollapseFiltro titulo="Filtros" content={
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarBloqueios(filtrosPesquisa);
              }}
              className="row g-3 mb-3 form-buscar-bloqueio"
            >
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                  Data do Cadastro:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                      ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue) => {
                        return {
                          ...oldValue,
                          dataCadastroMaiorIgual: "",
                          dataCadastroMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                  Data do Bloqueio:{" "}
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={
                    filtrosPesquisa.dataBloqueioMaiorIgual && filtrosPesquisa.dataBloqueioMenorIgual
                      ? [new Date(filtrosPesquisa.dataBloqueioMaiorIgual), new Date(filtrosPesquisa.dataBloqueioMenorIgual)]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas || datas.length === 0) {
                      setFiltrosPesquisa((oldValue) => ({
                        ...oldValue,
                        dataBloqueioMaiorIgual: "",
                        dataBloqueioMenorIgual: "",
                      }));
                      return;
                    }

                    const [data_inicial, data_final] = datas;

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataBloqueioMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataBloqueioMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    }));
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">
                  Data do Desbloqueio:
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  value={
                    filtrosPesquisa.dataDesbloqueioMaiorIgual && filtrosPesquisa.dataDesbloqueioMenorIgual
                      ? [new Date(filtrosPesquisa.dataDesbloqueioMaiorIgual), new Date(filtrosPesquisa.dataDesbloqueioMenorIgual)]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas || datas.length === 0) {
                      setFiltrosPesquisa((oldValue) => ({
                        ...oldValue,
                        dataDesbloqueioMaiorIgual: "",
                        dataDesbloqueioMenorIgual: "",
                      }));
                      return;
                    }

                    const [data_inicial, data_final] = datas;

                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataDesbloqueioMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataDesbloqueioMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    }));
                  }}
                />
              </div>


              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-descricao" className="form-label fw-bolder text-orange">
                  Descrição:
                </label>
                <input
                  value={filtrosPesquisa.descricao}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, descricao: e.target.value };
                    });
                  }}
                  placeholder="Descrição"
                  className={"form-control"}
                  id="form-descricao"
                />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-statusBloqueioId" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={false}
                  disabled={false}
                  options={StatusOptions}
                  value={StatusSelected}
                  onChange={(event: any) => setStatusSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
              </div>
              <div className="col-12 " style={{ textAlign: "right" }}>
                <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                  <FontAwesomeIcon color="white" className="" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-orange search-buttom-margin ms-2"
                  title="Limpar Filtro"
                >
                  <FontAwesomeIcon color="white" className="" icon={faEraser} />
                </button>
              </div>
            </form>} />
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoBloqueios}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Bloqueios"
            itens={bloqueios}
          />
        </div>
      </div>
    </>
  );
};

export default BloqueioPage;
