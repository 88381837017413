import { useQuery } from "react-query";
import EditorService, {
  DynamicProvider,
} from "../../../services/EditorService";
import { useProvider } from "./useProvider";
import { useValue } from "./useValue";
import { CustomEditor } from "../../../utils/types/slate";
import { toast } from "react-toastify";
import { usePublish } from "./usePublish";
import { blockHelper } from "../helpers/blockHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { useTenant } from "./useTenant";
import { handleApiError } from "../../../utils/Utils";
import { IEditorPaginationRequestParams } from "../../../interfaces/IEditorVersionResponse";

interface UseEditorVersionParams {
  provider: DynamicProvider;
  editor: CustomEditor;
  paginationParams?: IEditorPaginationRequestParams;
  removeOnlyHtml?: boolean;
}

export function useEditorVersion({
  provider,
  editor,
  paginationParams,
  removeOnlyHtml = false,
}: UseEditorVersionParams) {
  const { setVersion, setEditorLoading, setVersionData } = useProvider();
  const { populate, staticInsert } = useValue(editor);
  const { publish } = usePublish();
  const { caller } = useTenant();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading, error } = useQuery({
    queryKey: ["EDITOR_GET_VERSION_LIST", provider.provider.value, paginationParams],
    queryFn: () => EditorService.getVersionsV2(provider, paginationParams),
    refetchOnMount: "always",
    onError: (error) => {
      setEditorLoading(false);
      const errorMessage = handleApiError(error);
      toast.error(errorMessage, { position: "bottom-right" });
    },
    onSuccess: ({ data }) => {
      const lastVersion = data[0];

      const useNewEditor = !lastVersion.jsonContent || lastVersion.jsonContent.sfdt;

      if (useNewEditor) {
        const previousLocation = location.state?.from || `/${caller}/Capa`;
        navigate(`/${caller}/NovoEditor`, {
          replace: true,
          state: { from: previousLocation },
        });
        return;
      }

      const currentVersion = lastVersion?.jsonContent.payload;

      if (!currentVersion?.docId) {
        setEditorLoading(false);
        toast.error("Erro ao carregar documento, versão inválida", {
          position: "bottom-right",
        });
        return;
      }

      setVersion(currentVersion);
      setVersionData(lastVersion);

      if (lastVersion.paginado) {
        setEditorLoading(false);
        staticInsert(currentVersion);
        return;
      }

      populate({
        ...currentVersion,
        editor,
        pageNumber: 0,
        removeOnlyHtml,
        onFinish: () => {
          setEditorLoading(false);
          blockHelper.replacePageCount(editor);
          publish.action(editor, currentVersion).then();
        },
      });
    },
  });

  const versions = data?.data ?? [];

  function getVersion() {
    if (versions.length) {
      return versions[0].jsonContent.payload;
    }
    return null;
  }

  return {
    lastVersion: getVersion(),
    versions,
    loading: isLoading,
    error,
  };
}
