import { useState, useContext, useEffect } from "react";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";

import { EModulo } from "../../enum";

import IContrato from "../../interfaces/IContrato";
import IProcesso from "../../interfaces/IProcesso";
import IConsulta from "../../interfaces/IConsulta";
import ICompromisso from "../../interfaces/ICompromisso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaFeedRequest from "../../interfaces/Requests/Feed/FiltroPesquisaFeedRequest";

import { OrangeContext } from "../../contexts/OrangeProvider";

import ImovelService from "../../services/ImovelService";
import LicencaService from "../../services/LicencaService";
import ProcessoService from "../../services/ProcessoService";
import ConsultaService from "../../services/ConsultaService";
import ContratoService from "../../services/ContratoService";
import ProcuracaoService from "../../services/ProcuracaoService";
import CompromissoService from "../../services/CompromissoService";

import Carregando from "../../components/Carregando";
import FluxoModal from "../../components/Fluxo/Modals/FluxoModal";

const ConfirmarFeedPage = () => {

  const navigate = useNavigate();

  const [refreshFeed, setRefreshFeed] = useState<boolean>(false);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoCompromissos, setCarregandoCompromissos] = useState(false);

  const { setProcesso, setContrato, setImovel, setProcuracao, setLicenca, setConsulta } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [filtrosPesquisa] = useState<FiltroPesquisaFeedRequest>({
    compromissoId: 0,
    modeloRelatorioId: 0,
    modulo: EModulo.Default,
    escritorioId: [],
    grupoUsuarioId: [],
    usuarioId: [],
    categoria: 0,
    tipoCompromissoId: [],
    moduloId: [],
    tipoCategoriaCompromissoId: [],
    processoId: 0,
    clienteId: 0,
    dataCompromissoMaiorIgual: "",
    dataCompromissoMenorIgual: "",
    observacaoCompromisso: "",
    usuarioIdCadastro: 0,
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    observacaoConclusao: "",
    usuarioIdConclusao: 0,
    dataConclusaoMaiorIgual: "",
    dataConclusaoMenorIgual: "",
    usuariosAprovadores: [],
    status: [],
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: 'compromissoId'
  });

  const [compromisso, setCompromisso] = useState<ICompromisso>({
    modulo: EModulo.Default,
    tipoCompromissoNome: "",

    compromissoId: 0,
    fluxoFaseId: 0,

    processoId: 0,
    codigo: "",
    dataConclusao: new Date(),
    dataCompromisso: new Date(),
    observacaoCompromisso: "",
    observacaoConclusao: "",
    tipoCompromissoId: 0,
    usuariosAprovadoresList: [],
    horario: "",
    status: "",
    grupoId: []
  });

  const toggleExibir = () => setExibirModal(!exibirModal);

  useEffect(() => {

    const match = window.location.href.match(/\/(\d+)$/);

    const compromissoId = match ? match[1] : null;

    carregarCompromissos({ ...filtrosPesquisa, compromissoId });

  }, [refreshFeed]);


  const carregarCompromissos = async (filtro: any) => {
    try {

      setCarregandoCompromissos(true);

      let resultado: IRecuperaLista<ICompromisso>;

      resultado = await CompromissoService.obter(filtro);

      if (resultado.totalRegistros > 0) {
        setCompromisso(resultado.data[0]);
      } else {
        navigate("/Feed");
      }
      setCarregandoCompromissos(false);

    } catch (error: any) {
      setCarregandoCompromissos(false);
    }
  };


  const Capa = () => {
    switch (compromisso?.modulo) {

      case EModulo.AreaDireito:
      case EModulo.Comunicacao:
        if (compromisso?.processoId) iniciarProcesso(compromisso?.processoId);
        break

      case EModulo.Contrato:
        if (compromisso?.contratoId) iniciarContrato(compromisso.contratoId);
        break;

      case EModulo.Procuracao:
        if (compromisso?.procuracaoId) iniciarProcuracao(compromisso.procuracaoId);
        break;

      case EModulo.Imovel:
        if (compromisso?.imovelId) iniciarImovel(compromisso.imovelId);
        break;

      case EModulo.Regulatorio:
        if (compromisso?.licencaId) iniciarLicenca(compromisso.licencaId);
        break;

      case EModulo.Consultivo:
        if (compromisso?.consultaId) iniciarConsulta(compromisso.consultaId);
        break;

    }

  }

  const iniciarProcuracao = async (procuracaoId: number) => {
    try {

      let resultado: IRecuperaLista<IProcuracao>;

      resultado = await ProcuracaoService.obterProcuracaoPorId(procuracaoId);

      setProcuracao(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Procuracao/Capa");
    }
  }

  const iniciarLicenca = async (licencaId: number) => {
    try {
      let resultado: IRecuperaLista<ILicenca>;
      resultado = await LicencaService.obterLicencaPorId(licencaId);
      setLicenca(resultado.data[0]);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Licenca/Capa");
    }
  }

  const iniciarConsulta = async (consultaId: number) => {
    try {
      let resultado: IRecuperaLista<IConsulta>;
      resultado = await ConsultaService.obterConsultaPorId(consultaId);
      setConsulta(resultado.data[0]);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Consulta/Capa");
    }
  }

  const iniciarProcesso = async (processoId: number) => {
    try {

      let resultado: IRecuperaLista<IProcesso>;

      resultado = await ProcessoService.obterProcessoPorId(processoId);

      setProcesso(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Processo/Capa");
    }
  };

  const iniciarContrato = async (contratoId: number) => {
    try {

      let resultado: IRecuperaLista<IContrato>;

      resultado = await ContratoService.obterContratoPorId(contratoId);

      setContrato(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Contrato/Capa");
    }
  }

  const iniciarImovel = async (imovelId: number) => {
    try {

      let resultado: IRecuperaLista<IImovel>;
      resultado = await ImovelService.obterImovelPorId(imovelId);
      setImovel(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Imovel/Capa");
    }
  };

  const isValidCapa = (compromisso: any) => {

    let isValid = true;

    if (compromisso.modulo == EModulo.Obrigacao) isValid = false;
    if (compromisso.modulo == EModulo.Despesa) isValid = false;
    if (compromisso.modulo == EModulo.Bloqueio) isValid = false;
    if (compromisso.modulo == EModulo.Garantia) isValid = false;
    if (compromisso.modulo == EModulo.Compromisso) isValid = false;

    return isValid;
  }

  return (
    <>

      <FluxoModal
        exibirModal={exibirModal}
        setExibirModal={toggleExibir}
        compromisso={compromisso}
        setRefresh={setRefreshFeed}
      />

      {carregandoCompromissos ? <Carregando /> : <>

        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{'Confirmar Compromisso'}  <FontAwesomeIcon
              className="mx-2 text-orange"
              icon={faCalendarCheck}
            /></h5>
          </div>

          <div className="modal-body">

            <div className="row g-3">

              <div className="col-md-4 mb-3">
                <label htmlFor="form-nome" className="form-label  fw-bolder text-orange">Horário: </label>
                <p>  {moment(compromisso?.dataCompromisso).format('DD/MM/YYYY HH:mm')}</p>
              </div>


              <div className="col-md-4 mb-3">
                <label htmlFor="form-nome" className="form-label  fw-bolder text-orange">Tipo: </label>
                <p>  {compromisso?.tipoCompromissoNome}</p>
              </div>


              <div className="col-md-4 mb-3">
                <label htmlFor="form-nome" className="form-label  fw-bolder text-orange">Usuário: </label>
                <p>   {compromisso?.usuarioNomeCadastro}</p>
              </div>

            </div>

            <div className="row g-3">

              <div className="col-md-4 mb-3">
                <label htmlFor="form-nome" className="form-label  fw-bolder text-orange">Módulo: </label>
                <p>  {compromisso?.moduloNome}</p>
              </div>


              <div className="col-md-4 mb-3">
                <label htmlFor="form-nome" className="form-label  fw-bolder text-orange">Status: </label>
                <p> {compromisso?.status}</p>
              </div>


              <div className="col-md-4 mb-3">
                <label htmlFor="form-nome" className="form-label  fw-bolder text-orange text-orange">Identificador:</label>
                <p> {compromisso?.tipoCompromissoNome}</p>
              </div>

            </div>

            <div className="modal-footer" style={{ margin: '0 auto' }}>

              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                onClick={() => toggleExibir()}
                className="d-inline-flex align-items-center"
              >
                <span title="Confirmar" className="navigation-service-icon navigation-service-icon-checkin"></span>
              </Button>

              {isValidCapa(compromisso) &&
                <Button
                  variant="light"
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  onClick={Capa}
                  className="d-inline-flex align-items-center"
                >
                  <span title="Capa" className="navigation-service-icon navigation-service-icon-front"></span>
                </Button>
              }
            </div>

          </div>
        </div>



      </>}


    </>
  )

}

export default ConfirmarFeedPage;