import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MigrationFieldsType } from "./DragDropTableMigration";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import FieldMigration from "./FieldMigration";
import { Droppable } from "react-beautiful-dnd";
import { useEffect, useRef, useState } from "react";

type ColumnProps = {
  column: {
    id: string;
    title: string;
    fieldIds: string[];
  };
  fields: MigrationFieldsType[];
};

const ColumnMigration = ({ column, fields }: ColumnProps) => {
  const [height, setHeight] = useState(300);
  const columnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (columnRef.current) {
      const parentElement = columnRef.current.parentElement;
      if (parentElement) {
        const siblings = parentElement.children;
        let maxHeight = 0;

        Array.from(siblings).forEach((sibling) => {
          if (sibling !== columnRef.current) {
            const height = sibling.clientHeight;
            console.log(height);
            if (height > maxHeight) {
              maxHeight = height;
            }
          }
        });

        setHeight(maxHeight);
      }
    }
  }, [columnRef]);

  return (
    <div style={{ width: "21vw" }} ref={columnRef}>
      <div>
        <div className="modal-header">
          <h6 className="modal-title text-orange">
            <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />
            {column.title}
          </h6>
        </div>
      </div>

      <Droppable droppableId={column.id}>
        {(provided) => (
          <div
            className="border px-3 pb-3 pt-2"
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ minHeight: height }}
          >
            {fields.map((field, index) => {
              return (
                <FieldMigration
                  key={field.requestName}
                  field={field}
                  index={index}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default ColumnMigration;
