import { DocumentStyle } from "../../../../types";

export const documentStyles = {
  comparation: {
    page: {
      width: "90%",
      height: "841.8897637795277PT",
    },
    header: {
      margin: "36PT",
    },
    content: {
      marginTop: "70.86614173228347PT",
      marginBottom: "70.86614173228347PT",
      marginLeft: "70.86614173228347PT",
      marginRight: "70.86614173228347PT",
    },
    footer: {
      margin: "36PT",
    },
  } as DocumentStyle,

  default: {
    page: {
      width: "595.2755905511812PT",
      height: "841.8897637795277PT",
    },
    header: {
      margin: "36PT",
    },
    content: {
      marginTop: "70.86614173228347PT",
      marginBottom: "70.86614173228347PT",
      marginLeft: "70.86614173228347PT",
      marginRight: "70.86614173228347PT",
    },
    footer: {
      margin: "36PT",
    },
  } as DocumentStyle
}