import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";

import ITipoProcuracao from "../../interfaces/ITipoProcuracao";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import IAdicionaProcuracaoRequest from "../../interfaces/Requests/Procuracao/IAdicionaProcuracaoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ProcuracaoService from "../../services/ProcuracaoService";
import TipoProcuracaoService from "../../services/TipoProcuracaoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import { procuracaoInitialValues, tipoProcuracaoInicialValues } from "../../contexts/InitialValuesContext";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";

const ProcuracaoCadastroPage = () => {
  const { procuracao, setProcuracao, setTipoProcuracao, tipoProcuracao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoTiposProcuracao, setCarregandoTiposProcuracao] = useState<boolean>(false);
  const [tiposProcuracao, setTiposProcuracao] = useState<ITipoProcuracao[]>([]);
  const [exibirTipoProcuracao, setExibirTipoProcuracao] = useState<boolean>(false);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [adicionarProcuracaoLoading, setAdicionarProcuracaoLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [defineIsNovaPasta, setDefineIsNovaPasta] = useState<boolean>(false);

  const navigate = useNavigate();


  useEffect(() => {

    setProcuracao(procuracaoInitialValues);

    setTipoProcuracao(tipoProcuracaoInicialValues);

    setConfiguracoesTela([]);

  }, []);

  useEffect(() => {
    if (tipoProcuracao.tipoProcuracaoId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoProcuracao(tipoProcuracaoInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoProcuracao.tipoProcuracaoId]);

  const initialValues: IAdicionaProcuracaoRequest = {
    procuracaoId: 0,
    tipoProcuracaoId: 0,
    modulo: EModulo.Default,
    tela: ETelaEnum.Default,
    categoriaId: 0,
    pastaid: 0,
    cidadeId: 0,
    departamentoId: 0,
    tipoinstrumentoId: 0,
    gestorId: 0,

    restrito: false,
    isnovapasta: defineIsNovaPasta,

    codigo: "",
    nomepasta: "",
    observacoesprocuracao: "",
    poderesprocuracao: "",
    nomesolicitante: "",
    emailsolicitante: "",

    datainiciovigencia: null,
    datafimvigencia: null,
    dataaprovacao: null,
    dataassinatura: null,

    advogadoInternoList: [],
    empresasList: [],
    centrosCusto: [],
    partesContrariasList: [],
    xCampoValorList: [],
    
    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,  
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Procuração",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          setAdicionarProcuracaoLoading(true);
          let resultado = await ProcuracaoService.adicionaProcuracao({
            ...values,
            tipoProcuracaoId: tipoProcuracao.tipoProcuracaoId,
            isnovapasta: defineIsNovaPasta,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });

          setProcuracao(resultado);

          setTipoProcuracao(tipoProcuracao);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Procuração cadastrada com sucesso`,
            timer: 4000,
          });
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setAdicionarProcuracaoLoading(false);
      }
    },
  });


  const carregarTipoProcuracao = async (novaPasta: boolean) => {
    try {
      setExibirTipoProcuracao(true);
      setCarregandoTiposProcuracao(true);
      if (novaPasta) {
        let resultado = await TipoProcuracaoService.obterTipoProcuracoes({
          nome: "",
          codigo: "",
          status: 1,
          categoriaNot: 1,
          limit: 100,
          totalItems: 0,
          offset: 0,
          sort: "nome",
        });
        setTiposProcuracao(resultado.data);
        setCarregandoTiposProcuracao(false);
        setDefineIsNovaPasta(false);
      } else {
        let resultado = await TipoProcuracaoService.obterTipoProcuracoes({
          nome: "",
          codigo: "",
          status: 1,
          categoria: 1,
          limit: 100,
          totalItems: 0,
          offset: 0,
          sort: "nome",
        });
        setTiposProcuracao(resultado.data);
        setCarregandoTiposProcuracao(false);
        setDefineIsNovaPasta(true);
      }
    } catch (error: any) {
      setCarregandoTiposProcuracao(false);
      setTiposProcuracao([]);
    }
  };

  const carregarConfiguracaoTela = async () => {
    setCarregandoConfiguracoesTela(true);
    let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
      TipoProcuracaoId: tipoProcuracao.tipoProcuracaoId,
      Modulo: EModulo.Procuracao,
      Tela: ETelaEnum.TelaCadastroProcuracao,
    });
    setConfiguracoesTela(resultado.data);
    setCarregandoConfiguracoesTela(false);
  };

  const onChangeTipoProcuracao = (value: number, texto: string) => {
    setConfiguracoesTela([]);
    formik.resetForm();
    formik.setFieldValue("tipoProcuracaoId", value);
    formik.setFieldValue("categoriaId", value);
    formik.setFieldValue("modulo", EModulo.Procuracao);
    formik.setFieldValue("tela", ETelaEnum.TelaCadastroProcuracao);
    setTipoProcuracao({
      tipoProcuracaoId: value,
      nome: texto,
      codigo: "",
      status: "",
    });
  };
  const renderCampos = () => {
    
    let component: any[] = [];
 
    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));
 
    return <div className="row mt-12">{component}</div>;
  };


  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Nova Procuração ${tipoProcuracao.tipoProcuracaoId > 0 ? " - " + tipoProcuracao.nome : ""}`}>
        {procuracao.procuracaoId > 0 ? (
          <>
            <button onClick={() => navigate("/Procuracao/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{procuracao.procuracaoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }
              }
              style={{ display: !formik.values.tipoProcuracaoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarProcuracaoLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarProcuracaoLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarProcuracaoLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">
        <div className="form-label fw-bolder text-orange">
          {<> Deseja vincular esta solicitação à uma pasta de procuração já existente? </>}
          <></>
          <div key={`inline-radio`} className="fw-bolder text-orange">
            <Form.Check inline className="form-radio" onClick={() => carregarTipoProcuracao(true)} label="Sim" name="group1" type="radio" />
            <Form.Check inline className="form-radio" onClick={() => carregarTipoProcuracao(false)} label="Não" name="group1" type="radio" />
          </div>
        </div>
        <>{exibirTipoProcuracao && <>
          {carregandoTiposProcuracao ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              Carregando tipo de procuração...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <div className="col-md-3 mt-3">
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                Tipo de Procuração
              </label>
              <select
                name="tipoProcuracaoId"
                value={tipoProcuracao.tipoProcuracaoId}
                className={"form-select"}
                onChange={(event: any) => onChangeTipoProcuracao(event.target.value, event.target[event.target.selectedIndex].text)}
                placeholder="Nome"
                id="form-area"
              >
                <option value="0"> Selecione um tipo de procuração</option>
                {tiposProcuracao.map((tipoProcuracao) => {
                  return (
                    <option key={tipoProcuracao.tipoProcuracaoId} value={tipoProcuracao.tipoProcuracaoId}>
                      {tipoProcuracao.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </>
        }
        </>
      </div>

      <hr></hr>

      {
        formik.values.tipoProcuracaoId > 0 && (
          <>
            {renderCampos()}
          </>
        )
      }
    </>
  );
}

export default ProcuracaoCadastroPage;
