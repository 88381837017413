import { useFormik } from 'formik';
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IAlterarFaseValores from '../../interfaces/IAlterarFaseValores';
import { AlterarFaseValoresRequest } from '../../interfaces/Requests/Valores/ValoresProcesso/AlterarFaseValoresRequest';
import ValoresService from '../../services/ValoresService';

interface IAlterarFaseValoresModalProps {
    toggleModalFase: (carregarDados?: boolean) => void,
    exibirModalFase: boolean,
    alterarFaseValores?: IAlterarFaseValores,
    processoId: number,
    faseOrigem: number
}

const AlterarFaseValoresModal = ({ toggleModalFase, exibirModalFase, alterarFaseValores, processoId, faseOrigem }: IAlterarFaseValoresModalProps) => {

    useEffect(() => {
        async function montarForm() {
            await formik.resetForm();

            if (alterarFaseValores) {
                await formik.setValues({ ...alterarFaseValores });
            }

            await formik.validateForm();
        }

        montarForm();

        // eslint-disable-next-line
    }, [exibirModalFase, alterarFaseValores]);

    async function cancelar() {
        formik.resetForm();
    }

    const valoresIniciais: AlterarFaseValoresRequest = {
        processoId: processoId,
        faseAtual: 0,
        faseAnterior: 0
    } as IAlterarFaseValores;

    const formik = useFormik({
        initialValues: valoresIniciais,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                values.faseAnterior = faseOrigem;
                await ValoresService.alterarFaseValores(values);
                await Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Fase Alterada com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                });

                setSubmitting(false);

            } catch (error: any) {
                let mensagemErro = 'Ocorreu um erro inesperado';

                if (error?.response?.data?.Message) {
                    mensagemErro = error.response.data.Message
                }

                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel editar a Fase`,
                    text: mensagemErro,
                    showConfirmButton: true
                });
            }
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModalFase} onHide={toggleModalFase}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{"Cadastrar Proxima Fase"}</h5>
                    <div onClick={() => { cancelar(); toggleModalFase(); }} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="col-md-3">  
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Selecione a Próxima Instância</label>
                            <select value={formik.values.faseAtual} onChange={(e) => {
                                formik.setFieldTouched("faseAtual", true);
                                formik.setFieldValue("faseAtual", parseInt(e.target.value));
                            }} placeholder="faseAtual" className={'form-select'} id="form-select" >
                                <option value="0" label="Pedido">Pedido</option>
                                <option value="1" label="1º Instância">Instancia1</option>
                                <option value="2" label="2º Instância">Instancia2</option>
                                <option value="3" label="3º Instância">Instancia3</option>
                                <option value="5" label="Execução">Execucao</option>
                                <option value="6" label="Estimado">Estimado</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formik.isSubmitting && <> SALVAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button
                        onClick={() => {
                            cancelar();
                            toggleModalFase();
                        }}
                        type="button"
                        className="btn btn-danger ms-5"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default AlterarFaseValoresModal;