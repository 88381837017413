import { CustomEditor } from "../../../utils/types/slate";
import { EditorHandler, StaticHandler, Version } from "../types";
import { pageHelper } from "../helpers/pageHelper";

export function useValue(editor: CustomEditor) {
  function populate(data: EditorHandler) {
    pageHelper.populate(data);
  }

  function staticInsert(version: Version) {
    const editorHandler: StaticHandler = { ...version, editor };
    pageHelper.staticInsert(editorHandler)
  }

  function clear() {
    return pageHelper.clear(editor);
  }

  return {
    populate,
    staticInsert,
    clear,
    initialValue: () => {
      return [pageHelper.emptyPage()];
    },
  };
}
