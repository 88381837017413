import { useContext, useEffect, useState } from "react";
import { faEraser, faPen, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as Yup from "yup";


import FluxoService from "../../../services/FluxoService";

import IFluxo from "../../../interfaces/IFluxo";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaFluxoRequest from "../../../interfaces/Requests/Fluxo/FiltroPesquisaFluxoRequest";

import GridPadrao from "../../../components/Comum/GridPadrao";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import "../Styles/icons.css";
import clsx from "clsx";

const TipoFluxoPesquisaPage = () => {

  const navigate = useNavigate();

  const { setFluxoFase } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModal, setExibirModal] = useState<boolean>(false);

  const [fluxos, setFluxos] = useState<IFluxo[]>([]);
  const [carregandoFluxo, setCarregandoFluxo] = useState(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaFluxoRequest>({
    nome: "",
    fluxoId: 0,
    codigo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "fluxoId",
    modulo: "",
  });

  const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa({ ...filtrosPesquisa, limit: currentRowsPerPage });

  const handlePageChange = (page: number) => setFiltrosPesquisa({ ...filtrosPesquisa, offset: (page - 1) * filtrosPesquisa.limit });

  const handleSort = async (column: TableColumn<IFluxo>, sortDirection: string) => setFiltrosPesquisa({ ...filtrosPesquisa, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` });

  const toggleModal = () => setExibirModal(!exibirModal);

  const initialValues: IFluxo = {
    fluxoId: 0,
    modulo: 0,
    fluxoFaseId: 0,

    nome: "",
    codigo: "",
    descricao: "",

    jsonContent: '{}',
    faseInicial: false,

    fases: []
  } as IFluxo



  useEffect(() => {
    carregarFluxo(filtrosPesquisa);
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  const carregarFluxo = async (filtro: FiltroPesquisaFluxoRequest) => {
    try {

      setCarregandoFluxo(true);

      let resultado: IRecuperaLista<IFluxo>;

      resultado = await FluxoService.obterFluxos(filtro);
      setFiltrosPesquisa({ ...filtrosPesquisa, totalItems: resultado.totalRegistros });
      setFluxos(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setFluxos([]);
    } finally {
      setCarregandoFluxo(false);
    }
  }

  const colunas: TableColumn<IFluxo>[] = [
    {
      name: "Id",
      sortField: "Id",
      selector: (row: IFluxo) => row.fluxoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IFluxo) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      cell: (row: IFluxo) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: '',
      cell: (fluxo: IFluxo) => {
        return (
          <div>
            <FontAwesomeIcon title="Editar" onClick={() => {
              formik.setValues(fluxo);
              setExibirModal(true);
            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />

          </div>
        )
      },
      ignoreRowClick: true,
    }
  ];

  const onRowClicked = async (row: IFluxo) => {

    try {

      setCarregandoFluxo(true);

      let resultado = await FluxoService.obterFasesPorId(row.fluxoId);

      setFluxoFase({
        ...row,
        fluxoFaseId: 0,
        fases: resultado.fases,
        modulo: resultado.moduloId,
        jsonContent: resultado.jsonContent,
      });

      navigate("/Fluxo/FluxoFase");

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoFluxo(false);
    }



  };

  const limparFiltros = () =>
    setFiltrosPesquisa({
      ...filtrosPesquisa,
      nome: "",
      modulo: "",
      status: 1,
    });

  const validacao = () => {
    let schema: any = {};
    schema.modulo = Yup.number().min(1, "Selecione o campo").required("Campo é obrigatório");
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validacao,
    onSubmit: async (values, { setSubmitting }) => {

      try {

        FluxoService.adicionaFluxo(values);

        await Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Fluxo cadastrado com sucesso`,
          showConfirmButton: false,
          timer: 3000
        });

        toggleModal();

      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          title: `Não foi possivel criar`,
          text: error?.response?.data?.Message,
          showConfirmButton: true
        });
      } finally {
        setSubmitting(false);
        formik.setValues(initialValues);
      }
    }
  });


  return (
    <>
      <LayoutPageTitle title="Selecione o fluxo que você deseja configurar">
        <button onClick={() => {
          setExibirModal(true);
        }} className="btn btn-md btn-orange">
          Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Nome
            </label>
            <input
              value={filtrosPesquisa.nome}
              onChange={(e) => {
                setFiltrosPesquisa((oldState) => {
                  return { ...oldState, nome: e.target.value };
                });
              }}
              placeholder="Nome"
              type="text"
              className={"form-control"}
              id="form-nome"
            />
          </div>

          <div className="col-md-2">
            <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
              Módulo:
            </label>
            <select
              id="form-modulo"
              className="form-select"
              value={filtrosPesquisa.modulo}
              onChange={(e) => {
                setFiltrosPesquisa((oldState) => ({
                  ...oldState,
                  modulo: parseInt(e.target.value),
                }));
              }}
            >
              <option value="">Selecione um módulo</option>
              <option value="1">Contencioso</option>
              <option value="2">XSigner</option>
              <option value="3">XFinder</option>
              <option value="4">Administração</option>
              <option value="5">Societário</option>
              <option value="6">Ged</option>
              <option value="7">Contrato</option>
              <option value="8">Dashboard</option>
              <option value="9">Consultivo</option>
              <option value="10">Imobiliário</option>
              <option value="11">Marcas & Patentes</option>
              <option value="12">Despesa</option>
              <option value="13">Ato Societário</option>
              <option value="14">Andamento</option>
              <option value="15">Bloqueio</option>
              <option value="16">Compromisso</option>
              <option value="17">Decisão</option>
              <option value="18">Desdobramento</option>
              <option value="19">Favorito</option>
              <option value="20">Fechamento</option>
              <option value="21">Procuração</option>
              <option value="22">Garantia</option>
              <option value="23">Obrigação</option>
              <option value="24">Pessoas</option>
              <option value="25">Proposta de Acordo</option>
              <option value="26">Publicação</option>
              <option value="27">Valores</option>
              <option value="28">Log</option>
              <option value="29">Pesquisa Global</option>
              <option value="30">Regulatório</option>
              <option value="31">Relatório</option>
              <option value="32">Email</option>
              <option value="33">Editor de Documento</option>
              <option value="34">Xpay Honorário</option>
              <option value="35">Xpay Reembolso</option>
              <option value="36">Xpay Fechamento</option>
              <option value="37">Xpay Contrato</option>
              <option value="38">Audiência</option>
              <option value="39">XLearning</option>
              <option value="40">Parte Contrária</option>
              <option value="41">Encerramento de Processo</option>
              <option value="42">XFinder Distribuição</option>
              <option value="43">Representante Societário</option>
              <option value="44">Sócio Administrador</option>
              <option value="45">Filial</option>
              <option value="46">XFinder Protocolo</option>
              <option value="47">Comunicação</option>
              <option value="48">Cliente</option>
              <option value="49">Usuário</option>
              <option value="50">Levantamento de Garantia</option>
            </select>
          </div>


          <div className="col-md-2">
            <div>
              <button onClick={() => carregarFluxo(filtrosPesquisa)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
              </button>
            </div>
          </div>
        </form>
      </div>



      <div className="row mt-2">
        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            onRowClicked={onRowClicked}
            progressPending={carregandoFluxo}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Fluxo"
            itens={fluxos}
          />
        </div>
      </div>


      <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Fluxo</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form>

              <div className="row">
                <div className="col-md-4 mb-2">
                  <label htmlFor="form-modulo" className="form-label fw-bolder text-orange">
                    Módulo:
                  </label>
                  <select
                    onChange={(e) => formik.setFieldValue('modulo', parseInt(e.target.value))}
                    className={"form-select"}
                  >
                    <option value="" label="Selecione um modulo" />

                    <option value="">Selecione um módulo</option>
                    <option value="1">Contencioso</option>
                    <option value="2">XSigner</option>
                    <option value="3">XFinder</option>
                    <option value="4">Administração</option>
                    <option value="5">Societário</option>
                    <option value="6">Ged</option>
                    <option value="7">Contrato</option>
                    <option value="8">Dashboard</option>
                    <option value="9">Consultivo</option>
                    <option value="10">Imobiliário</option>
                    <option value="11">Marcas & Patentes</option>
                    <option value="12">Despesa</option>
                    <option value="13">Ato Societário</option>
                    <option value="14">Andamento</option>
                    <option value="15">Bloqueio</option>
                    <option value="16">Compromisso</option>
                    <option value="17">Decisão</option>
                    <option value="18">Desdobramento</option>
                    <option value="19">Favorito</option>
                    <option value="20">Fechamento</option>
                    <option value="21">Procuração</option>
                    <option value="22">Garantia</option>
                    <option value="23">Obrigação</option>
                    <option value="24">Pessoas</option>
                    <option value="25">Proposta de Acordo</option>
                    <option value="26">Publicação</option>
                    <option value="27">Valores</option>
                    <option value="28">Log</option>
                    <option value="29">Pesquisa Global</option>
                    <option value="30">Regulatório</option>
                    <option value="31">Relatório</option>
                    <option value="32">Email</option>
                    <option value="33">Editor de Documento</option>
                    <option value="34">Xpay Honorário</option>
                    <option value="35">Xpay Reembolso</option>
                    <option value="36">Xpay Fechamento</option>
                    <option value="37">Xpay Contrato</option>
                    <option value="38">Audiência</option>
                    <option value="39">XLearning</option>
                    <option value="40">Parte Contrária</option>
                    <option value="41">Encerramento de Processo</option>
                    <option value="42">XFinder Distribuição</option>
                    <option value="43">Representante Societário</option>
                    <option value="44">Sócio Administrador</option>
                    <option value="45">Filial</option>
                    <option value="46">XFinder Protocolo</option>
                    <option value="47">Comunicação</option>
                    <option value="48">Cliente</option>
                    <option value="49">Usuário</option>
                    <option value="50">Levantamento de Garantia</option>
                  </select>
                  {formik.touched.modulo && formik.errors.modulo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.modulo}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>


              <div className="row">
                <div className="col-md-4 mb-5">
                  <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">Código</label>
                  <input {...formik.getFieldProps('codigo')} placeholder="Nome" type="text" className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.codigo && formik.errors.codigo,
                    },
                    {
                      'is-valid': formik.touched.codigo && !formik.errors.codigo,
                    }
                  )} id="form-codigo" />
                  {formik.touched.codigo && formik.errors.codigo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.codigo}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-5">
                  <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">Nome</label>
                  <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.nome && formik.errors.nome,
                    },
                    {
                      'is-valid': formik.touched.nome && !formik.errors.nome,
                    }
                  )} id="form-nome" />
                  {formik.touched.nome && formik.errors.nome && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.nome}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-5">
                  <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">Descrição</label>
                  <input {...formik.getFieldProps('descricao')} placeholder="Nome" type="text" className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.descricao && formik.errors.descricao,
                    },
                    {
                      'is-valid': formik.touched.descricao && !formik.errors.descricao,
                    }
                  )} id="form-descricao" />
                  {formik.touched.descricao && formik.errors.descricao && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.descricao}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </form>
          </div>

          <div className="modal-footer" style={{ margin: '0 auto' }}>
            <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
              {!formik.isSubmitting && <> SALVAR </>}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button disabled={formik.isSubmitting || !formik.values} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

          </div>
        </div>
      </Modal>

    </>
  );
};

export default TipoFluxoPesquisaPage;
