import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from "../../../enum";

import AndamentoService from "../../../services/AndamentoService";

import IAndamentos from "../../../interfaces/IAndamentos";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaAndamentoRequest from "../../../interfaces/Requests/Andamento/FiltroPesquisaAndamentoRequest";
import { IRecuperaSeparadorTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import GridPadrao from "../GridPadrao";
import AndamentoField from "./AndamentoField";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import CollapseDefault from "../../Collapse/CollapseDefault";

interface IAndamentoListFieldPropos {
  modulo: EModulo;
  configuracaoTela: IRecuperaSeparadorTelaResponse;
}

const AndamentoListField = ({ modulo, configuracaoTela }: IAndamentoListFieldPropos) => {
  const { processo, contrato, imovel, licenca, procuracao, marca, consulta } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirListaAndamento, setExibirListaAndamento] = useState<boolean>(false);
  const [exibirModalAndamento, setExibirModalAndamento] = useState<boolean>(false);
  const [atualizaTabelaAndamento, setAtualizaTabelaAndamento] = useState<boolean>(false);
  const [andamentos, setAndamentos] = useState<IAndamentos[]>([]);
  const [carregandoAndamentos, setCarregandoAndamentos] = useState(false);
  const [editarAndamento, setEditarAndamento] = useState<IAndamentos>({
    processoParteContraria: [],
    andamentoId: 0,
    andamentoTipoId: 0,
    modulo: modulo,
    codigo: "",
    dataAndamento: new Date(),
    andamentoTipoNome: "",
    descricao: "",
    restrito: false,
    dataCadastro: "",
    xFinderAndamentoTipoExterno: "",
    numeroProcesso: "",
  });
  const [filtrosAndamento, setFiltrosAndamento] = useState<FiltroPesquisaAndamentoRequest>({
    tipoPesquisa: 1,
    areaDireitoId: [],
    filtro: "",
    limit: 10,
    ultimosQuantidade: null,
    totalItems: 0,
    offset: 0,
    sort: "-DataAndamento",
    status: [1],
    modulo: modulo,
    descricao: "",
    andamentoTipoId: [],
    dataAndamentoMaiorIgual: "",
    dataAndamentoMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
  });

  const toggleAndamento = () => setExibirModalAndamento(!exibirModalAndamento);

  useEffect(() => {
    if (atualizaTabelaAndamento) {
      setTimeout(() => carregarAndamentos(filtrosAndamento), 3000);
    }
    setAtualizaTabelaAndamento(false);
  }, [atualizaTabelaAndamento]);

  useEffect(() => {
    setFiltrosAndamento((oldState) => {
      return { ...oldState, ultimosQuantidade: 0 };
    });
    if (exibirListaAndamento) carregarAndamentos(filtrosAndamento);
  }, [filtrosAndamento.offset, filtrosAndamento.limit, filtrosAndamento.sort]);


  useEffect(() => {
    if (exibirListaAndamento) carregarAndamentos(filtrosAndamento);
  }, [exibirListaAndamento]);

  const handlePerRowsChangeAndamento = async (currentRowsPerPage: number) => setFiltrosAndamento((oldState) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChangeAndamento = (page: number) => setFiltrosAndamento((oldState) => { return { ...oldState, offset: (page - 1) * filtrosAndamento.limit }; });

  const handleSortAndamento = async (column: TableColumn<IAndamentos>, sortDirection: string) => setFiltrosAndamento((oldState) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  const colunasAndamentos: TableColumn<IAndamentos>[] = [
    {
      cell: (row: IAndamentos) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                className="form-label fw-bolder text-orange"
                style={{
                  paddingTop: "30px",
                }}
              >
                #{row.andamentoId} - {row.andamentoTipoNome} - {moment(row.dataCadastro).format("DD/MM/yyyy")}
              </p>
              <p>{row.descricao}</p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Data do andamento: </b> {moment(row.dataAndamento).format("DD/MM/yyyy")}
                <br />
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Restrito: </b> {row.restrito ? "Sim" : "Não"}
                <br />
              </p>
              <p>
                <>
                  <FontAwesomeIcon
                    title="Remover Andamento"
                    onClick={async () => {
                      const swalWithBootstrapButtons = Swal.mixin({
                        heightAuto: false,
                        customClass: {
                          confirmButton: "btn btn-orange",
                          cancelButton: "btn btn-danger ms-5",
                        },
                        buttonsStyling: false,
                      });

                      let result = await swalWithBootstrapButtons.fire({
                        title: "Remover",
                        text: `Você realmente deseja remover?`,
                        showCancelButton: true,
                        cancelButtonText: "Cancelar",
                        confirmButtonText: `Confirmar`,
                      });

                      if (result.isConfirmed) {
                        AndamentoService.inativarAndamento(row.andamentoId);
                        carregarAndamentos(filtrosAndamento);
                      }
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faTrash}
                  />
                  <FontAwesomeIcon
                    title="Editar Andamento"
                    onClick={async () => {
                      setEditarAndamento({
                        modulo: modulo,
                        andamentoId: row.andamentoId,
                        codigo: row.codigo,
                        descricao: row.descricao,
                        andamentoTipoId: row.andamentoTipoId,
                        dataAndamento: row.dataAndamento,
                        processoId: row.processoId,
                        restrito: row.restrito,
                        andamentoTipoNome: "",
                        dataCadastro: "",
                        xFinderAndamentoTipoExterno: "",
                        numeroProcesso: "",
                        processoParteContraria: row.processoParteContraria
                      });
                      setExibirModalAndamento(true);
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faEdit}
                  />
                </>
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const maisFiltros = (): any => {
    let objeto: any = {};
    if (processo.processoId && EModulo.AreaDireito == modulo) objeto.processoId = processo.processoId;
    if (contrato.contratoId && EModulo.Contrato == modulo) objeto.contratoId = contrato.contratoId;
    if (imovel.imovelId && EModulo.Imovel == modulo) objeto.imovelId = imovel.imovelId;
    if (licenca.licencaId && EModulo.Regulatorio == modulo) objeto.licencaId = licenca.licencaId;
    if (procuracao.procuracaoId && EModulo.Procuracao == modulo) objeto.procuracaoId = procuracao.procuracaoId;
    if (marca.marcaId && EModulo.MarcaEPertences == modulo) objeto.marcaId = marca.marcaId;
    if (consulta.consultaId && EModulo.Consultivo == modulo) objeto.consultaId = consulta.consultaId;
    return objeto;
  }

  const carregarAndamentos = async (filtro: FiltroPesquisaAndamentoRequest) => {
    try {

      setCarregandoAndamentos(true);

      let resultado: IRecuperaLista<IAndamentos>;
      resultado = await AndamentoService.obterAndamentos({
        ...filtro,
        ...maisFiltros()
      });

      setAndamentos(resultado.data);

      setFiltrosAndamento((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCarregandoAndamentos(false);
    } catch (error: any) {
      setAndamentos([]);
      setCarregandoAndamentos(false);
    }
  };

  return (
    <>
      <AndamentoField
        editarAndamento={editarAndamento}
        exibirModal={exibirModalAndamento}
        toggleModal={toggleAndamento}
        modulo={modulo}
        setAtualizarTabela={setAtualizaTabelaAndamento}

        //modulos
        processoId={processo?.processoId}
        areaDireitoId={processo?.areaDireitoId}

        contratoId={contrato?.contratoId}
        tipoContratoId={contrato?.tipoContratoId}

        imovelId={imovel?.imovelId}
        tipoImovelId={imovel?.tipoImovelId}

        procuracaoId={procuracao?.procuracaoId}
        tipoProcuracaoId={procuracao?.tipoProcuracaoId}

        consultaId={consulta?.consultaId}
        tipoConsultaId={consulta?.tipoConsultaId}

        marcaId={marca?.marcaId}
        tipoMarcaId={marca?.tipoMarcaId}

        licencaId={licenca?.licencaId}
        tipoLicencaId={licenca?.tipoLicencaId}
      />

      <div className="row mt-3">
        <CollapseDefault
          modulo={EModulo.Andamento}
          carregando={carregandoAndamentos}
          exibir={exibirListaAndamento}
          setExibir={setExibirListaAndamento}
          help={configuracaoTela.help}
          titulo={configuracaoTela?.label ? configuracaoTela.label : 'Últimos Andamentos'}
          content={<>
            {exibirListaAndamento && (
              <>
                <div className="col-md-12">
                  <button
                    onClick={() => {
                      setEditarAndamento({ ...editarAndamento, andamentoId: 0 });
                      setExibirModalAndamento(!exibirModalAndamento);
                    }}
                    className="btn btn-orange"
                  >
                    {exibirModalAndamento && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                    {!exibirModalAndamento && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
                  </button>
                </div>
                <div className="col-md-auto mt-3">
                  <input
                    value={filtrosAndamento.filtro}
                    onChange={(e) => {
                      setFiltrosAndamento((oldState) => {
                        return { ...oldState, filtro: e.target.value };
                      });
                    }}
                    placeholder="Andamentos"
                    type="text"
                    className={clsx("form-control")}
                  />
                </div>
                <div className="col-md-auto mt-3">
                  <input
                    value={filtrosAndamento.ultimosQuantidade || ""}
                    type="number"
                    maxLength={3}
                    onChange={(e) => {
                      setFiltrosAndamento((oldState) => {
                        return { ...oldState, ultimosQuantidade: parseInt(e.target.value, 10) };
                      });
                    }}
                    placeholder="+ Quantidade"
                    className={clsx("form-control")}
                  />
                </div>
                <div className="col-md-auto mt-2">
                  <button onClick={() => carregarAndamentos(filtrosAndamento)} className="btn btn-orange">
                    {<> Pesquisar </>}
                  </button>
                </div>
                <div className="col-md-auto mt-2">{<> Buscar por: </>}</div>
                <div className="col-md-auto mt-2">
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltrosAndamento({ ...filtrosAndamento, tipoPesquisa: 1 })}
                      label="Nome"
                      name="group1"
                      type="radio"
                    />
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltrosAndamento({ ...filtrosAndamento, tipoPesquisa: 2 })}
                      label="Código"
                      name="group1"
                      type="radio"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-5">
                  <GridPadrao
                    onSort={handleSortAndamento}
                    progressPending={carregandoAndamentos}
                    limit={filtrosAndamento.limit}
                    onChangePage={handlePageChangeAndamento}
                    onChangeRowsPerPage={handlePerRowsChangeAndamento}
                    paginationServer={true}
                    paginationTotalRows={filtrosAndamento.totalItems}
                    colunas={colunasAndamentos}
                    tipo="Andamentos"
                    itens={andamentos}
                  />
                </div>
              </>
            )}
          </>}
        />
      </div>
      <hr />
    </>
  );
};

export default AndamentoListField;
