import { faEraser, faPen, faPlus, faSearch, faFileExcel, faCog, } from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableColumn } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import GridPadrao from '../../components/Comum/GridPadrao';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import LayoutPageTitle from '../../layout/LayoutPageTitle';
import Swal from "sweetalert2";
import IXPayContrato from '../../interfaces/IXPayContrato';
import XPayContratoService from '../../services/XPayContratoService';
import IModulo from '../../interfaces/IModulo';
import ModuloService from '../../services/ModuloService';
import { MultiSelect } from 'react-multi-select-component';
import XPayContratoModal from './XPayContratoModal';
import FiltroPesquisaXPayContratoRequest from '../../interfaces/Requests/XPayContrato/FiltroPesquisaXCampoRequest';
import IEscritorio from '../../interfaces/IEscritorio';
import EscritorioService from '../../services/EscritorioService';
import XPayContratoAgrupadoresModal from './XPayContratoAgrupadoresModal';

const XPayContratoPage = () => {
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoXPayContratos, setCarregandoXPayContratos] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);
    const [XPayContratos, setXPayContratos] = useState<IXPayContrato[]>([]);
    const [XPayContrato, setXPayContrato] = useState<IXPayContrato>();
    const [exibirAgrupadores, setExibirAgrupadores] = useState<boolean>(false);

    const [relatorioId, setRelatorioId] = useState<number | null>(null);
    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaXPayContratoRequest>({
        escritorioId: [],
        nome: "",
        status: 1,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        modulo: [],
        totalItems: 0,
        offset: 0,
        sort: "-dataCadastro",
        limit: 10
    });

    const [modulo, setModulo] = useState<any>([]);
    const [moduloSelecionados, setModuloSelecionados] = useState([]);
    const [carregandoModulo, setCarregandoModulo] = useState<boolean>(false);

    const [escritorio, setEscritorio] = useState<any>([]);
    const [escritorioSelecionados, setEscritorioSelecionados] = useState([]);
    const [carregandoEscritorio, setCarregandoEscritorio] = useState<boolean>(false);

    const toggleModal = (carregarDados?: boolean): void => {
        if (carregarDados) {
            carregarXPayContratos(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);
    }

    const toggleAgrupadores = () => setExibirAgrupadores(!exibirAgrupadores);

    const carregarXPayContratos = async (filtro: FiltroPesquisaXPayContratoRequest): Promise<void> => {
        try {
            setCarregandoXPayContratos(true);
            let resultado: IRecuperaLista<IXPayContrato>;

            resultado = await XPayContratoService.obterXPayContrato(filtro);

            if (resultado.data === null) {
                setXPayContratos([]);
                setRelatorioId(null);
            }
            else {
                setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
                setRelatorioId(resultado.relatorioId || null);
                setXPayContratos(resultado.data);
            }
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros.',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setXPayContratos([]);
            setRelatorioId(null);
        }
        finally {
            setCarregandoXPayContratos(false);
            setLoadingInicial(false);
        }
    }

    const limparFiltros = () => {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                status: 1,
                nome: "",
                dataCadastroMaiorIgual: "",
                dataCadastroMenorIgual: "",
                modulo: [],
                escritorioId: [],
                totalItems: 0,
                offset: 0,
                sort: "-dataCadastro",
                limit: 10
            }
        });
        setModuloSelecionados([]);
        setEscritorioSelecionados([]);
    }

    const carregarModulo = async () => {
        try {
            if (modulo.length > 0) return;
            setCarregandoModulo(true);
            let resultado: IRecuperaLista<IModulo>;
            resultado = await ModuloService.obterModulos({
                nome: "",
                codigo: "",
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "nome",
            });
            setModulo(returnOptions(resultado.data, "moduloId", "modulo"));
            setCarregandoModulo(false);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setCarregandoModulo(false);
            setModulo([]);
        }
    }

    const carregarEscritorio = async () => {
        try {
            if (escritorio.length > 0) return;
            setCarregandoEscritorio(true);
            let resultado: IRecuperaLista<IEscritorio>;
            resultado = await EscritorioService.obterEscritorios({
                nome: "",
                clienteId: 0,
                isTelaPesquisaProcesso: true,
                codigo: "",
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "nome",
            });
            setEscritorio(returnOptions(resultado.data, "escritorioId", "escritorio"));
            setCarregandoEscritorio(false);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setCarregandoEscritorio(false);
            setEscritorio([]);
        }
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarXPayContratos(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

    useEffect(() => {
        carregarModulo();
        carregarEscritorio();
    }, []);

    const returnOptions = (array: any, propId: string, arrayName: string) => {
        array.forEach((item: any) => {
            eval(`${arrayName}`).push({
                value: eval(`item.${propId}`),
                label: item.nome,
            });
        });
        return eval(`${arrayName}`);
    };

    const setIdsSelect = (entidade: string, optionsSelected: any) => {
        const IdsSelected: number[] = [];

        optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

        switch (entidade) {
            case "moduloId":
                setFiltrosPesquisa({ ...filtrosPesquisa, modulo: IdsSelected });
                break;
            case "escritorioId":
                setFiltrosPesquisa({ ...filtrosPesquisa, escritorioId: IdsSelected });
                break;
        }
    };

    const colunas: TableColumn<IXPayContrato>[] = [
        {
            name: 'Id',
            sortField: 'xPayContratoId',
            selector: (row: IXPayContrato) => row.xpayContratoId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Módulo',
            sortField: 'modulo',
            selector: (row: IXPayContrato) => row.modulo ?? "",
            sortable: true,
            wrap: true
        },
        {
            name: 'Nome',
            sortField: 'nome',
            selector: (row: IXPayContrato) => row.nome ?? "",
            sortable: true,
            wrap: true
        },
        {
            name: 'Escritório',
            sortField: 'escritorioNome',
            selector: (row: IXPayContrato) => row.escritorioNome ?? "",
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (XPayContrato: IXPayContrato) => {
                return (
                    <>
                        <div>
                            <FontAwesomeIcon title="Editar" onClick={() => {
                                setXPayContrato(XPayContrato);
                                setExibirModal(true);
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />
                        </div>
                        <div style={{ paddingRight: "10px" }}>
                            <FontAwesomeIcon
                                title="Agrupadores"
                                onClick={() => {
                                    setXPayContrato(XPayContrato);
                                    setExibirAgrupadores(true);
                                }}
                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                size="2x"
                                className="mx-1 text-orange"
                                icon={faCog}
                            />
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
        }
    ]

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<IXPayContrato>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };


    return (<>
        <LayoutPageTitle title="XPayContratos">
            <button onClick={() => {
                setExibirModal(true)
            }} className="btn btn-md btn-orange">
                Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        <XPayContratoModal
            exibirModal={exibirModal}
            titulo={`Novo XPayContrato`}
            toggleModal={toggleModal}
            XPayContrato={XPayContrato}
        />

        <XPayContratoAgrupadoresModal
            exibirModal={exibirAgrupadores}
            titulo={"Agrupadores"}
            toggleModal={toggleAgrupadores}
            xPayContrato={XPayContrato}
        />

        <div className="row mt-2">
            <form onSubmit={(e) => {
                e.preventDefault();
                carregarXPayContratos(filtrosPesquisa);
            }} className="row g-3 mb-3 form-buscar-XPayContrato">
                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
                    <DateRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        maxDate={new Date()}
                        value={
                            (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                                [
                                    filtrosPesquisa.dataCadastroMaiorIgual,
                                    filtrosPesquisa.dataCadastroMenorIgual,
                                ] : ''
                        }
                        onChange={(datas: Date[]) => {
                            if (!datas) {
                                setFiltrosPesquisa(oldValue => {
                                    return {
                                        ...oldValue,
                                        dataCadastroMaiorIgual: '',
                                        dataCadastroMenorIgual: '',
                                    }
                                });
                                return
                            }

                            let data_inicial = datas[0];
                            let data_final = datas[1];
                            if (data_final) {
                                data_final.setHours(0, 0, 0, 0);
                                data_final = new Date(data_final.getTime() - 1)
                            }
                            setFiltrosPesquisa(oldValue => {

                                return {
                                    ...oldValue,
                                    dataCadastroMaiorIgual: (data_inicial) ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                    dataCadastroMenorIgual: (data_final) ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                }
                            })
                        }}
                    />
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                    <input value={filtrosPesquisa.nome} onChange={(e) => {
                        setFiltrosPesquisa(oldState => { return { ...oldState, nome: e.target.value } });
                    }} placeholder="Nome" className={'form-control'} id="form-nome" />
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-modeulo" className="form-label fw-bolder text-orange">
                        Módulo:
                    </label>
                    <MultiSelect
                        isLoading={carregandoModulo}
                        disabled={carregandoModulo}
                        options={modulo}
                        value={moduloSelecionados}
                        onChange={(event: any) => {
                            setModuloSelecionados(event);
                            setIdsSelect("moduloId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                            selectSomeItems: "Selecione...",
                            allItemsAreSelected: "Todos selecionados",
                            selectAll: "Selecione todos",
                            search: "Pesquise",
                            selectAllFiltered: "Selecione todos (filtrados)",
                        }}
                    />
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-escritorio" className="form-label fw-bolder text-orange">
                        Escritório:
                    </label>
                    <MultiSelect
                        isLoading={carregandoEscritorio}
                        disabled={carregandoEscritorio}
                        options={escritorio}
                        value={escritorioSelecionados}
                        onChange={(event: any) => {
                            setEscritorioSelecionados(event);
                            setIdsSelect("escritorioId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                            selectSomeItems: "Selecione...",
                            allItemsAreSelected: "Todos selecionados",
                            selectAll: "Selecione todos",
                            search: "Pesquise",
                            selectAllFiltered: "Selecione todos (filtrados)",
                        }}
                    />
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Status:</label>
                    <select value={filtrosPesquisa.status} onChange={(e) => {
                        setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                    }} placeholder="Nome" className={'form-select'} id="form-select" >
                        <option value="0" label="Todos"> Todos </option>
                        <option value="1" label="Ativo"> Ativo </option>
                        <option value="-1" label="Inativo"> Inativo </option>
                    </select>
                </div>

                <div className="col-12 "
                    style={{ textAlign: 'right' }}>
                    <button
                        type="submit"
                        className="btn btn-orange search-buttom-margin "
                        title="Buscar"
                    >
                        <FontAwesomeIcon color='white' className='' icon={faSearch} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            limparFiltros();
                        }}
                        className="btn btn-orange search-buttom-margin ms-2"
                        title="Limpar Filtro"
                    >
                        <FontAwesomeIcon color='white' className='' icon={faEraser} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            //baixarRelatorio();
                        }}
                        className="btn btn-orange search-buttom-margin ms-2"
                        title="Baixar Relatório"
                        disabled={relatorioId === null || carregandoXPayContratos}
                    >
                        <FontAwesomeIcon color='white' className='' icon={faFileExcel} />
                    </button>
                </div>
            </form>
            <div className="col-md-12 mb-10">
                <GridPadrao
                    onSort={handleSort}
                    progressPending={carregandoXPayContratos}
                    limit={filtrosPesquisa.limit}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer={true}
                    paginationTotalRows={filtrosPesquisa.totalItems}
                    colunas={colunas}
                    tipo='XPayContratos'
                    itens={XPayContratos} />
            </div>
        </div >
    </>)
}

export default XPayContratoPage;