import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "../../../components/GridPadrao";
import IFile from "../../../interfaces/IFile";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import ContratoService from "../../../services/ContratoService";
import EmpresaService from "../../../services/EmpresaService";
import ProcessoService from "../../../services/ProcessoService";
import TipoDocumentoService from "../../../services/TipoDocumentoService";

interface IFileUploadFieldProps {
  processoId?: number;
  areaDireitoId?: number;
  contratoId?: number;
  empresaId?: number;
  atoSocietarioId?: number;
  tipoContratoId?: number;
  setQuantidadeArquivosSelecionados(quantidade: number): void;
  toggleModal: () => void;
  exibirModal: boolean;
  capa?: boolean;
}

const FileUploadAtoField = ({
  processoId,
  empresaId,
  atoSocietarioId,
  areaDireitoId,
  contratoId,
  tipoContratoId,
  setQuantidadeArquivosSelecionados,
  toggleModal,
  exibirModal,
  capa,
}: IFileUploadFieldProps) => {
  const [descricao, setDescricao] = useState<string>("");
  const [tipoDocumentos, setTipoDocumento] = useState<any[]>([]);
  const [tipoDocumentoId, setTipoDocumentoId] = useState<number>(0);

  useLayoutEffect(() => {
    carregaTipoDocumento();
  }, []);

  const adicionaArquivo = (event: any) => {
    formik.values.push({
      index: 0,
      tipoDocumentoId: 0,
      documentoId: 0,
      serRestriro: false,
      file: event.target.files[0],
      nome: !descricao ? event.target.files[0].name : descricao,
      extensao: event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf(".") + 1),
    });
    formik.values.forEach((file: IFile, index: number) => (file.index = index));
    formik.submitForm();
  };

  const iniciarUploadArquivos = async () => {

    formik.values.forEach(async (item: IFile) => {
      const enviados = [];

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Realizando upload...`,
        allowOutsideClick: false,
        showConfirmButton: false
      });

      Swal.showLoading()

      try {

        const formData = new FormData();
        formData.append("origem", "1");
        formData.append("file", item.file);
        formData.append("tipoDocumentoId", tipoDocumentoId.toString());
        formData.append("descricao", item.nome);
        formData.append("restrito", item.serRestriro.toString());
        if (processoId) {
          formData.append("modulo", "1");
          formData.append("processoId", processoId.toString());
          enviados.push(await ProcessoService.adicionaArquivo(formData));
        }
        if (contratoId) {
          formData.append("modulo", "7");
          formData.append("contratoId", contratoId.toString());
          enviados.push(await ContratoService.adicionaArquivo(formData));
        }
        if (empresaId) {
          formData.append("modulo", "5");
          formData.append("empresaId", empresaId.toString());
          enviados.push(await EmpresaService.adicionaArquivo(formData));
        }
        if (atoSocietarioId) {
          formData.append("modulo", "13");
          formData.append("atoSocietarioId", atoSocietarioId.toString());
          enviados.push(await EmpresaService.adicionaArquivo(formData));
        }

        setQuantidadeArquivosSelecionados(0);
        Swal.hideLoading();

        await Swal.fire({
          heightAuto: false,
          icon: 'success',
          text: `Upload realizado com sucesso!`,
          showConfirmButton: true,
          timer: 4000
        });

        Swal.close();

      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao realizar o upload dos arquivos`,
          text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
          showConfirmButton: true,
        });
      }
    });

  };

  const initialValues: IFile[] = [];

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      formik.setValues(values);
      setQuantidadeArquivosSelecionados(values.length);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (processoId) iniciarUploadArquivos();
  }, [processoId]);

  useEffect(() => {
    if (contratoId) iniciarUploadArquivos();
  }, [contratoId]);

  useEffect(() => {
    if (empresaId) iniciarUploadArquivos();
  }, [empresaId]);

  useEffect(() => {
    if (atoSocietarioId) carregaTipoDocumento();
  }, [atoSocietarioId]);


  // useEffect(() => {
  //   if(atoSocietarioId) carregaTipoDocumento();
  //   formik.setValues([])
  // },[atoSocietarioId]);

  const adicionaSerRestrito = (index: number) => {
    formik.values.forEach((file) => {
      if (file.index == index) {
        if (!file.serRestriro) {
          file.serRestriro = true;
          formik.setValues(formik.values);
          return;
        }
        if (file.serRestriro) {
          file.serRestriro = false;
          formik.setValues(formik.values);
          return;
        }
      }
    });
  };

  const colunas: TableColumn<IFile>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IFile) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Extensão",
      sortField: "nome",
      selector: (row: IFile) => row.extensao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Restrito?",
      cell: (row: IFile) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.serRestriro}
              onClick={() => adicionaSerRestrito(row.index)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
    {
      name: "Remover?",
      cell: (row: IFile) => {
        return (
          <div style={{ paddingRight: "10px" }}>
            <FontAwesomeIcon
              title="Remover?"
              onClick={async () => {
                const swalWithBootstrapButtons = Swal.mixin({
                  heightAuto: false,
                  customClass: {
                    confirmButton: "btn btn-danger ms-5",
                    cancelButton: "btn btn-orange ms-5",
                  },
                  buttonsStyling: false,
                });

                let result = await swalWithBootstrapButtons.fire({
                  title: "Cancelar",
                  text: `Você realmente deseja remover este arquivo?`,
                  showCancelButton: true,
                  cancelButtonText: "Não",
                  confirmButtonText: `Sim, desejo remover!`,
                });

                if (result.isConfirmed) {
                  let restantes = formik.values.filter((e) => e.index != row.index);
                  formik.setValues(restantes);
                }
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faTrash}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const carregaTipoDocumento = async () => {
    try {
      if (areaDireitoId && areaDireitoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", 1, areaDireitoId, processoId || 0);
        setTipoDocumento(campos.data);
      }

      if (tipoContratoId && tipoContratoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", 7, tipoContratoId, contratoId || 0);
        setTipoDocumento(campos.data);
      }

      if (empresaId && empresaId > 0) {
        let campos = await TipoDocumentoService.obterTipoDocumentos({
          nome: '',
          codigo: '',
          status: 0,
          offset: 0,
          limit: 1000,
          sort: 'tipoDocumentoId',
          totalItems: 0
        });
        setTipoDocumento(campos.data);
      }

      if (atoSocietarioId && atoSocietarioId > 0) {
        let campos = await TipoDocumentoService.obterTipoDocumentos({
          nome: '',
          codigo: '',
          status: 0,
          offset: 0,
          limit: 1000,
          sort: 'tipoDocumentoId',
          totalItems: 0
        });
        setTipoDocumento(campos.data);
      }


    } catch (error: any) {
      setTipoDocumento([]);
    }
  };

  const adicionar = () => {
    if (!tipoDocumentoId) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Por favor é necessário informar o tipo de documento`,
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (capa) {
      iniciarUploadArquivos();
      formik.setValues([]);
      setTipoDocumentoId(0);
      setDescricao('');
    }
    toggleModal();
  };

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Importação de arquivos</h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row g-3">
            <div className="col-md-6 mb-3">
              <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                Tipo de Documento:
              </label>
              <select
                name="tipoDocumentoId"
                value={tipoDocumentoId}
                onChange={(event) => setTipoDocumentoId(parseInt(event.target.value))}
                className={"form-control"}
              >
                <option value="0">Selecione</option>
                {tipoDocumentos.map((map: any) => {
                  return (
                    <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                      {map.nome}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                Descrição:
              </label>
              <input
                value={descricao}
                placeholder="Descrição do arquivo..."
                type="text"
                className={"form-control"}
                onChange={(e: any) => setDescricao(e.target.value)}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                Arquivo:
              </label>
              <input
                type="file"
                accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.pdf,.mp3,.zip,.wav,.tiff,.tif,.rft,.rar,.mp4,.eml,.opus,.mkv,.pjc"
                className="btn btn-orange ms-1"
                onChange={(e: any) => {
                  adicionaArquivo(e);
                  e.target.value = null;
                }}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <GridPadrao paginationServer={true} colunas={colunas} tipo="Arquivos" itens={formik.values} />
            </div>
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button onClick={() => adicionar()} type="button" className=" btn btn-orange ms-5">
              Adicionar
            </button>

            <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadAtoField;
