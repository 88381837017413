import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoReembolso from "../interfaces/ITipoReembolso";
import { AdicionaTipoReembolsoRequest } from "../interfaces/Requests/TipoReembolso/AdicionaTipoReembolsoRequest";
import FiltroPesquisaTipoReembolsoRequest from "../interfaces/Requests/TipoReembolso/FiltroPesquisaTipoReembolsoRequest";
import { EditarTipoReembolsoRequest } from "../interfaces/Requests/TipoReembolso/EditarTipoReembolsoRequest";

class TipoReembolsoService {

    async obterTipoReembolsos(filtros: FiltroPesquisaTipoReembolsoRequest): Promise<IRecuperaLista<ITipoReembolso>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoReembolso>>(`TipoReembolso/RecuperaTipoReembolso?${query}`);

        return result;
    }

    async adicionaTipoReembolso(adicionaTipoReembolso: AdicionaTipoReembolsoRequest): Promise<ITipoReembolso> {
        let { data: result } = await api.post<ITipoReembolso>(`TipoReembolso/AdicionaTipoReembolso`, adicionaTipoReembolso);

        return result;
    }

    async obterTipoReembolsoLimitada(filtros: FiltroPesquisaTipoReembolsoRequest): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if(filtros.queryCustom){
            query += filtros.queryCustom;
        }

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        } 

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoReembolso/RecuperaListaLimitada?${query}`);

        return result;
    }

    async alterarStatus(tipoReembolsoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoReembolso>(`TipoReembolso/AtualizaTipoReembolsoPorId?id=${tipoReembolsoId}`, data);
    }

    async atualizarTipoReembolso(editarTipoReembolso: EditarTipoReembolsoRequest): Promise<void> {

        let dataTipoReembolso = [
            { "op": "replace", "path": "/nome", "value": editarTipoReembolso.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoReembolso.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoReembolso/AtualizaTipoReembolsoPorId?id=${editarTipoReembolso.tipoReembolsoId}`, dataTipoReembolso)
        ]);
    }
}


export default new TipoReembolsoService();