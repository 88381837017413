import { useNavigate } from 'react-router-dom';
import { AiFillFile } from 'react-icons/ai';
import { BsDownload, BsPeopleFill } from 'react-icons/bs';
import EnvelopeService from '../../../services/EnvelopeService';
import IEnvelope from '../../../interfaces/IEnvelope';
import IEnvelopeSignatario from '../../../interfaces/IEnvelopeSignatario';
import { CSSProperties, useState } from 'react';
import Swal from "sweetalert2";
import SignatarioForm from './SignatarioForm';
import { SignatariosListItem } from './SignatariosListItem';
import SignatarioDragAndDrop from './SignatarioDragAndDrop';
import CompromissoService from '../../../services/CompromissoService';


function CardEnvelopeDados(props: { carregarEnvelope: () => Promise<void>; envelope: IEnvelope; setEnvelope: (envelope: IEnvelope) => void; publico: boolean; itensStyle?: CSSProperties, children?: React.ReactNode }) {

    const navigate = useNavigate();
    const [envioPorOrdem, setEnvioPorOrdem] = useState<boolean>(false);

    const [signatarioEditar, setSignatarioEditar] = useState<IEnvelopeSignatario>();

    async function removerSignatario(signatario: IEnvelopeSignatario) {
        if (props.envelope.envelopeId > 0 && signatario.hashSignatario) {
            try {
                Swal.fire({
                    heightAuto: false,
                    icon: 'info',
                    title: 'Removendo signatário...',
                    showConfirmButton: false,
                });

                Swal.showLoading();

                await EnvelopeService.removerSignatario(signatario.hashSignatario);

                const resultado = {
                    ...props.envelope,
                    signatarios: props.envelope.signatarios.filter(s => s.hashSignatario !== signatario.hashSignatario).sort((a, b) => a.ordemAssinatura - b.ordemAssinatura).map((s, i) => ({ ...s, ordemAssinatura: i }))
                }
                await EnvelopeService.atualizarSignatarios(resultado);

                props.setEnvelope(resultado);

                if (resultado) {
                    Swal.fire({
                        heightAuto: false,
                        title: 'Registro removido com sucesso',
                        text: '',
                        timer: 2000,
                        icon: 'success',
                        showConfirmButton: false
                    });
                }
            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    title: 'Não foi possivel remover registro',
                    text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                    timer: 4000,
                    icon: 'error',
                    showConfirmButton: false,
                    didClose: async () => {
                        await props.carregarEnvelope();
                    }
                });
            }
            return;
        }
        props.envelope.signatarios = props.envelope.signatarios.filter(s => s.signatarioId !== signatario.signatarioId).map((s, index) => {
            s.ordemAssinatura = index;
            return s;
        });

        props.setEnvelope(
            {
                ...props.envelope
            }
        );
    }

    async function baixarTodos(): Promise<void> {

        Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Baixando arquivos...',
            showConfirmButton: false,
        });
        Swal.showLoading();
        try {
            const arquivo = await EnvelopeService.gerarZip(props.envelope.hashEnvelope);
            if (arquivo) {
                const url = window.URL.createObjectURL(arquivo);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'documentos.zip';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
            Swal.hideLoading();
            Swal.update({
                title: 'Baixado com sucesso',
                text: '',
                icon: 'success',
                showConfirmButton: true
            });

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possivel baixar arquivos',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false
            });
        }
    }

    function nomearStatusEnvelope(status: number): string {
        switch (status) {
            case -2:
                return 'Novo';
            case -1:
                return 'Cancelado';
            case 1:
                return 'Em Confecção';
            case 2:
                return 'Aguardando Signatários';
            case 3:
                return 'Assinado';
            default:
                return 'Não definido';
        }
    }

    async function cancelarEnvelope(): Promise<void> {

        const confirmacao = await Swal.fire({
            heightAuto: false,
            icon: 'question',
            title: 'Cancelar Envelope',
            text: 'Tem certeza que deseja cancelar o envelope?',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary-base)',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        });

        if (confirmacao.value) {
            Swal.fire({
                heightAuto: false,
                icon: 'info',
                title: 'Cancelando props.envelope...',
                showConfirmButton: false,
            });
            Swal.showLoading();

            try {
                props.setEnvelope(await EnvelopeService.cancelarEnvelope(props.envelope.hashEnvelope));
                await Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: 'Envelope cancelado com sucesso',
                    showConfirmButton: false,
                    timer: 3000,
                });
            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: 'Não foi possivel cancelar envelope',
                    text: error && (error.response && error.response.data) ? typeof error.response.data === 'string' ? error.response.data : error.response.data.Message : error.message,
                    showConfirmButton: true
                });
            } finally {
                Swal.close();
            }
        }
    }

    async function adicionaEnvelope(): Promise<void> {

        if (props.envelope.status === -2) {
            try {
                props.envelope.envioPorOrdem = envioPorOrdem;
                const pedirMensagem = await Swal.fire({
                    title: 'Mensagem',
                    input: 'textarea',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Salvar',
                    showLoaderOnConfirm: true,
                    didOpen: (popup) => {
                        const textoTextarea = popup.querySelector('textarea') as HTMLTextAreaElement;
                        if (!textoTextarea)
                            return;
                        textoTextarea.value = `Prezado(a).\nSegue(m) documento(s) para assinatura.`;
                        textoTextarea.focus();
                        Swal.disableInput();
                        Swal.enableInput();
                    },
                    preConfirm: (mensagem) => {
                        if (mensagem && mensagem.trim().length > 0) {
                            return mensagem;
                        }
                        Swal.showValidationMessage('Mensagem obrigatória');
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                });

                if (!pedirMensagem.isConfirmed || !pedirMensagem.value)
                    return Swal.close();

                Swal.fire({
                    heightAuto: false,
                    icon: 'info',
                    title: 'Enviando envelope para o servidor...',
                    showConfirmButton: false,
                });

                Swal.showLoading();
                const novoEnvelope = await EnvelopeService.adicionaEnvelope(props.envelope, pedirMensagem.value);

                if (props.envelope.compromissoId && props.envelope.contratoId) {
                    await CompromissoService.concluirCompromisso({ observacao: "Concluído!" }, props.envelope.compromissoId, props.envelope.contratoId);
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Envelope criado com sucesso e compromisso concluído`,
                        showConfirmButton: false,
                        timer: 3000,
                        didClose: () => { navigate(`/Contrato/Capa`); }
                    });
                }
                else if (props.envelope.compromissoId && props.envelope.procuracaoId) {
                    await CompromissoService.concluirCompromisso({ observacao: "Concluído!" }, props.envelope.compromissoId, props.envelope.procuracaoId);
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Envelope criado com sucesso e compromisso concluído`,
                        showConfirmButton: false,
                        timer: 3000,
                        didClose: () => { navigate(`/Procuracao/Capa`); }
                    });
                } else {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        text: `Envelope criado com sucesso`,
                        showConfirmButton: false,
                        timer: 3000,
                        didClose: () => {
                            navigate(`/XSigner/Envelopes/Editar/${novoEnvelope.envelopeId}`);
                        }
                    });
                }

            } catch (error: any) {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possivel criar o envelope`,
                    text: error && (error.response && error.response.data) ? typeof error.response.data === 'string' ? error.response.data : error.response.data.Message : error.message,
                    showConfirmButton: true
                });
            }
        }

    }

    return <>
        <div className="envelope-barra-lateral">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <h6 className='form-label text-soft fw-bolder'>
                                <i style={{
                                    fontSize: '1rem',
                                    margin: '0 .5rem .1rem 0',
                                    verticalAlign: 'bottom'
                                }}>
                                    <AiFillFile />
                                </i>
                                {props.envelope.nomeDocumento}
                                <span className="badge badge-secondary background-orange float-right">
                                    {nomearStatusEnvelope(props.envelope.status)}
                                </span>
                            </h6>
                            <p className="mb-0" style={{ fontSize: '.7rem' }}>
                                <b>Hash do envelope:</b> {props.envelope.hashEnvelope}
                            </p>
                        </div>
                        <div className="col-md-12 text-soft text-orange">
                            <p>
                                <b>Código:</b> {props.envelope.codigo}
                                <a className={'pdf-documento-baixar-tudo'} onClick={baixarTodos}> Baixar todos os arquivos <BsDownload /></a>
                                <span className='clear-fix'></span>
                            </p>
                        </div>
                    </div>
                    <hr style={{ opacity: 0.2 }} />
                    <div className="row">
                        <div className="col-12 mb-2">
                            <p className='form-label text-soft fw-bolder text-orange'>
                                <i style={{
                                    fontSize: '1rem',
                                    margin: '0 .5rem .1rem 0',
                                    verticalAlign: 'bottom'
                                }}>
                                    <BsPeopleFill />
                                </i>
                                Signatários
                            </p>
                        </div>

                        {!props.publico && (![-1, 3].includes(props.envelope.status)) &&
                            <SignatarioForm
                                envelope={props.envelope}
                                signatarioEditar={signatarioEditar}
                                adicionarSignatario={signatario => {
                                    props.setEnvelope({
                                        ...props.envelope,
                                        signatarios: [...props.envelope.signatarios, signatario].sort((a, b) => a.ordemAssinatura - b.ordemAssinatura)
                                    });
                                }}
                                setSignatarioEditar={(valor) => setSignatarioEditar(valor)} />}
                        <div className="col-12 mb-4 mt-4">
                            {props.publico ?
                                props.envelope.signatarios.sort(
                                    (a, b) => a.ordemAssinatura - b.ordemAssinatura
                                ).map(
                                    (signatario, index) => <SignatariosListItem key={index} style={props.itensStyle} signatario={signatario} carregando={false} publico={true} />) :
                                <SignatarioDragAndDrop
                                    envelope={props.envelope}
                                    signatarioEditar={signatarioEditar}
                                    removerSignatario={removerSignatario}
                                    setSignatarioEditar={(value) => setSignatarioEditar(value)}
                                ></SignatarioDragAndDrop>
                            }
                        </div>
                        {props.envelope.status === -2 && <>
                            <div className="col-12 mb-4 mt-3">
                                <div className="col-12">
                                    <div className="card-body background-orange-soft">
                                        <input type="checkbox" id="form-data-assinatura" className="form-check-input me-2" onChange={(e) => setEnvioPorOrdem(e.currentTarget.checked)} />
                                        As assinaturas devem ser realizadas na ordem acima.
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-4 mt-4">
                                <button disabled={props?.envelope?.signatarios?.length == 0} className="btn btn-orange pt-2 w-100" type="button" onClick={adicionaEnvelope}>Enviar para Assinatura</button>
                            </div>
                        </>}

                        {!props.publico && [1, 2].includes(props.envelope.status) && <>
                            <div className="col-12 mb-4 mt-4">
                                <button className="btn btn-orange pt-2 w-100" type="button" onClick={cancelarEnvelope}>Cancelar Envelope</button>
                            </div>
                        </>}
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </>;
}



export default CardEnvelopeDados;

