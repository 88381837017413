export enum EModuloNomeEnum {
    Default = '',

    Proposta = 'Propostas',
    Garantia = 'Garantia',
    Obrigação = 'Obrigação',
    Despesa = 'Despesa',
    Decisao = 'Decisão',
    Desdobramento = 'Desdobramento',
    PropostaAcordo = 'Propostas',
    Audiencia = 'Audiencia',
    Bloqueio = 'Bloqueio',
    Reembolso = 'XPay - Reembolso',
    Honorario = 'XPay - Honorário',
    Comunicacao = 'Comunicação',


    Compromisso = 'Compromisso',

    Contencioso = 'Contencioso',
    Consultivo = 'Consultivo',
    Processo = 'Processo',
    Contrato = "Contrato",
    Imovel = "Imobiliário",
    ImovelSemAcento = "Imobiliario",
    Procuracao = "Procuração",
    Regulatorio = "Regulatório"
}