/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from "react";
import { faPercent, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { TableColumn } from "react-data-table-component";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Modal } from "react-bootstrap";
import GridPadrao from "../../Comum/GridPadrao";
import Swal from "sweetalert2";
import clsx from "clsx";

import CentroCustoService from "../../../services/CentroCustoService";

import ICentroCusto from "../../../interfaces/ICentroCusto";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import FiltroPesquisaCentroCustoRequest from "../../../interfaces/Requests/CentroCusto/FiltroPesquisaCentroCustoRequest";

import { EModulo } from "../../../enum";
import CurrencyField from "../CurrencyBRL/CurrencyField";
import { notifySuccess } from "../Toast/ToastFormik";

interface ICentroCustoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  centroCustoSelected: ICentroCusto[];
  setFieldValue: (field: string, value: any, ok: boolean) => any;
  modulo: EModulo;
  tipoMarcaId?: number;
  tipoConsultaId?: number;
  tipoProcuracaoId?: number;
  tipoLicencaId?: number;
  areaDireitoId?: number;
  tipoContratoId?: number;
  tipoImovelId?: number;
  tipoDespesaId?: number
}

const CentroCustoField = ({
  toggleModal,
  exibirModal,
  centroCustoSelected,
  setFieldValue,
  modulo,
  tipoMarcaId,
  tipoProcuracaoId,
  tipoConsultaId,
  tipoLicencaId,
  areaDireitoId,
  tipoContratoId,
  tipoImovelId,
  tipoDespesaId
}: ICentroCustoFieldProps) => {
  const [centrosCusto, setCentrosCusto] = useState<ICentroCusto[]>([]);
  const [carregandoCentrosCusto, setCarregandoCentrosCusto] = useState(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaCentroCustoRequest>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 5,
    totalItems: 0,
    offset: 0,
    sort: "tipoCentroCustoId",
    modulo,
    tipoMarcaId,
    tipoProcuracaoId,
    tipoConsultaId,
    tipoLicencaId,
    areaDireitoId,
    tipoContratoId,
    tipoImovelId,
    tipoDespesaId
  });
  const [percentualTotal, setPercentualTotal] = useState<number>(0);

  useEffect(() => {
    if (centroCustoSelected.length > 0) {
      centrosCusto.forEach((centroCusto) => {
        const centroCustoChecked = centroCustoSelected.filter((e: ICentroCusto) => e.tipoCentroCustoId === centroCusto.tipoCentroCustoId)[0];
        if (centroCustoChecked) {
          centroCusto.percentual = centroCustoChecked.percentual;
          centroCusto.percentualMask = (centroCustoChecked.percentual * 100).toString();
          centroCusto.checked = true;
        }
      });
    }

    setPercentualTotal(centroCustoSelected.reduce((total, object) => { return total + object.percentual; }, 0));

    setCentrosCusto(centrosCusto);

  }, [centrosCusto, centroCustoSelected]);

  const adicionar = (tipoCentroCustoId: number) => {
    setFieldValue("tipoCentroCustoId", 0, true);
    centrosCusto.forEach((centroCusto) => {
      if (centroCusto.tipoCentroCustoId == tipoCentroCustoId) {
        if (!centroCusto.checked) {
          centroCusto.checked = true;
          setCentrosCusto(centrosCusto);
          setFieldValue(
            "centrosCusto",
            centrosCusto.filter((e: any) => e.checked),
            true
          );
          return;
        }
        if (centroCusto.checked) {
          centroCusto.percentual = 0;
          centroCusto.percentualMask = "";
          centroCusto.checked = false;
          setCentrosCusto(centrosCusto);
          setFieldValue(
            "centrosCusto",
            centrosCusto.filter((e: any) => e.checked),
            true
          );
          return;
        }
      }
    });
  };

  const carregarListaLimitadaCentroCusto = async (filtro: FiltroPesquisaCentroCustoRequest) => {
    try {
      setCarregandoCentrosCusto(true);

      let resultado: IRecuperaLista<ICentroCusto>;
      resultado = await CentroCustoService.obterListaLimitadaCentroCustos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setCentrosCusto(resultado.data);
    } catch (error: any) {
      setCentrosCusto([]);
    } finally {
      setCarregandoCentrosCusto(false);
    }
  };

  const colunas: TableColumn<ICentroCusto>[] = [
    {
      name: "Id",
      sortField: "tipoCentroCustoId",
      selector: (row: ICentroCusto) => row.tipoCentroCustoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: ICentroCusto) => row.codigo,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: ICentroCusto) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Informar percentual",
      cell: (row: ICentroCusto, index: number) => {
        return (
          <div>
            {row.checked &&
              <CurrencyField
                onValueChange={(values) => onChangePercentualValue(index, values)}
                floatValue={row.percentual} />
            }
          </div>
        );
      },
      ignoreRowClick: true,
    },
    {
      name: "Adicionar?",
      cell: (row: ICentroCusto) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => adicionar(row.tipoCentroCustoId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<ICentroCusto>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  useEffect(() => {
    if (exibirModal) {
      carregarListaLimitadaCentroCusto(filtrosPesquisa);
    }

    calcularTotal();

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort, exibirModal]);

  const onChangePercentualValue = (index: number, values: any) => {
    let percentual = parseFloat(values.formattedValue.replace("R$", "").replace(",", "."));
    if ((values.floatValue / 100) > 100) {
      centrosCusto[index].percentual = 0;
      centrosCusto[index].percentualMask = "";
    } else {
      centrosCusto[index].percentual = percentual;
      centrosCusto[index].percentualMask = values.formattedValue;
      calcularTotal();
    }
  }

  const calcularTotal = () => {
    let percentualTotal = 0;
    centrosCusto
      .filter((e: any) => e.checked)
      .forEach((centroCusto) => {
        percentualTotal += centroCusto.percentual ? centroCusto.percentual : 0;
      });

     if(percentualTotal == 100) notifySuccess('Percentual 100%');

    setPercentualTotal(percentualTotal);
  };

  const confirmar = () => {
    // 1. Caso a lista estiver vazia
    if (centroCustoSelected.length == 0) {
      toggleModal();
    }

    // 2. Caso a somatoria estiver diferente de 100%
    if (percentualTotal != 100) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `Percentual`,
        text: "A soma do percentual deve ser igual a 100%",
        showConfirmButton: true,
      });
      return;
    } else {
      // 3. Caso a somatoria estiver 100%
      toggleModal();
      return;
    }
  };

  const cancelar = () => {
    setFieldValue("centrosCusto", [], true);

    centrosCusto.forEach((centroCusto) => {
      centroCusto.percentual = 0;
      centroCusto.percentualMask = "";
      centroCusto.checked = false;
    });

    setPercentualTotal(0);

    toggleModal();
  }

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={confirmar}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Centro Custo <FontAwesomeIcon className="mx-2 text-orange" icon={faPercent} /> <ProgressBar variant="var(--primary-base2)" now={percentualTotal} />
            <a style={{ fontSize: "15px" }}>Total: {percentualTotal <= 100 && (percentualTotal).toFixed(2)}</a>
          </h5>
          <div onClick={() => confirmar()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-auto">
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto">
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto">
              <button onClick={() => carregarListaLimitadaCentroCusto(filtrosPesquisa)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>
          </div>
          <div className="col-md-auto mb-10">
            <GridPadrao onSort={handleSort} progressPending={carregandoCentrosCusto} limit={filtrosPesquisa.limit} onChangePage={handlePageChange} onChangeRowsPerPage={handlePerRowsChange} paginationServer={true} paginationTotalRows={filtrosPesquisa.totalItems} colunas={colunas} tipo='Tipo de Classe da Marca' itens={centrosCusto} />
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button disabled={percentualTotal != 100} onClick={() => confirmar()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CentroCustoField;