import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import Swal from "sweetalert2";

export default function ForgotPassword() {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Email incorreto").min(3, "Minimo 3 caracteres").max(50, "Máximo 50 caracteres").required("Email é obrigatório"),
  });

  const initialValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      try {
        await AuthService.resetPassword(values.email);
        setStep(2);
      } catch (error) {
        setStatus("Não foi possivel resetar a sua senha");
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const codigoSchema = Yup.object().shape({
    codigo: Yup.string().min(3, "Minimo 3 caracteres").max(100, "Máximo 100 caracteres").required("Código é obrigatório"),
  });

  const codigoInitialValues = {
    codigo: "",
  };

  const codigoFormik = useFormik({
    initialValues: codigoInitialValues,
    validationSchema: codigoSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        await AuthService.resetPasswordConfirmCode(formik.values.email, values.codigo);
        await Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Senha resetada com sucesso`,
          text: "Você irá receber sua nova senha no seu email",
          showConfirmButton: true,
        });
        navigate("/");
      } catch (error) {
        setStatus("Não foi possivel resetar a sua senha");
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    codigoFormik.validateForm();
    // codigoFormik.setStatus('Você irá receber em seu email o código para resetar sua senha');
    
  }, []);

  return (
    <>
      {step === 1 && (
        <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
          {formik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* begin::Form group */}
          <div className="fv-row mb-3">
            <label className="form-label fs-6 fw-bolder text-orange">Email</label>
            <input
              placeholder="Email"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                { "is-invalid": formik.touched.email && formik.errors.email },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
              type="email"
              name="email"
              autoComplete="off"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>

          {/* begin::Action */}
          <div className="text-center">
            <button type="submit" className="btn btn-lg btn-orange w-100 mt-5" disabled={formik.isSubmitting || !formik.isValid}>
              {!loading && <span className="indicator-label">Continuar</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  Aguarde...
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      )}

      {step === 2 && (
        <form className="form w-100" onSubmit={codigoFormik.handleSubmit} noValidate id="kt_login_signin_form">
          {codigoFormik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{codigoFormik.status}</div>
            </div>
          )}

          <div className="mb-lg-15 alert alert-success">
            <div className="alert-text font-weight-bold">Você irá receber em seu email o código para resetar sua senha</div>
          </div>

          <div className="fv-row mb-10">
            <div className="d-flex justify-content-between mt-n5">
              <div className="d-flex flex-stack mb-2">
                <label className="form-label fw-bolder text-orange fs-6 mb-0">Código de Validação</label>
              </div>
            </div>
            <input
              placeholder="Código de Validação"
              type="text"
              autoComplete="off"
              {...codigoFormik.getFieldProps("codigo")}
              className={clsx(
                "form-control form-control-lg",
                {
                  "is-invalid": codigoFormik.touched.codigo && codigoFormik.errors.codigo,
                },
                {
                  "is-valid": codigoFormik.touched.codigo && !codigoFormik.errors.codigo,
                }
              )}
            />
            {codigoFormik.touched.codigo && codigoFormik.errors.codigo && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{codigoFormik.errors.codigo}</span>
                </div>
              </div>
            )}
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-lg btn-orange w-100 mt-5" disabled={codigoFormik.isSubmitting || !codigoFormik.isValid}>
              {!loading && <span className="indicator-label">Recuperar Senha</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  Aguarde...
                </span>
              )}
            </button>
          </div>
        </form>
      )}
    </>
  );
}
