import {
  faAddressCard,
  faCheckCircle,
  faCog,
  faEraser,
  faPen,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ICliente from "../../interfaces/ICliente";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaClienteRequest from "../../interfaces/Requests/Cliente/FiltroPesquisaClienteRequest";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import ClienteService from "../../services/ClienteService";
import ClienteDetalheModal from "./ClienteDetalheModal";
import ClienteModal from "./ClienteModal";
import ClienteConfigSegurancaModal from "./ClienteConfigSegurancaModal";
import GridUsuarios from "../Admininstracao/Seguranca/GrupoUsuarios/components/GridUsuarios";

const clienteInicial = {
  clienteId: 0,
  pessoaId: 0,
  codigo: "",
  urlLogo: "",
  clienteNome: "",
  clienteNomeSocial: "",
  usuarioTipo: "",
  areasDireito: [],
  atualizaAreas: true,
  atualizaEscritorio: true,
  clienteDocumento: "",
  dataCadastro: "",
  escritorios: [],
  status: "",
};

const ClientePage = () => {
  const [cliente, setCliente] = useState<ICliente>(clienteInicial);
  const [clientes, setClientes] = useState<ICliente[]>([]);
  const [carregandoClientes, setCarregandoClientes] = useState(true);
  const [reset, setReset] = useState(false);
  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [exibirDetalheModal, setExibirDetalheModal] = useState<boolean>(false);
  const [exibirConfigSeguranca, setExibirConfigSeguranca] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaClienteRequest>({
      clienteId: 0,
      pessoaId: 0,
      clienteNome: "",
      clienteDocumento: "",
      clienteTipo: "",
      codigo: "",
      status: 0,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "clienteId",
    });

  const toggleConfigSecuranca = () =>
    setExibirConfigSeguranca(!exibirConfigSeguranca);

  const toggleFormModal = (carregarDados?: boolean) => {
    if (carregarDados) {
      carregarClientes(filtrosPesquisa);
    }

    setExibirFormModal(!exibirFormModal);

    setTimeout(() => {
      setCliente(clienteInicial);
    }, 300);
  };

  const toggleDetalheModal = (carregarDados?: boolean) => {
    if (carregarDados) {
      carregarClientes(filtrosPesquisa);
    }

    setExibirDetalheModal(!exibirDetalheModal);

    setTimeout(() => {
      setCliente(clienteInicial);
    }, 300);
  };

  async function carregarClientes(filtro: FiltroPesquisaClienteRequest) {
    try {
      setCarregandoClientes(true);
      let resultado: IRecuperaLista<ICliente>;

      resultado = await ClienteService.obterClientes(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setClientes(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setClientes([]);
    } finally {
      setCarregandoClientes(false);
    }
  }

  async function alterarStatus(clienteId: number, status: number) {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await ClienteService.alterarStatus(clienteId, status);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status do Cliente alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();

        setFiltrosPesquisa((oldState) => {
          carregarClientes(oldState);
          return oldState;
        });
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o Status do Cliente`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  }

  const renderCell = (cliente: ICliente) => {
    return (
      <>
        <div style={{ paddingRight: "10px" }}>
          <FontAwesomeIcon
            title="Editar Cliente"
            onClick={() => {
              setCliente(cliente);
              setExibirFormModal(true);
            }}
            style={{ fontWeight: "normal", cursor: "pointer" }}
            size="2x"
            className="mx-1 text-orange"
            icon={faPen}
          />
        </div>
        <div style={{ paddingRight: "10px" }}>
          {cliente.status === "Ativo" ? (
            <FontAwesomeIcon
              title="Desativar Cliente"
              onClick={() => {
                alterarStatus(cliente.clienteId, -1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faTrashAlt}
            />
          ) : (
            <FontAwesomeIcon
              title="Ativar Usuário"
              onClick={() => {
                alterarStatus(cliente.clienteId, 1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faCheckCircle}
            />
          )}
        </div>

        <div style={{ paddingRight: "10px" }}>
          <FontAwesomeIcon
            title="Detalhes Cliente"
            onClick={() => {
              setCliente(cliente);
              setExibirDetalheModal(true);
            }}
            style={{ fontWeight: "normal", cursor: "pointer" }}
            size="2x"
            className="mx-1 text-orange"
            icon={faAddressCard}
          />
        </div>

        <div style={{ paddingRight: "10px" }}>
          <FontAwesomeIcon
            title="Config.Segurança"
            onClick={() => {
              setCliente(cliente);
              setExibirConfigSeguranca(true);
            }}
            style={{ fontWeight: "normal", cursor: "pointer" }}
            size="2x"
            className="mx-1 text-orange"
            icon={faCog}
          />
        </div>
      </>
    );
  };

  const colunas: TableColumn<ICliente>[] = [
    {
      name: "Id",
      sortField: "clienteId",
      selector: (row: ICliente) => row.clienteId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "clienteNome",
      selector: (row: ICliente) => row.clienteNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome Social",
      sortField: "clienteNomeSocial",
      selector: (row: ICliente) => row.clienteNomeSocial,
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      sortField: "clienteDocumento",
      selector: (row: ICliente) => row.clienteDocumento,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: ICliente) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: renderCell,
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<ICliente>,
    sortDirection: string
  ) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  async function limparFiltros() {
    setReset(!reset);
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        clienteNome: "",
        clienteDocumento: "",
        codigo: "",
        status: 1,
      };
    });

    await carregarClientes({
      ...filtrosPesquisa,
      clienteNome: "",
      clienteDocumento: "",
      codigo: "",
      status: 1,
    });
  }

  useEffect(() => {
    carregarClientes(filtrosPesquisa);
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageTitle title="Cliente">
        <button
          onClick={() => {
            navigate("/Cliente/Cadastrar");
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <ClienteModal
        exibirModal={exibirFormModal}
        titulo={cliente ? "Editar Registro" : "Criar Registro"}
        toggleModal={toggleFormModal}
        Cliente={cliente}
      />

      <ClienteConfigSegurancaModal
        exibirModal={exibirConfigSeguranca}
        titulo={"Configuração de Segurança"}
        toggleModal={toggleConfigSecuranca}
        cliente={cliente}
      />

      <ClienteDetalheModal
        exibirModal={exibirDetalheModal}
        titulo={"Detalhes do Cliente"}
        toggleDetalhesModal={toggleDetalheModal}
        Cliente={cliente}
      />
      <div className="row mt-1">
        <div className="col-md-12">
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setReset(!reset);
              await carregarClientes(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2 ">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Nome
              </label>
              <input
                value={filtrosPesquisa.clienteNome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, clienteNome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>

            <div className="col-md-2">
              <label
                htmlFor="form-document"
                className="form-label fw-bolder text-orange"
              >
                Documento
              </label>
              <input
                value={filtrosPesquisa.clienteDocumento}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, clienteDocumento: e.target.value };
                  });
                }}
                placeholder="Documento"
                type="text"
                className={"form-control"}
                id="form-document"
              />
            </div>

            <div className="col-md-2">
              <label
                htmlFor="form-codigo"
                className="form-label fw-bolder text-orange"
              >
                Código
              </label>
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={"form-control"}
                id="form-codigo"
              />
            </div>

            <div className="col-md-2">
              <label
                htmlFor="form-status"
                className="form-label fw-bolder text-orange"
              >
                Status
              </label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, status: parseInt(e.target.value) };
                  });
                }}
                placeholder="Nome"
                className={"form-select"}
                id="form-select"
              >
                <option value="0" label="Todos">
                  {" "}
                  Todos{" "}
                </option>
                <option value="1" label="Ativo">
                  {" "}
                  Ativo{" "}
                </option>
                <option value="-1" label="Inativo">
                  {" "}
                  Inativo{" "}
                </option>
              </select>
            </div>

            <div className="col-md-2">
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin "
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10">
          <GridUsuarios
            pagination
            reset={reset}
            onSort={handleSort}
            progressPending={carregandoClientes}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Cliente"
            itens={clientes}
          />
        </div>
      </div>
    </>
  );
};

export default ClientePage;
