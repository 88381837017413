import styled, { css } from "styled-components";

export const RecorrenciaSelectButton = styled.button`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  min-width: 200px;
  position: relative;

  div.recorrencia-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.arrow-icon {
    position: absolute;
    right: 10px;
  }
`;

export const RecorrenciaOptions = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 200px;
  gap: 4px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px;
  border-radius: 0 0 4px 4px;
  margin-top: 2px;
  position: absolute;
`;

export const RecorrenciaOption = styled.button<{ selected: boolean }>`
  padding: 5px 10px;
  border: none;
  display: flex;
  background: ${(props) => (props.selected ? "#B4D5FF" : "transparent")};

  ${(props) =>
    !props.selected &&
    css`
      &:hover {
        background: #eee;
      }
    `}
`;