import React, { createContext, useCallback, useState } from "react";

export const DrawerContext = createContext(
  {} as {
    open: boolean;
    toggle: (value?: boolean) => void;
    handleOutsideClick: (event: MouseEvent, ref?: HTMLDivElement | null) => void;
  }
);

export function DrawerProvider({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false);

  function toggle(value = false) {
    setOpen(value ? value : (state) => !state);
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent, ref?: HTMLDivElement | null) => {
      if (ref && !ref.contains(event.target as Node)) {
        setOpen(false);
      }
    },
    []
  );

  return (
    <DrawerContext.Provider value={{ open, toggle, handleOutsideClick }}>
      {children}
    </DrawerContext.Provider>
  );
}
