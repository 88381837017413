import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IAreaDireito from "../interfaces/IAreaDireito";
import { AdicionaAreaDireitoRequest } from "../interfaces/Requests/AreaDireito/AdicionaAreaDireitoRequest";
import FiltroPesquisaAreaDireitoRequest from "../interfaces/Requests/AreaDireito/FiltroPesquisaAreaDireitoRequest";
import { EditarAreaDireitoRequest } from "../interfaces/Requests/AreaDireito/EditarAreaDireitoRequest";
import { EModulo } from "../enum";

class AreaDireitoService {

    async obterAreaDireitos(filtros: FiltroPesquisaAreaDireitoRequest): Promise<IRecuperaLista<IAreaDireito>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IAreaDireito>>(`AreaDireito/RecuperaAreaDireito?${query}`);

        return result;
    }

    async adicionaAreaDireito(adicionaAreaDireito: AdicionaAreaDireitoRequest): Promise<IAreaDireito> {
        let { data: result } = await api.post<IAreaDireito>(`AreaDireito/AdicionaAreaDireito`, adicionaAreaDireito);

        return result;
    }


    async alterarStatus(areaDireitoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IAreaDireito>(`AreaDireito/AtualizaAreaDireitoPorId?id=${areaDireitoId}`, data);
    }

    async atualizarAreaDireito(editarAreaDireito: EditarAreaDireitoRequest): Promise<void> {

        let dataAreaDireito = [
            { "op": "replace", "path": "/nome", "value": editarAreaDireito.nome },
            { "op": "replace", "path": "/codigo", "value": editarAreaDireito.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`AreaDireito/AtualizaAreaDireitoPorId?id=${editarAreaDireito.areaDireitoId}`, dataAreaDireito)
        ]);
    }

    async obterListaLimitada(ClienteId: number | null = null): Promise<IRecuperaLista<IAreaDireito>> {
        let { data: result } = await api.get<IRecuperaLista<IAreaDireito>>(`/AreaDireito/RecuperaListaLimitada`, {
            params: {
                ClienteId,
                modulo: EModulo.AreaDireito
            }
        })

        return result;
    }

}


export default new AreaDireitoService();