import { useMutation, useQuery } from "react-query";
import { KanbanQuery } from "../queries";
import {
  IConsultaKanban,
  IConsultaKanbanCard,
  IConsultantKanbanFilter,
} from "../../../interfaces/IConsultaKanban";
import { useState } from "react";
import { IChangeIndex, IMoveItem } from "../interfaces/useKanban";
import ConsultaService from "../../../services/ConsultaService";
import { toast } from "react-toastify";
import { handleApiError } from "../../../utils/Utils";

export function useKanban(filters?: Partial<IConsultantKanbanFilter>) {
  const [items, setItems] = useState<IConsultaKanban | null>(null);

  const { mutateAsync: changeStatusFn, isLoading: isStatusChanging } = useMutation({
    mutationFn: ConsultaService.updateConsultaStatus,
    onError: (error: Error) => {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage, { position: "bottom-right" });
    },
    onSuccess: () => {
      toast.success("Item atualizado com sucesso!", {
        position: "bottom-right",
      });
    },
  });

  const { error, isLoading } = useQuery({
    queryKey: [KanbanQuery.list],
    onSuccess: setItems,
    queryFn: () =>
      ConsultaService.obterConsultasKanban({
        ...filters,
        offset: filters?.offset ?? 0,
        limit: filters?.limit ?? 10,
        sort: filters?.sort ?? "consultaId",
      }),
  });

  function move({ position, status }: IMoveItem) {
    let removed: IConsultaKanbanCard | null = null;

    setItems((state) => {
      if (!state) {
        return null;
      }

      const updatedItems = state.cards.map((item) => {
        if (item.agrupadorOpcaoNome === status.from) {
          [removed] = item.data.splice(position.from, 1);
        }
        return item;
      });

      const cards = updatedItems.map((item) => {
        if (item.agrupadorOpcaoNome === status.to && removed) {
          item.data.splice(position.to, 0, removed);
        }
        return item;
      });

      return {
        ...state,
        cards,
      };
    });
  }

  function change({ position, status }: IChangeIndex) {
    setItems((state) => {
      if (!state) {
        return null;
      }

      return {
        ...state,
        cards: state?.cards.map((mock) => {
          if (mock.agrupadorOpcaoNome === status) {
            const [element] = mock.data.splice(position.from, 1);
            mock.data.splice(position.to, 0, element);
            return mock;
          }
          return mock;
        }),
      };
    });
  }

  async function changeStatus(params: {
    consultaId: number;
    newStatus: number;
  }) {
    await changeStatusFn(params);
  }

  return {
    items,
    change,
    move,
    changeStatus,
    error,
    isLoading,
    isStatusChanging
  };
}
