import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IDepartamento from "../interfaces/IDepartamento";
import { AdicionaDepartamentoRequest } from "../interfaces/Requests/Departamento/AdicionaDepartamentoRequest";
import FiltroPesquisaDepartamentoRequest from "../interfaces/Requests/Departamento/FiltroPesquisaDepartamentoRequest";
import { EditarDepartamentoRequest } from "../interfaces/Requests/Departamento/EditarDepartamentoRequest";

class DepartamentoService {

    async obterDepartamentos(filtros: FiltroPesquisaDepartamentoRequest): Promise<IRecuperaLista<IDepartamento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IDepartamento>>(`Departamento/RecuperaDepartamento?${query}`);

        return result;
    }

    async adicionaDepartamento(adicionaDepartamento: AdicionaDepartamentoRequest): Promise<IDepartamento> {
        let { data: result } = await api.post<IDepartamento>(`Departamento/AdicionaDepartamento`, adicionaDepartamento);

        return result;
    }


    async alterarStatus(departamentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IDepartamento>(`Departamento/AtualizaDepartamentoPorId?id=${departamentoId}`, data);
    }

    async atualizarDepartamento(editarDepartamento: EditarDepartamentoRequest): Promise<void> {

        let dataDepartamento = [
            { "op": "replace", "path": "/nome", "value": editarDepartamento.nome },
            { "op": "replace", "path": "/codigo", "value": editarDepartamento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Departamento/AtualizaDepartamentoPorId?id=${editarDepartamento.departamentoId}`, dataDepartamento)
        ]);
    }
}


export default new DepartamentoService();