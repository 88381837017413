/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./Api";

import { tipoPorModulo } from "../utils/Utils";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoSeparadors from "../interfaces/ITipoSeparadores";
import { EModulo, ETelaEnum } from "../enum";
import { IRecuperaSeparadorTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import IFiltroSeparadorTelaRequest from "../interfaces/Requests/ConfiguracaoTela/IFiltroSeparadorTelaRequest";

class ConfiguracaoSeparadorService {

  async adicionar(separadors: ITipoSeparadors): Promise<IRecuperaLista<any>> {

    let { data: result } = await api.post<IRecuperaLista<any>>(`ConfiguracaoTela/AdicionaConfiguracaoTela`, separadors);

    return result;
  }

  async obterTodo(): Promise<IRecuperaLista<any>> {

    let { data: result } = await api.get<IRecuperaLista<any>>(`Separador/RecuperaSeparador?status=1`);

    return result;
  }
  async obterDoing(tipoSeparador: ITipoSeparadors, tela: ETelaEnum): Promise<IRecuperaLista<any>> {
    const categoriaId =
      tipoSeparador.areaDireitoId ||
      tipoSeparador.tipoMarcaId ||
      tipoSeparador.tipoConsultaId ||
      tipoSeparador.tipoAtoId ||
      tipoSeparador.tipoContratoId ||
      tipoSeparador.tipoImovelId ||
      tipoSeparador.tipoLicencaId ||
      tipoSeparador.tipoSociedadeId ||
      tipoSeparador.tipoProcuracaoId;

    let query = `ConfiguracaoTela/RecuperaConfiguracaoTela?modulo=${tipoSeparador.modulo}&tela=${tela}&status=1`;

    if (categoriaId) {
      query += `&${tipoPorModulo(tipoSeparador.modulo)}Id=${categoriaId}`;
    }

    const { data: result } = await api.get<IRecuperaLista<any>>(query);

    return result;
  }


  async obterCapa(tipoSeparador: IFiltroSeparadorTelaRequest): Promise<IRecuperaLista<IRecuperaSeparadorTelaResponse>> {

    let categoriaId: any;

    if (tipoSeparador.areaDireitoId) categoriaId = tipoSeparador.areaDireitoId;
    if (tipoSeparador.tipoMarcaId) categoriaId = tipoSeparador.tipoMarcaId;
    if (tipoSeparador.tipoConsultaId) categoriaId = tipoSeparador.tipoConsultaId;
    if (tipoSeparador.tipoAtoId) categoriaId = tipoSeparador.tipoAtoId;
    if (tipoSeparador.tipoContratoId) categoriaId = tipoSeparador.tipoContratoId;
    if (tipoSeparador.tipoImovelId) categoriaId = tipoSeparador.tipoImovelId;
    if (tipoSeparador.tipoLicencaId) categoriaId = tipoSeparador.tipoLicencaId;
    if (tipoSeparador.tipoSociedadeId) categoriaId = tipoSeparador.tipoSociedadeId;
    if (tipoSeparador.tipoProcuracaoId) categoriaId = tipoSeparador.tipoProcuracaoId;

    let { data: result } = await api.get<IRecuperaLista<IRecuperaSeparadorTelaResponse>>(`ConfiguracaoTela/RecuperaSeparadorCapa?modulo=${tipoSeparador.modulo}&tela=${ETelaEnum.TelaSeparadorCapa}&status=1&${tipoPorModulo(tipoSeparador.modulo)}Id=${categoriaId}`);

    return result;
  }
  async obterFiltro(modulo: EModulo, tela: ETelaEnum): Promise<IRecuperaLista<IRecuperaSeparadorTelaResponse>> {

    let { data: result } = await api.get<IRecuperaLista<IRecuperaSeparadorTelaResponse>>(`ConfiguracaoTela/RecuperaSeparadorCapa?modulo=${modulo}&tela=${tela}&status=1`);

    return result;
  }
}

export default new ConfiguracaoSeparadorService();
