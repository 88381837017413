import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import { faPrint, faHistory, faShareAlt } from "@fortawesome/free-solid-svg-icons";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaImovelRequest } from "../../interfaces/Requests/Imovel/IAdicionaImovelRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaSeparadorTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../enum";
import { ECamposIDEnum, ESeparadorIDEnum } from "../../enum/ECamposIDEnum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import ImovelService from "../../services/ImovelService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import { OrangeContext } from "../../contexts/OrangeProvider";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";

import { defaultSchema } from "../../utils/SchemasUtil";
import {
  CoordinatesResponseStatusEnum,
  GetCoordinatesResponse,
  LatLng,
} from "../../components/Comum/Map/contract/MapContract";


import Carregando from "../../components/Carregando";
import { GoogleMap } from "../../components/Comum/Map/GoogleMap";
import { notifyDanger } from "../../components/Comum/Toast/ToastFormik";
import { googleMapsApi } from "../../components/Comum/Map/services/api";
import CollapseDefault from "../../components/Collapse/CollapseDefault";
import CompartilharModal from "../../components/Comum/Share/CompartilharModal";
import AndamentoListField from "../../components/Comum/Andamento/AndamentoListField";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";
import CollapseCapaDinanimos from "../../components/Comum/Modulo/CollapseCapaDinanimos";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import ComunicacaoListField from "../../components/Comum/Comunicacao/ComunicacaoListField";

const ImovelCapaPage = () => {
  const { imovel, tipoImovel, setImovel } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [refreshCapa, setRefreshCapa] = useState(true);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [mapCenter, setMapCenter] = useState<LatLng>({
    lat: 0,
    lng: 0,
  });

  const [exibirModalShare, setExibirModalShare] = useState<boolean>(false);

  const [mapError, setMapError] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [showMap, setShowMap] = useState(false);

  const componentRef = useRef<any>();
  const compartilhar = () => `Nome_${imovel.nomeImovel}_${tipoImovel.tipoImovelId > 0 ? "_Tipo_de_Imovel_" + tipoImovel.nome : ""}_Status_${imovel.statusImovelNome == "" ? "_Ativo_" : nomearStatusImovel(imovel.statusImovelNome)}`;
  const getConfiguracaoTelaFieldValue = (field: string) => { return configuracoesTela.filter((config) => config.campoNome === field)[0]?.valor; };
  const toggleShare = () => setExibirModalShare(!exibirModalShare);
  const inserirSessionStorage = () => sessionStorageService.inserir(imovel, "imovel_capa");
  const carregarSessionStorage = async () => setImovel(JSON.parse((await sessionStorageService.obter("imovel_capa")) || null));

  const nomearStatusImovel = (categoria: string) => {
    switch (categoria) {
      case "EmElaboracao":
        return "Em Elaboração";
      case "EmAprovacao":
        return "Em Aprovação";
      case "EmAssinatura":
        return "Em Assinatura";
      case "Assinado":
        return "Assinado";
      case "Cancelado":
        return "Cancelado";
      default:
        return "Não definido";
    }
  };

  const initialValues: IAdicionaImovelRequest = {
    imovelId: imovel.imovelId,
    principalId: imovel.imovelId,
    tipoImovelId: imovel.tipoImovelId,
    categoriaId: imovel.tipoImovelId,
    modulo: EModulo.Imovel,
    tela: ETelaEnum.TelaCapaImovel,

    cidadeId: 0,
    departamentoId: 0,
    indiceId: 0,
    tipoaquisicaoId: 0,
    tiporelacionamentoId: 0,
    imovelprincipalId: 0,
    tipolocalidadeId: 0,
    restrito: false,
    observacoesimovel: "",
    nomeimovel: "",
    logradouro: "",
    endereco: "",
    numeroendereco: "",
    complemento: "",
    bairro: "",
    cep: "",

    dadosmatricula: "",
    areaconstruida: "",
    metragemterreno: "",
    datainiciovigencia: null,
    datafimvigencia: null,
    dataaquisicao: null,
    datareajuste: null,
    dataavaliacao: null,
    dataconsolidicacao: null,
    dataregistromatricula: null,

    valoraluguel: 0,
    valorcondominio: 0,
    valoriptu: 0,
    valoravaliacao: 0,
    valorcontabil: 0,
    valorvendaforcada: 0,

    codigo: "",
    avaliador: "",

    empresasList: [],
    partesContrariasList: [],
    advogadoInternoList: [],
    centrosCusto: [],
    xCampoValorList: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!imovel.imovelId) return;

    carregarImovel();

    carregarSeparadoresTela();

    formik.setValues(initialValues);

  }, [imovel.imovelId, refreshCapa]);

  useEffect(() => {
    if (!imovel.imovelId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [imovel]);

  useEffect(() => {
    async function loadMapCenter(): Promise<void> {
      try {
        const addressFields = [
          getConfiguracaoTelaFieldValue(ECamposIDEnum.NumeroEndereco),
          getConfiguracaoTelaFieldValue(ECamposIDEnum.Bairro),
          getConfiguracaoTelaFieldValue(ECamposIDEnum.CEP),
        ];

        let address = getConfiguracaoTelaFieldValue(ECamposIDEnum.Endereco);

        addressFields.forEach((field) => {
          if (field) {
            address += ` ${field}`;
          }
        });

        const api = await googleMapsApi();
        const response = await api.get<GetCoordinatesResponse>("geocode/json", {
          params: { address },
        });

        const { status } = response.data;

        if (status === CoordinatesResponseStatusEnum.ZERO_RESULTS) {
          setMapError(true);
          return;
        }

        setMapCenter(response.data.results[0].geometry.location);
        setFormattedAddress(response.data.results[0].formatted_address);
      } catch (error) {
        setMapError(true);
      }
    }

    if (configuracoesTela.length) {
      loadMapCenter();
    }
  }, [configuracoesTela, showMap]);

  const carregarImovel = async () => {
    try {
      if (!imovel.imovelId) return;
      let resultado: IRecuperaLista<IImovel>;
      resultado = await ImovelService.obterImovelPorId(imovel.imovelId);
      setImovel(resultado.data[0]);
    } catch (error: any) {
      notifyDanger(error?.response?.data?.Message);
    }
  };


  const carregarSeparadoresTela = async () => {

    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;
      resultado = await ConfiguracaoSeparadorService.obterCapa({
        modulo: EModulo.Imovel,
        tipoImovelId: formik.values.tipoImovelId,
      });

      setSeparadoresTela(resultado.data);

    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (imovelId: number) => {
    try {
      let resultado = await ImovelService.adicionaFavorido(imovelId);
      setImovel({ ...imovel, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      notifyDanger(error?.response?.data?.Message);
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ImovelService.removerFavorido(favoritoId);
      setImovel({ ...imovel, favoritoId: 0 });
    } catch (error: any) {
      notifyDanger(error?.response?.data?.Message);
    }
  };



  const renderSeparadores = () => {

    const components = separadoresTela.map((separadorTela, index) => {
      switch (separadorTela.codigo) {

        case ESeparadorIDEnum.ExibirAndamento:
          return <AndamentoListField key={index.toString()} modulo={EModulo.Imovel} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirCompromisso:
          return <CompromissoListField key={index.toString()} modulo={EModulo.Imovel} setRefreshCapa={setRefreshCapa} configuracaoTela={separadorTela}/>;

        case ESeparadorIDEnum.ExibirComunicacao:
          return <ComunicacaoListField key={index.toString()} modulo={EModulo.Imovel} configuracaoTela={separadorTela}/>;

        default:
          return (
            <CollapseCapaDinanimos
              key={index.toString()}
              formik={formik}
              controller={"imovel"}
              setRefreshCapa={setRefreshCapa}
              separadorTela={separadorTela}
              setConfiguracoesTela={setConfiguracoesTela}
              configuracoesTela={configuracoesTela}
              separadorTelaLista={separadoresTela}
              setSeparadorTelaLista={setSeparadoresTela}
            />
          );
      }
    });

    return components;
  };

  return (
    <>
      <ToastContainer />

      <CompartilharModal
        texto={compartilhar()}
        exibirModal={exibirModalShare}
        toggleModal={toggleShare}
      />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${imovel.imovelId}  ${tipoImovel.tipoImovelId > 0 ? " - " + tipoImovel.nome : ""
            } => ${imovel.statusImovelNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a
                style={{ cursor: "pointer", fontSize: "30px" }}
                onClick={() => setRefreshCapa(!refreshCapa)}
              >
                {
                  <FontAwesomeIcon
                    color={"#DC853D"}
                    className="mx-2"
                    icon={faHistory}
                  />
                }
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span
                className="indicator-progress"
                style={{
                  display: "block",
                  fontSize: "30px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faPrint}
                  title="Imprimir o imovel"
                />
              </a>
            )}
            content={() => componentRef.current}
          />

          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            onClick={() => toggleShare()}
            title="Compartilhar"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShareAlt} />}
          </a>

          {imovel.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(imovel.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o imovel"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(imovel.imovelId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o imovel"
            ></a>
          )}

          <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="bottom"
            overlay={PopoverSubMenuImovel()}
          >
            <a
              style={{ cursor: "pointer", marginRight: "10px" }}
              className="navigation-services-list-link"
            >
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageButton>
        <hr />
        <br />
        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderSeparadores()}
            <FileListCapaField modulo={EModulo.Imovel} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />

            {configuracoesTela.length > 0 && (
              <>
                <CollapseDefault
                  carregando={false}
                  exibir={showMap}
                  modulo={EModulo.Default}
                  setExibir={setShowMap}
                  titulo="Localização"
                  content={
                    <Fragment>
                      <GoogleMap center={mapCenter} formattedAddress={formattedAddress} />
                    </Fragment>} />
                <hr />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ImovelCapaPage;
