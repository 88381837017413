import api from "./Api";
import IEncerramento from "../interfaces/IEncerramento";

class EncerramentoService {

    async EncerraProcesso(EncerraProcesso: IEncerramento): Promise<IEncerramento> {
        let { data: result } = await api.put<IEncerramento>(`/Processo/EncerraProcesso`, EncerraProcesso);

        return result;
    }

    async EncerraConsulta(EncerraConsulta: IEncerramento): Promise<IEncerramento> {
        let { data: result } = await api.put<IEncerramento>(`/Consulta/EncerraConsulta`, EncerraConsulta);

        return result;
    }

}


export default new EncerramentoService();