import api from "./Api";
import moment from "moment";

import { EModulo, ETipoCampoEnum } from "../enum";
import { EModuloNomeEnum } from "../enum/EModuloNomeEnum";

import CamposService from "./CamposService";

import IOption from "../interfaces/IOption";
import IAdvogado from "../interfaces/IAdvogado";
import IEmpresa from "../interfaces/IEmpresa";
import ICentroCusto from "../interfaces/ICentroCusto";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IParteContraria from "../interfaces/IParteContraria";
import IModeloRelatorio from "../interfaces/IModeloRelatorio";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import IProcesso, { IProcessoEstatisticas } from "../interfaces/IProcesso";
import { IUpdatePartesList } from "../interfaces/Requests/Processo/IUpdatePartesList";
import IAdicionaProcessoRequest from "../interfaces/Requests/Processo/IAdicionaProcessoRequest";
import { FiltroPesquisaProcessoRequest } from "../interfaces/Requests/Processo/FiltroPesquisaProcessoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class ProcessoService {

  public obterProcessosv2 = async (filtros: any): Promise<IRecuperaLista<IProcesso>> => {

    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query += this.montarXCampov2(filtros.xCampoValores);

    query += this.montarTextosv2(filtros);

    query += this.montarListasv2(filtros);

    query += this.montarDatasv2(filtros);

    const { data: result } = await api.get<IRecuperaLista<IProcesso>>(`/Processo/RecuperaListagemProcesso?${query}`);

    return result;
  }

  async gerarExcelv2(filtros: any): Promise<Blob> {

    let processoFilter: any = {};

    // Xcampo
    if (filtros.xCampoValores.length > 0) processoFilter.xCampoValores = filtros.xCampoValores;

    // String properties
    if (filtros.processoid) processoFilter.processoid = [filtros.processoid];
    if (filtros.pasta) processoFilter.pasta = filtros.pasta;
    if (filtros.resumo) processoFilter.resumo = filtros.resumo;
    if (filtros.observacoes) processoFilter.observacoes = filtros.observacoes;
    if (filtros.partecontraria) processoFilter.partecontraria = filtros.partecontraria;
    if (filtros.numeroprocesso) processoFilter.numeroprocesso = filtros.numeroprocesso;
    if (filtros.descricaoobjetoacao) processoFilter.descricaoobjetoacao = filtros.descricaoobjetoacao;
    if (filtros.numerodesdobramento) processoFilter.numerodesdobramento = filtros.numerodesdobramento;

    // Number properties
    if (filtros.valorcausamaiorigual) processoFilter.valorcausamaiorigual = filtros.valorcausamaiorigual;
    if (filtros.valorcausamenorigual) processoFilter.valorcausamenorigual = filtros.valorcausamenorigual;

    // Date properties
    if (filtros.datadistribuicaomaiorigual) processoFilter.datadistribuicaomaiorigual = filtros.datadistribuicaomaiorigual;
    if (filtros.datadistribuicaomenorigual) processoFilter.datadistribuicaomenorigual = filtros.datadistribuicaomenorigual;
    if (filtros.datarecebimentomaiorigual) processoFilter.datarecebimentomaiorigual = filtros.datarecebimentomaiorigual;
    if (filtros.datarecebimentomenorigual) processoFilter.datarecebimentomenorigual = filtros.datarecebimentomenorigual;
    if (filtros.datacadastromaiorigual) processoFilter.datacadastromaiorigual = filtros.datacadastromaiorigual;
    if (filtros.datacadastromenorigual) processoFilter.datacadastromenorigual = filtros.datacadastromenorigual;
    if (filtros.prazofatalmaiorigual) processoFilter.prazofatalmaiorigual = filtros.prazofatalmaiorigual;
    if (filtros.prazofatalmenorigual) processoFilter.prazofatalmenorigual = filtros.prazofatalmenorigual;
    if (filtros.dataultimaatualizacaomaiorigual) processoFilter.dataultimaatualizacaomaiorigual = filtros.dataultimaatualizacaomaiorigual;
    if (filtros.dataultimaatualizacaomenorigual) processoFilter.dataultimaatualizacaomenorigual = filtros.dataultimaatualizacaomenorigual;
    if (filtros.dataencerramentomaiorigual) processoFilter.dataencerramentomaiorigual = filtros.dataencerramentomaiorigual;
    if (filtros.dataencerramentomenorigual) processoFilter.dataencerramentomenorigual = filtros.dataencerramentomenorigual;
    if (filtros.dataencerramentocadastromaiorigual) processoFilter.dataencerramentocadastromaiorigual = filtros.dataencerramentocadastromaiorigual;
    if (filtros.dataencerramentocadastromenorigual) processoFilter.dataencerramentocadastromenorigual = filtros.dataencerramentocadastromenorigual;
    if (filtros.databaixaprovisoriamaiorigual) processoFilter.databaixaprovisoriamaiorigual = filtros.databaixaprovisoriamaiorigual;
    if (filtros.databaixaprovisoriamenorigual) processoFilter.databaixaprovisoriamenorigual = filtros.databaixaprovisoriamenorigual;
    if (filtros.databaixaprovisoriacadastromaiorigual) processoFilter.databaixaprovisoriacadastromaiorigual = filtros.databaixaprovisoriacadastromaiorigual;
    if (filtros.databaixaprovisoriacadastromenorigual) processoFilter.databaixaprovisoriacadastromenorigual = filtros.databaixaprovisoriacadastromenorigual;

    // List properties (assuming filtros.*Id is an array of IOption objects)
    if (filtros.areadireitoId && filtros.areadireitoId.length > 0) processoFilter.areadireitoId = filtros.areadireitoId.map((option: IOption) => option.value);
    if (filtros.subareadireitoId && filtros.subareadireitoId.length > 0) processoFilter.subareadireitoId = filtros.subareadireitoId.map((option: IOption) => option.value);
    if (filtros.classificacaoId && filtros.classificacaoId.length > 0) processoFilter.classificacaoId = filtros.classificacaoId.map((option: IOption) => option.value);
    if (filtros.ambitoId && filtros.ambitoId.length > 0) processoFilter.ambitoId = filtros.ambitoId.map((option: IOption) => option.value);
    if (filtros.tipocontingenciaId && filtros.tipocontingenciaId.length > 0) processoFilter.tipocontingenciaId = filtros.tipocontingenciaId.map((option: IOption) => option.value);
    if (filtros.esferaId && filtros.esferaId.length > 0) processoFilter.esferaId = filtros.esferaId.map((option: IOption) => option.value);
    if (filtros.statusprocessoId && filtros.statusprocessoId.length > 0) processoFilter.statusprocessoId = filtros.statusprocessoId.map((option: IOption) => option.value);
    if (filtros.motivoencerramentoId && filtros.motivoencerramentoId.length > 0) processoFilter.motivoencerramentoId = filtros.motivoencerramentoId.map((option: IOption) => option.value);
    if (filtros.instanciaId && filtros.instanciaId.length > 0) processoFilter.instanciaId = filtros.instanciaId.map((option: IOption) => option.value);
    if (filtros.faseprocessualId && filtros.faseprocessualId.length > 0) processoFilter.faseprocessualId = filtros.faseprocessualId.map((option: IOption) => option.value);
    if (filtros.tipoacaoId && filtros.tipoacaoId.length > 0) processoFilter.tipoacaoId = filtros.tipoacaoId.map((option: IOption) => option.value);
    if (filtros.riscoId && filtros.riscoId.length > 0) processoFilter.riscoId = filtros.riscoId.map((option: IOption) => option.value);
    if (filtros.estadoId && filtros.estadoId.length > 0) processoFilter.estadoId = filtros.estadoId.map((option: IOption) => option.value);
    if (filtros.cIdadeId && filtros.cIdadeId.length > 0) processoFilter.cIdadeId = filtros.cIdadeId.map((option: IOption) => option.value);
    if (filtros.escritorioId && filtros.escritorioId.length > 0) processoFilter.escritorioId = filtros.escritorioId.map((option: IOption) => option.value);
    if (filtros.empresaId && filtros.empresaId.length > 0) processoFilter.empresa = filtros.empresaId.map((option: IOption) => option.value);
    if (filtros.clienteId && filtros.clienteId.length > 0) processoFilter.clienteId = filtros.clienteId.map((option: IOption) => option.value);
    if (filtros.juizId && filtros.juizId.length > 0) processoFilter.juizId = filtros.juizId.map((option: IOption) => option.value);
    if (filtros.advogadointernoId && filtros.advogadointernoId.length > 0) processoFilter.advogadointernoId = filtros.advogadointernoId.map((option: IOption) => option.value);
    if (filtros.grupoeconomicoId && filtros.grupoeconomicoId.length > 0) processoFilter.grupoeconomicoId = filtros.grupoeconomicoId.map((option: IOption) => option.value);


    let request = {
      modeloRelatorioId: filtros.modeloRelatorioId,
      processoFilter
    }

    const { data: result } = await api.post(`Processo/GerarRelatorio`, request);

    return result;
  }

  async obterModeloRelatorio(filtros: any): Promise<IRecuperaLista<IModeloRelatorio>> {
    let query = `${"modulo=" + filtros.modulo}`;

    if (filtros.areaDireitoId > 0) {
      query += `&areaDireitoId=${filtros.areaDireitoId}`;
    }

    if (filtros.tipoContratoId > 0) {
      query += `&tipoContratoId=${filtros.tipoContratoId}`;
    }

    if (filtros.tipoMarcaId > 0) {
      query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
    }

    if (filtros.tipoImovelId > 0) {
      query += `&tipoImovelId=${filtros.tipoImovelId}`;
    }

    if (filtros.tipoLicencaId > 0) {
      query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
    }

    if (filtros.tipoConsultaId > 0) {
      query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
    }

    if (filtros.tipoProcuracaoId > 0) {
      query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
    }

    if (filtros.modeloRelatorioId > 0) {
      query += `&modeloRelatorioId=${filtros.modeloRelatorioId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<IModeloRelatorio>>(`Relatorio/RecuperaModeloRelatorio?${query}`);

    return result;
  }

  async adicionaProcesso(adicionaProcesso: IAdicionaProcessoRequest): Promise<IProcesso> {
    let { data: result } = await api.post<IProcesso>(`Processo/AdicionaProcesso`, this.montarObjetoProcesso(adicionaProcesso));
    return result;
  }

  async editarProcesso(editarProcesso: IAdicionaProcessoRequest) {
    let request = this.montarObjetoArrayProcesso(editarProcesso);
    if (request.length > 0) {
      let result = await api.patch(`Processo/AtualizaProcessoPorId?id=${editarProcesso.processoId}`, request);
      return result;
    }
  }

  async alterarProcessoPorCampo(campo: string, valor: any, processoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let { data: result } = await api.patch<IAdicionaProcessoRequest>(`Processo/AtualizaProcessoPorId?id=${processoId}`, request);
      return result;
    }
  }

  async alterarProcessoPorXCampo(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let result = await api.patch<IAdicionaProcessoRequest>(`Processo/AtualizaXCampoValor?id=${xcampoid}`, request);
      return result;
    }
  }

  async adicionarProcessoPorXCampoValor(campo: number, valor: any, xCampoOpcaoId: number, processoId: number) {
    let request: any;

    if (xCampoOpcaoId > 0)
      request = {
        campoId: campo,
        xCampoOpcaoId: xCampoOpcaoId,
        processoId: processoId,
        valor: valor
      };
    else
      request = {
        campoId: campo,
        processoId: processoId,
        valor: valor
      }

    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);
    return result;
  }

  async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);

    return result;
  }

  async alterarProcessoPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaProcessoRequest>(`Processo/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  async alterarProcessoPorCampoBolleano(campo: string, valor: any, processoId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaProcessoRequest>(`Processo/AtualizaProcessoPorId?id=${processoId}`, request);
  }

  async alterarProcessoPorCentroCustoList(centrosCusto: ICentroCusto[], processoId: number) {
    if (centrosCusto.length > 0) {
      await api.put<IAdicionaProcessoRequest>(`Processo/AtualizaCentroCusto`, {
        processoId: processoId,
        centrosCusto: centrosCusto,
      });
    }
  }

  async alterarProcessoPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], processoId: number) {

    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        const request: IUpdatePartesList = {
          processoId: processoId,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<IAdicionaProcessoRequest>("Processo/AtualizaPartesProcessoPorId", request);
      }
    }
  }

  async alterarProcessoPorAdvogadoList(advogadoList: IAdvogado[], processoId: number) {
    if (advogadoList.filter((e) => e.checked).length > 0) {
      const advogadoListRequest: any[] = [];
      advogadoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoId: item.advogadoId,
            codigo: item.codigo,
          };
          advogadoListRequest.push(advogado);
        });

      if (advogadoListRequest.length > 0) {
        const request: IUpdatePartesList = {
          processoId: processoId,
          advogadoList: advogadoListRequest,
        };
        await api.put<IAdicionaProcessoRequest>("Processo/AtualizaPartesProcessoPorId", request);
      }
    }
  }

  async alterarProcessoPorEmpresaList(empresasList: IEmpresa[], processoId: number) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            formaParticipacaoId: item.formaParticipacaoId,
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        const request: IUpdatePartesList = {
          processoId: processoId,
          empresasList: empresasRequestList,
        };
        await api.put<IAdicionaProcessoRequest>("Processo/AtualizaPartesProcessoPorId", request);
      }
    }
  }

  async alterarProcessoPorParteContrariaList(partesContrariasList: IParteContraria[], processoId: number) {
    const partesContrariasRequestList: any[] = [];
    partesContrariasList.forEach((parteContraria) => {
      let requestParteContraria: any = {};
      if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
      if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
      if (parteContraria.cargoId) requestParteContraria.cargoId = parteContraria.cargoId;
      if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
      if (parteContraria.tipoCentroCustoId) requestParteContraria.tipoCentroCustoId = parteContraria.tipoCentroCustoId;
      if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
      if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
      if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
      if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
      if (parteContraria.ctps) requestParteContraria.ctps = parteContraria.ctps;
      if (parteContraria.dataAdmissao) requestParteContraria.dataAdmissao = parteContraria.dataAdmissao;
      if (parteContraria.dataDemissao) requestParteContraria.dataDemissao = parteContraria.dataDemissao;
      if (parteContraria.dataNascimento) requestParteContraria.dataNascimento = parteContraria.dataNascimento;
      if (parteContraria.departamentoId) requestParteContraria.departamentoId = parteContraria.departamentoId;
      if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
      if (parteContraria.email) requestParteContraria.email = parteContraria.email;
      if (parteContraria.empresaContratante) requestParteContraria.empresaContratante = parteContraria.empresaContratante;
      if (parteContraria.empresaTerceira) requestParteContraria.empresaTerceira = parteContraria.empresaTerceira;
      if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
      if (parteContraria.estadoCivilId) requestParteContraria.estadoCivilId = parteContraria.estadoCivilId;
      if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
      if (parteContraria.formaParticipacaoId) requestParteContraria.formaParticipacaoId = parteContraria.formaParticipacaoId;
      if (parteContraria.formaParticipacaoNome) requestParteContraria.formaParticipacaoNome = parteContraria.formaParticipacaoNome;
      if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
      if (parteContraria.matricula) requestParteContraria.matricula = parteContraria.matricula;
      if (parteContraria.moedaId) requestParteContraria.moedaId = parteContraria.moedaId;
      if (parteContraria.motivoDesligamentoId) requestParteContraria.motivoDesligamentoId = parteContraria.motivoDesligamentoId;
      if (parteContraria.nacionalidade) requestParteContraria.nacionalidade = parteContraria.nacionalidade;
      if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
      if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
      if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
      if (parteContraria.numeroContrato) requestParteContraria.numeroContrato = parteContraria.numeroContrato;
      if (parteContraria.observacoesContratacao) requestParteContraria.observacoesContratacao = parteContraria.observacoesContratacao;
      if (parteContraria.observacoesDesligamento) requestParteContraria.observacoesDesligamento = parteContraria.observacoesDesligamento;
      if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
      if (parteContraria.orgaoRegistroId) requestParteContraria.orgaoRegistroId = parteContraria.orgaoRegistroId;
      if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
      if (parteContraria.pisPasep) requestParteContraria.pisPasep = parteContraria.pisPasep;
      if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
      if (parteContraria.regimeContratacao) requestParteContraria.regimeContratacao = parteContraria.regimeContratacao;
      if (parteContraria.rg) requestParteContraria.rg = parteContraria.rg;
      if (parteContraria.rgOrgao) requestParteContraria.rgOrgao = parteContraria.rgOrgao;
      if (parteContraria.statusFolhaId) requestParteContraria.statusFolhaId = parteContraria.statusFolhaId;
      if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
      if (parteContraria.tipoContratacao) requestParteContraria.tipoContratacao = parteContraria.tipoContratacao;
      if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
      if (parteContraria.ultimoSalario) requestParteContraria.ultimoSalario = parteContraria.ultimoSalario;
      partesContrariasRequestList.push(requestParteContraria);
    });

    const request: IUpdatePartesList = {
      processoId: processoId,
      partesContrariasList: partesContrariasRequestList,
    }
    await api.put<IAdicionaProcessoRequest>("Processo/AtualizaPartesProcessoPorId", request);

  }

  async obterAdvogadosInterno(processoId: number): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/Processo/RecuperaAdvogadoInternoPorId?processoId=${processoId}`);

    return result;
  }

  async obterCentroCusto(processoId: number): Promise<IRecuperaLista<ICentroCusto>> {
    let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`/Processo/RecuperaCentroCustoPorId?processoId=${processoId}`);
    return result;
  }

  async adicionaFavorido(processoId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.AreaDireito,
      processoId: processoId,
    });
    return result;
  }

  async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  async obterProcessos(filtros: FiltroPesquisaProcessoRequest): Promise<IRecuperaLista<IProcesso>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query = this.montarTextos(filtros, query);

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<IProcesso>>(`/Processo/RecuperaListagemProcesso?${query}`);

    return result;
  }

  async validarExisteProcesso(numeroProcesso: string): Promise<IRecuperaLista<IProcesso>> {
    let query = `modulo=1&offset=1&limit=10&sort=processoId`;

    if (numeroProcesso) query += `&numeroProcesso=${numeroProcesso}`;

    let { data: result } = await api.get<IRecuperaLista<IProcesso>>(`/Processo/RecuperaListagemProcesso?${query}`);

    return result;
  }

  async obterProcessoPorId(processoId: number): Promise<IRecuperaLista<IProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IProcesso>>(`/Processo/RecuperaListagemProcesso?&processoId=${processoId}`);
    return result;
  }

  async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`/Favorito/RecuperaFavorito`);

    return result;
  }

  async obterCapaProcesso(processoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Processo/RecuperaCapaProcesso?processoId=${processoId}`
    );

    return result;
  }

  async gerarExcel(filtros: FiltroPesquisaProcessoRequest): Promise<Blob> {

    let processoFilter: any = {};

    // string
    if (filtros.processoId) processoFilter.processoId = [filtros.processoId]
      ;
    if (filtros.pasta) processoFilter.pasta = filtros.pasta;
    if (filtros.resumo) processoFilter.resumo = filtros.resumo;
    if (filtros.observacoes) processoFilter.observacoes = filtros.observacoes;
    if (filtros.parteContraria) processoFilter.parteContraria = filtros.parteContraria;
    if (filtros.numeroProcesso) processoFilter.numeroProcesso = filtros.numeroProcesso;
    if (filtros.descricaoObjetoAcao) processoFilter.descricaoObjetoAcao = filtros.descricaoObjetoAcao;
    if (filtros.numeroDesdobramento) processoFilter.numeroDesdobramento = filtros.numeroDesdobramento;

    //number
    if (filtros.valorCausaMaiorIgual) processoFilter.valorCausaMaiorIgual = filtros.valorCausaMaiorIgual;
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;

    //datas
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;
    if (filtros.valorCausaMenorIgual) processoFilter.valorCausaMenorIgual = filtros.valorCausaMenorIgual;
    if (filtros.dataDistribuicaoMaiorIgual) processoFilter.dataDistribuicaoMaiorIgual = filtros.dataDistribuicaoMaiorIgual;
    if (filtros.dataDistribuicaoMenorIgual) processoFilter.dataDistribuicaoMenorIgual = filtros.dataDistribuicaoMenorIgual;
    if (filtros.dataRecebimentoMaiorIgual) processoFilter.dataRecebimentoMaiorIgual = filtros.dataRecebimentoMaiorIgual;
    if (filtros.dataRecebimentoMenorIgual) processoFilter.dataRecebimentoMenorIgual = filtros.dataRecebimentoMenorIgual;
    if (filtros.dataCadastroMaiorIgual) processoFilter.dataCadastroMaiorIgual = filtros.dataCadastroMaiorIgual;
    if (filtros.dataCadastroMenorIgual) processoFilter.dataCadastroMenorIgual = filtros.dataCadastroMenorIgual;
    if (filtros.prazoFatalMaiorIgual) processoFilter.prazoFatalMaiorIgual = filtros.prazoFatalMaiorIgual;
    if (filtros.prazoFatalMenorIgual) processoFilter.prazoFatalMenorIgual = filtros.prazoFatalMenorIgual;
    if (filtros.prazoFatalMaiorIgual) processoFilter.prazoFatalMaiorIgual = filtros.prazoFatalMaiorIgual;
    if (filtros.prazoFatalMenorIgual) processoFilter.prazoFatalMenorIgual = filtros.prazoFatalMenorIgual;
    if (filtros.dataUltimaAtualizacaoMaiorIgual) processoFilter.dataUltimaAtualizacaoMaiorIgual = filtros.dataUltimaAtualizacaoMaiorIgual;
    if (filtros.dataUltimaAtualizacaoMenorIgual) processoFilter.dataUltimaAtualizacaoMenorIgual = filtros.dataUltimaAtualizacaoMenorIgual;
    if (filtros.dataEncerramentoMaiorIgual) processoFilter.dataEncerramentoMaiorIgual = filtros.dataEncerramentoMaiorIgual;
    if (filtros.dataEncerramentoMenorIgual) processoFilter.dataEncerramentoMenorIgual = filtros.dataEncerramentoMenorIgual;
    if (filtros.dataEncerramentoCadastroMaiorIgual) processoFilter.dataEncerramentoCadastroMaiorIgual = filtros.dataEncerramentoCadastroMaiorIgual;
    if (filtros.dataEncerramentoCadastroMenorIgual) processoFilter.dataEncerramentoCadastroMenorIgual = filtros.dataEncerramentoCadastroMenorIgual;
    if (filtros.dataBaixaProvisoriaMaiorIgual) processoFilter.dataBaixaProvisoriaMaiorIgual = filtros.dataBaixaProvisoriaMaiorIgual;
    if (filtros.dataBaixaProvisoriaMenorIgual) processoFilter.dataBaixaProvisoriaMenorIgual = filtros.dataBaixaProvisoriaMenorIgual;
    if (filtros.dataBaixaProvisoriaCadastroMaiorIgual) processoFilter.dataBaixaProvisoriaCadastroMaiorIgual = filtros.dataBaixaProvisoriaCadastroMaiorIgual;
    if (filtros.dataBaixaProvisoriaCadastroMenorIgual) processoFilter.dataBaixaProvisoriaCadastroMenorIgual = filtros.dataBaixaProvisoriaCadastroMenorIgual;


    // listas
    if (filtros.areaDireitoId && filtros.areaDireitoId.length > 0) processoFilter.areaDireitoId = filtros.areaDireitoId;
    if (filtros.subAreaDireitoId && filtros.subAreaDireitoId.length > 0) processoFilter.subAreaDireitoId = filtros.subAreaDireitoId;
    if (filtros.classificacaoId && filtros.classificacaoId.length > 0) processoFilter.classificacaoId = filtros.classificacaoId;
    if (filtros.ambitoId && filtros.ambitoId.length > 0) processoFilter.ambitoId = filtros.ambitoId;
    if (filtros.tipoContingenciaId && filtros.tipoContingenciaId.length > 0) processoFilter.tipoContingenciaId = filtros.tipoContingenciaId;
    if (filtros.esferaId && filtros.esferaId.length > 0) processoFilter.esferaId = filtros.esferaId;
    if (filtros.statusProcessoId && filtros.statusProcessoId.length > 0) processoFilter.statusProcessoId = filtros.statusProcessoId;
    if (filtros.motivoEncerramentoId && filtros.motivoEncerramentoId.length > 0) processoFilter.motivoEncerramentoId = filtros.motivoEncerramentoId;
    if (filtros.instanciaId && filtros.instanciaId.length > 0) processoFilter.instanciaId = filtros.instanciaId;
    if (filtros.faseProcessualId && filtros.faseProcessualId.length > 0) processoFilter.faseProcessualId = filtros.faseProcessualId;
    if (filtros.tipoAcaoId && filtros.tipoAcaoId.length > 0) processoFilter.tipoAcaoId = filtros.tipoAcaoId;
    if (filtros.riscoId && filtros.riscoId.length > 0) processoFilter.riscoId = filtros.riscoId;
    if (filtros.estadoId && filtros.estadoId.length > 0) processoFilter.estadoId = filtros.estadoId;
    if (filtros.cidadeId && filtros.cidadeId.length > 0) processoFilter.cidadeId = filtros.cidadeId;
    if (filtros.escritorioId && filtros.escritorioId.length > 0) processoFilter.escritorioId = filtros.escritorioId;
    if (filtros.empresaId && filtros.empresaId.length > 0) processoFilter.empresa = filtros.empresaId;
    if (filtros.clienteId && filtros.clienteId.length > 0) processoFilter.clienteId = filtros.clienteId;
    if (filtros.juizId && filtros.juizId.length > 0) processoFilter.juizId = filtros.juizId;
    if (filtros.advogadoInternoId && filtros.advogadoInternoId.length > 0) processoFilter.advogadoInternoId = filtros.advogadoInternoId;
    if (filtros.grupoEconomicoId && filtros.grupoEconomicoId.length > 0) processoFilter.grupoEconomicoId = filtros.grupoEconomicoId;

    let request = {
      modeloRelatorioId: filtros.modeloRelatorioId,
      processoFilter
    }

    const { data: result } = await api.post(`Processo/GerarRelatorio`, request);

    return result;
  }

  private montarTextos = (filtros: FiltroPesquisaProcessoRequest, query: string) => {
    if (filtros.processoId > 0) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.licencaId && filtros.licencaId > 0) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.imovelId && filtros.imovelId > 0) {
      query += `&imovelId=${filtros.imovelId}`;
    }

    if (filtros.resumo.length > 0) {
      query += `&resumo=${filtros.resumo}`;
    }

    if (filtros.descricaoObjetoAcao.length > 0) {
      query += `&descricaoObjetoAcao=${filtros.descricaoObjetoAcao}`;
    }

    if (filtros.pasta.length > 0) {
      query += `&pasta=${filtros.pasta}`;
    }

    if (filtros.parteContraria.length > 0) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    if (filtros.numeroDesdobramento.length > 0) {
      query += `&numeroDesdobramento=${filtros.numeroDesdobramento}`;
    }

    if (filtros.observacoes.length > 0) {
      query += `&observacoes=${filtros.observacoes}`;
    }

    if (filtros.numeroProcesso.length > 0) {
      query += `&numeroProcesso=${filtros.numeroProcesso}`;
    }

    if (filtros.valorCausaMaiorIgual > 0 && filtros.valorCausaMenorIgual > 0) {
      if (filtros.valorCausaMaiorIgual >= filtros.valorCausaMenorIgual) {
        query += `&valorCausaMenorIgual=${filtros.valorCausaMenorIgual / 100}`;
        query += `&valorCausaMaiorIgual=${filtros.valorCausaMaiorIgual / 100}`;
      }
    }

    return query;
  };

  private montarListas = (filtros: FiltroPesquisaProcessoRequest, query: string) => {
    if (filtros.areaDireitoId.length > 0) filtros.areaDireitoId.forEach((id) => (query += `&areaDireitoId=${id}`));
    if (filtros.clienteId.length > 0) filtros.clienteId.forEach((id) => (query += `&clienteId=${id}`));
    if (filtros.statusProcessoId.length > 0) filtros.statusProcessoId.forEach((id) => (query += `&statusProcessoId=${id}`));
    if (filtros.subAreaDireitoId.length > 0) filtros.subAreaDireitoId.forEach((id) => (query += `&subAreaDireitoId=${id}`));
    if (filtros.classificacaoId.length > 0) filtros.classificacaoId.forEach((id) => (query += `&classificacaoId=${id}`));
    if (filtros.ambitoId.length > 0) filtros.ambitoId.forEach((id) => (query += `&ambitoId=${id}`));
    if (filtros.juizId.length > 0) filtros.juizId.forEach((id) => (query += `&juizId=${id}`));
    if (filtros.tipoContingenciaId.length > 0) filtros.tipoContingenciaId.forEach((id) => (query += `&tipoContingenciaId=${id}`));
    if (filtros.esferaId.length > 0) filtros.esferaId.forEach((id) => (query += `&esferaId=${id}`));
    if (filtros.motivoEncerramentoId.length > 0) filtros.motivoEncerramentoId.forEach((id) => (query += `&motivoEncerramentoId=${id}`));
    if (filtros.instanciaId.length > 0) filtros.instanciaId.forEach((id) => (query += `&instancia=${id}`));
    if (filtros.faseProcessualId.length > 0) filtros.faseProcessualId.forEach((id) => (query += `&faseProcessualId=${id}`));
    if (filtros.tipoAcaoId.length > 0) filtros.tipoAcaoId.forEach((id) => (query += `&tipoAcaoId=${id}`));
    if (filtros.riscoId.length > 0) filtros.riscoId.forEach((id) => (query += `&riscoId=${id}`));
    if (filtros.cidadeId.length > 0) filtros.cidadeId.forEach((id) => (query += `&cidadeId=${id}`));
    if (filtros.escritorioId.length > 0) filtros.escritorioId.forEach((id) => (query += `&escritorioId=${id}`));
    if (filtros.empresa.length > 0) filtros.empresa.forEach((id) => (query += `&empresa=${id}`));
    if (filtros.estadoId.length > 0) filtros.estadoId.forEach((id) => (query += `&estadoId=${id}`));
    if (filtros.advogadoInternoId.length > 0) filtros.advogadoInternoId.forEach((id) => (query += `&advogadoInternoPrincipalId=${id}`));
    if (filtros.tipoPedidoId.length > 0) filtros.tipoPedidoId.forEach((id) => (query += `&tipoPedidoId=${id}`));
    return query;
  };

  private montarDatas = (filtros: FiltroPesquisaProcessoRequest, query: string) => {
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.dataBaixaProvisoriaCadastroMaiorIgual && filtros.dataBaixaProvisoriaCadastroMenorIgual) {
      query += `&dataBaixaProvisoriaCadastroMaiorIgual=${filtros.dataBaixaProvisoriaCadastroMaiorIgual}&dataBaixaProvisoriaCadastroMenorIgual=${filtros.dataBaixaProvisoriaCadastroMenorIgual}`;
    }
    if (filtros.dataBaixaProvisoriaMaiorIgual && filtros.dataBaixaProvisoriaMenorIgual) {
      query += `&dataBaixaProvisoriaMaiorIgual=${filtros.dataBaixaProvisoriaMaiorIgual}&dataBaixaProvisoriaMenorIgual=${filtros.dataBaixaProvisoriaMenorIgual}`;
    }
    if (filtros.dataDistribuicaoMenorIgual && filtros.dataDistribuicaoMaiorIgual) {
      query += `&dataDistribuicaoMenorIgual=${filtros.dataDistribuicaoMenorIgual}&dataDistribuicaoMaiorIgual=${filtros.dataDistribuicaoMaiorIgual}`;
    }
    if (filtros.dataEncerramentoCadastroMenorIgual && filtros.dataEncerramentoCadastroMaiorIgual) {
      query += `&dataEncerramentoCadastroMenorIgual=${filtros.dataEncerramentoCadastroMenorIgual}&dataEncerramentoCadastroMaiorIgual=${filtros.dataEncerramentoCadastroMaiorIgual}`;
    }
    if (filtros.dataEncerramentoMaiorIgual && filtros.dataEncerramentoMenorIgual) {
      query += `&dataEncerramentoMaiorIgual=${filtros.dataEncerramentoMaiorIgual}&dataEncerramentoMenorIgual=${filtros.dataEncerramentoMenorIgual}`;
    }
    if (filtros.dataRecebimentoMaiorIgual && filtros.dataRecebimentoMenorIgual) {
      query += `&dataRecebimentoMaiorIgual=${filtros.dataRecebimentoMaiorIgual}&dataRecebimentoMenorIgual=${filtros.dataRecebimentoMenorIgual}`;
    }
    if (filtros.dataUltimaAtualizacaoMaiorIgual && filtros.dataUltimaAtualizacaoMenorIgual) {
      query += `&dataUltimaAtualizacaoMaiorIgual=${filtros.dataUltimaAtualizacaoMaiorIgual}&dataUltimaAtualizacaoMenorIgual=${filtros.dataUltimaAtualizacaoMenorIgual}`;
    }
    return query;
  };

  private montarXCampov2 = (xCampoValores: any[]): string => {
    let query = '';

    xCampoValores.forEach((item: any, index: number) => {
      query += `&XCampoValores[${index}].campoId=${item.campoId}`;
      switch (item.campoTipo) {
        case ETipoCampoEnum.Texto:
          query += `&XCampoValores[${index}].texto=${encodeURIComponent(item.texto)}`;
          break;
        case ETipoCampoEnum.Inteiro:
          query += `&XCampoValores[${index}].inteiro=${item.inteiro}`;
          break;
        case ETipoCampoEnum.Percentual:
          query += `&XCampoValores[${index}].valor=${item.valor}`;
          break;
        case ETipoCampoEnum.Boolean:
          query += `&XCampoValores[${index}].boolean=${item.boolean ? true : false}`;
          break;
        case ETipoCampoEnum.Lista:
          query += `&XCampoValores[${index}].xCampoOpcaoId[${index}]=${item.xCampoOpcaoId}`;
          break;
      }
    });

    return query;
  };

  private montarTextosv2 = (filtros: any): string => {
    let query = '';

    if (filtros?.processoid > 0) query += `&processoid=${filtros.processoid}`;
    if (filtros?.licencaid && filtros.licencaid > 0) query += `&licencaid=${filtros.licencaid}`;
    if (filtros?.imovelid && filtros.imovelid > 0) query += `&imovelid=${filtros.imovelid}`;
    if (filtros?.resumo?.length > 0) query += `&resumo=${filtros.resumo}`;
    if (filtros?.descricaoobjetoacao?.length > 0) query += `&descricaoobjetoacao=${filtros.descricaoobjetoacao}`;
    if (filtros?.pasta?.length > 0) query += `&pasta=${filtros.pasta}`;
    if (filtros?.partecontraria?.length > 0) query += `&partecontraria=${filtros.partecontraria}`;
    if (filtros?.numerodesdobramento?.length > 0) query += `&numerodesdobramento=${filtros.numerodesdobramento}`;
    if (filtros?.observacoes?.length > 0) query += `&observacoes=${filtros.observacoes}`;
    if (filtros?.numeroprocesso?.length > 0) query += `&numeroprocesso=${filtros.numeroprocesso}`;
    if (filtros?.valorcausamenorigual > 0 && filtros?.valorcausamenorigual >= filtros?.valorcausamenorigual) {
      query += `&valorcausamenorigual=${filtros.valorcausamenorigual / 100}`;
      query += `&valorcausamaiorigual=${filtros.valorcausamenorigual / 100}`;
    }

    return query;
  };

  private montarListasv2 = (filtros: any): string => {
    let query = '';

    if (filtros?.areadireitoId?.length > 0) filtros.areadireitoId.forEach((id: IOption) => query += `&areadireitoid=${id.value}`);
    if (filtros?.clienteId?.length > 0) filtros.clienteId.forEach((id: IOption) => query += `&clienteid=${id.value}`);
    if (filtros?.statusprocessoId?.length > 0) filtros.statusprocessoId.forEach((id: IOption) => query += `&statusprocessoid=${id.value}`);
    if (filtros?.subareadireitoId?.length > 0) filtros.subareadireitoId.forEach((id: IOption) => query += `&subareadireitoid=${id.value}`);
    if (filtros?.classificacaoId?.length > 0) filtros.classificacaoId.forEach((id: IOption) => query += `&classificacaoid=${id.value}`);
    if (filtros?.ambitoId?.length > 0) filtros.ambitoId.forEach((id: IOption) => query += `&ambitoid=${id.value}`);
    if (filtros?.juizId?.length > 0) filtros.juizId.forEach((id: IOption) => query += `&juizid=${id.value}`);
    if (filtros?.tipocontingenciaId?.length > 0) filtros.tipocontingenciaId.forEach((id: IOption) => query += `&tipocontingenciaid=${id.value}`);
    if (filtros?.esferaId?.length > 0) filtros.esferaId.forEach((id: IOption) => query += `&esferaid=${id.value}`);
    if (filtros?.motivoencerramentoId?.length > 0) filtros.motivoencerramentoId.forEach((id: IOption) => query += `&motivoencerramentoid=${id.value}`);
    if (filtros?.instanciaId?.length > 0) filtros.instanciaId.forEach((id: IOption) => query += `&instanciaid=${id.value}`);
    if (filtros?.faseprocessualId?.length > 0) filtros.faseprocessualId.forEach((id: IOption) => query += `&faseprocessualid=${id.value}`);
    if (filtros?.tipoacaoId?.length > 0) filtros.tipoacaoId.forEach((id: IOption) => query += `&tipoacaoid=${id.value}`);
    if (filtros?.riscoId?.length > 0) filtros.riscoId.forEach((id: IOption) => query += `&riscoid=${id.value}`);
    if (filtros?.cidadeId?.length > 0) filtros.cidadeId.forEach((id: IOption) => query += `&cidadeid=${id.value}`);
    if (filtros?.escritorioId?.length > 0) filtros.escritorioId.forEach((id: IOption) => query += `&escritorioid=${id.value}`);
    if (filtros?.empresaId?.length > 0) filtros.empresaId.forEach((id: IOption) => query += `&empresa=${id.value}`);
    if (filtros?.estadoId?.length > 0) filtros.estadoId.forEach((id: IOption) => query += `&estadoid=${id.value}`);
    if (filtros?.advogadointernoId?.length > 0) filtros.advogadointernoId.forEach((id: IOption) => query += `&advogadointernoprincipalid=${id.value}`);
    if (filtros?.advogadoId?.length > 0) filtros.advogadoId.forEach((id: IOption) => query += `&advogadoId=${id.value}`);


    return query;
  };

  private montarDatasv2 = (filtros: any): string => {
    let query = '';

    if (filtros?.datacadastroprocessomaiorigual && filtros?.datacadastroprocessomenorigual) query += `&datacadastromaiorigual=${filtros.datacadastroprocessomaiorigual}&datacadastromenorigual=${filtros.datacadastroprocessomenorigual}`;
    if (filtros?.databaixaprovisoriacadastromaiorigual && filtros?.databaixaprovisoriacadastromenorigual) query += `&databaixaprovisoriacadastromaiorigual=${filtros.databaixaprovisoriacadastromaiorigual}&databaixaprovisoriacadastromenorigual=${filtros.databaixaprovisoriacadastromenorigual}`;
    if (filtros?.databaixaprovisoriamaiorigual && filtros?.databaixaprovisoriamenorigual) query += `&databaixaprovisoriamaiorigual=${filtros.databaixaprovisoriamaiorigual}&databaixaprovisoriamenorigual=${filtros.databaixaprovisoriamenorigual}`;
    if (filtros?.datadistribuicaomenorigual && filtros?.datadistribuicaomaiorigual) query += `&datadistribuicaomenorigual=${filtros.datadistribuicaomenorigual}&datadistribuicaomaiorigual=${filtros.datadistribuicaomaiorigual}`;
    if (filtros?.dataencerramentocadastromenorigual && filtros?.dataencerramentocadastromaiorigual) query += `&dataencerramentocadastromenorigual=${filtros.dataencerramentocadastromenorigual}&dataencerramentocadastromaiorigual=${filtros.dataencerramentocadastromaiorigual}`;
    if (filtros?.dataencerramentomaiorigual && filtros?.dataencerramentomenorigual) query += `&dataencerramentomaiorigual=${filtros.dataencerramentomaiorigual}&dataencerramentomenorigual=${filtros.dataencerramentomenorigual}`;
    if (filtros?.datarecebimentomaiorigual && filtros?.datarecebimentomenorigual) query += `&datarecebimentomaiorigual=${filtros.datarecebimentomaiorigual}&datarecebimentomenorigual=${filtros.datarecebimentomenorigual}`;
    if (filtros?.dataultimaatualizacaomaiorigual && filtros?.dataultimaatualizacaomenorigual) query += `&dataultimaatualizacaomaiorigual=${filtros.dataultimaatualizacaomaiorigual}&dataultimaatualizacaomenorigual=${filtros.dataultimaatualizacaomenorigual}`;

    return query;
  };

  private montarObjetoProcesso = (processo: IAdicionaProcessoRequest) => {
    let request: any = {};

    // XCampos
    if (processo.xCampoValorList.length > 0) request.xCampoValorList = processo.xCampoValorList;

    // Campos texto
    if (processo.numeroprocesso) request.numeroProcesso = processo.numeroprocesso;
    if (processo.pasta) request.pasta = processo.pasta;
    if (processo.descricaoobjetoacao) request.descricaoObjetoAcao = processo.descricaoobjetoacao;
    if (processo.resumoprocesso) request.resumo = processo.resumoprocesso;
    if (processo.valorcausa) request.valorCausa = processo.valorcausa;
    if (processo.codigo) request.codigo = processo.codigo;
    if (processo.observacoesprocesso) request.observacoesprocesso = processo.observacoesprocesso;
    if (processo.dadosmatricula) request.dadosMatricula = processo.dadosmatricula;

    // Campos data
    if (processo.datadistribuicao) request.dataDistribuicao = processo.datadistribuicao;
    if (processo.prazofatal) request.prazoFatal = processo.prazofatal;
    if (processo.datarecebimento) request.dataRecebimento = processo.datarecebimento;
    if (processo.dataencerramento) request.dataEncerramento = processo.dataencerramento;
    if (processo.dataencerramentocadastro) request.dataEncerramentoCadastro = processo.dataencerramentocadastro;
    if (processo.databaixaprovisoria) request.dataBaixaProvisoria = processo.databaixaprovisoria;
    if (processo.databaixaprovisoriacadastro) request.dataBaixaProvisoriaCadastro = processo.databaixaprovisoriacadastro;

    // Campos lista
    if (processo.areaDireitoId) request.areaDireitoId = processo.areaDireitoId;
    if (processo.licencaId) request.licencaId = processo.licencaId;
    if (processo.processoprincipalid) request.processoPrincipalId = processo.processoprincipalid;
    if (processo.imovelId) request.imovelId = processo.imovelId;
    if (processo.xFinderDistribuicaoId) request.xFinderDistribuicaoId = processo.xFinderDistribuicaoId;
    if (processo.formaparticipacaoId) request.formaParticipacaoId = processo.formaparticipacaoId;
    if (processo.classificacaoId) request.classificacaoId = processo.classificacaoId;
    if (processo.tipodocumentoId) request.tipoDocumentoId = processo.tipodocumentoId;
    if (processo.paisId) request.paisId = processo.paisId;
    if (processo.statusprocessoId) request.statusProcessoId = processo.statusprocessoId;
    if (processo.motivoencerramentoId) request.motivoEncerramentoId = processo.motivoencerramentoId;
    if (processo.cidadeId) request.cidadeId = processo.cidadeId;
    if (processo.escritorioId) request.escritorioId = processo.escritorioId;
    if (processo.subareadireitoId) request.subAreaDireitoId = processo.subareadireitoId;
    if (processo.instanciaId) request.instanciaId = processo.instanciaId;
    if (processo.faseProcessualId) request.faseProcessualId = processo.faseProcessualId;
    if (processo.riscoId) request.riscoId = processo.riscoId;
    if (processo.tipoAcaoId) request.tipoAcaoId = processo.tipoAcaoId;
    if (processo.esferaId) request.esferaId = processo.esferaId;
    if (processo.ambitoId) request.ambitoId = processo.ambitoId;
    if (processo.juizId) request.juizId = processo.juizId;
    if (processo.tipoContingenciaId) request.tipoContingenciaId = processo.tipoContingenciaId;

    // Campos Pessoa
    if (processo.advogadoInternoList.filter((e) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      processo.advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (processo.advogadoList.filter((e) => e.checked).length > 0) {
      request.advogadoList = [];
      processo.advogadoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoId: item.advogadoId,
            codigo: item.codigo,
          };
          request.advogadoList.push(advogado);
        });
    }

    if (processo.centrosCusto.filter((e) => e.checked).length > 0) {
      request.centrosCusto = [];
      processo.centrosCusto
        .filter((e) => e.checked)
        .forEach((item) => {
          let centroCusto: any = {
            tipoCentroCustoId: item.tipoCentroCustoId,
            percentual: item.percentual,
          };
          request.centrosCusto.push(centroCusto);
        });
    }

    if (processo.empresasList.filter((e) => e.checked).length > 0) {
      request.empresasList = [];
      let objeto: any = {};
      processo.empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          if (item.percentual && item.gestaoProcessoId) {
            objeto.gestaoProcessoId = item.gestaoProcessoId;
            objeto.percentual = item.percentual;
          }
          let empresa: any = {
            empresaId: item.empresaId,
            ...objeto,
            codigo: item.codigo,
            principal: item.principal,
            formaParticipacaoId: item.formaParticipacaoId,
          };
          request.empresasList.push(empresa);
        });
    }

    request.partesContrariasList = [];
    processo.partesContrariasList.forEach((parteContraria) => {
      let requestParteContraria: any = {};
      if (parteContraria.xCampoValorList?.length > 0) requestParteContraria.xCampoValorList = parteContraria.xCampoValorList;
      if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
      if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
      if (parteContraria.cargoId) requestParteContraria.cargoId = parteContraria.cargoId;
      if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
      if (parteContraria.tipoCentroCustoId) requestParteContraria.tipoCentroCustoId = parteContraria.tipoCentroCustoId;
      if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
      if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
      if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
      if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
      if (parteContraria.ctps) requestParteContraria.ctps = parteContraria.ctps;
      if (parteContraria.dataAdmissao) requestParteContraria.dataAdmissao = parteContraria.dataAdmissao;
      if (parteContraria.dataDemissao) requestParteContraria.dataDemissao = parteContraria.dataDemissao;
      if (parteContraria.dataNascimento) requestParteContraria.dataNascimento = parteContraria.dataNascimento;
      if (parteContraria.departamentoId) requestParteContraria.departamentoId = parteContraria.departamentoId;
      if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
      if (parteContraria.email) requestParteContraria.email = parteContraria.email;
      if (parteContraria.empresaContratante) requestParteContraria.empresaContratante = parteContraria.empresaContratante;
      if (parteContraria.empresaTerceira) requestParteContraria.empresaTerceira = parteContraria.empresaTerceira;
      if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
      if (parteContraria.estadoCivilId) requestParteContraria.estadoCivilId = parteContraria.estadoCivilId;
      if (parteContraria.formaParticipacaoId) requestParteContraria.formaParticipacaoId = parteContraria.formaParticipacaoId;
      if (parteContraria.formaParticipacaoNome) requestParteContraria.formaParticipacaoNome = parteContraria.formaParticipacaoNome;
      if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
      if (parteContraria.matricula) requestParteContraria.matricula = parteContraria.matricula;
      if (parteContraria.moedaId) requestParteContraria.moedaId = parteContraria.moedaId;
      if (parteContraria.motivoDesligamentoId) requestParteContraria.motivoDesligamentoId = parteContraria.motivoDesligamentoId;
      if (parteContraria.nacionalidade) requestParteContraria.nacionalidade = parteContraria.nacionalidade;
      if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
      if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
      if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
      if (parteContraria.numeroContrato) requestParteContraria.numeroContrato = parteContraria.numeroContrato;
      if (parteContraria.observacoesContratacao) requestParteContraria.observacoesContratacao = parteContraria.observacoesContratacao;
      if (parteContraria.observacoesDesligamento) requestParteContraria.observacoesDesligamento = parteContraria.observacoesDesligamento;
      if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
      if (parteContraria.orgaoRegistroId) requestParteContraria.orgaoRegistroId = parteContraria.orgaoRegistroId;
      if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
      if (parteContraria.pisPasep) requestParteContraria.pisPasep = parteContraria.pisPasep;
      if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
      if (parteContraria.regimeContratacao) requestParteContraria.regimeContratacao = parteContraria.regimeContratacao;
      if (parteContraria.rg) requestParteContraria.rg = parteContraria.rg;
      if (parteContraria.rgOrgao) requestParteContraria.rgOrgao = parteContraria.rgOrgao;
      if (parteContraria.statusFolhaId) requestParteContraria.statusFolhaId = parteContraria.statusFolhaId;
      if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
      if (parteContraria.tipoContratacao) requestParteContraria.tipoContratacao = parteContraria.tipoContratacao;
      if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
      if (parteContraria.ultimoSalario) requestParteContraria.ultimoSalario = parteContraria.ultimoSalario;
      request.partesContrariasList.push(requestParteContraria);
    });

    return request;
  };

  private montarObjetoArrayProcesso = (processo: IAdicionaProcessoRequest) => {
    let request: any = [];

    // Campos texto
    if (processo.numeroprocesso) request.push({ op: "replace", path: "/numeroprocesso", value: processo.numeroprocesso });
    if (processo.pasta) request.push({ op: "replace", path: "/pasta", value: processo.pasta });
    if (processo.descricaoobjetoacao) request.push({ op: "replace", path: "/descricaoobjetoacao", value: processo.descricaoobjetoacao });
    if (processo.resumoprocesso) request.push({ op: "replace", path: "/resumoprocesso", value: processo.resumoprocesso });
    if (processo.valorcausa) request.push({ op: "replace", path: "/valorcausa", value: processo.valorcausa });
    if (processo.codigo) request.push({ op: "replace", path: "/codigo", value: processo.codigo });
    if (processo.observacoesprocesso) request.push({ op: "replace", path: "/observacoesprocesso", value: processo.observacoesprocesso });
    if (processo.dadosmatricula) request.push({ op: "replace", path: "/dadosMatricula", value: processo.dadosmatricula });


    // Campos data
    if (processo.datadistribuicao) request.push({ op: "replace", path: "/datadistribuicao", value: processo.datadistribuicao });
    if (processo.datarecebimento) request.push({ op: "replace", path: "/datarecebimento", value: processo.datarecebimento });
    if (processo.dataencerramento) request.push({ op: "replace", path: "/dataencerramento", value: processo.dataencerramento });
    if (processo.dataencerramentocadastro) request.push({ op: "replace", path: "/dataencerramentocadastro", value: processo.dataencerramentocadastro });
    if (processo.databaixaprovisoria) request.push({ op: "replace", path: "/databaixaprovisoria", value: processo.databaixaprovisoria });
    if (processo.databaixaprovisoriacadastro) request.push({ op: "replace", path: "/databaixaprovisoriacadastro", value: processo.databaixaprovisoriacadastro });

    // Campos lista
    if (processo.licencaId) request.push({ op: "replace", path: "/licencaId", value: processo.licencaId });
    if (processo.processoprincipalid) request.push({ op: "replace", path: "/processoprincipalId", value: processo.processoprincipalid });
    if (processo.imovelId) request.push({ op: "replace", path: "/imovelId", value: processo.imovelId });
    if (processo.formaparticipacaoId) request.push({ op: "replace", path: "/formaparticipacaoId", value: processo.formaparticipacaoId });
    if (processo.classificacaoId) request.push({ op: "replace", path: "/classificacaoId", value: processo.classificacaoId });
    if (processo.tipodocumentoId) request.push({ op: "replace", path: "/tipodocumentoId", value: processo.tipodocumentoId });
    if (processo.paisId) request.push({ op: "replace", path: "/paisId", value: processo.paisId });
    if (processo.statusprocessoId) request.push({ op: "replace", path: "/statusprocessoId", value: processo.statusprocessoId });
    if (processo.motivoencerramentoId) request.push({ op: "replace", path: "/motivoencerramentoId", value: processo.motivoencerramentoId });
    if (processo.cidadeId) request.push({ op: "replace", path: "/cidadeId", value: processo.cidadeId });
    if (processo.escritorioId) request.push({ op: "replace", path: "/escritorioId", value: processo.escritorioId });
    if (processo.subareadireitoId) request.push({ op: "replace", path: "/subareadireitoId", value: processo.subareadireitoId });
    if (processo.instanciaId) request.push({ op: "replace", path: "/instanciaId", value: processo.instanciaId });
    if (processo.faseProcessualId) request.push({ op: "replace", path: "/faseprocessualId", value: processo.faseProcessualId });
    if (processo.riscoId) request.push({ op: "replace", path: "/riscoId", value: processo.riscoId });
    if (processo.tipoAcaoId) request.push({ op: "replace", path: "/tipoacaoId", value: processo.tipoAcaoId });
    if (processo.esferaId) request.push({ op: "replace", path: "/esferaId", value: processo.esferaId });
    if (processo.ambitoId) request.push({ op: "replace", path: "/ambitoId", value: processo.ambitoId });
    if (processo.juizId) request.push({ op: "replace", path: "/juizId", value: processo.juizId });
    if (processo.tipoContingenciaId) request.push({ op: "replace", path: "/tipocontingenciaId", value: processo.tipoContingenciaId });

    return request;
  };

  public adicionarHistorico = async (processo: IAdicionaProcessoRequest, compromissoId: number) => {
    let request: any = [];

    // Campos Texto
    if (processo.numeroprocesso) request.push({ label: "Número do Processo", value: processo.numeroprocesso });
    if (processo.pasta) request.push({ label: "Pasta", value: processo.pasta });
    if (processo.descricaoobjetoacao) request.push({ label: "Descrição do Objeto da Ação", value: processo.descricaoobjetoacao });
    if (processo.observacoesprocesso) request.push({ label: "Observações do Processo", value: processo.observacoesprocesso });
    if (processo.resumoprocesso) request.push({ label: "Resumo do Processo", value: processo.resumoprocesso });
    if (processo.codigo) request.push({ label: "Código", value: processo.codigo });
    if (processo.dadosmatricula) request.push({ label: "Dados de Matrícula", value: processo.dadosmatricula });

    // Campos Numéricos
    if (processo.valorcausa) request.push({ label: "Valor da Causa", value: processo.valorcausa });

    // Campos Lista
    if (processo.clienteNome) request.push({ label: "Cliente", value: processo.clienteNome });
    if (processo.areaDireitoNome) request.push({ label: "Área do Direito", value: processo.areaDireitoNome });
    if (processo.subareadireitoNome) request.push({ label: "Subárea do Direito", value: processo.subareadireitoNome });
    if (processo.classificacaoNome) request.push({ label: "Classificação", value: processo.classificacaoNome });
    if (processo.ambitoNome) request.push({ label: "Âmbito", value: processo.ambitoNome });
    if (processo.instanciaNome) request.push({ label: "Instância", value: processo.instanciaNome });
    if (processo.faseProcessualNome) request.push({ label: "Fase Processual", value: processo.faseProcessualNome });
    if (processo.tipoAcaoNome) request.push({ label: "Tipo de Ação", value: processo.tipoAcaoNome });
    if (processo.riscoNome) request.push({ label: "Risco", value: processo.riscoNome });
    if (processo.statusprocessoNome) request.push({ label: "Status do Processo", value: processo.statusprocessoNome });
    if (processo.motivoencerramentoNome) request.push({ label: "Motivo de Encerramento", value: processo.motivoencerramentoNome });
    if (processo.juizNome) request.push({ label: "Juiz", value: processo.juizNome });

    // Campos Data
    if (processo.datadistribuicao) request.push({ label: "Data de Distribuição", value: moment(processo.datadistribuicao).format('DD/MM/YYYY') });
    if (processo.datarecebimento) request.push({ label: "Data de Recebimento", value: moment(processo.datarecebimento).format('DD/MM/YYYY') });
    if (processo.prazofatal) request.push({ label: "Prazo Fatal", value: moment(processo.prazofatal).format('DD/MM/YYYY') });
    if (processo.dataencerramento) request.push({ label: "Data de Encerramento", value: moment(processo.dataencerramento).format('DD/MM/YYYY') });
    if (processo.databaixaprovisoria) request.push({ label: "Data de Baixa Provisória", value: moment(processo.databaixaprovisoria).format('DD/MM/YYYY') });

    // Campos de Advogados Internos
    processo.advogadoInternoList.forEach((advogadoInterno) => {
      let advogadoInternoItem = {
        nome: advogadoInterno.nome,
        principal: advogadoInterno.principal ? "Sim" : "Não",
        codigo: advogadoInterno.codigo,
      };
      request.push({ label: "Advogado Interno", value: [advogadoInternoItem] });
    });

    // Campos de Empresas
    processo.empresasList.forEach((empresa) => {
      let empresaItem = {
        empresaId: empresa.empresaId,
        nomeFantasia: empresa.nomeFantasia || empresa.nome,
        principal: empresa.principal ? "Sim" : "Não",
      };
      request.push({ label: "Empresa", value: [empresaItem] });
    });

    // Campos de Parte Contrária
    processo.partesContrariasList.forEach((parteContraria) => {
      let parteContrariaItem = {
        nome: parteContraria.nome,
        documento: parteContraria.documento,
        tipoDocumento: parteContraria.tipoDocumento,
        principal: parteContraria.principal ? "Sim" : "Não",
      };
      request.push({ label: "Parte Contrária", value: [parteContrariaItem] });
    });

    // Campos de Centros de Custo
    processo.centrosCusto.forEach((centroCusto) => {
      let centroCustoItem = {
        nome: centroCusto.nome,
        percentual: centroCusto.percentual,
      };
      request.push({ label: "Centro de Custo", value: [centroCustoItem] });
    });

    // Campos Customizados (XCampo)
    processo.xCampoValorList.forEach((xCampo) => {
      let valorCampo;
      switch (xCampo.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          valorCampo = xCampo.texto;
          break;
        case ETipoCampoEnum.Data:
          valorCampo = moment(xCampo.data).format('DD/MM/YYYY');
          break;
        case ETipoCampoEnum.Lista:
          valorCampo = xCampo.xCampoOpcaoNome;
          break;
        case ETipoCampoEnum.Valor:
          valorCampo = xCampo.valor;
          break;
        case ETipoCampoEnum.Inteiro:
          valorCampo = xCampo.inteiro;
          break;
        default:
          valorCampo = "N/A";
          break;
      }
      request.push({ label: xCampo.label, value: valorCampo });
    });

    // Envio para o backend
    await api.post<any>(`HistoricoCompromisso/AdicionaHistoricoCompromisso`, { compromissoId, historicovalor: JSON.stringify(request) });

    return request;
  };

  public concluirCompromisso = async (processo: IAdicionaProcessoRequest): Promise<any> => {

    let result: any[] = [];

    // Salvar campos normais
    result.push(await this.editarProcesso(processo));

    // Salvar campos Pessoas
    if (processo.partesContrariasList?.length > 0) result.push(await this.alterarProcessoPorParteContrariaList(processo.partesContrariasList, processo.processoId));
    if (processo.advogadoList?.length > 0) result.push(await this.alterarProcessoPorAdvogadoList(processo.advogadoList, processo.processoId));
    if (processo.advogadoInternoList?.length > 0) result.push(await this.alterarProcessoPorAdvogadoInternoList(processo.advogadoInternoList, processo.processoId));
    if (processo.empresasList?.length > 0) result.push(await this.alterarProcessoPorEmpresaList(processo.empresasList, processo.processoId));

    // Salvar xcampos
    if (processo.xCampoValorList?.length > 0) {
      processo.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, processoId: processo.processoId, texto: xCampo.texto }));
            else result.push(await this.alterarProcessoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, processoId: processo.processoId, data: xCampo.data }));
            else result.push(await this.alterarProcessoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, processoId: processo.processoId, valor: xCampo.valor }));
            else result.push(await this.alterarProcessoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, processoId: processo.processoId, inteiro: xCampo.inteiro }));
            else result.push(await this.alterarProcessoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, processoId: processo.processoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
            else result.push(await this.alterarProcessoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Tabela:
            await CamposService.alterarXCampoValorTabelaPorColuna(xCampo.linhas, EModuloNomeEnum.Processo, processo.processoId); //OK
            break;
        }
      });
    }

    return result;

  };

  async obterStaticicasProcesso(): Promise<IRecuperaLista<IProcessoEstatisticas>> {
    const { data: result } = await api.get<IRecuperaLista<IProcessoEstatisticas>>("/Processo/RecuperaListagemProcessoDashboard");
    return result;
  }
}

export default new ProcessoService();
