import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaAtoSocietarioRequest from "../interfaces/Requests/AtoSocietario/FiltroPesquisaEmpresaAto";
import { AdicionaAtoSocietarioRequest } from '../interfaces/Requests/AtoSocietario/AdicionaAtoSocietarioRequest';
import api from "./Api";
import { IAtoSocietario } from "../interfaces/AtoSocietario";
import ICentroCusto from "../interfaces/ICentroCusto";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class AtoSocietarioService {

  async obterAtosSocietarios(filtros: FiltroPesquisaAtoSocietarioRequest): Promise<IRecuperaLista<IAtoSocietario>> {

    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

    if (filtros.empresaId) {
      query += `&empresaId=${filtros.empresaId}`;
    }

    if (filtros.atoSocietarioId) {
      query += `&atoSocietarioId=${filtros.atoSocietarioId}`;
    }

    if (filtros.tipoAtoId) {
      query += `&tipoAtoId=${filtros.tipoAtoId}`;
    }
    if (filtros.atoRegistrado) {
      query += `&AtoRegistradoJunta=${filtros.atoRegistrado}`;
    }

    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.dataRegistroMaiorIgual && filtros.dataRegistroMenorIgual) {
      query += `&dataRegistroMaiorIgual=${filtros.dataRegistroMaiorIgual}&dataRegistroMenorIgual=${filtros.dataRegistroMenorIgual}`;
    }

    if (filtros.dataAtoMaiorIgual && filtros.dataAtoMenorIgual) {
      query += `&dataAtoMaiorIgual=${filtros.dataAtoMaiorIgual}&dataAtoMenorIgual=${filtros.dataAtoMenorIgual}`;
    }

    let { data: result } = await api.get<IRecuperaLista<IAtoSocietario>>(`/AtoSocietario/RecuperaAtoSocietario?${query}`);

    return result;
  }

  async adicionaAtoSocietario(atoSocietario: AdicionaAtoSocietarioRequest): Promise<IAtoSocietario> {
    let { data: result } = await api.post<IAtoSocietario>(`/AtoSocietario/AdicionaAtoSocietario`, atoSocietario);

    return result;
  }

  async alteraAtosSocietarios(atoSocietario: AdicionaAtoSocietarioRequest) {
    const data: any = [];
    const keysToExclude = ['atoSocietarioId', 'empresaId', 'clienteId', 'usuarioIdCadastro', 'dataCadastro'];
    atoSocietario.statusAtoId = 1;
    const paths = Object.keys(atoSocietario);
    paths.forEach(path => {
      if (!keysToExclude.includes(path)) {
        data.push({
          op: 'replace',
          path: '/' + path,
          value: (atoSocietario as any)[path]
        })
      }
    })

    await api.patch<IAtoSocietario>(`/AtoSocietario/AtualizaAtoSocietarioPorId?id=${atoSocietario.atoSocietarioId}`, data)

  }

  public async alterarAtoSocietarioPorCentroCustoList(centrosCusto: ICentroCusto[], atoSocietarioId: number) {
    if (centrosCusto.length > 0) {
      await api.put<AdicionaAtoSocietarioRequest>(`AtoSocietario/AtualizaCentroCusto`, {
        atoSocietarioId: atoSocietarioId,
        centrosCusto: centrosCusto,
      });
    }
  }

  public async alterarAtoSocietarioPorCampo(campo: string, valor: any, atoSocietarioId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<AdicionaAtoSocietarioRequest>(`AtoSocietario/AtualizaAtoSocietarioPorId?id=${atoSocietarioId}`, request);
    }
  }

  public async alterarAtoSocietarioPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<AdicionaAtoSocietarioRequest>(`AtoSocietario/AtualizaXCampoValor?id=${xcampoId}`, request);
    }
  }

  public async alterarAtoSocietarioPorCampoBolleano(campo: string, valor: any, atoSocietarioId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<AdicionaAtoSocietarioRequest>(`AtoSocietario/AtualizaAtoSocietarioPorId?id=${atoSocietarioId}`, request);
  }

  public async alterarAtoSocietarioPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<AdicionaAtoSocietarioRequest>(`AtoSocietario/AtualizaXCampoValor?id=${xcampoid}`, request);
  }
  
  public async obterAtoSocietarioPorId(atoSocietarioId: number): Promise<IRecuperaLista<IAtoSocietario>> {
 
    let { data: result } = await api.get<IRecuperaLista<IAtoSocietario>>(`/AtoSocietario/RecuperaAtoSocietario?&atoSocietarioId=${atoSocietarioId}`);
 
    return result;
  }

  public async obterCapaAtoSocietario(atoSocietarioId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
   
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/AtoSocietario/RecuperaCapaAtoSocietario?atoSocietarioId=${atoSocietarioId}`
   
    );

    return result;
  }
}

export default new AtoSocietarioService()