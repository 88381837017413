import { useContext, useEffect, useState } from "react";
import { faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";


import { EModulo, ETelaEnum } from "../../../enum";
import { defaultSchema, setValuesXCadastroCampos } from "../../../utils/SchemasUtil";

import ITipoComunicacao from "../../../interfaces/ITipoComunicacao";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaComunicacaoRequest } from "../../../interfaces/Requests/Comunicacao/AdicionaComunicacaoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ComunicacaoService from "../../../services/ComunicacaoService";
import TipoComunicacaoService from "../../../services/TipoComunicacaoService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import Carregando from "../../Carregando";
import { notifyFormikDanger } from "../Toast/ToastFormik";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageForButton from "../../../layout/LayoutPageButton";
import CamposCadastroDinamicos from "../Solucao/CamposCadastroSolucoes";
import { tipoComunicacaoInitialValues } from "../../../contexts/InitialValuesContext";

interface IComunicacaoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  setAtualizarTabela(atualizaAgora: boolean): void
}

const ComunicacaoField = ({
  toggleModal,
  exibirModal,
  setAtualizarTabela }: IComunicacaoFieldProps) => {

  const [carregandoTiposComunicacao, setCarregandoTiposComunicacao] = useState<boolean>(false);
  const [tiposComunicacao, setTiposComunicacao] = useState<ITipoComunicacao[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { processo, contrato, setTipoComunicacao, tipoComunicacao, setComunicacao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const initialValues: AdicionaComunicacaoRequest = {
    comunicacaoId: 0,
    principalId: 0,
    tipoComunicacaoId: 0,
    categoriaId: 0,

    modulo: EModulo.Comunicacao,
    tela: ETelaEnum.TelaCadastroComunicacao,

    processoId: processo?.processoId,
    areaDireitoId: processo?.areaDireitoId,

    contratoId: contrato?.contratoId,
    tipoContratoId: contrato?.tipoContratoId,

    datacomunicacao: null,
    descricao: "",
    codigo: "",
    status: "",

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Comunicação",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro da Comunicação..",
            showConfirmButton: false,
          });

          Swal.showLoading();

          setAdicionarLoading(true);

          let resultado = await ComunicacaoService.adicionaComunicacao({
            ...values,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });

          setComunicacao(resultado);

          Swal.hideLoading();

          setTipoComunicacao(tipoComunicacao);

          setConfiguracoesTela([]);

          setAtualizarTabela(true);

          toggleModal();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Comunicação cadastrada com sucesso`,
            timer: 4000,
          });

        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    if (exibirModal) {

      setTipoComunicacao(tipoComunicacaoInitialValues);

      carregarTipoComunicacao();

      setConfiguracoesTela([]);

      formik.setValues(initialValues);

    }
  }, [exibirModal]);

  useEffect(() => {
    if (tipoComunicacao.tipoComunicacaoId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoComunicacao(tipoComunicacaoInitialValues);
      formik.setValues(initialValues);
    }
  }, [tipoComunicacao.tipoComunicacaoId]);

  const maisFiltros = (): any => {
    let objeto: any = {};

    if (processo.processoId) {
      objeto.modulo = EModulo.AreaDireito;
      objeto.processoId = processo.processoId;
      objeto.areaDireitoId = processo.areaDireitoId;
    }

    if (contrato.contratoId) {
      objeto.modulo = EModulo.Contrato;
      objeto.contratoId = contrato.contratoId;
      objeto.tipoContratoId = contrato.tipoContratoId;
    }

    return objeto;
  }


  const carregarTipoComunicacao = async () => {
    try {

      setCarregandoTiposComunicacao(true);

      let resultado = await TipoComunicacaoService.obterListaLimitadaTipoComunicacao({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...maisFiltros()
      });

      setTiposComunicacao(resultado.data);

    } catch (error: any) {
      setTiposComunicacao([]);
    } finally {
      setCarregandoTiposComunicacao(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...maisFiltros(),
        tela: ETelaEnum.TelaCadastroComunicacao,
        tipoComunicacaoId: tipoComunicacao.tipoComunicacaoId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      console.log(error);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const onChangeTipoComunicacao = (value: number, texto: string) => {

    setConfiguracoesTela([]);

    formik.setFieldValue("tipoComunicacaoId", value);

    formik.setFieldValue("categoriaId", value);

    setTipoComunicacao({
      tipoComunicacaoId: value,
      nome: texto,
      opcaoNome: "",
      codigo: "",
      status: "",
      opcaoId: 0,
      categoriaNome: "",
      moduloNome: "",
      categoriaId: 0,
      modulo: EModulo.Comunicacao
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos key={configuracaoTela.campo} configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">

          <ToastContainer />

          <div className="modal-header">
            <LayoutPageForButton title={`Nova Comunicação ${tipoComunicacao?.tipoComunicacaoId > 0 ? " - " + tipoComunicacao?.nome : ""}`}>


            </LayoutPageForButton>
          </div>

          <div className="modal-body">

            <div className="row mt-2">

              {carregandoTiposComunicacao ? (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando tipos de comunicações...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <div className="col-md-3 mt-3">
                  <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                    Tipo de Comunicação:
                  </label>
                  <select
                    name="tipoComunicacaoId"
                    value={tipoComunicacao.tipoComunicacaoId}
                    className={"form-select"}
                    onChange={(event: any) => onChangeTipoComunicacao(event.target.value, event.target[event.target.selectedIndex].text)}
                    placeholder="Nome"
                  >
                    <option value="0"> Selecione um tipos de comunicaçoes</option>
                    {tiposComunicacao.map((tipo) => {
                      return (
                        <option key={tipo.opcaoId} value={tipo.opcaoId}>
                          {tipo.opcaoNome}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>

            <hr></hr>

            {carregandoConfiguracoesTela ? <Carregando /> :
              <>
                {renderCampos()}
              </>}
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }} >

            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoComunicacaoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>

              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}

              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}

              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>

            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>

          </div>

        </div>

      </Modal>
    </>
  );
}

export default ComunicacaoField;
