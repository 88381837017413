import api from "./Api";

import IPublicacao from "../interfaces/IPublicacao";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaPublicacaoRequest from "../interfaces/Requests/Publicacao/FiltroPesquisaPublicacaoRequest";

class PublicacaoService {

    async obterPublicacoes(filtros: FiltroPesquisaPublicacaoRequest): Promise<IRecuperaLista<IPublicacao>> {
        let query = `offset=${filtros.offset}&sort=${filtros.sort}`

        if (filtros.ultimosQuantidade) {

            query += `&limit=${filtros.ultimosQuantidade}`;

        } else {
            query += `&limit=${filtros.limit}`;
        }

        if (filtros.status.length > 0) {
            for (let index = 0; index < filtros.status.length; index++) {
                const status = filtros.status[index];
                query += `&status=${status}`;
            }
        }

        if (filtros.advogadoInternoId.length > 0) {
            for (let index = 0; index < filtros.advogadoInternoId.length; index++) {
                const Id = filtros.advogadoInternoId[index];
                query += `&advogadoInternoPrincipalId=${Id}`;
            }
        }

        if (filtros.areaDireitoId.length > 0) filtros.areaDireitoId.forEach((id) => (query += `&areaDireitoId=${id}`));


        if (filtros.processoId && filtros.processoId > 0) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.modulo && filtros.modulo > 0) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.numeroProcesso) {
            query += `&numeroProcesso=${filtros.numeroProcesso}`;
        }

        if (filtros.parteContraria) {
            query += `&parteContraria=${filtros.parteContraria}`;
        }

        if (filtros.processoIdentificado) {
            query += `&processoIdentificado=${false}`;
        }

        if (filtros.dataPublicacaoMaiorIgual) {
            query += `&dataPublicacaoMaiorIgual=${filtros.dataPublicacaoMaiorIgual}`;
        }

        if (filtros.dataPublicacaoMenorIgual) {
            query += `&dataPublicacaoMenorIgual=${filtros.dataPublicacaoMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        if (filtros.dataDivulgacaoMaiorIgual) {
            query += `&dataDivulgacaoMaiorIgual=${filtros.dataDivulgacaoMaiorIgual}`;
        }

        if (filtros.dataDivulgacaoMenorIgual) {
            query += `&dataDivulgacaoMenorIgual=${filtros.dataDivulgacaoMenorIgual}`;
        }


        let { data: result } = await api.get<IRecuperaLista<IPublicacao>>(`Publicacao/RecuperaPublicacao?${query}`);

        return result;
    }

    async gerarExcel(filtros: FiltroPesquisaPublicacaoRequest): Promise<Blob> {

        let query = `offset=${filtros.offset}&sort=${filtros.sort}`

        if (filtros.ultimosQuantidade) {

            query += `&limit=${filtros.ultimosQuantidade}`;

        } else {
            query += `&limit=${filtros.limit}`;
        }

        if (filtros.status.length > 0) {
            for (let index = 0; index < filtros.status.length; index++) {
                const status = filtros.status[index];

                query += `&status=${status}`;
            }
        }

        if (filtros.processoId && filtros.processoId > 0) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.modulo && filtros.modulo > 0) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.numeroProcesso) {
            query += `&numeroProcesso=${filtros.numeroProcesso}`;
        }

        if (filtros.parteContraria) {
            query += `&parteContraria=${filtros.parteContraria}`;
        }

        if (filtros.dataPublicacaoMaiorIgual) {
            query += `&dataPublicacaoMaiorIgual=${filtros.dataPublicacaoMaiorIgual}`;
        }

        if (filtros.dataPublicacaoMenorIgual) {
            query += `&dataPublicacaoMenorIgual=${filtros.dataPublicacaoMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        if (filtros.dataDivulgacaoMaiorIgual) {
            query += `&dataDivulgacaoMaiorIgual=${filtros.dataDivulgacaoMaiorIgual}`;
        }

        if (filtros.dataDivulgacaoMenorIgual) {
            query += `&dataDivulgacaoMenorIgual=${filtros.dataDivulgacaoMenorIgual}`;
        }

        if (filtros.areaDireitoId.length > 0) filtros.areaDireitoId.forEach((id) => (query += `&areaDireitoId=${id}`));

        const { data: result } = await api.get(`Publicacao/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

        return result;
    }

    async alterarStatus(publicacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPublicacao>(`Publicacao/AtualizaPublicacaoPorId?id=${publicacaoId}`, data);
    }

    async vincularProcesso(publicacaoId: number, processoId: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/processoId", "value": processoId },
            { "op": "replace", "path": "/processoIdentificado", "value": true },
        ]

        await api.patch<IPublicacao>(`Publicacao/AtualizaPublicacaoPorId?id=${publicacaoId}`, data);
    }
}


export default new PublicacaoService();
