import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 10px 20px 10px 20px;
`
export const Header = styled.header`
  border-bottom: 1px solid #ddd;
  padding: 10px 20px 10px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    border: none;
    background: transparent;

    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;

    &:hover {
      background: #f2f2fa;
    }
  }
`;

export const Footer = styled.footer`
  padding: 0 20px;

  > form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > textarea {
      min-height: 80px;
      max-height: 200px;
    }

    div.actions {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
    }
  }
`;

export const Title = styled.h5``;

export const Subtitle = styled.p``;
