
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import PowerBI from "../../components/Comum/PowerBI/PowerBI";
import { EModulo } from "../../enum";
import "./style/icons.css";

const ProcessoDashboardPage = () => {

  return (
    <>
      <LayoutPageTitle title="Dashboard Contencioso" />
      <PowerBI modulo={EModulo.AreaDireito} />
    </>
  );
}

export default ProcessoDashboardPage;
