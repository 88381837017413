import { RenderElementProps } from "slate-react";
import {
  ParagraphElement,
  LinkElement,
  BulletedListElement,
  ListItemElement,
  NumberedListElement,
  ImageElement,
  HeadingOneElement,
  HeadingTwoElement,
  HeadingThreeElement,
  HeaderElement,
  PageElement,
  ContentElement,
  FooterElement,
  TableCellElement,
  TableRowElement,
  TableElement,
  ListItemTextElement,
  LineBreakElement,
  TableHeadElement,
  FragmentElement,
} from "./components";

export const renderElement = (props: RenderElementProps) => {
  switch (props.element.type) {
    case "paragraph":
      return <ParagraphElement {...props} />;
    case "link":
      return <LinkElement {...props} />;
    case "bulleted-list":
      return <BulletedListElement {...props} />;
    case "list-item":
      return <ListItemElement {...props} />;
    case "numbered-list":
      return <NumberedListElement {...props} />;
    case "list-item-text":
      return <ListItemTextElement {...props} />;
    case "image":
      return <ImageElement {...props} />;
    case "heading-one":
      return <HeadingOneElement {...props} />;
    case "heading-two":
      return <HeadingTwoElement {...props} />;
    case "heading-three":
      return <HeadingThreeElement {...props} />;
    case "header":
      return <HeaderElement {...props} />;
    case "content":
      return <ContentElement {...props} />;
    case "footer":
      return <FooterElement {...props} />;
    case "page":
      return <PageElement {...props} />;
    case "table":
      return <TableElement {...props} />;
    case "table-cell":
      return <TableCellElement {...props} />;
    case "table-row":
      return <TableRowElement {...props} />;
    case "table-head":
        return <TableHeadElement {...props} />;
    case "line-break":
      return <LineBreakElement {...props} />;
    case "fragment": 
      return <FragmentElement {...props} />;
  }
};
