import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import clsx from "clsx";
import Select from "react-select";

import IValores from "../../interfaces/IValores";
import ITipoPedido from "../../interfaces/ITipoPedido";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { AdicionaValoresRequest } from "../../interfaces/Requests/Valores/AdicionarValores/AdicionaValoresRequest";

import ValoresService from "../../services/ValoresService";
import { AdicionaEncargosHonorariosRequest } from "../../interfaces/Requests/Valores/AdicionarValores/AdicionaEncargosHonorariosRequest";
import { AdicionaValoresMultaRequest } from "../../interfaces/Requests/Valores/AdicionarValores/AdicionaValoresMultaRequest";
import { AdicionaFaseValoresRequest } from "../../interfaces/Requests/Valores/AdicionarValores/AdicionaFaseValoresRequest";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

interface IValoresFieldProps {
  editarValores?: IValores;
  fase: string;
  toggleModal: () => void;
  exibirModal: boolean;
  processoId: number;
  titulo: string;
  setAtualizarTabela(atualizaAgora: boolean): void;
  areaDireitoId: number;
}

const ValoresModal = ({ toggleModal, exibirModal, processoId, areaDireitoId, titulo, editarValores, fase, setAtualizarTabela }: IValoresFieldProps) => {
  const [options, setOptions] = useState<any[]>([]);
  const [carregandoTipoPedido, setCarregandoTipoPedido] = useState(false);
  const initialValuesValoresMulta: AdicionaValoresMultaRequest = {
    status: 1,
    tipoRisco: 2,
    multaIndiceId: 6,
    multaMoedaId: 1,
    multaJuros: 0,
    multaValor: 0,
    multaCorrecaoDataBase: new Date(),
    multaJurosDataBase: new Date(),
    multaRiscoId: 1,
    multaProvavelValor: 0,
    multaPossivelValor: 0,
    multaRemotoValor: 0,
    multaOficioIndiceId: 6,
    multaOficioMoedaId: 1,
    multaOficioJuros: 0,
    multaOficioValor: 0,
    multaOficioCorrecaoDataBase: new Date(),
    multaOficioJurosDataBase: new Date(),
    multaOficioRiscoId: 1,
    multaOficioProvavelValor: 0,
    multaOficioPossivelValor: 0,
    multaOficioRemotoValor: 0,
    multaIsoladaIndiceId: 6,
    multaIsoladaMoedaId: 1,
    multaIsoladaJuros: 0,
    multaIsoladaValor: 0,
    multaIsoladaCorrecaoDataBase: new Date(),
    multaIsoladaJurosDataBase: new Date(),
    multaIsoladaRiscoId: 1,
    multaIsoladaProvavelValor: 0,
    multaIsoladaPossivelValor: 0,
    multaIsoladaRemotoValor: 0,
    multaMoraIndiceId: 6,
    multaMoraMoedaId: 1,
    multaMoraJuros: 0,
    multaMoraValor: 0,
    multaMoraCorrecaoDataBase: new Date(),
    multaMoraJurosDataBase: new Date(),
    multaMoraRiscoId: 1,
    multaMoraProvavelValor: 0,
    multaMoraPossivelValor: 0,
    multaMoraRemotoValor: 0,
    multaProvisaoValor: 0,
  };

  const initialValuesEncargosHonorarios: AdicionaEncargosHonorariosRequest = {
    valor: 0,
    percentual: 0,
    provavelValor: 0,
    possivelValor: 0,
    remotoValor: 0,
    provisaoValor: 0,
    status: 1,
  };

  const initialValuesFase: AdicionaFaseValoresRequest = {
    indiceId: 6,
    fase: "",
    moedaId: 1,
    tipoRisco: 2,
    riscoId: 1,
    juros: 0.00,
    valor: 0.00,
    correcaoDataBase: new Date(),
    jurosDataBase: new Date(),
    provavelValor: 0,
    possivelValor: 0,
    remotoValor: 0,
    provisaoValor: 0,
    ProvisaoManualValor: 0,
    status: 1,
    tipoContingenciaId: 1,
    encargosHonorariosCreateDto: initialValuesEncargosHonorarios,
    valoresMultaCreateDto: initialValuesValoresMulta
  };

  const initialValuesValores: AdicionaValoresRequest = {
    valoresProcessoPedidoId: 0,
    processoId: processoId,
    tipoPedidoId: 0,
    valoresFaseCreateDto: initialValuesFase,
    codigo: "",
  };


  useEffect(() => {
    const montarForm = async () => {
      await formik.resetForm();

      if (editarValores) {
        await formik.setValues({ ...editarValores });
      }
      await formik.validateForm();
    }
    montarForm();
  }, [exibirModal, editarValores]);

  const formik = useFormik({
    initialValues: initialValuesValores,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: titulo,
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Realizando o cadastro...',
            showConfirmButton: false,
          });

          Swal.showLoading();

          let textResponse = "";

          if (!editarValores) {
            formik.values.valoresFaseCreateDto.fase = fase;
            await ValoresService.adicionaValores(formik.values);
            textResponse = "Valores adicionada com sucesso!";
            setAtualizarTabela(true);
          }

          toggleModal();

          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: textResponse,
            showConfirmButton: true,
            timer: 4000
          });

          Swal.close();
          setSubmitting(false);
        }

      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar/atualizar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  const cancelar = async () => {
    formik.resetForm();
    formik.setValues(initialValuesValores);
  }


  const carregarTipoPedido = async () => {
    try {
      setCarregandoTipoPedido(true);

      let resultado: IRecuperaLista<ITipoPedido>;
      resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoPedido", 1, areaDireitoId, processoId);
      resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoPedidoId }))

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setOptions([]);
    } finally {
      setCarregandoTipoPedido(false);
    }
  }


  useEffect(() => {
    carregarTipoPedido();

  }, []);

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Registrar valores
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">

              {carregandoTipoPedido ? (
                <>Carregando tipos de Objetos...</>
              ) : (
                <div className="col-md-6">
                  <label htmlFor="form-cliente" className="form-label required fw-bolder text-orange">
                    Tipo de Objeto:
                  </label>
                  <Select
                    value={{
                      label: options.find((e) => e.value === formik.values.tipoPedidoId)?.label,
                      value: formik.values.tipoPedidoId,
                    }}
                    onChange={(option: any) => formik.setFieldValue("tipoPedidoId", option.value)}
                    options={options} />
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={() => formik.submitForm()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ValoresModal;