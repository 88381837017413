import { PageElement } from "../../../../../utils/types/slate";
import { CommonService } from "./commons";
import { DocumentStyle } from "../../../types";

export const PageService = {
  makePage(pageNumber: number, documentStyle: DocumentStyle): PageElement {
    const page: PageElement = {
      id: `page_${pageNumber}`,
      style: documentStyle.page,
      type: "page",
      children: [
        {
          id: `header_component_${pageNumber}`,
          style: documentStyle.header,
          type: "header",
          children: [CommonService.makeParagraph()],
        },
        {
          id: `content_${pageNumber}`,
          type: "content",
          style: documentStyle.content,
          children: [CommonService.makeParagraph()],
        },
        {
          id: `footer_component_${pageNumber}`,
          style: documentStyle.footer,
          type: "footer",
          children: [CommonService.makeParagraph()],
        },
      ],
    };

    return page;
  },
};
