import IFornecedores from "../interfaces/IFornecedores";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import AdicionarFornecedorRequest from "../interfaces/Requests/Fornecedores/AdicionarFornecedorRequest";
import AtualizarFornecedorRequest from "../interfaces/Requests/Fornecedores/AtualizarFornecedorRequest";
import FiltroPesquisaFornecedorRequest from "../interfaces/Requests/Fornecedores/FiltroPesquisaFornecedores";
import api from "./Api";

class FornecedoresService {
  async obterFornecedores(filtros: FiltroPesquisaFornecedorRequest) {
    const queryNome = filtros.nome ? `&nome=${filtros.nome}` : "";

    const queryCodigo =
      filtros.codigo === "0" ? "" : `&codigo=${filtros.codigo}`;

    const queryStatus =
      filtros.status === 0
        ? "status=0&status=-1&status=1"
        : `status=${filtros.status}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";
    const queryPaginacao = `&offset=${filtros.offset}${limit}&sort=${filtros.sort}`;

    const { data: result } = await api.get<IRecuperaLista<IFornecedores>>(
      `Fornecedor/RecuperaFornecedor?${queryStatus}${queryPaginacao}${queryNome}${queryCodigo}`
    );

    return result;
  }

  async obterFornecedorViaPessoa(pessoaId: number) {
    const { data: result } = await api.get<IRecuperaLista<IFornecedores>>(
      `Fornecedor/RecuperaFornecedor?pessoaId=${pessoaId}`
    );

    return result;
  }

  async adicionarFornecedor(request: AdicionarFornecedorRequest) {
    const { data: result } = await api.post<IFornecedores>(
      `Fornecedor/AdicionaFornecedor`,
      request
    );

    return result;
  }

  async atualizarFornecedor(id: string, request: AtualizarFornecedorRequest) {
    const body = [];

    for (const iterator of Object.keys(request)) {
      body.push({
        op: "replace",
        path: `/${iterator}`,
        value: request[iterator as keyof typeof request],
      });
    }

    const { data: result } = await api.patch<IRecuperaLista<IFornecedores>>(
      `Fornecedor/AtualizaFornecedorPorId?id=${id}`,
      body
    );

    return result;
  }
}

export default new FornecedoresService();
