import { TableColumn } from "react-data-table-component";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { OverlayTrigger } from "react-bootstrap";

import IProcesso from "../../interfaces/IProcesso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IValoresProcesso from "../../interfaces/IValoresProcesso";
import FiltroPesquisaValoresProcessoRequest from "../../interfaces/Requests/Valores/ValoresProcesso/FiltroPesquisaValoresProcessoRequest";

import ValoresService from "../../services/ValoresService";

import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "../../contexts/OrangeProvider";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";

const ValoresProcessoPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoValoresProcesso, setCarregandoValoresProcesso] = useState(false);
  const [valoresProcessoList, setValoresProcessoList] = useState<IValoresProcesso[]>([]);
  const [valoresProcesso, setValoresProcesso] = useState<IValoresProcesso>();
  const { processo, setProcesso, setAreaDireito } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaValoresProcessoRequest>({
    ProcessoId: processo.processoId,
    Fase: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "processoId",
  });

  const componentRef = useRef<any>();

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_valores");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_valores");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_valores")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaValoresProcessoRequest = JSON.parse((await sessionStorageService.obter("filtro_valores")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, ProcessoId: filtro.ProcessoId });
  };

  async function carregarValoresProcesso(filtro: FiltroPesquisaValoresProcessoRequest): Promise<void> {
    try {
      setCarregandoValoresProcesso(true);
      let resultado: IRecuperaLista<IValoresProcesso>;

      resultado = await ValoresService.obterValoresProcesso(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setValoresProcessoList(resultado.data);
      inserirSessionStorage();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setValoresProcessoList([]);
    } finally {
      setCarregandoValoresProcesso(false);
      setLoadingInicial(false);
    }
  }

  const colunas: TableColumn<IValoresProcesso>[] = [
    {
      cell: (valores: IValoresProcesso) => {
        return (
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ textAlign: 'right' }} scope="col">Valor</th>
                <th style={{ textAlign: 'right' }} scope="col">Base</th>
                <th style={{ textAlign: 'right' }} scope="col">Correção</th>
                <th style={{ textAlign: 'right' }} scope="col">Juros</th>
                <th style={{ textAlign: 'right' }} scope="col">Atualizado</th>
              </tr>
            </thead>
            <tbody>
              <th scope="row">Contingência Passiva</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <tr>
                <th scope="row">Valor da Contingência Total</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaTotalPassivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaPassivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaPassivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaPassivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <th scope="row">Valor da Provisão Passiva</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvisaoPassivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvisaoPassivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvisaoPassivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvisaoPassivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <th scope="row">Valor Provável</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelPassivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelPassivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelPassivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelPassivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <th scope="row">Valor Possível</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelPassivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelPassivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelPassivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelPassivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <th scope="row">Valor Remoto</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoPassivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoPassivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoPassivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoPassivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <p></p>
              </tr>
              <th scope="row">Contingência Ativa</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <tr>
                <th scope="row">Valor da Contingência Total</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaTotalAtivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaAtivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaAtivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorContingeciaAtivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <th scope="row">Valor Provável</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelAtivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelAtivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelAtivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorProvavelAtivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <th scope="row">Valor Possível</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelAtivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelAtivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelAtivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorPossivelAtivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
              <tr>
                <th scope="row">Valor Remoto</th>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoAtivo?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoAtivoCorrecao?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoAtivoJuros?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                <td style={{ textAlign: 'right' }}>{valores?.valorRemotoAtivoAtualizado?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
              </tr>
            </tbody>
          </table>
        );
      },
    },
  ];


  const handleSort = async (column: TableColumn<IValoresProcesso>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  useEffect(() => {
    carregarValoresProcesso(filtrosPesquisa);
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageForButton title="Valores">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>
      <hr />

      <button
        onClick={() => {
          setValoresProcesso(undefined);
          navigate("/GerenciarValores");
        }}
        className="btn btn-md btn-orange"
      >
        Gerenciar Valores
      </button>
      <div className="row mt-2">
        <GridPadrao
          onSort={handleSort}
          progressPending={carregandoValoresProcesso}
          limit={filtrosPesquisa.limit}
          paginationServer={false}
          paginationTotalRows={filtrosPesquisa.totalItems}
          colunas={colunas}
          tipo="Valores"
          itens={valoresProcessoList}
        />
      </div>
    </>
  );
};

export default ValoresProcessoPage;
