import { EModulo } from "../enum";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import ICentroCusto from "../interfaces/ICentroCusto";
import IEmpresa from "../interfaces/IEmpresa";
import IParteContraria from "../interfaces/IParteContraria";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { IAdicionaMarcaRequest } from "../interfaces/Requests/Marca/IAdicionaMarcaRequest";
import api from "./Api";

class MarcaService {

  async adicionaMarca(adicionaMarca: IAdicionaMarcaRequest): Promise<IMarca> {
    let { data: result } = await api.post<IMarca>(`Marca/AdicionaMarca`, this.montarObjetoAdicionarMarca(adicionaMarca));
    return result;
  }

  public async alterarProcessoPorCentroCustoList(centrosCusto: ICentroCusto[], marcaId: number) {
    if (centrosCusto.length > 0) {
      await api.put<IAdicionaMarcaRequest>(`Marca/AtualizaCentroCusto`, {
        marcaId: marcaId,
        centrosCusto: centrosCusto,
      });
    }
  }

  public async obterMarcas(filtros: any): Promise<IRecuperaLista<IMarca>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.numeroPedido) {
      query += `&numeroPedido=${filtros.numeroPedido}`;
    }

    if (filtros.tituloRegistro) {
      query += `&tituloRegistro=${filtros.tituloRegistro}`;
    }

    if (filtros.nomeMarca) {
      query += `&nomeMarca=${filtros.nomeMarca}`;
    }

    if (filtros.numeroRegistro) {
      query += `&numeroRegistro=${filtros.numeroRegistro}`;
    }
    
    if (filtros.parteContraria.length > 0) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<IMarca>>(`Marca/RecuperaListagemMarca?${query}`);

    return result;
  }

  async obterCentroCusto(marcaId: number): Promise<IRecuperaLista<ICentroCusto>> {
    let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`/Marca/RecuperaCentroCustoPorId?marcaId=${marcaId}`);
    return result;
  }

  public async alterarMarcaPorCampo(campo: string, valor: any, marcaId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaMarcaRequest>(`Marca/AtualizaMarcaPorId?id=${marcaId}`, request);
    }
  }

  public async alterarMarcaPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaMarcaRequest>(`Marca/AtualizaXCampoValor?id=${xcampoId}`, request);
    }
  }

  public async alterarMarcaPorCampoBolleano(campo: string, valor: any, marcaId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaMarcaRequest>(`Marca/AtualizaMarcaPorId?id=${marcaId}`, request);
  }

  public async alterarMarcaPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaMarcaRequest>(`Marca/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`/Favorito/RecuperaFavorito`);

    return result;
  }

  public async alterarMarcaPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], marcaId: number) {

    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        const request = {
          marcaId: marcaId,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<IAdicionaMarcaRequest>("Marca/AtualizaPartesMarcaPorId", request);
      }
    }
  }

  public async alterarMarcaPorEmpresaList(empresasList: IEmpresa[], marcaId: number) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        const request = {
          marcaId: marcaId,
          empresasList: empresasRequestList,
        };
        await api.put<IAdicionaMarcaRequest>("Marca/AtualizaPartesMarcaPorId", request);
      }
    }
  }

  public async alterarMarcaPorParteMarcaList(partesMarcasList: IParteContraria[], marcaId: number) {
    const partesMarcasRequestList: any[] = [];
    partesMarcasList.forEach((parteMarca) => {
      let requestParteMarca: any = {};
      if (parteMarca.bairro) requestParteMarca.bairro = parteMarca.bairro;
      if (parteMarca.celular) requestParteMarca.celular = parteMarca.celular;
      if (parteMarca.cep) requestParteMarca.cep = parteMarca.cep;
      if (parteMarca.cidadeId) requestParteMarca.cidadeId = parteMarca.cidadeId;
      if (parteMarca.codigo) requestParteMarca.codigo = parteMarca.codigo;
      if (parteMarca.complemento) requestParteMarca.complemento = parteMarca.complemento;
      if (parteMarca.documento) requestParteMarca.documento = parteMarca.documento;
      if (parteMarca.email) requestParteMarca.email = parteMarca.email;
      if (parteMarca.endereco) requestParteMarca.endereco = parteMarca.endereco;
      if (parteMarca.estadoId) requestParteMarca.estadoId = parteMarca.estadoId;
      if (parteMarca.papelSignatarioId) requestParteMarca.papelSignatarioId = parteMarca.papelSignatarioId;
      if (parteMarca.papelSignatarioNome) requestParteMarca.papelSignatarioNome = parteMarca.papelSignatarioNome;
      if (parteMarca.logradouro) requestParteMarca.logradouro = parteMarca.logradouro;
      if (parteMarca.nome) requestParteMarca.nome = parteMarca.nome;
      if (parteMarca.nomeSocial) requestParteMarca.nomeSocial = parteMarca.nomeSocial;
      if (parteMarca.numero) requestParteMarca.numero = parteMarca.numero;
      if (parteMarca.observacoesGerais) requestParteMarca.observacoesGerais = parteMarca.observacoesGerais;
      if (parteMarca.pessoaId) requestParteMarca.pessoaId = parteMarca.pessoaId;
      if (parteMarca.principal) requestParteMarca.principal = parteMarca.principal;
      if (parteMarca.telefone) requestParteMarca.telefone = parteMarca.telefone;
      if (parteMarca.tipoDocumento) requestParteMarca.tipoDocumento = parteMarca.tipoDocumento;
      partesMarcasRequestList.push(requestParteMarca);
    });

    const request = {
      marcaId: marcaId,
      partesMarcasList: partesMarcasRequestList,
    }
    await api.put<IAdicionaMarcaRequest>("Marca/AtualizaPartesMarcaPorId", request);

  }

  public async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  public async adicionaFavorido(marcaId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.MarcaEPertences,
      marcaId: marcaId,
    });
    return result;
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  public async obterAdvogadosInterno(marcaId: number): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/Marca/RecuperaAdvogadoInternoPorId?marcaId=${marcaId}`);

    return result;
  }

  public async obterMarcaPorId(marcaId: number): Promise<IRecuperaLista<IMarca>> {
    let { data: result } = await api.get<IRecuperaLista<IMarca>>(`/Marca/RecuperaListagemMarca?&marcaId=${marcaId}`);
    return result;
  }

  public async obterCapaMarca(marcaId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Marca/RecuperaCapaMarca?marcaId=${marcaId}`
    );

    return result;
  }

  private montarObjetoAdicionarMarca = (marca: IAdicionaMarcaRequest) => {
    let request: any = {};

    // XCampos
    if (marca.xCampoValorList.length > 0) request.xCampoValorList = marca.xCampoValorList;

    // Campos texto
    if (marca.codigo) request.codigo = marca.codigo;
    if (marca.nomemarca) request.nomeMarca = marca.nomemarca;
    if (marca.numeropedido) request.numeroPedido = marca.numeropedido;
    if (marca.observacoesmarca) request.observacoesMarca = marca.observacoesmarca;
    if (marca.numeroregistro) request.numeroRegistro = marca.numeroregistro;
    if (marca.especificacao) request.especificacao = marca.especificacao;
    if (marca.tituloregistro) request.tituloRegistro = marca.tituloregistro;

    // Campos bool
    request.restrito = marca.restrito ? true : false;

    // Campos data
    if (marca.dataconcessao) request.dataConcessao = marca.dataconcessao;
    if (marca.datadeposito) request.dataDeposito = marca.datadeposito;
    if (marca.datainiciovigencia) request.dataInicioVigencia = marca.datainiciovigencia;
    if (marca.datafimvigencia) request.dataFimVigencia = marca.datafimvigencia;
    if (marca.datapublicacao) request.dataPublicacao = marca.datapublicacao;

    // Campos lista
    if (marca.departamentoId) request.departamentoId = marca.departamentoId;
    if (marca.tipoMarcaId) request.tipoMarcaId = marca.tipoMarcaId;
    if (marca.tipoDocumentoCheckList) request.tipoDocumentoCheckList = marca.tipoDocumentoCheckList;
    if (marca.apresentacaomarcaId) request.apresentacaoMarcaId = marca.apresentacaomarcaId;
    if (marca.classemarcaId) request.classeMarcaId = marca.classemarcaId;
    if (marca.naturezamarcaId) request.naturezaMarcaId = marca.naturezamarcaId;
    if (marca.classificacaoipcId) request.classificacaoIPCId = marca.classificacaoipcId;
    if (marca.escritorioId) request.escritorioId = marca.escritorioId;
    if (marca.paisId) request.paisId = marca.paisId;
    if (marca.cidadeId) request.cidadeId = marca.cidadeId;
    if (marca.tipodocumentoId) request.tipoDocumentoId = marca.tipodocumentoId;

    // Campos Pessoa e Empresa
    if (marca.advogadoInternoList.filter((e) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      marca.advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (marca.empresasList.filter((e) => e.checked).length > 0) {
      request.empresasList = [];
      marca.empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          request.empresasList.push(empresa);
        });
    }

    if (marca.centrosCusto.filter((e) => e.checked).length > 0) {
      request.centrosCusto = [];
      marca.centrosCusto
        .filter((e) => e.checked)
        .forEach((item) => {
          let centroCusto: any = {
            tipoCentroCustoId: item.tipoCentroCustoId,
            percentual: item.percentual,
          };
          request.centrosCusto.push(centroCusto);
        });
    }

    if (marca.partesContrariasList.length > 0) {
      request.partesContrariasList = [];
      marca.partesContrariasList.forEach((parteContraria) => {
        let requestParteContraria: any = {};
        if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
        if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
        if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
        if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
        if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
        if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
        if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
        if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
        if (parteContraria.email) requestParteContraria.email = parteContraria.email;
        if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
        if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
        if (parteContraria.formaParticipacaoId) requestParteContraria.formaParticipacaoId = parteContraria.formaParticipacaoId;
        if (parteContraria.formaParticipacaoNome) requestParteContraria.formaParticipacaoNome = parteContraria.formaParticipacaoNome  ;
        if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
        if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
        if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
        if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
        if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
        if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
        if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
        if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
        if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
        request.partesContrariasList.push(requestParteContraria);
      });
    }

    return request;
  };

  private montarListas = (filtros: any, query: string) => {

    if (filtros.empresaId.length > 0) filtros.empresaId.forEach((id: any) => (query += `&empresa=${id}`));
    if (filtros.departamentoId.length > 0) filtros.departamentoId.forEach((id: any) => (query += `&DepartamentoId=${id}`));
    if (filtros.apresentacaoMarcas.length > 0) filtros.apresentacaoMarcas.forEach((id: any) => (query += `&ApresentacaoMarcaId=${id}`));

    if (filtros.escritorioId.length > 0) filtros.escritorioId.forEach((id: any) => (query += `&escritorioId=${id}`));
    if (filtros.naturezaMarcaId.length > 0) filtros.naturezaMarcaId.forEach((id: any) => (query += `&naturezaMarcaId=${id}`));
    if (filtros.classeMarcaId.length > 0) filtros.classeMarcaId.forEach((id: any) => (query += `&classeMarcaId=${id}`));

    if (filtros.classificacaoIPCId.length > 0) filtros.classificacaoIPCId.forEach((id: any) => (query += `&classificacaoIPCId=${id}`));
    if (filtros.paisId.length > 0) filtros.paisId.forEach((id: any) => (query += `&paisId=${id}`));
    if (filtros.cidadeId.length > 0) filtros.cidadeId.forEach((id: any) => (query += `&cidadeId=${id}`));

    if (filtros.statusMarcaId.length > 0) filtros.statusMarcaId.forEach((id: any) => (query += `&statusMarcaId=${id}`));
    if (filtros.tipoMarcaId.length > 0) filtros.tipoMarcaId.forEach((id: any) => (query += `&TipoMarcaId=${id}`));


    return query;
  };

  private montarDatas = (filtros: any, query: string) => {
    if (filtros.dataInicioVigenciaMaiorIgual && filtros.dataInicioVigenciaMenorIgual) {
      query += `&dataInicioVigenciaMaiorIgual=${filtros.dataInicioVigenciaMaiorIgual}&dataInicioVigenciaMenorIgual=${filtros.dataInicioVigenciaMenorIgual}`;
    }
    if (filtros.dataFimVigenciaMaiorIgual && filtros.dataFimVigenciaMenorIgual) {
      query += `&dataFimVigenciaMaiorIgual=${filtros.dataFimVigenciaMaiorIgual}&dataFimVigenciaMenorIgual=${filtros.dataFimVigenciaMenorIgual}`;
    }
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.dataDepositoMaiorIgual && filtros.dataDepositoMenorIgual) {
      query += `&dataDepositoMaiorIgual=${filtros.dataDepositoMaiorIgual}&dataDepositoMenorIgual=${filtros.dataDepositoMenorIgual}`;
    }
    if (filtros.dataConcessaoMaiorIgual && filtros.dataConcessaoMenorIgual) {
      query += `&dataConcessaoMaiorIgual=${filtros.dataConcessaoMaiorIgual}&dataConcessaoMenorIgual=${filtros.dataConcessaoMenorIgual}`;
    }
    if (filtros.dataPublicacaoMaiorIgual && filtros.dataPublicacaoMenorIgual) {
      query += `&dataPublicacaoMaiorIgual=${filtros.dataPublicacaoMaiorIgual}&dataPublicacaoMenorIgual=${filtros.dataPublicacaoMenorIgual}`;
    }

    return query;
  };



}

export default new MarcaService();
