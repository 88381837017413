import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faFileArchive, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import LayoutPageForButton from "../../layout/LayoutPageButton";
import { ETipoCampoEnum } from "../../enum/ETipoCampoEnum";
import { ECamposIDEnum } from "../../enum/ECamposIDEnum";
import { tipoPorModulo } from "../../utils/Utils";
import { EModulo, ETelaEnum } from "../../enum";

import ITipoHonorario from "../../interfaces/ITipoHonorario";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaTipoHonorarioRequest from "../../interfaces/Requests/TipoHonorario/FiltroPesquisaTipoHonorarioRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import HonorarioService from "../../services/HonorarioService";
import TipoHonorarioService from "../../services/TipoHonorarioService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { OrangeContext } from "../../contexts/OrangeProvider";
import IFileUploadField from "../../components/FileUpload/FileUploadField";
import CamposCadastroDinamicos from "../../components/Honorario/CamposCadastroDinamicos";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";


const HonorarioCadastroPage = () => {
  const { honorario, setHonorario, tipoHonorario, setTipoHonorario } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);

  const [adicionarHonorarioLoading, setAdicionarHonorarioLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [tipoHonorarios, setTipoHonorarios] = useState<ITipoHonorario[]>([]);
  const [carregandoTipoHonorario, setCarregandoTipoHonorario] = useState(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaTipoHonorarioRequest>({
    nome: "",
    codigo: "",
    status: 1,
    queryCustom: "",
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: "tipoHonorarioId",
  });

  const navigate = useNavigate();

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const initialValues = {
    modulo: honorario.modulo,
    categoriaId: honorario.categoriaId,
    processoId: honorario.processoId || null,
    consultaId: honorario.consultaId || null,
    contratoId: honorario.contratoId || null,
    tipoHonorarioId: 0,
  };

  useEffect(() => {
    carregarTipoHonorario(filtrosPesquisa);
  }, []);

  const adicionarShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Lista:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}Id`] = Yup.number().min(1, `${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          schema[configuracao.campoNome.toLowerCase()] = Yup.date().required(`${configuracao.label} é um campo obrigatório`)
          break;

        case ETipoCampoEnum.Empresa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Empresa) {
            schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Pessoa:
          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.Advogado) {
            schema.advogadoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno) {
            schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && configuracao.campoNome === ECamposIDEnum.ParteContraria) {
            schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionarShema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Honorário",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          Swal.showLoading();
          setAdicionarHonorarioLoading(true);
          let resultado = await HonorarioService.adicionaHonorario({
            ...values,
            xCampoValorList: carregarObjetoXCampos()
          });
          Swal.hideLoading();
          setHonorario(resultado);

          setQuantidadeArquivosSelecionados(0);
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarHonorarioLoading(false);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel cadastrar o processo.`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarHonorarioLoading(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.tipoHonorarioId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      formik.setValues(initialValues);
    }
  }, [formik.values.tipoHonorarioId]);

  const carregarObjetoXCampos = () => {
    let xCampoValorList = new Array<any>;
    configuracoesTela.forEach(configuracao => {

      if (configuracao?.isXCampo) {
        switch (configuracao.campoTipo) {
          case ETipoCampoEnum.AreaTexto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Texto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Data:
            xCampoValorList.push({ campoId: configuracao.campo, data: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Valor:
            xCampoValorList.push({ campoId: configuracao.campo, valor: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Inteiro:
            xCampoValorList.push({ campoId: configuracao.campo, inteiro: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Lista:
            xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`) })
            break;

          case ETipoCampoEnum.Boolean:
            xCampoValorList.push({ campoId: configuracao.campo, boolean: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          default:
            break;
        }
      }
    });
    return xCampoValorList;
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(honorario.modulo)}Id=${honorario.categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        Modulo: honorario.modulo,
        Tela: ETelaEnum.TelaCadastroHonorario,
        TipoHonorarioId: tipoHonorario.tipoHonorarioId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const carregarTipoHonorario = async (filtro: FiltroPesquisaTipoHonorarioRequest) => {
    try {
      setCarregandoTipoHonorario(true);

      if (honorario.modulo === EModulo.AreaDireito)
        filtro.queryCustom = `&modulo=1&areaDireitoId=${honorario.categoriaId}`;

      if (honorario.modulo === EModulo.Contrato)
        filtro.queryCustom = `&modulo=7&contratoId=${honorario.categoriaId}`;

      if (honorario.modulo === EModulo.Consultivo)
        filtro.queryCustom = `&modulo=9&consultaId=${honorario.categoriaId}`;

      let resultado: IRecuperaLista<ITipoHonorario>;

      resultado = await TipoHonorarioService.obterTipoHonorarioLimitada(filtro);

      setFiltrosPesquisa({ ...filtrosPesquisa, totalItems: resultado.totalRegistros });

      setTipoHonorarios(resultado.data);

    } catch (error: any) {
      setTipoHonorarios([]);
    } finally {
      setCarregandoTipoHonorario(false);
    }
  };

  const onChangeTipoHonorario = (value: any, texto: string) => {

    setConfiguracoesTela([]);

    formik.setFieldValue("tipoHonorarioId", parseInt(value));

    setTipoHonorario({
      tipoHonorarioId: parseInt(value),
      nome: texto,
      checked: false,
      codigo: "",
      status: "",
      categoriaId: 0,
      categoriaNome: "",
      modulo: 0,
      moduloNome: "",
      opcaoNome: "",
      opcaoId: 0
    });
  };

  const renderImportadorArquivos = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumento)[0]?.label}:
            </label>
            <br></br>
            <Button
              onClick={() => setExibirModalImportacaoArquivos(true)}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
              Selecione os arquivos da honorario
            </Button>
          </div>
          <IFileUploadField
            modulo={EModulo.XpayHonorario}
            setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
            exibirModal={exibirModalImportacaoArquivos}
            toggleModal={toggleImportacaoArquivos}
            honorarioId={honorario.honorarioId}
            tipoHonorarioId={formik.values.tipoHonorarioId}
          />
        </div>
        <div className="row mt-2">
          <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
            {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
            {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
            {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
          </a>
        </div>
      </>
    );
  };

  const renderDefault = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <CamposCadastroDinamicos
          configuracaoTela={configuracaoTela}
          formik={formik}
        />
      </>
    );
  };

  const renderCampos = () => {
    let component: any[] = [];
    configuracoesTela.forEach((configuracaoTela) => {
      if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        component.push(renderImportadorArquivos());
        return;
      }
      component.push(renderDefault(configuracaoTela));
    });

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Cadastro de Honorário`}>
        {honorario.honorarioId > 0 ? (
          <>
            <button onClick={() => navigate("/XpayHonorario/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{honorario.honorarioId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !tipoHonorario.tipoHonorarioId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarHonorarioLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarHonorarioLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarHonorarioLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>


      <div className="row mt-2">
        {carregandoTipoHonorario ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipos de honorarios...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Honorário
            </label>
            <select
              name="tipoHonorarioId"
              value={formik.values.tipoHonorarioId}
              className={"form-select"}
              onChange={(event: any) => onChangeTipoHonorario(event.target.value, event.target[event.target.selectedIndex].text)}
              placeholder="Nome"
              id="form-area"
            >
              <option value="0"> Selecione um tipo de honorário</option>
              {tipoHonorarios.map((tipo) => {
                return (
                  <option key={tipo.opcaoId} value={tipo.opcaoId}>
                    {tipo.opcaoNome}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
      <hr></hr>
      <>
        {renderCampos()}
      </>
    </>
  );
};

export default HonorarioCadastroPage;
