import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";

import IOption from "../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaObrigacaoRequest } from "../../interfaces/Requests/Obrigacao/AdicionaObrigacaoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ObrigacaoService from "../../services/ObrigacaoService";
import TipoObrigacaoService from "../../services/TipoObrigacaoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Solucao/CamposCadastroSolucoes";
import { tipoObrigacaoInicialValues } from "../../contexts/InitialValuesContext";
import { tipoPorModulo } from "../../utils/Utils";

const ObrigacaoCadastroPage = () => {
  const [carregandoTiposObrigacao, setCarregandoTiposObrigacao] = useState<boolean>(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { obrigacao, setObrigacao, setTipoObrigacao, tipoObrigacao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: AdicionaObrigacaoRequest = {
    obrigacaoId: 0,
    principalId: 0,
    tipoObrigacaoId: 0,

    modulo: EModulo.Obrigacao,
    tela: ETelaEnum.TelaCadastroObrigacao,
    categoriaId: obrigacao.categoriaId || 0,

    processoId: obrigacao.processoId || 0,
    areaDireitoId: obrigacao.areaDireitoId || 0,

    imovelId: obrigacao.imovelId || 0,
    tipoImovelId: obrigacao.tipoImovelId || 0,

    codigo: "",
    concluida: false,
    dataintimacao: null,
    descricaomulta: "",
    descricaoobrigacao: "",
    origemobrigacaoId: 0,
    possuimulta: false,
    prazo: null,
    valormultabase: 0,
    databasemulta: null,
    dataconclusao: null,
    periodicidadeId: 0,

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Obrigação",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro da obrigacao..",
            showConfirmButton: false,
          });

          Swal.showLoading();

          setAdicionarLoading(true);

          let resultado = await ObrigacaoService.adicionaObrigacao({
            ...values,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });


          Swal.hideLoading();

          setObrigacao({
            ...resultado,
            obrigacaoId: resultado.obrigacaoId,
            tipoObrigacaoId: resultado.tipoObrigacaoId,
            categoriaId: resultado.tipoObrigacaoId,
            modulo: obrigacao.modulo,
            processoId: obrigacao?.processoId,
            areaDireitoId: obrigacao.areaDireitoId,
            tipoImovelId: obrigacao.tipoImovelId,
            imovelId: obrigacao?.imovelId
          });

          setTipoObrigacao(tipoObrigacao);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Obrigação cadastrada com sucesso`,
            timer: 4000,
          });

        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setTipoObrigacao(tipoObrigacaoInicialValues);

    carregarTipoObrigacao();

    setConfiguracoesTela([]);

    formik.setValues(initialValues);

  }, []);

  useEffect(() => {
    if (tipoObrigacao.tipoObrigacaoId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoObrigacao(tipoObrigacaoInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoObrigacao.tipoObrigacaoId]);

  const carregarTipoObrigacao = async () => {
    try {

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(obrigacao.modulo)}Id=${obrigacao.categoriaId}`);

      setCarregandoTiposObrigacao(true);

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await TipoObrigacaoService.obterListaLimitadaTipoObrigacao({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        modulo: obrigacao.modulo,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...objeto
      });
      
      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));
      setOptions(options);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setCarregandoTiposObrigacao(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(obrigacao.modulo)}Id=${obrigacao.categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        modulo: obrigacao.modulo,
        tela: ETelaEnum.TelaCadastroObrigacao,
        tipoObrigacaoId: tipoObrigacao.tipoObrigacaoId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const onChangeTipoObrigacao = (value: number, texto: string) => {
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoObrigacaoId", value);

    formik.setFieldValue("categoriaId", value);

    setTipoObrigacao({
      tipoObrigacaoId: value,
      nome: texto,
      opcaoNome: "",
      codigo: "",
      status: "",
      opcaoId: 0,
      categoriaNome: "",
      moduloNome: "",
      categoriaId: 0,
      modulo: EModulo.Default
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos key={configuracaoTela.campo} configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />
      <LayoutPageForButton title={`Nova Obrigação ${tipoObrigacao.tipoObrigacaoId > 0 ? " - " + tipoObrigacao.nome : ""}`}>
        {obrigacao.obrigacaoId > 0 ? (
          <>
            <button onClick={() => navigate("/Obrigacao/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{obrigacao.obrigacaoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoObrigacaoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposObrigacao ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de obrigações...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Obrigação:
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoObrigacaoId)?.label,
                value: formik.values.tipoObrigacaoId,
              }}
              onChange={(option: any) => onChangeTipoObrigacao(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default ObrigacaoCadastroPage;
