
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { TableColumn } from 'react-data-table-component';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UsuarioService from '../../services/UsuarioService';

import ICliente from '../../interfaces/ICliente';
import IRecuperaLista from '../../interfaces/IRecuperaLista';

import { useAuth } from "../../contexts/AuthProvider";
import GridPadrao from '../../components/Comum/GridPadrao';
import PaginationRequest from "../../interfaces/Requests/PaginationRequest";

interface IClienteModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
}

const TrocaUsuarioModal = ({ toggleModal, exibirModal }: IClienteModalProps) => {
    const { changeClient } = useAuth();
    const [carregandoClientes, setCarregandoClientes] = useState(false);
    const [clientes, setClientes] = useState<ICliente[]>([]);
    const [filtrosPesquisa, setFiltrosPesquisa] = useState<PaginationRequest>({
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'id'
    });

    const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });

    const handlePageChange = (page: number, totalRows: number) => setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });

    const handleSort = async (column: TableColumn<ICliente>, sortDirection: string) => setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });

    useEffect(() => {
        if (exibirModal) carregarClientes();
    }, [exibirModal]);

    const carregarClientes = async () => {
        try {
            setCarregandoClientes(true);
            let resultado: IRecuperaLista<any>;
            resultado = await UsuarioService.obterClientesPorEscritorio();
            setClientes(resultado.data);
            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
        } catch (error: any) {
            setClientes([]);
        }
        finally {
            setCarregandoClientes(false);
        }
    }

    const colunas: TableColumn<ICliente>[] = [
        {
            name: 'Id',
            sortField: 'clienteId',
            selector: (row: ICliente) => row.clienteId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Nome',
            sortField: 'clienteNome',
            selector: (row: ICliente) => row.clienteNome,
            sortable: true,
            wrap: true
        },
        {
            name: 'CNPJ',
            sortField: 'clienteDocumento',
            selector: (row: ICliente) => row.clienteDocumento,
            sortable: true,
            wrap: true
        },
    ]

    const onRowClicked = async (row: ICliente) => {

        toggleModal();

        const swalWithBootstrapButtons = Swal.mixin({
            heightAuto: false,
            customClass: {
                confirmButton: "btn btn-orange",
                cancelButton: "btn btn-danger ms-5",
            },
            buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
            title: row.clienteNome,
            text: "Deseja realmente trocar de cliente?",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

            await changeClient(row.clienteId);

            setCarregandoClientes(false);
        }
    };

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">

                <div className="modal-header">
                    <h5 className="modal-title text-orange">Deseja trocar o cliente?</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
                        <GridPadrao onSort={handleSort} onRowClicked={onRowClicked} progressPending={carregandoClientes} limit={filtrosPesquisa.limit} onChangePage={handlePageChange} onChangeRowsPerPage={handlePerRowsChange} paginationServer={true} paginationTotalRows={filtrosPesquisa.totalItems} colunas={colunas} tipo='Esfera' itens={clientes} />
                    </div>  
                </div>

                <div className="modal-footer" style={{ margin: '0 auto' }} />
            </div>
        </Modal >
    </>
}

export default TrocaUsuarioModal;