import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { DragDropContext } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { faInfo, faPaperPlane, faProjectDiagram, faRobot } from "@fortawesome/free-solid-svg-icons";

import { orderByNome, tipoPorModulo } from "../../../utils/Utils";

import { ICampo } from "../../../interfaces/ICampo";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import { EModulo } from "../../../enum";
import { ETelaEnum, TelaFluxo } from "../../../enum/";
import { ETipoTelaEnum } from "../../../enum/ETipoTelaEnum";

import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import Spinner from "../../../components/Spinner";
import CamposXField from "../XCampos/CamposXField";
import Column from "../../../components/Comum/Column";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageForButton from "../../../layout/LayoutPageButton";
import { notifySuccess } from "../../../components/Comum/Toast/ToastFormik";

import "./../Styles/dragdrop.css";

const ConfiguracaoTelaPage = () => {

  const { fluxoFase, tela, setTela, setCampos, campos, eCampos, setECampos } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [processamentoAoSalvar, setProcessamentoAoSalvar] = useState<boolean>(false);
  const [carregandoConfiguracaoTela, setCarregandoConfiguracaoTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const [columns, setColumns] = useState<any>({
    todo: {
      id: "todo",
      list: [],
    },
    doing: {
      id: "doing",
      list: [],
    },
  });
  const [exibirModalXCampos, setExibirModalXCampos] = useState<boolean>(false);

  const setarOrdenacao = () => columns.doing.list.forEach((campo: ICampo, index: number) => (campo.ordenacao = index)); // preencher o campo ordenação de acordo com a index

  const toggleXCampos = (): void => setExibirModalXCampos(!exibirModalXCampos);

  useEffect(() => {
    if (!carregandoConfiguracaoTela) {

      carregarConfiguracaoDoing();

      carregarConfiguracaoTodo();

    }
  }, [tela]);

  useEffect(() => {
    if (fluxoFase.fluxoFaseId === 0) {
      navigate("/Fluxo/FluxoFase");
    }
  }, [fluxoFase.fluxoFaseId]);

  useEffect(() => {
    setarOrdenacao();
    if (columns.doing.list.length > 0) {
      setCampos(columns.doing.list);
    }
  }, [columns.doing]);


  useEffect(() => {
    if (tela === ETelaEnum.TelaConcluirFase)
      setColumns({
        todo: {
          id: "todo",
          list: tratarECampos(eCampos, campos),
        },
        doing: {
          id: "doing",
          list: campos,
        },
      });

    if (tela === ETelaEnum.TelaCapaFase)
      setColumns({
        todo: {
          id: "todo",
          list: tratarECampos(eCampos, campos),
        },
        doing: {
          id: "doing",
          list: campos,
        },
      });
  }, [campos.length, eCampos.length]);

  const onDragEnd = ({ source, destination }: any) => {
    try {
      if (destination === undefined || destination === null) return null;

      if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

      const start = columns[source.droppableId];
      const end = columns[destination.droppableId];

      if (start === end) {
        const newList = start.list.filter((_: any, idx: number) => idx !== source.index);

        newList.splice(destination.index, 0, start.list[source.index]);
        const newCol = {
          id: start.id,
          list: newList,
        };

        setColumns((state: any) => ({ ...state, [newCol.id]: newCol }));
      } else {
        const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);

        const newStartCol = {
          id: start.id,
          list: newStartList,
        };
        const newEndList = end.list;

        newEndList.splice(destination.index, 0, eCampos.filter((e) => e.campo === start.list[source.index].campo)[0]);

        const newEndCol = {
          id: end.id,
          list: newEndList,
        };

        setColumns((state: any) => ({
          ...state,
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol,
        }));
        setarOrdenacao();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const alterarItem = (item: ICampo) => {
    columns.doing.list = columns.doing.list.filter((e: ICampo) => e.campo !== item.campo);
    columns.doing.list.splice(item.ordenacao, 0, item);
    setColumns({
      todo: {
        id: "todo",
        list: columns.todo.list,
      },
      doing: {
        id: "doing",
        list: columns.doing.list,
      },
    });
  };

  const tratarECampos = (todo: any, doing: any) => {
    let lista: ICampo[] = [];
    todo.forEach((campo: any, index: number) => {
      if (doing.filter((e: any) => e.campo === campo.campo).length == 0) {
        lista.push({ ...campo, ordenacao: (index + 1) });
      }
    });

    return lista;
  }

  const tratarResponseECampos = (campos: ICampo[]) => {
    let lista: ICampo[] = [];
    campos.forEach(campo => {
      campo.modulo = fluxoFase.modulo;
      eval(`campo.${tipoPorModulo(EModulo.Compromisso)}Id=${fluxoFase.fluxoFaseId}`);
      lista.push(campo);
    });
    return lista;
  }

  const carregarConfiguracaoTodo = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let todo: IRecuperaLista<any>;

      todo = await ConfiguracaoTelaService.obterTodo(TelaFluxo(fluxoFase.modulo, ETipoTelaEnum.Cadastro));

      setECampos(tratarResponseECampos(todo.data?.slice()?.sort(orderByNome)));

      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setECampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const carregarConfiguracaoDoing = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let doing: IRecuperaLista<any>;
      doing = await ConfiguracaoTelaService.obterDoing({ fluxoFaseId: fluxoFase.fluxoFaseId, tela: tela, modulo: EModulo.Compromisso });
      setCampos(doing.data);

      setarOrdenacao();
      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setCampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const adicionarConfiguracao = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Salvar Configuração de Tela ",
        text: `Você realmente deseja salvar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        setProcessamentoAoSalvar(true);

        await ConfiguracaoTelaService.adicionaConfiguracaoTela({
          tela: tela,
          modulo: EModulo.Compromisso,
          fluxoFaseId: fluxoFase.fluxoFaseId,
          camposList: columns.doing.list,
        });

        notifySuccess('Campos cadastrados com sucesso');

      }
      setProcessamentoAoSalvar(false);
    } catch (error: any) {
      setProcessamentoAoSalvar(false);
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel cadastrar os campos.`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };


  const popover = () => (
    <Popover id="popover-basic" style={{ marginLeft: "-20px" }}>
      <Popover.Body style={{ cursor: "pointer" }}>
        {!carregandoConfiguracaoTela ? (
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Cadastro</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(TelaFluxo(fluxoFase.modulo, ETipoTelaEnum.Cadastro));
                    navigate("/Fluxo/ConfiguracaoTela/Cadastro");
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-input"></span>
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Capa</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(TelaFluxo(fluxoFase.modulo, ETipoTelaEnum.Capa));
                    navigate("/Fluxo/ConfiguracaoTela/Capa");
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Fluxo</Tooltip>}>
              {({ ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => navigate("/Fluxo/FluxoFase")}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <FontAwesomeIcon className="mx-2 text-orange" icon={faProjectDiagram} />
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Automação</Tooltip>}>
              {({ ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => navigate("/Fluxo/ConfiguracaoTela/Automacao")}
                  style={{
                    border: "none",
                    background: "none",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  {...triggerHandler}
                >
                  <FontAwesomeIcon className="mx-2 text-orange" icon={faRobot} />
                </Button>
              )}
            </OverlayTrigger>
          </div>
        ) : (
          <Spinner />
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <CamposXField setCamposList={setCampos} camposList={campos} exibirModal={exibirModalXCampos} toggleModal={toggleXCampos} modulo={fluxoFase.modulo} categoriaId={fluxoFase.fluxoFaseId} />

      <ToastContainer />

      <LayoutPageTitle title={`${tela === ETelaEnum.TelaConcluirFase ? "Cadastro" : "Capa"} - #${fluxoFase.fluxoFaseId} - ${fluxoFase.descricao}`}>
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover()}>
          <a style={{ cursor: "pointer" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageTitle>

      <LayoutPageForButton title={""}>
        <div className="col-md-11">
          <button disabled={processamentoAoSalvar} onClick={() => setExibirModalXCampos(true)} type="button" className="btn btn-orange me-5">
            X CAMPOS <FontAwesomeIcon color={"white"} className="mx-2" icon={faInfo} />
          </button>
        </div>
        <div className="col-md-10">
          <button disabled={processamentoAoSalvar} onClick={() => adicionarConfiguracao()} type="button" className="btn btn-orange me-5">
            {!processamentoAoSalvar && (
              <>
                SALVAR
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
              </>
            )}
            {processamentoAoSalvar && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Salvando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </LayoutPageForButton>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row mt-2">
          {Object.values(columns).map((col: any) => (
            <div className="col-md-6">
              {col.id === "doing" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique para configurar os campos selecionados.
                    </h6>
                  </div>
                </p>
              )}
              {col.id === "todo" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique segure e arraste para selecionar um campo.
                    </h6>
                  </div>
                </p>
              )}
              <div style={{ overflow: "auto", height: "650px" }}>
                <Column col={col} key={col.id} alterarItem={alterarItem} />
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>
    </>

  );
};

export default ConfiguracaoTelaPage;
