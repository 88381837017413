import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ISeguradora from "../interfaces/ISeguradora";
import { AdicionaSeguradoraRequest } from "../interfaces/Requests/Seguradora/AdicionaSeguradoraRequest";
import FiltroPesquisaSeguradoraRequest from "../interfaces/Requests/Seguradora/FiltroPesquisaSeguradoraRequest";
import { EditarSeguradoraRequest } from "../interfaces/Requests/Seguradora/EditarSeguradoraRequest";

class SeguradoraService {

    async obterSeguradoras(filtros: FiltroPesquisaSeguradoraRequest): Promise<IRecuperaLista<ISeguradora>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ISeguradora>>(`Seguradora/RecuperaSeguradora?${query}`);

        return result;
    }

    async adicionaSeguradora(adicionaSeguradora: AdicionaSeguradoraRequest): Promise<ISeguradora> {
        let { data: result } = await api.post<ISeguradora>(`Seguradora/AdicionaSeguradora`, adicionaSeguradora);

        return result;
    }


    async alterarStatus(seguradoraId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ISeguradora>(`Seguradora/AtualizaSeguradoraPorId?id=${seguradoraId}`, data);
    }

    async atualizarSeguradora(editarSeguradora: EditarSeguradoraRequest): Promise<void> {

        let dataSeguradora = [
            { "op": "replace", "path": "/nome", "value": editarSeguradora.nome },
            { "op": "replace", "path": "/codigo", "value": editarSeguradora.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Seguradora/AtualizaSeguradoraPorId?id=${editarSeguradora.seguradoraId}`, dataSeguradora)
        ]);
    }
}


export default new SeguradoraService();