import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import Sun from "./sun.png";
import Moon from "./moon.png";
import { darkTheme, lightTheme } from "../../../../theme";
import { useAuth } from "../../../contexts/AuthProvider";
import { generateColorPalette } from "../../../utils/GeneratePallette";

const ProfileThemeContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  width: 100%;
  /* max-height: 90px; */
`;

const ColorInput = styled.input`
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  opacity: 0;
  
`;

const ColorList = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  border-right: 2px solid var(--primary-base);
  padding: 2px;
  margin-right: 10px;
  padding-right: 10px;
`;

const ColorBox = styled.div<{ color: string}>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const ModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60px;
  min-width: 60px;
`;
const SectionTittle = styled.span`
  color: var(--primary-base);
  font-weight: 600;
  position: relative;
`;
const ThemeMode = styled.div`
  flex: 9;
  max-width: 70px;
  max-height: 90px;
  /* max-height: 100%; */
  border: 2px solid var(--primary-base);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
  cursor: pointer;
  transition: 0.5s;
  padding-top: 4px;
`;
const ThemeImage = styled.img`
  width: 50%;
`;
const ThemeTittle = styled.span`

max-height: 50%;
text-align: center;
color: var(--primary-base);
`;

const ColorContainer = styled.div`
display: flex;
width: 70%;
gap: 12px;
margin-left: 8px;
`

const ColorSection = styled.div`
display: flex;
flex-direction: column;


`

const ColorTittle = styled.span`
color: var(--primary-base);
font-weight: 600;
`

const ProfileTheme: React.FC = () => {
  const { user, updateUser } = useAuth();
  const [darkLightMode, setDarkLightMode] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [colorPrimary, setColorPrimary] = useState<string>("#cacaca");
  const [colorSecondary, setColorSecondary] = useState<string>("#cacaca");
  const [currentColorPallete, setCurrentColorPallete] = useState<string[]>([
    "#cacaca",
    "#cacaca",
    "#cacaca",
    "#cacaca",
    "#cacaca",
    "#cacaca",
    "#cacaca",
    "#cacaca",
  ]);

  const { setSelectedTheme, selectedTheme, setThemeMode, themeMode } =
    useContext<OrangeInterfaceContext>(OrangeContext);

  useEffect(() => {
    if (user) {
      const corTemplate = user?.corTemplate || ''; // Garante que corTemplate não seja undefined
      const [primeiraCor, segundaCor] = corTemplate.split('|'); // Desestrutura as cores
    
      setColorPrimary(primeiraCor);
      setColorSecondary(segundaCor); // Define a cor secundária
    }
  

  }, [])
  

  return (
    <ProfileThemeContainer>
      <ColorList>
      <ColorSection>

      <ColorTittle>Primária / Secundária</ColorTittle>
      <ColorContainer>
        
            <ColorBox
              color={colorPrimary}
            >
              <ColorInput
                type="color"
                value={colorPrimary}
                onChange={(e) => {
                  setColorPrimary(e.target.value);
                }}
                onBlur={() => {
                  const colorPalette = generateColorPalette(colorPrimary);
                  setCurrentColorPallete(colorPalette);
                  let finalPallette = [colorPrimary, ...colorPalette];
                  setSelectedTheme({
                    primaryBase: finalPallette[0],
                    primaryBase2: finalPallette[1],
                    primaryBaseLight1: finalPallette[2],
                    primaryBaseLight2: finalPallette[3],
                    primaryBaseLight3: finalPallette[4],
                    primaryBaseDark1: finalPallette[5],
                    primaryBaseDark2: finalPallette[6],
                    primaryBaseDark3: finalPallette[7],
                    secondaryBase: colorSecondary,
                  });
                }}
              />
            </ColorBox>
            <ColorBox
              color={colorSecondary}
            >
              <ColorInput
                type="color"
                value={colorSecondary}
                onChange={(e) => {
                  setColorSecondary(e.target.value);
                }}
                onBlur={() => {
                  const colorPalette = generateColorPalette(colorPrimary);
                  setCurrentColorPallete(colorPalette);
                  let finalPallette = [colorPrimary, ...colorPalette];
                  setSelectedTheme({
                    primaryBase: finalPallette[0],
                    primaryBase2: finalPallette[1],
                    primaryBaseLight1: finalPallette[2],
                    primaryBaseLight2: finalPallette[3],
                    primaryBaseLight3: finalPallette[4],
                    primaryBaseDark1: finalPallette[5],
                    primaryBaseDark2: finalPallette[6],
                    primaryBaseDark3: finalPallette[7],
                    secondaryBase: colorSecondary,
                  });
                }}
              />
            </ColorBox>
      </ColorContainer>
          </ColorSection>
          <ColorSection>

      <ColorTittle>Padrão / Escuro</ColorTittle>
      <ColorContainer>
        
            <ColorBox
              color={lightTheme.primaryBase}
              onClick={()=> setSelectedTheme(lightTheme)}
            >
              
            </ColorBox>
            <ColorBox
              color={darkTheme.primaryBase}
              onClick={()=> setSelectedTheme(darkTheme)}
            >
              
            </ColorBox>
      </ColorContainer>
          </ColorSection>
      </ColorList>
      <ModeContainer>
        <SectionTittle>Modo</SectionTittle>
        <ThemeMode
          style={{
            backgroundColor:
              darkLightMode === "dark" ? `var(--primary-base)` : undefined,
          }}
          onClick={() =>
            setDarkLightMode((prev) => {
              if (prev == "" || prev == "light") {
                setThemeMode("dark")
                return "dark";
              } else {
                setThemeMode("light")
                return "light";
              }
            })
          }
        >
          {darkLightMode == "light" || darkLightMode == "" ? (
            <>
              {" "}
              <ThemeImage src={Sun}></ThemeImage>
              <ThemeTittle>Modo Claro</ThemeTittle>
            </>
          ) : (
            <>
              {" "}
              <ThemeImage src={Moon}></ThemeImage>
              <ThemeTittle
                style={{
                  color: darkLightMode === "dark" ? `white` : undefined,
                }}
              >
                Modo Escuro
              </ThemeTittle>
            </>
          )}
        </ThemeMode>
      </ModeContainer>
    </ProfileThemeContainer>
  );
};

export default ProfileTheme;
