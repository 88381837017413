import React, { useEffect,useRef} from 'react';
import { AiOutlineFileSync } from 'react-icons/ai';

export interface PaginaPDFProps {
    props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    paginaNumero: number,
    paginaTotal: number,
    telaCheia?:boolean,
    onCreate: (paginaNumero: number, imagem: HTMLImageElement) => Promise<void>,
}

export default function PaginaPDF(props: PaginaPDFProps) {
    const imagemRef = useRef<HTMLImageElement>(null);

    const atualizarPagina = async ()=>{
        if(props.paginaNumero === 1){
            console.log("atualizarPagina");
        }
        if(imagemRef.current){
            const imagem = imagemRef.current as HTMLImageElement;
            try{
                await props.onCreate(props.paginaNumero, imagem);
            }catch(error){
                console.log(error);
            }
        }
    }   
    
    useEffect(() => {
        atualizarPagina();
    }, []);
    
    return <>
        <div
            className='pdf-pagina-conteiner carregando d-block'
            style={(props.telaCheia)?{
                maxWidth: '100%'
            }:{}}
        >
            <div className="capa">
                <i>
                    <AiOutlineFileSync/>
                </i>
            </div>
            {React.createElement('img', {
                ...props.props,
                ref: imagemRef,
                url: undefined
            })}
        </div>
    </>;
}