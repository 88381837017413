import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IFormaPagamento from "../interfaces/IFormaPagamento";
import { AdicionaFormaPagamentoRequest } from "../interfaces/Requests/FormaPagamento/AdicionaFormaPagamentoRequest";
import FiltroPesquisaFormaPagamentoRequest from "../interfaces/Requests/FormaPagamento/FiltroPesquisaFormaPagamentoRequest";
import { EditarFormaPagamentoRequest } from "../interfaces/Requests/FormaPagamento/EditarFormaPagamentoRequest";

class FormaPagamentoService {

    async obterFormaPagamentos(filtros: FiltroPesquisaFormaPagamentoRequest): Promise<IRecuperaLista<IFormaPagamento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFormaPagamento>>(`FormaPagamento/RecuperaFormaPagamento?${query}`);

        return result;
    }

    async adicionaFormaPagamento(adicionaFormaPagamento: AdicionaFormaPagamentoRequest): Promise<IFormaPagamento> {
        let { data: result } = await api.post<IFormaPagamento>(`FormaPagamento/AdicionaFormaPagamento`, adicionaFormaPagamento);

        return result;
    }


    async alterarStatus(formaPagamentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IFormaPagamento>(`FormaPagamento/AtualizaFormaPagamentoPorId?id=${formaPagamentoId}`, data);
    }

    async atualizarFormaPagamento(editarFormaPagamento: EditarFormaPagamentoRequest): Promise<void> {

        let dataFormaPagamento = [
            { "op": "replace", "path": "/nome", "value": editarFormaPagamento.nome },
            { "op": "replace", "path": "/codigo", "value": editarFormaPagamento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`FormaPagamento/AtualizaFormaPagamentoPorId?id=${editarFormaPagamento.formaPagamentoId}`, dataFormaPagamento)
        ]);
    }
}


export default new FormaPagamentoService();