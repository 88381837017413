import { useNavigate, useParams } from "react-router-dom";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import IGrupoUsuarios from "../../../../interfaces/IGrupoUsuarios";
import { useCallback, useEffect, useState } from "react";
import GrupoUsuariosService from "../../../../services/GrupoUsuariosService";
import Carregando from "../../../../components/Carregando";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEraser,
  faPlus,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UsuarioService from "../../../../services/UsuarioService";
import IUsuario from "../../../../interfaces/IUsuario";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import Column from "./components/Column";
import Swal from "sweetalert2";
import IUsuariosGrupoUsuarios from "../../../../interfaces/IUsuariosGrupoUsuarios";

interface Columns {
  [key: string]: {
    id: string;
    title: string;
    usuarioIds: number[];
  };
}

export interface UsuarioType {
  id: number;
  nome: string;
}

const GrupoUsuarioAddUsers = () => {
  const [columnsOrder] = useState(["column-1", "column-2"]);
  const [columns, setColumns] = useState<Columns>({
    "column-1": {
      id: "column-1",
      title: "",
      usuarioIds: [],
    },

    "column-2": {
      id: "column-2",
      title: "",
      usuarioIds: [],
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadingGrupo, setLoadingGrupo] = useState<boolean>(false);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [usuariosTotal, setUsuariosTotal] = useState<IUsuario[]>([]);

  const navigate = useNavigate();

  const [grupo, setGrupo] = useState<IGrupoUsuarios>({
    grupoId: 0,
    nome: "",
    status: 0,
    dataCadastro: new Date(),
    moduloId: 0,
    moduloNome: "",
    usuarios: [],
  });

  const { id } = useParams();

  useEffect(() => {
    getGrupo();
  }, [id]);

  useEffect(() => {
    getUsuarios();
  }, []);

  useEffect(() => {
    setLoading(true);

    if (grupo) {
      const usuariosGrupo = grupo.usuarios;

      setColumns({
        "column-1": {
          id: "column-1",
          title: "Usuários do grupo",
          usuarioIds: usuariosGrupo.map((usuario) => usuario.usuarioId),
        },

        "column-2": {
          id: "column-2",
          title: "Usuários totais",
          usuarioIds: [],
        },
      });

      if (usuariosTotal.length > 0) {
        const usersRestantes = usuariosTotal.filter((usuario) => {
          return !usuariosGrupo.some(
            (usuarioGrupo) => usuario.usuarioId === usuarioGrupo.usuarioId
          );
        });

        setColumns({
          "column-1": {
            id: "column-1",
            title: "Usuários do grupo",
            usuarioIds: usuariosGrupo.map((usuario) => usuario.usuarioId),
          },

          "column-2": {
            id: "column-2",
            title: "Usuários totais",
            usuarioIds: usersRestantes.map((usuario) => usuario.usuarioId),
          },
        });
      }
    }
    setLoading(false);
    setLoaded(true);
  }, [grupo, usuariosTotal]);

  const getGrupo = useCallback(async () => {
    try {
      setLoadingGrupo(true);
      const data = await GrupoUsuariosService.obterGrupoUsuarioUnico(id ?? "0");

      setGrupo(data.data[0]);
    } catch (error) {
      console.error("Erro ao buscar o gupo de usuários: ", error);
    } finally {
      setLoadingGrupo(false);
    }
  }, []);

  const getUsuarios = useCallback(async () => {
    try {
      setUsersLoading(true);

      if (nome === "" && email === "") {
        setUsuariosTotal([]);
        setUsersLoading(false);

        return;
      }

      const data = await UsuarioService.obterUsuarios({
        offset: 0,
        limit: 1000,
        sort: "nomeUsuario",
        totalItems: 0,
        nomeUsuario: nome,
        clientes: [],
        email: email,
        numeroDocumento: "",
        status: 1,
      });

      setUsuariosTotal(data.data);
    } catch (error) {
      console.error("Erro ao buscar o gupo de usuários: ", error);
    } finally {
      setUsersLoading(false);
    }
  }, [nome, email]);

  const handleDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    // caso não seja a mesma coluna (start == finish) terminar o fluxo
    if (start === finish) {
      return;
    }

    // caso o destino seja para a lista de usuários totais, executar um fluxo diferente
    if (destination.droppableId === "column-2") {
      const startUsuarioIds = Array.from(start.usuarioIds);
      startUsuarioIds.splice(source.index, 1);

      const newStart = {
        ...start,
        usuarioIds: startUsuarioIds,
      };

      const newState = {
        ...columns,
        [newStart.id]: newStart,
      };

      setColumns(newState);

      await handleAddUser(startUsuarioIds);
    } else {
      const startUsuarioIds = Array.from(start.usuarioIds);
      startUsuarioIds.splice(source.index, 1);

      const newStart = {
        ...start,
        usuarioIds: startUsuarioIds,
      };

      const finishUsuarioIds = Array.from(finish.usuarioIds);
      finishUsuarioIds.splice(source.index, 0, parseInt(draggableId));

      const newFinish = {
        ...finish,
        usuarioIds: finishUsuarioIds,
      };

      const newState = {
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      };

      setColumns(newState);

      await handleAddUser(finishUsuarioIds);
    }
  };

  const handleAddUser = async (usuarioIds: number[]) => {
    try {
      await GrupoUsuariosService.editarGrupoUsuarios(id ?? "", {
        usuarios: usuarioIds,
      });

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Grupo de usuários atualizado com sucesso`,
        showConfirmButton: false,
        timer: 3000,
      });

      await getGrupo();
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: "Ocorreu um erro inesperado",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleAddAllUsers = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você deseja adicionar todos os usuários pesquisados ao grupo?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        const usuarios = [];
        usuarios.push(...grupo.usuarios.map((usuario) => usuario.usuarioId));
        usuarios.push(...usuariosTotal.map((usuario) => usuario.usuarioId));

        await GrupoUsuariosService.editarGrupoUsuarios(id ?? "0", {
          usuarios,
        });

        setNome("");
        setEmail("");

        await getGrupo();
        await getUsuarios();

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Grupo de usuários atualizado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: "Ocorreu um erro inesperado",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleRemoveAllUsers = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você deseja remover todos os usuários do grupo?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await GrupoUsuariosService.editarGrupoUsuarios(id ?? "0", {
          usuarios: [],
        });

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Grupo de usuários atualizado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        setNome("");
        setEmail("");

        await getGrupo();
        await getUsuarios();
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: "Ocorreu um erro inesperado",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  if (loadingGrupo || usersLoading || loading) {
    return <Carregando />;
  }

  return (
    <>
      <LayoutPageTitle
        title={`Grupo de usuários: ${grupo.nome}`}
      ></LayoutPageTitle>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await getUsuarios();
        }}
        className="row g-3 mb-3"
      >
        <div className="col-md-6 d-flex align-items-end">
          <div className="me-3" style={{ flex: 1 }}>
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Nome do Usuário:
            </label>
            <input
              placeholder="Nome"
              type="text"
              className="form-control"
              id="form-nome"
              value={nome}
              onChange={(e) => {
                e.preventDefault();
                setNome(e.target.value);
              }}
            />
          </div>

          <div className="me-3" style={{ flex: 1 }}>
            <label
              htmlFor="form-email"
              className="form-label fw-bolder text-orange"
            >
              Email do Usuário:
            </label>
            <input
              placeholder="Email"
              type="text"
              className="form-control"
              id="form-email"
              value={email}
              onChange={(e) => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-sm btn-orange search-buttom-margin me-2"
            >
              <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
            </button>
            <button
              onClick={async (e) => {
                e.preventDefault();
                setNome("");
                setEmail("");
              }}
              className="btn btn-sm btn-orange search-buttom-margin me-2"
            >
              <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
            </button>
            <button
              className="btn btn-sm btn-orange search-buttom-margin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/administracao/seguranca/grupousuarios");
              }}
            >
              Voltar
              <FontAwesomeIcon
                color={"white"}
                className="mx-2"
                icon={faArrowLeft}
              />
            </button>
          </div>
        </div>
      </form>

      <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <button className="btn btn-orange" onClick={handleAddAllUsers}>
          Adicionar todos os usuários
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>

        <button className="btn btn-orange" onClick={handleRemoveAllUsers}>
          Remover todos os usuários
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faTrash} />
        </button>
      </div>

      {loaded && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: 20,
              justifyContent: "center",
              marginTop: "26px",
            }}
          >
            {columnsOrder.map((columnId) => {
              if (columnId === "column-1") {
                const column = columns[columnId];

                const usuarios = column.usuarioIds
                  .map((usuarioId) => {
                    const usuarioIdEncontrado = grupo.usuarios.find(
                      (usuario) => usuario.usuarioId === usuarioId
                    );

                    return usuarioIdEncontrado;
                  })
                  .filter(
                    (usuario): usuario is IUsuariosGrupoUsuarios =>
                      usuario !== undefined
                  );

                return (
                  <Column
                    key={column.id}
                    column={column}
                    usuarios={usuarios}
                    customHolder={"Arraste algum usuário para este espaço"}
                  />
                );
              } else {
                const column = columns[columnId];

                const usuarios = column.usuarioIds
                  .map((usuarioId) => {
                    const usuarioIdEncontrado = usuariosTotal.find(
                      (usuario) => usuario.usuarioId === usuarioId
                    );

                    return {
                      nomeUsuario: usuarioIdEncontrado?.nome,
                      email: usuarioIdEncontrado?.email,
                      usuarioId: usuarioIdEncontrado?.usuarioId,
                    };
                  })
                  .filter(
                    (usuario): usuario is IUsuariosGrupoUsuarios =>
                      usuario !== undefined
                  );

                return (
                  <Column
                    key={column.id}
                    column={column}
                    usuarios={usuarios}
                    customHolder={
                      "Utilize o email ou o nome de algum usuário para pesquisa-lo!"
                    }
                  />
                );
              }
            })}
          </div>
        </DragDropContext>
      )}
    </>
  );
};

export default GrupoUsuarioAddUsers;
