import { useContext, useEffect, useRef, useState } from "react";
import { faPrint, faShare, faHistory, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { EPropriedadesTela } from "../../enum/EPropriedadesTela";
import { defaultSchema } from "../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaPropostaAcordoRequest } from "../../interfaces/Requests/PropostaAcordo/AdicionaPropostaAcordoRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaPropriedadesTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../enum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import RelatorioService from "../../services/RelatorioService";
import PropostaAcordoService from "../../services/PropostaAcordoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CamposCapaDinamicos from "../../components/Comum/Solucao/CamposCapaSolucoes";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { notifyFormikDanger, notifySuccess } from "../../components/Comum/Toast/ToastFormik";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";

const PropostaAcordoCapaPage = () => {
  const { propostaAcordo, tipoPropostaAcordo, setPropostaAcordo } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [propriedadesTela, setPropriedadesTela] = useState<IRecuperaPropriedadesTelaResponse[]>([]);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);

  const componentRef = useRef<any>();

  const compartilharWhatSapp = () => {
    return `Número do PropostaAcordo: *#${propostaAcordo.propostaAcordoId}* / ${tipoPropostaAcordo.tipoPropostaAcordoId > 0
      ? "Tipo de PropostaAcordo: *" + tipoPropostaAcordo.nome + "*"
      : ""
      }  / Status: *${propostaAcordo.statusPropostaAcordoNome == ""
        ? "Ativo*"
        : nomearStatusPropostaAcordo(propostaAcordo.statusPropostaAcordoNome)
      }*`;
  };

  const nomearStatusPropostaAcordo = (categoria: string) => {
    switch (categoria) {
      case "EmElaboracao":
        return "Em Elaboração";
      case "EmAprovacao":
        return "Em Aprovação";
      case "EmAssinatura":
        return "Em Assinatura";
      case "Assinado":
        return "Assinado";
      case "Cancelado":
        return "Cancelado";
      default:
        return "Não definido";
    }
  };

  const initialValues: AdicionaPropostaAcordoRequest = {
    propostaAcordoId: propostaAcordo.propostaAcordoId,
    principalId: propostaAcordo.propostaAcordoId,
    tipoPropostaAcordoId: propostaAcordo.tipoPropostaAcordoId,
    modulo: EModulo.PropostaAcordo,
    tela: ETelaEnum.TelaCapaPropostaAcordo,
    categoriaId: propostaAcordo.categoriaId || 0,

    processoId: propostaAcordo.processoId || 0,
    areaDireitoId: propostaAcordo.areaDireitoId || 0,

    imovelId: propostaAcordo.imovelId || 0,
    tipoImovelId: propostaAcordo.tipoImovelId || 0,

    validadeproposta: null,
    valoracordo: 0,
    descricao: "",
    datapagamento: null,
    multaatraso: false,
    valormultaatraso: 0,
    periodicidadeId: 0,
    codigo: "",
    realizadopor: "",
    dataCadastro: null,
    statusPropostaAcordoNome: "",
    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!propostaAcordo.propostaAcordoId) return;

    carregaCapa();

    formik.setValues(initialValues);

  }, [propostaAcordo.propostaAcordoId, refreshCapa]);

  useEffect(() => {
    if (!propostaAcordo.propostaAcordoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [propostaAcordo]);


  const inserirSessionStorage = () => sessionStorageService.inserir(propostaAcordo, "propostaAcordo_capa");


  const carregarSessionStorage = async () => setPropostaAcordo(JSON.parse((await sessionStorageService.obter("propostaAcordo_capa")) || null));


  const carregaCapa = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let resultado = await PropostaAcordoService.obterCapaPropostaAcordo(propostaAcordo.propostaAcordoId);
      setPropriedadesTela(resultado.dataPropriedades);
      setConfiguracoesTela(resultado.data);

    } catch (error: any) {
      console.log(error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (propostaAcordoId: number) => {
    try {
      let resultado = await PropostaAcordoService.adicionaFavorido(propostaAcordoId);
      setPropostaAcordo({ ...propostaAcordo, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await PropostaAcordoService.removerFavorido(favoritoId);
      setPropostaAcordo({ ...propostaAcordo, favoritoId: 0 });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const solicitarRelatorio = async (): Promise<void> => {

    try {

      await RelatorioService.solicitarRelatorio(propostaAcordo.propostaAcordoId, 2);

      notifySuccess('Solicitação realizada com sucesso, faça o acompanhaemento da sua solicitação no menu Relatório');

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: 'Não foi possível baixar arquivos',
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        timer: 4000,
        icon: 'error',
        showConfirmButton: false
      });
    } 
  }

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaDinamicos
          controller={"propostaAcordo"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });

    return <div className="row mt-12">{component}</div>;
  };




  return (
    <>
      <ToastContainer />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${propostaAcordo.propostaAcordoId}  ${tipoPropostaAcordo.tipoPropostaAcordoId > 0 ? " - " + tipoPropostaAcordo.nome : ""
            } => ${propostaAcordo.statusPropostaAcordoNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a
                style={{ cursor: "pointer", fontSize: "30px" }}
                onClick={() => setRefreshCapa(!refreshCapa)}
              >
                {
                  <FontAwesomeIcon
                    color={"#DC853D"}
                    className="mx-2"
                    icon={faHistory}
                  />
                }
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span
                className="indicator-progress"
                style={{
                  display: "block",
                  fontSize: "30px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>

          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faPrint}
                  title="Imprimir o propostaAcordo"
                />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {
              <FontAwesomeIcon
                color={"#DC853D"}
                className="mx-2"
                icon={faShare}
              />
            }
          </a>
          {propriedadesTela.find(e => e.codigo == EPropriedadesTela.BancoMasterPropostaVenda) &&
            <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => solicitarRelatorio()}>
              {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faMoneyBill} title="Formulário" />}
            </a>
          }
          {propostaAcordo.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(propostaAcordo.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o propostaAcordo"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(propostaAcordo.propostaAcordoId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o propostaAcordo"
            ></a>
          )}

          <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="bottom"
            overlay={propostaAcordo.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}
          >
            <a
              style={{ cursor: "pointer", marginRight: "10px" }}
              className="navigation-services-list-link"
            >
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderCampos()}
            <CompromissoListField modulo={EModulo.PropostaAcordo} setRefreshCapa={setRefreshCapa}
            />
            <FileListCapaField modulo={EModulo.PropostaAcordo} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela}
            />
          </>
        )}

        <hr />
      </div>
    </>
  );
};
export default PropostaAcordoCapaPage;
