import { faEraser, faSearch, faFileExcel, faEye, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import XFinderIntimacaoService from "../../services/XFinderIntimacaoService";
import FiltroPesquisaXFinderIntimacaoRequest from "../../interfaces/Requests/XFinder/FiltroPesquisaXFinderIntimacaoRequest";
import GridPadrao from "../../components/Comum/GridPadrao";
import IXFinderIntimacao from "../../interfaces/IXFinderIntimacao";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import Swal from "sweetalert2";
import DocumentoService from "../../services/DocumentoService";
import PreviewPDFField from "../../components/Comum/PreviewPDF/PreviewPDF";
import XFinderIntimacaoModal from "./XFinderIntimacaoModal";

const XFinderIntimacaoPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoXFinderIntimacoes, setCarregandoXFinderIntimacoes] = useState(false);
  const [xFinderIntimacoes, setXFinderIntimacoes] = useState<IXFinderIntimacao[]>([]);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);
  const [intimacaoId, setIntimacaoId] = useState<number>(0);
  const [relatorioId, setRelatorioId] = useState<number | null>();
  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaXFinderIntimacaoRequest>({
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    juntadoEmMaiorIgual: "",
    juntadoEmMenorIgual: "",
    nomeParteAutora: "",
    numeroProcesso: "",
    cNJTribunalId: [],
    conteudoEmTexto: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    status: 0,
    sort: "xFinderIntimacaoId",
  });

  useEffect(() => {
    if (!loadingInicial) {
      carregarXFinderIntimacoes(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);



  async function carregarXFinderIntimacoes(filtro: FiltroPesquisaXFinderIntimacaoRequest): Promise<void> {
    try {
      setCarregandoXFinderIntimacoes(true);
      let resultado: IRecuperaLista<IXFinderIntimacao>;

      resultado = await XFinderIntimacaoService.obterXFinderIntimacoes(filtro);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setRelatorioId(resultado.relatorioId || null);
      setXFinderIntimacoes(resultado.data);
      setAtualizaTabela(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros.",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setXFinderIntimacoes([]);
      setRelatorioId(null);
    } finally {
      setCarregandoXFinderIntimacoes(false);
      setLoadingInicial(false);
    }
  }

  function toggleFormModal(carregarDados?: boolean): void {
    if (carregarDados) {
      carregarXFinderIntimacoes(filtrosPesquisa);
    }

    setExibirModal(!exibirModal);
  }

  useEffect(() => {
    if (atualizarTabela) setTimeout(() => carregarXFinderIntimacoes(filtrosPesquisa), 1000);
  }, [atualizarTabela]);

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const verificaIsPreview = (extensao: string) => {
    if (extensao.endsWith(".pdf") || extensao.endsWith(".mp4")) return true;
    else return false;
  };

  const colunas: TableColumn<IXFinderIntimacao>[] = [
    {
      name: "Id",
      sortField: "xFinderIntimacaoId",
      selector: (row: IXFinderIntimacao) => row.xFinderIntimacaoId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IXFinderIntimacao) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo",
      sortField: "titulo",
      selector: (row: IXFinderIntimacao) => row.titulo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Órgão",
      sortField: "orgao",
      selector: (row: IXFinderIntimacao) => row.orgao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Autor",
      sortField: "nomeParteAutora",
      selector: (row: IXFinderIntimacao) => row.nomeParteAutora,
      sortable: true,
      wrap: true,
    },
    {
      name: "Réu",
      sortField: "nomeParteRe",
      selector: (row: IXFinderIntimacao) => row.nomeParteRe,
      sortable: true,
      wrap: true,
    },
    {
      name: "Número Processo",
      sortField: "numeroProcesso",
      selector: (row: IXFinderIntimacao) => row.numeroProcesso,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Autuação/Expedição",
      sortField: "dataAutuacao",
      selector: (row: IXFinderIntimacao) => {
        if (row.dataAutuacao === null) return "-";
        const data = row.dataAutuacao || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    }/*,
    {
      name: "Data Ciência Parte",
      sortField: "dataCienciaParte",
      selector: (row: IXFinderIntimacao) => {
        if (row.dataCienciaParte === null) return "-";
        const data = row.dataCienciaParte || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    }*/,
    {
      name: "",
      cell: (xFinderIntimacao: IXFinderIntimacao) => {
        return (
          <>
            <div>
              <>
                {verificaIsPreview(xFinderIntimacao.documentos[0]?.extensao || "") && (
                  <FontAwesomeIcon
                    title="Preview"
                    onClick={() => previewArquivo(xFinderIntimacao.documentos[0].documentoId)}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faEye}
                  />
                )}
            {xFinderIntimacao.status === "Pendente" && <FontAwesomeIcon title="Marcar como Lido" onClick={() => {
              setIntimacaoId(xFinderIntimacao.xFinderIntimacaoId);
              setExibirModal(true);
            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faCheckCircle} />
                }

              </>
            </div>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IXFinderIntimacao>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  async function baixarRelatorio(): Promise<void> {
    if (!xFinderIntimacoes) return;

    if (relatorioId === 0 || relatorioId === null) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `A pesquisa não retornou nenhum resultado.`,
        showConfirmButton: true,
        timer: 4000,
      });
      return;
    }

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Baixando arquivos...",
      showConfirmButton: false,
    });
    Swal.showLoading();
    try {
      const arquivo = await XFinderIntimacaoService.gerarExcel(relatorioId || 0);
      if (arquivo) {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${new Date().toLocaleString("BR")} - XFinderIntimacoes.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      Swal.hideLoading();
      Swal.update({
        title: "Baixado com sucesso",
        text: "",
        icon: "success",
        showConfirmButton: true,
      });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível baixar arquivos",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  function limparFiltros() {
    setFiltrosPesquisa((oldValue: any) => {
      return {
        ...oldValue,
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        juntadoEmMaiorIgual: "",
        juntadoEmMenorIgual: "",
        nomeParteAutora: "",
        numeroProcesso: "",
        cNJTribunalId: [],
        conteudoEmTexto: "",
        status: 0,
      };
    });
  }

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  return (
    <>

      <XFinderIntimacaoModal
        toggleModal={toggleFormModal}
        exibirModal={exibirModal}
        intimacaoId={intimacaoId}
        setAtualizarTabela={setAtualizaTabela}
      />

      <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

      <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarXFinderIntimacoes(filtrosPesquisa);
              }}
              className="row g-3 mb-3 form-buscar-xfinder-distribuicao"
            >
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                  Data de Cadastro
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                      ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue: any) => {
                        return {
                          ...oldValue,
                          dataCadastroMaiorIgual: "",
                          dataCadastroMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-juntadoEm" className="form-label fw-bolder text-orange">
                  Documento Juntado Em:
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.juntadoEmMaiorIgual && filtrosPesquisa.juntadoEmMenorIgual
                      ? [filtrosPesquisa.juntadoEmMaiorIgual, filtrosPesquisa.juntadoEmMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue: any) => {
                        return {
                          ...oldValue,
                          juntadoEmMaiorIgual: "",
                          juntadoEmMenorIgual: "",
                        };
                      });
                      return;
                    }
                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        juntadoEmMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        juntadoEmMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status
                </label>
                <select
                  value={filtrosPesquisa.status}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, status: parseInt(e.target.value) };
                    });
                  }}
                  placeholder="Status"
                  className={"form-select"}
                  id="form-status"
                >
                  <option value={0}>Todos</option>
                  <option value={1}>Pendente</option>
                  <option value={2}>Processo não localizado</option>
                  <option value={3}>Concluída</option>
                  <option value={-1}>Excluída</option>
                </select>
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-nomeAutor" className="form-label fw-bolder text-orange">
                  Autor
                </label>
                <input
                  value={filtrosPesquisa.nomeParteAutora}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, nomeParteAutora: e.target.value };
                    });
                  }}
                  placeholder="Autor"
                  className={"form-control"}
                  id="form-nomeAutor"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-numero-processo" className="form-label fw-bolder text-orange">
                  Número Processo
                </label>
                <input
                  value={filtrosPesquisa.numeroProcesso}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, numeroProcesso: e.target.value };
                    });
                  }}
                  placeholder="Número Processo"
                  className={"form-control"}
                  id="form-numero-processo"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-conteudo" className="form-label fw-bolder text-orange">
                  Conteúdo
                </label>
                <input
                  value={filtrosPesquisa.conteudoEmTexto}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, conteudoEmTexto: e.target.value };
                    });
                  }}
                  placeholder="Conteúdo"
                  className={"form-control"}
                  id="form-conteudo"
                />
              </div>

              <div className="col-12 " style={{ textAlign: "right" }}>
                <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                  <FontAwesomeIcon color="white" className="" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-orange search-buttom-margin ms-2"
                  title="Limpar Filtro"
                >
                  <FontAwesomeIcon color="white" className="" icon={faEraser} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    baixarRelatorio();
                  }}
                  className="btn btn-orange search-buttom-margin ms-2"
                  title="Baixar Relatório"
                  disabled={relatorioId === null || carregandoXFinderIntimacoes}
                >
                  <FontAwesomeIcon color="white" className="" icon={faFileExcel} />
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-12 mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoXFinderIntimacoes}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="XFinderIntimacoes"
              itens={xFinderIntimacoes}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default XFinderIntimacaoPage;
