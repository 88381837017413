/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from "react-bootstrap";
import FiltroPesquisaContratoRequest  from "../../../interfaces/Requests/Contrato/FiltroPesquisaContratoRequest";
import { MultiSelect } from "react-multi-select-component";
import { useEffect, useState } from "react";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import PeriodicidadeService from "../../../services/PeriodicidadeService";
import IPeriodicidade from "../../../interfaces/IPeriodicidade";
import IDepartamento from "../../../interfaces/IDepartamento";
import DepartamentoService from "../../../services/DepartamentoService";
import IGestor from "../../../interfaces/IUsuario";
import GestorService from "../../../services/UsuarioService";
import IIndice from "../../../interfaces/IIndice";
import IndiceService from "../../../services/IndiceService";
import IMoeda from "../../../interfaces/IMoeda";
import MoedaService from "../../../services/MoedaService";
import IEmpresa from "../../../interfaces/IEmpresa";
import EmpresaService from "../../../services/EmpresaService";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { useAuth } from "../../../contexts/AuthProvider";

interface IContratoModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtro: FiltroPesquisaContratoRequest;
  setFiltro: (filtro: any) => void;
}

const FiltroModal = ({ toggleModal, exibirModal, filtro, setFiltro, setPesquisar }: IContratoModalProps) => {
  const [carregamentoPeriodicidade, setCarregamentoPeriodicidade] = useState<boolean>(false);
  const [periodicidade, setPeriodicidade] = useState<any>([]);
  const [periodicidadeSelected, setPeriodicidadeSelected] = useState([]);

  const [carregamentoDepartamento, setCarregamentoDepartamento] = useState<boolean>(false);
  const [departamento, setDepartamento] = useState<any>([]);
  const [departamentoSelected, setDepartamentoSelected] = useState([]);

  const [carregamentoGestor, setCarregamentoGestor] = useState<boolean>(false);
  const [gestor, setGestor] = useState<any>([]);
  const [gestorSelected, setGestorSelected] = useState([]);

  const [carregamentoMoeda, setCarregamentoMoeda] = useState<boolean>(false);
  const [moeda, setMoeda] = useState<any>([]);
  const [moedaSelected, setMoedaSelected] = useState([]);

  const [carregamentoIndice, setCarregamentoIndice] = useState<boolean>(false);
  const [indice, setIndice] = useState<any>([]);
  const [indiceSelected, setIndiceSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);

  const [selectedStatusPasta, setSelectedStatusPasta] = useState<any>([]);

  const [solicitanteSelected, setSolicitanteSelected] = useState([]);

  const [selectedUrgente, setSelectedUrgente] = useState<any>([]);

  const [exibirFiltrosData, setExibirFiltrosData] = useState<boolean>(false);
  const [exibirFiltrosLista, setExibirFiltrosLista] = useState<boolean>(false);

  const { user } = useAuth();

  useEffect(() => {
    if (exibirFiltrosLista) {
      carregarPeriodicidade();
      carregarDepartamento();
      carregarGestor();
      carregarMoeda();
      carregarIndice();
      if (user?.clienteTipo === 'Empresa') {
        carregarEmpresa();
      }
    }
  }, [exibirFiltrosLista]);

  const optionsStatusPasta = [
    { label: "Em Elaboração", value: "1" },
    { label: "Vigente", value: "2" },
    { label: "Vencida", value: "3" },
    { label: "Encerrada", value: "4" },
  ];

  const optionsUrgente = [
    { label: "Sim", value: "1" },
    { label: "Não", value: "0" },
  ];


  async function carregarPeriodicidade() {
    try {
      if (periodicidade.length > 0) return;
      setCarregamentoPeriodicidade(true);
      let resultadoSuAreaDireito: IRecuperaLista<IPeriodicidade>;
      resultadoSuAreaDireito = await PeriodicidadeService.obterPeriodicidades({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "periodicidadeId",
      });
      setPeriodicidade(returnOptions(resultadoSuAreaDireito.data, "periodicidadeId", "periodicidade"));
      setCarregamentoPeriodicidade(false);
    } catch (error: any) {
      setCarregamentoPeriodicidade(false);
      setPeriodicidade([]);
    }
  }

  async function carregarDepartamento() {
    try {
      if (departamento.length > 0) return;
      setCarregamentoDepartamento(true);
      let resultado: IRecuperaLista<IDepartamento>;
      resultado = await DepartamentoService.obterDepartamentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "departamentoId",
      });
      setDepartamento(returnOptions(resultado.data, "departamentoId", "departamento"));
      setCarregamentoDepartamento(false);
    } catch (error: any) {
      setCarregamentoDepartamento(false);
      setDepartamento([]);
    }
  }

  async function carregarGestor() {
    try {
      if (gestor.length > 0) return;
      setCarregamentoGestor(true);
      let resultado: IRecuperaLista<IGestor>;
      resultado = await GestorService.obterUsuarios({
        nomeUsuario: "",
        clientes: [user?.clienteId || 0],
        email: "",
        numeroDocumento: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "usuarioId",
      });
      setGestor(returnOptions(resultado.data, "gestorId", "gestor"));
      setCarregamentoGestor(false);
    } catch (error: any) {
      setCarregamentoGestor(false);
    }
  }

  async function carregarMoeda() {
    try {
      if (moeda.length > 0) return;
      setCarregamentoMoeda(true);
      let resultado: IRecuperaLista<IMoeda>;
      resultado = await MoedaService.obterMoedas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "moedaId",
      });
      setMoeda(returnOptions(resultado.data, "moedaId", "moeda"));
      setCarregamentoMoeda(false);
    } catch (error: any) {
      setCarregamentoMoeda(false);
    }
  }

  async function carregarIndice() {
    try {
      if (indice.length > 0) return;
      setCarregamentoIndice(true);
      let resultado: IRecuperaLista<IIndice>;
      resultado = await IndiceService.obterIndices({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "indiceId",
      });
      setIndice(returnOptions(resultado.data, "indiceId", "indice"));
      setCarregamentoIndice(false);
    } catch (error: any) {
      setCarregamentoIndice(false);
      setIndice([]);
    }
  }

  async function carregarEmpresa() {
    try {
      if (empresa.length > 0) return;
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "empresaId",
      });
      setEmpresa(returnOptionsEmpresa(resultado.data));
      setCarregamentoEmpresa(false);
    } catch (error: any) {
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
  }

  const returnOptionsEmpresa = (empresas: any) => {
    let empresasParse: any = [];
    empresas.forEach((item: any) => {
      empresasParse.push({
        value: item.empresaId,
        label: item.nomeFantasia,
      });
    });
    return empresasParse;
  };

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });

    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {
      case "indiceId":
        setFiltro({ ...filtro, indiceId: IdsSelected });
        break;

      case "periodicidadeId":
        setFiltro({ ...filtro, periodicidadeId: IdsSelected });
        break;

      case "departamentoId":
        setFiltro({ ...filtro, departamentoId: IdsSelected });
        break;

      case "gestorId":
        setFiltro({ ...filtro, gestorId: IdsSelected });
        break;

      case "moedaId":
        setFiltro({ ...filtro, moedaId: IdsSelected });
        break;

      case "empresa":
        setFiltro({ ...filtro, empresa: IdsSelected });
        break;

      case "statusPasta":
        setFiltro({ ...filtro, statusPasta: IdsSelected });
        break;

      case "usuarioIdCadastro":
        setFiltro({ ...filtro, usuarioIdCadastro: IdsSelected });
        break;

      case "urgente":
        setFiltro({ ...filtro, urgenteFilter: IdsSelected });
        break;
    }
  };

  return (
    <>
      <>
        <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
          <div className="modal-content">
            <div className="modal-body">

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosData(!exibirFiltrosData)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosData ? "-" : "+"} Filtros por datas</h5>
                </div>
              </div>
              {exibirFiltrosData && (
                <>
                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Data de Cadastro:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        
                        value={
                          filtro.dataCadastroMaiorIgual && filtro.dataCadastroMenorIgual
                            ? [filtro.dataCadastroMaiorIgual, filtro.dataCadastroMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataCadastroMaiorIgual: "",
                                dataCadastroMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataInicioVigencia" className="form-label fw-bolder text-orange">
                        Data de Início da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        
                        value={
                          filtro.dataInicioVigenciaMaiorIgual && filtro.dataInicioVigenciaMenorIgual
                            ? [filtro.dataInicioVigenciaMaiorIgual, filtro.dataInicioVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataInicioVigenciaMaiorIgual: "",
                                dataInicioVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataInicioVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataInicioVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataFimVigencia" className="form-label fw-bolder text-orange">
                        Data Fim da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        
                        value={
                          filtro.dataFimVigenciaMaiorIgual && filtro.dataFimVigenciaMenorIgual
                            ? [filtro.dataFimVigenciaMaiorIgual, filtro.dataFimVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataFimVigenciaMaiorIgual: "",
                                dataFimVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataFimVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataFimVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-dataAprovacao" className="form-label fw-bolder text-orange">
                        Data da Aprovação:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        
                        value={
                          filtro.dataAprovacaoMaiorIgual && filtro.dataAprovacaoMenorIgual
                            ? [filtro.dataAprovacaoMaiorIgual, filtro.dataAprovacaoMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataAprovacaoMaiorIgual: "",
                                dataAprovacaoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataAprovacaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataAprovacaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataAssinatura" className="form-label fw-bolder text-orange">
                        Data da Assinatura:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        
                        value={
                          filtro.dataAssinaturaMaiorIgual && filtro.dataAssinaturaMenorIgual
                            ? [filtro.dataAssinaturaMaiorIgual, filtro.dataAssinaturaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataAssinaturaMaiorIgual: "",
                                dataAssinaturaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataAssinaturaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataAssinaturaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataCancelado" className="form-label fw-bolder text-orange">
                        Data do Cancelamento:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        
                        value={
                          filtro.dataCanceladoMaiorIgual && filtro.dataCanceladoMenorIgual ? [filtro.dataCanceladoMaiorIgual, filtro.dataCanceladoMenorIgual] : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataCanceladoMaiorIgual: "",
                                dataCanceladoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataCanceladoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataCanceladoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosLista(!exibirFiltrosLista)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosLista ? "-" : "+"} Filtros por listas</h5>
                </div>
              </div>
              {exibirFiltrosLista && (
                <>
                  <div className="row mt-12">

                  <div className="col-md-4 mt-3">
                      <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                        Urgente:
                      </label>
                      <MultiSelect
                        options={optionsUrgente}
                        value={selectedUrgente}
                        onChange={(event: any) => {
                          setSelectedUrgente(event);
                          setIdsSelect("urgente", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                        Status da Pasta:
                      </label>
                      <MultiSelect
                        options={optionsStatusPasta}
                        value={selectedStatusPasta}
                        onChange={(event: any) => {
                          setSelectedStatusPasta(event);
                          setIdsSelect("statusPasta", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Solicitante:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoGestor}
                        disabled={carregamentoGestor}
                        options={gestor}
                        value={solicitanteSelected}
                        onChange={(event: any) => {
                          setSolicitanteSelected(event);
                          setIdsSelect("usuarioIdCadastro", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>


                  </div>

                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Periodicidade:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoPeriodicidade}
                        disabled={carregamentoPeriodicidade}
                        options={periodicidade}
                        value={periodicidadeSelected}
                        onChange={(event: any) => {
                          setPeriodicidadeSelected(event);
                          setIdsSelect("periodicidadeId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Departamento:
                      </label>
                      <MultiSelect
                        disabled={carregamentoDepartamento}
                        isLoading={carregamentoDepartamento}
                        options={departamento}
                        value={departamentoSelected}
                        onChange={(event: any) => {
                          setDepartamentoSelected(event);
                          setIdsSelect("departamentoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <>{(user?.clienteTipo === 'Empresa') &&
                      <div className="col-md-4 mt-3">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Empresa:
                        </label>
                        <MultiSelect
                          isLoading={carregamentoEmpresa}
                          disabled={carregamentoEmpresa}
                          options={empresa}
                          value={empresaSelected}
                          onChange={(event: any) => {
                            setEmpresaSelected(event);
                            setIdsSelect("empresa", event);
                          }}
                          labelledBy={"Selecione..."}
                          overrideStrings={{
                            selectSomeItems: "Selecione...",
                            allItemsAreSelected: "Todos selecionados",
                            selectAll: "Selecione todos",
                            search: "Pesquise",
                            selectAllFiltered: "Selecione todos (filtrados)"
                          }}
                        />
                      </div>
                    }</>
                  </div>

                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Gestor:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoGestor}
                        disabled={carregamentoGestor}
                        options={gestor}
                        value={gestorSelected}
                        onChange={(event: any) => {
                          setGestorSelected(event);
                          setIdsSelect("gestorId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Moeda:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoMoeda}
                        disabled={carregamentoMoeda}
                        options={moeda}
                        value={moedaSelected}
                        onChange={(event: any) => {
                          setMoedaSelected(event);
                          setIdsSelect("moedaId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Índice:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoIndice}
                        disabled={carregamentoIndice}
                        options={indice}
                        value={indiceSelected}
                        onChange={(event: any) => {
                          setIndiceSelected(event);
                          setIdsSelect("indiceId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>

                </>
              )}
            </div>

            <div className="modal-footer" style={{ margin: "0 auto" }}>
              <button
                type="button"
                onClick={() => {
                  setPesquisar(true);
                  toggleModal();
                }}
                className="btn btn-orange me-5"
              >
                Pesquisar
              </button>
              <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                CANCELAR
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default FiltroModal;
