import { useState } from "react";
import {
  Container,
  Content,
  FilialSection,
  Section,
  Interligacao,
  NomeEmpresa,
} from "./styles";

import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { IOrganogramaFiliais } from "../../../../../interfaces/IOrganogramaFiliais";
import { IOrganogramaSocios } from "../../../../../interfaces/IOrganogramaSocios";


type CompanyProps = {
  nome: string;
  socios: IOrganogramaSocios[];
  filiais: IOrganogramaFiliais[];
};

export const Empresa = ({
  nome,
  socios,
  filiais,
}: CompanyProps): JSX.Element => {
  const [exibirSocios, setExibirSocios] = useState(true);
  const [exibirFiliais, setExibirFiliais] = useState(true);

  return (
    <Container>
      <Interligacao />
      <NomeEmpresa>{nome.toLowerCase()}</NomeEmpresa>
      <Content>
        {socios.length > 0 && (
          <Section>
            <header>
              <button
                type="button"
                onClick={() => {
                  setExibirSocios((prev) => !prev)
                }}
              >
                <strong>Sócios</strong>
                {exibirSocios ? <MdArrowDropUp /> : <MdArrowDropDown />}
              </button>
            </header>
            <div>
              {exibirSocios &&
                socios.map((socio) => (
                  <div key={socio.nome}>
                    <span>{socio.nome} - {socio.quotasAcoes}%</span>
                  </div>
                ))}
            </div>
          </Section>
        )}
      </Content>
      {filiais.length > 0 && (
        <FilialSection>
          <header>
            <button
              type="button"
              onClick={() => {
                setExibirFiliais((prev) => !prev)
              }}
            >
              <strong>Filiais</strong>
              {exibirFiliais ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
          </header>
          <div>
            {exibirFiliais &&
              filiais.map((filial) => (
                <div key={filial.nome}>
                  <span>{filial.nome}</span>
                </div>
              ))}
          </div>
        </FilialSection>
      )}
    </Container>
  );
};
