import EditorService, {
  DynamicProvider,
} from "../../../services/EditorService";
import { useMutation } from "react-query";
import { useTenant } from "./useTenant";
import { toast } from "react-toastify";
import { CustomEditor } from "../../../utils/types/slate";
import { blockHelper } from "../helpers/blockHelper";
import { Version } from "../types";

export function usePublish() {
  const { module, tenant } = useTenant();

  const { mutateAsync: refetchMinutaFn, isLoading: refetchLoading } =
    useMutation({
      mutationFn: EditorService.refetchMinuta,
      onError,
      onSuccess: () => {
        toast.success("Documento atualizado com sucesso!", {
          position: "bottom-right",
        });
      },
    });

  const { mutateAsync: setNewVersionFn, isLoading: publishLoading } =
    useMutation({
      mutationFn: EditorService.setNewVersion,
      onError,
      onSuccess: () => {
        toast.success("Versão publicada com sucesso!", {
          position: "bottom-right",
        });
      },
    });

  function onError(error: Error) {
    toast.error(error?.message ?? "Ocorreu um erro ao publicar a versão!");
  }

  async function refetch(tenant: DynamicProvider) {
    await refetchMinutaFn(tenant);
  }

  async function publish(editor: CustomEditor, version: Version | null) {
    if (!version) {
      return;
    }

    await setNewVersionFn({
      [tenant.provider.key]: tenant.provider.value,
      modulo: module,
      paginado: true,
      jsonContent: JSON.stringify({
        payload: {
          ...version,
          footer: blockHelper.footers(editor),
          header: blockHelper.headers(editor),
          nodes: editor.children,
        },
      }),
    });
  }

  return {
    publish: {
      action: publish,
      isLoading: publishLoading,
    },
    refetch: {
      action: refetch,
      isLoading: refetchLoading,
    },
  };
}
