import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import IAndamentos from "../../interfaces/IAndamentos";
import { TableColumn } from "react-data-table-component";
import GridPadrao from "../../components/Comum/GridPadrao";

interface IAndamentoModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  andamento: IAndamentos;
  titulo: string;
}

const AndamentoDetalhesModal = ({ toggleModal, exibirModal, andamento, titulo }: IAndamentoModalProps) => {

  function formatarData(data: string): string {
    const dataRet = data || ''
    if (dataRet.includes('T')) {
      const [ano, mes, dia] = dataRet.split('T')[0].split('-')
      return `${dia}/${mes}/${ano}`
    }
    return '-'
  }



  const colunasPartesContrarias: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "processoParteContrariaId",
      selector: (row: any) => row.processoParteContrariaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacaoNome",
      selector: (row: any) => row.formaParticipacaoNome,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}  <FontAwesomeIcon className="mx-2 text-orange" icon={faFileAlt} /></h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <div className="row mt-2">
              {andamento.numeroProcesso &&
                <div className="col-md-6">
                  <label htmlFor="form-andamentoTipoNome" className="form-label fw-bolder text-orange">
                    Número do Processo:
                  </label>
                  <br />
                  {andamento.numeroProcesso}
                </div>
              }
              {andamento.numeroContrato &&
                <div className="col-md-6">
                  <label htmlFor="form-andamentoTipoNome" className="form-label fw-bolder text-orange">
                    Número do Contrato:
                  </label>
                  <br />
                  {andamento.numeroContrato}
                </div>
              }
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <label htmlFor="form-publicacao" className="form-label fw-bolder text-orange">
                  Partes:
                </label>
                <br />
                <GridPadrao
                  paginationServer={true}
                  colunas={colunasPartesContrarias}
                  tipo="Partes"
                  itens={andamento.processoParteContraria}
                />
              </div>
            </div>

            <div className="row mt-2">

              <div className="col-md-6">
                <label htmlFor="form-andamentoTipoNome" className="form-label fw-bolder text-orange">
                  Tipo:
                </label>
                <br />
                {andamento.andamentoTipoNome}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-restrito" className="form-label fw-bolder text-orange">
                  Restrito:
                </label>
                <br />
                {andamento.restrito ? "Sim" : "Não"}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="form-dataAndamento" className="form-label fw-bolder text-orange">
                  Data do Andamento:
                </label>
                <br />
                {formatarData(andamento.dataAndamento.toString() || "")}
              </div>

              <div className="col-md-6">
                <label htmlFor="form-dataCadastro" className="form-label fw-bolder text-orange">
                  Data de Cadastro:
                </label>
                <br />
                {formatarData(andamento.dataCadastro.toString() || "")}
              </div>

            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="form-descricao" className="form-label fw-bolder text-orange">
                  Descrição:
                </label>
                <br />
                {andamento.descricao}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AndamentoDetalhesModal;
