import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IResultadoSentenca from "../interfaces/IResultadoSentenca";
import { AdicionaResultadoSentencaRequest } from "../interfaces/Requests/ResultadoSentenca/AdicionaResultadoSentencaRequest";
import FiltroPesquisaResultadoSentencaRequest from "../interfaces/Requests/ResultadoSentenca/FiltroPesquisaResultadoSentencaRequest";
import { EditarResultadoSentencaRequest } from "../interfaces/Requests/ResultadoSentenca/EditarResultadoSentencaRequest";
import { EModulo } from "../enum/EModulos";

class ResultadoSentencaService {

    async obterResultadoSentencas(filtros: FiltroPesquisaResultadoSentencaRequest): Promise<IRecuperaLista<IResultadoSentenca>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IResultadoSentenca>>(`ResultadoSentenca/RecuperaResultadoSentenca?${query}`);

        return result;
    }

    async adicionaResultadoSentenca(adicionaResultadoSentenca: AdicionaResultadoSentencaRequest): Promise<IResultadoSentenca> {
        let { data: result } = await api.post<IResultadoSentenca>(`ResultadoSentenca/AdicionaResultadoSentenca`, adicionaResultadoSentenca);

        return result;
    }


    async alterarStatus(resultadosentencaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IResultadoSentenca>(`ResultadoSentenca/AtualizaResultadoSentencaPorId?id=${resultadosentencaId}`, data);
    }

    async atualizarResultadoSentenca(editarResultadoSentenca: EditarResultadoSentencaRequest): Promise<void> {

        let dataResultadoSentenca = [
            { "op": "replace", "path": "/nome", "value": editarResultadoSentenca.nome },
            { "op": "replace", "path": "/codigo", "value": editarResultadoSentenca.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`ResultadoSentenca/AtualizaResultadoSentencaPorId?id=${editarResultadoSentenca.resultadoSentencaId}`, dataResultadoSentenca)
        ]);
    }
    async obterResultadoSentencaLimitada(filtros: FiltroPesquisaResultadoSentencaRequest): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        } 

        let { data: result } = await api.get<IRecuperaLista<any>>(`ResultadoSentenca/RecuperaListaLimitada?${query}`);

        return result;
    }

    async RecuperaListaLimitada(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<IResultadoSentenca>> {
        let { data: result } = await api.get<IRecuperaLista<IResultadoSentenca>>(
            `/ResultadoSentenca/RecuperaListaLimitada`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Audiencia,
                },
            }
        );

        return result;
    }

    async recuperaListaLimitadaPesquisa(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<IResultadoSentenca>> {
        let { data: result } = await api.get<IRecuperaLista<IResultadoSentenca>>(
            `/ResultadoSentenca/RecuperaListaLimitadaPesquisa`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Audiencia,
                },
            }
        );

        return result;
    }
}


export default new ResultadoSentencaService();