import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoDecisao from "../interfaces/ITipoDecisao";

class TipoDecisaoService {

    async obterTipoDecisaos(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoDecisao/RecuperaTipoDecisao?${query}`);

        return result;
    }

    
    async obterListaLimitadaTipoDecisaos(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoDecisao/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoDecisao(adicionaTipoDecisao: any): Promise<ITipoDecisao> {
        let { data: result } = await api.post<ITipoDecisao>(`TipoDecisao/AdicionaTipoDecisao`, adicionaTipoDecisao);

        return result;
    }


    async alterarStatus(tipoDecisaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoDecisao>(`TipoDecisao/AtualizaTipoDecisaoPorId?id=${tipoDecisaoId}`, data);
    }

    async atualizarTipoDecisao(editarTipoDecisao: any): Promise<void> {

        let dataTipoDecisao = [
            { "op": "replace", "path": "/nome", "value": editarTipoDecisao.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoDecisao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoDecisao/AtualizaTipoDecisaoPorId?id=${editarTipoDecisao.tipoDecisaoId}`, dataTipoDecisao)
        ]);
    }
}


export default new TipoDecisaoService();