import { useMutation } from "react-query";
import EditorService from "../../../../../../services/EditorService";
import { handleApiError } from "../../../../../../utils/Utils";
import { toast } from "react-toastify";
import { ICompareChangesResponse } from "../../../../../../interfaces/IEditorVersionResponse";
import { IComparedVersion } from "../../interfaces/versionType";

interface UseChangesComparatorProps {
  onSuccessCompare: (data: ICompareChangesResponse) => void
}

export function useChangesComparator({ onSuccessCompare }: UseChangesComparatorProps) {
  const { mutateAsync: compareChangesFn, isLoading } = useMutation({
    mutationFn: EditorService.compareChanges,
    onSuccess: onSuccessCompare,
    onError: (error) => {
      const errorMessage = handleApiError(error)
      toast.error(errorMessage, { position: 'bottom-right' })
    }
  })

  async function compareChanges(versions: IComparedVersion[]) {
    await compareChangesFn(versions);
  }

  return {
    compareChanges,
    isLoading
  }
}