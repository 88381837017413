import { useContext, useEffect, useState } from "react";
import { faInfo, faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { useFormik } from 'formik';

import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";
import { ICampo } from "../../../../interfaces/ICampo";
import { ETelaEnum } from "../../../../enum/ETelaEnum";
import { EModulo, ETipoCampoEnum } from "../../../../enum";

import ConfiguracaoTelaService from "../../../../services/ConfiguracaoTelaService";
import ConfiguracaoModeloRelatorioService from "../../../../services/ConfiguracaoModeloRelatorioService";

import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import { OrangeContext } from "../../../../contexts/OrangeProvider";
import LayoutPageForButton from "../../../../layout/LayoutPageButton";
import CamposXField from "../../XCampos/CamposXField";
import Column from "./Column/Column";
import IModeloRelatorio from "../../../../interfaces/IModeloRelatorio";
import "./../../Styles/dragdrop.css";
import { Modal } from "react-bootstrap";
import clsx from "clsx";

const ConfiguracaoRelatorioDespesaModeloPage = () => {
  const [exibirConfirmarModal, setExibirConfirmarModal] = useState<boolean>(false);

  const [carregandoConfiguracaoTela, setCarregandoConfiguracaoTela] = useState<boolean>(false);
  const [processamentoAoSalvar, setProcessamentoAoSalvar] = useState<boolean>(false);
  const { setCampos, campos, eCampos, setECampos, modeloRelatorio } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [columns, setColumns] = useState<any>({
    todo: {
      id: "todo",
      list: [],
    },
    doing: {
      id: "doing",
      list: [],
    },
  });
  const [exibirModalXCampos, setExibirModalXCampos] = useState<boolean>(false);

  const setarOrdenacao = () => columns.doing.list.forEach((campo: ICampo, index: number) => (campo.ordenacao = index)); // preencher o campo ordenação de acordo com a index

  const toggleXCampos = (): void => setExibirModalXCampos(!exibirModalXCampos);

  const toggleConfimarModal = (): void => setExibirConfirmarModal(!exibirConfirmarModal);

  useEffect(() => {
    if (!carregandoConfiguracaoTela) {

      if (modeloRelatorio?.modeloRelatorioId) carregarModeloDoing();

      carregarConfiguracaoTodo();
    }
  }, []);


  useEffect(() => {
    setarOrdenacao();
    if (columns.doing.list.length > 0) {
      setCampos(columns.doing.list);
    }
  }, [columns.doing]);



  useEffect(() => {
    setColumns({
      todo: {
        id: "todo",
        list: tratarECampos(eCampos, campos),
      },
      doing: {
        id: "doing",
        list: campos,
      },
    });
  }, [campos.length, eCampos.length]);

  const onDragEnd = ({ source, destination }: any) => {
    try {
      if (destination === undefined || destination === null) return null;

      if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

      const start = columns[source.droppableId];
      const end = columns[destination.droppableId];

      if (start === end) {
        const newList = start.list.filter((_: any, idx: number) => idx !== source.index);

        newList.splice(destination.index, 0, start.list[source.index]);
        const newCol = {
          id: start.id,
          list: newList,
        };

        setColumns((state: any) => ({ ...state, [newCol.id]: newCol }));
      } else {
        const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);

        const newStartCol = {
          id: start.id,
          list: newStartList,
        };
        const newEndList = end.list;

        newEndList.splice(destination.index, 0, eCampos.filter((e) => e.campo === start.list[source.index].campo)[0]);

        const newEndCol = {
          id: end.id,
          list: newEndList,
        };

        setColumns((state: any) => ({
          ...state,
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol,
        }));
        setarOrdenacao();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const alterarItem = (item: ICampo) => {
    columns.doing.list = columns.doing.list.filter((e: ICampo) => e.campo !== item.campo);
    columns.doing.list.splice(item.ordenacao, 0, item);
    setColumns({
      todo: {
        id: "todo",
        list: columns.todo.list,
      },
      doing: {
        id: "doing",
        list: columns.doing.list,
      },
    });
  };

  const tratarECampos = (todo: any, doing: any) => {
    let lista: ICampo[] = [];
    todo.forEach((campo: any, index: number) => {
      if (doing.filter((e: any) => e.campo === campo.campo).length == 0) {
        lista.push({ ...campo, ordenacao: (index + 1), tabelaOrigem: 5 });
      }
    });

    return lista;
  }

  const carregarConfiguracaoTodo = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let todo: IRecuperaLista<any>;

      todo = await ConfiguracaoTelaService.obterTodo(ETelaEnum.TelaCapaDespesa);

      let resultado: ICampo[] = [];
      todo.data.forEach((item: any) => {
        resultado.push({
          campo: item.camposId,
          campoNome: item.label,
          label: item.label,
          entidade: item.label,
          tipo: ETipoCampoEnum.Texto,
          camposId: item.camposId,
          ordenacao: 0,
          help: "",
          nome: "",
          isXCampo: false,
          obrigatorio: false,
          somenteLeitura: false,
          Lista: [],
          campoTipo: ETipoCampoEnum.Texto,
          tabelaOrigem: 5,
          valor: 0,
          valorId: 0
        })
      });

      setECampos(resultado);

      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setECampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const carregarModeloDoing = async () => {
    try {

      setCarregandoConfiguracaoTela(true);
      let doing: IRecuperaLista<IModeloRelatorio>;

      doing = await ConfiguracaoModeloRelatorioService.obterModeloRelatorio({
        modeloRelatorioId: modeloRelatorio.modeloRelatorioId,
        modulo: EModulo.Despesa
      });

      let resultado: ICampo[] = [];
      doing.data[0].modeloRelatorioCampos.forEach((item: any) => {
        resultado.push({
          campo: item.camposId,
          campoNome: item.label,
          label: item.label,
          entidade: item.label,
          tipo: ETipoCampoEnum.Texto,
          camposId: item.camposId,
          ordenacao: 0,
          help: "",
          nome: "",
          isXCampo: false,
          obrigatorio: false,
          somenteLeitura: false,
          Lista: [],
          campoTipo: ETipoCampoEnum.Texto,
          tabelaOrigem: 5,
          valor: 0,
          valorId: 0
        })
      });

      setCampos(resultado);

      setarOrdenacao();

      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setCampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const adicionarConfiguracao = async () => {
    try {

      setProcessamentoAoSalvar(true);

      if (modeloRelatorio.modeloRelatorioId)
        await ConfiguracaoModeloRelatorioService.editaConfiguracaoRelatorio({
          ...modeloRelatorio,
          modeloRelatorioCampos: campos,
          modulo: EModulo.Despesa,
          nome: formik.values.nome,
          tipo: formik.values.tipo,
          status: 1
        });
      else
        await ConfiguracaoModeloRelatorioService.adicionaConfiguracaoRelatorio({
          ...modeloRelatorio,
          modeloRelatorioCampos: campos,
          modulo: EModulo.Despesa,
          nome: formik.values.nome,
          tipo: formik.values.tipo,
          status: 1,
        });

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Modelo cadastrado com sucesso`,
        showConfirmButton: false,
        timer: 3000,
      });

      setProcessamentoAoSalvar(false);
      toggleConfimarModal();
    } catch (error: any) {
      setProcessamentoAoSalvar(false);
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel cadastrar os campos.`,
        text: error.response.data.Message,
        showConfirmButton: true,
      });
    }
  };

  const schema = Yup.object().shape({ nome: Yup.string().max(50, 'Maximo 50 caracteres').required('Nome é obrigatório') });


  const formik = useFormik({
    initialValues: { nome: modeloRelatorio.nome, tipo: EModulo.Contrato },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false)
    }
  });

  return (
    <>
      <CamposXField setCamposList={setCampos} camposList={campos} exibirModal={exibirModalXCampos} toggleModal={toggleXCampos} modulo={EModulo.Despesa} />
      <LayoutPageTitle title={("Configuração do Relatório: ") + modeloRelatorio.nome} />
      <LayoutPageForButton title={""}>
        <div className="col-md-11">
          <button disabled={processamentoAoSalvar} onClick={() => setExibirModalXCampos(true)} type="button" className="btn btn-orange me-5">
            X CAMPOS <FontAwesomeIcon color={"white"} className="mx-2" icon={faInfo} />
          </button>
        </div>
        <div className="col-md-12">
          <button disabled={processamentoAoSalvar} onClick={() => toggleConfimarModal()} type="button" className="btn btn-orange me-5">
            {!processamentoAoSalvar && (
              <>
                Confirmar?
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
              </>
            )}
            {processamentoAoSalvar && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Salvando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </LayoutPageForButton>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row mt-2">
          {Object.values(columns).map((col: any) => (
            <div className="col-md-6">
              {col.id === "doing" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      Clique para configurar os campos selecionados.
                    </h6>
                  </div>
                </p>
              )}
              {col.id === "todo" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      Clique segure e arraste para selecionar um campo.
                    </h6>
                  </div>
                </p>
              )}
              <div style={{ overflow: "auto", height: "650px" }}>
                <Column col={col} key={col.id} alterarItem={alterarItem} />
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>

      <Modal size="lg" centered={false} show={exibirConfirmarModal} onHide={toggleConfimarModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              {modeloRelatorio.modeloRelatorioId ? "Deseja editar o nome do modelo?" : "Informar o nome do modelo:"}
            </h5>
            <div onClick={() => toggleConfimarModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >

              <div className="col-md-6 mb-3">
                <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">Nome</label>
                <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                  'form-control',
                  {
                    'is-invalid': formik.touched.nome && formik.errors.nome,
                  },
                  {
                    'is-valid': formik.touched.nome && !formik.errors.nome,
                  }
                )} id="form-nome" />
                {formik.touched.nome && formik.errors.nome && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.nome}</span>
                    </div>
                  </div>
                )}
              </div>

              {!modeloRelatorio.modeloRelatorioId &&
                <div className="col-md-6">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Tipo:
                  </label>
                  <select
                    {...formik.getFieldProps("tipo")}
                    value={formik.values.tipo}
                    onChange={(e) => formik.setFieldValue("tipo", parseInt(e.target.value), true)}
                    className={"form-select"}
                    id="form-select"
                  >
                    <option value="7" label="Despesa" />
                  </select>
                  {formik.touched.tipo && formik.errors.tipo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.tipo}</span>
                      </div>
                    </div>
                  )}
                </div>
              }
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button disabled={!formik.isValid} onClick={() => adicionarConfiguracao()} type="button" className="btn btn-orange ms-5">
              Salvar
            </button>

            <button
              onClick={() => toggleConfimarModal()}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfiguracaoRelatorioDespesaModeloPage;
