import { faEraser, faSearch, faFileExcel, faCheckCircle, faPlus, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import XFinderGuiaService from "../../services/XFinderGuiaService";
import FiltroPesquisaXFinderGuiaRequest from "../../interfaces/Requests/XFinder/FiltroPesquisaXFinderGuiaRequest";
import GridPadrao from "../../components/Comum/GridPadrao";
import IXFinderGuia from "../../interfaces/IXFinderGuia";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import Swal from "sweetalert2";
import DocumentoService from "../../services/DocumentoService";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import XFinderGuiasModal from "../XFinder/XFinderGuiasModal";


const XFinderGuiasPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoXFinderGuias, setCarregandoXFinderGuias] = useState(false);
  const [xFinderGuias, setXFinderGuias] = useState<IXFinderGuia[]>([]);
  const [relatorioId, setRelatorioId] = useState<number | null>();
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaXFinderGuiaRequest>({
    xFinderGuiasId: [],
    dataGuiaMaiorIgual: "",
    dataGuiaMenorIgual: "",
    nomePesquisa: "",
    numeroProcesso: "",
    reu: "",
    autor: "",
    advogadoAutor: "",
    dataAudienciaMaiorIgual: "",
    dataAudienciaMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    status: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "xFinderGuiaId",
  });


  const toggleModal = () => {
    setExibirModal(!exibirModal);
  };

  async function alterarStatus(xFinderGuiasId: number, status: number, tituloVar: string, textVar: string) {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: tituloVar,
        text: textVar,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await XFinderGuiaService.alterarStatus(xFinderGuiasId, status);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Ação realizada com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();

        setFiltrosPesquisa((oldState) => {
          carregarXFinderGuias(oldState);
          return oldState;
        });
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar a ação`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarXFinderGuias(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  async function carregarXFinderGuias(filtro: FiltroPesquisaXFinderGuiaRequest): Promise<void> {
    try {
      setCarregandoXFinderGuias(true);
      let resultado: IRecuperaLista<IXFinderGuia>;

      resultado = await XFinderGuiaService.obterXFinderGuias(filtro);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setRelatorioId(resultado.relatorioId || null);
      setXFinderGuias(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros.",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setXFinderGuias([]);
      setRelatorioId(null);
    } finally {
      setCarregandoXFinderGuias(false);
      setLoadingInicial(false);
    }
  }

  function nomearStatus(status: string): string {
    switch (status) {
      case 'Pendente':
        return 'Pendente';
      case 'Cadastrado':
        return 'Concluído';
      default:
        return 'Pendente';
    }
  }

  const baixarArquivo = async (url: string, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      url = url.replace("http://", "https://");
      await DocumentoService.downloadKurier(url)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const colunas: TableColumn<IXFinderGuia>[] = [
    {
      name: "Id",
      sortField: "xFinderGuiasId",
      selector: (row: IXFinderGuia) => row.xFinderGuiasId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IXFinderGuia) => nomearStatus(row.status),
      sortable: true,
      wrap: true,
    },
    {
      name: "Responsabilidade",
      sortField: "numeroProcesso",
      selector: (row: IXFinderGuia) => row.numeroProcesso,
      sortable: true,
      wrap: true,
    },
    {
      name: "Qtde Novos",
      sortField: "instancia",
      selector: (row: IXFinderGuia) => row.instancia,
      sortable: true,
      wrap: true,
    },
    {
      name: "Qtde Encerrados",
      sortField: "jurisdicao",
      selector: (row: IXFinderGuia) => row.jurisdicao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Qtde Atualizados",
      sortField: "orgaoVara",
      selector: (row: IXFinderGuia) => row.orgaoVara,
      sortable: true,
      wrap: true,
    },
    {
      name: "Movimentação Provisão",
      sortField: "autor",
      selector: (row: IXFinderGuia) => row.autor,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Cadastro",
      sortField: "dataCadastro",
      selector: (row: IXFinderGuia) => {
        if (row.dataCadastro === null) return "-";
        const data = row.dataCadastro || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Data Conclusão",
      sortField: "autor",
      selector: (row: IXFinderGuia) => row.reu,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (xFinderGuia: IXFinderGuia) => {
        return (
          <>
            <div>

              <>
                <FontAwesomeIcon
                  title="Gerar Relatório"
                  onClick={() =>
                    baixarArquivo(xFinderGuia.linkAcessoInicial, xFinderGuia.numeroProcesso)
                  }
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  icon={faFileExcel}
                  size="2x"
                  className="mx-2 text-orange"
                />
                {xFinderGuia.status === "Pendente" && (
                  <>
                    <FontAwesomeIcon
                      title="Aprovar"
                      onClick={() => {
                        alterarStatus(xFinderGuia.xFinderGuiasId, 2, 'Aprovar Alterações', 'Você realmente deseja aprovar essa operação?');
                      }}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="2x"
                      className="mx-2 text-orange"
                      icon={faCheckCircle}
                    />

                    <FontAwesomeIcon
                      title="Reprovar"
                      onClick={() => {
                        alterarStatus(xFinderGuia.xFinderGuiasId, -1, 'Reprovar Alterações', 'Você realmente deseja reprovar essa operação?');
                      }}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="2x"
                      className="mx-2 text-orange"
                      icon={faExclamationCircle}
                    />
                  </>
                )}
              </>
            </div>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IXFinderGuia>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  async function baixarRelatorio(): Promise<void> {
    if (!xFinderGuias) return;

    if (relatorioId === 0 || relatorioId === null) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `A pesquisa não retornou nenhum resultado.`,
        showConfirmButton: true,
        timer: 4000,
      });
      return;
    }

    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Baixando arquivos...",
      showConfirmButton: false,
    });
    Swal.showLoading();
    try {
      const arquivo = await XFinderGuiaService.gerarExcel(relatorioId || 0);
      if (arquivo) {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${new Date().toLocaleString("BR")} - XFinderGuias.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      Swal.hideLoading();
      Swal.update({
        title: "Baixado com sucesso",
        text: "",
        icon: "success",
        showConfirmButton: true,
      });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível baixar arquivos",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  function limparFiltros() {
    setFiltrosPesquisa((oldValue: any) => {
      return {
        ...oldValue,
        xFinderGuiaId: 0,
        dataGuiaMaiorIgual: "",
        dataGuiaMenorIgual: "",
        nomePesquisa: "",
        numeroProcesso: "",
        reu: "",
        autor: "",
        advogadoAutor: "",
        dataAudienciaMaiorIgual: "",
        dataAudienciaMenorIgual: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        status: 0,
      };
    });
  }

  return (
    <>
      <LayoutPageTitle title="Upload de Planilhas">
        <button onClick={() => {
          setExibirModal(true);
        }} className="btn btn-md btn-orange">
          Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
        </button>
      </LayoutPageTitle>
      {exibirModal && <XFinderGuiasModal exibirModal={exibirModal} titulo={`Novo`} toggleModal={toggleModal} />}
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarXFinderGuias(filtrosPesquisa);
              }}
              className="row g-3 mb-3 form-buscar-xfinder-guia"
            >
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                  Data de Cadastro
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                      ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue: any) => {
                        return {
                          ...oldValue,
                          dataCadastroMaiorIgual: "",
                          dataCadastroMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status
                </label>
                <select
                  value={filtrosPesquisa.status}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, status: parseInt(e.target.value) };
                    });
                  }}
                  placeholder="Status"
                  className={"form-select"}
                  id="form-status"
                >
                  <option value={0}>Todos</option>
                  <option value={1}>Pendente</option>
                  <option value={2}>Concluído</option>
                  <option value={-1}>Cancelado</option>
                </select>
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-responsabilidade" className="form-label fw-bolder text-orange">
                  Responsabilidade
                </label>
                <select
                  value={filtrosPesquisa.status}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, status: parseInt(e.target.value) };
                    });
                  }}
                  placeholder="Responsabilidade"
                  className={"form-select"}
                  id="form-responsabilidade"
                >
                  <option value={0}>Todos</option>
                  <option value={1}>Abastece AÍ</option>
                  <option value={2}>Cargo</option>
                  <option value={3}>Corporação Societário</option>
                  <option value={4}>GAZ</option>
                  <option value={5}>Ipiranga</option>
                  <option value={6}>JTC</option>
                </select>
              </div>
              <div className="col-12 " style={{ textAlign: "right" }}>
                <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                  <FontAwesomeIcon color="white" className="" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-orange search-buttom-margin ms-2"
                  title="Limpar Filtro"
                >
                  <FontAwesomeIcon color="white" className="" icon={faEraser} />
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-12 mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoXFinderGuias}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="XFinderGuias"
              itens={xFinderGuias}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default XFinderGuiasPage;
