import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoComunicacao from "../interfaces/ITipoComunicacao";
import { AdicionaTipoComunicacaoRequest } from "../interfaces/Requests/TipoComunicacao/AdicionaTipoComunicacaoRequest";
import { EditarTipoComunicacaoRequest } from "../interfaces/Requests/TipoComunicacao/EditarTipoComunicacaoRequest";
import FiltroPesquisaTipoComunicacaoRequest from "../interfaces/Requests/TipoComunicacao/FiltroPesquisaTipoComunicacaoRequest";
import { EModulo } from "../enum";


class TipoComunicacaoService {

    async obterTipoComunicacoes(filtros: FiltroPesquisaTipoComunicacaoRequest): Promise<IRecuperaLista<ITipoComunicacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoComunicacao>>(`TipoComunicacao/RecuperaTipoComunicacao?${query}`);

        return result;
    }

    async obterListaLimitadaTipoComunicacao(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo == EModulo.AreaDireito) {
            query += `&modulo=${filtros.modulo}`;
            query += `&areaDireitoId=${filtros.areaDireitoId}`;

        }

        if (filtros.modulo == EModulo.Contrato) {
            query += `&modulo=${filtros.modulo}`;
            query += `&tipoContratoId=${filtros.tipoContratoId}`;

        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoComunicacao/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoComunicacao(adicionaTipoComunicacao: AdicionaTipoComunicacaoRequest): Promise<ITipoComunicacao> {
        let { data: result } = await api.post<ITipoComunicacao>(`TipoComunicacao/AdicionaTipoComunicacao`, adicionaTipoComunicacao);

        return result;
    }


    async alterarStatus(tipoComunicacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoComunicacao>(`TipoComunicacao/AtualizaTipoComunicacaoPorId?id=${tipoComunicacaoId}`, data);
    }

    async atualizarTipoComunicacao(editarTipoComunicacao: EditarTipoComunicacaoRequest): Promise<void> {

        let dataTipoComunicacao = [
            { "op": "replace", "path": "/nome", "value": editarTipoComunicacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoComunicacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoComunicacao/AtualizaTipoComunicacaoPorId?id=${editarTipoComunicacao.tipoComunicacaoId}`, dataTipoComunicacao)
        ]);
    }
}


export default new TipoComunicacaoService();