
export default class TimeDelta{
    private years = 0;
    private months = 0;
    private days = 0;
    private hours = 0;
    private minutes = 0;
    private seconds = 0;
    private milliseconds = 0;
    private value = 0;
    private negative = false;

    
    constructor(props: {years?: number, months?: number, days?: number, hours?: number, minutes?: number, seconds?: number, milliseconds?: number}|number){
        if(typeof props === 'number'){
            if (props < 0){
                this.negative = true;
                props = -props;
            }
            this.years = Math.floor(props / (1000 * 60 * 60 * 24 * 365));
            let resto = props % (1000 * 60 * 60 * 24 * 365);
            this.months = Math.floor(resto / (1000 * 60 * 60 * 24 * 30));
            resto = resto % (1000 * 60 * 60 * 24 * 30);
            this.days = Math.floor(resto / (1000 * 60 * 60 * 24));
            resto = resto % (1000 * 60 * 60 * 24);
            this.hours = Math.floor(resto / (1000 * 60 * 60));
            resto = resto % (1000 * 60 * 60);
            this.minutes = Math.floor(resto / (1000 * 60));
            resto = resto % (1000 * 60);
            this.seconds = Math.floor(resto / 1000);
            resto = resto % 1000;
            this.milliseconds = resto;
            this.value = props;
        }else{
            this.years = props.years || 0;
            this.months = props.months || 0;
            this.days = props.days || 0;
            this.hours = props.hours || 0;
            this.minutes = props.minutes || 0;
            this.seconds = props.seconds || 0;
            this.milliseconds = props.milliseconds || 0;
            this.value = this.years * (1000 * 60 * 60 * 24 * 365)
                + this.months * (1000 * 60 * 60 * 24 * 30)
                + this.days * (1000 * 60 * 60 * 24)
                + this.hours * (1000 * 60 * 60)
                + this.minutes * (1000 * 60)
                + this.seconds * 1000
                + this.milliseconds;
        }
    }

    private inversor(): number{
        return this.negative?-1:1;
    }

    getYears(): number{
        return this.years * this.inversor();
    }

    getMonths(): number{
        return this.months * this.inversor();
    }

    getDays(): number{
        return this.days * this.inversor();
    }

    getHours(): number{
        return this.hours * this.inversor();
    }

    getMinutes(): number{
        return this.minutes * this.inversor();
    }

    getSeconds(): number{
        return this.seconds * this.inversor();
    }
    
    getMilliseconds(): number{
        return this.milliseconds * this.inversor();
    }

    valueOf(): number{
        return this.value
    }

    static fromDate(date: Date): TimeDelta{
        return new TimeDelta(date.valueOf());
    }

    addTimeDelta(timeDelta: TimeDelta): TimeDelta{
        return new TimeDelta(this.value + timeDelta.value);
    }

    subtractTimeDelta(timeDelta: TimeDelta): TimeDelta{
        return new TimeDelta(this.value - timeDelta.value);
    }

    addToDate(date: Date): Date{
        date.setFullYear(date.getFullYear() + this.getYears());
        date.setMonth(date.getMonth() + this.getMonths());
        date.setDate(date.getDate() + this.getDays());
        date.setHours(date.getHours() + this.getHours());
        date.setMinutes(date.getMinutes() + this.getMinutes());
        date.setSeconds(date.getSeconds() + this.getSeconds());
        date.setMilliseconds(date.getMilliseconds() + this.getMilliseconds());
        return date;
    }
    
    subtractFromDate(date: Date): Date{
        date.setFullYear(date.getFullYear() - this.getYears());
        date.setMonth(date.getMonth() - this.getMonths());
        date.setDate(date.getDate() - this.getDays());
        date.setHours(date.getHours() - this.getHours());
        date.setMinutes(date.getMinutes() - this.getMinutes());
        date.setSeconds(date.getSeconds() - this.getSeconds());
        date.setMilliseconds(date.getMilliseconds() - this.getMilliseconds());
        return date;
    }    

    addDays(days: number): TimeDelta{
        const td =  new TimeDelta(this.value);
        td.days += days;
        return td
    }

    addHours(hours: number): TimeDelta{
        const td =  new TimeDelta(this.value);
        td.hours += hours;
        return td
    }

    addMinutes(minutes: number): TimeDelta{
        const td =  new TimeDelta(this.value);
        td.minutes += minutes;
        return td
    }
    
    addSeconds(seconds: number): TimeDelta{
        const td =  new TimeDelta(this.value);
        td.seconds += seconds;
        return td
    }

    addMilliseconds(milliseconds: number): TimeDelta{
        return new TimeDelta(this.value + milliseconds);
    }

    public get rep(): string{
        return this.toString();
    }

    public get val(): number{
        return this.valueOf();
    }

    public get tipo(): string{
        return 'timeDelta';
    }

    toString(format?: string): string{
        if(format){
            let formatado = this.negative?"-":"";
            let formatarString = false;
            let addComa = false;
            for(let i=0; i<format.length; i++){
                const c = format.charAt(i);
                if(addComa){
                    formatado += ', ';
                    addComa = false;
                }
                if (c === '%'){
                    if(formatarString){
                        formatado += '%'
                        formatarString = false;
                    }else{
                        formatarString = true;
                    }
                } else if (formatarString){
                    if(c === 'y'){
                        formatado += this.years;
                    }else if(c === 'Y'){
                        if(this.years > 0){
                            formatado += this.years + ` ${this.years > 1?'anos':'ano'}`;
                            addComa = true;
                        }
                    }else if(c === 'M'){
                        formatado += this.months;
                    }else if(c === 'X'){
                        if(this.months > 0){
                            formatado += this.months + ` ${this.months > 1?'meses':'mes'}`;
                            addComa = true;
                        }
                    }else if(c === 'd'){
                        formatado += this.days;
                    }else if(c === 'D'){
                        if(this.days > 0){
                            formatado += this.days + ` dia${this.days > 1?'s':''}`;
                            addComa = true;
                        }
                    }else if(c === 'h'){
                        formatado += this.hours.toString().padStart(2,'0');
                    }else if(c === 'm'){
                        formatado += this.minutes.toString().padStart(2,'0');
                    }else if(c === 's'){
                        formatado += this.seconds.toString().padStart(2,'0');
                    }else if(c === 'S'){
                        formatado += this.milliseconds.toString().padEnd(4,'0');
                    }else{
                        formatado += '%'+c;
                    }
                    formatarString = false;
                } else{
                    formatado += c;
                }
            }
            return formatado;
        }

        return `${this.years?this.years+' anos, ':''}${this.months?this.months+' meses, ':''}${this.days?this.days+' dias, ':''}${this.hours?this.hours+' horas, ':''}${this.minutes?this.minutes+' minutos, ':''}${this.seconds?this.seconds+' segundos, ':''}${this.milliseconds?this.milliseconds+' milisegundos':''}`;
    }
}