import styled from "styled-components";

export const LinkComponent = styled.a<{ selected: boolean }>`
  border: ${(props) => (props.selected ? "1px solid blue" : "none")};
`;

export const LinkTrigger = styled.div`
  background: #fff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8;
  margin: 20px 0;
  padding: 10px;
  gap: 10px;
  max-width: 20%;

  display: flex;
  justify-content: center;

  position: absolute;

  > button {
    background: none;
    border: none;
    border-radius: 4px;
    background: #b4d5ff;
    padding: 4px 8px;
    transition: 0.4s;

    &:hover {
      opacity: 0.6;
    }
  }
`;