/* eslint-disable @typescript-eslint/no-explicit-any */

// React imports
import { useEffect, useState } from "react";

// Component imports
import { Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";

// Interface imports
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import ISubAreaDireito from "../../../interfaces/ISubAreaDireito";
import IClassificacao from "../../../interfaces/IClassificacao";
import IAmbito from "../../../interfaces/IAmbito";
import ITipoContingencia from "../../../interfaces/ITipoContingencia";
import IEsfera from "../../../interfaces/IEsfera";
import IJuiz from "../../../interfaces/IJuiz";
import IMotivoEncerramento from "../../../interfaces/IMotivoEncerramento";
import IInstancia from "../../../interfaces/IInstancia";
import IFaseProcessual from "../../../interfaces/IFaseProcessual";
import IRisco from "../../../interfaces/IRisco";
import ITipoAcao from "../../../interfaces/ITipoAcao";
import IEstado from "../../../interfaces/IEstado";
import { FiltroPesquisaProcessoRequest } from "../../../interfaces/Requests/Processo/FiltroPesquisaProcessoRequest";

// Service imports
import SubAreaDireitoService from "../../../services/SubAreaDireitoService";
import ClassificacaoService from "../../../services/ClassificacaoService";
import AmbitoService from "../../../services/AmbitoService";
import TipoContingenciaService from "../../../services/TipoContingenciaService";
import EsferaService from "../../../services/EsferaService";
import JuizService from "../../../services/JuizService";
import MotivoEncerramentoService from "../../../services/MotivoEncerramentoService";
import InstanciaService from "../../../services/InstanciaService";
import FaseProcessualService from "../../../services/FaseProcessualService";
import RiscoService from "../../../services/RiscoService";
import TipoAcaoService from "../../../services/TipoAcaoService";
import EstadoService from "../../../services/EstadoService";

// Enum imports
import { EModulo } from "../../../enum";
import ITipoPedido from "../../../interfaces/ITipoPedido";
import TipoPedidoService from "../../../services/TipoPedidoService";


interface IProcessoModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtro: FiltroPesquisaProcessoRequest;
  setFiltro: (filtro: any) => void;
}

const FiltroModal = ({ toggleModal, exibirModal, filtro, setFiltro, setPesquisar }: IProcessoModalProps) => {
  const [carregamentoSubAreaDireito, setCarregamentoSubAreaDireito] = useState<boolean>(false);
  const [subAreaDireito, setSubAreaDireito] = useState<any>([]);
  const [subAreaDireitoSelected, setSubAreaDireitoSelected] = useState([]);

  const [carregamentoClassificacao, setCarregamentoClassificacao] = useState<boolean>(false);
  const [classificacao, setClassificacao] = useState<any>([]);
  const [classificacaoSelected, setClassificacaoSelected] = useState([]);

  const [carregamentoAmbito, setCarregamentoAmbito] = useState<boolean>(false);
  const [ambito, setAmbito] = useState<any>([]);
  const [ambitoSelected, setAmbitoSelected] = useState([]);

  const [carregamentoJuiz, setCarregamentoJuiz] = useState<boolean>(false);
  const [juiz, setJuiz] = useState<any>([]);
  const [juizSelected, setJuizSelected] = useState([]);

  const [carregamentoTipoContingencia, setCarregamentoTipoContingencia] = useState<boolean>(false);
  const [tipoContingencia, setTipoContingencia] = useState<any>([]);
  const [tipoContingenciaSelected, setTipoContingenciaSelected] = useState([]);

  const [carregamentoEsfera, setCarregamentoEsfera] = useState<boolean>(false);
  const [esfera, setEsfera] = useState<any>([]);
  const [esferaSelected, setEsferaSelected] = useState([]);

  const [carregamentoMotivoEncerramento, setCarredandoMotivoEncerramento] = useState<boolean>(false);
  const [motivoEncerramento, setMotivoEncerramento] = useState<any>([]);
  const [motivoEncerramentoSelected, setMotivoEncerramentoSelected] = useState([]);

  const [carregamentoInstancia, setCarredandoInstancia] = useState<boolean>(false);
  const [instancia, setInstancia] = useState<any>([]);
  const [instanciaSelected, setInstanciaSelected] = useState([]);

  const [carregamentoFaseProcessual, setCarregamentoFaseProcessual] = useState<boolean>(false);
  const [faseProcessual, setFaseProcessual] = useState<any>([]);
  const [faseProcessualSelected, setFaseProcessualSelected] = useState([]);

  const [carregamentoTipoAcao, setCarregamentoTipoAcao] = useState<boolean>(false);
  const [tipoAcao, setTipoAcao] = useState<any>([]);
  const [tipoAcaoSelected, setTipoAcaoSelected] = useState([]);

  const [carregamentoRisco, setCarregamentoRisco] = useState<boolean>(false);
  const [risco, setRisco] = useState<any>([]);
  const [riscoSelected, setRiscoSelected] = useState([]);

  const [carregamentoEstado, setCarregamentoEstado] = useState<boolean>(false);
  const [estado, setEstado] = useState<any>([]);
  const [estadoSelected, setEstadoSelected] = useState([]);

  const [carregamentoTipoPedido, setCarregamentoTipoPedido] = useState<boolean>(false);
  const [tipoPedido, setTipoPedido] = useState<any>([]);
  const [tipoPedidoSelected, setTipoPedidoSelected] = useState([]);


  const [exibirFiltrosData, setExibirFiltrosData] = useState<boolean>(false);
  const [exibirFiltrosLista, setExibirFiltrosLista] = useState<boolean>(false);

  useEffect(() => {
    if (exibirFiltrosLista) {
      carregarSubAreaDireito();
      carregarClassificacao();
      carregarAmbito();
      carregarJuiz();
      carregarTipoContingencia();
      carregarEsfera();
      carregarMotivoEncerramento();
      carregarInstancia();
      carregarTipoAcao();
      carregarRisco();
      carregarFaceProcessual();
      carregarEstado();
      carregarTipoPedido();
    }
  }, [exibirFiltrosLista]);

  async function carregarSubAreaDireito() {
    try {
      if (subAreaDireito.length > 0) return;
      setCarregamentoSubAreaDireito(true);
      let resultadoSuAreaDireito: IRecuperaLista<ISubAreaDireito>;
      resultadoSuAreaDireito = await SubAreaDireitoService.obterSubAreaDireitos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "subAreaDireitoId",
      });
      setSubAreaDireito(returnOptions(resultadoSuAreaDireito.data, "subAreaDireitoId", "subAreaDireito"));
      setCarregamentoSubAreaDireito(false);
    } catch (error: any) {
      setCarregamentoSubAreaDireito(false);
      setSubAreaDireito([]);
    }
  }

  async function carregarClassificacao() {
    try {
      if (classificacao.length > 0) return;
      setCarregamentoClassificacao(true);
      let resultado: IRecuperaLista<IClassificacao>;
      resultado = await ClassificacaoService.obterClassificacaos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "classificacaoId",
      });
      setClassificacao(returnOptions(resultado.data, "classificacaoId", "classificacao"));
      setCarregamentoClassificacao(false);
    } catch (error: any) {
      setCarregamentoClassificacao(false);
      setClassificacao([]);
    }
  }

  async function carregarAmbito() {
    try {
      if (ambito.length > 0) return;
      setCarregamentoAmbito(true);
      let resultado: IRecuperaLista<IAmbito>;
      resultado = await AmbitoService.obterAmbitos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "ambitoId",
      });
      setAmbito(returnOptions(resultado.data, "ambitoId", "ambito"));
      setCarregamentoAmbito(false);
    } catch (error: any) {
      setCarregamentoAmbito(false);
    }
  }

  async function carregarJuiz() {
    try {
      if (juiz.length > 0) return;
      setCarregamentoJuiz(true);
      let resultado: IRecuperaLista<IJuiz>;
      resultado = await JuizService.obterJuizs({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "juizId",
      });
      setJuiz(returnOptions(resultado.data, "juizId", "juiz"));
      setCarregamentoJuiz(false);
    } catch (error: any) {
      setCarregamentoJuiz(false);
    }
  }

  async function carregarTipoContingencia() {
    try {
      if (tipoContingencia.length > 0) return;
      setCarregamentoTipoContingencia(true);
      let resultado: IRecuperaLista<ITipoContingencia>;
      resultado = await TipoContingenciaService.obterTipoContingencias({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "tipoContingenciaId",
      });
      setTipoContingencia(returnOptions(resultado.data, "tipoContingenciaId", "tipoContingencia"));
      setCarregamentoTipoContingencia(false);
    } catch (error: any) {
      setCarregamentoTipoContingencia(false);
      setTipoContingencia([]);
    }
  }

  async function carregarEsfera() {
    try {
      if (esfera.length > 0) return;
      setCarregamentoEsfera(true);
      let resultado: IRecuperaLista<IEsfera>;
      resultado = await EsferaService.obterEsferas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "esferaId",
      });
      setEsfera(returnOptions(resultado.data, "esferaId", "esfera"));
      setCarregamentoEsfera(false);
    } catch (error: any) {
      setCarregamentoEsfera(false);
      setEsfera([]);
    }
  }

  async function carregarMotivoEncerramento() {
    try {
      if (motivoEncerramento.length > 0) return;
      setCarredandoMotivoEncerramento(true);
      let resultado: IRecuperaLista<IMotivoEncerramento>;
      resultado = await MotivoEncerramentoService.obterMotivoEncerramentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        tipoConsultaId: 0,
        areaDireitoId: 0,
        modulo: EModulo.Default,
        totalItems: 0,
        offset: 0,
        sort: "motivoEncerramentoId",
      });
      setMotivoEncerramento(returnOptions(resultado.data, "motivoEncerramentoId", "motivoEncerramento"));
      setCarredandoMotivoEncerramento(false);
    } catch (error: any) {
      setCarredandoMotivoEncerramento(false);
      setMotivoEncerramento([]);
    }
  }

  async function carregarInstancia() {
    try {
      if (instancia.length > 0) return;
      setCarredandoInstancia(true);
      let resultado: IRecuperaLista<IInstancia>;
      resultado = await InstanciaService.obterInstancias({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "instanciaId",
      });
      setInstancia(returnOptions(resultado.data, "instanciaId", "instancia"));
      setCarredandoInstancia(false);
    } catch (error: any) {
      setCarredandoInstancia(false);
      setInstancia([]);
    }
  }

  async function carregarFaceProcessual() {
    try {
      if (faseProcessual.length > 0) return;
      setCarregamentoFaseProcessual(true);
      let resultado: IRecuperaLista<IFaseProcessual>;
      resultado = await FaseProcessualService.obterFaseProcessuals({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "faseProcessualId",
      });
      setFaseProcessual(returnOptions(resultado.data, "faseProcessualId", "faseProcessual"));
      setCarregamentoFaseProcessual(false);
    } catch (error: any) {
      setCarregamentoFaseProcessual(false);
      setFaseProcessual([]);
    }
  }

  async function carregarRisco() {
    try {
      if (risco.length > 0) return;
      setCarregamentoRisco(true);
      let resultado: IRecuperaLista<IRisco>;
      resultado = await RiscoService.obterRiscos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "riscoId",
      });
      setRisco(returnOptions(resultado.data, "riscoId", "risco"));
      setCarregamentoRisco(false);
    } catch (error: any) {
      setCarregamentoRisco(false);
      setRisco([]);
    }
  }

  async function carregarTipoAcao() {
    try {
      if (tipoAcao.length > 0) return;
      setCarregamentoTipoAcao(true);
      let resultado: IRecuperaLista<ITipoAcao>;
      resultado = await TipoAcaoService.obterTipoAcaos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "tipoAcaoId",
      });
      setTipoAcao(returnOptions(resultado.data, "tipoAcaoId", "tipoAcao"));
      setCarregamentoTipoAcao(false);
    } catch (error: any) {
      setCarregamentoTipoAcao(false);
      setTipoAcao([]);
    }
  }

  async function carregarEstado() {
    try {
      if (estado.length > 0) return;
      setCarregamentoEstado(true);
      let resultado: IRecuperaLista<IEstado>;
      resultado = await EstadoService.obterEstados({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "estadoId",
      });
      setEstado(returnOptions(resultado.data, "estadoId", "estado"));
      setCarregamentoEstado(false);
    } catch (error: any) {
      setCarregamentoEstado(false);
      setEstado([]);
    }
  }

  async function carregarTipoPedido() {
    try {
      if (tipoPedido.length > 0) return;
      setCarregamentoTipoPedido(true);
      let resultado: IRecuperaLista<ITipoPedido>;
      resultado = await TipoPedidoService.obterTipoPedidos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTipoPedido(returnOptions(resultado.data, "tipoPedidoId", "tipoPedido"));
      setCarregamentoTipoPedido(false);
    } catch (error: any) {
      setCarregamentoTipoPedido(false);
      setTipoPedido([]);
    }
  }

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });
    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {
      case "areaDireitoId":
        setFiltro({ ...filtro, areaDireitoId: IdsSelected });
        break;

      case "subAreaDireitoId":
        setFiltro({ ...filtro, subAreaDireitoId: IdsSelected });
        break;

      case "classificacaoId":
        setFiltro({ ...filtro, classificacaoId: IdsSelected });
        break;

      case "ambitoId":
        setFiltro({ ...filtro, ambitoId: IdsSelected });
        break;

      case "juizId":
        setFiltro({ ...filtro, juizId: IdsSelected });
        break;

      case "tipoContingenciaId":
        setFiltro({ ...filtro, tipoContingenciaId: IdsSelected });
        break;

      case "esferaId":
        setFiltro({ ...filtro, esferaId: IdsSelected });
        break;

      case "motivoEncerramentoId":
        setFiltro({ ...filtro, motivoEncerramentoId: IdsSelected });
        break;

      case "instanciaId":
        setFiltro({ ...filtro, instanciaId: IdsSelected });
        break;

      case "faseProcessualId":
        setFiltro({ ...filtro, faseProcessualId: IdsSelected });
        break;

      case "tipoAcaoId":
        setFiltro({ ...filtro, tipoAcaoId: IdsSelected });
        break;

      case "riscoId":
        setFiltro({ ...filtro, riscoId: IdsSelected });
        break;

      case "cidadeId":
        setFiltro({ ...filtro, cidadeId: IdsSelected });
        break;

      case "estadoId":
        setFiltro({ ...filtro, estadoId: IdsSelected });
        break;

      case "empresa":
        setFiltro({ ...filtro, empresa: IdsSelected });
        break;

      case "tipoPedidoId":
        setFiltro({ ...filtro, tipoPedidoId: IdsSelected });
        break;
    }
  };

  return (
    <>
      <>
        <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
          <div className="modal-content">
            <div className="modal-body">

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosData(!exibirFiltrosData)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosData ? "-" : "+"} Filtros por datas</h5>
                </div>
              </div>

              {exibirFiltrosData && (
                <>
                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Data de Cadastro:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataCadastroMaiorIgual && filtro.dataCadastroMenorIgual
                            ? [new Date(filtro.dataCadastroMaiorIgual), new Date(filtro.dataCadastroMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataCadastroMaiorIgual: "",
                              dataCadastroMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Data da Distribuição:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataDistribuicaoMaiorIgual && filtro.dataDistribuicaoMenorIgual
                            ? [new Date(filtro.dataDistribuicaoMaiorIgual), new Date(filtro.dataDistribuicaoMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataDistribuicaoMaiorIgual: "",
                              dataDistribuicaoMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataDistribuicaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataDistribuicaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Recebimento:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataRecebimentoMaiorIgual && filtro.dataRecebimentoMenorIgual
                            ? [new Date(filtro.dataRecebimentoMaiorIgual), new Date(filtro.dataRecebimentoMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataRecebimentoMaiorIgual: "",
                              dataRecebimentoMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataRecebimentoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataRecebimentoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                  </div>

                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Baixa Provisória:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataBaixaProvisoriaMaiorIgual && filtro.dataBaixaProvisoriaMenorIgual
                            ? [new Date(filtro.dataBaixaProvisoriaMaiorIgual), new Date(filtro.dataBaixaProvisoriaMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataBaixaProvisoriaMaiorIgual: "",
                              dataBaixaProvisoriaMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataBaixaProvisoriaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataBaixaProvisoriaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Baixa Provisória do Cadastro:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataBaixaProvisoriaCadastroMaiorIgual && filtro.dataBaixaProvisoriaCadastroMenorIgual
                            ? [new Date(filtro.dataBaixaProvisoriaCadastroMaiorIgual), new Date(filtro.dataBaixaProvisoriaCadastroMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataBaixaProvisoriaCadastroMaiorIgual: "",
                              dataBaixaProvisoriaCadastroMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataBaixaProvisoriaCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataBaixaProvisoriaCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Prazo fatal:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.prazoFatalMaiorIgual && filtro.prazoFatalMenorIgual
                            ? [new Date(filtro.prazoFatalMaiorIgual), new Date(filtro.prazoFatalMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              prazoFatalMaiorIgual: "",
                              prazoFatalMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            prazoFatalMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            prazoFatalMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                  </div>

                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Encerramento:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataEncerramentoMaiorIgual && filtro.dataEncerramentoMenorIgual
                            ? [new Date(filtro.dataEncerramentoMaiorIgual), new Date(filtro.dataEncerramentoMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataEncerramentoMaiorIgual: "",
                              dataEncerramentoMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataEncerramentoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataEncerramentoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Encerramento do Cadastro:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataEncerramentoCadastroMaiorIgual && filtro.dataEncerramentoCadastroMenorIgual
                            ? [new Date(filtro.dataEncerramentoCadastroMaiorIgual), new Date(filtro.dataEncerramentoCadastroMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataEncerramentoCadastroMaiorIgual: "",
                              dataEncerramentoCadastroMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataEncerramentoCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataEncerramentoCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Última Atualização:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataUltimaAtualizacaoMaiorIgual && filtro.dataUltimaAtualizacaoMenorIgual
                            ? [new Date(filtro.dataUltimaAtualizacaoMaiorIgual), new Date(filtro.dataUltimaAtualizacaoMenorIgual)]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas || datas.length === 0) {
                            setFiltro((oldValue: any) => ({
                              ...oldValue,
                              dataUltimaAtualizacaoMaiorIgual: "",
                              dataUltimaAtualizacaoMenorIgual: "",
                            }));
                            return;
                          }

                          const [data_inicial, data_final] = datas;

                          setFiltro((oldValue: any) => ({
                            ...oldValue,
                            dataUltimaAtualizacaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                            dataUltimaAtualizacaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                          }));
                        }}
                      />
                    </div>

                  </div>
                </>
              )}

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosLista(!exibirFiltrosLista)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosLista ? "-" : "+"} Filtros por listas</h5>
                </div>
              </div>
              {exibirFiltrosLista && (
                <>
                  <div className="row mt-12">

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Estado:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoEstado}
                        disabled={carregamentoEstado}
                        options={estado}
                        value={estadoSelected}
                        onChange={(event: any) => {
                          setEstadoSelected(event);
                          setIdsSelect("estadoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Sub Área Direito:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoSubAreaDireito}
                        disabled={carregamentoSubAreaDireito}
                        options={subAreaDireito}
                        value={subAreaDireitoSelected}
                        onChange={(event: any) => {
                          setSubAreaDireitoSelected(event);
                          setIdsSelect("subAreaDireitoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Classificação:
                      </label>
                      <MultiSelect
                        disabled={carregamentoClassificacao}
                        isLoading={carregamentoClassificacao}
                        options={classificacao}
                        value={classificacaoSelected}
                        onChange={(event: any) => {
                          setClassificacaoSelected(event);
                          setIdsSelect("classificacaoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Âmbito:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoAmbito}
                        disabled={carregamentoAmbito}
                        options={ambito}
                        value={ambitoSelected}
                        onChange={(event: any) => {
                          setAmbitoSelected(event);
                          setIdsSelect("ambitoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Juiz:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoJuiz}
                        disabled={carregamentoJuiz}
                        options={juiz}
                        value={juizSelected}
                        onChange={(event: any) => {
                          setJuizSelected(event);
                          setIdsSelect("juizId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Tipo de Contigência:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoTipoContingencia}
                        disabled={carregamentoTipoContingencia}
                        options={tipoContingencia}
                        value={tipoContingenciaSelected}
                        onChange={(event: any) => {
                          setTipoContingenciaSelected(event);
                          setIdsSelect("tipoContingenciaId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Motivo do Encerramento:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoMotivoEncerramento}
                        disabled={carregamentoMotivoEncerramento}
                        options={motivoEncerramento}
                        value={motivoEncerramentoSelected}
                        onChange={(event: any) => {
                          setMotivoEncerramentoSelected(event);
                          setIdsSelect("motivoEncerramentoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Instância:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoInstancia}
                        disabled={carregamentoInstancia}
                        options={instancia}
                        value={instanciaSelected}
                        onChange={(event: any) => {
                          setInstanciaSelected(event);
                          setIdsSelect("instanciaId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Esfera:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoEsfera}
                        disabled={carregamentoEsfera}
                        options={esfera}
                        value={esferaSelected}
                        onChange={(event: any) => {
                          setEsferaSelected(event);
                          setIdsSelect("esferaId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Fase Processual:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoFaseProcessual}
                        disabled={carregamentoFaseProcessual}
                        options={faseProcessual}
                        value={faseProcessualSelected}
                        onChange={(event: any) => {
                          setFaseProcessualSelected(event);
                          setIdsSelect("faseProcessualId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Risco:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoRisco}
                        disabled={carregamentoRisco}
                        options={risco}
                        value={riscoSelected}
                        onChange={(event: any) => {
                          setRiscoSelected(event);
                          setIdsSelect("riscoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Tipo de Ação:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoTipoAcao}
                        disabled={carregamentoTipoAcao}
                        options={tipoAcao}
                        value={tipoAcaoSelected}
                        onChange={(event: any) => {
                          setTipoAcaoSelected(event);
                          setIdsSelect("tipoAcaoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Tipo de Pedido:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoTipoPedido}
                        disabled={carregamentoTipoPedido}
                        options={tipoPedido}
                        value={tipoPedidoSelected}
                        onChange={(event: any) => {
                          setTipoPedidoSelected(event);
                          setIdsSelect("tipoPedidoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>

                </>
              )}
            </div>
            <div className="modal-footer" style={{ margin: "0 auto" }}>
              <button
                type="button"
                onClick={() => {
                  setPesquisar(true);
                  toggleModal();
                }}
                className="btn btn-orange me-5"
              >
                Pesquisar
              </button>
              <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                CANCELAR
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default FiltroModal;
