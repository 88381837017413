import FiltroPesquisaEmpresaRequest from "../../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest";
import { MultiSelect } from "react-multi-select-component";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CidadeService from "../../services/CidadeService";
import AtividadeEmpresaService from "../../services/AtividadeEmpresaService";
import ClienteService from "../../services/ClienteService";
import GrupoEconomicoService from "../../services/GrupoEconomicoService";
import MoedaService from "../../services/MoedaService";
import MotivoSituacaoService from "../../services/MotivoSituacaoService";
import OrgaoRegistroService from "../../services/OrgaoRegistroService";
import TipoEmpresaService from "../../services/TipoEmpresaService";

interface IEmpresaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtro: FiltroPesquisaEmpresaRequest;
  setFiltro: (filtro: FiltroPesquisaEmpresaRequest) => void;
}

const location = {
  "allItemsAreSelected": "Todos os items estão selecionados.",
  "clearSearch": "Limpar pesquisa",
  "clearSelected": "Limpar seleção",
  "noOptions": "Não há opções",
  "search": "Buscar",
  "selectAll": "Selecionar todos",
  "selectAllFiltered": "Selecionar todos (filtrados)",
  "selectSomeItems": "Selecione...",
  "create": "Criar",
};

const locationLimited = {
  "allItemsAreSelected": "Todos os items estão selecionados.",
  "clearSearch": "Limpar pesquisa",
  "clearSelected": "Limpar seleção",
  "noOptions": "Não há opções",
  "search": "Buscar",
  "selectAll": "Selecionar todos (100 primeiros itens)",
  "selectAllFiltered": "Selecionar todos (filtrados)",
  "selectSomeItems": "Selecione...",
  "create": "Criar",
};


const SocietarioFiltroListasModal = ({ toggleModal, exibirModal, filtro, setFiltro, setPesquisar }: IEmpresaModalProps) => {

  const [carregandoAtividadeEmpresaPrincipal, setCarregandoAtividadeEmpresaPrincipal] = useState<boolean>(false);
  const [atividadeEmpresaPrincipal, setAtividadeEmpresaPrincipal] = useState<any>([]);
  const [atividadeEmpresaPrincipalIdsSelecionados, setAtividadeEmpresaPrincipalIdsSelecionados] = useState<any>([]);
  
  const [carregandoCidade, setCarregandoCidade] = useState<boolean>(false);
  const [cidade, setCidade] = useState<any>([]);
  const [cidadeIdsSelecionados, setCidadeIdsSelecionados] = useState<any>([]);
  
  const [carregandoCliente, setCarregandoCliente] = useState<boolean>(false);
  const [cliente, setCliente] = useState<any>([]);
  const [clienteIdsSelecionados, setClienteIdsSelecionados] = useState<any>([]);
  
  const [carregandoGrupoEconomico, setCarregandoGrupoEconomico] = useState<boolean>(false);
  const [grupoEconomico, setGrupoEconomico] = useState<any>([]);
  const [grupoEconomicoIdsSelecionados, setGrupoEconomicoIdsSelecionados] = useState<any>([]);
  
  const [carregandoMoeda, setCarregandoMoeda] = useState<boolean>(false);
  const [moeda, setMoeda] = useState<any>([]);
  const [moedaIdsSelecionados, setMoedaIdsSelecionados] = useState<any>([]);
  
  const [carregandoMotivoSituacao, setCarregandoMotivoSituacao] = useState<boolean>(false);
  const [motivoSituacao, setMotivoSituacao] = useState<any>([]);
  const [motivoSituacaoIdsSelecionados, setMotivoSituacaoIdsSelecionados] = useState<any>([]);
  
  const [carregandoOrgaoRegistro, setCarregandoOrgaoRegistro] = useState<boolean>(false);
  const [orgaoRegistro, setOrgaoRegistro] = useState<any>([]);
  const [orgaoRegistroIdsSelecionados, setOrgaoRegistroIdsSelecionados] = useState<any>([]);
  
  const [carregandoTipoEmpresa, setCarregandoTipoEmpresa] = useState<boolean>(false);
  const [tipoEmpresa, setTipoEmpresa] = useState<any>([]);
  const [tipoEmpresaIdsSelecionados, setTipoEmpresaIdsSelecionados] = useState<any>([]);


  useEffect(() => {
    if (exibirModal) {
      carregarGrupoEconomico();
      carregarAtividadeEmpresaPrincipal();
      carregarCidade();
      carregarCliente();
      carregarMoeda();
      carregarMotivoSituacao();
      carregarOrgaoRegistro();
      carregarTipoEmpresa();
    }
  }, [exibirModal]);



  async function carregarAtividadeEmpresaPrincipal(){
    if(atividadeEmpresaPrincipal.length) return;
    setCarregandoAtividadeEmpresaPrincipal(true);
    try{
      const resultado = await AtividadeEmpresaService.obterAtividadeEmpresas({
        offset: 0,
        limit: 100,
        sort: "atividadeEmpresaId",
        status: 0,
        nome: "",
        codigo: "",
        totalItems: 0
      });
      setAtividadeEmpresaPrincipal(returnOptions(resultado.data, "atividadeEmpresaId", "atividadeEmpresaPrincipal"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoAtividadeEmpresaPrincipal(false);
  }

  async function carregarCidade(nome = ""){
    if(cidade.length) return;
    setCarregandoCidade(true);
    try{
      const resultado = await CidadeService.obterCidade({
        offset: 0,
        limit: 10000,
        sort: "nome",
        status: 0,
        nome: nome,
        codigo: "",
        totalItems: 0
      });
      setCidade(returnOptions(resultado.data, "cidadeId", "cidade"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoCidade(false);
  }

  async function carregarCliente(nome = ""){
    if(cliente.length) return;    
    setCarregandoCliente(true);
    try{
      const resultado = await ClienteService.obterClientes({
        offset: 0,
        limit: 5000,
        sort: "clienteId",
        status: 0,
        clienteId: 0,
        codigo: "",
        totalItems: 0,
        clienteNome: nome,
        clienteDocumento: "",
        pessoaId: 0,
        clienteTipo: ""
      });
      setCliente(returnOptions(resultado.data, "clienteId", "cliente", "clienteNome||item.clienteDocumento"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoCliente(false);
  }

  async function carregarGrupoEconomico(){
    if(grupoEconomico.length) return;
    
    setCarregandoGrupoEconomico(true);
    try{
      const resultado = await GrupoEconomicoService.obterGrupoEconomicos({
        offset: 0,
        limit: 100,
        sort: "grupoEconomicoId",
        status: 0,
        nome: "",
        codigo: "",
        totalItems: 0
      });
      setGrupoEconomico(returnOptions(resultado.data, "grupoEconomicoId", "grupoEconomico"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoGrupoEconomico(false);    
  }

  async function carregarMoeda(){
    if(moeda.length) return;
    
    setCarregandoMoeda(true);
    try{
      const resultado = await MoedaService.obterMoedas({
        offset: 0,
        limit: 100,
        sort: "moedaId",
        status: 0,
        nome: "",
        codigo: "",
        totalItems: 0
      });
      setMoeda(returnOptions(resultado.data, "moedaId", "moeda"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoMoeda(false);    
  }

  async function carregarMotivoSituacao(){
    if(motivoSituacao.length) return;
    
    setCarregandoMotivoSituacao(true);
    try{
      const resultado = await MotivoSituacaoService.obterMotivoSituacaos({
        offset: 0,
        limit: 100,
        sort: "motivoSituacaoId",
        status: 0,
        nome: "",
        codigo: "",
        totalItems: 0
      });
      setMotivoSituacao(returnOptions(resultado.data, "motivoSituacaoId", "motivoSituacao"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoMotivoSituacao(false);    
  }

  async function carregarOrgaoRegistro(){
    if(orgaoRegistro.length) return;
    
    setCarregandoOrgaoRegistro(true);
    try{
      const resultado = await OrgaoRegistroService.obterOrgaoRegistros({
        offset: 0,
        limit: 100,
        sort: "orgaoRegistroId",
        status: 0,
        nome: "",
        codigo: "",
        totalItems: 0
      });
      setOrgaoRegistro(returnOptions(resultado.data, "orgaoRegistroId", "orgaoRegistro"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoOrgaoRegistro(false);    
  }

  async function carregarTipoEmpresa(){
    if(tipoEmpresa.length) return;
    
    setCarregandoTipoEmpresa(true);
    try{
      const resultado = await TipoEmpresaService.obterTipoEmpresas({
        offset: 0,
        limit: 100,
        sort: "tipoEmpresaId",
        status: 0,
        nome: "",
        codigo: "",
        totalItems: 0
      });
      setTipoEmpresa(returnOptions(resultado.data, "tipoEmpresaId", "tipoEmpresa"));
    } catch(error: any){
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setCarregandoTipoEmpresa(false);    
  }

  const returnOptions = (array: any, propId: string, arrayName: string, label = "nome") => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: eval(`item.${label}`),
      });
    });
    return eval(`${arrayName}`);
  };


  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {
      case "atividadeEmpresaPrincipalId":
        setFiltro({...filtro, atividadeEmpresaPrincipalId: IdsSelected});
        break;
      case "cidadeId":
        setFiltro({...filtro, cidadeId: IdsSelected});
        break;
      case "clienteId":
        setFiltro({...filtro, clienteId: IdsSelected});
        break;
      case "grupoEconomicoId":
        setFiltro({...filtro, grupoEconomicoId: IdsSelected});
        break;
      case "moedaId":
        setFiltro({...filtro, moedaId: IdsSelected});
        break;
      case "motivoSituacaoId":
        setFiltro({...filtro, motivoSituacaoId: IdsSelected});
        break;
      case "orgaoRegistroId":
        setFiltro({...filtro, orgaoRegistroId: IdsSelected});
        break;
      case "tipoEmpresaId":
        setFiltro({...filtro, tipoEmpresaId: IdsSelected});
        break;
    }
  };

  return (
    <>     
    { exibirModal && (
          <div className="modal-body">
            <div className="row mt-12">
              <div className="col-md-6 mt-3">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Grupo Economico:
                </label>
                <MultiSelect
                  overrideStrings={location}
                  isLoading={carregandoGrupoEconomico}
                  disabled={carregandoGrupoEconomico}
                  options={grupoEconomico}
                  value={grupoEconomicoIdsSelecionados}
                  onChange={(event: any) => {
                    setGrupoEconomicoIdsSelecionados(event);
                    setIdsSelect("grupoEconomicoId", event);
                  }}
                  labelledBy={"Selecione..."}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-atividadeempresaprincipalid" className="form-label fw-bolder text-orange">
                  Atividade da Empresa Principal
                </label>
                <MultiSelect
                  overrideStrings={location}
                  isLoading={carregandoAtividadeEmpresaPrincipal}
                  disabled={carregandoAtividadeEmpresaPrincipal}
                  options={atividadeEmpresaPrincipal}
                  value={atividadeEmpresaPrincipalIdsSelecionados}
                  onChange={(event: any)=>{
                    setAtividadeEmpresaPrincipalIdsSelecionados(event);
                    setIdsSelect("atividadeEmpresaPrincipalId", event);
                  }}
                  filterOptions={(options: any, atividadeEmpresa:string) => {
                    if(atividadeEmpresa.length > 0){
                      return options.filter((option: any) => {
                        return option.label.toLowerCase().includes(atividadeEmpresa.toLowerCase());
                      }).slice(0, 10);
                    }
                    return options.slice(0, 10);
                  }}
                  labelledBy={"Selecione..."}
                  />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-cidadeid" className="form-label fw-bolder text-orange">
                  Cidade
                </label>
                <MultiSelect
                  overrideStrings={locationLimited}
                  isLoading={carregandoCidade}
                  disabled={carregandoCidade}
                  options={cidade.slice(0, 100)}
                  value={cidadeIdsSelecionados}
                  filterOptions={(options: any, cidadeNome:string) => {
                    if(cidadeNome.trim().length === 0) return options.slice(0, 100);
                    let searchRegex = eval(`/${cidadeNome.trim().toLowerCase().split(/\s+/g).join('|')}/g`);
                    let filtered =  cidade.filter((option: any) => {
                      return searchRegex.exec(option.label.toLowerCase()) !== null;
                    });

                    if(cidadeNome.trim.length > 3)
                      return filtered;
                    return filtered.slice(0, 100);
                  }}
                  onChange={(event: any)=>{
                    setCidadeIdsSelecionados(event);
                    setIdsSelect("cidadeId", event);
                  }}
                  labelledBy={"Selecione..."}
                  />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-clienteid" className="form-label fw-bolder text-orange">
                  Cliente
                </label>
                <MultiSelect
                  overrideStrings={location}
                  isLoading={carregandoCliente}
                  disabled={carregandoCliente}
                  options={cliente}
                  value={clienteIdsSelecionados}
                  filterOptions={(options: any, cliente:string) => {
                    if(cliente.length > 0){
                      return options.filter((option: any) => {
                        return option.label.toLowerCase().includes(cliente.toLowerCase());
                      }).slice(0, 10);
                    }
                    return options.slice(0, 10);
                  }}

                  onChange={(event: any)=>{
                    setClienteIdsSelecionados(event);
                    setIdsSelect("clienteId", event);
                  }}
                  labelledBy={"Selecione..."}
                  />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-moedaid" className="form-label fw-bolder text-orange">
                  Moeda
                </label>
                <MultiSelect
                  overrideStrings={location}
                  isLoading={carregandoMoeda}
                  disabled={carregandoMoeda}
                  options={moeda}
                  value={moedaIdsSelecionados}
                  onChange={(event: any)=>{
                    setMoedaIdsSelecionados(event);
                    setIdsSelect("moedaId", event);
                  }}
                  labelledBy={"Selecione..."}
                  />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-motivosituacaoid" className="form-label fw-bolder text-orange">
                  Motivo da Situacao
                </label>
                <MultiSelect
                  overrideStrings={location}
                  isLoading={carregandoMotivoSituacao}
                  disabled={carregandoMotivoSituacao}
                  options={motivoSituacao}
                  value={motivoSituacaoIdsSelecionados}
                  onChange={(event: any)=>{
                    setMotivoSituacaoIdsSelecionados(event);
                    setIdsSelect("motivoSituacaoId", event);
                  }}
                  labelledBy={"Selecione..."}
                  />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-orgaoregistroid" className="form-label fw-bolder text-orange">
                  Órgão do Registro
                </label>
                <MultiSelect
                  overrideStrings={location}
                  isLoading={carregandoOrgaoRegistro}
                  disabled={carregandoOrgaoRegistro}
                  options={orgaoRegistro}
                  value={orgaoRegistroIdsSelecionados}
                  onChange={(event: any)=>{
                    setOrgaoRegistroIdsSelecionados(event);
                    setIdsSelect("orgaoRegistroId", event);
                  }}
                  labelledBy={"Selecione..."}
                  />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="form-tipoempresaid" className="form-label fw-bolder text-orange">
                  Tipo de Empresa
                </label>
                <MultiSelect
                  overrideStrings={location}
                  isLoading={carregandoTipoEmpresa}
                  disabled={carregandoTipoEmpresa}
                  options={tipoEmpresa}
                  value={tipoEmpresaIdsSelecionados}
                  onChange={(event: any)=>{
                    setTipoEmpresaIdsSelecionados(event);
                    setIdsSelect("tipoEmpresaId", event);
                  }}
                  labelledBy={"Selecione..."}
                  />
              </div>
            </div>               
          </div> 

    ) }
    </>
  );
};

export default SocietarioFiltroListasModal;
