import { useContext, useEffect, useRef, useState } from "react";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";

import { MultiSelect } from "react-multi-select-component";
import IProcesso from "../../interfaces/IProcesso";
import IObrigacao from "../../interfaces/IObrigacao";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaObrigacaoRequest from "../../interfaces/Requests/Obrigacao/FiltroPesquisaObrigacaoRequest";


import sessionStorageService from "../../services/sessionStorage/sessionStorageService";
import ObrigacaoService from "../../services/ObrigacaoService";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const ObrigacaoPage = () => {
  const [StatusOptions, setStatusOptions] = useState<any>([{ label: "Cancelada", value: 4 }, { label: "Cumprida", value: 2 }, { label: "Descumprida", value: 3 }, { label: "Pendente", value: 1 }]);
  const [StatusSelected, setStatusSelected] = useState([]);

  const [carregandoObrigacoes, setCarregandoObrigacoes] = useState(false);
  const [obrigacoes, setObrigacoes] = useState<IObrigacao[]>([]);

  const { processo, setProcesso, setAreaDireito, obrigacao, setObrigacao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaObrigacaoRequest>({
    obrigacaoId: 0,
    processoId: processo.processoId,
    tipoObrigacaoId: 0,
    statusObrigacaoId: [],
    origemObrigacaoId: 0,
    descricaoObrigacao: "",
    prazoMaiorIgual: "",
    prazoMenorIgual: "",
    possuiMulta: "",
    periodicidadeId: "",
    valorMultaBaseMaiorIgual: "",
    valorMultaBaseMenorIgual: "",
    valorMultaAtualMaiorIgual: "",
    valorMultaAtualMenorIgual: "",
    descricaoMulta: "",
    dataBaseMultaMaiorIgual: "",
    dataBaseMultaMenorIgual: "",
    dataIntimacaoMaiorIgual: "",
    dataIntimacaoMenorIgual: "",
    dataConclusaoMaiorIgual: "",
    dataConclusaoMenorIgual: "",
    concluida: 0,
    codigo: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "obrigacaoId",
  });

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusObrigacaoId: IdsSelected };
    });
  }, [StatusSelected]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_obrigacoes");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_obrigacoes");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_obrigacoes")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaObrigacaoRequest = JSON.parse((await sessionStorageService.obter("filtro_obrigacoes")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, processoId: filtro.processoId });
  };


  async function carregarObrigacoes(filtro: FiltroPesquisaObrigacaoRequest): Promise<void> {
    try {
      setCarregandoObrigacoes(true);
      let resultado: IRecuperaLista<IObrigacao>;

      resultado = await ObrigacaoService.obterObrigacoes(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setObrigacoes(resultado.data);
      inserirSessionStorage();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setObrigacoes([]);
    } finally {
      setCarregandoObrigacoes(false);
    }
  }

  const colunas: TableColumn<IObrigacao>[] = [
    {
      name: "Id",
      sortField: "ObrigacaoId",
      selector: (row: IObrigacao) => row.obrigacaoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row: IObrigacao) => row.statusObrigacaoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo de Obrigacão",
      sortField: "tipoObrigacaoNome",
      selector: (row: IObrigacao) => row.tipoObrigacaoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data da Intimação",
      sortField: "dataIntimacao",
      selector: (row: IObrigacao) => {
        if (row.dataIntimacao === null) return "-";
        const data = row.dataIntimacao.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Conclusão",
      sortField: "dataConclusao",
      selector: (row: IObrigacao) => {
        if (row.dataConclusao === null) return "-";
        const data = row.dataConclusao.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true,
    }, {
      name: "",
      cell: (row: IObrigacao) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Editar"
              onClick={() => abrirCapa(row)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IObrigacao>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        ObrigacaoId: 0,
        TipoObrigacaoId: 0,
        StatusObrigacaoId: [],
        OrigemObrigacaoId: 0,
        DescricaoObrigacao: "",
        PrazoMaiorIgual: "",
        PrazoMenorIgual: "",
        PossuiMulta: "",
        PeriodicidadeId: "",
        ValorMultaBaseMaiorIgual: "",
        ValorMultaBaseMenorIgual: "",
        ValorMultaAtualMaiorIgual: "",
        ValorMultaAtualMenorIgual: "",
        DescricaoMulta: "",
        dataBaseMultaMaiorIgual: "",
        dataBaseMultaMenorIgual: "",
        dataIntimacaoMaiorIgual: "",
        dataIntimacaoMenorIgual: "",
        dataConclusaoMaiorIgual: "",
        dataConclusaoMenorIgual: "",
        Concluida: 0,
        Codigo: "",
        statusObrigacaoNome: "",
      };
    });
  }

  const abrirCapa = (row: IObrigacao) => {
    setObrigacao({
      ...row,
      obrigacaoId: row.obrigacaoId,
      tipoObrigacaoId: row.tipoObrigacaoId,
      categoriaId: row.tipoObrigacaoId,

      modulo: obrigacao.modulo,

      processoId: obrigacao?.processoId,
      areaDireitoId: obrigacao.areaDireitoId,

      tipoImovelId: obrigacao.tipoImovelId,
      imovelId: obrigacao?.imovelId
    });
    navigate("/Obrigacao/Capa");
  };

  return (
    <>
      <LayoutPageForButton title="Obrigação">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setObrigacao({ ...obrigacao, obrigacaoId: 0 });
            navigate("/Obrigacao/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="row mt-2">
        <div className="col-md-12">

          <CollapseFiltro titulo="Filtros" content={
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarObrigacoes(filtrosPesquisa);
              }}
              className="row g-3 mb-3 form-buscar-obrigacao"
            >
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                  Data da Intimação:
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataIntimacaoMaiorIgual && filtrosPesquisa.dataIntimacaoMenorIgual
                      ? [filtrosPesquisa.dataIntimacaoMaiorIgual, filtrosPesquisa.dataIntimacaoMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue) => {
                        return {
                          ...oldValue,
                          dataIntimacaoMaiorIgual: "",
                          dataIntimacaoMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataIntimacaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataIntimacaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-orange">
                  Data de Conclusão:
                </label>
                <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  maxDate={new Date()}
                  value={
                    filtrosPesquisa.dataConclusaoMaiorIgual && filtrosPesquisa.dataConclusaoMenorIgual
                      ? [filtrosPesquisa.dataConclusaoMaiorIgual, filtrosPesquisa.dataConclusaoMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue) => {
                        return {
                          ...oldValue,
                          dataConclusaoMaiorIgual: "",
                          dataConclusaoMenorIgual: "",
                        };
                      });
                      return;
                    }
                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa((oldValue) => {
                      return {
                        ...oldValue,
                        dataConclusaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataConclusaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={false}
                  disabled={false}
                  options={StatusOptions}
                  value={StatusSelected}
                  onChange={(event: any) => setStatusSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-tipo-obrigacao" className="form-label fw-bolder text-orange">
                  Tipo de Obrigacão:
                </label>
                <select
                  value={filtrosPesquisa.tipoObrigacaoId}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, tipoObrigacaoId: parseInt(e.target.value) };
                    });
                  }}
                  placeholder="Tipo de Obrigacão"
                  className={"form-select"}
                  id="form-tipo-obrigacao"
                >
                  <option value={0}>Todos</option>
                  <option value={2}>Liminar</option>
                  <option value={1}>Obrigação de Fazer</option>
                  <option value={3}>Obrigação de Não Fazer</option>
                  <option value={4}>Obrigação de Pagar</option>
                </select>
              </div>
              <div className="col-12 " style={{ textAlign: "right" }}>
                <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                  <FontAwesomeIcon color="white" className="" icon={faSearch} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-orange search-buttom-margin ms-2"
                  title="Limpar Filtro"
                >
                  <FontAwesomeIcon color="white" className="" icon={faEraser} />
                </button>
              </div>
            </form>} />
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoObrigacoes}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Obrigacoes"
            itens={obrigacoes}
          />
        </div>
      </div>
    </>
  );
};

export default ObrigacaoPage;
