import { RenderElementProps } from "slate-react";
import { TableCellElement as TableCellEl } from "../../../../../../utils/types/slate";

export const TableCellElement = (props: RenderElementProps) => {
  const element = props.element as TableCellEl;

  return (
    <td
      {...props.attributes}
      rowSpan={element.cellAttributes?.rowSpan}
      colSpan={element.cellAttributes?.colSpan}
      style={element.style}
    >
      {props.children}
    </td>
  );
};
