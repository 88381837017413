import IDocumento from "../interfaces/IDocumento";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaDocumentoRequest from "../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import api from "./Api";

class XLearningService {

  public async download(documentoId: number) {
    return await api.get(`/XLearning/download/${documentoId}`, { responseType: "blob" });
  }

  public async previewDocument(documentoId: number): Promise<any> {
    return await api.get<any>(`/XLearning/generatePreSignedURL/${documentoId}`);
  }

  public async obterArquivos(filtros: FiltroPesquisaDocumentoRequest): Promise<IRecuperaLista<IDocumento>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.documentoId && filtros.documentoId.length > 0) {
      query += filtros.documentoId.map(id => `&documentoId=${id}`).join('')
    }

    if (filtros.nome && filtros.nome && filtros.nome.length) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.despesaId && filtros.despesaId !== 0) {
      query += `&despesaId=${filtros.despesaId}`;
    }

    if (filtros.processoId && filtros.processoId !== 0) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.contratoId && filtros.contratoId !== 0) {
      query += `&contratoId=${filtros.contratoId}`;
    }

    if (filtros.empresaId && filtros.empresaId !== 0) {
      query += `&empresaId=${filtros.empresaId}`;
    }

    if (filtros.marcaId && filtros.marcaId !== 0) {
      query += `&marcaId=${filtros.marcaId}`;
    }

    if (filtros.imovelId && filtros.imovelId !== 0) {
      query += `&imovelId=${filtros.imovelId}`;
    }

    if (filtros.licencaId && filtros.licencaId !== 0) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.licencaId && filtros.licencaId !== 0) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.consultaId && filtros.consultaId !== 0) {
      query += `&consultaId=${filtros.consultaId}`;
    }

    if (filtros.procuracaoId && filtros.procuracaoId !== 0) {
      query += `&procuracaoId=${filtros.procuracaoId}`;
    }

    if (filtros.atoSocietarioId && filtros.atoSocietarioId !== 0) {
      query += `&atoSocietarioId=${filtros.atoSocietarioId}`;
    }

    if (filtros.modulo.length > 0) {
      for (let index = 0; index < filtros.modulo.length; index++) {
        const modulo = filtros.modulo[index];

        query += `&modulo=${modulo}`;
      }
    }

    if (filtros.status && filtros.status !== 0) {
      query += `&status=${filtros.status}`;
    }

    if (filtros.origem && filtros.origem !== 0) {
      query += `&origem=${filtros.origem}`;
    }

    if (filtros.descricao && filtros.descricao.length > 0) {
      query += `&descricao=${filtros.descricao}`;
    }

    if (filtros.codigo && filtros.codigo.length > 0) {
      query += `&codigo=${filtros.codigo}`;
    }

    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }

    if (filtros.tipoDocumentoId && filtros.tipoDocumentoId.length > 0) {
      query += filtros.tipoDocumentoId.map(id => `&tipoDocumentoId=${id}`).join('')
    }

    if (filtros.usuarioIdCadastro && filtros.usuarioIdCadastro.length > 0) {
      query += filtros.usuarioIdCadastro.map(id => `&usuarioIdCadastro=${id}`).join('')
    }

    let { data: result } = await api.get<IRecuperaLista<IDocumento>>(`/XLearning/RecuperaDocumento?${query}`);

    return result;
  }

  public async upload(dados: { Modulo: number; Origem: string; File: File; TipoDocumentoId: number; Descricao: string; Restrito: string; Codigo: string; TipoContratoId?: number; PastaGedId?: number, TipoProcuracaoId?: number }): Promise<any> {
    const dadosFormulario = new FormData()

    dadosFormulario.append('Modulo', dados.Modulo.toString());
    dadosFormulario.append('Origem', dados.Origem);
    dadosFormulario.append('File', dados.File);
    dadosFormulario.append('TipoDocumentoId', dados.TipoDocumentoId.toString());
    dadosFormulario.append('Descricao', dados.Descricao);
    dadosFormulario.append('Restrito', dados.Restrito.toString());
    dadosFormulario.append('Codigo', dados.Codigo);

    if (dados.TipoContratoId && dados.TipoContratoId > 0) {
      dadosFormulario.append('TipoContratoId', dados.TipoContratoId.toString());
    }

    if (dados.TipoProcuracaoId && dados.TipoProcuracaoId > 0) {
      dadosFormulario.append('TipoProcuracaoId', dados.TipoProcuracaoId.toString());
    }

    if (dados.PastaGedId && dados.PastaGedId > 0) {
      dadosFormulario.append('PastaGedId', dados.PastaGedId.toString());
    }

    const arquivo = new FileReader();

    const controle = {
      carregando: true,
      arquivoB64: ""
    }

    arquivo.onload = async () => {
      controle.carregando = false;
      controle.arquivoB64 = arquivo.result as string;
    }
    arquivo.onerror = () => {
      controle.carregando = false;
    }
    arquivo.onabort = () => {
      controle.carregando = false;
    }

    arquivo.readAsDataURL(dados.File);

    while (controle.carregando) {
      await new Promise(resolve => setTimeout(resolve, 10));
    }

    const result = await api.post<number>('/XLearning/upload', dadosFormulario, { headers: { 'Content-Type': 'multipart/form-data' } });

    return { id: result.data, arquivoB64: controle.arquivoB64 };
  }

  public async inativaDocumento(documentoId: number) {
    let { data: result } = await api.put(`XLearning/InativaDocumentoPorId?id=${documentoId}`);

    return result;
  }

  public async atualizaDocumento(editarDocumento: IDocumento): Promise<void> {
    let dataDocumento = [
      { op: "replace", path: "/descricao", value: editarDocumento.descricao },
      { op: "replace", path: "/restrito", value: editarDocumento.restrito },
      { op: "replace", path: "/tipoDocumentoId", value: editarDocumento.tipoDocumentoId },
    ];

    if (editarDocumento.pastaGedId && editarDocumento.pastaGedId > 0) {
      dataDocumento.push({ op: "replace", path: "/pastaGedId", value: editarDocumento.pastaGedId })

    }

    await Promise.all([await api.patch<void>(`XLearning/AtualizaDocumento?id=${editarDocumento.documentoId}`, dataDocumento)]);
  }

  public async gerarExcel(relatorioId: number): Promise<Blob> {
    const { data: result } = await api.get(`XLearning/GerarRelatorio?relatorioId=${relatorioId}`, { responseType: 'blob' });
    return result;
  }

}

export default new XLearningService();
