/* eslint-disable no-case-declarations */
import { get } from 'lodash';
import * as Yup from "yup";
import moment from "moment";
import { FormikProps } from "formik";

import { EPeriodoEnum } from "../enum/EPeriodoEnum";
import { ECamposIDEnum, ETipoCampoEnum, returnarEnumDescricaoID } from "../enum";

import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { notifySuccess } from "../components/Comum/Toast/ToastFormik";

export const defaultSchema = (configuracoesTela: IRecuperaConfiguracaoTelaResponse[]) => {
  let schema: any = {};
  configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
    switch (configuracao.campoTipo) {

      case ETipoCampoEnum.Texto:
      case ETipoCampoEnum.AreaTexto:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
          schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`Campo ${configuracao.label} é obrigatório`);
        }
        break;

      case ETipoCampoEnum.Lista:

        if (configuracao.campoNome === ECamposIDEnum.CentroCusto) break;

        if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
          schema[`${returnarEnumDescricaoID(configuracao.campoNome, configuracao.isXCampo)}`] = Yup.number().min(1, `Campo ${configuracao.label} é obrigatório`).notOneOf([0], `Campo ${configuracao.label} é obrigatório`).typeError(`Campo ${configuracao.label} é obrigatório`);
        }

        if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.TipoDocumentoCheckList) {
          schema.quantidadeDocumentosCheckList = Yup.number().min(1, "Documentos do checklist são obrigatórios").typeError(`Campo ${configuracao.label} é obrigatório`);
        }

        if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.TipoDocumento) {
          schema.quantidadeArquivosSelecionados = Yup.number().min(1, "Documentos são obrigatórios").typeError(`Campo ${configuracao.label} é obrigatório`);
        }

        break;

      case ETipoCampoEnum.ListaMulti:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
          schema[`${returnarEnumDescricaoID(configuracao.campoNome, configuracao.isXCampo)}`] = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`).typeError(`Campo ${configuracao.label} é obrigatório`);
        }
        break;

      case ETipoCampoEnum.ListaComLink:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
          schema[`${configuracao.campoNome.toLowerCase()}Id`] = Yup.number().min(1, `Campo ${configuracao.label} é obrigatório`);
        }
        break;

      case ETipoCampoEnum.Valor:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
          schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required(`Campo ${configuracao.label} é obrigatório`).typeError(`Campo ${configuracao.label} é obrigatório`);
        }
        break;

      case ETipoCampoEnum.Data:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
          schema[configuracao.campoNome.toLowerCase()] = Yup.string()
            .required(`Campo ${configuracao.label} é obrigatório`)
            .test(
              "valid-date-length",
              `Campo ${configuracao.label} deve ter exatamente 10 caracteres no formato DD/MM/YYYY`,
              function (value: any) {
                return value && value.length === 10 && /^\d{4}-\d{2}-\d{2}$/.test(value);
              }
            )
            .typeError(`Campo ${configuracao.label} deve estar no formato DD/MM/YYYY`);
        }
        break;

      case ETipoCampoEnum.Empresa:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.Empresa) {
          schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
        }
        break;

      case ETipoCampoEnum.Pessoa:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno) {
          schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
        }

        if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.ParteContraria) {
          schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
        }
        break;

      case ETipoCampoEnum.Tabela:
        if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
          schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.array().min(1, `Campo tabela ${configuracao.label} é obrigatório`);
        }
        break;

      default:
        break;
    }
  });
  return Yup.object().shape(schema);
};

export const setValuesCampo = (configuracoesTela: IRecuperaConfiguracaoTelaResponse[], formik: FormikProps<any>) => {
  configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {

    const campoNome = configuracao.campoNome?.toLowerCase();
    const valor = configuracao.valor;
    const valorId = configuracao.valorId;
    const valorCampoTipoValor = configuracao.valorCampoTipoValor;

    if (!campoNome) {
      return;
    }

    let valorParaSetar;
    let campoIdParaSetar = campoNome;

    switch (configuracao.campoTipo) {
      case ETipoCampoEnum.AreaTexto:
      case ETipoCampoEnum.Texto:
      case ETipoCampoEnum.Percentual:
      case ETipoCampoEnum.Inteiro:
        valorParaSetar = valor;
        break;

      case ETipoCampoEnum.Valor:
        valorParaSetar = valorCampoTipoValor;
        break;

      case ETipoCampoEnum.Data:
        valorParaSetar = moment(valor, 'DD/MM/YYYY').format('YYYY-MM-DD');
        break;

      case ETipoCampoEnum.Lista:
        valorParaSetar = valorId;
        campoIdParaSetar = returnarEnumDescricaoID(campoNome, configuracao.isXCampo);
        break;

      case ETipoCampoEnum.Boolean:
        valorParaSetar = Boolean(valor);
        break;

      default:
        return;
    }

    formik.setFieldTouched(campoIdParaSetar, true);
    formik.setFieldValue(campoIdParaSetar, valorParaSetar);
    notifySuccess(`Campo ${configuracao.label} preenchido com sucesso.`);
  });
};

export const setValuesXCadastroCampos = (configuracoesTela: IRecuperaConfiguracaoTelaResponse[], formik: FormikProps<any>) => {
  let xCampoValorList = new Array<any>();

  configuracoesTela.forEach(configuracao => {
    if (configuracao?.isXCampo) {
      const campoValor = get(formik.values, configuracao.campoNome.toLowerCase());

      switch (configuracao?.campoTipo) {
        case ETipoCampoEnum.AreaTexto:
        case ETipoCampoEnum.Texto:
          if (campoValor) {
            xCampoValorList.push({ campoId: configuracao.campo, texto: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Data:
          if (campoValor) {
            xCampoValorList.push({ campoId: configuracao.campo, data: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Valor:
        case ETipoCampoEnum.Percentual:
          if (campoValor && campoValor > 0) {
            xCampoValorList.push({ campoId: configuracao.campo, valor: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Inteiro:
          if (Number.isInteger(campoValor)) {
            xCampoValorList.push({ campoId: configuracao.campo, inteiro: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Lista:
        case ETipoCampoEnum.ListaComLink:
          const campoIdValor = get(formik.values, `${configuracao.campoNome.toLowerCase()}Id`);
          if (campoIdValor && campoIdValor > 0) {
            xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: campoIdValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.ListaMulti:
          const opcoesMulti = formik.values[`${configuracao.campoNome.toLowerCase()}Id`] || [];
          if (Array.isArray(opcoesMulti) && opcoesMulti.length > 0) {
            opcoesMulti.forEach((xOpcoes: any) => {
              xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: xOpcoes.value, campoTipo: configuracao?.campoTipo });
            });
          }
          break;

        case ETipoCampoEnum.Boolean:
          if (typeof campoValor === 'boolean') {
            xCampoValorList.push({ campoId: configuracao.campo, boolean: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Tabela:
          if (campoValor) {
            xCampoValorList.push({ campoId: configuracao.campo, xCampoValorTabelaCreateDto: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        default:
          break;
      }
    }
  });

  return xCampoValorList;
}

export const setValuesXPesquisaCampos = (configuracoesTela: IRecuperaConfiguracaoTelaResponse[], formik: FormikProps<any>) => {
  let xCampoValorList: Array<any> = [];

  configuracoesTela.forEach(configuracao => {
    if (configuracao?.isXCampo) {
      const campoValor = get(formik.values, configuracao.campoNome.toLowerCase());

      switch (configuracao?.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          if (campoValor) {
            xCampoValorList.push({ campoId: configuracao.campo, texto: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Percentual:
          if (campoValor && campoValor > 0) {
            xCampoValorList.push({ campoId: configuracao.campo, valor: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Inteiro:
          if (Number.isInteger(campoValor)) {
            xCampoValorList.push({ campoId: configuracao.campo, inteiro: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;

        case ETipoCampoEnum.Lista:
        case ETipoCampoEnum.ListaMulti:
        case ETipoCampoEnum.ListaComLink:
          const opcoesMulti = formik.values[`${configuracao.campoNome.toLowerCase()}Id`] || [];
          if (Array.isArray(opcoesMulti) && opcoesMulti.length > 0) {
            opcoesMulti.forEach((xOpcoes: any) => {
              xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: xOpcoes.value, campoTipo: configuracao?.campoTipo });
            });
          }
          break;

        case ETipoCampoEnum.Boolean:
          if (typeof campoValor === 'boolean') {
            xCampoValorList.push({ campoId: configuracao.campo, boolean: campoValor, campoTipo: configuracao?.campoTipo });
          }
          break;


        default:
          break;
      }
    }
  });

  return xCampoValorList;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setValuesXCamposSolucoes = (configuracoesTela: IRecuperaConfiguracaoTelaResponse[], formik: FormikProps<any>) => {
  let xCampoValorList = new Array<any>;
  configuracoesTela.forEach(configuracao => {

    if (configuracao?.isXCampo) {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.AreaTexto:
          xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
          break;

        case ETipoCampoEnum.Texto:
          xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
          break;

        case ETipoCampoEnum.Data:
          xCampoValorList.push({ campoId: configuracao.campo, data: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
          break;

        case ETipoCampoEnum.Valor:
        case ETipoCampoEnum.Percentual:
          xCampoValorList.push({ campoId: configuracao.campo, valor: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
          break;

        case ETipoCampoEnum.Inteiro:
          xCampoValorList.push({ campoId: configuracao.campo, inteiro: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
          break;

        case ETipoCampoEnum.Lista:
          if (eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`) > 0) {
            xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo });
          }
          break;

        case ETipoCampoEnum.Boolean:
          xCampoValorList.push({ campoId: configuracao.campo, boolean: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
          break;

        default:
          break;
      }
    }
  });
  return xCampoValorList;
}

export const setValueCalcularVigencia = (vigencia: any, formik: FormikProps<any>) => {
  let hoje: any;
  let final: any;
  let periodo = parseInt(formik.values["quantidadeperiodicidade"], 10) || 1;
  let inicio: any;

  if (!formik.values?.datainiciovigencia) {
    inicio = moment(new Date()).format('YYYY-MM-DD');
    formik.setFieldValue('datainiciovigencia', inicio);
  } else {
    inicio = formik.values?.datainiciovigencia.split("-");
  }

  const ano = parseInt(inicio[0], 10);
  const mes = parseInt(inicio[1], 10) - 1; // Os meses em JavaScript são baseados em zero (janeiro é 0)
  const dia = parseInt(inicio[2], 10);

  hoje = new Date(ano, mes, dia);

  switch (parseInt(vigencia)) {
    case EPeriodoEnum.DIA:
      final = new Date(ano, mes, hoje.getDate() + periodo);
      break;

    case EPeriodoEnum.SEMANAL:
      final = new Date(ano, mes, hoje.getDate() + periodo * 7);
      break;

    case EPeriodoEnum.MES:
      final = new Date(ano, hoje.getMonth() + periodo, dia);
      break;

    case EPeriodoEnum.ANO:
      final = new Date(hoje.getFullYear() + periodo, mes, dia);
      break;

    case EPeriodoEnum.TRIMESTRAL:
      final = new Date(ano, hoje.getMonth() + periodo * 3, dia);
      break;

    default:
      formik.setFieldValue('periodoVigencia', null);
      formik.setFieldValue('datafimvigencia', null);
      formik.setFieldValue("periodicidadeId", EPeriodoEnum.DEFAULT);
      return;
  }

  formik.setFieldValue('datafimvigencia', moment(final).format('YYYY-MM-DD'));
};