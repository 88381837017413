import { Droppable } from "react-beautiful-dnd";
import { ModuloType } from "../PerfilPermissoesPageEdit";
import Modulo from "./Modulo";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IColumnProps {
  column: {
    id: string;
    title: string;
    modulosIds: number[] | undefined;
  };
  modulos: ModuloType[];
  onModuloClick: (modulo: ModuloType) => void;

}

const Column = ({ column, modulos, onModuloClick }: IColumnProps) => {
  return (
    <div>
      <div>
        <div className="modal-header">
          <h6 className="modal-title text-orange">
            <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />
            {column.title}
          </h6>
        </div>
      </div>
      <div
        style={{
          marginTop: "1rem",
          borderRadius: 2,
          padding: "1.5rem",
          display: "flex",
          flexDirection: "column",
          height: "60vh",
          width: "30vw",
          maxWidth: "700px",
        }}
        className="border sm:flex-row"
      >
        <Droppable droppableId={column.id}>
          {(provided) => (
            <div
              style={{ padding: 8, overflowY: "scroll", height: "60vh" }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {modulos?.map((modulo, index) => {
                return (
                  <Modulo key={modulo.modulo} modulo={modulo} index={index} onModuloClick={onModuloClick}/>
                );
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default Column;
