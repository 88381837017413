import { faHistory, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import Swal from "sweetalert2";
import * as Yup from "yup";
import IDocumento from "../../../interfaces/IDocumento";
import ITipoDocumento from "../../../interfaces/ITipoDocumento";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import DocumentoService from "../../../services/DocumentoService";
import TipoDocumentoService from "../../../services/TipoDocumentoService";


interface IAndamentoFieldProps {
  editarDocumento: IDocumento;
  toggleModal: () => void;
  exibirModalDocumentoEdit: boolean;
  processoId?: number;
  areaDireitoId?: number;
  contratoId?: number;
  tipoContratoId?: number;
  atoSocietarioId?: number;
  empresaId?: number;
  modulo: number;
  setAtualizarTabela(atualizaAgora: boolean): void
}

const FileUploadAtoFieldEdit = ({ toggleModal, atoSocietarioId, exibirModalDocumentoEdit, processoId, areaDireitoId, contratoId, tipoContratoId, modulo, editarDocumento, setAtualizarTabela }: IAndamentoFieldProps) => {
  const [carregandoTipoDocumento, setCarregandoTipoDocumento] = useState(false);
  const [tipoDocumentos, setTipoDocumento] = useState<ITipoDocumento[]>([]);

  const initialValues: IDocumento = {
    index: 0,
    documentoId: 0,
    clienteId: 0,
    nome: "",
    descricao: "",
    tamanho: 0,
    tipoDocumentoId: 0,
    tipoDocumentoNome: "",
    restrito: false,
    status: "",
    codigo: "",
    dataCadastro: new Date,
    usuarioIdCadastro: 0,
    usuarioNomeCadastro: "",
    modulo: modulo.toString(),
    extensao: "",
    pastaGedNome: "",
    etiquetas:[]
  };

  const schema = () => {
    let schema: any = {};
    schema.descricao = Yup.string().min(3, "Campo descrição está inválido").required("Campo descrição é obrigatório");
    schema.tipoDocumentoId = Yup.number().min(1).required("Campo tipo de documento é obrigatório");
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema(),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar documento",
          text: `Você realmente deseja editar este documento?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Realizando edição...',
            showConfirmButton: false,
          });
          Swal.showLoading();
          await DocumentoService.atualizaDocumento(values);
          toggleModal();
          //setSubmitting(false);
          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: `Documento editado com sucesso!`,
            showConfirmButton: true,
            timer: 4000
          });
    
          Swal.close();

          setAtualizarTabela(true);
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar editar o documento.`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  async function cancelar() {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  const carregaTipoDocumento = async () => {
    setCarregandoTipoDocumento(true);
    try {
      if (areaDireitoId && areaDireitoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", 1, areaDireitoId, processoId || 0);
        setTipoDocumento(campos.data);
      }

      if (tipoContratoId && tipoContratoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", 7, tipoContratoId, contratoId || 0);
        setTipoDocumento(campos.data);
      }

      if (atoSocietarioId && atoSocietarioId > 0) {
        let campos = await TipoDocumentoService.obterTipoDocumentos({
          nome:'',
          codigo:'',          
          status:0,          
          offset: 0,
          limit: 1000,
          sort:'tipoDocumentoId',
          totalItems:0
        });
        setTipoDocumento(campos.data);
      }

      // if (tipoContratoId && tipoContratoId > 0) {
      //   let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", 7, tipoContratoId);
      //   setTipoDocumento(campos.data);
      // }
      setCarregandoTipoDocumento(false);

    } catch (error: any) {
      setTipoDocumento([]);
      setCarregandoTipoDocumento(false);
    }
  };

  useLayoutEffect(() => {
      carregaTipoDocumento();
  }, []);

  useEffect(() => {
    formik.setValues(initialValues);
    formik.resetForm();
    if (editarDocumento.documentoId > 0) {
      formik.setValues({ ...editarDocumento, dataCadastro: new Date(editarDocumento.dataCadastro) });
    }
  }, [exibirModalDocumentoEdit, editarDocumento]);

  return (
    <Modal size="lg" centered={false} show={exibirModalDocumentoEdit} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Editar documento - #{formik.values.documentoId.toString()} <FontAwesomeIcon className="mx-2 text-orange" icon={faHistory} />
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">

              {carregandoTipoDocumento ? (
                <>Carregando tipos de documento...</>
              ) : (
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    Tipo de Documento:
                  </label>
                  <select
                    {...formik.getFieldProps("tipoDocumentoId")}
                    value={formik.values.tipoDocumentoId}
                    onChange={(e) => {
                      formik.setFieldTouched("tipoDocumentoId", true);
                      formik.setFieldValue("tipoDocumentoId", parseInt(e.target.value));
                    }}
                    className={clsx(
                      "form-select",
                      {
                        "is-invalid": formik.touched.tipoDocumentoId && formik.errors.tipoDocumentoId,
                      },
                      {
                        "is-valid": formik.touched.tipoDocumentoId && !formik.errors.tipoDocumentoId,
                      }
                    )}
                    id="form-client"
                  >
                    <option value="0"> Selecione um tipo de documento </option>
                    {tipoDocumentos.map((map) => {
                      return (
                        <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                          {" "}
                          {map.nome}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="col-md-9 mt-3">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Descrição
                </label>
                <textarea
                  {...formik.getFieldProps("descricao")}
                  maxLength={500}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.descricao && formik.errors.descricao,
                    },
                    {
                      "is-valid": formik.touched.descricao && !formik.errors.descricao,
                    }
                  )}
                  id="form-nomeUsuario"
                />
                {formik.touched.descricao && formik.errors.descricao && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.descricao}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mt-6 mt-3">
                <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                  Ser Restrito?:{" "}
                  <FormCheckInput
                    type="checkbox"
                    style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                    checked={formik.values.restrito}
                    onChange={(e) => {
                      if (formik.values.restrito) formik.setValues({ ...formik.values, restrito: false });
                      if (!formik.values.restrito) formik.setValues({ ...formik.values, restrito: true });
                    }}
                  />
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={() => formik.submitForm()} disabled={!formik.isValid} type="button" className="btn btn-orange ms-5">
            Atualizar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadAtoFieldEdit;
