/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Draggable } from "react-beautiful-dnd";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";

import ConfiguracaoFluxoFaseService from "../../../../services/ConfiguracaoFluxoFaseService";

import IOption from "../../../../interfaces/IOption";
import { IAutomacao } from "../../../../interfaces/IAutomacao";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";

import { OrangeContext } from "../../../../contexts/OrangeProvider";
import { notifySuccess } from "../../../../components/Comum/Toast/ToastFormik";
import Swal from "sweetalert2";
import clsx from "clsx";

interface ItemProps {
  item: IAutomacao;
  index: number;
  col: string;
}

const buscarEstilo = (isDragging: boolean, draggableStyle: any) => ({
  padding: 10,
  margin: `0 50px 15px 40px`,
  background: isDragging ? "var(--primary-base)" : "white",
  color: isDragging ? "white" : "black",
  border: `1px solid`,
  fontSize: `12px`,
  borderRadius: `5px`,
  cursor: "pointer",
  ...draggableStyle,
});

const FormACampo: React.FC<ItemProps> = ({ item, index, col }) => {

  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const { fluxoFase } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [options] = useState<IOption[]>([{ label: 'Selecione um tipo de processamento', value: 0 }, { label: 'Após criar um compromisso', value: 1 }, { label: 'Após confirmar um compromisso', value: 2 }]);

  const initialValues = {
    nome: item.nome,
    ordenacao: item.ordenacao,
    automacaoId: item.automacaoId,
    processamento: item.processamento,
  } as IAutomacao;


  const schema = Yup.object().shape({ processamento: Yup.number().min(1).required("Processamento é obrigatório") });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {

      try {

        setExibirModal(false);

        await ConfiguracaoFluxoFaseService.adicionaAutomacao({
          nome: values.nome,
          ordenacao: values.ordenacao,
          automacaoId: values.automacaoId,
          fluxosFasesId: fluxoFase.fluxoFaseId,
          processamento: values.processamento,
        });

        notifySuccess('Automação salva com sucesso.');


      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel cadastrar automação.`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
      }

    },
  });

  const preencherCampos = () => {

    formik.setValues(item);

    setExibirModal(!exibirModal);
  };

  return (
    <>
      <Draggable key={item?.automacaoId} draggableId={`${item?.automacaoId}`} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {item?.nome}
              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                onClick={() => preencherCampos()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {item?.nome} {<FontAwesomeIcon color={formik.values.processamento ? "white" : "red"} className="mx-2" icon={formik.values.processamento ? faCheck : faExclamation} />}
              </div>
            </>
          )
        }
      </Draggable>

      <Modal size="lg" centered={true} show={exibirModal} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange"> {formik.values.nome}</h5>
            <div
              onClick={() => {
                formik.setValues(initialValues);
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>

          <div className="modal-body">
            <form>

              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-clienteTipo" className="form-label text-orange fw-bolder">
                    Informar o processamento:
                  </label>
                  <Select
                    value={{
                      label: options.find((e) => e.value === formik.values.processamento)?.label,
                      value: formik.values.processamento,
                    }}
                    className={clsx({
                      "is-invalid": formik.touched.processamento && formik.errors.processamento,
                      "is-valid": formik.touched.processamento && !formik.errors.processamento,
                    })}
                    onChange={(option: any) => formik.setFieldValue('processamento', option.value)}
                    options={options}
                  />
                  {formik.touched.processamento && formik.errors.processamento && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.processamento}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              onClick={() => formik.submitForm()}
              type="button"
              disabled={!formik.isValid}
              className="btn btn-orange me-5"
            >
              SALVAR
            </button>

            <button
              onClick={() => {
                setExibirModal(!exibirModal);
                formik.setValues(initialValues);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormACampo;
