/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { faEdit, faTimes, faTrash, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import clsx from "clsx";

import { defaultSchema, setValuesXCamposSolucoes } from "../../../utils/SchemasUtil";
import { EModulo, ECamposIDEnum, ETelaEnum } from "../../../enum";
import { objetoPropriedade, routePropriedade, tipoPorModulo } from "../../../utils/Utils";

import { Nullable } from "../../types/Nullable";
import { IPessoa } from "../../../interfaces/IPessoa";
import IParteContraria from "../../../interfaces/IParteContraria";
import ISignatarioPapel from "../../../interfaces/ISignatarioPapel";
import IFormaParticipacao from "../../../interfaces/IFormaParticipacao";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ParteContrariaService from "../../../services/ParteContrariaService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import GridPadrao from "../GridPadrao";
import { notifyFormikDanger, notifySuccess } from "../Toast/ToastFormik";
import AdicionaPessoa from "../Pessoa/AdicionaPessoa";
import RecuperaPessoa from "../Pessoa/RecuperaPessoa";
import ParteContrariaCadastroPage from "./ParteContrariaCadastro";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import GedUploadService from "../../../services/GedUploadService";

interface IParteContrariaFieldProps {
  modulo: number;
  titulo: string;
  setFieldValue: any;
  principalId: number;
  exibirModal: boolean;
  categoriaId?: number,
  toggleModal: () => void;
  partesContrariasList: IParteContraria[];
  setRefreshParteContraria?: (atualiza: boolean) => void;
}

const ParteContrariaField = ({ modulo, categoriaId, toggleModal, exibirModal, setFieldValue, titulo, principalId, partesContrariasList, setRefreshParteContraria }: IParteContrariaFieldProps) => {

  const [tipoDocumento, setTipoDocumento] = useState<string>("");
  const [numeroDocumento, setNumeroDocumento] = useState<string>("");
  const [pessoa, setPessoa] = useState<Nullable<IPessoa>>();
  const { contrato, setParteContraria, parteContraria } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [partesContrariasListPendentes, setParteContrariaListPendente] = useState<IParteContraria[]>([]);
  const [arquivosParaImportar, setArquivosParaImportar] = useState<any[]>([]);


  const [formaParticaocoes, setFormaParticipacaoes] = useState<IFormaParticipacao[]>([]);
  const [papeisSignatarios, setPapeisSignatarios] = useState<ISignatarioPapel[]>([]);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const initialValues: IParteContraria = {
    parteContrariaId: 0,
    controller: '',
    modulo: EModulo.ParteContraria,

    tipoParteContrariaId: 0,
    categoriaId: 0,
    formaParticipacaoId: 0,
    papelSignatarioId: 0,

    nome: "",
    nomeSocial: "",
    documento: "",
    tipoDocumento: "",
    formaParticipacaoNome: "",
    papelSignatarioNome: "",
    principal: false,
    pessoaId: 0,

    dataAdmissao: new Date(),
    dataDemissao: new Date(),
    dataNascimento: new Date(),
    matricula: "",
    email: "",
    estadoCivilId: 0,
    nacionalidade: "",
    telefone: "",
    celular: "",
    rg: "",
    rgOrgao: "",
    pisPasep: "",
    orgaoRegistroId: 0,
    cargoId: 0,
    motivoDesligamentoId: 0,
    statusFolhaId: 0,
    tipoCentroCustoId: 0,
    ultimoSalario: 0,
    moedaId: 0,
    departamentoId: 0,
    codigo: "",
    ctps: "",
    observacoesDesligamento: "",
    observacoesGerais: "",
    tipoContratacao: 0,
    empresaTerceira: "",
    numeroContrato: "",
    regimeContratacao: 0,
    empresaContratante: "",
    observacoesContratacao: "",
    logradouro: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
    estadoId: 0,
    cidadeId: 0,
    arquivosParaImportar: [],
    xCampoValorList: []

  };

  const handlerPessoa = (pessoa: IPessoa) => {
    formik.setFieldValue("pessoaId", pessoa.pessoaId, true);
    formik.setFieldValue("nome", pessoa.nome, true);
    formik.setFieldValue("nomeSocial", pessoa.nomeSocial, true);
    formik.setFieldValue("documento", pessoa.numeroDocumento, true);
    formik.setFieldValue("tipoDocumento", pessoa.tipoDocumento, true);
    setPessoa(pessoa);

  }

  const handlerTipo = (newTipo: string) => setTipoDocumento(newTipo);

  const handlerNumeroDocumento = (newNumeroDocumento: string) => setNumeroDocumento(newNumeroDocumento);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {

      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Parte Contrária",
          text: `Você realmente deseja adicionar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          const pendentes = partesContrariasListPendentes.filter((e) => e.documento != values.documento);

          pendentes.push({
            ...values,
            xCampoValorList: setValuesXCamposSolucoes(configuracoesTela, formik)
          });

          setParteContrariaListPendente(pendentes);

          arquivosParaImportar.push(values.arquivosParaImportar);


          if (principalId && setRefreshParteContraria) {

            let resultado = await ParteContrariaService.adicionaParteContrariaList(pendentes, principalId, routePropriedade(modulo)); //OK 

            await alterarPrincipal(resultado[0][`${objetoPropriedade(modulo)}ParteContrariaId`], values.principal);

            notifySuccess(`Partes Contrárias salvas com sucesso!`);

            setRefreshParteContraria(true);

            setParteContraria(resultado[0]);

          }

          Swal.close();

          formik.setValues({ ...initialValues, tipoParteContrariaId: values.tipoParteContrariaId });

        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar as partes`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      } finally {
        setPessoa(null);
        setSubmitting(false);
      }
    },
  });

  const alterarPrincipal = async (parteContrariaId: any, principal: boolean) => {

    if (principal) {

      partesContrariasList.forEach(async (parte: any) => await ParteContrariaService.alterarCampoPrincipal(ECamposIDEnum.Principal, false, parte[`${objetoPropriedade(modulo)}ParteContrariaId`], routePropriedade(modulo)));

      await ParteContrariaService.alterarPorCampo(ECamposIDEnum.Principal, true, parteContrariaId, routePropriedade(modulo));

    }
  }

  const marcarPrincipal = (pessoaId: number) => {
    // Primeiro, mapeia todas as partes para desmarcar o estado 'principal' de todas,
    // exceto daquela que corresponde ao ID fornecido.
    const atualizadasPartesContrariasList = partesContrariasListPendentes.map((parte) => ({
      ...parte,
      principal: parte.pessoaId === pessoaId ? !parte.principal : false
    }));

    // Atualiza o estado apenas uma vez, em vez de em cada iteração.
    setParteContrariaListPendente(atualizadasPartesContrariasList);
  };


  useEffect(() => {

    // caso não tenha nenhm marcado como principal
    if (partesContrariasListPendentes?.length > 0) {

      if (partesContrariasListPendentes?.filter((e) => e.principal).length == 0 && !principalId) {

        partesContrariasListPendentes.forEach(parte => parte.principal = false);

        partesContrariasListPendentes[0].principal = true;

        setParteContrariaListPendente(partesContrariasListPendentes);

      }
    }

  }, [partesContrariasListPendentes]);

  useEffect(() => {

    if (exibirModal) {

      carregaParteContrariaTipo();

      if (modulo == EModulo.AreaDireito) carregarListaLimitadaFormaParticipacao();

      if (modulo == EModulo.Consultivo) carregarListaLimitadaFormaParticipacao();

      if (modulo === EModulo.Contrato) carregarListaLimitadaPapelSignatario();

    }

    let objeto: any = {};

    eval(`objeto.${tipoPorModulo(modulo)}Id=${categoriaId}`);

    formik.setValues({
      ...initialValues,
      ...objeto
    });

  }, [tipoDocumento, exibirModal]);

  useEffect(() => {

    if (exibirModal && formik.values.tipoParteContrariaId) {
      carregarConfiguracaoTela();
    }

  }, [formik.values.tipoParteContrariaId]);

  useEffect(() => {
    if (formik.values.pessoaId > 0)
      handlerPessoa({
        nome: formik.values.nome,
        nomeSocial: formik.values.nomeSocial,
        tipoDocumento: formik.values.tipoDocumento,
        pessoaId: formik.values.pessoaId,
        numeroDocumento: formik.values.documento,
        dataCadastro: new Date(),
        status: 1,
        usuarioIdCadastro: 0,
        checked: false,
        podeAssinar: false,
      });
  }, [formik.values]);

  useEffect(() => { iniciarUploadArquivos(); }, [contrato?.contratoParteContraria]);

  useEffect(() => {
    if (parteContraria?.parteContrariaId) setTimeout(() => {
      iniciarUploadArquivos();
    }, 2000);
  }, [parteContraria?.parteContrariaId]);


  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};
      eval(`objeto.${tipoPorModulo(modulo)}Id=${categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        modulo: modulo,
        tela: ETelaEnum.TelaCadastroParteContraria,
        tipoParteContrariaId: formik.values.tipoParteContrariaId
      });

      setConfiguracoesTela(resultado.data);

      setCarregandoConfiguracoesTela(false);

    } catch (error) {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const carregarListaLimitadaFormaParticipacao = async () => {
    try {

      let objeto: any = {};

      objeto[`${tipoPorModulo(modulo)}Id`] = categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.FormaParticipacao, modulo, objeto[`${tipoPorModulo(modulo)}Id`], 0);

      setFormaParticipacaoes(resultado.data);

    } catch (error: any) {
      setFormaParticipacaoes([]);
    }
  }

  const carregarListaLimitadaPapelSignatario = async () => {
    try {
      let objeto: any = {};
      objeto[`${tipoPorModulo(modulo)}Id`] = categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.PapelSignatario, modulo, objeto[`${tipoPorModulo(modulo)}Id`], 0);
      setPapeisSignatarios(resultado.data);

    } catch (error: any) {
      setPapeisSignatarios([]);
    }
  }

  const carregaParteContrariaTipo = async () => {
    try {

      let objeto: any = {};

      objeto[`${tipoPorModulo(modulo)}Id`] = categoriaId;

      let resultado = await ParteContrariaService.obterParteContraria({
        nome: tipoDocumento == "NOME" ? "CPF" : tipoDocumento,
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "tipoParteContrariaId",
      });

      formik.setFieldValue('categoriaId', resultado.data.find((e: any) => e).tipoParteContrariaId);

      formik.setFieldValue('tipoParteContrariaId', resultado.data.find((e: any) => e).tipoParteContrariaId);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        timer: 4000,
        icon: "info",
        showConfirmButton: false,
      });
    }
  }

  const colunas: TableColumn<IParteContraria>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IParteContraria) => row?.nomeSocial || row?.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      sortField: "documento",
      selector: (row: IParteContraria) => row?.numeroDocumento || row?.documento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Forma de Participação *",
      sortField: "formaParticipacaoNome",
      selector: (row: IParteContraria) => (modulo === EModulo.AreaDireito || modulo === EModulo.Consultivo) ? (row?.formaParticipacaoNome || row?.formaParticipacao) || "" : modulo === EModulo.Contrato ? (row?.papelSignatarioNome || row?.papelSignatario) || "" : "",
      sortable: true,
      wrap: true,
    },
    ...(!principalId
      ? [
        {
          name: "Remover",
          cell: (row: IParteContraria) => {
            return (
              <div style={{ paddingRight: "10px" }}>
                <FontAwesomeIcon
                  title="Remover?"
                  onClick={async () => {
                    const swalWithBootstrapButtons = Swal.mixin({
                      heightAuto: false,
                      customClass: {
                        confirmButton: "btn btn-danger ms-5",
                        cancelButton: "btn btn-orange ms-5",
                      },
                      buttonsStyling: false,
                    });

                    let result = await swalWithBootstrapButtons.fire({
                      title: "Cancelar",
                      text: `Você realmente deseja cancelar? todos os dados preenchidos serão perdidos`,
                      showCancelButton: true,
                      cancelButtonText: "Não",
                      confirmButtonText: `Sim, desejo remover!`,
                    });

                    if (result.isConfirmed) {
                      let pendentes = partesContrariasList.filter((e) => e.documento != row.documento);
                      setParteContrariaListPendente(pendentes);
                    }
                  }}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="2x"
                  className="mx-1 text-orange"
                  icon={faTrash}
                />
              </div>
            );
          }
        },
      ]
      : []),
    ...(!principalId
      ? [
        {
          name: "Marcar Principal",
          cell: (row: IParteContraria) => {
            return (
              <div>
                <label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    style={{ cursor: 'pointer' }}
                    checked={row.principal}
                    onClick={() => marcarPrincipal(row?.pessoaId)}
                  />
                </label>
              </div>
            );
          }
        },
      ]
      : []),
    ...(!principalId
      ? [
        {
          name: "Alterar",
          cell: (row: IParteContraria) => {
            return (
              <div style={{ paddingRight: "10px" }}>
                <FontAwesomeIcon
                  title="Alterar?"
                  onClick={() => formik.setValues(row)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="2x"
                  className="mx-1 text-orange"
                  icon={faEdit}
                />
              </div>
            );
          }
        },
      ]
      : []),

  ];

  const cancelar = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-danger ms-5",
        cancelButton: "btn btn-orange ms-5",
      },
      buttonsStyling: false,
    });

    let result = await swalWithBootstrapButtons.fire({
      title: "Cancelar",
      text: `Você realmente deseja cancelar? todos os dados preenchidos serão perdidos`,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: `Sim, desejo cancelar!`,
    });

    if (result.isConfirmed) {
      setPessoa(null);
    }
  }

  const fechar = () => {

    if (!principalId)
      setFieldValue("partesContrariasList", partesContrariasListPendentes, true);

    setPessoa(null);

    toggleModal();
  };

  const isValid = () => {

    if (!formik.values.formaParticipacaoId && modulo == EModulo.AreaDireito) return true;

    if (!formik.values.formaParticipacaoId && modulo == EModulo.Consultivo) return true;

    if (!formik.values.papelSignatarioId && modulo == EModulo.Contrato) return true;

    return false;

  }

  const iniciarUploadArquivos = async () => {
    arquivosParaImportar.forEach(async (partes: any) => {
      partes.forEach(async (item: any) => {

        // capa ou cadastro? 
        let parteContrariaId = contrato.contratoParteContraria?.filter(e => e.pessoaId == item.pessoaId)[0]?.parteContrariaId?.toString() || parteContraria?.parteContrariaId.toString();

        Swal.fire({
          heightAuto: false,
          icon: "info",
          text: `Realizando upload...`,
          allowOutsideClick: false,
          showConfirmButton: false
        });

        Swal.showLoading()

        try {

          const formData = new FormData();
          formData.append("origem", "1");
          formData.append("file", item.file);
          formData.append("tipoDocumentoId", item.tipoDocumentoId?.toString() || '');
          formData.append("descricao", item.nome);
          formData.append("restrito", item.serRestriro.toString());
          formData.append("modulo", EModulo.ParteContraria?.toString());
          formData.append("parteContrariaId", parteContrariaId);

          await GedUploadService.adicionaArquivo(formData);

          setFieldValue("quantidadeArquivosSelecionados", 0);

          setArquivosParaImportar([]);

          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: `Upload das partes realizado com sucesso!`,
            showConfirmButton: true,
            timer: 4000
          });

          Swal.close();

        } catch (error: any) {
          console.log(error)
        }
      });
    });
  };

  return (
    <>
      <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              {titulo} <FontAwesomeIcon className="mx-2 text-orange" icon={faUsers} />
            </h5>
            <div onClick={() => fechar()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
        </div>

        <div className="modal-body">

          {!pessoa && (
            <div className="row mt-3">
              <div className="col-md-12">
                <RecuperaPessoa handlerTipo={handlerTipo} handlerNumeroDocumento={handlerNumeroDocumento} handlerPessoa={handlerPessoa} />
              </div>
            </div>
          )}

          {pessoa && pessoa.pessoaId === 0 && (
            <>
              <div className="row mt-3">
                <div className="col-md-12">
                  <AdicionaPessoa
                    tipoDocumento={tipoDocumento}
                    numeroDocumento={numeroDocumento}
                    handlerPessoa={handlerPessoa}
                    modalCancelar={cancelar}
                  />
                </div>
              </div>
            </>
          )}

          {pessoa && pessoa.pessoaId > 0 && (
            <>
              {(modulo === EModulo.AreaDireito || modulo === EModulo.Consultivo) && (
                <div className="row mt-3">
                  <div className="col-md-4">
                    <label htmlFor="form-cliente" className="form-label fw-bolder required text-orange">
                      Forma de Participação:
                    </label>
                    <select
                      name="formaParticipacaoId"
                      value={formik.values.formaParticipacaoId}
                      onChange={(event: any) => {
                        formik.setFieldValue("formaParticipacaoId", parseInt(event.target.value));
                        formik.setFieldValue("formaParticipacaoNome", event.target[event.target.selectedIndex].text);
                        formik.setFieldValue("formaParticipacao", event.target[event.target.selectedIndex].text);
                      }}
                      className={clsx(
                        "form-select",
                        {
                          "is-invalid": formik.touched.formaParticipacaoId && formik.errors.formaParticipacaoId,
                        },
                        {
                          "is-valid": formik.touched.formaParticipacaoId && !formik.errors.formaParticipacaoId,
                        }
                      )}
                      id="form-formaParticipacaoId"
                    >
                      <option value="0">Selecione</option>
                      {formaParticaocoes.map((map: any) => {
                        return (
                          <option key={map.nome} value={map.formaParticipacaoId}>
                            {map.nome}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {(modulo === EModulo.Contrato) && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <label htmlFor="form-cliente" className="form-label fw-bolder required text-orange">
                        Informe a Forma de Participação:
                      </label>
                      <select
                        name="papelSignatarioId"
                        value={formik.values.papelSignatarioId}
                        onChange={(event: any) => {
                          formik.setFieldValue("papelSignatarioId", parseInt(event.target.value));
                          formik.setFieldValue("papelSignatarioNome", event.target[event.target.selectedIndex].text);
                          formik.setFieldValue("papelSignatario", event.target[event.target.selectedIndex].text);
                        }}
                        className={clsx(
                          "form-select",
                          {
                            "is-invalid": formik.touched.papelSignatarioId && formik.errors.papelSignatarioId,
                          },
                          {
                            "is-valid": formik.touched.papelSignatarioId && !formik.errors.papelSignatarioId,
                          }
                        )}
                        id="form-papelSignatarioId"
                      >
                        <option value="0">Selecione</option>
                        {papeisSignatarios.map((map: any) => {
                          return (
                            <option key={map.nome} value={map.papelSignatarioId}>
                              {map.nome}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </>
              )}


              <ParteContrariaCadastroPage
                formik={formik}
                pessoa={pessoa}
                categoriaId={categoriaId || 0}
                configuracoesTela={configuracoesTela}
                carregandoConfiguracoesTela={carregandoConfiguracoesTela}
              />

              <div className="modal-footer" style={{ margin: "0 auto" }}>
                <div className="row mt-3" style={{ margin: "0 auto" }}>
                  <div className="col-md-6">
                    <button
                      disabled={isValid()}
                      onClick={() => {
                        notifyFormikDanger(formik.errors);
                        formik.handleSubmit()
                      }}
                      className="btn btn-orange">
                      Adicionar
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button onClick={() => cancelar()} type="button" className="btn btn-danger float-end">
                      Cancelar
                    </button>
                  </div>
                </div>
              </div></>
          )}

          {!pessoa && (
            <div className="row mt-4">
              <div className="col-md-12 mt-5">
                {partesContrariasListPendentes?.length > 0 && <h3 className="text-orange mb-3">{"Selecionados:"}</h3>}
                <GridPadrao colunas={colunas} tipo="ParteContrarias" itens={partesContrariasListPendentes} />
              </div>

              <div className="modal-footer" style={{ margin: "0 auto" }}>
                <div className="row mt-1" style={{ margin: "0 auto" }}>
                  <div className="col-md-6">
                    <button disabled={!partesContrariasListPendentes?.length} onClick={() => fechar()} type="button" className="btn btn-orange">
                      Confirmar
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button onClick={() => fechar()} type="button" className="btn btn-danger float-end">
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal >
    </>
  );
};

export default ParteContrariaField;
