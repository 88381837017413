/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { faEraser, faFileExcel, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";

import "./style/icons.css";
import { EModulo } from "../../enum";
import GridPadrao from "../../components/Comum/GridPadrao";

import EmpresaService from "../../services/EmpresaService";
import ProcessoService from "../../services/ProcessoService";
import AreaDireitoService from "../../services/AreaDireitoService";
import StatusProcessoService from "../../services/StatusProcessoService";
import ConfiguracaoModeloRelatorioService from "../../services/ConfiguracaoModeloRelatorioService";

import IEmpresa from "../../interfaces/IEmpresa";
import IAreaDireito from "../../interfaces/IAreaDireito";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IStatusProcesso from "../../interfaces/IStatusProcesso";
import IModeloRelatorio from "../../interfaces/IModeloRelatorio";
import IProcesso, { IProcessoPartesContraria } from "../../interfaces/IProcesso";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { FiltroPesquisaProcessoRequest } from "../../interfaces/Requests/Processo/FiltroPesquisaProcessoRequest";

import FiltroModal from "./Modal/FiltroModal";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";
import PadraoRelatorioModal from "./Modal/PadraoRelatorioModal";
import { areaDireitoInicialValues, processoCadastroInicialValues } from "../../contexts/InitialValuesContext";
import GrupoEconomicoService from "../../services/GrupoEconomicoService";
import IGrupoEconomico from "../../interfaces/IGrupoEconomico";
import IAdvogadoInterno from "../../interfaces/IAdvogadoInterno";
import IEscritorio from "../../interfaces/IEscritorio";
import AdvogadoInternosService from "../../services/AdvogadoInternosService";
import EscritorioService from "../../services/EscritorioService";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const filtroInicial: FiltroPesquisaProcessoRequest = {
  processoId: 0,
  numeroProcesso: "",
  pasta: "",
  numeroDesdobramento: '',
  parteContraria: "",
  descricaoObjetoAcao: "",
  observacoes: "",
  resumo: "",
  usuarioId: 0,
  offset: 0,
  limit: 10,
  sort: "processoId",
  totalItems: 0,
  status: 1,
  modeloRelatorioId: 0,
  advogadoInternoId: [],

  valorCausaMaiorIgual: 0,
  valorCausaMenorIgual: 0,

  areaDireitoId: [],
  subAreaDireitoId: [],
  classificacaoId: [],
  ambitoId: [],
  juizId: [],
  tipoContingenciaId: [],
  esferaId: [],
  statusProcessoId: [],
  motivoEncerramentoId: [],
  instanciaId: [],
  faseProcessualId: [],
  tipoAcaoId: [],
  riscoId: [],
  estadoId: [],
  cidadeId: [],
  escritorioId: [],
  empresa: [],
  clienteId: [],
  grupoEconomicoId: [],
  tipoPedidoId: [],
  
  dataDistribuicaoMaiorIgual: "",
  dataDistribuicaoMenorIgual: "",

  dataRecebimentoMaiorIgual: "",
  dataRecebimentoMenorIgual: "",

  prazoFatalMaiorIgual: "",
  prazoFatalMenorIgual: "",

  dataCadastroMaiorIgual: "",
  dataCadastroMenorIgual: "",

  dataUltimaAtualizacaoMaiorIgual: "",
  dataUltimaAtualizacaoMenorIgual: "",

  dataEncerramentoMaiorIgual: "",
  dataEncerramentoMenorIgual: "",

  dataEncerramentoCadastroMaiorIgual: "",
  dataEncerramentoCadastroMenorIgual: "",

  dataBaixaProvisoriaMaiorIgual: "",
  dataBaixaProvisoriaMenorIgual: "",

  dataBaixaProvisoriaCadastroMaiorIgual: "",
  dataBaixaProvisoriaCadastroMenorIgual: "",
};

const ProcessoPadraoPage = () => {
  const [processos, setProcessos] = useState<IProcesso[]>([]);
  const [carregandoProcessos, setCarregandoProcessos] = useState(false);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [modelos, setModelos] = useState<IModeloRelatorio[]>([]);
  const [carregandoModelo, setCarregandoModelo] = useState(false);
  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaProcessoRequest>(filtroInicial);

  const [carregamentoAreaDireito, setCarregamentoAreaDireito] = useState<boolean>(false);
  const [areasDireitoOptions, setAreasDireitoOptions] = useState<any>([]);
  const [areaDireitoSelected, setAreaDireitoSelected] = useState([]);

  const [carregamentoStatusProcesso, setCarregamentoStatusProcesso] = useState<boolean>(false);
  const [StatusProcessoOptions, setStatusProcessoOptions] = useState<any>([]);
  const [StatusProcessoSelected, setStatusProcessoSelected] = useState([]);

  const [carregamentoGrupoEconomico, setCarregamentoGrupoEconomico] = useState<boolean>(false);
  const [grupoEconomicoOptions, setGrupoEconomicoOptions] = useState<any[]>([]);
  const [grupoEconomicoSelected, setGrupoEconomicoSelected] = useState<any[]>([]);

  const [carregamentoEscritorio, setCarregamentoEscritorio] = useState<boolean>(false);
  const [escritorio, setEscritorio] = useState<any>([]);
  const [escritorioSelected, setEscritorioSelected] = useState([]);

  const [carregamentoAdvogados, setCarregamentoAdvogados] = useState<boolean>(false);
  const [advogadosInterno, setAdvogadosInterno] = useState<any>([]);
  const [advogadosSelected, setAdvogadosSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);

  const { setProcesso, setAreaDireito, setProcessoCadastro } = useContext<OrangeInterfaceContext>(OrangeContext);

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);


  useEffect(() => {

    carregarModelos();

    carregarEscritorio();

    carregarAreaDireito();

    carregarStatusProcesso();

    carregarGrupoEconomico();

    carregarAdvogadoInterno();

  }, []);

  useEffect(() => {
    if (filtrosPesquisa?.grupoEconomicoId?.length > 0) {
      carregarEmpresa();
    } else {
      setEmpresa([]);
      setEmpresaSelected([]);
    }
  },
    [filtrosPesquisa?.grupoEconomicoId]);


  const returnOptionsStatusProcesso = (array: any) => {
    array.forEach((item: any) => {
      StatusProcessoOptions.push({
        value: item.statusProcessoId,
        label: item.nome,
      });
    });
    return setStatusProcessoOptions;
  };

  const returnOptionsAreasDireito = (array: any) => {
    array.forEach((item: any) => {
      areasDireitoOptions.push({
        value: item.areaDireitoId,
        label: item.nome,
      });
    });
    return setAreasDireitoOptions;
  };

  const returnOptionsGrupoEconomico = (array: any[]) => {
    let options: any[] = [];
    array.forEach((item: any) => {
      options.push({
        value: item.grupoEconomicoId,
        label: item.nome,
      });
    });
    return options;
  };

  const returnOptionsAdvogadoInterno = (array: any[]) => {
    let options: any[] = [];
    array.forEach((item: any) => {
      options.push({
        value: item.advogadoInternoId,
        label: item.nome,
      });
    });
    return options;
  };

  const returnOptionsEscritorio = (array: any[]) => {
    let options: any[] = [];
    array.forEach((item: any) => {
      options.push({
        value: item.escritorioId,
        label: item.nome,
      });
    });
    return options;
  };

  const returnOptionsEmpresa = (empresas: any) => {
    let empresasParse: any = [];
    empresas.forEach((item: any) => {
      empresasParse.push({
        value: item.empresaId,
        label: item.nomeFantasia,
      });
    });
    return empresasParse;
  };

  const carregarEmpresa = async () => {
    try {

      setCarregamentoEmpresa(true);

      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({
        limit: 100,
        grupoEconomicoId: filtrosPesquisa.grupoEconomicoId,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "empresaId",
      });

      setEmpresa(returnOptionsEmpresa(resultado.data));
      setEmpresaSelected(returnOptionsEmpresa(resultado.data));
      setCarregamentoEmpresa(false);

    } catch (error: any) {
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
  }

  const carregarAreaDireito = async () => {
    try {

      setCarregamentoAreaDireito(true);
      let resultadoAreaDireito: IRecuperaLista<IAreaDireito>;
      resultadoAreaDireito = await AreaDireitoService.obterListaLimitada();
      setAreasDireitoOptions(returnOptionsAreasDireito(resultadoAreaDireito.data));
      setCarregamentoAreaDireito(false);

    } catch (error: any) {
      setCarregamentoAreaDireito(false);
      setAreasDireitoOptions([]);
    }
  }

  const carregarAdvogadoInterno = async () => {
    try {
      setCarregamentoAdvogados(true);
      let resultado: IRecuperaLista<IAdvogadoInterno>;

      resultado = await AdvogadoInternosService.obterAdvogadosPorFiltro({
        filtro: "",
        tipoPesquisa: 1,
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "advogadoInternoId",
      });

      setAdvogadosInterno(returnOptionsAdvogadoInterno(resultado.data));
      setCarregamentoAdvogados(false);
    } catch (error: any) {
      setCarregamentoAdvogados(false);
      setAdvogadosInterno([]);
    }
  }

  const carregarEscritorio = async () => {
    try {
      if (escritorio.length > 0) return;
      setCarregamentoEscritorio(true);
      let resultado: IRecuperaLista<IEscritorio>;
      resultado = await EscritorioService.obterEscritorios({
        clienteId: 0,
        nome: "",
        codigo: "",
        limit: 1000,
        isTelaPesquisaProcesso: true,
        totalItems: 0,
        offset: 0,
        sort: "escritorioId",
      });
      setEscritorio(returnOptionsEscritorio(resultado.data));
      setCarregamentoEscritorio(false);
    } catch (error: any) {
      setCarregamentoEscritorio(false);
      setEscritorio([]);
    }
  }

  const carregarGrupoEconomico = async () => {
    try {
      setCarregamentoGrupoEconomico(true);
      let resultado: IRecuperaLista<IGrupoEconomico>;
      resultado = await GrupoEconomicoService.obterGrupoEconomicos({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setGrupoEconomicoOptions(returnOptionsGrupoEconomico(resultado.data));

    } catch (error: any) {
      setCarregamentoGrupoEconomico(false);
      setGrupoEconomicoOptions([]);
    } finally {
      setCarregamentoGrupoEconomico(false);
    }
  }

  const carregarStatusProcesso = async () => {
    try {
      setCarregamentoStatusProcesso(true);
      let resultado: IRecuperaLista<IStatusProcesso>;
      resultado = await StatusProcessoService.obterStatusProcessos({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusProcessoId",
      });
      setStatusProcessoOptions(returnOptionsStatusProcesso(resultado.data));
      setCarregamentoStatusProcesso(false);
    } catch (error: any) {
      setCarregamentoStatusProcesso(false);
      setStatusProcessoOptions([]);
    }
  }

  const carregarProcessos = async (filtro: FiltroPesquisaProcessoRequest) => {
    try {
      setCarregandoProcessos(true);

      let resultado: IRecuperaLista<IProcesso>;
      resultado = await ProcessoService.obterProcessos(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setProcessos(resultado.data);
      setCarregandoProcessos(false);
      setPesquisar(false);
    } catch (error: any) {
      setProcessos([]);
    } finally {
      setCarregandoProcessos(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  const montarParteContraria = (partes: IProcessoPartesContraria[]) => {
    let partesString = "";
    partes.forEach((parte) => {
      if (parte.principal === "True") {
        partesString += parte.nome + " - " + parte.formaParticipacaoNome;
      }
    });
    return partesString;
  };

  const colunas: TableColumn<IProcesso>[] = [
    {
      cell: (processo: IProcesso) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setProcesso(processo);
                  setAreaDireito({
                    areaDireitoId: processo.areaDireitoId,
                    nome: processo.areaDireitoNome,
                    checked: false,
                    codigo: "",
                    status: "",
                  });
                  navigate("/Processo/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Processo: {processo.numeroProcesso} - {montarParteContraria(processo.processoParteContraria)}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{processo.statusProcessoNome}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {processo.processoId}
                {processo.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(processo.favoritoId)}
                    style={{ margin: "-28px -11px 4px 215px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(processo.processoId)}
                    style={{ margin: "-28px -11px 4px 215px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Área: </b> {processo.areaDireitoNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Comarca: </b>
                {processo.estadoNome} {processo.cidadeNome}-{processo.estadoUF}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Advogado Responsável: </b> {processo.advogadoResponsavelNome}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Ação: </b> {processo.tipoAcaoNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Escritório: </b> {processo.escritorioNome}{" "}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IProcesso>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    if (pesquisar) carregarProcessos(filtrosPesquisa);
  }, [pesquisar]);

  const adicionarFavorito = async (processoId: number) => {
    try {
      await ProcessoService.adicionaFavorido(processoId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Processo adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarProcessos(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar processo como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const removerFavorido = async (favoritoId: number) => {
    try {
      await ProcessoService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Processo removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarProcessos(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  useEffect(() => {
    const IdsSelected: number[] = [];
    areaDireitoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, areaDireitoId: IdsSelected };
    });
  }, [areaDireitoSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusProcessoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, statusProcessoId: IdsSelected };
    });
  }, [StatusProcessoSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    empresaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, empresa: IdsSelected };
    });
  }, [empresaSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    grupoEconomicoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, grupoEconomicoId: IdsSelected };
    });
  }, [grupoEconomicoSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    escritorioSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, escritorioId: IdsSelected };
    });
  }, [escritorioSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    advogadosSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, advogadoInternoId: IdsSelected };
    });
  }, [advogadosSelected]);


  const carregarModelos = async () => {
    try {
      setCarregandoModelo(true);
      let resultado: IRecuperaLista<any>;
      resultado = await ConfiguracaoModeloRelatorioService.obterModeloRelatorio({
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        modulo: EModulo.AreaDireito,
        sort: "modeloRelatorioIdId",
        modeloRelatorioId: "",
      });

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setModelos(resultado.data);
    } catch (error: any) {
      setModelos([]);
    } finally {
      setCarregandoModelo(false);
      setLoadingInicial(false);
    }
  }

  return (
    <>
      <LayoutPageTitle title="Processo">
        <button onClick={() => {
          setAreaDireito(areaDireitoInicialValues);
          setProcessoCadastro(processoCadastroInicialValues);
          navigate("/Processo/Novo");
        }}
          className="btn btn-md btn-orange">
          Novo <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <FiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <PadraoRelatorioModal
        exibirModal={exibirConfirmarModeloModal}
        toggleModal={toggleConfimarModeloModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
      />

      <CollapseFiltro titulo="Filtros"
        content={
          <>
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={(e) => e.preventDefault()}
                  className="row g-3 mb-3 form">

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-processoId" className="form-label fw-bolder text-orange">
                      Identificador:
                    </label>
                    <input
                      value={filtrosPesquisa.processoId}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState: any) => {
                          return { ...oldState, processoId: e.target.value };
                        });
                      }}
                      placeholder="ID"
                      type="number"
                      className={"form-control"}
                      id="form-processoId" />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-numeroProcesso" className="form-label fw-bolder text-orange">
                      Número do Processo:
                    </label>
                    <input
                      value={filtrosPesquisa.numeroProcesso}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState) => {
                          return { ...oldState, numeroProcesso: e.target.value };
                        });
                      }}
                      placeholder="Número do Processo"
                      type="text"
                      className={"form-control"}
                      id="form-numeroProcesso" />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Área Direito:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoAreaDireito}
                      disabled={carregamentoAreaDireito}
                      options={areasDireitoOptions}
                      value={areaDireitoSelected}
                      onChange={(event: any) => setAreaDireitoSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }} />
                  </div>


                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Grupo Econômico:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoGrupoEconomico}
                      disabled={carregamentoGrupoEconomico}
                      options={grupoEconomicoOptions}
                      value={grupoEconomicoSelected}
                      onChange={(event: any) => setGrupoEconomicoSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }} />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Empresa Principal:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoEmpresa}
                      disabled={carregamentoEmpresa}
                      options={empresa}
                      value={empresaSelected}
                      onChange={(event: any) => setEmpresaSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }} />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Advogados Interno:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoAdvogados}
                      disabled={carregamentoAdvogados}
                      options={advogadosInterno}
                      value={advogadosSelected}
                      onChange={(event: any) => setAdvogadosSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }} />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Escritório:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoEscritorio}
                      disabled={carregamentoEscritorio}
                      options={escritorio}
                      value={escritorioSelected}
                      onChange={(event: any) => setEscritorioSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }} />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                      Parte Contrária:
                    </label>
                    <input
                      value={filtrosPesquisa.parteContraria}
                      onChange={(e) => {
                        setFiltrosPesquisa((oldState) => {
                          return { ...oldState, parteContraria: e.target.value };
                        });
                      }}
                      placeholder="Nome ou CPF/CNPJ"
                      type="text"
                      className={"form-control"}
                      id="form-parteContraria" />
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                      Status:
                    </label>
                    <MultiSelect
                      isLoading={carregamentoStatusProcesso}
                      disabled={carregamentoStatusProcesso}
                      options={StatusProcessoOptions}
                      value={StatusProcessoSelected}
                      onChange={(event: any) => setStatusProcessoSelected(event)}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }} />
                  </div>
                </form>

              </div>
            </div>

            <div className="row">
              <div className="col-12"
                style={{ textAlign: 'right' }}>
                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    setFiltrosPesquisa(filtroInicial);
                    setPesquisar(false);
                    setAreaDireitoSelected([]);
                    setStatusProcessoSelected([]);
                    setEmpresaSelected([]);
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current} />
                <button
                  onClick={() => toggleConfimarModeloModal()}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  disabled={carregandoProcessos}>
                  <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                </button>

                <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

              </div>
            </div>
          </>
        }
      />

      <div className="row">
        <div className="col-md-12" style={{ overflow: !carregandoProcessos ? "auto" : "scroll", height: "600px" }}>
          <div ref={componentRef}>
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoProcessos}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Processos"
              itens={processos}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessoPadraoPage;
