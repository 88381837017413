
import { Modal } from "react-bootstrap";
import { faCloudDownloadAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IXFinderDistribuicao from "../../interfaces/IXFinderDistribuicao";
import { currencyLabelTotalFormatterNotRS } from "../../utils/Utils";
import IDocumento from "../../interfaces/IDocumento";
import { TableColumn } from "react-data-table-component";
import GridPadrao from "../../components/GridPadrao";
import { useState } from "react";
import PreviewPDFField from "../../components/Comum/PreviewPDF/PreviewPDF";
import Swal from "sweetalert2";
import DocumentoService from "../../services/DocumentoService";
import moment from "moment";

interface IXFinderDistribuicaoModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    finderDistribuicao?: IXFinderDistribuicao,
    titulo: string
}

const XFinderDistribuicaoModal = ({ toggleModal, exibirModal, finderDistribuicao, }: IXFinderDistribuicaoModalProps) => {
    const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);

    const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

    const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

    const baixarArquivo = async (url: string, nomeArquivo: string) => {

        Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando download...",
            showConfirmButton: false,
        });
        Swal.showLoading();

        try {
            url = url.replace("http:", "https:");
            await DocumentoService.downloadKurier(url)
                .then((response: any) => {
                    const disposition = response.headers["content-disposition"];
                    let fileName = "";
                    if (disposition && disposition.indexOf("attachment") !== -1) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].replace(/['"]/g, "");
                        }
                    }
                    const fileLink = document.createElement("a");
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    fileLink.setAttribute("download", nomeArquivo + ".pdf");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error: any) => {
                    throw new Error(error);
                });

            Swal.hideLoading();

            await Swal.fire({
                heightAuto: false,
                icon: "success",
                text: `Download realizado com sucesso!`,
                showConfirmButton: true,
                timer: 4000,
            });

            Swal.close();
        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Não foi possivel realizar o download.`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        }
    };

    const colunasArquivos: TableColumn<IDocumento>[] = [
        {
            name: "Arquivo",
            sortField: "Nome",
            cell: (row: IDocumento, index: number) => `Arquivo ${index + 1}`,
            sortable: true,
            wrap: true,
        },
        {
            name: "Nome",
            sortField: "Nome",
            cell: (row: IDocumento) => row.nome,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Arquivos',
            cell: (row: IDocumento, index: number) => {
                return (
                    <FontAwesomeIcon
                        title="Baixar Arquivo"
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        icon={faCloudDownloadAlt}
                        size="2x"
                        onClick={() => {
                            if (row.linkDocumento) {
                                if (row.linkDocumento.match(/pdf.*/)) {
                                    setUrlPDFDocumento(row.linkDocumento);
                                    setModalExibirPDFDocumento(true);
                                } else {
                                    baixarArquivo(row.linkDocumento, `arquivo_${index}`);
                                }
                            }
                        }}
                        className="mx-2 text-orange"
                    />
                )
            },
        },
    ];

    return <>
        <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">Detalhes</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <form className="row g-3">
                        <div className="row">
                            <div className="col-md-3 mt-3">

                                <label htmlFor="form" className="form-label fw-bolder text-orange">Forum: </label>
                                <p>{finderDistribuicao?.forum}</p>
                            </div>

                            <div className="col-md-3 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Advogado Autor: </label>
                                <p>{finderDistribuicao?.advogadoAutor}</p>
                            </div>

                            <div className="col-md-3 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Vara: </label>
                                <p>{finderDistribuicao?.vara}</p>
                            </div>

                            <div className="col-md-3 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Status: </label>
                                <p>{finderDistribuicao?.status}</p>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Número do Processo: </label>
                                <p>{finderDistribuicao?.numeroProcesso}</p>
                            </div>

                            <div className="col-md-6 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Valor: </label>
                                <p>{currencyLabelTotalFormatterNotRS(finderDistribuicao?.valor)}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Assunto: </label>
                                <p>{finderDistribuicao?.assuntos}</p>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Réu: </label>
                                <p>{finderDistribuicao?.reu}</p>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Tipo de Ocorrência: </label>
                                <p>{finderDistribuicao?.tipoOcorrencia}</p>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-4 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Data da Distribuição: </label>
                                <p>{moment(finderDistribuicao?.dataDistribuicao).format('DD/MM/YYYY')}</p>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Data de Cadastro: </label>
                                <p>{moment(finderDistribuicao?.dataCadastro).format('DD/MM/YYYY')}</p>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label htmlFor="form" className="form-label fw-bolder text-orange">Data da Audiência: </label>
                                <p>{moment(finderDistribuicao?.dataAudiencia).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mb-10">
                                <GridPadrao
                                    paginationServer={true}
                                    colunas={colunasArquivos}
                                    tipo="XFinderGuias"
                                    itens={finderDistribuicao?.xFinderDistribuicaoDocumentos || []}
                                />
                            </div>
                        </div>

                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                </div>
            </div>
        </Modal>
    </>
}

export default XFinderDistribuicaoModal;