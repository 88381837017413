import api from "./Api";

import IProtocolo from "../interfaces/IProtocolo";
import IRecuperaLista from "../interfaces/IRecuperaLista";

import { EModulo } from "../enum";

import { AdicionaProtocoloRequest } from "../interfaces/Requests/Protocolo/AdicionaProtocoloRequest";
import FiltroPesquisaProtocoloRequest from "../interfaces/Requests/Protocolo/FiltroPesquisaProtocoloRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";


class ProtocoloService {

    public async obterProtocolo(filtros: FiltroPesquisaProtocoloRequest): Promise<IRecuperaLista<IProtocolo>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.status.length > 0) {
            filtros.status.forEach((id) => (query += `&status=${id}`));
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.descricao) {
            query += `&descricao=${filtros.descricao}`;
        }

        if (filtros.tipoProtocoloTribunal) {
            query += `&tipoProtocoloTribunal=${filtros.tipoProtocoloTribunal}`;
        }

        if (filtros.xfinderProtocoloId) {
            query += `&xfinderProtocoloId=${filtros.xfinderProtocoloId}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IProtocolo>>(`Protocolo/RecuperaProtocolo?${query}`);

        return result;
    }

    public async obterTipoProtocoloTribunal(filtros: FiltroPesquisaProtocoloRequest): Promise<IRecuperaLista<any>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.tipoProtocoloId) {
            query += `&tipoProtocoloId=${filtros.tipoProtocoloId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`Protocolo/RecuperaTipoProtocoloTribunal?${query}`);

        return result;
    }

    public async obterTipoArquivo(filtros: FiltroPesquisaProtocoloRequest): Promise<IRecuperaLista<any>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.tipoProtocoloId) {
            query += `&tipoProtocoloId=${filtros.tipoProtocoloId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`Protocolo/RecuperaTipoArquivo?${query}`);

        return result;
    }

    public async adicionaProtocolo(adicionaProtocolo: AdicionaProtocoloRequest): Promise<IProtocolo> {
        let { data: result } = await api.post<IProtocolo>(`Protocolo/AdicionaProtocolo  `, this.montarObjetoRequest(adicionaProtocolo));

        return result;
    }

    public async adicionaFavorido(xfinderProtocoloId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Protocolo, xfinderProtocoloId: xfinderProtocoloId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async obterCapa(xfinderProtocoloId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Protocolo/RecuperaCapaProtocolo?protocoloId=${xfinderProtocoloId}`);
        return result;
    }

    public async alterarStatus(xfinderProtocoloId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IProtocolo>(`Protocolo/AtualizaProtocoloPorId?id=${xfinderProtocoloId}`, data);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Protocolo/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarProtocoloPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Protocolo/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    private montarObjetoRequest = (protocolo: AdicionaProtocoloRequest) => {
        let request: any = {};

        if (protocolo.xCampoValorList?.length > 0) request.xCampoValorList = protocolo.xCampoValorList;

        if (protocolo.tipoProtocoloId) request.tipoProtocoloId = protocolo.tipoProtocoloId;
        if (protocolo.statusprotocoloId) request.statusProtocoloId = protocolo.statusprotocoloId;
        if (protocolo.processoempresaId) request.processoEmpresaId = protocolo.processoempresaId;

        if (protocolo.codigo) request.codigo = protocolo.codigo;
        if (protocolo.descricao) request.descricao = protocolo.descricao;
        if (protocolo.tipoprotocolotribunal) request.tipoProtocoloTribunal = protocolo.tipoprotocolotribunal;


        let documentosId: number[] = [];
        
        protocolo.documentos.forEach(documento => documentosId.push(documento.documentoId));

        if (protocolo?.documentos?.length > 0) request.documentosId = documentosId;

        if (protocolo.processoId) {
            request.modulo = EModulo.AreaDireito;
            request.processoId = protocolo.processoId;
        }

        return request;
    };

}


export default new ProtocoloService();