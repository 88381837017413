import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > strong {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: var(--bs-text-primary);
  }

  > button {
    border: none;
    background: transparent;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px;
  overflow-y: auto;

  > div.card-template {
    padding: 10px;
    border-bottom: 1px solid #ddd;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > div.button-container {
      > button {
        width: 100%;
      }
    }

    > div.card-info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 40%;

      p {
        padding: 0;
        margin: 0;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        color: var(--bs-text-primary);
      }

      span {
        color: var(--bs-text-muted);
        font-size: 12px;
      }
    }
  }
`;