import api from "./Api";
import moment from "moment";

import { ETelaEnum } from "../enum";
import { removerPropriedadesVazia } from "../utils/Utils";

import IOption from "../interfaces/IOption";
import IUsuario from "../interfaces/IUsuario";
import ICompromisso from "../interfaces/ICompromisso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class CompromissoService {

  async obter(filtros: any): Promise<IRecuperaLista<ICompromisso>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=dataCompromisso`;


    if (filtros.compromissoId > 0) {
      query += `&compromissoId=${filtros.compromissoId}`;
    }

    if (filtros.modulo > 0) {
      query += `&modulo=${filtros.modulo}`;
    }

    if (filtros.processoId > 0) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.contratoId > 0) {
      query += `&contratoId=${filtros.contratoId}`;
    }

    if (filtros.marcaId > 0) {
      query += `&marcaId=${filtros.marcaId}`;
    }

    if (filtros.imovelId > 0) {
      query += `&imovelId=${filtros.imovelId}`;
    }

    if (filtros.licencaId > 0) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.empresaId > 0) {
      query += `&empresaId=${filtros.empresaId}`;
    }

    if (filtros.consultaId > 0) {
      query += `&consultaId=${filtros.consultaId}`;
    }

    if (filtros.procuracaoId > 0) {
      query += `&procuracaoId=${filtros.procuracaoId}`;
    }

    if (filtros.empresaId > 0) {
      query += `&empresaId=${filtros.empresaId}`;
    }

    if (filtros.despesaId > 0) {
      query += `&despesaId=${filtros.despesaId}`;
    }

    if (filtros.obrigacaoId > 0) {
      query += `&obrigacaoId=${filtros.obrigacaoId}`;
    }

    if (filtros.desdobramentoId > 0) {
      query += `&desdobramentoId=${filtros.desdobramentoId}`;
    }

    if (filtros.decisaoId > 0) {
      query += `&decisaoId=${filtros.decisaoId}`;
    }

    if (filtros.bloqueioId > 0) {
      query += `&bloqueioId=${filtros.bloqueioId}`;
    }

    if (filtros.garantiaId > 0) {
      query += `&garantiaId=${filtros.garantiaId}`;
    }

    if (filtros.honorarioId > 0) {
      query += `&honorarioId=${filtros.honorarioId}`;
    }

    if (filtros.reembolsoId > 0) {
      query += `&reembolsoId=${filtros.reembolsoId}`;
    }

    if (filtros.propostaAcordoId > 0) {
      query += `&propostaAcordoId=${filtros.propostaAcordoId}`;
    }

    if (filtros.xfinderProtocoloId > 0) {
      query += `&xfinderProtocoloId=${filtros.xfinderProtocoloId}`;
    }

    if (filtros.comunicacaoId > 0) {
      query += `&comunicacaoId=${filtros.comunicacaoId}`;
    }

    if (filtros.audienciaId > 0) {
      query += `&audienciaId=${filtros.audienciaId}`;
    }

    if (filtros.tipoPesquisa == 1 && filtros.filtroPesquisa.length > 0) {
      query += `&CompromissoId=${filtros.filtroPesquisa}`;
    }

    if (filtros.tipoPesquisa == 2 && filtros.filtroPesquisa.length > 0) {
      query += `&ObservacaoCompromisso=${filtros.filtroPesquisa}`;
    }

    if (filtros.status > 0) {
      query += `&status=${filtros.status}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ICompromisso>>(`Compromisso/RecuperaCompromisso?${query}`);

    return result;
  }

  async adicionaCompromisso(adiciona: ICompromisso): Promise<ICompromisso> {
    let request: any = {};

    request.modulo = adiciona.modulo;
    request.tipoCompromissoId = adiciona.tipoCompromissoId;

    request.processoId = adiciona.processoId;
    request.contratoId = adiciona.contratoId;
    request.marcaId = adiciona.marcaId;
    request.imovelId = adiciona.imovelId;
    request.licencaId = adiciona.licencaId;
    request.empresaId = adiciona.empresaId;
    request.consultaId = adiciona.consultaId;
    request.procuracaoId = adiciona.procuracaoId;

    request.despesaId = adiciona.despesaId;
    request.decisaoId = adiciona.decisaoId;
    request.garantiaId = adiciona.garantiaId;
    request.bloqueioId = adiciona.bloqueioId;
    request.obrigacaoId = adiciona.obrigacaoId;
    request.propostaAcordoId = adiciona.propostaAcordoId;
    request.desdobramentoId = adiciona.desdobramentoId;
    request.xfinderProtocoloId = adiciona.xfinderProtocoloId;
    request.comunicacaoId = adiciona.comunicacaoId;
    request.audienciaId = adiciona.audienciaId;
    request.reembolsoId = adiciona.reembolsoId;
    request.honorarioId = adiciona.honorarioId;

    request.dataCompromisso = adiciona.dataCompromisso;
    request.observacaoCompromisso = adiciona.observacaoCompromisso;
    request.observacaoConclusao = adiciona.observacaoConclusao;
    request.desabilitarNotificacaoPorEmail = adiciona.desabilitarNotificacaoPorEmail;
    
    if (adiciona.recorrencia) request.recorrencia = adiciona.recorrencia;
    if (adiciona.grupoId.length > 0) request.grupoId = adiciona.grupoId;
    if (adiciona.usuariosAprovadoresList?.length > 0) {
      request.usuariosAprovadoresList = [];
      adiciona.usuariosAprovadoresList.forEach((item) => {
        request.usuariosAprovadoresList.push({
          usuarioId: item.usuarioId,
          principal: !item.principal ? false : true,
          codigo: item.codigo,
        });
      });
    }
    let { data: result } = await api.post<ICompromisso>(`Compromisso/AdicionaCompromisso`, request);

    return result;
  }

  async cancelarCompromisso(observacao: string, compromissoId: number): Promise<ICompromisso> {
    let { data: result } = await api.put(`Compromisso/CancelaCompromissoPorId?id=${compromissoId}`, observacao);

    return result;
  }

  async concluirCompromisso(adicionar: any, compromissoId: number, objetoId: number): Promise<ICompromisso> {

    let fluxosFasesId: any = [];

    if (adicionar.fluxosFasesId?.length > 0) adicionar.fluxosFasesId.forEach((option: IOption) => fluxosFasesId.push(option.value));

    let { data: result } = await api.put(`Compromisso/ConcluiCompromissoPorId?Id=${compromissoId}&objetoId=${objetoId}`, removerPropriedadesVazia({
      observacao: adicionar.observacao || 'Concluído',
      iniciarFluxoFase: fluxosFasesId
    }));

    return result;


  }

  async removerUsuarioAprovador(compromissoUsuarioId: number): Promise<ICompromisso> {
    let { data: result } = await api.delete(`Compromisso/RemoveUsuarioAprovador?compromissoUsuarioId=${compromissoUsuarioId}`);

    return result;
  }

  async adicionaUsuarioAprovador(usuario: IUsuario, compromissoId: number): Promise<any> {
    let usuarioRequest = {
      usuarioId: usuario.usuarioId,
      principal: usuario.principal,
      codigo: usuario.codigo,
    };

    let { data: result } = await api.post<any>(`Compromisso/AdicionaUsuarioAprovador?compromissoId=${compromissoId}`, usuarioRequest);
    return result.data;
  }

  async atualizarCompromisso(editarCompromisso: ICompromisso): Promise<void> {
    // Montar lista de usuarios
    let usuariosAprovadoresList: any = [];
    editarCompromisso.usuariosAprovadoresList
      .filter((e) => e.checked)
      .forEach((item) => {
        usuariosAprovadoresList.push({
          usuarioId: item.usuarioId,
          principal: item.principal,
          codigo: item.codigo,
        });
      });

    let request = [
      { op: "replace", path: "/tipoCompromissoId", value: editarCompromisso.tipoCompromissoId },
      { op: "replace", path: "/dataConclusao", value: editarCompromisso.dataConclusao },
      {
        op: "replace",
        path: "/dataCompromisso",
        value: moment(editarCompromisso.dataCompromisso).format("YYYY-MM-DD").toString() + " " + editarCompromisso.horario + ":00",
      },
      { op: "replace", path: "/observacaoCompromisso", value: editarCompromisso.observacaoCompromisso },
      { op: "replace", path: "/observacaoConclusao", value: editarCompromisso.observacaoConclusao },
      { op: "replace", path: "/desabilitarNotificacaoPorEmail", value: editarCompromisso.desabilitarNotificacaoPorEmail },
      { op: "replace", path: "/codigo", value: editarCompromisso.codigo },
    ];

    await Promise.all([await api.patch<void>(`Compromisso/AtualizaCompromissoPorId?id=${editarCompromisso.compromissoId}`, request)]);
  }

  async obterTelaCompromisso(compromissoId: number, tela: ETelaEnum): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Compromisso/RecuperaTelaCompromisso?tela=${tela}&compromissoId=${compromissoId}`
    );

    return result;
  }

  async obterHistorico(filtros: any): Promise<IRecuperaLista<any>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=dataCompromisso`;

    if (filtros.compromissoId > 0) {
      query += `&compromissoId=${filtros.compromissoId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(`HistoricoCompromisso/RecuperaHistoricoCompromisso?${query}`);

    return result;
  }
}

export default new CompromissoService();
