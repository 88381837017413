import styled from "styled-components";

export const MenuContainer = styled.div`
  width: 100%;
  height: 70px;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DocumentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MenuActions = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 100%;
`;

export const MenuAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 35px;
  width: 35px;
`;

export const MenuTitleInput = styled.input`
  height: 40px;
  border: none;
  font-size: 22px;
  width: 100%;
  max-width: 500px;
  border-radius: 4px;
  outline: none;
  text-overflow: ellipsis;

  &:focus {
    border: 1.5px solid var(--bs-orange);
  }
`;

export const MenuTitleEdit = styled.button`
  height: 30px;
  width: 30px;
  border: none;
  background-color: var(--bs-success);
  border-radius: 4px;
  transition: opacity 0.7 ease-in;
  color: #fff;

  &:hover {
    opacity: 0.8;
  }
`;

export const MenuTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const MenuDescription = styled.small`
  color: var(--bs-gray);
  margin-left: 3px;
`;

export const ContainerSeparator = styled.div`
  width: 1px;
  height: 50%;
  background-color: #ddd;
`;