import { useQuery } from "react-query";
import EditorService from "../../../../../services/EditorService";
import { toast } from "react-toastify";
import { useTenant } from "../../../hooks/useTenant";
import { EditorQuery } from "../queries";
import { handleApiError } from "../../../../../utils/Utils";
import { EditorVersionData, IEditorPaginationRequestParams } from "../../../../../interfaces/IEditorVersionResponse";

export function useVersionList(params?: IEditorPaginationRequestParams) {
  const { tenant } = useTenant();

  const { data, isLoading, error } = useQuery({
    queryKey: [EditorQuery.get_versions, tenant, params],
    queryFn: () => EditorService.getVersionsV2(tenant, params),
    onError: (error) => {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage, { position: "bottom-right" });
    },
  });

  function validVersions(versions: EditorVersionData[]) {
    return versions.filter(version => version.jsonContent && version.jsonContent.sfdt);
  }

  return {
    versions: data
      ? {
          ...data,
          data: validVersions(data?.data ?? []),
        }
      : undefined,
    isError: error,
    isLoading,
  };
}
