import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import clsx from "clsx";
import DocumentoService from "../../services/DocumentoService";
import { EModulo } from "../../enum";

interface IUploadMinutaModalProps {
    toggleModalUploadMinuta: (carregarDados?: boolean) => void,
    exibirModal: boolean,
    titulo: string,
    tipoProcuracaoId: number,
}

const UploadMinutaModal = ({ toggleModalUploadMinuta, exibirModal, titulo, tipoProcuracaoId }: IUploadMinutaModalProps) => {

    const novoXlearningValidacao = Yup.object().shape({
        arquivo: Yup.mixed().required("Arquivo obritagório.")
            .test(
                'type', 'O arquivo deve ser do tipos docx.', (arquivo) => {
                    console.log(arquivo)
                    const caminho = arquivo as string;
                    return (
                        caminho !== undefined &&
                        (
                            caminho.endsWith('.docx')
                        )
                    );
                }
            ),
    })

    const formularioUploadMinuta = useFormik({
        initialValues: { arquivo: "", codigo: "", descricao: "", modulo: 21, tipoDocumentoId: 55 },
        validationSchema: novoXlearningValidacao,
        onSubmit: async (values, { setSubmitting }) => {
            const campo = document.querySelector('#form-arquivo')

            if (!values.arquivo || !campo) return;

            const arquivos: FileList | null = (campo as HTMLInputElement).files;

            if (!arquivos || arquivos.length === 0) return;

            for (let i = 0; i < arquivos.length; i++) {
                if (!arquivos[i].name?.endsWith('.docx')) {
                    return await Swal.fire({
                        title: 'Arquivo inválido.',
                        text: 'O arquivo deve ser do tipo docx.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }

            const enviados = [];

            Swal.fire({
                heightAuto: false,
                icon: "info",
                text: `Enviando arquivos...`,
                allowOutsideClick: false,
                showConfirmButton: false
            });

            Swal.showLoading()

            for (let i = 0; i < arquivos.length; i++) {
                const dados = {
                    "Modulo": EModulo.Procuracao,
                    "Origem": "10",
                    "File": arquivos[i],
                    "TipoDocumentoId": 55,
                    "Descricao": "Minuta Módulo de Procurações",
                    "Restrito": "false",
                    "Codigo": "",
                    "TipoProcuracaoId": tipoProcuracaoId
                }

                try {
                    const swalWithBootstrapButtons = Swal.mixin({
                        heightAuto: false,
                        customClass: {
                            confirmButton: 'btn btn-orange',
                            cancelButton: 'btn btn-danger ms-5'
                        },
                        buttonsStyling: false
                    });

                    let result = await swalWithBootstrapButtons.fire({
                        title: 'Atualizar Minuta',
                        text: `Você realmente deseja atualizar esta minuta?`,
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: `Confirmar`
                    });

                    if (result.isConfirmed) {

                        enviados.push(await DocumentoService.upload(dados));

                        await Swal.fire({
                            heightAuto: false,
                            icon: 'success',
                            text: `Minuta atualizada com sucesso`,
                            showConfirmButton: false,
                            timer: 3000
                        });

                        toggleModalUploadMinuta();
                    }
                } catch (error: any) {
                    setSubmitting(false);
                    let mensagemErro = 'Ocorreu um erro inesperado';

                    if (error?.response?.data?.Message) {
                        mensagemErro = error.response.data.Message
                    }
                    return Swal.fire({
                        heightAuto: false,
                        icon: 'error',
                        title: `Não foi possível atualizar a minuta`,
                        text: mensagemErro,
                        showConfirmButton: true
                    });
                }
            }

            setSubmitting(false);

            Swal.close();
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModal} backdrop='static' onEscapeKeyDown={(e) => { e.preventDefault(); return false }} onHide={toggleModalUploadMinuta}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <div onClick={() => toggleModalUploadMinuta()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="row mt-2">
                            <div className="col-12 col-lg-6">
                                <label htmlFor="form-arquivo" className="form-label required fw-bolder text-orange">Minuta</label>
                                <input {...formularioUploadMinuta.getFieldProps('arquivo')} placeholder="Selecione a minuta" type="file" multiple={false} className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formularioUploadMinuta.touched.arquivo && formularioUploadMinuta.errors.arquivo,
                                    },
                                    {
                                        'is-valid': formularioUploadMinuta.touched.arquivo && !formularioUploadMinuta.errors.arquivo,
                                    }
                                )} id="form-arquivo" />
                                {formularioUploadMinuta.touched.arquivo && formularioUploadMinuta.errors.arquivo && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioUploadMinuta.errors.arquivo}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formularioUploadMinuta.isSubmitting || !formularioUploadMinuta.isValid} onClick={() => { formularioUploadMinuta.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formularioUploadMinuta.isSubmitting && <> SALVAR </>}
                        {formularioUploadMinuta.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formularioUploadMinuta.isSubmitting} onClick={() => toggleModalUploadMinuta()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default UploadMinutaModal;