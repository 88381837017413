import { Modal } from "react-bootstrap";
import { ConsultModalProps } from "./props";
import { useEffect, useState } from "react";
import {
  Body,
  Header,
  Title,
  Subtitle,
  Layout,
  Actions,
  Main,
  SectionColumn,
  Lawyer,
  Avatar,
  LogContainer,
} from "./styles";
import {
  MdClose,
  MdEast,
  MdHistory,
  MdInfoOutline,
  MdOutlineDescription,
  MdOutlineGroupAdd,
  MdOutlineInfo,
  MdPinEnd,
  MdSwapHoriz,
} from "react-icons/md";
import { TextContent } from "../TextContent";
import { kanbanDateFormat } from "../../util/date";
import { useInternalLawyer } from "../../hooks/useInternalLawyer";
import { nameInitials } from "../../util/string";

const buttons = [
  {
    title: "Mais detalhes",
    icon: <MdOutlineInfo size={20} />,
    id: "more-info",
  },
  {
    title: "Encerrar consulta",
    icon: <MdPinEnd size={20} />,
    id: "end-consult",
  },
  {
    title: "Compromissos",
    icon: <MdOutlineGroupAdd size={20} />,
    id: "commitment",
  },
  { title: "Andamentos", icon: <MdSwapHoriz size={20} />, id: "andamentos" },
  { title: "Timesheet", icon: <MdHistory size={20} />, id: "timesheet" },
  {
    title: "Documentos",
    icon: <MdOutlineDescription size={20} />,
    id: "document",
  },
  { title: "Acessar capa", icon: <MdEast size={20} />, id: "cover" },
];

export function ConsultModal({ selectedCard }: ConsultModalProps) {
  const [show, setShow] = useState(false);
  const [showLogs, setShowLogs] = useState(true);
  const { lawyers } = useInternalLawyer(selectedCard?.consultaId);

  function toggle() {
    setShow((state) => !state);
  }

  useEffect(() => {
    setShow(selectedCard !== null);
  }, [selectedCard]);

  if (!selectedCard) {
    return null;
  }

  return (
    <Modal size="xl" onHide={toggle} show={show}>
      <Header>
        <div>
          <Title>
            #{selectedCard.consultaId} - {selectedCard.tipoConsultaNome}{" "}
            {selectedCard.tituloConsulta
              ? `- ${selectedCard.tituloConsulta}`
              : null}
          </Title>
          <Subtitle>Status: {selectedCard.statusConsultaNome}</Subtitle>
        </div>
        <button type="button" onClick={toggle}>
          <MdClose size={20} />
        </button>
      </Header>
      <Body>
        <Layout>
          <Main>
            <div className="section">
              {lawyers.length > 0 && (
                <SectionColumn>
                  <span>Advogados responsáveis</span>
                  <Lawyer>
                    {lawyers.map((lawyer) => (
                      <Avatar key={lawyer.nome}>
                        {nameInitials(lawyer.nome)}
                      </Avatar>
                    ))}
                  </Lawyer>
                </SectionColumn>
              )}

              {selectedCard.tempoGasto && (
                <SectionColumn>
                  <span>Tempo gasto</span>
                  <strong>{selectedCard.tempoGasto}</strong>
                </SectionColumn>
              )}

              <SectionColumn>
                <span>Solicitado em</span>
                <strong>{kanbanDateFormat(selectedCard.dataCadastro)}</strong>
              </SectionColumn>

              {selectedCard.prazoFatal && (
                <SectionColumn>
                  <span>Prazo</span>
                  <strong>{kanbanDateFormat(selectedCard.prazoFatal)}</strong>
                </SectionColumn>
              )}
            </div>

            <div className="info-section">
              <TextContent
                label="Descrição"
                placeholder="Adicione uma descrição mais detalhada..."
                defaultValue={selectedCard.descricaoConsulta}
              />
              <TextContent
                label="Parecer jurídico"
                placeholder="Adicione o parecer final..."
                defaultValue={selectedCard.parecerJuridico || undefined}
              />
            </div>

            <div className="info-section">
              <LogContainer>
                <header>
                  <div className="label">
                    <MdInfoOutline size={18} />
                    <span>Atividades/Logs</span>
                  </div>

                  <button
                    type="button"
                    onClick={() => setShowLogs((state) => !state)}
                  >
                    {showLogs ? "Ocultar detalhes" : "Ver detalhes"}
                  </button>
                </header>

                {showLogs && (
                  <div className="column">
                    <Avatar>BV</Avatar>
                    <div className="user-info">
                      <p>
                        <b>Bruno Vicente</b>{" "}
                        <span>removeu o Checklist de este cartão</span>
                      </p>

                      <span>
                        {kanbanDateFormat(
                          new Date().toISOString(),
                          "d 'de' MMMM, HH:mm"
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </LogContainer>
            </div>
          </Main>

          <Actions>
            {buttons.map((btn) => (
              <button type="button" key={btn.id}>
                {btn.title}
                {btn.icon}
              </button>
            ))}
          </Actions>
        </Layout>
      </Body>
    </Modal>
  );
}
