import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";

import IOption from "../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaGarantiaRequest } from "../../interfaces/Requests/Garantia/AdicionaGarantiaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import GarantiaService from "../../services/GarantiaService";
import TipoGarantiaService from "../../services/TipoGarantiaService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Solucao/CamposCadastroSolucoes";
import { tipoGarantiaInicialValues } from "../../contexts/InitialValuesContext";
import { tipoPorModulo } from "../../utils/Utils";

const GarantiaCadastroPage = () => {
  const [carregandoTiposGarantia, setCarregandoTiposGarantia] = useState<boolean>(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { garantia, setGarantia, setTipoGarantia, tipoGarantia } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: AdicionaGarantiaRequest = {
    garantiaId: 0,
    principalId: 0,
    tipoGarantiaId: 0,

    modulo: EModulo.Garantia,
    tela: ETelaEnum.TelaCadastroGarantia,
    categoriaId: garantia.categoriaId || 0,

    processoId: garantia.processoId || 0,
    areaDireitoId: garantia.areaDireitoId || 0,

    imovelId: garantia.imovelId || 0,
    tipoImovelId: garantia.tipoImovelId || 0,

    descricao: "",
    matriculagarantia: "",
    numeroprocesso: "",
    datainiciovigencia: null,
    datavencimento: null,
    datafatal: null,
    datagarantia: null,
    formapagamentoId: 0,
    codigobarras: "",
    bancoId: null,
    agencia: "",
    conta: "",
    moedaId: 0,
    valorprincipal: 0,
    indiceId: 0,
    juros: 0,
    numeroapolice: "",
    matriculaimovel: "",
    cartorio: "",
    corretora: "",
    codigo: "",
    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Garantia",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro da garantia..",
            showConfirmButton: false,
          });

          Swal.showLoading();

          setAdicionarLoading(true);

          let resultado = await GarantiaService.adicionaGarantia({
            ...values,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });


          Swal.hideLoading();

          setGarantia({
            ...resultado,
            garantiaId: resultado.garantiaId,
            tipoGarantiaId: resultado.tipoGarantiaId,
            categoriaId: resultado.tipoGarantiaId,
            modulo: garantia.modulo,
            processoId: garantia?.processoId,
            areaDireitoId: garantia.areaDireitoId,
            tipoImovelId: garantia.tipoImovelId,
            imovelId: garantia?.imovelId
          });

          setTipoGarantia(tipoGarantia);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Garantia cadastrada com sucesso`,
            timer: 4000,
          });

        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setTipoGarantia(tipoGarantiaInicialValues);

    carregarTipoGarantia();

    setConfiguracoesTela([]);

    formik.setValues(initialValues);

  }, []);

  useEffect(() => {
    if (tipoGarantia.tipoGarantiaId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoGarantia(tipoGarantiaInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoGarantia.tipoGarantiaId]);

  const carregarTipoGarantia = async () => {
    try {

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(garantia.modulo)}Id=${garantia.categoriaId}`);

      setCarregandoTiposGarantia(true);

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await TipoGarantiaService.obterListaLimitadaTipoGarantias({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        modulo: garantia.modulo,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...objeto
      });
     
      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));
      setOptions(options);

    }
    catch (error: any) {
      setOptions([]);
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setCarregandoTiposGarantia(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(garantia.modulo)}Id=${garantia.categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        modulo: garantia.modulo,
        tela: ETelaEnum.TelaCadastroGarantia,
        tipoGarantiaId: tipoGarantia.tipoGarantiaId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const onChangeTipoGarantia = (value: number, texto: string) => {
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoGarantiaId", value);

    formik.setFieldValue("categoriaId", value);

    setTipoGarantia({
      tipoGarantiaId: value,
      nome: texto,
      opcaoNome: "",
      codigo: "",
      status: "",
      opcaoId: 0,
      categoriaNome: "",
      moduloNome: "",
      categoriaId: 0,
      modulo: EModulo.Default
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />
      <LayoutPageForButton title={`Nova Garantia ${tipoGarantia.tipoGarantiaId > 0 ? " - " + tipoGarantia.nome : ""}`}>
        {garantia.garantiaId > 0 ? (
          <>
            <button onClick={() => navigate("/Garantia/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{garantia.garantiaId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoGarantiaId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposGarantia ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de garantias...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Garantia
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoGarantiaId)?.label,
                value: formik.values.tipoGarantiaId,
              }}
              onChange={(option: any) => onChangeTipoGarantia(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default GarantiaCadastroPage;
