import api from "./Api";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import IModeloRelatorio from "../interfaces/IModeloRelatorio";
import AtualizaModeloRelatorioReq from "../interfaces/Requests/ModeloRelatorios/AtualizarModeloRelatorioReq";
import ITipoModeloRelatorio from "../interfaces/ITipoModeloRelatorio";
import EditarModeloRelatorioReq from "../interfaces/Requests/ModeloRelatorios/EditarModeloRelatorioReq";

class ConfiguracaoRelatorioService {
  async adicionaConfiguracaoRelatorio(
    adiciona: IModeloRelatorio & { extensao?: string }
  ): Promise<IModeloRelatorio> {
    let { data: result } = await api.post<IModeloRelatorio>(
      `Relatorio/AdicionaModeloRelatorio`,
      adiciona
    );

    return result;
  }

  async editaConfiguracaoRelatorio(
    adiciona: IModeloRelatorio
  ): Promise<IModeloRelatorio> {
    let { data: result } = await api.put<IModeloRelatorio>(
      `Relatorio/AtualizaModeloRelatorioCampos`,
      adiciona
    );

    return result;
  }

  async alterarStatus(
    modeloRelatorioId: number,
    status: number
  ): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];
    await api.patch<IModeloRelatorio>(
      `Relatorio/AtualizaModeloRelatorioPorId?id=${modeloRelatorioId}`,
      data
    );
  }

  async obterModeloRelatorio(
    filtros: any
  ): Promise<IRecuperaLista<IModeloRelatorio>> {
    let query = "";

    if (filtros.modulo && filtros.modulo !== 0) {
      query = `${"modulo=" + filtros.modulo}`;
    }

    if (filtros.status) {
      query += `&status=${filtros.status}`;
    }

    if (filtros.offset) {
      query += `&offset=${filtros.offset}`;
    }

    if (filtros.limit) {
      query += `&limit=${filtros.limit}`;
    }

    if (filtros.sort) {
      query += `&sort=${filtros.sort}`;
    }

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.areaDireitoId > 0) {
      query += `&areaDireitoId=${filtros.areaDireitoId}`;
    }

    if (filtros.tipoContratoId > 0) {
      query += `&tipoContratoId=${filtros.tipoContratoId}`;
    }

    if (filtros.tipoMarcaId > 0) {
      query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
    }

    if (filtros.tipoSociedadeId > 0) {
      query += `&tipoSociedadeId=${filtros.tipoSociedadeId}`;
    }
    if (filtros.tipoImovelId > 0) {
      query += `&tipoImovelId=${filtros.tipoImovelId}`;
    }

    if (filtros.tipoLicencaId > 0) {
      query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
    }

    if (filtros.tipoConsultaId > 0) {
      query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
    }

    if (filtros.tipoProcuracaoId > 0) {
      query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
    }

    if (filtros.modeloRelatorioId > 0) {
      query += `&modeloRelatorioId=${filtros.modeloRelatorioId}`;
    }

    query += filtros.tipo ? `&Tipo=${filtros.tipo}` : "";

    let { data: result } = await api.get<IRecuperaLista<IModeloRelatorio>>(
      `Relatorio/RecuperaModeloRelatorio?${query}`
    );

    return result;
  }

  async adicionarCampos(relatorio: AtualizaModeloRelatorioReq) {
    const { data: result } = await api.put<IModeloRelatorio>(
      `Relatorio/AtualizaModeloRelatorioCampos`,
      relatorio
    );

    return result;
  }

  async obterTipos(modulo: number) {
    const { data: result } = await api.get<
      IRecuperaLista<ITipoModeloRelatorio>
    >(`Relatorio/RecuperaTipoRelatorio?modulo=${modulo}`);

    return result;
  }

  async editarModeloRelatorio(id: number, relatorio: EditarModeloRelatorioReq) {
    const body = [];

    for (const iterator of Object.keys(relatorio)) {
      body.push({
        op: "replace",
        path: `/${iterator}`,
        value: relatorio[iterator as keyof typeof relatorio],
      });
    }

    const { data: result } = await api.patch<IRecuperaLista<IModeloRelatorio>>(
      `Relatorio/AtualizaModeloRelatorioPorId?id=${id}`,
      body
    );

    return result;
  }
}

export default new ConfiguracaoRelatorioService();
