import clsx from "clsx";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import PerfilService from "../../services/PerfilService";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

interface IPermissoesProps {
  exibirModal: boolean;
  handleClose: () => void;
}

const PermissoesModal = ({ exibirModal, handleClose }: IPermissoesProps) => {
  const validarFormPermissoes = Yup.object().shape({
    nome: Yup.string()
      .max(50, "Máximo 50 caracteres")
      .required("O Nome é obrigatório."),
    perfilInicial: Yup.boolean().required(
      "O perfil inicial deve ser informado."
    ),
    perfilAdHash: Yup.string().max(50, "Máximo 50 caracteres"),
  });

  const formik = useFormik({
    initialValues: {
      nome: "",
      perfilInicial: false,
      perfilAdHash: "",
    },
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        await PerfilService.adicionaPerfil(formik.values);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Perfil cadastrado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível cadastrar o perfil`,
          text: "Ocorreu um erro inesperado.",
          showConfirmButton: true,
        });
      } finally {
        resetForm();
        handleClose();
      }
    },
  });

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Adicionar Perfil</h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Nome
              </label>
              <input
                {...formik.getFieldProps("nome")}
                placeholder="Nome"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.nome && formik.errors.nome,
                  },
                  {
                    "is-valid": formik.touched.nome && !formik.errors.nome,
                  }
                )}
                id="form-nome"
              />
              {formik.touched.nome && formik.errors.nome && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.nome}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 mb-4">
              <label
                htmlFor="form-perfilAdHash"
                className="form-label fw-bolder"
              >
                Hash AD
              </label>
              <input
                {...formik.getFieldProps("perfilAdHash")}
                placeholder="Hash AD"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.perfilAdHash && formik.errors.perfilAdHash,
                  },
                  {
                    "is-valid":
                      formik.touched.perfilAdHash &&
                      !formik.errors.perfilAdHash,
                  }
                )}
                id="form-perfilAdHash"
              />
              {formik.touched.perfilAdHash && formik.errors.perfilAdHash && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.perfilAdHash}</span>
                  </div>
                </div>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormCheckInput
                style={{ marginRight: "5px" }}
                type="checkbox"
                {...formik.getFieldProps("perfilInicial")}
                placeholder="Perfil Inicial"
                id="form-perfilInicial"
              />

              <div className="col-md-6 mb-3">
                <label
                  htmlFor="form-perfilInicial"
                  className="form-label fw-bolder"
                >
                  Perfil Inicial
                </label>

                {formik.touched.perfilInicial &&
                  formik.errors.perfilInicial && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.perfilInicial}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PermissoesModal;
