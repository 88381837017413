import { Actions } from "./Actions";
import { Container } from "./Container";
import { Description } from "./Description";
import { DocumentInfo } from "./DocumentInfo";
import { Item } from "./Item";
import { Separator } from "./Separator";
import { Title } from "./Title";
import { TitleEdit } from "./TitleEdit";
import { TitleInput } from "./TitleInput";

export const Menu = {
  Item,
  Separator,
  Container,
  DocumentInfo,
  Description,
  Title,
  TitleEdit,
  TitleInput,
  Actions
}