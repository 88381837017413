import { useState } from "react";
import { Popover } from "react-bootstrap";
import { MdLink, MdOutlineImage } from "react-icons/md";
import { ImagePopover } from "../../../Popover/Image";
import { LinkPopover } from "../../../Popover/Link";
import { EmbeedControl } from "./types";

export const embeed: EmbeedControl[] = [
  {
    type: "link",
    component: <MdLink />,
    popover: () => {
      const [show, setShow] = useState(false);

      const onHide = () => {
        setShow(false);
      };

      return (
        <Popover
          show={show}
          id="popover-positioned-bottom"
          title="Inserir link"
        >
          <LinkPopover onHide={onHide} />
        </Popover>
      );
    },
  },
  {
    type: "image",
    component: <MdOutlineImage />,
    popover: () => {
      return (
        <Popover id="popover-positioned-bottom" title="Inserir imagem">
          <ImagePopover />
        </Popover>
      );
    },
  },
];