import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IFeed from "../interfaces/IFeed";
import FiltroPesquisaFeedRequest from "../interfaces/Requests/Feed/FiltroPesquisaFeedRequest";

class FeedService {

    async obterFeed(filtros: FiltroPesquisaFeedRequest): Promise<IRecuperaLista<IFeed>> {
        let query = ``

        if (filtros.sort) query += `&sort=${filtros.sort}`;

        if (filtros.compromissoId) query += `&compromissoId=${filtros.compromissoId}`;

        if (filtros.observacaoCompromisso) query += `&observacaoCompromisso=${filtros.observacaoCompromisso}`;

        if (filtros.status.length > 0) filtros.status.forEach((item) => (query += `&status=${item.value}`));

        if (filtros.usuarioId.length > 0) filtros.usuarioId.forEach((item) => (query += `&usuarioId=${item.value}`));

        if (filtros.grupoUsuarioId.length > 0) filtros.grupoUsuarioId.forEach((item) => (query += `&grupoId=${item.value}`));

        if (filtros.tipoCategoriaCompromissoId.length > 0) filtros.tipoCategoriaCompromissoId.forEach((item) => (query += `&categoria=${item.value}`));

        if (filtros.moduloId.length > 0) filtros.moduloId.forEach((item) => (query += `&modulo=${item.value}`));

        if (filtros.escritorioId.length > 0) filtros.escritorioId.forEach((item) => (query += `&escritorioId=${item.value}`));

        if (filtros.tipoCompromissoId.length > 0) filtros.tipoCompromissoId.forEach((item) => (query += `&tipoCompromissoId=${item.value}`));

        if (filtros.dataCompromissoMaiorIgual && filtros.dataCompromissoMenorIgual) query += `&dataCompromissoMaiorIgual=${filtros.dataCompromissoMaiorIgual}&dataCompromissoMenorIgual=${filtros.dataCompromissoMenorIgual}`;

        if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;

        if (filtros.dataConclusaoMaiorIgual && filtros.dataConclusaoMenorIgual) query += `&dataConclusaoMaiorIgual=${filtros.dataConclusaoMaiorIgual}&dataConclusaoMenorIgual=${filtros.dataConclusaoMenorIgual}`;

        let { data: result } = await api.get<IRecuperaLista<IFeed>>(`Compromisso/FeedList?${query}`);

        return result;
    }

    async obterDetalhe(compromissoId: number): Promise<IRecuperaLista<IFeed>> {

        let { data: result } = await api.get<IRecuperaLista<IFeed>>(`Compromisso/RecuperaFeedDetalhes?compromissoId=${compromissoId}`);

        return result;
    }

}
export default new FeedService();