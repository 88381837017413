import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IAudiencia from "../interfaces/IAudiencia";

import { EModulo, ETipoCampoEnum } from "../enum";

import FiltroPesquisaAudienciaRequest from "../interfaces/Requests/Audiencia/IFiltroPesquisaAudienciaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class AudienciaService {

    public async obterAudiencias(filtros: FiltroPesquisaAudienciaRequest): Promise<IRecuperaLista<IAudiencia>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.status.length > 0) {
            filtros.status.forEach((id) => (query += `&status=${id}`));
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.contratoId) {
            query += `&contratoId=${filtros.contratoId}`;
        }

        if (filtros.audienciaId) {
            query += `&audienciaId=${filtros.audienciaId}`;
        }

        if (filtros.tipoAudienciaId) {
            query += `&tipoAudienciaId=${filtros.tipoAudienciaId}`;
        }

      
        let { data: result } = await api.get<IRecuperaLista<IAudiencia>>(`Audiencia/RecuperaAudiencia?${query}`);

        return result;
    }

    
    async obterListaLimitadaTipoAudiencia(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoAudiencia/RecuperaListaLimitada?${query}`);

        return result;
    }

    public async obterAudienciaPorId(audienciaId: number): Promise<IRecuperaLista<IAudiencia>> {

        let { data: result } = await api.get<IRecuperaLista<IAudiencia>>(`Audiencia/RecuperaAudiencia?audienciaId=${audienciaId}`);

        return result;
    }

    public async adicionaAudiencia(adicionaAudiencia: any): Promise<IAudiencia> {
        let { data: result } = await api.post<IAudiencia>(`Audiencia/AdicionaAudiencia`, this.montarObjetoRequest(adicionaAudiencia));

        return result;
    }

    public async editarAudiencia(editarAudiencia: any) {
        let request = this.montarObjetoArray(editarAudiencia);

        if (request.length > 0) {
            let result = await api.patch(`Audiencia/AtualizaAudienciaPorId?id=${editarAudiencia.audienciaId}`, request);
            return result;
        }
    }

    public async adicionaFavorido(audienciaId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Audiencia, audienciaId: audienciaId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async obterCapaAudiencia(audienciaId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Audiencia/RecuperaCapaAudiencia?audienciaId=${audienciaId}`);
        return result;
    }

    async alterarStatus(audienciaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IAudiencia>(`Audiencia/AtualizaAudienciaPorId?id=${audienciaId}`, data);
    }

    async atualizarAudiencia(editarAudiencia: any): Promise<void> {

        let dataAudiencia = [
            { "op": "replace", "path": "/codigo", "value": editarAudiencia.codigo },
            { "op": "replace", "path": "/descricaoAudiencia", "value": editarAudiencia.descricaoAudiencia }
        ]


        await Promise.all([
            await api.patch<void>(`Audiencia/AtualizaAudienciaPorId?id=${editarAudiencia.audienciaId}`, dataAudiencia)
        ]);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Audiencia/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarAudienciaPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Audiencia/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    private montarObjetoArray = (audiencia: IAudiencia) => {
        let request: any = [];

        // Campos texto
        if (audiencia.codigo) request.push({ op: "replace", path: "/codigo", value: audiencia.codigo });

        if (audiencia.resultadosentencaId) request.push({ op: "replace", path: "/resultadoSentencaId", value: audiencia.resultadosentencaId });
        if (audiencia.resultadosudienciaId) request.push({ op: "replace", path: "/resultadoAudienciaId", value: audiencia.resultadosudienciaId });

        if (audiencia.observacao) request.push({ op: "replace", path: "/observacao", value: audiencia.observacao });
        if (audiencia.dataleitura) request.push({ op: "replace", path: "/dataLeitura", value: audiencia.dataleitura });
        if (audiencia.datasentenca) request.push({ op: "replace", path: "/dataSentenca", value: audiencia.datasentenca });
        if (audiencia.dataaudiencia) request.push({ op: "replace", path: "/dataAudiencia", value: audiencia.dataaudiencia });

        if (audiencia.resultadoAudienciaNome) request.push({ op: "replace", path: "/resultadoAudienciaNome", value: audiencia.resultadoAudienciaNome });
        if (audiencia.resultadoSentencaNome) request.push({ op: "replace", path: "/resultadoSentencaNome", value: audiencia.resultadoSentencaNome });

        return request;
    };

    private montarObjetoRequest = (audiencia: IAudiencia) => {
        let request: any = {};

        if (audiencia.xCampoValorList.length > 0) request.xCampoValorList = audiencia.xCampoValorList;

        if (audiencia.tipoAudienciaId) request.tipoAudienciaId = audiencia.tipoAudienciaId;
        if (audiencia.codigo) request.codigo = audiencia.codigo;

        if (audiencia.resultadosentencaId) request.resultadoSentencaId = audiencia.resultadosentencaId;
        if (audiencia.resultadosudienciaId) request.resultadoAudienciaId = audiencia.resultadosudienciaId;

        if (audiencia.observacao) request.observacao = audiencia.observacao;
        if (audiencia.dataleitura) request.dataLeitura = audiencia.dataleitura;
        if (audiencia.datasentenca) request.dataSentenca = audiencia.datasentenca;
        if (audiencia.dataaudiencia) request.dataAudiencia = audiencia.dataaudiencia;

        if (audiencia.resultadoAudienciaNome) request.resultadoAudienciaNome = audiencia.resultadoAudienciaNome;
        if (audiencia.resultadoSentencaNome) request.resultadoSentencaNome = audiencia.resultadoSentencaNome;

        if (audiencia.processoId) {
            request.modulo = EModulo.AreaDireito;
            request.processoId = audiencia.processoId;
            request.areaDireitoId = audiencia.areaDireitoId;
        }


        return request;
    };

    public concluirCompromisso = async (audiencia: IAudiencia): Promise<any> => {

        let result: any[] = [];

        // Salvar campos normais
        result.push(await this.editarAudiencia(audiencia));

        // Salvar xcampos
        if (audiencia.xCampoValorList?.length > 0) {
            audiencia.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, audienciaId: audiencia.audienciaId, texto: xCampo.texto }));
                        else result.push(await this.alterarAudienciaPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, audienciaId: audiencia.audienciaId, data: xCampo.data }));
                        else result.push(await this.alterarAudienciaPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, audienciaId: audiencia.audienciaId, valor: xCampo.valor }));
                        else result.push(await this.alterarAudienciaPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, audienciaId: audiencia.audienciaId, valor: xCampo.inteiro }));
                        else result.push(await this.alterarAudienciaPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, audienciaId: audiencia.audienciaId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarAudienciaPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        // results
        return result;
    };
}


export default new AudienciaService();