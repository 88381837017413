import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";

import Select from "react-select";
import { useEffect, useState } from "react";
import { optionsType } from "../../../components/Comum/Fornecedor/FornecedorField";
import ModuloService from "../../../services/ModuloService";
import clsx from "clsx";
import DashboardTabelasService from "../../../services/DashboardTabelasService";
import IDashboardTabela from "../../../interfaces/IDashboardTabela";

interface IDashBoardEditModal {
  exibirModal: boolean;
  handleClose: () => void;
  dashboard: IDashboardTabela;
}

const DashBoardEditModal = ({
  exibirModal,
  handleClose,
  dashboard,
}: IDashBoardEditModal) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modulos, setModulos] = useState<optionsType[]>([]);
  const [modulo, setModulo] = useState<optionsType>({
    label: "",
    value: 0,
  });

  const validarFormPermissoes = Yup.object().shape({
    nome: Yup.string().required("O nome é obrigatório."),
    chaveRelatorio: Yup.string().required(
      "A Chave do Relatório é obrigatória."
    ),
    modulo: Yup.number().min(1, "O módulo é obrigatório."),
    codigo: Yup.string().max(50, "O máximo é de 50 caracteres."),
  });

  const formik = useFormik({
    initialValues: {
      nome: dashboard.nome,
      modulo: dashboard.modulo,
      chaveRelatorio: dashboard.chaveRelatorio,
      codigo: dashboard.codigo,
    },
    enableReinitialize: true,
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        await DashboardTabelasService.atualizarDashboard(
          dashboard.dashboardId.toString(),
          values
        );

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Dashboard atualizado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível atualizar o Dashboard`,
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          showConfirmButton: true,
        });
      } finally {
        resetForm();
        setModulo({
          label: "",
          value: 0,
        });
        handleClose();
      }
    },
  });

  const carregarModulos = async () => {
    try {
      setLoading(true);
      const response = await ModuloService.obterModulos({
        limit: 10,
        nome: "",
        offset: 0,
        sort: "moduloId",
        status: 0,
        totalItems: 0,
        codigo: "0",
      });

      const mappedModulos = response.data.map((modulo) => {
        return {
          label: modulo.nome,
          value: modulo.moduloId,
        };
      });

      setModulos(mappedModulos);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos módulos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarModuloSelecionado = () => {
    const [moduloSelecionado] = modulos.filter(
      (modulo) => modulo.value === formik.values.modulo
    );

    setModulo(moduloSelecionado);
  };

  useEffect(() => {
    carregarModulos();
  }, [dashboard.dashboardId]);

  useEffect(() => {
    carregarModuloSelecionado();
  }, [modulos, exibirModal]);

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Editar Dashboard</h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange mt-3"
              >
                Nome
              </label>
              <input
                {...formik.getFieldProps("nome")}
                placeholder="Nome"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.nome && formik.errors.nome,
                  },
                  {
                    "is-valid": formik.touched.nome && !formik.errors.nome,
                  }
                )}
                id="form-nome"
              />
              {formik.touched.nome && formik.errors.nome && (
                <div className="fv-plugins-message-container mt-1">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.nome}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Chave do Relatório
              </label>
              <input
                {...formik.getFieldProps("chaveRelatorio")}
                placeholder="Chave do Relatório"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.chaveRelatorio &&
                      formik.errors.chaveRelatorio,
                  },
                  {
                    "is-valid":
                      formik.touched.chaveRelatorio &&
                      !formik.errors.chaveRelatorio,
                  }
                )}
                id="form-chaveRelatorio"
              />
              {formik.touched.chaveRelatorio &&
                formik.errors.chaveRelatorio && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.chaveRelatorio}</span>
                    </div>
                  </div>
                )}
            </div>

            <div className="col-md-6">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange mt-3"
              >
                Código
              </label>
              <input
                {...formik.getFieldProps("codigo")}
                placeholder="Código"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.codigo && formik.errors.codigo,
                  },
                  {
                    "is-valid": formik.touched.codigo && !formik.errors.codigo,
                  }
                )}
                id="form-codigo"
              />
              {formik.touched.codigo && formik.errors.codigo && (
                <div className="fv-plugins-message-container mt-1">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.codigo}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 mt-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Módulo:
              </label>
              <Select
                isLoading={loading}
                placeholder="Selecione um módulo"
                id="form-select"
                options={modulos}
                isSearchable
                value={modulo}
                onChange={(select) => {
                  if (select) {
                    setModulo(select);
                    formik.setFieldTouched("modulo", true);
                    formik.setFieldValue("modulo", select.value);
                  }
                }}
              />
              {formik.touched.modulo && formik.errors.modulo && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.modulo}</span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              setModulo({
                label: "",
                value: 0,
              });
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DashBoardEditModal;
