import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ISignatarioPapel from "../interfaces/ISignatarioPapel";
import IPapelSignatario from "../interfaces/IPapelSignatario";
import { EditarPapelSignatarioRequest } from "../interfaces/Requests/PapelSignatario/EditarPapelSignatarioRequest";
import { AdicionaPapelSignatarioRequest } from "../interfaces/Requests/PapelSignatario/AdicionaPapelSignatarioRequest";

class PapelSignatarioService {

    async obterPapelSignatarios(filtros: any): Promise<IRecuperaLista<ISignatarioPapel>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ISignatarioPapel>>(`PapelSignatario/RecuperaPapelSignatario?${query}`);

        return result;
    }

    async adicionaPapelSignatario(adicionaPapelSignatario: AdicionaPapelSignatarioRequest): Promise<IPapelSignatario> {
        let { data: result } = await api.post<IPapelSignatario>(`PapelSignatario/AdicionaPapelSignatario`, adicionaPapelSignatario);

        return result;
    }

    async alterarStatus(papelSignatarioId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPapelSignatario>(`PapelSignatario/AtualizaPapelSignatarioPorId?id=${papelSignatarioId}`, data);
    }

    async atualizarPapelSignatario(editarPapelSignatario: EditarPapelSignatarioRequest): Promise<void> {

        let dataPapelSignatario = [
            { "op": "replace", "path": "/nome", "value": editarPapelSignatario.nome },
            { "op": "replace", "path": "/codigo", "value": editarPapelSignatario.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`PapelSignatario/AtualizaPapelSignatarioPorId?id=${editarPapelSignatario.papelSignatarioId}`, dataPapelSignatario)
        ]);
    }

}


export default new PapelSignatarioService();