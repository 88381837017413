import { faEraser, faPen, faPlus, faSearch, faFileExcel, } from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableColumn } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import GridPadrao from '../../components/Comum/GridPadrao';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import LayoutPageTitle from '../../layout/LayoutPageTitle';
import Swal from "sweetalert2";
import FiltroPesquisaXCampoRequest from '../../interfaces/Requests/XCampo/FiltroPesquisaXCampoRequest';
import IXCampo from '../../interfaces/IXCampo';
import XCampoService from '../../services/XCampoService';
import IModulo from '../../interfaces/IModulo';
import ModuloService from '../../services/ModuloService';
import { MultiSelect } from 'react-multi-select-component';
import XCampoModal from './XCampoModal';
import { EModulo } from '../../enum';

const XCampoPage = () => {
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoXCampos, setCarregandoXCampos] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);
    const [xCampos, setXCampos] = useState<IXCampo[]>([]);
    const [xCampo, setXCampo] = useState<IXCampo>();

    const [relatorioId, setRelatorioId] = useState<number | null>(null);
    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaXCampoRequest>({
        isXCampo: true,
        status: 1,
        label: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        modulo: [],
        tipo: "",
        totalItems: 0,
        offset: 0,
        sort: "-dataCadastro",
        limit: 10
    });
    const [modulo, setModulo] = useState<any>([]);
    const [moduloSelecionados, setModuloSelecionados] = useState([]);
    const [carregandoModulo, setCarregandoModulo] = useState<boolean>(false);

    const toggleModal = (carregarDados?: boolean): void => {
        if (carregarDados) {
            carregarXCampos(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);
    }

    const carregarXCampos = async (filtro: FiltroPesquisaXCampoRequest): Promise<void> => {
        try {
            setCarregandoXCampos(true);
            let resultado: IRecuperaLista<IXCampo>;

            resultado = await XCampoService.obterXCampo(filtro);

            if (resultado.data === null) {
                setXCampos([]);
                setRelatorioId(null);
            }
            else {
                setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
                setRelatorioId(resultado.relatorioId || null);
                setXCampos(resultado.data);
            }
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros.',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setXCampos([]);
            setRelatorioId(null);
        }
        finally {
            setCarregandoXCampos(false);
            setLoadingInicial(false);
        }
    }

    const limparFiltros = () => {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                status: 1,
                label: "",
                dataCadastroMaiorIgual: "",
                dataCadastroMenorIgual: "",
                modulo: [],
                tipo: "",
                totalItems: 0,
                offset: 0,
                sort: "-dataCadastro",
                limit: 10
            }
        });
        setModuloSelecionados([]);
    }

    const baixarRelatorio = async (): Promise<void> => {
        if (!xCampos) return;

        if (relatorioId === 0 || relatorioId === null) {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `A pesquisa não retornou nenhum resultado.`,
                showConfirmButton: true,
                timer: 4000
            });
            return;
        }


        Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Baixando arquivos...',
            showConfirmButton: false,
        });
        Swal.showLoading();
        try {
            const arquivo = await XCampoService.gerarExcel(relatorioId || 0);
            if (arquivo) {
                const url = window.URL.createObjectURL(arquivo);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${new Date().toLocaleString('BR')} - XCampos.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            Swal.hideLoading();
            Swal.update({
                title: 'Baixado com sucesso',
                text: '',
                icon: 'success',
                showConfirmButton: true
            });

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível baixar arquivos',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false
            });
        }
    }

    const carregarModulo = async () => {
        try {
            if (modulo.length > 0) return;
            setCarregandoModulo(true);
            let resultado: IRecuperaLista<IModulo>;
            resultado = await ModuloService.obterModulos({
                nome: "",
                codigo: "",
                status: 1,
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: "nome",
            });
            setModulo(returnOptions(resultado.data, "moduloId", "modulo"));
            setCarregandoModulo(false);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: "Não foi possível obter registros",
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                timer: 4000,
                icon: "error",
                showConfirmButton: false,
            });
            setCarregandoModulo(false);
            setModulo([]);
        }
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarXCampos(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

    useEffect(() => {
        carregarModulo();
    }, []);

    const returnOptions = (array: any, propId: string, arrayName: string) => {
        array.forEach((item: any) => {
            eval(`${arrayName}`).push({
                value: eval(`item.${propId}`),
                label: item.nome,
            });
        });
        return eval(`${arrayName}`);
    };

    const setIdsSelect = (entidade: string, optionsSelected: any) => {
        const IdsSelected: number[] = [];

        optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

        switch (entidade) {
            case "moduloId":
                setFiltrosPesquisa({ ...filtrosPesquisa, modulo: IdsSelected });
                break;
        }
    };

    const colunas: TableColumn<IXCampo>[] = [
        {
            name: 'Id',
            sortField: 'camposId',
            selector: (row: IXCampo) => row.camposId ? row.camposId : 0,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Módulo',
            sortField: 'modulo',
            selector: (row: IXCampo) => row.moduloNome ?? "",
            sortable: true,
            wrap: true
        },
        {
            name: 'Label',
            sortField: 'label',
            selector: (row: IXCampo) => row.label ?? "",
            sortable: true,
            wrap: true
        },
        {
            name: 'Tipo',
            sortField: 'tipo',
            selector: (row: IXCampo) => row.tipo ?? "",
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (xCampo: IXCampo) => {
                return (
                    <div>
                        <FontAwesomeIcon title="Editar" onClick={() => {
                            setXCampo(xCampo);
                            setExibirModal(true);
                        }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ]

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<IXCampo>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };



    return (<>
        <LayoutPageTitle title="XCampos">
            <button onClick={() => {
                setXCampo({
                    label: "",
                    codigo: "",
                    modulo: EModulo.Default,
                    tipo: "",
                    exibirTelaPesquisa: false,
                    salvarXCampoFechamento: false,
                    xCampoTabelaPropriedades: []
                });
                setExibirModal(true)
            }} className="btn btn-md btn-orange">
                Novo <FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        <XCampoModal
            exibirModal={exibirModal}
            titulo={`XCampo`}
            toggleModal={toggleModal}
            xCampo={xCampo}
        />

        <div className="row mt-2">
            <form onSubmit={(e) => {
                e.preventDefault();
                carregarXCampos(filtrosPesquisa);
            }} className="row g-3 mb-3 form-buscar-xCampo">
                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
                    <DateRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        maxDate={new Date()}
                        value={
                            (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                                [
                                    filtrosPesquisa.dataCadastroMaiorIgual,
                                    filtrosPesquisa.dataCadastroMenorIgual,
                                ] : ''
                        }
                        onChange={(datas: Date[]) => {
                            if (!datas) {
                                setFiltrosPesquisa(oldValue => {
                                    return {
                                        ...oldValue,
                                        dataCadastroMaiorIgual: '',
                                        dataCadastroMenorIgual: '',
                                    }
                                });
                                return
                            }

                            let data_inicial = datas[0];
                            let data_final = datas[1];
                            if (data_final) {
                                data_final.setHours(0, 0, 0, 0);
                                data_final = new Date(data_final.getTime() - 1)
                            }
                            setFiltrosPesquisa(oldValue => {

                                return {
                                    ...oldValue,
                                    dataCadastroMaiorIgual: (data_inicial) ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                                    dataCadastroMenorIgual: (data_final) ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                                }
                            })
                        }}
                    />
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-tipo" className="form-label fw-bolder text-orange">Tipo:</label>
                    <select value={filtrosPesquisa.tipo} onChange={(e) => {
                        setFiltrosPesquisa(oldState => { return { ...oldState, tipo: (e.target.value) } });
                    }} placeholder="Nome" className={'form-select'} id="form-select" >
                        <option value="0" label="Todos"> Todos </option>
                        <option value="AreaTexto" label="Área de Texto"> Área de Texto </option>
                        <option value="Boolean" label="Boolean"> Boolean </option>
                        <option value="Data" label="Data"> Data </option>
                        <option value="Inteiro" label="Inteiro"> Inteiro </option>
                        <option value="Lista" label="Lista"> Lista </option>
                        <option value="ListaComLink" label="Lista com Link"> ListaComLink </option>
                        <option value="ListaMulti" label="ListaMulti"> Lista </option>
                        <option value="Texto" label="Texto"> Texto </option>
                        <option value="Valor" label="Valor"> Valor </option>
                        <option value="Percentual" label="Percentual"> Percentual </option>
                    </select>
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-label" className="form-label fw-bolder text-orange">Label:</label>
                    <input value={filtrosPesquisa.label} onChange={(e) => {
                        setFiltrosPesquisa(oldState => { return { ...oldState, label: e.target.value } });
                    }} placeholder="Label" className={'form-control'} id="form-label" />
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-modeulo" className="form-label fw-bolder text-orange">
                        Módulo:
                    </label>
                    <MultiSelect
                        isLoading={carregandoModulo}
                        disabled={carregandoModulo}
                        options={modulo}
                        value={moduloSelecionados}
                        onChange={(event: any) => {
                            setModuloSelecionados(event);
                            setIdsSelect("moduloId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                            selectSomeItems: "Selecione...",
                            allItemsAreSelected: "Todos selecionados",
                            selectAll: "Selecione todos",
                            search: "Pesquise",
                            selectAllFiltered: "Selecione todos (filtrados)",
                        }}
                    />
                </div>

                <div className="col-12 col-lg-4 col-xl-4">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Status:</label>
                    <select value={filtrosPesquisa.status} onChange={(e) => {
                        setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                    }} placeholder="Nome" className={'form-select'} id="form-select" >
                        <option value="0" label="Todos"> Todos </option>
                        <option value="1" label="Ativo"> Ativo </option>
                        <option value="-1" label="Inativo"> Inativo </option>
                    </select>
                </div>

                <div className="col-12 "
                    style={{ textAlign: 'right' }}>
                    <button
                        type="submit"
                        className="btn btn-orange search-buttom-margin "
                        title="Buscar"
                    >
                        <FontAwesomeIcon color='white' className='' icon={faSearch} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            limparFiltros();
                        }}
                        className="btn btn-orange search-buttom-margin ms-2"
                        title="Limpar Filtro"
                    >
                        <FontAwesomeIcon color='white' className='' icon={faEraser} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            baixarRelatorio();
                        }}
                        className="btn btn-orange search-buttom-margin ms-2"
                        title="Baixar Relatório"
                        disabled={relatorioId === null || carregandoXCampos}
                    >
                        <FontAwesomeIcon color='white' className='' icon={faFileExcel} />
                    </button>
                </div>
            </form>
            <div className="col-md-12 mb-10">
                <GridPadrao
                    onSort={handleSort}
                    progressPending={carregandoXCampos}
                    limit={filtrosPesquisa.limit}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer={true}
                    paginationTotalRows={filtrosPesquisa.totalItems}
                    colunas={colunas}
                    tipo='XCampos'
                    itens={xCampos} />
            </div>
        </div >
    </>)
}

export default XCampoPage;



