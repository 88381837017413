import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { Form } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faMinus, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from "../../../enum";

import ComunicacaoService from "../../../services/ComunicacaoService";

import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaComunicacaoRequest from "../../../interfaces/Requests/Comunicacao/FiltroPesquisaComunicacaoRequest";
import { IRecuperaSeparadorTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import GridPadrao from "../GridPadrao";
import ComunicacaoField from "./ComunicacaoField";
import ComunicacaoFieldEdit from "./ComunicacaoEdit";
import IComunicacao from "../../../interfaces/IComunicacao";
import CollapseDefault from "../../Collapse/CollapseDefault";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { comunicacaoInitialValues } from "../../../contexts/InitialValuesContext";

interface IComunicacaoListFieldPropos {
  modulo: EModulo;
  configuracaoTela: IRecuperaSeparadorTelaResponse;
}

const ComunicacaoListField = ({ modulo, configuracaoTela }: IComunicacaoListFieldPropos) => {
  const { processo, contrato, setComunicacao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirListaComunicacao, setExibirListaComunicacao] = useState<boolean>(false);
  const [exibirModalCapaComunicacao, setExibirModalCapaComunicacao] = useState<boolean>(false);
  const [exibirModalCadastroComunicacao, setExibirModalCadastroComunicacao] = useState<boolean>(false);

  const [atualizaTabelaComunicacao, setAtualizaTabelaComunicacao] = useState<boolean>(false);
  const [comunicacoes, setComunicacoes] = useState<IComunicacao[]>([]);
  const [carregandoComunicacao, setCarregandoComunicacao] = useState(false);

  const [filtrosComunicacao, setFiltrosComunicacao] = useState<FiltroPesquisaComunicacaoRequest>({
    processoId: processo.processoId,
    contratoId: contrato.contratoId,
    comunicacaoId: 0,
    modulo: modulo,
    tipoPesquisa: 0,
    filtro: "",

    ultimosQuantidade: null,
    descricao: "",
    tipoComunicacaoId: [],
    dataComunicacaoMaiorIgual: null,
    dataComunicacaoMenorIgual: null,
    codigo: "",
    nome: "",

    limit: 10,
    offset: 0,
    status: 1,
    totalItems: 0,
    sort: "-dataComunicacao",
  });

  const toggleCapaComunicacao = () => setExibirModalCapaComunicacao(!exibirModalCapaComunicacao);

  const toggleCadastroComunicacao = () => setExibirModalCadastroComunicacao(!exibirModalCadastroComunicacao);

  useEffect(() => {

    if (atualizaTabelaComunicacao) {
      setTimeout(() => carregarComunicacao(filtrosComunicacao), 3000);
    }

    setAtualizaTabelaComunicacao(false);

  }, [atualizaTabelaComunicacao]);

  useEffect(() => {

    setFiltrosComunicacao((oldState) => {
      return { ...oldState, ultimosQuantidade: 0 };
    });

    if (exibirListaComunicacao) carregarComunicacao(filtrosComunicacao);

  }, [filtrosComunicacao.offset, filtrosComunicacao.limit, filtrosComunicacao.sort]);

  useEffect(() => {
    if (exibirListaComunicacao) {
      carregarComunicacao(filtrosComunicacao);
    }
  }, [exibirListaComunicacao]);

  const handlePerRowsChangeComunicacao = async (currentRowsPerPage: number) => setFiltrosComunicacao((oldState) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChangeComunicacao = (page: number) => setFiltrosComunicacao((oldState) => { return { ...oldState, offset: (page - 1) * filtrosComunicacao.limit }; });

  const handleSortComunicacao = async (column: TableColumn<IComunicacao>, sortDirection: string) => setFiltrosComunicacao((oldState) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  const colunas: TableColumn<IComunicacao>[] = [
    {
      cell: (row: IComunicacao) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                className="form-label fw-bolder text-orange"
                style={{
                  paddingTop: "30px",
                }}
              >
                #{row.comunicacaoId} - {row.tipoComunicacaoNome} - {moment(row.dataCadastro).format("DD/MM/yyyy")}
              </p>
              <p><b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Descrição: </b>{row.descricao}</p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Data da Comunicação: </b> {moment(row.dataComunicacao).format("DD/MM/yyyy")}
                <br />
              </p>
              <p>
                <>
                  <FontAwesomeIcon
                    title="Editar"
                    onClick={() => abrirCapa(row)}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    size="2x"
                    className="mx-2 text-orange"
                    icon={faSearch}
                  />
                </>
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const abrirCapa = (row: IComunicacao) => {

    setComunicacao({
      ...row,
      comunicacaoId: row.comunicacaoId,
      tipoComunicacaoId: row.tipoComunicacaoId,

      modulo: modulo,
      processoId: processo.processoId,
      areaDireitoId: processo.areaDireitoId,

      contratoId: contrato.contratoId,
      tipoContratoId: contrato.tipoContratoId,
    });

    setExibirModalCapaComunicacao(true);
  };

  const carregarComunicacao = async (filtro: FiltroPesquisaComunicacaoRequest) => {
    try {

      setCarregandoComunicacao(true);

      let resultado: IRecuperaLista<IComunicacao>;

      resultado = await ComunicacaoService.obterComunicacoes(filtro);

      setFiltrosComunicacao((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setComunicacoes(resultado.data);

      setCarregandoComunicacao(false);

    } catch (error: any) {
      setComunicacoes([]);
      setCarregandoComunicacao(false);
    }
  };

  return (
    <>
      <ComunicacaoField
        exibirModal={exibirModalCadastroComunicacao}
        toggleModal={toggleCadastroComunicacao}
        setAtualizarTabela={setAtualizaTabelaComunicacao}
      />

      <ComunicacaoFieldEdit
        exibirModal={exibirModalCapaComunicacao}
        toggleModal={toggleCapaComunicacao}
      />

      <div className="row mt-3">
        <CollapseDefault
          modulo={EModulo.Comunicacao}
          carregando={carregandoComunicacao}
          exibir={exibirListaComunicacao}
          setExibir={setExibirListaComunicacao}
          help={configuracaoTela.help}
          titulo={configuracaoTela.label}
          content={<>
            {exibirListaComunicacao && (
              <>
                <div className="col-md-12">
                  <button
                    onClick={() => {

                      setComunicacao({
                        ...comunicacaoInitialValues,
                        modulo: modulo,
                        processoId: processo.processoId,
                        areaDireitoId: processo.areaDireitoId,
                      });

                      setExibirModalCadastroComunicacao(true);

                    }}
                    className="btn btn-orange"
                  >
                    {exibirModalCadastroComunicacao && <FontAwesomeIcon color={"white"} className="mx-2" icon={faMinus} />}
                    {!exibirModalCadastroComunicacao && <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />}
                  </button>
                </div>
                <div className="col-md-auto mt-3">
                  <input
                    value={filtrosComunicacao.filtro}
                    onChange={(e) => {
                      setFiltrosComunicacao((oldState) => {
                        return { ...oldState, filtro: e.target.value };
                      });
                    }}
                    placeholder="Comunicacao"
                    type="text"
                    className={clsx("form-control")}
                  />
                </div>
                <div className="col-md-auto mt-3">
                  <input
                    value={filtrosComunicacao.ultimosQuantidade || ""}
                    type="number"
                    maxLength={3}
                    onChange={(e) => {
                      setFiltrosComunicacao((oldState) => {
                        return { ...oldState, ultimosQuantidade: parseInt(e.target.value, 10) };
                      });
                    }}
                    placeholder="+ Quantidade"
                    className={clsx("form-control")}
                  />
                </div>
                <div className="col-md-auto mt-2">
                  <button onClick={() => carregarComunicacao(filtrosComunicacao)} className="btn btn-orange">
                    {<> Pesquisar </>}
                  </button>
                </div>
                <div className="col-md-auto mt-2">{<> Buscar por: </>}</div>
                <div className="col-md-auto mt-2">
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltrosComunicacao({ ...filtrosComunicacao, tipoPesquisa: 1 })}
                      label="Nome"
                      name="group1"
                      type="radio"
                    />
                    <Form.Check
                      inline
                      className="form-radio"
                      onClick={() => setFiltrosComunicacao({ ...filtrosComunicacao, tipoPesquisa: 2 })}
                      label="Código"
                      name="group1"
                      type="radio"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-5">
                  <GridPadrao
                    onSort={handleSortComunicacao}
                    progressPending={carregandoComunicacao}
                    limit={filtrosComunicacao.limit}
                    onChangePage={handlePageChangeComunicacao}
                    onChangeRowsPerPage={handlePerRowsChangeComunicacao}
                    paginationServer={true}
                    paginationTotalRows={filtrosComunicacao.totalItems}
                    colunas={colunas}
                    tipo="Comunicacao"
                    itens={comunicacoes}
                  />
                </div>
              </>
            )}
          </>}
        />
      </div>
      <hr />
    </>
  );
};

export default ComunicacaoListField;
