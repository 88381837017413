import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import {
  faCheckCircle,
  faEraser,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import GridUsuarios from "../Admininstracao/Seguranca/GrupoUsuarios/components/GridUsuarios";
import { useEffect, useState } from "react";
import FiltroPesquisaDashboardReq from "../../interfaces/Requests/Dashboard/FiltroPesquisaDashboardReq";
import { TableColumn } from "react-data-table-component";
import IDashboardTabela from "../../interfaces/IDashboardTabela";
import Swal from "sweetalert2";
import DashboardTabelasService from "../../services/DashboardTabelasService";
import DashboardAddModal from "./modals/DashboardAddModal";
import DashBoardEditModal from "./modals/DashboardEditModal";
import Select from "react-select";
import { optionsType } from "../../components/Comum/Fornecedor/FornecedorField";
import ModuloService from "../../services/ModuloService";

export default function DashboardTabelaPage() {
  const [modulos, setModulos] = useState<optionsType[]>([]);
  const [modulo, setModulo] = useState<optionsType>({
    label: "",
    value: 0,
  });
  const [reset, setReset] = useState<boolean>(false);
  const [exibirAddModal, setExibirAddModal] = useState<boolean>(false);
  const [exibirEditModal, setExibirEditModal] = useState<boolean>(false);
  const [dashboards, setDashboards] = useState<IDashboardTabela[]>([]);
  const [dashboardEdit, setDashboardEdit] = useState<IDashboardTabela>({
    dashboardId: 0,
    clienteId: 0,
    nome: "",
    moduloNome: "",
    modulo: 0,
    chaveRelatorio: "",
    codigo: "",
    status: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [filtros, setFiltros] = useState<FiltroPesquisaDashboardReq>({
    limit: 10,
    modulo: 0,
    nome: "",
    offset: 0,
    sort: "dashboardId",
    status: "0",
    totalItems: 0,
  });

  const renderCell = (dashboard: IDashboardTabela) => {
    if (dashboard.status === -1) {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Dashboard"
              onClick={() => {
                setDashboardEdit(dashboard);
                setExibirEditModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Ativar Dashboard"
              onClick={() => {
                handleStatusChange(dashboard.dashboardId.toString(), 1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faCheckCircle}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Dashboard"
              onClick={() => {
                setDashboardEdit(dashboard);
                setExibirEditModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Desativar Dashboard"
              onClick={() => {
                handleStatusChange(dashboard.dashboardId.toString(), -1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faTrashAlt}
            />
          </div>
        </>
      );
    }
  };

  const colunas: TableColumn<IDashboardTabela>[] = [
    {
      name: "Id",
      sortField: "dashboardId",
      selector: (row: IDashboardTabela) => row.dashboardId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IDashboardTabela) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Módulo",
      sortField: "modulo",
      selector: (row: IDashboardTabela) => row.moduloNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Chave do Relatório",
      sortField: "chaveRelatorio",
      selector: (row: IDashboardTabela) => row.chaveRelatorio,
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const carregarDashboards = async (filtro: FiltroPesquisaDashboardReq) => {
    try {
      const response = await DashboardTabelasService.obterDashboards(filtro);

      setDashboards(response.data);
      setFiltros((prev) => ({
        ...prev,
        totalItems: response.totalRegistros,
      }));
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos Dashboards",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const carregarModulos = async () => {
    try {
      setLoading(true);
      const response = await ModuloService.obterModulos({
        limit: 10,
        nome: "",
        offset: 0,
        sort: "moduloId",
        status: 1,
        totalItems: 0,
        codigo: "0",
      });

      const mappedModulos = response.data.map((modulo) => {
        return {
          label: modulo.nome,
          value: modulo.moduloId,
        };
      });

      setModulos(mappedModulos);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos módulos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const limparFiltros = async () => {
    setFiltros({
      modulo: 0,
      limit: 10,
      nome: "",
      offset: 0,
      sort: "dashboardId",
      status: "0",
      totalItems: 0,
    });

    setModulo({
      label: "",
      value: 0,
    });

    await carregarDashboards({
      modulo: 0,
      limit: 10,
      nome: "",
      offset: 0,
      sort: "dashboardId",
      status: "0",
      totalItems: 0,
    });
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((prev) => {
      return { ...prev, limit: currentRowsPerPage };
    });

    await carregarDashboards({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((prev) => {
      return { ...prev, offset: (page - 1) * filtros.limit };
    });

    await carregarDashboards({
      ...filtros,
      offset: (page - 1) * filtros.limit,
    });
  };

  const handleSort = async (
    column: TableColumn<IDashboardTabela>,
    sortDirection: string
  ) => {
    setFiltros((prev) => {
      return {
        ...prev,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await carregarDashboards({
      ...filtros,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    });
  };

  const handleStatusChange = async (id: string, status: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await DashboardTabelasService.atualizarDashboard(id, { status });

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status do Dashboard alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await carregarDashboards(filtros);
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível alterar o status do Dashboard",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    carregarDashboards(filtros);
    carregarModulos();
  }, [filtros.status]);

  return (
    <>
      <LayoutPageTitle title="Dashboards">
        <button
          onClick={() => {
            setExibirAddModal(true);
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <DashboardAddModal
        exibirModal={exibirAddModal}
        handleClose={async () => {
          setExibirAddModal(false);

          await carregarDashboards(filtros);
        }}
      />

      <DashBoardEditModal
        exibirModal={exibirEditModal}
        handleClose={async () => {
          setExibirEditModal(false);

          await carregarDashboards(filtros);
        }}
        dashboard={dashboardEdit}
      />

      <div className="col-md-12">
        <form
          onSubmit={async (e) => {
            setReset(!reset);
            e.preventDefault();

            await carregarDashboards(filtros);
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Nome:
            </label>
            <input
              placeholder="Nome"
              type="text"
              className="form-control"
              id="form-nome"
              value={filtros.nome}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((prev) => {
                  return {
                    ...prev,
                    nome: e.target.value,
                  };
                });
              }}
            />
          </div>

          <div className="col-md-2">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Módulo:
            </label>
            <Select
              isLoading={loading}
              placeholder="Selecione um módulo"
              id="form-select"
              options={modulos}
              isSearchable
              value={modulo}
              onChange={async (select) => {
                if (select) {
                  setFiltros((prev) => ({ ...prev, modulo: select.value }));
                  setModulo(select);

                  await carregarDashboards({
                    ...filtros,
                    modulo: select.value,
                  });
                }
              }}
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Status:
            </label>

            <select
              placeholder="Status"
              className="form-control"
              id="form-select"
              value={filtros.status}
              onChange={async (e) => {
                e.preventDefault();
                setFiltros((prev) => {
                  return { ...prev, status: e.target.value };
                });
                setReset(!reset);

                await carregarDashboards({
                  ...filtros,
                  status: e.target.value,
                });
              }}
            >
              <option value="0" label="Todos">
                Todos
              </option>

              <option value="1" label="Ativo">
                Ativo
              </option>

              <option value="-1" label="Inativo">
                Inativo
              </option>
            </select>
          </div>

          <div className="col-md-2">
            <div>
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>
              <button
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridUsuarios
            onSort={handleSort}
            progressPending={loading}
            limit={filtros.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtros.totalItems}
            colunas={colunas}
            tipo="Dashboards"
            itens={dashboards}
            reset={reset}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
}
