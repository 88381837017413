import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ISubGrupo from "../interfaces/ISubGrupo";
import { AdicionaSubGrupoRequest } from "../interfaces/Requests/SubGrupo/AdicionaSubGrupoRequest";
import FiltroPesquisaSubGrupoRequest from "../interfaces/Requests/SubGrupo/FiltroPesquisaSubGrupoRequest";
import { EditarSubGrupoRequest } from "../interfaces/Requests/SubGrupo/EditarSubGrupoRequest";

class SubGrupoService {

    async obterSubGrupos(filtros: FiltroPesquisaSubGrupoRequest): Promise<IRecuperaLista<ISubGrupo>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ISubGrupo>>(`SubGrupo/RecuperaSubGrupo?${query}`);

        return result;
    }

    async adicionaSubGrupo(adicionaSubGrupo: AdicionaSubGrupoRequest): Promise<ISubGrupo> {
        let { data: result } = await api.post<ISubGrupo>(`SubGrupo/AdicionaSubGrupo`, adicionaSubGrupo);

        return result;
    }


    async alterarStatus(subGrupoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ISubGrupo>(`SubGrupo/AtualizaSubGrupoPorId?id=${subGrupoId}`, data);
    }

    async atualizarSubGrupo(editarSubGrupo: EditarSubGrupoRequest): Promise<void> {

        let dataSubGrupo = [
            { "op": "replace", "path": "/nome", "value": editarSubGrupo.nome },
            { "op": "replace", "path": "/codigo", "value": editarSubGrupo.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`SubGrupo/AtualizaSubGrupoPorId?id=${editarSubGrupo.subGrupoId}`, dataSubGrupo)
        ]);
    }
}


export default new SubGrupoService();