import { RenderElementProps } from "slate-react";
import { BulletedListElement as BulledtedListEl } from "../../../../../../utils/types/slate";

export const BulletedListElement = (props: RenderElementProps) => {
  const element = props.element as BulledtedListEl

  const style: React.CSSProperties = {
    textAlign: (element.align as any) ?? "start",
  };

  return (
    <ul style={style} {...props.attributes}>
      {props.children}
    </ul>
  );
}