import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #2E3334;

  > label {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
`;

export const TextArea = styled.textarea`
  width: 500px;
  min-height: 50px;
  max-height: 200px;
  padding: 10px;
  resize: none;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #2e3334;
  background-color: #F3F4F6;

  &::placeholder {
    color: #797c7f;
  }

  &:focus {
    border: 2px solid var(--primary-base);
  }
`;