import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { EModulo } from "../../../../../../enum";
import { ISelecionaModeloRequest } from "../../../../../../interfaces/IEditorVersionResponse";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { toast } from "react-toastify";
import EditorService from "../../../../../../services/EditorService";
import { handleApiError } from "../../../../../../utils/Utils";

interface UseTemplateProps {
  editor: DocumentEditor;
  close: () => void;
}

export function useTemplate({ editor, close }: UseTemplateProps) {
  const [isEnabled, setIsEnabled] = useState(true);
  const { data, isLoading, error } = useQuery({
    queryKey: ["TEMPLATE_LIST"],
    enabled: isEnabled,
    retry: false,
    queryFn: () => EditorService.templateList(EModulo.AreaDireito),
    onSuccess: () => setIsEnabled(false),
  });

  const { mutateAsync: selectTemplateFn, isLoading: selectLoading } =
    useMutation({
      mutationFn: EditorService.selectTemplate,
      onSuccess: ({ jsonContent }) => {
        const data = { sfdt: jsonContent.sfdt };
        editor.open(JSON.stringify(data));
        toast.success("Modelo selecionado com sucesso!", {
          position: "bottom-right",
        });
        close();
      },
      onError: (error) => {
        const errorMessage = handleApiError(error);
        toast.error(errorMessage, { position: "bottom-right" });
      },
    });

  async function selectTemplate(params: ISelecionaModeloRequest) {
    await selectTemplateFn(params);
  }

  return {
    templates: data?.data ?? [],
    isLoading,
    error,
    selectTemplate,
    selectLoading,
  };
}
