import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import GridPadrao from "../Comum/GridPadrao";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";

import { ECamposIDEnum, EModulo } from "../../enum";

import DocumentoService from "../../services/DocumentoService";
import GedUploadService from "../../services/GedUploadService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import IFile from "../../interfaces/IFile";
import IDocumento from "../../interfaces/IDocumento";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaDocumentoRequest from "../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";

import CollapseFiltro from "../Collapse/CollapseFiltro";
import { FileDropzone } from "../FileUpload/DragDropZone";
import { OrangeContext } from "../../contexts/OrangeProvider";
import ITipoDocumento from "../../interfaces/ITipoDocumento";
import { notifySuccess } from "../Comum/Toast/ToastFormik";

interface IFileMinutaFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
}

const FileMinutaField = ({ toggleModal, exibirModal }: IFileMinutaFieldProps) => {

  const { contrato } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [documentos, setDocumentos] = useState<IDocumento[]>([]);
  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);

  const [descricao, setDescricao] = useState<string>("");
  const [tipoDocumentos, setTipoDocumento] = useState<ITipoDocumento[]>([]);
  const [tipoDocumentoId, setTipoDocumentoId] = useState<number>(0);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    modulo: [EModulo.Contrato],
    anexoMinuta: false,
    contratoId: contrato.contratoId,
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });


  const initialValues: IFile[] = [];

  const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa((oldState) => { return { ...oldState, limit: currentRowsPerPage }; });

  const handlePageChange = (page: number) => setFiltrosPesquisa((oldState) => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit }; });

  const handleSort = async (column: TableColumn<IDocumento>, sortDirection: string) => setFiltrosPesquisa((oldState) => { return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` }; });

  useEffect(() => {
    if (exibirModal) {
      carregarDocumentos(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    if (exibirModal) {

      carregaTipoDocumento();

      carregarDocumentos(filtrosPesquisa);

      limparCampos();

    }
  }, [exibirModal]);


  const adicionar = async (documentoId: number) => {
    const novosDocumentos = await Promise.all(
      documentos.map(async (doc) => {
        if (doc.documentoId === documentoId) {
          const novoStatus = !doc.anexoMinuta; // Inverte o status atual de 'anexoMinuta'

          if (novoStatus) {
            notifySuccess(`Documento: ${doc.descricao} anexado à minuta com sucesso.`);
          } else {
            notifySuccess(`Documento: ${doc.descricao} removido da minuta com sucesso.`);
          }

          await DocumentoService.atualizaAnexoMinuta(doc.documentoId, novoStatus);
          return { ...doc, anexoMinuta: novoStatus }; // Atualiza o status de 'anexoMinuta'
        }
        return doc; // Retorna os outros documentos sem alterações
      })
    );

    // Atualiza o estado de 'documentos' com a nova lista imutável
    setDocumentos(novosDocumentos);
  };



  const carregaTipoDocumento = async () => {
    try {

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.Contrato, contrato.tipoContratoId, contrato.contratoId, 0, 58);

      setTipoDocumento(resultado.data);

    } catch (error: any) {
      setTipoDocumento([]);
    }
  };

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {

      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setDocumentos(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoDocumentos(false);
    }
  };

  const colunas: TableColumn<IDocumento>[] = [

    {
      name: "Anexar a Minuta?",
      ignoreRowClick: true,
      width: '200px',
      cell: (row: IDocumento) => {
        return (
          <div>
            <Form.Check
              type="switch"
              checked={row.anexoMinuta}
              id="custom-switch"
              onChange={() => adicionar(row.documentoId)}
            />
          </div>
        );
      },
    },
    {
      name: "ID",
      sortField: "documentoId",
      selector: (row: IDocumento) => row.documentoId,
      sortable: true,
      wrap: true,
      width: '100px',
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: IDocumento) => row.descricao,
      sortable: true,
      wrap: true,
      width: '200px',
    },

    {
      name: "Data de Cadastro",
      sortField: "dataCadastro",
      selector: (row: IDocumento) => moment(row.dataCadastro).format('DD/MM/YYYY'),
      sortable: true,
      wrap: true,
      width: '200px',
    },
  ];

  const isValidProtocolos = () => {
    let isValid = true;

    documentos.forEach(documento => {
      if (documento.anexoMinuta && !documento.tipoProtocoloTribunalIndex) {
        isValid = false;
      }
    });

    return isValid;
  }

  const confirmar = async () => toggleModal();


  const cancelar = () => {

    formik.setValues([]);

    toggleModal();
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      formik.setValues(values);
    },
  });

  const adicionaArquivo = (event: any) => {
    for (let index = 0; index < event.length; index++) {
      const currentFile = event[index];
      formik.values.push({
        index: formik.values.length,
        tipoDocumentoId: 0,
        documentoId: 0,
        serRestriro: false,
        file: currentFile,
        nome: !descricao ? currentFile.name : descricao,
        extensao: currentFile.name.substring(currentFile.name.lastIndexOf(".") + 1),
      });
    }

    formik.values.forEach((file: IFile, index: number) => (file.index = index));

  };

  const iniciarUploadArquivos = async () => {

    formik.values.forEach(async (item: IFile, index: number) => {

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Realizando upload...`,
        allowOutsideClick: false,
        showConfirmButton: false
      });

      Swal.showLoading()

      try {

        const formData = new FormData();
        formData.append("origem", "1");
        formData.append("file", item.file);
        formData.append("tipoDocumentoId", tipoDocumentoId.toString());
        formData.append("descricao", !descricao ? item.nome : `${descricao}`);
        formData.append("restrito", item.serRestriro.toString());
        formData.append("modulo", EModulo.Contrato.toString());
        formData.append("contratoId", contrato.contratoId.toString());
        await GedUploadService.adicionaArquivo(formData);

        Swal.hideLoading();

        Swal.close();

      } catch (error: any) {
        console.log(error)
      } finally {
        carregarDocumentos(filtrosPesquisa);
      }
    });

    limparCampos();

  };

  const limparCampos = () => {

    formik.setValues(initialValues);

    setDescricao('');

    setTipoDocumentoId(0);
  }

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <ToastContainer />
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Anexar documentos na minuta
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">

          <div className="row mb-2">

            <div className="col-md-auto mb-10">
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Pesquise"
                type="text"
                className={clsx("form-control")}
              />
            </div>

            <div className="col-md-auto mb-3">
              <button onClick={() => carregarDocumentos(filtrosPesquisa)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>

          </div>

          <div className="col-md-12 mb-3">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoDocumentos}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Documentos"
              itens={documentos}
            />
          </div>

          <CollapseFiltro
            titulo="Deseja importar novos arquivos?"
            open={false}
            content={
              <>
                <div className="row mb-2">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                      Tipo de Documento:
                    </label>
                    <select
                      name="tipoDocumentoId"
                      value={tipoDocumentoId}
                      onChange={(event) => setTipoDocumentoId(parseInt(event.target.value))}
                      className={"form-control"}
                    >
                      <option value="0">Selecione</option>
                      {tipoDocumentos.map((map: any) => {
                        return (
                          <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                            {map.nome}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Descrição:
                    </label>
                    <input
                      value={descricao}
                      placeholder="Descrição do arquivo..."
                      type="text"
                      className={"form-control"}
                      onChange={(e: any) => setDescricao(e.target.value)}
                    />
                  </div>

                </div>

                <div className="col-md-12 mb-3">
                  <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                    Arquivo:
                  </label>
                  <FileDropzone
                    onDrop={(acceptedFiles) => {
                      adicionaArquivo(acceptedFiles);
                    }}
                  />
                </div>

                <div className="modal-footer" style={{ margin: "0 auto" }}>

                  <button
                    onClick={() => iniciarUploadArquivos()}
                    disabled={!tipoDocumentoId}
                    type="button"
                    className="btn btn-orange ms-5"
                  >
                    Iniciar Importação
                  </button>

                </div>
              </>}
          />

        </div>

        <div className="modal-footer" style={{ margin: "0 auto" }}>

          <button
            onClick={() => confirmar()}
            disabled={!isValidProtocolos()}
            type="button"
            className="btn btn-orange ms-5"
          >
            Adicionar
          </button>

          <button
            onClick={() => cancelar()}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal >
  );
};

export default FileMinutaField;
