import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoObrigacao from "../interfaces/ITipoObrigacao";
import { AdicionaTipoObrigacaoRequest } from "../interfaces/Requests/TipoObrigacao/AdicionaTipoObrigacaoRequest";
import FiltroPesquisaTipoObrigacaoRequest from "../interfaces/Requests/TipoObrigacao/FiltroPesquisaTipoObrigacaoRequest";
import { EditarTipoObrigacaoRequest } from "../interfaces/Requests/TipoObrigacao/EditarTipoObrigacaoRequest";

class TipoObrigacaoService {

    async obterTipoObrigacoes(filtros: FiltroPesquisaTipoObrigacaoRequest): Promise<IRecuperaLista<ITipoObrigacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoObrigacao>>(`TipoObrigacao/RecuperaTipoObrigacao?${query}`);

        return result;
    }

    async obterListaLimitadaTipoObrigacao(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoObrigacao/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoObrigacao(adicionaTipoObrigacao: AdicionaTipoObrigacaoRequest): Promise<ITipoObrigacao> {
        let { data: result } = await api.post<ITipoObrigacao>(`TipoObrigacao/AdicionaTipoObrigacao`, adicionaTipoObrigacao);

        return result;
    }


    async alterarStatus(tipoObrigacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoObrigacao>(`TipoObrigacao/AtualizaTipoObrigacaoPorId?id=${tipoObrigacaoId}`, data);
    }

    async atualizarTipoObrigacao(editarTipoObrigacao: EditarTipoObrigacaoRequest): Promise<void> {

        let dataTipoObrigacao = [
            { "op": "replace", "path": "/nome", "value": editarTipoObrigacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoObrigacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoObrigacao/AtualizaTipoObrigacaoPorId?id=${editarTipoObrigacao.tipoObrigacaoId}`, dataTipoObrigacao)
        ]);
    }
}


export default new TipoObrigacaoService();