import React, { FC, ReactNode, useContext, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { OrangeInterfaceContext } from "./app/interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "./app/contexts/OrangeProvider";
import { lightTheme } from "./theme";
import { GlobalStyles } from "./globalStyles";

const ThemeController: FC<{ children: ReactNode }> = ({ children }: any) => {
  const { setSelectedTheme, selectedTheme } =
    useContext<OrangeInterfaceContext>(OrangeContext);

  return (
    <ThemeProvider theme={selectedTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default ThemeController;
