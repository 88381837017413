import { Descendant } from "slate";
import { ReactEditor } from "slate-react";
import { CustomEditor } from "../../../utils/types/slate";

function toNode(editor: CustomEditor, html: HTMLElement) {
  try {
    return ReactEditor.toSlateNode(editor, html);
  } catch (error) {
    console.error("toNode error: ", error);
    return null;
  }
}

function toHTML(editor: CustomEditor, child?: Descendant) {
  if (!child) {
    return null;
  }

  try {
    return ReactEditor.toDOMNode(editor, child);
  } catch (error) {
    console.error("toHTML error: ", error);
    return null;
  }
}

export const nodeHelper = {
  toHTML,
  toNode,
};
