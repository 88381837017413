interface layout {
    title: string
    children?: any
}

const LayoutPageTitle = ({ children, title }: layout) => {
    return (<>
        <div className="d-flex text-orange justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{title}</h1>
            {children}
        </div>
    </>)
}

export default LayoutPageTitle;