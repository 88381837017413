/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { Draggable } from "react-beautiful-dnd";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrangeContext } from "../../../../contexts/OrangeProvider";

import { ICampo } from "../../../../interfaces/ICampo";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";

interface ItemProps {
  alterarItem(item: ICampo): void;
  item: ICampo;
  index: number;
  col: string;
}

const buscarEstilo = (isDragging: boolean, draggableStyle: any) => ({
  padding: 10,
  margin: `0 50px 15px 40px`,
  background: isDragging ? "var(--primary-base)" : "white",
  color: isDragging ? "white" : "black",
  border: `1px solid`,
  fontSize: `12px`,
  borderRadius: `5px`,
  cursor: "pointer",
  ...draggableStyle,
});

const FormESeparador: React.FC<ItemProps> = ({ item, index, alterarItem, col }) => {

  const { campos } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModal, setExibirModal] = useState<boolean>(false);

  const initialValues = {
    campo: 1556,
    camposId: 1556,
    codigo: item.codigo,
    nome: item.nome,
    help: item.help,
    label: item.label,
    modulo: item.modulo,
    ordenacao: item.ordenacao,
    separadorId: item.separadorId,
  } as ICampo;

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      alterarItem(values);

      campos.splice(values.ordenacao, 0, { ...values });

      formik.setValues(initialValues);

      setSubmitting(false);

    },
  });

  return (
    <>
      <Draggable key={item.separadorId} draggableId={item.separadorId?.toString() || item.label} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                onClick={() => setExibirModal(!exibirModal)}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                <>{item?.label}</>
              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                onClick={() => setExibirModal(!exibirModal)}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                <>{item.label}</>
              </div>
            </>
          )
        }
      </Draggable>

      <Modal size="lg" centered={true} show={exibirModal} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Alterar Separador: {formik.values.label}</h5>
            <div
              onClick={() => {
                formik.setValues(initialValues);
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">

              {formik.values.codigo && (
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Código da Parametrização:
                    </label>
                    <div className="codigo-value">{formik.values.codigo}</div>
                  </div>
                </div>
              )}

              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Nome
                  </label>
                  <input placeholder="Nome" {...formik.getFieldProps("label")} type="text" className={"form-control"}></input>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Texto de Ajuda
                  </label>
                  <input placeholder={formik.values.help} {...formik.getFieldProps("help")} type="text" className={"form-control"}></input>
                </div>
              </div>

            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              onClick={() => {
                alterarItem(formik.values);
                setExibirModal(!exibirModal);
              }}
              type="button"
              className="btn btn-orange me-5"
            >
              ALTERAR
            </button>
            <button
              onClick={() => {
                setExibirModal(!exibirModal);
                formik.setValues(initialValues);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormESeparador;
