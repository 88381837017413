import { useParams } from "react-router-dom";
import {
  DynamicProviderUrl,
  getDynamicProvider,
} from "../util/getDynamicProvider";
import { Caller } from "../util";

export function useTenant() {
  const params = useParams<DynamicProviderUrl>();

  const moduleOptions: { [key: string]: number } = {
    [Caller.CONTRATO]: 7,
    [Caller.PROCURACAO]: 21,
    [Caller.PROCESSO]: 1,
  };

  return {
    tenant: getDynamicProvider(params),
    module: moduleOptions[params.caller ?? Caller.CONTRATO],
    caller: params.caller ?? Caller.CONTRATO
  };
}
