import { useEffect, useState } from "react";
import { faEraser, faSearch, faFileExcel, faEnvelope, faPaperPlane, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import FechamentoModal from "./FechamentoModal";
import Swal from "sweetalert2";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import IFechamento from "../../interfaces/IFechamento";
import FechamentoService from "../../services/FechamentoService";
import FiltroPesquisaFechamentoRequest from "../../interfaces/Requests/Fechamento/FiltroPesquisaFechamentoRequest";
import FechamentoConfiguracaoService from "../../services/FechamentoConfiguracaoService";
import IFechamentoConfiguracao from "../../interfaces/IFechamentoConfiguracao";
import ReactSelect, { MultiValue } from "react-select";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import FechamentoDetalheModal from "./FechamentoDetalhes";
import FechamentoPreviewModal from "./FechamentoPreviewModal";
import FechamentoRelatorioModal from "./FechamentoRelatorioModal";


const fechamentoInicial: IFechamento = {
  fechamentoId: 0,
  usuarioIdCadastro: 0,
  clienteId: 0,
  fechamentoConfiguracaoId: 0,
  fechamentoNome: "",
  dataCorte: "",
  status: "",
  dataCadastro: "",
  totalProcessosAtivos: 0,
  totalProcessosEncerrados: 0,

  passivaValorTotalAtualizado: 0,
  passivaValorTotalJurosECorrecao: 0,
  passivaValorTotalPrincipal: 0,
  passivaValorTotalMulta: 0,
  passivaValorTotalHonorarios: 0,
  passivaValorProvavelPrincipal: 0,
  passivaValorProvavelCorrecao: 0,
  passivaValorProvavelJuros: 0,
  passivaValorProvavelAtualizado: 0,
  passivaValorPossivelPrincipal: 0,
  passivaValorPossivelCorrecao: 0,
  passivaValorPossivelJuros: 0,
  passivaValorPossivelAtualizado: 0,
  passivaValorRemotoPrincipal: 0,
  passivaValorRemotoCorrecao: 0,
  passivaValorRemotoJuros: 0,
  passivaValorRemotoAtualizado: 0,
  passivaValorProvisaoAtualPrincipal: 0,
  passivaValorProvisaoAtualCorrecao: 0,
  passivaValorProvisaoAtualJuros: 0,
  passivaValorProvisaoAtualAtualizado: 0,

  ativaValorTotalAtualizado: 0,
  ativaValorTotalHonorarios: 0,
  ativaValorTotalJurosECorrecao: 0,
  ativaValorTotalMulta: 0,
  ativaValorTotalPrincipal: 0,
  ativaValorProvavelPrincipal: 0,
  ativaValorProvavelCorrecao: 0,
  ativaValorProvavelJuros: 0,
  ativaValorProvavelAtualizado: 0,
  ativaValorPossivelPrincipal: 0,
  ativaValorPossivelCorrecao: 0,
  ativaValorPossivelJuros: 0,
  ativaValorPossivelAtualizado: 0,
  ativaValorRemotoPrincipal: 0,
  ativaValorRemotoCorrecao: 0,
  ativaValorRemotoJuros: 0,
  ativaValorRemotoAtualizado: 0,
  ativaValorProvisaoAtualPrincipal: 0,
  ativaValorProvisaoAtualCorrecao: 0,
  ativaValorProvisaoAtualJuros: 0,
  ativaValorProvisaoAtualAtualizado: 0,

  valorProvisaoAnteriorAtualizado: 0,
  valorAcrescimo: 0,
  valorAtualizacao: 0,
  valorReversao: 0,
  valorDespesa: 0,
  valorResultado: 0,
};

const FechamentoPage = () => {
  const [fechamento, setFechamento] = useState<IFechamento>(fechamentoInicial);
  const [fechamentos, setFechamentos] = useState<IFechamento[]>([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoFechamentos, setCarregandoFechamentos] = useState(false);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [exibirCartaModal, setExibirCartaModal] = useState<boolean>(false);
  const [fechamentosConfiguracao, setFechamentosConfiguracao] = useState<IFechamentoConfiguracao[]>([]);
  const [exibirDetalheModal, setExibirDetalheModal] = useState<boolean>(false);
  const [fechamentosConfiguracaoSelecionados, setFechamentosConfiguracaoSelecionados] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);
  const [exibirRelatorioModal, setExibirRelatorioModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaFechamentoRequest>({
    fechamentosConfiguracao: [],
    fechamentoId: 0,
    dataCorteMaiorIgual: "",
    dataCorteMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    status: 3,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-fechamentoId",
  });


  function toggleFormModal(carregarDados?: boolean): void {
    if (carregarDados) {
      carregarFechamentos(filtrosPesquisa);
    }

    setExibirModal(!exibirModal);

    setTimeout(() => {
      setFechamento(fechamentoInicial);
    }, 300);
  }

  function toggleDocModal(carregarDocDados?: boolean): void {
    if (carregarDocDados) {
      carregarFechamentos(filtrosPesquisa);
    }
    setExibirCartaModal(!exibirCartaModal);
  }

  function toggleDetalheModal(): void {
    setExibirDetalheModal(!exibirDetalheModal);

    setTimeout(() => {
      setFechamento(fechamentoInicial);
    }, 300);
  }

  function toggleExibirRelatorioModal(): void {
    setExibirRelatorioModal(!exibirRelatorioModal);
  }

  async function carregarFechamentos(filtro: FiltroPesquisaFechamentoRequest) {
    try {
      setCarregandoFechamentos(true);
      let resultado: IRecuperaLista<IFechamento>;

      resultado = await FechamentoService.obterFechamentos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setFechamentos(resultado.data);
      setAtualizaTabela(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setFechamentos([]);
    } finally {
      setCarregandoFechamentos(false);
      setLoadingInicial(false);
    }
  }

  async function detalhesFechamento(fechamentoId: number) {
    try {
      setCarregandoFechamentos(true);
      let resultado: IFechamento;

      resultado = await FechamentoService.recuperaDetalhesFechamento(fechamentoId);

      setFechamento(resultado);
      setAtualizaTabela(false);
      setExibirDetalheModal(true);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setFechamento(fechamentoInicial);
    } finally {
      setCarregandoFechamentos(false);
      setLoadingInicial(false);
    }
  }

  const colunas: TableColumn<IFechamento>[] = [
    {
      name: "Id",
      sortField: "fechamentoId",
      selector: (row: IFechamento) => row.fechamentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IFechamento) => row.fechamentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Status',
      sortField: 'status',
      selector: (row: IFechamento) => row.status,
      sortable: true,
      wrap: true
    },
    {
      name: 'Data Corte',
      sortField: 'dataCorte',
      selector: (row: IFechamento) => {
        const data = row.dataCorte || ''
        if (data.includes('T')) {
          const [ano, mes, dia] = data.split('T')[0].split('-')
          return `${dia}/${mes}/${ano}`
        }
        return '-'
      },
      sortable: true,
      wrap: true
    },
    {
      name: 'Data Cadastro',
      sortField: 'dataCadastro',
      selector: (row: IFechamento) => {
        const data = row.dataCadastro || ''
        if (data.includes('T')) {
          const [ano, mes, dia] = data.split('T')[0].split('-')
          return `${dia}/${mes}/${ano}`
        }
        return '-'
      },
      sortable: true,
      wrap: true
    },
    {
      name: '',
      cell: (fechamento: IFechamento) => {
        return (
          <div>
            {<FontAwesomeIcon title="Visualizar Fechamento" onClick={() => {
              detalhesFechamento(fechamento.fechamentoId)
            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faSearch} />
            }

            {fechamento.status === "Fechado" && <FontAwesomeIcon title="Gerar Relatório" onClick={() => {
              setFechamento(fechamento);
              toggleExibirRelatorioModal();
            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faFileExcel} />
            }

            {(fechamento.fechamentoId === 234 || fechamento.fechamentoId === 222) &&
              <FontAwesomeIcon title="Visualizar Cartas de Cicularização" onClick={() => {
                setExibirCartaModal(true);
              }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faEnvelope} />
            }

            {(fechamento.fechamentoId !== 234 && fechamento.fechamentoId !== 222) &&
              <FontAwesomeIcon title="Iniciar Circularização" onClick={() => {
                setFechamento(fechamento);
              }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPaperPlane} />
            }
          </div>

        )
      },
      ignoreRowClick: true,
    }
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IFechamento>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  async function pesquisarFechamentosConfiguracao() {
    try {
      const result = await FechamentoConfiguracaoService.obterFechamentoConfiguracaos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "fechamentoConfiguracaoId",
      });
      setFechamentosConfiguracao(result.data);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Configurações`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  }

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        fechamentoConfiguracaoId: [],
        status: 3,
        dataCadastroMaiorIgual: '',
        dataCadastroMenorIgual: '',
        dataCorteMaiorIgual: '',
        dataCorteMenorIgual: '',
      };
    });
    setFechamentosConfiguracaoSelecionados([]);
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarFechamentos(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    if (atualizarTabela) setTimeout(() => carregarFechamentos(filtrosPesquisa), 1000);
  }, [atualizarTabela]);

  useEffect(() => {
    pesquisarFechamentosConfiguracao();
    setLoadingInicial(true);
  }, []);

  return (
    <>
      <LayoutPageTitle title="Fechamento" >
        <div>
          <button onClick={() => {
            setFechamento(fechamentoInicial);
            setExibirModal(true);
          }} className="btn btn-md btn-orange">
            Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
          </button>
        </div>
      </LayoutPageTitle>

      <FechamentoModal
        toggleModal={toggleFormModal}
        exibirModal={exibirModal}
        setAtualizarTabela={setAtualizaTabela}
      />

      <FechamentoRelatorioModal
        toggleModal={toggleExibirRelatorioModal}
        exibirRelatorioModal={exibirRelatorioModal}
        fechamentoId={fechamento.fechamentoId}
      />

      <FechamentoDetalheModal exibirModal={exibirDetalheModal} titulo={"Detalhes do Fechamento"} toggleModal={toggleDetalheModal} fechamento={fechamento} />
      <FechamentoPreviewModal exibirCartaModal={exibirCartaModal} toggleCartaModal={toggleDocModal} />

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarFechamentos(filtrosPesquisa);
            }}
            className="row g-3 mb-3">

            <div className="col-12 col-lg-4 col-xl-5">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                    [
                      filtrosPesquisa.dataCadastroMaiorIgual,
                      filtrosPesquisa.dataCadastroMenorIgual,
                    ] : ''
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                  }
                }}

              />
            </div>

            <div className="col-12 col-lg-4 col-xl-5">
              <label htmlFor="form-data-corte" className="form-label fw-bolder text-orange">Data de Corte:</label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  (filtrosPesquisa.dataCorteMaiorIgual && filtrosPesquisa.dataCorteMenorIgual) ?
                    [
                      filtrosPesquisa.dataCorteMaiorIgual,
                      filtrosPesquisa.dataCorteMenorIgual,
                    ] : ''
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCorteMaiorIgual: '',
                      dataCorteMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCorteMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                      dataCorteMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCorteMaiorIgual: '',
                      dataCorteMenorIgual: '',
                    }));
                  }
                }} />

            </div>
            <div className="col-12 col-lg-4 col-xl-5">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">Status:</label>
              <select value={filtrosPesquisa.status} onChange={(e) => {
                setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
              }} placeholder="Status" className={'form-select'} id="form-status" >
                <option value={0}>Todos</option>
                <option value={1}>Executando</option>
                <option value={2}>Aberto</option>
                <option value={3}>Fechado</option>
                <option value={4}>Erro</option>
                <option value={-1}>Excluido</option>
              </select>
            </div>

            <div className="col-12 col-lg-4 col-xl-5">
              <label htmlFor="form-fechamento-configuracao" className="form-label fw-bolder text-orange">
                Nome Fechamento:
              </label>
              <ReactSelect
                onChange={(selectedOptions) => {
                  let fechamentoConfiguracaoId = selectedOptions.map((c) => parseInt(c.value));

                  setFiltrosPesquisa((oldState) => ({ ...oldState, fechamentosConfiguracao: fechamentoConfiguracaoId }));
                  setFechamentosConfiguracaoSelecionados(selectedOptions);
                }}
                name="fechamentoConfiguracaoId"
                placeholder="Fechamento"
                isMulti={true}
                value={fechamentosConfiguracaoSelecionados}
                defaultValue={fechamentosConfiguracaoSelecionados}
                options={fechamentosConfiguracao.map((c) => ({ value: c.fechamentoConfiguracaoId.toString(), label: c.nome }))}
              />
            </div>

            <div className="col-md-2"
              style={{ textAlign: 'right' }}>
              <button
                type="submit"
                className="btn btn-orange search-buttom-margin "
                title="Buscar">
                <FontAwesomeIcon color='white' className='' icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro">
                <FontAwesomeIcon color='white' className='' icon={faEraser} />
              </button>
            </div>

          </form>
        </div>

        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoFechamentos}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Fechamentos"
            itens={fechamentos}
          />
        </div>

      </div>
    </>
  );
};

export default FechamentoPage;
