import { faHistory, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import clsx from "clsx";

import IPastaGed from "../../interfaces/IPastaGed";
import IDocumento from "../../interfaces/IDocumento";
import ITipoDocumento from "../../interfaces/ITipoDocumento";

import PastaGedService from "../../services/PastaGedService";
import DocumentoService from "../../services/DocumentoService";
import TipoDocumentoService from "../../services/TipoDocumentoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { EModulo } from "../../enum";

import { tipoPorModulo } from "../../utils/Utils";

interface IFieldProps {
  editarDocumento: IDocumento;
  toggleModal: () => void;
  exibirModalDocumentoEdit: boolean;

  campo: number;

  despesaId?: number;
  tipoDespesaId?: number,

  honorarioId?: number;
  tipoHonorarioId?: number,

  reembolsoId?: number;
  tipoReembolsoId?: number,

  desdobramentoId?: number;
  tipoDesdobramentoId?: number,

  moduloDespesaSelecionado?: EModulo,
  moduloDesdobramentoSelecionado?: EModulo,
  moduloHonorarioSelecionado?: EModulo,
  moduloReembolsoSelecionado?: EModulo,
  moduloAudienciaSelecionado?: EModulo,
  moduloPropostaAcordoSelecionado?: EModulo,

  categoriaId?: number,

  processoId?: number;
  areaDireitoId?: number;

  contratoId?: number;
  tipoContratoId?: number;

  marcaId?: number;
  tipoMarcaId?: number;

  imovelId?: number;
  tipoImovelId?: number;

  consultaId?: number,
  tipoConsultaId?: number,

  procuracaoId?: number,
  tipoProcuracaoId?: number,

  licencaId?: number;
  tipoLicencaId?: number;

  propostaAcordoId?: number;
  tipoPropostaAcordoId?: number;

  empresaId?: number;
  tipoSociedadeId?: number;

  atoSocietarioId?: number;
  tipoAtoId?: number,

  audienciaId?: number;
  tipoAudienciaId?: number,

  modulo: number;
  setAtualizarTabela(atualizaAgora: boolean): void
}

const FileUploadFieldEdit = ({
  toggleModal,
  exibirModalDocumentoEdit,

  campo,

  despesaId,
  tipoDespesaId,

  desdobramentoId,
  tipoDesdobramentoId,

  honorarioId,
  tipoHonorarioId,

  reembolsoId,
  tipoReembolsoId,

  moduloDespesaSelecionado,
  moduloDesdobramentoSelecionado,
  moduloHonorarioSelecionado,
  moduloReembolsoSelecionado,
  moduloAudienciaSelecionado,
  moduloPropostaAcordoSelecionado,
  categoriaId,

  processoId,
  areaDireitoId,

  contratoId,
  tipoContratoId,

  marcaId,
  tipoMarcaId,

  consultaId,
  tipoConsultaId,

  procuracaoId,
  tipoProcuracaoId,

  imovelId,
  tipoImovelId,

  licencaId,
  tipoLicencaId,

  empresaId,
  atoSocietarioId,
  tipoAtoId,

  propostaAcordoId,
  tipoPropostaAcordoId,

  audienciaId, 
  tipoAudienciaId,

  modulo,

  editarDocumento,
  setAtualizarTabela }: IFieldProps) => {

  const [carregandoTipoDocumento, setCarregandoTipoDocumento] = useState(false);
  const [tipoDocumentos, setTipoDocumento] = useState<ITipoDocumento[]>([]);
  const [listaPasta, setListaPasta] = useState<IPastaGed[]>([]);


  const initialValues: IDocumento = {
    index: 0,
    documentoId: 0,
    clienteId: 0,
    nome: "",
    descricao: "",
    tamanho: 0,
    tipoDocumentoId: 0,
    tipoDocumentoNome: "",
    restrito: false,
    status: "",
    codigo: "",
    dataCadastro: new Date,
    usuarioIdCadastro: 0,
    usuarioNomeCadastro: "",
    modulo: modulo.toString(),
    extensao: "",
    pastaGedNome: "",
    pastaGedId: 0,
    etiquetas: []
  };

  const schema = () => {
    let schema: any = {};
    schema.descricao = Yup.string().min(3, "Campo descrição está inválido").required("Campo descrição é obrigatório");
    schema.tipoDocumentoId = Yup.number().min(1).required("Campo tipo de documento é obrigatório");
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema(),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar documento",
          text: `Você realmente deseja editar este documento?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: 'info',
            title: 'Realizando edição...',
            showConfirmButton: false,
          });
          Swal.showLoading();
          await DocumentoService.atualizaDocumento(values);
          toggleModal();
          //setSubmitting(false);
          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: `Documento editado com sucesso!`,
            showConfirmButton: true,
            timer: 4000
          });

          Swal.close();

          setAtualizarTabela(true);
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar editar o documento.`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  async function cancelar() {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  const carregaTipoDocumento = async () => {
    setCarregandoTipoDocumento(true);
    try {
      if (areaDireitoId && areaDireitoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.AreaDireito, areaDireitoId, processoId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoMarcaId && tipoMarcaId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.MarcaEPertences, tipoMarcaId, marcaId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoAtoId && tipoAtoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.AtoSocietario, tipoAtoId, atoSocietarioId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoImovelId && tipoImovelId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.Imovel, tipoImovelId, imovelId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoConsultaId && tipoConsultaId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.Consultivo, tipoConsultaId, consultaId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoProcuracaoId && tipoProcuracaoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.Procuracao, tipoProcuracaoId, procuracaoId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoLicencaId && tipoLicencaId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.Regulatorio, tipoLicencaId, licencaId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoContratoId && tipoContratoId > 0) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("TipoDocumento", EModulo.Contrato, tipoContratoId, contratoId, 0, campo);
        setTipoDocumento(campos.data);
      }

      if (tipoDespesaId && tipoDespesaId > 0 && moduloDespesaSelecionado) {
        let objeto: any = {};
        eval(`objeto.${tipoPorModulo(moduloDespesaSelecionado)}Id=${categoriaId}`);
        let campos = await ConfiguracaoTelaService.obterListaLimitadaDespesa(moduloDespesaSelecionado, "TipoDocumento", tipoDespesaId, despesaId, objeto)
        setTipoDocumento(campos.data);
      }

      if (tipoDesdobramentoId && tipoDesdobramentoId > 0 && moduloDesdobramentoSelecionado) {
        let objeto: any = {};
        eval(`objeto.${tipoPorModulo(moduloDesdobramentoSelecionado)}Id=${categoriaId}`);
        let campos = await ConfiguracaoTelaService.obterListaLimitadaDespesa(moduloDesdobramentoSelecionado, "TipoDocumento", tipoDesdobramentoId, desdobramentoId, objeto)
        setTipoDocumento(campos.data);
      }

      if (tipoPropostaAcordoId && tipoPropostaAcordoId > 0 && moduloPropostaAcordoSelecionado) {
        let objeto: any = {};
        eval(`objeto.${tipoPorModulo(moduloPropostaAcordoSelecionado)}Id=${categoriaId}`);
        let campos = await ConfiguracaoTelaService.obterListaLimitadaPropostaAcordo(moduloPropostaAcordoSelecionado, "TipoDocumento", tipoPropostaAcordoId, propostaAcordoId, objeto)
        setTipoDocumento(campos.data);
      }

      if (tipoHonorarioId && tipoHonorarioId > 0 && moduloHonorarioSelecionado) {
        let objeto: any = {};
        eval(`objeto.${tipoPorModulo(moduloHonorarioSelecionado)}Id=${categoriaId}`);
        let campos = await ConfiguracaoTelaService.obterListaLimitadaDespesa(moduloHonorarioSelecionado, "TipoDocumento", tipoHonorarioId, honorarioId, objeto)
        setTipoDocumento(campos.data);
      }

      if (tipoReembolsoId && tipoReembolsoId > 0 && moduloReembolsoSelecionado) {
        let objeto: any = {};
        eval(`objeto.${tipoPorModulo(moduloReembolsoSelecionado)}Id=${categoriaId}`);
        let campos = await ConfiguracaoTelaService.obterListaLimitadaReembolso(moduloReembolsoSelecionado, "TipoDocumento", tipoReembolsoId, reembolsoId, objeto, campo)
        setTipoDocumento(campos.data);
      }

      if (tipoAudienciaId && tipoAudienciaId > 0 && moduloAudienciaSelecionado) {
        let objeto: any = {};
        eval(`objeto.${tipoPorModulo(moduloAudienciaSelecionado)}Id=${categoriaId}`);
        let campos = await ConfiguracaoTelaService.obterListaLimitadaAudiencia(moduloAudienciaSelecionado, "TipoDocumento", tipoAudienciaId, audienciaId, objeto, campo)
        setTipoDocumento(campos.data);
      }

      if (empresaId && empresaId > 0) {
        let campos = await TipoDocumentoService.obterTipoDocumentos({
          nome: '',
          codigo: '',
          status: 0,
          offset: 0,
          limit: 1000,
          sort: 'tipoDocumentoId',
          totalItems: 0
        });
        setTipoDocumento(campos.data);

        const pastas = await PastaGedService.obterPastaGeds({
          nome: '',
          codigo: '',
          status: 1,
          limit: 10,
          totalItems: 0,
          offset: 0,
          sort: 'id'
        });

        setListaPasta(pastas.data)
      }

      setCarregandoTipoDocumento(false);

    } catch (error: any) {
      setTipoDocumento([]);
      setCarregandoTipoDocumento(false);
    }
  };

  useEffect(() => {

    formik.setValues(initialValues);

    formik.resetForm();

    if (editarDocumento.documentoId > 0) {
      formik.setValues({ ...editarDocumento, dataCadastro: new Date(editarDocumento.dataCadastro) });
    }

    carregaTipoDocumento();

  }, [exibirModalDocumentoEdit, editarDocumento]);

  return (
    <Modal size="lg" centered={false} show={exibirModalDocumentoEdit} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Editar documento - #{formik.values.documentoId.toString()} <FontAwesomeIcon className="mx-2 text-orange" icon={faHistory} />
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">

              {carregandoTipoDocumento ? (
                <>Carregando tipos de documento...</>
              ) : (
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    Tipo de Documento:
                  </label>
                  <select
                    {...formik.getFieldProps("tipoDocumentoId")}
                    value={formik.values.tipoDocumentoId}
                    onChange={(e) => {
                      formik.setFieldTouched("tipoDocumentoId", true);
                      formik.setFieldValue("tipoDocumentoId", parseInt(e.target.value));
                    }}
                    className={clsx(
                      "form-select",
                      {
                        "is-invalid": formik.touched.tipoDocumentoId && formik.errors.tipoDocumentoId,
                      },
                      {
                        "is-valid": formik.touched.tipoDocumentoId && !formik.errors.tipoDocumentoId,
                      }
                    )}
                    id="form-client"
                  >
                    <option value="0"> Selecione um tipo de documento </option>
                    {tipoDocumentos.map((map) => {
                      return (
                        <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                          {" "}
                          {map.nome}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {empresaId ? (
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-pasta" className="form-label fw-bolder text-orange">Pasta</label>
                  <select
                    {...formik.getFieldProps("pastaGedId")}
                    placeholder="Pasta"
                    onChange={(e) => {
                      formik.setFieldTouched("pastaGedId", true);
                      formik.setFieldValue("pastaGedId", parseInt(e.target.value));
                    }}
                    className={'form-select'}
                  >
                    <option value="0">Selecione uma pasta</option>
                    {listaPasta.map((pasta, index) => {
                      return (
                        <option key={index} value={pasta.pastaGedId} label={pasta.nome}> {pasta.nome} </option>
                      )
                    })}
                  </select>

                </div>
              ) : <></>}

              <div className="col-md-9 mt-3">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Descrição
                </label>
                <textarea
                  {...formik.getFieldProps("descricao")}
                  maxLength={500}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.descricao && formik.errors.descricao,
                    },
                    {
                      "is-valid": formik.touched.descricao && !formik.errors.descricao,
                    }
                  )}
                  id="form-nomeUsuario"
                />

                {formik.touched.descricao && formik.errors.descricao && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.descricao}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mt-6 mt-3">
                <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                  Ser Restrito?:{" "}
                  <FormCheckInput
                    type="checkbox"
                    style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                    checked={formik.values.restrito}
                    onChange={() => {
                      if (formik.values.restrito) formik.setValues({ ...formik.values, restrito: false });
                      if (!formik.values.restrito) formik.setValues({ ...formik.values, restrito: true });
                    }}
                  />
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={() => formik.submitForm()} disabled={!formik.isValid} type="button" className="btn btn-orange ms-5">
            Atualizar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadFieldEdit;
