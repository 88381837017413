import { Editor } from "../../components/Editor"
import { DocumentEditor } from "../../components/Editor/components/DocumentEditor"

const EditorPage = () => {
  return (
    <Editor>
      <DocumentEditor />
    </Editor>
  )
}

export default EditorPage