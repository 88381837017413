import api from "./Api";
import AtualizarEsferaRequest from "../interfaces/Requests/Esfera/AtualizarEsferaRequest";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IEsfera from "../interfaces/IEsfera";
import { AdicionaEsferaRequest } from "../interfaces/Requests/Esfera/AdicionaEsferaRequest";
import FiltroPesquisaEsferaRequest from "../interfaces/Requests/Esfera/FiltroPesquisaEsferaRequest";
import { EditarEsferaRequest } from "../interfaces/Requests/Esfera/EditarEsferaRequest";

class EsferaService {

    async obterEsferas(filtros: FiltroPesquisaEsferaRequest): Promise<IRecuperaLista<IEsfera>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IEsfera>>(`Esfera/RecuperaEsfera?${query}`);

        return result;
    }

    async adicionaEsfera(adicionaEsfera: AdicionaEsferaRequest): Promise<IEsfera> {
        let { data: result } = await api.post<IEsfera>(`Esfera/AdicionaEsfera`, adicionaEsfera);

        return result;
    }


    async alterarStatus(esferaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IEsfera>(`Esfera/AtualizaEsferaPorId?id=${esferaId}`, data);
    }

    async atualizarEsfera(editarEsfera: EditarEsferaRequest): Promise<void> {

        let dataEsfera = [
            { "op": "replace", "path": "/nome", "value": editarEsfera.nome },
            { "op": "replace", "path": "/codigo", "value": editarEsfera.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Esfera/AtualizaEsferaPorId?id=${editarEsfera.esferaId}`, dataEsfera)
        ]);
    }
}


export default new EsferaService();