import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import CamposCapaDinamicos from "./CamposCapaDinamicos";
import Carregando from "../../Carregando";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import { 
  IRecuperaConfiguracaoTelaResponse, 
  IRecuperaSeparadorTelaResponse 
} from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

interface ICollapseProps {
  formik: any;
  controller: string;
  setRefreshCapa(refresh: boolean): void;

  separadorTela: IRecuperaSeparadorTelaResponse;
  separadorTelaLista: IRecuperaSeparadorTelaResponse[];
  setSeparadorTelaLista(item: IRecuperaSeparadorTelaResponse[]): void;

  configuracoesTela: IRecuperaConfiguracaoTelaResponse[];
  setConfiguracoesTela(item: IRecuperaConfiguracaoTelaResponse[]): void;
}

const CollapseSeparador = ({
  formik,
  controller,
  setRefreshCapa,
  separadorTela,
  separadorTelaLista,
  setSeparadorTelaLista,
  configuracoesTela,
  setConfiguracoesTela
}: ICollapseProps) => {

  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [separadoresClicados, setSeparadoresClicados] = useState<number[]>([]);

  const uniqueCollapseId = `collapse-${separadorTela.separadorId}`;

  useEffect(() => { setConfiguracoesTela([]); }, []); // ajuste 

  const carregaCapa = async () => {
    try {
      setCarregandoConfiguracoesTela(true);

      const resultado = await ConfiguracaoTelaService.obterCapa(
        formik.values.principalId,
        formik.values.modulo,
        separadorTela.separadorId || 0
      );

      const novasConfiguracoes = resultado.data;
      const configuracoesAtualizadas: IRecuperaConfiguracaoTelaResponse[] = [...configuracoesTela];

      novasConfiguracoes.forEach((novaConfig) => {
        const index = configuracoesAtualizadas.findIndex(config => config.campo === novaConfig.campo);
        if (index !== -1) {
          configuracoesAtualizadas[index] = novaConfig;
        } else {
          configuracoesAtualizadas.push(novaConfig);
        }
      });

      // Função para reordenar os campos
      const reordenarCampos = (configs: IRecuperaConfiguracaoTelaResponse[]): IRecuperaConfiguracaoTelaResponse[] => {
        return configs.map((config, index) => ({
          ...config,
          ordenacao: index
        }));
      };

      const configuracoesReordenadas = reordenarCampos(configuracoesAtualizadas);

      setConfiguracoesTela(configuracoesReordenadas);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter campos`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };


  const renderCampos = () => {
    return configuracoesTela.filter((configuracaoTela: IRecuperaConfiguracaoTelaResponse) => configuracaoTela.separadorId === separadorTela.separadorId).map((configuracaoTela: IRecuperaConfiguracaoTelaResponse) => (
      <CamposCapaDinamicos
        key={configuracaoTela.campo}
        controller={controller}
        setRefreshCapa={setRefreshCapa}
        configuracaoTela={configuracaoTela}
        setConfiguracaoTela={setConfiguracoesTela}
        configuracaoTelaLista={configuracoesTela}
        formik={formik}
      />
    ));
  };

  const toggleCollapse = () => {
    if (separadorTela.separadorId) {
      if (!separadoresClicados.includes(separadorTela.separadorId)) {
        setSeparadoresClicados([...separadoresClicados, separadorTela.separadorId]);
        setSeparadorTelaLista(separadorTelaLista.map(separador => {
          if (separador.separadorId === separadorTela.separadorId) {
            if (separador.separadorId) {
              if (!separador.collpase) {
                carregaCapa();
              }
            }
            return { ...separador, collpase: !separador.collpase };
          }
          return separador;
        }));
      } else {
        setSeparadorTelaLista(separadorTelaLista.map(separador => {
          if (separador.separadorId === separadorTela.separadorId) {
            return { ...separador, collpase: !separador.collpase };
          }
          return separador;
        }));
      }
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{separadorTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  return (
    <>
      <div className="col-md-12 mt-2">
        <a data-bs-toggle="collapse" data-bs-target={`#${uniqueCollapseId}`} aria-expanded="false">
          <label
            onClick={toggleCollapse}
            style={{ cursor: "pointer" }}
            htmlFor="form-cliente"
            className="form-label fw-bolder text-orange"
          >
            {separadorTela.label}: {separadorTela.help && <PopoverHelper />}

            {!carregandoConfiguracoesTela && (
              <span style={{ display: "inline", marginLeft: "5px" }}>
                {separadorTela.collpase ? "-" : "+"}
              </span>
            )}
           
          </label>
        </a>
      </div>

      <div className={`collapse row`} id={uniqueCollapseId}>
        {carregandoConfiguracoesTela ? <Carregando /> : renderCampos()}
      </div>
      <hr />
    </>
  );
};

export default CollapseSeparador;
