import { useEffect, useState } from "react";
import ColumnMigration from "./ColumnMigration";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import ColunaSelecaoModal from "../modals/ColunaSelecaoModal";
import { optionsType } from "../../../../../components/Comum/Fornecedor/FornecedorField";
import Swal from "sweetalert2";

export type MigrationFieldsType = {
  propertyName: string;
  requestName: string;
  type: string;
  required: boolean;
  hasProperties: boolean;
};

export type DragDropProps = {
  colunas: MigrationFieldsType[];
  headers: optionsType[];
  updateCreateRequest: (
    key: string,
    value: number | string,
    remove?: boolean,
    objectNameProp?: boolean
  ) => void;
};

const DragDropTableMigration = ({
  colunas,
  headers,
  updateCreateRequest,
}: DragDropProps) => {
  const [columns, setColumns] = useState({
    "column-1": {
      id: "column-1",
      title: "Campos do Sistema",
      fieldIds: colunas.map((coluna) => coluna.requestName),
    },
    "column-2": {
      id: "column-2",
      title: "Campos Selecionados para a Migração",
      fieldIds: [],
    },
  });
  const [columnsOrder] = useState(["column-1", "column-2"]);
  const [exibirModal, setExibirModal] = useState(false);
  const [cancelar, setCancelar] = useState({ flag: false, draggableId: "" });
  const [fieldId, setFieldId] = useState("");

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      headers.find(
        (header) =>
          header.label === "Selecione uma sheet!" && header.value === 0
      )
    ) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: "Selecione uma sheet!",
        showConfirmButton: true,
      });

      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = columns[source.droppableId as keyof typeof columns];
    const finish = columns[destination.droppableId as keyof typeof columns];

    if (start === finish) {
      const newFieldIds = Array.from(start.fieldIds);
      newFieldIds.splice(source.index, 1);
      newFieldIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        fieldIds: newFieldIds,
      };

      setColumns((prev) => ({ ...prev, [newColumn.id]: newColumn }));

      return;
    }

    const startFieldIds = Array.from(start.fieldIds);
    startFieldIds.splice(source.index, 1);

    const newStart = {
      ...start,
      fieldIds: startFieldIds,
    };

    const finishFieldIds = Array.from(finish.fieldIds);
    finishFieldIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      fieldIds: finishFieldIds,
    };

    setColumns((prev) => ({
      ...prev,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    }));

    if (destination.droppableId === "column-2") {
      setExibirModal(true);
      setFieldId(draggableId);
    } else {
      updateCreateRequest(draggableId, "", true);
    }
  };

  const handleClose = () => {
    setExibirModal(false);
  };

  useEffect(() => {
    if (cancelar.flag) {
      handleDragEnd({
        destination: { droppableId: "column-1", index: 0 },
        source: { droppableId: "column-2", index: 0 },
        draggableId: cancelar.draggableId,
        reason: "DROP",
        combine: undefined,
        mode: "FLUID",
        type: "",
      });
    }
  }, [cancelar]);

  return (
    <>
      {exibirModal && (
        <ColunaSelecaoModal
          exibirModal={exibirModal}
          handleClose={handleClose}
          headers={headers}
          updateCreateRequest={updateCreateRequest}
          fieldId={fieldId}
          fields={colunas}
          setCancelar={setCancelar}
        />
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="d-flex gap-3">
          {columnsOrder.map((columnId) => {
            const column = columns[columnId as keyof typeof columns];

            const fields = column.fieldIds
              .map((fieldId) => {
                const found = colunas.find(
                  (coluna) => coluna.requestName === fieldId
                );

                return found;
              })
              .filter((field) => field != undefined) as MigrationFieldsType[];

            return (
              <ColumnMigration
                key={column.id}
                column={column}
                fields={fields}
              />
            );
          })}
        </div>
      </DragDropContext>
    </>
  );
};

export default DragDropTableMigration;
