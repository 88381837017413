/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEraser, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";


import ConsultaService from "../../services/ConsultaService";
import TipoConsultaService from "../../services/TipoConsultaService";
import StatusConsultaService from "../../services/StatusConsultaService";

import ITipoConsulta from "../../interfaces/ITipoConsulta";
import IStatusConsulta from "../../interfaces/IStatusConsulta";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";


import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";
import ConsultaFiltroModal from "./ConsultaFiltroModal";
import GridPadrao from "../../components/Comum/GridPadrao";
import "./style/icons.css";
import FiltroPesquisaConsultaRequest from "../../interfaces/Requests/Consulta/FiltroPesquisaConsultaRequest";
import IConsulta from "../../interfaces/IConsulta";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";
import AreaTextoFiltroField from "../../components/Comum/AreaTexto/AreaTextoFiltroField";
import { Button } from "react-bootstrap";

const filtroInicial: FiltroPesquisaConsultaRequest = {
  consultaId: 0,
  clienteId: 0,
  usuarioCadastroId: 0,

  tipoConsultaId: [],
  statusConsultaId: [],
  cidadeId: [],
  departamentoId: [],
  escritorioId: [],
  empresa: [],

  restrito: false,
  isParteContraria: false,

  codigo: "",
  observacoesConsulta: "",
  nomeSolicitante: "",
  emailSolicitante: "",
  parecerJuridico: "",
  tempoGasto: "",
  descricaoConsulta: "",
  parteContraria: "",

  dataFinalizacaoMaiorIgual: null,
  dataFinalizacaoMenorIgual: null,

  prazoFatalMaiorIgual: null,
  prazoFatalMenorIgual: null,

  dataCadastroMaiorIgual: null,
  dataCadastroMenorIgual: null,

  limit: 10,
  offset: 0,
  sort: "consultaId",
  totalItems: 0,
  modeloRelatorioId: 0
};


const ConsultaPage = () => {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaConsultaRequest>(filtroInicial);

  const [carregamentoTipoConsulta, setCarregamentoTipoConsulta] = useState<boolean>(false);
  const [tiposConsultaOptions, setTiposConsultaOptions] = useState<any>([]);
  const [tipoConsultaSelected, setTipoConsultaSelected] = useState([]);

  const [carregamentoStatusConsulta, setCarregamentoStatusConsulta] = useState<boolean>(false);
  const [StatusConsultaOptions, setStatusConsultaOptions] = useState<any>([]);
  const [StatusConsultaSelected, setStatusConsultaSelected] = useState([]);

  const { setTipoConsulta, setConsulta } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [consultas, setConsultas] = useState<IConsulta[]>([]);
  const [carregandoConsultas, setCarregandoConsultas] = useState(false);

  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);

  const componentRef = useRef<any>();

  useLayoutEffect(() => {
    carregarTipoConsulta();
    carregarStatusConsulta();
  }, []);

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  useEffect(() => {
    if (pesquisar) carregarConsultas(filtrosPesquisa);
  }, [pesquisar]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoConsultaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, tipoConsultaId: IdsSelected };
    });
  }, [tipoConsultaSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusConsultaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusConsultaId: IdsSelected };
    });
  }, [StatusConsultaSelected]);

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  const returnOptionsStatusConsulta = (array: any) => {
    array.forEach((item: any) => {
      StatusConsultaOptions.push({
        value: item.statusConsultaId,
        label: item.nome,
      });
    });
    return setStatusConsultaOptions;
  };

  const returnOptionsTiposConsulta = (array: any) => {
    array.forEach((item: any) => {
      tiposConsultaOptions.push({
        value: item.tipoConsultaId,
        label: item.nome,
      });
    });
    return setTiposConsultaOptions;
  };

  const carregarTipoConsulta = async (): Promise<void> => {
    try {
      setCarregamentoTipoConsulta(true);
      let resultadoTipoConsulta: IRecuperaLista<ITipoConsulta>;
      resultadoTipoConsulta = await TipoConsultaService.obterTipoConsultas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoConsultaId",
      });
      setTiposConsultaOptions(returnOptionsTiposConsulta(resultadoTipoConsulta.data));
      setCarregamentoTipoConsulta(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoConsulta(false);
      setTiposConsultaOptions([]);
    }
  }

  const carregarStatusConsulta = async (): Promise<void> => {
    try {
      setCarregamentoStatusConsulta(true);
      let resultado: IRecuperaLista<IStatusConsulta>;
      resultado = await StatusConsultaService.obterStatusConsultas({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusConsultaId",
      });
      setStatusConsultaOptions(returnOptionsStatusConsulta(resultado.data));
      setCarregamentoStatusConsulta(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoStatusConsulta(false);
      setStatusConsultaOptions([]);
    }
  }

  const colunas: TableColumn<IConsulta>[] = [
    {
      cell: (consulta: IConsulta) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setConsulta(consulta);
                  setTipoConsulta({
                    tipoConsultaId: consulta.tipoConsultaId,
                    nome: consulta.tipoConsultaNome,
                    codigo: "",
                    status: consulta.statusConsultaNome
                  });
                  navigate("/Consulta/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Consulta: #{consulta.consultaId.toString().padStart(5, "0")}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{consulta.statusConsultaNome}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {consulta.consultaId}
                {consulta.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(consulta.favoritoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(consulta.consultaId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Contrato: </b> {consulta.tipoConsultaNome}{" "}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Empresa: </b> {consulta.consultaEmpresas.map(empresa => `${empresa.nome} / `)}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Partes: </b> {consulta.consultaParteContraria.map(parte => `${parte.nome} / `)}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const adicionarFavorito = async (consultaId: number): Promise<void> => {
    try {
      await ConsultaService.adicionaFavorido(consultaId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Contrato adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarConsultas(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar contrato como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const removerFavorido = async (favoritoId: number): Promise<void> => {
    try {
      await ConsultaService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Contrato removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarConsultas(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IConsulta>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const carregarConsultas = async (filtro: FiltroPesquisaConsultaRequest) => {
    try {
      setCarregandoConsultas(true);

      let resultado: IRecuperaLista<IConsulta>;
      resultado = await ConsultaService.obterConsultas(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setConsultas(resultado.data);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoConsultas(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  const abrirKanban = () => {
    navigate('/Consulta/Kanban');
  }

  return (
    <>
      <LayoutPageTitle title="Consulta">
        <button onClick={() => navigate("/Consulta/Novo")} className="btn btn-md btn-orange">
          Nova <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <ConsultaFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <AreaTextoFiltroField
        toggleModal={toggleAreaTextoModal}
        exibirModal={exibirModalAreaTexto}
        field="descricaoConsulta"
        setFiltrosPesquisa={setFiltrosPesquisa}
        titulo="Descrição da Consulta"
      />

      <div className="row">
        <div className="col-md-12" >

          <CollapseFiltro titulo="Filtros" content={
            <form onSubmit={(e) => e.preventDefault()}
              className="row g-3 mb-3 form">
              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-tipoConsultaId" className="form-label fw-bolder text-orange">
                  Identificador:
                </label>
                <input
                  value={filtrosPesquisa.consultaId}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, consultaId: e.target.value };
                    });
                  }}
                  placeholder="ID"
                  type="number"
                  className={"form-control"}
                  id="form-tipoConsultaId"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-nomeSolicitante" className="form-label fw-bolder text-orange">
                  Nome do solicitante:
                </label>
                <input
                  value={filtrosPesquisa.nomeSolicitante}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nomeSolicitante: e.target.value };
                    });
                  }}
                  placeholder="Número"
                  type="text"
                  className={"form-control"}
                  id="form-nomeSolicitante"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-numero" className="form-label fw-bolder text-orange">
                  E-mail do solicitante:
                </label>
                <input
                  value={filtrosPesquisa.emailSolicitante}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, emailSolicitante: e.target.value };
                    });
                  }}
                  placeholder="E-mail"
                  type="email"
                  className={"form-control"}
                  id="form-numero"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Tipo de Consulta:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoConsulta}
                  disabled={carregamentoTipoConsulta}
                  options={tiposConsultaOptions}
                  value={tipoConsultaSelected}
                  onChange={(event: any) => setTipoConsultaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                  Parte:
                </label>
                <input
                  value={filtrosPesquisa.parteContraria}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, parteContraria: e.target.value };
                    });
                  }}
                  placeholder="Parte"
                  type="text"
                  className={"form-control"}
                  id="form-parteContraria"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4 mt-1">

                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  isLoading={carregamentoStatusConsulta}
                  disabled={carregamentoStatusConsulta}
                  options={StatusConsultaOptions}
                  value={StatusConsultaSelected}
                  onChange={(event: any) => setStatusConsultaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-4">
                <label htmlFor="form-descricaoConsulta" className="form-label fw-bolder text-orange">
                  Descrição da Consulta:
                </label>
                <div className="input-group">
                <textarea
                  value={filtrosPesquisa.descricaoConsulta}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, descricaoConsulta: e.target.value };
                    });
                  }}
                  placeholder="Descrição da Consulta"
                  className={"form-control"}
                  id="form-descricaoConsulta"
                />

                <Button
                  onClick={() => toggleAreaTextoModal()}
                  style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                  variant="secondary"
                  size="sm"
                >
                  <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
                </Button>
              </div>
              </div>

              <div className="col-12"
                style={{ textAlign: 'right' }}>
                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    setFiltrosPesquisa(filtroInicial);
                    setPesquisar(false);
                    setTipoConsultaSelected([]);
                    setStatusConsultaSelected([]);
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

              </div>
            </form>} />

          <div className="col-md-12 mb-10" style={{ overflow: !carregandoConsultas ? "auto" : "scroll", height: "600px" }}>

            <div ref={componentRef}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoConsultas}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Consulta"
                itens={consultas}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConsultaPage;

