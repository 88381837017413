export const htmlContent = `
<!DOCTYPE html>
<html lang="pt-BR">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Análise de Contrato - Dentello Advocacia</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            color: #333;
            line-height: 1.6;
            margin: 20px;
        }
        h1 {
            color: #ff6600;
        }
        h2 {
            color: #6600cc;
        }
        .section {
            margin-bottom: 20px;
        }
        .section p {
            margin: 10px 0;
        }
        .section ul {
            margin: 10px 0;
            padding-left: 20px;
        }
        .section ul li {
            margin: 5px 0;
        }
        .highlight {
            color: #ff6600;
            font-weight: bold;
        }
    </style>
</head>
<body>
    <h1>Análise do Contrato de Implantação Xjur</h1>

    <div class="section">
        <h2>Resumo do Contrato</h2>
        <p><span class="highlight">Partes:</span></p>
        <ul>
            <li><strong>Contratante:</strong> Karina Dentello Sociedade Individual de Advocacia ("Dentello Advocacia"), CNPJ: 25.218.418/0001-09, Avenida Papa Pio XII, 847, sala 17 e 13, Campinas/SP, CEP: 13.070-091</li>
            <li><strong>Contratada:</strong> Xjur Tecnologia LTDA ("Xjur"), CNPJ: 46.953.724/0001-50, Av. Paulista, 1636, Sala 103, São Paulo/SP, CEP: 01310-200</li>
        </ul>
        <p><span class="highlight">Vigência:</span> 24 meses a partir da assinatura, podendo ser prorrogado mediante termo aditivo.</p>
        <p><span class="highlight">Objeto:</span> Licença de Uso do software Xjur, incluindo liberação do ambiente e suporte técnico conforme descrito na proposta técnica e comercial Anexo I.</p>
    </div>

    <div class="section">
        <h2>Riscos para o Cliente</h2>
        <ul>
            <li><strong>Penalidades por Atraso:</strong> Juros de 10% ao mês e multa de 2% sobre o valor devido em caso de atraso no pagamento.</li>
            <li><strong>Suspensão dos Serviços:</strong> Após 90 dias de atraso no pagamento, a Contratada pode suspender a execução dos serviços.</li>
            <li><strong>Multas por Descumprimento de SLA:</strong> Multas não compensatórias de até 50% do valor da mensalidade por descumprimento dos acordos de nível de serviço.</li>
            <li><strong>Obrigações Adicionais:</strong> A Contratada pode cobrar por atividades não previstas no Anexo I mediante orçamento prévio aprovado pela Contratante.</li>
        </ul>
    </div>

    <div class="section">
        <h2>Itens Divergentes</h2>
        <ul>
            <li><strong>Cláusula de Exclusividade:</strong> A utilização do software por dispositivos eletrônicos (robôs) é proibida sem a prévia concordância da Contratada.</li>
            <li><strong>Responsabilidade por Tributos:</strong> Cada Parte é responsável pelo pagamento de seus próprios tributos decorrentes da assinatura do contrato.</li>
            <li><strong>Obrigações de Suporte:</strong> O suporte técnico deve ser realizado conforme as condições previstas no Anexo I, podendo gerar custos adicionais para atividades não descritas.</li>
        </ul>
    </div>

    <div class="section">
        <h2>Providências Recomendadas</h2>
        <ul>
            <li><strong>Revisar Periodicamente:</strong> Monitorar os pagamentos para evitar atrasos e consequentes penalidades.</li>
            <li><strong>Negociar Ajustes:</strong> Em caso de alterações no volume de serviços, renegociar os valores com a Contratada para evitar gastos adicionais inesperados.</li>
            <li><strong>Documentar Comunicações:</strong> Garantir que todas as comunicações importantes sejam feitas por escrito e devidamente documentadas.</li>
            <li><strong>Solicitar Clarificações:</strong> Esclarecer quaisquer dúvidas sobre as quantidades e valores cobrados para evitar divergências futuras.</li>
            <li><strong>Monitorar SLA:</strong> Acompanhar o cumprimento dos níveis de serviço (SLA) acordados para garantir a qualidade dos serviços prestados.</li>
        </ul>
    </div>
</body>
</html>
`;
