import styled, { css } from "styled-components";
import { defaultColors } from "./colors";
import { MdBlock } from "react-icons/md";
import { useState } from "react";
import { Editor, Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import { getNodeBySelection } from "../../Toolbar/controls/common";
import { CustomText } from "../../../../../utils/types/slate";
import { Text } from "slate";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;

  > button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 500;
    border-radius: 4px;
    transition: 0.4s;

    > svg {
      margin-right: 8px;
      font-size: 20px;
    }

    &:hover {
      background: #f2f2fa;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 4px;
`;

export const ColorSelector = styled.button<{
  bgColor: string;
  active: boolean;
}>`
  background: ${(props) => props.bgColor};
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: none;
  border: 1px solid #ddd;

  ${(props) =>
    props.active &&
    css`
    opacity  0.6:;
    border: 2px solid blue;
  `}
`;

type FontColorProps = {
  caller: "text-color" | "background-color";
};

export const FontColor = ({ caller }: FontColorProps) => {
  const editor = useSlateStatic();
  const [selectedColor, setSelectedColor] = useState("#000");

  const selectColor = (hex: string) => {
    setSelectedColor(hex);

    const { marks } = Editor.marks(editor) as CustomText;

    const key = caller === 'background-color' ? 'highlight' : 'textColor'

    Transforms.setNodes(
      editor,
      { marks: { ...marks, [key]: hex } },
      {
        match: (node) => Text.isText(node),
        split: true,
      }
    );
    return;
  };

  const resetColor = () => {
    if (caller === 'background-color') {
      selectColor('transparent')
      return
    }

    selectColor('#000')
  }

  return (
    <Container>
      <button onClick={resetColor}>
        <MdBlock />
        Nenhum
      </button>
      <Content>
        {defaultColors.map((color) => (
          <ColorSelector
            onClick={() => selectColor(color.hex)}
            active={selectedColor === color.hex}
            type="button"
            key={color.id}
            bgColor={color.hex}
          />
        ))}
      </Content>
    </Container>
  );
};
