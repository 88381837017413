import { faEraser, faPen, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect, useState } from "react"
import { TableColumn } from "react-data-table-component"
import { MultiSelect } from "react-multi-select-component"
import { useNavigate } from "react-router-dom"
import MaskedInput from "react-text-mask"
import Swal from "sweetalert2"
import GridPadrao from "../../../../components/Comum/GridPadrao"
import { OrangeContext } from "../../../../contexts/OrangeProvider"
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext"
import IEmpresa, { IEmpresaListagem } from "../../../../interfaces/IEmpresa"
import IRecuperaLista from "../../../../interfaces/IRecuperaLista"
import FiltroPesquisaEmpresaRequest from "../../../../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest"
import EmpresaService from "../../../../services/EmpresaService"
import { EmpresaSolucaoLayout } from "../../componentes/EmpresaSolucaoLayout"


export const FiliaisPage = () => {
  const { empresa, setEmpresa } = useContext<OrangeInterfaceContext>(OrangeContext)
  const [carregandoEmpresas, setCarregandoEmpresas] = useState(false);
  const [Empresas, setEmpresas] = useState<IEmpresaListagem[]>([]);
  const [pesquisar, setPesquisar] = useState(false);
  const [StatusEmpresaSelected, setStatusEmpresaSelected] = useState([]);

  const navigate = useNavigate();

  const filtroInicial: FiltroPesquisaEmpresaRequest = {
    statusEmpresa: 0,
    empresaMatrizId: empresa.empresaId,
    limit: 10,
    offset: 0,
    sort: "empresaId",
    totalItems: 0,
    modeloRelatorioId: 0,
  };

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEmpresaRequest>(filtroInicial);

  async function carregarEmpresas() {
    try {
      setCarregandoEmpresas(true);
      let resultado: IRecuperaLista<IEmpresaListagem>;
      resultado = await EmpresaService.obterEmpresasListagem(filtrosPesquisa);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setEmpresas(resultado.data);
      setCarregandoEmpresas(false);
      setPesquisar(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setEmpresas([]);
    } finally {
      setCarregandoEmpresas(false);
      setPesquisar(false);
    }
  }

  const colunas: TableColumn<IEmpresa>[] = [
    {
      name: 'Id',
      sortField: 'empresaId',
      selector: (row) => row.empresaId,
      sortable: true,
      ignoreRowClick: true,
    },
    {
      name: 'Nome',
      sortField: 'nomeFantasia',
      selector: (row) => row.nomeFantasia,
      sortable: true,
      ignoreRowClick: true,
    },
    {
      name: 'CNPJ',
      sortField: 'cnpj',
      selector: (row) => row.numeroDocumento,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Status",
      sortField: "statusEmpresa",
      selector: (row) => row.statusEmpresa ? row.statusEmpresa : '',
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: 'Opções',
      cell: (row) => {
        return (
          <div>
            <FontAwesomeIcon
              title={`Editar`}
              onClick={() => {
                setEmpresa(row)
                navigate("/Societario/Empresa/Capa");
                // setItem(row);
                // modalActions.handleOpen()
              }}
              style={{ fontWeight: 'normal', cursor: 'pointer' }}
              size='2x'
              className='mx-2 text-orange'
              icon={faPen}
            />
            <FontAwesomeIcon
              title={'Exluir'}
              onClick={() => {
                // handleActions(row, 'delete');
              }}
              style={{ fontWeight: 'normal', cursor: 'pointer' }}
              size='2x'
              className='mx-2 text-orange'
              icon={faTrash}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    }
  ]



  useEffect(() => {
    if (pesquisar) {
      carregarEmpresas();
      setPesquisar(false)
    }
  }, [pesquisar]);

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
    setPesquisar(true);
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
    setPesquisar(true);
  };

  const handleSort = async (column: TableColumn<IEmpresaListagem>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
    setPesquisar(true);
  };

  const limpaFiltros = () => {
    setFiltrosPesquisa(filtroInicial);
  }

  return (
    <>
      <EmpresaSolucaoLayout title="Filiais">
        <div className="row mt-2">
          <div className="col-md-12" >

            <form onSubmit={(e) => e.preventDefault()} className="row g-3 mb-3">

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-razaoSocial" className="form-label fw-bolder text-orange">
                  Razão Social:
                </label>
                <input
                  value={filtrosPesquisa.razaoSocial}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, razaoSocial: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-razaoSocial"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-razaoSocial" className="form-label fw-bolder text-orange">
                  CNPJ:
                </label>
                <MaskedInput
                  value={filtrosPesquisa.cnpj}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, cnpj: e.target.value };
                    });
                  }
                  }
                  mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  placeholder="CNPJ"
                  type="text"
                  className={"form-control"}
                  id="form-cnpj"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-nomeFantasia" className="form-label fw-bolder text-orange">
                  Nome Fantasia:
                </label>
                <input
                  value={filtrosPesquisa.nomeFantasia}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nomeFantasia: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-nomeFantasia"
                />
              </div>


              <div className="col-12 col-lg-4 col-xl-4">
                <label className="form-label fw-bolder text-orange" htmlFor="form-inscricaoEstadual">
                  Inscrição Estadual
                </label>
                <input
                  id="form-inscricaoEstadual"
                  className={"form-control"}
                  placeholder="Inscrição Estadual"
                  onChange={(e) => { setFiltrosPesquisa(old => { return { ...old, inscricaoEstadual: e.target.value } }) }}
                  value={filtrosPesquisa.inscricaoEstadual}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label className="form-label fw-bolder text-orange" htmlFor="form-inscricaoMunicipal">
                  Inscrição Municipal
                </label>
                <input
                  id="form-inscricaoMunicipal"
                  className={"form-control"}
                  placeholder="Inscrição Municipal"
                  onChange={(e) => { setFiltrosPesquisa(old => { return { ...old, inscricaoMunicipal: e.target.value } }) }}
                  value={filtrosPesquisa.inscricaoMunicipal}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  options={[
                    { value: 0, label: "Nula" },
                    { value: 1, label: "Ativa" },
                    { value: 3, label: "Suspensa" },
                    { value: 4, label: "Inapta" },
                    { value: 5, label: "Baixada" },
                  ]
                  }
                  value={StatusEmpresaSelected}
                  onChange={(event: any) => setStatusEmpresaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12 text-end">

                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    limpaFiltros()
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>

              </div>
            </form>

            <div className="col-md-12 mb-10" style={{ overflow: !carregandoEmpresas ? "auto" : "scroll", height: "600px" }}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoEmpresas}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Cliente"
                itens={Empresas}
              />
            </div>

          </div>
        </div>
      </EmpresaSolucaoLayout>
    </>
  )
}