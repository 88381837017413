import styled from "styled-components";

export const Container = styled.div`
  width: 400px;
  overflow-y: auto;
  max-height: 100%;

  div.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;
  }

  div.content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const VersionCard = styled.div<{ selected: boolean }>`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border-bottom: ${(props) =>
    props.selected ? "3px solid var(--bs-success)" : "1px solid #eee"};

  display: flex;
  gap: 12px;

  > * {
    width: 100%;
  }

  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);

  > div.icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  > div.title {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > span {
      font-size: 12px;
    }

    > * {
      padding: 0;
      margin: 0;
    }
  }
`;
