import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPublicacaoAto from "../interfaces/IPublicacaoAto";
import { AdicionaPublicacaoAtoRequest } from "../interfaces/Requests/PublicacaoAto/AdicionaPublicacaoAtoRequest";
import FiltroPesquisaPublicacaoAtoRequest from "../interfaces/Requests/PublicacaoAto/FiltroPesquisaPublicacaoAtoRequest";
import { EditarPublicacaoAtoRequest } from "../interfaces/Requests/PublicacaoAto/EditarPublicacaoAtoRequest";

class PublicacaoAtoService {

    async obterPublicacaoAtos(filtros: FiltroPesquisaPublicacaoAtoRequest): Promise<IRecuperaLista<IPublicacaoAto>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IPublicacaoAto>>(`PublicacaoAto/RecuperaPublicacaoAto?${query}`);

        return result;
    }

    async adicionaPublicacaoAto(adicionaPublicacaoAto: AdicionaPublicacaoAtoRequest): Promise<IPublicacaoAto> {
        let { data: result } = await api.post<IPublicacaoAto>(`PublicacaoAto/AdicionaPublicacaoAto`, adicionaPublicacaoAto);

        return result;
    }


    async alterarStatus(publicacaoAtoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPublicacaoAto>(`PublicacaoAto/AtualizaPublicacaoAtoPorId?id=${publicacaoAtoId}`, data);
    }

    async atualizarPublicacaoAto(editarPublicacaoAto: EditarPublicacaoAtoRequest): Promise<void> {

        let dataPublicacaoAto = [
            { "op": "replace", "path": "/nome", "value": editarPublicacaoAto.nome },
            { "op": "replace", "path": "/codigo", "value": editarPublicacaoAto.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`PublicacaoAto/AtualizaPublicacaoAtoPorId?id=${editarPublicacaoAto.publicacaoAtoId}`, dataPublicacaoAto)
        ]);
    }
}


export default new PublicacaoAtoService();