import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { EModulo } from "../../enum";
import Spinner from "../Spinner";

interface ICollapseFiltroProps {
  content: any;
  modulo: EModulo;
  exibir: boolean;
  setExibir(exibir: boolean): void;
  titulo: string;
  help?: string;
  carregando: boolean
}

const CollapseDefault = ({ content, exibir, setExibir, help, titulo, carregando, modulo }: ICollapseFiltroProps) => {

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body> {help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const uniqueCollapseId = `collapse-${modulo}`;

  return (
    <>
      <div className="col-md-12">
        <a data-bs-toggle="collapse" data-bs-target={`#${uniqueCollapseId}`} aria-expanded="false">
          <label
            onClick={() => setExibir(!exibir)}
            style={{ cursor: "pointer" }}
            htmlFor="form-cliente"
            className="form-label fw-bolder text-orange"
          >
            {titulo}

            {!carregando && (
              <span style={{ display: "inline", marginLeft: "5px" }}>
                {exibir ? "-" : "+"}
              </span>
            )}

            {carregando && (
              <Spinner />
            )}
          </label>{help && <PopoverHelper />}
        </a>
      </div>
      <div className={`collapse row`} id={`${uniqueCollapseId}`}>
        {content}
      </div>
    </>
  );
};
export default CollapseDefault;
