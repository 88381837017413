import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusObrigacao from "../interfaces/IStatusObrigacao";
import { AdicionaStatusObrigacaoRequest } from "../interfaces/Requests/StatusObrigacao/AdicionaStatusObrigacaoRequest";
import FiltroPesquisaStatusObrigacaoRequest from "../interfaces/Requests/StatusObrigacao/FiltroPesquisaStatusObrigacaoRequest";
import { EditarStatusObrigacaoRequest } from "../interfaces/Requests/StatusObrigacao/EditarStatusObrigacaoRequest";

class StatusObrigacaoService {

    async obterStatusObrigacoes(filtros: FiltroPesquisaStatusObrigacaoRequest): Promise<IRecuperaLista<IStatusObrigacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusObrigacao>>(`StatusObrigacao/RecuperaStatusObrigacao?${query}`);

        return result;
    }

    async adicionaStatusObrigacao(adicionaStatusObrigacao: AdicionaStatusObrigacaoRequest): Promise<IStatusObrigacao> {
        let { data: result } = await api.post<IStatusObrigacao>(`StatusObrigacao/AdicionaStatusObrigacao`, adicionaStatusObrigacao);

        return result;
    }


    async alterarStatus(statusObrigacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusObrigacao>(`StatusObrigacao/AtualizaStatusObrigacaoPorId?id=${statusObrigacaoId}`, data);
    }

    async atualizarStatusObrigacao(editarStatusObrigacao: EditarStatusObrigacaoRequest): Promise<void> {

        let dataStatusObrigacao = [
            { "op": "replace", "path": "/nome", "value": editarStatusObrigacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusObrigacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusObrigacao/AtualizaStatusObrigacaoPorId?id=${editarStatusObrigacao.statusObrigacaoId}`, dataStatusObrigacao)
        ]);
    }
}


export default new StatusObrigacaoService();