import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { OrangeContext } from "../../contexts/OrangeProvider";
import FileUploadCheckListField from "./IFileUploadCheckField";

interface IFileCheckListFieldProps {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
}

const IFileCheckListField = ({
  configuracaoTela,
  formik
}: IFileCheckListFieldProps) => {
  const {
    procuracao,
    tipoProcuracao,
    contrato,
    tipoContrato,
    imovel,
    tipoImovel,
    consulta,
    tipoConsulta } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  useEffect(() => { formik.setFieldValue("quantidadeDocumentosCheckList", quantidadeArquivosSelecionados) }, [quantidadeArquivosSelecionados]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12 mt-3">
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}{configuracaoTela.obrigatorio ? "*" : ""}:
          </label>
          <br></br>
          <Button
            onClick={() => setExibirModalImportacaoArquivos(true)}
            style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
            variant="secondary"
            size="sm"
          >
            <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
            Selecione os arquivos
          </Button>
        </div>
        <FileUploadCheckListField
          setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
          exibirModal={exibirModalImportacaoArquivos}
          toggleModal={toggleImportacaoArquivos}

          modulo={formik.values.modulo}

          contratoId={contrato.contratoId}
          tipoContratoId={tipoContrato.tipoContratoId}

          procuracaoId={procuracao.procuracaoId}
          tipoProcuracaoId={tipoProcuracao.tipoProcuracaoId}

          imovelId={imovel.imovelId}
          tipoImovelId={tipoImovel.tipoImovelId}

          consultaId={consulta.consultaId}
          tipoConsultaId={tipoConsulta.tipoConsultaId}

          campo={configuracaoTela.campo}
        />
      </div>
      <div className="row mt-2">
        <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
          {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
          {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
          {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
        </a>
      </div>
    </>
  );
};

export default IFileCheckListField;
