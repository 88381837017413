import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import RecuperaPessoa from "../../components/Comum/Pessoa/RecuperaPessoa";
import { IPessoa } from "../../interfaces/IPessoa";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import ClienteService from "../../services/ClienteService";
import EscritorioSelected from "../../components/Comum/EscritorioSelected";
import AreaDireitoSelected from "../../components/Comum/AreaDireitoSelected";
import AdicionaPessoa from "../../components/Comum/Pessoa/AdicionaPessoa";
import { AdicionarClienteRequest } from "../../interfaces/Requests/Cliente/AdicionaClienteRequest";
import Spinner from "../../components/Spinner";

function ClienteCadastroPage() {
  const [pessoa, setPessoa] = useState<IPessoa>();
  const [tipoDocumento, setTipoDocumento] = useState<string>("");
  const [numeroDocumento, setNumeroDocumento] = useState<string>("");
  const navigate = useNavigate();

  const handlerPessoa = (newPessoa: IPessoa) => setPessoa(newPessoa);

  const handlerTipo = (newTipo: string) => setTipoDocumento(newTipo);

  const handlerNumeroDocumento = (newNumeroDocumento: string) => setNumeroDocumento(newNumeroDocumento);

  const adicionaClienteRequest = Yup.object().shape({
    pessoaId: Yup.number().min(1).required("Cliente é obrigatório."),
    codigo: Yup.string().max(50, "Maximo 50 caracteres."),
    urlLogo: Yup.string().min(5, "Mínimo 5 caracteres.").required("URL é obrigatória.")
  });

  const initialValues: AdicionarClienteRequest = {
    pessoaId: 0,
    clienteNome: pessoa?.nome || "",
    clienteNomeSocial: pessoa?.nomeSocial || "",
    escritorios: [],
    areasDireito: [],
    codigo: "",
    urlLogo: "",
    status: 1,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaClienteRequest,
    onSubmit: async (values) => {
      try {
        await ClienteService.adicionaCliente(values);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Cliente cadastrado com sucesso`,
          timer: 4000,
        });

        navigate("/Cliente");
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar Cliente`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
      }
    },
  });

  useEffect(() => {
    if (pessoa) {
      formik.setFieldValue("pessoaId", pessoa.pessoaId);
    }

  }, [pessoa]);

  console.log(formik.values.areasDireito)

  return (
    <>
      <LayoutPageTitle title="Cadastrar Cliente" />

      <div className="row mt-2">
        <div className="col-md-12">
          {!pessoa && <RecuperaPessoa handlerTipo={handlerTipo} handlerNumeroDocumento={handlerNumeroDocumento} handlerPessoa={handlerPessoa} />}
          {pessoa && pessoa.pessoaId === 0 && (
            <>
              <AdicionaPessoa tipoDocumento={tipoDocumento} numeroDocumento={numeroDocumento} hrefCancelar="/Cliente" handlerPessoa={handlerPessoa} />
            </>
          )}
          {pessoa && pessoa.pessoaId > 0 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
              className="mb-3"
            >
              <div className="row mt-2">
                <div className="col-md-4 mb-3">
                  <label htmlFor="form-codigo" className="form-label required text-orange fw-bolder">
                    Código
                  </label>
                  <input
                    {...formik.getFieldProps("codigo")}
                    placeholder="Código"
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.codigo && formik.errors.codigo,
                      },
                      {
                        "is-valid": formik.touched.codigo && !formik.errors.codigo,
                      }
                    )}
                    id="form-codigo"
                  />
                  {formik.touched.codigo && formik.errors.codigo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.codigo}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="form-urlLogo" className="form-label required text-orange fw-bolder">
                    URL Logo
                  </label>
                  <input
                    {...formik.getFieldProps("urlLogo")}
                    placeholder="URL"
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.urlLogo && formik.errors.urlLogo,
                      },
                      {
                        "is-valid": formik.touched.urlLogo && !formik.errors.urlLogo,
                      }
                    )}
                    id="form-urlLogo"
                  />
                  {formik.touched.urlLogo && formik.errors.urlLogo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.urlLogo}</span>
                      </div>
                    </div>
                  )}
                </div>

              </div>

              <div className="row mt-3">
                <div className="col-md-4 mb-5">
                  <label htmlFor="form-clienteNome" className="form-label required text-orange fw-bolder">
                    Nome Pessoa
                  </label>
                  <input
                    {...formik.getFieldProps("clienteNome")}
                    value={pessoa.nome}
                    disabled
                    placeholder="Nome Cliente"
                    type="text"
                    className={"form-control"}
                  ></input>
                </div>
                <div className="col-md-4 mb-5">
                  <label htmlFor="form-clienteNomeSocial" className="form-label required text-orange fw-bolder">
                    Nome Social
                  </label>
                  <input
                    {...formik.getFieldProps("clienteNomeSocial")}
                    placeholder="Nome Social"
                    disabled
                    type="clienteNomeSocial"
                    value={pessoa.nomeSocial}
                    className={"form-control"}
                  ></input>
                </div>
              </div>


              <label htmlFor="form-clienteNome" className="form-label required text-orange fw-bolder">
                Selecione os escritórios:
              </label>
              <EscritorioSelected clienteId={0} setFieldValue={formik.setFieldValue} escritoriosSelected={formik.values.escritorios} />

              <label htmlFor="form-clienteNome" className="form-label required text-orange fw-bolder">
                Selecione as aréas:
              </label>
              <AreaDireitoSelected setFieldValue={formik.setFieldValue} areasDireitoSelected={formik.values.areasDireito} />

              <div className="col-md-3 mt-5">

                <button
                  disabled={!formik.isValid || formik.values.areasDireito.length === 0 || formik.values.escritorios.length === 0}
                  type="submit"
                  className="btn btn-orange"
                >
                  {!formik.isSubmitting && <> Salvar </>}
                  {formik.isSubmitting && (
                    <Spinner />
                  )}
                </button>

                <button
                  onClick={() => {
                    navigate("/Cliente");
                  }}
                  disabled={formik.isSubmitting}
                  type="button"
                  className="btn btn-danger float-end"
                >
                  Cancelar
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default ClienteCadastroPage;
