import { IAtoSocietario } from "../interfaces/AtoSocietario";
import IAtividadeEmpresa, { IEmpresaAtividadesEmpresaRequest } from '../interfaces/IAtividadeEmpresa';

import IOption from "../interfaces/IOption";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IRepresentante from "../interfaces/IRepresentante";
import IEmpresa, { IEmpresaListagem, IEmpresaRequest } from "../interfaces/IEmpresa";
import FiltroPesquisaEmpresaRequest from "../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { camelizar } from "../pages/ConfiguracaoTelaPage/Empresa/utils";
import { IRepresentanteEmpresa, ISocioAdministrador } from "../pages/SocietarioPage/EmpresaPage/Solucoes/Interfaces";
import api from "./Api";


class EmpresaService {

  async adicionarFavorito(empresaId: number) {
    let { data: result } = await api.post('/Favorito/AdicionaFavorito', { modulo: 5, empresaId: empresaId });
    return result;
  }

  async removerFavorito(favoritoId: number) {
    return await api.delete(`/Favorito/RemoveFavorito?favoritoId=${favoritoId}`)
  }

  async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  async obterEmpresasListagem(filtro: FiltroPesquisaEmpresaRequest): Promise<IRecuperaLista<IEmpresaListagem>> {
    let query = '';
    let params: string[] = [];

    let chaves = Object.keys(filtro) as Array<keyof FiltroPesquisaEmpresaRequest>;
    let valores = Object.values(filtro) as Array<FiltroPesquisaEmpresaRequest[keyof FiltroPesquisaEmpresaRequest]>;

    for (let i = 0; i < chaves.length; i++) {
      if (chaves[i].endsWith('Id')) {
        if (valores[i] !== undefined && (valores[i] as []).length > 0) {
          (valores[i] as number[]).forEach(v => {
            params.push(`${chaves[i]}=${v}`);
          });
        } else {
          params.push(`${chaves[i]}=${valores[i]}`);
        }
      } else if (
        valores[i] !== undefined &&
        valores[i] !== null &&
        (typeof (valores[i]) !== 'string' || (valores[i] as string).length > 0)

      ) {
        if (chaves[i] === 'statusEmpresa' && valores[i] as number === -1) {
          continue;
        }

        if (chaves[i] === 'statusEmpresa') {
          if (valores[i] !== undefined && (valores[i] as []).length > 0) {
            (valores[i] as number[]).forEach(v => {
              params.push(`${chaves[i]}=${v}`);
            });
          }
        } else {
          params.push(`${chaves[i]}=${valores[i]}`);
        }
      }
    }

    query = params.join('&');

    let { data: result } = await api.get<IRecuperaLista<IEmpresaListagem>>(`Empresa/RecuperaListagemEmpresa?${query}`);
    return result;
  }

  async obterEmpresa(filtros: any): Promise<IRecuperaLista<IEmpresa>> {
    let query = `${filtros.statusEmpresa === 0 ? "&statusEmpresa=1" : "&statusEmpresa=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.tipoPesquisa === 1 && filtros.filtro.length > 0) {
      query += `&razaoSocial=${encodeURIComponent(filtros.filtro)}`;
    }

    if (filtros.tipoPesquisa === 2 && filtros.filtro.length > 0) {
      query += `&codigo=${encodeURIComponent(filtros.filtro)}`;
    }

    if (Array.isArray(filtros.grupoEconomicoId) && filtros.grupoEconomicoId.length > 0) {
      filtros.grupoEconomicoId.forEach((id: any) => {
        query += `&grupoEconomicoId=${encodeURIComponent(id)}`;
      });
    }

    let { data: result } = await api.get<IRecuperaLista<IEmpresa>>(`Empresa/RecuperaEmpresa?${query}`);

    return result;
  }

  async obterEmpresaPorGrupoEconomico(grupoEconomicoId: IOption[]): Promise<IRecuperaLista<IEmpresa>> {
    let query = `${`statusEmpresa=1&offset=0&limit=1000&sort=nome`}`;

    if (grupoEconomicoId?.length > 0) grupoEconomicoId.forEach((option: IOption) => query += `&grupoEconomicoId=${option.value}`);

    let { data: result } = await api.get<IRecuperaLista<IEmpresa>>(`Empresa/RecuperaEmpresa?${query}`);

    return result;
  }


  async obterFiliais(filtros?: any): Promise<IRecuperaLista<IEmpresa>> {

    let query = `${filtros.statusEmpresa === 0 ? "&statusEmpresa=1" : "&statusEmpresa=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort
      }`;

    if (filtros.tipoPesquisa === 1 && filtros.filtro.length > 0) {
      query += `&razaoSocial=${filtros.filtro}`;
    }

    if (filtros.tipoPesquisa === 2 && filtros.filtro.length > 0) {
      query += `&codigo=${filtros.filtro}`;
    }

    if (filtros.tipoPesquisa === 2 && filtros.filtro.length > 0) {
      query += `&codigo=${filtros.filtro}`;
    }

    if (filtros.empresaMatrizId) {
      query += `&empresaMatrizId=${filtros.empresaMatrizId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<IEmpresa>>(`/Empresa/RecuperaListagemEmpresa?${query}&limit=10&offset=0&sort=empresaId&totalItems=0`);

    return result;
  }

  async obterSocioAdministrador(empresaId: number): Promise<IRecuperaLista<any>> {

    let { data: result } = await api.get<IRecuperaLista<any>>(`SocioAdministrador/RecuperaSocioAdministrador?empresaId=${empresaId}`);

    return result;
  }

  async obterCapaEmpresa(empresaId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Empresa/RecuperaCapaEmpresa?empresaId=${empresaId}`
    );
    return result;
  }

  async obterDadosEmpresa(empresaId: number): Promise<IRecuperaLista<IEmpresa>> {
    let { data: result } = await api.get<IRecuperaLista<IEmpresa>>(
      `/Empresa/RecuperaEmpresa?empresaId=${empresaId}`
    );
    return result;
  }

  async atualizaEmpresaPorId(campo: string, valor: any, empresaId: number,): Promise<any> {
    let data = [
      { op: "replace", path: "/" + campo, value: valor }
    ]
    await api.patch(`/Empresa/AtualizaEmpresaPorId?id=${empresaId}`, data);
  }

  async atualizaEmpresaPorCampo(campo: string, valor: any, empresaId: number,): Promise<any> {

    if (camelizar(campo) === 'atividadesEmpresa') {
      if (valor) {
        const atividadesEmpresaRequest: IEmpresaAtividadesEmpresaRequest = {
          empresaId,
          empresasAtividadesEmpresa: valor.map(
            (atividades: IAtividadeEmpresa, index: number) => {
              return {
                atividadeEmpresaId: atividades.atividadeEmpresaId,
                ordem: index
              }
            })
        }

        let { data: result } = await api.put(`/Empresa/AtualizaAtividadesEmpresa`, atividadesEmpresaRequest);

        return result

      }

    } else {

      if (camelizar(campo) === 'statusEmpresa') {
        campo = 'status'
      }

      let data = [
        { op: "replace", path: "/" + campo, value: valor }
      ]
      await api.patch(`/Empresa/AtualizaEmpresaPorId?id=${empresaId}`, data);
    }

  }

  async adicionaRepresentante(empresaId: number, representante: IRepresentante) {
    let { data: result } = await api.post(`/Empresa/AdicionaRepresentante?empresaId=${empresaId}`, { ...representante });
    return result;
  }

  async removeRepresentante(representanteId: number) {
    let { data: result } = await api.delete(`/Empresa/RemoveRepresentantePorId?id=${representanteId}`);
    return result;
  }

  async alterarRepresentante(representante: IRepresentanteEmpresa): Promise<void> {
    let data = [
      { op: "replace", path: "/email", value: representante.email },
      { op: "replace", path: "/dataEntrada", value: representante.dataEntrada },
      { op: "replace", path: "/dataVencimentoMandato", value: representante.dataVencimentoMandato },
      { op: "replace", path: "/dataProximaReeleicao", value: representante.dataProximaReeleicao },
      { op: "replace", path: "/dataSaida", value: representante.dataSaida },
      { op: "replace", path: "/qualificacaoId", value: representante.qualificacaoId },
      { op: "replace", path: "/observacaoRepresentante", value: representante.observacaoRepresentante },
      { op: "replace", path: "/podeAssinar", value: representante.podeAssinar },
      { op: "replace", path: "/status", value: representante.status },
      { op: "replace", path: "/codigo", value: representante.codigo }
    ];
    await api.patch<IRepresentanteEmpresa>(`/Empresa/AtualizaRepresentantePorId?id=${representante.representanteId}`, data);
  }

  async alteraAtividadesEmpresa(atividadesEmpresas: IEmpresaAtividadesEmpresaRequest): Promise<any> {
    let { data: result } = await api.put(`/Empresa/AtualizaAtividadesEmpresa`, atividadesEmpresas);
    return result
  }

  async obterAtividadesEmpresa(empresaId: number): Promise<IAtividadeEmpresa[]> {
    const { data: result } = await api.get(`/Empresa/RecuperaAtividadesEmpresasPorId?empresaId=${empresaId}`);
    return result
  }

  async adicionaSocioAdministrador(empresaId: number, socioAdministrador: ISocioAdministrador): Promise<any> {
    let { data: result } = await api.post(`/Empresa/AdicionaSocioAdministrador?empresaId=${empresaId}`, { ...socioAdministrador });
    return result;
  }

  async removeSocioAdministrador(socioAdministradorId: number) {
    const { data: result } = await api.delete(`/Empresa/RemoveSocioAdministradorPorId?id=${socioAdministradorId}`);
    return result;
  }

  async alteraSocioAdministrador(socioAdiministrador: ISocioAdministrador) {
    const data = [
      { op: "replace", path: "/nome", value: socioAdiministrador.nome },
      { op: "replace", path: "/dataEntrada", value: socioAdiministrador.dataEntrada },
      { op: "replace", path: "/numeroDocumento", value: socioAdiministrador.numeroDocumento },
      { op: "replace", path: "/idade", value: socioAdiministrador.idade },
      { op: "replace", path: "/quotasAcoes", value: socioAdiministrador.quotasAcoes },
      { op: "replace", path: "/pais", value: socioAdiministrador.pais },
      { op: "replace", path: "/qualificacaoId", value: socioAdiministrador.qualificacaoId },
      { op: "replace", path: "/codigo", value: socioAdiministrador.codigo },
      { op: "replace", path: "/status", value: socioAdiministrador.status },
      { op: "replace", path: "/tipoQuotasAcoesId", value: socioAdiministrador.tipoQuotasAcoesId },
    ];
    await api.patch<IRepresentanteEmpresa>(`/Empresa/AtualizaSocioAdministradorPorId?id=${socioAdiministrador.socioAdministradorId}`, data);

  }

  async adicionarEmpresa(empresa: IEmpresaRequest): Promise<any> {
    const empresaRequest = this.criarObjetoRequest(empresa);
    const { data: result } = await api.post<any>('Empresa/AdicionaEmpresa', empresaRequest);
    return result;
  }

  async adicionarAtosSocietarios(atoSocietario: IAtoSocietario): Promise<any> {
    const { data: result } = await api.post<IAtoSocietario>('/AtoSocietario/AdicionaAtoSocietario', atoSocietario);
    return result;
  }

  async recuperaAtosSocietarios(empresaId: number) {

    let { data: result } = await api.get<IRecuperaLista<IAtoSocietario>>(
      `/AtoSocietario/RecuperaAtoSocietario?empresaId=${empresaId}`
    );

    return result;
  }

  async alteraAtosSocietarios(empresaId: number, atoSocietario: any) {
    const data: any = [];
    const keysToExclude = ['atoSocietarioId', 'empresaId', 'clienteId', 'usuarioIdCadastro', 'dataCadastro'];
    atoSocietario.status = 1;
    const paths = Object.keys(atoSocietario);
    paths.forEach(path => {
      if (!keysToExclude.includes(path)) {
        data.push({
          op: 'replace',
          path: '/' + path,
          value: atoSocietario[path]
        })
      }
    })
    await api.patch<IAtoSocietario>(`/AtoSocietario/AtualizaAtoSocietarioPorId?id=${atoSocietario.atoSocietarioId}`, data)

  }

  private criarObjetoRequest(empresa: any) {

    const camposRequest = [
      "grupoEconomicoId",
      "tipoEstabelecimento",
      "empresaMatrizId",
      "tipoSociedadeId",
      "pessoaId",
      "tipoEmpresaId",
      "objetoSocial",
      "inscricaoEstadual",
      "inscricaoMunicipal",
      "orgaoRegistroId",
      "descricaoOrgao",
      "nire",
      "dataConstituicao",
      "dataPrimeiroRegistro",
      "endereco",
      "numero",
      "complemento",
      "bairro",
      "cep",
      "cidadeId",
      "porteEmpresa",
      "valorCapitalSocial",
      "valorCapitalIntegralizado",
      "valorCapitalAIntegralizar",
      "moedaId",
      "observacoesEmpresa",
      "telefone",
      "email",
      "status",
      "dataSituacao",
      "motivoSituacaoId",
      "atividadeEmpresaPrincipalId",
      "empresasAtividadesEmpresa",
      "sociosAdministradores",
      "representantes",
      "codigo"
    ]

    const camposFiltrados = new Map(camposRequest.map((campo) => {
      if (campo === 'status') {
        return [campo, empresa['statusEmpresa']]
      }

      return [campo, empresa[campo]]
    }))

    return Object.fromEntries(camposFiltrados)
  }

}

export default new EmpresaService();
