import { IOrganograma } from "../interfaces/IOrganograma";
import { IOrganogramaService } from "../interfaces/IOrganogramaService";
import { OrganogramaType } from "../interfaces/Types/OrganogramaType";
import api from "./Api";

export default class OrganogramaService implements IOrganogramaService {
  async exibirOrganograma(id: number, type: OrganogramaType): Promise<IOrganograma> {
    const apiChamada = type === 'empresa' ? 'GeraOrganogramaByEmpresaId' : 'GeraOrganogramaByGrupoId'
    const endpoint = `/Empresa/${apiChamada}` 
  
    const { data } = await api.get<IOrganograma>(
      endpoint,
      {
        params: {
          ...(type === "empresa" ? { empresaId: id } : { grupoId: id })
        },
      }
    );

    return data;
  }
}
