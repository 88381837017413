import { useQuery } from "react-query";
import EditorService from "../../../services/EditorService";
import { IEditorVersionAdditionalInfoRequest } from "../../../interfaces/IEditorVersionResponse";
import { EditorQuery } from "../components/WordProcessor/queries";

export function useCustomFlags(params: IEditorVersionAdditionalInfoRequest) {
  const { data, isLoading, error } = useQuery({
    queryFn: () => EditorService.getAdditionalInformation(params),
    queryKey: [EditorQuery.custom_flags, params],
  });

  return {
    data: data ? data : null,
    isLoading,
    error,
  };
}
