
import * as Yup from 'yup';
import { useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DocumentoService from '../../services/DocumentoService';
import { BsCloudArrowDown, BsEye, BsEyeglasses } from 'react-icons/bs';
import IDocumento from '../../interfaces/IDocumento';
import moment from 'moment';
import { TableColumn } from 'react-data-table-component';
import FiltroPesquisaDocumentoRequest from '../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import GridPadrao from '../../components/Comum/GridPadrao';
import PreviewPDFField from '../../components/Comum/PreviewPDF/PreviewPDF';


interface IFechamentoPreviewModalProps {
    toggleCartaModal: (carregarDados?: boolean) => void,
    exibirCartaModal: boolean
}

const FechamentoPreviewModal = ({ toggleCartaModal, exibirCartaModal }: IFechamentoPreviewModalProps) => {

    const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

    const handleSortArquivo = async (column: TableColumn<any>, sortDirection: string) => {
        setFiltrosPesquisaArquivos((oldState: any) => {
          return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
        });
      };

      const handlePageChangeArquivo = (page: number) => {
        setFiltrosPesquisaArquivos((oldState: any) => {
          return { ...oldState, offset: (page - 1) * filtrosPesquisaArquivos.limit };
        });
      };

      const handlePerRowsChangeArquivo = async (currentRowsPerPage: number) => {
        setFiltrosPesquisaArquivos((oldState: any) => {
          return { ...oldState, limit: currentRowsPerPage };
        });
      };


    const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);
    const [exibirListaArquivosImportados, setExibirListaArquivosImportados] = useState<boolean>(false);
    const [exibirComponenteParaImportarArquivo, setExibirComponenteParaImportarArquivo] = useState<boolean>(false);


    const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");
    const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
    const [documentos, setDocumentos] = useState<any>([]);
    const verificaIsPdf = (extensao: string) => {
        if (extensao.endsWith(".pdf")) return true;
        else return false;
      };


    const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
        Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando download...",
            showConfirmButton: false,
        });
        Swal.showLoading();

        try {
            await DocumentoService.download(documentoId)
                .then((response: any) => {
                    const disposition = response.headers["content-disposition"];
                    let fileName = "";
                    if (disposition && disposition.indexOf("attachment") !== -1) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].replace(/['"]/g, "");
                        }
                    }
                    const fileLink = document.createElement("a");
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    fileLink.setAttribute("download", nomeArquivo);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error: any) => {
                    throw new Error(error);
                });

            Swal.hideLoading();

            await Swal.fire({
                heightAuto: false,
                icon: "success",
                text: `Download realizado com sucesso!`,
                showConfirmButton: true,
                timer: 4000,
            });

            Swal.close();
        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Não foi possivel realizar o download.`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        }
    };

    const onChangeTipoDocumento = () => {
         carregarDocumentos(filtrosPesquisaArquivos);
      };

    const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
        documentoId: [],
        processoId: 269,
        modulo: [],
        nome: "",
        status: 1,
        origem: 0,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: "-documentoId",
      });

    const previewArquivo = async (documentoId: number) => {
        try {
            const resultado = await DocumentoService.previewDocument(documentoId);
            setUrlPDFDocumento(resultado.data);
            setModalExibirPDFDocumento(true);
        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Ocorreu um erro ao obter o arquivo`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        }
    };

    const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
        try {
          setCarregandoDocumentos(true);
    
          let resultado: IRecuperaLista<IDocumento>;
          resultado = await DocumentoService.obterArquivos({ ...filtro, processoId: 269, status: 1 });
    
          if (resultado.data != null) setDocumentos(resultado.data);
          else setDocumentos([]);
    
          setFiltrosPesquisaArquivos((oldState) => {
            return { ...oldState, totalItems: resultado.totalRegistros };
          });
    
          setCarregandoDocumentos(false);
        } catch (error: any) {
          setDocumentos([]);
          setCarregandoDocumentos(false);
        }
      };

    const colunasArquivos: TableColumn<any>[] = [
        {
          name: "Tipo",
          sortField: "tipoDocumentoNome",
          selector: (row: any) => row.tipoDocumentoNome,
          sortable: true,
          wrap: true,
        },
    
        {
          name: "Descrição",
          sortField: "descricao",
          selector: (row: any) => row.descricao,
          sortable: true,
          wrap: true,
        },
        {
          name: "Data de Cadastro",
          sortField: "  ",
          selector: (row: any) => moment(row.dataCadastro).format("DD/MM/yyyy"),
          sortable: true,
          wrap: true,
        },
        {
          name: "",
          cell: (row: IDocumento) => {
            return (
              <div>
    
                <BsCloudArrowDown
                  title="Baixar Arquivo"
                  onClick={() => baixarArquivo(row.documentoId, row.nome)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
                <>
                  {verificaIsPdf(row.extensao) && (
                    <BsEye
                      title="Preview"
                      onClick={() => previewArquivo(row.documentoId)}
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      size="30px"
                      className="mx-2 text-orange"
                    />
                  )}
                </>
              </div>
            );
          },
          ignoreRowClick: true,
        },
      ];
    return <>
          <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />

        <Modal size='lg' centered={true} show={exibirCartaModal} onHide={toggleCartaModal} exibirListaArquivosImportados={false}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{"Cartas de Circularização"}</h5>
                    <div onClick={() => toggleCartaModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="col-md-12">
            {<label
              onClick={() => setExibirListaArquivosImportados(exibirListaArquivosImportados)}
              htmlFor="form-Documentos"
              style={{ cursor: "pointer" }}
              className="form-label fw-bolder text-orange"
            >
              <BsEyeglasses
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                size={"30px"}
                title={"Carregar Arquivos"}
              />
            </label>}
            {!exibirListaArquivosImportados && (
              <GridPadrao
                progressPending={carregandoDocumentos}
                onSort={handleSortArquivo}
                limit={filtrosPesquisaArquivos.limit}
                onChangePage={handlePageChangeArquivo}
                onChangeRowsPerPage={handlePerRowsChangeArquivo}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisaArquivos.totalItems}
                colunas={colunasArquivos}
                tipo="Documentos"
                itens={documentos}
              />
            )}
          </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                <button disabled={false} onClick={() => toggleCartaModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default FechamentoPreviewModal;