import { RenderElementProps } from "slate-react";
import { NumberedListElement as NumberedListEl } from "../../../../../../utils/types/slate";

export const NumberedListElement = (props: RenderElementProps) => {
  const element = props.element as NumberedListEl

  const style: React.CSSProperties = {
    textAlign: (element.align as any) ?? "start",
  };
  
  return (
    <ol style={style} {...props.attributes}>
      {props.children}
    </ol>
  );
}