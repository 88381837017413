import { useContext, useEffect, useState } from "react";
import { faBackward, faEdit, faFileArchive, faTimes } from "@fortawesome/free-solid-svg-icons";
import { BsTrash, BsCloudArrowDown, BsEye, BsCloudArrowUp } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import moment from "moment";
import clsx from "clsx";

import ObrigacaoService from "../../../services/ObrigacaoService";
import DocumentoService from "../../../services/DocumentoService";
import CompromissoService from "../../../services/CompromissoService";

import { ECamposIDEnum, ETipoCampoEnum, EModulo, ETelaEnum, returnarEnumDescricaoID } from "../../../enum";

import IDocumento from "../../../interfaces/IDocumento";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaDocumentoRequest from "../../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import Carregando from "../../../components/Carregando";
import GridPadrao from "../../../components/Comum/GridPadrao";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import IFileUploadField from "../../../components/FileUpload/FileUploadField";
import CamposCapaDinamicos from "../../../components/Fluxo/CamposCapaDinamicos";
import IFileUploadFieldEdit from "../../../components/FileUpload/FileUploadFieldEdit";
import CamposCadastroDinamico from "../../../components/Fluxo/CamposCadastroDinamicos";
import PreviewPDFField from "../../../components/Comum/PreviewPDF/PreviewPDF";
import ICompromisso from "../../../interfaces/ICompromisso";
import AreaTextoDefault from "../../../components/Comum/AreaTexto/AreaTextoDefault";

interface IWorkflowModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  compromisso: ICompromisso;
  setRefresh(salvando: boolean): void;
  capa: boolean;
}

const ObrigacaoFluxoModal = ({ toggleModal, exibirModal, compromisso, setRefresh, capa }: IWorkflowModalProps) => {
  const { obrigacao, processo } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [carregandoDocumentos, setCarregandoDocumentos] = useState(false);
  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    obrigacaoId: compromisso.obrigacaoId,
    modulo: [EModulo.Obrigacao],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const [documentos, setDocumentos] = useState<any>([]);
  const [documento, setDocumento] = useState<IDocumento>({
    index: 0,
    documentoId: 0,
    clienteId: 0,
    nome: "",
    descricao: "",
    tamanho: 0,
    tipoDocumentoId: 0,
    tipoDocumentoNome: "",
    restrito: false,
    status: "",
    codigo: "",
    dataCadastro: new Date(),
    usuarioIdCadastro: 0,
    usuarioNomeCadastro: "",
    modulo: "",
    extensao: "",
    pastaGedNome: "",
    etiquetas: [],
  });

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [exibirListaArquivosImportados, setExibirListaArquivosImportados] = useState<boolean>(false);
  const [quantidadeArquivosSelecionados, setQuantidadeArquivosSelecionados] = useState<number>(0);
  const [exibirModalImportacaoArquivos, setExibirModalImportacaoArquivos] = useState<boolean>(false);
  const [exibirComponenteParaImportarArquivo, setExibirComponenteParaImportarArquivo] = useState<boolean>(false);
  const [exibirModalDocumentoEdit, setExibirModalDocumentoEdit] = useState<boolean>(false);
  const [atualizaTabelaDocumento, setAtualizaTabelaDocumento] = useState<boolean>(true);
  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  const toggleDocumentoEdit = () => setExibirModalDocumentoEdit(!exibirModalDocumentoEdit);

  const toggleImportacaoArquivos = () => setExibirModalImportacaoArquivos(!exibirModalImportacaoArquivos);

  const initialValues = {
    modulo: EModulo.Obrigacao,
    fluxoFaseId: compromisso.fluxoFaseId,
    categoriaId: obrigacao.tipoObrigacaoId,
    processoId: obrigacao.processoId || null,
  };

  const adicionarShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required(`${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Lista:
           if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[`${returnarEnumDescricaoID(configuracao.campoNome, configuracao.isXCampo)}`] = Yup.number().min(1, `${configuracao.label} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura) {
            schema[configuracao.campoNome.toLowerCase()] = Yup.date().required(`Campo ${configuracao.label} é obrigatório`).typeError(`Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Empresa:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.Empresa) {
            schema.empresasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        case ETipoCampoEnum.Pessoa:
          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.Advogado) {
            schema.advogadoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.AdvogadoInterno) {
            schema.advogadoInternoList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }

          if (configuracao.obrigatorio && !configuracao.somenteLeitura && configuracao.campoNome === ECamposIDEnum.ParteContraria) {
            schema.partesContrariasList = Yup.array().min(1, `Campo ${configuracao.label} é obrigatório`);
          }
          break;

        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionarShema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Obrigação",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Concluindo o compromisso...",
            showConfirmButton: false,
          });
          Swal.showLoading();

          // Salvar Despesa
          await ObrigacaoService.concluirCompromisso({
            ...values,
            xCampoValorList: carregarObjetoXCampos()
          });

          setTimeout(async () => {

            // Salvar Compromisso
            await CompromissoService.concluirCompromisso({ observacao: values.observacao || 'Concluído!' }, compromisso.compromissoId, obrigacao.obrigacaoId);

            toggleModal();
            formik.resetForm();
            setConfiguracoesTela([]);

            setRefresh(true);
            Swal.hideLoading();
            setSubmitting(false);

            await Swal.fire({ icon: "success", title: `Compromisso concluído com sucesso`, timer: 3000 });

          }, 2000);
        }
      } catch (error: any) {
        Swal.hideLoading();
        setSubmitting(false);
        setConfiguracoesTela([]);
      }
    },
  });

  useEffect(() => {

    if (compromisso.compromissoId) carregarConfiguracaoTela();

    formik.setValues({
      ...formik.values,
      obrigacaoId: obrigacao.obrigacaoId,
      categoriaId: obrigacao.tipoObrigacaoId,
      fluxoFaseId: compromisso.fluxoFaseId,
      modulo: EModulo.Despesa,
      observacao: compromisso.observacaoConclusao,
    });

  }, [obrigacao]);


  useEffect(() => {
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      if (configuracao.campoTipo == ETipoCampoEnum.Lista
        && configuracao.campoNome != ECamposIDEnum.TipoDocumento
        && configuracao.campoNome != ECamposIDEnum.TipoDocumentoCheckList
        && configuracao.campoNome != ECamposIDEnum.TipoDocumentoMinuta) {
        if (configuracao.obrigatorio) formik.setFieldValue(`${configuracao.campoNome.toLowerCase()}Id`, configuracao.obrigatorio ? 0 : eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`));
      } else {
        if (configuracao.obrigatorio) formik.setFieldValue(`${configuracao.campoNome.toLowerCase()}`, configuracao.obrigatorio ? "" : eval(`formik.values.${configuracao.campoNome.toLowerCase()}`));
      }
    });
  }, [configuracoesTela?.length]);


  useEffect(() => {
    if (atualizaTabelaDocumento) {
      carregarDocumentos(filtrosPesquisaArquivos);
    }
  }, [atualizaTabelaDocumento]);

  const carregarObjetoXCampos = () => {
    let xCampoValorList = new Array<any>;
    configuracoesTela.forEach(configuracao => {

      if (configuracao?.isXCampo) {
        switch (configuracao.campoTipo) {
          case ETipoCampoEnum.AreaTexto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Texto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Data:
            xCampoValorList.push({ campoId: configuracao.campo, data: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Valor:
          case ETipoCampoEnum.Percentual:
            xCampoValorList.push({ campoId: configuracao.campo, valor: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Inteiro:
            xCampoValorList.push({ campoId: configuracao.campo, inteiro: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Lista:
                        xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: eval(`formik.values.${returnarEnumDescricaoID(configuracao.campoNome, true)}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          case ETipoCampoEnum.Boolean:
            xCampoValorList.push({ campoId: configuracao.campo, boolean: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`), xCampoValorId: configuracao?.xCampoValorId, campoTipo: configuracao.campoTipo })
            break;

          default:
            break;
        }
      }
    });
    return xCampoValorList;
  }

  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);

      let resultado = await CompromissoService.obterTelaCompromisso(compromisso.compromissoId, ETelaEnum.TelaConcluirFase);

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setCarregandoConfiguracoesTela(false);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const verificaIsPdf = (extensao: string) => {
    if (extensao.endsWith(".pdf")) return true;
    else return false;
  };

  const carregarDocumentos = async (filtro: FiltroPesquisaDocumentoRequest) => {
    try {

      setCarregandoDocumentos(true);

      let resultado: IRecuperaLista<IDocumento>;
      resultado = await DocumentoService.obterArquivos({ ...filtro, processoId: processo.processoId, status: 1 });

      if (resultado.data != null) setDocumentos(resultado.data);
      else setDocumentos([]);

      setFiltrosPesquisaArquivos((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

    } catch (error: any) {
      setDocumentos([]);
      setCarregandoDocumentos(false);
    } finally {
      setAtualizaTabelaDocumento(false);
      setCarregandoDocumentos(false);
    }
  };

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const excluirDocumento = async (documentoId: number) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Deletar documento",
        text: `Você realmente deseja deletar este documento?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await DocumentoService.inativaDocumento(documentoId);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Documento deletado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        carregarDocumentos(filtrosPesquisaArquivos);
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel deletar o documento`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };

  const handlePerRowsChangeArquivo = async (currentRowsPerPage: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChangeArquivo = (page: number) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisaArquivos.limit };
    });
  };

  const handleSortArquivo = async (column: TableColumn<any>, sortDirection: string) => {
    setFiltrosPesquisaArquivos((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const colunasArquivos: TableColumn<any>[] = [
    {
      name: "Documento ID",
      sortField: "documentoId",
      selector: (row: any) => row.documentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumentoNome",
      selector: (row: any) => row.tipoDocumentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: any) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Restrito?",
      sortField: "restrito",
      selector: (row: any) => (row.restrito ? "Sim" : "Não"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Usuário",
      sortField: "usuarioNomeCadastro",
      selector: (row: any) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "  ",
      selector: (row: any) => moment(row.dataCadastro).format("DD/MM/yyyy"),
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IDocumento) => {
        return (
          <div>
            {!capa &&
              <>
                <FaEdit
                  title="Editar Arquivo"
                  onClick={() => {
                    setDocumento(row);
                    onChangeTipoDocumento();
                    setExibirModalDocumentoEdit(true);
                  }}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />

                <IFileUploadFieldEdit
                  campo={58}
                  exibirModalDocumentoEdit={exibirModalDocumentoEdit}
                  toggleModal={toggleDocumentoEdit}
                  editarDocumento={documento}
                  modulo={parseInt(documento.modulo)}
                  setAtualizarTabela={setAtualizaTabelaDocumento}
                  areaDireitoId={processo.areaDireitoId}
                />

                <BsTrash
                  title="Deletar Arquivo"
                  onClick={() => excluirDocumento(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
              </>}

            <BsCloudArrowDown
              title="Baixar Arquivo"
              onClick={() => baixarArquivo(row.documentoId, row.nome)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />
            <>
              {verificaIsPdf(row.extensao) && (
                <BsEye
                  title="Preview"
                  onClick={() => previewArquivo(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
              )}
            </>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const onChangeTipoDocumento = () => {
    if (configuracoesTela.filter((e: { campo: number }) => e.campo == 58).length > 0) {
      const tipoDocumento = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo == 58)[0];

      const configuracoesTelaFilter = configuracoesTela.filter((e: IRecuperaConfiguracaoTelaResponse) => e.campo !== 58);

      configuracoesTelaFilter.push({ ...tipoDocumento, alterarCampo: true });

      setConfiguracoesTela(configuracoesTelaFilter);
    }
    if (exibirComponenteParaImportarArquivo) carregarDocumentos(filtrosPesquisaArquivos);
  };

  const renderImportadorArquivos = () => {
    return exibirComponenteParaImportarArquivo ? (
      <>
        <div className="row mt-3">
          <div className="col-md-4 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              {configuracoesTela.filter((e) => e.campoNome == ECamposIDEnum.TipoDocumento)[0]?.label}:
              <FontAwesomeIcon
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                icon={faBackward}
              />
            </label>
            <br />
            <>
              <Button
                onClick={() => setExibirModalImportacaoArquivos(true)}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faFileArchive} />
                Selecione os arquivos
              </Button>
              <IFileUploadField
                campo={58}
                modulo={EModulo.Obrigacao}
                setQuantidadeArquivosSelecionados={setQuantidadeArquivosSelecionados}
                exibirModal={exibirModalImportacaoArquivos}
                toggleModal={toggleImportacaoArquivos}
                tipoObrigacaoId={obrigacao.tipoObrigacaoId}
                obrigacaoId={obrigacao.obrigacaoId}
                capa={true}
              />
            </>
          </div>
        </div>
        <div className="row mt-2">
          <a style={{ color: "var(--primary-base)", fontSize: "12px" }}>
            {quantidadeArquivosSelecionados === 0 && "Nenhum arquivos selecionado"}
            {quantidadeArquivosSelecionados === 1 && `${quantidadeArquivosSelecionados} arquivos selecionado`}
            {quantidadeArquivosSelecionados > 1 && `${quantidadeArquivosSelecionados} arquivos selecionados`}
          </a>
        </div>
      </>
    ) : (
      <>
        <div className="row mt-2">
          <div className="col-md-12">
            <label
              onClick={() => setExibirListaArquivosImportados(!exibirListaArquivosImportados)}
              htmlFor="form-Documentos"
              style={{ cursor: "pointer" }}
              className="form-label fw-bolder text-orange"
            >
              Documentos {exibirListaArquivosImportados ? "-" : "+"}
              <BsCloudArrowUp
                onClick={() => {
                  onChangeTipoDocumento();
                  setExibirComponenteParaImportarArquivo(!exibirComponenteParaImportarArquivo);
                }}
                color={"var(--primary-base2)"}
                className="mx-3"
                style={{ cursor: "pointer" }}
                size={"30px"}
                title={"Realizar upload de documento"}
              />
            </label>
            {exibirListaArquivosImportados && (
              <GridPadrao
                progressPending={carregandoDocumentos}
                onSort={handleSortArquivo}
                limit={filtrosPesquisaArquivos.limit}
                onChangePage={handlePageChangeArquivo}
                onChangeRowsPerPage={handlePerRowsChangeArquivo}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisaArquivos.totalItems}
                colunas={colunasArquivos}
                tipo="Documentos"
                itens={documentos}
              />
            )}
          </div>
          <hr />
        </div>
      </>
    );
  };

  const renderDefault = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        {capa ?
          <CamposCapaDinamicos
            configuracaoTela={configuracaoTela}
            configuracaoTelaLista={configuracoesTela}
            advogadosInterno={[]}
            partesProcesso={[]}
            formik={formik} />
          :
          <CamposCadastroDinamico
            configuracaoTelaLista={configuracoesTela}
            configuracaoTela={configuracaoTela}
            formik={formik}
          />
        }
      </>
    );
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {

      if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        component.push(renderImportadorArquivos());
        return;
      }

      else {
        component.push(renderDefault(configuracaoTela));
        return;
      }
    });

    return <div className="row mt-12">{component}</div>;
  };

  const renderObservacao = () => {
    return (
      <div className="row mt-3">
        <div className="col-md-6">
          <label htmlFor="form-observacao" className="form-label fw-bolder text-orange">
            Observação:
          </label>
          <div className="input-group">
            <textarea
              {...formik.getFieldProps("observacao")}
              placeholder="Observação"
              className={"form-control"}
              id="form-observacaoCompromisso"
              rows={5}
            />

            <Button
              onClick={() => toggleAreaTextoModal()}
              style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
              variant="secondary"
              size="sm"
            >
              <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
            </Button>
          </div>
          {formik.touched.observacao && formik.errors.observacao && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span style={{ fontSize: "11px" }} role="alert">
                  {formik.errors.observacao}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }


  return (
    <>

      <AreaTextoDefault
        toggleModal={toggleAreaTextoModal}
        exibirModal={exibirModalAreaTexto}
        field="observacao"
        formik={formik}
      />
      <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Concluir: #{compromisso.compromissoId}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
            </div>
          </div>
          <div className="modal-body" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />
            {carregandoConfiguracoesTela ? <Carregando /> :
              <>
                {compromisso.fluxoFaseId ? renderCampos() : null}
                {renderObservacao()}
              </>}
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <div className="row mt-1" style={{ margin: "0 auto" }}>
              <div className="col-md-6">
                <button onClick={() => formik.submitForm()} disabled={!formik.isValid || capa} type="submit" className="btn btn-orange">
                  Concluir
                </button>
              </div>
              <div className="col-md-6">
                <button onClick={() => toggleModal()} type="button" className="btn btn-danger float-end">
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ObrigacaoFluxoModal;
