import IPerfilLista from "../interfaces/IPerfilLista";
import IPerfilUnicoLista from "../interfaces/IPerfilUnicoLista";
import IPermissoesLista from "../interfaces/IPermissoesLista";
import { AdicionaPerfilRequest } from "../interfaces/Requests/Perfil/AdicionaPerfilRequest";
import { EditaPermissoesRequest } from "../interfaces/Requests/Perfil/EditaPerfilPermissoes";
import { AtivaPerfilRequest } from "../interfaces/Requests/Perfil/AtivaPerfilRequest";
import FiltroPesquisaPerfisRequest from "../interfaces/Requests/Perfil/FiltroPesquisaPerfisRequest";
import api from "./Api";
import { AtualizaPerfilRequest } from "../interfaces/Requests/Perfil/AtualizaPerfilRequest";

class PerfilService {
  async obterPerfis(filtros: FiltroPesquisaPerfisRequest) {
    const queryStatus =
      filtros.status === "0"
        ? "&status=0&status=-1&status=1"
        : `&status=${filtros.status}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";
    const queryPaginacao = `&offset=${filtros.offset}${limit}&sort=${filtros.sort}`;
    const queryClient = filtros.clienteId
      ? `&clienteId=${filtros.clienteId}`
      : "";

    const { data: result } = await api.get<IPerfilLista>(
      `PerfilUsuario/RecuperaPerfis?${queryStatus}${queryPaginacao}${queryClient}`
    );

    return result;
  }

  async pesquisaPerfis(nome: string) {
    const { data: result } = await api.get<IPerfilLista>(
      `PerfilUsuario/RecuperaPerfis?nome=${nome}`
    );

    return result;
  }

  async adicionaPerfil(req: AdicionaPerfilRequest) {
    const { data: result } = await api.post<IPerfilLista>(
      "PerfilUsuario/AdicionaPerfil",
      req
    );

    return result;
  }

  async obterPerfil(id: string) {
    const { data: result } = await api.get<IPerfilUnicoLista>(
      `/PerfilUsuario/RecuperaPerfil/${id}`
    );

    return result;
  }
  async atualizaPerfil(id: string, req: AtualizaPerfilRequest) {
    const body = [];

    for (const iterator of Object.keys(req)) {
      body.push({
        op: "replace",
        path: `/${iterator}`,
        value: req[iterator as keyof typeof req],
      });
    }

    const { data: result } = await api.patch<IPerfilUnicoLista>(
      `PerfilUsuario/AtualizaPerfilUsuario/${id}`,
      body
    );

    return result;
  }

  async removePerfil(id: string) {
    const { data: result } = await api.delete<IPerfilUnicoLista>(
      `/PerfilUsuario/RemovePerfil/${id}`
    );

    return result;
  }

  async ativaPerfil(id: string, req: AtivaPerfilRequest) {
    const body = [
      {
        op: "replace",
        path: "/status",
        value: req.Status,
      },
    ];

    const { data: result } = await api.patch<IPerfilUnicoLista>(
      `PerfilUsuario/AtualizaPerfilUsuario/${id}`,
      body
    );

    return result;
  }

  async editaPerfil(id: number, req: EditaPermissoesRequest) {
    const { data: result } = await api.put<IPermissoesLista>(
      `/Permissao/AtualizaPermissao/${id}`,
      req
    );

    return result;
  }
}

export default new PerfilService();
