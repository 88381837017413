import { useLocation, Link } from 'react-router-dom';
import EnvelopeService from '../../../services/EnvelopeService';
import IEnvelope from '../../../interfaces/IEnvelope';
import React, { useEffect, useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import VisualizarDocumentos from '../../../components/VisualizarDocumentos';
import { AiOutlineFileSync } from 'react-icons/ai';
import clsx from "clsx";
import * as Yup from 'yup';
import '../../../layout/Layout.scss';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';
import { FaEdit, FaFont, FaRegTrashAlt } from 'react-icons/fa';
import { valida_cpf, valida_cnpj } from '../../../utils/Validadores';
import CardEnvelopeDados from './CardEnvelopeDados';



function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const AssinarEnvelope = () => {

    let query = useQuery();

    const [envelope, setEnvelope] = useState<IEnvelope>();
    const [carregandoEnvelope, setCarregandoEnvelope] = useState(true);
    const [salvarEnvelope, setSalvarEnvelope] = useState(false);
    const [fonte, setFonte] = useState(0);
    const [documentoTipo, setDocumentoTipo] = useState('2');
    const assinatura = useRef() as React.MutableRefObject<CanvasDraw>;
    const assinaturaEscrita = useRef() as React.MutableRefObject<HTMLInputElement>;
    const container = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [assinaturaText, setAssinaturaTexto] = useState('');

    const [assinar, setAssinar] = useState(false);

    async function carregarEnvelope(): Promise<void> {
        try {
            setCarregandoEnvelope(true);
            const resultado = await EnvelopeService.recuperaEnvelopePorSignatario({
                hashEnvelope: query.get('hashEnvelope') as string,
                hashSignatario: query.get('hashSignatario') as string
            });

            setEnvelope(resultado);

        } catch (error: any) {
            console.log(error);
        } finally {
            setCarregandoEnvelope(false);
        }
    }

    const itenEstilo: React.CSSProperties = {
        userSelect: "none",
        padding: 16,
        margin: `0 0 .25rem 0`,
        border: `.05rem dashed #ddd`,
        background: 'white'
    }


    function fontAtual(): string {
        return [
            "",
            "'Sacramento', cursive",
            "'Great Vibes', cursive",
            "'Herr Von Muellerhoff', cursive",
            "'Mr De Haviland', cursive",
            "'Qwigley', cursive",
            "'Stalemate', cursive",
            "'Comforter Brush', cursive",
        ][fonte];
    }

    function ajustarTamanhoFonte(): void {
        const elemento = document.querySelector('#assinatura-texto') as HTMLInputElement

        if (!elemento) return;

        let fontSize = 50;

        elemento.style.fontSize = `${fontSize}px`;

        while ((elemento.scrollWidth - elemento.offsetWidth) > 0 && fontSize > 0) {
            fontSize--;
            elemento.style.fontSize = `${fontSize}px`;
        }

        elemento.style.fontSize = `${fontSize - 3}px`;
    }

    useEffect(() => {
        ajustarTamanhoFonte()
    }, [assinaturaText, fonte])

    function fonteAnterior(): void {
        if (fonte > 1) {
            return setFonte(fonte - 1);
        }
        setFonte(7);
        ajustarTamanhoFonte()
    }

    function proximaFonte(): void {
        try {
            if (!fonte) {
                if (assinatura.current) {
                    assinatura.current.clear();
                }
                return setFonte(1);
            }
            if (fonte < 7) {
                return setFonte(fonte + 1);
            }
            return setFonte(1);
        } finally {
            ajustarTamanhoFonte()
        }
    }

    function desenharAssinatura(): void {
        if (fonte > 0) {
            setFonte(0);
            assinatura.current.undo();
        }
    }


    const signatarioValidacoes = Yup.object().shape({
        documento: Yup.string().min(3).max(50, 'Maximo 50 caracteres').test('is-valid', 'Insira um documento válido', val => {
            const CPFREGEX = /^\d{3}(\.?)\d{3}(\.?)\d{3}(-?)\d{2}$/;
            const CNPJREGEX = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;

            switch (documentoTipo) {
                case '1':
                    return (`${val}`.length >= 3);
                case '2':
                    return CPFREGEX.test(`${val}`) && valida_cpf(`${val}`);
                case '3':
                    return CNPJREGEX.test(`${val}`) && valida_cnpj(`${val}`);
                case '4':
                    return `${val}`.length >= 1;
                default:
                    return false;
            }
        }),
        dataNascimento: Yup.date().max(new Date(), 'A data de nascimento não pode ser futura').required('A data de nascimento é obrigatória').nullable()
    })

    const formularioAssinatura = useFormik({
        initialValues: { documento: '', tipo: 2, dataNascimento: '' },
        validationSchema: signatarioValidacoes,
        onSubmit: async (values, { setSubmitting }) => {

            setSalvarEnvelope(true);

            if (!envelope) return;

            const _assinaturaEscrita = assinaturaEscrita.current;
            const _assinatura = assinatura.current;

            if ((fonte > 0 && !_assinaturaEscrita) || (fonte === 0 && !_assinatura))
                return;

            let ip = '';

            try {
                const resultado = await (await fetch('https://api.ipify.org?format=json')).json();
                ip = await resultado.ip;
            } catch (error) {
                console.log(error);
                const resultado = await (await fetch('https://v4.ident.me')).text();
                ip = await resultado;
            }

            let imagemData = "...";


            const canvas = document.createElement('canvas');
            canvas.width = 215;
            canvas.height = 65;
            const contexto = canvas.getContext('2d') as CanvasRenderingContext2D;
            contexto.clearRect(0, 0, canvas.width, canvas.height);

            if (fonte > 0) {
                if (assinaturaText.trim().length < 0) {
                    return Swal.fire({
                        text: 'Assinatura vazia',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        timer: 4000
                    })
                }

                const fontSize = parseInt(_assinaturaEscrita.style.fontSize);
                const fontFamily = _assinaturaEscrita.style.fontFamily;
                contexto.textAlign = "center";
                contexto.font = `${fontSize * (215 / _assinaturaEscrita.offsetWidth)}px ${fontFamily}`
                contexto.fillStyle = 'black';
                contexto.fillText(assinaturaText, canvas.width / 2, canvas.height * 0.58, 215);

                imagemData = canvas.toDataURL('image/png', 100);
            } else {
                const assinaturaData = _assinatura.getDataURL('png');
                const img = new Image(canvas.width, canvas.height);
                img.src = assinaturaData;
                img.width = 215;
                img.height = 65;

                await new Promise<void>((resolve, reject) => {
                    img.onload = () => resolve();
                    img.onerror = () => reject();
                })
                contexto.drawImage(img, 0, 0, canvas.width, canvas.height);
                imagemData = canvas.toDataURL('image/png', 100);
            }

            const _canvas = document.createElement('canvas');
            _canvas.width = canvas.width;
            _canvas.height = canvas.height;
            const imagemVaziaData = _canvas.toDataURL('image/png', 100);
            _canvas.remove();


            if (imagemData === imagemVaziaData) {
                return Swal.fire({
                    text: 'Assinatura vazia',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    timer: 4000
                })
            }

            canvas.remove();

            const imagemBloob = await (await fetch(imagemData)).blob();

            const imagemArquivo = new File([imagemBloob], 'assinatura.png', { type: 'image/png' })


            const dados = {
                HashEnvelope: query.get('hashEnvelope') as string,
                HashSignatario: query.get('hashSignatario') as string,
                DocIdentificacaoTipo: values.tipo,
                DocIdentificacaoNumero: values.documento,
                IPRequest: ip,
                File: imagemArquivo,
                DataNascimento: values.dataNascimento
            }

            Swal.fire({
                title: 'Aguarde...',
                text: 'Salvando assinatura',
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false
            })
            Swal.showLoading();
            EnvelopeService.assinarDocumento(dados).then(async (response) => {
                Swal.fire({
                    title: 'Sucesso',
                    text: 'Assinatura salva com sucesso',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    timer: 4000,
                    didClose: async () => {
                        setAssinar(false);
                        setSalvarEnvelope(false);
                        await carregarEnvelope();
                    }
                });

            }).catch((error: any) => {
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: 'Não foi possivel assinar o documento',
                    text: error && error.response && error.response.data && typeof error.response.data === 'string' ? error.response.data : error.response.data.message,
                    showConfirmButton: true
                });
            });
        }
    });



    function mascararCampo(e: React.FormEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>): void {
        if (!['2', '3'].includes(documentoTipo)) {
            if (e.type === 'change') {
                return formularioAssinatura.getFieldProps('documento').onChange(e);
            }
            return;
        }

        const input = e.currentTarget;
        let valor = input.value.replaceAll(/[^0-9]/g, '');

        const tamanhoMaximo = documentoTipo === '2' ? 11 : 14;
        let mascara = documentoTipo === '2' ? '___.___.___-__' : '__.___.___/____-__';

        if (valor.length > tamanhoMaximo) {
            valor = valor.substring(0, tamanhoMaximo);
        }

        for (let i = 0; i < valor.length; i++) {
            mascara = mascara.replace('_', valor[i]);
        }

        formularioAssinatura.setFieldValue('documento', mascara, true);
        setTimeout(() => {
            input.selectionStart = input.selectionEnd = mascara.split(/([^0-9])([^0-9]|$)/g)[0].length;
        }, 10);
    }

    function assinarEnvelope() {
        return <Modal
            show={assinar}
            onHide={() => {
                setAssinar(false)
            }}
        >
            <small><Modal.Header closeButton>
                <h6 className='text-orange' >Assinar Envelope</h6>
            </Modal.Header></small>
            <Modal.Body>
                <div className="row"
                    ref={container}
                >
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 mb-1">
                                <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-soft text-orange">Tipo de Documento:</label>
                                <select {...formularioAssinatura.getFieldProps('tipo')} placeholder="Tipo" className={'form-select'} id="form-select" value={documentoTipo} onChange={(e) => {
                                    if (['2', '3'].includes(documentoTipo)) {
                                        formularioAssinatura.setFieldValue('documento',
                                            formularioAssinatura.getFieldProps('documento').value.replaceAll(/[^0-9]/g, '')
                                            , true);
                                    }
                                    setDocumentoTipo(e.currentTarget.value); return true
                                }} >
                                    <option value='1'>RG</option>
                                    <option value='2'>CPF</option>
                                    <option value='3'>CNPJ</option>
                                    <option value='4'>Documento Estrangeiro</option>
                                </select>
                            </div>
                            <div className="col-12 mb-1">
                                <label htmlFor="form-data-assinatura" className="form-label fw-bolder text-soft text-orange">Numero do Documento:</label>
                                <input {...formularioAssinatura.getFieldProps('documento')} type="text" onFocus={mascararCampo} onChange={mascararCampo} className={clsx(
                                    "form-control mb-1",
                                    {
                                        'is-invalid': formularioAssinatura.touched.documento && formularioAssinatura.errors.documento
                                    },
                                    {
                                        'is-valid': formularioAssinatura.touched.documento && !formularioAssinatura.errors.documento
                                    })} placeholder="Insira o número do documento" />
                            </div>

                            <div className="col-12 mb-1">
                                <label htmlFor="form-data-nascimento" className="form-label fw-bolder text-soft text-orange">Data de Nascimento:</label>
                                <input
                                    id="form-data-nascimento"
                                    type="date"
                                    onChange={(e) => formularioAssinatura.setFieldValue('dataNascimento', e.target.value)}
                                    onBlur={(e) => {
                                        const selectedDate = new Date(e.target.value);
                                        const today = new Date();
                                        if (selectedDate > today) {
                                            formularioAssinatura.setFieldValue('dataNascimento', ''); // Limpa o campo
                                        }
                                    }}
                                    max={new Date().toISOString().split('T')[0]} // Impede datas futuras
                                    className={clsx("form-control mb-1", {
                                        'is-invalid': formularioAssinatura.touched.dataNascimento && formularioAssinatura.errors.dataNascimento
                                    })}
                                    placeholder="Insira a data de nascimento"
                                />
                                {formularioAssinatura.touched.dataNascimento && formularioAssinatura.errors.dataNascimento && (
                                    <div className="invalid-feedback">
                                        {formularioAssinatura.errors.dataNascimento}
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-1">
                        <div className="form-label fw-bolder text-soft text-orange">
                            Sua assinatura
                            <span className="float-right" >
                                <div className='d-flex justify-content-between small'>
                                    {(fonte > 0) ?
                                        <a
                                            style={{ cursor: 'pointer', backgroundColor: 'orange' }}
                                            onClick={desenharAssinatura}
                                            className='me-2'
                                            title='Desenhar Assinatura'
                                        >
                                            <FaEdit />
                                        </a>
                                        :
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={proximaFonte}
                                            className='me-2'
                                            title='Escrever Assinatura'
                                        >
                                            <FaFont />
                                        </a>
                                    }
                                    <a
                                        className="me-2"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { if (!assinatura.current) return; assinatura.current.clear() }}
                                        title='Limpar Assinatura'
                                    >
                                        <FaRegTrashAlt />
                                    </a>
                                </div>
                            </span>
                            <span className='clear-fix'></span>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <CanvasDraw
                                ref={assinatura}
                                brushColor={'#000'}
                                brushRadius={1}
                                lazyRadius={3}
                                hideGridX={true}
                                disabled={fonte > 0}
                                hideInterface={true}
                                gridColor={'#000'}
                                backgroundColor={'#fff'}
                                gridSizeY={139 * 0.8}
                                canvasWidth={462}
                                canvasHeight={139}
                                className={'assinatura'}
                                style={{
                                    border: '1px dashed #ddd'
                                }}
                            />
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: 'calc( 100% - 1rem)',
                                fontSize: '40px',
                                display: `${(fonte === 0) ? 'none' : 'block'}`
                            }}
                                className='assinatura'
                            >
                                <input
                                    ref={assinaturaEscrita}
                                    type="text" id="assinatura-texto"
                                    placeholder="Escreva sua assinatura"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'transparent',
                                        textAlign: 'center',
                                        border: '0',
                                        outline: 'none',
                                        fontFamily: fontAtual()
                                    }}
                                    onChange={(e) => setAssinaturaTexto(e.target.value)}
                                />
                            </div>

                            {(fonte > 0) ?
                                <p className='text-center small'>
                                    <a
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                        className={'text-center me-4'}
                                        onClick={fonteAnterior}
                                    >Fonte Anterior</a>
                                    <a
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                        className={'text-center'}
                                        onClick={proximaFonte}
                                    >Próxima Fonte</a>
                                </p> :
                                <p className='text-soft fw-light small text-orange'>
                                    Use mouse ou o tela do seu celular para assinar
                                </p>
                            }

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: '#dc3545', border: 'none' }} variant="secondary" size='sm' onClick={() => { setAssinar(false) }}>
                    Fechar
                </Button>
                <Button style={{ backgroundColor: 'var(--primary-base)', border: 'none' }} variant="primary" disabled={(salvarEnvelope || !formularioAssinatura.isValid)} size='sm' onClick={() => { formularioAssinatura.handleSubmit() }}>
                    Assinar
                </Button>
            </Modal.Footer>
        </Modal>
    }

    function visualizarEnvelope() {

        return (<>
            <div className="col-md-12" style={{ textAlign: 'initial' }}>
                <div className="row">
                    {envelope &&
                        <CardEnvelopeDados
                            publico={true}
                            carregarEnvelope={carregarEnvelope}
                            envelope={envelope}
                            setEnvelope={setEnvelope}
                            itensStyle={itenEstilo}
                        >
                            {envelope && envelope.signatarios[0].status === 1 &&
                                <div className="col-12 mb-1 text-orange">
                                    <button type="button" className="btn btn-orange pt-2 w-100 text-orange" onClick={() => { setAssinar(true) }}>
                                        <i className="fas fa-file-signature "></i> Assinar
                                    </button>
                                </div>
                            }
                        </CardEnvelopeDados>
                    }
                    <div className="envelope-documentos">
                        {envelope && envelope.documentos.map((documento, index) => (
                            <VisualizarDocumentos
                                key={index}
                                style={{
                                    width: '100%'
                                }}
                                url={documento.documentoUrl}
                            >
                                <div className="pdf-pagina-conteiner carregando">
                                    <div className="capa">
                                        <i>
                                            <AiOutlineFileSync />
                                        </i>
                                    </div>
                                </div>
                            </VisualizarDocumentos>
                        ))}
                        {envelope && envelope?.documentoAssinadoIdUrl?.length &&
                            <VisualizarDocumentos
                                style={{
                                    width: '100%'
                                }}
                                url={envelope.documentoAssinadoIdUrl}
                            >
                                <div className="pdf-pagina-conteiner carregando">
                                    <div className="capa">
                                        <i>
                                            <AiOutlineFileSync />
                                        </i>
                                    </div>
                                </div>
                            </VisualizarDocumentos>
                        }
                    </div>
                </div>
            </div>
        </>)
    }

    function conteudo() {

        if (carregandoEnvelope) {
            return <p className='text-center'>
                <img
                    alt="Carregando"
                    style={{
                        width: '200px',
                        margin: "0 auto",
                        animation: 'pulsing 2s ease-in-out infinite',
                    }}
                    src={process.env.PUBLIC_URL + '/images/logo-xjur.png'}
                />
            </p>
        } else if (envelope) {
            return visualizarEnvelope()
        }
        else {
            return <>
                <p className='text-center' style={{ fontSize: '8em', color: '#ffa35f' }}>
                    404
                </p>
                <h6> Documento não encontrado ou não disponível para assinatura.</h6>
            </>
        }
    }

    useEffect(() => {
        carregarEnvelope();
    }, []);

    return <>
        <main className="public-xsign main-orange">
            <div className="pro-sidebar-header navbar">
                <Link className="" to="/home">
                    <img className="ms-6" style={{ filter: 'brightness(0) invert(1)', maxHeight: '30px' }} src={process.env.PUBLIC_URL + '/images/logo2.png'} alt="Orange" />
                </Link>
            </div>
            <div className='conteudo'>
                {conteudo()}
            </div>
            <div style={{ padding: '2em 0 1em 0', color: '#fff', textAlign: 'center' }}>XJUR</div>
            {assinarEnvelope()}
        </main>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link href={`https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Great+Vibes&family=Herr+Von+Muellerhoff&family=Mr+De+Haviland&family=Qwigley&family=Sacramento&family=Stalemate&display=swap`} rel="stylesheet"></link>
    </>
}



export default AssinarEnvelope;
