import { useContext } from "react";
import { faBackward, faPaperPlane, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Node } from "reactflow";
import Swal from "sweetalert2";
import * as Yup from 'yup';

import FluxoService from "../../../services/FluxoService";
import { IFase } from "../../../interfaces/IFase";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import NodeProvedor from "./workFlow/nodes/NodeOpcao";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { notifyDanger, notifyFormikDanger, notifySuccess } from "../../../components/Comum/Toast/ToastFormik";

const FluxoPage = () => {

  const navigate = useNavigate();

  const { fluxoFase, setFluxoFase } = useContext(OrangeContext);

  const schema = Yup.object().shape({
    nodes: Yup.array()
      .of(
        Yup.object().shape({
          data: Yup.object().shape({
            faseInicial: Yup.boolean(),
          }),
        })
      )
      .test(
        "faseInicial",
        "Sem fase incial definida",
        (nodes: any) => nodes.some((node: Node) => node.data.faseInicial)
      ),
    edges: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      nodes: JSON.parse(fluxoFase?.jsonContent)?.nodes || [],
      edges: JSON.parse(fluxoFase?.jsonContent)?.edges || [],
    },
    validationSchema: schema,

    onSubmit: async (jsonContent) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        const result = await swalWithBootstrapButtons.fire({
          title: "Salvar Fluxo?",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await FluxoService.atualizaFluxo({ fluxoId: fluxoFase.fluxoId, jsonContent });

          let fluxo = await FluxoService.obterFasesPorId(fluxoFase.fluxoId);

          setFluxoFase({ ...fluxoFase, jsonContent: await atualizarFluxoFaseId(jsonContent, fluxo.fases) });

          notifySuccess('Fluxo atualizado com sucesso.');
        }
      } catch (error: any) {
        notifyDanger('Existe fase não configurada, favor verificar.');
      }
    },
  });

  const atualizarFluxoFaseId = async (jsonContent: any, fases: IFase[]) => {
    jsonContent.nodes.forEach((node: any) => {
      const fase = fases.find((fase) => fase.codigo === node.data.codigo);
      if (fase) node.data.fluxosFaseId = fase.fluxosFasesId;
    });

    notifySuccess('Parametrizações liberadas para configuração.');

    await FluxoService.atualizaFluxo({ fluxoId: fluxoFase.fluxoId, jsonContent });

    return JSON.stringify(jsonContent);
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageTitle title={"#" + fluxoFase.fluxoId + " " + fluxoFase.nome}>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button type="button" onClick={() => navigate("/Fluxo")} className="btn btn-orange">
            <FontAwesomeIcon color={'white'} className='mx-2' icon={faBackward} />
          </button>
          <button type="button" onClick={() => navigate("/Fluxo/FluxoDesfazer")} className="btn btn-orange">
            <FontAwesomeIcon color={'white'} className='mx-2' icon={faUndo} />
          </button>
          <button type="button" onClick={() => {
            formik.handleSubmit();
            notifyFormikDanger(formik.errors);
          }} className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
            <FontAwesomeIcon color={'white'} className='mx-2' icon={faPaperPlane} />
          </button>
        </div>
      </LayoutPageTitle>

      <div style={{ width: '100%', height: "100%" }}>
        <NodeProvedor initialEdges={formik.values.edges} initialNodes={formik.values.nodes} onNodesEdgesChange={(data: any) => formik.setValues(data)} />
      </div>
    </>
  );
};

export default FluxoPage;
