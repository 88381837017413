import { useContext, useEffect, useState } from "react";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";

import { defaultSchema } from "../../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../../enum";
import LayoutPageButton from "../../../layout/LayoutPageButton";

import AudienciaService from "../../../services/AudienciaService";
import sessionStorageService from "../../../services/sessionStorage/sessionStorageService";

import Carregando from "../../Carregando";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import CamposCapaSolucoes from "../Solucao/CamposCapaSolucoes";
import FileListCapaField from "../../FileUpload/FileListCapaField";

interface IFieldEditProps {
  toggleModal: () => void;
  exibirModal: boolean;
}

const AudienciaEditModal = ({
  toggleModal,
  exibirModal,
}: IFieldEditProps) => {
  const { audiencia, tipoAudiencia, setAudiencia, processo, contrato } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);

  const initialValues: any = {
    audienciaId: audiencia?.audienciaId,
    principalId: audiencia?.audienciaId,
    tipoAudienciaId: audiencia?.tipoAudienciaId,

    modulo: EModulo.Audiencia,
    tela: ETelaEnum.TelaCapaAudiencia,
    categoriaId: audiencia?.categoriaId,

    processoId: processo?.processoId,
    areaDireitoId: processo?.areaDireitoId,

    contratoId: contrato?.contratoId,
    tipoContratoId: contrato?.tipoContratoId,

    dataaudiencia: null,
    descricao: "",
    codigo: "",
    status: "",
    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (exibirModal) {
      if (!audiencia?.audienciaId) return;
      carregaCapa();
      formik.setValues(initialValues);
    }
  }, [audiencia?.audienciaId, refreshCapa, exibirModal]);

  useEffect(() => {
    if (!audiencia?.audienciaId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [audiencia]);

  const inserirSessionStorage = () => sessionStorageService.inserir(audiencia, "audiencia_capa");

  const carregarSessionStorage = async () => setAudiencia(JSON.parse((await sessionStorageService.obter("audiencia_capa")) || null));

  const carregaCapa = async () => {
    try {
      setCarregandoConfiguracoesTela(true);
      let resultado = await AudienciaService.obterCapaAudiencia(audiencia?.audienciaId);
      setConfiguracoesTela(resultado.data);
      setCarregandoConfiguracoesTela(false);
    } catch (error: any) {
      setCarregandoConfiguracoesTela(false);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const renderCampos = () => {
    let component: any[] = [];
    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaSolucoes
          controller={"audiencia"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });
    return <div key={audiencia?.audienciaId} className="row mt-12">{component}</div>;
  };

  return (
    <>
      <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <ToastContainer />
          <div className="modal-header">
            <LayoutPageButton
              title={`Editar #${audiencia?.audienciaId}  ${tipoAudiencia?.tipoAudienciaId > 0 ? " - " + tipoAudiencia?.nome : ""} => ${audiencia?.status}`}
            >
              <>
                {!carregandoConfiguracoesTela && (
                  <a
                    style={{ cursor: "pointer", fontSize: "30px" }}
                    onClick={() => setRefreshCapa(!refreshCapa)}
                  >
                    <FontAwesomeIcon
                      color={"#DC853D"}
                      className="mx-2"
                      icon={faHistory}
                    />
                  </a>
                )}
                {carregandoConfiguracoesTela && (
                  <span
                    className="indicator-progress"
                    style={{
                      display: "block",
                      fontSize: "30px",
                      paddingLeft: "11px",
                      paddingRight: "11px",
                    }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </>
            </LayoutPageButton>
            <br />
          </div>
          <div className="modal-body">
            {carregandoConfiguracoesTela ? (
              <Carregando />
            ) : (
              <>
                {renderCampos()}
                <FileListCapaField modulo={EModulo.Audiencia} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
              </>
            )}
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }} />
        </div>
      </Modal>
    </>
  );
};

export default AudienciaEditModal;
