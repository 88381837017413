import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import { validateCNPJ, validateCPF } from "validations-br";
import * as Yup from "yup";
import { FieldText } from "../../../components/Comum/Fields/FieldText";
import { IPessoa } from "../../../interfaces/IPessoa";
import AdicionaPessoaRequest from "../../../interfaces/Requests/AdicionaPessoaRequest";
import PessoaService from "../../../services/PessoaService";
import { MASCARA_CNPJ, MASCARA_CPF } from "../../../utils/Constants";
import DatePicker from "react-datepicker";
import { ptBR } from "date-fns/locale";

interface AdicionaPessoaProps {
  handlePessoa: (pessoa: IPessoa) => void;
  numeroDocumento: string;
  tipoDocumento: string;
  nome?: string;
  hrefCancelar?: any;
  modalCancelar?: any;
  disabledsInputs?: boolean;
  disableErrors?: boolean;
}

const AdicionaPessoa = (props: AdicionaPessoaProps) => {
  const adicionaPessoaSchema = Yup.object().shape({
    numeroDocumento: Yup.string().test("validar-numero-documento", "Documento inválido", (value) => {
      if (!value) {
        return false;
      }

      if (formik.values.tipoDocumento === "CNPJ") {
        let cnpjValidar = value.replace(/\./g, "").replace(/-/g, "").replace("/", "").replace(/_/g, "");

        if (cnpjValidar.length !== 14) {
          return false;
        }

        return validateCNPJ(cnpjValidar);
      } else if (formik.values.tipoDocumento === "CPF") {
        let cpfValidar = value.replace(/\./g, "").replace(/-/g, "").replace(/_/g, "");

        if (cpfValidar.length !== 11) {
          return false;
        }

        return validateCPF(cpfValidar);
      } else {
        return true;
      }
    }),
    nome: Yup.string().min(4).required(),
    nomeSocial: Yup.string().min(4).required(),
    tipoDocumento: Yup.string().required(),
  });

  const initialValues: AdicionaPessoaRequest = {
    nome: props.nome || '',
    nomeSocial: props.nome || '',
    numeroDocumento: props.numeroDocumento,
    tipoDocumento: props.tipoDocumento,
    dataNascimento: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaPessoaSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let result = await PessoaService.adicionaPessoa(values);
        const swalConfirm = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let modalConfirm = await swalConfirm.fire({
          text: `Pessoa cadastrada com sucesso`,
          icon: "success",
          showCancelButton: false,
          confirmButtonText: `Ok`,
        });

        if (modalConfirm.isConfirmed) {
          props.handlePessoa(result);
        }
      } catch (error: any) {
        if (props.disableErrors) {
          const pessoaExistente = await PessoaService.buscarPessoaPorDocumento(values.numeroDocumento);
          if (pessoaExistente.data.length > 0) {
            props.handlePessoa(pessoaExistente.data[0])
          }
        } else {
          Swal.fire({
            heightAuto: false,
            icon: "error",
            title: `Ocorreu um erro ao tentar cadastrar Pessoa`,
            text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
            showConfirmButton: true,
          });
        }

        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("tipoDocumento", props.tipoDocumento);
    formik.setFieldValue("numeroDocumento", props.numeroDocumento);
  }, []);

  return (
    <>
      <h3 className="text-orange mb-3">{''}</h3>
      <form id="form-add-pessoa-modal" onSubmit={(e) => { e.preventDefault(); formik.submitForm(); }} >
        <Row>
          <div className="col-12 col-md-6 col-lg-6">
            <FieldText
              {...formik.getFieldProps("nome")}
              errors={formik.errors}
              touched={formik.touched}
              placeholder={'Nome'}
              name={'nome'}
              label={'Nome'}
            />
          </div>


          <div className="col-12 col-md-6 col-lg-6">
            <FieldText
              {...formik.getFieldProps("nomeSocial")}
              errors={formik.errors}
              touched={formik.touched}
              name={'nomeSocial'}
              placeholder={'Nome Social'}
              label={'Nome Social'}
            />
          </div>


          <div className="col-12 col-md-12 col-lg-6 mt-3">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Tipo Documento
            </label>
            <select
              disabled={props.disabledsInputs}
              value={formik.values.tipoDocumento}
              onChange={(e) => {
                formik.setFieldValue("tipoDocumento", e.target.value);
                formik.setFieldValue("numeroDocumento", "");
              }}
              placeholder="Tipo Documento"
              className={"form-select"}
              id="form-select"
            >
              <option value="CPF" label="CPF">
                {" "}
                CPF{" "}
              </option>
              <option value="CNPJ" label="CNPJ">
                {" "}
                CNPJ{" "}
              </option>
              <option value="OUTROS" label="Outros">
                {" "}
                Outros{" "}
              </option>
            </select>
          </div>

          {formik.values.tipoDocumento === "CPF" && (
            <div className="col-12 col-md-6 col-lg-6 mt-3">
              <label htmlFor="form-numeroDocumento" className="form-label fw-bolder text-orange">
                CPF
              </label>
              <MaskedInput
                disabled={props.disabledsInputs}
                mask={MASCARA_CPF}
                {...formik.getFieldProps("numeroDocumento")}
                placeholder="000.000.000-00"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.numeroDocumento && formik.errors.numeroDocumento,
                  },
                  {
                    "is-valid": formik.touched.numeroDocumento && !formik.errors.numeroDocumento,
                  }
                )}
                id="form-numeroDocumento"
              />
            </div>
          )}

          {formik.values.tipoDocumento === "CNPJ" && (
            <div className="col-12 col-md-6 col-lg-6 mt-3">
              <label htmlFor="form-numeroDocumento" className="form-label fw-bolder text-orange">
                CNPJ
              </label>
              <MaskedInput
                disabled={props.disabledsInputs}
                mask={MASCARA_CNPJ}
                {...formik.getFieldProps("numeroDocumento")}
                placeholder="00.000.000/0000-00"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.numeroDocumento && formik.errors.numeroDocumento,
                  },
                  {
                    "is-valid": formik.touched.numeroDocumento && !formik.errors.numeroDocumento,
                  }
                )}
                id="form-numeroDocumento"
              />
            </div>
          )}

          {formik.values.tipoDocumento === "OUTROS" && (
            <div className="col-12 col-md-6 col-lg-6 mt-3">
              <label htmlFor="form-numeroDocumento" className="form-label fw-bolder text-orange">
                Documento
              </label>
              <input
                disabled={props.disabledsInputs}
                {...formik.getFieldProps("numeroDocumento")}
                placeholder=""
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.numeroDocumento && formik.errors.numeroDocumento,
                  },
                  {
                    "is-valid": formik.touched.numeroDocumento && !formik.errors.numeroDocumento,
                  }
                )}
                id="form-numeroDocumento"
              />
            </div>
          )}

          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <label htmlFor="form-dataNascimento" className="form-label required fw-bolder text-orange">
              Data do Nascimento:
            </label>
            <DatePicker
              locale={ptBR}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/MM/yyyy"
              className={"form-control"}
              selected={formik.values.dataNascimento}
              onChange={(date: Date) => formik.setFieldValue("dataNascimento", date)}
            />

            {formik.touched.dataNascimento && formik.errors.dataNascimento && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span style={{ fontSize: "11px" }} role="alert">
                    {formik.errors.dataNascimento}
                  </span>
                </div>
              </div>
            )}
          </div>

        </Row>

      </form>
    </>
  );
}

export default AdicionaPessoa;