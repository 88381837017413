import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoLocalidade from "../interfaces/ITipoLocalidade";
import { AdicionaTipoLocalidadeRequest } from "../interfaces/Requests/TipoLocalidade/AdicionaTipoLocalidadeRequest";
import FiltroPesquisaTipoLocalidadeRequest from "../interfaces/Requests/TipoLocalidade/FiltroPesquisaTipoLocalidadeRequest";
import { EditarTipoLocalidadeRequest } from "../interfaces/Requests/TipoLocalidade/EditarTipoLocalidadeRequest";

class TipoLocalidadeService {

    async obterTipoLocalidades(filtros: FiltroPesquisaTipoLocalidadeRequest): Promise<IRecuperaLista<ITipoLocalidade>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoLocalidade>>(`TipoLocalidade/RecuperaTipoLocalidade?${query}`);

        return result;
    }

    async adicionaTipoLocalidade(adicionaTipoLocalidade: AdicionaTipoLocalidadeRequest): Promise<ITipoLocalidade> {
        let { data: result } = await api.post<ITipoLocalidade>(`TipoLocalidade/AdicionaTipoLocalidade`, adicionaTipoLocalidade);

        return result;
    }


    async alterarStatus(tipoLocalidadeId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoLocalidade>(`TipoLocalidade/AtualizaTipoLocalidadePorId?id=${tipoLocalidadeId}`, data);
    }

    async atualizarTipoLocalidade(editarTipoLocalidade: EditarTipoLocalidadeRequest): Promise<void> {

        let dataTipoLocalidade = [
            { "op": "replace", "path": "/nome", "value": editarTipoLocalidade.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoLocalidade.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoLocalidade/AtualizaTipoLocalidadePorId?id=${editarTipoLocalidade.tipoLocalidadeId}`, dataTipoLocalidade)
        ]);
    }
}


export default new TipoLocalidadeService();