import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IAtividadeEmpresa from "../interfaces/IAtividadeEmpresa";
import { AdicionaAtividadeEmpresaRequest } from "../interfaces/Requests/AtividadeEmpresa/AdicionaAtividadeEmpresaRequest";
import FiltroPesquisaAtividadeEmpresaRequest from "../interfaces/Requests/AtividadeEmpresa/FiltroPesquisaAtividadeEmpresaRequest";
import { EditarAtividadeEmpresaRequest } from "../interfaces/Requests/AtividadeEmpresa/EditarAtividadeEmpresaRequest";

class AtividadeEmpresaService {

    async obterAtividadeEmpresas(filtros: FiltroPesquisaAtividadeEmpresaRequest): Promise<IRecuperaLista<IAtividadeEmpresa>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IAtividadeEmpresa>>(`AtividadeEmpresa/RecuperaAtividadeEmpresa?${query}`);

        return result;
    }

    async adicionaAtividadeEmpresa(adicionaAtividadeEmpresa: AdicionaAtividadeEmpresaRequest): Promise<IAtividadeEmpresa> {
        let { data: result } = await api.post<IAtividadeEmpresa>(`AtividadeEmpresa/AdicionaAtividadeEmpresa`, adicionaAtividadeEmpresa);

        return result;
    }


    async alterarStatus(atividadeEmpresaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IAtividadeEmpresa>(`AtividadeEmpresa/AtualizaAtividadeEmpresaPorId?id=${atividadeEmpresaId}`, data);
    }

    async atualizarAtividadeEmpresa(editarAtividadeEmpresa: EditarAtividadeEmpresaRequest): Promise<void> {

        let dataAtividadeEmpresa = [
            { "op": "replace", "path": "/nome", "value": editarAtividadeEmpresa.nome },
            { "op": "replace", "path": "/codigo", "value": editarAtividadeEmpresa.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`AtividadeEmpresa/AtualizaAtividadeEmpresaPorId?id=${editarAtividadeEmpresa.atividadeEmpresaId}`, dataAtividadeEmpresa)
        ]);
    }
}


export default new AtividadeEmpresaService();