import api from "./Api";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import IDecisao from "../interfaces/IDecisao";

import { EModulo, ETipoCampoEnum } from "../enum";

import { EditarDecisaoRequest } from "../interfaces/Requests/Decisao/EditarDecisaoRequest";
import { AdicionaDecisaoRequest } from "../interfaces/Requests/Decisao/AdicionaDecisaoRequest";
import FiltroPesquisaDecisaoRequest from "../interfaces/Requests/Decisao/FiltroPesquisaDecisaoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class DecisaoService {

    public async obterDecisoes(filtros: FiltroPesquisaDecisaoRequest): Promise<IRecuperaLista<IDecisao>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.status.length > 0) {
            filtros.status.forEach((id) => (query += `&status=${id}`));
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.decisaoId) {
            query += `&decisaoId=${filtros.decisaoId}`;
        }

        if (filtros.dataDecisaoMaiorIgual) {
            query += `&dataDecisaoMaiorIgual=${filtros.dataDecisaoMaiorIgual}`;
        }

        if (filtros.dataDecisaoMenorIgual) {
            query += `&dataDecisaoMenorIgual=${filtros.dataDecisaoMenorIgual}`;
        }

        if (filtros.prazoFatalMaiorIgual) {
            query += `&prazoFatalMaiorIgual=${filtros.prazoFatalMaiorIgual}`;
        }

        if (filtros.prazoFatalMenorIgual) {
            query += `&prazoFatalMenorIgual=${filtros.prazoFatalMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        if (filtros.dataInicioVigenciaMenorIgual) {
            query += `&dataInicioVigenciaMenorIgual=${filtros.dataInicioVigenciaMenorIgual}`;
        }

        if (filtros.dataInicioVigenciaMaiorIgual) {
            query += `&dataInicioVigenciaMaiorIgual=${filtros.dataInicioVigenciaMaiorIgual}`;
        }

        if (filtros.dataVencimentoMenorIgual) {
            query += `&dataVencimentoMenorIgual=${filtros.dataVencimentoMenorIgual}`;
        }

        if (filtros.dataVencimentoMaiorIgual) {
            query += `&dataVencimentoMaiorIgual=${filtros.dataVencimentoMaiorIgual}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IDecisao>>(`Decisao/RecuperaDecisao?${query}`);

        return result;
    }


    public async obterDecisaoPorId(decisaoId: number): Promise<IRecuperaLista<IDecisao>> {

        let { data: result } = await api.get<IRecuperaLista<IDecisao>>(`Decisao/RecuperaDecisao?decisaoId=${decisaoId}`);

        return result;
    }
    public async adicionaDecisao(adicionaDecisao: AdicionaDecisaoRequest): Promise<IDecisao> {
        let { data: result } = await api.post<IDecisao>(`Decisao/AdicionaDecisao`, this.montarObjetoRequest(adicionaDecisao));

        return result;
    }

    public async adicionaFavorido(decisaoId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Decisao, decisaoId: decisaoId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async obterCapaDecisao(decisaoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Decisao/RecuperaCapaDecisao?decisaoId=${decisaoId}`);
        return result;
    }

    public async editarDecisao(editarDecisao: AdicionaDecisaoRequest) {
        let request = this.montarObjetoArray(editarDecisao);

        if (request.length > 0) {
            let result = await api.patch(`Decisao/AtualizaDecisaoPorId?id=${editarDecisao.decisaoId}`, request);
            return result;
        }
    }

    public async alterarStatus(decisaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IDecisao>(`Decisao/AtualizaDecisaoPorId?id=${decisaoId}`, data);
    }

    public async atualizarDecisao(editarDecisao: EditarDecisaoRequest): Promise<void> {

        let dataDecisao = [
            { "op": "replace", "path": "/codigo", "value": editarDecisao.codigo },
            { "op": "replace", "path": "/descricaoDecisao", "value": editarDecisao.teorDecisao }
        ]


        await Promise.all([
            await api.patch<void>(`Decisao/AtualizaDecisaoPorId?id=${editarDecisao.decisaoId}`, dataDecisao)
        ]);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Decisao/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarDecisaoPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Decisao/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    public concluirCompromisso = async (decisao: AdicionaDecisaoRequest): Promise<any> => {

        let result: any[] = [];


        // Salvar campos normais
        result.push(await this.editarDecisao(decisao));

        // Salvar xcampos
        if (decisao.xCampoValorList?.length > 0) {
            decisao.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, decisaoId: decisao.decisaoId, texto: xCampo.texto }));
                        else result.push(await this.alterarDecisaoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, decisaoId: decisao.decisaoId, data: xCampo.data }));
                        else result.push(await this.alterarDecisaoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, decisaoId: decisao.decisaoId, valor: xCampo.valor }));
                        else result.push(await this.alterarDecisaoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, decisaoId: decisao.decisaoId, inteiro: xCampo.inteiro }));
                        else result.push(await this.alterarDecisaoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, decisaoId: decisao.decisaoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarDecisaoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        return result;
    };

    private montarObjetoRequest = (decisao: AdicionaDecisaoRequest) => {
        let request: any = {};

        if (decisao.xCampoValorList.length > 0) request.xCampoValorList = decisao.xCampoValorList;

        if (decisao.tipoDecisaoId) request.tipoDecisaoId = decisao.tipoDecisaoId;

        if (decisao.codigo) request.codigo = decisao.codigo;
        if (decisao.teordecisao) request.teorDecisao = decisao.teordecisao;
        if (decisao.detalhesrecurso) request.detalhesRecurso = decisao.detalhesrecurso;
        if (decisao.detalhesobrigacao) request.detalhesObrigacao = decisao.detalhesobrigacao;
        if (decisao.datadecisao) request.dataDecisao = decisao.datadecisao;
        if (decisao.prazofatal) request.prazoFatal = decisao.prazofatal;
        if (decisao.possuirecurso) request.possuiRecurso = decisao.possuirecurso;
        if (decisao.possuiobrigacao) request.possuiObrigacao = decisao.possuiobrigacao;

        if (decisao.processoId) {
            request.modulo = EModulo.AreaDireito;
            request.processoId = decisao.processoId;
            request.areaDireitoId = decisao.areaDireitoId;
        }

        if (decisao.imovelId) {
            request.modulo = EModulo.Imovel;
            request.imovelId = decisao.imovelId;
            request.tipoImovelId = decisao.tipoImovelId;
        }

        return request;
    };

    private montarObjetoArray = (despesa: AdicionaDecisaoRequest) => {
        let request: any = [];

        // Campos texto
        if (despesa.codigo) request.push({ op: "replace", path: "/codigo", value: despesa.codigo });
        if (despesa.teordecisao) request.push({ op: "replace", path: "/teorDecisao", value: despesa.teordecisao });
        if (despesa.detalhesrecurso) request.push({ op: "replace", path: "/detalhesRecurso", value: despesa.detalhesrecurso });
        if (despesa.detalhesrecurso) request.push({ op: "replace", path: "/detalhesRecurso", value: despesa.detalhesrecurso });

        // Campos data
        if (despesa.datadecisao) request.push({ op: "replace", path: "/dataDecisao", value: despesa.datadecisao });
        if (despesa.prazofatal) request.push({ op: "replace", path: "/prazoFatal", value: despesa.prazofatal });


        return request;
    };
}


export default new DecisaoService();