import { faBalanceScale, faExchangeAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const PopoverSubMenuLicenca = (isProcesso?: boolean) => {
  const navigate = useNavigate();

  const tooltip = (title: string) =>  <Tooltip id={`button-tooltip-${title}`}> {title} </Tooltip>

  return (
    <Popover id="popover-basic">
      <Popover.Body style={{ cursor: "pointer" }}>
        <p>

          <OverlayTrigger placement="bottom" overlay={tooltip("Voltar para Capa da Licença")}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Licenca/Capa")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
              </Button>
            )}
          </OverlayTrigger>

          {isProcesso &&
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Novo Processo</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  {...triggerHandler}
                  onClick={() => navigate("/Regulatorio/Processo/Novo")}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref}>
                    <FontAwesomeIcon color={"var(--primary-base2)"} className="mx5" icon={faPlus} />
                  </span>
                </Button>
              )}
            </OverlayTrigger>
          }


          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Processos</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Processo/Regulatorio")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faBalanceScale}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>




          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Logs</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                style={{
                  border: "none",
                  background: "none",
                }}
                {...triggerHandler}
                onClick={() => navigate("/Licenca/Logs")}
                className="d-inline-flex align-items-center"
              >
                <span ref={ref}>
                  <FontAwesomeIcon color={"#D2691E"} className="mx5" icon={faExchangeAlt}></FontAwesomeIcon>
                </span>
              </Button>
            )}
          </OverlayTrigger>

        </p>
      </Popover.Body>
    </Popover>
  );
};
