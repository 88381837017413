import { useState } from "react";
import { Modal } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import ICliente from "../../interfaces/ICliente";
import IEscritorio from "../../interfaces/IEscritorio";
import IAreaDireito from "../../interfaces/IAreaDireito";
import FiltroPesquisaEscritorioRequest from "../../interfaces/Requests/Escritorio/FiltroPesquisaEscritorioRequest";

import GridPadrao from "../../components/Comum/GridPadrao";

interface IClienteModalProps {
  toggleDetalhesModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  Cliente?: ICliente;
  titulo: string;
}

const ClienteDetalheModal = ({ toggleDetalhesModal, exibirModal, Cliente, titulo }: IClienteModalProps) => {
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEscritorioRequest>({
    clienteId: 0,
    nome: "",
    codigo: "",
    limit: 10,
    isTelaPesquisaProcesso: false,
    totalItems: 0,
    offset: 0,
    sort: "escritorioId",
  });

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAreaDireito>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const colunasEscritorio: TableColumn<IEscritorio>[] = [
    {
      name: "Id",
      sortField: "escritorioId",
      selector: (row: IEscritorio) => row.escritorioId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "escritorioNome",
      selector: (row: IEscritorio) => row.escritorioNome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IEscritorio) => row.codigo,
      sortable: true,
      wrap: true,
    },
  ];

  const colunasAreaDireito: TableColumn<IAreaDireito>[] = [
    {
      name: "Id",
      sortField: "escritorioId",
      selector: (row: IAreaDireito) => row.areaDireitoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "escritorioNome",
      selector: (row: IAreaDireito) => row.nome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IAreaDireito) => row.codigo,
      sortable: true,
      wrap: true,
    },
  ];

  const render = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-6 mb-1">
            <label htmlFor="form" className="form-label fw-bolder text-orange">
              Código
            </label>
            <br />
            {Cliente?.codigo}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6 mb-1">
            <label htmlFor="form" className="form-label fw-bolder text-orange">
              Nome
            </label>
            <br />
            {Cliente?.clienteNome}
          </div>
          <div className="col-md-6 mb-1">
            <label htmlFor="form" className="form-label fw-bolder text-orange">
              Nome Social
            </label>
            <br />
            {Cliente?.clienteNomeSocial}
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6 mb-1">
            <label htmlFor="form" className="form-label fw-bolder text-orange">
              Documento
            </label>
            <br />
            {Cliente?.clienteDocumento}
          </div>
        </div>

        {Cliente && Cliente?.escritorios && Cliente?.escritorios.length > 0 && (
          <div id="Escritorio">
            <div className="col-md-12 mb-2">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                Escritórios
              </label>
              <div className="col-md-12 mb-2" style={{ overflow: "auto", height: (Cliente?.escritorios || []).length > 5 ? "250px" : "160px" }}>
                <GridPadrao
                  onSort={handleSort}
                  progressPending={false}
                  limit={filtrosPesquisa.limit}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationServer={true}
                  paginationTotalRows={filtrosPesquisa.totalItems}
                  colunas={colunasEscritorio}
                  tipo="Escritorio"
                  itens={Cliente?.escritorios || []}
                />
              </div>
            </div>
          </div>
        )}
        {Cliente &&  Cliente?.areasDireito && Cliente?.areasDireito.length > 0 && (
          <div id="AreasDireito">
            <div className="col-md-12 mb-2">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                Área do Direito
              </label>
              <div className="col-md-12 mb-2" style={{ overflow: "auto", height: "240px" }}>
                <GridPadrao
                  onSort={handleSort}
                  progressPending={false}
                  limit={filtrosPesquisa.limit}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationServer={true}
                  paginationTotalRows={filtrosPesquisa.totalItems}
                  colunas={colunasAreaDireito}
                  tipo="AreaDireito"
                  itens={Cliente?.areasDireito || []}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleDetalhesModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}</h5>
            <div onClick={() => toggleDetalhesModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">{render()}</form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClienteDetalheModal;
