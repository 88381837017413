import { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { optionsType } from "../../../../../components/Comum/Fornecedor/FornecedorField";
import { MigrationFieldsType } from "../components/DragDropTableMigration";

interface IColunaSelecaoModal {
  exibirModal: boolean;
  handleClose: () => void;
  headers: optionsType[];
  updateCreateRequest: (
    key: string,
    value: number | string,
    remove?: boolean,
    objectNameProp?: boolean
  ) => void;
  fieldId: string;
  setCancelar: ({
    flag,
    draggableId,
  }: {
    flag: boolean;
    draggableId: string;
  }) => void;
  fields: MigrationFieldsType[];
}

const ColunaSelecaoModal = ({
  exibirModal,
  handleClose,
  headers,
  updateCreateRequest,
  fieldId,
  setCancelar,
  fields,
}: IColunaSelecaoModal) => {
  const [coluna, setColuna] = useState<optionsType>({
    label: "",
    value: -1,
  });

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Propriedade:{" "}
            {
              fields.filter((field) => field.requestName === fieldId)[0]
                .propertyName
            }
          </h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder"
              >
                Coluna Correspondente:
              </label>

              <Select
                placeholder="Selecione uma Coluna"
                id="form-select"
                options={headers}
                isSearchable
                value={coluna}
                onChange={(select) => {
                  if (select) {
                    setColuna(select);
                  }
                }}
              />
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            type="button"
            className="btn btn-orange me-5"
            onClick={() => {
              handleClose();
              updateCreateRequest(fieldId, coluna.value, false, true);
            }}
            disabled={
              coluna.label === "Selecione uma sheet!" || coluna.value === -1
            }
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              setCancelar({ flag: true, draggableId: fieldId });
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ColunaSelecaoModal;
