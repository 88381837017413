
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import PowerBI from "../../components/Comum/PowerBI/PowerBI";
import { EModulo } from "../../enum";
import "./style/icons.css";

const ImovelDashboardPage = () => {

  return (
    <>
      <LayoutPageTitle title="Dashboard Imobiliário" />
      <PowerBI modulo={EModulo.Imovel} />
    </>
  );
}

export default ImovelDashboardPage;
