import React, { useState } from 'react';
import Swal from "sweetalert2";
import { DragDropContext, Droppable, Draggable, NotDraggingStyle, DraggingStyle } from 'react-beautiful-dnd';
import { Dropdown } from 'react-bootstrap';

import IEnvelope from '../../../interfaces/IEnvelope';
import EnvelopeService from '../../../services/EnvelopeService';
import IEnvelopeSignatario from '../../../interfaces/IEnvelopeSignatario';
import { SignatariosListItem } from './SignatariosListItem';

export default function SignatarioDragAndDrop(props: { envelope: IEnvelope; signatarioEditar?: IEnvelopeSignatario; removerSignatario: (signatario: IEnvelopeSignatario) => void; setSignatarioEditar: (value: IEnvelopeSignatario | undefined) => void; }) {

    const [carregandoSignatarios, setCarregandoSignatarios] = useState(false);

    const reenviarLink = async (signatario: IEnvelopeSignatario) => {
        try {
            Swal.fire({
                heightAuto: false,
                title: 'Enviando link',
                text: '',
                icon: 'info',
                showConfirmButton: false
            });
            Swal.showLoading();

            await EnvelopeService.reenviarLink(signatario.hashSignatario);

            Swal.close();

            Swal.fire({
                heightAuto: false,
                title: 'Link reenviado com sucesso',
                text: '',
                timer: 2000,
                icon: 'success',
                showConfirmButton: false
            });
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível reenviar o link',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
        }
    }

    const reordenar = (itens: IEnvelopeSignatario[], indiceInicio: number, indiceFinal: number) => {
        const itensOrdenados = Array.from(itens);
        const [removido] = itensOrdenados.splice(indiceInicio, 1);
        itensOrdenados.splice(indiceFinal, 0, removido);
        return itensOrdenados;
    }

    const itenEstilo = (arrastando: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): React.CSSProperties => {
        return {
            userSelect: "none",
            padding: 16,
            margin: `0 0 .25rem 0`,
            border: `.05rem dashed ${arrastando ? '#000' : '#ddd'}`,
            background: arrastando ? '#fafafa' : 'white',
            position: 'relative',
            ...draggableStyle
        };
    }

    const listaEstilo = (arrastando: boolean) => {
        return {
            background: arrastando ? "#ec945350" : "transparent",
            padding: 0,
            marginBottom: '1rem',
            width: '100%'
        };
    }

    const onDragEnd = (result: any): void => {
        if (!result.destination) {
            return;
        }

        if (result.source.index === result.destination.index)
            return;

        setCarregandoSignatarios(true);

        const itensOrdenados = reordenar(
            props.envelope.signatarios,
            result.source.index,
            result.destination.index
        );

        itensOrdenados.forEach((item: any, index: any) => {
            item.ordemAssinatura = index;
        });

        if (props.envelope.status === 1) {
            EnvelopeService.atualizarSignatarios(
                {
                    ...props.envelope,
                    signatarios: itensOrdenados
                }
            ).then(() => {
                props.envelope.signatarios = [
                    ...itensOrdenados
                ];
            }).finally(() => {
                setCarregandoSignatarios(false);
            });
        } else if (props.envelope.status === -2) {
            props.envelope.signatarios = [
                ...itensOrdenados
            ];
            setCarregandoSignatarios(false);
        }
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="signatarios">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={listaEstilo(snapshot.isDraggingOver)}
                    >
                        {props.envelope.signatarios.sort(
                            (a, b) => a.ordemAssinatura - b.ordemAssinatura).map((signatario, index) => (
                                <Draggable
                                    key={signatario.signatarioId ? signatario.signatarioId : (-10 - index)}
                                    draggableId={signatario.signatarioId ? signatario.signatarioId.toString() : (-10 - index).toString()}
                                    index={index}
                                    isDragDisabled={carregandoSignatarios || (props.envelope.envelopeId > 0)}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={itenEstilo(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <SignatariosListItem
                                                signatario={signatario}
                                                carregando={(props.envelope.status > 0 && (!signatario.signatarioId || signatario.signatarioId <= 0 || carregandoSignatarios))}
                                            >
                                                {props.envelope.status !== -1 && props.envelope.status < 3 && (signatario.status === 1 || signatario.status === undefined) &&
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="outline-secondary">
                                                            Opções
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {(((signatario.status || 1) === 1 || (signatario.signatarioId || 0) < 0)) &&
                                                                <>
                                                                    <Dropdown.Item onClick={() => {
                                                                        props.setSignatarioEditar(signatario);
                                                                    }}>Editar</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => props.removerSignatario(signatario)}>Remover</Dropdown.Item>
                                                                    {((signatario.signatarioId || 0) > 0 && signatario.status === 1) &&
                                                                        <Dropdown.Item onClick={() => reenviarLink(signatario)}>Reenviar Link</Dropdown.Item>}
                                                                </>}
                                                        </Dropdown.Menu>
                                                    </Dropdown>}
                                            </SignatariosListItem>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
