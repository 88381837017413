import { useCallback, useEffect, useRef, useState } from 'react';
import { useStore, getStraightPath, useReactFlow } from 'reactflow';
import ENodeEnum from '../../../../../enum/ENodeEnum';
import { getEdgeParams } from './utils.js';
import { FiTrash } from 'react-icons/fi';
import '../nodes/styles/NodeCondicao.css';
import sessionStorageService from '../../../../../services/sessionStorage/sessionStorageService';
import Swal from 'sweetalert2';
import { ICondicao } from '../../../../../interfaces/IFase';


const FloatingEdge = ({ id, data: initialData, source, target }: { id: any, data: any, source: any, target: any, markerEnd: any, style: any }) => {

  const { getNodes } = useReactFlow();

  const sourceNode = useStore(useCallback((store: any) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store: any) => store.nodeInternals.get(target), [target]));

  const [data, setData] = useState(initialData);
  const [textBBox, setTextBBox] = useState<DOMRect | null>(null);
  const [exibirModal, setExibirModal] = useState(false);

  const textRef = useRef<SVGTextElement | null>(null);
  const rectRef = useRef<SVGRectElement | null>(null);

  const condicao = sourceNode?.data?.condicoes?.find((e: any) => e.codigo == `${target}${source}`);

  const onClick = () => setExibirModal(!exibirModal);

  useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox();
      setTextBBox(bbox);
      if (rectRef.current) {
        rectRef.current.setAttribute('width', String(bbox.width > 10 ? bbox.width + 10 : 10));
        rectRef.current.setAttribute('height', String(bbox.height > 3 ? bbox.height + 4 : 10));
      }
    }
  }, [textRef.current, setTextBBox, data.label]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  if (!sourceNode || !targetNode) return null;

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  const labelX = (sx + tx) / 2;
  const labelY = (sy + ty) / 2;

  const atualizar = (chave: any, valor: any) => {

    sessionStorageService.inserir(chave, valor);

    const evento = new CustomEvent('localStorageAtualizado', { detail: { chave, valor } });

    window.dispatchEvent(evento);
  };

  const onExcluir = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-orange",
        cancelButton: "btn btn-danger ms-5",
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Excluir",
      html: "Você realmente deseja excluir?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    });

    if (result.isConfirmed) {

      getNodes().forEach((node) => {
        if (node.data?.condicoes && node.type === ENodeEnum.NodeCondicao) {
          const updatedCondicoes = node.data.condicoes.filter((condicao: ICondicao) => condicao.codigoProximaFaseId !== target);

          if (updatedCondicoes.length !== node.data.condicoes.length) {
            atualizar("atualizaNode", JSON.stringify({ data: { ...node.data, condicoes: updatedCondicoes }, id: node.id }));
          }
        }
      });

      atualizar("apagaEdge", JSON.stringify({ data, id }));
    }
  };

  return (
    <>
      <svg width="0" height="0">
        <defs>
          <marker id="orange-arrowhead" markerWidth="10" markerHeight="7" refX="5" refY="3.5" orient="auto" markerUnits="strokeWidth">
            <path d="M 0 0 L 10 3.5 L 0 7 z" fill="var(--primary-base-dark2)" />
          </marker>
        </defs>
      </svg>

      <g>
        <path
          id={id}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd="url(#orange-arrowhead)"
          style={{ strokeWidth: 2, stroke: 'var(--primary-base-dark2)' }}
        />
        <path
          id={id}
          className="react-flow__edge-path"
          d={edgePath}
          style={{ strokeWidth: 20, stroke: 'transparent' }}
        />

        {textBBox && sourceNode.type === ENodeEnum.NodeCondicao && (
          <rect
            x={labelX - textBBox.width / 2 - 5}
            y={labelY - textBBox.height / 2 - 2}
            width={textBBox.width > 10 ? textBBox.width + 10 : 10}
            height={textBBox.height > 3 ? textBBox.height + 4 : 10}
            fill="var(--primary-base)"
            stroke="var(--primary-base-dark2)"
            strokeWidth="2"
            rx="5"
            ref={rectRef}
          />
        )}

        {sourceNode.type === ENodeEnum.NodeCondicao && (
          <>
            {textBBox && (
              <rect
                x={labelX - textBBox.width / 2 - 5}
                y={labelY - textBBox.height / 2 - 2}
                width={textBBox.width > 10 ? textBBox.width + 10 : 10}
                height={textBBox.height > 3 ? textBBox.height + 4 : 10}
                fill="#ec9453"
                stroke="#d86f3e"
                strokeWidth="2"
                rx="5"
                ref={rectRef}
              />
            )}

            <foreignObject
              x={labelX + (textBBox ? textBBox.width / 2 : 0) + 5}
              y={labelY - (textBBox ? textBBox.height / 2 : 0) - 15}
              width="20"
              height="20"
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                <FiTrash className="icon-hover-effect"
                  onClick={(e) => {
                    e.stopPropagation(); // Previne o evento de clicar no ícone de propagação para o texto
                    onExcluir();
                  }} />
              </div>
            </foreignObject>
            <text onClick={onClick} ref={textRef} x={labelX} y={labelY} dy={3} fontSize="10" fill="#fff" textAnchor="middle">
              {condicao ? `${condicao?.label} - ${condicao?.operacao}` : 'Clique e edite'}
            </text>
          </>
        )}
      </g>
    </>
  );
};

export default FloatingEdge;
