import clsx from "clsx";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";
import { useFormik } from "formik";
import DatePicker  from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import {  useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTimes } from "@fortawesome/free-solid-svg-icons";
import FechamentoService from "../../services/FechamentoService";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaFechamentoConfiguracaoRequest from "../../interfaces/Requests/Fechamento/FiltroPesquisaFechamentoConfiguracaoRequest";
import IFechamentoConfiguracao from "../../interfaces/IFechamentoConfiguracao";
import FechamentoConfiguracaoService from "../../services/FechamentoConfiguracaoService";
import { AdicionaFechamentoRequest } from "../../interfaces/Requests/Fechamento/AdicionaFechamentoRequest";
import {  OverlayTrigger, Popover } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface IFechamentoFieldProps {
  toggleModal: () => void;
  fechamentoConfiguracao?: IFechamentoConfiguracao,
  exibirModal: boolean;
  setAtualizarTabela(atualizaAgora: boolean): void;

}

const FechamentoField = ({ toggleModal, exibirModal, setAtualizarTabela}: IFechamentoFieldProps) => {  
 
  const [fechamentoConfiguracao, setFechamentoConfiguracao] = useState<IFechamentoConfiguracao[]>([]);
  const [carregandoFechamentoConfiguracao, setCarregandoFechamentoConfiguracao] = useState(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaFechamentoConfiguracaoRequest>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "fechamentoConfiguracaoId",
  });
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>A data de corte é utilizada como filtro para determinar os novos processos e os encerrados. A data de corte é comparada com a data de encerramentos para os casos já encerrados ou com a data de cadastro para os demais casos.</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const initialValues: AdicionaFechamentoRequest = {
    fechamentoConfiguracaoId: 0,
    dataCorte: new Date()
  };

  const schema = () => {
    let schema: any = {};
    schema.dataCorte = Yup.date()
      .transform((value) => {
        return value ? moment(value).toDate() : value;
      })
      .required("Campo data de corte é obrigatório");

    schema.fechamentoConfiguracaoId = Yup.number().min(1).required("Campo Fechamento é obrigatório");

    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Registrar Fechamento",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: "Fechamento criado com sucesso, aguarde alguns minutos até que ele esteja concluído!",
            showConfirmButton: true,
            timer: 4000
          });

          Swal.showLoading();

          FechamentoService.adicionaFechamento(values);
          toggleModal();

          setSubmitting(false);

          Swal.hideLoading();

          setAtualizarTabela(true);

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  async function cancelar() {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  async function carregarFechamentoConfiguracao(filtro: FiltroPesquisaFechamentoConfiguracaoRequest) {
    try {
      setCarregandoFechamentoConfiguracao(true);
      let resultado: IRecuperaLista<IFechamentoConfiguracao>;

      resultado = await FechamentoConfiguracaoService.obterFechamentoConfiguracaos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setFechamentoConfiguracao(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setFechamentoConfiguracao([]);
    } finally {
      setCarregandoFechamentoConfiguracao(false);
    }
  }

  useLayoutEffect(() => {
    carregarFechamentoConfiguracao(filtrosPesquisa);
  }, []);

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Registrar Fechamento
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">
              {carregandoFechamentoConfiguracao ? (
                <>Carregando configurações...</>
              ) : (
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-cliente" className="form-label required fw-bolder text-orange">
                    Nome do Fechamento:
                  </label>
                  <select
                    {...formik.getFieldProps("fechamentoConfiguracaoId")}
                    value={formik.values.fechamentoConfiguracaoId}
                    onChange={(e) => {
                      formik.setFieldTouched("fechamentoConfiguracaoId", true);
                      formik.setFieldValue("fechamentoConfiguracaoId", parseInt(e.target.value));
                    }}
                    className={clsx(
                      "form-select",
                      {
                        "is-invalid": formik.touched.fechamentoConfiguracaoId && formik.errors.fechamentoConfiguracaoId,
                      },
                      {
                        "is-valid": formik.touched.fechamentoConfiguracaoId && !formik.errors.fechamentoConfiguracaoId,
                      }
                    )}
                    id="form-client"
                  >
                    <option value="0"> Selecione uma opção</option>
                    {fechamentoConfiguracao.map((map) => {
                      return (
                        <option key={map.fechamentoConfiguracaoId} value={map.fechamentoConfiguracaoId}>
                          {" "}
                          {map.nome}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="col-md-6 mt-3">


              <label htmlFor="form-areas" className="form-label fw-bolder required text-orange">
                Data de Corte:
                <a style={{ fontSize: "12px" }}>
                  {<PopoverHelper></PopoverHelper>}
                </a>
              </label>

                <DatePicker 
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.dataCorte && formik.errors.dataCorte,
                    },
                    {
                      "is-valid": formik.touched.dataCorte && !formik.errors.dataCorte,
                    }
                  )}
                  id="form-dataCorte" selected={formik.values.dataCorte}
                  onChange={(date: Date) => formik.setFieldValue("dataCorte", date)}
                />

                {formik.touched.dataCorte && formik.errors.dataCorte && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span style={{ fontSize: "11px" }} role="alert">
                        {formik.errors.dataCorte}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
        <button disabled={formik.isSubmitting || !formik.isValid}  onClick={() => formik.submitForm()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FechamentoField;