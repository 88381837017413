import { useContext, useEffect, useState } from "react";
import clsx from 'clsx';
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEraser, faPaperPlane, faSearch } from "@fortawesome/free-solid-svg-icons";

import { ECamposIDEnum, EModulo, ETelaEnum, ETipoCampoEnum } from "../../enum";

import ICidade from '../../interfaces/ICidade';
import IEstado from '../../interfaces/IEstado';
import { ICampo } from '../../interfaces/ICampo';
import IEmpresa from '../../interfaces/IEmpresa';
import { IPessoa } from "../../interfaces/IPessoa";
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import ITipoSociedade from '../../interfaces/ITipoSociedade';
import { OrangeInterfaceContext } from '../../interfaces/Contexts/OrangeInterfaceContext';
import { IAdicionaSocietarioRequest } from '../../interfaces/Requests/Societario/IAdicionaSocietarioRequest';
import { IRecuperaConfiguracaoTelaResponse } from '../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse';

import CidadeService from '../../services/CidadeService';
import EstadoService from '../../services/EstadoService';
import SocietarioService from '../../services/SocietarioService';
import TipoSociedadeService from '../../services/TipoSociedadeService';
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { useDisclosure } from "./hooks/useDisclosure";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from '../../contexts/OrangeProvider';
import ModalRecuperaPessoa from "./componentes/ModalRecuperaPessoa";
import { LayoutPageContents } from "../../layout/LayoutPageContents";
import { ModalAdicionaPessoa } from "./componentes/ModalAdicionaPessoa";
import CamposCadastroDinamicos from '../../components/Societario/CamposCadastroDinamicos';
import "./EmpresaPage/style.scss";

const SocietarioCadastroPage = () => {
  const { empresa, setEmpresa, tipoSociedade, setTipoSociedade } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [matriz, setMatriz] = useState<IEmpresa>();
  const [pessoa, setPessoa] = useState<IPessoa>();
  const [nome, setNome] = useState('');
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [tipoDocumento, setTipoDocumento] = useState('');

  const [carregandoTipoSociedade, setCarregandoTipoSociedade] = useState<boolean>(false);
  const [tiposSociedades, setTiposSociedades] = useState<ITipoSociedade[]>([]);

  const modalRecupera = useDisclosure(false);

  const [carregandoCidades, setCarregandoCidades] = useState<boolean>(false);
  const [cidades, setCidades] = useState<ICidade[]>([]);
  const [estados, setEstado] = useState<IEstado[]>([]);

  const navigate = useNavigate();

  const initialValues: IAdicionaSocietarioRequest = {
    grupoeconomicoId: 0,
    tipoestabelecimento: 1,
    tipoSociedadeId: 0,
    pessoaId: 0,
    tipoempresaId: 0,
    objetosocial: "",
    inscricaoestadual: "",
    inscricaomunicipal: "",
    orgaoregistroId: 0,
    descricaoorgao: "",
    nire: "",
    dataconstituicao: null,
    dataprimeiroregistro: null,
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
    cidadeId: 0,
    porteEmpresa: 1,
    valorcapitalsocial: 0,
    valorcapitalintegralizado: 0,
    valorcapitalAintegralizar: 0,
    moedaId: 0,
    observacoesempresa: "",
    telefone: "",
    email: "",
    statusempresa: 0,
    datasituacao: null,
    motivosituacaoId: 0,
    atividadeempresaprincipalId: 0,
    empresamatrizId: 0,
    pracaId: 0,
    bandeiraId: 0,
    empresasAtividadesEmpresa: [],
    sociosAdministradores: [],
    representantes: [],
    xCampoValorList: [],
    codigo: ""
  };

  const adicionarShema = () => {
    let schema: any = {};
    configuracoesTela.forEach((configuracao: IRecuperaConfiguracaoTelaResponse) => {
      switch (configuracao.campoTipo) {
        case ETipoCampoEnum.Texto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required("Campo obrigatório");
          break;

        case ETipoCampoEnum.AreaTexto:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.string().required("Campo obrigatório");
          break;

        case ETipoCampoEnum.Lista:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}Id`] = Yup.number().min(1, `${configuracao.campoNome} é um campo obrigatório`);
          break;

        case ETipoCampoEnum.Valor:
          if (configuracao.obrigatorio) schema[`${configuracao.campoNome.toLowerCase()}`] = Yup.number().required().typeError(`Campo ${configuracao.label} é obrigatório`);
          break;

        case ETipoCampoEnum.Data:
          if (configuracao.obrigatorio) schema[configuracao.campoNome.toLowerCase()] = Yup.date().required("Campo obrigatório");
          break;


        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const carregarObjetoXCampos = () => {
    let xCampoValorList = new Array<any>;
    configuracoesTela.forEach(configuracao => {

      if (configuracao?.isXCampo) {
        switch (configuracao.campoTipo) {
          case ETipoCampoEnum.AreaTexto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Texto:
            xCampoValorList.push({ campoId: configuracao.campo, texto: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Data:
            xCampoValorList.push({ campoId: configuracao.campo, data: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Valor:
            xCampoValorList.push({ campoId: configuracao.campo, valor: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Inteiro:
            xCampoValorList.push({ campoId: configuracao.campo, inteiro: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          case ETipoCampoEnum.Lista:
            xCampoValorList.push({ campoId: configuracao.campo, xCampoOpcaoId: eval(`formik.values.${configuracao.campoNome.toLowerCase()}Id`) })
            break;

          case ETipoCampoEnum.Boolean:
            xCampoValorList.push({ campoId: configuracao.campo, boolean: eval(`formik.values.${configuracao.campoNome.toLowerCase()}`) })
            break;

          default:
            break;
        }
      }
    });
    return xCampoValorList;
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionarShema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Marca",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          let resultado = await SocietarioService.adicionaSocietario({
            ...formik.values,
            pessoaId: pessoa?.pessoaId,
            empresamatrizId: matriz?.empresaId,
            tipoSociedadeId: tipoSociedade?.tipoSociedadeId,
            tipoestabelecimento: matriz?.empresaId ? 2 : 1,
            xCampoValorList: carregarObjetoXCampos()
          });

          setEmpresa(resultado);
          setTipoSociedade(tipoSociedade);
          formik.resetForm();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Marca cadastrado com sucesso`,
            timer: 4000,
          });
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setConfiguracoesTela([]);
      }
    },
  });

  const carregarTipoSociedade = async () => {
    try {

      setCarregandoTipoSociedade(true);

      let resultado: any = await TipoSociedadeService.obterTipoSociedades({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setTiposSociedades(resultado.data);

    } catch (error: any) {
      setTiposSociedades([]);
    } finally {
      setCarregandoTipoSociedade(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {
      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        TipoSociedadeId: tipoSociedade.tipoSociedadeId,
        Modulo: EModulo.Societario,
        Tela: ETelaEnum.TelaCadastroEmpresa,
      });
      setConfiguracoesTela(resultado.data);
      formik.setValues({ ...formik.values, ...empresa });
      formik.setFieldValue("atividadeEmpresa", empresa?.empresasAtividadesEmpresa);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (formik.values.tipoSociedadeId) {
      carregarConfiguracaoTela();
    } else {
      formik.resetForm();
      setConfiguracoesTela([]);
    }
  }, [formik.values.tipoSociedadeId]);

  useEffect(() => { if (formik.values.tipoSociedadeId) carregaEstado(); }, [formik.values.tipoSociedadeId]);

  useEffect(() => {
    if (formik.values.estadoId > 0) carregaCidade();
  }, [formik.values.estadoId]);

  const carregaEstado = async () => {
    try {
      if (estados.length > 0) return;
      let resultado: IRecuperaLista<IEstado>;
      resultado = await EstadoService.obterEstados({
        nome: "",
        codigo: "",
        status: 0,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "estadoId",
      });
      setEstado(resultado.data);
    } catch (error: any) {
      setEstado([]);
    }
  };

  const carregaCidade = async () => {
    try {
      setCidades([]);
      setCarregandoCidades(true);

      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidadePorFiltros({
        estadoId: formik.values.estadoId,
        IBGE: "",
        nome: "",
        status: 1,
        limit: 900,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setCarregandoCidades(false);
      setCidades(resultado.data);
    } catch (error: any) {
      setCidades([]);
      setCarregandoCidades(false);
    }
  };

  const renderCidade = (configuracaoTela: ICampo) => {
    return (
      <>
        <div className="col-md-3 mt-3">
          {carregandoCidades ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              Carregando cidades...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <>
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                {configuracaoTela.label}
                <a style={{ fontSize: "12px" }}>
                  {configuracaoTela.obrigatorio ? "*:" : ":"}
                  {/* {configuracaoTela.help && <PopoverHelper></PopoverHelper>} */}
                </a>
              </label>
              <select
                name="cidadeId"
                value={formik.values.cidadeId}
                onChange={(e) => {
                  formik.setFieldTouched("cidadeId", true);
                  formik.setFieldValue("cidadeId", parseInt(e.target.value));
                }}
                className={clsx(
                  "form-select",
                  {
                    "is-invalid": formik.touched.cidadeId && formik.errors.cidadeId,
                  },
                  {
                    "is-valid": formik.touched.cidadeId && !formik.errors.cidadeId,
                  }
                )}
                id="form-cidadeId"
              >
                <option value="0">Selecione</option>
                {cidades.map((map: any) => {
                  return (
                    <option key={map.nome} value={map.cidadeId}>
                      {map.nome}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </>
    );
  };

  const renderEstado = (configuracaoTela: ICampo) => {
    return (
      <>
        <div className="col-md-3 mt-3">
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
            <a style={{ fontSize: "12px" }}>
              {configuracaoTela.obrigatorio ? "*:" : ":"}
            </a>
          </label>
          <select
            name="estadoId"
            value={formik.values.estadoId}
            onChange={(e) => {
              formik.setFieldTouched("estadoId", true);
              formik.setFieldValue("estadoId", parseInt(e.target.value));
            }}
            className={clsx(
              "form-select",
              {
                "is-invalid": formik.touched.estadoId && formik.errors.estadoId,
              },
              {
                "is-valid": formik.touched.estadoId && !formik.errors.estadoId,
              }
            )}
            id="form-estadoId"
          >
            <option value="0">Selecione</option>
            {estados.map((map: any) => {
              return (
                <option key={map.nome} value={map.estadoId}>
                  {map.nome}
                </option>
              );
            })}
          </select>
        </div>
      </>
    );
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      if (configuracaoTela.campoNome == ECamposIDEnum.Estado) {
        component.push(renderEstado(configuracaoTela));
        return;
      }

      if (configuracaoTela.campoNome == ECamposIDEnum.Cidade) {
        component.push(renderCidade(configuracaoTela));
        return;
      }

      if (configuracaoTela.campoNome == ECamposIDEnum.TipoDocumento) {
        return;
      } else {
        component.push(renderDefault(configuracaoTela));
      }
    });

    return <div className="row mt-12">{component}</div>;
  };

  const renderDefault = (configuracaoTela: IRecuperaConfiguracaoTelaResponse) => {
    return (
      <>
        <CamposCadastroDinamicos
          configuracaoTela={configuracaoTela}
          formik={formik}
        />
      </>
    );
  };

  const handlePessoa = async (pessoa: IPessoa, empresa?: IEmpresa) => {
    if (pessoa.pessoaId === 0) {
      if (formik.values) {
        if (empresa) {
          setEmpresa({ ...empresa })
        }
        formik.setValues({ ...formik.values, ...empresa })
      }
      modalRecupera.handleClose();
      setShowModalCadastro(true);
    } else {
      if (formik.values) {
        if (empresa) {
          setEmpresa({ ...empresa })
        }
        formik.setValues({ ...formik.values, ...empresa });
      }
      setPessoa(() => { return pessoa })
    }
  }

  const handleCadastroPessoa = (pessoa: IPessoa) => {
    setPessoa(() => { return pessoa })
    formik.setValues({ ...formik.values, ...empresa })
    if (matriz) {
      formik.setFieldValue('empresaMatrizId', matriz?.empresaId);
    }
    formik.setFieldValue('pessoaId', pessoa?.pessoaId);
    setShowModalCadastro(false)
  }

  const limparCampos = (event: any) => {
    setPessoa(undefined);
    event.target.blur();
  }

  useEffect(() => {
    modalRecupera.handleOpen();
  }, []);

  const onChangeTipoSociedade = (value: any, texto: string) => {
    formik.setFieldValue("tipoSociedadeId", parseInt(value));
    setTipoSociedade({
      tipoSociedadeId: parseInt(value),
      nome: texto,
      codigo: "",
      status: "",
    });
  };

  useEffect(() => {
    if (pessoa) {
      formik.setFieldValue('pessoaId', pessoa.pessoaId)
      if (matriz) {
        formik.setFieldValue('empresaMatrizId', matriz.empresaId);
        formik.setFieldValue('tipoEstabelecimento', 2);
      } else {
        formik.setFieldValue('tipoEstabelecimento', 1);
      }
      modalRecupera.handleClose()
    }
    carregarTipoSociedade();
  }, [pessoa]);

  return (
    <>

      <ModalRecuperaPessoa
        className={'societario'}
        title={'Pesquisar/Adicionar Empresas'}
        documentoPadrao={'CNPJ'}
        setTipoDocumento={setTipoDocumento}
        setNumeroDocumento={setNumeroDocumento}
        setNome={setNome}
        modalActions={modalRecupera}
        handlePessoa={handlePessoa}
        setMatriz={setMatriz}
      />

      <ModalAdicionaPessoa
        className={'societario'}
        title={"Nova Empresa"}
        showModal={showModalCadastro}
        setShowModal={setShowModalCadastro}
        tipoDocumento={tipoDocumento}
        nome={nome}
        numeroDocumento={numeroDocumento}
        handlePessoa={handleCadastroPessoa}
      />

      <>
        <LayoutPageTitle title="Cadastro Empresas">

          {empresa.empresaId > 0 ?
            <>
              <button onClick={() => navigate("/Societario/Empresa/Capa")} type="submit" className="btn btn-orange me-5">
                IR PARA CAPA #{empresa.empresaId}
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
              </button>
            </> :
            <>
              <button
                onClick={formik.submitForm}
                disabled={!pessoa}
                type="submit"
                className="btn btn-orange btn-secondary me-5"
              >
                {!carregandoTipoSociedade && (
                  <>
                    Salvar Empresa
                    <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
                  </>
                )}
                {carregandoTipoSociedade && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button></>}
        </LayoutPageTitle>


        {carregandoTipoSociedade ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de sociedade...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          pessoa?.pessoaId &&
          <Row className="w-100">
            <div className="col-md-6 mt-3">
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                Tipo de Sociedade
              </label>
              <select
                name="tipoSociedadeId"
                value={formik.values.tipoSociedadeId}
                className={"form-select"}
                onChange={(event: any) => onChangeTipoSociedade(event.target.value, event.target[event.target.selectedIndex].text)}
                id="form"
              >
                <option value="0"> Selecione um tipo de sociedade</option>
                {tiposSociedades.map((tipoSociedade) => {
                  return (
                    <option key={tipoSociedade.tipoSociedadeId} value={tipoSociedade.tipoSociedadeId}>
                      {tipoSociedade.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          </Row>
        )}

        <LayoutPageContents>
          <Row className="w-100 mt-3">
            <Col sm={12} md={6} xl={3} className="mb-3">
              <label className="form-label fw-bolder text-orange" htmlFor="pessoa-id">Nome Fantasia:</label>
              <input type="text" className="form-control" value={pessoa?.nome || ''} disabled />
            </Col>
            <Col sm={12} md={6} xl={3} className="mb-3">
              <label className="form-label fw-bolder text-orange" htmlFor="pessoa-id">CNPJ:</label>
              <input type="text" className="form-control" value={pessoa?.numeroDocumento || ''} disabled />
            </Col>
            <Col sm={12} md={12} xl={3} className="mb-3">
              <label className="form-label fw-bolder text-orange" htmlFor="pessoa-id">Denominação Social:</label>
              <input type="text" className="form-control" value={pessoa?.nomeSocial || ''} disabled />
            </Col>
            <Col sm={12} md={3} xl={1} className="mb-3 ms-auto">
              <label className="form-label fw-bolder text-orange"> &nbsp; </label>
              <Button className="btn btn-orange w-100" onClick={() => modalRecupera.handleOpen()}>
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
              </Button>
            </Col>
            <Col sm={12} md={3} xl={1} className="mb-3 ms-auto">
              <label className="form-label fw-bolder text-orange"> &nbsp; </label>
              <Button as="label" className="btn btn-orange w-100" onClick={limparCampos}>
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
              </Button>
            </Col>
          </Row>
          {renderCampos()}
        </LayoutPageContents>

      </>
    </>)
}

export default SocietarioCadastroPage;