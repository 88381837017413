/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import ReactToPrint from "react-to-print";
import { OverlayTrigger, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faHistory,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";

import { EModulo, ESeparadorIDEnum, ETelaEnum } from "../../enum";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaMarcaRequest } from "../../interfaces/Requests/Marca/IAdicionaMarcaRequest";
import { IRecuperaConfiguracaoTelaResponse, IRecuperaSeparadorTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import LayoutPageButton from "../../layout/LayoutPageButton";

import MarcaService from "../../services/MarcaService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import { OrangeContext } from "../../contexts/OrangeProvider";

import Carregando from "../../components/Carregando";
import { defaultSchema } from "../../utils/SchemasUtil";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompartilharModal from "../../components/Comum/Share/CompartilharModal";
import AndamentoListField from "../../components/Comum/Andamento/AndamentoListField";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";
import CollapseCapaDinanimos from "../../components/Comum/Modulo/CollapseCapaDinanimos";
import { PopoverSubMenuMarca } from "../../components/Comum/Popover/PopoverSubMenuMarca";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";

const MarcaCapaPage = () => {

  const componentRef = useRef<any>();

  const { marca, setMarca, tipoMarca } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [refreshCapa, setRefreshCapa] = useState(true);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [separadoresTela, setSeparadoresTela] = useState<IRecuperaSeparadorTelaResponse[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [sumirPropriedades, setSumirPropriedades] = useState(false);

  const [exibirModalShare, setExibirModalShare] = useState<boolean>(false);

  const initialValues: IAdicionaMarcaRequest = {

    marcaId: marca.marcaId,
    principalId: marca.marcaId,
    tipoMarcaId: marca.tipoMarcaId,
    categoriaId: marca.tipoMarcaId,
    modulo: EModulo.MarcaEPertences,
    tela: ETelaEnum.TelaCapaMarcasEPertences,

    restrito: false,
    datainiciovigencia: null,
    datafimvigencia: null,
    datadeposito: null,
    dataconcessao: null,
    datapublicacao: null,

    codigo: "",
    nomemarca: "",
    numeropedido: "",
    observacoesmarca: "",
    especificacao: "",
    numeroregistro: "",
    tituloregistro: "",

    tipoDocumentoCheckList: 0,
    apresentacaomarcaId: 0,
    classemarcaId: 0,
    naturezamarcaId: 0,
    classificacaoipcId: 0,
    escritorioId: 0,
    departamentoId: 0,
    paisId: 0,
    estadoId: 0,
    cidadeId: 0,
    tipodocumentoId: 0,

    empresasList: [],
    partesContrariasList: [],
    advogadoInternoList: [],
    centrosCusto: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,

  };

  const toggleShare = () => setExibirModalShare(!exibirModalShare);

  const inserirSessionStorage = () => sessionStorageService.inserir(marca, "marca_capa");

  const compartilhar = () => `Número_${marca.marcaId}_${tipoMarca.tipoMarcaId > 0 ? "_Tipo_de_Marca_" + tipoMarca.nome : ""}_Status_${marca.statusMarcaNome}`;

  const carregarSessionStorage = async () => setMarca(JSON.parse((await sessionStorageService.obter("marca_capa")) || null));

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!marca.marcaId) return;

    carregarMarca();

    carregarSeparadoresTela();

    formik.setValues(initialValues);

  }, [marca.marcaId, refreshCapa]);

  useEffect(() => {
    if (!marca.marcaId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [marca.marcaId]);

  const carregarMarca = async () => {
    try {
      if (!marca.marcaId) return;
      let resultado: IRecuperaLista<IMarca>;
      resultado = await MarcaService.obterMarcaPorId(marca.marcaId);
      setMarca(resultado.data[0]);
      setRefreshCapa(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const carregarSeparadoresTela = async () => {

    try {

      setCarregandoConfiguracoesTela(true);

      let resultado: IRecuperaLista<IRecuperaSeparadorTelaResponse>;
      resultado = await ConfiguracaoSeparadorService.obterCapa({
        modulo: EModulo.MarcaEPertences,
        tipoMarcaId: formik.values.tipoMarcaId,
        
      });

      setSeparadoresTela(resultado.data);

    } catch (error: any) {
      setSeparadoresTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (marcaId: number) => {
    try {
      let resultado = await MarcaService.adicionaFavorido(marcaId);
      setMarca({ ...marca, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar marca como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await MarcaService.removerFavorido(favoritoId);
      setMarca({ ...marca, favoritoId: 0 });
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  };



  const renderSeparadores = () => {

    const components = separadoresTela.map((separadorTela, index) => {
      switch (separadorTela.codigo) {

        case ESeparadorIDEnum.ExibirAndamento:
          return <AndamentoListField key={index.toString()} modulo={EModulo.MarcaEPertences} configuracaoTela={separadorTela} />;

        case ESeparadorIDEnum.ExibirCompromisso:
          return <CompromissoListField key={index.toString()} modulo={EModulo.MarcaEPertences} setRefreshCapa={setRefreshCapa} />;

        default:
          return (
            <CollapseCapaDinanimos
              key={index.toString()}
              formik={formik}
              controller={"marca"}
              setRefreshCapa={setRefreshCapa}
              separadorTela={separadorTela}
              setConfiguracoesTela={setConfiguracoesTela}
              configuracoesTela={configuracoesTela}
              separadorTelaLista={separadoresTela}
              setSeparadorTelaLista={setSeparadoresTela}
            />
          );
      }
    });

    return components;
  };

  return (
    <>
      <ToastContainer />

      <CompartilharModal
        texto={compartilhar()}
        exibirModal={exibirModalShare}
        toggleModal={toggleShare}
      />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>

        <LayoutPageButton
          title={`Capa #${marca.marcaId}  ${tipoMarca.tipoMarcaId > 0 ? " - " + tipoMarca.nome : ""} - Status: ${marca.statusMarcaId > 0 ? marca.statusMarcaNome : ""
            }`}
        >
          <>
            {!sumirPropriedades &&
              !carregandoConfiguracoesTela && (
                <a style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => setRefreshCapa(!refreshCapa)}>
                  {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faHistory} />}
                </a>
              )}

            {carregandoConfiguracoesTela && (
              <span className="indicator-progress" style={{ display: "block", fontSize: "30px", paddingLeft: "11px", paddingRight: "11px" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}

          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon onClick={() => {
                  setSumirPropriedades(true);
                  setTimeout(() => {
                    setSumirPropriedades(false);
                  }, 3000);
                }} color={"#DC853D"} className="mx-2" icon={faPrint} title="Imprimir o marca" />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            onClick={() => toggleShare()}
            title="Compartilhar"
          >
            {<FontAwesomeIcon color={"#DC853D"} className="mx-2" icon={faShareAlt} />}
          </a>

          {marca.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(marca.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o marca"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(marca.marcaId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o marca"
            ></a>
          )}

          <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuMarca()}>
            <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>

        </LayoutPageButton>
        <hr />
        <br />
        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderSeparadores()}
            <FileListCapaField modulo={EModulo.MarcaEPertences} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela} />
          </>
        )}
      </div>
    </>
  );
}
export default MarcaCapaPage;
