/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import DepartamentoService from "../../services/DepartamentoService";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import Swal from "sweetalert2";

import CidadeService from "../../services/CidadeService";
import GestorService from "../../services/UsuarioService";
import EmpresaService from "../../services/EmpresaService";
import TipoInstrumentoService from "../../services/TipoInstrumentoService";

import ICidade from "../../interfaces/ICidade";
import IGestor from "../../interfaces/IUsuario";
import IEmpresa from "../../interfaces/IEmpresa";
import IDepartamento from "../../interfaces/IDepartamento";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import ITipoInstrumento from "../../interfaces/ITipoInstrumento";
import FiltroPesquisaProcuracaoRequest from "../../interfaces/Requests/Procuracao/FiltroPesquisaProcuracaoRequest";

import { useAuth } from "../../contexts/AuthProvider";

interface IProcuracaoModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtro: FiltroPesquisaProcuracaoRequest;
  setFiltro: (filtro: any) => void;
}

const ProcuracaoFiltroModal = ({ toggleModal, exibirModal, filtro, setFiltro, setPesquisar }: IProcuracaoModalProps) => {

  const [carregamentoDepartamento, setCarregamentoDepartamento] = useState<boolean>(false);
  const [departamento, setDepartamento] = useState<any>([]);
  const [departamentoSelected, setDepartamentoSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);

  const [carregamentoGestor, setCarregamentoGestor] = useState<boolean>(false);
  const [gestor, setGestor] = useState<any>([]);
  const [gestorSelected, setGestorSelected] = useState([]);

  const [carregamentoCidades, setCarregamentoCidades] = useState<boolean>(false);
  const [cidades, setCidades] = useState<any>([]);
  const [cidadesSelected, setCidadesSelected] = useState([]);

  const [carregamentoTipoInstrumento, setCarregamentoTipoInstrumento] = useState<boolean>(false);
  const [tipoInstrumento, setTipoInstrumento] = useState<any>([]);
  const [tipoInstrumentoSelected, setTipoInstrumentoSelected] = useState([]);

  const [selectedStatusPasta, setSelectedStatusPasta] = useState<any>([]);
  const [exibirFiltrosData, setExibirFiltrosData] = useState<boolean>(false);
  const [exibirFiltrosLista, setExibirFiltrosLista] = useState<boolean>(false);

  const { user } = useAuth();

  useEffect(() => {
    if (exibirFiltrosLista) {

      carregarDepartamento();
      carregarCidade();
      carregarCidade();
      carregarGestor();
      carregarTipoInstrumento();

      if (user?.clienteTipo === 'Empresa') {
        carregarEmpresa();
      }
    }
  }, [exibirFiltrosLista]);

  const optionsStatusPasta = [
    { label: "Em Elaboração", value: "1" },
    { label: "Vigente", value: "2" },
    { label: "Vencida", value: "3" },
    { label: "Encerrada", value: "4" },
  ];

  const carregarDepartamento = async () => {
    try {
      if (departamento.length > 0) return;
      setCarregamentoDepartamento(true);
      let resultado: IRecuperaLista<IDepartamento>;
      resultado = await DepartamentoService.obterDepartamentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "departamentoId",
      });
      setDepartamento(returnOptions(resultado.data, "departamentoId", "departamento"));
      setCarregamentoDepartamento(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoDepartamento(false);
      setDepartamento([]);
    }
  }

  const carregarEmpresa = async () => {
    try {
      if (empresa.length > 0) return;
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "empresaId",
      });
      setEmpresa(returnOptionsEmpresa(resultado.data));
      setCarregamentoEmpresa(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
  }

  const carregarCidade = async () => {
    try {
      if (cidades.length > 0) return;
      setCarregamentoCidades(true);
      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidade({
        limit: 10000,
        codigo: "",
        offset: 0,
        sort: "cidadeId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setCidades(returnOptions(resultado.data, "cidadeId", "cidades"));
      setCarregamentoCidades(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoCidades(false);
      setCidades([]);
    }
  }

  const carregarGestor = async () => {
    try {
      if (gestor.length > 0) return;
      setCarregamentoGestor(true);
      let resultado: IRecuperaLista<IGestor>;
      resultado = await GestorService.obterUsuarios({
        nomeUsuario: "",
        clientes: [user?.clienteId || 0],
        email: "",
        numeroDocumento: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "usuarioId",
      });
      setGestor(returnOptions(resultado.data, "gestorId", "gestor"));
      setCarregamentoGestor(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoGestor(false);
    }
  }

  const carregarTipoInstrumento = async () => {
    try {
      if (gestor.length > 0) return;
      setCarregamentoTipoInstrumento(true);
      let resultado: IRecuperaLista<ITipoInstrumento>;
      resultado = await TipoInstrumentoService.obterTipoInstrumentos({
        limit: 100,
        codigo: "",
        offset: 0,
        sort: "cidadeId",
        nome: "",
        totalItems: 0,
        status: 0
      });
      setTipoInstrumento(returnOptions(resultado.data, "tipoInstrumentoId", "tipoInstrumento"));
      setCarregamentoTipoInstrumento(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoInstrumento(false);
    }
  }

  const returnOptionsEmpresa = (empresas: any) => {
    let empresasParse: any = [];
    empresas.forEach((item: any) => {
      empresasParse.push({
        value: item.empresaId,
        label: item.nomeFantasia,
      });
    });
    return empresasParse;
  };

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });

    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {

      case "cidadeId":
        setFiltro({ ...filtro, cidadeId: IdsSelected });
        break;

      case "tipoInstrumentoId":
        setFiltro({ ...filtro, tipoInstrumentoId: IdsSelected });
        break;

      case "departamentoId":
        setFiltro({ ...filtro, departamentoId: IdsSelected });
        break;

      case "gestorId":
        setFiltro({ ...filtro, gestorId: IdsSelected });
        break;

      case "empresa":
        setFiltro({ ...filtro, empresa: IdsSelected });
        break;

      case "statusPasta":
        setFiltro({ ...filtro, statusPasta: IdsSelected });
        break;
    }
  };

  return (
    <>
      <>
        <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
          <div className="modal-content">
            <div className="modal-body">

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosData(!exibirFiltrosData)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosData ? "-" : "+"} Filtros por datas</h5>
                </div>
              </div>
              {exibirFiltrosData && (
                <>
                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                        Data de Cadastro:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataCadastroMaiorIgual && filtro.dataCadastroMenorIgual
                            ? [filtro.dataCadastroMaiorIgual, filtro.dataCadastroMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataCadastroMaiorIgual: "",
                                dataCadastroMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataInicioVigencia" className="form-label fw-bolder text-orange">
                        Data de Início da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataInicioVigenciaMaiorIgual && filtro.dataInicioVigenciaMenorIgual
                            ? [filtro.dataInicioVigenciaMaiorIgual, filtro.dataInicioVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataInicioVigenciaMaiorIgual: "",
                                dataInicioVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataInicioVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataInicioVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataFimVigencia" className="form-label fw-bolder text-orange">
                        Data Fim da Vigência:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataFimVigenciaMaiorIgual && filtro.dataFimVigenciaMenorIgual
                            ? [filtro.dataFimVigenciaMaiorIgual, filtro.dataFimVigenciaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataFimVigenciaMaiorIgual: "",
                                dataFimVigenciaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataFimVigenciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataFimVigenciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-12 mt-3">
                    <div className="col-4">
                      <label htmlFor="form-dataAprovacao" className="form-label fw-bolder text-orange">
                        Data da Aprovação:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataAprovacaoMaiorIgual && filtro.dataAprovacaoMenorIgual
                            ? [filtro.dataAprovacaoMaiorIgual, filtro.dataAprovacaoMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataAprovacaoMaiorIgual: "",
                                dataAprovacaoMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataAprovacaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataAprovacaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="form-dataAssinatura" className="form-label fw-bolder text-orange">
                        Data da Assinatura:
                      </label>
                      <DateTimeRangePicker
                        className="form-control p-0"
                        calendarIcon={null}
                        showLeadingZeros={true}
                        value={
                          filtro.dataAssinaturaMaiorIgual && filtro.dataAssinaturaMenorIgual
                            ? [filtro.dataAssinaturaMaiorIgual, filtro.dataAssinaturaMenorIgual]
                            : ""
                        }
                        onChange={(datas: Date[]) => {
                          if (!datas) {
                            setFiltro((oldValue: any) => {
                              return {
                                ...oldValue,
                                dataAssinaturaMaiorIgual: "",
                                dataAssinaturaMenorIgual: "",
                              };
                            });
                            return;
                          }

                          let data_inicial = datas[0];
                          let data_final = datas[1];
                          if (data_final) {
                            data_final.setHours(0, 0, 0, 0);
                            data_final = new Date(data_final.getTime() - 1);
                          }
                          setFiltro((oldValue: any) => {
                            return {
                              ...oldValue,
                              dataAssinaturaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                              dataAssinaturaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row mt-12 mt-6">
                <div style={{ cursor: 'pointer' }} onClick={() => setExibirFiltrosLista(!exibirFiltrosLista)} className="modal-header">
                  <h5 className="modal-title text-orange"> {exibirFiltrosLista ? "-" : "+"} Filtros por listas</h5>
                </div>
              </div>
              {exibirFiltrosLista && (
                <>
                  <div className="row mt-12">
                    <>{(user?.clienteTipo === 'Empresa') &&
                      <div className="col-md-4 mt-3">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                          Empresa:
                        </label>
                        <MultiSelect
                          isLoading={carregamentoEmpresa}
                          disabled={carregamentoEmpresa}
                          options={empresa}
                          value={empresaSelected}
                          onChange={(event: any) => {
                            setEmpresaSelected(event);
                            setIdsSelect("empresa", event);
                          }}
                          labelledBy={"Selecione..."}
                          overrideStrings={{
                            selectSomeItems: "Selecione...",
                            allItemsAreSelected: "Todos selecionados",
                            selectAll: "Selecione todos",
                            search: "Pesquise",
                            selectAllFiltered: "Selecione todos (filtrados)"
                          }}
                        />
                      </div>
                    }</>
                  </div>

                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Gestor:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoGestor}
                        disabled={carregamentoGestor}
                        options={gestor}
                        value={gestorSelected}
                        onChange={(event: any) => {
                          setGestorSelected(event);
                          setIdsSelect("gestorId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Departamento:
                      </label>
                      <MultiSelect
                        disabled={carregamentoDepartamento}
                        isLoading={carregamentoDepartamento}
                        options={departamento}
                        value={departamentoSelected}
                        onChange={(event: any) => {
                          setDepartamentoSelected(event);
                          setIdsSelect("departamentoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                        Status da Pasta:
                      </label>
                      <MultiSelect
                        options={optionsStatusPasta}
                        value={selectedStatusPasta}
                        onChange={(event: any) => {
                          setSelectedStatusPasta(event);
                          setIdsSelect("statusPasta", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Cidade:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoCidades}
                        disabled={carregamentoCidades}
                        options={cidades}
                        value={cidadesSelected}
                        onChange={(event: any) => {
                          setCidadesSelected(event);
                          setIdsSelect("cidadeId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="form" className="form-label fw-bolder text-orange">
                        Tipo de Contrato:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoTipoInstrumento}
                        disabled={carregamentoTipoInstrumento}
                        options={tipoInstrumento}
                        value={tipoInstrumentoSelected}
                        onChange={(event: any) => {
                          setTipoInstrumentoSelected(event);
                          setIdsSelect("tipoInstrumentoId", event);
                        }}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer" style={{ margin: "0 auto" }}>
              <button
                type="button"
                onClick={() => {
                  setPesquisar(true);
                  toggleModal();
                }}
                className="btn btn-orange me-5"
              >
                Pesquisar
              </button>
              <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
                CANCELAR
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default ProcuracaoFiltroModal;
