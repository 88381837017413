import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Modal } from "react-bootstrap";
interface IModalCustomBodyProps {
  toggleModal: () => void;
  titulo: string;
  exibirModal: boolean;
  conteudo: JSX.Element;
}

export const ModalCustomBody: FC<IModalCustomBodyProps> = ({ titulo, exibirModal, conteudo, toggleModal}) => {
    return (
      <Modal size="lg" centered={true} show={exibirModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              {titulo}
            </h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            {conteudo}
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    );
}
