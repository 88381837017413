import { useContext, useEffect, useRef, useState } from "react";
import { faPrint, faShare, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";

import { defaultSchema } from "../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaProtocoloRequest } from "../../interfaces/Requests/Protocolo/AdicionaProtocoloRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../enum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import ProtocoloService from "../../services/ProtocoloService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CamposCapaSolucoes from "../../components/Comum/Solucao/CamposCapaSolucoes";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";

const ProtocoloCapaPage = () => {
  const { protocolo, tipoProtocolo, setProtocolo } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);

  const componentRef = useRef<any>();

  const inserirSessionStorage = () => sessionStorageService.inserir(protocolo, "protocolo_capa");

  const carregarSessionStorage = async () => setProtocolo(JSON.parse((await sessionStorageService.obter("protocolo_capa")) || null));

  const compartilharWhatSapp = () => `Número do Protocolo: *#${protocolo.xfinderProtocoloId}* / ${"Tipo de Protocolo: *" + tipoProtocolo.nome} + `;

  const initialValues: AdicionaProtocoloRequest = {
    xfinderProtocoloId: protocolo.xfinderProtocoloId,
    principalId: protocolo.xfinderProtocoloId,
    tipoProtocoloId: protocolo.tipoProtocoloId,

    modulo: EModulo.Protocolo,
    tela: ETelaEnum.TelaCapaProtocolo,
    categoriaId: protocolo.categoriaId || 0,

    processoId: protocolo.processoId || 0,
    areaDireitoId: protocolo.areaDireitoId || 0,

    statusprotocoloId: 0,
    processoempresaId: 0,
    descricao: '',
    codigo: '',
    tipoprotocolotribunal: '',
    documentos: [],
    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!protocolo.xfinderProtocoloId) return;

    carregaCapa();

    formik.setValues(initialValues);

  }, [protocolo.xfinderProtocoloId, refreshCapa]);

  useEffect(() => {
    if (!protocolo.xfinderProtocoloId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [protocolo]);

  const carregaCapa = async () => {
    try {
      if (!protocolo.xfinderProtocoloId) return;
      setCarregandoConfiguracoesTela(true);
      let resultado = await ProtocoloService.obterCapa(protocolo.xfinderProtocoloId);
      setConfiguracoesTela(resultado.data);
      setCarregandoConfiguracoesTela(false);
    } catch (error: any) {
      console.log(error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const adicionarFavorito = async (xfinderProtocoloId: number) => {
    try {
      let resultado = await ProtocoloService.adicionaFavorido(xfinderProtocoloId);
      setProtocolo({ ...protocolo, favoritoId: resultado.favoritoId });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ProtocoloService.removerFavorido(favoritoId);
      setProtocolo({ ...protocolo, favoritoId: 0 });
    } catch (error: any) {
      notifyFormikDanger(error?.response?.data?.Message);
    }
  };

  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaSolucoes
          controller={"protocolo"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa Protocolo: #${protocolo.xfinderProtocoloId}  ${tipoProtocolo.tipoProtocoloId > 0 ? " - " + tipoProtocolo.nome : ''} `}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a
                style={{ cursor: "pointer", fontSize: "30px" }}
                onClick={() => setRefreshCapa(!refreshCapa)}
              >
                {
                  <FontAwesomeIcon
                    color={"#DC853D"}
                    className="mx-2"
                    icon={faHistory}
                  />
                }
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span
                className="indicator-progress"
                style={{
                  display: "block",
                  fontSize: "30px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faPrint}
                  title="Imprimir o protocolo"
                />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {
              <FontAwesomeIcon
                color={"#DC853D"}
                className="mx-2"
                icon={faShare}
              />
            }
          </a>

          {protocolo.favoritoId ? (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => removerFavorito(protocolo.favoritoId)}
              className="navigation-service-icon navigation-service-icon-startShow"
              title="Desfavoritar o protocolo"
            ></a>
          ) : (
            <a
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => adicionarFavorito(protocolo.xfinderProtocoloId)}
              className="navigation-service-icon navigation-service-icon-start"
              title="Favoritar o protocolo"
            ></a>
          )}

          <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="bottom"
            overlay={protocolo.modulo == EModulo.AreaDireito ? PopoverSubMenuProcesso() : PopoverSubMenuImovel()}
          >
            <a
              style={{ cursor: "pointer", marginRight: "10px" }}
              className="navigation-services-list-link"
            >
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderCampos()}
            <hr />
            <FileListCapaField somenteLeitura={true} modulo={EModulo.Protocolo} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela}
            />
          </>
        )}

        <hr />
      </div>
    </>
  );
};
export default ProtocoloCapaPage;
