import IModeloDocumento from "../interfaces/IModeloDocumento";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import AdicionaModeloDocumentoReq from "../interfaces/Requests/ModeloDocumento/AdicionaModeloDocumentoReq";
import EditaModeloDocumentoReq from "../interfaces/Requests/ModeloDocumento/EditaModeloDocumento";
import FiltroPesquisaModelosDocumentosReq from "../interfaces/Requests/ModeloDocumento/FiltroPesquisaModelosDocumentosReq";
import api from "./Api";

class ModeloDocumento {
  async obterModelos(filtros: FiltroPesquisaModelosDocumentosReq) {
    const queryStatus =
      filtros.status === "0"
        ? "&status=0&status=-1&status=1"
        : `&status=${filtros.status}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";
    const queryPaginacao = `&offset=${filtros.offset}${limit}&sort=${filtros.sort}`;
    const queryModulo = filtros.modulo !== 0 ? `&modulo=${filtros.modulo}` : "";
    const queryCodigo =
      filtros.codigo !== "" ? `&codigo=${filtros.codigo}` : "";

    const queryNome = filtros.nome !== "" ? `&nome=${filtros.nome}` : "";

    const { data: result } = await api.get<IRecuperaLista<IModeloDocumento>>(
      `ModeloDocumento/RecuperaModeloDocumento?${queryStatus}${queryPaginacao}${queryModulo}${queryCodigo}${queryNome}`
    );

    return result;
  }

  async adicionarModelo(req: AdicionaModeloDocumentoReq) {
    const { data: response } = await api.post<IRecuperaLista<IModeloDocumento>>(
      "ModeloDocumento/AdicionaModeloDocumento",
      req
    );

    return response;
  }

  async editarModelo(id: number, req: EditaModeloDocumentoReq) {
    const body = [];

    for (const iterator of Object.keys(req)) {
      if (req[iterator as keyof typeof req]) {
        body.push({
          op: "replace",
          path: `/${iterator}`,
          value: req[iterator as keyof typeof req],
        });
      }
    }

    const { data: result } = await api.patch<IRecuperaLista<IModeloDocumento>>(
      `ModeloDocumento/AtualizaModeloDocumentoPorId?id=${id}`,
      body
    );

    return result;
  }
}

export default new ModeloDocumento();
