import api from "./Api";

import { EModulo, ETipoCampoEnum } from "../enum";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPropostaAcordo from "../interfaces/IPropostaAcordo";

import { EditarPropostaAcordoRequest } from "../interfaces/Requests/PropostaAcordo/EditarPropostaAcordoRequest";
import { AdicionaPropostaAcordoRequest } from "../interfaces/Requests/PropostaAcordo/AdicionaPropostaAcordoRequest";
import FiltroPesquisaPropostaAcordoRequest from "../interfaces/Requests/PropostaAcordo/FiltroPesquisaPropostaAcordoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class PropostaAcordoService {

    public async obterCapaPropostaAcordo(propostaAcordoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
            `/PropostaAcordo/RecuperaCapaPropostaAcordo?propostaAcordoId=${propostaAcordoId}`
        );

        return result;
    }

    public async obterPropostaAcordoPorId(propostaAcordoId: number): Promise<IRecuperaLista<IPropostaAcordo>> {

        let { data: result } = await api.get<IRecuperaLista<IPropostaAcordo>>(`PropostaAcordo/RecuperaPropostaAcordo?propostaAcordoId=${propostaAcordoId}`);

        return result;
    }

    public async obterPropostasAcordo(filtros: FiltroPesquisaPropostaAcordoRequest): Promise<IRecuperaLista<IPropostaAcordo>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.statusPropostaAcordoId) {
            if (filtros.statusPropostaAcordoId.length > 0) filtros.statusPropostaAcordoId.forEach((id: any) => (query += `&statusPropostaAcordoId=${id}`));
        }

        if (filtros.propostaAcordoId) {
            query += `&propostaAcordoId=${filtros.propostaAcordoId}`;
        }

        if (filtros.processoId && filtros.modulo == EModulo.AreaDireito) {
            query += `&processoId=${filtros.processoId}`;
        }

        if (filtros.imovelId && filtros.modulo == EModulo.Imovel) {
            query += `&imovelId=${filtros.imovelId}`;
        }


        if (filtros.validadePropostaMaiorIgual) {
            query += `&validadePropostaMaiorIgual=${filtros.validadePropostaMaiorIgual}`;
        }

        if (filtros.validadePropostaMenorIgual) {
            query += `&validadePropostaMenorIgual=${filtros.validadePropostaMenorIgual}`;
        }

        if (filtros.valorAcordoMaiorIgual) {
            query += `&valorAcordoMaiorIgual=${filtros.valorAcordoMaiorIgual}`;
        }

        if (filtros.valorAcordoMenorIgual) {
            query += `&valorAcordoMenorIgual=${filtros.valorAcordoMenorIgual}`;
        }

        if (filtros.dataPagamentoMaiorIgual) {
            query += `&dataPagamentoMaiorIgual=${filtros.dataPagamentoMaiorIgual}`;
        }

        if (filtros.dataPagamentoMenorIgual) {
            query += `&dataPagamentoMenorIgual=${filtros.dataPagamentoMenorIgual}`;
        }

        if (filtros.valorMultaAtrasoMaiorIgual) {
            query += `&valorMultaAtrasoMaiorIgual=${filtros.valorMultaAtrasoMaiorIgual}`;
        }

        if (filtros.valorMultaAtrasoMenorIgual) {
            query += `&valorMultaAtrasoMenorIgual=${filtros.valorMultaAtrasoMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IPropostaAcordo>>(`PropostaAcordo/RecuperaPropostaAcordo?${query}`);

        return result;
    }

    public async obterPropostasAcordoStatus(filtros: FiltroPesquisaPropostaAcordoRequest): Promise<IRecuperaLista<IPropostaAcordo>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        let { data: result } = await api.get<IRecuperaLista<IPropostaAcordo>>(`StatusPropostaAcordo/RecuperaStatusPropostaAcordo?${query}`);

        return result;
    }

    public async adicionaPropostaAcordo(adicionaPropostaAcordo: AdicionaPropostaAcordoRequest): Promise<IPropostaAcordo> {
        let { data: result } = await api.post<IPropostaAcordo>(`PropostaAcordo/AdicionaPropostaAcordo`, this.montarObjetoRequest(adicionaPropostaAcordo));

        return result;
    }



    public async editarPropostaAcordo(editarPropostaAcordo: AdicionaPropostaAcordoRequest) {
        let request = this.montarObjetoArray(editarPropostaAcordo);

        if (request.length > 0) {
            let result = await api.patch(`PropostaAcordo/AtualizaPropostaAcordoPorId?id=${editarPropostaAcordo.propostaAcordoId}`, request);
            return result;
        }
    }

    public async adicionaFavorido(propostaAcordoId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.PropostaAcordo, propostaAcordoId: propostaAcordoId });
        return result;
    }

    public removerFavorido = async (favoritoId: number) => await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);

    public async alterarStatus(propostaAcordoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPropostaAcordo>(`PropostaAcordo/AtualizaPropostaAcordoPorId?id=${propostaAcordoId}`, data);
    }

    public async atualizarPropostaAcordo(editarPropostaAcordo: EditarPropostaAcordoRequest): Promise<void> {

        let dataPropostaAcordo = [
            { "op": "replace", "path": "/codigo", "value": editarPropostaAcordo.codigo },
            { "op": "replace", "path": "/descricaoPropostaAcordo", "value": editarPropostaAcordo.descricao }
        ]


        await Promise.all([
            await api.patch<void>(`PropostaAcordo/AtualizaPropostaAcordoPorId?id=${editarPropostaAcordo.propostaAcordoId}`, dataPropostaAcordo)
        ]);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`PropostaAcordo/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarPropostaAcordoPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`PropostaAcordo/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    public async alterarPropostaAcordoPorCampo(campo: string, valor: any, propostaAcordoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`PropostaAcordo/AtualizaPropostaAcordoPorId?id=${propostaAcordoId}`, request);
            return result;
        }
    }

    public async alterarPropostaAcordoPorCampoBolleano(campo: string, valor: any, despesaId: number) {
        let request: any = [];

        request.push({ op: "replace", path: "/" + campo, value: valor });
        await api.patch<any>(`PropostaAcordo/AtualizaPropostaAcordoPorId?id=${despesaId}`, request);
    }

    public async alterarPropostaAcordoPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
        let request: any = [];

        request.push({ op: "replace", path: "/" + campo, value: valor });
        await api.patch<any>(`PropostaAcordo/AtualizaXCampoValor?id=${xcampoid}`, request);
    }

    private montarObjetoRequest = (propostaAcordo: AdicionaPropostaAcordoRequest) => {
        let request: any = {};

        if (propostaAcordo.xCampoValorList.length > 0) request.xCampoValorList = propostaAcordo.xCampoValorList;

        // Campos texto
        if (propostaAcordo.codigo) request.codigo = propostaAcordo.codigo;
        if (propostaAcordo.descricao) request.descricao = propostaAcordo.descricao;
        if (propostaAcordo.realizadopor) request.realizadoPor = propostaAcordo.realizadopor;

        //Data
        if (propostaAcordo.validadeproposta) request.validadeProposta = propostaAcordo.validadeproposta;
        if (propostaAcordo.datapagamento) request.dataPagamento = propostaAcordo.datapagamento;

        //Lista
        if (propostaAcordo.modulo) request.modulo = propostaAcordo.modulo;
        if (propostaAcordo.tipoPropostaAcordoId) request.tipoPropostaAcordoId = propostaAcordo.tipoPropostaAcordoId;
        if (propostaAcordo.statuspropostaacordoId) request.statusPropostaAcordoId = propostaAcordo.statuspropostaacordoId;
        if (propostaAcordo.periodicidadeId) request.periodicidadeId = propostaAcordo.periodicidadeId;
        if (propostaAcordo.formapagamentoId) request.formaPagamentoId = propostaAcordo.formapagamentoId;

        if (propostaAcordo.processoId) {
            request.processoId = propostaAcordo.processoId;
            request.modulo = EModulo.AreaDireito;
        }
        if (propostaAcordo.imovelId) {
            request.imovelId = propostaAcordo.imovelId;
            request.modulo = EModulo.Imovel;
        }

        //Bolleano
        if (propostaAcordo.multaatraso) request.multaAtraso = true;


        //Valor
        if (propostaAcordo.valoracordo) request.valorAcordo = propostaAcordo.valoracordo;
        if (propostaAcordo.valormultaatraso) request.valorMultaAtraso = propostaAcordo.valormultaatraso;
        if (propostaAcordo.valorentrada) request.valorEntrada = propostaAcordo.valorentrada;
        if (propostaAcordo.quantidadeparcelas) request.quantidadeParcelas = propostaAcordo.quantidadeparcelas;
        if (propostaAcordo.valorparcela) request.valorParcela = propostaAcordo.valorparcela;


        return request;
    };

    private montarObjetoArray = (propostaAcordo: AdicionaPropostaAcordoRequest) => {
        let request: any = [];

        // Campos texto
        if (propostaAcordo.codigo) request.push({ op: "replace", path: "/codigo", value: propostaAcordo.codigo });
        if (propostaAcordo.descricao) request.push({ op: "replace", path: "/descricao", value: propostaAcordo.descricao });

        //Data 
        if (propostaAcordo.validadeproposta) request.push({ op: "replace", path: "/validadeProposta", value: propostaAcordo.validadeproposta });
        if (propostaAcordo.datapagamento) request.push({ op: "replace", path: "/dataPagamento", value: propostaAcordo.datapagamento });

        //Lista
        if (propostaAcordo.tipoPropostaAcordoId) request.push({ op: "replace", path: "/tipoPropostaAcordoId", value: propostaAcordo.tipoPropostaAcordoId });
        if (propostaAcordo.processoId) request.push({ op: "replace", path: "/processoId", value: propostaAcordo.processoId });
        if (propostaAcordo.periodicidadeId) request.push({ op: "replace", path: "/periodicidadeId", value: propostaAcordo.periodicidadeId });
        if (propostaAcordo.formapagamentoId) request.push({ op: "replace", path: "/formapagamentoId", value: propostaAcordo.formapagamentoId });
        if (propostaAcordo.statuspropostaacordoId) request.push({ op: "replace", path: "/statuspropostaAcordoId", value: propostaAcordo.statuspropostaacordoId });

        //Valor
        if (propostaAcordo.valormultaatraso) request.push({ op: "replace", path: "/valorMultaAtraso", value: propostaAcordo.valormultaatraso });
        if (propostaAcordo.valoracordo) request.push({ op: "replace", path: "/valorAcordo", value: propostaAcordo.valoracordo });
        if (propostaAcordo.valorentrada) request.push({ op: "replace", path: "/valorentrada", value: propostaAcordo.valorentrada });
        if (propostaAcordo.quantidadeparcelas) request.push({ op: "replace", path: "/quantidadeparcelas", value: propostaAcordo.quantidadeparcelas });
        if (propostaAcordo.valorparcela) request.push({ op: "replace", path: "/valorparcela", value: propostaAcordo.valoracordo });

        return request;
    };

    public concluirCompromisso = async (propostaAcordo: AdicionaPropostaAcordoRequest): Promise<any> => {

        let result: any[] = [];

        // Salvar campos normais
        await this.editarPropostaAcordo(propostaAcordo);

        // Salvar xcampos
        if (propostaAcordo.xCampoValorList?.length > 0) {
            propostaAcordo.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, propostaAcordoId: propostaAcordo.propostaAcordoId, texto: xCampo.texto }));
                        else result.push(await this.alterarPropostaAcordoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, propostaAcordoId: propostaAcordo.propostaAcordoId, data: xCampo.data }));
                        else result.push(await this.alterarPropostaAcordoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, propostaAcordoId: propostaAcordo.propostaAcordoId, valor: xCampo.valor }));
                        else result.push(await this.alterarPropostaAcordoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, propostaAcordoId: propostaAcordo.propostaAcordoId, inteiro: xCampo.inteiro }));
                        else result.push(await this.alterarPropostaAcordoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, propostaAcordoId: propostaAcordo.propostaAcordoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarPropostaAcordoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        return result;
    };


}


export default new PropostaAcordoService();