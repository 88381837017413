/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { faEraser, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";

import GridPadrao from "../../components/Comum/GridPadrao";
import "../ProcessoPage/style/icons.css"

import ProcuracaoService from "../../services/ProcuracaoService";
import TipoProcuracaoService from "../../services/TipoProcuracaoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import ITipoProcuracao from "../../interfaces/ITipoProcuracao";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaProcuracaoRequest from "../../interfaces/Requests/Procuracao/FiltroPesquisaProcuracaoRequest";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import ProcuracaoFiltroModal from "./ProcuracaoFiltroModal";
import { OrangeContext } from "../../contexts/OrangeProvider";

const ProcuracaoPastaPage = () => {
  const [procuracoes, setProcuracoes] = useState<IProcuracao[]>([]);
  const [carregandoProcuracoes, setCarregandoProcuracoes] = useState(false);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);

  const [carregamentoTipoProcuracao, setCarregamentoTipoProcuracao] = useState<boolean>(false);
  const [tiposProcuracoesOptions, setTiposProcuracoesOptions] = useState<any>([]);
  const [tipoProcuracaoSelected, setTipoProcuracaoSelected] = useState([]);

  const optionsStatusProcuracao = [
    { label: "Em Elaboração", value: "1" },
    { label: "Em Aprovação", value: "2" },
    { label: "Em Assinatura", value: "3" },
    { label: "Assinado", value: "4" },
    { label: "Cancelado", value: "5" },
  ];

  const [selectedStatusProcuracao, setSelectedStatusProcuracao] = useState<any>([]);

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    setFiltrosPesquisa({ ...filtrosPesquisa, statusProcuracaoId: IdsSelected });

  };

  const [relatorioId, setRelatorioId] = useState<number | null>(null);

  const { procuracao, setProcuracao, setTipoProcuracao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const filtroInicial: FiltroPesquisaProcuracaoRequest = {
    procuracaoId: 0,
    modeloRelatorioId:0,
    pastaId: procuracao.pastaId,
    clienteId: [],
    tipoProcuracaoId: [],
    statusProcuracaoId: [],
    cidadeId: [],
    departamentoId: [],
    tipoInstrumentoId: [],
    gestorId: [],
    empresa: [],
  
    codigo: "",
    numeroProcuracao: "",
    observacoesProcuracao: "",
    poderesProcuracao: "",
    nomeSolicitante: "",
    tempoGasto: "",
    emailSolicitante: "",
    parteContraria: "",
  
    dataInicioVigenciaMaiorIgual: null,
    dataInicioVigenciaMenorIgual: null,
  
    dataFimVigenciaMaiorIgual: null,
    dataFimVigenciaMenorIgual: null,
  
    dataAprovacaoMaiorIgual: null,
    dataAprovacaoMenorIgual: null,
  
    dataAssinaturaMaiorIgual: null,
    dataAssinaturaMenorIgual: null,
  
    dataCadastroMaiorIgual: null,
    dataCadastroMenorIgual: null,
  
    offset: 0,
    limit: 10,
    sort: "procuracaoId",
    totalItems: 0,
  };

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaProcuracaoRequest>(filtroInicial);

  useEffect(() => {
    if (!procuracao.procuracaoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [procuracao]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(procuracao, "procuracao_pasta");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_pasta");
  };

  const carregarSessionStorage = async () => {
    let procuracao: IProcuracao = JSON.parse((await sessionStorageService.obter("procuracao_pasta")) || null);
    setProcuracao(procuracao);

    let filtro: FiltroPesquisaProcuracaoRequest = JSON.parse((await sessionStorageService.obter("filtro_pasta")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, pastaId: filtro.pastaId });
  };

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  function toggleModal(): void {
    setExibirFormModal(!exibirFormModal);
  }

  useLayoutEffect(() => {
    carregarTipoProcuracao();
  }, []);

  const returnOptionsTiposProcuracoes = (array: any) => {
    array.forEach((item: any) => {
      tiposProcuracoesOptions.push({
        value: item.tipoProcuracaoId,
        label: item.nome,
      });
    });
    return setTiposProcuracoesOptions;
  };

  function formatarData(data: string): string {
    const dataRet = data || ''
    if (dataRet.includes('T')) {
      const [ano, mes, dia] = dataRet.split('T')[0].split('-')
      return `${dia}/${mes}/${ano}`
    }
    return '-'
  }

  function nomearStatusProcuracao(status: string): string {
    switch (status) {
      case "EmElaboracao":
        return 'Em Elaboração';
      case "EmAprovacao":
        return 'Em Aprovação';
      case "EmAssinatura":
        return 'Em Assinatura';
      case "Assinado":
        return 'Assinado';
      case "Cancelado":
        return 'Cancelado';
      default:
        return 'Não definido';
    }
  }

  function nomearStatusPasta(status: string): string {
    switch (status) {
      case "EmElaboracao":
        return 'Em Elaboração';
      default:
        return status;
    }
  }

  async function carregarTipoProcuracao() {
    try {
      setCarregamentoTipoProcuracao(true);
      let resultadoTipoProcuracao: IRecuperaLista<ITipoProcuracao>;
      resultadoTipoProcuracao = await TipoProcuracaoService.obterTipoProcuracoes({
        nome: "",
        codigo: "",
        status: 1,
        categoria: 0,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoProcuracaoId",
      });
      setTiposProcuracoesOptions(returnOptionsTiposProcuracoes(resultadoTipoProcuracao.data));
      setCarregamentoTipoProcuracao(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoProcuracao(false);
      setTiposProcuracoesOptions([]);
    }
  }

  async function carregarProcuracoes(filtro: FiltroPesquisaProcuracaoRequest) {
    try {
      setCarregandoProcuracoes(true);

      let resultado: IRecuperaLista<IProcuracao>;
      resultado = await ProcuracaoService.obterProcuracaos(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setProcuracoes(resultado.data);
      setRelatorioId(resultado.relatorioId || null);
      setCarregandoProcuracoes(false);
      setPesquisar(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setProcuracoes([]);
      setRelatorioId(null);
    } finally {
      setCarregandoProcuracoes(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  useEffect(() => {
    setPesquisar(true);

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  const colunas: TableColumn<IProcuracao>[] = [
    {
      cell: (procuracao: IProcuracao) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setProcuracao(procuracao);
                  setTipoProcuracao({
                    tipoProcuracaoId: procuracao.tipoProcuracaoId,
                    nome: procuracao.tipoProcuracaoNome,
                    codigo: "",
                    status: "",
                  });
                  navigate("/Procuracao/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Número: {procuracao.numeroProcuracao}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{nomearStatusProcuracao(procuracao.statusProcuracaoNome)}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {procuracao.procuracaoId}
                {procuracao.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(procuracao.favoritoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(procuracao.procuracaoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Empresa: </b> {procuracao.procuracaoEmpresas.map(empresa => `${empresa.nome} / `)}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Partes: </b> {procuracao.procuracaoParteContraria.map(parte => `${parte.nome} / `)}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IProcuracao>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    if (pesquisar) carregarProcuracoes(filtrosPesquisa);
  }, [pesquisar]);

  async function adicionarFavorito(procuracaoId: number) {
    try {
      await ProcuracaoService.adicionaFavorido(procuracaoId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Procuracao adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarProcuracoes(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar procuracao como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  async function removerFavorido(favoritoId: number) {
    try {
      await ProcuracaoService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Procuracao removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarProcuracoes(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoProcuracaoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, tipoProcuracaoId: IdsSelected };
    });
  }, [tipoProcuracaoSelected]);

  return (
    <>
      <LayoutPageTitle title="Pastas">
        <button onClick={() => navigate("/Procuracao/Novo")} className="btn btn-md btn-orange">
          Nova <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <ProcuracaoFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <div className="row mt-2">
        <div className="col-md-12" >
          <form onSubmit={(e) => e.preventDefault()}
            className="row g-3 mb-3 form-buscar-procuracao">

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                Identificador:
              </label>
              <input
                value={filtrosPesquisa.procuracaoId}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, procuracaoId: e.target.value };
                  });
                }}
                placeholder="ID"
                type="number"
                className={"form-control"}
                id="form-pasta"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                Número:
              </label>
              <input
                value={filtrosPesquisa.numeroProcuracao}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, numeroProcuracao: e.target.value };
                  });
                }}
                placeholder="Número"
                type="number"
                className={"form-control"}
                id="form"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form" className="form-label fw-bolder text-orange">
                E-mail do solicitante:
              </label>
              <input
                value={filtrosPesquisa.emailSolicitante}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, emailSolicitante: e.target.value };
                  });
                }}
                placeholder="E-mail"
                type="text"
                className={"form-control"}
                id="form-numeroProcuracao"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Tipo de Procuracão:
              </label>
              <MultiSelect
                isLoading={carregamentoTipoProcuracao}
                disabled={carregamentoTipoProcuracao}
                options={tiposProcuracoesOptions}
                value={tipoProcuracaoSelected}
                onChange={(event: any) => setTipoProcuracaoSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                Parte Contrária:
              </label>
              <input
                value={filtrosPesquisa.parteContraria}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, parteContraria: e.target.value };
                  });
                }}
                placeholder="Nome ou CPF/CNPJ"
                type="text"
                className={"form-control"}
                id="form-parteContraria"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status do Instrumento:
              </label>
              <MultiSelect
                options={optionsStatusProcuracao}
                value={selectedStatusProcuracao}
                //onChange={setSelectedStatusProcuracao}
                onChange={(event: any) => {
                  setSelectedStatusProcuracao(event);
                  setIdsSelect("statusProcuracao", event);
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }}
              />
            </div>

            <div className="col-12"
              style={{ textAlign: 'right' }}>
              <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
              </button>
              <button
                onClick={() => {
                  setFiltrosPesquisa(filtroInicial);
                  setPesquisar(false);
                  setTipoProcuracaoSelected([]);
                  setSelectedStatusProcuracao([]);
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
              </button>
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                    <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                  </button>
                )}
                content={() => componentRef.current}
              />


              <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
              </button>

            </div>
          </form>

          <div className="col-md-12 mb-10" style={{ overflow: !carregandoProcuracoes ? "auto" : "scroll", height: "600px" }}>

            <div ref={componentRef}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoProcuracoes}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Procuracoes"
                itens={procuracoes}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProcuracaoPastaPage;
