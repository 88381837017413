import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../../../layout/LayoutPageTitle";
import {
  faCloudDownloadAlt,
  faEraser,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import FiltroPesquisaMigracoesRequest from "../../../../interfaces/Requests/Migracoes/FiltroPesquisaMigracoes";
import { useEffect, useState } from "react";
import GridUsuarios from "../GrupoUsuarios/components/GridUsuarios";
import { TableColumn } from "react-data-table-component";
import IMigracoes from "../../../../interfaces/IMigracoes";
import MigracoesService from "../../../../services/MigracoesService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../components/Spinner";

const MigracoesPage = () => {
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(0);
  const [migracoes, setMigracoes] = useState<IMigracoes[]>([]);
  const [filtros, setFiltros] = useState<FiltroPesquisaMigracoesRequest>({
    limit: 10,
    offset: 0,
    sort: "migrationId",
    totalItems: 0,
    status: "",
  });

  const statusCasting = {
    created: "Criado",
    reading_file: "Lendo o arquivo...",
    migrating: "Migrando...",
    processing_report: "Processando o relatório...",
    finished: "Concluído",
    error: "Erro",
  };

  const navigate = useNavigate();

  const renderCell = (migracao: IMigracoes) => {
    if (downloadLoading === migracao.migrationId) {
      return (
        <div
          style={{
            paddingRight: "10px",
            marginLeft: "2px",
            marginRight: "2px",
            cursor: "default",
          }}
        >
          <Spinner />
        </div>
      );
    }

    return (
      <div style={{ paddingRight: "10px", cursor: "default" }}>
        <FontAwesomeIcon
          title="Baixar "
          onClick={() => {
            downloadRelatorio(migracao.migrationId);
          }}
          style={{ fontWeight: "normal", cursor: "pointer" }}
          size="1x"
          className="mx-2 text-orange"
          color="grey"
          icon={faCloudDownloadAlt}
        />
      </div>
    );
  };

  const downloadRelatorio = async (migrationId: number) => {
    try {
      setDownloadLoading(migrationId);
      const response = await MigracoesService.baixarArquivo(migrationId);

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;

      // Definir o nome do arquivo
      link.setAttribute(
        "download",
        `RelatorioDeMigracao - ID ${migrationId}.zip`
      );
      document.body.appendChild(link);
      link.click();

      // Remover o link depois que o download começar
      link.parentNode?.removeChild(link);

      // Liberar o objeto URL criado
      window.URL.revokeObjectURL(url);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        text: `Ocorreu um erro ao baixar o arquivo.`,
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setDownloadLoading(0);
    }
  };

  const colunas: TableColumn<IMigracoes>[] = [
    {
      name: "Id",
      sortField: "migrationId",
      selector: (row: IMigracoes) => row.migrationId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código de Referência",
      sortField: "migrationReference",
      selector: (row: IMigracoes) => row.migrationReference,
      sortable: true,
      wrap: true,
    },
    {
      name: "Data de Criação",
      sortField: "data",
      selector: (row: IMigracoes) => row.dataCriacao.toString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status da Migração",
      sortField: "status",
      selector: (row: IMigracoes) => handleStatusName(row.status),
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const carregarMigracoes = async (filtro: FiltroPesquisaMigracoesRequest) => {
    try {
      setLoading(true);

      const data = await MigracoesService.obterMigracoes(filtro);

      setMigracoes(data.data);

      setFiltros((oldState) => {
        return { ...oldState, totalItems: data.totalRegistros };
      });
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros das Migrações",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const limparFiltros = async () => {
    const filtros = {
      limit: 10,
      offset: 0,
      sort: "migrationId",
      totalItems: 0,
      status: "Todos",
    };

    setFiltros(filtros);

    await carregarMigracoes(filtros);
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });

    await carregarMigracoes({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((oldState) => {
      return { ...oldState, offset: (page - 1) * filtros.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<IMigracoes>,
    sortDirection: string
  ) => {
    setFiltros((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await carregarMigracoes(filtros);
  };

  const handleStatusName = (status: string) => {
    return statusCasting[status as keyof typeof statusCasting];
  };

  useEffect(() => {
    carregarMigracoes(filtros);
  }, []);

  return (
    <>
      <LayoutPageTitle title="Migrações">
        <button
          onClick={() => {
            navigate("/Administracao/Seguranca/AdicionarMigracao");
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <div className="col-md-12">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setReset(!reset);
            await carregarMigracoes(filtros);
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Status da Migração:
            </label>

            <select
              onChange={async (e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return { ...oldState, status: e.target.value };
                });
                setReset(!reset);

                await carregarMigracoes({
                  ...filtros,
                  status: e.target.value,
                });
              }}
              value={filtros.status}
              placeholder="Status"
              className="form-control"
              id="form-select"
            >
              <option value="0" label="Todos">
                Todos
              </option>

              {Object.keys(statusCasting).map((status) => {
                return (
                  <option
                    key={statusCasting[status as keyof typeof statusCasting]}
                    value={status}
                    label={statusCasting[status as keyof typeof statusCasting]}
                  />
                );
              })}
            </select>
          </div>

          <div className="col-md-2">
            <div>
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </div>
        </form>

        <GridUsuarios
          onSort={handleSort}
          progressPending={loading}
          limit={filtros.limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationServer={true}
          paginationTotalRows={filtros.totalItems}
          colunas={colunas}
          tipo="Migrações"
          itens={migracoes}
          reset={reset}
          pagination={true}
        />
      </div>
    </>
  );
};

export default MigracoesPage;
