import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faTimes } from "@fortawesome/free-solid-svg-icons";

interface IImagemFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  url: string;
}

const ImagemField = ({ toggleModal, exibirModal, url }: IImagemFieldProps) => {

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faImage} />
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>

        <div className="modal-body">
          <div className="row mt-1">
            <div className="col-md-12 mt-3">

              <img style={{ width: '500px', height: '400px' }} src={url} alt="Imagem" />
              
            </div>
          </div>

        </div>

      </div>
    </Modal>
  );
};

export default ImagemField;
