/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import clsx from "clsx";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import MaskedInput from "react-text-mask";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faEdit,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { ECamposIDEnum, EModulo } from "../../../enum";
import { tipoPorModulo } from "../../../utils/Utils";
import { MASCARA_HORARIO } from "../../../utils/Constants";
import { returnarRecorrenciaEnumID } from "../../../enum/ERecorrenciaEnum";

import UsuarioService from "../../../services/UsuarioService";
import CompromissoService from "../../../services/CompromissoService";
import TipoCompromissoService from "../../../services/TipoCompromissoService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import IOption from "../../../interfaces/IOption";
import IUsuario from "../../../interfaces/IUsuario";
import ICompromisso from "../../../interfaces/ICompromisso";
import IRecorrencia from "../../../interfaces/IRecorrencia";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import PaginationRequest from "../../../interfaces/Requests/PaginationRequest";
import FiltroPesquisaUsuarioRequest from "../../../interfaces/Requests/FiltroPesquisaUsuarioRequest";

import { notifySuccess } from "../Toast/ToastFormik";
import { RecorrenciaModal } from "./Recorrencia/Modal";
import { RecorrenciaSelect } from "./Recorrencia/Select";
import AreaTextoDefault from "../AreaTexto/AreaTextoDefault";
import GridPadrao from "../../../components/Comum/GridPadrao";
import { recorrenciaOptions, useRecorrencia } from "./Recorrencia/RecorrenciaContext";

interface ICompromissoFieldProps {

  modulo: number;

  processoId?: number;
  areaDireitoId?: number;

  contratoId?: number;
  tipoContratoId?: number;

  imovelId?: number;
  tipoImovelId?: number;

  marcaId?: number;
  tipoMarcaId?: number;

  licencaId?: number;
  tipoLicencaId?: number;

  empresaId?: number;
  tipoSociedadeId?: number;

  consultaId?: number;
  tipoConsultaId?: number;

  procuracaoId?: number;
  tipoProcuracaoId?: number;

  audienciaId?: number;
  tipoAudienciaId?: number;

  despesaId?: number;
  tipoDespesaId?: number;

  propostaAcordoId?: number;
  tipoPropostaAcordoId?: number;

  garantiaId?: number;
  tipoGarantiaId?: number;

  honorarioId?: number;
  tipoHonorarioId?: number;

  reembolsoId?: number;
  tipoReembolsoId?: number;

  obrigacaoId?: number;
  tipoObrigacaoId?: number;

  bloqueioId?: number;
  tipoBloqueioId?: number;

  decisaoId?: number;
  tipoDecisaoId?: number;

  comunicacaoId?: number;
  tipoComunicacaoId?: number;

  desdobramentoId?: number;
  tipoDesdobramentoId?: number;

  xfinderProtocoloId?: number;
  tipoProtocoloId?: number;

  editarCompromisso: ICompromisso;
  toggleModal: () => void;
  exibirModal: boolean;
  setAtualizarTabelaCompromisso(atualizaTabelaCompromisso: boolean): void;
}

const CompromissoField = ({
  processoId,
  areaDireitoId,

  contratoId,
  tipoContratoId,

  marcaId,
  tipoMarcaId,

  imovelId,
  tipoImovelId,

  consultaId,
  tipoConsultaId,

  procuracaoId,
  tipoProcuracaoId,

  despesaId,
  tipoDespesaId,

  honorarioId,
  tipoHonorarioId,

  reembolsoId,
  tipoReembolsoId,

  licencaId,
  tipoLicencaId,

  propostaAcordoId,
  tipoPropostaAcordoId,

  garantiaId,
  tipoGarantiaId,

  decisaoId,
  tipoDecisaoId,

  obrigacaoId,
  tipoObrigacaoId,

  comunicacaoId,
  tipoComunicacaoId,

  bloqueioId,
  tipoBloqueioId,

  desdobramentoId,
  tipoDesdobramentoId,

  xfinderProtocoloId,
  tipoProtocoloId,

  empresaId,
  tipoSociedadeId,

  audienciaId,
  tipoAudienciaId,

  modulo,
  editarCompromisso,
  toggleModal,
  exibirModal,
  setAtualizarTabelaCompromisso,

}: ICompromissoFieldProps) => {
  const [optionsTiposCompromisso, setOptionsTiposCompromisso] = useState<IOption[]>([]);
  const [carregandoTipos, setCarregandoTipos] = useState(false);
  const [filtroTipos] = useState<PaginationRequest>({
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: "nome",
  });

  const [usuarioId, setUsuarioId] = useState<number>(0);
  const [usuarioPrincipal, setUsuarioPrincipal] = useState<boolean>(false);
  const [filtrosUsuario, setFiltrosUsuario] =
    useState<FiltroPesquisaUsuarioRequest>({
      clientes: [],
      numeroDocumento: "",
      email: "",
      nomeUsuario: "",
      status: 1,
      limit: 1000,
      totalItems: 0,
      offset: 0,
      sort: "NomeUsuario",
    });

  const [optionsGrupos, setOptionsGrupos] = useState<IOption[]>([]);
  const [gruposSelected, setGruposSelected] = useState<any[]>([]);
  const [selecionarPorGrupos, setSelecionarPorGrupos] = useState<boolean>(false);

  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [optionsUsuario, setOptionsUsuario] = useState<IOption[]>([]);

  const [carregandoUsuarios, setCarregandoUsuarios] = useState<boolean>(false);

  const [desabilitarNotificacaoEmail, setDesabilitarNotificacaoEmail] = useState<boolean>(false);

  const { recorrencia, showRecorrenciaModal, setRecorrencia } = useRecorrencia()

  const [recorrenciaRequest, setRecorrenciaRequest] = useState<IRecorrencia>({
    repetirCada: 1,
    termino: new Date(),
    tipoRecorrencia: 0,
    somenteDiasUteis: false
  });

  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);


  const initialValues: ICompromisso = {
    modulo: modulo,
    tipoCompromissoNome: "",
    compromissoId: 0,
    codigo: "",
    dataConclusao: new Date(),
    dataCompromisso: new Date(),
    horario: "",
    observacaoCompromisso: "",
    observacaoConclusao: "",
    tipoCompromissoId: 0,
    usuariosAprovadoresList: [],
    categoriaId: 0,
    grupoId: []
  };

  const carregaTipoCompromisso = async () => {

    let options: any[] = [{ label: 'Selecione', value: 0 }];

    try {

      setCarregandoTipos(true);

      let resultado: IRecuperaLista<any>;

      resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.TipoCompromisso,
        formik.values.modulo,
        eval(`${tipoPorModulo(formik.values?.modulo)}Id`),
        0);

      if (resultado?.data?.length === 0) {
        resultado = await TipoCompromissoService.obter({
          codigo: "",
          status: 1,
          limit: 1000,
          totalItems: 0,
          offset: 0,
          sort: "nome",
        });
      }

      resultado.data.map((item) => options.push({ label: item.nome, value: item.tipoCompromissoId }));

      setOptionsTiposCompromisso(options);
    }
    catch (error: any) {
      let resultadoFull: IRecuperaLista<any>;
      resultadoFull = await TipoCompromissoService.obter({
        codigo: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      resultadoFull.data.map((item) => options.push({ label: item.nome, value: item.tipoCompromissoId }));
      setOptionsTiposCompromisso(options);
    } finally {
      setCarregandoTipos(false);
    }
  }

  const carregarUsuarios = async () => {
    try {
      let optionsUsuario: any[] = [{ label: 'Selecione', value: 0 }];

      setCarregandoUsuarios(true);

      let resultado = await UsuarioService.obterUsuarios(filtrosUsuario);

      resultado.data.map((item: IUsuario) =>
        optionsUsuario.push({ label: item.nomeUsuario, value: item.usuarioId })
      );

      setOptionsUsuario(optionsUsuario);
      setUsuarios(resultado.data);

    } catch (error: any) {
      setOptionsUsuario([]);
      setCarregandoUsuarios(false);
    } finally {
      setCarregandoUsuarios(false);
    }
  };

  const carregarGrupos = async () => {
    try {

      let options: any[] = [];

      let resultado: IRecuperaLista<any>;

      resultado = await UsuarioService.obterListaLimitadaGrupoUsuario(
        formik.values.modulo,
        eval(`${tipoPorModulo(formik.values?.modulo)}Id`),
        formik.values.tipoCompromissoId);

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));

      setOptionsGrupos(options);

    } catch (error: any) {
      setOptionsGrupos([]);
    }
  };
  const removerUsuarioAprovador = async (row: IUsuario) => {
    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-danger ms-5",
        cancelButton: "btn btn-orange ms-5",
      },
      buttonsStyling: false,
    });

    let result = swalWithBootstrapButtons.fire({
      title: "Remover esse Aprovador",
      text: `Você realmente deseja remover esse aprovador principal?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: `Sim, desejo remover!`,
    });

    if ((await result).isConfirmed) {
      // remover da lista na tela
      formik.values.usuariosAprovadoresList =
        formik.values.usuariosAprovadoresList.filter(
          (e) => e.usuarioId != row.usuarioId
        );
      formik.setFieldValue(
        "usuariosAprovadoresList",
        formik.values.usuariosAprovadoresList
      );

      // verificar se com essa remoção vai ficar apenas 1 registro sem ser principal, adicionar o mesmo como principal
      if (
        formik.values.usuariosAprovadoresList.filter((e) => e.principal == true)
          .length == 0
      ) {
        if (formik.values.usuariosAprovadoresList.length > 0) {
          formik.values.usuariosAprovadoresList[0].principal = true;
          CompromissoService.removerUsuarioAprovador(
            formik.values.usuariosAprovadoresList[0].compromissoUsuarioId || 0
          );
          CompromissoService.adicionaUsuarioAprovador(
            formik.values.usuariosAprovadoresList[0],
            formik.values.compromissoId
          );
        }
      }

      // 3. Por final remover do banco o item selecionado.
      if (
        formik.values.compromissoId > 0 &&
        (row.compromissoUsuarioId || 0) > 0
      ) {
        CompromissoService.removerUsuarioAprovador(
          row.compromissoUsuarioId || 0
        );
      }
    }
  };

  const colunas: TableColumn<IUsuario>[] = [
    {
      name: "Id",
      sortField: "usuarioId",
      selector: (row: IUsuario) => row.usuarioId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome do Usuário",
      sortField: "nome",
      selector: (row: IUsuario) => (row.nome ? row.nome : row.nomeUsuario),
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      cell: (row: IUsuario) => (row.principal ? "Sim" : "Não"),
      ignoreRowClick: true,
    },
    {
      name: "",
      cell: (row: IUsuario) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Remover Aprovador"
              onClick={async () => removerUsuarioAprovador(row)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faTrash}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    if (exibirModal) {
      carregarGrupos();
      carregarUsuarios();
    }
  }, [filtroTipos.offset, filtroTipos.limit, filtroTipos.sort]);


  const schema = () => {
    let schema: any = {};

    schema.horario = Yup.string()
      .min(5, "Campo horário é obrigatório")
      .required("Campo horário é obrigatório");
    schema.tipoCompromissoId = Yup.number().min(
      1,
      "Por favor é necessário informar a o tipo de compromisso"
    );
    schema.dataCompromisso = Yup.date()
      .transform((value) => {
        return value ? moment(value).toDate() : value;
      })
      .required("Campo data do compromisso é obrigatório");

    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema(),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Registrar Compromisso ",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        Swal.close();

        if (result.isConfirmed) {

          if (values.compromissoId) {
            await atualizaCompromisso(values)
          } else {
            await adicionaCompromisso(values)
          }

          toggleModal();

          resetAfterSubmit();

          setSubmitting(false);

          notifySuccess('Compromisso agendado com sucesso');

          setDesabilitarNotificacaoEmail(false);

        }

      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    },
  });


  useEffect(() => {
    if (selecionarPorGrupos) {
      carregarGrupos();
    }
  }, [selecionarPorGrupos, formik.values.tipoCompromissoId]);

  useEffect(() => {
    if (exibirModal) {


      let objeto: any = {};

      //modulos
      if (processoId && EModulo.AreaDireito == modulo) objeto.processoId = processoId;
      if (contratoId && EModulo.Contrato == modulo) objeto.contratoId = contratoId;
      if (imovelId && EModulo.Imovel == modulo) objeto.imovelId = imovelId;
      if (licencaId && EModulo.Regulatorio == modulo) objeto.licencaId = licencaId;
      if (procuracaoId && EModulo.Procuracao == modulo) objeto.procuracaoId = procuracaoId;
      if (empresaId && EModulo.Societario == modulo) objeto.empresaId = empresaId;
      if (marcaId && EModulo.MarcaEPertences == modulo) objeto.marcaId = marcaId;
      if (consultaId && EModulo.Consultivo == modulo) objeto.consultaId = consultaId;

      //solucoes
      if (despesaId && EModulo.Despesa == modulo) objeto.despesaId = despesaId;
      if (propostaAcordoId && EModulo.PropostaAcordo == modulo) objeto.propostaAcordoId = propostaAcordoId;
      if (garantiaId && EModulo.Garantia == modulo) objeto.garantiaId = garantiaId;
      if (honorarioId && EModulo.XpayHonorario == modulo) objeto.honorarioId = honorarioId;
      if (reembolsoId && EModulo.XpayReembolso == modulo) objeto.reembolsoId = reembolsoId;
      if (decisaoId && EModulo.Decisao == modulo) objeto.decisaoId = decisaoId;
      if (obrigacaoId && EModulo.Obrigacao == modulo) objeto.obrigacaoId = obrigacaoId;
      if (bloqueioId && EModulo.Bloqueio == modulo) objeto.bloqueioId = bloqueioId;
      if (desdobramentoId && EModulo.Desdobramento == modulo) objeto.desdobramentoId = desdobramentoId;
      if (xfinderProtocoloId && EModulo.Protocolo == modulo) objeto.xfinderProtocoloId = xfinderProtocoloId;
      if (comunicacaoId && EModulo.Comunicacao == modulo) objeto.comunicacaoId = comunicacaoId;
      if (audienciaId && EModulo.Audiencia == modulo) objeto.audienciaId = audienciaId;

      if (editarCompromisso.compromissoId > 0)
        formik.setValues({
          ...objeto,
          ...editarCompromisso,
          dataCompromisso: new Date(editarCompromisso.dataCompromisso),
        });
      else {
        formik.setValues({
          ...initialValues,
          ...objeto
        });
      }

      editarCompromisso.usuariosAprovadoresList.forEach((usuario) => {
        formik.values.usuariosAprovadoresList.push(usuario);
      });

      carregaTipoCompromisso();

      carregarUsuarios();

    }
  }, [exibirModal]);

  const atualizaCompromisso = async (values: ICompromisso) => {
    await CompromissoService.atualizarCompromisso({
      ...values,
      dataCompromisso: setHorario(values.horario),
      desabilitarNotificacaoPorEmail: desabilitarNotificacaoEmail,
    });
  }

  const adicionaCompromisso = async (values: ICompromisso) => {
    let objetoRecorrencia: any = null;

    if (recorrencia.value !== 'none') {
      objetoRecorrencia = {};
      objetoRecorrencia.tipoRecorrencia = returnarRecorrenciaEnumID(recorrencia.value);
      objetoRecorrencia.termino = recorrenciaRequest.termino;
      objetoRecorrencia.repetirCada = recorrenciaRequest.repetirCada;
      objetoRecorrencia.somenteDiasUteis = recorrenciaRequest.somenteDiasUteis;
    }

    await CompromissoService.adicionaCompromisso({
      ...values,
      dataCompromisso: setHorario(values.horario),
      desabilitarNotificacaoPorEmail: desabilitarNotificacaoEmail,
      recorrencia: objetoRecorrencia
    });
  }

  const resetAfterSubmit = () => {
    setAtualizarTabelaCompromisso(true);
    limparChecks();
    setRecorrencia(recorrenciaOptions[0]);
    formik.resetForm();
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosUsuario((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosUsuario((oldState) => {
      return { ...oldState, offset: (page - 1) * filtroTipos.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<IUsuario>,
    sortDirection: string
  ) => {
    setFiltrosUsuario((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const limparChecks = () => {
    formik.values.usuariosAprovadoresList.forEach((usuario) => {
      usuario.checked = false;
      usuario.principal = false;
    });
    formik.setFieldValue(
      "usuariosAprovadoresList",
      formik.values.usuariosAprovadoresList
    );
  };

  const cancelar = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-danger ms-5",
        cancelButton: "btn btn-orange ms-5",
      },
      buttonsStyling: false,
    });

    let result = await swalWithBootstrapButtons.fire({
      title: "Cancelar",
      text: `Você realmente deseja cancelar? todos os dados preenchidos serão perdidos`,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: `Sim, desejo cancelar!`,
    });

    if (result.isConfirmed) {
      formik.resetForm();
    }

    toggleModal();
    setRecorrencia(recorrenciaOptions[0])
  }

  const fechar = () => {
    formik.resetForm();
    toggleModal();
  };

  const adicionarUsuario = () => {
    if (
      formik.values.usuariosAprovadoresList.filter((e) => e.principal).length ==
      0 &&
      !usuarioPrincipal
    ) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `Usuário aprovador`,
        text: "É necessário informar o primeiro como principal",
        showConfirmButton: true,
      });
      return;
    }

    let usuarioSelecionado = usuarios.filter(
      (e) => e.usuarioId == usuarioId
    )[0];

    if (
      formik.values.usuariosAprovadoresList.filter(
        (e) => e.usuarioId === usuarioSelecionado.usuarioId
      ).length == 1
    ) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `Usuário aprovador`,
        text: "Usuário já infomando",
        showConfirmButton: true,
      });
      return;
    }

    formik.values.usuariosAprovadoresList.forEach(
      (usuario) => !usuario.principal
    );

    if (usuarioSelecionado) {
      usuarioSelecionado.principal = usuarioPrincipal;

      formik.values.usuariosAprovadoresList =
        formik.values.usuariosAprovadoresList.filter(
          (e) => e.usuarioId != usuarioId
        );
      formik.values.usuariosAprovadoresList.push(usuarioSelecionado);
      formik.setFieldValue(
        "usuariosAprovadoresList",
        formik.values.usuariosAprovadoresList
      );
      setUsuarioPrincipal(false);

      if (formik.values.compromissoId > 0) {
        CompromissoService.adicionaUsuarioAprovador(
          usuarioSelecionado,
          formik.values.compromissoId
        );
      }
    }
  };

  const setHorario = (horario: string, defaultDate?: Date) => {
    let dataCompromisso = defaultDate || formik.values.dataCompromisso || new Date();

    try {
      if (horario && !horario.match(/_.*/)) {
        let hora: number = parseInt(horario.substring(0, 2));

        let minutos: number = parseInt(horario.substring(3, 5));

        dataCompromisso.setHours(hora);

        dataCompromisso.setMinutes(minutos);

        formik.setFieldValue("horario", horario);
      }
    } catch (error) {
      formik.setFieldValue("horario", "");
    }
    return moment(dataCompromisso).format("YYYY-MM-DD HH:mm:ss");
  };

  const validarHorario = (horario: string) => {
    try {
      if (horario && !horario.match(/_.*/)) {
        let hora: number = parseInt(horario.substring(0, 2));

        let minutos: number = parseInt(horario.substring(3, 5));

        if (hora > 23) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: `Horas inválidas`,
            showConfirmButton: true,
          });
          formik.setFieldValue("horario", "");
          return;
        }

        if (minutos > 59) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: `Minutos inválidos`,
            showConfirmButton: true,
          });
          formik.setFieldValue("horario", "");
          return;
        }
        formik.setFieldValue("horario", horario);
      }
    } catch (error) {
      formik.setFieldValue("horario", "");
    }
  };


  return (
    <>
      <RecorrenciaModal
        recorreciaRequest={recorrenciaRequest}
        setRecorrenciaRequest={setRecorrenciaRequest}
        startDate={new Date(formik.getFieldProps("dataCompromisso").value)}
      />

      <AreaTextoDefault
        toggleModal={toggleAreaTextoModal}
        exibirModal={exibirModalAreaTexto}
        field="observacaoCompromisso"
        formik={formik}
      />

      <Modal style={{ opacity: showRecorrenciaModal ? 0.8 : 1 }} size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              Compromissos{" "}
              <FontAwesomeIcon className="mx-2 text-orange" icon={faCalendarCheck} />
            </h5>
            <div onClick={() => fechar()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            >
              <div className="row mt-3">
                <div className="col-md-4 mt-3">
                  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    Tipo de Compromisso:
                  </label>
                  {carregandoTipos ? (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      <span className="spinner-border text-orange spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <Select
                      value={{
                        label: optionsTiposCompromisso.find((e) => e.value === formik.values.tipoCompromissoId)?.label,
                        value: formik.values.tipoCompromissoId,
                      }}
                      options={optionsTiposCompromisso}
                      onChange={(option: any) => {
                        formik.setFieldTouched("tipoCompromissoId", true);
                        formik.setFieldValue("tipoCompromissoId", parseInt(option.value));
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3">
                  <label htmlFor="form-dataCompromisso" className="form-label required fw-bolder text-orange">
                    Data do Compromisso:
                  </label>
                  <DatePicker
                    locale={ptBR}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    className={clsx("form-select", {
                      "is-invalid": formik.touched.dataCompromisso && formik.errors.dataCompromisso,
                      "is-valid": formik.touched.dataCompromisso && !formik.errors.dataCompromisso,
                    })}
                    selected={formik.values.dataCompromisso}
                    onChange={(date) => formik.setFieldValue("dataCompromisso", date)}
                  />
                  {formik.touched.dataCompromisso && formik.errors.dataCompromisso && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.dataCompromisso}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-2 mt-3">
                  <label htmlFor="form-horario" className="form-label required fw-bolder text-orange">
                    Horário:
                  </label>
                  <MaskedInput
                    mask={MASCARA_HORARIO}
                    {...formik.getFieldProps("horario")}
                    onChange={(e) => validarHorario(e.target.value)}
                    placeholder="00:00"
                    type="text"
                    className={clsx("form-control", {
                      "is-invalid": formik.touched.horario && formik.errors.horario,
                      "is-valid": formik.touched.horario && !formik.errors.horario,
                    })}
                    id="form-horario"
                  />
                  {formik.touched.horario && formik.errors.horario && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.horario}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {!formik.values.compromissoId &&
                  <div className="col-md-2 mt-3">
                    <label
                      htmlFor="form-recorrencia"
                      className="form-label fw-bolder text-orange"
                    >
                      Recorrência:
                    </label>
                    <RecorrenciaSelect />
                  </div>}
              </div>


              <div className="row mt-3">
                <div className="col-md-6 mt-3">
                  <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                    Desabilitar Notificação por e-mail?:{" "}
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={desabilitarNotificacaoEmail}
                      onChange={() => setDesabilitarNotificacaoEmail(!desabilitarNotificacaoEmail)}
                    />
                  </label>
                </div>

              </div>


              <div className="col-md-12 mt-3">
                <label htmlFor="form-observacaoCompromisso" className="form-label fw-bolder text-orange">
                  Observação:
                </label>
                <div className="input-group">
                  <textarea
                    {...formik.getFieldProps("observacaoCompromisso")}
                    placeholder="Observação do compromisso"
                    className={"form-control"}
                    id="form-observacaoCompromisso"
                    rows={5}
                  />

                  <Button
                    onClick={() => toggleAreaTextoModal()}
                    style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                    variant="secondary"
                    size="sm"
                  >
                    <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
                  </Button>
                </div>
              </div>

              {formik.values.tipoCompromissoId > 0 && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6 mt-3 d-flex align-items-center">
                      <label
                        style={{ paddingRight: '5px' }}
                        htmlFor="form-cliente"
                        className="form-label fw-bolder text-orange mb-0 mr-4"
                      >
                        Deseja selecionar por grupos de usuários?
                      </label>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={selecionarPorGrupos}
                        onChange={() => setSelecionarPorGrupos(!selecionarPorGrupos)}
                      />
                    </div>

                    {selecionarPorGrupos && (
                      <div className="col-md-6">
                        <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                          Selecione os grupos de aprovadores:
                        </label>
                        <MultiSelect
                          options={optionsGrupos}
                          value={gruposSelected}
                          onChange={(options: any) => {
                            const IdsSelected = options.map((item: any) => item.value);
                            formik.setFieldValue('grupoId', IdsSelected);
                            setGruposSelected(options);
                          }}
                          labelledBy={"Selecione..."}
                          overrideStrings={{
                            selectSomeItems: "Selecione...",
                            allItemsAreSelected: "Todos selecionados",
                            selectAll: "Selecione todos",
                            search: "Pesquise",
                            selectAllFiltered: "Selecione todos (filtrados)"
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}


              {!selecionarPorGrupos && (
                <>
                  <div className="row mt-4">
                    <div className="col-md-6 mt-3 d-flex align-items-center">
                      <label style={{ paddingRight: '5px' }} htmlFor="form-cliente" className="form-label fw-bolder text-orange mb-0 mr-4">
                        Marcar como principal?
                      </label>
                      {formik.values.usuariosAprovadoresList.filter((e) => e.principal).length === 0 && (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={usuarioPrincipal}
                          onChange={() => setUsuarioPrincipal(!usuarioPrincipal)}
                        />
                      )}
                    </div>


                    <div className="col-md-6">
                      <label htmlFor="form-cliente" className="form-label fw-bolder text-orange">
                        Selecione usuários aprovadores:
                      </label>
                      <div className="form-group d-flex align-items-center">

                        <Select
                          value={{
                            label: optionsUsuario.find((e) => e.value === usuarioId)?.label,
                            value: usuarioId,
                          }}
                          onChange={(option: any) => setUsuarioId(option.value)}
                          options={optionsUsuario}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minWidth: '300px',
                              width: '100%',
                            }),
                          }}
                        />

                        <button
                          onClick={() => adicionarUsuario()}
                          type="button"
                          className="btn btn-orange ml-2"
                        >
                          Adicionar
                        </button>
                      </div>
                    </div>
                  </div>


                  <div className="row mt-3">
                    <div className="col-md-12 mb-10">
                      <GridPadrao
                        onSort={handleSort}
                        limit={filtroTipos.limit}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationServer={true}
                        paginationTotalRows={filtroTipos.totalItems}
                        colunas={colunas}
                        tipo="Usuários"
                        itens={formik.values.usuariosAprovadoresList} />
                    </div>
                  </div></>
              )}

              <div className="modal-footer" style={{ margin: "0 auto", marginTop: '20px' }}>
                <div className="row mt-3" style={{ margin: "0 auto" }}>
                  <div className="col-md-6">
                    <button
                      disabled={!formik.isValid || (formik.values.usuariosAprovadoresList.filter((e) => e.principal).length === 0 && !selecionarPorGrupos)}
                      type="submit"
                      className="btn btn-orange"
                    >
                      Salvar
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      onClick={() => cancelar()}
                      type="button"
                      className="btn btn-danger float-end"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal >
    </>
  );
};

export default CompromissoField;
