import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import { validateCNPJ, validateCPF } from "validations-br";
import * as Yup from "yup";
import IEmpresa from "../../../interfaces/IEmpresa";
import { IPessoa } from "../../../interfaces/IPessoa";
import CNPJAService from "../../../services/CNPJAService";
import PessoaService from "../../../services/PessoaService";
import { MASCARA_CNPJ, MASCARA_CPF } from "../../../utils/Constants";

interface RecuperaPessoaProps {
  handlePessoa: (pessoa: IPessoa, dadosEmpresa?: IEmpresa ) => void;
  handleTipo: (tipo: string) => void;
  handleNumeroDocumento: (numeroDocumento: string) => void;
  handleNome: (nome: string) => void;
  documentoPadrao?: string;
  tiposDocumento?: string[];
  titulo?: string;
  submitForm?: (formik: any) => void;
}

function RecuperaPessoaEmpresaCNPJA(props: RecuperaPessoaProps) {
  const [tipoDocumento, setTipoDocumento] = useState<string>(
    props.documentoPadrao || "CPF"
  );
  const [tiposDocumentos, setTiposDocumentos] = useState<string[]>(
    props.tiposDocumento || ["CPF", "CNPJ", "OUTROS"]
  );
  const [erroDocumento, setErroDocumento] = useState<string>("");

  const recuperaPessoaSchema = Yup.object().shape({
    numeroDocumento: Yup.string().test(
      "documento-obrigatorio",
      "Validação de documento",
      (value) => {
        if (!value) {
          setErroDocumento("Documento deve ser preenchido");
          return false;
        }

        if (tipoDocumento === "CNPJ") {
          let cnpjValidar = value
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace("/", "")
            .replace(/_/g, "");

          if (cnpjValidar.length !== 14) {
            setErroDocumento("Preencha todo CNPJ");
            return false;
          }

          if (!validateCNPJ(cnpjValidar)) {
            setErroDocumento("CNPJ Inválido");
            return false;
          }

          setErroDocumento("");
          return true;
        } else if (tipoDocumento === "CPF") {
          let cpfValidar = value
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace(/_/g, "");

          if (cpfValidar.length !== 11) {
            setErroDocumento("Preencha todo CPF");
            return false;
          }

          if (!validateCPF(cpfValidar)) {
            setErroDocumento("CPF Inválido");
            return false;
          }

          setErroDocumento("");
          return true;
        } else {
          return true;
        }
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      numeroDocumento: "",
    },
    validationSchema: recuperaPessoaSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await buscarPessoa();
      setSubmitting(false);
    },
  });

  async function buscarPessoa() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-orange me-5",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    try {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: "Realizando operação. Aguarde...",
        showConfirmButton: false,
      })
      
      Swal.showLoading()

      const cnpj = formik.values.numeroDocumento.replaceAll('.','').replaceAll('-', '').replaceAll('/', '');
      
      const result = await PessoaService.buscarPessoaPorDocumento(
        formik.values.numeroDocumento
      );    

      const dadosEmpresa = await CNPJAService.pesquisarEmpresa(cnpj)
          dadosEmpresa.sociosAdministradores.map((dado:any, index) => {
            dado.key = index;
          })

      if (result.totalRegistros > 0) {
        let resultButton = await swalWithBootstrapButtons.fire({
          heightAuto: false,
          icon: "success",
          text: `A empresa foi encontrada, siga os próximos passos para continuar o cadastro.`,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Pesquisar outro documento",
          confirmButtonText: `Continuar`,
        });        

        if (resultButton.isConfirmed) { 
          props.handleTipo(tipoDocumento);
          props.handleNumeroDocumento(formik.values.numeroDocumento);
          props.handlePessoa(result.data[0], dadosEmpresa as unknown as IEmpresa);
         
        }
      }
    } catch (error: any) {
      
      if (error?.response?.status === 404) {
        let resultButton = await swalWithBootstrapButtons.fire({
          heightAuto: false,
          icon: "warning",
          text: `Pessoa não foi encontrada, para cadastrar usuário você deve criar uma Pessoa`,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Pesquisar outro documento",
          confirmButtonText: `Continuar`,
        });

        if (resultButton.isConfirmed) {   
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando operação. Aguarde...",
            showConfirmButton: false,
          })
          
          Swal.showLoading()     
 
          const cnpj = formik.values.numeroDocumento.replaceAll('.','').replaceAll('-', '').replaceAll('/', '');
          const dadosEmpresa = await CNPJAService.pesquisarEmpresa(cnpj)
          
          props.handleTipo(tipoDocumento);
          props.handleNumeroDocumento(formik.values.numeroDocumento);
          props.handleNome(dadosEmpresa.razaoSocial);

          if( dadosEmpresa ) {
            dadosEmpresa.sociosAdministradores.map((dado:any, index) => {
              dado.key = index;
            })
            props.handlePessoa({ pessoaId: 0} as IPessoa, dadosEmpresa  as unknown as IEmpresa);
          } else {
            props.handlePessoa({ pessoaId: 0 } as IPessoa);

          }          
        }

      } else {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar buscar Pessoa`,
          text:
            error?.response?.data?.Message && typeof error.response.data.Message
              ? error.response.data.Message
              : "", //error && error.response && error.response.data && typeof error.response.data === 'string' ? error.response.data : error.message,
          showConfirmButton: true,
        });
      }
    } finally {
      Swal.hideLoading()
    }
  }

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <>
      <h3 className="text-orange mb-3">{props.titulo || "Pesquisar Pessoa"}</h3>
      <form
        id="form-recupera-pessoa-modal"
        onSubmit={(e) => {
          e.preventDefault();
          props.submitForm ? props.submitForm(formik) : formik.submitForm();
        }}
        className="row g-3 mb-3"
      >
        {tiposDocumentos.length > 1 && (
          <div className="col-md-3 ">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Tipo Documento
            </label>
            <select
              value={tipoDocumento}
              onChange={(e) => {
                formik.resetForm();
                setTipoDocumento(e.target.value);
              }}
              placeholder="Tipo Documento"
              className={"form-select"}
              id="form-select"
            >
              {tiposDocumentos.map((tipo) => {
                return (
                  <option key={tipo} value={tipo}>
                    {" "}
                    {tipo}{" "}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        {tipoDocumento === "CPF" && (
          <div className="col-md-6 ">
            <label
              htmlFor="form-documento"
              className="form-label fw-bolder text-orange"
            >
              CPF
            </label>
            <MaskedInput
              mask={MASCARA_CPF}
              {...formik.getFieldProps("numeroDocumento")}
              placeholder="000.000.000-00"
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid":
                    formik.touched.numeroDocumento &&
                    formik.errors.numeroDocumento,
                },
                {
                  "is-valid":
                    formik.touched.numeroDocumento &&
                    !formik.errors.numeroDocumento,
                }
              )}
              id="form-documento"
            />
            {formik.touched.numeroDocumento &&
              formik.errors.numeroDocumento && (
                <div className="fv-plugins-message-container mt-1">
                  <div className="fv-help-block">
                    <span role="alert">{erroDocumento}</span>
                  </div>
                </div>
              )}
          </div>
        )}

        {tipoDocumento === "CNPJ" && (
          <div className="col-md-6 ">
            <label
              htmlFor="form-documento"
              className="form-label fw-bolder text-orange"
            >
              CNPJ
            </label>
            <MaskedInput
              mask={MASCARA_CNPJ}
              {...formik.getFieldProps("numeroDocumento")}
              placeholder="00.000.000/0000-00"
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid":
                    formik.touched.numeroDocumento &&
                    formik.errors.numeroDocumento,
                },
                {
                  "is-valid":
                    formik.touched.numeroDocumento &&
                    !formik.errors.numeroDocumento,
                }
              )}
              id="form-documento"
            />
            {formik.touched.numeroDocumento &&
              formik.errors.numeroDocumento && (
                <div className="fv-plugins-message-container mt-1">
                  <div className="fv-help-block">
                    <span role="alert">{erroDocumento}</span>
                  </div>
                </div>
              )}
          </div>
        )}

        {tipoDocumento === "OUTROS" && (
          <div className="col-md-6 ">
            <label
              htmlFor="form-documento"
              className="form-label fw-bolder text-orange"
            >
              Documento
            </label>
            <input
              {...formik.getFieldProps("numeroDocumento")}
              placeholder="Documento"
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid":
                    formik.touched.numeroDocumento &&
                    formik.errors.numeroDocumento,
                },
                {
                  "is-valid":
                    formik.touched.numeroDocumento &&
                    !formik.errors.numeroDocumento,
                }
              )}
              id="form-documento"
            />
            {formik.touched.numeroDocumento &&
              formik.errors.numeroDocumento && (
                <div className="fv-plugins-message-container mt-1">
                  <div className="fv-help-block">
                    <span role="alert">{erroDocumento}</span>
                  </div>
                </div>
              )}
          </div>
        )}
      </form>
    </>
  );
}

export default RecuperaPessoaEmpresaCNPJA;
