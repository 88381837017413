import * as Yup from "yup";
import { ECamposIDEnum, ETipoCampoEnum } from "../../enum";
import {
  MASCARA_CEP,
  MASCARA_CNPJ,
  MASCARA_CPF,
  MASCARA_TELEFONE,
} from "../../utils/Constants";

import { ICampo } from "../../interfaces/ICampo";

import EmpresaService from "../../services/EmpresaService";

import { camelizar, recuperarTipoCampo } from "../ConfiguracaoTelaPage/Empresa/utils";
import { Modelo } from "../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";

export const getFormValuesModelo = (campos: Modelo[]) => {
  try {
    const keyValuesFromCampos = campos.map((campo) => {
      const fieldName = camelizar(campo.nome || campo.campoNome);
      return {
        key: fieldNeedHaveId(campo) ? fieldName.concat("Id") : fieldName,
        value: campo.valor,
      };
    }) as any[];
    const keyValuesToFormValues = keyValuesFromCampos.reduce(
      (obj, item) => ((obj[item.key] = item.value), obj),
      {}
    ) as any;
    return keyValuesToFormValues;
  } catch (error: any) {
    console.log(
      `Algo errado com a função - getKeyValuesFromModelo: ${error.message}`
    );
  }
};

export const generateSchemaFromModelo = (fields: Modelo[]): any => {
  let schema: any = {};
  fields.forEach((field) => {
    
    if (field.campoNome != ECamposIDEnum.Representantes) {
      let fieldName = camelizar(field.campoNome);
      let campo = recuperarTipoCampo(field);

      if (fieldNeedHaveId(field)) {
        fieldName = fieldName.concat("Id");
      }

      if (field.obrigatorio) {
        campo = campo.required(`Campo é obrigatório.`).typeError('Campo é obrigatório.');
      }

      schema[fieldName] = campo;
    }

  });
  return Yup.object().shape(schema);
};

export const getMaskFromFieldName = (
  fieldName: string
): (string | RegExp)[] | undefined => {
  switch (fieldName.toLowerCase()) {
    case "cpf":
      return MASCARA_CPF;
    case "cnpj":
      return MASCARA_CNPJ;
    case "cep":
      return MASCARA_CEP;
    case "telefone":
      return MASCARA_TELEFONE;
  }
};

export const fieldNeedHaveId = (campo: Modelo) => {
  if (
    campo.tipo === ETipoCampoEnum.Lista &&
    !campo.multiplo &&
    campo.controller &&
    campo.controller.length > 0 &&
    !campo.controller.match("NULL") &&
    !campo.campoNome.endsWith("Id")
  ) {
    return true;
  }
  return false;
};

export const getFieldsCapaEmpresaValues = async (empresaId: number) => {
  let { data: result } = await EmpresaService.obterCapaEmpresa(empresaId);

  const values = getFormValuesModelo(result as unknown as Modelo[]);
  return values;
};

export const mergeValuesInModelo = (modelo: Modelo[], fields: ICampo[]) => {
  modelo.map((m) => {
    const campo = fields.find((f) => f.campo === m.campo);
    m.valor = campo?.valor;
    m.valorId = campo?.valorId;
  });
  return modelo;
};

export const showErrorMessage = (error: any) => {
  return error?.response?.data?.Message &&
    typeof error.response.data.Message === "string"
    ? error.response.data.Message
    : error.message;
};
