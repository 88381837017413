
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import PowerBI from "../../components/Comum/PowerBI/PowerBI";
import { EModulo } from "../../enum";
import "./style/icons.css";

const PowerBIDashboardPage = () => {

  return (
    <>
      <LayoutPageTitle title="Dashboard Contrato" />
      <PowerBI modulo={EModulo.Contrato} />
    </>
  );
}

export default PowerBIDashboardPage;
