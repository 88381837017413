import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import LayoutPageForButton from "../../layout/LayoutPageButton";
import { tipoPorModulo } from "../../utils/Utils";
import { EModulo, ETelaEnum } from "../../enum";

import IOption from "../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaBloqueioRequest } from "../../interfaces/Requests/Bloqueio/AdicionaBloqueioRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import BloqueioService from "../../services/BloqueioService";
import TipoBloqueioService from "../../services/TipoBloqueioService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import { OrangeContext } from "../../contexts/OrangeProvider";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Solucao/CamposCadastroSolucoes";


const BloqueioCadastroPage = () => {
  const { bloqueio, setBloqueio, tipoBloqueio, setTipoBloqueio } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [carregandoTipoBloqueio, setCarregandoTipoBloqueio] = useState(false);

  const navigate = useNavigate();

  const initialValues: AdicionaBloqueioRequest = {
    bloqueioId: 0,
    principalId: 0,
    categoriaId: bloqueio.categoriaId || 0,
    modulo: EModulo.Bloqueio,

    tela: ETelaEnum.TelaCadastroBloqueio,

    processoId: bloqueio.processoId || 0,
    areaDireitoId: bloqueio.areaDireitoId || 0,

    imovelId: bloqueio.imovelId || 0,
    tipoImovelId: bloqueio.tipoImovelId || 0,

    tipoBloqueioId: 0,

    atoSocietarioId: 0,

    descricao: "",
    databloqueio: null,
    datadesbloqueio: null,
    bancoId: null,
    agencia: "",
    conta: "",
    moedaId: null,
    valor: 0,
    codigo: "",

    xCampoValorList: []
  };

  useEffect(() => { carregarTipoBloqueio(); }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Bloqueio",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
           if (result.isConfirmed) {
 Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro do Bloqueio...",
            showConfirmButton: false,
          });

          Swal.showLoading();
          setAdicionarLoading(true);

          let resultado = await BloqueioService.adicionaBloqueio({
            ...values,
            modulo: bloqueio.modulo,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });

          Swal.hideLoading();

          setBloqueio({
            ...resultado,
            bloqueioId: resultado.bloqueioId,
            tipoBloqueioId: resultado.tipoBloqueioId,
            categoriaId: resultado.tipoBloqueioId,
            modulo: bloqueio.modulo,
            processoId: bloqueio?.processoId,
            areaDireitoId: bloqueio.areaDireitoId,
            tipoImovelId: bloqueio.tipoImovelId,
            imovelId: bloqueio?.imovelId,
          });

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarLoading(false);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel cadastrar o bloqueio.`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.tipoBloqueioId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      formik.setValues(initialValues);
    }
  }, [formik.values.tipoBloqueioId]);

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(bloqueio.modulo)}Id=${bloqueio.categoriaId}`);


      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        Modulo: bloqueio.modulo,
        Tela: ETelaEnum.TelaCadastroBloqueio,
        TipoBloqueioId: tipoBloqueio.tipoBloqueioId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  async function carregarTipoBloqueio() {
    try {
      setCarregandoTipoBloqueio(true);

      let objeto: any = {};

      objeto[`${tipoPorModulo(bloqueio.modulo)}Id`] = bloqueio.categoriaId;

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await TipoBloqueioService.obterListaLimitadaTipoBloqueios({
        nome: "",
        codigo: "",
        status: 1,
        modulo: bloqueio.modulo,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...objeto,
      });

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));
      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoTipoBloqueio(false);
    }

  }

  const onChangeTipoBloqueio = (value: any, texto: string) => {

    setConfiguracoesTela([]);

    formik.setFieldValue("tipoBloqueioId", parseInt(value));

    formik.setFieldValue("categoriaId", parseInt(value));

    setTipoBloqueio({
      tipoBloqueioId: parseInt(value),
      nome: texto,
      codigo: "",
      status: "",
      categoriaId: 0,
      categoriaNome: "",
      modulo: 0,
      moduloNome: "",
      opcaoNome: "",
      opcaoId: 0
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12  ">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Cadastro de Bloqueio`}>
        {bloqueio.bloqueioId > 0 ? (
          <>
            <button onClick={() => navigate("/Bloqueio/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{bloqueio.bloqueioId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoBloqueioId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>


      <div className="row mt-2">
        {carregandoTipoBloqueio ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipos de bloqueios...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Bloqueio
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoBloqueioId)?.label,
                value: formik.values.tipoBloqueioId,
              }}
              onChange={(option: any) => onChangeTipoBloqueio(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>
      <hr></hr>
      <>
        {renderCampos()}
      </>
    </>
  );
};

export default BloqueioCadastroPage;
