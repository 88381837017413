import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TableColumn } from "react-data-table-component";
import { BsCloudArrowDown, BsEye } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";

import { EModulo } from "../../enum";

import DocumentoService from "../../services/DocumentoService";

import IDocumento from "../../interfaces/IDocumento";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IFechamentoConfiguracao from "../../interfaces/IFechamentoConfiguracao";
import FiltroPesquisaFechamentoRequest from "../../interfaces/Requests/Fechamento/FiltroPesquisaFechamentoRequest";
import FiltroPesquisaDocumentoRequest from "../../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";

import GridPadrao from "../../components/Comum/GridPadrao";
import PreviewPDFField from "../../components/Comum/PreviewPDF/PreviewPDF";
import IFechamento from "../../interfaces/IFechamento";
import FechamentoService from "../../services/FechamentoService";
import { notifySuccess } from "../../components/Comum/Toast/ToastFormik";
import { ToastContainer } from "react-toastify";

interface IFechamentoFieldProps {
  toggleModal: () => void;
  fechamentoConfiguracao?: IFechamentoConfiguracao,
  exibirRelatorioModal: boolean,
  fechamentoId?: number,
}

const FechamentoField = ({ toggleModal, exibirRelatorioModal, fechamentoId }: IFechamentoFieldProps) => {
  const [modelos, setModelos] = useState<IDocumento[]>([]);
  const [carregandoModelo, setCarregandoModelo] = useState(false);

  const [carregandoGeracao, setCarregandoGeracao] = useState(false);
  const [fechamento, setFechamento] = useState<any[]>([]);
  const [fechamentoAnteriorId, setFechamentoAnteriorId] = useState<number>(0);

  const [exibirModalPDFDocumento, setModalExibirPDFDocumento] = useState<boolean>(false);
  const [urlPDFDocumento, setUrlPDFDocumento] = useState<string>("");

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaFechamentoRequest>({
    fechamentosConfiguracao: [],
    fechamentoId: 0,
    dataCorteMaiorIgual: "",
    dataCorteMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    status: 3,
    limit: 1000,
    totalItems: 0,
    offset: 0,
    sort: "fechamentoId",
  });

  const [filtrosPesquisaArquivos, setFiltrosPesquisaArquivos] = useState<FiltroPesquisaDocumentoRequest>({
    documentoId: [],
    modulo: [EModulo.Fechamento],
    nome: "",
    status: 1,
    origem: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "-documentoId",
  });

  const togglePDFDocumento = () => setModalExibirPDFDocumento(!exibirModalPDFDocumento);

  useEffect(() => {
    if (exibirRelatorioModal) {

      carregarDocumentos();

      carregarFechamentos();

    }
  }, [exibirRelatorioModal]);


  const iniciarRelatorio = async (): Promise<void> => {

    try {


      toggleModal();

      await FechamentoService.gerarExcelFechamento({ fechamentoAtualId: fechamentoId || 0, fechamentoAnteriorId: fechamentoAnteriorId });

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: 'Não foi possível baixar arquivos',
        text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
        timer: 4000,
        icon: 'error',
        showConfirmButton: false
      });
    } finally {
      setCarregandoGeracao(false);
    }
  }

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const carregarDocumentos = async () => {
    try {
      setCarregandoModelo(true);
      let resultado: IRecuperaLista<any>;
      resultado = await DocumentoService.obterArquivos({ ...filtrosPesquisaArquivos });

      setFiltrosPesquisaArquivos((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setModelos(resultado.data);
    } catch (error: any) {
      setModelos([]);
    } finally {
      setCarregandoModelo(false);
    }
  }

  const carregarFechamentos = async () => {
    try {
      let resultado: IRecuperaLista<IFechamento>;

      resultado = await FechamentoService.obterFechamentos(filtrosPesquisa);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setFechamento(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<any>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const previewArquivo = async (documentoId: number) => {
    try {
      const resultado = await DocumentoService.previewDocument(documentoId);
      setUrlPDFDocumento(resultado.data);
      setModalExibirPDFDocumento(true);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao obter o arquivo`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const verificaIsPdf = (extensao: string) => {
    if (extensao.endsWith(".pdf")) return true;
    else return false;
  };

  const colunas: TableColumn<any>[] = [
    {
      name: "ID",
      sortField: "documentoId",
      selector: (row: any) => row.documentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo",
      sortField: "tipoDocumentoNome",
      selector: (row: any) => row.tipoDocumentoNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descrição",
      sortField: "descricao",
      selector: (row: any) => row.descricao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Corte Anterior",
      sortField: "dataCorteFechamentoAnterior",
      selector: (row: any) =>
        moment(row.dataCorteFechamentoAnterior).isValid() ?
          moment(row.dataCorteFechamentoAnterior).format("DD/MM/yyyy") :
          "",
      sortable: true,
      wrap: true,
    },
    {
      name: "Corte Atual",
      sortField: "dataCorteFechamentoAtual",
      selector: (row: any) =>
        moment(row.dataCorteFechamentoAtual).isValid() ?
          moment(row.dataCorteFechamentoAtual).format("DD/MM/yyyy") :
          "",
      sortable: true,
      wrap: true,
    },
    {
      name: "Data",
      sortField: "dataCadastro",
      selector: (row: any) =>
        moment(row.dataCadastro).isValid() ?
          moment(row.dataCadastro).format("DD/MM/yyyy") :
          "",
      sortable: true,
      wrap: true,
    },

    {
      name: "",
      cell: (row: IDocumento) => {
        return (
          <div>
            <BsCloudArrowDown
              title="Baixar Arquivo"
              onClick={() => baixarArquivo(row.documentoId, row.nome)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="30px"
              className="mx-2 text-orange"
            />
            <>
              {verificaIsPdf(row.extensao) && (
                <BsEye
                  title="Preview"
                  onClick={() => previewArquivo(row.documentoId)}
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  size="30px"
                  className="mx-2 text-orange"
                />
              )}
            </>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  return (
    <Modal size="xl" centered={false} show={exibirRelatorioModal} onHide={toggleModal}>
      <ToastContainer />
      <PreviewPDFField exibirModal={exibirModalPDFDocumento} toggleModal={togglePDFDocumento} url={urlPDFDocumento} />
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Relatório de Fechamento
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">

          <div className="row mt-1">
            <div className="col-md-7 mb-10" style={{ cursor: "pointer" }}>
              <div className="input-group mb-3">
                <select
                  value={fechamentoAnteriorId}
                  onChange={(e) => setFechamentoAnteriorId(parseInt(e.target.value))}
                  className={"form-select"}
                >
                  <option value="0"> Selecione o mês anterior</option>
                  {fechamento.map((map) => {
                    return (
                      <option key={map.fechamentoId} value={map.fechamentoId}>
                        Data de Fechamento: {moment(map.dataCorte)?.format('DD/MM/YYYY')}
                      </option>
                    );
                  })}
                </select>

                {carregandoGeracao ? (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    <span className="spinner-border spinner-border-sm align-middle ms-2 text-orange"></span>
                  </span>
                ) : (
                  <div className="input-group-append">
                    <button onClick={() => {
                      notifySuccess("Relatório solicitado com sucesso.");
                      setCarregandoGeracao(true);
                      setTimeout(() => { iniciarRelatorio() }, 2000);
                    }} className="input-group-text btn-orange">Clique aqui para solicitar relatório
                      <FontAwesomeIcon color={"white"} className="mx-2 text-orange" icon={faArrowRight} /></button>
                  </div>)}
              </div>
            </div>
          </div>


          <div className="row mt-1">
            <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoModelo}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas}
                tipo="Modelos"
                itens={modelos}
              />
            </div>
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              onClick={() => toggleModal()}
              type="button"
              className="btn btn-danger ms-5"
            >
              Fechar
            </button>
          </div>
        </div>

      </div>
    </Modal >
  );
};

export default FechamentoField;