import { FiFilePlus, FiMinusCircle, FiSearch } from "react-icons/fi";
import {
  ClearButton,
  Container,
  Content,
  NewButton,
  Search,
  Separator,
  Title,
} from "./styles";
import { useState } from "react";

export function Filters() {
  const [focused, setFocused] = useState(false);

  return (
    <Container>
      <Title>Consultivo</Title>

      <Content>
        <Search focused={focused}>
          <label htmlFor="filterSearch">
            <FiSearch size={18} color="#797C7F" />
          </label>
          <input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            id="filterSearch"
            type="text"
            placeholder="Pesquisar"
          />
        </Search>

        <ClearButton>
          <FiMinusCircle />
          <span>Limpar filtros</span>
        </ClearButton>

        <Separator />

        <NewButton>
          <FiFilePlus size={20} color="#2E3334" />
          <span>Novo</span>
        </NewButton>
      </Content>
    </Container>
  );
}
