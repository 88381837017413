import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IRisco from "../interfaces/IRisco";
import { AdicionaRiscoRequest } from "../interfaces/Requests/Risco/AdicionaRiscoRequest";
import FiltroPesquisaRiscoRequest from "../interfaces/Requests/Risco/FiltroPesquisaRiscoRequest";
import { EditarRiscoRequest } from "../interfaces/Requests/Risco/EditarRiscoRequest";

class RiscoService {

    async obterRiscos(filtros: FiltroPesquisaRiscoRequest): Promise<IRecuperaLista<IRisco>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IRisco>>(`Risco/RecuperaRisco?${query}`);

        return result;
    }

    async obterListaLimitada(filtros: FiltroPesquisaRiscoRequest): Promise<IRecuperaLista<IRisco>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IRisco>>(`Risco/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaRisco(adicionaRisco: AdicionaRiscoRequest): Promise<IRisco> {
        let { data: result } = await api.post<IRisco>(`Risco/AdicionaRisco`, adicionaRisco);

        return result;
    }


    async alterarStatus(riscoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IRisco>(`Risco/AtualizaRiscoPorId?id=${riscoId}`, data);
    }

    async atualizarRisco(editarRisco: EditarRiscoRequest): Promise<void> {

        let dataRisco = [
            { "op": "replace", "path": "/nome", "value": editarRisco.nome },
            { "op": "replace", "path": "/codigo", "value": editarRisco.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Risco/AtualizaRiscoPorId?id=${editarRisco.riscoId}`, dataRisco)
        ]);
    }
}


export default new RiscoService();