/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useRef } from "react";
import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";


import GridPadrao from "../../components/Comum/GridPadrao";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IProcesso from "../../interfaces/IProcesso";
import { FiltroPesquisaProcessoRequest } from "../../interfaces/Requests/Processo/FiltroPesquisaProcessoRequest";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import moment from "moment";
import { BsCloudArrowDown } from "react-icons/bs";
import Swal from "sweetalert2";
import DocumentoService from "../../services/DocumentoService";
import { MultiSelect } from "react-multi-select-component";
import RelatorioService from "../../services/RelatorioService";
import { routePropriedade } from "../../utils/Utils";

const filtroInicial: any = {

  statusRelatorioProcessamento: [],
  modulo: [],

  dataCadastroMaiorIgual: null,
  dataCadastroMenorIgual: null,
  
  offset: 0,
  limit: 10,
  sort: "-relatorioProcessamentoId",
  totalItems: 0,
};

const RelatorioPage = () => {

  const [pesquisar, setPesquisar] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [relatorios, setRelatorios] = useState<any[]>([]);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaProcessoRequest>(filtroInicial);

  const [statusOptions] = useState<any>([
    { label: 'Finalizado', value: 3 },
    { label: 'Aguardando Processamento', value: 1 },
    { label: 'Executando', value: 2 },
    { label: 'Erro', value: -1 }
  ]);

  const [statusSelected, setStatusSelected] = useState([]);
  const [moduloOptions] = useState<any>([
    { label: "Contencioso", value: 1 },
    { label: "Societario", value: 5 },
    { label: "Contrato", value: 7 },
    { label: "Consultivo", value: 9 },
    { label: "Imovel", value: 10 },
    { label: "MarcaEPertences", value: 11 },
    { label: "Despesa", value: 12 },
    { label: "AtoSocietario", value: 13 },
    { label: "Bloqueio", value: 15 },
    { label: "Compromisso", value: 16 },
    { label: "Decisao", value: 17 },
    { label: "Desdobramento", value: 18 },
    { label: "Fechamento", value: 20 },
    { label: "Procuracao", value: 21 },
    { label: "Garantia", value: 22 },
    { label: "Obrigacao", value: 23 },
    { label: "ParteContraria", value: 40 },
    { label: "PropostaAcordo", value: 25 },
    { label: "Publicacao", value: 26 },
    { label: "Valores", value: 27 },
    { label: "Log", value: 28 },
    { label: "PesquisaGlobal", value: 29 },
    { label: "Regulatorio", value: 30 },
    { label: "Relatorio", value: 31 },
    { label: "Email", value: 32 },
    { label: "EditorDocumento", value: 33 },
    { label: "XpayHonorario", value: 34 },
    { label: "XpayReembolso", value: 35 },
    { label: "XpayFechamento", value: 36 },
    { label: "XpayContrato", value: 37 },
    { label: "Audiencia", value: 38 },
    { label: "Andamento", value: 14 },
    { label: "XFinderDistribuicao", value: 42 },
    { label: "Protocolo", value: 46 },
    { label: "Comunicacao", value: 47 }
  ]);

  const [moduloOptionsSelected, setModuloOptionsSelected] = useState([]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    moduloOptionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, modulo: IdsSelected };
    });
  }, [moduloOptionsSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    statusSelected.forEach((item: any) => IdsSelected.push(item.value));
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, statusRelatorioProcessamento: IdsSelected };
    });
  }, [statusSelected]);


  const componentRef = useRef<any>();


  useEffect(() => {

    carregarRelatorio(filtrosPesquisa);

  }, []);


  const carregarRelatorio = async (filtro: FiltroPesquisaProcessoRequest) => {
    try {

      setCarregando(true);

      let resultado: IRecuperaLista<IProcesso>;
      resultado = await RelatorioService.obterRelatorio(filtro);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setRelatorios(resultado.data);
      setCarregando(false);
      setPesquisar(false);

    } catch (error: any) {
      setRelatorios([]);
    } finally {
      setCarregando(false);
      setPesquisar(false);
    }
  }

  useEffect(() => {

    setPesquisar(true);

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);



  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {

          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }

          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    }
  };

  const colunas: TableColumn<any>[] = [
    {
      name: 'Id',
      sortField: 'relatorioProcessamentoId',
      selector: (row: any) => row.relatorioProcessamentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: 'Nome',
      sortField: 'modeloRelatorioNome',
      selector: (row: any) => row.modeloRelatorioNome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: 'Módulo',
      sortField: 'modulo',
      selector: (row: any) => routePropriedade(row.modulo),
      sortable: true,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: "Status",
      sortField: 'statusRelatorioProcessamento',
      sortable: true,
      wrap: true,
      cell: (row: any) => (
        <div>
          {row?.statusRelatorioProcessamento === 1 && (
            <>
              <span>Aguardando</span>
            </>
          )}
          {row?.statusRelatorioProcessamento === 2 && (
            <>
              <span>Executando</span>
            </>
          )}
          {row?.statusRelatorioProcessamento === 3 && (
            <>
              <span>Finalizado</span>
            </>
          )}
          {row?.statusRelatorioProcessamento === -1 && (
            <>
              <span>Erro</span>
            </>
          )}
        </div>
      ),
    },
    {
      name: 'Data da Solicitação',
      sortField: 'dataSolicitacao',
      selector: (row: any) => moment(row.dataSolicitacao).format("DD/MM/YYYY HH:mm"),
      sortable: true,
      wrap: true
    },
    {
      name: 'Opções',
      sortField: 'status',
      sortable: true,
      wrap: true,
      cell: (row: any) => {
        return (
          <>
            {row.statusRelatorioProcessamento == 3 ?
              <BsCloudArrowDown
                title="Baixar Arquivo"
                onClick={() => baixarArquivo(row.documentoId, row?.documentoNome)}
                style={{ fontWeight: "normal", cursor: "pointer" }}
                size="30px"
                className="mx-2 text-orange"
              />
              : "Não Disponível"}
          </>
        )
      },
    },

  ]

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IProcesso>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    if (pesquisar) carregarRelatorio(filtrosPesquisa);
  }, [pesquisar]);


  return (
    <>
      <LayoutPageTitle title="Baixar Relatórios" />


      <div className="row">
        <div className="col-md-12">
          <form onSubmit={(e) => e.preventDefault()} className="row g-3 mb-3 form">

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                isLoading={false}
                disabled={false}
                options={statusOptions}
                value={statusSelected}
                onChange={(event: any) => setStatusSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>


            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Modulo:
              </label>
              <MultiSelect
                isLoading={false}
                disabled={false}
                options={moduloOptions}
                value={moduloOptionsSelected}
                onChange={(event: any) => setModuloOptionsSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-dataPublicacao" className="form-label fw-bolder text-orange">Data da Solicitação:</label>
              <DateTimeRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={[
                  filtrosPesquisa.dataCadastroMaiorIgual || "", // Valor inicial para a data de início
                  filtrosPesquisa.dataCadastroMenorIgual || "" // Valor inicial para a data de fim
                ]}
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length !== 2 || !datas[0] || !datas[1]) {
                    setFiltrosPesquisa((oldValue: any) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: "",
                      dataCadastroMenorIgual: "",
                    }));
                    return;
                  }

                  const [dataInicial, dataFinal] = datas;
                  if (!(dataInicial instanceof Date) || !(dataFinal instanceof Date)) {
                    console.error("Invalid Date Object");
                    return;
                  }

                  const dataFinalAdjusted = new Date(dataFinal);
                  dataFinalAdjusted.setHours(0, 0, 0, 0);
                  dataFinalAdjusted.setDate(dataFinalAdjusted.getDate());

                  setFiltrosPesquisa((oldValue: any) => ({
                    ...oldValue,
                    dataCadastroMaiorIgual: dataInicial ? `${dataInicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                    dataCadastroMenorIgual: dataFinalAdjusted ? `${dataFinalAdjusted.toISOString().split("T")[0]}T23:59:59.9999` : "",
                  }));
                }}
              />
            </div>

          </form>

        </div>
      </div>

      <div className="row">
        <div className="col-12"
          style={{ textAlign: 'right' }}>
          <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
            <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
          </button>
          <button
            onClick={() => {
              setFiltrosPesquisa(filtroInicial);
              setPesquisar(false);
              setStatusSelected([]);
            }}
            className="btn btn-sm btn-orange search-buttom-margin ms-2"
          >
            <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
          </button>

        </div>
      </div>

      <div className="row">
        <div className="col-md-12" style={{ overflow: !carregando ? "auto" : "scroll", height: "600px" }}>
          <div ref={componentRef}>
            <GridPadrao
              onSort={handleSort}
              progressPending={carregando}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Relatorio"
              itens={relatorios}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatorioPage;
