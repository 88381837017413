import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { EModulo, ETelaEnum } from "../../enum";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";

import ITipoLicenca from "../../interfaces/ITipoLicenca";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaLicencaRequest } from "../../interfaces/Requests/Licenca/IAdicionaLicencaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import LicencaService from "../../services/LicencaService";
import TipoLicencaService from "../../services/TipoLicencaService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";
import { licencaInitialValues, tipoLicencaInicialValues } from "../../contexts/InitialValuesContext";


const LicencaCadastroPage = () => {
  const navigate = useNavigate();
  const { licenca, setLicenca, setTipoLicenca, tipoLicenca } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [carregandoTiposLicenca, setCarregandoTiposLicenca] = useState<boolean>(false);
  const [tiposLicenca, setTiposLicenca] = useState<ITipoLicenca[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const initialValues: IAdicionaLicencaRequest = {
    licencaId: 0,
    tipoLicencaId: 0,

    modulo: EModulo.Regulatorio,
    tela: ETelaEnum.TelaCadastroRegulatorio,
    categoriaId: 0,

    numerolicenca: "",
    canal: "",
    fistel: "",
    observacoeslicenca: "",
    numerolicencadiacritics: "",

    orgaoResponsavelId: 0,
    cidadeId: 0,
    tipoServicoId: 0,
    restrito: false,

    dataemissao: null,
    datavencimento: null,
    dataConcessao: null,

    empresasList: [],
    advogadoInternoList: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Licença",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          setAdicionarLoading(true);
          let resultado = await LicencaService.adicionaLicenca({
            ...values,
            tipoLicencaId: tipoLicenca.tipoLicencaId,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });
          setLicenca(resultado);
          setTipoLicenca(tipoLicenca);
          formik.resetForm();
          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Licença cadastrado com sucesso`,
            timer: 4000,
          });
        }
        setAdicionarLoading(false);
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
        setSubmitting(false);
        setConfiguracoesTela([]);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setLicenca(licencaInitialValues);

    setTipoLicenca(tipoLicencaInicialValues);

    carregarTipoLicenca();

    setConfiguracoesTela([]);

  }, []);

  useEffect(() => {
    if (tipoLicenca.tipoLicencaId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoLicenca(tipoLicencaInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoLicenca.tipoLicencaId]);

  const carregarTipoLicenca = async () => {
    try {
      setCarregandoTiposLicenca(true);
      let resultado = await TipoLicencaService.obterTipoLicencas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTiposLicenca(resultado.data);
      setCarregandoTiposLicenca(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
      setCarregandoTiposLicenca(false);
      setTiposLicenca([]);
    }
  };

  const carregarConfiguracaoTela = async () => {
    setCarregandoConfiguracoesTela(true);
    let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
      TipoLicencaId: tipoLicenca.tipoLicencaId,
      Modulo: EModulo.Regulatorio,
      Tela: ETelaEnum.TelaCadastroRegulatorio,
    });
    setConfiguracoesTela(resultado.data);
    setCarregandoConfiguracoesTela(false);
  };

  const onChangeTipoLicenca = (value: number, texto: string) => {
    console.log(value)
    setConfiguracoesTela([]);
    formik.resetForm();
    formik.setFieldValue("tipoLicencaId", value);
    formik.setFieldValue("categoriaId", value);
    formik.setFieldValue("modulo", EModulo.Regulatorio);
    formik.setFieldValue("tela", ETelaEnum.TelaCadastroRegulatorio);
    setTipoLicenca({
      tipoLicencaId: value,
      nome: texto,
      codigo: "",
      status: "",
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Nova ${tipoLicenca.tipoLicencaId > 0 ? " - " + tipoLicenca.nome : ""}`}>
        {licenca.licencaId > 0 ? (
          <>
            <button onClick={() => navigate("/Licenca/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{licenca.licencaId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              disabled={adicionarLoading}
              style={{ display: !formik.values.tipoLicencaId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposLicenca ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando lista de tipos...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo:
            </label>
            <select
              name="tipoLicencaId"
              value={tipoLicenca.tipoLicencaId}
              className={"form-select"}
              onChange={(event: any) => onChangeTipoLicenca(event.target.value, event.target[event.target.selectedIndex].text)}
              placeholder="Nome"
              id="form-area"
            >
              <option value="0"> Selecione um tipo de licenca</option>
              {tiposLicenca.map((tipoLicenca) => {
                return (
                  <option key={tipoLicenca.tipoLicencaId} value={tipoLicenca.tipoLicencaId}>
                    {tipoLicenca.nome}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default LicencaCadastroPage;
