import axios from "axios";
import StorageUtils from "../utils/StorageUtils";
import IAutenticacao from "../interfaces/IAutenticao";

let _retryRefleshTpken = 0;

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  //baseURL: "https://apim-xjur-qa.xjur.com.br/",
  withCredentials: true,
});

export const returnLogin = async () => {
  try {
    const user = await StorageUtils.getUser();
    if (user?.activeDitectory) {
      StorageUtils.clear();
      window.location.href = process.env.REACT_APP_SITE_URL ? process.env.REACT_APP_SITE_URL : "https://xjur.com.br";
    } else {
      StorageUtils.clear();
      window.location.reload();
    }
  } catch (error) {
    console.info("Error: returnLogin:", error);
    StorageUtils.clear();
  }
};

api.interceptors.request.use(
  async (config: any) => {
    if (
      config.url.toLowerCase().indexOf("twofactor") < 0 &&
      config.url.toLowerCase().indexOf("authenticate") < 0 &&
      config.url.toLowerCase().indexOf("refreshtoken") < 0
    ) {
      if (new Date().getTime() > StorageUtils.getAccessExpireIn()) {
        returnLogin();
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) =>
            cancel("Sessão Expirada")
          ),
        };
      }
    }

    await StorageUtils.updateAccessExpireIn();
    if (config.url.toLowerCase().indexOf("refreshtoken") >= 0) {
      delete config.headers.Authorization;
      delete config.headers.common["Authorization"];
    } else {
      const accessToken = await StorageUtils.getToken();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (err: any) => Promise.reject(err)
);

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error && error.response && error.response.status === 498) {
      try {
        originalRequest._retry = true;

        _retryRefleshTpken += 1;

        if (_retryRefleshTpken > 10) returnLogin();

        let ip = "";

        try {
          const resultado = await (await fetch("https://v4.ident.me")).text();
          ip = resultado;
        } catch (error) {
          const resultado = await (
            await fetch("https://api.ipify.org?format=json")
          ).json();
          ip = await resultado.ip;
        }

        let { data: result } = await api.post<IAutenticacao>(
          "Autenticacao/RefreshToken",
          { ip }
        );

        StorageUtils.setToken(result.token);

        api.defaults.headers.common["Authorization"] = "Bearer " + result.token;
      } catch (error) {
        returnLogin();
      }

      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default api;
