import styled from "styled-components";

export const Container = styled.div`
  background-color: #F3F4F6;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  padding: 10px 15px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2E3334;

  div.title {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  span {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
  border-radius: 4px;
`;