import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import GridPadrao from "./GridPadrao";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

interface IListaSelectedProps {
  entidade: string;
  tipoId: number;
  setFieldValue: any;
  ListaSelected: any[];
}

const ListaCamposSolucoesField = ({ tipoId, entidade, setFieldValue, ListaSelected }: IListaSelectedProps) => {
  const [Lista, setLista] = useState<any[]>([]);
  const [carregandoListaCamposs, setCarregandoListaCamposs] = useState(false);
  const [marcarTodos, setMarcaTodos] = useState<boolean>(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<any>({
    tipoId: tipoId,
    nome: "",
    codigo: "",
    status: 1,
    offset: 0,
    limit: 5,
    sort: `${entidade}Id`,
    totalPaginas: 0,
  });

  useEffect(() => {
    if (Lista.length > 0) {
      ListaSelected.forEach((item) => {
        const ListaCamposChecked = Lista.filter((e: any) => eval(`e.${entidade}Id`) === eval(`item.${entidade}Id`))[0];
        if (ListaCamposChecked) ListaCamposChecked.checked = true;
      });
    }
    setLista(Lista);
  }, [Lista]);

  const handleChange = (id: number) => {
    Lista.forEach((item) => {
      if (eval(`item.${entidade}Id`) === id) {
        if (!item.checked) {
          item.checked = true;
          ListaSelected.push(item);
          setLista(Lista);
          return;
        }
        if (item.checked) {
          item.checked = false;
          ListaSelected = ListaSelected.filter((e) => eval(`e.${entidade}Id`) !== eval(`item.${entidade}Id`));
          setLista(Lista);
          return;
        }
      }
    });
    setFieldValue("Lista", ListaSelected, true);
  };

  const handleChangeAll = (marcar: boolean) => {
    setMarcaTodos(marcar);

    Lista.forEach((item) => {
      if (!marcarTodos) {
        item.checked = true;
        ListaSelected.push(item);
        setLista(Lista);
        return;
      } else {
        item.checked = false;
        ListaSelected = ListaSelected.filter((e) => eval(`e.${entidade}Id`) !== eval(`item.${entidade}Id`));
        setLista(Lista);
        return;
      }
    });
    setFieldValue("Lista", ListaSelected, true);
  };

  async function carregarListaCampos(filtro: any) {
    try {
      setCarregandoListaCamposs(true);
      setMarcaTodos(false);
      let resultado: IRecuperaLista<any>;

      resultado = await ConfiguracaoTelaService.obterCampo(filtro, entidade);

      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setLista(resultado.data);
    } catch (error: any) {
      setLista([]);
    } finally {
      setCarregandoListaCamposs(false);
    }
  }

  const colunas: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: `${entidade}Id`,
      selector: (row: any) => eval(`row.${entidade}Id`),
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: any) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: any) => {
        return (
          <div>
            <label>
              <FormCheckInput
                name="terms"
                checked={row.checked}
                onChange={() => handleChange(eval(`row.${entidade}Id`))}
                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              />
            </label>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState: any) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<any>, sortDirection: string) => {
    setFiltrosPesquisa((oldState: any) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    carregarListaCampos(filtrosPesquisa);

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarListaCampos(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Nome
              </label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>

            <div className="col-md-2">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Código
              </label>
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={"form-control"}
                id="form-codigo"
              />
            </div>

            <div className="col-md-2">
              <button
                type="button"
                className="btn btn-sm btn-orange search-buttom-margin "
                onClick={(e) => {
                  carregarListaCampos(filtrosPesquisa);
                }}
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
              </button>
            </div>

            <div className="row mt-3">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Selecionar pagina atual: {"  "}
                <FormCheckInput
                  name="terms"
                  checked={marcarTodos}
                  onChange={(e: any) => handleChangeAll(e.target.checked)}
                  style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                />
              </label>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoListaCamposs}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="selecoes"
            itens={Lista}
          />
        </div>
      </div>
    </>
  );
};

export default ListaCamposSolucoesField;
