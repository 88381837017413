import styled from "styled-components";

export const Container = styled.div`
  > button, > label {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    background: none;
    border: none;
    padding: 10px;
    transition: 0.3s;

    > input[type='file'] {
      display: none;
    }

    > svg {
      font-size: 20px;
    }

    &:hover {
      background: #f2f2fa;
    }
  }

  > form {
    display: flex;
    flex-direction: column;

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    padding: 20px;

    display: flex;
    justify-content: center;

    > button {
      border: none;
      color: #312e38;
      background: transparent;
      font-weight: bold;
      height: 40px;
      border-radius: 4px;
      transition: 0.3s ease;

      &:not(:disabled) {
        &:hover {
          background: #f2f2fa;
        }
      }

      &:disabled {
        opacity: 0.4;
      }
    }
  }
`;
