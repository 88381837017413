import { useRef } from "react";

export default function useDebounce(fn: any, delay: number) {
  const timeoutRef = useRef<any>(null);
  function debounceFn(...args: any) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fn(...args);
    }, delay);
  }
  return debounceFn;
}