import { faPen, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import * as Formik from 'formik';
import Swal from "sweetalert2";

import { IPessoa } from "../../../interfaces/IPessoa";
import { ISocioAdministrador } from '../EmpresaPage/Solucoes/Interfaces';
import { IConfiguracaoTela } from "../../../interfaces/IConfiguracaoTela";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import { fieldNeedHaveId, generateSchemaFromModelo, getFormValuesModelo, showErrorMessage } from "../utils";

import { ModalAdicionaPessoa } from "./ModalAdicionaPessoa";
import GridPadrao from "../../../components/Comum/GridPadrao";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { FieldText } from "../../../components/Comum/Fields/FieldText";
import RecuperaPessoa from "../../../components/Comum/Pessoa/RecuperaPessoa";
import { CamposCadastroEmpresaDinamicos } from "../../../components/Empresa";
import { EPessoaTipoEmpresa } from "../../../enum/EPessoaTipoEmpresa";
import { camelizar } from "../../ConfiguracaoTelaPage/Empresa/utils";
import { Modelo } from "../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";
import moment from "moment";


export interface TelaCadastroSolucaoProps {
	toggleModal: (value?: boolean) => void;
	adicionar: (value: any, pessoaAtual?: IPessoa) => Promise<boolean>;
	alterarStatus: (value: any) => void;
	editar: (value: any) => void;
	loading?: boolean;
	exibirModal: boolean;
	modelo: Modelo;
	colunasDoGrid: TableColumn<any>[];
	dados: any[];
	tela: IConfiguracaoTela;
	pessoasInicial?: any[];
}

const TelaCadastroSolucao = ({
	toggleModal,
	adicionar,
	alterarStatus,
	editar,
	loading,
	exibirModal,
	modelo,
	colunasDoGrid,
	dados,
	tela,
	pessoasInicial,
}: TelaCadastroSolucaoProps) => {

	const { empresa } = useContext<OrangeInterfaceContext>(OrangeContext);
	const [exibirPessoaModal, setExibirPessoaModal] = useState(false);
	const [colunas, setColunas] = useState<any>();
	const [pessoaAtual, setPessoaAtual] = useState<any>();
	const [items, setItems] = useState<any[]>([]);
	const [camposExtras, setCamposExtras] = useState<Modelo[] & { maxValue?: number }>([]);

	const [tipoDocumento, setTipoDocumento] = useState('');
	const [numeroDocumento, setNumeroDocumento] = useState('');
	const [pessoas, setPessoas] = useState<any[]>(pessoasInicial || []);

	console.log(dados);

	useLayoutEffect(() => {
		if (colunasDoGrid) {
			colunasDoGrid.push(opcoes);
			setColunas(colunasDoGrid);
		}

	}, []);

	const calculaQuotasDisponivel = (): number => {
		const quotasTotalInicial = 100
		const restanteDeQuotas = empresa.sociosAdministradores?.reduce((acc, socio) => acc - (socio.quotasAcoes || 0), quotasTotalInicial)
		return restanteDeQuotas !== undefined ? restanteDeQuotas : quotasTotalInicial
	}

	const calculaQuotasIndividual = (socioAdministrador: ISocioAdministrador): number => {
		const quotasTotalInicial = calculaQuotasDisponivel()
		const quotaIndidual = empresa.sociosAdministradores.find(e => e.socioAdministradorId === socioAdministrador.socioAdministradorId)?.quotasAcoes
		if (quotaIndidual !== undefined) {
			return quotasTotalInicial + quotaIndidual + 0.0001
		}
		return 0;
	}

	const salvar = async (formValues: any, remove = false) => {
		const campoId = modelo.campoNome + 'Id';
		if (remove) {
			alterarStatus(formValues);
		} else {
			if (formValues[campoId]) {
				editar(formValues);
				setPessoaAtual(undefined);
				setExibirPessoaModal(false);
				toggleModal(false);
				resetarValores();
			} else {
				const resultado = await adicionar(formValues, pessoaAtual);
				if (resultado) {
					setPessoaAtual(undefined);
					setExibirPessoaModal(false);
					toggleModal(false);
					resetarValores();
				}
			}
		}
	};

	const formik = useFormik<any>({
		validationSchema: generateSchemaFromModelo(camposExtras || []),
		initialValues: getFormValuesModelo(modelo.camposExtras as Modelo[]),
		validateOnBlur: true,
		onSubmit: (values: any) => {
			formik.setSubmitting(true);
			salvar(values);
		}
	});


	const opcoes = {
		name: 'Opções',
		cell: (row: any) => {
			return (
				<div>
					<FontAwesomeIcon
						title={`Editar ${modelo.label}`}
						onClick={() => {
							setPessoaAtual(row);
							formik.setValues(row);
						}}
						style={{ fontWeight: 'normal', cursor: 'pointer' }}
						size='2x'
						className='mx-2 text-orange'
						icon={faPen}
					/>
					{modelo.campoNome != "socioAdministrador" &&
						<FontAwesomeIcon
							title={`Excluir ${modelo.label}`}
							onClick={() => { salvar(row, true) }}
							style={{ fontWeight: 'normal', cursor: 'pointer' }}
							size='2x'
							className='mx-2 text-orange'
							icon={faTrash}
						/>
					}
				</div>
			);
		},
		ignoreRowClick: true,
	};

	const selecionarPessoa = (pessoa: IPessoa) => {
		try {
			if (pessoa.pessoaId === 0) {
				toggleModal(false);
				setExibirPessoaModal(true);
			}
			else if (pessoas.find((x: IPessoa) => x.pessoaId == pessoa.pessoaId)) {
				Swal.fire({
					heightAuto: false,
					icon: "info",
					title: "Pessoa já está cadastrada!",
					showConfirmButton: true,
				});
				setPessoas(
					pessoas.map(pessoa => {
						return { ...pessoa };
					})
				);
				return;
			} else {
				if (modelo.camposExtras && modelo.camposExtras.length) {
					setPessoaAtual({ ...pessoa });
					formik.setFieldValue('pessoaId', pessoa.pessoaId);
				} else {
					setPessoas([{ ...pessoa }
					]);
				}
			}

		} catch (error: any) {
			Swal.fire({
				title: 'Erro Inesperado',
				icon: 'error',
				text: showErrorMessage(error),
				showConfirmButton: true,
				timer: 3000,
			})
		}
	};

	const adicionarPessoa = (pessoa: IPessoa) => {
		if (pessoa.pessoaId) {
			setExibirPessoaModal(false)
			setPessoaAtual({ ...pessoa })
			formik.setFieldValue('pessoaId', pessoa.pessoaId);
		}
	}

	const resetarValores = () => {
		formik.resetForm();
		setPessoaAtual(undefined);
	}



	useEffect(() => {
		if (dados) {
			dados.forEach(dado => {
				dado.dataEntrada = moment(dado.dataEntrada).format('YYYY-MM-DD');
				dado.dataVencimentoMandato = moment(dado.dataVencimentoMandato).format('YYYY-MM-DD');
				dado.dataSaida = moment(dado.dataSaida).format('YYYY-MM-DD');
				dado.dataProximaReeleicao = moment(dado.dataProximaReeleicao).format('YYYY-MM-DD');
			});
		}
		setPessoas(dados);
		setItems(dados);
	}, [empresa, dados, exibirModal]);

	useEffect(() => {
		if (pessoaAtual) {
			if (modelo.camposExtras) {
				const v = modelo.camposExtras;
				v.map((e: Modelo & { maxValue?: number }) => {
					if (e.campoNome === "quotasAcoes") {
						e.maxValue = calculaQuotasIndividual(pessoaAtual);
					}
				})
				setCamposExtras(v)
			}
		}

	}, [pessoaAtual]);

	useLayoutEffect(() => {
		if (formik.values.socioAdministradorId) {

			formik.setFieldValue("dataEntrada", moment(new Date(formik.values.dataEntrada)).format("YYYY-MM-DD"));
			switch (formik.values.status) {
				case "Ativo":
					formik.setFieldValue("status", 1);
					break;

				default:
					formik.setFieldValue("status", -1);
					break;
			}

		}
	}, [formik.values.socioAdministradorId]);

	return (
		<>
			<div style={{ width: '100%' }}>
				<GridPadrao
					tipo={modelo.label}
					colunas={colunas}
					itens={items}
				/>
			</div>

			<Modal size="lg" className={'societario'} show={exibirModal} onHide={() => toggleModal()}>
				<Modal.Header>
					<Modal.Title>{modelo.label}</Modal.Title>
					<div onClick={() => { toggleModal() }} className="btn btn-icon close btn-sm ms-2">
						<FontAwesomeIcon className="mx-2" icon={faTimes} />
					</div>
				</Modal.Header>

				<Modal.Body>
					<RecuperaPessoa
						handlerTipo={setTipoDocumento}
						handlerNumeroDocumento={setNumeroDocumento}
						handlerPessoa={selecionarPessoa}
					/>
				</Modal.Body>

			</Modal>

			<ModalAdicionaPessoa
				className={'societario'}
				title={modelo.label}
				handlePessoa={adicionarPessoa}
				tipoDocumento={tipoDocumento}
				numeroDocumento={numeroDocumento}
				showModal={exibirPessoaModal}
				setShowModal={setExibirPessoaModal}
			/>

			<Modal size="lg" className={'societario'} centered={false} show={(pessoaAtual !== undefined)} onHide={() => { resetarValores() }}>
				<Modal.Header>
					<Modal.Title>{modelo.label} - Dados Adicionais</Modal.Title>
					<button onClick={() => resetarValores()} className={'btn btn-icon close'} >
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</Modal.Header>
				<Modal.Body>
					{pessoaAtual?.pessoaId && (
						<>
							{modelo.campoNome == EPessoaTipoEmpresa.Representantes &&
								<Row>
									<div className="col-12 col-md-4 col-lg-2">
										<Form.Label className="fw-bold text-orange">Pode assinar?
											<FormCheckInput
												{...formik.getFieldProps("podeAssinar")}
												type="checkbox"
												checked={formik.values.podeAssinar}
												style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
												onClick={(e: any) => (formik.setFieldValue("podeAssinar", e.target.checked))} />
										</Form.Label>
									</div>
								</Row>
							}



							<Row>
								<div className="col-12 col-md-4 col-lg-3">
									<FieldText disabled={true} name={'tipoDocumento'} label="Tipo de Documento" value={pessoaAtual?.tipoDocumento} />
								</div>

								<div className="col-12 col-md-4 col-lg-3">
									<FieldText disabled={true} name={'Documento'} label="Documento" value={pessoaAtual?.numeroDocumento} />
								</div>

								<div className="col-12 col-md-4 col-lg-3">
									<FieldText disabled={true} name={'Nome'} label="Nome" value={pessoaAtual?.nome} />
								</div>
							</Row>
						</>
					)}
					<Row>



						{pessoaAtual && modelo.camposExtras && modelo.camposExtras.map((campoExtra: any, index: number) => {
							let campoNome = camelizar(campoExtra.campoNome);

							if (fieldNeedHaveId(campoExtra)) {
								campoNome = campoNome + 'Id';
							}

							return <div key={index} className="col-md-6 col-lg-4 mt-3">
								<CamposCadastroEmpresaDinamicos
									modelo={campoExtra}
									campo={{
										onChange: (e: any) => {
											setPessoaAtual({
												...pessoaAtual,
												[campoNome]: e.target.value
											});
											formik.setFieldValue(campoNome, e.target.value);
										},
										value: formik.values[campoNome] || "",
										name: campoNome,
										maxValue: campoExtra.maxValue ? calculaQuotasIndividual(pessoaAtual) : null,
										onBlur: formik.getFieldProps(campoNome).onBlur
									}}
									touched={formik.touched[campoNome] as Formik.FormikTouched<any>}
									error={formik.errors[campoNome] as Formik.FormikErrors<any>}
									tela={tela}
									handleSetValue={(value: any) => {
										setPessoaAtual({
											...pessoaAtual,
											[campoNome]: value
										});

										if (Array.isArray(value.value)) {
											formik.setFieldValue(value.name, value.value);
										} else {
											formik.setFieldValue(campoNome, value.value || value);
										}
									}}
								/>
							</div>;
						})}
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<button
						onClick={() => formik.submitForm()}
						disabled={!formik.isValid}
						className={'btn btn-orange'} >
						{loading ?
							<span hidden={!loading} className="indicator-progress" style={{ display: "block" }}>
								Carregando...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
							: 'Salvar'}

					</button>
					<Button onClick={() => { resetarValores() }} className={'btn btn-danger'}>Cancelar</Button>
				</Modal.Footer>
			</Modal>

		</>
	);
}

export default TelaCadastroSolucao;