/* eslint-disable @typescript-eslint/no-empty-function */
import { ETipoCampoEnum } from "../../../../enum";
import { Popover, OverlayTrigger, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import React from "react";


import { IRecuperaConfiguracaoTelaResponse } from "../../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { faEdit, faPaperPlane, faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CamposService from "../../../../services/CamposService";
import { notifySuccess } from "../../Toast/ToastFormik";
import { routePropriedade } from "../../../../utils/Utils";
import CurrencyField from "../../CurrencyBRL/CurrencyField";

interface IFormListTabelaFieldProps {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
  capa?: boolean;
}

const FormListTabelaField = ({
  configuracaoTela,
  formik,
  capa,
}: IFormListTabelaFieldProps) => {

  const [editMode, setEditMode] = useState<any>({});

  const [editedValues, setEditedValues] = useState<any>({});

  useEffect(() => {
    if (capa) {
      inicializar();
    }
  }, [configuracaoTela.capaCampoTabelaReadDto]);

  const inicializar = () => {
    if (!formik.values[`${configuracaoTela.campoNome?.toLowerCase()}`]) {
      formik.values[`${configuracaoTela.campoNome?.toLowerCase()}`] = [];
      let linhas = configuracaoTela?.capaCampoTabelaReadDto?.linhas || [];

      linhas.forEach((item: any, index: number) => {
        let colunas = item.colunas || [];

        colunas.forEach((linha: any) => {
          linha.linhaIndex = index;
          linha.tipo = configuracaoTela?.capaCampoTabelaReadDto?.xCampoTabelaPropriedades?.find((e: any) => e.colunaIndex == linha.colunaIndex)?.tipo;
          linha.xCampoValorId = configuracaoTela.xCampoValorId;
          linha.campoId = configuracaoTela.campo;
        });

        formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), linhas);
      });
    }
  };

  const popover = (help: string) => (
    <Popover id="popover-basic">
      <Popover.Body>{help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = (help: string) => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover(help)}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const Colunas = () => {
    const colunas = capa
      ? configuracaoTela?.capaCampoTabelaReadDto?.xCampoTabelaPropriedades || []
      : configuracaoTela?.xCampoTabelaPropriedades || [];

    return colunas.map((coluna: any, index: number) => (
      <th
        style={{
          textAlign: coluna?.tipo == ETipoCampoEnum.Valor ? 'right' : 'left',
          paddingRight: coluna?.tipo === ETipoCampoEnum.Valor ? '10px' : '0'
        }}
        key={index}
        className="text-orange"
      >
        {coluna.label}
        {coluna.help && PopoverHelper(coluna.help)}
      </th>
    ));
  };

  const Cadastro = () => {
    const linhasPorIndice: { [key: number]: any[] } = {};
    const colunas = configuracaoTela.xCampoTabelaPropriedades || [];
    const linhas = formik?.values[`${configuracaoTela?.campoNome?.toLowerCase()}`]?.sort((a: any, b: any) => (a?.linhaIndex < b?.linhaIndex ? -1 : 1));

    linhas.forEach((linha: any) => {
      if (!linhasPorIndice[linha.linhaIndex]) {
        linhasPorIndice[linha.linhaIndex] = [];
      }

      linhasPorIndice[linha.linhaIndex].push(linha);
    });

    return Object.keys(linhasPorIndice).map((indice: any) => (
      <tr key={indice}>
        {colunas.map((coluna) => (
          <td
            style={{
              textAlign: coluna?.tipo == ETipoCampoEnum.Valor ? 'right' : 'left',
              paddingRight: coluna?.tipo === ETipoCampoEnum.Valor ? '10px' : '0'
            }}
            key={coluna.colunaIndex}
            className="text-orange"
          >
            {linhasPorIndice[indice].map((linha, index) => (
              <React.Fragment key={index}>
                {linha.colunaIndex === coluna.colunaIndex ? Linhas(linha, editMode[`${indice}-${coluna.colunaIndex}`], indice, index) : null}
              </React.Fragment>
            ))}
          </td>
        ))}
      </tr>
    ));
  };

  const Capa = () => {
    try {
      let colunas = configuracaoTela?.capaCampoTabelaReadDto?.linhas || [];
      return colunas.map((coluna: any, index: number) => (
        <tr key={`row-${index}`} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
          {coluna.colunas.map((linha: any, colIndex: number) => (
            <td
              style={{
                textAlign: linha?.tipo == ETipoCampoEnum.Valor ? 'right' : 'left',
                paddingRight: linha?.tipo === ETipoCampoEnum.Valor ? '10px' : '0'
              }}
              key={`column-${colIndex}`}
              className="text-orange"
            >
              {Linhas(linha, editMode[`${index}-${colIndex}`], index, colIndex)}

            </td>
          ))}
          {capa && !configuracaoTela.somenteLeitura && (
            <td key={`delete-${index}`} className="text-orange">
              <FontAwesomeIcon
                style={{ cursor: 'pointer' }}
                className="mx-4 text-orange"
                icon={faTrash}
                onClick={() => Remover(coluna)}
              />
            </td>
          )}
        </tr>
      ));
    } catch (error) {
      return null; // Return null or an appropriate error message
    }
  };
  const Linhas = (linha: any, isEditing: boolean, rowIndex: number, colIndex: number) => {

    const handleInputChange = (e: any) => {
      const { value } = e.target;
      setEditedValues((prevState: any) => ({
        ...prevState,
        [`${rowIndex}-${colIndex}`]: value
      }));
    };


    switch (linha?.tipo) {
      case ETipoCampoEnum.Inteiro:
        return isEditing ? (
          <InputGroup>
            <input
              type="number"
              maxLength={50}
              width={200}
              defaultValue={linha.inteiro}
              className="form-control form-control-sm"
              onChange={handleInputChange}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              className="mx-4 text-orange"
              icon={faPaperPlane}
              onClick={() => onEditar(rowIndex, colIndex)}
            />
          </InputGroup>
        ) : (
          <>
            {linha.inteiro}
            <FontAwesomeIcon
              style={{ cursor: 'pointer', visibility: capa ? 'initial' : 'hidden' }}
              className="mx-4 text-orange"
              icon={faEdit}
              onClick={() => onChange(rowIndex, colIndex, linha)}
            />
          </>
        );

      case ETipoCampoEnum.Data:
        return isEditing ? (
          <InputGroup>
            <input
              type="date"
              className="form-control form-control-sm"
              onChange={handleInputChange}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              className="mx-4 text-orange"
              icon={faPaperPlane}
              onClick={() => onEditar(rowIndex, colIndex)}
            />
          </InputGroup>
        ) : (
          <>
            {moment(linha.data).format('DD/MM/YYYY')}
            <FontAwesomeIcon
              style={{ cursor: 'pointer', visibility: capa ? 'initial' : 'hidden' }}
              className="mx-4 text-orange"
              icon={faEdit}
              onClick={() => onChange(rowIndex, colIndex, linha)}
            />
          </>
        );

      case ETipoCampoEnum.Texto:
        return isEditing ? (
          <InputGroup>
            <input
              type="text"
              maxLength={50}
              defaultValue={linha.texto}
              className="form-control form-control-sm"
              onChange={handleInputChange}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              className="mx-4 text-orange"
              icon={faPaperPlane}
              onClick={() => onEditar(rowIndex, colIndex)}
            />
          </InputGroup>
        ) : (
          <>
            {linha.texto}
            <FontAwesomeIcon
              style={{ cursor: 'pointer', visibility: capa ? 'initial' : 'hidden' }}
              className="mx-4 text-orange"
              icon={faEdit}
              onClick={() => onChange(rowIndex, colIndex, linha)}
            />
          </>
        );

      case ETipoCampoEnum.Valor:
        return isEditing ? (
          <InputGroup>
            <CurrencyField
              className="form-control form-control-sm"
              onValueChange={(e: any) => handleInputChange({ target: { value: e.floatValue } })}
              floatValue={linha.valor}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              className="mx-4 text-orange"
              icon={faPaperPlane}
              onClick={() => onEditar(rowIndex, colIndex)}
            />
          </InputGroup>
        ) : (
          <>
            {linha.valor ? (
              (() => {
                const formattedValue = new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
                  .formatToParts(linha.valor)
                  .filter((part) => part.type !== 'currency')
                  .map((part) => part.value)
                  .join('');

                return <b>{formattedValue}</b>;
              })()
            ) : (
              <b>0,00</b>
            )}
            <FontAwesomeIcon
              style={{ cursor: 'pointer', visibility: capa ? 'initial' : 'hidden' }}
              className="mx-4 text-orange"
              icon={faEdit}
              onClick={() => onChange(rowIndex, colIndex, linha)}
            />
          </>
        );

      default:
        return null;
    }
  };

  const onChange = (index: number, colIndex: number, linha: any) => {
    setEditMode((prevState: any) => ({
      ...prevState,
      [`${index}-${colIndex}`]: !prevState[`${index}-${colIndex}`]
    }));

    setEditedValues((prevState: any) => ({
      ...prevState,
      [`${index}-${colIndex}`]: linha.texto
    }));
  };


  const onEditar = async (rowIndex: number, colIndex: number) => {

    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-orange",
        cancelButton: "btn btn-danger ms-5",
      },
      buttonsStyling: false,
    });

    let result = await swalWithBootstrapButtons.fire({
      title: "Editar",
      text: `Você realmente deseja editar?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Confirmar`,
    });

    if (result.isConfirmed) {

      const updatedLinhas = [...formik.values[`${configuracaoTela.campoNome?.toLowerCase()}`]];
      const row = updatedLinhas[rowIndex];
      const cell = row.colunas[colIndex];

      switch (cell.tipo) {
        case ETipoCampoEnum.Texto:

          cell.texto = editedValues[`${rowIndex}-${colIndex}`];
          await CamposService.alterarPorXCampoTabela(cell.tipo, cell.texto, cell.xCampoValorTabelaId, routePropriedade(formik.values.modulo));
          break;

        case ETipoCampoEnum.Inteiro:
          cell.inteiro = editedValues[`${rowIndex}-${colIndex}`];
          await CamposService.alterarPorXCampoTabela(cell.tipo, cell.inteiro, cell.xCampoValorTabelaId, routePropriedade(formik.values.modulo));
          break;

        case ETipoCampoEnum.Valor:
          cell.valor = editedValues[`${rowIndex}-${colIndex}`];
          await CamposService.alterarPorXCampoTabela(cell.tipo, cell.valor, cell.xCampoValorTabelaId, routePropriedade(formik.values.modulo));
          break;

        case ETipoCampoEnum.Data:
          cell.data = editedValues[`${rowIndex}-${colIndex}`];
          await CamposService.alterarPorXCampoTabela(cell.tipo, cell.data, cell.xCampoValorTabelaId, routePropriedade(formik.values.modulo));
          break;

        default:
          break;
      }

      setEditMode((prevState: any) => ({
        ...prevState,
        [`${rowIndex}-${colIndex}`]: false
      }));

      formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), updatedLinhas);

      notifySuccess('Campo atualizado com sucesso.');

    }

  };

  const Remover = async (row: any) => {

    const swalWithBootstrapButtons = Swal.mixin({
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-orange",
        cancelButton: "btn btn-danger ms-5",
      },
      buttonsStyling: false,
    });

    let result = await swalWithBootstrapButtons.fire({
      title: "Excluir",
      text: `Você realmente deseja excluir essa linha?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: `Confirmar`,
    });

    if (result.isConfirmed) {

      row.colunas.forEach(async (linha: any) => {
        await CamposService.removerXCampoValorTabelaPorLinha(linha.xCampoValorTabelaId, formik.values.modulo);
      });

      let colunas = configuracaoTela?.capaCampoTabelaReadDto?.linhas || [];
      let atualizar: any[] = [];

      colunas.forEach((coluna: any) => {
        if (coluna?.colunas[0]?.xCampoValorTabelaId != row.colunas[0]?.xCampoValorTabelaId)
          atualizar.push(coluna);
      });

      configuracaoTela.capaCampoTabelaReadDto.linhas = atualizar;

      formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), atualizar);

      notifySuccess(`Linha excluida com sucesso.`);

    }
  };


  return (
    <>
      <table className="table">
        <thead className="thead-dark">
          <tr>{Colunas()}</tr>
        </thead>
        <tbody>{capa ? Capa() : Cadastro()}</tbody>
      </table>
    </>
  );
};

export default FormListTabelaField;
