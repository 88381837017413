import { Layout, Content } from "./styles";
import Spinner from 'react-bootstrap/Spinner'

export function EditorLoader() {
  return (
    <Layout>
      <Content>
        <Spinner animation="border" className="text-orange" />
        <strong>Carregando Minuta...</strong>
      </Content>
    </Layout>
  );
}
