import ptBR from "date-fns/locale/pt-BR";
import { format } from "date-fns";
import { MdApps, MdClose } from "react-icons/md";
import { EditorVersionData } from "../../../../interfaces/IEditorVersionResponse";
import {
  BagdgeVersion,
  Container,
  Version,
  EditorArea,
  Content,
  Versions,
  Header,
  VersionHeader,
  LoaderContainer,
} from "./styles";
import { useEditorVersion } from "../../hooks/useVersion";
import { useTenant } from "../../hooks/useTenant";
import { renderElement, renderLeaf } from "../Element";
import { useEditor } from "../../hooks/useEditor";
import { Slate } from "slate-react";
import { useValue } from "../../hooks/useValue";
import { useProvider } from "../../hooks/useProvider";
import { Drawer } from "../Drawer";
import { useDrawer } from "../Drawer/useDrawer";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { blockHelper } from "../../helpers/blockHelper";

export const VersionList = () => {
  const { editor } = useEditor();
  const { initialValue, populate, staticInsert } = useValue(editor);
  const { tenant } = useTenant();
  const { open, toggle: toggleDrawer } = useDrawer();
  const { setVersionData, versionData, setEditorLoading, editorLoading } =
    useProvider();

  const { versions } = useEditorVersion({
    provider: tenant,
    editor,
    removeOnlyHtml: true,
  });

  function formatDate(dateString: string) {
    return format(new Date(dateString), "d 'de' MMMM, HH:mm", { locale: ptBR });
  }

  async function handleSelectVersion(version: EditorVersionData) {
    setEditorLoading(true);
    const data = version.jsonContent.payload;

    if (!data.nodes) {
      toast.error('Não foi possível carregar o documento, versão indisponível!');
      return
    }

    if (version.paginado) {
      setVersionData(version);
      setEditorLoading(false);
      staticInsert(data);
      toggleDrawer();
      return;
    }

    toggleDrawer();

    populate({
      ...data,
      editor,
      removeOnlyHtml: true,
      pageNumber: 0,
      onFinish: () => {
        setVersionData(version);
        setEditorLoading(false);
        blockHelper.replacePageCount(editor);
        toggleDrawer();
      },
    });
  }

  const versionContent = versionData?.jsonContent.payload;

  return (
    <>
      <Drawer open={open}>
        <VersionHeader>
          <strong>Versões</strong>
          <button onClick={() => toggleDrawer()} type="button">
            <MdClose size={20} />
          </button>
        </VersionHeader>

        <Versions>
          {versions.map((version) => (
            <Version
              onClick={() => handleSelectVersion(version)}
              selected={version.versao === versionData?.versao}
              key={version.versao}
            >
              <div className="content">
                {versionData && (
                  <div className="content-body">
                    <strong>
                      {version.jsonContent.payload.title || "Sem Título"}
                    </strong>
                    <small>
                      Por: {version.nomeUsuario} Em:{" "}
                      {formatDate(version.dataCadastro)}
                    </small>
                  </div>
                )}

                <div className="content-footer">
                  <BagdgeVersion
                    currentVersion={versionData?.versao === version.versao}
                  >
                    Versão{" "}
                    {versions[0].versao === version.versao
                      ? "Atual"
                      : `: ${version.versao}`}
                  </BagdgeVersion>
                </div>
              </div>
            </Version>
          ))}
        </Versions>
      </Drawer>

      <Container>
        <Slate value={initialValue()} editor={editor}>
          <Container>
            <Header>
              <div>
                <strong>{versionContent?.title || "Sem Título"}</strong>
                {versionData && (
                  <span>
                    Publicada por {versionData.nomeUsuario} em{" "}
                    {formatDate(versionData.dataCadastro)}
                  </span>
                )}
              </div>

              <button onClick={() => toggleDrawer()} type="button">
                <MdApps size={20} />
                <span>
                  Versões <small>({versions.length})</small>
                </span>
              </button>
            </Header>

            {editorLoading && (
              <LoaderContainer>
                <div>
                  <Spinner size="sm" />
                </div>
              </LoaderContainer>
            )}

            <Content>
              <div id="editor-container">
                <EditorArea
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                />
              </div>
            </Content>
          </Container>
        </Slate>
      </Container>
    </>
  );
};
