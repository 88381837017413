import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IEstado from "../interfaces/IEstado";
import { AdicionaEstadoRequest } from "../interfaces/Requests/Estado/AdicionaEstadoRequest";
import FiltroPesquisaEstadoRequest from "../interfaces/Requests/Estado/FiltroPesquisaEstadoRequest";
import { EditarEstadoRequest } from "../interfaces/Requests/Estado/EditarEstadoRequest";

class EstadoService {

    async obterEstados(filtros: FiltroPesquisaEstadoRequest): Promise<IRecuperaLista<IEstado>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IEstado>>(`Estado/RecuperaEstado?${query}`);

        return result;
    }

    async obterEstadoPorSigla(uf: string): Promise<IRecuperaLista<IEstado>> {

        let { data: result } = await api.get<IRecuperaLista<IEstado>>(`Estado/RecuperaEstado?status=-1&status=1&offset=0&limit=100&UF=${uf}`);

        return result;
    }

    async adicionaEstado(adicionaEstado: AdicionaEstadoRequest): Promise<IEstado> {
        let { data: result } = await api.post<IEstado>(`Estado/AdicionaEstado`, adicionaEstado);

        return result;
    }


    async alterarStatus(estadoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IEstado>(`Estado/AtualizaEstadoPorId?id=${estadoId}`, data);
    }

    async atualizarEstado(editarEstado: EditarEstadoRequest): Promise<void> {

        let dataEstado = [
            { "op": "replace", "path": "/nome", "value": editarEstado.nome },
            { "op": "replace", "path": "/codigo", "value": editarEstado.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Estado/AtualizaEstadoPorId?id=${editarEstado.estadoId}`, dataEstado)
        ]);
    }
}


export default new EstadoService();