
import React, { useEffect, useState, useContext } from 'react';
import { faEraser, faSearch, faFileExcel, faBuilding, faTimes, faCheck, faTrash, faFileImport, faCalendar, faPlusCircle, faSpinner, faRobot, faArchive } from '@fortawesome/free-solid-svg-icons';
import { BsFillArrowUpRightCircleFill, BsSearch } from 'react-icons/bs';
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelect } from 'react-multi-select-component';
import { TableColumn } from 'react-data-table-component';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Swal from "sweetalert2";

import ProcessoService from '../../services/ProcessoService';
import PublicacaoService from '../../services/PublicacaoService';
import AreaDireitoService from '../../services/AreaDireitoService';
import AdvogadoInternosService from '../../services/AdvogadoInternosService';

import PublicacaoIAModal from './PublicacaoIAModal';
import PublicacaoVincularModal from './PublicacaoVincularModal';
import PublicacaoRelatorioModal from './PublicacaoRelatorioModal';
import CompromissoField from '../../components/Comum/Compromisso/CompromissoField';
import PublicacaoDetalhes from '../../components/Comum/Publicacao/PublicacaoDetalhes';
import GridPadrao from '../../components/Comum/GridPadrao';
import DropDown from '../../components/Comum/DropDown/DropDown';

import { EModulo } from '../../enum';

import IPublicacao from '../../interfaces/IPublicacao';
import ICompromisso from '../../interfaces/ICompromisso';
import IAreaDireito from '../../interfaces/IAreaDireito';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import IAdvogadoInterno from '../../interfaces/IAdvogadoInterno';
import { OrangeInterfaceContext } from '../../interfaces/Contexts/OrangeInterfaceContext';

import AndamentoField from '../../components/Comum/Andamento/AndamentoField';

import { OrangeContext } from '../../contexts/OrangeProvider';
import { notifyWarning } from '../../components/Comum/Toast/ToastFormik';

import IProcesso from '../../interfaces/IProcesso';
import IAndamentos from '../../interfaces/IAndamentos';
import FiltroPesquisaPublicacaoRequest from '../../interfaces/Requests/Publicacao/FiltroPesquisaPublicacaoRequest';

import { useAuth } from '../../contexts/AuthProvider';

const PublicacaoPage = () => {

    const { user } = useAuth();

    const navigate = useNavigate();

    const { setProcesso, processo } = useContext<OrangeInterfaceContext>(OrangeContext);

    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoPublicacoes, setCarregandoPublicacoes] = useState(false);
    const [exibirModalPublicacao, setExibirModalPublicacao] = useState<boolean>(false);

    const [carregamentoAreaDireito, setCarregamentoAreaDireito] = useState<boolean>(false);
    const [areasDireitoOptions, setAreasDireitoOptions] = useState<any>([]);
    const [areaDireitoSelected, setAreaDireitoSelected] = useState([]);

    const [carregamentoAdvogados, setCarregamentoAdvogados] = useState<boolean>(false);
    const [advogadosOptions, setAdvogadosOptions] = useState<any>([]);
    const [advogadosSelected, setAdvogadosSelected] = useState([]);

    const [statusOptions] = useState<any>([{ label: 'Pendente', value: 1 }, { label: 'Lido', value: 2 }, { label: 'Descartado', value: 3 },]);
    const [statusSelected, setStatusSelected] = useState([]);

    const [exibirModalAndamento, setExibirModalAndamento] = useState<boolean>(false);
    const [editarAndamento] = useState<IAndamentos>({
        processoParteContraria: [],
        andamentoId: 0,
        andamentoTipoId: 0,
        modulo: EModulo.AreaDireito,
        codigo: "",
        dataAndamento: new Date(),
        andamentoTipoNome: "",
        descricao: "",
        restrito: false,
        dataCadastro: "",
        xFinderAndamentoTipoExterno: "",
        numeroProcesso: "",
    });

    const [, setAtualizarTabelaCompromisso] = useState<boolean>(false);
    const [exibirCompromisso, setExibirCompromisso] = useState<boolean>(false);
    const [editarCompromisso] = useState<ICompromisso>({
        modulo: EModulo.AreaDireito,
        tipoCompromissoNome: "",
        compromissoId: 0,
        codigo: "",
        dataConclusao: new Date(),
        dataCompromisso: new Date(),
        observacaoCompromisso: "",
        observacaoConclusao: "",
        tipoCompromissoId: 0,
        usuariosAprovadoresList: [],
        horario: "",
        grupoId: []
    });
    const [exibirIA, setExibirIA] = useState<boolean>(false);
    const [exibirProcesso, setExibirProcesso] = useState<boolean>(false);
    const [exibirTeorModal, setExibirTeorModal] = useState<boolean>(false);
    const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);

    const [publicacoes, setPublicacoes] = useState<IPublicacao[]>([]);
    const [publicacao, setPublicacao] = useState<IPublicacao>({
        classificacaoPublicacao: "",
        processoParteContraria: [],
        codigo: "",
        dataCadastro: new Date(),
        dataDivulgacao: new Date(),
        dataPublicacao: new Date(),
        estadoId: 0,
        fonte: "",
        idTermoCadastrado: "",
        numeroProcessoCapturado: "",
        processoId: 0,
        publicacaoId: 0,
        status: "",
        teorPublicacao: "",
        estadoNome: ""
    });

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaPublicacaoRequest>({
        publicacaoId: [],
        areaDireitoId: [],
        modulo: EModulo.AreaDireito,
        processoIdentificado: false,
        processoId: 0,
        estadoId: 0,
        idTermoCadastrado: "",
        advogadoInternoId: [],
        fonte: "",
        dataPublicacaoMaiorIgual: null,
        dataPublicacaoMenorIgual: null,
        dataDivulgacaoMaiorIgual: "",
        dataDivulgacaoMenorIgual: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        numeroProcessoCapturado: "",
        classificacaoPublicacao: "",
        teorPublicacao: "",
        codigo: "",
        ultimosQuantidade: null,
        limit: 10,
        totalItems: 0,
        offset: 0,
        filtro: "",
        sort: "-DataPublicacao",
        status: [1],
    });

    const toggleIAModal = () => setExibirIA(!exibirIA);
    const toggleProcessoModal = () => setExibirProcesso(!exibirProcesso);
    const toggleModalPublicacao = () => setExibirTeorModal(!exibirTeorModal);
    const toggleAndamento = () => setExibirModalAndamento(!exibirModalAndamento);
    const toggleCompromissoModal = () => setExibirCompromisso(!exibirCompromisso);
    const togglePublicacao = (): void => setExibirModalPublicacao(!exibirModalPublicacao);
    const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

    const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa((oldState: any) => { return { ...oldState, limit: currentRowsPerPage } });
    const handlePageChange = (page: number) => setFiltrosPesquisa((oldState: any) => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    const handleSort = async (column: TableColumn<IPublicacao>, sortDirection: string) => setFiltrosPesquisa((oldState: any) => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });

    useEffect(() => {

        carregarAreaDireito();

        carregarAdvogadoInterno();

    }, []);

    useEffect(() => {
        if (!loadingInicial) {
            carregarPublicacoes(filtrosPesquisa);
        }
    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

    useEffect(() => {
        const IdsSelected: number[] = [];
        areaDireitoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
        setFiltrosPesquisa((oldState) => {
            return { ...oldState, areaDireitoId: IdsSelected };
        });
    }, [areaDireitoSelected]);

    useEffect(() => {
        const IdsSelected: number[] = [];
        statusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
        setFiltrosPesquisa((oldState) => {
            return { ...oldState, status: IdsSelected };
        });
    }, [statusSelected]);

    useEffect(() => {
        const IdsSelected: number[] = [];
        advogadosSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
        setFiltrosPesquisa((oldState) => {
            return { ...oldState, advogadoInternoId: IdsSelected };
        });
    }, [advogadosSelected]);

    const returnOptions = (array: any, propId: string) => {
        let options: any[] = [];

        array.forEach((item: any) => {
            options.push({
                value: eval(`item.${propId}`),
                label: item.nome,
            });
        });

        return options;
    };

    const carregarAreaDireito = async () => {
        try {
            setCarregamentoAreaDireito(true);
            let resultado: IRecuperaLista<IAreaDireito>;
            resultado = await AreaDireitoService.obterListaLimitada();
            setAreasDireitoOptions(returnOptions(resultado.data, "areaDireitoId"));
            setCarregamentoAreaDireito(false);
        } catch (error: any) {
            setCarregamentoAreaDireito(false);
            setAreasDireitoOptions([]);
        }
    }

    const carregarAdvogadoInterno = async () => {
        try {
            setCarregamentoAdvogados(true);
            let resultado: IRecuperaLista<IAdvogadoInterno>;

            resultado = await AdvogadoInternosService.obterAdvogadosPorFiltro({
                filtro: "",
                tipoPesquisa: 1,
                status: 1,
                limit: 1000,
                totalItems: 0,
                offset: 0,
                sort: "advogadoInternoId",
            });

            setAdvogadosOptions(returnOptions(resultado.data, "advogadoInternoId"));
            setCarregamentoAdvogados(false);
        } catch (error: any) {
            setCarregamentoAdvogados(false);
            setAdvogadosOptions([]);
        }
    }

    const carregarPublicacoes = async (filtro: FiltroPesquisaPublicacaoRequest): Promise<void> => {
        try {
            setCarregandoPublicacoes(true);

            let resultado: IRecuperaLista<IPublicacao>;
            resultado = await PublicacaoService.obterPublicacoes(filtro);

            setFiltrosPesquisa((oldState: any) => { return { ...oldState, totalItems: resultado.totalRegistros } });
            setPublicacoes(resultado.data);

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros.',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
        }
        finally {
            setCarregandoPublicacoes(false);
            setLoadingInicial(false);
        }
    }

    const limparFiltros = () => {
        setFiltrosPesquisa((oldValue: any) => {
            return {
                ...oldValue,
                modulo: EModulo.AreaDireito,
                processoId: processo.processoId,
                publicacaoId: [],
                areaDireitoId: [],
                estadoId: 0,
                idTermoCadastrado: "",
                advogadoInternoId: [],
                fonte: "",
                dataPublicacaoMaiorIgual: null,
                dataPublicacaoMenorIgual: null,
                dataDivulgacaoMaiorIgual: "",
                dataDivulgacaoMenorIgual: "",
                dataCadastroMaiorIgual: "",
                dataCadastroMenorIgual: "",
                numeroProcessoCapturado: "",
                classificacaoPublicacao: "",
                teorPublicacao: "",
                codigo: "",
                ultimosQuantidade: null,
                limit: 10,
                totalItems: 0,
                offset: 0,
                filtro: "",
                sort: "-DataPublicacao",
                status: [1],
                processoIdentificado: false
            }
        });
    }

    const alterarStatus = async (Ids: number[], status: number) => {
        try {
            const swalWithBootstrapButtons = Swal.mixin({
                heightAuto: false,
                customClass: {
                    confirmButton: 'btn btn-orange',
                    cancelButton: 'btn btn-danger ms-5'
                },
                buttonsStyling: false
            });

            let result = await swalWithBootstrapButtons.fire({
                title: `${Ids.length > 1 ? Ids.length + ' publicações selecionadas' : Ids.length + ' publicação selecionada'} `,
                text: `Você realmente deseja ${status == 2 ? 'marcar como lido' : ''}${status == 3 ? 'descartar' : ''}?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Confirmar`
            });

            if (result.isConfirmed) {

                Ids.forEach(async publicacaoId => {
                    await PublicacaoService.alterarStatus(publicacaoId, status);
                });

                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Status alterado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                });

                await carregarPublicacoes(filtrosPesquisa);
            }

        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel alterar o Status do Banco`,
                text: error?.response?.data?.Message,
                showConfirmButton: true
            });
        }
    }

    const adicionar = (publicacaoId: number) => {
        if (isValid(publicacaoId)) {
            const updatedPublicacoes = publicacoes.map(publicacao => {
                if (publicacao.publicacaoId === publicacaoId) {
                    return { ...publicacao, checked: !publicacao.checked };
                }
                return publicacao;
            });
            setPublicacoes(updatedPublicacoes);
        }
    };

    const isValid = (publicacaoId: number) => {
        const publicacao = publicacoes?.find(e => e.status === "Pendente" && e.publicacaoId === publicacaoId);

        if (!publicacao) {
            notifyWarning('Publicação deve estar como status de pendente para selecionar.');
        }

        return !!publicacao;
    };


    const publicacoesMarcadas = () => {
        let selecionados = publicacoes?.filter((e: IPublicacao) => e.checked) || [];
        return selecionados.map(publicacao => publicacao.publicacaoId);
    };


    const carregarProcesso = async (processoId: number) => {
        try {

            let resultado: IRecuperaLista<IProcesso>;

            resultado = await ProcessoService.obterProcessoPorId(processoId);

            setPublicacao({
                ...publicacao,
                areaDireitoId: resultado.data.find(e => e)?.areaDireitoId,
                processoId: processoId // setar a area do direito
            });

            setProcesso({ ...processo, processoId: processoId });

        } catch (error: any) {
            await Swal.fire({
                heightAuto: false,
                icon: "error",
                title: `Ocorreu um erro`,
                text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
                showConfirmButton: true,
            });
        } finally {
            toggleAndamento();
        }
    };

    const colunas: TableColumn<IPublicacao>[] = [
        {
            name: "Selecionar",
            ignoreRowClick: true,
            cell: (row: IPublicacao) => {
                return (
                    <div>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            style={{ cursor: 'pointer' }}
                            checked={row?.checked}
                            disabled={row.status !== "Pendente"}
                            onClick={() => adicionar(row.publicacaoId)}
                        />
                    </div>
                );
            },
        },
        {
            name: 'Id',
            sortField: 'publicacaoId',
            selector: (row: IPublicacao) => row.publicacaoId,
            sortable: true,
            wrap: true
        },
        {
            name: "Status",
            ignoreRowClick: true,
            cell: (row: IPublicacao) => {
                return (
                    <div>
                        {row?.status == "Lido" && <div placeholder='Lido' className="lidoStatus"></div>}
                        {row?.status == "Pendente" && <div placeholder='Pendente' className="pendenteStatus"></div>}
                        {row?.status == "Descartado" && <div placeholder='Descartado' className="excluidaStatus"></div>}
                    </div>
                );
            },
        },
        {
            name: 'ID Processo',
            sortField: 'processoId',
            selector: (row: IPublicacao) => row.processoId,
            sortable: true,
            wrap: true
        },
        {
            name: 'Número Processo',
            sortField: 'numeroProcessoCapturado',
            selector: (row: IPublicacao) => row.numeroProcessoCapturado,
            sortable: true,
            wrap: true
        },
        {
            name: 'Parte Contrária',
            sortField: 'processoParteContraria',
            cell: (row: IPublicacao) => {
                let nomes = '';
                if (row?.processoParteContraria?.length < 3) {
                    for (let index = 0; index < row?.processoParteContraria?.length; index++) {
                        nomes += row.processoParteContraria[index].nome;
                    }
                } else {
                    if (row?.processoParteContraria?.length > 3) {
                        for (let index = 0; index < 3; index++) {
                            nomes += row.processoParteContraria[index].nome;
                        }
                    }
                }
                return `${nomes}...`;
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Data Publicação',
            sortField: 'dataPublicacao',
            selector: (row: IPublicacao) => {
                const data = row.dataPublicacao.toString() || ''
                if (data.includes('T')) {
                    const [ano, mes, dia] = data.split('T')[0].split('-')
                    const [horario] = data.split('T')[1].split('.')
                    return `${dia}/${mes}/${ano} ${horario}`
                }
                return '-'
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Data de Divulgação',
            sortField: 'dataDivulgacao',
            selector: (row: IPublicacao) => {
                const data = row.dataDivulgacao.toString() || ''
                if (data.includes('T')) {
                    const [ano, mes, dia] = data.split('T')[0].split('-')
                    const [horario] = data.split('T')[1].split('.')
                    return `${dia}/${mes}/${ano} ${horario}`
                }
                return '-'
            },
            sortable: true,
            wrap: true
        },
        {
            name: 'Estado',
            sortField: 'estado',
            selector: (row: IPublicacao) => row.estadoNome,
            sortable: true,
            wrap: true
        },
        {
            name: 'Teor Publicação',
            cell: (row: IPublicacao) => {
                return (
                    <>
                        {row.teorPublicacao?.length > 50 ? <> <div title={row.teorPublicacao}> {row.teorPublicacao?.substring(0, 50)}...</div></> : row.teorPublicacao}
                    </>
                )
            },
            ignoreRowClick: true,
        },
        {
            name: 'Opções',
            cell: (row: IPublicacao) => {
                return (
                    <>
                        <DropDown
                            height={400}
                            totalItems={publicacoes?.length}
                            content={
                                <>
                                    <Dropdown.Item onClick={async () => {
                                        setPublicacao(row);
                                        setExibirModalPublicacao(true);
                                    }}>
                                        <BsSearch
                                            title="Detalhes"
                                            onClick={async () => {
                                                setPublicacao(row);
                                                setExibirModalPublicacao(true);
                                            }}
                                            style={{ fontWeight: "normal", cursor: "pointer" }}
                                            size="25px"
                                            className="mx-2 text-orange"
                                        /><label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                            Detalhes
                                        </label>
                                    </Dropdown.Item>

                                    {row.processoIdentificado && (
                                        <Dropdown.Item onClick={async () => {
                                            setPublicacao(row);
                                            setProcesso({ ...processo, processoId: row.processoId });
                                            navigate("/Processo/Capa");
                                        }}>
                                            <BsFillArrowUpRightCircleFill
                                                title="Acessar o Processo"
                                                onClick={async () => {
                                                    setPublicacao(row);
                                                    setProcesso({ ...processo, processoId: row.processoId });
                                                    navigate("/Processo/Capa");
                                                }}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                size="25px"
                                                className="mx-2 text-orange"
                                            /><label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Acessar Processo
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    {row.status == "Pendente" && (
                                        <Dropdown.Item onClick={() => alterarStatus([row.publicacaoId], 2)}>
                                            <FontAwesomeIcon
                                                title="Lido"
                                                onClick={() => alterarStatus([row.publicacaoId], 2)}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faCheck}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Marcar como lido
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    {row.status == "Pendente" && (
                                        <Dropdown.Item onClick={() => alterarStatus([row.publicacaoId], 3)}>
                                            <FontAwesomeIcon
                                                title="Descartar"
                                                onClick={() => alterarStatus([row.publicacaoId], 3)}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faTrash}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Descartar
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    {!row.processoIdentificado && (
                                        <Dropdown.Item onClick={() => {
                                            setPublicacao(row);
                                            toggleProcessoModal()
                                        }}>
                                            <FontAwesomeIcon
                                                title="Vincular ao Processo"
                                                onClick={() => {
                                                    setPublicacao(row);
                                                    toggleProcessoModal()
                                                }}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faFileImport}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Vincular ao Processo
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    {row.processoIdentificado && (
                                        <Dropdown.Item onClick={() => {
                                            setPublicacao(row);
                                            carregarProcesso(row.processoId);
                                        }}>
                                            <FontAwesomeIcon
                                                title="Criar um compromisso"
                                                onClick={() => {
                                                    setPublicacao(row);
                                                    carregarProcesso(row.processoId);
                                                }}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faSpinner}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Criar um andamento
                                            </label>
                                        </Dropdown.Item>
                                    )}


                                    {row.processoIdentificado && (
                                        <Dropdown.Item onClick={() => {
                                            setPublicacao(row);
                                            toggleCompromissoModal()
                                        }}>
                                            <FontAwesomeIcon
                                                title="Criar um compromisso"
                                                onClick={() => {
                                                    setPublicacao(row);
                                                    toggleCompromissoModal()
                                                }}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faCalendar}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Criar um compromisso
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    {!row.processoIdentificado && (
                                        <Dropdown.Item onClick={() => {
                                            setProcesso({ ...processo, publicacaoId: row.publicacaoId });
                                            navigate("/Processo/Novo");
                                        }}>
                                            <FontAwesomeIcon
                                                title="Criar um Processo"
                                                onClick={() => {
                                                    setProcesso({ ...processo, publicacaoId: row.publicacaoId });
                                                    navigate("/Processo/Novo");
                                                }}
                                                style={{ fontWeight: "normal", cursor: "pointer" }}
                                                icon={faPlusCircle}
                                                size="1x"
                                                className="mx-2 text-orange"
                                            /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                                Criar um Processo
                                            </label>
                                        </Dropdown.Item>
                                    )}

                                    <Dropdown.Item onClick={() => setExibirIA(true)}>
                                        <FontAwesomeIcon
                                            title="IA"
                                            onClick={() => setExibirIA(true)}
                                            style={{ fontWeight: "normal", cursor: "pointer", visibility: user?.id == 13 ? 'initial' : 'hidden' }} // mostrar apenas para usuario 13
                                            icon={faRobot}
                                            size="1x"
                                            className="mx-2 text-orange"
                                        /> <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                                            IA
                                        </label>
                                    </Dropdown.Item>



                                </>}
                        >
                        </DropDown >
                    </>
                )
            },
        },
    ]

    return (<>


        <PublicacaoIAModal
            exibirModal={exibirIA}
            toggleModal={toggleIAModal}
        />

        <PublicacaoDetalhes
            exibirModal={exibirModalPublicacao}
            toggleModal={togglePublicacao}
            publicacao={publicacao}
            titulo="Detalhes da Publicação"
        />

        <PublicacaoRelatorioModal
            exibirModal={exibirConfirmarModeloModal}
            toggleModal={toggleConfimarModeloModal}
            filtro={filtrosPesquisa}
            setFiltro={setFiltrosPesquisa}
        />

        <PublicacaoVincularModal
            exibirModal={exibirProcesso}
            toggleModal={toggleProcessoModal}
            publicacao={publicacao}
            titulo="Vincular ao Processo"
        />

        <CompromissoField
            toggleModal={toggleCompromissoModal}
            processoId={publicacao.processoId}
            modulo={EModulo.AreaDireito}
            editarCompromisso={editarCompromisso}
            exibirModal={exibirCompromisso}
            setAtualizarTabelaCompromisso={setAtualizarTabelaCompromisso}
        />

        <AndamentoField
            editarAndamento={editarAndamento}
            exibirModal={exibirModalAndamento}
            modulo={EModulo.AreaDireito}
            processoId={publicacao.processoId}
            areaDireitoId={publicacao.areaDireitoId}
            toggleModal={toggleAndamento}
            setAtualizarTabela={setAtualizarTabelaCompromisso}
        />

        <ToastContainer />



        <Modal size='lg' centered={true} show={exibirTeorModal} onHide={toggleModalPublicacao}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">Selecionar o escritório  <FontAwesomeIcon
                        title="Selecionar o escritório"
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="1x"
                        className="mx-2 text-orange"
                        icon={faBuilding}
                    /></h5>
                    <div onClick={() => toggleModalPublicacao()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="col-md-12 mb-3">
                            <label htmlFor="form-clienteTipo" className="form-label text-orange fw-bolder">
                                Teor da Publicação:
                            </label>
                            {publicacao.teorPublicacao}
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button onClick={() => toggleModalPublicacao()} type="button" className="btn btn-danger ms-5"> Cancelar </button>
                </div>
            </div>
        </Modal>

        <div className="row mt-2">
            <form onSubmit={(e) => {
                e.preventDefault();
                carregarPublicacoes(filtrosPesquisa);
            }} className="row g-3 mb-3 form-buscar-publicacao">
                <div className="row mt-3">
                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                            Área Direito:
                        </label>
                        <MultiSelect
                            isLoading={carregamentoAreaDireito}
                            disabled={carregamentoAreaDireito}
                            options={areasDireitoOptions}
                            value={areaDireitoSelected}
                            onChange={(event: any) => setAreaDireitoSelected(event)}
                            labelledBy={"Selecione..."}
                            overrideStrings={{
                                selectSomeItems: "Selecione...",
                                allItemsAreSelected: "Todos selecionados",
                                selectAll: "Selecione todos",
                                search: "Pesquise",
                                selectAllFiltered: "Selecione todos (filtrados)"
                            }}

                        />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-numero-processo" className="form-label fw-bolder text-orange">
                            Número do Processo:
                        </label>
                        <input
                            value={filtrosPesquisa.numeroProcesso}
                            onChange={(e) => {
                                setFiltrosPesquisa((oldState: any) => {
                                    return { ...oldState, numeroProcesso: e.target.value };
                                });
                            }}
                            placeholder="Número do Processo"
                            className={"form-control"}
                            id="form-numero-processo"
                        />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                            Parte Contrária:
                        </label>
                        <input
                            value={filtrosPesquisa.parteContraria}
                            onChange={(e) => {
                                setFiltrosPesquisa((oldState) => {
                                    return { ...oldState, parteContraria: e.target.value };
                                });
                            }}
                            placeholder="Nome ou CPF/CNPJ"
                            type="text"
                            className={"form-control"}
                            id="form-parteContraria"
                        />
                    </div>
                </div>

                <div className="row mt-3">

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-dataPublicacao" className="form-label fw-bolder text-orange">Data da Publicação:</label>
                        <DateTimeRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            value={[
                                filtrosPesquisa.dataPublicacaoMaiorIgual || "", // Valor inicial para a data de início
                                filtrosPesquisa.dataPublicacaoMenorIgual || "" // Valor inicial para a data de fim
                            ]}
                            onChange={(datas: Date[]) => {
                                if (!datas || datas.length !== 2 || !datas[0] || !datas[1]) {
                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataPublicacaoMaiorIgual: "",
                                        dataPublicacaoMenorIgual: "",
                                    }));
                                    return;
                                }

                                const [dataInicial, dataFinal] = datas;
                                if (!(dataInicial instanceof Date) || !(dataFinal instanceof Date)) {
                                    console.error("Invalid Date Object");
                                    return;
                                }

                                const dataFinalAdjusted = new Date(dataFinal);
                                dataFinalAdjusted.setHours(0, 0, 0, 0);
                                dataFinalAdjusted.setDate(dataFinalAdjusted.getDate());

                                setFiltrosPesquisa((oldValue: any) => ({
                                    ...oldValue,
                                    dataPublicacaoMaiorIgual: dataInicial ? `${dataInicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                                    dataPublicacaoMenorIgual: dataFinalAdjusted ? `${dataFinalAdjusted.toISOString().split("T")[0]}T23:59:59.9999` : "",
                                }));
                            }}
                        />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-dataDivulgacao" className="form-label fw-bolder text-orange">Data de Divulgação:</label>
                        <DateTimeRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            value={[
                                filtrosPesquisa.dataDivulgacaoMaiorIgual || "", // Valor inicial para a data de início
                                filtrosPesquisa.dataDivulgacaoMenorIgual || "" // Valor inicial para a data de fim
                            ]}
                            onChange={(datas: Date[]) => {
                                if (!datas || datas.length !== 2 || !datas[0] || !datas[1]) {
                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataDivulgacaoMaiorIgual: "",
                                        dataDivulgacaoMenorIgual: "",
                                    }));
                                    return;
                                }

                                const [dataInicial, dataFinal] = datas;
                                if (!(dataInicial instanceof Date) || !(dataFinal instanceof Date)) {
                                    console.error("Invalid Date Object");
                                    return;
                                }

                                const dataFinalAdjusted = new Date(dataFinal);
                                dataFinalAdjusted.setHours(0, 0, 0, 0);
                                dataFinalAdjusted.setDate(dataFinalAdjusted.getDate());

                                setFiltrosPesquisa((oldValue: any) => ({
                                    ...oldValue,
                                    dataDivulgacaoMaiorIgual: dataInicial ? `${dataInicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                                    dataDivulgacaoMenorIgual: dataFinalAdjusted ? `${dataFinalAdjusted.toISOString().split("T")[0]}T23:59:59.9999` : "",
                                }));
                            }}
                        />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-dataCadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
                        <DateTimeRangePicker
                            className="form-control p-0"
                            calendarIcon={null}
                            showLeadingZeros={true}
                            value={[
                                filtrosPesquisa.dataCadastroMaiorIgual || "", // Valor inicial para a data de início
                                filtrosPesquisa.dataCadastroMenorIgual || "" // Valor inicial para a data de fim
                            ]}
                            onChange={(datas: Date[]) => {
                                if (!datas || datas.length !== 2 || !datas[0] || !datas[1]) {
                                    setFiltrosPesquisa((oldValue: any) => ({
                                        ...oldValue,
                                        dataCadastroMaiorIgual: "",
                                        dataCadastroMenorIgual: "",
                                    }));
                                    return;
                                }

                                const [dataInicial, dataFinal] = datas;
                                if (!(dataInicial instanceof Date) || !(dataFinal instanceof Date)) {
                                    console.error("Invalid Date Object");
                                    return;
                                }

                                const dataFinalAdjusted = new Date(dataFinal);
                                dataFinalAdjusted.setHours(0, 0, 0, 0);
                                dataFinalAdjusted.setDate(dataFinalAdjusted.getDate());

                                setFiltrosPesquisa((oldValue: any) => ({
                                    ...oldValue,
                                    dataCadastroMaiorIgual: dataInicial ? `${dataInicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                                    dataCadastroMenorIgual: dataFinalAdjusted ? `${dataFinalAdjusted.toISOString().split("T")[0]}T23:59:59.9999` : "",
                                }));
                            }}
                        />
                    </div>
                </div>

                <div className="row mt-3">

                    <div className="col-12 col-lg-4 col-xl-4">

                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                            Status:
                        </label>
                        <MultiSelect
                            isLoading={false}
                            disabled={false}
                            options={statusOptions}
                            value={statusSelected}
                            onChange={(event: any) => setStatusSelected(event)}
                            labelledBy={"Selecione..."}
                            overrideStrings={{
                                selectSomeItems: "Selecione...",
                                allItemsAreSelected: "Todos selecionados",
                                selectAll: "Selecione todos",
                                search: "Pesquise",
                                selectAllFiltered: "Selecione todos (filtrados)"
                            }}

                        />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                            Advogados Interno:
                        </label>
                        <MultiSelect
                            isLoading={carregamentoAdvogados}
                            disabled={carregamentoAdvogados}
                            options={advogadosOptions}
                            value={advogadosSelected}
                            onChange={(event: any) => setAdvogadosSelected(event)}
                            labelledBy={"Selecione..."}
                            overrideStrings={{
                                selectSomeItems: "Selecione...",
                                allItemsAreSelected: "Todos selecionados",
                                selectAll: "Selecione todos",
                                search: "Pesquise",
                                selectAllFiltered: "Selecione todos (filtrados)"
                            }}
                        />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <label htmlFor="form" className="form-label fw-bolder text-orange">Publicações sem processo identificado:</label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            onChange={(e) => {
                                setFiltrosPesquisa((oldState) => {
                                    return { ...oldState, processoIdentificado: !filtrosPesquisa.processoIdentificado };
                                });
                            }}
                        />
                    </div>

                </div>

                <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-end' }}>



                    {publicacoesMarcadas()?.length > 0 &&
                        <>
                            <button type='button' onClick={() => alterarStatus(publicacoesMarcadas(), 2)} className="btn btn-orange  search-buttom-margin ms-2" title="Buscar">
                                <FontAwesomeIcon color='white' className='' icon={faCheck} />
                            </button>

                            <button type='button' onClick={() => alterarStatus(publicacoesMarcadas(), 3)} className="btn btn-orange  search-buttom-margin ms-2" title="Buscar">
                                <FontAwesomeIcon color='white' className='' icon={faTrash} />
                            </button>
                        </>

                    }

                    <button type="submit" className="btn btn-orange search-buttom-margin ms-2" title="Buscar">
                        <FontAwesomeIcon color='white' className='' icon={faSearch} />
                    </button>

                    <button onClick={(e) => {
                        e.preventDefault();
                        limparFiltros();
                    }} className="btn btn-orange search-buttom-margin ms-2" title="Limpar Filtro">
                        <FontAwesomeIcon color='white' className='' icon={faEraser} />
                    </button>

                    <button onClick={() => toggleConfimarModeloModal()} className="btn btn-orange search-buttom-margin ms-2" disabled={carregandoPublicacoes}>
                        <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                    </button>


                </div>

            </form>
        </div >

        <div className="col-md-12 mb-10">
            <GridPadrao
                onSort={handleSort}
                progressPending={carregandoPublicacoes}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={colunas} tipo='Publicacoes' itens={publicacoes}
                disableSelectAllRows={true}
            />
        </div>

    </>)
}

export default PublicacaoPage;