import { Button, Modal, ModalProps } from "react-bootstrap";
import { Wrapper, Content, Subtitle, Title, Header, Footer } from "./styles";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useSlateStatic } from "slate-react";
import { useTenant } from "../../../hooks/useTenant";
import { useThread } from "../../../hooks/useThread";

export const AddCommentModal = ({ onHide, ...props }: ModalProps) => {
  const [commentText, setCommentText] = useState("");
  const editor = useSlateStatic()
  const { comment } = useThread()
  const { tenant, module } = useTenant()

  const onAddComment = async (event: React.FormEvent) => {
    event.preventDefault();

    await comment.add({
      comment: commentText,
      modulo: module,
      path: JSON.stringify(editor.selection),
      tenant,
    })
    
    setCommentText('')
    onHide?.()
  };

  return (
    <Modal {...props} onHide={onHide} centered>
      <Wrapper>
        <Header>
          <strong>Comentário</strong>
          <button onClick={() => onHide?.()} type="button">
            <MdClose size={20} />
          </button>
        </Header>

        <Content>
          <Title>Adicionar comentário</Title>
          <Subtitle>
            Seu comentário será adicionado e iniciará uma discussão
          </Subtitle>
        </Content>

        <Footer>
          <form onSubmit={onAddComment}>
            <textarea
              onChange={({ target: { value } }) => setCommentText(value)}
              className="form-control"
              placeholder="Escreva seu comentário..."
            />

            <div className="actions">
              <Button onClick={onHide} type="button" variant="text">
                Cancelar
              </Button>
              <Button type="submit" variant="var(--primary-base2)">
                Salvar
              </Button>
            </div>
          </form>
        </Footer>
      </Wrapper>
    </Modal>
  );
};
