import { Droppable } from "react-beautiful-dnd";
import { Ticket } from "../Ticket";
import { Container, Header, CardColumn } from "./styles";
import { LaneProps } from "./props";
import { Spinner } from "react-bootstrap";

export function Lane({ lane, showLoader, selectCard }: LaneProps) {
  return (
    <Droppable droppableId={lane.agrupadorOpcaoNome}>
      {(provided) => (
        <Container ref={provided.innerRef} {...provided.droppableProps}>
          <Header>
            <div className="title">
              <span>{lane.agrupadorOpcaoNome}</span>
              {showLoader && <Spinner size="sm" animation="border" className="text-orange" />}
            </div>
            <small>({lane.data.length})</small>
          </Header>

          <CardColumn>
            {lane.data.map((card, index) => (
              <Ticket
                index={index}
                key={card.consultaId}
                card={card}
                selectCard={selectCard}
              />
            ))}
          </CardColumn>
        </Container>
      )}
    </Droppable>
  );
}
