import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import { TableColumn } from "react-data-table-component";
import { Form, Modal, ProgressBar } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faEye, faPercent, faTimes } from "@fortawesome/free-solid-svg-icons";

import { routePropriedade, tipoPorModulo } from "../../../utils/Utils";
import { ECamposIDEnum, EModulo } from "../../../enum";

import CamposService from "../../../services/CamposService";
import EmpresaService from "../../../services/EmpresaService";
import ValoresService from "../../../services/ValoresService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import IEmpresa from "../../../interfaces/IEmpresa";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IGestaoProcesso from "../../../interfaces/IGestaoProcesso";
import IValoresProcesso from "../../../interfaces/IValoresProcesso";
import ISignatarioPapel from "../../../interfaces/ISignatarioPapel";
import IFormaParticipacao from "../../../interfaces/IFormaParticipacao";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaAdvogadoRequest from "../../../interfaces/Requests/FiltroPesquisaAdvogadoRequest";
import FiltroPesquisaValoresProcessoRequest from "../../../interfaces/Requests/Valores/ValoresProcesso/FiltroPesquisaValoresProcessoRequest";

import GridPadrao from "../GridPadrao";
import Carregando from "../../Carregando";
import CurrencyField from "../CurrencyBRL/CurrencyField";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { notifyDanger, notifySuccess } from "../Toast/ToastFormik";
import Spinner from "../../Spinner";

interface IEmpresaFieldProps {
  formik: any;
  toggleModal: () => void;
  exibirModal: boolean;
  exibirRateio?: boolean;
  setRefreshCapa?(refresh: boolean): void;
}

const EmpresaProvisaoField = ({ toggleModal, formik, exibirModal, exibirRateio, setRefreshCapa }: IEmpresaFieldProps) => {

  const { processo } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [empresa, setEmpresa] = useState<IEmpresa>();
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);

  const [filiais, setFiliais] = useState<IEmpresa[]>([]);
  const [carregandoFiliais, setCarregandoFiliais] = useState(false);

  const [carregandoEmpresas, setCarregandoEmpresas] = useState(false);
  const [formaParticipacao, setFormaParticipacao] = useState<IFormaParticipacao[]>([]);
  const [papelSignatario, setPapelSIgnatario] = useState<ISignatarioPapel[]>([]);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaAdvogadoRequest>({
    filtro: "",
    tipoPesquisa: 1,
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "empresaId",
  });


  const [valorProvisaoPassivo, setValorProvisaoPassivo] = useState<number>(0);
  const [valorProvisaoPassivoAtualizado, setValorProvisaoPassivoAtualizado] = useState<number>(0);
  const [filtrosValores] = useState<FiltroPesquisaValoresProcessoRequest>({
    ProcessoId: processo.processoId,
    Fase: "",
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "processoId",
  });

  const [habilitarRateio, setHabilitarRateio] = useState(false);
  const [percentualTotal, setPercentualTotal] = useState<number>(0);

  const [carregandoGestores, setCarregandoGestores] = useState(false);
  const [gestaoProcesso, setGestaoProcesso] = useState<IGestaoProcesso[]>([]);


  useEffect(() => { iniciarProvisao(); }, [valorProvisaoPassivo, valorProvisaoPassivoAtualizado, formik.values.empresasList]);

  useEffect(() => { if (exibirRateio && exibirModal) carregarValoresProcesso(filtrosValores); }, [exibirRateio, exibirModal]);

  useEffect(() => { if (exibirModal) carregarEmpresas(filtrosPesquisa); }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    if (exibirModal) {

      carregarEmpresas(filtrosPesquisa);

      if (formik.values.modulo == EModulo.Contrato) {
        carregarPapelSignatario();
      }

      if (formik.values.modulo == EModulo.AreaDireito) {
        carregarFormaParticipacao();
        if (exibirRateio) {
          carregarGestaoProcesso();
        }
      }
    }
  }, [exibirModal]);


  const preencherMarcacoes = (empresas: IEmpresa[]) => {

    empresas.forEach((empresa) => {

      const empresaChecked = formik.values.empresasList?.find((e: IEmpresa) => e.numeroDocumento === empresa.numeroDocumento);

      if (empresaChecked) {
        // adicionar os check de seleção
        empresa.checked = true;

        // adicionar o check de principal
        if (empresaChecked && empresaChecked.principal) empresa.principal = true; else empresa.principal = false;

        // adicionar O papel signatario com valor default
        if (formik.values.modulo == EModulo.Contrato) {
          empresa.papelSignatario = empresaChecked?.papelSignatario ? empresaChecked?.papelSignatario : '';
          empresa.papelSignatarioId = empresaChecked?.papelSignatarioId ? empresaChecked?.papelSignatarioId : 0;
        }

        if (formik.values.modulo == EModulo.AreaDireito) {

          // adicionar as formas de participação
          empresa.formaParticipacao = empresaChecked?.formaParticipacao ? empresaChecked?.formaParticipacao : '';
          empresa.formaParticipacaoId = empresaChecked?.formaParticipacaoId ? empresaChecked?.formaParticipacaoId : 0;

          // adicionar a gestao de processo
          empresa.gestaoProcessoNome = empresaChecked?.gestaoProcessoNome ? empresaChecked?.gestaoProcessoNome : '';
          empresa.gestaoProcessoId = empresaChecked?.gestaoProcessoId ? empresaChecked?.gestaoProcessoId : 0;

          if (empresaChecked?.percentual) {
            empresa.percentual = empresaChecked?.percentual;
            empresa.percentualMask = empresaChecked?.percentual ? (empresaChecked.percentual * 100).toString() : '';
            setHabilitarRateio(true);
            setPercentualTotal(formik.values.empresasList.reduce((total: number, object: IEmpresa) => { return total + object.percentual; }, 0));
          } else {
            setHabilitarRateio(false);
          }
        }
      }
    });

    return empresas;

  }

  const marcarPrincipal = (empresaId: number) => {
    if (isValid(empresaId)) {
      formik.setFieldValue("empresasList", [], true);
      empresas.forEach((empresa) => {
        if (empresa.empresaId == empresaId) {
          if (!empresa.principal) {
            empresa.principal = true;
            empresa.checked = true;
            setEmpresas(empresas);
            formik.setFieldValue("empresasList", empresas, true);
            return;
          }
        } else {
          empresa.principal = false;
          setEmpresas(empresas);
          formik.setFieldValue("empresasList", empresas, true);
          return;
        }
      });
    }
  };

  const adicionar = (empresaId: number) => {
    if (isValid(empresaId)) {
      formik.setFieldValue("empresasList", [], true);
      empresas.forEach((empresa) => {
        if (empresa.empresaId == empresaId) {
          if (!empresa.checked) {
            empresa.checked = true;
            setEmpresas(empresas);
            formik.setFieldValue("empresasList", empresas, true);
            return;
          }
          if (empresa.checked) {
            empresa.checked = false;
            empresa.principal = false;
            setEmpresas(empresas);
            formik.setFieldValue("empresasList", empresas, true);
            return;
          }
        }
      });
    }
  };

  const isValid = (empresaId: number): boolean => {
    let retorno = true;
    empresas.forEach((empresa) => {
      if (empresa.empresaId == empresaId) {

        if (formik.values.modulo === EModulo.Contrato && !empresa.papelSignatarioId) {
          notifyDanger('Por gentileza selecionar o papel signatário.');
          empresa.checked = false;
          empresa.principal = false;
          setEmpresas(empresas);
          formik.setFieldValue("empresasList", empresas, true);
          retorno = false;
        }

        if (formik.values.modulo == EModulo.AreaDireito && !empresa.formaParticipacaoId) {
          notifyDanger('Por gentileza selecionar a forma de participação.');
          empresa.checked = false;
          empresa.principal = false;
          setEmpresas(empresas);
          formik.setFieldValue("empresasList", empresas, true);
          retorno = false;
        }

        if (habilitarRateio) {
          if ((formik.values.modulo == EModulo.AreaDireito) && !empresa.gestaoProcessoId) {
            notifyDanger('Por gentileza selecionar o gestor.');
            empresa.checked = false;
            empresa.principal = false;
            setEmpresas(empresas);
            formik.setFieldValue("empresasList", empresas, true);
            retorno = false;
          }
          if ((formik.values.modulo == EModulo.AreaDireito) && !empresa.percentual) {
            notifyDanger('Por gentileza preencher o percentual.');
            empresa.checked = false;
            empresa.principal = false;
            setEmpresas(empresas);
            formik.setFieldValue("empresasList", empresas, true);
            retorno = false;
          }
        }

        if (formik.values.modulo !== EModulo.Contrato && formik.values.modulo !== EModulo.AreaDireito) {
          retorno = true;
        }

      }
    });
    return retorno;
  }

  const carregarFormaParticipacao = async () => {
    try {
      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values.modulo)}Id`] = formik.values.categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.FormaParticipacao,
        formik.values.modulo,
        objeto[`${tipoPorModulo(formik.values.modulo)}Id`],
        0);
      setFormaParticipacao(resultado.data);
    } catch (error: any) {
      setFormaParticipacao([]);
    }
  }

  const carregarPapelSignatario = async () => {
    try {

      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values.modulo)}Id`] = formik.values.categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.PapelSignatario,
        formik.values.modulo,
        objeto[`${tipoPorModulo(formik.values.modulo)}Id`],
        0);

      setPapelSIgnatario(resultado.data);
    } catch (error: any) {
      setPapelSIgnatario([]);
    }
  }

  const carregarGestaoProcesso = async () => {
    try {

      setGestaoProcesso([]);

      setCarregandoGestores(true);

      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values.modulo)}Id`] = formik.values.categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.GestaoProcesso,
        formik.values.modulo,
        objeto[`${tipoPorModulo(formik.values.modulo)}Id`],
        0);

      setGestaoProcesso(resultado.data);

    } catch (error: any) {
      setGestaoProcesso([]);
    } finally {
      setCarregandoGestores(false);
    }
  };

  const carregarValoresProcesso = async (filtro: FiltroPesquisaValoresProcessoRequest): Promise<void> => {
    try {
      let resultado: IRecuperaLista<IValoresProcesso>;

      resultado = await ValoresService.obterValoresProcesso(filtro);

      setValorProvisaoPassivo(resultado.data.filter(e => e)[0]?.valorProvisaoPassivo);

      setValorProvisaoPassivoAtualizado(resultado.data.filter(e => e)[0]?.valorProvisaoPassivoAtualizado);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  const carregarEmpresas = async (filtro: FiltroPesquisaAdvogadoRequest) => {
    try {

      setCarregandoEmpresas(true);

      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setEmpresas(preencherMarcacoes(resultado.data));

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoEmpresas(false);
    }
  };

  const carregarFiliais = async () => {
    try {

      setFiliais([]);

      setCarregandoFiliais(true);

      let resultado = await EmpresaService.obterFiliais(empresa?.empresaId);

      setFiliais(resultado.data);

      setCarregandoFiliais(false);


    } catch (error: any) {
      setFiliais([]);
      setCarregandoFiliais(false);
    }
  };

  const atribuirPapelOuForma = (empresa: IEmpresa, value: number) => {
    if (formik.values.modulo === EModulo.AreaDireito) {
      empresa.formaParticipacaoId = value;
      empresa.formaParticipacao = formaParticipacao?.find(e => e.formaParticipacaoId == value)?.nome || '';
      formik.values.empresasList = formik.values.empresasList.filter((e: IEmpresa) => e.empresaId !== empresa.empresaId);
      formik.values.empresasList.push(empresa);
      formik.setFieldValue("empresasList", formik.values.empresasList, true);
    }

    if (formik.values.modulo === EModulo.Contrato) {
      empresa.papelSignatarioId = value;
      empresa.papelSignatario = papelSignatario?.find(e => e.papelSignatarioId == value)?.nome || '';
      formik.values.empresasList = formik.values.empresasList.filter((e: IEmpresa) => e.empresaId !== empresa.empresaId);
      formik.values.empresasList.push(empresa);
      formik.setFieldValue("empresasList", formik.values.empresasList, true);
    }
  };

  const atribuirGestaoProcesso = (empresa: IEmpresa, value: number) => {
    empresa.gestaoProcessoId = value;
    empresa.gestaoProcessoNome = gestaoProcesso?.find(e => e.gestaoProcessoId == value)?.nome || '';
    formik.values.empresasList = formik.values.empresasList.filter((e: IEmpresa) => e.empresaId !== empresa.empresaId);
    formik.values.empresasList.push(empresa);
    formik.setFieldValue("empresasList", formik.values.empresasList, true);
  };

  const onChangePercentualValue = (index: number, values: any) => {
    let percentualTotal = 0;
    let percentual = parseFloat(values.formattedValue.replace("R$", "").replace(",", "."));

    if ((values.floatValue / 100) > 100) {
      empresas[index].percentual = 0;
      empresas[index].percentualMask = "";
    } else {
      empresas[index].percentual = percentual;
      empresas[index].percentualMask = values.formattedValue;
      empresas.filter((e: any) => e.checked).forEach((empresa) => { percentualTotal += empresa.percentual ? empresa.percentual : 0; });
      setPercentualTotal(percentualTotal);
    }
  }

  const iniciarProvisao = () => {
    if (formik.values.empresasList?.length > 0) {
      formik.values.empresasList.forEach((empresa: IEmpresa) => {
        empresa.provisao = (empresa.percentual * valorProvisaoPassivo) / 100;
        empresa.provisaoAtualizada = (empresa.percentual * valorProvisaoPassivoAtualizado) / 100;
      });
    }

    formik.setFieldValue("empresasList", formik.values.empresasList, true);
  };

  useEffect(() => { if (empresa?.empresaId) carregarFiliais(); }, [empresa]);

  const colunasComPapel: TableColumn<IEmpresa>[] = [
    {
      name: "Filiais?",
      cell: (row: IEmpresa) => (
        <div>
          {row.expandir ?

            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faBackward}
              onClick={() => onRowClicked(row, false)}
            />
            :
            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faEye}
              onClick={() => onRowClicked(row, true)}
            />
          }
        </div>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      cell: (row: IEmpresa) => {
        return (
          row.expandir ?
            carregandoFiliais ? <Carregando /> :
              <div>
                <br></br>
                <p>{row?.nomeFantasia}</p>
                <hr></hr>
                {filiais.map((filial) => (
                  <div key={filial?.empresaId} style={{ display: 'block' }}>
                    <p>{filial.empresaId}-{filial?.nomeFantasia}</p>
                  </div>
                ))}
              </div> :
            <div>
              <p>{row?.nomeFantasia}</p>
            </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Forma de Participação *",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <>{(formik.values.modulo === 1) &&
            <div>
              <select
                name="formaParticipacaoId"
                onChange={(e) => atribuirPapelOuForma(row, parseInt(e.target.value, 10))}
                className={"form-control"}
                id="form-formaParticipacaoId"
                value={row?.formaParticipacaoId}
              >
                <option key={0} value={0}>Selecione</option>
                {formaParticipacao.map((map) => {
                  return (
                    <option key={map?.formaParticipacaoId} value={map?.formaParticipacaoId}>
                      {map?.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          }
            {(formik.values.modulo === EModulo.Contrato) &&
              <div>
                <select
                  name="papelSignatarioId"
                  onChange={(e) => atribuirPapelOuForma(row, parseInt(e.target.value, 10))}
                  className={"form-control"}
                  id="form-papelSignatarioId"
                  value={row.papelSignatarioId}
                >
                  <option key={0} value={0}>Selecione</option>
                  {papelSignatario.map((map) => {
                    return (
                      <option key={map?.papelSignatarioId} value={map?.papelSignatarioId}>
                        {map?.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
            }
          </>
        );
      },
    },
    {
      name: "Principal?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => adicionar(row.empresaId)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const colunasSemPapel: TableColumn<IEmpresa>[] = [
    {
      name: "Id",
      sortField: "empresaId",
      selector: (row: IEmpresa) => row.empresaId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IEmpresa) => row.nomeFantasia,
      sortable: true,
      wrap: true,
    },
    {
      name: "Principal?",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => adicionar(row.empresaId)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const colunasRateioComPapel: TableColumn<IEmpresa>[] = [
    {
      name: "Filiais?",
      cell: (row: IEmpresa) => (
        <div>
          {row.expandir ?

            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faBackward}
              onClick={() => onRowClicked(row, false)}
            />
            :
            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faEye}
              onClick={() => onRowClicked(row, true)}
            />
          }
        </div>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      cell: (row: IEmpresa) => {
        return (
          row.expandir ?
            carregandoFiliais ?
              <Spinner /> :
              <div>
                <br></br>
                <p>{row?.nomeFantasia}</p>
                <hr></hr>
                {filiais.map((filial) => (
                  <div key={filial?.empresaId} style={{ display: 'block' }}>
                    <p>{filial.empresaId}-{filial?.nomeFantasia}</p>
                  </div>
                ))}
              </div> :
            <div>
              <p>{row?.nomeFantasia}</p>
            </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Forma de Participação *",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <>{(formik.values.modulo === 1) &&
            <div>
              <select
                name="formaParticipacaoId"
                onChange={(e) => atribuirPapelOuForma(row, parseInt(e.target.value, 10))}
                className={"form-control"}
                id="form-formaParticipacaoId"
                value={row?.formaParticipacaoId}
              >
                <option key={0} value={0}>Selecione</option>
                {formaParticipacao.map((map) => {
                  return (
                    <option key={map?.formaParticipacaoId} value={map?.formaParticipacaoId}>
                      {map?.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          }
            {(formik.values.modulo === EModulo.Contrato) &&
              <div>
                <select
                  name="papelSignatarioId"
                  onChange={(e) => atribuirPapelOuForma(row, parseInt(e.target.value, 10))}
                  className={"form-control"}
                  id="form-papelSignatarioId"
                  value={row.papelSignatarioId}
                >
                  <option key={0} value={0}>Selecione</option>
                  {papelSignatario.map((map) => {
                    return (
                      <option key={map?.papelSignatarioId} value={map?.papelSignatarioId}>
                        {map?.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
            }
          </>
        );
      },
    },
    {
      name: "Percentual",
      cell: (row: IEmpresa, index: number) => {
        return (
          <div>
            <CurrencyField
              onValueChange={(values) => onChangePercentualValue(index, values)}
              floatValue={row.percentual} />
          </div>
        );
      },
      ignoreRowClick: true,
    },

    {
      name: 'Gestão',
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (carregandoGestores ? <Spinner /> :
          <select
            name="Gestor"
            onChange={(e) => atribuirGestaoProcesso(row, parseInt(e.target.value, 10))}
            className={"form-control"}
            id="form-gestaoProcessoId"
            value={row?.gestaoProcessoId}
          >
            <option key={0} value={0}>Selecione</option>
            {gestaoProcesso.map((map) => {
              return (
                <option key={map?.gestaoProcessoId} value={map?.gestaoProcessoId}>
                  {map?.nome}
                </option>
              );
            })}
          </select>);
      },
    },
    {
      name: "Principal?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.checked}
              onClick={() => adicionar(row.empresaId)}
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IEmpresa>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const isValidEmpresas = () => {

    let retorno = false;

    if (empresas.filter(e => e.checked && e.principal)?.length == 0) retorno = true;

    if (empresas?.length > 0) {

      if (formik.values.modulo == EModulo.AreaDireito) {
        if (empresas.filter(e => e.checked && !e.formaParticipacaoId)?.length > 0) retorno = true;

        if (habilitarRateio) {
          if (empresas.filter(e => e.checked && !e.gestaoProcessoId)?.length > 0) retorno = true;
          if (percentualTotal != 100) retorno = true;
        }
      }

      if (formik.values.modulo == EModulo.Contrato) {
        if (empresas.filter(e => e.checked && !e.papelSignatarioId)?.length > 0) retorno = true;
      }
    }

    return retorno;

  }

  const onRowClicked = (row: IEmpresa, expandir: boolean) => {

    empresas.forEach(empresa => {
      if (row.empresaId == empresa.empresaId) {
        empresa.expandir = expandir;
      } else {
        empresa.expandir = false;
      }
    });

    setEmpresas(empresas);

    setEmpresa(row);

  }

  const cancelar = () => {
    formik.setFieldValue("empresasList", [], true);

    empresas.forEach((empresa) => {
      empresa.percentual = 0;
      empresa.percentualMask = "";
      empresa.checked = false;
    });

    setPercentualTotal(0);

    toggleModal();
  }



  const confirmar = async () => {

    // 1. Caso a lista estiver vazia
    if (formik.values.empresasList.length == 0) {
      toggleModal();
    }

    // 2. Caso a somatoria estiver diferente de 100%
    if (habilitarRateio && percentualTotal != 100) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `Percentual`,
        text: "A soma do percentual deve ser igual a 100%",
        showConfirmButton: true,
      });
      return;
    } else {

      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar empresas",
          text: `Você realmente deseja editar as empresas?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await CamposService.alterarPorEmpresaList(formik.values.empresasList, formik.values.principalId, routePropriedade(formik.values.modulo), exibirRateio); // OK

          notifySuccess(`Empresas salvas com sucesso!`);

          Swal.close();

          setRefreshCapa &&
            setRefreshCapa(true);
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar as empresas`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      } finally {
        toggleModal();
      }
    }

  }

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <ToastContainer />
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Empresas {habilitarRateio && <>
            <FontAwesomeIcon className="mx-2 text-orange" icon={faPercent} /> <ProgressBar variant="var(--primary-base2)" now={percentualTotal} />
            <a style={{ fontSize: "15px" }}>Total: {percentualTotal <= 100 && (percentualTotal).toFixed(2)}</a></>}
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">

          {exibirRateio &&
            <div className="row mb-2">
              <div className="col-md-auto mb-10">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Deseja habilitar rateio?  <FormCheckInput
                  name="terms"
                  checked={habilitarRateio}
                  onClick={() => {
                    setHabilitarRateio(!habilitarRateio);
                    formik.setFieldValue("empresasList", [], true);
                  }}
                  style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                />
                </label>
              </div>
            </div>
          }

          <div className="row mb-2">
            <div className="col-md-auto mb-10">
              <input
                value={filtrosPesquisa.filtro}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, filtro: e.target.value };
                  });
                }}
                placeholder="Pesquise"
                type="text"
                className={clsx("form-control")}
              />
            </div>

            <div className="col-md-auto mb-3">
              <button onClick={() => carregarEmpresas(filtrosPesquisa)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>
            <div className="col-md-auto">{<> Buscar por: </>}</div>
            <div className="col-md-auto">
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 1 })}
                  label="Nome"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 2 })}
                  label="Documento"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 3 })}
                  label="Código"
                  name="group1"
                  type="radio"
                />
              </div>
            </div>
          </div>

          <div className="col-md-auto mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoEmpresas}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={(formik.values.modulo == EModulo.AreaDireito || formik.values.modulo == EModulo.Contrato) ? (habilitarRateio ? colunasRateioComPapel : colunasComPapel) : colunasSemPapel}
              tipo="Empresas"
              itens={empresas}
            />
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            onClick={() => confirmar()}
            disabled={isValidEmpresas()}
            type="button"
            className="btn btn-orange ms-5"
          >
            Adicionar
          </button>

          <button
            onClick={() => cancelar()}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EmpresaProvisaoField;
