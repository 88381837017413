import { HistoryEditor } from "slate-history";
import { ActionKey, KeyboardKeys, KeydownParams } from "./types";
import { CustomEditor } from "../../../../../utils/types/slate";
import { markHandler } from "../../Toolbar/controls/mark/handler";
import { MarkOption } from "../../Toolbar/controls/mark";

function setupMark(editor: CustomEditor, mark: MarkOption) {
  markHandler(editor, mark);
}

export function onKeyDown({ editor, event }: KeydownParams) {
  if (!editor.selection) {
    return;
  }

  const ctrlPressed = event.ctrlKey || event.metaKey;

  if (ctrlPressed) {
    const actions: ActionKey = {
      [KeyboardKeys.BOLD]: () => setupMark(editor, "bold"),
      [KeyboardKeys.UNDERLINE]: () => setupMark(editor, "underline"),
      [KeyboardKeys.ITALIC]: () => setupMark(editor, "italic"),
      [KeyboardKeys.STRIKETHROUGH]: () => setupMark(editor, "strikethrough"),
      [KeyboardKeys.UNDO]: () => HistoryEditor.undo(editor),
      [KeyboardKeys.REDO]: () => HistoryEditor.redo(editor),
      [KeyboardKeys.ENTER]: () => null,
    };

    const pressedKey = event.key as keyof ActionKey;

    if (pressedKey in actions) {
      event.preventDefault();
      actions[pressedKey]();
    }

    return
  }
}
