import { Element, Node } from "slate"
import { CustomEditor, CustomElement } from "../../../../../../utils/types/slate"

export const getNodeBySelection = (editor: CustomEditor) => {
  const { selection } = editor
  if (!selection) return

  const descendants = Array.from(Node.descendants(editor))

  for (const descendant of descendants) {
    const [element, nodePath] = descendant
    if (Element.isElement(element)) {
      const selectionPath = selection.anchor.path.toString()
      const elementPath = nodePath.concat(0).toString()
      if (selectionPath === elementPath) {
        return element
      }
    }
  }

  return {} as CustomElement
}