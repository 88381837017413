import styled, { css } from "styled-components";

export const ImageComponent = styled.img<{ selected: boolean }>`
  display: block;
  box-shadow: ${(props) => (props.selected ? "0 0 0 3px #B4D5FF" : "none")};
  place-items: center;
  height: ${(props) =>
    props.height && Number(props.height) > 0 ? props.height : "20em"};
`;

export const ActionsContainer = styled.div<{ alignment?: string }>`
  position: absolute;
  bottom: -60px;
  width: 100%;

  display: flex;
  justify-content: ${props => props.alignment ?? "start"};

  > div {
    background: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    padding: 10px;

    display: flex;
    gap: 20px;
    border: 1px solid #ddd;
  }
`;

export const VerticalDivider = styled.div`
  height: 30px;
  width: 2px;
  background: #ddd;
`;

export const ImageButtonAction = styled.button`
  background: #fff;
  border: none;
  max-width: "100%";
  height: "20em";
  display: "flex";
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  width: 30px;
  height: 30px;

  transition: 0.2s;

  > svg {
    font-size: 20px;
  }

  &:hover {
    background: #f2f2fa;
  }

  &.edit-action {
    right: 40px;
  }
`;

const positionedImageContainerCss = css`
  position: relative;
`;

const makeFlexImageContainerCss = (alignment?: string) => {
  return css`
    display: flex;
    justify-content: ${alignment ?? "start"};
  `;
};

export const ImageContainer = styled.div<{
  isPositioned?: boolean;
  alignment?: string;
}>`
  /* ${props => props.isPositioned ? positionedImageContainerCss : makeFlexImageContainerCss(props.alignment)} */
  display: flex;
  justify-content: ${props => props.alignment ?? "start"};
`;

type ImgPosition = {
  left: string;
  top: string;
};

const makePositionedCss = (position: ImgPosition) => {
  return css`
    position: absolute;
    left: ${position.left};
    top: ${position.top};
  `;
};

export const ImagePosition = styled.div<{ position?: ImgPosition }>`
  position: relative;
`;
