import { faBackward, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from "clsx"
import * as Formik from 'formik'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from "react"
import CurrencyInput from 'react-currency-masked-input'
import { TableColumn } from 'react-data-table-component'
import Select from 'react-select'
import MaskedInput from "react-text-mask"
import { OrangeContext } from '../../contexts/OrangeProvider'
import { ETipoCampoEnum } from '../../enum'
import { OrangeInterfaceContext } from '../../interfaces/Contexts/OrangeInterfaceContext'
import { IConfiguracaoTela } from '../../interfaces/IConfiguracaoTela'
import { CampoListaMulti } from '../../pages/SocietarioPage/componentes/CampoListaMulti'
import useIsMounted from '../../pages/SocietarioPage/hooks/useIsMounted'
import { getMaskFromFieldName } from '../../pages/SocietarioPage/utils'
import AtividadeEmpresaService from '../../services/AtividadeEmpresaService'
import LimitacaoTabelaService from '../../services/LimitacaoTabelaService'
import { MASCARA_CNPJ, MASCARA_CPF } from '../../utils/Constants'
import GridPadrao from '../Comum/GridPadrao'
import { getEnumByName, camelizar } from '../../pages/ConfiguracaoTelaPage/Empresa/utils'
import { Modelo } from '../../pages/ConfiguracaoTelaPage/Empresa/utils/CadastroCampo'
import CadastroCampoPessoa from '../../pages/ConfiguracaoTelaPage/Empresa/utils/CadastroCampoPessoa'

interface ICamposProps extends React.PropsWithRef<any> {
	modelo: Modelo
	handleSetValue: (value: any) => void
	handleUpdateValue: (value: any) => void
	campo: Formik.FieldInputProps<any>
	touched: boolean | Formik.FormikTouched<any> | Formik.FormikTouched<any>[] | undefined
	error: string | Formik.FormikErrors<any> | string[] | Formik.FormikErrors<any>[] | undefined
	tela: IConfiguracaoTela
	isCapa?: boolean
	disabled?: boolean
}

const CamposCapaEmpresaDinamicos = (props: ICamposProps) => {
	const { empresa, } = useContext<OrangeInterfaceContext>(OrangeContext);
	const { handleSetValue, handleUpdateValue } = props
	const [loading, setLoading] = useState<boolean>()
	const [editando, setEditando] = useState<boolean>(false)
	const [opcoes, setOpcoes] = useState<any[]>([])
	const [itemSelected, setItemSelected] = useState<any>({})

	const isMounted = useIsMounted()
	const ref = useRef<any>()

	const getOpcoes = async () => {
		let options: any[] = []

		if (props.modelo.tipo !== ETipoCampoEnum.ListaMulti as ETipoCampoEnum &&
			props.modelo.tipo !== ETipoCampoEnum.Lista as ETipoCampoEnum &&
			props.modelo.tipo !== ETipoCampoEnum.EnumLista as ETipoCampoEnum
		) {
			setLoading(false)
			setOpcoes(options)
		} else if (props.modelo.tipo === ETipoCampoEnum.EnumLista as ETipoCampoEnum) {
			if (props.modelo.controller && props.modelo.controller.length > 0 && props.modelo.controller !== 'NULL') {
				options = getEnumByName(props.modelo.controller)
			} else {
				options = getEnumByName(props.modelo.campoNome)
			}
		} else {
			let resultado: any[] = []
			let chave = ''

			if (props.modelo.controller && props.modelo.controller.length) {
				try {
					chave = `${camelizar(props.modelo.controller as string)}Id`
					try {
						if (props.modelo.controller === 'AtividadeEmpresa' || props.modelo.controller === 'AtividadeEmpresaPrincipal') {
							resultado = (await AtividadeEmpresaService.obterAtividadeEmpresas({
								offset: 0,
								limit: 10000,
								sort: "atividadeEmpresaId",
								status: 0,
								nome: "",
								codigo: "",
								totalItems: 0
							})).data

							chave = "atividadeEmpresaId";

						} else {
							resultado = (await LimitacaoTabelaService.obterListaLimitadaEmpresa(props.tela, props.modelo.controller, true)).data
						}
					} catch (e) {
						resultado = (await LimitacaoTabelaService.obterListaCompleta(props.modelo.controller)).data
					}
				} catch (error: any) {
					console.log(error);

				}
			}

			options = resultado.map((item: any) => {
				return {
					label: item.nome,
					value: item[chave]
				}
			})
		}

		if (isMounted()) {
			setLoading(false)
			setOpcoes(options)
		}
	}

	const getClassName = (extra = ""): string => {
		return clsx(
			`${props.className || ''} ${extra}`,
			{
				'is-invalid': props.touched && props.error,
			}
		)
	}

	const handleSelectChange = (valor: any, meta: any) => {
		let selecionado = {}
		const campoNome = meta.name

		if (valor === undefined || valor === null || valor.length === 0) {
			handleSetValue({ name: meta.name, value: '' })
		}
		else if (props.modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || props.modelo.multiplo) {
			selecionado = valor.map((item: any, index: number) => {
				return {
					[camelizar(campoNome) + 'Id']: item.value,
					ordem: index
				}
			})
			handleSetValue({ name: meta.name, value: selecionado })
		} else {
			handleSetValue({ name: meta.name, value: valor.value })
		}
	}

	const getCampoTipo = () => {

		switch (props.modelo.tipo) {

			case ETipoCampoEnum.ListaMulti as ETipoCampoEnum:
				return (
					<CampoListaMulti
						tela={props.tela}
						setValues={handleSetValue}
					/>
				)

			case ETipoCampoEnum.Lista as ETipoCampoEnum:
			case ETipoCampoEnum.EnumLista as ETipoCampoEnum:
				return (
					<Select
						ref={ref}
						value={opcoes.find(x => x.value == props.campo.value)}
						name={props.campo.name}
						placeholder={props.modelo.label}
						isClearable={true}
						backspaceRemovesValue={true}
						onBlur={props.campo.onBlur}
						id={props.id}
						onChange={handleSelectChange}
						isOptionSelected={(option: any, value: any) => {
							if (!props.campo.value) return false
							if (props.modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || props.modelo.multiplo) {
								return props.campo.value.includes(option.value)
							}
							return option.value === props.campo.value
						}}
						isLoading={loading}
						loadingMessage={() => 'Carregando...'}
						isMulti={props.modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || props.modelo.multiplo}
						className={getClassName()}
						options={opcoes} />
				)
			case ETipoCampoEnum.Pessoa as ETipoCampoEnum:
				return (
					<>
						<CadastroCampoPessoa
							modelo={props.modelo}
							campo={props.campo}
							touched={props.touched}
							onBlur={props.campo.onBlur}
							error={props.error}
							pessoasInicial={props.campo.value}
							setValue={(valor) => {
								if (props.modelo.camposRetornar && props.modelo.camposRetornar.length > 0) {
									if (props.modelo.multiplo) {
										handleSetValue(
											valor.map((v: any, i: number) => {
												let r: any = {}
												props.modelo.camposRetornar?.forEach((c: string) => {
													r[c] = v[c]
												})
												return r
											})
										)
									} else {
										let r: any = {}
										props.modelo.camposRetornar?.forEach((c: string) => {
											r[c] = valor[c]
										})
										handleSetValue(r)
									}
								}
								else if (props.modelo.camposExtras && props.modelo.camposExtras.length > 0) {
									handleSetValue(valor)
								} else {
									if (props.modelo.multiplo) {
										handleSetValue(
											valor.map((v: any, i: number) => {
												return {
													[camelizar(props.modelo.campoNome) + 'Id']: v.pessoaId,
													ordem: i
												}
											})
										)
									} else {
										handleSetValue(valor.pessoaId)
									}
								}
							}}
							innerRef={ref}
							tela={props.tela}
						/>
					</>
				)
			case ETipoCampoEnum.Documento as ETipoCampoEnum:
				return <MaskedInput
					className={getClassName('form-control')}
					name={props.campo.name}
					onBlur={props.campo.onBlur}
					value={props.campo.value || ''}
					onChange={props.campo.onChange}
					placeholderChar={' '}
					mask={(value) => (value.replace(/[^0-9]/g, '').length > 11) ? MASCARA_CNPJ : [...MASCARA_CPF, /\d/]}
				/>
		
			case ETipoCampoEnum.AreaTexto as ETipoCampoEnum:
				return <textarea
					ref={ref}
					{...props.campo}
					onBlur={props.campo.onBlur}
					className={getClassName('form-control')}
				></textarea>

			case ETipoCampoEnum.Percentual as ETipoCampoEnum:
				return <CurrencyInput
					className={getClassName('form-control')}
					name={props.campo.name}
					onBlur={props.campo.onBlur}
					value={(props.campo.value || 0.0)?.toFixed('2')}
					onChange={
						(event: Event, value: string) => {
							handleSetValue(parseFloat(value.replace(',', '.')))
						}
					}
					separator=','
				/>

			case ETipoCampoEnum.Valor as ETipoCampoEnum:
				return <CurrencyInput
					className={getClassName('form-control')}
					opre={'RS'}
					name={props.campo.name}
					onBlur={props.campo.onBlur}
					value={(props.campo.value || 0.0)?.toFixed('2')}
					onChange={
						(event: Event, value: string) => {
							handleSetValue(parseFloat(value.replace(',', '.')))
						}
					}
					separator=','
				/>

			case ETipoCampoEnum.Data as ETipoCampoEnum:
				return <input
					ref={ref}
					{...props.campo}
					value={props.campo.value || new Date()}
					onBlur={props.campo.onBlur}
					className={getClassName('form-control')}
					type="date"
				/>

			case ETipoCampoEnum.Inteiro as ETipoCampoEnum:
				return <input
					ref={ref}
					{...props.campo}
					onBlur={props.campo.onBlur}
					className={getClassName('form-control')}
					type="number"
				/>

			case ETipoCampoEnum.Texto as ETipoCampoEnum: {
				const typedMask = getMaskFromFieldName(props.campo.name);
				if (typedMask) {
					return <MaskedInput className={getClassName('form-control')} {...props.campo} mask={typedMask} />
				} else {
					return <input type="text" {...props.campo} className={getClassName('form-control')} />
				}
			}

			default:
				return <input type="abc" {...props.campo} className={getClassName('form-control')} />
		}
	}

	useEffect(() => {
		getOpcoes()
	}, [])

	useEffect(() => {
		if (!editando) {
			setItemSelected(() => props.campo)
		}
	}, [editando]);

	const colunas: TableColumn<any>[] = [
		{
			name: "Nome",
			sortField: "nome",
			selector: (row) => row.atividadeEmpresaNome,
			sortable: true,
			wrap: true,
		}
	]

	return (
		<>
			<label className={`form-label fw-bolder text-orange`} >
				{props.modelo.label}:
				<FontAwesomeIcon
					onClick={() => {
						setEditando(() => !editando)
						if (editando) {
							handleUpdateValue(props.modelo)
						}
					}} color={"var(--primary-base2)"} className="mx-3" style={{ cursor: "pointer" }} icon={editando ? faBackward : faEdit} />
			</label>

			<br />

			<strong>
				{!editando ?
					props.modelo.tipo === 'Data' ? !['Invalid date', ''].includes(props.modelo.valor) ? moment(props.modelo.valor).format('DD/MM/YYYY') : '' :
						props.modelo.tipo === 'Valor' ? `R$ ${props.modelo.valor}` :
							props.modelo.valor : ''}
			</strong>

			{!editando && (props.modelo.tipo === ETipoCampoEnum.ListaMulti) && (

				<GridPadrao
					colunas={colunas}
					itens={empresa.empresasAtividadesEmpresa}
					tipo={'Atividades Empresas Secundária'}
				/>
			)

			}

			{editando && (
				getCampoTipo()
			)}

			{props.touched && props.error &&
				<div className="fv-plugins-message-container">
					<div className="fv-help-block">
						<span role="alert" className="text-danger">{props.error}</span>
					</div>
				</div>
			}
		</>
	)
}

export default CamposCapaEmpresaDinamicos