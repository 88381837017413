import { useEffect } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { faCog, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { currencyNumber } from "../../utils/Utils";
import ClienteService from "../../services/ClienteService";

import ICliente from "../../interfaces/ICliente";
import IConfiguracaoesSeguranca from "../../interfaces/Requests/Cliente/IConfiguracaoesSeguranca";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

interface IClienteConfigSegurancaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  cliente: ICliente;
  titulo: string;
}

const ClienteConfigSegurancaModal = ({ toggleModal, exibirModal, cliente, titulo }: IClienteConfigSegurancaModalProps) => {

  const ClienteValidacoes = Yup.object().shape({
    quantidadeMinimaCaracteres: Yup.number().max(999, "Informe a quantidade de caracteres").required("Informação inválida"),
    quantidadeMinimaLetraMaiuscula: Yup.number().max(999, "Informe a quantidade de letras maiuculas").required("Informação inválida"),
    quantidadeMinimaLetraMinuscula: Yup.number().max(999, "Informe a quantidade de letras minusculas").required("Informação inválida"),
    quantidadeMinimaNumero: Yup.number().max(999, "Mínima de Número").required("Informação inválida"),
    quantidadeMinimaCaracteresEspeciais: Yup.number().max(999, "Mínima de caracteres especiais").required("Informação inválida"),
  });

  const valoresIniciais: IConfiguracaoesSeguranca = {
    clienteId: cliente.clienteId,
    configuracoesSegurancaId: 0,
    quantidadeMinimaCaracteres: 0,
    quantidadeMinimaLetraMaiuscula: 0,
    quantidadeMinimaLetraMinuscula: 0,
    quantidadeMinimaNumero: 0,
    quantidadeMinimaCaracteresEspeciais: 0,
    quantidadeMinutosExigirNovoToken: null,
    desabilitarExigirToken: false
  };

  const formik = useFormik({
    initialValues: valoresIniciais,
    validationSchema: ClienteValidacoes,
    onSubmit: async (values, { setSubmitting }) => {
      try {

        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Configuração",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          await ClienteService.salvarConfiguracapSeguranca({ ...values, clienteId: cliente.clienteId });
        }

        setSubmitting(false);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Configuração de segurança salva com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        toggleModal(true);

      } catch (error: any) {
        let mensagemErro = "Ocorreu um erro inesperado";

        if (error?.response?.data?.Message) {
          mensagemErro = error.response.data.Message;
        }

        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar o Cliente`,
          text: mensagemErro,
          showConfirmButton: true,
        });
      }
    },
  });

  useEffect(() => {
    if (cliente && cliente.clienteId) {
      carregarConfigSeguranca(cliente.clienteId);
    }
  }, [exibirModal, cliente]);

  async function carregarConfigSeguranca(clienteId: number) {
    try {

      let resultado: any;

      resultado = await ClienteService.obterConfiguracapSeguranca(clienteId);

      if (resultado.data.length > 0) formik.setValues(resultado.data[0]); else formik.setValues(valoresIniciais);

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  return (
    <>
      <Modal size="xl" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}  <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faCog}
            /></h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">

            <form>

              <div className="row mt-1">
                <div className="col-md-4 mt-3">
                  <label htmlFor="form-quantidadeMinimaCaracteres" className="form-label fw-bolder text-orange">
                    Mínima de caracteres:
                  </label>
                  <NumberFormat
                    format={currencyNumber}
                    {...formik.getFieldProps('quantidadeMinimaCaracteres')}
                    placeholder={"0"}
                    maxLength={3}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.quantidadeMinimaCaracteres && formik.errors.quantidadeMinimaCaracteres,
                      },
                      {
                        "is-valid": formik.touched.quantidadeMinimaCaracteres && !formik.errors.quantidadeMinimaCaracteres,
                      }
                    )}
                    value={formik.values.quantidadeMinimaCaracteres}
                    thousandSeparator={true}
                    onValueChange={(values) => formik.setFieldValue("quantidadeMinimaCaracteres", values.value, true)}

                  />
                  {formik.touched.quantidadeMinimaCaracteres && formik.errors.quantidadeMinimaCaracteres && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.quantidadeMinimaCaracteres}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label htmlFor="form-quantidadeMinimaLetraMaiuscula" className="form-label fw-bolder text-orange">
                    Mínima de letras maiúsculas:
                  </label>
                  <NumberFormat
                    format={currencyNumber}
                    {...formik.getFieldProps('quantidadeMinimaLetraMaiuscula')}
                    placeholder={"0"}
                    maxLength={3}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.quantidadeMinimaLetraMaiuscula && formik.errors.quantidadeMinimaLetraMaiuscula,
                      },
                      {
                        "is-valid": formik.touched.quantidadeMinimaLetraMaiuscula && !formik.errors.quantidadeMinimaLetraMaiuscula,
                      }
                    )}
                    value={formik.values.quantidadeMinimaLetraMaiuscula}
                    thousandSeparator={true}
                    onValueChange={(values) => formik.setFieldValue("quantidadeMinimaLetraMaiuscula", values.value, true)}

                  />
                  {formik.touched.quantidadeMinimaLetraMaiuscula && formik.errors.quantidadeMinimaLetraMaiuscula && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.quantidadeMinimaLetraMaiuscula}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label htmlFor="form-quantidadeMinimaLetraMinuscula" className="form-label fw-bolder text-orange">
                    Mínima de letras minúsculas:
                  </label>
                  <NumberFormat
                    format={currencyNumber}
                    {...formik.getFieldProps('quantidadeMinimaLetraMinuscula')}
                    placeholder={"0"}
                    maxLength={3}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.quantidadeMinimaLetraMinuscula && formik.errors.quantidadeMinimaLetraMinuscula,
                      },
                      {
                        "is-valid": formik.touched.quantidadeMinimaLetraMinuscula && !formik.errors.quantidadeMinimaLetraMinuscula,
                      }
                    )}
                    value={formik.values.quantidadeMinimaLetraMinuscula}
                    thousandSeparator={true}
                    onValueChange={(values) => formik.setFieldValue("quantidadeMinimaLetraMinuscula", values.value, true)}

                  />
                  {formik.touched.quantidadeMinimaLetraMinuscula && formik.errors.quantidadeMinimaLetraMinuscula && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.quantidadeMinimaLetraMinuscula}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-4 mt-3">
                  <label htmlFor="form-quantidadeMinimaNumero" className="form-label fw-bolder text-orange">
                    Minima de números:
                  </label>
                  <NumberFormat
                    format={currencyNumber}
                    {...formik.getFieldProps('quantidadeMinimaNumero')}
                    placeholder={"0"}
                    maxLength={3}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.quantidadeMinimaNumero && formik.errors.quantidadeMinimaNumero,
                      },
                      {
                        "is-valid": formik.touched.quantidadeMinimaNumero && !formik.errors.quantidadeMinimaNumero,
                      }
                    )}
                    value={formik.values.quantidadeMinimaNumero}
                    thousandSeparator={true}
                    onValueChange={(values) => formik.setFieldValue("quantidadeMinimaNumero", values.value, true)}

                  />
                  {formik.touched.quantidadeMinimaNumero && formik.errors.quantidadeMinimaNumero && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.quantidadeMinimaNumero}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label htmlFor="form-quantidadeMinimaCaracteresEspeciais" className="form-label fw-bolder text-orange">
                    Mínima de caracteres especiais:
                  </label>
                  <NumberFormat
                    format={currencyNumber}
                    {...formik.getFieldProps('quantidadeMinimaCaracteresEspeciais')}
                    placeholder={"0"}
                    maxLength={3}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.quantidadeMinimaCaracteresEspeciais && formik.errors.quantidadeMinimaCaracteresEspeciais,
                      },
                      {
                        "is-valid": formik.touched.quantidadeMinimaCaracteresEspeciais && !formik.errors.quantidadeMinimaCaracteresEspeciais,
                      }
                    )}
                    value={formik.values.quantidadeMinimaCaracteresEspeciais}
                    thousandSeparator={true}
                    onValueChange={(values) => formik.setFieldValue("quantidadeMinimaCaracteresEspeciais", values.value, true)}

                  />
                  {formik.touched.quantidadeMinimaCaracteresEspeciais && formik.errors.quantidadeMinimaCaracteresEspeciais && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.quantidadeMinimaCaracteresEspeciais}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label htmlFor="form-quantidadeMinutosExigirNovoToken" className="form-label fw-bolder text-orange">
                    Desabilitar exigir token a cada novo login?{" "}
                    <FormCheckInput
                      name="terms"
                      checked={formik.values.desabilitarExigirToken}
                      onClick={() => formik.setValues({ ...formik.values, desabilitarExigirToken: !formik.values.desabilitarExigirToken, quantidadeMinutosExigirNovoToken: null })}
                      style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                    />
                  </label>
                  {formik.values.desabilitarExigirToken &&
                    <NumberFormat
                      format={currencyNumber}
                      {...formik.getFieldProps('quantidadeMinutosExigirNovoToken')}
                      placeholder={"Minutos para exigir"}
                      maxLength={10}
                      className={"form-control"}
                      value={formik.values.quantidadeMinutosExigirNovoToken}
                      thousandSeparator={true}
                      onValueChange={(values) => formik.setFieldValue("quantidadeMinutosExigirNovoToken", values.value, true)}
                    />
                  }
                  {formik.values.desabilitarExigirToken && !formik.values.quantidadeMinutosExigirNovoToken && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {"Informar os minutos para exigir novo token"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </form>

          </div>
          <div className="modal-footer" style={{ margin: '0 auto' }}>
            <button disabled={formik.isSubmitting || !formik.isValid || (formik.values.desabilitarExigirToken && !formik.values.quantidadeMinutosExigirNovoToken)} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
              {!formik.isSubmitting && <> SALVAR </>}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClienteConfigSegurancaModal;