import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IXFinderDistribuicao from "../interfaces/IXFinderDistribuicao";


import FiltroPesquisaXFinderDistribuicaoRequest from "../interfaces/Requests/XFinder/FiltroPesquisaXFinderDistribuicaoRequest";


class XFinderDistribuicaoService {

    async gerarExcel(filtros: FiltroPesquisaXFinderDistribuicaoRequest): Promise<Blob> {

        let query = `sort=${filtros.sort}&modeloRelatorioId=${filtros.modeloRelatorioId}`;

        if (filtros.xFinderDistribuicaoId && filtros.xFinderDistribuicaoId.length > 0) {
            query += filtros.xFinderDistribuicaoId.map(id => `&xFinderDistribuicaoId=${id}`).join('')
        }

        if (filtros.areaDireitoId.length > 0) filtros.areaDireitoId.forEach((id) => (query += `&areaDireitoId=${id}`));

        if (filtros.status != 0) {
            query += `&status=${filtros.status}`;
        }

        if (filtros.nomePesquisa) {
            query += `&nomePesquisa=${filtros.nomePesquisa}`;
        }

        if (filtros.numeroProcesso) {
            query += `&numeroProcesso=${filtros.numeroProcesso}`;
        }

        if (filtros.assuntos) {
            query += `&assuntos=${filtros.assuntos}`;
        }

        if (filtros.dataAudienciaMaiorIgual) {
            query += `&dataAudienciaMaiorIgual=${filtros.dataAudienciaMaiorIgual}`;
        }

        if (filtros.dataAudienciaMenorIgual) {
            query += `&dataAudienciaMenorIgual=${filtros.dataAudienciaMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }
        if (filtros.dataDistribuicaoMaiorIgual) {
            query += `&dataDistribuicaoMaiorIgual=${filtros.dataDistribuicaoMaiorIgual}`;
        }

        if (filtros.dataDistribuicaoMenorIgual) {
            query += `&dataDistribuicaoMenorIgual=${filtros.dataDistribuicaoMenorIgual}`;
        }
        const { data: result } = await api.get(`XFinderDistribuicao/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

        return result;
    }


    async obterXFinderDistribuicoes(filtros: FiltroPesquisaXFinderDistribuicaoRequest): Promise<IRecuperaLista<IXFinderDistribuicao>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.xFinderDistribuicaoId && filtros.xFinderDistribuicaoId.length > 0) {
            query += filtros.xFinderDistribuicaoId.map(id => `&xFinderDistribuicaoId=${id}`).join('')
        }

        if (filtros.areaDireitoId.length > 0) filtros.areaDireitoId.forEach((id) => (query += `&areaDireitoId=${id}`));

        if (filtros.status != 0) {
            query += `&status=${filtros.status}`;
        }

        if (filtros.nomePesquisa) {
            query += `&nomePesquisa=${filtros.nomePesquisa}`;
        }

        if (filtros.numeroProcesso) {
            query += `&numeroProcesso=${filtros.numeroProcesso}`;
        }

        if (filtros.assuntos) {
            query += `&assuntos=${filtros.assuntos}`;
        }

        if (filtros.dataAudienciaMaiorIgual) {
            query += `&dataAudienciaMaiorIgual=${filtros.dataAudienciaMaiorIgual}`;
        }

        if (filtros.dataAudienciaMenorIgual) {
            query += `&dataAudienciaMenorIgual=${filtros.dataAudienciaMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }
        if (filtros.dataDistribuicaoMaiorIgual) {
            query += `&dataDistribuicaoMaiorIgual=${filtros.dataDistribuicaoMaiorIgual}`;
        }

        if (filtros.dataDistribuicaoMenorIgual) {
            query += `&dataDistribuicaoMenorIgual=${filtros.dataDistribuicaoMenorIgual}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IXFinderDistribuicao>>(`XFinderDistribuicao/RecuperaXFinderDistribuicao?${query}`);

        return result;
    }

    async triagem(xFinderDistribuicaoId: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": 5 },
            { "op": "replace", "path": "/escritorioId", "value": null },

        ]

        await api.patch<IXFinderDistribuicao>(`XFinderDistribuicao/AtualizaXFinderDistribuicaoPorId?id=${xFinderDistribuicaoId}`, data);
    }

    async salvarEscritorio(xFinderDistribuicaoId: number, escritorioId: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": 1 },
            { "op": "replace", "path": "/escritorioId", "value": escritorioId },

        ]

        await api.patch<IXFinderDistribuicao>(`XFinderDistribuicao/AtualizaXFinderDistribuicaoPorId?id=${xFinderDistribuicaoId}`, data);
    }

    async alterarStatus(xFinderDistribuicaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IXFinderDistribuicao>(`XFinderDistribuicao/AtualizaXFinderDistribuicaoPorId?id=${xFinderDistribuicaoId}`, data);
    }

}


export default new XFinderDistribuicaoService();