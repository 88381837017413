import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import FluxoService from "../../../services/FluxoService";

import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaFluxoRequest from "../../../interfaces/Requests/Fluxo/FiltroPesquisaFluxoRequest";

import { OrangeContext } from "../../../contexts/OrangeProvider";

import "../Styles/icons.css";
import Carregando from "../../../components/Carregando";

const FluxoDesfazerPage = () => {

  const navigate = useNavigate();

  const { fluxoFase, setFluxoFase } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [filtros] = useState<FiltroPesquisaFluxoRequest>({
    nome: "",
    fluxoId: fluxoFase.fluxoId,
    codigo: "",
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "fluxoId",
    modulo: "",
  });

  const carregarFluxo = useCallback(async (filtro: FiltroPesquisaFluxoRequest) => {
    try {

      const fluxo = await FluxoService.obterFluxos(filtro);

      const fluxoFase = await FluxoService.obterFasesPorId(filtro.fluxoId);

      setFluxoFase({
        ...fluxoFase,
        fases: fluxoFase.fases,
        nome: fluxo.data[0].nome,
        jsonContent: fluxo.data[0].jsonContent
      });

      navigate("/Fluxo/FluxoFase");

    } catch (error) {
      console.error("Failed to load workflow", error);
    }
  }, [navigate, setFluxoFase]);

  useEffect(() => {
    carregarFluxo(filtros);
  }, [carregarFluxo, filtros]);

  return (
    <div className="row mt-2">
      <Carregando />
    </div>
  );
};

export default FluxoDesfazerPage;
