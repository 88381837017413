import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IResultadoAudiencia from "../interfaces/IResultadoAudiencia";
import { AdicionaResultadoAudienciaRequest } from "../interfaces/Requests/ResultadoAudiencia/AdicionaResultadoAudienciaRequest";
import FiltroPesquisaResultadoAudienciaRequest from "../interfaces/Requests/ResultadoAudiencia/FiltroPesquisaResultadoAudienciaRequest";
import { EditarResultadoAudienciaRequest } from "../interfaces/Requests/ResultadoAudiencia/EditarResultadoAudienciaRequest";
import { EModulo } from "../enum/EModulos";

class ResultadoAudienciaService {

    async obterResultadoAudiencias(filtros: FiltroPesquisaResultadoAudienciaRequest): Promise<IRecuperaLista<IResultadoAudiencia>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IResultadoAudiencia>>(`ResultadoAudiencia/RecuperaResultadoAudiencia?${query}`);

        return result;
    }

    async adicionaResultadoAudiencia(adicionaResultadoAudiencia: AdicionaResultadoAudienciaRequest): Promise<IResultadoAudiencia> {
        let { data: result } = await api.post<IResultadoAudiencia>(`ResultadoAudiencia/AdicionaResultadoAudiencia`, adicionaResultadoAudiencia);

        return result;
    }


    async alterarStatus(resultadoaudienciaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IResultadoAudiencia>(`ResultadoAudiencia/AtualizaResultadoAudienciaPorId?id=${resultadoaudienciaId}`, data);
    }

    async atualizarResultadoAudiencia(editarResultadoAudiencia: EditarResultadoAudienciaRequest): Promise<void> {

        let dataResultadoAudiencia = [
            { "op": "replace", "path": "/nome", "value": editarResultadoAudiencia.nome },
            { "op": "replace", "path": "/codigo", "value": editarResultadoAudiencia.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`ResultadoAudiencia/AtualizaResultadoAudienciaPorId?id=${editarResultadoAudiencia.resultadoAudienciaId}`, dataResultadoAudiencia)
        ]);
    }
    async obterResultadoAudienciaLimitada(filtros: FiltroPesquisaResultadoAudienciaRequest): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        } 

        let { data: result } = await api.get<IRecuperaLista<any>>(`ResultadoAudiencia/RecuperaListaLimitada?${query}`);

        return result;
    }

    async RecuperaListaLimitada(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<IResultadoAudiencia>> {
        let { data: result } = await api.get<IRecuperaLista<IResultadoAudiencia>>(
            `/ResultadoAudiencia/RecuperaListaLimitada`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Audiencia,
                },
            }
        );

        return result;
    }

    async recuperaListaLimitadaPesquisa(
        ClienteId: number | null = null
    ): Promise<IRecuperaLista<IResultadoAudiencia>> {
        let { data: result } = await api.get<IRecuperaLista<IResultadoAudiencia>>(
            `/ResultadoAudiencia/RecuperaListaLimitadaPesquisa`,
            {
                params: {
                    ClienteId,
                    modulo: EModulo.Audiencia,
                },
            }
        );

        return result;
    }
}


export default new ResultadoAudienciaService();