import { useQuery } from "react-query";
import EditorService from "../../../services/EditorService";
import { GetThread } from "../types";
import { queries } from "../queries";

export function useGetThread(data: GetThread) {
  const { threads: threadsQuery } = queries;

  const {
    data: threads,
    error,
    isLoading,
  } = useQuery({
    queryKey: [threadsQuery, data],
    queryFn: () => EditorService.getCommentThreads(data),
  });

  return {
    threads: threads?.thread ?? [],
    isLoading,
    error,
  };
}
