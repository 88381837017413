import { ETipoCampoEnum } from "../enum";
import IHonorario from "../interfaces/IHonorario";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { AdicionaHonorarioRequest } from "../interfaces/Requests/Honorario/AdicionaHonorarioRequest";
import FiltroPesquisaHonorarioRequest from "../interfaces/Requests/Honorario/FiltroPesquisaHonorarioRequest";
import api from "./Api";

class HonorarioService {

  public async obterHonorarios(filtros: FiltroPesquisaHonorarioRequest): Promise<IRecuperaLista<any>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

    if (filtros.statusId.length > 0) {
      filtros.statusId.forEach((id) => (query += `&statusId=${id}`));
  }

    if (filtros.processoId) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.honorarioId) {
      query += `&honorarioId=${filtros.honorarioId}`;
    }

    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }

    if (filtros.CompetenciaMaiorIgual) {
      query += `&CompetenciaMaiorIgual=${filtros.CompetenciaMaiorIgual}`;
    }

    if (filtros.CompetenciaMenorIgual) {
      query += `&CompetenciaMenorIgual=${filtros.CompetenciaMenorIgual}`;
    }

    if (filtros.tipoHonorarioId) {
      query += `&tipoHonorarioId=${filtros.tipoHonorarioId}`;
    }

    if (filtros.contratoId) {
      query += `&contratoId=${filtros.contratoId}`;
    }

    if (filtros.consultaId) {
      query += `&consultaId=${filtros.consultaId}`;
    }

    if (filtros.escritorioId) {
      query += `&escritorioId=${filtros.escritorioId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<IHonorario>>(`Honorario/RecuperaHonorario?${query}`);

    return result;
  }

  public async obterHonorarioPorId(honorarioId: number): Promise<IRecuperaLista<IHonorario>> {
    let { data: result } = await api.get<IRecuperaLista<IHonorario>>(`Honorario/RecuperaHonorario?honorarioId${honorarioId}`);

    return result;
  }

  public async adicionaHonorario(adicionaHonorario: AdicionaHonorarioRequest): Promise<IHonorario> {
    let { data: result } = await api.post<IHonorario>(`Honorario/AdicionaHonorario`, adicionaHonorario);

    return result;
  }

  public async editarHonorario(editarHonorario: AdicionaHonorarioRequest) {
    let request = this.montarObjetoArray(editarHonorario);
    if (request.length > 0) await api.patch(`Honorario/AtualizaHonorarioPorId?id=${editarHonorario.honorarioId}`, request);
  }

  public async alterarStatus(honorarioId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<IHonorario>(`Honorario/AtualizaHonorarioPorId?id=${honorarioId}`, data);
  }

  public async obterCapaHonorario(honorarioId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Honorario/RecuperaCapaHonorario?honorarioId=${honorarioId}`
    );

    return result;
  }

  public async alterarHonorarioPorCampo(campo: string, valor: any, honorarioId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<any>(`Honorario/AtualizaHonorarioPorId?id=${honorarioId}`, request);
    }
  }

  public async alterarHonorarioPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<any>(`Honorario/AtualizaXCampoValor?id=${xcampoId}`, request);
    }
  }

  public async alterarHonorarioPorCampoBolleano(campo: string, valor: any, honorarioId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`Honorario/AtualizaHonorarioPorId?id=${honorarioId}`, request);
  }

  public async alterarHonorarioPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`Honorario/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  public async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`Honorario/AdicionaXCampoValor`, request);

    return result;
  }

  public async atualizarHonorario(editarHonorario: AdicionaHonorarioRequest): Promise<void> {
    let dataHonorario = [
      { op: "replace", path: "/tipoHonorarioId", value: editarHonorario.tipoHonorarioId },
      { op: "replace", path: "/descricao", value: editarHonorario.descricao },
      { op: "replace", path: "/competencia", value: editarHonorario.competencia },
      { op: "replace", path: "/escritorioId", value: editarHonorario.escritorioId },
      { op: "replace", path: "/moedaId", value: editarHonorario.moedaId },
      { op: "replace", path: "/status", value: editarHonorario.statusId },
      { op: "replace", path: "/valorTotal", value: editarHonorario.valorTotal },
      { op: "replace", path: "/codigo", value: editarHonorario.codigo },
      { op: "replace", path: "/numeroParcela", value: editarHonorario.numeroParcela },
      { op: "replace", path: "/tipoCentroCustoId", value: editarHonorario.tipoCentroCustoId }
    ];

    await Promise.all([await api.patch<void>(`Honorario/AtualizaHonorarioPorId?id=${editarHonorario.honorarioId}`, dataHonorario)]);
  }

  public concluirCompromisso = async (honorario: AdicionaHonorarioRequest) => {

    // Salvar campos normais
    await this.editarHonorario(honorario);

    // Salvar xcampos
    if (honorario.xCampoValorList?.length > 0) {
      honorario.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, honorarioId: honorario.honorarioId, texto: xCampo.texto });
            else await this.alterarHonorarioPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId);
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, honorarioId: honorario.honorarioId, data: xCampo.data });
            else await this.alterarHonorarioPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId);
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, honorarioId: honorario.honorarioId, valor: xCampo.valor });
            else await this.alterarHonorarioPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId);
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, honorarioId: honorario.honorarioId, inteiro: xCampo.inteiro });
            else await this.alterarHonorarioPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId);
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) await this.adicionarXCampoValor({ campoId: xCampo.campoId, honorarioId: honorario.honorarioId, xCampoOpcaoId: xCampo.xCampoOpcaoId });
            else await this.alterarHonorarioPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId);
            break;
        }
      });
    }
  };

  private montarObjetoArray = (honorario: AdicionaHonorarioRequest) => {
    let request: any = [];

    // Campos texto
    if (honorario.codigo) request.push({ op: "replace", path: "/codigo", value: honorario.codigo });
    if (honorario.descricao) request.push({ op: "replace", path: "/descricao", value: honorario.descricao });
    if (honorario.numeroParcela) request.push({ op: "replace", path: "/numeroParcela", value: honorario.numeroParcela });

    // Campos data
    if (honorario.competencia) request.push({ op: "replace", path: "/competencia", value: honorario.competencia });

    //Valor
    if (honorario.valorTotal) request.push({ op: "replace", path: "/valorTotal", value: honorario.valorTotal });

    // Campos lista
    if (honorario.tipoCentroCustoId) request.push({ op: "replace", path: "/tipoCentroCustoId", value: honorario.tipoCentroCustoId });
    if (honorario.escritorioId) request.push({ op: "replace", path: "/escritorioId", value: honorario.escritorioId });
    if (honorario.moedaId) request.push({ op: "replace", path: "/moedaId", value: honorario.moedaId });

    return request;
  };
}

export default new HonorarioService();
