import axios from "axios";
import { EPorteEmpresa, EStatusEmpresa } from "../enum";
import IGrupoEconomicoEmpresa, { IGrupoEconomicoEmpresaSocioAdministrador } from "../interfaces/IGrupoEconomicoEmpresa";
import AtividadeEmpresaService from "./AtividadeEmpresaService";
import CidadeService from "./CidadeService";
import MotivoSituacaoService from "./MotivoSituacaoService";
import QualificacaoService from "./QualificacaoService";

class CNPJAService {


    async pesquisarEmpresa(numeroDocumento: string): Promise<IGrupoEconomicoEmpresa> {
        const {data: resposta} = await  axios({
            method: "get",
            url: `https://api.cnpja.com/office/${numeroDocumento}?strategy=CACHE`,
            headers: {
                'Authorization': 'eaaa243d-ef56-443e-99a3-2d83ccf9a1b8-cc5c34c0-4d4b-4884-82e2-d6e5e2bbbc1c',
            }
        });

        return await this.parseData(resposta)
    }

    async parseData(resposta: any): Promise<IGrupoEconomicoEmpresa>  {
        let dados: any = {
            numeroDocumento: resposta.taxId,
            razaoSocial: resposta.company.name,
            dataConstituicao: resposta.founded,
            nomeFantasia: resposta.alias || '',
            tipoEstabelecimento: resposta.head?1:2,
            dataSituacao: resposta.statusDate,
            inscricaoEstadual: resposta?.registrations?.[0]?.number || '',
            endereco: resposta.address.street,
            numero: resposta.address.number,
            complemento: resposta.address.details,
            bairro: resposta.address.district,
            uf: resposta.address.state,
            cep: resposta.address.zip,
            telefone: resposta.phones?.[0]?`(${resposta.phones[0].area}) ${resposta.phones[0].number}`: '',
            email: resposta.emails?.[0]?.address || '',
            porteEmpresa: EPorteEmpresa[resposta.company.size.acronym],
            statusEmpresa: EStatusEmpresa[resposta.status.text],
            valorCapitalSocial: resposta.company.equity
        }

        if(resposta.address.municipality){
            try{
                let cidade = await CidadeService.obterCidadePorIBGE(resposta.address.municipality);
                dados.cidadeId = cidade.data[0].cidadeId
            } catch(e){
                console.log(e)
            }
        }
        if(resposta.reason){
            try{
                let motivo = await MotivoSituacaoService.obterMotivoSituacaos({
                    nome: resposta.reason.text.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                    offset: 0,
                    limit: 1,
                    status:0,
                    sort: 'nome',
                    totalItems: 1
                })
                dados.motivoSituacaoId = motivo.data[0].motivoSituacaoId
            } catch(e){
                console.log(e)
            }            
        }

        if(resposta.mainActivity?.id){
            try{
                let atividade = await AtividadeEmpresaService.obterAtividadeEmpresas({
                    codigo: resposta.mainActivity.id.toString(),
                    offset: 0,
                    limit: 1,
                    status:0,
                    sort: 'nome',
                    totalItems: 1
                })
                dados.atividadeEmpresaPrincipalId = atividade.data[0].atividadeEmpresaId
            } catch(e){
                console.log(e)
            }            
        }

        if(resposta.sideActivities?.length){
            dados.empresasAtividadesEmpresa = []
            try{
                for(let i = 0; i < resposta.sideActivities.length; i++){
                    let sideActivitie = resposta.sideActivities[i];
                    let atividade = await AtividadeEmpresaService.obterAtividadeEmpresas({
                        codigo: sideActivitie.id.toString(),
                        offset: 0,
                        limit: 1,
                        status:0,
                        sort: 'nome',
                        totalItems: 1
                    })
                    dados.empresasAtividadesEmpresa.push({
                        atividadeEmpresaId: atividade.data[0].atividadeEmpresaId,
                        nome: atividade.data[0].nome,
                        orden: i
                    })
                }
            } catch(e){
                console.log(e)
            }
        }

        if(resposta.company.members){
            dados.sociosAdministradores = []
            try{
                for(let i = 0; i < resposta.company.members.length; i++){
                    let member = resposta.company.members[i]
                    let qualificacao;
                    try{
                        qualificacao = await QualificacaoService.obterQualificacaos({
                            nome: member.role.text,
                            offset: 0,
                            limit: 1,
                            status:0,
                            sort: 'nome',
                            totalItems: 1
                        })
                    } catch(e){
                        console.log(e)
                    }
                    let membro: IGrupoEconomicoEmpresaSocioAdministrador = {
                        nome: member.person.name,
                        dataEntrada: member.since,
                        numeroDocumento: "",
                        idade: member.person?.age?.split('-')[0] || '',
                        pais: "",
                        qualificacaoId: qualificacao?.data[0]?.qualificacaoId || 0,
                        codigo: "",
                        quotasAcoes: 0,
                   }
                    dados.sociosAdministradores.push(membro)
                }
            } catch(e){
                console.log(e)
            }
        }


        
        let empresa = {
            empresaId: '',
            grupoEconomicoId: '',
            grupoEconomicoNome: "",
            tipoEstabelecimento: '',
            pessoaId: '',
            numeroDocumento: "",
            razaoSocial: "",
            nomeFantasia: "",
            tipoEmpresaId: '',
            objetoSocial: "",
            inscricaoEstadual: "",
            inscricaoMunicipal: "",
            orgaoRegistroId: '',
            descricaoOrgao: "",
            nire: "",
            dataConstituicao: "",
            dataPrimeiroRegistro: "",
            endereco: "",
            numero: "",
            complemento: "",
            bairro: "",
            cep: "",
            cidadeId: '',
            porteEmpresa: '',
            valorCapitalSocial: '',
            valorCapitalIntegralizado: '',
            valorCapitalAIntegralizar: '',
            moedaId: '',
            observacoesEmpresa: "",
            telefone: "",
            email: "",
            statusEmpresa: '',
            dataSituacao: "",
            motivoSituacaoId: '',
            atividadeEmpresaPrincipalId: '',
            empresasAtividadesEmpresa: [],
            sociosAdministradores: [],
            representantes: [],
            dataCadastro: "",
            usuarioIdCadastro: '',
            codigo: ""
        }

        

        return {
            ...empresa,
            ...dados
        }
    }
}


export default new CNPJAService();