import styled, { css } from "styled-components";

export const Container = styled.div<{ isAwnser: boolean; isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
  position: relative;
  background: #fff;
  transition: background 0.4s ease;

  ${(props) =>
    props.isAwnser &&
    css`
      border-left: 2px dashed gray;
    `}

  ${(props) =>
    props.isActive &&
    css`
      background: var(--bs-light);
    `}

  border-radius: ${(props) => (props.isAwnser ? "0" : "12px")};

  > div.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: absolute;
    right: 10px;

    > button {
      border: none;
      border-radius: 8px;
      color: var(--bs-gray);
      height: 30px;
      width: 30px;
      background: transparent;

      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        font-size: 18px;
      }

      &:hover {
        background: #f2f2fa;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  > div.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  div.header {
    gap: 10px;
    display: flex;

    div.info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    > img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }

  > div.content {
    cursor: text;
  }

  form.footer {
    display: block;

    div.actions {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
    }

    > input {
      width: 100%;
    }
  }
`;

export const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bs-orange);

  > strong {
    color: #fff;
  }
`;
