import { Handle, Position, useStore } from 'reactflow';
import './styles/NodeInicioFim.css';
import { IoMdMove } from 'react-icons/io';

const connectionNodeIdSelector = (state: any) => state.connectionNodeId;

export default function NodeFim({ id }: { id: any, data: any }) {
    const connectionNodeId = useStore<any>(connectionNodeIdSelector);
    
    const isConnecting = !!connectionNodeId;
    const isTarget = connectionNodeId && connectionNodeId !== id;
    const label = isTarget ? 'Conecte com esse elemento' : '';
    return (
        <>
            <div className="nodeInit">
                <div style={{ display: 'flex', flexDirection: 'row', zIndex: '-1' }}>

                    <div className="nodeInitIcon" style={{ cursor: 'move' }} >
                        <IoMdMove />
                    </div>
                </div>

                <div
                    className="nodeInitBody"
                    style={{
                        borderStyle: isTarget ? 'dashed' : 'solid',
                        backgroundColor: isTarget ? '#ffcce3' : '#ff3939',
                    }}
                >
                    {!isConnecting && (
                        <Handle className="customHandle" position={Position.Right} type="source" />
                    )}

                    <Handle
                        className="customHandle"
                        position={Position.Left}
                        type="target"
                        isConnectableStart={false}
                    />

                    <div className="nodeInitContet">
                        {!isTarget && (<p>Fim</p>)}
                        {isTarget && (<p>{label}</p>)}
                    </div>
                </div>
            </div>
        </>
    );
}
