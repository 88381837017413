import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { optionsType } from "../../../components/Comum/Fornecedor/FornecedorField";
import Swal from "sweetalert2";
import Select from "react-select";
import ConfiguracaoModeloRelatorioService from "../../../services/ConfiguracaoModeloRelatorioService";
import { useNavigate } from "react-router-dom";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import IModeloRelatorio from "../../../interfaces/IModeloRelatorio";

interface IRelatorioParametrizacaoModal {
  exibirModal: boolean;
  handleClose: () => void;
  modulos: optionsType[];
  edit?: boolean;
  relatorio?: IModeloRelatorio;
}

const RelatorioParametrizacaoModal = ({
  exibirModal,
  handleClose,
  modulos,
  edit,
  relatorio,
}: IRelatorioParametrizacaoModal) => {
  const [loading, setLoading] = useState(false);
  const [modulo, setModulo] = useState<optionsType>({
    label: "Selecione um módulo",
    value: 0,
  });
  const [extensao, setExtensao] = useState<optionsType>({
    label: "Selecione uma extensão",
    value: 0,
  });
  const [extensoes] = useState<optionsType[]>([{ label: ".xlsx", value: 1 }]);
  const [tipo, setTipo] = useState<optionsType>({
    label: "Selecione um tipo",
    value: 0,
  });
  const [tipos, setTipos] = useState<optionsType[]>([]);
  const navigate = useNavigate();

  const validarFormPermissoes = Yup.object().shape({
    nome: Yup.string().required("O nome é obrigatório."),
    tipo: Yup.number().min(1, "O tipo é obrigatório."),
    modulo: Yup.number().min(1, "O módulo é obrigatório."),
    extensao: Yup.string().required("A extensão é obrigatória."),
  });

  const formik = useFormik({
    initialValues: {
      nome: relatorio ? relatorio?.nome : "",
      modulo: relatorio ? relatorio.modulo : 0,
      tipo: relatorio?.tipoRelatorioId ? relatorio.tipoRelatorioId : 0,
      extensao: relatorio?.extensao ? relatorio.extensao : "",
    },
    enableReinitialize: true,
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (edit) {
          try {
            await ConfiguracaoModeloRelatorioService.editarModeloRelatorio(
              relatorio?.modeloRelatorioId ?? 0,
              { nome: values.nome }
            );

            await Swal.fire({
              heightAuto: false,
              icon: "success",
              text: `Modelo de Relatório atualizado com sucesso.`,
              showConfirmButton: false,
              timer: 3000,
            });

            resetForm();
            setModulo({
              label: "Selecione um módulo",
              value: 0,
            });

            handleClose();

            return;
          } catch (e: any) {
            Swal.fire({
              heightAuto: false,
              icon: "error",
              title: `Não foi possível cadastrar o Modelo de Relatório`,
              text:
                e?.response?.data?.Message &&
                typeof e.response.data.Message === "string"
                  ? e.response.data.Message
                  : e.message,
              showConfirmButton: true,
            });

            return;
          }
        }

        const response =
          await ConfiguracaoModeloRelatorioService.adicionaConfiguracaoRelatorio(
            {
              ...values,
              modeloRelatorioId: 0,
              modeloRelatorioCampos: [],
              status: 1,
            }
          );

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Modelo de Relatório cadastrado com sucesso.`,
          showConfirmButton: false,
          timer: 3000,
        });

        resetForm();
        setModulo({
          label: "Selecione um módulo",
          value: 0,
        });

        handleClose();

        navigate(
          `/Relatorio/ConfiguracaoRelatorio/${response.modeloRelatorioId}`
        );
      } catch (e: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível cadastrar o Modelo de Relatório`,
          text:
            e?.response?.data?.Message &&
            typeof e.response.data.Message === "string"
              ? e.response.data.Message
              : e.message,
          showConfirmButton: true,
        });
      }
    },
  });

  const carregarTipos = async (modulo: number) => {
    try {
      setLoading(true);

      const result = await ConfiguracaoTelaService.obterTiposRelatorio(modulo);

      const mappedResult = result.data.map((tipo) => ({
        label: tipo.nome,
        value: tipo.tipoRelatorioId,
      }));

      setTipos(mappedResult);

      if (edit && relatorio) {
        const tipo = mappedResult.find(
          (result) => result.value === relatorio?.tipoRelatorioId
        );

        setTipo(tipo ?? { label: "", value: 0 });
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos Tipos de Relatório",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (edit && relatorio && exibirModal) {
      carregarTipos(relatorio?.modulo);

      const moduloFound = modulos.find(
        (modulo) => modulo.value === relatorio.modulo
      );

      setModulo(moduloFound ?? { label: "", value: 0 });

      const extensaoFound = extensoes.find(
        (extensao) => extensao.label === relatorio.extensao
      );

      setExtensao(extensaoFound ?? { label: "", value: 0 });
    }
  }, [relatorio]);

  return (
    <Modal
      size="lg"
      centered={true}
      show={exibirModal}
      onHide={() => {
        setModulo({
          label: "Selecione um módulo",
          value: 0,
        });

        setTipos([]);
        setTipo({
          label: "Selecione um tipo",
          value: 0,
        });

        handleClose();
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            {edit ? "Editar" : "Cadastrar"} Modelo de Relatório
          </h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="row">
              <div className="col-md-6">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder required text-orange"
                >
                  Nome
                </label>
                <input
                  {...formik.getFieldProps("nome")}
                  placeholder="Nome"
                  type="text"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.nome && formik.errors.nome,
                    },
                    {
                      "is-valid": formik.touched.nome && !formik.errors.nome,
                    }
                  )}
                  id="form-nome"
                />
                {formik.touched.nome && formik.errors.nome && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.nome}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="form-nome"
                  className="form-label required fw-bolder text-orange"
                >
                  Módulo:
                </label>
                <Select
                  placeholder="Selecione um módulo"
                  id="form-select"
                  options={modulos}
                  isSearchable
                  value={modulo}
                  isDisabled={edit}
                  onChange={async (select) => {
                    if (select) {
                      setModulo(select);
                      formik.setFieldTouched("modulo", true);
                      formik.setFieldValue("modulo", select.value);

                      await carregarTipos(select.value);
                    }
                  }}
                />
                {formik.touched.modulo && formik.errors.modulo && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.modulo}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder text-orange required mt-3"
                >
                  Extensão
                </label>
                <Select
                  placeholder="Selecione uma extensão"
                  id="form-select"
                  options={extensoes}
                  isSearchable
                  value={extensao}
                  isDisabled={edit}
                  onChange={(select) => {
                    if (select) {
                      formik.setFieldTouched("extensao", true);
                      formik.setFieldValue("extensao", select.label);

                      setExtensao(select);
                    }
                  }}
                />

                {formik.touched.extensao && formik.errors.extensao && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.extensao}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder required text-orange"
                >
                  Tipo
                </label>

                <Select
                  placeholder="Selecione um tipo"
                  id="form-select"
                  options={tipos}
                  isLoading={loading}
                  isDisabled={tipos.length === 0 || edit}
                  value={tipo}
                  isSearchable
                  onChange={(select) => {
                    if (select) {
                      formik.setFieldTouched("tipo", true);
                      formik.setFieldValue("tipo", select.value);

                      setTipo(select);
                    }
                  }}
                />

                {formik.touched.tipo && formik.errors.tipo && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.tipo}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            Próximo
          </button>
          <button
            onClick={() => {
              formik.resetForm();

              setModulo({
                label: "Selecione um módulo",
                value: 0,
              });

              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RelatorioParametrizacaoModal;
