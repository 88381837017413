import { useRef, useState } from "react";
import {
  useSlateStatic,
  RenderElementProps,
} from "slate-react";
import { FooterComponent } from "./styles";
import { MdOutlineDelete } from "react-icons/md";
import { FooterElement as FooterEl } from "../../../../../../utils/types/slate";
import { FooterService } from "../../../../lib";
import { useProvider } from "../../../../hooks/useProvider";
import { useOutsideClick } from "../../../../../../utils/OutsideClick";

export const FooterElement = (props: RenderElementProps) => {
  const [active, setActive] = useState(false);
  const editor = useSlateStatic();
  const element = props.element as FooterEl
  const { version } = useProvider()
  const footerStyle = {
    padding: `20pt ${version?.contentStyle.marginRight} 20pt ${version?.contentStyle.marginLeft}`
  };
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({
    source: ref.current,
    onClickOutside: () => setActive(false)
  })

  return (
    <FooterComponent
      id={element.id}
      selected={active}
      contentEditable={active}
      suppressContentEditableWarning
      onClick={() => setActive(true)}
      {...props.attributes}
    >
      <div
        ref={ref}
        contentEditable={active}
        suppressContentEditableWarning
        className="container"
        style={footerStyle}
      >
        {props.children}
      </div>

      {active && (
        <div
          contentEditable={false}
          suppressContentEditableWarning
          className="footer"
        >
          <strong>Rodapé</strong>
          <div>
            <button onClick={() => FooterService.removeFooter(editor, element)}>
              <MdOutlineDelete />
            </button>
          </div>
        </div>
      )}
    </FooterComponent>
  );
};
