import { useEffect } from "react";
import { IPessoa } from "../../../interfaces/IPessoa";
import PessoaService from "../../../services/PessoaService";
import { MASCARA_CNPJ, MASCARA_CPF } from "../../../utils/Constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import MaskedInput from "react-text-mask";
import { validateCNPJ, validateCPF } from "validations-br";
import Swal from "sweetalert2";
import AdicionaPessoaRequest from "../../../interfaces/Requests/AdicionaPessoaRequest";
import { useNavigate } from "react-router-dom";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, OverlayTrigger } from "react-bootstrap";
import Spinner from "../../Spinner";

interface AdicionaPessoaProps {
  handlerPessoa: (pessoa: IPessoa) => void;
  numeroDocumento: string;
  tipoDocumento: string;
  hrefCancelar?: any;
  modalCancelar?: any;
}

function AdicionaPessoa(props: AdicionaPessoaProps) {
  const navigate = useNavigate();

  const adicionaPessoaSchema = Yup.object().shape({
    numeroDocumento: Yup.string().test("validar-numero-documento", "Documento inválido", (value) => {
      if (!value) {
        return false;
      }

      if (formik.values.tipoDocumento === "CNPJ") {
        let cnpjValidar = value.replace(/\./g, "").replace(/-/g, "").replace("/", "").replace(/_/g, "");

        if (cnpjValidar.length !== 14) {
          return false;
        }

        return validateCNPJ(cnpjValidar);
      } else if (formik.values.tipoDocumento === "CPF") {
        let cpfValidar = value.replace(/\./g, "").replace(/-/g, "").replace(/_/g, "");

        if (cpfValidar.length !== 11) {
          return false;
        }

        return validateCPF(cpfValidar);
      } else {
        return true;
      }
    }),
    nome: Yup.string().min(4).required(),
    nomeSocial: Yup.string().min(4).required(),
    tipoDocumento: Yup.string().required(),
  });

  const initialValues: AdicionaPessoaRequest = {
    nome: "",
    nomeSocial: "",
    numeroDocumento: props.numeroDocumento,
    tipoDocumento: props.tipoDocumento,
    dataNascimento: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: adicionaPessoaSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let result = await PessoaService.adicionaPessoa(values);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Pessoa cadastrada com sucesso`,
          timer: 4000,
        });

        props.handlerPessoa(result);
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar Pessoa`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });

        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("tipoDocumento", props.tipoDocumento);
    formik.setFieldValue("numeroDocumento", props.numeroDocumento);
  }, []);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>É o nome pelo qual as pessoas preferem ser identificadas no meio social e que reflete a sua identidade de gênero.</Popover.Body>
    </Popover>
  );


  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  return (
    <>
      <h3 className="text-orange mb-3">Adicionar Pessoa</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.submitForm();
        }}
        className="mb-3"
      >
        <div className="col-md-3 mt-3">
          <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
            Nome
          </label>
          <input
            {...formik.getFieldProps("nome")}
            placeholder="Nome"
            type="text"
            className={clsx(
              "form-control",
              {
                "is-invalid": formik.touched.nome && formik.errors.nome,
              },
              {
                "is-valid": formik.touched.nome && !formik.errors.nome,
              }
            )}
            id="form-nome"
          />
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="form-nomeSocial" className="form-label fw-bolder text-orange">
            Nome Social: <PopoverHelper />
          </label>
          <input
            {...formik.getFieldProps("nomeSocial")}
            placeholder="Nome Social"
            type="text"
            className={clsx(
              "form-control",
              {
                "is-invalid": formik.touched.nomeSocial && formik.errors.nomeSocial,
              },
              {
                "is-valid": formik.touched.nomeSocial && !formik.errors.nomeSocial,
              }
            )}
          />
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
            Tipo Documento
          </label>
          <select
            disabled={true}
            value={formik.values.tipoDocumento}
            onChange={(e) => {
              formik.setFieldValue("tipoDocumento", e.target.value);
              formik.setFieldValue("numeroDocumento", "");
            }}
            placeholder="Tipo Documento"
            className={"form-select"}
            id="form-select"
          >
            <option value="CPF" label="CPF">
              {" "}
              CPF{" "}
            </option>
            <option value="CNPJ" label="CNPJ">
              {" "}
              CNPJ{" "}
            </option>
            <option value="OUTROS" label="Outros">
              {" "}
              Outros{" "}
            </option>
          </select>
        </div>

        {formik.values.tipoDocumento === "CPF" && (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-numeroDocumento" className="form-label fw-bolder text-orange">
              CPF
            </label>
            <MaskedInput
              disabled={true}
              mask={MASCARA_CPF}
              {...formik.getFieldProps("numeroDocumento")}
              placeholder="000.000.000-00"
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.numeroDocumento && formik.errors.numeroDocumento,
                },
                {
                  "is-valid": formik.touched.numeroDocumento && !formik.errors.numeroDocumento,
                }
              )}
              id="form-numeroDocumento"
            />
          </div>
        )}

        {formik.values.tipoDocumento === "CNPJ" && (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-numeroDocumento" className="form-label fw-bolder text-orange">
              CNPJ
            </label>
            <MaskedInput
              disabled={true}
              mask={MASCARA_CNPJ}
              {...formik.getFieldProps("numeroDocumento")}
              placeholder="00.000.000/0000-00"
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.numeroDocumento && formik.errors.numeroDocumento,
                },
                {
                  "is-valid": formik.touched.numeroDocumento && !formik.errors.numeroDocumento,
                }
              )}
              id="form-numeroDocumento"
            />
          </div>
        )}

        {formik.values.tipoDocumento === "OUTROS" && (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-numeroDocumento" className="form-label fw-bolder text-orange">
              Documento
            </label>
            <input
              disabled={true}
              {...formik.getFieldProps("numeroDocumento")}
              placeholder=""
              type="text"
              className={clsx(
                "form-control",
                {
                  "is-invalid": formik.touched.numeroDocumento && formik.errors.numeroDocumento,
                },
                {
                  "is-valid": formik.touched.numeroDocumento && !formik.errors.numeroDocumento,
                }
              )}
              id="form-numeroDocumento"
            />
          </div>
        )}

        <div className="col-md-3">
          <button disabled={formik.isSubmitting || !formik.isValid} type="submit" className="btn btn-orange mt-5">
            {!formik.isSubmitting && <> Salvar </>}
            {formik.isSubmitting && (
              <Spinner />
            )}
          </button>
          <button
            onClick={() => {
              if (props.modalCancelar) props.modalCancelar();
              if (props.hrefCancelar) navigate(props.hrefCancelar);
            }}
            disabled={formik.isSubmitting}
            type="button"
            className="btn btn-danger  mt-5 float-end"
          >
            Cancelar
          </button>
        </div>
      </form>
    </>
  );
}

export default AdicionaPessoa;
