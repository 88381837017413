import { useState } from "react";
import { Modal } from "react-bootstrap";

import IUsuario from "../../../../interfaces/IUsuario";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TableColumn } from "react-data-table-component";
import GridPerfil from "./components/GridPerfil";

type PerfilType = {
  perfilUsuarioId: number;
  nome: string;
  perfilAdHash: string | null;
};

interface IUsuarioModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  usuario: IUsuario;
  titulo: string;
}

const UsuarioDetalheModal = ({
  toggleModal,
  exibirModal,
  usuario,
  titulo,
}: IUsuarioModalProps) => {
  const [exibeRestricoes, setExibeRestricoes] = useState<boolean>(false);
  const [exibePerfis, setExibePerfis] = useState<boolean>(false);

  const colunas: TableColumn<PerfilType>[] = [
    {
      name: "Id",
      sortField: "ID",
      selector: (row: PerfilType) => row.perfilUsuarioId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: PerfilType) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Hash AD",
      sortField: "hashAd",
      selector: (row: PerfilType) => {
        if (row.perfilAdHash === "" || !row.perfilAdHash) {
          return "";
        } else {
          return row.perfilAdHash;
        }
      },
      sortable: true,
      wrap: true,
    },
  ];

  const itens = usuario.perfisUsuario;

  const handleHide = () => {
    setExibeRestricoes(false);
    setExibePerfis(false);
    toggleModal();
  };

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleHide}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">{titulo}</h5>
          <div
            onClick={handleHide}
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          >
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row mt-2">
            <div className="col-md-6">
              <label
                htmlFor="form-nomeUsuario"
                className="form-label fw-bolder text-orange"
              >
                Nome do Usuário
              </label>
              <br />
              {usuario?.nomeUsuario}
            </div>

            <div className="col-md-6">
              <label
                htmlFor="form-email"
                className="form-label fw-bolder text-orange"
              >
                Email
              </label>
              <br />
              {usuario?.email}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <label
                htmlFor="form-clienteNome"
                className="form-label fw-bolder text-orange"
              >
                Cliente
              </label>
              <br />
              {usuario?.clienteNome}
            </div>

            <div className="col-md-6">
              <label
                htmlFor="form-codigo"
                className="form-label fw-bolder text-orange"
              >
                Código
              </label>
              <br />
              {usuario?.codigo}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <label
                htmlFor="form-nomePessoa"
                className="form-label fw-bolder text-orange"
              >
                Nome Pessoa
              </label>
              <br />
              {usuario?.nomePessoa}
            </div>

            <div className="col-md-6">
              <label
                htmlFor="form-nomeSocial"
                className="form-label fw-bolder text-orange"
              >
                Nome Social
              </label>
              <br />
              {usuario?.nomeSocial}
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-12">
              <label
                className="form-label fw-bolder text-orange"
                onClick={() => setExibePerfis(!exibePerfis)}
                style={{ cursor: "pointer" }}
              >
                Perfis do Usuário:
                <FontAwesomeIcon
                  color={"var(--primary-base2)"}
                  className="mx-3"
                  icon={exibePerfis ? faMinus : faPlus}
                />
              </label>
            </div>
          </div>

          {exibePerfis && (
            <GridPerfil
              colunas={colunas}
              itens={itens}
              tipo="Perfis"
              paginationServer={false}
            />
          )}

          <hr />

          <div className="row">
            <div className="col-md-12">
              <label
                className="form-label fw-bolder text-orange"
                onClick={() => setExibeRestricoes(!exibeRestricoes)}
                style={{ cursor: "pointer" }}
              >
                Restrições de Acesso:
                <FontAwesomeIcon
                  color={"var(--primary-base2)"}
                  className="mx-3"
                  icon={exibeRestricoes ? faMinus : faPlus}
                />
              </label>

              <div
                className="row"
                style={{ display: exibeRestricoes ? "flex" : "none" }}
              >
                <div className="col-6">
                  <label
                    htmlFor="form-exibirProcessoRestrito"
                    className="form-label fw-bolder text-orange mb-0"
                  >
                    Exibir Processos Restritos
                  </label>
                  <br />
                  {usuario.exibirProcessoRestrito ? "Sim" : "Não"}
                </div>

                <div className="col-6">
                  <label
                    htmlFor="form-exibirContratoRestrito"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Exibir Contratos Restritos
                  </label>
                  <br />
                  {usuario.exibirContratoRestrito ? "Sim" : "Não"}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="form-exibirDocumentoRestrito"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Exibir Documentos Restritos
                  </label>
                  <br />
                  {usuario.exibirDocumentoRestrito ? "Sim" : "Não"}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="form-exibirLicencaRestrito"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Exibir Licenças Restritos
                  </label>
                  <br />
                  {usuario.exibirLicencaRestrito ? "Sim" : "Não"}
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="form-exibirConsultaRestrito"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Exibir Consultas Restritas
                  </label>
                  <br />
                  {usuario.exibirConsultaRestrito ? "Sim" : "Não"}
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="form-exibirImovelRestrito"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Exibir Imoveis Restritos
                  </label>
                  <br />
                  {usuario.exibirImovelRestrito ? "Sim" : "Não"}
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="form-exibirProcuracaoRestrito"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Exibir Procurações Restritas
                  </label>
                  <br />
                  {usuario.exibirProcuracaoRestrito ? "Sim" : "Não"}
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="form-exibirMarcaRestrito"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Exibir Marcas Restritas
                  </label>
                  <br />
                  {usuario.exibirMarcaRestrito ? "Sim" : "Não"}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="form-alterarCompromissosDeTerceiros"
                    className="form-label fw-bolder text-orange mb-0 mt-2"
                  >
                    Alterar Compromissos de Terceiros
                  </label>
                  <br />
                  {usuario.alterarCompromissosDeTerceiros ? "Sim" : "Não"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UsuarioDetalheModal;
