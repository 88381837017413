import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IOrgaoResponsavel from "../interfaces/IOrgaoResponsavel";
import { AdicionaOrgaoResponsavelRequest } from "../interfaces/Requests/OrgaoResponsavel/AdicionaOrgaoResponsavelRequest";
import FiltroPesquisaOrgaoResponsavelRequest from "../interfaces/Requests/OrgaoResponsavel/FiltroPesquisaOrgaoResponsavelRequest";
import { EditarOrgaoResponsavelRequest } from "../interfaces/Requests/OrgaoResponsavel/EditarOrgaoResponsavelRequest";

class OrgaoResponsavelService {

    async obterOrgaoResponsavels(filtros: FiltroPesquisaOrgaoResponsavelRequest): Promise<IRecuperaLista<IOrgaoResponsavel>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IOrgaoResponsavel>>(`OrgaoResponsavel/RecuperaOrgaoResponsavel?${query}`);

        return result;
    }

    async adicionaOrgaoResponsavel(adicionaOrgaoResponsavel: AdicionaOrgaoResponsavelRequest): Promise<IOrgaoResponsavel> {
        let { data: result } = await api.post<IOrgaoResponsavel>(`OrgaoResponsavel/AdicionaOrgaoResponsavel`, adicionaOrgaoResponsavel);

        return result;
    }


    async alterarStatus(orgaoResponsavelId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IOrgaoResponsavel>(`OrgaoResponsavel/AtualizaOrgaoResponsavelPorId?id=${orgaoResponsavelId}`, data);
    }

    async atualizarOrgaoResponsavel(editarOrgaoResponsavel: EditarOrgaoResponsavelRequest): Promise<void> {

        let dataOrgaoResponsavel = [
            { "op": "replace", "path": "/nome", "value": editarOrgaoResponsavel.nome },
            { "op": "replace", "path": "/codigo", "value": editarOrgaoResponsavel.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`OrgaoResponsavel/AtualizaOrgaoResponsavelPorId?id=${editarOrgaoResponsavel.orgaoResponsavelId}`, dataOrgaoResponsavel)
        ]);
    }
}


export default new OrgaoResponsavelService();