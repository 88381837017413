import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../../contexts/AuthProvider'
import UsuarioService from '../../../services/UsuarioService'
import Swal from 'sweetalert2'

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  > input {
    visibility: hidden;
  }

  > button {
    margin: 20px 0;
    width: 100px;
    align-self: center;
    border: none;
    padding: 5px 0;
    border-radius: 4px;
    background-color: transparent;
    color: var(--bs-orange);
    transition: color 0.5s ease;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &:hover {
      opacity: 0.8;
    }
  }
`

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid var(--bs-orange);
`

const EmptyPhotoPreview = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: var(--bs-orange);

  display: flex;
  justify-content: center;
  align-items: center;

  > strong {
    font-size: 40px;
    color: #fff;
  }
`

export const ProfilePhotoUpload = (): JSX.Element => {
  const { avatarLabel, user, updateUserPhoto } = useAuth()
  const [photoUrl, setPhotoUrl] = useState(user?.urlFoto)

  const uploadInputRef = useRef<HTMLInputElement | null>(null)

  const handleUploadPhoto = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      Swal.showLoading()

      const photo = event.target.files?.[0];

      if (photo && user) {
        const response = await UsuarioService.upload("photo", photo, user.id);
        if (response.urlFoto) {
          setPhotoUrl(response.urlFoto)
          updateUserPhoto(response)
        }
      }

      await Swal.fire({
        title: 'Foto de perfil alterada com sucesso!',
        icon: 'success',
      })
    } catch (error) {
      console.log(error);
    } finally {
      Swal.hideLoading()
    }
  };

  const renderPhoto = () => {
    return photoUrl ? (
         <ProfileImage
          src={photoUrl}
          alt="Imagem de perfil"
        /> 
    ) : (
      <EmptyPhotoPreview>
        <strong>{avatarLabel()}</strong>
      </EmptyPhotoPreview>
    );
  };

  return (
    <ComponentWrapper>
      <input
        ref={uploadInputRef}
        multiple={false}
        accept="image/*"
        type="file"
        onChange={handleUploadPhoto}
      />
      {renderPhoto()}
      <button type='button' onClick={() => uploadInputRef.current?.click()}>Alterar foto</button>
    </ComponentWrapper>
  );
}