import { EModulo } from "./EModulos";
import { ETipoTelaEnum } from "./ETipoTelaEnum";

export enum ETelaEnum {
  Default = 0,

  TelaCadastroProcesso = 1,
  TelaCapaProcesso = 2,

  TelaCadastroEmpresa = 3,
  TelaCapaEmpresa = 4,

  TelaCadastroAtoSocietario = 5,
  TelaCapaAtoSocietario = 6,

  TelaCadastroDespesa = 7,
  TelaCapaDespesa = 8,

  TelaCadastroContrato = 9,
  TelaCapaContrato = 10,

  TelaCadastroRegulatorio = 11,
  TelaCapaRegulatorio = 12,

  TelaCadastroConsultivo = 13,
  TelaCapaConsultivo = 14,

  TelaCadastroImovel = 15,
  TelaCapaImovel = 16,

  TelaCadastroMarcasEPertences = 17,
  TelaCapaMarcasEPertences = 18,

  TelaCadastroProcuracao = 19,
  TelaCapaProcuracao = 20,

  TelaConcluirFase = 21,
  TelaCapaFase = 22,

  TelaCapaHonorario = 23,
  TelaCadastroHonorario = 24,

  TelaCapaReembolso = 25,
  TelaCadastroReembolso = 26,

  TelaCapaXpayFechamento = 27,
  TelaCadastroXpayFechamento = 28,

  TelaCapaPropostaAcordo = 35,
  TelaCadastroPropostaAcordo = 36,

  TelaCadastroObrigacao = 29,
  TelaCapaObrigacao = 30,

  TelaCadastroGarantia = 31,
  TelaCapaGarantia = 32,

  TelaCapaParteContraria = 43,
  TelaCadastroParteContraria = 44,

  TelaCadastroBloqueio = 38,
  TelaCapaBloqueio = 37,

  TelaCadastroDecisao = 34,
  TelaCapaDecisao = 33,

  TelaCapaDesdobramento = 39,
  TelaCadastroDesdobramento = 40,

  TelaCapaProtocolo = 49,
  TelaCadastroProtocolo = 50,

  TelaCadastroComunicacao = 52,
  TelaCapaComunicacao = 53,

  TelaCadastroAudiencia = 55,
  TelaCapaAudiencia = 56,

  TelaPesquisaPrincipal = 54,
  TelaPesquisaMaisFiltros = 57,

  TelaSeparadorCapa = 51,
  TelaSeparadorPesquisa = 58,

}

export const TelaCadastro = (modulo: EModulo): ETelaEnum => {
  switch (modulo) {
    case EModulo.AreaDireito:
      return ETelaEnum.TelaCadastroProcesso;
    case EModulo.Societario:
      return ETelaEnum.TelaCadastroAtoSocietario;
    case EModulo.Contrato:
      return ETelaEnum.TelaCadastroContrato;
    case EModulo.Consultivo:
      return ETelaEnum.TelaCadastroConsultivo;
    case EModulo.Imovel:
      return ETelaEnum.TelaCadastroImovel;
    case EModulo.MarcaEPertences:
      return ETelaEnum.TelaCadastroMarcasEPertences;
    case EModulo.Despesa:
      return ETelaEnum.TelaCadastroDespesa;
    case EModulo.AtoSocietario:
      return ETelaEnum.TelaCadastroAtoSocietario;
    case EModulo.Bloqueio:
      return ETelaEnum.TelaCadastroBloqueio;
    case EModulo.Compromisso:
      return ETelaEnum.TelaCadastroParteContraria;
    case EModulo.Decisao:
      return ETelaEnum.TelaCadastroDecisao;
    case EModulo.Desdobramento:
      return ETelaEnum.TelaCadastroDesdobramento;
    case EModulo.Fechamento:
      return ETelaEnum.TelaCadastroXpayFechamento;
    case EModulo.Procuracao:
      return ETelaEnum.TelaCadastroProcuracao;
    case EModulo.Garantia:
      return ETelaEnum.TelaCadastroGarantia;
    case EModulo.Obrigacao:
      return ETelaEnum.TelaCadastroObrigacao;
    case EModulo.ParteContraria:
      return ETelaEnum.TelaCadastroParteContraria;
    case EModulo.PropostaAcordo:
      return ETelaEnum.TelaCadastroPropostaAcordo;
    case EModulo.Regulatorio:
      return ETelaEnum.TelaCadastroRegulatorio;
    case EModulo.XpayHonorario:
      return ETelaEnum.TelaCadastroHonorario;
    case EModulo.XpayReembolso:
      return ETelaEnum.TelaCadastroReembolso;
    case EModulo.XpayFechamento:
      return ETelaEnum.TelaCadastroXpayFechamento;
    case EModulo.Audiencia:
      return ETelaEnum.TelaCadastroAudiencia;
    case EModulo.Protocolo:
      return ETelaEnum.TelaCadastroProtocolo;
    case EModulo.Comunicacao:
      return ETelaEnum.TelaCadastroComunicacao;
    default:
      return ETelaEnum.TelaCadastroProcesso;
  }
};

export const TelaCapa = (modulo: EModulo): ETelaEnum => {
  switch (modulo) {
    case EModulo.AreaDireito:
      return ETelaEnum.TelaCapaProcesso;
    case EModulo.Societario:
      return ETelaEnum.TelaCapaAtoSocietario;
    case EModulo.Contrato:
      return ETelaEnum.TelaCapaContrato;
    case EModulo.Consultivo:
      return ETelaEnum.TelaCapaConsultivo;
    case EModulo.Imovel:
      return ETelaEnum.TelaCapaImovel;
    case EModulo.MarcaEPertences:
      return ETelaEnum.TelaCapaMarcasEPertences;
    case EModulo.Despesa:
      return ETelaEnum.TelaCapaDespesa;
    case EModulo.AtoSocietario:
      return ETelaEnum.TelaCapaAtoSocietario;
    case EModulo.Bloqueio:
      return ETelaEnum.TelaCapaBloqueio;
    case EModulo.Compromisso:
      return ETelaEnum.TelaCapaParteContraria;
    case EModulo.Decisao:
      return ETelaEnum.TelaCapaDecisao;
    case EModulo.Desdobramento:
      return ETelaEnum.TelaCapaDesdobramento;
    case EModulo.Fechamento:
      return ETelaEnum.TelaCapaXpayFechamento;
    case EModulo.Procuracao:
      return ETelaEnum.TelaCapaProcuracao;
    case EModulo.Garantia:
      return ETelaEnum.TelaCapaGarantia;
    case EModulo.Obrigacao:
      return ETelaEnum.TelaCapaObrigacao;
    case EModulo.ParteContraria:
      return ETelaEnum.TelaCapaParteContraria;
    case EModulo.PropostaAcordo:
      return ETelaEnum.TelaCapaPropostaAcordo;
    case EModulo.Regulatorio:
      return ETelaEnum.TelaCapaRegulatorio;
    case EModulo.XpayHonorario:
      return ETelaEnum.TelaCapaHonorario;
    case EModulo.XpayReembolso:
      return ETelaEnum.TelaCapaReembolso;
    case EModulo.XpayFechamento:
      return ETelaEnum.TelaCapaXpayFechamento;
    case EModulo.Audiencia:
      return ETelaEnum.TelaCapaAudiencia;
    case EModulo.Protocolo:
      return ETelaEnum.TelaCapaProtocolo;
    case EModulo.Comunicacao:
      return ETelaEnum.TelaCapaComunicacao;
    default:
      return ETelaEnum.TelaCapaProcesso;
  }
};

  export const TelaFluxo = (modulo: EModulo, cadastroOuCapa: ETipoTelaEnum): ETelaEnum => {
    switch (modulo) {
      case EModulo.AreaDireito:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroProcesso : ETelaEnum.TelaCapaProcesso;
      case EModulo.Societario:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroAtoSocietario : ETelaEnum.TelaCapaAtoSocietario;
      case EModulo.Contrato:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroContrato : ETelaEnum.TelaCapaContrato;
      case EModulo.Consultivo:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroConsultivo : ETelaEnum.TelaCapaConsultivo;
      case EModulo.Imovel:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroImovel : ETelaEnum.TelaCapaImovel;
      case EModulo.MarcaEPertences:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroMarcasEPertences : ETelaEnum.TelaCapaMarcasEPertences;
      case EModulo.Despesa:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroDespesa : ETelaEnum.TelaCapaDespesa;
      case EModulo.AtoSocietario:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroAtoSocietario : ETelaEnum.TelaCapaAtoSocietario;
      case EModulo.Bloqueio:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroBloqueio : ETelaEnum.TelaCapaBloqueio;
      case EModulo.Compromisso:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroParteContraria : ETelaEnum.TelaCapaParteContraria;
      case EModulo.Decisao:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroDecisao : ETelaEnum.TelaCapaDecisao;
      case EModulo.Desdobramento:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroDesdobramento : ETelaEnum.TelaCapaDesdobramento;
      case EModulo.Fechamento:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroXpayFechamento : ETelaEnum.TelaCapaXpayFechamento;
      case EModulo.Procuracao:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroProcuracao : ETelaEnum.TelaCapaProcuracao;
      case EModulo.Garantia:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroGarantia : ETelaEnum.TelaCapaGarantia;
      case EModulo.Obrigacao:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroObrigacao : ETelaEnum.TelaCapaObrigacao;
      case EModulo.ParteContraria:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroParteContraria : ETelaEnum.TelaCapaParteContraria;
      case EModulo.PropostaAcordo:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroPropostaAcordo : ETelaEnum.TelaCapaPropostaAcordo;
      case EModulo.Regulatorio:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroRegulatorio : ETelaEnum.TelaCapaRegulatorio;
      case EModulo.XpayHonorario:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroHonorario : ETelaEnum.TelaCapaHonorario;
      case EModulo.XpayReembolso:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroReembolso : ETelaEnum.TelaCapaReembolso;
      case EModulo.XpayFechamento:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroXpayFechamento : ETelaEnum.TelaCapaXpayFechamento;
      case EModulo.Audiencia:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroAudiencia : ETelaEnum.TelaCapaAudiencia;
      case EModulo.Protocolo:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroProtocolo : ETelaEnum.TelaCapaProtocolo;
      case EModulo.Comunicacao:
        return cadastroOuCapa == ETipoTelaEnum.Cadastro ? ETelaEnum.TelaCadastroComunicacao : ETelaEnum.TelaCapaComunicacao;
      default:
        return ETelaEnum.TelaCadastroProcesso;
    }
  };
