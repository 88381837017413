/* eslint-disable @typescript-eslint/no-empty-function */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRecuperaConfiguracaoTelaResponse } from "../../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { notifySuccess } from "../../Toast/ToastFormik";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ETipoCampoEnum } from "../../../../enum";


interface IFormListTabelaFieldProps {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
}

const FormListFluxoTabelaField = ({
  configuracaoTela,
  formik
}: IFormListTabelaFieldProps) => {

  const linhas = configuracaoTela.capaCampoTabelaReadDto.linhas[0].colunas || [];

  // Calcula o número de linhas baseado no maior 'linhaIndex'
  const itens = Math.max(...linhas.map((c: any) => c.linhaIndex)) + 1;

  const Remover = async (linha: any) => {
    // Assumindo que `dados` é o array com o objeto que contém as colunas
    const linhas = configuracaoTela?.capaCampoTabelaReadDto?.linhas[0].colunas; // Extrai as linhas (colunas aqui se referem às células em várias linhas)

    // Filtra as colunas para remover as que têm o `linhaIndex` especificado
    const colunasAtualizadas = linhas.filter((coluna: any) => coluna.linhaIndex !== linha.linhaIndex);

    // Atualiza a estrutura de dados principal
    configuracaoTela.capaCampoTabelaReadDto.linhas = [{ ...configuracaoTela?.capaCampoTabelaReadDto?.linhas[0], colunas: colunasAtualizadas }];

    // Atualiza o campo no formulário Formik, certifique-se que o nome do campo é configurado corretamente
    formik.setFieldValue(configuracaoTela.campoNome?.toLowerCase(), [{ ...configuracaoTela?.capaCampoTabelaReadDto?.linhas[0], colunas: colunasAtualizadas }]);

    // Exibe uma notificação de sucesso
    notifySuccess("Linha excluída com sucesso.");
  }

  const Colunas = () => {
    const colunas = configuracaoTela?.capaCampoTabelaReadDto?.xCampoTabelaPropriedades;
    return colunas.map((coluna: any, index: number) => (
      <th style={{ textAlign: coluna?.tipo == ETipoCampoEnum.Valor ? 'right' : 'left', paddingRight: coluna?.tipo === ETipoCampoEnum.Valor ? '10px' : '0' }} key={index} className="text-orange">
        {coluna.label}
      </th>
    ));
  };

  const Capa = Array.from({ length: itens }, (_, rowIndex) => {
    const itens = linhas.filter((cell: any) => cell.linhaIndex === rowIndex);
    return (
      <tr key={rowIndex}>
        {itens.map((linha: any, index: number) => (
          <>
            <td className="text-orange" key={`${rowIndex}-${linha.colunaIndex}`}>{linha.texto}</td>
            {index === itens.length - 1 && ( // Condicional para adicionar ícone apenas na última célula
              <td style={{ textAlign: linha?.tipo == ETipoCampoEnum.Valor ? 'right' : 'left', paddingRight: linha?.tipo === ETipoCampoEnum.Valor ? '10px' : '0' }} key={`delete-${rowIndex}`} className="text-orange">
                <FontAwesomeIcon style={{ cursor: 'pointer' }} className="mx-4 text-orange" icon={faTrash} onClick={() => Remover(linha)} />
              </td>)}
          </>
        ))}

      </tr>
    );
  });

  return (
    <table className="table">
      <thead className="thead-dark">
        <tr>{Colunas()}</tr>
      </thead>

      <tbody>{Capa}</tbody>
    </table>
  );
}


export default FormListFluxoTabelaField;
