import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";

class TipoPropostaAcordoService {

    async obterTipoPropostaAcordos(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoPropostaAcordo/RecuperaTipoPropostaAcordo?${query}`);

        return result;
    }

    async obterListaLimitadaTipoPropostaAcordos(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoPropostaAcordo/RecuperaListaLimitada?${query}`);

        return result;
    }
}


export default new TipoPropostaAcordoService();