import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiMonitor, FiSettings, FiTrash } from 'react-icons/fi';
import { faPlus, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Handle, Position, useReactFlow, useStore } from 'reactflow';
import { useNavigate } from 'react-router-dom';
import { IoMdMove } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import Select from "react-select";
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import './styles/NodeFase.css';
import { ETelaEnum } from '../../../../../enum';

import { OrangeContext } from '../../../../../contexts/OrangeProvider';

import IOption from '../../../../../interfaces/IOption';
import { ICondicao } from '../../../../../interfaces/IFase';


import FaseSLA from '../../../../../services/FaseSLA';
import CamposSlaService from '../../../../../services/CamposSLA';
import TabelaOrigemService from '../../../../../services/TabelaOrigem';
import GruposUsuariosService from '../../../../../services/GruposUsuariosService';
import TipoCompromissoService from '../../../../../services/TipoCompromissoService';
import TipoResponsavelService from '../../../../../services/TipoResponsavelService';
import sessionStorageService from '../../../../../services/sessionStorage/sessionStorageService';
import ENodeEnum from '../../../../../enum/ENodeEnum';

const connectionNodeIdSelector = (state: any) => state.connectionNodeId;

export default function NodeFase({ id, data }: { id: any, data: any }) {

    const navigate = useNavigate();

    const { getNodes } = useReactFlow();

    const { fluxoFase, setFluxoFase, setTela } = useContext(OrangeContext);

    const [exibirFaseModal, setExibirFaseModal] = useState(false);

    const connectionNodeId = useStore<any>(connectionNodeIdSelector);

    const isConnecting = !!connectionNodeId;
    const isTarget = connectionNodeId && connectionNodeId !== id;

    const toggleFaseModal = () => setExibirFaseModal(!exibirFaseModal);

    const toggleConfiguracaoTelaModal = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            heightAuto: false,
            customClass: {
                confirmButton: "btn btn-orange",
                cancelButton: "btn btn-danger ms-5",
            },
            buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
            title: "Configuração de Tela",
            html: "Qualquer alteração não salva no fluxo será perdida, deseja continuar?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
        });

        if (result.isConfirmed) {

            setTela(ETelaEnum.TelaConcluirFase);

            setFluxoFase({
                ...fluxoFase,
                descricao: data?.nome,
                fluxoFaseId: data.fluxosFaseId
            });

            navigate('/Fluxo/ConfiguracaoTela/Cadastro');
        }
    }

    const renderCampos = () => {

        const customStyles = {
            control: (provided: any) => ({
                ...provided,
                borderWidth: '1px',
                borderColor: '#ced4da',
                boxShadow: 'none',
                width: '280px',
                '&:hover': {
                    border: 'none',
                    borderColor: '#ced4da',
                },
            }),
        };

        const [tiposSla, setTiposSla] = useState<IOption[]>([]);
        const [campoSla, setCampoSla] = useState<IOption[]>([]);
        const [tabelaOrigem, setTabelaOrigem] = useState<IOption[]>([]);
        const [gruposUsuarios, setGruposUsuarios] = useState<IOption[]>([]);
        const [tiposCompromisso, setTiposCompromisso] = useState<IOption[]>([]);
        const [tiposResponsavel, setTiposResponsavel] = useState<IOption[]>([]);

        const [novoCompromisso, setNovoCompromisso] = useState("");
        const [exibirModalTipoCompromisso, setExibirModalTipoCompromisso] = useState(false);

        const toggleModalTipoCompromisso = () => setExibirModalTipoCompromisso(!exibirModalTipoCompromisso);
        const onDisable = () => getNodes()?.some(node => node.data?.faseInicial && node.data?.id !== id);

        const initialFase = {
            id: id,
            nome: data?.nome,
            codigo: data.codigo || id,
            observacao: data?.observacao,
            faseInicial: data?.faseInicial,
            fluxosFaseId: data.fluxosFaseId,
            tipoCompromissoId: data?.tipoCompromissoId,
            sla: {
                campoId: data.sla?.campoId,
                tipoFaseSLA: data.sla?.tipoFaseSLA,
                valorFaseSLA: data.sla?.valorFaseSLA,
                tabelaOrigemId: data.sla?.tabelaOrigemId,
                tabelaOrigemNome: data.sla?.tabelaOrigemNome,
            },
            condicoes: data.condicoes || [],
            responsavel: {
                grupoId: data.responsavel?.grupoId,
                fluxosFaseId: data.responsavel?.fluxosFasesId,
                tipoResponsavelFase: data.responsavel?.tipoResponsavelFase,
            }
        };

        const schema = Yup.object({
            tipoCompromissoId: Yup.number().required('Tipo de compromisso é obrigatório'),
            nome: Yup.string().required('Nome é obrigatório'),
            responsavel: Yup.object({
                grupoId: Yup.number().when('tipoResponsavelFase', {
                    is: 'GrupoUsuarios',
                    then: Yup.number().min(1, 'Grupo de usuários é obrigatório').required('Grupo de usuários é obrigatório')
                }),
                tipoResponsavelFase: Yup.string().required('Tipo de Responsável é obrigatório'),
            }),
            sla: Yup.object({
                valorFaseSLA: Yup.number().required('Valor do SLA é obrigatório'),
                tabelaOrigemId: Yup.number().required('Tabela de Origem é obrigatório'),
                campoId: Yup.number().required('Campo é obrigatório'),
                tipoFaseSLA: Yup.string().required('Tipo de SLA é obrigatório'),
            }),
        });

        const formik = useFormik({
            initialValues: initialFase,
            validationSchema: schema,
            onSubmit: async (values, { setSubmitting }) => {
                try {

                    atualizar("atualizaNode", JSON.stringify({ data: values, id: id }));

                } catch (error: any) {
                    Swal.fire({
                        heightAuto: false,
                        icon: "error",
                        title: "Não foi possível salvar esta solicitação",
                        text: error.response?.data?.Message || "Erro desconhecido",
                        showConfirmButton: true,
                    });
                } finally {
                    setSubmitting(false);
                    toggleFaseModal();
                }
            },
        });

        useEffect(() => {
            if (exibirFaseModal) {

                carregarTiposSLA();

                carregarTabelaOrigem();

                carregarGruposUsuarios();

                carregarTiposCompromisso();

                carregarTiposResponsavel();

                formik.setValues(initialFase);

            }
        }, [exibirFaseModal]);

        useEffect(() => {
            if (formik.values.sla?.tabelaOrigemId && exibirFaseModal)
                carregaCampoSLA(formik.values.sla.tabelaOrigemNome);

        }, [formik.values.sla?.tabelaOrigemId, exibirFaseModal]);

        const carregaCampoSLA = async (tabelaOrigem: any) => {
            try {

                let options: IOption[] = [];

                const resultados: any = await CamposSlaService.obter({
                    tabelaOrigem: tabelaOrigem,
                    status: 1,
                    limit: 100,
                    offset: 0,
                    sort: "nome",
                });

                resultados.data.map((item: any) => options.push({ label: item.nome, value: item.campo }));

                setCampoSla(options);

            } catch (error) {
                setCampoSla([]);
            }
        };

        const carregarTiposCompromisso = async () => {
            try {

                let options: IOption[] = [];

                const resultados: any = await TipoCompromissoService.obter({
                    status: 1,
                    limit: 1000,
                    offset: 0,
                    sort: "nome",
                });

                resultados.data.map((item: any) => options.push({ label: item.nome, value: item.tipoCompromissoId }));

                setTiposCompromisso(options);

            } catch (error) {
                setTiposCompromisso([]);
            }
        };

        const carregarTiposResponsavel = async () => {
            try {
                let options: IOption[] = [];

                const resultados: any = await TipoResponsavelService.obter({
                    status: 1,
                    limit: 100,
                    offset: 0,
                    sort: "nome",
                });

                resultados.map((item: string) => options.push({ label: item, value: item }));

                setTiposResponsavel(options);

            } catch (error) {
                setTiposResponsavel([]);
            }
        };

        const carregarGruposUsuarios = async () => {
            try {

                let options: IOption[] = [];

                const resultados: any = await GruposUsuariosService.obter({
                    status: 1,
                    limit: 100,
                    offset: 0,
                    sort: "nome",
                });

                resultados.data.map((item: any) => options.push({ label: item.nome, value: item.grupoId }));

                setGruposUsuarios(options);

            } catch (error) {
                setGruposUsuarios([]);
            }
        };

        const carregarTiposSLA = async () => {
            try {

                let options: IOption[] = [];

                const resultados: any = await FaseSLA.obter({
                    status: 1,
                    limit: 100,
                    offset: 0,
                    sort: "nome",
                });

                resultados.map((item: string) => options.push({ label: item, value: item }));

                setTiposSla(options);

            } catch (error) {
                setTiposSla([]);
            }
        };

        const carregarTabelaOrigem = async () => {
            try {

                let options: IOption[] = [];

                const resultados: any = await TabelaOrigemService.obter({
                    status: 1,
                    limit: 100,
                    offset: 0,
                    sort: "nome",
                });

                resultados.data.map((item: any) => options.push({ label: item.nome, value: item.tabelaOrigemId }));

                setTabelaOrigem(options);
            } catch (error) {
                setTabelaOrigem([]);
            }
        };

        const renderTipoCompromisso = () => (
            <Modal size="lg" centered show={exibirModalTipoCompromisso} onHide={toggleModalTipoCompromisso}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-orange">Adicionar novo Tipo de Compromisso</h5>
                        <div onClick={toggleFaseModal} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="col-md-auto mb-10">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome tipo compromisso</label>
                            <input
                                type="text"
                                value={novoCompromisso}
                                onChange={(e) => setNovoCompromisso(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="modal-footer" style={{ margin: "0 auto" }}>
                        <button
                            onClick={toggleFaseModal}
                            type="button"
                            className="btn btn-danger ms-5"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={async () => {
                                await TipoCompromissoService.alterar(novoCompromisso, 2);
                                setExibirModalTipoCompromisso(false);
                                carregarTiposCompromisso();
                            }}
                            type="button"
                            className="btn btn-orange ms-5"
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </Modal>
        );


        return (
            <>
                {exibirModalTipoCompromisso && renderTipoCompromisso()}

                <Modal size="lg" centered show={exibirFaseModal} onHide={toggleFaseModal}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-orange">
                                #{formik.values.fluxosFaseId} Configuração da Fase
                            </h5>
                            <div onClick={toggleFaseModal} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                                <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="row">

                                    <div className="col-6 mt-3">
                                        <label htmlFor="form-tipoCompromisso" className="form-label fw-bolder text-orange">
                                            Tipo de compromisso:
                                        </label>

                                        <div className="input-group">
                                            <Select
                                                value={{
                                                    label: tiposCompromisso.find((e) => e.value === formik.values.tipoCompromissoId)?.label,
                                                    value: formik.values.tipoCompromissoId,
                                                }}
                                                options={tiposCompromisso}
                                                onChange={(option: any) => formik.setFieldValue("tipoCompromissoId", parseInt(option.value))}
                                                styles={customStyles}
                                            />

                                            <button
                                                onClick={() => setExibirModalTipoCompromisso(true)}
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", color: "white", fontWeight: '1000' }}
                                            >
                                                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
                                            </button>
                                        </div>
                                        {formik.touched.tipoCompromissoId && formik.errors.tipoCompromissoId && (
                                            <div className='fv-plugins-message-container mt-1'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.tipoCompromissoId}</span>
                                                </div>
                                            </div>
                                        )}

                                        <div className="form-check mt-3">
                                            <input
                                                type="checkbox"
                                                id="form-faseinicial"
                                                disabled={onDisable()}
                                                {...formik.getFieldProps('faseInicial')}
                                                checked={formik.values.faseInicial}
                                                className="form-check-input"
                                            />
                                            <label htmlFor="form-faseinicial" className="form-check-label fw-bolder text-orange">
                                                Deseja definir como fase inicial?
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                                    <input
                                        type="text"
                                        {...formik.getFieldProps('nome')}
                                        className="form-control" />
                                    {formik.touched.nome && formik.errors.nome && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.nome}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="form-responsavel" className="form-label fw-bolder text-orange">Tipo Responsável:</label>

                                    <Select
                                        value={{
                                            label: tiposResponsavel.find((e) => e.value === formik.values.responsavel?.tipoResponsavelFase)?.label,
                                            value: formik.values.responsavel?.tipoResponsavelFase,
                                        }}
                                        options={tiposResponsavel}
                                        onChange={(option: any) => formik.setFieldValue("responsavel.tipoResponsavelFase", option.value)}
                                    />
                                    {formik.touched.responsavel?.tipoResponsavelFase && formik.errors.responsavel?.tipoResponsavelFase && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.responsavel?.tipoResponsavelFase}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {formik.values.responsavel?.tipoResponsavelFase == "GrupoUsuarios" &&
                                    <div className="col-12">
                                        <label htmlFor="form-grupoUsuarios" className="form-label fw-bolder text-orange">Grupo usuários:</label>
                                        <Select
                                            value={{
                                                label: gruposUsuarios.find((e) => e.value === formik.values.responsavel?.grupoId)?.label,
                                                value: formik.values.responsavel?.grupoId,
                                            }}
                                            options={gruposUsuarios}
                                            onChange={(option: any) => formik.setFieldValue("responsavel.grupoId", parseInt(option.value))}
                                        />
                                        {formik.touched.responsavel?.grupoId && formik.errors.responsavel?.grupoId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.responsavel?.grupoId}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>}

                                <div className="col-12">
                                    <label htmlFor="form-observacao" className="form-label fw-bolder text-orange">Observações:</label>
                                    <textarea
                                        {...formik.getFieldProps('observacao')}
                                        className="form-control" />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="form-tipoFaseSLA" className="form-label fw-bolder text-orange">Tipo SLA:</label>
                                    <Select
                                        value={{
                                            label: tiposSla.find((e) => e.value === formik.values.sla?.tipoFaseSLA)?.label,
                                            value: formik.values.sla?.tipoFaseSLA,
                                        }}
                                        options={tiposSla}
                                        onChange={(option: any) => formik.setFieldValue("sla.tipoFaseSLA", option.value)}
                                    />
                                    {formik.touched.sla?.tipoFaseSLA && formik.errors.sla?.tipoFaseSLA && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.sla?.tipoFaseSLA}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="form-tabelaOrigemId" className="form-label fw-bolder text-orange">Tabela de Origem:</label>
                                    <Select
                                        value={{
                                            label: tabelaOrigem.find((e) => e.value === formik.values.sla?.tabelaOrigemId)?.label,
                                            value: formik.values.sla?.tabelaOrigemId,
                                        }}
                                        options={tabelaOrigem}
                                        onChange={(option: any) => {
                                            formik.setFieldValue("sla.tabelaOrigemId", option.value);
                                            formik.setFieldValue("sla.tabelaOrigemNome", option.label);
                                        }}
                                    />
                                    {formik.touched?.sla?.tabelaOrigemId && formik.errors.sla?.tabelaOrigemId && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors?.sla.tabelaOrigemId}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {formik.values.sla.tabelaOrigemId > 0 &&
                                    <div className="col-6">
                                        <label htmlFor="form-campoSla" className="form-label fw-bolder text-orange">Campo para contagem do SLA:</label>
                                        <Select
                                            value={{
                                                label: campoSla.find((e) => e.value === formik.values.sla?.campoId)?.label,
                                                value: formik.values.sla?.campoId,
                                            }}
                                            options={campoSla}
                                            onChange={(option: any) => formik.setFieldValue("sla.campoId", parseInt(option.value))}
                                        />
                                        {formik.touched.sla?.campoId && formik.errors.sla?.campoId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.sla?.campoId}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }

                                <div className="col-6">
                                    <label htmlFor="form-sla" className="form-label fw-bolder text-orange">SLA:</label>
                                    <input
                                        type="number"
                                        {...formik.getFieldProps('sla.valorFaseSLA')}
                                        className="form-control" />
                                    {formik.touched.sla?.valorFaseSLA && formik.errors.sla?.valorFaseSLA && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.sla?.valorFaseSLA}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer mt-3" style={{ margin: "0 auto" }}>

                            <button
                                onClick={formik.submitForm}
                                disabled={!formik.isValid}
                                type="button"
                                className="btn btn-orange ms-5"
                            >
                                Adicionar
                            </button>

                            <button
                                onClick={toggleFaseModal}
                                type="button"
                                className="btn btn-danger ms-5"
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Modal >
            </>
        );
    };

    const atualizar = (chave: any, valor: any) => {

        sessionStorageService.inserir(chave, valor);

        const evento = new CustomEvent('localStorageAtualizado', { detail: { chave, valor } });

        window.dispatchEvent(evento);
    };

    const excluir = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            heightAuto: false,
            customClass: {
                confirmButton: "btn btn-orange",
                cancelButton: "btn btn-danger ms-5",
            },
            buttonsStyling: false,
        });

        const result = await swalWithBootstrapButtons.fire({
            title: "Excluir",
            html: "Você realmente deseja excluir?<br>Qualquer ligação com esta fase ou condição também será removida.",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
        });

        if (result.isConfirmed) {

            getNodes().forEach((node) => {

                if (node.data?.condicoes && node.type == ENodeEnum.NodeCondicao) {

                    node.data.condicoes = node.data.condicoes.filter((condicao: ICondicao) => !condicao?.codigoAnteriorFaseId?.includes(id) && !condicao?.codigoProximaFaseId?.includes(id));

                    atualizar("atualizaNode", JSON.stringify({ data: node.data, id: node.id }));
                }
            });

            atualizar("apagaNode", JSON.stringify({ data, id }));

        }
    };

    return (
        <>
            {renderCampos()}

            <div className={data.faseInicial ? "nodeFaseInicial" : "nodeFase"}>
                <div style={{ display: 'flex', flexDirection: 'row', zIndex: '-1' }}>
                    <div className={data.faseInicial ? "nodeFaseIconInicial" : "nodeFaseIcon"} style={{ cursor: 'move' }}>
                        <IoMdMove />
                    </div>
                    <div className={data.faseInicial ? "nodeFaseIconInicial" : "nodeFaseIcon"} onClick={toggleFaseModal}>
                        <FiSettings />
                    </div>
                    <div style={{ display: data.fluxosFaseId ? 'initial' : 'none' }} className={data.faseInicial ? "nodeFaseIconInicial" : "nodeFaseIcon"} onClick={toggleConfiguracaoTelaModal}>
                        <FiMonitor />
                    </div>
                    <div className={data.faseInicial ? "nodeFaseIconInicial" : "nodeFaseIcon"} onClick={() => excluir()}>
                        <FiTrash />
                    </div>
                </div>

                <div
                    className="nodeFaseBody"
                    style={{
                        borderStyle: isTarget ? 'dashed' : 'solid',
                        backgroundColor: isTarget ? '#ffcce3' : '#fff5e1',
                        position: "relative"
                    }}
                >
                    {!isConnecting && (
                        <Handle className="customHandle" position={Position.Right} type="source" />
                    )}
                    <div>
                        <Handle
                            className="customHandle"
                            position={Position.Left}
                            type="target"
                            isConnectableStart={false}
                        />
                    </div>

                    <div className="nodeFaseContet">
                        {!isTarget && (
                            <>
                                {typeof data.nome === 'string' ? (
                                    <>
                                        <p>{data.nome} {data.faseInicial && <FontAwesomeIcon color={"#7b40a3"} className="mx-1" icon={faStar} />}</p>
                                    </>
                                ) : (
                                    <p>Clique para editar</p>
                                )}
                                {data?.sla?.valorFaseSLA && (
                                    <>
                                        <p>SLA - {data.sla.valorFaseSLA}</p>
                                    </>
                                )}
                            </>
                        )}

                        {isTarget && (
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{
                                    width: "20px",
                                    height: "5px",
                                }} /><p>...</p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    );
}

