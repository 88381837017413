import { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import clsx from "clsx";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ETipoIntegracaoXSignerEnum } from '../../../enum/ETipoIntegracaoXSignerEnum';

import EnvelopeService from '../../../services/EnvelopeService';

import IEnvelope from '../../../interfaces/IEnvelope';
import IRecuperaLista from '../../../interfaces/IRecuperaLista';
import ISignatarioPapel from '../../../interfaces/ISignatarioPapel';
import IEnvelopeSignatario from '../../../interfaces/IEnvelopeSignatario';

import EnvelopeSignatarioModal from './EnvelopeSignatarioModal';
import Spinner from '../../../components/Spinner';

export default function SignatarioForm(props: {
    envelope: IEnvelope; signatarioEditar?: IEnvelopeSignatario;

    adicionarSignatario: (signatario: IEnvelopeSignatario) => void;

    setSignatarioEditar: (value: IEnvelopeSignatario | undefined) => void;
}) {

    const [papeisSignatario, setpapeisSignatario] = useState<ISignatarioPapel[]>([]);

    const [d4SignMetodoAutenticacao, setD4SignMetodosAutenticacao] = useState<string[]>([]);

    const [plataformas, setPlataformas] = useState<any[]>([]);

    const [d4Sign, setD4Sign] = useState<boolean>(false);

    const [carregamentoPapel, setCarregamentoPapel] = useState<boolean>(false);

    const initialValues = {
        nome: '',
        email: '',
        ordemAssinatura: -1,
        papelSignatarioId: 0,
        papelSignatarioNome: '',
        plataforma: (props?.envelope?.plataforma || ETipoIntegracaoXSignerEnum.xsigner),
        codigo: props.envelope?.codigo,
        depoisDeId: props.envelope.signatarios[0]?.signatarioId || 0,
        metodoAutenticacao: ''
    };

    useEffect(() => {
        carregarD4SignMetodosAutenticacao();
        carregarPlataformas();
        carregarPapeisSignatario();
        setD4Sign(props?.envelope?.plataforma == ETipoIntegracaoXSignerEnum.d4signer);
    }, []);

    const carregarPapeisSignatario = async (): Promise<void> => {
        setCarregamentoPapel(true);
        const resultado = await EnvelopeService.obterPapeisSignatarioPorPlataforma(formik.values.plataforma);
        setpapeisSignatario(resultado);
        setCarregamentoPapel(false);
    };

    const carregarD4SignMetodosAutenticacao = async (): Promise<void> => {
        const resultado = await EnvelopeService.obterD4SignMetodosAutenticacao();
        setD4SignMetodosAutenticacao(resultado.metodosAutenticacao);
    };

    const carregarPlataformas = async (): Promise<void> => {
        let resultado: IRecuperaLista<string[]>;
        resultado = await EnvelopeService.obterPlataformas();
        setPlataformas(resultado.data);
    };

    const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório').max(256, 'Máximo 256 caracteres'),
        email: Yup.string()
            .max(256, 'Máximo 256 caracteres')
            .required('Email é obrigatório')
            .email('Email inválido'),
        papelSignatarioId: Yup.number().required('Papel é obrigatório'),
        depoisDeId: Yup.number().test('depoisDeId', 'Campo obrigatório', (value) => {
            if (!props.envelope.envioPorOrdem) return true;
            return (props.envelope.signatarios.length === 0 || (value || 0) > 0);
        })
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting }) => {
            if (props.envelope.status === -2) {
                props.adicionarSignatario({
                    signatarioId: -1 - props.envelope.signatarios.length,
                    nome: values.nome,
                    email: values.email,
                    papelSignatarioId: values.papelSignatarioId,
                    papelSignatarioNome: values.papelSignatarioNome,
                    codigo: values.codigo,
                    ordemAssinatura: props.envelope.signatarios?.length || 0,
                    metodoAutenticacao: values.metodoAutenticacao,
                    plataformaDeAssinatura: values.plataforma
                });
                formik.resetForm();
                return;
            }

            let reordenar = false;
            let ordem = props.envelope.signatarios.length;

            if (props.envelope.envioPorOrdem && ordem > 0) {
                ordem = 0;
                props.envelope.signatarios.sort((a, b) => a.ordemAssinatura - b.ordemAssinatura).forEach((signatario, index) => {
                    if (signatario.signatarioId != values.depoisDeId) {
                        if (reordenar) {
                            props.envelope.signatarios[index].ordemAssinatura++;
                        } else {
                            ordem++;
                        }
                        return;
                    }
                    if (index + 1 < props.envelope.signatarios.length) {
                        reordenar = true;
                    }
                });
            }

            const dados: IEnvelopeSignatario = {
                signatarioId: -1 - props.envelope.signatarios.length,
                nome: values.nome,
                email: values.email,
                codigo: values.codigo,
                ordemAssinatura: ordem,

                plataformaDeAssinatura: values.plataforma,
                metodoAutenticacao: values.metodoAutenticacao,

                papelSignatarioId: values.papelSignatarioId,
                papelSignatarioNome: values.papelSignatarioNome,
            };

            try {
                Swal.fire({
                    heightAuto: false,
                    icon: 'info',
                    title: 'Adicionando signatário...',
                    showConfirmButton: false,
                });
                Swal.showLoading();

                if (dados.signatarioId && dados.signatarioId < 0) {
                    const signatario = await EnvelopeService.criarSignatario(dados, props.envelope.hashEnvelope);
                    props.adicionarSignatario(signatario);
                    if (reordenar) {
                        await EnvelopeService.atualizarSignatarios(props.envelope);
                    }
                } else {
                    await EnvelopeService.atualizarSignatarios(props.envelope);
                }

                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Signatário ${dados.signatarioId && dados.signatarioId > 0 ? 'editado' : 'criado'} com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                });
            } catch (error: any) {
                const indice = props.envelope.signatarios.indexOf(dados);
                props.envelope.signatarios = [
                    ...props.envelope.signatarios.splice(0, indice),
                    ...props.envelope.signatarios.splice(indice + 1)
                ];
                Swal.fire({
                    heightAuto: false,
                    icon: 'error',
                    title: `Não foi possível ${dados.signatarioId && dados.signatarioId > 0 ? 'editar' : 'criar'} o signatário`,
                    text: error && (error.response && error.response.data) ? typeof error.response.data === 'string' ? error.response.data : error.response.data.Message : error.message,
                    showConfirmButton: true
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    useEffect(() => {
        if (props?.envelope?.signatarios?.length == 0) {
            carregarPapeisSignatario();
        }
    }, [formik.values.plataforma]);

    return <>
        {props.signatarioEditar && papeisSignatario !== undefined &&
            <EnvelopeSignatarioModal
                signatario={props.signatarioEditar}
                exibirModal={props.signatarioEditar !== undefined}
                titulo="Editar Signatário"
                papeisSignatario={papeisSignatario}
                toggleModal={(signatario) => {
                    if (!signatario) {
                        return props.setSignatarioEditar(undefined);
                    }
                    props.envelope.signatarios = props.envelope.signatarios.map(item => {
                        if (item.signatarioId === signatario.signatarioId) {
                            return signatario;
                        }
                        return item;
                    });
                    props.setSignatarioEditar(undefined);
                }} />}

        {(plataformas?.length > 1 && props?.envelope?.signatarios?.length == 0 && !props.envelope.hashEnvelope) ?
            <div className="col-12 mb-3">
                <label htmlFor="form-data-papel" style={{ color: 'var(--primary-base)' }} className="form-label fw-bolder text-soft text-orange'">Plataforma:</label>
                <select
                    {...formik.getFieldProps('plataforma')}
                    onChange={(e) => {
                        formik.setFieldTouched("plataforma", true);
                        formik.setFieldValue("plataforma", e.target.value);
                        setD4Sign(e.target.value === 'd4sign');
                    }}
                    className={clsx(
                        "form-select",
                        {
                            'is-invalid': formik.touched.plataforma && formik.errors.plataforma
                        },
                        {
                            'is-valid': formik.touched.plataforma && !formik.errors.plataforma
                        })} id="form-data-papel"
                >{plataformas.map((item, index) => {
                    return <option key={index} value={item}>{item}</option>;
                })}
                </select>
                {formik.touched.plataforma && formik.errors.plataforma && (
                    <div className='fv-plugins-message-container mt-1'>
                        <div className='fv-help-block'>
                            <span role='alert' style={{ color: '#dc3545' }}>{formik.errors.plataforma}</span>
                        </div>
                    </div>
                )}
            </div> :
            <>
                <div className="col-12 mb-1">
                    <label htmlFor="form-data-nome" style={{ color: 'var(--primary-base)' }} className="form-label fw-bolder text-orange'">{d4Sign ? ' D4Sign' : ''}</label>
                </div>
            </>
        }

        <div className="col-12 mb-1">
            <label htmlFor="form-data-nome" style={{ color: 'var(--primary-base)' }} className="form-label fw-bolder text-orange'">Nome:</label>
            <input
                {...formik.getFieldProps('nome')}
                type="text"
                className={clsx(
                    "form-control mb-1",
                    {
                        'is-invalid': formik.touched.nome && formik.errors.nome
                    },
                    {
                        'is-valid': formik.touched.nome && !formik.errors.nome
                    })}
                placeholder="Insira o nome"
                id='form-data-nome' />
            {formik.touched.nome && formik.errors.nome && (
                <div className='fv-plugins-message-container mt-1'>
                    <div className='fv-help-block'>
                        <span role='alert' style={{ color: '#dc3545' }}>{formik.errors.nome}</span>
                    </div>
                </div>
            )}
        </div>

        <div className="col-12 mb-1">
            <label htmlFor="form-data-email" style={{ color: 'var(--primary-base)' }} className="form-label fw-bolder text-soft text-orange'">E-mail:</label>
            <input
                {...formik.getFieldProps('email')}
                type="email"
                className={clsx(
                    "form-control mb-1",
                    {
                        'is-invalid': formik.touched.email && formik.errors.email
                    },
                    {
                        'is-valid': formik.touched.email && !formik.errors.email
                    })}
                placeholder="Insira o e-mail"
                required={true}
                id="form-data-email" />
            {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container mt-1'>
                    <div className='fv-help-block'>
                        <span role='alert' style={{ color: '#dc3545' }}>{formik.errors.email}</span>
                    </div>
                </div>
            )}
        </div>

        <div className="col-12 mb-3">
            {carregamentoPapel ? <Spinner /> :
                <>
                    <label htmlFor="form-data-papel" style={{ color: 'var(--primary-base)' }} className="form-label fw-bolder text-soft text-orange'">Papel:</label>
                    <select
                        placeholder="Papel"
                        value={formik.values.papelSignatarioId}
                        onChange={(event: any) => {
                            formik.setFieldValue("papelSignatarioId", event.target.value);
                            formik.setFieldValue("papelSignatarioNome", event.target[event.target.selectedIndex]?.text);
                        }}
                        className={clsx(
                            "form-select",
                            {
                                'is-invalid': formik.touched.papelSignatarioId && formik.errors.papelSignatarioId
                            },
                            {
                                'is-valid': formik.touched.papelSignatarioId && !formik.errors.papelSignatarioId
                            })} id="form-data-papel"
                    >
                         <option key={0} value={0}>Selecione</option>
                        {papeisSignatario && papeisSignatario.map((item, index) => {
                        return <option key={index} value={item.papelSignatarioId}>{item.nome}</option>;
                    })}
                    </select>
                    {formik.touched.papelSignatarioId && formik.errors.papelSignatarioId && (
                        <div className='fv-plugins-message-container mt-1'>
                            <div className='fv-help-block'>
                                <span role='alert' style={{ color: '#dc3545' }}>{formik.errors.papelSignatarioId}</span>
                            </div>
                        </div>
                    )}
                </>}
        </div>

        {(d4Sign) &&
            <div className="col-12 mb-3">
                <label htmlFor="form-data-papel" style={{ color: 'var(--primary-base)' }} className="form-label fw-bolder text-soft text-orange'">Método de Autenticação:</label>
                <select
                    {...formik.getFieldProps('metodoAutenticacao')}
                    placeholder="Metodo Autenticacao"
                    onChange={(e) => {
                        formik.setFieldTouched("metodoAutenticacao", true);
                        formik.setFieldValue("metodoAutenticacao", e.target.value);
                    }}
                    className={clsx(
                        "form-select",
                        {
                            'is-invalid': formik.touched.metodoAutenticacao && formik.errors.metodoAutenticacao
                        },
                        {
                            'is-valid': formik.touched.metodoAutenticacao && !formik.errors.metodoAutenticacao
                        })} id="form-data-papel"
                >{d4SignMetodoAutenticacao.map((item, index) => {
                    return <option key={index} value={item}>{item}</option>;
                })}
                </select>
                {formik.touched.metodoAutenticacao && formik.errors.metodoAutenticacao && (
                    <div className='fv-plugins-message-container mt-1'>
                        <div className='fv-help-block'>
                            <span role='alert' style={{ color: '#dc3545' }}>{formik.errors.metodoAutenticacao}</span>
                        </div>
                    </div>
                )}
            </div>
        }

        {(props.envelope !== null && props.envelope?.status === 2 && props.envelope.envioPorOrdem === true) && (props.envelope?.signatarios || []).length > 0 &&
            <div className="col-12 mb-3">
                <label htmlFor="form-data-depois-de" className="form-label fw-bolder text-soft">Depois de</label>
                <select
                    {...formik.getFieldProps('depoisDeId')}
                    placeholder="Adicionar depois de"
                    id="form-data-depois-de"
                    defaultValue={'0'}
                    className={clsx(
                        "form-select",
                        {
                            'is-invalid': formik.touched.depoisDeId && formik.errors.depoisDeId
                        },
                        {
                            'is-valid': formik.touched.depoisDeId && !formik.errors.depoisDeId
                        })}
                >
                    <option value="0">
                        -- Selecione um signatário --
                    </option>
                    {[
                        ...props.envelope.signatarios.sort((a, b) => a.ordemAssinatura - b.ordemAssinatura).filter(s => s.status === 2).slice(-1),
                        ...props.envelope.signatarios.sort((a, b) => a.ordemAssinatura - b.ordemAssinatura).filter(s => s.status === 1)
                    ].map((item, index) => {
                        return <option key={index} value={item.signatarioId}>{item.nome}</option>;
                    })}
                </select>
                {formik.touched.depoisDeId && formik.errors.depoisDeId && (
                    <div className='fv-plugins-message-container mt-1'>
                        <div className='fv-help-block'>
                            <span role='alert' style={{ color: '#dc3545' }}>{formik.errors.depoisDeId}</span>
                        </div>
                    </div>
                )}
            </div>}

        <div className="col-12 mb-4">
            <button
                className="btn h-100 float-right btn-orange"
                type="button"
                onClick={() => formik.submitForm()}
            > Adicionar <FontAwesomeIcon className='mx-2' icon={faPlus} /></button>
        </div>
    </>;
}
