import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IAmbito from "../interfaces/IAmbito";
import { AdicionaAmbitoRequest } from "../interfaces/Requests/Ambito/AdicionaAmbitoRequest";
import FiltroPesquisaAmbitoRequest from "../interfaces/Requests/Ambito/FiltroPesquisaAmbitoRequest";
import { EditarAmbitoRequest } from "../interfaces/Requests/Ambito/EditarAmbitoRequest";

class AmbitoService {

    async obterAmbitos(filtros: FiltroPesquisaAmbitoRequest): Promise<IRecuperaLista<IAmbito>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IAmbito>>(`Ambito/RecuperaAmbito?${query}`);

        return result;
    }

    async adicionaAmbito(adicionaAmbito: AdicionaAmbitoRequest): Promise<IAmbito> {
        let { data: result } = await api.post<IAmbito>(`Ambito/AdicionaAmbito`, adicionaAmbito);

        return result;
    }


    async alterarStatus(ambitoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IAmbito>(`Ambito/AtualizaAmbitoPorId?id=${ambitoId}`, data);
    }

    async atualizarAmbito(editarAmbito: EditarAmbitoRequest): Promise<void> {

        let dataAmbito = [
            { "op": "replace", "path": "/nome", "value": editarAmbito.nome },
            { "op": "replace", "path": "/codigo", "value": editarAmbito.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Ambito/AtualizaAmbitoPorId?id=${editarAmbito.ambitoId}`, dataAmbito)
        ]);
    }
}


export default new AmbitoService();