import { Editor } from "../../components/Editor"
import { VersionList } from "../../components/Editor/components/VersionList"

const EditorHistoryPage = () => {
  return (
    <Editor>
      <VersionList />
    </Editor>
  )
}

export default EditorHistoryPage