import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoSociedade from "../interfaces/ITipoSociedade";
import { AdicionaTipoSociedadeRequest } from "../interfaces/Requests/TipoSociedade/AdicionaTipoSociedadeRequest";
import FiltroPesquisaTipoSociedadeRequest from "../interfaces/Requests/TipoSociedade/FiltroPesquisaTipoSociedadeRequest";
import { EditarTipoSociedadeRequest } from "../interfaces/Requests/TipoSociedade/EditarTipoSociedadeRequest";

class TipoSociedadeService {

    async obterTipoSociedades(filtros: FiltroPesquisaTipoSociedadeRequest): Promise<IRecuperaLista<ITipoSociedade>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoSociedade>>(`TipoSociedade/RecuperaTipoSociedade?${query}`);

        return result;
    }

    async adicionaTipoSociedade(adicionaTipoSociedade: AdicionaTipoSociedadeRequest): Promise<ITipoSociedade> {
        let { data: result } = await api.post<ITipoSociedade>(`TipoSociedade/AdicionaTipoSociedade`, adicionaTipoSociedade);

        return result;
    }


    async alterarStatus(tipoSociedadeId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoSociedade>(`TipoSociedade/AtualizaTipoSociedadePorId?id=${tipoSociedadeId}`, data);
    }

    async atualizarTipoSociedade(editarTipoSociedade: EditarTipoSociedadeRequest): Promise<void> {

        let dataTipoSociedade = [
            { "op": "replace", "path": "/nome", "value": editarTipoSociedade.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoSociedade.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoSociedade/AtualizaTipoSociedadePorId?id=${editarTipoSociedade.tipoSociedadeId}`, dataTipoSociedade)
        ]);
    }
}


export default new TipoSociedadeService();