import { ToastContainer } from "react-toastify";
import { WordProcessor } from "../../components/Editor/components/WordProcessor";
import { DrawerProvider } from "../../components/Editor/components/Drawer/context";
import { TitleProvider } from "../../components/Editor/components/WordProcessor/contexts/TitleContext";
import { ModeProvider } from "../../components/Editor/components/WordProcessor/contexts/ModeContext";

const NovoEditorPage = () => {
  return (
    <DrawerProvider>
      <ToastContainer />
      <TitleProvider>
        <ModeProvider>
          <WordProcessor />
        </ModeProvider>
      </TitleProvider>
    </DrawerProvider>
  );
};

export default NovoEditorPage;
