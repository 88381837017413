/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// React and related hooks
import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// External libraries and components
import { faEraser, faFile, faFileExcel, faFilter, faList, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import { MultiSelect } from "react-multi-select-component";
import { OverlayTrigger } from "react-bootstrap";

// Internal components and services
import GridPadrao from "../../../components/Comum/GridPadrao";
import ContratoService from "../../../services/ContratoService";
import TipoContratoService from "../../../services/TipoContratoService";
import ContratoFiltroModal from "../Modal/FiltroModal";
import RelatorioPadraoModal from "../Modal/RelatorioPadraoModal";
import { PopoverSubMenuImovel } from "../../../components/Comum/Popover/PopoverSubMenuImovel";
import LayoutPageButton from "../../../layout/LayoutPageButton";
import { OrangeContext } from "../../../contexts/OrangeProvider";

// Interfaces and types
import FiltroPesquisaContratoRequest from "../../../interfaces/Requests/Contrato/FiltroPesquisaContratoRequest";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import IContrato, { IContratoPartesContraria } from "../../../interfaces/IContrato";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import ITipoContrato from "../../../interfaces/ITipoContrato";

// Styles
import "../style/icons.css";


const PastaImovelPage = () => {
  const [contratos, setContratos] = useState<IContrato[]>([]);
  const [carregandoContratos, setCarregandoContratos] = useState(false);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);

  const [carregamentoTipoContrato, setCarregamentoTipoContrato] = useState<boolean>(false);
  const [tiposContratosOptions, setTiposContratosOptions] = useState<any>([]);
  const [tipoContratoSelected, setTipoContratoSelected] = useState([]);
  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);

  const optionsStatusContrato = [
    { label: "Em Elaboração", value: "1" },
    { label: "Em Aprovação", value: "2" },
    { label: "Em Assinatura", value: "3" },
    { label: "Assinado", value: "4" },
    { label: "Cancelado", value: "5" },
  ];

  const [selectedStatusContrato, setSelectedStatusContrato] = useState<any>([]);

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    setFiltrosPesquisa({ ...filtrosPesquisa, statusContrato: IdsSelected });

  };

  const { contrato, setContrato, setTipoContrato } = useContext<OrangeInterfaceContext>(OrangeContext);

  const filtroInicial: FiltroPesquisaContratoRequest = {
    contratoId: 0,
    pastaId: 132,

    numeroContrato: "",
    objetoContrato: "",
    observacoesContrato: "",
    codigo: "",
    parteContraria: "",

    urgente: false,
    isNovaPasta: false,

    statusContrato: [],
    statusPasta: [],
    tipoContratoId: [],
    periodicidadeId: [],
    departamentoId: [],
    gestorId: [],
    moedaId: [],
    indiceId: [],
    usuarioIdCadastro: [],
    urgenteFilter: [],

    dataInicioVigenciaMaiorIgual: "",
    dataInicioVigenciaMenorIgual: "",

    dataFimVigenciaMaiorIgual: "",
    dataFimVigenciaMenorIgual: "",

    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",

    dataAprovacaoMaiorIgual: "",
    dataAprovacaoMenorIgual: "",

    dataAssinaturaMaiorIgual: "",
    dataAssinaturaMenorIgual: "",

    dataCanceladoMaiorIgual: "",
    dataCanceladoMenorIgual: "",

    valorTotalMaiorIgual: 0,
    valorTotalMenorIgual: 0,

    offset: 0,
    limit: 10,
    sort: "contratoId",
    totalItems: 0,
    modeloRelatorioId: 0

  };

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaContratoRequest>(filtroInicial);

  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);


  const navigate = useNavigate();

  const componentRef = useRef<any>();

  function toggleModal(): void {
    setExibirFormModal(!exibirFormModal);
  }

  useLayoutEffect(() => {
    carregarTipoContrato();
  }, []);

  const returnOptionsTiposContratos = (array: any) => {
    array.forEach((item: any) => {
      tiposContratosOptions.push({
        value: item.tipoContratoId,
        label: item.nome,
      });
    });
    return setTiposContratosOptions;
  };

  function formatarData(data: string): string {
    const dataRet = data || ''
    if (dataRet.includes('T')) {
      const [ano, mes, dia] = dataRet.split('T')[0].split('-')
      return `${dia}/${mes}/${ano}`
    }
    return '-'
  }

  function nomearStatusContrato(status: string): string {
    switch (status) {
      case "EmElaboracao":
        return 'Em Elaboração';
      case "EmAprovacao":
        return 'Em Aprovação';
      case "EmAssinatura":
        return 'Em Assinatura';
      case "Assinado":
        return 'Assinado';
      case "Cancelado":
        return 'Cancelado';
      default:
        return 'Não definido';
    }
  }

  function nomearStatusPasta(status: string): string {
    switch (status) {
      case "EmElaboracao":
        return 'Em Elaboração';
      default:
        return status;
    }
  }

  async function carregarTipoContrato() {
    try {
      setCarregamentoTipoContrato(true);
      let resultadoTipoContrato: IRecuperaLista<ITipoContrato>;
      resultadoTipoContrato = await TipoContratoService.obterTipoContratos({
        nome: "",
        codigo: "",
        status: 1,
        categoria: 0,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoContratoId",
      });
      setTiposContratosOptions(returnOptionsTiposContratos(resultadoTipoContrato.data));
      setCarregamentoTipoContrato(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoContrato(false);
      setTiposContratosOptions([]);
    }
  }

  async function carregarContratos(filtro: FiltroPesquisaContratoRequest) {
    try {
      setCarregandoContratos(true);

      let resultado: IRecuperaLista<IContrato>;
      resultado = await ContratoService.obterContratos(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setContratos(resultado.data);
      setCarregandoContratos(false);
      setPesquisar(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setContratos([]);
    } finally {
      setCarregandoContratos(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  useEffect(() => {
    setPesquisar(true);

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  const montarParteContraria = (partes: IContratoPartesContraria[]) => {
    let partesString = "";

    partes.forEach((parte) => {
      if (parte.principal) {
        partesString += parte.nome + " - " + parte.papelSignatarioNome;
      }
    });
    return partesString;
  };

  const colunas: TableColumn<IContrato>[] = [
    {
      cell: (contrato: IContrato) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setContrato(contrato);
                  setTipoContrato({
                    tipoContratoId: contrato.tipoContratoId,
                    nome: contrato.tipoContratoNome,
                    categoria: 0,
                    checked: false,
                    codigo: "",
                    status: "",
                  });
                  navigate("/Contrato/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Contrato: {contrato.numeroContrato} - {montarParteContraria(contrato.contratoParteContraria)}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{nomearStatusContrato(contrato.statusContrato)}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {106}
                {contrato.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(contrato.favoritoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(106)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Contrato: </b> {contrato.tipoContratoNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Vigência: </b>
                {formatarData(contrato.dataInicioVigencia === null ? "" : contrato.dataInicioVigencia.toString().toString())}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}> até </b>
                {formatarData(contrato.dataFimVigencia === null ? "" : contrato.dataFimVigencia.toString())}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status da Pasta: </b> {nomearStatusPasta(contrato.statusPasta)}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Departamento Solicitante: </b> {contrato.departamentoNome}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IContrato>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    if (pesquisar) carregarContratos(filtrosPesquisa);
  }, [pesquisar]);

  async function adicionarFavorito(contratoId: number) {
    try {
      await ContratoService.adicionaFavorido(contratoId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Contrato adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarContratos(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar contrato como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }


  async function removerFavorido(favoritoId: number) {
    try {
      await ContratoService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Contrato removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarContratos(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoContratoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, tipoContratoId: IdsSelected };
    });
  }, [tipoContratoSelected]);




  return (
    <>

      <LayoutPageButton
        title={`Pastas de contratos do imóvel`}
      >

        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuImovel()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageButton>

      <ContratoFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <RelatorioPadraoModal
        exibirModal={exibirConfirmarModeloModal}
        toggleModal={toggleConfimarModeloModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12" >
            <form onSubmit={(e) => e.preventDefault()}
              className="row g-3 mb-3 form-buscar-contrato">

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-contratoId" className="form-label fw-bolder text-orange">
                  ID do Instrumento:
                </label>
                <input
                  value={filtrosPesquisa.contratoId}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, contratoId: e.target.value };
                    });
                  }}
                  placeholder="ID"
                  type="number"
                  className={"form-control"}
                  id="form-contratoId"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-numeroContrato" className="form-label fw-bolder text-orange">
                  Número do Contrato:
                </label>
                <input
                  value={filtrosPesquisa.numeroContrato}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, numeroContrato: e.target.value };
                    });
                  }}
                  placeholder="Número do Contrato"
                  type="text"
                  className={"form-control"}
                  id="form-numeroContrato"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Tipo Contrato:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoContrato}
                  disabled={carregamentoTipoContrato}
                  options={tiposContratosOptions}
                  value={tipoContratoSelected}
                  onChange={(event: any) => setTipoContratoSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                  Parte:
                </label>
                <input
                  value={filtrosPesquisa.parteContraria}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, parteContraria: e.target.value };
                    });
                  }}
                  placeholder="Parte"
                  type="text"
                  className={"form-control"}
                  id="form-parteContraria"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status do Instrumento:
                </label>
                <MultiSelect
                  options={optionsStatusContrato}
                  value={selectedStatusContrato}
                  onChange={(event: any) => {
                    setSelectedStatusContrato(event);
                    setIdsSelect("statusContrato", event);
                  }}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12 "
                style={{ textAlign: 'right' }}>
                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    setFiltrosPesquisa(filtroInicial);
                    setPesquisar(false);
                    setTipoContratoSelected([]);
                    setSelectedStatusContrato([]);
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <button
                  onClick={() => toggleConfimarModeloModal()}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  disabled={carregandoContratos}>
                  <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                </button>

                <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

              </div>
            </form>

            <div className="col-md-12 mb-10" style={{ overflow: !carregandoContratos ? "auto" : "scroll", height: "600px" }}>

              <div ref={componentRef}>
                <GridPadrao
                  onSort={handleSort}
                  progressPending={carregandoContratos}
                  limit={filtrosPesquisa.limit}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationServer={true}
                  paginationTotalRows={filtrosPesquisa.totalItems}
                  colunas={colunas}
                  tipo="Contratos"
                  itens={contratos}
                />
              </div>
            </div>
          </div>
        </div>
      </div >

    </>
  );
};
export default PastaImovelPage;
