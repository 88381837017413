import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusCitacao from "../interfaces/IStatusCitacao";
import { AdicionaStatusCitacaoRequest } from "../interfaces/Requests/StatusCitacao/AdicionaStatusCitacaoRequest";
import FiltroPesquisaStatusCitacaoRequest from "../interfaces/Requests/StatusCitacao/FiltroPesquisaStatusCitacaoRequest";
import { EditarStatusCitacaoRequest } from "../interfaces/Requests/StatusCitacao/EditarStatusCitacaoRequest";

class StatusCitacaoService {

    async obterStatusCitacaos(filtros: FiltroPesquisaStatusCitacaoRequest): Promise<IRecuperaLista<IStatusCitacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusCitacao>>(`StatusCitacao/RecuperaStatusCitacao?${query}`);

        return result;
    }

    async adicionaStatusCitacao(adicionaStatusCitacao: AdicionaStatusCitacaoRequest): Promise<IStatusCitacao> {
        let { data: result } = await api.post<IStatusCitacao>(`StatusCitacao/AdicionaStatusCitacao`, adicionaStatusCitacao);

        return result;
    }


    async alterarStatus(statusCitacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusCitacao>(`StatusCitacao/AtualizaStatusCitacaoPorId?id=${statusCitacaoId}`, data);
    }

    async atualizarStatusCitacao(editarStatusCitacao: EditarStatusCitacaoRequest): Promise<void> {

        let dataStatusCitacao = [
            { "op": "replace", "path": "/nome", "value": editarStatusCitacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusCitacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusCitacao/AtualizaStatusCitacaoPorId?id=${editarStatusCitacao.statusCitacaoId}`, dataStatusCitacao)
        ]);
    }
}


export default new StatusCitacaoService();