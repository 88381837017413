import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";

interface IPreviewPDFFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  url: string;
}

const PreviewPDFField = ({ toggleModal, exibirModal, url }: IPreviewPDFFieldProps) => {
  return (
    <Modal size="xl" fullscreen={'xl-down'} centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Pré Visualização - <FontAwesomeIcon className="mx-2 text-orange" icon={faFilePdf} />
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <iframe src={url} style={{ width: "100%", height: "740px"}} frameBorder="0"></iframe>
      </div>
    </Modal>);
};

export default PreviewPDFField;
