/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";

import ImovelService from "../../../services/ImovelService";
import LicencaService from "../../../services/LicencaService";
import DespesaService from "../../../services/DespesaService";
import DecisaoService from "../../../services/DecisaoService";
import ContratoService from "../../../services/ContratoService";
import ConsultaService from "../../../services/ConsultaService";
import ProcessoService from "../../../services/ProcessoService";
import GarantiaService from "../../../services/GarantiaService";
import BloqueioService from "../../../services/BloqueioService";
import HonorarioService from "../../../services/HonorarioService";
import AudienciaService from "../../../services/AudienciaService";
import ReembolsoService from "../../../services/ReembolsoService";
import ObrigacaoService from "../../../services/ObrigacaoService";
import ProcuracaoService from "../../../services/ProcuracaoService";
import ComunicacaoService from "../../../services/ComunicacaoService";
import DesdobramentoService from "../../../services/DesdobramentoService";
import PropostaAcordoService from "../../../services/PropostaAcordoService";

import IDespesa from "../../../interfaces/IDespesa";
import IDecisao from "../../../interfaces/IDecisao";
import IGarantia from "../../../interfaces/IGarantia";
import IBloqueio from "../../../interfaces/IBloqueio";
import IConsulta from "../../../interfaces/IConsulta";
import IProcesso from "../../../interfaces/IProcesso";
import IContrato from "../../../interfaces/IContrato";
import IHonorario from "../../../interfaces/IHonorario";
import IReembolso from "../../../interfaces/IReembolso";
import IObrigacao from "../../../interfaces/IObrigacao";
import IAudiencia from "../../../interfaces/IAudiencia";
import IComunicacao from "../../../interfaces/IComunicacao";
import ICompromisso from "../../../interfaces/ICompromisso";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IDesdobramento from "../../../interfaces/IDesdobramento";
import IPropostaAcordo from "../../../interfaces/IPropostaAcordo";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import { EModulo } from "../../../enum";

import { OrangeContext } from "../../../contexts/OrangeProvider";

import ImovelFluxoModal from "../../../pages/FluxoPage/ImovelFluxoModal";
import ProcessoFluxoModal from "../../../pages/FluxoPage/ProcessoFluxoModal";
import ContratoFluxoModal from "../../../pages/FluxoPage/ContratoFluxoModal";
import ConsultivoFluxoModal from "../../../pages/FluxoPage/ConsultivoFluxoModal";
import ProcuracaoFluxoModal from "../../../pages/FluxoPage/ProcuracaoFluxoModal";
import RegulatorioFluxoModal from "../../../pages/FluxoPage/RegulatorioFluxoModal";

import DecisaoFluxoModal from "../../../pages/FluxoPage/SolucoesPage/DecisaoFluxoModal";
import DespesaFluxoModal from "../../../pages/FluxoPage/SolucoesPage/DespesaFluxoModal";
import GarantiaFluxoModal from "../../../pages/FluxoPage/SolucoesPage/GarantiaFluxoModal";
import BloqueioFluxoModal from "../../../pages/FluxoPage/SolucoesPage/BloqueioFluxoModal";
import ObrigacaoFluxoModal from "../../../pages/FluxoPage/SolucoesPage/ObrigacaoFluxoModal";
import DesdobramentoFluxoModal from "../../../pages/FluxoPage/SolucoesPage/DesdobramentoFluxoModal";
import PropostaAcordoFluxoModal from "../../../pages/FluxoPage/SolucoesPage/PropostaAcordoFluxoModal";

import {
    bloqueioInicialValues,
    decisaoInicialValues,
    despesaInicialValues,
    desdobramentoInicialValues,
    garantiaInicialValues,
    obrigacaoInicialValues,
    propostaAcordoInicialValues,

    processoInitialValues,
    contratoInitialValues,
    procuracaoInitialValues,
    imovelInitialValues,
    licencaInitialValues,
    consultaInitialValues,
    comunicacaoInitialValues,
    reembolsoInicialValues,
    honorarioInicialValues,
    audienciaInicialValues

} from "../../../contexts/InitialValuesContext";




import CompromissoConcluirField from "../../Comum/Compromisso/CompromissoConcluirField";
import ComunicacaoFluxoModal from "../../../pages/FluxoPage/SolucoesPage/ComunicacaoFluxoModal";
import ReembolsoFluxoModal from "../../../pages/FluxoPage/SolucoesPage/ReembolsoFluxoModal";
import HonorarioFluxoModal from "../../../pages/FluxoPage/SolucoesPage/HonorarioFluxoModal";
import AudienciaFluxoModal from "../../../pages/FluxoPage/SolucoesPage/AudienciaFluxoModal";

interface IFluxoModalProps {
    exibirModal: boolean;
    setExibirModal(exibir: boolean): void;
    compromisso: ICompromisso;
    setRefresh(salvando: boolean): void;
}

const FluxoModal = ({ compromisso, setRefresh, exibirModal, setExibirModal }: IFluxoModalProps) => {

    const {

        setProcesso,
        setContrato,
        setImovel,
        setProcuracao,
        setDespesa,
        setLicenca,
        setConsulta,

        setObrigacao,
        setBloqueio,
        setDecisao,
        setGarantia,
        setDesdobramento,
        setPropostaAcordo,
        setComunicacao,
        setReembolso,
        setHonorario,
        setAudiencia,
    } = useContext<OrangeInterfaceContext>(OrangeContext);

    const [exibirModalProcessoConcluir, setExibirModalProcessoConcluir] = useState<boolean>(false);

    const [exibirModalConsultaConcluir, setExibirModalConsultaConcluir] = useState<boolean>(false);

    const [exibirModalContratoConcluir, setExibirModalContratoConcluir] = useState<boolean>(false);

    const [exibirModalProcuracaoConcluir, setExibirModalProcuracaoConcluir] = useState<boolean>(false);

    const [exibirModalImovelConcluir, setExibirModalImovelConcluir] = useState<boolean>(false);

    const [exibirModalDespesaConcluir, setExibirModalDespesaConcluir] = useState<boolean>(false);

    const [exibirModalObrigacaoConcluir, setExibirModalObrigacaoConcluir] = useState<boolean>(false);

    const [exibirModalBloqueioConcluir, setExibirModalBloqueioConcluir] = useState<boolean>(false);

    const [exibirModalDecisaoConcluir, setExibirModalDecisaoConcluir] = useState<boolean>(false);

    const [exibirModalGarantiaConcluir, setExibirModalGarantiaConcluir] = useState<boolean>(false);

    const [exibirModalDesdobramentoConcluir, setExibirModalDesdobramentoConcluir] = useState<boolean>(false);

    const [exibirModalPropostaAcordoConcluir, setExibirModalPropostaAcordoConcluir] = useState<boolean>(false);

    const [exibirModalRegulatorioConcluir, setExibirModalRegulatorioConcluir] = useState<boolean>(false);

    const [exibirModalComunicacaoConcluir, setExibirModalComunicacaoConcluir] = useState<boolean>(false);

    const [exibirModalReembolsoConcluir, setExibirModalReembolsoConcluir] = useState<boolean>(false);

    const [exibirModalHonorarioConcluir, setExibirModalHonorarioConcluir] = useState<boolean>(false);

    const [exibirModalAudienciaConcluir, setExibirModalAudienciaConcluir] = useState<boolean>(false);

    const [exibirModalCompromissoConcluir, setExibirModalCompromissoConcluir] = useState<boolean>(false);

    const [filtros] = useState<any>({
        compromissoId: 0,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: "",
    });

    const toggleExibirContratoConcluir = () => setExibirModalContratoConcluir(!exibirModalContratoConcluir);

    const toggleExibirProcessoConcluir = () => setExibirModalProcessoConcluir(!exibirModalProcessoConcluir);

    const toggleExibirImovelConcluir = () => setExibirModalImovelConcluir(!exibirModalImovelConcluir);

    const toggleExibirProcuracaoConcluir = () => setExibirModalProcuracaoConcluir(!exibirModalProcuracaoConcluir);

    const toggleExibirDespesaConcluir = () => setExibirModalDespesaConcluir(!exibirModalDespesaConcluir);

    const toggleExibirObrigacaoConcluir = () => setExibirModalObrigacaoConcluir(!exibirModalObrigacaoConcluir);

    const toggleExibirBloqueioConcluir = () => setExibirModalBloqueioConcluir(!exibirModalBloqueioConcluir);

    const toggleExibirDecisaoConcluir = () => setExibirModalDecisaoConcluir(!exibirModalDecisaoConcluir);

    const toggleExibirGarantiaConcluir = () => setExibirModalGarantiaConcluir(!exibirModalGarantiaConcluir);

    const toggleExibirDesdobramentoConcluir = () => setExibirModalDesdobramentoConcluir(!exibirModalDesdobramentoConcluir);

    const toggleExibirPropostaAcordoConcluir = () => setExibirModalPropostaAcordoConcluir(!exibirModalPropostaAcordoConcluir);

    const toggleExibirRegulatorioConcluir = () => setExibirModalRegulatorioConcluir(!exibirModalRegulatorioConcluir);

    const toggleExibirConsultaConcluir = () => setExibirModalConsultaConcluir(!exibirModalConsultaConcluir);

    const toggleExibirComunicacaoConcluir = () => setExibirModalComunicacaoConcluir(!exibirModalComunicacaoConcluir);

    const toggleExibirReembolsoConcluir = () => setExibirModalReembolsoConcluir(!exibirModalReembolsoConcluir);

    const toggleExibirHonorarioConcluir = () => setExibirModalHonorarioConcluir(!exibirModalHonorarioConcluir);

    const toggleExibirAudienciaConcluir = () => setExibirModalAudienciaConcluir(!exibirModalAudienciaConcluir);

    const toggleExibirCompromissoConcluir = () => setExibirModalCompromissoConcluir(!exibirModalCompromissoConcluir);

    useEffect(() => {

        if (exibirModal) {
            switch (compromisso?.modulo) {

                case EModulo.AreaDireito:
                    iniciarProcesso();
                    break;

                case EModulo.Contrato:
                    iniciarContrato();
                    break;

                case EModulo.Procuracao:
                    iniciarProcuracao();
                    break;


                case EModulo.Regulatorio:
                    iniciarRegulatorio();
                    break;

                case EModulo.Imovel:
                    iniciarImovel();
                    break;

                case EModulo.Consultivo:
                    iniciarConsulta();
                    break;

                case EModulo.Despesa:
                    iniciarDespesa();
                    break;

                case EModulo.Obrigacao:
                    iniciarObrigacao();
                    break;

                case EModulo.Bloqueio:
                    iniciarBloqueio();
                    break;

                case EModulo.Decisao:
                    iniciarDecisao();
                    break;

                case EModulo.Garantia:
                    iniciarGarantia();
                    break;

                case EModulo.Desdobramento:
                    iniciarDesdobramento();
                    break;

                case EModulo.PropostaAcordo:
                    iniciarPropostaAcordo();
                    break;

                case EModulo.Comunicacao:
                    iniciarComunicacao();
                    break;

                case EModulo.XpayReembolso:
                    iniciarReembolso();
                    break;

                case EModulo.XpayHonorario:
                    iniciarHonorario();
                    break;

                case EModulo.Audiencia:
                    iniciarAudiencia();
                    break;

                default:
                    toggleExibirCompromissoConcluir();
                    break;
            }
            setExibirModal(false);
        }

    }, [exibirModal]);




    const iniciarContrato = async () => {
        try {
            if (compromisso.contratoId) {
                let resultado: IRecuperaLista<IContrato>;
                resultado = await ContratoService.obterContratoPorId(compromisso.contratoId);
                setContrato(resultado.data[0]);
                toggleExibirContratoConcluir();
            }

        } catch (error: any) {
            setContrato(contratoInitialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarProcesso = async () => {
        try {

            if (compromisso.processoId) {
                let resultado: IRecuperaLista<IProcesso>;
                resultado = await ProcessoService.obterProcessoPorId(compromisso.processoId);
                setProcesso(resultado.data[0]);
                toggleExibirProcessoConcluir();
            }

        } catch (error: any) {
            setProcesso(processoInitialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarReembolso = async () => {
        try {

            if (compromisso.reembolsoId) {
                let resultado: IRecuperaLista<IReembolso>;
                resultado = await ReembolsoService.obterReembolsoPorId(compromisso.reembolsoId);
                setReembolso(resultado.data[0]);
                toggleExibirReembolsoConcluir();
            }

        } catch (error: any) {
            setReembolso(reembolsoInicialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarHonorario = async () => {
        try {

            if (compromisso.honorarioId) {
                let resultado: IRecuperaLista<IHonorario>;
                resultado = await HonorarioService.obterHonorarioPorId(compromisso.honorarioId);
                setHonorario(resultado.data[0]);
                toggleExibirHonorarioConcluir();
            }

        } catch (error: any) {
            setHonorario(honorarioInicialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarAudiencia = async () => {
        try {

            if (compromisso.audienciaId) {
                let resultado: IRecuperaLista<IAudiencia>;
                resultado = await AudienciaService.obterAudienciaPorId(compromisso.audienciaId);
                setAudiencia(resultado.data[0]);
                toggleExibirAudienciaConcluir();
            }

        } catch (error: any) {
            setAudiencia(audienciaInicialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarProcuracao = async () => {
        try {

            if (compromisso.procuracaoId) {
                let resultado: IRecuperaLista<IProcuracao>;
                resultado = await ProcuracaoService.obterProcuracaoPorId(compromisso.procuracaoId);
                setProcuracao(resultado.data[0]);
                toggleExibirProcuracaoConcluir();
            }

        } catch (error: any) {
            setProcuracao(procuracaoInitialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarRegulatorio = async () => {
        try {

            if (compromisso.licencaId) {
                let resultado: IRecuperaLista<ILicenca>;
                resultado = await LicencaService.obterLicencaPorId(compromisso.licencaId);
                setLicenca(resultado.data[0]);
                toggleExibirRegulatorioConcluir();
            }

        } catch (error: any) {
            setLicenca(licencaInitialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarImovel = async () => {
        try {

            if (compromisso.imovelId) {
                let resultado: IRecuperaLista<IImovel>;
                resultado = await ImovelService.obterImovelPorId(compromisso.imovelId);
                setImovel(resultado.data[0]);
                toggleExibirImovelConcluir();
            }

        } catch (error: any) {
            setImovel(imovelInitialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarConsulta = async () => {
        try {

            if (compromisso.consultaId) {
                let resultado: IRecuperaLista<IConsulta>;
                resultado = await ConsultaService.obterConsultaPorId(compromisso.consultaId);
                setConsulta(resultado.data[0]);
                toggleExibirConsultaConcluir();
            }

        } catch (error: any) {
            setConsulta(consultaInitialValues);
        } finally {
            setExibirModal(false);

        }
    };

    const iniciarDespesa = async () => {
        try {
            if (compromisso.despesaId) {
                let resultado: IRecuperaLista<IDespesa>;
                resultado = await DespesaService.obterDespesaPorId(compromisso.despesaId);
                setDespesa(resultado.data[0]);
                toggleExibirDespesaConcluir();
            }
        } catch (error: any) {
            setDespesa(despesaInicialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarObrigacao = async () => {
        try {
            let resultado: IRecuperaLista<IObrigacao>;
            resultado = await ObrigacaoService.obterObrigacoes({ ...filtros, obrigacaoId: compromisso.obrigacaoId });
            setObrigacao(resultado.data[0]);
            toggleExibirObrigacaoConcluir();
        } catch (error: any) {
            setObrigacao(obrigacaoInicialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarBloqueio = async () => {
        try {
            let resultado: IRecuperaLista<IBloqueio>;
            resultado = await BloqueioService.obterBloqueios({ ...filtros, bloqueioId: compromisso.bloqueioId });
            setBloqueio(resultado.data[0]);
            toggleExibirBloqueioConcluir();
        } catch (error: any) {
            setBloqueio(bloqueioInicialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarComunicacao = async () => {
        try {

            let resultado: IRecuperaLista<IComunicacao>;
            resultado = await ComunicacaoService.obterComunicacoes({ ...filtros, comunicacaoId: compromisso.comunicacaoId });
            setComunicacao(resultado.data[0]);
            toggleExibirComunicacaoConcluir();
        } catch (error: any) {
            setComunicacao(comunicacaoInitialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarDecisao = async () => {
        try {
            let resultado: IRecuperaLista<IDecisao>;
            resultado = await DecisaoService.obterDecisoes({ ...filtros, decisaoId: compromisso.decisaoId });
            setDecisao(resultado.data[0]);
            toggleExibirDecisaoConcluir();
        } catch (error: any) {
            setDecisao(decisaoInicialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarGarantia = async () => {
        try {
            let resultado: IRecuperaLista<IGarantia>;
            resultado = await GarantiaService.obterGarantias({ ...filtros, garantiaId: compromisso.garantiaId });
            setGarantia(resultado.data[0]);
            toggleExibirGarantiaConcluir();
        } catch (error: any) {
            setGarantia(garantiaInicialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarPropostaAcordo = async () => {
        try {
            let resultado: IRecuperaLista<IPropostaAcordo>;
            resultado = await PropostaAcordoService.obterPropostasAcordo({ ...filtros, propostaAcordoId: compromisso.propostaAcordoId });
            setPropostaAcordo(resultado.data[0]);
            toggleExibirPropostaAcordoConcluir();
        } catch (error: any) {
            setPropostaAcordo(propostaAcordoInicialValues);
        } finally {
            setExibirModal(false);
        }
    };

    const iniciarDesdobramento = async () => {
        try {
            let resultado: IRecuperaLista<IDesdobramento>;
            resultado = await DesdobramentoService.obterDesdobramentos({ ...filtros, desdobramentoId: compromisso.desdobramentoId });
            setDesdobramento(resultado.data[0]);
            toggleExibirDesdobramentoConcluir();
        } catch (error: any) {
            setDesdobramento(desdobramentoInicialValues);
        }
    };

    return (
        <>

            {compromisso?.modulo == EModulo.Compromisso &&
                <CompromissoConcluirField
                    exibirModal={exibirModalCompromissoConcluir}
                    toggleModal={toggleExibirCompromissoConcluir}
                    compromissoId={compromisso.compromissoId}
                    tipoCompromissoId={compromisso.tipoCompromissoId}
                    setAtualizarTabelaCompromisso={setRefresh}
                />}


            {compromisso?.modulo == EModulo.AreaDireito &&
                <ProcessoFluxoModal
                    exibirModal={exibirModalProcessoConcluir}
                    toggleModal={toggleExibirProcessoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}


            {compromisso?.modulo == EModulo.Contrato &&
                <ContratoFluxoModal
                    exibirModal={exibirModalContratoConcluir}
                    toggleModal={toggleExibirContratoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}


            {compromisso?.modulo == EModulo.Consultivo &&
                <ConsultivoFluxoModal
                    exibirModal={exibirModalConsultaConcluir}
                    toggleModal={toggleExibirConsultaConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Procuracao &&
                <ProcuracaoFluxoModal
                    exibirModal={exibirModalProcuracaoConcluir}
                    toggleModal={toggleExibirProcuracaoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}


            {compromisso?.modulo == EModulo.Imovel &&
                <ImovelFluxoModal
                    exibirModal={exibirModalImovelConcluir}
                    toggleModal={toggleExibirImovelConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Regulatorio &&
                <RegulatorioFluxoModal
                    exibirModal={exibirModalRegulatorioConcluir}
                    toggleModal={toggleExibirRegulatorioConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}


            {compromisso?.modulo == EModulo.Despesa &&
                <DespesaFluxoModal
                    exibirModal={exibirModalDespesaConcluir}
                    toggleModal={toggleExibirDespesaConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}


            {compromisso?.modulo == EModulo.Decisao &&
                <DecisaoFluxoModal
                    exibirModal={exibirModalDecisaoConcluir}
                    toggleModal={toggleExibirDecisaoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Obrigacao &&
                <ObrigacaoFluxoModal
                    exibirModal={exibirModalObrigacaoConcluir}
                    toggleModal={toggleExibirObrigacaoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.XpayReembolso &&
                <ReembolsoFluxoModal
                    exibirModal={exibirModalReembolsoConcluir}
                    toggleModal={toggleExibirReembolsoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.XpayHonorario &&
                <HonorarioFluxoModal
                    exibirModal={exibirModalHonorarioConcluir}
                    toggleModal={toggleExibirHonorarioConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Bloqueio &&
                <BloqueioFluxoModal
                    exibirModal={exibirModalBloqueioConcluir}
                    toggleModal={toggleExibirBloqueioConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Decisao &&
                <DecisaoFluxoModal
                    exibirModal={exibirModalBloqueioConcluir}
                    toggleModal={toggleExibirBloqueioConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.PropostaAcordo &&
                <PropostaAcordoFluxoModal
                    exibirModal={exibirModalPropostaAcordoConcluir}
                    toggleModal={toggleExibirPropostaAcordoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Garantia &&
                <GarantiaFluxoModal
                    exibirModal={exibirModalGarantiaConcluir}
                    toggleModal={toggleExibirGarantiaConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Desdobramento &&
                <DesdobramentoFluxoModal
                    exibirModal={exibirModalDesdobramentoConcluir}
                    toggleModal={toggleExibirDesdobramentoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Comunicacao &&
                <ComunicacaoFluxoModal
                    exibirModal={exibirModalComunicacaoConcluir}
                    toggleModal={toggleExibirComunicacaoConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

            {compromisso?.modulo == EModulo.Audiencia &&
                <AudienciaFluxoModal
                    exibirModal={exibirModalAudienciaConcluir}
                    toggleModal={toggleExibirAudienciaConcluir}
                    compromisso={compromisso}
                    setRefresh={setRefresh}
                    capa={compromisso.status == "Concluido"}
                />}

        </>
    );
}
export default FluxoModal;
