import clsx from "clsx";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import { useState } from "react";
import { optionsType } from "../../../components/Comum/Fornecedor/FornecedorField";
import ModeloDocumentoService from "../../../services/ModeloDocumentoService";

interface IModeloDocumentoMAddModal {
  exibirModal: boolean;
  handleClose: () => void;
  modulos: optionsType[];
}

const ModeloDocumentoAddModal = ({
  exibirModal,
  handleClose,
  modulos,
}: IModeloDocumentoMAddModal) => {
  const [modulo, setModulo] = useState<optionsType>();

  const validarFormPermissoes = Yup.object().shape({
    nome: Yup.string()
      .max(50, "Máximo 50 caracteres")
      .required("O Nome é obrigatório."),
    modulo: Yup.number()
      .required("O módulo é obrigatório")
      .min(1, "O módulo é obrigatório"),
    codigo: Yup.string().max(50, "Máximo 50 caracteres").optional(),
  });

  const formik = useFormik({
    initialValues: {
      nome: "",
      modulo: 0,
      codigo: "",
    },
    validationSchema: validarFormPermissoes,
    onSubmit: async (values, { resetForm }) => {
      try {
        await ModeloDocumentoService.adicionarModelo(values);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Modelo de Documento cadastrado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possível cadastrar o Modelo de Documento`,
          text: "Ocorreu um erro inesperado.",
          showConfirmButton: true,
        });
      } finally {
        resetForm();
        setModulo(undefined);
        handleClose();
      }
    },
  });

  return (
    <Modal size="lg" centered={true} show={exibirModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Adicionar Modelo de Documento
          </h5>
        </div>
        <div className="modal-body">
          <form className="col">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder text-orange"
              >
                Nome
              </label>
              <input
                {...formik.getFieldProps("nome")}
                placeholder="Nome"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.nome && formik.errors.nome,
                  },
                  {
                    "is-valid": formik.touched.nome && !formik.errors.nome,
                  }
                )}
                id="form-nome"
              />
              {formik.touched.nome && formik.errors.nome && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.nome}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder text-orange"
              >
                Código:
              </label>

              <input
                {...formik.getFieldProps("codigo")}
                placeholder="Código"
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.codigo && formik.errors.codigo,
                  },
                  {
                    "is-valid": formik.touched.codigo && !formik.errors.codigo,
                  }
                )}
                id="form-codigo"
              />

              {formik.touched.codigo && formik.errors.codigo && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.codigo}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 mb-3">
              <label
                htmlFor="form-nome"
                className="form-label required fw-bolder text-orange"
              >
                Módulo:
              </label>

              <Select
                placeholder="Selecione um módulo"
                id="form-select"
                options={modulos}
                isSearchable
                value={modulo}
                onChange={(select) => {
                  if (select) {
                    setModulo(select);
                    formik.setFieldValue("modulo", select.value);
                  }
                }}
              />

              {formik.touched.modulo && formik.errors.modulo && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.modulo}</span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => {
              formik.submitForm();
            }}
            type="button"
            className="btn btn-orange me-5"
          >
            SALVAR
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              setModulo(undefined);
              handleClose();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModeloDocumentoAddModal;
