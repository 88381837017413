import styled from "styled-components";

export const Container = styled.div`
  min-height: 200px;
  min-width: 250px;
  border-top: 3px solid #3B86C2;
`
export const Interligacao = styled.div`
  margin-left: 30px;
  height: 30px;
  margin-top: -3px;
  border-left: 3px solid #3B86C2;
  border-radius: 8px 0;
`
export const NomeEmpresa = styled.div`
  border-radius: 40px;
  background: #EBF4F9;
  border: 3px solid #3B86C2;
  text-align: center;
  font-size: 12px;
  height: 40px;
  min-width: 80%;
  max-width: 80%;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 20px;
  margin-left: 30px;
  border-left: 3px solid #3B86C2;
`
export const Section = styled.div`
  border-radius: 0;
  margin: 20px 0;

  > header {
    display: flex;
    align-items: center;

    > button {
      cursor: pointer;
      margin-left: -2px;
      border: none;
      background: transparent;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 6px;
      border-bottom: 3px solid #3B86C2;
      border-radius: 0 0 0 8px;

      > svg {
        font-size: 20px;
      }
    }
  
    > strong {
      font-size: 14px;
    }
  }

  > div {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    border-left: 3px solid #3B86C2;
    border-radius: 0 0 0 8px;


    > div {
      padding: 5px 10px;
      margin: 5px 2px 0 -3px;
      border-bottom: 3px solid #3B86C2;
      border-radius: 0 0 0 8px;

      > span {
        font-size: 14px;
      }
    }
  }
`
export const FilialSection = styled(Section)`
  margin: 0 30px;

  > header {
    border-left: 3px solid #3B86C2;
    border-radius: 0 0 0 8px;
  }
`