import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IMotivoEncerramento from "../interfaces/IMotivoEncerramento";
import { AdicionaMotivoEncerramentoRequest } from "../interfaces/Requests/MotivoEncerramento/AdicionaMotivoEncerramentoRequest";
import FiltroPesquisaMotivoEncerramentoRequest from "../interfaces/Requests/MotivoEncerramento/FiltroPesquisaMotivoEncerramentoRequest";
import { EditarMotivoEncerramentoRequest } from "../interfaces/Requests/MotivoEncerramento/EditarMotivoEncerramentoRequest";

class MotivoEncerramentoService {

    async obterMotivoEncerramentos(filtros: FiltroPesquisaMotivoEncerramentoRequest): Promise<IRecuperaLista<IMotivoEncerramento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IMotivoEncerramento>>(`MotivoEncerramento/RecuperaMotivoEncerramento?${query}`);

        return result;
    }

    async obterMotivoEncerramentosLimitado(filtros: FiltroPesquisaMotivoEncerramentoRequest): Promise<IRecuperaLista<IMotivoEncerramento>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IMotivoEncerramento>>(`MotivoEncerramento/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaMotivoEncerramento(adicionaMotivoEncerramento: AdicionaMotivoEncerramentoRequest): Promise<IMotivoEncerramento> {
        let { data: result } = await api.post<IMotivoEncerramento>(`MotivoEncerramento/AdicionaMotivoEncerramento`, adicionaMotivoEncerramento);

        return result;
    }


    async alterarStatus(motivoEncerramentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IMotivoEncerramento>(`MotivoEncerramento/AtualizaMotivoEncerramentoPorId?id=${motivoEncerramentoId}`, data);
    }

    async atualizarMotivoEncerramento(editarMotivoEncerramento: EditarMotivoEncerramentoRequest): Promise<void> {

        let dataMotivoEncerramento = [
            { "op": "replace", "path": "/nome", "value": editarMotivoEncerramento.nome },
            { "op": "replace", "path": "/codigo", "value": editarMotivoEncerramento.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`MotivoEncerramento/AtualizaMotivoEncerramentoPorId?id=${editarMotivoEncerramento.motivoEncerramentoId}`, dataMotivoEncerramento)
        ]);
    }
}


export default new MotivoEncerramentoService();