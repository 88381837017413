import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import {
  faCheckCircle,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faEraser,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import FiltroPesquisaModelosDocumentosReq from "../../interfaces/Requests/ModeloDocumento/FiltroPesquisaModelosDocumentosReq";
import IModeloDocumento from "../../interfaces/IModeloDocumento";
import ModeloDocumentoService from "../../services/ModeloDocumentoService";
import Swal from "sweetalert2";
import { optionsType } from "../../components/Comum/Fornecedor/FornecedorField";
import ModuloService from "../../services/ModuloService";
import Select, { SingleValue } from "react-select";
import GridUsuarios from "../Admininstracao/Seguranca/GrupoUsuarios/components/GridUsuarios";
import { TableColumn } from "react-data-table-component";
import ModeloDocumentoAddModal from "./modals/ModeloDocumentoAddModal";
import ModeloDocumentoEditModal from "./modals/ModeloDocumentoEditModal";
import ModeloDocumentoUploadMinutaModal from "./modals/ModeloDocumentoUploadMinutaModal";
import DocumentoService from "../../services/DocumentoService";

const ModeloDocumentoPage = () => {
  const [modeloDocumentoId, setModeloDocumentoId] = useState(0);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exibirAddModal, setExibirAddModal] = useState(false);
  const [exibirEditModal, setExibirEditModal] = useState(false);
  const [exibirModalUploadMinuta, setExibirModalUploadMinuta] = useState(false);
  const [modelos, setModelos] = useState<IModeloDocumento[]>([]);
  const [modelo, setModelo] = useState<IModeloDocumento>({
    clienteId: 0,
    codigo: "",
    documentoId: 0,
    modeloDocumentoId: 0,
    modulo: 0,
    moduloNome: "",
    nome: "",
    status: "",
  });
  const [filtros, setFiltros] = useState<FiltroPesquisaModelosDocumentosReq>({
    limit: 10,
    modulo: 0,
    nome: "",
    codigo: "",
    offset: 0,
    sort: "modeloDocumentoId",
    status: "0",
    totalItems: 0,
  });

  const [modulos, setModulos] = useState<optionsType[]>([]);
  const [modulo, setModulo] = useState<SingleValue<optionsType>>({
    label: "",
    value: 0,
  });

  const carregarModelos = async (
    filtro: FiltroPesquisaModelosDocumentosReq
  ) => {
    setLoading(true);
    try {
      const data = await ModeloDocumentoService.obterModelos(filtro);

      setModelos(data.data);
      setFiltros((oldState) => {
        return { ...oldState, totalItems: data.totalRegistros };
      });
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos Modelos de Documentos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });

      setModelos([]);
    } finally {
      setLoading(false);
    }
  };

  const carregarModulos = async () => {
    try {
      const response = await ModuloService.obterModulos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: "id",
      });

      const mappedModulos = response.data.map((modulo) => {
        return {
          value: modulo.moduloId,
          label: modulo.nome,
        };
      });

      setModulos(mappedModulos);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos módulos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  function renderCell(modeloDocumento: IModeloDocumento) {
    if (modeloDocumento.status !== "Ativo") {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Modelo de Documento"
              onClick={() => {
                setModelo(modeloDocumento);
                setExibirEditModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Ativar Modelo de Documento"
              onClick={() => {
                handleStatusChange(modeloDocumento.modeloDocumentoId, "1");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faCheckCircle}
            />
          </div>

          <FontAwesomeIcon
            title="Carregar Modelo de Documento"
            onClick={() => {
              setModeloDocumentoId(modeloDocumento.modeloDocumentoId);
              setExibirModalUploadMinuta(true);
            }}
            style={{ fontWeight: "normal", cursor: "pointer" }}
            size="1x"
            className="mx-2 text-orange"
            icon={faCloudUploadAlt}
          />

          {modeloDocumento.documentoId > 0 ? (
            <FontAwesomeIcon
              title="Baixar Modelo de Documento"
              onClick={() => {
                baixarArquivo(
                  modeloDocumento?.documentoId,
                  modeloDocumento.nome
                );
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faCloudDownloadAlt}
            />
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Modelo de Documento"
              onClick={() => {
                setModelo(modeloDocumento);
                setExibirEditModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Desativar Modelo de Documento"
              onClick={() => {
                handleStatusChange(modeloDocumento.modeloDocumentoId, "-1");
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faTrashAlt}
            />
          </div>

          <FontAwesomeIcon
            title="Carregar Modelo de Documento"
            onClick={() => {
              setModeloDocumentoId(modeloDocumento.modeloDocumentoId);
              setExibirModalUploadMinuta(true);
            }}
            style={{ fontWeight: "normal", cursor: "pointer" }}
            size="1x"
            className="mx-2 text-orange"
            icon={faCloudUploadAlt}
          />

          {modeloDocumento.documentoId > 0 ? (
            <FontAwesomeIcon
              title="Baixar Modelo de Documento"
              onClick={() => {
                baixarArquivo(
                  modeloDocumento?.documentoId,
                  modeloDocumento.nome
                );
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faCloudDownloadAlt}
            />
          ) : (
            <></>
          )}
        </>
      );
    }
  }

  const colunas: TableColumn<IModeloDocumento>[] = [
    {
      name: "Id",
      sortField: "modeloDocumentoId",
      selector: (row: IModeloDocumento) => row.modeloDocumentoId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IModeloDocumento) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IModeloDocumento) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Módulo",
      sortField: "modulo",
      selector: (row: IModeloDocumento) => row.moduloNome,
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const baixarArquivo = async (documentoId: number, nomeArquivo: string) => {
    nomeArquivo = "Modelo de Documento - " + nomeArquivo + ".docx";
    Swal.fire({
      heightAuto: false,
      icon: "info",
      title: "Realizando download...",
      showConfirmButton: false,
    });
    Swal.showLoading();

    try {
      await DocumentoService.download(documentoId)
        .then((response: any) => {
          const disposition = response.headers["content-disposition"];
          let fileName = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          const fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute("download", nomeArquivo);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          throw new Error(error);
        });

      Swal.hideLoading();

      await Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Download realizado com sucesso!`,
        showConfirmButton: true,
        timer: 4000,
      });

      Swal.close();
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel realizar o download.`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };

  const handleStatusChange = async (id: number, status: string) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await ModeloDocumentoService.editarModelo(id, { status });

        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status do Modelo de Documento alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await carregarModelos(filtros);
      }
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível editar o Fornecedor`,
        text: "Ocorreu um erro inesperado.",
        showConfirmButton: true,
      });
    }
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });

    await carregarModelos({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((oldState) => {
      return { ...oldState, offset: (page - 1) * filtros.limit };
    });

    await carregarModelos({
      ...filtros,
      offset: (page - 1) * filtros.limit,
    });
  };

  const handleSort = async (
    column: TableColumn<IModeloDocumento>,
    sortDirection: string
  ) => {
    setFiltros((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await carregarModelos({
      ...filtros,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    });
  };

  useEffect(() => {
    carregarModelos(filtros);
  }, [filtros.status]);

  useEffect(() => {
    carregarModulos();
  }, []);

  return (
    <>
      <LayoutPageTitle title="Modelo de Documento">
        <button
          className="btn btn-md btn-orange"
          onClick={() => setExibirAddModal(true)}
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <ModeloDocumentoAddModal
        exibirModal={exibirAddModal}
        handleClose={async () => {
          setExibirAddModal(false);

          await carregarModelos(filtros);
        }}
        modulos={modulos}
      />

      <ModeloDocumentoEditModal
        exibirModal={exibirEditModal}
        handleClose={async () => {
          setExibirEditModal(false);

          await carregarModelos(filtros);
        }}
        modulos={modulos}
        modelo={modelo}
      />

      <ModeloDocumentoUploadMinutaModal
        ModeloDocumentoId={modeloDocumentoId}
        exibirModal={exibirModalUploadMinuta}
        titulo="Atualizar Modelo de Documento"
        toggleModalUploadMinuta={async () => {
          setExibirModalUploadMinuta(false);
          await carregarModelos(filtros);
        }}
        modulos={modulos}
      />

      <div className="col-md-12">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setReset(!reset);
            await carregarModelos(filtros);
          }}
          className="row g-3 mb-3"
        >
          <div className="col-md-2 ">
            <label
              htmlFor="form-nome"
              className="form-label fw-bolder text-orange"
            >
              Nome:
            </label>
            <input
              value={filtros.nome}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return {
                    ...oldState,
                    nome: e.target.value,
                  };
                });
              }}
              placeholder="Nome"
              type="text"
              className="form-control"
              id="form-nome"
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Código:
            </label>
            <input
              value={filtros.codigo}
              onChange={(e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return {
                    ...oldState,
                    codigo: e.target.value,
                  };
                });
              }}
              placeholder="Código"
              type="text"
              className="form-control"
              id="form-codigo"
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-email"
              className="form-label fw-bolder text-orange"
            >
              Módulo:
            </label>

            <Select
              options={modulos}
              value={modulo}
              isSearchable
              onChange={(value) => {
                setModulo(value);
                setFiltros((prev) => ({
                  ...prev,
                  modulo: value?.value ?? 0,
                }));
              }}
            />
          </div>

          <div className="col-md-2 ">
            <label
              htmlFor="form-status"
              className="form-label fw-bolder text-orange"
            >
              Status:
            </label>

            <select
              onChange={async (e) => {
                e.preventDefault();
                setFiltros((oldState) => {
                  return { ...oldState, status: e.target.value };
                });
                setReset(!reset);

                await carregarModelos({
                  ...filtros,
                  status: e.target.value,
                });
              }}
              value={filtros.status}
              placeholder="Status"
              className="form-control"
              id="form-select"
            >
              <option value="0" label="Todos">
                Todos
              </option>

              <option value="1" label="Ativo">
                Ativo
              </option>

              <option value="-1" label="Inativo">
                Inativo
              </option>
            </select>
          </div>

          <div className="col-md-2">
            <div>
              <button
                type="submit"
                className="btn btn-sm btn-orange search-buttom-margin"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faSearch}
                />
              </button>
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  setReset(!reset);

                  setFiltros({
                    nome: "",
                    status: "0",
                    limit: 10,
                    totalItems: 0,
                    offset: 0,
                    sort: "modeloDocumentoId",
                    codigo: "",
                    modulo: 0,
                  });

                  setModulo({ label: "", value: 0 });

                  await carregarModelos({
                    nome: "",
                    status: "0",
                    limit: 10,
                    totalItems: 0,
                    offset: 0,
                    sort: "modeloDocumentoId",
                    codigo: "",
                    modulo: 0,
                  });
                }}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon
                  color="white"
                  className="mx-2"
                  icon={faEraser}
                />
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridUsuarios
            onSort={handleSort}
            progressPending={loading}
            limit={filtros.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={filtros.totalItems}
            paginationServer={true}
            colunas={colunas}
            tipo="Fornecedores"
            itens={modelos}
            pagination
            reset={reset}
          />
        </div>
      </div>
    </>
  );
};

export default ModeloDocumentoPage;
