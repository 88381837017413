/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./Api";
import { EModulo } from "../enum";

import { tipoPorModulo } from "../utils/Utils";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoPropriedades from "../interfaces/ITipoPropriedades";

class ConfiguracaoPropriedadeService {

  async adicionar(propriedades: ITipoPropriedades): Promise<IRecuperaLista<any>> {

    let { data: result } = await api.post<IRecuperaLista<any>>(`PropriedadeConfiguracao/AdicionaPropriedadeConfiguracao`, propriedades);

    return result;
  }

  async obterTodo(modulo: EModulo): Promise<IRecuperaLista<any>> {

    let { data: result } = await api.get<IRecuperaLista<any>>(`Propriedade/RecuperaPropriedade?modulo=${modulo}&status=1`);

    return result;
  }

  async obterDoing(tipoPropriedade: ITipoPropriedades): Promise<IRecuperaLista<any>> {

    let categoriaId: any;

    if(tipoPropriedade.areaDireitoId) categoriaId = tipoPropriedade.areaDireitoId;
    if(tipoPropriedade.tipoMarcaId) categoriaId = tipoPropriedade.tipoMarcaId;
    if(tipoPropriedade.tipoConsultaId) categoriaId = tipoPropriedade.tipoConsultaId;
    if(tipoPropriedade.tipoAtoId) categoriaId = tipoPropriedade.tipoAtoId;
    if(tipoPropriedade.tipoContratoId) categoriaId = tipoPropriedade.tipoContratoId;
    if(tipoPropriedade.tipoImovelId) categoriaId = tipoPropriedade.tipoImovelId;
    if(tipoPropriedade.tipoLicencaId) categoriaId = tipoPropriedade.tipoLicencaId;
    if(tipoPropriedade.tipoSociedadeId) categoriaId = tipoPropriedade.tipoSociedadeId;
    if(tipoPropriedade.tipoProcuracaoId) categoriaId = tipoPropriedade.tipoProcuracaoId;

    let { data: result } = await api.get<IRecuperaLista<any>>(`PropriedadeConfiguracao/RecuperaPropriedadeConfiguracao?modulo=${tipoPropriedade.modulo}&status=1&${tipoPorModulo(tipoPropriedade.modulo)}Id=${categoriaId}`);

    return result;
  }

}

export default new ConfiguracaoPropriedadeService();
