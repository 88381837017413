import { Node, Text, Transforms } from "slate";
import { CustomEditor, CustomElement, CustomText, FooterElement, HeaderElement, PageElement } from "../../../utils/types/slate"
import { Block } from "../types";

function headers(editor: CustomEditor): Block<HeaderElement> {
  const firstPage = editor.children[0] as PageElement;
  const firstHeader = firstPage.children[0] as HeaderElement
  const defaultPage = editor.children[1] as PageElement;
  const defaultHeader = defaultPage.children[0] as HeaderElement;

  return {
    firstPage: firstHeader,
    even: firstHeader,
    default: defaultHeader
  }
}

function footers(editor: CustomEditor): Block<FooterElement> {
  const firstPage = editor.children[0] as PageElement;
  const firstFooter = firstPage.children[2] as FooterElement
  const defaultPage = editor.children[1] as PageElement;
  const defaultFooter = defaultPage.children[2] as FooterElement;

  return {
    firstPage: firstFooter,
    even: firstFooter,
    default: defaultFooter
  }
}

function withId<T>(name: 'footer' | 'header', element: T, page: number): T {
  return {
    ...element,
    id: `${name}_component_${page}`,
  }
}

function replacePageCount(editor: CustomEditor) {
  const descendants = Array.from(Node.descendants(editor));

  for (const [node, path] of descendants) {
    if (Text.isText(node)) {
      const { text } = node as CustomText;
      if (text === '@PAGE_COUNT') {
        const count = editor.children.length;
        Transforms.insertText(editor, `${count}`, { at: path });
      }
    }
  }
}

export const blockHelper = {
  headers,
  footers,
  withId,
  replacePageCount
}