import { EditorVersionData } from "../../../../../../interfaces/IEditorVersionResponse";
import IRecuperaLista from "../../../../../../interfaces/IRecuperaLista";

type VersionList = IRecuperaLista<EditorVersionData>;

const defaultCache = {
  data: [],
  dataPropriedades: [],
  totalPaginas: 0,
  totalRegistros: 0,
  relatorioId: null,
};

export function updateTitle(
  title: string,
  cache: IRecuperaLista<EditorVersionData> | undefined
): VersionList {
  if (cache) {
    cache.data[0].titulo = title;
    return cache;
  }
  return defaultCache;
}

export function updateVersionCache(
  newVersion: EditorVersionData,
  current?: VersionList
): VersionList {
  if (current) {
    return {
      ...current,
      data: [newVersion, ...current.data],
    };
  }

  return defaultCache;
}
