import { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { TableColumn } from 'react-data-table-component';
import { faSearch, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";

import GridPadrao from '../GridPadrao';

import { EModulo } from '../../../enum';

import PublicacaoService from '../../../services/PublicacaoService';

import IPublicacao from '../../../interfaces/IPublicacao';
import IRecuperaLista from '../../../interfaces/IRecuperaLista';
import { OrangeInterfaceContext } from '../../../interfaces/Contexts/OrangeInterfaceContext';
import FiltroPesquisaPublicacaoRequest from '../../../interfaces/Requests/Publicacao/FiltroPesquisaPublicacaoRequest';
import { IRecuperaSeparadorTelaResponse } from '../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse';

import PublicacaoDetalhes from './PublicacaoDetalhes';
import { OrangeContext } from '../../../contexts/OrangeProvider';
import clsx from 'clsx';
import CollapseDefault from '../../Collapse/CollapseDefault';
import IOption from '../../../interfaces/IOption';
import { MultiSelect } from 'react-multi-select-component';

interface IPublicacaoListFieldPropos {
    configuracaoTela: IRecuperaSeparadorTelaResponse;
}

const PublicacaoListField = ({ configuracaoTela }: IPublicacaoListFieldPropos) => {

    const { processo } = useContext<OrangeInterfaceContext>(OrangeContext);

    const [options] = useState<IOption[]>([{ label: 'Pendente', value: 1 }, { label: 'Lido', value: 2 }, { label: 'Descartado', value: 3 },]);
    const [optionsSelected, setOptionsSelected] = useState([]);

    const [exibirListaPublicacao, setExibirListaPublicacao] = useState<boolean>(false);
    const [exibirModalPublicacao, setExibirModalPublicacao] = useState<boolean>(false);
    const [atualizaTabelaPublicacao, setAtualizaTabelaPublicacao] = useState<boolean>(false);
    const [publicacoes, setPublicacoes] = useState<IPublicacao[]>([]);
    const [publicacao, setPublicacao] = useState<IPublicacao>({
        classificacaoPublicacao: "",
        processoParteContraria: [],
        codigo: "",
        dataCadastro: new Date(),
        dataDivulgacao: new Date(),
        dataPublicacao: new Date(),
        estadoId: 0,
        fonte: "",
        idTermoCadastrado: "",
        numeroProcessoCapturado: "",
        processoId: processo.processoId,
        publicacaoId: 0,
        status: "",
        teorPublicacao: "",
        estadoNome: "",
    });
    const [carregandoPublicacoes, setCarregandoPublicacoes] = useState(false);
    const [filtrosPublicacao, setFiltrosPublicacao] = useState<FiltroPesquisaPublicacaoRequest>({
        publicacaoId: [],
        areaDireitoId: [],
        modulo: EModulo.AreaDireito,
        processoId: processo.processoId,
        estadoId: 0,
        idTermoCadastrado: "",
        advogadoInternoId: [],
        fonte: "",
        dataPublicacaoMaiorIgual: null,
        dataPublicacaoMenorIgual: null,
        dataDivulgacaoMaiorIgual: "",
        dataDivulgacaoMenorIgual: "",
        dataCadastroMaiorIgual: "",
        dataCadastroMenorIgual: "",
        numeroProcessoCapturado: "",
        classificacaoPublicacao: "",
        teorPublicacao: "",
        codigo: "",
        ultimosQuantidade: null,
        limit: 10,
        totalItems: 0,
        offset: 0,
        filtro: "",
        sort: "-DataPublicacao",
        status: [1],
        processoIdentificado: false
    });

    const togglePublicacao = () => setExibirModalPublicacao(!exibirModalPublicacao);

    useEffect(() => {
        const IdsSelected: number[] = [];
        optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
        setFiltrosPublicacao((oldState) => {
            return { ...oldState, status: IdsSelected };
        });
    }, [optionsSelected]);

    useEffect(() => {

        if (exibirModalPublicacao) {
            setFiltrosPublicacao((oldState) => {
                return { ...oldState, ultimosQuantidade: 0 };
            });

            carregarPublicacoes(filtrosPublicacao);
        }
    }, [filtrosPublicacao.offset, filtrosPublicacao.limit, filtrosPublicacao.sort]);


    useEffect(() => {
        if (atualizaTabelaPublicacao) {
            carregarPublicacoes(filtrosPublicacao);
        }
        setAtualizaTabelaPublicacao(false);
    }, [setAtualizaTabelaPublicacao]);

    useEffect(() => {
        if (exibirModalPublicacao) {
            carregarPublicacoes(filtrosPublicacao);
        }
    }, [exibirModalPublicacao]);


    const handlePerRowsChangePublicacao = async (currentRowsPerPage: number) => {
        setFiltrosPublicacao((oldState) => {
            return { ...oldState, limit: currentRowsPerPage };
        });
    };

    const handlePageChangePublicacao = (page: number) => {
        setFiltrosPublicacao((oldState) => {
            return { ...oldState, offset: (page - 1) * filtrosPublicacao.limit };
        });
    };

    const handleSortPublicacao = async (column: TableColumn<IPublicacao>, sortDirection: string) => {
        setFiltrosPublicacao((oldState) => {
            return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
        });
    };

    const carregarPublicacoes = async (filtro: FiltroPesquisaPublicacaoRequest) => {
        try {
            setCarregandoPublicacoes(true);

            let resultado: IRecuperaLista<IPublicacao>;
            resultado = await PublicacaoService.obterPublicacoes({ ...filtro, processoId: processo.processoId });

            if (resultado.data != null) setPublicacoes(resultado.data);
            else setPublicacoes([]);

            setFiltrosPublicacao((oldState) => {
                return { ...oldState, totalItems: resultado.totalRegistros };
            });

            setCarregandoPublicacoes(false);
        } catch (error: any) {
            setPublicacoes([]);
            setCarregandoPublicacoes(false);
        }
    };


    const colunasPublicacoes: TableColumn<IPublicacao>[] = [
        {
            cell: (row: IPublicacao) => {
                return (
                    <>
                        <a style={{ cursor: "pointer" }}>
                            <p
                                className="form-label fw-bolder text-orange"
                                style={{
                                    paddingTop: "30px",
                                }}
                            >
                                #{row.publicacaoId} - {row.fonte} - Divulgado em: {moment(row.dataDivulgacao).format("DD/MM/yyyy")}
                            </p>
                            <p>
                                <b className="form-label fw-bolder text-orange">Data da Publicação: </b> {moment(row.dataPublicacao).format("DD/MM/yyyy")}
                                <br />
                                <b className="form-label fw-bolder text-orange">Status: </b> {row.status}
                                <br />
                            </p>
                            <p>
                                <>
                                    <FontAwesomeIcon
                                        title="Detalhes"
                                        onClick={async () => {
                                            setPublicacao(row);
                                            setExibirModalPublicacao(true);
                                        }}
                                        style={{ fontWeight: "normal", cursor: "pointer" }}
                                        size="2x"
                                        className="mx-2 text-orange"
                                        icon={faSearch}
                                    />
                                </>
                            </p>
                            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                                {row?.teorPublicacao?.split(/\.(?=\s\d)/).map((sentence, index) => (
                                    <p key={index}>{sentence?.trim()}.</p>
                                ))}
                            </div>
                        </a>
                    </>
                );
            },
            ignoreRowClick: true,
        },
    ];

    return (<>
        <PublicacaoDetalhes
            exibirModal={exibirModalPublicacao}
            toggleModal={togglePublicacao}
            publicacao={publicacao}
            titulo="Detalhes da Publicação"
        />

        <div className="row mt-3">
            <CollapseDefault
                carregando={carregandoPublicacoes}
                exibir={exibirListaPublicacao}
                setExibir={setExibirListaPublicacao}
                modulo={EModulo.Publicacao}
                titulo={configuracaoTela.label}
                help={configuracaoTela.help}
                content={
                    exibirListaPublicacao && (
                        <>
                            <div className="row align-items-end"> {/* Adiciona a classe 'row' e 'align-items-end' para alinhar os itens verticalmente na parte inferior */}

                                <div className="col-2">
                                    <label htmlFor="form-dataPublicacao" className="form-label fw-bolder text-orange">
                                        Status:
                                    </label>
                                    <MultiSelect
                                        isLoading={false}
                                        disabled={false}
                                        options={options}
                                        value={optionsSelected}
                                        onChange={(event: any) => setOptionsSelected(event)}
                                        labelledBy={"Selecione..."}
                                        overrideStrings={{
                                            selectSomeItems: "Selecione...",
                                            allItemsAreSelected: "Todos selecionados",
                                            selectAll: "Selecione todos",
                                            search: "Pesquise",
                                            selectAllFiltered: "Selecione todos (filtrados)"
                                        }}
                                    />
                                </div>


                                <div className="col-4">
                                    <label htmlFor="form-dataPublicacao" className="form-label fw-bolder text-orange">
                                        Data de Publicação:
                                    </label>
                                    <DateTimeRangePicker
                                        className="form-control p-0"
                                        calendarIcon={null}
                                        showLeadingZeros={true}
                                        value={[
                                            filtrosPublicacao.dataPublicacaoMaiorIgual || "", // Valor inicial para a data de início
                                            filtrosPublicacao.dataPublicacaoMenorIgual || "" // Valor inicial para a data de fim
                                        ]}
                                        onChange={(datas: Date[]) => {
                                            if (!datas || datas.length !== 2 || !datas[0] || !datas[1]) {
                                                setFiltrosPublicacao((oldValue: any) => ({
                                                    ...oldValue,
                                                    dataPublicacaoMaiorIgual: "",
                                                    dataPublicacaoMenorIgual: "",
                                                }));
                                                return;
                                            }

                                            const [dataInicial, dataFinal] = datas;
                                            if (!(dataInicial instanceof Date) || !(dataFinal instanceof Date)) {
                                                console.error("Invalid Date Object");
                                                return;
                                            }

                                            const dataFinalAdjusted = new Date(dataFinal);
                                            dataFinalAdjusted.setHours(0, 0, 0, 0);
                                            dataFinalAdjusted.setDate(dataFinalAdjusted.getDate());

                                            setFiltrosPublicacao((oldValue: any) => ({
                                                ...oldValue,
                                                dataPublicacaoMaiorIgual: dataInicial ? `${dataInicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                                                dataPublicacaoMenorIgual: dataFinalAdjusted ? `${dataFinalAdjusted.toISOString().split("T")[0]}T23:59:59.9999` : "",
                                            }));
                                        }}
                                    />
                                </div>




                                <div className="col-2">
                                    <label htmlFor="form-dataPublicacao" className="form-label fw-bolder text-orange">
                                        Informar Quantidade:
                                    </label>
                                    <input
                                        value={filtrosPublicacao.ultimosQuantidade || ""}
                                        type="number"
                                        maxLength={3}
                                        onChange={(e) => {
                                            setFiltrosPublicacao((oldState) => {
                                                return { ...oldState, ultimosQuantidade: parseInt(e.target.value, 10) };
                                            });
                                        }}
                                        placeholder="+ Quantidade"
                                        className={clsx("form-control")}
                                    />
                                </div>

                                <div className="col-2 d-flex align-items-end"> {/* Adiciona a classe 'd-flex' para tornar o contêiner flexível e 'align-items-end' para alinhar o botão verticalmente na parte inferior */}
                                    <button onClick={() => carregarPublicacoes(filtrosPublicacao)} className="btn btn-orange">
                                        {<> Pesquisar </>}
                                    </button>
                                </div>

                            </div>

                            <div className="col-md-12 mb-5">
                                <GridPadrao
                                    onSort={handleSortPublicacao}
                                    progressPending={carregandoPublicacoes}
                                    limit={filtrosPublicacao.limit}
                                    onChangePage={handlePageChangePublicacao}
                                    onChangeRowsPerPage={handlePerRowsChangePublicacao}
                                    paginationServer={true}
                                    paginationTotalRows={filtrosPublicacao.totalItems}
                                    colunas={colunasPublicacoes}
                                    tipo="Publicacoes"
                                    itens={publicacoes}
                                    disableSelectAllRows={true}
                                />
                            </div>
                        </>
                    )}
            />
        </div>
        <hr />
    </>)
}

export default PublicacaoListField;