import { Navigate, Route, Routes } from "react-router-dom";

import { Kanban } from "../components/Kanban";
import UsuarioCadastroPage from "../pages/Admininstracao/Seguranca/UsuarioPage/UsuarioCadastroPage";
import UsuarioPage from "../pages/Admininstracao/Seguranca/UsuarioPage/UsuarioPage";
import AlterarSenhaPage from "../pages/AlterarSenhaPage/AlterarSenhaPage";
import AmbitoPage from "../pages/AmbitoPage/AmbitoPage";
import AndamentoPage from "../pages/Andamento/AndamentoPage";
import AndamentoTipoPage from "../pages/AndamentoTipoPage/AndamentoTipoPage";
import AreaDireitoPage from "../pages/AreaDireitoPage/AreaDireitoPage";
import AtividadeEmpresaPage from "../pages/AtividadeEmpresaPage/AtividadeEmpresaPage";
import BancoPage from "../pages/BancoPage/BancoPage";
import BloqueioPage from "../pages/BloqueioPage/BloqueioPage";
import CNJOrgaoPage from "../pages/CNJPage/CNJOrgaoPage";
import CargoPage from "../pages/CargoPage/CargoPage";
import TipoComunicacaoPage from "../pages/TipoComunicacaoPage/TipoComunicacaoPage";
import SeparadorPage from "../pages/SeparadorPage/SeparadorPage";
import CentroCustoPage from "../pages/CentroCustoPage/CentroCustoPage";
import CidadePage from "../pages/CidadePage/CidadePage";
import ClassificacaoPage from "../pages/ClassificacaoPage/ClassificacaoPage";
import ClienteCadastroPage from "../pages/Cliente/ClienteCadastroPage";
import ClientePage from "../pages/Cliente/ClientePage";

import AutomacaoPage from "../pages/ConfiguracaoTelaPage/Automacao/AutomacaoPage";

import ProcessoSolucoesPage from "../pages/ConfiguracaoTelaPage/Processo/SolucoesPage";
import ProcessoPesquisaLimitadaPage from "../pages/ConfiguracaoTelaPage/Processo/AreaDireitoPage";
import ProcessoConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Processo/ConfiguracaoTelaPage";

import PesquisaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Pesquisa/ConfiguracaoTelaPage";

import DepartamentoPage from "../pages/DepartamentoPage/DepartamentoPage";
import EsferaPage from "../pages/EsferaPage/EsferaPage";
import EstadoCivilPage from "../pages/EstadoCivilPage/EstadoCivilPage";
import FaseProcessualPage from "../pages/FaseProcessualPage/FaseProcessualPage";
import FormaParticipacaoPage from "../pages/FormaParticipacaoPage/FormaParticipacaoPage";
import HomePage from "../pages/HomePage/HomePage";
import IndicePage from "../pages/IndicePage/IndicePage";
import InstanciaPage from "../pages/InstanciaPage/InstanciaPage";
import JuizPage from "../pages/JuizPage/JuizPage";
import MoedaPage from "../pages/MoedaPage/MoedaPage";
import MotivoDesligamentoPage from "../pages/MotivoDesligamentoPage/MotivoDesligamentoPage";
import MotivoEncerramentoPage from "../pages/MotivoEncerramentoPage/MotivoEncerramentoPage";
import MotivoEncerramentoConsultaPage from "../pages/MotivoEncerramentoConsultaPage/MotivoEncerramentoConsultaPage";
import OrgaoRegistroPage from "../pages/OrgaoRegistroPage/OrgaoRegistroPage";
import PaisPage from "../pages/PaisPage/PaisPage";
import PerfilPage from "../pages/PerfilPage/PerfilPage";
import PeriodicidadePage from "../pages/PeriodicidadePage/PeriodicidadePage";
import RiscoPage from "../pages/RiscoPage/RiscoPage";
import StatusBloqueioPage from "../pages/StatusBloqueioPage/StatusBloqueioPage";
import StatusDesdobramentoPage from "../pages/StatusDesdobramentoPage/StatusDesdobramentoPage";
import StatusFolhaPage from "../pages/StatusFolhaPage/StatusFolhaPage";
import StatusProcessoPage from "../pages/StatusProcessoPage/StatusProcessoPage";
import StatusPropostaAcordoPage from "../pages/StatusPropostaAcordoPage/StatusPropostaAcordoPage";
import SubAreaDireitoPage from "../pages/SubAreaDireitoPage/SubAreaDireitoPage";
import TipoAcaoPage from "../pages/TipoAcaoPage/TipoAcaoPage";
import TipoContingenciaPage from "../pages/TipoContingenciaPage/TipoContingenciaPage";
import TipoDesdobramentoPage from "../pages/TipoDesdobramentoPage/TipoDesdobramentoPage";
import TipoEmpresaPage from "../pages/TipoEmpresaPage/TipoEmpresaPage";
import TipoPedidoPage from "../pages/TipoPedidoPage/TipoPedidoPage";

import DecisaoPage from "../pages/Decisao/DecisaoPage";
import DesdobramentoPage from "../pages/DesdobramentoPage/DesdobramentoPage";
import DespesaPage from "../pages/DespesaPage/DespesaPage";
import EstadoPage from "../pages/EstadoPage/EstadoPage";
import FechamentoPage from "../pages/FechamentoPage/FechamentoPage";
import FormaPagamentoPage from "../pages/FormaPagamentoPage/FormaPagamentoPage";
import GestaoProcessoPage from "../pages/GestaoProcessoPage/GestaoProcessoPage";
import GrupoEconomicoPage from "../pages/GrupoEconomico/GrupoEconomicoPage";

import FeedPage from "../pages/FeedPage/FeedPage";
import ConfirmarFeedPage from "../pages/FeedPage/ConfirmarFeed";

import LogContratoPage from "../pages/LogsPage/LogContratoPage";
import LogEmpresaPage from "../pages/LogsPage/LogEmpresaPage";
import LogImovelPage from "../pages/LogsPage/LogImovelPage";
import LogMarcaPage from "../pages/LogsPage/LogMarcaPage";
import LogProcessoPage from "../pages/LogsPage/LogProcessoPage";

import XFinderIntimacaoLoginPage from "../pages/Admininstracao/Seguranca/XFinderPage/XFinderIntimacaoLoginPage";
import FechamentoDashboardPage from "../pages/Contencioso/Dashboard/FechamentoDashboardPage";
import UploadPlanilhaFechamentoPage from "../pages/FechamentoPage/UploadPlanilhaFechamentoPage";
import GedPage from "../pages/GedPage/GedPage";
import ModuloPage from "../pages/ModuloPage/ModuloPage";
import MotivoSituacaoPage from "../pages/MotivoSituacaoPage/MotivoSituacaoPage";
import ObrigacaoPage from "../pages/ObrigacaoPage/ObrigacaoPage";
import OrgaoResponsavelPage from "../pages/OrgaoResponsavelPage/OrgaoResponsavelPage";
import OrigemObrigacaoPage from "../pages/OrigemObrigacaoPage/OrigemObrigacaoPage";
import PastaGedPage from "../pages/PastaGedPage/PastaGedPage";
import ProcessoCadastroPage from "../pages/ProcessoPage/ProcessoCadastroPage";
import ProcessoCapaPage from "../pages/ProcessoPage/ProcessoCapaPage";
import ProcessoPadraoPage from "../pages/ProcessoPage/ProcessoPadraoPage";
import ProcessoPage from "../pages/ProcessoPage/ProcessoPage";
import PropostaAcordoPage from "../pages/PropostaAcordoPage/PropostaAcordoPage";
import PublicacaoPage from "../pages/Publicacao/PublicacaoPage";
import QualificacaoPage from "../pages/QualificacaoPage/QualificacaoPage";
import SeguradoraPage from "../pages/SeguradoraPage/SeguradoraPage";

import FechamentoConfiguracaoPage from "../pages/FechamentoConfiguracao/FechamentoConfiguracaoPage";
import FechamentoParametrizacao from "../pages/ConfiguracaoTelaPage/FechamentoParametrizacao/FechamentoParametrizacao";

import EmpresaConfiguracaoTela from "../pages/ConfiguracaoTelaPage/Empresa/ConfiguracaoTelaPage";
import SociedadeSolucoesPage from "../pages/ConfiguracaoTelaPage/Empresa/SolucoesPage";
import EmpresaCadastroPage from "../pages/SocietarioPage/EmpresaPage/EmpresaCadastroPage";
import EmpresaCapaPage from "../pages/SocietarioPage/EmpresaPage/EmpresaCapaPage";
import { AtosSocietariosPage } from "../pages/SocietarioPage/EmpresaPage/Solucoes/AtosSocietarios/AtosSocietarioPage";
import { ConselhoPage } from "../pages/SocietarioPage/EmpresaPage/Solucoes/Conselho/ConselhoPage";
import { FiliaisPage } from "../pages/SocietarioPage/EmpresaPage/Solucoes/FiliaisPage";
import { RepresentantesPage } from "../pages/SocietarioPage/EmpresaPage/Solucoes/Representantes/RepresentantesPage";
import { DistribuicaoCapitalPage } from "../pages/SocietarioPage/EmpresaPage/Solucoes/SocioAdministrador/SocioAdministradorPage";

import StatusDespesaPage from "../pages/StatusDespesaPage/StatusDespesaPage";
import StatusGarantiaPage from "../pages/StatusGarantiaPage/StatusGarantiaPage";
import StatusObrigacaoPage from "../pages/StatusObrigacaoPage/StatusObrigacaoPage";
import TipoAtoPage from "../pages/TipoAtoPage/TipoAtoPage";
import TipoContratoPage from "../pages/TipoContratoPage/TipoContratoPage";
import TipoDespesaPage from "../pages/TipoDespesaPage/TipoDespesaPage";
import TipoDocumentoPage from "../pages/TipoDocumentoPage/TipoDocumentoPage";
import TipoGarantiaPage from "../pages/TipoGarantiaPage/TipoGarantiaPage";
import TipoLicencaPage from "../pages/TipoLicencaPage/TipoLicencaPage";
import TipoObrigacaoPage from "../pages/TipoObrigacaoPage/TipoObrigacaoPage";
import TipoServicoPage from "../pages/TipoServicoPage/TipoServicoPage";
import GerenciarValoresPage from "../pages/ValoresPage/GerenciarValoresPage";
import ValoresProcessoPage from "../pages/ValoresPage/ValoresProcessoPage";
import XCampoPage from "../pages/XCampos/XCampoPage";
import XFinderDistribuicaoPage from "../pages/XFinder/XFinderDistribuicaoPage";
import EnvelopeDashboardPage from "../pages/XSigner/Dashboard/EnvelopeDashboardPage";
import AssinarEnvelope from "../pages/XSigner/Envelopes/AssinarEnvelopePage";
import EditarEnvelopePage from "../pages/XSigner/Envelopes/EditarEnvelopePage";
import EnvelopesPage from "../pages/XSigner/Envelopes/EnvelopesPage";
import XlearningPage from "../pages/Xlearning/XlearningPage";

import ApresentacaoMarcaPage from "../pages/ApresentacaoMarcaPage/ApresentacaoMarcaPage";
import CNJTribunalPage from "../pages/CNJPage/CNJTribunalPage";
import CNJUnidadeOrigemPage from "../pages/CNJPage/CNJUnidadeOrigemPage";
import ClassificacaoIPCPage from "../pages/ClassificacaoIPCPage/ClassificacaoIPCPage";
import PapelSignatarioPage from "../pages/PapelSignatarioPage/PapelSignatarioPage";
import StatusConsultaPage from "../pages/StatusConsultaPage/StatusConsultaPage";
import StatusImovelPage from "../pages/StatusImovelPage/StatusImovelPage";
import StatusProcuracaoPage from "../pages/StatusProcuracaoPage/StatusProcuracaoPage";
import TipoAquisicaoPage from "../pages/TipoAquisicaoPage/TipoAquisicaoPage";
import TipoConsultaPage from "../pages/TipoConsultaPage/TipoConsultaPage";
import TipoInstrumentoPage from "../pages/TipoInstrumentoPage/TipoInstrumentoPage";
import TipoLocalidadePage from "../pages/TipoLocalidadePage/TipoLocalidadePage";
import TipoProcuracaoPage from "../pages/TipoProcuracaoPage/TipoProcuracaoPage";
import XFinderIntimacaoPage from "../pages/XFinder/XFinderIntimacaoPage";

import ContratoConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Contrato/ConfiguracaoTelaPage";
import ContratoSolucoesPage from "../pages/ConfiguracaoTelaPage/Contrato/SolucoesPage";
import TipoContratoPesquisaPage from "../pages/ConfiguracaoTelaPage/Contrato/TipoContratoPesquisaPage";
import ContrapartidaPage from "../pages/ContrapartidaPage/ContrapartidaPage";
import ContratoCadastroPage from "../pages/Contrato/ContratoCadastroPage";
import ContratoCapaPage from "../pages/Contrato/ContratoCapaPage";
import ContratoPastaPage from "../pages/Contrato/ContratoPastaPage";
import PowerBIDashboardPage from "../pages/Contrato/ContratoDashboardPage";
import ContratoPardaoPage from "../pages/Contrato/ContratoPadraoPage";
import ContratoPage from "../pages/Contrato/ContratoPage";

import MarcaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Marca/ConfiguracaoTelaPage";
import MarcaSolucoesPage from "../pages/ConfiguracaoTelaPage/Marca/SolucoesPage";
import TipoMarcaPesquisaPage from "../pages/ConfiguracaoTelaPage/Marca/TipoMarcaPesquisaPage";
import MarcaCadastroPage from "../pages/MarcaPage/MarcaCadastroPage";
import MarcaCapaPage from "../pages/MarcaPage/MarcaCapaPage";
import MarcaPage from "../pages/MarcaPage/MarcaPage";
import StatusMarcaPage from "../pages/StatusMarcaPage/StatusMarcaPage";
import TipoMarcaPage from "../pages/TipoMarcaPage/TipoMarcaPage";
import NaturezaMarcaPage from "../pages/NaturezaMarcaPage/NaturezaMarcaPage";
import ClasseMarcaPage from "../pages/ClasseMarcaPage/ClasseMarcaPage";

import ImovelConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Imovel/ConfiguracaoTelaPage";
import ImovelSolucoesPage from "../pages/ConfiguracaoTelaPage/Imovel/SolucoesPage";
import TipoImovelLimitadaPage from "../pages/ConfiguracaoTelaPage/Imovel/TipoImovelPage";
import ImovelCadastroPage from "../pages/ImovelPage/ImovelCadastroPage";
import ImovelCapaPage from "../pages/ImovelPage/ImovelCapaPage";
import ImovelPage from "../pages/ImovelPage/ImovelPage";
import TipoImovelPage from "../pages/TipoImovelPage/TipoImovelPage";
import ProcessoImovelCadastroPage from "../pages/ImovelPage/ProcessoCadastroPage";
import ProcessoImovelCapaPage from "../pages/ImovelPage/ProcessoCapaPage";
import ProcessoImovelPage from "../pages/ImovelPage/ProcessoPage";

import LicencaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Regulatorio/ConfiguracaoTelaPage";
import LicencaSolucoesPage from "../pages/ConfiguracaoTelaPage/Regulatorio/SolucoesPage";
import TipoLicencaPesquisaPage from "../pages/ConfiguracaoTelaPage/Regulatorio/TipoLicencaPesquisaPage";
import LicencaCadastroPage from "../pages/LicencaPage/LicencaCadastroPage";
import LicencaCapaPage from "../pages/LicencaPage/LicencaCapaPage";
import LicencaPage from "../pages/LicencaPage/LicencaPage";
import LogLicencaPage from "../pages/LogsPage/LogLicencaPage";

import ConsultaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Consultivo/ConfiguracaoTelaPage";
import ConsultaSolucoesPage from "../pages/ConfiguracaoTelaPage/Consultivo/SolucoesPage";
import TipoConsultaPesquisaPage from "../pages/ConfiguracaoTelaPage/Consultivo/TipoConsultaPesquisaPage";
import ConsultaCadastroPage from "../pages/ConsultaPage/ConsultaCadastroPage";
import ConsultaCapaPage from "../pages/ConsultaPage/ConsultaCapaPage";
import ConsultaFAQPage from "../pages/ConsultaPage/ConsultaFAQPage";
import ConsultaPage from "../pages/ConsultaPage/ConsultaPage";
import LogConsultaPage from "../pages/LogsPage/LogConsultaPage";

import ProcuracaoConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Procuracao/ConfiguracaoTelaPage";
import ProcuracaoSolucoesPage from "../pages/ConfiguracaoTelaPage/Procuracao/SolucoesPage";
import TipoProcuracaoPesquisaPage from "../pages/ConfiguracaoTelaPage/Procuracao/TipoProcuracaoPesquisaPage";
import LogProcuracaoPage from "../pages/LogsPage/LogProcuracaoPage";
import ProcuracaoCadastroPage from "../pages/ProcuracaoPage/ProcuracaoCadastroPage";
import ProcuracaoCapaPage from "../pages/ProcuracaoPage/ProcuracaoCapaPage";
import ProcuracaoPage from "../pages/ProcuracaoPage/ProcuracaoPage";
import FechamentoGlossarioPage from "../pages/FechamentoGlossario/FechamentoGlossarioPage";
import BandeiraPage from "../pages/BandeiraPage/BandeiraPage";
import MateriaDeliberadaPage from "../pages/MateriaDeliberadaPage/MateriaDeliberadaPage";
import PracaPage from "../pages/PracaPage/PracaPage";
import SubGrupoPage from "../pages/SubGrupoPage/SubGrupoPage";

import RelatorioProcessoModeloPage from "../pages/ConfiguracaoTelaPage/Processo/RelatorioPage/RelatorioModeloPage";
import ConfiguracaoRelatorioProcessoModeloPage from "../pages/ConfiguracaoTelaPage/Processo/RelatorioPage/ConfiguracaoRelatorioPage";
import TipoSociedadePesquisaPage from "../pages/ConfiguracaoTelaPage/Empresa/TipoSociedadePage";
import TipoSociedadePage from "../pages/TipoSociedadePage/TipoSociedadePage";
import PastaImovelPage from "../pages/Contrato/Imovel/PastaImovelPage";

import AtoSolucoesPage from "../pages/ConfiguracaoTelaPage/Ato/SolucoesPage";
import TipoAtoLimitadaPage from "../pages/ConfiguracaoTelaPage/Ato/TipoAtoPage";
import AtoConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Ato/ConfiguracaoTelaPage";
import AtoSocietarioCadastroPage from "../pages/SocietarioPage/EmpresaPage/Solucoes/AtosSocietarios/AtoSocietarioCadastroPage";
import AtoSocietarioCapaPage from "../pages/SocietarioPage/EmpresaPage/Solucoes/AtosSocietarios/AtoSocietarioCapaPage";
import AtoRegistradoJuntaPage from "../pages/AtoRegistradoJuntaPage/AtoRegistradoJuntaPage";
import StatusAtoPage from "../pages/StatusAtoPage/StatusAtoPage";
import PublicacaoAtoPage from "../pages/PublicacaoAtoPage/PublicacaoAtoPage";
import StatusCitacaoPage from "../pages/StatusCitacaoPage/StatusCitacaoPage";
import LicencaLimitesPage from "../pages/LicencaPage/LicencaLimitesPage";
import ProcessoRegulatorioPage from "../pages/LicencaPage/ProcessoPage";
import ProcessoRegulatorioCapaPage from "../pages/LicencaPage/ProcessoCapaPage";
import ProcessoRegulatorioCadastroPage from "../pages/LicencaPage/ProcessoCadastroPage";

import EditorPage from "../pages/EditorPage/EditorPage";
import LinkEditorPage from "../pages/EditorPage/LinkEditorPage";

import GarantiaPage from "../pages/Garantia/GarantiaPage";
import GarantiaCapaPage from "../pages/Garantia/GarantiaCapaPage";
import GarantiaCadastroPage from "../pages/Garantia/GarantiaCadastroPage";
import TipoGarantiaPesquisaPage from "../pages/ConfiguracaoTelaPage/Garantia/TipoGarantiaPesquisaPage";
import GarantiaConfiguracaoTelaCapaPage from "../pages/ConfiguracaoTelaPage/Garantia/ConfiguracaoTelaPage";
import GarantiaConfiguracaoTelaCadastroPage from "../pages/ConfiguracaoTelaPage/Garantia/ConfiguracaoTelaPage";

import ProtocoloPage from "../pages/ProtocoloPage/ProtocoloPage";
import ProtocoloCapaPage from "../pages/ProtocoloPage/ProtocoloCapaPage";
import ProtocoloCadastroPage from "../pages/ProtocoloPage/ProtocoloCadastroPage";
import TipoProtocoloPesquisaPage from "../pages/ConfiguracaoTelaPage/Protocolo/TipoProtocoloPesquisaPage";
import ProtocoloConfiguracaoTelaCapaPage from "../pages/ConfiguracaoTelaPage/Protocolo/ConfiguracaoTelaPage";
import ProtocoloConfiguracaoTelaCadastroPage from "../pages/ConfiguracaoTelaPage/Protocolo/ConfiguracaoTelaPage";

import PropostaAcordoCapaPage from "../pages/PropostaAcordoPage/PropotaAcordoCapaPage";
import PropostaAcordoCadastroPage from "../pages/PropostaAcordoPage/PropostaAcordoCadastroPage";
import PropostaAcordoCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/PropostaAcordo/ConfiguracaoTelaPage";
import PropostaAcordoCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/PropostaAcordo/ConfiguracaoTelaPage";

import ObrigacaoCapaPage from "../pages/ObrigacaoPage/ObrigacaoCapaPage";
import ObrigacaoCadastroPage from "../pages/ObrigacaoPage/ObrigacaoCadastroPage";
import TipoObrigacaoPesquisaPage from "../pages/ConfiguracaoTelaPage/Obrigacao/TipoObrigacaoPesquisaPage";
import ObrigacaoCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Obrigacao/ConfiguracaoTelaPage";
import ObrigacaoCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Obrigacao/ConfiguracaoTelaPage";

import TipoComunicacaoPesquisaPage from "../pages/ConfiguracaoTelaPage/Comunicacao/TipoComunicacaoPesquisaPage";
import ComunicacaoCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Comunicacao/ConfiguracaoTelaPage";
import ComunicacaoCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Comunicacao/ConfiguracaoTelaPage";

import DespesaCapaPage from "../pages/DespesaPage/DespesaCapaPage";
import DespesaCadastroPage from "../pages/DespesaPage/DespesaCadastroPage";
import TipoDespesaPesquisaPage from "../pages/ConfiguracaoTelaPage/Despesa/TipoDespesaPesquisaPage";
import DespesaCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Despesa/ConfiguracaoTelaPage";
import DespesaCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Despesa/ConfiguracaoTelaPage";

import DesdobramentoCapaPage from "../pages/DesdobramentoPage/DesdobramentoCapaPage";
import DesdobramentoCadastroPage from "../pages/DesdobramentoPage/DesdobramentoCadastroPage";
import DesdobramentoCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Desdobramento/ConfiguracaoTelaPage";
import DesdobramentoCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Desdobramento/ConfiguracaoTelaPage";
import TipoDesdobramentoPesquisaPage from "../pages/ConfiguracaoTelaPage/Desdobramento/TipoDesdobramentoPesquisaPage";

import DecisaoCapaPage from "../pages/Decisao/DecisaoCapaPage";
import DecisaoCadastroPage from "../pages/Decisao/DecisaoCadastroPage";
import TipoDecisaoPesquisaPage from "../pages/ConfiguracaoTelaPage/Decisao/TipoDecisaoPesquisaPage";
import DecisaoCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Decisao/ConfiguracaoTelaPage";
import DecisaoCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Decisao/ConfiguracaoTelaPage";

import BloqueioCapaPage from "../pages/BloqueioPage/BloqueioCapaPage";
import BloqueioCadastroPage from "../pages/BloqueioPage/BloqueioCadastroPage";
import TipoBloqueioPesquisaPage from "../pages/ConfiguracaoTelaPage/Bloqueio/TipoBloqueioPesquisaPage";
import BloqueioCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Bloqueio/ConfiguracaoTelaPage";
import BloqueioCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Bloqueio/ConfiguracaoTelaPage";

import SocietarioPage from "../pages/SocietarioPage/SocietarioPage";
import SocietarioCadastroPage from "../pages/SocietarioPage/SocietarioCadastroPage";
import ConfiguracaoRelatorioSocietarioModeloPage from "../pages/ConfiguracaoTelaPage/Empresa/RelatorioPage/ConfiguracaoRelatorioPage";
import RelatorioSocietarioModeloPage from "../pages/ConfiguracaoTelaPage/Empresa/RelatorioPage/RelatorioModeloPage";

import FluxoPage from "../pages/ConfiguracaoTelaPage/Fluxo/FluxoPage";
import FluxoDesfazerPage from "../pages/ConfiguracaoTelaPage/Fluxo/FluxoDesfazerPage";
import TipoFluxoPesquisaPage from "../pages/ConfiguracaoTelaPage/Fluxo/TipoFluxoPesquisaPage";
import FluxoCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Fluxo/ConfiguracaoTelaPage";
import FluxoCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Fluxo/ConfiguracaoTelaPage";
import FluxoAutomacaoConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Fluxo/ConfiguracaoAutomacaoPage";

import ProcuracaoPastaPage from "../pages/ProcuracaoPage/ProcuracaoPastaPage";
import ContratoDashboardPage from "../pages/Contrato/Dashboard/ContratoDashboardPage";
import TipoHonorarioPage from "../pages/TipoHonorarioPage/TipoHonorarioPage";
import TipoReembolsoPage from "../pages/TipoReembolsoPage/TipoReembolsoPage";

import TipoHonorarioPesquisaPage from "../pages/ConfiguracaoTelaPage/XpayHonorario/TipoHonorarioPesquisaPage";
import TipoHonorarioCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/XpayHonorario/ConfiguracaoTelaPage";
import TipoHonorarioCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/XpayHonorario/ConfiguracaoTelaPage";
import TipoReembolsoPesquisaPage from "../pages/ConfiguracaoTelaPage/XpayReembolso/TipoReembolsoPesquisaPage";
import TipoReembolsoCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/XpayReembolso/ConfiguracaoTelaPage";
import TipoReembolsoCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/XpayReembolso/ConfiguracaoTelaPage";

import AudienciaPage from "../pages/AudienciaPage/AudienciaPage";
import AudienciaCapaPage from "../pages/AudienciaPage/AudienciaCapaPage";
import AudienciaCadastroPage from "../pages/AudienciaPage/AudienciaCadastroPage";
import TipoAudienciaPesquisaPage from "../pages/ConfiguracaoTelaPage/Audiencia/TipoAudienciaPesquisaPage";
import AudienciaCapaConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Audiencia/ConfiguracaoTelaPage";
import AudienciaCadastroConfiguracaoTelaPage from "../pages/ConfiguracaoTelaPage/Audiencia/ConfiguracaoTelaPage";

import HonorarioPage from "../pages/HonorarioPage/HonorarioPage";
import HonorarioCadastroPage from "../pages/HonorarioPage/HonorarioCadastroPage";
import HonorarioCapaPage from "../pages/HonorarioPage/HonorarioCapaPage";

import ReembolsoCadastroPage from "../pages/ReembolsoPage/ReembolsoCadastroPage";
import ReembolsoCapaPage from "../pages/ReembolsoPage/ReembolsoCapaPage";

import XPayContratoPage from "../pages/XPayContrato/XPayContratoPage";
import XPayFechamentoPage from "../pages/XPayFechamentoPage/XPayFechamentoPage";
import TipoAudienciaPage from "../pages/TipoAudienciaPage/TipoAudienciaPage";
import ResultadoAudienciaPage from "../pages/ResultadoAudienciaPage/ResultadoAudienciaPage";
import ResultadoSentencaPage from "../pages/ResultadoSentencaPage/ResultadoAudienciaPage";
import ConfiguracaoRelatorioContratoModeloPage from "../pages/ConfiguracaoTelaPage/Contrato/RelatorioPage/ConfiguracaoRelatorioPage";
import RelatorioContratoModeloPage from "../pages/ConfiguracaoTelaPage/Contrato/RelatorioPage/RelatorioContratoModeloPage";
import RelatorioImovelModeloPage from "../pages/ConfiguracaoTelaPage/Imovel/RelatorioPage/RelatorioImovelModeloPage";
import ConfiguracaoRelatorioImovelModeloPage from "../pages/ConfiguracaoTelaPage/Imovel/RelatorioPage/ConfiguracaoRelatorioPage";
import TipoPropostaAcordoPesquisaPage from "../pages/ConfiguracaoTelaPage/PropostaAcordo/TipoPropostaAcordoPesquisaPage";
import ImovelDashboardPage from "../pages/ImovelPage/ImovelDashboardPage";
import ProcessoDashboardPage from "../pages/ProcessoPage/ProcessoDashboardPage";

import TipoParteContrariaPesquisaPage from "../pages/ConfiguracaoTelaPage/ParteContraria/TipoParteContrariaPesquisaPage";
import ConfiguracaoCadastroParteContrariaPage from "../pages/ConfiguracaoTelaPage/ParteContraria/ConfiguracaoTelaPage";
import ConfiguracaoCapaParteContrariaPage from "../pages/ConfiguracaoTelaPage/ParteContraria/ConfiguracaoTelaPage";
import ConfiguracaoPropriedadePage from "../pages/ConfiguracaoTelaPage/Propriedade/ConfiguracaoPropriedadePage";
import ConfiguracaoSeparadorPage from "../pages/ConfiguracaoTelaPage/Separador/ConfiguracaoSeparadorPage";

import DespesaImovelFiltroPage from "../pages/DespesaPage/DespesaImovelFiltroPage";
import ConfiguracaoRelatorioDespesaModeloPage from "../pages/ConfiguracaoTelaPage/Despesa/RelatorioPage/ConfiguracaoRelatorioPage";
import RelatorioDespesaModeloPage from "../pages/ConfiguracaoTelaPage/Despesa/RelatorioPage/RelatorioImovelModeloPage";
import RegulatorioDashboardPage from "../pages/LicencaPage/LicencaDashboardPage";
import RelatorioPage from "../pages/RelatorioPage/RelatorioPage";
import EditorHistoryPage from "../pages/EditorPage/HistoryPage";
import ReembolsoPage from "../pages/ReembolsoPage/ReembolsoPage";

import PerfilPermissoesPage from "../pages/PerfilPermissoesPage/PerfilPermissoesPage";
import PerfilPermissoesPageEdit from "../pages/PerfilPermissoesPage/PerfilPermissoesPageEdit";
import GrupoUsuariosPage from "../pages/Admininstracao/Seguranca/GrupoUsuarios/GrupoUsuariosPage";
import GrupoUsuarioAddUsers from "../pages/Admininstracao/Seguranca/GrupoUsuarios/GrupoUsuariosAddUserPage";
import NovoEditorPage from "../pages/EditorPage/NovoEditorPage";
import FornecedoresPage from "../pages/Fornecedores/FornecedoresPage";
import FornecedoresCadastroPage from "../pages/Fornecedores/FornecedoresCadastroPage";
import AdvogadoInternoPage from "../pages/Admininstracao/Seguranca/AdvogadoInternoPage/AdvogadoInternoPage";
import DashboardTabelaPage from "../pages/Dashboard/DashboardPage";
import TipoProvisaoPage from "../pages/TipoProvisaoPage/TipoProvisaoPage";
import ModeloDocumentoPage from "../pages/ModeloDocumentoPage/ModeloDocumentoPage";
import ChangeLog from "../pages/UpdatesPage/ChangeLog";
import NovoChangeLog from "../pages/UpdatesPage/NovoChangeLog";
import ChangeLogEmailPage from "../pages/UpdatesPage/ChangeLogEmailPage";
import UpdatesPage from "../pages/UpdatesPage/UpdatesPage";
import MigracoesPage from "../pages/Admininstracao/Seguranca/Migracoes/MigracoesPage";
import MigracoesAddPage from "../pages/Admininstracao/Seguranca/Migracoes/MigracoesAddPage";
import RelatorioParametrizacaoPage from "../pages/ConfiguracaoTelaPage/Relatorio/RelatorioParametrizacaoPage";
import RelatorioParametrizacaoConfig from "../pages/ConfiguracaoTelaPage/Relatorio/RelatorioParametrizacaoConfig";

export const PrivateRoutes = () => (
  <Routes>
    <Route path="/Home">
      <Route index element={<HomePage />} />
    </Route>

    <Route path="/Fluxo">
      <Route index element={<TipoFluxoPesquisaPage />} />
    </Route>

    <Route path="/Fluxo/FluxoFase">
      <Route index element={<FluxoPage />} />
    </Route>

    <Route path="/Fluxo/FluxoDesfazer">
      <Route index element={<FluxoDesfazerPage />} />
    </Route>

    <Route path="/Fluxo/ConfiguracaoTela/Cadastro">
      <Route index element={<FluxoCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Fluxo/ConfiguracaoTela/Automacao">
      <Route index element={<FluxoAutomacaoConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Fluxo/ConfiguracaoTela/Capa">
      <Route index element={<FluxoCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Automacao">
      <Route index element={<AutomacaoPage />} />
    </Route>

    <Route path="/AndamentoTipo">
      <Route index element={<AndamentoTipoPage />} />
    </Route>

    <Route path="Comunicacao/ConfiguracaoTela/TipoComunicacao">
      <Route index element={<TipoComunicacaoPesquisaPage />} />
    </Route>

    <Route path="Comunicacao/ConfiguracaoTela/Cadastro">
      <Route index element={<ComunicacaoCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Comunicacao/ConfiguracaoTela/Capa">
      <Route index element={<ComunicacaoCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Obrigacao/Novo">
      <Route index element={<ObrigacaoCadastroPage />} />
    </Route>

    <Route path="Obrigacao/Capa">
      <Route index element={<ObrigacaoCapaPage />} />
    </Route>

    <Route path="Obrigacao/ConfiguracaoTela/TipoObrigacao">
      <Route index element={<TipoObrigacaoPesquisaPage />} />
    </Route>

    <Route path="Obrigacao/ConfiguracaoTela/Cadastro">
      <Route index element={<ObrigacaoCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Obrigacao/ConfiguracaoTela/Capa">
      <Route index element={<ObrigacaoCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Desdobramento/Novo">
      <Route index element={<DesdobramentoCadastroPage />} />
    </Route>

    <Route path="Desdobramento/Capa">
      <Route index element={<DesdobramentoCapaPage />} />
    </Route>

    <Route path="Desdobramento/ConfiguracaoTela/TipoDesdobramento">
      <Route index element={<TipoDesdobramentoPesquisaPage />} />
    </Route>

    <Route path="Desdobramento/ConfiguracaoTela/Cadastro">
      <Route index element={<DesdobramentoCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Desdobramento/ConfiguracaoTela/Capa">
      <Route index element={<DesdobramentoCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Decisao/Novo">
      <Route index element={<DecisaoCadastroPage />} />
    </Route>

    <Route path="Decisao/Capa">
      <Route index element={<DecisaoCapaPage />} />
    </Route>
    <Route path="ParteContraria/ConfiguracaoTela/Cadastro">
      <Route index element={<ConfiguracaoCadastroParteContrariaPage />} />
    </Route>

    <Route path="ParteContraria/ConfiguracaoTela/Capa">
      <Route index element={<ConfiguracaoCapaParteContrariaPage />} />
    </Route>

    <Route path="ParteContraria/ConfiguracaoTela/TipoParteContraria">
      <Route index element={<TipoParteContrariaPesquisaPage />} />
    </Route>

    <Route path="Decisao/ConfiguracaoTela/TipoDecisao">
      <Route index element={<TipoDecisaoPesquisaPage />} />
    </Route>

    <Route path="Decisao/ConfiguracaoTela/Cadastro">
      <Route index element={<DecisaoCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Decisao/ConfiguracaoTela/Capa">
      <Route index element={<DecisaoCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Bloqueio/Novo">
      <Route index element={<BloqueioCadastroPage />} />
    </Route>

    <Route path="Bloqueio/Capa">
      <Route index element={<BloqueioCapaPage />} />
    </Route>

    <Route path="Bloqueio/ConfiguracaoTela/TipoBloqueio">
      <Route index element={<TipoBloqueioPesquisaPage />} />
    </Route>

    <Route path="Bloqueio/ConfiguracaoTela/Cadastro">
      <Route index element={<BloqueioCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Bloqueio/ConfiguracaoTela/Capa">
      <Route index element={<BloqueioCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Despesa/Novo">
      <Route index element={<DespesaCadastroPage />} />
    </Route>

    <Route path="Despesa/Capa">
      <Route index element={<DespesaCapaPage />} />
    </Route>

    <Route path="Despesa/ConfiguracaoTela/TipoDespesa">
      <Route index element={<TipoDespesaPesquisaPage />} />
    </Route>

    <Route path="Despesa/ConfiguracaoTela/Cadastro">
      <Route index element={<DespesaCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Despesa/ConfiguracaoTela/Capa">
      <Route index element={<DespesaCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="PropostaAcordo/ConfiguracaoTela/TipoPropostaAcordo">
      <Route index element={<TipoPropostaAcordoPesquisaPage />} />
    </Route>

    <Route path="PropostaAcordo/ConfiguracaoTela/Cadastro">
      <Route index element={<PropostaAcordoCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="PropostaAcordo/ConfiguracaoTela/Capa">
      <Route index element={<PropostaAcordoCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="PropostaAcordo/Novo">
      <Route index element={<PropostaAcordoCadastroPage />} />
    </Route>

    <Route path="PropostaAcordo/Capa">
      <Route index element={<PropostaAcordoCapaPage />} />
    </Route>

    <Route path="Processo/ConfiguracaoTela/AreaDireito">
      <Route index element={<ProcessoPesquisaLimitadaPage />} />
    </Route>

    <Route path=":Rota/ConfiguracaoTela/Pesquisa/:moduloSelecionado">
      <Route index element={<PesquisaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Processo/ConfiguracaoRelatorio">
      <Route index element={<RelatorioProcessoModeloPage />} />
    </Route>

    <Route path="Processo/ConfiguracaoRelatorio/Modelo">
      <Route index element={<ConfiguracaoRelatorioProcessoModeloPage />} />
    </Route>

    <Route path="/TipoProvisao">
      <Route index element={<TipoProvisaoPage />} />
    </Route>

    <Route path="Contrato/ConfiguracaoRelatorio">
      <Route index element={<RelatorioContratoModeloPage />} />
    </Route>

    <Route path="Contrato/ConfiguracaoRelatorio/Modelo">
      <Route index element={<ConfiguracaoRelatorioContratoModeloPage />} />
    </Route>

    <Route path="Imovel/ConfiguracaoRelatorio">
      <Route index element={<RelatorioImovelModeloPage />} />
    </Route>

    <Route path="Imovel/ConfiguracaoRelatorio/Modelo">
      <Route index element={<ConfiguracaoRelatorioImovelModeloPage />} />
    </Route>

    <Route path="Despesa/ConfiguracaoRelatorio">
      <Route index element={<RelatorioDespesaModeloPage />} />
    </Route>

    <Route path="Despesa/ConfiguracaoRelatorio/Modelo">
      <Route index element={<ConfiguracaoRelatorioDespesaModeloPage />} />
    </Route>

    <Route path="Fechamento/ConfiguracaoTela/FechamentoConfiguracao">
      <Route index element={<FechamentoConfiguracaoPage />} />
    </Route>

    <Route path="Fechamento/ConfiguracaoTela/FechamentoParametrizacao">
      <Route index element={<FechamentoParametrizacao />} />
    </Route>

    <Route path="Fechamento/ConfiguracaoTela/FechamentoGlossario">
      <Route index element={<FechamentoGlossarioPage />} />
    </Route>

    <Route path="Processo/ConfiguracaoTela/Solucoes">
      <Route index element={<ProcessoSolucoesPage />} />
    </Route>

    <Route path="Processo/ConfiguracaoTela/Cadastro">
      <Route index element={<ProcessoConfiguracaoTelaPage />} />
    </Route>

    <Route path="Processo/ConfiguracaoTela/Capa">
      <Route index element={<ProcessoConfiguracaoTelaPage />} />
    </Route>

    <Route path="Configuracao/Propriedade">
      <Route index element={<ConfiguracaoPropriedadePage />} />
    </Route>

    <Route path="Consulta/Kanban">
      <Route index element={<Kanban />} />
    </Route>

    <Route path="Configuracao/Separador">
      <Route index element={<ConfiguracaoSeparadorPage />} />
    </Route>

    <Route path="/Regulatorio/Processo/Novo">
      <Route index element={<ProcessoRegulatorioCadastroPage />} />
    </Route>

    <Route path="/Processo/Novo">
      <Route index element={<ProcessoCadastroPage />} />
    </Route>

    <Route path="/Processo/Cadastro">
      <Route index element={<ProcessoCadastroPage />} />
    </Route>

    <Route path="/Regulatorio/Processo/Cadastro">
      <Route index element={<ProcessoRegulatorioCadastroPage />} />
    </Route>

    <Route path="/Processo/Capa">
      <Route index element={<ProcessoCapaPage />} />
    </Route>

    <Route path="Regulatorio/Processo/Capa">
      <Route index element={<ProcessoRegulatorioCapaPage />} />
    </Route>

    <Route path="/Processo/Dashboard">
      <Route index element={<ProcessoDashboardPage />} />
    </Route>

    <Route path="/Processo/Padrao">
      <Route index element={<ProcessoPadraoPage />} />
    </Route>

    <Route path="/Processo">
      <Route index element={<ProcessoPage />} />
    </Route>

    <Route path="Contrato/PowerBI">
      <Route index element={<PowerBIDashboardPage />} />
    </Route>

    <Route path="Contrato/ConfiguracaoTela/TipoContrato">
      <Route index element={<TipoContratoPesquisaPage />} />
    </Route>

    <Route path="Contrato/ConfiguracaoTela/Cadastro">
      <Route index element={<ContratoConfiguracaoTelaPage />} />
    </Route>

    <Route path="/:caller/Editor">
      <Route index element={<EditorPage />} />
    </Route>

    <Route path="/:caller/NovoEditor">
      <Route index element={<NovoEditorPage />} />
    </Route>

    <Route path="/:caller/Editor/Historico">
      <Route index element={<EditorHistoryPage />} />
    </Route>

    <Route path="/EditorDocumento/:email/:hash/:id">
      <Route index element={<LinkEditorPage />} />
    </Route>

    <Route path="Contrato/ConfiguracaoTela/Capa">
      <Route index element={<ContratoConfiguracaoTelaPage />} />
    </Route>

    <Route path="Contrato/ConfiguracaoTela/Solucoes">
      <Route index element={<ContratoSolucoesPage />} />
    </Route>

    <Route path="/StatusCitacao">
      <Route index element={<StatusCitacaoPage />} />
    </Route>

    <Route path="/Contrato/ContratoPastaPage">
      <Route index element={<ContratoPastaPage />} />
    </Route>

    <Route path="/Procuracao/ProcuracaoPastaPage">
      <Route index element={<ProcuracaoPastaPage />} />
    </Route>

    <Route path="/Contrato/ContratoPastaImovelPage">
      <Route index element={<PastaImovelPage />} />
    </Route>

    <Route path="/Contrato/Novo">
      <Route index element={<ContratoCadastroPage />} />
    </Route>

    <Route path="/Contrato/Capa">
      <Route index element={<ContratoCapaPage />} />
    </Route>

    <Route path="/Contrato/Padrao">
      <Route index element={<ContratoPardaoPage />} />
    </Route>

    <Route path="/Contrato">
      <Route index element={<ContratoPage />} />
    </Route>

    <Route path="Ato/ConfiguracaoTela/TipoAto">
      <Route index element={<TipoAtoLimitadaPage />} />
    </Route>

    <Route path="Ato/ConfiguracaoTela/Solucoes">
      <Route index element={<AtoSolucoesPage />} />
    </Route>

    <Route path="/Ato/ConfiguracaoTela/Capa">
      <Route index element={<AtoConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Ato/ConfiguracaoTela/Cadastro">
      <Route index element={<AtoConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Ato/Novo">
      <Route index element={<AtoSocietarioCadastroPage />} />
    </Route>

    <Route path="/Ato/Capa">
      <Route index element={<AtoSocietarioCapaPage />} />
    </Route>

    <Route path="Marca/ConfiguracaoTela/TipoMarca">
      <Route index element={<TipoMarcaPesquisaPage />} />
    </Route>

    <Route path="Marca/ConfiguracaoTela/Solucoes">
      <Route index element={<MarcaSolucoesPage />} />
    </Route>

    <Route path="/Marca/ConfiguracaoTela/Capa">
      <Route index element={<MarcaConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Marca/ConfiguracaoTela/Cadastro">
      <Route index element={<MarcaConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Marca/Novo">
      <Route index element={<MarcaCadastroPage />} />
    </Route>

    <Route path="/Marca/Capa">
      <Route index element={<MarcaCapaPage />} />
    </Route>

    <Route path="/Marca">
      <Route index element={<MarcaPage />} />
    </Route>

    <Route path="/Imovel/Processo/Novo">
      <Route index element={<ProcessoImovelCadastroPage />} />
    </Route>

    <Route path="/Imovel/Processo/Capa">
      <Route index element={<ProcessoImovelCapaPage />} />
    </Route>

    <Route path="/Imovel/Processo">
      <Route index element={<ProcessoImovelPage />} />
    </Route>

    <Route path="Imovel/ConfiguracaoTela/TipoImovel">
      <Route index element={<TipoImovelLimitadaPage />} />
    </Route>

    <Route path="Imovel/ConfiguracaoTela/Solucoes">
      <Route index element={<ImovelSolucoesPage />} />
    </Route>

    <Route path="Imovel/ConfiguracaoTela/Capa">
      <Route index element={<ImovelConfiguracaoTelaPage />} />
    </Route>

    <Route path="Imovel/ConfiguracaoTela/Cadastro">
      <Route index element={<ImovelConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Imovel/Novo">
      <Route index element={<ImovelCadastroPage />} />
    </Route>

    <Route path="/Imovel/Capa">
      <Route index element={<ImovelCapaPage />} />
    </Route>

    <Route path="/Imovel/Dashboard">
      <Route index element={<ImovelDashboardPage />} />
    </Route>

    <Route path="/Imovel">
      <Route index element={<ImovelPage />} />
    </Route>

    <Route path="/Audiencia">
      <Route index element={<AudienciaPage />} />
    </Route>

    <Route path="Audiencia/Novo">
      <Route index element={<AudienciaCadastroPage />} />
    </Route>

    <Route path="Audiencia/Capa">
      <Route index element={<AudienciaCapaPage />} />
    </Route>

    <Route path="Audiencia/ConfiguracaoTela/TipoAudiencia">
      <Route index element={<TipoAudienciaPesquisaPage />} />
    </Route>

    <Route path="/Audiencia/ConfiguracaoTela/Capa">
      <Route index element={<AudienciaCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Audiencia/ConfiguracaoTela/Cadastro">
      <Route index element={<AudienciaCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Licenca/ConfiguracaoTela/TipoLicenca">
      <Route index element={<TipoLicencaPesquisaPage />} />
    </Route>

    <Route path="Licenca/ConfiguracaoTela/Solucoes">
      <Route index element={<LicencaSolucoesPage />} />
    </Route>

    <Route path="Licenca/ConfiguracaoTela/Capa">
      <Route index element={<LicencaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Licenca/ConfiguracaoTela/Cadastro">
      <Route index element={<LicencaConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Licenca/Novo">
      <Route index element={<LicencaCadastroPage />} />
    </Route>

    <Route path="/Licenca/Capa">
      <Route index element={<LicencaCapaPage />} />
    </Route>

    <Route path="/Licenca">
      <Route index element={<LicencaPage />} />
    </Route>

    <Route path="/LicencaLimites">
      <Route index element={<LicencaLimitesPage />} />
    </Route>

    <Route path="/Licenca/Dashboard">
      <Route index element={<RegulatorioDashboardPage />} />
    </Route>

    <Route path="Consulta/ConfiguracaoTela/TipoConsulta">
      <Route index element={<TipoConsultaPesquisaPage />} />
    </Route>

    <Route path="Relatorio/ConfiguracaoRelatorio">
      <Route index element={<RelatorioParametrizacaoPage />} />
    </Route>

    <Route path="Relatorio/ConfiguracaoRelatorio/:id">
      <Route index element={<RelatorioParametrizacaoConfig />} />
    </Route>

    <Route path="Consulta/ConfiguracaoTela/Solucoes">
      <Route index element={<ConsultaSolucoesPage />} />
    </Route>

    <Route path="Consulta/ConfiguracaoTela/Capa">
      <Route index element={<ConsultaConfiguracaoTelaPage />} />
    </Route>

    <Route path="Consulta/ConfiguracaoTela/Cadastro">
      <Route index element={<ConsultaConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Consulta/Novo">
      <Route index element={<ConsultaCadastroPage />} />
    </Route>

    <Route path="/Consulta/Capa">
      <Route index element={<ConsultaCapaPage />} />
    </Route>

    <Route path="/Consulta">
      <Route index element={<ConsultaPage />} />
    </Route>
    <Route path="/Consulta/FAQ">
      <Route index element={<ConsultaFAQPage />} />
    </Route>

    <Route path="Procuracao/ConfiguracaoTela/TipoProcuracao">
      <Route index element={<TipoProcuracaoPesquisaPage />} />
    </Route>

    <Route path="Procuracao/ConfiguracaoTela/Solucoes">
      <Route index element={<ProcuracaoSolucoesPage />} />
    </Route>

    <Route path="Procuracao/ConfiguracaoTela/Capa">
      <Route index element={<ProcuracaoConfiguracaoTelaPage />} />
    </Route>

    <Route path="Procuracao/ConfiguracaoTela/Cadastro">
      <Route index element={<ProcuracaoConfiguracaoTelaPage />} />
    </Route>

    <Route path="/Procuracao/Novo">
      <Route index element={<ProcuracaoCadastroPage />} />
    </Route>

    <Route path="/Procuracao/Capa">
      <Route index element={<ProcuracaoCapaPage />} />
    </Route>

    <Route path="/Procuracao">
      <Route index element={<ProcuracaoPage />} />
    </Route>

    <Route path="/XCampo">
      <Route index element={<XCampoPage />} />
    </Route>

    <Route path="/Ambito">
      <Route index element={<AmbitoPage />} />
    </Route>

    <Route path="/Juiz">
      <Route index element={<JuizPage />} />
    </Route>

    <Route path="/StatusObrigacao">
      <Route index element={<StatusObrigacaoPage />} />
    </Route>

    <Route path="/TipoObrigacao">
      <Route index element={<TipoObrigacaoPage />} />
    </Route>

    <Route path="/OrgaoResponsavel">
      <Route index element={<OrgaoResponsavelPage />} />
    </Route>

    <Route path="/TipoLicenca">
      <Route index element={<TipoLicencaPage />} />
    </Route>

    <Route path="/TipoServico">
      <Route index element={<TipoServicoPage />} />
    </Route>

    <Route path="/Obrigacao">
      <Route index element={<ObrigacaoPage />} />
    </Route>

    <Route path="Garantia/ConfiguracaoTela/TipoGarantia">
      <Route index element={<TipoGarantiaPesquisaPage />} />
    </Route>

    <Route path="Garantia/ConfiguracaoTela/Cadastro">
      <Route index element={<GarantiaConfiguracaoTelaCadastroPage />} />
    </Route>

    <Route path="Garantia/ConfiguracaoTela/Capa">
      <Route index element={<GarantiaConfiguracaoTelaCapaPage />} />
    </Route>

    <Route path="/Garantia">
      <Route index element={<GarantiaPage />} />
    </Route>

    <Route path="/Garantia/Novo">
      <Route index element={<GarantiaCadastroPage />} />
    </Route>

    <Route path="/Garantia/Capa">
      <Route index element={<GarantiaCapaPage />} />
    </Route>

    <Route path="Protocolo/ConfiguracaoTela/TipoProtocolo">
      <Route index element={<TipoProtocoloPesquisaPage />} />
    </Route>

    <Route path="Protocolo/ConfiguracaoTela/Cadastro">
      <Route index element={<ProtocoloConfiguracaoTelaCadastroPage />} />
    </Route>

    <Route path="Protocolo/ConfiguracaoTela/Capa">
      <Route index element={<ProtocoloConfiguracaoTelaCapaPage />} />
    </Route>

    <Route path="/Protocolo/Novo">
      <Route index element={<ProtocoloCadastroPage />} />
    </Route>

    <Route path="/Protocolo/Capa">
      <Route index element={<ProtocoloCapaPage />} />
    </Route>

    <Route path="/Protocolo">
      <Route index element={<ProtocoloPage />} />
    </Route>

    <Route path="/Decisao">
      <Route index element={<DecisaoPage />} />
    </Route>

    <Route path="/PropostaAcordo">
      <Route index element={<PropostaAcordoPage />} />
    </Route>

    <Route path="/Classificacao">
      <Route index element={<ClassificacaoPage />} />
    </Route>

    <Route path="/AreaDireito">
      <Route index element={<AreaDireitoPage />} />
    </Route>

    <Route path="/Cliente">
      <Route index element={<ClientePage />} />
    </Route>

    <Route path="/Cliente/Cadastrar">
      <Route index element={<ClienteCadastroPage />} />
    </Route>

    <Route path="/Esfera">
      <Route index element={<EsferaPage />} />
    </Route>

    <Route path="/FaseProcessual">
      <Route index element={<FaseProcessualPage />} />
    </Route>

    <Route path="/FormaParticipacao">
      <Route index element={<FormaParticipacaoPage />} />
    </Route>

    <Route path="/TipoContrato">
      <Route index element={<TipoContratoPage />} />
    </Route>

    <Route path="/CNJOrgao">
      <Route index element={<CNJOrgaoPage />} />
    </Route>

    <Route path="/CNJTribunal">
      <Route index element={<CNJTribunalPage />} />
    </Route>

    <Route path="/CNJUnidadeOrigem">
      <Route index element={<CNJUnidadeOrigemPage />} />
    </Route>

    <Route path="/OrigemObrigacao">
      <Route index element={<OrigemObrigacaoPage />} />
    </Route>

    <Route path="/Instancia">
      <Route index element={<InstanciaPage />} />
    </Route>

    <Route path="/statusBloqueio">
      <Route index element={<StatusBloqueioPage />} />
    </Route>

    <Route path="/MotivoEncerramento">
      <Route index element={<MotivoEncerramentoPage />} />
    </Route>

    <Route path="/MotivoEncerramentoConsulta">
      <Route index element={<MotivoEncerramentoConsultaPage />} />
    </Route>

    <Route path="/GrupoEconomico">
      <Route index element={<GrupoEconomicoPage />} />
    </Route>

    <Route path="/TipoSociedade">
      <Route index element={<TipoSociedadePage />} />
    </Route>

    <Route path="Empresa/ConfiguracaoTela/TipoSociedade">
      <Route index element={<TipoSociedadePesquisaPage />} />
    </Route>

    <Route path="Empresa/ConfiguracaoTela/Solucoes">
      <Route index element={<SociedadeSolucoesPage />} />
    </Route>

    <Route path="Empresa/ConfiguracaoTela/Capa">
      <Route index element={<EmpresaConfiguracaoTela />} />
    </Route>

    <Route path="Empresa/ConfiguracaoTela/Cadastro">
      <Route index element={<EmpresaConfiguracaoTela />} />
    </Route>

    <Route path="Societario/ConfiguracaoRelatorio">
      <Route index element={<RelatorioSocietarioModeloPage />} />
    </Route>

    <Route path="Societario/ConfiguracaoRelatorio/Modelo">
      <Route index element={<ConfiguracaoRelatorioSocietarioModeloPage />} />
    </Route>

    <Route path="/Societario/Empresa">
      <Route index element={<SocietarioPage />} />
    </Route>

    <Route path="/Societario/Empresa/Novo">
      <Route index element={<EmpresaCadastroPage />} />
    </Route>

    <Route path="/Societario/Novo">
      <Route index element={<SocietarioCadastroPage />} />
    </Route>

    <Route path="/Societario/Empresa/Capa">
      <Route index element={<EmpresaCapaPage />} />
    </Route>

    <Route path="/Societario/Empresa/Solucoes/Filiais">
      <Route index element={<FiliaisPage />} />
    </Route>

    <Route path="/Societario/Empresa/Solucoes/AtosSocietario">
      <Route index element={<AtosSocietariosPage />} />
    </Route>

    <Route path="/Societario/Empresa/Solucoes/Representante">
      <Route index element={<RepresentantesPage />} />
    </Route>

    <Route path="/Societario/Empresa/Solucoes/DistribuicaoCapitalPage">
      <Route index element={<DistribuicaoCapitalPage />} />
    </Route>

    <Route path="/Societario/Empresa/Solucoes/ConselhoPage">
      <Route index element={<ConselhoPage />} />
    </Route>

    <Route path="/TipoPedido">
      <Route index element={<TipoPedidoPage />} />
    </Route>

    <Route path="/Risco">
      <Route index element={<RiscoPage />} />
    </Route>

    <Route path="/StatusProcesso">
      <Route index element={<StatusProcessoPage />} />
    </Route>

    <Route path="/StatusPropostaAcordo">
      <Route index element={<StatusPropostaAcordoPage />} />
    </Route>

    <Route path="/SubAreaDireito">
      <Route index element={<SubAreaDireitoPage />} />
    </Route>

    <Route path="/TipoAcao">
      <Route index element={<TipoAcaoPage />} />
    </Route>

    <Route path="/TipoContingencia">
      <Route index element={<TipoContingenciaPage />} />
    </Route>

    <Route path="/banco">
      <Route index element={<BancoPage />} />
    </Route>

    <Route path="/TipoDocumento">
      <Route index element={<TipoDocumentoPage />} />
    </Route>

    <Route path="/Estado">
      <Route index element={<EstadoPage />} />
    </Route>

    <Route path="/Cidade">
      <Route index element={<CidadePage />} />
    </Route>

    <Route path="/Cargo">
      <Route index element={<CargoPage />} />
    </Route>

    <Route path="/Separador">
      <Route index element={<SeparadorPage />} />
    </Route>

    <Route path="/TipoComunicacao">
      <Route index element={<TipoComunicacaoPage />} />
    </Route>

    <Route path="/CentroCusto">
      <Route index element={<CentroCustoPage />} />
    </Route>

    <Route path="/Departamento">
      <Route index element={<DepartamentoPage />} />
    </Route>

    <Route path="/EstadoCivil">
      <Route index element={<EstadoCivilPage />} />
    </Route>

    <Route path="/Indice">
      <Route index element={<IndicePage />} />
    </Route>

    <Route path="/Modulo">
      <Route index element={<ModuloPage />} />
    </Route>

    <Route path="/ModeloDocumento">
      <Route index element={<ModeloDocumentoPage />} />
    </Route>

    <Route path="/Moeda">
      <Route index element={<MoedaPage />} />
    </Route>

    <Route path="/Periodicidade">
      <Route index element={<PeriodicidadePage />} />
    </Route>

    <Route path="/MotivoDesligamento">
      <Route index element={<MotivoDesligamentoPage />} />
    </Route>

    <Route path="/TipoDesdobramento">
      <Route index element={<TipoDesdobramentoPage />} />
    </Route>

    <Route path="/StatusDesdobramento">
      <Route index element={<StatusDesdobramentoPage />} />
    </Route>

    <Route path="/StatusFolha">
      <Route index element={<StatusFolhaPage />} />
    </Route>

    <Route path="/TipoGarantia">
      <Route index element={<TipoGarantiaPage />} />
    </Route>

    <Route path="/StatusGarantia">
      <Route index element={<StatusGarantiaPage />} />
    </Route>

    <Route path="/Pais">
      <Route index element={<PaisPage />} />
    </Route>

    <Route path="/AtividadeEmpresa">
      <Route index element={<AtividadeEmpresaPage />} />
    </Route>

    <Route path="/AtoRegistradoJunta">
      <Route index element={<AtoRegistradoJuntaPage />} />
    </Route>

    <Route path="/Bandeira">
      <Route index element={<BandeiraPage />} />
    </Route>

    <Route path="/MateriaDeliberada">
      <Route index element={<MateriaDeliberadaPage />} />
    </Route>

    <Route path="/OrgaoRegistro">
      <Route index element={<OrgaoRegistroPage />} />
    </Route>

    <Route path="/Praca">
      <Route index element={<PracaPage />} />
    </Route>

    <Route path="/PublicacaoAto">
      <Route index element={<PublicacaoAtoPage />} />
    </Route>

    <Route path="/SubGrupo">
      <Route index element={<SubGrupoPage />} />
    </Route>

    <Route path="/StatusAto">
      <Route index element={<StatusAtoPage />} />
    </Route>

    <Route path="/TipoEmpresa">
      <Route index element={<TipoEmpresaPage />} />
    </Route>

    <Route path="/TipoSociedade">
      <Route index element={<TipoSociedadePage />} />
    </Route>

    <Route path="/Dashboard">
      <Route index element={<DashboardTabelaPage />} />
    </Route>

    <Route path="/Perfil">
      <Route index element={<PerfilPage />} />
    </Route>

    <Route path="/Alterar-Senha">
      <Route index element={<AlterarSenhaPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/AdvogadoInterno">
      <Route index element={<AdvogadoInternoPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/GrupoUsuarios">
      <Route index element={<GrupoUsuariosPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/Migracoes">
      <Route index element={<MigracoesPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/AdicionarMigracao">
      <Route index element={<MigracoesAddPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/GrupoUsuarios/:id">
      <Route index element={<GrupoUsuarioAddUsers />} />
    </Route>

    <Route path="/Administracao/Seguranca/PerfilPermissoes">
      <Route index element={<PerfilPermissoesPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/PerfilPermissoes/:id">
      <Route index element={<PerfilPermissoesPageEdit />} />
    </Route>

    <Route path="/Administracao/Seguranca/Usuarios">
      <Route index element={<UsuarioPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/XFinder">
      <Route index element={<XFinderIntimacaoLoginPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/Usuarios/Cadastrar">
      <Route index element={<UsuarioCadastroPage />} />
    </Route>

    <Route path="/Administracao/Seguranca/Usuarios/Editar/:id">
      <Route index element={<UsuarioCadastroPage />} />
    </Route>

    <Route path="/Processo">
      <Route index element={<ProcessoPadraoPage />} />
    </Route>

    <Route path="/Processo/Regulatorio">
      <Route index element={<ProcessoRegulatorioPage />} />
    </Route>

    <Route path="/Contencioso/Dashboards/DashboardFechamento">
      <Route index element={<FechamentoDashboardPage />} />
    </Route>

    <Route path="/FormaPagamento">
      <Route index element={<FormaPagamentoPage />} />
    </Route>

    <Route path="/Fornecedores">
      <Route index element={<FornecedoresPage />} />
    </Route>

    <Route path="/Fornecedores/Adicionar">
      <Route index element={<FornecedoresCadastroPage />} />
    </Route>

    <Route path="/StatusDespesa">
      <Route index element={<StatusDespesaPage />} />
    </Route>

    <Route path="/TipoDespesa">
      <Route index element={<TipoDespesaPage />} />
    </Route>

    <Route path="/Despesa">
      <Route index element={<DespesaPage />} />
    </Route>

    <Route path="/Imovel/DespesaFiltro">
      <Route index element={<DespesaImovelFiltroPage />} />
    </Route>

    <Route path="/Desdobramento">
      <Route index element={<DesdobramentoPage />} />
    </Route>

    <Route path="/Bloqueio">
      <Route index element={<BloqueioPage />} />
    </Route>

    <Route path="/Fechamento">
      <Route index element={<FechamentoPage />} />
    </Route>

    <Route path="/XFinder/XFinderDistribuicao">
      <Route index element={<XFinderDistribuicaoPage />} />
    </Route>

    <Route path="/Publicacao">
      <Route index element={<PublicacaoPage />} />
    </Route>

    <Route path="/Andamento">
      <Route index element={<AndamentoPage />} />
    </Route>

    <Route path="/XFinder/XFinderIntimacao">
      <Route index element={<XFinderIntimacaoPage />} />
    </Route>

    <Route path="/Fechamento/UploadPlanilhaFechamento">
      <Route index element={<UploadPlanilhaFechamentoPage />} />
    </Route>

    <Route path="/XSigner/Envelopes">
      <Route index element={<EnvelopesPage />} />
    </Route>

    <Route path="/XSigner/Envelopes/Novo">
      <Route index element={<EditarEnvelopePage />} />
    </Route>

    <Route path="/XSigner/Envelopes/Editar/:envelopeId">
      <Route index element={<EditarEnvelopePage />} />
    </Route>

    <Route path="/xsigner">
      <Route index element={<AssinarEnvelope />} />
    </Route>

    <Route path="/XSigner/Dashboard">
      <Route index element={<EnvelopeDashboardPage />} />
    </Route>

    <Route path="/Contrato/Dashboard">
      <Route index element={<ContratoDashboardPage />} />
    </Route>

    <Route path="/GerenciarValores">
      <Route index element={<GerenciarValoresPage />} />
    </Route>

    <Route path="/Feed">
      <Route index element={<FeedPage />} />
    </Route>

    <Route path="/LinkCompromisso/:CompromissoId">
      <Route index element={<ConfirmarFeedPage />} />
    </Route>

    <Route path="/Qualificacao">
      <Route index element={<QualificacaoPage />} />
    </Route>

    <Route path="/MotivoSituacao">
      <Route index element={<MotivoSituacaoPage />} />
    </Route>

    <Route path="/TipoAto">
      <Route index element={<TipoAtoPage />} />
    </Route>

    <Route path="/PapelSignatario">
      <Route index element={<PapelSignatarioPage />} />
    </Route>

    <Route path="/Valores">
      <Route index element={<ValoresProcessoPage />} />
    </Route>

    <Route path="/Contrapartida">
      <Route index element={<ContrapartidaPage />} />
    </Route>

    <Route path="/GestaoProcessual">
      <Route index element={<GestaoProcessoPage />} />
    </Route>

    <Route path="/Seguradora">
      <Route index element={<SeguradoraPage />} />
    </Route>

    <Route path="/StatusConsulta">
      <Route index element={<StatusConsultaPage />} />
    </Route>

    <Route path="/TipoConsulta">
      <Route index element={<TipoConsultaPage />} />
    </Route>

    <Route path="/StatusImovel">
      <Route index element={<StatusImovelPage />} />
    </Route>

    <Route path="/TipoAquisicao">
      <Route index element={<TipoAquisicaoPage />} />
    </Route>

    <Route path="/TipoImovel">
      <Route index element={<TipoImovelPage />} />
    </Route>

    <Route path="/TipoReembolso">
      <Route index element={<TipoReembolsoPage />} />
    </Route>

    <Route path="/TipoHonorario">
      <Route index element={<TipoHonorarioPage />} />
    </Route>

    <Route path="/TipoLocalidade">
      <Route index element={<TipoLocalidadePage />} />
    </Route>

    <Route path="/StatusProcuracao">
      <Route index element={<StatusProcuracaoPage />} />
    </Route>

    <Route path="/TipoInstrumento">
      <Route index element={<TipoInstrumentoPage />} />
    </Route>

    <Route path="/TipoProcuracao">
      <Route index element={<TipoProcuracaoPage />} />
    </Route>

    <Route path="/ApresentacaoMarca">
      <Route index element={<ApresentacaoMarcaPage />} />
    </Route>

    <Route path="/ClasseMarca">
      <Route index element={<ClasseMarcaPage />} />
    </Route>

    <Route path="/ClassificacaoIPC">
      <Route index element={<ClassificacaoIPCPage />} />
    </Route>

    <Route path="/NaturezaMarca">
      <Route index element={<NaturezaMarcaPage />} />
    </Route>

    <Route path="/StatusMarca">
      <Route index element={<StatusMarcaPage />} />
    </Route>

    <Route path="/TipoMarca">
      <Route index element={<TipoMarcaPage />} />
    </Route>

    <Route path="/Contrato/Logs">
      <Route index element={<LogContratoPage />} />
    </Route>

    <Route path="/Empresa/Logs">
      <Route index element={<LogEmpresaPage />} />
    </Route>

    <Route path="/Imovel/Logs">
      <Route index element={<LogImovelPage />} />
    </Route>

    <Route path="/Marca/Logs">
      <Route index element={<LogMarcaPage />} />
    </Route>

    <Route path="/Licenca/Logs">
      <Route index element={<LogLicencaPage />} />
    </Route>

    <Route path="/Consulta/Logs">
      <Route index element={<LogConsultaPage />} />
    </Route>

    <Route path="/Procuracao/Logs">
      <Route index element={<LogProcuracaoPage />} />
    </Route>

    <Route path="/Processo/Logs">
      <Route index element={<LogProcessoPage />} />
    </Route>

    <Route path="/Xlearning">
      <Route index element={<XlearningPage />} />
    </Route>

    <Route path="/Ged">
      <Route index element={<GedPage />} />
    </Route>

    <Route path="/PastaGed">
      <Route index element={<PastaGedPage />} />
    </Route>

    <Route path="Xpay/ConfiguracaoTela/TipoHonorario">
      <Route index element={<TipoHonorarioPesquisaPage />} />
    </Route>

    <Route path="Xpay/ConfiguracaoTela/TipoHonorario/Cadastro">
      <Route index element={<TipoHonorarioCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Xpay/ConfiguracaoTela/TipoHonorario/Capa">
      <Route index element={<TipoHonorarioCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="XpayHonorario/Novo">
      <Route index element={<HonorarioCadastroPage />} />
    </Route>

    <Route path="XpayHonorario/Capa">
      <Route index element={<HonorarioCapaPage />} />
    </Route>

    <Route path="/Honorario">
      <Route index element={<HonorarioPage />} />
    </Route>

    <Route path="Xpay/ConfiguracaoTela/TipoReembolso">
      <Route index element={<TipoReembolsoPesquisaPage />} />
    </Route>

    <Route path="Xpay/ConfiguracaoTela/TipoReembolso/Cadastro">
      <Route index element={<TipoReembolsoCadastroConfiguracaoTelaPage />} />
    </Route>

    <Route path="Xpay/ConfiguracaoTela/TipoReembolso/Capa">
      <Route index element={<TipoReembolsoCapaConfiguracaoTelaPage />} />
    </Route>

    <Route path="XpayReembolso/Novo">
      <Route index element={<ReembolsoCadastroPage />} />
    </Route>

    <Route path="XpayReembolso/Capa">
      <Route index element={<ReembolsoCapaPage />} />
    </Route>

    <Route path="/Reembolso">
      <Route index element={<ReembolsoPage />} />
    </Route>

    <Route path="/Xpay/Configuracao/Contrato">
      <Route index element={<XPayContratoPage />} />
    </Route>

    <Route path="/XPay/Fechamento">
      <Route index element={<XPayFechamentoPage />} />
    </Route>

    <Route path="/TipoAudiencia">
      <Route index element={<TipoAudienciaPage />} />
    </Route>

    <Route path="/ResultadoAudiencia">
      <Route index element={<ResultadoAudienciaPage />} />
    </Route>

    <Route path="/ResultadoSentenca">
      <Route index element={<ResultadoSentencaPage />} />
    </Route>

    <Route path="/ChangeLogs">
      <Route index element={<ChangeLog />} />
    </Route>

    <Route path="/Updates">
      <Route index element={<UpdatesPage />} />
    </Route>

    <Route path="/ChangeLogs/Novo">
      <Route index element={<NovoChangeLog />} />
    </Route>

    <Route path="/ChangeLogEmail">
      <Route index element={<ChangeLogEmailPage />} />
    </Route>

    <Route path="/" element={<Navigate replace to="/Home" />} />

    <Route path="*" element={<Navigate to="/Home" />} />

    <Route path="/Relatorio">
      <Route index element={<RelatorioPage />} />
    </Route>
  </Routes>
);
