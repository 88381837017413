import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoContingencia from "../interfaces/ITipoContingencia";
import { AdicionaTipoContingenciaRequest } from "../interfaces/Requests/TipoContingencia/AdicionaTipoContingenciaRequest";
import FiltroPesquisaTipoContingenciaRequest from "../interfaces/Requests/TipoContingencia/FiltroPesquisaTipoContingenciaRequest";
import { EditarTipoContingenciaRequest } from "../interfaces/Requests/TipoContingencia/EditarTipoContingenciaRequest";

class TipoContingenciaService {

    async obterTipoContingencias(filtros: FiltroPesquisaTipoContingenciaRequest): Promise<IRecuperaLista<ITipoContingencia>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoContingencia>>(`TipoContingencia/RecuperaTipoContingencia?${query}`);

        return result;
    }

    async adicionaTipoContingencia(adicionaTipoContingencia: AdicionaTipoContingenciaRequest): Promise<ITipoContingencia> {
        let { data: result } = await api.post<ITipoContingencia>(`TipoContingencia/AdicionaTipoContingencia`, adicionaTipoContingencia);

        return result;
    }


    async alterarStatus(tipoContingenciaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoContingencia>(`TipoContingencia/AtualizaTipoContingenciaPorId?id=${tipoContingenciaId}`, data);
    }

    async atualizarTipoContingencia(editarTipoContingencia: EditarTipoContingenciaRequest): Promise<void> {

        let dataTipoContingencia = [
            { "op": "replace", "path": "/nome", "value": editarTipoContingencia.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoContingencia.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoContingencia/AtualizaTipoContingenciaPorId?id=${editarTipoContingencia.tipoContingenciaId}`, dataTipoContingencia)
        ]);
    }
}


export default new TipoContingenciaService();