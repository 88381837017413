import React from "react";
import FormPropriedades from "../../Propriedade/Form/FormPropriedades";
import { Droppable } from "react-beautiful-dnd";
import IPropriedade from "../../../../interfaces/IPropriedade";

interface ColumnProps {
  col: {
    id: string;
    list: IPropriedade[];
  };
}

const Column: React.FC<ColumnProps> = ({ col: { id, list } }) => {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <>
          <div
            key={id}
            style={{ border: "1px solid #dee2e6", paddingTop: "40px", paddingBottom: "600px" }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map((item, index) => {
              return (
                <>
                  <FormPropriedades item={item} key={item.codigo} index={index} col={id} />
                </>
              );
            })}
          </div>
        </>
      )}
    </Droppable>
  );
};

export default Column;
