import api from "./Api";
import { IEtiqueta } from "../interfaces/IEtiquetas";

class EtiquetaService {
  async adicionaEtiquetas(etiquetas: IEtiqueta[]) {
    etiquetas.forEach(async (item) => {
      await api.post<IEtiqueta>(`Etiqueta/AdicionaEtiqueta`, item);
    });
  }

  async adicionaEtiqueta(etiqueta: IEtiqueta) {
    await api.post<IEtiqueta>(`Etiqueta/AdicionaEtiqueta`, etiqueta);
  }

  async alterarEtiqueta(etiqueta: IEtiqueta): Promise<void> {
    let data = [{ op: "replace", path: "/nome", value: etiqueta.nome }];

    await api.patch<IEtiqueta>(`Etiqueta/AtualizaEtiquetaPorId?id=${etiqueta.etiquetaId}`, data);
  }

  async removerEtiqueta(etiquetaId: number): Promise<void> {
    await api.delete<IEtiqueta>(`Etiqueta/RemoveEtiqueta?etiquetaId=${etiquetaId}`);
  }
}

export default new EtiquetaService();
