import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend    

} from 'chart.js';

import { ChartOptions, ChartData } from 'chart.js';

import IWidgetDataSet, { IWidgetDado } from "../interfaces/IWidgetDataSet";
import { DadoGenerico, TimeDelta } from '../../types';
import gerarCor from '../../utils/Colors';
import { IGraficoOptions } from './GraficoWidget';
import { valorAbreviado } from '../../../../utils/Utils';
import { useState } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    
    
);

export default function BarChart(props: { options?: IGraficoOptions, direcao?: string, formatoDoDado?: string, dados: IWidgetDataSet, empilhado?: boolean }) {
    const [ tamanho, setTamanho ] = useState(props.options?.tamanho || 187);
    
    let dataSet = props.dados;
    let dados = dataSet.dados as IWidgetDado[];

    const isValorTimeDelta = (dados[0]?.valor as unknown as DadoGenerico)?.tipo === 'timeDelta'
    const isMultiDataset = dados[0]?.etiqueta ? true : false;


    

    function corFundo(index: number, limit:number): string {
        return gerarCor(index, limit, 0.95);
    }

    function corBorda(index: number, limit:number): string {
        return gerarCor(index, limit, 1);
    }   

    function getDados(): ChartData<"bar", number[], string> {
        let _datasets:any[] = [];     

        if(isMultiDataset) {
            dados.map((dado :IWidgetDado, index) => {             
                _datasets.push( 
                    {
                        label: dado.etiqueta,                       
                        data: dado.valor,                       
                        backgroundColor: corFundo(index, dados.length),
                        borderWidth: 1, 
                        minBarLength: 2,                                                            
                        borderColor: corBorda(index, dados.length)
                    }
                )
               

            })
            console.log(_datasets)

        } else {
            _datasets.push({
                data: dados.map((dado: IWidgetDado) => {
                    if ((dado.valor as unknown as DadoGenerico)?.tipo === 'timeDelta') {
                        return (dado.valor as unknown as TimeDelta).valueOf();
                    }
                        return dado.valor as number
                    }),
                    datalabels: {                                              
                        align: 'end',
                        anchor: 'end',                        
                    },
                    parsing: {
                    'yAxisKey': 'val',                    
                    },
                    minBarLength: 2,
                    backgroundColor: dados.map((dado :IWidgetDado, index: number)=>{
                    return dado?.backgroundColor || corFundo(index, dados.length)
                }),
                borderColor: dados.map((dado: IWidgetDado, index: number) => {
                    return dado?.borderColor || corBorda(index, dados.length)
                }),
                borderWidth: 1.5

            })
        }   

        return  {
            labels: dataSet.etiquetas as string[],       
            datasets: [..._datasets]
        };
    }

    function getOptions() {

        const chartOptions: ChartOptions<'bar'> = {
           responsive: true,        
           maintainAspectRatio: props.options?.mostrarMaisNoZoom ? true : false,                      
           scales: { 
                yAxis:{                                
                    ticks: {                   
                        callback: function(label, index, labels) {
                            if(isValorTimeDelta) {
                                return new TimeDelta(typeof label === 'string'?parseInt(label):label).toString("%D%h:%m:%s");
                            }                        
                            return label
                        }
                    },
                }
            },
            plugins: {               
                datalabels: {
                    display: false, // Pode esta sendo alterado.
                    formatter: function (value) {
                        if (props.options?.tipoDeDado === 'moeda') {
                            return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                        }
                        return value
                    }
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (this, i): string | string[] {
                            let dados = props.dados.dados as IWidgetDado[];
                            if ((dados[0]?.valor as unknown as DadoGenerico)?.tipo === 'timeDelta') {
                                const data: number = Math.floor(this.dataPoints[0].raw as number);
                                const td = new TimeDelta(data);
                                return td.toString('%Y%X%D%h:%m:%s');
                            }
                            if (props.options?.tipoDeDado === 'moeda') {
                                return (i.formattedValue as any).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                            }
                            return i.formattedValue;
                        }
                    }
                }
            }
        }

        if (props.options?.direcaoDaBarra === 'vertical') {
            chartOptions.indexAxis = 'x'
            if (chartOptions.plugins?.datalabels?.display === false) {
                chartOptions.plugins.datalabels.display = true;
            }        
            
                chartOptions.scales = {
                    y: {
                        suggestedMax: porcentagemSobreOMaiorValor(.30),
                        reverse: props.options?.barraInvertida === true ? true : false,
                    },
                    xAxis: {                        
                        ticks: {
                            callback: function(label, index, labels) {
                                if(props.options?.mostraTotalNoLabel){
                                    const labelss = dataSet.etiquetas as string[]                           
                                    return labelss[index] + ' (' + somaTotalLabel(index) + ')'
                                } else {
                                    const labelss = dataSet.etiquetas as string[]                           
                                    return labelss[index]
                                }
                            }
                        },
                        stacked: false
                    }
                    
                }
            
        } 
        
        if (props.options?.direcaoDaBarra === 'horizontal') {
            chartOptions.indexAxis = 'y'
            if (chartOptions.plugins?.datalabels?.display === false) {
                chartOptions.plugins.datalabels.display = true;
            }
            chartOptions.scales = {
                x: {
                    stacked: props.empilhado,
                    suggestedMax: porcentagemSobreOMaiorValor(.30),
                    reverse: props.options?.barraInvertida === true ? true : false,                                
                },
                y: {
                    stacked: props.empilhado,
                    display: props.empilhado ? false : true,
                    ticks: {
                        callback: function(label, index, labels) {
                            if(props.options?.mostraTotalNoLabel){
                                const labelss = dataSet.etiquetas as string[]                           
                                return labelss[index].length > 20 ? labelss[index].substring(0,20).concat('...') : labelss[index] + ' (' + somaTotalLabel(index) + ')'
                            } else {
                                const labelss = dataSet.etiquetas as string[]                           
                                return labelss[index].length > 20 ? labelss[index].substring(0,20).concat('...') : labelss[index]
                            }
                        }
                    },        
                }
            }
        }

        if (isMultiDataset) {
            chartOptions.plugins = {               
                legend: {
                    display: props?.options?.mostrarLegenda || false,
                    position: props?.options?.posicaoLegenda || 'right'
                },
                datalabels: {
                    display: props.options?.mostraDataLabels || false,                 
                    labels: {
                        value: {                             
                            align:'end',
                            anchor: 'end',                        
                            color: 'black',    
                            formatter: function (value,context) {
                                if(props.options?.agruparDataSets) {
                                    // Aplica o dataLabel no ultimo dataset Agrupado com o total.
                                    if((context.datasetIndex === (dataSet.dados as IWidgetDado[]).length - 1 )) {
                                        return somaTotalLabel(context.dataIndex).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                                    }                                    
                                    return ''                                                                       
                                }  

                                if (props.options?.tipoDeDado === 'moeda') {
                                    return '\n R$ '+ valorAbreviado(value,1);
                                    // return '\n '+ value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                                }
                                
                                return value
                            },                         
                            font: {
                            weight: 'normal',
                            },
                            offset: 0,
                            padding: 3
                        },
                        label: {                          
                            align:'start',
                            anchor: 'start',
                            textAlign:'center',
                            rotation: -20,                                                    
                            color: 'black',
                            display: props.options?.agruparDataSets ? false : true,  
                            formatter: function(value: any, ctx: any) {
                                return ctx.dataset.label 
                            },                             
                            font: {
                                size: 10, 
                                weight: 'normal',
                            },
                            offset: 5,
                            padding: 0
                        }
                    }
                    
                  },
                tooltip: {
                    callbacks: {
                        label: function (this, i): string | string[] {
                            let dados = props.dados.dados as IWidgetDado[];
                            if ((dados[0]?.valor as unknown as DadoGenerico)?.tipo === 'timeDelta') {
                                const data: number = Math.floor(this.dataPoints[0].raw as number);
                                const td = new TimeDelta(data);
                                return td.toString('%Y%X%D%h:%m:%s');
                            }
                            if (props.options?.tipoDeDado === 'moeda') {
                                return i.dataset.label +': '+ (i.formattedValue as any).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                            }
                            return i.dataset.label +': '+i.formattedValue;
                        }
                    }
                }              
            };
            
            chartOptions.scales = {                
                y: {
                    suggestedMax: porcentagemSobreOMaiorValor(.30),
                    reverse: props.options?.barraInvertida === true ? true : false,
                    stacked:props.options?.agruparDataSets,
                    ticks: {
                        callback: function(label, index, labels) {
                            if(props.options?.direcaoDaBarra === 'horizontal') {
                                const l = this.getLabelForValue(index);
                                return l.length > 20 ? l.substring(0,20).concat('...') : l
                            } else {
                                return label
                            }                        
                        }
                    },  
                    
                },                
                x:{  
                    suggestedMax: porcentagemSobreOMaiorValor(.30), 
                    stacked:props.options?.agruparDataSets,
                    display: props.empilhado ? false : true,
                    ticks: { 
                        padding: 30, 
                        font: {
                            size: 14
                        },              
                        callback: function(label, index, labels) {
                            const labelss = dataSet.etiquetas as string[]                           
                            return props.options?.tipoDeDado === 'moeda' ? labelss[index] : labelss[index] + ' (' + somaTotalLabel(index) + ')'             
                        }
                    },
                }
                
                
            }
        } 
        return chartOptions
    }

    function somaTotalLabel(index: number) {
        let soma = 0;
        if (isMultiDataset) {
            dados.forEach(dado => {
                soma += (dado.valor as unknown as number[])[index];
            })
        } else {
            return dados[index].valor
        }

        return soma
    }

    function porcentagemSobreOMaiorValor(porcentagem: number) {
        if (isMultiDataset) {
            const valores: any[] = []
            
            if(props.options?.agruparDataSets) {
                //Adiciona a soma dos valores de cada dataSet
                const etiquetas = dataSet.etiquetas as string[];
                etiquetas.map((etiqueta, index) => {
                    const valoresDaEtiqueta: number[] = [];

                    dados.map((dado: IWidgetDado) => {
                        const arrayAtual = dado.valor as number[]
                        valoresDaEtiqueta.push(arrayAtual[index]);
                    })
                    
                    valores.push( valoresDaEtiqueta.reduce((soma, item) => soma + item , 0) || 0)

                    // dados.map((dado: IWidgetDado) => {
                    //     const arrayDeValores = dado.valor as number[];
                    //     valores.push( arrayDeValores.reduce((soma, item) => soma + item , 0) || 0)
                    // })
                })

            } else {
                // Adiciona os valores de cada dataSet
                dados.map((dado: any) => {
                    valores.push(...dado.valor || 0)
                })
            }

            const maiorNumeroEncontrado = Math.max.apply(null, valores)
            return (maiorNumeroEncontrado * porcentagem) + maiorNumeroEncontrado

        } else {
            let m = Math.max.apply(null, dados.map(dado => { return dado.valor }) as number[])
            return (m * porcentagem) + m

        }
    }

    return <Bar options={getOptions()} height={tamanho} data={getDados()} />;
}