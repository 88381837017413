import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import React, { createContext, useCallback, useState } from "react";
import { elementHelper } from "../helpers/element/element.helper";

interface ToggleModeParams {
  data: string;
  editor: DocumentEditor;
  isRestored?: boolean;
}

export type Mode = "default" | "history" | "comparator";

export const ModeContext = createContext(
  {} as {
    mode: Mode;
    setMode: React.Dispatch<React.SetStateAction<Mode>>;
    toggleComparatorMode: (params: ToggleModeParams) => void;
    toggleHistoryMode: (params: ToggleModeParams) => void;
  }
);

export function ModeProvider({ children }: { children: React.ReactNode }) {
  const [mode, setMode] = useState<Mode>("default");

  const toggleHistoryMode = useCallback(
    (params: ToggleModeParams) => {
      const { data, editor } = params;
      setMode((state) => {
        const value = state === "history" ? "default" : "history";
        editor.isReadOnly = value === "history";
        if (value !== "history" && !params.isRestored) {
          editor.open(data);
        }
        return value;
      });
    },
    [setMode]
  );

  const toggleComparatorMode = useCallback(
    (params: ToggleModeParams) => {
      const { data, editor } = params;
      setMode((state) => {
        const value = state === "comparator" ? "default" : "comparator";
        editor.isReadOnly = value === "comparator";
        editor.open(data);
        if (value === "comparator") {
          elementHelper.removeTrackChangesButtons();
        }
        return value;
      });
    },
    [setMode]
  );

  return (
    <ModeContext.Provider
      value={{ mode, setMode, toggleComparatorMode, toggleHistoryMode }}
    >
      {children}
    </ModeContext.Provider>
  );
}
