import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IAndamentos from "../interfaces/IAndamentos";
import FiltroPesquisaAndamentoRequest from "../interfaces/Requests/Andamento/FiltroPesquisaAndamentoRequest";
import moment from "moment";

class AndamentoService {
  async obterAndamentos(filtros: FiltroPesquisaAndamentoRequest): Promise<IRecuperaLista<IAndamentos>> {
    let query = "";

    if (filtros.ultimosQuantidade) {
      query = `&offset=${filtros.offset}&limit=${filtros.ultimosQuantidade}&sort=${filtros.sort}`;
    } else {
      query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;
    }

    if (filtros.numeroProcesso) {
      query += `&numeroProcesso=${filtros.numeroProcesso}`;
    }

    if (filtros.parteContraria) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    if (filtros.tipoPesquisa == 1) {
      query += `&descricao=${filtros.filtro}`;
    }

    if (filtros.modulo && filtros.modulo > 0) {
      query += `&modulo=${filtros.modulo}`;
    }

    if (filtros.tipoPesquisa == 2) {
      query += `&codigo=${filtros.filtro}`;
    }

    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataAndamentoMenorIgual) {
      query += `&dataAndamentoMenorIgual=${filtros.dataAndamentoMenorIgual}`;
    }

    if (filtros.dataAndamentoMaiorIgual) {
      query += `&dataAndamentoMaiorIgual=${filtros.dataAndamentoMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }

    if (filtros.processoId && filtros.processoId > 0) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.contratoId && filtros.contratoId > 0) {
      query += `&contratoId=${filtros.contratoId}`;
    }

    if (filtros.marcaId && filtros.marcaId > 0) {
      query += `&marcaId=${filtros.marcaId}`;
    }

    if (filtros.imovelId && filtros.imovelId > 0) {
      query += `&imovelId=${filtros.imovelId}`;
    }

    if (filtros.licencaId && filtros.licencaId > 0) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.empresaId && filtros.empresaId > 0) {
      query += `&empresaId=${filtros.empresaId}`;
    }

    if (filtros.consultaId && filtros.consultaId > 0) {
      query += `&consultaId=${filtros.consultaId}`;
    }

    if (filtros.areaDireitoId.length > 0) filtros.areaDireitoId.forEach((id) => (query += `&areaDireitoId=${id}`));

    if (filtros.status.length > 0) filtros.status.forEach((id) => (query += `&status=${id}`));


    if (filtros.andamentoTipoId.length > 0) {
      for (let index = 0; index < filtros.andamentoTipoId.length; index++) {
        const andamentoTipoId = filtros.andamentoTipoId[index];

        query += `&andamentoTipoId=${andamentoTipoId}`;
      }
    }

    let { data: result } = await api.get<IRecuperaLista<IAndamentos>>(`Andamento/RecuperaAndamento?${query}`);

    return result;
  }

  async inativarAndamento(andamentoId: number) {
    let { data: result } = await api.put(`Andamento/InativaAndamentoPorId?id=${andamentoId}`);

    return result;
  }

  async adicionaAndamento(adicionaAndamento: IAndamentos): Promise<IAndamentos> {
    let { data: result } = await api.post<IAndamentos>(`Andamento/AdicionaAndamento`, adicionaAndamento);

    return result;
  }

  async alterarStatus(andamentoId: number, status: number): Promise<void> {
    let data = [
        { "op": "replace", "path": "/status", "value": status },
    ]

    await api.patch<IAndamentos>(`Andamento/AtualizaAndamentoPorId?id=${andamentoId}`, data);
}

  async atualizarAndamento(editarAndamento: IAndamentos): Promise<void> {
    let dataAndamento = [
      { op: "replace", path: "/andamentoTipoId", value: editarAndamento.andamentoTipoId },
      { op: "replace", path: "/dataAndamento", value: moment(editarAndamento.dataAndamento).format('YYYY-MM-DD 00:00:00') },
      { op: "replace", path: "/descricao", value: editarAndamento.descricao },
      { op: "replace", path: "/restrito", value: editarAndamento.restrito },
      { op: "replace", path: "/codigo", value: editarAndamento.codigo },
    ];

    await Promise.all([await api.patch<void>(`Andamento/AtualizaAndamentoPorId?id=${editarAndamento.andamentoId}`, dataAndamento)]);
  }


  async gerarExcel(filtros: FiltroPesquisaAndamentoRequest): Promise<Blob> {

    let query = "";

    if (filtros.ultimosQuantidade) {
      query = `&offset=${filtros.offset}&limit=${filtros.ultimosQuantidade}&sort=${filtros.sort}`;
    } else {
      query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;
    }

    if (filtros.numeroProcesso) {
      query += `&numeroProcesso=${filtros.numeroProcesso}`;
    }

    if (filtros.parteContraria) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    if (filtros.tipoPesquisa == 1) {
      query += `&descricao=${filtros.filtro}`;
    }

    if (filtros.modulo && filtros.modulo > 0) {
      query += `&modulo=${filtros.modulo}`;
    }

    if (filtros.tipoPesquisa == 2) {
      query += `&codigo=${filtros.filtro}`;
    }

    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataAndamentoMenorIgual) {
      query += `&dataAndamentoMenorIgual=${filtros.dataAndamentoMenorIgual}`;
    }

    if (filtros.dataAndamentoMaiorIgual) {
      query += `&dataAndamentoMaiorIgual=${filtros.dataAndamentoMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }

    if (filtros.processoId && filtros.processoId > 0) {
      query += `&processoId=${filtros.processoId}`;
    }

    if (filtros.contratoId && filtros.contratoId > 0) {
      query += `&contratoId=${filtros.contratoId}`;
    }

    if (filtros.marcaId && filtros.marcaId > 0) {
      query += `&marcaId=${filtros.marcaId}`;
    }

    if (filtros.imovelId && filtros.imovelId > 0) {
      query += `&imovelId=${filtros.imovelId}`;
    }

    if (filtros.licencaId && filtros.licencaId > 0) {
      query += `&licencaId=${filtros.licencaId}`;
    }

    if (filtros.empresaId && filtros.empresaId > 0) {
      query += `&empresaId=${filtros.empresaId}`;
    }

    if (filtros.descricao && filtros.descricao.length > 0) {
      query += `&descricao=${filtros.descricao}`;
    }

    if (filtros.areaDireitoId.length > 0) filtros.areaDireitoId.forEach((id) => (query += `&areaDireitoId=${id}`));

    if (filtros.andamentoTipoId.length > 0) {
      for (let index = 0; index < filtros.andamentoTipoId.length; index++) {
        const andamentoTipoId = filtros.andamentoTipoId[index];

        query += `&andamentoTipoId=${andamentoTipoId}`;
      }
    }

    const { data: result } = await api.get(`Andamentos/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

    return result;
  }
}

export default new AndamentoService();
