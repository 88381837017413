import { FooterElement } from "../../../utils/types/slate";
import { AddBlock } from "../types";
import { blockHelper } from "./blockHelper";
import { replacer } from "./replacer";

export function addFooter(
  child: FooterElement,
  { block, currentPage, firstPageDiff }: AddBlock<FooterElement>
) {
  const footerBlock = blockHelper.withId<FooterElement>('footer', block.default, currentPage);

  if (firstPageDiff && currentPage === 0) {
    return blockHelper.withId<FooterElement>('footer', block.firstPage, currentPage);
  }

  child = replacer.childrenReplacer(footerBlock, currentPage);
  return child;
}
