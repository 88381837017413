import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 40px;
`;

export const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: #E5E5E6;
`;

export const Title = styled.h1`
  font-size: 22px;
  letter-spacing: -0.5px;
  color: #2E3334;
`;

export const Search = styled.div<{ focused: boolean }>`
  border-radius: 4px;
  border: ${props => props.focused ? '2px solid #3b82f6' : '1px solid #E5E5E6'};
  height: 100%;
  width: 180px;
  padding: 0 4px;

  display: flex;
  align-items: center;
  gap: 8px;

  > label {
    background: #E5E5E6;
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    border-radius: 15px;
  }

  > input {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    color: #797C7F;

    &::placeholder {
      color: #797C7F;
    }
  }
`;

export const NewButton = styled.button`
  height: 100%;
  padding: 0 10px;
  border-radius: 4px;
  border: none;
  background-color: var(--primary-base);

  display: flex;
  align-items: center;
  gap: 4px;
  color: #2E3334;

  > span {
    letter-spacing: -0.5px;
    font-weight: 500;
  }
`;

export const ClearButton = styled.button`
  height: 100%;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E5E5E6;

  display: flex;
  align-items: center;
  gap: 4px;
  color: #2E3334;

  > span {
    letter-spacing: -0.5px;
    font-weight: 500;
  }
`;