import { faTimes, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import FormCheckInput from "react-bootstrap/esm/FormCheckInput"
import { TableColumn } from "react-data-table-component"
import GridPadrao from '../../../components/Comum/GridPadrao'
import { OrangeContext } from "../../../contexts/OrangeProvider"
import { ETelaEnum } from "../../../enum"
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext"
import IAtividadeEmpresa from '../../../interfaces/IAtividadeEmpresa'
import { IConfiguracaoTela } from '../../../interfaces/IConfiguracaoTela'
import AtividadeEmpresaService from "../../../services/AtividadeEmpresaService"
import EmpresaService from "../../../services/EmpresaService"
import { useDisclosure } from "../hooks/useDisclosure"

interface CampoListaMultiProps {
  items?: any[]
  tela: IConfiguracaoTela
  setValues: (field: { name: string, value: IAtividadeEmpresa[] }) => void;
}

type ItemsAtividadesEmpresa = IAtividadeEmpresa & { checked: boolean }

export const CampoListaMulti = ({ items: itens, tela, setValues }: CampoListaMultiProps) => {
  const { empresa, } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [atividadesEmpresa, setAtividadesEmpresa] = useState<ItemsAtividadesEmpresa[]>([]);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const modalActions = useDisclosure();
  const [filtroItems, setFiltroItems] = useState<IAtividadeEmpresa[]>([]);


  const marcarAtividade = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setAtividadesEmpresa((itens) => {
      return itens.map((item) => {
        if (item.atividadeEmpresaId === Number(target.id)) {
          return { ...item, checked: value as boolean }
        } else {
          return { ...item }
        }
      })
    })

    atividadesEmpresa.map((item) => {
      if (item.atividadeEmpresaId === Number(target.id)) { item.checked = value as boolean }
    })
  }

  const handleValues = () => {
    const itemsSelecionados = atividadesEmpresa.filter((e) => e.checked)

    setValues({ name: 'atividadesEmpresa', value: itemsSelecionados })

    modalActions.handleClose()
  }

  const colunas: TableColumn<ItemsAtividadesEmpresa>[] = [
    {
      name: "Id",
      sortField: "atividadeEmpresaId",
      selector: (row) => row.atividadeEmpresaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Adicionar?",
      cell: (row) => {
        return (
          <>
            <div>
              <FormCheckInput
                id={row.atividadeEmpresaId.toString()}
                name={row.nome.toString()}
                checked={row.checked}
                onChange={marcarAtividade}
                style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              /> </div>          </>
        );
      },
      ignoreRowClick: true,
    },
  ]

  const setFiltro = (codigo: string, nome: string) => {
    if (nome && nome !== '') {
      const filtrados = atividadesEmpresa.filter(item => item.nome.includes(nome))
      setFiltroItems(() => filtrados)
      return
    }

    if (codigo && codigo !== '') {
      const filtrados = atividadesEmpresa.filter(item => item.codigo.includes(codigo))
      setFiltroItems(() => filtrados)
      return
    }

    setFiltroItems(() => atividadesEmpresa)
  }

  const carregarAtividades = async () => {
    let atividadesEmpresaExistentes: any[];

    let resultado = (await AtividadeEmpresaService.obterAtividadeEmpresas({
      offset: 0,
      limit: 10000,
      sort: "atividadeEmpresaId",
      status: 0,
      nome: "",
      codigo: "",
      totalItems: 0
    })).data as unknown as (IAtividadeEmpresa & { checked: boolean })[];


    if (empresa.empresaId > 0) {
      atividadesEmpresaExistentes = await EmpresaService.obterAtividadesEmpresa(empresa.empresaId)
    } else {
      atividadesEmpresaExistentes = itens || [];
    }

    if (tela.tela === ETelaEnum.TelaCapaEmpresa) {
      resultado.map((item) => {
        const itemSelecionado = atividadesEmpresaExistentes.find((a: any) => a.atividadeEmpresaId === item.atividadeEmpresaId)
        if (itemSelecionado) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      })
    }

    if(tela.tela === ETelaEnum.TelaCadastroEmpresa) {
      if(atividadesEmpresa.length === 0) return

      resultado.map((item) => {
        const itemSelecionado = atividadesEmpresa.find((a:any) => a.atividadeEmpresaId === item.atividadeEmpresaId )
        if( itemSelecionado ) {
          item.checked = true; 
        } else {
          item.checked = false;
        }
      })
    }

    setAtividadesEmpresa(resultado.sort((a, b) => a.checked ? -1 : 1));
  }

  useEffect(() => {
    carregarAtividades();
  }, [])

  useEffect(() => {
    const count = atividadesEmpresa.filter(item => item.checked);
    setSelectedCount(() => count.length);
    setFiltroItems(() => atividadesEmpresa);
  }, [atividadesEmpresa]);

  return (
    <>
      <Button
        onClick={() => {
          modalActions.handleOpen()
        }}
        className="btn-orange w-100"
        variant="secondary"
        size="sm"
      >
        <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
        Gerenciar
      </Button>


      <div className="row mt-2">
        <a className="text-orange-grad" style={{ fontSize: "12px" }}>
          {selectedCount === 0 && `Nenhuma item selecionada`}
          {selectedCount === 1 && `${selectedCount} item selecionada`}
          {selectedCount > 1 && `${selectedCount} itens selecionadas`}
        </a>
      </div>

      <Modal size="lg" className={'societario'} centered={false} show={modalActions.isOpen} onHide={() => { modalActions.handleClose() }} >
        <Modal.Header>
          <Modal.Title>Atividades Secundárias da Empresa.</Modal.Title>
          <button onClick={() => modalActions.handleClose()} className={'btn btn-icon close'} >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-3">
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Código:
              </label>
              <input
                onChange={(e) => {
                  setFiltro(e.target.value, '');
                }}
                placeholder="Código"
                type="text"
                className={"form-control"}
                id="codigo"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Nome:
              </label>
              <input
                onChange={(e) => {
                  setFiltro('', e.target.value);
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="nome"
              />
            </div>
          </div>

          <GridPadrao
            colunas={colunas}
            itens={filtroItems}
            tipo={'Atividades'}
            onChangePage={() => carregarAtividades()}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className={'btn btn-orange'} form={'form-add-pessoa-modal'} onClick={handleValues}>Salvar</Button>
          <Button className={'btn btn-danger'} onClick={() => modalActions.handleClose()}>Cancelar</Button>
        </Modal.Footer>
      </Modal>


    </>
  )
}