import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaTipoContratoRequest from "../interfaces/Requests/TipoContrato/FiltroPesquisaTipoContratoRequest";
import ITipoContratoGrupo from "../interfaces/ITipoContratoGrupo";

class TipoContratoGrupoService {

    async obterTipoContratoGrupo(filtros: FiltroPesquisaTipoContratoRequest): Promise<IRecuperaLista<ITipoContratoGrupo>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`


        let { data: result } = await api.get<IRecuperaLista<ITipoContratoGrupo>>(`TipoContratoGrupo/RecuperaTipoContratoGrupo?${query}`);

        return result;
    }
    
}


export default new TipoContratoGrupoService();

