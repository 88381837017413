import Estatisticas from "./Estatisticas";
import { DadoNumerico } from "../types";
import { IDashboardFechamento } from '../../../pages/Contencioso/Dashboard/IDashboardFechamento';
import moment from "moment";

export default class FechamentoDashboardEstatisticas extends Estatisticas<IDashboardFechamento> {

  public meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  
  public dadosIndicadoresGerais(): DadoNumerico[] {    
    const resultado: DadoNumerico[] = [];
    const dadosGerais = this.dados[0].totalizadoresGerais;

    resultado.push(new DadoNumerico('Qtd. Processos', [], [dadosGerais.quantidadeProcessosAtivos]));
    resultado.push(new DadoNumerico('Total R$ Geral', [], [dadosGerais.valorTotalAtualizado]));
    resultado.push(new DadoNumerico('Total R$ Principal', [], [dadosGerais.valorTotalPrincipal]));
    resultado.push(new DadoNumerico('Total R$ Multa', [], [dadosGerais.valorTotalMulta]));
    resultado.push(new DadoNumerico('Total R$ Juros', [], [dadosGerais.valorTotalJurosECorrecao]));
    resultado.push(new DadoNumerico('Total R$ Honorarios', [], [dadosGerais.valorTotalHonorarios]));
    resultado.push(new DadoNumerico('Total R$ Remoto', [], [dadosGerais.valorTotalRemotoAtualizado]));
    resultado.push(new DadoNumerico('Total R$ Possivel', [], [dadosGerais.valorTotalPossivelAtualizado]));
    resultado.push(new DadoNumerico('Total R$ Provável', [], [dadosGerais.valorTotalProvavelAtualizado]));
    resultado.push(new DadoNumerico('Total R$ Provisionado', [], [dadosGerais.valorTotalProvisaoAtualizado]));

    return resultado; 
  } 

  public dadosPassivoAtivo(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].qtdeAtivosPassivos;

    resultado.push(new DadoNumerico('Ativos', [], [dados.ativo]));
    resultado.push(new DadoNumerico('Passivos', [], [dados.passivo]));

    return resultado; 
  }
   
  public dadosJudicialAdministrativo(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].qtdeJudiciaisAdministrativos;

    resultado.push(new DadoNumerico('Judicial', [], [dados.judicial]));
    resultado.push(new DadoNumerico('Administrativo', [], [dados.administrativo]));

    return resultado; 
  } 
  
  public dadosValorTotalPorDivisao(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].valorTotalPorDivisao;   

    dados.map((d) => resultado.push(new DadoNumerico( d.divisao, [], [d.valorTotal]) )) 

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) < Number(b.total())) ? 1 : -1 })
    return resultadoSort
  } 

  public dadosProvisaoPorDivisao(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].provisaoPorDivisao;

    dados.map((d) => resultado.push(new DadoNumerico( d.divisao, [], [d.provisao]) ));

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) < Number(b.total())) ? 1 : -1 });
    return resultadoSort

  } 

  public provisaoPorArea(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].provisaoPorArea;

    dados.map((d) => d.provisao > 0 ?  resultado.push(new DadoNumerico( d.area, [], [d.provisao]) ) : '');

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) < Number(b.total())) ? 1 : -1 });
    return resultadoSort

  }

  public provisaoPorDivisao(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].provisaoPorDivisao

    dados.map((d) => d.provisao > 0 ? resultado.push(new DadoNumerico( d.divisao, [], [d.provisao]) ): '');

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) < Number(b.total())) ? 1 : -1 });
    return resultadoSort   

  }
  
  public valorTotalPorArea(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].valorTotalPorArea

    dados.map((d) => resultado.push(new DadoNumerico( d.area, [], [d.valorTotal]) ));

    const resultadoSort = resultado.sort((a, b) => { return (Number(a.total()) < Number(b.total())) ? 1 : -1 });
    return resultadoSort

  }
  
  public variacaoProvisao(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].variacaoProvisao

    dados.map((d) => resultado.push(new DadoNumerico( moment(d.dataCorte, 'YYYY-MM-DD').format('MM/YY'), [], [d.provisao]) ));

    return resultado

  }


  public dadosVariacaoProvisaoPorArea(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].variacaoProvisaoPorArea; 
    const areasEncontradas: string[] =  [];

    dados.map((d) => {
      d.areaEProvisao.map((area) => {
        if(!areasEncontradas.includes(area.area)) {
          areasEncontradas.push(area.area)
        }
      })      
    })

    areasEncontradas.map((area, index) => { 
      const dadosAreas = dados.map(a => {
        const g = a.areaEProvisao.find(d => {
          if(d.area === area) {
            return d.provisao
          }
        })
         return g?.provisao || 0
      })

      // Verifica se existe pelo menos um um valor no array, senão a soma é 0.
      const elementoComValores = dadosAreas.reduce((acc, el) => acc + el );

      if(elementoComValores) {
        resultado.push(new DadoNumerico(area, [], dadosAreas));
      }

    })

    return resultado; 
  }

  public labelsVariacaoProvisaoPorArea(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].variacaoProvisaoPorArea;

    dados.map((d) => {     
      resultado.push(new DadoNumerico(moment(d.dataCorte, 'YYYY-MM-DD').format('MM/YY'), [], []));
    })

    return resultado; 
  } 

  public labelsVariacaoProvisaoPorDivisao(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].variacaoProvisaoPorDivisao;

    dados.map((d) => {     
      resultado.push(new DadoNumerico(moment(d.dataCorte, 'YYYY-MM-DD').format('MM/YY'), [], []));
    })

    return resultado; 
  }

  public dadosVariacaoProvisaoPorDivisao(): DadoNumerico[] {
    const resultado: DadoNumerico[] = [];
    const dados = this.dados[0].variacaoProvisaoPorDivisao;

    const divisoes: string[] =  [];

    dados.map((d,index) => {
      d.divisao.map((divisao) => {
        if(!divisoes.includes(divisao.divisao)) {
          divisoes.push(divisao.divisao)
        }
      })      
    })

    divisoes.map((divisao, index) => { 
      const dadosDivisao = dados.map(a => {
        const g = a.divisao.find(d => {
          if(d.divisao === divisao) {
            return d.provisao
          }
        })

         return g?.provisao || 0
      })

      // Verifica se existe pelo menos um um valor no array, senão a soma é 0.
      const elementoComValores = dadosDivisao.reduce((acc, el) => acc + el );

      if(elementoComValores) {
        resultado.push(new DadoNumerico(divisao, [], dadosDivisao));
      }

    })

    return resultado;
  } 

}

// Função que agrupa por um determinado campo do array.
export function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
  return array.reduce((store, item) => {
    let key = grouper(item)
    if (!store.has(key)) {
      store.set(key, [item])
    } else {
      store.get(key)?.push(item)
    }
    return store
  }, new Map<K, V[]>());
}
