import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Modal } from "react-bootstrap"
import { RiFileAddLine } from "react-icons/ri"
import { IPessoa } from "../../../interfaces/IPessoa"
import AdicionaPessoaEmpresa from "./AdicionaPessoasEmpresa"

interface IModalAdicionaPessoa {
  title: string,
  className?: string,
  showModal: boolean,
  tipoDocumento: string,
  numeroDocumento: string,
  disabledInputs?: boolean,
  nome?: string,
  setShowModal: (show: boolean) => void,
  handlePessoa: (pessoa: IPessoa) => void
}

export const ModalAdicionaPessoa = (props: IModalAdicionaPessoa) => {

  const { handlePessoa, numeroDocumento, tipoDocumento, nome } = props;
  
  return (
    <>
      <Modal size="lg" show={props.showModal} className={props.className} centered={true} onHide={() => props.setShowModal(false)}>
        <Modal.Header>
          <RiFileAddLine style={{ width: 28, height: 28 }} />

          <Modal.Title>{props.title}</Modal.Title>
          <button onClick={() => props.setShowModal(false)} className={'btn btn-icon close'} >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <AdicionaPessoaEmpresa
            disableErrors={true}
            nome={props.nome}
            handlePessoa={handlePessoa}
            numeroDocumento={numeroDocumento}
            tipoDocumento={tipoDocumento}
            disabledsInputs={props.disabledInputs}
          />
        </Modal.Body>
        <div className="modal-footer" style={{ margin: '0 auto' }}>
          <Button className={'btn btn-orange'} form={'form-add-pessoa-modal'} type={'submit'}>Salvar</Button>
          <Button className={'btn btn-danger'} onClick={() => props.setShowModal(false)}>Cancelar</Button>
        </div>
      </Modal>

    </>
  )
}