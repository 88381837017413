import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { ICampo } from "../interfaces/ICampo";


class CampoFluxoRegraSerivce {

    async obter(filtros: any): Promise<IRecuperaLista<ICampo>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.tabelaOrigem) {
            query += `&tabelaOrigem=${filtros.tabelaOrigem}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ICampo>>(`Campos/RecuperaCampoFluxoRegra?${query}`);

        return result;
    }

    
 
}


export default new CampoFluxoRegraSerivce();