import IGrupoUsuarios from "../interfaces/IGrupoUsuarios";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import AdicionarGrupoUsuariosRequest from "../interfaces/Requests/GrupoUsuarios/AdicionarGruposUsuariosRequest";
import EditarGrupoUsuariosRequest from "../interfaces/Requests/GrupoUsuarios/EditarGrupoUsuariosRequest";
import FiltroPesquisaGruposRequest from "../interfaces/Requests/GrupoUsuarios/FiltroPesquisaGruposRequest";
import api from "./Api";

class GrupoUsuariosService {
  async obterGruposUsuarios(filtros: FiltroPesquisaGruposRequest) {
    const queryNome = filtros.nome ? `&nome=${filtros.nome}` : "";

    const queryModulo =
      filtros.moduloId === "0" ? "" : `&moduloId=${filtros.moduloId}`;

    const queryStatus =
      filtros.status === 0
        ? "status=0&status=-1&status=1"
        : `status=${filtros.status}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";
    const queryPaginacao = `&offset=${filtros.offset}${limit}&sort=${filtros.sort}`;

    const { data: result } = await api.get<IRecuperaLista<IGrupoUsuarios>>(
      `GrupoUsuario/RecuperaGrupo?${queryStatus}${queryPaginacao}${queryNome}${queryModulo}`
    );

    return result;
  }

  async alterarStatusGruposUsuarios(id: string, status: number) {
    const body = [
      {
        op: "replace",
        path: "/Status",
        value: status,
      },
    ];

    const { data: result } = await api.patch<IRecuperaLista<IGrupoUsuarios>>(
      `/GrupoUsuario/AtualizaGrupo/${id}`,
      body
    );

    return result;
  }

  async adicionarGrupoUsuarios(request: AdicionarGrupoUsuariosRequest) {
    const { data: result } = await api.post<IGrupoUsuarios>(
      "/GrupoUsuario/AdicionaGrupo",
      request
    );

    return result;
  }

  async obterGrupoUsuarioUnico(id: string) {
    const { data: result } = await api.get<IRecuperaLista<IGrupoUsuarios>>(
      `GrupoUsuario/RecuperaGrupo?grupoId=${id}`
    );

    return result;
  }

  async editarGrupoUsuarios(id: string, request: EditarGrupoUsuariosRequest) {
    const patch = [];

    if (request.nome !== undefined) {
      patch.push({ op: "replace", path: "/Nome", value: request.nome });
    }

    if (request.usuarios !== undefined) {
      patch.push({
        op: "replace",
        path: "/UsuarioIds",
        value: request.usuarios,
      });
    }

    if (request.moduloId !== undefined) {
      patch.push({ op: "replace", path: "/moduloId", value: request.moduloId });
    }

    const { data: result } = await api.patch<IGrupoUsuarios>(
      `/GrupoUsuario/AtualizaGrupo/${id}`,
      patch
    );

    return result;
  }
}

export default new GrupoUsuariosService();
