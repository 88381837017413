import { Tooltip } from "../../../../Tooltip";
import { MenuAction } from "../styles";
import { ButtonHTMLAttributes } from "react";

interface ItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
}

export function Item(props: ItemProps) {
  return (
    <Tooltip placement="bottom" content={props.title}>
      <MenuAction className={props.className} {...props}>{props.children}</MenuAction>
    </Tooltip>
  );
}
