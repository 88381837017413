import { RenderLeafProps } from "slate-react";

export const renderLeaf = ({ children, leaf, attributes }: RenderLeafProps) => {
  const style: React.CSSProperties = {
    background: leaf.marks?.highlight,
    color: leaf.marks?.textColor,
    fontSize: leaf.marks?.fontSize,
    fontFamily: leaf.marks?.fontFamily,
    textIndent: 0
  }

  if (leaf.marks?.bold) {
    children = <strong style={style}>{children}</strong>
  }

  if (leaf.marks?.italic) {
    children = <em style={style}>{children}</em>
  }

  if (leaf.marks?.underline) {
    children = <u style={style}>{children}</u>
  }

  if (leaf.marks?.strikethrough) {
    children = <s style={style}>{children}</s>
  }

  return <span style={style} {...attributes}>{children}</span>
}