import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "./GridPadrao";
import IAreaDireito from "../../interfaces/IAreaDireito";
import IEscritorio from "../../interfaces/IEscritorio";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaEscritorioRequest from "../../interfaces/Requests/Escritorio/FiltroPesquisaEscritorioRequest";
import EscritorioService from "../../services/EscritorioService";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

interface IEscritorioSelectedProps {
  clienteId: number;
  setFieldValue: (field: string, value:any, ok:boolean)=>any;
  escritoriosSelected: IEscritorio[];
}

const EscritorioSelected = ({ clienteId, setFieldValue, escritoriosSelected }: IEscritorioSelectedProps) => {
  const [escritorios, setEscritorios] = useState<IEscritorio[]>([]);
  const [carregandoEscritorios, setCarregandoEscritorios] = useState(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEscritorioRequest>({
    clienteId: clienteId,
    nome: "",
    codigo: "",
    limit: 5,
    isTelaPesquisaProcesso: false,
    totalItems: 0,
    offset: 0,
    sort: "escritorioId",
  });

  useEffect(() => {
    if (escritorios.length > 0) {
      escritoriosSelected.forEach((escritorio) => {
        const escritorioChecked = escritorios.filter((e: IEscritorio) => e.escritorioId === escritorio.escritorioId)[0];
        if (escritorioChecked) escritorioChecked.checked = true;
      });
    }
    setEscritorios(escritorios);
  }, [escritorios, escritoriosSelected]);

  const handleChange = (escritorioId: number) => {
    escritorios.forEach((escritorio) => {
      if (escritorio.escritorioId === escritorioId) {
        if (!escritorio.checked) {
          escritorio.checked = true;
          setFieldValue(
            "escritorios",
            escritorios.filter((e: any) => e.checked),
            true
          );
          setEscritorios(escritorios);
          return;
        }
        if (escritorio.checked) {
          escritorio.checked = false;
          setFieldValue(
            "escritorios",
            escritorios.filter((e: any) => e.checked),
            true
          );
          setEscritorios(escritorios);
          return;
        }
      }
    });
  };

  async function carregarEscritorios(filtro: FiltroPesquisaEscritorioRequest) {
    try {
      setCarregandoEscritorios(true);
      let resultado: IRecuperaLista<IEscritorio>;

      resultado = await EscritorioService.obterEscritorios(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setEscritorios(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setEscritorios([]);
    } finally {
      setCarregandoEscritorios(false);
    }
  }

  const colunas: TableColumn<IEscritorio>[] = [
    {
      name: "Id",
      sortField: "escritorioId",
      selector: (row: IEscritorio) => row.escritorioId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IEscritorio) => row.nome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IEscritorio) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IEscritorio) => {
        return (
          <div>
            <label>
              <FormCheckInput
                name="terms"
                checked={row.checked}
                onClick={() => handleChange(row.escritorioId)}
                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              />
            </label>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAreaDireito>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    carregarEscritorios(filtrosPesquisa);
    
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
      };
    });
  }

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarEscritorios(filtrosPesquisa);
              }}
              className="row g-3 mb-3"
            >
              <div className="col-md-2 ">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Nome
                </label>
                <input
                  value={filtrosPesquisa.nome}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nome: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-nome"
                />
              </div>

              <div className="col-md-2 ">
                <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                  Código
                </label>
                <input
                  value={filtrosPesquisa.codigo}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, codigo: e.target.value };
                    });
                  }}
                  placeholder="Código"
                  type="text"
                  className={"form-control"}
                  id="form-codigo"
                />
              </div>

              <div className="col-md-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-orange search-buttom-margin "
                    onClick={(e) => {
                      carregarEscritorios(filtrosPesquisa);
                    }}
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      limparFiltros();
                    }}
                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-12 mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoEscritorios}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Escritorio"
              itens={escritorios}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EscritorioSelected;
