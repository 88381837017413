import api from "./Api";
import IParteContraria from "../interfaces/IParteContraria";
import IAdvogado from "../interfaces/IAdvogado";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import ICentroCusto from "../interfaces/ICentroCusto";
import IEmpresa from "../interfaces/IEmpresa";
import { IUpdatePartesList } from "../interfaces/Requests/Processo/IUpdatePartesList";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { ECamposIDEnum, EModulo, ETipoCampoEnum } from "../enum";
import { routePropriedade, tipoPorModulo } from "../utils/Utils";

class CamposService {

  async obterXOptions(filtros: any): Promise<IRecuperaLista<any>> {
    let query = "";

    switch (filtros.modulo) {

      case EModulo.AreaDireito:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&areaDireitoId=${filtros.areaDireitoId}`;
        break;

      case EModulo.Contrato:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoContratoId=${filtros.tipoContratoId}`;
        break;

      case EModulo.MarcaEPertences:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoMarcaId=${filtros.tipoMarcaId}`;
        break;

      case EModulo.Imovel:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoImovelId=${filtros.tipoImovelId}`;
        break;

      case EModulo.Regulatorio:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoLicencaId=${filtros.tipoLicencaId}`;
        break;

      case EModulo.Consultivo:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoConsultaId=${filtros.tipoConsultaId}`;
        break;

      case EModulo.Procuracao:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        break;

      case EModulo.Societario:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoSociedadeId=${filtros.tipoSociedadeId}`;
        break;

      case EModulo.AtoSocietario:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoAtoId=${filtros.tipoAtoId}`;
        break;

      case EModulo.Compromisso:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&fluxoFaseId=${filtros.fluxoFaseId}`;
        break;

      case EModulo.Despesa:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoDespesaId=${filtros.tipoDespesaId}`;
        break;

      case EModulo.Protocolo:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoProtocoloId=${filtros.tipoProtocoloId}`;
        break;

      case EModulo.Garantia:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoGarantiaId=${filtros.tipoGarantiaId}`;
        break;

      case EModulo.Desdobramento:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoDesdobramentoId=${filtros.tipoDesdobramentoId}`;
        break;

      case EModulo.Decisao:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoDecisaoId=${filtros.tipoDecisaoId}`;
        break;

      case EModulo.Bloqueio:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoBloqueioId=${filtros.tipoBloqueioId}`;
        break;

      case EModulo.Obrigacao:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoObrigacaoId=${filtros.tipoObrigacaoId}`;
        break;

      case EModulo.ParteContraria:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoParteContrariaId=${filtros.tipoParteContrariaId}`;
        break;

      case EModulo.Comunicacao:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoComunicacaoId=${filtros.tipoComunicacaoId}`;
        break;

      case EModulo.PropostaAcordo:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoPropostaAcordoId=${filtros.tipoPropostaAcordoId}`;
        break;

      case EModulo.XpayHonorario:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoHonorarioId=${filtros.tipoHonorarioId}`;
        break;

      case EModulo.XpayReembolso:
        query += filtros.areaDireitoId ? `areaDireitoId=${filtros.areaDireitoId}&` : '';
        query += filtros.tipoImovelId ? `tipoImovelId=${filtros.tipoImovelId}&` : '';
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoReembolsoId=${filtros.tipoReembolsoId}`;
        break;

      default:
        "";
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(`XCampos/RecuperaListaLimitada?${query}`);

    return result;
  }

  async obterOptions(filtros: any): Promise<IRecuperaLista<any>> {
    let query = "";

    switch (filtros.modulo) {

      case EModulo.ParteContraria:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoParteContrariaId=${filtros.categoriaId}`
        break;

      case EModulo.Despesa:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoDespesaId=${filtros.categoriaId}`
        break;

      case EModulo.Garantia:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoGarantiaId=${filtros.categoriaId}`;
        break;

      case EModulo.Protocolo:
        query += filtros.controller == ECamposIDEnum.ProcessoEmpresa ? `&processoId=${filtros.processoId}` : '';
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += `&tipoProtocoloId=${filtros.categoriaId}`;
        break;

      case EModulo.Bloqueio:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoBloqueioId=${filtros.categoriaId}`;
        break;

      case EModulo.Decisao:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoDecisaoId=${filtros.categoriaId}`;
        break;

      case EModulo.Desdobramento:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoDesdobramentoId=${filtros.categoriaId}`;
        break;

      case EModulo.PropostaAcordo:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoPropostaAcordoId=${filtros.categoriaId}`;
        break;

      case EModulo.Obrigacao:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoObrigacaoId=${filtros.categoriaId}`;
        break;

      case EModulo.XpayHonorario:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoHonorarioId=${filtros.categoriaId}`;
        break;

      case EModulo.XpayReembolso:
        query += filtros.areaDireitoId ? `&areaDireitoId=${filtros.areaDireitoId}` : '';
        query += filtros.tipoImovelId ? `&tipoImovelId=${filtros.tipoImovelId}` : '';
        query += `&tipoReembolsoId=${filtros.categoriaId}`;
        break;

      default:
        "";
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(`${filtros.controller}/RecuperaListaLimitada?modulo=${filtros.modulo}${query}`);

    return result;
  }

  async obterAdvogadosInterno(objetoId: number, entidade: string): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/${entidade}/RecuperaAdvogadoInternoPorId?${entidade}Id=${objetoId}`);

    return result;
  }

  async obterCentroCusto(objetoId: number, entidade: string): Promise<IRecuperaLista<ICentroCusto>> {
    let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`/${entidade}/RecuperaCentroCustoPorId?${entidade}Id=${objetoId}`);
    return result;
  }

  async alterarPorCampo(campo: string, valor: any, objetoId: number, entidade: string) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let { data: result } = await api.patch<any>(`${entidade}/Atualiza${entidade}PorId?id=${objetoId}`, request);
      return result;
    }
  }

  async alterarPorXCampo(campo: string, valor: any, xcampoid: number, entidade: string) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let result = await api.patch<any>(`${entidade}/AtualizaXCampoValor?id=${xcampoid}`, request);
      return result;
    }
  }

  async alterarPorXCampoMulti(XOpcoes: any, xcampoid: number, entidade: string, objetoId: number,) {
    let request: any = {};

    request[`${entidade}Id`] = objetoId;

    request.campoId = xcampoid;

    request.xCampoOpcaoIdList = [];

    XOpcoes.forEach((opcao: any) => { request.xCampoOpcaoIdList.push(opcao.value); });

    let result = await api.post<any>(`${entidade}/AtualizaXCampoValorListaMulti?id=${xcampoid}`, request);

    return result;
  }

  async adicionarPorXCampoValor(campo: number, valor: any, xCampoOpcaoId: number, objetoId: number, entidade: string, tipo: ETipoCampoEnum) {
    let request: any;

    let objeto: any = {};

    objeto[`${entidade}Id`] = objetoId;

    switch (tipo) {

      case ETipoCampoEnum.Data:
        objeto.data = valor;
        break;

      case ETipoCampoEnum.Texto:
      case ETipoCampoEnum.AreaTexto:
        objeto.texto = valor;
        break;

      case ETipoCampoEnum.Lista:
      case ETipoCampoEnum.EnumLista:
        objeto.lista = valor;
        break;

      case ETipoCampoEnum.Inteiro:
        objeto.inteiro = valor;
        break;

      case ETipoCampoEnum.Numerico:
        objeto.numerico = valor;
        break;

      default:
        objeto.valor = valor;
        break;
    }

    if (xCampoOpcaoId > 0)
      request = {
        campoId: campo,
        xCampoOpcaoId: xCampoOpcaoId,
        ...objeto,
      };
    else
      request = {
        campoId: campo,
        ...objeto,
      }

    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);
    return result;
  }

  async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);

    return result;
  }

  async alterarPorXCampoBolleano(campo: string, valor: any, xcampoid: number, entidade: string) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`${entidade}/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  async alterarPorCampoBolleano(campo: string, valor: any, objetoId: number, entidade: string) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<any>(`${entidade}/Atualiza${entidade}PorId?id=${objetoId}`, request);
  }

  async alterarPorCentroCustoList(centrosCusto: ICentroCusto[], objetoId: number, entidade: string) {
    if (centrosCusto.length > 0) {
      let objeto: any = {};
      objeto[`${entidade}Id`] = objetoId;
      await api.put<any>(`${entidade}/AtualizaCentroCusto`, {
        ...objeto,
        centrosCusto: centrosCusto,
      });
    }
  }

  async alterarPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], objetoId: number, entidade: string) {

    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        let objeto: any = {};
        objeto[`${entidade}Id`] = objetoId;
        const request: IUpdatePartesList = {
          ...objeto,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<any>(`${entidade}/AtualizaPartes${entidade}PorId`, request);
      }
    }
  }

  async alterarPorAdvogadoList(advogadoList: IAdvogado[], objetoId: number, entidade: string) {
    if (advogadoList.filter((e) => e.checked).length > 0) {
      const advogadoListRequest: any[] = [];
      advogadoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoId: item.advogadoId,
            codigo: item.codigo,
          };
          advogadoListRequest.push(advogado);
        });

      if (advogadoListRequest.length > 0) {
        let objeto: any = {};
        objeto[`${entidade}Id`] = objetoId;
        const request: any = {
          ...objeto,
          advogadoList: advogadoListRequest,
        };
        await api.put<any>(`${entidade}/AtualizaPartes${entidade}PorId`, request);
      }
    }
  }

  async alterarPorEmpresaList(empresasList: IEmpresa[], objetoId: number, entidade: string, rateio?: boolean) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      let objeto: any = {};
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {

          if (rateio) {
            if (item.gestaoProcessoId) objeto.gestaoProcessoId = item.gestaoProcessoId;
            if (item.percentual) objeto.percentual = item.percentual;
          }

          if (item.papelSignatarioId) objeto.papelSignatarioId = item.papelSignatarioId;
          if (item.formaParticipacaoId) objeto.formaParticipacaoId = item.formaParticipacaoId;

          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            ...objeto
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        let objeto: any = {};
        objeto[`${entidade}Id`] = objetoId;
        const request: any = {
          ...objeto,
          empresasList: empresasRequestList,
        };
        await api.put<any>(`${entidade}/AtualizaPartes${entidade}PorId`, request);
      }
    }
  }

  async adicionaXCampoValorTabela(linhas: any, entidade: string, objetoId: number): Promise<void> {
    let objeto: any = {};
    objeto[`${entidade}Id`] = objetoId;
    await api.post<any>(`${entidade}/AdicionaXCampoValor`, {
      ...objeto, xCampoValorTabelaCreateDto: linhas, campoId: linhas?.find((e: any) => e)?.campoId
    });
  }

  async alterarPorXCampoTabela(campo: string, valor: any, xcampoid: number, entidade: string) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    
    await api.patch<any>(`${entidade}/AtualizaXCampoValorTabela?id=${xcampoid}`, request);
  }

  async removerParteContraria(parteContrariaId: any, entidade: string): Promise<void> {
    await api.delete<void>(`${entidade}/ExcluirParteContrariaPorId?id=${parteContrariaId}`);
  }

  async removerXCampoValorTabelaPorLinha(xCampoValorTabelaId: number, modulo: EModulo): Promise<void> {
    if (xCampoValorTabelaId) {
      await api.post<void>(`${routePropriedade(modulo)}/RemoveXCampoValorTabela`, [xCampoValorTabelaId]);
    }
  }

  async removerXCampoValorTabelaPorLinhas(linhas: any, entidade: string): Promise<void> {
    linhas.forEach(async (linha: any) => {
      if (linha.xCampoValorTabelaId) {
        await api.post<void>(`${entidade}/RemoveXCampoValorTabela`, [linha.xCampoValorTabelaId]);
      }
    });
  }

  async removerXCampoValorTabelaPorColuna(colunas: any, entidade: string): Promise<void> {
    colunas.forEach(async (linhas: any) => {
      linhas.forEach(async (linha: any) => {
        if (linha.xCampoValorTabelaId)
          await api.post<void>(`${entidade}/RemoveXCampoValorTabela`, [linha.xCampoValorTabelaId]);
      });
    });
  }

  async alterarXCampoValorTabelaPorLinhas(linhas: any, entidade: string): Promise<void> {
    if (linhas?.length > 0) await api.post<void>(`${entidade}/AdicionaLinhaXCampoValorTabela`, linhas);
  }

  async alterarXCampoValorTabelaPorColuna(linhas: any, entidade: string, objetoId: number): Promise<void> {

    let addCampo: any[] = [];
    let addLinha: any[] = [];

    linhas.forEach((item: any) => {
      if (item.colunas) {
        item.colunas.forEach((coluna: any) => {
          if (!coluna.xCampoValorTabelaId) {
            if (!coluna.xCampoValorId) {
              addCampo.push(coluna);
            } else {
              addLinha.push(coluna);
            }

          }
        });
      }
    });

    if (addLinha.length > 0) {
      await api.post<void>(`${entidade}/AdicionaLinhaXCampoValorTabela`, addLinha);
    }

    if (addCampo.length > 0) {
      await this.adicionaXCampoValorTabela(addCampo, entidade, objetoId);
    }
  }

  async alterarPorParteContrariaList(partesContrariasList: IParteContraria[], objetoId: number, entidade: string) {
    const partesContrariasRequestList: any[] = [];
    partesContrariasList.forEach((parteContraria) => {
      let requestParteContraria: any = {};
      if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
      if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
      if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
      if (parteContraria.cargoId) requestParteContraria.cargoId = parteContraria.cargoId;
      if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
      if (parteContraria.tipoCentroCustoId) requestParteContraria.tipoCentroCustoId = parteContraria.tipoCentroCustoId;
      if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
      if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
      if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
      if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
      if (parteContraria.ctps) requestParteContraria.ctps = parteContraria.ctps;
      if (parteContraria.dataAdmissao) requestParteContraria.dataAdmissao = parteContraria.dataAdmissao;
      if (parteContraria.dataDemissao) requestParteContraria.dataDemissao = parteContraria.dataDemissao;
      if (parteContraria.dataNascimento) requestParteContraria.dataNascimento = parteContraria.dataNascimento;
      if (parteContraria.departamentoId) requestParteContraria.departamentoId = parteContraria.departamentoId;
      if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
      if (parteContraria.email) requestParteContraria.email = parteContraria.email;
      if (parteContraria.empresaContratante) requestParteContraria.empresaContratante = parteContraria.empresaContratante;
      if (parteContraria.empresaTerceira) requestParteContraria.empresaTerceira = parteContraria.empresaTerceira;
      if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
      if (parteContraria.estadoCivilId) requestParteContraria.estadoCivilId = parteContraria.estadoCivilId;
      if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
      if (parteContraria.papelSignatarioId) requestParteContraria.papelSignatarioId = parteContraria.papelSignatarioId;
      if (parteContraria.formaParticipacaoId) requestParteContraria.formaParticipacaoId = parteContraria.formaParticipacaoId;
      if (parteContraria.formaParticipacaoNome) requestParteContraria.formaParticipacaoNome = parteContraria.formaParticipacaoNome;
      if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
      if (parteContraria.matricula) requestParteContraria.matricula = parteContraria.matricula;
      if (parteContraria.moedaId) requestParteContraria.moedaId = parteContraria.moedaId;
      if (parteContraria.motivoDesligamentoId) requestParteContraria.motivoDesligamentoId = parteContraria.motivoDesligamentoId;
      if (parteContraria.nacionalidade) requestParteContraria.nacionalidade = parteContraria.nacionalidade;
      if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
      if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
      if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
      if (parteContraria.numeroContrato) requestParteContraria.numeroContrato = parteContraria.numeroContrato;
      if (parteContraria.observacoesContratacao) requestParteContraria.observacoesContratacao = parteContraria.observacoesContratacao;
      if (parteContraria.observacoesDesligamento) requestParteContraria.observacoesDesligamento = parteContraria.observacoesDesligamento;
      if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
      if (parteContraria.orgaoRegistroId) requestParteContraria.orgaoRegistroId = parteContraria.orgaoRegistroId;
      if (parteContraria.pisPasep) requestParteContraria.pisPasep = parteContraria.pisPasep;
      if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
      if (parteContraria.regimeContratacao) requestParteContraria.regimeContratacao = parteContraria.regimeContratacao;
      if (parteContraria.rg) requestParteContraria.rg = parteContraria.rg;
      if (parteContraria.rgOrgao) requestParteContraria.rgOrgao = parteContraria.rgOrgao;
      if (parteContraria.statusFolhaId) requestParteContraria.statusFolhaId = parteContraria.statusFolhaId;
      if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
      if (parteContraria.tipoContratacao) requestParteContraria.tipoContratacao = parteContraria.tipoContratacao;
      if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
      if (parteContraria.ultimoSalario) requestParteContraria.ultimoSalario = parteContraria.ultimoSalario;
      partesContrariasRequestList.push(requestParteContraria);
    });

    let objeto: any = {};
    objeto[`${entidade}Id`] = objetoId;
    const request: any = {
      ...objeto,
      partesContrariasList: partesContrariasRequestList,
    }
    await api.put<any>(`${entidade}/AtualizaPartes${entidade}PorId`, request);

  }


}


export default new CamposService();