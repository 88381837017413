import { faCheckCircle, faEraser, faPen, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "../../components/Comum/GridPadrao";
import IFechamentoConfiguracao from "../../interfaces/IFechamentoConfiguracao";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaFechamentoConfiguracaoRequest from "../../interfaces/Requests/FechamentoConfiguracao/FiltroPesquisaFechamentoConfiguracaoRequest";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import FechamentoConfiguracaoService from "../../services/FechamentoConfiguracaoService";
import FechamentoConfiguracaoModal from "./FechamentoConfiguracaoModal";
import { Navigate, useNavigate } from "react-router-dom";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "../../contexts/OrangeProvider";
 

const FechamentoConfiguracaoPage = () => {
    const [fechamentoConfiguracao, setFechamentoConfiguracao] = useState<IFechamentoConfiguracao>();
    const [fechamentoConfiguracaos, setFechamentoConfiguracaos] = useState<IFechamentoConfiguracao[]>([]);
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoFechamentoConfiguracao, setCarregandoFechamentoConfiguracao] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);
    const [inputOptions, setInputOptions] = useState<any>([]);
    const navigate = useNavigate();
    const {setSelectedParametrizacoes, selectedParametrizacoes } = useContext<OrangeInterfaceContext>(OrangeContext);
    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaFechamentoConfiguracaoRequest>({
        nome: '',
        descricao: '',
        codigo: '',
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: 'id'
    });

    function toggleModal(carregarDados?: boolean): void {
        if (carregarDados) {
            carregarFechamentoConfiguracao(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);

        setTimeout(() => {
            setFechamentoConfiguracao(undefined)
        }, 300)
    }

    async function carregarFechamentoConfiguracao(filtro: FiltroPesquisaFechamentoConfiguracaoRequest) {
        try {
            setCarregandoFechamentoConfiguracao(true);
            let resultado: IRecuperaLista<IFechamentoConfiguracao>;

            resultado = await FechamentoConfiguracaoService.obterFechamentoConfiguracaos(filtro);

            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
            setFechamentoConfiguracaos(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setFechamentoConfiguracaos([]);
        }
        finally {
            setCarregandoFechamentoConfiguracao(false);
            setLoadingInicial(false);
        }
    }

    async function alterarStatus(fechamentoConfiguracaoId: number, status: number) {
        try {
            const swalWithBootstrapButtons = Swal.mixin({
                heightAuto: false,
                customClass: {
                    confirmButton: 'btn btn-orange',
                    cancelButton: 'btn btn-danger ms-5'
                },
                buttonsStyling: false
            });

            let result = await swalWithBootstrapButtons.fire({
                title: 'Alterar Status ',
                text: `Você realmente deseja alterar o status?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Confirmar`
            });

            if (result.isConfirmed) {
                await FechamentoConfiguracaoService.alterarStatus(fechamentoConfiguracaoId, status);

                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Status da configuração do fechamento alterado com sucesso!`,
                    showConfirmButton: false,
                    timer: 3000
                })

                await limparFiltros();

                setFiltrosPesquisa(oldState => {
                    carregarFechamentoConfiguracao(oldState);
                    return oldState;
                })
            }

        } catch (error: any) {
            let mensagemErro = 'Ocorreu um erro inesperado';

            if (error?.response?.data?.Message) {
                mensagemErro = error.response.data.Message
            }

            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel alterar o Status do Configuração do Fechamento`,
                text: mensagemErro,
                showConfirmButton: true
            });
        }
    }

    const colunas: TableColumn<IFechamentoConfiguracao>[] = [
        {
            name: 'Id',
            sortField: 'fechamentoConfiguracaoId',
            selector: (row: IFechamentoConfiguracao) => row.fechamentoConfiguracaoId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Nome',
            sortField: 'fechamentoConfiguracaoNome',
            selector: (row: IFechamentoConfiguracao) => row.nome,
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            sortField: 'status',
            selector: (row: IFechamentoConfiguracao) => row.status,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (fechamentoConfiguracao: IFechamentoConfiguracao) => {
                return (
                    <div>
                        <FontAwesomeIcon title="Editar Configuração do Fechamento" onClick={() => {
                            setFechamentoConfiguracao(fechamentoConfiguracao);
                            setExibirModal(true);
                        }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />

                        {fechamentoConfiguracao.status === 'Ativo' ?
                            <FontAwesomeIcon title="Desativar Configuração do Fechamento" onClick={() => {
                                alterarStatus(fechamentoConfiguracao.fechamentoConfiguracaoId, -1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faTrashAlt} />
                            :
                            <FontAwesomeIcon title="Ativar Configuração do Fechamento" onClick={() => {
                                alterarStatus(fechamentoConfiguracao.fechamentoConfiguracaoId, 1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faCheckCircle} />
                        }
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ]

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<IFechamentoConfiguracao>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };

    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                nome: '',
                codigo: '',
                status: 1
            }
        });
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarFechamentoConfiguracao(filtrosPesquisa);
        }

        
    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

    useEffect(() => {

        async function fetchData() {
            try {
                const resultado = await FechamentoConfiguracaoService.obterFechamentoConfiguracaos(filtrosPesquisa);
                setInputOptions(resultado.data); // Ajustando para guardar apenas os dados retornados
            } catch (error) {
                console.error("Falha ao buscar configurações", error);
                setInputOptions([]); // Assegura que inputOptions está limpo em caso de erro
            }
        }
        fetchData();
    }, [filtrosPesquisa]);


    return (<>
        <LayoutPageTitle title="Configuração do Fechamento">
            <button onClick={() => {
                setFechamentoConfiguracao(undefined);
                setExibirModal(true);
            }} className="btn btn-md btn-orange">
                Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        <FechamentoConfiguracaoModal exibirModal={exibirModal} titulo={fechamentoConfiguracao ? 'Editar Registro' : 'Criar Registro'} toggleModal={toggleModal} FechamentoConfiguracao={fechamentoConfiguracao} />

        <div className="row mt-2">
            <div className="col-md-12">
                <div className="col-md-12">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        carregarFechamentoConfiguracao(filtrosPesquisa);
                    }} className="row g-3 mb-3">
                        <div className="col-md-2">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome</label>
                            <select value={filtrosPesquisa.nome}
                            onChange={(e) => {
                                    setFiltrosPesquisa(oldState => { return { ...oldState, nome: e.target.value } });
                                }} placeholder="Nome" className={'form-select'} id="form-select" >
                                    {inputOptions.map((option: any, index: any) => (
                                        <option key={index} value={option.nome}>{option.nome}</option>
                                    ))}

                                    <option value="">Todos</option>
                            </select>
                        </div>
                        <div className="col-md-2 ">
                            <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">Código</label>
                            <input value={filtrosPesquisa.codigo} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, codigo: e.target.value } });
                            }} placeholder="Código" type="text" className={'form-control'} id="form-codigo" />
                        </div>        
                        <div className="col-md-2">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Status</label>
                            <select value={filtrosPesquisa.status} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                            }} placeholder="Nome" className={'form-select'} id="form-select" >
                                <option value="0" label="Todos"> Todos </option>
                                <option value="1" label="Ativo"> Ativo </option>
                                <option value="-1" label="Inativo"> Inativo </option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                                    <FontAwesomeIcon color='white' className='mx-2' icon={faSearch} />
                                </button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    limparFiltros();
                                }} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                                    <FontAwesomeIcon color='white' className='mx-2' icon={faEraser} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 mb-10">
                    <GridPadrao onRowClicked={(e)=>{
                    
                    const initialState = {
                        fechamentoConfiguracaoId: e.fechamentoConfiguracaoId,
                        areasDireito: e.areasDireito,
                        empresas:e.empresas
                    } 
                setSelectedParametrizacoes(initialState); 
                navigate("/Fechamento/ConfiguracaoTela/FechamentoParametrizacao")}} onSort={handleSort} progressPending={carregandoFechamentoConfiguracao} limit={filtrosPesquisa.limit} onChangePage={handlePageChange} onChangeRowsPerPage={handlePerRowsChange} paginationServer={true} paginationTotalRows={filtrosPesquisa.totalItems} colunas={colunas} tipo='Configuração do Fechamento' itens={fechamentoConfiguracaos} />
                </div>
            </div>
        </div >
    </>)
}

export default FechamentoConfiguracaoPage;