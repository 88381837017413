import { Draggable } from "react-beautiful-dnd";
import { Container, Title, Details } from "./styles";
import { TicketProps } from "./props";

export function Ticket({ index, card, selectCard }: TicketProps) {
  return (
    <Draggable index={index} draggableId={card.consultaId.toString()}>
      {(provided, snapshot) => (
        <Container
          onClick={() => selectCard(card)}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Title>{card.tipoConsultaNome} {card.tituloConsulta ? `- ${card.tituloConsulta}` : null}</Title>
          <Details>
            <span>#{card.consultaId}</span>
          </Details>
        </Container>
      )}
    </Draggable>
  );
}
