import { IEtiqueta } from "../../interfaces/IEtiquetas";

export const ETIQUETAS_STATICA: IEtiqueta[] = [
  { etiquetaId:0, nome: "", cor: "primary", documentoId: 0 },
  { etiquetaId:0, nome: "", cor: "success", documentoId: 0 },
  { etiquetaId:0, nome: "", cor: "secondary", documentoId: 0 },
  { etiquetaId:0, nome: "", cor: "danger", documentoId: 0 },
  { etiquetaId:0, nome: "", cor: "info", documentoId: 0 },
  { etiquetaId:0, nome: "", cor: "warning", documentoId: 0 },
];
