import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IFechamentoGlossario from "../interfaces/IFechamentoGlossario";
import { AdicionaFechamentoGlossarioRequest } from "../interfaces/Requests/FechamentoGlossario/AdicionaFechamentoGlossarioRequest";
import FiltroPesquisaFechamentoGlossarioRequest from "../interfaces/Requests/FechamentoGlossario/FiltroPesquisaFechamentoGlossarioRequest";
import { EditarFechamentoGlossarioRequest } from "../interfaces/Requests/FechamentoGlossario/EditarFechamentoGlossarioRequest";

class FechamentoGlossarioService {

    async obterFechamentoGlossarios(filtros: FiltroPesquisaFechamentoGlossarioRequest): Promise<IRecuperaLista<IFechamentoGlossario>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.descricao) {
            query += `&descricao=${filtros.descricao}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFechamentoGlossario>>(`FechamentoGlossario/RecuperaFechamentoGlossario?${query}`);

        return result;
    }

    async adicionaFechamentoGlossario(adicionaFechamentoGlossario: AdicionaFechamentoGlossarioRequest): Promise<IFechamentoGlossario> {
        let { data: result } = await api.post<IFechamentoGlossario>(`FechamentoGlossario/AdicionaFechamentoGlossario`, adicionaFechamentoGlossario);

        return result;
    }


    async alterarStatus(fechamentoGlossarioId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IFechamentoGlossario>(`FechamentoGlossario/AtualizaFechamentoGlossarioPorId?id=${fechamentoGlossarioId}`, data);
    }

    async atualizarFechamentoGlossario(editarFechamentoGlossario: EditarFechamentoGlossarioRequest): Promise<void> {

        let dataFechamentoGlossario = [
            { "op": "replace", "path": "/coluna", "value": editarFechamentoGlossario.coluna },
            { "op": "replace", "path": "/codigo", "value": editarFechamentoGlossario.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`FechamentoGlossario/AtualizaFechamentoGlossarioPorId?id=${editarFechamentoGlossario.fechamentoGlossarioId}`, dataFechamentoGlossario)
        ]);
    }
}


export default new FechamentoGlossarioService();