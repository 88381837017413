import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IClassificacao from "../interfaces/IClassificacao";
import { AdicionaClassificacaoRequest } from "../interfaces/Requests/Classificacao/AdicionaClassificacaoRequest";
import FiltroPesquisaClassificacaoRequest from "../interfaces/Requests/Classificacao/FiltroPesquisaClassificacaoRequest";
import { EditarClassificacaoRequest } from "../interfaces/Requests/Classificacao/EditarClassificacaoRequest";

class ClassificacaoService {

    async obterClassificacaos(filtros: FiltroPesquisaClassificacaoRequest): Promise<IRecuperaLista<IClassificacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IClassificacao>>(`Classificacao/RecuperaClassificacao?${query}`);

        return result;
    }

    async adicionaClassificacao(adicionaClassificacao: AdicionaClassificacaoRequest): Promise<IClassificacao> {
        let { data: result } = await api.post<IClassificacao>(`Classificacao/AdicionaClassificacao`, adicionaClassificacao);

        return result;
    }


    async alterarStatus(classificacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IClassificacao>(`Classificacao/AtualizaClassificacaoPorId?id=${classificacaoId}`, data);
    }

    async atualizarClassificacao(editarClassificacao: EditarClassificacaoRequest): Promise<void> {

        let dataClassificacao = [
            { "op": "replace", "path": "/nome", "value": editarClassificacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarClassificacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Classificacao/AtualizaClassificacaoPorId?id=${editarClassificacao.classificacaoId}`, dataClassificacao)
        ]);
    }
}


export default new ClassificacaoService();