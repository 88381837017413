import { Descendant } from "slate"
import { is } from "./is"
import { EditorHandler } from "../types"
import { addFooter } from "./addFooter"
import { addHeader } from "./addHeader"
import { FooterElement, HeaderElement, PageElement } from "../../../utils/types/slate"

interface HandleChildParams extends EditorHandler {
  child: Descendant
}

function handleChild({ child, ...version }: HandleChildParams) {
  if (is<FooterElement>(child, 'footer')) {
    return addFooter(child, {
      block: version.footer,
      currentPage: version.pageNumber,
      pageCount: version.editor.children.length,
      firstPageDiff: version.firstPageHeaderAndFooterDifferent
    })
  }

  if (is<HeaderElement>(child, 'header')) {
    return addHeader(child, {
      block: version.header,
      currentPage: version.pageNumber,
      pageCount: version.editor.children.length,
      firstPageDiff: version.firstPageHeaderAndFooterDifferent
    })
  }

  return child
}

function getChilds(page: PageElement, data: EditorHandler): PageElement[] {
  const children = page.children.map((child) =>
    handleChild({
      child,
      ...data
    })
  );
  return [{ ...page, children }];
}

export const childHelper = {
  handleChild,
  getChilds
}