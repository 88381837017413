import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareAltSquare,
} from "@fortawesome/free-solid-svg-icons";

const whatsapp = "/images/whatsapp.png";
const teams = "/images/teams.png";


interface ICompartilharModalProps {
  texto: string,
  toggleModal: () => void;
  exibirModal: boolean;
}

const CompartilharModal = ({
  texto,
  toggleModal,
  exibirModal,

}: ICompartilharModalProps) => {

  return (
    <>

      <Modal size="sm" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              Compartilhar{" "}
              <FontAwesomeIcon
                className="mx-2 text-orange"
                icon={faShareAltSquare}
              />
            </h5>
          </div>
        </div>
        <div className="modal-body">
          <div className="col-md-12">

            <a
              style={{ cursor: "pointer", fontSize: "30px" }}
              className="linkVisitado"
              href={`https://web.whatsapp.com/send?text=${texto}`}
              target="_blank"
              title="Compartilhar através do WhatsApp"
            >
              <img src={whatsapp} style={{ margin: '0 auto', width: '35px', height: '35px' }} />
            </a>

            <a
              style={{ cursor: "pointer", fontSize: "30px", marginLeft: '10px' }}
              className="linkVisitado"
              href={`https://teams.microsoft.com/l/chat/0/0?message=${texto}`}
              target="_blank"
              title="Compartilhar através do Teams"
            >
              <img src={teams} style={{ margin: '0 auto', width: '35px', height: '35px' }} />
            </a>
          </div>

        </div>
      </Modal>
    </>
  );
};

export default CompartilharModal;
