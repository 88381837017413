/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Draggable } from "react-beautiful-dnd";
import { faLayerGroup, faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EModulo, ETelaEnum } from "../../enum";
import { ETipoTelaEnum } from "../../enum/ETipoTelaEnum";

import { ICampo } from "../../interfaces/ICampo";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";

import LimitacaoTabelaService from "../../services/LimitacaoTabelaService";

import { DefinirTipoTela } from "../../utils/Utils";
import { OrangeContext } from "../../contexts/OrangeProvider";
import { notifySuccess } from "./Toast/ToastFormik";
import ConfiguracaoSeparadorService from "../../services/ConfiguracaoSeparadorService";

interface ItemProps {
  alterarItem(item: ICampo): void;
  item: ICampo;
  index: number;
  col: string;
}

const FormPCampos: React.FC<ItemProps> = ({ item, index, alterarItem, col }) => {

  const { tela, campos, eCampos } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [exibirModal, setExibirModal] = useState<boolean>(false);

  const [separadores, setSeparadores] = useState<ICampo[]>([]);

  const initialValues = {
    nome: "",
    help: item?.help,
    label: item?.label,
    campo: item?.campo,
    modulo: item?.modulo,
    camposId: item?.camposId,
    isXCampo: item?.isXCampo,
    campoNome: item?.campoNome,
    ordenacao: item?.ordenacao,
    separadorId: item?.separadorId,
    tipo: item?.tipo || item?.campoTipo,
    entidade: item?.campoNome?.toLowerCase(),

    entidadeCondicionada: "",
    somenteLeitura: false,
    obrigatorio: false,
    Lista: [],

  } as ICampo;

  const todo = (draggableStyle: any) => ({
    padding: 10,
    margin: `0 50px 15px 40px`,
    background: "var(--primary-base)",
    color: "white",
    border: `1px solid`,
    fontSize: `12px`,
    borderRadius: `5px`,
    cursor: "pointer",
    ...draggableStyle,
  });

  const doing = (isDragging: boolean, draggableStyle: any) => ({
    padding: 10,
    margin: `0 50px 15px 40px`,
    background: isDragging ? "var(--primary-base)" : "white",
    color: "white",
    border: `1px solid`,
    fontSize: `12px`,
    borderRadius: `5px`,
    cursor: "pointer",
    ...draggableStyle,
  });

  const doingX = (isDragging: boolean, draggableStyle: any) => ({
    padding: 10,
    margin: `0 50px 15px 40px`,
    background: isDragging ? "rgb(241, 114, 83)" : "white",
    color: isDragging ? "white" : "black",
    border: `1px solid`,
    fontSize: `12px`,
    borderRadius: `5px`,
    cursor: "pointer",
    ...draggableStyle,
  });

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>Opção de organização que permite reunir campos relacionados ou similares em uma única seção visual. Essa funcionalidade é valiosa em interfaces de usuário para melhorar a usabilidade e a experiência do usuário, especialmente em telas com muitos campos ou informações.</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      alterarItem(values);

      campos.splice(values.ordenacao, 0, { ...values });

      LimitacaoTabelaService.adicionaOpcoes(values);

      formik.setValues(initialValues);

      setSubmitting(false);

      notifySuccess('Limitação de opções cadastrada com sucesso');
    },
  });

  useEffect(() => {

    if (exibirModal) {

      carregarConfiguracaoTelaSeparadores();

      formik.setValues(initialValues);

    }

  }, [exibirModal]);

  const carregarConfiguracaoTelaSeparadores = async () => {

    try {
      let resultado: IRecuperaLista<any>;

      resultado = await ConfiguracaoSeparadorService.obterDoing({
        modulo: formik.values.modulo || EModulo.Default,
        areaDireitoId: formik.values.areaDireitoId,
        tipoContratoId: formik.values.tipoContratoId,
        tipoConsultaId: formik.values.tipoConsultaId,
        tipoImovelId: formik.values.tipoImovelId,
        tipoLicencaId: formik.values.tipoLicencaId,
        tipoMarcaId: formik.values.tipoMarcaId,
        tipoProcuracaoId: formik.values.tipoProcuracaoId,
        tipoSociedadeId: formik.values.tipoSociedadeId,
        fluxosFasesId: formik.values.fluxoFaseId,
        nome: "",
        telas: {
          capa: tela,
          cadastro: tela
        },
      }, ETelaEnum.TelaSeparadorPesquisa);

      setSeparadores(resultado.data);

    } catch (error: any) {
      setSeparadores([]);
    }
  }

  const preencherCampos = () => {
    if (DefinirTipoTela(tela) === ETipoTelaEnum.Cadastro) {
      item.entidade = eCampos.filter((e: any) => e.campo === item.campo)[0]?.entidade;
      item.tipo = eCampos.filter((e: any) => e.campo === item.campo)[0]?.tipo;
    }
    formik.setValues(item);
    setExibirModal(!exibirModal);
  };

  return (
    <>
      <Draggable key={item?.campo} draggableId={item?.campo?.toString()} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                onClick={() => preencherCampos()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={todo(provided.draggableProps.style)}
              >
                {item?.label}

              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                onClick={() => preencherCampos()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={item.isXCampo ? doingX(true, provided.draggableProps.style) : doing(true, provided.draggableProps.style)}
                title={!item.separadorId && DefinirTipoTela(tela) == ETipoTelaEnum.Capa && 'Definir Separador'}
              >
                <>
                  {formik.values?.label}{formik.values?.obrigatorio && <span style={{ color: 'red' }}>{" "}*</span>}{tela == ETelaEnum.TelaPesquisaMaisFiltros && !formik.values.separadorId && <FontAwesomeIcon color={"white"} className="mx-2" icon={faLayerGroup} />}
                </>
              </div>
            </>
          )
        }
      </Draggable>

      <Modal size="lg" centered={true} show={exibirModal} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title text-orange">Alterar Campo: {formik.values.campoNome} / Label: {formik.values.label}</h6>
            <div
              onClick={() => {
                formik.setValues(initialValues);
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Nome:
                  </label>
                  <input placeholder="Nome" {...formik.getFieldProps("label")} type="text" className={"form-control"}></input>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Texto de Ajuda:
                  </label>
                  <input placeholder={formik.values.help} {...formik.getFieldProps("help")} type="text" className={"form-control"}></input>
                </div>
              </div>

              {tela == ETelaEnum.TelaPesquisaMaisFiltros &&
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                      Definir Separador: <PopoverHelper />
                    </label>
                    <select
                      className={'form-select'}
                      {...formik.getFieldProps("separadorId")}
                      value={formik.values.separadorId || 0}
                      onChange={(event: any) => {
                        formik.setFieldValue("separadorId", parseInt(event.target.value), true);
                      }}
                    >
                      <option key={0} value={0}> Selecione</option>
                      {separadores.map((campo: ICampo) => {
                        return (
                          <option key={campo.separadorId} value={campo.separadorId || 0}>
                            {campo.label}
                          </option>
                        );
                      })}
                    </select>
                    {formik.touched.separadorId && formik.errors.separadorId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.separadorId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }

            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>

            <button
              onClick={() => {
                alterarItem(formik.values);
                setExibirModal(!exibirModal);
              }}
              type="button"
              className="btn btn-orange me-5"
            >
              ALTERAR
            </button>

            <button
              onClick={() => {
                setExibirModal(!exibirModal);
                formik.setValues(initialValues);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal >
    </>
  );
};

export default FormPCampos;
