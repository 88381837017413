/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./Api";
import moment from "moment";

import { EModulo, ETipoCampoEnum } from "../enum";
import { currencyLabelFormatter } from "../utils/Utils";

import IOption from "../interfaces/IOption";
import IContrato from "../interfaces/IContrato";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IParteContraria from "../interfaces/IParteContraria";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import IEmpresaContrato from "../interfaces/IEmpresaContrato";
import { IContratoDocumentos } from "../interfaces/IContratoMinuta";
import { IUpdatePartesList } from "../interfaces/Requests/Contrato/IUpdatePartesList";
import { IAdicionaContratoRequest } from "../interfaces/Requests/Contrato/IAdicionaContratoRequest";
import { IRecuperaDocumentoContrato } from "../interfaces/Requests/Editor/IRecuperaDocumentoContrato";
import FiltroPesquisaContratoRequest from "../interfaces/Requests/Contrato/FiltroPesquisaContratoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import CamposService from "./CamposService";

import { EModuloNomeEnum } from "../enum/EModuloNomeEnum";

class ContratoService {

  public async obterContratos(filtros: FiltroPesquisaContratoRequest): Promise<IRecuperaLista<IContrato>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query = this.montarTextos(filtros, query);

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<IContrato>>(`/Contrato/RecuperaListagemContrato?${query}`);

    return result;
  }

  public async obterContratosv2(filtros: any): Promise<IRecuperaLista<IContrato>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query += this.montarXCampov2(filtros.xCampoValores);
    query = this.montarTextosv2(filtros, query);
    query = this.montarListasv2(filtros, query);
    query = this.montarDatasv2(filtros, query);

    const { data: result } = await api.get<IRecuperaLista<IContrato>>(`/contrato/recuperalistagemcontrato?${query}`);
    return result;
  }


  public async adicionaContrato(adicionaContrato: IAdicionaContratoRequest): Promise<IContrato> {
    let { data: result } = await api.post<IContrato>(`Contrato/AdicionaContrato`, this.montarObjetoRequest(adicionaContrato));
    return result;
  }

  public async adicionaContrato2(adicionaContrato: any, centroCusto: any[]): Promise<IContrato> {
    let { data: result } = await api.post<IContrato>(`Contrato/AdicionaContrato`, this.montarObjetoRequest2(adicionaContrato, centroCusto));
    return result;
  }

  public async editarContrato(editarContrato: IAdicionaContratoRequest) {
    let request = this.montarObjetoArrayContrato(editarContrato);
    if (request.length > 0) {
      let result = await api.patch(`Contrato/AtualizaContratoPorId?id=${editarContrato.contratoId}`, request);
      return result;
    }
  }

  public async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);

    return result;
  }

  public async alterarContratoPorCampo(campo: string, valor: any, contratoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaContratoRequest>(`Contrato/AtualizaContratoPorId?id=${contratoId}`, request);
    }
  }

  public async alterarContratoPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      let result = await api.patch<IAdicionaContratoRequest>(`Contrato/AtualizaXCampoValor?id=${xcampoId}`, request);
      return result;
    }
  }

  public async alterarContratoPorCampoBolleano(campo: string, valor: any, contratoId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaContratoRequest>(`Contrato/AtualizaContratoPorId?id=${contratoId}`, request);
  }

  public async alterarContratoPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaContratoRequest>(`Contrato/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  public async alterarContratoPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], contratoId: number) {

    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        const request: IUpdatePartesList = {
          contratoId: contratoId,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<IAdicionaContratoRequest>("Contrato/AtualizaPartesContratoPorId", request);
      }
    }
  }

  public async alterarContratoPorEmpresaList(empresasList: IEmpresaContrato[], contratoId: number) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        const request: IUpdatePartesList = {
          contratoId: contratoId,
          empresasList: empresasRequestList,
        };
        await api.put<IAdicionaContratoRequest>("Contrato/AtualizaPartesContratoPorId", request);
      }
    }
  }

  public async alterarContratoPorParteContrariaList(partesContrariasList: IParteContraria[], contratoId: number) {
    const partesContrariasRequestList: any[] = [];
    partesContrariasList.forEach((parteContraria) => {
      let requestParteContraria: any = {};
      // eslint-disable-next-line no-self-assign
      if (requestParteContraria?.xCampoValorList?.length > 0) requestParteContraria.xCampoValorList = requestParteContraria.xCampoValorList;
      if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
      if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
      if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
      if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
      if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
      if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
      if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
      if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
      if (parteContraria.email) requestParteContraria.email = parteContraria.email;
      if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
      if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
      if (parteContraria.papelSignatarioId) requestParteContraria.papelSignatarioId = parteContraria.papelSignatarioId;
      if (parteContraria.papelSignatarioNome) requestParteContraria.papelSignatarioNome = parteContraria.papelSignatarioNome;
      if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
      if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
      if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
      if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
      if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
      if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
      if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
      if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
      if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
      partesContrariasRequestList.push(requestParteContraria);
    });

    const request: IUpdatePartesList = {
      contratoId: contratoId,
      partesContrariasList: partesContrariasRequestList,
    }
    await api.put<IAdicionaContratoRequest>("Contrato/AtualizaPartesContratoPorId", request);

  }

  public async obterAdvogadosInterno(contratoId: number): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/Contrato/RecuperaAdvogadoInternoPorId?contratoId=${contratoId}`);

    return result;
  }

  public async adicionaFavorido(contratoId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.Contrato,
      contratoId: contratoId,
    });
    return result;
  }

  public async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  public async obterContratoPorId(contratoId: number): Promise<IRecuperaLista<IContrato>> {
    let { data: result } = await api.get<IRecuperaLista<IContrato>>(`/Contrato/RecuperaListagemContrato?&contratoId=${contratoId}`);
    return result;
  }

  public async obterLinksMinutaContratoPorId(contratoId: number): Promise<IRecuperaLista<IContratoDocumentos>> {
    let { data: result } = await api.get<IRecuperaLista<IContratoDocumentos>>(`/Contrato/RecuperaMinutaContratoPorId?&contratoId=${contratoId}`);
    return result;
  }

  public async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`/Favorito/RecuperaFavorito`);

    return result;
  }

  public async obterCapaContratoPorSeparador(contratoId: number, separadorId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Contrato/RecuperaCapaContrato?contratoId=${contratoId}&separadorId=${separadorId}`);

    return result;
  }

  public async obterCapaContratoPorId(contratoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Contrato/RecuperaCapaContrato?contratoId=${contratoId}`);

    return result;
  }

  public async obterMinutaPorContrato(contratoId: number): Promise<IRecuperaDocumentoContrato> {
    const { data: result } = await api.get<IRecuperaDocumentoContrato>('Contrato/RecuperaLinkMinutaPorId', {
      params: {
        contratoId
      }
    })

    return result
  }

  public async gerarExcel(filtros: FiltroPesquisaContratoRequest): Promise<Blob> {

    let query = `sort=${filtros.sort}&modeloRelatorioId=${filtros.modeloRelatorioId}`;

    query = this.montarTextos(filtros, query);

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    const { data: result } = await api.get(`Contrato/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

    return result;
  }

  public async gerarExcelv2(filtros: FiltroPesquisaContratoRequest): Promise<Blob> {

    let query = `sort=${filtros.sort}&modeloRelatorioId=${filtros.modeloRelatorioId}`;

    query = this.montarTextosv2(filtros, query);

    query = this.montarListasv2(filtros, query);

    query = this.montarDatasv2(filtros, query);

    const { data: result } = await api.get(`Contrato/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

    return result;
  }

  // Métodos privados
  private montarListasv2 = (filtros: any, query: string): string => {
    if (filtros?.statuscontrato?.length > 0) filtros?.statuscontrato.forEach((option: IOption) => (query += `&statuscontrato=${option.value}`));
    if (filtros?.statuspasta?.length > 0) filtros?.statuspasta.forEach((option: IOption) => (query += `&statuspasta=${option.value}`));
    if (filtros?.tipocontratoId?.length > 0) filtros?.tipocontratoId.forEach((option: IOption) => (query += `&tipocontratoId=${option.value}`));
    if (filtros?.periodicIdadeId?.length > 0) filtros?.periodicIdadeId.forEach((option: IOption) => (query += `&periodicIdadeId=${option.value}`));
    if (filtros?.departamentoId?.length > 0) filtros?.departamentoId.forEach((option: IOption) => (query += `&departamentoId=${option.value}`));
    if (filtros?.gestorId?.length > 0) filtros?.gestorId.forEach((option: IOption) => (query += `&gestorId=${option.value}`));
    if (filtros?.usuarioidcadastro?.length > 0) filtros?.usuarioidcadastro.forEach((option: IOption) => (query += `&usuarioIdcadastro=${option.value}`));
    if (filtros?.moedaId?.length > 0) filtros?.moedaId.forEach((option: IOption) => (query += `&moedaId=${option.value}`));
    if (filtros?.indiceId?.length > 0) filtros?.indiceId.forEach((option: IOption) => (query += `&indiceId=${option.value}`));
    if (filtros?.urgentefilter?.length == 1) filtros?.urgentefilter.forEach((option: IOption) => (query += `&urgente=${option.value === 1 ? "true" : "false"}`));

    return query;
  };

  private montarXCampov2 = (xCampoValores: any[]): string => {
    let query = '';

    xCampoValores.forEach((item: any, index: number) => {
      query += `&XCampoValores[${index}].campoId=${item.campoId}`;
      switch (item.campoTipo) {
        case ETipoCampoEnum.Texto:
          query += `&XCampoValores[${index}].texto=${encodeURIComponent(item.texto)}`;
          break;
        case ETipoCampoEnum.Inteiro:
          query += `&XCampoValores[${index}].inteiro=${item.inteiro}`;
          break;
        case ETipoCampoEnum.Percentual:
          query += `&XCampoValores[${index}].valor=${item.valor}`;
          break;
        case ETipoCampoEnum.Boolean:
          query += `&XCampoValores[${index}].boolean=${item.boolean ? true : false}`;
          break;
        case ETipoCampoEnum.Lista:
          query += `&XCampoValores[${index}].xCampoOpcaoId[${index}]=${item.xCampoOpcaoId}`;
          break;
      }
    });

    return query;
  };

  private montarDatasv2 = (filtros: any, query: string): string => {
    if (filtros?.datainiciovigenciamaiorigual && filtros?.datainiciovigenciamenorigual) {
      query += `&datainiciovigenciamaiorigual=${filtros?.datainiciovigenciamaiorigual}&datainiciovigenciamenorigual=${filtros?.datainiciovigenciamenorigual}`;
    }
    if (filtros?.datafimvigenciamaiorigual && filtros?.datafimvigenciamenorigual) {
      query += `&datafimvigenciamaiorigual=${filtros?.datafimvigenciamaiorigual}&datafimvigenciamenorigual=${filtros?.datafimvigenciamenorigual}`;
    }
    if (filtros?.datacadastromaiorigual && filtros?.datacadastromenorigual) {
      query += `&datacadastromaiorigual=${filtros?.datacadastromaiorigual}&datacadastromenorigual=${filtros?.datacadastromenorigual}`;
    }
    if (filtros?.dataaprovacaomaiorigual && filtros?.dataaprovacaomenorigual) {
      query += `&dataaprovacaomaiorigual=${filtros?.dataaprovacaomaiorigual}&dataaprovacaomenorigual=${filtros?.dataaprovacaomenorigual}`;
    }
    if (filtros?.dataassinaturamaiorigual && filtros?.dataassinaturamenorigual) {
      query += `&dataassinaturamaiorigual=${filtros?.dataassinaturamaiorigual}&dataassinaturamenorigual=${filtros?.dataassinaturamenorigual}`;
    }
    if (filtros?.datacanceladomaiorigual && filtros?.datacanceladomenorigual) {
      query += `&datacanceladomaiorigual=${filtros?.datacanceladomaiorigual}&datacanceladomenorigual=${filtros?.datacanceladomenorigual}`;
    }

    return query;
  };

  private montarTextosv2 = (filtros: any, query: string): string => {
    if (filtros?.contratoid > 0) {
      query += `&contratoid=${filtros?.contratoid}`;
    }

    if (filtros?.pastaid > 0) {
      query += `&pastaid=${filtros?.pastaid}`;
    }

    if (filtros?.objetocontrato?.length > 0) {
      query += `&objetocontrato=${filtros?.objetocontrato}`;
    }

    if (filtros?.partecontraria?.length > 0) {
      query += `&partecontraria=${filtros?.partecontraria}`;
    }

    if (filtros?.observacoescontrato?.length > 0) {
      query += `&observacoescontrato=${filtros?.observacoescontrato}`;
    }

    if (filtros?.codigo?.length > 0) {
      query += `&codigo=${filtros?.codigo}`;
    }

    if (filtros?.numerocontrato?.length > 0) {
      query += `&numerocontrato=${filtros?.numerocontrato}`;
    }

    if (filtros?.valortotalmaiorigual > 0 && filtros?.valortotalmenorigual > 0) {
      if (filtros?.valortotalmaiorigual >= filtros?.valortotalmenorigual) {
        query += `&valortotalmenorigual=${filtros?.valortotalmenorigual / 100}`;
        query += `&valortotalmaiorigual=${filtros?.valortotalmaiorigual / 100}`;
      }
    }

    return query;
  };

  private montarListas = (filtros: FiltroPesquisaContratoRequest, query: string) => {
    if (filtros.statusContrato.length > 0) filtros.statusContrato.forEach((id) => (query += `&statusContrato=${id}`));
    if (filtros.statusPasta.length > 0) filtros.statusPasta.forEach((id) => (query += `&statusPasta=${id}`));
    if (filtros.tipoContratoId.length > 0) filtros.tipoContratoId.forEach((id) => (query += `&tipoContratoId=${id}`));
    if (filtros.periodicidadeId.length > 0) filtros.periodicidadeId.forEach((id) => (query += `&periodicidadeId=${id}`));
    if (filtros.departamentoId.length > 0) filtros.departamentoId.forEach((id) => (query += `&departamentoId=${id}`));
    if (filtros.gestorId.length > 0) filtros.gestorId.forEach((id) => (query += `&gestorId=${id}`));
    if (filtros.usuarioIdCadastro.length > 0) filtros.usuarioIdCadastro.forEach((id) => (query += `&usuarioIdCadastro=${id}`));
    if (filtros.moedaId.length > 0) filtros.moedaId.forEach((id) => (query += `&moedaId=${id}`));
    if (filtros.indiceId.length > 0) filtros.indiceId.forEach((id) => (query += `&indiceId=${id}`));
    if (filtros.urgenteFilter.length == 1) filtros.urgenteFilter.forEach((id) => (query += `&urgente=${id === 1 ? "true" : "false"}`));

    return query;
  };

  private montarDatas = (filtros: FiltroPesquisaContratoRequest, query: string) => {
    if (filtros.dataInicioVigenciaMaiorIgual && filtros.dataInicioVigenciaMenorIgual) {
      query += `&dataInicioVigenciaMaiorIgual=${filtros.dataInicioVigenciaMaiorIgual}&dataInicioVigenciaMenorIgual=${filtros.dataInicioVigenciaMenorIgual}`;
    }
    if (filtros.dataFimVigenciaMaiorIgual && filtros.dataFimVigenciaMenorIgual) {
      query += `&dataFimVigenciaMaiorIgual=${filtros.dataFimVigenciaMaiorIgual}&dataFimVigenciaMenorIgual=${filtros.dataFimVigenciaMenorIgual}`;
    }
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.dataAprovacaoMaiorIgual && filtros.dataAprovacaoMenorIgual) {
      query += `&dataAprovacaoMaiorIgual=${filtros.dataAprovacaoMaiorIgual}&dataAprovacaoMenorIgual=${filtros.dataAprovacaoMenorIgual}`;
    }
    if (filtros.dataAssinaturaMaiorIgual && filtros.dataAssinaturaMenorIgual) {
      query += `&dataAssinaturaMaiorIgual=${filtros.dataAssinaturaMaiorIgual}&dataAssinaturaMenorIgual=${filtros.dataAssinaturaMenorIgual}`;
    }
    if (filtros.dataCanceladoMaiorIgual && filtros.dataCanceladoMenorIgual) {
      query += `&dataCanceladoMaiorIgual=${filtros.dataCanceladoMaiorIgual}&dataCanceladoMenorIgual=${filtros.dataCanceladoMenorIgual}`;
    }

    return query;
  };

  private montarTextos = (filtros: FiltroPesquisaContratoRequest, query: string) => {
    if (filtros.contratoId > 0) {
      query += `&contratoId=${filtros.contratoId}`;
    }

    if (filtros.pastaId > 0) {
      query += `&pastaId=${filtros.pastaId}`;
    }

    if (filtros.objetoContrato.length > 0) {
      query += `&objetoContrato=${filtros.objetoContrato}`;
    }

    if (filtros.parteContraria.length > 0) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    if (filtros.observacoesContrato.length > 0) {
      query += `&observacoesContrato=${filtros.observacoesContrato}`;
    }

    if (filtros.codigo.length > 0) {
      query += `&codigo=${filtros.codigo}`;
    }

    if (filtros.numeroContrato.length > 0) {
      query += `&numeroContrato=${filtros.numeroContrato}`;
    }

    if (filtros.valorTotalMaiorIgual > 0 && filtros.valorTotalMenorIgual > 0) {
      if (filtros.valorTotalMaiorIgual >= filtros.valorTotalMenorIgual) {
        query += `&valorTotalMenorIgual=${filtros.valorTotalMenorIgual / 100}`;
        query += `&valorTotalMaiorIgual=${filtros.valorTotalMaiorIgual / 100}`;
      }
    }

    return query;
  };

  private montarObjetoRequest2 = (contrato: IAdicionaContratoRequest, centroCusto: any[]) => {

    let request: any = {};

    // XCampos
    if (contrato.xCampoValorList.length > 0) request.xCampoValorList = contrato.xCampoValorList;

    // Campos texto
    if (contrato.nomepasta) request.nomePasta = contrato.nomepasta;
    if (contrato.objetocontrato) request.objetoContrato = contrato.objetocontrato;
    if (contrato.observacoescontrato) request.observacoesContrato = contrato.observacoescontrato;
    if (contrato.codigo) request.codigo = contrato.codigo;
    if (contrato.agencia) request.agencia = contrato.agencia;
    if (contrato.conta) request.conta = contrato.conta;

    // Campos bool
    if (contrato.isnovapasta) request.isNovaPasta = true; else request.isNovaPasta = false;
    if (contrato.urgente) request.urgente = contrato.urgente;
    if (contrato.restrito) request.restrito = contrato.restrito;

    // Campos inteiro
    if (contrato.pastaid > 0) request.pastaId = contrato.pastaid;
    if (contrato.quantidadeperiodicidade > 0) request.quantidadePeriodicidade = contrato.quantidadeperiodicidade;

    // Campos valor
    if (contrato.valortotal) request.valorTotal = contrato.valortotal;

    // Campos data
    if (contrato.dataassinatura && moment(contrato.dataassinatura, 'YYYY-MM-DD', true).isValid()) request.dataAssinatura = contrato.dataassinatura;
    if (contrato.dataaprovacao && moment(contrato.dataaprovacao, 'YYYY-MM-DD', true).isValid()) request.dataAprovacao = contrato.dataaprovacao;
    if (contrato.datainiciovigencia && moment(contrato.datainiciovigencia, 'YYYY-MM-DD', true).isValid()) request.dataInicioVigencia = contrato.datainiciovigencia;
    if (contrato.datafimvigencia && moment(contrato.datafimvigencia, 'YYYY-MM-DD', true).isValid()) request.dataFimVigencia = contrato.datafimvigencia;


    // Campos lista
    if (contrato.periodicidadeId) request.periodicidadeId = contrato.periodicidadeId;
    if (contrato.departamentoId) request.departamentoId = contrato.departamentoId;
    if (contrato.gestorId) request.gestorId = contrato.gestorId;
    if (contrato.moedaId) request.moedaId = contrato.moedaId;
    if (contrato.indiceId) request.indiceId = contrato.indiceId;
    if (contrato.bancoId) request.bancoId = contrato.bancoId;
    if (contrato.tipoContratoId) request.tipoContratoId = contrato.tipoContratoId;
    if (contrato.cidadeId) request.cidadeId = contrato.cidadeId;
    if (contrato.tipoVigenciaId) request.tipoVigenciaId = contrato.tipoVigenciaId;
    if (contrato.tipominutaId) request.tipoMinutaId = contrato.tipominutaId;
    if (contrato.quantidadeperiodicidade) request.quantidadePeriodicidade = contrato.quantidadeperiodicidade;

    // Campos Pessoa
    if (contrato.advogadoInternoList.filter((e) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      contrato.advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (contrato.empresasList.filter((e) => e.checked).length > 0) {
      request.empresasList = [];
      contrato.empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          request.empresasList.push(empresa);
        });
    }

    if (centroCusto.filter((e: any) => e.checked).length > 0) {
      request.centrosCusto = [];
      centroCusto
        .filter((e) => e.checked)
        .forEach((item) => {
          let centroCusto: any = {
            tipoCentroCustoId: item.tipoCentroCustoId,
            percentual: item.percentual,
          };
          request.centrosCusto.push(centroCusto);
        });
    }

    if (contrato.partesContrariasList.length > 0) {
      request.partesContrariasList = [];
      contrato.partesContrariasList.forEach((parteContraria) => {
        let requestParteContraria: any = {};
        if (parteContraria?.xCampoValorList?.length > 0) requestParteContraria.xCampoValorList = parteContraria.xCampoValorList;
        if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
        if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
        if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
        if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
        if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
        if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
        if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
        if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
        if (parteContraria.email) requestParteContraria.email = parteContraria.email;
        if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
        if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
        if (parteContraria.papelSignatarioId) requestParteContraria.papelSignatarioId = parteContraria.papelSignatarioId;
        if (parteContraria.papelSignatarioNome) requestParteContraria.papelSignatarioNome = parteContraria.papelSignatarioNome;
        if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
        if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
        if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
        if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
        if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
        if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
        if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
        if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
        if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
        if (parteContraria.rg) requestParteContraria.rg = parteContraria.rg;
        if (parteContraria.rgOrgao) requestParteContraria.rgOrgao = parteContraria.rgOrgao;
        if (parteContraria.estadoCivilId) requestParteContraria.estadoCivilId = parteContraria.estadoCivilId;
        if (parteContraria.nacionalidade) requestParteContraria.nacionalidade = parteContraria.nacionalidade;
        request.partesContrariasList.push(requestParteContraria);
      });
    }

    return request;
  };

  private montarObjetoRequest = (contrato: IAdicionaContratoRequest) => {

    let request: any = {};

    // XCampos
    if (contrato.xCampoValorList.length > 0) request.xCampoValorList = contrato.xCampoValorList;

    // Campos texto
    if (contrato.nomepasta) request.nomePasta = contrato.nomepasta;
    if (contrato.objetocontrato) request.objetoContrato = contrato.objetocontrato;
    if (contrato.observacoescontrato) request.observacoesContrato = contrato.observacoescontrato;
    if (contrato.codigo) request.codigo = contrato.codigo;
    if (contrato.agencia) request.agencia = contrato.agencia;
    if (contrato.conta) request.conta = contrato.conta;

    // Campos bool
    if (contrato.isnovapasta) request.isNovaPasta = true; else request.isNovaPasta = false;
    if (contrato.urgente) request.urgente = contrato.urgente;
    if (contrato.restrito) request.restrito = contrato.restrito;

    // Campos inteiro
    if (contrato.pastaid > 0) request.pastaId = contrato.pastaid;
    if (contrato.quantidadeperiodicidade > 0) request.quantidadePeriodicidade = contrato.quantidadeperiodicidade;

    // Campos valor
    if (contrato.valortotal) request.valorTotal = contrato.valortotal;

    // Campos data
    if (contrato.dataassinatura) request.dataAssinatura = contrato.dataassinatura;
    if (contrato.dataaprovacao) request.dataAprovacao = contrato.dataaprovacao;
    if (contrato.datainiciovigencia) request.dataInicioVigencia = contrato.datainiciovigencia;
    if (contrato.datafimvigencia) request.dataFimVigencia = contrato.datafimvigencia;

    // Campos lista
    if (contrato.periodicidadeId) request.periodicidadeId = contrato.periodicidadeId;
    if (contrato.departamentoId) request.departamentoId = contrato.departamentoId;
    if (contrato.gestorId) request.gestorId = contrato.gestorId;
    if (contrato.moedaId) request.moedaId = contrato.moedaId;
    if (contrato.indiceId) request.indiceId = contrato.indiceId;
    if (contrato.bancoId) request.bancoId = contrato.bancoId;
    if (contrato.tipoContratoId) request.tipoContratoId = contrato.tipoContratoId;
    if (contrato.cidadeId) request.cidadeId = contrato.cidadeId;
    if (contrato.tipoVigenciaId) request.tipovigenciaId = contrato.tipoVigenciaId;
    if (contrato.tipominutaId) request.tipoMinutaId = contrato.tipominutaId;
    if (contrato.quantidadeperiodicidade) request.quantidadePeriodicidade = contrato.quantidadeperiodicidade;

    // Campos Pessoa
    if (contrato.advogadoInternoList.filter((e) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      contrato.advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (contrato.empresasList.filter((e) => e.checked).length > 0) {
      request.empresasList = [];
      contrato.empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          request.empresasList.push(empresa);
        });
    }


    if (contrato.partesContrariasList.length > 0) {
      request.partesContrariasList = [];
      contrato.partesContrariasList.forEach((parteContraria) => {
        let requestParteContraria: any = {};
        if (parteContraria?.xCampoValorList?.length > 0) requestParteContraria.xCampoValorList = parteContraria.xCampoValorList;
        if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
        if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
        if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
        if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
        if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
        if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
        if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
        if (parteContraria.email) requestParteContraria.email = parteContraria.email;
        if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
        if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
        if (parteContraria.papelSignatarioId) requestParteContraria.papelSignatarioId = parteContraria.papelSignatarioId;
        if (parteContraria.papelSignatarioNome) requestParteContraria.papelSignatarioNome = parteContraria.papelSignatarioNome;
        if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
        if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
        if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
        if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
        if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
        if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
        if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
        if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
        if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
        if (parteContraria.rg) requestParteContraria.rg = parteContraria.rg;
        if (parteContraria.rgOrgao) requestParteContraria.rgOrgao = parteContraria.rgOrgao;
        if (parteContraria.estadoCivilId) requestParteContraria.estadoCivilId = parteContraria.estadoCivilId;
        if (parteContraria.nacionalidade) requestParteContraria.nacionalidade = parteContraria.nacionalidade;
        request.partesContrariasList.push(requestParteContraria);
      });
    }

    return request;
  };

  private montarObjetoArrayContrato = (contrato: IAdicionaContratoRequest) => {
    let request: any = [];

    // Campos Texto
    if (contrato.nomepasta) request.push({ op: "replace", path: "/nomepasta", value: contrato.nomepasta });
    if (contrato.objetocontrato) request.push({ op: "replace", path: "/objetocontrato", value: contrato.objetocontrato });
    if (contrato.observacoescontrato) request.push({ op: "replace", path: "/observacoescontrato", value: contrato.observacoescontrato });
    if (contrato.codigo) request.push({ op: "replace", path: "/codigo", value: contrato.codigo });
    if (contrato.emailusuarioexterno) request.push({ op: "replace", path: "/emailusuarioexterno", value: contrato.emailusuarioexterno });
    if (contrato.nomeusuarioexterno) request.push({ op: "replace", path: "/nomeusuarioexterno", value: contrato.nomeusuarioexterno });

    // Campos Boleano
    if (contrato.isnovapasta) request.push({ op: "replace", path: "/isnovapasta", value: contrato.isnovapasta });
    if (contrato.urgente) request.push({ op: "replace", path: "/urgente", value: contrato.urgente });
    if (contrato.restrito) request.push({ op: "replace", path: "/restrito", value: contrato.restrito });

    // Campos Valor
    if (contrato.valortotal) request.push({ op: "replace", path: "/valortotal", value: contrato.valortotal });

    // Campos Inteiro
    if (contrato.pastaid) request.push({ op: "replace", path: "/pastaid", value: contrato.pastaid });

    // Campos Data
    if (contrato.dataassinatura) request.push({ op: "replace", path: "/dataassinatura", value: contrato.dataassinatura });
    if (contrato.dataaprovacao) request.push({ op: "replace", path: "/dataaprovacao", value: contrato.dataaprovacao });
    if (contrato.datainiciovigencia) request.push({ op: "replace", path: "/datainiciovigencia", value: contrato.datainiciovigencia });
    if (contrato.datafimvigencia) request.push({ op: "replace", path: "/datafimvigencia", value: contrato.datafimvigencia });

    // Campos Lista
    if (contrato.tipoContratoId) request.push({ op: "replace", path: "/tipoContratoId", value: contrato.tipoContratoId });
    if (contrato.periodicidadeId) request.push({ op: "replace", path: "/periodicidadeId", value: contrato.periodicidadeId });
    if (contrato.departamentoId) request.push({ op: "replace", path: "/departamentoId", value: contrato.departamentoId });
    if (contrato.gestorId) request.push({ op: "replace", path: "/gestorId", value: contrato.gestorId });
    if (contrato.moedaId) request.push({ op: "replace", path: "/moedaId", value: contrato.moedaId });
    if (contrato.indiceId) request.push({ op: "replace", path: "/indiceId", value: contrato.indiceId });
    if (contrato.bancoId) request.push({ op: "replace", path: "/bancoId", value: contrato.bancoId });
    if (contrato.tipoVigenciaId) request.push({ op: "replace", path: "/tipoVigenciaId", value: contrato.tipoVigenciaId });

    return request;
  };
  public adicionarHistorico = async (contrato: IAdicionaContratoRequest, compromissoId: number) => {
    let request: any = [];

    // Campos Texto
    if (contrato.nomepasta) request.push({ label: "Nome da Pasta", value: contrato.nomepasta });
    if (contrato.objetocontrato) request.push({ label: "Objeto do Contrato", value: contrato.objetocontrato });
    if (contrato.observacoescontrato) request.push({ label: "Observações do Contrato", value: contrato.observacoescontrato });
    if (contrato.codigo) request.push({ label: "Código", value: contrato.codigo });
    if (contrato.emailusuarioexterno) request.push({ label: "Email do Usuário Externo", value: contrato.emailusuarioexterno });
    if (contrato.nomeusuarioexterno) request.push({ label: "Nome do Usuário Externo", value: contrato.nomeusuarioexterno });

    // Campos Boleano
    if (contrato.isnovapasta) request.push({ label: "Nova Pasta", value: "Sim" });
    if (contrato.urgente) request.push({ label: "Urgente", value: "Sim" });
    if (contrato.restrito) request.push({ label: "Restrito", value: "Sim" });

    // Campos Valor
    if (contrato.valortotal) request.push({ label: "Valor Total", value: contrato.valortotal });

    // Campos Inteiro
    if (contrato.pastaid) request.push({ label: "ID da Pasta", value: contrato.pastaid });
    if (contrato.pastaNome) request.push({ label: "Nome da Pasta", value: contrato.pastaNome });

    // Campos Data (formatação com Moment.js)
    if (contrato.dataassinatura) request.push({ label: "Data de Assinatura", value: moment(contrato.dataassinatura).format('DD/MM/YYYY') });
    if (contrato.dataaprovacao) request.push({ label: "Data de Aprovação", value: moment(contrato.dataaprovacao).format('DD/MM/YYYY') });
    if (contrato.datainiciovigencia) request.push({ label: "Data de Início de Vigência", value: moment(contrato.datainiciovigencia).format('DD/MM/YYYY') });
    if (contrato.datafimvigencia) request.push({ label: "Data de Fim de Vigência", value: moment(contrato.datafimvigencia).format('DD/MM/YYYY') });

    // Campos Lista com ID e Nome
    if (contrato.tipoContratoNome) request.push({ label: "Tipo de Contrato", value: contrato.tipoContratoNome });
    if (contrato.periodicidadeNome) request.push({ label: "Periodicidade", value: contrato.periodicidadeNome });
    if (contrato.departamentoNome) request.push({ label: "Departamento", value: contrato.departamentoNome });
    if (contrato.gestorNome) request.push({ label: "Gestor", value: contrato.gestorNome });
    if (contrato.moedaNome) request.push({ label: "Moeda", value: contrato.moedaNome });
    if (contrato.indiceNome) request.push({ label: "Índice", value: contrato.indiceNome });
    if (contrato.bancoNome) request.push({ label: "Banco", value: contrato.bancoNome });
    if (contrato.tipoVigenciaNome) request.push({ label: "Tipo de Vigência", value: contrato.tipoVigenciaNome });

    // Campos Parte Contraria - organizados no formato do grid
    contrato.partesContrariasList.forEach((parteContraria) => {
      let parteContrariaGridItem = {
        nome: parteContraria.nome,
        documento: parteContraria.documento,
        tipoDocumento: parteContraria.tipoDocumento,
        formaParticipacao: parteContraria.formaParticipacaoNome,
        principal: parteContraria.principal ? "Sim" : "Não",
      };
      request.push({ label: "Parte Contraria", value: [parteContrariaGridItem] });
    });

    // Campos Advogado Interno - organizados no formato do grid
    contrato.advogadoInternoList.filter((e) => e.checked).forEach((advogadoInterno) => {
      let advogadoInternoGridItem = {
        nome: advogadoInterno.nome,
        principal: advogadoInterno.principal ? "Sim" : "Não",
        codigo: advogadoInterno.codigo,
      };
      request.push({ label: "Advogado Interno", value: [advogadoInternoGridItem] });
    });

    // Campos Empresa - organizados no formato do grid
    contrato.empresasList.filter((e) => e.checked).forEach((empresa) => {
      let empresaGridItem = {
        empresaId: empresa.empresaId,
        nomeFantasia: empresa.nome,
        principal: empresa.principal ? "Sim" : "Não",
      };
      request.push({ label: "Empresa", value: [empresaGridItem] });
    });

    // Adiciona os campos customizados que podem variar em tipo
    contrato.xCampoValorList.forEach(async xCampo => {
      let valorCampo;
      switch (xCampo.campoTipo) {
        case ETipoCampoEnum.Texto:
        case ETipoCampoEnum.AreaTexto:
          valorCampo = xCampo.texto;
          break;
        case ETipoCampoEnum.Data:
          valorCampo = moment(xCampo.data).format('DD/MM/YYYY');
          break;
        case ETipoCampoEnum.Lista:
          valorCampo = xCampo.xCampoOpcaoNome;
          break;
        case ETipoCampoEnum.Valor:
          valorCampo = currencyLabelFormatter(xCampo.valor);
          break;
        case ETipoCampoEnum.Inteiro:
          valorCampo = xCampo.inteiro;
          break;
        default:
          valorCampo = "N/A";
          break;
      }
      request.push({ label: xCampo.label, value: valorCampo });
    });

    // Envio para o backend
    await api.post<any>(`HistoricoCompromisso/AdicionaHistoricoCompromisso`, { compromissoId, historicovalor: JSON.stringify(request) });

    return request;
};

  public concluirCompromisso = async (contrato: IAdicionaContratoRequest): Promise<any> => {

    let result: any[] = [];

    // Salvar campos normais
    await this.editarContrato(contrato);

    // Salvar campos Pessoas
    if (contrato.partesContrariasList?.length > 0) result.push(await this.alterarContratoPorParteContrariaList(contrato.partesContrariasList, contrato.contratoId));
    if (contrato.advogadoInternoList?.length > 0) result.push(await this.alterarContratoPorAdvogadoInternoList(contrato.advogadoInternoList, contrato.contratoId));
    if (contrato.empresasList?.length > 0) result.push(await this.alterarContratoPorEmpresaList(contrato.empresasList, contrato.contratoId));

    // Salvar xcampos
    if (contrato.xCampoValorList?.length > 0) {
      contrato.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, contratoId: contrato.contratoId, texto: xCampo.texto }));
            else result.push(await this.alterarContratoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, contratoId: contrato.contratoId, data: xCampo.data }));
            else result.push(await this.alterarContratoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, contratoId: contrato.contratoId, valor: xCampo.valor }));
            else result.push(await this.alterarContratoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, contratoId: contrato.contratoId, inteiro: xCampo.inteiro }));
            else result.push(await this.alterarContratoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) await result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, contratoId: contrato.contratoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
            else await result.push(await this.alterarContratoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Tabela:
            await CamposService.alterarXCampoValorTabelaPorColuna(xCampo.linhas, EModuloNomeEnum.Contrato, contrato.contratoId); //OK
            break;

          default:
            break;
        }
      });
    }

    return result;
  };
}




export default new ContratoService();