import FiltroPesquisaEmpresaRequest from "../../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";


import "react-datepicker/dist/react-datepicker.css";

interface IEmpresaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  setPesquisar: (pesquisar: boolean) => void;
  filtrosPesquisa: FiltroPesquisaEmpresaRequest;
  setFiltrosPesquisa: (filtro: any) => any;
}

const SocietarioFiltroDatasModal = ({ exibirModal, filtrosPesquisa, setFiltrosPesquisa }: IEmpresaModalProps) => {
  return (
    <>
     { exibirModal && (
      <>
        <div className="row mt-12 mt-3">
          <div className="col-6">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Data de Cadastro:
            </label>                                
            <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  
                  value={
                    filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                      ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue: any) => {
                        return {
                          ...oldValue,
                          dataCadastroMaiorIgual: "",
                          dataCadastroMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />             
          </div>              

          <div className="col-6">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Data Constituição:
            </label>
            <DateRangePicker
                  className="form-control p-0"
                  calendarIcon={null}
                  showLeadingZeros={true}
                  
                  value={
                    filtrosPesquisa.dataConstituicaoMaiorIgual && filtrosPesquisa.dataConstituicaoMenorIgual
                      ? [filtrosPesquisa.dataConstituicaoMaiorIgual, filtrosPesquisa.dataConstituicaoMenorIgual]
                      : ""
                  }
                  onChange={(datas: Date[]) => {
                    if (!datas) {
                      setFiltrosPesquisa((oldValue: any) => {
                        return {
                          ...oldValue,
                          dataConstituicaoMaiorIgual: "",
                          dataConstituicaoMenorIgual: "",
                        };
                      });
                      return;
                    }

                    let data_inicial = datas[0];
                    let data_final = datas[1];
                    if (data_final) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataConstituicaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                        dataConstituicaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                      };
                    });
                  }}
                />                 
          </div>
        </div>

        <div className="row mt-12 mt-3">
          <div className="col-6">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Data do Primeiro registro:
            </label>
            <DateRangePicker
              className="form-control p-0"
              calendarIcon={null}
              showLeadingZeros={true}
              
              value={
                filtrosPesquisa.dataPrimeiroRegistroMenorIgual && filtrosPesquisa.dataPrimeiroRegistroMaiorIgual
                  ? [filtrosPesquisa.dataPrimeiroRegistroMenorIgual, filtrosPesquisa.dataPrimeiroRegistroMaiorIgual]
                  : ""
              }
              onChange={(datas: Date[]) => {
                if (!datas) {
                  setFiltrosPesquisa((oldValue: any) => {
                    return {
                      ...oldValue,
                      dataPrimeiroRegistroMenorIgual: "",
                      dataPrimeiroRegistroMaiorIgual: "",
                    };
                  });
                  return;
                }

                let data_inicial = datas[0];
                let data_final = datas[1];
                if (data_final) {
                  data_final.setHours(0, 0, 0, 0);
                  data_final = new Date(data_final.getTime() - 1);
                }
                setFiltrosPesquisa((oldValue: any) => {
                  return {
                    ...oldValue,
                    dataPrimeiroRegistroMenorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                    dataPrimeiroRegistroMaiorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                  };
                });
              }}
            />
          </div>            

          <div className="col-6">
            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
              Data Situação:
            </label>
            <DateRangePicker
              className="form-control p-0"
              calendarIcon={null}
              showLeadingZeros={true}
              
              value={
                filtrosPesquisa.dataSituacaoMenorIgual && filtrosPesquisa.dataSituacaoMaiorIgual
                  ? [filtrosPesquisa.dataSituacaoMenorIgual, filtrosPesquisa.dataSituacaoMaiorIgual]
                  : ""
              }
              onChange={(datas: Date[]) => {
                if (!datas) {
                  setFiltrosPesquisa((oldValue: any) => {
                    return {
                      ...oldValue,
                      dataSituacaoMenorIgual: "",
                      dataSituacaoMaiorIgual: "",
                    };
                  });
                  return;
                }

                let data_inicial = datas[0];
                let data_final = datas[1];
                if (data_final) {
                  data_final.setHours(0, 0, 0, 0);
                  data_final = new Date(data_final.getTime() - 1);
                }
                setFiltrosPesquisa((oldValue: any) => {
                  return {
                    ...oldValue,
                    dataSituacaoMenorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                    dataSituacaoMaiorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                  };
                });
              }}
            />               
          </div>              
        </div>
      </>
     )}    
    </>
  );
};

export default SocietarioFiltroDatasModal;
