import React from 'react';
import './styles/Dashboard.scss';
import { GeradorWidget, ModeloWidget } from './utils/GeradorWidget';
import { IWidget } from './widgets';
import { EstatisticasContexto } from './statistics';
import { DashboardContextoProps, DashboardContextoProvider } from './DashboardContext';
import { createRef } from 'react';
import { RefObject } from 'react';
import IDashboardWidget from '../../interfaces/IDashboardWidget';
import { IWidgetsTamanhos } from './widgets/interfaces/IWidget';
import { FaPen, FaSave, FaTrash } from 'react-icons/fa';
import { DragAndDropContextoProvider } from './context/DragAndDrop';


export interface DashboardProps extends React.PropsWithRef<any>{
    widgets: ModeloWidget[];
    estatico?: boolean; 
    carregando?: boolean;
    dashboadID: number;
    widgetOrdens: string[];
    widgetTamanhos: IWidgetsTamanhos[];
    onDashboardChanged: () => any;
    salvar: (widgets: IDashboardWidget[]) => any;
}

interface  DashboardState{  
    widgets: IWidget[];
    erro: any|undefined;
    carregando: boolean;
    modelos: ModeloWidget[];
    editando: boolean;
    widgetOrdens: string[];
    widgetTamanhos: IWidgetsTamanhos[];
    contexto: DashboardContextoProps;

}


const natural = (i:number) => Math.max(0, i);

export default class Dashboard<T> extends React.Component<DashboardProps, DashboardState>{
        
    static contextType = EstatisticasContexto;
    context: React.ContextType<typeof EstatisticasContexto>
    innerRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    constructor(props: DashboardProps, context: React.ContextType<typeof EstatisticasContexto>) {
        super(props);
        this.context = context;

        if(props.ref){
            props.ref(this);
        }

        this.state = {
            erro: undefined,
            carregando: false,
            widgets: [],
            modelos: props.widgets,
            editando: false,
            widgetOrdens: props.widgetOrdens,
            widgetTamanhos: props.widgetTamanhos,
            contexto: {
                instancia: this,
                editando: false
            }
        }

        if(this.context){
            this.context.onAtualizando = () => {
                this.setState({
                    erro: undefined,
                    carregando: true
            });
            }
        }

        if(this.context){
            this.context.onAtualizado = () => {
                this.setState({
                    erro: undefined,
                    carregando: false
                });
                this.atualizaWidgets();
            }
        }

        if(this.context){
            this.context.onFalhaAoAtualizar = (e: any) => {
                this.setState({
                    erro: e,
                    carregando: false
                });
            }
        }
        this.salvar = this.salvar.bind(this);
    }

    ativarWidget(titulo: string, posicao: number){
        this.setState({
            widgetOrdens: [
                ...this.state.widgetOrdens.slice(0, posicao),
                titulo,
                ...this.state.widgetOrdens.slice(posicao)
            ]
        });
    }

    desativaresWidget(titulo: string){
        this.setState({
            widgetOrdens: this.state.widgetOrdens.filter(ordem => ordem !== titulo)
        });
    }

    getWidgetIndex(titulo: string){
        return this.state.widgetOrdens.map(
            s=>s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ).indexOf(
            titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        );
    }

    moverWidget(titulo: string, posicao: number){
        const index = this.getWidgetIndex(titulo);
        if(index === posicao || index < 0) return;

        const ordems = [
            ...this.state.widgetOrdens.slice(0, index),
            ...this.state.widgetOrdens.slice(index + 1)
        ];

        const tamanhos = [
            ...this.state.widgetTamanhos.slice(0, index),
            ...this.state.widgetTamanhos.slice(index + 1)
        ];

        ordems.splice(posicao, 0, titulo);
        tamanhos.splice(posicao, 0, this.state.widgetTamanhos[index]);

        this.setState({
            widgetOrdens: ordems,
            widgetTamanhos: tamanhos,
        });
        

    }

    subirWidget(titulo: string){
        
        const index = this.getWidgetIndex(titulo);
        if(index === 0) return;
        
        const ordems = [
            ...this.state.widgetOrdens.slice(natural(0), natural(index -1)),
            this.state.widgetOrdens[index],
            this.state.widgetOrdens[index-1],
            ...this.state.widgetOrdens.slice(index + 1)

        ];
        
        const tamanhos = [
            ...this.state.widgetTamanhos.slice(natural(0), natural(index -1)),
            this.state.widgetTamanhos[index],
            this.state.widgetTamanhos[index-1],
            ...this.state.widgetTamanhos.slice(index + 1)
        ];

        this.setState({
            widgetOrdens: ordems,
            widgetTamanhos:tamanhos,
        });
        
    }
    
    descerWidget(titulo: string){
        const index = this.getWidgetIndex(titulo);
        if(index < 0 || index === this.state.widgetOrdens.length - 1) return;
    

        const ordems = [
            ...this.state.widgetOrdens.slice(natural(0), natural(index)),
            this.state.widgetOrdens[index+1],
            this.state.widgetOrdens[index],
            ...this.state.widgetOrdens.slice(index + 2)
        ];

        const tamanhos = [
            ...this.state.widgetTamanhos.slice(natural(0), natural(index)),
            this.state.widgetTamanhos[index+1],
            this.state.widgetTamanhos[index],
            ...this.state.widgetTamanhos.slice(index + 2)
        ];

        this.setState({
            widgetOrdens: ordems,
            widgetTamanhos: tamanhos,
        });
        
    }
    
    public get width(){
        if(!this.innerRef.current) return 0;
        return this.innerRef.current.getBoundingClientRect().width;
    }

    atualizaWidgets() {
        if(!this.context)return;
        const modelos: ModeloWidget[] = [];

        for(const titulo of this.state.widgetOrdens){
            const modelo = this.state.modelos.find(w => w.props.titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") === titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
            if(!modelo) continue;
            modelo.props.desativado = false;
            modelos.push(modelo);
        }
        
        this.state.widgetTamanhos.forEach((tamanho, index) => {
            const widget = modelos[index];
            if(!widget?.props) return;
            widget.props.xs = tamanho?.xs || '12';
            widget.props.sm = tamanho?.sm || '12';
            widget.props.md = tamanho?.md || '6';
            widget.props.lg = tamanho?.lg || '6';
            widget.props.xl = tamanho?.xl || '3';
        })

        const widgets = GeradorWidget.gerarWidgets(modelos, this.context)
        this.setState({
            widgets: widgets
        });
    }

    onWidgetChanged(titulo:string, props: IWidgetsTamanhos){
        const index = this.state.widgetOrdens.findIndex(w => w === titulo);

        const tamanhos = [
            ...this.state.widgetTamanhos.map(t => ({...t})),
        ] as IWidgetsTamanhos[];

        if(index > -1){
            tamanhos[index].xs = props.xs;
            tamanhos[index].sm = props.sm;
            tamanhos[index].md = props.md;
            tamanhos[index].lg = props.lg;
            tamanhos[index].xl = props.xl;
        }

        this.setState({
            widgetTamanhos: tamanhos
        });
    }

    componentDidUpdate(prevProps: DashboardProps, prevState: DashboardState) {
        let atualizar = false;

        if (prevProps.widgetTamanhos !== this.props.widgetTamanhos || prevProps.widgetOrdens !== this.props.widgetOrdens) {
            atualizar = true;
            this.setState({
                widgetOrdens: this.props.widgetOrdens,
                widgetTamanhos: this.props.widgetTamanhos
            });
        }

        if (prevState.widgetTamanhos !== this.state.widgetTamanhos || prevState.widgetOrdens !== this.state.widgetOrdens) {
            atualizar = true;
            this.setState({
                widgetOrdens: this.state.widgetOrdens,
                widgetTamanhos: this.state.widgetTamanhos
            });
        }

        if (atualizar) {
            this.atualizaWidgets();
        }
    }

    get mudou(){
        let mudou = false

        this.state.widgetTamanhos.forEach((tamanho:IWidgetsTamanhos, index) => {
                if(this.props.widgetTamanhos[index].xs !== tamanho.xs){
                    mudou = true;
                }
                if(this.props.widgetTamanhos[index].sm !== tamanho.sm){
                    mudou = true;
                }
                if(this.props.widgetTamanhos[index].md !== tamanho.md){
                    mudou = true;
                }
                if(this.props.widgetTamanhos[index].lg !== tamanho.lg){
                    mudou = true;
                }
                if(this.props.widgetTamanhos[index].xl !== tamanho.xl){
                    mudou = true;
                }

        });

        this.state.widgetOrdens.forEach((nome:string, index) => {
            if(this.props.widgetOrdens[index] !== nome){
                mudou = true;
            }
        })

        return mudou;
    }

    async salvar(){
        if(!this.mudou){
            console.log('Não houve mudanças');
            this.setState({
                editando: false,
                contexto: {
                    instancia:this,
                    editando: false,
                }
            });
            return;
        }
        try{
            const widgets: IDashboardWidget[] = [];

            this.state.widgetOrdens.forEach((titulo, index)=>{
                if(widgets.find(w => w.nome === titulo)) return;
                widgets.push({
                    nome: titulo,
                    xs: this.state.widgetTamanhos[index].xs || '12',
                    sm: this.state.widgetTamanhos[index].sm || '12',
                    md: this.state.widgetTamanhos[index].md || '6',
                    lg: this.state.widgetTamanhos[index].lg || '6',
                    xl: this.state.widgetTamanhos[index].xl || '4',
                })
            });

            console.log(widgets);

            await this.props?.salvar(widgets);
            
            this.setState({
                editando: false,
                contexto: {
                    instancia:this,
                    editando: false,
                }
            });
        } catch(e){
            return;
        }

    }

    render(){
        return (
            <DashboardContextoProvider
                props={this.state.contexto}
            >
                {!this.props.carregando && this.state.widgets.length > 0 && !this.props.estatico &&
                <div className="col-12 mb-2 text-right">
                    <button
                        type='button'
                        className="btn btn-sm btn-orange me-2"
                        onClick={() =>{
                            this.setState({
                                editando: !this.state.editando,
                                widgetOrdens: this.props.widgetOrdens,
                                widgetTamanhos: this.props.widgetTamanhos,
                                contexto: {
                                    instancia: this,
                                    editando: !this.state.editando,
                                }
                            });
                        }}
                        title={!this.state.editando?"Editar":"Cancelar"}
                    >
                        {this.state.editando ? <FaTrash/> : <FaPen/>}
                    </button>
                    {this.state.editando &&
                        <button
                            type='button'
                            className="btn btn-sm btn-orange "
                            onClick={this.salvar}
                            title="Salvar"
                        ><FaSave/></button>
                    }
                </div>}
                <div className={`dashboard-conteiner${this.state.carregando || this.props.carregando?' carregando':this.state.editando?' editando':''}`} ref={this.innerRef}>
                    {!this.props.carregando && this.state.widgets.length === 0 && <div className='sem-widgets'>Nenhum widget para ser exibido.</div>}
                    <DragAndDropContextoProvider>
                        {this.state.widgets}
                    </DragAndDropContextoProvider>
                </div>
            </DashboardContextoProvider>
        );
    }
}