import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { faCog, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IEmpresa from "../../interfaces/IEmpresa";
import IAreaDireito from "../../interfaces/IAreaDireito";
import IXPayContrato from "../../interfaces/IXPayContrato";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import PaginationRequest from "../../interfaces/Requests/PaginationRequest";
import IXpayContratoAgrupadores from "../../interfaces/Requests/XPayContrato/IXpayContratoAgrupadores";

import EmpresaService from "../../services/EmpresaService";
import AreaDireitoService from "../../services/AreaDireitoService";
import XPayContratoService from "../../services/XPayContratoService";

interface IXPayContratoConfigSegurancaModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  xPayContrato?: IXPayContrato;
  titulo: string;
}

const XPayContratoAgrupadoresModal = ({ toggleModal, exibirModal, xPayContrato, titulo }: IXPayContratoConfigSegurancaModalProps) => {


  const [carregamentoAreaDireito, setCarregamentoAreaDireito] = useState<boolean>(false);
  const [areasDireitoOptions, setAreasDireitoOptions] = useState<any>([]);
  const [areaDireitoSelected, setAreaDireitoSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresaOptions, setEmpresaOptions] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);

  const [filtrosPesquisa] = useState<PaginationRequest>({
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: "empresaId",
  });

  const initialValues: IXpayContratoAgrupadores = {
    xPayContratoId: xPayContrato?.xpayContratoId || 0,
    empresaPagadoraId: 0,
    areasDireito: [],
    empresas: []
  };

  const XPayContratoValidacoes = Yup.object().shape({
    empresaPagadoraId: Yup.number().min(1, "Selecione uma empresa pagadora").required("Empresa pagadora é obrigatório"),
  });

  console.log(xPayContrato);
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: XPayContratoValidacoes,
    onSubmit: async (values, { setSubmitting }) => {
      try {

        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Configuração",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          await XPayContratoService.atualizaAgrupadores({ ...values });
        }

        setSubmitting(false);

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Agrupadores salvos com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        toggleModal(true);

      } catch (error: any) {
        let mensagemErro = "Ocorreu um erro inesperado";

        if (error?.response?.data?.Message) {
          mensagemErro = error.response.data.Message;
        }

        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar o XPayContrato`,
          text: mensagemErro,
          showConfirmButton: true,
        });
      }
    },
  });

  useEffect(() => {

    if (exibirModal) {

      carregarAreaDireito();

      carregarEmpresa();

      formik.setValues(initialValues);
    }

  }, [exibirModal]);

  const carregarAreaDireito = async () => {
    try {
      setCarregamentoAreaDireito(true);
      let resultado: IRecuperaLista<IAreaDireito>;
      resultado = await AreaDireitoService.obterListaLimitada();
      setAreasDireitoOptions(returnOptionsAreasDireito(resultado.data));
    } catch (error: any) {
      setAreasDireitoOptions([]);
    } finally {
      setCarregamentoAreaDireito(false);
    }
  }

  const returnOptionsAreasDireito = (array: any) => {
    let lista: any[] = [];
    array.forEach((item: any) => {
      lista.push({
        value: item?.areaDireitoId || 0,
        label: item.nome,
      });
    });
    return lista;
  };

  const carregarEmpresa = async () => {
    try {
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({ ...filtrosPesquisa, status: 1 });
      setEmpresaOptions(returnOptionsEmpresa(resultado.data));
      setEmpresas(resultado.data);
    } catch (error: any) {
      setEmpresaOptions([]);
      setEmpresas([]);
    } finally {
      setCarregamentoEmpresa(false);
    }
  }

  const returnOptionsEmpresa = (array: any) => {
    let lista: any[] = [];
    array.forEach((item: any) => {
      lista.push({
        value: item?.empresaId || 0,
        label: item.nomeFantasia,
      });
    });
    return lista;
  };

  const setListaEmpresas = (opcoes: any) => {
    let lista: any[] = [];
    opcoes.forEach((opcao: any) => {
      lista.push(opcao.value);
    });
    formik.setFieldValue("empresas", lista);
  }

  const setListaAreasDireito = (opcoes: any) => {
    let lista: any[] = [];
    opcoes.forEach((opcao: any) => {
      lista.push(opcao.value);
    });
    formik.setFieldValue("areasDireito", lista);
  }

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}  <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faCog}
            /></h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form>
              <div className="row mt-1">

                <div className="col-md-4 mt-2">
                  <label htmlFor="form-empresaPagadoraId" className="form-label required fw-bolder text-orange">
                    Empresa Pagadora:
                  </label>

                  <select
                    {...formik.getFieldProps("empresaPagadoraId")}
                    value={formik.values.empresaPagadoraId}
                    onChange={(e) => {
                      formik.setFieldTouched("empresaPagadoraId", true);
                      formik.setFieldValue("empresaPagadoraId", parseInt(e.target.value));
                    }}
                    className={clsx(
                      "form-select",
                      {
                        "is-invalid": formik.touched.empresaPagadoraId && formik.errors.empresaPagadoraId,
                      },
                      {
                        "is-valid": formik.touched.empresaPagadoraId && !formik.errors.empresaPagadoraId,
                      }
                    )}
                    id="form-empresaPagadoraId"
                  >
                    <option value="0"> Selecione uma opção</option>
                    {empresas.map((empresa) => {
                      return (
                        <option key={empresa.empresaId} value={empresa.empresaId}>
                          {" "}
                          {empresa.nomeFantasia}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-4 mt-2">
                  <div className="col-md-12">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Empresas:
                    </label>
                    <MultiSelect
                      isLoading={false}
                      disabled={false}
                      options={empresaOptions}
                      value={empresaSelected}
                      onChange={(event: any) => {
                        setEmpresaSelected(event);
                        setListaEmpresas(event);
                      }}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-2">
                  <div className="col-md-12">
                    <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Áreas do Direito:
                    </label>
                    <MultiSelect
                      isLoading={false}
                      disabled={false}
                      options={areasDireitoOptions}
                      value={areaDireitoSelected}
                      onChange={(event: any) => {
                        setAreaDireitoSelected(event);
                        setListaAreasDireito(event);
                      }}
                      labelledBy={"Selecione..."}
                      overrideStrings={{
                        selectSomeItems: "Selecione...",
                        allItemsAreSelected: "Todos selecionados",
                        selectAll: "Selecione todos",
                        search: "Pesquise",
                        selectAllFiltered: "Selecione todos (filtrados)"
                      }}
                    />
                  </div>
                </div>

              </div>
            </form>

          </div>
          <div className="modal-footer" style={{ margin: '0 auto' }}>
            <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-orange me-5">
              {!formik.isSubmitting && <> SALVAR </>}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default XPayContratoAgrupadoresModal;