export enum EModulo {
    Default = 0,
    AreaDireito = 1,
    Societario = 5,
    Contrato = 7,
    Consultivo = 9,
    Imovel = 10,
    MarcaEPertences = 11,
    Despesa = 12,
    AtoSocietario = 13,
    Bloqueio = 15,
    Compromisso = 16,
    Decisao = 17,
    Desdobramento = 18,
    Fechamento = 20,
    Procuracao = 21,
    Garantia = 22,
    Obrigacao = 23,
    ParteContraria = 40,
    PropostaAcordo = 25,
    Publicacao = 26,
    Valores = 27,
    Log = 28,
    PesquisaGlobal = 29,
    Regulatorio = 30,
    Relatorio = 31,
    Email = 32,
    EditorDocumento = 33,
    XpayHonorario = 34,
    XpayReembolso = 35,
    XpayFechamento = 36,
    XpayContrato = 37,
    Audiencia = 38,
    Andamento = 14,
    XFinderDistribuicao = 42,
    Protocolo = 46,
    Comunicacao = 47,
}