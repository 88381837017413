/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from "react";
import { faEdit, faPaperPlane, faTags, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { Card, Form, Modal, Popover } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import clsx from "clsx";

import { ETIQUETAS_STATICA } from "./EtiquetasStatic";
import ITipoDocumento from "../../interfaces/ITipoDocumento";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { IEtiqueta } from "../../interfaces/IEtiquetas";
import IPastaGed from "../../interfaces/IPastaGed";

import DocumentoService from "../../services/DocumentoService";
import PastaGedService from "../../services/PastaGedService";
import TipoDocumentoService from "../../services/TipoDocumentoService";
import EtiquetaService from "../../services/EtiquetaService";

interface ICriarGedModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  titulo: string;
}

const CriarGedModal = ({ toggleModal, exibirModal, titulo }: ICriarGedModalProps) => {
  const novoGedValidacao = Yup.object().shape({
    arquivo: Yup.mixed().required("Arquivo obritagório."),

    descricao: Yup.string().required("Descrição obritagória.").min(5, "Descrição deve ter no mínimo 5 caracteres."),

    tipoDocumentoId: Yup.number().required("Tipo de Documento obritagório.").min(1, "Campo tipo de documento é obrigatório"),

    pastaGedId: Yup.number().required("Campo pasta é obrigatório").min(1, "Campo pasta é obrigatório"),
  });

  const [carregandoPastaGed, setCarregandoPastaGed] = useState(false);
  const [pastaGeds, setPastaGeds] = useState<IPastaGed[]>([]);

  const [carregandoTipoDocumento, setCarregandoTipoDocumento] = useState(false);
  const [tiposDocumentos, setTiposDocumentos] = useState<ITipoDocumento[]>([]);

  const [adicionarEtiqueta, setAdicionarEtiqueta] = useState(false);
  const [etiquetas, setEtiquetas] = useState<IEtiqueta[]>([]);

  async function carregarPastaGed() {
    try {
      setCarregandoPastaGed(true);
      let resultado: IRecuperaLista<IPastaGed>;

      resultado = await PastaGedService.obterPastaGeds({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setPastaGeds(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setPastaGeds([]);
    } finally {
      setCarregandoPastaGed(false);
    }
  }

  async function carregarTipoDocument0() {
    try {
      setCarregandoTipoDocumento(true);
      let resultado: IRecuperaLista<ITipoDocumento>;

      resultado = await TipoDocumentoService.obterTipoDocumentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTiposDocumentos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTiposDocumentos([]);
    } finally {
      setCarregandoTipoDocumento(false);
    }
  }

  useEffect(() => {
    carregarPastaGed();
    carregarTipoDocument0();
    limparTodasEtiquetaModal();
  }, []);

  const etiquetasStaticas = useFormik({
    initialValues: ETIQUETAS_STATICA,
    onSubmit: async ({}) => {},
  });

  const limparTodasEtiquetaModal = () => {
    etiquetasStaticas.values.forEach(async (etiqueta: IEtiqueta) => (etiqueta.checked = false));
    etiquetasStaticas.setValues(etiquetasStaticas.values);
  };

  const formikGed = useFormik({
    initialValues: { arquivo: "", codigo: "", descricao: "", modulo: 0, tipoDocumentoId: 0, restrito: false, pastaGedId: 0 },
    validationSchema: novoGedValidacao,
    onSubmit: async (values, { setSubmitting }) => {
      const campo = document.querySelector("#form-arquivo");

      if (!values.arquivo || !campo) return;

      const arquivos: FileList | null = (campo as HTMLInputElement).files;

      if (!arquivos || arquivos.length === 0) return;

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Enviando arquivos...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      Swal.showLoading();

      for (let i = 0; i < arquivos.length; i++) {
        const dados = {
          Modulo: 6,
          Origem: "12",
          File: arquivos[i],
          TipoDocumentoId: values.tipoDocumentoId,
          Descricao: values.descricao,
          Restrito: values.restrito.toString(),
          Codigo: values.codigo,
          PastaGedId: values.pastaGedId,
        };

        try {
          let resultado: any;
          resultado = await DocumentoService.upload(dados);
          etiquetas.forEach((item) => (item.documentoId = resultado.id));
          await EtiquetaService.adicionaEtiquetas(etiquetas);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: `Documento cadastrado com sucesso`,
            showConfirmButton: false,
            timer: 3000,
          });

          toggleModal();
        } catch (error: any) {
          setSubmitting(false);
          return Swal.fire({
            heightAuto: false,
            icon: "error",
            title: `Não foi possível Enviar o arquivo.`,
            text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
            showConfirmButton: true,
          });
        }
      }

      setSubmitting(false);

      Swal.close();
    },
  });

  const alterarEtiqueta = (index: number, checked: boolean) => {
    etiquetasStaticas.values[index].alterar = checked;
    etiquetasStaticas.setValues(etiquetasStaticas.values);
  };

  const checkedEtiquetas = (index: number, checked: boolean) => {
    if (checked && etiquetas.length >= 4) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `Você atingiu o limite máximo de etiquetas`,
        showConfirmButton: true,
      });
      etiquetasStaticas.values[index].checked = false;
      etiquetasStaticas.setValues(etiquetasStaticas.values);
      return;
    }

    if (checked && !etiquetasStaticas.values[index].nome) {
      Swal.fire({
        heightAuto: false,
        icon: "info",
        title: `É necessário que você informe uma descrição para etiqueta`,
        showConfirmButton: true,
      });
      etiquetasStaticas.values[index].checked = false;
      etiquetasStaticas.setValues(etiquetasStaticas.values);
      return;
    }

    etiquetasStaticas.values[index].checked = checked;
    etiquetasStaticas.setValues(etiquetasStaticas.values);
    setEtiquetas(etiquetasStaticas.values.filter((e) => e.checked));
  };

  const removerEtiquetas = (item: IEtiqueta) => {
    setEtiquetas(etiquetas.filter((e) => e.nome != item.nome));
    etiquetasStaticas.values.forEach((etiqueta: IEtiqueta) => {
      if (etiqueta.nome == item.nome) {
        item.checked = false;
        etiquetasStaticas.setValues(etiquetasStaticas.values);
      }
    });
  };

  const popoverEtiquetas = () => {
    return (
      adicionarEtiqueta && (
        <Popover style={{ top: "300px", left: "670px", maxWidth: "320px" }}>
          <Popover.Body>
            <div style={{ width: "25rem", cursor: "pointer" }}>
              {etiquetasStaticas.values.map((item, index) => (
                <>
                  <div className="col-md-6">
                    {!item.alterar ? (
                      <>
                        <tr>
                          <td style={{ paddingRight: "10px" }}>
                            <FormCheckInput
                              name="terms"
                              checked={item.checked}
                              onClick={() => checkedEtiquetas(index, !item.checked)}
                              className="text-orange"
                              style={{
                                backgroundColor: "var(--primary-base2)",
                                borderColor: "var(--primary-base2)",
                                cursor: "pointer",
                                fontSize: "24px",
                                marginTop: "-2px",
                              }}
                            />
                          </td>

                          <td>
                            <Card
                              onClick={() => alterarEtiqueta(index, true)}
                              className={"fade alert alert-" + item.cor + " show"}
                              style={{ width: "215px", height: "28px" }}
                            >
                              <Card.Body
                                onDoubleClick={() => alterarEtiqueta(index, true)}
                                style={{ width: "300px", height: "28px", marginTop: "-25px", marginLeft: "-20px" }}
                              >
                                {" "}
                                <label style={{ fontSize: "14px" }} className="text-orange">
                                  {item.nome} {"  "}
                                </label>
                              </Card.Body>
                            </Card>
                          </td>
                          <td style={{ paddingLeft: "10px" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer", fontSize: "15px" }} className="text-orange" icon={faEdit} />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td style={{ paddingRight: "10px" }}>
                            <FontAwesomeIcon
                              style={{ cursor: "pointer", fontSize: "24px" }}
                              onClick={() => alterarEtiqueta(index, false)}
                              className="text-orange"
                              icon={faPaperPlane}
                            />
                          </td>
                          <td>
                            <Card className={"fade alert alert-" + item.cor + " show"} style={{ width: "215px", height: "28px" }}>
                              <Card.Body style={{ width: "300px", height: "28px", marginTop: "-25px", marginLeft: "-20px" }}>
                                <Form.Control
                                  style={{ height: "21px", width: "188px" }}
                                  type="text"
                                  onChange={(e) => (item.nome = e.target.value)}
                                  maxLength={17}
                                />
                              </Card.Body>
                            </Card>
                          </td>
                        </tr>
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          </Popover.Body>
        </Popover>
      )
    );
  };

  return (
    <>
      <Modal
        size="xl"
        centered={true}
        show={exibirModal}
        backdrop="static"
        onEscapeKeyDown={(e) => {
          e.preventDefault();
          return false;
        }}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">{titulo}</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              <div className="row mt-2">
                {etiquetas.length > 0 ? (
                  etiquetas.map((item) => (
                    <Card className={"fade alert alert-" + item.cor + " show"} style={{ width: "230px", height: "28px", paddingRight: "10px" }}>
                      <Card.Body style={{ width: "340px", height: "28px", marginTop: "-25px", marginLeft: "-20px" }}>
                        <label style={{ fontSize: "14px" }} className="form-label fw-bolder text-orange">
                          {item.nome} {"  "}
                          <FontAwesomeIcon
                            style={{ cursor: "pointer", fontSize: "15px" }}
                            onClick={() => removerEtiquetas(item)}
                            className="text-orange"
                            icon={faTimes}
                          />
                        </label>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <>
                    <div style={{ width: "230px", height: "55px" }}>Nenhuma etiqueta selecionada</div>
                  </>
                )}
              </div>

              <div className="row mt-2">
                {carregandoTipoDocumento ? (
                  <>Carregando tipo documento...</>
                ) : (
                  <div className="col-md-6 mt-3">
                    <label htmlFor="form-tipoDocumentoId" className="form-label required fw-bolder text-orange">
                      Tipo de Documento:
                    </label>
                    <select
                      {...formikGed.getFieldProps("tipoDocumentoId")}
                      value={formikGed.values.tipoDocumentoId}
                      onChange={(e) => {
                        formikGed.setFieldTouched("tipoDocumentoId", true);
                        formikGed.setFieldValue("tipoDocumentoId", parseInt(e.target.value));
                      }}
                      className={clsx(
                        "form-select",
                        {
                          "is-invalid": formikGed.touched.tipoDocumentoId && formikGed.errors.tipoDocumentoId,
                        },
                        {
                          "is-valid": formikGed.touched.tipoDocumentoId && !formikGed.errors.tipoDocumentoId,
                        }
                      )}
                      id="form-tipoDocumentoId"
                    >
                      <option value="0"> Selecione um tipo de documento </option>
                      {tiposDocumentos.map((map) => {
                        return (
                          <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                            {" "}
                            {map.nome}{" "}
                          </option>
                        );
                      })}
                    </select>
                    {formikGed.touched.tipoDocumentoId && formikGed.errors.tipoDocumentoId && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formikGed.errors.tipoDocumentoId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {carregandoPastaGed ? (
                  <>Carregando pastas...</>
                ) : (
                  <div className="col-md-6 mt-3">
                    <label htmlFor="form-pastaGedId" className="form-label required fw-bolder text-orange">
                      Pasta:
                    </label>
                    <select
                      {...formikGed.getFieldProps("pastaGedId")}
                      value={formikGed.values.pastaGedId}
                      onChange={(e) => {
                        formikGed.setFieldTouched("pastaGedId", true);
                        formikGed.setFieldValue("pastaGedId", parseInt(e.target.value));
                      }}
                      className={clsx(
                        "form-select",
                        {
                          "is-invalid": formikGed.touched.pastaGedId && formikGed.errors.pastaGedId,
                        },
                        {
                          "is-valid": formikGed.touched.pastaGedId && !formikGed.errors.pastaGedId,
                        }
                      )}
                      id="form-pastaGedId"
                    >
                      <option value="0"> Selecione uma pasta </option>
                      {pastaGeds.map((map) => {
                        return (
                          <option key={map.pastaGedId} value={map.pastaGedId}>
                            {" "}
                            {map.nome}{" "}
                          </option>
                        );
                      })}
                    </select>
                    {formikGed.touched.pastaGedId && formikGed.errors.pastaGedId && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formikGed.errors.pastaGedId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="row mt-2">
                <div className="col-12 col-lg-6">
                  <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                    Código
                  </label>
                  <input
                    {...formikGed.getFieldProps("codigo")}
                    placeholder="Insira o Código"
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formikGed.touched.codigo && formikGed.errors.codigo,
                      },
                      {
                        "is-valid": formikGed.touched.codigo && !formikGed.errors.codigo,
                      }
                    )}
                    id="form-codigo"
                  />
                  {formikGed.touched.codigo && formikGed.errors.codigo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formikGed.errors.codigo}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-12 col-lg-6">
                  <label htmlFor="form-arquivo" className="form-label required fw-bolder text-orange">
                    Arquivos
                  </label>
                  <input
                    {...formikGed.getFieldProps("arquivo")}
                    placeholder="Selecione o arquivo"
                    type="file"
                    multiple={false}
                    accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.pdf,.mp3,.zip,.wav,.tiff,.tif,.rft,.rar,.mp4,.eml,.opus,.mkv,.pjc"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formikGed.touched.arquivo && formikGed.errors.arquivo,
                      },
                      {
                        "is-valid": formikGed.touched.arquivo && !formikGed.errors.arquivo,
                      }
                    )}
                    id="form-arquivo"
                  />
                  {formikGed.touched.arquivo && formikGed.errors.arquivo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formikGed.errors.arquivo}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="row mt-6 mt-3">
                  <label htmlFor="form-restrito" className="form-label fw-bolder text-orange">
                    Restrito?{" "}
                    <FormCheckInput
                      type="checkbox"
                      style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                      onChange={(e) => {
                        if (formikGed.values.restrito) formikGed.setValues({ ...formikGed.values, restrito: false });
                        if (!formikGed.values.restrito) formikGed.setValues({ ...formikGed.values, restrito: true });
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6 col-lg-15">
                  <label htmlFor="form-descricao" className="form-label required fw-bolder text-orange">
                    Descrição
                  </label>
                  <textarea
                    {...formikGed.getFieldProps("descricao")}
                    placeholder="Insira a descrição"
                    maxLength={4000}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formikGed.touched.descricao && formikGed.errors.descricao,
                      },
                      {
                        "is-valid": formikGed.touched.descricao && !formikGed.errors.descricao,
                      }
                    )}
                    id="form-descricao"
                  />
                  {formikGed.touched.descricao && formikGed.errors.descricao && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formikGed.errors.descricao}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-6 col-lg-6">
                  <label htmlFor="form-descricao" className="form-label fw-bolder text-orange">
                    Etiqueta:{" "}
                    <FontAwesomeIcon
                      style={{ fontSize: "17px", cursor: "pointer" }}
                      className="mx-2 text-orange"
                      onClick={() => setAdicionarEtiqueta(!adicionarEtiqueta)}
                      icon={faTags}
                    />
                  </label>
                  {popoverEtiquetas()}
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              disabled={formikGed.isSubmitting || !formikGed.isValid}
              onClick={() => {
                formikGed.submitForm();
              }}
              type="button"
              className="btn btn-orange me-5"
            >
              {!formikGed.isSubmitting && <> SALVAR </>}
              {formikGed.isSubmitting && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <button disabled={formikGed.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
              {" "}
              CANCELAR{" "}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CriarGedModal;