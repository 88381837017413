import { IconBaseProps } from "react-icons";

export enum MenuType {
  cover,
  send,
  ia,
  templateList,
  publish,
  commitment,
  historyVersion,
  comparator,
  importador
}

export interface DocumentMenuItem {
  type: MenuType
  title: string;
  enabled: boolean;
  className?: string;
  icon: React.ComponentType<IconBaseProps>;
}