/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./Api";

import { ICampo } from "../interfaces/ICampo";
import { ICampoDinamico } from "../interfaces/ICampoDinamico";
import { IConfiguracaoTela } from "../interfaces/IConfiguracaoTela";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { AdicionaConfiguracaoTelaRequest } from "../interfaces/Requests/ConfiguracaoTela/AdicionaConfiguracaoTelaRequest";
import { IRecuperaConfiguracaoTelaRequest } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ECamposIDEnum, EModulo, ETelaEnum } from "../enum";
import { Modelo } from "../pages/ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";
import { routePropriedade, tipoPorModulo } from "../utils/Utils";
import DuplicarParametrizacaoRequest from "../interfaces/Requests/DuplicarParametrizacao/DuplicarParametrizacaoRequest";
import ITipoRelatorio from "../interfaces/ITipoRelatorio";
import ICampoTipos from "../interfaces/ICampoTipos";

class ConfiguracaoTelaService {
  async obteTipos(modulo: EModulo): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${tipoPorModulo(modulo)}/Recupera${tipoPorModulo(modulo)}?status=1&offset=0&limit=1000`
    );

    return result;
  }

  public async obterCapa(
    objetoId: number,
    modulo: EModulo,
    separadorId: number
  ): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<
      IRecuperaLista<IRecuperaConfiguracaoTelaResponse>
    >(
      `/${routePropriedade(modulo)}/RecuperaCapa${routePropriedade(modulo)}?${routePropriedade(modulo)}Id=${objetoId}&separadorId=${separadorId}`
    );

    return result;
  }

  public async obterPesquisa(modulo: EModulo, tela: ETelaEnum): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {

    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`ConfiguracaoTela/RecuperaConfiguracaoTela?tela=${tela}&modulo=${modulo}`);

    return result;
  }

  public async obterFiltro(
    modulo: EModulo,
    separadorId: number
  ): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<
      IRecuperaLista<IRecuperaConfiguracaoTelaResponse>
    >(
      `/${routePropriedade(modulo)}/RecuperaCapa${routePropriedade(modulo)}&separadorId=${separadorId}`
    );

    return result;
  }

  public async duplicar(request: DuplicarParametrizacaoRequest) {
    const { data: response } = await api.post(
      `/ConfiguracaoTela/CopiaConfiguracaoTela`,
      request
    );

    return response;
  }

  async obterCamposCapa(
    modulo: EModulo
  ): Promise<IRecuperaLista<ICampoDinamico>> {
    let query = "";
    switch (modulo) {
      case EModulo.AreaDireito:
        query += `capaProcesso=true`;
        break;

      case EModulo.Contrato:
        query += `capaContrato=true`;
        break;

      case EModulo.Societario:
        query += `capaEmpresa=true`;
        break;

      case EModulo.MarcaEPertences:
        query += `capaMarca=true`;
        break;

      case EModulo.Imovel:
        query += `capaImovel=true`;
        break;

      case EModulo.Regulatorio:
        query += `capaLicenca=true`;
        break;

      case EModulo.Consultivo:
        query += `capaConsulta=true`;
        break;

      case EModulo.Procuracao:
        query += `capaProcuracao=true`;
        break;

      case EModulo.AtoSocietario:
        query += `capaAtoSocietario=true`;
        break;

      case EModulo.Despesa:
        query += `capaDespesa=true`;
        break;

      case EModulo.Protocolo:
        query += `capaXFinderProtocolo=true`;
        break;

      case EModulo.XpayHonorario:
        query += `capaHonorario=true`;
        break;

      case EModulo.XpayReembolso:
        query += `capaReembolso=true`;
        break;

      case EModulo.Audiencia:
        query += `capaAudiencia=true`;
        break;

      default:
        "";
    }
    let { data: result } = await api.get<IRecuperaLista<ICampoDinamico>>(
      `Campos/RecuperaCampos?${query}`
    );

    return result;
  }

  async obterPartes(modulo: number, idBusca: number): Promise<any> {
    let query = "";

    switch (modulo) {
      case EModulo.AreaDireito:
        query += `/Processo/RecuperaPartesProcessoPorId?processoId=${idBusca}`;
        break;

      case EModulo.Contrato:
        query += `/Contrato/RecuperaPartesContratoPorId?contratoId=${idBusca}`;
        break;

      case EModulo.MarcaEPertences:
        query += `/Marca/RecuperaPartesMarcaPorId?marcaId=${idBusca}`;
        break;

      case EModulo.Imovel:
        query += `/Imovel/RecuperaPartesImovelPorId?imovelId=${idBusca}`;
        break;

      case EModulo.Regulatorio:
        query += `/Licenca/RecuperaPartesLicencaPorId?licencaId=${idBusca}`;
        break;

      case EModulo.Consultivo:
        query += `/Consulta/RecuperaPartesConsultaPorId?consultaId=${idBusca}`;
        break;

      case EModulo.Procuracao:
        query += `/Procuracao/RecuperaPartesProcuracaoPorId?procuracaoId=${idBusca}`;
        break;

      case EModulo.Societario:
        query += `/Empresa/RecuperaPartesProcuracaoPorId?empresaId=${idBusca}`;
        break;

      default:
        "";
    }
    return await api.get<any[]>(query);
  }

  async obterListaGestorSolicitanteApData(
    modulo?: EModulo,
    categoriaId?: number,
    campo?: number,
    departamentoId?: number
  ): Promise<IRecuperaLista<any>> {
    let query = "";

    if (campo) query += `&campo=${campo}`;

    query += `&tipoContratoId=${categoriaId}&departamentoId=${departamentoId}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `Gestor/RecuperaGestorApData?modulo=${modulo}${query}`
    );

    return result;
  }

  async obterListaLimitadaCadastroCampo(
    controller?: string,
    modulo?: EModulo,
    categoriaId?: number,
    idObjeto?: number,
    idSubObjeto?: number,
    campo?: number,
  ): Promise<IRecuperaLista<any>> {
    let query = "";

    if (campo) query += `&campo=${campo}`;


    switch (modulo) {
      case EModulo.AreaDireito:
        query += `&areaDireitoId=${categoriaId}`;
        break;

      case EModulo.Contrato:
        query += `&tipoContratoId=${categoriaId}`;
        break;

      case EModulo.MarcaEPertences:
        query += `&tipoMarcaId=${categoriaId}`;
        break;

      case EModulo.Imovel:
        query += `&tipoImovelId=${categoriaId}`;
        break;

      case EModulo.Regulatorio:
        query += `&tipoLicencaId=${categoriaId}`;
        break;

      case EModulo.Consultivo:
        query += `&tipoConsultaId=${categoriaId}`;
        break;

      case EModulo.Procuracao:
        query += `&tipoProcuracaoId=${categoriaId}`;
        break;

      case EModulo.Societario:
        query += `&tipoSociedadeId=${categoriaId}`;
        break;

      case EModulo.AtoSocietario:
        query += `&tipoAtoId=${categoriaId}`;
        break;

      case EModulo.Compromisso:
        query += `&fluxoFaseId=${categoriaId}`;
        break;

      case EModulo.Despesa:
        query += `&tipoDespesaId=${categoriaId}&despesaId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.Garantia:
        query += `&tipoGarantiaId=${categoriaId}&garantiaId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.Protocolo:
        query += `&tipoProtocoloId=${categoriaId}&xfinderProtocoloId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.Bloqueio:
        query += `&tipoBloqueioId=${categoriaId}&bloqueioId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.Decisao:
        query += `&tipoDecisaoId=${categoriaId}&decisaoId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.ParteContraria:
        query += `&tipoParteContrariaId=${categoriaId}&parteContrariaId=${idSubObjeto}`;
        query += `&papelSignararioId=${categoriaId}&parteContrariaId=${idSubObjeto}`;
        break;

      case EModulo.Desdobramento:
        query += `&tipoDesdobramentoId=${categoriaId}&desdobramentoId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.PropostaAcordo:
        query += `&tipoPropostaAcordoId=${categoriaId}&propostaAcordoId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.Obrigacao:
        query += `&tipoObrigacaoId=${categoriaId}&obrigacaoId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.Comunicacao:
        query += `&tipoComunicacaoId=${categoriaId}&comunicacaoId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.XpayHonorario:
        query += `&tipoHonorarioId=${categoriaId}&honorarioId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.XpayReembolso:
        query += `&tipoReembolsoId=${categoriaId}&reembolsoId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      case EModulo.Audiencia:
        query += `&tipoAudienciaId=${categoriaId}&audienciaId=${idSubObjeto}&processoId=${idObjeto}`;
        break;

      default:
        "";
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${controller}/RecuperaListaLimitada?modulo=${modulo}${query}`
    );

    return result;
  }

  async obterListaLimitadaDespesa(
    modulo: EModulo,
    campo?: string,
    tipoDespesaId?: number,
    despesaId?: number,
    objeto?: any
  ): Promise<IRecuperaLista<any>> {
    let query = `&tipoDespesaId=${tipoDespesaId}&${tipoPorModulo(modulo)}Id=${eval(`objeto.${tipoPorModulo(modulo)}Id`)}&despesaId=${despesaId}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${campo}/RecuperaListaLimitada?modulo=${EModulo.Despesa}${query}`
    );

    return result;
  }

  async obterListaLimitadaDesdobramento(
    modulo: EModulo,
    campo?: string,
    tipoDesdobramentoId?: number,
    desdobramentoId?: number,
    objeto?: any
  ): Promise<IRecuperaLista<any>> {
    let query = `&tipoDesdobramentoId=${tipoDesdobramentoId}&${tipoPorModulo(modulo)}Id=${eval(`objeto.${tipoPorModulo(modulo)}Id`)}&desdobramentoId=${desdobramentoId}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${campo}/RecuperaListaLimitada?modulo=${EModulo.Desdobramento}${query}`
    );

    return result;
  }

  async obterListaLimitadaPropostaAcordo(
    modulo: EModulo,
    campo?: string,
    tipoPropostaAcordoId?: number,
    propostaAcordoId?: number,
    objeto?: any
  ): Promise<IRecuperaLista<any>> {
    let query = `&tipoPropostaAcordoId=${tipoPropostaAcordoId}&${tipoPorModulo(modulo)}Id=${eval(`objeto.${tipoPorModulo(modulo)}Id`)}&propostaAcordoId=${propostaAcordoId}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${campo}/RecuperaListaLimitada?modulo=${EModulo.Despesa}${query}`
    );

    return result;
  }

  async obterListaLimitadaHonorario(
    modulo: EModulo,
    controller?: string,
    tipoHonorarioId?: number,
    honorarioId?: number,
    objeto?: any,
    campo?: number
  ): Promise<IRecuperaLista<any>> {
    let query = `&tipoHonorarioId=${tipoHonorarioId}&${tipoPorModulo(modulo)}Id=${eval(`objeto.${tipoPorModulo(modulo)}Id`)}&honorarioId=${honorarioId}`;

    if (campo) query += `&campo=${campo}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${controller}/RecuperaListaLimitada?modulo=${EModulo.XpayHonorario}${query}`
    );

    return result;
  }

  async obterListaLimitadaAudiencia(
    modulo: EModulo,
    controller?: string,
    tipoAudienciaId?: number,
    audienciaId?: number,
    objeto?: any,
    campo?: number
  ): Promise<IRecuperaLista<any>> {
    let query = `&tipoAudienciaId=${tipoAudienciaId}&${tipoPorModulo(modulo)}Id=${eval(`objeto.${tipoPorModulo(modulo)}Id`)}&audienciaId=${audienciaId}`;

    if (campo) query += `&campo=${campo}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${controller}/RecuperaListaLimitada?modulo=${EModulo.Audiencia}${query}`
    );

    return result;
  }

  async obterListaLimitadaReembolso(
    modulo: EModulo,
    controller?: string,
    tipoReembolsoId?: number,
    reembolsoId?: number,
    objeto?: any,
    campo?: number
  ): Promise<IRecuperaLista<any>> {
    let query = `&tipoReembolsoId=${tipoReembolsoId}&${tipoPorModulo(modulo)}Id=${eval(`objeto.${tipoPorModulo(modulo)}Id`)}&reembolsoId=${reembolsoId}`;

    if (campo) query += `&campo=${campo}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${controller}/RecuperaListaLimitada?modulo=${EModulo.XpayReembolso}${query}`
    );

    return result;
  }

  async obterListaLimitadaXDespesa(filtros: any): Promise<IRecuperaLista<any>> {
    let query = `${"modulo=12&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`)}&campo=${filtros.campo}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `XCampos/RecuperaListaLimitada?${query}`
    );

    return result;
  }

  async obterListaLimitadaXPropostaAcordo(
    filtros: any
  ): Promise<IRecuperaLista<any>> {
    let query = `${"modulo=36&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`)}&campo=${filtros.campo}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `XCampos/RecuperaListaLimitada?${query}`
    );

    return result;
  }

  async obterListaLimitadaXHonorario(
    filtros: any
  ): Promise<IRecuperaLista<any>> {
    let query = `${"modulo=34&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`)}&campo=${filtros.campo}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `XCampos/RecuperaListaLimitada?${query}`
    );

    return result;
  }

  async obterListaLimitadaXReembolso(
    filtros: any
  ): Promise<IRecuperaLista<any>> {
    let query = `${"modulo=35&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`)}&campo=${filtros.campo}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `XCampos/RecuperaListaLimitada?${query}`
    );

    return result;
  }

  async obterDoing(filtros: any): Promise<IRecuperaLista<any>> {
    let query = `${"modulo=" + filtros.modulo + "&tela=" + filtros.tela}`;
    //Modulos
    if (filtros.areaDireitoId > 0) {
      query += `&areaDireitoId=${filtros.areaDireitoId}`;
    }

    if (filtros.tipoContratoId > 0) {
      query += `&tipoContratoId=${filtros.tipoContratoId}`;
    }

    if (filtros.tipoMarcaId > 0) {
      query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
    }

    if (filtros.tipoImovelId > 0) {
      query += `&tipoImovelId=${filtros.tipoImovelId}`;
    }

    if (filtros.tipoLicencaId > 0) {
      query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
    }

    if (filtros.tipoConsultaId > 0) {
      query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
    }

    if (filtros.tipoProcuracaoId > 0) {
      query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
    }

    if (filtros.tipoSociedadeId > 0) {
      query += `&tipoSociedadeId=${filtros.tipoSociedadeId}`;
    }

    if (filtros.tipoAtoId > 0) {
      query += `&tipoAtoId=${filtros.tipoAtoId}`;
    }

    //Fluxo
    if (filtros.fluxoFaseId > 0) {
      query += `&fluxoFaseId=${filtros.fluxoFaseId}`;
    }

    // soluçoes
    if (filtros.tipoDespesaId > 0) {
      query += `&tipoDespesaId=${filtros.tipoDespesaId}`;
    }

    if (filtros.tipoGarantiaId > 0) {
      query += `&tipoGarantiaId=${filtros.tipoGarantiaId}`;
    }

    if (filtros.tipoProtocoloId > 0) {
      query += `&tipoProtocoloId=${filtros.tipoProtocoloId}`;
    }

    if (filtros.tipoObrigacaoId > 0) {
      query += `&tipoObrigacaoId=${filtros.tipoObrigacaoId}`;
    }

    if (filtros.tipoBloqueioId > 0) {
      query += `&tipoBloqueioId=${filtros.tipoBloqueioId}`;
    }

    if (filtros.tipoDecisaoId > 0) {
      query += `&tipoDecisaoId=${filtros.tipoDecisaoId}`;
    }

    if (filtros.tipoDesdobramentoId > 0) {
      query += `&tipoDesdobramentoId=${filtros.tipoDesdobramentoId}`;
    }

    if (filtros.tipoParteContrariaId > 0) {
      query += `&tipoParteContrariaId=${filtros.tipoParteContrariaId}`;
    }

    if (filtros.tipoHonorarioId > 0) {
      query += `&tipoHonorarioId=${filtros.tipoHonorarioId}`;
    }

    if (filtros.tipoReembolsoId > 0) {
      query += `&tipoReembolsoId=${filtros.tipoReembolsoId}`;
    }

    if (filtros.tipoComunicacaoId > 0) {
      query += `&tipoComunicacaoId=${filtros.tipoComunicacaoId}`;
    }

    if (filtros.tipoAudienciaId > 0) {
      query += `&tipoAudienciaId=${filtros.tipoAudienciaId}`;
    }

    if (filtros.separadorId > 0) {
      query += `&separadorId=${filtros.separadorId}`;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `ConfiguracaoTela/RecuperaConfiguracaoTela?${query}`
    );

    return result;
  }

  async obterTodo(tela: ETelaEnum): Promise<IRecuperaLista<any>> {
    let query = "";

    switch (tela) {
      case ETelaEnum.TelaCadastroProcesso:
        query = "cadastroProcesso=true";
        break;

      case ETelaEnum.TelaCapaProcesso:
        query = "capaProcesso=true";
        break;

      case ETelaEnum.TelaCadastroContrato:
        query = "cadastroContrato=true";
        break;

      case ETelaEnum.TelaCapaContrato:
        query = "capaContrato=true";
        break;

      case ETelaEnum.TelaCadastroMarcasEPertences:
        query = "cadastroMarca=true";
        break;

      case ETelaEnum.TelaCapaMarcasEPertences:
        query = "capaMarca=true";
        break;

      case ETelaEnum.TelaCadastroImovel:
        query = "cadastroImovel=true";
        break;

      case ETelaEnum.TelaCapaImovel:
        query = "capaImovel=true";
        break;

      case ETelaEnum.TelaCadastroRegulatorio:
        query = "cadastroLicenca=true";
        break;

      case ETelaEnum.TelaCapaRegulatorio:
        query = "capaLicenca=true";
        break;

      case ETelaEnum.TelaCadastroConsultivo:
        query = "cadastroConsulta=true";
        break;

      case ETelaEnum.TelaCapaConsultivo:
        query = "capaConsulta=true";
        break;

      case ETelaEnum.TelaCadastroProcuracao:
        query = "cadastroProcuracao=true";
        break;

      case ETelaEnum.TelaCapaProcuracao:
        query = "capaProcuracao=true";
        break;

      case ETelaEnum.TelaCadastroEmpresa:
        query = "cadastroEmpresa=true";
        break;

      case ETelaEnum.TelaCapaEmpresa:
        query = "capaEmpresa=true";
        break;

      case ETelaEnum.TelaCadastroAtoSocietario:
        query = "cadastroAtoSocietario=true";
        break;

      case ETelaEnum.TelaCapaAtoSocietario:
        query = "capaAtoSocietario=true";
        break;

      case ETelaEnum.TelaCadastroDespesa:
        query = "cadastroDespesa=true";
        break;

      case ETelaEnum.TelaCapaDespesa:
        query = "capaDespesa=true";
        break;

      case ETelaEnum.TelaCadastroHonorario:
        query = "cadastroHonorario=true";
        break;

      case ETelaEnum.TelaCapaHonorario:
        query = "capaHonorario=true";
        break;

      case ETelaEnum.TelaCadastroReembolso:
        query = "cadastroReembolso=true";
        break;

      case ETelaEnum.TelaCapaReembolso:
        query = "capaReembolso=true";
        break;

      case ETelaEnum.TelaConcluirFase:
        query = "concluirFase=true";
        break;

      case ETelaEnum.TelaCapaFase:
        query = "capaFase=true";
        break;

      case ETelaEnum.TelaCapaPropostaAcordo:
        query = "capaPropostaAcordo=true";
        break;

      case ETelaEnum.TelaCadastroPropostaAcordo:
        query = "cadastroPropostaAcordo=true";
        break;

      case ETelaEnum.TelaCadastroGarantia:
        query = "cadastroGarantia=true";
        break;

      case ETelaEnum.TelaCapaGarantia:
        query = "capaGarantia=true";
        break;

      case ETelaEnum.TelaCadastroParteContraria:
        query = "cadastroParteContraria=true";
        break;

      case ETelaEnum.TelaCapaParteContraria:
        query = "capaParteContraria=true";
        break;

      case ETelaEnum.TelaCadastroBloqueio:
        query = "cadastroBloqueio=true";
        break;

      case ETelaEnum.TelaCapaBloqueio:
        query = "capaBloqueio=true";
        break;

      case ETelaEnum.TelaCadastroDecisao:
        query = "cadastroDecisao=true";
        break;

      case ETelaEnum.TelaCapaDecisao:
        query = "capaDecisao=true";
        break;

      case ETelaEnum.TelaCadastroObrigacao:
        query = "cadastroObrigacao=true";
        break;

      case ETelaEnum.TelaCapaComunicacao:
        query = "capaComunicacao=true";
        break;

      case ETelaEnum.TelaCadastroComunicacao:
        query = "cadastroComunicacao=true";
        break;

      case ETelaEnum.TelaCapaObrigacao:
        query = "capaObrigacao=true";
        break;

      case ETelaEnum.TelaCadastroDesdobramento:
        query = "cadastroDesdobramento=true";
        break;

      case ETelaEnum.TelaCapaDesdobramento:
        query = "capaDesdobramento=true";
        break;

      case ETelaEnum.TelaCadastroProtocolo:
        query = "cadastroXFinderProtocolo=true";
        break;

      case ETelaEnum.TelaCapaProtocolo:
        query = "capaXFinderProtocolo=true";
        break;

      case ETelaEnum.TelaCadastroAudiencia:
        query = "cadastroAudiencia=true";
        break;

      case ETelaEnum.TelaCapaAudiencia:
        query = "capaAudiencia=true";
        break;

      default:
        break;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `/Campos/RecuperaCampos?status=1&isXCampo=false&${query}`
    );

    return result;
  }

  async obterListaLimitadaCadastro(
    filtros: any,
    entidade: string,
    modulo: EModulo
  ): Promise<IRecuperaLista<any>> {
    let query = "";

    if (filtros.campo) query += `campo=${filtros.campo}&`;

    switch (modulo) {
      //Modulos
      case EModulo.AreaDireito:
        query += `${"modulo=1&tela=" + filtros.tela + "&areaDireitoId=" + filtros.areaDireitoId}`;
        break;

      case EModulo.Contrato:
        query += `${"modulo=7&tela=" + filtros.tela + "&tipoContratoId=" + filtros.tipoContratoId}`;
        break;

      case EModulo.Imovel:
        query += `${"modulo=10&tela=" + filtros.tela + "&tipoImovelId=" + filtros.tipoImovelId}`;
        break;

      case EModulo.MarcaEPertences:
        query += `${"modulo=11&tela=" + filtros.tela + "&tipomarcaid=" + filtros.tipoMarcaId}`;
        break;

      case EModulo.Regulatorio:
        query += `${"modulo=30&tela=" + filtros.tela + "&tipoLicencaId=" + filtros.tipoLicencaId}`;
        break;

      case EModulo.Consultivo:
        query += `${"modulo=9&tela=" + filtros.tela + "&tipoConsultaId=" + filtros.tipoConsultaId}`;
        break;

      case EModulo.Procuracao:
        query += `${"modulo=21&tela=" + filtros.tela + "&tipoProcuracaoId=" + filtros.tipoProcuracaoId}`;
        break;

      case EModulo.Societario:
        query += `${"modulo=5&tela=" + filtros.tela + "&tipoSociedadeId=" + filtros.tipoSociedadeId}`;
        break;

      case EModulo.AtoSocietario:
        query += `${"modulo=13&tela=" + filtros.tela + "&tipoAtoId=" + filtros.tipoAtoId}`;
        break;

      case EModulo.Compromisso:
        query += `${"modulo=16&tela=" + filtros.tela + "&fluxoFaseId=" + filtros.fluxoFaseId}`;
        break;

      //Soluções
      case EModulo.Despesa:
        query += `${"modulo=12&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoDespesaId=" + filtros.tipoDespesaId}`;
        break;

      case EModulo.Garantia:
        query += `${"modulo=22&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoGarantiaId=" + filtros.tipoGarantiaId}`;
        break;

      case EModulo.PropostaAcordo:
        query += `${"modulo=25&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoPropostaAcordoId=" + filtros.tipoPropostaAcordoId}`;
        break;

      case EModulo.Bloqueio:
        query += `${"modulo=15&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoBloqueioId=" + filtros.tipoBloqueioId}`;
        break;

      case EModulo.Decisao:
        query += `${"modulo=17&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoDecisaoId=" + filtros.tipoDecisaoId}`;
        break;

      case EModulo.Obrigacao:
        query += `${"modulo=23&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoObrigacaoId=" + filtros.tipoObrigacaoId}`;
        break;

      case EModulo.ParteContraria:
        query += `${"modulo=40&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoParteContrariaId=" + filtros.tipoParteContrariaId}`;
        break;

      case EModulo.Desdobramento:
        query += `${"modulo=18&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoDesdobramentoId=" + filtros.tipoDesdobramentoId}`;
        break;

      case EModulo.Comunicacao:
        query += `${"modulo=47&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoComunicacaoId=" + filtros.tipoComunicacaoId}`;
        break;

      case EModulo.Protocolo:
        query += `${"modulo=468&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoProtocoloId=" + filtros.tipoProtocoloId}`;
        break;

      case EModulo.XpayHonorario:
        query += `${"modulo=34&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoHonorarioId=" + filtros.tipoHonorarioId}`;
        break;

      case EModulo.XpayReembolso:
        query += `${"modulo=35&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoReembolsoId=" + filtros.tipoReembolsoId}`;
        break;

      case EModulo.Audiencia:
        query += `${"modulo=38&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoAudienciaId=" + filtros.tipoAudienciaId}`;
        break;

      default:
        "";
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${entidade}/RecuperaListaLimitada?${query}`
    );

    return result;
  }

  async obterListaLimitadaCadastroXCampo(
    filtros: any
  ): Promise<IRecuperaLista<any>> {
    let query = "";

    switch (filtros.modulo) {
      case EModulo.AreaDireito:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&areaDireitoId=${filtros.areaDireitoId}`;
        break;

      case EModulo.Contrato:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoContratoId=${filtros.tipoContratoId}`;
        break;

      case EModulo.MarcaEPertences:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoMarcaId=${filtros.tipoMarcaId}`;
        break;

      case EModulo.Imovel:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoImovelId=${filtros.tipoImovelId}`;
        break;

      case EModulo.Regulatorio:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoLicencaId=${filtros.tipoLicencaId}`;
        break;

      case EModulo.Consultivo:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoConsultaId=${filtros.tipoConsultaId}`;
        break;

      case EModulo.Procuracao:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        break;

      case EModulo.Societario:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoSociedadeId=${filtros.tipoSociedadeId}`;
        break;

      case EModulo.AtoSocietario:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoAtoId=${filtros.tipoAtoId}`;
        break;

      case EModulo.Compromisso:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&fluxoFaseId=${filtros.fluxoFaseId}`;
        break;

      case EModulo.Despesa:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoDespesaId=${filtros.tipoDespesaId}`;
        break;

      case EModulo.PropostaAcordo:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoPropostaAcordoId=${filtros.tipoDespesaId}`;
        break;

      case EModulo.Garantia:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoGarantiaId=${filtros.tipoGarantiaId}`;
        break;

      case EModulo.Protocolo:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoProtocoloId=${filtros.tipoProtocoloId}`;
        break;

      case EModulo.Bloqueio:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoBloqueioId=${filtros.tipoBloqueioId}`;
        break;

      case EModulo.Decisao:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoDecisaoId=${filtros.tipoDecisaoId}`;
        break;

      case EModulo.Obrigacao:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoObrigacaoId=${filtros.tipoObrigacaoId}`;
        break;

      case EModulo.Comunicacao:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoComunicacaoId=${filtros.tipoComunicacaoId}`;
        break;

      case EModulo.ParteContraria:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoParteContrariaId=${filtros.tipoParteContrariaId}`;
        break;

      case EModulo.Desdobramento:
        query += `campo=${filtros.campo}&modulo=${filtros.modulo}&tela=${filtros.tela}&tipoDesdobramentoId=${filtros.tipoDesdobramentoId}`;
        break;

      case EModulo.XpayHonorario:
        query += `${"modulo=34&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoHonorarioId=" + filtros.tipoHonorarioId}`;
        break;

      case EModulo.XpayReembolso:
        query += `${"modulo=35&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoReembolsoId=" + filtros.tipoReembolsoId}`;
        break;

      case EModulo.Audiencia:
        query += `${"modulo=38&tela=" + filtros.tela + `&${tipoPorModulo(filtros.modulo)}Id=` + eval(`filtros.${tipoPorModulo(filtros.modulo)}Id`) + "&tipoAudienciaId=" + filtros.tipoAudienciaId}`;
        break;

      default:
        "";
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `XCampos/RecuperaListaLimitada?${query}`
    );

    return result;
  }

  async obterCampo(filtros: any, entidade: string): Promise<IRecuperaLista<any>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.campo) {
      query += `&campo=${filtros.campo}`;
    }

    if (entidade == ECamposIDEnum.Escritorio) {
      query += `&isTelaPesquisaProcesso=${filtros.isTelaPesquisaProcesso}`;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `${entidade}/Recupera${entidade}?${query}`
    );

    return result;
  }

  async recuperarCampos(query: any): Promise<IRecuperaLista<ICampoDinamico>> {
    let { data: result } = await api.get<IRecuperaLista<ICampoDinamico>>(
      `/Campos/RecuperaCampos?${query}`
    );
    return result;
  }

  async obterCampos(query: any): Promise<IRecuperaLista<ICampoDinamico>> {
    let { data: result } = await api.get<IRecuperaLista<ICampoDinamico>>(
      `/Campos/RecuperaCampos`,
      {
        params: query,
      }
    );
    return result;
  }

  async obterValoresCampo<T>(campo: ICampo): Promise<T[]> {
    const entidade = campo.entidade;
    const url = `${entidade}/Recupera${entidade}`;
    let { data: result } = await api.get<IRecuperaLista<T[]>>(url, {});
    return result.data as unknown as T[];
  }

  async adicionaConfiguracaoTela(
    adicionaConfiguracaoTela: AdicionaConfiguracaoTelaRequest
  ): Promise<IConfiguracaoTela> {
    let { data: result } = await api.post<IConfiguracaoTela>(
      `ConfiguracaoTela/AdicionaConfiguracaoTela`,
      adicionaConfiguracaoTela
    );
    return result;
  }

  async obterConfiguracaoTela(
    query: IRecuperaConfiguracaoTelaRequest
  ): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<
      IRecuperaLista<IRecuperaConfiguracaoTelaResponse>
    >(`ConfiguracaoTela/RecuperaConfiguracaoTela`, {
      params: query,
    });

    return result;
  }

  async obterPropriedadesTela(modulo: EModulo): Promise<IRecuperaLista<any>> {
    let query = "";

    if (modulo) {
      query += `&modulo=${modulo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<any>>(
      `PropriedadeConfiguracao/RecuperaPropriedadeConfiguracao?${query}`
    );

    return result;
  }

  async obterConfiguracaoTela2(
    query: IRecuperaConfiguracaoTelaRequest
  ): Promise<Modelo[]> {
    let { data: result } = await api.get<IRecuperaLista<ICampo>>(
      `ConfiguracaoTela/RecuperaConfiguracaoTela`,
      {
        params: query,
      }
    );

    let campos = (
      await this.obterCampos({
        Empresa:
          query.Modulo === EModulo.Societario && query.SubModulo === undefined,
      })
    ).data;

    let modelo: Modelo[] = result.data?.map((campo) => {
      let c = campos?.find((c) => c.campo === campo.campo);
      return {
        ...c,
        ...campo,
        codigo: campo.campo,
        controller: c?.controller || "",
        nome: c?.nome || campo.campoNome,
        tipo: c?.tipo || campo.tipo,
        label: campo.label,
      };
    });

    return modelo;
  }

  async obterTiposRelatorio(modulo: number) {
    const { data: result } = await api.get<IRecuperaLista<ITipoRelatorio>>(
      `Relatorio/RecuperaTipoRelatorio?modulo=${modulo}`
    );

    return result;
  }

  async obterCamposRelatorio(modulo: number, tipoRelatorioId: number) {
    const { data: result } = await api.get<IRecuperaLista<ICampoTipos>>(
      `Relatorio/RecuperaCamposRelatorio?modulo=${modulo}&tipoRelatorioId=${tipoRelatorioId}`
    );

    return result;
  }
}

export default new ConfiguracaoTelaService();
