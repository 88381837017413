export default class CookieUtils {

    static clearCookie() {
        let allCookies = document.cookie.split(';');
        for (let index = 0; index < allCookies.length; index++)
            document.cookie = allCookies[index] + "=;expires="
            + new Date(0).toUTCString();
    }

    static getCookie = async (key: string): Promise<string> => {
        const cookies = document.cookie;
        const cookieArray = cookies.split(";");
        for (let i = 0; i < cookieArray.length; i++) {
            const cookie = cookieArray[i].trim();
            const cookieKey = cookie.split("=")[0];
            const result: any = cookie.split("=")[1];
            if (cookieKey === key) {
                return result;
            }
        }
        return "";
    }
}