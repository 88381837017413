import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import IFile from "../../interfaces/IFile";

import ContratoService from "../../services/ContratoService";
import { faCheckCircle, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { TableColumn } from "react-data-table-component";
import GridPadrao from "../Comum/GridPadrao";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";
import { EModulo } from "../../enum";
import { FileDropzone } from "./DragDropZone";
import Carregando from "../Carregando";

interface IFileUploadCheckListFieldProps {
  modulo: EModulo,

  contratoId?: number;
  tipoContratoId?: number;

  procuracaoId?: number;
  tipoProcuracaoId?: number;

  consultaId?: number;
  tipoConsultaId?: number;

  tipoImovelId?: number;
  imovelId?: number;

  setQuantidadeArquivosSelecionados: any;
  toggleModal: () => void;
  exibirModal: boolean;

  campo?: number;

}

const IFileUploadCheckField = ({

  modulo,

  contratoId,
  tipoContratoId,

  procuracaoId,
  tipoProcuracaoId,

  tipoImovelId,
  imovelId,

  consultaId,
  tipoConsultaId,

  setQuantidadeArquivosSelecionados,
  toggleModal,
  exibirModal,

  campo,


}: IFileUploadCheckListFieldProps) => {
  const [carregandoTipoDocumento, setCarregandoTipoDocumento] = useState<boolean>(false);
  const [tipoDocumentos, setTipoDocumento] = useState<any[]>([]);
  const [nomeArquivo, setNomeArquivo] = useState<string>("");

  useEffect(() => { if (exibirModal) carregaTipoDocumento(); }, [exibirModal]);

  const adicionaArquivo = (event: any, tipoDocumentoId: number, nome: string) => {
    for (let index = 0; index < event.length; index++) {
      const currentFile = event[index];
      formik.values.push({
        index: formik.values.length,
        documentoId: 0,
        tipoDocumentoId: tipoDocumentoId,
        serRestriro: false,
        file: currentFile,
        nome: nome,
        extensao: currentFile.name.substring(currentFile.name.lastIndexOf(".") + 1),
      });
    }

    formik.values.forEach((file: IFile, index: number) => (file.index = index));

    formik.submitForm();
  };

  const iniciarUploadArquivos = async () => {
    formik.values.forEach(async (item: IFile) => {
      const enviados = [];

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Realizando upload...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      Swal.showLoading();

      try {
        const formData = new FormData();
        formData.append("origem", "1");
        formData.append("file", item.file);
        formData.append("tipoDocumentoId", item.tipoDocumentoId ? item.tipoDocumentoId.toString() : "1");
        formData.append("descricao", item.nome);
        formData.append("restrito", item.serRestriro.toString());

        if (contratoId) {
          formData.append("contratoId", contratoId.toString());
          formData.append("modulo", EModulo.Contrato.toString());
        }

        if (procuracaoId) {
          formData.append("procuracaoId", procuracaoId.toString());
          formData.append("modulo", EModulo.Procuracao.toString());
        }

        if (imovelId) {
          formData.append("imovelId", imovelId.toString());
          formData.append("modulo", EModulo.Imovel.toString());
        }

        if (consultaId) {
          formData.append("consultaId", consultaId.toString());
          formData.append("modulo", EModulo.Consultivo.toString());
        }

        enviados.push(await ContratoService.adicionaArquivo(formData));

        setQuantidadeArquivosSelecionados(0);

        Swal.hideLoading();

        await Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Upload realizado com sucesso!`,
          showConfirmButton: true,
          timer: 4000,
        });

        Swal.close();
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao realizar o upload dos arquivos`,
          text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
          showConfirmButton: true,
        });
      }
    });
  };

  const initialValues: IFile[] = [];

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      formik.setValues(values);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setQuantidadeArquivosSelecionados(formik.values.length)
  }, [formik.values.length]);

  useEffect(() => {
    if (contratoId) iniciarUploadArquivos();
  }, [contratoId]);

  useEffect(() => {
    if (imovelId) iniciarUploadArquivos();
  }, [imovelId]);

  useEffect(() => {
    if (procuracaoId) iniciarUploadArquivos();
  }, [procuracaoId]);

  useEffect(() => {
    if (consultaId) iniciarUploadArquivos();
  }, [consultaId]);

  const carregaTipoDocumento = async () => {
    try {

      setCarregandoTipoDocumento(true);

      let campos: any;

      if (tipoContratoId && modulo == EModulo.Contrato) {
        campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("tipodocumentochecklist", EModulo.Contrato, tipoContratoId, contratoId, 0, campo);
      }
      if (tipoImovelId && modulo == EModulo.Imovel) {
        campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("tipodocumentochecklist", EModulo.Imovel, tipoImovelId, imovelId, 0, campo);
      }
      if (tipoProcuracaoId && modulo == EModulo.Procuracao) {
        campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("tipodocumentochecklist", EModulo.Procuracao, tipoProcuracaoId, procuracaoId, 0, campo);
      }
      if (tipoConsultaId && modulo == EModulo.Consultivo) {
        campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo("tipodocumentochecklist", EModulo.Consultivo, tipoConsultaId, consultaId, 0, campo);
      }

      setTipoDocumento(campos.data);

    } catch (error: any) {
      setTipoDocumento([]);
    } finally {
      setCarregandoTipoDocumento(false);
    }
  };

  const colunas: TableColumn<IFile>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IFile) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Extensão",
      sortField: "extensao",
      selector: (row: IFile) => row.extensao,
      sortable: true,
      wrap: true,
    },
    {
      name: "Remover?",
      cell: (row: IFile) => {
        return (
          <div style={{ paddingRight: "10px" }}>
            <FontAwesomeIcon
              title="Remover?"
              onClick={async () => {
                const swalWithBootstrapButtons = Swal.mixin({
                  heightAuto: false,
                  customClass: {
                    confirmButton: "btn btn-danger ms-5",
                    cancelButton: "btn btn-orange ms-5",
                  },
                  buttonsStyling: false,
                });

                let result = await swalWithBootstrapButtons.fire({
                  title: "Cancelar",
                  text: `Você realmente deseja remover este arquivo?`,
                  showCancelButton: true,
                  cancelButtonText: "Não",
                  confirmButtonText: `Sim, desejo remover!`,
                });

                if (result.isConfirmed) {
                  formik.setValues(formik.values.filter((e) => e.index != row.index));
                }
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faTrash}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const adicionar = () => {
    formik.values.forEach((documento) => {
      if (!documento.file || documento.file.length == 0) {
        Swal.fire({
          heightAuto: false,
          icon: "info",
          text: `Por favor é necessário informar o documento ${documento.nome}`,
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }
    });

    toggleModal();
  };

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Importação de arquivos <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faCheckCircle} /> </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <div className="row">
            {carregandoTipoDocumento && <Carregando />}
            {tipoDocumentos.map((documento, index) => (
              <>
                {formik.values.filter((e) => e.tipoDocumentoId == documento.tipoDocumentoId).length == 0 && (
                  <>
                    <div className="col-md-2 mb-2">
                      <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                        Doc: {documento.tipo}
                      </label>
                      {documento.nome}
                    </div>

                    <div className="col-md-4 mb-3">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Descrição:
                      </label>
                      <input
                        placeholder="Opcional"
                        type="text"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid": formik.touched[index]?.nome && formik.errors[index]?.nome,
                          },
                          {
                            "is-valid": formik.touched[index]?.nome && !formik.errors[index]?.nome,
                          }
                        )}
                        id="form-nome"
                        onChange={(e: any) => setNomeArquivo(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                        Arquivo:
                      </label>
                      <FileDropzone
                        onDrop={(acceptedFiles) => {
                          adicionaArquivo(acceptedFiles, documento.tipoDocumentoId, nomeArquivo ? nomeArquivo : documento.nome);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            ))}
          </div>

          <div className="col-md-12">
            <GridPadrao paginationServer={true} colunas={colunas} tipo="Arquivos" itens={formik.values} />
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button onClick={() => adicionar()} type="button" className=" btn btn-orange ms-5">
              Adicionar
            </button>

            <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IFileUploadCheckField;
