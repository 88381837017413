import { useContext, useEffect, useRef, useState } from "react";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { EModulo } from "../../enum";

import { MultiSelect } from "react-multi-select-component";
import IProcesso from "../../interfaces/IProcesso";
import IAudiencia from "../../interfaces/IAudiencia";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaAudienciaRequest from "../../interfaces/Requests/Audiencia/IFiltroPesquisaAudienciaRequest";

import sessionStorageService from "../../services/sessionStorage/sessionStorageService";
import AudienciaService from "../../services/AudienciaService";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";

const AudienciaPage = () => {

  const [StatusOptions, setStatusOptions] = useState<any>([{ label: "Ativa", value: 1 }, { label: "Encerrada", value: -1 }]);
  const [StatusSelected, setStatusSelected] = useState([]);

  const [carregandoAudiencias, setCarregandoAudiencias] = useState(false);
  const [audiencias, setAudiencias] = useState<IAudiencia[]>([]);

  const { processo, setProcesso, setAreaDireito, audiencia, setAudiencia } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaAudienciaRequest>({
    modulo: EModulo.AreaDireito,
    codigo: '',

    dataAudienciaMaiorIgual: '',
    dataAudienciaMenorIgual: '',

    dataCadastroMaiorIgual: '',
    dataCadastroMenorIgual: '',

    nome: '',
    processoId: processo.processoId,

    status: [],

    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "audienciaId",
  });

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, status: IdsSelected };
    });
  }, [StatusSelected]);

  useEffect(() => {
    if (!processo.processoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [processo]);

  useEffect(() => {
    if (atualizarTabela) {
      setTimeout(() => {
        carregarAudiencias(filtrosPesquisa);
      }, 2000);
    }
  }, [atualizarTabela]);

  const inserirSessionStorage = () => {
    sessionStorageService.inserir(processo, "processo_audiencia");
    sessionStorageService.inserir(filtrosPesquisa, "filtro_audiencia");
  };

  const carregarSessionStorage = async () => {
    let processo: IProcesso = JSON.parse((await sessionStorageService.obter("processo_audiencia")) || null);
    setProcesso(processo);

    let filtro: FiltroPesquisaAudienciaRequest = JSON.parse((await sessionStorageService.obter("filtro_audiencia")) || null);
    setFiltrosPesquisa({ ...filtrosPesquisa, processoId: filtro.processoId });
  };

  const carregarAudiencias = async (filtro: FiltroPesquisaAudienciaRequest): Promise<void> => {
    try {

      setCarregandoAudiencias(true);

      let resultado: IRecuperaLista<IAudiencia>;

      resultado = await AudienciaService.obterAudiencias(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setAudiencias(resultado.data);

      inserirSessionStorage();

    } catch (error: any) {
      setAudiencias([]);
    } finally {
      setCarregandoAudiencias(false);
      setAtualizaTabela(false);
    }
  }

  const colunas: TableColumn<IAudiencia>[] = [
    {
      name: "Id",
      sortField: "audienciaId",
      cell: (row: IAudiencia) => row.audienciaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
      width: '100px'
    },
    {
      name: "Tipo de Audiência",
      sortField: "tipoAudienciaNome",
      cell: (row: IAudiencia) => row.tipoAudienciaNome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Data de Cadastro",
      sortField: "dataCadastro",
      cell: (row: IAudiencia) => moment(row.dataCadastro).format('DD/MM/YYYY'),
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Data da Audiência",
      sortField: "dataAudiencia",
      cell: (row: IAudiencia) => moment(row.dataaudiencia).format('DD/MM/YYYY'),
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Status",
      sortField: "status",
      cell: (row: IAudiencia) => row.status,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "",
      cell: (row: IAudiencia) => {
        return (
          <div>
            <FontAwesomeIcon
              title="Editar"
              onClick={() => abrirCapa(row)}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-2 text-orange"
              icon={faSearch}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAudiencia>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,

        dataAudienciaMaiorIgual: "",
        dataAudienciaMenorIgual: "",

        dataInicioVigenciaMaiorIgual: "",
        dataInicioVigenciaMenorIgual: "",

        dataCadastroMaiorIgual: '',
        dataCadastroMenorIgual: '',

        status: [],
        codigo: "",
        clienteId: 0,
        audienciaId: 0,
      };
    });
  }

  const abrirCapa = (row: IAudiencia) => {
    setAudiencia({
      ...row,
      audienciaId: row.audienciaId,
      tipoAudienciaId: row.tipoAudienciaId,
      categoriaId: row.tipoAudienciaId,

      modulo: audiencia.modulo,
      processoId: audiencia?.processoId,
      areaDireitoId: audiencia.areaDireitoId,

      status: row.status
    });
    navigate("/Audiencia/Capa");
  };

  return (
    <>
      <LayoutPageForButton title="Audiência">
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverSubMenuProcesso()}>
          <a style={{ cursor: "pointer", marginRight: "10px" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageForButton>

      <LayoutPageTitle title="">
        <button
          onClick={() => {
            setAudiencia({ ...audiencia, audienciaId: 0 });
            navigate("/Audiencia/Novo")
          }}
          className="btn btn-md btn-orange"
        >
          Novo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>


      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarAudiencias(filtrosPesquisa);
            }}
            className="row g-3 mb-3 form-buscar-audiencia"
          >

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">
                Data do Cadastro: {" "}
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={
                  filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                    ? [new Date(filtrosPesquisa.dataCadastroMaiorIgual), new Date(filtrosPesquisa.dataCadastroMenorIgual)]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: "",
                      dataCadastroMenorIgual: "",
                    }));
                    return;
                  }

                  const [data_inicial, data_final] = datas;

                  setFiltrosPesquisa((oldValue) => ({
                    ...oldValue,
                    dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                    dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                  }));
                }}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-dataAudiencia" className="form-label fw-bolder text-orange">
                Data da Audiência: {" "}
              </label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={
                  filtrosPesquisa.dataAudienciaMaiorIgual && filtrosPesquisa.dataAudienciaMenorIgual
                    ? [new Date(filtrosPesquisa.dataAudienciaMaiorIgual), new Date(filtrosPesquisa.dataAudienciaMenorIgual)]
                    : ""
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa((oldValue) => ({
                      ...oldValue,
                      dataAudienciaMaiorIgual: "",
                      dataAudienciaMenorIgual: "",
                    }));
                    return;
                  }

                  const [data_inicial, data_final] = datas;

                  setFiltrosPesquisa((oldValue) => ({
                    ...oldValue,
                    dataAudienciaMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                    dataAudienciaMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                  }));
                }}
              />
            </div>


            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                  isLoading={false}
                  disabled={false}
                  options={StatusOptions}
                  value={StatusSelected}
                  onChange={(event: any) => setStatusSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
            </div>

            <div className="col-12 " style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-orange search-buttom-margin " title="Buscar">
                <FontAwesomeIcon color="white" className="" icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro"
              >
                <FontAwesomeIcon color="white" className="" icon={faEraser} />
              </button>
            </div>

          </form>
        </div>

        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoAudiencias}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Audiencias"
            itens={audiencias}
          />
        </div>
      </div>
    </>
  );
};

export default AudienciaPage;
