class sessionStorageService {
  async inserir(value: any, chave: string) {
    sessionStorage.setItem(chave, JSON.stringify(value));
  }
  async obter(chave: string): Promise<any> {
    return sessionStorage.getItem(chave);
  }
  async remove(chave: string): Promise<any> {
    return sessionStorage.removeItem(chave);
  }
} 
export default new sessionStorageService();
