import IAdvogado from "../interfaces/IAdvogado";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaAdvogadoRequest from "../interfaces/Requests/FiltroPesquisaAdvogadoRequest";
import api from "./Api";

class AdvogadoService {
  async obterAdvogadosPorFiltro(filtros: FiltroPesquisaAdvogadoRequest): Promise<IRecuperaLista<IAdvogado>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${
      filtros.sort
    }`;

    if (filtros.tipoPesquisa === 1 && filtros.filtro.length > 0) {
      query += `&nome=${filtros.filtro}`;
    }

    if (filtros.tipoPesquisa === 2 && filtros.filtro.length > 0) {
      query += `&documento=${filtros.filtro}`;
    }

    if (filtros.tipoPesquisa === 3 && filtros.filtro.length > 0) {
      query += `&oab=${filtros.filtro}`;
    }

    let { data: result } = await api.get<IRecuperaLista<IAdvogado>>(`Advogado/RecuperaAdvogado?${query}`);

    return result;
  }

  async adicionaAdvogado(adicionaAdvogado: any): Promise<any> {
    let { data: result } = await api.post<any>(`Advogado/AdicionaAdvogado`, adicionaAdvogado);

    return result;
  }
}

export default new AdvogadoService();
