import { Element } from "slate";
import { CustomEditor, CustomElement, HeaderElement, PageElement } from "../../../../../utils/types/slate";
import { CommonService } from "./commons";
import { pxToPt } from "../../../components/Toolbar/controls/common";
import { ReactEditor } from "slate-react";

export const HeaderService = {
  defaultStyle: {
    padding: "35.4pt"
  },
  makeHeader(): HeaderElement {
    return {
      id: 'header_component_0',
      type: "header",
      children: [CommonService.makeParagraph()],
    };
  },
  getHeaderChildrens(header: HeaderElement): CustomElement[] | null {
    const childs: CustomElement[] = [];
    
    if (Element.isElement(header)) {
      header.children.forEach(child => Element.isElement(child) && childs.push(child))
    }

    return childs
  },
  getExistentHeader(editor: CustomEditor): HeaderElement | null {
    const firstElement = editor.children[0];

    if (Element.isElement(firstElement) && firstElement.type === "page") {
      const header = firstElement.children.find(
        (child) => Element.isElement(child) && child.type === "header"
      );
      return header ? (header as HeaderElement) : null;
    }

    return null;
  },
  addHeader(editor: CustomEditor) {
    const existentHeader = this.getExistentHeader(editor);

    if (existentHeader) {
      const { findElementPath, focus } = CommonService;
      const path = findElementPath(editor, existentHeader);
      focus(editor, path);
      return;
    }

    editor.children.forEach((child) => {
      if (Element.isElement(child) && child.type === "page") {
        const path = CommonService.findElementPath(editor, child)?.concat(0);
        const header = this.makeHeader();
        CommonService.addElement(editor, header, path);
      }
    });

    const selectionPath = editor.selection?.anchor.path[0] ?? 0
    CommonService.focus(editor, [selectionPath])
  },
  removeHeader(editor: CustomEditor, element: HeaderElement) {
    CommonService.removeElement(editor, element)
  },
  getHeight() {
    const element = document.getElementById('header-component')
    if (!element) { return 0 }
    const { height } = element.getBoundingClientRect()
    return pxToPt(height)
  },
  formatHeaderChildren(header: HeaderElement): HeaderElement {
    const paragraph = CommonService.makeParagraph()

    return {
      ...header,
      children: header.children.filter((child) => Element.isElement(child)).concat(paragraph),
    };
  },
  getHtmlHeaderByPage(editor: CustomEditor, page: number) {
    const pageElement = editor.children[page] as PageElement
    const header = pageElement.children[0] as HeaderElement
    return ReactEditor.toDOMNode(editor, header)
  }
};
