import { useEffect, useState } from "react";
import { faEraser, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import XPayFechamentoModal from "./XPayFechamentoModal";
import Swal from "sweetalert2";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";
import IXPayFechamento from "../../interfaces/IXPayFechamento";
import XPayFechamentoService from "../../services/XPayFechamentoService";
import FiltroPesquisaXPayFechamentoRequest from "../../interfaces/Requests/XPayFechamento/FiltroPesquisaXPayFechamentoRequest";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useNavigate } from "react-router-dom";
import EscritorioService from "../../services/EscritorioService";
import { MultiSelect } from "react-multi-select-component";
import IEscritorio from "../../interfaces/IEscritorio";


const xPayFechamentoInicial: IXPayFechamento = {
  xPayFechamentoId: 0,
  tipoCentroCustoId: 0,
  clienteId: 0,
  codigo: "",
  dataCadastro: "",
  dataCorte: "",
  dataPagamento: "",
  dataVencimento: "",
  escritorioId: 0,
  escritorioNome: "",
  modulo: "",
  numeroNota: "",
  status: "",
  tipoFechamento: "",
  valorBruto: 0,
  valorDesconto: 0,
  valorLiquido: 0
};

const XPayFechamentoPage = () => {
  const [xPayFechamento, setXPayFechamento] = useState<IXPayFechamento>(xPayFechamentoInicial);
  const [xPayFechamentos, setXPayFechamentos] = useState<IXPayFechamento[]>([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoXPayFechamentos, setCarregandoXPayFechamentos] = useState(false);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [atualizarTabela, setAtualizaTabela] = useState<boolean>(false);

  const [escritorio, setEscritorio] = useState<any>([]);
  const [escritorioSelecionados, setEscritorioSelecionados] = useState([]);
  const [carregandoEscritorio, setCarregandoEscritorio] = useState<boolean>(false);

  const navigate = useNavigate();


  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaXPayFechamentoRequest>({
    xPayfechamentoId: 0,
    escritorioId: [],
    modulo: [],
    tipoFechamento: 0,
    dataPagamentoMaiorIgual: "",
    dataPagamentoMenorIgual: "",
    dataVencimentoMaiorIgual: "",
    dataVencimentoMenorIgual: "",
    dataCorteMaiorIgual: "",
    dataCorteMenorIgual: "",
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    status: 0,
    limit: 10,
    totalItems: 0,
    offset: 0,
    sort: "xPayFechamentoId",
  });

  function toggleFormModal(carregarDados?: boolean): void {
    if (carregarDados) {
      carregarXPayFechamentos(filtrosPesquisa);
    }

    setExibirModal(!exibirModal);

    setTimeout(() => {
      setXPayFechamento(xPayFechamentoInicial);
    }, 300);
  }

  async function carregarXPayFechamentos(filtro: FiltroPesquisaXPayFechamentoRequest) {
    try {
      setCarregandoXPayFechamentos(true);
      let resultado: IRecuperaLista<IXPayFechamento>;

      resultado = await XPayFechamentoService.obterXPayXPayFechamentos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setXPayFechamentos(resultado.data);
      setAtualizaTabela(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setXPayFechamentos([]);
    } finally {
      setCarregandoXPayFechamentos(false);
      setLoadingInicial(false);
    }
  }

  const colunas: TableColumn<IXPayFechamento>[] = [
    {
      name: "Id",
      sortField: "xPayFechamentoId",
      selector: (row: IXPayFechamento) => row.xPayFechamentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Tipo de Fechamento",
      sortField: "tipoFechamento",
      selector: (row: IXPayFechamento) => row.tipoFechamento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Escritório",
      sortField: "escritorioNome",
      selector: (row: IXPayFechamento) => row.escritorioNome,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Status',
      sortField: 'status',
      selector: (row: IXPayFechamento) => row.status,
      sortable: true,
      wrap: true
    },
    {
      name: 'Data Corte',
      sortField: 'dataCorte',
      selector: (row: IXPayFechamento) => {
        const data = row.dataCorte || ''
        if (data.includes('T')) {
          const [ano, mes, dia] = data.split('T')[0].split('-')
          return `${dia}/${mes}/${ano}`
        }
        return '-'
      },
      sortable: true,
      wrap: true
    },
    {
      name: 'Data Cadastro',
      sortField: 'dataCadastro',
      selector: (row: IXPayFechamento) => {
        const data = row.dataCadastro || ''
        if (data.includes('T')) {
          const [ano, mes, dia] = data.split('T')[0].split('-')
          return `${dia}/${mes}/${ano}`
        }
        return '-'
      },
      sortable: true,
      wrap: true
    }
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IXPayFechamento>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const carregarEscritorio = async () => {
    try {
      if (escritorio.length > 0) return;
      setCarregandoEscritorio(true);
      let resultado: IRecuperaLista<IEscritorio>;
      resultado = await EscritorioService.obterEscritorios({
        nome: "",
        clienteId: 0,
        isTelaPesquisaProcesso: true,
        codigo: "",
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setEscritorio(returnOptions(resultado.data, "escritorioId", "escritorio"));
      setCarregandoEscritorio(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregandoEscritorio(false);
      setEscritorio([]);
    }
  }

  useEffect(() => {
    carregarEscritorio();
  }, []);

  const returnOptions = (array: any, propId: string, arrayName: string) => {
    array.forEach((item: any) => {
      eval(`${arrayName}`).push({
        value: eval(`item.${propId}`),
        label: item.nome,
      });
    });
    return eval(`${arrayName}`);
  };

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    switch (entidade) {
      case "moduloId":
        setFiltrosPesquisa({ ...filtrosPesquisa, modulo: IdsSelected });
        break;
      case "escritorioId":
        setFiltrosPesquisa({ ...filtrosPesquisa, escritorioId: IdsSelected });
        break;
    }
  };

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        status: 0,
        dataCadastroMaiorIgual: '',
        dataCadastroMenorIgual: '',
        dataCorteMaiorIgual: '',
        dataCorteMenorIgual: '',
        dataPagamentoMaiorIgual: '',
        dataPagamentoMenorIgual: '',
        dataVencimentoMaiorIgual: '',
        dataVencimentoMenorIgual: '',
        tipoFechamento: 0,
        modulo: []
      };
    });
    setEscritorio([]);
  }

  useEffect(() => {
    if (!loadingInicial) {
      carregarXPayFechamentos(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    if (atualizarTabela) setTimeout(() => carregarXPayFechamentos(filtrosPesquisa), 1000);
  }, [atualizarTabela]);

  useEffect(() => {
    setLoadingInicial(true);
  }, []);

  return (
    <>
      <LayoutPageTitle title="XPayFechamento">
        <div>
          <button onClick={() => {
            setXPayFechamento(xPayFechamentoInicial);
            setExibirModal(true);
          }} className="btn btn-md btn-orange">
            Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
          </button>
        </div>
      </LayoutPageTitle>

      <XPayFechamentoModal
        toggleModal={toggleFormModal}
        exibirModal={exibirModal}
        setAtualizarTabela={setAtualizaTabela}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarXPayFechamentos(filtrosPesquisa);
            }}
            className="row g-3 mb-3">

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-cadastro" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  (filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual) ?
                    [
                      filtrosPesquisa.dataCadastroMaiorIgual,
                      filtrosPesquisa.dataCadastroMenorIgual,
                    ] : ''
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCadastroMaiorIgual: '',
                      dataCadastroMenorIgual: '',
                    }));
                  }
                }}

              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-corte" className="form-label fw-bolder text-orange">Data de Corte:</label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  (filtrosPesquisa.dataCorteMaiorIgual && filtrosPesquisa.dataCorteMenorIgual) ?
                    [
                      filtrosPesquisa.dataCorteMaiorIgual,
                      filtrosPesquisa.dataCorteMenorIgual,
                    ] : ''
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCorteMaiorIgual: '',
                      dataCorteMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCorteMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                      dataCorteMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataCorteMaiorIgual: '',
                      dataCorteMenorIgual: '',
                    }));
                  }
                }} />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-data-vencimento" className="form-label fw-bolder text-orange">Data de Vencimento:</label>
              <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                  (filtrosPesquisa.dataVencimentoMaiorIgual && filtrosPesquisa.dataVencimentoMenorIgual) ?
                    [
                      filtrosPesquisa.dataVencimentoMaiorIgual,
                      filtrosPesquisa.dataVencimentoMenorIgual,
                    ] : ''
                }
                onChange={(datas: Date[]) => {
                  if (!datas || datas.length === 0) {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataVencimentoMaiorIgual: '',
                      dataVencimentoMenorIgual: '',
                    }));
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];

                  const isValidDate = (date: any) => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;

                  if (isValidDate(data_inicial)) {
                    if (isValidDate(data_final)) {
                      data_final.setHours(0, 0, 0, 0);
                      data_final = new Date(data_final.getTime() - 1);
                    }

                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataVencimentoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split('T')[0]}T00:00:00.0000` : '',
                      dataVencimentoMenorIgual: data_final ? `${data_final.toISOString().split('T')[0]}T23:59:59.9999` : '',
                    }));
                  } else {
                    setFiltrosPesquisa(oldValue => ({
                      ...oldValue,
                      dataVencimentoMaiorIgual: '',
                      dataVencimentoMenorIgual: '',
                    }));
                  }
                }} />

            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-status" className="form-label fw-bolder text-orange">Status:</label>
              <select value={filtrosPesquisa.status} onChange={(e) => {
                setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
              }} placeholder="Status" className={'form-select'} id="form-status" >
                <option value={0}>Todos</option>
                <option value={1}>Em Validação</option>
                <option value={2}>Em Aprovação da Nota</option>
                <option value={3}>Pago</option>
                <option value={4}>Cancelado</option>
              </select>
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-tipoFechamento" className="form-label fw-bolder text-orange">Tipo de Fechamento:</label>
              <select value={filtrosPesquisa.tipoFechamento} onChange={(e) => {
                setFiltrosPesquisa(oldState => { return { ...oldState, tipoFechamento: parseInt(e.target.value) } });
              }} placeholder="Tipo de Fechamento" className={'form-select'} id="form-tipoFechamento" >
                <option value={0}>Todos</option>
                <option value={1}>Honorário</option>
                <option value={2}>Reembolso</option>
              </select>
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="form-escritorio" className="form-label fw-bolder text-orange">
                Escritório:
              </label>
              <MultiSelect
                isLoading={carregandoEscritorio}
                disabled={carregandoEscritorio}
                options={escritorio}
                value={escritorioSelecionados}
                onChange={(event: any) => {
                  setEscritorioSelecionados(event);
                  setIdsSelect("escritorioId", event);
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)",
                }}
              />
            </div>

            <div className="col-12 "
              style={{ textAlign: 'right' }}>
              <button
                type="submit"
                className="btn btn-orange search-buttom-margin "
                title="Buscar">
                <FontAwesomeIcon color='white' className='' icon={faSearch} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  limparFiltros();
                }}
                className="btn btn-orange search-buttom-margin ms-2"
                title="Limpar Filtro">
                <FontAwesomeIcon color='white' className='' icon={faEraser} />
              </button>
            </div>

          </form>
        </div>

        <div className="col-md-12 mb-10">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoXPayFechamentos}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="XPayFechamentos"
            itens={xPayFechamentos}
          />
        </div>

      </div>
    </>
  );
};

export default XPayFechamentoPage;
