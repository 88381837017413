import clsx from "clsx";
import { useEffect, useState } from "react";
import { Form, Modal, ProgressBar } from "react-bootstrap";
import GridPadrao from "./../GridPadrao";
import { TableColumn } from "react-data-table-component";
import { faBackward, faEye, faPercent, faTimes } from "@fortawesome/free-solid-svg-icons";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

import { routePropriedade, tipoPorModulo } from "../../../utils/Utils";
import { ECamposIDEnum, EModulo, EPropriedadesIDEnum } from "../../../enum";

import CamposService from "../../../services/CamposService";
import EmpresaService from "../../../services/EmpresaService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import IEmpresa from "../../../interfaces/IEmpresa";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import IGestaoProcesso from "../../../interfaces/IGestaoProcesso";
import ISignatarioPapel from "../../../interfaces/ISignatarioPapel";
import IFormaParticipacao from "../../../interfaces/IFormaParticipacao";
import FiltroPesquisaEmpresaRequest from "../../../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest";

import Carregando from "../../Carregando";
import CurrencyField from "../CurrencyBRL/CurrencyField";
import { notifySuccess, notifyWarning } from "../Toast/ToastFormik";
import { empresaFilialInitialValues } from "../../../contexts/InitialValuesContext";
import IPapelSignatario from "../../../interfaces/IPapelSignatario";

interface IEmpresaFieldProps {
  formik: any;
  toggleModal: () => void;
  exibirModal: boolean;
  capa?: boolean
  setRefreshCapa?(refresh: boolean): void;
}

const EmpresaField = ({ toggleModal, formik, exibirModal, capa, setRefreshCapa }: IEmpresaFieldProps) => {

  const [empresa, setEmpresa] = useState<IEmpresa>(empresaFilialInitialValues);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);

  const [carregandoPropriedades, setCarregandoPropriedades] = useState<boolean>(false);

  const [carregandoEmpresas, setCarregandoEmpresas] = useState(false);
  const [formaParticipacao, setFormaParticipacao] = useState<IFormaParticipacao[]>([]);
  const [papelSignatario, setPapelSIgnatario] = useState<ISignatarioPapel[]>([]);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEmpresaRequest>({
    filtro: "",
    tipoPesquisa: 1,
    status: 1,
    grupoEconomicoId: [],
    modeloRelatorioId: 0,
    limit: 200,
    totalItems: 0,
    offset: 0,
    sort: "empresaId",
  });


  const [exibirRateio, setExibirRateio] = useState(false);
  const [habilitarRateio, setHabilitarRateio] = useState(false);
  const [percentualTotal, setPercentualTotal] = useState<number>(0);
  const [carregandoGestores, setCarregandoGestores] = useState(false);
  const [gestaoProcesso, setGestaoProcesso] = useState<IGestaoProcesso[]>([]);

  const scrollToTop = () => {
    const modalBody = document.querySelector('.modal-body');
    if (modalBody) {
      modalBody.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (!carregandoEmpresas && exibirModal) {
      carregarEmpresas(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  useEffect(() => {
    if (empresa?.empresaId && exibirModal) {
      carregarFilial({ ...filtrosPesquisa, empresaMatrizId: empresa.empresaId });
    }
  }, [empresa?.empresaId]);

  useEffect(() => {
    if (exibirModal) {

      if (formik.values?.grupoEconomicoId && filtrosPesquisa?.grupoEconomicoId) {
        filtrosPesquisa.grupoEconomicoId.push(formik.values?.grupoEconomicoId);
      } else {
        filtrosPesquisa.grupoEconomicoId = []
      }

      if (formik.values.modulo == EModulo.Contrato) {
        carregarPapelSignatario();
      }
      else {
        carregarFormaParticipacao();
        carregarGestaoProcesso();
        carregarPropriedadesTela();
      }

      if (empresas?.length == 0) {
        carregarEmpresas(filtrosPesquisa);
      }
    }
  }, [exibirModal]);

  const marcarPrincipal = (empresaId: number) => {
    if (isValid(empresaId)) {
      let empresaSelected = formik?.values?.empresasList.find((e: IEmpresa) => e.empresaId == empresaId);
      const updatedEmpresas = empresas.map(empresa => {
        if (empresa.empresaId === empresaId) {
          return {
            ...empresa,
            
            checked: true,
            principal: true,

            formaParticipacaoId: empresaSelected?.formaParticipacaoId,
            formaParticipacao: empresaSelected?.formaParticipacao,
            papelSignatarioId: empresaSelected?.papelSignatarioId,
            papelSignatario: empresaSelected?.papelSignatario,
          };
        } else {
          return {
            ...empresa,
            principal: false,
          };
        }
      });

      setEmpresas(updatedEmpresas);

      adicionarEmpresasList(updatedEmpresas);

    }
  };

  const adicionar = (empresaId: number) => {
    if (isValid(empresaId)) {

      const updatedEmpresas = empresas.map(empresa => {
        if (empresa.empresaId === empresaId) {
          return {
            ...empresa,
            checked: !empresa.checked,
            principal: !empresa.checked ? false : empresa.principal,
          };
        }
        return empresa;
      });

      setEmpresas(updatedEmpresas);

      adicionarEmpresasList(updatedEmpresas);

    }
  };

  const isValid = (empresaId: number): boolean => {
    const empresa = empresas.find(e => e.empresaId === empresaId);
    if (!empresa) return false;

    let errors = [];
    if (habilitarRateio && formik.values.modulo === EModulo.AreaDireito) {
      if (!empresa.gestaoProcessoId) {
        errors.push('Por gentileza selecionar o gestor.');
      }
      if (!empresa.percentual) {
        errors.push('Por gentileza preencher o percentual.');
      }
    }
    if (errors.length > 0) {
      errors.forEach(error => console.log(error));
      return false;
    }
    return true;
  }

  const preencherMarcacoes = (empresas: IEmpresa[], isFilial?: boolean) => {
    if (empresas.length > 0 && (formaParticipacao.length > 0 || papelSignatario.length > 0)) {
      empresas.forEach((empresa) => {
        const empresaChecked = formik.values.empresasList?.filter((e: IEmpresa) => e.empresaId === empresa.empresaId)[0];

        empresa.isFilial = isFilial;

        if (empresaChecked && empresaChecked.expandir) empresa.expandir = true; else empresa.expandir = false;

        // adicionar os check de seleção
        if (empresaChecked && empresaChecked.checked) empresa.checked = true; else empresa.checked = false;

        // adicionar o check de principal
        if (empresaChecked && empresaChecked.principal) empresa.principal = true; else empresa.principal = false;


        // adicionar papel signatario com valor default
        if (formik.values.modulo == EModulo.Contrato) {
          empresa.papelSignatario = empresaChecked?.papelSignatario ? empresaChecked?.papelSignatario : '';
          empresa.papelSignatarioId = empresaChecked?.papelSignatarioId ? empresaChecked?.papelSignatarioId : 0;
        }

        // adicionar as formas de participação e demais do formik.values.modulo processo
        if (formik.values.modulo == EModulo.AreaDireito) {
          empresa.percentual = formik.values.empresasList?.filter((e: IEmpresa) => e.empresaId === empresa.empresaId)[0]?.percentual;
          empresa.percentualMask = formik.values.empresasList?.filter((e: IEmpresa) => e.empresaId === empresa.empresaId)[0]?.percentualMask;

          empresa.gestaoProcessoNome = empresaChecked?.gestaoProcessoNome ? empresaChecked?.gestaoProcessoNome : '';
          empresa.gestaoProcessoId = empresaChecked?.gestaoProcessoId ? empresaChecked?.gestaoProcessoId : 0;

          empresa.formaParticipacao = empresaChecked?.formaParticipacao ? empresaChecked?.formaParticipacao : '';
          empresa.formaParticipacaoId = empresaChecked?.formaParticipacaoId ? empresaChecked?.formaParticipacaoId : 0;
        }
      });
    }
    return empresas;
  }

  const carregarPropriedadesTela = async () => {
    try {
      setCarregandoPropriedades(true);
      let resultado = await ConfiguracaoTelaService.obterPropriedadesTela(formik.values.modulo);
      setExibirRateio(resultado.data?.find(e => e.codigo == EPropriedadesIDEnum.GestorEmpresa));
    } catch (error) {
      setExibirRateio(false);
    } finally {
      setCarregandoPropriedades(false);
    }
  };

  const carregarFormaParticipacao = async () => {
    try {
      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values.modulo)}Id`] = formik.values.categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.FormaParticipacao,
        formik.values.modulo,
        objeto[`${tipoPorModulo(formik.values.modulo)}Id`],
        0);
      setFormaParticipacao(resultado.data);
    } catch (error: any) {
      setFormaParticipacao([]);
    }
  }

  const carregarPapelSignatario = async () => {
    try {

      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values.modulo)}Id`] = formik.values.categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.PapelSignatario,
        formik.values.modulo,
        objeto[`${tipoPorModulo(formik.values.modulo)}Id`],
        0);

      setPapelSIgnatario(resultado.data);
    } catch (error: any) {
      setPapelSIgnatario([]);
    }
  }

  const carregarGestaoProcesso = async () => {
    try {

      setGestaoProcesso([]);

      setCarregandoGestores(true);

      let objeto: any = {};
      objeto[`${tipoPorModulo(formik.values.modulo)}Id`] = formik.values.categoriaId;

      let resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(
        ECamposIDEnum.GestaoProcesso,
        formik.values.modulo,
        objeto[`${tipoPorModulo(formik.values.modulo)}Id`],
        0);

      setGestaoProcesso(resultado.data);

    } catch (error: any) {
      setGestaoProcesso([]);
    } finally {
      setCarregandoGestores(false);
    }
  };

  const carregarEmpresas = async (filtro: FiltroPesquisaEmpresaRequest) => {
    try {

      setCarregandoEmpresas(true);

      let resultado: IRecuperaLista<IEmpresa>;


      resultado = await EmpresaService.obterEmpresa(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setEmpresas(preencherMarcacoes(resultado.data));

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoEmpresas(false);
    }
  };

  const carregarFilial = async (filtro: FiltroPesquisaEmpresaRequest) => {
    try {


      let filiais: IEmpresa[] = [];
      setCarregandoEmpresas(true);

      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterFiliais(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      filiais = preencherMarcacoes(resultado.data, true);

      if (filiais?.length > 0) {
        filiais.forEach(filial => empresas.push(filial));
        setEmpresas(empresas);
        notifyWarning("Empresa possui filiais para seleção");
        scrollToTop();
      } else {
        setEmpresas(empresas.filter(e => !e.isFilial));
        notifyWarning("Não existe filiais para empresa selecionada.");
      }

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setCarregandoEmpresas(false);
      scrollToTop();
    }
  };

  const adicionarEmpresasList = (updatedList: IEmpresa[]) => {

    const checkedEmpresas = updatedList.filter((e: IEmpresa) => e.checked);

    // Agora, eliminamos duplicatas baseadas no 'documento'
    const uniqueEmpresas = checkedEmpresas.filter((empresa: IEmpresa, index: number, self: any) =>
      index === self.findIndex((t: IEmpresa) => (
        t.numeroDocumento === empresa.numeroDocumento
      ))
    );

    formik.setFieldValue("empresasList", uniqueEmpresas, true);
  };

  const updateEmpresasList = (updatedEmpresa: IEmpresa) => {
    const updatedList = formik.values.empresasList.filter((e: IEmpresa) => e.empresaId !== updatedEmpresa.empresaId);

    updatedList.push(updatedEmpresa);

    adicionarEmpresasList(updatedList);
  };

  const atribuirFormaPagamentoId = (empresa: IEmpresa, value: any) => {
    if (formik.values.modulo === EModulo.AreaDireito) {

      empresa.formaParticipacaoId = value;
      empresa.formaParticipacao = formaParticipacao?.find(e => e.formaParticipacaoId == value)?.nome || '';
      empresa.checked = !empresa.formaParticipacaoId ? false : true;
      empresa.principal = !empresa.formaParticipacaoId ? false : empresa.principal;

      const updatedEmpresa = {
        ...empresa,
        formaParticipacaoId: value,
        checked: !empresa.formaParticipacaoId ? false : true,
        principal: !empresa.formaParticipacaoId ? false : empresa.principal,
        formaParticipacao: formaParticipacao.find((e: IFormaParticipacao) => e.formaParticipacaoId === value)?.nome || ''
      };
      updateEmpresasList(updatedEmpresa);
    }

    if (formik.values.modulo === EModulo.Contrato) {

      empresa.papelSignatarioId = value;
      empresa.papelSignatario = papelSignatario?.find(e => e.papelSignatarioId == value)?.nome || '';
      empresa.checked = !empresa.papelSignatarioId ? false : true;
      empresa.principal = !empresa.formaParticipacaoId ? false : empresa.principal;

      const updatedEmpresa = {
        ...empresa,
        papelSignatarioId: value,
        checked: !empresa.papelSignatarioId ? false : true,
        principal: !empresa.formaParticipacaoId ? false : empresa.principal,
        papelSignatario: papelSignatario.find((e: IPapelSignatario) => e.papelSignatarioId === value)?.nome || ''
      };
      updateEmpresasList(updatedEmpresa);
    }
  };

  const atribuirGestorId = (empresa: IEmpresa, value: any) => {
    empresa.gestaoProcessoId = value;
    empresa.gestaoProcessoNome = gestaoProcesso?.find(e => e.gestaoProcessoId == value)?.nome || '';
    empresa.checked = !empresa.gestaoProcessoId ? false : true;
    const updatedEmpresa = {
      ...empresa,
      gestaoProcessoId: value,
      checked: !empresa.gestaoProcessoId ? false : true,
      gestaoProcessoNome: gestaoProcesso.find(e => e.gestaoProcessoId === value)?.nome || ''
    };
    updateEmpresasList(updatedEmpresa);
  };

  const calcularTotal = () => {
    let percentualTotal = 0;
    empresas.forEach((empresa) => {
      percentualTotal += empresa.percentual ? empresa.percentual : 0;
    });

    setPercentualTotal(percentualTotal);
  };

  const onChangePercentualValue = (index: number, values: any) => {
    let percentual = parseFloat(values.formattedValue.replace("R$", "").replace(",", "."));
    if ((values.floatValue / 100) > 1000) {
      empresas[index].percentual = 0;
      empresas[index].percentualMask = "";
    } else {
      empresas[index].percentual = percentual;
      empresas[index].percentualMask = values.formattedValue;
      calcularTotal();
    }
  }

  const colunasComPapel: TableColumn<IEmpresa>[] = [
    {
      name: "Filiais?",
      cell: (row: IEmpresa) => (
        <div>
          {row.expandir ?

            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faBackward}
              onClick={() => onRowClicked(row, false)}
            />
            :
            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faEye}
              onClick={() => onRowClicked(row, true)}
            />
          }
        </div>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.nomeFantasia}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Documento",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.numeroDocumento}</p>
          </div>
        );
      },
      ignoreRowClick: true,
    },
    {
      name: "Forma de Participação *",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <>{(formik.values.modulo === 1) &&
            <div>
              <select
                name="formaParticipacaoId"
                onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                className={"form-control"}

                id="form-formaParticipacaoId"
                disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
                value={row?.formaParticipacaoId}
              >
                <option key={0} value={0}>Selecione</option>
                {formaParticipacao.map((map) => {
                  return (
                    <option key={map?.formaParticipacaoId} value={map?.formaParticipacaoId}>
                      {map?.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          }
            {(formik.values.modulo === EModulo.Contrato) &&
              <div>
                <select
                  name="papelSignatarioId"
                  onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                  className={"form-control"}
                  id="form-papelSignatarioId"
                  disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
                  value={row.papelSignatarioId}
                >
                  <option key={0} value={0}>Selecione</option>
                  {papelSignatario.map((map) => {
                    return (
                      <option key={map?.papelSignatarioId} value={map?.papelSignatarioId}>
                        {map?.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
            }
          </>
        );
      },
    },
    {
      name: "Principal?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0) || (formik.values.modulo === EModulo.Contrato && !row.papelSignatarioId) || (formik.values.modulo === EModulo.AreaDireito && !row.formaParticipacaoId)}
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <Form.Check
              type="switch"
              id="custom-switch"
              style={{ cursor: 'pointer' }}
              checked={row.checked}
              disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0) || (formik.values.modulo === EModulo.Contrato && !row.papelSignatarioId) || (formik.values.modulo === EModulo.AreaDireito && !row.formaParticipacaoId)}
              onClick={() => adicionar(row.empresaId)}
            />
          </div>
        );
      },
    },
  ];

  const colunasSemPapel: TableColumn<IEmpresa>[] = [
    {
      name: "Filiais?",
      cell: (row: IEmpresa) => (
        <div>
          {row.expandir ?

            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faBackward}
              onClick={() => onRowClicked(row, false)}
            />
            :
            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faEye}
              onClick={() => onRowClicked(row, true)}
            />
          }
        </div>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IEmpresa) => row.nomeFantasia,
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.numeroDocumento}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Principal?",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <Form.Check
              type="switch"
              id="custom-switch"
              style={{ cursor: 'pointer' }}
              checked={row.checked}
              disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
              onClick={() => adicionar(row.empresaId)}
            />
          </div>
        );
      },
    },
  ];

  const colunasRateioComPapel: TableColumn<IEmpresa>[] = [
    {
      name: "Filiais?",
      cell: (row: IEmpresa) => (
        <div>
          {row.expandir ?

            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faBackward}
              onClick={() => onRowClicked(row, false)}
            />
            :
            <FontAwesomeIcon
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-1 text-orange"
              icon={faEye}
              onClick={() => onRowClicked(row, true)}
            />
          }
        </div>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.nomeFantasia}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Documento",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.numeroDocumento}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Forma de Participação *",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <>{(formik.values.modulo === 1) &&
            <div>
              <select
                name="formaParticipacaoId"
                onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                className={"form-control"}
                id="form-formaParticipacaoId"
                disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
                value={row?.formaParticipacaoId}
              >
                <option key={0} value={0}>Selecione</option>
                {formaParticipacao.map((map) => {
                  return (
                    <option key={map?.formaParticipacaoId} value={map?.formaParticipacaoId}>
                      {map?.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          }
            {(formik.values.modulo === EModulo.Contrato) &&
              <div>
                <select
                  name="papelSignatarioId"
                  onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                  className={"form-control"}
                  id="form-papelSignatarioId"
                  disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
                  value={row.papelSignatarioId}
                >
                  <option key={0} value={0}>Selecione</option>
                  {papelSignatario.map((map) => {
                    return (
                      <option key={map?.papelSignatarioId} value={map?.papelSignatarioId}>
                        {map?.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
            }
          </>
        );
      },
    },
    {
      name: "Percentual",
      cell: (row: IEmpresa, index: number) => {
        return (
          <div>
            <CurrencyField
              onValueChange={(values) => onChangePercentualValue(index, values)}
              floatValue={row.percentual} />
          </div>
        );
      },
      ignoreRowClick: true,
    },

    {
      name: 'Gestão',
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (carregandoGestores ? <Carregando /> :
          <select
            name="Gestor"
            onChange={(e) => atribuirGestorId(row, parseInt(e.target.value, 10))}
            className={"form-control"}
            id="form-gestaoProcessoId"
            disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
            value={row?.gestaoProcessoId}
          >
            <option key={0} value={0}>Selecione</option>
            {gestaoProcesso.map((map) => {
              return (
                <option key={map?.gestaoProcessoId} value={map?.gestaoProcessoId}>
                  {map?.nome}
                </option>
              );
            })}
          </select>);
      },
    },
    {
      name: "Principal?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <Form.Check
              type="switch"
              id="custom-switch"
              style={{ cursor: 'pointer' }}
              checked={row.checked}
              disabled={(empresas.filter(e => e.numeroDocumento == row.numeroDocumento && e.isFilial)?.length > 0)}
              onClick={() => adicionar(row.empresaId)}
            />
          </div>
        );
      },
    },
  ];

  const colunasComPapelF: TableColumn<IEmpresa>[] = [
    {
      name: "Nome",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.nomeFantasia}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Documento",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.numeroDocumento}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Forma de Participação *",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <>{(formik.values.modulo === 1) &&
            <div>
              <select
                name="formaParticipacaoId"
                onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                className={"form-control"}
                id="form-formaParticipacaoId"
                value={row?.formaParticipacaoId}
              >
                <option key={0} value={0}>Selecione</option>
                {formaParticipacao.map((map) => {
                  return (
                    <option key={map?.formaParticipacaoId} value={map?.formaParticipacaoId}>
                      {map?.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          }
            {(formik.values.modulo === EModulo.Contrato) &&
              <div>
                <select
                  name="papelSignatarioId"
                  onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                  className={"form-control"}
                  id="form-papelSignatarioId"
                  value={row.papelSignatarioId}

                >
                  <option key={0} value={0}>Selecione</option>
                  {papelSignatario.map((map) => {
                    return (
                      <option key={map?.papelSignatarioId} value={map?.papelSignatarioId}>
                        {map?.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
            }
          </>
        );
      },
    },
    {
      name: "Principal?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.principal}
              disabled={(formik.values.modulo === EModulo.Contrato && !row.papelSignatarioId) || (formik.values.modulo === EModulo.AreaDireito && !row.formaParticipacaoId)}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <Form.Check
              type="switch"
              id="custom-switch"
              style={{ cursor: 'pointer' }}
              checked={row.checked}
              disabled={(formik.values.modulo === EModulo.Contrato && !row.papelSignatarioId) || (formik.values.modulo === EModulo.AreaDireito && !row.formaParticipacaoId)}
              onClick={() => adicionar(row.empresaId)}
            />
          </div>
        );
      },
    },
  ];

  const colunasSemPapelF: TableColumn<IEmpresa>[] = [
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IEmpresa) => row.nomeFantasia,
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.numeroDocumento}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Principal?",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <Form.Check
              type="switch"
              id="custom-switch"
              style={{ cursor: 'pointer' }}
              checked={row.checked}
              onClick={() => adicionar(row.empresaId)}
            />
          </div>
        );
      },
    },
  ];

  const colunasRateioComPapelF: TableColumn<IEmpresa>[] = [
    {
      name: "Nome",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.nomeFantasia}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Documento",
      cell: (row: IEmpresa) => {
        return (
          <div>
            <p>{row?.numeroDocumento}</p>
          </div>
        );
      },
      ignoreRowClick: true,
      width: '300px', // Set the width for the column
    },
    {
      name: "Forma de Participação *",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <>{(formik.values.modulo === 1) &&
            <div>
              <select
                name="formaParticipacaoId"
                onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                className={"form-control"}
                id="form-formaParticipacaoId"
                value={row?.formaParticipacaoId}
              >
                <option key={0} value={0}>Selecione</option>
                {formaParticipacao.map((map) => {
                  return (
                    <option key={map?.formaParticipacaoId} value={map?.formaParticipacaoId}>
                      {map?.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          }
            {(formik.values.modulo === EModulo.Contrato) &&
              <div>
                <select
                  name="papelSignatarioId"
                  onChange={(e) => atribuirFormaPagamentoId(row, parseInt(e.target.value, 10))}
                  className={"form-control"}
                  id="form-papelSignatarioId"
                  value={row.papelSignatarioId}
                >
                  <option key={0} value={0}>Selecione</option>
                  {papelSignatario.map((map) => {
                    return (
                      <option key={map?.papelSignatarioId} value={map?.papelSignatarioId}>
                        {map?.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
            }
          </>
        );
      },
    },
    {
      name: "Percentual",
      cell: (row: IEmpresa, index: number) => {
        return (
          <div>
            <CurrencyField
              onValueChange={(values) => onChangePercentualValue(index, values)}
              floatValue={row.percentual} />
          </div>
        );
      },
      ignoreRowClick: true,
    },

    {
      name: 'Gestão',
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (carregandoGestores ? <Carregando /> :
          <select
            name="Gestor"
            onChange={(e) => atribuirGestorId(row, parseInt(e.target.value, 10))}
            className={"form-control"}
            id="form-gestaoProcessoId"
            value={row?.gestaoProcessoId}
          >
            <option key={0} value={0}>Selecione</option>
            {gestaoProcesso.map((map) => {
              return (
                <option key={map?.gestaoProcessoId} value={map?.gestaoProcessoId}>
                  {map?.nome}
                </option>
              );
            })}
          </select>);
      },
    },
    {
      name: "Principal?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <FormCheckInput
              name="terms"
              checked={row.principal}
              onClick={() => marcarPrincipal(row.empresaId)}
              style={{ borderRadius: "50%", backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      name: "Adicionar?",
      ignoreRowClick: true,
      cell: (row: IEmpresa) => {
        return (
          <div>
            <Form.Check
              type="switch"
              id="custom-switch"
              style={{ cursor: 'pointer' }}
              checked={row.checked}
              disabled={!row.formaParticipacaoId || !row.papelSignatarioId}
              onClick={() => adicionar(row.empresaId)}
            />
          </div>
        );
      },
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IEmpresa>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  const isValidEmpresas = () => {
    let isValid = false;

    // Verifica se existe pelo menos uma empresa marcada como principal e checada.
    if (!empresas.some(e => e.checked && e.principal)) {
      isValid = true;
    }

    // Caso específico para o módulo Área de Direito
    if (formik.values.modulo === EModulo.AreaDireito) {
      // Verifica se existe alguma empresa checada sem forma de participação definida.

      // Verificações específicas quando o rateio está habilitado
      if (habilitarRateio) {
        // Verifica se o total do percentual é diferente de 100.
        if (percentualTotal !== 100) {
          isValid = true;
        }

        // Verifica se existe alguma empresa checada sem gestor de processo definido.
        if (empresas.some(e => e.checked && !e.gestaoProcessoId)) {
          isValid = true;
        }
      }
    }

    // Se nenhuma das condições inválidas foi encontrada, retorna falso (todas empresas são válidas)
    return isValid;
  }

  const onRowClicked = (row: IEmpresa, expandir: boolean) => {

    empresas.forEach(empresa => {
      if (row.empresaId == empresa.empresaId) {
        empresa.expandir = expandir;
      } else {
        empresa.expandir = false;
      }
    });

    updateEmpresasList({ ...row, expandir: expandir })

    setEmpresas(empresas);

    setEmpresa(row);
  }

  const confirmar = async () => {

    if (capa) {
      try {

        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Editar empresas",
          text: `Você realmente deseja editar as empresas?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await CamposService.alterarPorEmpresaList(formik.values.empresasList, formik.values.principalId, routePropriedade(formik.values.modulo), exibirRateio); // OK

          notifySuccess(`Empresas salvas com sucesso!`);

          Swal.close();

          setRefreshCapa && setRefreshCapa(true);
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel editar as empresas`,
          text: error?.response?.data?.Message,
          showConfirmButton: true,
        });
      }
    }

    toggleModal();

  }

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <ToastContainer />
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Empresas {exibirRateio && <>
            <FontAwesomeIcon className="mx-2 text-orange" icon={faPercent} /> <ProgressBar variant="var(--primary-base2)" now={percentualTotal} />
            <a style={{ fontSize: "15px" }}>Total: {percentualTotal <= 100 && (percentualTotal).toFixed(2)}</a></>}
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body" style={{ maxHeight: '75vh', overflowY: 'auto' }}>

          {exibirRateio &&
            <div className="row mb-2">
              <div className="col-md-auto mb-10">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Deseja habilitar rateio?  <FormCheckInput
                  name="terms"
                  checked={habilitarRateio}
                  onClick={() => {
                    setHabilitarRateio(!habilitarRateio);
                    formik.setFieldValue("empresasList", [], true);
                    formik.setFieldValue("rateio", !habilitarRateio, true);
                  }}
                  style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                />
                </label>
              </div>
            </div>
          }

          {empresas.filter(e => e.isFilial)?.length > 0 &&
            <div className="row mb-2">
              <h5 className="modal-title text-orange">Filiais da empresa {empresa?.nomeFantasia} </h5>
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoEmpresas && carregandoPropriedades}
                limit={filtrosPesquisa.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtrosPesquisa.totalItems}
                colunas={(formik.values.modulo == EModulo.AreaDireito || formik.values.modulo == EModulo.Contrato) ? (habilitarRateio ? colunasRateioComPapelF : colunasComPapelF) : colunasSemPapelF}
                tipo="Empresas"
                itens={empresas.filter(e => e.isFilial)}
              />
            </div>
          }

          <div className="row mb-2">
            <div className="col-md-auto mb-10">
              <input
                value={filtrosPesquisa.filtro}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, filtro: e.target.value };
                  });
                }}
                placeholder="Pesquise"
                type="text"
                className={clsx("form-control")}
              />
            </div>

            <div className="col-md-auto mb-3">
              <button onClick={() => carregarEmpresas(filtrosPesquisa)} className="btn btn-orange">
                {<> Pesquisar </>}
              </button>
            </div>
            <div className="col-md-auto">{<> Buscar por: </>}</div>
            <div className="col-md-auto">
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 1 })}
                  label="Nome"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 2 })}
                  label="Documento"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  className="form-radio"
                  onClick={() => setFiltrosPesquisa({ ...filtrosPesquisa, tipoPesquisa: 3 })}
                  label="Código"
                  name="group1"
                  type="radio"
                />
              </div>
            </div>
          </div>

          <div className="col-md-auto mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoEmpresas && carregandoPropriedades}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={(formik.values.modulo == EModulo.AreaDireito || formik.values.modulo == EModulo.Contrato) ? (habilitarRateio ? colunasRateioComPapel : colunasComPapel) : colunasSemPapel}
              tipo="Empresas"
              itens={empresas.filter(e => !e.isFilial)}
            />
          </div>
        </div>

        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            onClick={() => confirmar()}
            disabled={isValidEmpresas()}
            type="button"
            className="btn btn-orange ms-5"
          >
            Adicionar
          </button>

          <button
            onClick={() => toggleModal()}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EmpresaField;
