/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import { faEraser, faFileExcel, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";

import ProcuracaoService from "../../services/ProcuracaoService";
import TipoProcuracaoService from "../../services/TipoProcuracaoService";

import GridPadrao from "../../components/Comum/GridPadrao";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import ITipoProcuracao from "../../interfaces/ITipoProcuracao";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaProcuracaoRequest from "../../interfaces/Requests/Procuracao/FiltroPesquisaProcuracaoRequest";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import ProcuracaoFiltroModal from "./ProcuracaoFiltroModal";
import { OrangeContext } from "../../contexts/OrangeProvider";
import "../ProcessoPage/style/icons.css"
import ProcuracaoRelatorioModal from "./ProcuracaoRelatorioModal";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const filtroInicial: FiltroPesquisaProcuracaoRequest = {
  procuracaoId: 0,
  pastaId: 0,
  modeloRelatorioId: 0,
  clienteId: [],
  tipoProcuracaoId: [],
  statusProcuracaoId: [],
  cidadeId: [],
  departamentoId: [],
  tipoInstrumentoId: [],
  gestorId: [],
  empresa: [],

  codigo: "",
  numeroProcuracao: "",
  observacoesProcuracao: "",
  poderesProcuracao: "",
  nomeSolicitante: "",
  tempoGasto: "",
  emailSolicitante: "",
  parteContraria: "",

  dataInicioVigenciaMaiorIgual: null,
  dataInicioVigenciaMenorIgual: null,

  dataFimVigenciaMaiorIgual: null,
  dataFimVigenciaMenorIgual: null,

  dataAprovacaoMaiorIgual: null,
  dataAprovacaoMenorIgual: null,

  dataAssinaturaMaiorIgual: null,
  dataAssinaturaMenorIgual: null,

  dataCadastroMaiorIgual: null,
  dataCadastroMenorIgual: null,

  offset: 0,
  limit: 10,
  sort: "procuracaoId",
  totalItems: 0,
};

const ProcuracaoPage = () => {
  const [procuracaos, setProcuracaos] = useState<IProcuracao[]>([]);
  const [carregandoProcuracaos, setCarregandoProcuracaos] = useState(false);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);

  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaProcuracaoRequest>(filtroInicial);

  const [carregamentoTipoProcuracao, setCarregamentoTipoProcuracao] = useState<boolean>(false);
  const [tiposProcuracaosOptions, setTiposProcuracaosOptions] = useState<any>([]);
  const [tipoProcuracaoSelected, setTipoProcuracaoSelected] = useState([]);

  const optionsStatusProcuracao = [
    { label: "Em Elaboração", value: "1" },
    { label: "Em Aprovação", value: "2" },
    { label: "Em Assinatura", value: "3" },
    { label: "Assinado", value: "4" },
    { label: "Cancelado", value: "5" },
  ];

  const [selectedStatusProcuracao, setSelectedStatusProcuracao] = useState<any>([]);

  const setIdsSelect = (entidade: string, optionsSelected: any) => {
    const IdsSelected: number[] = [];

    optionsSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));

    setFiltrosPesquisa({ ...filtrosPesquisa, statusProcuracaoId: IdsSelected });
  };

  const { setProcuracao, setTipoProcuracao } = useContext<OrangeInterfaceContext>(OrangeContext);

  const navigate = useNavigate();

  const componentRef = useRef<any>();

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);

  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

  useLayoutEffect(() => {
    carregarTipoProcuracao();
  }, []);

  const returnOptionsTiposProcuracaos = (array: any) => {
    array.forEach((item: any) => {
      tiposProcuracaosOptions.push({
        value: item.tipoProcuracaoId,
        label: item.nome,
      });
    });
    return setTiposProcuracaosOptions;
  };
  const carregarTipoProcuracao = async () => {
    try {
      setCarregamentoTipoProcuracao(true);
      let resultadoTipoProcuracao: IRecuperaLista<ITipoProcuracao>;
      resultadoTipoProcuracao = await TipoProcuracaoService.obterTipoProcuracoes({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "tipoProcuracaoId",
      });
      setTiposProcuracaosOptions(returnOptionsTiposProcuracaos(resultadoTipoProcuracao.data));
      setCarregamentoTipoProcuracao(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoProcuracao(false);
      setTiposProcuracaosOptions([]);
    }
  }

  const carregarProcuracaos = async (filtro: FiltroPesquisaProcuracaoRequest) => {
    try {
      setCarregandoProcuracaos(true);

      let resultado: IRecuperaLista<IProcuracao>;
      resultado = await ProcuracaoService.obterProcuracaos(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setProcuracaos(resultado.data);
      setCarregandoProcuracaos(false);
      setPesquisar(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setProcuracaos([]);
    } finally {
      setCarregandoProcuracaos(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  const montarParteContraria = (partes: IProcuracaoParteContraria[]) => {
    let partesString = "";

    partes.forEach((parte) => {
      if (parte.principal) {
        partesString += parte.nome + " - " + parte.papelSignatarioNome;
      }
    });
    return partesString;
  };

  const colunas: TableColumn<IProcuracao>[] = [
    {
      cell: (procuracao: IProcuracao) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => {
                  setProcuracao(procuracao);
                  setTipoProcuracao({
                    tipoProcuracaoId: procuracao.tipoProcuracaoId,
                    nome: procuracao.tipoProcuracaoNome,
                    codigo: "",
                    status: "",
                  });
                  navigate("/Procuracao/Capa");
                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Número: {procuracao.numeroProcuracao}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{procuracao.statusProcuracaoNome}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {procuracao.procuracaoId}
                {procuracao.favoritoId ? (
                  <a
                    onClick={() => removerFavorido(procuracao.favoritoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={() => adicionarFavorito(procuracao.procuracaoId)}
                    style={{ margin: "-28px -11px 4px 200px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Empresa: </b> {procuracao.procuracaoEmpresas.map(empresa => `${empresa.nome} / `)}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Partes: </b> {procuracao.procuracaoParteContraria.map(parte => `${parte.nome} / `)}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IProcuracao>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    if (pesquisar) carregarProcuracaos(filtrosPesquisa);
  }, [pesquisar]);

  const adicionarFavorito = async (procuracaoId: number) => {
    try {
      await ProcuracaoService.adicionaFavorido(procuracaoId);

      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Procuracao adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarProcuracaos(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar procuracao como favorito`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  const removerFavorido = async (favoritoId: number) => {
    try {
      await ProcuracaoService.removerFavorido(favoritoId);

      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Procuracao removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      carregarProcuracaos(filtrosPesquisa);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorido`,
        text: error && error.response && error.response.data && typeof error.response.data === "string" ? error.response.data : error.message,
        showConfirmButton: true,
      });
    }
  }

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoProcuracaoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, tipoProcuracaoId: IdsSelected };
    });
  }, [tipoProcuracaoSelected]);

  return (
    <>
      <LayoutPageTitle title="Procuração">
        <button onClick={() => navigate("/Procuracao/Novo")} className="btn btn-md btn-orange">
          Nova <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <ProcuracaoFiltroModal
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
        toggleModal={toggleModal}
      />

      <ProcuracaoRelatorioModal
        exibirModal={exibirConfirmarModeloModal}
        toggleModal={toggleConfimarModeloModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
      />

      <div className="row">
        <div className="col-md-12" >

          <CollapseFiltro titulo="Filtros" content={
            <form onSubmit={(e) => e.preventDefault()}
              className="row g-3 mb-3 form-buscar-procuracao">
              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Identificador:
                </label>
                <input
                  value={filtrosPesquisa.procuracaoId}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, procuracaoId: e.target.value };
                    });
                  }}
                  placeholder="ID"
                  type="number"
                  className={"form-control"}
                  id="form-pasta"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Número:
                </label>
                <input
                  value={filtrosPesquisa.numeroProcuracao}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState: any) => {
                      return { ...oldState, numeroProcuracao: e.target.value };
                    });
                  }}
                  placeholder="Número"
                  type="number"
                  className={"form-control"}
                  id="form"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  E-mail do solicitante:
                </label>
                <input
                  value={filtrosPesquisa.emailSolicitante}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, emailSolicitante: e.target.value };
                    });
                  }}
                  placeholder="E-mail"
                  type="text"
                  className={"form-control"}
                  id="form-numeroProcuracao"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Tipo de Procuracão:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoProcuracao}
                  disabled={carregamentoTipoProcuracao}
                  options={tiposProcuracaosOptions}
                  value={tipoProcuracaoSelected}
                  onChange={(event: any) => setTipoProcuracaoSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                  Parte Contrária:
                </label>
                <input
                  value={filtrosPesquisa.parteContraria}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, parteContraria: e.target.value };
                    });
                  }}
                  placeholder="Nome ou CPF/CNPJ"
                  type="text"
                  className={"form-control"}
                  id="form-parteContraria"
                />
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status do Instrumento:
                </label>
                <MultiSelect
                  options={optionsStatusProcuracao}
                  value={selectedStatusProcuracao}
                  //onChange={setSelectedStatusProcuracao}
                  onChange={(event: any) => {
                    setSelectedStatusProcuracao(event);
                    setIdsSelect("statusProcuracao", event);
                  }}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-12"
                style={{ textAlign: 'right' }}>
                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    setFiltrosPesquisa(filtroInicial);
                    setPesquisar(false);
                    setTipoProcuracaoSelected([]);
                    setSelectedStatusProcuracao([]);
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                      <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <button
                  onClick={() => toggleConfimarModeloModal()}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  disabled={carregandoProcuracaos}>
                  <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                </button>

                <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

              </div>

            </form>} />
        </div>
      </div>

      <div className="col-md-12 mb-10" style={{ overflow: !carregandoProcuracaos ? "auto" : "scroll", height: "600px" }}>

        <div ref={componentRef}>
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoProcuracaos}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="procurações"
            itens={procuracaos}
          />
        </div>
      </div>
    </>
  );
};
export default ProcuracaoPage;
