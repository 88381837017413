import { faCheckCircle, faEraser, faPen, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "../../components/Comum/GridPadrao";
import ICidade from "../../interfaces/ICidade";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaCidadeRequest from "../../interfaces/Requests/Cidade/FiltroPesquisaCidadeRequest";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import CidadeService from "../../services/CidadeService";
import CidadeModal from "./CidadeModal";


const CidadePage = () => {
    const [cidade, setCidade] = useState<ICidade>();
    const [cidades, setCidades] = useState<ICidade[]>([]);
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [carregandoCidade, setCarregandoCidade] = useState(false);
    const [exibirModal, setExibirModal] = useState<boolean>(false);

    const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaCidadeRequest>({
        estadoId: 0,
        IBGE:'',
        nome: '',
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: 'cidadeId'
    });

    function toggleModal(carregarDados?: boolean): void {
        if (carregarDados) {
            carregarCidade(filtrosPesquisa);
        }

        setExibirModal(!exibirModal);

        setTimeout(() => {
            setCidade(undefined)
        }, 300)
    }

    async function carregarCidade(filtro: FiltroPesquisaCidadeRequest) {
        try {
            setCarregandoCidade(true);
            let resultado: IRecuperaLista<ICidade>;

            resultado = await CidadeService.obterCidade(filtro);

            setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });
            setCidades(resultado.data);
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setCidades([]);
        }
        finally {
            setCarregandoCidade(false);
            setLoadingInicial(false);
        }
    }

    async function alterarStatus(cidadeId: number, status: number) {
        try {
            const swalWithBootstrapButtons = Swal.mixin({
                heightAuto: false,
                customClass: {
                    confirmButton: 'btn btn-orange',
                    cancelButton: 'btn btn-danger ms-5'
                },
                buttonsStyling: false
            });

            let result = await swalWithBootstrapButtons.fire({
                title: 'Alterar Status ',
                text: `Você realmente deseja alterar o status?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Confirmar`
            });

            if (result.isConfirmed) {
                await CidadeService.alterarStatus(cidadeId, status);

                Swal.fire({
                    heightAuto: false,
                    icon: 'success',
                    text: `Status do Cidade alterado com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                })

                await limparFiltros();

                setFiltrosPesquisa(oldState => {
                    carregarCidade(oldState);
                    return oldState;
                })
            }

        } catch (error: any) {
            let mensagemErro = 'Ocorreu um erro inesperado';

            if (error?.response?.data?.Message) {
                mensagemErro = error.response.data.Message
            }

            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: `Não foi possivel alterar o Status do Cidade`,
                text: mensagemErro,
                showConfirmButton: true
            });
        }
    }

    const colunas: TableColumn<ICidade>[] = [
        {
            name: 'Id',
            sortField: 'cidadeId',
            selector: (row: ICidade) => row.cidadeId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true
        },
        {
            name: 'Nome',
            sortField: 'cidadeNome',
            selector: (row: ICidade) => row.nome,
            sortable: true,
            wrap: true
        },
        {
            name: 'Código',
            sortField: 'codigo',
            selector: (row: ICidade) => row.codigo,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: (cidade: ICidade) => {
                return (
                    <div>
                        <FontAwesomeIcon title="Editar Cidade" onClick={() => {
                            setCidade(cidade);
                            setExibirModal(true);
                        }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faPen} />

                        {cidade.status === 'Ativo' ?
                            <FontAwesomeIcon title="Desativar Cidade" onClick={() => {
                                alterarStatus(cidade.cidadeId, -1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faTrashAlt} />
                            :
                            <FontAwesomeIcon title="Ativar Cidade" onClick={() => {
                                alterarStatus(cidade.cidadeId, 1)
                            }} style={{ fontWeight: 'normal', cursor: 'pointer' }} size='2x' className='mx-2 text-orange' icon={faCheckCircle} />
                        }
                    </div>
                )
            },
            ignoreRowClick: true,
        }
    ]

    const handlePerRowsChange = async (currentRowsPerPage: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
    };

    const handlePageChange = (page: number) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
    };

    const handleSort = async (column: TableColumn<ICidade>, sortDirection: string) => {
        setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });
    };

    function limparFiltros() {
        setFiltrosPesquisa(oldValue => {
            return {
                ...oldValue,
                nome: '',
                codigo: '',
                status: 1
            }
        });
    }

    useEffect(() => {
        if (!loadingInicial) {
            carregarCidade(filtrosPesquisa);
        }

    }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])

    return (<>
        <LayoutPageTitle title="Cidade">
            <button onClick={() => {
                setCidade(undefined);
                setExibirModal(true);
            }} className="btn btn-md btn-orange">
                Novo<FontAwesomeIcon color={'white'} className='mx-2' icon={faPlus} />
            </button>
        </LayoutPageTitle>

        <CidadeModal exibirModal={exibirModal} titulo={cidade ? 'Editar Registro' : 'Criar Registro'} toggleModal={toggleModal} cidade={cidade} />

        <div className="row mt-2">
            <div className="col-md-12">
                <div className="col-md-12">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        carregarCidade(filtrosPesquisa);
                    }} className="row g-3 mb-3">
                        <div className="col-md-2 ">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Nome:</label>
                            <input value={filtrosPesquisa.nome} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, nome: e.target.value } });
                            }} placeholder="Nome" type="text" className={'form-control'} id="form-nome" />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Status:</label>
                            <select value={filtrosPesquisa.status} onChange={(e) => {
                                setFiltrosPesquisa(oldState => { return { ...oldState, status: parseInt(e.target.value) } });
                            }} placeholder="Nome" className={'form-select'} id="form-select" >
                                <option value="0" label="Todos"> Todos </option>
                                <option value="1" label="Ativo"> Ativo </option>
                                <option value="-1" label="Inativo"> Inativo </option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <button type="submit" className="btn btn-sm btn-orange search-buttom-margin ">
                                    <FontAwesomeIcon color='white' className='mx-2' icon={faSearch} />
                                </button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    limparFiltros();
                                }} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                                    <FontAwesomeIcon color='white' className='mx-2' icon={faEraser} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 mb-10">
                    <GridPadrao onSort={handleSort} progressPending={carregandoCidade} limit={filtrosPesquisa.limit} onChangePage={handlePageChange} onChangeRowsPerPage={handlePerRowsChange} paginationServer={true} paginationTotalRows={filtrosPesquisa.totalItems} colunas={colunas} tipo='Cidade' itens={cidades} />
                </div>
            </div>
        </div >
    </>)
}

export default CidadePage;