import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import styled from "styled-components";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faBold,
  faHeading,
  faImage,
  faItalic,
  faPalette,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import EmailUpdateService from "../../services/EmailUpdateService";

type IconState = {
  bold: boolean;
  italic: boolean;
  heading: boolean;
  justifyLeft: boolean;
  justifyCenter: boolean;
  justifyRight: boolean;
  [key: string]: boolean;
};

const MainContentContainer = styled.div`
  display: flex;
  gap: 100px;
  margin-bottom: 50px;
`;

const TopBar = styled.div`
  min-width: 100%;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;

  button {
    background-color: transparent;
    border: none;
    font-size: 11px;
    color: #575757;
  }
  button svg {
    font-size: 13px;
  }

  input[type="color"] {
    opacity: 0;
  }
`;

const ColorContainer = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }

  svg {
    font-size: 13px;
    margin: 0px !important;
  }
`;

const TextEditorContainer = styled.div`
  border: 2px solid rgb(255, 215, 187);
  min-width: 45%;
  max-width: 45%;
`;
const TextEditor = styled.div`
  padding: 10px;
  padding-top: 4px;
  max-width: 100%;
  &:focus {
    outline: none;
    border: none;
  }
`;

const ImageInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ChangeLogEmailPage = () => {
  const [assunto, setAssunto] = useState<string>();
  const [corTexto, setCorTexto] = useState<string>("#000000");
  const [htmlContent, setHtmlContent] = useState<string>();
  const [activeIcons, setActiveIcons] = useState<IconState>({
    bold: false,
    italic: false,
    heading: false,
    justifyLeft: false,
    justifyCenter: false,
    justifyRight: false,
  });

  useEffect(() => {
    // Define o estilo padrão de envio
    const previewElement = document.getElementById("preview");

    function updateInitialHtml() {
      return `

            <div id="title-div-text-editor">
                <h1 id="main-title-text-editor" style="color: var(--primary-base-light2);">
                ${assunto ? assunto : "Insira um assunto"}</h1>
                <hr style="margin-bottom: 0px;"/>
            </div>`;
    }

    if (previewElement) {
      previewElement.innerHTML =
        updateInitialHtml() +
        (htmlContent != undefined ? htmlContent : "Insira o corpo do e-mail");
    }
  }, [htmlContent, assunto]);

  useEffect(() => {
    const textEditor = document.getElementById("text-editor");

    const allFalse = Object.keys(activeIcons).every(
      (key) => activeIcons[key] === false
    );

    if (!allFalse) {
      if (
        textEditor?.innerHTML == "" ||
        textEditor?.innerHTML == "<br>" ||
        textEditor?.innerHTML == "<div><br></div>"
      ) {
        textEditor.innerHTML = "";
        setActiveIcons({
          bold: false,
          italic: false,
          heading: false,
          justifyLeft: false,
          justifyCenter: false,
          justifyRight: false,
        });
      }
    }
  }, [<TextEditor />]);

  const fileInputRef: any = useRef(null);
  const handleInputClick = () => {
    fileInputRef.current.click();
  };

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    setHtmlContent(e.currentTarget.innerHTML);
  };

  const editorRef = useRef<HTMLDivElement>(null);

  const executeCommand = (command: string, arg?: string) => {
    const selection = window.getSelection();
    setActiveIcons((prev) => ({
      ...prev,
      [command]: !prev[command],
    }));

    if (selection && selection.rangeCount > 0) {
      const selectedNode = selection.getRangeAt(0).commonAncestorContainer;
      const parentElement =
        selectedNode instanceof HTMLElement
          ? selectedNode
          : selectedNode.parentElement;

      // Checa se o elemento atual é um titulo.
      if (command === "formatBlock") {
        if (
          (parentElement && parentElement.tagName.startsWith("H")) ||
          (parentElement &&
            parentElement.parentElement &&
            parentElement.parentElement.tagName.startsWith("H")) ||
          (parentElement &&
            parentElement.parentElement &&
            parentElement.parentElement.parentElement &&
            parentElement.parentElement.parentElement.tagName.startsWith("H"))
        ) {
          document.execCommand("formatBlock", false, "p");
        } else {
          // Aplica o formato de titulo como estava antes
          document.execCommand(command, false, arg || undefined);
        }
      } else {
        document.execCommand(command, false, arg || undefined);
      }
    }

    editorRef.current?.focus();
  };

  const checkActiveStyles = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let parentElement =
        range.commonAncestorContainer.nodeType === 3
          ? range.commonAncestorContainer.parentNode
          : range.commonAncestorContainer;

      const styles = {
        bold: false,
        italic: false,
        heading: false,
        justifyLeft: false,
        justifyCenter: false,
        justifyRight: false,
      };

      // Função para verificar os estilos ao subir a cadeia de elementos
      const checkStyles = (element: any) => {
        if (!element || element.nodeType === 9) {
          return;
        }

        // Verifica estilos
        if (
          window.getComputedStyle(element, null).fontWeight === "bold" ||
          element.tagName === "B" ||
          element.tagName === "STRONG"
        ) {
          styles.bold = true;
        }
        if (
          window.getComputedStyle(element, null).fontStyle === "italic" ||
          element.tagName === "I" ||
          element.tagName === "EM"
        ) {
          styles.italic = true;
        }
        if (
          element.tagName &&
          ["H1", "H2", "H3", "H4", "H5", "H6"].includes(element.tagName)
        ) {
          styles.heading = true;
        }
        const textAlign = window.getComputedStyle(element, null).textAlign;
        if (textAlign === "left" || textAlign === "start") {
          styles.justifyLeft = true;
        }
        if (textAlign === "center") {
          styles.justifyCenter = true;
        }
        if (textAlign === "right") {
          styles.justifyRight = true;
        }

        // Subir para o próximo elemento pai
        checkStyles(element.parentNode);
      };

      // Inicia a verificação a partir do elemento pai inicial
      checkStyles(parentElement);

      setActiveIcons(styles);
    }
  };

  const enviarEmail = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Enviar E-mail?",
        text: `Você deseja enviar o e-mail de atualização?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Enviar`,
      });
      
      if (result.isConfirmed) {
        if (htmlContent && assunto) {
          EmailUpdateService.enviaEmail({
            assunto: assunto,
            corpo: htmlContent,
          });
        }
        
        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `E-mail enviado com sucesso!`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possível enviar o e-mail`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    const textEditor = document.getElementById("text-editor");
    textEditor?.addEventListener("mouseup", checkActiveStyles);
    textEditor?.addEventListener("keyup", checkActiveStyles);

    return () => {
      textEditor?.removeEventListener("mouseup", checkActiveStyles);
      textEditor?.removeEventListener("keyup", checkActiveStyles);
    };
  }, []);

  const insertImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target ? e.target.result : null;
        if (result) {
          const img = document.createElement("img");
          img.src = result.toString();
          img.style.maxWidth = "100%"; // Garante que a imagem não seja mais que o editor

          const selection = window.getSelection();
          if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            range.insertNode(img);
            range.setStartAfter(img);
            range.setEndAfter(img);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <LayoutPageTitle title="Envio de E-mail de atualização">
        <div>
          <button
            style={{ marginRight: "15px" }}
            onClick={(e) => {
              e.preventDefault();
              if (
                assunto === "" ||
                assunto == undefined ||
                htmlContent == undefined
              ) {
                Swal.fire({
                  heightAuto: false,
                  icon: "error",
                  title: `Falha no envio!`,
                  text: "Você deve inserir um assunto e um corpo para o e-mail!",
                  showConfirmButton: true,
                });
              } else {
                enviarEmail();
              }
            }}
            type="submit"
            className="btn btn-sm btn-orange search-buttom-margin "
          >
            ENVIAR
            <FontAwesomeIcon
              title="Enviar Email"
              style={{
                fontWeight: "normal",
                cursor: "pointer",
                color: "white",
              }}
              size="1x"
              className="mx-2"
              icon={faPaperPlane}
            />
          </button>
        </div>
      </LayoutPageTitle>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="row g-3 mb-3"
            >
              <div style={{ width: "35%" }} className="col-md-2 ">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder text-orange"
                >
                  Assunto:
                </label>
                <input
                  value={assunto}
                  onChange={(e) => {
                    setAssunto(e.target.value);
                  }}
                  placeholder="Assunto"
                  type="text"
                  className={"form-control"}
                  style={{ width: "100%" }}
                  id="form-nome"
                />
              </div>

              <div style={{ margin: "0 auto" }} className="col-md-2 "></div>
            </form>
          </div>
          <div className="col-md-12 mb-10">
            <div>
              <MainContentContainer>
                <TextEditorContainer>
                  <TopBar>
                    <button onClick={() => executeCommand("bold")}>
                      <FontAwesomeIcon
                        title="Negrito"
                        style={{
                          fontWeight: "normal",
                          cursor: "pointer",
                          color: activeIcons.bold ? "var(--primary-base2)" : "black",
                        }}
                        size="2x"
                        className="mx-2"
                        icon={faBold}
                      />
                    </button>
                    <button onClick={() => executeCommand("italic")}>
                      <FontAwesomeIcon
                        title="Itálico"
                        style={{
                          fontWeight: "normal",
                          cursor: "pointer",
                          color: activeIcons.italic ? "var(--primary-base2)" : "black",
                        }}
                        size="2x"
                        className="mx-2"
                        icon={faItalic}
                      />
                    </button>
                    <button onClick={() => executeCommand("formatBlock", "h4")}>
                      <FontAwesomeIcon
                        title="Título"
                        style={{
                          fontWeight: "normal",
                          cursor: "pointer",
                          color: activeIcons.heading ? "var(--primary-base2)" : "black",
                        }}
                        size="2x"
                        className="mx-2"
                        icon={faHeading}
                      />
                    </button>
                    <ColorContainer>
                      <div style={{ width: "0px" }}>
                        <FontAwesomeIcon
                          title="Alterar cor"
                          style={{
                            color: corTexto,
                            fontWeight: "normal",
                            cursor: "pointer",
                            margin: "0px",
                            position: "relative",
                          }}
                          size="2x"
                          className="mx-2"
                          icon={faPalette}
                        />
                      </div>
                      <input
                        type="color"
                        value={corTexto}
                        onChange={(e) => {
                          setCorTexto(e.target.value);
                        }}
                        onBlur={(e) => {
                          executeCommand("foreColor", corTexto);
                        }}
                      />
                    </ColorContainer>

                    <button onClick={() => executeCommand("justifyLeft")}>
                      <FontAwesomeIcon
                        title="Alinhas à esquerda"
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2"
                        icon={faAlignLeft}
                      />
                    </button>
                    <button onClick={() => executeCommand("justifyCenter")}>
                      <FontAwesomeIcon
                        title="Alinhas ao centro"
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2"
                        icon={faAlignCenter}
                      />
                    </button>
                    <button onClick={() => executeCommand("justifyRight")}>
                      <FontAwesomeIcon
                        title="Alinhas à direita"
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="2x"
                        className="mx-2"
                        icon={faAlignRight}
                      />
                    </button>
                    <ImageInputContainer>
                      <HiddenFileInput
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={insertImage}
                      />
                      <button onClick={handleInputClick}>
                        <FontAwesomeIcon
                          title="Inserir imagem"
                          style={{ fontWeight: "normal", cursor: "pointer" }}
                          size="2x"
                          className="mx-2"
                          icon={faImage}
                        />
                      </button>
                    </ImageInputContainer>
                  </TopBar>
                  <TextEditor
                    id="text-editor"
                    ref={editorRef}
                    contentEditable
                    onInput={handleInput}
                    style={{
                      minHeight: "100px",
                    }}
                  ></TextEditor>
                </TextEditorContainer>
              </MainContentContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeLogEmailPage;
