import api from "./Api";
import { removerPropriedadesVazia } from "../utils/Utils";

import IFluxo from "../interfaces/IFluxo";
import { ICondicao } from "../interfaces/IFase";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaFluxoRequest from "../interfaces/Requests/Fluxo/FiltroPesquisaFluxoRequest";

import { Node } from "reactflow";
import ENodeEnum from "../enum/ENodeEnum";

class FluxoService {

    async obterFluxos(filtros: FiltroPesquisaFluxoRequest): Promise<IRecuperaLista<IFluxo>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.fluxoId) {
            query += `&fluxoId=${filtros.fluxoId}`;
        }

        if (filtros.modulo) {
            query += `&moduloId=${filtros.modulo}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IFluxo>>(`Fluxo/RecuperaFluxos?${query}`);

        return result;
    }

    async obterFluxoPorId(fluxoId: number): Promise<IRecuperaLista<IFluxo>> {

        let { data: result } = await api.get<IRecuperaLista<IFluxo>>(`Fluxo/RecuperaFluxos?fluxoId=${fluxoId}`);

        return result;
    }


    async obterFasesPorId(faseId: number): Promise<any> {

        let { data: result } = await api.get<IFluxo>(`Fluxo/RecuperaFluxoPorId?fluxoId=${faseId}`);

        return result;
    }

    async adicionaFluxo(adicionar: IFluxo): Promise<IFluxo> {
        let { data: result } = await api.post<IFluxo>(`Fluxo/AdicionaFluxo`, {
            
            nome: adicionar.nome,
            codigo: adicionar.codigo,
            moduloId: adicionar.modulo,
            descricao: adicionar.descricao,

            jsonContent: "{}"
        });
        return result;
    }

    async atualizaFluxo(fluxo: any): Promise<any> {
        let fases = this.montarFase(fluxo.jsonContent.nodes);

        let { data: result } = await api.post<any>(`Fluxo/AtualizaFluxo`, {
            ...fluxo,
            fases: removerPropriedadesVazia(fases),
            jsonContent: JSON.stringify(fluxo.jsonContent)

        });

        return result;
    }

    montarFase = (nodes: any) => {
        const fases = new Map();

        nodes.forEach((node: Node) => {
            if (node.type === ENodeEnum.NodeFase) {

                const uniqueCondicoes = node.data.condicoes.filter((value: ICondicao, index: number, self: ICondicao[]) =>
                    index === self.findIndex((c) => (
                        c.codigo === value.codigo
                    ))
                );

                const fase = {
                    nome: node.data.nome,
                    fluxosFasesId: node.data.fluxosFasesId,
                    faseInicial: node.data.faseInicial,
                    observacao: node.data.observacao,
                    tipoCompromissoId: node.data.tipoCompromissoId,
                    codigo: node.data.codigo,
                    sla: {
                        campoId: node.data.sla.campoId,
                        tipoFaseSLA: node.data.sla.tipoFaseSLA,
                        valorFaseSLA: node.data.sla.valorFaseSLA,
                        tabelaOrigemId: node.data.sla.tabelaOrigemId
                    },
                    condicoes: uniqueCondicoes,
                    responsavel: {
                        tipoResponsavelFase: node.data.responsavel.tipoResponsavelFase,
                        grupoId: node.data.responsavel.grupoId,
                    }
                };

                fases.set(node.id, fase);
            }
        });



        nodes.forEach((node: Node) => {
            if (node.type === ENodeEnum.NodeCondicao) {
                let fase: any = node.data.codigoAnteriorFaseId ? fases.get(node.data.codigoAnteriorFaseId) : Array.from(fases.values()).find(e => e.faseInicial);
                if (fase) {
                    node.data.condicoes && node.data.condicoes.forEach((condicao: any) => {
                        fase.condicoes.push({
                            codigo: condicao.codigo,
                            fluxoInicial: fase.fluxoInicial,
                            fluxosFasesId: fase.fluxosFasesId,
                            codigoProximaFaseId: condicao.codigoProximaFaseId,
                            codigoAnteriorFaseId: fase.fluxoInicial ? null : condicao.codigoAnteriorFaseId,
                            regras: condicao.regras,
                        });
                    });
                }
            }
        });

        return Array.from(fases.values());
    }
}

export default new FluxoService();
