import {useEffect, useState } from "react";
import Swal from "sweetalert2";
import IChangeLog from "../../interfaces/IChangeLog";
import ChangeLogService from "../../services/ChangeLogService";
import styled from "styled-components";
import ChangeLogSVG from "./ChangeLogSVG.svg";

const MainContainer = styled.div`
`

const UpdateContentContainer = styled.div`
`

const UpdatesContainer = styled.div`
display: flex;
`

const ButtonContainer = styled.div`
background-image: url(${ChangeLogSVG});
background-repeat: no-repeat;
background-size: contain;
background-position:center;
padding: 8px;
transition: .3s;
&:hover{
    scale: 1.15;
}
`
const ChangeLogButton = styled.div`
cursor: pointer;
border: none;
color: white;
margin-right: 5px;
padding: 0 10px;
width: 100%;
`

const UpdatesPage = () => {
    const [carregandoChangeLog, setCarregandoChangeLog] = useState(false);
    const [changeLogsOrdenados, setChangeLogsOrdenados] = useState<IChangeLog[]>([]);
    const [selectedChangeLog, setSelectedChangeLog] = useState<number>(0);

    const recuperaChangeLogs = async () => {
        try {
            setCarregandoChangeLog(true);

            let resultado = await ChangeLogService.obterChangeLogs({
                versao: '',
                status: 1, 
                limit: 100,
                totalItems: 0,
                offset: 0,
                sort: 'id'
            });

            // Ordenação dos registros
            const ordenados = resultado.data.sort((a, b) => {
                const dateA = new Date(a.dataCadastro || "0001-01-01T00:00:00").getTime();
                const dateB = new Date(b.dataCadastro || "0001-01-01T00:00:00").getTime();
              
                // Tratando datas inválidas para que fiquem por último
                if (a.dataCadastro === "0001-01-01T00:00:00" || !a.dataCadastro) return 1;
                if (b.dataCadastro === "0001-01-01T00:00:00" || !b.dataCadastro) return -1;

                // Ordenando do mais recente para o mais antigo
                return dateB - dateA;
            });

            // Armazenando apenas os 3 registros mais recentes
            setChangeLogsOrdenados(ordenados.slice(0, 3));
        } catch (error: any) {
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível obter registros',
                text: error?.response?.data?.Message && typeof error.response.data.Message === 'string' ? error.response.data.Message : error.message,
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
            setChangeLogsOrdenados([]);
        } finally {
            setCarregandoChangeLog(false);
            

        }
    }

    const selectChangeLog = (index: number) => {
        setSelectedChangeLog(index);
    }


    const renderizaChangeLog = () => {
        const changelogContent = document.getElementById("update-content");
        if(changelogContent && changeLogsOrdenados && changeLogsOrdenados.length > 0){
            changelogContent.innerHTML =  changeLogsOrdenados[selectedChangeLog].descricao 
        }
    }


    useEffect(() => {
        recuperaChangeLogs();
    }, []);



    useEffect(() => {
        if(changeLogsOrdenados.length > 0){
            renderizaChangeLog();
        }
    }, [selectedChangeLog]);




    useEffect(() => {
        if(!carregandoChangeLog){
            renderizaChangeLog();
            
        }
    }, [carregandoChangeLog]);


    return (<>
        <MainContainer>
            <UpdatesContainer>


                {changeLogsOrdenados.length > 2 ? (
                    <ButtonContainer>
                        
                                        <ChangeLogButton onClick={() => selectChangeLog(2)}>
                        {changeLogsOrdenados[2].versao || "Carregando..."}
                                        </ChangeLogButton>
                    </ButtonContainer>
                ) : ""}

                {changeLogsOrdenados.length > 1 ? (
                    <ButtonContainer>
                        <ChangeLogButton onClick={() => selectChangeLog(1)}>
                        {changeLogsOrdenados[1].versao || "Carregando..."}
                        </ChangeLogButton>
                    </ButtonContainer>
                ) : ""}

                {changeLogsOrdenados.length > 0 ? (
                    <ButtonContainer>
                        <ChangeLogButton onClick={() => selectChangeLog(0)}>
                        {changeLogsOrdenados[0].versao || "Carregando..."}
                        </ChangeLogButton>
                    </ButtonContainer>
                ) : ""}
  
            </UpdatesContainer>
            <UpdateContentContainer>
                <div id="update-content">
                
                </div>
            </UpdateContentContainer>
        </MainContainer>
    </>);
}


export default UpdatesPage;
