import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import GridPadrao from "../../components/Comum/GridPadrao";

import { OrangeContext } from "../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";

import IEmpresa from "../../interfaces/IEmpresa";
import IAreaDireito from "../../interfaces/IAreaDireito";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IStatusProcesso from "../../interfaces/IStatusProcesso";
import IProcesso, { IProcessoPartesContraria } from "../../interfaces/IProcesso";
import { FiltroPesquisaProcessoRequest } from "../../interfaces/Requests/Processo/FiltroPesquisaProcessoRequest";

import EmpresaService from "../../services/EmpresaService";
import ProcessoService from "../../services/ProcessoService";
import AreaDireitoService from "../../services/AreaDireitoService";
import StatusProcessoService from "../../services/StatusProcessoService";

import { desdobramentoInicialValues, tipoDesdobramentoInicialValues } from "../../contexts/InitialValuesContext";
import { EModulo } from "../../enum";
import IXFinderDistribuicao from "../../interfaces/IXFinderDistribuicao";
import { formartDDMMYYYToDateTime } from "../../utils/Utils";
import FiltroModal from "../ProcessoPage/Modal/FiltroModal";

const filtroInicial: FiltroPesquisaProcessoRequest = {
  processoId: 0,
  numeroProcesso: "",
  pasta: "",
  numeroDesdobramento: '',
  parteContraria: "",
  descricaoObjetoAcao: "",
  observacoes: "",
  resumo: "",
  usuarioId: 0,
  offset: 0,
  limit: 10,
  sort: "processoId",
  totalItems: 0,
  status: 1,
  modeloRelatorioId: 0,

  valorCausaMaiorIgual: 0,
  valorCausaMenorIgual: 0,

  areaDireitoId: [],
  subAreaDireitoId: [],
  classificacaoId: [],
  ambitoId: [],
  juizId: [],
  tipoContingenciaId: [],
  esferaId: [],
  statusProcessoId: [],
  motivoEncerramentoId: [],
  instanciaId: [],
  faseProcessualId: [],
  tipoAcaoId: [],
  riscoId: [],
  estadoId: [],
  cidadeId: [],
  escritorioId: [],
  empresa: [],
  clienteId: [],
  advogadoInternoId: [],
  grupoEconomicoId: [],
  tipoPedidoId: [],

  dataDistribuicaoMaiorIgual: "",
  dataDistribuicaoMenorIgual: "",

  dataRecebimentoMaiorIgual: "",
  dataRecebimentoMenorIgual: "",

  prazoFatalMaiorIgual: "",
  prazoFatalMenorIgual: "",

  dataCadastroMaiorIgual: "",
  dataCadastroMenorIgual: "",

  dataUltimaAtualizacaoMaiorIgual: "",
  dataUltimaAtualizacaoMenorIgual: "",

  dataEncerramentoMaiorIgual: "",
  dataEncerramentoMenorIgual: "",

  dataEncerramentoCadastroMaiorIgual: "",
  dataEncerramentoCadastroMenorIgual: "",

  dataBaixaProvisoriaMaiorIgual: "",
  dataBaixaProvisoriaMenorIgual: "",

  dataBaixaProvisoriaCadastroMaiorIgual: "",
  dataBaixaProvisoriaCadastroMenorIgual: "",
};


interface IXFinderIntimacaoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  xFinderDistribuicao: IXFinderDistribuicao;
}

const XFinderDesdobramentoModal = ({ toggleModal, exibirModal, xFinderDistribuicao }: IXFinderIntimacaoFieldProps) => {

  const { setProcesso, setDesdobramento, setTipoDesdobramento, processoCadastro, setProcessoCadastro } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [processos, setProcessos] = useState<IProcesso[]>([]);
  const [carregandoProcessos, setCarregandoProcessos] = useState(false);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [pesquisar, setPesquisar] = useState(false);
  const [selecionarProcesso, setSelecionarProcesso] = useState(false);

  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaProcessoRequest>(filtroInicial);

  const [carregamentoAreaDireito, setCarregamentoAreaDireito] = useState<boolean>(false);
  const [areasDireitoOptions, setAreasDireitoOptions] = useState<any>([]);
  const [areaDireitoSelected, setAreaDireitoSelected] = useState([]);

  const [carregamentoStatusProcesso, setCarregamentoStatusProcesso] = useState<boolean>(false);
  const [StatusProcessoOptions, setStatusProcessoOptions] = useState<any>([]);
  const [StatusProcessoSelected, setStatusProcessoSelected] = useState([]);

  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [empresaSelected, setEmpresaSelected] = useState([]);

  const componentRef = useRef<any>();

  const navigate = useNavigate();

  useEffect(() => {

    carregarAreaDireito();

    carregarStatusProcesso();

    carregarEmpresa();

    setSelecionarProcesso(true);

  }, []);

  useEffect(() => {
    if (pesquisar) {
      carregarProcessos(filtrosPesquisa);
    }
  }, [pesquisar]);


  const returnOptionsStatusProcesso = (array: any) => {
    array.forEach((item: any) => {
      StatusProcessoOptions.push({
        value: item.statusProcessoId,
        label: item.nome,
      });
    });
    return setStatusProcessoOptions;
  };

  const returnOptionsAreasDireito = (array: any) => {
    array.forEach((item: any) => {
      areasDireitoOptions.push({
        value: item.areaDireitoId,
        label: item.nome,
      });
    });
    return setAreasDireitoOptions;
  };

  const carregarEmpresa = async () => {
    try {
      if (empresa.length > 0) return;
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa({
        limit: 100,
        codigo: "",
        status: 1,
        offset: 0,
        sort: "empresaId",
      });
      setEmpresa(returnOptionsEmpresa(resultado.data));
      setCarregamentoEmpresa(false);
    } catch (error: any) {
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
  }

  const returnOptionsEmpresa = (empresas: any) => {
    let empresasParse: any = [];
    empresas.forEach((item: any) => {
      empresasParse.push({
        value: item.empresaId,
        label: item.nomeFantasia,
      });
    });
    return empresasParse;
  };

  const carregarAreaDireito = async () => {
    try {
      setCarregamentoAreaDireito(true);
      let resultadoAreaDireito: IRecuperaLista<IAreaDireito>;
      resultadoAreaDireito = await AreaDireitoService.obterListaLimitada();
      setAreasDireitoOptions(returnOptionsAreasDireito(resultadoAreaDireito.data));
      setCarregamentoAreaDireito(false);
    } catch (error: any) {
      setCarregamentoAreaDireito(false);
      setAreasDireitoOptions([]);
    }
  }

  const carregarStatusProcesso = async () => {
    try {
      setCarregamentoStatusProcesso(true);
      let resultado: IRecuperaLista<IStatusProcesso>;
      resultado = await StatusProcessoService.obterStatusProcessos({
        codigo: "",
        nome: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "statusProcessoId",
      });
      setStatusProcessoOptions(returnOptionsStatusProcesso(resultado.data));
      setCarregamentoStatusProcesso(false);
    } catch (error: any) {
      setCarregamentoStatusProcesso(false);
      setStatusProcessoOptions([]);
    }
  }

  const carregarProcessos = async (filtro: FiltroPesquisaProcessoRequest) => {
    try {
      setCarregandoProcessos(true);

      let resultado: IRecuperaLista<IProcesso>;
      resultado = await ProcessoService.obterProcessos(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setProcessos(resultado.data);
      setCarregandoProcessos(false);
      setPesquisar(false);
    } catch (error: any) {
      setProcessos([]);
    } finally {
      setCarregandoProcessos(false);
      setPesquisar(false);
      setLoadingInicial(false);
    }
  }

  useEffect(() => {
    if (!loadingInicial) {
      setPesquisar(true);
    }

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  const montarParteContraria = (partes: IProcessoPartesContraria[]) => {
    let partesString = "";
    partes.forEach((parte) => {
      if (parte.principal === "True") {
        partesString += parte.nome + " - " + parte.formaParticipacaoNome;
      }
    });
    return partesString;
  };

  const iniciarProcesso = async (processoId: number) => {

    try {

      setCarregandoProcessos(true);

      let resultado: IRecuperaLista<IProcesso>;

      resultado = await ProcessoService.obterProcessoPorId(processoId);

      let processo = resultado.data[0];

      setProcesso(processo);

      setTipoDesdobramento(tipoDesdobramentoInicialValues);


      setDesdobramento({
        ...desdobramentoInicialValues,
        tipoDesdobramentoId: 0,
        modulo: EModulo.AreaDireito,
        processoId: processo.processoId,
        areaDireitoId: processo.areaDireitoId,
        categoriaId: processo.areaDireitoId,
        dataDistribuicao: formartDDMMYYYToDateTime(xFinderDistribuicao.dataDistribuicao),
        numero: xFinderDistribuicao.numeroProcesso,
      });

      navigate("/Desdobramento/Novo");

      setCarregandoProcessos(false);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message,
        showConfirmButton: true,
      });
    }
  };


  const colunas: TableColumn<IProcesso>[] = [
    {
      cell: (processo: IProcesso) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={() => iniciarProcesso(processo.processoId)}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                }}
              >
                Processo: {processo.numeroProcesso} - {montarParteContraria(processo.processoParteContraria)}
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{processo.statusProcessoNome}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {processo.processoId}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Área: </b> {processo.areaDireitoNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Comarca: </b>
                {processo.estadoNome} {processo.cidadeNome}-{processo.estadoUF}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Ação: </b> {processo.tipoAcaoNome}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Escritório: </b> {processo.escritorioNome}
              </p>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IProcesso>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  useEffect(() => {
    const IdsSelected: number[] = [];
    areaDireitoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, areaDireitoId: IdsSelected };
    });
  }, [areaDireitoSelected]);


  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusProcessoSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, statusProcessoId: IdsSelected };
    });
  }, [StatusProcessoSelected]);

  useEffect(() => {
    const IdsSelected: number[] = [];
    empresaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, empresa: IdsSelected };
    });
  }, [empresaSelected]);

  return (
    <>
      {selecionarProcesso &&
        <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
          <div className="modal-content">
            <div className="modal-body">
              <LayoutPageTitle title="Selecione o processo" />

              <FiltroModal
                setPesquisar={setPesquisar}
                exibirModal={exibirFormModal}
                filtro={filtrosPesquisa}
                setFiltro={setFiltrosPesquisa}
                toggleModal={toggleModal}
              />

              <div className="row">
                <div className="col-md-12" >
                  <form onSubmit={(e) => e.preventDefault()}
                    className="row g-3 mb-3 form">
                    <div className="col-12 col-lg-4 col-xl-4">
                      <label htmlFor="form-processoId" className="form-label fw-bolder text-orange">
                        Identificador:
                      </label>
                      <input
                        value={filtrosPesquisa.processoId}
                        onChange={(e) => {
                          setFiltrosPesquisa((oldState: any) => {
                            return { ...oldState, processoId: e.target.value };
                          });
                        }}
                        placeholder="ID"
                        type="number"
                        className={"form-control"}
                        id="form-processoId"
                      />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                      <label htmlFor="form-numeroProcesso" className="form-label fw-bolder text-orange">
                        Número do Processo:
                      </label>
                      <input
                        value={filtrosPesquisa.numeroProcesso}
                        onChange={(e) => {
                          setFiltrosPesquisa((oldState) => {
                            return { ...oldState, numeroProcesso: e.target.value };
                          });
                        }}
                        placeholder="Número do Processo"
                        type="text"
                        className={"form-control"}
                        id="form-numeroProcesso"
                      />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Empresa Principal:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoEmpresa}
                        disabled={carregamentoEmpresa}
                        options={empresa}
                        value={empresaSelected}
                        onChange={(event: any) => setEmpresaSelected(event)}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}

                      />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                      <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                        Área Direito:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoAreaDireito}
                        disabled={carregamentoAreaDireito}
                        options={areasDireitoOptions}
                        value={areaDireitoSelected}
                        onChange={(event: any) => setAreaDireitoSelected(event)}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}

                      />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                      <label htmlFor="form-parteContraria" className="form-label fw-bolder text-orange">
                        Parte Contrária:
                      </label>
                      <input
                        value={filtrosPesquisa.parteContraria}
                        onChange={(e) => {
                          setFiltrosPesquisa((oldState) => {
                            return { ...oldState, parteContraria: e.target.value };
                          });
                        }}
                        placeholder="Nome ou CPF/CNPJ"
                        type="text"
                        className={"form-control"}
                        id="form-parteContraria"
                      />
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                      <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                        Status:
                      </label>
                      <MultiSelect
                        isLoading={carregamentoStatusProcesso}
                        disabled={carregamentoStatusProcesso}
                        options={StatusProcessoOptions}
                        value={StatusProcessoSelected}
                        onChange={(event: any) => setStatusProcessoSelected(event)}
                        labelledBy={"Selecione..."}
                        overrideStrings={{
                          selectSomeItems: "Selecione...",
                          allItemsAreSelected: "Todos selecionados",
                          selectAll: "Selecione todos",
                          search: "Pesquise",
                          selectAllFiltered: "Selecione todos (filtrados)"
                        }}
                      />
                    </div>

                    <div className="col-12"
                      style={{ textAlign: 'right' }}>

                      <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                        <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                      </button>

                      <button
                        onClick={() => {
                          setFiltrosPesquisa(filtroInicial);
                          setPesquisar(false);
                          setAreaDireitoSelected([]);
                          setStatusProcessoSelected([]);
                          setEmpresaSelected([]);
                        }}
                        className="btn btn-sm btn-orange search-buttom-margin ms-2"
                      >
                        <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                      </button>

                      <button onClick={() => setExibirFormModal(!exibirFormModal)} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
                        <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                      </button>

                    </div>
                  </form>

                  <div className="col-md-12 mb-10" style={{ overflow: !carregandoProcessos ? "auto" : "scroll", height: "600px" }}>

                    <div ref={componentRef}>
                      <GridPadrao
                        onSort={handleSort}
                        progressPending={carregandoProcessos}
                        limit={filtrosPesquisa.limit}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationServer={true}
                        paginationTotalRows={filtrosPesquisa.totalItems}
                        colunas={colunas}
                        tipo="Processos"
                        itens={processos}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};
export default XFinderDesdobramentoModal;