import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPeriodicidade from "../interfaces/IPeriodicidade";
import { AdicionaPeriodicidadeRequest } from "../interfaces/Requests/Periodicidade/AdicionaPeriodicidadeRequest";
import FiltroPesquisaPeriodicidadeRequest from "../interfaces/Requests/Periodicidade/FiltroPesquisaPeriodicidadeRequest";
import { EditarPeriodicidadeRequest } from "../interfaces/Requests/Periodicidade/EditarPeriodicidadeRequest";

class PeriodicidadeService {

    async obterPeriodicidades(filtros: FiltroPesquisaPeriodicidadeRequest): Promise<IRecuperaLista<IPeriodicidade>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IPeriodicidade>>(`Periodicidade/RecuperaPeriodicidade?${query}`);

        return result;
    }

    async adicionaPeriodicidade(adicionaPeriodicidade: AdicionaPeriodicidadeRequest): Promise<IPeriodicidade> {
        let { data: result } = await api.post<IPeriodicidade>(`Periodicidade/AdicionaPeriodicidade`, adicionaPeriodicidade);

        return result;
    }


    async alterarStatus(periodicidadeId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPeriodicidade>(`Periodicidade/AtualizaPeriodicidadePorId?id=${periodicidadeId}`, data);
    }

    async atualizarPeriodicidade(editarPeriodicidade: EditarPeriodicidadeRequest): Promise<void> {

        let dataPeriodicidade = [
            { "op": "replace", "path": "/nome", "value": editarPeriodicidade.nome },
            { "op": "replace", "path": "/codigo", "value": editarPeriodicidade.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`Periodicidade/AtualizaPeriodicidadePorId?id=${editarPeriodicidade.periodicidadeId}`, dataPeriodicidade)
        ]);
    }
}


export default new PeriodicidadeService();