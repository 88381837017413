import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const Body = styled(Modal.Body)`
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 40px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;

  > div {
    display: flex;
    flex-direction: column;
  }

  > button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.span`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #2E3334;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #797C7F;
`;  

export const Layout = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 250px;
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
  
  div.section {
    display: flex;
    gap: 20px;
  }
  
  div.info-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > span {
    letter-spacing: -0.5px;
    color: #797C7F;
  }

  > strong {
    color: #2E3334;
  }
`;

export const Lawyer = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #7941dc;
  margin-right: -8px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: medium;
  text-transform: lowercase;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  
  > button {
    width: 100%;
    border: none;
    border-radius: 4px;
    height: 40px;
    letter-spacing: -0.5px;
    color: #2E3334;
    background-color: #F3F4F6;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 0 10px;
    transition: opacity 0.5ms ease-in;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > header {
    display: flex;
    align-items: center;
    gap: 20px;

    div.label {
      display: flex;
      align-items: center;
      gap: 4px;

      color: #2E3334;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.5px;
    }

    > button {
      border: none;
      background-color: #F3F4F6;
      padding: 5px 10px;
      border-radius: 4px;
      color: #2E3334;
      font-weight: 500;
      transition: opacity 0.5ms ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  div.column {
    display: flex;
    align-items: center;
    gap: 20px;

    div.user-info {
      display: flex;
      flex-direction: column;

      > p {
        margin: 0;
        padding: 0;
        color: #2E3334;
      }

      > span {
        color: #797C7F;
        font-size: 12px;
      }
    } 
  }
`;