import { faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import GridPadrao from "./../Comum/GridPadrao";
import IAreaDireito from "../../interfaces/IAreaDireito";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import FiltroPesquisaAreaDireitoRequest from "../../interfaces/Requests/AreaDireito/FiltroPesquisaAreaDireitoRequest";
import AreaDireitoService from "../../services/AreaDireitoService";

interface IAreaDireitoSelectedProps {
  setFieldValue: (field: string, value:any, ok:boolean)=>any;
  areasDireitoSelected: IAreaDireito[];
}

const AreaDireitoSelected = ({ setFieldValue, areasDireitoSelected }: IAreaDireitoSelectedProps) => {
  const [areasDireito, setAreaDireitos] = useState<IAreaDireito[]>([]);
  const [carregandoAreaDireito, setCarregandoAreaDireito] = useState(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaAreaDireitoRequest>({
    nome: "",
    codigo: "",
    status: 1,
    limit: 5,
    totalItems: 0,
    offset: 0,
    sort: "areaDireitoId",
  });

  useEffect(() => {
    if (areasDireito.length > 0) {
      areasDireitoSelected.forEach((area) => {
        const areasDireitoSelect = areasDireito.filter((e: IAreaDireito) => e.areaDireitoId === area.areaDireitoId)[0];
        if (areasDireitoSelect) areasDireitoSelect.checked = true;
      });
    }
    setAreaDireitos(areasDireito);
  }, [areasDireito, areasDireitoSelected]);

  const handleChange = (areaDireitoId: number) => {
    areasDireito.forEach((area) => {
      if (area.areaDireitoId === areaDireitoId) {
        if (!area.checked) {
          area.checked = true;
          setFieldValue(
            "areasDireito",
            areasDireito.filter((e: any) => e.checked),
            true
          );
          setAreaDireitos(areasDireito);
          return;
        }
        if (area.checked) {
          area.checked = false;
          setFieldValue(
            "areasDireito",
            areasDireito.filter((e: any) => e.checked),
            true
          );
          setAreaDireitos(areasDireito);
          return;
        }
      }
    });
  };

  async function carregarAreaDireito(filtro: FiltroPesquisaAreaDireitoRequest) {
    try {
      setCarregandoAreaDireito(true);
      let resultado: IRecuperaLista<IAreaDireito>;

      resultado = await AreaDireitoService.obterAreaDireitos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setAreaDireitos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setAreaDireitos([]);
    } finally {
      setCarregandoAreaDireito(false);
    }
  }

  const colunas: TableColumn<IAreaDireito>[] = [
    {
      name: "Id",
      sortField: "areaDireitoId",
      selector: (row: IAreaDireito) => row.areaDireitoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IAreaDireito) => row.nome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IAreaDireito) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IAreaDireito) => {
        return (
          <div>
            <label>
              <FormCheckInput
                name="terms"
                checked={row.checked}
                onClick={() => handleChange(row.areaDireitoId)}
                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              />
            </label>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (column: TableColumn<IAreaDireito>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  function limparFiltros() {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
      };
    });
  }

  useEffect(() => {
    carregarAreaDireito(filtrosPesquisa);
    
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarAreaDireito(filtrosPesquisa);
              }}
              className="row g-3 mb-3"
            >
              <div className="col-md-2 ">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Nome
                </label>
                <input
                  value={filtrosPesquisa.nome}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nome: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-nome"
                />
              </div>

              <div className="col-md-2 ">
                <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                  Código
                </label>
                <input
                  value={filtrosPesquisa.codigo}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, codigo: e.target.value };
                    });
                  }}
                  placeholder="Código"
                  type="text"
                  className={"form-control"}
                  id="form-codigo"
                />
              </div>

              <div className="col-md-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-orange search-buttom-margin "
                    onClick={(e) => {
                      carregarAreaDireito(filtrosPesquisa);
                    }}
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      limparFiltros();
                    }}
                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-12 mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoAreaDireito}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="AreaDireito"
              itens={areasDireito}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AreaDireitoSelected;
