/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Draggable } from "react-beautiful-dnd";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";

import { EModulo } from "../../../../enum";
import { ETelaEnum } from "../../../../enum/ETelaEnum";
import { tipoPorModulo } from "../../../../utils/Utils";
import { ETipoCampoEnum } from "../../../../enum/ETipoCampoEnum";

import { ICampo } from "../../../../interfaces/ICampo";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";


import ConfiguracaoTelaService from "../../../../services/ConfiguracaoTelaService";
import LimitacaoTabelaService from "../../../../services/LimitacaoTabelaService";

import { OrangeContext } from "../../../../contexts/OrangeProvider";
import ListaXSelected from "../../../../components/Comum/ListaXCamposSelected";

interface ItemProps {
  alterarItem(item: ICampo): void;
  item: ICampo;
  index: number;
  col: string;
}

const buscarEstilo = (isDragging: boolean, draggableStyle: any) => ({
  padding: 10,
  margin: `0 50px 15px 40px`,
  background: isDragging ? "rgb(241, 114, 83)" : "white",
  color: isDragging ? "white" : "black",
  border: `1px solid`,
  fontSize: `12px`,
  borderRadius: `5px`,
  cursor: "pointer",
  ...draggableStyle,
});

const FormXCampoParteContraria: React.FC<ItemProps> = ({ item, index, alterarItem, col }) => {
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoListaLimitadaCadastro, setCarregandoListaLImitadaCadastro] = useState<boolean>(false);
  const [adicionarListaLImitadaCadastro, setAdicionarListaLImitadaCadastro] = useState<boolean>(false);

  const { tela, tipoParteContraria, campos, setCampos } = useContext<OrangeInterfaceContext>(OrangeContext);
  const valoresIniciais = {
    campo: item.campo,
    entidade: item.entidade,
    campoNome: item.campoNome,
    ordenacao: item.ordenacao,
    tipo: item.tipo,
    isXCampo: item.isXCampo,
    nome: "",
    label: "",
    help: "",
    obrigatorio: false,
    somenteLeitura: false,
    Lista: [],
  } as ICampo;

  useEffect(() => {
    if (!carregandoListaLimitadaCadastro && exibirModal && (formik.values.tipo == ETipoCampoEnum.Lista || formik.values.tipo == ETipoCampoEnum.ListaMulti)) {
      carregarListaLimitadaCadastroPorCampo();
    }
  }, [exibirModal]);

  const getOpcoesXId = (Lista: any): number[] => {
    const OpcoesId: any[] = [];
    if (Lista && Lista.length > 0) {
      Lista.forEach((item: any) => {
        if (item) OpcoesId.push(item.xCampoOpcaoId);
      });
    }
    return OpcoesId;
  };

  const formik = useFormik({
    initialValues: valoresIniciais,
    onSubmit: async (values, { setSubmitting }) => {

      alterarItem(values);

      campos.splice(values.ordenacao, 0, { ...values });

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(tipoParteContraria.modulo)}Id=${tipoParteContraria.categoriaId}`);

      LimitacaoTabelaService.adicionaListaLimitadaSolucoes({
        ...objeto,
        modulo: EModulo.ParteContraria,
        campo: item.campo,
        opcaoId: getOpcoesXId(values.Lista),
        tipoParteContrariaId: tipoParteContraria.opcaoId
      });

      formik.setValues(valoresIniciais);

      setSubmitting(false);
    },
  });

  async function carregarListaLimitadaCadastroPorCampo() {
    try {
      setCarregandoListaLImitadaCadastro(true);
      let resultado: IRecuperaLista<any>;

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(tipoParteContraria.modulo)}Id=${tipoParteContraria.categoriaId}`);
      resultado = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
        ...objeto,
        tela: tela,
        campo: formik.values.campo,
        modulo: EModulo.ParteContraria,
        tipoParteContrariaId: tipoParteContraria.opcaoId,
      });

      formik.values.Lista = resultado.data;
      setCarregandoListaLImitadaCadastro(false);
    } catch (error: any) {
      setCarregandoListaLImitadaCadastro(false);
    }
  }

  const preencherCampos = () => {
    formik.setValues({ ...item, tipo: item.campoTipo || item.tipo });
    setExibirModal(!exibirModal);
  };

  return (
    <>
      <Draggable key={item?.campo} draggableId={item?.campo?.toString()} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && <>{item?.label}</>}
                {carregandoListaLimitadaCadastro && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                onClick={() => preencherCampos()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && !adicionarListaLImitadaCadastro && (
                  <>
                    X{item?.label} {item?.obrigatorio && "*"}
                  </>
                )}
                {(carregandoListaLimitadaCadastro || adicionarListaLImitadaCadastro) && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          )
        }
      </Draggable>

      <Modal size="lg" centered={true} show={exibirModal && !carregandoListaLimitadaCadastro} onHide={() => setExibirModal(!exibirModal)}>

        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Alterar Campo: {formik.values.campoNome} - Label:{formik.values.label}</h5>
            <div
              onClick={() => {
                formik.setValues(valoresIniciais);
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <form className="row g-3">
              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Nome
                  </label>
                  <input placeholder="Nome" {...formik.getFieldProps("label")} type="text" className={"form-control"}></input>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                    Texto de Ajuda
                  </label>
                  <input placeholder={formik.values.help} {...formik.getFieldProps("help")} type="text" className={"form-control"}></input>
                </div>
              </div>

              {(tela === ETelaEnum.TelaCadastroParteContraria) && (
                <div className="row mt-4">
                  <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                    Obrigatório:{" "}
                    <FormCheckInput
                      type="checkbox"
                      style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                      checked={formik.values.obrigatorio}
                      onChange={(e: any) => {
                        if (formik.values.obrigatorio) formik.setValues({ ...formik.values, obrigatorio: false });
                        if (!formik.values.obrigatorio) formik.setValues({ ...formik.values, obrigatorio: true });
                      }}
                    />
                  </label>
                </div>
              )}

              {(tela === ETelaEnum.TelaCapaParteContraria) && (
                <div className="row mt-4">
                  <label htmlFor="form-label" className="form-label fw-bolder text-orange">
                    Somente Leitura:{" "}
                    <FormCheckInput
                      type="checkbox"
                      style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
                      checked={formik.values.somenteLeitura}
                      onChange={(e: any) => {
                        if (formik.values.somenteLeitura) formik.setValues({ ...formik.values, somenteLeitura: false });
                        if (!formik.values.somenteLeitura) formik.setValues({ ...formik.values, somenteLeitura: true });
                      }}
                    />
                  </label>
                </div>
              )}

              <div className="row mt-4">
                <>
                  {tela === ETelaEnum.TelaCadastroParteContraria && (formik.values.tipo == ETipoCampoEnum.Lista || formik.values.tipo == ETipoCampoEnum.ListaMulti) && (
                    <>
                      <ListaXSelected
                        ListaSelected={formik.values.Lista || []}
                        xCampoId={formik.values.campo}
                        setFieldValue={formik.setFieldValue}
                      ></ListaXSelected>
                    </>
                  )}
                </>
              </div>
            </form>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            {(formik.values.tipo == ETipoCampoEnum.Lista || formik.values.tipo == ETipoCampoEnum.ListaMulti) ? (
              <button
                disabled={formik.values.Lista?.length === 0}
                onClick={() => {
                  setAdicionarListaLImitadaCadastro(true);
                  setCampos(campos.filter((e) => e.campo !== formik.values.campo));
                  formik.submitForm();
                  setExibirModal(!exibirModal);
                  setAdicionarListaLImitadaCadastro(false);
                }}
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            ) : (
              <button
                onClick={() => {
                  setCampos(campos.filter((e) => e.campo !== formik.values.campo));
                  formik.submitForm();
                  setExibirModal(!exibirModal);
                }}
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            )}

            <button
              onClick={() => {
                setExibirModal(!exibirModal);
                formik.setValues(valoresIniciais);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormXCampoParteContraria;
