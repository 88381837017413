import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaAutomacaoRequest from "../interfaces/Requests/Automacao/FiltroPesquisaAutomacaoRequest";
import { AdicionaAutomacaoRequest } from "../interfaces/Requests/Automacao/AdicionaAutomacaoRequest";
import { EditarAutomacaoRequest } from "../interfaces/Requests/Automacao/EditarAutomacaoRequest";
import { IAutomacao } from "../interfaces/Requests/Automacao/IAutomacao";

class AutomacaoService {

    async obterAutomacaos(filtros: FiltroPesquisaAutomacaoRequest): Promise<IRecuperaLista<IAutomacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IAutomacao>>(`Automacao/RecuperaAutomacao?${query}`);

        return result;
    }

    async obterTipos(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        let { data: result } = await api.get<IRecuperaLista<any>>(`Automacao/RecuperaTipoAutomacao?${query}`);

        return result;
    }

    async adicionaAutomacao(adicionaAutomacao: AdicionaAutomacaoRequest): Promise<IAutomacao> {
        const { data: result } = await api.post<IAutomacao>(
            'Automacao/AdicionaAutomacao',
            {
                nome: adicionaAutomacao.nome,
                codigo: adicionaAutomacao.codigo,
                modulo: adicionaAutomacao.modulo,
                tipo: parseInt(adicionaAutomacao.tipo)
            }
        );
    
        return result;
    }
    


    async alterarStatus(bancoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IAutomacao>(`Automacao/AtualizaAutomacaoPorId?id=${bancoId}`, data);
    }

    async atualizarAutomacao(editarAutomacao: EditarAutomacaoRequest): Promise<void> {

        let dataAutomacao = [
            { "op": "replace", "path": "/nome", "value": editarAutomacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarAutomacao.codigo },
            { "op": "replace", "path": "/tipo", "value": editarAutomacao.tipo },
            { "op": "replace", "path": "/modulo", "value": editarAutomacao.modulo }
        ]


        await Promise.all([
            await api.patch<void>(`Automacao/AtualizaAutomacaoPorId?id=${editarAutomacao.automacaoId}`, dataAutomacao)
        ]);
    }
}


export default new AutomacaoService();