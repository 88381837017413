import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoAquisicao from "../interfaces/ITipoAquisicao";
import { AdicionaTipoAquisicaoRequest } from "../interfaces/Requests/TipoAquisicao/AdicionaTipoAquisicaoRequest";
import FiltroPesquisaTipoAquisicaoRequest from "../interfaces/Requests/TipoAquisicao/FiltroPesquisaTipoAquisicaoRequest";
import { EditarTipoAquisicaoRequest } from "../interfaces/Requests/TipoAquisicao/EditarTipoAquisicaoRequest";

class TipoAquisicaoService {

    async obterTipoAquisicoes(filtros: FiltroPesquisaTipoAquisicaoRequest): Promise<IRecuperaLista<ITipoAquisicao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoAquisicao>>(`TipoAquisicao/RecuperaTipoAquisicao?${query}`);

        return result;
    }

    async adicionaTipoAquisicao(adicionaTipoAquisicao: AdicionaTipoAquisicaoRequest): Promise<ITipoAquisicao> {
        let { data: result } = await api.post<ITipoAquisicao>(`TipoAquisicao/AdicionaTipoAquisicao`, adicionaTipoAquisicao);

        return result;
    }


    async alterarStatus(tipoAquisicaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoAquisicao>(`TipoAquisicao/AtualizaTipoAquisicaoPorId?id=${tipoAquisicaoId}`, data);
    }

    async atualizarTipoAquisicao(editarTipoAquisicao: EditarTipoAquisicaoRequest): Promise<void> {

        let dataTipoAquisicao = [
            { "op": "replace", "path": "/nome", "value": editarTipoAquisicao.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoAquisicao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoAquisicao/AtualizaTipoAquisicaoPorId?id=${editarTipoAquisicao.tipoAquisicaoId}`, dataTipoAquisicao)
        ]);
    }
}


export default new TipoAquisicaoService();