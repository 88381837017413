import {
  Editor,
  Element,
  Node as SlateNode,
  Path,
  Transforms,
  Descendant,
} from "slate";
import {
  CustomEditor,
  CustomElement,
  FooterElement,
  HeaderElement,
  ImageElement,
  PageElement,
  ParagraphElement,
  TableElement,
} from "../../../../../utils/types/slate";
import { ReactEditor } from "slate-react";
import { DocumentStyle, Block } from "../../../types";
import { removeUnit } from "../../../components/Toolbar/controls/common";

type GetBlocksType = {
  childrens: Descendant[];
  firstPageDifferent: boolean;
};

export const CommonService = {
  htmlContent(editor: CustomEditor, slateElement: Descendant) {
    return ReactEditor.toDOMNode(editor, slateElement);
  },
  makeParagraph(): ParagraphElement {
    return {
      type: "paragraph",
      children: [{ text: "" }],
    };
  },
  makeImage(url: string, style: React.CSSProperties): ImageElement {
    return {
      type: "image",
      style,
      url,
      children: [{ text: "" }],
    };
  },
  addElement(
    editor: CustomEditor,
    element: Descendant | Descendant[],
    path: Path
  ) {
    Transforms.insertNodes(editor, element, { at: path });
  },
  removeElement(editor: CustomEditor, element: CustomElement) {
    const path = this.findElementPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  },
  removeElementByPath(editor: CustomEditor, path: Path) {
    Transforms.removeNodes(editor, { at: path });
  },
  focus(editor: CustomEditor, path: Path) {
    Transforms.select(editor, Editor.start(editor, path));
    ReactEditor.focus(editor);
  },
  compareValue(v1: Descendant, v2: CustomElement) {
    return JSON.stringify(v1) === JSON.stringify(v2);
  },
  findElementPath(editor: CustomEditor, element: CustomElement) {
    for (const [node, path] of Array.from(SlateNode.descendants(editor))) {
      if (this.compareValue(node, element)) {
        return path;
      }
    }
    return [];
  },
  elementByPath(editor: CustomEditor, path: Path) {
    const descendants = Array.from(SlateNode.descendants(editor));

    for (const descendant of descendants) {
      const [element, elementPath] = descendant;
      if (JSON.stringify(elementPath) === JSON.stringify(path)) {
        return element;
      }
    }
  },
  toSlate<T = CustomElement>(editor: CustomEditor, node: Node) {
    return ReactEditor.toSlateNode(editor, node) as T;
  },
  getPageMaxHeight(documentStyle: DocumentStyle) {
    const { page, content } = documentStyle;
    const pageHeight = removeUnit(page.height);
    const [topSpacing, bottomSpacing] = [
      removeUnit(content.marginBottom),
      removeUnit(content.marginTop),
    ];

    const spacing = topSpacing + bottomSpacing;
    return pageHeight - spacing;
  },
  getElementHeight(editor: CustomEditor, slateElement: Descendant) {
    if (Element.isElement(slateElement)) {
      const domElement = ReactEditor.toDOMNode(editor, slateElement);
      return domElement.getBoundingClientRect().height;
    }

    return 0;
  },
  addTable(editor: CustomEditor, table: TableElement) {
    const { selection } = editor;

    if (selection) {
      const selectionPath = selection.anchor.path.slice();

      this.removeElementByPath(editor, selectionPath);
      this.addElement(editor, table, selectionPath);

      const lastIndex = selectionPath.pop();

      if (lastIndex !== undefined) {
        const paragraphPath = selectionPath.concat(lastIndex + 1);
        this.addElement(editor, this.makeParagraph(), paragraphPath);
      }
    }
  },
  formatNodesToPublishVersion(editorValue: Descendant[]) {
    const nodes: CustomElement[] = [];

    for (const page of editorValue) {
      if (Element.isElement(page) && page.type === "page") {
        for (const element of page.children) {
          if (Element.isElement(element) && element.type === "content") {
            for (const child of element.children) {
              if (Element.isElement(child)) {
                nodes.push(child);
              }
            }
          }
        }
      }
    }
    return { nodes };
  },

  getDefaultBlock<Element>(editorValue: Descendant[], block: "header" | "footer") {
    let blockElement: CustomElement = {} as CustomElement

    editorValue.forEach(page => {
      const pageElement = page as PageElement
      pageElement.children.forEach(element => {
        if (Element.isElement(element) && element.type === block) {
          blockElement = element
        }
      })
    })

    return blockElement as Element
  },

  formatBlocksToPublishVersion({
    childrens,
    firstPageDifferent,
  }: GetBlocksType) {
    const defaultHeader = this.getDefaultBlock<HeaderElement>(childrens, "header")
    const defaultFooter = this.getDefaultBlock<FooterElement>(childrens, "footer")

    let header: Block<HeaderElement> = {
      default: defaultHeader,
      even: defaultHeader,
      firstPage: defaultHeader 
    }

    let footer: Block<FooterElement> = {
      default: defaultFooter,
      even: defaultFooter,
      firstPage: defaultFooter
    }

    if (firstPageDifferent) {
      const firstPage = childrens[0] as PageElement;
      const firstHeader = firstPage.children[0] as HeaderElement;
      const firstFooter = firstPage.children[2] as FooterElement;
      header.firstPage = firstHeader
      footer.firstPage = firstFooter
    }

    return { header, footer };
  },
};
