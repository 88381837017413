import React, { useContext, useEffect, useState } from "react";
import { faEraser, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Draggable } from "react-beautiful-dnd";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { Modal } from "react-bootstrap";
import { ICampo } from "../../../../interfaces/ICampo";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../../interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "../../../../contexts/OrangeProvider";
import AreaDireitoService from "../../../../services/AreaDireitoService";
import GridPadrao from "../../../../components/Comum/GridPadrao";
import FiltroPesquisaAreaDireitoRequest from "../../../../interfaces/Requests/AreaDireito/FiltroPesquisaAreaDireitoRequest";
import IAreaDireito from "../../../../interfaces/IAreaDireito";
import Swal from "sweetalert2";
import { TableColumn } from "react-data-table-component";
import IEmpresa from "../../../../interfaces/IEmpresa";
import EmpresaService from "../../../../services/EmpresaService";
import GrupoEconomicoService from "../../../../services/GrupoEconomicoService";

interface IAreaDireitoSelectedProps {
  setFieldValue: (field: string, value:any, ok:boolean)=>any;
  areasDireitoSelected: any;
  alterarItem(item: ICampo): void;
  item: ICampo;
  index: number;
  col: string;
}

const buscarEstilo = (isDragging: boolean, draggableStyle: any) => ({
  padding: 10,
  margin: `0 50px 15px 40px`,
  background: isDragging ? "var(--primary-base)" : "white",
  color: isDragging ? "white" : "black",
  border: `1px solid`,
  fontSize: `12px`,
  borderRadius: `5px`,
  cursor: "pointer",
  ...draggableStyle,
});


const FormECampo: React.FC<IAreaDireitoSelectedProps> = ({ item, index, alterarItem, col, setFieldValue, areasDireitoSelected}:IAreaDireitoSelectedProps) => {
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [carregandoListaLimitadaCadastro, setCarregandoListaLImitadaCadastro] = useState<boolean>(false);
  const [adicionarListaLImitadaCadastro, setAdicionarListaLImitadaCadastro] = useState<boolean>(false);
  const [tipoEmpresa, setTipoEmpresa] = useState<boolean>(false);

  const {setSelectedParametrizacoes, selectedParametrizacoes } = useContext<OrangeInterfaceContext>(OrangeContext);


  const [areasDireito, setAreaDireitos] = useState<IAreaDireito[]>([]);
  const [carregandoAreaDireito, setCarregandoAreaDireito] = useState(false);
  const [areasDireitoSelect, setAreasDireitoSelect] = useState<any>(selectedParametrizacoes.areasDireito);
  const [empresasSelect, setEmpresasSelect] = useState<any>(selectedParametrizacoes.empresas);
  
  const [carregamentoEmpresa, setCarregamentoEmpresa] = useState<boolean>(false);
  const [empresa, setEmpresa] = useState<any>([]);
  const [inputOptions, setInputOptions] = useState<any>([]);

  const [empresaTodos, setEmpresaTodos] = useState<boolean>(false);
  const [areaTodos, setAreaTodos] = useState<boolean>(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaAreaDireitoRequest>({ 
    nome: "",
    codigo: "",
    status: 1,
    limit: 5,
    totalItems: 0,
    offset: 0,
    sort: "areaDireitoId",
  });

  const [filtrosPesquisaEmpresa, setFiltrosPesquisaEmpresa] = useState({ 
    nome: "",
    codigo: "",
    status: 1,
    limit: 5,
    totalItems: 0,
    offset: 0,
    sort: "empresaId",
    grupoEconomico:"",
  });

  useEffect(()=>{
    
    areasDireitoSelect.forEach((areaSelected: any) => {
      areasDireito.forEach((area)=>{
        if(areaSelected.areaDireitoId == area.areaDireitoId){
          if(area.checked != false) area.checked = true;
        }
      })
    });
    
    empresasSelect.forEach((empresaSelected: any) => {
      empresa.forEach((empresa: any)=>{
        if(empresaSelected.empresaId == empresa.empresaId){
          empresa.checked = true;
        }
      })
    });
  }, [carregandoAreaDireito, carregamentoEmpresa]);


  useEffect(() => {
    if (areasDireitoSelected.length > 0) {
      areasDireitoSelected.forEach((area: any) => {
        const auxDireitoSelect = areasDireito.filter((e: IAreaDireito) => e.areaDireitoId === area.areaDireitoId)[0];
        setAreasDireitoSelect(auxDireitoSelect)
   
        
        if (auxDireitoSelect) auxDireitoSelect.checked = true;
      });

      
    }
    setAreaDireitos(areasDireito);
  }, [areasDireito, areasDireitoSelected]);

  const handleChange = (areaDireitoId: number) => {
    areasDireito.forEach((area) => {
      if (area.areaDireitoId === areaDireitoId) {
        if (!area.checked) {
          area.checked = true;
          setFieldValue(
            "areasDireito",
            areasDireito.filter((e: any) => e.checked),
            true
          );
          setAreaDireitos(areasDireito);

          setAreasDireitoSelect((prevItems: any) => [...prevItems, area]);
          return;
        }
        if (area.checked) {
          area.checked = false;
          setFieldValue(
            "areasDireito",
            areasDireito.filter((e: any) => e.checked),
            true
          );
          setAreaDireitos(areasDireito);
          setAreasDireitoSelect((prevItems: any) => prevItems.filter((item: any) => item.areaDireitoId !== areaDireitoId));
          return;
        }
        
      }
    });
  };

  const handleChangeEmpresa = (empresaId: number) => {
    empresa.forEach((empresaItem: any) => {
      if (empresaItem.empresaId === empresaId) {
        if (!empresaItem.checked) {
          empresaItem.checked = true;
          setFieldValue(
            "empresa",
            empresa.filter((e: any) => e.checked),
            true
          );
          setEmpresa(empresa);

          setEmpresasSelect((prevItems: any) => [...prevItems, empresaItem]);
          return;
        }
        if (empresaItem.checked) {
          empresaItem.checked = false;
          setFieldValue(
            "empresa",
            empresa.filter((e: any) => e.checked),
            true
          );
          setEmpresa(empresa);
          setEmpresasSelect((prevItems: any) => prevItems.filter((item: any) => item.empresaId !== empresaId));
          return;
        }
        
      }
    });
  };

  const handleChangeAllArea = (value: boolean) => {

    setAreaTodos(value);
-
    areasDireito.forEach((areaItem:any) => {
      if (!areaTodos) {
        areaItem.checked = true;
        setAreasDireitoSelect((prevItems: any) => {
          const itemExists = prevItems.some((item:any) => item.areaDireitoId === areaItem.areaDireitoId);
          if (!itemExists) {
            return [...prevItems, areaItem];
          }
          return prevItems;
        });
        return;
      } else {
        areaItem.checked = false;
        setAreasDireitoSelect((prevItems: any) => prevItems.filter((item: any) => item.areaDireitoId !== areaItem.areaDireitoId));
        return;
      }
    });
    setFieldValue("Lista", areasDireitoSelect, true);
  };

  const handleChangeAllEmpresa = (value: boolean) => {
    setEmpresaTodos(value);
    empresa.forEach((empresaItem:any) => {
      if (!empresaTodos) {
        empresaItem.checked = true;
        setEmpresasSelect((prevItems: any) => {
          const itemExists = prevItems.some((item:any) => item.empresaId === empresaItem.empresaId);
          if (!itemExists) {
            return [...prevItems, empresaItem];
          }
          return prevItems;
        });
        return;
      } else {
        empresaItem.checked = false;
        setAreasDireitoSelect((prevItems: any) => prevItems.filter((item: any) => item.empresaId !== empresaItem.empresaId));
        return;
      }
    });
    setFieldValue("Lista", empresasSelect, true);
  };

  async function carregarAreaDireito(filtro: FiltroPesquisaAreaDireitoRequest) {
    try {
      setCarregandoAreaDireito(true);
      let resultado: IRecuperaLista<IAreaDireito>;

      resultado = await AreaDireitoService.obterAreaDireitos(filtro);

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setAreaDireitos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setAreaDireitos([]);
    } finally {
      setCarregandoAreaDireito(false);
    }
  }

  const carregarEmpresa = async (filtros: any) => {
    try {
      setCarregamentoEmpresa(true);
      let resultado: IRecuperaLista<IEmpresa>;
      resultado = await EmpresaService.obterEmpresa(filtros);
      
      setEmpresa(resultado.data);
      
      
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoEmpresa(false);
      setEmpresa([]);
    }
    finally{
      setCarregamentoEmpresa(false);
    }
  }

  const colunas: TableColumn<IAreaDireito>[] = [
    {
      name: "Id",
      sortField: "areaDireitoId",
      selector: (row: IAreaDireito) => row.areaDireitoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IAreaDireito) => row.nome,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IAreaDireito) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IAreaDireito) => {
        return (
          <div className="form-switch">
            <label>
              <FormCheckInput
                name="terms"
                checked={row.checked}
                onClick={() => handleChange(row.areaDireitoId)}
                className="form-check-input"
              />
            </label>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const colunas2: TableColumn<IEmpresa>[] = [
    {
      name: "Id",
      sortField: "empresaId",
      selector: (row: IEmpresa) => row.empresaId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nomeFantasia",
      selector: (row: IEmpresa) => row.nomeFantasia,
      sortable: true,
      wrap: true,
    },

    {
      name: "Código",
      sortField: "codigo",
      selector: (row: IEmpresa) => row.codigo,
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      cell: (row: IEmpresa) => {
        return (
          <div className="form-switch">
            <label>
              <FormCheckInput
                name="terms"
                checked={row.checked}
                onClick={() => handleChangeEmpresa(row.empresaId)}
                className="form-check-input"
              />
            </label>
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
    
      
    
  };

  const handleSort = async (column: TableColumn<IAreaDireito>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  function limparFiltros() { 
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
      };
    });
    setFiltrosPesquisaEmpresa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
      };
    });
  }

  useEffect(() => {
    carregarAreaDireito(filtrosPesquisa);
    
    
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);


  useEffect(() => {

    async function fetchData() {
        try {
            const resultado = await GrupoEconomicoService.obterGrupoEconomicos({
                nome: '',
                codigo: '',
                limit: 50,
                status: 1,
                totalItems: 0,
                offset: 0,
                sort: 'nome'
            });
            setInputOptions(resultado.data);
        } catch (error) {
            console.error("Falha ao buscar configurações", error);
            setInputOptions([]); 
        }
    }
    fetchData();
}, []);

  return (
    <>
      <Draggable key={item?.campo} draggableId={item?.campo?.toString()} index={index}>
        {(provided: any) =>
          col === "todo" ? (
            <>
              <div
                key={index.toString()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && <>{item?.label}</>}
                {carregandoListaLimitadaCadastro && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                key={index.toString()}
                onClick={(e: any) => {
                  const tipo = e.target.innerText;
                  if(tipo === "Empresa"){
                    setTipoEmpresa(true);
                  }else{
                    setTipoEmpresa(false);
                  }
                setExibirModal(!exibirModal)}}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={buscarEstilo(true, provided.draggableProps.style)}
              >
                {!carregandoListaLimitadaCadastro && !adicionarListaLImitadaCadastro && (
                  <>
                    {item?.label} {item?.obrigatorio && "*"}
                  </>
                )}
                {(carregandoListaLimitadaCadastro || adicionarListaLImitadaCadastro) && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </div>
            </>
          )
        }
      </Draggable>
        {tipoEmpresa ?    
        <Modal size="lg" centered={true} show={exibirModal && !carregandoListaLimitadaCadastro} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <div
              onClick={() => {
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
          <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                
                
                carregarEmpresa(filtrosPesquisaEmpresa);
              }}
              className="row g-3 mb-3"
            >
              <div className="col-md-2 ">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Nome
                </label>
                <input
                  value={filtrosPesquisaEmpresa.nome}
                  onChange={(e) => {
                    setFiltrosPesquisaEmpresa((oldState) => {
                      return { ...oldState, nome: e.target.value };
                    });
                    setFiltrosPesquisaEmpresa((oldState) => {
                      return { ...oldState, sort: `empresaId&nomeFantasia=${e.target.value}` };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-nome"
                />
              </div>

              <div className="col-md-2 ">
                <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                  Código
                </label>
                <input
                  value={filtrosPesquisaEmpresa.codigo}
                  onChange={(e) => {
                    setFiltrosPesquisaEmpresa((oldState) => {
                      return { ...oldState, codigo: e.target.value };
                    });
                  }}
                  placeholder="Código"
                  type="text"
                  className={"form-control"}
                  id="form-codigo"
                />
              </div>

              <div className="col-md-2">
                            <label htmlFor="form-nome" className="form-label fw-bolder text-orange">Grupo Economico</label>
                            <select value={filtrosPesquisaEmpresa.grupoEconomico} onChange={(e) => {
                                    if(e.target.value !== "Sem Grupo"){
                                      setFiltrosPesquisaEmpresa(oldState => { return { ...oldState, grupoEconomico: e.target.value } });
                                    }else{
                                    setFiltrosPesquisaEmpresa({
                                      nome:filtrosPesquisaEmpresa.nome ,
                                      codigo: filtrosPesquisaEmpresa.codigo,
                                      status: 1,
                                      limit: 5,
                                      totalItems: 0,
                                      offset: 0,
                                      sort: filtrosPesquisaEmpresa.sort,
                                      grupoEconomico: "",
                                    });
                                  }
                                  
                                }} placeholder="Grupo economico" className={'form-select'} id="form-select" >
                                    {inputOptions.map((option: any, index: any) => (
                                        <option key={index} value={option.grupoEconomicoId}>{option.nome}</option>
                                    ))}

                                    <option value="">Sem Grupo</option>
                            </select>
              </div>

              <div className="col-md-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-orange search-buttom-margin "
                    onClick={() => {
                      carregarEmpresa(filtrosPesquisaEmpresa);
                    }}
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      limparFiltros();
                    }}
                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                  </button>
                </div>
              </div>
            </form>
            <div style={{display: "flex", flexDirection: "column"}} className="form-switch">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Selecionar pagina atual: {"  "}
              </label>
                <FormCheckInput
                style={{marginLeft: "0px"}}
                  name="terms"
                  checked={empresaTodos}
                  onChange={(e: any) => handleChangeAllEmpresa(e.target.checked)}
                 className="form-check-input"
                />
            </div>
          </div>
          <div className="col-md-12 mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregamentoEmpresa}
              limit={filtrosPesquisaEmpresa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisaEmpresa.totalItems}
              colunas={colunas2}
              tipo="AreaDireito"
              itens={empresa}
            />
          </div>
        </div>
      </div>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            {  
      
             (
              <button

                onClick={() => {
                  
                  const newState = {
                    fechamentoConfiguracaoId: selectedParametrizacoes.fechamentoConfiguracaoId,
                    areasDireito: selectedParametrizacoes.areasDireito,
                    empresas:empresasSelect
                }
                  setSelectedParametrizacoes(newState);
                
                  setExibirModal(!exibirModal);
                }} 
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            ) 

            }

            <button
              onClick={() => {
                setExibirModal(!exibirModal);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal> :    
      <Modal size="lg" centered={true} show={exibirModal && !carregandoListaLimitadaCadastro} onHide={() => setExibirModal(!exibirModal)}>
        <div className="modal-content">
          <div className="modal-header">
            <div
              onClick={() => {
                setExibirModal(!exibirModal);
              }}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            >
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
          <div className="row mt-2">
        <div className="col-md-12">
          <div className="col-md-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                carregarAreaDireito(filtrosPesquisa);
              }}
              className="row g-3 mb-3"
            >
              <div className="col-md-2 ">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Nome
                </label>
                <input
                  value={filtrosPesquisa.nome}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nome: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-nome"
                />
              </div>

              <div className="col-md-2 ">
                <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                  Código
                </label>
                <input
                  value={filtrosPesquisa.codigo}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, codigo: e.target.value };
                    });
                  }}
                  placeholder="Código"
                  type="text"
                  className={"form-control"}
                  id="form-codigo"
                />
              </div>

              <div className="col-md-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-orange search-buttom-margin "
                    onClick={(e) => {
                      carregarAreaDireito(filtrosPesquisa);
                    }}
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      limparFiltros();
                    }}
                    className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  >
                    <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
                  </button>
                </div>
              </div>
            </form>
            <div style={{display: "flex", flexDirection: "column"}} className="form-switch">
              <label htmlFor="form-codigo" className="form-label fw-bolder text-orange">
                Selecionar pagina atual: {"  "}
              </label>
                <FormCheckInput
                style={{marginLeft: "0px"}}
                  name="terms"
                  checked={areaTodos}
                  onChange={(e: any) => handleChangeAllArea(e.target.checked)}
                 className="form-check-input"
                />
            </div>
          </div>
          <div className="col-md-12 mb-10">
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoAreaDireito}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="AreaDireito"
              itens={areasDireito}
            />
          </div>
        </div>
      </div>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            {  
             (
              <button

                onClick={() => {

                  const newState = {
                    fechamentoConfiguracaoId: selectedParametrizacoes.fechamentoConfiguracaoId,
                    areasDireito: areasDireitoSelect,
                    empresas:selectedParametrizacoes.empresas
                }
                setSelectedParametrizacoes(newState);
                  setExibirModal(!exibirModal);
                }} 
                type="button"
                className="btn btn-orange me-5"
              >
                ALTERAR
              </button>
            ) 

            }

            <button
              onClick={() => {
                setExibirModal(!exibirModal);
              }}
              type="button"
              className="btn btn-danger ms-5"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </Modal>}
 
    </>
  );
};

export default FormECampo;
