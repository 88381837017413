import IDash from "../interfaces/IDash";
import IDocumento from "../interfaces/IDocumento";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import FiltroPesquisaDocumentoRequest from "../interfaces/Requests/Documento/FiltroPesquisaDocumentoRequest";
import api from "./Api";

class PowerBIService {

    public async solicitar(dashboard: IDash): Promise<any> {

        let { data: result } = await api.post('PowerBi/ExportarParaArquivo',
            {
                dashboardId: dashboard.dashboardId,
                formatoArquivo: dashboard.extensao
            });

        return result;
    }

    public async gerarRelatorioPowerBI(dashboard: IDash): Promise<any> {

        let query = `dashboardId=${dashboard.dashboardId}&formatoArquivo=${dashboard.extensao}`;

        const { data: result } = await api.get(`Dashboard/GerarRelatorioPowerBI?${query}`, { responseType: "blob" });

        return result;
    }

    public async obterPowerBiExport(filtros: FiltroPesquisaDocumentoRequest): Promise<any> {

        let { data: result } = await api.get<any>(`PowerBi/RecuperaPowerBiExport/${filtros.dashboardId}`);

        return result;
    }
}


export default new PowerBIService();