import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoEmpresa from "../interfaces/ITipoEmpresa";
import { AdicionaTipoEmpresaRequest } from "../interfaces/Requests/TipoEmpresa/AdicionaTipoEmpresaRequest";
import FiltroPesquisaTipoEmpresaRequest from "../interfaces/Requests/TipoEmpresa/FiltroPesquisaTipoEmpresaRequest";
import { EditarTipoEmpresaRequest } from "../interfaces/Requests/TipoEmpresa/EditarTipoEmpresaRequest";

class TipoEmpresaService {

    async obterTipoEmpresas(filtros: FiltroPesquisaTipoEmpresaRequest): Promise<IRecuperaLista<ITipoEmpresa>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoEmpresa>>(`TipoEmpresa/RecuperaTipoEmpresa?${query}`);

        return result;
    }

    async adicionaTipoEmpresa(adicionaTipoEmpresa: AdicionaTipoEmpresaRequest): Promise<ITipoEmpresa> {
        let { data: result } = await api.post<ITipoEmpresa>(`TipoEmpresa/AdicionaTipoEmpresa`, adicionaTipoEmpresa);

        return result;
    }


    async alterarStatus(tipoEmpresaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoEmpresa>(`TipoEmpresa/AtualizaTipoEmpresaPorId?id=${tipoEmpresaId}`, data);
    }

    async atualizarTipoEmpresa(editarTipoEmpresa: EditarTipoEmpresaRequest): Promise<void> {

        let dataTipoEmpresa = [
            { "op": "replace", "path": "/nome", "value": editarTipoEmpresa.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoEmpresa.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoEmpresa/AtualizaTipoEmpresaPorId?id=${editarTipoEmpresa.tipoEmpresaId}`, dataTipoEmpresa)
        ]);
    }
}


export default new TipoEmpresaService();