import './AuthWrapper.scss';
import { Navigate, Route, Routes } from 'react-router-dom'

import LoginForm from './LoginForm/LoginForm';
import ForgotPassword from './ForgotPasswordForm/ForgotPassword';
import LinkEditorPage from '../EditorPage/LinkEditorPage';

export default function AuthWrapper() {
    return (
        <div className="row col-md-12">
            <div className="col-md-8 d-flex flex-wrap align-items-center esconder-mobile" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/login-page.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                height: '100vh'
            }}>
                {/* <img alt='Logo' src='images/logo.png' className="img-fluid logoxjur-lg"  style={{ margin: '0 auto' }} /> */}
            </div>
            <div className='col-md-4'>
                <div className='content-login'>

                    <img className="logoxjur" alt='Logo' src={process.env.PUBLIC_URL + '/images/logo-branco.png'} />

                    <div id="card-login" className='card-login'>
                        <Routes>
                            <Route path='/Login'>
                                <Route index element={<LoginForm />} />
                            </Route>

                            <Route path="/EditorDocumento/:email/:hash/:id">
                                <Route index element={<LinkEditorPage />} />
                            </Route>

                            <Route path='/Esqueci-Minha-Senha'>
                                <Route index element={<ForgotPassword />} />
                            </Route>


                            <Route path='/' element={<Navigate replace to='/Login' />} />

                            <Route
                                path="*"
                                element={<Navigate to="/Login" />}
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    )
}