import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusDespesa from "../interfaces/IStatusDespesa";
import { AdicionaStatusDespesaRequest } from "../interfaces/Requests/StatusDespesa/AdicionaStatusDespesaRequest";
import FiltroPesquisaStatusDespesaRequest from "../interfaces/Requests/StatusDespesa/FiltroPesquisaStatusDespesaRequest";
import { EditarStatusDespesaRequest } from "../interfaces/Requests/StatusDespesa/EditarStatusDespesaRequest";

class StatusDespesaService {

    async obterStatusDespesas(filtros: FiltroPesquisaStatusDespesaRequest): Promise<IRecuperaLista<IStatusDespesa>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusDespesa>>(`StatusDespesa/RecuperaStatusDespesa?${query}`);

        return result;
    }

    async adicionaStatusDespesa(adicionaStatusDespesa: AdicionaStatusDespesaRequest): Promise<IStatusDespesa> {
        let { data: result } = await api.post<IStatusDespesa>(`StatusDespesa/AdicionaStatusDespesa`, adicionaStatusDespesa);

        return result;
    }


    async alterarStatus(statusDespesaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusDespesa>(`StatusDespesa/AtualizaStatusDespesaPorId?id=${statusDespesaId}`, data);
    }

    async atualizarStatusDespesa(editarStatusDespesa: EditarStatusDespesaRequest): Promise<void> {

        let dataStatusDespesa = [
            { "op": "replace", "path": "/nome", "value": editarStatusDespesa.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusDespesa.codigo },
            { "op": "replace", "path": "/cor", "value": editarStatusDespesa.cor }
        ]


        await Promise.all([
            await api.patch<void>(`StatusDespesa/AtualizaStatusDespesaPorId?id=${editarStatusDespesa.statusDespesaId}`, dataStatusDespesa)
        ]);
    }
}


export default new StatusDespesaService();