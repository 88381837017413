import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";

import IOption from "../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import AudienciaService from "../../services/AudienciaService";
import TipoAudienciaService from "../../services/TipoAudienciaService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import CamposCadastroDinamicos from "../../components/Comum/Solucao/CamposCadastroSolucoes";
import { tipoAudienciaInicialValues } from "../../contexts/InitialValuesContext";
import { tipoPorModulo } from "../../utils/Utils";

const AudienciaCadastroPage = () => {
  const [carregandoTiposAudiencia, setCarregandoTiposAudiencia] = useState<boolean>(false);

  const [options, setOptions] = useState<IOption[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { audiencia, setAudiencia, setTipoAudiencia, tipoAudiencia } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: any = {
    audienciaId: 0,
    principalId: 0,
    tipoAudienciaId: 0,

    modulo: EModulo.Audiencia,
    tela: ETelaEnum.TelaCadastroAudiencia,
    categoriaId: audiencia.categoriaId,

    processoId: audiencia.processoId,
    areaDireitoId: audiencia.areaDireitoId,

    codigo: "",

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Audiência",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro da audiência..",
            showConfirmButton: false,
          });

          Swal.showLoading();

          setAdicionarLoading(true);

          let resultado = await AudienciaService.adicionaAudiencia({
            ...values,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });


          Swal.hideLoading();

          setAudiencia({
            ...resultado,
            audienciaId: resultado.audienciaId,
            tipoAudienciaId: resultado.tipoAudienciaId,
            categoriaId: resultado.tipoAudienciaId,
            modulo: audiencia.modulo,
            processoId: audiencia?.processoId,
            areaDireitoId: audiencia.areaDireitoId,
          });

          setTipoAudiencia(tipoAudiencia);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Audiência cadastrada com sucesso`,
            timer: 4000,
          });

        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setTipoAudiencia(tipoAudienciaInicialValues);

    carregarTipoAudiencia();

    setConfiguracoesTela([]);

    formik.setValues(initialValues);

  }, []);

  useEffect(() => {
    if (tipoAudiencia.tipoAudienciaId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoAudiencia(tipoAudienciaInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoAudiencia.tipoAudienciaId]);

  const carregarTipoAudiencia = async () => {
    try {

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(audiencia.modulo)}Id=${audiencia.categoriaId}`);

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      setCarregandoTiposAudiencia(true);
      let resultado = await TipoAudienciaService.obterListaLimitadaTipoAudiencia({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        modulo: audiencia.modulo,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...objeto
      });

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));
      setOptions(options);

    } catch (error: any) {
      setOptions([]);
    } finally {
      setCarregandoTiposAudiencia(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(audiencia.modulo)}Id=${audiencia.categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        modulo: audiencia.modulo,
        tela: ETelaEnum.TelaCadastroAudiencia,
        tipoAudienciaId: tipoAudiencia.tipoAudienciaId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  const onChangeTipoAudiencia = (value: number, texto: string) => {
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoAudienciaId", value);

    formik.setFieldValue("categoriaId", value);

    setTipoAudiencia({
      tipoAudienciaId: value,
      nome: texto,
      opcaoNome: "",
      codigo: "",
      status: "",
      opcaoId: 0,
      categoriaNome: "",
      moduloNome: "",
      categoriaId: 0,
      checked: false,
      modulo: EModulo.Default
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Nova Audiência ${tipoAudiencia.tipoAudienciaId > 0 ? " - " + tipoAudiencia.nome : ""}`}>
        {audiencia.audienciaId > 0 ? (
          <>
            <button onClick={() => navigate("/Audiencia/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{audiencia.audienciaId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoAudienciaId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposAudiencia ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de audiencias...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Audiência:
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoAudienciaId)?.label,
                value: formik.values.tipoAudienciaId,
              }}
              onChange={(option: any) => onChangeTipoAudiencia(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default AudienciaCadastroPage;
