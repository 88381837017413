import React from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdOutlineLooks3,
  MdOutlineLooksOne,
  MdOutlineLooksTwo,
  MdOutlineTextFormat,
} from "react-icons/md";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { FontContext } from "../../../context/FontContext";
import { getNodeBySelection } from "../../Toolbar/controls/common";

type Heading = {
  type: "paragraph" | "heading-one" | "heading-two" | "heading-three";
  text: string;
  icon: JSX.Element;
};

const headings: Heading[] = [
  { type: "paragraph", text: "Texto normal", icon: <MdOutlineTextFormat /> },
  { type: "heading-one", text: "Título 1", icon: <MdOutlineLooksOne /> },
  { type: "heading-two", text: "Título 2", icon: <MdOutlineLooksTwo /> },
  { type: "heading-three", text: "Título 3", icon: <MdOutlineLooks3 /> },
];

export const FontHeading = () => {
  const editor = useSlateStatic();
  const [selectedHeading, setSelectedHeading] = useState(headings[0]);
  const { setFontSize } = React.useContext(FontContext);
  const [show, setShow] = useState(false);

  const activeHeading = (heading: Heading) => {
    const selectedNode = getNodeBySelection(editor)
    
    Transforms.setNodes(editor, {
      type: heading.type,
      style: selectedNode?.style,
      children: [{ text: "" }],
    });

    const fontSizes = {
      paragraph: 11,
      "heading-one": 20,
      "heading-two": 16,
      "heading-three": 14,
    };

    const fontSize = fontSizes[heading.type];
    setFontSize(fontSize);
    setSelectedHeading(heading);
    ReactEditor.focus(editor);
  };

  return (
    <Dropdown onToggle={() => setShow((prev) => !prev)} show={show}>
      <Dropdown.Toggle
        size="sm"
        variant="text"
        style={{ display: "flex", alignItems: "center" }}
      >
        {selectedHeading.text}
        {show ? <MdArrowDropUp size={20} /> : <MdArrowDropDown size={20} />}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {headings.map((heading) => (
          <Dropdown.Item
            key={heading.type}
            onClick={() => activeHeading(heading)}
            active={heading.type === selectedHeading.type}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              {heading.icon}
              <small>{heading.text}</small>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
