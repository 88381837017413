import "./styles/lib.css";
import "./styles/styles.scss";
import { useTenant } from "../../hooks/useTenant";
import { useRef, useState } from "react";
import { Container } from "./styles";
import { useCustomFlags } from "../../hooks/useCustomFlags";
import { useAuth } from "../../../../contexts/AuthProvider";
import { useSyncFusionEditor } from "./hooks/useSyncFusionEditor";
import { useToolbar } from "./hooks/useToolbar";
import { L10n, registerLicense } from "@syncfusion/ej2-base";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import ptBR from "./locale/pt-BR.json";
import { WordProcessorMenu } from "./components";
import { useVersionList } from "./hooks/useVersionList";
import { EditorLoader } from "../Loader";
import { ExportModal } from "./components/Export";
import { Versions } from "./components/Versions";
import { useMode } from "./hooks/useMode";

registerLicense(process.env.REACT_APP_REGISTER_LICENSE || "");
DocumentEditorContainerComponent.Inject(Toolbar);
L10n.load(ptBR);

export function WordProcessor() {
  const { mode, toggleHistoryMode } = useMode();
  const [containerComponent, setContainerComponent] =
    useState<DocumentEditorContainerComponent | null>(null);
  const container = useRef<DocumentEditorContainerComponent>(null);
  const { user } = useAuth();
  const { tenant, module } = useTenant();
  const { data: customFlags } = useCustomFlags({ tenant, modulo: module });
  const { controlTrackChanges, openDocument, customSettings } =
    useSyncFusionEditor();
  const { toolbarItems, onToolbarClick, isExporting, setIsExporting } =
    useToolbar();
  const { isLoading, versions } = useVersionList({
    limit: 1,
    offset: 0
  });

  if (isLoading && mode === 'default') {
    return <EditorLoader />;
  }

  function onCreate() {
    if (container.current) {
      setContainerComponent(container.current);
      const editor = container.current.documentEditor;
      openDocument(editor, version);
      if (customFlags) {
        customSettings(editor, customFlags);
      }
    }
  }

  function onChangeToHistoryMode(isRestored = false) {
    const editor = containerComponent?.documentEditor;
    const content = version?.jsonContent.sfdt;
    if (!editor || !content) {
      return;
    }

    toggleHistoryMode({
      data: JSON.stringify({ sfdt: content }),
      editor,
      isRestored,
    });
  }

  const version = versions?.data[0];
  const historyMode = mode === "history";
  const isDefaultMode = mode === "default";

  return (
    <Container historyMode={historyMode}>
      {isExporting && containerComponent && (
        <ExportModal
          editor={containerComponent.documentEditor}
          show={isExporting}
          onHide={() => setIsExporting((state) => !state)}
        />
      )}

      <div className="editor-wrapper">
        {containerComponent && (
          <WordProcessorMenu
            container={containerComponent}
            version={version}
            onChangeToHistoryMode={onChangeToHistoryMode}
          />
        )}

        <DocumentEditorContainerComponent
          ref={container}
          created={onCreate}
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          height="100%"
          locale="pt-BR"
          enableToolbar
          currentUser={user?.email}
          userColor={user?.corTemplate}
          beforeAcceptRejectChanges={(args) =>
            controlTrackChanges(args, customFlags)
          }
          toolbarItems={isDefaultMode ? toolbarItems(customFlags) : []}
          showPropertiesPane={isDefaultMode}
          toolbarClick={(args) =>
            onToolbarClick(args, containerComponent?.documentEditor)
          }
        />
      </div>

      {historyMode && containerComponent && (
        <Versions
          editor={containerComponent.documentEditor}
          toggleViewMode={() => onChangeToHistoryMode(true)}
        />
      )}
    </Container>
  );
}
