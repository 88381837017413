import api from "./Api";
import IFeedCount from "../interfaces/IFeedCount";

class FeedCountService {

    async obterFeedCount(): Promise<IFeedCount> {

        let { data: result } = await api.get<IFeedCount>(`Compromisso/FeedCount`);

        return result;
    }

}

export default new FeedCountService();