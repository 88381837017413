import {
  GoogleMap as Map,
  Marker,
  GoogleMapProps as Props,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { useState } from "react";
import { LatLng } from "./contract/MapContract";
import { MdHome } from "react-icons/md";
import { GOOGLE_MAPS_API_KEY } from "./util";

export interface GoogleMapProps extends Props {
  center: LatLng;
  formattedAddress: string;
}

const containerStyle = {
  width: "100%",
  height: "500px",
};

export const GoogleMap = (props: GoogleMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-maps-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [showInfo, setShowInfo] = useState(false);

  const [zoom, setZoom] = useState(15)

  const onLoad = () => {
    const bounds = new window.google.maps.LatLngBounds(props.center);
    map?.fitBounds(bounds);
    setMap(map);
  };

  const unmount = () => setMap(null);

  const handleShowInfo = (show: boolean) => {
    if (show) {
      setZoom(20)
      map?.panTo(props.center)
    } else {
      setZoom(18)
    }

    setShowInfo(show)
  };

  return isLoaded ? (
    <Map
      {...props}
      mapContainerStyle={containerStyle}
      zoom={zoom}
      center={props.center}
      onLoad={onLoad}
      onUnmount={unmount}
      options={{
        streetViewControl: false,
      }}
    >
      <Marker onClick={() => handleShowInfo(true)} position={props.center} />

      {showInfo && props.formattedAddress && (
        <InfoWindow onCloseClick={() => handleShowInfo(false)} position={props.center}>
          <div className="d-flex align-items-center gap-2">
            <MdHome size={20} />
            <strong>{props.formattedAddress}</strong>
          </div>
        </InfoWindow>
      )}
      <hr />
    </Map>
  ) : null;
};
