import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import IEnvelope from "../../../interfaces/IEnvelope";
import EnvelopeService from "../../../services/EnvelopeService";
import Swal from "sweetalert2";
import clsx from "clsx";

interface ICriarEnvelopeModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    onCreate: (envelopes: IEnvelope) => void,
    exibirModal: boolean,
    titulo: string,
}

const CriarEnvelopeModal = ({ toggleModal, exibirModal, titulo , onCreate}: ICriarEnvelopeModalProps) => {
    
    const novoEnvelopeValidacao = Yup.object().shape({
        arquivo: Yup.mixed().required("Arquivo obritagório.")
        .test(
            'type', 'O arquivo deve ser do tipos pdf.', (arquivo, contexto)=>{
                console.log(arquivo)
                const caminho = arquivo as string;
                return (
                    caminho !== undefined &&
                    (
                        caminho.endsWith('.pdf')
                    )
                );
            }
        ),
        codigo: Yup.string().required("Código obritagório.").min(5, "Código deve ter no mínimo 5 caracteres."),
    })

    
    const formularioNovoEnvelope = useFormik({
        initialValues: {arquivo: "", codigo: ""},
        validationSchema: novoEnvelopeValidacao,
        onSubmit: async (values, { setSubmitting }) => {
            const campo = document.querySelector('#form-arquivo')

            if(!values.arquivo || ! campo) return;

            const arquivos: FileList | null = (campo as HTMLInputElement).files;

            if(!arquivos || arquivos.length === 0) return;

            for(let i = 0; i < arquivos.length; i++){
                if(!['application/pdf'].includes(arquivos[i].type)){
                    return await Swal.fire({
                        title: 'Arquivo inválido.',
                        text: 'O arquivo deve ser do tipo pdf.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }

            const enviados = [];

            const fileName =  arquivos[0].name;

            Swal.fire({
                heightAuto: false,
                icon: "info",
                text: `Enviando arquivos...`,
                allowOutsideClick: false,
                showConfirmButton: false
            });

            Swal.showLoading()

            for(let i = 0; i < arquivos.length; i++){
                const dados = {
                    "Modulo": "2",
                    "Origem": "6",
                    "File": arquivos[i],
                    "TipoDocumentoId": "4",
                    "Descricao": "XSigner: Upload documento",
                    "Restrito": "false"
                }

                try {
                    enviados.push(await EnvelopeService.upload(dados));
                } catch(error: any) {
                    setSubmitting(false);
                    return Swal.fire({
                        heightAuto: false,
                        icon: 'error',
                        title: `Não foi possível Enviar o arquivo.`,
                        text:  (error.response)?(typeof error.response?.data === 'string' ? error.response.data : error.response.data.message): error.message,
                        showConfirmButton: true
                    });
                }
            }
            
            setSubmitting(false);

            Swal.close();
            onCreate( {
                envelopeId: -2,
                hashEnvelope: "",
                nomeDocumento: fileName,
                clienteId: -2,
                clienteNome: "",
                envioPorOrdem: false,
                status: -2,
                dataCadastro: "",
                usuarioIdCadastro: -2,
                documentoAssinadoId: -2,
                dataAssinatura: "",
                codigo: values.codigo,
                dataCancelado: "",
                usuarioIdCancelado: -2,
                integracaoD4Sign: false,
                signatarios: [],
                documentos: enviados.map(e =>({
                        envelopeDocumentosId: -2,
                        documentoId: e.id,
                        documentoUrl: e.arquivoB64,
                        ordem: 0,
                        dataCadastro: ""
                }))
            });
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModal} backdrop='static' onEscapeKeyDown={(e)=>{e.preventDefault(); return false}} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{titulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className='mx-2 text-orange' icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="row mt-2">
                            <div className="col-12 col-lg-6">
                                <label htmlFor="form-codigo" className="form-label required fw-bolder text-orange">Código</label>
                                <input {...formularioNovoEnvelope.getFieldProps('codigo')} placeholder="Insira o Código" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formularioNovoEnvelope.touched.codigo && formularioNovoEnvelope.errors.codigo,
                                    },
                                    {
                                        'is-valid': formularioNovoEnvelope.touched.codigo && !formularioNovoEnvelope.errors.codigo,
                                    }
                                )} id="form-codigo" />
                                {formularioNovoEnvelope.touched.codigo && formularioNovoEnvelope.errors.codigo && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioNovoEnvelope.errors.codigo}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-lg-6">
                                <label htmlFor="form-arquivo" className="form-label required fw-bolder text-orange">Arquivos</label>
                                <input {...formularioNovoEnvelope.getFieldProps('arquivo')} placeholder="Selecione os arquivos" type="file" multiple={true} className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formularioNovoEnvelope.touched.arquivo && formularioNovoEnvelope.errors.arquivo,
                                    },
                                    {
                                        'is-valid': formularioNovoEnvelope.touched.arquivo && !formularioNovoEnvelope.errors.arquivo,
                                    }
                                )} id="form-arquivo" />
                                {formularioNovoEnvelope.touched.arquivo && formularioNovoEnvelope.errors.arquivo && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formularioNovoEnvelope.errors.arquivo}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formularioNovoEnvelope.isSubmitting || !formularioNovoEnvelope.isValid} onClick={() => { formularioNovoEnvelope.submitForm() }} type="button" className="btn btn-orange me-5">
                        {!formularioNovoEnvelope.isSubmitting && <> SALVAR </>}
                        {formularioNovoEnvelope.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button disabled={formularioNovoEnvelope.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5"> CANCELAR </button>

                </div>
            </div>
        </Modal>
    </>
}

export default CriarEnvelopeModal;