import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import {  OverlayTrigger, Popover } from "react-bootstrap";
import NumberFormat from "react-number-format";
import clsx from "clsx";

import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ETipoCampoEnum, ECamposIDEnum, returnarEnumDescricaoID, EModulo, ETelaEnum } from "../../enum";
import { currencyFormatter } from "../../utils/Utils";

import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

interface ICamposDinamicosPropos {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
}

const CamposCadastroDinamicos = ({ configuracaoTela, formik }: ICamposDinamicosPropos) => {
  const [campos, setCampos] = useState<any[]>([]);
  const [genericId, setGenericId] = useState<number>(0);

  useEffect(() => {
    if (configuracaoTela.campoTipo == ETipoCampoEnum.Lista) {
      carregaLista();
    }
  }, [formik.values.tipoAtoId]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const carregaLista = async () => {
    try {
      if (configuracaoTela.isXCampo) {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
          tipoAtoId: formik.values.tipoAtoId,
          tela: ETelaEnum.TelaCadastroAtoSocietario,
          campo: configuracaoTela.campo,
          modulo: EModulo.AtoSocietario
        });
        setCampos(campos.data);
      } else {
        let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(configuracaoTela?.campoNome, EModulo.AtoSocietario, formik.values.tipoAtoId, 0);
        setCampos(campos.data);
      }
    } catch (error: any) {
      setCampos([]);
    }
  };

  const onChangeSelect = (value: string, nome: string) => {
    formik.setFieldTouched(`${nome.toLowerCase()}Id`, true);
    formik.setFieldValue(`${nome.toLowerCase()}Id`, parseInt(value));
    setGenericId(parseInt(value));
  };

  const renderInput = () => {
    let component = <></>;
    switch (configuracaoTela?.campoTipo) {
      case ETipoCampoEnum.Lista:

        component = (
          <>
            <select
              value={genericId}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              onChange={(e) => onChangeSelect(e.target.value, configuracaoTela?.campoNome || "")}
              placeholder={configuracaoTela?.campoNome}
              id={`form-${configuracaoTela?.campoNome}`}
            >
              <option value="0"> Selecione </option>
              {configuracaoTela.isXCampo ?
                campos?.map((item: any) => {
                  return (
                    <option key={item[`xCampoOpcaoId`]} value={item[`xCampoOpcaoId`]}>
                      {item.nome}
                    </option>
                  );
                }) :
                campos?.map((item: any) => {
                  return (
                    <option key={item[`${configuracaoTela?.campoNome.toLowerCase()}Id`]} value={item[returnarEnumDescricaoID(configuracaoTela?.campoNome || "")]}>
                      {item.nome}
                    </option>
                  );
                })}
            </select>
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}Id`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}Id`)}</span>
                </div>
              </div>
            )}
          </>
        );

        break;

      case ETipoCampoEnum.Valor:
        component = (
          <>
            <NumberFormat
              format={currencyFormatter}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              thousandSeparator={true}
              prefix={"R$"}
              onValueChange={(values: any) => {
                formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}`, true);
                formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}`, values.floatValue / 100);
              }}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Inteiro:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="number"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="checkbox"
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="date"
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <textarea
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              maxLength={500}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,

              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Texto:
        component = (
          <>{
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="text"
              maxLength={500}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome}`}
            />
          }
            {eval(`formik.touched.${configuracaoTela?.campoNome.toLowerCase()}`) && eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{eval(`formik.errors.${configuracaoTela?.campoNome.toLowerCase()}`)}</span>
                </div>
              </div>
            )}
          </>
        );
        break;
    }

    return component;
  };

  const renderClassName = () => {
    if (configuracaoTela?.campoTipo === ETipoCampoEnum.Pessoa ||
      configuracaoTela?.campoTipo === ETipoCampoEnum.Empresa ||
      configuracaoTela?.campoNome === ECamposIDEnum.CentroCusto) {
      return "col-md-12 mt-3";
    } else {
      return "col-md-3 mt-3";
    }
  };

  return (
    <>
      <div className={renderClassName()}>
        <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
          {configuracaoTela.label}
          <a style={{ fontSize: "12px" }}>
            {configuracaoTela.obrigatorio ? "*:" : ":"}
            {configuracaoTela.help && <PopoverHelper></PopoverHelper>}
          </a>
        </label>
        <div className="row">
          <div className="col-md-12">{renderInput()}</div>
        </div>
      </div>
    </>
  );
};

export default CamposCadastroDinamicos;
