import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ICampo } from "../../../interfaces/ICampo";
import { IXCampoField } from "../../../interfaces/IXCampoField";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import FiltroPesquisaCamposXRequest from "../../../interfaces/Requests/CamposX/FiltroPesquisaCamposXRequest";

import CamposXService from "../../../services/CamposXService";
import GridPadrao from "../../../components/Comum/GridPadrao";
import { EModulo } from "../../../enum";
import { tipoPorModulo } from "../../../utils/Utils";

interface ICamposXFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  setCamposList(campos: ICampo[]): void;
  camposList: ICampo[];
  modulo: EModulo,
  categoriaId?: number;
}

const CamposXField = ({ toggleModal, exibirModal, camposList, modulo, categoriaId }: ICamposXFieldProps) => {
  const [camposXs, setCamposXs] = useState<IXCampoField[]>([]);
  const [carregandoCamposXs, setCarregandoCamposXs] = useState(false);
  const [filtros, setFiltros] = useState<FiltroPesquisaCamposXRequest>({
    isXCampo: true,
    status: 1,
    limit: 10,
    totalItems: 0,
    offset: 0,
    codigo: "",
    nome: "",
    sort: "campoId",
    modulo: modulo
  });

  const selecionar = (campo: IXCampoField) => {
    let objeto: any = {};
    objeto[`${tipoPorModulo(modulo)}Id`] = categoriaId;
    camposList.push({
      campo: campo.camposId,
      camposId: campo.camposId,
      campoNome: campo.nome,
      modulo: modulo,
      ordenacao: 0,
      tipo: campo.tipo,
      entidade: "XCampos",
      help: "",
      label: campo.label,
      obrigatorio: false,
      somenteLeitura: false,
      isXCampo: true,
      ...objeto
    });
  };

  const carregarXCampos = async (filtro: FiltroPesquisaCamposXRequest) => {
    try {
      setCarregandoCamposXs(true);

      let resultado: IRecuperaLista<IXCampoField>;
      resultado = await CamposXService.obterCamposX(filtro);

      setFiltros((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setCamposXs(resultado.data);
      setCarregandoCamposXs(false);
    } catch (error: any) {
      setCamposXs([]);
      setCarregandoCamposXs(false);
    }
  };

  const colunas: TableColumn<IXCampoField>[] = [
    {
      name: "Id",
      sortField: "camposId",
      selector: (row: IXCampoField) => row.camposId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IXCampoField) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Label",
      sortField: "label",
      selector: (row: IXCampoField) => row.label,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo",
      sortField: "tipo",
      selector: (row: IXCampoField) => row.tipo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Selecionar?",
      cell: (row: IXCampoField) => {
        return (
          camposList.filter((e) => e.campo == row.camposId).length == 0 && (
            <div>
              <FormCheckInput
                name="terms"
                checked={row.checked}
                onClick={() => selecionar(row)}
                style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              />
            </div>
          )
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltros((oldState) => {
      return { ...oldState, offset: (page - 1) * filtros.limit };
    });
  };

  const handleSort = async (column: TableColumn<IXCampoField>, sortDirection: string) => {
    setFiltros((oldState) => {
      return { ...oldState, sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}` };
    });
  };

  useEffect(() => {
    if (exibirModal) carregarXCampos(filtros);
  }, [filtros.offset, filtros.limit, filtros.sort, exibirModal]);

  const cancelar = () => {
    toggleModal();
  };

  const adicionar = () => toggleModal()


  return (
    <Modal size="lg" centered={true} show={exibirModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Deseja adicionar um XCampo?</h5>
          <div onClick={() => cancelar()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row mt-5">
            <div className="col-md-auto">
              <input
                value={filtros.codigo}
                onChange={(e) => {
                  setFiltros((oldState) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={clsx("form-control")}
              />
            </div>
            <div className="col-md-auto">
              <input
                value={filtros.nome}
                onChange={(e) => {
                  setFiltros((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={clsx("form-control")}
              />
            </div>

            <div className="col-md-auto">
              <button
                onClick={() => {
                  carregarXCampos(filtros);
                }}
                className="btn btn-orange"
              >
                {<> Pesquisar </>}
              </button>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <GridPadrao
                onSort={handleSort}
                progressPending={carregandoCamposXs}
                limit={filtros.limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                paginationTotalRows={filtros.totalItems}
                colunas={colunas}
                tipo="CamposXs"
                itens={camposXs}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button onClick={() => adicionar()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button onClick={() => cancelar()} type="button" className="btn btn-danger ms-5">
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CamposXField;
