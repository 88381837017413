import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { faClone, faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { ETelaEnum } from "../../../enum/ETelaEnum";

import TipoProcuracaoService from "../../../services/TipoProcuracaoService";

import ITipoProcuracao from "../../../interfaces/ITipoProcuracao";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaTipoProcuracaoRequest from "../../../interfaces/Requests/TipoProcuracao/FiltroPesquisaTipoProcuracaoRequest";

import GridPadrao from "../../../components/Comum/GridPadrao";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import "../Styles/icons.css";
import DuplicarParametrizacaoModal from "../../../components/DuplicarParametrizacao/DuplicarParametrizacaoModal";

const TipoProcuracaoPesquisaPage = () => {
  const [tipoProcuracaos, setTipoProcuracaos] = useState<ITipoProcuracao[]>([]);
  const [tipoProcuracaosClone, setTipoProcuracaosClone] = useState<
    ITipoProcuracao[]
  >([]);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [carregandoTipoProcuracao, setCarregandoTipoProcuracao] =
    useState(false);
  const [exibirModal, setExibirModal] = useState(false);
  const { setTipoProcuracao, setCampos, setTela } =
    useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();

  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaTipoProcuracaoRequest>({
      nome: "",
      codigo: "",
      status: 1,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "tipoProcuracaoId",
    });

  useLayoutEffect(() => {
    setCampos([]);

    setTela(ETelaEnum.Default);

    if (!carregandoTipoProcuracao) carregarTipoProcuracao(filtrosPesquisa);
  }, []);

  async function carregarTipoProcuracao(
    filtro: FiltroPesquisaTipoProcuracaoRequest
  ) {
    try {
      setCarregandoTipoProcuracao(true);
      let resultado: IRecuperaLista<ITipoProcuracao>;
      resultado = await TipoProcuracaoService.obterTipoProcuracoes(filtro);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setTipoProcuracaos(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoProcuracaos([]);
    } finally {
      setCarregandoTipoProcuracao(false);
      setLoadingInicial(false);
    }
  }

  async function carregarTipoProcuracaoClone() {
    try {
      let resultado: IRecuperaLista<ITipoProcuracao>;
      resultado = await TipoProcuracaoService.obterTipoProcuracoes({
        codigo: "",
        limit: 0,
        nome: "",
        offset: 0,
        sort: "nome",
        status: 1,
        totalItems: 0,
      });

      setTipoProcuracaosClone(resultado.data);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  const colunas: TableColumn<ITipoProcuracao>[] = [
    {
      name: "Id",
      sortField: "TipoProcuracaoId",
      selector: (row: ITipoProcuracao) => row.tipoProcuracaoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "TipoProcuracaoNome",
      selector: (row: ITipoProcuracao) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Código",
      sortField: "codigo",
      selector: (row: ITipoProcuracao) => row.codigo,
      sortable: true,
      wrap: true,
    },
  ];

  const onRowClicked = (row: ITipoProcuracao) => {
    setTipoProcuracao(row);
    navigate("/Procuracao/ConfiguracaoTela/Solucoes");
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  const handleSort = async (
    column: TableColumn<ITipoProcuracao>,
    sortDirection: string
  ) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
  };

  const limparFiltros = () => {
    setFiltrosPesquisa((oldValue) => {
      return {
        ...oldValue,
        nome: "",
        codigo: "",
        status: 1,
      };
    });
  };

  useEffect(() => {
    if (!loadingInicial) {
      carregarTipoProcuracao(filtrosPesquisa);
    }
  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort]);

  return (
    <>
      <LayoutPageTitle title="Selecione o tipo de procuração que você deseja configurar">
        {" "}
      </LayoutPageTitle>

      <DuplicarParametrizacaoModal
        exibirModal={exibirModal}
        handleClose={async () => {
          setExibirModal(false);

          await carregarTipoProcuracao(filtrosPesquisa);
        }}
        itens={tipoProcuracaosClone}
        idKey="tipoProcuracaoId"
        modalTitle="Copiar configurações de um Tipo de Procuração para o outro"
        moduloId={21}
      />

      <div className="row mt-2">
        <div className="col-md-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              carregarTipoProcuracao(filtrosPesquisa);
            }}
            className="row g-3 mb-3"
          >
            <div className="col-md-2 ">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Nome
              </label>
              <input
                value={filtrosPesquisa.nome}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, nome: e.target.value };
                  });
                }}
                placeholder="Nome"
                type="text"
                className={"form-control"}
                id="form-nome"
              />
            </div>
            <div className="col-md-2 ">
              <label
                htmlFor="form-codigo"
                className="form-label fw-bolder text-orange"
              >
                Código
              </label>
              <input
                value={filtrosPesquisa.codigo}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, codigo: e.target.value };
                  });
                }}
                placeholder="Código"
                type="text"
                className={"form-control"}
                id="form-codigo"
              />
            </div>
            <div className="col-md-2">
              <label
                htmlFor="form-nome"
                className="form-label fw-bolder text-orange"
              >
                Status
              </label>
              <select
                value={filtrosPesquisa.status}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState) => {
                    return { ...oldState, status: parseInt(e.target.value) };
                  });
                }}
                placeholder="Nome"
                className={"form-select"}
                id="form-select"
              >
                <option value="0" label="Todos">
                  {" "}
                  Todos{" "}
                </option>
                <option value="1" label="Ativo">
                  {" "}
                  Ativo{" "}
                </option>
                <option value="-1" label="Inativo">
                  {" "}
                  Inativo{" "}
                </option>
              </select>
            </div>
            <div className="col-md-2">
              <div>
                <button
                  type="submit"
                  className="btn btn-sm btn-orange search-buttom-margin "
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faSearch}
                  />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    limparFiltros();
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faEraser}
                  />
                </button>
                <button
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  onClick={async (e) => {
                    e.preventDefault();
                    await carregarTipoProcuracaoClone();
                    setExibirModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    color="white"
                    className="mx-2"
                    icon={faClone}
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
          <GridPadrao
            onSort={handleSort}
            onRowClicked={onRowClicked}
            progressPending={carregandoTipoProcuracao}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Tipo Procuracao"
            itens={tipoProcuracaos}
          />
        </div>
      </div>
    </>
  );
};

export default TipoProcuracaoPesquisaPage;
