import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IChangeLog from "../interfaces/IChangeLog";
import FiltroPesquisaChangeLogRequest from "../interfaces/Requests/ChangeLog/FiltroPesquisaChangeLogRequest";
import { AdicionaChangeLogRequest } from "../interfaces/Requests/ChangeLog/AdicionaChangeLogRequest";
import { AtualizarChangeLogPorIdRequest } from "../interfaces/Requests/ChangeLog/AtualizarChangeLogPorIdRequest";
import IEmailUpdate from "../interfaces/IEmailUpdate";

class EmailUpdateService {

    async enviaEmail(EmailUpdate: IEmailUpdate): Promise<IEmailUpdate> {
        let { data: result } = await api.post(`ChangeLog/EnviaEmail`, EmailUpdate);

        return result;
    }
    
}
export default new EmailUpdateService();