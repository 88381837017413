/* eslint-disable no-case-declarations */
import { useEffect, useState } from "react";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import { MultiSelect } from "react-multi-select-component";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { faEdit, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import XCampoService from "../../../services/XCampoService";
import AreaDireitoService from "../../../services/AreaDireitoService";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import IOption from "../../../interfaces/IOption";
import IEmpresa from "../../../interfaces/IEmpresa";
import IAreaDireito from "../../../interfaces/IAreaDireito";
import { IRecuperaConfiguracaoTelaResponse } from "../../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { ETipoCampoEnum } from "../../../enum/ETipoCampoEnum";
import { currencyFormatterPerc } from "../../../utils/Utils";

import { ECamposIDEnum, returnarEnumDescricaoID } from "../../../enum/ECamposIDEnum";

import CidadeField from "../EstadoCidade/CidadeField";
import EstadoField from "../EstadoCidade/EstadoField";
import CurrencyField from "../CurrencyBRL/CurrencyField";
import AreaTextoField from "../AreaTexto/AreaTextoField";
import EmpresaService from "../../../services/EmpresaService";

interface ICamposDinamicosPropos {
  configuracaoTela: IRecuperaConfiguracaoTelaResponse;
  formik: any;
}

const CamposPesquisaDinamicos = ({ configuracaoTela, formik }: ICamposDinamicosPropos) => {

  const [empresaIdMulti, setEmpresaIdMulti] = useState<IOption[]>([]);
  const [empresaIdMultiSelected, setEmpresaIdMultiSelected] = useState<IOption[]>([]);

  const [genericIdMulti, setGenericIdMulti] = useState<IOption[]>([]);
  const [genericIdMultiSelected, setGenericIdMultiSelected] = useState<IOption[]>([]);

  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);

  const [filtros] = useState<any>({
    isTelaPesquisaProcesso: true,
    nome: "",
    status: 1,
    offset: 0,
    codigo: "",
    limit: 1000,
    sort: "nome",
    totalPaginas: 0,
    grupoEconomicoId: 0,
  });

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{configuracaoTela.help}</Popover.Body>
    </Popover>
  );

  const PopoverHelper = () => (
    <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={popover}>
      <a style={{ cursor: "pointer" }}>
        <FontAwesomeIcon className="mx-1 text-orange" icon={faQuestionCircle} />
      </a>
    </OverlayTrigger>
  );

  const naoChamar = (): boolean => {
    if (configuracaoTela.campoNome === ECamposIDEnum.Empresa) {
      return false;
    }

    if (configuracaoTela.campoNome === ECamposIDEnum.AreaDireito) {
      return false;
    }

    if (configuracaoTela.campoNome === ECamposIDEnum.ParteContraria) {
      return false;
    }

    return true;
  };


  const options = async () => {
    try {
      let options: IOption[] = [];

      if (configuracaoTela.isXCampo) {
        const itens = await XCampoService.obterCampo({ ...filtros, campoId: configuracaoTela?.campo });
        options = itens.data.map((item: any) => ({ value: item.xCampoOpcaoId, label: item.nome }));
      } else if (naoChamar()) {
        const itens = await ConfiguracaoTelaService.obterCampo(filtros, configuracaoTela.campoNome);
        options = itens.data.map((item: any) => {
          const label = configuracaoTela.campoNome === ETipoCampoEnum.Empresa
            ? item.razaoSocial
            : configuracaoTela.campoNome === ECamposIDEnum.Advogado
              ? item.pessoaNome
              : item.nome;
          return {
            value: item[returnarEnumDescricaoID(configuracaoTela.campoNome, false)],
            label,
          };
        });
      }

      setGenericIdMulti(options);
    } catch (error: any) {
      console.error("Error fetching options:", error);
      setGenericIdMulti([]);
    }
  };

  const carregarAreaDireito = async () => {
    try {
      const itens = await AreaDireitoService.obterListaLimitada();
      const options = itens.data.map((item: IAreaDireito) => ({ value: item.areaDireitoId, label: item.nome }));
      setGenericIdMulti(options);
    } catch (error: any) {
      console.error("Error fetching area direito:", error);
      setGenericIdMulti([]);
    }
  };

  const carregarEmpresa = async (grupoEconomicoId: IOption[]) => {
    try {
      const itens = await EmpresaService.obterEmpresaPorGrupoEconomico(grupoEconomicoId);
      const options = itens.data.map((item: IEmpresa) => ({ value: item.empresaId, label: item.razaoSocial }));
      setEmpresaIdMulti(options);
    } catch (error: any) {
      console.error("Error fetching empresa:", error);
      setEmpresaIdMulti([]);
    }
  };


  useEffect(() => {
    if (configuracaoTela.campoTipo === ETipoCampoEnum.Lista || configuracaoTela.campoNome === ECamposIDEnum.Empresa) options();
  }, []);

  useEffect(() => {
    if (configuracaoTela.campoNome === ECamposIDEnum.Advogado || configuracaoTela.campoNome === ECamposIDEnum.AdvogadoInterno) { options(); }
  }, []);

  useEffect(() => {

    if (configuracaoTela?.campoNome == ECamposIDEnum.GrupoEconomico) {
      if (formik.values.grupoeconomicoId?.length > 0) {
        carregarEmpresa(formik.values.grupoeconomicoId);
      }
    }

  }, [genericIdMultiSelected?.length]);

  useEffect(() => {
    if (configuracaoTela.campoNome === ECamposIDEnum.AreaDireito) carregarAreaDireito();
  }, []);

  useEffect(() => {
    if (configuracaoTela.campoNome === ECamposIDEnum.Empresa) carregarEmpresa([]);
  }, []);


  useEffect(() => {
    if (configuracaoTela.campoTipo === ETipoCampoEnum.Lista || configuracaoTela.campoTipo === ETipoCampoEnum.ListaMulti) {
      formik.setFieldValue(returnarEnumDescricaoID(configuracaoTela.campoNome, configuracaoTela.isXCampo), true);
    }
  }, []);

  const onChangeMultiSelect = (event: any, campoNome: string, isXCampo = false) => {

    formik.setFieldTouched(returnarEnumDescricaoID(campoNome, isXCampo), true);

    formik.setFieldValue(returnarEnumDescricaoID(campoNome, isXCampo), event);

    setGenericIdMultiSelected(event);
  };

  const renderAreaTexto = () => {
    if (configuracaoTela?.campoTipo == ETipoCampoEnum.AreaTexto) {
      return (
        <>
          <AreaTextoField
            configuracaoTela={configuracaoTela}
            toggleModal={toggleAreaTextoModal}
            exibirModal={exibirModalAreaTexto}
            formik={formik}
          />
        </>
      );
    }
    return <></>;
  };

  const renderInput = () => {
    let component = <></>;

    switch (configuracaoTela?.campoTipo) {

      case ETipoCampoEnum.Lista:
      case ETipoCampoEnum.ListaMulti:
        component = (
          <>
            <MultiSelect
              isLoading={false}
              disabled={false}
              options={genericIdMulti}
              value={genericIdMultiSelected}
              onChange={(event: any) => onChangeMultiSelect(event, configuracaoTela?.campoNome.toLowerCase(), configuracaoTela.isXCampo)}
              labelledBy={"Selecione..."}
              overrideStrings={{
                selectSomeItems: "Selecione...",
                allItemsAreSelected: "Todos selecionados",
                selectAll: "Selecione todos",
                search: "Pesquise",
                selectAllFiltered: "Selecione todos (filtrados)"
              }}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Valor:
        component = (
          <div className="row">
            <div className="col-md-6">
              <CurrencyField
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`] &&
                    formik.errors[`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`],
                })}
                onValueChange={(values: any) => {
                  formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`, true);
                  formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`, values.floatValue);
                }}
                floatValue={eval(`formik.values?.${configuracaoTela?.campoNome.toLowerCase()}maiorigual`)}
              />
            </div>
            <div className="col-md-6">
              <CurrencyField
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[`${configuracaoTela?.campoNome.toLowerCase()}menorigual`] &&
                    formik.errors[`${configuracaoTela?.campoNome.toLowerCase()}menorigual`],
                })}
                onValueChange={(values: any) => {
                  formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}menorigual`, true);
                  formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}menorigual`, values.floatValue);
                }}
                floatValue={eval(`formik.values?.${configuracaoTela?.campoNome.toLowerCase()}menorigual`)}
              />
            </div>
          </div>
        );
        break;



      case ETipoCampoEnum.Percentual:
        component = (
          <div className="row">
            <div className="col-md-6">
              <NumberFormat
                format={currencyFormatterPerc}
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`] &&
                    formik.errors[`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`],
                })}
                thousandSeparator={true}
                onValueChange={(values: any) => {
                  formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`, true);
                  formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}maiorigual`, values.floatValue / 100);
                }}
                placeholder="Percentual Mínimo"
              />
            </div>

            <div className="col-md-6">
              <NumberFormat
                format={currencyFormatterPerc}
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[`${configuracaoTela?.campoNome.toLowerCase()}menorigual`] &&
                    formik.errors[`${configuracaoTela?.campoNome.toLowerCase()}menorigual`],
                })}
                thousandSeparator={true}
                onValueChange={(values: any) => {
                  formik.setFieldTouched(`${configuracaoTela?.campoNome.toLowerCase()}menorigual`, true);
                  formik.setFieldValue(`${configuracaoTela?.campoNome.toLowerCase()}menorigual`, values.floatValue / 100);
                }}
                placeholder="Percentual Máximo"
              />
            </div>
          </div>
        );
        break;


      case ETipoCampoEnum.Inteiro:

        component = (
          <>
            <input
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="number"
              className={clsx("form-control", {
                "form-control-error": configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
              })}
              id={`form-${configuracaoTela?.campoNome?.toLowerCase()}`}
            />
          </>
        );

        break;

      case ETipoCampoEnum.Boolean:
        component = (
          <>
            <FormCheckInput
              {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
              placeholder={configuracaoTela.label}
              type="checkbox"
              style={{ backgroundColor: "var(--primary-base2)", borderColor: "var(--primary-base2)", width: "20px", height: "20px", cursor: "pointer" }}
              className={clsx("form-control", {
                "border border-danger": configuracaoTela.obrigatorio,
              })}
              id={`form-${configuracaoTela?.campoNome?.toLowerCase()}`}
            />
          </>
        );
        break;

      case ETipoCampoEnum.Data:
        component = (
          <>
            <DateTimeRangePicker
              className={clsx("form-control p-0", {
                "form-control-error":
                  configuracaoTela?.obrigatorio &&
                  formik.touched[configuracaoTela?.campoNome?.toLowerCase()] &&
                  formik.errors[configuracaoTela?.campoNome?.toLowerCase()],
              })}
              calendarIcon={null}
              showLeadingZeros={true}
              value={[
                formik.values[`${configuracaoTela?.campoNome?.toLowerCase()}maiorigual`] || null,
                formik.values[`${configuracaoTela?.campoNome?.toLowerCase()}menorigual`] || null,
              ]}
              onChange={(datas: Date[]) => {
                if (!datas || datas.length === 0) {
                  formik.setFieldValue(`${configuracaoTela?.campoNome?.toLowerCase()}maiorigual`, null);
                  formik.setFieldValue(`${configuracaoTela?.campoNome?.toLowerCase()}menorigual`, null);
                  return;
                }

                let data_inicial = datas[0];
                let data_final = datas[1];

                if (data_inicial) data_inicial.setHours(0, 0, 0, 0); // Set to start of the day

                if (data_final) data_final.setHours(23, 59, 59, 999); // Set to end of the day

                formik.setFieldValue(`${configuracaoTela?.campoNome?.toLowerCase()}maiorigual`, data_inicial ? data_inicial.toISOString() : null);
                formik.setFieldValue(`${configuracaoTela?.campoNome?.toLowerCase()}menorigual`, data_final ? data_final.toISOString() : null);
              }}
            />
          </>
        );
        break;

      case ETipoCampoEnum.AreaTexto:
        component = (
          <>
            <div className="input-group">
              <textarea
                {...formik.getFieldProps(configuracaoTela?.campoNome.toLowerCase())}
                placeholder={configuracaoTela.label}
                type="text"
                maxLength={500}
                className={clsx("form-control", {
                  "form-control-error": configuracaoTela?.obrigatorio &&
                    formik.touched[configuracaoTela?.campoNome.toLowerCase()] &&
                    formik.errors[configuracaoTela?.campoNome.toLowerCase()], // Adicione a classe "campo-invalido" quando for inválido
                })}
                id={`form-${configuracaoTela?.campoNome?.toLowerCase()}`} />

              <Button
                onClick={() => toggleAreaTextoModal()}
                style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
              </Button>
            </div>
          </>
        );
        break;

      case ETipoCampoEnum.Empresa:
        component = (
          <MultiSelect
            isLoading={false}
            disabled={false}
            options={empresaIdMulti}
            value={empresaIdMultiSelected}
            onChange={(event: any) => {
              formik.setFieldValue("empresaId", event);
              setEmpresaIdMultiSelected(event);
            }}
            labelledBy="Selecione..."
            overrideStrings={{
              selectSomeItems: "Selecione...",
              allItemsAreSelected: "Todos selecionados",
              selectAll: "Selecione todos",
              search: "Pesquise",
              selectAllFiltered: "Selecione todos (filtrados)"
            }}
          />
        );
        break;

      case ETipoCampoEnum.Texto:
        const commonInputPropsTexto = {
          ...formik.getFieldProps(configuracaoTela?.campoNome?.toLowerCase()),
          placeholder: configuracaoTela.label,
          type: "text",
          maxLength: 500,
          className: clsx("form-control", {
            "form-control-error": configuracaoTela?.obrigatorio &&
              formik.touched[configuracaoTela?.campoNome?.toLowerCase()] &&
              formik.errors[configuracaoTela?.campoNome?.toLowerCase()],
          }),
          id: `form-${configuracaoTela?.campoNome?.toLowerCase()}`,
        };

        component = <input {...commonInputPropsTexto} />;
        break;


      case ETipoCampoEnum.Pessoa:
        const campoNome = configuracaoTela?.campoNome as ECamposIDEnum;

        const commonInputPropsPessoa = {
          ...formik.getFieldProps(campoNome?.toLowerCase()),
          placeholder: configuracaoTela.label,
          type: "text",
          maxLength: 500,
          className: "form-control",
          id: `form-${campoNome?.toLowerCase()}`,
        };
        if (campoNome === ECamposIDEnum.ParteContraria) {
          component = <input {...commonInputPropsPessoa} />;
        } else if (
          campoNome === ECamposIDEnum.Advogado ||
          campoNome === ECamposIDEnum.AdvogadoInterno
        ) {
          component = (
            <MultiSelect
              isLoading={false}
              disabled={false}
              options={genericIdMulti}
              value={genericIdMultiSelected}
              onChange={(event: any) => onChangeMultiSelect(event, campoNome?.toLowerCase(), configuracaoTela.isXCampo)}
              labelledBy="Selecione..."
              overrideStrings={{
                selectSomeItems: "Selecione...",
                allItemsAreSelected: "Todos selecionados",
                selectAll: "Selecione todos",
                search: "Pesquise",
                selectAllFiltered: "Selecione todos (filtrados)"
              }}
            />
          );
        }
        break;


    }

    return component;
  };



  const definirInput = () => {
    let component: any[] = [];

    switch (configuracaoTela.campoNome) {

      case ECamposIDEnum.Estado:
        component.push(
          <div className="col-md-4 mt-3"><EstadoField configuracaoTela={configuracaoTela} formik={formik} /></div>
        );
        break;

      case ECamposIDEnum.Cidade:
        component.push(
          <div className="col-md-4 mt-3">  <CidadeField configuracaoTela={configuracaoTela} formik={formik} />   </div>
        );
        break;

      default:
        component.push(
          <>
            {renderAreaTexto()}
            <div className={`col-md-4 mt-3`}>
              <label className="form-label fw-bolder text-orange">
                {configuracaoTela.label}
                {configuracaoTela.obrigatorio ? "*:" : ":"}
                {configuracaoTela.help && <PopoverHelper />}
              </label>
              <div className="row">
                <div className="col-md-12">{renderInput()}</div>
              </div>
            </div>
          </>
        );
    }

    return <>{component}</>;
  };


  return (definirInput());
};

export default CamposPesquisaDinamicos;