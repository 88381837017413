import * as Yup from "yup";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useAuth } from "../../contexts/AuthProvider";
import UsuarioService from "../../services/UsuarioService";
import AtualizarUsuarioRequest from "../../interfaces/Requests/AtualizarUsuarioRequest";
import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { ProfileChancelaUpload, ProfilePhotoUpload } from "./components";
import ProfileTheme from "./components/ProfileTheme";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { OrangeContext } from "../../contexts/OrangeProvider";
import PerfilService from "../../services/PerfilService";
import { useParams } from "react-router-dom";

export default function PerfilPage() {
  const { setSelectedTheme, selectedTheme, setThemeMode, themeMode } =
    useContext<OrangeInterfaceContext>(OrangeContext);
  const [loading, setLoading] = useState(false);
  const { user, updateUser } = useAuth();
  const [currentColors, setCurrentColors] = useState<string>(selectedTheme.primaryBase + "|" + selectedTheme.secondaryBase);
  const [currentThemeMode, setCurrentThemeMode] = useState<string>(themeMode);



  useEffect(() => {
    setCurrentColors(selectedTheme.primaryBase + "|" + selectedTheme.secondaryBase)
  }, [selectedTheme])
  
  useEffect(() => {
    setCurrentThemeMode(themeMode)
  }, [themeMode])

  useEffect(() => {
    if (!user) {
      return;
    }
    
    formik.setFieldValue("nomeUsuario", user?.nomeUsuario);
    formik.setFieldValue("corTemplate", currentColors);
    formik.setFieldValue("modoExibicao", currentThemeMode == "" || currentThemeMode == "light"  ? 1 : 2);
    formik.setFieldValue("usuarioId", user?.id);
  }, [user, currentColors, currentThemeMode]);

  
  const profileSchema = Yup.object().shape({
    nomeUsuario: Yup.string()
      .min(3, "Minimo 5 caracteres")
      .max(50, "Máximo 50 caracteres")
      .required("Nome do usuário é obrigatório"),
    corTemplate: Yup.string()
      .min(3, "Minimo 3 caracteres")
      .max(50, "Máximo 50 caracteres")
      .required("corTemplate é obrigatório"),
  });

  const initialValues: AtualizarUsuarioRequest = {
    nomeUsuario: "",
    corTemplate: "",
    modoExibicao: 1,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus("");

      try {
        await UsuarioService.atualizarPerfil(values);

        Swal.fire({
          heightAuto: false,
          icon: "success",
          title: `Perfil atualizado com sucesso`,
          showConfirmButton: true,
        });

        updateUser(values);
      } catch (error: any) {
        let mensagem = "Não foi editar seu perfil";

        if (error?.response?.data?.Message) {
          mensagem = error.response.data.Message;
        }

        setStatus(mensagem);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <LayoutPageTitle title="Perfil" />

      <div className="row mt-2">
        <div className="col-md-4">
          <form className="form" onSubmit={formik.handleSubmit} noValidate>
            <ProfilePhotoUpload />

            {formik.status && (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="fv-row mb-3">
              <label className="form-label fs-6 fw-bolder text-orange">
                Nome do usuário
              </label>
              <input
                placeholder="Nome do usuário"
                {...formik.getFieldProps("nomeUsuario")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.nomeUsuario && formik.errors.nomeUsuario,
                  },
                  {
                    "is-valid":
                      formik.touched.nomeUsuario && !formik.errors.nomeUsuario,
                  }
                )}
                type="text"
                autoComplete="off"
              />
              {formik.touched.nomeUsuario && formik.errors.nomeUsuario && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.nomeUsuario}</span>
                </div>
              )}
            </div>

            <div className="fv-row mb-3">
              <ProfileTheme/>
            </div>

            <ProfileChancelaUpload />

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-lg btn-orange w-100 mt-2"
                disabled={!formik.isValid}
              >
                {!loading && <span className="indicator-label">Salvar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    Aguarde...
                  </span>
                )}
              </button>

            </div>
          </form>
        </div>
      </div>
    </>
  );
}
