import { Editor, Element, Range, Transforms } from "slate"
import { CustomEditor, LinkElement } from "../../../../../utils/types/slate"
import { isValidUrl } from "../../../util"

const isLinkActive = (editor: CustomEditor) => {
  const link = Editor.nodes(editor, {
    match: node => 
      !Editor.isEditor(node) && Element.isElement(node) && node.type === 'link'
  })

  return !!link.next().value
}

const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) && Element.isElement(node) && node.type === "link",
  });
}

export const insertLink = (editor: CustomEditor, url: string, text: string) => {
  if (editor.selection) {
    wrapLink(editor, url, text)
  }
}

const wrapLink = (editor: CustomEditor, url: string, text: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }
  
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text }] : []
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

export const withLink = (editor: CustomEditor) => {
  const { isInline, insertText } = editor

  editor.isInline = element => ['link'].includes(element.type) || isInline(element)

  editor.insertText = text => {
    if (text && isValidUrl(text)) {
      wrapLink(editor, text, text)
    } else {
      insertText(text)
    }
  }

  return editor
}