import * as Formik from 'formik';
import Swal from "sweetalert2";
import moment from "moment";
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEraser, faPaperPlane, faSearch } from "@fortawesome/free-solid-svg-icons";


import "./style.scss";
import { ECamposIDEnum, EModulo, ETipoCampoEnum } from "../../../enum";
import { fieldNeedHaveId, generateSchemaFromModelo, getFormValuesModelo } from "../utils";

import { ReturnFormField } from "../interfaces";
import IEmpresa from '../../../interfaces/IEmpresa';
import { IPessoa } from "../../../interfaces/IPessoa";
import ITipoSociedade from '../../../interfaces/ITipoSociedade';
import { IConfiguracaoTela } from "../../../interfaces/IConfiguracaoTela";
import { OrangeInterfaceContext } from '../../../interfaces/Contexts/OrangeInterfaceContext';

import EmpresaService from "../../../services/EmpresaService";
import TipoSociedadeService from '../../../services/TipoSociedadeService';
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import { useDisclosure } from "../hooks/useDisclosure";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { OrangeContext } from '../../../contexts/OrangeProvider';
import ModalRecuperaPessoa from "../componentes/ModalRecuperaPessoa";
import { LayoutPageContents } from "../../../layout/LayoutPageContents";
import { ModalAdicionaPessoa } from "../componentes/ModalAdicionaPessoa";
import { CamposCadastroEmpresaDinamicos } from "../../../components/Empresa";
import { camelizar } from '../../ConfiguracaoTelaPage/Empresa/utils';
import { Modelo } from '../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo';
  import ICidade from '../../../interfaces/ICidade';
import IEstado from '../../../interfaces/IEstado';
import IRecuperaLista from '../../../interfaces/IRecuperaLista';
import CidadeService from '../../../services/CidadeService';
import EstadoService from '../../../services/EstadoService';
import clsx from 'clsx';
import { ICampo } from '../../../interfaces/ICampo';

const EmpresaCadastroPage = () => {
  const { empresa, setEmpresa, tipoSociedade, setTipoSociedade } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [tipoDocumento, setTipoDocumento] = useState('');
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [nome, setNome] = useState('');
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [pessoa, setPessoa] = useState<IPessoa>();
  const [esquemaValidacao, setEsquemaValidacao] = useState();
  const [tela, setTela] = useState<IConfiguracaoTela>();
  const [matriz, setMatriz] = useState<IEmpresa>();

  const [carregando, setCarregando] = useState<boolean>(false);
  const [tiposSociedades, setTiposSociedades] = useState<ITipoSociedade[]>([]);

  const modalRecupera = useDisclosure(false);

  const navigate = useNavigate();

  /// REFATORAÇÃO
  const [carregandoCidades, setCarregandoCidades] = useState<boolean>(false);
  const [cidades, setCidades] = useState<ICidade[]>([]);
  const [estados, setEstado] = useState<IEstado[]>([]);

  const empresaTelaCadastro: IConfiguracaoTela = {
    modulo: EModulo.Societario,
    tela: 3,
    camposList: [],
  }

  const adicionarEmpresa = async () => {
    try {

      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });
      let result = await swalWithBootstrapButtons.fire({
        title: "Salvar Empresa",
        text: `Você realmente deseja salvar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });
      if (result.isConfirmed) {

        Swal.showLoading();
        const resultado = await EmpresaService.adicionarEmpresa({
          ...formik.values,
          pessoaId: pessoa?.pessoaId,
          empresaMatrizId: matriz?.empresaId,
          tipoSociedadeId: tipoSociedade?.tipoSociedadeId,
          tipoEstabelecimento: matriz?.empresaId ? 2 : 1
        });

        Swal.hideLoading();
        setEmpresa(resultado);
        formik.resetForm();
        Swal.fire({
          title: 'Sucesso',
          text: 'Empresa cadastrado.',
          icon: 'success',
          showConfirmButton: true,
          timer: 3000
        });
      }
    }
    catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível salvar",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  }

  const formik = useFormik<any>({
    validationSchema: esquemaValidacao,
    initialValues: {},
    validateOnBlur: true,
    onSubmit: adicionarEmpresa
  });

  const carregarTipoSociedade = async () => {
    try {

      setCarregando(true);

      let resultado: any = await TipoSociedadeService.obterTipoSociedades({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setTiposSociedades(resultado.data);

    } catch (error: any) {
      setTiposSociedades([]);
    } finally {
      setCarregando(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {
      const listaDeCampos = (
        await ConfiguracaoTelaService.obterConfiguracaoTela2({
          Modulo: empresaTelaCadastro.modulo,
          Tela: empresaTelaCadastro.tela,
          SubModulo: empresaTelaCadastro?.subModulo,
          TipoSociedadeId: tipoSociedade.tipoSociedadeId,
        })
      )
        .filter((x: Modelo) => x.campo !== 120)
        .map((m: Modelo) => {

          if (m.campoNome === "AtividadesEmpresa") {
            m.campoNome = 'AtividadeEmpresa'
            m.nome = 'AtividadeEmpresa'
          }

          if (m.campoNome === "SociosAdministradores") {
            m.multiplo = true;
            m.camposRetornar = ["nome", "dataEntrada", "numeroDocumento", "idade", "quotasAcoes", "pais", "qualificacaoId", "codigo"];
            m.camposExtras = [
              {
                campoNome: "DataEntrada",
                nome: "DataEntrada",
                controller: null,
                label: "Data da Entrada",
                entidade: "",
                tipo: ETipoCampoEnum.Data,
                ordenacao: 0,
                obrigatorio: false,
                somenteLeitura: false,
                campo: 0,
                help: "",
                isXCampo: false,
              },
              {
                campoNome: "Pais",
                nome: "Pais",
                controller: "Pais",
                label: "País",
                entidade: "",
                tipo: ETipoCampoEnum.Lista,
                ordenacao: 0,
                obrigatorio: false,
                somenteLeitura: false,
                campo: 0,
                help: "",
                isXCampo: false,
              },
              {
                campoNome: "QuotasAcoes",
                nome: "QuotasAcoes",
                controller: null,
                label: "Quota de Ações",
                entidade: "",
                tipo: ETipoCampoEnum.Percentual,
                ordenacao: 0,
                obrigatorio: true,
                somenteLeitura: false,
                campo: 0,
                help: "",
                isXCampo: false,
              },
              {
                campoNome: "Qualificacao",
                nome: "Qualificacao",
                controller: "Qualificacao",
                label: "Qualificação",
                entidade: "",
                tipo: ETipoCampoEnum.Lista,
                ordenacao: 0,
                obrigatorio: false,
                somenteLeitura: false,
                campo: 0,
                help: "",
                isXCampo: false,
              },
              {
                campoNome: "Codigo",
                nome: "Codigo",
                controller: null,
                label: "Código",
                entidade: "",
                tipo: ETipoCampoEnum.Texto,
                ordenacao: 0,
                obrigatorio: false,
                somenteLeitura: false,
                campo: 0,
                help: "",
                isXCampo: false,
              },
            ];
          }
          return m;
        });

      formik.setValues({ ...formik.values, ...getFormValuesModelo(listaDeCampos) });
      formik.setValues({ ...formik.values, ...empresa });
      formik.setFieldValue("atividadeEmpresa", empresa?.empresasAtividadesEmpresa);
      setEsquemaValidacao(() => generateSchemaFromModelo(listaDeCampos as Modelo[]));
      setTela(undefined);
      setTela({
        ...empresaTelaCadastro,
        camposList: listaDeCampos,
      });
    } catch (error: any) {
      setTela({
        ...empresaTelaCadastro,
        camposList: [],
      });
    }
  }

  useEffect(() => { if (formik.values.tipoSociedadeId) carregaEstado(); }, [formik.values.tipoSociedadeId]);

  useEffect(() => {
    if (formik.values.estadoId > 0) carregaCidade();
  }, [formik.values.estadoId]);
  

  const carregaEstado = async () => {
    try {
      if (estados.length > 0) return;
      let resultado: IRecuperaLista<IEstado>;
      resultado = await EstadoService.obterEstados({
        nome: "",
        codigo: "",
        status: 0,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "estadoId",
      });
      setEstado(resultado.data);
    } catch (error: any) {
      setEstado([]);
    }
  };

  const carregaCidade = async () => {
    try {
      setCidades([]);
      setCarregandoCidades(true);

      let resultado: IRecuperaLista<ICidade>;
      resultado = await CidadeService.obterCidadePorFiltros({
        estadoId: formik.values.estadoId,
        IBGE: "",
        nome: "",
        status: 1,
        limit: 900,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });

      setCarregandoCidades(false);
      setCidades(resultado.data);
    } catch (error: any) {
      setCidades([]);
      setCarregandoCidades(false);
    }
  };
  
  const renderCidade = (configuracaoTela: ICampo) => {
    return (
      <>
        <div className="col-md-6 col-lg-4 mt-3">
          {carregandoCidades ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              Carregando cidades...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <>
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                {configuracaoTela.label}
                <a style={{ fontSize: "12px" }}>
                  {configuracaoTela.obrigatorio ? "*:" : ":"}
                  {/* {configuracaoTela.help && <PopoverHelper></PopoverHelper>} */}
                </a>
              </label>
              <select
                name="cidadeId"
                value={formik.values.cidadeId}
                onChange={(e) => {
                  formik.setFieldTouched("cidadeId", true);
                  formik.setFieldValue("cidadeId", parseInt(e.target.value));
                }}
                className={clsx(
                  "form-select",
                  {
                    "is-invalid": formik.touched.cidadeId && formik.errors.cidadeId,
                  },
                  {
                    "is-valid": formik.touched.cidadeId && !formik.errors.cidadeId,
                  }
                )}
                id="form-cidadeId"
              >
                <option value="0">Selecione</option>
                {cidades.map((map: any) => {
                  return (
                    <option key={map.nome} value={map.cidadeId}>
                      {map.nome}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </>
    );
  };
  
  const renderEstado = (configuracaoTela: ICampo) => {
    return (
      <>
        <div className="col-md-6 col-lg-4 mt-3">
          <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
            {configuracaoTela.label}
            <a style={{ fontSize: "12px" }}>
              {configuracaoTela.obrigatorio ? "*:" : ":"}
            </a>
          </label>
          <select
            name="estadoId"
            value={formik.values.estadoId}
            onChange={(e) => {
              formik.setFieldTouched("estadoId", true);
              formik.setFieldValue("estadoId", parseInt(e.target.value));
            }}
            className={clsx(
              "form-select",
              {
                "is-invalid": formik.touched.estadoId && formik.errors.estadoId,
              },
              {
                "is-valid": formik.touched.estadoId && !formik.errors.estadoId,
              }
            )}
            id="form-estadoId"
          >
            <option value="0">Selecione</option>
            {estados.map((map: any) => {
              return (
                <option key={map.nome} value={map.estadoId}>
                  {map.nome}
                </option>
              );
            })}
          </select>
        </div>
      </>
    );
  };

  const renderCampos = () => {

    if (tela) {
      if (pessoa) {
        return (
          <>
            <Row>
              {tela.camposList && tela.camposList.map((campo: any, index: number) => {

                if (campo.campoNome == ECamposIDEnum.Cidade) {
                  return (renderCidade(tela.camposList.filter(e => e.campoNome == ECamposIDEnum.Cidade)[0]));
                }

                if (campo.campoNome == ECamposIDEnum.Estado) {
                  return (renderEstado(tela.camposList.filter(e => e.campoNome == ECamposIDEnum.Estado)[0]));
                }

                let campoNome = camelizar(campo.campoNome);

                if (fieldNeedHaveId(campo)) {
                  campoNome = campoNome + 'Id'
                }

                return (
                  <div key={index} className="col-md-6 col-lg-4 mt-3">
                    <CamposCadastroEmpresaDinamicos
                      modelo={campo}
                      handleSetValue={handleSetValues}
                      touched={formik.touched[campoNome] as Formik.FormikTouched<any>}
                      error={formik.errors[campoNome] as Formik.FormikErrors<any>}
                      tela={tela}
                      campo={{
                        onChange: (e: any) => {
                          formik.setFieldValue(campoNome, e.target.value);
                        },
                        value: campo.tipo === 'Data' || campo.campoTipo === 'Data' ? moment(formik.values[campoNome]).format("YYYY-MM-DD") : formik.values[campoNome] || "",
                        name: campoNome,
                        onBlur: formik.getFieldProps(campoNome).onBlur
                      }}
                    />
                  </div>
                )
              })}
            </Row>
          </>
        )
      } else {
        return <h4 className="text-muted text-center w-100"> Adicione uma pessoa</h4>
      }
    }
  }

  const handlePessoa = async (pessoa: IPessoa, empresa?: IEmpresa) => {
    if (pessoa.pessoaId === 0) {
      if (formik.values) {
        if (empresa) {
          setEmpresa({ ...empresa })
        }
        formik.setValues({ ...formik.values, ...empresa })
      }
      modalRecupera.handleClose();
      setShowModalCadastro(true);
    } else {
      if (formik.values) {
        if (empresa) {
          setEmpresa({ ...empresa })
        }
        formik.setValues({ ...formik.values, ...empresa });
      }
      setPessoa(() => { return pessoa })
    }
  }

  const handleCadastroPessoa = (pessoa: IPessoa) => {
    setPessoa(() => { return pessoa })
    formik.setValues({ ...formik.values, ...empresa })
    if (matriz) {
      formik.setFieldValue('empresaMatrizId', matriz?.empresaId);
    }
    formik.setFieldValue('pessoaId', pessoa?.pessoaId);
    setShowModalCadastro(false)
  }

  const handleSetValues = (field: ReturnFormField) => {
    if (Array.isArray(field.value)) {
      formik.setFieldValue(field.name, field.value);
    } else {
      formik.setFieldValue(field.name, field.value);
    }
  }

  const resetClick = (event: any) => {
    setPessoa(undefined);
    event.target.blur();
  }

  useEffect(() => {
    modalRecupera.handleOpen();
  }, []);

  useEffect(() => {
    if (formik.values.tipoSociedadeId) {
      carregarConfiguracaoTela();
    }
  }, [formik.values.tipoSociedadeId]);

  const onChangeTipoSociedade = (value: any, texto: string) => {
    formik.setFieldValue("tipoSociedadeId", parseInt(value));
    setTipoSociedade({
      tipoSociedadeId: parseInt(value),
      nome: texto,
      codigo: "",
      status: "",
    });
  };

  useEffect(() => {
    if (pessoa) {
      formik.setFieldValue('pessoaId', pessoa.pessoaId)
      if (matriz) {
        formik.setFieldValue('empresaMatrizId', matriz.empresaId);
        formik.setFieldValue('tipoEstabelecimento', 2);
      } else {
        formik.setFieldValue('tipoEstabelecimento', 1);
      }
      modalRecupera.handleClose()
    }
    carregarTipoSociedade();
  }, [pessoa]);

  return (
    <>
      <LayoutPageTitle title="Cadastro Empresas">

        {empresa.empresaId > 0 ?
          <>
            <button onClick={() => navigate("/Societario/Empresa/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{empresa.empresaId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </> :
          <>
            <button
              onClick={formik.submitForm}
              disabled={!pessoa}
              type="submit"
              className="btn btn-orange btn-secondary me-5"
            >
              {!carregando && (
                <>
                  Salvar Empresa
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
                </>
              )}
              {carregando && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button></>}
      </LayoutPageTitle>



      {carregando ? (
        <span className="indicator-progress" style={{ display: "block" }}>
          Carregando tipo de sociedade...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        pessoa?.pessoaId &&
        <Row className="w-100">
          <div className="col-md-6 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Sociedade
            </label>
            <select
              name="tipoSociedadeId"
              value={formik.values.tipoSociedadeId}
              className={"form-select"}
              onChange={(event: any) => onChangeTipoSociedade(event.target.value, event.target[event.target.selectedIndex].text)}
              id="form"
            >
              <option value="0"> Selecione um tipo de sociedade</option>
              {tiposSociedades.map((tipoSociedade) => {
                return (
                  <option key={tipoSociedade.tipoSociedadeId} value={tipoSociedade.tipoSociedadeId}>
                    {tipoSociedade.nome}
                  </option>
                );
              })}
            </select>
          </div>
        </Row>
      )}

      <LayoutPageContents>
        <Row className="w-100 mt-3">
          <Col sm={12} md={6} xl={3} className="mb-3">
            <label className="form-label fw-bolder text-orange" htmlFor="pessoa-id">Nome Fantasia:</label>
            <input type="text" className="form-control" value={pessoa?.nome || ''} disabled />
          </Col>
          <Col sm={12} md={6} xl={3} className="mb-3">
            <label className="form-label fw-bolder text-orange" htmlFor="pessoa-id">CNPJ:</label>
            <input type="text" className="form-control" value={pessoa?.numeroDocumento || ''} disabled />
          </Col>
          <Col sm={12} md={12} xl={3} className="mb-3">
            <label className="form-label fw-bolder text-orange" htmlFor="pessoa-id">Denominação Social:</label>
            <input type="text" className="form-control" value={pessoa?.nomeSocial || ''} disabled />
          </Col>
          <Col sm={12} md={3} xl={1} className="mb-3 ms-auto">
            <label className="form-label fw-bolder text-orange"> &nbsp; </label>
            <Button className="btn btn-orange w-100" onClick={() => modalRecupera.handleOpen()}>
              <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
            </Button>
          </Col>
          <Col sm={12} md={3} xl={1} className="mb-3 ms-auto">
            <label className="form-label fw-bolder text-orange"> &nbsp; </label>
            <Button as="label" className="btn btn-orange w-100" onClick={resetClick}>
              <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
            </Button>
          </Col>
        </Row>
        {renderCampos()}
      </LayoutPageContents>

      <ModalRecuperaPessoa
        className={'societario'}
        title={'Pesquisar/Adicionar Empresas'}
        documentoPadrao={'CNPJ'}
        setTipoDocumento={setTipoDocumento}
        setNumeroDocumento={setNumeroDocumento}
        setNome={setNome}
        modalActions={modalRecupera}
        handlePessoa={handlePessoa}
        setMatriz={setMatriz}
      />

      <ModalAdicionaPessoa
        className={'societario'}
        title={"Nova Empresa"}
        showModal={showModalCadastro}
        setShowModal={setShowModalCadastro}
        tipoDocumento={tipoDocumento}
        nome={nome}
        numeroDocumento={numeroDocumento}
        handlePessoa={handleCadastroPessoa}
      />

    </>
  )

}

export default EmpresaCadastroPage