import { FormikErrors, FormikTouched } from "formik"
import { Form } from "react-bootstrap"

interface InputProps {
  label: string
  name: string
  placeholder?: string
  errors?: FormikErrors<{
    [field: string]: any
  }>
  touched?: FormikTouched<{
    [field: string]: any
  }>
  autofocus?: boolean
  disabled?: boolean
  value?: string 
}

export const FieldText = (props: InputProps) => {

  const isError = props?.errors?.[props.name] && props?.touched?.[props.name]
  const isValid = props?.errors?.[props.name] && props?.touched?.[props.name]
  const errorClass = isError ? "is-invalid" : isValid ? 'is-valid' : ''

  return (
    <>
      <Form.Label className="fw-bold text-orange" htmlFor={props.name}>{props.label}</Form.Label>
      <Form.Control 
        {...props}
        className={errorClass}
        id={props.name}
        name={props.name}
        value={props.value || ''}
        placeholder={props.placeholder || ''}
        autoComplete="off"
        disabled={props.disabled}
        
      />
    </>
  )


}

