import React from "react";
import {
  CustomElement,
  TableElement,
} from "../../../../../utils/types/slate";
import { CommonService } from "./commons";

export const TableService = {
  tableStyle: {
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: 'fixed'
  } as React.CSSProperties,

  rowStyle: { height: "40px" } as React.CSSProperties,

  columnStyle: {
    border: "1px solid black",
    padding: 5,
  } as React.CSSProperties,

  cellAttributes: {
    rowSpan: 1,
    colSpan: 1
  },

  arrayFromNumber(number: number) {
    return Array.from(new Array(number).keys());
  },

  buildTableChildren(rows: number, columns: number): CustomElement[] {
    const tableRows = this.arrayFromNumber(rows);
    const tableColumns = this.arrayFromNumber(columns);

    const children: CustomElement[] = tableRows.map(() => ({
      type: "table-row",
      style: this.rowStyle,
      children: tableColumns.map(() => ({
        type: "table-cell",
        cellAttributes: this.cellAttributes,
        style: this.columnStyle,
        children: [CommonService.makeParagraph()],
      })),
    }));

    return children;
  },

  buildTable(numberOfRows: number, numberOfColumns: number) {
    const table: TableElement = {
      type: "table",
      style: this.tableStyle,
      children: this.buildTableChildren(numberOfRows, numberOfColumns),
    };

    return table;
  },
};
