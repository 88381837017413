import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IPais from "../interfaces/IPais";
import { AdicionaPaisRequest } from "../interfaces/Requests/Pais/AdicionaPaisRequest";
import FiltroPesquisaPaisRequest from "../interfaces/Requests/Pais/FiltroPesquisaPaisRequest";
import { EditarPaisRequest } from "../interfaces/Requests/Pais/EditarPaisRequest";

class PaisService {

    async obterPais(filtros: FiltroPesquisaPaisRequest): Promise<IRecuperaLista<IPais>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.ddi) {
            query += `&ddi=${filtros.ddi}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IPais>>(`Pais/RecuperaPais?${query}`);

        return result;
    }

    async adicionaPais(adicionaPais: AdicionaPaisRequest): Promise<IPais> {
        let { data: result } = await api.post<IPais>(`Pais/AdicionaPais`, adicionaPais);

        return result;
    }


    async alterarStatus(paisId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IPais>(`Pais/AtualizaPaisPorId?id=${paisId}`, data);
    }

    async atualizarPais(editarPais: EditarPaisRequest): Promise<void> {

        let dataPais = [
            { "op": "replace", "path": "/nome", "value": editarPais.nome },
            { "op": "replace", "path": "/codigo", "value": editarPais.codigo },
            { "op": "replace", "path": "/ddi", "value": editarPais.ddi }
        ]


        await Promise.all([
            await api.patch<void>(`Pais/AtualizaPaisPorId?id=${editarPais.paisId}`, dataPais)
        ]);
    }
}


export default new PaisService();