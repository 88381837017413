import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import IUsuario from "../../../../interfaces/IUsuario";
import UsuarioService from "../../../../services/UsuarioService";
import IRecuperaLista from "../../../../interfaces/IRecuperaLista";
import { notifySuccess } from "../../../../components/Comum/Toast/ToastFormik";

interface IGrupoUsuarioModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  usuario: IUsuario;
}

const GrupoUsuarioModal = ({ toggleModal, exibirModal, usuario }: IGrupoUsuarioModalProps) => {

  const [usuariosOptions, setUsuariosOptions] = useState<any[]>([]);
  const [usuariosSelected, setUsuariosSelected] = useState<any[]>([]);


  useEffect(() => {

    if (exibirModal) {

      carregarUsuarios();

      carregarUsuariosCompatilhados();
    }
  }, [exibirModal]);


  const carregarUsuariosCompatilhados = async () => {
    try {
      let resultado: IRecuperaLista<any>;
      resultado = await UsuarioService.obterUsuariosCompartilhados({
        clientes: [],
        usuarioId: usuario.usuarioId,
        numeroDocumento: "",
        email: "",
        nomeUsuario: "",
        status: 1,
        limit: 1000,
        totalItems: 0,
        offset: 0,
        sort: "clienteId",
      });

      setUsuariosSelected(returnSelectedUsuarios(resultado.data));

    } catch (error: any) {
      setUsuariosSelected([]);
    }
  }

  const adicionarUsuarioCompartilhado = async () => {
    try {

      await UsuarioService.adicionaUsuarioCompartilhado(usuariosSelected, usuario.usuarioId);

      notifySuccess('Usuários compartilhados com sucesso');

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar adicionar`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      fechar();
    }
  }

  const carregarUsuarios = async () => {
    try {
      let resultado: IRecuperaLista<any>;
      resultado = await UsuarioService.obterUsuarios({
        clientes: [],
        numeroDocumento: "",
        email: "",
        nomeUsuario: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "clienteId",
      });

      setUsuariosOptions(returnOptionsUsuarios(resultado.data));
    } catch (error: any) {
      setUsuariosOptions([]);
    }
  }

  const returnSelectedUsuarios = (array: any) => {
    array.forEach((item: any) => {
      usuariosSelected.push({
        value: item.usuarioCompartilhadoId,
        label: item.usuarioCompartilhadoNome,
      });
    });
    return usuariosSelected;
  };

  const returnOptionsUsuarios = (array: any) => {
    array.forEach((item: any) => {
      usuariosOptions.push({
        value: item.usuarioId,
        label: item.nome,
      });
    });
    return usuariosOptions;
  };


  const fechar = () => {
    toggleModal();
    setUsuariosSelected([]);
    setUsuariosOptions([]);
  }

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={fechar}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Visualizar Agendas</h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <div className="col-md-6">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Selecione os usuários:
              </label>
              <MultiSelect
                options={usuariosOptions}
                value={usuariosSelected}
                onChange={(event: any) => {
                  setUsuariosSelected(event);
                  const IdsSelected: number[] = [];
                  usuariosSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
                }}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>
          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button disabled={usuariosSelected.length == 0} onClick={() => adicionarUsuarioCompartilhado()} type="button" className="btn btn-orange ms-5">
              Adicionar
            </button>
            <button onClick={() => fechar()} type="button" className="btn btn-danger ms-5">
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GrupoUsuarioModal;
