/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./Api";

import IValores from "../interfaces/IValores";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IValoresProcesso from "../interfaces/IValoresProcesso";
import IAlterarFaseValores from "../interfaces/IAlterarFaseValores";
import FiltroPesquisaValoresRequest from "../interfaces/Requests/Valores/FiltroPesquisaValoresRequest";
import { AdicionaValoresRequest } from "../interfaces/Requests/Valores/AdicionarValores/AdicionaValoresRequest";
import { AlterarFaseValoresRequest } from "../interfaces/Requests/Valores/ValoresProcesso/AlterarFaseValoresRequest";
import FiltroPesquisaValoresProcessoRequest from "../interfaces/Requests/Valores/ValoresProcesso/FiltroPesquisaValoresProcessoRequest";

import { ETipoRisco } from "../enum/ETipoRisco";
import { tratarValorZerado } from "../utils/Utils";

class ValoresService {

    async obterValores(filtros: FiltroPesquisaValoresRequest): Promise<IRecuperaLista<IValores>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.ProcessoId) {
            query += `&processoId=${filtros.ProcessoId}`;
        }

        if (filtros.Fase >= 0) {
            query += `&fase=${filtros.Fase}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IValores>>(`ValoresProcessoPedido/RecuperaValoresProcessoPedido?${query}`);

        return result;
    }

    async obterValoresProcesso(filtros: FiltroPesquisaValoresProcessoRequest): Promise<IRecuperaLista<IValoresProcesso>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.ProcessoId) {
            query += `&processoId=${filtros.ProcessoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IValoresProcesso>>(`ValoresProcessoPedido/RecuperaValoresProcesso?${query}`);

        return result;
    }

    async adicionaValores(adicionaValores: AdicionaValoresRequest): Promise<IValores> {
        let { data: result } = await api.post<IValores>(`ValoresProcessoPedido/AdicionaValoresProcessoPedido`, adicionaValores);

        return result;
    }

    async alterarStatus(valoresProcessoPedidoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IValores>(`ValoresProcessoPedido/AtualizaValoresProcessoPedidoPorId?id=${valoresProcessoPedidoId}`, data);
    }

    async atualizarProbabilidadeValoresMulta(valores: any): Promise<void> {

        let dataValores: any[] = [];

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaRiscoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaRiscoReadDto.riscoId });

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaProvavelPercentual", "value": parseFloat(valores.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValor) });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaRemotoPercentual", "value": parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValor) });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaPossivelPercentual", "value": parseFloat(valores.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValor) });

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaJuros", "value": valores.valoresFaseReadDto?.valoresMultaReadDto?.multaJurosValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaValor", "value": valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIndiceId", "value": valores?.valoresFaseReadDto.valoresMultaReadDto.multaIndiceReadDto.indiceId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaJurosDataBase", "value": valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaJurosDataBase });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaCorrecaoDataBase", "value": valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaCorrecaoDataBase });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }


    async atualizarProbabilidadeValoresPrincipal(valores: any): Promise<void> {

        let dataValores: any[] = [];

        dataValores.push({ "op": "replace", "path": "riscoId", "value": valores.valoresFaseReadDto.riscoId });

        dataValores.push({ "op": "replace", "path": "provavelPercentual", "value": parseFloat(valores?.valoresFaseReadDto?.provavelValor) });
        dataValores.push({ "op": "replace", "path": "remotoPercentual", "value": parseFloat(valores?.valoresFaseReadDto?.remotoValor) });
        dataValores.push({ "op": "replace", "path": "possivelPercentual", "value": parseFloat(valores?.valoresFaseReadDto?.possivelValor) });

        dataValores.push({ "op": "replace", "path": "valor", "value": valores.valoresFaseReadDto?.valor });
        dataValores.push({ "op": "replace", "path": "juros", "value": valores.valoresFaseReadDto?.juros });
        dataValores.push({ "op": "replace", "path": "indiceId", "value": valores.valoresFaseReadDto?.indiceId });
        dataValores.push({ "op": "replace", "path": "jurosDataBase", "value": valores.valoresFaseReadDto?.jurosDataBase });
        dataValores.push({ "op": "replace", "path": "tipoProvisaoId", "value": valores.valoresFaseReadDto?.tipoProvisaoId });
        dataValores.push({ "op": "replace", "path": "correcaoDataBase", "value": valores.valoresFaseReadDto?.correcaoDataBase });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async atualizarValoresPrincipal(valores: any): Promise<void> {

        let dataValores: any[] = [];

        switch (valores?.valoresFaseReadDto?.tipoRisco) {

            case ETipoRisco.Detalhando:
                valores.valoresFaseReadDto.valor =
                    parseFloat(valores?.valoresFaseReadDto?.provavelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.possivelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.remotoValor);
                break;

            case ETipoRisco.Percentual:
                valores.valoresFaseReadDto.valor = parseFloat(valores?.valoresFaseReadDto?.valor);
                break;

            case ETipoRisco.Unico:
                valores.valoresFaseReadDto.valor = parseFloat(valores?.valoresFaseReadDto?.valor);
                dataValores.push({ "op": "replace", "path": "riscoId", "value": valores.valoresFaseReadDto.riscoId });
                break;

            default: break;
        }

        valores.valoresFaseReadDto.provavelPercentual = (parseFloat(valores?.valoresFaseReadDto?.provavelValor) / valores.valoresFaseReadDto?.valor);
        valores.valoresFaseReadDto.possivelPercentual = (parseFloat(valores?.valoresFaseReadDto?.possivelValor) / valores.valoresFaseReadDto?.valor);
        valores.valoresFaseReadDto.remotoPercentual = (parseFloat(valores?.valoresFaseReadDto?.remotoValor) / valores.valoresFaseReadDto?.valor);


        dataValores.push({ "op": "replace", "path": "provavelPercentual", "value": valores.valoresFaseReadDto.provavelPercentual * 100 });
        dataValores.push({ "op": "replace", "path": "remotoPercentual", "value": valores.valoresFaseReadDto?.remotoPercentual * 100 });
        dataValores.push({ "op": "replace", "path": "possivelPercentual", "value": valores.valoresFaseReadDto?.possivelPercentual * 100 });
        dataValores.push({ "op": "replace", "path": "valor", "value": valores.valoresFaseReadDto?.valor });
        dataValores.push({ "op": "replace", "path": "juros", "value": valores.valoresFaseReadDto?.juros });

        dataValores.push({ "op": "replace", "path": "indiceId", "value": valores.valoresFaseReadDto?.indiceId });
        dataValores.push({ "op": "replace", "path": "jurosDataBase", "value": valores.valoresFaseReadDto?.jurosDataBase });
        dataValores.push({ "op": "replace", "path": "tipoProvisaoId", "value": valores.valoresFaseReadDto?.tipoProvisaoId });
        dataValores.push({ "op": "replace", "path": "correcaoDataBase", "value": valores.valoresFaseReadDto?.correcaoDataBase });
        
        if (valores.valoresFaseReadDto?.tipoContingenciaReadDto?.tipoContingenciaId)
            dataValores.push({ "op": "replace", "path": "tipoContingenciaId", "value": valores.valoresFaseReadDto?.tipoContingenciaReadDto?.tipoContingenciaId });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async atualizarValoresHonorarios(valores: any): Promise<void> {

        let dataValores: any[] = [];

        switch (valores?.valoresFaseReadDto?.tipoRisco) {

            case ETipoRisco.Detalhando:
                valores.valoresFaseReadDto.encargosHonorariosReadDto.valor =
                    parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.provavelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.possivelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.remotoValor);
                break;

            case ETipoRisco.Percentual:
                valores.valoresFaseReadDto.encargosHonorariosReadDto.valor = parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.valor);
                break;

            case ETipoRisco.Unico:
                valores.valoresFaseReadDto.encargosHonorariosReadDto.valor = parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.valor);
                break;

            default: break;
        }

        valores.valoresFaseReadDto.encargosHonorariosReadDto.provavelPercentual = (parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.provavelValor) / valores.valoresFaseReadDto.encargosHonorariosReadDto.valor);
        valores.valoresFaseReadDto.encargosHonorariosReadDto.possivelPercentual = (parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.possivelValor) / valores.valoresFaseReadDto.encargosHonorariosReadDto.valor);
        valores.valoresFaseReadDto.encargosHonorariosReadDto.remotoPercentual = (parseFloat(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.remotoValor) / valores.valoresFaseReadDto.encargosHonorariosReadDto.valor);

        dataValores.push({ "op": "replace", "path": "encargosHonorarios/provavelPercentual", "value": tratarValorZerado(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.provavelPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "encargosHonorarios/possivelPercentual", "value": tratarValorZerado(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.possivelPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "encargosHonorarios/remotoPercentual", "value": tratarValorZerado(valores?.valoresFaseReadDto?.encargosHonorariosReadDto?.remotoPercentual) * 100 });

        dataValores.push({ "op": "replace", "path": "encargosHonorarios/valor", "value": valores.valoresFaseReadDto.encargosHonorariosReadDto.valor });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async atualizarValoresMulta(valores: any): Promise<void> {

        let dataValores: any[] = [];

        switch (valores?.valoresFaseReadDto?.tipoRisco) {

            case ETipoRisco.Detalhando:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaValor =
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValor);
                break;

            case ETipoRisco.Percentual:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaValor);
                break;

            case ETipoRisco.Unico:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaValor);
                dataValores.push({ "op": "replace", "path": "valoresMulta/multaRiscoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaRiscoReadDto.riscoId });

                break;

            default: break;
        }

        valores.valoresFaseReadDto.valoresMultaReadDto.multaProvavelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelValor || 0) / valores.valoresFaseReadDto.valoresMultaReadDto.multaValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaPossivelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelValor || 0) / valores.valoresFaseReadDto.valoresMultaReadDto.multaValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaRemotoPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoValor || 0) / valores.valoresFaseReadDto.valoresMultaReadDto.multaValor;

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaProvavelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto?.valoresMultaReadDto?.multaProvavelPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaRemotoPercentual", "value": tratarValorZerado(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaRemotoPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaPossivelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto?.valoresMultaReadDto?.multaPossivelPercentual) * 100 });

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaValor", "value": valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaJuros", "value": valores.valoresFaseReadDto?.valoresMultaReadDto?.multaJurosValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaJurosDataBase", "value": valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaJurosDataBase });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIndiceId", "value": valores?.valoresFaseReadDto.valoresMultaReadDto.multaIndiceReadDto.indiceId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/tipoProvisaoId", "value": valores?.valoresFaseReadDto.valoresMultaReadDto.multaTipoProvisaoReadDto.tipoProvisaoId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaCorrecaoDataBase", "value": valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaCorrecaoDataBase });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async atualizarValoresMultaIsolada(valores: any): Promise<void> {

        let dataValores: any[] = [];

        switch (valores?.valoresFaseReadDto?.tipoRisco) {

            case ETipoRisco.Detalhando:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor =
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaProvavelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaPossivelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRemotoValor);
                break;

            case ETipoRisco.Percentual:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor);
                break;

            case ETipoRisco.Unico:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor);
                dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaRiscoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRiscoId });
                break;

            default: break;
        }

        valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvavelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaProvavelValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaPossivelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaPossivelValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRemotoPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaIsoladaRemotoValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor;

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaProvavelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvavelPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaRemotoPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaRemotoPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaPossivelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaPossivelPercentual) * 100 });

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaJurosValor", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaValor", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaIndiceId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaIndiceId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaProvisaoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaProvisaoId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaJurosDataBase", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaJurosDataBase });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaIsoladaCorrecaoDataBase", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaIsoladaCorrecaoDataBase });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async atualizarValoresMultaMora(valores: any): Promise<void> {

        let dataValores: any[] = [];

        switch (valores?.valoresFaseReadDto?.tipoRisco) {

            case ETipoRisco.Detalhando:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor =
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraProvavelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraPossivelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRemotoValor);
                break;

            case ETipoRisco.Percentual:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor);
                break;

            case ETipoRisco.Unico:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor);
                dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraRiscoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraRiscoId });
                break;

            default: break;
        }

        valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraProvavelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraProvavelValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraPossivelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraPossivelValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraRemotoPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaMoraRemotoValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor;

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraProvavelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraProvavelPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraRemotoPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraRemotoPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraPossivelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraPossivelPercentual) * 100 });

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraJurosValor", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraValor", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraValor });


        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraIndiceId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraIndiceId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraProvisaoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraProvisaoId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraJurosDataBase", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraJurosDataBase });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaMoraCorrecaoDataBase", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaMoraCorrecaoDataBase });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async atualizarValoresMultaOficio(valores: any): Promise<void> {

        let dataValores: any[] = [];

        switch (valores?.valoresFaseReadDto?.tipoRisco) {

            case ETipoRisco.Detalhando:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor =
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioProvavelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioPossivelValor) +
                    parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRemotoValor);

                break;

            case ETipoRisco.Percentual:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor);
                break;

            case ETipoRisco.Unico:
                valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor = parseFloat(valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor);
                dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioRiscoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioRiscoId });
                break;

            default: break;
        }

        valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioProvavelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioProvavelValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioPossivelPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioPossivelValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor;
        valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioRemotoPercentual = parseFloat(valores?.valoresFaseReadDto?.valoresMultaReadDto?.multaOficioRemotoValor) / valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor;

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioProvavelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioProvavelPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioRemotoPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioRemotoPercentual) * 100 });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioPossivelPercentual", "value": tratarValorZerado(valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioPossivelPercentual) * 100 });

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioJurosValor", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosValor });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioValor", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioValor });

        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioIndiceId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioIndiceId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioProvisaoId", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioProvisaoId });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioJurosDataBase", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioJurosDataBase });
        dataValores.push({ "op": "replace", "path": "valoresMulta/multaOficioCorrecaoDataBase", "value": valores.valoresFaseReadDto.valoresMultaReadDto.multaOficioCorrecaoDataBase });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async atualizarStatus(valores: any): Promise<void> {

        let dataValores: any[] = [];

        dataValores.push({ "op": "replace", "path": "status", "value": valores.valoresFaseReadDto.status });

        await Promise.all([
            await api.patch<void>(`ValoresProcessoPedido/AtualizaValoresPedidoPorId?id=${valores.valoresFaseReadDto.valoresFaseId}`, dataValores)
        ]);
    }

    async trocarTipoRiscoId(processoId: number, tipoRiscoId: number): Promise<void> {

        let data = {
            processoId,
            tipoRiscoId
        }

        await Promise.all([
            await api.post<void>(`ValoresProcessoPedido/AlterarTipoRiscoValores`, data)
        ]);
    }
    async alterarFaseValores(adicionaValores: AlterarFaseValoresRequest): Promise<IAlterarFaseValores> {
        let { data: result } = await api.post<IAlterarFaseValores>(`ValoresProcessoPedido/AlterarFaseValores`, adicionaValores);

        return result;
    }
}


export default new ValoresService();