import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IEnvelope from "../interfaces/IEnvelope";
import ISignatarioPapel from "../interfaces/ISignatarioPapel";

import FiltroPesquisaEnvelopeRequest from "../interfaces/Requests/FiltroPesquisaEnvelopeRequest";
import IEnvelopeSignatario from "../interfaces/IEnvelopeSignatario";
import ID4SignMetodoAutenticacao from "../interfaces/ID4SignMetodoAutenticacao";
import IXSignerConfiguracao from "../interfaces/IXSignerConfiguracao";


class EnvelopeService {
    async cancelarEnvelope(hashEnvelope: string): Promise<IEnvelope> {
        const { data: resposta } = await api.put(`XSigner/CancelaEnvelope?hashEnvelope=${hashEnvelope}`);
        return resposta.data as IEnvelope;
    }

    async gerarZip(hashEnvelope: string): Promise<Blob> {
        const { data: result } = await api.get(`XSigner/CriaArquivoZip?hashEnvelope=${hashEnvelope}`, { responseType: 'blob' });
        return result;
    }

    async gerarExcel(relatorioId: number): Promise<Blob> {
        const { data: result } = await api.get(`XSigner/GerarRelatorio?relatorioId=${relatorioId}`, { responseType: 'blob' });
        return result;
    }

    assinarDocumento(dados: { HashEnvelope: string; HashSignatario: string; DocIdentificacaoTipo: number; DocIdentificacaoNumero: string; IPRequest: string; File: File; DataNascimento: string; }): Promise<any> {
        const dadosFormulario = new FormData()

        dadosFormulario.append('HashEnvelope', dados.HashEnvelope);
        dadosFormulario.append('HashSignatario', dados.HashSignatario);
        dadosFormulario.append('DocIdentificacaoTipo', dados.DocIdentificacaoTipo.toString());
        dadosFormulario.append('DocIdentificacaoNumero', dados.DocIdentificacaoNumero);
        dadosFormulario.append('DataNascimento', dados.DataNascimento);
        dadosFormulario.append('IPRequest', dados.IPRequest);
        dadosFormulario.append('File', dados.File);

        return api.post(`XSigner/AssinaDocumento`, dadosFormulario, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    async recuperaEnvelopePorSignatario(parametros: { hashEnvelope: string; hashSignatario: string }): Promise<IEnvelope> {
        const { data } = await api.get(
            `XSigner/RecuperaEnvelopePorSignatario?hashEnvelope=${parametros.hashEnvelope}&hashSignatario=${parametros.hashSignatario}`
        );
        return data.data;
    }

    async reenviarLink(hashSignatario: string | undefined) {
        const result = await api.post(`XSigner/ReenviaLinkSignatario?hashSignatario=${hashSignatario}`);
        return result.data;
    }
    async removerSignatario(hashSignatario: string) {
        const result = await api.delete(`XSigner/ExcluiSignatarioEnvelope?hashSignatario=${hashSignatario}`);
        return result.data;
    }

    async adicionaEnvelope(envelope: IEnvelope, mensagemUsuario: string): Promise<IEnvelope> {

        let objeto: any = {};

        if (envelope.contratoId) objeto.contratoId = envelope.contratoId;
        if (envelope.procuracaoId) objeto.procuracaoId = envelope.procuracaoId;

        let request = {
            envioPorOrdem: envelope.envioPorOrdem,
            status: 1,
            codigo: envelope.codigo,
            mensagemUsuario: mensagemUsuario,
            modulo: envelope.modulo,
            ...objeto,

            documentos: envelope.documentos.map(d => ({
                documentoId: d.documentoId,
                ordem: d.ordem,
            })),
            plataformaDeAssinatura: envelope?.signatarios[0]?.plataformaDeAssinatura,
            signatarios: envelope.signatarios.map(s => ({
                ordemAssinatura: s.ordemAssinatura,
                papelSignatarioId: s.papelSignatarioId,
                status: 1,
                tipoEnvio: 1,
                nome: s.nome,
                email: s.email,
                numeroCelular: '',
                codigo: s.codigo,
                metodoAutenticacao: s.metodoAutenticacao
            }))
        }

        const result = await api.post<IEnvelope>('/XSigner/AdicionaEnvelope', request);

        return result.data;
    }

    async upload(dados: { Modulo: string; Origem: string; File: File; TipoDocumentoId: string; Descricao: string; Restrito: string; }): Promise<any> {
        const dadosFormulario = new FormData()

        dadosFormulario.append('Modulo', dados.Modulo);
        dadosFormulario.append('Origem', dados.Origem);
        dadosFormulario.append('File', dados.File);
        dadosFormulario.append('TipoDocumentoId', dados.TipoDocumentoId);
        dadosFormulario.append('Descricao', dados.Descricao);
        dadosFormulario.append('Restrito', dados.Restrito);

        const arquivo = new FileReader();

        const controle = {
            carregando: true,
            arquivoB64: ""
        }

        arquivo.onload = async () => {
            controle.carregando = false;
            controle.arquivoB64 = arquivo.result as string;
        }
        arquivo.onerror = () => {
            controle.carregando = false;
        }
        arquivo.onabort = () => {
            controle.carregando = false;
        }

        arquivo.readAsDataURL(dados.File);

        while (controle.carregando) {
            await new Promise(resolve => setTimeout(resolve, 10));
        }

        const result = await api.post<number>('/ged/awss3/upload', dadosFormulario, { headers: { 'Content-Type': 'multipart/form-data' } });

        return { id: result.data, arquivoB64: controle.arquivoB64 };
    }

    async atualizarSignatario(signatario: IEnvelopeSignatario): Promise<IEnvelope> {
        const { data: result } = await (
            await api.patch(
                `XSigner/AtualizaSignatarioPorHashSignatario?hashSignatario=${signatario.hashSignatario}`,
                [
                    {
                        "path": "/nome", "op": "replace",
                        "value": signatario.nome
                    },
                    {
                        "path": "/email", "op": "replace",
                        "value": signatario.email
                    },
                    {
                        "path": "/papelSignatarioId", "op": "replace",
                        "value": signatario.papelSignatarioId
                    }
                ]
            )).data;
        return result;
    }

    async atualizarSignatarios(envelope: IEnvelope): Promise<void> {
        const signatarios: IEnvelopeSignatario[] = []
        for (let index = 0; index < envelope.signatarios.length; index++) {
            const signatario = envelope.signatarios[index];
            if (signatario.status && signatario.status > 1) {
                continue;
            }
            if (signatario.hashSignatario) {
                const { data: resposta } = await api.patch(
                    `XSigner/AtualizaSignatarioPorHashSignatario?hashSignatario=${signatario.hashSignatario}`,
                    [
                        {
                            "path": "/ordemAssinatura", "op": "replace",
                            "value": signatario.ordemAssinatura
                        },
                        {
                            "path": "/nome", "op": "replace",
                            "value": signatario.nome
                        },
                        {
                            "path": "/email", "op": "replace",
                            "value": signatario.email
                        },
                        {
                            "path": "/papelSignatarioId", "op": "replace",
                            "value": signatario.papelSignatarioId
                        }
                    ]
                )

                const signatarioAtualizado = resposta.data.signatarios[0];
                if (signatarioAtualizado) signatarios.push(signatarioAtualizado);
            } else {
                const { data: resposta } = await api.post(`XSigner/AdicionaSignatarioEnvelope`, {
                    ...signatario,
                    ordemAssinatura: (signatario.ordemAssinatura >= 0) ? signatario.ordemAssinatura : index,
                    hashEnvelope: envelope.hashEnvelope,
                    papelSignatarioId: 1,
                    status: 1,
                    tipoEnvio: 1,
                    numeroCelular: "",
                    codigo: ""
                })
                const respostaSignatarios = resposta.signatarios?.find((s: IEnvelopeSignatario) => s.email === signatario.email);
                signatarios.push(respostaSignatarios[0] as IEnvelopeSignatario);
            }
        }

        envelope.signatarios = signatarios.sort((a, b) => a.ordemAssinatura - b.ordemAssinatura);
    }

    async criarSignatario(signatario: IEnvelopeSignatario, hashEnvelope: string): Promise<IEnvelopeSignatario> {
        const { data: envelope } = await api.post<IEnvelope>(`XSigner/AdicionaSignatarioEnvelope`, {
            ...signatario,
            hashEnvelope: hashEnvelope,
            status: 1,
            tipoEnvio: 1,
            numeroCelular: "",
            codigo: "teste 1"
        });

        const signatariosAtualizado = envelope.signatarios?.find(x => x.email === signatario.email)

        return signatariosAtualizado as IEnvelopeSignatario;
    }

    async obterEnvelopes(filtros: FiltroPesquisaEnvelopeRequest): Promise<IRecuperaLista<IEnvelope>> {
        let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

        if (filtros.envelopeId && filtros.envelopeId.length > 0) {
            query += filtros.envelopeId.map(id => `&envelopeId=${id}`).join('')
        }

        if (filtros.codigo && filtros.codigo && filtros.codigo.length) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.contratoId && filtros.contratoId > 0) {
            query += `&contratoId=${filtros.contratoId}`
        }

        if (filtros.status && filtros.status !== 0) {
            query += `&status=${filtros.status}`;
        }

        if (filtros.dataAssinaturaMaiorIgual && filtros.dataAssinaturaMaiorIgual && filtros.dataAssinaturaMaiorIgual.length) {
            query += `&dataAssinaturaMaiorIgual=${filtros.dataAssinaturaMaiorIgual}`;
        }

        if (filtros.dataAssinaturaMenorIgual && filtros.dataAssinaturaMenorIgual && filtros.dataAssinaturaMenorIgual.length) {
            query += `&dataAssinaturaMenorIgual=${filtros.dataAssinaturaMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMaiorIgual && filtros.dataCadastroMaiorIgual.length) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual && filtros.dataCadastroMenorIgual && filtros.dataCadastroMenorIgual.length) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }
        if (filtros.dataCanceladoMaiorIgual && filtros.dataCanceladoMaiorIgual && filtros.dataCanceladoMaiorIgual.length) {
            query += `&dataCanceladoMaiorIgual=${filtros.dataCanceladoMaiorIgual}`;
        }

        if (filtros.dataCanceladoMenorIgual && filtros.dataCanceladoMenorIgual && filtros.dataCanceladoMenorIgual.length) {
            query += `&dataCanceladoMenorIgual=${filtros.dataCanceladoMenorIgual}`;
        }

        if (filtros.nomeDocumento && filtros.nomeDocumento && filtros.nomeDocumento.length) {
            query += `&nomeDocumento=${filtros.nomeDocumento}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IEnvelope>>(`XSigner/RecuperaEnvelope?${query}`);

        return result;
    }

    async obterPapeisSignatario(filtro?: { papelSignatarioId?: number }): Promise<ISignatarioPapel[]> {
        let query = '?';

        if (filtro && filtro.papelSignatarioId) {
            query += `papelSignatarioId=${filtro.papelSignatarioId}`;
        }

        const { data: result } = await api.get<IRecuperaLista<ISignatarioPapel>>(`/PapelSignatario/RecuperaPapelSignatario${query}`);

        return await result.data;
    }


    async obterPapeisSignatarioPorPlataforma(plataforma: string): Promise<ISignatarioPapel[]> {
        let query = `offset=0&limit=1000&sort=nome`;

        if (plataforma) query += `&plataforma=${plataforma}`;

        const { data: result } = await api.get<IRecuperaLista<ISignatarioPapel>>(`/PapelSignatario/RecuperaListaLimitada?${query}`);

        return await result.data;
    }

    async obterPapelSignatarioNome(papelSignatarioId: number): Promise<string> {
        const { data: result } = await api.get<IRecuperaLista<ISignatarioPapel>>(`/PapelSignatario/papelSignatarioNome?papelSignatarioId=${papelSignatarioId}`);
        return result.data[0].nome;
    }

    async obterD4SignMetodosAutenticacao(): Promise<ID4SignMetodoAutenticacao> {
        const { data: result } = await api.get<ID4SignMetodoAutenticacao>(`XSigner/ListaMetodosAutenticacao`);

        return result;
    }

    async obterPlataformas(): Promise<IRecuperaLista<any>> {

        const { data: result } = await api.get<IRecuperaLista<any>>(`XSigner/RecuperaPlataformas`);

        return result;
    }

}


export default new EnvelopeService();