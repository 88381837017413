/* eslint-disable no-mixed-spaces-and-tabs */
import clsx from "clsx"
import * as Formik from 'formik'
import { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-masked-input'
import Select from 'react-select'
import MaskedInput from 'react-text-mask'
import { EModulo, ETelaEnum, ETipoCampoEnum } from '../../enum'
import { IConfiguracaoTela } from '../../interfaces/IConfiguracaoTela'
import { ModeloFixo } from '../../pages/SocietarioPage/EmpresaPage/Solucoes/AtosSocietarios/AtoSocietariosCampos'
import { CampoListaMulti } from '../../pages/SocietarioPage/componentes/CampoListaMulti'
import AtividadeEmpresaService from '../../services/AtividadeEmpresaService'
import LimitacaoTabelaService from '../../services/LimitacaoTabelaService'
import { MASCARA_CNPJ, MASCARA_CPF } from '../../utils/Constants'
import { getEnumByName, camelizar } from "../../pages/ConfiguracaoTelaPage/Empresa/utils"
import CadastroCampoPessoa from "../../pages/ConfiguracaoTelaPage/Empresa/utils/CadastroCampoPessoa"
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService"

interface ICamposProps extends React.PropsWithRef<any> {
	modelo: any
	handleSetValue: (value: any) => void
	campo: Formik.FieldInputProps<any> & { [key: string]: any }
	touched: boolean | Formik.FormikTouched<any> | Formik.FormikTouched<any>[] | undefined
	error: string | Formik.FormikErrors<any> | string[] | Formik.FormikErrors<any>[] | undefined
	tela: IConfiguracaoTela
	disabled?: boolean
}

const CamposCadastroEmpresaDinamicos = (props: ICamposProps) => {

	const { handleSetValue } = props
	const [loading, setLoading] = useState<boolean>()
	const [opcoes, setOpcoes] = useState<any[]>([])

	const ref = useRef<any>();

	useEffect(() => {
		carregarLista()
	}, []);

	const carregarLista = async () => {
		setLoading(true)
		let options: any[] = []

		if (props.modelo.tipo !== ETipoCampoEnum.ListaMulti as ETipoCampoEnum &&
			props.modelo.tipo !== ETipoCampoEnum.Lista as ETipoCampoEnum &&
			props.modelo.tipo !== ETipoCampoEnum.EnumLista as ETipoCampoEnum) {
			setLoading(false);
			setOpcoes(options);
		} else if (props.modelo.tipo === ETipoCampoEnum.EnumLista as ETipoCampoEnum) {
			if (props.modelo.controller && props.modelo.controller.length > 0 && props.modelo.controller !== 'NULL') {
				options = getEnumByName(props.modelo.controller)
			} else {
				options = getEnumByName(props.modelo.campoNome)
			}
		} else {
			let resultado: any[] = []
			let chave = ''

			if (props.modelo.controller && props.modelo.controller.length && !props.modelo.isXCampo) {
				try {
					chave = `${camelizar(props.modelo.controller as string)}Id`
					try {
						if (props.modelo.controller === 'AtividadeEmpresa' || props.modelo.controller === 'AtividadeEmpresaPrincipal') {
							resultado = (await AtividadeEmpresaService.obterAtividadeEmpresas({
								offset: 0,
								limit: 10000,
								sort: "atividadeEmpresaId",
								status: 0,
								nome: "",
								codigo: "",
								totalItems: 0
							})).data

							chave = "atividadeEmpresaId";

						} else {
							resultado = (await LimitacaoTabelaService.obterListaLimitadaEmpresa(props.tela, props.modelo.controller, true)).data
						}
					} catch (e) {

						resultado = (await LimitacaoTabelaService.obterListaCompleta(props.modelo.controller)).data
					}
				} catch (error: any) {
					console.log(error);

				}
				options = resultado.map((item: any) => {
					return {
						label: item.nome,
						value: item[chave]
					}
				})
			} else {

				let resultadoX = await ConfiguracaoTelaService.obterListaLimitadaCadastroXCampo({
					tipoSociedadeId: props.modelo?.tipoSociedadeId,
					tela: ETelaEnum.TelaCadastroEmpresa,
					campo: props.modelo.camposId,
					modulo: EModulo.Societario,
				});

				options = resultadoX.data.map((item: any) => {
					return {
						label: item.nome,
						value: item.xCampoOpcaoId
					}
				})
			}
		}
		setOpcoes(options)
		setLoading(false)
	}

	const handleCamposDependentes = (value: any) => {
		const modelo = props.modelo as ModeloFixo
		if (!modelo.camposDependentes) return

		// #TODO: Melhorar essa logica.
		modelo.camposDependentes.forEach((campoDependente) => {
			const field = document.getElementsByName(campoDependente)[0] as HTMLButtonElement | null;
			if (field) {
				if (value === undefined || value === null || value.length === 0) {
					field.disabled = true
					handleSetValue({ name: campoDependente, value: '' })
				}
				// Se for Sim ()
				if (value === 1) {
					field.disabled = false
				}
				//Se for Não
				if (value === 2) {
					field.disabled = true
					handleSetValue({ name: campoDependente, value: '' })
				}
			}
		})
	}

	const handleSelectChange = (valor: any, meta: any) => {
		let selecionado = {}
		const campoNome = meta.name

		if (valor === undefined || valor === null || valor.length === 0) {
			handleSetValue({ name: meta.name, value: '' })
		}
		else if (props.modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || props.modelo.multiplo) {
			selecionado = valor.map((item: any, index: number) => {
				return {
					[camelizar(campoNome) + 'Id']: item.value,
					ordem: index
				}
			})

			handleSetValue({ name: meta.name, value: selecionado })

		} else {
			handleSetValue({ name: meta.name, value: valor.value })
		}

		if (props.modelo.camposDependentes) {
			handleCamposDependentes(valor?.value)
		}
	}

	const renderInput = () => {
		switch (props.modelo.tipo) {

			case ETipoCampoEnum.ListaMulti as ETipoCampoEnum:
				return (
					<>
						<CampoListaMulti
							items={props.campo.value}
							tela={props.tela}
							setValues={props.handleSetValue}
						/>
					</>
				);
			case ETipoCampoEnum.Lista as ETipoCampoEnum:
			case ETipoCampoEnum.EnumLista as ETipoCampoEnum:
				return (
					<Select
						classNamePrefix="react-select"
						value={opcoes.find(x => x.value == props.campo.value)}
						name={props.campo.name}
						placeholder={props.modelo.label}
						isClearable={true}
						backspaceRemovesValue={true}
						onBlur={props.campo.onBlur}
						id={props.id}
						onChange={handleSelectChange}
						isOptionSelected={(option: any, value: any) => {
							if (!props.campo.value) return false
							if (props.modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || props.modelo.multiplo) {
								return props.campo.value.includes(option.value)
							}
							return option.value === props.campo.value
						}}
						isLoading={loading}
						loadingMessage={() => 'Carregando...'}
						isMulti={props.modelo.tipo === ETipoCampoEnum.ListaMulti as ETipoCampoEnum || props.modelo.multiplo}
						className={renderClassName()}
						options={opcoes}
					/>
				);
			case ETipoCampoEnum.Pessoa as ETipoCampoEnum:
				return (
					<>
						<CadastroCampoPessoa
							modelo={props.modelo}
							campo={props.campo}
							touched={props.touched}
							onBlur={props.campo.onBlur}
							error={props.error}
							pessoasInicial={props.campo.value}
							setValue={(valor) => {
								if (props.modelo.camposRetornar && props.modelo.camposRetornar.length > 0) {
									if (props.modelo.multiplo) {
										handleSetValue({
											name: camelizar(props.modelo.campoNome), value: valor.map((v: any, i: number) => {
												let r: any = {}
												props.modelo.camposRetornar?.forEach((c: string) => {
													r[c] = v[c]
												})
												return r
											})
										}
										)
									} else {
										let r: any = {}
										props.modelo.camposRetornar?.forEach((c: string) => {
											r[c] = valor[c]
										})
										handleSetValue(r)
									}
								}
								else if (props.modelo.camposExtras && props.modelo.camposExtras.length > 0) {
									handleSetValue(valor)
								} else {
									if (props.modelo.multiplo) {
										handleSetValue(
											valor.map((v: any, i: number) => {
												return {
													[camelizar(props.modelo.campoNome) + 'Id']: v.pessoaId,
													ordem: i
												}
											})
										)
									} else {
										handleSetValue(valor.pessoaId)
									}
								}
							}}
							innerRef={ref}
							tela={props.tela}
						/>
					</>
				);
			case ETipoCampoEnum.Documento as ETipoCampoEnum:
				return <MaskedInput
					className={renderClassName('form-control')}
					name={props.campo.name}
					onBlur={props.campo.onBlur}
					value={props.campo.value || ''}
					onChange={props.campo.onChange}
					placeholderChar={' '}
					mask={(value) => (value.replace(/[^0-9]/g, '').length > 11) ? MASCARA_CNPJ : [...MASCARA_CPF, /\d/]}
				/>
			case ETipoCampoEnum.AreaTexto as ETipoCampoEnum:
				return <textarea
					{...props.campo}
					onBlur={props.campo.onBlur}
					className={renderClassName('form-control')}
				></textarea>
			case ETipoCampoEnum.Percentual as ETipoCampoEnum:
				return <input
					{...props.campo}
					onBlur={props.campo.onBlur}
					className={renderClassName('form-control')}
					step="0.01"
					max={props.campo?.maxValue}
					type="number"
				/>
			case ETipoCampoEnum.Valor as ETipoCampoEnum:
				return <CurrencyInput
					className={renderClassName('form-control')}
					name={props.campo.name}
					onBlur={props.campo.onBlur}
					value={(props.campo.value || 0.0)?.toFixed('2')}
					onChange={
						(event: Event, value: string) => {
							handleSetValue(parseFloat(value.replace(',', '.')))
						}
					}
					separator=','
				/>
			case ETipoCampoEnum.Data as ETipoCampoEnum:
				return <input
					{...props.campo}
					value={props.campo.value}
					onBlur={props.campo.onBlur}
					className={renderClassName('form-control')}
					type="date"
				/>
			case ETipoCampoEnum.Inteiro as ETipoCampoEnum:
				return <input
					{...props.campo}
					onBlur={props.campo.onBlur}
					className={renderClassName('form-control')}
					max={props.campo?.maxValue}
					type="number"
				/>
			case ETipoCampoEnum.Texto as ETipoCampoEnum:
				return <input type="text" {...props.campo} className={renderClassName('form-control')} />
			default:
				return <input type="abc" {...props.campo} className={renderClassName('form-control')} />
		}
	}

	const renderClassName = (extra = ""): string => {
		return clsx(
			`${props.className || ''} ${extra}`,
			{
				'is-invalid': (props.touched && props.error) || (props.modelo.obrigatorio),
			}
		)
	}

	return <>
		<label
			htmlFor="form-nome"
			className={`form-label ${props.modelo.obrigatorio ? 'required ' : ''}fw-bolder text-orange`}
		>{props.modelo.label}

		</label>

		{renderInput()}

		{props.touched && props.error &&
			<div className="fv-plugins-message-container">
				<div className="fv-help-block">
					<span role="alert" className="text-danger">{props.error}</span>
				</div>
			</div>
		}
	</>
}

export default CamposCadastroEmpresaDinamicos