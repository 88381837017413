import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";

class TipoQuotaAcoesService {

    async obter(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoQuotasAcoes/RecuperaTipoQuotasAcoes?${query}`);

        return result;
    }
    
}


export default new TipoQuotaAcoesService();