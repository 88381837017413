import { Pagination } from "react-bootstrap";

interface EditorPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void
}

export function EditorPagination({ currentPage, totalPages, onPageChange }: EditorPaginationProps) {
  return (
    <div className="d-flex items-center justify-content-between">
      <span className="text-muted font-weight-bold">Pagina {currentPage} de {totalPages}</span>

      <Pagination>
        <Pagination.First disabled={currentPage === 1} onClick={() => onPageChange(0)} />
        <Pagination.Prev disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)} />
        <Pagination.Next disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)} />
        <Pagination.Last disabled={currentPage === totalPages} onClick={() => onPageChange(totalPages)} />
      </Pagination>
    </div>
  )
}