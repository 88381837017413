import { faEraser, faFileExcel, faFilter, faPlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import "./style/icons.css";
import { EModulo, ETelaEnum } from "../../enum";
import { setValuesXPesquisaCampos } from "../../utils/SchemasUtil";

import GridPadrao from "../../components/Comum/GridPadrao";

import ProcessoService from "../../services/ProcessoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IProcesso, { IProcessoPartesContraria } from "../../interfaces/IProcesso";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";

import Carregando from "../../components/Carregando";
import RelatorioModal from "./Modal/RelatorioModal";
import FiltroModal from "../../components/Comum/Pesquisa/FiltroModal";
import { processoInitialValues } from "../../contexts/InitialValuesContext";
import CamposPesquisaDinamicos from "../../components/Comum/Pesquisa/CamposPesquisaDinamicos";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const ProcessoPage = () => {
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [configuracoesModalTela, setConfiguracoesModalTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [limpar, setLimpar] = useState(true);
  const [pesquisar, setPesquisar] = useState(false);
  const [processos, setProcessos] = useState<IProcesso[]>([]);
  const [carregandoProcessos, setCarregandoProcessos] = useState(false);
  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);
  const [exibirFormModal, setExibirFormModal] = useState<boolean>(false);
  const [paginacao, setPaginacao] = useState<any>({
    offset: 0,
    limit: 10,
    sort: "processoId",
    totalItems: 0,
  });

  const { setProcesso, setAreaDireito } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();
  const componentRef = useRef<any>();

  const toggleModal = (): void => setExibirFormModal(!exibirFormModal);
  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

  useEffect(() => {
    if (limpar) {
      formik.resetForm();
      carregarConfiguracaoTela();
    }
  }, [limpar]);

  const carregarConfiguracaoTela = async () => {
    try {
      setCarregandoConfiguracoesTela(true);
      const resultado: IRecuperaLista<IRecuperaConfiguracaoTelaResponse> = await ConfiguracaoTelaService.obterPesquisa(EModulo.AreaDireito, ETelaEnum.TelaPesquisaPrincipal);
      setConfiguracoesTela(resultado.data);
    } catch (error: any) {
      navigate("/Processo/Padrao");
    } finally {
      setCarregandoConfiguracoesTela(false);
      setLimpar(false);
    }
  };

  useEffect(() => {
    if (pesquisar) {
      formik.handleSubmit();
    }
  }, [pesquisar]);

  const montarParteContraria = (partes: IProcessoPartesContraria[]) => partes
    .filter(parte => parte.principal)
    .map(parte => `${parte.nome} - ${parte.formaParticipacaoNome}`)
    .join(", ");


  const colunas: TableColumn<IProcesso>[] = [
    {
      cell: (processo: IProcesso) => (
        <a style={{ cursor: "pointer" }}>
          <p
            onClick={() => {
              setProcesso(processo);
              setAreaDireito({
                areaDireitoId: processo.areaDireitoId,
                nome: processo.areaDireitoNome,
                checked: false,
                codigo: "",
                status: "",
              });
              navigate("/Processo/Capa");
            }}
            style={{
              overflow: "hidden",
              textDecoration: "underline",
              fontFamily: "arial,sans-serif",
              fontSize: "18px",
              color: "#0000FF",
              paddingTop: "30px",
            }}
          >
            Processo: {processo.numeroProcesso} - {montarParteContraria(processo.processoParteContraria)}
          </p>
          <p>
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
            <a style={{ fontSize: "13px", textDecoration: "underline" }}>{processo.statusProcessoNome}</a>
            {"  "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {processo.processoId}
            {processo.favoritoId ? (
              <a
                onClick={() => removerFavorito(processo.favoritoId)}
                style={{ margin: "-28px -11px 4px 215px" }}
                className="navigation-service-icon navigation-service-icon-startShow"
              ></a>
            ) : (
              <a
                onClick={() => adicionarFavorito(processo.processoId)}
                style={{ margin: "-28px -11px 4px 215px" }}
                className="navigation-service-icon navigation-service-icon-start"
              ></a>
            )}
          </p>
          <p>
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Área: </b> {processo.areaDireitoNome}{" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Comarca: </b>
            {processo.estadoNome} {processo.cidadeNome}-{processo.estadoUF}
          </p>
          <p>
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Advogado Responsável: </b> {processo.advogadoResponsavelNome}
          </p>
          <p>
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo de Ação: </b> {processo.tipoAcaoNome}{" "}
            <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Escritório: </b> {processo.escritorioNome}{" "}
          </p>
        </a>
      ),
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = (currentRowsPerPage: number) => {
    setPaginacao((oldState: any) => ({ ...oldState, limit: currentRowsPerPage }));
  };

  const handlePageChange = (page: number) => {
    setPaginacao((oldState: any) => ({ ...oldState, offset: (page - 1) * paginacao.limit }));
  };

  const handleSort = (column: TableColumn<IProcesso>, sortDirection: string) => {
    setPaginacao((oldState: any) => ({
      ...oldState,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    }));
  };

  const adicionarFavorito = async (processoId: number) => {
    try {
      await ProcessoService.adicionaFavorido(processoId);
      Swal.fire({
        heightAuto: false,
        icon: "success",
        text: `Processo adicionado como favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      formik.handleSubmit();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel adicionar processo como favorito`,
        text: error?.response?.data?.Message || error.message,
        showConfirmButton: true,
      });
    }
  };

  const removerFavorito = async (favoritoId: number) => {
    try {
      await ProcessoService.removerFavorido(favoritoId);
      Swal.fire({
        heightAuto: false,
        icon: "info",
        text: `Processo removido de favorito`,
        showConfirmButton: false,
        timer: 3000,
      });
      formik.handleSubmit();
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel remover de Favorito`,
        text: error?.response?.data?.Message || error.message,
        showConfirmButton: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: { modulo: EModulo.AreaDireito },
    onSubmit: async (values, { setSubmitting }) => {
      setCarregandoProcessos(true);
      try {
        const resultado: IRecuperaLista<IProcesso> = await ProcessoService.obterProcessosv2({
          ...values,
          ...paginacao,
          xCampoValores: setValuesXPesquisaCampos([...configuracoesTela, ...configuracoesModalTela], formik),
        });
        setPaginacao((oldState: any) => ({
          ...oldState,
          totalItems: resultado.totalRegistros,
        }));
        setProcessos(resultado.data);
      } catch (error: any) {
        setProcessos([]);
      } finally {
        setCarregandoProcessos(false);
        setPesquisar(false);
        setSubmitting(false);
      }
    },
  });

  const renderCampos = () => configuracoesTela.map((configuracaoTela) => (
    <CamposPesquisaDinamicos
      key={configuracaoTela.campoNome}
      configuracaoTela={configuracaoTela}
      formik={formik}
    />
  ));

  return (
    <>
      <LayoutPageTitle title="Processo">
        <button onClick={() => {
          setProcesso(processoInitialValues);
          navigate("/Processo/Novo");
        }}
          className="btn btn-md btn-orange">
          Novo <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <FiltroModal
        formik={formik}
        toggleModal={toggleModal}
        setPesquisar={setPesquisar}
        exibirModal={exibirFormModal}
        configuracoesTela={configuracoesModalTela}
        setConfiguracaoesTela={setConfiguracoesModalTela}
      />

      <RelatorioModal
        formik={formik}
        toggleModal={toggleConfimarModeloModal}
        exibirModal={exibirConfirmarModeloModal}
        configuracoesTela={configuracoesTela}
        configuracoesModalTela={configuracoesModalTela}
      />

      <CollapseFiltro titulo="Filtros" content={<> {carregandoConfiguracoesTela ? <Carregando /> : <div className="row mt-12">{renderCampos()}</div>} </>} />

      <div className="row">
        <div className="col-12"
          style={{ textAlign: 'right' }}>
          <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
            <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
          </button>
          <button
            onClick={() => setLimpar(true)}
            className="btn btn-sm btn-orange search-buttom-margin ms-2"
          >
            <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
          </button>
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPrint} title="Imprimir" />
              </button>
            )}
            content={() => componentRef.current} />
          <button
            onClick={toggleConfimarModeloModal}
            className="btn btn-sm btn-orange search-buttom-margin ms-2"
            disabled={carregandoProcessos}>
            <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
          </button>

          <button onClick={toggleModal} type="submit" className="btn btn-sm btn-orange search-buttom-margin ms-2">
            <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12" style={{ overflow: !carregandoProcessos ? "auto" : "scroll", height: "600px" }}>
          <div ref={componentRef}>
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoProcessos}
              limit={paginacao.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={paginacao.totalItems}
              colunas={colunas}
              tipo="Processos"
              itens={processos}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessoPage;
