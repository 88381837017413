import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Carregando from "../../../components/Carregando";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";
import { LayoutPageContents } from "../../../layout/LayoutPageContents";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";

interface ISolucaoProps {
	title: string,
	botaoAdicionar?: JSX.Element,
	popover?: JSX.Element,
	loading?: boolean;
	children?: JSX.Element
}

export const EmpresaSolucaoLayout = ({title, loading = false, botaoAdicionar,popover, children}: ISolucaoProps) => {
	const { empresa, } = useContext<OrangeInterfaceContext>(OrangeContext);
	const navigate = useNavigate();
	
	useEffect(() => {
		if(!empresa.empresaId) {
			navigate('/Societario/Empresa')
		}
	}, [])

	return (
		<>
			<LayoutPageTitle title={`Empresas - ${title}`}/>

			<LayoutPageContents>
				<div className="d-flex align-items-center justify-content-between w-100">
					{empresa && (	
						<>
							<span
								onClick={() => {
									navigate("/Societario/Empresa/Capa");
								}}
								style={{
									overflow: "hidden",
									cursor: "pointer",
									textDecoration: "underline",
									fontFamily: "arial,sans-serif",
									fontSize: "18px",
									color: "var(--primary-base2)",
								}}
							>
							<b> Empresa: { empresa.nomeFantasia } </b>
							</span>
							{ botaoAdicionar }
						</>	
					)}
				</div>
				<div className="w-100">
					{children}
				</div>
				<div hidden={!loading} style={{position:'fixed',width: '80%', height: '100%', top: '30%' }}>
					<Carregando />
				</div>
				


			</LayoutPageContents>

			
	</>

	)

}