import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoMarca from "../interfaces/ITipoMarca";
import { AdicionaTipoMarcaRequest } from "../interfaces/Requests/TipoMarca/AdicionaTipoMarcaRequest";
import FiltroPesquisaTipoMarcaRequest from "../interfaces/Requests/TipoMarca/FiltroPesquisaTipoMarcaRequest";
import { EditarTipoMarcaRequest } from "../interfaces/Requests/TipoMarca/EditarTipoMarcaRequest";

class TipoMarcaService {
  async obterTipoMarcas(
    filtros: FiltroPesquisaTipoMarcaRequest
  ): Promise<IRecuperaLista<ITipoMarca>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ITipoMarca>>(
      `TipoMarca/RecuperaTipoMarca?${query}${limit}`
    );

    return result;
  }

  async adicionaTipoMarca(
    adicionaTipoMarca: AdicionaTipoMarcaRequest
  ): Promise<ITipoMarca> {
    let { data: result } = await api.post<ITipoMarca>(
      `TipoMarca/AdicionaTipoMarca`,
      adicionaTipoMarca
    );

    return result;
  }

  async alterarStatus(tipoMarcaId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ITipoMarca>(
      `TipoMarca/AtualizaTipoMarcaPorId?id=${tipoMarcaId}`,
      data
    );
  }

  async atualizarTipoMarca(
    editarTipoMarca: EditarTipoMarcaRequest
  ): Promise<void> {
    let dataTipoMarca = [
      { op: "replace", path: "/nome", value: editarTipoMarca.nome },
      { op: "replace", path: "/codigo", value: editarTipoMarca.codigo },
    ];

    await Promise.all([
      await api.patch<void>(
        `TipoMarca/AtualizaTipoMarcaPorId?id=${editarTipoMarca.tipoMarcaId}`,
        dataTipoMarca
      ),
    ]);
  }
}

export default new TipoMarcaService();
