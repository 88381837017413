import { useEffect, useRef } from "react";
import { DrawerProps } from "./props";
import { Container, Overlay } from "./styles";
import { useDrawer } from "./useDrawer";

export function Drawer({ children, open }: DrawerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { handleOutsideClick } = useDrawer();

  useEffect(() => {
    window.addEventListener("mousedown", (event) =>
      handleOutsideClick(event, containerRef.current)
    );
    return () =>
      window.removeEventListener("mousedown", (event) =>
        handleOutsideClick(event, containerRef.current)
      );
  }, [handleOutsideClick]);

  return (
    <Overlay open={open}>
      <Container ref={containerRef}>{children}</Container>
    </Overlay>
  );
}
