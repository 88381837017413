import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import ptBR from "date-fns/locale/pt-BR";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

import IDespesa from "../../../../../interfaces/IDespesa";
import IRecuperaLista from "../../../../../interfaces/IRecuperaLista";
import ITipoDespesa from "../../../../../interfaces/ITipoDespesa";
import { AdicionaDespesaRequest } from "../../../../../interfaces/Requests/Despesa/AdicionaDespesaRequest";
import FiltroPesquisaTipoDespesaRequest from "../../../../../interfaces/Requests/TipoDespesa/FiltroPesquisaTipoDespesaRequest";

import DespesaService from "../../../../../services/DespesaService";
import TipoDespesaService from "../../../../../services/TipoDespesaService";

import { EModulo } from "../../../../../enum";
import { currencyFormatter } from "../../../../../utils/Utils";

interface IDespesaFieldProps {
  editarDespesa?: IDespesa;
  toggleModal: () => void;
  retornaListaTipoDespesa?: (lista: ITipoDespesa[] ) => void;
  exibirModal: boolean;
  empresaId?: number;
  atoSocietarioId?: number;
  titulo: string;
  setAtualizarTabela(atualizaAgora: boolean): void;
}

const DespesaEmpresaModal = ({
  toggleModal,
  exibirModal,
  empresaId,
  atoSocietarioId,
  titulo,
  editarDespesa,
  setAtualizarTabela,
  retornaListaTipoDespesa,
}: IDespesaFieldProps) => {
  const [tipoDespesas, setTipoDespesas] = useState<ITipoDespesa[]>([]);
  const [carregandoTipoDespesa, setCarregandoTipoDespesa] = useState(false);
  const [filtrosPesquisa, setFiltrosPesquisa] =
    useState<FiltroPesquisaTipoDespesaRequest>({
      nome: "",
      codigo: "",
      status: 1,
      limit: 10,
      totalItems: 0,
      offset: 0,
      sort: "tipoDespesaId",
    });

  const initialValues: AdicionaDespesaRequest = {
    despesaId: 0,    
    tipoDespesaId: 0,
    modulo: empresaId ? EModulo.Societario : EModulo.AtoSocietario,
    empresaId: empresaId,
    atoSocietarioId: atoSocietarioId,
    descricao: "",
    dataefetiva: undefined,
    datavencimento: undefined,
    datafatal: new Date(),
    formapagamentoId: "",
    codigobarras: "",
    bancoId: "",
    agencia: "",
    conta: "",
    moedaId: "",
    valorprincipal: 0,
    valorjuros: 0,
    valorcorrecao: 0,
    valormulta: 0,
    valorhonorarios: 0,
    codigo: "",
    statusdespesaId: 0,
    centrocustoId:0,
    fornecedorId:0,

    xCampoValorList:[],
    centrosCusto:[],
  };

  useEffect(() => {
    async function montarForm() {
      await formik.resetForm();

      if (editarDespesa) {
       // await formik.setValues({ ...editarDespesa });
      }
      await formik.validateForm();
    }
    montarForm();
  }, [exibirModal, editarDespesa]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if(!values.tipoDespesaId) { 
          throw new Error('Campo Tipo Despesa é obrigatorio.')
        }
        if(!values.valorprincipal) { throw new Error('Campo Valor Principal é obrigatorio.') }
        if(!values.dataefetiva) { throw new Error('Campo Data Efetiva é obrigatorio.') }
        if(!values.datavencimento) { throw new Error('Campo Data Vencimento é obrigatorio.') }
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: titulo,
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro/edição...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          let textResponse = "";

          if (!editarDespesa) {
            await DespesaService.adicionaDespesa(values);
            textResponse = "Despesa adicionada com sucesso!";
          } else {
            await DespesaService.atualizarDespesa(values);
            textResponse = "Despesa editada com sucesso!";
          }

          toggleModal();

          Swal.hideLoading();

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            text: textResponse,
            showConfirmButton: true,
            timer: 4000,
          });

          Swal.close();
          setSubmitting(false);

          setAtualizarTabela(true);
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar/atualizar`,
          text:
            error?.response?.data?.Message && typeof error.response.data.Message
              ? error.response.data.Message
              : error.message,
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  async function cancelar() {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  async function carregarTipoDespesa() {
    try {
      setCarregandoTipoDespesa(true);
      let resultado: IRecuperaLista<ITipoDespesa>;

      resultado = await TipoDespesaService.RecuperaListaLimitada();

      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });
      setTipoDespesas(resultado.data);
      retornaListaTipoDespesa?.(resultado.data);
      
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text:
          error?.response?.data?.Message &&
          typeof error.response.data.Message === "string"
            ? error.response.data.Message
            : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setTipoDespesas([]);
    } finally {
      setCarregandoTipoDespesa(false);
    }
  }

  useEffect(() => {
    carregarTipoDespesa();
  }, []);

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">{titulo}</h5>
          <div
            onClick={() => toggleModal()}
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          >
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">
              {formik.values.despesaId > 0 && (
                <div className="col-md-6 mt-3">
                  <label
                    htmlFor="form-status"
                    className="form-label fw-bolder text-orange"
                  >
                    Status
                  </label>
                  <select
                    {...formik.getFieldProps("statusdespesaId")}
                    value={formik.values.statusdespesaId}
                    onChange={(e) => {
                      formik.setFieldTouched("statusdespesaId", true);
                      formik.setFieldValue(
                        "statusdespesaId",
                        parseInt(e.target.value)
                      );
                    }}
                    placeholder="Status"
                    className={"form-select"}
                    id="form-status"
                  >
                    <option value={0}>Todos</option>
                    <option value={3}>Cancelado</option>
                    <option value={2}>Pago</option>
                    <option value={1}>Pendente</option>
                  </select>
                </div>
              )}
            </div>

            <div className="row mt-1">
              {carregandoTipoDespesa ? (
                <>Carregando tipos de despesa...</>
              ) : (
                <div className="col-md-6 mt-3">
                  <label
                    htmlFor="form-cliente"
                    className="form-label required fw-bolder text-orange"
                  >
                    Tipo de Despesa:
                  </label>
                  <select
                    {...formik.getFieldProps("tipoDespesaId")}
                    value={formik.values.tipoDespesaId}
                    onChange={(e) => {
                      formik.setFieldTouched("tipoDespesaId", true);
                      formik.setFieldValue(
                        "tipoDespesaId",
                        parseInt(e.target.value)
                      );
                    }}
                    className={clsx(
                      "form-select",
                      {
                        "is-invalid":
                         !formik.values.tipoDespesaId
                      },
                      {
                        "is-valid":
                          formik.touched.tipoDespesaId &&
                          !formik.errors.tipoDespesaId,
                      }
                    )}
                    id="form-client"
                  >
                    <option value="0"> Selecione um tipo Despesa </option>
                    {tipoDespesas.map((map) => {
                      return (
                        <option
                          key={map.tipoDespesaId}
                          value={map.tipoDespesaId}
                        >
                          {" "}
                          {map.nome}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-codigo"
                  className="form-label fw-bolder text-orange"
                >
                  Código:
                </label>
                <input
                  {...formik.getFieldProps("codigo")}
                  placeholder="Código"
                  type="text"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.codigo && formik.errors.codigo,
                    },
                    {
                      "is-valid":
                        formik.touched.codigo && !formik.errors.codigo,
                    }
                  )}
                  id="form-codigo"
                />
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-valorprincipal"
                  className="form-label required fw-bolder text-orange"
                >
                  Valor Principal:
                </label>
                <NumberFormat
                  format={currencyFormatter}
                  placeholder={"0,00"}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                       !formik.values.valorprincipal || formik.values.valorprincipal == 0
                    },
                    {
                      "is-valid":
                        formik.touched.valorprincipal &&
                        !formik.errors.valorprincipal,
                    }
                  )}
                  value={formik.values.valorprincipal}
                  onValueChange={(values) =>
                    formik.setFieldValue(
                      "valorprincipal",
                      (parseFloat(values.value) / 100).toFixed(2),
                      true
                    )
                  }
                />
                {formik.touched.valorprincipal &&
                  formik.errors.valorprincipal && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.valorprincipal}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-valorjuros"
                  className="form-label fw-bolder text-orange"
                >
                  Valor Juros:
                </label>
                <NumberFormat
                  format={currencyFormatter}
                  placeholder={"0,00"}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.valorjuros && formik.errors.valorjuros,
                    },
                    {
                      "is-valid":
                        formik.touched.valorjuros && !formik.errors.valorjuros,
                    }
                  )}
                  value={formik.values.valorjuros}
                  thousandSeparator={false}
                  onValueChange={(values) =>
                    formik.setFieldValue(
                      "valorjuros",
                      (parseFloat(values.value) / 100).toFixed(2),
                      true
                    )
                  }
                />
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-valorcorrecao"
                  className="form-label fw-bolder text-orange"
                >
                  Valor Correcao:
                </label>
                <NumberFormat
                  format={currencyFormatter}
                  placeholder={"0,00"}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.valorcorrecao &&
                        formik.errors.valorcorrecao,
                    },
                    {
                      "is-valid":
                        formik.touched.valorcorrecao &&
                        !formik.errors.valorcorrecao,
                    }
                  )}
                  value={formik.values.valorcorrecao}
                  thousandSeparator={false}
                  onValueChange={(values) =>
                    formik.setFieldValue(
                      "valorcorrecao",
                      (parseFloat(values.value) / 100).toFixed(2),
                      true
                    )
                  }
                />
                {formik.touched.valorcorrecao &&
                  formik.errors.valorcorrecao && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.valorcorrecao}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-valormulta"
                  className="form-label fw-bolder text-orange"
                >
                  Valor Multa:
                </label>
                <NumberFormat
                  format={currencyFormatter}
                  placeholder={"0,00"}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.valormulta && formik.errors.valormulta,
                    },
                    {
                      "is-valid":
                        formik.touched.valormulta && !formik.errors.valormulta,
                    }
                  )}
                  value={formik.values.valormulta}
                  thousandSeparator={false}
                  onValueChange={(values) =>
                    formik.setFieldValue(
                      "valormulta",
                      (parseFloat(values.value) / 100).toFixed(2),
                      true
                    )
                  }
                />
                {formik.touched.valormulta && formik.errors.valormulta && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span style={{ fontSize: "11px" }} role="alert">
                        {formik.errors.valormulta}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-valorhonorarios"
                  className="form-label fw-bolder text-orange"
                >
                  Valor Honorarios:
                </label>
                <NumberFormat
                  format={currencyFormatter}
                  placeholder={"0,00"}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.valorhonorarios &&
                        formik.errors.valorhonorarios,
                    },
                    {
                      "is-valid":
                        formik.touched.valorhonorarios &&
                        !formik.errors.valorhonorarios,
                    }
                  )}
                  value={formik.values.valorhonorarios}
                  thousandSeparator={false}
                  onValueChange={(values) =>
                    formik.setFieldValue(
                      "valorhonorarios",
                      (parseFloat(values.value) / 100).toFixed(2),
                      true
                    )
                  }
                />
                {formik.touched.valorhonorarios &&
                  formik.errors.valorhonorarios && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.valorhonorarios}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-dataefetiva"
                  className="form-label required fw-bolder text-orange"
                >
                  Data Efetiva:
                </label>
                <DatePicker
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                       !formik.values.dataefetiva
                    },
                    {
                      "is-valid":
                        formik.touched.dataefetiva &&
                        !formik.errors.dataefetiva,
                    }
                  )}
                  id="form-dataefetiva"
                  selected={formik.values.dataefetiva}
                  onChange={(date: Date) =>
                    formik.setFieldValue("dataefetiva", date)
                  }
                />

                {formik.touched.dataefetiva && formik.errors.dataefetiva && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span style={{ fontSize: "11px" }} role="alert">
                        {formik.errors.dataefetiva}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label
                  htmlFor="form-datavencimento"
                  className="form-label required fw-bolder text-orange"
                >
                  Data de Vencimento:
                </label>
                <DatePicker
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                       !formik.values.datavencimento
                    },
                    {
                      "is-valid":
                        formik.touched.datavencimento &&
                        !formik.errors.datavencimento,
                    }
                  )}
                  id="form-datavencimento"
                  selected={formik.values.datavencimento}
                  onChange={(date: Date) =>
                    formik.setFieldValue("datavencimento", date)
                  }
                />

                {formik.touched.datavencimento &&
                  formik.errors.datavencimento && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.datavencimento}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="col-md-12 mt-3">
                <label
                  htmlFor="form-nome"
                  className="form-label fw-bolder text-orange"
                >
                  Descrição
                </label>
                <textarea
                  {...formik.getFieldProps("descricao")}
                  maxLength={4000}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.descricao && formik.errors.descricao,
                    },
                    {
                      "is-valid":
                        formik.touched.descricao && !formik.errors.descricao,
                    }
                  )}
                  id="form-nomeUsuario"
                />
                {formik.touched.descricao && formik.errors.descricao && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.descricao}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            onClick={() => formik.submitForm()}
            type="button"
            className="btn btn-orange ms-5"
          >
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DespesaEmpresaModal;
