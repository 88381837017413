import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoGarantia from "../interfaces/ITipoGarantia";
import { AdicionaTipoGarantiaRequest } from "../interfaces/Requests/TipoGarantia/AdicionaTipoGarantiaRequest";
import FiltroPesquisaTipoGarantiaRequest from "../interfaces/Requests/TipoGarantia/FiltroPesquisaTipoGarantiaRequest";
import { EditarTipoGarantiaRequest } from "../interfaces/Requests/TipoGarantia/EditarTipoGarantiaRequest";

class TipoGarantiaService {

    async obterTipoGarantias(filtros: FiltroPesquisaTipoGarantiaRequest): Promise<IRecuperaLista<ITipoGarantia>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<ITipoGarantia>>(`TipoGarantia/RecuperaTipoGarantia?${query}`);

        return result;
    }

    async obterListaLimitadaTipoGarantias(filtros: any): Promise<IRecuperaLista<any>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        if (filtros.modulo) {
            query += `&modulo=${filtros.modulo}`;
        }

        if (filtros.areaDireitoId) {
            query += `&areaDireitoId=${filtros.areaDireitoId}`;
        }

        if (filtros.tipoMarcaId) {
            query += `&tipoMarcaId=${filtros.tipoMarcaId}`;
        }

        if (filtros.tipoImovelId) {
            query += `&tipoImovelId=${filtros.tipoImovelId}`;
        }

        if (filtros.tipoContratoId) {
            query += `&tipoContratoId=${filtros.tipoContratoId}`;
        }

        if (filtros.tipoLicencaId) {
            query += `&tipoLicencaId=${filtros.tipoLicencaId}`;
        }

        if (filtros.tipoConsultaId) {
            query += `&tipoConsultaId=${filtros.tipoConsultaId}`;
        }

        if (filtros.tipoProcuracaoId) {
            query += `&tipoProcuracaoId=${filtros.tipoProcuracaoId}`;
        }

        let { data: result } = await api.get<IRecuperaLista<any>>(`TipoGarantia/RecuperaListaLimitada?${query}`);

        return result;
    }

    async adicionaTipoGarantia(adicionaTipoGarantia: AdicionaTipoGarantiaRequest): Promise<ITipoGarantia> {
        let { data: result } = await api.post<ITipoGarantia>(`TipoGarantia/AdicionaTipoGarantia`, adicionaTipoGarantia);

        return result;
    }


    async alterarStatus(tipoGarantiaId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<ITipoGarantia>(`TipoGarantia/AtualizaTipoGarantiaPorId?id=${tipoGarantiaId}`, data);
    }

    async atualizarTipoGarantia(editarTipoGarantia: EditarTipoGarantiaRequest): Promise<void> {

        let dataTipoGarantia = [
            { "op": "replace", "path": "/nome", "value": editarTipoGarantia.nome },
            { "op": "replace", "path": "/codigo", "value": editarTipoGarantia.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`TipoGarantia/AtualizaTipoGarantiaPorId?id=${editarTipoGarantia.tipoGarantiaId}`, dataTipoGarantia)
        ]);
    }
}


export default new TipoGarantiaService();