import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IOrigemObrigacao from "../interfaces/IOrigemObrigacao";
import { AdicionaOrigemObrigacaoRequest } from "../interfaces/Requests/OrigemObrigacao/AdicionaOrigemObrigacaoRequest";
import FiltroPesquisaOrigemObrigacaoRequest from "../interfaces/Requests/OrigemObrigacao/FiltroPesquisaOrigemObrigacaoRequest";
import { EditarOrigemObrigacaoRequest } from "../interfaces/Requests/OrigemObrigacao/EditarOrigemObrigacaoRequest";

class OrigemObrigacaoService {

    async obterOrigemObrigacoes(filtros: FiltroPesquisaOrigemObrigacaoRequest): Promise<IRecuperaLista<IOrigemObrigacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IOrigemObrigacao>>(`OrigemObrigacao/RecuperaOrigemObrigacao?${query}`);

        return result;
    }

    async adicionaOrigemObrigacao(adicionaOrigemObrigacao: AdicionaOrigemObrigacaoRequest): Promise<IOrigemObrigacao> {
        let { data: result } = await api.post<IOrigemObrigacao>(`OrigemObrigacao/AdicionaOrigemObrigacao`, adicionaOrigemObrigacao);

        return result;
    }


    async alterarStatus(origemObrigacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IOrigemObrigacao>(`OrigemObrigacao/AtualizaOrigemObrigacaoPorId?id=${origemObrigacaoId}`, data);
    }

    async atualizarOrigemObrigacao(editarOrigemObrigacao: EditarOrigemObrigacaoRequest): Promise<void> {

        let dataOrigemObrigacao = [
            { "op": "replace", "path": "/nome", "value": editarOrigemObrigacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarOrigemObrigacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`OrigemObrigacao/AtualizaOrigemObrigacaoPorId?id=${editarOrigemObrigacao.origemObrigacaoId}`, dataOrigemObrigacao)
        ]);
    }
}


export default new OrigemObrigacaoService();