import { MdCheck, MdDelete } from "react-icons/md";
import { Container, Header, Content } from "./styles";
import { CommentCard } from "../Card";
import {
  CommentThread as Thread,
  EditComment,
  DeleteComment,
} from "../../../types";
import { Path } from "slate";
import { CommonService } from "../../../lib/slate/services/commons";
import { useTenant } from "../../../hooks/useTenant";
import { useThread } from "../../../hooks/useThread";
import { useSlateStatic } from "slate-react";

interface CommentThreadProps {
  thread: Thread;
  modulo: number;
  toggleDrawer: () => void;
}

export const CommentThread = ({
  thread,
  modulo,
  toggleDrawer,
}: CommentThreadProps) => {
  const editor = useSlateStatic();
  const { thread: threadHandler, comment: commentHandler } = useThread();
  const { tenant } = useTenant();

  const selectThread = (current: Thread) => {
    threadHandler.setSelectedThread((prev) => {
      if (prev?.path === current.path) {
        return null;
      }
      return current;
    });

    if (thread.path) {
      const selectionPath = JSON.parse(thread.path) as Path;
      if (selectionPath) {
        CommonService.focus(editor, selectionPath);
      }
    }

    toggleDrawer();
  };

  const reply = async (comment: string): Promise<void> => {
    await commentHandler.reply({
      comment: comment,
      modulo,
      parentId: thread.comentarioPai.editorDocumentoComentariosId,
      tenant,
    });
  };

  const edit = async (data: EditComment): Promise<void> => {
    await commentHandler.edit(data);
  };

  const deleteComment = async (data: DeleteComment) => {
    await commentHandler.delete(data);
  };

  const deleteThread = async () => {
    await threadHandler.remove(
      thread.comentarioPai.editorDocumentoComentariosId
    );
  };

  const closeThread = async () => {
    await threadHandler.close(
      thread.comentarioPai.editorDocumentoComentariosId
    );
  };

  return (
    <Container>
      <Header onClick={() => selectThread(thread)}>
        <strong>
          Discussão #{thread.comentarioPai.editorDocumentoComentariosId}
        </strong>
        <div className="thread-actions">
          <button onClick={closeThread} type="button">
            <MdCheck />
          </button>

          <button onClick={deleteThread} type="button">
            <MdDelete />
          </button>
        </div>
      </Header>

      <Content>
        <div className="parent-comment">
          <CommentCard
            reply={reply}
            data={thread.comentarioPai}
            edit={edit}
            deleteComment={deleteComment}
          />
        </div>
        {thread.respostas.length > 0 && (
          <div className="replies">
            <strong>Respostas ({thread.respostas.length})</strong>
            <div className="replies-content">
              {thread.respostas.map((comment) => (
                <CommentCard
                  isAwnser
                  key={comment.dataCadastro}
                  data={comment}
                  edit={edit}
                  deleteComment={deleteComment}
                />
              ))}
            </div>
          </div>
        )}
      </Content>
    </Container>
  );
};
