import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import { TableColumn } from "react-data-table-component";
import { Form, Modal } from 'react-bootstrap';
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { ECamposIDEnum, EModulo } from "../../../enum";

import IFile from "../../../interfaces/IFile";

import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";

import GridPadrao from "../../Comum/GridPadrao";
import { FileDropzone } from "../DragDropZone";

interface IFileUploadFieldProps {

  campo?: number;
  principal: any;

  exibirAdicionar?: boolean;
  importarArquivos?: boolean;

  toggleModal: () => void;
  exibirModal: boolean;
}

const FileUploadParteContraria = ({

  campo,
  principal,

  exibirAdicionar = true,
  importarArquivos = false,

  toggleModal,
  exibirModal,

}: IFileUploadFieldProps) => {


  const [tipoDocumentos, setTipoDocumento] = useState<any[]>([]);

  const initialValues: IFile[] = [];

  useEffect(() => { if (exibirModal) carregaTipoDocumento(); }, [exibirModal]);


  const adicionaArquivo = (event: any) => {
    for (let index = 0; index < event.length; index++) {
      const currentFile = event[index];

      let file = {
        index: formik.values.length,
        documentoId: 0,
        pessoaId: principal.values.pessoaId,
        serRestriro: false,
        tipoDocumentoId: 0,
        file: currentFile,
        nome: currentFile.name,
        extensao: currentFile.name.substring(currentFile.name.lastIndexOf(".") + 1),
      }

      principal.values.arquivosParaImportar.push(file);

      formik.values.push(file);

    }

    formik.values.forEach((file: IFile, index: number) => (file.index = index));

    formik.submitForm();
  };



  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {

      formik.setValues(values);

      principal.setFieldValue("quantidadeArquivosSelecionados", values.length);

      setSubmitting(false);
    },
  });



  useEffect(() => { if (exibirModal) carregaTipoDocumento(); }, [exibirModal]);

  useEffect(() => { if (importarArquivos) adicionar(); }, [importarArquivos]);

  const adicionaSerRestrito = (index: number) => {
    formik.values.forEach((file) => {
      if (file.index == index) {
        if (!file.serRestriro) {
          file.serRestriro = true;
          formik.setValues(formik.values);
          return;
        }
        if (file.serRestriro) {
          file.serRestriro = false;
          formik.setValues(formik.values);
          return;
        }
      }
    });
  };

  const atribuirTipo = (index: number, tipoDocumentoId: number) => {
    formik.values.forEach((file) => {
      if (file.index == index) {
        file.tipoDocumentoId = tipoDocumentoId;
        formik.setValues(formik.values);
      }
    });
  };

  const atribuirDescricao = (index: number, descricao: string) => {
    formik.values.forEach((file) => {
      if (file.index == index) {
        file.nome = descricao;
        formik.setValues(formik.values);
      }
    });
  };

  const colunas: TableColumn<IFile>[] = [
    {
      name: 'Selecione o tipo do arquivo:',
      ignoreRowClick: true,
      cell: (row: IFile, index: number) => {
        return (
          <select
            name="tipoDocumentoId"
            value={row.tipoDocumentoId}
            onChange={(event) => atribuirTipo(index, parseInt(event.target.value))}
            className={"form-control"}
          >
            <option value="0">Selecione</option>
            {tipoDocumentos.map((map: any) => {
              return (
                <option key={map.tipoDocumentoId} value={map.tipoDocumentoId}>
                  {map.nome}
                </option>
              );
            })}
          </select>
        );
      },
    },
    {
      name: 'Descrição:',
      ignoreRowClick: true,
      cell: (row: IFile, index: number) => {
        return (
          <input
            value={row.nome}
            placeholder="Opcional"
            type="text"
            className={"form-control"}
            onChange={(e: any) => atribuirDescricao(index, e.target.value)}
          />
        );
      },
    },
    {
      name: "Restrito?",
      cell: (row: IFile) => {
        return (
          <div>
            <Form.Check
              type="switch"
              checked={row.serRestriro}
              id="custom-switch"
              onChange={() => adicionaSerRestrito(row.index)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
    {
      name: "Remover?",
      cell: (row: IFile) => {
        return (
          <div style={{ paddingRight: "10px" }}>
            <FontAwesomeIcon
              title="Remover?"
              onClick={async () => {
                const swalWithBootstrapButtons = Swal.mixin({
                  heightAuto: false,
                  customClass: {
                    confirmButton: "btn btn-danger ms-5",
                    cancelButton: "btn btn-orange ms-5",
                  },
                  buttonsStyling: false,
                });

                let result = await swalWithBootstrapButtons.fire({
                  title: "Cancelar",
                  text: `Você realmente deseja remover este arquivo?`,
                  showCancelButton: true,
                  cancelButtonText: "Não",
                  confirmButtonText: `Sim, desejo remover!`,
                });

                if (result.isConfirmed) {
                  let restantes = formik.values.filter((e) => e.index != row.index);
                  formik.setValues(restantes);
                }
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="2x"
              className="mx-1 text-orange"
              icon={faTrash}
            />
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const carregaTipoDocumento = async () => {
    try {


      let campos = await ConfiguracaoTelaService.obterListaLimitadaCadastroCampo(ECamposIDEnum.TipoDocumento, EModulo.ParteContraria, principal.values.tipoParteContrariaId, 0, 0, campo);

      setTipoDocumento(campos.data);

    } catch (error: any) {
      setTipoDocumento([]);
    }
  };

  const adicionar = () => toggleModal();


  const isValid = () => {
    let retorno = false;
    formik.values.forEach(file => {
      if (file.tipoDocumentoId == 0) retorno = true;
    });
    return retorno;
  }

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Importação de arquivos <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faFile} /> </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="row g-3">

            <div className="col-md-12 mb-3">
              <label htmlFor="form-nome" className="form-label required fw-bolder text-orange">
                Arquivo:
              </label>
              <FileDropzone
                onDrop={(acceptedFiles) => {
                  adicionaArquivo(acceptedFiles);
                }}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <GridPadrao paginationServer={true} colunas={colunas} tipo="Arquivos" itens={formik.values} />
            </div>
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>

            {exibirAdicionar ?
              <button disabled={isValid()} onClick={() => adicionar()} type="button" className=" btn btn-orange ms-5">
                Adicionar
              </button>
              :
              <button disabled={isValid()} onClick={() => toggleModal()} type="button" className=" btn btn-orange ms-5">
                Adicionar
              </button>}
            <button onClick={() => toggleModal()} type="button" className="btn btn-danger ms-5">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadParteContraria;
