export function create(fn: () => void) {
  return new Promise<void>((resolve, reject) => {
    try {
      fn();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export const promise = {
  create
}
