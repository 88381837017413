import { RenderElementProps } from "slate-react";
import { PageElement as Element } from "../../../../../../utils/types/slate";
import { Container } from "./styles";
import { documentStyles } from "../../../../lib/slate/services/document/styles";

export const PageElement = (props: RenderElementProps) => {
  const pageElement = props.element as Element;
  const { page } = documentStyles.default

  const pageStyle = {
    ...page,
    ...props.element.style,
    maxHeight: props.element.style?.height,
  }

  return (
    <Container
      id={pageElement.id}
      style={pageStyle}
      {...props.attributes}
    >
      {props.children}
    </Container>
  );
};
