import { useContext, useEffect, useLayoutEffect, useState } from "react";
import Swal from "sweetalert2";
import { Collapse, Modal } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";
import { faEraser, faFileExcel, faFilter, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

import EmpresaService from "../../services/EmpresaService";

import { showErrorMessage } from "./utils";
import { EStatusEmpresa } from "../../enum";
import GridPadrao from "../../components/Comum/GridPadrao";
import { EEmpresaStatus } from "../../enum/EEmpresaStatus";
import { ETipoEstabelecimento } from "../../enum/ETipoEstabelecimento";

import IRecuperaLista from "../../interfaces/IRecuperaLista";
import IEmpresa, { IEmpresaListagem } from "../../interfaces/IEmpresa";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaEmpresaRequest from "../../interfaces/Requests/Empresa/FiltroPesquisaEmpresaRequest";

import { OrangeContext } from "../../contexts/OrangeProvider";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import SocietarioFiltroDatasModal from "./SocietarioFiltroDatasModal";
import SocietarioFiltroListasModal from "./SocietarioFiltroListasModal";
import ITipoSociedade from "../../interfaces/ITipoSociedade";
import TipoSociedadeService from "../../services/TipoSociedadeService";
import SocietarioRelatorioModal from "./SocietarioRelatorioModal";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";

const filtroInicial: FiltroPesquisaEmpresaRequest = {
  statusEmpresa: 0,
  limit: 10,
  offset: 0,
  sort: "empresaId",
  totalItems: 0,
  modeloRelatorioId: 0,
};

const SocietarioPage = () => {
  const [Empresas, setEmpresas] = useState<IEmpresaListagem[]>([]);
  const [carregandoEmpresas, setCarregandoEmpresas] = useState(false);
  const [pesquisar, setPesquisar] = useState(false);
  const { setEmpresa } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [exibirFormListasModal, setExibirFormListasModal] = useState<boolean>(false);
  const [exibirFormDatasModal, setExibirFormDatasModal] = useState<boolean>(false);
  const [exibirMaisFiltros, setExibirMaisFiltros] = useState<boolean>(false);

  const [carregamentoTipoSociedade, setCarregamentoTipoSociedade] = useState<boolean>(false);
  const [tipoSociedadeIdOptions, setTipoSociedadeOptions] = useState<any>([]);
  const [tipoSociedadeIdSelected, setTipoSociedadeSelected] = useState([]);

  const [exibirConfirmarModeloModal, setExibirConfirmarModeloModal] = useState<boolean>(false);


  const [StatusEmpresaSelected, setStatusEmpresaSelected] = useState([]);

  const [favoritando, setFavoritando] = useState<boolean>(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaEmpresaRequest>(filtroInicial);

  const navigate = useNavigate();

  const toggleListasModal = () => setExibirFormListasModal(!exibirFormListasModal);

  const toggleDatasModal = () => setExibirFormDatasModal(!exibirFormDatasModal);

  const toggleMaisFiltros = () => setExibirMaisFiltros(!exibirMaisFiltros);

  const toggleConfimarModeloModal = (): void => setExibirConfirmarModeloModal(!exibirConfirmarModeloModal);

  const limpaFiltros = (): void => {
    setFiltrosPesquisa(filtroInicial);
    setPesquisar(true);
  }

  const removerFavorito = async (favoritoId: number, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (favoritando || !favoritoId) return false;
    setFavoritando(true);
    try {
      await EmpresaService.removerFavorito(favoritoId);
      setEmpresas(Empresas.map(empresa => {
        if (empresa.favoritoId === favoritoId) {
          empresa.favoritoId = 0;
        }
        return empresa;
      }));
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível remover favorito.",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setFavoritando(false);
    return false
  }

  const adicionarFavorito = async (empresaId: number, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

    event.preventDefault();
    event.stopPropagation();

    if (favoritando) return false;
    setFavoritando(true);
    try {
      let favorito = await EmpresaService.adicionarFavorito(empresaId);

      setEmpresas(Empresas.map(empresa => {
        if (empresa.empresaId === empresaId) {
          empresa.favoritoId = favorito.favoritoId;
        }
        return empresa;
      }));

    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível adicionar favorito.",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }

    setFavoritando(false);
    return false
  }

  const carregarEmpresas = async () => {
    try {
      setCarregandoEmpresas(true);
      let resultado: IRecuperaLista<IEmpresaListagem>;
      resultado = await EmpresaService.obterEmpresasListagem(filtrosPesquisa);
      setFiltrosPesquisa((oldState) => {
        return { ...oldState, totalItems: resultado.totalRegistros };
      });

      setEmpresas(resultado.data);
      setCarregandoEmpresas(false);
      setPesquisar(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setEmpresas([]);
    } finally {
      setCarregandoEmpresas(false);
      setPesquisar(false);
    }
  }

  useEffect(() => {
    if (pesquisar) carregarEmpresas();
  }, [pesquisar]);

  useLayoutEffect(() => {
    carregarTipoSociedade();
  }, []);

  useEffect(() => {
    const IdsSelected: number[] = [];
    tipoSociedadeIdSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    if (IdsSelected?.length > 0) {
      setFiltrosPesquisa((oldState: any) => {
        return { ...oldState, tipoSociedadeId: IdsSelected };
      });
    }
  }, [tipoSociedadeIdSelected]);

  const returnOptionsTipoSociedade = (array: any) => {
    array.forEach((item: any) => {
      tipoSociedadeIdOptions.push({
        value: item.tipoSociedadeId,
        label: item.nome,
      });
    });
    return setTipoSociedadeOptions;
  };

  const carregarTipoSociedade = async (): Promise<void> => {
    try {
      setCarregamentoTipoSociedade(true);
      let resultadoTipoSociedade: IRecuperaLista<ITipoSociedade>;
      resultadoTipoSociedade = await TipoSociedadeService.obterTipoSociedades({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTipoSociedadeOptions(returnOptionsTipoSociedade(resultadoTipoSociedade.data));
      setCarregamentoTipoSociedade(false);
    } catch (error: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
      setCarregamentoTipoSociedade(false);
      setTipoSociedadeOptions([]);
    }
  }

  const selectEmpresa = async (empresaId: number) => {
    try {
      const resultado = await EmpresaService.obterDadosEmpresa(empresaId);
      setEmpresa(resultado.data[0] as IEmpresa);
    } catch (error: any) {
      Swal.fire({
        title: "Desculpe, ocorreu um erro ao tentar obter empresa",
        text: showErrorMessage(error),
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
      })
    }
  }

  const colunas: TableColumn<IEmpresa>[] = [
    {
      cell: (Empresa) => {
        return (
          <>
            <a style={{ cursor: "pointer" }}>
              <p
                onClick={async () => {
                  setCarregandoEmpresas(true)
                  await selectEmpresa(Empresa.empresaId)
                  navigate("/Societario/Empresa/Capa");

                }}
                style={{
                  overflow: "hidden",
                  textDecoration: "underline",
                  fontFamily: "arial,sans-serif",
                  fontSize: "18px",
                  color: "#0000FF",
                  paddingTop: "30px",
                  marginBottom: ".25rem",
                  display: "flex",
                }}
              >
                <p className="col">
                  Empresa: {Empresa.razaoSocial} - {Empresa.numeroDocumento}
                </p>
                <div className="col" style={{ maxWidth: "fit-content", transform: "scale(.5)" }}>
                </div>
              </p>

              <p>
                {" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Status:</b>{" "}
                <a style={{ fontSize: "13px", textDecoration: "underline" }}>{EStatusEmpresa[Empresa.statusEmpresa]}</a>
                {"  "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>ID:</b> {Empresa.empresaId}
                {Empresa.favoritoId ? (
                  <a
                    onClick={(e) => removerFavorito(Empresa.favoritoId, e)}
                    style={{ margin: "-28px -11px 4px 160px" }}
                    className="navigation-service-icon navigation-service-icon-startShow"
                  ></a>
                ) : (
                  <a
                    onClick={(e) => adicionarFavorito(Empresa.empresaId, e)}
                    style={{ margin: "-28px -11px 4px 160px" }}
                    className="navigation-service-icon navigation-service-icon-start"
                  ></a>
                )}
              </p>
              <p>
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Tipo Estabelecimento: </b> {ETipoEstabelecimento[Empresa.tipoEstabelecimento] || 'Não informado'}{" "}
                <b style={{ fontSize: "14px", color: "var(--primary-base2)" }}>Grupo Econômico: </b>
                {Empresa.grupoEconomicoNome}{" "}
              </p>

              <div className="row mb-3">
                <div className="d-inline-flex pe-0" style={{ width: 'fit-content' }}>
                  <b style={{ margin: '0 .5rem 0 0', fontSize: "14px", color: "var(--primary-base2)" }}> Nome Fantasia:</b>
                  <span style={{ minWidth: "1rem", margin: ".05rem .5rem 0 0" }}>{Empresa.nomeFantasia}</span>
                </div>
                <div className="d-inline-flex pe-0" style={{ width: 'fit-content' }}>
                  <b style={{ margin: '0 .5rem 0 0', fontSize: "14px", color: "var(--primary-base2)" }}> Situação: </b>
                  <span style={{ minWidth: "1rem", margin: ".05rem .5rem 0 0" }}>{EEmpresaStatus[Empresa.statusEmpresa]}</span>
                </div>
                <div className="d-inline-flex pe-0" style={{ width: 'fit-content' }}>
                  <b style={{ margin: '0 .5rem 0 0', fontSize: "14px", color: "var(--primary-base2)" }}> Data da Constituição: </b>
                  <span style={{ minWidth: "1rem", margin: ".05rem .5rem 0 0" }}>{new Date(Empresa.dataConstituicao).toLocaleDateString()}</span>
                </div>
                {Empresa.atividadeEmpresaPrincipal &&
                  <div className="d-inline-flex pe-0" style={{ width: 'fit-content' }}>
                    <b style={{ margin: '0 .5rem 0 0', fontSize: "14px", color: "var(--primary-base2)" }}> Atividade Principal: </b>
                    <span style={{ minWidth: "1rem", margin: ".05rem .5rem 0 0" }}>{Empresa.atividadeEmpresaPrincipal}</span>
                  </div>}

                {Empresa.endereco && Empresa.numero &&
                  <div className="d-inline-flex pe-0" style={{ width: 'fit-content' }}>
                    <b style={{ margin: '0 .5rem 0 0', fontSize: "14px", color: "var(--primary-base2)" }}> Endereço: </b>
                    <span style={{ minWidth: "1rem", margin: ".05rem .5rem 0 0" }}> {Empresa.endereco} - {Empresa.numero}</span>
                  </div>}
              </div>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
    setPesquisar(true);
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
    setPesquisar(true);
  };

  const handleSort = async (column: TableColumn<IEmpresaListagem>, sortDirection: string) => {
    setFiltrosPesquisa((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });
    setPesquisar(true);
  };

  useEffect(() => {
    const IdsSelected: number[] = [];
    StatusEmpresaSelected.forEach((item: any) => IdsSelected.push(parseInt(item.value)));
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, statusEmpresa: IdsSelected };
    });
  }, [StatusEmpresaSelected]);

  return (
    <>
      <LayoutPageTitle title="Empresa">
        <button onClick={() => navigate("/Societario/Empresa/Novo")} className="btn btn-md btn-orange">
          Nova<FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <SocietarioRelatorioModal
        exibirModal={exibirConfirmarModeloModal}
        toggleModal={toggleConfimarModeloModal}
        filtro={filtrosPesquisa}
        setFiltro={setFiltrosPesquisa}
      />

      <div className="row">
        <div className="col-md-12" >

          < CollapseFiltro titulo="Filtros" content={
            <form onSubmit={(e) => e.preventDefault()} className="row g-3 mb-3">

              <div className="col-4">
                <label htmlFor="form-razaoSocial" className="form-label fw-bolder text-orange">
                  Denominação Social:
                </label>
                <input
                  value={filtrosPesquisa.razaoSocial}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, razaoSocial: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-razaoSocial"
                />
              </div>

              <div className="col-4">
                <label htmlFor="form-razaoSocial" className="form-label fw-bolder text-orange">
                  CNPJ:
                </label>
                <MaskedInput
                  value={filtrosPesquisa.cnpj}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, cnpj: e.target.value };
                    });
                  }
                  }
                  mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  placeholder="CNPJ"
                  type="text"
                  className={"form-control"}
                  id="form-cnpj"
                />
              </div>

              <div className="col-4">

                <label htmlFor="form-nomeFantasia" className="form-label fw-bolder text-orange">
                  Nome Fantasia:
                </label>
                <input
                  value={filtrosPesquisa.nomeFantasia}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nomeFantasia: e.target.value };
                    });
                  }}
                  placeholder="Nome"
                  type="text"
                  className={"form-control"}
                  id="form-nomeFantasia"
                />
              </div>

              <div className="col-4">

                <label className="form-label fw-bolder text-orange" htmlFor="form-inscricaoEstadual">
                  Inscrição Estadual
                </label>
                <input
                  id="form-inscricaoEstadual"
                  className={"form-control"}
                  placeholder="Inscrição Estadual"
                  onChange={(e) => { setFiltrosPesquisa(old => { return { ...old, inscricaoEstadual: e.target.value } }) }}
                  value={filtrosPesquisa.inscricaoEstadual}
                />
              </div>

              <div className="col-4">

                <label className="form-label fw-bolder text-orange" htmlFor="form-inscricaoMunicipal">
                  Inscrição Municipal
                </label>
                <input
                  id="form-inscricaoMunicipal"
                  className={"form-control"}
                  placeholder="Inscrição Municipal"
                  onChange={(e) => { setFiltrosPesquisa(old => { return { ...old, inscricaoMunicipal: e.target.value } }) }}
                  value={filtrosPesquisa.inscricaoMunicipal}
                />
              </div>

              <div className="col-4">

                <label htmlFor="form-status" className="form-label fw-bolder text-orange">
                  Status:
                </label>
                <MultiSelect
                  options={[
                    { value: 0, label: "Nula" },
                    { value: 1, label: "Ativa" },
                    { value: 3, label: "Suspensa" },
                    { value: 4, label: "Inapta" },
                    { value: 5, label: "Baixada" },
                  ]
                  }
                  value={StatusEmpresaSelected}
                  onChange={(event: any) => setStatusEmpresaSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}
                />
              </div>

              <div className="col-4">

                <label htmlFor="form-tipoSociedadeId" className="form-label fw-bolder text-orange">
                  Tipo de Sociedade:
                </label>
                <MultiSelect
                  isLoading={carregamentoTipoSociedade}
                  disabled={carregamentoTipoSociedade}
                  options={tipoSociedadeIdOptions}
                  value={tipoSociedadeIdSelected}
                  onChange={(event: any) => setTipoSociedadeSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }}

                />
              </div>

              <div className="col-4">

                <label htmlFor="form-nomeSocio" className="form-label fw-bolder text-orange">
                  Sócio:
                </label>
                <input
                  value={filtrosPesquisa.nomeSocio}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nomeSocio: e.target.value };
                    });
                  }}
                  placeholder="Nome ou Documento"
                  type="text"
                  className={"form-control"}
                  id="form-nomeSocio"
                />
              </div>

              <div className="col-4">

                <label htmlFor="form-nomeRepresentante" className="form-label fw-bolder text-orange">
                  Representante:
                </label>
                <input
                  value={filtrosPesquisa.nomeRepresentante}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, nomeRepresentante: e.target.value };
                    });
                  }}
                  placeholder="Nome ou Documento"
                  type="text"
                  className={"form-control"}
                  id="form-nomeRepresentante"
                />
              </div>

              <div className="col-12 text-end">

                <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
                </button>
                <button
                  onClick={() => {
                    limpaFiltros()
                  }}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                >
                  <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
                </button>

                <button onClick={() => setExibirMaisFiltros(!exibirMaisFiltros)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </button>

                <button
                  onClick={() => toggleConfimarModeloModal()}
                  className="btn btn-sm btn-orange search-buttom-margin ms-2"
                  disabled={carregandoEmpresas}>
                  <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
                </button>


              </div>

            </form>}/>

          <div className="col-md-12 mb-10" style={{ overflow: !carregandoEmpresas ? "auto" : "scroll", height: "600px" }}>
            <GridPadrao
              onSort={handleSort}
              progressPending={carregandoEmpresas}
              limit={filtrosPesquisa.limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationServer={true}
              paginationTotalRows={filtrosPesquisa.totalItems}
              colunas={colunas}
              tipo="Empresas"
              itens={Empresas}
            />
          </div>

        </div>
      </div>

      <Modal size="lg" centered={true} show={exibirMaisFiltros} onHide={toggleMaisFiltros}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="row mt-12 mt-6">
              <div style={{ cursor: 'pointer' }} onClick={() => setExibirFormDatasModal(!exibirFormDatasModal)} className="modal-header">
                <h5 className="modal-title text-orange"> {exibirFormDatasModal ? "-" : "+"} Filtros por datas</h5>
              </div>
            </div>
            <SocietarioFiltroDatasModal
              setPesquisar={setPesquisar}
              exibirModal={exibirFormDatasModal}
              filtrosPesquisa={filtrosPesquisa}
              setFiltrosPesquisa={setFiltrosPesquisa}
              toggleModal={toggleDatasModal}
            />
            <div className="row mt-12 mt-6">
              <div style={{ cursor: 'pointer' }} onClick={() => setExibirFormListasModal(!exibirFormListasModal)} className="modal-header">
                <h5 className="modal-title text-orange"> {exibirFormListasModal ? "-" : "+"} Filtros por listas</h5>
              </div>
            </div>
            <SocietarioFiltroListasModal
              setPesquisar={setPesquisar}
              exibirModal={exibirFormListasModal}
              filtro={filtrosPesquisa}
              setFiltro={setFiltrosPesquisa}
              toggleModal={toggleListasModal}
            />
          </div>

          <div className="modal-footer" style={{ margin: "0 auto" }}>
            <button
              type="button"
              onClick={() => {
                setPesquisar(true);
                toggleMaisFiltros();
              }}
              className="btn btn-orange me-5"
            >
              Pesquisar
            </button>
            <button onClick={() => toggleMaisFiltros()} type="button" className="btn btn-danger ms-5">
              CANCELAR
            </button>
          </div>

        </div>

      </Modal>

    </>
  );
};

export default SocietarioPage;
