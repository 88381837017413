import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import {
  faCheckCircle,
  faEraser,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import FiltroPesquisaRelatorio from "../../../interfaces/Requests/ModeloRelatorios/FiltroPesquisaRelatorio";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import GridUsuarios from "../../Admininstracao/Seguranca/GrupoUsuarios/components/GridUsuarios";
import { TableColumn } from "react-data-table-component";
import IModeloRelatorio from "../../../interfaces/IModeloRelatorio";
import ConfiguracaoModeloRelatorioService from "../../../services/ConfiguracaoModeloRelatorioService";
import ModuloService from "../../../services/ModuloService";
import { optionsType } from "../../../components/Comum/Fornecedor/FornecedorField";
import Select from "react-select";
import RelatorioParametrizacaoModal from "./RelatorioParametrizacaoModal";

const RelatorioParametrizacaoPage = () => {
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [exibirModal, setExibirModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [relatorios, setRelatorios] = useState<any[]>([]);
  const [relatorio, setRelatorio] = useState<IModeloRelatorio>();
  const [modulos, setModulos] = useState<optionsType[]>([]);
  const [modulo, setModulo] = useState<optionsType>({ label: "", value: 0 });
  const [tipos, setTipos] = useState<optionsType[]>([]);
  const [tipo, setTipo] = useState<optionsType>({ label: "", value: 0 });
  const [filtros, setFiltros] = useState<FiltroPesquisaRelatorio>({
    limit: 10,
    modeloRelatorioId: "",
    offset: 0,
    nome: "",
    tipo: 0,
    sort: "modeloRelatorioId",
    status: 0,
    totalItems: 0,
    modulo: 0,
  });

  const navigate = useNavigate();

  function renderCell(relatorio: IModeloRelatorio) {
    if (relatorio.status !== "Ativo") {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Ativar Modelo de Relatório"
              onClick={() => {
                handleStatusChange(relatorio.modeloRelatorioId, 1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faCheckCircle}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Modelo de Relatório de Relatório"
              onClick={() => {
                setRelatorio(relatorio);
                setModalEdit(true);
                setExibirModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Desativar Modelo de Relatório"
              onClick={() => {
                handleStatusChange(relatorio.modeloRelatorioId, -1);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              icon={faTrashAlt}
            />
          </div>
          <div style={{ paddingRight: "10px", cursor: "default" }}>
            <FontAwesomeIcon
              title="Editar Modelo de Relatório"
              onClick={() => {
                setRelatorio(relatorio);
                setModalEdit(true);
                setExibirModal(true);
              }}
              style={{ fontWeight: "normal", cursor: "pointer" }}
              size="1x"
              className="mx-2 text-orange"
              color="grey"
              icon={faPencilAlt}
            />
          </div>
        </>
      );
    }
  }

  const colunas: TableColumn<IModeloRelatorio>[] = [
    {
      name: "ID",
      sortField: "modeloRelatorioId",
      selector: (row: IModeloRelatorio) => row.modeloRelatorioId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IModeloRelatorio) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Módulo",
      sortField: "modulo",
      selector: (row: IModeloRelatorio) => row.moduloNome ?? "",
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo",
      sortField: "tipo",
      selector: (row: IModeloRelatorio) => row.tipo,
      sortable: true,
      wrap: true,
    },
    {
      name: null,
      ignoreRowClick: true,
      cell: renderCell,
    },
  ];

  const carregarRelatorios = async (filtro: FiltroPesquisaRelatorio) => {
    try {
      setLoading(true);

      const id =
        filtro.modeloRelatorioId !== ""
          ? parseInt(filtro.modeloRelatorioId)
          : 0;

      const data =
        await ConfiguracaoModeloRelatorioService.obterModeloRelatorio({
          ...filtro,
          modeloRelatorioId: id,
        });

      setRelatorios(data.data);

      setFiltros((prev) => ({ ...prev, totalItems: data.totalRegistros }));
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos Modelos de Relatórios",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarModulos = async () => {
    try {
      setLoading(true);

      const data = await ModuloService.obterModulos({
        codigo: "",
        limit: 0,
        nome: "",
        offset: 0,
        sort: "nome",
        status: 1,
        totalItems: 0,
      });

      const mappedModulos = data.data.map((modulo) => {
        return {
          label: modulo.nome,
          value: modulo.moduloId,
        };
      });

      setModulos(mappedModulos);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos Módulos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarTipos = async (modulo: number) => {
    try {
      setLoading(true);

      const data = await ConfiguracaoModeloRelatorioService.obterTipos(modulo);

      const mappedTipos = data.data.map((tipo) => {
        return {
          label: tipo.nome,
          value: tipo.tipoRelatorioId,
        };
      });

      setTipos(mappedTipos);
    } catch (e: any) {
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros dos Tipos de Modelos",
        text:
          e?.response?.data?.Message &&
          typeof e.response.data.Message === "string"
            ? e.response.data.Message
            : e.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const limparFiltros = async () => {
    const filtrosLimpos: FiltroPesquisaRelatorio = {
      modeloRelatorioId: "",
      limit: 10,
      nome: "",
      tipo: 0,
      offset: 0,
      sort: "modeloRelatorioId",
      status: 0,
      totalItems: 0,
      modulo: 0,
    };

    setReset(!reset);

    setFiltros(filtrosLimpos);
    setModulo({ label: "", value: 0 });
    setTipo({ label: "", value: 0 });
    setTipos([]);

    await carregarRelatorios(filtrosLimpos);
  };

  const handleStatusChange = async (
    modeloRelatorioId: number,
    status: number
  ) => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Alterar Status ",
        text: `Você realmente deseja alterar o status?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        await ConfiguracaoModeloRelatorioService.alterarStatus(
          modeloRelatorioId,
          status
        );
        Swal.fire({
          heightAuto: false,
          icon: "success",
          text: `Status alterado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });

        await limparFiltros();
      }
    } catch (error: any) {
      let mensagemErro = "Ocorreu um erro inesperado";

      if (error?.response?.data?.Message) {
        mensagemErro = error.response.data.Message;
      }

      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel alterar o Status`,
        text: mensagemErro,
        showConfirmButton: true,
      });
    }
  };

  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltros((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });

    await carregarRelatorios({ ...filtros, limit: currentRowsPerPage });
  };

  const handlePageChange = async (page: number) => {
    setFiltros((oldState) => {
      return { ...oldState, offset: (page - 1) * filtros.limit };
    });

    await carregarRelatorios({
      ...filtros,
      offset: (page - 1) * filtros.limit,
    });
  };

  const handleSort = async (
    column: TableColumn<any>,
    sortDirection: string
  ) => {
    setFiltros((oldState) => {
      return {
        ...oldState,
        sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
      };
    });

    await carregarRelatorios({
      ...filtros,
      sort: `${sortDirection === "desc" ? "-" : ""}${column.sortField}`,
    });
  };

  const handlePageClick = (relatorio: IModeloRelatorio) => {
    navigate(`/Relatorio/ConfiguracaoRelatorio/${relatorio.modeloRelatorioId}`);
  };

  useEffect(() => {
    carregarRelatorios(filtros);
    carregarModulos();
  }, []);

  return (
    <>
      <LayoutPageTitle title="Selecione o modelo que você deseja configurar">
        <button
          onClick={() => {
            setExibirModal(true);
          }}
          className="btn btn-md btn-orange"
        >
          Novo Modelo
          <FontAwesomeIcon color={"white"} className="mx-2" icon={faPlus} />
        </button>
      </LayoutPageTitle>

      <RelatorioParametrizacaoModal
        exibirModal={exibirModal}
        handleClose={async () => {
          setExibirModal(false);
          setModalEdit(false);
          setRelatorio(undefined);
          if (modalEdit) {
            await carregarRelatorios(filtros);
          }
        }}
        modulos={modulos}
        edit={modalEdit}
        relatorio={relatorio}
      />

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setReset(!reset);
          await carregarRelatorios(filtros);
        }}
        className="row g-3 mb-3"
      >
        <div className="col-md-2 ">
          <label
            htmlFor="form-status"
            className="form-label fw-bolder text-orange"
          >
            ID:
          </label>
          <input
            value={filtros.modeloRelatorioId}
            onChange={(e) => {
              e.preventDefault();
              setFiltros((oldState) => {
                return {
                  ...oldState,
                  modeloRelatorioId: e.target.value,
                };
              });
            }}
            type="number"
            placeholder="ID do Modelo"
            className="form-control"
            id="form-codigo"
          />
        </div>

        <div className="col-md-2 ">
          <label
            htmlFor="form-nome"
            className="form-label fw-bolder text-orange"
          >
            Nome:
          </label>
          <input
            value={filtros.nome}
            onChange={(e) => {
              e.preventDefault();
              setFiltros((oldState) => {
                return {
                  ...oldState,
                  nome: e.target.value,
                };
              });
            }}
            placeholder="Nome"
            type="text"
            className="form-control"
            id="form-nome"
          />
        </div>

        <div className="col-md-2">
          <label
            htmlFor="form-nome"
            className="form-label fw-bolder text-orange"
          >
            Tipo:
          </label>
          <Select
            isLoading={loading}
            placeholder="Selecione um tipo"
            id="form-select"
            options={tipos}
            isDisabled={tipos.length === 0}
            isSearchable
            value={tipo}
            onChange={async (select) => {
              if (select) {
                setFiltros((prev) => ({ ...prev, tipo: select.value }));
                setTipo(select);
                setReset(!reset);

                await carregarRelatorios({
                  ...filtros,
                  tipo: select.value,
                });
              }
            }}
          />
        </div>

        <div className="col-md-2">
          <label
            htmlFor="form-nome"
            className="form-label fw-bolder text-orange"
          >
            Módulo:
          </label>
          <Select
            isLoading={loading}
            placeholder="Selecione um módulo"
            id="form-select"
            options={modulos}
            isSearchable
            value={modulo}
            onChange={async (select) => {
              if (select) {
                setFiltros((prev) => ({ ...prev, modulo: select.value }));
                setModulo(select);
                setReset(!reset);
                setTipos([]);

                await carregarTipos(select.value);

                await carregarRelatorios({
                  ...filtros,
                  modulo: select.value,
                });
              }
            }}
          />
        </div>

        <div className="col-md-2 ">
          <label
            htmlFor="form-status"
            className="form-label fw-bolder text-orange"
          >
            Status:
          </label>

          <select
            onChange={async (e) => {
              e.preventDefault();
              setFiltros((oldState) => {
                return { ...oldState, status: parseInt(e.target.value) };
              });
              setReset(!reset);

              await carregarRelatorios({
                ...filtros,
                status: parseInt(e.target.value),
              });
            }}
            value={filtros.status}
            placeholder="Status"
            className="form-control"
            id="form-select"
          >
            <option value="0" label="Todos">
              Todos
            </option>

            <option value="1" label="Ativo">
              Ativo
            </option>

            <option value="-1" label="Inativo">
              Inativo
            </option>
          </select>
        </div>

        <div className="col-md-2">
          <div>
            <button
              type="submit"
              className="btn btn-sm btn-orange search-buttom-margin"
            >
              <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
            </button>
            <button
              onClick={limparFiltros}
              className="btn btn-sm btn-orange search-buttom-margin ms-2"
            >
              <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
            </button>
          </div>
        </div>
      </form>

      <div className="col-md-12 mb-10" style={{ cursor: "pointer" }}>
        <GridUsuarios
          onSort={handleSort}
          progressPending={loading}
          limit={filtros.limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          onRowClicked={handlePageClick}
          paginationTotalRows={filtros.totalItems}
          paginationServer={true}
          colunas={colunas}
          tipo="Modelos de Relatórios"
          itens={relatorios}
          pagination
          reset={reset}
        />
      </div>
    </>
  );
};

export default RelatorioParametrizacaoPage;
