import { HeaderElement } from "../../../utils/types/slate";
import { AddBlock } from "../types";
import { blockHelper } from "./blockHelper";
import { replacer } from "./replacer";

export function addHeader(
  child: HeaderElement,
  { block, currentPage, firstPageDiff }: AddBlock<HeaderElement>
) {
  const headerBlock = blockHelper.withId<HeaderElement>('footer', block.default, currentPage);

  if (firstPageDiff && currentPage === 0) {
    return blockHelper.withId<HeaderElement>('footer', block.firstPage, currentPage);
  }

  child = replacer.childrenReplacer(headerBlock, currentPage);
  return child;
}
