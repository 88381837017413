import { Modal } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUserAlt, faUsers } from "@fortawesome/free-solid-svg-icons";

import GridPadrao from "../../components/Comum/GridPadrao";
import IUsuario from "../../interfaces/IUsuario";

interface INomesModalProps {
  toggleModal: (carregarDados?: boolean) => void;
  exibirModal: boolean;
  usuarios: IUsuario[];
}

const ResponsaveisFeedModal = ({ toggleModal, exibirModal, usuarios }: INomesModalProps) => {

  const colunasPartesContrarias: TableColumn<IUsuario>[] = [
    {
      name: "Id",
      sortField: "nome",
      selector: (row: IUsuario) => row.usuarioId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: IUsuario) => row.nome,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Principal",
      sortField: "principal",
      selector: (row: IUsuario) => row.principal ? "Sim" : "Não",
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
  ];

  return (
    <>
      <Modal size="lg" centered={true} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">Responsáveis Todos  <FontAwesomeIcon className="mx-2 text-orange" icon={faUsers} /></h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <div className="row mt-3">
              <div className="col-md-12">
                <GridPadrao
                  paginationServer={true}
                  colunas={colunasPartesContrarias}
                  tipo="Nomes"
                  itens={usuarios}
                />
              </div>
            </div>

          </div>
          <div className="modal-footer" style={{ margin: "0 auto" }}>

          </div>
        </div>

      </Modal>
    </>
  );
};

export default ResponsaveisFeedModal;
