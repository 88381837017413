import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { faEdit, faStopCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import CompromissoService from "../../../services/CompromissoService";
import { useState } from "react";
import AreaTextoDefault from "../AreaTexto/AreaTextoDefault";

interface ICompromissoFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  compromissoId: number;
  setAtualizarTabelaCompromisso(atualiza: boolean): void;
}

const CompromissoCancelarField = ({ toggleModal, exibirModal, compromissoId, setAtualizarTabelaCompromisso: setAualizarTabelaCompromisso }: ICompromissoFieldProps) => {
  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);

  const initialValues: any = {
    observacao: "",
  };

  const schema = () => {
    let schema: any = {};
    schema.observacao = Yup.string()
      .min(3, "Campo observação está inválido")
      .required("Campo observação é obrigatório");
    return Yup.object().shape(schema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema(),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Registrar Compromisso ",
          text: `Você realmente deseja cancelar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {
          CompromissoService.cancelarCompromisso(values, compromissoId);
          setAualizarTabelaCompromisso(true);
          setSubmitting(false);
          formik.resetForm();
          toggleModal();
        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  return (
    <>

      <AreaTextoDefault
        toggleModal={toggleAreaTextoModal}
        exibirModal={exibirModalAreaTexto}
        field="observacao"
        formik={formik}
      />

      <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-orange">
              #{compromissoId}  Cancelar <FontAwesomeIcon className="mx-2 text-orange" icon={faStopCircle} />
            </h5>
            <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
              <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
            </div>
          </div>
        </div>

        <div className="modal-body">
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            >
              <div className="row mt-3">
                <div className="col-md-12">
                  <label htmlFor="form-observacao" className="form-label required fw-bolder text-orange">
                    Observação:
                  </label>

                  <div className="input-group">
                    <textarea
                      {...formik.getFieldProps("observacao")}
                      placeholder="Observação"
                      className={"form-control"}
                      id="form-observacaoCompromisso"
                      rows={5}
                    />

                    <Button
                      onClick={() => toggleAreaTextoModal()}
                      style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                      variant="secondary"
                      size="sm"
                    >
                      <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
                    </Button>
                  </div>
                  {formik.touched.observacao && formik.errors.observacao && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span style={{ fontSize: "11px" }} role="alert">
                          {formik.errors.observacao}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-3">

                <div className="modal-footer" style={{ margin: "0 auto" }}>
                  <div className="row mt-1" style={{ margin: "0 auto" }}>
                    <div className="col-md-6">
                      <button disabled={!formik.isValid} type="submit" className="btn btn-orange">
                        Cancelar
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button onClick={() => toggleModal()} type="button" className="btn btn-danger float-end">
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default CompromissoCancelarField;
