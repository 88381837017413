import Estatisticas, { IEstatisticaDado } from "../statistics/Estatisticas";
import { WidgetProps, GraficoWidget, IWidget, GraficoProps, IWidgetDado, CardProps, CardWidget, IWidgetDataSet } from "../widgets";
import WidgetWrapper from "../widgets/WidgetWrapper";


function getClassName(props: IWidget): string {
    let className = props.ClassName ||'';
    className += 'widget col'

    if(props.sm){
        className += ` col-sm-${props.sm}`
    }
    if(props.md){
        className += ` col-md-${props.md}`
    }
    if(props.lg){
        className += ` col-lg-${props.lg}`
    }
    if(props.xl){
        className += ` col-xl-${props.xl}`
    }

    return className;
}


export interface ModeloWidget {
    tipo: string;
    props: WidgetProps;
}

export abstract class GeradorWidget{
    

    private static getWidget(modelo: ModeloWidget, index:number): any{
        switch(modelo.tipo){
            case "chart":
                return <GraficoWidget key={index} {...modelo.props as GraficoProps}/>
            case "card":
                return <CardWidget key={index} {...modelo.props as CardProps}/>
            /*case "list":
                return ListaWidget;
            case "table":
                return TabelaWidget;*/
            case "error":
                return <WidgetWrapper key={index} tamanhos={{}} titulo={modelo.props.titulo} className={getClassName(modelo.props)}>
                    <div className="widget-erro">
                        <strong>Não foi possível carregar o widget!</strong>
                    </div>
                </WidgetWrapper>;
            default:
                return <WidgetWrapper key={index} tamanhos={{}} titulo={modelo.props.titulo} className={getClassName(modelo.props)}>
                    <div className="widget-erro">
                        <strong>Não foi possível carregar o widget!</strong>
                    </div>
                </WidgetWrapper>;
        }
    }

    private static extrairDados(widget: ModeloWidget, estatisticas: Estatisticas<any>): ModeloWidget{
        const dataset = {
            etiquetas: [],
            dados: []
        } as IWidgetDataSet;
        
        const etiquetas = (widget.props.dados.etiquetas as IEstatisticaDado);
        const dados = (widget.props.dados.dados as IEstatisticaDado);

        if(etiquetas.metodo){
            const callback = etiquetas.callback || ((v:any)=>v);
            const recuperado = estatisticas.recuperarDado(etiquetas.metodo, etiquetas.args)
            dataset.etiquetas = callback(recuperado);
        } else {
            dataset.etiquetas = etiquetas;
        }

        if((dados as IEstatisticaDado).metodo){
            const callback = dados.callback || ((v:any)=>v);
            const recuperado = estatisticas.recuperarDado(dados.metodo, dados.args)
            dataset.dados = callback(recuperado).map((v:any)=>{
                return {  
                    etiqueta: v?.etiqueta,        
                    valor: v.dados ? v.dados : v
                } as IWidgetDado;
            });
        }else {
            dataset.dados = dados;
        }        

        return {
            ...widget,
            props: {
                ...widget.props,
                dados: dataset
            }
        }
    }

    public static gerarWidgets(modelos:ModeloWidget[], estatisticas: Estatisticas<any>): IWidget[]{

        return modelos.map(
            (modelo:ModeloWidget, index)=>
                GeradorWidget.getWidget(
                    GeradorWidget.extrairDados(modelo, estatisticas),
                    index
                )
            );
    }

}