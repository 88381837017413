import { DadoNumerico, ModeloWidget } from "../../components/dashboard";
import { MultiDados } from "../../components/dashboard/types/TiposDados";

export const WIDGETS: ModeloWidget[] = [
  {
    tipo: "chart",
    props: {
        titulo: 'Volumetria - Status e Total de Processos',      
        dados: {
            etiquetas: {
                metodo: 'processosEstatusTipo',
                callback: (dados: MultiDados) => dados.etiquetas.map((e) => e)
            },
            dados:                              
            {
                metodo: 'processosEstatusTipo',
                callback: (dados: MultiDados) => dados.dados.map(dado => dado)
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            mostraDataLabels: true,
            direcaoDaBarra: "vertical",
            agruparDataSets: false,
            mostrarLegenda: true,
            mostrarMaisNoZoom: true        
        }

    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Empresa- Status Ativo',       
        dados: {
            etiquetas: {
                metodo: 'processoPorEmpresa',
                callback: (labels: DadoNumerico[]) => labels.map((dado)=> dado.etiqueta)
            },
            dados: {
                metodo: 'processoPorEmpresa',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: "horizontal",
            mostrarMaisNoZoom: true,
        }
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Empresa- Valor Provisionado',      
        dados: {
            etiquetas: {
                metodo: 'empresasPorValorTipoProcesso',
                callback: (dados: MultiDados) => dados.etiquetas.map((etiqueta)=> etiqueta)
            },
            dados: {
                metodo: 'empresasPorValorTipoProcesso',
                callback: (dados: MultiDados) => dados.dados.map(dado => dado)
            },
        },
        tipoGrafico: 'bar',
        empilhado:true,
        configuracaoGrafico: {
            direcaoDaBarra: 'horizontal',
            agruparDataSets: true,
            mostraDataLabels: true,
            tipoDeDado: 'moeda', 
            mostrarLegenda: true,
            posicaoLegenda: 'bottom', 
            mostrarMaisNoZoom: true
        }
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Uf - Valor Provisionado',       
        dados: {
            etiquetas: {
                metodo: 'UfPorValor',
                callback: (labels: DadoNumerico[]) => labels.map((dado)=> dado.etiqueta)
            },
            dados: {
                metodo: 'UfPorValor',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: "horizontal",
            tipoDeDado: "moeda",
            mostrarMaisNoZoom: true

        }
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Evolução - Processos distribuidos por ano.',       
        dados: {
            etiquetas: {
                metodo: 'processosPorDataDistribuicao',
                callback: (labels: DadoNumerico[]) => labels.map((dado)=> dado.etiqueta)
            },
            dados: {
                metodo: 'processosPorDataDistribuicao',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {            
            direcaoDaBarra: "vertical",
            barraInvertida: false,
                     
        }
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Evolução - Processos encerrados por ano.',       
        dados: {
            etiquetas: {
                metodo: 'processosPorDataEncerramento',
                callback: (labels: DadoNumerico[]) => labels.map((dado)=> dado.etiqueta)
            },
            dados: {
                metodo: 'processosPorDataEncerramento',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: 'vertical',
            barraInvertida: false
        }
        
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Distribuição de Processos por mês.',
        desativado: false,
        sm: '12',
        md:'6',
        lg:'3',
        dados: {
            etiquetas: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            dados:                              
            {
                metodo: 'processosAnoDistribuidoPorMes',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'line',
        configuracaoGrafico: {    
            mostrarMaisNoZoom: true, 
            tamanho: 20,           
            posicaoLegenda:'bottom',
        }
       
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Encerramento de Processos por mês.',
        desativado: false,
        sm: '12',
        md:'6',
        lg:'3',
        dados: {
            etiquetas: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            dados:                              
            {
                metodo: 'processosAnoEncerradosPorMes',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado)
            },
        },
        tipoGrafico: 'line',
        configuracaoGrafico: {
            mostrarMaisNoZoom: true,
            tamanho: 20,
            posicaoLegenda:'bottom'
        }
    }
},
{
    tipo: "chart",
    props: {
        titulo: 'Composição - Processos por UF - Ativos',
        dados: {
            etiquetas: {
                metodo: 'processosPorUF',
                callback: (labels: DadoNumerico[]) => labels.map((dado)=> dado.etiqueta)
            },
            dados: {
                metodo: 'processosPorUF',
                callback: (dados: DadoNumerico[]) => dados.map(dado => dado.total())
            },
        },
        tipoGrafico: 'bar',
        configuracaoGrafico: {
            direcaoDaBarra: 'vertical',
            barraInvertida: false
        }
        
    }
}


]