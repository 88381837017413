import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import ITipoAto from "../interfaces/ITipoAto";
import { AdicionaTipoAtoRequest } from "../interfaces/Requests/TipoAto/AdicionaTipoAtoRequest";
import FiltroPesquisaTipoAtoRequest from "../interfaces/Requests/TipoAto/FiltroPesquisaTipoAtoRequest";
import { EditarTipoAtoRequest } from "../interfaces/Requests/TipoAto/EditarTipoAtoRequest";

class TipoAtoService {
  async obterTipoAtos(
    filtros: FiltroPesquisaTipoAtoRequest
  ): Promise<IRecuperaLista<ITipoAto>> {
    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&sort=${filtros.sort}`;

    const limit = filtros.limit !== 0 ? `&limit=${filtros.limit}` : "";

    if (filtros.nome) {
      query += `&nome=${filtros.nome}`;
    }

    if (filtros.codigo) {
      query += `&codigo=${filtros.codigo}`;
    }

    let { data: result } = await api.get<IRecuperaLista<ITipoAto>>(
      `TipoAto/RecuperaTipoAto?${query}${limit}`
    );

    return result;
  }

  async adicionaTipoAto(
    adicionaTipoAto: AdicionaTipoAtoRequest
  ): Promise<ITipoAto> {
    let { data: result } = await api.post<ITipoAto>(
      `TipoAto/AdicionaTipoAto`,
      adicionaTipoAto
    );

    return result;
  }

  async alterarStatus(tipoAtoId: number, status: number): Promise<void> {
    let data = [{ op: "replace", path: "/status", value: status }];

    await api.patch<ITipoAto>(
      `TipoAto/AtualizaTipoAtoPorId?id=${tipoAtoId}`,
      data
    );
  }

  async atualizarTipoAto(editarTipoAto: EditarTipoAtoRequest): Promise<void> {
    let dataTipoAto = [
      { op: "replace", path: "/nome", value: editarTipoAto.nome },
      { op: "replace", path: "/codigo", value: editarTipoAto.codigo },
    ];

    await Promise.all([
      await api.patch<void>(
        `TipoAto/AtualizaTipoAtoPorId?id=${editarTipoAto.tipoAtoId}`,
        dataTipoAto
      ),
    ]);
  }
}

export default new TipoAtoService();
