import { createContext, useState, useEffect, useContext } from "react";
import IAlterarSenhaRequest from "../interfaces/IAlterarSenhaRequest";
import IUsuarioAutenticacao from "../interfaces/IUsuarioAutenticacao";
import AtualizarUsuarioRequest from "../interfaces/Requests/AtualizarUsuarioRequest";
import AuthService from "../services/AuthService";
import Storage from "../utils/StorageUtils";
import Cookie from "../utils/CookieUtils";
import IAutenticacao from "../interfaces/IAutenticao";
import { IUploadResponse } from "../interfaces/IUploadResponse";

interface IAuthContext {
  isAuthorized: boolean;
  loading: boolean;
  signIn: (email: string, codigo: string, ip: string) => Promise<void>;
  signOut: () => Promise<void>;
  authenticate: (email: string, password: string) => Promise<IAutenticacao>;
  authenticateExternal: (email: string, hash: string) => Promise<void>;
  changePassword: (changePasswordRequest: IAlterarSenhaRequest) => Promise<void>;
  changeClient: (clienteId: number) => Promise<void>;
  updateUser: (user: AtualizarUsuarioRequest) => Promise<void>;
  avatarLabel: () => string;
  user?: IUsuarioAutenticacao;
  signInSemToken: (result: IAutenticacao) => Promise<void>;
  updateUserPhoto: (response: IUploadResponse) => Promise<void>;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }: any) => {
  const [isAuthorized, setAuthorized] = useState<boolean>(false);
  const [user, setUser] = useState<IUsuarioAutenticacao>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadStorageData();
  }, []);

  const loadStorageData = async () => {
    const storageToken = await Storage.getToken();
    const storageUser = await Storage.getUser();

    if (
      storageToken &&
      storageUser &&
      new Date().getTime() < Storage.getAccessExpireIn()
    ) {
      setUser(storageUser);
      setAuthorized(true);
    } else {
      Storage.clearStorage();
    }

    Storage.updateAccessExpireIn();

    setLoading(false);
  };

  const signIn = async (
    email: string,
    codigo: string,
    ip: string
  ): Promise<void> => {
    try {
      const result = await AuthService.signIn(email, codigo, ip);

      if (result === null) {
        throw new Error("Tratar erro");
      }

      let user = { ...result } as IUsuarioAutenticacao;

      await Storage.setToken(result.token);
      await Storage.setUser(user);

      setUser(user);
      setAuthorized(true);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const signInSemToken = async (result: IAutenticacao): Promise<void> => {
    try {
      let user = { ...result } as IUsuarioAutenticacao;

      await Storage.setToken(result.token);
      await Storage.setUser(user);

      setUser(user);
      setAuthorized(true);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const authenticate = async (
    email: string,
    password: string
  ): Promise<IAutenticacao> => {
    try {
      return await AuthService.authenticate(email, password);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  const authenticateExternal = async (
    email: string,
    hash: string
  ): Promise<void> => {
    try {

      let result = await AuthService.authenticateExternal(email, hash);
      if (result === null) {
        throw new Error("Tratar erro");
      }

      let user = { ...result } as IUsuarioAutenticacao;

      await Storage.setToken(result.token);
      await Storage.setUser(user);

      setUser(user);
      setAuthorized(true);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const signOut = async (): Promise<void> => {
    try {
      let user = await Storage.getUser();

      await Storage.updateAccessExpireIn();
      await Storage.clearStorage();
      await Cookie.clearCookie();

      if (user?.activeDitectory) {
        window.location.replace("https://xjur.com.br/");
      }
    } catch (error) {
      alert("Login não pode ser realizado");
    } finally {
      setAuthorized(false);
    }
  };

  const changePassword = async (
    changePasswordRequest: IAlterarSenhaRequest
  ): Promise<void> => {
    try {
      if (!user) {
        return;
      }

      await AuthService.changePassword(changePasswordRequest);

      if (user.primeiroAcesso) {
        setUser({ ...user, primeiroAcesso: false });
        Storage.setUser({ ...user, primeiroAcesso: false });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const changeClient = async (clienteId: number): Promise<void> => {
    let result = await AuthService.trocarCliente(clienteId);

    setTimeout(async () => {
      if (result === null) {
        throw new Error("Tratar erro");
      }

      let user = { ...result } as IUsuarioAutenticacao;

      await Storage.setToken(result.token);
      await Storage.setUser(user);

      setUser(user);
      setAuthorized(true);
    }, 1000);
  };

  const updateUserPhoto = async (response: IUploadResponse) => {
    const user = await Storage.getUser()
    if (!user) { return }
    user.urlChancela = response.urlChancela
    user.urlFoto = response.urlFoto
    await Storage.setUser(user)
    setUser(user)
  }

  const updateUser = async (
    userUpdated: AtualizarUsuarioRequest
  ): Promise<void> => {
    let userStorage = await Storage.getUser();

    if (!userStorage) {
      return;
    }

    userStorage.nomeUsuario = userUpdated.nomeUsuario;
    userStorage.corTemplate = userUpdated.corTemplate;

    await Storage.setUser(userStorage);
    setUser(userStorage);
  };

  const avatarLabel = () => {
    if (user) {
      const [name, surname] = user.nomeUsuario.split(" ");
      const [n, s] = [name?.charAt(0) ?? "", surname?.charAt(0) ?? ""];
      return `${n}${s}`;
    }

    return ""
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        loading,
        signIn,
        signOut,
        authenticate,
        authenticateExternal,
        user,
        changePassword,
        avatarLabel,
        updateUser,
        changeClient,
        signInSemToken,
        updateUserPhoto
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext<IAuthContext | any>(AuthContext);

  return context as IAuthContext;
}

export function urlLogoAuth() {
  const context = useContext<IAuthContext | any>(AuthContext);

  return context as IAuthContext;
}
