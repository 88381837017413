import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IMotivoSituacao from "../interfaces/IMotivoSituacao";
import { AdicionaMotivoSituacaoRequest } from "../interfaces/Requests/MotivoSituacao/AdicionaMotivoSituacaoRequest";
import FiltroPesquisaMotivoSituacaoRequest from "../interfaces/Requests/MotivoSituacao/FiltroPesquisaMotivoSituacaoRequest";
import { EditarMotivoSituacaoRequest } from "../interfaces/Requests/MotivoSituacao/EditarMotivoSituacaoRequest";

class MotivoSituacaoService {

    async obterMotivoSituacaos(filtros: FiltroPesquisaMotivoSituacaoRequest): Promise<IRecuperaLista<IMotivoSituacao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IMotivoSituacao>>(`MotivoSituacao/RecuperaMotivoSituacao?${query}`);

        return result;
    }

    async adicionaMotivoSituacao(adicionaMotivoSituacao: AdicionaMotivoSituacaoRequest): Promise<IMotivoSituacao> {
        let { data: result } = await api.post<IMotivoSituacao>(`MotivoSituacao/AdicionaMotivoSituacao`, adicionaMotivoSituacao);

        return result;
    }


    async alterarStatus(motivoSituacaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IMotivoSituacao>(`MotivoSituacao/AtualizaMotivoSituacaoPorId?id=${motivoSituacaoId}`, data);
    }

    async atualizarMotivoSituacao(editarMotivoSituacao: EditarMotivoSituacaoRequest): Promise<void> {

        let dataMotivoSituacao = [
            { "op": "replace", "path": "/nome", "value": editarMotivoSituacao.nome },
            { "op": "replace", "path": "/codigo", "value": editarMotivoSituacao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`MotivoSituacao/AtualizaMotivoSituacaoPorId?id=${editarMotivoSituacao.motivoSituacaoId}`, dataMotivoSituacao)
        ]);
    }
}


export default new MotivoSituacaoService();