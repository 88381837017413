import styled, { css } from "styled-components";

export const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background: #fff;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 2px;

  padding: 10px 20px;
  border-top: 1px solid #eee;
`

export const Control = styled.button<{ isActive: boolean }>`
  height: 30px;
  width: 30px;
  border: none;
  background: transparent;
  border-radius: 4px;
  transition: 0.3s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    font-size: 20px;
    font-weight: light;
  }

  &:hover {
    background: #ddd;
  }

  ${props => props.isActive && css`
    background: #d3e3fd;

    &:hover {
      background: #d3e3fd;
    }
  `}
`