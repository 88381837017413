import { Modal, ModalProps } from "react-bootstrap";
import { htmlContent } from "./htmlContent";
import { MdClose } from "react-icons/md";

export function AIDiagnosticModal(props: ModalProps) {
  return (
    <Modal size="xl" contentClassName="" {...props}>
      <Modal.Header>
        <Modal.Title>Análise de IA</Modal.Title>
        <button style={{ border: 'none', background: 'transparent', padding: 10 }} type="button" onClick={props.onHide}>
          <MdClose size={20} />
        </button>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '700px', overflowY: 'scroll', padding: 20 }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Modal>
  );
}
