import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Formik from 'formik';
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { CamposCadastroEmpresaDinamicos } from "../../../components/Empresa";
import { ETipoCampoEnum } from "../../../enum";
import { ModeloFixo } from "../EmpresaPage/Solucoes/AtosSocietarios/AtoSocietariosCampos";
import { IModalActions } from '../hooks/useDisclosure';
import { ReturnFormField } from "../interfaces";
import { generateSchemaFromModelo, getFormValuesModelo } from "../utils";
import { Modelo } from "../../ConfiguracaoTelaPage/Empresa/utils/CadastroCampo";
import { camelizar } from "../../ConfiguracaoTelaPage/Empresa/utils";


interface IModalSolucaoProps {
  handleActions: (item: any, actions: 'create' | 'update' | 'delete') => void; //Somente para exemplo.
  modalActions: IModalActions
  titulo: string;  
  empresaId: number;
  campos: Modelo[] | ModeloFixo[];
  conteudo?: JSX.Element;
  itemSelecionado?: any,
  tela?: any;
}

export default function ModalSolucao(props: IModalSolucaoProps) {
  const [ isEdit, setIsEdit ] = React.useState(false);

  //Função que verifica se nos campos que vem nas props, existe algum campo com camposDependentes. 
  const verificaSeExisteCamposDependentes = () => {
    const dependentes = props.campos.filter((campo: ModeloFixo) => campo.camposDependentes );
    if(dependentes) {
      dependentes.map((camposDependentes: ModeloFixo) => {
        camposDependentes.camposDependentes?.map((nomeCampo) => {
          if(props.itemSelecionado[camposDependentes.nome] === 1) {
            props.campos.map((campo: ModeloFixo) => {
              if(campo.nome === nomeCampo) {
                campo.disabled = false;
              }
            })
          }
          if(props.itemSelecionado[camposDependentes.nome] === 2) {
            props.campos.map((campo: ModeloFixo) => {
              if(campo.nome === nomeCampo) {
                campo.disabled = true;
              }
            })
          }
          console.log(props.itemSelecionado[camposDependentes.nome]) 
        })
      })
    }

  }

  useEffect(() => {
    if(props.modalActions.isOpen && props.itemSelecionado) {
      verificaSeExisteCamposDependentes();
      formik.setValues(props.itemSelecionado)
      setIsEdit(() => true)
    } else {
      formik.resetForm();
      setIsEdit(() => false)
    }
  },[ props.modalActions.isOpen ]);

  const formValues = getFormValuesModelo(props.campos)
  
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: generateSchemaFromModelo(props.campos),
    onSubmit: async (values) => {
      if(formik.isValid) {
        if(isEdit) {
          props.handleActions(values, 'update');
          return
        }
        props.handleActions(values, 'create');   
      }
    }, 
  })

  const handleSetValues = (field: ReturnFormField) => {
    if(Array.isArray(field.value)) {
      formik.setFieldValue(field.name, field.value);
    } else {
      formik.setFieldValue(field.name, field.value);
    }
  } 

  const onCLickAdd = () => {
    formik.submitForm();
  }

  const onCLickCancel = () => {
    formik.resetForm();
    props.modalActions.handleClose();    
  }
  
  return (
    <Modal size="lg" className={'societario'} centered={true} show={props.modalActions.isOpen} onHide={ onCLickCancel }>
      <Modal.Header>
        <Modal.Title>{props.titulo}</Modal.Title>
          <button onClick={ onCLickCancel } className={'btn btn-icon close'} >
            <FontAwesomeIcon icon={faTimes} />
          </button>
      </Modal.Header>
      
      <Modal.Body>
        <Row>
          { props.campos && props.campos.map((campo: ModeloFixo, index: number) => {
            let campoNome = camelizar(campo.campoNome);
            let value;
              if ( campo.tipo !== ETipoCampoEnum.EnumLista && !campo.multiplo && campo.controller && campo.controller.length > 0 && !campoNome.endsWith('Id')) {
                campoNome = campoNome + 'Id';
              }
              if(campo.tipo === 'Data') {
                if(formik.values[campoNome]) {
                  value = moment(formik.values[campoNome]).format("YYYY-MM-DD") || "";
                } else {
                  value = undefined;
                }
              } else {
                value = formik.values[campoNome] || "";
              }
            return (
              <div key={index} className="col-md-6 col-lg-4 mt-3">
                <CamposCadastroEmpresaDinamicos
                  modelo={campo}
                  handleSetValue={handleSetValues}
                  touched={formik.touched[campoNome] as Formik.FormikTouched<any>}
                  error={formik.errors[campoNome] as Formik.FormikErrors<any>}
                  tela={props.tela} 
                  campo={{
                    onChange: (e: any) => {
                      formik.setFieldValue(campoNome, e.target.value);
                    },
                    value: value,
                    disabled: campo.disabled,                    
                    name: campoNome,
                    onBlur: formik.getFieldProps(campoNome).onBlur
                  }}
                />
              </div>
            )            
          })}            
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ onCLickAdd } type={"button"} className={"btn btn-orange ms-5"}> Adicionar </Button>
        <Button onClick={ onCLickCancel } className={"btn btn-danger ms-5"}> Cancelar </Button>
      </Modal.Footer>
   </Modal>
  )
}