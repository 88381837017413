import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { EModulo, ETelaEnum } from "../../enum";

import ITipoConsulta from "../../interfaces/ITipoConsulta";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { IAdicionaConsultaRequest } from "../../interfaces/Requests/Consulta/IAdicionaConsultaRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import ConsultaService from "../../services/ConsultaService";
import TipoConsultaService from "../../services/TipoConsultaService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";


import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";
import CamposCadastroDinamicos from "../../components/Comum/Modulo/CamposCadastroDinamicos";
import { consultaInitialValues, tipoConsultaInicialValues } from "../../contexts/InitialValuesContext";


const ConsultaCadastroPage = () => {
  const [carregandoTiposConsulta, setCarregandoTiposConsulta] = useState<boolean>(false);
  const [tiposConsulta, setTiposConsulta] = useState<ITipoConsulta[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { consulta, setConsulta, setTipoConsulta, tipoConsulta } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarConsultaLoading, setAdicionarConsultaLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: IAdicionaConsultaRequest = {
    principalId: 0,
    consultaId: 0,

    tipoConsultaId: tipoConsulta.tipoConsultaId,
    categoriaId: tipoConsulta.tipoConsultaId,
    modulo: EModulo.Consultivo,
    tela: ETelaEnum.TelaCadastroConsultivo,

    cidadeId: 0,
    departamentoId: 0,
    escritorioId: 0,
    tipodocumentoId: 0,

    restrito: false,

    codigo: "",
    observacoesconsulta: "",
    nomesolicitante: "",
    emailsolicitante: "",
    parecerjuridico: "",
    tempogasto: "",
    descricaoconsulta: "",

    datafinalizacao: null,
    prazofatal: null,

    empresasList: [],
    partesContrariasList: [],
    advogadoInternoList: [],
    centrosCusto: [],
    xCampoValorList: [],

    quantidadeDocumentosCheckList: 0,
    quantidadeArquivosSelecionados: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Consulta",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {
          setAdicionarConsultaLoading(true);
          let resultado = await ConsultaService.adicionaConsulta({
            ...values,
            tipoConsultaId: tipoConsulta.tipoConsultaId,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });

          setConsulta(resultado);

          formik.resetForm();

          setSubmitting(false);

          setConfiguracoesTela([]);

          setAdicionarConsultaLoading(false);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Consulta cadastrado com sucesso`,
            timer: 4000,
          });
        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      }
    },
  });

  useEffect(() => {

    setConsulta(consultaInitialValues);

    setTipoConsulta(tipoConsultaInicialValues);

    carregarTipoConsulta();

    setConfiguracoesTela([]);

  }, []);


  useEffect(() => {
    if (tipoConsulta.tipoConsultaId > 0) {
      carregarConfiguracaoTela();
    } else {
      setConfiguracoesTela([]);
      setTipoConsulta(tipoConsultaInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoConsulta.tipoConsultaId]);

  const carregarTipoConsulta = async () => {
    try {
      setCarregandoTiposConsulta(true);
      let resultado = await TipoConsultaService.obterTipoConsultas({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "nome",
      });
      setTiposConsulta(resultado.data);
      setCarregandoTiposConsulta(false);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
      setCarregandoTiposConsulta(false);
      setTiposConsulta([]);
    }
  };

  const carregarConfiguracaoTela = async () => {
    setCarregandoConfiguracoesTela(true);
    let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
      TipoConsultaId: tipoConsulta.tipoConsultaId,
      Modulo: EModulo.Consultivo,
      Tela: ETelaEnum.TelaCadastroConsultivo,
    });
    setConfiguracoesTela(resultado.data);
    setCarregandoConfiguracoesTela(false);
  };

  const onChangeTipoConsulta = (value: number, texto: string) => {
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoConsultaId", value);
    formik.setFieldValue("categoriaId", value);
    formik.setFieldValue("modulo", EModulo.Consultivo);
    formik.setFieldValue("tela", ETelaEnum.TelaCadastroConsultivo);

    setTipoConsulta({
      tipoConsultaId: value,
      nome: texto,
      codigo: "",
      status: "",
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />
      <LayoutPageForButton title={`Nova Consulta ${tipoConsulta.tipoConsultaId > 0 ? " - " + tipoConsulta.nome : ""}`}>
        {consulta.consultaId > 0 ? (
          <>
            <button onClick={() => navigate("/Consulta/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{consulta.consultaId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoConsultaId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarConsultaLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarConsultaLoading && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarConsultaLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposConsulta ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de consulta...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Consulta:
            </label>
            <select
              name="tipoConsultaId"
              value={tipoConsulta.tipoConsultaId}
              className={"form-select"}
              onChange={(event: any) => onChangeTipoConsulta(event.target.value, event.target[event.target.selectedIndex].text)}
              placeholder="Nome"
              id="form-area"
            >
              <option value="0"> Selecione um tipo de consulta</option>
              {tiposConsulta.map((tipoConsulta) => {
                return (
                  <option key={tipoConsulta.tipoConsultaId} value={tipoConsulta.tipoConsultaId}>
                    {tipoConsulta.nome}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default ConsultaCadastroPage;
