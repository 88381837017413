import React, { FC, MutableRefObject, PropsWithRef, useEffect, useState } from "react";
import { faUsers, faTimes, faTrash, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as formik from 'formik';
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import { camelizar, gerarEschemaValidacao } from ".";
import RecuperaPessoa from "../../../../components/Comum/Pessoa/RecuperaPessoa";
import GridPadrao from "../../../../components/GridPadrao";
import { IConfiguracaoTela } from "../../../../interfaces/IConfiguracaoTela";
import { IPessoa } from "../../../../interfaces/IPessoa";
import { ModalAdicionaPessoa } from "../../../SocietarioPage/componentes/ModalAdicionaPessoa";
import CadastroCampo, { Modelo } from "./CadastroCampo";

export interface CadastroCampoPessoaProps extends PropsWithRef<any> {
    modelo: Modelo;
    campo: formik.FieldInputProps<any>;
    touched: boolean | formik.FormikTouched<any> | formik.FormikTouched<any>[] | undefined;
    error: string | formik.FormikErrors<any> | string[] | formik.FormikErrors<any>[] | undefined;
    innerRef?: MutableRefObject<any>;
    onBlur: (field: string) => void;
    setValue: (value: any) => void;
    tela: IConfiguracaoTela;
    pessoasInicial?: IPessoa[];
}


const CadastroCampoPessoa: FC<CadastroCampoPessoaProps> = ({ innerRef, modelo, campo, onBlur, setValue, tela, pessoasInicial }) => {

    const [modalAberto, setModalAberto] = useState(false);
    const [checkSocios, setCheckSocios] = useState(false);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [, setTipoDocumento] = useState('');
    const [, setNumeroDocumento] = useState('');
    const [pessoas, setPessoas] = useState<IPessoa[]>(pessoasInicial || []);
    const [pessoaAtual, setPessoaAtual] = useState<IPessoa>();
    const [pessoaAdicionar, setPessoaAdicionar] = useState<IPessoa>();
    const [ultimaPessoaCNPJSelecionado, setUltimaPessoaCNPJSelecionado] = useState<IPessoa & { key?: number }>();
    const [dadosFiltrados, setDadosFiltrados] = useState<IPessoa[]>([]);
    const [, setMaxQuotas] = useState<number>(100);

    const mergePessoaDadosCNPJA = async (pessoaCadastrada?: IPessoa) => {
        pessoas.map(async (pessoaCnpja: IPessoa & { key?: number }) => {
            if (pessoaCadastrada) {
                if (pessoaCnpja.key === ultimaPessoaCNPJSelecionado?.key) {
                    setPessoaAtual({ ...pessoaCnpja, ...pessoaCadastrada });
                }
            }
        })
    }

    useEffect(() => {
        if (checkSocios === true) {
            setDadosFiltrados(campo.value);
        }
    }, [checkSocios])

    useEffect(() => {
        if (modelo.campoNome === 'SociosAdministradores') {
            let maxValue = 100;
            dadosFiltrados && dadosFiltrados.length > 0 && dadosFiltrados.map((pessoa: any) => {
                maxValue -= pessoa.quotasAcoes;
            })

            modelo.camposExtras?.map((campoExtra: any) => {
                if (campoExtra.campoNome === "QuotasAcoes") {
                    campoExtra.maxValue = maxValue
                }

            })

            setMaxQuotas(maxValue);

        }
    }, [pessoas, dadosFiltrados])

    const editar = function (pessoaAtual: IPessoa & { key?: number }, remove = false) {
        if (modelo.multiplo) {
            let novoValor: any[] = [];

            if (dadosFiltrados.length > 0) {
                novoValor = [
                    ...dadosFiltrados.filter((item: any) => item.key !== pessoaAtual.key)
                ]
            }

            if (!remove) {
                novoValor.push({
                    ...pessoaAtual
                })
            }
            setValue(novoValor);
            setDadosFiltrados(novoValor);
            setPessoaAtual(undefined);

        } else {
            if (remove) {
                setValue({});
                setPessoaAtual(undefined);
                setPessoas([])
            } else {
                setValue({
                    pessoaAtual
                });
                setPessoas([{
                    ...pessoaAtual
                }])
                setPessoaAtual(undefined)
            }
        }
    }

    const gerarInicial = () => {
        let valores: any = {};

        modelo.camposExtras?.forEach(campo => {
            valores[camelizar(campo.nome)] = campo.valor;
        })

        return valores;
    }

    const extraForm = useFormik<any>({
        validationSchema: gerarEschemaValidacao(modelo.camposExtras || []),
        initialValues: modelo.camposExtras ? gerarInicial() : {},
        validateOnBlur: true,
        onSubmit: (values: any) => {
            return values;
        },
    })

    const colunas: TableColumn<IPessoa & { dataEntrada: string }>[] = [
        {
            name: "Id",
            sortField: "pessoaId",
            selector: (row) => row.pessoaId,
            sortable: true,
            wrap: true,
            ignoreRowClick: true,
        },
        {
            name: "Nome",
            sortField: "nome",
            selector: (row) => row.nome,
            sortable: true,
            wrap: true,
        },
        {
            name: "Documento",
            sortField: "documento",
            selector: (row) => row.numeroDocumento,
            sortable: true,
            wrap: true,
        },
        {
            name: "Data da Entrada",
            sortField: "nome",
            selector: (row) => row.dataEntrada,
            sortable: true,
            wrap: true,
        },
        {
            name: modelo.camposExtras ? 'Opções' : "Remover?",
            cell: (row) => {
                return (
                    <div>
                        <FontAwesomeIcon
                            title={`Editar ${modelo.label}`}
                            onClick={() => {
                                adiconaPessoaSeNaoExistir(row)
                                extraForm.setValues(row);
                            }}
                            style={{ fontWeight: 'normal', cursor: 'pointer' }}
                            size='2x'
                            className='mx-2 text-orange'
                            icon={faPen}
                        />
                        <FontAwesomeIcon
                            title={`Exluir ${modelo.label}`}
                            onClick={() => { editar(row, true); }}
                            style={{ fontWeight: 'normal', cursor: 'pointer' }}
                            size='2x'
                            className='mx-2 text-orange'
                            icon={faTrash}
                        />
                    </div>

                );
            },
            ignoreRowClick: true,
        },
    ];

    const adiconaPessoaSeNaoExistir = (pessoa: IPessoa) => {
        setUltimaPessoaCNPJSelecionado(pessoa);
        setPessoaAdicionar(pessoa);

        if (!pessoa.pessoaId) {
            setModalCadastroAberto(true)
        } else {
            const dados = pessoas.find((p) => p.nome === pessoa.nome);
            extraForm.setValues({ ...dados, ...pessoa })
            setPessoaAtual({ ...dados, ...pessoa })
        }
    }

    const handleAdicionarPessoa = (pessoa: IPessoa) => {
        try {
            if (pessoa.pessoaId === 0) {
                setModalCadastroAberto(true);
            } else if (pessoas.find((p: IPessoa) => p.pessoaId == pessoa.pessoaId)) {
                return
            } else {
                if (modelo.multiplo) {
                    if (modelo.camposExtras && modelo.camposExtras.length) {
                        mergePessoaDadosCNPJA(pessoa);
                    } else {
                        mergePessoaDadosCNPJA(pessoa);
                    }
                } else {
                    if (modelo.camposExtras && modelo.camposExtras.length) {
                        setPessoaAtual({ ...pessoa });
                        setModalAberto(true)
                    } else {
                        setPessoas([
                            {
                                ...pessoa
                            }
                        ]);
                    }
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            setModalCadastroAberto(false)
        }
    }

    return <>
        <div>
            <Button
                onClick={() => {
                    setModalAberto(true)
                    setCheckSocios(true)
                }}
                className="btn-orange form-control"
                variant="secondary"
                size="sm"
            >
                <FontAwesomeIcon color={"white"} className="mx-3" icon={faUsers} />
                Gerenciar
            </Button>
        </div>
        <div className="row mt-2">
            <a className="text-orange-grad" style={{ fontSize: "12px" }}>
                {(pessoas.length === 0) && `Nenhuma pessoa selecionada`}
                {(pessoas.length === 1) && `${(pessoas.length)} pessoa selecionada`}
                {(pessoas.length > 1) && `${(pessoas.length)} pessoas selecionadas`}
            </a>
        </div>
        <input ref={innerRef} type="hidden" name={campo.name} value={pessoas.map(p => p.pessoaId).join(',')} onChange={campo.onChange} />

        <Modal size="lg" centered={false} show={modalAberto} onHide={() => { setModalAberto(false); onBlur(campo.name) }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">{modelo.label}</h5>
                    <div onClick={() => { setModalAberto(false); onBlur(campo.name) }} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <RecuperaPessoa
                        handlerTipo={setTipoDocumento}
                        handlerNumeroDocumento={setNumeroDocumento}
                        handlerPessoa={(pessoa) => {
                            try {
                                setModalCadastroAberto(true);
                                if (pessoas.find((x: IPessoa) => x.pessoaId == pessoa.pessoaId)) {
                                    setPessoas(
                                        pessoas.map(p => {
                                            return {
                                                ...p
                                            }
                                        })
                                    )
                                    return;
                                } else {
                                    if (modelo.multiplo) {
                                        if (modelo.camposExtras && modelo.camposExtras.length) {
                                            setModalAberto(true)
                                            setPessoaAtual({ ...pessoa });
                                        } else {
                                            setPessoas([
                                                ...pessoas,
                                                pessoa
                                            ]);
                                        }
                                    } else {
                                        if (modelo.camposExtras && modelo.camposExtras.length) {
                                            setModalAberto(true)
                                            setPessoaAtual({ ...pessoa });
                                        } else {
                                            setPessoas([
                                                {
                                                    ...pessoa
                                                }
                                            ]);
                                        }
                                    }
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        }}
                    />
                    {dadosFiltrados && (
                        <GridPadrao
                            tipo="pessoas"
                            colunas={colunas}
                            itens={dadosFiltrados}
                        />
                    )}
                </div>
            </div>
            <Modal.Footer>
                <Button className={'btn btn-orange'} form={'form-add-pessoa-modal'} onClick={() => { setModalAberto(false) }}>OK</Button>
            </Modal.Footer>
        </Modal>

        <Modal size="lg" backdropClassName="blur" centered={false} show={(pessoaAtual !== undefined)} onHide={() => { setPessoaAtual(undefined) }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-orange">Dados Adicionais</h5>
                    <div onClick={() => { setPessoaAtual(undefined) }} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="row">
                        {pessoaAtual && modelo.camposExtras && modelo.camposExtras.map((campoExtra: any, index) => {
                            let campoNome = camelizar(campoExtra.campoNome)
                            if (campoExtra.controller && campoExtra.controller.length > 0 && !campoNome.endsWith('Id')) {
                                campoNome = campoNome + 'Id'
                            }
                            return <div key={index} className="col-md-6 col-lg-4 mt-3">
                                <CadastroCampo
                                    modelo={campoExtra}
                                    campo={{
                                        onChange: (e: any) => {
                                            setPessoaAtual({
                                                ...pessoaAtual,
                                                [campoNome]: e.target.value
                                            })
                                            extraForm.setFieldValue(campoNome, e.target.value)
                                        },
                                        value: (pessoaAtual as any)[campoNome] || "",
                                        name: campoNome,
                                        onBlur: extraForm.getFieldProps(campoNome).onBlur
                                    }}
                                    touched={extraForm.touched[campoNome] as formik.FormikTouched<any>}
                                    error={extraForm.errors[campoNome] as formik.FormikErrors<any>}
                                    tela={tela}
                                    setValue={(value: any) => {

                                        setPessoaAtual({
                                            ...pessoaAtual,
                                            [campoNome]: value
                                        })

                                        extraForm.setFieldValue(campoNome, value)
                                    }}
                                ></CadastroCampo>
                            </div>
                        })}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <button onClick={
                                () => {
                                    if (!extraForm.isValid) {
                                        Swal.fire({
                                            title: "Atenção",
                                            text: Object.keys(extraForm.errors).map(
                                                (chave: string) => {
                                                    return `${extraForm.errors[chave]}`
                                                }).join("\n"),
                                            icon: "warning",
                                            confirmButtonText: "Ok"
                                        })
                                        return
                                    }
                                    try {
                                        if (!pessoaAtual) {
                                            return
                                        }
                                        if (pessoaAtual.pessoaId === 0) {
                                            setPessoaAtual(undefined)
                                            setModalAberto(true)
                                        } else {
                                            editar(pessoaAtual)
                                        }
                                    } catch (e) {
                                        console.log(e)
                                    } finally {
                                        setModalCadastroAberto(false)
                                        setModalAberto(true)
                                    }
                                }
                            }
                                className="btn btn-orange mt-4 me-2">Salvar
                            </button>
                            <button
                                onClick={() => { setPessoaAtual(undefined) }}
                                type="button"
                                className="btn btn-danger mt-4 "
                            >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        <ModalAdicionaPessoa
            className={'societario'}
            title={"Nova Pessoa"}
            showModal={modalCadastroAberto}
            setShowModal={setModalCadastroAberto}
            tipoDocumento={"CPF"}
            nome={pessoaAdicionar?.nome}
            numeroDocumento={pessoaAdicionar?.numeroDocumento || ''}
            handlePessoa={handleAdicionarPessoa}
            disabledInputs={false}

        />

    </>
}

export default CadastroCampoPessoa;