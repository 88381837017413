import { EModulo, ETipoCampoEnum } from "../enum";
import IAdvogadoInterno from "../interfaces/IAdvogadoInterno";
import ICentroCusto from "../interfaces/ICentroCusto";
import IEmpresa from "../interfaces/IEmpresa";
import IParteContraria from "../interfaces/IParteContraria";
import IPartesProcesso from "../interfaces/IPartesProcesso";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";
import { IAdicionaImovelRequest } from "../interfaces/Requests/Imovel/IAdicionaImovelRequest";
import api from "./Api";

class ImovelService {

  public async adicionaImovel(adicionaImovel: IAdicionaImovelRequest): Promise<IImovel> {
    let { data: result } = await api.post<IImovel>(`Imovel/AdicionaImovel`, this.montarObjetoAdicionarImovel(adicionaImovel));
    return result;
  }

  public async editarImovel(editar: IAdicionaImovelRequest) {
    let request = this.montarObjetoArrayImovel(editar);
    if (request.length > 0) {
      let result = await api.patch(`Imovel/AtualizaImovelPorId?id=${editar.imovelId}`, request);
      return result;
    }
  }


  public async alterarImovelPorCentroCustoList(centrosCusto: ICentroCusto[], imovelId: number) {
    if (centrosCusto.length > 0) {
      await api.put<IAdicionaImovelRequest>(`Imovel/AtualizaCentroCusto`, {
        imovelId: imovelId,
        centrosCusto: centrosCusto,
      });
    }
  }

  public async obterImovels(filtros: any): Promise<IRecuperaLista<IImovel>> {
    let query = `offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    query = this.montarTextos(filtros, query);

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    query = this.montarValores(filtros, query);

    let { data: result } = await api.get<IRecuperaLista<IImovel>>(`Imovel/RecuperaListagemImovel?${query}`);

    return result;
  }

  public async gerarExcel(filtros: any): Promise<Blob> {

    let query = `sort=${filtros.sort}&modeloRelatorioId=${filtros.modeloRelatorioId}`;

    query = this.montarTextos(filtros, query);

    query = this.montarListas(filtros, query);

    query = this.montarDatas(filtros, query);

    query = this.montarValores(filtros, query);

    const { data: result } = await api.get(`Imovel/GerarRelatorioByModelo?${query}`, { responseType: "blob" });

    return result;
  }

  async obterCentroCusto(imovelId: number): Promise<IRecuperaLista<ICentroCusto>> {
    let { data: result } = await api.get<IRecuperaLista<ICentroCusto>>(`/Imovel/RecuperaCentroCustoPorId?imovelId=${imovelId}`);
    return result;
  }

  public async alterarImovelPorCampo(campo: string, valor: any, imovelId: number) {
    let request: any = [];

    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaImovelRequest>(`Imovel/AtualizaImovelPorId?id=${imovelId}`, request);
    }
  }

  async adicionarImovelPorXCampo(campo: number, valor: any, xCampoOpcaoId: number, imovelId: number) {
    let request: any = {
      campoId: campo,
      xCampoOpcaoId: xCampoOpcaoId,
      imovelId: imovelId,
      valor: valor
    };
    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);
    return result;
  }

  public async alterarImovelPorXCampo(campo: string, valor: any, xcampoId: number) {
    let request: any = [];
    if (valor) {
      request.push({ op: "replace", path: "/" + campo, value: valor });
      await api.patch<IAdicionaImovelRequest>(`Imovel/AtualizaXCampoValor?id=${xcampoId}`, request);
    }
  }

  public async alterarImovelPorCampoBolleano(campo: string, valor: any, imovelId: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaImovelRequest>(`Imovel/AtualizaImovelPorId?id=${imovelId}`, request);
  }

  public async alterarImovelPorXCampoBolleano(campo: string, valor: any, xcampoid: number) {
    let request: any = [];

    request.push({ op: "replace", path: "/" + campo, value: valor });
    await api.patch<IAdicionaImovelRequest>(`Imovel/AtualizaXCampoValor?id=${xcampoid}`, request);
  }

  async obterFavoritos(): Promise<IRecuperaLista<any>> {
    let { data: result } = await api.get<IRecuperaLista<any>>(`/Favorito/RecuperaFavorito`);

    return result;
  }

  public async alterarImovelPorAdvogadoInternoList(advogadoInternoList: IAdvogadoInterno[], imovelId: number) {

    if (advogadoInternoList.filter((e) => e.checked).length > 0) {
      const advogadoInternoListRequest: any[] = [];
      advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          advogadoInternoListRequest.push(advogado);
        });

      if (advogadoInternoListRequest.length > 0) {
        const request = {
          imovelId: imovelId,
          advogadoInternoList: advogadoInternoListRequest,
        };
        await api.put<IAdicionaImovelRequest>("Imovel/AtualizaPartesImovelPorId", request);
      }
    }
  }

  public async alterarImovelPorEmpresaList(empresasList: IEmpresa[], imovelId: number) {
    if (empresasList.filter((e) => e.checked).length > 0) {
      const empresasRequestList: any[] = [];
      empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          empresasRequestList.push(empresa);
        });

      if (empresasRequestList.length > 0) {
        const request = {
          imovelId: imovelId,
          empresasList: empresasRequestList,
        };
        await api.put<IAdicionaImovelRequest>("Imovel/AtualizaPartesImovelPorId", request);
      }
    }
  }

  public async alterarImovelPorParteImovelList(partesImovelsList: IParteContraria[], imovelId: number) {
    const partesImovelsRequestList: any[] = [];
    partesImovelsList.forEach((parteImovel) => {
      let requestParteImovel: any = {};
      if (parteImovel.bairro) requestParteImovel.bairro = parteImovel.bairro;
      if (parteImovel.celular) requestParteImovel.celular = parteImovel.celular;
      if (parteImovel.cep) requestParteImovel.cep = parteImovel.cep;
      if (parteImovel.cidadeId) requestParteImovel.cidadeId = parteImovel.cidadeId;
      if (parteImovel.codigo) requestParteImovel.codigo = parteImovel.codigo;
      if (parteImovel.complemento) requestParteImovel.complemento = parteImovel.complemento;
      if (parteImovel.documento) requestParteImovel.documento = parteImovel.documento;
      if (parteImovel.email) requestParteImovel.email = parteImovel.email;
      if (parteImovel.endereco) requestParteImovel.endereco = parteImovel.endereco;
      if (parteImovel.estadoId) requestParteImovel.estadoId = parteImovel.estadoId;
      if (parteImovel.papelSignatarioId) requestParteImovel.papelSignatarioId = parteImovel.papelSignatarioId;
      if (parteImovel.papelSignatarioNome) requestParteImovel.papelSignatarioNome = parteImovel.papelSignatarioNome;
      if (parteImovel.logradouro) requestParteImovel.logradouro = parteImovel.logradouro;
      if (parteImovel.nome) requestParteImovel.nome = parteImovel.nome;
      if (parteImovel.nomeSocial) requestParteImovel.nomeSocial = parteImovel.nomeSocial;
      if (parteImovel.numero) requestParteImovel.numero = parteImovel.numero;
      if (parteImovel.observacoesGerais) requestParteImovel.observacoesGerais = parteImovel.observacoesGerais;
      if (parteImovel.pessoaId) requestParteImovel.pessoaId = parteImovel.pessoaId;
      if (parteImovel.principal) requestParteImovel.principal = parteImovel.principal;
      if (parteImovel.telefone) requestParteImovel.telefone = parteImovel.telefone;
      if (parteImovel.tipoDocumento) requestParteImovel.tipoDocumento = parteImovel.tipoDocumento;
      partesImovelsRequestList.push(requestParteImovel);
    });

    const request = {
      imovelId: imovelId,
      partesImovelsList: partesImovelsRequestList,
    }
    await api.put<IAdicionaImovelRequest>("Imovel/AtualizaPartesImovelPorId", request);

  }

  public async adicionaArquivo(formData: FormData) {
    await api.post(`ged/awss3/upload`, formData);
  }

  public async adicionaFavorido(imovelId: number): Promise<any> {
    let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, {
      modulo: EModulo.Imovel,
      imovelId: imovelId,
    });
    return result;
  }

  public async removerFavorido(favoritoId: number) {
    await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
  }

  public async obterAdvogadosInterno(imovelId: number): Promise<IRecuperaLista<IPartesProcesso>> {
    let { data: result } = await api.get<IRecuperaLista<IPartesProcesso>>(`/Imovel/RecuperaAdvogadoInternoPorId?imovelId=${imovelId}`);

    return result;
  }

  public async obterImovelPorId(imovelId: number): Promise<IRecuperaLista<IImovel>> {
    let { data: result } = await api.get<IRecuperaLista<IImovel>>(`/Imovel/RecuperaListagemImovel?&imovelId=${imovelId}`);
    return result;
  }

  public async obterCapaImovel(imovelId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
    let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(
      `/Imovel/RecuperaCapaImovel?imovelId=${imovelId}`
    );

    return result;
  }

  public async adicionarXCampoValor(request: any) {
    let { data: result } = await api.post<any>(`XCampos/AdicionaXCampoValor`, request);

    return result;
  }

  public concluirCompromisso = async (imovel: IAdicionaImovelRequest): Promise<any> => {

    let result: any[] = [];

    // Salvar campos normais
    await this.editarImovel(imovel);

    // Salvar campos Pessoas
    if (imovel.partesContrariasList?.length > 0) result.push(await this.alterarImovelPorParteImovelList(imovel.partesContrariasList, imovel.imovelId));
    if (imovel.advogadoInternoList?.length > 0) result.push(await this.alterarImovelPorAdvogadoInternoList(imovel.advogadoInternoList, imovel.imovelId));
    //   if (imovel.empresasList?.length > 0) result.push(await this.alterarImovelPorEmpresaList(imovel.empresasList, imovel.imovelId));

    // Salvar xcampos
    if (imovel.xCampoValorList?.length > 0) {
      imovel.xCampoValorList.forEach(async xCampo => {
        switch (xCampo.campoTipo) {

          case ETipoCampoEnum.Texto:
          case ETipoCampoEnum.AreaTexto:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, imovelId: imovel.imovelId, texto: xCampo.texto }));
            else result.push(await this.alterarImovelPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Data:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, imovelId: imovel.imovelId, data: xCampo.data }));
            else result.push(await this.alterarImovelPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Valor:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, imovelId: imovel.imovelId, valor: xCampo.valor }));
            else result.push(await this.alterarImovelPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
            break;


          case ETipoCampoEnum.Inteiro:
            if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, imovelId: imovel.imovelId, inteiro: xCampo.inteiro }));
            else result.push(await this.alterarImovelPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
            break;

          case ETipoCampoEnum.Lista:
            if (!xCampo.xCampoValorId) await result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, imovelId: imovel.imovelId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
            else await result.push(await this.alterarImovelPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
            break;

          default:
            break;
        }
      });
    }

    return result;
  };

  private montarObjetoArrayImovel = (imovel: IAdicionaImovelRequest) => {
    let request: any = [];

    // Campos texto
    if (imovel.codigo) request.push({ op: "replace", path: "/codigo", value: imovel.codigo });
    if (imovel.observacoesimovel) request.push({ op: "replace", path: "/observacoesimovel", value: imovel.observacoesimovel });
    if (imovel.nomeimovel) request.push({ op: "replace", path: "/nomeimovel", value: imovel.nomeimovel });
    if (imovel.logradouro) request.push({ op: "replace", path: "/logradouro", value: imovel.logradouro });
    if (imovel.endereco) request.push({ op: "replace", path: "/endereco", value: imovel.endereco });
    if (imovel.numeroendereco) request.push({ op: "replace", path: "/numeroendereco", value: imovel.numeroendereco });
    if (imovel.complemento) request.push({ op: "replace", path: "/complemento", value: imovel.complemento });
    if (imovel.bairro) request.push({ op: "replace", path: "/bairro", value: imovel.bairro });
    if (imovel.cep) request.push({ op: "replace", path: "/cep", value: imovel.cep });
    if (imovel.dadosmatricula) request.push({ op: "replace", path: "/dadosmatricula", value: imovel.dadosmatricula });
    if (imovel.areaconstruida) request.push({ op: "replace", path: "/areaconstruida", value: imovel.areaconstruida });
    if (imovel.metragemterreno) request.push({ op: "replace", path: "/metragemterreno", value: imovel.metragemterreno });
    if (imovel.avaliador) request.push({ op: "replace", path: "/avaliador", value: imovel.avaliador });


    // Campos data
    if (imovel.datainiciovigencia) request.push({ op: "replace", path: "/datainiciovigencia", value: imovel.datainiciovigencia });
    if (imovel.datafimvigencia) request.push({ op: "replace", path: "/datafimvigencia", value: imovel.datafimvigencia });
    if (imovel.dataaquisicao) request.push({ op: "replace", path: "/dataaquisicao", value: imovel.dataaquisicao });
    if (imovel.datareajuste) request.push({ op: "replace", path: "/datareajuste", value: imovel.datareajuste });
    if (imovel.dataconsolidicacao) request.push({ op: "replace", path: "/dataconsolidicacao", value: imovel.dataconsolidicacao });
    if (imovel.dataavaliacao) request.push({ op: "replace", path: "/dataavaliacao", value: imovel.dataavaliacao });
    if (imovel.dataregistromatricula) request.push({ op: "replace", path: "/dataavaliacao", value: imovel.dataavaliacao });

    // Campos Valor
    if (imovel.valoraluguel) request.push({ op: "replace", path: "/valoraluguel", value: imovel.valoraluguel });
    if (imovel.valorcondominio) request.push({ op: "replace", path: "/valorcondominio", value: imovel.valorcondominio });
    if (imovel.valoriptu) request.push({ op: "replace", path: "/valoriptu", value: imovel.valoriptu });
    if (imovel.valorcontabil) request.push({ op: "replace", path: "/valorcontabil", value: imovel.valorcontabil });
    if (imovel.valoravaliacao) request.push({ op: "replace", path: "/valoravaliacao", value: imovel.valoravaliacao });
    if (imovel.valorvendaforcada) request.push({ op: "replace", path: "/valorvendaforcada", value: imovel.valorvendaforcada });

    if (imovel.departamentoId) request.push({ op: "replace", path: "/departamentoId", value: imovel.departamentoId });
    if (imovel.cidadeId) request.push({ op: "replace", path: "/cidadeId", value: imovel.cidadeId });
    if (imovel.indiceId) request.push({ op: "replace", path: "/indiceId", value: imovel.indiceId });
    if (imovel.tipoaquisicaoId) request.push({ op: "replace", path: "/tipoaquisicaoId", value: imovel.tipoaquisicaoId });
    if (imovel.tipolocalidadeId) request.push({ op: "replace", path: "/tipolocalidadeId", value: imovel.tipolocalidadeId });
    if (imovel.tiporelacionamentoId) request.push({ op: "replace", path: "/tiporelacionamentoId", value: imovel.tiporelacionamentoId });
    if (imovel.imovelprincipalId) request.push({ op: "replace", path: "/imovelprincipalId", value: imovel.imovelprincipalId });

    return request;
  };

  private montarObjetoAdicionarImovel = (imovel: IAdicionaImovelRequest) => {
    let request: any = {};

    // XCampos
    if (imovel.xCampoValorList.length > 0) request.xCampoValorList = imovel.xCampoValorList;

    // Campos texto
    if (imovel.codigo) request.codigo = imovel.codigo;
    if (imovel.observacoesimovel) request.observacoesImovel = imovel.observacoesimovel;
    if (imovel.nomeimovel) request.nomeImovel = imovel.nomeimovel;
    if (imovel.logradouro) request.logradouro = imovel.logradouro;
    if (imovel.endereco) request.endereco = imovel.endereco;
    if (imovel.numeroendereco) request.numeroEndereco = imovel.numeroendereco;
    if (imovel.complemento) request.complemento = imovel.complemento;
    if (imovel.bairro) request.bairro = imovel.bairro;
    if (imovel.cep) request.cep = imovel.cep;
    if (imovel.dadosmatricula) request.dadosMatricula = imovel.dadosmatricula;
    if (imovel.areaconstruida) request.areaConstruida = imovel.areaconstruida;
    if (imovel.metragemterreno) request.metragemTerreno = imovel.metragemterreno;
    if (imovel.avaliador) request.avaliador = imovel.avaliador;

    // Campos bool
    request.restrito = imovel.restrito ? true : false;

    // Campos data
    if (imovel.datainiciovigencia) request.dataInicioVigencia = imovel.datainiciovigencia;
    if (imovel.datafimvigencia) request.dataFimVigencia = imovel.datafimvigencia;
    if (imovel.dataaquisicao) request.dataAquisicao = imovel.dataaquisicao;
    if (imovel.datareajuste) request.dataReajuste = imovel.datareajuste;
    if (imovel.dataconsolidicacao) request.dataConsolidicacao = imovel.dataconsolidicacao;
    if (imovel.dataavaliacao) request.dataAvaliacao = imovel.dataavaliacao;
    if (imovel.dataregistromatricula) request.dataRegistroMatricula = imovel.dataregistromatricula;

    // Campos Valor
    if (imovel.valoraluguel) request.valorAluguel = imovel.valoraluguel || 0;
    if (imovel.valorcondominio) request.valorCondominio = imovel.valorcondominio || 0;
    if (imovel.valoriptu) request.valorIPTU = imovel.valoriptu || 0;
    if (imovel.valorcontabil) request.valorContabil = imovel.valorcontabil || 0;
    if (imovel.valoravaliacao) request.valorAvaliacao = imovel.valoravaliacao || 0;
    if (imovel.valorvendaforcada) request.valorVendaForcada = imovel.valorvendaforcada || 0;

    // Campos lista
    if (imovel.departamentoId) request.departamentoId = imovel.departamentoId;
    if (imovel.tipoImovelId) request.tipoImovelId = imovel.tipoImovelId;
    if (imovel.cidadeId) request.cidadeId = imovel.cidadeId;
    if (imovel.indiceId) request.indiceId = imovel.indiceId;
    if (imovel.tipoaquisicaoId) request.tipoAquisicaoId = imovel.tipoaquisicaoId;
    if (imovel.tipolocalidadeId) request.tipoLocalidadeId = imovel.tipolocalidadeId;
    if (imovel.tiporelacionamentoId) request.tipoRelacionamentoId = imovel.tiporelacionamentoId;
    if (imovel.imovelprincipalId) request.imovelPrincipalId = imovel.imovelprincipalId;

    // Campos Pessoa e Empresa
    if (imovel.advogadoInternoList.filter((e) => e.checked).length > 0) {
      request.advogadoInternoList = [];
      imovel.advogadoInternoList
        .filter((e) => e.checked)
        .forEach((item) => {
          let advogado: any = {
            advogadoInternoId: item.advogadoInternoId,
            principal: item.principal,
            codigo: item.codigo,
          };
          request.advogadoInternoList.push(advogado);
        });
    }

    if (imovel.empresasList.filter((e) => e.checked).length > 0) {
      request.empresasList = [];
      imovel.empresasList
        .filter((e) => e.checked)
        .forEach((item) => {
          let empresa: any = {
            empresaId: item.empresaId,
            codigo: item.codigo,
            principal: item.principal,
            papelSignatarioId: item.papelSignatarioId,
          };
          request.empresasList.push(empresa);
        });
    }

    if (imovel.centrosCusto.filter((e) => e.checked).length > 0) {
      request.centrosCusto = [];
      imovel.centrosCusto
        .filter((e) => e.checked)
        .forEach((item) => {
          let centroCusto: any = {
            tipoCentroCustoId: item.tipoCentroCustoId,
            percentual: item.percentual,
          };
          request.centrosCusto.push(centroCusto);
        });
    }

    if (imovel.partesContrariasList.length > 0) {
      request.partesContrariasList = [];
      imovel.partesContrariasList.forEach((parteContraria) => {
        let requestParteContraria: any = {};
        if (parteContraria.tipoParteContrariaId) requestParteContraria.tipoParteContrariaId = parteContraria.tipoParteContrariaId;
        if (parteContraria.bairro) requestParteContraria.bairro = parteContraria.bairro;
        if (parteContraria.celular) requestParteContraria.celular = parteContraria.celular;
        if (parteContraria.cep) requestParteContraria.cep = parteContraria.cep;
        if (parteContraria.cidadeId) requestParteContraria.cidadeId = parteContraria.cidadeId;
        if (parteContraria.codigo) requestParteContraria.codigo = parteContraria.codigo;
        if (parteContraria.complemento) requestParteContraria.complemento = parteContraria.complemento;
        if (parteContraria.documento) requestParteContraria.documento = parteContraria.documento;
        if (parteContraria.email) requestParteContraria.email = parteContraria.email;
        if (parteContraria.endereco) requestParteContraria.endereco = parteContraria.endereco;
        if (parteContraria.estadoId) requestParteContraria.estadoId = parteContraria.estadoId;
        if (parteContraria.formaParticipacaoId) requestParteContraria.formaParticipacaoId = parteContraria.formaParticipacaoId;
        if (parteContraria.formaParticipacaoNome) requestParteContraria.formaParticipacaoNome = parteContraria.formaParticipacaoNome;
        if (parteContraria.logradouro) requestParteContraria.logradouro = parteContraria.logradouro;
        if (parteContraria.nome) requestParteContraria.nome = parteContraria.nome;
        if (parteContraria.nomeSocial) requestParteContraria.nomeSocial = parteContraria.nomeSocial;
        if (parteContraria.numero) requestParteContraria.numero = parteContraria.numero;
        if (parteContraria.observacoesGerais) requestParteContraria.observacoesGerais = parteContraria.observacoesGerais;
        if (parteContraria.pessoaId) requestParteContraria.pessoaId = parteContraria.pessoaId;
        if (parteContraria.principal) requestParteContraria.principal = parteContraria.principal;
        if (parteContraria.telefone) requestParteContraria.telefone = parteContraria.telefone;
        if (parteContraria.tipoDocumento) requestParteContraria.tipoDocumento = parteContraria.tipoDocumento;
        request.partesContrariasList.push(requestParteContraria);
      });
    }

    return request;
  };

  private montarListas = (filtros: any, query: string) => {
    if (filtros.empresaId.length > 0) filtros.empresaId.forEach((id: any) => (query += `&empresa=${id}`));
    if (filtros.departamentoId.length > 0) filtros.departamentoId.forEach((id: any) => (query += `&DepartamentoId=${id}`));
    if (filtros.cidadeId.length > 0) filtros.cidadeId.forEach((id: any) => (query += `&cidadeId=${id}`));
    if (filtros.statusImovelId.length > 0) filtros.statusImovelId.forEach((id: any) => (query += `&statusImovelId=${id}`));
    if (filtros.tipoImovelId.length > 0) filtros.tipoImovelId.forEach((id: any) => (query += `&TipoImovelId=${id}`));
    if (filtros.indiceId.length > 0) filtros.indiceId.forEach((id: any) => (query += `&IndiceId=${id}`));
    if (filtros.tipoAquisicaoId.length > 0) filtros.tipoAquisicaoId.forEach((id: any) => (query += `&TipoAquisicaoId=${id}`));
    if (filtros.tipoRelacionamentoId.length > 0) filtros.tipoRelacionamentoId.forEach((id: any) => (query += `&tipoRelacionamentoId=${id}`));
    if (filtros.tipoLocalidadeId.length > 0) filtros.tipoLocalidadeId.forEach((id: any) => (query += `&TipoLocalidadeId=${id}`));
    if (filtros.imovelPrincipal.length > 0) filtros.imovelPrincipal.forEach((id: any) => (query += `&ImovelPrincipal=${id === 1 ? "true" : "false"}`));
    return query;
  };

  private montarDatas = (filtros: any, query: string) => {
    if (filtros.dataInicioVigenciaMaiorIgual && filtros.dataInicioVigenciaMenorIgual) {
      query += `&dataInicioVigenciaMaiorIgual=${filtros.dataInicioVigenciaMaiorIgual}&dataInicioVigenciaMenorIgual=${filtros.dataInicioVigenciaMenorIgual}`;
    }
    if (filtros.dataFimVigenciaMaiorIgual && filtros.dataFimVigenciaMenorIgual) {
      query += `&dataFimVigenciaMaiorIgual=${filtros.dataFimVigenciaMaiorIgual}&dataFimVigenciaMenorIgual=${filtros.dataFimVigenciaMenorIgual}`;
    }
    if (filtros.dataCadastroMaiorIgual && filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }
    if (filtros.dataAquisicaoMaiorIgual && filtros.dataAquisicaoMenorIgual) {
      query += `&dataAquisicaoMaiorIgual=${filtros.dataAquisicaoMaiorIgual}&dataAquisicaoMenorIgual=${filtros.dataAquisicaoMenorIgual}`;
    }
    if (filtros.dataReajusteMaiorIgual && filtros.dataReajusteMenorIgual) {
      query += `&dataReajusteMaiorIgual=${filtros.dataReajusteMaiorIgual}&dataReajusteMenorIgual=${filtros.dataReajusteMenorIgual}`;
    }
    if (filtros.dataConsolidicacaoMaiorIgual && filtros.dataConsolidicacaoMenorIgual) {
      query += `&dataConsolidicacaoMaiorIgual=${filtros.dataConsolidicacaoMaiorIgual}&dataConsolidicacaoMenorIgual=${filtros.dataConsolidicacaoMenorIgual}`;
    }
    if (filtros.dataAvaliacaoMaiorIgual && filtros.dataAvaliacaoMenorIgual) {
      query += `&dataAvaliacaoMaiorIgual=${filtros.dataAvaliacaoMaiorIgual}&dataAvaliacaoMenorIgual=${filtros.dataAvaliacaoMenorIgual}`;
    }
    if (filtros.dataRegistroMatriculaMaiorIgual && filtros.dataRegistroMatriculaMenorIgual) {
      query += `&dataRegistroMatriculaMaiorIgual=${filtros.dataRegistroMatriculaMaiorIgual}&dataRegistroMatriculaMenorIgual=${filtros.dataRegistroMatriculaMenorIgual}`;
    }

    return query;
  };

  private montarTextos = (filtros: any, query: string) => {
    if (filtros.imovelId) {
      query += `&imovelId=${filtros.imovelId}`;
    }

    if (filtros.avaliador) {
      query += `&avaliador=${filtros.avaliador}`;
    }

    if (filtros.nomeImovel) {
      query += `&nomeImovel=${filtros.nomeImovel}`;
    }

    if (filtros.cep) {
      query += `&cep=${filtros.cep}`;
    }

    if (filtros.dadosMatricula) {
      query += `&dadosMatricula=${filtros.dadosMatricula}`;
    }

    if (filtros.parteContraria && filtros.parteContraria.length > 0) {
      query += `&parteContraria=${filtros.parteContraria}`;
    }

    return query;
  };

  private montarValores = (filtros: any, query: string) => {

    if (filtros.valorAluguelMaiorIgual > 0 && filtros.valorAluguelMenorIgual > 0) {
      if (filtros.valorAluguelMaiorIgual >= filtros.valorAluguelMenorIgual) {
        query += `&valorAluguelMenorIgual=${filtros.valorAluguelMenorIgual / 100}`;
        query += `&valorAluguelMaiorIgual=${filtros.valorAluguelMaiorIgual / 100}`;
      }
    }

    if (filtros.valorCondominioMaiorIgual > 0 && filtros.valorCondominioMenorIgual > 0) {
      if (filtros.valorCondominioMaiorIgual >= filtros.valorCondominioMenorIgual) {
        query += `&valorCondominioMenorIgual=${filtros.valorCondominioMenorIgual / 100}`;
        query += `&valorCondominioMaiorIgual=${filtros.valorCondominioMaiorIgual / 100}`;
      }
    }

    if (filtros.valorIptuMaiorIgual > 0 && filtros.valorIptuMenorIgual > 0) {
      if (filtros.valorIptuMaiorIgual >= filtros.valorIptuMenorIgual) {
        query += `&valorIptuMenorIgual=${filtros.valorIptuMenorIgual / 100}`;
        query += `&valorIptuMaiorIgual=${filtros.valorIptuMaiorIgual / 100}`;
      }
    }

    if (filtros.valorContabilMaiorIgual > 0 && filtros.valorContabilMenorIgual > 0) {
      if (filtros.valorContabilMaiorIgual >= filtros.valorContabilMenorIgual) {
        query += `&valorContabilMenorIgual=${filtros.valorContabilMenorIgual / 100}`;
        query += `&valorContabilMaiorIgual=${filtros.valorContabilMaiorIgual / 100}`;
      }
    }

    if (filtros.valorAvaliacaoMaiorIgual > 0 && filtros.valorAvaliacaoMenorIgual > 0) {
      if (filtros.valorAvaliacaoMaiorIgual >= filtros.valorAvaliacaoMenorIgual) {
        query += `&valorAvaliacaoMenorIgual=${filtros.valorAvaliacaoMenorIgual / 100}`;
        query += `&valorAvaliacaoMaiorIgual=${filtros.valorAvaliacaoMaiorIgual / 100}`;
      }
    }

    if (filtros.valorVendaForcadaMaiorIgual > 0 && filtros.valorVendaForcadaMenorIgual > 0) {
      if (filtros.valorVendaForcadaMaiorIgual >= filtros.valorVendaForcadaMenorIgual) {
        query += `&valorVendaForcadaMenorIgual=${filtros.valorVendaForcadaMenorIgual / 100}`;
        query += `&valorVendaForcadaMaiorIgual=${filtros.valorVendaForcadaMaiorIgual / 100}`;
      }
    }

    return query;
  };

}

export default new ImovelService();
