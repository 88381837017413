import { useContext, useEffect, useState } from "react";
import { faEllipsisH, faInfo, faLayerGroup, faPaperPlane, faSearch, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext } from "react-beautiful-dnd";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import Swal from "sweetalert2";

import { ETelaEnum } from "../../../enum";
import { routeCapaCadastroPesquisa, routePropriedade, tipoPorModulo } from "../../../utils/Utils";

import ConfiguracaoSeparadorService from "../../../services/ConfiguracaoSeparadorService";

import { ICampo } from "../../../interfaces/ICampo";
import IRecuperaLista from "../../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../../interfaces/Contexts/OrangeInterfaceContext";

import LayoutPageTitle from "../../../layout/LayoutPageTitle";
import { OrangeContext } from "../../../contexts/OrangeProvider";
import LayoutPageForButton from "../../../layout/LayoutPageButton";

import "./../Styles/dragdrop.css";
import Column from "../Separador/Column/Column";
import { notifySuccess } from "../../../components/Comum/Toast/ToastFormik";
import ConfiguracaoTelaService from "../../../services/ConfiguracaoTelaService";
import { ETipoTelaEnum } from "../../../enum/ETipoTelaEnum";

const ConfiguracaoSeparadorPage = () => {
  const [carregandoConfiguracaoTela, setCarregandoConfiguracaoTela] = useState<boolean>(false);
  const [processamentoAoSalvar, setProcessamentoAoSalvar] = useState<boolean>(false);
  const [categoriaId, setCategoriaId] = useState<number | null>();

  const { setCampos, campos, eCampos, setECampos, tipoSeparador, setTipoPropriedade, setTela } = useContext<OrangeInterfaceContext>(OrangeContext);
  const navigate = useNavigate();
  const [columns, setColumns] = useState<any>({
    todo: {
      id: "todo",
      list: [],
    },
    doing: {
      id: "doing",
      list: [],
    },
  });

  const setarOrdenacao = () => columns.doing.list.forEach((campo: ICampo, index: number) => (campo.ordenacao = index));

  const telaSeparador = routeCapaCadastroPesquisa(tipoSeparador.telas.capa, tipoSeparador.modulo) == ETipoTelaEnum.Filtros ? ETelaEnum.TelaSeparadorPesquisa : ETelaEnum.TelaSeparadorCapa;

  useEffect(() => {

    carregarConfiguracaoDoing();
    carregarConfiguracaoTodo();

    if (tipoSeparador.areaDireitoId) setCategoriaId(tipoSeparador.areaDireitoId);
    if (tipoSeparador.tipoMarcaId) setCategoriaId(tipoSeparador.tipoMarcaId);
    if (tipoSeparador.tipoConsultaId) setCategoriaId(tipoSeparador.tipoConsultaId);
    if (tipoSeparador.tipoAtoId) setCategoriaId(tipoSeparador.tipoAtoId);
    if (tipoSeparador.tipoContratoId) setCategoriaId(tipoSeparador.tipoContratoId);
    if (tipoSeparador.tipoImovelId) setCategoriaId(tipoSeparador.tipoImovelId);
    if (tipoSeparador.tipoLicencaId) setCategoriaId(tipoSeparador.tipoLicencaId);
    if (tipoSeparador.tipoSociedadeId) setCategoriaId(tipoSeparador.tipoSociedadeId);
    if (tipoSeparador.tipoProcuracaoId) setCategoriaId(tipoSeparador.tipoProcuracaoId);

  }, []);


  useEffect(() => {
    setarOrdenacao();
    if (columns.doing.list.length > 0) {
      setCampos(columns.doing.list);
    }
  }, [columns.doing]);


  useEffect(() => {
    setColumns({
      todo: {
        id: "todo",
        list: tratarECampos(eCampos, campos),
      },
      doing: {
        id: "doing",
        list: tratarCampos(campos),
      },
    });
  }, [eCampos.length, campos?.length]);

  const onDragEnd = ({ source, destination }: any) => {
    try {
      if (destination === undefined || destination === null) return null;

      if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

      const start = columns[source.droppableId];
      const end = columns[destination.droppableId];

      if (start === end) {
        const newList = start.list.filter((_: any, idx: number) => idx !== source.index);

        newList.splice(destination.index, 0, start.list[source.index]);
        const newCol = {
          id: start.id,
          list: newList,
        };

        setColumns((state: any) => ({ ...state, [newCol.id]: newCol }));
      } else {
        const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);

        const newStartCol = {
          id: start.id,
          list: newStartList,
        };
        const newEndList = end.list;

        newEndList.splice(destination.index, 0, eCampos.filter((e) => e.separadorId === start.list[source.index].separadorId)[0]);

        const newEndCol = {
          id: end.id,
          list: newEndList,
        };

        setColumns((state: any) => ({
          ...state,
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol,
        }));
        setarOrdenacao();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tratarECampos = (todo: any, doing: any) => {
    let lista: ICampo[] = [];
    todo.forEach((campo: ICampo, index: number) => {
      if (doing.filter((e: any) => e.separadorId === campo.separadorId).length == 0) {
        lista.push({
          ...campo,
          ordenacao: (index + 1),
          nome: campo.nome,
          label: campo.nome,
        });
      }
    });
    return lista;
  }


  const tratarCampos = (doing: any) => {
    let lista: ICampo[] = [];
    doing.forEach((campo: ICampo) => {
      lista.push({
        ...campo,
        camposId: 1556,
        campo: 1556,
        label: (campo.nome || campo.label),
        separadorId: campo.separadorId
      });
    });
    return lista;
  }


  const carregarConfiguracaoTodo = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let todo: IRecuperaLista<any>;

      todo = await ConfiguracaoSeparadorService.obterTodo();

      setECampos(todo.data);
      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setECampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const carregarConfiguracaoDoing = async () => {
    try {

      setCarregandoConfiguracaoTela(true);

      let doing: IRecuperaLista<any>;

      doing = await ConfiguracaoSeparadorService.obterDoing(tipoSeparador, telaSeparador == ETelaEnum.TelaSeparadorPesquisa ? ETelaEnum.TelaSeparadorPesquisa : ETelaEnum.TelaSeparadorCapa);

      setCampos(doing.data);
      setarOrdenacao();
      setCarregandoConfiguracaoTela(false);

    } catch (error: any) {
      setCampos([]);
      setCarregandoConfiguracaoTela(false);
    }
  };

  const alterarItem = (item: ICampo) => {
    columns.doing.list = columns.doing.list.filter((e: ICampo) => e.separadorId !== item.separadorId);
    columns.doing.list.splice(item.ordenacao, 0, item);
    setColumns({
      todo: {
        id: "todo",
        list: columns.todo.list,
      },
      doing: {
        id: "doing",
        list: columns.doing.list,
      },
    });
  }

  const adicionarConfiguracao = async () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-orange",
          cancelButton: "btn btn-danger ms-5",
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: "Salvar Configuração de Separadores",
        text: `Você realmente deseja salvar?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
      });

      if (result.isConfirmed) {
        setProcessamentoAoSalvar(true);

        let objeto: any = {};
        eval(`objeto.${tipoPorModulo(tipoSeparador.modulo)}Id=${categoriaId}`);
        await ConfiguracaoTelaService.adicionaConfiguracaoTela({
          ...objeto,
          modulo: tipoSeparador.modulo,
          camposList: columns.doing.list,
          tela: telaSeparador == ETelaEnum.TelaSeparadorPesquisa ? ETelaEnum.TelaSeparadorPesquisa : ETelaEnum.TelaSeparadorCapa,
        });

        notifySuccess('Separadores cadastrados com sucesso');

      }
      setProcessamentoAoSalvar(false);
    } catch (error: any) {
      setProcessamentoAoSalvar(false);
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Não foi possivel cadastrar os campos.`,
        text: error?.response?.data?.Message,
        showConfirmButton: true,
      });
    }
  };

  const popoverCapaCadastro = () => (
    <Popover id="popover-basic" style={{ marginLeft: "-20px" }}>
      <Popover.Body style={{ cursor: "pointer" }}>
        {!carregandoConfiguracaoTela ? (
          <>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Cadastro</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(tipoSeparador.telas.cadastro);
                    navigate(`/${routePropriedade(tipoSeparador.modulo)}/ConfiguracaoTela/Cadastro`);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-input"></span>
                </Button>
              )}
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Capa</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  onClick={() => {
                    setTela(tipoSeparador.telas.capa);
                    navigate(`/${routePropriedade(tipoSeparador.modulo)}/ConfiguracaoTela/Capa`);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
                </Button>
              )}
            </OverlayTrigger>

            <p>
              <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Propriedades</Tooltip>}>
                {({ ref, ...triggerHandler }) => (
                  <Button
                    variant="light"
                    onClick={() => {
                      let objeto: any = {};
                      eval(`objeto.${tipoPorModulo(tipoSeparador.modulo)}Id=${categoriaId}`);
                      setTipoPropriedade(
                        {
                          ...objeto,
                          telas: { cadastro: tipoSeparador.telas.cadastro, capa: tipoSeparador.telas.capa },
                          nome: tipoSeparador.nome,
                          modulo: tipoSeparador.modulo,
                          propriedadeId: []
                        }
                      );
                      navigate("/Configuracao/Propriedade");
                    }}
                    style={{
                      border: "none",
                      background: "none",
                    }}
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  >
                    <span ref={ref}><FontAwesomeIcon className="mx-2 text-orange" icon={faEllipsisH} /></span>
                  </Button>
                )}
              </OverlayTrigger>


              <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Separadores</Tooltip>}>
                {({ ref, ...triggerHandler }) => (
                  <Button
                    variant="light"
                    style={{
                      border: "none",
                      background: "none",
                    }}
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  >
                    <span ref={ref}><FontAwesomeIcon className="mx-2 text-orange" icon={faLayerGroup} /></span>
                  </Button>
                )}
              </OverlayTrigger>

            </p>
          </>
        ) : (
          <span className="indicator-progress" style={{ display: "block" }}>
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </Popover.Body>
    </Popover>
  );
  


  const popoverPesquisa = () => (
    <Popover id="popover-basic" style={{ marginLeft: "-20px" }}>
      <Popover.Body style={{ cursor: "pointer" }}>
        {!carregandoConfiguracaoTela ? (
          <>

            <p>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{routeCapaCadastroPesquisa(tipoSeparador.telas.cadastro, tipoSeparador.modulo)}</Tooltip>}>
                {({ ref, ...triggerHandler }) => (
                  <Button
                    variant="light"
                    onClick={() => {
                      setTela(tipoSeparador.telas.cadastro);
                      navigate(`/${routePropriedade(tipoSeparador.modulo)}/ConfiguracaoTela/${routeCapaCadastroPesquisa(tipoSeparador.telas.cadastro, tipoSeparador.modulo, true)}`);
                    }}
                    style={{
                      border: "none",
                      background: "none",

                    }}
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  >
                    <span ref={ref}><FontAwesomeIcon className="mx-2 text-orange" icon={faSearch} /></span>
                  </Button>
                )}
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{routeCapaCadastroPesquisa(tipoSeparador.telas.capa, tipoSeparador.modulo)}</Tooltip>}>
                {({ ref, ...triggerHandler }) => (
                  <Button
                    variant="light"
                    onClick={() => {
                      setTela(tipoSeparador.telas.capa);
                      navigate(`/${routePropriedade(tipoSeparador.modulo)}/ConfiguracaoTela/${routeCapaCadastroPesquisa(tipoSeparador.telas.capa, tipoSeparador.modulo, true)}`);
                    }}
                    style={{
                      border: "none",
                      background: "none",

                    }}
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  >
                    <span ref={ref}><FontAwesomeIcon className="mx-2 text-orange" icon={faSearchPlus} /></span>
                  </Button>
                )}
              </OverlayTrigger>
            </p>

            <p>
              <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Separadores</Tooltip>}>
                {({ ref, ...triggerHandler }) => (
                  <Button
                    variant="light"
                    style={{
                      border: "none",
                      background: "none",
                    }}
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  >
                    <span ref={ref}><FontAwesomeIcon className="mx-2 text-orange" icon={faLayerGroup} /></span>
                  </Button>
                )}
              </OverlayTrigger>

            </p>
          </>
        ) : (
          <span className="indicator-progress" style={{ display: "block" }}>
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <LayoutPageTitle title={`Separadores - ${tipoSeparador.nome}`}>
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={telaSeparador == ETelaEnum.TelaSeparadorPesquisa ? popoverPesquisa() : popoverCapaCadastro()}>
          <a style={{ cursor: "pointer" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>
      </LayoutPageTitle>

      <ToastContainer />

      <LayoutPageForButton title={""}>
        <div className="col-md-11" />
        <div className="col-md-12">
          <button disabled={processamentoAoSalvar} onClick={() => adicionarConfiguracao()} type="button" className="btn btn-orange me-5">
            {!processamentoAoSalvar && (
              <>
                SALVAR
                <FontAwesomeIcon color={"white"} className="mx-2" icon={faPaperPlane} />
              </>
            )}
            {processamentoAoSalvar && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Salvando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </LayoutPageForButton>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row mt-2">
          {Object.values(columns).map((col: any) => (
            <div className="col-md-6">
              {col.id === "doing" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique no
                      <BsEye
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        size="20px"
                        className="mx-2"
                      />

                      para visualizar o tutorial.
                    </h6>
                  </div>
                </p>
              )}
              {col.id === "todo" && (
                <p>
                  <div className="modal-header">
                    <h6 className="modal-title text-orange">
                      {carregandoConfiguracaoTela ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-1" icon={faInfo} />}
                      {" "} Clique segure e arraste para selecionar uma separador.
                    </h6>
                  </div>
                </p>
              )}
              <div style={{ overflow: "auto", height: "650px" }}>
                <Column col={col} key={col.id} alterarItem={alterarItem} />
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

export default ConfiguracaoSeparadorPage;
