/* eslint @typescript-eslint/no-var-requires: "off" */
import React, { useEffect, useState } from 'react';
import * as pdfjsLib from "pdfjs-dist";
import PaginaPDF from './PaginaPDF';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { BsArrowLeft, BsDownload, BsExclamationTriangle, BsFullscreenExit } from 'react-icons/bs';
import Swal from 'sweetalert2';
import './Docx.scss';
import { TypedArray } from 'pdfjs-dist/types/src/display/api';

// Utilizar worker próprio posteriormente
pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';

export interface VisualizarDocumentosProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    url: string,
    children?: React.ReactNode,
    onPageRender?: (pagina: pdfjsLib.PDFPageProxy, canvas: HTMLImageElement) => void,
    onDocumentLoad?: (totalPaginas: number) => void,
    onDocumentError?: (error: any) => void,
    onDocumentLoadProgress?: (progresso: number, total:number) => void
}

interface ICachePagina{
    pagina: pdfjsLib.PDFPageProxy;
    numero: number;
    proporcao: number;
    imagem: string;
}

export default function VisualizarDocumentos(props: VisualizarDocumentosProps) {
    const [pdfRef, setPdfRef] = useState<pdfjsLib.PDFDocumentProxy>();
    const [docxRef, setDocxRef] = useState<React.ReactElement>();
    const [error, setError] = useState<any>();
    const [telaCheia, setTelaCheia] = useState<string|undefined>(undefined);

    const paginasCache: ICachePagina[] = [];

    const paginaProps: VisualizarDocumentosProps = {
        ...props,
    };

    delete(paginaProps.children)
    delete(paginaProps.onDocumentLoad)
    delete(paginaProps.onDocumentError)
    delete(paginaProps.onDocumentLoadProgress)
    
    async function carregarDocumento() {
        if (pdfRef) {
            try{
                pdfRef.destroy();
            }catch(error){
                console.log(error);
            }
        }
        try{
            pdfjsLib.getDocument(props.url);
            const tarefa = pdfjsLib.getDocument(props.url);
            tarefa.onProgress = (progresso: number, total: number) => {
                if(props.onDocumentLoadProgress) props.onDocumentLoadProgress(progresso, total);
            }
            const pdf = await tarefa.promise;
            setPdfRef(pdf);
            props.onDocumentLoad && props.onDocumentLoad(pdf.numPages);
            setError(undefined);
        }catch(error){
            await carregarDocx(error);
            
        }
    }

    async function carregarDocx(errorOriginal: any): Promise<void> {
        const request = new window.XMLHttpRequest();
        let erroAoBaixar = false;
        try{

            request.open('GET', props.url, true);
            request.responseType = 'arraybuffer';
            const result = await new Promise<ArrayBuffer>((resolve, reject) => {
                request.onload = () => resolve(request.response);
                request.onerror = (e) => { setError(e); erroAoBaixar = true;  return reject(new Error('Erro ao carregar documento'))};
                request.send();
            });
            

            const mammoth = require('mammoth');
            const documento  = await mammoth.convertToHtml(
                { arrayBuffer: result },
                { 
                    includeDefaultStyleMap: true,
                    includeEmbeddedStyleMap: true,
                    ignoreEmptyParagraphs: false,
                    convertImage: mammoth.images.imgElement(function(image: any) {
                        return image.read("base64").then(function(imageBuffer:any) {
                            return {
                                src: "data:" + image.contentType + ";base64," + imageBuffer
                            };
                        });
                    })
                },
            )

            setDocxRef(
                <div className='documento-conteiner'
                    dangerouslySetInnerHTML={{ __html: documento.value }}
                >
                </div>
            );
        }catch(error){
            if (erroAoBaixar) return (props.onDocumentError || (()=>null))(errorOriginal);

            setDocxRef(
                <div 
                    className='pdf-pagina-conteiner p-5'
                >
                    <h2 className='text-center text-secondary'><BsExclamationTriangle/></h2>
                    <h6 className='text-center text-secondary'>Previsualização não disponível</h6>
                    <p className='text-center'>
                        Esse tipo de documento não permite a previsualização.<br/>Por favor, baixe o arquivo para visualizar o conteúdo.
                    </p>
                </div>
            );
        }
    }
    
    async function renderizarPagina(paginaNumero: number, imagem: HTMLImageElement) {
        const cache = paginasCache.find(cache=> cache.numero === paginaNumero);

        async function renderizar(cache: ICachePagina){
            imagem.src = cache.imagem;
            await new Promise<void>((resolve, reject) => {
                imagem.onload = () => resolve();
                imagem.onerror = () => reject();
            })
            imagem.parentElement && imagem.parentElement.classList.remove('carregando');
            props.onPageRender && props.onPageRender(cache.pagina, imagem);
        }

        if(cache){
            await renderizar(cache);
        }

        const pagina = await (pdfRef as pdfjsLib.PDFDocumentProxy).getPage(paginaNumero);
        const viewport = pagina.getViewport({scale: 1});
        const proporcao = viewport.width / viewport.height;
        const width = Math.min(Math.max(window.screen.width, window.screen.height) * 2, 2480);
        const scale = width/viewport.width;
        const canvasTemporaria = document.createElement('canvas');

        canvasTemporaria.width = width;
        canvasTemporaria.height = width / proporcao;
        const canvasTemporariaContexto = canvasTemporaria.getContext('2d') as CanvasRenderingContext2D;

        const contexto = {
            canvasContext: canvasTemporariaContexto,
            viewport: pagina.getViewport({scale: scale})
        };

        await pagina.render(contexto).promise;

        const novoCache = {
            pagina: pagina,
            numero: paginaNumero,
            imagem: canvasTemporaria.toDataURL('image/png', 1),
            proporcao: proporcao
        }

        canvasTemporaria.remove();

        await renderizar(novoCache);
    }
    
    function onFullscreenChange(){
        if(!document.fullscreenElement){
            setTelaCheia(undefined);
        }
    }

    function recuperaTipoArquivo(dados: ArrayBuffer): string[]{
        let contentType = 'application/pdf';
        let extencao = '.pdf';

        const arquivoUintArray = new Uint8Array(dados.slice(0, 8));
        const arquivoBytes:number[] = [];

        for(let i = 0; i < arquivoUintArray.length; i++){
            arquivoBytes.push(arquivoUintArray[i]);
        }

        const dadosString = String.fromCharCode.apply(null, arquivoBytes);

        if(dadosString.startsWith('PK\x03\x04')){
            contentType = 'application/application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            extencao = '.docx';
        }else if(dadosString.startsWith('\xec\xa5\xc1\x00')){
            contentType = 'application/msword';
            extencao = '.doc';
        }
        else if(dadosString.startsWith('\xd0\xcf\x11\xe0\xa1\xb1\x1a\xe1')){
            contentType = 'application/msword';
            extencao = '.doc';
        }

        return [`documento.${extencao}`, contentType]

    }

    async function baixarArquivo(documento:pdfjsLib.PDFDocumentProxy | undefined): Promise<void> {
        try{
            Swal.fire({
                heightAuto: false,
                title: 'Baixando Arquivo',
                text: '',
                icon: 'info',
            });
            Swal.showLoading()
            let dados: ArrayBuffer| TypedArray;
            let documentoNome = 'documento.pdf';
            let contentType =  'application/pdf'

            if(documento){
                dados = await documento.getData();
            } else{
                const request = await fetch(props.url);
                dados = await request.arrayBuffer();
                [documentoNome, contentType] = recuperaTipoArquivo(dados);
            }

            let blob = new Blob([await dados], {type: contentType});
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = documentoNome;
            document.body.appendChild(a);
            a.click();
            a.remove()
            setTimeout(() => {
                a.remove();
                window.URL.revokeObjectURL(url);
                Swal.hideLoading();
                Swal.update({icon: 'success', title: 'Download Concluído'});
            }, 1000)
        } catch(error){
            Swal.fire({
                heightAuto: false,
                title: 'Não foi possível baixar o arquivo',
                text: 'Ocorreu um erro ao baixar o arquivo. Tente novamente mais tarde.',
                timer: 4000,
                icon: 'error',
                showConfirmButton: false,
            });
        }
    }


    useEffect(() => {
        setError(undefined);
        carregarDocumento();
    }, []);

    useEffect(() => {
        window.addEventListener('fullscreenchange', onFullscreenChange);
        return ()=>{
            window.removeEventListener('fullscreenchange', onFullscreenChange);
        }
    },[])

    if(error){
        return <div className='pdf-pagina-conteiner error'>
        <div className="capa">
                <i>
                    <IoCloseCircleOutline/>
                </i>
                <h6>Falha ao carregar o documento.</h6>
                <h6><u><a onClick={()=>{
                    setError(undefined);
                    carregarDocumento();
                }}>Tentar Novamente</a></u></h6>
            </div>
        </div>
    }
    if (!pdfRef && !docxRef) {
        return <>
            <div className='pdf-documento-container'>
                {React.createElement('div', paginaProps, props.children)}
            </div>
        </>
    }

    if (docxRef) {
        return <div className='pdf-documento-container'>
            <div className='documento-controles'>
                <a
                    className='pdf-documento-download'
                    title="Fazer download"
                    onClick={(e)=>{
                        baixarArquivo(pdfRef);
                    }}
                >
                    Baixar <BsDownload/>
                </a>
                <div className='clear-fix'/>
            </div>
            {docxRef}
        </div>
    }
    
    return <>
            <div 
                className='pdf-documento-container'
                id={pdfRef?._pdfInfo.fingerprints[0]}
            >
                <div className='documento-controles'>
                    <a
                        className='pdf-documento-tela-cheia'
                        title="Visualizar em tela cheia"
                        onClick={(e)=>{
                            setTelaCheia(pdfRef?._pdfInfo.fingerprints[0])
                            document.getElementById(pdfRef?._pdfInfo.fingerprints[0])?.requestFullscreen();
                        }}
                    >
                        <BsFullscreenExit/> Visualizar em tela cheia
                    </a>
                    <a
                        className='pdf-documento-download'
                        title="Fazer download"
                        onClick={(e)=>{
                            pdfRef && baixarArquivo(pdfRef);
                        }}
                    >
                        Baixar <BsDownload/>
                    </a>
                    <div className='clear-fix'/>
                </div>
                <div className='sair-fullscreen' onClick={()=>{
                    setTelaCheia(undefined);
                    document.exitFullscreen();
                }}>
                <BsArrowLeft/>
            </div>
            {Array.from(Array(pdfRef?.numPages).keys()).map((paginaNumero) => {
                    return <PaginaPDF
                    props={paginaProps}
                    key={paginaNumero}
                    paginaNumero={paginaNumero+1}
                    paginaTotal={pdfRef?.numPages || 0}
                    telaCheia={telaCheia === pdfRef?._pdfInfo.fingerprints[0]}
                    onCreate={renderizarPagina}
                />
                })}
            </div>
    </>
}