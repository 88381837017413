import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusProcuracao from "../interfaces/IStatusProcuracao";
import { AdicionaStatusProcuracaoRequest } from "../interfaces/Requests/StatusProcuracao/AdicionaStatusProcuracaoRequest";
import FiltroPesquisaStatusProcuracaoRequest from "../interfaces/Requests/StatusProcuracao/FiltroPesquisaStatusProcuracaoRequest";
import { EditarStatusProcuracaoRequest } from "../interfaces/Requests/StatusProcuracao/EditarStatusProcuracaoRequest";

class StatusProcuracaoService {

    async obterStatusProcuracoes(filtros: FiltroPesquisaStatusProcuracaoRequest): Promise<IRecuperaLista<IStatusProcuracao>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusProcuracao>>(`StatusProcuracao/RecuperaStatusProcuracao?${query}`);

        return result;
    }

    async adicionaStatusProcuracao(adicionaStatusProcuracao: AdicionaStatusProcuracaoRequest): Promise<IStatusProcuracao> {
        let { data: result } = await api.post<IStatusProcuracao>(`StatusProcuracao/AdicionaStatusProcuracao`, adicionaStatusProcuracao);

        return result;
    }


    async alterarStatus(statusProcuracaoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusProcuracao>(`StatusProcuracao/AtualizaStatusProcuracaoPorId?id=${statusProcuracaoId}`, data);
    }

    async atualizarStatusProcuracao(editarStatusProcuracao: EditarStatusProcuracaoRequest): Promise<void> {

        let dataStatusProcuracao = [
            { "op": "replace", "path": "/nome", "value": editarStatusProcuracao.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusProcuracao.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusProcuracao/AtualizaStatusProcuracaoPorId?id=${editarStatusProcuracao.statusProcuracaoId}`, dataStatusProcuracao)
        ]);
    }
}


export default new StatusProcuracaoService();