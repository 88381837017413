import { createContext, useState, ReactNode } from "react";
import Estatisticas from "./Estatisticas";

const EstatisticasContexto = createContext<Estatisticas<any> | null>(null);

const EstatisticasContextoProvider = ( props: {children: ReactNode[]|ReactNode, estatisticas:Estatisticas<any>}) => {
  return (
    <EstatisticasContexto.Provider value={props.estatisticas}>
        {props.children}
    </EstatisticasContexto.Provider>
  );
};

export { EstatisticasContexto, EstatisticasContextoProvider };