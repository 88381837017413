
import api from "./Api";
import IXCampo from "../interfaces/IXCampo";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IXOpcao from "../interfaces/IXOpcao";
import FiltroPesquisaXCampoRequest from "../interfaces/Requests/XCampo/FiltroPesquisaXCampoRequest";

class XCampoService {

  async obterXCampo(filtros: FiltroPesquisaXCampoRequest): Promise<IRecuperaLista<IXCampo>> {
    let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}&isXCampo=true`;

    if (filtros.label && filtros.label && filtros.label.length) {
      query += `&label=${filtros.label}`;
    }

    if (filtros.tipo && filtros.tipo && filtros.tipo.length && filtros.tipo != "0") {
      query += `&tipo=${filtros.tipo}`;
    }

    if (filtros.modulo.length > 0) {
      for (let index = 0; index < filtros.modulo.length; index++) {
        const modulo = filtros.modulo[index];

        query += `&modulo=${modulo}`;
      }
    }

    if (filtros.status && filtros.status !== 0) {
      query += `&status=${filtros.status}`;
    }


    if (filtros.dataCadastroMaiorIgual) {
      query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
    }

    if (filtros.dataCadastroMenorIgual) {
      query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
    }


    let { data: result } = await api.get<IRecuperaLista<IXCampo>>(`/Campos/RecuperaCampos?${query}`);

    return result;
  }

  async adicionaXCampo(adicionaXCampo: IXCampo): Promise<IXCampo> {
    let { data: result } = await api.post<IXCampo>(`XCampos/AdicionaXCampo`, adicionaXCampo);

    return result;
  }

  async adicionaOpcoesXCampoPorLista(opcoes: IXOpcao[], campoId: number): Promise<void> {
    opcoes.forEach(async opcao => {
      opcao.campoId = campoId;
      await api.post<IXCampo>(`XCampos/AdicionaXCampoOpcao`, opcao);
    });

  }

  async atualizarOpcoesXCampoPorLista(opcoes: IXOpcao[]): Promise<void> {
    opcoes.forEach(async opcao => {
      let data = [{ op: "replace", path: "/nome", value: opcao.nome }];
      await Promise.all([await api.patch<void>(`XCampos/AtualizaXCampoOpcaoPorId?id=${opcao.xCampoOpcaoId}`, data)]);
    });

  }

  async adicionaOpcoesXCampo(opcao: IXOpcao): Promise<void> {
    await api.post<IXCampo>(`XCampos/AdicionaXCampoOpcao`, opcao);
  }

  async atualizaXOpcaoCampo(opcao: IXOpcao): Promise<void> {
    let data = [{ op: "replace", path: "/nome", value: opcao.nome }];
    await Promise.all([await api.patch<void>(`XCampos/AtualizaXCampoOpcaoPorId?id=${opcao.xCampoOpcaoId}`, data)]);
  }

  async inativaXCampo(camposId: number) {
    let { data: result } = await api.put(`Campos/InativaCampoPorId?id=${camposId}`);

    return result;
  }

  async atualizaXCampo(editarXCampo: IXCampo): Promise<void> {
    let dataXCampo = [
      { op: "replace", path: "/isXCampo", value: true },
      { op: "replace", path: "/label", value: editarXCampo.label },
      { op: "replace", path: "/tipoId", value: editarXCampo.tipo },
      { op: "replace", path: "/modulo", value: editarXCampo.modulo },
      { op: "replace", path: "/codigo", value: editarXCampo.codigo },
      { op: "replace", path: "/tipo", value: editarXCampo.tipoDescricao },
      { op: "replace", path: "/exibirTelaPesquisa", value: editarXCampo.exibirTelaPesquisa },
    ];

    await Promise.all([await api.patch<void>(`XCampos/AtualizaXCampoPorId?id=${editarXCampo.camposId}`, dataXCampo)]);
  }

  async obterCampo(filtros: any): Promise<IRecuperaLista<any>> {

    let query = `${filtros.status === 0 ? "&status=-1&status=1" : "&status=" + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`;

    if (filtros.campoId) query += `&campoId=${filtros.campoId}`;

    let { data: result } = await api.get<IRecuperaLista<any>>(`XCampos/RecuperaXCampoOpcao?${query}`);

    return result;
  }

  async gerarExcel(relatorioId: number): Promise<Blob> {
    const { data: result } = await api.get(`XCampo/GerarRelatorio?relatorioId=${relatorioId}`, { responseType: 'blob' });
    return result;
  }

}

export default new XCampoService();