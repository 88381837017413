import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { TableColumn } from "react-data-table-component";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import clsx from "clsx";

import { ILevantamento } from "../../interfaces/ILevantamento";

import GridPadrao from "../../components/Comum/GridPadrao";
import GarantiaService from "../../services/GarantiaService";
import { OrangeContext } from "../../contexts/OrangeProvider";
import PaginationRequest from "../../interfaces/Requests/PaginationRequest";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import CurrencyField, { CurrencyFormatterLabel } from "../../components/Comum/CurrencyBRL/CurrencyField";

interface ILevantamentoProps {
  toggleModal: () => void;
  exibirModal: boolean;
  setAtualizarTabela(atualizaAgora: boolean): void
}

const LevantamentoModal = ({ toggleModal, exibirModal, setAtualizarTabela }: ILevantamentoProps) => {
  const { garantia } = useContext<OrangeInterfaceContext>(OrangeContext);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState<PaginationRequest>({
    limit: 10,
    totalItems: garantia?.levantamentos?.length,
    offset: 0,
    sort: "levantamentoId",
  });
  const initialValues: ILevantamento =
  {
    levantamentoId: 0,
    garantiaId: garantia.garantiaId,
    tipoLevantamento: 0,
    levantadoPor: 0,
    numeroAlvara: "",
    dataLevantamento: new Date(),
    valorLevantadoPrincipal: 0,
    valorLevantadoCorrecao: 0,
    valorLevantadoJuros: 0,
    valorLevantadoAtualizado: 0,
    codigo: ""
  }

  useEffect(() => {

    if (exibirModal) {

      formik.resetForm();

      formik.setValues(initialValues);

      formik.validateForm();
    }

  }, [exibirModal]);

  const schema = Yup.object().shape({
    tipoLevantamento: Yup.number().min(1, "Campo é obrigatório").required("Campo é obrigatório"),
    levantadoPor: Yup.number().min(1, "Campo é obrigatório").required("Campo é obrigatório"),

    valorLevantadoPrincipal: Yup.string().max(50, "Maximo 50 caracteres"),
    valorLevantadoCorrecao: Yup.string().max(50, "Maximo 50 caracteres"),
    valorLevantadoJuros: Yup.string().max(50, "Maximo 50 caracteres"),
    valorLevantadoAtualizado: Yup.string().max(50, "Maximo 50 caracteres"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });

        let result = await swalWithBootstrapButtons.fire({
          title: "Registrar Levantamento ",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });

        if (result.isConfirmed) {

          await GarantiaService.adicionaLevantamento(values);

          await Swal.fire({
            heightAuto: false,
            icon: 'success',
            text: `Levantamento cadastrado com sucesso`,
            showConfirmButton: false,
            timer: 2000
          });

          await setAtualizarTabela(true);

          setTimeout(async () => { await toggleModal() }, 3000);

        }
      } catch (error: any) {
        await Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Ocorreu um erro ao tentar cadastrar`,
          text: error?.response?.data?.Message && typeof error.response.data.Message ? error.response.data.Message : "",
          showConfirmButton: true,
        });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    let valorTotal = (formik.values.valorLevantadoPrincipal + formik.values.valorLevantadoCorrecao + formik.values.valorLevantadoJuros);

    formik.setFieldValue("valorLevantadoAtualizado", valorTotal)

  }, [formik.values.valorLevantadoPrincipal, formik.values.valorLevantadoCorrecao, formik.values.valorLevantadoJuros]);

  async function cancelar() {
    formik.resetForm();
    formik.setValues(initialValues);
  }

  const colunas: TableColumn<ILevantamento>[] = [
    {
      name: 'Id',
      sortField: 'levantamentoId',
      selector: (row: ILevantamento) => row.levantamentoId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: 'Por:',
      sortField: 'levantadoPor',
      selector: (row: ILevantamento) => row.levantadoPor == 1 ? "Empresa" : "Parte Contrária",
      sortable: true,
      wrap: true
    },
    {
      name: 'Tipo:',
      sortField: 'tipoLevantamento',
      selector: (row: ILevantamento) => row.tipoLevantamento == 1 ? "Parcial" : "Total",
      sortable: true,
      wrap: true
    },
    {
      name: 'Principal:',
      sortField: 'valorLevantadoPrincipal',
      selector: (row: ILevantamento) => {
        if (row.valorLevantadoPrincipal === null) return "R$ 0,00";

        const valor = row.valorLevantadoPrincipal || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      }, sortable: true,
      wrap: true
    },
    {
      name: 'Correção:',
      sortField: 'valorLevantadoCorrecao',
      selector: (row: ILevantamento) => {
        if (row.valorLevantadoCorrecao === null) return "R$ 0,00";

        const valor = row.valorLevantadoCorrecao || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      }, sortable: true,
      wrap: true
    },
    {
      name: 'Juros:',
      sortField: 'valorLevantadoJuros',
      selector: (row: ILevantamento) => {
        if (row.valorLevantadoJuros === null) return "R$ 0,00";

        const valor = row.valorLevantadoJuros || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      }, sortable: true,
      wrap: true
    },
    {
      name: 'Atualizado:',
      sortField: 'valorLevantadoAtualizado',
      selector: (row: ILevantamento) => {
        if (row.valorLevantadoAtualizado === null) return "R$ 0,00";

        const valor = row.valorLevantadoAtualizado || 0;
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
      }, sortable: true,
      wrap: true
    },
    {
      name: 'Data:',
      sortField: 'dataLevantamento',
      selector: (row: ILevantamento) => {
        if (row.dataLevantamento === null) return "-";
        const data = row.dataLevantamento.toString() || "";
        if (data.includes("T")) {
          const [ano, mes, dia] = data.split("T")[0].split("-");
          return `${dia}/${mes}/${ano}`;
        }
        return "-";
      },
      sortable: true,
      wrap: true
    },
  ]


  const handlePerRowsChange = async (currentRowsPerPage: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, limit: currentRowsPerPage };
    });
  };

  const handlePageChange = (page: number) => {
    setFiltrosPesquisa((oldState) => {
      return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit };
    });
  };

  return (
    <Modal size="xl" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">
            Registrar Levantamento
          </h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <div className="row mt-1">
              <div className="col-6">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Tipo de Levantamento:
                </label>
                <select
                  {...formik.getFieldProps("tipoLevantamento")}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.tipoLevantamento && formik.errors.tipoLevantamento,
                    },
                    {
                      "is-valid": formik.touched.tipoLevantamento && !formik.errors.tipoLevantamento,
                    }
                  )} value={formik.values.tipoLevantamento}
                  onChange={(e: any) => {
                    formik.setFieldValue("tipoLevantamento", parseInt(e.target.value), true);
                  }}
                >
                  <option value="0" label="Selecione" />

                  <option value="1" label="Parcial">
                    Parcial
                  </option>
                  <option value="2" label="Total">
                    Total
                  </option>

                </select>
              </div>

              <div className="col-6">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Levantado Por:
                </label>
                <select
                  {...formik.getFieldProps("levantadoPor")}
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.tipoLevantamento && formik.errors.tipoLevantamento,
                    },
                    {
                      "is-valid": formik.touched.tipoLevantamento && !formik.errors.tipoLevantamento,
                    }
                  )} value={formik.values.levantadoPor}
                  onChange={(e: any) => {
                    formik.setFieldValue("levantadoPor", parseInt(e.target.value), true);
                  }}
                >
                  <option value="0" label="Selecione" />

                  <option value="1" label="Empresa">
                    Empresa
                  </option>
                  <option value="2" label="Parte Contrária">
                    Parte Contrária
                  </option>

                </select>
                {formik.touched.levantadoPor && formik.errors.levantadoPor && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.levantadoPor}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Valor Principal:
                </label>
                <CurrencyField
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.valorLevantadoPrincipal && formik.errors.valorLevantadoPrincipal,
                    },
                    {
                      "is-valid": formik.touched.valorLevantadoPrincipal && !formik.errors.valorLevantadoPrincipal,
                    }
                  )}
                  onValueChange={(values: any) => {
                    formik.setFieldTouched(`valorLevantadoPrincipal`, true);
                    formik.setFieldValue(`valorLevantadoPrincipal`, values.floatValue);
                  }}
                  floatValue={formik.values.valorLevantadoPrincipal}
                />
                {formik.touched.valorLevantadoPrincipal && formik.errors.valorLevantadoPrincipal && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.valorLevantadoPrincipal}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Valor Correção:
                </label>
                <CurrencyField
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.valorLevantadoCorrecao && formik.errors.valorLevantadoCorrecao,
                    },
                    {
                      "is-valid": formik.touched.valorLevantadoCorrecao && !formik.errors.valorLevantadoCorrecao,
                    }
                  )}
                  {...formik.getFieldProps('valorLevantadoCorrecao')}
                  onValueChange={(values: any) => {
                    formik.setFieldTouched(`valorLevantadoCorrecao`, true);
                    formik.setFieldValue(`valorLevantadoCorrecao`, values.floatValue);
                  }}
                  floatValue={formik.values.valorLevantadoCorrecao}
                />
                {formik.touched.valorLevantadoCorrecao && formik.errors.valorLevantadoCorrecao && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.valorLevantadoCorrecao}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Valor Juros:
                </label>
                <CurrencyField
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.valorLevantadoJuros && formik.errors.valorLevantadoJuros,
                    },
                    {
                      "is-valid": formik.touched.valorLevantadoJuros && !formik.errors.valorLevantadoJuros,
                    }
                  )}
                  {...formik.getFieldProps('valorLevantadoJuros')}
                  onValueChange={(values: any) => {
                    formik.setFieldTouched(`valorLevantadoJuros`, true);
                    formik.setFieldValue(`valorLevantadoJuros`, values.floatValue);
                  }}
                  floatValue={formik.values.valorLevantadoJuros}
                />
                {formik.touched.valorLevantadoJuros && formik.errors.valorLevantadoJuros && (
                  <div className="fv-plugins-message-container mt-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.valorLevantadoJuros}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Número do Alvará:</label>
                <input {...formik.getFieldProps('numeroAlvara')} placeholder="Número" type="text" className={clsx(
                  'form-control',
                  {
                    'is-invalid': formik.touched.numeroAlvara && formik.errors.numeroAlvara,
                  },
                  {
                    'is-valid': formik.touched.numeroAlvara && !formik.errors.numeroAlvara,
                  }
                )} id="form-nome" />
                {formik.touched.numeroAlvara && formik.errors.numeroAlvara && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.numeroAlvara}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Data do Levantamento:</label>
                <DatePicker
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  className={clsx(
                    "form-select",
                    {
                      "is-invalid":
                        formik.touched.dataLevantamento &&
                        formik.errors.dataLevantamento,
                    },
                    {
                      "is-valid":
                        formik.touched.dataLevantamento &&
                        !formik.errors.dataLevantamento,
                    }
                  )}
                  selected={formik.values.dataLevantamento}
                  onChange={(date: Date) =>
                    formik.setFieldValue("dataLevantamento", date)
                  }
                  maxDate={new Date()}  // Sets today as the maximum selectable date
                />
                {formik.touched.dataLevantamento && formik.errors.dataLevantamento && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.dataLevantamento}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-4">
                <label htmlFor="form" className="form-label fw-bolder text-orange">
                  Total:
                </label>
                {"  "}
                {CurrencyFormatterLabel(
                  (formik.values?.valorLevantadoPrincipal) +
                  (formik.values?.valorLevantadoCorrecao) +
                  (formik.values?.valorLevantadoJuros))}
              </div>
            </div>


          </form>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button disabled={!formik.isValid || formik.values.levantamentoId > 0} onClick={() => formik.submitForm()} type="button" className="btn btn-orange ms-5">
            Adicionar
          </button>

          <button
            onClick={() => {
              cancelar();
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            Cancelar
          </button>
        </div>

        <div className="col-md-12 mb-10">
          <GridPadrao
            limit={10}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo="Levantamentos"
            itens={garantia.levantamentos}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LevantamentoModal;