import { Draggable } from "react-beautiful-dnd";

interface CampoRelatorioProps {
  campo: {
    idUnico: string;
    label?: string;
    fase?: number;
    tabelaOrigem?: number;
  };
  index: number;
  descartado?: boolean;
  isRelatorio?: boolean;
  toggleModal?: (campo: { id: string; label?: string }) => void;
}

const CampoRelatorio = ({
  campo,
  index,
  descartado,
  isRelatorio,
  toggleModal,
}: CampoRelatorioProps) => {
  const handleNameChange = async () => {
    if (toggleModal) {
      toggleModal({ id: campo.idUnico, label: campo.label });
    }
  };

  return (
    <Draggable draggableId={campo.idUnico ?? ""} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div
            style={{
              border: descartado ? "1px solid red" : "1px solid #FFB52E",
              backgroundColor: descartado ? "red" : "#FFB52E",
              borderRadius: 4,
              padding: 8,
              marginBottom: 10,
              color: "white",
              fontWeight: "bold",
            }}
            className={
              campo.tabelaOrigem === 7 &&
              (campo.fase === -1 || typeof campo.fase === "undefined")
                ? "required"
                : ""
            }
            onClick={isRelatorio ? handleNameChange : () => null}
          >
            {campo.label}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default CampoRelatorio;
