import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusBloqueio from "../interfaces/IStatusBloqueio";
import { AdicionaStatusBloqueioRequest } from "../interfaces/Requests/StatusBloqueio/AdicionaStatusBloqueioRequest";
import FiltroPesquisaStatusBloqueioRequest from "../interfaces/Requests/StatusBloqueio/FiltroPesquisaStatusBloqueioRequest";
import { EditarStatusBloqueioRequest } from "../interfaces/Requests/StatusBloqueio/EditarStatusBloqueioRequest";

class StatusBloqueioService {

    async obterStatusBloqueios(filtros: FiltroPesquisaStatusBloqueioRequest): Promise<IRecuperaLista<IStatusBloqueio>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusBloqueio>>(`StatusBloqueio/RecuperaStatusBloqueio?${query}`);

        return result;
    }

    async adicionaStatusBloqueio(adicionaStatusBloqueio: AdicionaStatusBloqueioRequest): Promise<IStatusBloqueio> {
        let { data: result } = await api.post<IStatusBloqueio>(`StatusBloqueio/AdicionaStatusBloqueio`, adicionaStatusBloqueio);

        return result;
    }


    async alterarStatus(statusBloqueioId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusBloqueio>(`StatusBloqueio/AtualizaStatusBloqueioPorId?id=${statusBloqueioId}`, data);
    }

    async atualizarStatusBloqueio(editarStatusBloqueio: EditarStatusBloqueioRequest): Promise<void> {

        let dataStatusBloqueio = [
            { "op": "replace", "path": "/nome", "value": editarStatusBloqueio.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusBloqueio.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusBloqueio/AtualizaStatusBloqueioPorId?id=${editarStatusBloqueio.statusBloqueioId}`, dataStatusBloqueio)
        ]);
    }
}


export default new StatusBloqueioService();