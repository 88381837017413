import api from "./Api";
import IRecuperaLista from "../interfaces/IRecuperaLista";
import IStatusProcesso from "../interfaces/IStatusProcesso";
import { AdicionaStatusProcessoRequest } from "../interfaces/Requests/StatusProcesso/AdicionaStatusProcessoRequest";
import FiltroPesquisaStatusProcessoRequest from "../interfaces/Requests/StatusProcesso/FiltroPesquisaStatusProcessoRequest";
import { EditarStatusProcessoRequest } from "../interfaces/Requests/StatusProcesso/EditarStatusProcessoRequest";

class StatusProcessoService {

    async obterStatusProcessos(filtros: FiltroPesquisaStatusProcessoRequest): Promise<IRecuperaLista<IStatusProcesso>> {
        let query = `${filtros.status === 0 ? '&status=-1&status=1' : '&status=' + filtros.status}&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.nome) {
            query += `&nome=${filtros.nome}`;
        }

        if (filtros.codigo) {
            query += `&codigo=${filtros.codigo}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IStatusProcesso>>(`StatusProcesso/RecuperaStatusProcesso?${query}`);

        return result;
    }

    async adicionaStatusProcesso(adicionaStatusProcesso: AdicionaStatusProcessoRequest): Promise<IStatusProcesso> {
        let { data: result } = await api.post<IStatusProcesso>(`StatusProcesso/AdicionaStatusProcesso`, adicionaStatusProcesso);

        return result;
    }


    async alterarStatus(statusProcessoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IStatusProcesso>(`StatusProcesso/AtualizaStatusProcessoPorId?id=${statusProcessoId}`, data);
    }

    async atualizarStatusProcesso(editarStatusProcesso: EditarStatusProcessoRequest): Promise<void> {

        let dataStatusProcesso = [
            { "op": "replace", "path": "/nome", "value": editarStatusProcesso.nome },
            { "op": "replace", "path": "/codigo", "value": editarStatusProcesso.codigo }
        ]


        await Promise.all([
            await api.patch<void>(`StatusProcesso/AtualizaStatusProcessoPorId?id=${editarStatusProcesso.statusProcessoId}`, dataStatusProcesso)
        ]);
    }
}


export default new StatusProcessoService();