import { useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GridPadrao from "../GridPadrao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "react-data-table-component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import EmpresaService from "../../../services/EmpresaService";

import IRecuperaLista from "../../../interfaces/IRecuperaLista";

interface IEmpresaFieldProps {
  toggleModal: () => void;
  exibirModal: boolean;
  empresaId: number;
}

const EmpresaRegulatorioField = ({ toggleModal, exibirModal, empresaId }: IEmpresaFieldProps) => {
  const [socios, setSocios] = useState<any[]>([]);
  const [carregandoSocios, setCarregandoSocios] = useState(false);


  const carregarSocios = async () => {
    try {
      setCarregandoSocios(true);

      let resultado: IRecuperaLista<any>;
      resultado = await EmpresaService.obterSocioAdministrador(empresaId);

      setSocios(resultado.data);
      setCarregandoSocios(false);

    } catch (error: any) {
      setSocios([]);
      setCarregandoSocios(false);
      Swal.fire({
        heightAuto: false,
        title: "Não foi possível obter registros",
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        timer: 4000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };


  const colunas: TableColumn<any>[] = [
    {
      name: "Id",
      sortField: "socioAdministradorId",
      selector: (row: any) => row.socioAdministradorId,
      sortable: true,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      name: "Nome",
      sortField: "nome",
      selector: (row: any) => row.nome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tipo de Quotas/Ações",
      sortField: "tipoQuotasAcoesNome",
      selector: (row: any) => row.tipoQuotasAcoesNome,
      sortable: true,
      wrap: true,
    },
    {
      name: "Quotas/Ações",
      sortField: "quotasAcoes",
      selector: (row: any) => row.quotasAcoes,
      sortable: true,
      wrap: true,
    }
  ];


  useLayoutEffect(() => {
    if (empresaId) {
      carregarSocios();
    }
  }, [empresaId]);

  return (
    <Modal size="lg" centered={false} show={exibirModal} onHide={toggleModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-orange">Sócios Administradores</h5>
          <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
            <FontAwesomeIcon className="mx-2 text-orange" icon={faTimes} />
          </div>
        </div>
        <div className="modal-body">
          <div className="col-md-auto mb-10">
            <GridPadrao
              progressPending={carregandoSocios}
              paginationServer={true}
              colunas={colunas}
              tipo="Socios"
              itens={socios}
            />
          </div>
        </div>
        <div className="modal-footer" style={{ margin: "0 auto" }}>
          <button
            onClick={() => {
              toggleModal();
            }}
            type="button"
            className="btn btn-danger ms-5"
          >
            {" "}
            Fechar{" "}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EmpresaRegulatorioField;
