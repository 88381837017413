
export function generateColorPalette(hexColor: string): string[] {
    const lightenDarkenColor = (col: string, amt: number): string => {
      col = col.replace(/^#/, "");
      if (col.length === 3) {
        col = col
          .split("")
          .map((c) => c + c)
          .join("");
      }
  
      let num = parseInt(col, 16);
      let r = (num >> 16) + amt;
      let g = ((num >> 8) & 0x00ff) + amt;
      let b = (num & 0x0000ff) + amt;
  
      r = Math.min(255, Math.max(0, r));
      g = Math.min(255, Math.max(0, g));
      b = Math.min(255, Math.max(0, b));
  
      return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;
    };
  
    const hexToHSL = (hex: string): [number, number, number] => {
      let r = 0,
        g = 0,
        b = 0;
      if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
      } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
      }
      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      let h = (max + min) / 2,
        s = (max + min) / 2,
        l = (max + min) / 2;
  
      if (max == min) {
        h = s = 0; 
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }
      return [h * 360, s * 100, l * 100];
    };
  
    const hslToHex = (h: number, s: number, l: number): string => {
      s /= 100;
      l /= 100;
      const k = (n: number) => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n: number) =>
        l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
      return `#${Math.round(f(0) * 255)
        .toString(16)
        .padStart(2, "0")}${Math.round(f(8) * 255)
        .toString(16)
        .padStart(2, "0")}${Math.round(f(4) * 255)
        .toString(16)
        .padStart(2, "0")}`;
    };
  
    const palette: string[] = [];
  
    for (let i = 4; i > 0; i--) {
      palette.push(lightenDarkenColor(hexColor, i * 20));
    }
  
    for (let i = 1; i <= 3; i++) {
      palette.push(lightenDarkenColor(hexColor, -i * 20));
    }
  
    const [h, s, l] = hexToHSL(hexColor);
    const complementaryColor = hslToHex((h + 180) % 360, s, l);
    palette.push(complementaryColor);
  
    return palette;
  }
  