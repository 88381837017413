import { useContext, useEffect, useRef, useState } from "react";
import { faPrint, faShare, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactToPrint from "react-to-print";
import { useFormik } from "formik";

import { defaultSchema } from "../../utils/SchemasUtil";

import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaReembolsoRequest } from "../../interfaces/Requests/Reembolso/AdicionaReembolsoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import { EModulo, ETelaEnum } from "../../enum";
import LayoutPageButton from "../../layout/LayoutPageButton";

import ReembolsoService from "../../services/ReembolsoService";
import sessionStorageService from "../../services/sessionStorage/sessionStorageService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import CamposCapaSolucoes from "../../components/Comum/Solucao/CamposCapaSolucoes";
import FileListCapaField from "../../components/FileUpload/FileListCapaField";
import CompromissoListField from "../../components/Comum/Compromisso/CompromissoListField";
import { PopoverSubMenuImovel } from "../../components/Comum/Popover/PopoverSubMenuImovel";
import { PopoverSubMenuProcesso } from "../../components/Comum/Popover/PopoverSubMenuProcesso";

const ReembolsoCapaPage = () => {
  const { reembolso, tipoReembolso, setReembolso, processo } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);
  const [refreshCapa, setRefreshCapa] = useState(true);

  const componentRef = useRef<any>();

  const compartilharWhatSapp = () => {
    return `Número do Reembolso: *#${reembolso.reembolsoId}* / ${tipoReembolso.tipoReembolsoId > 0
      ? "Tipo de Decisão: *" + tipoReembolso.nome + "*"
      : ""
      }  / Status: *${reembolso.statusNome == ""
        ? "Ativo*"
        : nomearStatusReembolso(reembolso.statusNome)
      }*`;
  };

  const nomearStatusReembolso = (categoria: string) => {
    switch (categoria) {
      case "EmElaboracao":
        return "Em Elaboração";
      case "EmAprovacao":
        return "Em Aprovação";
      case "EmAssinatura":
        return "Em Assinatura";
      case "Assinado":
        return "Assinado";
      case "Cancelado":
        return "Cancelado";
      case "Ativo":
        return "Ativo";
      default:
        return "Não definido";
    }
  };

  const initialValues: AdicionaReembolsoRequest = {
    reembolsoId: reembolso.reembolsoId,
    principalId: reembolso.reembolsoId,
    tipoReembolsoId: reembolso.tipoReembolsoId,

    modulo: EModulo.XpayReembolso,
    categoriaId: reembolso.categoriaId,

    tela: ETelaEnum.TelaCadastroReembolso,

    processoId: processo.processoId,
    areaDireitoId: processo.areaDireitoId,
    escritorioId: processo.escritorioId || 0,

    contratoId: reembolso.contratoId,
    tipoContratoId: reembolso.tipoContratoId,

    imovelId: reembolso.imovelId,
    tipoImovelId: reembolso.tipoImovelId,

    consultaId: reembolso.consultaId,
    tipoConsultaId: reembolso.tipoConsultaId,

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!reembolso.reembolsoId) return;

    carregaCapa();

    formik.setValues(initialValues);

  }, [reembolso.reembolsoId, refreshCapa]);

  useEffect(() => {
    if (!reembolso.reembolsoId) {
      carregarSessionStorage();
    } else {
      inserirSessionStorage();
    }
  }, [reembolso]);


  const inserirSessionStorage = () => sessionStorageService.inserir(reembolso, "reembolso_capa");


  const carregarSessionStorage = async () => setReembolso(JSON.parse((await sessionStorageService.obter("reembolso_capa")) || null));


  const carregaCapa = async () => {
    try {
      if (!reembolso.reembolsoId) return;

      setCarregandoConfiguracoesTela(true);
      let resultado = await ReembolsoService.obterCapaReembolso(reembolso.reembolsoId);
      setConfiguracoesTela(resultado.data);
      setCarregandoConfiguracoesTela(false);
    
    } catch (error: any) {
      console.log(error?.response?.data?.Message);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };


  const renderCampos = () => {
    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => {
      component.push(
        <CamposCapaSolucoes
          controller={"reembolso"}
          configuracaoTela={configuracaoTela}
          setConfiguracaoTela={setConfiguracoesTela}
          configuracaoTelaLista={configuracoesTela}
          formik={formik}
        />
      );
    });

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <div style={{ marginLeft: "15px" }} ref={componentRef}>
        <LayoutPageButton
          title={`Capa #${reembolso.reembolsoId}  ${tipoReembolso.tipoReembolsoId > 0 ? " - " + tipoReembolso.nome : ""
            } => ${reembolso.statusNome}`}
        >
          <>
            {!carregandoConfiguracoesTela && (
              <a
                style={{ cursor: "pointer", fontSize: "30px" }}
                onClick={() => setRefreshCapa(!refreshCapa)}
              >
                {
                  <FontAwesomeIcon
                    color={"#DC853D"}
                    className="mx-2"
                    icon={faHistory}
                  />
                }
              </a>
            )}

            {carregandoConfiguracoesTela && (
              <span
                className="indicator-progress"
                style={{
                  display: "block",
                  fontSize: "30px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </>
          <ReactToPrint
            trigger={() => (
              <a style={{ cursor: "pointer", fontSize: "30px" }}>
                <FontAwesomeIcon
                  color={"#DC853D"}
                  className="mx-2"
                  icon={faPrint}
                  title="Imprimir o reembolso"
                />
              </a>
            )}
            content={() => componentRef.current}
          />
          <a
            style={{ cursor: "pointer", fontSize: "30px" }}
            className="linkVisitado"
            href={`https://web.whatsapp.com/send?text=${compartilharWhatSapp()}`}
            target="_blank"
            title="Compartilhar através do WhatsApp"
          >
            {
              <FontAwesomeIcon
                color={"#DC853D"}
                className="mx-2"
                icon={faShare}
              />
            }
          </a>

          <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="bottom"
            overlay={PopoverSubMenuProcesso()}
          >
            <a
              style={{ cursor: "pointer", marginRight: "10px" }}
              className="navigation-services-list-link"
            >
              <span className="navigation-service-icon navigation-service-icon-grid"></span>
            </a>
          </OverlayTrigger>
        </LayoutPageButton>
        <hr />
        <br />

        {carregandoConfiguracoesTela ? (
          <Carregando />
        ) : (
          <>
            {renderCampos()}
            <hr />
            <CompromissoListField modulo={EModulo.XpayReembolso} setRefreshCapa={setRefreshCapa}
            />
            <hr />
            <FileListCapaField modulo={EModulo.XpayReembolso} configuracoesTela={configuracoesTela} setConfiguracoesTela={setConfiguracoesTela}
            />
          </>
        )}

      </div>
    </>
  );
};
export default ReembolsoCapaPage;
