import { Routes, Navigate, Route, useLocation } from 'react-router-dom';
import Layout from './Layout';
import { useAuth } from '../contexts/AuthProvider';
import { PrivateRoutes } from '../routing/PrivateRoutes';
import AlterarSenhaPage from '../pages/AlterarSenhaPage/AlterarSenhaPage';
import { useContext, useEffect } from 'react';
import { OrangeInterfaceContext } from '../interfaces/Contexts/OrangeInterfaceContext';
import { OrangeContext } from '../contexts/OrangeProvider';
import { generateColorPalette } from '../utils/GeneratePallette';

const isEditorPage = (path: string) => {
  const [, editorIdentifier] = path.split('/').filter(p => p)
  return editorIdentifier === 'Editor'
};

export default function LayoutWrapper() {
  const { user } = useAuth();
  const params = useLocation();



  const { setSelectedTheme, selectedTheme, setThemeMode, themeMode } =
    useContext<OrangeInterfaceContext>(OrangeContext);

  useEffect(() => {
    
    if (user) {
      const corTemplate = user?.corTemplate || ''; 
      const [primeiraCor, segundaCor] = corTemplate.split('|'); 
    let colorPallete = generateColorPalette(primeiraCor);
    setSelectedTheme({
      primaryBase: primeiraCor,
      primaryBase2: colorPallete[1],
      primaryBaseLight1: colorPallete[2],
      primaryBaseLight2: colorPallete[3],
      primaryBaseLight3: colorPallete[4],
      primaryBaseDark1: colorPallete[5],
      primaryBaseDark2: colorPallete[6],
      primaryBaseDark3: colorPallete[7],
      secondaryBase: segundaCor,
    })
    setThemeMode(user?.modoExibicao == 1 ? "light" : "dark");
    
   }
  }, [])
  return (
    <Layout isEditorPage={isEditorPage(params.pathname)}>
      {user?.primeiroAcesso ? (
        <Routes>
          <Route path='/Alterar-Senha'>
            <Route index element={<AlterarSenhaPage />} />
          </Route>
          <Route path='/' element={<Navigate replace to='/Alterar-Senha' />} />
          <Route path='*' element={<Navigate to='/Alterar-Senha' />} />
        </Routes>
      ) : (
        <PrivateRoutes />
      )}
    </Layout>
  );
}
