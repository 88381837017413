import api from "./Api";

import IRecuperaLista from "../interfaces/IRecuperaLista";
import IDesdobramento from "../interfaces/IDesdobramento";

import { EModulo, ETipoCampoEnum } from "../enum";

import { EditarDesdobramentoRequest } from "../interfaces/Requests/Desdobramento/EditarDesdobramentoRequest";
import { AdicionaDesdobramentoRequest } from "../interfaces/Requests/Desdobramento/AdicionaDesdobramentoRequest";
import FiltroPesquisaDesdobramentoRequest from "../interfaces/Requests/Desdobramento/FiltroPesquisaDesdobramentoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

class DesdobramentoService {

    public async obterDesdobramentos(filtros: FiltroPesquisaDesdobramentoRequest): Promise<IRecuperaLista<IDesdobramento>> {
        let query = `&offset=${filtros.offset}&limit=${filtros.limit}&sort=${filtros.sort}`

        if (filtros.statusDesdobramentoId.length > 0) {
            filtros.statusDesdobramentoId.forEach((id) => (query += `&statusDesdobramentoId=${id}`));
        }

        if (filtros.processoId) {
            query += `&processoId=${filtros.processoId}`;
        }
        if (filtros.dataDistribuicaoMaiorIgual) {
            query += `&dataDistribuicaoMaiorIgual=${filtros.dataDistribuicaoMaiorIgual}`;
        }

        if (filtros.dataDistribuicaoMenorIgual) {
            query += `&dataDistribuicaoMenorIgual=${filtros.dataDistribuicaoMenorIgual}`;
        }

        if (filtros.dataProtocoloMaiorIgual) {
            query += `&dataProtocoloMaiorIgual=${filtros.dataProtocoloMaiorIgual}`;
        }

        if (filtros.dataProtocoloMenorIgual) {
            query += `&dataProtocoloMenorIgual=${filtros.dataProtocoloMenorIgual}`;
        }

        if (filtros.dataEncerramentoMaiorIgual) {
            query += `&dataEncerramentoMaiorIgual=${filtros.dataEncerramentoMaiorIgual}`;
        }

        if (filtros.dataEncerramentoMenorIgual) {
            query += `&dataEncerramentoMenorIgual=${filtros.dataEncerramentoMenorIgual}`;
        }

        if (filtros.dataCadastroMaiorIgual) {
            query += `&dataCadastroMaiorIgual=${filtros.dataCadastroMaiorIgual}`;
        }

        if (filtros.dataCadastroMenorIgual) {
            query += `&dataCadastroMenorIgual=${filtros.dataCadastroMenorIgual}`;
        }

        if (filtros.tipoDesdobramentoId.length > 0) {
            for (let index = 0; index < filtros.tipoDesdobramentoId.length; index++) {
                const tipoDesdobramentoId = filtros.tipoDesdobramentoId[index];

                query += `&tipoDesdobramentoId=${tipoDesdobramentoId}`;
            }
        }

        if (filtros.numero) {
            query += `&numero=${filtros.numero}`;
        }

        let { data: result } = await api.get<IRecuperaLista<IDesdobramento>>(`Desdobramento/RecuperaDesdobramento?${query}`);

        return result;
    }

    public async obterDesdobramentoPorId(desdobramentoId: number): Promise<IRecuperaLista<IDesdobramento>> {
        let query = `&offset=0&limit=100&sort=desdobramentoId`;

        query += `&desdobramentoId=${desdobramentoId}`;

        let { data: result } = await api.get<IRecuperaLista<IDesdobramento>>(`Desdobramento/RecuperaDesdobramento?${query}`);

        return result;
    }


    public async adicionaDesdobramento(adicionaDesdobramento: any): Promise<IDesdobramento> {

        if (!adicionaDesdobramento.estadoId) delete adicionaDesdobramento.estadoId;
        if (!adicionaDesdobramento.cidadeId) delete adicionaDesdobramento.cidadeId;

        let { data: result } = await api.post<IDesdobramento>(`Desdobramento/AdicionaDesdobramento`, this.montarObjetoRequest(adicionaDesdobramento));

        return result;
    }

    public async editarDesdobramento(editarDesdobramento: AdicionaDesdobramentoRequest) {
        let request = this.montarObjetoArray(editarDesdobramento);

        if (request.length > 0) {
            let result = await api.patch(`Desdobramento/AtualizaDesdobramentoPorId?id=${editarDesdobramento.desdobramentoId}`, request);
            return result;
        }
    }

    public async adicionaFavorido(desdobramentoId: number): Promise<any> {
        let { data: result } = await api.post<any>(`Favorito/AdicionaFavorito`, { modulo: EModulo.Desdobramento, desdobramentoId: desdobramentoId });
        return result;
    }

    public async removerFavorido(favoritoId: number) {
        await api.delete<number>(`Favorito/RemoveFavorito?favoritoId=${favoritoId.toString()}`);
    }

    public async obterCapaDesdobramento(desdobramentoId: number): Promise<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>> {
        let { data: result } = await api.get<IRecuperaLista<IRecuperaConfiguracaoTelaResponse>>(`/Desdobramento/RecuperaCapaDesdobramento?desdobramentoId=${desdobramentoId}`);
        return result;
    }

    public async alterarStatus(desdobramentoId: number, status: number): Promise<void> {
        let data = [
            { "op": "replace", "path": "/status", "value": status },
        ]

        await api.patch<IDesdobramento>(`Desdobramento/AtualizaDesdobramentoPorId?id=${desdobramentoId}`, data);
    }

    public async atualizarDesdobramento(editarDesdobramento: EditarDesdobramentoRequest): Promise<void> {

        let dataDesdobramento = [
            { "op": "replace", "path": "/codigo", "value": editarDesdobramento.codigo },
            { "op": "replace", "path": "/descricaoObrigacao", "value": editarDesdobramento.resumo }
        ]


        await Promise.all([
            await api.patch<void>(`Desdobramento/AtualizaDesdobramentoPorId?id=${editarDesdobramento.desdobramentoId}`, dataDesdobramento)
        ]);
    }

    public async adicionarXCampoValor(request: any) {
        let result = await api.post<any>(`Desdobramento/AdicionaXCampoValor`, request);

        return result;
    }

    public async alterarDesdobramentoPorXCampo(campo: string, valor: any, xcampoId: number) {
        let request: any = [];

        if (valor) {
            request.push({ op: "replace", path: "/" + campo, value: valor });
            let result = await api.patch<any>(`Desdobramento/AtualizaXCampoValor?id=${xcampoId}`, request);
            return result;
        }
    }

    public concluirCompromisso = async (desdobramento: AdicionaDesdobramentoRequest): Promise<any> => {

        let result: any[] = [];

        // Salvar campos normais
        result.push(await this.editarDesdobramento(desdobramento));

        // Salvar xcampos
        if (desdobramento.xCampoValorList?.length > 0) {
            desdobramento.xCampoValorList.forEach(async xCampo => {
                switch (xCampo.campoTipo) {

                    case ETipoCampoEnum.Texto:
                    case ETipoCampoEnum.AreaTexto:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, desdobramentoId: desdobramento.desdobramentoId, texto: xCampo.texto }));
                        else result.push(await this.alterarDesdobramentoPorXCampo(ETipoCampoEnum.Texto, xCampo.texto, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Data:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, desdobramentoId: desdobramento.desdobramentoId, data: xCampo.data }));
                        else result.push(await this.alterarDesdobramentoPorXCampo(ETipoCampoEnum.Data, xCampo.data, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Valor:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, desdobramentoId: desdobramento.desdobramentoId, valor: xCampo.valor }));
                        else result.push(await this.alterarDesdobramentoPorXCampo(ETipoCampoEnum.Valor, xCampo.valor, xCampo.xCampoValorId));
                        break;


                    case ETipoCampoEnum.Inteiro:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, desdobramentoId: desdobramento.desdobramentoId, inteiro: xCampo.inteiro }));
                        else result.push(await this.alterarDesdobramentoPorXCampo(ETipoCampoEnum.Inteiro, xCampo.inteiro, xCampo.xCampoValorId));
                        break;

                    case ETipoCampoEnum.Lista:
                        if (!xCampo.xCampoValorId) result.push(await this.adicionarXCampoValor({ campoId: xCampo.campoId, desdobramentoId: desdobramento.desdobramentoId, xCampoOpcaoId: xCampo.xCampoOpcaoId }));
                        else result.push(await this.alterarDesdobramentoPorXCampo(ETipoCampoEnum.xCampoOpcao, xCampo.xCampoOpcaoId, xCampo.xCampoValorId));
                        break;
                }
            });
        }

        // results
        return result;
    };

    private montarObjetoRequest = (desdobramento: AdicionaDesdobramentoRequest) => {
        let request: any = {};

        if (desdobramento.xCampoValorList.length > 0) request.xCampoValorList = desdobramento.xCampoValorList;

        if (desdobramento.tipoDesdobramentoId) request.tipoDesdobramentoId = desdobramento.tipoDesdobramentoId;

        if (desdobramento.codigo) request.codigo = desdobramento.codigo;
        if (desdobramento.instanciaId) request.instanciaId = desdobramento.instanciaId;
        if (desdobramento.cidadeId) request.cidadeId = desdobramento.cidadeId;
        if (desdobramento.responsaveldesdobramentoId) request.responsavelDesdobramentoId = desdobramento.responsaveldesdobramentoId;
        if (desdobramento.estadoId) request.estadoId = desdobramento.estadoId;
        if (desdobramento.numero) request.numero = desdobramento.numero;
        if (desdobramento.resumo) request.resumo = desdobramento.resumo;
        if (desdobramento.datadistribuicao) request.dataDistribuicao = desdobramento.datadistribuicao;
        if (desdobramento.dataprotocolo) request.dataProtocolo = desdobramento.dataprotocolo;

        if (desdobramento.processoId) {
            request.modulo = EModulo.AreaDireito;
            request.processoId = desdobramento.processoId;
            request.areaDireitoId = desdobramento.areaDireitoId;
        }

        if (desdobramento.imovelId) {
            request.modulo = EModulo.Imovel;
            request.imovelId = desdobramento.imovelId;
            request.tipoImovelId = desdobramento.tipoImovelId;
        }

        return request;
    };

    private montarObjetoArray = (desdobramento: AdicionaDesdobramentoRequest) => {
        let request: any = [];

        // Campos texto
        if (desdobramento.codigo) request.push({ op: "replace", path: "/codigo", value: desdobramento.codigo });
        if (desdobramento.resumo) request.push({ op: "replace", path: "/resumo", value: desdobramento.resumo });
        if (desdobramento.numero) request.push({ op: "replace", path: "/numero", value: desdobramento.numero });

        if (desdobramento.instanciaId) request.push({ op: "replace", path: "/instanciaId", value: desdobramento.instanciaId });
        if (desdobramento.cidadeId) request.push({ op: "replace", path: "/cidadeId", value: desdobramento.cidadeId });
        if (desdobramento.responsaveldesdobramentoId) request.push({ op: "replace", path: "/responsavelDesdobramento", value: desdobramento.responsaveldesdobramentoId });
        if (desdobramento.estadoId) request.push({ op: "replace", path: "/estadoId", value: desdobramento.estadoId });

        if (desdobramento.datadistribuicao) request.push({ op: "replace", path: "/dataDistribuicao", value: desdobramento.datadistribuicao });
        if (desdobramento.dataprotocolo) request.push({ op: "replace", path: "/dataProtocolo", value: desdobramento.dataprotocolo });

        return request;
    };
}


export default new DesdobramentoService();