/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState, useContext } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import { useRef } from "react";
import moment from "moment";


import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/pt-br';
import interactionPlugin from '@fullcalendar/interaction';
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { Button, Dropdown, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { faCalendar, faCalendarWeek, faCheck, faClock, faEdit, faEraser, faFileExcel, faFilter, faForward, faList, faPlus, faSearch, faUsers } from "@fortawesome/free-solid-svg-icons";

import { EModulo } from "../../enum";
import { routePropriedade, tipoPorModulo } from "../../utils/Utils";
import { EModuloNomeEnum } from "../../enum/EModuloNomeEnum";

import FeedService from "../../services/FeedService";
import ModuloService from "../../services/ModuloService";
import ImovelService from "../../services/ImovelService";
import UsuarioService from "../../services/UsuarioService";
import DespesaService from "../../services/DespesaService";
import LicencaService from "../../services/LicencaService";
import ConsultaService from "../../services/ConsultaService";
import ProcessoService from "../../services/ProcessoService";
import ContratoService from "../../services/ContratoService";
import ObrigacaoService from "../../services/ObrigacaoService";
import AudienciaService from "../../services/AudienciaService";
import ProcuracaoService from "../../services/ProcuracaoService";
import EscritorioService from "../../services/EscritorioService";
import ComunicacaoService from "../../services/ComunicacaoService";
import CompromissoService from "../../services/CompromissoService";
import DesdobramentoService from "../../services/DesdobramentoService";
import TipoCompromissoService from "../../services/TipoCompromissoService";

import IFeed from "../../interfaces/IFeed";
import IOption from "../../interfaces/IOption";
import IUsuario from "../../interfaces/IUsuario";
import IConsulta from "../../interfaces/IConsulta";
import IProcesso from "../../interfaces/IProcesso";
import IContrato from "../../interfaces/IContrato";
import ICompromisso from "../../interfaces/ICompromisso";
import IRecuperaLista from "../../interfaces/IRecuperaLista";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import FiltroPesquisaFeedRequest from "../../interfaces/Requests/Feed/FiltroPesquisaFeedRequest";

import DecisaoService from "../../services/DecisaoService";
import GarantiaService from "../../services/GarantiaService";
import BloqueioService from "../../services/BloqueioService";
import HonorarioService from "../../services/HonorarioService";
import ReembolsoService from "../../services/ReembolsoService";
import PropostaAcordoService from "../../services/PropostaAcordoService";

import LayoutPageTitle from "../../layout/LayoutPageTitle";
import { OrangeContext } from "../../contexts/OrangeProvider";

import Carregando from "../../components/Carregando";
import FeedRelatorioModal from "./FeedRelatorioModal";
import GridPadrao from "../../components/Comum/GridPadrao";
import ResponsaveisFeedModal from "./ResponsaveisFeedModal";
import DropDown from "../../components/Comum/DropDown/DropDown";
import FluxoModal from "../../components/Fluxo/Modals/FluxoModal";
import CollapseFiltro from "../../components/Collapse/CollapseFiltro";
import CompromissoField from "../../components/Comum/Compromisso/CompromissoField";
import AreaTextoFiltroField from "../../components/Comum/AreaTexto/AreaTextoFiltroField";

export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button, 
  .fc-button.fc-next-button, 
  .fc-button.fc-button-primary{
  background: var(--primary-base);
  border-color:var(--primary-base);
  background-image: none;
  }
  .fc-toolbar-title, .fc-col-header-cell-cushion{
    color: var(--primary-base);
}`

export enum ETipoBusca {
  Usuario = 1,
  GrupoUsuario = 2,
  Escritorio = 3
}

const FeedPage = () => {

  const navigate = useNavigate();

  const [feeds, setFeeds] = useState<IFeed[]>([]);
  const [carregandoFeed, setCarregandoFeed] = useState(false);
  const [responsaveis, setResponsaveis] = useState<IUsuario[]>([]);

  const [carregandoDetalhes, setCarregandoDetalhes] = useState(false);
  const [feedDetalhe, setFeedDetalhe] = useState<any>();

  const [compromissos, setCompromissos] = useState<IOption[]>([]);
  const [refreshFeed, setRefreshFeed] = useState<boolean>(false);

  const [exibirModalRelatorio, setExibirModalRelatorio] = useState<boolean>(false);
  const [exibirModalCompromisso, setExibirModalCompromisso] = useState<boolean>(false);
  const [exibirModalResponsaveis, setExibirModalResponsaveis] = useState<boolean>(false);

  const [pesquisar, setPesquisar] = useState<boolean>(false);
  const [usuariosOptions, setUsuariosOptions] = useState<IOption[]>([]);
  const [usuariosSelected, setUsuariosSelected] = useState<IOption[]>([]);

  const [moduloOptions, setModulosOptions] = useState<IOption[]>([]);
  const [modulosSelected, setModulosSelected] = useState<IOption[]>([]);

  const [escritoriosOptions, setEscritoriosOptions] = useState<IOption[]>([]);
  const [escritoriosSelected, setEscritoriosSelected] = useState<IOption[]>([]);

  const [grupoUsuariosOptions, setGrupoUsuariosOptions] = useState<IOption[]>([]);
  const [grupoUsuariosSelected, setGrupoUsuariosSelected] = useState<IOption[]>([]);

  const [tipoCompromissoOptions, setTipoCompromissoOptions] = useState<IOption[]>([]);
  const [tipoCompromissoSelected, settipoCompromissoSelected] = useState<IOption[]>([]);

  const [tipoCategoriaCompromissoOptions] = useState<IOption[]>([{ label: 'Audiência', value: 2 }, { label: 'Tarefa', value: 1 }]);
  const [tipoCategoriaCompromissoSelected, setTipoCategoriaCompromissoSelected] = useState<IOption[]>([]);

  const [statusCompromissoOptions] = useState<IOption[]>([{ label: 'Pendente', value: 1 }, { label: 'Concluído', value: 2 }, { label: 'Cancelado', value: 3 }]);
  const [statusCompromissoSelected, setStatusCompromissoSelected] = useState<IOption[]>([]);

  const [exibirModalAreaTexto, setExibirModalAreaTexto] = useState<boolean>(false);

  const [view, setView] = useState('dayGridMonth');
  const {

    setProcesso,
    setContrato,
    setImovel,
    setProcuracao,
    setLicenca,
    setConsulta,

    setObrigacao,
    setDespesa,
    setDesdobramento,
    setPropostaAcordo,
    setGarantia,
    setDecisao,
    setBloqueio,
    setHonorario,
    setReembolso,
    setComunicacao,
    setAudiencia
  } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState<FiltroPesquisaFeedRequest>({
    compromissoId: 0,
    modeloRelatorioId: 0,
    modulo: EModulo.Compromisso,
    escritorioId: [],
    moduloId: [],
    grupoUsuarioId: [],
    tipoCategoriaCompromissoId: [],
    usuarioId: [],
    categoria: 0,
    tipoCompromissoId: [],
    processoId: 0,
    clienteId: 0,
    dataCompromissoMaiorIgual: "",
    dataCompromissoMenorIgual: "",
    observacaoCompromisso: "",
    usuarioIdCadastro: 0,
    dataCadastroMaiorIgual: "",
    dataCadastroMenorIgual: "",
    observacaoConclusao: "",
    usuarioIdConclusao: 0,
    dataConclusaoMaiorIgual: "",
    dataConclusaoMenorIgual: "",
    usuariosAprovadores: [],
    status: [],
    limit: 100,
    totalItems: 0,
    offset: 0,
    sort: 'compromissoId'
  });

  const [exibirModalCompromissoAvulso, setExibirModalCompromissoAvulso] = useState<boolean>(false);

  const [compromisso, setCompromisso] = useState<ICompromisso>({
    modulo: EModulo.Compromisso,
    tipoCompromissoNome: "",
    compromissoId: 0,
    processoId: 0,
    codigo: "",
    dataConclusao: new Date(),
    dataCompromisso: new Date(),
    observacaoCompromisso: "",
    observacaoConclusao: "",
    tipoCompromissoId: 0,
    usuariosAprovadoresList: [],
    horario: "",
    grupoId: []
  });

  const toggleAreaTextoModal = () => setExibirModalAreaTexto(!exibirModalAreaTexto);
  const toggleExibirRelatorio = () => setExibirModalRelatorio(!exibirModalRelatorio);
  const toggleExibirResponsaveis = () => setExibirModalResponsaveis(!setExibirModalResponsaveis);
  const toggleExibirCompromissoAvulso = () => setExibirModalCompromissoAvulso(!exibirModalCompromissoAvulso);

  const tooltip = (title: string) => <Tooltip id={`button-tooltip-${title}`}> {title} </Tooltip>
  const handlePerRowsChange = async (currentRowsPerPage: number) => setFiltrosPesquisa(oldState => { return { ...oldState, limit: currentRowsPerPage } });
  const handlePageChange = (page: number) => setFiltrosPesquisa(oldState => { return { ...oldState, offset: (page - 1) * filtrosPesquisa.limit } });
  const handleSort = async (column: TableColumn<IFeed>, sortDirection: string) => setFiltrosPesquisa(oldState => { return { ...oldState, sort: `${sortDirection === 'desc' ? '-' : ''}${column.sortField}` } });


  const handleDiaClick = () => {
    setCarregandoFeed(true);
    setTimeout(() => {
      setCarregandoFeed(false);
      setView('dayGridDay');
    }, 2000);
  }

  const handleSemanaClick = () => {
    setCarregandoFeed(true);
    setTimeout(() => {
      setCarregandoFeed(false);
      setView('timeGridWeek');
    }, 2000);
  }

  const handleMesClick = () => {
    setCarregandoFeed(true);
    setTimeout(() => {
      setCarregandoFeed(false);
      setView('dayGridMonth');
    }, 2000);
  }

  const handlePlanilhaClick = () => {
    setCarregandoFeed(true);
    setTimeout(() => {
      setCarregandoFeed(false);
      setView('sheetGrid');
    }, 2000);
  }

  const montarFeeds = (feeds: IFeed[]) => {
    const newCompromissos: any[] = []; // Use a local variable
    feeds.forEach(feed => {
      newCompromissos.push({
        tipoCompromissoNome: feed?.tipoCompromissoNome,
        compromissoId: feed?.compromissoId,

        modulo: feed?.modulo,

        processoId: feed?.processoId,
        consultaId: feed?.consultaId,
        contratoId: feed?.contratoId,
        imovelId: feed?.imovelId,
        procuracaoId: feed?.procuracaoId,
        licencaId: feed?.licencaId,

        decisaoId: feed?.decisaoId,
        despesaId: feed?.despesaId,
        bloqueioId: feed?.bloqueioId,
        garantiaId: feed?.garantiaId,
        reembolsoId: feed?.reembolsoId,
        honorarioId: feed?.honorarioId,
        audienciaId: feed?.audienciaId,
        obrigacaoId: feed?.obrigacaoId,
        comunicacaoId: feed?.comunicacaoId,
        desdobramentoId: feed?.desdobramentoId,
        propostaAcordoId: feed?.propostaAcordoId,

        fluxoFaseId: feed?.fluxoFaseId,
        status: feed?.status,
        usuario: feed?.usuarioNomeCadastro,
        compromissoDetalhes: feed?.compromissoDetalhes,
        dataCompromisso: moment(feed.dataCompromisso).format('DD/MM/YYYY HH:mm'),
        start: new Date(feed.dataCompromisso),
        color: '#fd7e14',
        display: '#fd7e14',
      });
    });

    setCompromissos(newCompromissos);
  }

  const carregarDetalhes = async (compromissoId: number) => {
    try {

      setCarregandoDetalhes(true);
      let resultado: IRecuperaLista<IFeed>;

      resultado = await FeedService.obterDetalhe(compromissoId);

      setFeedDetalhe(resultado.data[0]);

    } catch (error: any) {
      console.log(error);
    } finally {
      setCarregandoDetalhes(false);

    }
  }

  const carregarFeeds = async (filtro: FiltroPesquisaFeedRequest) => {
    try {
      setCarregandoFeed(true);

      let resultado: IRecuperaLista<IFeed>;

      resultado = await FeedService.obterFeed({
        ...filtro,
        moduloId: modulosSelected,
        usuarioId: usuariosSelected,
        status: statusCompromissoSelected,
        escritorioId: escritoriosSelected,
        grupoUsuarioId: grupoUsuariosSelected,
        tipoCompromissoId: tipoCompromissoSelected,
        tipoCategoriaCompromissoId: tipoCategoriaCompromissoSelected,

        dataCompromissoMaiorIgual: filtro.dataCompromissoMaiorIgual,
        dataCompromissoMenorIgual: filtro.dataCompromissoMenorIgual,
        dataCadastroMaiorIgual: filtro.dataCadastroMaiorIgual,
        dataCadastroMenorIgual: filtro.dataCadastroMenorIgual,

        sort: "dataCompromisso"
      });

      setFeeds(resultado.data);

      montarFeeds(resultado.data);

      setFiltrosPesquisa(oldState => { return { ...oldState, totalItems: resultado.totalRegistros } });

    } catch (error: any) {
      setFeeds([]);
      montarFeeds([]);
    }
    finally {
      setCarregandoFeed(false);
      setPesquisar(false);
    }
  }

  const carregarModulo = async () => {
    try {
      let resultado: IRecuperaLista<any>;
      resultado = await ModuloService.obterModulos({
        status: 1,
        limit: 10,
        totalItems: 0,
        codigo: '',
        nome: '',
        offset: 0,
        sort: "moduloId",
      });
      setModulosOptions(returnOptionsModulo(resultado.data));
    } catch (error: any) {
      setModulosOptions([]);
    }
  }

  const returnOptionsModulo = (array: any) => {
    array.forEach((item: any) => {
      moduloOptions.push({
        value: item.moduloId,
        label: item.nome,
      });
    });
    return moduloOptions;
  };

  const carregarUsuarios = async () => {
    try {
      let resultado: IRecuperaLista<any>;
      resultado = await UsuarioService.obterUsuariosFeeds({
        clientes: [],
        numeroDocumento: "",
        email: "",
        nomeUsuario: "",
        status: 1,
        limit: 100,
        totalItems: 0,
        offset: 0,
        sort: "clienteId",
      });
      setUsuariosOptions(returnOptionsUsuarios(resultado.data));
    } catch (error: any) {
      setUsuariosOptions([]);
    }
  }

  const returnOptionsUsuarios = (array: any) => {
    array.forEach((item: any) => {
      usuariosOptions.push({
        value: item.usuarioCompartilhadoId,
        label: item.usuarioCompartilhadoNome,
      });
    });
    return usuariosOptions;
  };

  const carregarEscritorios = async () => {
    try {
      let resultado: IRecuperaLista<any>;
      resultado = await EscritorioService.obterEscritorios({
        clienteId: 0,
        nome: "",
        codigo: "",
        limit: 5,
        isTelaPesquisaProcesso: true,
        totalItems: 0,
        offset: 0,
        sort: "escritorioId",
      });
      setEscritoriosOptions(returnOptionsEscritorios(resultado.data));
    } catch (error: any) {
      setEscritoriosOptions([]);
    }
  }

  const carregarTipoCompromisso = async () => {
    try {
      let resultado: IRecuperaLista<any>;
      resultado = await TipoCompromissoService.obter({
        clienteId: 0,
        nome: "",
        codigo: "",
        limit: 1000,
        totalItems: 0,
        offset: 0,
        status: 1,
        sort: "tipoCompromissoId",
      });
      setTipoCompromissoOptions(returnOptionsTipoCompromisso(resultado.data));
    } catch (error: any) {
      setTipoCompromissoOptions([]);
    }
  }

  const carregarGrupoUsuarios = async () => {
    try {
      let resultado: IRecuperaLista<any>;
      resultado = await UsuarioService.obterGrupoUsuarios({
        clientes: [],
        numeroDocumento: "",
        email: "",
        nomeUsuario: "",
        status: 1,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: "clienteId",
      });
      setGrupoUsuariosOptions(returnOptionsGrupoUsuarios(resultado.data));
    } catch (error: any) {
      setGrupoUsuariosOptions([]);
    }
  }

  const iniciarCompromisso = async (compromissoId: any) => {
    try {

      let compromisso = (await CompromissoService.obter({
        compromissoId: compromissoId,
        limit: 10,
        totalItems: 0,
        offset: 0,
        sort: "compromissoId",
        status: 1,
      }))?.data?.find(e => e);

      if (compromisso) {

        setCompromisso({
          modulo: compromisso.modulo,
          contratoId: compromisso.contratoId,
          compromissoId: compromisso.compromissoId,
          codigo: compromisso.codigo,
          observacaoCompromisso: compromisso.observacaoCompromisso,
          tipoCompromissoId: compromisso.tipoCompromissoId,
          observacaoConclusao: compromisso.observacaoConclusao,
          tipoCompromissoNome: "",
          usuariosAprovadoresList: compromisso.usuariosAprovadoresList,
          dataCompromisso: compromisso.dataCompromisso,
          dataConclusao: compromisso.dataConclusao,
          horario: moment(compromisso.dataCompromisso).format("HH:mm"),
          grupoId: []
        });

        toggleExibirCompromissoAvulso();

      }

    } catch (error: any) {
      setCompromisso(compromisso);
    }
  };

  const returnOptionsEscritorios = (array: any) => {
    array.forEach((item: any) => {
      escritoriosOptions.push({
        value: item.escritorioId,
        label: item.nome,
      });
    });
    return escritoriosOptions;
  };

  const returnOptionsTipoCompromisso = (array: any) => {
    array.forEach((item: any) => {
      tipoCompromissoOptions.push({
        value: item.tipoCompromissoId,
        label: item.nome,
      });
    });
    return tipoCompromissoOptions;
  };

  const returnOptionsGrupoUsuarios = (array: any) => {
    array.forEach((item: any) => {
      grupoUsuariosOptions.push({
        value: item.grupoId,
        label: item.nome,
      });
    });
    return grupoUsuariosOptions;
  };

  useEffect(() => {

    carregarModulo();

    carregarUsuarios();

    carregarEscritorios();

    carregarGrupoUsuarios();

    carregarTipoCompromisso();

  }, []);

  useEffect(() => {
    if (refreshFeed) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [refreshFeed]);

  const setFeed = (feed: any, isNavigate: boolean) => {

    switch (feed.modulo) {

      case EModuloNomeEnum.Contencioso:
        if (isNavigate) {
          iniciarProcesso(feed.processoId);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.AreaDireito,
            compromissoId: feed.compromissoId,
            processoId: feed.processoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Contrato:
        if (isNavigate) {
          iniciarContrato(feed.contratoId);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Contrato,
            compromissoId: feed.compromissoId,
            contratoId: feed.contratoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }

        break;

      case EModuloNomeEnum.Procuracao:
        if (isNavigate) {
          iniciarProcuracao(feed.procuracaoId);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Procuracao,
            compromissoId: feed.compromissoId,
            procuracaoId: feed.procuracaoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Imovel:
        if (isNavigate) {
          iniciarImovel(feed.imovelId);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Imovel,
            compromissoId: feed.compromissoId,
            imovelId: feed.imovelId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Obrigação:
        if (isNavigate) {
          iniciarSolucao(feed.obrigacaoId, EModulo.Obrigacao);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Obrigacao,
            compromissoId: feed.compromissoId,
            obrigacaoId: feed.obrigacaoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Desdobramento:
        if (isNavigate) {
          iniciarSolucao(feed.desdobramentoId, EModulo.Desdobramento);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Obrigacao,
            compromissoId: feed.compromissoId,
            desdobramentoId: feed.desdobramentoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Regulatorio:
        if (isNavigate) {
          iniciarLicenca(feed.licencaId);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Regulatorio,
            compromissoId: feed.compromissoId,
            licencaId: feed.licencaId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Decisao:
        if (isNavigate) {
          iniciarSolucao(feed.decisaoId, EModulo.Decisao);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Decisao,
            compromissoId: feed.compromissoId,
            decisaoId: feed.decisaoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Consultivo:
        if (isNavigate) {
          iniciarConsulta(feed.consultaId);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Consultivo,
            compromissoId: feed.compromissoId,
            consultaId: feed.consultaId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Despesa:
        if (isNavigate) {
          iniciarSolucao(feed.despesaId, EModulo.Despesa);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Despesa,
            compromissoId: feed.compromissoId,
            despesaId: feed.despesaId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.PropostaAcordo:
        if (isNavigate) {
          iniciarSolucao(feed.propostaAcordoId, EModulo.PropostaAcordo);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.PropostaAcordo,
            compromissoId: feed.compromissoId,
            propostaAcordoId: feed.propostaAcordoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Garantia:
        if (isNavigate) {
          iniciarSolucao(feed.garantiaId, EModulo.Garantia);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Garantia,
            compromissoId: feed.compromissoId,
            garantiaId: feed.garantiaId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Bloqueio:
        if (isNavigate) {
          iniciarSolucao(feed.bloqueioId, EModulo.Bloqueio);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Bloqueio,
            compromissoId: feed.compromissoId,
            bloqueioId: feed.bloqueioId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Honorario:
        if (isNavigate) {
          iniciarSolucao(feed.honorarioId, EModulo.XpayHonorario);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Comunicacao,
            compromissoId: feed.compromissoId,
            honorarioId: feed.honorarioId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Reembolso:
        if (isNavigate) {
          iniciarSolucao(feed.reembolsoId, EModulo.XpayReembolso);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.XpayReembolso,
            compromissoId: feed.compromissoId,
            reembolsoId: feed.reembolsoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Comunicacao:
      case "Comunicacao": // ajustar na base
        if (isNavigate) {
          iniciarSolucao(feed.comunicacaoId, EModulo.Comunicacao);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Comunicacao,
            compromissoId: feed.compromissoId,
            comunicacaoId: feed.comunicacaoId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      case EModuloNomeEnum.Audiencia:
        if (isNavigate) {
          iniciarSolucao(feed.audienciaId, EModulo.Audiencia);
        }
        else {
          setCompromisso({
            ...compromisso,
            modulo: EModulo.Comunicacao,
            compromissoId: feed.compromissoId,
            audienciaId: feed.audienciaId,
            fluxoFaseId: feed.fluxoFaseId,
          });
          setExibirModalCompromisso(!exibirModalCompromisso);
        }
        break;

      default:
        setCompromisso({
          ...compromisso,
          modulo: EModulo.Compromisso,
          compromissoId: feed.compromissoId,
          contratoId: feed.contratoId,
          fluxoFaseId: feed.fluxoFaseId,
        });
        setExibirModalCompromisso(!exibirModalCompromisso);
        return;
    }

  }

  const iniciarProcuracao = async (procuracaoId: number) => {
    try {

      let resultado: IRecuperaLista<IProcuracao>;

      resultado = await ProcuracaoService.obterProcuracaoPorId(procuracaoId);

      setProcuracao(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Procuracao/Capa");
    }
  }

  const iniciarConsulta = async (consultaId: number) => {
    try {
      let resultado: IRecuperaLista<IConsulta>;
      resultado = await ConsultaService.obterConsultaPorId(consultaId);
      setConsulta(resultado.data[0]);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Consulta/Capa");
    }
  }

  const iniciarLicenca = async (licencaId: number) => {
    try {
      let resultado: IRecuperaLista<ILicenca>;
      resultado = await LicencaService.obterLicencaPorId(licencaId);
      setLicenca(resultado.data[0]);
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Licenca/Capa");
    }
  }

  const iniciarProcesso = async (processoId: number) => {
    try {

      let resultado: IRecuperaLista<IProcesso>;

      resultado = await ProcessoService.obterProcessoPorId(processoId);

      setProcesso(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Processo/Capa");
    }
  };

  const iniciarContrato = async (contratoId: number) => {
    try {

      let resultado: IRecuperaLista<IContrato>;

      resultado = await ContratoService.obterContratoPorId(contratoId);

      setContrato(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Contrato/Capa");
    }
  }

  const iniciarImovel = async (imovelId: number) => {
    try {

      let resultado: IRecuperaLista<IImovel>;
      resultado = await ImovelService.obterImovelPorId(imovelId);
      setImovel(resultado.data[0]);

    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate("/Imovel/Capa");
    }
  };

  const iniciarSolucao = async (solucaoId: number, modulo: EModulo) => {
    try {

      let processoId = 0;

      const obter = async (service: (id: number) => Promise<any>, setState: (data: any) => void, modulo: EModulo) => {

        const { data } = await service(solucaoId);

        const solucao = data[0];

        if (solucao.processoId) {
          processoId = solucao.processoId;
          const { data: processos } = await ProcessoService.obterProcessoPorId(processoId);
          const processo = processos[0];
          setProcesso(processo);
          setState({
            ...solucao,
            categoriaId: eval(`solucao.${tipoPorModulo(modulo)}Id`),
            modulo: EModulo.AreaDireito,
            processoId: processo?.processoId,
            areaDireitoId: processo.areaDireitoId,

          }); solucao
        }
      };

      switch (modulo) {
        case EModulo.Obrigacao:
          await obter(ObrigacaoService.obterObrigacaoPorId, setObrigacao, EModulo.Obrigacao);
          break;

        case EModulo.Despesa:
          await obter(DespesaService.obterDespesaPorId, setDespesa, EModulo.Despesa);
          break;

        case EModulo.Decisao:
          await obter(DecisaoService.obterDecisaoPorId, setDecisao, EModulo.Decisao);
          break;

        case EModulo.PropostaAcordo:
          await obter(PropostaAcordoService.obterPropostaAcordoPorId, setPropostaAcordo, EModulo.PropostaAcordo);
          break;

        case EModulo.Desdobramento:
          await obter(DesdobramentoService.obterDesdobramentoPorId, setDesdobramento, EModulo.Desdobramento);
          break;

        case EModulo.Garantia:
          await obter(GarantiaService.obterGarantiaPorId, setGarantia, EModulo.Garantia);
          break;

        case EModulo.Bloqueio:
          await obter(BloqueioService.obterBloqueioPorId, setBloqueio, EModulo.Bloqueio);
          break;

        case EModulo.XpayReembolso:
          await obter(ReembolsoService.obterReembolsoPorId, setReembolso, EModulo.XpayReembolso);
          break;

        case EModulo.XpayHonorario:
          await obter(HonorarioService.obterHonorarioPorId, setHonorario, EModulo.XpayHonorario);
          break;

        case EModulo.Comunicacao:
          await obter(ComunicacaoService.obterComunicacaoPorId, setComunicacao, EModulo.Comunicacao);
          break;

        case EModulo.Audiencia:
          await obter(AudienciaService.obterAudienciaPorId, setAudiencia, EModulo.Audiencia);
          break;


        default:
          throw new Error(`Módulo ${modulo} não suportado`);
      }
    } catch (error: any) {
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro`,
        text: error?.response?.data?.Message ?? error.message,
        showConfirmButton: true,
      });
    } finally {
      navigate(`/${routePropriedade(modulo)}/Capa`);
    }
  };

  const PopoverDetalhe = () => (
    <Popover id="popover-basic">
      <Popover.Body style={{ color: '#fd7e14' }}>
        <b>ID:</b> {feedDetalhe?.compromissoId}<br />
        <b>Horário:</b> {moment(feedDetalhe?.dataCompromisso).format('HH:mm')}<br />
        <b>Tipo:</b> {feedDetalhe?.tipoCompromissoNome}<br />
        <b>Usuário:</b> {feedDetalhe?.usuariosAprovadoresList?.find((e: any) => e.principal)?.nome}<br />
        <b>Módulo:</b> {feedDetalhe?.modulo}<br />
        <b>Status:</b> {feedDetalhe?.status}<br />
        <b>Identificador:</b> {feedDetalhe?.compromissoDetalhes}<br />
        <b>
          <p>
            <OverlayTrigger placement="bottom" overlay={tooltip("Capa")}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  style={{
                    border: "none",
                    background: "none",
                    visibility: feedDetalhe?.modulo == "Compromisso" ? "hidden" : "initial"
                  }}
                  {...triggerHandler}
                  onClick={() => setFeed(feedDetalhe, true)}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-front"></span>
                </Button>
              )}

            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={tooltip("Deseja concluir?")}>
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  style={{
                    border: "none",
                    background: "none",
                  }}
                  {...triggerHandler}
                  onClick={() => setFeed(feedDetalhe, false)}
                  className="d-inline-flex align-items-center"
                >
                  <span ref={ref} className="navigation-service-icon navigation-service-icon-checkin"></span>
                </Button>
              )}
            </OverlayTrigger>


            <OverlayTrigger
              placement="bottom"
              overlay={tooltip("Editar?")}
              trigger={['hover', 'focus']} // Add focus trigger
            >
              {() => (
                <FontAwesomeIcon
                  title="Editar"
                  style={{
                    cursor: 'pointer',
                    width: '20px',
                    height: '20px'
                  }}
                  onClick={() => iniciarCompromisso(feedDetalhe?.compromissoId)}
                  className='mx-2 text-orange'
                  icon={faEdit}
                />
              )}
            </OverlayTrigger>

          </p>
        </b>
        <br />
      </Popover.Body>
    </Popover>
  );

  const renderAgendamento = (event: any) => {

    const handleClick = async () => await carregarDetalhes(event?.event?.extendedProps?.compromissoId);

    return (
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        placement="top"
        overlay={PopoverDetalhe()}
        onEnter={handleClick}
      >
        <a style={{ cursor: "pointer" }}>
          <FontAwesomeIcon className="mx-1" icon={faClock} />
          <b style={{ fontSize: '12px', paddingRight: '5px' }}>
            {event?.event?.extendedProps?.dataCompromisso?.slice(-5)}
          </b>
          <i style={{ fontSize: '12px' }}>
            {event?.event?.extendedProps?.tipoCompromissoNome}
          </i>
          {/* Indicador de carregamento enquanto os detalhes estão sendo carregados */}
          {carregandoDetalhes && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
        </a>
      </OverlayTrigger>
    );
  };

  const PopoverMenu = () => (
    <Popover id="popover-basic">
      <Popover.Body style={{ cursor: "pointer" }}>
        <>
          <p>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2"></Tooltip>}>
              {() => (
                <FontAwesomeIcon onClick={handleDiaClick} color='white' className='mx-2 text-orange' icon={faClock} />
              )}
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2"></Tooltip>}>
              {() => (
                <FontAwesomeIcon onClick={handleSemanaClick} color='white' className='mx-2 text-orange' icon={faCalendarWeek} />
              )}
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2"></Tooltip>}>
              {() => (
                <FontAwesomeIcon onClick={handleMesClick} color='white' className='mx-2 text-orange' icon={faCalendar} />
              )}
            </OverlayTrigger>
          </p>

          <p>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Relatório</Tooltip>}>
              {() => (
                <FontAwesomeIcon onClick={() => setExibirModalRelatorio(true)} color="var(--primary-base2)" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
              )}
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">+ Filtros</Tooltip>}>
              {() => (
                <a data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false">
                  <FontAwesomeIcon color={"var(--primary-base2)"} className="mx-2" icon={faFilter} title="+ Filtros" />
                </a>
              )}
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Planilha</Tooltip>}>
              {() => (
                <FontAwesomeIcon onClick={handlePlanilhaClick} color={"var(--primary-base2)"} className="mx-2" icon={faList} title="Planilha" />
              )}
            </OverlayTrigger>
          </p>

          <p>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Adicionar</Tooltip>}>
              {() => (
                <FontAwesomeIcon onClick={() => setExibirModalCompromissoAvulso(true)} color="var(--primary-base2)" className="mx-2" icon={faPlus} title="Adicionar" />
              )}
            </OverlayTrigger>
          </p>
        </>
      </Popover.Body>
    </Popover>
  );

  const limparFiltros = () => {
    setUsuariosSelected([]);
    setGrupoUsuariosSelected([]);
    setEscritoriosSelected([]);
    settipoCompromissoSelected([]);

    setFiltrosPesquisa({
      compromissoId: 0,
      modeloRelatorioId: 0,
      modulo: EModulo.Compromisso,
      escritorioId: [],
      grupoUsuarioId: [],
      usuarioId: [],
      moduloId: [],
      categoria: 0,
      tipoCompromissoId: [],
      tipoCategoriaCompromissoId: [],
      processoId: 0,
      clienteId: 0,
      dataCompromissoMaiorIgual: "",
      dataCompromissoMenorIgual: "",
      observacaoCompromisso: "",
      usuarioIdCadastro: 0,
      dataCadastroMaiorIgual: "",
      dataCadastroMenorIgual: "",
      observacaoConclusao: "",
      usuarioIdConclusao: 0,
      dataConclusaoMaiorIgual: "",
      dataConclusaoMenorIgual: "",
      usuariosAprovadores: [],
      status: [],
      limit: 100,
      totalItems: 0,
      offset: 0,
      sort: 'compromissoId'
    });
  }

  const colunas: TableColumn<IFeed>[] = [
    {
      name: 'Módulo',
      sortField: 'modulo',
      selector: (row: IFeed) => row.modulo,
      sortable: true,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: 'Dia',
      sortField: 'dataCompromisso',
      selector: (row: IFeed) => moment(row.dataCompromisso).format('DD/MM/YYYY hh:ss'),
      sortable: true,
      wrap: true,
      ignoreRowClick: true
    },
    {
      name: 'Tipo',
      sortField: 'tipoCompromissoNome',
      selector: (row: IFeed) => row.tipoCompromissoNome,
      sortable: true,
      wrap: true
    },
    {
      name: 'Descrição',
      sortField: 'observacaoCompromisso',
      selector: (row: IFeed) => row.observacaoCompromisso,
      sortable: true,
      wrap: true
    },
    {
      name: 'Responsável Principal',
      sortField: 'usuarioNomeCadastro',
      selector: (row: IFeed) => row.usuarioNomeCadastro,
      sortable: true,
      wrap: true
    },
    {
      name: 'Responsáveis Todos',
      sortField: 'processoParteContraria',
      cell: (row: IFeed) => {
        let nomes = '';
        if (row?.usuariosAprovadoresList?.length < 3) {
          for (let index = 0; index < row?.usuariosAprovadoresList?.length; index++) {
            nomes += row.usuariosAprovadoresList[index].nome;
          }
        } else {
          if (row?.usuariosAprovadoresList?.length > 3) {
            for (let index = 0; index < 3; index++) {
              nomes += row.usuariosAprovadoresList[index].nome;
            }
          }
        }
        return `${nomes}...`;
      },
      sortable: true,
      wrap: true
    },
    {
      name: 'Status',
      sortField: 'status',
      selector: (row: IFeed) => row.status,
      sortable: true,
      wrap: true
    },
    {
      name: 'Opções',
      cell: (row: IFeed) => {
        return (
          <>
            <DropDown
              height={150}
              totalItems={feeds?.length}
              content={
                <>
                  <Dropdown.Item onClick={() => setFeed(
                    {
                      modulo: row.modulo,
                      processoId: row.processoId,
                      contratoId: row.contratoId,
                      imovelId: row.imovelId,
                      licencaId: row.licencaId,
                      procuracaoId: row.procuracaoId,


                      despesaId: row.despesaId,
                      bloqueioId: row.bloqueioId,
                      garantiaId: row.garantiaId,
                      obrigacaoId: row.obrigacaoId,
                      comunicacaoId: row.compromissoId,
                      desdobramentoId: row.desdobramentoId,
                      propostaAcordoId: row.propostaAcordoId,
                      honorarioId: row.honorarioId,
                      reembolsoId: row.reembolsoId,

                      compromissoId: row.compromissoId,
                      fluxoFaseId: row.fluxoFaseId
                    }, true)}>
                    <FontAwesomeIcon className="mx-2 text-orange" icon={faForward} />
                    <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Acessar Capa
                    </label>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setFeed(
                    {
                      modulo: row.modulo,
                      processoId: row.processoId,
                      contratoId: row.contratoId,
                      imovelId: row.imovelId,
                      licencaId: row.licencaId,
                      procuracaoId: row.procuracaoId,


                      despesaId: row.despesaId,
                      bloqueioId: row.bloqueioId,
                      garantiaId: row.garantiaId,
                      obrigacaoId: row.obrigacaoId,
                      comunicacaoId: row.compromissoId,
                      desdobramentoId: row.desdobramentoId,
                      propostaAcordoId: row.propostaAcordoId,
                      honorarioId: row.honorarioId,
                      reembolsoId: row.reembolsoId,


                      compromissoId: row.compromissoId,
                      fluxoFaseId: row.fluxoFaseId

                    }, false)}>
                    <FontAwesomeIcon className="mx-2 text-orange" icon={faCheck} />
                    <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Concluir
                    </label>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => {
                    setResponsaveis(row.usuariosAprovadoresList);
                    setExibirModalResponsaveis(true);
                  }}>
                    <FontAwesomeIcon className="mx-2 text-orange" icon={faUsers} />
                    <label style={{ fontSize: '12px' }} htmlFor="form-nome" className="form-label fw-bolder text-orange">
                      Todos os Responsáveis
                    </label>
                  </Dropdown.Item>

                </>}
            >
            </DropDown >
          </>
        )
      },
    },

  ]

  useEffect(() => {

    if (pesquisar) carregarFeeds(filtrosPesquisa);

  }, [pesquisar])

  useEffect(() => {

    setCompromissos([]);

    carregarFeeds(filtrosPesquisa);

  }, [filtrosPesquisa.offset, filtrosPesquisa.limit, filtrosPesquisa.sort])


  return (
    <>
      <LayoutPageTitle title="Feed">

        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={PopoverMenu()}>
          <a style={{ cursor: "pointer" }} className="navigation-services-list-link">
            <span className="navigation-service-icon navigation-service-icon-grid"></span>
          </a>
        </OverlayTrigger>

      </LayoutPageTitle>

      <FluxoModal
        exibirModal={exibirModalCompromisso}
        setExibirModal={setExibirModalCompromisso}
        compromisso={compromisso}
        setRefresh={setRefreshFeed}
      />

      <FeedRelatorioModal
        exibirModal={exibirModalRelatorio}
        toggleModal={toggleExibirRelatorio}
        usuarioId={usuariosSelected}
        escritorioId={escritoriosSelected}
        grupoUsuarioId={grupoUsuariosSelected}
      />

      <ResponsaveisFeedModal
        exibirModal={exibirModalResponsaveis}
        toggleModal={toggleExibirResponsaveis}
        usuarios={responsaveis}
      />

      <CompromissoField
        modulo={EModulo.Compromisso}
        editarCompromisso={compromisso}
        exibirModal={exibirModalCompromissoAvulso}
        setAtualizarTabelaCompromisso={setPesquisar}
        toggleModal={toggleExibirCompromissoAvulso}
      />

      <AreaTextoFiltroField
        toggleModal={toggleAreaTextoModal}
        exibirModal={exibirModalAreaTexto}
        field="observacaoCompromisso"
        setFiltrosPesquisa={setFiltrosPesquisa}
        titulo="Observação do Compromisso"
      />


      <CollapseFiltro
        titulo="Filtros"
        content={
          <>
            <div className="row mt-2">
              <div className="col-md-3 mt-2">
                <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                  Módulo:
                </label>
                <MultiSelect
                  options={moduloOptions}
                  value={modulosSelected}
                  onChange={(event: any) => setModulosSelected(event)}
                  labelledBy={"Selecione..."}
                  overrideStrings={{
                    selectSomeItems: "Selecione...",
                    allItemsAreSelected: "Todos selecionados",
                    selectAll: "Selecione todos",
                    search: "Pesquise",
                    selectAllFiltered: "Selecione todos (filtrados)"
                  }} />
              </div>
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Usuário:
              </label>
              <MultiSelect
                options={usuariosOptions}
                value={usuariosSelected}
                onChange={(event: any) => setUsuariosSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Escritório:
              </label>
              <MultiSelect
                options={escritoriosOptions}
                value={escritoriosSelected}
                onChange={(event: any) => setEscritoriosSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Grupo de Usuário:
              </label>
              <MultiSelect
                options={grupoUsuariosOptions}
                value={grupoUsuariosSelected}
                onChange={(event: any) => setGrupoUsuariosSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Categoria do Compromisso:
              </label>
              <MultiSelect
                options={tipoCategoriaCompromissoOptions}
                value={tipoCategoriaCompromissoSelected}
                onChange={(event: any) => setTipoCategoriaCompromissoSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Status:
              </label>
              <MultiSelect
                options={statusCompromissoOptions}
                value={statusCompromissoSelected}
                onChange={(event: any) => setStatusCompromissoSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form-nome" className="form-label fw-bolder text-orange">
                Tipo de Compromisso:
              </label>
              <MultiSelect
                options={tipoCompromissoOptions}
                value={tipoCompromissoSelected}
                onChange={(event: any) => settipoCompromissoSelected(event)}
                labelledBy={"Selecione..."}
                overrideStrings={{
                  selectSomeItems: "Selecione...",
                  allItemsAreSelected: "Todos selecionados",
                  selectAll: "Selecione todos",
                  search: "Pesquise",
                  selectAllFiltered: "Selecione todos (filtrados)"
                }} />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form" className="form-label fw-bolder text-orange">Data de Compromisso:</label>
              <DateTimeRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={filtrosPesquisa.dataCompromissoMaiorIgual && filtrosPesquisa.dataCompromissoMenorIgual
                  ? [filtrosPesquisa.dataCompromissoMaiorIgual, filtrosPesquisa.dataCompromissoMenorIgual]
                  : ""}
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataCompromissoMaiorIgual: "",
                        dataCompromissoMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue: any) => {
                    return {
                      ...oldValue,
                      dataCompromissoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataCompromissoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }} />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form" className="form-label fw-bolder text-orange">Data de Conclusão:</label>
              <DateTimeRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={filtrosPesquisa.dataConclusaoMaiorIgual && filtrosPesquisa.dataConclusaoMenorIgual
                  ? [filtrosPesquisa.dataConclusaoMaiorIgual, filtrosPesquisa.dataConclusaoMenorIgual]
                  : ""}
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataConclusaoMaiorIgual: "",
                        dataConclusaoMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue: any) => {
                    return {
                      ...oldValue,
                      dataConclusaoMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataConclusaoMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }} />
            </div>

            <div className="col-md-6 mt-2">
              <label htmlFor="form-observacaoCompromisso" className="form-label fw-bolder text-orange">
                Observação:
              </label>
              <div className="input-group">
                <textarea
                  value={filtrosPesquisa.observacaoCompromisso}
                  onChange={(e) => {
                    setFiltrosPesquisa((oldState) => {
                      return { ...oldState, observacaoCompromisso: e.target.value };
                    });
                  }}
                  placeholder="Observação do compromisso"
                  className={"form-control"}
                  id="form-observacaoCompromisso"
                />

                <Button
                  onClick={() => toggleAreaTextoModal()}
                  style={{ color: "white", backgroundColor: "var(--primary-base)", borderColor: "var(--primary-base)" }}
                  variant="secondary"
                  size="sm"
                >
                  <FontAwesomeIcon color={"white"} className="mx-1" icon={faEdit} />
                </Button>
              </div>
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form-observacaoCompromisso" className="form-label fw-bolder text-orange">
                ID Compromisso:
              </label>
              <input
                value={filtrosPesquisa.compromissoId}
                onChange={(e) => {
                  setFiltrosPesquisa((oldState: any) => {
                    return { ...oldState, compromissoId: e.target.value };
                  });
                }}
                placeholder="ID Compromisso"
                className={"form-control"}
              />
            </div>

            <div className="col-md-3 mt-2">
              <label htmlFor="form" className="form-label fw-bolder text-orange">Data de Cadastro:</label>
              <DateTimeRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                value={filtrosPesquisa.dataCadastroMaiorIgual && filtrosPesquisa.dataCadastroMenorIgual
                  ? [filtrosPesquisa.dataCadastroMaiorIgual, filtrosPesquisa.dataCadastroMenorIgual]
                  : ""}
                onChange={(datas: Date[]) => {
                  if (!datas) {
                    setFiltrosPesquisa((oldValue: any) => {
                      return {
                        ...oldValue,
                        dataCadastroMaiorIgual: "",
                        dataCadastroMenorIgual: "",
                      };
                    });
                    return;
                  }

                  let data_inicial = datas[0];
                  let data_final = datas[1];
                  if (data_final) {
                    data_final.setHours(0, 0, 0, 0);
                    data_final = new Date(data_final.getTime() - 1);
                  }
                  setFiltrosPesquisa((oldValue: any) => {
                    return {
                      ...oldValue,
                      dataCadastroMaiorIgual: data_inicial ? `${data_inicial.toISOString().split("T")[0]}T00:00:00.0000` : "",
                      dataCadastroMenorIgual: data_final ? `${data_final.toISOString().split("T")[0]}T23:59:59.9999` : "",
                    };
                  });
                }} />
            </div>

            <div className="col-12 mt-2" style={{ textAlign: 'right' }}>

              <button onClick={() => setPesquisar(true)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} title="Pesquisar" />
              </button>

              <button
                onClick={() => limparFiltros()}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} title="Limpar Filtros" />
              </button>

              <button
                onClick={() => setExibirModalRelatorio(true)}
                className="btn btn-sm btn-orange search-buttom-margin ms-2"
              >
                <FontAwesomeIcon color="white" className="mx-2" icon={faFileExcel} title="Baixar Relatório" />
              </button>

              <button onClick={() => setExibirModalCompromissoAvulso(!exibirModalCompromissoAvulso)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
                <FontAwesomeIcon color="white" className="mx-2" icon={faPlus} title="Adicionar" />
              </button>

            </div>
          </>
        }
      />

      <hr />

      {view == 'sheetGrid' ?
        <div className="col-md-12">
          <GridPadrao
            onSort={handleSort}
            progressPending={carregandoFeed}
            limit={filtrosPesquisa.limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationServer={true}
            paginationTotalRows={filtrosPesquisa.totalItems}
            colunas={colunas}
            tipo='Feeds'
            itens={feeds} />
        </div> :
        <div className="col-md-12">
          <StyleWrapper>
            {!carregandoFeed || refreshFeed ?
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={view}
                events={compromissos}
                eventContent={renderAgendamento}
                locale={esLocale}
              /> : <Carregando />}
          </StyleWrapper>
        </div>
      }


    </>
  )

}

export default FeedPage;