import { useContext, useEffect, useState } from "react";
import { faArrowRight, faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";

import { ECamposIDEnum, EModulo, ETelaEnum } from "../../enum";
import { tipoPorModulo } from "../../utils/Utils";
import { defaultSchema, setValuesXCadastroCampos } from "../../utils/SchemasUtil";

import IOption from "../../interfaces/IOption";
import { OrangeInterfaceContext } from "../../interfaces/Contexts/OrangeInterfaceContext";
import { AdicionaDesdobramentoRequest } from "../../interfaces/Requests/Desdobramento/AdicionaDesdobramentoRequest";
import { IRecuperaConfiguracaoTelaResponse } from "../../interfaces/Requests/ConfiguracaoTela/IRecuperaConfiguracaoTelaResponse";

import DesdobramentoService from "../../services/DesdobramentoService";
import TipoDesdobramentoService from "../../services/TipoDesdobramentoService";
import ConfiguracaoTelaService from "../../services/ConfiguracaoTelaService";

import Carregando from "../../components/Carregando";
import { OrangeContext } from "../../contexts/OrangeProvider";
import LayoutPageForButton from "../../layout/LayoutPageButton";
import { notifyFormikDanger } from "../../components/Comum/Toast/ToastFormik";
import { tipoDesdobramentoInicialValues } from "../../contexts/InitialValuesContext";
import CamposCadastroDinamicos from "../../components/Comum/Solucao/CamposCadastroSolucoes";

const DesdobramentoCadastroPage = () => {
  const [carregandoTiposDesdobramento, setCarregandoTiposDesdobramento] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [configuracoesTela, setConfiguracoesTela] = useState<IRecuperaConfiguracaoTelaResponse[]>([]);
  const { desdobramento, setDesdobramento, setTipoDesdobramento, tipoDesdobramento, processoCadastro } = useContext<OrangeInterfaceContext>(OrangeContext);

  const [adicionarLoading, setAdicionarLoading] = useState<boolean>(false);
  const [carregandoConfiguracoesTela, setCarregandoConfiguracoesTela] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues: AdicionaDesdobramentoRequest = {
    desdobramentoId: 0,
    principalId: 0,
    tipoDesdobramentoId: 0,

    modulo: EModulo.Desdobramento,
    tela: ETelaEnum.TelaCadastroDesdobramento,
    categoriaId: desdobramento.categoriaId || 0,

    processoId: desdobramento.processoId || 0,
    areaDireitoId: desdobramento.areaDireitoId || 0,

    imovelId: desdobramento.imovelId || 0,
    tipoImovelId: desdobramento.tipoImovelId || 0,

    numero: '',
    datadistribuicao: null,

    cidadeId: 0,
    codigo: "",
    estadoId: 0,
    instanciaId: 0,
    responsaveldesdobramentoId: 0,
    resumo: "",
    dataprotocolo: null,

    xCampoValorList: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultSchema(configuracoesTela),
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        const swalWithBootstrapButtons = Swal.mixin({
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-orange",
            cancelButton: "btn btn-danger ms-5",
          },
          buttonsStyling: false,
        });
        let result = await swalWithBootstrapButtons.fire({
          title: "Salvar Desdobramento",
          text: `Você realmente deseja salvar?`,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: `Confirmar`,
        });
        if (result.isConfirmed) {

          Swal.fire({
            heightAuto: false,
            icon: "info",
            title: "Realizando o cadastro do desdobramento...",
            showConfirmButton: false,
          });

          Swal.showLoading();

          setAdicionarLoading(true);

          let resultado = await DesdobramentoService.adicionaDesdobramento({
            ...values,
            xCampoValorList: setValuesXCadastroCampos(configuracoesTela, formik)
          });


          Swal.hideLoading();

          setDesdobramento({
            ...resultado,
            desdobramentoId: resultado.desdobramentoId,
            tipoDesdobramentoId: resultado.tipoDesdobramentoId,
            categoriaId: resultado.tipoDesdobramentoId,
            modulo: desdobramento.modulo,
            processoId: desdobramento?.processoId,
            areaDireitoId: desdobramento.areaDireitoId,
            tipoImovelId: desdobramento.tipoImovelId,
            imovelId: desdobramento?.imovelId
          });

          setTipoDesdobramento(tipoDesdobramento);

          setConfiguracoesTela([]);

          await Swal.fire({
            heightAuto: false,
            icon: "success",
            title: `Desdobramento cadastrado com sucesso`,
            timer: 4000,
          });

        }
      } catch (error: any) {
        Swal.fire({
          heightAuto: false,
          icon: "error",
          title: `Não foi possivel salvar esta solicitação`,
          text: error.response.data.Message,
          showConfirmButton: true,
        });
      } finally {
        setSubmitting(false);
        setAdicionarLoading(false);
      }
    },
  });

  useEffect(() => {

    setTipoDesdobramento(tipoDesdobramentoInicialValues);

    carregarTipoDesdobramento();

    setConfiguracoesTela([]);

    formik.setValues(initialValues);

  }, []);


  const setarValoresXFinderDistribuicao = () => {

    if (desdobramento?.numero && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.Numero)) {
      formik.setFieldValue("numero", desdobramento?.numero);
    }

    if (desdobramento?.dataDistribuicao && configuracoesTela?.filter(e => e.campoNome == ECamposIDEnum.DataDistribuicao)) {
      formik.setFieldValue("datadistribuicao", moment(desdobramento.dataDistribuicao).format('YYYY-MM-DD'));
    }
  }

  useEffect(() => {
    if (tipoDesdobramento.tipoDesdobramentoId > 0) {
      carregarConfiguracaoTela();
      setarValoresXFinderDistribuicao();
    } else {
      setConfiguracoesTela([]);
      setTipoDesdobramento(tipoDesdobramentoInicialValues);
      formik.setValues(initialValues);
    }
  }, [tipoDesdobramento.tipoDesdobramentoId]);

  const carregarTipoDesdobramento = async () => {
    try {

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(desdobramento.modulo)}Id=${desdobramento.categoriaId}`);

      setCarregandoTiposDesdobramento(true);

      let options: IOption[] = [{ label: 'Selecione', value: 0 }];

      let resultado = await TipoDesdobramentoService.obterListaLimitadaTipoDesdobramentos({
        nome: "",
        codigo: "",
        status: 1,
        limit: 100,
        modulo: desdobramento.modulo,
        totalItems: 0,
        offset: 0,
        sort: "nome",
        ...objeto
      });

      resultado.data.map((item) => options.push({ label: item.opcaoNome, value: item.opcaoId }));
      setOptions(options);

    } catch (error: any) {
      setOptions([]);
      await Swal.fire({
        heightAuto: false,
        icon: "error",
        title: `Ocorreu um erro ao tentar pesquisar as Áreas`,
        text: error?.response?.data?.Message && typeof error.response.data.Message === "string" ? error.response.data.Message : error.message,
        showConfirmButton: true,
      });
    } finally {
      setCarregandoTiposDesdobramento(false);
    }
  };

  const carregarConfiguracaoTela = async () => {
    try {

      setCarregandoConfiguracoesTela(true);

      let objeto: any = {};

      eval(`objeto.${tipoPorModulo(desdobramento.modulo)}Id=${desdobramento.categoriaId}`);

      let resultado = await ConfiguracaoTelaService.obterConfiguracaoTela({
        ...objeto,
        modulo: desdobramento.modulo,
        tela: ETelaEnum.TelaCadastroDesdobramento,
        tipoDesdobramentoId: tipoDesdobramento.tipoDesdobramentoId
      });

      setConfiguracoesTela(resultado.data);
    } catch (error) {
      setConfiguracoesTela([]);
    } finally {
      setCarregandoConfiguracoesTela(false);
    }
  };

  console.log(formik.values);

  const onChangeTipoDesdobramento = (value: number, texto: string) => {
    setConfiguracoesTela([]);

    formik.setFieldValue("tipoDesdobramentoId", value);

    formik.setFieldValue("categoriaId", value);

    setTipoDesdobramento({
      tipoDesdobramentoId: value,
      nome: texto,
      opcaoNome: "",
      codigo: "",
      status: "",
      opcaoId: 0,
      categoriaNome: "",
      moduloNome: "",
      categoriaId: 0,
      modulo: EModulo.Default
    });
  };

  const renderCampos = () => {

    let component: any[] = [];

    configuracoesTela.forEach((configuracaoTela) => component.push(<CamposCadastroDinamicos configuracaoTela={configuracaoTela} configuracaoTelaLista={configuracoesTela} formik={formik} />));

    return <div className="row mt-12">{component}</div>;
  };

  return (
    <>
      <ToastContainer />

      <LayoutPageForButton title={`Novo Desdobramento ${tipoDesdobramento.tipoDesdobramentoId > 0 ? " - " + tipoDesdobramento.nome : ""}`}>
        {desdobramento.desdobramentoId > 0 ? (
          <>
            <button onClick={() => navigate("/Desdobramento/Capa")} type="submit" className="btn btn-orange me-5">
              IR PARA CAPA #{desdobramento.desdobramentoId}
              <FontAwesomeIcon color={"white"} className="mx-2" icon={faArrowRight} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                notifyFormikDanger(formik.errors);
                formik.submitForm();
              }}
              style={{ display: !formik.values.tipoDesdobramentoId ? 'none' : 'inline' }}
              className={formik.isValid ? "btn btn-orange" : "btn btn-danger"}>
              {!adicionarLoading && !carregandoConfiguracoesTela && (
                <>
                  SALVAR
                  <FontAwesomeIcon color={"white"} className="mx-2" icon={formik.isValid ? faPaperPlane : faExclamation} />
                </>
              )}
              {adicionarLoading && !carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Salvando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!adicionarLoading && carregandoConfiguracoesTela && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Carregando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </>
        )}
      </LayoutPageForButton>

      <div className="row mt-2">

        {carregandoTiposDesdobramento ? (
          <span className="indicator-progress" style={{ display: "block" }}>
            Carregando tipo de desdobramentos...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <div className="col-md-3 mt-3">
            <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
              Tipo de Desdobramento
            </label>
            <Select
              value={{
                label: options.find((e) => e.value === formik.values.tipoDesdobramentoId)?.label,
                value: formik.values.tipoDesdobramentoId,
              }}
              onChange={(option: any) => onChangeTipoDesdobramento(option.value, option.label)}
              options={options} />
          </div>
        )}
      </div>

      <hr></hr>

      {carregandoConfiguracoesTela ? <Carregando /> :
        <>
          {renderCampos()}
        </>}
    </>
  );
}

export default DesdobramentoCadastroPage;
