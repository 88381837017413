import { useState, useEffect, useContext } from 'react';
import Dashboard from '../../components/dashboard';
import { EstatisticasContextoProvider } from '../../components/dashboard/statistics';
import { IWidgetsTamanhos } from '../../components/dashboard/widgets/interfaces/IWidget';
import IRecuperaLista from '../../interfaces/IRecuperaLista';
import LayoutDashboard from '../../layout/Dashboard';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ProcessoService from '../../services/ProcessoService';
import * as constants from './HomeDashboarContants';
import DashboardStatisticas, { groupBy } from '../../components/dashboard/statistics/DashboardEstatistica';
import { IProcessoEstatisticas } from '../../interfaces/IProcesso';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthProvider';
import { OrangeInterfaceContext } from '../../interfaces/Contexts/OrangeInterfaceContext';
import { OrangeContext } from '../../contexts/OrangeProvider';


export const HomePage = () => {

const { user, updateUser } = useAuth();

const DASHBOARDID = 1;

const [areasDireito, setAreasDireito] = useState<string[]>([]);

const [, setFiltroSelecionado] = useState('');

const [areaDireitoSelecionada, setAreaDireitoSelecionada] =  useState<string>("");

const [empresas, setEmpresas] = useState<string[]>([]);

const [empresaSelecionada, setEmpresaSelecionada] = useState<string>("")

const [carregandoProcessos, setCarregandoProcessos] = useState(true);

const [estatisticas] = useState(new DashboardStatisticas());

const [filtrosPesquisa, setFiltrosPesquisa] = useState({
  dataCadastroMaiorIgual: '',
  dataCadastroMenorIgual: '',
  dataDistribuicaoMaiorIgual: '',
  dataDistribuicaoMenorIgual: '',
  dataEncerramentoMaiorIgual: '',
  dataEncerramentoMenorIgual: ''
});

const [widgetOrdem, setWidgetOrdem] = useState<string[]>([
    'Volumetria - Status e Total de Processos',   
    'Empresa- Status Ativo',
    'Empresa- Valor Provisionado',
    'Uf - Valor Provisionado',
    'Evolução - Processos distribuidos por ano.',
    'Evolução - Processos encerrados por ano.',
    'Distribuição de Processos por mês.',
    'Encerramento de Processos por mês.',
    'Composição - Processos por UF - Ativos'
    // 'Mapa'
  
]);

const [widgetTamanhos, setWidgetTamanho] = useState<IWidgetsTamanhos[]>([
    {
        xs: '12',
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6'
    },
    {
        xs: '12',
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6'
    },
    {
        xs: '12',
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6',
    },
    {
        xs: '12',
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6'
    },
    {
        xs: '12',
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6'
    },
    {
        xs: '12',
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6'
    },
    {
        xs: '12',
        sm: '12',
        md: '12',
        lg: '12',
        xl: '12'
    },
    {
        xs: '12',
        sm: '12',
        md: '12',
        lg: '12',
        xl: '12'
    },
    {
      xs: '12',
      sm: '12',
      md: '12',
      lg: '12',
      xl: '12'
    }    
   
]);

  

const alterandoDataEncerramento = (datas: Date[]): void => {
  if(!datas) {
      setFiltrosPesquisa((prevState) => {
          return {
            ...prevState,             
              dataEncerramentoMaiorIgual:  '',
              dataEncerramentoMenorIgual:  '',
          }
      });
      return 
  }
  let data_inicial = datas[0];
  let data_final = datas[1];

  if(data_final){
      data_final.setHours(0,0,0,0);
      data_final = new Date(data_final.getTime() - 1)
  }

  setFiltrosPesquisa((prevState) => {      
      return {
          ...prevState,     
          dataEncerramentoMaiorIgual: (data_inicial)?`${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`:'',
          dataEncerramentoMenorIgual: (data_final)?`${data_final.toISOString().split('T')[0]}T23:59:59.9999`:'',
      }
  })
  
}

const alterandoDataDistribuicao = (datas: Date[]): void => {
  if(!datas) {
      setFiltrosPesquisa((prevState) => {
          return {
            ...prevState,             
              dataDistribuicaoMaiorIgual:  '',
              dataDistribuicaoMenorIgual:  '',
          }
      });
      return 
  }
  let data_inicial = datas[0];
  let data_final = datas[1];

  if(data_final){
      data_final.setHours(0,0,0,0);
      data_final = new Date(data_final.getTime() - 1)
  }

  setFiltrosPesquisa((prevState) => {      
      return {
          ...prevState,     
          dataDistribuicaoMaiorIgual: (data_inicial)?`${data_inicial.toISOString().split('T')[0]}T00:00:00.0000`:'',
          dataDistribuicaoMenorIgual: (data_final)?`${data_final.toISOString().split('T')[0]}T23:59:59.9999`:'',
      }
  })
  
}

const carregarProcessos = async (area?: string, empresa?:string): Promise<void> => {
    await estatisticas.atualiza(async () => {
        try {
                setCarregandoProcessos(true);

                let resultado: IRecuperaLista<IProcessoEstatisticas>;
                resultado = await ProcessoService.obterStaticicasProcesso();
                resultado.data = resultado.data.filter((dado) =>  (dado.dataDistribuicao !== null || dado.dataEncerramento !== null)); 

                if(empresas.length == 0) {
                  const listaDeEmpresasDosProcessos = groupBy(resultado.data, e => e.processoEmpresas.find(e => e.principal === "True")?.nome);  
                  setEmpresas(Array.from(listaDeEmpresasDosProcessos.keys()).map(e => e ? e : 'Outros'));
                }

                if(areasDireito.length == 0) {
                  const listaDeAreasDeDireito = groupBy(resultado.data, e => e.areaDireitoNome);
                  setAreasDireito(Array.from(listaDeAreasDeDireito.keys()).map(e => e ? e : 'Outros'));
                }

                let resultadosFiltrados = resultado.data;

                if(area) {
                    resultadosFiltrados = resultadosFiltrados.filter(
                      (e) => (e.areaDireitoNome === area));
                }

                if(empresa) {
                  resultadosFiltrados = resultadosFiltrados.filter(
                    (e) => {
                      if(empresa === 'Outros') {
                        return e.processoEmpresas.length === 0
                      } else {
                        return e.processoEmpresas.find(e => e.principal === "True")?.nome === empresa
                      }
                    });
                }

                if(filtrosPesquisa.dataDistribuicaoMaiorIgual && filtrosPesquisa.dataDistribuicaoMenorIgual) {
                  resultadosFiltrados = resultadosFiltrados.filter(
                    (e) => 
                      {
                        const date = new Date(e.dataDistribuicao);
                        console.log(date.toISOString());  
                        return (date >=  new Date(filtrosPesquisa.dataDistribuicaoMaiorIgual) && date <=  new Date(filtrosPesquisa.dataDistribuicaoMenorIgual));
                      }
                    )               
                }

                if(filtrosPesquisa.dataEncerramentoMaiorIgual && filtrosPesquisa.dataEncerramentoMenorIgual) {
                  resultadosFiltrados = resultadosFiltrados.filter(
                    (e) => 
                      {
                        const date = new Date(e.dataEncerramento);
                        console.log(date.toISOString());  
                        return (date >=  new Date(filtrosPesquisa.dataEncerramentoMaiorIgual) && date <=  new Date(filtrosPesquisa.dataEncerramentoMenorIgual));
                      }
                    )               
                }
                
                if(resultadosFiltrados.length === 0) {
                  Swal.fire({
                    heightAuto: false,
                    title: 'Não foi obter o filtro selecionado',
                    text: 'Ocorreu um erro ao tentar recuperar o filtro selecionado. Os Dados não existem.',
                    timer: 4000,
                    icon: 'error',
                    showConfirmButton: false,
                });
                limpaFiltros();
                
                return resultado.data
                }

                return resultadosFiltrados;
               
        } catch (error) {
            console.log(error)
            setCarregandoProcessos(false);
            return [];
        }
        finally {
          setCarregandoProcessos(false);
            
        } 
    });
}

const limpaFiltros = () => {
  setAreaDireitoSelecionada('');
  setEmpresaSelecionada('');
  setFiltrosPesquisa( () => {
    return {                                
      dataCadastroMaiorIgual: '',
      dataCadastroMenorIgual: '',
      dataDistribuicaoMaiorIgual: '',
      dataDistribuicaoMenorIgual: '',
      dataEncerramentoMaiorIgual: '',
      dataEncerramentoMenorIgual: ''
    }
  }); 
}

const onChangeAreaDireito = (value: string, texto: string) => {
  setFiltroSelecionado(oldstate => (oldstate === value ? 'Todos' : value));
  setAreaDireitoSelecionada(value);    
}

const onChangeEmpresas = (value: string, texto: string) => { 
  setFiltroSelecionado(oldstate => (oldstate === value ? 'Todos' : value));
  setEmpresaSelecionada(value);
}

useEffect(() => {
  setCarregandoProcessos(false)
//  mountedRef.current ? carregarProcessos(areaDireitoSelecionada, empresaSelecionada) : ''
//     return void (mountedRef.current = false);
}, [])
    return (<>
        <LayoutDashboard dash={null} title="Dashboard" />
        <div className="row mt-2">          
            <div className="col-12 col-lg-3 col-xl-2 mt-3">
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                Áreas:
              </label>
              <select
                name="areaDireitoId"
                value={areaDireitoSelecionada}
                className={"form-select"}
                onChange={(event: any) => onChangeAreaDireito(event.target.value, event.target[event.target.selectedIndex].text)}
                placeholder="Nome"
                id="form-area"
              >
                <option value="">Todas</option>
                { areasDireito.map((area, index) => {
                  return (
                    <option key={index} value={area}>
                      {area}
                    </option>
                  );
                })
                }
              </select>
            </div>
            <div className="col-12 col-lg-3 col-xl-2 mt-3">
              <label htmlFor="form-areas" className="form-label fw-bolder text-orange">
                Empresas:
              </label>
              <select
                name="Empresas"
                value={empresaSelecionada}
                className={"form-select"}
                onChange={(event: any) => onChangeEmpresas(event.target.value, event.target[event.target.selectedIndex].text)}
                placeholder="Nome"
                id="form-area"
              >
                <option value="">Todas</option>
                {empresas.map((nome, index) => {
                  return (
                    <option key={index} value={nome}>
                      {nome}
                    </option>
                  );
                })}
              </select>
          </div>

          <div className="col-12 col-lg-3 col-xl-3 mt-3">
            <label 
              htmlFor=""
              className="form-label fw-bolder text-orange">
                Data do Encerramento:
            </label>

            <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                    (filtrosPesquisa.dataEncerramentoMaiorIgual && filtrosPesquisa.dataEncerramentoMenorIgual)?
                        [
                            filtrosPesquisa.dataEncerramentoMaiorIgual,
                            filtrosPesquisa.dataEncerramentoMenorIgual,
                        ]:''
                }
                onChange={alterandoDataEncerramento}

            />
        </div>

        <div className="col-12 col-lg-3 col-xl-3 mt-3">
            <label 
              htmlFor="form-data-assinatura" 
              className="form-label fw-bolder text-orange">
                Data da Distribuição:
            </label>

            <DateRangePicker
                className="form-control p-0"
                calendarIcon={null}
                showLeadingZeros={true}
                maxDate={new Date()}
                value={
                    (filtrosPesquisa.dataDistribuicaoMaiorIgual && filtrosPesquisa.dataDistribuicaoMenorIgual)?
                        [
                            filtrosPesquisa.dataDistribuicaoMaiorIgual,
                            filtrosPesquisa.dataDistribuicaoMenorIgual,
                        ]:''
                }
                onChange={alterandoDataDistribuicao}

            />
        </div>
        <div className="col-12 col-lg-3 col-xl-2 mt-3">
          <button onClick={() => carregarProcessos(areaDireitoSelecionada, empresaSelecionada)} className="btn btn-sm btn-orange search-buttom-margin ms-2">
            <FontAwesomeIcon color="white" className="mx-2" icon={faSearch} />
          </button>
          <button
            onClick={() => {
              limpaFiltros();
            }}
            className="btn btn-sm btn-orange search-buttom-margin ms-2"
          >
            <FontAwesomeIcon color="white" className="mx-2" icon={faEraser} />
          </button>
        </div>
      </div>
      
        <div className="row mt-2">
            <div className="col-md-12">
            <div className="col-md-12 mb-10">
                    {
                    estatisticas ? 
                        <EstatisticasContextoProvider
                            estatisticas={estatisticas}
                        >
                            <Dashboard
                                    estatico={true}
                                    widgets={constants.WIDGETS}
                                    widgetOrdens={widgetOrdem}
                                    widgetTamanhos={widgetTamanhos}
                                    carregando={carregandoProcessos}
                                    dashboadID={DASHBOARDID} 
                                    onDashboardChanged={function () {
                                        throw new Error('Function not implemented.');
                                    } } 
                                    salvar={function () {
                                        throw new Error('Function not implemented.');
                                    } }                                
                            />
                        </EstatisticasContextoProvider>
                        :
                        null
                    }
                </div>
            </div>
        </div>
   
    </>    
      )
}
export default HomePage;